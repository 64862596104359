var CADTH_GUIDELINE_DOC_PREFIX, CADTH_GUIDELINE_DOC_TYPE, CadthGuidelineDoc, errorHandlerWithMsg, generateActions, generateGUID, mediator, _ref;

CadthGuidelineDoc = require('lib/db_docs/cadth_guideline_doc');

_ref = require('lib/cadth_guidelines_helper'), CADTH_GUIDELINE_DOC_PREFIX = _ref.CADTH_GUIDELINE_DOC_PREFIX, CADTH_GUIDELINE_DOC_TYPE = _ref.CADTH_GUIDELINE_DOC_TYPE;

errorHandlerWithMsg = require('lib/app_utils').errorHandlerWithMsg;

generateActions = require('actions/utils/generate_actions');

generateGUID = require('base/lib/utils').generateGUID;

mediator = require('mediator');

module.exports = generateActions({
  autoGenerate: ['createOrUpdateError', 'createOrUpdateSuccess', 'fetchError', 'fetchSuccess', 'openDialog', 'closeDialog', 'setSortBy'],
  name: 'CadthGuidelinesActions',
  actions: {
    dbChange: function(change, _pending, _lastSeq) {
      return this.dispatch(change);
    },
    fetch: function(_arg) {
      var dbId;
      dbId = _arg.dbId;
      this.dispatch();
      return CadthGuidelineDoc.at(dbId).fetchMany().then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
    },
    setDialogField: function(field, value) {
      return this.dispatch({
        field: field,
        value: value
      });
    },
    createOrUpdate: function(guideline) {
      var docId, _ref1;
      this.dispatch();
      docId = (_ref1 = guideline._id) != null ? _ref1 : "" + CADTH_GUIDELINE_DOC_PREFIX + (generateGUID());
      return CadthGuidelineDoc.at(mediator.project.id, docId).update(_.extend({
        _id: docId,
        docType: CADTH_GUIDELINE_DOC_TYPE
      }, guideline)).then((function(_this) {
        return function() {
          mediator.dialogs.success($.t('key_messages:guidelines.save_success'));
          return _this.actions.createOrUpdateSuccess();
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          errorHandlerWithMsg(err, $.t('key_messages:guidelines.save_error'));
          return _this.actions.createOrUpdateError();
        };
      })(this));
    },
    "delete": function(guidelineId) {
      return CadthGuidelineDoc.at(mediator.project.id, guidelineId)["delete"]().then(function() {
        return mediator.dialogs.success($.t('key_messages:guidelines.delete_success'));
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('key_messages:guidelines.delete_error'));
      });
    }
  }
});
