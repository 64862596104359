{ oneOf, bool } = PropTypes
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
ImportActions = require 'actions/import_actions'
ImportStore = require 'stores/import_store'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'

storeConnector =
  ImportStore: (Store) ->
    importType: Store.getImportType()
    isOpen: Store.isRevmanCsvConflictMethodDialogOpen()
    isResolving: Store.isRevmanCsvResolvingConflicts()
    selectedMethod: Store.getRevmanCsvSelectedConflictMethod()

RevmanCsvConflictMethodDialog = createReactClass
  displayName: 'RevmanCsvConflictMethodDialog'
  mixins: [Translation('projects:import_dialog.revman_csv_resolution_dialog')]

  propTypes:
    importType: oneOf(['project', 'question', 'outcome'])
    isOpen: bool.isRequired
    isResolving: bool.isRequired
    selectedMethod: oneOf(['merge', 'import_new'])

  selectMethod: (method) -> ->
    ImportActions.selectRevmanCsvConflictMethod method

  getDescription: ->
    { importType } = @props
    return '' if importType is 'project'
    @i18n "description_#{importType}s"

  getMethodLabel: (method) ->
    { importType } = @props
    return '' if importType is 'project'
    @i18n "options.#{method}_#{importType}s"

  render: ->
    { isOpen, isResolving, selectedMethod } = @props

    <Modal isOpen={isOpen} title={@i18n 'title'}>
      <p>{@getDescription()}</p>
      {_.map ['merge', 'import_new'], (optionValue) =>
        <div className="mb-10" key={optionValue}>
          <label onClick={@selectMethod optionValue} htmlFor={"resolution_method_#{optionValue}"}>
            <input
              checked={optionValue is selectedMethod}
              onChange={@selectMethod optionValue}
              type="radio"
              name={"resolution_method_#{optionValue}"}
              value={optionValue}
            />
            {@getMethodLabel optionValue}
          </label>
        </div>
      }
      <ApplyCancelButtons
        applyLabel={@i18n 'resolve'}
        cancelLabel={@i18n '/actions.cancel'}
        applying={isResolving}
        isSubmitEnabled={selectedMethod? and not isResolving}
        onApply={ImportActions.resolveRevmanCsvConflict}
        onCancel={ImportActions.cancelResolveRevmanCsvConflict}
      />
    </Modal>

module.exports = ConnectStore RevmanCsvConflictMethodDialog, ImportStore, storeConnector
