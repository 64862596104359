var Chaplin, DonationView, NewUserView, StartupService, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

NewUserView = require('views/new_user_view');

DonationView = require('views/donation_view');

Chaplin = require('chaplin');

mediator = require('mediator');

module.exports = StartupService = (function(_super) {
  __extends(StartupService, _super);

  function StartupService() {
    this.destroy = __bind(this.destroy, this);
    return StartupService.__super__.constructor.apply(this, arguments);
  }

  StartupService.prototype.namespace = 'startup';

  StartupService.prototype.initialState = 'idle';

  StartupService.prototype.initialize = function() {
    StartupService.__super__.initialize.apply(this, arguments);
    _(this).extend(Chaplin.EventBroker);
    return _(['sessionStarted', 'tosAccepted', 'walkthroughEnded', 'newUserScreenShown']).each((function(_this) {
      return function(event) {
        return _this.subscribeEvent(event, function() {
          return _this.handle(event);
        });
      };
    })(this));
  };

  StartupService.prototype.destroy = function() {
    this.clearQueue();
    return this.unsubscribeAllEvents();
  };

  StartupService.prototype.states = {
    idle: {
      sessionStarted: 'acceptingTos'
    },
    acceptingTos: {
      _onEnter: function() {
        return this.tosService.check();
      },
      tosAccepted: 'donationScreen'
    },
    newUserScreen: {
      _onEnter: function() {
        var view;
        if (Boolean(mediator.user.get('skipNewUserScreen'))) {
          return mediator.publish('newUserScreenShown');
        } else {
          view = new NewUserView();
          return view.promise().then(function() {
            return mediator.publish('newUserScreenShown');
          });
        }
      },
      newUserScreenShown: 'donationScreen'
    },
    donationScreen: {
      _onEnter: function() {
        var donationDialogSkipped, donationEnabled, view;
        donationEnabled = false;
        donationDialogSkipped = Boolean(mediator.user.get('skipDonationDialog'));
        if (!donationEnabled || donationDialogSkipped) {
          return mediator.publish('startupCompleted');
        } else {
          view = new DonationView;
          return view.promise().then(function() {
            return mediator.publish('startupCompleted');
          });
        }
      }
    },
    walkthrough: {
      _onEnter: function() {
        return this.walkthroughService.askToStart();
      },
      walkthroughEnded: 'startupCompleted'
    },
    startupCompleted: {
      _onEnter: function() {
        return mediator.publish('startupCompleted');
      }
    }
  };

  return StartupService;

})(machina.Fsm);
