LabelCell = require 'components/etd/etd_header_label_cell'
EtdHeaderEditableCell = require 'components/etd/etd_header_editable_cell'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

EtdHeaderRow = createReactClass
  displayName: 'EtdHeaderRow'
  mixins: [Translation('es:recommendations.header'), CustomRenderMixin]

  focusEditCell: ->
    if @props.isEditing
      @refs[@props.sectionId].focus()

  _getEditCellProps: (sectionProps) ->
    return {
      renderMode: @props.renderMode
      ref: sectionProps.sectionId
      content: sectionProps.cellContent
      sectionId: sectionProps.sectionId
      isEditing: sectionProps.isEditing
      inAutoMode: sectionProps.inAutoMode
      updateHeaderFieldContent: sectionProps.updateHeaderFieldContent
      hasSwitchButton: sectionProps.hasSwitchButton
      showContentModeSwitchModal: sectionProps.showContentModeSwitchModal
    }

  render: ->
    editCellProps = @_getEditCellProps @props
    trClassess = classNames 'switch-button': editCellProps.hasSwitchButton
    <tr className={trClassess}>
      <LabelCell text={@props.cellLabel} renderMode={@props.renderMode} />
      <EtdHeaderEditableCell {...editCellProps} />
    </tr>

module.exports = EtdHeaderRow
