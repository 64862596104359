var DnDActions, DragDropTypes;

DragDropTypes = require('components/decision_aid/drag_drop_types');

DnDActions = require('actions/react_dnd_actions');

module.exports = {
  dragSpec: {
    beginDrag: function(props, monitor, component) {
      return {
        chapterId: props.chapterId
      };
    },
    isDragging: function(props, monitor) {
      return props.chapterId === monitor.getItem().chapterId;
    },
    canDrag: R.T
  },
  dropSpec: {
    hover: function(props, monitor, component) {
      var draggingChapterId, hovereChapterId;
      hovereChapterId = props.chapterId;
      draggingChapterId = monitor.getItem().chapterId;
      if (hovereChapterId === draggingChapterId) {
        return;
      }
      return DnDActions.hover({
        type: DragDropTypes.CHAPTER_TITLE,
        hovereChapterId: hovereChapterId,
        draggingChapterId: draggingChapterId
      });
    },
    canDrop: R.F
  },
  dragCollect: function(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget()
    };
  }
};
