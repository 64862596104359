Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'

QuestionStatus = createReactClass
  displayName: 'QuestionStatus'
  mixins: [Translation('dbep:questions_list_screen.statuses')]
  propTypes:
    status: PropTypes.oneOf(['published', 'in_review', 'unpublished']).isRequired
    link: PropTypes.string

  render: ->
    { status, link } = @props

    <div className="dbep-table__cell status">
      <Tooltip>
        <div
          className="question-status"
          title={if status is 'in_review' then @i18n('in_review_description') else null}
        >
          <div className="question-status__status">
            <div className={"status__square #{status}"} />
            <div>{@i18n status}</div>
          </div>
          {link and <div className="question-status__link">
            <Tooltip>
              <a
                href={link}
                rel="noreferrer noopener"
                target="_blank"
                title={@i18n '../open_link'}
              />
            </Tooltip>
          </div>}
        </div>
      </Tooltip>
    </div>

module.exports = QuestionStatus
