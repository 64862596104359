CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ValidationContainer = require 'components/common/validation_container'
Input = require 'components/common/input'
{ patterns, messages } = require 'base/validation'


validateValue = (pattern, value) ->
  isValid = patterns[pattern].test value
  return [ true, null ] if isValid

  # TODO: this doesn't look like a solid solution
  validationErrorText = messages.pattern.replace '{1}', pattern
  [ false, validationErrorText ]

ValidatedInput = createReactClass
  displayName: 'ValidatedInput'

  propTypes:
    validation: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.oneOf(_.keys(patterns))
    ]).isRequired
    onValidationResult: PropTypes.func
    validateOnMount: PropTypes.bool.isRequired

  mixins: [
    CustomRenderMixin
  ]

  getDefaultProps: ->
    validateOnMount: false

  getInitialState: ->
    validationErrorText: null

  _ref: (el) ->
    @input = el

  _validate: ->
    { validation } = @props
    value = if @input.getAttribute('type') is 'checkbox'
      @input.checked
    else
      @input.value.trim()

    [ isValueValid, validationErrorText ] = if _.isFunction validation
      validation value
    else
      validateValue validation, value

    @setState validationErrorText: if isValueValid then null else validationErrorText
    @props.onValidationResult? isValueValid

  componentDidUpdate: (_prevProps, _prevState) ->
    @_validate()

  componentDidMount: ->
    @_validate() if @props.validateOnMount

  render: ->
    { validationErrorText } = @state
    props = _.omit @props, 'validation', 'onValidationResult', 'validateOnMount'

    <ValidationContainer errorMsg={validationErrorText}>
      <Input {...props} inputRef={@_ref}/>
    </ValidationContainer>

module.exports = ValidatedInput
