ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
VotingTeamComments = require 'components/etd/voting_team_comments'
ContentEditable = require 'components/common/content_editable'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
GdtEditor = Editor.Editor

VotesSummaryDialog = createReactClass
  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  editorRef: (el) ->
    @gdtEditor = el

  getAdminComment: ->
    @gdtEditor.getEditorContent()

  _getDraftJudgement: ->
    if @props.draftJudgement then @i18n "radio_values.#{@props.draftJudgement}" else @i18n 'n_a'

  _getSectionName: ->
    if @props.etdPart is 'conclusions'
      @props.sectionName
    else
      "#{@props.sectionName}:"

  render: ->
    <div className="voting-summary-dialog">
      <div className="section-details">
        <span className="section-name">{@_getSectionName()}</span>
        <span className="details-value">{@props.sectionDescription}</span>
      </div>
      <div className="draft-judgement-details">
        <span className="draft-judgement">{@i18n 'draft_judgement'}:</span>
        <span className="details-value">{@_getDraftJudgement()}</span>
      </div>
      <div className="vote-comments-title">{@i18n 'team_members_votes_comments'}</div>
      <VotingTeamComments
        displayMode="summary"
        etdPart={@props.etdPart}
        isProposedVoting={@props.isProposedVoting}
        votingResults={@props.votingResults}
        votingOptions={@props.votingOptions}
        actions={@props.actions}
        sectionId={@props.sectionId}
        hiddenVotes={@props.hiddenVotes}
      />
      <div className="admin-comment">
        <span>{@i18n 'summary'}</span>
        <GdtEditor
          ref={@editorRef}}
          editorContent={@props.adminComment}
        />
      </div>

      <ApplyCancelButtons
        onApply={@props.onApply}
        onCancel={@props.onCancel}
      />
    </div>

module.exports = VotesSummaryDialog
