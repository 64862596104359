var __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

_.namespace(module, function(require) {
  var ALL_MODULES, W, mediator, _modulesSpecsMap, _optional;
  mediator = require('mediator');
  W = require('when');
  ALL_MODULES = require('lib/modules_helper').ALL_MODULES;
  _modulesSpecsMap = (function() {
    var reducer;
    reducer = function(idPrefix) {
      return function(map, moduleSpec) {
        var id, moduleId, submodules;
        id = moduleSpec.id, submodules = moduleSpec.submodules;
        moduleId = idPrefix ? "" + idPrefix + id : id;
        map[moduleId] = moduleSpec;
        if (_.isEmpty(submodules)) {
          return map;
        }
        return _.reduce(submodules, reducer("" + id + "#"), map);
      };
    };
    return _.reduce(ALL_MODULES, reducer(), {});
  })();
  _optional = (function() {
    var moduleName, moduleSpec, _results;
    _results = [];
    for (moduleName in _modulesSpecsMap) {
      moduleSpec = _modulesSpecsMap[moduleName];
      if (moduleSpec.optional) {
        _results.push(moduleName);
      }
    }
    return _results;
  })();
  return {
    _getPresets: function() {
      return {
        cochrane: '*'
      };
    },
    _getModulesToEnable: function(moduleList) {
      return _(moduleList).chain().map(function(entry) {
        var module, submodule, _ref;
        if (__indexOf.call(entry, '#') >= 0) {
          _ref = entry.split('#'), module = _ref[0], submodule = _ref[1];
          return {
            module: module,
            submodule: submodule
          };
        } else {
          return {
            module: entry,
            submodule: '*'
          };
        }
      }).groupBy('module').map(function(v, k) {
        return [k, _(v).chain().pluck('submodule').compact().value()];
      }).object().value();
    },
    _getSubmoduleTree: function(submodules, submodulesToEnable) {
      return _(submodules).map(function(submodule) {
        var disabled, markEnabled, _ref;
        markEnabled = (_ref = submodule.id, __indexOf.call(submodulesToEnable, _ref) >= 0);
        disabled = submodule.missing || !(submodule.mandatory || markEnabled);
        return _(submodule).chain().omit('mandatory').extend({
          disabled: disabled
        }).value();
      });
    },
    _getModuleTree: function(modules, moduleList) {
      var modulesToEnable;
      modulesToEnable = this._getModulesToEnable(moduleList);
      return _(modules).map((function(_this) {
        return function(module) {
          var anySubmoduleEnabled, disabled, enabledSubmodules, markEnabled, submodules, toEnable, _ref;
          toEnable = (_ref = modulesToEnable[module.id]) != null ? _ref : [];
          enabledSubmodules = __indexOf.call(toEnable, '*') >= 0 ? _(module.submodules).pluck('id') : toEnable;
          submodules = _this._getSubmoduleTree(module.submodules, enabledSubmodules);
          anySubmoduleEnabled = _(submodules).findWhere({
            disabled: false
          });
          markEnabled = module.id in modulesToEnable || anySubmoduleEnabled;
          disabled = module.missing || !(module.mandatory || markEnabled);
          return _(module).chain().omit('mandatory').extend({
            submodules: submodules,
            disabled: disabled
          }).value();
        };
      })(this));
    },
    _getAllModules: function() {
      return this._getModuleTree(ALL_MODULES, _optional);
    },
    _hasRejectedAccessRights: function(moduleRejectedAccessRights) {
      var userAccessRights;
      if (!moduleRejectedAccessRights) {
        return false;
      }
      userAccessRights = mediator.user.getAccessRights();
      return _.intersection(moduleRejectedAccessRights, userAccessRights).length > 0;
    },
    _hasAccessRightsToModule: function(moduleAccessRights) {
      var userAccessRights;
      if (!moduleAccessRights) {
        return true;
      }
      userAccessRights = mediator.user.getAccessRights();
      return _.intersection(moduleAccessRights, userAccessRights).length > 0;
    },
    _rejectHiddenModules: function(modulesList) {
      return _.reject(modulesList, (function(_this) {
        return function(moduleName) {
          var accessRights, featureSwitch, featureSwitchOrServerSwitchInterchangeable, hiddenInMainProject, hiddenInWorkspace, hiddenWithFeatureSwitches, hideInMainProject, hideInWorkspace, hideWithFeatureSwitches, missesFeatureSwitch, missesOrganizationSwitch, missesServerSwitch, missesSwitch, missing, organizationFeatureSwitch, rejectedAccessRights, serverFeatureSwitches, showInWorkspace, _ref;
          _ref = _modulesSpecsMap[moduleName], missing = _ref.missing, featureSwitch = _ref.featureSwitch, organizationFeatureSwitch = _ref.organizationFeatureSwitch, serverFeatureSwitches = _ref.serverFeatureSwitches, accessRights = _ref.accessRights, hideInWorkspace = _ref.hideInWorkspace, hideInMainProject = _ref.hideInMainProject, hideWithFeatureSwitches = _ref.hideWithFeatureSwitches, featureSwitchOrServerSwitchInterchangeable = _ref.featureSwitchOrServerSwitchInterchangeable, showInWorkspace = _ref.showInWorkspace, rejectedAccessRights = _ref.rejectedAccessRights;
          missesSwitch = (featureSwitch != null) && !mediator.services.switches.isOn(featureSwitch);
          missesOrganizationSwitch = (organizationFeatureSwitch != null) && (mediator.activeWorkspace != null) && mediator.activeWorkspace !== 'personal' && !mediator.services.switches.isOn(organizationFeatureSwitch);
          missesServerSwitch = (serverFeatureSwitches != null) && _.every(serverFeatureSwitches, function(sfs) {
            return !mediator.services.switches.isServerSwitchOn(sfs);
          });
          missesFeatureSwitch = featureSwitchOrServerSwitchInterchangeable ? missesSwitch && missesOrganizationSwitch && missesServerSwitch : missesSwitch || missesOrganizationSwitch || missesServerSwitch;
          hiddenInMainProject = _.contains(hideInMainProject, mediator.project.id);
          hiddenInWorkspace = _.contains(hideInWorkspace, mediator.activeWorkspace);
          hiddenWithFeatureSwitches = (hideWithFeatureSwitches != null) && _.some(hideWithFeatureSwitches, function(fs) {
            return mediator.services.switches.isOn(fs);
          });
          return missing || missesFeatureSwitch || hiddenInWorkspace || hiddenInMainProject || hiddenWithFeatureSwitches || !_this._hasAccessRightsToModule(accessRights) || _this._hasRejectedAccessRights(rejectedAccessRights) || !_.isEmpty(showInWorkspace) && !_.contains(showInWorkspace, mediator.activeWorkspace);
        };
      })(this));
    },
    enableModulesForPreset: function(preset, project) {
      var modulesToEnable, presetModules, presets;
      presets = this._getPresets();
      if (!(preset in presets)) {
        return W.resolve(project);
      }
      presetModules = presets[preset];
      modulesToEnable = presetModules === '*' ? '*' : this._getModuleTree(ALL_MODULES, presetModules);
      return this._setPresetSpecificSettings(preset, project).then(function() {
        return project.save('modules', modulesToEnable);
      });
    },
    _setPresetSpecificSettings: function(preset, project) {
      if (preset !== 'cochrane') {
        return W.resolve();
      }
      return W.all([mediator.services.esView.savePreference(project, false, 'SOF', true), mediator.services.esView.savePreference(project, 'diagnostic', 'LayerOneSof', true)]);
    },
    getDefaultAttributes: function() {
      return {
        modules: '*'
      };
    },
    getProjectModules: function(project) {
      if (mediator.user.get('uberAdmin')) {
        return this._getAllModules();
      }
      if (project.get('modules') === '*') {
        return this._getAllModules();
      } else {
        return JSON.parse(JSON.stringify(project.get('modules')));
      }
    },
    getVisibleProjectModules: function(project) {
      var allVisibleModulesList, isVisible;
      if (mediator.user.get('uberAdmin')) {
        return this._getAllModules();
      }
      allVisibleModulesList = this._rejectHiddenModules(_(_modulesSpecsMap).keys());
      isVisible = function(query) {
        return __indexOf.call(allVisibleModulesList, query) >= 0;
      };
      return _(this.getProjectModules(project)).chain().filter(function(module) {
        return isVisible(module.id);
      }).map(function(module) {
        if (module.submodules) {
          module.submodules = module.submodules.filter(function(submodule) {
            return isVisible("" + module.id + "#" + submodule.id);
          });
        }
        return module;
      }).value();
    },
    _isOn: function(query, project, getFn) {
      var module, moduleId, submodule, submoduleId, _ref;
      _ref = query.split('#'), moduleId = _ref[0], submoduleId = _ref[1];
      module = _(getFn(project)).findWhere({
        id: moduleId
      });
      if (!module) {
        return false;
      }
      if (submoduleId) {
        submodule = _(module.submodules).findWhere({
          id: submoduleId
        });
        if (!submodule) {
          return false;
        }
        return !submodule.disabled;
      } else {
        return !module.disabled;
      }
    },
    isEnabled: function(query, project) {
      return this._isOn(query, project, this.getProjectModules);
    },
    isVisible: function(query, project) {
      return this._isOn(query, project, this.getVisibleProjectModules);
    }
  };
});
