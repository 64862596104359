var Exceptions, PUBLICATION_PLATFORM_PUBLICATION_STATUS_DOC_ID, PublicationPlatformApi, PublicationStatusDoc, QUESTION_GROUPS_DOC_ID, QuestionDoc, W, appUtils, callMethod, generateActions, mediator, sequence;

appUtils = require('lib/app_utils');

callMethod = require('base/lib/utils').callMethod;

Exceptions = require('lib/exceptions');

generateActions = require('actions/utils/generate_actions');

mediator = require('mediator');

PublicationPlatformApi = require('actions/async/publication_platform_api');

PUBLICATION_PLATFORM_PUBLICATION_STATUS_DOC_ID = require('lib/doc_ids').PUBLICATION_PLATFORM_PUBLICATION_STATUS;

PublicationStatusDoc = require('lib/db_docs/publication_platform_status_doc');

QuestionDoc = require('lib/db_docs/question_doc');

QUESTION_GROUPS_DOC_ID = require('lib/questions_helper').QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE.regular;

sequence = require('when/sequence');

W = require('when/when');

module.exports = generateActions({
  autoGenerate: ['changeStatusFilter', 'closeModal', 'fetchQuestionsError', 'fetchQuestionsSuccess', 'fetchStatusesError', 'fetchStatusesSuccess', 'publishError', 'publishSuccess', 'setPublishGuidelineProgressMaxCount', 'setSearchText', 'updatePublishGuidelineProgress'],
  name: 'PublicationPlatformActions',
  actions: {
    dbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      if (doc._id !== PUBLICATION_PLATFORM_PUBLICATION_STATUS_DOC_ID) {
        return;
      }
      return this.dispatch(doc);
    },
    fetch: function() {
      this.dispatch();
      this.actions.fetchQuestions();
      return this.actions.fetchStatuses();
    },
    fetchQuestions: function() {
      var groupsPromise, questionsPromise;
      questionsPromise = QuestionDoc.at(mediator.project.id).fetchMany().then(function(questions) {
        return _.reduce(questions, function(acc, question) {
          acc[question._id] = question;
          return acc;
        }, {});
      });
      groupsPromise = mediator.services.storePersistenceAdapter.fetch(mediator.project.id, QUESTION_GROUPS_DOC_ID)["catch"](function(err) {
        if (err.status !== 404) {
          throw err;
        }
        return {};
      });
      return W.all([questionsPromise, groupsPromise]).then((function(_this) {
        return function(_arg) {
          var questionGroups, questions;
          questions = _arg[0], questionGroups = _arg[1];
          return _this.actions.fetchQuestionsSuccess({
            questions: questions,
            questionGroups: questionGroups
          });
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('dissemination:publication_platform.fetch_question_error'));
          return _this.actions.fetchQuestionsError();
        };
      })(this));
    },
    fetchStatuses: function() {
      return PublicationStatusDoc.at(mediator.project.id).fetch()["catch"](function(err) {
        if (err.status !== 404) {
          throw err;
        }
        return {};
      }).then(this.actions.fetchStatusesSuccess)["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('dissemination:publication_platform.fetch_statuses_error'));
          return _this.actions.fetchStatusesError();
        };
      })(this));
    },
    openModal: function(params) {
      var err;
      if (mediator.services.replication.isConnected()) {
        return this.dispatch(params);
      } else {
        err = new Exceptions.no_connection;
        return mediator.dialogs.error(err);
      }
    },
    publishGuideline: function(preview) {
      var projectId, updateDate, updateDateISO, updateTimestamp;
      if (preview == null) {
        preview = false;
      }
      this.dispatch();
      projectId = mediator.project.id;
      updateDate = new Date();
      updateTimestamp = updateDate.getTime();
      updateDateISO = updateDate.toISOString();
      return mediator.services.documentSectionsExportService["export"]({
        projectId: projectId
      }).then((function(_this) {
        return function(_arg) {
          var chapters, guidelineDoc, questionIds, sections;
          guidelineDoc = _arg[0], chapters = _arg[1], sections = _arg[2];
          questionIds = _.chain(sections).where({
            type: 'recommendation'
          }).map(function(_arg1) {
            var questionId;
            questionId = _arg1.recommendationData.questionId;
            return questionId;
          }).value();
          return mediator.services.publicationPlatformExporter["export"](projectId, questionIds).then(function(recommendations) {
            var guidelinePayload, recommendationsToUpdateStatusFor;
            recommendationsToUpdateStatusFor = _.pluck(recommendations, '@id');
            _this.actions.setPublishGuidelineProgressMaxCount(1 + chapters.length + sections.length + recommendations.length);
            guidelinePayload = {
              allChaptersIds: _.pluck(chapters, 'id'),
              allSectionIds: _.pluck(sections, 'id'),
              guideline: _.extend({}, guidelineDoc, {
                lastModified: updateDateISO
              })
            };
            return PublicationPlatformApi.publishDoc('guideline', projectId, guidelineDoc.id, guidelinePayload, preview).then(function() {
              _this.actions.updatePublishGuidelineProgress();
              return sequence(_.map(chapters, function(chapter) {
                return function() {
                  var chapterPayload;
                  chapterPayload = _.extend({}, chapter, {
                    lastModified: updateDateISO
                  });
                  return PublicationPlatformApi.publishDoc('chapter', projectId, chapter.id, chapterPayload, preview).then(function() {
                    return _this.actions.updatePublishGuidelineProgress();
                  });
                };
              }));
            }).then(function() {
              return sequence(_.map(sections, function(section) {
                return function() {
                  var sectionPayload;
                  sectionPayload = _.extend({}, section, {
                    lastModified: updateDateISO
                  });
                  return PublicationPlatformApi.publishDoc('section', projectId, section.id, sectionPayload, preview).then(function() {
                    return _this.actions.updatePublishGuidelineProgress();
                  });
                };
              }));
            }).then(function() {
              return sequence(_.map(recommendations, function(recommendation) {
                return function() {
                  var recommendationPayload;
                  recommendationPayload = _.extend({}, recommendation, {
                    lastModified: updateDateISO
                  });
                  return PublicationPlatformApi.publishDoc('recommendation', projectId, recommendation['@id'], recommendationPayload, preview).then(function() {
                    return _this.actions.updatePublishGuidelineProgress();
                  });
                };
              }));
            }).then(function() {
              if (preview) {
                return W.resolve();
              }
              return PublicationStatusDoc.at(mediator.project.id).updateAsInstance(callMethod('updateGuidelinePublicationDate', updateTimestamp, recommendationsToUpdateStatusFor));
            }).then(function() {
              _this.actions.publishSuccess();
              return mediator.dialogs.success($.t('dissemination:publication_platform.publish_guideline_success'));
            });
          })["catch"](function(err) {
            _this.actions.publishError();
            return appUtils.errorHandlerWithMsg(err, $.t('dissemination:publication_platform.publish_guideline_error'));
          });
        };
      })(this));
    },
    publishQuestion: function(questionId, preview) {
      var projectId, updateDate, updateDateISO, updateTimestamp;
      if (preview == null) {
        preview = false;
      }
      this.dispatch();
      updateDate = new Date();
      updateTimestamp = updateDate.getTime();
      updateDateISO = updateDate.toISOString();
      projectId = mediator.project.id;
      return mediator.services.publicationPlatformExporter["export"](projectId, [questionId]).then((function(_this) {
        return function(_arg) {
          var recommendation, recommendationPayload;
          recommendation = _arg[0];
          if (!recommendation) {
            _this.actions.publishQuestionError(new Error('noNecessaryData'));
            return;
          }
          recommendationPayload = _.extend({}, recommendation, {
            lastModified: updateDateISO
          });
          return PublicationPlatformApi.publishDoc('recommendation', projectId, recommendation['@id'], recommendationPayload, preview).then(function() {
            if (preview) {
              return W.resolve();
            }
            return PublicationStatusDoc.at(mediator.project.id).updateAsInstance(callMethod('updateQuestionPublicationDate', updateTimestamp, recommendation['@id']));
          }).then(function() {
            _this.actions.publishSuccess();
            return mediator.dialogs.success($.t('dissemination:publication_platform.publish_question_success'));
          });
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          _this.actions.publishError();
          return appUtils.errorHandlerWithMsg(err, $.t('dissemination:publication_platform.publish_question_error'));
        };
      })(this));
    }
  }
});
