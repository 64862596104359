var PARAMS_VALIDATORS, REQUIRED_PARAMS, SERVER_URL, W, addFooterWithLink, ajax, getEmailBodyForSending, invalidArgumentsErr, mediator, rawContentToHTML, validateArgs, _ref, _validatorFn;

W = require('when');

mediator = require('mediator');

SERVER_URL = require('base/lib/db_helper').getBackendUrl();

rawContentToHTML = require('lib/draft_utils').rawContentToHTML;

_ref = require('actions/async/api_helpers'), invalidArgumentsErr = _ref.invalidArgumentsErr, ajax = _ref.ajax;

REQUIRED_PARAMS = {
  startVoting: ['projectId', 'memberIds', 'questionEtds', 'emailBody', 'contactEmail', 'parts']
};

PARAMS_VALIDATORS = {
  projectId: function(id) {
    return _.isString(id) && !_.isEmpty(id);
  },
  memberIds: function(ids) {
    return _.isArray(ids) && !_.isEmpty(ids);
  },
  questionEtds: function(ids) {
    return !_.isEmpty(ids);
  },
  emailBody: function(message) {
    return Immutable.Map.isMap(message);
  },
  contactEmail: function(email) {
    return !_.isEmpty(email);
  },
  parts: function(parts) {
    return !_.isEmpty(parts);
  }
};

_validatorFn = function(params) {
  return function(validationResult, paramName) {
    if (!PARAMS_VALIDATORS[paramName](params[paramName])) {
      validationResult.valid = false;
      validationResult.invalidArgs[paramName] = params[paramName];
    }
    return validationResult;
  };
};

addFooterWithLink = function(emailBody) {
  return "" + emailBody + "\n <p>" + ($.t('voting:voting.modals.please_follow_link')) + "</p>\n <p>{#link}</p>";
};

getEmailBodyForSending = function(emailBody) {
  emailBody = rawContentToHTML(emailBody);
  return addFooterWithLink(emailBody);
};

validateArgs = function(fnName, params) {
  var valResult;
  if (_.isEmpty(params)) {
    return {
      valid: false
    };
  }
  valResult = REQUIRED_PARAMS[fnName].reduce(_validatorFn(params), {
    valid: true,
    invalidArgs: {}
  });
  if (!_.isEmpty(valResult.invalidArgs)) {
    console.error('invalid arguments:', valResult.invalidArgs);
  }
  return valResult.valid;
};

module.exports = {
  sendVoting: function(data) {
    var emailBody, params, projectId;
    ({
      startVoting: ['projectId', 'memberIds', 'questionEtds', 'emailBody', 'contactEmail', 'parts']
    });
    if (!validateArgs('startVoting', data)) {
      return invalidArgumentsErr();
    }
    projectId = data.projectId, emailBody = data.emailBody;
    params = _.omit(data, 'projectId', 'emailBody');
    params = _.extend({}, params, {
      messageBody: getEmailBodyForSending(emailBody)
    });
    return ajax({
      url: "" + SERVER_URL + "/panel-voice/" + projectId,
      type: 'POST',
      data: JSON.stringify(params),
      xhrFields: {
        withCredentials: true
      }
    });
  },
  closeVoting: function(data) {
    var etdId, projectId;
    projectId = data.projectId, etdId = data.etdId;
    return ajax({
      url: "" + SERVER_URL + "/panel-voice/" + projectId + "/" + etdId + "/_close",
      type: 'POST',
      data: JSON.stringify({}),
      xhrFields: {
        withCredentials: true
      }
    });
  },
  sendReminders: function(data, etdId) {
    var messageBody, params, projectId, url, urlBase;
    projectId = data.projectId, messageBody = data.messageBody;
    params = _.chain(data).omit('projectId').extend({
      messageBody: addFooterWithLink(messageBody)
    }).value();
    urlBase = "" + SERVER_URL + "/panel-voice/" + projectId;
    url = etdId ? urlBase + ("/" + etdId + "/reminders") : urlBase + "/reminders";
    return ajax({
      url: url,
      type: 'POST',
      data: JSON.stringify(params),
      xhrFields: {
        withCredentials: true
      }
    });
  }
};
