var Migration, ORGANIZATION_DATA_DOCUMENT, OrganizationProjectsOrganizationId, W, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

ORGANIZATION_DATA_DOCUMENT = require('lib/doc_prefixes').organizationsData;

W = require('when');

module.exports = OrganizationProjectsOrganizationId = (function(_super) {
  __extends(OrganizationProjectsOrganizationId, _super);

  function OrganizationProjectsOrganizationId() {
    OrganizationProjectsOrganizationId.__super__.constructor.call(this, false);
  }

  OrganizationProjectsOrganizationId.prototype.up = function(project, colls) {
    var adapter, projectId;
    OrganizationProjectsOrganizationId.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    if (project.organizationId != null) {
      return W.resolve();
    }
    return adapter.fetch(projectId, ORGANIZATION_DATA_DOCUMENT).then(function(doc) {
      return project.save('organizationId', doc.organizationId);
    })["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
    });
  };

  return OrganizationProjectsOrganizationId;

})(Migration);
