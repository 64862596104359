ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ReferenceEditModal = require 'components/references/reference_edit_modal'
ReferencesActions = require 'actions/references_actions'
ReferencesImportView = require 'views/references_import_view'
ReferencesHeader = require 'components/references/references_header'
ReferencesStore = require 'stores/references_store'
ReferencesTable = require 'components/references/references_table'

storeConnectors =
  ReferencesStore: (ReferencesStore) ->
    hasEverFetched: ReferencesStore.hasEverFetched()
    isFetching: ReferencesStore.isFetching()
    references: ReferencesStore.getReferencesWithSearch()
    sortedBy: ReferencesStore.getSortedBy()
    sortAsc: ReferencesStore.getSortAsc()
    activeSearch: ReferencesStore.getActiveSearch()
    selectedReferenceId: ReferencesStore.getSelectedReferenceId()
    editingReference: ReferencesStore.getEditingReference()
    duplicatesCount: ReferencesStore.getDuplicatesCount()
    usedReferences: ReferencesStore.getUsedReferences()
    usedReferencesFetchTime: ReferencesStore.getUsedReferencesFetchTime()
    isFetchingUsedReferences: ReferencesStore.isFetchingUsedReferences()

References = createReactClass
  displayName: 'ReferencesComponent'

  mixins: [ CustomRenderMixin ]

  _listRef: (el) ->
    @list = el

  onImport: ->
    new ReferencesImportView importReferences: @importReferences

  importReferences: (refSpecs) ->
    ReferencesActions.importReferences refSpecs

  onSaveEditing: (refSpec) ->
    { editingReference, references } = @props
    refId = editingReference.get '_id', null
    ReferencesActions.saveReference refSpec, refId, references
    ReferencesActions.toggleReferenceEdit()

  removeReference: ->
    { selectedReferenceId } = @props
    reference = @props.references.find (ref) -> ref.get('_id') is selectedReferenceId
    ReferencesActions.removeReference reference

  removeAllReferences: ->
    ReferencesActions.removeReferences @props.references.toJS()

  onSearch: (searchText) ->
    @list?.scrollToPosition 0
    ReferencesActions.search searchText

  render: ->
    <div className='references-container'>
      <ReferencesHeader
        activeSearch={@props.activeSearch}
        duplicatesCount={@props.duplicatesCount}
        isFetchingUsedReferences={@props.isFetchingUsedReferences}
        onAddReference={ReferencesActions.addNewReference}
        onDeleteAll={if @props.references.isEmpty() then undefined else @removeAllReferences}
        onFetchUsage={ReferencesActions.fetchUsedReferences}
        onImportReferences={@onImport}
        onSearch={@onSearch}
        onSearchReset={ReferencesActions.resetSearch}
        usedReferencesFetchTime={@props.usedReferencesFetchTime}
      />
      <div className='table-container'>
        <ReferencesTable
          {...@props}
          listRef={@_listRef}
          sortByColumn={ReferencesActions.sortByColumn}
          toggleReferenceSelection={ReferencesActions.toggleReferenceSelection}
        />
        {if @props.editingReference?
          <ReferenceEditModal
            action={if @props.editingReference.get('_id')? then 'edit' else 'add'}
            data={@props.editingReference}
            isOpen
            onClose={ReferencesActions.toggleReferenceEdit}
            onSave={@onSaveEditing}
          />
        }
      </div>
    </div>

module.exports = ConnectStore References, ReferencesStore, storeConnectors
