ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
ACPVisualGuidelinesStore = require 'stores/acp_visual_guidelines_store'
ChaptersList = require 'components/document_sections/v2/chapters_list'
ConnectStore = require 'components/enhancers/connect_store'
DocumentSectionsV2Store = require 'stores/document_sections_v2_store'
InteractiveTable = require 'components/dissemination/acp_visual_guidelines/interactive_table'
VisualizationPreview =
  require 'components/dissemination/acp_visual_guidelines/visualization_preview'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
{ useI18n, useCoffeeMemo } = require 'lib/react_utils'
{ MANUAL_REC_PREFIX } = require 'lib/etd_helper'
{ Tab, TabsContainer } = require 'components/common/tabs_container'

storeConnector =
  ACPVisualGuidelinesStore: (Store) ->
    activeTab: Store.getActiveTab()
    visualization: Store.getCurrentVisualization()
    tableData: Store.getInteractiveTableData()
  DocumentSectionsV2Store: (Store) ->
    rootChaptersOrder: Store.getRootChaptersOrder()
    chapters: Store.getChapters()
    scrollToSectionContent: Store.getScrollToSectionContent()
    populationsSection: Store.getSectionBySectionType('acp_recommendations_populations')
  QuestionGroupsStore: (Store) ->
    isFetchingQuestionGroups: Store.isFetching()
    questionGroups: Store.getQuestionGroups()
  QuestionsStore: (Store) ->
    isFetchingQuestions: Store.isFetching()
    questions: Store.getQuestions()

VisualGuidelinesDetails = ({
  activeTab
  chapters
  itemId
  nmaList
  populationsSection
  questionGroups
  questions
  rootChaptersOrder
  scrollToSectionContent
  tableData
  visualization
}) ->

  i18n = useI18n('')

  recIdsToBeFetchedFromStore = useCoffeeMemo [populationsSection], -> 
    populationsSection.getIn(['additionalData', 'populations'], Immutable.Map())
    .map (pop) -> pop.get('recommendations', Immutable.List())
    .valueSeq()
    .flatten()
    .filter (recId) -> recId.indexOf(MANUAL_REC_PREFIX) is -1 # don't include manual recommendations
      # use only the first part of the recommendation id beacuse the second part is
      # the recommendationId that comes from overarching question multirecommendations
    .map (recId) -> recId.split('_')[0]
    .toSet()
    .toJS()

  return null if visualization.isEmpty()
  <div id="visual-guidelines">
    <TabsContainer
      activeTab={activeTab}
      changeTab={ACPVisualGuidelinesActions.changeTab}
      fitToScreenBottom
      withTabsPane
    >
      <Tab tabName='document' tabLabel={i18n 'vi:tabs.document'}>
        <div className="document-sections-v2 h-full overflow-hidden">
          <ChaptersList
            view={'visual-guidelines'}
            chapters={chapters}
            chaptersOrder={rootChaptersOrder}
            questionGroups={questionGroups}
            questions={questions}
            scrollToSectionContent={scrollToSectionContent}
          />
        </div>
      </Tab>
      <Tab tabName='table' tabLabel={i18n 'vi:tabs.table'}>
        <InteractiveTable
          recIdsToBeFetchedFromStore={recIdsToBeFetchedFromStore}
          populationsSection={populationsSection}
          tableData={tableData}
        />
      </Tab>
      <Tab tabName='preview' tabLabel={i18n 'vi:tabs.preview'}>
        <VisualizationPreview
          recIdsToBeFetchedFromStore={recIdsToBeFetchedFromStore}
          populationsSection={populationsSection}
          tableData={tableData}
          itemId={itemId}
        />
      </Tab>
    </TabsContainer>
  </div>


VisualGuidelinesDetails.propTypes =
  rootChaptersOrder: PropTypes.instanceOf(Immutable.List).isRequired
  chapters: PropTypes.instanceOf(Immutable.Map).isRequired
  visualization: PropTypes.instanceOf(Immutable.Map).isRequired
  tableData: PropTypes.instanceOf(Immutable.Map).isRequired
  itemId: PropTypes.string.isRequired

module.exports = ConnectStore VisualGuidelinesDetails, [
  ACPVisualGuidelinesStore
  DocumentSectionsV2Store
  QuestionGroupsStore
  QuestionsStore
], storeConnector
