{ instanceOf, string, number } = PropTypes
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
PanelVoiceActions = require 'actions/panel_voice_actions'
PanelVoiceStore = require 'stores/panel_voice_store'
MembersStore = require 'stores/members_store'
Reminder = require './modals/reminder_modal'
Translation = require 'components/mixins/translation'

storeConnector =
  PanelVoiceStore: (Store, props) ->
    openedRemindersModal: Store.getOpenedRemindersModal()
    votedMemberIds: Store.getVotedMemberIds props.questionId
    sentMemberIds: Store.getSentMemberIds props.questionId
  MembersStore: (Store) ->
    panelMembers: Store.getPanelMembers().toList()

Responses = createReactClass
  displayName: 'Responses'

  propTypes:
    questionId: string.isRequired
    panelMembers: instanceOf(Immutable.List).isRequired
    votingStages: instanceOf(Immutable.List).isRequired
    votedMemberIds: instanceOf(Immutable.List)
    sentMemberIds: instanceOf(Immutable.List)
    lastVotingResults: instanceOf(Immutable.Map)
    openedRemindersModal: string

  mixins: [
    Translation('voting:voting'), CustomRenderMixin
  ]

  componentWillMount: ->
    @nonTestStages = @_getNonTestVotingStages @props
    @lastNonTestStage = @nonTestStages.last()

  componentWillReceiveProps: (nextProps) ->
    @nonTestStages = @_getNonTestVotingStages nextProps
    @lastNonTestStage = @nonTestStages.last()

  _getNonTestVotingStages: (props) ->
    props.votingStages.filter (stage) ->
      stage.get('status') isnt 'test'

  getResponsesStats: ->
    { lastVotingResults } = @props
    return '-' if @nonTestStages.isEmpty()
    sentTo = @lastNonTestStage.get('members').size
    responded = lastVotingResults.get('results')?.size ? 0
    <div>
      <div>{"#{responded}/#{sentTo}"}</div>
      {if sentTo isnt responded
        <span>{@i18n 'modals.send_reminder'}</span>
      }
    </div>

  getEtdId: ->
    @props.lastVotingResults?.get('etdId')

  _isLastStageClosed: ->
    @lastNonTestStage?.get('status') is 'closed'

  _isVotingSentToAllMembers: ->
    @props.panelMembers.size is @props.sentMemberIds.size

  _didAllMembersRespond: ->
    @props.votedMemberIds.size is @props.sentMemberIds.size

  _reminderDisabled: ->
    { votingStages } = @props

    votingStages.isEmpty() or
      votingStages.last().get('status') is 'test' or
      @_isLastStageClosed() or
      (@_isVotingSentToAllMembers() and @_didAllMembersRespond())

  showReminderModal: ->
    if not @_reminderDisabled()
      PanelVoiceActions.showRemindersModal @getEtdId()

  closeReminderModal: ->
    PanelVoiceActions.hideRemindersModal()

  sendReminders: (etdId, memberIds, messageBody) ->
    PanelVoiceActions.sendReminders memberIds, messageBody, etdId

  renderReminderModal: ->
    { votingStages, panelMembers } = @props
    etdId = @getEtdId()

    if not votingStages.isEmpty() and @props.openedRemindersModal is etdId
      <Reminder
        panelMembers={panelMembers}
        sentMemberIds={@props.sentMemberIds}
        votedMemberIds={@props.votedMemberIds}
        etdId={etdId}
        onSend={@sendReminders}
        onClose={@closeReminderModal}
      />

  render: ->
    className = classNames 'responses-active': not @_reminderDisabled()
    <div className={className}>
      <div onClick={@showReminderModal}>
        {@getResponsesStats()}
      </div>
      {@renderReminderModal()}
    </div>

module.exports = ConnectStore Responses, [PanelVoiceStore, MembersStore], storeConnector
