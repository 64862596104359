ConnectStore = require 'components/enhancers/connect_store'
DropdownMenu = require 'components/common/dropdown_menu'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
NMAImportModal = require 'components/nma/nma_import_modal'
NMAInterventionManagementModal = require 'components/nma/nma_intervention_management_modal'
NMAImportModalStore = require 'actions/nma_import_modal_actions'
NMAOutcomeModal = require 'components/nma/nma_outcome_modal'
NMAQuestionActions = require 'actions/nma_question_actions'
NMAQuestionStore = require 'stores/nma_question_store'
Router = require 'router'
Spinner = require 'components/common/spinner'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'
{ DndProvider, useDrag, useDrop } = ReactDnD
{ useRef } = React

NMA_OUTCOME_DND_TYPE = 'nma_outcome'

NMAOutcomeInQuestion = ({ index, outcomeId, outcomes, questionId }) ->
  i18n = useI18n('nma:question.outcomes')

  outcome = outcomes.get(outcomeId)
  outcomeName = outcome?.get('name') ? ''
  fileName = outcome?.get 'fileName'

  onMenuSelect = useCoffeeCallback [outcome, outcomeName], (option) ->
    switch option
      when 'edit'
        NMAQuestionActions.openOutcomeModal outcome.toJS()
      when 'delete'
        confirmOptions =
          title: i18n 'confirm_delete.title'
          message: i18n 'confirm_delete.message', { outcomeName }
          confirmText: i18n '/actions.delete'
          declineText: i18n '/actions.cancel'
          yesClass: 'danger'
        mediator.dialogs.confirm confirmOptions, (confirmed) ->
          return unless confirmed
          NMAQuestionActions.deleteOutcome outcome.toJS()

  onClick = useCoffeeCallback [outcome, questionId], ->
    outcomeId = outcome?.get '_id'
    return unless outcomeId
    route = Router::getProjectRelativeUrl "/nma/#{questionId}/outcomes/#{outcomeId}"
    mediator.publish '!router:route', route

  onFileNameClick = useCoffeeCallback [], (evt) ->
    evt.stopPropagation()

  onUpload = useCoffeeCallback [outcome], (evt) ->
    evt.stopPropagation()
    NMAImportModalStore.openImportDialog outcome

  ref = useRef null
  dragRef = useRef null

  [{ isDragging }, drag, dragPreview] = useDrag
    item: { index, outcomeId, type: NMA_OUTCOME_DND_TYPE }
    collect: (monitor) ->
      isDragging: monitor.isDragging()

  [{ isOver }, drop] = useDrop
    accept: NMA_OUTCOME_DND_TYPE
    collect: (monitor) ->
      isOver: monitor.isOver()
    drop: ->
      NMAQuestionActions.saveMovedOutcome questionId
    hover: (item, monitor) ->
      return unless ref.current
      return if item.index is index
      hoverBoundingRect = ref.current.getBoundingClientRect()
      hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      clientOffset = monitor.getClientOffset()
      hoverClientY = clientOffset.y - hoverBoundingRect.top
      return if item.index < index and hoverClientY < hoverMiddleY
      return if item.index > index and hoverClientY > hoverMiddleY
      placementDelta = if item.index < index then 1 else 0
      NMAQuestionActions.moveOutcome
        hoverOutcomeId: outcomeId
        movingOutcomeId: item.outcomeId
        placementDelta: placementDelta
      item.index = index

  drag dragRef
  drop dragPreview ref

  return null unless outcome

  className = classNames 'outcome-in-question',
    dragging: isDragging
    over: isOver

  <div className={className} ref={ref} onClick={onClick}>
    <div className="drag-marker" ref={dragRef} />
    <Tooltip>
      <div className="outcome-in-question__name" title={outcomeName}>
        {outcomeName}
      </div>
    </Tooltip>
    {if fileName
      <Tooltip>
        <div
          className="outcome-in-question__file-name"
          onClick={onFileNameClick}
          title={fileName}
        >
          <div>{fileName}</div>
        </div>
      </Tooltip>
    else
      <Tooltip>
        <IconButton
          className="outcome-in-question__upload-file"
          iconName="archive"
          onClick={onUpload}
          title={i18n '/actions.import'}
        />
      </Tooltip>
    }
    <DropdownMenu
      menuPosition={alignment: 'end'}
      onSelect={onMenuSelect}
      opener={<IconButton className="outcome-in-question__menu" iconName="more" />}
      options={[
        className: 'edit'
        text: i18n '/actions.edit'
        value: 'edit'
      ,
        className: 'delete'
        text: i18n '/actions.delete'
        value: 'delete'
      ]}
    />
  </div>

NMAOutcomeInQuestion.propTypes =
  index: PropTypes.number.isRequired
  outcomeId: PropTypes.string.isRequired
  outcomes: PropTypes.instanceOf(Immutable.Map).isRequired
  questionId: PropTypes.string.isRequired

NMAQuestion = ({ isFetching, outcomes, question }) ->
  i18n = useI18n 'nma:question'

  onAddOutcome = useCoffeeCallback [], ->
    NMAQuestionActions.openOutcomeModal()

  onOpenInterventionManagementModal = useCoffeeCallback [], ->
    NMAQuestionActions.openInterventionManagementModal()

  outcomeIds = question.get 'outcomes', Immutable.List()
  questionId = question.get '_id', ''

  return <Spinner /> if isFetching

  <div className="nma-question">
    <div className="nma-question__header">
      <h3>{i18n 'outcomes.title'}</h3>
      <div className="header__controls">
        <button
          className="btn btn-apply-inverted"
          onClick={onOpenInterventionManagementModal}
        >
          {i18n 'manage_interventions'}
        </button>
        <button
          className="btn btn-apply"
          onClick={onAddOutcome}
        >
          {i18n '/actions.add_outcome'}
        </button>
      </div>
    </div>
    <div className="nma-question__outcomes">
      <DndProvider backend={ReactDnDHTML5Backend.default}>
        {outcomeIds.map (outcomeId, index) ->
          <NMAOutcomeInQuestion
            key={outcomeId}
            index={index}
            outcomeId={outcomeId}
            outcomes={outcomes}
            questionId={questionId}
          />
        }
      </DndProvider>
      {outcomeIds.isEmpty() and <div className="nma-question__no-outcomes">
        {i18n 'outcomes.no_outcomes'}
      </div>}
    </div>
    <NMAOutcomeModal />
    <NMAInterventionManagementModal />
    <NMAImportModal />
  </div>

NMAQuestion.propTypes =
  isFetching: PropTypes.bool.isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  outcomes: PropTypes.instanceOf(Immutable.Map).isRequired

storeConnector =
  NMAQuestionStore: (Store) ->
    isFetching: Store.isFetching()
    question: Store.getCurrentQuestion()
    outcomes: Store.getOutcomes()

module.exports = ConnectStore NMAQuestion, [NMAQuestionStore], storeConnector
