ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Modal = require 'components/common/modal'
ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
QuestionsSelectorField =
  require 'components/dissemination/acp_visual_guidelines/questions_selector_field'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'

VISUALIZATION_FORM =
  main:
    [
      fieldKey: 'name'
      type: 'string'
      showClear: false
      useControlledInput: true
    ,
      fieldKey: 'sourceData'
      type: 'custom'
      component: QuestionsSelectorField
    ]

VisualGuidelinesModal = ({
  applying
  isModalOpen
  editingVisualization
}) ->

  i18n = useI18n('vi:form')

  visualGuidelineId = editingVisualization.get('_id')

  onApply = useCoffeeCallback [visualGuidelineId, editingVisualization], ->
    if visualGuidelineId
      ACPVisualGuidelinesActions.updateVisualization editingVisualization
    else
      ACPVisualGuidelinesActions.createVisualization editingVisualization

  onChange = useCoffeeCallback [], (key, value) ->
    ACPVisualGuidelinesActions.updateEditingVisualization { key, value }

  titleKey = if visualGuidelineId then 'update_visualization' else 'create_visualization'
  applyLabelKey = if visualGuidelineId then '/actions.update' else '/actions.create'

  <Modal
    className="mda-topics-field-modal"
    isOpen={isModalOpen}
    modalSize="medium"
    title={i18n titleKey}
  >
    <ExtractionForm
      data={editingVisualization.toJS()}
      form={VISUALIZATION_FORM}
      i18n={i18n}
      inModal
      onChange={onChange}
    />
    <ApplyCancelButtons
      onCancel={ACPVisualGuidelinesActions.closeCreateOrEditDialog}
      onApply={onApply}
      applying={applying}
      applyLabel={i18n applyLabelKey}
      isSubmitEnabled={not _.isEmpty(editingVisualization.get('name'))}
    />
  </Modal>

module.exports = VisualGuidelinesModal
