ConnectStore = require 'components/enhancers/connect_store'
DropdownMenu = require 'components/common/dropdown_menu'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
NMAOutcomeModal = require 'components/nma/nma_outcome_modal'
NMAOutcomeStore = require 'stores/nma_outcome_store'
NMAQuestionActions = require 'actions/nma_question_actions'
Router = require 'router'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

storeConnector =
  NMAOutcomeStore: (Store) ->
    isFetching: Store.isFetching()
    outcome: Store.getOutcome()

NMAOutcomeToolbar = ({ isFetching, outcome }) ->
  i18n = useI18n 'nma:question.outcomes'

  outcomeName = outcome.get('name') ? ''
  questionId = outcome.get('questionId') ? ''

  onGoBack = useCoffeeCallback [questionId], ->
    route =  Router::getProjectRelativeUrl "/nma/#{questionId}"
    mediator.publish '!router:route', route

  onMenuSelect = useCoffeeCallback [outcome, outcomeName, questionId], (option) ->
    switch option
      when 'edit'
        NMAQuestionActions.openOutcomeModal outcome.toJS()
      when 'delete'
        confirmOptions =
          title: i18n 'confirm_delete.title'
          message: i18n 'confirm_delete.message', { outcomeName }
          confirmText: i18n '/actions.delete'
          declineText: i18n '/actions.cancel'
          yesClass: 'danger'
        mediator.dialogs.confirm confirmOptions, (confirmed) ->
          return unless confirmed
          NMAQuestionActions.deleteOutcome(outcome.toJS()).then ->
            route =  Router::getProjectRelativeUrl "/nma/#{questionId}"
            mediator.publish '!router:route', route

  return null if isFetching

  <div className="nma-outcome-toolbar">
    <IconButton className="toolbar__back" iconName="arrow-left" onClick={onGoBack} />
    <div className="toolbar__outcome-name">{outcomeName}</div>
    <div className="toolbar__controls">
      <DropdownMenu
        menuPosition={alignment: 'end'}
        onSelect={onMenuSelect}
        opener={<IconButton className="toolbar__menu" iconName="more" />}
        options={[
          className: 'edit'
          text: i18n '/actions.edit'
          value: 'edit'
        ,
          className: 'delete'
          text: i18n '/actions.delete'
          value: 'delete'
        ]}
      />
    </div>
    <NMAOutcomeModal />
  </div>

NMAOutcomeToolbar.propTypes =
  isFetching: PropTypes.bool.isRequired
  outcome: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore NMAOutcomeToolbar, [NMAOutcomeStore], storeConnector
