var DiagnosticOutcome, Entity,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Entity = require('base/models/entity');

DiagnosticOutcome = (function(_super) {
  __extends(DiagnosticOutcome, _super);

  function DiagnosticOutcome() {
    return DiagnosticOutcome.__super__.constructor.apply(this, arguments);
  }

  return DiagnosticOutcome;

})(Entity);

module.exports = {
  TP: new DiagnosticOutcome({
    name: 'TP',
    label: 'true_positives'
  }),
  FN: new DiagnosticOutcome({
    name: 'FN',
    label: 'false_negatives'
  }),
  TN: new DiagnosticOutcome({
    name: 'TN',
    label: 'true_negatives'
  }),
  FP: new DiagnosticOutcome({
    name: 'FP',
    label: 'false_positives'
  }),
  Inconclusive: new DiagnosticOutcome({
    name: 'Inconclusive',
    label: 'inconclusive'
  }),
  Complications: new DiagnosticOutcome({
    name: 'Complications',
    label: 'complications'
  }),
  Cost: new DiagnosticOutcome({
    name: 'Cost',
    label: 'cost'
  }),
  get: function(name) {
    return this[name];
  }
};
