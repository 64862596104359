DocSectionsHeader = require 'components/document_sections/from_template/doc_sections_header'
DocSectionsChapter = require 'components/document_sections/from_template/doc_sections_chapter'
DocSectionsFromTemplateActions = require 'actions/doc_sections_from_template_actions'
EditSubchapterModal = require 'components/document_sections/from_template/edit_subchapter_modal'
ConfirmationModal = require 'components/common/confirmation_modal'
Translation = require 'components/mixins/translation'
Spinner = require 'components/common/spinner'

DocumentSectionsFromTemplate = createReactClass
  displayName: 'DocumentSectionsFromTemplate'

  propTypes:
    isDocSectionsFetching: PropTypes.bool
    isQuestionsFetching: PropTypes.bool
    isReferencesFetching: PropTypes.bool
    chapters: PropTypes.instanceOf(Immutable.Map)
    attachments: PropTypes.instanceOf(Immutable.Map)
    editingSubchapterData: PropTypes.instanceOf(Immutable.Map)
    collapsedChapters: PropTypes.instanceOf(Immutable.List).isRequired
    addingSubchapterTo: PropTypes.string
    deletingSubchapter: PropTypes.bool
    renderMode: PropTypes.oneOf(['regular', 'printout'])
    referenceIds: PropTypes.arrayOf(PropTypes.string)

  getDefaultProps: ->
    renderMode: 'regular'

  getInitialState: ->
    referencesState: 0

  mixins: [ Translation('docsec:from_template') ]

  refreshEditors: ->
    # This is artificial field used to refresh references indexes in editors
    # This is done this was as editors don't refresh
    # when props in ReferencesCitationDecorator are updated
    # See `componentDidUpdate` in DocSectionsEditor
    @setState referencesState: @state.referencesState + 1

  render: ->
    {
      isDocSectionsFetching,
      isQuestionsFetching,
      isReferencesFetching,
      renderMode,
      referenceIds
    } = @props
    isFetching = isDocSectionsFetching or isQuestionsFetching or isReferencesFetching

    if isFetching
      <Spinner />
    else
      { editingSubchapterData, addingSubchapterTo, deletingSubchapter, collapsedChapters } = @props
      <div>
        <DocSectionsHeader
          renderMode={renderMode}
        />
        <table>
          {@props.chaptersOrder.map (chapterId, idx) =>
            <DocSectionsChapter
              key={chapterId}
              index={idx + 1}
              expanded={not collapsedChapters.includes(chapterId)}
              chapterId={chapterId}
              chapterData={@props.chapters.get chapterId}
              attachments={@props.attachments}
              renderMode={renderMode}
              referenceIds={referenceIds}
              refreshEditors={@refreshEditors}
              referencesState={@state.referencesState}
            />
          }
        </table>
        {if editingSubchapterData
          <EditSubchapterModal
            isOpen={not R.isNil(editingSubchapterData)}
            initialTitle={editingSubchapterData.get 'title'}
            initialTitleHidden={editingSubchapterData.get 'titleHidden'}
            onSave={DocSectionsFromTemplateActions.saveEditingChapterSubsectionTitle}
            onCancel={DocSectionsFromTemplateActions.cancelEditChapterSubsection}
          />
        }
        {if addingSubchapterTo
          <EditSubchapterModal
            isOpen={not R.isNil(addingSubchapterTo)}
            initialTitleHidden={false}
            onSave={DocSectionsFromTemplateActions.saveAddingChapterSubsection}
            onCancel={DocSectionsFromTemplateActions.hideAddChapterSubsection}
          />
        }
        {if deletingSubchapter
          <ConfirmationModal
            isOpen={deletingSubchapter}
            question={@i18n '/messages.confirm'}
            confirmLabel={@i18n '/actions.delete'}
            onConfirm={DocSectionsFromTemplateActions.confirmChapterSubsectionDeletion}
            onCancel={DocSectionsFromTemplateActions.cancelChapterSubsectionDeletion}
          />
        }
      </div>

module.exports = DocumentSectionsFromTemplate
