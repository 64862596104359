mediator = require 'mediator'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'

module.exports =
  dropSpec:
    drop: (props, monitor, component) ->
      section = monitor.getItem()
      if section.chapterId isnt props.chapterId
        DocumentSectionsV2Actions.changeSectionParentId(
          mediator.project.id, section.chapterId, section.sectionId, props.chapterId
        )

  dropCollect: (connect, monitor) ->
    connectDropTarget: connect.dropTarget()
    isOver: monitor.isOver()
