var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "<label for=\"effectTotal\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.total", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n<input id=\"effectTotal\" class=\"form-control\"\n  name=\"effectTotal\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.effectTotal || (depth0 != null ? depth0.effectTotal : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"effectTotal","hash":{},"data":data}) : helper)))
    + "\">\n<div class=\"sup\"></div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}