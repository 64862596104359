var outcomesShowCommentsMixin, showCommentsMixin,
  __slice = [].slice;

showCommentsMixin = require('components/mixins/show_comments_mixin');

outcomesShowCommentsMixin = function() {
  var steps;
  steps = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return _.extend({}, showCommentsMixin, {
    isShowCommentsDisabled: function() {
      if (this.state.showingAllComments) {
        return true;
      }
      return !this.props.questionGroups.some(function(qGroup) {
        return qGroup.get('outcomes').some(function(outcome) {
          var outcomeComments;
          outcomeComments = outcome.get('memberComments', Immutable.Map());
          if (outcomeComments.isEmpty()) {
            return false;
          }
          return _.some(steps, function(stepName) {
            return !outcomeComments.get(stepName, Immutable.Map()).isEmpty();
          });
        });
      });
    }
  });
};

module.exports = outcomesShowCommentsMixin;
