AddCustomTooltip = require 'components/enhancers/add_custom_tooltip'

ViewSettingsList = (props) ->
  { settings, onSettingChange } = props

  <ul className='etd-view-options'>
    {settings.map (settingSpec, idx) ->
      { label, name, isActive, disabled } = settingSpec

      <li className='etd-view-option' key={idx}>
        <label>
          <input
            type='checkbox'
            onChange={onSettingChange}
            name={name}
            checked={isActive}
            disabled={disabled}
          />
          {label}
        </label>
      </li>
    }
  </ul>

ViewSettingsButton = (props) ->
  <button className='eye' {...props}>
    {$.t 'es:recommendations.etd_view_settings'}
  </button>

tooltipParams = trigger: 'click', position: 'bottom', alignment: 'center'
module.exports = AddCustomTooltip ViewSettingsButton, ViewSettingsList, tooltipParams
