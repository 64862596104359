var CollectionView, MemberApprovalStatusView, NextStepButton, QuestionsApprovalView, TableScrolling, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

MemberApprovalStatusView = require('views/scope/member_approval_status_view');

NextStepButton = require('views/scope/next_step_button_view');

TableScrolling = require('base/lib/traits/table_scrolling');

template = require('views/templates/scope/questions_approval');

mediator = require('mediator');

module.exports = QuestionsApprovalView = (function(_super) {
  __extends(QuestionsApprovalView, _super);

  function QuestionsApprovalView() {
    return QuestionsApprovalView.__super__.constructor.apply(this, arguments);
  }

  QuestionsApprovalView.prototype.className = 'questions-approval';

  QuestionsApprovalView.prototype.template = template;

  QuestionsApprovalView.prototype.container = '#page-container';

  QuestionsApprovalView.prototype.itemView = MemberApprovalStatusView;

  QuestionsApprovalView.prototype.listSelector = 'tbody';

  QuestionsApprovalView.prototype.initialize = function() {
    this.collection = new Backbone.Collection();
    QuestionsApprovalView.__super__.initialize.apply(this, arguments);
    this._refreshCollection();
    this.delegate('click', 'button.refresh', this._refreshCollection);
    return this.enable(TableScrolling, {
      content: '.members-table',
      fixedElements: '.sticky-header, .information, .bottom-bar'
    });
  };

  QuestionsApprovalView.prototype._refreshCollection = function() {
    this.collection.reset();
    this.loading = true;
    this.render();
    return this.options.service.getApprovalStatus({
      force: this.options.preview
    }).then((function(_this) {
      return function(members) {
        _this.collection = members;
        _this.itemViewOptions = {
          membersIndexes: _this._anonymizeMembers()
        };
        _this.loading = false;
        return _this.render();
      };
    })(this));
  };

  QuestionsApprovalView.prototype._anonymizeMembers = function() {
    return _(this.collection.models).chain().map(function(member, idx) {
      return [member.cid, idx + 1];
    }).object().value();
  };

  QuestionsApprovalView.prototype.afterRender = function() {
    QuestionsApprovalView.__super__.afterRender.apply(this, arguments);
    this.$('.members').toggleClass('box-loading', this.loading);
    return this.subview('next-step', new NextStepButton({
      step: 'questions.approval',
      container: this.$('.bottom-bar')
    }));
  };

  QuestionsApprovalView.prototype.getTemplateData = function() {
    return _(QuestionsApprovalView.__super__.getTemplateData.apply(this, arguments)).extend({
      preview: this.options.preview
    });
  };

  return QuestionsApprovalView;

})(CollectionView);
