Button = require 'components/common/button'

VOTING_BUTTONS =
  reminder:
    label: $.t 'voting:voting.compose_reminder_for_selected'
    className: 'btn btn-left'
    title: $.t 'voting:voting.tooltip_reminder'
  start:
    label: $.t 'voting:voting.compose_message_send_forms'
    className: "btn start-voting"
  closeFinal:
    label: $.t 'voting:voting.close_voting_and_finish'
    className: "btn btn-right btn-send"
  closePhase1:
    label: $.t 'voting:voting.close_voting_phase1'
    className: "btn btn-right btn-send"

VotingActionButton = createReactClass
  displayName: 'VotingActionButton'
  propTypes:
    onClick: PropTypes.func.isRequired
    type: PropTypes.string.isRequired

  render: ->
    <Button {...VOTING_BUTTONS[@props.type]} {...@props} />

module.exports = VotingActionButton
