var AdolopedEtdActions, EtdAsync, QuestionGroupsActions, QuestionsActions, ReferencesActions, W, alt, convertListToOrderedMap, mediator;

alt = require('alt');

EtdAsync = require('actions/async/etd');

mediator = require('mediator');

QuestionGroupsActions = require('actions/question_groups_actions');

QuestionsActions = require('actions/questions_actions');

ReferencesActions = require('actions/references_actions');

W = require('when');

convertListToOrderedMap = require('lib/immutable_utils').convertListToOrderedMap;

AdolopedEtdActions = (function() {
  function AdolopedEtdActions() {
    this.generateActions('fetchError');
  }

  AdolopedEtdActions.prototype.fetch = function(_arg) {
    var dbId, docIds;
    dbId = _arg.dbId, docIds = _arg.docIds;
    this.dispatch();
    return EtdAsync.fetchEtdDoc(dbId, docIds).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  AdolopedEtdActions.prototype.fetchSuccess = function(_arg) {
    var rows;
    rows = _arg.rows;
    return this.dispatch(convertListToOrderedMap(Immutable.fromJS(_.pluck(rows, 'doc'))));
  };

  return AdolopedEtdActions;

})();

module.exports = alt.createActions(AdolopedEtdActions);
