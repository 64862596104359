var IndividualRatingsToggleMixin;

IndividualRatingsToggleMixin = {
  toggleIndividualRatings: function() {
    var currentState;
    currentState = this.state.showIndividualRatings;
    return this.setState({
      showIndividualRatings: !currentState
    });
  }
};

module.exports = IndividualRatingsToggleMixin;
