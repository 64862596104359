{ func, instanceOf, bool, oneOfType } = PropTypes
QuestionsListComponentStore = require 'stores/questions_list_component_store'
Translation = require 'components/mixins/translation'
Tooltip = require 'components/common/tooltip'
SpinnerButton = require 'components/common/spinner_button'
PanelVoiceActions = require 'actions/panel_voice_actions'
{ getReminderEmailBody } = require 'stores/utils/panel_voice_helpers'
{ getInitials } = require 'lib/members_helper'
utils = require 'base/lib/utils'
StatusMatrix = require 'components/common/status_matrix'
FeedbackLegend = require 'components/panel_voice/feedback/feedback_legend'
alt = require 'alt'
{ questionCustomId } = require 'lib/questions_helper'

FeedbackStatus = createReactClass

  displayName: "FeedbackStatus"
  mixins: [Translation('')]

  propTypes:
    panelMembers: instanceOf(Immutable.OrderedMap)
    questionsList: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)]
    )
    questionGroups: instanceOf(Immutable.Map)
    votingResults: instanceOf(Immutable.List)
    isMemberInPanelVoiceFn: func.isRequired
    isSendingReminders: bool.isRequired

  getInitialState: ->
    selectedMembers: new Immutable.List()

  getDefaultProps: ->
    panelMembers: new Immutable.OrderedMap()
    questionsList: new Immutable.List()
    questionGroups: new Immutable.Map()
    votingResults: new Immutable.List()

  sendReminders: ->
    message = getReminderEmailBody()
    PanelVoiceActions.sendReminders @state.selectedMembers, message

  # reminders can only be send to members that have received at least one panel voice form
  _isValidMembersSelection: ->
    @state.selectedMembers.every @props.isMemberInPanelVoiceFn

  toggleMemberSelection: (e) ->
    selectedMembers = if @state.selectedMembers.indexOf(e.target.value) isnt -1
      @state.selectedMembers.delete @state.selectedMembers.indexOf(e.target.value)
    else
      @state.selectedMembers.push e.target.value
    @setState selectedMembers: selectedMembers

  isSendButtonDisabled: ->
    @state.selectedMembers.isEmpty() or not @_isValidMembersSelection()

  isCoi: (memberId, question) ->
    question.getIn(['coi', memberId], false)

  _getMemberVotingStatus: (memberId, questionId, etdIds) ->
    votingStatusesForQuestion = (votingResults, etdIds) ->
      votingResults.filter (result) ->
        _(etdIds).contains result.get('etdId')

    { votingResults } = @props
    questionVotingResults = votingStatusesForQuestion(votingResults, etdIds)

    questionVotingStages = alt.stores.PanelVoiceStore.getQuestionVotingStages questionId
    questionVotingStatus = alt.stores.PanelVoiceStore.getQuestionVotingStatus questionId

    lastVotingStages = questionVotingStages?.last()
    return "unsent" unless lastVotingStages
    return "unsent" unless lastVotingStages.get("members").contains memberId
    lastRoundTimestamp = lastVotingStages.get('lastRoundTimestamp', "").toString()
    return questionVotingStatus if questionVotingResults.isEmpty()
    memberResults = questionVotingResults.find (result) =>
      result.get("memberId") is memberId
    , null, new Immutable.Map()
    .get("roundsResults", new Immutable.Map())

    return questionVotingStatus if memberResults.isEmpty()
    timestamp = memberResults.keySeq().last()
    return questionVotingStatus if timestamp isnt lastRoundTimestamp
    memberResults.getIn [timestamp, "status"], "unsent"

  getCellValue: (memberId, questionId, etdIds) ->
    status = @_getMemberVotingStatus(memberId, questionId, etdIds)
    switch status
      when 'finished'
        'finished'
      when 'new', 'ongoing'
        'sent'
      else
        'not_sent'

  prepareQuestionsMatrixData: (questionsList, panelMembers) ->
    questionsList.reduce (acc, q) =>
      questionId = q.get('_id')
      etdIds = q.get('recommendationIds')?.toJS() or []
      row =
        rowId: questionId
        columns: panelMembers.reduce (cols, member) =>
          memberId = member.get('_id')
          status = @getCellValue memberId, questionId, etdIds
          col =
            columnId: memberId
            className: classNames(
              'panel-voice-feedback-matrix__default-status',
              'exclamation-mark': @isCoi memberId, q
              'panel-voice-feedback-matrix__sent-status': status is 'sent'
              'panel-voice-feedback-matrix__finished-status': status is 'finished'
            )
          cols.concat [col]
        , []
      acc.concat [row]
    , []

  getCheckedColumnsMap: ->
    @props.panelMembers.reduce (acc, member) =>
      memberId = member.get('_id')
      acc[memberId] = @state.selectedMembers.contains memberId
      acc
    , {}

  getGrouppedMatrixData: ->
    { questionsList, questionGroups, panelMembers } = @props

    QuestionsListComponentStore.groupQuestionsByGroup questionsList, questionGroups
      .reduce (acc, group) =>
        groupId = group.get '_id'
        groupQuestions = group.get 'questions'
        matrixData = @prepareQuestionsMatrixData groupQuestions, panelMembers
        caption = if groupId is 'questionsWithoutGroup' then '' else group.get 'caption'

        acc.push { rows: matrixData, caption }
        acc
      , []

  getMatrixRowLabels: ->
    @props.questionsList.reduce (acc, question) ->
      acc[question.get('_id')] =
        label: questionCustomId(question)
        tooltip: question.get('question')
      acc
    , {}

  getMatrixColumnLabels: ->
    @props.panelMembers.reduce (acc, member) ->
      obj =
        label: getInitials member
        tooltip: [(member.get('givenNames') or ''), member.get('lastName')].join ' '
      acc.concat [obj]
    , []

  render: ->
    <div className="voting-feedback-status">
      <StatusMatrix
        grouppedRows={@getGrouppedMatrixData()}
        rowLabels={@getMatrixRowLabels()}
        columnLabels={@getMatrixColumnLabels()}
        type='column-check'
        checkedColumnsMap={@getCheckedColumnsMap()}
        toggleCheckbox={@toggleMemberSelection}
      />
      <FeedbackLegend />
      <SpinnerButton
        className="btn btn-block btn-apply"
        onClick={@sendReminders}
        disabled={@isSendButtonDisabled()}
        label={@i18n 'voting:voting.modals.send_reminder'}
        loading={@props.isSendingReminders}
      />
    </div>

module.exports = FeedbackStatus
