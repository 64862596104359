RadioWithLabel = require 'components/common/radio_with_label'
Translation = require 'components/mixins/translation'
ContentEditable = require 'components/common/content_editable'
MultiComparisonsActions = require 'actions/multi_comparisons_actions'
SpinnerButton = require 'components/common/spinner_button'
MultiComparisonsSojTable = require './multi_comparisons_soj_table'
InterventionsGradingTable = require './interventions_grading_table'
Select = require 'components/common/select_custom'
IconButton = require 'components/common/icon_button'
SummaryEditor = require './summary_editor'

RecommendationsBox = createReactClass
  displayName: 'RecommendationsBox'

  propTypes:
    recommendations: PropTypes.instanceOf(Immutable.List).isRequired

  mixins: [ Translation('mc:recommendation') ]

  setRecommendationStrength: (idx) -> (value) ->
    MultiComparisonsActions.setRecommendationStrength { idx, value }

  removeRecommendation: (idx) -> ->
    MultiComparisonsActions.removeRecommendation idx

  getStrengthSelectOptions: ->
    _(['strong', 'conditional']).map (strength) =>
      text: @i18n strength
      value: strength

  render: ->
    <div className="multi-comparisons__recommendations-box">
      {@props.recommendations.map (recommendation, idx) =>
        <div
          key={idx}
          className="multi-comparisons__textareas-box"
        >
          <div className="multi-comparisons__recommendations-box-header">
            <p className="multi-comparisons__recommendations-box-title">
              {@i18n 'recommendation'}
            </p>
            {if @props.recommendations.size > 1
              <IconButton
                className="multi-comparisons__recommendations-remove"
                iconName="decline-icon"
                onClick={@removeRecommendation idx}
              />
            }
          </div>
          <div>
            <span className="multi-comparisons__recommendation-strength-text">
              {@i18n 'strength'}
            </span>
            <Select
              className="multi-comparisons__recommendation-strength-select"
              options={@getStrengthSelectOptions()}
              listClassName='multi-comparisons-recommendation-options'
              selected={recommendation.get('strength', 'strong')}
              onChange={@setRecommendationStrength idx}
            />
          </div>

          <SummaryEditor
            onSave={MultiComparisonsActions.updateRecommendationText}
            attrName={idx}
            value={recommendation.get 'text'}
          />
        </div>
      }
      <IconButton
        className="multi-comparisons__add-recommendation-button"
        iconName="add"
        label={@i18n 'add'}
        labelPosition='right'
        onClick={MultiComparisonsActions.addRecommendation}
      />
    </div>

ComparisonsReviewSummary = createReactClass
  displayName: 'ComparisonsReviewSummary'

  propTypes:
    adolopmentData: PropTypes.instanceOf(Immutable.Map).isRequired
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired
    assessmentSections: PropTypes.arrayOf(PropTypes.string).isRequired
    comparisonsEditData: PropTypes.instanceOf(Immutable.Map).isRequired
    currentSelectedQuestionType: PropTypes.string.isRequired
    etds: PropTypes.instanceOf(Immutable.Map).isRequired
    savingComparison: PropTypes.bool.isRequired
    summarySections: PropTypes.arrayOf(PropTypes.string).isRequired

  mixins: [ Translation('') ]

  saveComparison: ->
    MultiComparisonsActions.saveComparison(true)

  render: ->
    {
      adolopmentData
      adolopments
      assessmentSections
      comparisonsEditData
      currentSelectedQuestionType
      etds
      summarySections
    } = @props

    <div className="multi-comparisons__comparisons-review-summary">
      <div className="multi-comparisons__table-name">{@i18n 'mc:summary_of_judgements'}</div>
      <div className="multi-comparisons__card">
        <MultiComparisonsSojTable
          adolopmentData={adolopmentData}
          adolopments={adolopments}
          selectedQuestions={comparisonsEditData.get 'selectedQuestions'}
          sectionsImportanceForDecision={comparisonsEditData.get 'sectionsImportanceForDecision'}
          assessmentSections={assessmentSections}
          hiddenSections={comparisonsEditData.getIn ['hiddenSections', 'soj']}
          etds={etds}
        />
      </div>

      <div className="multi-comparisons__table-name">{@i18n 'mc:review'}</div>
      <div className="multi-comparisons__card">
        <div className="multi-comparisons__grading-information-text">
          {@i18n "mc:review_tables.grading_information_#{currentSelectedQuestionType}"}
          <span className="multi-comparisons__grading-information-rating-star"></span>
        </div>
        <InterventionsGradingTable
          selectedInterventions={comparisonsEditData.get 'selectedInterventions'}
          sectionsImportanceForDecision={comparisonsEditData.get 'sectionsImportanceForDecision'}
          assessmentSections={_(assessmentSections).reject (s) ->
            s in ['certaintyOfEvidence', 'certaintyOfEvidenceOfTestAccuracy']}
          sectionGradingComments={comparisonsEditData.get 'sectionGradingComments'}
          interventionsRating={comparisonsEditData.get 'interventionsRating'}
          hiddenSections={comparisonsEditData.getIn ['hiddenSections', 'grading']}
          hiddenInterventions={comparisonsEditData.get 'hiddenInterventions'}
        />
      </div>

      <div className="multi-comparisons__card multi-comparisons__card-with-textareas">
        <RecommendationsBox
          recommendations={comparisonsEditData.get 'recommendations'}
        />
      </div>

      <div className="multi-comparisons__card multi-comparisons__card-with-textareas">
        <div className="multi-comparisons__textareas-box">
          {
            _(summarySections).map (attrName) =>
              label = "mc:review_summary_attributes.#{_.string.underscored attrName}"
              <div key={attrName}>
                <div className="multi-comparisons__textarea-label">
                  {@i18n label}
                </div>
                <SummaryEditor
                  attrName={attrName}
                  value={comparisonsEditData.get attrName}
                  onSave={MultiComparisonsActions.updateComparisonsTextAttribute}
                />
              </div>
          }
        </div>
      </div>

      <div className="bottom-buttons">
        <SpinnerButton
          className="btn btn-apply"
          onClick={@saveComparison}
          label={@i18n 'mc:save_comparison'}
          loading={@props.savingComparison}
        />
      </div>
    </div>

module.exports = ComparisonsReviewSummary
