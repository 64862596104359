AccessRights = require 'components/team/form/access_rights'
AccessRightsFormGroup = require 'components/team/form/access_rights_form_group'
MemberFormGroup = require 'components/team/member_form_group'
PanelMemberFormGroup = require 'components/team/form/panel_member_form_group'
MemberTitleCell = require 'components/team/form/member_title_cell'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
{ REQUIRED_FIELDS, getMemberAccessRights } = require 'lib/members_helper'

FIELDS_TO_EDIT = Immutable.List(['email',
  'title', 'lastName', 'givenNames', 'access_rights', 'panel_member'])

EditableMemberRow = createReactClass

  displayName: "EditableMemberRow"

  propTypes:
    adminsLimit: PropTypes.number.isRequired
    disabledAccessRights: PropTypes.instanceOf(Array)
    errors: PropTypes.instanceOf(Immutable.Map)
    fieldsToEdit: PropTypes.instanceOf(Immutable.List)
    member: PropTypes.instanceOf(Immutable.Map).isRequired
    removeMember: PropTypes.func.isRequired
    updateAttr: PropTypes.func.isRequired

  getDefaultProps: ->
    fieldsToEdit: FIELDS_TO_EDIT
    disabledAccessRights: []

  mixins: [Translation('team:member')]

  updateTitle: (value)->
    @props.updateAttr 'title', value

  updateAccessRights: (value) ->
    @props.updateAttr 'accessRights', Immutable.List [value]

  togglePanelMemberRole: ->
    @props.updateAttr 'panelMember', not @props.member.get('panelMember', false)

  renderFormGroup: (attr, disabled = false) ->
    { adminsLimit, disabledAccessRights, errors, member } = @props
    switch attr
      when 'email', 'lastName', 'givenNames'
        <MemberFormGroup
          attr={attr}
          disabled={disabled}
          error={@props.errors?.get(attr)}
          inline={true}
          label={@i18n _.string.underscored(attr)}
          memberId={member.get('_id')}
          required={attr in REQUIRED_FIELDS}
          updateAttr={@props.updateAttr}
          value={member.get(attr)}
        />
      when 'title'
        <MemberTitleCell
          member={member}
          updateAttr={@updateTitle}
          inline={true}
        />
      when 'access_rights'
        <AccessRightsFormGroup
          adminsLimit={adminsLimit}
          disabledAccessRights={disabledAccessRights}
          errors={errors}
          member={member}
          selected={getMemberAccessRights(member)}
          updateAccessRights={@updateAccessRights}
        />
      when 'panel_member'
        <PanelMemberFormGroup
          member={member}
          onUpdate={@togglePanelMemberRole}
        />

  render: ->
    { fieldsToEdit, member, removeMember } = @props

    <div className="new-member">
      <div className="content">
        <span className="pull-right delete" onClick={removeMember}></span>
        {fieldsToEdit.map (field) =>
          <div key="#{field}-#{member.get('_id')}" className="col-6">{@renderFormGroup field}</div>
        }
        <div className="clearfix" />
        <div className="separator" />
      </div>
    </div>

module.exports = EditableMemberRow
