DocSectionsFromTemplateActions = require 'actions/doc_sections_from_template_actions'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
DocSectionsFromTemplateStore = require 'stores/doc_sections_from_template_store'
ConnectStore = require 'components/enhancers/connect_store'
Translation = require 'components/mixins/translation'
utils = require 'base/lib/utils'

storeConnectors =
  DocSectionsFromTemplateStore: (Store) ->
    projectDataSections: Store.getProjectDataSections()
    projectData: Store.getProjectData()

DocSectionsHeader = createReactClass
  displayName: 'DocSectionsHeader'

  propTypes:
    projectDataSections: PropTypes.instanceOf(Immutable.List).isRequired
    projectData: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [Translation('docsec:project_sections')]

  getInitialState: ->
    expanded: false
    editing: false
    inputsData: @props.projectData.toJS()

  expandHeader: ->
    @setState expanded: true

  collapseHeader: ->
    @setState expanded: false

  handleSave: ->
    DocSectionsFromTemplateActions.saveProjectData Immutable.fromJS @state.inputsData
    @setState editing: false

  enableEdit: ->
    @setState editing: true

  getHeaderControls: ->
    editing = @state.editing
    [
      name: if editing then 'save' else 'edit'
      handler: if editing then @handleSave else @enableEdit
    ]

  handleInputChange: (inputKey) -> (e) =>
    inputsData = Object.assign @state.inputsData, utils.getKeyValObject(inputKey, e.target.value)
    @setState { inputsData }

  renderSectionEdit: (projectSection) ->
    key = projectSection.get('key')
    value = @state.inputsData[key] || ''
    changeHandler = @handleInputChange(key)

    if projectSection.get('type') is 'textarea'
      <textarea
        className="doc-sections-from-template-header__section-textarea"
        value={value}
        onChange={changeHandler}
      ></textarea>
    else
      <input
        type="text"
        className="doc-sections-from-template-header__section-input"
        value={value}
        onChange={changeHandler}
      />

  render: ->
    { projectDataSections, projectData } = @props

    <div className="doc-sections-from-template-header">
      <CollapsibleWithControls
        isExpanded={@state.expanded}
        onExpand={@expandHeader}
        onCollapse={@collapseHeader}
        controls={@getHeaderControls()}
      >
        <p>{projectData.get('title')}</p>

        <table className="doc-sections-from-template-header__sections-table">
          <colgroup>
            <col className="c-1" />
            <col className="c-2" />
          </colgroup>
          <tbody>
            {projectDataSections.map (projectSection) =>
              <tr key={projectSection.get('key')}>
                <td className="doc-sections-from-template-header__section-label-cell">
                  {projectSection.get('label') ? @i18n(projectSection.get('labelKey'))}
                </td>
                <td className="doc-sections-from-template-header__section-value-cell">
                  {if @state.editing
                    @renderSectionEdit(projectSection)
                  else
                    <span>
                      {projectData.get(projectSection.get('key'))}
                    </span>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </CollapsibleWithControls>
    </div>

module.exports = ConnectStore DocSectionsHeader, DocSectionsFromTemplateStore, storeConnectors
