List = createReactClass
  displayName: 'List'

  propTypes:
    className: PropTypes.string
    ordered: PropTypes.bool

  getDefaultProps: ->
    className: ''
    ordered: false

  render: ->
    if @props.ordered
      <ol className={classNames "standard-list", @props.className}>
        {@props.children}
      </ol>
    else
      <ul className={classNames "standard-list", @props.className}>
        {@props.children}
      </ul>

module.exports = List
