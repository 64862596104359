Button = require 'components/common/button'
IconButton = require 'components/common/icon_button'
ConnectStore = require 'components/enhancers/connect_store'
QuestionsStore = require 'stores/questions_store'
ToolbarQuestionsList = require 'components/evidence_syntheses/toolbar_questions_list'
QualityIndicatorsStore = require 'stores/quality_indicators_store'
QualityIndicatorsActions = require 'actions/quality_indicators_actions'
QualityIndicatorsExportModal =
  require 'components/quality_indicators/quality_indicators_export_dialog'
{ useI18n } = require 'lib/react_utils'
{ useCallback, useMemo } = React

storeConnector =
  QuestionsStore: (Store) ->
    isFetching: Store.isFetching()
    currentQuestion: Store.getQuestion()
  QualityIndicatorsStore: (Store) ->
    currentView: Store.getCurrentView()
    showExportDialog: Store.getShowExportDialog()

QualityIndicatorsToolbar = ({ isFetching, currentQuestion, currentView, showExportDialog }) ->
  i18n = useI18n()

  buttonLabel = useMemo ->
    if currentView is 'etd' then 'add_quality_indicator' else 'create_qi_etd'
  , [currentView]

  buttonClassNames = useMemo ->
    if currentView is 'etd' then 'ep-btn ep-btn--round' else 'ep-btn btn'
  , [currentView]

  changeView = useCallback ->
    QualityIndicatorsActions.changeView currentView
  , [currentView]

  onExport = useCallback QualityIndicatorsActions.openExportDialog
  , []

  <div className="evidence-syntheses-toolbar">
    <div className="flex w-full">
      <div id="es-toolbar-questions" className="flex-grow mr-10">
        <div className=".questions-menu">
          <div className="questions">
            {if not isFetching
              <ToolbarQuestionsList
                currentQuestion={currentQuestion?.get('question', '')}
                questionsCollectionType="regular"
              />
            }
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Button
          className={buttonClassNames}
          label={i18n "actions.#{buttonLabel}"}
          onClick={changeView}
        />
        <IconButton
          className="mx-10"
          iconName="export"
          onClick={onExport}
        />
        {showExportDialog and <QualityIndicatorsExportModal question={currentQuestion} />}
      </div>
    </div>
  </div>

QualityIndicatorsToolbar.propTypes =
  currentQuestion: PropTypes.instanceOf(Immutable.Map).isRequired
  isFetching: PropTypes.bool.isRequired
  showExportDialog: PropTypes.bool.isRequired

module.exports = ConnectStore QualityIndicatorsToolbar,
  [ QualityIndicatorsStore, QuestionsStore ], storeConnector
