Modal = require 'components/common/modal'
Button = require 'components/common/button'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'


ProjectInvitationSuccessDialog = createReactClass
  displayName: 'ProjectInvitationSuccessDialog'

  propTypes:
    organizationId: PropTypes.string
    projectId: PropTypes.string.isRequired
    onClose: PropTypes.func.isRequired
    isCopy: PropTypes.bool.isRequired

  mixins: [Translation('projects:welcome')]

  getWorkSpaceName: ->
    if @props.organizationId?
      mediator.userOrganizations[@props.organizationId]?.organizationName ? ''
    else
      @i18n 'personal'

  openProject: ->
    mediator.publish '!router:route', "/projects/#{@props.projectId}"
    @props.onClose()

  render: ->
    { isCopy } = @props
    <Modal isOpen onRequestClose={@props.onClose} className='invitation-dialog'>
      <div className='message'>
        {if isCopy
          @i18n 'project_moved_to_selected'
        else
          @i18n 'project_added_to_workspace', workspace: @getWorkSpaceName()
        }
      </div>
      {if isCopy
        <Button
          className='btn btn-block'
          label={@i18n '/translation:actions.ok'}
          onClick={@props.onClose}
        />
      else
        <ApplyCancelButtons
          onApply={@openProject}
          onCancel={@props.onClose}
          cancelLabel={@i18n '/translation:actions.ok'}
          applyLabel={@i18n '/translation:actions.open_project'}
          cancelClass='btn-alternative'
        />
      }
    </Modal>

module.exports = ProjectInvitationSuccessDialog
