var UserProjectDataActions, alt, appUtils, mediator;

alt = require('alt');

mediator = require('mediator');

appUtils = require('lib/app_utils');

UserProjectDataActions = (function() {
  function UserProjectDataActions() {
    this.generateActions('fetchSuccess', 'fetchError');
  }

  UserProjectDataActions.prototype.fetch = function(projectId) {
    var udbName;
    udbName = mediator.user.id;
    return mediator.services.storePersistenceAdapter.fetch(udbName, projectId).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  UserProjectDataActions.prototype.dbChange = function(_arg) {
    var doc;
    doc = _arg.doc;
    if (doc._id === mediator.project.id) {
      return this.dispatch(doc);
    }
  };

  UserProjectDataActions.prototype.toggleSojSection = function(etdId, sectionId) {
    var docId, udbName;
    udbName = mediator.user.id;
    docId = mediator.project.id;
    return mediator.services.storePersistenceAdapter.updateOrCreate(udbName, docId, function(doc) {
      var currentSectionStatus, hiddenSojSections, sections;
      hiddenSojSections = doc.hiddenSojSections || {};
      sections = hiddenSojSections[etdId] || {};
      currentSectionStatus = sections[sectionId] || false;
      sections[sectionId] = !currentSectionStatus;
      hiddenSojSections[etdId] = sections;
      return _.extend(doc, {
        hiddenSojSections: hiddenSojSections
      });
    })["catch"](appUtils.errorHandler);
  };

  UserProjectDataActions.prototype.toggleAssessmentSection = function(etdId, sectionId) {
    var docId, udbName;
    udbName = mediator.user.id;
    docId = mediator.project.id;
    return mediator.services.storePersistenceAdapter.updateOrCreate(udbName, docId, function(doc) {
      var collapsedAssessementSections, currentSectionStatus, sections;
      collapsedAssessementSections = doc.collapsedAssessementSections || {};
      sections = collapsedAssessementSections[etdId] || {};
      currentSectionStatus = sections[sectionId] || false;
      sections[sectionId] = !currentSectionStatus;
      collapsedAssessementSections[etdId] = sections;
      return _.extend(doc, {
        collapsedAssessementSections: collapsedAssessementSections
      });
    })["catch"](appUtils.errorHandler);
  };

  UserProjectDataActions.prototype.toggleAssessmentSections = function(etdId, sectionKeys, collapse) {
    var docId, sections, udbName;
    udbName = mediator.user.id;
    docId = mediator.project.id;
    sections = sectionKeys.reduce(function(acc, section, sectionKey) {
      acc[sectionKey] = collapse;
      return acc;
    }, {});
    return mediator.services.storePersistenceAdapter.updateOrCreate(udbName, docId, function(doc) {
      var collapsedAssessementSections;
      collapsedAssessementSections = doc.collapsedAssessementSections || {};
      collapsedAssessementSections[etdId] = sections;
      return _.extend(doc, {
        collapsedAssessementSections: collapsedAssessementSections
      });
    })["catch"](appUtils.errorHandler);
  };

  UserProjectDataActions.prototype._toggleTableViewSetting = function(etdId, settingKey) {
    var docId, udbName;
    udbName = mediator.user.id;
    docId = mediator.project.id;
    return mediator.services.storePersistenceAdapter.updateOrCreate(udbName, docId, function(doc) {
      var path;
      path = ['etdViewSettings', etdId, settingKey];
      return R.assocPath(path, R.not(R.pathOr(true, path, doc)), doc);
    })["catch"](appUtils.errorHandler);
  };

  UserProjectDataActions.prototype.toggleAdditionalConsiderations = function(etdId) {
    return this.actions._toggleTableViewSetting(etdId, 'showAdditionalConsiderations');
  };

  UserProjectDataActions.prototype.toggleOriginalDataSections = function(etdId, sectionKeys, collapse) {
    return this.actions._toggleDataSections('collapsedOriginalSections', etdId, sectionKeys, collapse);
  };

  UserProjectDataActions.prototype.toggleAdolopmentDataSections = function(etdId, sectionKeys, collapse) {
    return this.actions._toggleDataSections('collapsedAdolopmentSections', etdId, sectionKeys, collapse);
  };

  UserProjectDataActions.prototype._toggleDataSections = function(optionName, etdId, sectionKeys, collapse) {
    var docId, sections, udbName;
    udbName = mediator.user.id;
    docId = mediator.project.id;
    sections = sectionKeys.reduce(function(acc, sectionKey) {
      acc[sectionKey] = collapse;
      return acc;
    }, {});
    return mediator.services.storePersistenceAdapter.updateOrCreate(udbName, docId, function(doc) {
      return R.assocPath(['etdViewSettings', etdId, optionName], sections, doc);
    })["catch"](appUtils.errorHandler);
  };

  UserProjectDataActions.prototype.toggleDataSection = function(optionName, etdId, sectionId) {
    var docId, udbName;
    udbName = mediator.user.id;
    docId = mediator.project.id;
    return mediator.services.storePersistenceAdapter.updateOrCreate(udbName, docId, function(doc) {
      var path;
      path = ['etdViewSettings', etdId, optionName, sectionId];
      return R.assocPath(path, R.not(R.pathOr(false, path, doc)), doc);
    })["catch"](appUtils.errorHandler);
  };

  UserProjectDataActions.prototype.toggleOriginalDataSection = function(etdId, sectionId) {
    return this.actions.toggleDataSection('collapsedOriginalSections', etdId, sectionId);
  };

  UserProjectDataActions.prototype.toggleAdolopmentDataSection = function(etdId, sectionId) {
    return this.actions.toggleDataSection('collapsedAdolopmentSections', etdId, sectionId);
  };

  UserProjectDataActions.prototype.toggleOriginalJudgement = function(etdId) {
    return this.actions._toggleTableViewSetting(etdId, 'showOriginalJudgement');
  };

  UserProjectDataActions.prototype.toggleAdolopmentJudgement = function(etdId) {
    return this.actions._toggleTableViewSetting(etdId, 'showAdolopmentJudgement');
  };

  UserProjectDataActions.prototype.toggleOriginalRecommendationAndJustification = function(etdId) {
    return this.actions._toggleTableViewSetting(etdId, 'showOriginalRecommendationAndJustification');
  };

  return UserProjectDataActions;

})();

module.exports = alt.createActions(UserProjectDataActions);
