OverReviewActions = require 'actions/over_review_actions'
Translation = require 'components/mixins/translation'
{ FlexTable, FlexTableRow, FlexTableCell, FlexTableFixedCell } =
  require 'components/common/flex_table'
{ Card } = ReactComponents
OutcomesMappingSelect = require 'components/over_review/outcomes_mapping_select'
Rating = require 'components/common/rating'
SourceComment = require 'components/over_review/source_comment'
{ string, arrayOf, object, shape } = PropTypes

PICO_FIELDS = [ 'population', 'intervention', 'comparison' ]

SourcesTab = createReactClass
  displayName: 'SourcesTab'

  propTypes:
    questionId: string.isRequired
    questionPico: shape(
      intervention: string.isRequired
      comparison: string.isRequired
      population: string.isRequired
    ).isRequired
    sources: object.isRequired
    sourcesOrder: arrayOf(string).isRequired

  mixins: [
    Translation('over_review:module')
  ]

  rateSourceReviewQuality: (sourceId) -> (rating) =>
    OverReviewActions.rateSourceReviewQuality { questionId: @props.questionId, sourceId, rating }

  sourceOutcomesByReviewedOutcomes: (sourceOutcomes) ->
    { reviewedOutcomes } = @props

    R.fromPairs(
      R.pluck('_id', sourceOutcomes)
        .map (sourceOutcomeId) ->
          reviewedOutcomeIdMappedTo = R.find ({ sourcesOutcomesMapping }) ->
            R.includes sourceOutcomeId, R.values sourcesOutcomesMapping
          , reviewedOutcomes
          if reviewedOutcomeIdMappedTo?
            [ sourceOutcomeId, reviewedOutcomeIdMappedTo['_id'] ]
          else
            null
        .filter R.compose R.not, R.isNil
    )

  addSourceComment: (sourceId) -> (comment) =>
    OverReviewActions.updateSourceComment { questionId: @props.questionId, sourceId, comment }

  render: ->
    { sourcesOrder, reviewedOutcomes } = @props

    <div className='over-review-sources-tab'>
      <h2>
        {@i18n '../module.pico_comparison'}
        <div>{@i18n '../module.pico_comparison_description'}</div>
      </h2>
      <Card>
        <div className='card-content'>
          <div className='sources-pico-summary'>
            <FlexTable className='sources-pico-table'>
              <FlexTableRow>
                <FlexTableFixedCell className='question-title-cell'>
                  {@i18n '../my_question'}
                </FlexTableFixedCell>
                {sourcesOrder.map (sourceId) =>
                  <FlexTableCell className='source-name-cell' key={sourceId}>
                    {@props.sources[sourceId].sourceName}
                  </FlexTableCell>
                }
              </FlexTableRow>
              {PICO_FIELDS.map (fieldName) =>
                <FlexTableRow key={fieldName}>
                  <FlexTableFixedCell className='question-field-name'>
                    {@i18n "../#{fieldName}"}
                  </FlexTableFixedCell>
                  <FlexTableFixedCell className='question-field-value'>
                    {@props.questionPico[fieldName]}
                  </FlexTableFixedCell>
                  {@props.sourcesOrder.map (sourceId) =>
                    <FlexTableCell className='source-field-value' key={sourceId}>
                      {@props.sources[sourceId][fieldName]}
                    </FlexTableCell>
                  }
                </FlexTableRow>
              }
              <FlexTableRow className='review-quality-row'>
                <FlexTableFixedCell className='review-quality-cell'>
                  {@i18n '../review_quality'}
                </FlexTableFixedCell>
                {@props.sourcesOrder.map (sourceId) =>
                  <FlexTableCell key={sourceId} className='source-review-quality-cell'>
                    <Rating
                      onRate={@rateSourceReviewQuality sourceId}
                      rating={@props.sources[sourceId].qualityOfReview}
                    />
                  </FlexTableCell>
                }
              </FlexTableRow>
              <FlexTableRow>
                <FlexTableFixedCell className='review-comment-cell'>
                  {@i18n '../review_comments'}
                </FlexTableFixedCell>
                {@props.sourcesOrder.map (sourceId) =>
                  sourceComment  = @props.sources[sourceId].comment
                  <FlexTableCell key={sourceId} className='source-review-comment-cell'>
                    <SourceComment
                      onApply={@addSourceComment sourceId}
                      comment={sourceComment}
                    />
                  </FlexTableCell>
                }
              </FlexTableRow>
            </FlexTable>
          </div>
        </div>
      </Card>
      <h2>
        {@i18n '../module.outcomes_comparison'}
        <div>{@i18n '../module.outcomes_comparison_description'}</div>
      </h2>
      <Card>
        <div className='card-content'>
          <div className='outcomes-mapping'>
            <FlexTable className='outcomes-mapping-table'>
              <FlexTableRow>
                <FlexTableFixedCell />
                {@props.sourcesOrder.map (sourceId) =>
                  <FlexTableCell className='source-name-cell' key={sourceId}>
                    {@props.sources[sourceId].sourceName}
                  </FlexTableCell>
                }
              </FlexTableRow>
              {reviewedOutcomes.map ({ _id, sourcesOutcomesMapping, name }) =>
                <FlexTableRow key={_id}>
                  <FlexTableFixedCell className='outcome-name'>
                    {name}
                  </FlexTableFixedCell>
                  {@props.sourcesOrder.map (sourceId) =>
                    sourceOutcomes = @props.sources[sourceId].outcomes
                    sourceOutcomesByReviewedOutcomes = @sourceOutcomesByReviewedOutcomes(
                      sourceOutcomes
                    )
                    mappedSourceOutcome = R.find(
                      R.whereEq(_id: sourcesOutcomesMapping[sourceId]),
                      sourceOutcomes
                    )

                    <FlexTableCell className='source-mapping' key={sourceId}>
                      <OutcomesMappingSelect
                        questionId={@props.questionId}
                        reviewedOutcomeId={_id}
                        sourceId={sourceId}
                        mappedSourceOutcome={mappedSourceOutcome}
                        sourceOutcomes={sourceOutcomes}
                        sourceOutcomesByReviewedOutcomes={sourceOutcomesByReviewedOutcomes}
                      />
                    </FlexTableCell>
                  }
                </FlexTableRow>
              }
            </FlexTable>
          </div>
        </div>
      </Card>
    </div>

module.exports = SourcesTab
