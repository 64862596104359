ConnectStore = require 'components/enhancers/connect_store'
DropdownMenu = require 'components/common/dropdown_menu'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
NMAListActions = require 'actions/nma_list_actions'
NMAQuestionModal = require 'components/nma/nma_question_modal'
NMAQuestionStore = require 'stores/nma_question_store'
NMAToolbarWithQuestionList = require 'components/nma/nma_toolbar_with_question_list'
Router = require 'router'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

NMAQuestionToolbar = ({ question }) ->
  i18n = useI18n 'nma:questions_list'
  questionTitle = question.get 'question', ''

  onGoToNMATable = useCoffeeCallback [question], ->
    mediator.publish '!router:route',
      Router::getProjectRelativeUrl "/nma/#{question.get '_id'}/viz"

  onMenuSelect = useCoffeeCallback [question, questionTitle], (option) ->
    switch option
      when 'edit'
        NMAListActions.openQuestionModal question
      when 'delete'
        confirmOptions =
          title: i18n 'confirm_delete.title'
          message: i18n 'confirm_delete.message', { questionTitle }
          confirmText: i18n '/actions.delete'
          declineText: i18n '/actions.cancel'
          yesClass: 'danger'
        mediator.dialogs.confirm confirmOptions, (confirmed) ->
          return unless confirmed
          NMAListActions.deleteQuestion(question.toJS()).then ->
            mediator.publish '!router:route', Router::getProjectRelativeUrl '/nma'

  rightElement = useCoffeeMemo [i18n, onGoToNMATable, onMenuSelect], ->
    <React.Fragment>
      <button className="btn btn-apply-inverted" onClick={onGoToNMATable}>
        {i18n '/actions.go_to_nma_table'}
      </button>
      <DropdownMenu
        className="question__menu"
        menuPosition={alignment: 'end'}
        onSelect={onMenuSelect}
        opener={<IconButton className="question__menu" iconName="more" />}
        options={[
          className: 'edit'
          text: i18n '/actions.edit'
          value: 'edit'
        ,
          className: 'delete'
          text: i18n '/actions.delete'
          value: 'delete'
        ]}
      />
      <NMAQuestionModal/>
    </React.Fragment>

  <NMAToolbarWithQuestionList rightElement={rightElement} />

storeConnector =
  NMAQuestionStore: (Store) ->
    question: Store.getCurrentQuestion()

module.exports = ConnectStore NMAQuestionToolbar, [NMAQuestionStore], storeConnector
