DatePicker = require 'components/common/date_picker'
GdtEditor = Editor.Editor
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
Validation = require 'base/validation'
{ instanceOf, string, func, oneOfType, number} = PropTypes

FAKE_LINK = "#{window.location.protocol}//#{window.location.hostname}/…"

VotingMessageForm = createReactClass

  displayName: "MessageForm"

  propTypes:
    type: string.isRequired
    message: oneOfType([instanceOf(Immutable.Map), string]).isRequired
    setDueDate: func.isRequired
    contactEmail: string
    dueDateTimestamp: number

  mixins: [
    Translation('voting:voting.modals')
  ]

  getInitialState: ->
    if @props.type in ['start', 'resend', 'test']
      contactEmail: @props.contactEmail
      datePickerShow: false
    else
      {}

  keepOriginalMessage: true

  _isPastDay: (day) ->
    DayPicker.DateUtils.isPastDay day

  _hasFailedValidation: (fieldName) ->
    @props.validationFault?.indexOf(fieldName) > -1

  _getValidationFailText: (fieldName) ->
    return unless @_hasFailedValidation(fieldName)

    if fieldName is 'contactEmail' then @i18n 'valid_email_required' else @i18n 'is_required_field'

  setDueDate: (e, date, { disabled }) ->
    @props.setDueDate e, date, { disabled }

  getTextContent: ->
    ReactDOM.findDOMNode(@editor).textContent

  render: ->
    currentDate = new Date()
    dueDate = new Date(@props.dueDateTimestamp).toLocaleDateString() if @props.dueDateTimestamp
    contactEmailClasses = classNames 'contact_email',
      'validation-fault': @_hasFailedValidation 'contactEmail'

    <div className="message-form">
      <div className="message-block">
        <span className="section-caption">{@i18n 'message'} *</span>
        <span className="validation-text">{@_getValidationFailText 'emailBody'}</span>
        <div className='message-text'>
          <GdtEditor
            ref={(el) => @editor = el}
            editorContent={@props.message}
          />
          <Tooltip>
            <div className='fake-link-block' title={@i18n 'link_is_generated_automatically'}>
              <span>{@i18n 'please_follow_link'}</span>
              <br />
              <span>{FAKE_LINK}</span>
            </div>
          </Tooltip>
        </div>
      </div>
      {if @props.type in ['start', 'resend', 'test']
        <div className="meta-block">
          <div className="due-date-block">
            <span className="section-caption">{@i18n 'due_date'}</span>
            <DatePicker
              onSave={@setDueDate}
              modifiers={disabled: @_isPastDay}
              selectedDays={[new Date(@props.dueDateTimestamp)]}
              canSelectMonth={false}
              value={dueDate}
              fromYear={currentDate.getFullYear()}
              toYear={currentDate.getFullYear() + 10}
            />
          </div>
          <div className="contact-email">
            <span className="section-caption">
              {@i18n 'contact_email'} *
              <span className="smaller">({@i18n 'contact_email_justification'})</span>
            </span>
            <input
              ref="contactEmail"
              className={contactEmailClasses}
              defaultValue={@props.contactEmail}
              onChange={@props.updateEmail}
            />
            <span className="validation-text email">{@_getValidationFailText 'contactEmail'}</span>
          </div>
        </div>
      }
    </div>

module.exports = VotingMessageForm
