var Model, Reference, authorsToString, setMd5Hash, vancouverFormat, _ref,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

_ref = require('lib/references_utils'), vancouverFormat = _ref.vancouverFormat, setMd5Hash = _ref.setMd5Hash, authorsToString = _ref.authorsToString;

module.exports = Reference = (function(_super) {
  __extends(Reference, _super);

  function Reference() {
    return Reference.__super__.constructor.apply(this, arguments);
  }

  Reference.prototype.defaults = {
    authors: [],
    title: '',
    year: '',
    publishedIn: '',
    added: new Date().getTime(),
    status: 'inScreening',
    volume: '',
    issue: '',
    pages: '',
    abstract: '',
    tags: [],
    authorKeywords: [],
    DOI: '',
    PMID: '',
    source: ''
  };

  Reference.prototype.initialize = function() {
    Reference.__super__.initialize.apply(this, arguments);
    return this.on('change', function() {
      return this.set('lastUpdated', new Date().getTime());
    });
  };

  Reference.prototype.setMd5Hash = function() {
    return this.set(setMd5Hash(this.toJSON()));
  };

  Reference.prototype.getFormattedAuthors = function() {
    var _ref1;
    return authorsToString((_ref1 = this.get('authors')) != null ? _ref1 : []);
  };

  Reference.prototype.getExtractedYear = function() {
    var _ref1;
    return (_ref1 = this.get('year').match(/\d{4}/)) != null ? _ref1[0] : void 0;
  };

  Reference.prototype.include = function() {
    this.set('status', 'included');
    return this.save();
  };

  Reference.prototype.exclude = function() {
    this.set('status', 'excluded');
    return this.save();
  };

  Reference.prototype.vancouverFormat = function() {
    return vancouverFormat(this.toJSON());
  };

  return Reference;

})(Model);
