var Controller, SidebarController, SidebarView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Controller = require('controllers/base/controller');

SidebarView = require('views/sidebar_view');

mediator = require('mediator');

module.exports = SidebarController = (function(_super) {
  __extends(SidebarController, _super);

  function SidebarController() {
    return SidebarController.__super__.constructor.apply(this, arguments);
  }

  SidebarController.prototype.initialize = function() {
    SidebarController.__super__.initialize.apply(this, arguments);
    return this.view = new SidebarView();
  };

  return SidebarController;

})(Controller);
