var DonationView, ModalView, W, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

ModalView = require('base/views/modal_view');

template = require('views/templates/donation');

W = require('when');

module.exports = DonationView = (function(_super) {
  __extends(DonationView, _super);

  function DonationView() {
    return DonationView.__super__.constructor.apply(this, arguments);
  }

  DonationView.prototype.title = $.t('donation.title');

  DonationView.prototype.className = 'donation-view';

  DonationView.prototype.template = template;

  DonationView.prototype.initialize = function() {
    this.options.position = 'center';
    this.options.fadeInMs = 300;
    this.options.fadeOutMs = 0;
    this.options.closeBtn = true;
    this.options.cls = 'donation-modal';
    this.delegate('change', 'form', this.handleFormChange);
    this.delegate('submit', 'form', this.handleFormSubmit);
    this.delegate('click', 'button.close', this.dispose);
    this._deferred = W.defer();
    return DonationView.__super__.initialize.apply(this, arguments);
  };

  DonationView.prototype.getTemplateData = function() {
    return {
      skipDonationDialog: mediator.user.get('skipDonationDialog'),
      explicitlyTriggered: this.options.explicit,
      donateAmountOptions: [50, 100, 200, 500]
    };
  };

  DonationView.prototype.handleFormChange = function(evt) {
    var selectedAmount;
    selectedAmount = this.dialog.$el.find('input:checked').prop('value');
    return this.$('button.donate').prop('disabled', selectedAmount == null);
  };

  DonationView.prototype.handleFormSubmit = function(evt) {
    evt.target.submit();
    return _.defer(this.dispose);
  };

  DonationView.prototype.promise = function() {
    return this._deferred.promise;
  };

  DonationView.prototype.dispose = function() {
    if (!this.disposed) {
      if (!this.options.explicit) {
        mediator.user.save('skipDonationDialog', this.$('#dont-show-again input').prop('checked'));
      }
      this._deferred.resolve();
    }
    return DonationView.__super__.dispose.apply(this, arguments);
  };

  return DonationView;

})(ModalView);
