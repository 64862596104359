var CoiInputView, CoiMatrixView, CoiToolbarView, CollectionView, QuestionCoiView, Scrolling, Shortcuts, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

QuestionCoiView = require('./question_coi_view');

CoiToolbarView = require('./coi_toolbar_view');

CoiInputView = require('./coi_input_view');

Shortcuts = require('base/lib/traits/shortcuts');

Scrolling = require('base/lib/traits/scrolling');

template = require('views/templates/team/coi_matrix');

mediator = require('mediator');

module.exports = CoiMatrixView = (function(_super) {
  __extends(CoiMatrixView, _super);

  function CoiMatrixView() {
    this._getMemberInfo = __bind(this._getMemberInfo, this);
    return CoiMatrixView.__super__.constructor.apply(this, arguments);
  }

  CoiMatrixView.prototype.container = '#page-container';

  CoiMatrixView.prototype.className = 'coi-view';

  CoiMatrixView.prototype.template = template;

  CoiMatrixView.prototype.itemView = QuestionCoiView;

  CoiMatrixView.prototype.animationDuration = 10;

  CoiMatrixView.prototype.listSelector = 'tbody';

  CoiMatrixView.prototype.shortcuts = {
    'r': 'render'
  };

  CoiMatrixView.prototype.initialize = function(options) {
    this.enable(Shortcuts);
    CoiMatrixView.__super__.initialize.apply(this, arguments);
    this.project = mediator.project;
    this.members = mediator.colls.teamMembers;
    this.membersWithConflict = mediator.colls.teamMembers.filter(function(m) {
      return m.hasConflict();
    });
    this.itemViewOptions = {
      members: _(this.membersWithConflict).pluck('id')
    };
    this.subview('toolbar', new CoiToolbarView({
      parentView: this
    }));
    this.members.on('change', this.render, this);
    this.project.on('change:coiFormCreated', this.render, this);
    return this.delegate('click', 'td, th', (function(_this) {
      return function(e) {
        var el, member, memberId, _ref;
        el = $(e.target);
        if ((_ref = e.target.nodeName) !== 'TD' && _ref !== 'TH') {
          el = el.parent('td, th');
        }
        memberId = el.data('member');
        if (!memberId) {
          return;
        }
        member = _this.members.get(memberId);
        return _this.subview('inputView', new CoiInputView({
          collection: _this.collection,
          member: member,
          coiFormType: mediator.project.get('coiFormType')
        }));
      };
    })(this));
  };

  CoiMatrixView.prototype._getMemberBinding = function(member) {
    var binding;
    binding = {};
    binding["th[data-member='" + member.id + "']"] = {
      observe: ['givenNames', 'lastName'],
      onGet: function(values) {
        return values.join(' ');
      }
    };
    return binding;
  };

  CoiMatrixView.prototype.afterRender = function() {
    var member, _i, _len, _ref, _results;
    CoiMatrixView.__super__.afterRender.apply(this, arguments);
    _ref = this.members.models;
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      member = _ref[_i];
      _results.push(this.stickit(member, this._getMemberBinding(member)));
    }
    return _results;
  };

  CoiMatrixView.prototype.dispose = function() {
    var member, _i, _len, _ref;
    _ref = this.members.models;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      member = _ref[_i];
      this.unstickit(member);
    }
    CoiMatrixView.__super__.dispose.apply(this, arguments);
    this.members.off(null, null, this);
    return this.project.off(null, null, this);
  };

  CoiMatrixView.prototype.getTemplateData = function() {
    return {
      members: this.membersWithConflict.map(this._getMemberInfo)
    };
  };

  CoiMatrixView.prototype._getMemberInfo = function(member) {
    return {
      id: member.id
    };
  };

  return CoiMatrixView;

})(CollectionView);
