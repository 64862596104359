var ProjectsComponent, ProjectsListView, ReactComponent, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ReactComponent = require('base/lib/traits/react_component');

ProjectsComponent = require('components/projects/projects');

View = require('base/views/view');

mediator = require('mediator');

module.exports = ProjectsListView = (function(_super) {
  __extends(ProjectsListView, _super);

  function ProjectsListView() {
    return ProjectsListView.__super__.constructor.apply(this, arguments);
  }

  ProjectsListView.prototype.container = '#page-container';

  ProjectsListView.prototype.autoRender = true;

  ProjectsListView.prototype.className = 'welcome-view';

  ProjectsListView.prototype.initialize = function() {
    this._stopInputsPropagation = false;
    ProjectsListView.__super__.initialize.apply(this, arguments);
    mediator.publish('projectChanged', null);
    return this.enable(ReactComponent);
  };

  ProjectsListView.prototype.afterRender = function() {
    document.getElementById('page-container').classList.add('welcome-screen');
    ProjectsListView.__super__.afterRender.apply(this, arguments);
    $('.outer-container').addClass('welcome-screen-container');
    $('#topbar').css('display', 'none');
    mediator.publish('!replicateBgProjects');
    return this.renderSimpleComponent(ProjectsComponent, {
      organizationId: this.options.organizationId,
      onboardingAutostart: this.options.onboardingAutostart
    });
  };

  ProjectsListView.prototype.dispose = function() {
    this.unmountComponent();
    document.getElementById('page-container').classList.remove('welcome-screen');
    $('.outer-container').removeClass('welcome-screen-container');
    $('#topbar').css('display', 'block');
    return ProjectsListView.__super__.dispose.apply(this, arguments);
  };

  return ProjectsListView;

})(View);
