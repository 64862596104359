var Migration, SensitivitySpecificityZeroToNull, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = SensitivitySpecificityZeroToNull = (function(_super) {
  __extends(SensitivitySpecificityZeroToNull, _super);

  function SensitivitySpecificityZeroToNull() {
    SensitivitySpecificityZeroToNull.__super__.constructor.call(this, false);
  }

  SensitivitySpecificityZeroToNull.prototype.up = function(project, colls) {
    var attrs, question, zeroToNull;
    SensitivitySpecificityZeroToNull.__super__.up.apply(this, arguments);
    attrs = ['pooledSensitivity', 'pooledSensitivityFrom', 'pooledSensitivityTo', 'pooledSpecificity', 'pooledSpecificityFrom', 'pooledSpecificityTo', 'pooledSensitivityComparison', 'pooledSensitivityComparisonFrom', 'pooledSensitivityComparisonTo', 'pooledSpecificityComparison', 'pooledSpecificityComparisonFrom', 'pooledSpecificityComparisonTo', 'pooledSensitivityRangeFrom', 'pooledSensitivityRangeTo', 'pooledSensitivityComparisonRangeFrom', 'pooledSensitivityComparisonRangeTo', 'pooledSpecificityRangeFrom', 'pooledSpecificityRangeTo', 'pooledSpecificityComparisonRangeFrom', 'pooledSpecificityComparisonRangeTo'];
    zeroToNull = function(question) {
      var attr, _i, _len;
      for (_i = 0, _len = attrs.length; _i < _len; _i++) {
        attr = attrs[_i];
        if (question.get(attr) === 0) {
          question.set(attr, null);
        }
      }
      return question.save();
    };
    return W.all((function() {
      var _i, _len, _ref, _results;
      _ref = colls.questions.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        question = _ref[_i];
        _results.push(zeroToNull(question));
      }
      return _results;
    })())["catch"](function(err) {
      console.log(err);
      throw err;
    });
  };

  return SensitivitySpecificityZeroToNull;

})(Migration);
