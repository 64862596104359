var EtdV1ManagementRecommendationsExporter, RecommendationsExporter,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

RecommendationsExporter = require('./recommendations_exporter');

module.exports = EtdV1ManagementRecommendationsExporter = (function(_super) {
  __extends(EtdV1ManagementRecommendationsExporter, _super);

  function EtdV1ManagementRecommendationsExporter(_originalQuestion) {
    this._exportResearchPossibilities = __bind(this._exportResearchPossibilities, this);
    this._exportMonitoringAndEvaluation = __bind(this._exportMonitoringAndEvaluation, this);
    this._exportImplementationConsiderations = __bind(this._exportImplementationConsiderations, this);
    this._exportSubgroupConsiderations = __bind(this._exportSubgroupConsiderations, this);
    this._exportJustification = __bind(this._exportJustification, this);
    this._exportRecommendationText = __bind(this._exportRecommendationText, this);
    this._exportRecommendationType = __bind(this._exportRecommendationType, this);
    this._exportConsequencesBalance = __bind(this._exportConsequencesBalance, this);
    EtdV1ManagementRecommendationsExporter.__super__.constructor.call(this, _originalQuestion);
  }

  EtdV1ManagementRecommendationsExporter.prototype._exportProblemSection = function() {
    var priorityCriterion;
    priorityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('problem', 'is_priority'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['priorityProblemJudgement']), this._originalQuestion['priorityProblemResearchResearchEvidence'], this._originalQuestion['priorityProblemAdditionalConsiderations']);
    return this._makeSection('ProblemSection', this._getTitleForSectionLabel('problem'), priorityCriterion);
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportBenefitsAndHarmsOfTheOptionsSection = function() {
    var certaintyOfEvidenceCriterion, desirableAnticipatedEffectsLargeCriterion, uncertaintyAboutMainOutcomesCriterion, undesirableAnticipatedEffectsSmallCriterion;
    certaintyOfEvidenceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('benefits_harms', 'criteria_1'), this._certaintyMgmtOptions, this._getNameForOptionLabel(this._originalQuestion['overallCertaintyJudgement']));
    uncertaintyAboutMainOutcomesCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('benefits_harms', 'criteria_2'), this._uncertaintyOptions, this._getNameForOptionLabel(this._originalQuestion['uncertaintyAboutMainOutcomesJudgement']));
    desirableAnticipatedEffectsLargeCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('benefits_harms', 'criteria_3'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['desirableAnticipatedEffectsLargeJudgement']));
    undesirableAnticipatedEffectsSmallCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('benefits_harms', 'criteria_4'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['undesirableAnticipatedEffectsSmallJudgement']));
    return this._makeSection('BenefitsAndHarmsOfTheOptionsSection', this._getTitleForSectionLabel('benefits_harms'), [certaintyOfEvidenceCriterion, uncertaintyAboutMainOutcomesCriterion, desirableAnticipatedEffectsLargeCriterion, undesirableAnticipatedEffectsSmallCriterion], this._originalQuestion['benefitsHarmsResearchEvidence'], this._originalQuestion['benefitsHarmsAdditionalConsiderations']);
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportResourceUseSection = function() {
    var incrementalConstToNetBenefitsCriterion, resourcesRequiredSmallCriterion;
    resourcesRequiredSmallCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('resource_use', 'criteria_1'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['resourcesRequiredSmallJudgement']), this._originalQuestion['resourcesRequiredSmallResearchEvidence'], this._originalQuestion['resourcesRequiredSmallAdditionalConsiderations']);
    incrementalConstToNetBenefitsCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('resource_use', 'criteria_2'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['incrementalCostToNetBenefitsJudgement']), this._originalQuestion['incrementalCostToNetBenefitsResearchEvidence'], this._originalQuestion['incrementalCostToNetBenefitsAdditionalConsiderations']);
    return this._makeSection('ResourceUseSection', this._getTitleForSectionLabel('resource_use'), [resourcesRequiredSmallCriterion, incrementalConstToNetBenefitsCriterion]);
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportEquitySection = function() {
    var inequitiesCriterion;
    inequitiesCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('equity', 'criteria'), this._inequitiesOptions, this._getNameForOptionLabel(this._originalQuestion['equityJudgement']));
    return this._makeSection('EquitySection', this._getTitleForSectionLabel('equity'), inequitiesCriterion, this._originalQuestion['equityResearchEvidence'], this._originalQuestion['equityAdditionalConsiderations']);
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportAcceptabilitySection = function() {
    var acceptabilityCriterion;
    acceptabilityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('acceptability', 'is_option_acceptable'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['acceptabilityJudgement']));
    return this._makeSection('AcceptabilitySection', this._getTitleForSectionLabel('acceptability'), acceptabilityCriterion, this._originalQuestion['acceptabilityResearchEvidence'], this._originalQuestion['acceptabilityAdditionalConsiderations']);
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportFeasibilitySection = function() {
    var feasibilityCriterion;
    feasibilityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('feasibility', 'is_option_feasible'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['feasibilityJudgement']));
    return this._makeSection('FeasibilitySection', this._getTitleForSectionLabel('feasibility'), feasibilityCriterion, this._originalQuestion['feasibilityResearchEvidence'], this._originalQuestion['feasibilityAdditionalConsiderations']);
  };

  EtdV1ManagementRecommendationsExporter.prototype.exportSections = function() {
    return [this._exportProblemSection(), this._exportBenefitsAndHarmsOfTheOptionsSection(), this._exportResourceUseSection(), this._exportEquitySection(), this._exportAcceptabilitySection(), this._exportFeasibilitySection()];
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportConsequencesBalance = function() {
    var consequencesBalance;
    consequencesBalance = this._originalQuestion['consequencesBalance'];
    if (consequencesBalance) {
      return $.t("es:recommendations.table.consequences_balance." + consequencesBalance);
    } else {
      return '';
    }
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportRecommendationType = function() {
    var recommendationType;
    recommendationType = this._originalQuestion['recommendationType'];
    if (recommendationType) {
      return $.t("es:recommendations.table.recommendation." + recommendationType);
    } else {
      return $.t('es:recommendations.table.recommendation.no_recommendation');
    }
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportRecommendationText = function() {
    var _ref;
    return (_ref = this._originalQuestion['recommendationText']) != null ? _ref : '';
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportJustification = function() {
    var _ref;
    return (_ref = this._originalQuestion['recommendationJustification']) != null ? _ref : '';
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportSubgroupConsiderations = function() {
    var _ref;
    return (_ref = this._originalQuestion['subgroupConsiderations']) != null ? _ref : '';
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportImplementationConsiderations = function() {
    var _ref;
    return (_ref = this._originalQuestion['implementationConsiderations']) != null ? _ref : '';
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportMonitoringAndEvaluation = function() {
    var _ref;
    return (_ref = this._originalQuestion['monitoringAndEvaluation']) != null ? _ref : '';
  };

  EtdV1ManagementRecommendationsExporter.prototype._exportResearchPossibilities = function() {
    var _ref;
    return (_ref = this._originalQuestion['researchPossibilities']) != null ? _ref : '';
  };

  EtdV1ManagementRecommendationsExporter.prototype.exportConclusions = function() {
    return {
      'consequencesBalance': this._exportConsequencesBalance(),
      'recommendationType': this._exportRecommendationType(),
      'recommendationText': this._exportRecommendationText(),
      'justification': this._exportJustification(),
      'subgroupConsiderations': this._exportSubgroupConsiderations(),
      'implementationConsiderations': this._exportImplementationConsiderations(),
      'monitoringAndEvaluation': this._exportMonitoringAndEvaluation(),
      'researchPossibilities': this._exportResearchPossibilities()
    };
  };

  EtdV1ManagementRecommendationsExporter.prototype.exportHeader = function() {
    return null;
  };

  EtdV1ManagementRecommendationsExporter.prototype.getJsonLdType = function() {
    switch (this._originalQuestion['recommendationType']) {
      case 'strongly_positive':
      case 'positive':
        return 'MedicalGuidelineRecommendation';
      case 'strongly_negative':
      case 'negative':
        return 'MedicalGuidelineContraindication';
      default:
        return null;
    }
  };

  return EtdV1ManagementRecommendationsExporter;

})(RecommendationsExporter);
