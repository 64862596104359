Phase2VotingBlock = require 'components/administration/voting/voting_phase2_block'
TextWithHelpLink = require 'components/administration/voting/voting_text_with_links'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

VotingPhase2Start = createReactClass
  displayName: 'VotingPhase2Start'

  propTypes:
    questionsByEtdPart: PropTypes.instanceOf(Immutable.Map)

  mixins: [
    CustomRenderMixin
    Translation('voting:voting')
  ]

  render: ->
    <div>
      <div className="phase2-voting-start">
        <div className="section-text">
          <TextWithHelpLink
            text={@i18n 'phase2_title'}
            links={link1: ['votingFormAllSections', @i18n 'etd_parts']}
            onInfoLinkClick={@props.onInfoLinkClick}
          />
          <b>{@i18n 'phase2'}</b>
        </div>
        <div className="etd-parts">
          {@props.questionsByEtdPart.map (questions, etdPart) =>
            <Phase2VotingBlock
              key={etdPart}
              projectId={@props.projectId}
              questions={questions}
              questionGroups={@props.questionGroups}
              etds={@props.etds}
              lastlyVoted={etdPart}
              getEtdPartLables={@props.getEtdPartLables}
              selectedContentOption={@props.selectedContentOption}
              message={@props.message}
              dueDate={@props.dueDate}
              contactEmail={@props.contactEmail}
              sendVoting={@props.sendVoting}
              canVotingBeStarted={@props.canVotingBeStarted}
              updateMessageDetails={@props.updateMessageDetails}
              updateVotingOption={@props.updateVotingOption}
              saveVotingPreferences={@props.saveVotingPreferences}
              onInfoLinkClick={@props.onInfoLinkClick}
            />
          .toList()
          }
        </div>
      </div>
    </div>

module.exports = VotingPhase2Start
