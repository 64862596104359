var EditorDecorators, ReferenceCitationMixin, insertTextContent;

EditorDecorators = require('lib/editor_decorators');

insertTextContent = Editor.EditorUtils.insertTextContent;

ReferenceCitationMixin = function(editorReferenceName) {
  if (editorReferenceName == null) {
    editorReferenceName = 'gdtEditor';
  }
  return {
    getInitialState: function() {
      return {
        showReferencesInsert: false
      };
    },
    getReferenceToggleSpec: function() {
      return {
        label: 'references',
        onToggle: this.toggleReferencesInsert
      };
    },
    getReferenceCitationDecorator: function() {
      return EditorDecorators.referenceDecorator;
    },
    onInsertReferences: function(data) {
      var referenceEntity;
      referenceEntity = {
        type: 'REFERENCE',
        mutability: 'IMMUTABLE',
        data: data
      };
      this.toggleReferencesInsert();
      return this[editorReferenceName].insertText(')', referenceEntity);
    },
    toggleReferencesInsert: function() {
      var isShowing;
      isShowing = this.state.showReferencesInsert;
      if (!isShowing) {
        this[editorReferenceName].keepEditorActive();
      }
      this.setState({
        showReferencesInsert: !isShowing
      });
      if (isShowing) {
        return this[editorReferenceName].focus();
      }
    },
    handleReferencePaste: function(editorState, text, html) {
      var tempDoc;
      if ((html != null)) {
        tempDoc = new DOMParser().parseFromString(html, "text/html");
        if (tempDoc.body.querySelector('[data-gdt-ref-ids]') !== null) {
          return Array.prototype.reduce.call(tempDoc.body.children, function(editorState, $contentBlock, idx) {
            var isBlock, refs, _ref;
            isBlock = Boolean((_ref = $contentBlock.dataset) != null ? _ref.block : void 0);
            refs = isBlock ? Array.prototype.slice.call($contentBlock.querySelectorAll('[data-gdt-ref-ids]')) : $contentBlock.hasAttribute('data-gdt-ref-ids') ? [$contentBlock] : [];
            text = $contentBlock.textContent;
            if (isBlock && idx > 0) {
              editorState = insertTextContent(editorState, '\n');
            }
            if (refs.length === 0) {
              return insertTextContent(editorState, text);
            }
            editorState = refs.map(function($ref) {
              var _ref1;
              return [$ref.textContent, (_ref1 = $ref.dataset) != null ? _ref1.gdtRefIds.split(',') : void 0];
            }).reduce(function(editorState, _arg) {
              var refIds, refOffset, refText, referenceEntity, textAfter, textBefore;
              refText = _arg[0], refIds = _arg[1];
              refOffset = text.indexOf(refText);
              if (refIds) {
                textBefore = text.slice(0, refOffset);
                textAfter = text.slice(refOffset + refText.length);
                referenceEntity = {
                  type: 'REFERENCE',
                  mutability: 'IMMUTABLE',
                  data: {
                    refIds: refIds
                  }
                };
                if (textBefore !== '') {
                  editorState = insertTextContent(editorState, textBefore);
                }
                text = textAfter;
                return insertTextContent(editorState, ')', referenceEntity);
              } else {
                textBefore = text.slice(0, refOffset + refText.length);
                textAfter = text.slice(textBefore.length);
                text = textAfter;
                return insertTextContent(editorState, textBefore);
              }
              return editorState;
            }, editorState);
            if (text !== '') {
              editorState = insertTextContent(editorState, text);
            }
            return editorState;
          }, editorState);
        }
      }
      return null;
    }
  };
};

module.exports = ReferenceCitationMixin;
