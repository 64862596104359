var Controller, Router, WelcomeController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Controller = require('controllers/base/controller');

Router = require('router');

mediator = require('mediator');

module.exports = WelcomeController = (function(_super) {
  __extends(WelcomeController, _super);

  function WelcomeController() {
    return WelcomeController.__super__.constructor.apply(this, arguments);
  }

  WelcomeController.prototype.historyURL = '';

  WelcomeController.prototype.index = function(params) {
    var action, isMemberOfOrganization, redirectURL;
    isMemberOfOrganization = !_.isEmpty(mediator.userOrganizations);
    redirectURL = isMemberOfOrganization ? 'organizations' : 'projects';
    action = isMemberOfOrganization ? 'index' : 'list';
    this.redirectTo(redirectURL, action, null, {
      forceStartup: true
    });
    return Router.prototype.changeURL(redirectURL);
  };

  return WelcomeController;

})(Controller);
