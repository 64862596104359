Switcher = (props) ->
  classes = classNames "switcher-container", props.className
  <div className={classes}>
    <label className="switcher" onClick={props.onChange} >
      <input type="checkbox" disabled="disabled" checked={props.checked} />
      <div className="slider round" />
    </label>
    <button onClick={props.onChange} disabled={props.disabled}>
      <span className="switch-btn-text">{props.buttonText}</span>
    </button>
  </div>

module.exports = Switcher
