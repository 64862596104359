ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
MDGSearchStrategyActions = require 'actions/mdg_search_strategy_actions'
MDGSearchStrategyStore = require 'stores/mdg_search_strategy_store'
Modal = require 'components/common/modal'
SearchDateField = require 'components/mdg_tables/search_date_field'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

FORM = [
  fieldKey: 'treatmentTopic'
  showClear: false
  type: 'string'
,
  fieldKey: 'diagnosis'
  showClear: false
  type: 'string'
,
  fieldKey: 'searchTermUsed'
  showClear: false
  type: 'textarea'
,
  fieldKey: 'numberOfSavedArticles'
  showClear: false
  type: 'string'
,
  fieldKey: 'searchTime'
  showClear: false
  type: 'string'
,
  component: SearchDateField
  fieldKey: 'searchDate'
  showClear: false
  type: 'custom'
,
  fieldKey: 'lastUpdate'
  showClear: false
  type: 'string'
,
  fieldKey: 'notes'
  showClear: false
  type: 'textarea'
]

storeConnector =
  MDGSearchStrategyStore: (Store) ->
    isOpen: Store.isGeneralInformationModalOpen()
    generalInformationModalState: Store.getGeneralInformationModalState()

MDGSearchStrategyGeneralInformationModal = ({
  isOpen
  generalInformationModalState
  searchStrategyId
}) ->
  i18n = useI18n('mdg_tables:search_strategy.general_information')

  onApply = useCoffeeCallback [
    MDGSearchStrategyActions
    generalInformationModalState
    searchStrategyId
  ], ->
    MDGSearchStrategyActions.saveGeneralInformation searchStrategyId,
      generalInformationModalState.toJS()

  onCancel = useCoffeeCallback [MDGSearchStrategyActions], ->
    MDGSearchStrategyActions.closeGeneralInformationModal()

  onChange = useCoffeeCallback [MDGSearchStrategyActions], (field, newValue) ->
    MDGSearchStrategyActions.updateGeneralInformationField field, newValue

  <Modal className="mdg-search-strategy-general-information-modal" isOpen={isOpen}>
    <ExtractionForm
      className="mdg-search-strategy-general-information-modal__form"
      data={generalInformationModalState.toJS()}
      form={main: FORM}
      i18n={i18n}
      onChange={onChange}
    />
    <ApplyCancelButtons
      applyLabel={i18n '/actions.save'}
      onApply={onApply}
      onCancel={onCancel}
    />
  </Modal>

MDGSearchStrategyGeneralInformationModal.propTypes =
  isOpen: PropTypes.bool.isRequired
  generalInformationModalState: PropTypes.instanceOf(Immutable.Map).isRequired
  searchStrategyId: PropTypes.string.isRequired

module.exports = ConnectStore MDGSearchStrategyGeneralInformationModal, MDGSearchStrategyStore,
  storeConnector
