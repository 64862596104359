var questionsShowCommentsMixin, showCommentsMixin;

showCommentsMixin = require('components/mixins/show_comments_mixin');

questionsShowCommentsMixin = _.extend({}, showCommentsMixin, {
  _isShowCommentsDisabled: function() {
    var noComments;
    noComments = this.props.questions.every(function(q) {
      return q.get('memberComments', Immutable.Map()).isEmpty();
    });
    return noComments || this.state.showingAllComments;
  }
});

module.exports = questionsShowCommentsMixin;
