var AdolopedEtdActions, AdolopedEtdStore, INITIAL_STATE, alt, errorHandler, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

AdolopedEtdActions = require('actions/adoloped_etd_actions');

errorHandler = require('lib/app_utils').errorHandler;

INITIAL_STATE = Immutable.fromJS({
  _meta: {
    isFetching: false
  },
  adolopments: {}
});

AdolopedEtdStore = (function() {
  function AdolopedEtdStore() {
    this.state = INITIAL_STATE;
    this.bindActions(AdolopedEtdActions);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      getAdolopments: this.getAdolopments
    });
  }

  AdolopedEtdStore.prototype.onDbChange = function(adolopmentDocs) {
    return this.setState(this.state.set('adolopments', adolopmentDocs));
  };

  AdolopedEtdStore.prototype.onFetch = function() {
    return this.setState(this.state.withMutations(function(state) {
      return state.setIn(['_meta', 'isFetching'], true);
    }));
  };

  AdolopedEtdStore.prototype.onFetchSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'isFetching'], false);
      return state.set('adolopments', data);
    }));
  };

  AdolopedEtdStore.prototype.onFetchError = function(err) {
    this.setState(this.state.setIn(['_meta', 'isFetching'], false));
    if (err.status !== 404) {
      return errorHandler(err);
    }
  };

  AdolopedEtdStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'isFetching']);
  };

  AdolopedEtdStore.prototype.getAdolopments = function() {
    return this.state.get('adolopments');
  };

  return AdolopedEtdStore;

})();

module.exports = alt.createStore(immutable(AdolopedEtdStore), 'AdolopedEtdStore');
