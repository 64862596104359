var SearchboxView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/searchbox');

module.exports = SearchboxView = (function(_super) {
  __extends(SearchboxView, _super);

  function SearchboxView() {
    return SearchboxView.__super__.constructor.apply(this, arguments);
  }

  SearchboxView.prototype.template = template;

  SearchboxView.prototype.container = '#searchbox-container';

  SearchboxView.prototype.autoRender = true;

  return SearchboxView;

})(View);
