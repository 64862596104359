IconButton = require 'components/common/icon_button'
Input = require 'components/common/input'

InputWithControls = createReactClass

  displayName: "InputWithControls"

  propTypes:
    value: PropTypes.string,
    controls: PropTypes.node.isRequired,
    editing: PropTypes.bool,
    onRequestSave: PropTypes.func.isRequired
    onRequestCancel: PropTypes.func

  getInitialState: ->
    value: @props.value

  onChange: (e) ->
    @setState value: e.target.value

  onSave: ->
    @props.onRequestSave @state.value

  onCancel: ->
    @setState value: @props.value
    @props.onRequestCancel?()

  render: ->
    { value, controls, editing } = @props
    <div className={classNames 'input-with-controls', @props.className}>
      {if editing
        <Input
          autoFocus
          focusSupport
          onChange={@onChange}
          onRequestSave={@onSave}
          onRequestCancel={@onCancel}
          type='text'
          value={@state.value}
        />
      else
        <div className={classNames 'input-with-controls__initial-text', @props.className}>
          {value}
        </div>
      }
      {unless editing
        <div className={classNames 'input-with-controls__controls', @props.className}>
          {controls}
        </div>
      }
    </div>

module.exports = InputWithControls
