var formatters, outcomeCellTypes, viewHelperUtils, _getControlAttrs;

formatters = require('lib/formatters').outcome;

viewHelperUtils = require('lib/view_helper_utils');

outcomeCellTypes = require('models/outcome/outcome_cell_types');

_getControlAttrs = function(controlType) {
  var label, value;
  value = _(outcomeCellTypes['control'].otherFormatters).findWhere({
    contentDefaultClass: "" + controlType + "-value"
  }).footnotesAttrs.common;
  label = _(outcomeCellTypes['control'].otherFormatters).findWhere({
    contentDefaultClass: "" + controlType + "-value-label"
  }).footnotesAttrs.common;
  return label.concat(value);
};

module.exports = {
  cellsOrder: {
    "sof_regular": ["sofOutcomeLabel", "impact", "control", "riskWithIntervention", "relativeEffect", "noOfParticipantsStudies", "quality", "comments"],
    "sof_narrative": ["sofOutcomeLabel", "impact", "noOfParticipantsStudies", "quality"],
    "sof_accp_regular": ["sofOutcomeLabel", "control", "riskWithIntervention", "noOfParticipantsStudies", "impact", "quality", "relativeEffect", "absoluteEffect"],
    "sof_accp_narrative": ["sofOutcomeLabel", "noOfParticipantsStudies", "quality", "impact"],
    "sof_v3_regular": ["sofOutcomeLabel", "control", "riskWithIntervention", "riskDifferenceWithIntervention", "relativeEffect", "absoluteEffect", "impact", "quality", "whatHappens"],
    "sof_v3_narrative": ["sofOutcomeLabel", "impact", "quality"],
    "relative_importance_regular": ["sofOutcomeLabel", "relativeImportance", "quality"],
    "relative_importance_narrative": ["sofOutcomeLabel", "relativeImportance", "quality"],
    "sof_short_regular": ["sofOutcomeLabel", "impact", "control", "riskWithIntervention", "riskDifference", "relativeEffect"],
    "sof_short_narrative": ["sofOutcomeLabel", "impact"],
    "l1_sof_regular": ["testResult", "prevalences", "noOfParticipantsStudiesDiagnostic", "quality"],
    "l2_sof_regular": ["testResult", "prevalences", "noOfParticipantsStudiesDiagnostic", "quality"],
    "evidence_short_regular": ["diagOutcome", "designStudies", "testAccuracy", "prevalences", "relativeImportance"]
  },
  theadSchemas: {
    'sof_regular': [
      {
        'outcome': [2, 1],
        'absoluteEffect': [1, 2],
        'relativeEffect': [2, 1],
        'noOfParticipants': [2, 1],
        'quality': [2, 1],
        'comments': [2, 1]
      }, {
        'riskWith[comparison]': [1, 1],
        'riskWith[intervention]': [1, 1]
      }
    ],
    'sof_narrative': [
      {
        'outcome': [1, 1],
        'impact': [1, 1],
        'noOfParticipants': [1, 1],
        'quality': [1, 1]
      }
    ],
    'sof_accp_regular': [
      {
        'outcome': [2, 1],
        'noOfParticipantsFollowUp': [2, 1],
        'quality': [2, 1],
        'relativeEffect': [2, 1],
        'absoluteEffect': [1, 2]
      }, {
        'riskWith[comparison]': [1, 1],
        'riksDifferenceWith[intervention]': [1, 1]
      }
    ],
    'sof_accp_narrative': [
      {
        'outcome': [1, 1],
        'noOfParticipantsFollowUp': [1, 1],
        'quality': [1, 1],
        'impact': [1, 1]
      }
    ],
    'sof_v3_regular': [
      {
        'outcome': [2, 1],
        'relativeEffect': [2, 1],
        'absoluteEffect': [1, 3],
        'quality': [2, 1],
        'whatHappens': [2, 1]
      }, {
        'without[intervention]': [1, 1],
        'with[intervention]': [1, 1],
        'difference': [1, 1]
      }
    ],
    'sof_v3_narrative': [
      {
        'outcome': [1, 1],
        'impact': [1, 1],
        'quality': [1, 1]
      }
    ],
    'relative_importance_regular': [
      {
        'outcome': [1, 1],
        'relativeImportance': [1, 1],
        'quality': [1, 1]
      }
    ],
    'relative_importance_narrative': [
      {
        'outcome': [1, 1],
        'relativeImportance': [1, 1],
        'quality': [1, 1]
      }
    ],
    'sof_short_regular': [
      {
        'outcome': [1, 1],
        'sofControl[comparison]': [1, 1],
        'correspondingRisk[intervention]': [1, 1],
        'riskDifference': [1, 1],
        'relativeEffect': [1, 1]
      }
    ],
    'sof_short_narrative': [
      {
        'outcome': [1, 1],
        'impact': [1, 1]
      }
    ],
    'l1_sof_regular': [
      {
        'testResult': [2, 1],
        'resultsPer1000': [1, 1],
        'noOfParticipantsStudiesDiagnostic': [2, 1],
        'quality': [2, 1]
      }, {
        'prevalences': [1, 1]
      }
    ],
    'l2_sof_regular': [
      {
        'testResult': [2, 1],
        'resultsPer1000': [1, 1],
        'noOfParticipantsStudiesDiagnostic': [2, 1],
        'quality': [2, 1]
      }, {
        'prevalences': [1, 1]
      }
    ],
    'evidence_short_regular': [
      {
        'diagOutcome': [1, 1],
        'designStudies': [1, 1],
        'testAccuracy': [1, 1],
        'prevalences': [1, 1],
        'relativeImportance': [1, 1]
      }
    ]
  },
  theadLabels: {
    outcome: $.t('es:outcomes'),
    resultsPer1000: $.t('es:outcome.diagnostic.no_of_results_per_1000'),
    impact: $.t('es:outcome.impact'),
    riskWith: $.t('es:outcome.risk_with'),
    riksDifferenceWith: $.t('es:outcome.risk_difference_with'),
    absoluteEffect: "" + ($.t('es:outcome.anticipated_absolute_effects')) + "<SUP>*</SUP> (95% CI)",
    relativeEffect: "" + ($.t('es:outcome.relative_effect')) + "<BR />(95% CI)",
    noOfParticipantsStudiesDiagnostic: "" + ($.t('es:outcome.no_of_participants')) + "<BR />(" + ($.t('es:outcome.studies')) + ")",
    noOfParticipants: "" + ($.t('es:outcome.no_of_participants')) + "<BR />(" + ($.t('es:outcome.studies')) + ")",
    noOfParticipantsFollowUp: "" + ($.t('es:outcome.no_of_participants')) + "<BR /> (" + ($.t('es:outcome.studies')) + ")<BR />" + ($.t('es:outcome.follow_up')),
    quality: "" + ($.t('es:outcome.certainty_of_evidence')) + "<BR />(GRADE)",
    comments: $.t('es:outcome.comments'),
    whatHappens: $.t('es:outcome.what_happens'),
    assumedRisk: $.t('es:outcome.assumed_risk'),
    sofControl: $.t('es:outcome.with'),
    correspondingRisk: $.t('es:outcome.with'),
    riskDifference: $.t('es:outcome.difference'),
    "with": $.t('es:outcome.with'),
    without: $.t('es:outcome.without'),
    difference: $.t('es:outcome.difference'),
    prevalences: $.t('es:outcome.diagnostic.prevalences'),
    relativeImportance: $.t('es:outcome.importance'),
    testAccuracy: $.t('es:outcome.diagnostic.dta_coe'),
    diagOutcome: $.t('es:outcome.outcome'),
    designStudies: $.t('es:outcome.study_design'),
    testResult: $.t('es:outcome.test_result')
  },
  tbodyCells: {
    'resultsPer1000': function() {
      return '';
    },
    'testResult': function(attributes, options) {
      return formatters.testResult.call(formatters, attributes, options);
    },
    'diagOutcome': function(attributes) {
      return formatters.diagOutcomeLabel.call(formatters, attributes);
    },
    'outcome': function(attributes) {
      return formatters.label.call(formatters, attributes, attributes.tableView);
    },
    'contSofControl': function(attributes) {
      var designStudies, viewType, _ref;
      viewType = attributes.tableView;
      if (!attributes.designStudies) {
        return '-';
      }
      designStudies = attributes.designStudies.value;
      if ((_ref = attributes.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
        return '-';
      } else if (attributes.metaType === 'NOT_POOLED' && viewType === 'sof') {
        return $.t('es:outcome.see_comment');
      } else if ((viewType === 'sof' || viewType === 'sof_accp' || viewType === 'sof_v3') && designStudies === 'case_reports') {
        return '-';
      } else {
        return formatters.sofControl.call(formatters, _(attributes).extend({
          _viewTypeName: viewType
        }));
      }
    },
    'contCorrespondingRisk': function(attributes) {
      var _ref;
      if ((_ref = attributes.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
        return '-';
      } else if (attributes.tableView === 'sof_v3') {
        return '-';
      } else {
        return formatters._contCorrespondingRisk.call(formatters, attributes);
      }
    },
    'contRiskDifference': function(attributes) {
      var _ref;
      if ((_ref = attributes.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
        return '-';
      } else if (attributes.metaType === 'NOT_POOLED') {
        return $.t('es:outcome.see_comment');
      } else {
        return formatters.riskDifference.call(formatters, attributes);
      }
    },
    'relativeEffect': function(attributes) {
      var _ref;
      if ((_ref = attributes.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
        return '-';
      } else if (attributes.metaType === 'NOT_POOLED' && attributes.tableView === 'sof') {
        return '-';
      } else {
        return formatters.relativeEffect.call(formatters, attributes);
      }
    },
    'noOfParticipantsStudiesDiagnostic': function(attributes) {
      return formatters.noOfParticipantsStudiesDiagnostic.call(formatters, attributes);
    },
    'noOfParticipants': function(attributes) {
      var _ref;
      if ((_ref = attributes.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
        return '-';
      } else {
        return formatters.noOfParticipantsStudies.call(formatters, attributes);
      }
    },
    'noOfParticipantsFollowUp': function(attributes) {
      var _ref;
      if ((_ref = attributes.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
        return '-';
      } else {
        return formatters.noOfParticipantsStudiesFollowUp.call(formatters, attributes);
      }
    },
    'quality': function(attributes) {
      var _ref;
      if ((_ref = attributes.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
        return '-';
      } else {
        if (attributes.rm5export == null) {
          attributes.rm5export = true;
        }
        return formatters.quality.call(formatters, attributes);
      }
    },
    'testAccuracy': function(attributes) {
      var _ref;
      if ((_ref = attributes.metaType) === 'NOT_MEASURED' || _ref === 'NOT_REPORTED') {
        return '-';
      } else {
        if (attributes.rm5export == null) {
          attributes.rm5export = true;
        }
        return formatters.quality.call(formatters, attributes);
      }
    },
    'comments': function(attributes) {
      return attributes.comments || '';
    },
    'whatHappens': function(attributes) {
      return attributes.comments || '';
    },
    'impact': function(attributes) {
      return attributes.narrativeDesc || '';
    },
    'dichNotReportedSofControl': function() {
      return '-';
    },
    'dichNotReportedCorrespondingRisk': function() {
      return '-';
    },
    'dichNotReportedRiskDifference': function() {
      return '-';
    },
    'controlLabel': function(attributes, controlRisk) {
      var _ref;
      if (controlRisk === 'calculatedControl') {
        return $.t('es:outcome.printout.study_population');
      } else {
        return (_ref = attributes[controlRisk + 'Label']) != null ? _ref : $.t('es:outcome.' + _.str.underscored(controlRisk + 'Label'));
      }
    },
    'dichSofControl': function(attributes, controlRisk) {
      var formatter, viewType;
      if (attributes.metaType === "NOT_POOLED") {
        if (attributes.tableView === 'sof') {
          return $.t('es:outcome.see_comment');
        } else {
          return $.t('es:outcome.not_pooled');
        }
      } else {
        viewType = attributes.tableView;
        formatter = controlRisk === 'calculatedControl' ? 'control' : "" + controlRisk + "Risk";
        return formatters[formatter].call(formatters, _(attributes).extend({
          _viewTypeName: viewType
        }));
      }
    },
    'dichCorrespondingRisk': function(attributes, controlRisk) {
      var formatter, riskSuffix, viewType;
      if (attributes.metaType === "NOT_POOLED") {
        if (attributes.tableView === 'sof') {
          return $.t('es:outcome.see_comment');
        } else {
          return $.t('es:outcome.not_pooled');
        }
      } else {
        viewType = attributes.tableView;
        riskSuffix = viewHelperUtils.controlRiskFormatterSuffix(controlRisk);
        formatter = "correspondingRisk" + riskSuffix;
        return formatters[formatter].call(formatters, _(attributes).extend({
          _viewTypeName: viewType
        }));
      }
    },
    'dichRiskDifference': function(attributes, controlRisk) {
      var formatter, riskSuffix, viewType;
      if (attributes.metaType === "NOT_POOLED") {
        return $.t('es:outcome.not_pooled');
      } else {
        viewType = attributes.tableView;
        riskSuffix = viewHelperUtils.controlRiskFormatterSuffix(controlRisk);
        formatter = "riskDifference" + riskSuffix;
        return formatters[formatter].call(formatters, _(attributes).extend({
          _viewTypeName: viewType
        }));
      }
    },
    'designStudies': function(attributes) {
      if (attributes.designStudies != null) {
        return $.t("es:outcome." + attributes.designStudies.label);
      } else {
        return '-';
      }
    },
    'relativeImportance': function(attributes) {
      return attributes['importanceLabel'];
    },
    'Inconclusive': function(attributes) {
      return attributes.inconclusive;
    },
    'Complications': function(attributes) {
      return attributes.complications;
    }
  },
  tableCaptionSchemas: {
    'sof': ['title', 'sofMeta'],
    'sof_accp': ['title', 'sofMeta'],
    'sof_v3': ['title', 'sofMeta'],
    'sof_short': ['title'],
    'relative_importance': ['title'],
    'l1_sof': ['title'],
    'l2_sof': ['title'],
    'evidence_short': ['title']
  },
  tableCaptionCells: {
    'title': function(attributes) {
      return "<B>" + attributes.title + "</B>";
    },
    'sofMeta': function(attributes) {
      return "<B>" + ($.t('es:outcome.printout.patient_or_population')) + ": </B>" + attributes.hpOrPop + " <BR /> <B>" + ($.t('es:question.setting')) + ": </B>" + attributes.settings + " <BR /> <B>" + ($.t('es:outcome.intervention')) + ": </B>" + attributes.intervention + " <BR /> <B>" + ($.t('es:outcome.comparison')) + ": </B>" + attributes.comparison;
    }
  },
  attributesMap: {
    'outcome': outcomeCellTypes['sofOutcomeLabel'].footnotesAttrs.common,
    'sofV3OutcomeLabel': outcomeCellTypes['sofV3OutcomeLabel'].footnotesAttrs.common,
    'dichSofControl': ['controlCount', 'controlTotal'],
    'dichSofControlLow': _getControlAttrs('low'),
    'dichSofControlModerate': _getControlAttrs('moderate'),
    'dichSofControlHigh': _getControlAttrs('high'),
    'contSofControl': ['changeScoreInControlsValue', 'units'],
    'dichCorrespondingRisk': ['interventionCount', 'interventionTotal'],
    'contCorrespondingRisk': ['effectMeasure', 'effectSize', 'confidenceIntervalFrom', 'confidenceIntervalTo'],
    'relativeEffect': outcomeCellTypes['relativeEffect'].footnotesAttrs.common,
    'noOfParticipants': outcomeCellTypes['noOfParticipantsStudies'].footnotesAttrs ? outcomeCellTypes['noOfParticipantsStudies'].footnotesAttrs.common : outcomeCellTypes['noOfParticipantsStudies'].formatterAttrsObserve,
    'noOfParticipantsStudiesDiagnostic': outcomeCellTypes['noOfParticipantsStudiesDiagnostic'].footnotesAttrs ? outcomeCellTypes['noOfParticipantsStudiesDiagnostic'].footnotesAttrs.common : outcomeCellTypes['noOfParticipantsStudiesDiagnostic'].formatterAttrsObserve,
    'noOfParticipantsFollowUp': outcomeCellTypes['noOfParticipantsStudiesFollowUp'].footnotesAttrs ? outcomeCellTypes['noOfParticipantsStudiesFollowUp'].footnotesAttrs.common : outcomeCellTypes['noOfParticipantsStudiesFollowUp'].formatterAttrsObserve,
    'quality': outcomeCellTypes['quality'].footnotesAttrs.SOF,
    'qualityDx': outcomeCellTypes['quality'].footnotesAttrs.LayerOneSof,
    'comments': ['comments'],
    'whatHappens': ['comments'],
    'impact': ['narrativeDesc'],
    'narrativeDesc': ['narrativeDesc'],
    'testAccuracy': outcomeCellTypes['quality'].footnotesAttrs.LayerOneSof,
    'designStudies': ['designStudies'],
    'relativeImportance': ['importance']
  },
  Rm5ExportTableCell: (function() {
    function _Class(params) {
      var key, value;
      if (params == null) {
        params = {};
      }
      for (key in params) {
        value = params[key];
        this[key] = value;
      }
    }

    _Class.prototype.type = 'td';

    _Class.prototype.textContent = '';

    _Class.prototype.rowspan = 1;

    _Class.prototype.colspan = 1;

    _Class.prototype.align = 'LEFT';

    _Class.prototype.valign = 'TOP';

    _Class.prototype.sup = '';

    return _Class;

  })()
};
