var acpVisualGuidelinesShape, arrayOf, docShape, docTypes, documentId, initial, number, optional, shape, string, _ref;

arrayOf = require('lib/db_docs/field_types/array_of');

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

docTypes = require('lib/db_docs/doc_types');

initial = require('lib/db_docs/field_types/type_decorators').initial;

shape = require('lib/db_docs/field_types/shape');

_ref = require('lib/db_docs/field_types/built_in_types'), string = _ref.string, number = _ref.number;

optional = shape.typeDecorators.optional;

acpVisualGuidelinesShape = docShape({
  docType: initial(docTypes.ACP_VISUAL_GUIDELINES)(string),
  name: string,
  selectedQuestions: initial([])(arrayOf(documentId)),
  lastUpdateDate: optional(number),
  orderNumber: optional(number),
  visualizationRootId: optional(documentId),
  template: initial('acp_visualization_template')(string)
});

module.exports = acpVisualGuidelinesShape;
