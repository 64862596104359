{ Card, CardContent } = ReactComponents
DocSectionsFromTemplateStore = require 'stores/doc_sections_from_template_store'
mediator = require 'mediator'
ReferenceAttachmentLink = require 'components/references/attachment_link'
ReferencesStore = require 'stores/references_store'
{ vancouverFormat } = require 'lib/references_utils'

DocSectionsReferencesChapter = createReactClass
  displayName: 'DocSectionsReferencesChapter'

  propTypes:
    renderMode: PropTypes.string

  render: ->
    { renderMode } = @props
    referenceIds = DocSectionsFromTemplateStore.getReferenceIds()
    references = ReferencesStore.getReferenceByIds referenceIds

    <Card className="references-chapter">
      <CardContent>
        {references.map (reference, idx) =>
          referenceId = reference.get '_id'
          attachmentFileName = reference.getIn ['attachment', 'fileName']

          <div className="reference" key={referenceId}>
            <div className="reference-content">
              {idx + 1}{'. '}{vancouverFormat reference.toJS()}
            </div>
            {renderMode isnt 'printout' and attachmentFileName and <ReferenceAttachmentLink
              attachmentFileName={attachmentFileName}
              className="attachment-link"
              projectId={mediator.project.id}
              referenceId={referenceId}
            />}
          </div>
        }
      </CardContent>
    </Card>

module.exports = DocSectionsReferencesChapter
