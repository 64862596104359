SummaryPrintout = createReactClass
  displayName: 'SojSummaryPrintoutComponent'

  render: ->
    tdProps = _(@props).omit(['option', 'isChecked']) # to avoid warning in console
    if @props.option
      <td className={classNames 'option-text', {checked: @props.isChecked}} {...tdProps}>
        <p>{@props.option.get 'text'}</p>
      </td>
    else
      <td className={classNames 'option-text', 'empty'} {...tdProps} />

module.exports = SummaryPrintout
