require('../../../modules/es.string.code-point-at');
require('../../../modules/es.string.ends-with');
require('../../../modules/es.string.includes');
require('../../../modules/es.string.match');
require('../../../modules/es.string.match-all');
require('../../../modules/es.string.pad-end');
require('../../../modules/es.string.pad-start');
require('../../../modules/es.string.repeat');
require('../../../modules/es.string.replace');
require('../../../modules/es.string.replace-all');
require('../../../modules/es.string.search');
require('../../../modules/es.string.split');
require('../../../modules/es.string.starts-with');
require('../../../modules/es.string.substr');
require('../../../modules/es.string.trim');
require('../../../modules/es.string.trim-start');
require('../../../modules/es.string.trim-end');
require('../../../modules/es.string.iterator');
require('../../../modules/es.string.anchor');
require('../../../modules/es.string.big');
require('../../../modules/es.string.blink');
require('../../../modules/es.string.bold');
require('../../../modules/es.string.fixed');
require('../../../modules/es.string.fontcolor');
require('../../../modules/es.string.fontsize');
require('../../../modules/es.string.italics');
require('../../../modules/es.string.link');
require('../../../modules/es.string.small');
require('../../../modules/es.string.strike');
require('../../../modules/es.string.sub');
require('../../../modules/es.string.sup');
var entryVirtual = require('../../../internals/entry-virtual');

module.exports = entryVirtual('String');
