ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
{ MDG_QUESTION_TYPES, SEARCH_STRATEGY_TYPES } = require 'lib/mdg_helper'
InterventionPopulationField = require 'components/mdg_tables/intervention_population_field'
Modal = require 'components/common/modal'
QuestionsActions = require 'actions/questions_actions'
QuestionsStore = require 'stores/questions_store'
Translation = require 'components/mixins/translation'

# This is here and not in helper because `InterventionPopulationField` requires actions,
# which required helper and it caused a dependency cycle.
MDG_CREATE_QUESTION_FORM = [
  fieldKey: 'question'
  type: 'string'
  showClear: false
,
  fieldKey: 'type'
  type: 'select'
  options: _.map MDG_QUESTION_TYPES, (value) -> { value, text: $.t "mdg_tables:types.#{value}" }
  showClear: false
,
  fieldKey: 'searchStrategyType'
  type: 'select'
  options: _.map SEARCH_STRATEGY_TYPES, (value) ->
    { value, text: $.t "mdg_tables:search_strategy_types.#{value}" }
  showClear: false
,
  fieldKey: 'intervention'
  type: 'custom'
  component: InterventionPopulationField
  showClear: false
,
  fieldKey: 'population'
  type: 'custom'
  component: InterventionPopulationField
  showClear: false
]

getQuestionForm = (questionData) ->
  main: if questionData.get('type', '').indexOf('imaging') is 0
    _.filter MDG_CREATE_QUESTION_FORM, ({ fieldKey }) -> fieldKey isnt 'intervention'
  else if questionData.get('_id')
    _.map MDG_CREATE_QUESTION_FORM, (field) ->
      if field.fieldKey in ['type', 'searchStrategyType']
        _.extend {}, field, {readOnly: true}
      else
        field
  else
    MDG_CREATE_QUESTION_FORM

storeConnector =
  QuestionsStore: (Store) ->
    isCreating: Store.isMdgCreateQuestionDialogCreating()
    isOpen: Store.isMdgCreateQuestionDialogOpen()
    questionData: Store.getMdgCreateQuestionDialogQuestionData()

CreateQuestionDialog = createReactClass
  displayName: 'MDGCreateQuestionDialog'

  mixins: [Translation('mdg_tables:create_question_dialog')]

  propTypes:
    isCreating: PropTypes.bool.isRequired
    isOpen: PropTypes.bool.isRequired
    questionData: PropTypes.instanceOf(Immutable.Map).isRequired

  onChange: (field, value) ->
    if field is 'type'
      QuestionsActions.changeMdgCreateQuestionDialogQuestionType value
    else
      useImmutable = field in ['intervention', 'population']
      QuestionsActions.setMdgCreateQuestionDialogQuestionDataField { field, value, useImmutable }

  isSubmitEnabled: ->
    { questionData } = @props
    return false if _.isEmpty(questionData.get('question'))
    return false if _.isEmpty(questionData.getIn(['intervention', 'name']))
    return false if _.isEmpty(questionData.getIn(['population', 'name']))
    true

  saveQuestion: ->
    { questionData } = @props
    questionId = questionData.get('_id')
    if questionId
      QuestionsActions.updateMdgQuestion { questionId, questionData: questionData.toJS() }
    else
      QuestionsActions.createMdgQuestion questionData.toJS()

  render: ->
    { isCreating, isOpen, questionData } = @props

    applyLabel = if questionData.get('_id') then @i18n '/actions.save' else @i18n '/actions.create'

    <Modal
      className="mdg-create-question-dialog"
      isOpen={isOpen}
      modalSize="medium"
      title={@i18n 'title'}
    >
      <ExtractionForm
        data={questionData.toJS()}
        form={getQuestionForm(questionData)}
        i18n={@i18n}
        inModal
        onChange={@onChange}
      />
      <ApplyCancelButtons
        applying={isCreating}
        applyLabel={applyLabel}
        isSubmitEnabled={@isSubmitEnabled()}
        onApply={@saveQuestion}
        onCancel={QuestionsActions.closeMdgCreateQuestionDialog}
      />
    </Modal>

module.exports = ConnectStore CreateQuestionDialog, QuestionsStore, storeConnector
