AdolopmentDataStore = require 'stores/adolopment_data_store'
AdolopedEtdStore = require 'stores/adoloped_etd_store'
ComparisonsReviewHeader = require './comparisons_review_header'
ComparisonsReviewSummary = require './comparisons_review_summary'
ConnectStore = require 'components/enhancers/connect_store'
MultiComparisonsExportModal = require './comparisons_export_modal'
MultiComparisonsStore = require 'stores/multi_comparisons_store'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
Spinner = require 'components/common/spinner'
{ filterGoodPracticeStatement } = require 'lib/questions_helper'
{ filterQuestionKeys, filterQuestionsWithTheSameType } = require 'lib/multi_comparisons_helper'

storeConnectors =
  AdolopedEtdStore: (Store) ->
    adolopments: Store.getAdolopments()
    isFetchingAdolopment: Store.isFetching()
  AdolopmentDataStore: (Store) ->
    adolopmentData: Store.getAdolopmentData()
  MultiComparisonsStore: (Store) ->
    savingComparison: Store.isSavingComparison()
    isFetchingMultiComparisons: Store.isFetching()
    isFetchingEtdsTemplateData: Store.isFetchingEtdsTemplateData()
    isShowingExportDialog: Store.isShowingExportDialog()
    comparisonsEditData: Store.getComparisonsEditData()
    etds: Store.getEtds()
  QuestionsStore: (Store) ->
    # We don't need all question fields here.
    # Question ID is stored under 'questionId' field to indicate that it's not a document
    questions: Store.getQuestions().filterNot(filterGoodPracticeStatement).map filterQuestionKeys
  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups()
    isFetchingQuestionGroups: Store.isFetching()

managementAssessmentSections = [
  'balanceOfEffects'
  'certaintyOfEvidence'
  'resourcesRequired'
  'costEffectiveness'
  'equity'
  'acceptability'
  'feasibility'
]

diagnosticAssessmentSections = [
  'balanceOfEffects'
  'certaintyOfEvidenceOfTestAccuracy'
  'resourcesRequired'
  'costEffectiveness'
  'equity'
  'acceptability'
  'feasibility'
]


summarySections = [
  'justification'
  'subgroupConsiderations'
  'implementationConsiderations'
  'monitoringAndEvaluation'
  'researchPriorities'
]

MultiComparisonDetails = createReactClass
  displayName: 'MultiComparisonDetails'

  propTypes:
    mcId: PropTypes.string.isRequired

  isFetching: ->
    @props.isFetchingMultiComparisons or @props.isFetchingEtdsTemplateData or
      @props.isFetchingAdolopment

  render: ->
    {
      adolopments
      adolopmentData
      savingComparison
      questions
      mcId
      comparisonsEditData,
      questionGroups
      isShowingExportDialog
      etds
    } = @props

    selectedQuestions = comparisonsEditData.get('selectedQuestions', Immutable.Map())
    currentSelectedQuestionType = if selectedQuestions.first()?.get('type') is 'diagnostic'
      'diagnostic'
    else
      'management'

    if currentSelectedQuestionType
      questions = questions.filter(filterQuestionsWithTheSameType(currentSelectedQuestionType))

    assessmentSections = if currentSelectedQuestionType is 'diagnostic'
      diagnosticAssessmentSections
    else
      managementAssessmentSections

    if @isFetching() or not comparisonsEditData or not questionGroups
      <Spinner />
    else
      <div>
        <ComparisonsReviewHeader
          comparisonsEditData={comparisonsEditData}
          questionGroups={questionGroups}
          questions={questions}
        />
        <ComparisonsReviewSummary
          adolopmentData={adolopmentData}
          adolopments={adolopments}
          assessmentSections={assessmentSections}
          comparisonsEditData={comparisonsEditData}
          currentSelectedQuestionType={currentSelectedQuestionType}
          etds={etds}
          savingComparison={savingComparison}
          summarySections={summarySections}
        />
        {if isShowingExportDialog
          <MultiComparisonsExportModal
            adolopmentData={adolopmentData}
            adolopments={adolopments}
            assessmentSections={assessmentSections}
            comparisonsData={comparisonsEditData}
            etds={etds}
            summarySections={summarySections}
          />
        }
      </div>

module.exports = ConnectStore MultiComparisonDetails,
  [
    AdolopmentDataStore
    AdolopedEtdStore
    MultiComparisonsStore
    QuestionsStore
    QuestionGroupsStore
  ], storeConnectors
