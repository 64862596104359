EDMSStore = require 'actions/edms_actions'
useTranslation = require 'components/hooks/use_translation'
{ insertFromEDMS } = require 'lib/epiditor_utils'
{ useState } = React
{ PMView } = Epiditor

EDMSInsertControl = ({ view, onCloseMenu }) ->
  i18n = useTranslation('')
  canInsert = insertFromEDMS(view.state)({})

  closeModal = ->
    EDMSStore.closeImportDialog()
    onCloseMenu()

  handleInsert = (data) ->
    view.focus()
    insertFromEDMS(view.state, view.dispatch)(data)
    closeModal()

  handleClick = (evt) ->
    evt.stopPropagation()
    EDMSStore.openImportDialog({ handler: handleInsert })

  <div
    className={classNames 'image-insert-toggle', disabled: !canInsert}
    onMouseDown={handleClick if canInsert}
  >
    <span>{i18n 'actions.insert_from_mayan'}</span>
  </div>

EDMSInsertControl.propTypes =
  view: PropTypes.instanceOf(PMView.EditorView).isRequired

module.exports = EDMSInsertControl
