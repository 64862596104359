EtdRecommendationContent =
  require 'components/dissemination/acp_visual_guidelines/etd_recommendation_content'
ManualRecommendationContent =
  require 'components/dissemination/acp_visual_guidelines/recommendation_content'
{ MANUAL_REC_PREFIX } = require 'lib/etd_helper'
{ RECOMMENDATION_ITEM_DND_TYPE, dragSpec, dropSpec, dragCollect, dropCollect } =
  require 'components/dissemination/acp_visual_guidelines/recommendation_item_dnd_spec'

RecommendationItem = createReactClass
  displayName: 'RecommendationItem'

  getRef: (el) ->
    @item = el

  render: ->
    {
      connectDragSource
      connectDropTarget
      isDragging
      isOver
      populationId
      recommendationId
      section
    } = @props

    className = classNames 'recommendation-item', { dragging: isDragging, over: isOver }
    recommendation = section.getIn(['additionalData', 'recommendationsData', recommendationId]
      , Immutable.Map())

    connectDragSource(
      connectDropTarget(
        <div ref={@getRef} className={className}>
          <div className="flex flex-row">
            <div className="drag-marker" />
            <div className="flex flex-grow">
              {if recommendationId.indexOf(MANUAL_REC_PREFIX) is 0
                <ManualRecommendationContent
                  populationId={populationId}
                  recommendationId={recommendationId}
                  section={section}
                  recommendation={recommendation}
                />
              else
                <EtdRecommendationContent
                  recommendationId={recommendationId}
                  section={section}
                  populationId={populationId}
                  recommendation={recommendation}
                />}
            </div>
          </div>
        </div>
      )
    )


DragSourced = ReactDnD.DragSource(
  RECOMMENDATION_ITEM_DND_TYPE, dragSpec, dragCollect
) RecommendationItem
DropTargeted = ReactDnD.DropTarget(
  RECOMMENDATION_ITEM_DND_TYPE, dropSpec, dropCollect
) DragSourced

module.exports = DropTargeted
