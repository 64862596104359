IconButton = require 'components/common/icon_button'
{ getSubchapterIllustrationPath } = require 'lib/da_helper'

SubchapterIllustration = ({ data, onDelete, onEdit }) ->
  illustration = data.get 'illustration'
  position = data.get 'position', 'right'

  <div className={classNames 'subchapter-illustration-container', "align-#{position}"}>
    <div className='illustration'>
      <img
        alt={$.t "da:illustrations.#{_.str.underscored illustration}"}
        src={getSubchapterIllustrationPath illustration}
      />
      <span>{illustration}</span>
    </div>
    <div className='illustration-controls'>
      <IconButton iconName='edit' onClick={onEdit}/>
      <IconButton iconName='delete' onClick={onDelete}/>
    </div>
  </div>

module.exports = SubchapterIllustration
