ProjectsList = require 'components/projects/projects_list'
NoProjects = require 'components/projects/no_projects'

ProjectsTab = (props) ->
  { projects } = props
  return <NoProjects isSearchActive={props.isSearchActive} /> if projects.isEmpty()

  <ProjectsList {...props} />

module.exports = ProjectsTab
