var DbDoc, docDbInterface, docInst;

docInst = require('lib/db_docs/document_instance');

docDbInterface = require('lib/db_docs/document_db_interface');

DbDoc = function(docShape, _arg) {
  var dbInterfaceProvider, dbSetup, docInstProvider, methods, middlewares, _ref;
  _ref = _arg != null ? _arg : {}, dbSetup = _ref.dbSetup, methods = _ref.methods, middlewares = _ref.middlewares;
  docInstProvider = docInst(docShape, methods);
  dbInterfaceProvider = docDbInterface(docShape, docInstProvider, middlewares);
  return {
    of: docInstProvider,
    at: function(dbId, docId) {
      return dbInterfaceProvider(_.defaults({
        dbId: dbId,
        docId: docId
      }, dbSetup));
    }
  };
};

module.exports = DbDoc;
