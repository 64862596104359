module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": "Varia",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L'intervento è accettabile per i principali stakeholder?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "Vi sono stakeholder che potrebbero non accettare le distribuzione dei benefici, rischi e costi?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "Vi sono stakeholder che potrebbero non accettare i costi o gli effetti indesiderabili nel breve termine, per gli effetti desiderabili (benefici) nel futuro?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "Vi sono stakeholder che potrebbero trovarsi in disaccordo con i valori assegnati agli effetti desiderabili o indesiderabili (a causa di come potrebbero venirne interessati direttamente o per la loro percezione dell'importanza relativa degli effetti per gli altri)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "E' possibile che l'intervento (opzione) possa incidere negativamente sull'autonomia degli individui?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "Vi sono stakeholder che potrebbero disapprovare moralmente l'intervento (opzione), per motivazioni diverse dagli effetti sull'autonomia degli individui? (ad esempio in relazione a principi etici come l'agire senza malevolenza, con benevolenza o per giustizia)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "E' probabile che i principali stakeholder non accettino la distribuzione dei benefici, rischi e costi, oppure i costi o gli effetti indesiderabili nel breve termine, a favore degli effetti desiderabili (benefici) nel futuro? E' probabile che si trovino in disaccordo con i valori assegnati agli effetti desiderabili e indesiderabili, o che non accettino gli interventi diagnostici per ragioni etiche?",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Si",
              "value": "yes"
            }
          ],
          "ratingDescription": "Quale intervento è piu' accettabile per i principali stakeholder?",
          "ratingOptions": [
            "best_acceptability",
            "intermediate_acceptability",
            "worst_acceptability"
          ],
          "selectedOption": ""
        },
        "additionalSection": {
          "additionalOptions": [
            {
              "text": "Giudizio aggiuntivo 1",
              "value": "additionalOption_1"
            },
            {
              "text": "Giudizio aggiuntivo 2",
              "value": "additionalOption_2"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Descrizione del criterio personalizzato",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Fornisci un comando personalizzato",
          "options": [
            {
              "text": "Giudizio 1",
              "value": "option_1"
            },
            {
              "text": "Giudizio 2",
              "value": "option_2"
            },
            {
              "text": "Giudizio 3",
              "value": "option_3"
            },
            {
              "text": "Giudizio 4",
              "value": "option_4"
            },
            {
              "text": "Giudizio 5",
              "value": "option_5"
            }
          ],
          "selectedOption": ""
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": "Varia",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Il bilancio tra effetti desiderabili e indesiderati favorisce l'intervento o il confronto?",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "I giudizi relativi a questo criterio includono i giudizi relativi a ciascuno dei quattro criteri precedenti:"
              },
              {
                "additions": [
                  "In che misura le persone valutano meno importanti gli esiti che si verificheranno in futuro rispetto a quelli si verificano adesso (i loro tassi di sconto)?",
                  "L'attitudine delle persone rispetto agli effetti indesiderabili (quanto sono contrari al rischio).",
                  "L'attitudine (delle persone) rispetto agli effetti desiderabili (quanto sono propensi al rischio)."
                ],
                "description": "Inoltre, il panel potrebbe voler considerare (e documentare qualora fosse importante) in che misura le seguenti considerazioni potrebbero influenzare il bilancio tra effetti desiderabili e indesiderabili:"
              }
            ],
            "panelDiscussion": ""
          },
          "info": "Qual è il bilancio tra effetti favorevoli e quelli indesiderati, prendendo in considerazione quanto valore gli individui ripongono negli esiti principali, quanto sono sostanziali gli effetti desiderabili e quelli indesiderabili, la certezza di queste stime, il tasso di sconto, l'avversione al rischio e la propensione al rischio?",
          "options": [
            {
              "text": "Favorisce il confronto",
              "value": "favors_comparison"
            },
            {
              "text": "Probabilmente favorisce il confronto",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Non favorisce nè l'intervento nè il confronto",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Probabilmente favorisce l'intervento",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Favorisce l'intervento",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?",
          "ratingOptions": [
            "best_balance",
            "intermediate",
            "worst_balance"
          ],
          "selectedOption": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalOptions": [
            {
              "text": "Variabile",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Il bilancio tra effetti desiderabili e indesiderati favorisce l'intervento o il confronto?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce nè l'intervento nè il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'efficacia dell'intervento sulla salute a breve e lungo termine (in circostanze controllate, spesso ideali) o (in un contesto di vita reale), inclusi i risultati riportati dai pazienti,   favoriscono l'intervento o il confronto?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce nè l'intervento nè il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'efficacia o l'impatto a breve e lungo termine dell'intervento sulla salute della popolazione, considerando anche i risultati riportati dai beneficiari, favoriscono l'intervento o il confronto? (Ciò dovrebbe includere considerazioni se i risultati a livello di popolazione rappresentino risultati aggregati di esiti individuali o emergano attraverso analisi di sistema.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce nè l'intervento nè il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "La misura del valore che i pazienti/beneficiari attribuiscono ai diversi esiti di salute favoriscono l'intervento o il confronto?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce nè l'intervento nè il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "La probabilità e gravità degli effetti avversi associati all'intervento (compreso il rischio che l'intervento venga utilizzato in modo improprio) favoriscono l'intervento o il confronto?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce nè l'intervento nè il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Gli effetti sulla salute positivi o negativi (ad es. riduzione dello stigma, impatto positivo su altre malattie, effetti di ricaduta non relativi ai pazienti/beneficiari) associati all'intervento favoriscono l'intervento o il confronto?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Maggiore è il beneficio netto per la salute associato a un intervento, maggiore è la probabilità di una raccomandazione generale a favore di tale intervento.",
          "options": [
            {
              "text": "A favore del confronto",
              "value": "favors_the_comparison"
            },
            {
              "text": "Probabilmente a favore del confronto",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Non favorisce né l'intervento né il confronto",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Probabilmente in favore dell'intervento",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "A favore dell'intervento",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "Nessuno studio incluso",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Qual è la qualità delle prove complessiva in relazione agli effetti del test?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Quanto può essere ritenuta valida l'indicazione fornita dai dati della ricerca sugli effetti attesi in merito a tutti gli esiti critici? Ad esempio, la probabilità che gli effetti saranno sufficientemente diversi rispetto a quanto descritto in letteratura, valutazione che può condizionare la decisione relativa all' intervento diagnostico.",
          "options": [
            {
              "text": "Molto bassa",
              "value": "very_low"
            },
            {
              "text": "Bassa",
              "value": "low"
            },
            {
              "text": "Moderata",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "Nessuno studio incluso",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Qual è la certezza complessiva delle prove relative agli effetti? ",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Quanto può essere ritenuta valida l'indicazione fornita dai dati della ricerca sugli effetti attesi in merito a tutti gli esiti critici; ad esempio, la probabilità che gli effetti siano sufficientemente diversi rispetto a quanto descritto in letteratura, valutazione che può condizionare la decisione relativa all' intervento?",
          "options": [
            {
              "text": "Molto bassa",
              "value": "very_low"
            },
            {
              "text": "Bassa",
              "value": "low"
            },
            {
              "text": "Moderata",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "ratingDescription": "What is the overall certainty of the evidence of effects for each intervention?",
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "Nessuno studio incluso",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Qual è la qualità complessiva delle prove relative agli effetti della gestione derivante dai risultati del test  ?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Molto bassa",
              "value": "very_low"
            },
            {
              "text": "Bassa",
              "value": "low"
            },
            {
              "text": "Moderata",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "Nessuno studio incluso",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Qual è la certezza delle prove relative alle risorse necessarie (costi)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "Sono state individuate tutte le voci importanti relative all'uso delle risorse che potrebbero differire tra le opzioni prese in considerazione?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Incertezza importante",
                    "value": "important"
                  },
                  {
                    "text": "Possibile importante incertezza ",
                    "value": "probably_important"
                  },
                  {
                    "text": "Probabilmente nessuna incertezza",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Probabilmente nessuna incertezza",
                    "value": "no_important"
                  }
                ],
                "question": "Quanto è certa la stima dei costi delle voci relative all'uso delle risorse che differiscono tra le opzioni prese in considerazione?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Variabilità importante",
                    "value": "important"
                  },
                  {
                    "text": "Probabile importante incertezza o variabilità",
                    "value": "probably_important"
                  },
                  {
                    "text": "Probabilmente nessuna importante incertezza o variabilità",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Probabilmente nessuna variabilità importante",
                    "value": "no_important"
                  }
                ],
                "question": "Esiste una variabilità importante nei costi relativi all'utilizzo delle risorse che differiscono tra le opzioni prese in considerazione?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Quale è la certezza delle prove rispetto ad una differenza per le varie risorse (ad esempio farmaci, ospedalizzazioni), ed i costi?",
          "options": [
            {
              "text": "Molto bassa",
              "value": "very_low"
            },
            {
              "text": "Bassa",
              "value": "low"
            },
            {
              "text": "Moderata",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "Nessuno studio incluso",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Qual è la certezza complessiva delle prove relative all'accuratezza del test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Molto bassa",
              "value": "very_low"
            },
            {
              "text": "Bassa",
              "value": "low"
            },
            {
              "text": "Moderata",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "Nessuno studio incluso",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Qual è la qualità complessiva delle prove in relazione a qualsiasi beneficio diretto critico o importante, agli eventi avversi o al disagio legato al test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Molto bassa",
              "value": "very_low"
            },
            {
              "text": "Bassa",
              "value": "low"
            },
            {
              "text": "Moderata",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "Nessuno studio incluso",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quanto forte è il legame tra i risultati dei test e le decisioni di management?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Molto bassa",
              "value": "very_low"
            },
            {
              "text": "Bassa",
              "value": "low"
            },
            {
              "text": "Moderata",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": "Varia",
              "value": "varies"
            },
            {
              "text": "Nessuno studio incluso",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "La costo-efficacia dell'intervento favorisce l'intervento o il controllo?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Nessuno studio incluso",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "Il rapporto costo-efficacia è sensibile ad una analisi di sensitività mono variata?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nessuno studio incluso",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "Il rapporto costo-efficacia è sensibile ad una analisi di sensitività multivariata?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nessuno studio incluso",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Limitazioni molto gravi",
                    "value": "very_serious"
                  },
                  {
                    "text": "Gravi limitazioni",
                    "value": "serious"
                  },
                  {
                    "text": "Nessuna limitazione grave",
                    "value": "no_serious"
                  }
                ],
                "question": "La valutazione economica sulla quale si basa la stima di costo-efficacia è affidabile?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nessuno studio incluso",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Limitazioni molto gravi",
                    "value": "very_serious"
                  },
                  {
                    "text": "Limitazioni gravi",
                    "value": "serious"
                  },
                  {
                    "text": "Nessuna limitazione grave",
                    "value": "no_serious"
                  }
                ],
                "question": "La valutazione economica sulla quale si basa la stima di costo-efficacia è affidabile?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "L'intervento è costo-efficace, considerando l'incertezza o la variabilità nei costi, l'incertezza o variabilità nel beneficio netto, le analisi di sensitività e l'affidabilità e applicabilità della valutazione economica?",
          "options": [
            {
              "text": "Favorisce il confronto",
              "value": "favors_comparison"
            },
            {
              "text": "Probabilmente favorisce il confronto",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Non favorisce nè l'intervento nè il confronto",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Probabilmente favorisce l'intervento",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Favorisce l'intervento",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Quale intervento ha un rapporto costo/efficacia favorevole?",
          "ratingOptions": [
            "best_cost_effectiveness",
            "intermediate_cost_effectiveness",
            "worst_cost_effectiveness"
          ],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": "Variabile",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quanto sostanziali sono gli effetti desiderabili attesi ?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Irrilevante",
                    "value": "trivial"
                  },
                  {
                    "text": "Piccolo",
                    "value": "small"
                  },
                  {
                    "text": "Moderato",
                    "value": "moderate"
                  },
                  {
                    "text": "Grande",
                    "value": "large"
                  }
                ],
                "question": "Quanto è sostanziale l'effetto atteso, (in termini di differenza tra gli interventi), per ogni esito principale per il quale vi è un effetto desiderabile?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Quanto grandi sono gli effetti favorevoli dell'intervento, considerando l'importanza degli oucome (il valore che è stato loro assegnato) e la dimensione dell'effetto (la probabilità di sperimentare il beneficio o l'entità del miglioramento)?",
          "options": [
            {
              "text": "Irrilevante",
              "value": "trivial"
            },
            {
              "text": "Piccolo",
              "value": "small"
            },
            {
              "text": "Moderato",
              "value": "moderate"
            },
            {
              "text": "Grande",
              "value": "large"
            }
          ],
          "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
          "ratingOptions": [
            "most_effective",
            "intermediate_effectiveness",
            "least_effective"
          ],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": "Variabile",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quale potrebbe essere l'impatto sull'equità?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "Esistono gruppi o setting che potrebbero essere svantaggiati in relazione al problema o alle opzioni considerate?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "Esistono ragioni plausibili per prevedere differenze nell'efficacia relativa delle opzioni per i gruppi o setting più svantaggiati?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "Esistono differenti condizioni di base fra i gruppi o setting che influenzano l'efficacia assoluta delle opzioni o l'importanza del problema per i gruppi o setting più svantaggiati?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "Vi sono delle considerazioni importanti da fare per l'implementazione dell'intervento al fine di garantire che le disuguaglianze siano ridotte, se possibile, e che non aumentino?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Esistono motivazioni plausibili per prevedere delle differenze nell'efficacia relativa dell'intervento per i sottogruppi più svantaggiati o condizioni di base differenti fra i sottogruppi più svantaggiati che possono influenzare l'efficacia assoluta dell'intervento o l'importanza del problema?",
          "options": [
            {
              "text": "Ridotto",
              "value": "reduced"
            },
            {
              "text": "Probabilmente ridotto",
              "value": "probably_reduced"
            },
            {
              "text": "Probabilmente nessun impatto",
              "value": "probably_no_impact"
            },
            {
              "text": "Probabilmente aumentato/a",
              "value": "probably_increased"
            },
            {
              "text": "Aumentato",
              "value": "increased"
            }
          ],
          "ratingDescription": "Se raccomandato, quale intervento ridurrebbe maggiormente le disuguaglianze sanitarie? ",
          "ratingOptions": [
            "most_reduction",
            "intermediate_reduction",
            "less_reduction"
          ],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": "Variabile",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "E' fattibile l'implementazione dell'intervento?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "L'intervento (opzione) è sostenibile?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "Vi sono delle considerazioni importanti da fare per l'implementazione dell'intervento al fine di garantire che, se possibile, le disuguaglianze siano ridotte e non aumentino?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "E' fattibile sostenere l'uso dell'intervento diagnostico e gestire eventuali barriere al suo utilizzo?",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Si",
              "value": "yes"
            }
          ],
          "ratingDescription": "E' possibile implementare l'opzione?",
          "ratingOptions": [
            "most_feasible",
            "intermediate_feasibility",
            "least_feasible"
          ],
          "selectedOption": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalOptions": [
            {
              "text": "Variabile",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "E' fattibile l'implementazione dell'intervento?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "Ci sono barriere legali che possono limitare l'implementazione dell'intervento?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Si",
                    "value": "yes"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "Ci sono aspetti di governance (es. considerazioni strategiche, decisioni prese in passato) che possono limitare l'implementazione dell'intervento? (Ciò dovrebbe includere considerazioni riguardanti la presenza o l'assenza di istituzioni che possano fornire un'efficace leadership e supervisione e che siano responsabili dell'attuazione dell'intervento influenzandone la fattibilità)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varia",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grandi implicazioni favorevoli",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Moderate implicazioni favorevoli",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Trascurabili implicazioni favorevoli e implicazioni negative",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Moderate implicazioni negative",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Grandi implicazioni negative",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Quali sono le implicazioni dell'intervento considerando l'interazione e l'adattamento con il sistema sanitario esistente? (Ciò include considerazioni sull'interazione dell'intervento o sul suo impatto sul sistema sanitario esistente e sui suoi componenti? )",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grandi implicazioni favorevoli",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Moderate implicazioni favorevoli",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Trascurabili implicazioni favorevoli e implicazioni negative",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": " Implicazioni negative moderate",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Implicazioni negative grandi",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Quali sono le implicazioni dell'intervento per il personale sanitario e per le altre risorse umane coinvolte (nel settore sanitario o in altri settori? (Ciò dovrebbe includere considerazioni sulla necessità, sull'utilizzo e l'impatto sul personale sanitario e sulle altre risorse umane coinvolte, nonché sulla loro distribuzione)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grandi implicazioni favorevoli",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Moderate implicazioni favorevoli",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Trascurabili implicazioni favorevoli e implicazioni negative",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Moderate implicazioni negative",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Grandi implicazioni negative",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Quali sono le implicazioni dell'intervento per le infrastrutture del sistema sanitario e le altre infrastrutture coinvolte? (Ciò dovrebbe includere considerazioni sulla necessità, l'utilizzo e l'impatto su risorse e infrastrutture, nonché sulla loro distribuzione)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Maggiore è la fattibilità di un'opzione dal punto di vista di tutti o della maggior parte degli stakeholders, maggiore è la probabilità di una raccomandazione generale a favore dell'intervento. Quanto più vantaggiose sono le implicazioni per il sistema sanitario nel suo complesso, tanto maggiore è la probabilità di una raccomandazione generale a favore dell'intervento.",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_not"
            },
            {
              "text": "Incerto",
              "value": "uncertain"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Si",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "feasible": {
          "description": "to identify and measure, and not susceptible to manipulation",
          "options": [],
          "ratingOptions": [
            "very_feasible",
            "feasible",
            "least_feasible"
          ],
          "selectedOptions": ""
        },
        "financialAndEconomicConsiderations": {
          "additionalOptions": [
            {
              "text": "Varia",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Le considerazioni di tipo economico favoriscono l'intervento o il confronto?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce nè l'intervento nè il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'alto costo e l'alto budget dell'attuazione e del mantenimento dell'intervento favoriscono l'intervento o il confronto? (Ciò dovrebbe includere considerazioni su come gli impatti sui costi e sul budget variano a breve rispetto a quello al lungo termine.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce nè l'intervento nè il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'alto costo complessivo dell'intervento favorisce l'intervento o il confronto? (Ciò dovrebbe includere considerazioni su come i diversi tipi di impatto economico sono distribuiti tra diversi settori o livelli organizzativi, se l'intervento contribuisce o limita il raggiungimento di obiettivi più ampi di sviluppo e riduzione della povertà e come influisce sulla forza lavoro disponibile.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce nè l'intervento nè il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Il rapporto tra costi e benefici (ad esempio basato su stime di costo-efficacia, costi-benefici o costi-utilità) favorisce l'intervento o il confronto?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Quanto più vantaggiose sono le implicazioni finanziarie ed economiche di un intervento, tanto maggiore è la probabilità di una raccomandazione generale a favore di tale intervento.",
          "options": [
            {
              "text": "A favore del confronto",
              "value": "favors_the_comparison"
            },
            {
              "text": "Probabilmente a favore del confronto",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Non favorisce nè l'intervento nè il confronto",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Probabilmente in favore dell'intervento",
              "value": "probably_favor_the_intervention"
            },
            {
              "text": "A favore dell'intervento",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalOptions": [
            {
              "text": "Variabile",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quale potrebbe essere l'impatto dell'intervento sull'equità, l'uguaglianza e la non discriminazione sanitaria?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "E' probabile che l'intervento aumenti le disuguaglianze e/o le inequita' in termini di condizione di salute o nei suoi determinanti? (Ciò dovrebbe includere considerazioni sui probabili cambiamenti nel tempo, ad esempio se è un aumento iniziale delle disuguaglianze sanitarie si possa riequilibrare nel tempo, nel momento in cui l'intervento venisse ampliato)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "È' probabile che i benefici e i danni dell'intervento siano distribuiti in modo equo? (Ciò dovrebbe includere un'attenzione particolare alle implicazioni per i gruppi di popolazione vulnerabili, emarginati o socialmente svantaggiati.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "L'intervento è economicamente sostenibile tra i gruppi di popolazione interessati e quindi finanziariamente accessibile? (Ciò dovrebbe includere l'impatto sulla spesa sanitaria delle famiglie, compreso il rischio di spese sanitarie enormi e rischi finanziari legati alla salute.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "L'intervento è accessibile ai gruppi di popolazione colpiti? (Ciò dovrebbe includere considerazioni sull'accesso fisico e sull'accesso all'informazione.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varia",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "L'intervento riguarda una condizione particolarmente grave (ad es. pericolosa per la vita, fine vita, che colpisce individui con un cattivo stato di salute preesistente) o rara?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente si",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Si",
                    "value": "yes"
                  }
                ],
                "question": "L'intervento rappresenta l'unica opzione disponibile? (Ciò dovrebbe includere considerazioni sul fatto che l'intervento sia proporzionato alla necessità e se sarà soggetto a revisione periodica.)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Maggiore è la probabilità che l'intervento aumenti l'equità e/o l'uguaglianza sanitaria e che riduca la discriminazione nei confronti di un particolare gruppo, maggiore è la probabilità di una raccomandazione generale a favore di questo intervento.",
          "options": [
            {
              "text": "Negativo",
              "value": "negative"
            },
            {
              "text": "Probabilmente negativo",
              "value": "probably_negative"
            },
            {
              "text": "Ne' negativo ne' positivo",
              "value": "neither_negative_nor_positive"
            },
            {
              "text": "Probabilmente positivo",
              "value": "probably_positive"
            },
            {
              "text": "Positivo",
              "value": "positive"
            }
          ],
          "selectedOption": ""
        },
        "humanRights": {
          "additionalOptions": [
            {
              "text": "Variabile",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L'intervento e' conforme agli standard e ai principi dei diritti umani universali?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Tutte le raccomandazioni dovrebbero essere conformi agli standard e ai principi dei diritti umani universali.",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "Incerto",
              "value": "uncertain"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Si",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problem": {
          "additionalOptions": [
            {
              "text": "Varia",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Il problema rappresenta una priorità?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente sì",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sì",
                    "value": "yes"
                  }
                ],
                "question": "Le conseguenze del problema sono serie (per esempio gravi o importanti in termini di potenziali benefici o risparmi)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varia",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente sì",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sì",
                    "value": "yes"
                  }
                ],
                "question": "Il problema è urgente?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probabilmente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probabilmente sì",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sì",
                    "value": "yes"
                  }
                ],
                "question": "Si tratta di una priorità riconosciuta (per esempio, basata su una decisione politica)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Più è grave o urgente un problema, più è probabile che un intervento (opzione) che lo affronta diventi una priorità",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "Probabilmente sì",
              "value": "probably_yes"
            },
            {
              "text": "Sì",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "qualityOfEvidence": {
          "additionalOptions": [
            {
              "text": "Variabile",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Qual è la certezza complessiva di queste prove?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Più grande è la certezza delle prove rispetto ai diversi criteri del framework WHO-INTEGRATE, maggiore è la probabilità di una raccomandazione generale.",
          "options": [
            {
              "text": "Molto bassa",
              "value": "very_low"
            },
            {
              "text": "Bassa",
              "value": "low"
            },
            {
              "text": "Moderata",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "relevantDirect": {
          "description": "to the selected problem and in the field of application",
          "options": [],
          "ratingOptions": [
            "very_relevant",
            "relevant",
            "least_relevant"
          ],
          "selectedOptions": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": "Varia",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quanto sono grandi le risorse necessarie (costi)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varia",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Irrilevante",
                    "value": "trivial"
                  },
                  {
                    "text": "Piccola",
                    "value": "small"
                  },
                  {
                    "text": "Moderata",
                    "value": "moderate"
                  },
                  {
                    "text": "Grande",
                    "value": "large"
                  }
                ],
                "question": "Quanto è grande la differenza per ciascuna voce relativa all'utilizzo delle risorse per le quali sono necessarie risorse inferiori?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varia",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grande",
                    "value": "large"
                  },
                  {
                    "text": "Moderata",
                    "value": "moderate"
                  },
                  {
                    "text": "Piccola",
                    "value": "small"
                  },
                  {
                    "text": "Irrilevante",
                    "value": "trivial"
                  }
                ],
                "question": "Quanto è grande la differenza per ciascuna voce relativa all'utilizzo delle risorse, per la quale sono necessarie risorse maggiori?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Quanto è grande in termini di costi la differenza tra l'intervento e il controllo nelle risorse utilizzate?",
          "options": [
            {
              "text": "Costi elevati",
              "value": "large_costs"
            },
            {
              "text": "Costi moderati",
              "value": "moderate_costs"
            },
            {
              "text": "Costi e risparmi irrilevanti",
              "value": "negligible_costs_savings"
            },
            {
              "text": "Risparmi moderati",
              "value": "moderate_savings"
            },
            {
              "text": "Risparmi elevati",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
          "ratingOptions": [
            "less_costs",
            "intermediate_costs",
            "most_costs"
          ],
          "selectedOption": ""
        },
        "responsive": {
          "description": "sensitive to change that means they should be able to capture possible changes in the system",
          "options": [],
          "ratingOptions": [
            "very_responsive",
            "responsive",
            "least_responsive"
          ],
          "selectedOptions": ""
        },
        "scientificallySound": {
          "description": "based on evidence (GRADE criteria) and strongly correlated with the quality of care provided",
          "options": [],
          "ratingOptions": [
            "very_scientifically_sound",
            "scientifically_sound",
            "least_scientifically_sound"
          ],
          "selectedOptions": ""
        },
        "societalImplications": {
          "additionalOptions": [
            {
              "text": "Variabile",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Il bilancio tra implicazioni sociali desiderabili e indesiderabili favorisce l'intervento o il confronto?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente favorisce il confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce né l'intervento né il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente favorisce l'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorisce l'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'impatto sociale e le conseguenze sociali dell'intervento (come l'aumento o la riduzione dello stigma, i risultati educativi, la coesione sociale o il raggiungimento di diritti umani altri rispetto alla salute) favoriscono l'intervento o il confronto?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorisce il confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente favorisce il confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce né l'intervento né il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente favorisce l'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorisce l'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'impatto ambientale e le implicazioni per la sostenibilità ecologica (es. protezione delle risorse naturali, mitigazione o adattamento ai cambiamenti climatici) favoriscono l'intervento o il confronto?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Maggiore è il beneficio netto per la salute associato a un intervento, maggiore è la probabilità di una raccomandazione generale a favore di tale intervento.",
          "options": [
            {
              "text": "Favorisce il confronto",
              "value": "favors_the_comparison"
            },
            {
              "text": "Probabilmente favorisce il confronto",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Non favorisce né l'intervento né il confronto",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Probabilmente favorisce l'intervento",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "A favore dell'intervento",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "socioCulturalAcceptability": {
          "additionalOptions": [
            {
              "text": "Varia",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L'intervento è accettabile per i principali stakeholder?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grande",
                    "value": "large"
                  },
                  {
                    "text": "Moderata",
                    "value": "moderate"
                  },
                  {
                    "text": "Piccola",
                    "value": "small"
                  },
                  {
                    "text": "Irrilevante",
                    "value": "trivial"
                  }
                ],
                "question": "Quanto è sostanziale l'invasività dell'intervento in termini di violazione delle libertà individuali (tra cui privacy e dignità)? (L'invasività varia da irrilevante – ad esempio attraverso la possibilità di scelta (es. costruendo piste ciclabili) ad alto – ad esempio limitando o eliminando la scelta (es. vietando le sigarette)).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variabile",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grande",
                    "value": "large"
                  },
                  {
                    "text": "Moderata",
                    "value": "moderate"
                  },
                  {
                    "text": "Piccola",
                    "value": "small"
                  },
                  {
                    "text": "Irrilevante",
                    "value": "trivial"
                  }
                ],
                "question": "Quanto è sostanziale l'impatto dell'intervento sull'autonomia degli individui, dei gruppi di popolazione e/o delle organizzazioni (per quanto riguarda la loro capacità di prendere una decisione competente, informata e volontaria)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce né l'intervento né il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'accettabilità socio-culturale dell'intervento tra i beneficiari previsti favorisce l'intervento o il confronto?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce né l'intervento né il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'accettabilità socio-culturale dell'intervento tra coloro che dovrebbero implementarlo favorisce l'intervento o il confronto?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce né l'intervento né il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'accettabilità socio-culturale dell'intervento tra gli stakeholders favorisce l'intervento o il confronto?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "A favore del confronto",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probabilmente a favore del confronto",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Non favorisce né l'intervento né il confronto",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probabilmente in favore dell'intervento",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "A favore dell'intervento",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'accettabilità socio-culturale dell'intervento tra la popolazione generale favorisce l'intervento o il confronto?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Maggiore è l'accettabilità socio-culturale di un intervento per tutti o per la maggior parte degli stakeholders, maggiore è la probabilità di una raccomandazione generale a favore di tale intervento.",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "Incerto",
              "value": "uncertain"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Si",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": "Varia",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quanto è accurato il test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Molto inaccurato",
              "value": "very_inaccurate"
            },
            {
              "text": "Inaccurato",
              "value": "inaccurate"
            },
            {
              "text": "Accurato",
              "value": "accurate"
            },
            {
              "text": "Molto accurato",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": "Varia",
              "value": "varies"
            },
            {
              "text": "Non so",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quanto sostanziali sono gli effetti indesiderabili attesi?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varia",
                    "value": "varies"
                  },
                  {
                    "text": "Non so",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grande",
                    "value": "large"
                  },
                  {
                    "text": "Moderata",
                    "value": "moderate"
                  },
                  {
                    "text": "Piccola",
                    "value": "small"
                  },
                  {
                    "text": "Irrilevante",
                    "value": "trivial"
                  }
                ],
                "question": "Quanto è sostanziale l'effetto atteso, in termini di differenza, per ogni esito principale per il quale vi è un effetto indesiderabile?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Quanto grandi sono gli effetti desiderabili dell'intervento, considerando l'importanza degli esiti (il valore che è stato loro assegnato), la dimensione dell'effetto (la probabilità di sperimentare un beneficio o l'entità del miglioramento che è probabile avvenga)?",
          "options": [
            {
              "text": "Grande",
              "value": "large"
            },
            {
              "text": "Moderata",
              "value": "moderate"
            },
            {
              "text": "Piccola",
              "value": "small"
            },
            {
              "text": "Irrilevante",
              "value": "trivial"
            }
          ],
          "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
          "ratingOptions": [
            "least_harmful",
            "intermediate",
            "more_harmful"
          ],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Esiste una importante incertezza o variabilità rispetto al valore attribuito agli esiti principali?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "Incertezza importante",
                    "value": "important"
                  },
                  {
                    "text": "Possibile incertezza importante",
                    "value": "probably_important"
                  },
                  {
                    "text": "Probabilmente nessuna incertezza",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Nessuna incertezza importante",
                    "value": "no_important"
                  }
                ],
                "question": "Esiste una incertezza importante rispetto al valore attribuito agli esiti principali?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Qual è il valore assegnato a ciascuno degli esiti principali? Esiste una incertezza rispetto alla valutazione degli esiti o una variabilità rispetto al valore assegnato da persone diverse, tali da poter portare a decisioni differenti?",
          "options": [
            {
              "text": "Importante incertezza o variabilità",
              "value": "important_uncertainty"
            },
            {
              "text": "Probabile importante incertezza o variabilità",
              "value": "possible_important"
            },
            {
              "text": "Probabilmente nessuna importante incertezza o variabilità",
              "value": "probably_no_important"
            },
            {
              "text": "Nessuna incertezza o variabilità importante",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "Accettabilità",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "Criterio",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "Bilancio degli effetti",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
          "criterionId": "balanceOfHealthBenefitsAndHarms",
          "name": "Bilancio benefici e danni",
          "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "Certezza nella stima degli effetti",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "Certezza delle prove",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "Certezza delle prove relative agli effetti della gestione",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "Certezza delle prove di evidenza relative ai costi richiesti",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "Qual'è la certezza delle prove di evidenza relative all'accuratezza del test?",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "Certezza delle prove relative agli effetti del test",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "Certezza delle prove relative ai risultati del test/management",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "Costo-efficacia",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "Effetti desiderabili",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "Equità",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "Fattibilità",
          "researchEvidenceId": "feasibility"
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
          "criterionId": "feasibilityAndHealthSystemConsiderations",
          "name": "Fattibilità e considerazioni relative al sistema sanitario",
          "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
        },
        "feasible": {
          "additionalConsiderationId": "feasible",
          "criterionId": "feasible",
          "name": "FEASIBLE",
          "researchEvidenceId": "feasible"
        },
        "financialAndEconomicConsiderations": {
          "additionalConsiderationId": "financialAndEconomicConsiderations",
          "criterionId": "financialAndEconomicConsiderations",
          "name": "Considerazioni economiche e finanziarie",
          "researchEvidenceId": "financialAndEconomicConsiderations"
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
          "criterionId": "healthEquityEqualityAndNonDiscrimination",
          "name": "Equità, uguaglianza e non-discriminazione nella salute ",
          "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
        },
        "humanRights": {
          "additionalConsiderationId": "humanRights",
          "criterionId": "humanRights",
          "name": "Copyright © 2015, McMaster University and Evidence Prime Inc., Tutti i diritti sono riservati",
          "researchEvidenceId": "humanRights"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "Problema",
          "researchEvidenceId": "problem"
        },
        "qualityOfEvidence": {
          "additionalConsiderationId": "qualityOfEvidence",
          "criterionId": "qualityOfEvidence",
          "name": "Qualità delle prove ",
          "researchEvidenceId": "qualityOfEvidence"
        },
        "relevantDirect": {
          "additionalConsiderationId": "relevantDirect",
          "criterionId": "relevantDirect",
          "name": "RELEVANT (DIRECT)",
          "researchEvidenceId": "relevantDirect"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "Risorse necessarie",
          "researchEvidenceId": "resourcesRequired"
        },
        "responsive": {
          "additionalConsiderationId": "responsive",
          "criterionId": "responsive",
          "name": "RESPONSIVE",
          "researchEvidenceId": "responsive"
        },
        "scientificallySound": {
          "additionalConsiderationId": "scientificallySound",
          "criterionId": "scientificallySound",
          "name": "SCIENTIFICALLY SOUND (CERTAINTY OF EVIDENCE)",
          "researchEvidenceId": "scientificallySound"
        },
        "societalImplications": {
          "additionalConsiderationId": "societalImplications",
          "criterionId": "societalImplications",
          "name": "Complicanze",
          "researchEvidenceId": "societalImplications"
        },
        "socioCulturalAcceptability": {
          "additionalConsiderationId": "socioCulturalAcceptability",
          "criterionId": "socioCulturalAcceptability",
          "name": "Accettabilità",
          "researchEvidenceId": "socioCulturalAcceptability"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": "Test di accuratezza",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "Effetti desiderabili",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "Valori",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "benefits": {
          "content": "",
          "description": "Benefici"
        },
        "clearAndActionable": {
          "content": "",
          "description": "Chiaro e fattibile",
          "options": [
            {
              "text": "Si",
              "value": "yes"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "clinicalConsiderations": {
          "content": "",
          "description": "Clinical considerations"
        },
        "collectingAndSummarizing": {
          "content": "",
          "description": "Raccogliere e riassumere le prove di evidenza è un uso improprio del tempo e delle risorse a disposizione del panel incaricato delle linee guida (costo opportunità in generale)",
          "options": [
            {
              "text": "Si",
              "value": "yes"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "considerations": {
          "content": "",
          "description": "Considerazioni"
        },
        "contextAndSystemConsiderations": {
          "content": "",
          "description": "Considerazioni di contesto e di sistema"
        },
        "decision": {
          "content": "",
          "description": "Decisione",
          "options": [
            {
              "text": "Piena implementazione",
              "value": "full_implementation"
            },
            {
              "text": "Valutazione d'impatto",
              "value": "impact_evaluation"
            },
            {
              "text": "Studio pilota",
              "value": "pilot_study"
            },
            {
              "text": "Posticipa",
              "value": "postpone"
            },
            {
              "text": "Non implementare",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "Tipo d decisione ",
          "options": [
            {
              "direction": "negative",
              "text": "Non copre",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "Fornire una copertura con produzione di prove",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "Fornire copertura con una negoziazione del prezzo",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "Copertura limitata",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "Copertura",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "Tipo di decisione",
          "options": [
            {
              "direction": "negative",
              "text": "Non implementare l'opzione",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "Posticipa la decisione",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "Condurre uno studio pilota sull'opzione",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "Implementare l'opzione con una valutazione di impatto",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "Implementare l'opzione",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "evidence": {
          "content": "",
          "description": "Evidenze"
        },
        "frequencyDoseDuration": {
          "content": "",
          "description": "Frequenza/Dose/Durata"
        },
        "goodPracticeStatement": {
          "content": "",
          "description": "Dichiarazione di buona pratica"
        },
        "harms": {
          "content": "",
          "description": "Danni"
        },
        "implementationConsiderations": {
          "content": "",
          "description": "Considerazioni per l'implementazione",
          "details": {
            "content": "",
            "description": "Dettagli sull'implementazione"
          }
        },
        "implementationConsiderationsOfAllRelevantOutcomes": {
          "content": "",
          "description": "Dopo aver preso in considerazione tutti gli outcome rilevanti e le potenziali conseguenze, l'implementazione delle buone pratiche porta a numerose conseguenze positive",
          "options": [
            {
              "text": "Si",
              "value": "yes"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderationsOrRemarks": {
          "content": "",
          "description": "Considerazioni per l'implementazione",
          "options": [
            {
              "text": "Si",
              "value": "yes"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "indications": {
          "content": "",
          "description": "Indicazioni"
        },
        "indicationsForDiscontinuation": {
          "content": "",
          "description": "Indicazioni per l'interruzione"
        },
        "justification": {
          "content": "",
          "description": "Giustificazione",
          "details": {
            "description": "Giustificazione dettagliata"
          },
          "overallDescription": "Giustificazione globale "
        },
        "keyMessage": {
          "content": "",
          "description": "Messaggio chiave"
        },
        "limitations": {
          "content": "",
          "description": "Limitazioni"
        },
        "mdgRecommendation": {
          "content": "",
          "description": "Raccomandazione",
          "options": [
            {
              "direction": "positive",
              "text": "Raccomandato",
              "value": "recommend"
            },
            {
              "direction": "negative",
              "text": "Non raccomandato",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Sometimes Recommended",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Nessuna Raccomandazione",
              "value": "suggest_either"
            }
          ],
          "selectedOption": ""
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "Monitoraggio e valutazione",
          "details": {
            "content": "",
            "description": "Dettagli su monitoraggio e valutazione"
          }
        },
        "monitoringAndEvaluationQI": {
          "content": "",
          "description": "Monitoraggio e valutazione"
        },
        "multipleChronicConditionsAndPolypharmacy": {
          "content": "",
          "description": "Condizioni di multi-cronicità e poli farmacoterapia"
        },
        "multipleRecommendations": {
          "description": "Raccomandazione/i",
          "options": [
            {
              "direction": "negative",
              "text": "Raccomandazione forte contro l'intervento",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Raccomandazione condizionale contro l'intervento",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Raccomandazione condizionale sia per l'intervento che per il confronto ",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Raccomandazione condizionale a favore dell'intervento",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Raccomandazione forte a favore dell'intervento",
              "value": "recommend"
            }
          ],
          "recommendations": [
            {
              "content": "",
              "selectedOption": ""
            }
          ]
        },
        "rationale": {
          "content": "",
          "description": "Razionale"
        },
        "rationaleConnectingIndirectEvidence": {
          "content": "",
          "description": "C'è un razionale ben documentato, chiaro ed esplicito che collega le prove indirette",
          "options": [
            {
              "text": "Si",
              "value": "yes"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "recommendation": {
          "content": "",
          "description": "Raccomandazione"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "Tipo di raccomandazione",
          "options": [
            {
              "direction": "negative",
              "text": "Raccomandazione forte contro l'intervento",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Raccomandazione condizionale contro l'intervento",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Raccomandazione condizionale sia per l'intervento che per il confronto ",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Raccomandazione condizionale a favore dell'intervento",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Raccomandazione forte a favore dell'intervento",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "Tipo di raccomandazione",
          "options": [
            {
              "direction": "negative",
              "text": "Raccomandazione forte contro l'opzione",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Raccomandazione condizionale contro l'opzione",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Raccomandazione condizionale di non differenza fra opzione e confronto",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Raccomandazione condizionale a favore dell'opzione",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Raccomandazione condizionale a favore dell'opzione",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "Priorità della ricerca"
        },
        "restrictions": {
          "content": "",
          "description": "Restrizioni"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "Considerazioni relative al sottogruppo"
        },
        "theMessageIsNecessary": {
          "content": "",
          "description": "Il messaggio è necessario in relazione alla pratica sanitaria attuale",
          "options": [
            {
              "text": "Si",
              "value": "yes"
            },
            {
              "text": "Probabilmente si",
              "value": "probably_yes"
            },
            {
              "text": "Probabilmente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "Clinici",
          "sections": {
            "background": {
              "name": "Background"
            },
            "detailedJustification": {
              "name": "Giustificazione dettagliata"
            },
            "implementationConsiderations": {
              "name": "Considerazioni per l'implementazione"
            },
            "justification": {
              "name": "Giustificazione"
            },
            "relatedRecommendations": {
              "name": "Raccomandazioni correlate"
            },
            "subgroupConsiderations": {
              "name": "Considerazioni relative al sottogruppo"
            },
            "summaryOfFindings": {
              "name": "Sintesi dei risultati"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "Pazienti",
          "sections": {
            "relatedRecommendations": {
              "name": "Raccomandazioni correlate"
            },
            "summaryOfFindings": {
              "name": "SoF"
            },
            "whatItMeansForYou": {
              "name": "Cosa significa per te?",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Parla con il tuo medico"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "Cosa puoi fare?"
                }
              }
            },
            "whoIsThisFor": {
              "name": "Per chi è questo?",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Per chi è questo"
                }
              }
            },
            "whyThisRecommendation": {
              "name": "Perché questa raccomandazione?",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "Informazioni aggiuntive"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "Benefici e danni"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "Perché questa raccomandazione"
                }
              }
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "Policymaker",
          "sections": {
            "assessment": {
              "name": "Valutazione"
            },
            "background": {
              "description": "Dettagli del quesito per la raccomandazione ",
              "details": {
                "name": "Riguardo questa decisione",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "Data"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "Decisori"
                  },
                  "perspective": {
                    "content": "",
                    "description": "Prospettiva"
                  },
                  "setting": {
                    "content": "",
                    "description": "Setting"
                  }
                },
                "sectionsOrder": [
                  "setting",
                  "perspective",
                  "decisionMakers",
                  "date"
                ]
              },
              "name": "Background"
            },
            "decision": {
              "content": "",
              "name": "Decisione",
              "options": [
                {
                  "text": "Piena implementazione",
                  "value": "full_implementation"
                },
                {
                  "text": "Valutazione d'impatto",
                  "value": "impact_evaluation"
                },
                {
                  "text": "Studio pilota",
                  "value": "pilot_study"
                },
                {
                  "text": "Posticipa",
                  "value": "postpone"
                },
                {
                  "text": "Non implementare",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "Implementazione"
            },
            "justification": {
              "name": "Giustificazione"
            },
            "monitoringAndEvaluation": {
              "name": "Monitoraggio e Valutazione"
            },
            "relatedRecommendations": {
              "name": "Raccomandazioni correlate"
            },
            "summaryOfFindings": {
              "name": "Sintesi dei risultati"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "age": {
          "content": "",
          "name": "Età"
        },
        "anticipatedOutcomes": {
          "content": "",
          "name": "Esiti attesi"
        },
        "background": {
          "content": "",
          "name": "Background"
        },
        "coi": {
          "content": "",
          "name": "Conflitto di interessi"
        },
        "comparison": {
          "content": "",
          "name": "Confronto"
        },
        "intent": {
          "content": "",
          "name": "Scopo"
        },
        "intervention": {
          "content": "",
          "name": "Intervento"
        },
        "linkedTreatments": {
          "content": "",
          "name": "Trattamenti correlati"
        },
        "mainOutcomes": {
          "content": "",
          "name": "Esiti principali"
        },
        "option": {
          "content": "",
          "name": "Opzione"
        },
        "perspective": {
          "content": "",
          "name": "Prospettiva"
        },
        "population": {
          "content": "",
          "name": "Popolazione"
        },
        "problem": {
          "content": "",
          "name": "Problema"
        },
        "purpose": {
          "content": "",
          "name": "Scopo del testo"
        },
        "requirements": {
          "content": "",
          "name": "Requirements"
        },
        "role": {
          "content": "",
          "name": "Ruolo del testo "
        },
        "setting": {
          "content": "",
          "name": "Setting"
        },
        "subgroups": {
          "content": "",
          "name": "Sottogruppi"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
