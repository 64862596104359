ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
MdaWorkflowDialogActions = require 'actions/mda_workflow_dialog_actions'
MdaWorkflowDialogStore = require 'stores/mda_workflow_dialog_store'
mediator = require 'mediator'
Modal = require 'components/common/modal'
SelectCustom = require 'components/common/select_custom'
TopicsTable = require 'components/mda/workflow/topics_table'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'
W = require 'when/when'

storeConnector =
  MdaWorkflowDialogStore: (Store) ->
    allTables: Store.getAllTables()
    allTopics: Store.getAllTopics()
    isFetchingTopics: Store.isFetchingTopics()
    isOpen: Store.isOpen 'split'
    isSplitting: Store.isSplitting()
    organizationId: Store.getOrganizationId()
    searchString: Store.getSearchString()
    selectedTopics: Store.getSelectedTopics()
    sortColumn: Store.getSortColumn()
    sortType: Store.getSortType()
    topics: Store.getTopics()

MdaSplitWorkflowDialog = ({
  allTables
  allTopics
  isFetchingTopics
  isOpen
  isSplitting
  organizationId
  searchString
  selectedTopics
  sortColumn
  sortType
  topics
}) ->
  i18n = useI18n('mda:topics')

  onCancel = useCoffeeCallback [], -> MdaWorkflowDialogActions.closeDialog()

  onApply = useCoffeeCallback [allTopics, organizationId, selectedTopics], ->
    message = i18n 'split.confirm.message'
    title = i18n 'split.confirm.title'
    mediator.dialogs.confirm { message, title }, (confirmed) ->
      if confirmed
        MdaWorkflowDialogActions.splitWorkflow { allTopics, organizationId, selectedTopics }
      else
        MdaWorkflowDialogActions.closeDialog()

  <Modal
    className="mda-workflow-modal"
    isOpen={isOpen}
    modalSize="large"
    title={i18n 'split_topics'}
  >
    <div className="mda-workflow-modal__content">
      <TopicsTable
        allTables={allTables}
        isFetching={isFetchingTopics}
        noTopicsMessageKey="topics.no_topics_in_project"
        searchString={searchString}
        topics={topics}
        sortColumn={sortColumn}
        sortType={sortType}
        selectedTopics={selectedTopics}
      />
      <ApplyCancelButtons
        applyLabel={i18n '/actions.split'}
        applying={isSplitting}
        onApply={onApply}
        onCancel={onCancel}
        isSubmitEnabled={not selectedTopics.isEmpty()}
      />
    </div>
  </Modal>

module.exports = ConnectStore MdaSplitWorkflowDialog, MdaWorkflowDialogStore, storeConnector
