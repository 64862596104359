Controls = require 'components/team/controls'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'
{ isReadOnly } = require 'lib/members_helper'
{bool, func, string, instanceOf} = PropTypes

MemberControls = createReactClass
  displayName: 'MemberControls'

  propTypes:
    expanded: bool.isRequired
    itemKey: string.isRequired
    member: instanceOf(Immutable.Map).isRequired
    save: func
    delete: func
    edit: func

  mixins: [Translation(), CustomRenderMixin]

  edit: ->
    @props.edit @props.itemKey

  save: ->
    @props.save @props.member

  delete: ->
    @props.delete @props.itemKey

  _getControlsList: ->
    [
      name: 'edit'
      handler: @edit
    ,
      name: 'save'
      handler: @save
    ,
      name: 'delete'
      handler: @delete
      disabled: isReadOnly @props.member
    ]

  render: ->
    controls = @_getControlsList().filter (c) =>
      if @props.expanded then c.name isnt 'edit' else c.name is 'edit'

    <Controls controls={controls} />

module.exports = MemberControls
