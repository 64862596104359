DraggableOutcome = require 'components/scope/outcomes/outcome_draggable'
OutcomeWithComments = require 'components/scope/outcomes/outcome_with_comments'
EditableOutcome = require 'components/scope/outcomes/outcome_editable'
{ dropSpec, dropCollect } = require 'components/scope/outcomes/outcomes_list_dnd_spec'
DragDropTypes = require 'components/scope/outcomes/drag_drop_types'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
DropTarget = ReactDnD.DropTarget

EditableOutcomesList = createReactClass
  displayName: 'EditableOutcomesList'
  mixins: [CustomRenderMixin]

  componentWillReceiveProps: (nextProps) ->
    # cancel previous movement if dragged item is still being draged above not droppable place
    if @props.isOver and not nextProps.isOver
      @props.undoItemMovement()

  _isOutcomeEditing: (oIndex) ->
    @props.editingOutcome?.gIndex is @props.gIndex and
    @props.editingOutcome?.oIndex is oIndex

  _getOutcomeProps: (outcomeData, index, hasComments) ->
    _.extend {
      outcomeData
      gIndex: @props.gIndex
      index: index
      editable: true
      className: 'outcome draggable'
      isEditing: @_isOutcomeEditing index
      onCopy: @props.onCopyOutcome
    }

  _outcomesMapper: (outcome, idx) ->
    hasComments = outcome.get('memberComments')?.flatten().size
    outcomeProps = @_getOutcomeProps outcome, idx
    _key = outcome.get 'key'

    <DraggableOutcome key={idx} gIndex={@props.gIndex} index={idx} _key={_key}>
      {if hasComments
        <OutcomeWithComments {...outcomeProps}
          membersMap={@props.membersMap}
          showingComments={@props.showingComments}
          onCommentToggle={@props.onCommentToggle}
        />
      else
        <EditableOutcome {...outcomeProps} />
      }
    </DraggableOutcome>

  render: ->
    { connectDropTarget, outcomes } = @props
    return false unless outcomes?.size

    connectDropTarget(
      <div className="outcomes">
        {@props.outcomes.map @_outcomesMapper}
      </div>
    )

module.exports = DropTarget(DragDropTypes.OUTCOME, dropSpec, dropCollect) EditableOutcomesList
