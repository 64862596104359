module.exports = function() {
    return {
        "assessment": {
            "additionalConsiderations": {
                "acceptability": {
                    "content": ""
                },
                "balanceOfEffects": {
                    "content": ""
                },
                "certaintyOfEffects": {
                    "content": ""
                },
                "certaintyOfEvidence": {
                    "content": ""
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestResult": {
                    "content": ""
                },
                "costEffectiveness": {
                    "content": ""
                },
                "desirableEffects": {
                    "content": ""
                },
                "equity": {
                    "content": ""
                },
                "feasibility": {
                    "content": ""
                },
                "problem": {
                    "content": ""
                },
                "resourcesRequired": {
                    "content": ""
                },
                "testAccuracy": {
                    "content": ""
                },
                "undesirableEffects": {
                    "content": ""
                },
                "values": {
                    "content": ""
                }
            },
            "criteria": {
                "acceptability": {
                    "additionalOptions": [
                        {
                            "text": "さまざま",
                            "value": "varies"
                        },
                        {
                            "text": "分からない",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "この選択肢は重要な利害関係者にとって妥当なものですか？",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    }
                                ],
                                "question": "利益と害とコストの分布に妥当性がないとみなす、重要な利害関係者はいますか?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    }
                                ],
                                "question": "将来の望ましい効果（利益）のために短期的にコストまたは望ましくない効果を受け入れない重要な利害関係者はいますか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    }
                                ],
                                "question": "（個人的に受ける影響が理由で、または自身以外の人々にとっての相対的重要性に関する個人的見解を理由に）望ましい効果と望ましくない効果に置かれる価値に異議を唱える重要な利害関係者はいますか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    }
                                ],
                                "question": "当該介入（選択肢）は、人々の自主性に悪影響を及ぼしますか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    }
                                ],
                                "question": "可能であれば不公平を減らすこと、そして不公平が増大しないことを確実にするために、介入（選択肢）を実施する上で考慮すべき重要な検討事項はありますか？",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "重要な利害関係者らが、利益、害、コストの分布、または将来的に望ましい効果（利益）を得るための短期的なコストや望ましくない効果を容認しないと考えられるか。重要な利害関係者らが、望ましい効果または望ましくない効果に置かれる価値に納得しないと考えられるか、あるいは倫理的な懸念から診断的介入を容認しないと考えられますか？",
                    "options": [
                        {
                            "text": "いいえ",
                            "value": "no"
                        },
                        {
                            "text": "おそらく、いいえ",
                            "value": "probably_no"
                        },
                        {
                            "text": "おそらく、はい",
                            "value": "probably_yes"
                        },
                        {
                            "text": "はい",
                            "value": "yes"
                        }
                    ],
                    "selectedOption": ""
                },
                "additionalSection": {
                    "description": "Custom criterion description",
                    "info": "Provide custom tooltip",
                    "options": [
                      {
                        "text": "Judgement 1",
                        "value": "option_1"
                      },
                      {
                        "text": "Judgement 2",
                        "value": "option_2"
                      },
                      {
                        "text": "Judgement 3",
                        "value": "option_3"
                      },
                      {
                        "text": "Judgement 4",
                        "value": "option_4"
                      },
                      {
                        "text": "Judgement 5",
                        "value": "option_5"
                      }
                    ],
                    "additionalOptions": [
                      {
                        "text": "Additional judgement 1",
                        "value": "additionalOption_1"
                      },
                      {
                        "text": "Additional judgement 2",
                        "value": "additionalOption_2"
                      }
                    ],
                    "selectedOption": "",
                    "decision": {
                      "selectedOption": "",
                      "comments": ""
                    },
                    "details": {
                      "panelDiscussion": "",
                      // questionsType is one of ['general', 'resources', 'outcomes']
                      "questionsType": "general",
                      "questions": []
                    }
                },
                "balanceOfEffects": {
                    "additionalOptions": [
                        {
                            "text": "さまざま",
                            "value": "varies"
                        },
                        {
                            "text": "分からない",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "望ましい効果と望ましくない効果のバランスは介入もしく比較対照を支持しますか？",
                    "details": {
                        "additionalInfos": [
                            {
                                "criteriaAnswers": [
                                    {
                                        "criterionId": "values"
                                    },
                                    {
                                        "criterionId": "certaintyOfEvidence"
                                    },
                                    {
                                        "criterionId": "desirableEffects"
                                    },
                                    {
                                        "criterionId": "undesirableEffects"
                                    }
                                ],
                                "description": "この基準についての詳細な判定には先行する４基準の各々に関する判定が含まれています。"
                            },
                            {
                                "additions": [
                                    "今発生するアウトカムに置かれる価値と比較したとき、将来のアウトカムに置かれる価値はどの程度低いか（現在のアウトカムからの割引率）？",
                                    "望ましくない効果に対する人々の態度（リスク回避の度合い）。",
                                    "望ましい効果に対する人々の態度（リスク志向の度合い）。"
                                ],
                                "description": "さらに、パネルは、次に示す検討事項が、望ましい効果と望ましくない効果のバランスにどの程度影響するかについて検討（そして必要であれば記録）するとよいだろう。"
                            }
                        ],
                        "panelDiscussion": ""
                    },
                    "info": "各個人が各主要アウトカムに置く価値、望ましい効果と望ましくない効果の程度、これらの推定値の確信性、割引率、ならびにリスク回避とリスク志向を考慮した場合の望ましい効果と望ましくない効果のバランスは何ですか？",
                    "options": [
                        {
                            "text": "比較対照が優位",
                            "value": "favors_comparison"
                        },
                        {
                            "text": "比較対照がおそらく優位",
                            "value": "probably_favors_comparison"
                        },
                        {
                            "text": "介入も比較対象もいずれも優位でない",
                            "value": "doesnt_favor_any"
                        },
                        {
                            "text": "おそらく介入が優位",
                            "value": "probably_favors_intervention"
                        },
                        {
                            "text": "介入が優位",
                            "value": "favors_intervention"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEffects": {
                    "additionalOptions": [
                        {
                            "text": "採用研究なし",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "検査の効果に関する全体的なエビデンスの確実性は何ですか？",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "info": "重大なアウトカムのすべてにおいて考えられる効果について研究がどれだけ優れた指標を提供するか、すなわち効果が当該研究で見つけた診断介入の決断を左右するほど大幅に異なる可能性を評価したものです。",
                    "options": [
                        {
                            "text": "非常に低",
                            "value": "very_low"
                        },
                        {
                            "text": "低",
                            "value": "low"
                        },
                        {
                            "text": "中",
                            "value": "moderate"
                        },
                        {
                            "text": "高",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidence": {
                    "additionalOptions": [
                        {
                            "text": "採用研究なし",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "効果に関する全体的なエビデンスの確実性は何ですか？",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "info": "重大なアウトカムのすべてにおいて考えられる効果について研究がどれだけ優れた指標を提供するか、すなわち効果が当該研究で見つけた治療介入の決断を左右するほど大幅に異なる可能性を評価したものです。",
                    "options": [
                        {
                            "text": "非常に低",
                            "value": "very_low"
                        },
                        {
                            "text": "低",
                            "value": "low"
                        },
                        {
                            "text": "中",
                            "value": "moderate"
                        },
                        {
                            "text": "高",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "additionalOptions": [
                        {
                            "text": "採用研究なし",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "What is the overall certainty of the evidence of effects of the management that is guided by the test results?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "非常に低",
                            "value": "very_low"
                        },
                        {
                            "text": "低",
                            "value": "low"
                        },
                        {
                            "text": "中",
                            "value": "moderate"
                        },
                        {
                            "text": "高",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "additionalOptions": [
                        {
                            "text": "採用研究なし",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "必要資源量（コスト）に関するエビデンスの確実性はなんですか？",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "さまざま",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    }
                                ],
                                "question": "考慮された選択肢の間で異なるかもしれない資源利用に関する重要なすべての項目が同定されていますか？",
                                "resources": []
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "重要な不確実性あり",
                                        "value": "important"
                                    },
                                    {
                                        "text": "おそらく重要な不確実性あり",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "おそらく重要な不確実性なし",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "重要な不確実性なし",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "検討されている選択肢の間で資源利用の項目のコストが、異なることに、どれほど確信がおけますか？",
                                "resources": []
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "重要なばらつきあり",
                                        "value": "important"
                                    },
                                    {
                                        "text": "おそらく重要なばらつきあり",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "おそらく重要なばらつきなし",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "重要なばらつきなし",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "検討されている選択肢の間で資源利用の項目のコストが、異なることに、重要なばらつきがありますか？",
                                "resources": []
                            }
                        ],
                        "questionsType": "resources"
                    },
                    "info": "資源利用のタイプ（例、薬剤、入院）やそのコストの違いに関するエビデンスは、どれくらい確実ですか？",
                    "options": [
                        {
                            "text": "非常に低",
                            "value": "very_low"
                        },
                        {
                            "text": "低",
                            "value": "low"
                        },
                        {
                            "text": "中",
                            "value": "moderate"
                        },
                        {
                            "text": "高",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "additionalOptions": [
                        {
                            "text": "採用研究なし",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "検査精度に関するエビデンスの全体的な確実性は何ですか？",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "非常に低",
                            "value": "very_low"
                        },
                        {
                            "text": "低",
                            "value": "low"
                        },
                        {
                            "text": "中",
                            "value": "moderate"
                        },
                        {
                            "text": "高",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "additionalOptions": [
                        {
                            "text": "採用研究なし",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "重大もしくは重要な直接的な利益、有害作用、検査の負担に関する全体的なエビデンスの確実性は何ですか？",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "非常に低",
                            "value": "very_low"
                        },
                        {
                            "text": "低",
                            "value": "low"
                        },
                        {
                            "text": "中",
                            "value": "moderate"
                        },
                        {
                            "text": "高",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfTestResult": {
                    "additionalOptions": [
                        {
                            "text": "採用研究なし",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "検査結果とマネジメント決断のリンクはどれくらい確実ですか？",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "非常に低",
                            "value": "very_low"
                        },
                        {
                            "text": "低",
                            "value": "low"
                        },
                        {
                            "text": "中",
                            "value": "moderate"
                        },
                        {
                            "text": "高",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "costEffectiveness": {
                    "additionalOptions": [
                        {
                            "text": "さまざま",
                            "value": "varies"
                        },
                        {
                            "text": "採用研究なし",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "その介入の費用対効果は介入または比較対照を支持しますか？",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "採用研究なし",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    }
                                ],
                                "question": "その費用対効果比率は、一元感度分析の感度が高いものですか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "採用研究なし",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    }
                                ],
                                "question": "その費用対効果比率は、多元感度分析の感度が高いものですか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "採用研究なし",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    },
                                    {
                                        "text": "非常に深刻な限界あり",
                                        "value": "very_serious"
                                    },
                                    {
                                        "text": "深刻な限界あり",
                                        "value": "serious"
                                    },
                                    {
                                        "text": "深刻な限界なし",
                                        "value": "no_serious"
                                    }
                                ],
                                "question": "費用対効果の推定値のベースになっているその経済評価は信頼できるものですか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "採用研究なし",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    },
                                    {
                                        "text": "非常に深刻な限界あり",
                                        "value": "very_serious"
                                    },
                                    {
                                        "text": "深刻な限界あり",
                                        "value": "serious"
                                    },
                                    {
                                        "text": "深刻な限界なし",
                                        "value": "no_serious"
                                    }
                                ],
                                "question": "費用対効果の推定値のベースになっているその経済評価は懸案のセッティングに適用できるものですか？",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "コストにおける不確実性やばらつき、正味の利益に関する不確実性やばらつき、感度分析、経済評価の信頼性や適応性を考慮した場合に、その介入は費用効果的ですか？",
                    "options": [
                        {
                            "text": "比較対照が優位",
                            "value": "favors_comparison"
                        },
                        {
                            "text": "比較対照がおそらく優位",
                            "value": "probably_favors_comparison"
                        },
                        {
                            "text": "介入も比較対象もいずれも優位でない",
                            "value": "doesnt_favor_any"
                        },
                        {
                            "text": "おそらく介入が優位",
                            "value": "probably_favors_intervention"
                        },
                        {
                            "text": "介入が優位",
                            "value": "favors_intervention"
                        }
                    ],
                    "selectedOption": ""
                },
                "desirableEffects": {
                    "additionalOptions": [
                        {
                            "text": "さまざま",
                            "value": "varies"
                        },
                        {
                            "text": "分からない",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "予期される望ましい効果はどの程度のものですか？",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "さまざま",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "わずか",
                                        "value": "trivial"
                                    },
                                    {
                                        "text": "小さい",
                                        "value": "small"
                                    },
                                    {
                                        "text": "中",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "大きい",
                                        "value": "large"
                                    }
                                ],
                                "question": "望ましい効果がある際の各主要アウトカムに関する予期される効果（差）はどの程度のものですか？",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "各アウトカムの重要性（各アウトカムにどれだけの価値が置かれるか）、ならびに効果のサイズ（利益を享受できる可能性、または各個人がどの程度の改善を実感すると考えられるか）を考慮した場合、介入の望ましい効果効果はどれほど大きいですか？",
                    "options": [
                        {
                            "text": "わずか",
                            "value": "trivial"
                        },
                        {
                            "text": "小さい",
                            "value": "small"
                        },
                        {
                            "text": "中",
                            "value": "moderate"
                        },
                        {
                            "text": "大きい",
                            "value": "large"
                        }
                    ],
                    "selectedOption": ""
                },
                "equity": {
                    "additionalOptions": [
                        {
                            "text": "さまざま",
                            "value": "varies"
                        },
                        {
                            "text": "分からない",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "医療上の不公平さへの影響は何ですか？",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    }
                                ],
                                "question": "考慮される問題や選択肢に関連して不利益を被るかもしれないグループや状況がありますか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    }
                                ],
                                "question": "不利益を被るグループまたは状況のために当該選択肢の相対的な効果の違いを予測するもっともらしい理由はありますか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    }
                                ],
                                "question": "不利益を被るグループまたは状況のために当該選択肢の絶対的効果または問題の重要性に影響するグループあるいは状況についてベースライン状態の違いはありますか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    }
                                ],
                                "question": "可能であれば不公平を減らすこと、そして不公平が増大しないことを確実にするために、介入（選択肢）を実施する上で考慮すべき重要な検討事項はありますか？",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "不利益を被るサブグループ間で介入の相対効果に差があること、または不利益を被るサブグループ間でベースライン条件に差があることにより、介入の絶対効果または問題の重要度が左右されると予期されるような妥当な事由はありますか？",
                    "options": [
                        {
                            "text": "減る",
                            "value": "reduced"
                        },
                        {
                            "text": "おそらく減る",
                            "value": "probably_reduced"
                        },
                        {
                            "text": "おそらく影響無し",
                            "value": "probably_no_impact"
                        },
                        {
                            "text": "おそらく増える",
                            "value": "probably_increased"
                        },
                        {
                            "text": "増える",
                            "value": "increased"
                        }
                    ],
                    "selectedOption": ""
                },
                "feasibility": {
                    "additionalOptions": [
                        {
                            "text": "さまざま",
                            "value": "varies"
                        },
                        {
                            "text": "分からない",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "その介入は実行可能ですか?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "さまざま",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    }
                                ],
                                "question": "その介入（選択肢）は持続可能ですか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "さまざま",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    }
                                ],
                                "question": "その介入（選択肢）の実行可能性を制限するような、または介入を実行する際に検討を必要するような重要な障壁はありますか？",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "診断的介入の使用を持続しながらも当該介入の使用を妨げる要因に対処することは可能ですか？",
                    "options": [
                        {
                            "text": "いいえ",
                            "value": "no"
                        },
                        {
                            "text": "おそらく、いいえ",
                            "value": "probably_no"
                        },
                        {
                            "text": "おそらく、はい",
                            "value": "probably_yes"
                        },
                        {
                            "text": "はい",
                            "value": "yes"
                        }
                    ],
                    "selectedOption": ""
                },
                "problem": {
                    "additionalOptions": [
                        {
                            "text": "さまざま",
                            "value": "varies"
                        },
                        {
                            "text": "分からない",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "この問題は優先事項ですか？",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "さまざま",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    }
                                ],
                                "question": "この問題の帰結は深刻ですか（すなわち、考えられる利益やコスト削減の観点から、帰結は重大または重要ですか）？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "さまざま",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    }
                                ],
                                "question": "この問題は切実ですか？",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "さまざま",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "いいえ",
                                        "value": "no"
                                    },
                                    {
                                        "text": "おそらく、いいえ",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "おそらく、はい",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "はい",
                                        "value": "yes"
                                    }
                                ],
                                "question": "それは広く認識されている（例、政治的または政策決定に基づいた）優先事項ですか？",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "問題が深刻または切実であるほど、その問題に対処する選択肢が優先事項となるべき可能性は高い",
                    "options": [
                        {
                            "text": "いいえ",
                            "value": "no"
                        },
                        {
                            "text": "おそらく、いいえ",
                            "value": "probably_no"
                        },
                        {
                            "text": "おそらく、はい",
                            "value": "probably_yes"
                        },
                        {
                            "text": "はい",
                            "value": "yes"
                        }
                    ],
                    "selectedOption": ""
                },
                "resourcesRequired": {
                    "additionalOptions": [
                        {
                            "text": "さまざま",
                            "value": "varies"
                        },
                        {
                            "text": "分からない",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "資源要件（コスト）はどの程度大きいですか？",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "さまざま",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "わずか",
                                        "value": "trivial"
                                    },
                                    {
                                        "text": "小さい",
                                        "value": "small"
                                    },
                                    {
                                        "text": "中",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "大きい",
                                        "value": "large"
                                    }
                                ],
                                "question": "必要資源量の少ない各資源項目間の差異はどれほど大きいか？",
                                "resources": []
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "さまざま",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "大きい",
                                        "value": "large"
                                    },
                                    {
                                        "text": "中",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "小さい",
                                        "value": "small"
                                    },
                                    {
                                        "text": "わずか",
                                        "value": "trivial"
                                    }
                                ],
                                "question": "必要資源量の大きい各資源項目間の差異はどれほど大きいですか？",
                                "resources": []
                            }
                        ],
                        "questionsType": "resources"
                    },
                    "info": "資源利用のうちコストの違いは、介入と比較対照でどの程度大きいですか？",
                    "options": [
                        {
                            "text": "大きなコスト",
                            "value": "large_costs"
                        },
                        {
                            "text": "中等度のコスト",
                            "value": "moderate_costs"
                        },
                        {
                            "text": "無視できるほどのコストや節減",
                            "value": "negligible_costs_savings"
                        },
                        {
                            "text": "中等度の節減",
                            "value": "moderate_savings"
                        },
                        {
                            "text": "大きな節減",
                            "value": "large_savings"
                        }
                    ],
                    "selectedOption": ""
                },
                "testAccuracy": {
                    "additionalOptions": [
                        {
                            "text": "さまざま",
                            "value": "varies"
                        },
                        {
                            "text": "分からない",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "検査はどのくらい正確ですか?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "非常に不正確",
                            "value": "very_inaccurate"
                        },
                        {
                            "text": "不正確",
                            "value": "inaccurate"
                        },
                        {
                            "text": "正確",
                            "value": "accurate"
                        },
                        {
                            "text": "非常に正確",
                            "value": "very_accurate"
                        }
                    ],
                    "selectedOption": ""
                },
                "undesirableEffects": {
                    "additionalOptions": [
                        {
                            "text": "さまざま",
                            "value": "varies"
                        },
                        {
                            "text": "分からない",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "予期される望ましくない効果はどの程度のものですか？",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "さまざま",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "分からない",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "大きい",
                                        "value": "large"
                                    },
                                    {
                                        "text": "中",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "小さい",
                                        "value": "small"
                                    },
                                    {
                                        "text": "わずか",
                                        "value": "trivial"
                                    }
                                ],
                                "question": "望ましくない効果がある際の各主要アウトカムについて予期される効果（差）はどの程度ですか？",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "各アウトカムの重要性（各アウトカムにどれだけの価値が置かれるか）、ならびに効果のサイズ（利益を享受できる可能性、または各個人がどの程度の改善を実感すると考えられるか）を考慮した場合、介入の望ましくない効果の大きさはどれほどですか？",
                    "options": [
                        {
                            "text": "大きい",
                            "value": "large"
                        },
                        {
                            "text": "中",
                            "value": "moderate"
                        },
                        {
                            "text": "小さい",
                            "value": "small"
                        },
                        {
                            "text": "わずか",
                            "value": "trivial"
                        }
                    ],
                    "selectedOption": ""
                },
                "values": {
                    "additionalOptions": [],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "人々が主要なアウトカムをどの程度重視するかについて重要な不確実性はありますか？",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "options": [
                                    {
                                        "text": "重要な不確実性あり",
                                        "value": "important"
                                    },
                                    {
                                        "text": "おそらく重要な不確実性あり",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "おそらく重要な不確実性なし",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "重要な不確実性なし",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "人々が主要なアウトカムをどの程度重視するかについて重要な不確実性はありますか？",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "各個人が各主要アウトカムに置く価値の大きさはどの程度のものですか？各個人が各アウトカムに置く価値の大きさに関する不確実性、あるいは各個人が各アウトカムに置く価値の大きさのばらつきに関する不確実性が大きすぎるために、複数の異なる決断が導かれてしまう可能性はありますか？",
                    "options": [
                        {
                            "text": "重要な不確実性またはばらつきあり",
                            "value": "important_uncertainty"
                        },
                        {
                            "text": "重要な不確実性またはばらつきの可能性あり",
                            "value": "possible_important"
                        },
                        {
                            "text": "重要な不確実性またはばらつきはおそらくなし",
                            "value": "probably_no_important"
                        },
                        {
                            "text": "重要な不確実性またはばらつきはなし",
                            "value": "no_important"
                        }
                    ],
                    "selectedOption": ""
                }
            },
            "researchEvidences": {
                "acceptability": {
                    "content": ""
                },
                "balanceOfEffects": {
                    "content": ""
                },
                "certaintyOfEffects": {
                    "content": ""
                },
                "certaintyOfEvidence": {
                    "content": ""
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestResult": {
                    "content": ""
                },
                "costEffectiveness": {
                    "content": ""
                },
                "desirableEffects": {
                    "content": ""
                },
                "equity": {
                    "content": ""
                },
                "feasibility": {
                    "content": ""
                },
                "problem": {
                    "content": ""
                },
                "resourcesRequired": {
                    "content": ""
                },
                "testAccuracy": {
                    "content": ""
                },
                "undesirableEffects": {
                    "content": ""
                },
                "values": {
                    "content": ""
                }
            },
            "sections": {
                "acceptability": {
                    "additionalConsiderationId": "acceptability",
                    "criterionId": "acceptability",
                    "name": "容認性",
                    "researchEvidenceId": "acceptability"
                },
                "additionalSection": {
                  "additionalConsiderationId": "additionalSection",
                  "criterionId": "additionalSection",
                  "name": "Custom criterion",
                  "researchEvidenceId": "additionalSection"
                },
                "balanceOfEffects": {
                    "additionalConsiderationId": "balanceOfEffects",
                    "criterionId": "balanceOfEffects",
                    "name": "効果のバランス",
                    "researchEvidenceId": "balanceOfEffects"
                },
                "certaintyOfEffects": {
                    "additionalConsiderationId": "certaintyOfEffects",
                    "criterionId": "certaintyOfEffects",
                    "name": "効果の確実性",
                    "researchEvidenceId": "certaintyOfEffects"
                },
                "certaintyOfEvidence": {
                    "additionalConsiderationId": "certaintyOfEvidence",
                    "criterionId": "certaintyOfEvidence",
                    "name": "エビデンスの確実性",
                    "researchEvidenceId": "certaintyOfEvidence"
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
                    "criterionId": "certaintyOfEvidenceOfManagementEffects",
                    "name": "マネジメントの効果に関するエビデンスの確実性",
                    "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
                    "criterionId": "certaintyOfEvidenceOfRequiredResources",
                    "name": "必要資源量に関するエビデンスの確実性",
                    "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
                    "criterionId": "certaintyOfEvidenceOfTestAccuracy",
                    "name": "検査精度のエビデンスの確実性",
                    "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
                    "criterionId": "certaintyOfEvidenceOfTestEffects",
                    "name": "検査の効果に関するエビデンスの確実性",
                    "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
                },
                "certaintyOfEvidenceOfTestResult": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
                    "criterionId": "certaintyOfEvidenceOfTestResult",
                    "name": "検査/マネジメントの結果にかかわるエビデンスの確実性",
                    "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
                },
                "costEffectiveness": {
                    "additionalConsiderationId": "costEffectiveness",
                    "criterionId": "costEffectiveness",
                    "name": "費用対効果",
                    "researchEvidenceId": "costEffectiveness"
                },
                "desirableEffects": {
                    "additionalConsiderationId": "desirableEffects",
                    "criterionId": "desirableEffects",
                    "name": "望ましい効果",
                    "researchEvidenceId": "desirableEffects"
                },
                "equity": {
                    "additionalConsiderationId": "equity",
                    "criterionId": "equity",
                    "name": "公平性",
                    "researchEvidenceId": "equity"
                },
                "feasibility": {
                    "additionalConsiderationId": "feasibility",
                    "criterionId": "feasibility",
                    "name": "実行可能性",
                    "researchEvidenceId": "feasibility"
                },
                "problem": {
                    "additionalConsiderationId": "problem",
                    "criterionId": "problem",
                    "name": "問題",
                    "researchEvidenceId": "problem"
                },
                "resourcesRequired": {
                    "additionalConsiderationId": "resourcesRequired",
                    "criterionId": "resourcesRequired",
                    "name": "必要資源量",
                    "researchEvidenceId": "resourcesRequired"
                },
                "testAccuracy": {
                    "additionalConsiderationId": "testAccuracy",
                    "criterionId": "testAccuracy",
                    "name": "検査精度",
                    "researchEvidenceId": "testAccuracy"
                },
                "undesirableEffects": {
                    "additionalConsiderationId": "undesirableEffects",
                    "criterionId": "undesirableEffects",
                    "name": "望ましくない効果",
                    "researchEvidenceId": "undesirableEffects"
                },
                "values": {
                    "additionalConsiderationId": "values",
                    "criterionId": "values",
                    "name": "価値観",
                    "researchEvidenceId": "values"
                }
            },
            "sectionsOrder": []
        },
        "conclusions": {
            "sections": {
                "decision": {
                    "content": "",
                    "description": "決断",
                    "options": [
                        {
                            "text": "本格的な実施",
                            "value": "full_implementation"
                        },
                        {
                            "text": "影響評価",
                            "value": "impact_evaluation"
                        },
                        {
                            "text": "予備研究",
                            "value": "pilot_study"
                        },
                        {
                            "text": "保留",
                            "value": "postpone"
                        },
                        {
                            "text": "実施しない",
                            "value": "do_not_implement"
                        }
                    ]
                },
                "decisionTypeCoverage": {
                    "content": "",
                    "description": "決断のタイプ",
                    "options": [
                        {
                            "text": "保険適用しない",
                            "value": "do_not_cover"
                        },
                        {
                            "text": "エビデンスの蓄積を条件に保険適用とする",
                            "value": "cover_with_evidence"
                        },
                        {
                            "text": "価格交渉を条件に保険適用とする",
                            "value": "cover_with_price"
                        },
                        {
                            "text": "部分的な保険適用",
                            "value": "restricted_coverage"
                        },
                        {
                            "text": "保険適用とする",
                            "value": "cover"
                        }
                    ],
                    "selectedOption": ""
                },
                "decisionTypeImplementation": {
                    "content": "",
                    "description": "決断のタイプ",
                    "options": [
                        {
                            "text": "当該選択肢を実施しない",
                            "value": "do_not_implement"
                        },
                        {
                            "text": "決断を保留する",
                            "value": "postpone"
                        },
                        {
                            "text": "当該選択肢の予備研究を実施する",
                            "value": "conduct_pilot_study"
                        },
                        {
                            "text": "影響評価を行い選択肢を実施する",
                            "value": "implement_with_evaluation"
                        },
                        {
                            "text": "当該選択肢を実施する",
                            "value": "implement"
                        }
                    ],
                    "selectedOption": ""
                },
                "implementationConsiderations": {
                    "content": "",
                    "description": "実施にかかわる検討事項",
                    "details": {
                        "content": "",
                        "description": "実行性の詳細"
                    }
                },
                "justification": {
                    "content": "",
                    "description": "正当性",
                    "details": {
                        "description": "正当性の詳細"
                    },
                    "overallDescription": "全体的な正当性"
                },
                "monitoringAndEvaluation": {
                    "content": "",
                    "description": "監視と評価",
                    "details": {
                        "content": "",
                        "description": "監視と評価の詳細"
                    }
                },
                "recommendation": {
                    "content": "",
                    "description": "推奨"
                },
                "recommendationTypeIntervention": {
                    "content": "",
                    "description": "推奨のタイプ",
                    "options": [
                        {
                            "text": "当該介入に反対する強い推奨",
                            "value": "recommend_against"
                        },
                        {
                            "text": "当該介入に反対する条件付きの推奨",
                            "value": "suggest_against"
                        },
                        {
                            "text": "当該介入または比較対照のいずれかについての条件付きの推奨",
                            "value": "suggest_either"
                        },
                        {
                            "text": "当該介入の条件付きの推奨",
                            "value": "suggest"
                        },
                        {
                            "text": "当該介入の強い推奨",
                            "value": "recommend"
                        }
                    ],
                    "selectedOption": ""
                },
                "recommendationTypeOption": {
                    "content": "",
                    "description": "推奨のタイプ",
                    "options": [
                        {
                            "text": "当該選択肢に反対する強い推奨",
                            "value": "recommend_against"
                        },
                        {
                            "text": "当該選択肢に反対する条件付きの推奨",
                            "value": "suggest_against"
                        },
                        {
                            "text": "当該選択肢または比較対照のいずれかについての条件付きの推奨",
                            "value": "suggest_either"
                        },
                        {
                            "text": "当該選択肢の条件付きの推奨",
                            "value": "suggest"
                        },
                        {
                            "text": "当該選択肢の強い推奨",
                            "value": "recommend"
                        }
                    ],
                    "selectedOption": ""
                },
                "researchPriorities": {
                    "content": "",
                    "description": "研究上の優先事項"
                },
                "restrictions": {
                    "content": "",
                    "description": "制限"
                },
                "subgroupConsiderations": {
                    "content": "",
                    "description": "サブグループに関する検討事項"
                }
            }
        },
        "presentations": {
            "sections": {
                "clinicians": {
                    "name": "臨床医",
                    "sections": {
                        "background": {
                            "name": "背景"
                        },
                        "detailedJustification": {
                            "name": "正当性の詳細"
                        },
                        "justification": {
                            "name": "正当性"
                        },
                        "subgroupConsiderations": {
                            "name": "サブグループに関する検討事項"
                        },
                        "summaryOfFindings": {
                            "name": "Summary of findings"
                        },
                        "implementationConsiderations": {
                             "name": "Implementation considerations"
                        }
                    },
                    "sectionsOrder": []
                },
                "patients": {
                    "name": "患者",
                    "sections": {
                        "justification": {
                            "name": "正当性"
                        },
                        "whatItMeansForYou": {
                            "content": "",
                            "name": "あなたにとっての意義"
                        },
                        "whoIsThisFor": {
                            "content": "",
                            "name": "対象はだれか"
                        }
                    },
                    "sectionsOrder": []
                },
                "policymakers": {
                    "name": "政策決定者",
                    "sections": {
                        "assessment": {
                            "name": "評価"
                        },
                        "background": {
                            "description": "推奨のための詳細な疑問",
                            "details": {
                                "name": "この決断について",
                                "sections": {
                                    "date": {
                                        "content": "",
                                        "description": "日付"
                                    },
                                    "decisionMakers": {
                                        "content": "",
                                        "description": "意思決定者"
                                    },
                                    "perspective": {
                                        "content": "",
                                        "description": "視点"
                                    },
                                    "setting": {
                                        "content": "",
                                        "description": "セッティング"
                                    }
                                },
                                "sectionsOrder": [
                                    "setting",
                                    "perspective",
                                    "decisionMakers",
                                    "date"
                                ]
                            },
                            "name": "背景"
                        },
                        "decision": {
                            "content": "",
                            "name": "決断",
                            "options": [
                                {
                                    "text": "本格的な実施",
                                    "value": "full_implementation"
                                },
                                {
                                    "text": "影響評価",
                                    "value": "impact_evaluation"
                                },
                                {
                                    "text": "予備研究",
                                    "value": "pilot_study"
                                },
                                {
                                    "text": "保留",
                                    "value": "postpone"
                                },
                                {
                                    "text": "実施しない",
                                    "value": "do_not_implement"
                                }
                            ],
                            "selectedOption": ""
                        },
                        "implementation": {
                            "name": "実行"
                        },
                        "justification": {
                            "name": "正当性"
                        },
                        "monitoringAndEvaluation": {
                            "name": "監視と評価"
                        },
                        "recommendation": {
                            "name": "推奨"
                        }
                    },
                    "sectionsOrder": []
                }
            },
            "sectionsOrder": []
        },
        "question": {
            "docId": "",
            "sections": {
                "anticipatedOutcomes": {
                    "content": "",
                    "name": "予期されるアウトカム"
                },
                "background": {
                    "content": "",
                    "name": "背景"
                },
                "comparison": {
                    "content": "",
                    "name": "比較対照"
                },
                "intervention": {
                    "content": "",
                    "name": "介入"
                },
                "linkedTreatments": {
                    "content": "",
                    "name": "関連づけられた治療"
                },
                "mainOutcomes": {
                    "content": "",
                    "name": "主要なアウトカム"
                },
                "option": {
                    "content": "",
                    "name": "選択肢"
                },
                "perspective": {
                    "content": "",
                    "name": "視点"
                },
                "population": {
                    "content": "",
                    "name": "集団"
                },
                "problem": {
                    "content": "",
                    "name": "問題"
                },
                "purpose": {
                    "content": "",
                    "name": "Purpose of the test"
                },
                "role": {
                    "content": "",
                    "name": "Role of the test"
                },
                "setting": {
                    "content": "",
                    "name": "セッティング"
                },
                "subgroups": {
                    "content": "",
                    "name": "サブグループ"
                },
                "coi": {
                    "name": "Conflict of interests",
                    "content": ""
                }
            },
            "sectionsOrder": [],
            "type": ""
        }
    };
};
