var JSONQuestionsByNumber, QUESTION_DEFAULTS, questionsHelper, utils,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

utils = require('base/lib/utils');

JSONQuestionsByNumber = function(qModels) {
  return _.chain(qModels).sortBy(function(q) {
    return q.get('orderNumber');
  }).map(function(q) {
    return q.toJSON();
  }).value();
};

QUESTION_DEFAULTS = {
  intervention: utils.bracketize($.t('es:question.intervention')),
  comparison: utils.bracketize($.t('es:question.comparison')),
  questionFormat: 'FOR_HP',
  indexTest: utils.bracketize($.t('es:question.index_test')),
  comparatorTest: utils.bracketize($.t('es:question.comparator_test')),
  targetCondition: utils.bracketize($.t('es:question.target_condition')),
  healthProblemOrPopulation: utils.bracketize($.t('es:question.healthProblemOrPopulation')),
  comparatorTestPresent: false,
  question: '',
  questionShort: '',
  diagnosticVerb: 'diagnose'
};

questionsHelper = {
  QUESTION_DEFAULTS: QUESTION_DEFAULTS,
  calculateTitles: function(params) {
    var attrs, isVs, question, questionFormat, questionShort, suffix, templateKey, _ref;
    attrs = _.clone(params);
    _ref = attrs.type === 'diagnostic' ? (isVs = attrs.comparatorTestPresent, templateKey = isVs ? 'diagnostic_vs' : 'diagnostic', suffix = attrs.diagnosticVerb === 'screen' ? '_screen' : '', [$.t("es:question.templates." + templateKey + suffix, attrs), $.t("es:question.templates." + templateKey + "_short" + suffix, attrs)]) : attrs.type === 'freeform' ? [attrs.question, attrs.question] : (questionFormat = attrs.questionFormat, [$.t("es:question.templates." + questionFormat, attrs), $.t("es:question.templates." + questionFormat + "_short", attrs)]), question = _ref[0], questionShort = _ref[1];
    return {
      question: question,
      questionShort: questionShort
    };
  },
  getSortedQuestions: function(groupsPromise, questions) {
    return groupsPromise.then((function(_this) {
      return function(groups) {
        return _this._getSortedQuestionsByGroups(groups, questions);
      };
    })(this));
  },
  _getSortedQuestionsByGroups: function(groupsModel, questions) {
    var groups, groupsIds, groupsWithQuestions, mapping, questionIdsInGroup, questionsWithoutGroups;
    mapping = groupsModel.get('mapping');
    if (mapping) {
      groups = _.indexObjectsArray(_(groupsModel.getGroupModels()).pluck('attributes'));
      groupsIds = _(groups).chain().values().pluck('_id').value();
      questionIdsInGroup = [];
      _(mapping).each(function(mappingGroupId, index) {
        if (__indexOf.call(groupsIds, mappingGroupId) >= 0) {
          return questionIdsInGroup.push(index);
        }
      });
      groupsWithQuestions = [];
      _(groups).each(function(group) {
        var groupQuestions;
        groupQuestions = [];
        _(mapping).each(function(mappingGroupId, index) {
          if (questions.get(index) && mappingGroupId === group._id) {
            return groupQuestions.push(questions.get(index));
          }
        });
        if (_.isEmpty(groupQuestions)) {
          return;
        }
        return groupsWithQuestions.push({
          caption: group.caption,
          questions: JSONQuestionsByNumber(groupQuestions)
        });
      });
      questionsWithoutGroups = questions.models.filter(function(q) {
        var _ref;
        return _ref = q.get('_id'), __indexOf.call(questionIdsInGroup, _ref) < 0;
      });
      return {
        groupsWithQuestions: groupsWithQuestions,
        questionsWithoutGroups: JSONQuestionsByNumber(questionsWithoutGroups)
      };
    } else {
      return {
        questionsWithoutGroups: JSONQuestionsByNumber(questions.models)
      };
    }
  }
};

module.exports = questionsHelper;
