EditButton = require 'components/common/edit_button'
Question = require 'components/scope/questions/question'
QuestionEdit = require 'components/scope/questions/question_edit'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

EditableQuestion = createReactClass
  displayName: 'EditableQuestion'

  mixins: [CustomRenderMixin, Translation('')]

  onEdit: ->
    @props.onEdit @props.data.get 'id'

  render: ->
    if @props.isEditing
      <QuestionEdit {...@props} />
    else
      <div>
        <EditButton
          title={@i18n 'actions.edit'}
          onClick={@onEdit}
        />
        <div className='caption'>
          <Question text={@props.data.get 'question'} />
        </div>
      </div>

module.exports = EditableQuestion
