var MdaTopicConvertInitialValueForCodesFromListToMap, MdaTopicDoc, Migration, W, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

MdaTopicDoc = require('lib/db_docs/mda_topic_doc');

Migration = require('./migration');

W = require('when');

migrationName = 'MdaTopicConvertInitialValueForCodesFromListToMap';

module.exports = MdaTopicConvertInitialValueForCodesFromListToMap = (function(_super) {
  __extends(MdaTopicConvertInitialValueForCodesFromListToMap, _super);

  function MdaTopicConvertInitialValueForCodesFromListToMap() {
    MdaTopicConvertInitialValueForCodesFromListToMap.__super__.constructor.call(this, false);
  }

  MdaTopicConvertInitialValueForCodesFromListToMap.prototype.up = function(project, colls) {
    var adapter, projectId;
    MdaTopicConvertInitialValueForCodesFromListToMap.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return MdaTopicDoc.at(projectId).fetchMany()["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
      return {
        rows: []
      };
    }).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(docs) {
      var updatedDocs;
      if (_.isEmpty(docs)) {
        return W.resolve();
      }
      updatedDocs = _.map(docs, function(doc) {
        if (!_.isArray(doc.codes)) {
          return doc;
        }
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName,
          codes: {}
        });
      });
      return adapter.bulkDocs(projectId, _.compact(updatedDocs));
    });
  };

  return MdaTopicConvertInitialValueForCodesFromListToMap;

})(Migration);
