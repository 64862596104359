Translation = require 'components/mixins/translation'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
ReviewedOutcomesList = require 'components/over_review/reviewed_outcomes_list'
SourcesList = require 'components/over_review/sources_list'
{ string, array, object, arrayOf } = PropTypes

OverReviewQuestionWithEdit = createReactClass
  displayName: 'OverReviewQuestionWithEdit'

  propTypes:
    editingSourceId: string
    questionId: string.isRequired
    questionText: string.isRequired
    reviewedOutcomes: array
    sources: object.isRequired
    sourcesOrder: arrayOf(string).isRequired

  mixins: [
    Translation('over_review:module')
  ]

  getInitialState: ->
    isEditing: false

  handleEdit: ->
    @setState isEditing: true

  hnadleSave: ->
    @setState isEditing: false

  getControls: ->
    { isEditing } = @state
    [
      name: if isEditing then 'save' else 'edit'
      handler: if isEditing then @hnadleSave else @handleEdit
    ]

  renderOutcome: (outcome, idx) ->
    <div className='items-list-item' key={outcome['_id']}>
      <span className='item-count'>
        {idx + 1}.
      </span>
      <div className='item-content'>
        {outcome.name}
      </div>
    </div>

  renderSource: (sourceId, idx) ->
    { sourceName, intervention, comparison, population } = @props.sources[sourceId]

    <div className='items-list-item' key={sourceId}>
      <span className='item-count'>
        {idx + 1}.
      </span>
      <div className='item-content'>
        {"#{intervention} vs #{comparison} in #{population}"}
      </div>
    </div>

  render: ->
    { questionId, questionText, reviewedOutcomes, sourcesOrder, sources, editingSourceId } = @props
    <div className='over-review-question'>
      <CollapsibleWithControls controls={@getControls()}>
        <div>{questionText}</div>
        <div>
          {if @state.isEditing
            <div>
              <ReviewedOutcomesList
                questionId={questionId}
                reviewedOutcomes={reviewedOutcomes}
              />
              <br />
              <SourcesList
                questionId={questionId}
                sources={sources}
                sourcesOrder={sourcesOrder}
                editingSourceId={editingSourceId}
              />
            </div>
          else
            <div>
              <div className='reviewed-outcomes'>
                <div className='title'>
                  {@i18n '../outcomes'}
                </div>
                <div className='items-list'>
                  {reviewedOutcomes.map @renderOutcome}
                </div>
              </div>
              <div className='reviewed-sources'>
                <div className='title'>
                  {@i18n '../tabs.sources'}
                </div>
                <div className='items-list'>
                  {sourcesOrder.map @renderSource}
                </div>
              </div>
            </div>
          }
        </div>
      </CollapsibleWithControls>
    </div>

module.exports = OverReviewQuestionWithEdit
