var AGE, BOOLEAN_OPTIONS, CERTAINTIES_OF_EVIDENCE, COEXISTING_CONDITIONS, FIELDS, GRADING_OPTIONS, INTENDED_POPULATIONS, MODULES, RECOMMENDATION_STRENGTHS, SITE_OF_DISEASE, SOURCE, SUBMODULES, SUBMODULES_TO_MODULES_MAP, TB_ASTERIXED_FIELDS, TB_GENERAL_INFORMATION_FORM_ITEMS, TB_RECOMMENDATION_FORM_ITEMS, VERSION_OPTIONS,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

BOOLEAN_OPTIONS = [
  {
    value: true,
    textKey: 'yes'
  }, {
    value: false,
    textKey: 'no'
  }
];

GRADING_OPTIONS = [
  {
    value: 'notGraded',
    textKey: 'notGraded'
  }, {
    value: 'grade',
    textKey: 'grade'
  }
];

VERSION_OPTIONS = [
  {
    value: 'prePrint',
    textKey: 'draft'
  }, {
    value: 'final',
    textKey: 'final'
  }
];

AGE = _.map(["any", "neonate", "infant", "child", "adolescent", "adult"], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.age." + value)
  };
});

FIELDS = _.map(['clinicalPractice', 'hta', 'publicHealth', 'healthPolicyAndSystems'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.field." + value)
  };
});

SOURCE = _.map(['gtb', 'hiv', 'mentalHealthAndSubstanceAbuse', 'nutritionForHealthAndDevelopment'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.source." + value)
  };
});

SUBMODULES_TO_MODULES_MAP = {
  prevention: ['infectionControl', 'tbPreventiveTreatment'],
  treatment: ['drugSusceptible', 'drugResistant']
};

MODULES = _.map(['prevention', 'screening', 'diagnosis', 'treatment', 'specialNeedsAndPopulations', 'tuberculosisAndComorbidities'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.recommendationModule." + value)
  };
});

SUBMODULES = _.map(['infectionControl', 'tbPreventiveTreatment', 'drugSusceptible', 'drugResistant'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.recommendationSubmodule." + value)
  };
});

TB_GENERAL_INFORMATION_FORM_ITEMS = {
  main: [
    {
      fieldKey: 'guidelineNoId',
      type: 'text'
    }, {
      fieldKey: 'title',
      type: 'text'
    }, {
      fieldKey: 'fullReference',
      type: 'text'
    }, {
      fieldKey: 'publicationDate',
      type: 'date'
    }, {
      fieldKey: 'version',
      type: 'radioOptions',
      options: VERSION_OPTIONS,
      placeholderFromI18n: true
    }, {
      fieldKey: 'source',
      type: 'select',
      options: SOURCE
    }, {
      fieldKey: 'sourceDocumentLink',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'kspLink',
      type: 'text'
    }, {
      fieldKey: 'isbn',
      type: 'text'
    }, {
      fieldKey: 'doi',
      type: 'text'
    }, {
      fieldKey: 'pmid',
      type: 'text'
    }, {
      fieldKey: 'guidelineGroupDetails',
      type: 'switchableText',
      options: BOOLEAN_OPTIONS,
      placeholderFromI18n: true
    }, {
      fieldKey: 'declarationOfInterest',
      type: 'radioOptions',
      options: BOOLEAN_OPTIONS,
      helperTextFromI18n: true
    }, {
      fieldKey: 'latestLiteratureSearchDate',
      type: 'date'
    }, {
      fieldKey: 'gradingEvidenceMethod',
      type: 'radioOptions',
      options: GRADING_OPTIONS,
      placeholderFromI18n: true,
      withOther: true
    }
  ],
  progress: [
    {
      fieldKey: 'intent',
      type: 'select',
      options: MODULES
    }, {
      fieldKey: 'subIntent',
      type: 'select',
      filteredOptions: function(parentValue) {
        return _.filter(SUBMODULES, function(submodule) {
          var _ref;
          return _ref = submodule.value, __indexOf.call(SUBMODULES_TO_MODULES_MAP[parentValue] || [], _ref) >= 0;
        });
      }
    }, {
      fieldKey: 'targetAudience',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'field',
      type: 'multiSelect',
      options: FIELDS
    }
  ]
};

INTENDED_POPULATIONS = _.map(['healthcareWorkers', 'publicHealthOrganizations', 'hospitals', 'educationalInstitutions', 'patientsOrPublic'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.intendedPopulation." + value)
  };
});

COEXISTING_CONDITIONS = _.map(['hiv', 'diabetes', 'renalFailure', 'severeMentalDisorder'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.coexistingCondition." + value)
  };
});

SITE_OF_DISEASE = _.map(['extrapulmonary', 'pulmonary'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.siteOfDisease." + value)
  };
});

CERTAINTIES_OF_EVIDENCE = _.map(['very_low', 'very_low_to_low', 'low', 'low_to_moderate', 'moderate', 'moderate_to_high', 'high', 'other', 'missing'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.certainties." + value)
  };
});

RECOMMENDATION_STRENGTHS = _.map(['recommend_against', 'suggest_against', 'suggest_either', 'suggest', 'recommend', 'other', 'missing'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.strengthOfRecommendation." + value)
  };
});

TB_RECOMMENDATION_FORM_ITEMS = {
  main: [
    {
      fieldKey: 'age',
      type: 'multiSelect',
      options: AGE
    }, {
      fieldKey: "uniqueRecommendationCode",
      type: "text",
      placeholderFromI18n: true
    }, {
      fieldKey: 'recommendationIntent',
      type: 'multiSelect',
      options: MODULES
    }, {
      fieldKey: 'coexistingCondition',
      type: 'select',
      options: COEXISTING_CONDITIONS
    }, {
      fieldKey: 'intendedPopulation',
      type: 'multiSelect',
      options: INTENDED_POPULATIONS
    }, {
      fieldKey: 'siteOfDisease',
      type: 'multiSelect',
      options: SITE_OF_DISEASE
    }, {
      fieldKey: 'evidenceTableUrl',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'evidenceProfilePagesRangeGuideline',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'evidenceProfilePagesRangePDF',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'evidenceToDecisionUrl',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'evidenceToDecisionPagesRangeGuideline',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'evidenceToDecisionPagesRangePDF',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'emlUrl',
      type: 'multiText',
      placeholderFromI18n: true,
      addValueLabel: $.t('actions.add_link')
    }, {
      fieldKey: 'additionalEvidenceTableUrl',
      type: 'multiText',
      placeholderFromI18n: true,
      addValueLabel: $.t('actions.add_link')
    }
  ]
};

TB_ASTERIXED_FIELDS = ['intent', 'subIntent', 'recommendationIntent'];

module.exports = {
  TB_ASTERIXED_FIELDS: TB_ASTERIXED_FIELDS,
  TB_GENERAL_INFORMATION_FORM_ITEMS: TB_GENERAL_INFORMATION_FORM_ITEMS,
  TB_RECOMMENDATION_FORM_ITEMS: TB_RECOMMENDATION_FORM_ITEMS,
  SUBMODULES_TO_MODULES_MAP: SUBMODULES_TO_MODULES_MAP
};
