var W, etdTemplateSections, mediator, utils,
  __slice = [].slice;

mediator = require('mediator');

W = require('when/when');

utils = require('base/lib/utils');

etdTemplateSections = require('lib/etd_templates/templates_sections');

module.exports = function(templateLanguages) {
  var languageSections;
  languageSections = _.chain(templateLanguages.sections).map(function(_arg) {
    var id, sections;
    id = _arg.id, sections = _arg.sections;
    return [id, require(sections)];
  }).object().value();
  return {
    _getLanguage: function(template) {
      return languageSections[template.getIn(['templateDef', 'lang'], templateLanguages.defaultLanguage)]();
    },
    _getSection: function(template, templateData, blockName, sectionId) {
      var defaultValue, isRating, parentSections, perIntervention, perOutcomes, section, sections, templateId, withRating, _ref, _ref1;
      templateId = template.getIn(['templateDef', 'id']);
      withRating = templateId.indexOf('overarching') === 0 || templateId === 'qualityIndicators';
      defaultValue = Immutable.fromJS(this._getLanguage(template)[blockName]['sections'][sectionId]);
      sections = template.getIn(['templateDef', blockName]);
      section = (_ref = sections.find(function(s) {
        return s.get('id') === sectionId;
      })) != null ? _ref : Immutable.Map();
      isRating = section.get('rating', false);
      perIntervention = section.get('perIntervention', false);
      perOutcomes = section.get('perOutcomes', false);
      parentSections = section.get('parentSections', Immutable.List());
      if (isRating) {
        defaultValue = defaultValue.set('rating', true);
      }
      if (perIntervention) {
        defaultValue = defaultValue.set('perIntervention', true);
      }
      if (perOutcomes) {
        defaultValue = defaultValue.set('perOutcomes', true);
      }
      if (!parentSections.isEmpty()) {
        defaultValue = defaultValue.set('parentSections', parentSections);
      }
      return (_ref1 = templateData.getIn([blockName, 'sections', sectionId])) != null ? _ref1 : defaultValue;
    },
    _getAssessmentRef: function(template, templateData, refName, refId) {
      var _ref;
      return (_ref = templateData.getIn(['assessment', refName, refId])) != null ? _ref : Immutable.fromJS(this._getLanguage(template)['assessment'][refName][refId]);
    },
    prepareTemplate: function(templateDef, templateData, forCustomization, preserveCurrentSections, blocks) {
      var template;
      if (forCustomization == null) {
        forCustomization = false;
      }
      template = Immutable.Map({
        templateDef: templateDef,
        templateData: Immutable.Map()
      });
      return template.withMutations((function(_this) {
        return function(template) {
          return _(blocks).forEach(function(blockName) {
            return _this._addSections(template, templateData, blockName, forCustomization, preserveCurrentSections);
          });
        };
      })(this));
    },
    updateTemplate: function(currentTemplate, newTemplate, blocks) {
      var additionalSectionPath, criteriaPath, template, tpl;
      tpl = this.prepareTemplate(newTemplate.get('templateDef'), currentTemplate.get('templateData'), false, true, blocks);
      template = tpl.mergeDeepWith((function(prev, next) {
        if (next) {
          return next;
        } else {
          return prev;
        }
      }), newTemplate);
      additionalSectionPath = ['templateData', 'assessment', 'criteria', 'additionalSection'];
      criteriaPath = ['templateData', 'assessment', 'criteria'];
      template = !template.getIn(criteriaPath, Immutable.Map()).isEmpty() ? template.updateIn(criteriaPath, function(criteria) {
        return criteria.map(function(criterion, criterionId) {
          var path;
          path = __slice.call(criteriaPath).concat([criterionId]);
          return criterion.withMutations(function(c) {
            if (newTemplate.getIn(__slice.call(path).concat(['options']))) {
              c.set('options', newTemplate.getIn(__slice.call(path).concat(['options'])));
            }
            if (newTemplate.getIn(__slice.call(path).concat(['additionalOptions']))) {
              return c.set('additionalOptions', newTemplate.getIn(__slice.call(path).concat(['additionalOptions'])));
            }
          });
        });
      }) : template;
      return template.withMutations(function(template) {
        return template.set('_id', currentTemplate.get('_id')).set('_rev', currentTemplate.get('_rev')).set('_attachments', currentTemplate.get('_attachments')).setIn(['templateData', 'question', 'docId'], currentTemplate.getIn(['templateData', 'question', 'docId'])).setIn(__slice.call(additionalSectionPath).concat(['options']), newTemplate.getIn(__slice.call(additionalSectionPath).concat(['options']))).setIn(__slice.call(additionalSectionPath).concat(['additionalOptions']), newTemplate.getIn(__slice.call(additionalSectionPath).concat(['additionalOptions'])));
      });
    },
    _getSectionIds: function(includeCustomization) {
      return function(forCustomization, sections) {
        return sections.filter(function(sectionObj) {
          if (includeCustomization) {
            return forCustomization || (sectionObj.get('required') || sectionObj.get('checked'));
          } else {
            return sectionObj.get('required') || sectionObj.get('checked');
          }
        }).map(function(sectionObj) {
          return sectionObj.get('id');
        });
      };
    },
    _addSections: function(template, templateData, blockName, forCustomization, preserveCurrentSections) {
      var onlyCheckedOrRequiredSectionsIds, sectionIds, sections;
      sections = template.getIn(['templateDef', blockName]);
      sectionIds = this._getSectionIds(true)(forCustomization, sections);
      onlyCheckedOrRequiredSectionsIds = this._getSectionIds(false)(forCustomization, sections);
      template = template.setIn(['templateData', blockName, 'sectionsOrder'], onlyCheckedOrRequiredSectionsIds);
      sectionIds = preserveCurrentSections ? templateData.getIn([blockName, 'sections'], Immutable.List()).keySeq().concat(sectionIds).toOrderedSet() : sectionIds;
      return template.withMutations((function(_this) {
        return function(template) {
          return sectionIds.forEach(function(sectionId) {
            return _this._addSection(template, templateData, blockName, sectionId);
          });
        };
      })(this));
    },
    _addSection: function(template, templateData, blockName, sectionId) {
      template = template.setIn(['templateData', blockName, 'sections', sectionId], this._getSection(template, templateData, blockName, sectionId));
      switch (blockName) {
        case 'assessment':
          return this._addAssessmentReferences(template, templateData, sectionId);
        case 'presentations':
          return this._addPresentationsSections(template, templateData, sectionId);
        default:
          return template;
      }
    },
    _addAssessmentReferences: function(template, templateData, sectionId) {
      return template.withMutations((function(_this) {
        return function(template) {
          _this._addAssessmentReference(template, templateData, sectionId, 'criteria', 'criterionId');
          _this._addAssessmentReference(template, templateData, sectionId, 'researchEvidences', 'researchEvidenceId');
          return _this._addAssessmentReference(template, templateData, sectionId, 'additionalConsiderations', 'additionalConsiderationId');
        };
      })(this));
    },
    _addAssessmentReference: function(template, templateData, sectionId, refName, refIdAttr) {
      var ref, refId, section;
      section = template.getIn(['templateData', 'assessment', 'sections', sectionId]);
      if (!section) {
        section = Immutable.fromJS(etdTemplateSections().assessment.sections[sectionId] || {});
      }
      refId = section.get(refIdAttr);
      ref = template.getIn(['templateData', 'assessment', refName, refId]);
      if (ref) {
        return template;
      } else {
        return template.withMutations((function(_this) {
          return function(template) {
            return template.setIn(['templateData', 'assessment', refName, refId], _this._getAssessmentRef(template, templateData, refName, refId));
          };
        })(this));
      }
    },
    _addPresentationsSections: function(template, templateData, sectionId) {
      var sectionIds;
      sectionIds = template.getIn(['templateDef', 'presentations'], Immutable.List());
      if (!sectionIds.isEmpty()) {
        sectionIds = sectionIds.find(function(presentationGroup) {
          return presentationGroup.get('id') === sectionId;
        }).get('sections').filter(function(sectionObj) {
          return sectionObj.get('required') || sectionObj.get('checked');
        }).map(function(sectionObj) {
          return sectionObj.get('id');
        });
      }
      return template.setIn(['templateData', 'presentations', 'sections', sectionId, 'sectionsOrder'], sectionIds);
    }
  };
};
