var ReactComponent, RevManStatus, RevManStatusView, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ReactComponent = require('base/lib/traits/react_component');

RevManStatus = require('components/archie/revman_status');

View = require('base/views/view');

RevManStatusView = (function(_super) {
  __extends(RevManStatusView, _super);

  function RevManStatusView() {
    return RevManStatusView.__super__.constructor.apply(this, arguments);
  }

  RevManStatusView.prototype.container = '.revman-web-status';

  RevManStatusView.prototype.autoRender = true;

  RevManStatusView.prototype.initialize = function() {
    RevManStatusView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  RevManStatusView.prototype.afterRender = function() {
    RevManStatusView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(RevManStatus, {
      onClick: this.options.onClick
    });
  };

  RevManStatusView.prototype.dispose = function() {
    this.unmountComponent();
    return RevManStatusView.__super__.dispose.apply(this, arguments);
  };

  return RevManStatusView;

})(View);

module.exports = RevManStatusView;
