var COMMENTS_TO_STEP_MAP, QUESTION_GENERATION_STEPS, helper, questionsGenHelper, updateQuestionsWithApprovalData, updateQuestionsWithRatingData, _ref;

_ref = require('lib/questions_helper'), QUESTION_GENERATION_STEPS = _ref.QUESTION_GENERATION_STEPS, updateQuestionsWithRatingData = _ref.updateQuestionsWithRatingData, updateQuestionsWithApprovalData = _ref.updateQuestionsWithApprovalData;

COMMENTS_TO_STEP_MAP = {
  'questions.brainstorming': 'questions.brainstorming',
  'questions.moderation': 'questions.brainstorming',
  'questions.proposal': 'questions.rating',
  'questions.approval': 'questions.approval'
};

questionsGenHelper = helper = {
  getNextStep: function(currentStep) {
    var currentIndex;
    if (currentStep === _.last(QUESTION_GENERATION_STEPS)) {
      return null;
    }
    currentIndex = QUESTION_GENERATION_STEPS.indexOf(currentStep);
    if (currentIndex === -1) {
      return null;
    }
    return QUESTION_GENERATION_STEPS[currentIndex + 1];
  },
  getStepQuestionsPath: function(stepName) {
    return ['questionsGenerationData', 'steps', stepName, 'questions'];
  },
  getStepQuestionsWithComments: function(state, stepName) {
    var commentsStepName, stepQuestions;
    stepQuestions = state.getIn(helper.getStepQuestionsPath(stepName), Immutable.List());
    if (stepQuestions.isEmpty()) {
      return Immutable.List();
    }
    commentsStepName = COMMENTS_TO_STEP_MAP[stepName];
    return stepQuestions.filterNot(function(question) {
      return question.getIn(['memberComments', commentsStepName], Immutable.Map()).isEmpty();
    });
  },
  updateWithRatingData: function(state, memberRatings) {
    var updatePath;
    updatePath = helper.getStepQuestionsPath(QUESTION_GENERATION_STEPS[3]);
    return state.updateIn(updatePath, function(questions) {
      return updateQuestionsWithRatingData(questions, memberRatings);
    });
  },
  updateWithApprovalData: function(state, memberApprovals) {
    var updatePath;
    updatePath = helper.getStepQuestionsPath(QUESTION_GENERATION_STEPS[5]);
    return state.updateIn(updatePath, function(questions) {
      return updateQuestionsWithApprovalData(questions, memberApprovals);
    });
  },
  recordSubmittedMemberData: function(state, stepName, membersData) {
    var submittedMemberIds, updatePath;
    switch (stepName) {
      case QUESTION_GENERATION_STEPS[3]:
      case QUESTION_GENERATION_STEPS[5]:
        submittedMemberIds = _.pluck(membersData, 'memberId');
        updatePath = ['questionsGenerationData', 'steps', stepName, 'submittedMemberIds'];
        return state.setIn(updatePath, Immutable.fromJS(submittedMemberIds));
    }
  },
  createApprovalQuestionGroupsFromRating: function(state) {
    var approvalQuestionGroups, ratingDataQuestionGroups, ratingQuestions, updatePath;
    updatePath = ['questionsGenerationData', 'steps', QUESTION_GENERATION_STEPS[5], 'questionGroups'];
    ratingQuestions = state.getIn(['questionsGenerationData', 'steps', QUESTION_GENERATION_STEPS[4], 'questions']);
    approvalQuestionGroups = Immutable.Map({
      accepted: Immutable.List(),
      mentioned: Immutable.List(),
      rejected: Immutable.List()
    });
    ratingDataQuestionGroups = ratingQuestions.groupBy(function(q) {
      return q.get('approvalStatus');
    }).map(function(questions, approvalStatus) {
      return questions.map(function(q) {
        return q.get('id');
      });
    });
    return state.setIn(updatePath, approvalQuestionGroups.merge(ratingDataQuestionGroups));
  },
  fetchResultsSuccessBrainstorming: function(state, data) {
    var stepName, updatePath;
    stepName = QUESTION_GENERATION_STEPS[1];
    updatePath = helper.getStepQuestionsPath(stepName);
    return state.updateIn(updatePath, Immutable.List(), function(questions) {
      return questions.mergeDeep(Immutable.fromJS(data));
    });
  },
  fetchResultsSuccessRating: function(state, data) {
    var stepName, submittedMemberIds, updatePath;
    stepName = QUESTION_GENERATION_STEPS[3];
    updatePath = ['questionsGenerationData', 'steps', stepName, 'submittedMemberIds'];
    submittedMemberIds = Immutable.List(_.keys(data));
    return state.setIn(updatePath, submittedMemberIds);
  },
  fetchResultsSuccessApproval: function(state, data) {
    var stepName;
    stepName = QUESTION_GENERATION_STEPS[5];
    state = helper.recordSubmittedMemberData(state, stepName, data);
    return helper.updateWithApprovalData(state, data);
  }
};

module.exports = questionsGenHelper;
