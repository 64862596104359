Label = require './label'
CheckboxInput = require './checkbox_input'
Translation = require '../mixins/translation'
EtdStore = require 'stores/etd_store'
EtdActions = require 'actions/etd_actions'

mediator = require 'mediator'

OutcomesSelect = createReactClass

  displayName: "OutcomesSelect"

  mixins: [
    Translation('projects:insert_dialog')
  ]

  propTypes:
    outcomes: PropTypes.object.isRequired

  getInitialState: ->
    outcomesInsertSize: 7
    exceedInsertSize: false
    selectedOutcomes: @props.selectedOutcomes

  onOptionSelect: (e) ->
    selectedArray = @state.selectedOutcomes
    selectedArray = if e.target.checked
      selectedArray.push e.target.value
    else
      index = selectedArray.findIndex (value) ->
        value is e.target.value
      selectedArray.delete index unless index is -1

    if selectedArray.size <= @state.outcomesInsertSize
      @_updateSelectedOutcomes selectedArray
    else
      @outcomesInsertSizeExceeded (confirm) =>
        @_updateSelectedOutcomes selectedArray if confirm
        @setState exceedInsertSize: confirm

  _updateSelectedOutcomes: (selectedOutcomes) ->
    @setState selectedOutcomes: selectedOutcomes
    _.defer =>
      @_toggleSelectAllCheckbox()
    @props.updateSelectedOutcomes? selectedOutcomes

  componentDidMount: ->
    if @props.outcomes.size <= @state.outcomesInsertSize and @props.selectedOutcomes.size is 0
      @_addAllOutcomes()
    _.defer =>
      @_toggleSelectAllCheckbox()

  _toggleSelectAllCheckbox: ->
    ReactDOM.findDOMNode(@refs.selectAll)?.checked =
      @state.selectedOutcomes.size is @props.outcomes.size

  selectAll: (e) ->
    if e.target.checked
      if @props.outcomes.size > @state.outcomesInsertSize
        @outcomesInsertSizeExceeded (confirm) =>
          @_addAllOutcomes() if confirm
          @setState exceedInsertSize: true
          ReactDOM.findDOMNode(@refs.selectAll)?.checked = confirm
      else
        @_addAllOutcomes()
    else
      selectedArray = @props.outcomes.clear()
      @setState selectedOutcomes: selectedArray
      @props.updateSelectedOutcomes? selectedArray

  _addAllOutcomes: ->
    selectedArray = @props.outcomes.map((outcome) -> outcome.get('_id'))
    @setState selectedOutcomes: selectedArray
    @props.updateSelectedOutcomes? selectedArray

  outcomesInsertSizeExceeded: (cb) ->
    return cb(true) if @state.exceedInsertSize
    message = @i18n 'outcomes_insert_size_exceeded',
      outcomesInsertSize: @state.outcomesInsertSize
    mediator.dialogs.confirm (
      yesClass : 'danger'
      message: message
    ), cb

  render: ->
    <div className="outcomes-select">
      { if @props.outcomes.size is 0
        <div className="warning-message">
          {@i18n 'no_critical_or_important_outcomes'}
        </div>
      else
        <div>
          <div className="select-all">
            <Label htmlFor="select-all">
              <CheckboxInput id='select-all' ref="selectAll" value='select-all'
                onChange={@selectAll}/>
              {@i18n 'select_all'}
            </Label>
          </div>
          <div className="separator" />
          {@props.outcomes.map (outcome, outcomeIdx) =>
            checked = @state.selectedOutcomes.contains outcome.get('_id')
            classess = classNames 'select-item', 'selected': checked
            <div key={outcomeIdx} className={classess}>
              <Label htmlFor={outcomeIdx}>
                <CheckboxInput id={outcomeIdx}
                  name={outcomeIdx}
                  value={outcome.get('_id')}
                  checked={checked}
                  onChange={@onOptionSelect}/>
                <span className="outcome-name">
                  {outcome.get('name')}
                  { if outcome.get('importanceLabel')
                    " (#{outcome.get('importanceLabel')})"
                  }
                </span>
              </Label>
            </div>
          }
        </div>
      }
    </div>

module.exports = OutcomesSelect
