var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  "
    + escapeExpression(((helpers.riskNotEditableCell || (depth0 && depth0.riskNotEditableCell) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.colNo : depth0), "{\"className\": \"risk-with-control\",\n    \"dataProperty\": \"riskWithControl\"}", {"name":"riskNotEditableCell","hash":{},"data":data})))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "NOT_MEASURED, NOT_REPORTED, NOT_POOLED", (depth0 != null ? depth0.metaType : depth0), {"name":"inStringArray","hash":{},"fn":this.program(4, data),"inverse":this.program(6, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.riskNotEditableCell || (depth0 && depth0.riskNotEditableCell) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.colNo : depth0), "{\"className\": \"risk-with-control\",\n      \"dataProperty\": \"riskWithControl\"}", {"name":"riskNotEditableCell","hash":{},"data":data})))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.notEditable : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      "
    + escapeExpression(((helpers.controlNotEditableCell || (depth0 && depth0.controlNotEditableCell) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.colNo : depth0), "{\"className\": \"control\", \"dataProperty\": \"control\",\n      \"messageKey\": \"-\"}", {"name":"controlNotEditableCell","hash":{},"data":data})))
    + "\n";
},"9":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.effectMeasure : depth0), "SMD", {"name":"is","hash":{},"fn":this.program(10, data),"inverse":this.program(12, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          "
    + escapeExpression(((helpers.riskNotEditableCell || (depth0 && depth0.riskNotEditableCell) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.colNo : depth0), "{\"className\": \"risk-with-control\",\n    \"dataProperty\": \"riskWithControl\", \"messageKey\": \"-\"}", {"name":"riskNotEditableCell","hash":{},"data":data})))
    + "\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "          <td "
    + escapeExpression(((helper = (helper = helpers.colNo || (depth0 != null ? depth0.colNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"colNo","hash":{},"data":data}) : helper)))
    + " data-property=\"riskWithControl\" class=\"risk-with-control\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"is","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "dich, time_to_event", (depth0 != null ? depth0.type : depth0), {"name":"inStringArray","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          </td>\n";
},"13":function(depth0,helpers,partials,data) {
  return "              <div class=\"inner-cell\">\n                <div class=\"cell-content main-value\">\n                  <span></span>\n                </div>\n              </div>\n";
  },"15":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "              <div class=\"inner-cell dich\">\n                <div class=\"cell-content "
    + escapeExpression(((helper = (helper = helpers.preFix || (depth0 != null ? depth0.preFix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"preFix","hash":{},"data":data}) : helper)))
    + "-value\">\n                  <span></span>\n                </div>\n              </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "case_series, case_reports", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.value : stack1), {"name":"inStringArray","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}