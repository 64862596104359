Question = require 'components/panel_voice/question'
{ instanceOf } = PropTypes

QuestionGroup = createReactClass
  displayName: 'PanelVoiceQuestionGroup'

  propTypes:
    groupData: instanceOf(Immutable.Map).isRequired

  render: ->
    { groupData } = @props
    groupCaption = groupData.get 'caption'
    groupQuestions = groupData.get 'questions'

    <div className='questions-group'>
      <div className='group-title'>
        <span className='group-caption'>{groupCaption}</span>
      </div>
      {groupQuestions.map (q) ->
        <Question data={q} key={q.get '_id'} />
      .toList()}
    </div>

module.exports = QuestionGroup
