var Checklist, ChecklistActions, ChecklistView, ReactComponent, View, alt, checklistDocId, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

ChecklistActions = require('actions/checklist_actions');

Checklist = require('components/checklist/checklist');

checklistDocId = require('lib/doc_ids').PROJECT_CHECKLIST;

module.exports = ChecklistView = (function(_super) {
  __extends(ChecklistView, _super);

  function ChecklistView() {
    return ChecklistView.__super__.constructor.apply(this, arguments);
  }

  ChecklistView.prototype.container = '#page-container';

  ChecklistView.prototype.autoRender = true;

  ChecklistView.prototype.initialize = function() {
    ChecklistView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  ChecklistView.prototype.afterRender = function() {
    var isMdgTablesSwitchOn, type;
    ChecklistView.__super__.afterRender.apply(this, arguments);
    isMdgTablesSwitchOn = mediator.services.switches.isOn('mdgTables');
    type = isMdgTablesSwitchOn ? 'mdg' : 'regular';
    return this.renderComponent(Checklist, ChecklistActions, checklistDocId, {
      fetchAndListen: {
        dbId: mediator.project.id
      },
      fetchParams: {
        type: type
      },
      props: {
        type: type
      }
    });
  };

  ChecklistView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('ChecklistStore');
    return ChecklistView.__super__.dispose.apply(this, arguments);
  };

  return ChecklistView;

})(View);
