{ func, instanceOf, bool, string } = PropTypes
ItemWithCheckbox = require 'components/common/item_with_checkbox'
EllipsizedText = require 'components/common/ellipsized_text'
Tooltip = require 'components/common/tooltip'
{ isOverarching } = require 'lib/questions_helper'

QuestionWithCheckbox = createReactClass

  displayName: "QuestionWithCheckbox"

  propTypes:
    blockedQuestionTooltip: string
    checked: bool
    onSelectQuestion: func.isRequired
    readonly: bool
    question: instanceOf(Immutable.Map).isRequired
    questionField: string
    withNumbers: bool

  getDefaultProps: ->
    blockedQuestionTooltip: null
    checked: false
    readonly: false
    questionField: 'question'
    withNumbers: false

  onSelectQuestion: ->
    @props.onSelectQuestion @props.question.get("_id") unless @props.readonly

  render: ->
    { blockedQuestionTooltip, question, questionField, readonly, checked, withNumbers } = @props

    questionTitle = question.get(questionField)

    <Tooltip>
      <div title={if readonly then blockedQuestionTooltip else undefined}>
        <ItemWithCheckbox
          readonly={readonly}
          onChange={@onSelectQuestion}
          checked={checked}
        >
          <div className={classNames(
            'question-row',
            'with-numbers': withNumbers,
            'readOnly': readonly
            'overarching-question': isOverarching(question.get('type'))
          )}>
            <EllipsizedText
              text={questionTitle}
              height={40}
              onClick={@onSelectQuestion}
              style={display: "table-cell", verticalAlign: "middle"}
            />
          </div>
        </ItemWithCheckbox>
      </div>
    </Tooltip>

module.exports = QuestionWithCheckbox
