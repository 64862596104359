VotingActions = require 'actions/voting_actions'
VotingStore = require 'stores/voting_store'
TabsPane = require 'components/common/tabs_pane'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
CreateStoreMixin = require 'components/mixins/create_store'

VOTING_TABS = [
  {tabName: 'phase1a', additionalText: 'unsent'}
  {tabName: 'phase1b', additionalText: 'ongoing'}
  {tabName: 'phase2a', additionalText: 'unsent'}
  {tabName: 'phase2b', additionalText: 'ongoing'}
  {tabName: 'finished'}
]

VotingModuleToolbar = createReactClass
  displayName: 'VotingModuleToolbar'

  mixins: [
    CustomRenderMixin
    CreateStoreMixin(VotingStore)
    Translation('voting:voting')
  ]

  getStateFromStores: ->
    activeTab: VotingStore.getActiveTab()
    notVotedQuestions: VotingStore.getNotVotedQuestions()
    etdsWithResultsPerPhase: VotingStore.getEtdsWithResultsPerPhase()

  _getVotingTabCount: (tabName) ->
    return @state.notVotedQuestions.size if tabName is 'phase1a'
    @state.etdsWithResultsPerPhase.get(tabName).size

  _getTabsList: ->
    VOTING_TABS.map (tabDetails) =>
      {tabName, additionalText} = tabDetails
      count = @_getVotingTabCount tabName
      # first tab must always be enabled. All other tabs - depending on whether there are any
      # questions being voted (count isnt 0)
      isDisabled = if tabDetails.tabName is 'phase1a' then false else count is 0
      if additionalText
        tabLabel = @i18n "tabs.#{tabName}"
        additionalLabel = @i18n("tabs.#{additionalText}") + " (#{count})"
      else
        tabLabel = @i18n("tabs.#{tabName}") + " (#{count})"
        additionalLabel = null
      { tabName, tabLabel, additionalLabel, isDisabled }

  render: ->
    <div className="voting-toolbar">
      <TabsPane
        activeTab={@state.activeTab}
        tabs={@_getTabsList()}
        onTabClick={VotingActions.changeTab}
      />
    </div>

module.exports = VotingModuleToolbar
