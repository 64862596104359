var AdolopmentDataDocShape, EtdData, boolean, docShape, mapOf, oneOf, optional, shape, string, _ref;

docShape = require('lib/db_docs/field_types/doc_shape');

shape = require('lib/db_docs/field_types/shape');

mapOf = require('lib/db_docs/field_types/map_of');

oneOf = require('lib/db_docs/field_types/one_of');

_ref = require('lib/db_docs/field_types/built_in_types'), string = _ref.string, boolean = _ref.boolean;

optional = shape.typeDecorators.optional;

EtdData = shape({
  assessment: optional(mapOf(shape({
    withAdolopment: boolean
  }))),
  conclusions: optional(mapOf(shape({
    withAdolopment: boolean
  })))
});

AdolopmentDataDocShape = docShape({
  etdsData: mapOf(EtdData)
});

module.exports = AdolopmentDataDocShape;
