var IsofEmbeddableLinkDialog, ModalView, W, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

template = require('views/templates/isof_emeddable_link_dialog');

W = require('when');

IsofEmbeddableLinkDialog = (function(_super) {
  __extends(IsofEmbeddableLinkDialog, _super);

  function IsofEmbeddableLinkDialog() {
    return IsofEmbeddableLinkDialog.__super__.constructor.apply(this, arguments);
  }

  IsofEmbeddableLinkDialog.prototype.id = 'isof-export-dialog';

  IsofEmbeddableLinkDialog.prototype.template = template;

  IsofEmbeddableLinkDialog.prototype.shortcuts = {
    'esc': 'dispose'
  };

  IsofEmbeddableLinkDialog.prototype.initialize = function() {
    IsofEmbeddableLinkDialog.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.copy-text', this.copyText);
    return this._deferred = W.defer();
  };

  IsofEmbeddableLinkDialog.prototype.copyText = function() {
    this.$el.find('textarea').select();
    return document.execCommand('copy');
  };

  IsofEmbeddableLinkDialog.prototype.getTemplateData = function() {
    return {
      link: this.options.link
    };
  };

  IsofEmbeddableLinkDialog.prototype.promise = function() {
    return this._deferred.promise;
  };

  IsofEmbeddableLinkDialog.prototype.dispose = function() {
    IsofEmbeddableLinkDialog.__super__.dispose.apply(this, arguments);
    return this._deferred.resolve();
  };

  return IsofEmbeddableLinkDialog;

})(ModalView);

module.exports = IsofEmbeddableLinkDialog;
