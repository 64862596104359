QuestionGroupIcons = require './question_group_icons'
ComparisonsSelection = require './comparisons_selection'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
MultiComparisonsTitle = require './multi_comparisons_title'
MultiComparisonsQuestionsList = require './multi_comparisons_questions_list'

OverarchingQuestion = createReactClass
  displayName: 'OverarchingQuestion'

  propTypes:
    questions: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.OrderedMap),
      PropTypes.instanceOf(Immutable.Map)]
    ).isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired,
    comparisonsEditData: PropTypes.instanceOf(Immutable.Map).isRequired
    editing: PropTypes.bool
    toggleExpand: PropTypes.func
    onSave: PropTypes.func.isRequired
    onEdit: PropTypes.func.isRequired,
    # onDelete is not required because delete icon shouldn't be displayed in QuestionGroupIcons
    # on multicomparisons summary review view, and QuestionGroupIcons component
    # only checks if function is provided
    onDelete: PropTypes.func
    headerClickAction: PropTypes.func
    multiComparison: PropTypes.instanceOf(Immutable.Map).isRequired,

  getDefaultProps: ->
    editing: false

  getControls: ->
    editing = @props.editing
    controls = [
      name: if editing then 'save' else 'edit'
      handler: if editing then @props.onSave else @props.onEdit
    ]

    if editing
      controls.concat
        name: 'delete'
        handler: @props.onDelete
    else
      controls

  handleCollapse: ->
    @props.onSave() if @props.editing

  render: ->
    {
      questions,
      comparisonsEditData,
      expanded,
      editing
      toggleExpand,
      onSave,
      onEdit,
      onDelete,
      headerClickAction,
      multiComparison,
      editingComparison,
      questionGroups
    } = @props

    <div className='multi-comparisons__questions-container'>
      <CollapsibleWithControls controls={@getControls()} onCollapse={@handleCollapse}>
        <MultiComparisonsTitle
          isEditing={editing}
          onClick={headerClickAction}
          overarchingQuestion={if editing
            comparisonsEditData.get('overarchingQuestion')
          else
            multiComparison.get('overarchingQuestion')
          }
        />
        {if editing
          <ComparisonsSelection
            editingComparison={editingComparison}
            questions={questions}
            questionGroups={questionGroups}
            selectedQuestions={comparisonsEditData.get('selectedQuestions')}
            overarchingQuestion={comparisonsEditData.get('overarchingQuestion')}
            interventionsComparableDesc={comparisonsEditData.get 'interventionsComparableDesc'}
          />
        else
          <MultiComparisonsQuestionsList
            selectedQuestions={multiComparison.get('selectedQuestions')}
            interventionsComparableDesc={multiComparison.get 'interventionsComparableDesc'}
          />
        }
      </CollapsibleWithControls>
    </div>

module.exports = OverarchingQuestion
