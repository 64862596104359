var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "checked";
  },"3":function(depth0,helpers,partials,data) {
  return "disabled";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<label class=\"checkbox\" for=\"moderateControl\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.moderate_control_label", {"name":"i18n","hash":{},"data":data})))
    + "\n  <input id=\"moderateControl\" name=\"moderateControl\" class=\"control-checkbox\"\n    type=\"checkbox\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.moderateControl : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n</label>\n<input name=\"moderateControlLabel\" type=\"text\"\n  value=\""
    + escapeExpression(((helper = (helper = helpers.moderateControlLabel || (depth0 != null ? depth0.moderateControlLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"moderateControlLabel","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.moderateControl : depth0), {"name":"unless","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n<div class=\"inline percent\">\n  <input name=\"moderateControlRisk\" class=\"number percent\" type=\"text\"\n    value=\""
    + escapeExpression(((helper = (helper = helpers.moderateControlRisk || (depth0 != null ? depth0.moderateControlRisk : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"moderateControlRisk","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.moderateControl : depth0), {"name":"unless","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n</div>\n<div class=\"sup\"></div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}