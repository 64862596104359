DocSectionsEditor = require 'components/document_sections/from_template/doc_sections_editor'
{ vancouverFormat } = require 'lib/references_utils'

ReferencesChapter = createReactClass
  displayName: 'ReferencesChapter'

  render: ->
    { references } = @props
    <div className="references">
      {references.map (reference, idx) ->
        ref = reference.toJS()
        <p key={ref._id}>{idx + 1}{'. '}{vancouverFormat ref}</p>
      }
    </div>

Chapter = createReactClass
  displayName: 'Chapter'

  render: ->
    { chapterData, chapterId, chapterNumber, attachments, references, referenceIds } = @props
    subchapterNumber = 0
    chapterType = chapterData.get('type')

    <div className='chapter-block'>
      <h2 className='new-page chapter-title'>{chapterNumber}{'. '}{chapterData.get 'name'}</h2>
      {chapterData.get('subsections').map (subsection, idx) ->
        <div key={idx}>
          {unless subsection.get('titleHidden')
            <h3 className='subchapter-title'>
              {chapterNumber}.{++subchapterNumber}{'. '}{subsection.get('title')}
            </h3>
          }
          {if chapterType is 'references'
            <ReferencesChapter references={references} />
          else
            <DocSectionsEditor
              content={subsection.get 'content'}
              readOnly
              renderMode='printout'
              chapterId={chapterId}
              attachments={attachments}
              referenceIds={referenceIds}
              references={references}
              subsectionIdx={idx}
            />
          }
        </div>
      }
    </div>

DocSectionsFromTemplatePrintout = createReactClass
  displayName: 'DocSectionsFromTemplatePrintout'

  render: ->
    { chaptersOrder, chapters, attachments, references, referenceIds } = @props
    <div>
      {chaptersOrder.map (chapterId, idx) =>
        <Chapter
          key={chapterId}
          chapterId={chapterId}
          chapterNumber={idx + 1}
          chapterData={chapters.get chapterId}
          attachments={attachments}
          references={references}
          referenceIds={referenceIds}
        />
      }
    </div>

module.exports = DocSectionsFromTemplatePrintout
