mediator = require 'mediator'

DisplayIfSwitchIsOn = ({ children, switchName, switchType }) ->
  shouldDisplay = switch switchType
    when 'feature'
      mediator.services.switches.isOn switchName
    when 'server'
      mediator.services.switches.isServerSwitchOn switchName
    else
      throw new Error "Unknown switch type: #{switchType}"

  if shouldDisplay
    children
  else
    null

DisplayIfSwitchIsOn.propTypes =
  children: PropTypes.node.isRequired
  switchName: PropTypes.string.isRequired
  switchType: PropTypes.oneOf(['feature', 'server'])

DisplayIfSwitchIsOn.defaultProps =
  switchName: 'feature'

module.exports = DisplayIfSwitchIsOn
