OutcomesTableViewActions = require 'actions/outcomes_table_view_actions'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Select = require 'components/common/select_custom'
EditableCell = require 'components/evidence_syntheses/outcomes/cells/editable_cell'
ValidatedInput = require 'components/common/validated_input'
{ CompoundEdit, CompoundEditSection, CompoundEditBlock } =
  require 'components/evidence_syntheses/outcomes/cells/compound_edit'
{ getKeyValObject } = require 'base/lib/utils'


effectMeasureOptions = [ 'RR', 'OR', 'HR', 'rateRatio', 'other' ].reduce (options, optionVal) ->
  options.push
    value: optionVal
    text: $.t "es:outcome.measure.#{_.str.underscored(optionVal)}"
  options
, []

# TODO: DRY! Same code in control_cell_extended. Generalize!
updateOutcomeAttribute = _.compose(
  OutcomesTableViewActions.updateEditedOutcomeAttributes
  getKeyValObject
)

RelativeEffectCell = createReactClass
  displayName: 'RelativeEffectCell'

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]

  propTypes:
    outcomeAttrs: PropTypes.object
    editMetadata: PropTypes.instanceOf(Immutable.Map)
    allValid: PropTypes.func.isRequired # provided by withValidation enhancer
    validate: PropTypes.func.isRequired # provided by withValidation enhancer

  _ref: (el) ->
    @el = el

  _getInputValue: (attrName) ->
    wasEdited = @props.editMetadata?.get('editedAttributes')?.has attrName
    if wasEdited
      @props.editMetadata?.getIn(['editedAttributes', attrName]) ? ''
    else
      @props.outcomeAttrs[attrName]

  handleInputChange: (evt) ->
    attrName = evt.target.getAttribute 'name'
    value = evt.target.value.trim()

    updateOutcomeAttribute attrName, value

  handleApply: ->
    OutcomesTableViewActions.saveEditedAttributes()
    @resetEdit()

  resetEdit: ->
    @el.stopEdit()

  updateEffectMeasure: (newVal) ->
    updateOutcomeAttribute 'effectMeasure', newVal

  render: ->
    effectMeasure = @_getInputValue 'effectMeasure'
    { allValid, validate } = @props

    <EditableCell {...@props} ref={@_ref} title={@i18n 'relative_effect'}>
      <CompoundEdit applyEnabled={allValid()} onApply={@handleApply} onCancel={@resetEdit}>
        <CompoundEditBlock label={@i18n 'relative'}>
          <Select
            options={effectMeasureOptions}
            selected={effectMeasure}
            onChange={@updateEffectMeasure}
            withClearOption
          />
          {if effectMeasure is 'other'
            <input
              name='otherMeasure'
              type='text'
              onChange={@handleInputChange}
              value={@_getInputValue 'otherMeasure'}
            />
          }
          <ValidatedInput
            name='effectSize'
            onChange={@handleInputChange}
            type='text'
            validation={validate 'effectSize'}
            value={@_getInputValue 'effectSize'}
          />
        </CompoundEditBlock>
        <CompoundEditBlock label={"95% CI #{@i18n 'from'}"}>
          <ValidatedInput
            name='confidenceIntervalFrom'
            onChange={@handleInputChange}
            type='text'
            validation={validate 'confidenceIntervalFrom'}
            value={@_getInputValue 'confidenceIntervalFrom'}
          />
          <ValidatedInput
            name='confidenceIntervalTo'
            onChange={@handleInputChange}
            type='text'
            validation={validate 'confidenceIntervalTo'}
            value={@_getInputValue 'confidenceIntervalTo'}
          />
        </CompoundEditBlock>
      </CompoundEdit>
    </EditableCell>

module.exports = RelativeEffectCell
