var CovidProgressPlus, GeneralInformationDoc, Migration, W, mediator, migrationName, updateRecommendationWithProgressPlusDataFromGeneralInformationDoc,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

GeneralInformationDoc = require('lib/db_docs/general_information_doc');

mediator = require('mediator');

Migration = require('./migration');

updateRecommendationWithProgressPlusDataFromGeneralInformationDoc = require('lib/covid_extraction_form_helper').updateRecommendationWithProgressPlusDataFromGeneralInformationDoc;

W = require('when/when');

migrationName = 'covid_progress_plus';

module.exports = CovidProgressPlus = (function(_super) {
  __extends(CovidProgressPlus, _super);

  function CovidProgressPlus() {
    CovidProgressPlus.__super__.constructor.call(this, false);
  }

  CovidProgressPlus.prototype._fetchGeneralInformationDoc = function(projectId) {
    return GeneralInformationDoc.at(projectId).fetch()["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
      return {};
    });
  };

  CovidProgressPlus.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    CovidProgressPlus.__super__.up.apply(this, arguments);
    if (!mediator.services.switches.isServerSwitchOn('covidFeatures')) {
      return W.resolve();
    }
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = _.compact(_.uniq(colls.questions.reduce(function(docIds, q) {
      var adolopmentRecIds, recIds, _ref, _ref1;
      recIds = (_ref = q.get('recommendationIds')) != null ? _ref : [];
      adolopmentRecIds = (_ref1 = q.get('recommendationAdolopmentIds')) != null ? _ref1 : [];
      return docIds.concat(recIds).concat(adolopmentRecIds);
    }, [])));
    if (_.isEmpty(docIds)) {
      return W.resolve();
    }
    return W.all([this._fetchGeneralInformationDoc(projectId), adapter.fetch(projectId, docIds)]).then(function(_arg) {
      var docs, generalInformationDoc, rows, updatedDocs, _ref;
      generalInformationDoc = _arg[0], (_ref = _arg[1], rows = _ref.rows);
      if (_.isEmpty(generalInformationDoc)) {
        return;
      }
      docs = _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
      if (_.isEmpty(docs)) {
        return;
      }
      updatedDocs = _.map(docs, function(doc) {
        var docToSave;
        docToSave = updateRecommendationWithProgressPlusDataFromGeneralInformationDoc(doc, generalInformationDoc);
        return _.extend(docToSave, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return CovidProgressPlus;

})(Migration);
