var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", blockHelperMissing=helpers.blockHelperMissing, buffer = "";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "patients, premium-patients", (depth0 != null ? depth0.presentationType : depth0), {"name":"inStringArray","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"choose-format\">\n  <label class=\"choose-format-title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_output", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n  <div class=\"formats-list\">\n    <label>\n";
  stack1 = ((helper = (helper = helpers.isConnected || (depth0 != null ? depth0.isConnected : depth0)) != null ? helper : helperMissing),(options={"name":"isConnected","hash":{},"fn":this.program(9, data),"inverse":this.program(11, data),"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.isConnected) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </label>\n    <label>\n";
  stack1 = ((helper = (helper = helpers.isConnected || (depth0 != null ? depth0.isConnected : depth0)) != null ? helper : helperMissing),(options={"name":"isConnected","hash":{},"fn":this.program(13, data),"inverse":this.program(15, data),"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.isConnected) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </label>\n    <label>\n";
  stack1 = ((helper = (helper = helpers.isConnected || (depth0 != null ? depth0.isConnected : depth0)) != null ? helper : helperMissing),(options={"name":"isConnected","hash":{},"fn":this.program(17, data),"inverse":this.program(19, data),"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.isConnected) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </label>\n  </div>\n</div>\n<div class=\"buttons\">\n  <div class=\"row mt-10\">\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-cancel cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-export download finish\" disabled>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "button_download", {"name":"i18n","hash":{},"data":data})))
    + "\n      </button>\n    </div>\n  </div>\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <div class=\"choose-presentation-format\">\n    <div>\n      <label class=\"choose-format-title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_presentation_format", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n    </div>\n    <div class=\"presentation-options\">\n      <div class=\"presentation-appearance-option\">\n        <div class=\"image-with-preview\">\n          <img class=\"format-image\" src=\"images/regular_presentation_format.png\">\n        </div>\n        <label>\n          <input\n            type=\"radio\"\n            name=\"presentationFormat\"\n            value=\"patients\"\n            ";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.premiumPresentations : depth0), {"name":"unless","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n          >\n          <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "regular_presentation_format", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </label>\n      </div>\n      <div class=\"presentation-appearance-option\">\n        <div class=\"image-with-preview\">\n          <img class=\"format-image\" src=\"images/premium_presentation_format.png\">\n          <button class=\"preview\" data-format=\"premium\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "see_example", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n        </div>\n        <label>\n          <input\n            type=\"radio\"\n            name=\"presentationFormat\"\n            value=\"premium-patients\"\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.premiumPresentations : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.premiumPresentations : depth0), {"name":"unless","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n          >\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "premium_presentation_format", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.premiumPresentations : depth0), {"name":"unless","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </label>\n      </div>\n    </div>\n  </div>\n";
},"3":function(depth0,helpers,partials,data) {
  return "checked";
  },"5":function(depth0,helpers,partials,data) {
  return "disabled";
  },"7":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "              <br/><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "standard_licence_only", {"name":"i18n","hash":{},"data":data})))
    + "</b>\n";
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"exportType\" value=\"externalLink\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_link", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"exportType\" value=\"externalLink\" disabled>\n        <span class=\"label-disabled-disconnected\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_link", {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n        <span class=\"question-mark-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_link_offline", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n";
},"13":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"exportType\" value=\"pdf\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.pdf", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"15":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"exportType\" value=\"pdf\" disabled>\n        <span class=\"label-disabled-disconnected\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.pdf", {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n        <span class=\"question-mark-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.pdf_offline", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n";
},"17":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"exportType\" value=\"externalEmbeddable\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_embeddable_code", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"19":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"exportType\" value=\"externalEmbeddable\" disabled>\n        <span class=\"label-disabled-disconnected\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_embeddable_code", {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n        <span class=\"question-mark-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_embeddable_offline", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<h2 class=\"title\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:export_dialog", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}