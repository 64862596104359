var INITIAL_DIALOG_STATE, INITIAL_STATE, MDA_TOPIC_DOC_TYPE, MdaTableActions, convertRowsToMap, generateStore, mediator;

convertRowsToMap = require('lib/app_utils').convertRowsToMap;

generateStore = require('stores/utils/generate_store');

MdaTableActions = require('actions/mda_table_actions');

MDA_TOPIC_DOC_TYPE = require('lib/db_docs/doc_types').MDA_TOPIC;

mediator = require('mediator');

INITIAL_DIALOG_STATE = Immutable.fromJS({
  applying: false,
  isOpen: false,
  name: null,
  tableId: null
});

INITIAL_STATE = Immutable.fromJS({
  currentTableId: null,
  dialog: INITIAL_DIALOG_STATE,
  isFetching: false,
  table: Immutable.Map(),
  topics: Immutable.Map()
});

module.exports = generateStore({
  name: 'MdaTableStore',
  initialState: INITIAL_STATE,
  boundActions: [MdaTableActions],
  methods: {
    onFetch: function(tableId) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetching', true);
        return state.set('currentTableId', tableId);
      }));
    },
    onFetchSuccess: function(_arg) {
      var table, topicsRows;
      table = _arg.table, topicsRows = _arg.topicsRows;
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetching', false);
        state.set('table', Immutable.fromJS(table));
        return state.set('topics', convertRowsToMap(topicsRows));
      }));
    },
    onDbChange: function(change) {
      if (change.id === this.state.get('currentTableId')) {
        return this.setState(this.state.set('table', Immutable.fromJS(change.doc)));
      } else if (change.deleted) {
        return this.setState(this.state.deleteIn(['topics', change.id]));
      } else if (change.doc.docType === MDA_TOPIC_DOC_TYPE) {
        return this.setState(this.state.setIn(['topics', change.id], Immutable.fromJS(change.doc)));
      }
    },
    onOpenCreateOrEditDialog: function(payload) {
      var name, tableId, _ref, _ref1;
      name = (_ref = payload != null ? payload.name : void 0) != null ? _ref : null;
      tableId = (_ref1 = payload != null ? payload.tableId : void 0) != null ? _ref1 : null;
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['dialog', 'isOpen'], true);
        state.setIn(['dialog', 'name'], name);
        return state.setIn(['dialog', 'tableId'], tableId);
      }));
    },
    onCloseCreateOrEditDialog: function() {
      return this.setState(this.state.set('dialog', INITIAL_DIALOG_STATE));
    },
    onUpdateQuestion: function() {
      return this.setState(this.state.setIn(['dialog', 'applying'], true));
    },
    onCreateTable: function() {
      return this.setState(this.state.setIn(['dialog', 'applying'], true));
    },
    isModalApplying: function() {
      return this.state.getIn(['dialog', 'applying']);
    },
    isModalOpen: function() {
      return this.state.getIn(['dialog', 'isOpen']);
    },
    getModalName: function() {
      return this.state.getIn(['dialog', 'name']);
    },
    getTableId: function() {
      return this.state.getIn(['dialog', 'tableId']);
    },
    isFetching: function() {
      return this.state.get('isFetching');
    },
    getContent: function() {
      return this.state.getIn(['table', 'content']);
    },
    getCurrentTableId: function() {
      return this.state.get('currentTableId');
    },
    getTopicsForTable: function() {
      var topicIds, topics;
      topicIds = this.state.getIn(['table', 'topics']) || Immutable.List();
      topics = this.state.get('topics');
      return topicIds.map(function(topicId) {
        return topics.get(topicId);
      }).filter(function(topic) {
        return topic != null;
      }).sortBy(function(topic) {
        var _ref;
        return (_ref = topic.get('name')) != null ? _ref : '';
      });
    }
  }
});
