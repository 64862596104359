var ModuleController, OverarchingQuestionCreatorView, OverarchingQuestionRecommendationsView, OverarchingQuestionsController,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

OverarchingQuestionCreatorView = require('views/overarching_questions/overarching_question_creator_view');

OverarchingQuestionRecommendationsView = require('views/overarching_questions/overarching_question_recommendations_view');

module.exports = OverarchingQuestionsController = (function(_super) {
  __extends(OverarchingQuestionsController, _super);

  function OverarchingQuestionsController() {
    return OverarchingQuestionsController.__super__.constructor.apply(this, arguments);
  }

  OverarchingQuestionsController.prototype.viewsForSubmodules = {
    'create': OverarchingQuestionCreatorView,
    'edit': OverarchingQuestionCreatorView,
    'details': OverarchingQuestionRecommendationsView
  };

  return OverarchingQuestionsController;

})(ModuleController);
