var Exceptions, GlobalExportActions, GlobalExportStore, INITIAL_STATE, alt, immutable, utils;

alt = require('alt');

Exceptions = require('lib/exceptions');

immutable = require('stores/utils/immutable');

GlobalExportActions = require('actions/global_export_actions');

utils = require('base/lib/utils');

INITIAL_STATE = Immutable.fromJS({
  _meta: {
    isLoading: false,
    isExporting: false,
    isOpen: false,
    isFetchError: false,
    isExportError: false,
    isOnline: true,
    screen: 'select_data'
  },
  fetchedData: {
    questions: Immutable.Map(),
    questionGroups: Immutable.Map(),
    documentSections: Immutable.Map(),
    projectMetadoc: Immutable.Map()
  },
  selectedQuestions: Immutable.List(),
  selectedDocuments: {
    documentSections: false,
    evidenceTables: false,
    isofs: false,
    etds: false,
    presentations: false
  },
  selectedPresentationAudiences: Immutable.List(),
  selectedDiagnosticQuestionViews: Immutable.List(),
  selectedManagementQuestionViews: Immutable.List(),
  selectedFormats: {
    documentSections: Immutable.List(),
    evidenceTables: Immutable.List(),
    isofs: Immutable.List(['link']),
    etds: Immutable.List(),
    presentations: Immutable.List()
  },
  exportedFileUrl: '',
  exportProgress: 0,
  projectId: null
});

GlobalExportStore = (function() {
  function GlobalExportStore() {
    this.state = INITIAL_STATE;
    this.bindActions(GlobalExportActions);
    this.exportPublicMethods({
      areAllQuestionsSelected: this.areAllQuestionsSelected,
      isAnyDiagnosticQuestionChosen: this.isAnyDiagnosticQuestionChosen,
      isAnyManagementQuestionChosen: this.isAnyManagementQuestionChosen,
      isFetchError: this.isFetchError,
      isExportError: this.isExportError,
      isExporting: this.isExporting,
      isOpen: this.isOpen,
      isLoading: this.isLoading,
      isExportEnabled: this.isExportEnabled,
      isOnline: this.isOnline,
      isDocumentSectionsEnabled: this.isDocumentSectionsEnabled,
      getCurrentScreen: this.getCurrentScreen,
      getQuestions: this.getQuestions,
      getQuestionGroups: this.getQuestionGroups,
      getSelectedQuestions: this.getSelectedQuestions,
      getSelectedDiagnosticQuestionViews: this.getSelectedDiagnosticQuestionViews,
      getSelectedManagementQuestionViews: this.getSelectedManagementQuestionViews,
      getSelectedDocuments: this.getSelectedDocuments,
      getSelectedPresentationAudiences: this.getSelectedPresentationAudiences,
      getSelectedFormats: this.getSelectedFormats,
      getExportProgress: this.getExportProgress,
      getExportedFileUrl: this.getExportedFileUrl,
      getExportOptions: this.getExportOptions
    });
  }

  GlobalExportStore.prototype.onOpenModal = function(projectId) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'isOpen'], true);
      s.setIn(['_meta', 'isLoading'], true);
      return s.set('projectId', projectId);
    }));
  };

  GlobalExportStore.prototype.onCloseModal = function() {
    return this.setState(INITIAL_STATE);
  };

  GlobalExportStore.prototype.onFetchSuccess = function(_arg) {
    var documentSections, projectMetadoc, questionGroups, questions;
    questions = _arg.questions, documentSections = _arg.documentSections, projectMetadoc = _arg.projectMetadoc, questionGroups = _arg.questionGroups;
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'isLoading'], false);
      s.setIn(['fetchedData', 'questions'], Immutable.fromJS(questions));
      s.setIn(['fetchedData', 'documentSections'], Immutable.fromJS(documentSections));
      s.setIn(['fetchedData', 'projectMetadoc'], Immutable.fromJS(projectMetadoc));
      return s.setIn(['fetchedData', 'questionGroups'], Immutable.fromJS(questionGroups));
    }));
  };

  GlobalExportStore.prototype.onFetchError = function(error) {
    var _setState;
    _setState = (function(_this) {
      return function(isOnline) {
        return _this.setState(_this.state.withMutations(function(s) {
          s.setIn(['_meta', 'isLoading'], false);
          s.setIn(['_meta', 'isFetchError'], true);
          return s.setIn(['_meta', 'isOnline'], isOnline);
        }));
      };
    })(this);
    if (error instanceof Exceptions.no_connection) {
      return _setState(false);
    } else {
      console.error('Error while fetching questions:', error);
      utils.reportRavenError(error);
      return _setState(true);
    }
  };

  GlobalExportStore.prototype.onSelectDocument = function(type) {
    var currentSelected;
    currentSelected = this.state.getIn(['selectedDocuments', type]);
    return this.setState(this.state.setIn(['selectedDocuments', type], !currentSelected));
  };

  GlobalExportStore.prototype.onSelectPresentationAudiences = function(audiences) {
    return this.setState(this.state.set('selectedPresentationAudiences', Immutable.fromJS(audiences)));
  };

  GlobalExportStore.prototype.onSelectFormats = function(_arg) {
    var formats, type;
    type = _arg.type, formats = _arg.formats;
    return this.setState(this.state.setIn(['selectedFormats', type], Immutable.fromJS(formats)));
  };

  GlobalExportStore.prototype.onExport = function() {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'isExporting'], true);
      s.setIn(['_meta', 'isExportError'], false);
      s.setIn(['_meta', 'screen'], 'export_progress');
      return s.set('exportProgress', 0);
    }));
  };

  GlobalExportStore.prototype.onExportSuccess = function(zipUrl) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'isExporting'], false);
      return s.set('exportedFileUrl', zipUrl);
    }));
  };

  GlobalExportStore.prototype.onExportError = function(error) {
    console.error('Error while exporting:', error);
    utils.reportRavenError(error);
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'isExporting'], false);
      s.setIn(['_meta', 'isExportError'], true);
      return s.setIn(['_meta', 'screen'], 'export_error');
    }));
  };

  GlobalExportStore.prototype.onUpdateSelectedQuestions = function(newSelectedQuestions) {
    return this.setState(this.state.set('selectedQuestions', newSelectedQuestions));
  };

  GlobalExportStore.prototype.onSelectQuestionViews = function(_arg) {
    var diagnostic, management;
    diagnostic = _arg.diagnostic, management = _arg.management;
    if (diagnostic == null) {
      diagnostic = Immutable.List();
    }
    if (management == null) {
      management = Immutable.List();
    }
    return this.setState(this.state.withMutations(function(s) {
      s.set('selectedDiagnosticQuestionViews', Immutable.fromJS(diagnostic));
      return s.set('selectedManagementQuestionViews', Immutable.fromJS(management));
    }));
  };

  GlobalExportStore.prototype.onSetExportProgress = function(exportProgress) {
    return this.setState(this.state.set('exportProgress', exportProgress));
  };

  GlobalExportStore.prototype.isExporting = function() {
    return this.state.getIn(['_meta', 'isExporting']);
  };

  GlobalExportStore.prototype.isOpen = function() {
    return this.state.getIn(['_meta', 'isOpen']);
  };

  GlobalExportStore.prototype.isLoading = function() {
    return this.state.getIn(['_meta', 'isLoading']);
  };

  GlobalExportStore.prototype.isExportError = function() {
    return this.state.getIn(['_meta', 'isExportError']);
  };

  GlobalExportStore.prototype.isFetchError = function() {
    return this.state.getIn(['_meta', 'isFetchError']);
  };

  GlobalExportStore.prototype.isExportEnabled = function() {
    var areEvidenceTablesChosen, arePresentationsChosen, isDiagnosticQuestionChosen, isManagementQuestionChosen, isNoAudienceChosen, isNoDiagnosticViewChosen, isNoManagementViewChosen, isNoQuestionChosen, isQuestionRequired;
    if (!this.state.get('selectedDocuments').some(_.identity)) {
      return false;
    }
    isNoQuestionChosen = this.state.get('selectedQuestions').isEmpty();
    isQuestionRequired = this.state.get('selectedDocuments').some(function(isSelected, documentType) {
      return documentType !== 'documentSections' && isSelected;
    });
    if (isQuestionRequired && isNoQuestionChosen) {
      return false;
    }
    arePresentationsChosen = this.state.getIn(['selectedDocuments', 'presentations']);
    isNoAudienceChosen = this.state.get('selectedPresentationAudiences').isEmpty();
    if (arePresentationsChosen && isNoAudienceChosen) {
      return false;
    }
    areEvidenceTablesChosen = this.state.getIn(['selectedDocuments', 'evidenceTables']);
    isManagementQuestionChosen = this.isAnyManagementQuestionChosen();
    isDiagnosticQuestionChosen = this.isAnyDiagnosticQuestionChosen();
    isNoManagementViewChosen = this.state.get('selectedManagementQuestionViews').isEmpty();
    isNoDiagnosticViewChosen = this.state.get('selectedDiagnosticQuestionViews').isEmpty();
    if (areEvidenceTablesChosen && isManagementQuestionChosen && isNoManagementViewChosen) {
      return false;
    }
    if (areEvidenceTablesChosen && isDiagnosticQuestionChosen && isNoDiagnosticViewChosen) {
      return false;
    }
    return this.state.get('selectedFormats').every((function(_this) {
      return function(selectedFormats, documentType) {
        var isTypeSelected;
        isTypeSelected = _this.state.getIn(['selectedDocuments', documentType]);
        return !isTypeSelected || !selectedFormats.isEmpty();
      };
    })(this));
  };

  GlobalExportStore.prototype.isOnline = function() {
    return this.state.getIn(['_meta', 'isOnline']);
  };

  GlobalExportStore.prototype.areAllQuestionsSelected = function() {
    var questions;
    questions = this.state.getIn(['fetchedData', 'questions']);
    if (questions.isEmpty()) {
      return false;
    }
    return this.state.get('selectedQuestions').size === questions.size;
  };

  GlobalExportStore.prototype.isAnyDiagnosticQuestionChosen = function() {
    return this.state.get('selectedQuestions').some((function(_this) {
      return function(questionId) {
        return _this.state.getIn(['fetchedData', 'questions', questionId]).get('type') === 'diagnostic';
      };
    })(this));
  };

  GlobalExportStore.prototype.isAnyManagementQuestionChosen = function() {
    return this.state.get('selectedQuestions').some((function(_this) {
      return function(questionId) {
        return _this.state.getIn(['fetchedData', 'questions', questionId]).get('type') !== 'diagnostic';
      };
    })(this));
  };

  GlobalExportStore.prototype.isDocumentSectionsEnabled = function() {
    var docSections;
    docSections = this.state.getIn(['fetchedData', 'documentSections']);
    return docSections != null;
  };

  GlobalExportStore.prototype.getCurrentScreen = function() {
    return this.state.getIn(['_meta', 'screen']);
  };

  GlobalExportStore.prototype.getQuestions = function() {
    return this.state.getIn(['fetchedData', 'questions']);
  };

  GlobalExportStore.prototype.getQuestionGroups = function() {
    return this.state.getIn(['fetchedData', 'questionGroups']);
  };

  GlobalExportStore.prototype.getSelectedDiagnosticQuestionViews = function() {
    return this.state.get('selectedDiagnosticQuestionViews');
  };

  GlobalExportStore.prototype.getSelectedManagementQuestionViews = function() {
    return this.state.get('selectedManagementQuestionViews');
  };

  GlobalExportStore.prototype.getSelectedQuestions = function() {
    return this.state.get('selectedQuestions');
  };

  GlobalExportStore.prototype.getSelectedDocuments = function() {
    return this.state.get('selectedDocuments');
  };

  GlobalExportStore.prototype.getSelectedPresentationAudiences = function() {
    return this.state.get('selectedPresentationAudiences');
  };

  GlobalExportStore.prototype.getSelectedFormats = function() {
    return this.state.get('selectedFormats');
  };

  GlobalExportStore.prototype.getExportProgress = function() {
    return this.state.get('exportProgress');
  };

  GlobalExportStore.prototype.getExportedFileUrl = function() {
    return this.state.get('exportedFileUrl');
  };

  GlobalExportStore.prototype.getExportOptions = function() {
    return Immutable.fromJS(_.reduce([
      {
        key: 'questions',
        path: ['fetchedData', 'questions']
      }, {
        key: 'questionGroups',
        path: ['fetchedData', 'questionGroups']
      }, {
        key: 'projectMetadoc',
        path: ['fetchedData', 'projectMetadoc']
      }, {
        key: 'documentSections',
        path: ['fetchedData', 'documentSections']
      }, {
        key: 'selectedDocuments'
      }, {
        key: 'selectedQuestions'
      }, {
        key: 'selectedDiagnosticQuestionViews'
      }, {
        key: 'selectedManagementQuestionViews'
      }, {
        key: 'selectedPresentationAudiences'
      }, {
        key: 'selectedFormats'
      }, {
        key: 'projectId'
      }
    ], (function(_this) {
      return function(acc, _arg) {
        var key, path;
        key = _arg.key, path = _arg.path;
        if (path == null) {
          path = [key];
        }
        acc[key] = _this.state.getIn(path);
        return acc;
      };
    })(this), {}));
  };

  return GlobalExportStore;

})();

module.exports = alt.createStore(immutable(GlobalExportStore), 'GlobalExportStore');
