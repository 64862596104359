Etd = require 'components/etd/etd'
PanelVoiceEtd = require 'components/etd/panel_voice_etd/panel_voice_etd'

OverarchingQuestionRecommendations = ({ questionId, panelVoice }) ->
  <div id="recommendations">
    {if panelVoice
      <PanelVoiceEtd
        questionId={questionId}
        type='overarching'
      />
    else
      <Etd
        questionId={questionId}
        type='overarching'
      />
    }
  </div>

OverarchingQuestionRecommendations.propTypes = {
  questionId: PropTypes.string.isRequired
}

module.exports = OverarchingQuestionRecommendations
