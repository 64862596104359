CollapsibleWithControls = require 'components/common/collapsible_with_controls'

CollapsibleChapter = createReactClass
  propTypes:
    isExpanded: PropTypes.bool
    onCollapse: PropTypes.func
    onExpand: PropTypes.func
    title: PropTypes.node

  getDefaultProps: ->
    isExpanded: false

  render: ->
    <div className={classNames 'gdt-collapsible-chapter', expanded: @props.isExpanded}>
      <CollapsibleWithControls
        forcedExpand={@props.isExpanded}
        onCollapse={@props.onCollapse}
        onExpand={@props.onExpand}
        withTogglableCaption
      >
        <div>{@props.title}</div>
        <div>{@props.children}</div>
      </CollapsibleWithControls>
    </div>

module.exports = CollapsibleChapter
