var NO_MORE_TASKS, ProjectTaskRunnerService, TASKS, TASKS_TO_RUN_DOC_ID, TASK_COMPLETE, W, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

W = require('when');

TASKS_TO_RUN_DOC_ID = require('lib/doc_prefixes').tasksToRun;

TASKS = require('lib/project_tasks/tasks');

utils = require('base/lib/utils');

TASK_COMPLETE = 'task_complete';

NO_MORE_TASKS = 'no_more_tasks';

module.exports = ProjectTaskRunnerService = (function() {
  function ProjectTaskRunnerService() {
    this._saveResult = __bind(this._saveResult, this);
  }

  ProjectTaskRunnerService.prototype._getTaskToRun = function(projectId) {
    return this.pouchAdapter.fetch(projectId, TASKS_TO_RUN_DOC_ID)["catch"](function(e) {
      if (e.status !== 404) {
        throw e;
      }
      return {
        tasks_to_run: []
      };
    }).then(function(_arg) {
      var tasks_to_run, _ref;
      tasks_to_run = _arg.tasks_to_run;
      return (_ref = tasks_to_run != null ? tasks_to_run[0] : void 0) != null ? _ref : null;
    });
  };

  ProjectTaskRunnerService.prototype._runTask = function(projectId, taskToRun) {
    var message, name, task;
    name = taskToRun.name;
    task = TASKS[name];
    if (!task) {
      message = "No task named " + name;
      return W.resolve(_.extend({}, taskToRun, {
        message: message,
        error: new Error(message),
        result: 'error'
      }));
    } else {
      return task.run(projectId, taskToRun);
    }
  };

  ProjectTaskRunnerService.prototype._saveResult = function(projectId) {
    return (function(_this) {
      return function(result) {
        return _this.pouchAdapter.updateOrCreate(projectId, TASKS_TO_RUN_DOC_ID, function(doc) {
          var results, tasks_to_run;
          tasks_to_run = doc.tasks_to_run, results = doc.results;
          if (tasks_to_run == null) {
            tasks_to_run = [];
          }
          if (results == null) {
            results = [];
          }
          tasks_to_run.shift();
          results.push(result);
          return _.extend(doc, {
            tasks_to_run: tasks_to_run,
            results: results
          });
        });
      };
    })(this);
  };

  ProjectTaskRunnerService.prototype._maybeRunSingleTask = function(projectId) {
    return this._getTaskToRun(projectId).then((function(_this) {
      return function(task) {
        if (!task) {
          return W.resolve(NO_MORE_TASKS);
        }
        return _this._runTask(projectId, task).then(_this._saveResult(projectId)).then(function() {
          return TASK_COMPLETE;
        });
      };
    })(this));
  };

  ProjectTaskRunnerService.prototype.runTasks = function(projectId) {
    return this._maybeRunSingleTask(projectId).then((function(_this) {
      return function(action) {
        var e;
        switch (action) {
          case TASK_COMPLETE:
            return _this.runTasks(projectId);
          case NO_MORE_TASKS:
            return W.resolve();
          default:
            e = new Error("Unknown action: " + action + " to perform");
            utils.reportRavenError(e, {
              extra: {
                projectId: projectId,
                action: action
              }
            });
            return W.resolve();
        }
      };
    })(this));
  };

  return ProjectTaskRunnerService;

})();
