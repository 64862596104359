ApprovalDetails = require 'components/scope/outcomes/approval_outcome_details'
ApprovalDetailsExtended = require 'components/scope/outcomes/approval_outcome_details_extended'
MarkerCell = require 'components/scope/outcomes/approval_outcome_marker_cell'
CommentButton = require 'components/common/empty_comment_button'
OutcomesGenerationActions = require 'actions/outcomes_generation_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
approvalStep = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS[5]

# TODO: very much alike ApprovalDetailsRow from Questions Generation
ApprovalOutcomeDetailsRow = createReactClass
  displayName: 'ApprovalOutcomeDetailsRow'

  propTypes:
    outcome: PropTypes.instanceOf(Immutable.Map).isRequired
    gIndex: PropTypes.number.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  _didAllMembersApprove: ->
    approvals = @props.outcome.get 'approvals', Immutable.Map()
    approvals.every (approval) -> approval

  onApplyAdminComment: (stepName) -> (data) =>
    _.extend data,
      step: stepName
      gIndex: @props.gIndex
      idOrKey: @props.outcome.get 'key'
    OutcomesGenerationActions.saveAdminComment data

  onSaveClose: ->
    @props.onCommentToggle()
    unless @props.readOnly
      adminComment = @detailsBlock.getAdminComment()
      OutcomesGenerationActions.saveAdminComment
        step: approvalStep
        gIndex: @props.gIndex
        idOrKey: @props.outcome.get 'key'
        newText: adminComment

  renderDetailsBlock: (allApproved) ->
    props = _.pick @props, ['outcome', 'membersMap', 'readOnly', 'groupType']

    if allApproved
      <ApprovalDetails {...props} onApplyAdminComment={@onApplyAdminComment} />
    else
      <ApprovalDetailsExtended {...props}
        ref={(el) => @detailsBlock = el}
        todos={@props.todos}
        gIndex={@props.gIndex}
        saveTodos={OutcomesGenerationActions.saveTodos}
        setDisagreementResolved={OutcomesGenerationActions.setDisagreementResolved}
        onApplyAdminComment={@onApplyAdminComment}
      />

  renderButtonsBlock: (allApproved) ->
    <div className='button-container'>
      {if allApproved
        <CommentButton className='comment-blank-hide' onClick={@props.onCommentToggle} />
      else
        <button onClick={@onSaveClose} className='btn save-close'>
          {@i18n '../questions.approval.save_close'}
        </button>
      }
    </div>

  render: ->
    allApproved = @_didAllMembersApprove()

    <tr className='outcome-details-row'>
      <MarkerCell importance={@props.outcome.get 'importance'} />
      <td colSpan='3'>
        {@renderDetailsBlock allApproved}
        {@renderButtonsBlock allApproved}
      </td>
    </tr>

module.exports = ApprovalOutcomeDetailsRow
