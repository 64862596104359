var APPROVAL_TAB_OUTCOME, OUTCOME, OUTCOMES_GENERATION_STEPS, OutcomesGenerationActions, OutcomesGenerationService, OutcomesGenerationStore, PouchSource, QGROUP, QUESTION, ReactDnDActions, alt, calcMedianRating, cleanUpDrag, convertComparisonData, createOutcomeObject, createQuestionGroupObject, deleteGroup, errorHandler, getComparisonQuestions, getImportanceForRating, getNextStepName, getProjectId, immutable, moveOutcomes, processComparisonData, sortQuestionGroups, updateWithComparisonData, _ensureOutcomesKeys, _ref, _ref1, _ref2;

alt = require('alt');

immutable = require('stores/utils/immutable');

OutcomesGenerationActions = require('actions/outcomes_generation_actions');

ReactDnDActions = require('actions/react_dnd_actions');

PouchSource = require('stores/sources/pouch_source');

OutcomesGenerationService = require('lib/services/outcomes_generation_service');

errorHandler = require('lib/app_utils').errorHandler;

_ref = require('lib/questions_helper'), OUTCOMES_GENERATION_STEPS = _ref.OUTCOMES_GENERATION_STEPS, calcMedianRating = _ref.calcMedianRating;

_ref1 = require('components/scope/outcomes/drag_drop_types'), OUTCOME = _ref1.OUTCOME, APPROVAL_TAB_OUTCOME = _ref1.APPROVAL_TAB_OUTCOME, QUESTION = _ref1.QUESTION, QGROUP = _ref1.QGROUP;

_ref2 = require('stores/utils/outcomes_generation_store_helper'), getProjectId = _ref2.getProjectId, getNextStepName = _ref2.getNextStepName, moveOutcomes = _ref2.moveOutcomes, createQuestionGroupObject = _ref2.createQuestionGroupObject, createOutcomeObject = _ref2.createOutcomeObject, sortQuestionGroups = _ref2.sortQuestionGroups, deleteGroup = _ref2.deleteGroup, cleanUpDrag = _ref2.cleanUpDrag, getComparisonQuestions = _ref2.getComparisonQuestions, processComparisonData = _ref2.processComparisonData, convertComparisonData = _ref2.convertComparisonData, updateWithComparisonData = _ref2.updateWithComparisonData, _ensureOutcomesKeys = _ref2._ensureOutcomesKeys, getImportanceForRating = _ref2.getImportanceForRating;

OutcomesGenerationStore = (function() {
  function OutcomesGenerationStore() {
    this.state = Immutable.fromJS({
      _meta: {
        currentDragPosition: null,
        editingOutcome: null,
        activeTab: OUTCOMES_GENERATION_STEPS[0]
      },
      outcomesGenerationData: {}
    });
    this.bindActions(OutcomesGenerationActions);
    this.bindActions(ReactDnDActions);
    this.registerAsync(PouchSource(OutcomesGenerationActions));
    this.exportPublicMethods({
      getStepsData: this.getStepsData,
      getActiveTab: this.getActiveTab,
      getCurrentStep: this.getCurrentStep,
      getNextStep: this.getNextStep,
      getTodos: this.getTodos,
      hasItemDropped: this.hasItemDropped,
      getDraggingQuestionPosition: this.getDraggingQuestionPosition,
      getEditingOutcome: this.getEditingOutcome,
      getStepQuestionGroupsWithComments: this.getStepQuestionGroupsWithComments,
      isFetching: this.isFetching,
      isFetchingResults: this.isFetchingResults,
      isExportingComments: this.isExportingComments,
      isMovingOutcomes: this.isMovingOutcomes,
      isClosingStep: this.isClosingStep,
      isSendingToMembers: this.isSendingToMembers,
      hasDataUpdated: this.hasDataUpdated
    });
  }

  OutcomesGenerationStore.prototype.onFetch = function(params) {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  OutcomesGenerationStore.prototype.onFetchSuccess = function(_arg) {
    var comparisonQuestionGroups, initialGenerationDone, outcomesGenerationData;
    comparisonQuestionGroups = _arg[0], outcomesGenerationData = _arg[1];
    initialGenerationDone = false;
    this.setState(this.state.withMutations(function(state) {
      var convertedComparisonData, initialStep;
      state.setIn(['_meta', 'fetching'], false);
      state.set('comparisonData', processComparisonData(comparisonQuestionGroups, getComparisonQuestions()));
      if (outcomesGenerationData) {
        outcomesGenerationData = Immutable.fromJS(outcomesGenerationData);
        state.set('outcomesGenerationData', _ensureOutcomesKeys(outcomesGenerationData));
        updateWithComparisonData(state);
      } else {
        initialStep = OUTCOMES_GENERATION_STEPS[0];
        convertedComparisonData = convertComparisonData(state.get('comparisonData'));
        if (!convertedComparisonData.isEmpty()) {
          initialGenerationDone = true;
        }
        state.setIn(['outcomesGenerationData', 'steps', initialStep, 'questionGroups'], convertedComparisonData);
        state.setIn(['outcomesGenerationData', 'currentStep'], initialStep);
      }
      return state.setIn(['_meta', 'activeTab'], state.getIn(['outcomesGenerationData', 'currentStep']));
    }));
    if (initialGenerationDone || this.state.getIn(['_meta', 'dataUpdateNotify'])) {
      return this.save();
    }
  };

  OutcomesGenerationStore.prototype.onFetchError = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    if (err.status !== 404) {
      return errorHandler(err);
    }
  };

  OutcomesGenerationStore.prototype.onSendOutcomes = function(params) {
    var memberIds;
    memberIds = params.memberIds;
    return this.setState(this.state.setIn(['_meta', 'sendingToMembers'], Immutable.fromJS(memberIds)));
  };

  OutcomesGenerationStore.prototype.onSendOutcomesSuccess = function(results) {
    return this.proceedToNextStep(results);
  };

  OutcomesGenerationStore.prototype.onSendOutcomesError = function(err) {
    this.setState(this.state.setIn(['_meta', 'sendingToMembers'], null));
    return errorHandler(err);
  };

  OutcomesGenerationStore.prototype.onFetchResults = function() {
    return this.setState(this.state.setIn(['_meta', 'fetchingResults'], true));
  };

  OutcomesGenerationStore.prototype.onFetchResultsSuccess = function(data) {
    var currentStep, memberAgreementComments, memberAgreements, memberIds, ogs, questionGroups, stepData, submittedMemberIds;
    questionGroups = sortQuestionGroups(Immutable.fromJS(data.questionGroups));
    memberIds = data.memberIds, submittedMemberIds = data.submittedMemberIds, memberAgreements = data.memberAgreements, memberAgreementComments = data.memberAgreementComments;
    currentStep = this.getCurrentStep();
    stepData = this.state.getIn(['outcomesGenerationData', 'steps', currentStep]);
    ogs = new OutcomesGenerationService();
    this.setState(this.state.setIn(['_meta', 'fetchingResults'], false).setIn(['outcomesGenerationData', 'steps', currentStep], Immutable.Map({
      questionGroups: ogs.mergeQuestionGroups(stepData.get('questionGroups'), questionGroups)
    })).mergeDeepIn(['outcomesGenerationData', 'steps', currentStep], Immutable.Map({
      memberIds: Immutable.List(memberIds),
      submittedMemberIds: Immutable.List(submittedMemberIds),
      memberAgreements: Immutable.fromJS(memberAgreements),
      memberAgreementComments: Immutable.fromJS(memberAgreementComments)
    })));
    return this.save();
  };

  OutcomesGenerationStore.prototype.onFetchResultsError = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetchingResults'], false));
    return errorHandler(err);
  };

  OutcomesGenerationStore.prototype.onCloseStep = function() {
    return this.setState(this.state.setIn(['_meta', 'closingStep'], true));
  };

  OutcomesGenerationStore.prototype.onCloseStepSuccess = function() {
    return this.proceedToNextStep();
  };

  OutcomesGenerationStore.prototype.onCloseStepError = function(err) {
    this.setState(this.state.setIn(['_meta', 'closingStep'], false));
    return errorHandler(err);
  };

  OutcomesGenerationStore.prototype.onHover = function(params) {
    var _ref3;
    if ((_ref3 = params != null ? params.hoverType : void 0) === QUESTION || _ref3 === OUTCOME || _ref3 === APPROVAL_TAB_OUTCOME) {
      return this.moveItem(params);
    }
  };

  OutcomesGenerationStore.prototype.onDropAreaDrop = function(params) {
    var dragKey, gIndex, importance, sourceGroup, sourcePosition, targetGroup, type, _ref3, _ref4;
    type = params.type;
    if ((_ref3 = params.type) !== OUTCOME && _ref3 !== APPROVAL_TAB_OUTCOME) {
      return;
    }
    switch (type) {
      case OUTCOME:
        targetGroup = params.dropAreaPosition[0];
        _ref4 = this.state.getIn(['_meta', 'currentDragPosition']) || this.state.getIn(['_meta', 'startDragPosition']), sourceGroup = _ref4[0], sourcePosition = _ref4[1];
        this.setState(this.state.withMutations(function(state) {
          var activeTab, outcomeObject, sourceOutcomes, sourceOutcomesPath, targetGroupOutcomesPath, targetOutcomes;
          activeTab = state.getIn(['_meta', 'activeTab']);
          sourceOutcomesPath = ['outcomesGenerationData', 'steps', activeTab, 'questionGroups', sourceGroup, 'outcomes'];
          outcomeObject = state.getIn(sourceOutcomesPath.concat([sourcePosition]));
          sourceOutcomes = state.getIn(sourceOutcomesPath);
          state.setIn(sourceOutcomesPath, sourceOutcomes.splice(sourcePosition, 1));
          targetGroupOutcomesPath = ['outcomesGenerationData', 'steps', activeTab, 'questionGroups', targetGroup, 'outcomes'];
          targetOutcomes = state.getIn(targetGroupOutcomesPath);
          state.setIn(targetGroupOutcomesPath, targetOutcomes.push(outcomeObject));
          return cleanUpDrag(state);
        }));
        break;
      case APPROVAL_TAB_OUTCOME:
        importance = params.importance, gIndex = params.gIndex, dragKey = params.dragKey;
        this.moveItem({
          hoverType: type,
          hoverImportance: importance,
          gIndex: gIndex,
          dragKey: dragKey
        });
    }
    return this.save();
  };

  OutcomesGenerationStore.prototype.onDrop = function(params) {
    var activeTab, draggedGroup, dropPosition, sourceGroup, targetGroup, type;
    dropPosition = this.state.getIn(['_meta', 'currentDragPosition']);
    if (dropPosition == null) {
      return;
    }
    type = params.type;
    activeTab = this.getActiveTab();
    sourceGroup = this.state.getIn(['_meta', 'startDragPosition'])[0];
    targetGroup = dropPosition[0];
    draggedGroup = this.state.getIn(['outcomesGenerationData', 'steps', activeTab, 'questionGroups', sourceGroup]);
    switch (type) {
      case OUTCOME:
        this.setState(cleanUpDrag(this.state));
        break;
      case QUESTION:
      case QGROUP:
        this.setState(this.state.withMutations(function(state) {
          var hasOutcomes, isLastQuestionInGroup, isSingleQuestionGroup;
          hasOutcomes = !draggedGroup.get('outcomes').isEmpty();
          isSingleQuestionGroup = type === QGROUP;
          isLastQuestionInGroup = type === QUESTION && draggedGroup.get('questions').isEmpty();
          if (hasOutcomes && isLastQuestionInGroup || isSingleQuestionGroup) {
            moveOutcomes(state, sourceGroup, targetGroup);
          }
          if (isSingleQuestionGroup || isLastQuestionInGroup) {
            deleteGroup(state, sourceGroup);
          }
          return cleanUpDrag(state);
        }));
    }
    return this.save();
  };

  OutcomesGenerationStore.prototype.onEndDrag = function(params) {
    if ((params != null ? params.type : void 0) === APPROVAL_TAB_OUTCOME) {
      return this.save();
    }
    return this.setState(cleanUpDrag(this.state));
  };

  OutcomesGenerationStore.prototype.onBeginDrag = function(params) {
    var startDragPosition;
    startDragPosition = (function() {
      switch (params.type) {
        case QUESTION:
          return [params.gIndex, params.qIndex];
        case OUTCOME:
          return [params.gIndex, params.oIndex];
        case QGROUP:
          return [params.gIndex, 0];
      }
    })();
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'stateSnapshot'], state.get('outcomesGenerationData'));
      return state.setIn(['_meta', 'startDragPosition'], startDragPosition);
    }));
  };

  OutcomesGenerationStore.prototype.onUndoItemMovement = function() {
    var stateSnapshot;
    stateSnapshot = this.state.getIn(['_meta', 'stateSnapshot']);
    if (stateSnapshot) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('outcomesGenerationData', stateSnapshot);
        return state.setIn(['_meta', 'currentDragPosition'], null);
      }));
    }
  };

  OutcomesGenerationStore.prototype.onCreateQuestionGroup = function() {
    this.setState(this.state.withMutations(function(state) {
      var activeTab, gIndex, qIndex, sourceGroupPath, sourceGroupsPath, sourceOutcomes, sourceOutcomesPath, sourceQuestion, sourceQuestions, sourceQuestionsPath, _ref3;
      _ref3 = state.getIn(['_meta', 'startDragPosition']), gIndex = _ref3[0], qIndex = _ref3[1];
      activeTab = state.getIn(['_meta', 'activeTab']);
      sourceGroupsPath = ['outcomesGenerationData', 'steps', activeTab, 'questionGroups'];
      sourceGroupPath = sourceGroupsPath.concat([gIndex]);
      sourceQuestionsPath = sourceGroupPath.concat(['questions']);
      sourceOutcomesPath = sourceGroupPath.concat(['outcomes']);
      sourceQuestions = state.getIn(sourceQuestionsPath);
      sourceQuestion = sourceQuestions.get(qIndex);
      if (state.getIn(sourceGroupPath.concat(['name'])) != null) {
        state.setIn(sourceQuestionsPath, sourceQuestions.splice(qIndex, 1));
        if (state.getIn(sourceQuestionsPath).size === 0) {
          sourceOutcomes = state.getIn(sourceOutcomesPath);
          deleteGroup(state, gIndex);
        } else {
          sourceOutcomes = Immutable.List();
        }
        state.setIn(sourceGroupsPath, state.getIn(sourceGroupsPath).push(createQuestionGroupObject(sourceQuestion, sourceOutcomes)));
      } else {
        state.setIn(sourceGroupPath.concat(['name']), 'New group');
      }
      return state.setIn(sourceGroupsPath, sortQuestionGroups(state.getIn(sourceGroupsPath)));
    }));
    return this.save();
  };

  OutcomesGenerationStore.prototype.onUpdateQuestionGroup = function(params) {
    var activeTab, gIndex, titleText;
    gIndex = params.gIndex, titleText = params.titleText;
    activeTab = this.getActiveTab();
    this.setState(this.state.setIn(['outcomesGenerationData', 'steps', activeTab, 'questionGroups', gIndex, 'name'], titleText));
    return this.save();
  };

  OutcomesGenerationStore.prototype.onDeleteQuestionGroup = function(gIndex) {
    var activeTab, group, groupOutcomes, groupQuestions, groupsPath;
    activeTab = this.getActiveTab();
    groupsPath = ['outcomesGenerationData', 'steps', activeTab, 'questionGroups'];
    group = this.state.getIn(groupsPath.concat([gIndex]));
    groupQuestions = group.get('questions');
    groupOutcomes = group.get('outcomes');
    if (groupQuestions.size) {
      this.setState(this.state.withMutations(function(state) {
        var newGroups;
        newGroups = groupQuestions.map(function(question) {
          return createQuestionGroupObject(question, groupOutcomes, {
            noName: true
          });
        });
        state = deleteGroup(state, gIndex);
        return state.setIn(groupsPath, sortQuestionGroups(state.getIn(groupsPath).concat(newGroups)));
      }));
    } else {
      this.setState(deleteGroup(this.state, gIndex));
    }
    return this.save();
  };

  OutcomesGenerationStore.prototype.onReleaseQuestionFromGroup = function(params) {
    var activeTab, fromGindex, fromGroupPath, fromGroupQuestionsPath, questionId, sourceGroupsPath;
    fromGindex = params.gIndex, questionId = params.id;
    activeTab = this.getActiveTab();
    sourceGroupsPath = ['outcomesGenerationData', 'steps', activeTab, 'questionGroups'];
    fromGroupPath = sourceGroupsPath.concat(fromGindex);
    fromGroupQuestionsPath = fromGroupPath.concat('questions');
    this.setState(this.state.withMutations(function(state) {
      var fromGroup, isLastQuestion, question, sourceGroups;
      fromGroup = state.getIn(fromGroupPath);
      isLastQuestion = state.getIn(fromGroupQuestionsPath).size === 1;
      if (isLastQuestion) {
        state.setIn(fromGroupPath, fromGroup.set('name', null));
      } else {
        question = state.getIn(fromGroupQuestionsPath).find(function(q) {
          return q.get('_id') === questionId;
        });
        state.setIn(fromGroupQuestionsPath, fromGroup.get('questions').filterNot(function(q) {
          return q.get('_id') === questionId;
        }));
        sourceGroups = state.getIn(sourceGroupsPath);
        state.setIn(sourceGroupsPath, sourceGroups.push(createQuestionGroupObject(question, null, {
          noName: true
        })));
      }
      state.setIn(sourceGroupsPath, sortQuestionGroups(state.getIn(sourceGroupsPath)));
      return cleanUpDrag(state);
    }));
    return this.save();
  };

  OutcomesGenerationStore.prototype.onAddOutcome = function(params) {
    var activeTab, groups, name;
    groups = params.groups, name = params.name;
    activeTab = this.getActiveTab();
    this.setState(this.state.withMutations((function(_this) {
      return function(state) {
        groups.forEach(function(gIndex) {
          var outcomes, outcomesPath;
          outcomesPath = _this._getActiveTabOutcomesPath(gIndex);
          outcomes = state.getIn(outcomesPath);
          return state.setIn(outcomesPath, outcomes.push(createOutcomeObject(name, activeTab)));
        });
        return state.setIn(['_meta', 'editingOutcome'], null);
      };
    })(this)));
    return this.save();
  };

  OutcomesGenerationStore.prototype.onEditOutcome = function(params) {
    return this.setState(this.state.setIn(['_meta', 'editingOutcome'], params));
  };

  OutcomesGenerationStore.prototype.onUpdateOutcome = function(params) {
    var deleteOutcome, gIndex, name, oIndex, outcomesPath, _ref3;
    _ref3 = this.state.getIn(['_meta', 'editingOutcome']), gIndex = _ref3.gIndex, oIndex = _ref3.oIndex;
    name = params.name, deleteOutcome = params.deleteOutcome;
    outcomesPath = this._getActiveTabOutcomesPath(gIndex);
    this.setState(this.state.withMutations(function(state) {
      var outcome, outcomePath, outcomes;
      if (deleteOutcome) {
        outcomes = state.getIn(outcomesPath);
        state.setIn(outcomesPath, outcomes.splice(oIndex, 1));
      } else {
        outcomePath = outcomesPath.concat(oIndex);
        outcome = state.getIn(outcomePath);
        state.setIn(outcomePath, outcome.set('name', name.trim()));
      }
      return state.setIn(['_meta', 'editingOutcome'], null);
    }));
    return this.save();
  };

  OutcomesGenerationStore.prototype.onChangeTab = function(tabName) {
    return this.setState(this.state.setIn(['_meta', 'activeTab'], tabName));
  };

  OutcomesGenerationStore.prototype.onSaveAdminComment = function(params) {
    var gIndex, idOrKey, newText, oIndex, outcomesPath, step, updatePath;
    gIndex = params.gIndex, oIndex = params.oIndex, newText = params.newText, step = params.step, idOrKey = params.idOrKey;
    outcomesPath = this._getActiveTabOutcomesPath(gIndex);
    if (idOrKey && !oIndex) {
      oIndex = this.state.getIn(outcomesPath).findIndex(function(o) {
        return o.get('key') === idOrKey;
      });
    }
    updatePath = outcomesPath.concat(oIndex);
    this.setState(this.state.updateIn(updatePath, function(outcome) {
      return outcome.setIn(['adminComments', step], newText);
    }));
    return this.save();
  };

  OutcomesGenerationStore.prototype.onUpdateOutcomeImportance = function(params) {
    var gIndex, importance, outcomeKey, outcomesPath;
    gIndex = params.gIndex, outcomeKey = params.outcomeKey, importance = params.importance;
    outcomesPath = this._getActiveTabOutcomesPath(gIndex);
    this.setState(this.state.updateIn(outcomesPath, function(outcomes) {
      return outcomes.map(function(o) {
        if (o.get('key') !== outcomeKey) {
          return o;
        }
        return o.set('importance', importance);
      });
    }));
    return this.save();
  };

  OutcomesGenerationStore.prototype.onConfirmUpdateNotification = function() {
    return this.setState(this.state.setIn(['_meta', 'dataUpdateNotify'], false));
  };

  OutcomesGenerationStore.prototype.onShowCommentsExport = function() {
    return this.setState(this.state.setIn(['_meta', 'isExportingComments'], true));
  };

  OutcomesGenerationStore.prototype.onHideCommentsExport = function() {
    return this.setState(this.state.setIn(['_meta', 'isExportingComments'], false));
  };

  OutcomesGenerationStore.prototype.onSetDisagreementResolved = function(data) {
    var gIndex, key, newOutcomes, oIndex, outcomes, outcomesPath, value;
    key = data.key, gIndex = data.gIndex, value = data.value;
    outcomesPath = this._getActiveTabOutcomesPath(gIndex);
    outcomes = this.state.getIn(outcomesPath);
    oIndex = outcomes.findIndex(function(o) {
      return o.get('key') === key;
    });
    newOutcomes = outcomes.setIn([oIndex, 'importanceDisagreementResolved'], value);
    return this.setState(this.state.setIn(outcomesPath, newOutcomes));
  };

  OutcomesGenerationStore.prototype.onSaveTodos = function(data) {
    var activeTab, key, todos;
    key = data.key, todos = data.todos;
    activeTab = this.getActiveTab();
    this.setState(this.state.setIn(['outcomesGenerationData', 'todos', activeTab, key], todos));
    return this.save();
  };

  OutcomesGenerationStore.prototype.onMoveApprovedOutcomes = function() {
    return this.setState(this.state.setIn(['_meta', 'movingOutcomes'], true));
  };

  OutcomesGenerationStore.prototype.onMoveApprovedOutcomesSuccess = function() {
    return this.setState(this.state.setIn(['_meta', 'movingOutcomes'], false));
  };

  OutcomesGenerationStore.prototype.onMoveApprovedOutcomesError = function(err) {
    this.setState(this.state.setIn(['_meta', 'movingOutcomes'], false));
    return errorHandler(err);
  };

  OutcomesGenerationStore.prototype.save = function() {
    var doc, docId, projectId;
    projectId = getProjectId();
    docId = 'outcomes_generation';
    doc = this.state.get('outcomesGenerationData').toJS();
    if (doc._id == null) {
      doc._id = docId;
    }
    return this.getInstance().save(projectId, doc);
  };

  OutcomesGenerationStore.prototype.proceedToNextStep = function(results) {
    var currentStep, currentStepPath, membersSentTo, nextStep, nextStepPath, proposalStep;
    membersSentTo = this.state.getIn(['_meta', 'sendingToMembers']);
    currentStep = this.getCurrentStep();
    nextStep = getNextStepName(currentStep);
    proposalStep = OUTCOMES_GENERATION_STEPS[4];
    currentStepPath = ['outcomesGenerationData', 'steps', currentStep];
    nextStepPath = ['outcomesGenerationData', 'steps', nextStep];
    this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'closingStep'], false);
      state.setIn(['_meta', 'sendingToMembers'], null);
      state.setIn(['outcomesGenerationData', 'currentStep'], nextStep);
      state.setIn(currentStepPath.concat(['closed']), true);
      state.setIn(['_meta', 'activeTab'], nextStep);
      state.setIn(nextStepPath.concat(['memberIds']), membersSentTo);
      return state.setIn(nextStepPath.concat(['questionGroups']), nextStep === proposalStep ? state.getIn(currentStepPath.concat(['questionGroups'])).map(function(qGroup) {
        return qGroup.set('outcomes', qGroup.get('outcomes').map(function(outcome) {
          var medianRating;
          medianRating = calcMedianRating(outcome.get('ratings', Immutable.List()));
          return outcome.set('medianRating', medianRating).set('importance', getImportanceForRating(medianRating));
        }));
      }) : !_.isEmpty(results) ? sortQuestionGroups(Immutable.fromJS(results.questionGroups)) : state.getIn(currentStepPath.concat(['questionGroups'])));
    }));
    return this.save();
  };

  OutcomesGenerationStore.prototype.moveItem = function(params) {
    var activeTab, dragIndex, dragKey, dragOutcome, draggedItem, gIndex, hoverImportance, hoverIndex, hoverKey, hoverType, itemsCollection, newOutcomes, outcomes, outcomesPath, sourceGroup, sourcePath, sourcePosition, startDragGroup, targetGroup, targetPath, targetPosition, _ref3;
    hoverType = params.hoverType;
    if (hoverType === APPROVAL_TAB_OUTCOME) {
      gIndex = params.gIndex, hoverKey = params.hoverKey, dragKey = params.dragKey, hoverImportance = params.hoverImportance;
      outcomesPath = this._getActiveTabOutcomesPath(gIndex);
      outcomes = this.state.getIn(outcomesPath);
      dragIndex = outcomes.findIndex(function(o) {
        return dragKey === o.get('key');
      });
      hoverIndex = hoverKey != null ? outcomes.findIndex(function(o) {
        return hoverKey === o.get('key');
      }) : 0;
      dragOutcome = outcomes.get(dragIndex);
      newOutcomes = outcomes["delete"](dragIndex).splice(hoverIndex, 0, dragOutcome.set('importance', hoverImportance));
      return this.setState(this.state.setIn(outcomesPath, newOutcomes));
    } else {
      targetGroup = params.hoverGindex, targetPosition = params.hoverItemIndex;
      _ref3 = this.state.getIn(['_meta', 'currentDragPosition']) || this.state.getIn(['_meta', 'startDragPosition']), sourceGroup = _ref3[0], sourcePosition = _ref3[1];
      itemsCollection = (function() {
        switch (hoverType) {
          case QUESTION:
            return 'questions';
          case OUTCOME:
            return 'outcomes';
          default:
            return 'questions';
        }
      })();
      startDragGroup = this.state.getIn(['_meta', 'startDragPosition'])[0];
      activeTab = this.getActiveTab();
      sourcePath = ['outcomesGenerationData', 'steps', activeTab, 'questionGroups', sourceGroup, itemsCollection];
      targetPath = ['outcomesGenerationData', 'steps', activeTab, 'questionGroups', targetGroup, itemsCollection];
      draggedItem = this.state.getIn(sourcePath.concat(sourcePosition));
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['_meta', 'currentDragPosition'], [targetGroup, targetPosition]);
        if (sourceGroup !== startDragGroup || params.type !== QGROUP) {
          state.setIn(sourcePath, state.getIn(sourcePath).splice(sourcePosition, 1));
        }
        return state.setIn(targetPath, state.getIn(targetPath).splice(targetPosition, 0, draggedItem));
      }));
    }
  };

  OutcomesGenerationStore.prototype._getActiveTabOutcomesPath = function(gIndex) {
    var activeTab;
    activeTab = this.getActiveTab();
    return ['outcomesGenerationData', 'steps', activeTab, 'questionGroups', gIndex, 'outcomes'];
  };

  OutcomesGenerationStore.prototype.getStepsData = function() {
    return this.state.getIn(['outcomesGenerationData', 'steps']);
  };

  OutcomesGenerationStore.prototype.getActiveTab = function() {
    return this.state.getIn(['_meta', 'activeTab']);
  };

  OutcomesGenerationStore.prototype.getCurrentStep = function() {
    return this.state.getIn(['outcomesGenerationData', 'currentStep']);
  };

  OutcomesGenerationStore.prototype.getNextStep = function() {
    var currentStep;
    currentStep = this.getCurrentStep();
    return getNextStepName(currentStep);
  };

  OutcomesGenerationStore.prototype.getTodos = function() {
    return this.state.getIn(['outcomesGenerationData', 'todos'], Immutable.Map());
  };

  OutcomesGenerationStore.prototype.hasItemDropped = function() {
    return this.state.getIn(['_meta', 'startDragPosition']) == null;
  };

  OutcomesGenerationStore.prototype.getDraggingQuestionPosition = function() {
    return this.state.getIn(['_meta', 'currentDragPosition']);
  };

  OutcomesGenerationStore.prototype.getEditingOutcome = function() {
    return this.state.getIn(['_meta', 'editingOutcome']);
  };

  OutcomesGenerationStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  OutcomesGenerationStore.prototype.isFetchingResults = function() {
    return this.state.getIn(['_meta', 'fetchingResults']);
  };

  OutcomesGenerationStore.prototype.isExportingComments = function() {
    return this.state.getIn(['_meta', 'isExportingComments'], false);
  };

  OutcomesGenerationStore.prototype.isMovingOutcomes = function() {
    return this.state.getIn(['_meta', 'movingOutcomes']);
  };

  OutcomesGenerationStore.prototype.isSendingToMembers = function() {
    return this.state.getIn(['_meta', 'sendingToMembers'], null) != null;
  };

  OutcomesGenerationStore.prototype.isClosingStep = function() {
    return this.state.getIn(['_meta', 'closingStep'], false);
  };

  OutcomesGenerationStore.prototype.hasDataUpdated = function() {
    return this.state.getIn(['_meta', 'dataUpdateNotify'], false);
  };

  OutcomesGenerationStore.prototype.getStepQuestionGroupsWithComments = function() {
    var activeTab, questionGroups;
    activeTab = this.getActiveTab();
    questionGroups = this.state.getIn(['outcomesGenerationData', 'steps', activeTab, 'questionGroups'], Immutable.List());
    return questionGroups.reduce(function(result, qGroup) {
      var outcomesWithComments;
      outcomesWithComments = qGroup.get('outcomes').filter(function(outcome) {
        var allComments, _ref3;
        allComments = (_ref3 = outcome.get('memberComments')) != null ? _ref3.flatten() : void 0;
        return allComments && !allComments.isEmpty();
      });
      if (outcomesWithComments.isEmpty()) {
        return result;
      }
      return result.push(qGroup.set('outcomes', outcomesWithComments));
    }, Immutable.List());
  };

  return OutcomesGenerationStore;

})();

module.exports = alt.createStore(immutable(OutcomesGenerationStore), 'OutcomesGenerationStore');
