var Migration, VotingSubModule, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = VotingSubModule = (function(_super) {
  __extends(VotingSubModule, _super);

  function VotingSubModule() {
    VotingSubModule.__super__.constructor.call(this, false);
  }

  VotingSubModule.prototype.up = function(project, colls) {
    var adminModule, submodule;
    VotingSubModule.__super__.up.apply(this, arguments);
    submodule = {
      id: 'voting',
      disabled: false
    };
    adminModule = _(project.get('modules')).findWhere({
      id: 'administration'
    });
    if (adminModule != null) {
      adminModule.submodules.splice(1, 0, submodule);
    }
    return project.save();
  };

  return VotingSubModule;

})(Migration);
