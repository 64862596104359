var DB_VIEW, Migration, QUESTIONS_DB_VIEW, ReferencesIdsChange, W, getEntityMapMapper, getKeyValObject, getNewId, getReferenceIDHash, markDeleted, mediator, migrationName, refDocPrefix, renameDocs, updateDocumentSections, updateModelBoundReferences, updateRecommendationDocs, _ref,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

_ref = require('lib/references_utils'), DB_VIEW = _ref.DB_VIEW, getReferenceIDHash = _ref.getReferenceIDHash;

QUESTIONS_DB_VIEW = require('lib/questions_helper').QUESTIONS_DB_VIEW;

getKeyValObject = require('base/lib/utils').getKeyValObject;

refDocPrefix = require('lib/doc_prefixes').reference;

W = require('when');

mediator = require('mediator');

migrationName = 'references_ids_change';

getNewId = function(refDoc) {
  var docCopy, idHash;
  docCopy = _.omit(refDoc, '_id', '_rev', 'rev_author');
  idHash = getReferenceIDHash(docCopy);
  return "" + refDocPrefix + idHash;
};

renameDocs = function(oldToNewIdsMap) {
  return function(refDoc) {
    var newDoc, newId;
    newDoc = _.omit(refDoc, '_rev');
    newId = oldToNewIdsMap[refDoc._id];
    return _.extend({}, newDoc, {
      '_id': newId,
      rev_author: migrationName
    });
  };
};

markDeleted = function(refDoc) {
  return _.extend({}, refDoc, {
    '_deleted': true,
    rev_author: migrationName
  });
};

updateModelBoundReferences = function(oldToNewIdsMap) {
  return function(model) {
    var property, referenceIds, references, referencesOrder, updatedIds, updatedReferences;
    references = model.get('references');
    referencesOrder = model.get('referencesOrder');
    if (_.isEmpty(references) && _.isEmpty(referencesOrder)) {
      return;
    }
    if (!_.isEmpty(references)) {
      updatedReferences = {};
      for (property in references) {
        referenceIds = references[property];
        if (!_.isEmpty(referenceIds)) {
          updatedIds = _.chain(referenceIds).map(function(id) {
            return oldToNewIdsMap[id];
          }).compact().value();
          if (!_.isEmpty(updatedIds)) {
            updatedReferences[property] = updatedIds;
          }
        }
      }
      model.set('references', updatedReferences);
    }
    if (!_.isEmpty(referencesOrder)) {
      model.set('referencesOrder', _.map(referencesOrder, function(id) {
        return oldToNewIdsMap[id];
      }));
    }
    return model.save();
  };
};

getEntityMapMapper = function(oldToNewIdsMap) {
  return function(entityRecord) {
    if (entityRecord.get('type') === 'REFERENCE') {
      return entityRecord.updateIn(['data', 'refIds'], function(refIds) {
        return refIds.map(function(id) {
          return oldToNewIdsMap[id];
        });
      });
    } else {
      return entityRecord;
    }
  };
};

updateRecommendationDocs = function(etdIds, projectId, oldToNewIdsMap) {
  return mediator.services.storePersistenceAdapter.fetch(projectId, etdIds).then(function(_arg) {
    var entityMapMapper, entityMapPath, researchEvidencesPath, rows, updatedEtdDocs;
    rows = _arg.rows;
    researchEvidencesPath = ['templateData', 'assessment', 'researchEvidences'];
    entityMapPath = ['content', 'entityMap'];
    entityMapMapper = getEntityMapMapper(oldToNewIdsMap);
    updatedEtdDocs = _.reduce(rows, function(updatedDocs, _arg1) {
      var doc, maybeUpdatedDoc;
      doc = _arg1.doc;
      doc = Immutable.fromJS(doc);
      maybeUpdatedDoc = doc.updateIn(researchEvidencesPath, function(researchEvidences) {
        return researchEvidences.map(function(sectionData) {
          var entityMap;
          entityMap = sectionData.getIn(entityMapPath, Immutable.Map());
          if (entityMap.isEmpty()) {
            return sectionData;
          } else {
            return sectionData.updateIn(entityMapPath, function(entityMap) {
              return entityMap.map(entityMapMapper);
            });
          }
        });
      });
      if (!maybeUpdatedDoc.equals(doc)) {
        updatedDocs.push(maybeUpdatedDoc.toJS());
      }
      return updatedDocs;
    }, []);
    if (!_.isEmpty(updatedEtdDocs)) {
      return mediator.services.storePersistenceAdapter.bulkDocs(projectId, updatedEtdDocs);
    }
  });
};

updateDocumentSections = function(projectId, oldToNewIdsMap) {
  return mediator.services.storePersistenceAdapter.fetch(projectId, 'document_sections').then(function(doc) {
    var entityMap, entityMapMapper, entityMapPath, updatedDoc, updatedEntityMap;
    doc = Immutable.fromJS(doc);
    entityMapPath = ['content', 'entityMap'];
    entityMap = doc.getIn(entityMapPath, Immutable.Map());
    if (entityMap.isEmpty()) {
      return;
    }
    entityMapMapper = getEntityMapMapper(oldToNewIdsMap);
    updatedEntityMap = entityMap.map(entityMapMapper);
    if (!updatedEntityMap.equals(entityMap)) {
      updatedDoc = doc.setIn(entityMapPath, updatedEntityMap);
      return mediator.services.storePersistenceAdapter.save(projectId, updatedDoc.toJS());
    }
  })["catch"](function(err) {
    if (err.status === 404) {
      return;
    }
    throw err;
  });
};

module.exports = ReferencesIdsChange = (function(_super) {
  __extends(ReferencesIdsChange, _super);

  function ReferencesIdsChange() {
    ReferencesIdsChange.__super__.constructor.call(this, false);
  }

  ReferencesIdsChange.prototype.up = function(project, colls) {
    var projectId, questions;
    projectId = project.id;
    questions = colls.questions;
    return mediator.services.storePersistenceAdapter.fetchWithView(projectId, 'references', DB_VIEW).then((function(_this) {
      return function(references) {
        var deletedDocs, oldToNewIdsMap, refernecesToChange, renamedDocs;
        refernecesToChange = _.reject(references, function(refDoc) {
          return _.str.startsWith(refDoc._id, refDocPrefix);
        });
        if (_.isEmpty(refernecesToChange)) {
          return;
        }
        oldToNewIdsMap = _.reduce(refernecesToChange, function(acc, refDoc) {
          acc[refDoc._id] = getNewId(refDoc);
          return acc;
        }, {});
        renamedDocs = _.chain(refernecesToChange).map(renameDocs(oldToNewIdsMap)).groupBy('_id').values().map(_.first).value();
        deletedDocs = _.map(refernecesToChange, markDeleted);
        return mediator.services.storePersistenceAdapter.bulkDocs(projectId, _.union(deletedDocs, renamedDocs)).then(function() {
          var etdIds, modelUpdater;
          modelUpdater = updateModelBoundReferences(oldToNewIdsMap);
          questions.forEach(function(question) {
            var outcomes;
            outcomes = question.get('outcomes');
            outcomes.forEach(modelUpdater);
            return modelUpdater(question);
          });
          etdIds = _.flatten(questions.pluck('recommendationIds'));
          return W.all([updateRecommendationDocs(etdIds, projectId, oldToNewIdsMap), updateDocumentSections(projectId, oldToNewIdsMap)]);
        });
      };
    })(this));
  };

  return ReferencesIdsChange;

})(Migration);
