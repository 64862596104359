Button = require 'components/common/button'
EllipsizedText = require 'components/common/ellipsized_text'
GdtEditor = Editor.Editor
{ instanceOf, func, string, number } = PropTypes

Question = (props) ->
  if props.renderMode is 'printout'
    <div><b>{props.index + 1}. {props.question}</b></div>
  else
    <EllipsizedText
      text={props.question}
      height={40}
      style={cursor: 'pointer'}
      onClick={props.onToggle}
    />

RelatedRecommendation = createReactClass

  displayName: 'RelatedRecommendation'

  propTypes:
    index: number
    onToggle: func.isRequired
    relatedRecommendation: instanceOf(Immutable.Map).isRequired
    renderMode: string
    question: instanceOf(Immutable.Map).isRequired

  render: ->
    { question, index, relatedRecommendation, onToggle, expanded, renderMode } = @props

    btnClassName = if expanded then "collapse" else "expand"

    <div className="related-recommendation">
      <div className="related-recommendation__question">
        <div className="caption">
          <Question
            index={index}
            renderMode={renderMode}
            question={question.get('question')}
            onToggle={onToggle}
          />
        </div>
        {renderMode isnt 'printout' and
          <Button
            className={btnClassName}
            onClick={onToggle}
          />
        }
      </div>
      {expanded and
        <div className="related-recommendation__content">
          {renderMode isnt 'printout' and
            <div className="uppercase">{relatedRecommendation.get('description')}</div>
          }
          <div className="related-recommendation__editor">
            <GdtEditor editorContent={relatedRecommendation.get('content')} readOnly />
          </div>
        </div>
      }
    </div>

module.exports = RelatedRecommendation
