Translation = require 'components/mixins/translation'
EtdTemplatesActions = require 'actions/etd_templates_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

TemplateName = createReactClass
  displayName: 'TemplateName'

  propTypes:
    templateName: PropTypes.string.isRequired
    templateChanged: PropTypes.bool
    autoUpdateName: PropTypes.bool

  mixins: [Translation('settings:etd_templates'), CustomRenderMixin]

  updateName: (e) ->
    EtdTemplatesActions.updateName e.currentTarget.value

  componentWillReceiveProps: (nextProps) ->
    if not @props.templateChanged and nextProps.templateChanged and @props.autoUpdateName
      EtdTemplatesActions.updateName "#{@props.templateName}_#{@i18n 'template_changed_appendix'}"

  render: ->
    <div className='block template-name-block'>
      <p className='template-name'>{@i18n 'template_name'}</p>
      {if @props.nameEditable
        <input
          onChange={@updateName}
          value={@props.templateName} />
      else
        <span>{@props.templateName}</span>
      }
    </div>

module.exports = TemplateName
