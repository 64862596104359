var EtdFixAdditionalQuestionsInTranslations, Migration, TRANSLATED_ADDITIONAL_QUESTION_TO_UNTRANSLATED, TRANSLATED_VALUES_TO_CHANGE, fixCriteria, mediator,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

mediator = require('mediator');

TRANSLATED_ADDITIONAL_QUESTION_TO_UNTRANSLATED = {
  'recursos': 'resources',
  'desenlaces': 'outcomes',
  '全般': 'general',
  '医療資源': 'resources',
  'アウトカム': 'outcomes',
  'üldine': 'general',
  'ressursid': 'resources',
  'tulemusnäitajad': 'outcomes'
};

TRANSLATED_VALUES_TO_CHANGE = _.keys(TRANSLATED_ADDITIONAL_QUESTION_TO_UNTRANSLATED);

fixCriteria = function(recommendation) {
  var criteria;
  criteria = recommendation.templateData.assessment.criteria;
  _.keys(criteria).forEach(function(key) {
    var questionsType, _ref;
    questionsType = (_ref = criteria[key].details) != null ? _ref.questionsType : void 0;
    if ((questionsType != null) && __indexOf.call(TRANSLATED_VALUES_TO_CHANGE, questionsType) >= 0) {
      return recommendation.templateData.assessment.criteria[key].details.questionsType = TRANSLATED_ADDITIONAL_QUESTION_TO_UNTRANSLATED[questionsType];
    }
  });
  return recommendation;
};

module.exports = EtdFixAdditionalQuestionsInTranslations = (function(_super) {
  __extends(EtdFixAdditionalQuestionsInTranslations, _super);

  function EtdFixAdditionalQuestionsInTranslations() {
    EtdFixAdditionalQuestionsInTranslations.__super__.constructor.call(this, false);
  }

  EtdFixAdditionalQuestionsInTranslations.prototype.up = function(project, colls) {
    var recommendationIds;
    EtdFixAdditionalQuestionsInTranslations.__super__.up.apply(this, arguments);
    recommendationIds = colls.questions.reduce(function(recommendationIds, q) {
      return recommendationIds.concat(q.get('recommendationIds'));
    }, []);
    return mediator.services.storePersistenceAdapter.fetch(project.id, recommendationIds).then(function(_arg) {
      var rows, updatedEtdDocs;
      rows = _arg.rows;
      updatedEtdDocs = rows.map(function(row) {
        var doc;
        doc = row.doc;
        return fixCriteria(doc);
      });
      return mediator.services.storePersistenceAdapter.bulkDocs(project.id, updatedEtdDocs);
    })["catch"](function(err) {
      throw err;
    });
  };

  return EtdFixAdditionalQuestionsInTranslations;

})(Migration);
