CommentButton = require 'components/common/empty_comment_button'
QuestionRatingRow = require 'components/scope/questions/question_rating_row'
{ QUESTION_GENERATION_STEPS } = require 'lib/questions_helper'
CommentsBlocks = require 'components/scope/questions/grouped_comments_blocks'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
QuestionCommentsMixin = require 'components/mixins/question_comments_mixin'
Translation = require 'components/mixins/translation'

QuestionRatingsWithComments = createReactClass
  displayName: 'QuestionRatingsWithComments'

  propTypes:
    data: PropTypes.object.isRequired
    membersMap: PropTypes.object.isRequired

  mixins: [
    CustomRenderMixin
    QuestionCommentsMixin
    Translation('scope:questions.proposal')
  ]

  _getMembersComments: ->
    @props.data.get 'memberComments', Immutable.Map()

  _getAdminComments: ->
    @props.data.get 'adminComments'

  renderCommentsToggle: ->
    buttonClass = classNames 'comment-blank-hide': @state.showingComments,
      'comment-blank': not @state.showingComments

    <CommentButton
      className={buttonClass}
      title={@i18n '../../outcomes.view_members_comments'}
      onClick={@toggleComments}
    />

  render: ->
    tBodyClass = classNames @props.data.get('approvalStatus'), 'with-comments'

    <tbody className={tBodyClass}>
      <QuestionRatingRow
        id={@props.data.get 'id'}
        question={@props.data.get 'question'}
        medianRating={@props.data.get 'medianRating'}
        approvalStatus={@props.data.get 'approvalStatus'}
        onChangeApprovalStatus={@props.onChangeApprovalStatus}
        readOnly={@props.readOnly}
        commentToggle={@renderCommentsToggle()}
      />
      {if @state.showingComments
        <tr>
          <td className='all-phases-comments' colSpan={6}>
            <CommentsBlocks
              itemIdOrKey={@props.data.get 'id'}
              membersComments={@_getMembersComments()}
              adminComments={@_getAdminComments()}
              membersMap={@props.membersMap}
              noCollapsePhase={QUESTION_GENERATION_STEPS[3]}
              onApplyAdminComment={@props.onApplyAdminComment}
              readOnly={@props.readOnly}
            />
          </td>
        </tr>
      }
    </tbody>

module.exports = QuestionRatingsWithComments
