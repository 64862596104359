Button = require './button'
ListItem = require './list_item'
List = require './list'
Label = require './label'
RadioInput = require './radio_input'
Translation = require '../mixins/translation'
Collapsible = require './collapsible'
mediator = require 'mediator'
utils = require 'base/lib/utils'

optionPropType = PropTypes.shape
  label: PropTypes.string.isRequired
  value: PropTypes.string.isRequired

ExportOptionsList = createReactClass
  displayName: 'ExportOptionsList'

  mixins: [
    Translation('projects:export_dialog')
  ]

  propTypes:
    name: PropTypes.string.isRequired
    label: PropTypes.string.isRequired
    optionsList: PropTypes.arrayOf optionPropType
    advancedOptionsList: PropTypes.arrayOf optionPropType

  getDefaultProps: ->
    advancedOptionsList: []

  _renderListOfOptions: (options) ->
    <div className="formats-list">
      {options.map (optionSpec, idx) =>
        { label, value } = optionSpec

        <Label htmlFor={value} key={idx}>
          <RadioInput id={value} name={@props.name} value={value} onChange={@props.onSelect} />
          <span>{label}</span>
        </Label>
      }
    </div>

  _renderAdvancedOptions: ->
    return false if not @props.advancedOptionsList or @props.advancedOptionsList.length <= 0
    <Collapsible caption={@i18n 'advanced'} containerClass='advanced-choose-format'>
      {@_renderListOfOptions @props.advancedOptionsList}
    </Collapsible>

  render: ->
    <div>
      <label className='choose-format-title'>{@props.label}</label>
      {@_renderListOfOptions @props.optionsList}
      {@_renderAdvancedOptions()}
    </div>

ExportDialog = createReactClass

  displayName: "ExportDialog"

  mixins: [
    Translation('projects:export_dialog')
  ]

  getInitialState: ->
    isDownloading: false

  getDefaultProps: ->
    outputFormats: ['html', 'pdf', 'doc']
    advancedOutputFormats: []
    additionalOptions: []

  _getRequiredOptionsNames: ->
    requiredAdditionalOptions = @props.additionalOptions
      .filter (o) -> o.isRequired
      .map (o) -> o.name

    ['outputFormat'].concat requiredAdditionalOptions

  _isExportDisabled: ->
    return true if @state.isDownloading
    not @_getRequiredOptionsNames().every (optionName) => Boolean @state[optionName]

  _prepareOutputOptions: (formats) ->
    formats.map (option, idx) =>
      label: @i18n "types.#{option}"
      value: option

  _getOutputOptions: ->
    @_prepareOutputOptions @props.outputFormats

  _getAdvancedOutputOptions: ->
    @_prepareOutputOptions @props.advancedOutputFormats

  onOptionSelect: (optionName) -> (evt) =>
    @setState utils.getKeyValObject optionName, evt.target.value

  onDownload: ->
    @setState isDownloading: true
    @props.onDownload _.omit @state, 'isDownloading'

  _renderAdditionalOptions: ->
    return false unless @props.additionalOptions

    @props.additionalOptions.map (spec, idx) =>
      { label, optionsList, name } = spec

      <ExportOptionsList
        key={idx}
        name={name}
        label={label}
        optionsList={optionsList}
        onSelect={@onOptionSelect name}
      />

  render: ->
    downloadBtnClass = classNames 'btn btn-block btn-success download',
      loading: @state.isDownloading

    <div className="export-dialog">
      <ExportOptionsList
        name='outputFormat'
        label={@i18n "choose_export_format"}
        optionsList={@_getOutputOptions()}
        advancedOptionsList={@_getAdvancedOutputOptions()}
        onSelect={@onOptionSelect 'outputFormat'}
      />
      {@_renderAdditionalOptions()}
      <div className="buttons">
        <div className="row mt-20">
          <div className="col-6">
            <Button
              className="btn btn-block btn-cancel"
              label={@i18n '/translation:actions.cancel'}
              onClick={@props.onClose}/>
          </div>
          <div className="col-6">
            <Button
              className={downloadBtnClass}
              label={@i18n '/translation:actions.export'}
              type="submit"
              disabled={@_isExportDisabled()}
              onClick={@onDownload}
            />
          </div>
        </div>
      </div>
    </div>

module.exports = ExportDialog
