CustomRenderMixin = require 'components/mixins/custom_render_mixin'

Outcome = createReactClass
  displayName: 'Outcome'
  mixins: [CustomRenderMixin]
  propTypes:
    outcomeData: PropTypes.object.isRequired

  render: ->
    <div className={@props.className}>
      <div className="outcome-child">
        {<span className='mark' /> if @props.marked}
        <span>{@props.outcomeData.get 'name'}</span>
        {@props.children}
      </div>
    </div>

module.exports = Outcome
