var sections;

sections = function() {
  return {
    chapters: {
      sectionsOrder: [],
      sections: {
        context: {
          name: "CONTEXTO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Em 28 de abril de 2011, foi publicada a Lei n° 12.401 que dispõe sobre a assistência terapêutica e a incorporação de tecnologias em saúde no âmbito do SUS. Esta lei é um marco para o SUS, pois define os critérios e prazos para a incorporação de tecnologias no sistema público de saúde. Define, ainda, que o Ministério da Saúde, assessorado pela Comissão Nacional de Incorporação de Tecnologias – CONITEC, tem como atribuições a incorporação, exclusão ou alteração de novos medicamentos, produtos e procedimentos, bem como a constituição ou alteração de protocolo clínico ou de diretriz terapêutica.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "bifnp",
                    "text": "Tendo em vista maior agilidade, transparência e eficiência na análise dos processos de incorporação de tecnologias, a nova legislação fixa o prazo de 180 dias (prorrogáveis por mais 90 dias) para a tomada de decisão, bem como inclui a análise baseada em evidências, levando em consideração aspectos como eficácia, acurácia, efetividade e segurança da tecnologia, além da avaliação econômica comparativa dos benefícios e dos custos em relação às tecnologias já existentes.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "5pjqk",
                    "text": "A lei estabelece a exigência do registro prévio do produto na Agência Nacional de Vigilância Sanitária (ANVISA) para que este possa ser avaliado para a incorporação no SUS.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3abjr",
                    "text": "Para regulamentar a composição, as competências e o funcionamento da CONITEC foi publicado o Decreto n° 7.646 de 21 de dezembro de 2011. A estrutura de funcionamento da CONITEC é composta por Plenário e Secretaria-Executiva.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "161im",
                    "text": "O Plenário é o fórum responsável pela emissão de recomendações para assessorar o Ministério da Saúde na incorporação, exclusão ou alteração das tecnologias, no âmbito do SUS, na constituição ou alteração de protocolos clínicos e diretrizes terapêuticas e na atualização da Relação Nacional de Medicamentos Essenciais (RENAME), instituída pelo Decreto n° 7.508, de 28 de junho de 2011. É composto por treze membros, um representante de cada Secretaria do Ministério da Saúde – sendo o indicado pela Secretaria de Ciência, Tecnologia e Insumos Estratégicos (SCTIE) o presidente do Plenário – e um representante de cada uma das seguintes instituições: ANVISA, Agência Nacional de Saúde Suplementar - ANS, Conselho Nacional de Saúde - CNS, Conselho Nacional de Secretários de Saúde - CONASS, Conselho Nacional de Secretarias Municipais de Saúde - CONASEMS e Conselho Federal de Medicina - CFM.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "f99e1",
                    "text": "Cabem à Secretaria-Executiva – exercida pelo Departamento de Gestão e Incorporação de Tecnologias em Saúde (DGITS/SCTIE) – a gestão e a coordenação das atividades da CONITEC, bem como a emissão deste relatório final sobre a tecnologia, que leva em consideração as evidências científicas, a avaliação econômica e o impacto da incorporação da tecnologia no SUS.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "f8c1v",
                    "text": "Todas as recomendações emitidas pelo Plenário são submetidas à consulta pública (CP) pelo prazo de 20 dias, exceto em casos de urgência da matéria, quando a CP terá prazo de 10 dias. As contribuições e sugestões da consulta pública são organizadas e inseridas ao relatório final da CONITEC, que, posteriormente, é encaminhado para o Secretário de Ciência, Tecnologia e Insumos Estratégicos para a tomada de decisão. O Secretário da SCTIE pode, ainda, solicitar a realização de audiência pública antes da sua decisão.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "cv2t0",
                    "text": "Para a garantia da disponibilização das tecnologias incorporadas no SUS, está estipulado no Decreto n° 7.646/ 2011 o prazo de 180 dias para a efetivação de sua oferta à população brasileira.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }
          ]
        },
        presentation: {
          name: "APRESENTAÇÃO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Esse relatório se refere à análisecrítica das evidências científicas apresentadas em [data de entrada do processo - XX/XX/XXXX], pelo [DEMANDANTE] sobre eficácia, segurança, custo-efetividade e impacto orçamentário do [MEDICAMENTO], para [INDICAÇÃO PROPOSTA], visando a avaliar sua [INCORPORAÇÃO/EXCLUSÃO/ALTERAÇÃO] no Sistema Único de Saúde (SUS).",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 85,
                        "length": 43,
                        "style": "ITALIC"
                      }, {
                        "offset": 134,
                        "length": 12,
                        "style": "ITALIC"
                      }, {
                        "offset": 217,
                        "length": 14,
                        "style": "ITALIC"
                      }, {
                        "offset": 237,
                        "length": 21,
                        "style": "ITALIC"
                      }, {
                        "offset": 281,
                        "length": 34,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }
          ]
        },
        executiveSummary: {
          name: "RESUMO EXECUTIVO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Tecnologia: [NOME GENÉRICO DO MEDICAMENTO (NOME COMERCIAL DO MEDICAMENTO®)]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 10,
                        "style": "BOLD"
                      }, {
                        "offset": 12,
                        "length": 63,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "6b8gs",
                    "text": "Indicação: [Doença ou condição clínica]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 9,
                        "style": "BOLD"
                      }, {
                        "offset": 10,
                        "length": 29,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "a3ct0",
                    "text": "Demandante: [Demandante]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 10,
                        "style": "BOLD"
                      }, {
                        "offset": 12,
                        "length": 12,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "95h16",
                    "text": "Introdução: [resumo do que é a doença, aspectos epidemiológicos e tratamento disponível no SUS.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 10,
                        "style": "BOLD"
                      }, {
                        "offset": 12,
                        "length": 84,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "a0h9r",
                    "text": "Pergunta: [derivada da PICO]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 8,
                        "style": "BOLD"
                      }, {
                        "offset": 10,
                        "length": 18,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "fe3a6",
                    "text": "Evidências científicas: [resumo dos principais achados, agrupando os principais resultados dos estudos – apenas para desfechos primários e outros clinicamente relevantes.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 22,
                        "style": "BOLD"
                      }, {
                        "offset": 24,
                        "length": 147,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "83g5f",
                    "text": "Avaliação econômica: [Tipo de avaliação, resultado (RCEI) e limitações, quando relevantes.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 19,
                        "style": "BOLD"
                      }, {
                        "offset": 21,
                        "length": 70,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "4id9k",
                    "text": "Avaliação de impacto orçamentário: [resultados mínimo e máximo em 1 ano e 5 anos e limitações, quando relevantes.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 33,
                        "style": "BOLD"
                      }, {
                        "offset": 35,
                        "length": 79,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3rf31",
                    "text": "Experiência internacional: [relato sintético do cenário de recomendação em outros países]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 25,
                        "style": "BOLD"
                      }, {
                        "offset": 27,
                        "length": 62,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "93gum",
                    "text": "Monitoramento do horizonte tecnológico: [Informar se há ou não há tecnologias promissoras]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 41,
                        "style": "BOLD"
                      }, {
                        "offset": 39,
                        "length": 51,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "cqq5g",
                    "text": "Considerações: [Acrescentar informações relevantes caso não tenham sido abordadas nos itens anteriores.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 13,
                        "style": "BOLD"
                      }, {
                        "offset": 15,
                        "length": 89,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "epom3",
                    "text": "Recomendação preliminar: [deve conter um resumo dos aspectos que foram discutidos no plenário e considerados importantes para emissão da recomendação preliminar, e a recomendação preliminar da CONITEC.] Considerou-se que [aspectos que foram discutidos no plenário e considerados para emissão da recomendação preliminar]. Assim, a CONITEC em [XX/XX/XXXX], recomendou a [(não) incorporação] no SUS do [medicamento] para [doença].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 24,
                        "style": "BOLD"
                      }, {
                        "offset": 25,
                        "length": 177,
                        "style": "ITALIC"
                      }, {
                        "offset": 220,
                        "length": 99,
                        "style": "ITALIC"
                      }, {
                        "offset": 341,
                        "length": 12,
                        "style": "ITALIC"
                      }, {
                        "offset": 368,
                        "length": 20,
                        "style": "ITALIC"
                      }, {
                        "offset": 399,
                        "length": 13,
                        "style": "ITALIC"
                      }, {
                        "offset": 418,
                        "length": 8,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "7cjn7",
                    "text": "Consulta pública: Foram recebidas [XX] contribuições técnico-científicas e [XX] contribuições de experiência ou opinião, sendo [XX% - maioria] [concordante ou discordante] com a recomendação preliminar da CONITEC. Foi [apresentar um resumo dos aspectos que chamaram atenção na pespectiva da consulta pública]. A CONITEC entendeu que [(não) houve argumentação] suficiente para alterar sua recomendação inicial.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 18,
                        "style": "BOLD"
                      }, {
                        "offset": 34,
                        "length": 4,
                        "style": "ITALIC"
                      }, {
                        "offset": 74,
                        "length": 5,
                        "style": "ITALIC"
                      }, {
                        "offset": 128,
                        "length": 13,
                        "style": "ITALIC"
                      }, {
                        "offset": 144,
                        "length": 26,
                        "style": "ITALIC"
                      }, {
                        "offset": 219,
                        "length": 88,
                        "style": "ITALIC"
                      }, {
                        "offset": 334,
                        "length": 24,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1a5lf",
                    "text": "Recomendação final: Os membros da CONITEC em [ xx/xx/20xx] deliberaram por recomendar a [(não) incorporação] no SUS do [medicamento] para [doença]. Foi assinado o Registro de Deliberação nº [xx/20xx].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 19,
                        "style": "BOLD"
                      }, {
                        "offset": 47,
                        "length": 10,
                        "style": "ITALIC"
                      }, {
                        "offset": 89,
                        "length": 18,
                        "style": "ITALIC"
                      }, {
                        "offset": 120,
                        "length": 11,
                        "style": "ITALIC"
                      }, {
                        "offset": 139,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 191,
                        "length": 7,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3tnog",
                    "text": "Decisão: [(não) incorporado/excluído/modificado] segundo Portaria SCTIE/MS n.º[ xx, de xx de xxxxxxxxx de 20xx].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 9,
                        "style": "BOLD"
                      }, {
                        "offset": 10,
                        "length": 37,
                        "style": "ITALIC"
                      }, {
                        "offset": 79,
                        "length": 31,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }
          ]
        },
        executiveSummaryEn: {
          name: "EXECUTIVE SUMMARY",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        clinicalCondition: {
          name: "CONDIÇÃO CLÍNICA",
          subsections: [
            {
              title: "Aspectos clínicos e epidemiológicos",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "[Definição da doença (o que é de acordo com as sociedades médicas de especialistas – brasileira, latino-americana, americana, europeia), complicações e comorbidades associadas (história natural da doença).]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 204,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "80ubp",
                    "text": "[Incidência e prevalência (dados nacionais e internacionais), diferenças de prevalência entre os gêneros, idade, gravidade da doença, estadiamento (no caso de oncologia), etc.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 174,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3fek8",
                    "text": "[Impacto da doença em morbidade, mortalidade, qualidade de vida, carga da doença e custos.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 89,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }, {
              title: "Tratamento recomendado",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "[Alternativas terapêuticas de acordo com PCDT ou outros documentos do MS. Se não existir, buscar por recomendações brasileiras de outros autores (sociedades médicas, associações, publicações científicas). Pode ser complementado por diretrizes internacionais.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 257,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "fbhfq",
                    "text": "[É recomendável descrever o tratamento preconizado para a indicação a que se destina a tecnologia avaliada, atentando para o nível/estadiamento/gravidade da doença e linhas de tratamento, se indicada para pacientes virgens de tratamento ou refratários (não-responsivos), se indicada após determinada terapia.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 307,
                        "style": "ITALIC"
                      }, {
                        "offset": 58,
                        "length": 26,
                        "style": "BOLD"
                      }, {
                        "offset": 125,
                        "length": 28,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "ffo3i",
                    "text": "[Acrescentar, se disponível, fluxograma contendo algoritmo de tratamento recomendado pelo SUS (quando houver).]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 109,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }
          ]
        },
        technology: {
          name: "A TECNOLOGIA",
          subsections: [
            {
              title: "Descrição",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "[O que é o novo medicamento do ponto de vista técnico. Destacar se ele traz alguma vantagem em relação à tecnologia já incorporada pelo SUS (ex.: em termos de aumento de conveniência de uso pelo paciente, via oral ao invés de parenteral, redução do número de administrações, maior facilidade posológica). Descrever, de forma simplificada: mecanismo de ação, sem entrar em detalhes de farmacocinética; informações de segurança relevantes; e esquemas de tratamento.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 462,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }, {
              title: "Ficha técnica",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Tipo: [MEDICAMENTO/HEMODERIVADO/VACINA]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 4,
                        "style": "BOLD"
                      }, {
                        "offset": 7,
                        "length": 31,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "49vi5",
                    "text": "Princípio Ativo: [Fonte: Registro ANVISA]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 15,
                        "style": "BOLD"
                      }, {
                        "offset": 18,
                        "length": 22,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "dqtd7",
                    "text": "Nome comercial: [Fonte: Registro ANVISA]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 14,
                        "style": "BOLD"
                      }, {
                        "offset": 17,
                        "length": 22,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "39v82",
                    "text": "Apresentação: [Fonte: Informada pelo demandante e conferida com o Registro ANVISA e tabela CMED]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 14,
                        "style": "BOLD"
                      }, {
                        "offset": 15,
                        "length": 80,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "74n50",
                    "text": "Detentor do registro: [Fonte: Registro ANVISA]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 22,
                        "style": "BOLD"
                      }, {
                        "offset": 23,
                        "length": 22,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "9103r",
                    "text": "Fabricante: : [Fonte: Registro ANVISA]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 10,
                        "style": "BOLD"
                      }, {
                        "offset": 15,
                        "length": 22,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3u636",
                    "text": "Indicação aprovada na Anvisa: [transcrever a indicação que consta em bula.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 28,
                        "style": "BOLD"
                      }, {
                        "offset": 31,
                        "length": 43,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "9n66o",
                    "text": "Indicação proposta pelo demandante: [transcrever a indicação proposta no dossiê ou que consta no formulário de submissão (comparar a indicação proposta pelo demandante com a aprovação da Anvisa. Se houver divergências, discutir na análise da evidência)]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 34,
                        "style": "BOLD"
                      }, {
                        "offset": 37,
                        "length": 215,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3rtg5",
                    "text": "Posologia e Forma de Administração: [transcrever o que consta em bula e MicroMedex]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 34,
                        "style": "BOLD"
                      }, {
                        "offset": 37,
                        "length": 45,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "aql6d",
                    "text": "Patente: [verificar no formulário de submissão e, quando a patente for nacional, confirmar em https://gru.inpi.gov.br/pePI/servlet/LoginController?action=login. Informar se existe o primeiro depósito de patente, inserir a data de vencimento da patente (citar referência). Caso o produto não tenha patente, informar que não existe. Caso não encontre essas informações, suprimir este item]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 9,
                        "style": "BOLD"
                      }, {
                        "offset": 10,
                        "length": 376,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [
                      {
                        "offset": 93,
                        "length": 66,
                        "key": 0
                      }
                    ],
                    "data": {}
                  }, {
                    "key": "52mmh",
                    "text": "Contraindicações: [FONTE: bula (o que não pode ser usado em determinada condição, colocar somente as mais importantes. Ex.: o medicamento ainda não foi estudado em pacientes menores de 18 anos; pacientes diabéticos não podem usar o medicamento)]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 16,
                        "style": "BOLD"
                      }, {
                        "offset": 19,
                        "length": 225,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "en98m",
                    "text": "Precauções: [FONTE: bula (pode ser usado de acordo com as devidas precauções em determinada condição, colocar somente as mais importantes. Ex.: o medicamento pode causar alterações hepáticas e por isso o paciente deve ser monitorado mensalmente)]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 10,
                        "style": "BOLD"
                      }, {
                        "offset": 13,
                        "length": 232,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "98c05",
                    "text": "Eventos adversos: [citar os muito comuns. Relatar quando existir evento de classe farmacêutica importante, como no caso dos biológicos (Ex.: tuberculose x medicamentos biológicos.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 16,
                        "style": "BOLD"
                      }, {
                        "offset": 19,
                        "length": 160,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {
                  "0": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "https://gru.inpi.gov.br/pePI/servlet/LoginController?action=login",
                      "url": "https://gru.inpi.gov.br/pePI/servlet/LoginController?action=login"
                    }
                  }
                }
              }
            }, {
              title: "Preço proposto para incorporação",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Quadro X: Apresentação de preços disponíveis para a tecnologia http://bps.saude.gov.br/login.jsf ",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 97,
                        "style": "ITALIC"
                      }, {
                        "offset": 63,
                        "length": 33,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [
                      {
                        "offset": 63,
                        "length": 33,
                        "key": 0
                      }
                    ],
                    "data": {}
                  }
                ],
                "entityMap": {
                  "0": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "http://bps.saude.gov.br/login.jsf",
                      "url": "http://bps.saude.gov.br/login.jsf"
                    }
                  }
                }
              }
            }
          ]
        },
        evidenceAnalysis: {
          name: "ANÁLISE DA EVIDÊNCIA",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "O objetivo deste relatório é analisar as evidências científicas apresentadas pelo [DEMANDANTE] sobre eficácia, segurança, custo-efetividade e impacto orçamentário do [MEDICAMENTO], para [INDICAÇÃO PROPOSTA], visando avaliar a sua incorporação no Sistema Único de Saúde.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 83,
                        "length": 10,
                        "style": "ITALIC"
                      }, {
                        "offset": 167,
                        "length": 11,
                        "style": "ITALIC"
                      }, {
                        "offset": 187,
                        "length": 18,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }, {
              title: "Evidências apresentadas pelo demandante",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "O demandante construiu a seguinte pergunta de pesquisa para busca e seleção de evidências, cuja estruturação encontra-se na Tabela [X].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 124,
                        "length": 10,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "9fif7",
                    "text": "Pergunta: O uso do [NOME GENÉRICO DO MEDICAMENTO] é eficaz, seguro e custo-efetivo em pacientes com [INDICAÇÃO] quando comparado ao(s) [NOME DO COMPARADOR DISPONÍVEL NO SUS]?",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 8,
                        "style": "BOLD"
                      }, {
                        "offset": 21,
                        "length": 27,
                        "style": "ITALIC"
                      }, {
                        "offset": 136,
                        "length": 36,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3um8i",
                    "text": "Com base na pergunta PICO estruturada acima e por meio de estratégia de busca nas bases [XXX] (Anexo [XX]), o demandante selecionou [XX - número] referências (Quadro [XX]), de acordo com os seguintes critérios de elegibilidade [XXXXXX].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 89,
                        "length": 3,
                        "style": "ITALIC"
                      }, {
                        "offset": 102,
                        "length": 2,
                        "style": "ITALIC"
                      }, {
                        "offset": 133,
                        "length": 11,
                        "style": "ITALIC"
                      }, {
                        "offset": 159,
                        "length": 11,
                        "style": "ITALIC"
                      }, {
                        "offset": 228,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "5tsej",
                    "text": "[Fazer um breve relato sobre a avaliação de risco de viés dos estudos selecionados, quando for o caso, pelo demandante]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 117,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }, {
              title: "Avaliação crítica da demanda",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "[Avaliar criticamente a coerência entre a pergunta PICO proposta, busca realizada e os estudos incluídos pelo demandante. Reproduzir a busca do demandante. No caso de necessidade de busca suplementar, apresentar a nova questão PICO, estratégia de busca, bases de dados, critérios de elegibilidade, seleção dos estudos (incluindo PRISMA), avaliação do risco de viés e estudos incluídos. Exemplo: a população ou linha de tratamento é a mesma? O comparador é o mesmo do SUS? Os estudos que não corresponderem à questão PICO não devem ser considerados e os motivos devem ser descritos. A avaliação do parecerista deverá ser baseada nas Diretrizes Metodológicas de elaboração de Pareceres Técnico-Científicos (http://conitec.gov.br/images/Artigos_Publicacoes/Diretrizes/PTC.pdf) ou de Revisão Sistemática  (http://bvsms.saude.gov.br/bvs/publicacoes/diretrizes_metodologicas_elaboracao_sistematica.pdf) ]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 895,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [
                      {
                        "offset": 705,
                        "length": 67,
                        "key": 0
                      }, {
                        "offset": 802,
                        "length": 93,
                        "key": 1
                      }
                    ],
                    "data": {}
                  }, {
                    "key": "3h5s8",
                    "text": "A [Secretaria-Executiva da CONITEC / Grupo parecerista externo-nome] realizou a avaliação crítica e [(não) considerou] [relatar se foi adequado ou não cada item a seguir: PICO, estratégia de busca, bases de dados, critérios de elegibilidade, seleção dos estudos (incluindo fluxograma PRISMA), avaliação do risco de viés e estudos incluídos.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 3,
                        "length": 64,
                        "style": "ITALIC"
                      }, {
                        "offset": 101,
                        "length": 16,
                        "style": "ITALIC"
                      }, {
                        "offset": 120,
                        "length": 220,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "4i638",
                    "text": "Com base na pergunta PICO estruturada acima, o demandante selecionou [XX] referências, que foram todas avaliadas.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 70,
                        "length": 2,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "5k27r",
                    "text": "OU",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 2,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "7qgsh",
                    "text": "Dentre as referências selecionadas pelo demandante, foram consideradas somente as [revisões sistemáticas e os ensaios clínicos randomizados que avaliaram os [desfechos [hospitalização, qualidade de vida e morte.] Os motivos de exclusão dos estudos não considerados, encontram-se no quadro [XX] abaixo.  ",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 83,
                        "length": 127,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1tgjs",
                    "text": "Além dos estudos apresentados pelo demandante, considerou-se relevante a realização de nova busca na literatura por artigos científicos, com o objetivo de localizar evidências complementares sobre o tema. A estratégia de busca realizada foi baseada nos critérios estabelecidos na [PICO do demandante/novo PICO] (Tabela [XX]).",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 281,
                        "length": 28,
                        "style": "ITALIC"
                      }, {
                        "offset": 320,
                        "length": 2,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "82v11",
                    "text": "Logo, com base nos critérios de inclusão descritos, na estratégia de busca e nas referências dos artigos selecionados, nenhum estudo adicional foi incluído neste relatório.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "77s0j",
                    "text": "OU",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 2,
                        "style": "BOLD"
                      }, {
                        "offset": 0,
                        "length": 2,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1g697",
                    "text": "Com base nos critérios de inclusão, na estratégia de busca e nas referências dos artigos selecionados, foram incluídos os seguintes estudos, além dos selecionados pelo demandante: [referências dos estudos incluídos, ex.:Nana et al. 20134 e Banana et al. 20145.] (Figura [XX – fluxograma da seleção das evidências])",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 270,
                        "length": 42,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {
                  "0": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "http://conitec.gov.br/images/Artigos_Publicacoes/Diretrizes/PTC.pdf",
                      "url": "http://conitec.gov.br/images/Artigos_Publicacoes/Diretrizes/PTC.pdf"
                    }
                  },
                  "1": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "http://bvsms.saude.gov.br/bvs/publicacoes/diretrizes_metodologicas_elaboracao_sistematica.pdf",
                      "url": "http://bvsms.saude.gov.br/bvs/publicacoes/diretrizes_metodologicas_elaboracao_sistematica.pdf"
                    }
                  }
                }
              }
            }, {
              title: "Evidência Clínica",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Foram incluídos [XX] estudos, dos quais ... [Descrever de forma agregada as características relevantes dos estudos apresentadas no Quadro[XX] em anexo). (Anexo [XX])",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 17,
                        "length": 2,
                        "style": "ITALIC"
                      }, {
                        "offset": 45,
                        "length": 105,
                        "style": "ITALIC"
                      }, {
                        "offset": 161,
                        "length": 2,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "95ge6",
                    "text": "Os resultados dos estudos foram descritos de acordo com os seguintes desfechos [xxx, xxxx, xxxx]·     [Desfecho]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 80,
                        "length": 15,
                        "style": "ITALIC"
                      }, {
                        "offset": 103,
                        "length": 8,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "ej5u3",
                    "text": "[Descrever o desfecho: o que ele significa, se é validado quando for substituto, se tem relação com o desfecho finalístico. Colocar a medida de efeito. Sempre que possível, os resultados deverão ser apresentados em termos de Risco Relativo – RR, Odds Ratio – OR, RRR, NNT ou NNH, assim como os intervalos de confiança. Se não estiverem disponíveis nos estudos e for possível calculá-los, por favor, acessem as calculadoras: statpages.org/ctab2x2.html OU www.graphpad.com/quickcalcs] [Apresentar a qualidade da evidência para cada desfecho de acordo com o GRADE] (Tabela XX).",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 559,
                        "style": "ITALIC"
                      }, {
                        "offset": 570,
                        "length": 2,
                        "style": "ITALIC"
                      }, {
                        "offset": 424,
                        "length": 26,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [
                      {
                        "offset": 423,
                        "length": 27,
                        "key": 0
                      }
                    ],
                    "data": {}
                  }
                ],
                "entityMap": {
                  "0": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "http://statpages.org/ctab2x2.html",
                      "url": "http://statpages.org/ctab2x2.html"
                    }
                  }
                }
              }
            }, {
              title: "Avaliação Econômica",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "O demandante apresentou uma análise de [tipo de análise], que foi avaliada com base nas Diretrizes Metodológicas para Estudos de Avaliação Econômica de Tecnologias em Saúde. (Quadro [XX]])",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 40,
                        "length": 15,
                        "style": "ITALIC"
                      }, {
                        "offset": 183,
                        "length": 2,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "boqst",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3k0te",
                    "text": "[Descrever resumidamente a análise realizada pelo demandante, atentando para os itens fundamentais de uma avaliação econômica, e avaliar criticamente com base nas Diretrizes Metodológicas para Estudos de Avaliação Econômica de Tecnologias em Saúde (http://conitec.gov.br/images/Artigos_Publicacoes/Diretrizes/AVE.pdf )]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 317,
                        "style": "ITALIC"
                      }, {
                        "offset": 249,
                        "length": 67,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [
                      {
                        "offset": 249,
                        "length": 67,
                        "key": 0
                      }
                    ],
                    "data": {}
                  }, {
                    "key": "471c9",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "cvonh",
                    "text": "[Descrever a análise realizada pelo demandante, atentando para os itens fundamentais de uma avaliação econômica, incluindo a estrutura do modelo. Ressaltar e justificar as limitações encontradas. Seguem alguns exemplos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 218,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "82f4b",
                    "text": "Comparador inadequado.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 22,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "244vk",
                    "text": "Horizonte temporal inadequado -> vai depender dos estudos que embasaram a análise, dos desfechos considerados e da doença.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 122,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "8ucgj",
                    "text": "Tipo de análise inadequado: ATENÇÃO -> quando o demandante apresentar análise de custo-minimização obrigatoriamente deve apresentar estudo de comparação direta ou indireta que não evidencie superioridade entre os comparadores -> comparadores precisam ter a mesma eficácia!",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 272,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "76rgb",
                    "text": "Deixar de considerar custos de eventos adversos e complicações -> Esses devem estar contidos.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 93,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "e04tl",
                    "text": "Deixar de considerar custos de tecnologias que serão utilizadas conjuntamente -> exames, consultas médicas, acompanhamento, medicamentos associados, contrastes, tiras reagentes, etc.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 182,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "39h14",
                    "text": "Utilização de evidências que não foram apresentadas na seção de evidência clínica ou que foram publicadas para outra indicação ou outra tecnologia.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 147,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "dkt13",
                    "text": "Deixar de mencionar as referências que basearam os percentuais de benefício clínico, probabilidades de prevalência ou de transição entre os estados.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 148,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3n83d",
                    "text": "Identificar se os pressupostos do modelo estão de acordo com a validade do modelo de análise de decisão escolhido]",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 113,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1lhmd",
                    "text": "Como resultado da avaliação econômica, o demandante encontrou uma razão de custo efetividade incremental (RCEI) de XXX, [comentar esse resultado se necessário]. (Quadro [XX]])",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 115,
                        "length": 3,
                        "style": "ITALIC"
                      }, {
                        "offset": 121,
                        "length": 37,
                        "style": "ITALIC"
                      }, {
                        "offset": 170,
                        "length": 2,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "8mf0h",
                    "text": "[Descrever e relatar a análise de sensibilidade realizada pelo demandante, apresentando os gráficos, discutindo seus resultados (as variáveis mais sensíveis no modelo e o impacto dessas variações no resultado do estudo)]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 218,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {
                  "0": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "http://conitec.gov.br/images/Artigos_Publicacoes/Diretrizes/AVE.pdf",
                      "url": "http://conitec.gov.br/images/Artigos_Publicacoes/Diretrizes/AVE.pdf"
                    }
                  }
                }
              }
            }, {
              title: "Análise de Impacto Orçamentário",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "[Descrever resumidamente a análise de impacto orçamentário realizada pelo demandante, apresentado os pontos discordantes. Segue alguns exemplos de limitações que podem ser apontadas:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 182,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "7at02",
                    "text": "A população-alvo (o número de pacientes que utilizarão o medicamento/tecnologia) deve ser adequada, considerando principalmente a linha de cuidado para a qual a tecnologia está indicada, subgrupos específicos (sexo, faixa etária, estadiamento, gravidade, etc). A tendência dos autores é sempre subestimar o tamanho da população-alvo, para com isso diminuir o impacto orçamentário. ATENÇÃO! Avaliar de acordo com a situação a adequação da fonte de informação dos dados da população-alvo: base de registro institucional (por exemplo, datasus) ou epidemiológico.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 559,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3hohu",
                    "text": "O horizonte temporal a ser considerado, preferencialmente, deve ser de 5 anos.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 78,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "8d2ab",
                    "text": "A análise não deve considerar o número de usuários como estável ao longo do horizonte temporal. Isso pode configurar um erro na análise de impacto orçamentário, já que se deveria considerar a incidência da doença ao longo dos anos, sendo esse número crescente.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 260,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "9mbv9",
                    "text": "Verificar a taxa de difusão da tecnologia utilizada na análise, que proporciona um cálculo de crescimento mais real da adesão à nova tecnologia. Essa informação geralmente provém das empresas e deve ser aceito, desde que referenciado.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 234,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "27tqv",
                    "text": "Esquema de tratamento adotado (posologia, frequência de tratamento e de doses ao longo do ano) deve ser adequado, de acordo com as recomendações de guidelines nacionais ou internacionais ou a bula. Atenção para alguns medicamentos que têm dose de indução no início do tratamento – no 1º ano. Esse custo não deve se repetir nos outros anos da análise.",
                    "type": "unordered-list-item",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 350,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "c55to",
                    "text": "A análise de impacto orçamentário feita pelo demandante foi considerada adequada.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "aciq9",
                    "text": "OU",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3550o",
                    "text": "Considerando-se as limitações apontadas na análise realizada pelo demandante, é provável que o cálculo do impacto orçamentário esteja [subestimado/superestimado].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 135,
                        "length": 25,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }, {
              title: "Avaliação por outras agências de ATS",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "[Deve-se citar as recomendações das agências de ATS internacionais (NICE/Inglaterra www.nice.org.uk, CADTH/Canadá http://www.cadth.ca/, PBAC/Austrália http://www.pbs.gov.au/pbs/home, SMC/Escócia https://www.scottishmedicines.org.uk/Home, INFARMED/Portugal, etc.)]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 261,
                        "style": "ITALIC"
                      }, {
                        "offset": 84,
                        "length": 15,
                        "style": "UNDERLINE"
                      }, {
                        "offset": 114,
                        "length": 20,
                        "style": "UNDERLINE"
                      }, {
                        "offset": 151,
                        "length": 30,
                        "style": "UNDERLINE"
                      }, {
                        "offset": 195,
                        "length": 41,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [
                      {
                        "offset": 83,
                        "length": 16,
                        "key": 0
                      }, {
                        "offset": 113,
                        "length": 21,
                        "key": 1
                      }, {
                        "offset": 150,
                        "length": 31,
                        "key": 2
                      }, {
                        "offset": 194,
                        "length": 42,
                        "key": 3
                      }
                    ],
                    "data": {}
                  }
                ],
                "entityMap": {
                  "0": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "http://www.nice.org.uk",
                      "url": "http://www.nice.org.uk/"
                    }
                  },
                  "1": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "http://www.cadth.ca/",
                      "url": "http://www.cadth.ca/"
                    }
                  },
                  "2": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "http://www.pbs.gov.au/pbs/home",
                      "url": "http://www.pbs.gov.au/pbs/home"
                    }
                  },
                  "3": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "https://www.scottishmedicines.org.uk/Home",
                      "url": "https://www.scottishmedicines.org.uk/Home"
                    }
                  }
                }
              }
            }, {
              title: "Monitoramento do horizonte tecnológico",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Tem que criar um modelo para este item",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 38,
                        "style": "BOLD"
                      }, {
                        "offset": 0,
                        "length": 38,
                        "style": "UNDERLINE"
                      }, {
                        "offset": 0,
                        "length": 38,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }, {
              title: "Implementação",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "[Descrever se o medicamento necessita de alguma particularidade para sua implementação, como alteração de PCDT, necessidade de adequação de infra-estrutura para a sua correta utilização, de uso de outras tecnologias de diagnóstico ou terapêuticas (especificando e indicando se fazem parte do rol do SUS), de treinamento de RH, etc. Excluir questões relacionadas a financiamento]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 378,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }, {
              title: "Considerações gerais",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "[Deve conter os principais elementos de todo o relatório que permitam subsidiar a análise ,discussão e recomendação preliminar da CONITEC. Em relação às evidências, considerar a utilização do GRADE para a recomendação geral sobre a demanda.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 241,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "4b0je",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "62ocu",
                    "text": "[Descrever considerações relacionada a nível de evidência e grau de recomendação se aplicável]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 94,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }
          ]
        },
        economicEvaluation: {
          name: "AVALIAÇÃO ECONÔMICA",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        budgetImpactAnalysis: {
          name: "ANÁLISE DE IMPACTO ORÇAMENTÁRIO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        clinicalGuidelines: {
          name: "DIRETRIZES CLÍNICAS",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        generalConsiderations: {
          name: "CONSIDERAÇÕES GERAIS",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        preliminaryRecommendations: {
          name: "RECOMENDAÇÃO PRELIMINAR DA CONITEC",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "[Esse item é incluído na versão do relatório que é disponibilizado em consulta pública. Deve-se incluir os aspectos que foram discutidos no plenário e considerados para emissão da recomendação preliminar.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 203,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "d09tt",
                    "text": "Pelo exposto, a CONITEC, em sua [xx]ª reunião ordinária, no dia [XX de XX de 20XX], recomendou a [(não) incorporação] no SUS do [MEDICAMENTO] para [DOENÇA/CONDIÇÃO CLÍNICA]. Considerou-se que...[ incluir os aspectos que foram discutidos no plenário e considerados para emissão da recomendação preliminar.].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 33,
                        "length": 2,
                        "style": "ITALIC"
                      }, {
                        "offset": 65,
                        "length": 16,
                        "style": "ITALIC"
                      }, {
                        "offset": 98,
                        "length": 18,
                        "style": "ITALIC"
                      }, {
                        "offset": 129,
                        "length": 11,
                        "style": "ITALIC"
                      }, {
                        "offset": 148,
                        "length": 23,
                        "style": "ITALIC"
                      }, {
                        "offset": 196,
                        "length": 109,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "eq5rp",
                    "text": "A matéria foi disponibilizada em consulta pública.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }
          ]
        },
        publicConsultation: {
          name: "CONSULTA PÚBLICA",
          subsections: [
            {
              title: "",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "A Consulta Pública nº [XXX] foi realizada entre os dias [XX/XX/20XX e XX/XX/20XX]. Foram recebidas [XXX] contribuições, sendo [XX] pelo formulário para contribuições técnico-científicas e [XXX] pelo formulário para contribuições sobre experiência ou opinião de pacientes, familiares, amigos ou cuidadores de pacientes, profissionais de saúde ou pessoas interessadas no tema. Foram consideradas apenas as contribuições encaminhadas no período estipulado e por meio do site da CONITEC, em formulário próprio.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 23,
                        "length": 3,
                        "style": "ITALIC"
                      }, {
                        "offset": 57,
                        "length": 23,
                        "style": "ITALIC"
                      }, {
                        "offset": 100,
                        "length": 3,
                        "style": "ITALIC"
                      }, {
                        "offset": 127,
                        "length": 2,
                        "style": "ITALIC"
                      }, {
                        "offset": 189,
                        "length": 3,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "869m9",
                    "text": "O formulário de contribuições técnico-científicas também é composto por duas partes, a primeira sobre as características do participante, e a segunda, sobre a contribuição propriamente dita, acerca do relatório em consulta, estruturada com uma pergunta sobre a qualidade do relatório e cinco blocos de perguntas sobre: (1) as evidências clínicas, (2) a avaliação econômica, (3) o impacto orçamentário, (4) a recomendação inicial da CONITEC, e (5) outros aspectos além dos citados.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "ek2af",
                    "text": "O formulário de experiência ou opinião é composto por duas partes, a primeira sobre as características do participante, e a segunda, sobre a contribuição propriamente dita, acerca do relatório em consulta, que está estruturada em três blocos de perguntas com o objetivo de conhecer a opinião do participante sobre: (1) a recomendação inicial da CONITEC, (2) a experiência prévia com o medicamento em análise e (3) a experiência prévia com outros medicamentos para tratar a doença em questão.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "6pgpv",
                    "text": "As características dos participantes foram quantificadas, agrupadas e estratificadas de acordo com os respectivos formulários. As contribuições foram quantitativamente e qualitativamente avaliadas, considerando as seguintes etapas: a) leitura de todas as contribuições, b) identificação e categorização das ideias centrais, e c) discussão acerca das contribuições. A seguir, é apresentado um resumo da análise das contribuições recebidas. O conteúdo integral das contribuições se encontra disponível na página da CONITEC (http://conitec.gov.br/index.php/consultas-publicas).",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 522,
                        "length": 50,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [
                      {
                        "offset": 522,
                        "length": 50,
                        "key": 0
                      }
                    ],
                    "data": {}
                  }
                ],
                "entityMap": {
                  "0": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                      "href": "http://conitec.gov.br/index.php/consultas-publicas",
                      "url": "http://conitec.gov.br/index.php/consultas-publicas"
                    }
                  }
                }
              }
            }, {
              title: "Contribuições técnico-científicas",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Das [número] contribuições recebidas de cunho técnico-científico, [número] foram excluídas por se tratarem de duplicações de outras contribuições, por abordarem um tema diferente ou por não conter informação (em branco).",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 5,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 67,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "2m9b",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3287s",
                    "text": "Perfil dos participantes",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 24,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "djgo9",
                    "text": "[Descrever o perfil dos participantes de acordo com a análise quantitativa das variáveis da primeira parte do formulário, resumida na aba “Resumo” da planilha em excel do resultado da CP. A tabela pode ser customizada.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 217,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "4238c",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "8orc8",
                    "text": "Evidência Clínica",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 17,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3p7rs",
                    "text": "Dentre as contribuições, foram identificadas [número] alusivas às evidências clínicas sobre [condição clínica em estudo]. Foram [número] contribuições contrárias à recomendação inicial da CONITEC, [número] neutras e [número] à favor. No entanto, somente foram consideradas [número] contribuições por estas apresentarem argumentação técnico-científica. Dessas contribuições, [número] foram contrárias à recomendação inicial, [número] neutras e [número] à favor. Essas contribuições se basearam nos seguintes fundamentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 46,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 93,
                        "length": 26,
                        "style": "ITALIC"
                      }, {
                        "offset": 129,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 198,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 274,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 375,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 425,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "2829v",
                    "text": "[Discutir as argumentações apresentadas, incluindo avaliação de elegibilidade dos estudos anexados, em relação aos subsídios e à recomendação preliminar].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 2,
                        "length": 150,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1j5dc",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "4pu6e",
                    "text": "[EXEMPLO:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 9,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "att57",
                    "text": "Efetividade superior",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 20,
                        "style": "UNDERLINE"
                      }, {
                        "offset": 0,
                        "length": 20,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "4t9ab",
                    "text": "Parte das contribuições sobre o campo das evidências clínicas ressaltava a existência de evidências de superioridade do tratamento com o fingolimode sobre as terapias existentes no SUS. Tal argumento pode ser representado pela seguinte contribuição:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 249,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "cenjd",
                    "text": "“Conforme alguns estudos selecionados, o fingolimode se mostra uma droga com perfil de segurança aceitável e eficácia melhor que os de primeira linha, mas menor que o de terceira linha atualmente incorporado.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 209,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "8pfn3",
                    "text": "Via de administração",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 20,
                        "style": "UNDERLINE"
                      }, {
                        "offset": 0,
                        "length": 20,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "76lak",
                    "text": "Parte das contribuições sobre o campo das evidências clínicas ressaltava a existência de benefícios adicionais em relação à via de administração oral do fingolimode sobre as terapias injetáveis em comparação no SUS. Tal argumento pode ser representado pelas seguintes contribuições:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 282,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "414ar",
                    "text": "“A via oral permite maior aderência ao tratamento da parte do paciente.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 72,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "do7fl",
                    "text": "“[...] a possibilidade de um tratamento oral para nossos pacientes, com poucos efeitos colaterais, seguro e que previne a atrofia cerebral e piora cognitiva desses pacientes (benefício não encontrado em outras medicações)”]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 223,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "2pp7a",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1iu70",
                    "text": "Avaliação Econômica",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 19,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3a6nf",
                    "text": "Houveram [número] contribuições que versaram sobre a análise da Avaliação Econômica, [número] contrárias à recomendação inicial da CONITEC, [número] neutras e [número] favoráveis. No entanto, somente foram consideradas [número] contribuições por estas apresentarem argumentação técnico-científica. Dessas contribuições, [número] foram contrárias à recomendação inicial, [número] neutras e [número] à favor. Essas contribuições se basearam nos seguintes fundamentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 10,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 86,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 141,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 160,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 371,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "8r7os",
                    "text": "[Discutir as argumentações apresentadas, incluindo avaliação de elegibilidade dos estudos anexados, em relação aos subsídios e à recomendação preliminar].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 151,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "23hol",
                    "text": "[EXEMPLO:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 9,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "eednq",
                    "text": "Custo dos medicamentos",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 22,
                        "style": "UNDERLINE"
                      }, {
                        "offset": 0,
                        "length": 22,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "8gqso",
                    "text": "Parte das contribuições sobre o campo da avaliação econômica ressaltava o menor custo de tratamento XX em relação ao XX. Tal argumento pode ser representado pela seguinte contribuições:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 185,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "21lrs",
                    "text": "“a opção de 02 drogas da mesma classe - inibidores da integrase - como primeira linha, possibilita uma melhor negociação de preço.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 131,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "25qi0",
                    "text": "Custos da incapacidade",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 22,
                        "style": "UNDERLINE"
                      }, {
                        "offset": 0,
                        "length": 22,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "ct5tj",
                    "text": "Parte das contribuições sobre o campo da avaliação econômica ressaltava a existência de custos significantes na perspectiva social associados à incapacidade gerada pela evolução da esclerose múltipla. Tais custos não foram considerados no modelo apresentado devido à adoção da perspectiva do SUS. Contudo, ressaltou-se a necessidade de considerá-los na presente discussão. Tais argumentos podem ser representado pelas seguintes contribuições:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 442,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "pr09",
                    "text": "“O acúmulo de incapacidade e custos indiretos da doença, como perda de emprego, aposentadoria, uso de recursos da previdência social e custo de geração de dependentes não é computado neste modelo.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 197,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "22prk",
                    "text": "“Ao se iniciar um tratamento com droga mais eficaz em casos de doença mais ativa se reduz custos com internações por surtos e se reduz o impacto socioeconômico pelo absenteísmo no trabalho.”]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 191,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "fu0u7",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "a1op0",
                    "text": "Análise de Impacto Orçamentário",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 31,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "8a7hc",
                    "text": "Foram enviadas [número] contribuições a respeito da avaliação da análise de impacto orçamentário [apresentada pelo demandante] sendo [número] contrárias à recomendação inicial da CONITEC, [número] neutra e [número] à favor. Destas, somente foram consideradas [número] contribuições por apresentarem argumentação técnico-científica, sendo [número] contrárias à recomendação inicial, [número] neutra e [número] à favor. Essas contribuições se basearam nos seguintes fundamentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 16,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 98,
                        "length": 27,
                        "style": "ITALIC"
                      }, {
                        "offset": 134,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 189,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 207,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 339,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "4270b",
                    "text": "[Discutir as argumentações apresentadas, incluindo avaliação de elegibilidade dos estudos anexados, em relação aos subsídios e à recomendação preliminar].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "c8eud",
                    "text": "[EXEMPLO",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 8,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "cp70m",
                    "text": "Custos semelhantes",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 18,
                        "style": "UNDERLINE"
                      }, {
                        "offset": 0,
                        "length": 18,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "6jn9p",
                    "text": "Parte das contribuições sobre o campo da análise de impacto orçamentário defendia a inexistência de impacto para o sistema, já que os custos são semelhantes e não haveria acumulação de tratamentos. Contudo, tal hipótese não se confirma com todas as opções de troca na realidade dos preços praticados, onde poderiam ser observadas diferenças reais com os custos de aquisição dos tratamentos em comparação. Tais argumentos podem ser representados pelas seguintes contribuições:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 475,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "9e4n4",
                    "text": "“Se houver troca de um medicamento pelo Fingolimode, não haverá impacto econômico pois seus custos são semelhantes.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 116,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "e24uo",
                    "text": "“Quando um paciente necessita da troca do medicamento, ele não acumulará tratamentos, lembrando que os custos destes fármacos são equivalentes.” ]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 146,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "9jqe4",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "dthqq",
                    "text": "Avaliação geral da recomendação preliminar da CONITEC",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 53,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "8af8o",
                    "text": "Houveram [número] contribuições sobre a recomendação preliminar da CONITEC, sendo [número] contrárias e [número] à favor. Somente [número] contribuições foram consideradas por descreveram os motivos pela [concordância/discordância] em relação à recomendação preliminar da CONITEC. As concordâncias apresentaram motivos convergentes aos já explicitados nas contribuições previamente categorizadas, que podem ser representados pelas seguintes contribuições:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 10,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 83,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 105,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 131,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "fjstl",
                    "text": "[EXEMPLO",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 7,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "e502r",
                    "text": "“Há pacientes que têm a doença mais agressiva e o fato de ter que aguardar o uso de betainterferona ou glatiramer para iniciar com Fingolimode ou Natalizumab pode ser muito dramático para o paciente.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 200,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "25lpu",
                    "text": "“Há dados suficientes de ensaios clínicos e de vida real para amparar o uso desta droga como proposto”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 102,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3pmn2",
                    "text": "Foram recebidas 5 contribuições favoráveis à recomendação inicial da CONITEC. Contudo, ao se avaliar o teor de seus motivos, observa-se essas contribuições são desfavoráveis à recomendação preliminar da CONITEC, podendo ser representadas pelas seguintes contribuições:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 268,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "7350s",
                    "text": "“O fingolimode apresenta baixo índice de efeitos colaterais se comparado a sua elevada eficácia. A preocupação com o desenvolvimento de leucoencefalopatia multifocal progressiva é muito menor. E, além disso, sua administração é oral, beneficiando em muito a vida dos pacientes.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 278,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "dm3ag",
                    "text": "Além dos argumentos apresentados, uma contribuição se referiu à necessidade de disponibilização de uma apresentação adequada de raltegravir para uso pediátrico em primeira linha:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 178,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "ej47m",
                    "text": "“Depende da apresentação para crianças, se líquida ,cp mastigável ou cp diluível ? Uma dificuldade de lactentes e bebês é apresentação!”]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 137,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "5mah",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "69t6e",
                    "text": "Contribuições além dos aspectos citados",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 39,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "dhleo",
                    "text": "Foram identificadas XX contribuições além dos aspectos citados. As contribuições se basearam fundamentalmente [nos casos em que o raltegravir deveria ser incorporado], e podem ser representadas pelos seguintes argumentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 20,
                        "length": 2,
                        "style": "ITALIC"
                      }, {
                        "offset": 111,
                        "length": 54,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "cc0tg",
                    "text": "[EXEMPLO",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 7,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "bia2d",
                    "text": "“Acredito que o Raltegravir deveria ser mantido para aqueles pacientes que já fazem uso da medicação, visto já estarem adaptados e não conhecermos o efeito a longo prazo do Dolutegravir”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 186,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "4937e",
                    "text": "“Concordo com a incorporação do raltegravir nas situações em que não seja possível o uso do dolutegravir, como por exemplo impossibilidade de suspender carbamazepina. Acrescento que o raltegravir deveria ser liberado também nas situações em que ocorra intolerância ao dolutegravir.”]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 283,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }, {
              title: "Contribuições sobre experiência ou opinião",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Das [número] contribuições recebidas sobre experiência com a tecnologia ou opinião sobre a incorporação, [número] foram excluídas por se tratarem de duplicações de outras contribuições, por abordarem um tema diferente ou por não conter informação (em branco).",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 5,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 106,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "dri0p",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "b5pak",
                    "text": "Perfil dos participantes",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 24,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "7pec3",
                    "text": "[Descrever o perfil dos participantes de acordo com a análise quantitativa das variáveis da primeira parte do formulário, resumida na aba “Resumo” da planilha em excel do resultado da CP. A tabela pode ser customizada.]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 1,
                        "length": 218,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3528g",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "j2c5",
                    "text": "Experiência como profissional de saúde",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 38,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "92rtb",
                    "text": "Foram recebidas [número] contribuições sobre experiências profissionais com as tecnologias avaliadas que foram contrárias à recomendação inicial da CONITEC, no entanto, foram consideradas somente [número] contribuições por apresentarem argumentação que se basearam nos seguintes fundamentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 17,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 197,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "dor10",
                    "text": "“[texto de contribuição que ilustra experiência contrária à recomendação – se necessário, inserir outros]”.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 107,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "f29dk",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "ee6me",
                    "text": "Houveram [número] contribuições sobre experiências profissionais com as tecnologias avaliadas que foram favoráveis à recomendação inicial da CONITEC, no entanto, foram consideradas somente [número] contribuições por apresentarem argumentação que se basearam nos seguintes fundamentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 10,
                        "length": 6,
                        "style": "ITALIC"
                      }, {
                        "offset": 190,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "10imq",
                    "text": "“[texto de contribuição que ilustra experiência favorável à recomendação – se necessário, inserir outros]”.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 107,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "42tb2",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "co91u",
                    "text": "Foram recebidas [número] contribuições sobre experiências profissionais com outras tecnologias que foram contrárias à recomendação inicial da CONITEC, no entanto, foram consideradas somente [número] contribuições por apresentarem argumentação que se basearam nos seguintes fundamentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 17,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3osa9",
                    "text": "“[texto de contribuição que ilustra experiência contrária à recomendação – se necessário, inserir outros]”.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 107,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "c6aig",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "fkptq",
                    "text": "Houveram [número] contribuições sobre experiências profissionais com outras tecnologias que foram favoráveis à recomendação inicial da CONITEC, no entanto, foram consideradas somente [número] contribuições por apresentarem argumentação que se basearam nos seguintes fundamentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 10,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "euc7",
                    "text": "“[texto de contribuição que ilustra experiência favorável à recomendação – se necessário, inserir outros]”.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 107,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "6gn9u",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1penr",
                    "text": "REPETIR ESSE MESMO PADRÃO PARA EXPERIÊNCIA COMO PACIENTE E COMO CUIDADOR OU RESPONSÁVEL",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "56t8j",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "ffqc6",
                    "text": "OU CONSIDERAR O MODELO ABAIXO:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3745s",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "43vpe",
                    "text": "Experiência profissional",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 24,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "dt3a0",
                    "text": "Foram identificadas [XX] contribuições de experiência profissional com o medicamento avaliado e com outras tecnologias, no entanto, foram consideradas somente [número] por apresentarem argumentações positivas e negativas, que se basearam nos seguintes fundamentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 21,
                        "length": 2,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "79sfs",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "2vdsh",
                    "text": "Contribuições Positivas",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 23,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "41em4",
                    "text": "Como experiências positivas, foram identificadas as seguintes categorias e suas respectivas idéias centrais de acordo com a tecnologia considerada:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "fpca2",
                    "text": "[EXEMPLO",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3lk1c",
                    "text": "Fingolimode",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 11,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "7klra",
                    "text": "Perfil de efeitos adversos",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 26,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "r6ee",
                    "text": "“Efeitos colaterais mínimos.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 29,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "e69sd",
                    "text": "“Baixa incidência de efeitos colaterais”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 40,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "6tshj",
                    "text": "Aderência/administração",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 23,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3g97l",
                    "text": "“Mellhora significativa da aderência ao tratamento. “",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 53,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "cbdgs",
                    "text": "“Facilidade a administração”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 28,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "ffn9t",
                    "text": "Betainterferonas e glatirâmer",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 29,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "6mskl",
                    "text": "Efetividade",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 11,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "5q9bl",
                    "text": "“Redução do número de surtos.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 30,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "5ij9s",
                    "text": "Natalizumabe",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 12,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "f8vdt",
                    "text": "Efetividade",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 11,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "abgiv",
                    "text": "“O Natalizumab apresenta um efeito mais prolongado sobre os surtos de esclerose múltipla”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 89,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "pqfq",
                    "text": "Outras terapias",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 15,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3agr3",
                    "text": "Fumarato de dimetila:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 21,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1sksp",
                    "text": "“Via oral, alta eficacia e sem efeitos colaterais.”]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 52,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "9p0jj",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "erfrr",
                    "text": "Contribuições Negativas",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 23,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1bmp3",
                    "text": "Como experiências negativas, foram identificadas as seguintes categorias e suas respectivas idéias centrais de acordo com a tecnologia considerada:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "deflg",
                    "text": "[EXEMPLO",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1k9ra",
                    "text": "Fingolimode",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 11,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "43bdr",
                    "text": "Falha terapêutica",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 17,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "7cnj1",
                    "text": "“Todas as medicações utilizadas na esclerose múltipla pode haver falha terapêutica.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 84,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "2ivb7",
                    "text": "Primeira dose monitorada",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 24,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "dj9ca",
                    "text": "“O protocolo da primeira dose, que exige internação hospitalar por 6 a 12h”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 75,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "36o4a",
                    "text": "“Necessidade de receber a primeira dose em ambiente acompanhado por médico e com monitorização de parâmetros cardiológicos.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 124,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "21o9l",
                    "text": "Efeitos adversos",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 16,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "4ln4g",
                    "text": "“Alguns pacientes referem desconforto cardíaco.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "d0sio",
                    "text": "“Arritmias”; “Linfopenia” e “Alterações das enzimas hepáticas”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "fv0l8",
                    "text": "Betainterferonas e glatirâmer",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 29,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "685nh",
                    "text": "Falha",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 5,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "a1ipk",
                    "text": "“Falha terapêutica”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 1,
                        "style": "ITALIC"
                      }, {
                        "offset": 18,
                        "length": 1,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "af0uf",
                    "text": "“Resistência (anticorpos neutralizantes)”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "eaqt6",
                    "text": "Efeitos adversos",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 16,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "anssq",
                    "text": "“Sintomas gripais”; “Reação local”, “Depressão”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 47,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "47dfd",
                    "text": "Outras terapias",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 15,
                        "style": "UNDERLINE"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3gd3q",
                    "text": "Fampridina:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 11,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "43g28",
                    "text": "“nao é uma droga modificadora da esclerose multipla. Trata sintomas.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 69,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3h1fk",
                    "text": "Alentuzumabe:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 13,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "feem8",
                    "text": "“imunossupressão”]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 18,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "c3g19",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "80mr2",
                    "text": "REPETIR ESSE MESMO PADRÃO PARA EXPERIÊNCIA COMO PACIENTE E COMO CUIDADOR OU RESPONSÁVEL",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "73h0a",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "eulop",
                    "text": "Opinião sobre a recomendação preliminar da CONITEC",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 50,
                        "style": "BOLD"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "6s2v",
                    "text": "Houveram [número] opiniões sobre a recomendação preliminar da CONITEC, sendo [número] contrárias e [número] à favor. Somente [número] opiniões foram consideradas por descreveram os motivos pela [concordância/discordância] em relação à recomendação preliminar da CONITEC. As opiniões se baseiam nos seguintes fundamentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 10,
                        "length": 6,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "f5g6e",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "28g5d",
                    "text": "[EXEMPLO",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "48k81",
                    "text": "[As concordâncias apresentaram motivos convergentes aos já explicitados nas contribuições previamente categorizadas e podem ser representados pelas seguintes contribuições:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1p1ca",
                    "text": "“Há pacientes que tem a doença mais agressiva e o fato de ter que aguardar o uso de betainterferona ou glatiramer para iniciar com Fingolimode ou Natalizumab pode ser muito dramático para o paciente.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 200,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "65s69",
                    "text": "“ha dados suficientes de ensaios clinicos e de vida real para amparar o uso desta droga como proposto”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 102,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "a3cmh",
                    "text": "Foram recebidas xx contribuições que concordaram com a recomendação inicial da CONITEC. Contudo, ao avaliar o comentário, observa-se que essas contribuições são discordantes com a recomendação preliminar da CONITEC, podendo ser representadas pelos seguintes argumentos:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "9b9u",
                    "text": "“O fingolimode apresenta baixo índice de efeitos colaterais se comparado a sua elevada eficácia. A preocupação com o desenvolvimento de leucoencefalopatia multifocal progressiva é muito menor. E, além disso, sua administração é oral, beneficiando em muito a vida dos pacientes.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 278,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "af000",
                    "text": "Além dos argumentos apresentados, uma contribuição se referiu à necessidade de disponibilização de uma apresentação adequada de raltegravir para uso pediátrico em primeira linha:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "b8fbu",
                    "text": "“Depende da apresentação para crianças, se líquida ,cp mastigável ou cp diluível ? Uma dificuldade de lactentes e bebês é apresentação!”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 136,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "ba3gn",
                    "text": "As discordâncias não apresentaram motivos diferentes aos já explicitados anteriormente, além de um apelo ao acesso universal e integral ao tratamento pelo SUS, podendo ser representadas pelos seguintes comentários:",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "qeob",
                    "text": "“A incorporação da medicação, tratará um conforto maior não somente a minha família, mas para MUITAS FAMÍLIAS QUE DEPENDEM DA MEDICAÇÃO.”",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 137,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "9m3e9",
                    "text": "“O remédio que hoje é distribuído pelo SUS para o tratamento da esclerose múltipla é mais caro que o remédio solicitado. Toda e qualquer vida precisa de cuidados.”]",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 0,
                        "length": 164,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }, {
              title: "Avaliação global das contribuições",
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Após apreciação das contribuições encaminhadas pela Consulta Pública .... [colocar as questões levantadas durante a reunião que foi apresentada a consulta pública] ..., o plenário da CONITEC entendeu que [houve/não houve] argumentação suficiente para alterar a recomendação inicial.",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 75,
                        "length": 87,
                        "style": "ITALIC"
                      }, {
                        "offset": 205,
                        "length": 15,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }
          ]
        },
        finalRecommendation: {
          name: "RECOMENDAÇÃO FINAL",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Os membros da CONITEC presentes na [xx]ª reunião [ordinária/extraordinária], no dia XX de XX de XX, deliberaram, [por unanimidade/maioria simples], por recomendar a [(não) incorporação] no SUS do [MEDICAMENTO] para [DOENÇA/CONDIÇÃO CLÍNICA/DIAGNÓSTICO].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 36,
                        "length": 2,
                        "style": "ITALIC"
                      }, {
                        "offset": 50,
                        "length": 24,
                        "style": "ITALIC"
                      }, {
                        "offset": 84,
                        "length": 14,
                        "style": "ITALIC"
                      }, {
                        "offset": 114,
                        "length": 31,
                        "style": "ITALIC"
                      }, {
                        "offset": 165,
                        "length": 19,
                        "style": "ITALIC"
                      }, {
                        "offset": 216,
                        "length": 35,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "2l358",
                    "text": "Foi assinado o Registro de Deliberação nº [xx/20XX].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 43,
                        "length": 7,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }
          ]
        },
        decision: {
          name: "DECISÃO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Portaria SCTIE/MS n.º [xx, de xx de xxxxxxxxx de 20xx].",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [
                      {
                        "offset": 23,
                        "length": 30,
                        "style": "ITALIC"
                      }
                    ],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }
          ]
        },
        references: {
          name: "REFERÊNCIAS",
          type: "references",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        annexes: {
          name: "ANEXOS",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        introduction: {
          name: "INTRODUÇÃO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        icd10: {
          name: "CLASSIFICAÇÃO ESTATÍSTICA INTERNACIONAL DE DOENÇAS E PROBLEMAS RELACIONADOS À SAÚDE (CID-10)",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        eligibilityCriteria: {
          name: "CRITÉRIOS DE ELEGIBILIDADE",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        methodology: {
          name: "METODOLOGIA",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        diagnosis: {
          name: "DIAGNÓSTICO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        ranking: {
          name: "CLASSIFICAÇÃO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        treatment: {
          name: "TRATAMENTO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        drugsAndAdministrationScheme: {
          name: "FÁRMACOS E ESQUEMA DE ADMINISTRAÇÃO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        followUpAndMonitoring: {
          name: "ACOMPANHAMENTO E MONITORIZAÇÃO",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        managementAndControl: {
          name: "GESTÃO E CONTROLE",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        flows: {
          name: "FLUXOGRAMAS",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        },
        clarityAndResponsibilityTerm: {
          name: "TERMO DE ESCLARECIMENTO E RESPONSABILIDADE",
          subsections: [
            {
              title: "",
              titleHidden: true,
              content: ""
            }
          ]
        }
      }
    }
  };
};

module.exports = sections;
