var SelectionView, Shortcuts, View, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

mediator = require('mediator');

template = require('views/templates/selection_view');

Shortcuts = require('base/lib/traits/shortcuts');

module.exports = SelectionView = (function(_super) {
  __extends(SelectionView, _super);

  function SelectionView() {
    this.onFocusLost = __bind(this.onFocusLost, this);
    return SelectionView.__super__.constructor.apply(this, arguments);
  }

  SelectionView.prototype.id = 'list-box';

  SelectionView.prototype.template = template;

  SelectionView.prototype.selectedItem = void 0;

  SelectionView.prototype.shortcuts = {
    'tab': 'selectNextViewItem',
    'shift+tab': 'selectPreviosViewItem',
    'down': 'selectNextViewItem',
    'up': 'selectPreviosViewItem',
    'enter': 'acceptHandler',
    'esc': 'abortHandler'
  };

  SelectionView.prototype.initialize = function() {
    SelectionView.__super__.initialize.apply(this, arguments);
    if (this.options.abortHandler) {
      this.abortHandler = this.options.abortHandler;
    }
    this._stopInputsPropagation = false;
    return this.enable(Shortcuts);
  };

  SelectionView.prototype.show = function(offset, currentItemValue) {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        _this.render();
        $('body').append(_this.$el);
        _this.viewsList = _this.$el.find('li');
        if (currentItemValue) {
          _this.selectCurrentItem(currentItemValue);
        } else {
          _this.setActiveViewItem(0);
        }
        _this.viewsCount = _this.viewsList.length;
        if (_this.options.compoundSelection) {
          _this.$el.addClass('compound-selection');
        }
        if (_this.options.className) {
          _this.$el.addClass(_this.options.className);
        }
        _this._display(offset);
        _this.delegate('mouseover', '.list-box-list li', _this.setActiveViewItemOnMouseOver);
        _this.delegate('click', '.list-box-list li:not(.disabled)', _this.acceptHandler);
        return _this.hidden = false;
      };
    })(this));
  };

  SelectionView.prototype._display = function(offset) {
    var elHeight, elWidth, right, rightOffset, top, topOffset, windowHeight;
    if (this.options.displayCssOptions) {
      return this.$el.css(_.extend({}, this.options.displayCssOptions));
    } else {
      elHeight = this.$el.outerHeight();
      elWidth = this.$el.outerWidth();
      windowHeight = window.innerHeight;
      top = offset.top, right = offset.right;
      rightOffset = Math.max(0, right != null ? right : $(window).width() - offset.left - elWidth);
      topOffset = top + elHeight > windowHeight ? top - (top + elHeight - windowHeight) : top;
      return this.$el.css({
        display: 'block',
        right: rightOffset,
        top: topOffset
      });
    }
  };

  SelectionView.prototype.selectCurrentItem = function(currentItemValue) {
    var currentView;
    currentView = currentItemValue ? this.$el.find("li[data-list-value='" + currentItemValue + "']") : this.$el.find("li").first();
    currentView.addClass('current');
    return this.setActiveViewItem(this.viewsList.index(currentView));
  };

  SelectionView.prototype.setActiveViewItemOnMouseOver = function(e) {
    return this.setActiveViewItem(this.viewsList.index($(e.target).closest('li')));
  };

  SelectionView.prototype.selectNextViewItem = function() {
    this.selectedRowIdx = (this.selectedRowIdx + 1) % this.viewsCount;
    this.setActiveViewItem(this.selectedRowIdx);
    return false;
  };

  SelectionView.prototype.selectPreviosViewItem = function() {
    this.selectedRowIdx = this.selectedRowIdx === 0 ? this.viewsCount - 1 : this.selectedRowIdx - 1;
    this.setActiveViewItem(this.selectedRowIdx);
    return false;
  };

  SelectionView.prototype.setActiveViewItem = function(selectedRowIdx) {
    this.selectedRowIdx = selectedRowIdx;
    return this.selectedItem = this.viewsList.eq(selectedRowIdx);
  };

  SelectionView.prototype.getSelectedItemValue = function() {
    return this.selectedItem.data('listValue');
  };

  SelectionView.prototype.getSelectedItemText = function() {
    return this.selectedItem.find('span').text();
  };

  SelectionView.prototype.acceptHandler = function(evt) {
    var _base;
    if (this.selectedItem.find('button').prop('disabled')) {
      return;
    }
    return typeof (_base = this.options).acceptHandler === "function" ? _base.acceptHandler(evt) : void 0;
  };

  SelectionView.prototype.abortHandler = function(e) {};

  SelectionView.prototype.hide = function() {
    if (!this.hidden) {
      this.$el.remove();
      this.hidden = true;
      return this.dispose();
    }
  };

  SelectionView.prototype.onFocusLost = function() {
    return this.hide();
  };

  SelectionView.prototype.getTemplateData = function() {
    var templateData;
    templateData = SelectionView.__super__.getTemplateData.apply(this, arguments);
    templateData.listItems = this.options.listItems;
    templateData.clearOption = this.options.clearOption;
    templateData.noTextTransform = this.options.noTextTransform;
    templateData.wrapLine = this.options.wrapLine;
    return templateData;
  };

  return SelectionView;

})(View);
