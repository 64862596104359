var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <tr>\n        <td class=\"grey-bg\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.comparator", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.comparison || (depth0 != null ? depth0.comparison : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparison","hash":{},"data":data}) : helper)))
    + "</td>\n        <td class=\"editable\">\n          <textarea rows=\"0\" class=\"comparator-input\" name=\"comparisonDirectnessDesc\">"
    + escapeExpression(((helper = (helper = helpers.comparisonDirectnessDesc || (depth0 != null ? depth0.comparisonDirectnessDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparisonDirectnessDesc","hash":{},"data":data}) : helper)))
    + "</textarea>\n        </td>\n        <td class=\"inputs-cell\">\n          "
    + escapeExpression(((helpers.directnessTableRadio || (depth0 && depth0.directnessTableRadio) || helperMissing).call(depth0, "comparisonDirectnessRadio", (depth0 != null ? depth0.comparisonDirectnessRadio : depth0), {"name":"directnessTableRadio","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div class=\"directness-box\">\n  <div class=\"title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.outcome", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\n  <table class=\"standard-table directness-table\">\n    <thead>\n      <tr class=\"grey-bg\">\n        <td class=\"domain\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.domain", {"name":"i18n","hash":{},"data":data})))
    + "</td>\n        <td class=\"description text-center\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.description", {"name":"i18n","hash":{},"data":data})))
    + "</td>\n        <td class=\"judgement\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.judgement", {"name":"i18n","hash":{},"data":data})))
    + "</td>\n      </tr>\n    </thead>\n    <tbody>\n      <tr>\n        <td class=\"grey-bg\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.population", {"name":"i18n","hash":{},"data":data})))
    + ": </td>\n        <td class=\"editable\">\n          <textarea class=\"population-input\" name=\"populationDirectnessDesc\">"
    + escapeExpression(((helper = (helper = helpers.populationDirectnessDesc || (depth0 != null ? depth0.populationDirectnessDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"populationDirectnessDesc","hash":{},"data":data}) : helper)))
    + "</textarea>\n        </td>\n        <td class=\"inputs-cell\">\n          "
    + escapeExpression(((helpers.directnessTableRadio || (depth0 && depth0.directnessTableRadio) || helperMissing).call(depth0, "populationDirectnessRadio", (depth0 != null ? depth0.populationDirectnessRadio : depth0), {"name":"directnessTableRadio","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr>\n        <td class=\"grey-bg\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.intervention", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + "\n        </td>\n        <td class=\"editable\">\n          <textarea class=\"intervention-input\" name=\"interventionDirectnessDesc\">"
    + escapeExpression(((helper = (helper = helpers.interventionDirectnessDesc || (depth0 != null ? depth0.interventionDirectnessDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"interventionDirectnessDesc","hash":{},"data":data}) : helper)))
    + "</textarea>\n        </td>\n        <td class=\"inputs-cell\">\n          "
    + escapeExpression(((helpers.directnessTableRadio || (depth0 && depth0.directnessTableRadio) || helperMissing).call(depth0, "interventionDirectnessRadio", (depth0 != null ? depth0.interventionDirectnessRadio : depth0), {"name":"directnessTableRadio","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.questionType : depth0), "prognostic", {"name":"isnt","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      <tr>\n        <td class=\"grey-bg\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.direct_comparison", {"name":"i18n","hash":{},"data":data})))
    + "</td>\n        <td class=\"editable\">\n          <textarea class=\"direct-comparision-input\" name=\"direct_comparisonDirectnessDesc\">"
    + escapeExpression(((helper = (helper = helpers.direct_comparisonDirectnessDesc || (depth0 != null ? depth0.direct_comparisonDirectnessDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"direct_comparisonDirectnessDesc","hash":{},"data":data}) : helper)))
    + "</textarea>\n        </td>\n        <td class=\"inputs-cell\">\n          "
    + escapeExpression(((helpers.directnessTableRadio || (depth0 && depth0.directnessTableRadio) || helperMissing).call(depth0, "direct_comparisonDirectnessRadio", (depth0 != null ? depth0.direct_comparisonDirectnessRadio : depth0), {"name":"directnessTableRadio","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr>\n        <td class=\"grey-bg\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.outcome", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</td>\n        <td class=\"editable\">\n          <textarea class=\"outcome-input\" name=\"outcomeDirectnessDesc\">"
    + escapeExpression(((helper = (helper = helpers.outcomeDirectnessDesc || (depth0 != null ? depth0.outcomeDirectnessDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"outcomeDirectnessDesc","hash":{},"data":data}) : helper)))
    + "</textarea>\n        </td>\n        <td class=\"inputs-cell\">\n          "
    + escapeExpression(((helpers.directnessTableRadio || (depth0 && depth0.directnessTableRadio) || helperMissing).call(depth0, "outcomeDirectnessRadio", (depth0 != null ? depth0.outcomeDirectnessRadio : depth0), {"name":"directnessTableRadio","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr class=\"final-judgement\">\n        <td class=\"grey-bg\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.final_judgement", {"name":"i18n","hash":{},"data":data})))
    + ": </td>\n        <td colspan=\"2\" class=\"inputs-cell text-right\">\n          <div class=\"input-block\">\n            <label>\n              <input type=\"radio\" name=\"indirectness\" ";
  stack1 = ((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.indirectness : depth0)) != null ? stack1.name : stack1), "None", {"name":"checkedIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n              <br>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.no_indirectness", {"name":"i18n","hash":{},"data":data})))
    + "\n            </label>\n          </div>\n          <div class=\"input-block\">\n            <label>\n              <input type=\"radio\" name=\"indirectness\" ";
  stack1 = ((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.indirectness : depth0)) != null ? stack1.name : stack1), "Serious", {"name":"checkedIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n              <br>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.serious_indirectness", {"name":"i18n","hash":{},"data":data})))
    + "\n            </label>\n          </div>\n          <div class=\"input-block\">\n            <label>\n              <input type=\"radio\" name=\"indirectness\"\n                ";
  stack1 = ((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.indirectness : depth0)) != null ? stack1.name : stack1), "VerySerious", {"name":"checkedIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n              <br>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.very_serious_indirectness", {"name":"i18n","hash":{},"data":data})))
    + "\n            </label>\n          </div>\n        </td>\n      </tr>\n    </tbody>\n  </table>\n  <div class=\"buttons row mt-10\">\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-cancel cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-apply finish apply\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.apply", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}