CollapsibleChapter = require 'components/common/collapsible_chapter'
ConnectStore = require 'components/enhancers/connect_store'
{
  formatMdgSearchStrategySearchDate
  MDG_SEARCH_STRATEGY_GENERAL_INFORMATION_FIELDS_TO_SHOW
  MDG_SEARCH_STRATEGY_SPEC_TREATMENT
  MDG_SEARCH_STRATEGY_SPEC_DIAGNOSTIC
  MDG_SEARCH_STRATEGY_TABS
} = require 'lib/mdg_helper'
IconButton = require 'components/common/icon_button'
EtdStore = require 'stores/etd_store'
MDGSearchStrategyActions = require 'actions/mdg_search_strategy_actions'
MDGSearchStrategyStore = require 'stores/mdg_search_strategy_store'
TextAreaField = require 'components/covid_extraction_form/textarea_field'
SearchStategySummary = require 'components/mdg_tables/search_strategy_summary'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

MDGSearchStrategyGeneralInformation = ({ searchStrategy }) ->
  i18n = useI18n('mdg_tables:search_strategy.general_information.fields')

  openModal = useCoffeeCallback [MDGSearchStrategyActions], ->
    MDGSearchStrategyActions.openGeneralInformationModal()

  <div className="mdg-search-strategy-tab__general-information">
    <IconButton
      className="general-information__edit-icon"
      iconName="edit"
      onClick={openModal}
    />
    {_.map MDG_SEARCH_STRATEGY_GENERAL_INFORMATION_FIELDS_TO_SHOW, (field) ->
      <div className={"general-information__field #{field}"} key={field}>
        <div className="field__title">{i18n field}</div>
        <div className="field__value">
          {if field is 'searchDate'
            formatMdgSearchStrategySearchDate(
              searchStrategy.getIn(['generalInformation', field]).toJS()
            )
          else
            searchStrategy.getIn(['generalInformation', field]) or '-'
          }
        </div>
      </div>
    }
  </div>

MDGSearchStrategyGeneralInformation.propTypes =
  searchStrategy: PropTypes.instanceOf(Immutable.Map).isRequired

storeConnector =
  MDGSearchStrategyStore: (Store) ->
    expandedRows: Store.getExpandedRows()
  EtdStore: (Store) ->
    isFetchingEtD: Store.isFetching()
    summary: Store.getConclusions().getIn(['sections', 'evidence', 'content'], '')

MDGSearchStrategyTab = ({ expandedRows, searchStrategy, tabId, summary, isFetchingEtD }) ->
  searchStrategyId = searchStrategy.get '_id'

  searchStrategyType = searchStrategy.get('searchStrategyType')
  searchStrategySpec = if searchStrategyType is 'diagnostic'
    MDG_SEARCH_STRATEGY_SPEC_DIAGNOSTIC
  else
    MDG_SEARCH_STRATEGY_SPEC_TREATMENT

  spec = searchStrategySpec[tabId]
  i18n = useI18n('mdg_tables:search_strategy')

  getColumns = useCoffeeCallback [spec, tabId], (sectionId) ->
    if tabId is 'scopus' and sectionId is 'diagnosisRCTTreatment'
      _.chain spec[sectionId].columns
        .map (columnId) ->
          return [columnId] unless columnId is 'whatYouTypedIn'
          ['whatYouTypedInDiagnosis', 'whatYouTypedInRCT', 'whatYouTypedInTreatment']
        .flatten()
        .value()
    else
      spec[sectionId].columns

  onChange = useCoffeeCallback [
    MDGSearchStrategyActions
    searchStrategyId
    tabId
  ], (typeId, columnId) -> (newValue) ->
    MDGSearchStrategyActions.updateCriterionField searchStrategyId,
      tabId, typeId, columnId, newValue

  onCollapse = useCoffeeCallback [MDGSearchStrategyActions], (typeId) -> ->
    MDGSearchStrategyActions.collapseRow typeId

  onExpand = useCoffeeCallback [MDGSearchStrategyActions], (typeId) -> ->
    MDGSearchStrategyActions.expandRow typeId

  <div className={"mdg-search-strategy-tab #{tabId} #{searchStrategyType}"}>
    <MDGSearchStrategyGeneralInformation searchStrategy={searchStrategy} />
    <div className="mdg-search-strategy-tab__inner">
      {_.map spec.sections, (sectionId) ->
        <div className={"inner__section #{sectionId}"} key={sectionId}>
          <CollapsibleChapter
            isExpanded={expandedRows.includes sectionId}
            onCollapse={onCollapse sectionId}
            onExpand={onExpand sectionId}
            title={i18n "section.#{sectionId}"}
          >
            {_.map getColumns(sectionId), (columnId) ->
              <div className={"type__column #{columnId}"} key={columnId}>
                <p className="column__title">
                  {i18n "columns.#{tabId}.#{sectionId}.#{columnId}"}
                </p>
                {
                  if sectionId is 'summary' and columnId is 'summary'
                    <SearchStategySummary
                      isFetching={isFetchingEtD}
                      cellId="evidence#conclusions"
                      content={summary}
                    />
                  else
                    <TextAreaField
                      onChange={onChange(sectionId, columnId)}
                      value={searchStrategy.getIn ['criteria', tabId, sectionId, columnId]}
                    />
                }
              </div>
            }
          </CollapsibleChapter>
        </div>
      }
    </div>
  </div>

MDGSearchStrategyTab.propTypes =
  expandedRows: PropTypes.instanceOf(Immutable.List).isRequired
  searchStrategy: PropTypes.instanceOf(Immutable.Map).isRequired
  tabId: PropTypes.oneOf(MDG_SEARCH_STRATEGY_TABS).isRequired

module.exports = ConnectStore MDGSearchStrategyTab,
  [EtdStore, MDGSearchStrategyStore], storeConnector
