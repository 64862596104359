module.exports = function() {
  return {
    chapters: {
      sectionsOrder: [],
      sections: {
        abstract_Common: {
          name: 'Abstract',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        executiveSummary_Common: {
          name: 'Executive summary',
          subsections: [
            {
              title: 'Methods',
              titleHidden: false,
              content: ''
            }, {
              title: 'Interpretation of strong and conditional recommendations',
              titleHidden: false,
              content: ''
            }, {
              title: 'How to use these guidelines',
              titleHidden: false,
              content: ''
            }, {
              title: 'Recommendations',
              titleHidden: false,
              content: ''
            }
          ]
        },
        tableOfContents_Common: {
          name: 'Table of contents',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        introduction_Common: {
          name: 'Introduction',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        scopeAndPurpose_Common: {
          name: 'Scope and purpose',
          subsections: [
            {
              title: 'Target audience',
              titleHidden: false,
              content: ''
            }, {
              title: 'How to use these guidelines',
              titleHidden: false,
              content: ''
            }
          ]
        },
        methods_Common: {
          name: 'Methods',
          subsections: [
            {
              title: 'Group composition',
              titleHidden: false,
              content: ''
            }, {
              title: 'Group interaction and processes',
              titleHidden: false,
              content: ''
            }, {
              title: 'Declaration and management of competing interests ',
              titleHidden: false,
              content: ''
            }, {
              title: 'Selection of questions and outcomes of interest',
              titleHidden: false,
              content: ''
            }, {
              title: 'Evidence review and development of clinical recommendations',
              titleHidden: false,
              content: ''
            }, {
              title: 'Use of indirect evidence',
              titleHidden: false,
              content: ''
            }, {
              title: 'Document review',
              titleHidden: false,
              content: ''
            }
          ]
        },
        howToUse_Common: {
          name: 'How to use these guidelines',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        generalIssues_Common: {
          name: 'General issues necessary for correct interpretation and implementation of recommendations',
          subsections: [
            {
              title: 'Assumed Values and preferences',
              titleHidden: false,
              content: ''
            }, {
              title: 'Recommendations for children',
              titleHidden: false,
              content: ''
            }, {
              title: 'Coexisting conditions',
              titleHidden: false,
              content: ''
            }
          ]
        },
        keyQuestions_Common: {
          name: 'Key questions',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        recommendations_Common: {
          name: 'Recommendations',
          subsections: [
            {
              title: 'Table summarizing recommendations',
              titleHidden: false,
              content: ''
            }, {
              title: '<Question 1>',
              titleHidden: false,
              content: {
                "blocks": [
                  {
                    "key": "96qh9",
                    "text": "Introduction",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "bm44i",
                    "text": "Summary of the evidence",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "an4a0",
                    "text": "Benefits",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "3glsk",
                    "text": "Harms and burden",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "aok2u",
                    "text": "Decision criteria and additional considerations",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "440k7",
                    "text": "Conclusions and research needs",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "dsgs0",
                    "text": "What others are saying",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "b7bsu",
                    "text": "",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "2quoq",
                    "text": "Recommendation 1...",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "1kt3f",
                    "text": " (Assumed values and preferences",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }, {
                    "key": "baovn",
                    "text": " Explanations and other considerations)",
                    "type": "unstyled",
                    "depth": 0,
                    "inlineStyleRanges": [],
                    "entityRanges": [],
                    "data": {}
                  }
                ],
                "entityMap": {}
              }
            }
          ]
        },
        updatingGuidelinesPlans_Common: {
          name: 'Plans for updating these guidelines',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        updatingRecommendationsLocally_Common: {
          name: 'Updating or adapting recommendations locally',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        conclusions_Common: {
          name: 'Conclusions',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        references_Common: {
          name: 'References',
          type: 'references',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        appendices_Common: {
          name: 'Appendices',
          subsections: [
            {
              title: 'Appendix 1',
              titleHidden: false,
              content: ''
            }
          ]
        }
      }
    }
  };
};
