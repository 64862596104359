var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <div>\n      <div class=\"dbep-publication-question-filter\">\n        <span class=\"dbep-publication-question-filter__text\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:publish.choose_question", {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n        <div class=\"dbep-publication-question-filter__input-with-icon\">\n          <input type=\"text\">\n        </div>\n      </div>\n      <label class=\"dbep-publication-select-all-questions dbep-publication__check-item\">\n        <input type=\"checkbox\" class=\"dbep-publication-select-all-questions__checkbox\" value=\"_all\">\n        <span class=\"dbep-publication-select-all-questions__text\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.select_all", {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n      </label>\n      <div class=\"questions-list\"></div>\n    </div>\n";
},"3":function(depth0,helpers,partials,data) {
  return "disabled";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div>\n  ";
  stack1 = ((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"text","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.mode : depth0), "publish", {"name":"isnt","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n<div class=\"row mt-20\">\n  <div class=\"col-6\">\n    <button class=\"btn btn-block btn-cancel\" data-role=\"cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n  <div class=\"col-6\">\n    <button\n      class=\"btn btn-block btn-success\"\n      data-role=\"ok\"\n      ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.mode : depth0), "publish", {"name":"isnt","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    >\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.ok", {"name":"i18n","hash":{},"data":data})))
    + "\n    </button>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}