Input = require 'components/common/input'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

QuestionTxEdit = createReactClass
  displayName: 'QuestionTxEdit'

  propTypes:
    data: PropTypes.object.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:question')
  ]

  getData: ->
    intervention: @_intervention.value
    comparison: @_comparison.value
    healthProblemOrPopulation: @_healthProblemOrPopulation.value
    questionFormat: @_questionFormat.value

  componentDidMount: ->
    @_intervention.focus()

  render: ->
    <div className='management expanded'>
      {@i18n 'should'}
      {' '}
      <Input
        id='intervention'
        inputRef={(el) => @_intervention = el} defaultValue={@props.data.get 'intervention'}
      />
      {' '}
      {@i18n 'vs'}
      {' '}
      <Input
        id='comparison'
        inputRef={(el) => @_comparison = el} defaultValue={@props.data.get 'comparison'}
      />
      {' '}
      {@i18n 'be_used'}
      {' '}
      <select
        id='questionFormat'
        ref={(el) => @_questionFormat = el} defaultValue={@props.data.get 'questionFormat'}
      >
        <option value='FOR_HP'>{@i18n 'for_hp_label'}</option>
        <option value='IN_POP'>{@i18n 'in_pop_label'}</option>
      </select>
      {' '}
      <Input
        id='healthProblemOrPopulation'
        inputRef={(el) => @_healthProblemOrPopulation = el}
        defaultValue={@props.data.get 'healthProblemOrPopulation'}
      />
      {'?'}
    </div>

module.exports = QuestionTxEdit
