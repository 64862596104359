{ CellWithTitle } = ReactComponents

PrintoutCellTitlesRow = ({ i18n, renderMode }) ->
  return null if renderMode isnt 'printout'
  <tr className='section-parts-title-row'>
    <CellWithTitle
      className='judgement'
      cellTitle={i18n 'judgement'}
    />
    <CellWithTitle
      className='research-evidences'
      cellTitle={i18n 'research_evidence'}
    />
    <CellWithTitle
      className='additional-considerations'
      cellTitle={i18n 'additional_considerations'}
    />
  </tr>


module.exports = PrintoutCellTitlesRow
