TextWithHelpLink = createReactClass
  displayName: 'TextWithHelpLink'

  _getParts: -> @props.text.split /\{([\w]+)\}/

  _getInfoLink: (infoFor, linkText) ->
    <a className="info-link" onClick={@props.onInfoLinkClick(infoFor)}>{linkText}</a>

  _isLinkPlaceholder: (text) ->
    /^link[\d]+/.test text

  render: ->
    <span>
      {@_getParts().map (partText, idx) =>
        if @_isLinkPlaceholder partText
          [infoFor, linkText] = @props.links[partText]
          <span key={idx}>
            {@_getInfoLink infoFor, linkText}
          </span>
        else
          <span key={idx}>{partText}</span>
      }
    </span>

module.exports = TextWithHelpLink
