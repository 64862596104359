CustomRenderMixin = require 'components/mixins/custom_render_mixin'

Row = createReactClass

  displayName: "EditableTableRow"

  mixins: [CustomRenderMixin]

  render: ->
    @props.children

module.exports = Row
