Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
MultiComparisonsActions = require 'actions/multi_comparisons_actions'
MultiComparisonsStore = require 'stores/multi_comparisons_store'
SelectCustom = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
{ string } = PropTypes

storeConnectors =
  MultiComparisonsStore: (Store) ->
    editingComparison: Store.isEditingComparison()
    comparisonsEditData: Store.getComparisonsEditData()
    multiComparisonsList: Store.getMultiComparisonsList()
    isShowingExportDialog: Store.isShowingExportDialog()

MultiComparisonsToolbar = createReactClass
  displayName: "MultiComparisonsToolbar"

  propTypes:
    view: string

  mixins: [Translation('')]

  getDefaultProps: ->
    view: ''

  getInitialState: ->
    showExportDialog: false

  getOptions: ->
    @props.multiComparisonsList.reduce (acc, comparison) ->
      acc.push({value: comparison.get('_id'), text: comparison.get('overarchingQuestion')})
      acc
    ,[]

  changeMulticomparison: (mcId) ->
    MultiComparisonsActions.editMultiComparison mcId

  render: ->
    { editingComparison, comparisonsEditData } = @props
    # TODO use routing instead of checking for editingComparisons etc.
    <div className='multi-comparisons-toolbar'>
      {editingComparison and editingComparison isnt 'new' and comparisonsEditData.get('_id') and
        <SelectCustom
          orderedList={true}
          signPosition="left"
          className="multi-comparison__overarching-question-select"
          listClassName="standard-list"
          options={@getOptions()}
          selected={comparisonsEditData.get('_id')}
          onChange={@changeMulticomparison}
        />
      }
      <Button
        className='export'
        title={@i18n 'actions.export'}
        onClick={MultiComparisonsActions.showExportDialog}
        disabled={@props.isShowingExportDialog or @props.view isnt 'details'}
      />
    </div>

module.exports = ConnectStore MultiComparisonsToolbar, MultiComparisonsStore, storeConnectors
