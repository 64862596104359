var HelpResources, W, mediator, utils;

utils = require('base/lib/utils');

mediator = require('mediator');

W = require('when/when');

module.exports = HelpResources = (function() {
  HelpResources.prototype._resourcesRoot = 'help';

  HelpResources.prototype._translationsDir = function() {
    if (utils.getCurrentLanguage() === 'en') {
      return null;
    } else {
      return "translations/" + (utils.getCurrentLanguage());
    }
  };

  function HelpResources() {
    this._buildResourcesMap();
    mediator.subscribe('moduleChanged', (function(_this) {
      return function(module) {
        _this.moduleName = (function() {
          switch (module) {
            case 'evidence_syntheses':
              return 'question';
            default:
              return '';
          }
        })();
        return _this.submoduleName = '';
      };
    })(this));
    mediator.subscribe('submoduleChanged', (function(_this) {
      return function(submodule) {
        return _this.submoduleName = (function() {
          switch (submodule) {
            case 'quality-of-evidence':
              return 'es';
            case 'recommendations':
              return 'recommendations';
            case 'presentations':
              return 'presentations';
            default:
              return '';
          }
        })();
      };
    })(this));
  }

  HelpResources.prototype._buildResourcesMap = function() {
    return W($.get("" + this._resourcesRoot + "/help_files.txt", {
      contentType: 'text/text'
    })).then((function(_this) {
      return function(response) {
        return _this.resourcesList = _(response.split('\n')).map(function(path) {
          return "" + _this._resourcesRoot + "/" + (_.string.ltrim(path, './'));
        });
      };
    })(this)).otherwise(function() {
      return mediator.dialogs.fatalError;
    });
  };

  HelpResources.prototype._pathForResource = function(property, translated) {
    return _([this._resourcesRoot, translated ? this._translationsDir() : void 0, this.moduleName, this.submoduleName, "" + (_.string.underscored(property)) + ".html"]).compact().join('/');
  };

  HelpResources.prototype.resourceFor = function(property) {
    var path, translPath;
    translPath = this._pathForResource(property, true);
    path = this._pathForResource(property, false);
    if (_(this.resourcesList).contains(translPath)) {
      return translPath;
    } else if (_(this.resourcesList).contains(path)) {
      return path;
    }
  };

  HelpResources.prototype.helpEnabled = function(property) {
    return mediator.helpResources.resourceFor(property) != null;
  };

  return HelpResources;

})();
