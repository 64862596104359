var PageView, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

View = require('base/views/view');

module.exports = PageView = (function(_super) {
  __extends(PageView, _super);

  function PageView() {
    return PageView.__super__.constructor.apply(this, arguments);
  }

  PageView.prototype.container = '#page-container';

  PageView.prototype.autoRender = true;

  PageView.prototype.renderedSubviews = false;

  PageView.prototype.initialize = function() {
    var rendered;
    PageView.__super__.initialize.apply(this, arguments);
    if (this.model || this.collection) {
      rendered = false;
      return this.modelBind('change', (function(_this) {
        return function() {
          if (!rendered) {
            _this.render();
          }
          return rendered = true;
        };
      })(this));
    }
  };

  PageView.prototype.renderSubviews = function() {};

  PageView.prototype.render = function() {
    PageView.__super__.render.apply(this, arguments);
    if (!this.renderedSubviews) {
      this.renderSubviews();
      return this.renderedSubviews = true;
    }
  };

  return PageView;

})(View);
