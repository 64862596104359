ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
GlobalExportActions = require 'actions/global_export_actions'
GlobalExportStore = require 'stores/global_export_store'
Translation = require 'components/mixins/translation'

ExportError = createReactClass
  displayName: 'GlobalExportError'
  mixins: [Translation('projects:global_export_dialog.export_error')]

  onExport: ->
    exportOptions = GlobalExportStore.getExportOptions()
    GlobalExportActions.export exportOptions

  render: ->
    <div className="global-export-modal__export-error">
      <div className="export-error__header">{@i18n 'title'}</div>
      <div className="export-error__description">{@i18n 'description'}</div>
      <div className="export-error__image" />
      <ApplyCancelButtons
        onApply={@onExport}
        onCancel={GlobalExportActions.closeModal}
        applyLabel={@i18n '/actions.try_again'}
        isSubmitEnabled
      />
    </div>

module.exports = ExportError
