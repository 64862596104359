ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Button = require 'components/common/button'
Modal = require 'components/common/modal'
InsertOptions = require 'components/etd/etd_insert_options'
Select = require 'components/common/select'
Translation = require 'components/mixins/translation'
OutcomesSelect = require 'components/common/outcomes_select'
EtdActions = require 'actions/etd_actions'
EtdStore = require 'stores/etd_store'
QuestionsStore = require 'stores/questions_store'
CreateStoreMixin = require 'components/mixins/create_store'
embeddedContentProvider = require 'lib/embedded_content'
mediator = require 'mediator'

INSERT_OPTIONS =
  tx: ['relative_importance', 'sof_short', 'sof_v1', 'sof_v2', 'sof_v3']
  dx: ['test_accuracy', 'evidence_short', 'l1_sof', 'l2_sof']
  qualityIndicators: ['quality_indicators']
  common: ['image']

InsertModal = createReactClass
  displayName: 'InsertModal'

  mixins: [
    Translation('es:recommendations.insert_modal')
    CreateStoreMixin(EtdStore)
  ]

  propTypes:
    onClose: PropTypes.func.isRequired
    onInsert: PropTypes.func.isRequired
    additionalOptions: PropTypes.object

  getInitialState: ->
    selectedOption: ''
    file: null
    selectedQuestion: null

  getStateFromStores: ->
    defaultQuestion: QuestionsStore.getQuestionModel()
    questions: QuestionsStore.getQuestions()

  getActiveQuestionType: ->
    { selectedQuestion, questions } = @state
    activeQuestion = questions.find (q) -> selectedQuestion is q.get '_id'
    return null unless activeQuestion?

    if activeQuestion.get('type') is 'diagnostic'
      'dx'
    else if @props.additionalOptions?.activeQuestion
      @props.additionalOptions.activeQuestion
    else 'tx'

  getActiveQuestionSelectedOutcomes: ->
    EtdStore.getInitialSelectedOutcomes @state.selectedQuestion

  getOutcomes: ->
    QuestionsStore.getAllOutcomes @state.selectedQuestion

  getInsertOptions: ->
    activeQuestionType = @getActiveQuestionType()
    return INSERT_OPTIONS.common unless activeQuestionType

    INSERT_OPTIONS[activeQuestionType].concat INSERT_OPTIONS.common

  getInsertSpec: ->
    { selectedOption, file, selectedQuestion } = @state
    selectedOutcomes = @getActiveQuestionSelectedOutcomes()
    return { contentType: 'ATTACHMENT', file } if file

    tableData = embeddedContentProvider.getTableData selectedOption, selectedOutcomes,
      selectedQuestion

    switch selectedOption
      when 'test_accuracy'
        contentType = 'HTML'
        content = Handlebars.partials.testAccuracySummary tableData.toJS()
        { contentType, content }
      when 'quality_indicators'
        contentType = 'GDT_TABLE'
        content =
          type: selectedOption
          renderMode: 'printout'
          data: { qualityIndicators: @props.additionalOptions?.qualityIndicators}
        { contentType, content }
      else
        contentType = 'GDT_TABLE'
        content =
          type: selectedOption
          qId: selectedQuestion
          selectedOutcomes: selectedOutcomes.toJS()
          data: tableData
        { contentType, content }

  submitInsert: ->
    @props.onInsert @getInsertSpec()

  onQuestionchange: (qId) ->
    @setState
      selectedQuestion: qId
      selectedOption: ''

  onOptionSelect: (e) ->
    selectedOption = e.target.value
    @setState {selectedOption}
    @_showOutcomesSelectList e.target.value unless @getActiveQuestionType() is 'dx'

  isSubmitDisabled: ->
    { selectedOption, file } = @state
    selectedOutcomes = @getActiveQuestionSelectedOutcomes()

    return false if selectedOption is 'quality_indicators'

    if selectedOption is 'image'
      not file
    else if @getActiveQuestionType() isnt 'dx'
      not selectedOption or selectedOutcomes.size is 0
    else
      not selectedOption

  getQuestionsSelectOptions: ->
    @state.questions
      .map (q) ->
        value: q.get '_id'
        text: q.get 'question'
      .toArray()

  getImageInsertState: ->
    @state.selectedOption isnt 'image'

  onFileSelect: (file) -> @setState { file }

  _updateSelectedOutcomes: (outcomes) ->
    qId = @state.selectedQuestion
    EtdActions.updateSelectedOutcomes { qId, outcomes }

  _showOutcomesSelectList: (value) ->
    @setState outcomesListTarget: if value in ['image', 'quality_indicators'] then null else value

  componentWillMount: ->
    @setState selectedQuestion: @state.defaultQuestion?.id ? @state.questions.first()?.get '_id'

  render: ->
    <Modal isOpen
      className='etd-insert-modal'
      onClose={@props.onClose}
      title={@i18n 'title'}
      closeButton
    >
      <div>
        <div className="insert-options">
          {if @state.questions.size > 1
            <div>
              <span>Question:</span>
              <Select
                options={@getQuestionsSelectOptions()}
                selected={@state.selectedQuestion}
                onChange={@onQuestionchange}
                style={width: '100%'}
              />
            </div>
          }
          <InsertOptions
            insertOptions={@getInsertOptions()}
            outcomes={@getOutcomes()}
            outcomesListTarget={@state.outcomesListTarget}
            isFileUploadDisabled={@getImageInsertState()}
            selectedOption={@state.selectedOption}
            selectedOutcomes={@getActiveQuestionSelectedOutcomes()}
            onOptionSelect={@onOptionSelect}
            onFileSelect={@onFileSelect}
            updateSelectedOutcomes={@_updateSelectedOutcomes}
          />
        </div>

        <ApplyCancelButtons
          onApply={@submitInsert}
          onCancel={@props.onClose}
          isSubmitEnabled={not @isSubmitDisabled()}
          applyLabel={@i18n '/translation:actions.insert'}
        />
      </div>
    </Modal>

module.exports = InsertModal
