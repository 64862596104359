OutcomesTableViewActions = require 'actions/outcomes_table_view_actions'
EditableCell = require 'components/evidence_syntheses/outcomes/cells/editable_cell'
{ CompoundEdit, CompoundEditSection, CompoundEditBlock } =
  require 'components/evidence_syntheses/outcomes/cells/compound_edit'
ValidatedInput = require 'components/common/validated_input'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
formatters = require 'lib/formatters'
{ getKeyValObject } = require 'base/lib/utils'


lowToHighControlNames = [ 'lowControl', 'moderateControl', 'highControl' ]

updateOutcomeAttribute = _.compose(
  OutcomesTableViewActions.updateEditedOutcomeAttributes
  getKeyValObject
)

ExtendedControlCell = createReactClass
  displayName: 'ExtendedControlCell'

  propTypes: {
    editMetadata: PropTypes.instanceOf(Immutable.Map)
    allValid: PropTypes.func.isRequired # provided by withValidation enhancer
    validate: PropTypes.func.isRequired # provided by withValidation enhancer
  }

  mixins: [
    Translation('es:outcome')
    CustomRenderMixin
  ]

  _ref: (el) ->
    @el = el

  _controlRiskRef: (controlName) -> (el) =>
    @[controlName] = el

  _getInputValue: (attrName) ->
    @props.editMetadata?.getIn(['editedAttributes', attrName]) ? @props.outcomeAttrs[attrName]

  handleValidatedInputChange: (evt) ->
    attrName = evt.target.getAttribute 'name'
    value = evt.target.value.trim()

    # re-calc calculatedControlValue if controlCount or controlTotal got changed
    if attrName in ['controlCount', 'controlTotal']
      calculatedControlValue = formatters.outcome.controlRatio {
        controlCount: if attrName is 'controlCount' then value else @_getInputValue 'controlCount'
        controlTotal: if attrName is 'controlTotal' then value else @_getInputValue 'controlTotal'
      }
      updatedValuesObj = _.extend getKeyValObject(attrName, value), { calculatedControlValue }

      OutcomesTableViewActions.updateEditedOutcomeAttributes updatedValuesObj
    else
      updateOutcomeAttribute attrName, value

  handleControlCheckboxChange: (evt) ->
    attrName = evt.target.getAttribute 'name'
    checked = evt.target.checked

    updateOutcomeAttribute attrName, checked

  handleApply: ->
    OutcomesTableViewActions.saveEditedAttributes()
    @resetEdit()

  resetEdit: ->
    @el.stopEdit()

  renderControlRiskSection: (controlName) ->
    { outcomeAttrs, validate } = @props
    controlLabelAttrName = "#{controlName}Label"
    controlValueAttrName = "#{controlName}Risk"
    isControlEnabled = @_getInputValue controlName

    <CompoundEditBlock label={@i18n _.str.underscored controlLabelAttrName} key={controlName}>
      <ValidatedInput
        checked={isControlEnabled}
        name={controlName}
        onChange={@handleControlCheckboxChange}
        ref={@_controlRiskRef(controlName)}
        type='checkbox'
        validation={validate 'controlRiskCount'}
      />
      <input
        value={@_getInputValue controlLabelAttrName}
        disabled={not isControlEnabled}
        type='text'
        name={controlLabelAttrName}
        onChange={@handleValidatedInputChange}
      />
      <ValidatedInput
        value={@_getInputValue controlValueAttrName}
        disabled={not isControlEnabled}
        name={controlValueAttrName}
        onChange={@handleValidatedInputChange}
        type='text'
        validation={validate controlValueAttrName}
      />
    </CompoundEditBlock>

  render: ->
    { outcomeAttrs, editMetadata, validate, allValid } = @props

    <EditableCell {...@props} ref={@_ref} title={@i18n 'control'}>
      <CompoundEdit applyEnabled={allValid()} onApply={@handleApply} onCancel={@resetEdit}>
        <CompoundEditSection className='control-data'>
          <CompoundEditBlock label={@i18n 'with_event'}>
            <ValidatedInput
              name='controlCount'
              onChange={@handleValidatedInputChange}
              type='text'
              validation={validate 'controlCount'}
              value={@_getInputValue 'controlCount'}
            />
          </CompoundEditBlock>
          <CompoundEditBlock label={@i18n 'total'}>
            <ValidatedInput
              name='controlTotal'
              onChange={@handleValidatedInputChange}
              type='text'
              validation={validate 'controlTotal'}
              value={@_getInputValue 'controlTotal'}
            />
          </CompoundEditBlock>
          <CompoundEditBlock label={@i18n 'calculated_control_label'}>
            <ValidatedInput
              checked={@_getInputValue 'calculatedControl'}
              name='calculatedControl'
              onChange={@handleControlCheckboxChange}
              ref={@_controlRiskRef('calculatedControl')}
              type='checkbox'
              validation={validate 'controlRiskCount'}
            />
            <input type='text' value={@_getInputValue 'calculatedControlValue'} disabled />
          </CompoundEditBlock>
        </CompoundEditSection>
        <CompoundEditSection className='control-risk-' sectionTitle={@i18n 'control_risk'}>
          {lowToHighControlNames.map @renderControlRiskSection}
        </CompoundEditSection>
      </CompoundEdit>
    </EditableCell>

module.exports = ExtendedControlCell
