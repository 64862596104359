var MDGQuestionDoc, ReferencesActions, W, appUtils, callMethod, generateActions, generateGUID, goToLastRow, mediator, _ref;

appUtils = require('lib/app_utils');

_ref = require('base/lib/utils'), callMethod = _ref.callMethod, generateGUID = _ref.generateGUID;

generateActions = require('actions/utils/generate_actions');

MDGQuestionDoc = require('lib/db_docs/mdg_question_doc');

mediator = require('mediator');

ReferencesActions = require('actions/references_actions');

W = require('when');

goToLastRow = function() {
  var elements, lastEl;
  elements = document.getElementsByClassName('mdg-table-edit-component');
  lastEl = _.last(elements);
  if (!lastEl) {
    return;
  }
  return lastEl.scrollIntoView({
    behavior: 'smooth',
    block: 'end'
  });
};

module.exports = generateActions({
  autoGenerate: ['fetchSuccess', 'fetchError', 'collapseRow', 'editRow', 'expandRow', 'saveRow', 'openExportDialog', 'closeExportDialog', 'openDuplicateStudyDialog', 'closeDuplicateStudyDialog', 'studyDuplicationFinished', 'openParentStudyDialog', 'closeParentStudyDialog', 'selectParentStudy', 'connectParentStudyFinished'],
  name: 'MDGTableActions',
  actions: {
    fetch: function(_arg) {
      var dbId, docId, fetchReferences, throwErr;
      dbId = _arg.dbId, docId = _arg.docId, fetchReferences = _arg.fetchReferences, throwErr = _arg.throwErr;
      if (throwErr == null) {
        throwErr = false;
      }
      if (fetchReferences == null) {
        fetchReferences = true;
      }
      this.dispatch();
      if (fetchReferences) {
        ReferencesActions.fetch();
      }
      return MDGQuestionDoc.at(dbId, docId).fetch().then((function(_this) {
        return function(question) {
          _this.actions.fetchSuccess(question);
          return question;
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          _this.actions.fetchError(err);
          if (throwErr) {
            throw err;
          }
        };
      })(this));
    },
    dbChange: function(change, _pending, _lastSeq) {
      var doc;
      doc = change.doc;
      if (doc.docType === 'reference') {
        return ReferencesActions.dbChange(change, _pending, _lastSeq);
      } else {
        return this.dispatch(change);
      }
    },
    addRow: function(questionId) {
      var studyId;
      studyId = generateGUID();
      this.dispatch(studyId);
      return MDGQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('addStudy', studyId)).then(function() {
        return setTimeout(goToLastRow, 100);
      })["catch"](appUtils.errorHandler);
    },
    duplicateRow: function(questionId, studyId) {
      var newStudyId;
      newStudyId = generateGUID();
      this.dispatch(newStudyId);
      return MDGQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('duplicateStudy', studyId, newStudyId)).then(function() {
        return setTimeout(goToLastRow, 100);
      })["catch"](appUtils.errorHandler);
    },
    deleteRow: function(questionId, studyId) {
      this.dispatch(studyId);
      return MDGQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('deleteStudy', studyId))["catch"](appUtils.errorHandler);
    },
    updateStudy: function(questionId, studyId, field, value) {
      var comments, scoreValue;
      if (field === 'score') {
        scoreValue = value.value, comments = value.comments;
        return MDGQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('updateStudyScoreAndComment', studyId, scoreValue, comments))["catch"](appUtils.errorHandler);
      } else {
        return MDGQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('updateStudy', studyId, field, value))["catch"](appUtils.errorHandler);
      }
    },
    duplicateStudyToAnotherQuestion: function(study, targetQuestionIds) {
      var studyId, studyWithNoParentStudy;
      this.dispatch();
      studyId = generateGUID();
      studyWithNoParentStudy = study["delete"]('parentStudy').toJS();
      return W.all(targetQuestionIds.map(function(questionId) {
        return MDGQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('addStudy', studyId, studyWithNoParentStudy));
      }).toJS()).then((function(_this) {
        return function() {
          _this.actions.studyDuplicationFinished();
          return mediator.dialogs.info({
            message: $.t('mdg_tables:duplicate_study_dialog.duplicate_study_success')
          });
        };
      })(this))["catch"](appUtils.errorHandler);
    },
    connectParentStudy: function(questionId, studyId, parentStudyId) {
      this.dispatch();
      return MDGQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('connectParentStudy', studyId, parentStudyId)).then(function() {
        return mediator.dialogs.info({
          message: $.t('mdg_tables:parent_study_selector_dialog.connect_success')
        });
      })["catch"](appUtils.errorHandler)["finally"]((function(_this) {
        return function() {
          return _this.actions.connectParentStudyFinished();
        };
      })(this));
    },
    disconnectParentStudy: function(questionId, studyId) {
      var confirmOptions;
      confirmOptions = {
        title: $.t('mdg_tables:parent_study_disconnect.confirm_title'),
        message: $.t('mdg_tables:parent_study_disconnect.confirm_text'),
        confirmText: $.t('actions.disconnect'),
        declineText: $.t('actions.cancel')
      };
      return mediator.dialogs.confirm(confirmOptions, function(confirmed) {
        if (!confirmed) {
          return;
        }
        return MDGQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('disconnectParentStudy', studyId)).then(function() {
          return mediator.dialogs.info({
            message: $.t('mdg_tables:parent_study_disconnect.disconnect_success')
          });
        })["catch"](appUtils.errorHandler);
      });
    }
  }
});
