var ArchieRemovalDialog, ArchieRemovalDialogView, ReactComponent, View, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ArchieRemovalDialog = require('components/archie/archie_removal_dialog');

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

View = require('base/views/view');

module.exports = ArchieRemovalDialogView = (function(_super) {
  __extends(ArchieRemovalDialogView, _super);

  function ArchieRemovalDialogView() {
    this.onToggleRead = __bind(this.onToggleRead, this);
    this.onClose = __bind(this.onClose, this);
    return ArchieRemovalDialogView.__super__.constructor.apply(this, arguments);
  }

  ArchieRemovalDialogView.prototype.isOpen = false;

  ArchieRemovalDialogView.prototype.isRead = false;

  ArchieRemovalDialogView.prototype.autoRender = true;

  ArchieRemovalDialogView.prototype.isRendered = false;

  ArchieRemovalDialogView.prototype.initialize = function() {
    var isArchieRemovalDialogRead, _ref;
    ArchieRemovalDialogView.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    isArchieRemovalDialogRead = (_ref = mediator.user.get('isArchieRemovalDialogRead')) != null ? _ref : false;
    this.isRead = isArchieRemovalDialogRead;
    return mediator.services.externalAccounts.getConnectionStatus('archie', mediator.user.get('name'))["catch"](function() {
      return {
        connected: false
      };
    }).then((function(_this) {
      return function(_arg) {
        var connected;
        connected = _arg.connected;
        if (_this.disposed) {
          return;
        }
        _this.isOpen = !isArchieRemovalDialogRead && connected;
        if (!_this.isRendered) {
          return;
        }
        return _this.updateRenderedComponent(_this._getComponentProps());
      };
    })(this));
  };

  ArchieRemovalDialogView.prototype.onClose = function() {
    this.isOpen = false;
    return this.updateRenderedComponent(this._getComponentProps());
  };

  ArchieRemovalDialogView.prototype.onToggleRead = function() {
    this.isRead = !this.isRead;
    mediator.user.save({
      isArchieRemovalDialogRead: this.isRead
    });
    return this.updateRenderedComponent(this._getComponentProps());
  };

  ArchieRemovalDialogView.prototype._getComponentProps = function() {
    return {
      isOpen: this.isOpen,
      isRead: this.isRead,
      onClose: this.onClose,
      onToggleRead: this.onToggleRead
    };
  };

  ArchieRemovalDialogView.prototype.afterRender = function() {
    ArchieRemovalDialogView.__super__.afterRender.apply(this, arguments);
    this.isRendered = true;
    return this.renderSimpleComponent(ArchieRemovalDialog, this._getComponentProps());
  };

  ArchieRemovalDialogView.prototype.dispose = function() {
    this.unmountComponent();
    return ArchieRemovalDialogView.__super__.dispose.apply(this, arguments);
  };

  return ArchieRemovalDialogView;

})(View);
