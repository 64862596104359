Validation = require 'base/validation'
Tooltip = require 'components/common/tooltip'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

{ string } = PropTypes

ValidationContainer = createReactClass

  displayName: "ValidationContainer"

  mixins: [CustomRenderMixin]

  propTypes:
    errorMsg: string
    className: string
    tipJoint: string
    targetJoint: string

  getDefaultProps: ->
    tipJoint: "left"
    targetJoint: "right"

  componentDidMount: ->
    @tooltipContainer = ReactDOM.findDOMNode(this)

  render: ->
    className = classNames 'validation-container', @props.className, invalid: @props.errorMsg
    {tipJoint, targetJoint} = @props
    <Tooltip
      targetJoint={targetJoint}
      tipJoint={tipJoint}
      tooltipContainer={@tooltipContainer}
      tooltipType="reactValidationError">
      <div ref="validationEl" className={className} title={@props.errorMsg}>
        {@props.children}
      </div>
    </Tooltip>


module.exports = ValidationContainer
