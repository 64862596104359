Translation = require 'components/mixins/translation'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Modal = require 'components/common/modal'
Input = require 'components/common/input'
Label = require 'components/common/label'
ChackboxInput = require 'components/common/checkbox_input'

EditSubchapterModal = createReactClass
  displayName: 'EditSubchapterModal'

  mixins: [
    Translation('docsec:from_template')
  ]

  propTypes:
    isOpen: PropTypes.bool
    initialTitle: PropTypes.string
    initialTitleHidden: PropTypes.bool
    onSave: PropTypes.func.isRequired
    onCancel: PropTypes.func.isRequired

  getInitialState: ->
    title: R.defaultTo '', @props.initialTitle
    titleHidden: R.defaultTo false, @props.initialTitleHidden

  handleTitleUpdate: (e) ->
    @setState title: e.target.value

  toggleTitleHidden: ->
    @setState titleHidden: not @state.titleHidden

  onApply: ->
    @props.onSave @state.title, @state.titleHidden

  render: ->
    <Modal
      isOpen={@props.isOpen}
      className="document_sections__edit-chapter-subsection-modal"
      closeButton={false}
      title={@i18n 'subchapter_name'}
    >
      <div className="document_sections__edit-chapter-modal-content">
        <Input
          className="document_sections__edit-chapter-title-input"
          value={@state.title}
          disabled={@state.titleHidden}
          onChange={@handleTitleUpdate}
        />
        <Label className="document_sections__edit-chapter-title-checkbox">
          <ChackboxInput
            checked={@state.titleHidden}
            onChange={@toggleTitleHidden}
          />
          {@i18n 'no_title'}
        </Label>
      </div>

      <ApplyCancelButtons
        onApply={@onApply}
        onCancel={@props.onCancel}
        applyLabel={@i18n '/translation:actions.save'}
      />
    </Modal>

module.exports = EditSubchapterModal
