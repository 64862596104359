EtdActions = require 'actions/etd_actions'
Tooltip = require 'components/common/tooltip'
Modal = require 'components/common/modal'
VotesSummaryDialog = require 'components/etd/voting_summary_dialog'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

VotingSectionTitleRow = createReactClass
  displayName: 'VotingSectionTitleRow'

  propTypes:
    votingResults: PropTypes.object.isRequired
    sectionId: PropTypes.string.isRequired

  mixins: [CustomRenderMixin, Translation('es:recommendations.table')]

  getInitialState: ->
    isModalOpen: false

  _getTitleText: ->
    if @props.votingResults
      @i18n 'voting_results'
    else
      @i18n 'voting_results_not_included_section'

  _getFinishedVotes: ->
    @props.votingResults.filter (voteData) -> voteData.get('status') is 'finished'

  showCommentsDialog: ->
    @_backupOriginalData() # to revert things back in case user clicks cancel button
    @setState isModalOpen: true

  hideCommentsDialog: ->
    @setState isModalOpen: false
    @tempData = null

  cancelCommentsChanges: ->
    {adminComment, hiddenVotes} = @tempData
    # if there were some hidden votes and they were restored - hide them back
    if hiddenVotes.size and hiddenVotes.size isnt @props.hiddenVotes
      EtdActions.hideVote {voteId:hiddenVotes, sectionId: @props.sectionId}
    # if there was admin comment and it was changed - restore original
    if adminComment and adminComment isnt @refs.votingSummaryDialog.getAdminComment()
      @_saveAdminComment adminComment
    @hideCommentsDialog()

  applyCommentsChanges: ->
    adminComment = @refs.votingSummaryDialog.getAdminComment()
    @_saveAdminComment adminComment
    @hideCommentsDialog()

  _saveAdminComment: (comment) ->
    cellId = "#{@props.etdPart}_#{@props.sectionId}#votingComment"
    EtdActions.updateEditorContent { cellId, content: comment }

  _backupOriginalData: ->
    @tempData =
      hiddenVotes: @props.hiddenVotes
      adminComment: @props.adminComment

  _hasVotes: ->
    @props.votingResults and not @props.votingResults.isEmpty()

  _getColsPan: ->
    if @props.etdPart is 'judgements' then 3 else 2

  render: ->
    <tr className="voting-title">
      <td colSpan={@_getColsPan()}>
        <div>
          {@_getTitleText()}
          {if @_hasVotes() and @props.renderMode isnt 'printout'
            <Tooltip>
              <button
                className="comment comment-blank"
                title={@i18n 'view_all_votes_from_team'}
                onClick={@showCommentsDialog}
              />
            </Tooltip>
          }
          {if @state.isModalOpen
            <Modal
              className="voting-summary-modal"
              isOpen={true}
              closeButton={true}
              onClose={@hideCommentsDialog}
              title={@i18n 'comments'}
              key="2"
            >
              <VotesSummaryDialog
                ref="votingSummaryDialog"
                etdPart={@props.etdPart}
                actions={EtdActions}
                votingResults={@props.votingResults}
                votingOptions={@props.getPossibleVotingOptions(true)}
                sectionName={@props.sectionName}
                sectionId={@props.sectionId}
                isProposedVoting={@props.isProposedVoting}
                sectionDescription={@props.sectionDescription}
                draftJudgement={@props.draftJudgement}
                adminComment={@props.adminComment}
                hiddenVotes={@props.hiddenVotes}
                onCancel={@cancelCommentsChanges}
                onApply={@applyCommentsChanges}
              />
            </Modal>
          }
        </div>
      </td>
    </tr>

module.exports = VotingSectionTitleRow
