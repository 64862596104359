{ callMethod, reportRavenError } = require 'base/lib/utils'
ChapterHeader = require 'components/document_sections/v2/chapter_header'
ChapterSections = require 'components/document_sections/v2/chapter_sections'
Collapsible = require 'components/common/collapsible_with_controls'
ConnectStore = require 'components/enhancers/connect_store'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
DocumentSectionsV2Store = require 'stores/document_sections_v2_store'
DocumentSectionsCommonStore = require 'stores/document_sections_common_store'
DiscardChangesActions = require 'actions/discard_changes_actions'
DiscardChangesStore = require 'stores/discard_changes_store'
InsertRecommendation = require 'components/document_sections/v2/insert_recommendation'
{ TopicCodes } = require 'components/common/topic_codes'
InsertMdaTable = require 'components/document_sections/v2/insert_mda_table'
MdaTableDoc = require 'lib/db_docs/mda_table_doc'
MdaTopicDoc = require 'lib/db_docs/mda_topic_doc'
MdaTopicsActions = require 'actions/mda_topics_actions'
MdaTopicsStore = require 'stores/mda_topics_store'
mediator = require 'mediator'
Modal = require 'components/common/modal'
{ convertListToOrderedMap } = require 'lib/immutable_utils'
{ scrollIntoView } = require 'lib/epiditor_utils'
ReferencesInsert = require 'components/etd/references_insert'
ReferencesStore = require 'stores/references_store'
Translation = require 'components/mixins/translation'
W = require 'when/when'

ChapterItem = createReactClass
  displayName: 'ChapterItem'

  mixins: [
    Translation('docsec:chapter_settings_options')
  ]

  propTypes:
    chapter: PropTypes.instanceOf(Immutable.Map).isRequired
    draftDiscussions: PropTypes.instanceOf(Immutable.Map).isRequired
    isExpanded: PropTypes.bool.isRequired
    isFetchingSections: PropTypes.bool.isRequired
    mdaTables: PropTypes.instanceOf(Immutable.Map)
    mdaTopicId: PropTypes.string
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    questions: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
    recommendations: PropTypes.instanceOf(Immutable.Map)
    scrollToChapter: PropTypes.string
    scrollToSection: PropTypes.string
    sections: PropTypes.instanceOf(Immutable.Map)
    suggesting: PropTypes.bool.isRequired
    updatedDraftDiscussion: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      discussionType: PropTypes.string.isRequired
      updatedData: PropTypes.object
      removed: PropTypes.bool
    })
    acceptedOrRejectedChange: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      changeId: PropTypes.string.isRequired
      accepted: PropTypes.bool.isRequired
    })
    scrollToSectionContent: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      contentPos: PropTypes.number.isRequired
    })
    hasChanges: PropTypes.bool.isRequired
    mdaTopicId: PropTypes.string
    topicCodes: PropTypes.instanceOf(Immutable.Map).isRequired
    view: PropTypes.string.isRequired

  getInitialState: ->
    insertModalMode: null # null | 'mdaTable' | 'recommendation' | 'add_code'
    modalOptions: {}

  componentDidUpdate: () ->
    { chapter, scrollToChapter } = @props
    if chapter.get('_id') is scrollToChapter
      @handleExpand()
      scrollIntoView @el
      @el.classList.add 'highlight-project'
      _.delay =>
        DocumentSectionsV2Actions.setScrollToChapter null
        @el.classList.remove 'highlight-project'
      , 500

  getRef: (el) ->
    @el = el

  handleExpand: ->
    { isExpanded, chapter, sections } = @props
    if isExpanded
      return
    chapterId = chapter.get('_id')
    projectId = mediator.project.id

    DocumentSectionsV2Actions.expandChapterSections { chapterId, projectId }

  handleCollapse: ->
    { chapter, hasChanges } = @props
    chapterId = chapter.get('_id')
    if hasChanges
      DiscardChangesActions.setCallback () ->
        DocumentSectionsV2Actions.collapseChapterSections(chapterId)
    else
      DocumentSectionsV2Actions.collapseChapterSections(chapterId)

  handleAddSection: ->
    { chapter, sections } = @props
    projectId = mediator.project.id
    chapterId = chapter.get('_id')
    if sections
      DocumentSectionsV2Actions.addSection(projectId, chapterId)
    else
      sectionsIds = chapter.get('sections').toArray()
      DocumentSectionsV2Actions.addSectionAndExpand(projectId, chapterId, sectionsIds)

  handleInsertChapter: ->
    { chapter } = @props
    projectId = mediator.project.id
    DocumentSectionsV2Actions.insertSubchapter projectId, chapter

  handleInsertReferences: (referencesToInsert) ->
    { chapter, sections, references } = @props
    { sectionId } = @state.modalOptions or {}

    DocumentSectionsV2Actions.addReferenceToDoc({
      chapterId: chapter.get('_id'),
      sectionId,
      referencesToInsert
    }).then @closeInsertModal

  handleInsertRecommendation: (questionsIds, selectedAppendices) ->
    { chapter, sections, questions } = @props
    { sectionId } = @state.modalOptions or {}
    projectId = mediator.project.id
    chapterId = chapter.get('_id')
    data = questionsIds.map (questionId) ->
      question = questions.get(questionId)
      sectionData =
        questionId: questionId
        recommendationId: question.get('recommendationIds').get(0)
        title: question.get('question')
      sectionData['selectedAppendices'] = selectedAppendices if selectedAppendices?
      sectionData
    if sections
      DocumentSectionsV2Actions.addRecommendationSections(projectId, chapterId, data, sectionId)
    else
      sectionsIds = chapter.get('sections').toArray()
      DocumentSectionsV2Actions.addRecommendationSectionsAndExpand(
        projectId, chapterId, sectionsIds, data, sectionId
      )
    @closeInsertModal()

  handleInsertMdaTable: (mdaTablesIds) ->
    { chapter, sections, mdaTables, mdaTopicId } = @props
    { sectionId } = @state.modalOptions or {}
    projectId = mediator.project.id
    chapterId = chapter.get('_id')
    data = mdaTablesIds.map (mdaTableId) ->
      mdaTableId: mdaTableId
      title: mdaTables.getIn [mdaTableId, 'name']
    if sections
      DocumentSectionsV2Actions.addRecommendationSections(projectId, chapterId, data, sectionId)
    else
      sectionsIds = chapter.get('sections').toArray()
      DocumentSectionsV2Actions.addRecommendationSectionsAndExpand(
        projectId, chapterId, sectionsIds, data, sectionId
      )
    if mdaTopicId? and mdaTablesIds.length > 0
      topicPromise = MdaTopicDoc
        .at projectId, mdaTopicId
        .updateAsInstance callMethod 'addTables', mdaTablesIds
      tablesPromises = _.map mdaTablesIds, (mdaTableId) ->
        MdaTableDoc
          .at projectId, mdaTableId
          .updateAsInstance callMethod 'addTopic', mdaTopicId
      W.all _.union tablesPromises, topicPromise
      .catch reportRavenError
    @closeInsertModal()

  onUpdateCodes: (codes) ->
    { sectionId } = @state.modalOptions or {}
    return unless sectionId
    DocumentSectionsV2Actions.updateSectionCodes { sectionId, codes }

  onRemoveCode: (code) -> =>
    { sectionId } = @state.modalOptions or {}
    return unless sectionId
    DocumentSectionsV2Actions.removeCodeFromSection { sectionId, code }

  openInsertModal: (mode) -> (modalOptions) =>
    @setState
      insertModalMode: mode
      modalOptions: modalOptions

  closeInsertModal: ->
    @setState
      insertModalMode: null
      modalOptions: {}

  onMark: ->
    { chapter } = @props
    DocumentSectionsV2Actions.toggleMark({
      chapterId: chapter.get('_id'), checked: chapter.get('markedAsImportant', false)
    })

  getTopicCodes: ->
    convertListToOrderedMap(
      @props.sections?.getIn([@state.modalOptions?.sectionId, 'codes']) or
        Immutable.Map(), 'id')

  getReferences: ->
    { chapter } = @props
    { modalOptions } = @state

    if modalOptions?.sectionId?
      section = @props.sections?.get(modalOptions.sectionId)
      section?.get('references') || Immutable.Map()
    else
      chapter.get('references', Immutable.Map())

  render: ->
    {
      acceptedOrRejectedChange,
      alreadyUsedRecommendationsQuestionsIds,
      chapter,
      draftDiscussions,
      hasChanges
      isExpanded,
      isFetchingSections,
      mdaTables,
      mdaTopicId
      questionGroups,
      questions,
      recommendations,
      references
      scrollToSection,
      scrollToSectionContent,
      sections,
      suggesting,
      topicCodes
      updatedDraftDiscussion,
      view
    } = @props
    { insertModalMode } = @state

    modalClassName = if insertModalMode is 'insertReferences' then 'references-insert-modal' else ''
    modalSize = if insertModalMode in ['addCode', 'insertReferences'] then 'full-size' else "medium"
    <div ref={@getRef} className="chapter-item">
      <Collapsible
        withTogglableCaption
        forcedExpand={isExpanded}
        onExpand={@handleExpand}
        onCollapse={@handleCollapse}
        captionClassName="chapter-caption"
        contentClassName="chapter-content"
      >
        <ChapterHeader
          chapterId={chapter.get('_id')}
          handleInsertChapter={@handleInsertChapter}
          isExpanded={isExpanded}
          isFromAcoem={chapter.get('isFromAcoem')}
          markedAsImportant={chapter.get('markedAsImportant', false)}
          onAddSection={@handleAddSection}
          onInsertMdaTable={@openInsertModal 'mdaTable'}
          onInsertRecommendation={@openInsertModal 'recommendation'}
          onInsertReferences={@openInsertModal 'insertReferences'}
          onMark={@onMark}
          hasReferences={chapter.get('refIds', Immutable.List()).size > 0}
          references={references}
          title={chapter.get('title')}
          view={view}
        />
        <ChapterSections
          acceptedOrRejectedChange={acceptedOrRejectedChange}
          chapterId={chapter.get('_id')}
          draftDiscussions={draftDiscussions}
          hasChanges={hasChanges}
          isFetching={isFetchingSections}
          mdaTables={mdaTables}
          mdaTopicId={mdaTopicId}
          onAddCode={@openInsertModal 'addCode'}
          onInsertMdaTable={@openInsertModal 'mdaTable'}
          onInsertRecommendation={@openInsertModal 'recommendation'}
          onInsertReferences={@openInsertModal 'insertReferences'}
          questions={questions}
          recommendations={recommendations}
          scrollToSection={scrollToSection}
          scrollToSectionContent={scrollToSectionContent}
          sections={sections}
          sectionsIds={chapter.get('sections')}
          suggesting={suggesting}
          updatedDraftDiscussion={updatedDraftDiscussion}
          view={view}
        />
      </Collapsible>
      {insertModalMode? and (
        <Modal
          isOpen={true}
          className={modalClassName}
          modalSize={modalSize}
          title={@i18n switch insertModalMode
            when 'mdaTable' then 'insert_mda_table'
            when 'addCode' then 'add_code_to_table'
            when 'insertReferences' then 'insert_references'
            else 'insert_recommendation'
          }
        >
          {switch insertModalMode
            when 'mdaTable'
              <InsertMdaTable
                onCancel={@closeInsertModal}
                onInsert={@handleInsertMdaTable}
                questionGroups={questionGroups}
                questions={questions}
              />
            when 'recommendation'
              <InsertRecommendation
                onCancel={@closeInsertModal}
                onInsert={@handleInsertRecommendation}
                alreadyUsedRecommendationsQuestionsIds={alreadyUsedRecommendationsQuestionsIds}
                questionGroups={questionGroups}
                questions={questions}
              />
            when 'addCode'
              <TopicCodes
                includedCodes={@getTopicCodes()}
                codesToInclude={topicCodes}
                handleDeleteCode={@onRemoveCode}
                onApply={@onUpdateCodes}
                onCancel={@closeInsertModal}
              />
            when 'insertReferences'
              <ReferencesInsert
                onClose={@closeInsertModal}
                onInsert={@handleInsertReferences}
                initialReferences={@getReferences()}
              />
            else
              null
          }
        </Modal>
      )}
    </div>

storeConnectors =
  DocumentSectionsV2Store: (Store, { chapterId }) ->
    chapter = Store.getChapter(chapterId)
    chapterSections = chapter.get('sections')
    scrollToSection = Store.getScrollToSection()

    acceptedOrRejectedChange: Store.getAcceptedOrRejectedChange()
    chapter: chapter
    draftDiscussions: Store.getDraftDiscussions(chapterSections)
    isExpanded: Store.isChapterExpanded(chapterId)
    isFetchingSections: Store.isFetchingChapterSections(chapterId)
    mdaTables: Store.getMdaTables()
    mdaTopicId: Store.getMdaTopicId()
    recommendations: Store.getRecommendations()
    scrollToChapter: Store.getScrollToChapter()
    scrollToSection: if chapterSections.includes(scrollToSection) then scrollToSection else null
    sections: Store.getChapterSections(chapterId)
    suggesting: Store.getSuggesting()
    updatedDraftDiscussion: Store.getUpdatedDraftDiscussion()
  DiscardChangesStore: (Store) ->
    hasChanges: Store.hasChanges()
  MdaTopicsStore: (Store) ->
    topicCodes: Store.getCurrentTopicCodes()
  DocumentSectionsCommonStore: (Store) ->
    alreadyUsedRecommendationsQuestionsIds: Store.getAlreadyUsedRecommendationsQuestionsIds()
  ReferencesStore: (Store) ->
    references: Store.getReferences()

module.exports =
  ConnectStore ChapterItem, [
    DocumentSectionsV2Store
    DocumentSectionsCommonStore
    DiscardChangesStore
    MdaTopicsStore
    ReferencesStore
  ], storeConnectors
