var OutcomesGeneration, OutcomesGenerationActions, OutcomesGenerationToolbar, OutcomesGenerationView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactToolbarView = require('views/react_toolbar_view');

OutcomesGeneration = require('components/scope/outcomes/outcomes_generation');

OutcomesGenerationToolbar = require('components/scope/outcomes/toolbar');

OutcomesGenerationActions = require('actions/outcomes_generation_actions');

ReactComponent = require('base/lib/traits/react_component');

mediator = require('mediator');

alt = require('alt');

module.exports = OutcomesGenerationView = (function(_super) {
  __extends(OutcomesGenerationView, _super);

  function OutcomesGenerationView() {
    return OutcomesGenerationView.__super__.constructor.apply(this, arguments);
  }

  OutcomesGenerationView.prototype.container = '#page-container';

  OutcomesGenerationView.prototype.className = 'outcome-generation-view';

  OutcomesGenerationView.prototype.autoRender = true;

  OutcomesGenerationView.prototype.initialize = function() {
    OutcomesGenerationView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: OutcomesGenerationToolbar
    }));
    return this.enable(ReactComponent);
  };

  OutcomesGenerationView.prototype.afterRender = function() {
    OutcomesGenerationView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(OutcomesGeneration, OutcomesGenerationActions);
  };

  OutcomesGenerationView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('MembersStore', 'OutcomesGenerationStore');
    return OutcomesGenerationView.__super__.dispose.apply(this, arguments);
  };

  return OutcomesGenerationView;

})(View);
