var mediator;

mediator = require('mediator');

module.exports = function(route, projectRoute, submodule) {
  route('', 'welcome');
  route('organizations', 'organizations');
  route('organizations/:organizationId/projects', 'projects#list');
  route('projects', 'projects#list');
  route('projects/', 'projects#list');
  route('projects/:projectId', 'projects#start');
  route('projects/:projectId/most-recent-change', 'projects#mostRecentChange');
  route('projects/:projectId/my-most-recent-change', 'projects#myMostRecentChange');
  projectRoute('dashboard');
  projectRoute('settings');
  projectRoute('settings', '/:submodule', '#submodule');
  projectRoute('voting');
  projectRoute('administration');
  projectRoute('tasks');
  projectRoute('tasks', '/:submodule', '#submodule');
  projectRoute('team');
  projectRoute('team', '/:submodule', '#submodule');
  projectRoute('scope');
  projectRoute('scope', '/:submodule', '#submodule');
  projectRoute('document_sections');
  projectRoute('references');
  projectRoute('references', '/:submodule', '#submodule');
  projectRoute('prognosis');
  projectRoute('prognosis', '/:id', '#question');
  projectRoute('prognosis', '/:id/', '#question');
  projectRoute('prognosis', '/:id/:submodule', '#submodule');
  projectRoute('evidence_syntheses');
  projectRoute('evidence_syntheses', '/:id', '#question');
  projectRoute('evidence_syntheses', '/:id/', '#question');
  projectRoute('evidence_syntheses', '/:id/:submodule', '#submodule');
  projectRoute('multi_comparisons');
  projectRoute('multi_comparisons', '/:submodule', '#submodule');
  projectRoute('multi_comparisons', '/:mcId/:submodule', '#submodule');
  projectRoute('dissemination');
  projectRoute('dissemination', '/:submodule', '#submodule');
  projectRoute('dissemination', '/:submodule/:submoduleScreen', '#submodule');
  projectRoute('dissemination', '/:submodule/:submoduleScreen/:itemId', '#submodule');
  projectRoute('overarching_questions');
  projectRoute('overarching_questions', '/:id/:submodule', '#submodule');
  projectRoute('key_messages');
  projectRoute('key_messages', '/:id/:submodule', '#submodule');
  projectRoute('cadth_guidelines');
  projectRoute('algorithms');
  projectRoute('algorithms', '/:id', '#algorithm');
  projectRoute('algorithms', '/:id/', '#algorithm');
  projectRoute('mda_tables');
  projectRoute('mda_tables', '/:id', '#table');
  projectRoute('mda_tables', '/:id/', '#table');
  projectRoute('mda_topics');
  projectRoute('mda_topics', '/:id', '#topic');
  projectRoute('mda_topics', '/:id/', '#topic');
  projectRoute('mda_verification');
  projectRoute('nma');
  projectRoute('nma', '/:questionId', '#question');
  projectRoute('nma', '/:questionId/outcomes/:outcomeId', '#outcome');
  return projectRoute('nma', '/:questionId/viz', '#viz');
};
