{ memo, useState, useEffect } = React

getStoreListeners = (stores, storeConnectors, props, setState) ->
  stores.reduce (listeners, store) ->
    { displayName: storeName } = store
    connector = storeConnectors[storeName]
    listeners[storeName] = ->
      setState (currentState) -> _.extend {}, currentState, connector(store, props)
    listeners
  , {}

validateStoreConnectors = (stores, storeConnectors) ->
  throw Error 'No storeConnector was provided!' unless storeConnectors?
  # ensure all stores have corresponding storeConnectors provided. Throw otherwise
  storesWithoutConnectors = stores.reduce (acc, store) ->
    { displayName: storeName } = store
    hasValidConnector = storeConnectors[storeName] and _.isFunction storeConnectors[storeName]
    return acc if hasValidConnector
    acc.concat storeName
  , []

  return if _.isEmpty storesWithoutConnectors

  throw Error "Valid storeConnector(s) missing: check storeConnector(s) for
    #{storesWithoutConnectors.join ', '}"

ConnectStores = (Component, stores, storeConnectors) ->
  stores = [].concat stores
  validateStoreConnectors stores, storeConnectors

  memo (props) ->
    _calculateState = ->
      stores.reduce (accState, store) =>
        _.extend accState, storeConnectors[store.displayName](store, props)
      , {}

    [state, setState] = useState(_calculateState())

    ref = (el) -> props.refCb? el

    useEffect(() ->
      storeListeners = getStoreListeners stores, storeConnectors, props, setState
      _.forEach stores, (store) =>
        store.listen storeListeners[store.displayName]

      () ->
        _.forEach stores, (store) =>
          store.unlisten storeListeners[store.displayName]
        storeListeners = null
    , [])

    useEffect((->
      setState(_calculateState())
    ), [props])

    <Component {...props} {...state} ref={if props.refCb? then ref else null } />

module.exports = ConnectStores
