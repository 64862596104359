CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Tooltip = require 'components/common/tooltip'

{string, bool} = PropTypes

SidebarModuleLink = createReactClass

  displayName: "SidebarModuleLink"

  mixins: [CustomRenderMixin]

  propTypes:
    collapsed: bool.isRequired
    absoluteExpand: bool.isRequired
    classes: string
    title: string
    label: string.isRequired

  render: ->
    { href, classes, title, label } = @props
    #this looks bad, but changing anything in tooltips children causes tooltip to unbind
    if @props.collapsed and not @props.absoluteExpand
      <Tooltip>
        <a href={href} className={classes} title={title} />
      </Tooltip>
    else
      <Tooltip>
        <a href={href} className={classes} title={title}>
          {label}
        </a>
      </Tooltip>

module.exports = SidebarModuleLink
