_.namespace(module, function(require) {
  var GeneralInformationDoc, W, exportGuideline, exportQuestionAndRecommendation, mediator, sequence;
  GeneralInformationDoc = require('lib/db_docs/general_information_doc');
  mediator = require('mediator');
  W = require('when/when');
  sequence = require('when/sequence');
  exportGuideline = require('lib/services/publication_platform_exporter/guideline');
  exportQuestionAndRecommendation = require('lib/services/publication_platform_exporter/question');
  return {
    _getGuidelineDoc: function(projectId) {
      return GeneralInformationDoc.at(projectId).fetch();
    },
    _getQuestionsAndRecommendations: function(projectId, questionIds) {
      var adapter;
      adapter = mediator.services.storePersistenceAdapter;
      return adapter.fetch(projectId, questionIds).then(function(_arg) {
        var questions, recommendationIds, rows;
        rows = _arg.rows;
        questions = _.chain(rows).pluck('doc').compact().filter(function(_arg1) {
          var _id;
          _id = _arg1._id;
          return _id.indexOf(':adoloped') < 0;
        }).value();
        recommendationIds = _.chain(questions).pluck('recommendationIds').compact().flatten().value();
        if (_.isEmpty(recommendationIds)) {
          return {
            questions: questions,
            recommendations: {}
          };
        }
        return adapter.fetch(projectId, recommendationIds).then(function(_arg1) {
          var recommendations, rows;
          rows = _arg1.rows;
          recommendations = _.chain(rows).pluck('doc').compact().map(function(doc) {
            return [doc['_id'], doc];
          }).object().value();
          return {
            questions: questions,
            recommendations: recommendations
          };
        });
      });
    },
    "export": function(projectId, questionIds) {
      var promises;
      promises = [this._getGuidelineDoc(projectId), this._getQuestionsAndRecommendations(projectId, questionIds)];
      return W.all(promises).then(function(_arg) {
        var exportedGuideline, guidelineDoc, processQuestion, questions, recommendations, _ref;
        guidelineDoc = _arg[0], (_ref = _arg[1], questions = _ref.questions, recommendations = _ref.recommendations);
        exportedGuideline = exportGuideline(projectId, guidelineDoc);
        processQuestion = function(question) {
          return function() {
            return exportQuestionAndRecommendation(question, recommendations, projectId);
          };
        };
        return sequence(_.map(questions, processQuestion)).then(function(questions) {
          return _.chain(questions).map(function(exportedQuestion) {
            if (!exportedQuestion) {
              return void 0;
            }
            return _.extend({}, {
              guideline: exportedGuideline
            }, exportedQuestion);
          }).compact().value();
        });
      });
    }
  };
});
