Button = require 'components/common/button'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
ConnectStore = require 'components/enhancers/connect_store'
DiscardChangesActions = require 'actions/discard_changes_actions'
DiscardChangesStore = require 'stores/discard_changes_store'
EllipsizedText = require 'components/common/ellipsized_text'
MdaTableActions = require 'actions/mda_table_actions'
MdaTableModal = require 'components/mda/tables/mda_table_modal'
{ MDA_TABLE: MDA_TABLE_DOC_TYPE } = require 'lib/db_docs/doc_types'
mediator = require 'mediator'
QuestionsList = require 'components/evidence_syntheses/questions_list/questions_list'
QuestionsStore = require 'stores/questions_store'
QuestionGroupsStore = require 'stores/question_groups_store'
Router = require 'router'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

MdaTableToolbarItem = ({ additionalProps, data }) ->
  { collapse, hasChanges } = additionalProps
  onClick = useCoffeeCallback [collapse, hasChanges, data], ->
    doChangeQuestion = ->
      link = Router::routeForSubmodule 'mda-tables', null, questionId: data.get '_id'
      mediator.publish '!router:route', link
      collapse?()
    if hasChanges
      DiscardChangesActions.setCallback doChangeQuestion
    else
      doChangeQuestion()

  <div className="toolbar-mda-table">
    <div className="table-name">
      <EllipsizedText height={40} onClick={onClick} text={data.get('name') ? ''} />
    </div>
  </div>

MdaTableToolbarItem.propTypes =
  data: PropTypes.instanceOf(Immutable.Map)
  additionalProps: PropTypes.shape({
    collapse: PropTypes.func
    hasChanges: PropTypes.bool.isRequired
  })

storeConnector =
  DiscardChangesStore: (Store) ->
    hasChanges: Store.hasChanges()
  QuestionsStore: (Store) ->
    questions: Store.getQuestions MDA_TABLE_DOC_TYPE
  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups MDA_TABLE_DOC_TYPE

MdaTableToolbar = ({ currentTableId, hasChanges, projectId, questions, questionGroups }) ->
  i18n = useI18n 'mda:tables'

  <div className={classNames 'mda-table-toolbar', 'mx-10': not currentTableId?}>
    {if currentTableId?
      <div className="flex-1">
        <CollapsibleWithControls
          additionalProps={{ hasChanges }}
          controlsPosition="left"
          passAdditionalProps
          withTogglableCaption
        >
          <Tooltip>
            <div className="current-table" title={i18n 'tables_menu'}>
              {questions.getIn [currentTableId, 'name'], ''}
            </div>
          </Tooltip>
          <QuestionsList questions={questions} questionGroups={questionGroups}>
            <MdaTableToolbarItem />
          </QuestionsList>
        </CollapsibleWithControls>
      </div>
    else
      <React.Fragment>
        <div className="bold">
          {i18n 'tables'}
        </div>
        <Button
          className="btn btn-apply"
          label={i18n '/actions.create_mda_table'}
          onClick={MdaTableActions.openCreateOrEditDialog}
        />
        <MdaTableModal projectId={projectId} />
      </React.Fragment>
    }
  </div>

MdaTableToolbar.propTypes =
  currentTableId: PropTypes.string
  hasChanges: PropTypes.bool.isRequired
  projectId: PropTypes.string.isRequired
  questions: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.OrderedMap),
    PropTypes.instanceOf(Immutable.Map)]
  ).isRequired
  questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore MdaTableToolbar, [
  DiscardChangesStore
  QuestionsStore
  QuestionGroupsStore
], storeConnector
