IconButton = require 'components/common/icon_button'
QualityIndicatorsActions = require 'actions/quality_indicators_actions'
{ useI18n } = require 'lib/react_utils'
EtdEditor = require 'components/etd/etd_editor'

QualityIndicatorRow = ({ qualityIndicator, readOnly, renderMode }) ->
  i18n = useI18n('quality_indicators:table')

  type = qualityIndicator.get('type')

  <React.Fragment>
    <tr>
      <td colSpan="3" className="quality-indicators__name">
      <div className="flex">
        <div className="flex-grow">
          <b>{qualityIndicator.get('name')}</b>
        </div>
        <div>
          {not readOnly && <IconButton
            iconName="edit"
            onClick={-> QualityIndicatorsActions.editRow(qualityIndicator.get('id'))}
          />}
        </div>
      </div>
      </td>
    </tr>
    <tr className='quality-indicator-row'>
      <td>
        <div>
          <b>{i18n 'type'}:</b> {not _.isEmpty(type) and i18n "../types.#{type}"}
        </div>
        <div>
          <b>{i18n 'explanation_of_terms'}:</b> {qualityIndicator.get('explanationOfTerms')}
        </div>
      </td>
      <td>
        <div className="text-container">
          <EtdEditor
            cellId="calculationFormula"
            content={qualityIndicator.get('calculationFormula')}
            editable={false}
            forceTableExpand={true}
            isConsensus={false}
            noInsertOption
            placeholder={""}
            renderMode={renderMode}
          />
        </div>
      </td>
      <td>
        <div className="text-container">
          <EtdEditor
            cellId="performanceIndicator"
            content={qualityIndicator.get('performanceIndicator')}
            editable={false}
            forceTableExpand={true}
            isConsensus={false}
            noInsertOption
            placeholder={""}
            renderMode={renderMode}
          />
        </div>
      </td>
    </tr>
  </React.Fragment>

QualityIndicatorRow.propTypes =
  qualityIndicator: PropTypes.instanceOf(Immutable.Map).isRequired
  readOnly: PropTypes.bool
  renderMode: PropTypes.oneOf(['regular', 'printout'])

QualityIndicatorRow.defaultProps =
  readOnly: false
  renderMode: 'printout'

module.exports = QualityIndicatorRow
