var GeneralScopeActions, GeneralScopeTableConnected, GeneralScopeView, GeneralToolbar, ReactComponent, ReactToolbarView, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

GeneralScopeTableConnected = require('components/scope/general_scope_table').GeneralScopeTableConnected;

GeneralScopeActions = require('actions/general_scope_actions');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

GeneralToolbar = require('components/scope/general_toolbar');

module.exports = GeneralScopeView = (function(_super) {
  __extends(GeneralScopeView, _super);

  function GeneralScopeView() {
    return GeneralScopeView.__super__.constructor.apply(this, arguments);
  }

  GeneralScopeView.prototype.container = '#page-container';

  GeneralScopeView.prototype.className = 'scope-view';

  GeneralScopeView.prototype.autoRender = false;

  GeneralScopeView.prototype.initialize = function() {
    this.updateView = true;
    this.enable(ReactComponent);
    GeneralScopeView.__super__.initialize.apply(this, arguments);
    if (!(this.model.get('scope') && _(this.model.get('scope')).isObject())) {
      this.model.set('scope', {}, {
        clean: true
      });
    }
    this.subview('toolbar', new ReactToolbarView({
      component: GeneralToolbar
    }));
    return this.render();
  };

  GeneralScopeView.prototype.afterRender = function() {
    GeneralScopeView.__super__.afterRender.apply(this, arguments);
    autosize(this.$('textarea'));
    GeneralScopeActions.fetch();
    return this.renderComponent(GeneralScopeTableConnected, GeneralScopeActions);
  };

  GeneralScopeView.prototype.dispose = function() {
    this.unmountComponent();
    return GeneralScopeView.__super__.dispose.apply(this, arguments);
  };

  return GeneralScopeView;

})(View);
