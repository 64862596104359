var ADOLOPMENT_DATA_DOC_ID, AdolopmentsHelper, IEtdRecommendationsExporter, RecommendationsExporter, TemplatesSections, W, draftRenderer, mediator, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

ADOLOPMENT_DATA_DOC_ID = require('lib/doc_ids').ADOLOPMENT_DATA;

AdolopmentsHelper = require('lib/adolopments_helper');

mediator = require('mediator');

RecommendationsExporter = require('./recommendations_exporter');

utils = require('./exporter_utils');

draftRenderer = require('./draft_renderer');

TemplatesSections = require('lib/etd_templates/templates_sections');

W = require('when');

module.exports = IEtdRecommendationsExporter = (function(_super) {
  __extends(IEtdRecommendationsExporter, _super);

  function IEtdRecommendationsExporter(_originalQuestion, pouchAdapter, _headerFieldsInQuestion, _isDiagnostic, _sectionTypes, _recommendationTypes, _referencesExporter) {
    var outcomesType, recommendationId, recommendationsIds;
    this.pouchAdapter = pouchAdapter;
    this._headerFieldsInQuestion = _headerFieldsInQuestion;
    this._isDiagnostic = _isDiagnostic;
    this._sectionTypes = _sectionTypes;
    this._recommendationTypes = _recommendationTypes;
    this._referencesExporter = _referencesExporter;
    IEtdRecommendationsExporter.__super__.constructor.call(this, _originalQuestion);
    recommendationsIds = _originalQuestion['recommendationIds'];
    if (recommendationsIds.length === 0) {
      throw new Error("No recommendations for question!");
    }
    recommendationId = recommendationsIds[0];
    this.recommendationsPromise = this._fetchRecommendation(recommendationId);
    this.panelVoiceVersionPromise = this._checkPanelVoiceVersion();
    outcomesType = this._isDiagnostic ? 'AnticipatedOutcomesAdditionalQuestion' : 'OutcomesAdditionalQuestion';
    this._additionalQuestionTypes = {
      'general': 'GeneralAdditionalQuestion',
      'outcomes': outcomesType,
      'resources': 'ResourcesAdditionalQuestion'
    };
    this._currentAnticipatedOutcomeId = 1;
  }

  IEtdRecommendationsExporter.prototype._checkPanelVoiceVersion = function() {
    return this.pouchAdapter.fetch(mediator.project.id, 'etd-voting-preferences').then(function() {
      return 'old';
    })["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
      return 'new';
    });
  };

  IEtdRecommendationsExporter.prototype._renderDraft = function(recommendation, data) {
    var attachments, exportedObj, references, referencesMap, rendered, _ref;
    attachments = (_ref = recommendation._attachments) != null ? _ref : {};
    references = this._referencesExporter.exportReferencesForEtdCell(data);
    referencesMap = _.chain(references).pluck('@id').map((function(_this) {
      return function(jsonLdId) {
        var gdtId;
        gdtId = _this._referencesExporter.getReferenceGdtId(jsonLdId);
        return [gdtId, jsonLdId];
      };
    })(this)).object().value();
    rendered = draftRenderer.renderDraftJsContentToHTML(data, attachments, referencesMap);
    exportedObj = {
      '@type': 'ValueWithFootnotes',
      'value': rendered
    };
    if (references.length > 0) {
      exportedObj['footnote'] = references;
    }
    return exportedObj;
  };

  IEtdRecommendationsExporter.prototype._getFinalValue = function(obj, panelVoiceVersion, field) {
    if (field == null) {
      field = 'content';
    }
    if (panelVoiceVersion === 'old') {
      return (obj != null ? obj["" + field + "Consensus"] : void 0) || (obj != null ? obj[field] : void 0) || '';
    } else {
      return (obj != null ? obj[field] : void 0) || '';
    }
  };

  IEtdRecommendationsExporter.prototype._exportOptions = function(options, additionalOptions) {
    return _(options).union(additionalOptions);
  };

  IEtdRecommendationsExporter.prototype._exportAdditionalQuestions = function(additionalQuestions, additionalQuestionsType) {
    return _(additionalQuestions).map((function(_this) {
      return function(question) {
        var additionalOptions, exportedOutcome, option, options, outcome, resource, resources, selectedOption, selectedOptions, _ref, _ref1;
        selectedOption = (function() {
          var _i, _j, _len, _len1, _ref, _ref1, _ref2, _results, _results1, _results2;
          switch (additionalQuestionsType) {
            case 'general':
              return question['selectedOption'];
            case 'outcomes':
              if (this._isDiagnostic) {
                resources = (_ref = question['resources']) != null ? _ref : [];
                _results = [];
                for (_i = 0, _len = resources.length; _i < _len; _i++) {
                  outcome = resources[_i];
                  exportedOutcome = {
                    'forOutcome': {
                      '@type': 'Outcome',
                      '@id': "_:ao" + this._currentAnticipatedOutcomeId,
                      'name': outcome['name']
                    },
                    'value': outcome['selectedOption']
                  };
                  this._currentAnticipatedOutcomeId = this._currentAnticipatedOutcomeId + 1;
                  _results.push(exportedOutcome);
                }
                return _results;
              } else {
                selectedOptions = (_ref1 = question['selectedOptions']) != null ? _ref1 : {};
                _results1 = [];
                for (outcome in selectedOptions) {
                  option = selectedOptions[outcome];
                  _results1.push({
                    'forOutcome': {
                      '@id': "outcomes/" + outcome
                    },
                    'value': option
                  });
                }
                return _results1;
              }
              break;
            case 'resources':
              resources = (_ref2 = question['resources']) != null ? _ref2 : [];
              _results2 = [];
              for (_j = 0, _len1 = resources.length; _j < _len1; _j++) {
                resource = resources[_j];
                _results2.push({
                  'forResource': resource['name'],
                  'value': resource['selectedOption']
                });
              }
              return _results2;
              break;
            default:
              throw new Error("Unknown additional questions' type");
          }
        }).call(_this);
        options = (_ref = question['options']) != null ? _ref : [];
        additionalOptions = (_ref1 = question['additionalOptions']) != null ? _ref1 : [];
        return {
          '@type': _this._additionalQuestionTypes[additionalQuestionsType],
          'description': question['question'],
          'options': _this._exportOptions(options, additionalOptions),
          'judgement': selectedOption
        };
      };
    })(this));
  };

  IEtdRecommendationsExporter.prototype._exportCriterion = function(recommendation, panelVoiceVersion, criterion) {
    var additionalOptions, additionalQuestions, additionalQuestionsType, details, exportedAdditionalQuestions, exportedOptions, options, panelDiscussion, question, selectedOption, _ref, _ref1, _ref2;
    options = criterion['options'];
    additionalOptions = criterion['additionalOptions'];
    exportedOptions = this._exportOptions(options, additionalOptions);
    selectedOption = this._getFinalValue(criterion, panelVoiceVersion, 'selectedOption');
    question = criterion['description'];
    details = (_ref = criterion['details']) != null ? _ref : {};
    additionalQuestionsType = details['questionsType'];
    panelDiscussion = this._renderDraft(recommendation, (_ref1 = details['panelDiscussion']) != null ? _ref1 : null);
    additionalQuestions = (_ref2 = details['questions']) != null ? _ref2 : [];
    exportedAdditionalQuestions = this._exportAdditionalQuestions(additionalQuestions, additionalQuestionsType);
    return this._makeCriterion('CustomJudgementCriterion', question, exportedOptions, selectedOption, null, null, panelDiscussion, exportedAdditionalQuestions);
  };

  IEtdRecommendationsExporter.prototype._exportSection = function(recommendation, panelVoiceVersion, sectionId) {
    var additionalConsideration, additionalConsiderations, additionalConsiderationsId, assessment, criteria, criterion, criterionId, exportedCriterion, favours, importance, researchEvidence, researchEvidenceId, researchEvidences, section, sectionName, templateData, _ref, _ref1, _ref10, _ref2, _ref3, _ref4, _ref5, _ref6, _ref7, _ref8, _ref9;
    templateData = (_ref = recommendation != null ? recommendation['templateData'] : void 0) != null ? _ref : {};
    assessment = (_ref1 = templateData['assessment']) != null ? _ref1 : {};
    researchEvidences = (_ref2 = assessment['researchEvidences']) != null ? _ref2 : {};
    criteria = (_ref3 = assessment['criteria']) != null ? _ref3 : {};
    additionalConsiderations = (_ref4 = assessment['additionalConsiderations']) != null ? _ref4 : {};
    section = (_ref5 = (_ref6 = assessment['sections']) != null ? _ref6[sectionId] : void 0) != null ? _ref5 : '';
    criterionId = (_ref7 = section['criterionId']) != null ? _ref7 : '';
    researchEvidenceId = (_ref8 = section['researchEvidenceId']) != null ? _ref8 : '';
    additionalConsiderationsId = (_ref9 = section['additionalConsiderationId']) != null ? _ref9 : '';
    criterion = (_ref10 = criteria[criterionId]) != null ? _ref10 : {};
    researchEvidence = this._renderDraft(recommendation, this._getFinalValue(researchEvidences[researchEvidenceId], panelVoiceVersion));
    additionalConsideration = this._renderDraft(recommendation, this._getFinalValue(additionalConsiderations[additionalConsiderationsId], panelVoiceVersion));
    favours = this._getFinalValue(section, panelVoiceVersion, 'favours');
    importance = this._getFinalValue(section, panelVoiceVersion, 'importance');
    sectionName = section['name'];
    exportedCriterion = this._exportCriterion(recommendation, panelVoiceVersion, criterion);
    return this._makeSection(this._sectionTypes[sectionId], sectionName, exportedCriterion, researchEvidence, additionalConsideration, favours, importance);
  };

  IEtdRecommendationsExporter.prototype.exportSections = function() {
    return W.all([this.recommendationsPromise, this.panelVoiceVersionPromise]).then((function(_this) {
      return function(_arg) {
        var definedSections, panelVoiceVersion, recommendation, section, _i, _len, _ref;
        recommendation = _arg[0], panelVoiceVersion = _arg[1];
        definedSections = [];
        _ref = recommendation['templateDef']['assessment'];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          section = _ref[_i];
          if (section['checked']) {
            definedSections.push(section['id']);
          }
        }
        return _(definedSections).map(function(sectionId) {
          return _this._exportSection(recommendation, panelVoiceVersion, sectionId);
        });
      };
    })(this));
  };

  IEtdRecommendationsExporter.prototype._exportConclusion = function(recommendation, panelVoiceVersion, conclusionField, contentField) {
    var conclusions, finalValue, options, section, sections, templateData, _ref, _ref1, _ref2, _ref3, _ref4;
    templateData = (_ref = recommendation != null ? recommendation['templateData'] : void 0) != null ? _ref : {};
    conclusions = (_ref1 = templateData['conclusions']) != null ? _ref1 : {};
    sections = (_ref2 = conclusions['sections']) != null ? _ref2 : {};
    section = (_ref3 = sections[conclusionField]) != null ? _ref3 : {};
    finalValue = this._getFinalValue(section, panelVoiceVersion, contentField);
    if (contentField === 'selectedOption') {
      options = section['options'];
      finalValue = (_ref4 = _.find(options, function(x) {
        return x.value === finalValue;
      })) != null ? _ref4.text : void 0;
    }
    return this._renderDraft(recommendation, finalValue);
  };

  IEtdRecommendationsExporter.prototype.exportConclusions = function() {
    return W.all([this.recommendationsPromise, this.panelVoiceVersionPromise]).then((function(_this) {
      return function(_arg) {
        var conclusions, contentFieldName, contentFieldNames, field, fields, jsonLDFieldNames, jsonLdFieldName, panelVoiceVersion, recommendation, _i, _len, _ref, _ref1;
        recommendation = _arg[0], panelVoiceVersion = _arg[1];
        jsonLDFieldNames = {
          'recommendationTypeIntervention': 'recommendationType',
          'recommendationTypeOption': 'recommendationType',
          'decisionTypeImplementation': 'decisionType',
          'decisionTypeCoverage': 'decisionType',
          'recommendation': 'recommendationText',
          'decision': 'decisionText'
        };
        contentFieldNames = {
          'recommendationTypeIntervention': 'selectedOption',
          'recommendationTypeOption': 'selectedOption',
          'decisionTypeImplementation': 'selectedOption',
          'decisionTypeCoverage': 'selectedOption'
        };
        contentFieldName = function(name) {
          var _ref;
          return (_ref = contentFieldNames[name]) != null ? _ref : 'content';
        };
        jsonLdFieldName = function(name) {
          var _ref;
          return (_ref = jsonLDFieldNames[name]) != null ? _ref : name;
        };
        fields = _((_ref = recommendation != null ? (_ref1 = recommendation['templateDef']) != null ? _ref1['conclusions'] : void 0 : void 0) != null ? _ref : []).chain().filter(function(field) {
          return Boolean(field['checked']) || Boolean(field['required']);
        }).pluck('id').value();
        conclusions = {};
        for (_i = 0, _len = fields.length; _i < _len; _i++) {
          field = fields[_i];
          conclusions[jsonLdFieldName(field)] = _this._exportConclusion(recommendation, panelVoiceVersion, field, contentFieldName(field));
        }
        return conclusions;
      };
    })(this));
  };

  IEtdRecommendationsExporter.prototype._exportHeaderField = function(recommendation, field) {
    var autoGenerated, content, fieldsWithAutoGeneration, question, section, sections, templateData, _ref, _ref1, _ref2, _ref3;
    templateData = (_ref = recommendation != null ? recommendation['templateData'] : void 0) != null ? _ref : {};
    question = (_ref1 = templateData['question']) != null ? _ref1 : {};
    sections = (_ref2 = question['sections']) != null ? _ref2 : {};
    section = (_ref3 = sections[field]) != null ? _ref3 : {};
    autoGenerated = section['autoContentGenerationOn'] != null ? section['autoContentGenerationOn'] : (fieldsWithAutoGeneration = this._isDiagnostic ? ['setting', 'population'] : ['population', 'mainOutcomes', 'setting'], __indexOf.call(fieldsWithAutoGeneration, field) >= 0);
    content = autoGenerated ? this._headerFieldsInQuestion[field] : section['content'];
    return this._renderDraft(recommendation, content != null ? content : null);
  };

  IEtdRecommendationsExporter.prototype.exportHeader = function() {
    return this.recommendationsPromise.then((function(_this) {
      return function(recommendation) {
        var field, header, headerFields, question, templateDef, _i, _len, _ref, _ref1;
        templateDef = (_ref = recommendation != null ? recommendation['templateDef'] : void 0) != null ? _ref : {};
        question = (_ref1 = templateDef['question']) != null ? _ref1 : {};
        headerFields = _.pluck(question, 'id');
        headerFields = _.filter(headerFields, function(x) {
          return x !== 'comparison' && x !== 'intervention';
        });
        header = {};
        for (_i = 0, _len = headerFields.length; _i < _len; _i++) {
          field = headerFields[_i];
          header[field] = _this._exportHeaderField(recommendation, field);
        }
        return utils.removeFalseValuesFromObject(header);
      };
    })(this));
  };

  IEtdRecommendationsExporter.prototype._checkTemplateSection = function(conclusionId, value) {
    var option, options, _ref, _ref1, _ref2;
    options = (_ref = (_ref1 = TemplatesSections()['conclusions']['sections'][conclusionId]) != null ? _ref1['options'] : void 0) != null ? _ref : [];
    option = _(options).find(function(option) {
      return option.value === value;
    });
    return (_ref2 = option != null ? option['direction'] : void 0) != null ? _ref2 : 'none';
  };

  IEtdRecommendationsExporter.prototype.getJsonLdType = function() {
    return W.all([this.recommendationsPromise, this.panelVoiceVersionPromise]).then((function(_this) {
      return function(_arg) {
        var conclusion, conclusionId, conclusionsToCheck, panelVoiceVersion, recommendation, typeValue, value, _ref, _ref1, _ref2, _ref3, _ref4;
        recommendation = _arg[0], panelVoiceVersion = _arg[1];
        conclusionsToCheck = ['recommendationTypeIntervention', 'recommendationTypeOption', 'decisionTypeImplementation', 'decisionTypeCoverage'];
        conclusionId = _((_ref = recommendation != null ? recommendation['templateDef']['conclusions'] : void 0) != null ? _ref : []).chain().filter(function(field) {
          return Boolean(field['checked']) || Boolean(field['required']);
        }).pluck('id').find(function(id) {
          return __indexOf.call(conclusionsToCheck, id) >= 0;
        }).value();
        if (!conclusionId) {
          return null;
        }
        conclusion = (_ref1 = recommendation != null ? (_ref2 = recommendation['templateData']) != null ? (_ref3 = _ref2['conclusions']) != null ? (_ref4 = _ref3['sections']) != null ? _ref4[conclusionId] : void 0 : void 0 : void 0 : void 0) != null ? _ref1 : {};
        typeValue = _this._getFinalValue(conclusion, panelVoiceVersion, 'selectedOption');
        value = _this._checkTemplateSection(conclusionId, typeValue);
        if (value === 'positive') {
          return 'MedicalGuidelineRecommendation';
        } else if (value === 'negative') {
          return 'MedicalGuidelineContraindication';
        } else {
          return null;
        }
      };
    })(this));
  };

  IEtdRecommendationsExporter.prototype._fetchRecommendation = function(recommendationId) {
    return this.pouchAdapter.fetch(mediator.project.id, mediator.project.id).then((function(_this) {
      return function(projectMetadoc) {
        var docIds, originalDocId;
        if (projectMetadoc.adolopmentIteration != null) {
          originalDocId = "" + recommendationId + ":adoloped_" + projectMetadoc.adolopmentIteration;
          docIds = [recommendationId, originalDocId, ADOLOPMENT_DATA_DOC_ID];
          return _this.pouchAdapter.fetch(mediator.project.id, docIds, {
            attachments: true
          }).then(function(_arg) {
            var adolopedDoc, adolopmentData, adolopmentSections, originalDoc, rows, _ref, _ref1;
            rows = _arg.rows;
            _ref = _.pluck(rows, 'doc'), adolopedDoc = _ref[0], originalDoc = _ref[1], adolopmentData = _ref[2];
            if (!originalDoc) {
              return adolopedDoc;
            }
            adolopmentSections = (adolopmentData != null ? (_ref1 = adolopmentData.etdsData) != null ? _ref1[recommendationId] : void 0 : void 0) || {};
            return AdolopmentsHelper.mergeEtdsBasedOnAdolopmentData(originalDoc, adolopedDoc, adolopmentSections);
          });
        } else {
          return _this.pouchAdapter.fetch(mediator.project.id, recommendationId, {
            attachments: true
          });
        }
      };
    })(this));
  };

  return IEtdRecommendationsExporter;

})(RecommendationsExporter);
