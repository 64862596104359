var INITIAL_STATE, SEARCHABLE_COLUMNS, TasksActions, generateStore, mediator;

generateStore = require('stores/utils/generate_store');

TasksActions = require('actions/tasks_actions');

mediator = require('mediator');

SEARCHABLE_COLUMNS = ['assignedTo', 'condition', 'reviewedBy', 'title'];

INITIAL_STATE = Immutable.fromJS({
  meta: {
    fetching: false,
    search: '',
    sort: {
      key: 'dueDate',
      direction: 'asc'
    },
    filters: {
      completed: false
    },
    editingTask: null,
    deleteConfirmationDialogOpen: null
  },
  tasks: []
});

module.exports = generateStore({
  name: 'TasksStore',
  initialState: INITIAL_STATE,
  boundActions: [TasksActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], true));
    },
    onFetchSuccess: function(tasks) {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['meta', 'fetching'], false);
        return s.set('tasks', Immutable.fromJS(tasks));
      }));
    },
    onFetchError: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], false));
    },
    onDbChange: function(doc) {
      var idx;
      if (doc._deleted) {
        return this.setState(this.state.update('tasks', function(tasks) {
          return tasks.filterNot(function(task) {
            return task.get('_id') === doc._id;
          });
        }));
      } else {
        idx = this.state.get('tasks', Immutable.List()).findIndex(function(task) {
          return task.get('_id') === doc._id;
        });
        if (idx !== -1) {
          return this.setState(this.state.setIn(['tasks', idx], Immutable.fromJS(doc)));
        } else {
          return this.setState(this.state.update('tasks', function(tasks) {
            return tasks.push(Immutable.fromJS(doc));
          }));
        }
      }
    },
    onOpenCreateOrEditDialog: function(task) {
      return this.setState(this.state.setIn(['meta', 'editingTask'], task));
    },
    onCloseCreateOrEditDialog: function() {
      return this.setState(this.state.setIn(['meta', 'editingTask'], null));
    },
    onOpenDeleteConfirmationDialog: function(task) {
      return this.setState(this.state.setIn(['meta', 'deleteConfirmationDialogOpen'], task));
    },
    onCloseDeleteConfirmationDialog: function() {
      return this.setState(this.state.setIn(['meta', 'deleteConfirmationDialogOpen'], null));
    },
    onSort: function(sortBy) {
      return this.setState(this.state.setIn(['meta', 'sort'], Immutable.fromJS(sortBy)));
    },
    onSearch: function(searchQuery) {
      return this.setState(this.state.setIn(['meta', 'search'], searchQuery));
    },
    onToggleCompletedFilter: function() {
      var completed, completedPath;
      completedPath = ['meta', 'filters', 'completed'];
      completed = this.state.getIn(completedPath);
      return this.setState(this.state.setIn(completedPath, !completed));
    },
    getTasks: function() {
      var searchQuery, showCompletedOnly, sortDirection, sortKey, tasks, tasksSorted;
      sortKey = this.state.getIn(['meta', 'sort', 'key']);
      sortDirection = this.state.getIn(['meta', 'sort', 'direction']);
      showCompletedOnly = this.state.getIn(['meta', 'filters', 'completed']);
      searchQuery = this.state.getIn(['meta', 'search']);
      tasksSorted = this.state.get('tasks', Immutable.List()).sortBy(function(task) {
        return (task.get(sortKey) || '').toString().toLowerCase();
      });
      tasks = sortDirection === 'asc' ? tasksSorted : tasksSorted.reverse();
      return tasks.filter(function(task) {
        return (!_.isEmpty(searchQuery) ? _.some(SEARCHABLE_COLUMNS, function(key) {
          var value;
          value = (task.get(key) || '').toString().toLowerCase();
          return (value != null) && value.indexOf(searchQuery.toLowerCase()) !== -1;
        }) : true) && (showCompletedOnly ? task.get('done') : true);
      });
    },
    getFilters: function() {
      return this.state.getIn(['meta', 'filters'], Immutable.Map());
    },
    getSort: function() {
      return this.state.getIn(['meta', 'sort']);
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'fetching'], false);
    },
    getEditingTask: function() {
      return this.state.getIn(['meta', 'editingTask'], null);
    },
    getDeleteConfirmationDialogOpen: function() {
      return this.state.getIn(['meta', 'deleteConfirmationDialogOpen'], null);
    },
    getSearchQuery: function() {
      return this.state.getIn(['meta', 'search']);
    }
  }
});
