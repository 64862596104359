var EditableItemView, TaskView, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

EditableItemView = require('base/views/editable_item_view');

template = require('views/templates/tasks/task');

module.exports = TaskView = (function(_super) {
  __extends(TaskView, _super);

  function TaskView() {
    return TaskView.__super__.constructor.apply(this, arguments);
  }

  TaskView.prototype.template = template;

  TaskView.prototype.bindings = {
    '.title': {
      observe: 'title',
      setOptions: {
        silent: true
      }
    },
    '.done': {
      observe: 'done',
      onSet: function(val) {
        this.model.save({
          done: val
        });
        return val;
      }
    },
    '.due-date': {
      observe: 'start',
      onGet: function(val) {
        return moment.unix(val).format($.t('tasks:calendar.date_input_format'));
      },
      onSet: function(val) {
        var timestamp;
        if (!val) {
          return;
        }
        timestamp = moment(val, $.t('tasks:calendar.date_input_format')).unix();
        this.model.set({
          end: timestamp
        });
        return timestamp;
      }
    }
  };

  TaskView.prototype.focusInput = function() {
    return this.$('.title').focusWithCursorAtEnd();
  };

  TaskView.prototype.afterRender = function() {
    TaskView.__super__.afterRender.apply(this, arguments);
    return this.$('.due-date').datepicker({
      dateFormat: $.t('tasks:calendar.datepicker_format')
    });
  };

  TaskView._close = function() {
    TaskView.__super__.constructor._close.apply(this, arguments);
    return this.$('.due-date').datepicker('hide');
  };

  TaskView.prototype["delete"] = function() {
    return TaskView.__super__["delete"].call(this, $.t('tasks:todo.delete_confirmation'));
  };

  return TaskView;

})(EditableItemView);
