Popover = require 'components/common/popover'
QuestionsStatusesActions = require 'actions/questions_statuses_actions'
QuestionStatusForm = require 'components/evidence_syntheses/question/question_status_form'
Translation = require 'components/mixins/translation'

{ string, instanceOf, bool, arrayOf }= PropTypes

ProgressSelect = createReactClass

  displayName: "ProgressSelect"
  mixins: [Translation('es:question.statuses')]

  propTypes:
    editable: bool
    questionId: string
    questionStatuses: instanceOf(Immutable.Map)
    showLabel: bool
    statusesList: arrayOf(string)
    groupKey: string

  getInitialState: ->
    formVisible: false

  getDefaultProps: ->
    editable: true
    questionId: ''
    questionStatuses: Immutable.Map()
    showLabel: true
    status: ''

  toggleCompleted: (e) ->
    QuestionsStatusesActions.toggleQuestionCompleted @props.questionId, e.target.checked
    @toggleForm()

  updateStatus: (key, value) -> =>
    QuestionsStatusesActions.updateQuestionStatus @props.questionId, key, value
    @toggleForm()

  toggleForm: ->
    @setState formVisible: not @state.formVisible

  render: ->
    {
      editable
      questionId
      questionStatuses
      showLabel
      statusesList
      groupKey
      withClearBtn
    } = @props

    currentStatus = questionStatuses.get(groupKey, Immutable.Map())
    status = currentStatus.get('status', '')

    boxClassess = classNames 'module-progress__box',
      "module-progress__box--#{_.string.dasherize(status)}"
    statusText = if _.isEmpty(status) then '' else @i18n("status.#{_.string.underscored(status)}")
    return null if _.isEmpty questionId
    <div className='module-progress-select'>
      {showLabel && <span className='bold'>{@i18n 'labels.status'}</span>}
      {if editable
        <Popover
          onRequestClose={@toggleForm}
          visible={@state.formVisible}
        >
          <span className={boxClassess} onClick={@toggleForm}>
            {statusText}
          </span>
          <QuestionStatusForm
            currentStatus={currentStatus}
            status={status}
            groupKey={groupKey}
            statusesList={statusesList}
            toggleCompleted={@toggleCompleted}
            updateStatus={@updateStatus}
            i18n={@i18n}
            withCompleted={false}
          />
        </Popover>
      else
        <span className={boxClassess} onClick={@toggleForm}>
          {statusText}
        </span>
      }
    </div>

module.exports = ProgressSelect
