var Migration, mediator;

mediator = require('mediator');

module.exports = Migration = (function() {
  function Migration(downSupported) {
    this.downSupported = downSupported != null ? downSupported : true;
    this.title = _.functionName(this.constructor);
  }

  Migration.prototype.up = function(project, colls) {
    return mediator.log("[UP] Running migration " + this.title);
  };

  Migration.prototype.down = function(project, colls) {
    return mediator.log("[DOWN] Running migration " + this.title);
  };

  return Migration;

})();
