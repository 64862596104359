CheckboxInput = require 'components/common/checkbox_input'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
InfoIconWithTooltip = require 'components/common/info_icon_with_tooltip'
Modal = require 'components/common/modal'
mediator = require 'mediator'
Tooltip = require 'components/common/tooltip'
ReferenceAuthorsEdit = require 'components/references/reference_authors_edit'
Translation = require 'components/mixins/translation'
{ getDefaultAttrs, authorsToString, authorStringToObject } = require 'lib/references_utils'

EDIT_FIELDS = _.chain(getDefaultAttrs())
  .omit(['lastUpdated', 'added', 'docType', 'status', 'attachment', 'recNumber'])
  .keys()
  .value()

ReferenceEditModal = createReactClass
  displayName: 'ReferenceEditModal'

  mixins: [
    CustomRenderMixin
    Translation('references:columns')
  ]

  propTypes:
    action: PropTypes.oneOf(['add', 'edit'])
    data: PropTypes.instanceOf(Immutable.Map).isRequired
    isOpen: PropTypes.bool.isRequired
    onClose: PropTypes.func.isRequired

  getDefaultProps: ->
    action: 'edit'

  collectValues: ->
    EDIT_FIELDS.reduce (result, fieldName) =>
      result[fieldName] = @_getFieldValue fieldName
      result
    , {}

  onSave: ->
    @props.onSave @collectValues()

  _getFieldValue: (fieldName) ->
    return this[fieldName].isChecked() if fieldName is 'researchResults'
    stringData = this[fieldName]?.value?.trim()

    switch fieldName
      when 'authors'
        if mediator.services.switches.isServerSwitchOn 'mdgFeatures'
          stringData.split(/; ?/).map authorStringToObject
        else
          this[fieldName].getAuthors().toJS()
      when 'tags', 'authorKeywords'
        if _.isEmpty(stringData) then [] else stringData.split /, ?/
      else stringData

  _getFieldData: (fieldName) ->
    @props.data.get fieldName

  _getTextContent: (fieldName) ->
    rawContent = @_getFieldData fieldName
    return '' unless rawContent

    switch fieldName
      when 'year' then rawContent.match(/\d{4}/)?[0]
      when 'authors' then authorsToString(rawContent)
      when 'tags', 'authorKeywords' then rawContent.join ', '
      else rawContent

  renderLabelCell: (fieldName) ->
    fieldLabel = @i18n fieldName
    <td className="field-name #{fieldName}">
      <span>{fieldLabel}</span>
      {switch fieldName
        when 'researchResults'
          <Tooltip targetJoint='top' tipJoint='bottom left'>
            <span className='info-icon' title={@i18n '../research_results_tooltip'} />
          </Tooltip>
        when 'authors'
          <Tooltip targetJoint='top' tipJoint='bottom left'>
            <span className='info-icon' title={@i18n '../authors_field_format_tooltip'} />
          </Tooltip>
        else null
        }
    </td>

  renderFieldEdit: (fieldName, fieldIdx) ->
    fieldRef = (el) => this[fieldName] = el
    <td className="field-edit #{fieldName}">
      {switch fieldName
        when 'researchResults'
          <CheckboxInput ref={fieldRef} defaultChecked={@_getFieldData fieldName} />
        when 'authors'
          if mediator.services.switches.isServerSwitchOn 'mdgFeatures'
            <input
              ref={fieldRef}
              type='text'
              defaultValue={@_getTextContent fieldName}
            />
          else
            <ReferenceAuthorsEdit
              ref={fieldRef}
              authors={@_getFieldData fieldName}
            />
        else
          <input
            ref={fieldRef}
            type='text'
            defaultValue={@_getTextContent fieldName}
          />
      }
    </td>

  renderRows: ->
    EDIT_FIELDS.map (fieldName, idx) =>
      <tr key={idx}>
        {@renderLabelCell fieldName}
        {@renderFieldEdit fieldName, idx}
      </tr>

  render: ->
    { action, isOpen, onClose } = @props

    <Modal
      className='reference-edit-modal'
      closeButton
      isOpen={isOpen}
      modalSize="medium"
      onClose={onClose}
      title={switch action
        when 'add'
          @i18n '../new_title'
        when 'edit'
          @i18n '../edit_title'
        else
          undefined
      }
    >
      <div>
        <div className='edit-container'>
          <table className='reference-edit-table'>
            <tbody>
              {@renderRows()}
            </tbody>
          </table>
        </div>
        <div className='row buttons'>
          <div className='col-6'>
            <button className='btn btn-cancel' onClick={onClose}>
              {@i18n '../close'}
            </button>

          </div>
          <div className='col-6'>
            <button className='btn' onClick={@onSave}>
              {@i18n '../save'}
            </button>
          </div>
        </div>
      </div>
    </Modal>

module.exports = ReferenceEditModal
