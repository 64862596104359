CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Judgement = require 'components/etd/judgements/judgement'
Translation = require 'components/mixins/translation'

{ CellWithTitle, Card, CardContent } = ReactComponents

JudgementsCell = createReactClass
  displayName: "JudgementsCell"

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  onEditClick: ->
    return if @props.readOnly
    if not @props.editable and @props.onEditClick
      @props.onEditClick()

  render: ->
    cardClassess = classNames 'judgement__card', 'readOnly': @props.readOnly
    <CellWithTitle
      className={@props.className}
      cellTitle={@i18n 'judgement' unless @props.renderMode is 'printout'}
    >
      <div onClick={@onEditClick}>
        <Card className={cardClassess}>
          <CardContent>
            {@props.showJudgement and <Judgement {...@props} />}
          </CardContent>
        </Card>
      </div>
    </CellWithTitle>


module.exports = JudgementsCell
