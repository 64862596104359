ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConfirmationModal = require 'components/common/confirmation_modal'
ConnectStore = require 'components/enhancers/connect_store'
{ kebabCase } = require 'lodash'
MdaTopicsActions = require 'actions/mda_topics_actions'
MdaTopicsStore = require 'stores/mda_topics_store'
Modal = require 'components/common/modal'
mediator = require 'mediator'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
{ useState } = React
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

TEMPLATES = ['condition', 'procedure']

TOPIC_FORM =
  main:
    [
      fieldKey: 'name'
      type: 'string'
      showClear: false
      useControlledInput: true
    ,
      fieldKey: 'slug'
      type: 'string'
      showClear: false
      useControlledInput: true
    ,
      fieldKey: 'template'
      type: 'select'
      disabled: not _.includes mediator.user.getAccessRights(), 'admin'
      options: _.map TEMPLATES, (value) -> { value, text: $.t "mda:topics.templates.#{value}" }
      showClear: false
    ,
      fieldKey: 'bodyParts'
      type: 'multiSelect'
      options: _.map _.range(1, 12), (value) ->
        # Had to stringify values here as I was having some problems with numbers
        # in the multi select component -> they are destringified in the exporter.
        { value: "#{value}", text: $.t "mda:topics.bodyParts.#{value}" }
      showClear: false
    ]

storeConnector =
  MdaTopicsStore: (Store) ->
    originalTopic: Store.getEditingTopic()
    applying: Store.isApplying()
    confirmResetDialogOpen: Store.isConfirmResetDialogOpen()

MdaTopicModal = ({ originalTopic, confirmResetDialogOpen, applying }) ->
  i18n = useI18n('mda:topics')
  [topic, setTopic] = useState(originalTopic)

  onApply = useCoffeeCallback [topic, originalTopic], ->
    if topic.get('_id')
      if originalTopic.get('template') is topic.get('template')
        MdaTopicsActions.updateTopic(topic.toJS())
      else
        MdaTopicsActions.openConfirmResetDialog()
    else
      MdaTopicsActions.createTopic(topic.toJS())

  onResetContent = useCoffeeCallback [topic], ->
    MdaTopicsActions.closeConfirmResetDialog()
    MdaTopicsActions.resetChapterStructureAndUpdateTopic(topic.toJS())
    .then MdaTopicsActions.closeCreateOrEditDialog

  onChange = useCoffeeCallback [originalTopic, setTopic, topic], (field, value) ->
    setTopic topic.withMutations (topicMutable) ->
      topicMutable.set field, value
      if field is 'name' and not originalTopic.slug
        topicMutable.set 'slug', kebabCase value

  <Modal
    className="mda-topics-field-modal"
    isOpen
    modalSize="medium"
    title={i18n if topic.get('_id') then 'update_topic' else 'new_topic'}
  >
    <ExtractionForm
      data={topic.toJS()}
      form={TOPIC_FORM}
      i18n={i18n}
      inModal
      onChange={onChange}
    />
    <ApplyCancelButtons
      onCancel={MdaTopicsActions.closeCreateOrEditDialog}
      onApply={onApply}
      applying={applying}
      applyLabel={i18n if topic.get('_id') then '/actions.update' else '/actions.create'}
      isSubmitEnabled={not _.isEmpty(topic.get('name')) and not _.isEmpty(topic.get('template'))}
    />
    <ConfirmationModal
      isOpen={confirmResetDialogOpen}
      question={i18n 'reset_topic_confirmation'}
      cancelLabel={i18n '/actions.cancel'}
      onConfirm={onResetContent}
      onCancel={MdaTopicsActions.closeConfirmResetDialog}
    />
  </Modal>

module.exports = ConnectStore MdaTopicModal, [MdaTopicsStore], storeConnector
