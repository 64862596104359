ReferencesInsertModal = require 'components/etd/references_insert_modal'
ReferenceCitationMixin = require 'components/mixins/reference_citation_mixin'
Translation = require 'components/mixins/translation'
GdtEditor = Editor.Editor
{ insertTextContent } = Editor.EditorUtils
{ builtInStyleControls: controls } = Editor

# handlePastedText :: EditorState -> pastedText -> pastedHTML
# insertTextContent :: EditorState -> text -> EntityData
# Using R.nAry to make insertTextContent ignore 3rd argument which doesn't fit (pastedHTML vs
# EntityData)
pasteHandler =
  handlePastedText: R.nAry 2, insertTextContent

editorControls = [
  controls.BOLD
  controls.ITALIC
  controls.UNDERLINE
  controls.UNORDERED_LIST
  controls.LINK
  controls.CUSTOM
  controls.CLEAR_FORMATING
]

ContentEditor = createReactClass
  displayName: 'ContentEditor'

  mixins: [Translation(''), ReferenceCitationMixin()]

  _ref: (el) ->
    @gdtEditor = el

  getContentJSON: ->
    @gdtEditor.getEditorContent().toJS()

  getDefaultProps: ->
    content: ""

  render: ->

    <div>
      <GdtEditor
        {...@props}
        controlsOrder={editorControls}
        customHandlers={pasteHandler}
        customControls={[@getReferenceToggleSpec()]}
        customDecorators={@getReferenceCitationDecorator()}
        placeholder={"#{@i18n 'actions.type_here'}..."}
        ref={@_ref}
        stickControlsTo='.gdt-collapsible-chapters.chapters-list'
      />
      {if @state.showReferencesInsert
        <ReferencesInsertModal
          onInsert={@onInsertReferences}
          onClose={@toggleReferencesInsert}
        />
      }
    </div>

module.exports = ContentEditor
