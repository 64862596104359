ScopeRatingMixin =
  hasMemberVoted: (memberId) ->
    @props.membersSubmitted.includes memberId

  getMemberName: (memberId) ->
    memberData = @props.members.get memberId
    return null unless memberData
    "#{memberData.get('givenNames') ? ""} #{memberData.get('lastName')}"

  membersMapper: (memberId, idx) ->
    liClass = if @hasMemberVoted memberId then 'completed' else 'new'
    memberName = @getMemberName memberId
    # don't render member if it was deleted (his name can't be found)
    return null unless memberName

    <li className={liClass} key={idx}>
      <span className='caption'>{memberName}</span>
    </li>

module.exports = ScopeRatingMixin
