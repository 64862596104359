GdtEditorWithApply = Editor.EditorWithApply
GdtEditor = Editor.Editor
EtdActions = require 'actions/etd_actions'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ CellWithTitle, Card, CardContent } = ReactComponents
ReferencesInsertModal = require 'components/etd/references_insert_modal'
ReferenceCitationMixin = require 'components/mixins/reference_citation_mixin'


AdditionalConsideration = createReactClass

  displayName: 'AdditionalConsiderationComponent'
  mixins: [CustomRenderMixin, Translation('es:recommendations.table'), ReferenceCitationMixin()]

  propTypes:
    placeholder: PropTypes.string

  getDefaultProps: ->
    placeholder: null

  componentDidUpdate: (prevProps, prevState) ->
    # if content has changed - force editor re-render,
    # this is important when editing additional consideration in modal window.
    prevContent = @getContent prevProps
    newContent = @getContent()
    hasContentChanged = if _.isString prevContent
      prevContent isnt newContent
    else if newContent and prevContent
      not prevContent.equals newContent
    else
      false

    @gdtEditor?.resetContent newContent if hasContentChanged

  getContent: (props = @props) ->
    { additionalConsiderations, isConsensus } = props

    if isConsensus
      additionalConsiderations.get('contentConsensus') or additionalConsiderations.get('content')
    else
      additionalConsiderations.get('content')

  onCancel: ->
    @gdtEditor.resetContent @getContent()

  saveContent: ->
    content = @gdtEditor.getEditorContent()
    options = isConsensus: @props.isConsensus
    EtdActions.updateEditorContent { cellId: @props.cellId, content, options }

  onApply: ->
    @saveContent()

  onEditClick: ->
    return if @props.readOnly
    if not @props.editable and @props.onEditClick
      @props.onEditClick()

  render: ->
    { highlighted, readOnly, renderMode, editable, isConsensus } = @props
    cellClass = classNames 'additional-considerations', 'highlighted': highlighted

    editable = if (renderMode is 'printout' or readOnly) then false else editable

    cardClassess = classNames 'ep-card-with-etd-editor', 'readOnly': readOnly

    <CellWithTitle
      className={cellClass}
      cellTitle={@i18n 'additional_considerations' unless renderMode is 'printout'}
      rowSpan={@props.rowSpan}
      colSpan={@props.colSpan}
    >
      <div onClick={@onEditClick}>
        <Card className={cardClassess}>
          <CardContent>
            <GdtEditorWithApply
              i18n={@i18n}
              readOnly={not editable}
              ref={(el) => @gdtEditor = el?.gdtEditor}
              customDecorators={@getReferenceCitationDecorator()}
              customControls={[@getReferenceToggleSpec()]}
              stickControlsTo='#recommendations'
              editorContent={@getContent()}
              onFocus={@props.onFocus}
              onCancel={@onCancel}
              onApply={@onApply}
              onBlur={@saveContent}
              placeholder={@props.placeholder}
            />
          </CardContent>
        </Card>
        {if @state.showReferencesInsert
          <ReferencesInsertModal
            onInsert={@onInsertReferences}
            onClose={@toggleReferencesInsert}
          />
        }
      </div>
    </CellWithTitle>

module.exports = AdditionalConsideration
