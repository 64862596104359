var Chaplin, FocusManager, Focusable, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

Chaplin = require('chaplin');

mediator = require('mediator');

Focusable = require('base/lib/traits/focusable');

utils = require('base/lib/utils');

module.exports = FocusManager = (function() {
  function FocusManager() {
    this.setFocus = __bind(this.setFocus, this);
  }

  FocusManager.prototype.focusPaths = [];

  FocusManager.prototype.setFocus = function(viewOrCallbackFunction, callbackFunction) {
    var deferred, focusChangedFunction, focusedView, view, _ref, _ref1, _ref2;
    _ref = _(viewOrCallbackFunction).isFunction() ? [mediator.heirView, viewOrCallbackFunction] : _(viewOrCallbackFunction).isUndefined() ? [mediator.heirView, callbackFunction] : [viewOrCallbackFunction, callbackFunction], view = _ref[0], focusChangedFunction = _ref[1];
    if (!view || view.disposed) {
      return;
    }
    this._ensureViewIsFocusable(view);
    if (view.cid === ((_ref1 = mediator.currentFocus) != null ? _ref1.cid : void 0)) {
      this._invokeCallback(false, focusChangedFunction);
      return;
    }
    focusedView = (_ref2 = mediator.currentFocus) != null ? _ref2 : mediator.heirView;
    deferred = $.Deferred();
    deferred.done((function(_this) {
      return function() {
        var _i, _len, _ref3, _ref4;
        if (mediator.currentFocus !== view) {
          if ((focusedView != null) && !focusedView.disposed && !focusedView._onFocusLostTriggered) {
            focusedView.onFocusLost(view);
            _ref4 = (_ref3 = focusedView._focusLostDeferreds) != null ? _ref3 : [];
            for (_i = 0, _len = _ref4.length; _i < _len; _i++) {
              deferred = _ref4[_i];
              deferred.resolve(true);
            }
          }
          _this.updateFocusPaths(view);
          view._focusGained();
        }
        view._focusLostDeferreds = [];
        return _this._invokeCallback(true, focusChangedFunction);
      };
    })(this)).always(function() {
      return focusedView != null ? focusedView._beforeFocusLostTriggered = false : void 0;
    });
    if ((focusedView == null) || focusedView.disposed) {
      return deferred.resolve(true);
    } else {
      if (!focusedView._beforeFocusLostTriggered) {
        focusedView._beforeFocusLostTriggered = true;
        return focusedView.beforeFocusLost(deferred, view);
      } else {
        return focusedView._focusLostDeferreds.push(deferred);
      }
    }
  };

  FocusManager.prototype.updateFocusPaths = function(focusTo) {
    var commonFocusPath, idx, view, _i, _len, _ref;
    commonFocusPath = mediator.currentFocus ? utils.longestCommonPrefix([focusTo._focusPath, mediator.currentFocus._focusPath], '.').replace(/\.$/, '') : '';
    if (commonFocusPath) {
      _ref = this.focusPaths;
      for (idx = _i = 0, _len = _ref.length; _i < _len; idx = ++_i) {
        view = _ref[idx];
        if (_.string.startsWith(view._focusPath, "" + commonFocusPath + ".")) {
          if (!(view.disposed || view._onFocusLostTriggered || view.cid === focusTo.cid)) {
            view.onFocusLost(focusTo);
          }
          delete this.focusPaths[idx];
        }
      }
    } else {
      this.focusPaths.length = 0;
    }
    this.focusPaths = _.compact(this.focusPaths);
    return this.focusPaths.push(focusTo);
  };

  FocusManager.prototype._invokeCallback = function(focusChanged, focusChangedFunction) {
    if (focusChangedFunction) {
      return focusChangedFunction(focusChanged);
    }
  };

  FocusManager.prototype.isFocusable = function(view) {
    return _(view.traits).contains('Focusable');
  };

  FocusManager.prototype._ensureViewIsFocusable = function(view) {
    if (this.isFocusable(view)) {
      return;
    }
    if (view === mediator.heirView) {
      return view.enable(Focusable);
    } else {
      throw new Error("Setting focus on unfocusable view (" + (_.functionName(view.constructor)) + ")");
    }
  };

  return FocusManager;

})();
