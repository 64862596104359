var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"edit-input-block form-group row mt-10\">\n  <div class=\"inline\">\n    <textarea name=\"inconclusivePrevalencesDesc\" class=\"cell-edit-textarea\">\n      "
    + escapeExpression(((helper = (helper = helpers.inconclusivePrevalencesDesc || (depth0 != null ? depth0.inconclusivePrevalencesDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"inconclusivePrevalencesDesc","hash":{},"data":data}) : helper)))
    + "\n    </textarea>\n  </div>\n  <div class=\"sup\"></div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "inconclusivePrevalencesDesc", "inconclusive_prevalences_desc", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}