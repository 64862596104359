ConnectStore = require 'components/enhancers/connect_store'
DropdownMenu = require 'components/common/dropdown_menu'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
NMAListActions = require 'actions/nma_list_actions'
NMAListStore = require 'stores/nma_list_store'
Router = require 'router'
Spinner = require 'components/common/spinner'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

storeConnector =
  NMAListStore: (Store) ->
    isFetching: Store.isFetching()
    questions: Store.getQuestions()

NMAQuestion = ({ question }) ->
  questionTitle = question.get 'question', ''
  i18n = useI18n 'nma:questions_list'

  onClick = useCoffeeCallback [question], (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    mediator.publish '!router:route',
      Router::getProjectRelativeUrl "/nma/#{question.get '_id'}"

  onMenuSelect = useCoffeeCallback [question, questionTitle], (option) ->
    switch option
      when 'edit'
        NMAListActions.openQuestionModal question.toJS()
      when 'delete'
        confirmOptions =
          title: i18n 'confirm_delete.title'
          message: i18n 'confirm_delete.message', { questionTitle }
          confirmText: i18n '/actions.delete'
          declineText: i18n '/actions.cancel'
          yesClass: 'danger'
        mediator.dialogs.confirm confirmOptions, (confirmed) ->
          return unless confirmed
          NMAListActions.deleteQuestion question.toJS()

  <div className="nma-questions-list__question" onClick={onClick}>
    <Tooltip>
      <div className="question__title" title={questionTitle}>
        {questionTitle}
      </div>
    </Tooltip>
    <DropdownMenu
      menuPosition={alignment: 'end'}
      onSelect={onMenuSelect}
      opener={<IconButton className="question__menu" iconName="more" />}
      options={[
        className: 'edit'
        text: i18n '/actions.edit'
        value: 'edit'
      ,
        className: 'delete'
        text: i18n '/actions.delete'
        value: 'delete'
      ]}
    />
  </div>

NMAQuestionsList = ({ isFetching, questions }) ->
  i18n = useI18n 'nma:questions_list'

  return <Spinner /> if isFetching

  <div className="nma-questions-list">
    {questions.map (question) ->
      <NMAQuestion key={question.get '_id'} question={question} />
    }
    {questions.isEmpty() and <div className="nma-questions-list__no-questions">
      {i18n 'no_questions'}
    </div>}
  </div>

NMAQuestionsList.propTypes =
  isFetching: PropTypes.bool.isRequired
  questions: PropTypes.instanceOf(Immutable.List).isRequired

module.exports = ConnectStore NMAQuestionsList, [NMAListStore], storeConnector
