var Model, QuestionsGeneration,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

module.exports = QuestionsGeneration = (function(_super) {
  __extends(QuestionsGeneration, _super);

  function QuestionsGeneration() {
    return QuestionsGeneration.__super__.constructor.apply(this, arguments);
  }

  QuestionsGeneration.prototype.getGeneratedQuestions = function() {
    var finishedStep, _ref;
    finishedStep = (_ref = this.get('steps')) != null ? _ref['questions.finished'] : void 0;
    if (!finishedStep) {
      return null;
    }
    return finishedStep.questions;
  };

  return QuestionsGeneration;

})(Model);
