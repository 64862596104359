DecisionAid = require 'components/decision_aid/decision_aid'
Translation = require 'components/mixins/translation'


DecisionAidsList = createReactClass
  displayName: 'DecisionAidsList'

  propTypes:
    decisionAids: PropTypes.instanceOf(Immutable.List).isRequired

  mixins: [Translation('')]

  getDefaultProps: ->
    decisionAids: Immutable.List()

  render: ->
    { decisionAids, editingDecisionAidId } = @props

    <div className='decision-aids-list'>
      {if @props.decisionAids.isEmpty()
        <dl>
          <dt>{@i18n 'da:welcome_message'}</dt>
          <dd>{@i18n 'da:create_action_description'}</dd>
        </dl>
      else
        <div>
          {decisionAids.map (decisionAid) ->
            daId = decisionAid.get '_id'

            <DecisionAid
              data={decisionAid}
              isEditing={editingDecisionAidId is daId}
              key={daId}
            />
          }
        </div>
      }
    </div>

module.exports = DecisionAidsList
