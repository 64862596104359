ConnectStore = require 'components/enhancers/connect_store'
EllipsizedText = require 'components/common/ellipsized_text'
{ HEALTH_ADVISOR_WORKSPACE_ID } = require 'lib/mda_helper'
mediator = require 'mediator'
Modal = require 'components/common/modal'
ReferencesActions = require 'actions/references_actions'
ReferencesStore = require 'stores/references_store'
Router = require 'router'
Tooltip = require 'components/common/tooltip'
{ vancouverFormat } = require 'lib/references_utils'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'
{ bool, instanceOf } = PropTypes

routeToAndCloseModal = (link) ->
  mediator.publish '!router:route', link
  ReferencesActions.closeReferenceUsageModal()

LinkToUsage = ({ data, value, onClick }) ->
  <Tooltip>
    <div
      className="link-to-usage"
      title={value}
      onClick={onClick(data.get('_id'))}
    >
      <EllipsizedText height={40} text={value or ''} />
    </div>
  </Tooltip>

ReferenceUsage = ({ referenceUsedIn }) ->
  i18n = useI18n()

  handleLinkToMdgTables = useCoffeeCallback [], (questionId) -> ->
    link = Router::routeForSubmodule 'evidence-syntheses', 'mdg-evidence-table', { questionId }
    routeToAndCloseModal link

  handleLinkToDocumentSections = useCoffeeCallback [], (_sectionId) -> ->
    link = Router::routeForModule 'document-sections', projectId: mediator.project.id
    routeToAndCloseModal link

  handleLinkToTopic = useCoffeeCallback [], (topicId) -> (_sectionId) -> ->
    link = Router::routeForSubmodule 'mda_topics', null, questionId: topicId
    routeToAndCloseModal link

  <div className="references-usage">
    {not referenceUsedIn.get('questions', Immutable.Map()).isEmpty() and <div>
      <label>{i18n 'references:used_in_tables'}</label>
      {referenceUsedIn.get('questions').map (question) ->
        <LinkToUsage
          onClick={handleLinkToMdgTables}
          key={question.get('_id')}
          data={question}
          value={question.get('question')}
        />
      }
    </div>}
    {not referenceUsedIn.get('document_sections_section', Immutable.Map()).isEmpty() and <div>
      {if mediator.activeWorkspace is HEALTH_ADVISOR_WORKSPACE_ID
        <label>
          {i18n 'references:used_in_topics'}
        </label>
      else
        <label>
          {i18n 'references:used_in_ds'}
        </label>
      }
      {referenceUsedIn.get('document_sections_section').map (section) ->
        if section.get('mdaTopicId')
          <LinkToUsage
            onClick={handleLinkToTopic(section.get('mdaTopicId'))}
            key={section.get('_id')}
            data={section}
            value={"#{section.get('topic')} - #{section.get('parentChapterTitle')}"}
          />
        else
          <LinkToUsage
            onClick={handleLinkToDocumentSections}
            key={section.get('_id')}
            data={section}
            value={section.get('parentChapterTitle')}
          />
      }
    </div>}
  </div>

ReferenceUsage.propTypes =
  referenceUsedIn: instanceOf(Immutable.Map)

ReferenceUsage.defaultProps =
  referenceUsedIn: Immutable.Map()

storeConnector =
  ReferencesStore: (Store) ->
    isOpen: Store.isReferenceUsageModalOpen()
    reference: Store.getReferenceUsageModalReference()
    referenceUsedIn: Store.getReferenceUsageModalReferenceUsedIn()

ReferenceUsageModal = ({ isOpen, reference, referenceUsedIn }) ->
  i18n = useI18n('references:reference_usage_modal')

  vancouver = useCoffeeMemo [reference], ->
    return '' unless reference?
    vancouverFormat reference.toJS(), true

  <Modal
    closeButton
    isOpen={isOpen}
    modalSize="medium"
    onClose={ReferencesActions.closeReferenceUsageModal}
    title={i18n 'reference_usage'}
  >
    <p className="bold">
      {i18n 'reference'}
    </p>
    <p dangerouslySetInnerHTML={__html: vancouver} />
    <p className="bold">{i18n 'used_in'}</p>
    <ReferenceUsage referenceUsedIn={referenceUsedIn} />
  </Modal>

ReferenceUsageModal.propTypes =
  isOpen: bool.isRequired
  reference: instanceOf(Immutable.Map)
  referenceUsedIn: instanceOf(Immutable.Map)

ReferenceUsageModal.defaultProps =
  referenceUsedIn: Immutable.Map()

module.exports = ConnectStore ReferenceUsageModal, ReferencesStore, storeConnector
