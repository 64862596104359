var CadthGuidelineDocShape, docShape, initial, string;

docShape = require('lib/db_docs/field_types/doc_shape');

initial = require('lib/db_docs/field_types/type_decorators').initial;

string = require('lib/db_docs/field_types/built_in_types').string;

CadthGuidelineDocShape = docShape({
  countryOrRegion: initial('')(string),
  guidelineGroup: initial('')(string),
  publicationYear: initial('')(string),
  qualityOfGuideline: initial('')(string),
  shortTitle: initial('')(string),
  sourceLink: initial('')(string),
  sourceText: initial('')(string),
  title: initial('')(string)
});

module.exports = CadthGuidelineDocShape;
