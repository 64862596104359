ConfirmationModal = require 'components/common/confirmation_modal'
ConnectStore = require 'components/enhancers/connect_store'
{ MASTER_PROJECT_ID } = require 'lib/mda_helper'
MdaTopic = require 'components/dissemination/mda_topics_workflow/mda_topic'
MdaTopicsStore = require 'stores/mda_topics_store'
MdaWorkflowPublicationActions = require 'actions/mda_workflow_publication_actions'
MdaWorkflowPublicationStore = require 'stores/mda_workflow_publication_store'
MDAPublicationModal = require 'components/dissemination/mda_topics_workflow/mda_publication_modal'
{ MDA_TOPIC } = require 'lib/db_docs/doc_types'
mediator = require 'mediator'
QuestionsList = require 'components/evidence_syntheses/questions_list/questions_list'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
QuestionsStatusesStore = require 'stores/questions_statuses_store'
Scrollable = require 'components/common/scrollable'
SearchBar = require 'components/common/search_bar'
Select = require 'components/common/select_custom'
Spinner = require 'components/common/spinner'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

QUESTION_STATUSES = ['all', 'published', 'pending', 'unpublished']
ALL_CELLS =  ['no', 'checkbox', 'question', 'status', 'publicationDate', 'workflow']
MASTER_CELLS = ['no', 'checkbox', 'publicationDate', 'workflow']

storeConnector =
  MdaWorkflowPublicationStore: (Store) ->
    isConfirmationDialogOpen: Store.isConfirmationDialogOpen()
    isFetchingStatuses: Store.isFetching()
    isPushingToMaster: Store.isPushingToMaster()
    pendingTopics: Store.getPendingTopics()
    searchText: Store.getSearchText()
    selectedTopics: Store.getSelectedTopics()
    statusFilter: Store.getStatusFilter()
    topicPublicationDates: Store.getTopicPublicationDates()
  QuestionsStore: (Store) ->
    isFetchingQuestions: Store.isFetching()
    questions: Store.getQuestions MDA_TOPIC
  QuestionGroupsStore: (Store) ->
    isFetchingQuestionGroups: Store.isFetching()
    questionGroups: Store.getQuestionGroups MDA_TOPIC

MdaPublicationTable = ({
  isConfirmationDialogOpen
  isFetchingQuestions
  isFetchingQuestionGroups
  isFetchingStatuses
  isPushingToMaster
  pendingTopics
  questions
  questionGroups
  searchText
  selectedTopics
  statusFilter
  topicPublicationDates
}) ->
  inMaster = mediator.project?.id is MASTER_PROJECT_ID
  allQuestionStatuses = _.filter QUESTION_STATUSES, (value) -> value isnt 'pending' or inMaster
  headerClassName = classNames 'publication-platform-questions-table__header',
    'not-in-master': not inMaster
    'in-master': inMaster
  bodyClassName = classNames 'publication-platform-questions-table__body',
    'in-master': inMaster

  i18n = useI18n 'dbep:questions_list_screen'

  changeSearchText = useCoffeeCallback [], (searchText) ->
    MdaWorkflowPublicationActions.setSearchText searchText

  resetSearchText = useCoffeeCallback [], ->
    MdaWorkflowPublicationActions.setSearchText ''

  statusFilterOptions = useCoffeeMemo [allQuestionStatuses, i18n], ->
    _.map allQuestionStatuses, (status) ->
      text: i18n "statuses.#{status}"
      value: status

  filteredQuestions = useCoffeeMemo [
    pendingTopics, questions, searchText, statusFilter, topicPublicationDates
  ], ->
    questions
      .filter (question) ->
        question.get('name')?.toLowerCase()?.indexOf(searchText.toLowerCase()) >= 0
      .filter (question) ->
        isTopicPending = pendingTopics.includes question.get '_id'
        status = if topicPublicationDates.has(question.get '_id') and not isTopicPending
          'published'
        else if isTopicPending
          'pending'
        else
          'unpublished'
        statusFilter in ['all', status]
      .sortBy (question) -> question.get 'name'

  pushToMaster = useCoffeeCallback [questions], ->
    MdaWorkflowPublicationActions.pushToMaster mediator.project.id, mediator.project.get('name'),
      questions.valueSeq().toJS()

  isSelectAllChecked = useCoffeeMemo [filteredQuestions, selectedTopics], ->
    filteredQuestions.every (question) ->
      selectedTopics.includes question.get '_id'

  toggleAll = useCoffeeCallback [filteredQuestions, isSelectAllChecked, selectedTopics], ->
    filteredQuestionsIds = filteredQuestions
      .valueSeq()
      .map (question) -> question.get '_id'
      .toArray()
    topicsToToggle = if isSelectAllChecked
      filteredQuestionsIds
    else
      _.reject filteredQuestionsIds, (topicId) -> selectedTopics.includes topicId
    MdaWorkflowPublicationActions.toggleTopics topicsToToggle

  return <Spinner /> if isFetchingQuestions or isFetchingQuestionGroups or isFetchingStatuses

  <div className="publication-platform-questions-table">
    <div className="publication-platform-questions-table__search-bar">
      <div className="search-bar__search">
        <SearchBar
          searchOnChange
          containerClass='questions-search'
          onSearch={changeSearchText}
          onSearchReset={resetSearchText}
          searchText={searchText}
        />
      </div>
      <div className="search-bar__controls">
        <div className="status-filter__text">{i18n 'status'}</div>
        <Select
          className="status-filter__select"
          options={statusFilterOptions}
          selected={statusFilter}
          onChange={MdaWorkflowPublicationActions.changeStatusFilter}
        />
      </div>
    </div>
    <div className="publication-platform-questions-table__table">
      <div className={headerClassName}>
        <div className="publication-platform-questions-table__row">
          {_.map ALL_CELLS, (cell) ->
            return null if cell in MASTER_CELLS and not inMaster
            <div className={"publication-platform-questions-table__cell #{cell}"} key={cell}>
              <div className="cell-content">
                {if cell is 'checkbox'
                  <input
                    checked={isSelectAllChecked}
                    onChange={toggleAll}
                    type="checkbox"
                  />
                else
                  i18n "cells.#{if cell is 'question' then 'topic' else cell}"
                }
              </div>
            </div>
          }
        </div>
      </div>
      <div className={bodyClassName}>
        {if filteredQuestions.isEmpty()
          status = i18n "statuses.#{statusFilter}"
          text = if searchText is '' and statusFilter is 'all'
            i18n '/messages.no_topics_in_project'
          else if searchText isnt '' and statusFilter isnt 'all'
            i18n '../mda_publication_screen.search.no_topics_matching_both_criteria',
              { status, searchText }
          else if searchText isnt ''
            i18n '../mda_publication_screen.search.no_topics_matching_search_text', { searchText }
          else
            i18n '../mda_publication_screen.search.no_topics_matching_status', { status }
          <p>{text}</p>
        else
          <Scrollable>
            <QuestionsList
              isFetchingData={false}
              questions={filteredQuestions}
              questionGroups={questionGroups}
              withEmptyQuestionGroups={false}
            >
              <MdaTopic
                pendingTopics={pendingTopics}
                selectedTopics={selectedTopics}
                topicPublicationDates={topicPublicationDates}
              />
            </QuestionsList>
          </Scrollable>
        }
      </div>
    </div>
    <ConfirmationModal
      confirmLabel={i18n '/actions.publish'}
      onCancel={MdaWorkflowPublicationActions.toggleConfirmationDialogOpen}
      onConfirm={pushToMaster}
      isOpen={isConfirmationDialogOpen}
      loading={isPushingToMaster}
      message={i18n '../mda_publication_screen.confirmation.message'}
      question={i18n '../mda_publication_screen.confirmation.question'}
    />
    <MDAPublicationModal />
  </div>

MdaPublicationTable.propTypes =
  isConfirmationDialogOpen: PropTypes.bool.isRequired
  isFetchingQuestions: PropTypes.bool.isRequired
  isFetchingStatuses: PropTypes.bool.isRequired
  isPushingToMaster: PropTypes.bool.isRequired
  pendingTopics: PropTypes.instanceOf(Immutable.List).isRequired
  questions: PropTypes.instanceOf(Immutable.Map).isRequired
  questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
  searchText: PropTypes.string.isRequired
  selectedTopics: PropTypes.instanceOf(Immutable.List).isRequired
  statusFilter: PropTypes.oneOf(QUESTION_STATUSES).isRequired
  topicPublicationDates: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore MdaPublicationTable, [
  MdaWorkflowPublicationStore
  QuestionsStore
  QuestionGroupsStore
], storeConnector
