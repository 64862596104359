var Controller, OrganizationsController, OrganizationsView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OrganizationsView = require('views/organizations_view');

Controller = require('controllers/base/controller');

mediator = require('mediator');

module.exports = OrganizationsController = (function(_super) {
  __extends(OrganizationsController, _super);

  function OrganizationsController() {
    return OrganizationsController.__super__.constructor.apply(this, arguments);
  }

  OrganizationsController.prototype.historyURL = '';

  OrganizationsController.prototype.index = function(params) {
    mediator.activeWorkspace = null;
    return this.view = new OrganizationsView;
  };

  return OrganizationsController;

})(Controller);
