_.namespace(module, function(require) {
  var CadthGuidelineDoc, QUESTIONS_DB_VIEW, W, exportQuestionAndRecommendation, mediator, sequence;
  CadthGuidelineDoc = require('lib/db_docs/cadth_guideline_doc');
  exportQuestionAndRecommendation = require('lib/services/cadth_exporter/question');
  mediator = require('mediator');
  QUESTIONS_DB_VIEW = require('lib/questions_helper').QUESTIONS_DB_VIEW;
  sequence = require('when/sequence');
  W = require('when/when');
  return {
    _getQuestionsAndRecommendations: function(projectId) {
      var adapter;
      adapter = mediator.services.storePersistenceAdapter;
      return adapter.fetchWithView(projectId, 'questions', QUESTIONS_DB_VIEW).then(function(questions) {
        return _.filter(questions, function(q) {
          return q.type === 'keyMessage';
        });
      }).then(function(questions) {
        var recommendationIds;
        recommendationIds = _.chain(questions).pluck('recommendationIds').compact().flatten().value();
        if (_.isEmpty(recommendationIds)) {
          return {
            questions: questions,
            recommendations: {}
          };
        }
        return adapter.fetch(projectId, recommendationIds).then(function(_arg) {
          var recommendations, rows;
          rows = _arg.rows;
          recommendations = _.chain(rows).pluck('doc').compact().map(function(doc) {
            return [doc['_id'], doc];
          }).object().value();
          return {
            questions: questions,
            recommendations: recommendations
          };
        });
      });
    },
    _getGuidelines: function(projectId) {
      return CadthGuidelineDoc.at(projectId).fetchMany().then(function(_arg) {
        var rows;
        rows = _arg.rows;
        return _.chain(rows).pluck('doc').map(function(doc) {
          return [doc._id, doc];
        }).object().value();
      });
    },
    "export": function(projectId) {
      var promises;
      promises = [this._getQuestionsAndRecommendations(projectId), this._getGuidelines(projectId)];
      return W.all(promises).then(function(_arg) {
        var guidelines, processQuestion, questions, recommendations, _ref;
        (_ref = _arg[0], questions = _ref.questions, recommendations = _ref.recommendations), guidelines = _arg[1];
        processQuestion = function(question) {
          return function() {
            return exportQuestionAndRecommendation(question, recommendations, guidelines);
          };
        };
        return sequence(_.map(questions, processQuestion)).then(function(questions) {
          return _.compact(questions);
        });
      });
    }
  };
});
