var COI_PROJECT_ID, CoiImportView, CoiMatrixView, CoiMembersView, CoiSendNotificationsModalView, CoiView, TabsView, View, W, coiService, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CoiImportView = require('views/team/coi_import_view');

CoiMatrixView = require('views/team/coi_matrix_view');

CoiMembersView = require('views/team/coi_members_view');

COI_PROJECT_ID = require('lib/mdg_helper').COI_PROJECT_ID;

CoiSendNotificationsModalView = require('./coi_send_notifications_modal');

coiService = require('lib/services/coi_service');

mediator = require('mediator');

TabsView = require('views/tabs_view');

template = require('views/templates/team/coi');

View = require('base/views/view');

W = require('when');

module.exports = CoiView = (function(_super) {
  __extends(CoiView, _super);

  function CoiView() {
    return CoiView.__super__.constructor.apply(this, arguments);
  }

  CoiView.prototype.container = '#page-container';

  CoiView.prototype.className = 'coi-view';

  CoiView.prototype.template = template;

  CoiView.prototype.autoRender = true;

  CoiView.prototype.bindings = {
    'input[name=formType]': {
      observe: 'coiFormType',
      onGet: function(val) {
        if (val === 'imcje') {
          return 'icmje';
        } else {
          return val;
        }
      }
    }
  };

  CoiView.prototype._filteredCoiMembersViewTabOptions = function(title, filter) {
    return {
      view: CoiMembersView,
      title: title,
      options: {
        collection: this.members,
        filterer: function(member) {
          return member.hasCoiFormStatus.apply(member, filter);
        }
      }
    };
  };

  CoiView.prototype.initialize = function() {
    var isElesevierFeaturesOn, isMdgFeaturesOn, tabsView;
    CoiView.__super__.initialize.apply(this, arguments);
    this.project = mediator.project;
    this.members = mediator.colls.teamMembers.getPanelMembersColl();
    this.delegate('click', 'a.open-send-notifications-modal', this.openSendNotificationsModal);
    this.delegate('click', 'a.send-reminder-mail', this.sendReminderNotifications);
    this.delegate('click', 'button.refresh-statuses', this.refreshCoiStatuses);
    this.delegate('click', 'a.import-coi', this.importCoi);
    isElesevierFeaturesOn = mediator.services.switches.isServerSwitchOn('elsevierFeatures');
    isMdgFeaturesOn = mediator.services.switches.isServerSwitchOn('mdgFeatures');
    if (isMdgFeaturesOn) {
      this.project.set('coiFormType', 'mdg');
    } else if (isElesevierFeaturesOn) {
      this.project.set('coiFormType', 'mgc');
    }
    tabsView = new TabsView({
      container: '.tabs-container',
      tabViews: [
        {
          view: CoiMembersView,
          title: $.t('team:coi.all_members_tab'),
          options: {
            collection: this.members
          }
        }, this._filteredCoiMembersViewTabOptions($.t('team:coi.unnotified_members_tab'), ['not-sent']), this._filteredCoiMembersViewTabOptions($.t('team:coi.notified_members_tab'), ['new', 'mail-sent', 'in-progress']), this._filteredCoiMembersViewTabOptions($.t('team:coi.completed_members_tab'), ['completed']), {
          view: CoiMatrixView,
          title: $.t('team:coi.matrix_tab'),
          options: {
            collection: mediator.questions
          }
        }
      ]
    });
    return this.subview('tabs', tabsView);
  };

  CoiView.prototype._isSendingDisabled = function() {
    var isMdgFeaturesOn;
    isMdgFeaturesOn = mediator.services.switches.isServerSwitchOn('mdgFeatures');
    return isMdgFeaturesOn && mediator.project.id !== COI_PROJECT_ID;
  };

  CoiView.prototype.sendNewNotifications = function() {
    this._disableCoiFormTypeRadioButtons();
    return coiService.sendNewNotifications().then((function(_this) {
      return function() {
        return _this.subview('tabs').render();
      };
    })(this));
  };

  CoiView.prototype.sendNotificationsToAll = function() {
    return coiService.resendNotifications().then(function() {
      return coiService.sendNewNotifications();
    }).then((function(_this) {
      return function() {
        return _this.subview('tabs').render();
      };
    })(this));
  };

  CoiView.prototype.sendReminderNotifications = function() {
    if (this._isSendingDisabled()) {
      return;
    }
    return mediator.dialogs.confirm($.t('messages.confirm'), (function(_this) {
      return function(confirmed) {
        if (confirmed) {
          return coiService.sendReminderNotifications().then(function() {
            return _this.subview('tabs').render();
          });
        }
      };
    })(this));
  };

  CoiView.prototype.getTemplateData = function() {
    var defaultFormTypes, isElsevierFeaturesOn, isMdgFeaturesOn, isMinsalFeaturesOn;
    defaultFormTypes = ['icmje', 'who', 'nhf', 'mspsc', 'nhi-it'];
    isElsevierFeaturesOn = mediator.services.switches.isServerSwitchOn('elsevierFeatures');
    isMinsalFeaturesOn = mediator.services.switches.isServerSwitchOn('minsalFeatures');
    isMdgFeaturesOn = mediator.services.switches.isServerSwitchOn('mdgFeatures');
    return {
      coiFormCreated: this.project.get('coiFormCreated'),
      coiFormTypes: isElsevierFeaturesOn ? ['mgc'] : isMdgFeaturesOn ? ['mdg'] : isMinsalFeaturesOn ? defaultFormTypes.concat('minsal') : defaultFormTypes,
      members: this.members,
      sendDisabled: this._isSendingDisabled()
    };
  };

  CoiView.prototype.afterRender = function() {
    CoiView.__super__.afterRender.apply(this, arguments);
    this.stickit(this.project, this.bindings);
    return this.subview('tabs').render();
  };

  CoiView.prototype.openSendNotificationsModal = function() {
    if (this._isSendingDisabled()) {
      return;
    }
    return this.subview('sendNotificationsModal', new CoiSendNotificationsModalView({
      parentView: this
    }));
  };

  CoiView.prototype.dispose = function() {
    this.unstickit(this.project);
    return CoiView.__super__.dispose.apply(this, arguments);
  };

  CoiView.prototype._disableCoiFormTypeRadioButtons = function() {
    return this.$('input[name="formType"]').prop('disabled', true);
  };

  CoiView.prototype.refreshCoiStatuses = function() {
    var member;
    return W.all(_.union([mediator.questions.fetch()], (function() {
      var _i, _len, _ref, _results;
      _ref = this.members.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        member = _ref[_i];
        _results.push(member.checkCoiFormStatus());
      }
      return _results;
    }).call(this))).then((function(_this) {
      return function() {
        return _this.subview('tabs').render();
      };
    })(this));
  };

  CoiView.prototype.importCoi = function() {
    return this.subview('importCoi', new CoiImportView({
      parentView: this
    }));
  };

  return CoiView;

})(View);
