var Migration, TeamMembersSeparateNames, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

Migration = require('./migration');

W = require('when');

module.exports = TeamMembersSeparateNames = (function(_super) {
  __extends(TeamMembersSeparateNames, _super);

  function TeamMembersSeparateNames() {
    TeamMembersSeparateNames.__super__.constructor.call(this, false);
  }

  TeamMembersSeparateNames.prototype.up = function(project, colls) {
    var member, separateName;
    TeamMembersSeparateNames.__super__.up.apply(this, arguments);
    separateName = function(member) {
      var given, last, name, words, _i;
      name = _.string.trim(member.get('name'));
      words = _.string.words(name);
      switch (words.length) {
        case 1:
          member.set('lastName', name);
          break;
        default:
          given = 2 <= words.length ? __slice.call(words, 0, _i = words.length - 1) : (_i = 0, []), last = words[_i++];
          member.set('lastName', last);
          member.set('givenNames', given.join(' '));
      }
      return member.save();
    };
    return W.all((function() {
      var _i, _len, _ref, _results;
      _ref = colls.teamMembers.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        member = _ref[_i];
        _results.push(separateName(member));
      }
      return _results;
    })()).then(function() {
      return {
        message: $.t('team:member.member_name_migrated')
      };
    });
  };

  return TeamMembersSeparateNames;

})(Migration);
