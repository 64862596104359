EtdActions = require 'actions/etd_actions'
EtdEditor = require 'components/etd/etd_editor'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ instanceOf, string, bool } = PropTypes

{ CellWithTitle, Card, CardContent } = ReactComponents

ResearchEvidence = createReactClass
  displayName: 'ResearchEvidenceComponent'

  propTypes:
    attachments: instanceOf(Immutable.Map)
    cellId: string
    editable: bool,
    highlighted: bool,
    isConsensus: bool
    placeholder: string,
    renderMode: string,
    researchEvidence: instanceOf(Immutable.Map).isRequired,

  mixins: [CustomRenderMixin, Translation('es:recommendations.table')]

  getDefaultProps: ->
    placeholder: null

  onEditClick: ->
    return if @props.readOnly
    if not @props.editable and @props.onEditClick
      @props.onEditClick()

  _getContent: ->
    if @props.isConsensus
      @props.researchEvidence.get('contentConsensus') or @props.researchEvidence.get('content')
    else
      @props.researchEvidence.get('content')

  render: ->

    { cellId, colSpan, editable, highlighted, renderMode, readOnly, rowSpan } = @props

    cellClass = classNames 'research-evidences', 'highlighted': highlighted
    content = if renderMode is 'printout'
      @_getContent() ? @i18n 'no_research_evidence_identified'
    else
      @_getContent()

    editable = if (renderMode is 'printout' or readOnly) then false else editable

    cardClassess = classNames 'ep-card-with-etd-editor', 'readOnly': readOnly

    <CellWithTitle
      className={cellClass}
      cellTitle={@i18n 'research_evidence' unless renderMode is 'printout'}
      rowSpan={rowSpan}
      colSpan={colSpan}
    >
      <div onClick={@onEditClick}>
        <Card className={cardClassess}>
          <CardContent>
            <EtdEditor
              renderMode={renderMode}
              forceTableExpand={readOnly or renderMode is 'printout'}
              editable={editable}
              cellId={cellId}
              attachments={@props.attachments}
              content={content}
              isConsensus={@props.isConsensus}
              placeholder={@props.placeholder}
              onSaveContent={EtdActions.updateEditorContent}
              onSaveAttachment={EtdActions.saveAttachment}
            />
          </CardContent>
        </Card>
      </div>
    </CellWithTitle>

module.exports = ResearchEvidence
