_.namespace(module, function(require) {
  var ACPVisualGuidelinesDoc, DbHelper, MANUAL_REC_PREFIX, VisualGuidelinesRootDoc, Visualization, VisualizationUtils, W, alt, convertRowsToMap, createPrintoutContainer, errorHandlerWithMsg, exportToFile, getAllGdtDataForOutcome, getChapterAndSectionIds, getElementAppliedStylesString, mediator, merge, removeHTMLNode, _ref, _ref1;
  ACPVisualGuidelinesDoc = require('lib/db_docs/acp_visual_guidelines_doc');
  _ref = require('lib/app_utils'), errorHandlerWithMsg = _ref.errorHandlerWithMsg, convertRowsToMap = _ref.convertRowsToMap;
  alt = require('alt');
  DbHelper = require('base/lib/db_helper');
  mediator = require('mediator');
  merge = require('lodash').merge;
  MANUAL_REC_PREFIX = require('lib/etd_helper').MANUAL_REC_PREFIX;
  Visualization = VIComponents.Visualization;
  VisualizationUtils = require('lib/visualization_utils');
  VisualGuidelinesRootDoc = require('lib/db_docs/visual_guidelines_root_doc');
  W = require('when');
  exportToFile = require('lib/export_to_file').exportToFile;
  getChapterAndSectionIds = require('lib/mda_helper').getChapterAndSectionIds;
  getAllGdtDataForOutcome = require('lib/nma_helper').getAllGdtDataForOutcome;
  _ref1 = require('base/lib/utils'), removeHTMLNode = _ref1.removeHTMLNode, createPrintoutContainer = _ref1.createPrintoutContainer, getElementAppliedStylesString = _ref1.getElementAppliedStylesString;
  return {
    gatherData: function(itemId) {
      var adapter, exportService, exportedVIId, projectId, tableData, visualizationRootId;
      adapter = mediator.services.storePersistenceAdapter;
      exportService = mediator.services.documentSectionsExportService;
      projectId = mediator.project.id;
      visualizationRootId = alt.stores.ACPVisualGuidelinesStore.getCurrentVisualizationRootId();
      tableData = alt.stores.ACPVisualGuidelinesStore.getInteractiveTableData();
      exportedVIId = "" + projectId + "-" + itemId;
      return W.all([ACPVisualGuidelinesDoc.at(projectId, itemId).fetch(), VisualGuidelinesRootDoc.at(projectId, visualizationRootId).fetch()]).then(function(_arg) {
        var mainDoc, rootDoc;
        mainDoc = _arg[0], rootDoc = _arg[1];
        return getChapterAndSectionIds(projectId, rootDoc).then(function(chaptersAndSectionIds) {
          return W.all([adapter.fetch(mediator.project.id, chaptersAndSectionIds.chapterIds), adapter.fetch(mediator.project.id, chaptersAndSectionIds.sectionIds)]);
        }).then(function(_arg1) {
          var chapterDocs, chapters, chaptersToSlugMap, sectionDocs, sections;
          chapters = _arg1[0], sections = _arg1[1];
          sectionDocs = convertRowsToMap(sections.rows || []);
          chapterDocs = convertRowsToMap(chapters.rows || []);
          chaptersToSlugMap = exportService.convertChaptersToSlugMap(chapterDocs.toJS());
          return exportService.processChaptersAndSections(chapterDocs.toJS(), sectionDocs.toJS(), rootDoc.chaptersOrder, {
            exportDate: new Date().toISOString(),
            guidelineId: exportedVIId,
            chaptersToSlugMap: chaptersToSlugMap
          }).then(function(_arg2) {
            var fullReferencesData, processedChapters, processedSections, processedSectionsObjs, referencesIds, _ref2;
            processedChapters = _arg2[0], processedSectionsObjs = _arg2[1];
            _ref2 = exportService.processSectionObjs(processedSectionsObjs), processedSections = _ref2.processedSections, referencesIds = _ref2.referencesIds, fullReferencesData = _ref2.fullReferencesData;
            return {
              processedSections: merge(_.indexBy(processedSections, 'guid'), sectionDocs.toJS()),
              referencesIds: referencesIds,
              fullReferencesData: fullReferencesData,
              processedChapters: _.indexBy(processedChapters, 'guid')
            };
          });
        }).then(function(_arg1) {
          var allReferencesIds, fullReferencesData, populationSection, processedChapters, processedSections, recommendationIds, recommendations, references, referencesIds;
          processedChapters = _arg1.processedChapters, processedSections = _arg1.processedSections, referencesIds = _arg1.referencesIds, fullReferencesData = _arg1.fullReferencesData;
          populationSection = _.find(processedSections, function(section) {
            return section.sectionType === 'acp_recommendations_populations';
          });
          recommendationIds = _.chain(populationSection.additionalData.populations).pluck('recommendations').flatten().compact().filter(function(recId) {
            return recId.indexOf(MANUAL_REC_PREFIX) === -1;
          }).map(function(recId) {
            return recId.split('_')[0];
          }).uniq().value();
          allReferencesIds = _.uniq(referencesIds);
          references = exportService.processReferences(allReferencesIds, fullReferencesData);
          recommendations = alt.stores.EtdsStore.getRecommendations(recommendationIds);
          return VisualizationUtils.getVisualizationData(tableData, Immutable.fromJS(populationSection), recommendations).then(function(_arg2) {
            var analysis, analysisGroups, outcomes, recommendations, viDoc;
            analysisGroups = _arg2.analysisGroups, recommendations = _arg2.recommendations, outcomes = _arg2.outcomes;
            analysis = analysisGroups.map(function(analysisGroupId) {
              var nma;
              nma = alt.stores.NMAListStore.getQuestion(analysisGroupId);
              return {
                _id: nma.get('_id'),
                title: nma.get('question'),
                interventions: nma.get('interventions', Immutable.List()).toJS(),
                outcomes: _.compact(nma.get('outcomes').map(function(outcomeId) {
                  var tmpOutcome;
                  tmpOutcome = outcomes[outcomeId];
                  if (!tmpOutcome) {
                    return;
                  }
                  tmpOutcome.data = getAllGdtDataForOutcome(tmpOutcome);
                  return tmpOutcome;
                }).toJS())
              };
            });
            viDoc = {
              visualization: mainDoc,
              visualizationRootDoc: rootDoc,
              chapters: processedChapters,
              sections: processedSections,
              references: references.toJS(),
              recommendations: recommendations,
              analysis: analysis
            };
            return {
              exportedVIId: exportedVIId,
              viDoc: viDoc
            };
          });
        });
      });
    },
    exportVi: function(itemId) {
      var adapter;
      adapter = mediator.services.storePersistenceAdapter;
      return this.gatherData(itemId).then(function(_arg) {
        var exportedVIId, viDoc;
        exportedVIId = _arg.exportedVIId, viDoc = _arg.viDoc;
        return adapter.updateOrCreate(DbHelper.getVIGuidelinesDbUrl(), exportedVIId, function(doc) {
          return _.extend({}, doc, {
            currentVersion: (doc.currentVersion || 0) + 1,
            _id: exportedVIId
          });
        }).then(function(res) {
          var newId;
          newId = "" + exportedVIId + "-" + res.currentVersion;
          return adapter.updateOrCreate(DbHelper.getVIGuidelinesDbUrl(), newId, function(doc) {
            return _.extend({}, doc, viDoc, {
              _id: newId
            });
          });
        });
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('vi:export_error'));
      });
    },
    getEmbeddableHTML: function(viUrl) {
      var attrsString;
      attrsString = 'frameborder=\"0\" width=\"100%\" height=\"100%\" allowfullscreen';
      return "<iframe src=\"" + viUrl + "\" " + attrsString + "> </iframe>";
    },
    exportVIGuidelines: function(itemId) {
      return this.exportVi(itemId).then((function(_this) {
        return function(res) {
          var newTab, viDocId, _ref2;
          newTab = window.open('about:blank', '_blank');
          viDocId = (_ref2 = res.id) != null ? _ref2 : res._id;
          return newTab.location.href = DbHelper.getVIGuidelinesUrl(viDocId);
        };
      })(this));
    },
    exportVIGuidelinesToPdf: function(itemId) {
      return this.gatherData(itemId).then(function(_arg) {
        var $charsetEl, $copyrightEl, $metaEl, $styleEl, $visualization, VisualizationPreview, exportedVIId, fakeDoc, tempContainer, viDoc, visualizationDocHTML;
        exportedVIId = _arg.exportedVIId, viDoc = _arg.viDoc;
        tempContainer = createPrintoutContainer();
        VisualizationPreview = React.createElement(Visualization, {
          i18n: i18next,
          data: viDoc,
          config: {
            printout: true
          }
        });
        ReactDOM.render(VisualizationPreview, tempContainer);
        $visualization = tempContainer.querySelector('#visual-guidelines-container');
        $visualization.querySelector('.interactive-visualization').remove();
        $visualization.querySelector('.project-info-banner').remove();
        $copyrightEl = document.createElement('div');
        $copyrightEl.innerHTML = '&copy; 2024 American College of Physicians';
        $copyrightEl.style.textAlign = 'right';
        $visualization.appendChild($copyrightEl);
        fakeDoc = document.implementation.createHTMLDocument('');
        $metaEl = document.createElement('meta');
        $metaEl.setAttribute('name', 'viewport');
        $metaEl.setAttribute('content', 'width=device-width, initial-scale=1');
        $charsetEl = document.createElement('meta');
        $charsetEl.setAttribute('charset', 'utf-8');
        $styleEl = document.createElement('style');
        $styleEl.innerHTML = getElementAppliedStylesString($visualization);
        fakeDoc.head.appendChild($metaEl);
        fakeDoc.head.appendChild($styleEl);
        fakeDoc.head.appendChild($charsetEl);
        fakeDoc.body.innerHTML = $visualization.outerHTML;
        visualizationDocHTML = '<!doctype html>' + fakeDoc.documentElement.outerHTML;
        fakeDoc = null;
        removeHTMLNode(tempContainer);
        return exportToFile('pdf', visualizationDocHTML, {
          fileName: "" + (mediator.project.get('name').slice(0, 255)),
          orientation: 'portrait',
          margins: {
            top: 2.7,
            right: 2.7,
            bottom: 2.7,
            left: 2.7
          }
        });
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('vi:export_error'));
      });
    }
  };
});
