var OrderedCollection, PrognosticQuestion, PrognosticQuestions,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OrderedCollection = require('models/base/ordered_collection');

PrognosticQuestion = require('models/prognostic_question');

module.exports = PrognosticQuestions = (function(_super) {
  __extends(PrognosticQuestions, _super);

  function PrognosticQuestions() {
    return PrognosticQuestions.__super__.constructor.apply(this, arguments);
  }

  PrognosticQuestions.prototype.model = PrognosticQuestion;

  PrognosticQuestions.prototype.initialize = function() {
    PrognosticQuestions.__super__.initialize.apply(this, arguments);
    return _.extend(this, new Backbone.Memento(this));
  };

  PrognosticQuestions.prototype.pouch = {
    fetch: 'prognosticQuestions',
    views: {
      prognosticQuestions: {
        map: function(doc) {
          if (doc.docType === 'prognosticQuestion') {
            return emit(doc.docType, doc);
          }
        }
      }
    },
    filters: {
      prognosticQuestions: function(doc) {
        return doc._deleted || doc.docType === 'prognosticQuestion';
      }
    }
  };

  return PrognosticQuestions;

})(OrderedCollection);
