EtdActions = require 'actions/etd_actions'
PanelVoiceEtdActions = require 'actions/panel_voice_etd_actions'
Modal = require 'components/common/modal'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
OptionsTypeSection = require 'components/etd/conclusions_options_type_selection'
ToggleSwitch = require 'components/common/toggle_switch'
Translation = require 'components/mixins/translation'
PanelVoiceEtdSectionEditModal = require 'components/mixins/panel_voice_etd_section_edit_modal_mixin'
{ func, instanceOf, string, bool } = PropTypes

RecommendationEditModal = createReactClass
  displayName: 'RecommendationEditModal'

  propType:
    highlightToPanelMembers: bool
    sectionId: string.isRequired
    renderMode: string.isRequired
    section: instanceOf(Immutable.Map)
    etdId: string.isRequired #required in mixin

  mixins: [
    Translation('es:recommendations.table')
    PanelVoiceEtdSectionEditModal
  ]

  onClose: ->
    PanelVoiceEtdActions.toggleEtdSectionEdit @props.sectionId

  saveChanges: ->
    if @SectionComponent
      @SectionComponent.saveContent()
    else
      EtdActions.save()
    @onClose()

  render: ->
    conclusionsContainerClass = classNames 'conclusions-data',
      highlighted: @props.highlightToPanelMembers
    section = @props.section

    <Modal isOpen
      className='conclusions-section-edit-dialog'
      onClose={@onClose}
      title={@i18n 'editor'}
    >
      <div className='section-details'>
        <span>{section.get('description', '').toUpperCase()}: </span>
      </div>
      <div className='section-data-container'>
        <div id='controls-block'>
          <ToggleSwitch
            label={@i18n 'highlight_for_panel_members'}
            onChange={@toggleSectionHighlight}
            checked={@props.highlightToPanelMembers}
          />
        </div>
        <div className={conclusionsContainerClass}>
          {@props.children}
        </div>
      </div>
      <ApplyCancelButtons
        onApply={@saveChanges}
        onCancel={@onClose}
        applyLabel={@i18n '/translation:actions.save'}
        applyClass='btn-send'
      />
    </Modal>

module.exports = RecommendationEditModal
