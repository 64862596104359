var PARAMS_VALIDATORS, REQUIRED_PARAMS, W, ajax, getApiUrl, mediator, validateArgs, wrongArgsErr, _validatorFn;

W = require('when');

mediator = require('mediator');

getApiUrl = require('base/lib/db_helper').getBackendUrl;

PARAMS_VALIDATORS = {
  projectId: function(id) {
    return _.isString(id) && !_.isEmpty(id);
  },
  memberIds: function(ids) {
    return _.isArray(ids) && !_.isEmpty(ids);
  },
  questionGroups: function(groups) {
    return _.isArray(groups) && !_.isEmpty(groups);
  },
  step: function(step) {
    return _.isString(step) && !_.isEmpty(step);
  },
  emailSubject: function(subject) {
    return _.isString(subject) && !_.isEmpty(subject);
  },
  emailBody: function(body) {
    return _.isString(body) && !_.isEmpty(body) && _.string.include(body, '{#link}');
  }
};

REQUIRED_PARAMS = {
  start: ['projectId', 'memberIds', 'questionGroups', 'step', 'emailSubject', 'emailBody'],
  getResults: ['projectId'],
  closeStep: ['projectId']
};

wrongArgsErr = function() {
  return W.reject(new Error({
    status: 400,
    responseText: {
      message: 'Invalid arguments supplied'
    }
  }));
};

_validatorFn = function(params) {
  return function(validationResult, paramName) {
    if (!PARAMS_VALIDATORS[paramName](params[paramName])) {
      validationResult.valid = false;
      validationResult.invalidArgs[paramName] = params[paramName];
    }
    return validationResult;
  };
};

validateArgs = function(fnName, params) {
  var valResult;
  if (_.isEmpty(params)) {
    return {
      valid: false
    };
  }
  valResult = REQUIRED_PARAMS[fnName].reduce(_validatorFn(params), {
    valid: true,
    invalidArgs: {}
  });
  if (!_.isEmpty(valResult.invalidArgs)) {
    console.warn(valResult.invalidArgs);
  }
  return valResult.valid;
};

ajax = function(params) {
  return W.promise(function(resolve, reject) {
    if (_.isObject(params)) {
      return $.ajax(_(params).extend({
        contentType: 'application/json',
        error: reject,
        success: resolve
      }));
    } else {
      return reject('params argument must be an object');
    }
  });
};

module.exports = {
  fetchOutcomesGeneration: function(projectId, docId) {
    return mediator.services.storePersistenceAdapter.fetch(projectId, docId)["catch"](function(err) {
      if (err.status === 404) {
        return null;
      } else {
        throw err;
      }
    });
  },
  start: function(params) {
    if (!validateArgs('start', params)) {
      return wrongArgsErr();
    }
    return ajax({
      url: "" + (getApiUrl()) + "/forms/outcomes-gen",
      type: 'POST',
      data: JSON.stringify(params)
    });
  },
  getResults: function(params) {
    var projectId;
    if (!validateArgs('getResults', params)) {
      return wrongArgsErr();
    }
    projectId = params.projectId;
    return ajax({
      url: "" + (getApiUrl()) + "/forms/outcomes-gen/" + projectId,
      type: 'GET'
    });
  },
  closeStep: function(params) {
    var projectId;
    if (!validateArgs('closeStep', params)) {
      return wrongArgsErr();
    }
    projectId = params.projectId;
    return ajax({
      url: "" + (getApiUrl()) + "/forms/outcomes-gen/" + projectId + "/current-step",
      type: 'PATCH',
      data: JSON.stringify({
        close: true
      })
    });
  }
};
