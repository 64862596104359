CustomRenderMixin = require 'components/mixins/custom_render_mixin'
mediator = require 'mediator'
MembersActions = require 'actions/members_actions'
Translation = require 'components/mixins/translation'
{instanceOf, bool, func} = PropTypes

PanelMemberCell = createReactClass
  displayName: 'PanelMemberCell'

  mixins: [Translation('team:member'), CustomRenderMixin]

  propTypes:
    el: (props, propName, componentName) ->
      if props[propName] not in ['td', 'th', 'div']
        new Error 'Invalid prop `' + propName + '` supplied to' +
          ' `' + componentName + '`. Validation failed.'
    expanded: bool
    withLabel: bool
    member: instanceOf(Immutable.Map).isRequired
    customUpdate: func

  getDefaultProps: ->
    expanded: false
    withLabel: true

  defaultUpdate: ->
    {member, expanded} = @props
    panelMember = not member.get('panelMember')
    if expanded
      MembersActions.updateMember member.get('_id'), 'panelMember', panelMember
    else
      MembersActions.save mediator.project.id, member.set 'panelMember', panelMember

  getInput: ->
    update = @props.customUpdate ? @defaultUpdate

    <div>
      <input
        type="checkbox"
        name="panel-member"
        checked={@props.member.get('panelMember')}
        onChange={update}
      />
      {if @props.withLabel
        <label onClick={update}>
          {@i18n 'panel_participation'}
        </label>
      }
    </div>

  render: ->
    React.createElement @props.el, className: @props.className, @getInput()

module.exports = PanelMemberCell
