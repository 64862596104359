Outcome = require 'components/scope/outcomes/outcome'
OutcomesGenerationActions = require 'actions/outcomes_generation_actions'
DeleteButton = require 'components/common/delete_button'
EditButton = require 'components/common/edit_button'
SaveButton = require 'components/common/save_button'
CopyButton = require 'components/common/copy_button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

EditableOutcome = createReactClass
  displayName: 'EditableOutcome'

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  _getName: ->
    @props.outcomeData.get 'name'

  onEdit: ->
    { gIndex, index: oIndex } = @props
    OutcomesGenerationActions.editOutcome { gIndex, oIndex }

  onSave: ->
    OutcomesGenerationActions.updateOutcome name: @refs.outcomeText.value

  onCopy: ->
    @props.onCopy @_getName()

  componentDidUpdate: (prevProps, prevState) ->
    @refs.outcomeText.focus() if @props.isEditing

  render: ->
    if @props.isEditing
      <div className={@props.className}>
        <input ref='outcomeText' defaultValue={@_getName()}/>
        <div className="buttons">
          <CopyButton
            title={@i18n 'outcome_duplicate'}
            onClick={@onCopy}
          />
          <DeleteButton
            title={@i18n 'outcome_delete'}
            onClick={OutcomesGenerationActions.deleteOutcome}
          />
          <SaveButton
            title={@i18n 'outcome_save'}
            onClick={@onSave}
          />
        </div>
        <div className="clearfix" />
      </div>
    else
      <Outcome className={@props.className} outcomeData={@props.outcomeData}>
        <EditButton
          title={@i18n 'edit_outcome'}
          onClick={@onEdit}
        />
        {@props.children}
        <div className="clearfix" />
      </Outcome>

module.exports = EditableOutcome
