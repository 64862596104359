Modal = require 'components/common/modal'
Tooltip = require 'components/common/tooltip'
EmbeddedHTMLEditor = require 'components/common/gdt_editor/embedded_html_editor'
Confirmation = require 'components/common/confirmation'
QualityIndicatorsTable = require 'components/common/gdt_editor/quality_indicators_table'
{
  CollapsedInsertedGdtTable,
  CollapseTableButton,
  DxEvidenceTable,
  EditorUtils,
  RelativeImportance,
  LayerOneTwoSof,
  OpenInNewWindowButton,
  SofShort,
  SofTable,
} = Editor

Translation = require 'components/mixins/translation'
{ getTableData } = require 'lib/embedded_content'
{ Entity } = Draft

gdtTableStyles =
  controls:
    position: 'fixed'
    height: '25px'
    border: '1px solid lightgrey'
    backgroundColor: '#dedede'
    top: '0'
  button:
    padding: '0 5px'

EditableTable = createReactClass
  applyEdit: (content) ->
    @props.onApply content

  render: ->
    { data, isEditing } = @props
    <div>
      {if isEditing
        <EmbeddedHTMLEditor html={data} onClose={@props.onClose} onApply={@applyEdit} />
      }
      <div
        className='embedded'
        dangerouslySetInnerHTML={__html: data}
       />
    </div>

InsertedGdtTableComponent = createReactClass
  displayName: 'InsertedGdtTableComponent'

  mixins: [
    Translation('')
  ]

  getInitialState: ->
    showConfirmation: false
    showControls: false

  _getEntityKey:  ->
    @props.block.getEntityAt(0)

  _resetTableData: ->
    entityKey = @_getEntityKey()
    { type, selectedOutcomes, qId, data, expanded } = Entity.get(entityKey).getData()
    tableData = getTableData type, Immutable.fromJS(selectedOutcomes), qId

    @props.blockProps.updateData entityKey, data: tableData, expanded: expanded

  _getTableProps: ->
    Entity.get(@_getEntityKey()).getData()

  showConfirmationFor: (confirmationAction) -> =>
    @setState showConfirmationFor: confirmationAction

  cancelConfirmation: ->
    @hideConfirmation()
    @props.blockProps.focusEditor()

  hideConfirmation: ->
    @setState showConfirmationFor: null

  onConfirm: (confirmedAction) -> =>
    switch confirmedAction
      when 'update' then @resetTableData()
      when 'edit' then @editTable()

  resetTableData: ->
    @hideConfirmation()
    @_resetTableData()

  editTable: ->
    entityKey = @_getEntityKey()
    { type, manualEdit, data } = @_getTableProps()

    html = @tableContent.innerHTML.replace(/\sdata-reactid="[^"]+"/g, '')
    data = _.extend {}, data, { html: html }

    if manualEdit
      @setState
        manuallyEditing: true
        showConfirmationFor: null
    else
      @props.blockProps.updateData entityKey,
        manualEdit: true
        data: data
      @setState
        manuallyEditing: true
        showConfirmationFor: null

  onEdit: ->
    { manualEdit } = @_getTableProps()
    return @setState manuallyEditing: true if manualEdit
    @showConfirmationFor('edit')()

  saveManualEdit: (newContent) ->
    entityKey = @_getEntityKey()
    { data } = @_getTableProps()

    data = _.extend {}, data, { html: newContent}
    @props.blockProps.updateData entityKey, data: data
    @cancelManualEdit()

  cancelManualEdit: ->
    @setState manuallyEditing: false
    @props.blockProps.focusEditor()

  onMouseOver: (evt) ->
    return if not @props.blockProps.isEditing
    @setState showControls: true
    @currentY = evt.pageY
    @animationId = requestAnimationFrame @_moveControls

  onMouseLeave: ->
    return unless @state.showControls
    cancelAnimationFrame @animationId
    @setState showControls: false
    @currentY = null

  onMouseMove: (evt) ->
    return unless @state.showControls
    @currentY = evt.pageY

  _moveControls: ->
    return if not @controls or @state.manuallyEditing
    width = @controls.clientWidth
    @controls.style.transform =
      "translateY(#{@currentY - 10}px) translateZ(0) translateX(-#{width}px)"
    @animationId = requestAnimationFrame @_moveControls

  toggleTable: (e) ->
    e.stopPropagation()
    entityKey = @_getEntityKey()
    { type, selectedOutcomes, qId, data, expanded, manualEdit } = @_getTableProps()

    if typeof data is "string"
      tmpData = getTableData type, Immutable.fromJS(selectedOutcomes), qId
      data = _.extend {}, tmpData, { html: data }

    @props.blockProps.updateData entityKey, data: data, expanded: not expanded, true

  openTableInNewWindow: (e) ->
    e.stopPropagation()
    { data } = @_getTableProps()
    EditorUtils.openTableInNewWindow data.title, ReactDOM.findDOMNode(@tableContent).innerHTML

  renderTable: ->
    { type, data, manualEdit } = @_getTableProps()

    if manualEdit
      return <EditableTable
                data={data.html}
                isEditing={@state.manuallyEditing}
                onApply={@saveManualEdit}
                onClose={@cancelManualEdit}
              />
    switch type
      when 'l1_sof', 'l2_sof'
        <LayerOneTwoSof data={data} />
      when 'evidence_short'
        <DxEvidenceTable data={data} />
      when 'relative_importance'
        <RelativeImportance data={data} />
      when 'sof_short'
        <SofShort data={data} />
      when 'sof_v1', 'sof_v2', 'sof_v3'
        <SofTable data={data} />
      when 'quality_indicators'
        <QualityIndicatorsTable
          readOnly
          qualityIndicators={Immutable.fromJS(data.qualityIndicators)}
        />
      else
        false

  collapsedTable: ->
    { data } = @_getTableProps()
    tableTitle = "#{@i18n('editor.table')}: <b>#{data.title}</b>"
    outcomesText = if outcomesSize is 1
      @i18n('editor.outcome')
    else
      @i18n('editor.outcomes')
    outcomesSize = data.outcomes?.length ? 0

    <CollapsedInsertedGdtTable
      tableData={data}
      tableTitle={tableTitle}
      outcomesText={"#{outcomesText} #{outcomesSize}"}
      onToggle={@toggleTable}
      openInNewWindow={@openTableInNewWindow}
      expandTitle={@i18n 'editor.expand_table'}
      openInNewWindowTitle={@i18n 'editor.open_table_in_new_window'}
    />

  tableRef: (e) ->
    @tableContent = e

  render: ->
    tableComponent = @renderTable()
    { renderMode, forceTableExpand } = @props.blockProps

    return @props.block.getText() unless tableComponent
    { type, initialType, manualEdit, data, expanded, renderMode: tableRenderMode } = @_getTableProps()


    # for printout table always has to be 'expanded' and we don't care about buttons etc.
    if renderMode is 'printout' or forceTableExpand or tableRenderMode is 'printout'
      return <div data-content-type={intialType ? type}>
        {tableComponent}
      </div>

    <div style={position: 'relative'}
      onMouseOver={@onMouseOver}
      onMouseLeave={@onMouseLeave}
      onMouseMove={@onMouseMove}
      data-content-type={initialType ? type}
    >
      {if @state.showConfirmationFor
        <Modal className='confirmation-modal' isOpen>
          <Confirmation
            question={@i18n "messages.table_#{@state.showConfirmationFor}_confirm"}
            onCancel={@cancelConfirmation}
            onConfirm={@onConfirm @state.showConfirmationFor}
          />
        </Modal>
      }
      {if @state.showControls and expanded
        <div ref={(el) => @controls = el} style={gdtTableStyles.controls}>
          {unless manualEdit
            <Tooltip>
              <button
                className='refresh'
                style={gdtTableStyles.button}
                title={@i18n 'editor.tooltips.update_data'}
                onClick={@showConfirmationFor 'update'}
              />
            </Tooltip>
          }
          <Tooltip>
            <button
              className='edit'
              style={gdtTableStyles.button}
              title={@i18n 'editor.tooltips.edit_manually'}
              onClick={@onEdit}
            />
          </Tooltip>
        </div>
      }
      <div ref={(el) => @tableContainer = el} >
        {not expanded and @collapsedTable()}
        {expanded and
          <div className="inserted-table_buttons-row">
            <CollapseTableButton
              label={@i18n 'editor.collapse_table'}
              onClick={@toggleTable}
            />
            <OpenInNewWindowButton
              label={@i18n 'editor.open_table_in_new_window'}
              onClick={@openTableInNewWindow}
            />
          </div>
        }
        <div ref={@tableRef} className={classNames 'hidden': not expanded}>
          {tableComponent}
        </div>
      </div>
    </div>

module.exports = InsertedGdtTableComponent
