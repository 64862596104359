ConnectStore = require 'components/enhancers/connect_store'
CreateUpdateFormModal = require 'components/common/create_update_form_modal'
NMAListActions = require 'actions/nma_list_actions'
NMAListStore = require 'stores/nma_list_store'
{ useI18n } = require 'lib/react_utils'

storeConnector =
  NMAListStore: (Store) ->
    isApplying: Store.isQuestionModalApplying()
    isOpen: Store.isQuestionModalOpen()
    mode: Store.getModalMode()
    question: Store.getModalQuestion()

FORM =
  main: [
    fieldKey: 'question'
    type: 'string'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'population'
    type: 'string'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'setting'
    type: 'string'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'author'
    type: 'string'
    showClear: false
    useControlledInput: true
  ]

NMAQuestionModal = ({ isApplying, isOpen, mode, question }) ->
  i18n = useI18n 'nma:questions_list.modal'

  <CreateUpdateFormModal
    className="nma-question-modal"
    data={question.toJS()}
    form={FORM}
    i18n={i18n}
    isApplying={isApplying}
    isOpen={isOpen}
    isSubmitEnabled={not isApplying and question.get('question')?.length > 0}
    mode={mode}
    onCancel={NMAListActions.closeQuestionModal}
    onCreate={NMAListActions.createQuestion}
    onChange={NMAListActions.updateQuestionInModal}
    onUpdate={NMAListActions.updateQuestion}
    title={i18n "title.#{mode}"}
  />

NMAQuestionModal.propTypes =
  isApplying: PropTypes.bool.isRequired
  isOpen: PropTypes.bool.isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  mode: PropTypes.oneOf(['create', 'edit']).isRequired

module.exports = ConnectStore NMAQuestionModal, [NMAListStore], storeConnector
