var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "<div class=\"edit-input-block row mt-10\">\n  <label for=\"withOutcome\">"
    + escapeExpression(((helpers.i18nCapitalize || (depth0 && depth0.i18nCapitalize) || helperMissing).call(depth0, "with_outcome", {"name":"i18nCapitalize","hash":{},"data":data})))
    + "</label>\n  <input id=\"withOutcome\" class=\"form-control\"\n    name=\"withOutcome\" value=\""
    + escapeExpression(((helper = (helper = helpers.withOutcome || (depth0 != null ? depth0.withOutcome : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"withOutcome","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"sup\"></div>\n</div>\n<div class=\"edit-input-block row mt-10\">\n  <label for=\"withoutOutcome\">"
    + escapeExpression(((helpers.i18nCapitalize || (depth0 && depth0.i18nCapitalize) || helperMissing).call(depth0, "without_outcome", {"name":"i18nCapitalize","hash":{},"data":data})))
    + "</label>\n  <input id=\"withoutOutcome\" class=\"form-control\"\n    name=\"withoutOutcome\" value=\""
    + escapeExpression(((helper = (helper = helpers.withoutOutcome || (depth0 != null ? depth0.withoutOutcome : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"withoutOutcome","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"sup\"></div>\n</div>\n<div class=\"case-control-other\">\n  <div class=\"row mt-10\">\n    <label class=\"legend\">"
    + escapeExpression(((helpers.i18nCapitalize || (depth0 && depth0.i18nCapitalize) || helperMissing).call(depth0, "other", {"name":"i18nCapitalize","hash":{},"data":data})))
    + "</label>\n  </div>\n  <div class=\"edit-input-block row mt-10\">\n    <label for=\"interventionCount\">"
    + escapeExpression(((helpers.i18nCapitalize || (depth0 && depth0.i18nCapitalize) || helperMissing).call(depth0, "exposed_with_event", {"name":"i18nCapitalize","hash":{},"data":data})))
    + "</label>\n    <input name=\"interventionCount\" class=\"form-control half\" value=\""
    + escapeExpression(((helper = (helper = helpers.interventionCount || (depth0 != null ? depth0.interventionCount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"interventionCount","hash":{},"data":data}) : helper)))
    + "\">\n    <input name=\"interventionTotal\" class=\"form-control half\" value=\""
    + escapeExpression(((helper = (helper = helpers.interventionTotal || (depth0 != null ? depth0.interventionTotal : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"interventionTotal","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"sup\"></div>\n  </div>\n  <div class=\"edit-input-block row mt-10\">\n    <label for=\"controlCount\">"
    + escapeExpression(((helpers.i18nCapitalize || (depth0 && depth0.i18nCapitalize) || helperMissing).call(depth0, "unexposed_with_event", {"name":"i18nCapitalize","hash":{},"data":data})))
    + "</label>\n    <input name=\"controlCount\" class=\"form-control half\" value=\""
    + escapeExpression(((helper = (helper = helpers.controlCount || (depth0 != null ? depth0.controlCount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"controlCount","hash":{},"data":data}) : helper)))
    + "\">\n    <input name=\"controlTotal\" class=\"form-control half\" value=\""
    + escapeExpression(((helper = (helper = helpers.controlTotal || (depth0 != null ? depth0.controlTotal : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"controlTotal","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"sup\"></div>\n  </div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}