ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
{ CADTH_GUIDELINE_FORM } = require 'lib/cadth_guidelines_helper'
CadthGuidelinesActions = require 'actions/cadth_guidelines_actions'
CadthGuidelinesStore = require 'stores/cadth_guidelines_store'
ConnectStore = require 'components/enhancers/connect_store'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
Modal = require 'components/common/modal'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

storeConnector =
  CadthGuidelinesStore: (Store) ->
    guideline: Store.getDialogGuideline()
    isOpen: Store.isDialogOpen()
    isSaving: Store.isDialogSaving()

CadthGuidelineModal = ({ guideline, isOpen, isSaving }) ->
  i18n = useI18n('key_messages:guidelines.modal')
  guidelineId = guideline.get '_id'
  isEditing = useCoffeeMemo [guidelineId], -> guidelineId?
  isSubmitEnabled = useCoffeeMemo [guideline], ->
    guideline.every (value, key) -> value isnt '' or key in ['shortTitle', 'sourceLink']
  onApply = useCoffeeCallback [CadthGuidelinesActions, guideline], ->
    CadthGuidelinesActions.createOrUpdate guideline.toJS()

  <Modal
    className="cadth-guideline-modal"
    isOpen={isOpen}
    modalSize="medium"
    title={i18n if isEditing then 'edit_title' else 'add_title'}
  >
    <ExtractionForm
      data={guideline.toJS()}
      form={main: CADTH_GUIDELINE_FORM}
      i18n={i18n}
      inModal
      onChange={CadthGuidelinesActions.setDialogField}
    />
    <ApplyCancelButtons
      applying={isSaving}
      applyLabel={i18n if isEditing then '/actions.save' else '/actions.create'}
      isSubmitEnabled={isSubmitEnabled}
      onApply={onApply}
      onCancel={CadthGuidelinesActions.closeDialog}
    />
  </Modal>

CadthGuidelineModal.propTypes =
  guideline: PropTypes.instanceOf(Immutable.Map).isRequired
  isOpen: PropTypes.bool.isRequired
  isSaving: PropTypes.bool.isRequired

module.exports = ConnectStore CadthGuidelineModal, CadthGuidelinesStore, storeConnector
