AssessmentAdolopments = require 'components/etd/assessment_adolopments'
AssessmentSectionHeader = require 'components/etd/assessment_section_header'
AssessmentSectionRow = require 'components/etd/assessment_section_row'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
PrintoutCellTitlesRow = require 'components/etd/printout_cell_titles_row'
Translation = require 'components/mixins/translation'


AssessmentSection = createReactClass

  displayName: "AssessmentSection"

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  propTypes:
    additionalConsiderations: PropTypes.instanceOf(Immutable.Map).isRequired
    additionalConsiderationsVisible: PropTypes.bool.isRequired
    adolopmentData: PropTypes.instanceOf(Immutable.Map).isRequired
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired
    criterion: PropTypes.instanceOf(Immutable.Map).isRequired
    displayContent: PropTypes.bool.isRequired
    editable: PropTypes.bool.isRequired
    etdId: PropTypes.string.isRequired
    etdViewSettings: PropTypes.instanceOf(Immutable.Map).isRequired
    expanded: PropTypes.bool.isRequired
    index: PropTypes.number.isRequired
    qualityIndicators: PropTypes.instanceOf(Immutable.List)
    renderMode: PropTypes.string.isRequired
    researchEvidence: PropTypes.instanceOf(Immutable.Map).isRequired
    sectionId: PropTypes.string.isRequired
    sectionName: PropTypes.string.isRequired
    showResultingDataOnly: PropTypes.bool
    templateId: PropTypes.string.isRequired
    type: PropTypes.oneOf(['overarching', 'keyMessages', 'regular', 'qualityIndicators'])

  getDefaultProps: ->
    qualityIndicators: Immutable.List()
    type: 'regular'

  render: ->
    {
      additionalConsiderationsVisible
      adolopmentData
      adolopments
      criterion
      displayContent
      etdId
      etdViewSettings
      expanded
      index
      overarchingQuestionData
      parentSectionsData
      qualityIndicators
      renderMode
      researchEvidence
      sectionData
      sectionId,
      sectionName
      showResultingDataOnly
      templateId
      type
    } = @props

    tbodyClassess = classNames 'expanded': expanded

    rows = []

    adolopmentSectionCollapsed = etdViewSettings.getIn(
      ['collapsedAdolopmentSections', sectionId], false)

    # withAdolopment means that one wants to update this section
    # if it is set to false than content of original section is displayed and marked as readonly
    withAdolopment = adolopmentData.get('withAdolopment', false)

    crId = sectionData.get('criterionId')
    criterion = if adolopments.isEmpty() or withAdolopment
      criterion
    else
      adolopment = adolopments.last()
      adolopment.getIn ['templateData', 'assessment', 'criteria', crId]

    reId = sectionData.get('researchEvidenceId')
    researchEvidence = if adolopments.isEmpty() or withAdolopment
      @props.researchEvidence
    else
      adolopment = adolopments.last()
      adolopment.getIn ['templateData', 'assessment', 'researchEvidences', reId]

    acId = sectionData.get('additionalConsiderationId')
    additionalConsiderations = if adolopments.isEmpty() or withAdolopment
      @props.additionalConsiderations
    else
      adolopment = adolopments.last()
      adolopment.getIn ['templateData', 'assessment', 'additionalConsiderations', acId]

    editable = @props.editable and (adolopments.isEmpty() or withAdolopment)

    sectionKey = if not adolopments.isEmpty() and withAdolopment
      'adolopment'
    else
      'original'

    sectionProps = _.omit(@props, [
      'additionalConsiderations',
      'criterion',
      'editable'
      'researchEvidence',
    ])

    sectionDescription = if sectionData.get('rating') and
    not _.isEmpty(criterion.get('ratingDescription'))
      criterion.get('ratingDescription')
    else
      criterion.get('description')

    sectionWithResultingData = <AssessmentSectionRow
      additionalConsiderations={additionalConsiderations}
      additionalConsiderationsVisible={additionalConsiderationsVisible}
      criterion={criterion}
      editable={editable}
      isAdoloped={not adolopments.isEmpty()}
      key={sectionId}
      originalJudgements={originalJudgements}
      overarchingQuestionData={overarchingQuestionData}
      parentSectionsData={parentSectionsData}
      qualityIndicators={qualityIndicators}
      readOnly={not editable}
      researchEvidence={researchEvidence}
      sectionKey={sectionKey}
      showJudgement={etdViewSettings.get('showAdolopmentJudgement', true) or not withAdolopment}
      withAdolopment={withAdolopment}
      templateId={templateId}
      type={type}
      {...sectionProps}
    />

    if showResultingDataOnly
      rows.push sectionWithResultingData
    else
      if not adolopments.isEmpty() and withAdolopment
        originalJudgements = adolopments.map (adolopment) ->
          adolopment.getIn ['templateData', 'assessment', 'criteria']
        rows.push AssessmentAdolopments(_.extend({},
          @props,
          withAdolopment,
          i18n: @i18n
        ))
      if not adolopmentSectionCollapsed or not withAdolopment
        rows.push sectionWithResultingData

    <tbody key={sectionId} className={tbodyClassess}>
      <AssessmentSectionHeader
        colSpan={3}
        content={sectionDescription}
        etdId={etdId}
        expanded={expanded}
        index={index}
        info={criterion.get('info')}
        isBeingAdoloped={not adolopments.isEmpty()}
        renderMode={renderMode}
        sectionId={sectionId}
        sectionName={sectionName}
        withAdolopment={withAdolopment}
      />
      <PrintoutCellTitlesRow i18n={@i18n} renderMode={renderMode}/>
      {rows if displayContent}
    </tbody>

module.exports = AssessmentSection
