RadioOptions = require '../covid_extraction_form/radio_options'
EditorText = require 'components/covid_extraction_form/editor_text'
{ convertFromRaw } = Draft

SwitchableText = createReactClass
  displayName: "SwitchableText"

  onChange: (field) -> (newValue) =>
    {fieldKey, onChange, value } = @props
    currValue = if _.isString value then { text: value } else value

    updatedValue = if field is 'showTextInput' and newValue is 'notReported'
      {"showTextInput": false, "text": newValue}
    else if field is 'showTextInput'
      {"#{field}": newValue, 'text': null}
    else
      {"#{field}": newValue.toJS()}

    onChange _.extend({}, currValue, updatedValue)

  render: ->
    {i18n, fieldKey, placeholderToUse, options, onChange, value} = @props

    plainTextValue = if _.isString(value?.text)
      value.text
    else if value?.text
        convertFromRaw(value.text).getPlainText()
    else ''

    showTextInput = (_.isString(value) and value isnt 'notReported') or value?.showTextInput
    radioInputValue = if plainTextValue is 'notReported'
      'notReported'
    else
      showTextInput

    <div className="flex flex-row">
      <div className="mr-10">
        <RadioOptions
          i18n={i18n}
          options={options}
          value={radioInputValue}
          fieldKey={fieldKey}
          placeholder={placeholderToUse}
          onChange={@onChange('showTextInput')}
        />
      </div>
      <div className="flex-grow">
        {showTextInput && <EditorText
          onSave={@onChange('text')}
          value={if _.isString(value) then value else value?.text}
        />
        }
      </div>
    </div>

module.exports = SwitchableText
