var INCLUDED_QUESTIONS_PATH, INCLUDED_TAGS_PATH, INITIAL_STATE, OVERARCHING_QUESTION, OverarchingQuestionsActions, QUESTION_DIAG_TAGS, QUESTION_MGMT_TAGS, QuestionGroupsStore, generateStore, getQuestionTags, groupQuestionsByGroup, includedTagAdder, includedTagRemover, mediator, prepareTags, sortByOrderNumber, stripHTML, _ref, _ref1, _ref2,
  __slice = [].slice;

generateStore = require('stores/utils/generate_store');

mediator = require('mediator');

_ref = require('lib/questions_helper'), groupQuestionsByGroup = _ref.groupQuestionsByGroup, sortByOrderNumber = _ref.sortByOrderNumber;

_ref1 = require('lib/overarching_question_helper'), QUESTION_DIAG_TAGS = _ref1.QUESTION_DIAG_TAGS, QUESTION_MGMT_TAGS = _ref1.QUESTION_MGMT_TAGS;

_ref2 = require('lib/question_tags_helper'), includedTagAdder = _ref2.includedTagAdder, includedTagRemover = _ref2.includedTagRemover, prepareTags = _ref2.prepareTags, stripHTML = _ref2.stripHTML;

OVERARCHING_QUESTION = require('lib/db_docs/doc_types').OVERARCHING_QUESTION;

OverarchingQuestionsActions = require('actions/overarching_questions_actions');

QuestionGroupsStore = require('stores/question_groups_store');

getQuestionTags = function(question) {
  if (question.get('type') === 'diagnostic') {
    return QUESTION_DIAG_TAGS;
  } else {
    return QUESTION_MGMT_TAGS;
  }
};

INCLUDED_QUESTIONS_PATH = ['currentQuestion', 'includedQuestions'];

INCLUDED_TAGS_PATH = ['currentQuestion', 'includedTags'];

INITIAL_STATE = Immutable.fromJS({
  questions: Immutable.Map(),
  currentQuestion: Immutable.Map(),
  meta: {
    isFetching: false,
    fetchingError: null,
    isExportModalOpen: false
  }
});

module.exports = generateStore({
  name: 'OverarchingQuestionsStore',
  initialState: INITIAL_STATE,
  boundActions: [OverarchingQuestionsActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['meta', 'isFetching'], true);
        return s.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchSuccess: function(questionsMap) {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['meta', 'isFetching'], false);
        return s.set('questions', questionsMap);
      }));
    },
    onFetchOne: function() {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['meta', 'isFetching'], true);
        return s.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchOneSuccess: function(question) {
      var includedTags, questionToSet, _ref3;
      includedTags = Immutable.fromJS((_ref3 = question.includedTags) != null ? _ref3 : []).toList();
      questionToSet = Immutable.fromJS(_.omit(question, 'includedTags')).set('includedTags', includedTags);
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['meta', 'isFetching'], false);
        return s.set('currentQuestion', Immutable.fromJS(question));
      }));
    },
    onFetchError: function(err) {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['meta', 'isFetching'], false);
        return s.setIn(['meta', 'fetchingError'], err);
      }));
    },
    onDbChange: function(change) {
      var deleted, doc, id, isPresent;
      deleted = change.deleted, id = change.id, doc = change.doc;
      if (doc.docType !== OVERARCHING_QUESTION) {
        return;
      }
      isPresent = this.state.get('questions').has(id);
      if (deleted && isPresent) {
        return this.setState(this.state.update('questions', function(questions) {
          return questions["delete"](id);
        }));
      } else {
        return this.setState(this.state.update('questions', function(questions) {
          if (isPresent) {
            return questions.update(id, function(q) {
              return q.merge(Immutable.fromJS(doc));
            });
          } else {
            return questions.set(id, Immutable.fromJS(doc));
          }
        }));
      }
    },
    onShowExportDialog: function() {
      return this.setState(this.state.setIn(['meta', 'isExportModalOpen'], true));
    },
    onHideExportDialog: function() {
      return this.setState(this.state.setIn(['meta', 'isExportModalOpen'], false));
    },
    onUpdateCurrentQuestion: function(_arg) {
      var attrName, value;
      attrName = _arg.attrName, value = _arg.value;
      return this.setState(this.state.setIn(['currentQuestion', attrName], value));
    },
    onUpdateCurrentQuestionType: function(newType) {
      var newTags, tagsToSet;
      newTags = newType === 'overarching:diagnostic' ? QUESTION_DIAG_TAGS : QUESTION_MGMT_TAGS;
      tagsToSet = Immutable.fromJS(prepareTags(newTags));
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['currentQuestion', 'type'], newType);
        return s.setIn(['currentQuestion', 'includedTags'], tagsToSet);
      }));
    },
    onDestroyCurrentQuestion: function() {
      return this.setState(this.state.set('currentQuestion', Immutable.Map()));
    },
    onAddIncludedQuestion: function(question) {
      var TAGS;
      TAGS = getQuestionTags(question);
      return this.setState(this.state.withMutations(function(s) {
        s.updateIn(INCLUDED_QUESTIONS_PATH, function(includedQuestions) {
          return includedQuestions.push(question.get('questionId'));
        });
        return _.each(TAGS, function(tag) {
          return s.updateIn(INCLUDED_TAGS_PATH, includedTagAdder(question, tag));
        });
      }));
    },
    onRemoveIncludedQuestion: function(questionId) {
      var questionIndex;
      questionIndex = this.state.getIn(INCLUDED_QUESTIONS_PATH).indexOf(questionId);
      if (questionIndex !== -1) {
        return this.setState(this.state.withMutations(function(s) {
          s.updateIn(INCLUDED_QUESTIONS_PATH, function(includedQuestions) {
            return includedQuestions["delete"](questionIndex);
          });
          return s.updateIn(INCLUDED_TAGS_PATH, includedTagRemover(questionId));
        }));
      }
    },
    onUpdateIncludedQuestion: function(_arg) {
      var TAGS, newQuestion, oldQuestionId, oldQuestionIndex;
      oldQuestionId = _arg.oldQuestionId, newQuestion = _arg.newQuestion;
      oldQuestionIndex = this.state.getIn(INCLUDED_QUESTIONS_PATH).indexOf(oldQuestionId);
      if (oldQuestionIndex !== -1 && newQuestion) {
        TAGS = getQuestionTags(newQuestion);
        return this.setState(this.state.withMutations(function(s) {
          s.setIn(__slice.call(INCLUDED_QUESTIONS_PATH).concat([oldQuestionIndex]), newQuestion.get('questionId'));
          s.updateIn(INCLUDED_TAGS_PATH, includedTagRemover(oldQuestionId));
          return _.each(TAGS, function(tag) {
            return s.updateIn(INCLUDED_TAGS_PATH, includedTagAdder(newQuestion, tag));
          });
        }));
      }
    },
    onUpdateMetadata: function(_arg) {
      var key, value;
      key = _arg.key, value = _arg.value;
      return this.setState(this.state.setIn(['currentQuestion', 'metadata', key], value));
    },
    onAddTag: function(tagKey) {
      return this.setState(this.state.updateIn(INCLUDED_TAGS_PATH, includedTagAdder(null, tagKey)));
    },
    onRemoveTag: function(tag) {
      return this.setState(this.state.updateIn(INCLUDED_TAGS_PATH, function(tags) {
        return tags.filterNot(function(t) {
          return t.get('id') === tag.get('id');
        });
      }));
    },
    onUpdateTag: function(tag) {
      var tagIndex;
      tagIndex = this.state.getIn(__slice.call(INCLUDED_TAGS_PATH), Immutable.List()).findIndex(function(t) {
        return t.get('id') === tag.get('id');
      });
      return this.setState(this.state.setIn(__slice.call(INCLUDED_TAGS_PATH).concat([tagIndex]), tag));
    },
    onAddCode: function(_arg) {
      var code, codes, codesPath, newCode, tagId;
      tagId = _arg.parentId, codes = _arg.codes;
      codesPath = __slice.call(INCLUDED_TAGS_PATH).concat([tagId], ['codes']);
      code = codes.first();
      newCode = Immutable.fromJS({
        id: code.get('@id'),
        codeType: code.get('@type'),
        value: stripHTML(code.get('value')),
        name: stripHTML(code.get('name'))
      });
      return this.setState(this.state.updateIn(codesPath, function(codes) {
        return codes.set(code.get('@id'), newCode);
      }));
    },
    onRemoveCode: function(_arg) {
      var code, codesPath, tagId;
      tagId = _arg.parentId, code = _arg.code;
      codesPath = __slice.call(INCLUDED_TAGS_PATH).concat([tagId], ['codes']);
      return this.setState(this.state.updateIn(codesPath, function(codes) {
        return codes["delete"](code.get('id'));
      }));
    },
    onHandleToggleTag: function(entryId) {
      var tagPath;
      tagPath = ['currentQuestion', 'includedTags', entryId];
      return this.setState(this.state.updateIn(tagPath, function(tag) {
        return tag.set('included', !tag.get('included'));
      }));
    },
    onClearIncludedTags: function() {
      return this.setState(this.state.setIn(INCLUDED_TAGS_PATH, Immutable.Map()));
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'isFetching']);
    },
    isExportModalOpen: function() {
      return this.state.getIn(['meta', 'isExportModalOpen']);
    },
    getCurrentQuestion: function() {
      return this.state.get('currentQuestion', Immutable.Map());
    },
    getQuestion: function(qId) {
      return this.state.getIn(['questions', qId], Immutable.Map());
    },
    getOverarchingQuestions: function() {
      return sortByOrderNumber(this.state.get('questions', Immutable.Map()));
    },
    getOverarchingQuestionsByGroup: function(_arg) {
      var groupedQuestions, questionGroups, questions, withEmptyGroups;
      withEmptyGroups = (_arg != null ? _arg : {}).withEmptyGroups;
      if (withEmptyGroups == null) {
        withEmptyGroups = true;
      }
      questions = this.getOverarchingQuestions();
      questionGroups = QuestionGroupsStore.getQuestionGroups();
      groupedQuestions = groupQuestionsByGroup(questions, questionGroups);
      if (withEmptyGroups) {
        return groupedQuestions;
      }
      return groupedQuestions.filterNot(function(group) {
        return group.get('questions').isEmpty();
      });
    }
  }
});
