var NMAQuestion, NMAQuestionActions, NMAQuestionToolbar, NMAQuestionView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

NMAQuestionToolbar = require('components/nma/nma_question_toolbar');

NMAQuestionActions = require('actions/nma_question_actions');

NMAQuestion = require('components/nma/nma_question');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = NMAQuestionView = (function(_super) {
  __extends(NMAQuestionView, _super);

  function NMAQuestionView() {
    return NMAQuestionView.__super__.constructor.apply(this, arguments);
  }

  NMAQuestionView.prototype.container = '#page-container';

  NMAQuestionView.prototype.id = 'nma-question';

  NMAQuestionView.prototype.autoRender = true;

  NMAQuestionView.prototype._stopInputsPropagation = false;

  NMAQuestionView.prototype.initialize = function() {
    NMAQuestionView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: NMAQuestionToolbar
    }));
    return this.enable(ReactComponent);
  };

  NMAQuestionView.prototype.afterRender = function() {
    NMAQuestionView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(NMAQuestion, NMAQuestionActions, this.options.questionId, {
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  NMAQuestionView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('NMAListStore', 'NMAQuestionStore');
    return NMAQuestionView.__super__.dispose.apply(this, arguments);
  };

  return NMAQuestionView;

})(View);
