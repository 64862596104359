Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
GlobalExportStore = require 'stores/global_export_store'
ProgressBar = require 'components/common/progress_bar'
Translation = require 'components/mixins/translation'

storeConnectors =
  GlobalExportStore: (Store) ->
    progress: Store.getExportProgress()
    isExporting: Store.isExporting()
    exportedFileUrl: Store.getExportedFileUrl()

ExportProgress = createReactClass
  displayName: 'GlobalExportProgress'
  mixins: [Translation('projects:global_export_dialog.export_progress')]
  propTypes:
    progress: PropTypes.number.isRequired
    isExporting: PropTypes.bool.isRequired
    exportedFileUrl: PropTypes.string.isRequired

  render: ->
    { progress, isExporting, exportedFileUrl } = @props

    <div className="global-export-modal__export-progress">
      <div className="export-progress__header">
        {@i18n if isExporting then 'exporting' else 'completed'}
      </div>
      {isExporting and <div className="export-progress__export-bar-description">
        <div>{@i18n 'start'}</div>
        <div>{@i18n 'finish'}</div>
      </div>}
      {isExporting and <ProgressBar percentage={progress} />}
      {not isExporting and
        <div className="export-progress__export-completed">
          <div className="export-completed__file">
            <a
              href={exportedFileUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              <span>{@i18n 'download'}</span>
            </a>
          </div>
        </div>
      }
    </div>

module.exports = ConnectStore ExportProgress, [GlobalExportStore], storeConnectors
