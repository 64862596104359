ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
EDMSActions = require 'actions/edms_actions'
EDMSStore = require 'stores/edms_store'
SearchBar = require 'components/common/search_bar'
Spinner = require 'components/common/spinner'
Tooltip = require 'components/common/tooltip'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'
{ pluck } = require 'lib/immutable_utils'
{ useEffect } = React
DynamicHeightContainer = require 'components/common/dynamic_height_container'

storeConnector =
  EDMSStore: (Store) ->
    localSearchQuery: Store.getLocalSearchQuery()
    globalSearchQuery: Store.getGlobalSearchQuery()
    isFetching: Store.isFetching()
    folders: Store.getFolders()
    files: Store.getFiles()
    submitting: Store.isSubmitting()
    openedFolders: Store.getOpenedFolders()
    rootFolderIds: Store.getRootFolderIds()
    selectedFile: Store.getSelectedFile()
    insertHandler: Store.getInsertHandler()

EDMSImport = ({
  insertHandler
  submitting
  selectedFile
  openedFolders
  localSearchQuery
  globalSearchQuery
  isFetching
  folders
  files
  rootFolderIds
}) ->

  i18n = useI18n('')

  resetLocalSearchQuery = useCoffeeCallback [], () ->
    EDMSActions.setLocalSearchQuery('', true)

  onChangeLocalSearchQuery = useCoffeeCallback [], (query) ->
    EDMSActions.setLocalSearchQuery(query)

  onChangeGlobalSearchQuery = useCoffeeCallback [], (query) ->
    EDMSActions.setGlobalSearchQuery(query)

  resetGlobalSearchQuery = useCoffeeCallback [], () ->
    EDMSActions.setGlobalSearchQuery('')
    EDMSActions.fetchCabinet()

  onClose = useCoffeeCallback [], () ->
    EDMSActions.setGlobalSearchQuery('')
    EDMSActions.closeImportDialog()
    EDMSActions.toggleSelectedFile(null)

  canSubmit = -> Boolean(selectedFile)

  useEffect ->
    EDMSActions.fetchCabinet()
    undefined
  , []

  onApply = useCoffeeCallback [selectedFile, files, insertHandler], () ->
    fileToInsert = files.find (f) -> f.get('id') is selectedFile
    return if fileToInsert?.isEmpty()
    EDMSActions.insertFile(selectedFile)
    .then (res) ->
      insertHandler(_.extend {
        insertType: if fileToInsert.get('fileType').indexOf('image') is 0 then 'image' else 'link'
      }, res, fileToInsert.toJS())
    .finally ->
      onClose()

  <div>
    <div className="my-10">
      <SearchBar
        searchOnChange={false}
        onSearch={onChangeGlobalSearchQuery}
        onSearchReset={resetGlobalSearchQuery}
        searchText={globalSearchQuery}
      />
    </div>
    <div className="flex flex-row">
      <div className="mr-20">
        <TreeView folders={folders} foldersOrder={rootFolderIds} openedFolders={openedFolders} />
      </div>
      <div className="w-full">
        <div className="my-10 flex flex-row items-center">
          <div>
            <SearchBar
              onSearch={onChangeLocalSearchQuery}
              onSearchReset={resetLocalSearchQuery}
              searchOnChange={false}
              searchText={localSearchQuery}
            />
          </div>
          <div>

          </div>
        </div>
        <div className="mt-20 mb-50 relative">
          {isFetching and <div className="overlay"><Spinner /></div>}
          <FilesView selectedFile={selectedFile} files={files} />
        </div>
      </div>
    </div>
    <ApplyCancelButtons
      onApply={onApply}
      onCancel={onClose}
      applying={submitting}
      isSubmitEnabled={!submitting && canSubmit()}
      applyLabel={i18n('/actions.insert')}
      applyClass="btn-alternative"
      cancelLabel={i18n('/actions.cancel')}
    />
  </div>


TreeView = ({ openedFolders, folders, foldersOrder }) ->
  <div className="tree-view">
    {foldersOrder.map((folderId) ->
      return null unless folders.has(folderId)
      <Folder key={folderId} folders={folders} folderId={folderId} openedFolders={openedFolders} />
    )}
  </div>

TreeView.propTypes =
  foldersOrder: PropTypes.instanceOf(Immutable.List).isRequired
  folders: PropTypes.instanceOf(Immutable.Map).isRequired
  openedFolders: PropTypes.instanceOf(Immutable.List).isRequired

Folder = ({ folders, folderId, openedFolders }) ->
  open = openedFolders.includes(folderId)
  subfolders = folders.getIn([folderId, 'childrenIds'], Immutable.List())
  folderClasses = classNames(
    'folder',
    'opened': open
    'closed': not open
  )

  fetchAndOpenFolder = useCoffeeCallback [folderId], () ->
    EDMSActions.fetchCabinet(folderId)

  <div>
    <div>
      <div className={folderClasses} onClick={fetchAndOpenFolder}>
        {folders.getIn([folderId, 'name'])}
      </div>
      <DynamicHeightContainer>
      {not subfolders.isEmpty() and open and <TreeView
        folders={folders}
        foldersOrder={subfolders}
        openedFolders={openedFolders}
      />}
      </DynamicHeightContainer>
    </div>
  </div>

Folder.propTypes =
  openedFolders: PropTypes.instanceOf(Immutable.List).isRequired
  folders: PropTypes.instanceOf(Immutable.Map).isRequired
  folderId: PropTypes.number.isRequired

FilesView = ({ files, selectedFile }) ->
  <div className="files">
    {files.map((file) ->
      <File selected={file.get('id') is selectedFile} key={file.get('id')} file={file} />
    )}
  </div>

FilesView.propTypes =
  files: PropTypes.instanceOf(Immutable.List).isRequired
  selectedFile: PropTypes.number

FilesView.defaultProps =
  selectedFile: null

File = ({ file, selected }) ->

  selectFile = (e) ->
    e.stopPropagation()
    EDMSActions.toggleSelectedFile(file.get('id'))

  classes = classNames(
    'file',
    'selected': selected
  )

  <div className={classes} onClick={selectFile}>
    <div className="miniature">
    {switch file.get('fileType')
      when 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf'
        <img src={file.get('imageUrl')} alt={file.get('name')}/>
      else
        <span className="file-icon" />
    }
    </div>
    <div className="metadata">
      <Tooltip>
        <div className="title" title={file.get('name')}>{file.get('name')}</div>
      </Tooltip>
    </div>
  </div>

File.propTypes =
  file: PropTypes.instanceOf(Immutable.Map).isRequired
  selected: PropTypes.bool.isRequired


module.exports = ConnectStore EDMSImport, [EDMSStore], storeConnector
