TabsPane = require 'components/common/tabs_pane'
ExportButton = require 'components/scope/scope_export_button'
QuestionsGenerationActions = require 'actions/questions_generation_actions'
QuestionsGenerationStore = require 'stores/questions_generation_store'
{ QUESTION_GENERATION_STEPS } = require 'lib/questions_helper'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
CreateStoreMixin = require 'components/mixins/create_store'

QuestionsGenerationToolbar = createReactClass
  displayName: 'QuestionsGenerationToolbar'

  mixins: [
    CustomRenderMixin
    Translation('')
    CreateStoreMixin(QuestionsGenerationStore)
  ]

  getStateFromStores: ->
    activeTab: QuestionsGenerationStore.getActiveTab()
    currentStep: QuestionsGenerationStore.getCurrentStep()
    commentedStepQuestions: QuestionsGenerationStore.getStepQuestionsWithComments()

  _getTabsList: ->
    currentStepIdx = QUESTION_GENERATION_STEPS.indexOf @state.currentStep
    QUESTION_GENERATION_STEPS.map (tabName, idx) =>
      tabLabel = @i18n "/scope:#{tabName}_step"
      isDisabled = idx > currentStepIdx
      { tabName, tabLabel, isDisabled }

  _isExportDisabled: ->
    # always disabled on initial and rating tab
    return true if @state.activeTab in [
      QUESTION_GENERATION_STEPS[0]
      QUESTION_GENERATION_STEPS[3]
    ]
    # always enabled on finished tab
    return false if @state.activeTab is QUESTION_GENERATION_STEPS[6]
    # for the rest of the tabs it is enabled if there is any question with comments
    @state.commentedStepQuestions.isEmpty()

  render: ->
    <div className='scope-toolbar'>
      <TabsPane
        activeTab={@state.activeTab}
        tabs={@_getTabsList()}
        onTabClick={QuestionsGenerationActions.changeTab}
      />
      <ExportButton
        className={@state.activeTab.replace(".", "-")}
        onClick={QuestionsGenerationActions.showCommentsExport}
        disabled={@_isExportDisabled()}
      />
    </div>

module.exports = QuestionsGenerationToolbar
