ConnectStore = require 'components/enhancers/connect_store'
CreateUpdateFormModal = require 'components/common/create_update_form_modal'
NMAQuestionActions = require 'actions/nma_question_actions'
NMAQuestionStore = require 'stores/nma_question_store'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

storeConnector =
  NMAQuestionStore: (Store) ->
    isApplying: Store.isOutcomeModalApplying()
    isOpen: Store.isOutcomeModalOpen()
    mode: Store.getOutcomeModalMode()
    outcome: Store.getModalOutcome()
    questionId: Store.getCurrentQuestion().get '_id', ''

TYPE_OPTIONS = [
  value: 'dich'
  textKey: 'dich'
,
  value: 'cont'
  textKey: 'cont'
,
  value: 'timeToEvent'
  textKey: 'time_to_event'
]

FORM =
  main: [
    fieldKey: 'name'
    type: 'string'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'shortName'
    type: 'string'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'type'
    type: 'radioOptions'
    options: TYPE_OPTIONS
    showClear: false
    withOther: false
  ,
    fieldKey: 'units'
    type: 'string'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'higherIsBetter'
    type: 'switcher'
    showClear: false
  ,
    fieldKey: 'baselineRisk'
    type: 'string'
    showClear: false
    additionalProps:
      type: 'number'
      step: 0.0001
    useControlledInput: true
  ]

NMAOutcomeModal = ({ isApplying, isOpen, mode, outcome, questionId }) ->
  i18n = useI18n 'nma:question.outcomes.modal'

  onCreateOutcome = useCoffeeCallback [outcome, questionId], ->
    NMAQuestionActions.createOutcome questionId, outcome.toJS()

  <CreateUpdateFormModal
    className="nma-outcome-modal"
    data={outcome.toJS()}
    form={FORM}
    i18n={i18n}
    isApplying={isApplying}
    isOpen={isOpen}
    isSubmitEnabled={not isApplying and outcome.get('name')?.length > 0}
    mode={mode}
    onCancel={NMAQuestionActions.closeOutcomeModal}
    onCreate={onCreateOutcome}
    onChange={NMAQuestionActions.updateOutcomeInModal}
    onUpdate={NMAQuestionActions.updateOutcome}
    title={i18n "title.#{mode}"}
  />

NMAOutcomeModal.propTypes =
  isApplying: PropTypes.bool.isRequired
  isOpen: PropTypes.bool.isRequired
  outcome: PropTypes.instanceOf(Immutable.Map).isRequired
  mode: PropTypes.oneOf(['create', 'edit']).isRequired
  questionId: PropTypes.string.isRequired

module.exports = ConnectStore NMAOutcomeModal, [NMAQuestionStore], storeConnector
