InfoTooltip = require 'components/common/info_tooltip'

LimitCounter = ({ limit, count, title, tooltip  }) ->
  classes = classNames 'items-counter__pill',
    'items-counter__pill--exceeded': limit and limit isnt -1 and count > limit
  <div className='items-counter'>
    {title and <h3>{title}</h3>}
    <span className={classes}>
      {if limit and limit isnt -1
        "#{count}/#{limit}"
      else
        count
      }
    </span>
    {tooltip and limit isnt -1 and <InfoTooltip>
        {tooltip}
      </InfoTooltip>
    }
  </div>

LimitCounter.propTypes =
  limit: PropTypes.number
  count: PropTypes.number.isRequired
  title: PropTypes.string
  tooltip: PropTypes.node

LimitCounter.defaultProps =
  limit: null
  title: null
  tooltip: null

module.exports = LimitCounter
