var NMAViz, NMAVizActions, NMAVizToolbar, NMAVizView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

NMAVizActions = require('actions/nma_viz_actions');

NMAVizToolbar = require('components/nma/nma_viz_toolbar');

NMAViz = require('components/nma/nma_viz');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = NMAVizView = (function(_super) {
  __extends(NMAVizView, _super);

  function NMAVizView() {
    return NMAVizView.__super__.constructor.apply(this, arguments);
  }

  NMAVizView.prototype.container = '#page-container';

  NMAVizView.prototype.id = 'nma-viz';

  NMAVizView.prototype.autoRender = true;

  NMAVizView.prototype._stopInputsPropagation = false;

  NMAVizView.prototype.initialize = function() {
    NMAVizView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: NMAVizToolbar,
      props: {
        questionId: this.options.questionId
      }
    }));
    return this.enable(ReactComponent);
  };

  NMAVizView.prototype.afterRender = function() {
    NMAVizView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(NMAViz, NMAVizActions, this.options.questionId, {
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  NMAVizView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('NMAVizStore', 'NMAQuestionStore', 'NMAListStore');
    return NMAVizView.__super__.dispose.apply(this, arguments);
  };

  return NMAVizView;

})(View);
