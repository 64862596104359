module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": "Líši sa",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je intervencia prijateľná pre kľúčové zainteresované strany?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno ",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Existujú kľúčové zainteresované strany, ktoré by nesúhlasili s rozdelením prínosov, rizík a nákladov? ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Existujú kľúčové zainteresované strany, ktoré by neakceptovali krátkodobé náklady alebo nežiadúce účinky napriek žiadúcim účinkom (prínosom) z dlhodobého hľadiska?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Existujú kľúčové zainteresované strany, ktoré by nesúhlasili s váhou pripísanou žiadúcim alebo nežiadúcim účinkom (napr. kvôli tomu, ako by mohli byť tieto subjekty ovplyvnené alebo kvôli ich vnímaniu relatívneho významu účinkov pre ostatných)?   ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Mala by intervencia (možnosť) nepriaznivý vplyv na autonómiu jednotlivcov?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Existujú kľúčové zainteresované strany, ktoré by s danou intervenciou (možnosťou) morálne nesúhlasili z iných dôvodov, než je ich vplyv na autonómiu ľudí (t.j. vo vzťahu k etickým princípom ako je princíp neškodnosti (nonmaleficencie), beneficencie alebo spravodlivosti)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je pravdepodobné, že kľúčové zainteresované strany nebudú akceptovať pomer prínosov, rizík a nákladov? Alebo nebudú akceptovať krátkodobé náklady, či nežiadúce účinky navzdory žiadúcim účinkom (prínosom) v budúcnosti? ja pravdepodobné, ťw nebudú súhlasiť s váhou pripísanou žiadúcim alebo nežiadúcim účinkom, alebo, že nebudú diagnostickú intervenciu akceptovať z etických dôvodov?   ",
          "options": [
            {
              "text": "Nie",
              "value": "no"
            },
            {
              "text": "Pravdepodobne nie",
              "value": "probably_no"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Áno",
              "value": "yes"
            }
          ],
          "ratingDescription": "Ktoré intervencia je viac prijateľná pre kľúčové zainteresované strany?",
          "ratingOptions": [
            "best_acceptability",
            "intermediate_acceptability",
            "worst_acceptability"
          ],
          "selectedOption": ""
        },
        "additionalSection": {
          "additionalOptions": [
            {
              "text": "Dodatočné hodnotenie 1",
              "value": "additionalOption_1"
            },
            {
              "text": "Dodatočné hodnotenie 2",
              "value": "additionalOption_2"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Popis vlastného kritéria",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Poskytnúť vlastný popis",
          "options": [
            {
              "text": "Hodnotenie 1",
              "value": "option_1"
            },
            {
              "text": "Hodnotenie 2",
              "value": "option_2"
            },
            {
              "text": "Hodnotenie 3",
              "value": "option_3"
            },
            {
              "text": "Hodnotenie 4",
              "value": "option_4"
            },
            {
              "text": "Hodnotenie 5",
              "value": "option_5"
            }
          ],
          "selectedOption": ""
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": "Líši sa",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je pomer žiadúcich a nežiadúcich účinkov v prospech intervencie alebo porovnania?",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "Podrobné hodnotenie tohto kritéria zahŕňa hodnotenie každého zo štyroch predchádzajúcich kritérií"
              },
              {
                "additions": [
                  "O koľko menej jednotlivci hodnotia budúce výstupy v porovnaní so súčasnými výstupmi (ich diskontné sadzby)? ",
                  "Postoje jednotlivcov k nežiadúcim účinkom (ako moc odmietajú riskovať)?",
                  "Postoje jednotlivcov k žiadúcim účinkom (ako moc sú ochotní riskovať)."
                ],
                "description": "Okrem toho by panely mohli zvážiť (a prípadne zdokumentovať), do akej miery môže byť pomer žiadúcich a nežiadúcich účinkov ovplyvnený nasledujúcimi úvahami:"
              }
            ],
            "panelDiscussion": ""
          },
          "info": "Aký je pomer žiadúcich a nežiadúcich účinkov s ohľadom na to, ako sú hodnotené hlavné výstupy, ako dôležité sú žiadúce a nežiadúce účinku, aká je istota týchto odhadov, aká je diskontná sadzba, miera odporu rizika a podstúpenia rizika?",
          "options": [
            {
              "text": "V prospech porovnania",
              "value": "favors_comparison"
            },
            {
              "text": "Pravdepodobne v prospech porovnania",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Neuprednostňuje ani intervenciu, ani porovnanie ",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Pravdepodobne uprednostňuje intervenciu",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Uprednostňuje intervenciu",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?",
          "ratingOptions": [
            "best_balance",
            "intermediate",
            "worst_balance"
          ],
          "selectedOption": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalOptions": [
            {
              "text": "líši sa",
              "value": "varies"
            },
            {
              "text": "Neviem / Nevieme",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Uprednostňuje rovnováha medzi žiaducimi a nežiaducimi účinkami na zdravie intervenciu alebo porovnanie?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie ",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie  ",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu ",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Má krátkodobú a dlhodobú <silnú>účinnosť</silnú> (za kontrolovaných, často ideálnych okolností) alebo <silná>účinnosť</silnú> (v reálnom živote) zásahu na <silné>zdravie jednotlivcov</silné>vrátane výsledkov hlásených pacientom uprednostňujú intervenciu alebo porovnanie?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie ",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu ",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Uprednostňuje intervenciu alebo porovnanie krátkodobej a dlhodobej <silnej>účinnosti</silnej> alebo <silný>vplyv</silnú> intervenciu na <silné>zdravie obyvateľstva</silné> vrátane výsledkov uvádzaných príjemcom? (To by malo zahŕňať úvahy o tom, či výsledky na úrovni populácie predstavujú agregované výsledky na individuálnej úrovni, alebo sa objavujú prostredníctvom dynamiky systému.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie ",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu ",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Do akej miery  pacienti/príjemcovia</strong>  rôznych zdravotných výsledkov<strong> hodnotia uprednostnenie intervencie alebo porovnania?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu ",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Uprednostňuje <strong>pravdepodobnosť a závažnosť nepriaznivých účinkov</strong> spojených s intervenciou (vrátane rizika zneužitia intervencie) intervenciu alebo porovnanie?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu ",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Uprednostňujú <strong>širšie pozitívne alebo negatívne vplyvy súvisiace so zdravím</strong> (napr. zníženie stigmy, pozitívny vplyv na iné choroby, vedľajšie účinky mimo pacientov/príjemcov) intervenciu alebo porovnanie?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím väčší je čistý zdravotný prínos spojený s intervenciou, tým väčšia je pravdepodobnosť všeobecného odporúčania v prospech tejto intervencie.",
          "options": [
            {
              "text": "Uprednostňuje porovnanie",
              "value": "favors_the_comparison"
            },
            {
              "text": "Pravdepodobne uprednostňuje porovnanie",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Pravdepodobne uprednostňuje intervenciu ",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "Uprednostňuje intervenciu",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "Žiadne zahrnuté štúdie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aká je celková istota dôkazov o účinkoch testu?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Ako dobre poskytuje výskum predstavu o pravdepodobných účinkoch na všetky kritické výstupy (t.j. aká je pravdepodobnosť, že sa reálne účinky budú natoľko líšiť od výsledkov výskumu, že by to mohlo ovplyvniť rozhodovanie o diagnostickej intervencii?   ",
          "options": [
            {
              "text": "Veľmi nízka",
              "value": "very_low"
            },
            {
              "text": "Nízka",
              "value": "low"
            },
            {
              "text": "Mierna",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "Žiadne zahrnuté štúdie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aká je celková istota dôkazov o účinkoch?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Ako dobre poskytuje výskum predstavu o pravdepodobných účinkoch na všetky kritické výstupy (t.j. aká je pravdepodobnosť, že sa účinky budú natoľko líšiť od výsledkov výskumu, že by to mohlo ovplyvniť rozhodovanie o intervencii?",
          "options": [
            {
              "text": "Veľmi nízka",
              "value": "very_low"
            },
            {
              "text": "Nízka",
              "value": "low"
            },
            {
              "text": "Mierna",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "ratingDescription": "What is the overall certainty of the evidence of effects for each intervention?",
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "Žiadne zahrnuté štúdie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aká je celková istota dôkazov o účinkoch postupu, ktorý sa riadi výsledkami testu?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Veľmi nízka",
              "value": "very_low"
            },
            {
              "text": "Nízka",
              "value": "low"
            },
            {
              "text": "Mierna",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "Žiadne zahrnuté štúdie ",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aká je istota dôkazov o požiadavkách na zdroje (náklady)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Neviem ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Žiaden / Žiadna/ Žiadne",
                    "value": "no"
                  },
                  {
                    "text": "Žiaden/ Žiadna / Žiadne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Boli identifikované všetky dôležité položky využívania zdrojov, ktoré sa môžu medzi zvažovanými možnosťami líšiť?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Neviem ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Dôležitá neistota",
                    "value": "important"
                  },
                  {
                    "text": "Možná dôležitá neistota",
                    "value": "probably_important"
                  },
                  {
                    "text": "Pravdepodobne žiadna dôležitá neistota",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Žiadna dôležitá neistota",
                    "value": "no_important"
                  }
                ],
                "question": "Ako isté sú náklady na položky použitia zdrojov, ktoré sa líšia medzi zvažovanými možnosťami?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Neviem ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Dôležitá variabilita",
                    "value": "important"
                  },
                  {
                    "text": "Možná dôležitá variabilita",
                    "value": "probably_important"
                  },
                  {
                    "text": "Pravdepodobná žiadna dôležitá variabilita",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Žiadna dôležitá variabilita",
                    "value": "no_important"
                  }
                ],
                "question": "Existuje významná variabilita v nákladoch položiek využívaných zdrojov, ktoré sa líšia medzi zvažovanými možnosťami?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Aké isté sú dôkazy o rozdieloch pre každý typ použitia zdrojov (napr. lieky, hospitalizácie) a náklady na zdroje?",
          "options": [
            {
              "text": "Veľmi nízky / nízke ",
              "value": "very_low"
            },
            {
              "text": "Nízka / Nízke / Nízky ",
              "value": "low"
            },
            {
              "text": "Mierny ",
              "value": "moderate"
            },
            {
              "text": "Vysoká/ Vysoký / Veľký / Veľká  ",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "Žiadne zahrnuté štúdie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aká je celková istota dôkazov o presnosti testu?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Veľmi nízky / nízke",
              "value": "very_low"
            },
            {
              "text": "Nízka / Nízke / Nízky",
              "value": "low"
            },
            {
              "text": "Mierny ",
              "value": "moderate"
            },
            {
              "text": "Vysoká/ Vysoký / Veľký / Veľká ",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "Žiadne zahrnuté štúdie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aká je celková istota dôkazov pre kritické alebo dôležité priame prínosy, nežiadúce účinky alebo záťaž testu?  ",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Veľmi nízka",
              "value": "very_low"
            },
            {
              "text": "Nízka",
              "value": "low"
            },
            {
              "text": "Mierna",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "Žiadne zahrnuté štúdie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aká je istota väzby medzi výsledkami testu a rozhodnutím vedenia manažmentu?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Veľmi nízka",
              "value": "very_low"
            },
            {
              "text": "Nízka",
              "value": "low"
            },
            {
              "text": "Mierna",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": "Líši sa",
              "value": "varies"
            },
            {
              "text": "Žiadne zahrnuté štúdie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Uprednostňuje nákladová efektívnosť intervencie samotnú intervenciu alebo porovnanie?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Žiadne zahrnuté štúdie",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Žiaden/ Žiadna/ Žiadne",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne žiaden/ žiadna / žiadne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Je pomer nákladovej efektívnosti citlivý na jednosmerné analýzy citlivosti?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Žiadne zahrnuté štúdie",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Žiaden/ Žiadna/ Žiadne",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne žiaden/ žiadna/ žiadne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Je pomer nákladovej efektívnosti citlivý na analýzu citlivosti viacerých premenných?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Žiadne zahrnuté štúdie",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Žiaden/ Žiadna/ Žiadne",
                    "value": "no"
                  },
                  {
                    "text": "Veľmi závažné obmedzenia",
                    "value": "very_serious"
                  },
                  {
                    "text": "Vážne obmedzenia",
                    "value": "serious"
                  },
                  {
                    "text": "Žiadne závažné obmedzenia",
                    "value": "no_serious"
                  }
                ],
                "question": "Je ekonomické hodnotenie, na ktorom je založený odhad nákladovej efektívnosti, spoľahlivé?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Žiadne zahrnuté štúdie",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Žiaden/ Žiadna/ Žiadne",
                    "value": "no"
                  },
                  {
                    "text": "Veľmi závažné obmedzenia",
                    "value": "very_serious"
                  },
                  {
                    "text": "Vážne obmedzenia",
                    "value": "serious"
                  },
                  {
                    "text": "Žiadne závažné obmedzenia",
                    "value": "no_serious"
                  }
                ],
                "question": "Je ekonomické hodnotenie, na ktorom je založený odhad nákladovej efektívnosti, použiteľné pre prostredie(a) záujmu?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je zásah nákladovo efektívny, berúc do úvahy neistotu alebo variabilitu nákladov, neistotu alebo variabilitu čistého prínosu, analýzy citlivosti a spoľahlivosť a použiteľnosť ekonomického hodnotenia?",
          "options": [
            {
              "text": "Uprednostňuje porovnanie",
              "value": "favors_comparison"
            },
            {
              "text": "Pravdepodobne uprednostňuje porovnanie",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Neuprednostňuje ani intervenciu, ani porovnanie ",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Pravdepodobne uprednostňuje intervenciu ",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Uprednostňuje intervenciu",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Ktorý zásah uprednostňuje efektívnosť nákladov?",
          "ratingOptions": [
            "best_cost_effectiveness",
            "intermediate_cost_effectiveness",
            "worst_cost_effectiveness"
          ],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": "líši sa ",
              "value": "varies"
            },
            {
              "text": "Neviem / Nevieme",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Ak podstatné sú predpokladané žiadúce účinky?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bezvýznamné",
                    "value": "trivial"
                  },
                  {
                    "text": "Malý/ Malá/ Malé",
                    "value": "small"
                  },
                  {
                    "text": "Mierny",
                    "value": "moderate"
                  },
                  {
                    "text": "Veľký / Veľká / Veľké",
                    "value": "large"
                  }
                ],
                "question": "Aký významný je očakávaný účinok (rozdiel) pre každý hlavný výsledok, pre ktorý existuje požadovaný účinok?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Aké veľké sú žiaduce účinky intervencie, berúc do úvahy dôležitosť výsledkov (nakoľko sú hodnotené) a veľkosť účinku (pravdepodobnosť, že sprostredkuje prínos alebo aké zlepšenie by jednotlivci pravdepodobne mohli prežívať)?",
          "options": [
            {
              "text": "Bezvýznamné",
              "value": "trivial"
            },
            {
              "text": "Malý/ Malá/ Malé",
              "value": "small"
            },
            {
              "text": "Mierny",
              "value": "moderate"
            },
            {
              "text": "Veľký / Veľká / Veľké",
              "value": "large"
            }
          ],
          "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
          "ratingOptions": [
            "most_effective",
            "intermediate_effectiveness",
            "least_effective"
          ],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": "Líši sa",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aký bude dopad na rovnosť v zdraví?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Existujú skupiny alebo prostredia, ktoré by mohli byť v súvislosti so zvažovaným problémom alebo možnosťou znevýhodnené? ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Existujú pádne dôvody pre predpokladanie rozdielu v relatívnej účinnosti možnosti u znevýhodnených skupín alebo prostredí?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Existujú v rôznych skupinách alebo prostrediach rôzne vstupné podmienky, ktoré ovplyvňujú absolútnu účinnosť možnosti alebo významu problému pre znevýhodnené skupiny alebo prostredia?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Existujú dôležité hľadiská, ktoré by mali byť pri implementácii intervencie (možnosti) zohľadnené, aby bolo zaistené, že nerovnosti budú pokiaľ možno znížené a nebudú ďalej prehlbované?  ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Existujú pádne dôvody pre predpokladanie rozdielu v relatívnej účinnosti intervencie u znevýhodnených skupín alebo u skupín s rozdielnymi vstupnými podmienkami, ktoré ovplyvňujú absolútnu činnosť intervencie alebo dôležitosť problému?",
          "options": [
            {
              "text": "Zníženie",
              "value": "reduced"
            },
            {
              "text": "Pravdepodobné zníženie",
              "value": "probably_reduced"
            },
            {
              "text": "Pravdepodobne žiadny vplyv",
              "value": "probably_no_impact"
            },
            {
              "text": "Pravdepodobne prehĺbenie",
              "value": "probably_increased"
            },
            {
              "text": "Zvýšenie",
              "value": "increased"
            }
          ],
          "ratingDescription": "Ktorá intervencia by v prípade odporúčania najviac znížila nerovnosti v zdraví?",
          "ratingOptions": [
            "most_reduction",
            "intermediate_reduction",
            "less_reduction"
          ],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": "líši sa",
              "value": "varies"
            },
            {
              "text": "Neviem",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je možné realizovať intervenciu? ",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Žiaden / Žiadna/ Žiadne",
                    "value": "no"
                  },
                  {
                    "text": "Žiaden/ Žiadna / Žiadne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Je intervencia (možnosť) udržateľná?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Žiaden / Žiadna/ Žiadne",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne žiaden/ žiadna / žiadne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Existujú dôležité prekážky, ktoré pravdepodobne obmedzia uskutočniteľnosť implementácie intervencie (možnosti) alebo si vyžadujú zváženie pri jej implementácii?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je možné pokračovať v používaní diagnostickej intervencie a riešiť potenciálne prekážky pri jej používaní?",
          "options": [
            {
              "text": "Žiaden / Žiadna/ Žiadne",
              "value": "no"
            },
            {
              "text": "Pravdepodobne žiaden/ žiadna / žiadne",
              "value": "probably_no"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Áno",
              "value": "yes"
            }
          ],
          "ratingDescription": "Ktorú intervenciu je možné viac implementovať? ",
          "ratingOptions": [
            "most_feasible",
            "intermediate_feasibility",
            "least_feasible"
          ],
          "selectedOption": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalOptions": [
            {
              "text": "líši sa",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je možné realizovať intervenciu?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Existujú <strong>právne prekážky</strong>, které môžu obmedziť vykonateľnosť intervencie?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Áno",
                    "value": "yes"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nie",
                    "value": "no"
                  }
                ],
                "question": "Existujú <strong>riadiace aspekty</strong> (napr. strategické úvahy, predošlé rozhodnutia), ktoré môžu limitovať vykonateľnosť intervencie? (Toto by malo zahŕňať úvahy týkajúce sa prítomnosti, či neprítomnosti formálnych alebo neformálnych inštitúcií ktoré môžu urobiť efektívne vedenie, dohľad a zodpovednosť za implementáciu intervencie v realizovateľnosti.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významné priaznivé dôsledky",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Mierne priaznivé dôsledky",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Zanedbateľné priaznivé a nepriaznivé dôsledky",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Mierne priaznivé dôsledky",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Významné nepriaznivé dôsledky",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Aké sú <strong>dôsledky</strong> intervencie <strong> interakcie a zapasovania do existujúceho zdravotného  systému</strong>? (Toto zahŕňa úvahy o interakci intervencie s alebo o dopade na existujúci zdravotný systém a o jeho zložky.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významné priaznivé dôsledky",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Mierne priaznivé dôsledky",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Zanedbateľné priaznivé a nepriaznivé dôsledky",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Mierne nepriaznivé dôsledky",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Významné nepriaznivé dôsledky",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Aké sú <strong>dôsledky</strong> intervence <strong> pre pracovníkov v zdravotníctve ako aj širšie ľudské zdroje</strong>? (Toto by malo zahŕňať úvahy o dopade intervencie na ľudské zdroje, o ich potrebe a rozdelení.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významné priaznivé dôsledky",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Mierne priaznivé dôsledky",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Zanedbateľné priaznivé a nepriaznivé dôsledky",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Mierne nepriaznivé dôsledky",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Významné nepriaznivé dôsledky",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Aké sú <strong>dôsledky</strong> intervencie <strong>pre infraštruktúru zdravotného systému a širšiu infraštruktúru</strong>? (Toto by malo zahŕňať úvahy o potrebe, využití a dopadne na nie-ľudské zdroje a infraštruktúru, ako aj ich rozdelení.)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím vyššia je vykonateľnosť nejakej varianty z pohľadu všetkých alebo väčšiny zainteresovaných strán, tým vyššia je pravdepodobnosť odporúčania v prospech tejto intervencie. Čím výhodnejšie sú dôsledky pre zdravotný systém ako celok, tým vyššia je pravdepodobnosť odporúčania v prospech intervencie.",
          "options": [
            {
              "text": "Žiadne",
              "value": "no"
            },
            {
              "text": "Pravdepodobne žiadne",
              "value": "probably_not"
            },
            {
              "text": "Nie je isté",
              "value": "uncertain"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Áno",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "feasible": {
          "description": "to identify and measure, and not susceptible to manipulation",
          "options": [],
          "ratingOptions": [
            "very_feasible",
            "feasible",
            "least_feasible"
          ],
          "selectedOptions": ""
        },
        "financialAndEconomicConsiderations": {
          "additionalOptions": [
            {
              "text": "líši sa",
              "value": "varies"
            },
            {
              "text": "Neviem",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Uprednostňujú finančné a ekonomické úvahy intervenciu alebo porovnanie?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Nevie sa",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Uprednostňujú <silný>vplyvy na náklady a rozpočet</silný> zavedenie a udržiavanie intervencie, priaznivej intervencie alebo porovnania? (To by malo zahŕňať úvahy o tom, ako sa menia vplyvy nákladov a rozpočtu v krátkodobom a dlhodobom horizonte.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nevie sa / Nevieme",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Uprednostňuje <silný>celkový dopad intervencie na ekonomiku</silný> intervenciu alebo porovnanie? (To by malo zahŕňať úvahy o tom, ako sú rôzne typy ekonomických dopadov rozdelené medzi rôzne sektory alebo organizačné úrovne, či intervencia prispieva alebo obmedzuje dosiahnutie širších cieľov rozvoja a znižovania chudoby, a ako ovplyvňuje dostupnú pracovnú silu.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Neviem/ Nevieme",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie ",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu ",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu ",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Uprednostňuje <silný>pomer nákladov a prínosov</silný> (napr. na základe odhadov nákladovej efektivity alebo nákladovej užitočnosti) intervenciu alebo porovnanie? ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím výhodnejšie sú finančné a ekonomické dôsledky intervencie, tým väčšia je pravdepodobnosť všeobecného odporúčania v prospech tejto intervencie.",
          "options": [
            {
              "text": "Uprednostňuje porovnanie",
              "value": "favors_the_comparison"
            },
            {
              "text": "Pravdepodobne uprednostňuje porovnanie ",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Neuprednostňuje ani intervenciu, ani porovnanie ",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Pravdepodobne uprednostňuje intervenciu ",
              "value": "probably_favor_the_intervention"
            },
            {
              "text": "Uprednostňuje intervenciu ",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalOptions": [
            {
              "text": "Líši sa",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aký by bol dopad intervencie na spravodlivosť, rovnosť a nediskrimináciu v zdraví?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Žiadna",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne žiadna",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Je pravdepodobnosť, že intervencia zvýši existujúcu nespravodlivosť a/ alebo nerovnosť v zdraví alebo jeho determinant? (toto by malo zahŕňať úvahy o pravdepodobných zmenách v nerovnostiach v priebehu času, napr. či sa úvodné zvýšenia nerovností  postupom času vyrovnajú, ako sa bude intervencia rozširovať?)  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nie",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Je pravdepodobné, že budú prínosy a riziká intervencie rozdelené rovnakým spôsobom? (Toto by malo zahŕňať špeciálnu pozornosť na dôsledky intervencie pre zraniteľné, marginalizované alebo inak sociálne znevýhodnené skupiny obyvateľov.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nie",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Je intervencia finančne dostupná pre dotknuté skupiny obyvateľstva? (Toto by malo zahŕňať dopad na výdaje domácností na zdravie, vrátane katastrofických výdajov domácnosti na zdravie a finančných rizík súvisiacich so zdravím.)   ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nie",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Je intervencia dostupná pre dotknuté skupiny obyvateľov? (Toto by malo zahŕňať úvahy o fyzickej a informačnej dostupnosti.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nie",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Oslovuje intervencia zvlášť závažné (napr. život ohrozujúce, na konci života, postihnutie jednotlivcov s nízkym predchádzajúcim stavom zdravia) alebo vzácne stavy?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nie",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Predstavuje intervencia jedinú dostupnú možnosť? (Toto by malo zahŕňať úvahy o tom, či je intervencia primeraná potrebe a či bude podliehať pravidelnému prieskumu.)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím vyššia je pravdepodobnosť, že interpretácia zvyšuje spravodlivosť a/ alebo rovnosť v zdraví a že znižuje diskrimináciu určitých skupín, tým je vyššia pravdepodobnosť formulácie odporúčaní v prospech tejto intervencie.",
          "options": [
            {
              "text": "Negatívne",
              "value": "negative"
            },
            {
              "text": "Pravdepodobne negatívne",
              "value": "probably_negative"
            },
            {
              "text": "Ani negatívne, ani pozitívne",
              "value": "neither_negative_nor_positive"
            },
            {
              "text": "Pravdepodobne pozitívne",
              "value": "probably_positive"
            },
            {
              "text": "Pozitívne",
              "value": "positive"
            }
          ],
          "selectedOption": ""
        },
        "humanRights": {
          "additionalOptions": [
            {
              "text": "Líši sa",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je intervencia v súlade so všeobecnými štandardami a princípmi dodržiavania ľudských práv? ",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Všetky odporúčania by mali byť v súlade so zásadami ľudských práv a ich všeobecnými princípmi.",
          "options": [
            {
              "text": "Nie",
              "value": "no"
            },
            {
              "text": "Pravdepodobne nie",
              "value": "probably_no"
            },
            {
              "text": "Nie je isté",
              "value": "uncertain"
            },
            {
              "text": "Pravdepodobne je",
              "value": "probably_yes"
            },
            {
              "text": "Áno, je",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problem": {
          "additionalOptions": [
            {
              "text": "líši sa",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je tento problém prioritný?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nie",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Sú dôsledky problému závažné (t.j. ťažké alebo dôležité z hľadiska možných prínosov alebo úspor)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nie",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Je tento problém naliehavý?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nie",
                    "value": "no"
                  },
                  {
                    "text": "Pravdepodobne nie",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravdepodobne áno",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Áno",
                    "value": "yes"
                  }
                ],
                "question": "Je uznaný ako prioritný (napr. na základe politického alebo strategického rozhodnutia)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím je problém závažnejší a naliehavejší, tým je pravdepodobnejšie, že možnosť oslovujúca problém bude mať prioritu.",
          "options": [
            {
              "text": "Nie",
              "value": "no"
            },
            {
              "text": "Pravdepodobne nie",
              "value": "probably_no"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Áno",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "qualityOfEvidence": {
          "additionalOptions": [
            {
              "text": "líši sa  ",
              "value": "varies"
            },
            {
              "text": "Neviem / Nevieme ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aká je celková kvalita dôkazov?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Čím vyššia je kvalita dôkazov naprieč rôznymi kritériami vo WHO-INTEGRATE rámci, tým väčšia je pravdepodobnosť všeobecného odporúčania.",
          "options": [
            {
              "text": "Veľmi nízky / nízke ",
              "value": "very_low"
            },
            {
              "text": "Nízka / Nízke / Nízky  ",
              "value": "low"
            },
            {
              "text": "Mierny ",
              "value": "moderate"
            },
            {
              "text": "Vysoká/ Vysoký / Veľký / Veľká   ",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "relevantDirect": {
          "description": "to the selected problem and in the field of application",
          "options": [],
          "ratingOptions": [
            "very_relevant",
            "relevant",
            "least_relevant"
          ],
          "selectedOptions": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": "líši sa",
              "value": "varies"
            },
            {
              "text": "Neviem",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aké veľké sú požiadavky na zdroje (náklady)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bezvýznamné",
                    "value": "trivial"
                  },
                  {
                    "text": "Malý/ Malá/ Malé",
                    "value": "small"
                  },
                  {
                    "text": "Mierny",
                    "value": "moderate"
                  },
                  {
                    "text": "Veľký / Veľká / Veľké",
                    "value": "large"
                  }
                ],
                "question": "Aký veľký je rozdiel v každej položke zdroja, na ktorú je potrebných menej zdrojov?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Líši sa ",
                    "value": "varies"
                  },
                  {
                    "text": "Neviem",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Veľký / Veľká / Veľké",
                    "value": "large"
                  },
                  {
                    "text": "Mierny",
                    "value": "moderate"
                  },
                  {
                    "text": "Malý/ Malá/ Malé",
                    "value": "small"
                  },
                  {
                    "text": "Bezvýznamné",
                    "value": "trivial"
                  }
                ],
                "question": "Aký veľký je rozdiel v jednotlivých položkách využitia zdrojov, na ktoré sú potrebné ďalšie zdroje?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Aké veľké sú náklady na rozdiel vo využívaní zdrojov medzi intervenciou a porovnaním?",
          "options": [
            {
              "text": "Veľké náklady",
              "value": "large_costs"
            },
            {
              "text": "Stredné náklady",
              "value": "moderate_costs"
            },
            {
              "text": "Zanedbateľné náklady a úspory",
              "value": "negligible_costs_savings"
            },
            {
              "text": "Mierne úspory",
              "value": "moderate_savings"
            },
            {
              "text": "Veľké úspory",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
          "ratingOptions": [
            "less_costs",
            "intermediate_costs",
            "most_costs"
          ],
          "selectedOption": ""
        },
        "responsive": {
          "description": "sensitive to change that means they should be able to capture possible changes in the system",
          "options": [],
          "ratingOptions": [
            "very_responsive",
            "responsive",
            "least_responsive"
          ],
          "selectedOptions": ""
        },
        "scientificallySound": {
          "description": "based on evidence (GRADE criteria) and strongly correlated with the quality of care provided",
          "options": [],
          "ratingOptions": [
            "very_scientifically_sound",
            "scientifically_sound",
            "least_scientifically_sound"
          ],
          "selectedOptions": ""
        },
        "societalImplications": {
          "additionalOptions": [
            {
              "text": "Líši sa",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je z hľadiska pomeru žiadúcich a nežiadúcich spoločenských dôsledkov vhodnejšia intervencia alebo porovnania?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "V prospech porovnania",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hľadiska sociálneho dopadu a sociálnych dôsledkov (napr. zníženie alebo zvýšenie stigmatizácie, ovplyvnenie vzdelávania a sociálnej súdržnosti alebo dosiahnutie rôznych ľudských práv okrem zdravia) vhodnejšia intervencia alebo porovnanie?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hľadiska dopadu na životné prostredie a dôsledky pre ekologickú udržateľnosť (napr. ochrana prírodných zdrojov, zmiernenie klimatických zmien alebo prispôsobenie sa tejto zmene klímy) vhodnejšia intervencia alebo porovnanie?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím väčší je čistý spoločenský prínos spojený s intervenciou, tým vyššia je pravdepodobnosť formulácie odporúčania v prospech tejto intervencie.",
          "options": [
            {
              "text": "Uprednostňuje porovnanie",
              "value": "favors_the_comparison"
            },
            {
              "text": "Pravdepodobne uprednostňuje porovnanie",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Neuprednostňuje ani intervenciu, ani porovnanie ",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Pravdepodobne uprednostňuje intervenciu",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "Uprednostňuje intervenciu",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "socioCulturalAcceptability": {
          "additionalOptions": [
            {
              "text": "Líši sa",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je intervencia prijateľná pre kľúčové zainteresované strany?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významný",
                    "value": "large"
                  },
                  {
                    "text": "Mierny",
                    "value": "moderate"
                  },
                  {
                    "text": "Malý",
                    "value": "small"
                  },
                  {
                    "text": "Bezvýznamný",
                    "value": "trivial"
                  }
                ],
                "question": "Aký podstatný je dopad intervencie z hľadiska zásahu do osobných slobôd (vrátane súkromia a dôstojnosti)? (Miera zásahu sa pohybuje od zanedbateľnej – napr. umožnením voľby (napr. budovanie cyklotrás) až po vysokú – napr. obmedzenie alebo vylúčenie voľby (napr. zákaz cigariet).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Líši sa",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významný",
                    "value": "large"
                  },
                  {
                    "text": "Mierny",
                    "value": "moderate"
                  },
                  {
                    "text": "Malý",
                    "value": "small"
                  },
                  {
                    "text": "Bezvýznamné",
                    "value": "trivial"
                  }
                ],
                "question": "Aký významný je dopad intervencie na autonómiu jednotlivcov, skupín obyvateľov a/alebo organizácie (s ohľadom na ich schopnosť urobiť kompetentné, informované a dobrovoľné rozhodnutie)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hľadiska sociokultúrnej prijateľnosti medzi zamýšľanými príjemcami zhodnejšia intervencia alebo porovnanie?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hľadiska sociokultúrnej prijateľnosti medzi tými, ktorí majú intervenciu implementovať, vhodnejšia intervencia alebo porovnanie?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hľadiska sociokultúrnej prijateľnosti pre ostatné zainteresované strany vhodnejšia intervencia alebo porovnanie?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Uprednostňuje porovnanie",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje porovnanie",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Neuprednostňuje ani intervenciu, ani porovnanie",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravdepodobne uprednostňuje intervenciu",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Uprednostňuje intervenciu",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hľadiska sociokultúrnej prijateľnosti pre všeobecnú verejnosť vhodnejšia intervencia alebo porovnanie?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím väčšia je sociokultúrna prijateľnosť intervencie pre všetkých alebo pre väčšinu relevantných zainteresovaných strán, tým väčšia je pravdepodobnosť odporúčania v prospech tejto intervencie.",
          "options": [
            {
              "text": "Nie",
              "value": "no"
            },
            {
              "text": "Pravdepodobne nie",
              "value": "probably_no"
            },
            {
              "text": "Nie je isté",
              "value": "uncertain"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Áno",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": "Líši sa",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Aký presný je test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Veľmi nepresný",
              "value": "very_inaccurate"
            },
            {
              "text": "Nepresný",
              "value": "inaccurate"
            },
            {
              "text": "Presný",
              "value": "accurate"
            },
            {
              "text": "Veľmi presný",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": "Líši sa ",
              "value": "varies"
            },
            {
              "text": "Nie je známe",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Ako významné sú očakávané nežiadúce účinky?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Líši sa ",
                    "value": "varies"
                  },
                  {
                    "text": "Nie je známe",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významný",
                    "value": "large"
                  },
                  {
                    "text": "Mierny",
                    "value": "moderate"
                  },
                  {
                    "text": "Malý",
                    "value": "small"
                  },
                  {
                    "text": "Bezvýznamný",
                    "value": "trivial"
                  }
                ],
                "question": "Ako významný je predpokladaný účinok na každý hlavný vstup pre ktorý existuje nežiadúci účinok?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Aké veľké sú nežiadúce účinky intervencie s ohľadom na dôležitosť výstupov (ako moc sú cenené) a veľkosť účinku (pravdepodobnosť, že dôjde k prínosu a ako veľké zlepšenie by jednotlivci pravdepodobne pocítili?",
          "options": [
            {
              "text": "Významný",
              "value": "large"
            },
            {
              "text": "Mierny",
              "value": "moderate"
            },
            {
              "text": "Malý",
              "value": "small"
            },
            {
              "text": "Bezvýznamný",
              "value": "trivial"
            }
          ],
          "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
          "ratingOptions": [
            "least_harmful",
            "intermediate",
            "more_harmful"
          ],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Existuje významná neistota alebo možnosť v tom, ako veľmi jednotlivci hodnotia hlavné výstupy? ",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "Dôležitá neistota",
                    "value": "important"
                  },
                  {
                    "text": "Možná dôležitá neistota",
                    "value": "probably_important"
                  },
                  {
                    "text": "Pravdepodobne žiadna dôležitá neistota",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Žiadna dôležitá neistota",
                    "value": "no_important"
                  }
                ],
                "question": "Existuje významná neistota v tom, ako veľmi jednotlivci hodnotia hlavné výstupy?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Ako veľmi jednotlivci hodnotia jednotlivé hlavné vstupy? Je neistota alebo možnosť ohľadne toho, ako hodnotia jednotlivé výstupy, ako dostatočne veľké, aby mohli viesť k rozdielnym rozhodnutiam?",
          "options": [
            {
              "text": "Dôležitá neistota alebo možnosť",
              "value": "important_uncertainty"
            },
            {
              "text": "Možná dôležitá neistota alebo možnosť",
              "value": "possible_important"
            },
            {
              "text": "Pravdepodobne žiadna dôležitá neistota alebo možnosť",
              "value": "probably_no_important"
            },
            {
              "text": "Žiadna významná neistota alebo možnosť",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "Prijateľnosť",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "Vlastné kritérium",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "Vyváženosť účinkov",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
          "criterionId": "balanceOfHealthBenefitsAndHarms",
          "name": "Vyváženosť zdravotných výhod a rizík",
          "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "Istota účinkov",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "Istota dôkazov",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "Istota dôkazov o účinkoch manažmentu",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "Istota dôkazov o požadovaných zdrojoch",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "Istota dôkazu o presnosti testu",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "Istota dôkazov o účinkoch testu",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "Istota dôkazu o výsledku testu/manažmente ",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "Nákladová efektivita",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "Žiadúce účinky",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "Spravodlivosť",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "Realizovateľnosť",
          "researchEvidenceId": "feasibility"
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
          "criterionId": "feasibilityAndHealthSystemConsiderations",
          "name": "Úvahy o uskutočniteľnosti a zdravotníckom systéme",
          "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
        },
        "feasible": {
          "additionalConsiderationId": "feasible",
          "criterionId": "feasible",
          "name": "FEASIBLE",
          "researchEvidenceId": "feasible"
        },
        "financialAndEconomicConsiderations": {
          "additionalConsiderationId": "financialAndEconomicConsiderations",
          "criterionId": "financialAndEconomicConsiderations",
          "name": "Finančné a ekonomické úvahy",
          "researchEvidenceId": "financialAndEconomicConsiderations"
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
          "criterionId": "healthEquityEqualityAndNonDiscrimination",
          "name": "Spravodlivosť v zdraví, rovnosť a nediskriminácia",
          "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
        },
        "humanRights": {
          "additionalConsiderationId": "humanRights",
          "criterionId": "humanRights",
          "name": "Ľudské práva",
          "researchEvidenceId": "humanRights"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "Problém",
          "researchEvidenceId": "problem"
        },
        "qualityOfEvidence": {
          "additionalConsiderationId": "qualityOfEvidence",
          "criterionId": "qualityOfEvidence",
          "name": "Kvalita dôkazov",
          "researchEvidenceId": "qualityOfEvidence"
        },
        "relevantDirect": {
          "additionalConsiderationId": "relevantDirect",
          "criterionId": "relevantDirect",
          "name": "RELEVANT (DIRECT)",
          "researchEvidenceId": "relevantDirect"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "Potrebné zdroje",
          "researchEvidenceId": "resourcesRequired"
        },
        "responsive": {
          "additionalConsiderationId": "responsive",
          "criterionId": "responsive",
          "name": "RESPONSIVE",
          "researchEvidenceId": "responsive"
        },
        "scientificallySound": {
          "additionalConsiderationId": "scientificallySound",
          "criterionId": "scientificallySound",
          "name": "SCIENTIFICALLY SOUND (CERTAINTY OF EVIDENCE)",
          "researchEvidenceId": "scientificallySound"
        },
        "societalImplications": {
          "additionalConsiderationId": "societalImplications",
          "criterionId": "societalImplications",
          "name": "Spoločenské dôsledky",
          "researchEvidenceId": "societalImplications"
        },
        "socioCulturalAcceptability": {
          "additionalConsiderationId": "socioCulturalAcceptability",
          "criterionId": "socioCulturalAcceptability",
          "name": "Sociálno-kultúrna prijateľnosť",
          "researchEvidenceId": "socioCulturalAcceptability"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": "Presnosť testu",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "Nežiadúce účinky",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "Hodnoty",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "benefits": {
          "content": "",
          "description": "Prínosy"
        },
        "clearAndActionable": {
          "content": "",
          "description": "Jasné a vykonateľné",
          "options": [
            {
              "text": "Áno",
              "value": "yes"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Pravdepodobne nie",
              "value": "probably_no"
            },
            {
              "text": "Nie",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "clinicalConsiderations": {
          "content": "",
          "description": "Clinical considerations"
        },
        "collectingAndSummarizing": {
          "content": "",
          "description": "Zber a sumarizácia dôkazov je nedostatočným využívaním obmedzeného času a energie panelu usmernení (príležitostné náklady by vyžadovali veľké vynaloženie)",
          "options": [
            {
              "text": "Áno",
              "value": "yes"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Pravdepodobne nie",
              "value": "probably_no"
            },
            {
              "text": "Nie",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "considerations": {
          "content": "",
          "description": "Úvahy"
        },
        "contextAndSystemConsiderations": {
          "content": "",
          "description": "Kontext a systémové zváženie"
        },
        "decision": {
          "content": "",
          "description": "Rozhodnutie",
          "options": [
            {
              "text": "Plná implementácia",
              "value": "full_implementation"
            },
            {
              "text": "Hodnotenie dopadu ",
              "value": "impact_evaluation"
            },
            {
              "text": "Pilotná štúdia",
              "value": "pilot_study"
            },
            {
              "text": "Odložiť",
              "value": "postpone"
            },
            {
              "text": "Neimplementovať",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "Typ rozhodnutia",
          "options": [
            {
              "direction": "negative",
              "text": "Nepokrýva",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "Kryť s tvorbou dôkazov",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "Kryť vyjednávaním o cene",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "Obmedzené krytie",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "Kryť",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "Typ rozhodnutia",
          "options": [
            {
              "direction": "negative",
              "text": "Nezaviesť možnosť ",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "Odložiť rozhodnutie",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "Vykonať pilotnú štúdiu o tejto možnosti",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "Zavádzať možnosť s hodnotením dopadu",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "Zaviesť možnosť",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "evidence": {
          "content": "",
          "description": "Dôkaz "
        },
        "frequencyDoseDuration": {
          "content": "",
          "description": "Frekvencia/dávka/trvanie"
        },
        "goodPracticeStatement": {
          "content": "",
          "description": "Odporúčanie dobrej praxe"
        },
        "harms": {
          "content": "",
          "description": "Riziká"
        },
        "implementationConsiderations": {
          "content": "",
          "description": "Implementačné úvahy",
          "details": {
            "content": "",
            "description": "Podrobná implementácia "
          }
        },
        "implementationConsiderationsOfAllRelevantOutcomes": {
          "content": "",
          "description": "Po zvážení všetkých relevantných výstupov a možných následných dôsledkov má implementácia o postupoch dobrej prax za následok významné pozitívne prínosy",
          "options": [
            {
              "text": "Áno",
              "value": "yes"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Pravdepodobne nie",
              "value": "probably_no"
            },
            {
              "text": "Nie",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderationsOrRemarks": {
          "content": "",
          "description": "Implementačné úvahy",
          "options": [
            {
              "text": "Áno",
              "value": "yes"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Pravdepodobne nie",
              "value": "probably_no"
            },
            {
              "text": "Nie",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "indications": {
          "content": "",
          "description": "Indikácie"
        },
        "indicationsForDiscontinuation": {
          "content": "",
          "description": "Indikácie pre prerušenie"
        },
        "justification": {
          "content": "",
          "description": "Zdôvodnenie",
          "details": {
            "description": "Podrobné zdôvodnenie"
          },
          "overallDescription": "Súhrnné zdôvodnenie"
        },
        "keyMessage": {
          "content": "",
          "description": "Kľúčový odkaz"
        },
        "limitations": {
          "content": "",
          "description": "Obmedzenia"
        },
        "mdgRecommendation": {
          "content": "",
          "description": "Odporúčanie",
          "options": [
            {
              "direction": "positive",
              "text": "Odporúčané",
              "value": "recommend"
            },
            {
              "direction": "negative",
              "text": "Nie je odporúčané",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Sometimes Recommended",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Žiadne odporúčanie",
              "value": "suggest_either"
            }
          ],
          "selectedOption": ""
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "Sledovanie a hodnotenie",
          "details": {
            "content": "",
            "description": "Podrobné sledovanie a hodnotenie"
          }
        },
        "monitoringAndEvaluationQI": {
          "content": "",
          "description": "Sledovanie a hodnotenie"
        },
        "multipleChronicConditionsAndPolypharmacy": {
          "content": "",
          "description": "Viacnásobné chronické stavy a polyfarmácia "
        },
        "multipleRecommendations": {
          "description": "Odporúčanie(a)",
          "options": [
            {
              "direction": "negative",
              "text": "Silné odporúčanie proti intervencii",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Podmienené odporúčanie proti intervencii",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Podmienené odporúčanie buď proti intervencii alebo pre porovnanie",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Podmienené odporúčanie pre intervenciu",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Silné odporúčanie pre intervenciu",
              "value": "recommend"
            }
          ],
          "recommendations": [
            {
              "content": "",
              "selectedOption": ""
            }
          ]
        },
        "rationale": {
          "content": "",
          "description": "Odôvodnenie"
        },
        "rationaleConnectingIndirectEvidence": {
          "content": "",
          "description": "Existuje dobre zdokumentované jasné a explicitné odôvodnenie prepojené na nepriame dôkazy",
          "options": [
            {
              "text": "Áno",
              "value": "yes"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Pravdepodobne nie",
              "value": "probably_no"
            },
            {
              "text": "Nie",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "recommendation": {
          "content": "",
          "description": "Odporúčanie"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "Typ odporúčania",
          "options": [
            {
              "direction": "negative",
              "text": "Silné odporúčanie proti intervencii",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Podmienené odporúčanie proti intervencii",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Podmienené odporúčanie buď pre intervenciu alebo pre porovnanie",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Podmienené odporúčanie pre intervenciu",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Silné odporúčanie pre intervenciu",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "Typ odporúčania",
          "options": [
            {
              "direction": "negative",
              "text": "Silné odporúčanie proti tejto možnosti",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Podmienené odporúčanie proti tejto možnosti",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Podmienené odporúčanie buď pre túto možnosť alebo pre porovnanie",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Podmienené odporúčanie pre túto možnosť",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Silné odporúčanie pre túto možnosť",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "Výskumné priority"
        },
        "restrictions": {
          "content": "",
          "description": "Obmedzenia"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "Podskupina úvah "
        },
        "theMessageIsNecessary": {
          "content": "",
          "description": "Správa je nevyhnutná s ohľadom na súčasnú zdravotnícku prax",
          "options": [
            {
              "text": "Áno",
              "value": "yes"
            },
            {
              "text": "Pravdepodobne áno",
              "value": "probably_yes"
            },
            {
              "text": "Pravdepodobne nie",
              "value": "probably_no"
            },
            {
              "text": "Nie",
              "value": "no"
            }
          ],
          "selectedOption": ""
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "Klinickí pracovníci (lekári)",
          "sections": {
            "background": {
              "name": "Pozadie"
            },
            "detailedJustification": {
              "name": "Podrobné zdôvodnenie"
            },
            "implementationConsiderations": {
              "name": "Implementačné úvahy"
            },
            "justification": {
              "name": "Zdôvodnenie"
            },
            "relatedRecommendations": {
              "name": "Súvisiace odporúčania"
            },
            "subgroupConsiderations": {
              "name": "Podskupina úvah"
            },
            "summaryOfFindings": {
              "name": "Súhrn zistení"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "Pacienti",
          "sections": {
            "relatedRecommendations": {
              "name": "Súvisiace odporúčania"
            },
            "summaryOfFindings": {
              "name": "SZ (Sumár zistení z angl. SoF - Summary of Findings)"
            },
            "whatItMeansForYou": {
              "name": "Čo toto pre vás znamená?",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Poraďte sa so zdravotníkom"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "Čo môžete robiť?"
                }
              }
            },
            "whoIsThisFor": {
              "name": "Pre koho je toto určené?",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Pre koho je toto určené"
                }
              }
            },
            "whyThisRecommendation": {
              "name": "Prečo toto odporúčanie?",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "Dodatočné informácie"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "Prínosy a riziká"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "Prečo toto odporúčanie"
                }
              }
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "Tvorcovia politík",
          "sections": {
            "assessment": {
              "name": "Hodnotenie"
            },
            "background": {
              "description": "Podrobnosti k otázke odporúčania",
              "details": {
                "name": "O tomto rozhodnutí",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "Dátum"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "Tí, čo robia rozhodnutie"
                  },
                  "perspective": {
                    "content": "",
                    "description": "Perspektíva"
                  },
                  "setting": {
                    "content": "",
                    "description": "Nastavenie"
                  }
                },
                "sectionsOrder": [
                  "setting",
                  "perspective",
                  "decisionMakers",
                  "date"
                ]
              },
              "name": "Pozadie"
            },
            "decision": {
              "content": "",
              "name": "Rozhodnutie",
              "options": [
                {
                  "text": "Plná implementácia",
                  "value": "full_implementation"
                },
                {
                  "text": "Hodnotenie dopadu ",
                  "value": "impact_evaluation"
                },
                {
                  "text": "Pilotná štúdia",
                  "value": "pilot_study"
                },
                {
                  "text": "Odložiť",
                  "value": "postpone"
                },
                {
                  "text": "Neimplementovať",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "Implementácia"
            },
            "justification": {
              "name": "Zdôvodnenie"
            },
            "monitoringAndEvaluation": {
              "name": "Sledovanie & hodnotenie"
            },
            "relatedRecommendations": {
              "name": "Súvisiace odporúčania"
            },
            "summaryOfFindings": {
              "name": "Súhrn zistení"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "age": {
          "content": "",
          "name": "Vek"
        },
        "anticipatedOutcomes": {
          "content": "",
          "name": "Očakávané výstupy"
        },
        "background": {
          "content": "",
          "name": "Pozadie"
        },
        "coi": {
          "content": "",
          "name": "Konflikt záujmov"
        },
        "comparison": {
          "content": "",
          "name": "Porovnanie"
        },
        "intent": {
          "content": "",
          "name": "Zámer"
        },
        "intervention": {
          "content": "",
          "name": "Intervencia"
        },
        "linkedTreatments": {
          "content": "",
          "name": "Súvisiaca liečba"
        },
        "mainOutcomes": {
          "content": "",
          "name": "Hlavné výstupy"
        },
        "option": {
          "content": "",
          "name": "Možnosť"
        },
        "perspective": {
          "content": "",
          "name": "Perspektíva"
        },
        "population": {
          "content": "",
          "name": "Populácia"
        },
        "problem": {
          "content": "",
          "name": "Problém"
        },
        "purpose": {
          "content": "",
          "name": "Účel testu"
        },
        "requirements": {
          "content": "",
          "name": "Requirements"
        },
        "role": {
          "content": "",
          "name": "Úloha testu"
        },
        "setting": {
          "content": "",
          "name": "Nastavenie"
        },
        "subgroups": {
          "content": "",
          "name": "Podskupiny"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
