MultiComparisonsActions = require 'actions/multi_comparisons_actions'
Translation = require 'components/mixins/translation'
IconButton = require 'components/common/icon_button'

SectionNameCell = createReactClass
  displayName: 'SectionNameCell'

  mixins: [ Translation('mc:review_tables') ]

  propTypes:
    section: PropTypes.string.isRequired
    tableName: PropTypes.oneOf(['soj', 'grading']).isRequired
    hidden: PropTypes.bool.isRequired

  toggleSection: ->
    { section, tableName } = @props
    MultiComparisonsActions.toggleReviewTableRow { section, tableName }

  render: ->
    { section, tableName, hidden } = @props
    iconName = if hidden then "eye" else "blind-eye"

    <td className={classNames "review-table__cell", "review-table__cell--section-name", section}>
      {unless hidden
        <span>{@i18n "sections.#{_.string.underscored section}"}</span>
      }
      <IconButton
        className={classNames 'review-table__hide-row-button',
          'review-table__hide-row-button--section-hidden': hidden
        }
        iconName={iconName}
        onClick={@toggleSection}
      />
    </td>

module.exports = SectionNameCell
