Translation = require 'components/mixins/translation'
DbHelper = require 'base/lib/db_helper'

MarketingScreen = createReactClass
  displayName: 'MarketingScreen'

  mixins: [Translation('')]

  propTypes:
    title: PropTypes.string
    descriptions: PropTypes.arrayOf(PropTypes.string).isRequired
    infoLink: PropTypes.string
    readAlsoLink: PropTypes.string
    imgName: PropTypes.string.isRequired
    licenseChildren: PropTypes.node
    children: PropTypes.node

  removeProtocolFromLink: (link) ->
    link.replace(/^https?\:\/\//,'')

  renderLink: (linkDesc, link) ->
    <div>
      {linkDesc}{' '}
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {@removeProtocolFromLink link}
      </a>
    </div>

  render: ->
    {
      title,
      descriptions,
      infoLink,
      readAlsoLink,
      licenseInfoTitle,
      licenseInfoDesc,
      licenseChildren,
      imgName,
      children
    } = @props

    <div className="marketing-screen">
      <div className="marketing-screen__texts">
        {title and <h3>{title}</h3>}
        <div className="marketing-screen__description">
          {_.map descriptions, (desc, idx) ->
            <p key={"description#{idx}"}>{desc}</p>
          }
        </div>

        <div className="marketing-screen__links">
          {infoLink and @renderLink @i18n('marketing.more_info_on'), infoLink}
          {readAlsoLink and @renderLink @i18n('marketing.read_also'), readAlsoLink}
        </div>

        <div className="marketing-screen__license-info-box">
          <h3>{licenseInfoTitle}</h3>
          <p dangerouslySetInnerHTML={{__html: licenseInfoDesc}} />
          {licenseChildren}
        </div>

        {children}
      </div>

      <div className="marketing-screen__img">
        <img src={DbHelper.getStaticAssetsUrl "/marketing/#{imgName}"} />
      </div>
    </div>

module.exports = MarketingScreen
