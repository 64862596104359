DbHelper = require 'base/lib/db_helper'
RadioInput = require 'components/common/radio_input'
Translation = require 'components/mixins/translation'
{ DA_ILLUSTRATIONS, DA_THEMES } = require 'lib/da_helper'


AppearanceConfiguratorOption = createReactClass
  displayName: 'AppearanceConfiguratorOption'

  propTypes:
    type: PropTypes.oneOf(['illustrations', 'themes']).isRequired
    value: PropTypes.oneOf(R.concat DA_ILLUSTRATIONS, DA_THEMES)
    isSelected: PropTypes.bool
    onChange: PropTypes.func

  mixins: [Translation('')]

  getDefaultProps: ->
    isSelected: false

  getImagePath: ->
    DbHelper.getStaticAssetsUrl(
      "/decision-aids/#{@props.type}/#{_.str.underscored @props.value}.svg"
    )

  getLabel: ->
    @i18n "da:#{@props.type}.#{_.str.underscored @props.value}"

  render: ->
    { type, value, isSelected, onChange } = @props

    <div className='decision-aids-appearance-select__option'>
      <RadioInput
        checked={isSelected}
        name={type}
        onChange={onChange}
        value={value}
        id={value}
        readOnly={not onChange?}
       />
      <label htmlFor={value}>
        <img src={@getImagePath()} alt={@getLabel()} />
      </label>
    </div>

module.exports = AppearanceConfiguratorOption
