Input = require 'components/common/input'
IconButton = require 'components/common/icon_button'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'
{ DndProvider } = ReactDnD
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
SidebarChapterItem = require 'components/document_sections/v2/sidebar_chapter_item'
SidebarChapterForm = require 'components/document_sections/v2/sidebar_chapter_form'

NON_EDITING_CHAPTERS_ROLES = ['mda_mab', 'mda_mar', 'mdg_panel_reviewer', 'evolent_panel_reviewer']

SidebarChapters = createReactClass
  displayName: 'SidebarChapters'

  mixins: [
    Translation('docsec:management')
  ]

  propTypes:
    chapters: PropTypes.instanceOf(Immutable.Map).isRequired
    chaptersOrder: PropTypes.instanceOf(Immutable.List).isRequired
    mdaTopicId: PropTypes.string

  getInitialState: () ->
    showNewChapterInput: false

  handleShowNewChapterInput: () ->
    @setState
      showNewChapterInput: true

  closeNewChapterEdit: ->
    @setState
      showNewChapterInput: false

  handleNewChapterSave: ({ title, slug }) ->
    DocumentSectionsV2Actions.createChapter(mediator.project.id, title, slug)
    @setState
      showNewChapterInput: false

  ###
  Render chapters recursively.

  chaptersIds
    Chapter identifiers in proper order.
  ancestors
    Array containing all chapter's ancestors (chapter's parent, parent's parent, etc.).
  data
    Object containing numeric index value of given chapter item.
    (we want to keep proper index during multiple recursive invocations)
  ###
  renderItems: (chaptersIds, ancestors = [], data = {index: 0}) ->
    { chapters, mdaTopicId } = @props

    <ol className="sidebar-chapters-items">
      {
        chaptersIds.map (chapterId) =>
          currentIndex = data.index
          data.index++
          chapter = chapters.get chapterId
          return null unless chapter
          subchaptersIds = chapter.get 'subchapters'
          parent = chapter.get 'parent'
          currentAncestors = ancestors.concat(parent || [])
          <li key={chapterId} className="sidebar-chapters-list-item">
            <SidebarChapterItem
              ancestors={currentAncestors}
              chapter={chapter}
              index={currentIndex}
              mdaTopicId={mdaTopicId}
              withEditButtons={@userHasPossibilityToEditChapters()}
            />
            {
              if !subchaptersIds.isEmpty()
                @renderItems subchaptersIds, currentAncestors, data
            }
          </li>
      }
    </ol>

  userHasPossibilityToEditChapters: ->
    not mediator.user.hasRole NON_EDITING_CHAPTERS_ROLES

  render: ->
    { chaptersOrder, mdaTopicId } = @props
    { showNewChapterInput } = @state

    <div className="sidebar-chapters flex flex-col overflow-auto h-full">
      {@userHasPossibilityToEditChapters() && <div className="sidebar-chapters-item-add">
          <span
            className="sidebar-chapters-item-add-text"
            onClick={@handleShowNewChapterInput}
          >
            + {@i18n 'add_new_chapter'}
          </span>
        </div>
      }
      <div className="sidebar-chapters-items-container overflow-auto">
        <DndProvider backend={ReactDnDHTML5Backend['default']}>
          {@renderItems(chaptersOrder)}
        </DndProvider>
      </div>
      {showNewChapterInput && (
        <SidebarChapterForm
          mdaTopicId={mdaTopicId}
          onCancel={@closeNewChapterEdit}
          onSubmit={@handleNewChapterSave}
        />
      )}
    </div>

module.exports = SidebarChapters
