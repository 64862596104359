var MultiComparisonsActions, QuestionGroupsActions, QuestionsActions, Router, alt, generateGUID, getComparisonData, mediator, multiComparisonPrefix, persistenceAdapter, _save;

alt = require('alt');

mediator = require('mediator');

generateGUID = require('base/lib/utils').generateGUID;

QuestionsActions = require('actions/questions_actions');

QuestionGroupsActions = require('actions/question_groups_actions');

multiComparisonPrefix = require('lib/doc_prefixes').multiComparison;

persistenceAdapter = mediator.services.storePersistenceAdapter;

Router = require('router');

_save = function(data) {
  return persistenceAdapter.save(mediator.project.id, data);
};

getComparisonData = function() {
  return alt.stores.MultiComparisonsStore.getComparisonsEditData().toJS();
};

MultiComparisonsActions = (function() {
  function MultiComparisonsActions() {
    this.generateActions('fetchSuccess', 'fetchFailure', 'fetchDetailsSuccess', 'fetchDetailsFailure', 'fetchEtdsTemplateDataSuccess', 'fetchEtdsTemplateDataFailure', 'addEtdTemplateData', 'removeComparisonQuestion', 'updateOverarchingQuestion', 'updateInterventionsComparableDesc', 'saveInBackgroundSuccess', 'saveComparisonFailure', 'updateMultiComparison', 'showExportDialog', 'hideExportDialog');
  }

  MultiComparisonsActions.prototype.dbChange = function(_arg) {
    var doc;
    doc = _arg.doc;
    if (_.string.startsWith(doc._id, multiComparisonPrefix)) {
      return this.actions.updateMultiComparison(doc);
    }
  };

  MultiComparisonsActions.prototype.fetch = function() {
    this.dispatch();
    persistenceAdapter.fetchWithKeyPrefix(mediator.project.id, multiComparisonPrefix).then((function(_this) {
      return function(res) {
        var docs;
        docs = _(res.rows).map(function(r) {
          return r.doc;
        });
        return _this.actions.fetchSuccess(docs);
      };
    })(this))["catch"]((function(_this) {
      return function(error) {
        return _this.actions.fetchFailure(error);
      };
    })(this));
    QuestionsActions.fetch();
    return QuestionGroupsActions.fetch();
  };

  MultiComparisonsActions.prototype.fetchDetails = function(_arg) {
    var mcId;
    mcId = _arg.mcId;
    this.dispatch();
    return persistenceAdapter.fetch(mediator.project.id, mcId).then((function(_this) {
      return function(mcDoc) {
        var mcData, recIds;
        mcData = Immutable.fromJS(mcDoc);
        recIds = mcData.get('selectedQuestions').flatMap(function(question) {
          return question.get('recommendationIds', Immutable.List());
        });
        _this.actions.fetchEtdsTemplateData({
          etdIds: recIds.toJS()
        });
        _this.actions.fetchDetailsSuccess(mcData);
        return mcData;
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        return _this.actions.fetchDetailsFailure(err);
      };
    })(this));
  };

  MultiComparisonsActions.prototype.fetchEtdsTemplateData = function(_arg) {
    var etdIds;
    etdIds = _arg.etdIds;
    this.dispatch();
    return persistenceAdapter.fetch(mediator.project.id, etdIds).then((function(_this) {
      return function(res) {
        var etdsTemplateData;
        etdsTemplateData = (res.rows || {}).reduce(function(result, row) {
          result[row.doc._id] = row.doc.templateData;
          return result;
        }, {});
        return _this.actions.fetchEtdsTemplateDataSuccess(etdsTemplateData);
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        return _this.actions.fetchEtdsTemplateDataFailure(err);
      };
    })(this));
  };

  MultiComparisonsActions.prototype.editMultiComparison = function(mcId, editInCollapsableView) {
    var comparisonData, recIds;
    if (editInCollapsableView == null) {
      editInCollapsableView = false;
    }
    if (!editInCollapsableView) {
      comparisonData = alt.stores.MultiComparisonsStore.getComparisonData(mcId);
      recIds = comparisonData.get('selectedQuestions').flatMap(function(question) {
        return question.get('recommendationIds', Immutable.List());
      });
      this.actions.fetchEtdsTemplateData({
        etdIds: recIds.toJS()
      });
    }
    return this.dispatch({
      mcId: mcId,
      editInCollapsableView: editInCollapsableView
    });
  };

  MultiComparisonsActions.prototype.selectComparisonQuestion = function(questionData) {
    var etdId, questionId;
    questionId = questionData.get('questionId');
    etdId = questionData.get('recommendationIds', Immutable.List()).first();
    if (alt.stores.MultiComparisonsStore.isEditingComparisonInCollapsableView()) {
      return this.dispatch(questionData);
    } else {
      persistenceAdapter.fetch(mediator.project.id, etdId).then((function(_this) {
        return function(doc) {
          _this.actions.addEtdTemplateData({
            etdId: etdId,
            etdTemplateData: doc.templateData
          });
          return _this.dispatch(questionData);
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          return _this.actions.fetchFailure(err);
        };
      })(this));
      return null;
    }
  };

  MultiComparisonsActions.prototype.updateInterventionRating = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.updateComparisonQuestion = function(_arg) {
    var etdId, newQuestionData, oldQuestionId, questionId;
    oldQuestionId = _arg.oldQuestionId, newQuestionData = _arg.newQuestionData;
    questionId = newQuestionData.get('questionId');
    etdId = newQuestionData.get('recommendationIds', Immutable.List()).first();
    if (alt.stores.MultiComparisonsStore.isEditingComparisonInCollapsableView()) {
      return this.dispatch({
        oldQuestionId: oldQuestionId,
        newQuestionData: newQuestionData
      });
    } else {
      persistenceAdapter.fetch(mediator.project.id, etdId).then((function(_this) {
        return function(doc) {
          _this.actions.addEtdTemplateData({
            etdId: etdId,
            etdTemplateData: doc.templateData
          });
          return _this.dispatch({
            oldQuestionId: oldQuestionId,
            newQuestionData: newQuestionData
          });
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          return _this.actions.fetchFailure(err);
        };
      })(this));
      return {};
    }
  };

  MultiComparisonsActions.prototype.updateRecommendationText = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.setRecommendationStrength = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.updateSectionImportanceForDecision = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.updateSectionGradingComments = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.addRecommendation = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.removeRecommendation = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.moveSelectedQuestion = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.moveSelectedIntervention = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.updateComparisonsTextAttribute = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.toggleReviewTableRow = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.toggleInterventionColumn = function(data) {
    this.dispatch(data);
    return this.actions.saveInBackground();
  };

  MultiComparisonsActions.prototype.createNewComparison = function() {
    var route;
    route = Router.prototype.getProjectRelativeUrl("/multi-comparisons/create");
    mediator.publish('!router:route', route);
    return this.dispatch();
  };

  MultiComparisonsActions.prototype.createComparison = function() {
    var data;
    this.dispatch();
    data = getComparisonData();
    if (!data._id) {
      data._id = "" + multiComparisonPrefix + (generateGUID());
    }
    return _save(data).then(this.actions.createComparisonSuccess)["catch"](this.actions.saveComparisonFailure);
  };

  MultiComparisonsActions.prototype.createComparisonSuccess = function(data) {
    this.actions.goToComparisonsList();
    return this.dispatch(data);
  };

  MultiComparisonsActions.prototype.saveComparison = function(navigateToListOnSuccess) {
    if (navigateToListOnSuccess == null) {
      navigateToListOnSuccess = false;
    }
    _save(getComparisonData()).then(this.actions.saveComparisonSuccess).then((function(_this) {
      return function() {
        if (navigateToListOnSuccess) {
          return _this.actions.goToComparisonsList();
        }
      };
    })(this))["catch"](this.actions.saveComparisonFailure);
    return this.dispatch();
  };

  MultiComparisonsActions.prototype.saveComparisonSuccess = function(data) {
    return this.dispatch(data);
  };

  MultiComparisonsActions.prototype.saveInBackground = function() {
    return _save(getComparisonData()).then(this.actions.onSaveInBackgroundSuccess)["catch"](this.actions.saveComparisonFailure);
  };

  MultiComparisonsActions.prototype["delete"] = function(mcId) {
    this.dispatch();
    return this.actions.saveComparison();
  };

  MultiComparisonsActions.prototype.goToComparisonsList = function() {
    var route;
    route = Router.prototype.getProjectRelativeUrl("/multi-comparisons");
    mediator.publish('!router:route', route);
    return this.dispatch();
  };

  MultiComparisonsActions.prototype.goToMultiComparisonsTable = function(goToMCId) {
    var route;
    route = Router.prototype.getProjectRelativeUrl("/multi-comparisons/" + goToMCId + "/details");
    return mediator.publish('!router:route', route);
  };

  return MultiComparisonsActions;

})();

module.exports = alt.createActions(MultiComparisonsActions);
