CheckboxInput = require 'components/common/checkbox_input'
Input = require 'components/common/input'
EditButton = require 'components/common/edit_button'
DeleteButton = require 'components/common/delete_button'
SaveButton = require 'components/common/save_button'

Todo = createReactClass
  displayName: 'Todo'

  propTypes:
    text: PropTypes.string.isRequired
    completed: PropTypes.bool.isRequired
    isEditing: PropTypes.bool.isRequired
    onEdit: PropTypes.func.isRequired
    onDelete: PropTypes.func.isRequired
    onSave: PropTypes.func.isRequired
    readOnly: PropTypes.bool

  toggleCompleted: ->
    @props.onSave completed: not @props.completed

  getText: -> @input.value if @input?

  onSave: ->
    text = @input.value
    @props.onSave { text }
    @saved = true

  componentDidUpdate: (prevProps, prevState) ->
    # focus input when switched to edit mode
    @input.focus() if @props.isEditing

  componentDidMount: ->
    @input.focus() if @props.isEditing

  renderTodoText: (text) ->
    textClass = classNames 'strike-through': @props.completed

    <div className='todo-text'>
      {if @props.isEditing
        <Input inputRef={(el) => @input = el} defaultValue={text} />
      else
        <span className={textClass}>{text}</span>
      }
    </div>

  renderTodoButtons: ->
    return null if @props.readOnly
    if @props.isEditing
      <div className='todo-buttons'>
        <DeleteButton onClick={@props.onDelete} />
        <SaveButton onClick={@onSave} />
      </div>
    else
      <div className='todo-buttons'>
        <EditButton onClick={@props.onEdit} />
      </div>

  render: ->
    { completed, text } = @props

    <div className='todo'>
      <div className='toggle'>
        {unless @props.isEditing
          <CheckboxInput
            checked={completed}
            onChange={@toggleCompleted}
            disabled={@props.readOnly}
          />
        }
      </div>
      {@renderTodoText(text)}
      {@renderTodoButtons()}
    </div>

module.exports = Todo
