Translation = require 'components/mixins/translation'
{ vancouverFormat } = require 'lib/references_utils'
mediator = require 'mediator'
{ instanceOf, func } = PropTypes

DuplicatesComparison = createReactClass
  displayName: 'DuplicatesComparison'

  propTypes:
    duplicates: instanceOf(Immutable.List).isRequired
    mergeDuplicates: func.isRequired
    keepDuplicates: func.isRequired

  mixins: [
    Translation('references:buttons')
  ]

  getInitialState: ->
    refChecks: @_getRefChecks()

  _getRefChecks: ->
    @props.duplicates.reduce (result, ref) ->
      result[ref.get '_id'] = false
      result
    , {}

  toggleReference: (referenceId) -> =>
    refChecks = _.extend {}, @state.refChecks
    refChecks[referenceId] = not refChecks[referenceId]

    @setState { refChecks }

  _isReferenceDeduplicated: (reference) ->
    reference.get('deduplicationStatus') in ['kept', 'merged']

  _getCheckedReferences: ->
    checkedIds = (id for id, checked of @state.refChecks when checked)
    @props.duplicates.filter (ref) -> ref.get('_id') in checkedIds

  mergeSelected: ->
    @props.mergeDuplicates @_getCheckedReferences()
    @setState refChecks: {}

  keepSelected: ->
    @props.keepDuplicates @_getCheckedReferences()
    @setState refChecks: {}

  renderReference: (reference) ->
    referenceId = reference.get '_id'
    referneceText = vancouverFormat reference.toJS()

    <div key={referenceId} className='duplicate-reference'>
      <label>
        <div>
          <input
            type='checkbox'
            checked={@state.refChecks[referenceId]}
            onChange={@toggleReference referenceId}
            disabled={@_isReferenceDeduplicated reference}
          />
        </div>
        <div>
          <span>{referneceText}</span>
        </div>
      </label>
    </div>

  render: ->
    buttonsEnabled = do =>
      _(@state.refChecks).chain().values().compact().value().length > 1

    <div>
      <div className='comparator-area'>
        {@props.duplicates.map(@renderReference)}
      </div>
      <div className='buttons'>
        <button
          disabled={not buttonsEnabled}
          className='btn btn-cancel'
          onClick={@keepSelected}
        >
          {@i18n 'keep_selected'}
        </button>
        <button
          className='btn'
          disabled={not buttonsEnabled}
          onClick={@mergeSelected}
        >
          {@i18n 'merge_selected'}
        </button>
      </div>
    </div>

module.exports = DuplicatesComparison
