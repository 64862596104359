Translation = require 'components/mixins/translation'

FinishedOutcomesTable = createReactClass
  displayName: 'FinishedOutcomesTable'

  mixins: [
    Translation('scope:outcomes')
  ]

  propTypes:
    outcomes: PropTypes.instanceOf(Immutable.List).isRequired

  getOutcomesByImportance: ->
    byImportance = @props.outcomes.groupBy (o) -> o.get 'importance'

    Immutable.Map
      included: byImportance.get('critical', Immutable.List()).concat(
        byImportance.get 'important', Immutable.List()
      )
      excluded: byImportance.get 'notImportant', Immutable.List()

  renderGroupedOutcomes: (outcomes, groupType) ->
    <table className='outcomes-approval' key={groupType}>
      <thead>
        <tr>
          <th className='outcome-name'>
            {@i18n groupType} ({outcomes.size})
          </th>
        </tr>
      </thead>
      <tbody>
        {outcomes.map (outcome, idx) ->
          <tr key={idx}>
            <td>
              {outcome.get 'name'}
            </td>
          </tr>
        }
      </tbody>
    </table>

  render: ->
    <div className='finished-outcomes-container'>
      {@getOutcomesByImportance().map(@renderGroupedOutcomes).toList()}
    </div>

module.exports = FinishedOutcomesTable
