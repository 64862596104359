Button = require 'components/common/button'
MDGTopicActions = require 'actions/mdg_topic_actions'
mediator = require 'mediator'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

MDGTopicsToolbar = ->
  projectId = mediator.project.id
  i18n = useI18n('settings:mdg_topics')

  onCreate = useCoffeeCallback [MDGTopicActions, projectId], ->
    MDGTopicActions.create projectId

  <div className="mdg-topics-toolbar px-20">
    <h2>{i18n 'title'}</h2>
    <Button className="btn btn-apply" label={i18n 'create_topic'} onClick={onCreate} />
  </div>

module.exports = MDGTopicsToolbar
