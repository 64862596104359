CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
{ getMemberAccessRights, getMemberPendingAccessRights } = require 'lib/members_helper'

{string, func, instanceOf, bool} = PropTypes

DefaultCell = (props) ->
  <td {...props}>{props.text}</td>

LabelCell = createReactClass

  displayName: 'LabelCell'

  propTypes:
    el: string.isRequired
    title: string
    tooltip: string

  mixins: [CustomRenderMixin]

  render: ->
    <Tooltip title={@props.tooltip}>
      {React.createElement @props.el, @props}
    </Tooltip>

SortableLabelCell = createReactClass

  displayName: 'SortableLabelCell'

  propTypes:
    name: string.isRequired
    onSort: func.isRequired
    sortDirection: string

  mixins: [CustomRenderMixin]

  getWrapper: ->
    sortDirectionClass = classNames 'sort', _.string.dasherize @props.sortDirection
    <div className='sortable-wrapper' onClick={@props.onSort}>
      {@props.children}
      <span className={sortDirectionClass}></span>
    </div>

  render: ->
    <Tooltip title={@props.tooltip}>
      {React.createElement @props.el, {}, @getWrapper()}
    </Tooltip>

PanelMemberHeaderCell = createReactClass

  displayName: 'PanelMemberHeaderCell'

  propTypes:
    name: string.isRequired

  mixins: [CustomRenderMixin, Translation('team:member')]

  getWrapper: ->
    <span className='with-info'>
      {@props.children}
      <Tooltip tooltipType="info" tipJoint="bottom left" targetJoint="top right">
        <span
          className="info-sign"
          title={@i18n "panel_member_description"} />
      </Tooltip>
    </span>

  render: ->
    React.createElement @props.el, {}, @getWrapper()

NameCell = createReactClass

  displayName: "NameCell"

  propTypes:
    el: string
    member: instanceOf(Immutable.Map).isRequired

  getDefaultProps: ->
    el: 'td'

  mixins: [Translation('team:member'), CustomRenderMixin]

  getName: ->
    member = @props.member
    name = ""
    name += @i18n("titles.#{member.get('title')}") + " " if member.get('title')
    # TODO - for some reason 'givenNames' is set and it's value is `null`
    name += (member.get('givenNames', '') ? '') + " " ? ''
    name += member.get('lastName') ? ''

  getElement: ->
    <a className="member-name" href={"mailto:#{@props.member.get('email')}"}>{@getName()}</a>

  render: ->
    title = _.string.escapeHTML(@i18n 'send_email', to: @getName())
    <Tooltip title={title}>
      {React.createElement @props.el, title: title, @getElement()}
    </Tooltip>

AccessRightsCell = createReactClass

  displayName: 'AccessRightsCell'

  mixins: [Translation('team:member_access_rights'), CustomRenderMixin]

  propTypes:
    member: instanceOf(Immutable.Map).isRequired
    el: string

  getDefaultProps: ->
    el: 'td'

  _getSelectedAccessRights: ->
    { member } = @props
    isPendingInvitation = member.get 'pendingInvitation'
    if isPendingInvitation
      getMemberPendingAccessRights member
    else
      getMemberAccessRights member

  render: ->
    accessRights = @_getSelectedAccessRights()

    text = if @props.member.get('pendingInvitation')
      @i18n('/team:member.pending_invitation') + ' - '
    else if @props.member.get('invitationRejected')
      @i18n('/team:member.invitation_declined') + ' - '
    else
      ''

    text += if not _.isEmpty accessRights
      @i18n "#{accessRights}.label"
    else
      ''
    React.createElement @props.el, {className: @props.className}, text

module.exports = {
  DefaultCell
  LabelCell
  NameCell
  AccessRightsCell
  SortableLabelCell
  PanelMemberHeaderCell
}
