var DnDActions, DragDropTypes;

DnDActions = require('actions/react_dnd_actions');

DragDropTypes = require('components/scope/outcomes/drag_drop_types');

module.exports = {
  dragSpec: {
    canDrag: function(props, monitor) {
      return !props.readOnly;
    },
    beginDrag: function(props, monitor, component) {
      var gIndex, key, outcome;
      gIndex = props.gIndex, outcome = props.outcome;
      key = outcome.get('key');
      return {
        gIndex: gIndex,
        key: key
      };
    },
    isDragging: function(props, monitor) {
      return monitor.getItem().key === props.outcome.get('key');
    },
    endDrag: function(props, monitor) {
      return DnDActions.endDrag({
        type: DragDropTypes.APPROVAL_TAB_OUTCOME
      });
    }
  },
  dropSpec: {
    hover: function(props, monitor, component) {
      var dragGindex, dragKey, hoverKey, _ref;
      _ref = monitor.getItem(), dragKey = _ref.key, dragGindex = _ref.gIndex;
      hoverKey = props.outcome.get('key');
      if (dragKey === hoverKey) {
        return;
      }
      if (dragGindex !== props.gIndex) {
        return;
      }
      return DnDActions.hover({
        hoverType: DragDropTypes.APPROVAL_TAB_OUTCOME,
        hoverImportance: props.outcome.get('importance'),
        gIndex: dragGindex,
        hoverKey: hoverKey,
        dragKey: dragKey
      });
    },
    canDrop: function() {
      return false;
    }
  },
  dragCollect: function(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget()
    };
  }
};
