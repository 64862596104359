Confirmation = require 'components/common/confirmation'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
Modal = require 'components/common/modal'
SearchBar = require 'components/common/search_bar'
SpinnerButton = require 'components/common/spinner_button'
Translation = require 'components/mixins/translation'
{ bool, func, instanceOf, number } = PropTypes

USAGE_DATE_FORMAT = 'MMM Do HH:mm'

ReferencesHeader = createReactClass
  displayName: 'ReferencesHeader'

  mixins: [ Translation('references:buttons') ]

  propTypes:
    activeSearch: instanceOf(Immutable.Map).isRequired
    duplicatesCount: number
    isFetchingUsedReferences: bool.isRequired
    onAddReference: func.isRequired
    onDeleteAll: func
    onFetchUsage: func.isRequired
    onImportReferences: func.isRequired
    onSearch: func.isRequired
    onSearchReset: func.isRequired
    usedReferencesFetchTime: number

  getInitialState: ->
    showDeleteConfirmation: false

  hideDeleteConfirmation: ->
    @setState showDeleteConfirmation: false

  onDelete: (evt) ->
    evt.stopPropagation()
    @setState showDeleteConfirmation: true

  doDeleteAllReferences: ->
    @props.onDeleteAll()
    @setState showDeleteConfirmation: false

  getUsageTooltip: ->
    { usedReferencesFetchTime } = @props
    if usedReferencesFetchTime
      @i18n 'fetch_usage_tooltip_with_date',
        date: moment(usedReferencesFetchTime).format USAGE_DATE_FORMAT
    else
      @i18n 'fetch_usage_tooltip'

  render: ->
    <div className='references-header-container'>
      <SearchBar
        searchOnChange
        containerClass='references-search'
        onSearch={@props.onSearch}
        onSearchReset={@props.onSearchReset}
        searchText={@props.activeSearch.get 'searchText'}
      />
      {if @props.duplicatesCount
        <IconButton
          iconName='duplicate'
          label={@i18n 'duplicates'}
          title={@i18n 'duplicates_tooltip', duplicatesCount: @props.duplicatesCount}
          onClick={-> mediator.publish '!router:route',
            "/projects/#{mediator.project.id}/references/duplicates"
          }
        />
      }
      <div className='buttons'>
        <SpinnerButton
          className="btn"
          label={@i18n 'fetch_usage'}
          loading={@props.isFetchingUsedReferences}
          onClick={@props.onFetchUsage}
          title={@getUsageTooltip()}
        />
        <button
          className="btn btn-danger"
          onClick={@onDelete}
          disabled={not @props.onDeleteAll? or @props.activeSearch.get('isActive')}
        >
          {@i18n 'delete_all_references'}
        </button>
        <button className="btn" onClick={@props.onAddReference}>
          {@i18n 'add_manually'}
        </button>
        <button className="btn btn-import" onClick={@props.onImportReferences}>
          {@i18n 'import_from_file'}
        </button>
      </div>
      {if @state.showDeleteConfirmation and @props.onDeleteAll?
        <Modal className='confirmation-modal-react' onClose={@hideDeleteConfirmation} isOpen>
          <Confirmation
            question={@i18n '../delete_all_references_confirmation'}
            onConfirm={@doDeleteAllReferences}
            onCancel={@hideDeleteConfirmation}
          />
        </Modal>
      }
    </div>

module.exports = ReferencesHeader
