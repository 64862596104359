var number, optional, shape;

shape = require('lib/db_docs/field_types/shape');

number = require('lib/db_docs/field_types/built_in_types').number;

optional = shape.typeDecorators.optional;

module.exports = {
  noOfStudies: optional(number)
};
