var GeneralInformationDoc, MdgPublicationApi, appUtils, generalInformationDocId, generateActions, mediator, sequence;

appUtils = require('lib/app_utils');

generateActions = require('actions/utils/generate_actions');

GeneralInformationDoc = require('lib/db_docs/general_information_doc');

generalInformationDocId = require('lib/doc_ids').GENERAL_INFORMATION;

MdgPublicationApi = require('actions/async/mdg_publication_api');

mediator = require('mediator');

sequence = require('when/sequence');

module.exports = generateActions({
  autoGenerate: ['closePublishDialog', 'fetchError', 'fetchSuccess', 'openPublishDialog', 'publishError', 'publishSuccess', 'setPublishProgressMaxCount', 'updatePublishProgress'],
  name: 'MDGPublicationActions',
  actions: {
    dbChange: function(data) {
      if (!(data.id === generalInformationDocId || data.id === mediator.project.id)) {
        return;
      }
      return this.dispatch(data);
    },
    publish: function() {
      var projectId;
      this.dispatch();
      projectId = mediator.project.id;
      return mediator.services.documentSectionsExportService["export"]({
        projectId: projectId
      }).then((function(_this) {
        return function(_arg) {
          var chapters, guidelineDoc, guidelinePayload, sections;
          guidelineDoc = _arg[0], chapters = _arg[1], sections = _arg[2];
          _this.actions.setPublishProgressMaxCount(1 + chapters.length + sections.length);
          guidelinePayload = {
            allChaptersIds: _.pluck(chapters, 'id'),
            allSectionIds: _.pluck(sections, 'id'),
            guideline: guidelineDoc
          };
          return MdgPublicationApi.publishDoc('guideline', projectId, guidelineDoc.id, guidelinePayload).then(function() {
            _this.actions.updatePublishProgress();
            return sequence(_.map(chapters, function(chapter) {
              return function() {
                return MdgPublicationApi.publishDoc('chapter', projectId, chapter.id, chapter).then(function() {
                  return _this.actions.updatePublishProgress();
                });
              };
            }));
          }).then(function() {
            return sequence(_.map(sections, function(section) {
              return function() {
                return MdgPublicationApi.publishDoc('section', projectId, section.id, section).then(function() {
                  return _this.actions.updatePublishProgress();
                });
              };
            }));
          }).then(function() {
            mediator.project.save({
              lastMdgUpload: guidelineDoc.lastModified
            });
            return _this.actions.publishSuccess();
          });
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          _this.actions.publishError(err);
          return appUtils.errorHandlerWithMsg(err, $.t('dbep:mdg_publication_screen.publish_error'));
        };
      })(this));
    },
    fetch: function(_arg) {
      var dbId;
      dbId = _arg.dbId;
      return GeneralInformationDoc.at(dbId).fetch().then((function(_this) {
        return function(generalInformationDoc) {
          var lastMdgUpload;
          lastMdgUpload = mediator.project.get('lastMdgUpload');
          return _this.actions.fetchSuccess({
            generalInformationDoc: generalInformationDoc,
            lastMdgUpload: lastMdgUpload
          });
        };
      })(this))["catch"](function(err) {
        if (err.status === 404) {
          return appUtils.errorHandlerWithMsg(err, $.t('dbep:mdg_publication_screen.fetch_error_no_general_information'));
        } else {
          return appUtils.errorHandler(err);
        }
      });
    }
  }
});
