var DbDoc, rootDocShape;

DbDoc = require('lib/db_docs/db_doc');

rootDocShape = require('lib/db_docs/doc_shapes/visual_guidelines_root_doc_shape');

module.exports = DbDoc(rootDocShape, {
  methods: {
    addChapter: function(chapterId) {
      return this.updateAt('chaptersOrder')(function(chaptersOrder) {
        return chaptersOrder.concat(chapterId);
      });
    },
    deleteChapter: function(deletableChapterId) {
      return this.updateAt('chaptersOrder')(function(chaptersOrder) {
        return chaptersOrder.filter(function(chapterId) {
          return chapterId !== deletableChapterId;
        });
      });
    },
    setRootChaptersOrder: function(rootChapterIds) {
      return this.setAt('chaptersOrder')(rootChapterIds);
    },
    updateTableData: function(tableData) {
      return this.setAt('interactiveTableData')(tableData);
    },
    removeRecommendation: function(recommendationId) {
      return this.updateAt('interactiveTableData')(function(tableData) {
        return _.extend({}, tableData, {
          recommendations: _.omit(tableData.recommendations, recommendationId)
        });
      });
    }
  }
});
