var ProjectsScreenOnboarding, Router, hopscotchTemplate, mediator;

mediator = require('mediator');

hopscotchTemplate = require('views/templates/panel_voice_hopscotch');

Router = require('router');

ProjectsScreenOnboarding = (function() {
  function ProjectsScreenOnboarding() {
    window.hopscotch.templates.gdt = hopscotchTemplate;
    window.hopscotch.setRenderer('gdt');
    this._toggleActiveTargetClass = this._toggleActiveTargetClass.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleTourEnd = this.handleTourEnd.bind(this);
    this.stepProps = {
      placement: 'bottom',
      xOffset: 'center',
      arrowOffset: 'center',
      width: 340,
      showPrevButton: true,
      onShow: this._toggleActiveTargetClass,
      onNext: this.handleStepChange,
      onPrev: this.handleStepChange
    };
  }

  ProjectsScreenOnboarding.prototype.startTour = function(stepNo) {
    if (stepNo == null) {
      stepNo = 0;
    }
    this._tour = this._constructTour();
    return window.hopscotch.startTour(this._tour, stepNo);
  };

  ProjectsScreenOnboarding.prototype.routeAndStartTour = function() {
    var projectsListURL, routeParams;
    if (_.str.endsWith(window.location.hash, 'projects')) {
      return this.startTour();
    } else {
      projectsListURL = mediator.activeWorkspace === 'personal' ? '/projects' : "/organizations/" + mediator.activeWorkspace + "/projects";
      routeParams = {
        onboardingTourAutostart: true
      };
      return mediator.publish('!router:route', projectsListURL, {
        routeParams: routeParams
      });
    }
  };

  ProjectsScreenOnboarding.prototype._toggleActiveTargetClass = function() {
    var _ref;
    return (_ref = this._currTarget) != null ? _ref.classList.toggle('onboarding-target') : void 0;
  };

  ProjectsScreenOnboarding.prototype.handleStepChange = function() {
    this._toggleActiveTargetClass();
    mediator.publish('!projects-screen-onboarding:stepChange');
    return this._currTarget = window.hopscotch.getCurrTarget();
  };

  ProjectsScreenOnboarding.prototype.handleTourEnd = function() {
    mediator.unsubscribe(null, null, 'walkthrough');
    this._toggleActiveTargetClass();
    this._currTarget = null;
    return mediator.publish('!projects-screen-onboarding:end');
  };

  ProjectsScreenOnboarding.prototype._i18nContent = function(stepNo) {
    return $.t("projects:projects_screen_onboarding.step_" + stepNo);
  };

  ProjectsScreenOnboarding.prototype._constructTour = function() {
    return {
      id: 'projects-screen-onboarding',
      templateToUse: 'gdt',
      onStart: (function(_this) {
        return function() {
          return _this._currTarget = window.hopscotch.getCurrTarget();
        };
      })(this),
      onClose: this.handleTourEnd,
      onEnd: this.handleTourEnd,
      steps: [
        _.extend(Object.create(this.stepProps), {
          content: this._i18nContent(1),
          target: '.tabs-container .tabspane .tab.active'
        }), _.extend(Object.create(this.stepProps), {
          content: this._i18nContent(2),
          target: '.tabs-container .tabspane .tab.copies'
        }), _.extend(Object.create(this.stepProps), {
          content: this._i18nContent(3),
          target: '.PopupBox_Content .user-menu__container',
          showNextButton: false
        })
      ]
    };
  };

  return ProjectsScreenOnboarding;

})();

module.exports = ProjectsScreenOnboarding;
