var DecisionAidView, DecisionAids, DecisionAidsActions, DecisionAidsHeader, DecisionAidsMarketingScreen, DisseminationToolbar, ReactComponent, ReactToolbarView, View, alt, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

alt = require('alt');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

DecisionAidsMarketingScreen = require('components/decision_aid/decision_aids_marketing_screen');

DecisionAids = require('components/decision_aid/decision_aids');

DecisionAidsActions = require('actions/decision_aids_actions');

DisseminationToolbar = require('components/dissemination/dissemination_toolbar');

ReactToolbarView = require('views/react_toolbar_view');

DecisionAidsHeader = require('components/decision_aid/decision_aids_header');

module.exports = DecisionAidView = (function(_super) {
  __extends(DecisionAidView, _super);

  function DecisionAidView() {
    this.handlePreview = __bind(this.handlePreview, this);
    return DecisionAidView.__super__.constructor.apply(this, arguments);
  }

  DecisionAidView.prototype.container = '#page-container';

  DecisionAidView.prototype.autoRender = true;

  DecisionAidView.prototype.initialize = function() {
    var _ref;
    DecisionAidView.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    if (mediator.services.switches.isOn('decisionAids')) {
      return this.subview('toolbar', new ReactToolbarView({
        component: DecisionAidsHeader,
        props: {
          screen: (_ref = this.options.submoduleScreen) != null ? _ref : 'list',
          onPreview: this.handlePreview,
          daId: this.options.itemId
        }
      }));
    } else {
      return this.subview('toolbar', new ReactToolbarView({
        component: DisseminationToolbar,
        props: {
          submodule: this.options.submodule
        }
      }));
    }
  };

  DecisionAidView.prototype.handlePreview = function() {
    var daId, projectId;
    projectId = mediator.project.id;
    daId = this.options.itemId;
    return mediator.services.decisionAidsService.publish(daId).then(function(res) {
      var path, timestamp, url;
      timestamp = res.timestamp;
      path = "/decision-aids/#/" + projectId + "/" + daId + "/" + timestamp;
      url = window.gdt.debugMode ? "https://gdt.test.evidenceprime.com" + path : path;
      window.open(url, '_blank');
      window.opener = null;
      return window.focus();
    })["catch"]((function(_this) {
      return function(err) {
        utils.reportRavenError(err);
        return mediator.dialogs.error(_this.i18n('da:decision_aid_preview_error'));
      };
    })(this));
  };

  DecisionAidView.prototype.afterRender = function() {
    var _ref;
    DecisionAidView.__super__.afterRender.apply(this, arguments);
    this._stopInputsPropagation = false;
    if (mediator.services.switches.isOn('decisionAids')) {
      return this.renderComponent(DecisionAids, DecisionAidsActions, null, {
        fetchAndListen: {
          dbId: mediator.project.id
        },
        props: {
          screen: (_ref = this.options.submoduleScreen) != null ? _ref : 'list',
          decisionAidId: this.options.itemId
        }
      });
    } else {
      return this.renderSimpleComponent(DecisionAidsMarketingScreen);
    }
  };

  DecisionAidView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('QuestionsStore', 'DecisionAidsStore');
    return DecisionAidView.__super__.dispose.apply(this, arguments);
  };

  return DecisionAidView;

})(View);
