var AdaptiveLayout, HtmlExport, IEView, QUESTIONS_VIEW_TYPE_TO_I18N_KEY, QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE, QuestionExportView, Rm5Export, defaultMargins, defaultOrientation, etdHelper, exportToFile, mediator, template, utils, _ref, _ref1,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

IEView = require('views/import_export_view');

template = require('views/templates/question_export');

mediator = require('mediator');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

HtmlExport = require('lib/html_export');

Rm5Export = require('lib/rm5_export');

_ref = require('lib/export_to_file'), exportToFile = _ref.exportToFile, defaultMargins = _ref.defaultMargins, defaultOrientation = _ref.defaultOrientation;

utils = require('base/lib/utils');

etdHelper = require('lib/etd_helper');

_ref1 = require('lib/questions_helper'), QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE = _ref1.QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE, QUESTIONS_VIEW_TYPE_TO_I18N_KEY = _ref1.QUESTIONS_VIEW_TYPE_TO_I18N_KEY;

module.exports = QuestionExportView = (function(_super) {
  __extends(QuestionExportView, _super);

  function QuestionExportView() {
    return QuestionExportView.__super__.constructor.apply(this, arguments);
  }

  QuestionExportView.prototype.id = 'outcomes-export-dialog';

  QuestionExportView.prototype.template = template;

  QuestionExportView.prototype.outcomesExportSize = 7;

  QuestionExportView.prototype.exceedExportSize = false;

  QuestionExportView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  QuestionExportView.prototype.typeToi18nKeyMap = QUESTIONS_VIEW_TYPE_TO_I18N_KEY;

  QuestionExportView.prototype.typeToPrintoutMap = QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE;

  QuestionExportView.prototype.offsetY = 50;

  QuestionExportView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = this.offsetY;
    QuestionExportView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.cancel', this.dispose);
    this.delegate('click', 'button.download', this.download);
    this.delegate('change', '.select-all', this.toggleAll);
    this.delegate('change', 'input[type="checkbox"]', this.toggleSelected);
    this.delegate('change', 'input[name="exportOutcomes"]', this.outcomeToggled);
    this.delegate('change', 'input[name="formatType"]', this._formatSelected);
    this.delegate('change', 'input[name="etdViewType"]', this.toggleDownloadButton);
    return this.enable(AdaptiveLayout);
  };

  QuestionExportView.prototype.fixLayout = function() {
    var availableHeight, notifierContainerHeight, otherElementsHeight, outcomesList, outcomesListHeight;
    availableHeight = $(window).height() - this.offsetY;
    notifierContainerHeight = this.$el.parents('.notifier-inner').outerHeight(true);
    outcomesList = this.$('.outcomes-to-export');
    otherElementsHeight = notifierContainerHeight - outcomesList.height();
    if (notifierContainerHeight > availableHeight) {
      outcomesListHeight = availableHeight - otherElementsHeight;
      return outcomesList.height(outcomesListHeight);
    }
  };

  QuestionExportView.prototype._getCheckedDownloadFormat = function() {
    return this.$('input[name="formatType"]:checked').val();
  };

  QuestionExportView.prototype._getCheckedOutcomesNumber = function() {
    return this.$("input[name=exportOutcomes]:checked").length;
  };

  QuestionExportView.prototype.isEtdExportViewChecked = function() {
    if (!this._isRecommendationsExport()) {
      return true;
    }
    if (this.$('input[name="etdViewType"]').length === 0) {
      return true;
    }
    return this.$('input[name="etdViewType"]:checked').val();
  };

  QuestionExportView.prototype.outcomeToggled = function(e) {
    this.$lastToggledOutcome = $(e.target);
    this.toggleDownloadButton();
    if (this._getCheckedOutcomesNumber() > this.outcomesExportSize) {
      return this.outcomesExportSizeExceeded((function(_this) {
        return function(confirmed) {
          if (!confirmed) {
            _this.$lastToggledOutcome.prop('checked', false);
          }
          if (confirmed) {
            return _this.exceedExportSize = true;
          }
        };
      })(this));
    }
  };

  QuestionExportView.prototype.outcomesExportSizeExceeded = function(cb) {
    var message;
    if (this.exceedExportSize) {
      return cb(true);
    }
    message = $.t('projects:export_dialog.outcomes_size_exceeded', {
      outcomesExportSize: this.outcomesExportSize
    });
    return mediator.dialogs.confirm({
      yesClass: 'danger',
      message: message
    }, cb);
  };

  QuestionExportView.prototype._isRecommendationOrDiagnosticExport = function() {
    return this.model.isDiagnostic() || this._isRecommendationsExport();
  };

  QuestionExportView.prototype.toggleDownloadButton = function() {
    var buttonEnabled;
    buttonEnabled = (this._isRecommendationOrDiagnosticExport() || this._getCheckedOutcomesNumber() > 0) && this._getCheckedDownloadFormat() && this.isEtdExportViewChecked();
    return this.$('button.download').prop('disabled', !buttonEnabled);
  };

  QuestionExportView.prototype.toggleAll = function() {
    var outcomes, selectAllInput;
    selectAllInput = this.$el.find('.select-all input')[0];
    outcomes = this.$el.find('input[name="exportOutcomes"]');
    if (selectAllInput.checked && outcomes.length > 7) {
      return this.outcomesExportSizeExceeded((function(_this) {
        return function(confirmed) {
          _this.exceedExportSize = confirmed;
          outcomes.prop('checked', confirmed);
          return $(selectAllInput).prop('checked', confirmed);
        };
      })(this));
    } else {
      return outcomes.prop('checked', selectAllInput.checked);
    }
  };

  QuestionExportView.prototype.showResultingDataOnly = function() {
    return this.$('input[name="showResultingDataOnly"]').is(":checked");
  };

  QuestionExportView.prototype._formatSelected = function() {
    var checkedFormat, orientationCannotBeSet;
    checkedFormat = this._getCheckedDownloadFormat();
    orientationCannotBeSet = checkedFormat === 'rm5';
    this.$('.orientations-list input').prop('disabled', orientationCannotBeSet);
    this.$('.orientations-list label').toggleClass('label-disabled', orientationCannotBeSet);
    this.$('button.download').html($.t('projects:export_dialog.button_download'));
    return this.toggleDownloadButton();
  };

  QuestionExportView.prototype._selectDefaultOrientation = function() {
    defaultOrientation = this._getViewType().orientation;
    return this.$(".orientations-list input[value='" + defaultOrientation + "']").prop('checked', true);
  };

  QuestionExportView.prototype._getViewType = function() {
    if (this._isRecommendationsExport()) {
      return {
        name: this.options.submodule,
        orientation: defaultOrientation,
        margins: defaultMargins
      };
    } else {
      return mediator.services.esView.getPreferredType(mediator.project, this.model.get('type'));
    }
  };

  QuestionExportView.prototype._isRecommendationsExport = function() {
    return /recommendations/.test(this.options.submodule);
  };

  QuestionExportView.prototype._getAppendices = function() {
    var appendices, insertedElements;
    appendices = [];
    insertedElements = $('#recommendations').find('div.inserted').toArray();
    insertedElements.images = 0;
    insertedElements.recordedTableTypes = [];
    _(insertedElements).forEach(function(insertedElement) {
      var appendixType, hasEmbeddedContent;
      hasEmbeddedContent = $(insertedElement).find('img').length || $(insertedElement).find('table').length;
      if (!hasEmbeddedContent) {
        return;
      }
      appendixType = insertedElement.getAttribute('appendix-type');
      if (appendixType === 'image') {
        return appendices.push({
          id: insertedElement.getAttribute('image-id'),
          isImage: true,
          name: "Image " + (++insertedElements.images),
          section: _.str.humanize(insertedElement.getAttribute('section')).toUpperCase()
        });
      } else {
        if (__indexOf.call(insertedElements.recordedTableTypes, appendixType) < 0) {
          insertedElements.recordedTableTypes.push(appendixType);
          return appendices.push({
            id: appendixType
          });
        }
      }
    });
    return appendices;
  };

  QuestionExportView.prototype.download = function(e, renderOptions) {
    var el, etdViewMode, exportInitParams, exportParams, fileName, isIetd, margins, orientation, outcomesOrAppendicesIds, recommendationType, rm5Export, selectedOutcomes, selectedOutputFormat, service, viewType, _ref2, _ref3;
    if (renderOptions == null) {
      renderOptions = {};
    }
    viewType = this._getViewType();
    outcomesOrAppendicesIds = this._isRecommendationsExport() ? (function() {
      var _i, _len, _ref2, _results;
      _ref2 = this.$("input[name=exportAppendix]:checked");
      _results = [];
      for (_i = 0, _len = _ref2.length; _i < _len; _i++) {
        el = _ref2[_i];
        _results.push($(el).attr('id'));
      }
      return _results;
    }).call(this) : (function() {
      var _i, _len, _ref2, _results;
      _ref2 = this.$("input[name=exportOutcomes]:checked");
      _results = [];
      for (_i = 0, _len = _ref2.length; _i < _len; _i++) {
        el = _ref2[_i];
        _results.push($(el).attr('id'));
      }
      return _results;
    }).call(this);
    fileName = "" + (this.model.get('question').substring(0, 100));
    orientation = this.$('.orientations-list input:checked').val();
    if (this._isRecommendationsExport()) {
      etdViewMode = this.$('.view-options input:checked').val();
    }
    exportInitParams = {
      outcomesOrAppendicesIds: outcomesOrAppendicesIds,
      etdViewMode: etdViewMode
    };
    selectedOutputFormat = this._getCheckedDownloadFormat();
    switch (selectedOutputFormat) {
      case 'html':
      case 'doc':
      case 'pdf':
        this.$('button.download').prop('disabled', true).addClass('loading').forceRedraw();
        exportParams = _.extend({}, exportInitParams, {
          showResultingDataOnly: this.showResultingDataOnly(),
          renderOptions: renderOptions,
          word: selectedOutputFormat === 'doc' ? true : void 0,
          etdVersion: (_ref2 = this.options) != null ? _ref2.etdVersion : void 0,
          votingStarted: (_ref3 = this.options) != null ? _ref3.votingStarted : void 0
        });
        margins = viewType.margins;
        return new HtmlExport(this.model, this.typeToPrintoutMap[viewType.name], exportParams).getSource().then(function(exportContent) {
          return exportToFile(selectedOutputFormat, exportContent, {
            fileName: fileName,
            orientation: orientation,
            margins: margins
          });
        }).then((function(_this) {
          return function() {
            return _this.dispose();
          };
        })(this)).ensure((function(_this) {
          return function() {
            return _this.$('button.download').removeClass('loading');
          };
        })(this)).done();
      case 'rm5':
        selectedOutcomes = this.model.get('outcomes').filter(function(outcome) {
          var _ref4;
          return _ref4 = outcome.cid, __indexOf.call(outcomesOrAppendicesIds, _ref4) >= 0;
        });
        rm5Export = new Rm5Export(this.model, this.typeToPrintoutMap[viewType.name].toLowerCase(), selectedOutcomes);
        rm5Export["export"]();
        return this.dispose();
      case 'jsonld':
        this.$('button.download').prop('disabled', true).addClass('loading').forceRedraw();
        isIetd = mediator.project.get('recommendationsMode') === 'static';
        recommendationType = isIetd ? 'ietd' : 'v1';
        service = this.model.isDiagnostic() ? mediator.services.jsonldDiagnosticExport : mediator.services.jsonldManagementExport;
        return service["export"](this.model.toJSON(), recommendationType).then(function(_arg) {
          var exportedQuestion;
          exportedQuestion = _arg[0];
          return exportToFile('jsonld', exportedQuestion, {
            fileName: fileName
          });
        }).then((function(_this) {
          return function() {
            return _this.dispose();
          };
        })(this)).ensure((function(_this) {
          return function() {
            return _this.$('button.download').removeClass('loading');
          };
        })(this)).done();
    }
  };

  QuestionExportView.prototype.afterRender = function() {
    QuestionExportView.__super__.afterRender.apply(this, arguments);
    if (this.model.get('outcomes').models.length <= this.outcomesExportSize) {
      this.$('input[name=exportOutcomes]').prop('checked', true);
    }
    this._selectDefaultOrientation();
    this.toggleDownloadButton();
    this.markSelected();
    return this.fixLayout();
  };

  QuestionExportView.prototype.getTemplateData = function() {
    var additionalData, outcome, viewTypeName, _ref2;
    viewTypeName = this._getViewType().name;
    additionalData = this._isRecommendationsExport() && viewTypeName !== 'recommendations-initial' ? {
      appendices: (_ref2 = etdHelper.getAppendicesList()) != null ? _ref2 : this._getAppendices(),
      etdViewOptions: etdHelper.getExportViewOptions()
    } : {
      outcomes: (function() {
        var _i, _len, _ref3, _results;
        _ref3 = this.model.get('outcomes').models;
        _results = [];
        for (_i = 0, _len = _ref3.length; _i < _len; _i++) {
          outcome = _ref3[_i];
          _results.push({
            id: outcome.cid,
            name: outcome.get('name'),
            importanceLabel: outcome.get('importanceLabel')
          });
        }
        return _results;
      }).call(this)
    };
    return _(QuestionExportView.__super__.getTemplateData.apply(this, arguments)).extend({
      viewTypeName: viewTypeName,
      outcomesExportSize: this.outcomesExportSize,
      displayOutcomes: !this._isRecommendationOrDiagnosticExport(),
      jsonLdEnabled: !this._isRecommendationsExport(),
      title: $.t('projects:export_dialog.export_table_title', {
        viewName: $.t("es:outcome_view_type." + this.typeToi18nKeyMap[viewTypeName])
      }),
      isAdolopment: mediator.project.isAdolopment()
    }, additionalData);
  };

  return QuestionExportView;

})(IEView);
