var DnDActions, DragDropTypes, QuestionsListComponentActions;

DnDActions = require('actions/react_dnd_actions');

DragDropTypes = require('components/evidence_syntheses/questions_list/drag_drop_types');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

module.exports = {
  dropSpec: {
    hover: function(props, monitor) {
      if (!monitor.isOver({
        shallow: true
      })) {
        return;
      }
      return DnDActions.hover({
        type: DragDropTypes.QUESTION,
        hoveredGroupId: props.groupId,
        draggingQuestionId: monitor.getItem().qId
      });
    }
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver()
    };
  }
};
