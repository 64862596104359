var documentId, documentIdWithAutoGUID, emptyIdStringParser, extendedParse, generateGUID;

extendedParse = require('lib/db_docs/field_types/type_decorators').extendedParse;

documentId = require('lib/db_docs/field_types/document_id');

generateGUID = require('base/lib/utils').generateGUID;

emptyIdStringParser = R.cond([[R.isEmpty, generateGUID], [R.T, R.identity]]);

documentIdWithAutoGUID = extendedParse(emptyIdStringParser)(documentId);

module.exports = documentIdWithAutoGUID;
