CustomRenderMixin = require 'components/mixins/custom_render_mixin'
RadioInput = require 'components/common/radio_input'
RadioWithLabel = require 'components/common/radio_with_label'
TextAreaWithApply = require 'components/common/text_area_with_apply'

RadioOptions = createReactClass
  displayName: 'ExtractionFormRadioOptions'

  mixins: [CustomRenderMixin]

  propTypes:
    fieldKey: PropTypes.string.isRequired
    withOther: PropTypes.bool
    value: PropTypes.oneOfType([
      PropTypes.string
      PropTypes.bool
    ])
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string
        PropTypes.bool
      ]).isRequired
      textKey: PropTypes.string.isRequired
    })).isRequired
    helperTextFromI18n: PropTypes.bool
    placeholder: PropTypes.string
    i18n: PropTypes.func.isRequired
    onChange: PropTypes.func.isRequired

  getDefaultProps: ->
    withOther: false
    helperTextFromI18n: false
    placeholder: ''

  render: ->
    {
      fieldKey
      value
      options
      onChange
      i18n
      withOther
      helperTextFromI18n
      placeholder
    } = @props

    isOtherChecked = not _.isEmpty(value) and not _.contains(_.pluck(options, 'value'), value)

    <div className="radio-options-container">
      {_.map options, ({ value: option, textKey }) ->
        <RadioWithLabel
          key={"#{option}"}
          checked={value is option}
          name={fieldKey}
          label={i18n "values.#{fieldKey}.#{textKey}"}
          value={"#{option}"}
          onChange={-> onChange option}
        />
      }
      {withOther and <div className="other">
        <RadioInput
          checked={isOtherChecked}
          name={fieldKey}
          value="other"
        />
        <TextAreaWithApply
          onApply={onChange}
          content={if isOtherChecked then value else ''}
          placeholder={placeholder}
        />
      </div>}
      {helperTextFromI18n and <div className="helper-text">
        {i18n "helperTexts.#{fieldKey}"}
      </div>}
    </div>

module.exports = RadioOptions
