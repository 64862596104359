QuestionRow = require 'components/scope/questions/question_approval_row'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
dndSpec = require 'components/scope/questions/question_approval_dnd_spec'
DragDropTypes = require 'components/scope/questions/drag_drop_types'
{ getDragWrapper } = require 'lib/react_dnd'
QuestionDrag = getDragWrapper DragDropTypes.APPROVAL_TAB_QUESTION, dndSpec

ApprovalQuestion = createReactClass
  displayName: 'ApprovalQuestion'

  propTypes:
    question: PropTypes.instanceOf(Immutable.Map).isRequired
    qIndex: PropTypes.number.isRequired
    groupType: PropTypes.string.isRequired

  mixins: [
    CustomRenderMixin
  ]

  render: ->
    id = @props.question.get 'id'
    { groupType, qIndex, readOnly } = @props

    <QuestionDrag id={id} qIndex={qIndex} groupType={groupType} canDrag={not readOnly}>
      <tbody className={groupType}>
        <QuestionRow question={@props.question} groupType={groupType} />
      </tbody>
    </QuestionDrag>

module.exports = ApprovalQuestion
