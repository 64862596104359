Handlebars.registerHelper('diagnosticQuestionHeader', function(buttonHelperName, options) {
  var buttonHelper, i18nTempl, params;
  if (!_.isString(buttonHelperName)) {
    options = buttonHelperName;
    buttonHelperName = null;
  }
  buttonHelper = buttonHelperName ? Handlebars.helpers[buttonHelperName] : function(name, title) {
    return "<button class='input-button " + name + "-comparator-test " + name + "' title='" + ($.t(title)) + "'></button>";
  };
  params = {
    indexTest: "<span class=\"input-with-button with-footnotes\"> <input type=\"text\" name=\"indexTest\" id=\"indexTest\"> <span class=\"sup indexTest\"></span> " + (buttonHelper('add', 'es:question.add_comparator_test')) + " </span>",
    comparatorTest: "<span class=\"input-with-button with-footnotes\"> <input type=\"text\" name=\"comparatorTest\" id=\"comparatorTest\"> <span class=\"sup comparatorTest\"></span> " + (buttonHelper('remove', 'es:question.remove_comparator_test')) + " </span>",
    targetCondition: '<span class=\"with-footnotes\"> <input type="text" name="targetCondition" id="targetCondition"> <span class=\"sup targetCondition\"></span> </span>',
    diagnosticVerb: '<select name="diagnosticVerb" id="diagnosticVerb"></select>'
  };
  if (this.comparatorTestPresent) {
    i18nTempl = 'es:question.templates.diagnostic_vs_edit';
    params.indexTest = "<span class=\"with-footnotes\"> <input type=\"text\" name=\"indexTest\" id=\"indexTest\"> <span class=\"sup indexTest\"></span> </span>";
    params.healthProblemOrPopulation = '<span class=\"with-footnotes\"> <input type="text" name="healthProblemOrPopulation" id="healthProblemOrPopulation"> <span class=\"sup healthProblemOrPopulation\"></span> </span>';
  } else {
    i18nTempl = 'es:question.templates.diagnostic_edit';
    params.healthProblemOrPopulation = '<span class="with-footnotes"> <input class="longer" type="text" name="healthProblemOrPopulation" id="healthProblemOrPopulation"> <span class="sup healthProblemOrPopulation"></span> </span>';
  }
  return new Handlebars.SafeString($.t(i18nTempl, params));
});

Handlebars.registerHelper('managementQuestionHeader', function() {
  var params;
  params = {
    intervention: '<span class="with-footnotes"> <input type="text" name="intervention" id="intervention"> <span class="sup intervention"></span> </span>',
    comparison: '<span class="with-footnotes"> <input type="text" name="comparison" id="comparison"> <span class="sup comparison"></span> </span>',
    forOrIn: '<select name="questionFormat" id="questionFormat"></select>',
    healthProblemOrPopulation: '<span class="with-footnotes"> <input type="text" name="healthProblemOrPopulation" id="healthProblemOrPopulation"> <span class="sup healthProblemOrPopulation"></span> </span>'
  };
  return new Handlebars.SafeString($.t('es:question.templates.general', params));
});

Handlebars.registerHelper('prognosticQuestionHeader', function() {
  var params;
  params = {
    healthCondition: '<span class="with-footnotes"> <input type="text" name="healthCondition" id="healthCondition"> <span class="sup healthCondition"></span> </span>',
    population: '<select class="prognostic" name="population" id="population"></select> <span class="with-footnotes populationOther"> <input type="text" name="populationOther" id="populationOther"> <span class="sup populationOther"></span> </span>',
    overTime: '<span class="with-footnotes"> <input type="text" name="overTime" id="overTime"> <span class="sup overTime"></span> </span>'
  };
  return new Handlebars.SafeString($.t('prognosis:question.templates.general', params));
});
