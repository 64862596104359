CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Checkbox = require 'components/common/checkbox_input'
EllipsizedText = require 'components/common/ellipsized_text'
DisabledElementTooltip = require 'components/common/disabled_element_tooltip'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
Router = require 'router'
mediator = require 'mediator'

QuestionItem = createReactClass
  displayName: 'QuestionItem'

  mixins: [CustomRenderMixin, Translation('voting:voting')]

  navigateToEtd: (evt) ->
    evt.preventDefault()
    mediator.publish '!router:route', @props.link

  render: ->
    tooltip = if @props.isDisabled then @i18n 'tooltip_disabled_question' else null
    checked = @props.checked and not @props.isDisabled

    <li className='question'>
      {if @props.toggle
        <div className='toggle'>
          <DisabledElementTooltip>
            <Checkbox disabled={@props.isDisabled}
              title={tooltip}
              checked={checked}
              onChange={@props.toggle}
            />
          </DisabledElementTooltip>
        </div>
      }
      <div className='caption'>
        <div className='caption-text'>
          <EllipsizedText
            text={@props.text}
            height={40}
            style={cursor: 'pointer'}
            onClick={@navigateToEtd}
          />
        </div>
      </div>
      {if @props.children
        <div className='stats'>
          {@props.children}
        </div>
      }
    </li>

module.exports = QuestionItem
