var Migration, SofTitleToTableTitle, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = SofTitleToTableTitle = (function(_super) {
  __extends(SofTitleToTableTitle, _super);

  function SofTitleToTableTitle() {
    SofTitleToTableTitle.__super__.constructor.call(this, false);
  }

  SofTitleToTableTitle.prototype.up = function(project, colls) {
    var question;
    SofTitleToTableTitle.__super__.up.apply(this, arguments);
    return W.all((function() {
      var _i, _len, _ref, _results;
      _ref = colls.questions.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        question = _ref[_i];
        _results.push(question.save({
          'manualTableTitle': question.get('manualSofTitle'),
          'tableTitle': question.get('sofTitle')
        }));
      }
      return _results;
    })())["catch"](function(err) {
      console.log(err);
      throw err;
    });
  };

  return SofTitleToTableTitle;

})(Migration);
