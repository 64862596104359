var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "  <div class=\"edit-input-block row mt-10\">\n    "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "dich_effect_measure", "effectSize", {"name":"inputBlock","hash":{},"data":data})))
    + "\n  </div>\n  <div class=\"edit-input-block row mt-10\" data-property=\"confidenceIntervalTo\">\n    <label for=\"from_ci\">";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "RANGE_OF_EFFECTS", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.from", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <input class=\"number\" id=\"from_ci\" type=\"text\"\n        data-tip-position=\"right\" data-target-position=\"left\"\n        name=\"confidenceIntervalFrom\" value=\""
    + escapeExpression(((helper = (helper = helpers.confidenceIntervalFrom || (depth0 != null ? depth0.confidenceIntervalFrom : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"confidenceIntervalFrom","hash":{},"data":data}) : helper)))
    + "\">\n        <label for=\"to_ci\" class=\"middle\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.to", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n        <input class=\"number\" id=\"to_ci\" type=\"text\"\n          data-tip-position=\"top\" data-target-position=\"bottom\"\n          name=\"confidenceIntervalTo\" value=\""
    + escapeExpression(((helper = (helper = helpers.confidenceIntervalTo || (depth0 != null ? depth0.confidenceIntervalTo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"confidenceIntervalTo","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"sup\"></div>\n  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.ranged", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"4":function(depth0,helpers,partials,data) {
  return "        95% CI\n      ";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "relativeEffect", "relative_effect", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}