var AccountActions, alt, mediator;

alt = require('alt');

mediator = require('mediator');

AccountActions = (function() {
  function AccountActions() {
    this.generateActions('openArchieTokenExpiredModal', 'closeArchieTokenExpiredModal', 'connectToProviderSuccess', 'connectToProviderError');
  }

  AccountActions.prototype.connectToProvider = function(_arg) {
    var login, password, promise, provider;
    provider = _arg.provider, login = _arg.login, password = _arg.password, promise = _arg.promise;
    return mediator.services.externalAccounts.connect(provider, login, password).then((function(_this) {
      return function(login) {
        mediator.user.set('archieTooltipHidden', false);
        return mediator.user.save().then(function() {
          return promise.resolve(login);
        }).then(_this.actions.connectToProviderSuccess());
      };
    })(this))["catch"]((function(_this) {
      return function(error) {
        _this.actions.connectToProviderError();
        return mediator.dialogs.error(error);
      };
    })(this));
  };

  return AccountActions;

})();

module.exports = alt.createActions(AccountActions);
