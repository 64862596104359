var CommentsExportMixin, defaultMargins, defaultOrientation, exportToFile, _ref;

_ref = require('lib/export_to_file'), exportToFile = _ref.exportToFile, defaultMargins = _ref.defaultMargins, defaultOrientation = _ref.defaultOrientation;

CommentsExportMixin = {
  exportToFile: function(selectedOptions, exportDocTemplate) {
    var exportContent, exportHtml, fileName, outputFormat, templateData;
    outputFormat = selectedOptions.outputFormat, exportContent = selectedOptions.exportContent;
    templateData = this.getTemplateData();
    templateData.withRatingSummary = exportContent && exportContent === 'tableAndComments';
    _.extend(templateData, {
      margins: defaultMargins,
      orientation: defaultOrientation
    });
    exportHtml = exportDocTemplate(templateData);
    fileName = this.props.projectName.substring(0, 100);
    return exportToFile(outputFormat, exportHtml, {
      fileName: fileName
    }).then(this.props.onClose);
  }
};

module.exports = CommentsExportMixin;
