var templateLanguages;

templateLanguages = {
  sections: [
    {
      id: 'en',
      sections: 'lib/doc_sections_templates/templates_sections_en'
    }, {
      id: 'pt',
      sections: 'lib/doc_sections_templates/templates_sections_pt'
    }, {
      id: 'et',
      sections: 'lib/doc_sections_templates/templates_sections_et'
    }
  ],
  defaultLanguage: 'pt'
};

module.exports = templateLanguages;
