var MdaTopicsActions, MdaVerfificationView, MdaVerificationActions, MdaVerificationScreen, MdaVerificationStore, MdaVerificationToolbar, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

MdaVerificationStore = require('stores/mda_verification_store');

MdaVerificationActions = require('actions/mda_verification_actions');

MdaVerificationScreen = require('components/mda/verification/verification_screen');

MdaVerificationToolbar = require('components/mda/verification/verification_toolbar');

MdaTopicsActions = require('actions/mda_topics_actions');

View = require('base/views/view');

module.exports = MdaVerfificationView = (function(_super) {
  __extends(MdaVerfificationView, _super);

  function MdaVerfificationView() {
    return MdaVerfificationView.__super__.constructor.apply(this, arguments);
  }

  MdaVerfificationView.prototype.container = '#page-container';

  MdaVerfificationView.prototype.id = 'mda-verification';

  MdaVerfificationView.prototype.autoRender = true;

  MdaVerfificationView.prototype.initialize = function() {
    MdaVerfificationView.__super__.initialize.call(this);
    this.subview('toolbar', new ReactToolbarView({
      component: MdaVerificationToolbar
    }));
    return this.enable(ReactComponent);
  };

  MdaVerfificationView.prototype.getComponentProps = function() {
    return {
      projectId: mediator.project.id
    };
  };

  MdaVerfificationView.prototype.afterRender = function() {
    MdaVerfificationView.__super__.afterRender.call(this);
    return this.renderComponent(MdaVerificationScreen, MdaVerificationActions, null, {
      props: this.getComponentProps(),
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  MdaVerfificationView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('MdaVerificationStore');
    return MdaVerfificationView.__super__.dispose.call(this);
  };

  return MdaVerfificationView;

})(View);
