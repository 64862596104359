CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ getCurrentTORSectionId } = require 'lib/etd_helper'
Popover = require 'components/common/popover'
Translation = require 'components/mixins/translation'
UserProjectDataActions = require 'actions/user_project_data_actions'
{ Button, Switcher } = ReactComponents

sectionIsCollapsed = (collapsedSections) -> (sectionKey) ->
  collapsedSections.has(sectionKey) and collapsedSections.get(sectionKey) is true

TableViewOptions = createReactClass
  displayName: "TableViewOptions"

  propTypes: {

    adolopmentOptions: PropTypes.bool.isRequired,
    assessmentSections: PropTypes.instanceOf(Immutable.Map).isRequired,
    conclusionsSections: PropTypes.instanceOf(Immutable.Map).isRequired,
    etdId: PropTypes.string.isRequired,
    etdViewSettings: PropTypes.instanceOf(Immutable.Map).isRequired,
  }

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations')
  ]

  getInitialState: ->
    opened: false

  getCustomSectionsToToggle: ->
    conclusionsSections = @props.conclusionsSections.keySeq().toJS()
    _.intersection([
      'justification'
      'recommendation'
      'decision'
    ], conclusionsSections)

  getAllSectionsKeys: ->
    @props.assessmentSections.keySeq().concat(@props.conclusionsSections.keySeq()).toList()

  hideOptions: ->
    @setState opened: false

  toggleOptions: ->
    @setState opened: not @state.opened

  toggleAdditionalConsiderations: ->
    UserProjectDataActions.toggleAdditionalConsiderations @props.etdId

  toggleOriginalDataSections: (collapse) -> =>
    UserProjectDataActions.toggleOriginalDataSections @props.etdId, @getAllSectionsKeys(), collapse

  toggleAdolopmentDataSections: (collapse) -> =>
    UserProjectDataActions.toggleAdolopmentDataSections(
      @props.etdId, @getAllSectionsKeys(), collapse)

  toggleOriginalJudgement: ->
    UserProjectDataActions.toggleOriginalJudgement @props.etdId

  toggleAdolopmentJudgement: ->
    UserProjectDataActions.toggleAdolopmentJudgement @props.etdId

  toggleCustomSections: (collapse) -> =>
    sectionsToToggle = @getCustomSectionsToToggle()
    UserProjectDataActions.toggleOriginalDataSections @props.etdId, sectionsToToggle, collapse

  render: ->
    { etdViewSettings } = @props
    collapsedOriginalSections = etdViewSettings.get 'collapsedOriginalSections', Immutable.Map()
    collapsedAdolopmentSections = etdViewSettings.get 'collapsedAdolopmentSections', Immutable.Map()

    allOrginalCollapsed = @getAllSectionsKeys().every sectionIsCollapsed(collapsedOriginalSections)
    allAdolopmentCollapsed = @getAllSectionsKeys().every(
      sectionIsCollapsed(collapsedAdolopmentSections))
    rAndJCollapsed = _.every(@getCustomSectionsToToggle(),
      sectionIsCollapsed(collapsedOriginalSections))

    <div className="table-view-options">
      <Popover
        visible={@state.opened}
        onRequestClose={@hideOptions}
      >
        <Button
          type="round"
          className={if @state.opened then "collapse-options" else "expand-options"}
          onClick={@toggleOptions}>
          {@i18n "/actions.table_view_options"}
        </Button>
        <div className="table-view-options__container">
          <h4>{@i18n 'table-view-options.etd_view'}</h4>
          <Switcher
            checked={etdViewSettings.get('showAdditionalConsiderations', true)}
            onChange={@toggleAdditionalConsiderations}
            buttonText={@i18n 'table-view-options.show_additional_considerations'}
          />
          {@props.adolopmentOptions and <div className="adolopment-options">
            <h4>{@i18n 'table-view-options.original_data_view_settings'}</h4>
            <Switcher
              checked={not allOrginalCollapsed}
              onChange={@toggleOriginalDataSections(not allOrginalCollapsed)}
              buttonText={@i18n 'table-view-options.show_original_data'}
            />
            <Switcher
              checked={etdViewSettings.get('showOriginalJudgement', true)}
              onChange={@toggleOriginalJudgement}
              buttonText={@i18n 'table-view-options.show_original_judgements'}
            />
            <Switcher
              checked={not rAndJCollapsed}
              onChange={@toggleCustomSections(not rAndJCollapsed)}
              buttonText={@i18n 'table-view-options.show_original_recommendation_and_justification'}
            />
            <h4>{@i18n 'table-view-options.adolopment_data_view_settings'}</h4>
            <Switcher
              checked={not allAdolopmentCollapsed}
              onChange={@toggleAdolopmentDataSections(not allAdolopmentCollapsed)}
              buttonText={@i18n 'table-view-options.show_adolopment_data'}
            />
            <Switcher
              checked={etdViewSettings.get('showAdolopmentJudgement', true)}
              onChange={@toggleAdolopmentJudgement}
              buttonText={@i18n 'table-view-options.show_adolopment_judgements'}
            />
          </div>
          }
        </div>
      </Popover>
    </div>

module.exports = TableViewOptions
