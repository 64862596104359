CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

GeneralInformationToolbar = createReactClass

  displayName: "GeneralInformationToolbar"

  mixins: [
    CustomRenderMixin
    Translation('settings:general_information')
  ]

  render: ->
    <h2 className="pl-20">{@i18n 'title'}</h2>


module.exports = GeneralInformationToolbar
