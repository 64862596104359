var ProjectTask, W, mediator, utils;

mediator = require('mediator');

utils = require('base/lib/utils');

W = require('when');

module.exports = ProjectTask = (function() {
  function ProjectTask(taskId) {
    this.taskId = taskId;
  }

  ProjectTask.prototype.run = function(projectId, task) {
    if (task == null) {
      task = {};
    }
    return this.unsafeRun(projectId, task).then((function(_this) {
      return function(result) {
        var extra, message;
        message = result.message;
        if (message == null) {
          message = "Task " + _this.taskId + " completed";
        }
        extra = _.omit(result, 'message');
        return _.extend({}, task, extra, {
          result: 'ok',
          message: message,
          timestamp: +new Date()
        });
      };
    })(this))["catch"]((function(_this) {
      return function(e) {
        var errorMsg, message;
        errorMsg = _.isString(e) ? e : e.message;
        message = "Error while performing task " + _this.taskId + ": " + errorMsg;
        if (mediator.debug) {
          console.error(e);
        } else {
          utils.reportRavenError(e, {
            extra: {
              projectId: projectId,
              task: task,
              error: e
            }
          });
        }
        return _.extend({}, task, {
          result: 'error',
          error: e,
          message: message,
          timestamp: +new Date()
        });
      };
    })(this));
  };

  ProjectTask.prototype.unsafeRun = function(projectId, task) {
    var message;
    message = 'Implement this method. ' + ("This invocation has been run with: " + projectId + ", " + (JSON.stringify(task)));
    return W.reject(new Error(message));
  };

  return ProjectTask;

})();
