var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"other-consideration\">\n  <div class=\"mt-10 row edit-input-block\">\n    <label class=\"attr-name\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "publication_bias", {"name":"i18n","hash":{},"data":data})))
    + "\n      <span class=\"context-help-icon\" data-property=\"publicationBias\"></span>\n    </label>\n    <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "publicationBias", ((stack1 = (depth0 != null ? depth0.publicationBias : depth0)) != null ? stack1.name : stack1), "None: undetected, Serious: publication_bias_strongly_suspected_short", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"sup\"></div>\n  </div>\n  <div class=\"mt-10 row edit-input-block\">\n    <label class=\"attr-name\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "large_effect", {"name":"i18n","hash":{},"data":data})))
    + "\n      <span class=\"context-help-icon\" data-property=\"largeEffect\"></span>\n    </label>\n    <div class=\"inline\">\n    "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "largeEffect", ((stack1 = (depth0 != null ? depth0.largeEffect : depth0)) != null ? stack1.name : stack1), "No: no, Large: large, VeryLarge: very_large", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"sup\"></div>\n  </div>\n  <div class=\"mt-10 row edit-input-block\">\n    <label class=\"attr-name\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "plausible_confounding", {"name":"i18n","hash":{},"data":data})))
    + "\n      <span class=\"context-help-icon\" data-property=\"plausibleConfounding\"></span>\n    </label>\n    <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "plausibleConfounding", ((stack1 = (depth0 != null ? depth0.plausibleConfounding : depth0)) != null ? stack1.name : stack1), "No: no, ReducedForRR: reduced_for_RR, IncreasedForRR: increased_for_RR", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"sup\"></div>\n  </div>\n  <div class=\"mt-10 row edit-input-block\">\n    <label class=\"attr-name\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dose_response_gradient", {"name":"i18n","hash":{},"data":data})))
    + "\n      <span class=\"context-help-icon\" data-property=\"doseResponseGradient\"></span>\n    </label>\n    <div class=\"inline\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "doseResponseGradient", ((stack1 = (depth0 != null ? depth0.doseResponseGradient : depth0)) != null ? stack1.name : stack1), "No: no, Yes: yes", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"sup\"></div>\n  </div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "otherConsiderations", "other_considerations", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}