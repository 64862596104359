var DbDoc, adolopmentDataDocId, adolopmentDataDocShape;

DbDoc = require('lib/db_docs/db_doc');

adolopmentDataDocShape = require('lib/db_docs/doc_shapes/adolopment_data_doc_shape');

adolopmentDataDocId = require('lib/doc_ids').ADOLOPMENT_DATA;

module.exports = DbDoc(adolopmentDataDocShape, {
  dbSetup: {
    docId: adolopmentDataDocId
  },
  methods: {
    toggleAdolopmentForSection: function(etdId, block, sectionId) {
      var currentValue;
      currentValue = this.getAt('etdsData', etdId, block, sectionId, 'withAdolopment') || false;
      return this.setAt('etdsData', etdId, block, sectionId, 'withAdolopment')(!currentValue);
    },
    toggleAdolopmentForSections: function(etdId, block, sectionIds) {
      var sections, sectionsToToggle;
      sectionsToToggle = _(sectionIds).reduce((function(_this) {
        return function(acc, sectionId) {
          acc[sectionId] = {
            withAdolopment: !_this.getAt('etdsData', etdId, block, sectionId, 'withAdolopment') || false
          };
          return acc;
        };
      })(this), {});
      sections = _.extend({}, this.getAt('etdsData', etdId, block) || {}, sectionsToToggle);
      return this.setAt('etdsData', etdId, block)(sections);
    }
  }
});
