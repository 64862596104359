ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
IconButton = require 'components/common/icon_button'
OverarchingQuestionsActions = require 'actions/overarching_questions_actions'
Popover = require 'components/common/popover'
PopoverContent = require 'components/common/popover_content'
SearchBar = require 'components/common/search_bar'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
{ Button } = ReactComponents
{ getCleanCodeLabel, getCodeLabel, searchCode } = require 'lib/question_tags_helper'

QuestionCodes = createReactClass
  displayName: "QuestionCodes"
  mixins: [Translation()]

  propTypes:
    codes: PropTypes.instanceOf(Immutable.Map)
    parentId: PropTypes.string.isRequired
    codeGroup: PropTypes.string.isRequired
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    isEditing: PropTypes.bool
    multiselect: PropTypes.bool

  getInitialState: ->
    searchText: ''
    searching: false
    codesList: Immutable.List()
    displayPopover: false
    checkedCodesMap: @props.codes

  getDefaultProps: ->
    codes: Immutable.List()
    isEditing: true
    multiselect: false

  componentDidUpdate: (prevProps, prevState) ->
    if (prevProps.codes isnt @props.codes)
      @setState checkedCodesMap: @props.codes

  handleAddCode: (code) -> =>
    { parentId, onAdd, multiselect } = @props
    if multiselect
      updatedCodesList = if @state.checkedCodesMap.has code.get('@id')
        @state.checkedCodesMap.delete code.get('@id')
      else
        @state.checkedCodesMap.set code.get('@id'), code

      @setState
        checkedCodesMap: updatedCodesList
    else
      onAdd { parentId, codes: Immutable.fromJS [code] }
      @setState
        searchText: ''
        searching: false
        displayPopover: false
        codesList: Immutable.List()

  onApply: ->
    { parentId, onAdd } = @props

    onAdd { parentId, codes: @state.checkedCodesMap }
    @setState
      searchText: ''
      searching: false
      displayPopover: false
      codesList: Immutable.List()

  onCancel: ->
    @setState
      searchText: ''
      codesList: Immutable.List()
      checkedCodesMap: @props.codes
      displayPopover: false
      searching: false

  handleDeleteCode: (code) -> =>
    { parentId, onRemove } = @props
    onRemove { parentId, code }

  togglePopover: ->
    @setState displayPopover: not @state.displayPopover

  changeSearchText: (searchText) ->
    { codeGroup } = @props
    field = switch codeGroup
      when 'population' then 'healthProblemOrPopulation'
      when 'comparison' then 'intervention'
      when 'comparatorTest' then 'indexTest'
      else codeGroup

    @setState { searchText, searching: true }
    searchCode(field, searchText).then (res) => @setState codesList: Immutable.fromJS(res.codes)
    .finally =>
      @setState searching: false

  resetSearchText: ->
    @setState
      searchText: ''
      codesList: Immutable.List()
      searching: false

  render: ->
    { codes, isEditing, multiselect } = @props
    { codesList, displayPopover, searchText, checkedCodesMap, searching } = @state

    triggerBtnClassNames = classNames 'add-code-trigger', 'hidden': not isEditing
    codeClassNames = classNames "flex flex-row ml-10", 'editable': isEditing

    <div>
      <div>
        {codes.map (code) =>
          <div className={codeClassNames} key={code.get('id')}>
            <span className="inline-block mr-5">
              {getCleanCodeLabel(code)}
            </span>
            {isEditing and <IconButton iconName="cross" onClick={@handleDeleteCode(code)} />}
          </div>
        .toList()}
      </div>
      <Popover
        onRequestClose={@togglePopover}
        positionParams={alignment: 'center'}
        visible={displayPopover}
      >
        <Button type="round" className={triggerBtnClassNames} onClick={@togglePopover}>
          {@i18n '/actions.add_code'}
        </Button>
        <PopoverContent>
          <SearchBar
            onSearch={@changeSearchText}
            onSearchReset={@resetSearchText}
            searchOnChange
            searchText={searchText}
          />
          {searching and <div className="py-10"><Spinner /></div>}
          <ul className='popover-codes-list'>
            {codesList.map (code) =>

              classes = classNames 'codes-list__code',
                included: checkedCodesMap.has code.get('@id')

              <li
                key={code.get('@id')}
                className={classes}
                onClick={@handleAddCode(code)}
                dangerouslySetInnerHTML={{__html: getCodeLabel(code)}}
              />
            }
          </ul>
          {multiselect and <div>
            <ApplyCancelButtons
              onApply={@onApply}
              onCancel={@onCancel}
              isSubmitEnabled={!checkedCodesMap.isEmpty()}
              applyClass="btn-alternative"
            />
          </div>}
        </PopoverContent>
      </Popover>
    </div>


module.exports = QuestionCodes
