Translation = require 'components/mixins/translation'
Tooltip = require 'components/common/tooltip'
IconButton = require 'components/common/icon_button'

ReviewTableSortableHeaderCell = createReactClass
  displayName: 'ReviewTableSortableHeaderCell'

  mixins: [ Translation('mc:review_tables') ]

  propTypes:
    title: PropTypes.string.isRequired
    hidden: PropTypes.bool
    columnIdx: PropTypes.number.isRequired
    allColumnsCount: PropTypes.number.isRequired
    moveColumnLeft: PropTypes.func.isRequired
    moveColumnRight: PropTypes.func.isRequired
    toggleColumn: PropTypes.func
    showSource: PropTypes.func

  getDefaultProps: ->
    hidden: false

  render: ->
    { title, hidden, columnIdx, allColumnsCount } = @props

    <th className="review-table__header-cell review-table__header-cell--sortable">
      <div className="review-table__header-cell-content">
        {if not hidden
          <Tooltip>
            <div title={title}>{title}</div>
          </Tooltip>
        }
        <div className="review-table__move-column">
          {if columnIdx isnt 0 and not hidden
            <button
              className="review-table__move-column-button"
              onClick={@props.moveColumnLeft}
            >
              &lt;
            </button>
          }
          {if columnIdx isnt allColumnsCount - 1 and not hidden
            <button
              className="review-table__move-column-button"
              onClick={@props.moveColumnRight}
            >
              &gt;
            </button>
          }

          {if @props.toggleColumn
            <IconButton
              className={classNames 'review-table__toggle-column-button',
                'review-table__toggle-column-button--hidden': hidden
              }
              iconName={if hidden then 'eye' else 'blind-eye'}
              onClick={@props.toggleColumn}
            />
          }

          {if @props.showSource
            <button
              className="review-table__show-source-button"
              onClick={@props.showSource}
            >
              {@i18n 'source'}
            </button>
          }
        </div>
      </div>
    </th>

module.exports = ReviewTableSortableHeaderCell
