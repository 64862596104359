Button = require 'components/common/button'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
ReferenceAttachmentLink = require 'components/references/attachment_link'
ReferencesActions = require 'actions/references_actions'
Translation = require 'components/mixins/translation'

ReferenceAttachmentCell = createReactClass
  displayName: 'ReferenceAttachmentCell'

  mixins: [Translation('references:attachment')]

  propTypes:
    attachmentData: PropTypes.instanceOf(Immutable.Map)
    referenceId: PropTypes.string.isRequired

  openDeleteModal: (e) ->
    e.stopPropagation()
    { referenceId } = @props
    ReferencesActions.openDeleteAttachmentModal referenceId

  openUploadModal: (e) ->
    e.stopPropagation()
    { referenceId } = @props
    ReferencesActions.openUploadAttachmentModal referenceId

  render: ->
    { attachmentData, referenceId } = @props
    attachmentFileName = attachmentData?.get 'fileName'

    <div className="attachment">
      {if attachmentFileName
        <div className="attachment__inner">
          <ReferenceAttachmentLink
            attachmentFileName={attachmentFileName}
            projectId={mediator.project.id}
            referenceId={referenceId}
          />
          <IconButton
            className="attachment__delete-button"
            iconName="cross"
            onClick={@openDeleteModal}
          />
        </div>
      else
        <div className="attachment__upload">
          <Button
            onClick={@openUploadModal}
            label={@i18n 'upload'}
          />
        </div>
      }
    </div>

module.exports = ReferenceAttachmentCell
