var INITIAL_STATE, NMAOutcomeActions, generateStore;

generateStore = require('stores/utils/generate_store');

NMAOutcomeActions = require('actions/nma_outcome_actions');

INITIAL_STATE = Immutable.fromJS({
  isFetching: false,
  outcome: Immutable.Map()
});

module.exports = generateStore({
  name: 'NMAOutcomeStore',
  initialState: INITIAL_STATE,
  boundActions: [NMAOutcomeActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.set('isFetching', true));
    },
    onFetchSuccess: function(outcome) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('outcome', Immutable.fromJS(outcome));
        return state.set('isFetching', false);
      }));
    },
    onDbChange: function(change) {
      var _ref;
      if (change.id !== this.state.getIn(['outcome', '_id'])) {
        return;
      }
      return this.setState(this.state.set('outcome', Immutable.fromJS((_ref = change.doc) != null ? _ref : {})));
    },
    onFetchError: function() {
      return this.setState(this.state.set('isFetching', false));
    },
    getOutcome: function() {
      return this.state.get('outcome');
    },
    isFetching: function() {
      return this.state.get('isFetching');
    }
  }
});
