Outcome = require 'components/scope/outcomes/outcome'
OutcomeWithComments = require 'components/scope/outcomes/outcome_with_comments'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

OutcomesList = createReactClass
  displayName: 'OutcomesList'
  mixins: [CustomRenderMixin]

  _getOutcomeProps: (outcomeData, index) ->
    _.extend {
      outcomeData
      gIndex: @props.gIndex
      index: index
      editable: false
      readOnlyComments: @props.readOnly
      marked: outcomeData.get('source') is 'brainstorming'
    }

  _outcomesMapper: (outcome, idx) ->
    hasComments = outcome.get('memberComments')?.flatten().size
    outcomeProps = @_getOutcomeProps outcome, idx

    if hasComments
      <OutcomeWithComments key={idx} {...outcomeProps}
        membersMap={@props.membersMap}
        showingComments={@props.showingComments}
        onCommentToggle={@props.onCommentToggle}
      />
    else
      <Outcome className='outcome' {...outcomeProps} key={idx} />

  render: ->
    return false unless @props.outcomes?.size

    <div className="outcomes">
      {@props.outcomes.map @_outcomesMapper}
    </div>

module.exports = OutcomesList
