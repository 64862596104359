var LimitsActions, MembersActions, QUESTION_GENERATION_DOC_ID, QuestionsActions, QuestionsGenerationActions, alt, mediator, questionsGeneartionAsync;

alt = require('alt');

mediator = require('mediator');

MembersActions = require('actions/members_actions');

LimitsActions = require('actions/limits_actions');

QuestionsActions = require('actions/questions_actions');

questionsGeneartionAsync = require('actions/async/questions_generation');

QUESTION_GENERATION_DOC_ID = require('lib/questions_helper').QUESTION_GENERATION_DOC_ID;

QuestionsGenerationActions = (function() {
  function QuestionsGenerationActions() {
    this.generateActions('pouchFetchSuccess', 'pouchFetchError', 'pouchSaveSuccess', 'pouchSaveError', 'saveQuestion', 'saveAdminComment', 'changeTab', 'addQuestion', 'editQuestion', 'deleteQuestion', 'changeQuestionApprovalStatus', 'sendToMembersSuccess', 'sendToMembersError', 'fetchResultsSuccess', 'fetchResultsError', 'closeStepSuccess', 'closeStepError', 'showCommentsExport', 'hideCommentsExport', 'saveTodos', 'setDisagreementResolved', 'save');
  }

  QuestionsGenerationActions.prototype.fetch = function() {
    var projectId;
    projectId = mediator.project.id;
    mediator.services.storePersistenceAdapter.fetch(projectId, QUESTION_GENERATION_DOC_ID).then(this.actions.pouchFetchSuccess)["catch"](this.actions.pouchFetchError);
    MembersActions.fetch();
    LimitsActions.fetchLimits(mediator.activeWorkspace, projectId);
    QuestionsActions.fetch({
      projectId: projectId
    });
    return this.dispatch();
  };

  QuestionsGenerationActions.prototype.sendToMembers = function(params) {
    var projectName;
    projectName = mediator.project.get('name');
    params = _.extend({
      projectName: projectName
    }, params);
    questionsGeneartionAsync.sendToMembers(params).then(this.actions.sendToMembersSuccess)["catch"](this.actions.sendToMembersError);
    return this.dispatch(params);
  };

  QuestionsGenerationActions.prototype.fetchResults = function(params) {
    questionsGeneartionAsync.fetchStepResults(params).then(this.actions.fetchResultsSuccess)["catch"](this.actions.fetchResultsError);
    return this.dispatch(params);
  };

  QuestionsGenerationActions.prototype.closeStep = function(params) {
    questionsGeneartionAsync.closeStep(params).then(this.actions.closeStepSuccess)["catch"](this.actions.closeStepError);
    return this.dispatch(params);
  };

  return QuestionsGenerationActions;

})();

module.exports = alt.createActions(QuestionsGenerationActions);
