var ExtendedApprovalAndFinishedTabsMigration, Migration, QUESTION_GENERATION_DOC_ID, QUESTION_GENERATION_STEPS, mediator, storeHelper, _ref,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

Migration = require('./migration');

mediator = require('mediator');

storeHelper = require('stores/utils/questions_generation_store_helper');

_ref = require('lib/questions_helper'), QUESTION_GENERATION_DOC_ID = _ref.QUESTION_GENERATION_DOC_ID, QUESTION_GENERATION_STEPS = _ref.QUESTION_GENERATION_STEPS;

module.exports = ExtendedApprovalAndFinishedTabsMigration = (function(_super) {
  __extends(ExtendedApprovalAndFinishedTabsMigration, _super);

  function ExtendedApprovalAndFinishedTabsMigration() {
    ExtendedApprovalAndFinishedTabsMigration.__super__.constructor.call(this, false);
  }

  ExtendedApprovalAndFinishedTabsMigration.prototype.up = function(project, colls) {
    ExtendedApprovalAndFinishedTabsMigration.__super__.up.apply(this, arguments);
    return mediator.services.storePersistenceAdapter.fetch(project.id, QUESTION_GENERATION_DOC_ID).then(function(questionsGenerationData) {
      var approvalQuestions, approvalStep, currentStep, finishedStep, newQuestionsGenerationData, others, withQuestionGroups, _i;
      currentStep = questionsGenerationData.currentStep;
      others = 3 <= QUESTION_GENERATION_STEPS.length ? __slice.call(QUESTION_GENERATION_STEPS, 0, _i = QUESTION_GENERATION_STEPS.length - 2) : (_i = 0, []), approvalStep = QUESTION_GENERATION_STEPS[_i++], finishedStep = QUESTION_GENERATION_STEPS[_i++];
      if (currentStep === approvalStep || currentStep === finishedStep) {
        withQuestionGroups = storeHelper.createApprovalQuestionGroupsFromRating(Immutable.fromJS({
          questionsGenerationData: questionsGenerationData
        }));
        if (currentStep === finishedStep) {
          approvalQuestions = questionsGenerationData.steps['questions.approval'].questions;
          withQuestionGroups = withQuestionGroups.setIn(['questionsGenerationData', 'steps', finishedStep, 'questions'], approvalQuestions);
        }
        newQuestionsGenerationData = withQuestionGroups.get('questionsGenerationData').toJS();
        return mediator.services.storePersistenceAdapter.save(project.id, newQuestionsGenerationData);
      }
    })["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
    });
  };

  return ExtendedApprovalAndFinishedTabsMigration;

})(Migration);
