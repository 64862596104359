var OrganizationsActions, W, alt, fetchOrganizationMembers, fetchOrganizations, mediator, organizationsData, _ref;

alt = require('alt');

organizationsData = require('lib/doc_prefixes').organizationsData;

_ref = require('actions/async/organizations_api'), fetchOrganizations = _ref.fetchOrganizations, fetchOrganizationMembers = _ref.fetchOrganizationMembers;

mediator = require('mediator');

W = require('when');

OrganizationsActions = (function() {
  function OrganizationsActions() {
    this.generateActions('checkedIfOrganizationProject', 'connected', 'disconnected', 'fetchError', 'fetchOrganizationMembersError', 'fetchOrganizationMembersSuccess', 'fetchSuccess');
  }

  OrganizationsActions.prototype.fetchOrganizations = function() {
    this.dispatch();
    return fetchOrganizations().then((function(_this) {
      return function(response) {
        return _this.actions.fetchSuccess(response.organizations);
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        return _this.actions.fetchError(err);
      };
    })(this));
  };

  OrganizationsActions.prototype.fetchOrganizationMembers = function(organizationId) {
    this.dispatch();
    return fetchOrganizationMembers(organizationId).then((function(_this) {
      return function(response) {
        return _this.actions.fetchOrganizationMembersSuccess(response.members);
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        return _this.actions.fetchOrganizationMembersError(err);
      };
    })(this));
  };

  OrganizationsActions.prototype.checkIfCurrentProjectIsInOrganization = function() {
    return mediator.services.storePersistenceAdapter.exists(mediator.project.id, organizationsData).then(this.actions.checkedIfOrganizationProject)["catch"](this.actions.fetchError);
  };

  return OrganizationsActions;

})();

module.exports = alt.createActions(OrganizationsActions);
