var BaseException, exception,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

BaseException = require('base/lib/exception');

exception = function(name, params) {
  var Exception;
  if (params == null) {
    params = {};
  }
  return Exception = (function(_super) {
    __extends(Exception, _super);

    function Exception() {
      Exception.__super__.constructor.call(this, name, $.t("errors." + name, params));
    }

    return Exception;

  })(BaseException);
};

module.exports = {
  fileUpload: {
    upload_fail: exception('upload_fail'),
    too_large: exception('too_large'),
    unauthorized: exception('unauthorized'),
    forbidden: exception('forbidden'),
    not_found: exception('not_found'),
    no_connection: exception('no_connection')
  }
};
