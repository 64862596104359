{ useI18n } = require 'lib/react_utils'

VotedEtdData = ({ onClick, votesCountLabel, votedCount, votingCount }) ->

  i18n = useI18n('es:recommendations.table')

  <div className='voted-data' onClick={onClick}>
    <div>
      {i18n votesCountLabel, { votedCount, votingCount }}
    </div>
  </div>

module.exports = VotedEtdData
