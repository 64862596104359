CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
{ instanceOf, func, bool } = PropTypes

MemberTitleCell = createReactClass

  displayName: "MemberTitleCell"

  mixins: [Translation('team:member'), CustomRenderMixin]

  propTypes:
    member: instanceOf(Immutable.Map).isRequired
    updateAttr: func.isRequired
    inline: bool

  getDefaultProps: ->
    inline: false

  getTitleOptions: ->
    [
      text: " "
      value: ""
    ,
      text: @i18n 'titles.prof'
      value: "prof"
    ,
      text: @i18n 'titles.dr'
      value: "dr"
    ,
      text: @i18n 'titles.mr'
      value: "mr"
    ,
      text: @i18n 'titles.mrs'
      value: "mrs"
    ,
      text: @i18n 'titles.ms'
      value: "ms"
    ]

  render: ->
    groupClass = classNames "inline": @props.inline, "col-12": not @props.inline
    <div className="form-group">
      <div className={groupClass}>
        <label className="pull-left" htmlFor='title'>{@i18n 'title'}</label>
        <div className="pull-left select-container">
          <Select
            id="title"
            name='title'
            selected={@props.member.get('title')}
            options={@getTitleOptions()}
            onChange={@props.updateAttr}
            />
        </div>
      </div>
      <div className="clearfix" />
    </div>

module.exports = MemberTitleCell
