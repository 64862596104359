var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return " checked ";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"edit-input-block row mt-10\">\n  <label  class=\"show-final-score\" for=\"showFinalScoreInControls\">\n  <input id=\"showFinalScoreInControls\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showFinalScoreInControls : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    type=\"checkbox\" value=\""
    + escapeExpression(((helper = (helper = helpers.showFinalScoreInControls || (depth0 != null ? depth0.showFinalScoreInControls : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"showFinalScoreInControls","hash":{},"data":data}) : helper)))
    + "\" name=\"showFinalScoreInControls\">\n  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.show_final_score_in_controls", {"name":"i18n","hash":{},"data":data})))
    + "\n  </label>\n</div>\n<div class=\"edit-input-block row mt-10\">\n  "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "change_score_in_controls", "changeScoreInControlsValue", {"name":"inputBlock","hash":{},"data":data})))
    + "\n</div>\n<div class=\"edit-input-block row mt-10\">\n  "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "units", "units", {"name":"inputBlock","hash":{},"data":data})))
    + "\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}