{ bool, func, element, shape, oneOf, number } = PropTypes
{ removeHTMLNode } = require 'base/lib/utils'

POPOVERS_STACK = Immutable.Stack()

CONTAINER_CLASS = 'PopupBox_Container'

MIN_CONTAINER_HEIGHT = '50'

DEFAULT_CONTAINER_STYLES = "
  position: fixed;
  z-index: 110;
  opacity: 0;
  transition: opacity .8s cubic-bezier(0, 0.34, 0.45, 1.5);
"

DEFAULT_POSITION_SPEC =
  position: 'bottom' # 'bottom' | 'top' | 'left' | 'right', 'cover'
  alignment: 'start' # 'start' | 'center' | 'end'
  positionOffset: 5  # distance in pixels between container edge and anchor edge

OPPOSITES =
  top: 'bottom'
  right: 'left'
  bottom: 'top'
  left: 'right'
  cover: 'cover'

# getAlignmentOffset :: RectObject -> RectObject -> String -> String -> Number
getAlignmentOffset = (anchorRect, containerRect, alignment, position) ->
  switch alignment
    when 'start' then 0
    when 'center'
      if position in [ 'top', 'bottom', 'cover' ]
        (anchorRect.width - containerRect.width)/2
      else
        (anchorRect.height - containerRect.height)/2
    when 'end'
      if position in [ 'top', 'bottom', 'cover' ]
        -(containerRect.width - anchorRect.width)
      else
        -(containerRect.height - anchorRect.height)

# primaryOffsetGetter :: RectObject -> RectObject -> Number -> (String -> Number)
primaryOffsetGetter = (anchorRect, containerRect, positionOffset) -> (position) ->
  anchorEdgeOffset = anchorRect[position]

  switch position
    when 'top'
      anchorEdgeOffset - (containerRect.height + positionOffset)
    when 'left'
      anchorEdgeOffset - (containerRect.width + positionOffset)
    when 'bottom', 'right'
      anchorEdgeOffset + positionOffset
    else
      anchorRect['top'] + positionOffset

# getSecondaryOffset :: RectObject -> RectObject -> String -> String -> Number
getSecondaryOffset = (anchorRect, containerRect, alignment, position) ->
  # alignment offset is an offset of container element relative to anchor element. It depends on
  # position
  alignmentOffset = getAlignmentOffset anchorRect, containerRect, alignment, position

  switch position
    when 'top', 'bottom', 'cover'
      { clientWidth } = document.documentElement
      secondaryOffset = anchorRect.left + alignmentOffset
      # ensure container won't overflow the screen
      if secondaryOffset + containerRect.width > clientWidth
        secondaryOffset = clientWidth - containerRect.width
    when 'left', 'right'
      { clientHeight } = document.documentElement
      secondaryOffset = anchorRect.top + alignmentOffset
      # ensure container won't overflow the screen
      if secondaryOffset + containerRect.height > clientHeight
        secondaryOffset = clientHeight - containerRect.height
  secondaryOffset

#  primaryOffsetOverflow :: Number -> RectObject -> String -> Number
primaryOffsetOverflow = (offset, containerRect, position) ->
  # distance between container edge and the screen edge in `position` direction.
  containerEdgeOffset = switch position
    when 'top', 'left', 'cover'
      offset
    when 'right'
      document.documentElement.clientWidth - (offset + containerRect.width)
    when 'bottom'
      document.documentElement.clientHeight - (offset + containerRect.height)

  # positive value means no overflow
  if containerEdgeOffset > 0 then 0 else Math.abs(containerEdgeOffset)

# containerPositionToStylesString :: String -> Number -> Number -> ?Number -> String
containerPositionToStylesString = (position, primaryOffset, secondaryOffset, containerHeight) ->
  # if containerHeight is provided, it means there will be a scrollbar, need to take its width into
  # account
  layoutStyles = if containerHeight? then "height:#{containerHeight}px;overflow-y:auto;" else ''
  switch position
    when 'top', 'bottom', 'cover'
      layoutStyles + "top:#{primaryOffset}px;left:#{secondaryOffset}px;"
    when 'right', 'left'
      layoutStyles + "top:#{secondaryOffset}px;left:#{primaryOffset}px;"

# TODO: unify this one with a similar function in add_custom_tooltip. Or maybe completely replace
# add_custom_tooltip with this.
# getContainerOffsets :: HTMLelement -> HTMLElement -> Object -> IO String
getPositionStylesString = (anchorEl, containerEl, positionSpec = {}) ->
  # get position spec params, falling back to defaults if any of them were not provided
  { position, alignment, positionOffset } = _.defaults positionSpec, DEFAULT_POSITION_SPEC
  desiredPosition = position

  # collect bounding rects of anchor and container elements
  anchorRect = anchorEl.getBoundingClientRect()
  containerRect = containerEl.getBoundingClientRect()

  primaryOffsetProvider = primaryOffsetGetter anchorRect, containerRect, positionOffset

  # we calculate 2 offsets (primary and secondary). "Primary" offset corresponds to "position"
  # parameter and "secondary" - to the "alignment" parameter in the position spec.
  # These values will be later put as `top` or `left` CSS props of container element to get it
  # positioned as requested
  primaryOffset = primaryOffsetProvider position
  secondaryOffset = getSecondaryOffset anchorRect, containerRect, alignment, position

  # Check if primary offset will not cause container overflowing the screen edge. If it does cause
  # the overflow then (1) try the inversed position (e.g. if desired position is `top`, then try
  # `bottom`), if the inversed position also causes overflow, then (2) choose between desired and
  # inversed position selecting the one which gives the most of visible space and then reduce the
  # height of the container to avoid the overflow.
  primaryOverflow = primaryOffsetOverflow primaryOffset, containerRect, position
  if primaryOverflow > 0
    inversedPosition = OPPOSITES[position]
    inversedOffset = primaryOffsetProvider inversedPosition
    inversedOverflow = primaryOffsetOverflow inversedOffset, containerRect, inversedPosition

    # inversed position doesn't overflow
    if inversedOverflow is 0
      primaryOffset = inversedOffset
      position = inversedPosition
    # inversed position also overflows, select whichever has more space for content
    else
      position = inversedPosition if inversedOverflow < primaryOverflow
      adjustedContainerHeight = Math.max(
        MIN_CONTAINER_HEIGHT,
        containerRect.height - Math.min(primaryOverflow, inversedOverflow)
      )
      # since we updated container height to fit in exactly the available space in the chosen
      # direction, primary offset is simply containerEdgeOffset with position offset added
      primaryOffset = anchorRect[position] + positionOffset

    # the position might have changed at this point, so update the secondary offset if needed
    if position isnt desiredPosition
      secondaryOffset = getSecondaryOffset anchorRect, containerRect, alignment, position

  containerPositionToStylesString position, primaryOffset, secondaryOffset, adjustedContainerHeight

handleContainerDragStart = (evt) ->
  evt.dataTransfer.setData 'text/plain', null
  evt.currentTarget.setAttribute 'data-initialX', evt.screenX
  evt.currentTarget.setAttribute 'data-initialY', evt.screenY

handleContainerDragEnd = (evt) ->
  { screenX, screenY, currentTarget } = evt
  dragContainer currentTarget, screenX, screenY
  currentTarget.setAttribute 'data-initialX', null
  currentTarget.setAttribute 'data-initialY', null

dragContainer = ($container, screenX, screenY) ->
  traveledY = screenY - $container.getAttribute 'data-initialY'
  traveledX = screenX - $container.getAttribute 'data-initialX'
  { offsetTop, offsetLeft } = $container
  $container.style.left = "#{offsetLeft + traveledX}px"
  $container.style.top = "#{offsetTop + traveledY}px"

addDragEventsListeners = ($el) ->
  return unless $el?
  $el.addEventListener 'dragstart', handleContainerDragStart
  $el.addEventListener 'dragend', handleContainerDragEnd

removeDragEventsListeners = ($el) ->
  return unless $el?
  $el.removeEventListener 'dragstart', handleContainerDragStart
  $el.removeEventListener 'dragend', handleContainerDragEnd

# preparePopupBoxContainer :: () -> IO HTMLElement
preparePopupBoxContainer = (draggable = false) ->
  container = document.createElement 'div'
  container.setAttribute 'style', DEFAULT_CONTAINER_STYLES
  container.setAttribute 'class', CONTAINER_CLASS
  if draggable
    container.setAttribute 'draggable', true
    addDragEventsListeners container
  document.body.appendChild container
  container

# setContainerStyles :: HTMLelement -> HTMLElement -> IO ()
setContainerStyles = (containerEl, anchorEl, positionSpec) ->
  # set calculated values
  positionStyles = getPositionStylesString anchorEl, containerEl, positionSpec
  containerEl.setAttribute 'style',
    DEFAULT_CONTAINER_STYLES + positionStyles + 'opacity:1;'

Popover = createReactClass
  displayName: 'Popover'

  propTypes:
    draggable: bool
    visible: bool.isRequired
    onRequestClose: func.isRequired
    # TODO: improve validation of target and content props. They can also be provided as 1st and
    # 2nd child elements respectively
    target: element
    content: element
    positionParams: shape(
      position: oneOf(['bottom', 'top', 'left', 'right', 'cover'])
      alignment: oneOf(['start', 'center', 'end'])
      positionOffset: number
    )
    children: (props, propName, componentName) ->
      children = props[propName]
      if children.length > 2
        throw new Error "#{componentName} must not have more than 2 child elements."
      null

  forceClose: ->
    @props.onRequestClose?() if @props.visible

  handleScroll: (evt) ->
    # hide content on scroll event, unless this is our content's scroll event
    return if @container? and (@container is evt.target or @container.contains evt.target)

    @forceClose()

  componentDidMount: ->
    @renderChild()
    window.addEventListener 'resize', @forceClose
    window.addEventListener 'scroll', @handleScroll, true

  componentWillUnmount: ->
    window.removeEventListener 'resize', @forceClose
    window.removeEventListener 'scroll', @handleScroll, true
    @unmountChild()
    if @container
      removeDragEventsListeners @container if @props.draggable
      removeHTMLNode @container
      @container ?= null

  componentDidUpdate: (prevProps) ->
    if @props.visible
      @renderChild(not prevProps.visible)
    else if prevProps.visible
      @unmountChild()

  getContentEl: ->
    { children, content } = @props
    return content if content?

    if children.length is 2
      _.last React.Children.toArray(children)
    else
      null

  getTargetEl: ->
    { target, children } = @props
    return target if target?

    _.first(React.Children.toArray(children))

  handleBlur: (evt) ->
    $targetEl = ReactDOM.findDOMNode @targetEl
    focusMovedToDescendantEl = @container? and @container.contains(evt.relatedTarget)
    focusMovedToTargetEl = evt.relatedTarget is $targetEl
    # ignore focusing descendant elements as well as the target element
    if focusMovedToDescendantEl or focusMovedToTargetEl
      return evt.preventDefault()
    focusedPopover = POPOVERS_STACK.first()
    return unless focusedPopover
    $focusedTargetEl = ReactDOM.findDOMNode focusedPopover.targetEl
    # Current popover's content is itself a popover. Since popover's content is not part of
    # targetEl DOM tree, we use popovers stack to test whether current popover (the on being
    # blurred) content doesn't contain the active (one the focused moved to) popover's target
    # element (this happens in case of nested popovers where one popover's content is parent of
    # another popover's target element). In such case we must prevent closing action of the current
    # popover, otherwise its decedents will get unmounted
    focusMovedToChildPopover = @wrappedChild?.contains $focusedTargetEl
    if focusMovedToChildPopover
      return evt.preventDefault()
    # Current popover is a target element of another popover. We also use popovers stack to test
    # whether current popover's (the one being blurred) targetEl is not a child of the active (the
    # one the focus moved to) popover's targetEl. Of course this makes sense only when current
    # popover and the focused popover are 2 different instances
    focusMovedToParentPopover = focusedPopover isnt this and
      $focusedTargetEl.contains $targetEl
    if focusMovedToParentPopover
      return evt.preventDefault()
    @props.onRequestClose?(evt)

  handleKeyDown: (evt) ->
    @handleBlur(evt) if evt.keyCode is 27

  targetRef: (el) ->
    @targetEl = el

  wrappedChildRef: (el) ->
    @wrappedChild = el

  prepareContent: ->
    # wrap child to have control over its focus
    contentEl = @getContentEl()
    return null unless contentEl

    classes = classNames 'PopupBox_Content', @props.className
      
    <div
      className={classes}
      onKeyDown={@handleKeyDown}
      onBlur={@handleBlur}
      ref={@wrappedChildRef}
      tabIndex='-1'
    >
      {contentEl}
    </div>

  renderChild: (initialRender = true) ->
    return unless @props.visible
    @container ?= preparePopupBoxContainer(draggable = @props.draggable)

    ReactDOM.render @prepareContent(), @container, () =>
      if initialRender
        # once any popover shows its content it goes on top of the stack, being the active one
        POPOVERS_STACK = POPOVERS_STACK.unshift this
        @wrappedChild.focus()
        setContainerStyles @container, ReactDOM.findDOMNode(@targetEl), @props.positionParams

  unmountChild: ->
    if @container?
      ReactDOM.unmountComponentAtNode @container
      # reset container styles back to default
      @container.setAttribute 'style', DEFAULT_CONTAINER_STYLES

    # once any popover hides its content it goes off the stack and if there are several
    # interrelated popovers on stack we need to return focus to the next related popover
    if POPOVERS_STACK.first() is this
      POPOVERS_STACK = POPOVERS_STACK.shift()
      nextPopover = POPOVERS_STACK.first()
      if nextPopover
        # moving focus back to parent popover
        $targetEl = ReactDOM.findDOMNode @targetEl
        if nextPopover.wrappedChild?.contains $targetEl
          nextPopover.wrappedChild?.focus()
        # moving focus back to child popover
        $nextPopoverTargetEl = ReactDOM.findDOMNode nextPopover.targetEl
        if $targetEl?.contains $nextPopoverTargetEl
          nextPopover.wrappedChild?.focus()

  render: ->
    targetEl = @getTargetEl()

    React.cloneElement targetEl,
      tabIndex: targetEl.props.tabIndex ? 0
      ref: (el) =>
        targetEl.ref? el
        @targetRef el

module.exports = Popover
