MarketingScreen = require 'components/common/marketing_screen'
Translation = require 'components/mixins/translation'
{ license_url } = require 'lib/constants'

MultiComparisonsMarketingScreen = createReactClass
  displayName: 'MultiComparisonsMarketingScreen'

  mixins: [Translation('')]

  render: ->
    <MarketingScreen
      title={@i18n 'marketing.multi_comparisons.title'}
      descriptions={@i18n 'marketing.multi_comparisons.description', returnObjects: true}
      licenseInfoTitle={@i18n 'marketing.multi_comparisons.license_info_title'}
      licenseInfoDesc={@i18n 'marketing.license_info_desc', link: license_url}
      readAlsoLink="https://evidenceprime.com/2018/02/28/30k-users-multi-comparisons/"
      imgName="multi.png"
    />

module.exports = MultiComparisonsMarketingScreen
