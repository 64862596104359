Input = require 'components/common/input'
QuestionsActions = require 'actions/questions_actions'
QuestionCodes = require 'components/common/question_codes'
Translation = require 'components/mixins/translation'

InterventionPopulationField = createReactClass
  displayName: 'InterventionPopulationField'

  mixins: [Translation('mdg_tables:create_question_dialog')]

  propTypes:
    field: PropTypes.oneOf(['intervention', 'population']).isRequired
    onChange: PropTypes.func.isRequired
    value: PropTypes.shape(
      name: PropTypes.string.isRequired
      shortName: PropTypes.string.isRequired
      codes: PropTypes.objectOf(PropTypes.shape
        id: PropTypes.string.isRequired
        codeType: PropTypes.string.isRequired
        value: PropTypes.string.isRequired
        name: PropTypes.string.isRequired
      ).isRequired
    ).isRequired

  onChange: (field) -> (evt) =>
    { onChange, value } = @props
    newValue = evt.target.value
    obj = {}
    obj[field] = newValue
    onChange _.extend {}, value, obj

  render: ->
    { field, value: { codes, name, shortName }} = @props
    <div className="intervention-population-field">
      <div className="intervention-population-field__name">
        <Input
          focusSupport={false}
          keyboardSupport={false}
          onChange={@onChange('name')}
          value={name}
        />
      </div>
      <div className="intervention-population-field__short-name-codes">
        <div className="short-name-codes__short-name">
          <div>{@i18n 'shortName'}</div>
          <Input
            focusSupport={false}
            keyboardSupport={false}
            onChange={@onChange('shortName')}
            value={shortName}
          />
        </div>
        <div className="short-name-codes__codes">
          <QuestionCodes
            codes={Immutable.fromJS(codes)}
            codeGroup={field}
            isEditing
            parentId={field}
            onAdd={QuestionsActions.addMdgCreateQuestionDialogCode}
            onRemove={QuestionsActions.removeMdgCreateQuestionDialogCode}
          />
        </div>
      </div>
    </div>

module.exports = InterventionPopulationField
