var EtdAsync, EtdsActions, alt, mediator;

alt = require('alt');

EtdAsync = require('actions/async/etd');

mediator = require('mediator');

EtdsActions = (function() {
  function EtdsActions() {
    this.generateActions('fetchSuccess', 'fetchAllSuccess', 'fetchError');
  }

  EtdsActions.prototype.fetch = function(etdIds) {
    if (etdIds == null) {
      etdIds = mediator.questions.models.reduce(function(result, qm) {
        return result.concat(qm.get('recommendationIds'));
      }, []);
    }
    this.dispatch(etdIds);
    return EtdAsync.fetchEtdDoc(mediator.project.id, etdIds).then((function(_this) {
      return function(data) {
        return _this.actions.fetchSuccess(data);
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        return _this.actions.fetchError(err);
      };
    })(this));
  };

  EtdsActions.prototype.fetchEtdsByQuestionIds = function(questionsIds) {
    var etdIds;
    etdIds = mediator.questions.models.reduce(function(result, qm) {
      if (_(questionsIds).contains(qm.get('_id'))) {
        result = result.concat(qm.get('recommendationIds'));
      }
      return result;
    }, []);
    return this.actions.fetch(etdIds);
  };

  EtdsActions.prototype.fetchAll = function(dbId) {
    return EtdAsync.fetchAllEtds(dbId).then(this.actions.fetchAllSuccess)["catch"](this.actions.fetchError);
  };

  EtdsActions.prototype.fetchEtdsForQuestions = function(questionIds) {
    var adapter;
    adapter = mediator.services.storePersistenceAdapter;
    return adapter.fetch(mediator.project.id, questionIds).then(function(res) {
      return _.pluck(res.rows, 'doc');
    }).then(function(docs) {
      var recIds;
      recIds = _.flatten(_.pluck(docs, 'recommendationIds'));
      return adapter.fetch(mediator.project.id, recIds);
    }).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  return EtdsActions;

})();

module.exports = alt.createActions(EtdsActions);
