var MDGSearchStrategy, MDGSearchStrategyActions, MDGSearchStrategyView, MDGToolbarView, ReactComponent, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

MDGSearchStrategy = require('components/mdg_tables/search_strategy');

MDGSearchStrategyActions = require('actions/mdg_search_strategy_actions');

MDGToolbarView = require('views/mdg_tables/toolbar_view');

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

View = require('base/views/view');

module.exports = MDGSearchStrategyView = (function(_super) {
  __extends(MDGSearchStrategyView, _super);

  function MDGSearchStrategyView() {
    return MDGSearchStrategyView.__super__.constructor.apply(this, arguments);
  }

  MDGSearchStrategyView.prototype.container = '#page-container';

  MDGSearchStrategyView.prototype.autoRender = true;

  MDGSearchStrategyView.prototype._stopInputsPropagation = false;

  MDGSearchStrategyView.prototype.initialize = function() {
    MDGSearchStrategyView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new MDGToolbarView({
      mode: 'searchStrategy'
    }));
    return this.enable(ReactComponent);
  };

  MDGSearchStrategyView.prototype.afterRender = function() {
    var questionId;
    MDGSearchStrategyView.__super__.afterRender.apply(this, arguments);
    questionId = this.options.questionId;
    return this.renderComponent(MDGSearchStrategy, MDGSearchStrategyActions, questionId, {
      fetchAndListen: {
        dbId: mediator.project.id,
        opts: {
          filter: function(doc) {
            return doc._id === questionId || (doc.docType === 'mdgSearchStrategy' && doc.questionId === questionId);
          }
        }
      }
    });
  };

  MDGSearchStrategyView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('MDGSearchStrategyStore', 'EtdStore');
    return MDGSearchStrategyView.__super__.dispose.apply(this, arguments);
  };

  return MDGSearchStrategyView;

})(View);
