IconButton = require 'components/common/icon_button'
Button = require 'components/common/button'
AddCustomTooltip = require 'components/enhancers/add_custom_tooltip'
ContentEditable = require 'components/common/content_editable'
TextAreaWithApply = require 'components/common/text_area_with_apply'
Translation = require 'components/mixins/translation'
MultiComparisonsActions = require 'actions/multi_comparisons_actions'

InterventionCommentDialog = createReactClass
  displayName: "InterventionCommentDialog"

  mixins: [Translation('')]

  propTypes:
    section: PropTypes.string.isRequired
    sectionComment: PropTypes.string

  getInitialState: ->
    comment: @props.sectionComment

  updateComment: (e) ->
    @setState { comment: e.target.innerHTML }

  onCancel: ->
    @props.hideTooltip()

  onApply: (commentText) ->

    MultiComparisonsActions.updateSectionGradingComments {
      section: @props.section
      comment: commentText
    }
    @props.hideTooltip()

  render: ->
    <div className="review-table__comment-dialog">
      <TextAreaWithApply editing
        content={@state.comment}
        onCancel={@onCancel}
        onApply={@onApply}
      />
    </div>

CommentButton = (props) ->
  buttonProps = _(props).omit 'i18n', 'sectionComment'
  <div>
  {
    if props.sectionComment
      <IconButton
        className="review-table__comment-button"
        iconName="comment-blank"
        {...buttonProps}
      />
    else
      <Button
        className="btn review-table__add-comment-button"
        label={props.i18n '/actions.add'}
        {...buttonProps}
      />
  }
  </div>

tooltipParams = trigger: 'click', position: 'bottom', alignment: 'end'
module.exports = AddCustomTooltip CommentButton, InterventionCommentDialog, tooltipParams
