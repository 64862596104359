var Outcome, ProjectTask, QuestionDoc, QuestionDocShape, QuestionsActions, UpdateQuestionAfterRevmanWebImport, W, mapped, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ProjectTask = require('lib/project_tasks/task');

mediator = require('mediator');

Outcome = require('models/outcome');

QuestionDoc = require('lib/db_docs/question_doc');

QuestionDocShape = require('lib/db_docs/doc_shapes/question_doc_shape');

QuestionsActions = require('actions/questions_actions');

mapped = require("lib/lens_utils").mapped;

W = require('when');

module.exports = UpdateQuestionAfterRevmanWebImport = (function(_super) {
  __extends(UpdateQuestionAfterRevmanWebImport, _super);

  function UpdateQuestionAfterRevmanWebImport() {
    UpdateQuestionAfterRevmanWebImport.__super__.constructor.call(this, 'update_question_after_revman_web_import');
  }

  UpdateQuestionAfterRevmanWebImport.prototype.unsafeRun = function(projectId, _arg) {
    var questionId;
    questionId = _arg.questionId;
    return QuestionDoc.at(projectId, questionId).update(function(doc) {
      return QuestionDoc.of(QuestionDocShape.defaults(doc)).updateAt('outcomes', mapped)(function(outcomeFromDb) {
        var outcome;
        outcome = new Outcome(outcomeFromDb);
        if (outcome.get('effectMeasure') === 'RD') {
          outcome.convertToAbsoluteEstimateFromRevMan();
        }
        outcome.recalculateOutcomeData();
        return outcome.attributes;
      }).json();
    }).then(function() {
      return {
        message: "Question " + questionId + " document has been updated for " + projectId + " (after Revman WEB import)"
      };
    });
  };

  return UpdateQuestionAfterRevmanWebImport;

})(ProjectTask);
