Button = require 'components/common/button'
{ useI18n } = require 'lib/react_utils'
{ referenceDecorator } = require 'lib/editor_decorators'

GdtEditor = Editor.Editor

QualityIndicatorsTable = ({ qualityIndicators }) ->
  i18n = useI18n('quality_indicators:table')
  return null if qualityIndicators.isEmpty()

  <table className="standard-table quality-indicators-table">
    <thead>
      <tr>
        <th>{i18n 'quality_indicator'}</th>
        <th>{i18n 'performance_measure'}</th>
        <th>{i18n 'performance_indicator_rate'}</th>
      </tr>
    </thead>
    <tbody>
      {qualityIndicators.map (qi) ->
        type = qi.get('type')
        <React.Fragment key={qi.get('id')}>
          <tr>
            <td colSpan="3" className="quality-indicators__name">
            <div className="flex">
              <div className="flex-grow">
                <b>{qi.get('name')}</b>
              </div>
            </div>
            </td>
          </tr>
          <tr className='quality-indicator-row'>
            <td>
              <div>
                <b>{i18n 'type'}:</b> {not _.isEmpty(type) and i18n "../types.#{type}"}
              </div>
              <div>
                <b>{i18n 'explanation_of_terms'}:</b> {qi.get('explanationOfTerms')}
              </div>
            </td>
            <td>
              <div className="text-container">
                <GdtEditor
                  attachments={Immutable.Map()}
                  readOnly
                  editorContent={qi.get('calculationFormula')}
                  customDecorators={referenceDecorator}
                />
              </div>
            </td>
            <td>
              <div className="text-container">
                <GdtEditor
                  attachments={Immutable.Map()}
                  readOnly
                  editorContent={qi.get('performanceIndicator')}
                  customDecorators={referenceDecorator}
                />
              </div>
            </td>
          </tr>
        </React.Fragment>
      }
    </tbody>
  </table>

QualityIndicatorsTable.propTypes =
  qualityIndicators: PropTypes.instanceOf(Immutable.List).isRequired

module.exports = QualityIndicatorsTable
