Router = require 'router'

Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
DecisionAidsActions = require 'actions/decision_aids_actions'
DecisionAidsStore = require 'stores/decision_aids_store'
IconButton = require 'components/common/icon_button'
Popover = require 'components/common/popover'
PopoverContent = require 'components/common/popover_content'
Translation = require 'components/mixins/translation'
{ getChaptersOrder } = require 'lib/decision_aids_helpers'

CHAPTERS_CONTROLS = ['addNew', 'changeOrder']

storeConnector =
  DecisionAidsStore: (Store, props) ->
    isFetching: Store.isFetching()
    decisionAid: Store.getDecisionAid(props.daId)
    editingDecisionAidId: Store.getEditingDecisionAidId()
    editingChaptersOrder: Store.getEditingChaptersOrder()
    expandedChapters: Store.getExpandedChapters()

DecisionAidsHeader = createReactClass
  displayName: 'DecisionAidsHeader'

  propTypes:
    expandedChapters: PropTypes.instanceOf(Immutable.Set).isRequired
    onPreview: PropTypes.func.isRequired
    screen: PropTypes.oneOf(['list', 'details', 'create']).isRequired

  mixins: [Translation('')]

  getInitialState: ->
    chapterOptionsOpen: false

  toggleChapterOptions: ->
    @setState chapterOptionsOpen: not @state.chapterOptionsOpen

  getTitle: ->
    switch @props.screen
      when 'create' then @i18n 'da:create_decision_aid'
      else
        @i18n 'da:decision_aids'

  showChapterEditModal: ->
    DecisionAidsActions.toggleEditModal true

  expandAllChapters: ->
    DecisionAidsActions.setExpandedChapters getChaptersOrder(@props.decisionAid)

  collapseAllChapters: ->
    DecisionAidsActions.setExpandedChapters []

  handleChaptersControlClick: (evt) ->
    @setState chapterOptionsOpen: false
    controlName = evt.target.getAttribute 'name'
    switch controlName
      when 'expandAll' then @expandAllChapters()
      when 'collapseAll' then @collapseAllChapters()
      when 'addNew' then @showChapterEditModal()
      when 'changeOrder' then DecisionAidsActions.editChaptersOrder @props.daId

  render: ->
    { editingChaptersOrder, daId } = @props

    inChapterOrderingMode = editingChaptersOrder?

    expandBtnLabel = if @props.expandedChapters.isEmpty()
      'expand_all'
    else
      'collapse_all'

    <header className='decision-aids-header'>
      <h2>{@getTitle()}</h2>
      {if @props.screen is 'list'
        createScreenUrl = Router::getProjectRelativeUrl '/dissemination/decision-aids/create'
        <a className='btn btn-success create' href={createScreenUrl}>
          {@i18n 'da:create_new'}
        </a>
      }
      {if @props.screen is 'details'
        <div className="controls">
          <Button
            shape="pill"
            label={@i18n "da:details-screen.chapters_controls.#{expandBtnLabel}"}
            onClick={@handleChaptersControlClick}
            name={_.string.camelize(expandBtnLabel)}
            disabled={inChapterOrderingMode}
          />
          <div className='chapter-controls'>
            <Popover
              visible={@state.chapterOptionsOpen}
              onRequestClose={@toggleChapterOptions}
            >
              <IconButton
                className="chapters-list-actions"
                iconName='gear'
                title={@i18n "/actions.chapters_list_actions"}
                labelPosition='left'
                onClick={@toggleChapterOptions}
                disabled={inChapterOrderingMode}
              />
              <PopoverContent>
                <div className="chapter-options-popover">
                  {CHAPTERS_CONTROLS.map (controlName) =>
                    <Button
                      key={controlName}
                      label={@i18n(
                        "da:details-screen.chapters_controls.#{_.str.underscored controlName}")}
                      onClick={@handleChaptersControlClick}
                      name={controlName}
                    />
                  }
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className='preview-control'>
            <IconButton
              iconName='export'
              title={@i18n 'da:decision_aid_preview'}
              labelPosition='left'
              onClick={@props.onPreview}
            />
          </div>
        </div>
      }
    </header>

module.exports =  ConnectStore DecisionAidsHeader, DecisionAidsStore, storeConnector
