ExportDialog = require 'components/common/export_dialog'
Modal = require 'components/common/modal'
QualityIndicatorsActions = require 'actions/quality_indicators_actions'
mediator = require 'mediator'
{ useI18n } = require 'lib/react_utils'
{ useCallback } = React

OUTPUT_FORMATS = ['html', 'doc', 'pdf']

QualityIndicatorsExportModal = ({question})->
  i18n = useI18n()

  onDownload = ({ outputFormat }) ->
    mediator.services.qualityIndicatorsService.export question, outputFormat
    .then closeExportDialog

  closeExportDialog = useCallback QualityIndicatorsActions.hideExportDialog
  , []

  <Modal isOpen
    className="quality-indicators-export-modal"
    closeButton={false}
    title={i18n 'quality_indicators:export.modal_title'}
  >
    <ExportDialog
      outputFormats={OUTPUT_FORMATS}
      onClose={closeExportDialog}
      onDownload={onDownload}
    />
  </Modal>

QualityIndicatorsExportModal.propTypes =
  question: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = QualityIndicatorsExportModal
