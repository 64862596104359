var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "      <tr class=\"outcome-diag-row\">\n        <th rowspan=\"3\" class=\"diagnostic-outcome-label\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.outcome", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"3\" class=\"no-of-studies-patients\">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_studies", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " (";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_patients", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ")\n        </th>\n        <th rowspan=\"3\" class=\"design-studies\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.study_design", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th colspan=\"5\" rowspan=\"2\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.factors_decrease_coe", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"effect-head-cell\" colspan=\"6\">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.effect_per_1000_patients", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        </th>\n        <th rowspan=\"3\" class=\"quality\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.dta_coe", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"3\" class=\"importance\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.importance", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      </tr>\n      <tr>\n        <th colspan=\"2\" class=\"prevalence-1\">\n          <span class=\"content\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence1 || (depth0 != null ? depth0.prevalence1 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n        </th>\n        <th colspan=\"2\" class=\"prevalence-2\">\n          <span class=\"content\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence2 || (depth0 != null ? depth0.prevalence2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n        </th>\n        <th colspan=\"2\" class=\"prevalence-3\">\n          <span class=\"content\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence3 || (depth0 != null ? depth0.prevalence3 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n        </th>\n      </tr>\n      <tr class=\"outcome-diag-row\">\n        <th class=\"risk-of-bias\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"indirectness\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.indirectness", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"inconsistency\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"imprecision\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.imprecision", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"publication-bias\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.publication_bias", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-1\">"
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-1\">"
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-2\">"
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-2\">"
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-3\">"
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-3\">"
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</th>\n      </tr>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "      <tr class=\"outcome-diag-row\">\n        <th rowspan=\"2\" class=\"diagnostic-outcome-label\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.outcome", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"no-of-studies-patients\">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_studies", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " (";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_patients", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ")\n        </th>\n        <th rowspan=\"2\" class=\"design-studies\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.study_design", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th colspan=\"5\" rowspan=\"1\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.factors_decrease_coe", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"effect-head-cell\" colspan=\"6\" rowspan=\"1\">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.effect_per_1000_patients", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        </th>\n        <th rowspan=\"2\" class=\"quality\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.dta_coe", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"2\" class=\"importance\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.importance", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      </tr>\n      <tr class=\"outcome-diag-row\">\n        <th class=\"risk-of-bias\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"indirectness\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.indirectness", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"inconsistency\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"imprecision\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.imprecision", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"publication-bias\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.publication_bias", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-1\">\n          <span class=\"content\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence1 || (depth0 != null ? depth0.prevalence1 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n        </th>\n        <th class=\"diagnostic-effect prevalence-2\">\n          <span class=\"content\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence2 || (depth0 != null ? depth0.prevalence2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n        </th>\n        <th class=\"diagnostic-effect prevalence-3\">\n          <span class=\"content\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.pre_test_prob", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence3 || (depth0 != null ? depth0.prevalence3 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n        </th>\n      </tr>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<table class=\"diagnostic-meta no-border\" width=\"100%\" border=\"0\" cellpadding=\"0\">\n  <tbody>\n    <tr class=\"no-border\">\n      <td width=\"50%\" class=\"no-border\" cellpadding=\"0\">\n        <div class=\"pooled-values-container\"></div>\n      </td>\n      <td width=\"5%\" class=\"no-border\">\n      </td>\n      <td class=\"no-border\" cellpadding=\"0\">\n        <div class=\"prevalences-container\"></div>\n      </td>\n      <td width=\"25%\" class=\"no-border\"></td>\n    </tr>\n  </tbody>\n</table>\n\n<table class=\"layer-one-single-3-prev-table outcomes-table\" width=\"100%\">\n  <thead>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </thead>\n  <tbody class=\"outcomes\"></tbody>\n</table>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}