var Migration, RecommendationModeSavingChange, W, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'recommendation_mode_saving_change';

module.exports = RecommendationModeSavingChange = (function(_super) {
  __extends(RecommendationModeSavingChange, _super);

  function RecommendationModeSavingChange() {
    RecommendationModeSavingChange.__super__.constructor.call(this, false);
  }

  RecommendationModeSavingChange.prototype.up = function(project, colls) {
    var personalMetadoc, personalMetadocMode;
    RecommendationModeSavingChange.__super__.up.apply(this, arguments);
    personalMetadoc = project.getPersonalMetadoc();
    personalMetadocMode = personalMetadoc.get('recommendationsMode');
    if (personalMetadocMode == null) {
      return W.resolve();
    }
    return personalMetadoc.unset('recommendationsMode').set({
      rev_author: "project_migration/" + migrationName
    }).save().then(function() {
      var projectMetadocMode;
      projectMetadocMode = project.get('recommendationsMode');
      if (personalMetadocMode !== projectMetadocMode) {
        return project.set({
          recommendationsMode: personalMetadocMode,
          rev_author: "project_migration/" + migrationName
        }).save();
      }
    });
  };

  return RecommendationModeSavingChange;

})(Migration);
