var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "class=\"special\"\n      ";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, buffer = "<label ";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "varies, no_included_studies, no_known", (depth0 != null ? depth0.option : depth0), {"name":"inStringArray","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + " title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n  <input type=\"radio\" name=\""
    + escapeExpression(((helper = (helper = helpers.attr || (depth0 != null ? depth0.attr : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"attr","hash":{},"data":data}) : helper)))
    + "\" data-bind-attr=\"true\" value=\""
    + escapeExpression(((helper = (helper = helpers.option || (depth0 != null ? depth0.option : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"option","hash":{},"data":data}) : helper)))
    + "\">\n  <span>"
    + escapeExpression(((helper = (helper = helpers.caption || (depth0 != null ? depth0.caption : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"caption","hash":{},"data":data}) : helper)))
    + "</span>\n  <br>\n</label>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}