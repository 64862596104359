CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Row = require 'components/common/editable_table/row'

Body = createReactClass

  displayName: "EditableTableBody"

  propTypes:
    children: PropTypes.node

  mixins: [CustomRenderMixin]

  render: ->
    <tbody>
      {React.Children.map @props.children, (child, key) =>
        <Row key={key}>
          {React.cloneElement child, _.extend {},
            expanded: @props.expanded,
            onExpand: @props.onExpanded,
            onCollapse: @props.onCollapse}
        </Row>
      }
    </tbody>

module.exports = Body
