Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ButtonsRow = require 'components/common/buttons_row'
Button = require 'components/common/button'

TemplateButtons = createReactClass
  displayName: 'TemplateButtons'

  propTypes:
    cancelEdit: PropTypes.func.isRequired
    saveTemplateChanges: PropTypes.func.isRequired
    closeEdit: PropTypes.func.isRequired
    revertChanges: PropTypes.func
    templateInUse: PropTypes.bool
    templateChanged: PropTypes.bool
    useCurrentTemplate: PropTypes.func

  mixins: [Translation('settings:etd_templates'), CustomRenderMixin]

  templateInUseButtons: ->
    <ButtonsRow className="settings-bottom-buttons-row">
      <Button
        className="btn-cancel"
        label={@i18n 'cancel'}
        onClick={@props.cancelEdit}/>
      {if @props.templateChanged
        <Button
          className="btn-apply save-changes"
          label={@i18n 'save_changes'}
          onClick={@props.saveTemplateChanges}/>
      else
        <Button
          className="btn-apply"
          label={@i18n 'ok'}
          onClick={@props.closeEdit}/>
      }
    </ButtonsRow>

  templateNotInUseButtons: ->
    <ButtonsRow className="settings-bottom-buttons-row">
      <Button
        className="revert"
        onClick={@props.revertChanges}
        disabled={not @props.templateChanged}
        label={@i18n 'revert_to_original'}
      />
      <Button
        className="use-template"
        onClick={@props.useCurrentTemplate}
        label={@i18n 'use_this_template'}
      />
    </ButtonsRow>

  render: ->
    if @props.templateInUse
      @templateInUseButtons()
    else
      @templateNotInUseButtons()

module.exports = TemplateButtons
