var COMMON_ATTRS, Outcome, cellToOutcomeAttrsMap, outcomeAttrs, provider;

Outcome = require('models/outcome');

provider = require('lib/gdt_tables/cell_props_provider').provider;

COMMON_ATTRS = ['_id'];

cellToOutcomeAttrsMap = {
  designStudies: COMMON_ATTRS.concat(['metaType', 'designStudies']),
  importance: COMMON_ATTRS.concat(['importance']),
  riskOfBias: COMMON_ATTRS.concat(['metaType', 'riskOfBias']),
  inconsistency: COMMON_ATTRS.concat(['metaType', 'inconsistency']),
  indirectness: COMMON_ATTRS.concat(['metaType', 'indirectness']),
  imprecision: COMMON_ATTRS.concat(['metaType', 'imprecision']),
  publicationBias: COMMON_ATTRS.concat(['metaType']),
  noOfStudies: COMMON_ATTRS.concat(['metaType', 'noOfStudies']),
  otherConsiderations: COMMON_ATTRS.concat(['publicationBias', 'largeEffect', 'plausibleConfounding', 'doseResponseGradient', 'metaType']),
  intervention: COMMON_ATTRS.concat(['type', 'interventionCount', 'interventionTotal', 'effectMeasure', 'metaType', 'designStudies']),
  control: COMMON_ATTRS.concat(['type', 'name', 'changeScoreInControls', 'effectMeasure', 'designStudies', 'changeScoreInControlsValue', 'units', 'controlCount', 'controlTotal', 'absDenominator', 'showFinalScoreInControls', 'metaType']),
  calculatedControlRisk: COMMON_ATTRS.concat(['lowControl', 'lowControlRisk', 'lowControlLabel', 'moderateControl', 'moderateControlRisk', 'highControl', 'highControlLabel', 'highControlRisk', 'moderateControlLabel', 'metaType', 'controlCount', 'controlTotal', 'calculatedControl', 'calculatedControlValue']),
  lowControlRisk: COMMON_ATTRS.concat(['lowControl', 'lowControlRisk', 'lowControlLabel', 'moderateControl', 'moderateControlRisk', 'highControl', 'highControlLabel', 'highControlRisk', 'moderateControlLabel', 'metaType', 'controlCount', 'controlTotal', 'calculatedControl', 'calculatedControlValue']),
  moderateControlRisk: COMMON_ATTRS.concat(['lowControl', 'lowControlRisk', 'lowControlLabel', 'moderateControl', 'moderateControlRisk', 'highControl', 'highControlLabel', 'highControlRisk', 'moderateControlLabel', 'metaType', 'controlCount', 'controlTotal', 'calculatedControl', 'calculatedControlValue']),
  highControlRisk: COMMON_ATTRS.concat(['lowControl', 'lowControlRisk', 'lowControlLabel', 'moderateControl', 'moderateControlRisk', 'highControl', 'highControlLabel', 'highControlRisk', 'moderateControlLabel', 'metaType', 'controlCount', 'controlTotal', 'calculatedControl', 'calculatedControlValue']),
  lowControlLabel: COMMON_ATTRS.concat(['lowControlLabel']),
  moderateControlLabel: COMMON_ATTRS.concat(['moderateControlLabel']),
  highControlLabel: COMMON_ATTRS.concat(['highControlLabel']),
  relativeEffect: COMMON_ATTRS.concat(['metaType', 'type', 'otherMeasure', 'effectMeasure', 'effectSize', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'designStudies']),
  absoluteEffect: COMMON_ATTRS.concat(['metaType', 'type', 'effectSize', 'confidenceIntervalFrom', 'designStudies', 'confidenceIntervalTo', 'effectMeasure', 'unitsType', 'units']),
  calculatedControlAbsoluteEffect: COMMON_ATTRS.concat(['metaType', 'type', 'absEffectAutoCalc', 'absEffectSize', 'absEffectFrom', 'absEffectTo', 'absDenominator', 'perPatients', 'perPatientsOther', 'designStudies', 'perTimeFrame', 'perTimeFrameOther', 'effectSize', 'units', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'effectMeasure', 'otherMeasure', 'fewerMore', 'fewerMoreFrom', 'fewerMoreTo']),
  lowControlAbsoluteEffect: COMMON_ATTRS.concat(['type', 'metaType', 'absEffectSizeLow', 'absEffectFromLow', 'absEffectToLow', 'absDenominator', 'effectSize', 'perPatients', 'perPatientsOther', 'designStudies', 'effectMeasure', 'otherMeasure', 'absEffectAutoCalc', 'perPatients', 'perPatientsOther', 'perTimeFrame', 'perTimeFrameOther', 'fewerMore', 'fewerMoreFrom', 'fewerMoreTo']),
  moderateControlAbsoluteEffect: COMMON_ATTRS.concat(['type', 'metaType', 'absEffectSizeModerate', 'absEffectFromModerate', 'absEffectToModerate', 'absDenominator', 'perPatients', 'perPatientsOther', 'designStudies', 'confidenceIntervalTo', 'effectMeasure', 'otherMeasure', 'absEffectAutoCalc', 'perPatients', 'perPatientsOther', 'perTimeFrame', 'perTimeFrameOther', 'fewerMore', 'fewerMoreFrom', 'fewerMoreTo']),
  highControlAbsoluteEffect: COMMON_ATTRS.concat(['type', 'metaType', 'absEffectSizeHigh', 'absEffectFromHigh', 'absEffectToHigh', 'absDenominator', 'perPatients', 'perPatientsOther', 'designStudies', 'perTimeFrame', 'perTimeFrameOther', 'effectSize', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'effectMeasure', 'otherMeasure', 'absEffectAutoCalc', 'fewerMore', 'fewerMoreFrom', 'fewerMoreTo']),
  comments: COMMON_ATTRS.concat(['comments']),
  quality: _(['quality', 'metaType', '_id']).union(Outcome.QUALITY_MODIFIERS),
  sofOutcomeLabel: COMMON_ATTRS.concat(['name', 'shortName', 'designStudies', 'metaType', 'followUpType', 'followUpLength', 'followUpTimeUnit', 'followUpLengthTo', 'followUpToTimeUnit', 'othersFollowUpTimeUnit', 'othersFollowUpToTimeUnit', 'type', 'scaleFrom', 'scaleTo', 'measuredWith', 'timingOfExposure']),
  sofV3OutcomeLabel: COMMON_ATTRS.concat(['name', 'shortName', 'designStudies', 'measuredWith', 'followUpType', 'followUpLength', 'followUpTimeUnit', 'followUpLengthTo', 'followUpToTimeUnit', 'othersFollowUpTimeUnit', 'othersFollowUpToTimeUnit', 'interventionCount', 'controlCount', 'interventionTotal', 'controlTotal', 'noOfStudies', 'withOutcome', 'withoutOutcome', 'metaType']),
  oneRowOutcomeLabel: COMMON_ATTRS.concat(['name', 'shortName', 'measuredWith', 'followUpLength', 'type', 'metaType', 'scaleTo', 'scaleFrom', 'designStudies', 'forceTypeSelection']),
  sofOutcomeEdit: COMMON_ATTRS.concat(['type', 'metaType']),
  diagnosticOutcomeLabel: COMMON_ATTRS.concat(['name']),
  noOfStudiesPatients: COMMON_ATTRS.concat(['noOfStudies', 'noOfPatients']),
  caseControl: COMMON_ATTRS.concat(['withOutcome', 'withoutOutcome', 'designStudies', 'interventionCount', 'interventionTotal', 'controlCount', 'controlTotal', 'metaType']),
  calculatedControlCorrespondingRisk: COMMON_ATTRS.concat(['type', 'controlCount', 'controlTotal', 'effectSize', 'unitsType', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'units', 'otherMeasure', 'name', 'metaType']),
  correspondingRisk: COMMON_ATTRS.concat(['type', 'controlCount', 'controlTotal', 'effectSize', 'unitsType', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'units', 'otherMeasure', 'name', 'metaType']),
  moderateControlCorrespondingRisk: COMMON_ATTRS.concat(['moderateControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'metaType']),
  highControlCorrespondingRisk: COMMON_ATTRS.concat(['highControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'metaType']),
  noOfParticipantsStudiesFollowUp: COMMON_ATTRS.concat(['interventionTotal', 'controlTotal', 'noOfStudies', 'followUpType', 'followUpLength', 'followUpTimeUnit', 'followUpLengthTo', 'followUpToTimeUnit', 'othersFollowUpTimeUnit', 'othersFollowUpToTimeUnit', 'designStudies', 'interventionCount', 'controlCount', 'withOutcome', 'withoutOutcome']),
  riskDifferenceWithIntervention: COMMON_ATTRS.concat(['type', 'controlCount', 'controlTotal', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator', 'otherMeasure', 'units', 'unitsType', 'metaType']),
  lowControlRiskDifferenceWithIntervention: COMMON_ATTRS.concat(['type', 'lowControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator']),
  moderatewControlRiskDifferenceWithIntervention: COMMON_ATTRS.concat(['type', 'moderateControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator']),
  highControlRiskDifferenceWithIntervention: COMMON_ATTRS.concat(['type', 'highControlRisk', 'effectSize', 'effectMeasure', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'absDenominator']),
  eventRatesWithControl: COMMON_ATTRS.concat(['type', 'controlCount', 'controlTotal', 'effectMeasure']),
  eventRatesWithIntervention: COMMON_ATTRS.concat(['type', 'interventionCount', 'interventionTotal', 'effectMeasure']),
  noOfParticipantsStudiesDiagnostic: COMMON_ATTRS.concat(['noOfStudies', 'noOfPatients']),
  narrativeDesc: COMMON_ATTRS.concat(['narrativeDesc']),
  events: COMMON_ATTRS.concat(['type', 'effectCount', 'effectTotal']),
  individuals: COMMON_ATTRS.concat(['effectCount', 'effectTotal']),
  rate: COMMON_ATTRS.concat(['effectCount', 'effectTotal', 'effectDenominator', 'effectRate', 'effectOtherDenominator', 'effectDuration', 'effectOtherDuration', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'type', 'effectMeasure', 'units'])
};

outcomeAttrs = function(cellId, outcomeAttrs) {
  var cellOutcomeAttrsList, _ref;
  cellOutcomeAttrsList = (_ref = cellToOutcomeAttrsMap[cellId]) != null ? _ref : [];
  return _.pick(outcomeAttrs, cellOutcomeAttrsList);
};

module.exports = provider('outcomeAttrs', outcomeAttrs);
