var ModalView, Rm5Import, Rm5ImportVerificationView, W, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

W = require('when');

template = require('views/templates/rm5_import_verification');

Rm5Import = require('lib/rm5_import');

module.exports = Rm5ImportVerificationView = (function(_super) {
  __extends(Rm5ImportVerificationView, _super);

  function Rm5ImportVerificationView() {
    return Rm5ImportVerificationView.__super__.constructor.apply(this, arguments);
  }

  Rm5ImportVerificationView.prototype.className = 'rm5-verification';

  Rm5ImportVerificationView.prototype.template = template;

  Rm5ImportVerificationView.prototype.title = $.t('projects:rm5_import_verification.title');

  Rm5ImportVerificationView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = 50;
    this.questions = this.options.questions;
    Rm5ImportVerificationView.__super__.initialize.apply(this, arguments);
    this._deferred = W.defer();
    this.delegate('click', 'button.accept', this.accept);
    return this.delegate('change', 'input[type=radio]', this.changeOutcomeEffectEstimation);
  };

  Rm5ImportVerificationView.prototype.promise = function() {
    return this._deferred.promise;
  };

  Rm5ImportVerificationView.prototype.accept = function() {
    return this._deferred.resolve().then(this.dispose);
  };

  Rm5ImportVerificationView.prototype.dispose = function() {
    if (this._deferred.promise.inspect().state === 'pending') {
      this._deferred.reject('cancelled');
    }
    return Rm5ImportVerificationView.__super__.dispose.apply(this, arguments);
  };

  Rm5ImportVerificationView.prototype.changeOutcomeEffectEstimation = function(e) {
    var $target, outcome, question;
    $target = $(e.target);
    question = _(this.questions).findWhere({
      id: $target.data('questionId')
    });
    outcome = question.get('outcomes').get($target.attr('name'));
    if ($target.val() === 'ratio') {
      Rm5Import.convertToRelativeEstimate(outcome);
    } else {
      outcome.convertToAbsoluteEstimateFromRevMan();
    }
    return outcome.save();
  };

  Rm5ImportVerificationView.prototype._getQuestionsWithOutcomesForVerification = function() {
    var outcome, question, questionData, questionsWithOutcomesToVerify, _i, _j, _len, _len1, _ref, _ref1;
    questionsWithOutcomesToVerify = [];
    _ref = this.questions;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      question = _ref[_i];
      questionData = {
        question: question.get('question'),
        _id: question.id,
        outcomes: []
      };
      _ref1 = question.get('outcomes').models;
      for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
        outcome = _ref1[_j];
        if (outcome.rm5importVerification) {
          questionData.outcomes.push(outcome.toJSON());
        }
      }
      if (questionData.outcomes.length) {
        questionsWithOutcomesToVerify.push(questionData);
      }
    }
    return questionsWithOutcomesToVerify;
  };

  Rm5ImportVerificationView.prototype.getTemplateData = function() {
    return {
      questions: this._getQuestionsWithOutcomesForVerification()
    };
  };

  return Rm5ImportVerificationView;

})(ModalView);
