var Chaplin, DbHelper, ReplicationService, USER_SESSIONS_DOCS, W, guard, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

DbHelper = require('base/lib/db_helper');

W = require('when');

Chaplin = require('chaplin');

W.delay = require('when/delay');

W.sequence = require('when/sequence');

W.parallel = require('when/parallel');

guard = require('when/guard');

mediator = require('mediator');

utils = require('base/lib/utils');

USER_SESSIONS_DOCS = ['user-sessions', 'user-sessions-new'];

module.exports = ReplicationService = (function(_super) {
  __extends(ReplicationService, _super);

  function ReplicationService() {
    this._replicateForegroundProject = __bind(this._replicateForegroundProject, this);
    this._fgProjectReplicated = __bind(this._fgProjectReplicated, this);
    this._stopForegroundProjectReplication = __bind(this._stopForegroundProjectReplication, this);
    this._replicateCentralUdb = __bind(this._replicateCentralUdb, this);
    this._localUdbReplicated = __bind(this._localUdbReplicated, this);
    this._replicateLocalUdb = __bind(this._replicateLocalUdb, this);
    this._replicateBackgroundProjects = __bind(this._replicateBackgroundProjects, this);
    this._replicateBackgroundProject = __bind(this._replicateBackgroundProject, this);
    this._handleError = __bind(this._handleError, this);
    this.sendLocalChanges = __bind(this.sendLocalChanges, this);
    this.retry = __bind(this.retry, this);
    this.isConnecting = __bind(this.isConnecting, this);
    this.isConnected = __bind(this.isConnected, this);
    this.isOnline = __bind(this.isOnline, this);
    this.destroy = __bind(this.destroy, this);
    return ReplicationService.__super__.constructor.apply(this, arguments);
  }

  ReplicationService.prototype.namespace = 'replication';

  ReplicationService.prototype.initialState = 'idle';

  ReplicationService.prototype.timeouts = {};

  ReplicationService.prototype.bgReplications = {};

  ReplicationService.prototype.fetchingCentralProjectId = null;

  ReplicationService.prototype.window = null;

  ReplicationService.prototype.udbService = null;

  ReplicationService.prototype.initialize = function() {
    ReplicationService.__super__.initialize.apply(this, arguments);
    _(this).extend(Chaplin.EventBroker);
    this.subscribeEvent('sessionStarted', (function(_this) {
      return function() {
        return _this.handle('sessionStarted');
      };
    })(this));
    this.subscribeEvent('projectChanged', (function(_this) {
      return function() {
        return _this.handle('projectChanged');
      };
    })(this));
    this.subscribeEvent('login', (function(_this) {
      return function() {
        return _this.handle('login');
      };
    })(this));
    this.subscribeEvent('!replicateBgProject', (function(_this) {
      return function(projectId) {
        return _this._replicateBackgroundProject(projectId)();
      };
    })(this));
    this.subscribeEvent('!replicateBgProjects', this._replicateBackgroundProjects);
    this.subscribeEvent("!" + this.namespace + ":fetchCentralProjectStarted", (function(_this) {
      return function(projectId) {
        var _ref;
        _this.fetchingCentralProjectId = projectId;
        return (_ref = _this.bgReplications[projectId]) != null ? _ref.cancel() : void 0;
      };
    })(this));
    this.subscribeEvent("!" + this.namespace + ":fetchCentralProjectEnded", (function(_this) {
      return function(projectId) {
        _this.fetchingCentralProjectId = null;
        return _this._replicateBackgroundProject(projectId)();
      };
    })(this));
    this.subscribeEvent('sessionExpired', (function(_this) {
      return function() {
        return _this.transition('unauthorized');
      };
    })(this));
    return this.on('transition', (function(_this) {
      return function(info) {
        _this.publishEvent("" + _this.namespace + "." + info.toState);
        _this.publishEvent("" + _this.namespace + ".statusChanged", info);
        if (info.fromState === 'connecting') {
          return _this.publishEvent("" + _this.namespace + ".finishedConnecting");
        }
      };
    })(this));
  };

  ReplicationService.prototype.destroy = function() {
    this.clearQueue();
    this._stopReplications();
    return this.unsubscribeAllEvents();
  };

  ReplicationService.prototype.isOnline = function() {
    var _ref;
    return (_ref = this.state) === 'connected' || _ref === 'unauthorized';
  };

  ReplicationService.prototype.isConnected = function() {
    return this.state === 'connected';
  };

  ReplicationService.prototype.isConnecting = function() {
    var _ref;
    return (_ref = this.state) === 'idle' || _ref === 'starting' || _ref === 'connecting';
  };

  ReplicationService.prototype.retry = function() {
    return this.transition('connecting');
  };

  ReplicationService.prototype._handleAccessDeniedProjects = function(dbsWithAccessDenied) {
    if (!dbsWithAccessDenied.length) {
      return W.resolve();
    }
    return this.authentication.getLoginStatus().then((function(_this) {
      return function(loginStatus) {
        if (loginStatus.status === 'central') {
          return _this._filterAccessDenied(dbsWithAccessDenied).then(function(trulyDenied) {
            return mediator.user.markAsAccessRevoked(trulyDenied);
          });
        } else {
          return _this.transition('unauthorized');
        }
      };
    })(this));
  };

  ReplicationService.prototype._handleDeletedProjects = function(deletedDbs) {
    if (!deletedDbs.length) {
      return W.resolve();
    }
    return mediator.user.markProjectsAsDeleted(deletedDbs);
  };

  ReplicationService.prototype._handleProjectReplicationError = function(e, db) {
    var _ref;
    if ((_ref = e.status) === 401 || _ref === 403) {
      return {
        accessDenied: db
      };
    } else if (e.status === 404) {
      return {
        projectDeleted: db
      };
    } else {
      throw e;
    }
  };

  ReplicationService.prototype._handleConnectionTimeout = function() {
    var timeoutFn;
    clearTimeout(this.timeouts.connectionTimeout);
    timeoutFn = (function(_this) {
      return function() {
        var now;
        now = moment(_this.clock.getTimestamp());
        if (now.diff(_this.connectionStart) >= _this.connectionTimeout && _this.state === 'connecting') {
          return _this.transition('connectionTimeout');
        }
      };
    })(this);
    return this.timeouts.connectionTimeout = setTimeout(timeoutFn, this.connectionTimeout);
  };

  ReplicationService.prototype._filterAccessDenied = function(projects) {
    return W.map(projects, function(project) {
      return W($.ajax(DbHelper.centralDbUrl(project), {
        dataType: 'json',
        xhrFields: {
          withCredentials: true
        }
      })).then((function() {}), function(e) {
        var _ref;
        if ((_ref = e.status) === 401 || _ref === 403) {
          return project;
        }
        throw e;
      });
    }).then(function(filtered) {
      return _(filtered).compact();
    });
  };

  ReplicationService.prototype._checkServerAvailability = function() {
    var udbName;
    udbName = this.udbService.getUdbName(mediator.user.get('name'));
    return W($.ajax(DbHelper.centralDbUrl(udbName), {
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      }
    }));
  };

  ReplicationService.prototype.sendLocalChanges = function(projects) {
    var notReplicatedProjects;
    notReplicatedProjects = _(projects).difference(mediator.user.getReplicatedProjects());
    if (notReplicatedProjects.length) {
      if (this.state === 'connected') {
        this.timeouts.localChanges = setTimeout(((function(_this) {
          return function() {
            return _this.sendLocalChanges(projects);
          };
        })(this)), this.retryTimeout);
      }
      return W.resolve();
    } else {
      return W.map(projects, (function(_this) {
        return function(db) {
          return _this._localReplica(db).replicate.to(_this._centralReplica(db))["catch"](function(e) {
            return _this._handleProjectReplicationError(e, db);
          });
        };
      })(this)).then((function(_this) {
        return function(infos) {
          var dbsWithAccessDenied, deletedDbs;
          dbsWithAccessDenied = _(infos).chain().pluck('accessDenied').compact().value();
          deletedDbs = _(infos).chain().pluck('projectDeleted').compact().value();
          return _this._handleAccessDeniedProjects(dbsWithAccessDenied).then(function() {
            return _this._handleDeletedProjects(deletedDbs);
          }).then(function() {
            if (infos.length - dbsWithAccessDenied.length - deletedDbs.length) {
              return _this.publishEvent('replication.backedUp', {
                timestamp: _.max(_(infos).pluck('end_time'))
              });
            }
          });
        };
      })(this), this._handleError);
    }
  };

  ReplicationService.prototype.states = {
    idle: {
      sessionStarted: 'starting',
      '*': function() {
        return this.deferUntilTransition();
      }
    },
    starting: {
      _onEnter: function() {
        this.window.on('online', (function(_this) {
          return function() {
            return _this.handle('windowOnline');
          };
        })(this));
        this.window.on('offline', (function(_this) {
          return function() {
            return _this.handle('windowOffline');
          };
        })(this));
        return this.transition('connecting');
      },
      '*': function() {
        return this.deferUntilTransition();
      }
    },
    connecting: {
      _onEnter: function() {
        this._handleConnectionTimeout(this.connectionStart = moment(this.clock.getTimestamp()));
        return this._checkServerAvailability().then((function(_this) {
          return function() {
            return _this.transition('connected');
          };
        })(this), this._handleError);
      },
      windowOffline: 'disconnected',
      '*': function() {
        return this.deferUntilTransition();
      }
    },
    connectionTimeout: {
      _onEnter: function() {
        return this.timeouts.retry = setTimeout(((function(_this) {
          return function() {
            return _this.transition('connecting');
          };
        })(this)), this.retryTimeout);
      },
      '*': function() {
        return this.deferUntilTransition();
      }
    },
    serverError: {
      _onEnter: function() {
        this._stopReplications();
        return this.timeouts.quietTime = setTimeout(((function(_this) {
          return function() {
            return _this.transition('disconnected');
          };
        })(this)), this.quietTime);
      },
      '*': function() {
        return this.deferUntilTransition();
      }
    },
    connected: {
      _onEnter: function() {
        this._stopReplications();
        return W.join(this.sendLocalChanges(mediator.user.getReplicatedProjects()), this._replicateBackgroundProjects(), this._replicateLocalUdb(), this._replicateCentralUdb(true), this._replicateForegroundProject()).otherwise(this._handleError);
      },
      projectChanged: function() {
        var _ref;
        if ((_ref = this.bgReplications[this.currentlyReplicatedProjectName]) != null) {
          _ref.cancel();
        }
        this._replicatePreviousProjectDb();
        return this._replicateForegroundProject();
      },
      windowOffline: 'disconnected'
    },
    unauthorized: {
      _onEnter: function() {
        return this._stopReplications();
      },
      login: 'connecting'
    },
    disconnected: {
      _onEnter: function() {
        this._stopReplications();
        return this.timeouts.retry = setTimeout(((function(_this) {
          return function() {
            return _this.transition('connecting');
          };
        })(this)), this.retryTimeout);
      },
      windowOnline: 'connecting',
      retry: 'connecting'
    }
  };

  ReplicationService.prototype._centralReplica = DbHelper.centralReplica;

  ReplicationService.prototype._localReplica = DbHelper.localReplica;

  ReplicationService.prototype._liveReplicationOpts = function() {
    return {
      heartbeat: this._disableHeartbeat ? false : this.heartbeat,
      live: true
    };
  };

  ReplicationService.prototype._stopReplications = function() {
    var key, replicationTask, timeout, _ref, _ref1, _ref2, _ref3;
    _ref = this.timeouts;
    for (key in _ref) {
      timeout = _ref[key];
      clearTimeout(timeout);
    }
    _ref1 = this.bgReplications;
    for (key in _ref1) {
      replicationTask = _ref1[key];
      if (replicationTask != null) {
        replicationTask.cancel();
      }
    }
    this._stopForegroundProjectReplication();
    if ((_ref2 = this.centralUdbReplication) != null) {
      _ref2.cancel();
    }
    return (_ref3 = this.localUdbReplication) != null ? _ref3.cancel() : void 0;
  };

  ReplicationService.prototype._handleError = function(error) {
    var _ref;
    if (error.status == null) {
      if (error instanceof SyntaxError) {
        return this.transition('serverError');
      } else {
        return mediator.dialogs.fatalError(error);
      }
    } else if (error.status === 401) {
      return this.transition('unauthorized');
    } else if (error.status === 409) {
      return utils.reportRavenError(error, {
        extra: {
          info: 'Duplicate replication processes detected in the same browser',
          projectId: (_ref = mediator.project) != null ? _ref.id : void 0
        }
      });
    } else if (error.status === 429) {
      utils.reportRavenError('Server returned 429, turning heartbeat off');
      this._disableHeartbeat = true;
      return this.transition('serverError');
    } else if (error.status < 500) {
      return this.transition('disconnected');
    } else {
      return this.transition('serverError');
    }
  };

  ReplicationService.prototype._bgReplicationBreakLength = function() {
    return _.random(this.minBreakBetweenBgProjects, 2 * this.minBreakBetweenBgProjects);
  };

  ReplicationService.prototype._guard = guard.bind(null, guard.n(2));

  ReplicationService.prototype._guardBackgroundTask = function(task) {
    return this._guard(task);
  };

  ReplicationService.prototype._replicateBackgroundProject = function(db, breakLength) {
    if (breakLength == null) {
      breakLength = 0;
    }
    return (function(_this) {
      return function() {
        var _ref;
        if (_this.state === 'disconnected') {
          throw {
            reason: 'disconnected'
          };
        }
        if (db === ((_ref = mediator.project) != null ? _ref.id : void 0) || db === _this.fetchingCentralProjectId) {
          return;
        }
        if (__indexOf.call(mediator.user.getReplicatedProjects(), db) < 0) {
          return W.resolve();
        }
        return W.delay(breakLength, _this._centralReplica(db).info().then(function(remoteInfo) {
          var replication;
          _this.publishEvent('replication.bgChanges.projectChange', {
            projectId: remoteInfo.db_name,
            lastSeq: null,
            updateSeq: remoteInfo.update_seq
          });
          replication = _this._centralReplica(db).replicate.to(_this._localReplica(db), {
            batch_size: 5,
            batch_limit: 2
          }).on('change', function(change) {
            return _this.publishEvent('replication.bgChanges.projectChange', {
              projectId: remoteInfo.db_name,
              lastSeq: change.last_seq,
              updateSeq: remoteInfo.update_seq
            });
          });
          _this.bgReplications[db] = replication;
          return replication.then(function(info) {
            return {
              info: info,
              remoteInfo: remoteInfo
            };
          })["finally"](function() {
            return delete _this.bgReplications[db];
          });
        })).then(function(_arg) {
          var info, remoteInfo;
          info = _arg.info, remoteInfo = _arg.remoteInfo;
          _this.publishEvent('replication.bgChanges.projectChange', {
            projectId: remoteInfo.db_name,
            lastSeq: info.last_seq,
            updateSeq: remoteInfo.update_seq
          });
          if (info.docs_written > 0) {
            _this.publishEvent('replication.bgChanges.project', {
              timestamp: info.end_time,
              changes: info.docs_written,
              project: db
            });
            return db;
          }
        })["catch"](function(e) {
          return _this._handleProjectReplicationError(e, db);
        });
      };
    })(this);
  };

  ReplicationService.prototype._replicateBackgroundProjects = function() {
    var backgroundProjects, guardedTasks, p, _ref;
    backgroundProjects = _.chain(mediator.user.getReplicatedProjects()).without((_ref = mediator.project) != null ? _ref.id : void 0).without(this.fetchingCentralProjectId).value();
    guardedTasks = (function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = backgroundProjects.length; _i < _len; _i++) {
        p = backgroundProjects[_i];
        _results.push(this._guardBackgroundTask(this._replicateBackgroundProject(p, this._bgReplicationBreakLength())));
      }
      return _results;
    }).call(this);
    return W.parallel(guardedTasks).then((function(_this) {
      return function(infos) {
        var changedProjects;
        changedProjects = _(infos).compact();
        if (changedProjects) {
          _this.publishEvent('replication.bgChanges.allProjects', {
            projects: changedProjects
          });
        }
        return _this.timeouts.bgProjects = setTimeout(_this._replicateBackgroundProjects, _this.bgSyncPeriod);
      };
    })(this), (function(_this) {
      return function(error) {
        if ((error != null ? error.reason : void 0) === 'disconnected') {

        } else {
          return _this._handleError(error);
        }
      };
    })(this));
  };

  ReplicationService.prototype._userSessionsFilter = function() {
    return {
      filter: function(doc) {
        var _ref;
        return (_ref = doc._id, __indexOf.call(USER_SESSIONS_DOCS, _ref) < 0) && !(doc._id.indexOf('u_') === 0 && doc._deleted);
      }
    };
  };

  ReplicationService.prototype._replicateLocalUdb = function() {
    var localReplica, replicationOptions, udbName;
    udbName = this.udbService.getUdbName(mediator.user.get('name'));
    localReplica = this._localReplica(udbName);
    replicationOptions = _.extend({}, this._liveReplicationOpts(), this._userSessionsFilter());
    return this.localUdbReplication = localReplica.replicate.to(this._centralReplica(udbName), replicationOptions).on('change', this._localUdbReplicated(udbName)).on('error', this._handleError);
  };

  ReplicationService.prototype._localUdbReplicated = function(dbName) {
    return (function(_this) {
      return function(change) {
        return _this.publishEvent('localChangesSent', {
          db_name: dbName,
          last_seq: change.last_seq
        });
      };
    })(this);
  };

  ReplicationService.prototype._replicateCentralUdb = function(firstReplication) {
    var replicationOptions, udbName;
    if (firstReplication == null) {
      firstReplication = false;
    }
    udbName = this.udbService.getUdbName(mediator.user.get('name'));
    if (firstReplication) {
      this.centralUdbReplication = null;
      return this._centralReplica(udbName).replicate.to(this._localReplica(udbName), this._userSessionsFilter()).then(this._replicateCentralUdb.bind(this, false), this._handleError);
    } else {
      replicationOptions = _.extend({}, this._liveReplicationOpts(), this._userSessionsFilter());
      return this.centralUdbReplication = this._centralReplica(udbName).replicate.to(this._localReplica(udbName), replicationOptions).on('error', this._handleError);
    }
  };

  ReplicationService.prototype._replicatePreviousProjectDb = function() {
    var centralReplica, localReplica, projectName;
    if (!this.currentlyReplicatedProjectName) {
      return;
    }
    projectName = _(this.currentlyReplicatedProjectName).clone();
    localReplica = this._localReplica(projectName);
    centralReplica = this._centralReplica(projectName);
    return localReplica.replicate.to(centralReplica).on('complete', (function(_this) {
      return function(change) {
        return _this.publishEvent('localChangesSent', {
          db_name: projectName,
          last_seq: change.last_seq
        });
      };
    })(this));
  };

  ReplicationService.prototype._stopForegroundProjectReplication = function() {
    var _ref, _ref1;
    if ((_ref = this.localFgProjectReplication) != null) {
      _ref.cancel();
    }
    return (_ref1 = this.centralFgProjectReplication) != null ? _ref1.cancel() : void 0;
  };

  ReplicationService.prototype._fgProjectReplicated = function(dbName) {
    return (function(_this) {
      return function(change) {
        var _ref;
        _this.publishEvent('replication.backedUp', {
          timestamp: (_ref = change.end_time) != null ? _ref : _this.clock.getTimestamp()
        });
        return _this.publishEvent('localChangesSent', {
          db_name: dbName,
          last_seq: change.last_seq
        });
      };
    })(this);
  };

  ReplicationService.prototype._replicateForegroundProject = function() {
    var centralReplica, localReplica, name;
    this._stopForegroundProjectReplication();
    if (!mediator.project) {
      return;
    }
    name = mediator.project.id;
    centralReplica = this._centralReplica(name);
    localReplica = this._localReplica(name);
    this.currentlyReplicatedProjectName = name;
    this.localFgProjectReplication = localReplica.replicate.to(centralReplica, this._liveReplicationOpts()).on('change', this._fgProjectReplicated(name)).on('error', this._handleError);
    this.centralFgProjectReplication = centralReplica.replicate.to(localReplica, this._liveReplicationOpts()).on('error', this._handleError);
    return W.resolve();
  };

  return ReplicationService;

})(machina.Fsm);
