Rating = createReactClass
  displayName: "Rating"

  propTypes:
    onRate: PropTypes.func
    rating: PropTypes.number
    maxRate: PropTypes.number
    readOnly: PropTypes.bool

  getInitialState: ->
    localRating: 0

  getDefaultProps: ->
    rating: 0
    maxRate: 5
    onRate: null
    readOnly: false

  mouseOverRating: (i) -> =>
    return if @props.rating
    @setState localRating: i

  onMouseLeave: ->
    @setState localRating: 0

  setRating: (i) -> =>
    { rating, onRate } = @props
    newRating = if i is rating then 0 else i
    onRate newRating if onRate

  render: ->
    { maxRate, rating, readOnly } = @props
    { localRating } = @state

    <div className="gdt-rating-container">
      {_([1..maxRate]).map (i) =>
        active = if rating then rating >= i else localRating >= i
        <span
          key={i}
          className={
            classNames(
              "gdt-rating-element",
              "gdt-rating-element--active": active
            )
          }
          onMouseOver={@mouseOverRating(i) unless readOnly}
          onMouseLeave={@onMouseLeave unless readOnly}
          onClick={@setRating(i) unless readOnly}
        />
      }
    </div>

module.exports = Rating
