Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Button = require 'components/common/button'

AlgorithmsToolbar = createReactClass
  displayName: 'AlgorithmsToolbar'

  mixins: [Translation('toolbar'), CustomRenderMixin]

  propTypes:
    addAlgorithm: PropTypes.func.isRequired

  render: ->
    <div className='algorithms-toolbar'>
      <div className='row'>
        <div className='text-right'>
          <Button
            className='btn add-new-algorithm'
            label={@i18n 'new_algorithm'}
            onClick={@props.addAlgorithm}
          />
        </div>
      </div>
    </div>

module.exports = AlgorithmsToolbar
