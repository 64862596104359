var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<table class=\"standard-table recommendation-table\" width=\"100%\">\n  <caption>\n    <h1>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "recommendation.title", {"name":"i18n","hash":{},"data":data})))
    + "\n    </h1>\n    <h2>"
    + escapeExpression(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"question","hash":{},"data":data}) : helper)))
    + "</h2>\n  </caption>\n  <tbody>\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "consequences_balance_dx", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "recommendation_dx", {"name":"i18nNamespace","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    <tr>\n      <td>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "panel_decisions", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td colspan=\"6\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "panelDecisionsText", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n    <tr>\n      <td>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "recommendation_text", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td colspan=\"6\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "recommendationText", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n    <tr>\n      <td>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "justification", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td colspan=\"6\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "recommendationJustification", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n    <tr>\n      <td>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "implementation_considerations", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td colspan=\"6\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "implementationConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n    <tr>\n      <td>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "monitoring_and_evaluation", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td colspan=\"6\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "monitoringAndEvaluation", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n    <tr>\n      <td>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "research_priorities", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n      <td colspan=\"6\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "researchPriorities", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n    </tr>\n  </tbody>\n</table>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <tr>\n      <td>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "\n      </td>\n";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "clearly_undesirable", "undesirable", "uncertain_balance", "similar_consequences", "desirable", "clearly_desirable", {"name":"forList","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </tr>\n    <tr class=\"radio-group\">\n      <td></td>\n";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "clearly_undesirable", "undesirable", "uncertain_balance", "similar_consequences", "desirable", "clearly_desirable", {"name":"forList","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tr>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "        <td>";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</td>\n";
},"5":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <td class=\"radio-input\"><input type=\"radio\" name=\"consequencesBalance\"\n          data-bind-attr=\"true\" value=\""
    + escapeExpression(((helper = (helper = helpers.it || (depth0 != null ? depth0.it : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"it","hash":{},"data":data}) : helper)))
    + "\"></td>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "    <tr>\n      <td></td>\n";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "strongly_negative", "negative", "neutral", "positive", "strongly_positive", {"name":"forList","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </tr>\n    <tr class=\"radio-group\">\n      <td></td>\n";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "strongly_negative", "negative", "neutral", "positive", "strongly_positive", {"name":"forList","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tr>\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <td ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "neutral", {"name":"is","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "><em>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), {"name":"i18n","hash":{},"data":data})))
    + "</em></td>\n";
},"9":function(depth0,helpers,partials,data) {
  return "colspan=\"2\"";
  },"11":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "        <td class=\"radio-input\" ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "neutral", {"name":"is","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "neutral", {"name":"isnt","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </td>\n";
},"12":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <input type=\"radio\" name=\"recommendationType\" data-bind-attr=\"true\" value=\""
    + escapeExpression(((helper = (helper = helpers.it || (depth0 != null ? depth0.it : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"it","hash":{},"data":data}) : helper)))
    + "\">\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:recommendations.table", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}