var ReactComponent, ViewTrait, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('./view_trait');

alt = require('alt');

mediator = require('mediator');

module.exports = ReactComponent = (function(_super) {
  __extends(ReactComponent, _super);

  function ReactComponent() {
    return ReactComponent.__super__.constructor.apply(this, arguments);
  }

  ReactComponent.prototype.apply = function(view) {
    ReactComponent.__super__.apply.apply(this, arguments);
    this._addFunction(view, 'renderComponent');
    this._addFunction(view, 'renderSimpleComponent');
    this._addFunction(view, 'unmountComponent');
    this._addFunction(view, '_createAndRenderComponent');
    return this._addFunction(view, 'updateRenderedComponent');
  };

  ReactComponent.prototype._createAndRenderComponent = function(originalFunction, trait, Component, props, el, onRenderCb) {
    return ReactDOM.render(React.createElement(Component, props), el, (function(_this) {
      return function() {
        _this._reactComponentRenderNode = el;
        _this.Component = Component;
        return typeof onRenderCb === "function" ? onRenderCb() : void 0;
      };
    })(this));
  };

  ReactComponent.prototype.updateRenderedComponent = function(originalFunction, trait, newProps, onRenderCb) {
    if (!(this.Component && this._reactComponentRenderNode)) {
      return;
    }
    return this._createAndRenderComponent(this.Component, newProps, this._reactComponentRenderNode, onRenderCb);
  };

  ReactComponent.prototype.renderComponent = function(_oF, _t, Component, Actions, ids, options, el) {
    var dbId, fetchParams, opts, params, _ref, _ref1;
    if (ids == null) {
      ids = null;
    }
    if (options == null) {
      options = {};
    }
    if (el == null) {
      el = this.el;
    }
    this._stopInputsPropagation = false;
    if (options.fetchAndListen) {
      _ref = options.fetchAndListen, dbId = _ref.dbId, opts = _ref.opts;
      fetchParams = options.fetchParams;
      Actions.fetch(_.extend({
        dbId: dbId,
        docId: ids
      }, fetchParams));
      this.listener = mediator.services.storePersistenceAdapter.setupListening(dbId, opts != null ? opts : {}, Actions.dbChange || Actions.fetch);
    } else if (ids) {
      params = {
        dbId: mediator.project.id,
        docId: ids
      };
      Actions.fetch(params);
      this.listener = mediator.services.storePersistenceAdapter.setupListening(mediator.project.id, options, function() {
        return Actions.fetch(params);
      });
    } else {
      Actions.fetch();
      this.modelBind('change', Actions.fetch);
    }
    return this._createAndRenderComponent(Component, (_ref1 = options.props) != null ? _ref1 : null, el, options.onRenderCb);
  };

  ReactComponent.prototype.renderSimpleComponent = function(originalFunction, trait, Component, props, el) {
    if (props == null) {
      props = {};
    }
    if (el == null) {
      el = this.el;
    }
    return this._createAndRenderComponent(Component, props, el);
  };

  ReactComponent.prototype.unmountComponent = function(originalFunction, trait) {
    var _ref;
    if ((_ref = this.listener) != null) {
      _ref.cancel();
    }
    this.listener = null;
    if (this._reactComponentRenderNode == null) {
      return;
    }
    ReactDOM.unmountComponentAtNode(this._reactComponentRenderNode);
    this._reactComponentRenderNode = null;
    return this.Component = null;
  };

  return ReactComponent;

})(ViewTrait);
