Assessment = require 'components/etd/assessment'
AssessmentWithPanelVoiceResults =
  require 'components/etd/panel_voice_etd/assessment_with_panel_voice_results'
ConclusionsWithPanelVoiceResults =
  require 'components/etd/panel_voice_etd/conclusions_with_panel_voice_results'
Conclusions = require 'components/etd/conclusions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Recommendation = require 'components/etd/recommendation'
TypeOfRecommendationWithPanelVoiceResults =
  require 'components/etd/panel_voice_etd/type_of_recommendation_with_panel_voice_results'
Translation = require 'components/mixins/translation'
{ getOrderedSections,
  getCurrentTORSectionId,
  prepareSectionsForPrintout
} = require 'lib/etd_helper'
{ bool, string, instanceOf, func } = PropTypes

HistoryEtdSections = createReactClass
  displayName: 'HistoryEtdSections'

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  propTypes:
    activeJudgmentOptionBySection: instanceOf(Immutable.Map).isRequired
    activeResultsViewTypeBySection: instanceOf(Immutable.Map).isRequired
    activeRound: string
    activeStage: string
    etdHighlightsByRoundMap: instanceOf(Immutable.Map).isRequired
    etdSnapshots: instanceOf(Immutable.Map).isRequired
    etdViewSettings: instanceOf(Immutable.Map).isRequired
    getAdminVotingSummary: func.isRequired
    hideVotingResults: bool.isRequired
    memberNamesMap: instanceOf(Immutable.Map).isRequired
    showRecommendationsPart: bool
    overarchingQuestionData: instanceOf(Immutable.Map)
    questionName: string.isRequired
    questionType: string.isRequired
    renderMode: string.isRequired
    templateId: string.isRequired
    votingResultsByTimestamp: instanceOf(Immutable.Map).isRequired
    votingStages: instanceOf(Immutable.List).isRequired

  getDefaultProps: ->
    adolopments: Immutable.Map()
    showRecommendationsPart: true
    overarchingQuestionData: Immutable.Map()

  _getEtdAttachments: (etdData) ->
    { etdSnapshots } = @props
    etdData.get('_attachments', Immutable.Map()).map (md5, attachmentId) ->
      etdSnapshots.getIn ['_attachments', md5]

  getAssessmentProps: (etdData) ->
    assessmentEtdData = etdData.getIn ['templateData', 'assessment']
    attachments = @_getEtdAttachments(etdData)
    { etdViewSettings, templateId } = @props

    props =
      additionalConsiderations: assessmentEtdData.get 'additionalConsiderations'
      additionalConsiderationsVisible: etdViewSettings.get('showAdditionalConsiderations', true)
      adolopments: Immutable.Map()
      adolopmentData: Immutable.Map()
      assessmentSections: getOrderedSections assessmentEtdData
      attachments: attachments
      collapsedAssessmentSections: @props.collapsedAssessmentSections
      conclusionsSections: getOrderedSections(etdData.getIn(['templateData', 'conclusions']))
      criterions: assessmentEtdData.get 'criteria'
      editable: false
      etdId: etdData.get("_id")
      etdViewSettings: @props.etdViewSettings
      questionType: @props.questionType
      renderMode: @props.renderMode
      researchEvidences: assessmentEtdData.get 'researchEvidences'
      templateId: templateId
      overarching: @props.type is 'overarching'
      overarchingQuestionData: @props.overarchingQuestionData
      sojStatus: 'closed'
      withSoj: false

    # clear sections if user want's to export without judgments
    criterions = prepareSectionsForPrintout props.criterions, 'assessment', @props.renderOptions
    _.extend props, { criterions }

  getConclusionsProps: (etdData) ->
    conclusionsSections = getOrderedSections(etdData.getIn(['templateData', 'conclusions'])).rest()

    props =
      adolopments: Immutable.Map()
      adolopmentData: Immutable.Map()
      assessmentSections: getOrderedSections etdData.getIn ['templateData', 'assessment']
      attachments: @_getEtdAttachments(etdData)
      editable: false
      etdId: etdData.get("_id")
      etdViewSettings: @props.etdViewSettings
      questionName: @props.questionName
      renderMode: @props.renderMode

    # clear sections if user wants to export without judgments
    conclusionsSections = prepareSectionsForPrintout conclusionsSections,
      'conclusion', @props.renderOptions
    _.extend(props, { conclusionsSections })

  getRecommendationProps: (etdData) ->
    conclusionsSections = getOrderedSections(etdData.getIn(['templateData', 'conclusions']))
    sectionId = getCurrentTORSectionId conclusionsSections
    section = conclusionsSections.get sectionId

    props =
      adolopments: Immutable.Map()
      adolopmentData: Immutable.Map()
      editable: false
      etdId: etdData.get("_id")
      etdViewSettings: @props.etdViewSettings
      overarchingQuestionData: @props.overarchingQuestionData
      questionName: @props.questionName
      templateId: @props.templateId
      renderMode: @props.renderMode
      votedEtdData: etdData.getIn ['templateData', 'conclusions', 'sections', sectionId]

    # clear sections if user want's to export without judgments
    section = prepareSectionsForPrintout conclusionsSections.get(sectionId),
      'recommendation', @props.renderOptions
    _.extend(props, { section, sectionId })

  sectionWithResults: (activeRound, section) ->
    { renderOptions, hideVotingResults } = @props
    return false if hideVotingResults
    return false if (renderOptions?[section].results.checked is false)
    activeRound isnt 'draft'

  getActiveStagePartNames: ->
    { activeStage, votingStages, activeRound } = @props

    activeStateParts = if activeStage is 'test'
      votingStages.reduce (rounds, stage) ->
        rounds.merge stage.get 'rounds'
      , Immutable.OrderedMap()
      .getIn [activeRound, 'parts']
    else
      stageRounds = votingStages.getIn([activeStage, 'rounds'])
      activeRoundParts = if activeRound is 'draft'
        stageRounds
          .filterNot (r) -> r.get('isTest')
          .first()
          .get('parts')
      else
        stageRounds
          .getIn([activeRound, 'parts'])

    activeStateParts.map (p) -> p.get 'name'

  _getInitialDraftTimestamp: ->
    # pick the first non-test round timestamp
    { votingStages, activeStage } = @props
    votingStages.getIn([activeStage, 'rounds'])
      .filterNot (round) -> round.get 'isTest'
      .keySeq()
      .first()
      .toString()

  render: ->

    {
      activeJudgmentOptionBySection
      activeResultsViewTypeBySection
      activeRound
      activeStage
      etdSnapshots
      getAdminVotingSummary
      hideVotingResults
      memberNamesMap
      overarchingQuestionData
      showRecommendationsPart
      votingResultsByTimestamp
      votingStages
    } = @props

    return <div>{@i18n 'no_voting_history'}</div> unless activeStage? and activeRound?

    activeStagePartNames = @getActiveStagePartNames()

    hasAssessmentVoted = activeStagePartNames.includes 'assessment'
    hasRecommendationVoted = activeStagePartNames.includes 'recommendation'
    hasConclusionsVoted = activeStagePartNames.includes 'conclusion'

    assessmentWithResults = @sectionWithResults(activeRound, 'assessment')
    recommendationWithResults = @sectionWithResults(activeRound, 'recommendation')
    conclusionsWithResults = @sectionWithResults(activeRound, 'conclusion')

    AssessmentComponent =
      if assessmentWithResults then AssessmentWithPanelVoiceResults else Assessment

    RecommendationComponent = if recommendationWithResults
      TypeOfRecommendationWithPanelVoiceResults
    else
      Recommendation

    ConclusionsComponent =
      if conclusionsWithResults then ConclusionsWithPanelVoiceResults else Conclusions

    etdData = etdSnapshots.get if activeRound is 'draft'
      @_getInitialDraftTimestamp()
    else
      "#{activeRound}"
    assessmentProps = @getAssessmentProps etdData
    recommendationProps = @getRecommendationProps etdData
    conclusionsProps = @getConclusionsProps etdData
    # mix in voting-related prop if this is not a draft option
    unless activeRound is 'draft' or hideVotingResults
      votingResults = votingResultsByTimestamp.get "#{activeRound}"
      etdHighlights = @props.etdHighlightsByRoundMap.get "#{activeRound}"

      assessmentProps = _.extend assessmentProps,
        activeJudgmentOptionBySection: activeJudgmentOptionBySection
        activeResultsViewTypeBySection: activeResultsViewTypeBySection
        etdHighlights: etdHighlights
        etdTab: 'history'
        editable: false
        getAdminVotingSummary: getAdminVotingSummary
        memberNamesMap: memberNamesMap
        votedEtdData: etdData.getIn ['templateData', 'assessment', 'criteria']
        votingResults: votingResults.get 'assessment'

      recommendationProps = _.extend recommendationProps,
        activeJudgmentOptionBySection: activeJudgmentOptionBySection
        activeResultsViewTypeBySection: activeResultsViewTypeBySection
        etdHighlights: etdHighlights
        etdTab: 'history'
        getAdminVotingSummary: getAdminVotingSummary
        memberNamesMap: memberNamesMap
        votingResults: votingResults.get 'recommendation'
        overarchingQuestionData

      conclusionsProps = _.extend conclusionsProps,
        activeJudgmentOptionBySection: activeJudgmentOptionBySection
        activeResultsViewTypeBySection: activeResultsViewTypeBySection
        etdHighlights: etdHighlights
        etdSnapshots: etdSnapshots
        etdTab: 'history'
        getAdminVotingSummary: getAdminVotingSummary
        memberNamesMap: memberNamesMap
        votingResults: votingResults.get 'conclusion'

    <div>
      {hasAssessmentVoted and
        <AssessmentComponent {...assessmentProps} />
      }

      {hasRecommendationVoted and showRecommendationsPart and
        <RecommendationComponent {...recommendationProps} />
      }

      {hasConclusionsVoted and
        <ConclusionsComponent {...conclusionsProps} />
      }
    </div>

module.exports = HistoryEtdSections
