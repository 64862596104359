var SectionLinkControl, chapterLinkMark, createControl, getDatasetAttrValFromNode, sectionLinkMark, sectionLinkTooltipPlugin;

sectionLinkTooltipPlugin = require('./section_link_tooltip_plugin')["default"];

SectionLinkControl = require('./section_link_control');

createControl = Epiditor.createControl;

getDatasetAttrValFromNode = function(attrName) {
  return function(node) {
    var attrVal;
    attrVal = node.dataset[attrName];
    if (attrVal) {
      return R.assoc(attrName, attrVal, {});
    }
  };
};

sectionLinkMark = function(urlProvider) {
  return {
    attrs: {
      sectionId: {}
    },
    inclusive: false,
    parseDOM: [
      {
        tag: 'a.section-link',
        getAttrs: getDatasetAttrValFromNode('sectionId')
      }, {
        tag: 'span.section-link',
        getAttrs: getDatasetAttrValFromNode('sectionId')
      }
    ],
    toDOM: function(mark) {
      var domAttrs, sectionId;
      sectionId = mark.attrs.sectionId;
      domAttrs = {
        "class": 'section-link',
        'data-sectionid': sectionId
      };
      if (urlProvider) {
        return [
          'a', _.extend(domAttrs, {
            href: urlProvider(sectionId)
          }), 0
        ];
      }
      return ['span', domAttrs, 0];
    }
  };
};

chapterLinkMark = function(urlProvider) {
  return {
    attrs: {
      chapterId: {}
    },
    inclusive: false,
    parseDOM: [
      {
        tag: 'a.chapter-link',
        getAttrs: getDatasetAttrValFromNode('chapterId')
      }, {
        tag: 'span.chapter-link',
        getAttrs: getDatasetAttrValFromNode('chapterId')
      }
    ],
    toDOM: function(mark) {
      var chapterId, domAttrs;
      chapterId = mark.attrs.chapterId;
      domAttrs = {
        "class": 'chapter-link',
        'data-chapterId': chapterId
      };
      if (urlProvider) {
        return [
          'a', _.extend(domAttrs, {
            href: urlProvider(chapterId)
          }), 0
        ];
      }
      return ['span', domAttrs, 0];
    }
  };
};

module.exports = function(sectionUrlProvider, chapterUrlProvider) {
  return {
    id: 'DocumentSectionsLinksExtension',
    schema: {
      marks: {
        sectionLink: sectionLinkMark(sectionUrlProvider),
        chapterLink: chapterLinkMark(chapterUrlProvider)
      }
    },
    plugins: [sectionLinkTooltipPlugin],
    toolbarControls: [createControl(SectionLinkControl)]
  };
};
