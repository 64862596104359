var AdaptiveLayout, CompareDuplicatesView, DuplicatesComparison, ModalView, ReactComponent,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

DuplicatesComparison = require('components/references/duplicates_comparison');

ReactComponent = require('base/lib/traits/react_component');

module.exports = CompareDuplicatesView = (function(_super) {
  __extends(CompareDuplicatesView, _super);

  function CompareDuplicatesView() {
    return CompareDuplicatesView.__super__.constructor.apply(this, arguments);
  }

  CompareDuplicatesView.prototype.className = 'compare-duplicates-view';

  CompareDuplicatesView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  CompareDuplicatesView.prototype.initialize = function() {
    this._stopInputsPropagation = false;
    this.options.position = 'top';
    this.options.offsetY = 50;
    this.options.width = 897;
    this.options.fadeInMs = 0;
    this.options.fadeOutMs = 0;
    this.delegate('click', 'button.close', this.dispose);
    this.enable(AdaptiveLayout);
    this.enable(ReactComponent);
    return CompareDuplicatesView.__super__.initialize.apply(this, arguments);
  };

  CompareDuplicatesView.prototype.afterRender = function() {
    CompareDuplicatesView.__super__.afterRender.apply(this, arguments);
    if (!this.modalRendered) {
      this.renderSimpleComponent(DuplicatesComparison, {
        duplicatesGroup: this.options.duplicatesGroup
      });
      _.defer((function(_this) {
        return function() {
          return _this.trigger('!fixLayout');
        };
      })(this));
    }
    return this.modalRendered = true;
  };

  CompareDuplicatesView.prototype.fixLayout = function() {
    var $comparatorArea;
    $comparatorArea = this.$el.find('.comparator-area');
    return $comparatorArea.height(Math.min($('#page-container').height() - 85, $comparatorArea.height()));
  };

  CompareDuplicatesView.prototype.dispose = function() {
    this.publishEvent('deduplicationCompleted');
    this.unmountComponent();
    return CompareDuplicatesView.__super__.dispose.apply(this, arguments);
  };

  return CompareDuplicatesView;

})(ModalView);
