var IEtdDiagnosticRecommendationsExporter, IEtdRecommendationsExporter,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

IEtdRecommendationsExporter = require('./ietd_recommendations_exporter');

module.exports = IEtdDiagnosticRecommendationsExporter = (function(_super) {
  __extends(IEtdDiagnosticRecommendationsExporter, _super);

  function IEtdDiagnosticRecommendationsExporter(_originalQuestion, pouchAdapter, referencesExporter) {
    var headerFieldsInQuestion, recommendationTypes, sectionTypes, _ref, _ref1;
    sectionTypes = {
      'problem': 'ProblemSection',
      'testAccuracy': 'TestAccuracySection',
      'desirableEffects': 'DesirableEffectsSection',
      'undesirableEffects': 'UndesirableEffectsSection',
      'certaintyOfEvidenceOfTestAccuracy': 'TestAccuracyCertaintyOfEvidenceSection',
      'certaintyOfEvidenceOfTestEffects': 'TestEffectsCertaintyOfEvidenceSection',
      'certaintyOfEvidenceOfManagementEffects': 'ManagementEffectsCertaintyOfEvidenceSection',
      'certaintyOfEvidenceOfTestResult': 'TestResultManagementEffectsCertaintyOfEvidenceSection',
      'certaintyOfEffects': 'EffectsCertaintyOfEvidenceSection',
      'values': 'ValuesSection',
      'balanceOfEffects': 'BalanceOfEffectsSection',
      'resourcesRequired': 'ResourcesRequiredSection',
      'certaintyOfEvidenceOfRequiredResources': 'ResourcesRequiredCertaintyOfEvidenceSection',
      'costEffectiveness': 'CostEffectivenessSection',
      'equity': 'EquitySection',
      'acceptability': 'AcceptabilitySection',
      'feasibility': 'FeasibilitySection'
    };
    headerFieldsInQuestion = {
      'population': (_ref = _originalQuestion['healthProblemOrPopulation']) != null ? _ref : '',
      'setting': (_ref1 = _originalQuestion['settings']) != null ? _ref1 : '',
      'anticipatedOutcomes': null,
      'perspective': null,
      'purpose': null,
      'background': null,
      'linkedTreatments': null
    };
    recommendationTypes = {
      'recommend_against': 'RecommendAgainstIntervention',
      'suggest_against': 'SuggestAgainstIntervention',
      'suggest_either': 'SuggestEither',
      'suggest': 'SuggestIntervention',
      'recommend': 'RecommendIntervention'
    };
    IEtdDiagnosticRecommendationsExporter.__super__.constructor.call(this, _originalQuestion, pouchAdapter, headerFieldsInQuestion, true, sectionTypes, recommendationTypes, referencesExporter);
  }

  return IEtdDiagnosticRecommendationsExporter;

})(IEtdRecommendationsExporter);
