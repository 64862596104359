ConnectStore = require 'components/enhancers/connect_store'
ImportStore = require 'stores/import_store'
ImportActions = require 'actions/import_actions'
ImportFileInput = require 'components/import_dialog/import_file_input'
OverReviewImportModal = require 'components/over_review/over_review_import_modal'

storeConnectors =
  ImportStore: (Store) ->
    fileData: Store.getFileData()
    questions: Store.getQuestions()
    studies: Store.getStudies()
    isImporting: Store.isImporting()
    isParsing: Store.isParsing()
    selectedItems: Store.getSelectedItems()

OverReviewImport = createReactClass
  displayName: 'OverReviewImport'

  _fileInputRef: (el) ->
    @fileInput = el

  importFile: ->
    @fileInput.click()

  handleModalClose: ->
    ImportActions.closeImportModal()
    @fileInput.reset()

  componentDidUpdate: (prevProps, prevState) ->
    if @props.fileData isnt prevProps.fileData and @props.fileData?.get('file')?
      rawImportData = ImportStore.getRawImportData()
      rawImportData? and ImportActions.parseQuestions rawImportData

  render: ->
    { fileData, questions, isParsing, isImporting, studies } = @props
    <div>
      <ImportFileInput ref={@_fileInputRef} />
      {if fileData.get('file')?
        <OverReviewImportModal
          fileName={fileData.get 'fileName'}
          isLoading={isParsing or isImporting}
          onClose={@handleModalClose}
          onSave={@props.onSave}
          questions={questions}
          studies={studies}
        />
      }
    </div>

module.exports = ConnectStore OverReviewImport, [ImportStore], storeConnectors
