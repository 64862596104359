List = require 'components/common/list'

EditableItemsList = createReactClass
  displayName: 'EditableItemsList'

  edit: (itemKey) ->
    @props.actions.edit itemKey, @props.itemsMap.get(@props.editingKey)

  save: (item) ->
    @props.actions.save item

  delete: (itemKey) ->
    @props.actions.delete itemKey

  cancel: (itemKey) ->
    @save @props.itemsMap.get(itemKey)

  render: ->
    <List ordered={@props.ordered ? true}>
      {@props.itemsMap.map((item, key) =>
        <@props.ItemComponent
          key={key}
          itemKey={key}
          item={item}
          editing={@props.editingKey is key}
          save={@save}
          delete={@delete}
          edit={@edit}
          cancel={@cancel}
          itemValidationErrors={@props.validationErrors?.get(key)}
        />).toList()
      }
    </List>

module.exports = EditableItemsList
