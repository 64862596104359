var EtdActions, EtdStore, PouchSource, QuestionsStore, W, alt, blockDataPath, consensusBasedFieldName, detailedJudgementsDataPath, embeddedContentProvider, ensureResoursesExist, errorHandler, generateGUID, getEditorContentAttachmentsList, getOrderedSections, getRecommendationTypeSections, immutable, mediator, templateDataPath, updateStoredAttachments, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

immutable = require('stores/utils/immutable');

embeddedContentProvider = require('lib/embedded_content');

EtdActions = require('actions/etd_actions');

PouchSource = require('stores/sources/pouch_source');

QuestionsStore = require('stores/questions_store');

errorHandler = require('lib/app_utils').errorHandler;

generateGUID = require('base/lib/utils').generateGUID;

_ref = require('lib/etd_helper'), getOrderedSections = _ref.getOrderedSections, getRecommendationTypeSections = _ref.getRecommendationTypeSections;

mediator = require('mediator');

W = require('when/when');

templateDataPath = ['template', 'templateData'];

blockDataPath = function(blockName) {
  return templateDataPath.concat(blockName);
};

detailedJudgementsDataPath = function(sectionId) {
  return blockDataPath('assessment').concat(['criteria', sectionId, 'details']);
};

ensureResoursesExist = function(path, state, field) {
  var draftResources, hasUnsetConsensusResourses;
  hasUnsetConsensusResourses = field === 'resourcesConsensus' && !state.getIn(path);
  if (hasUnsetConsensusResourses) {
    draftResources = state.getIn(path.slice(0, -1).concat(['resources']));
    return state.setIn(path, draftResources);
  } else {
    return state;
  }
};

consensusBasedFieldName = function(fieldName, isConsensus) {
  if (isConsensus) {
    return "" + fieldName + "Consensus";
  } else {
    return fieldName;
  }
};

getEditorContentAttachmentsList = function(editorContent) {
  var entityMap;
  if (!editorContent) {
    return null;
  }
  entityMap = editorContent.get('entityMap');
  if (entityMap) {
    return entityMap.reduce(function(result, entityData) {
      if (entityData.get('type') === 'IMAGE') {
        return result.push(entityData.getIn(['data', 'imageId']));
      } else {
        return result;
      }
    }, Immutable.List());
  } else {
    return Immutable.List();
  }
};

updateStoredAttachments = function(state, editorContent, identifier, sectionId, isConsensus) {
  var activeAttachments, anotherTabAttachments, anotherTabContent, attachmentsPath, editorAttachments, fieldName, storedAttachments;
  attachmentsPath = ['template', '_attachments'];
  storedAttachments = state.getIn(attachmentsPath, Immutable.Map());
  if (storedAttachments.isEmpty()) {
    return state;
  }
  editorAttachments = getEditorContentAttachmentsList(editorContent);
  fieldName = consensusBasedFieldName('content', !isConsensus);
  anotherTabContent = state.getIn(['template', 'templateData', 'assessment', identifier, sectionId, fieldName]);
  anotherTabAttachments = getEditorContentAttachmentsList(anotherTabContent);
  if (anotherTabAttachments && !anotherTabAttachments.isEmpty()) {
    editorAttachments = editorAttachments.concat(anotherTabAttachments);
  }
  activeAttachments = storedAttachments.filter(function(attachmentData, attachmentName) {
    var attachmentIdentifier, attachmentIdentifierWithFilename, attachmentSectionId, _ref1;
    _ref1 = attachmentName.split('#'), attachmentSectionId = _ref1[0], attachmentIdentifierWithFilename = _ref1[1];
    attachmentIdentifier = attachmentIdentifierWithFilename.split('/')[0];
    if (attachmentSectionId !== sectionId || attachmentIdentifier !== identifier) {
      return true;
    }
    return editorAttachments.contains(attachmentName);
  });
  if (storedAttachments.size !== activeAttachments.size) {
    return state.setIn(attachmentsPath, activeAttachments);
  } else {
    return state;
  }
};

EtdStore = (function() {
  function EtdStore() {
    var stateJS;
    stateJS = {
      _meta: {
        activeContentEditableId: '',
        initialSelectedOutcomes: {},
        dbId: '',
        isHeaderEditing: false,
        presentationType: 'clinicians',
        isFetchingEtd: false,
        isEditable: true,
        isIsofVisibleInPremiumView: false,
        shouldShowDefaultTemplateDialog: false
      },
      template: {}
    };
    this.state = Immutable.fromJS(stateJS);
    this.exportPublicMethods({
      isEditable: this.isEditable,
      getQuestion: this.getQuestion,
      getBackgroundDetailSections: this.getBackgroundDetailSections,
      getRecommendation: this.getRecommendation,
      getAssessment: this.getAssessment,
      getTemplate: this.getTemplate,
      getTemplateId: this.getTemplateId,
      getAssessmentSections: this.getAssessmentSections,
      getConclusionsSections: this.getConclusionsSections,
      getCriterion: this.getCriterion,
      getConclusions: this.getConclusions,
      getCriterions: this.getCriterions,
      getResearchEvidences: this.getResearchEvidences,
      getResearchEvidence: this.getResearchEvidence,
      getAdditionalConsiderations: this.getAdditionalConsiderations,
      getAdditionalConsideration: this.getAdditionalConsideration,
      isTemplateUndefined: this.isTemplateUndefined,
      isTemplateEmpty: this.isTemplateEmpty,
      getActiveContentEditable: this.getActiveContentEditable,
      getAttachments: this.getAttachments,
      getSelectedOptionText: this.getSelectedOptionText,
      getHeaderSections: this.getHeaderSections,
      getCriterionDescription: this.getCriterionDescription,
      isFetching: this.isFetching,
      getSojStatus: this.getSojStatus,
      isHeaderEditing: this.isHeaderEditing,
      isHeaderExpanded: this.isHeaderExpanded,
      getInitialSelectedOutcomes: this.getInitialSelectedOutcomes,
      getRelatedQuestionsIds: this.getRelatedQuestionsIds,
      getHiddenRelatedQuestions: this.getHiddenRelatedQuestions,
      getPresentationSection: this.getPresentationSection,
      getPresentationType: this.getPresentationType,
      getPresentationTitle: this.getPresentationTitle,
      getPresentationData: this.getPresentationData,
      getEtdId: this.getEtdId,
      isIsofVisibleInPremiumView: this.isIsofVisibleInPremiumView,
      shouldShowDefaultTemplateDialog: this.shouldShowDefaultTemplateDialog,
      getRecommendationMetadata: this.getRecommendationMetadata,
      isAnyRecommendationMetadataFilled: this.isAnyRecommendationMetadataFilled
    });
    this.bindActions(EtdActions);
    this.registerAsync(PouchSource(EtdActions));
  }

  EtdStore.prototype.onDbChange = function(etdDoc) {
    return this.setState(this.state.set('template', Immutable.fromJS(etdDoc)));
  };

  EtdStore.prototype.onSave = function() {
    return this._save();
  };

  EtdStore.prototype.onFetch = function(_arg) {
    var dbId;
    dbId = _arg.dbId;
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'dbId'], dbId);
      return state.setIn(['_meta', 'isFetchingEtd'], true);
    }));
  };

  EtdStore.prototype.onFetchSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      var _ref1, _ref2;
      state.setIn(['_meta', 'templateUndefined'], false);
      state.setIn(['_meta', 'isFetchingEtd'], false);
      state.setIn(['_meta', 'presentationType'], _.first((data != null ? (_ref1 = data.templateData) != null ? (_ref2 = _ref1.presentations) != null ? _ref2.sectionsOrder : void 0 : void 0 : void 0) || ['clinicians']));
      return state.set('template', Immutable.fromJS(data));
    }));
  };

  EtdStore.prototype.onFetchError = function(err) {
    this.setState(this.state.setIn(['_meta', 'isFetchingEtd'], false));
    if (err.status !== 404) {
      return errorHandler(err);
    }
  };

  EtdStore.prototype.onSetTemplateWithDataForPdf = function(etd) {
    return this.setState(this.state.set('template', Immutable.fromJS(etd)));
  };

  EtdStore.prototype.onPouchSaveError = errorHandler;

  EtdStore.prototype.onPouchPutAttachmentError = errorHandler;

  EtdStore.prototype.getTemplate = function() {
    return this.state.get('template');
  };

  EtdStore.prototype.getTemplateId = function() {
    return this.state.getIn(['template', 'templateDef', 'id'], '');
  };

  EtdStore.prototype.getEtdId = function() {
    return this.state.getIn(['template', '_id'], '');
  };

  EtdStore.prototype.isEditable = function() {
    return this.state.getIn(['_meta', 'isEditable']);
  };

  EtdStore.prototype.getPresentationTitle = function() {
    return this.state.getIn(['template', 'templateData', 'presentations', 'presentationTitle']);
  };

  EtdStore.prototype.getPresentationData = function() {
    return this.state.getIn(['template', 'templateData', 'presentations', 'sections'], Immutable.List());
  };

  EtdStore.prototype.getQuestion = function() {
    return getOrderedSections(this.state.getIn(['template', 'templateData', 'question']));
  };

  EtdStore.prototype.getPresentationSection = function(presentationType) {
    var enabledSections, sections;
    if (this.isFetching() || this.isTemplateUndefined() || this.state.get('template').isEmpty()) {
      return Immutable.Map();
    }
    enabledSections = this.state.getIn(['template', 'templateDef', 'presentations'], new Immutable.Map()).find(function(type) {
      return type.get('id') === presentationType;
    }, null, Immutable.Map()).get('sections', new Immutable.Map()).filter(function(section) {
      return section.get('checked');
    }).reduce(function(acc, section) {
      return acc.concat(section.get('id'));
    }, []);
    sections = this.state.getIn(['template', 'templateData', "presentations", 'sections', presentationType]);
    return getOrderedSections(sections).filter(function(section, key) {
      return __indexOf.call(enabledSections, key) >= 0;
    });
  };

  EtdStore.prototype.getBackgroundDetailSections = function() {
    return getOrderedSections(this.state.getIn(['template', 'templateData', 'presentations', 'sections', 'policymakers', 'sections', 'background', 'details']));
  };

  EtdStore.prototype.getRecommendation = function() {
    var conclusions, conclusionsSections, content, contentSectionId, getSectionIdFromTemplateDef, option, optionSectionId, recommendation, recommendationContentSections, recommendationOptionSections, sectionContent, selectedOption;
    conclusions = this.state.getIn(['template', 'templateDef', 'conclusions']);
    recommendationOptionSections = getRecommendationTypeSections();
    recommendationContentSections = ['decision', 'recommendation'];
    getSectionIdFromTemplateDef = function(sections) {
      var _ref1;
      return conclusions != null ? (_ref1 = conclusions.find(function(s) {
        var _ref2;
        return _ref2 = s.get('id'), __indexOf.call(sections, _ref2) >= 0;
      })) != null ? _ref1.get('id') : void 0 : void 0;
    };
    optionSectionId = getSectionIdFromTemplateDef(recommendationOptionSections);
    contentSectionId = getSectionIdFromTemplateDef(recommendationContentSections);
    conclusionsSections = this.state.getIn(['template', 'templateData', 'conclusions', 'sections']);
    sectionContent = conclusionsSections != null ? conclusionsSections.get(contentSectionId) : void 0;
    option = conclusionsSections != null ? conclusionsSections.get(optionSectionId) : void 0;
    content = (sectionContent != null ? sectionContent.get('contentConsensus') : void 0) || (sectionContent != null ? sectionContent.get('content') : void 0);
    selectedOption = (option != null ? option.get('selectedOptionConsensus') : void 0) || (option != null ? option.get('selectedOption') : void 0);
    return recommendation = {
      content: content,
      selectedOption: selectedOption
    };
  };

  EtdStore.prototype.onUpdateRecommendationContent = function(_arg) {
    var content, presentationType;
    presentationType = _arg.presentationType, content = _arg.content;
    this.setState(this.state.setIn(['template', 'templateData', 'presentations', 'sections', presentationType, 'sections', 'recommendation', 'content'], content));
    return this._save();
  };

  EtdStore.prototype.getAssessment = function() {
    return this.state.getIn(['template', 'templateData', 'assessment']);
  };

  EtdStore.prototype.getAssessmentSections = function() {
    return getOrderedSections(this.state.getIn(['template', 'templateData', 'assessment']));
  };

  EtdStore.prototype.getConclusions = function() {
    return this.state.getIn(['template', 'templateData', 'conclusions'], Immutable.Map());
  };

  EtdStore.prototype.getConclusionsSections = function() {
    return getOrderedSections(this.state.getIn(['template', 'templateData', 'conclusions']));
  };

  EtdStore.prototype.getCriterion = function(criterionId) {
    return this.state.getIn(['template', 'templateData', 'assessment', 'criteria', criterionId]);
  };

  EtdStore.prototype.getCriterions = function() {
    return this.state.getIn(['template', 'templateData', 'assessment', 'criteria']);
  };

  EtdStore.prototype.getResearchEvidences = function() {
    return this.state.getIn(['template', 'templateData', 'assessment', 'researchEvidences']);
  };

  EtdStore.prototype.getResearchEvidence = function(researchEvidenceId) {
    return this.state.getIn(['template', 'templateData', 'assessment', 'researchEvidences', researchEvidenceId]);
  };

  EtdStore.prototype.getAdditionalConsiderations = function() {
    return this.state.getIn(['template', 'templateData', 'assessment', 'additionalConsiderations']);
  };

  EtdStore.prototype.getAdditionalConsideration = function(additionalConsiderationId) {
    return this.state.getIn(['template', 'templateData', 'assessment', 'additionalConsiderations', additionalConsiderationId]);
  };

  EtdStore.prototype.onToggleRelatedQuestion = function(qId) {
    var path;
    path = ['template', 'templateData', 'hiddenRelatedQuestions', qId];
    this.setState(this.state.setIn(path, !this.state.getIn(path, false)));
    return this._save();
  };

  EtdStore.prototype.getHiddenRelatedQuestions = function() {
    return this.state.getIn(['template', 'templateData', 'hiddenRelatedQuestions'], Immutable.Map());
  };

  EtdStore.prototype.getRelatedQuestionsIds = function() {
    return this.state.getIn(['template', 'templateData', 'relatedQuestionsIds'], Immutable.List());
  };

  EtdStore.prototype.onUpdateRelatedQuestionsIds = function(relatedQuestionsIds) {
    this.setState(this.state.setIn(['template', 'templateData', 'relatedQuestionsIds'], relatedQuestionsIds));
    return this._save();
  };

  EtdStore.prototype.getAttachments = function(sectionId) {
    var attachments;
    if (sectionId == null) {
      sectionId = '';
    }
    attachments = this.state.getIn(['template', '_attachments']);
    if (!attachments) {
      return Immutable.Map();
    }
    if (sectionId) {
      return attachments.filter(function(attachment, attachmentName) {
        return attachmentName.match("" + sectionId + "/");
      });
    } else {
      return attachments;
    }
  };

  EtdStore.prototype.getHeaderSections = function() {
    return getOrderedSections(this.state.getIn(['template', 'templateData', 'question']));
  };

  EtdStore.prototype.getCriterionDescription = function(sectionId) {
    var sectionsCriterion;
    sectionsCriterion = this.state.getIn(blockDataPath('assessment').concat(['criteria', sectionId]));
    return sectionsCriterion != null ? sectionsCriterion.get('description') : void 0;
  };

  EtdStore.prototype.getSelectedOptionText = function(sectionId) {
    var option, sectionsCriterion, selectedOption;
    sectionsCriterion = this.state.getIn(blockDataPath('assessment').concat(['criteria', sectionId]));
    if (!sectionsCriterion) {
      return;
    }
    selectedOption = sectionsCriterion.get('selectedOption');
    option = sectionsCriterion.get('options').concat(sectionsCriterion.get('additionalOptions')).find(function(option) {
      return option.get('value') === selectedOption;
    });
    if (!option) {
      return;
    }
    return option.get('text');
  };

  EtdStore.prototype.getSojStatus = function() {
    return this.state.getIn(['_meta', 'sojStatus'], 'closed');
  };

  EtdStore.prototype.isHeaderExpanded = function() {
    return this.state.getIn(['template', 'templateData', 'question', 'expanded'], false);
  };

  EtdStore.prototype.isHeaderEditing = function() {
    return this.state.getIn(['_meta', 'isHeaderEditing']);
  };

  EtdStore.prototype.getRecommendationMetadata = function(formFields) {
    var data, fields;
    data = this.state.getIn(['template', 'templateData', 'conclusions', 'sections', 'recommendation']);
    if (data == null) {
      data = Immutable.Map();
    }
    fields = _.reduce(formFields, function(acc, group) {
      return acc.concat(group);
    }, []);
    return _.chain(fields).map(function(_arg) {
      var fieldKey, type, value;
      fieldKey = _arg.fieldKey, type = _arg.type;
      value = data.get(fieldKey);
      if (type === 'multiSelect') {
        value = _.isString(value) ? Immutable.List([value]) : value;
        value = (value != null ? value : Immutable.List()).toJS();
      }
      return [fieldKey, value];
    }).object().value();
  };

  EtdStore.prototype.isAnyRecommendationMetadataFilled = function(formFields) {
    var data, fields;
    data = this.getRecommendationMetadata(formFields);
    fields = _.reduce(formFields, function(acc, group) {
      return acc.concat(group);
    }, []);
    return _.some(fields, function(_arg) {
      var fieldKey, type;
      fieldKey = _arg.fieldKey, type = _arg.type;
      if (type === 'multiSelect') {
        return !_.isEmpty(data[fieldKey]);
      } else {
        return data[fieldKey] != null;
      }
    });
  };

  EtdStore.prototype.onUpdateRecommendationMetadata = function(_arg) {
    var field, path, value, valueToSave;
    field = _arg.field, value = _arg.value;
    valueToSave = _.isArray(value) ? Immutable.fromJS(value) : value;
    path = ['template', 'templateData', 'conclusions', 'sections', 'recommendation', field];
    return this.setState(this.state.setIn(path, valueToSave));
  };

  EtdStore.prototype.onSetEditableFlag = function(editable) {
    return this.setState(this.state.setIn(['_meta', 'isEditable'], editable));
  };

  EtdStore.prototype.onToggleEditableFlag = function() {
    return this.setState(this.state.setIn(['_meta', 'isEditable'], !this.isEditable()));
  };

  EtdStore.prototype.onSwitchCellContentMode = function(sectionId) {
    var dataPath, newMode, _ref1;
    dataPath = ['template', 'templateData', 'question', 'sections', sectionId, 'autoContentGenerationOn'];
    newMode = !((_ref1 = this.state.getIn(dataPath)) != null ? _ref1 : true);
    return this.setState(this.state.setIn(dataPath, newMode));
  };

  EtdStore.prototype.onUpdateBackground = function(data) {
    var dataPath, sectionId, sectionPart, _ref1;
    _ref1 = data.attr.split('-'), sectionId = _ref1[0], sectionPart = _ref1[1];
    dataPath = (function() {
      switch (sectionId) {
        case 'background':
          return ['template', 'templateData', 'question', 'sections', sectionPart, 'content'];
        case 'details':
          return ['template', 'templateData', 'presentations', 'sections', 'policymakers', 'sections', 'background', 'details', 'sections', sectionPart, 'content'];
      }
    })();
    return this.setState(this.state.setIn(dataPath, data.value));
  };

  EtdStore.prototype.onEnableHeaderEdit = function() {
    return this.setState(this.state.setIn(['_meta', 'isHeaderEditing'], true));
  };

  EtdStore.prototype.onDisableHeaderEdit = function() {
    var isHeaderEditing;
    isHeaderEditing = this.isHeaderEditing();
    if (isHeaderEditing) {
      return this.setState(this.state.setIn(['_meta', 'isHeaderEditing'], false));
    }
  };

  EtdStore.prototype.onSaveBackground = function() {
    return this._save();
  };

  EtdStore.prototype.onChangeAndSaveCriteriaDecision = function(data) {
    var dataPath;
    dataPath = ['template', 'templateData', 'assessment', 'criteria', data.sectionId, 'decision', 'selectedOption'];
    this.setState(this.state.setIn(dataPath, data.value));
    return this._save();
  };

  EtdStore.prototype.onChangeAndSaveDecision = function(value) {
    var dataPath;
    dataPath = ['template', 'templateData', 'presentations', 'sections', 'policymakers', 'sections', 'decision', 'selectedOption'];
    this.setState(this.state.setIn(dataPath, value));
    return this._save();
  };

  EtdStore.prototype._save = function(template) {
    var dbId;
    if (template == null) {
      template = this.getTemplate();
    }
    dbId = this.state.getIn(['_meta', 'dbId']);
    return this.getInstance().save(dbId, template.toJS());
  };

  EtdStore.prototype.onKeepSojStatus = function(data) {
    return this.setState(this.state.setIn(['_meta', 'sojStatus'], data.sojStatus));
  };

  EtdStore.prototype.onChangeJudgement = function(data) {
    return this._changeJudgement(data);
  };

  EtdStore.prototype.onChangeSelectedValue = function(_arg) {
    var dataPath, sectionId, value;
    sectionId = _arg.sectionId, value = _arg.value;
    dataPath = ['template', 'templateData', 'conclusions', 'sections', sectionId, 'selectedOption'];
    this.setState(this.state.setIn(dataPath, value));
    return this._save();
  };

  EtdStore.prototype._changeJudgement = function(data) {
    var dataPath, isConsensus, sectionId, value;
    sectionId = data.sectionId, value = data.value, isConsensus = data.isConsensus;
    dataPath = ['template', 'templateData', 'assessment', 'criteria', sectionId];
    dataPath.push(consensusBasedFieldName('selectedOption', isConsensus));
    return this.setState(this.state.setIn(dataPath, value));
  };

  EtdStore.prototype._changeConclusionsOption = function(data) {
    var isConsensus, sectionId, updateField, value;
    sectionId = data.sectionId, value = data.value, isConsensus = data.isConsensus;
    updateField = consensusBasedFieldName('selectedOption', isConsensus);
    return this.setState(this.state.setIn(['template', 'templateData', 'conclusions', 'sections', sectionId, updateField], value));
  };

  EtdStore.prototype.onChangeAndSaveJudgement = function(data) {
    this._changeJudgement(data);
    return this._save();
  };

  EtdStore.prototype.onClearJudgement = function(data) {
    return this._changeJudgement(_.extend({}, data, {
      value: ''
    }));
  };

  EtdStore.prototype.onClearAllJudgements = function(data) {
    var updateField;
    updateField = consensusBasedFieldName('selectedOption', data.isConsensus);
    return this.setState(this.state.updateIn(['template', 'templateData', 'assessment', 'criteria'], function(criteria) {
      return criteria.map(function(criterion) {
        return criterion.set(updateField, '');
      });
    }));
  };

  EtdStore.prototype.onUpdateConclusionsOption = function(data) {
    return this._changeConclusionsOption(data);
  };

  EtdStore.prototype.onUpdateAndSaveConclusionsOption = function(data) {
    this._changeConclusionsOption(data);
    return this._save();
  };

  EtdStore.prototype.onClearConclusionsOption = function(data) {
    return this._changeConclusionsOption(_.extend({}, data, {
      value: ''
    }));
  };

  EtdStore.prototype.onUpdatePanelDiscussion = function(data) {
    var isConsensus, sectionId, updateField, value;
    sectionId = data.sectionId, value = data.value, isConsensus = data.isConsensus;
    updateField = consensusBasedFieldName('panelDiscussion', isConsensus);
    return this.setState(this.state.setIn(detailedJudgementsDataPath(sectionId).concat([updateField]), value));
  };

  EtdStore.prototype.onUpdateDetailedGeneralQuestion = function(data) {
    var isConsensus, path, questionIdx, sectionId, updateField, value;
    sectionId = data.sectionId, questionIdx = data.questionIdx, value = data.value, isConsensus = data.isConsensus;
    updateField = consensusBasedFieldName('selectedOption', isConsensus);
    path = detailedJudgementsDataPath(sectionId).concat(['questions', questionIdx, updateField]);
    return this.setState(this.state.setIn(path, value));
  };

  EtdStore.prototype.onUpdateDetailedOutcomesQuestion = function(data) {
    var isConsensus, outcomeId, path, questionIdx, sectionId, updateField, value;
    sectionId = data.sectionId, questionIdx = data.questionIdx, outcomeId = data.outcomeId, value = data.value, isConsensus = data.isConsensus;
    updateField = consensusBasedFieldName('selectedOptions', isConsensus);
    path = detailedJudgementsDataPath(sectionId).concat(['questions', questionIdx, updateField, outcomeId]);
    return this.setState(this.state.setIn(path, value));
  };

  EtdStore.prototype.onChangeDetailedResourceName = function(data) {
    var isConsensus, questionIdx, resourceIdx, resourcesPath, sectionId, stateToUpdate, updateField, updatePath, value;
    sectionId = data.sectionId, questionIdx = data.questionIdx, resourceIdx = data.resourceIdx, value = data.value, isConsensus = data.isConsensus;
    updateField = consensusBasedFieldName('resources', isConsensus);
    updatePath = detailedJudgementsDataPath(sectionId).concat(['questions', questionIdx, updateField, resourceIdx, 'name']);
    resourcesPath = detailedJudgementsDataPath(sectionId).concat(['questions', questionIdx, updateField]);
    stateToUpdate = ensureResoursesExist(resourcesPath, this.state, updateField);
    return this.setState(stateToUpdate.setIn(updatePath, value));
  };

  EtdStore.prototype.onUpdateDetailedResourcesQuestion = function(data) {
    var isConsensus, questionIdx, resourceIdx, resourcesPath, sectionId, stateToUpdate, updateField, updatePath, value;
    sectionId = data.sectionId, questionIdx = data.questionIdx, resourceIdx = data.resourceIdx, value = data.value, isConsensus = data.isConsensus;
    updateField = consensusBasedFieldName('resources', isConsensus);
    updatePath = detailedJudgementsDataPath(sectionId).concat(['questions', questionIdx, updateField, resourceIdx, 'selectedOption']);
    resourcesPath = detailedJudgementsDataPath(sectionId).concat(['questions', questionIdx, updateField]);
    stateToUpdate = ensureResoursesExist(resourcesPath, this.state, updateField);
    return this.setState(stateToUpdate.setIn(updatePath, value));
  };

  EtdStore.prototype.onAddDetailedResource = function(data) {
    var draftResources, isConsensus, path, questionIdx, resources, sectionId, updateField, _ref1;
    sectionId = data.sectionId, questionIdx = data.questionIdx, isConsensus = data.isConsensus;
    updateField = consensusBasedFieldName('resources', isConsensus);
    path = detailedJudgementsDataPath(sectionId).concat(['questions', questionIdx, updateField]);
    draftResources = this.state.getIn(detailedJudgementsDataPath(sectionId).concat(['questions', questionIdx, 'resources']));
    resources = (_ref1 = this.state.getIn(path, draftResources)) != null ? _ref1 : Immutable.List();
    return this.setState(this.state.setIn(path, resources.push(Immutable.fromJS({
      name: '',
      selectedOption: ''
    }))));
  };

  EtdStore.prototype.onRemoveDetailedResource = function(data) {
    var draftResources, isConsensus, path, questionIdx, resourceIdx, resources, sectionId, updateField;
    sectionId = data.sectionId, questionIdx = data.questionIdx, resourceIdx = data.resourceIdx, isConsensus = data.isConsensus;
    updateField = consensusBasedFieldName('resources', isConsensus);
    path = detailedJudgementsDataPath(sectionId).concat(['questions', questionIdx, updateField]);
    draftResources = this.state.getIn(detailedJudgementsDataPath(sectionId).concat(['questions', questionIdx, 'resources']));
    resources = this.state.getIn(path, draftResources).filter(function(resource, idx) {
      return idx !== resourceIdx;
    });
    return this.setState(this.state.setIn(path, resources));
  };

  EtdStore.prototype.onUpdateHeaderFieldContent = function(inputDetails) {
    var content, sectionId;
    content = inputDetails.content, sectionId = inputDetails.sectionId;
    return this.setState(this.state.setIn(['template', 'templateData', 'question', 'sections', sectionId, 'content'], content));
  };

  EtdStore.prototype.onChangeImportance = function(data) {
    var isConsensus, section, updateField, value;
    section = data.section, value = data.value, isConsensus = data.isConsensus;
    updateField = consensusBasedFieldName('importance', isConsensus);
    return this.setState(this.state.setIn(['template', 'templateData', 'assessment', 'sections', section, updateField], value));
  };

  EtdStore.prototype.onClearImportance = function(data) {
    var updateField;
    updateField = consensusBasedFieldName('importance', data.isConsensus);
    return this.setState(this.state.updateIn(['template', 'templateData', 'assessment', 'sections'], function(sections) {
      return sections.map(function(sec) {
        return sec.setIn([updateField], 'clear');
      });
    }));
  };

  EtdStore.prototype.onSaveHeaderData = function() {
    if (this.isHeaderEditing()) {
      return this._save();
    }
  };

  EtdStore.prototype.onToggleHeader = function() {
    var dataPath, expanded, _ref1;
    dataPath = ['template', 'templateData', 'question', 'expanded'];
    expanded = (_ref1 = this.state.getIn(dataPath)) != null ? _ref1 : true;
    this.setState(this.state.setIn(dataPath, !expanded));
    return this._save();
  };

  EtdStore.prototype.onSetOverallJustification = function(justification) {
    var path;
    path = blockDataPath('conclusions').concat(['sections', 'justification']);
    return this.setState(this.state.setIn(path, justification));
  };

  EtdStore.prototype.onSetOverallJustificationAndSave = function(justification) {
    this.onSetOverallJustification(justification);
    return this._save();
  };

  EtdStore.prototype.onHideVote = function(data) {
    var etdPart, sectionId, updatePath, updateSections, voteId;
    sectionId = data.sectionId, voteId = data.voteId, etdPart = data.etdPart;
    updateSections = etdPart === 'conclusions' ? ['conclusions', 'sections'] : ['assessment', 'criteria'];
    updatePath = ['template', 'templateData'].concat(updateSections, [sectionId, 'hiddenVotes']);
    return this.setState(this.state.updateIn(updatePath, Immutable.List(), function(hiddenVotes) {
      if (Immutable.List.isList(voteId)) {
        voteId = voteId.filterNot(function(id) {
          return hiddenVotes.includes(id);
        });
        return hiddenVotes.concat(voteId);
      } else {
        return hiddenVotes.push(voteId);
      }
    }));
  };

  EtdStore.prototype.onShowVote = function(data) {
    var etdPart, sectionId, updatePath, updateSections, voteId;
    sectionId = data.sectionId, voteId = data.voteId, etdPart = data.etdPart;
    updateSections = etdPart === 'conclusions' ? ['conclusions', 'sections'] : ['assessment', 'criteria'];
    updatePath = ['template', 'templateData'].concat(updateSections, [sectionId, 'hiddenVotes']);
    return this.setState(this.state.updateIn(updatePath, function(hiddenVotes) {
      if (Immutable.List.isList(voteId)) {
        return hiddenVotes.filter(function(hiddenVoteId) {
          return !voteId.includes(hiddenVoteId);
        });
      } else {
        return hiddenVotes.filter(function(hiddenVoteId) {
          return hiddenVoteId !== voteId;
        });
      }
    }));
  };

  EtdStore.prototype.onEnableContentEditable = function(contentEditableId) {
    return this.setState(this.state.setIn(['_meta', 'activeContentEditableId'], contentEditableId));
  };

  EtdStore.prototype.onDisableContentEditable = function() {
    var dataPath, isCurrentlyEditable;
    dataPath = ['_meta', 'activeContentEditableId'];
    isCurrentlyEditable = this.state.getIn(dataPath);
    if (isCurrentlyEditable) {
      return this.setState(this.state.setIn(dataPath, ''));
    }
  };

  EtdStore.prototype.onUpdateEditorContent = function(data) {
    var cellId, content, etdPart, identifier, index, isConsensus, options, section, sectionId, sectionsPath, updateField, updatePath, _ref1;
    cellId = data.cellId, content = data.content, options = data.options;
    _ref1 = cellId.split('#'), sectionId = _ref1[0], identifier = _ref1[1];
    if (identifier !== 'votingComment') {
      isConsensus = options.isConsensus;
      updateField = consensusBasedFieldName('content', isConsensus);
    }
    updatePath = (function() {
      var _ref2, _ref3;
      switch (identifier) {
        case 'researchEvidences':
        case 'additionalConsiderations':
          return blockDataPath('assessment').concat([identifier, sectionId, updateField]);
        case 'conclusions':
          return blockDataPath('conclusions').concat(['sections', sectionId, updateField]);
        case 'votingComment':
          _ref2 = sectionId.split('_'), etdPart = _ref2[0], sectionId = _ref2[1];
          sectionsPath = etdPart === 'conclusions' || etdPart === 'recommendation' ? ['conclusions', 'sections'] : ['assessment', 'criteria'];
          return ['template', 'templateData'].concat(sectionsPath, [sectionId, identifier]);
        case 'conclusionsMultiRecommendations':
          _ref3 = sectionId.split('_'), section = _ref3[0], index = _ref3[1];
          return blockDataPath('conclusions').concat(['sections', section, 'recommendations', index, 'content']);
      }
    })();
    this.setState(this.state.withMutations(function(state) {
      state.setIn(updatePath, content);
      return updateStoredAttachments(state, content, identifier, sectionId, isConsensus);
    }));
    return this._save();
  };

  EtdStore.prototype.onUpdateContentEditableContent = function(data) {
    var content, contentEditableId, dataURIPattern, dbId, detailed, etdPart, identifier, options, presentationType, sectionId, updateField, updatePath, updateSections, _ref1;
    contentEditableId = data.contentEditableId, content = data.content, options = data.options;
    _ref1 = contentEditableId.split('#'), sectionId = _ref1[0], identifier = _ref1[1], detailed = _ref1[2];
    presentationType = this.getPresentationType();
    updateField = options.isConsensus ? 'contentConsensus' : 'content';
    updatePath = (function() {
      var _ref2;
      switch (identifier) {
        case 'conclusions':
          if (detailed) {
            return ['template', 'templateData', 'conclusions', 'sections', sectionId, detailed, updateField];
          } else {
            return ['template', 'templateData', 'conclusions', 'sections', sectionId, updateField];
          }
          break;
        case 'question':
          return ['template', 'templateData', 'question', 'sections', sectionId, 'content'];
        case 'patients':
          return ['template', 'templateData', 'presentations', 'sections', 'patients', 'sections', sectionId, 'content'];
        case 'policymakers':
          return ['template', 'templateData', 'presentations', 'sections', 'policymakers', 'sections', sectionId, 'content'];
        case 'criteria':
          return ['template', 'templateData', 'assessment', 'criteria', sectionId, 'decision', 'comments'];
        case 'presentations':
          return ['template', 'templateData', 'presentations', 'sections', presentationType, 'sections', sectionId, 'content'];
        case 'votingComment':
          _ref2 = sectionId.split('_'), etdPart = _ref2[0], sectionId = _ref2[1];
          updateSections = etdPart === 'conclusions' ? ['conclusions', 'sections'] : ['assessment', 'criteria'];
          return ['template', 'templateData'].concat(updateSections, [sectionId, identifier]);
        default:
          return ['template', 'templateData', 'assessment', identifier, sectionId, updateField];
      }
    })();
    if (!Immutable.Map.isMap(content)) {
      dataURIPattern = /src="data:\S+"/g;
      content = content.replace(dataURIPattern, 'src=""');
    }
    this.setState(this.state.setIn(updatePath, content));
    dbId = this.state.getIn(['_meta', 'dbId']);
    return this.getInstance().save(dbId, this.getTemplate().toJS());
  };

  EtdStore.prototype.onSaveContentEditable = function() {
    var dbId;
    if (this.state.getIn(['template', '_attachments'])) {
      this._updateAttachments();
    }
    dbId = this.state.getIn(['_meta', 'dbId']);
    return this.getInstance().save(dbId, this.getTemplate().toJS());
  };

  EtdStore.prototype.onInsertContent = function(insertData) {
    var cellId, file, options, selectedOption, selectedOutcomes;
    selectedOption = insertData.selectedOption, selectedOutcomes = insertData.selectedOutcomes, file = insertData.file, options = insertData.options;
    return W.resolve((function() {
      switch (selectedOption) {
        case 'sof_short':
        case 'test_accuracy':
        case 'evidence_short':
        case 'relative_importance':
          return this._insertTable(selectedOption, selectedOutcomes, options);
        case 'sof_v1':
        case 'sof_v2':
        case 'sof_v3':
        case 'sof_v4':
        case 'l1_sof':
        case 'l2_sof':
          return this._insertSof(selectedOption, selectedOutcomes, options);
        case 'image':
          cellId = this.state.getIn(['_meta', 'activeContentEditableId']);
          return this._insertImage({
            file: file,
            cellId: cellId,
            options: options
          });
      }
    }).call(this));
  };

  EtdStore.prototype.onToggleSectionHighlight = function(_arg) {
    var etdPart, sectionId, updatePath;
    etdPart = _arg.etdPart, sectionId = _arg.sectionId;
    updatePath = ['template', 'templateData', etdPart, 'sections', sectionId, 'highlightToPanelMembers'];
    return this.setState(this.state.updateIn(updatePath, false, function(val) {
      return !val;
    }));
  };

  EtdStore.prototype._insertTable = function(tableName, selectedOutcomes, options) {
    var tableData, tableHTML;
    tableData = QuestionsStore.getDataForTable(tableName, selectedOutcomes);
    tableHTML = embeddedContentProvider.getResearchEvidenceTable(tableName, tableData.toJS());
    return this.insertContentToContentEditable(tableHTML, options);
  };

  EtdStore.prototype._insertSof = function(sofType, selectedOutcomes, options) {
    var questionModel;
    questionModel = QuestionsStore.getQuestionModel();
    return embeddedContentProvider.getSofTable(sofType, questionModel, selectedOutcomes).then((function(_this) {
      return function(tableHTML) {
        return _this.insertContentToContentEditable(tableHTML, options);
      };
    })(this));
  };

  EtdStore.prototype._insertImage = function(params) {
    var attachment, attachmentName, cellId, dbId, file, options;
    cellId = params.cellId, file = params.file, options = params.options;
    if (!(cellId && file)) {
      throw new Error;
    }
    attachmentName = "" + cellId + "/" + (file.name.replace(/\s/g, '_'));
    this.insertContentToContentEditable(embeddedContentProvider.getImageString({
      'db-image': attachmentName,
      style: "width: 100%;",
      section: cellId.split('#')[0]
    }), options);
    dbId = this.state.getIn(['_meta', 'dbId']);
    attachment = {
      content_type: file.type,
      data: file
    };
    return this.getInstance().save(dbId, this.state.setIn(['template', '_attachments', attachmentName], attachment).get('template').toJS());
  };

  EtdStore.prototype.onSaveAttachment = function(data) {
    var attachment, attachmentName, file, newState;
    file = data.file, attachmentName = data.attachmentName;
    attachment = {
      content_type: file.type,
      data: file
    };
    newState = this.state.setIn(['template', '_attachments', attachmentName], attachment);
    return this._save(newState.get('template'));
  };

  EtdStore.prototype.insertContentToContentEditable = function(content, options) {
    var contentEditableId, currentContent, newContent, sectionId, updateField;
    contentEditableId = this.getActiveContentEditable();
    sectionId = contentEditableId.split('#')[0];
    updateField = options.isConsensus ? 'contentConsensus' : 'content';
    currentContent = this.getResearchEvidence(sectionId).get(updateField);
    newContent = currentContent + content;
    return this.onUpdateContentEditableContent({
      contentEditableId: contentEditableId,
      content: newContent,
      options: options
    });
  };

  EtdStore.prototype._updateAttachments = function() {
    var activeAttachments, attachments;
    attachments = this.state.getIn(['template', '_attachments']);
    if (!attachments) {
      return;
    }
    activeAttachments = attachments.filter((function(_this) {
      return function(attachment, attachmentName) {
        var fieldId, fieldType, isPresentInConsensusEtd, isPresentInDraftEtd, _ref1;
        _ref1 = attachmentName.split('/')[0].split('#'), fieldId = _ref1[0], fieldType = _ref1[1];
        isPresentInDraftEtd = _this.state.getIn(['template', 'templateData', 'assessment', fieldType, fieldId, 'content']).match(attachmentName) != null;
        isPresentInConsensusEtd = _this.state.getIn(['template', 'templateData', 'assessment', fieldType, fieldId, 'contentConsensus'], '').match(attachmentName) != null;
        return isPresentInDraftEtd || isPresentInConsensusEtd;
      };
    })(this));
    if (activeAttachments.size !== this.state.getIn(['template', '_attachments']).size) {
      return this.setState(this.state.setIn(['template', '_attachments'], activeAttachments));
    }
  };

  EtdStore.prototype.onUpdateManualContent = function(data) {
    var sectionId, updatePath, value;
    sectionId = data.sectionId, value = data.value;
    updatePath = ['template', 'templateData', 'presentations', 'sections', this.getPresentationType(), 'sections', sectionId, 'content'];
    return this.setState(this.state.setIn(updatePath, value));
  };

  EtdStore.prototype.onUpdateAutoGenerationFlag = function(data) {
    var sectionId, updatePath, value;
    sectionId = data.sectionId, value = data.value;
    updatePath = ['template', 'templateData', 'presentations', 'sections', this.getPresentationType(), 'sections', sectionId, 'autoMode'];
    return this.setState(this.state.setIn(updatePath, value));
  };

  EtdStore.prototype.onSetEtdParts = function(etdParts) {
    return this.setState(this.state.withMutations(function(state) {
      var etdPart, etdPartData;
      for (etdPart in etdParts) {
        etdPartData = etdParts[etdPart];
        state.setIn(templateDataPath.concat([etdPart]), Immutable.fromJS(etdPartData));
      }
      return state;
    }));
  };

  EtdStore.prototype.onUpdatePresentationType = function(type) {
    return this.setState(this.state.setIn(['_meta', 'presentationType'], type));
  };

  EtdStore.prototype.onUpdateSelectedOutcomes = function(params) {
    var outcomes, qId;
    qId = params.qId, outcomes = params.outcomes;
    return this.setState(this.state.setIn(['_meta', 'initialSelectedOutcomes', qId], outcomes));
  };

  EtdStore.prototype.onAddPresentationSectionField = function(_arg) {
    var keyPath, partId, sectionId;
    sectionId = _arg.sectionId, partId = _arg.partId;
    keyPath = ['template', 'templateData', 'presentations', 'sections', this.getPresentationType(), 'sections', sectionId, 'parts', partId, 'additionalFields'];
    this.setState(this.state.updateIn(keyPath, function(partFields) {
      return partFields.push(Immutable.Map({
        content: ''
      }));
    }));
    return this._save();
  };

  EtdStore.prototype.onUpdatePresentationSectionFieldContent = function(_arg) {
    var content, fieldIndex, keyPath, partId, sectionId;
    sectionId = _arg.sectionId, partId = _arg.partId, content = _arg.content, fieldIndex = _arg.fieldIndex;
    keyPath = ['template', 'templateData', 'presentations', 'sections', this.getPresentationType(), 'sections', sectionId, 'parts', partId, 'additionalFields', fieldIndex, 'content'];
    this.setState(this.state.setIn(keyPath, content));
    return this._save();
  };

  EtdStore.prototype.onRemovePresentationSectionField = function(_arg) {
    var fieldIndex, keyPath, partId, sectionId;
    sectionId = _arg.sectionId, partId = _arg.partId, fieldIndex = _arg.fieldIndex;
    keyPath = ['template', 'templateData', 'presentations', 'sections', this.getPresentationType(), 'sections', sectionId, 'parts', partId, 'additionalFields', fieldIndex];
    this.setState(this.state.deleteIn(keyPath));
    return this._save();
  };

  EtdStore.prototype.onUpdatePresentationPatientsSectionContent = function(_arg) {
    var content, keyPath, partId, sectionId;
    sectionId = _arg.sectionId, partId = _arg.partId, content = _arg.content;
    keyPath = ['template', 'templateData', 'presentations', 'sections', this.getPresentationType(), 'sections', sectionId, 'parts', partId, 'content'];
    this.setState(this.state.setIn(keyPath, content));
    return this._save();
  };

  EtdStore.prototype.onUpdatePatientsPresentationName = function(presentationName) {
    var keyPath;
    keyPath = ['template', 'templateData', 'presentations', 'sections', this.getPresentationType(), 'presentationName'];
    return this.setState(this.state.setIn(keyPath, presentationName));
  };

  EtdStore.prototype.onShowDefaultTemplateDialog = function() {
    return this.setState(this.state.setIn(['_meta', 'showDefaultTemplateDialog'], true));
  };

  EtdStore.prototype.onHideDefaultTemplateDialog = function() {
    return this.setState(this.state.setIn(['_meta', 'showDefaultTemplateDialog'], false));
  };

  EtdStore.prototype.onAddRecommendation = function() {
    var recommendationsPath;
    recommendationsPath = ['template', 'templateData', 'conclusions', 'sections', 'multipleRecommendations', 'recommendations'];
    this.setState(this.state.setIn(recommendationsPath, this.state.getIn(recommendationsPath).push(Immutable.Map({
      _id: generateGUID(),
      selectedOption: null,
      content: ""
    }))));
    return this._save();
  };

  EtdStore.prototype.onRemoveRecommendation = function(index) {
    var recommendationsPath;
    recommendationsPath = ['template', 'templateData', 'conclusions', 'sections', 'multipleRecommendations', 'recommendations'];
    this.setState(this.state.setIn(recommendationsPath, this.state.getIn(recommendationsPath)["delete"](index)));
    return this._save();
  };

  EtdStore.prototype.onUpdateSelectedRecommendationValue = function(_arg) {
    var index, path, value;
    index = _arg.index, value = _arg.value;
    path = ['template', 'templateData', 'conclusions', 'sections', 'multipleRecommendations', 'recommendations', index, 'selectedOption'];
    this.setState(this.state.setIn(path, value));
    return this._save();
  };

  EtdStore.prototype.onUpdateSelectedCertaintyValue = function(_arg) {
    var index, path, value;
    index = _arg.index, value = _arg.value;
    path = ['template', 'templateData', 'conclusions', 'sections', 'multipleRecommendations', 'recommendations', index, 'selectedCertainty'];
    this.setState(this.state.setIn(path, Immutable.fromJS(value)));
    return this._save();
  };

  EtdStore.prototype.onSetRating = function(_arg) {
    var key, newRating, path, sectionId;
    sectionId = _arg.sectionId, key = _arg.key, newRating = _arg.newRating;
    path = ['template', 'templateData', 'assessment', 'criteria', sectionId, 'rating'];
    return this.setState(this.state.updateIn(path, Immutable.Map(), function(currentRating) {
      if (key === 'all') {
        return currentRating.clear().set('all', newRating);
      } else {
        return currentRating.remove('all').set(key, newRating);
      }
    }));
  };

  EtdStore.prototype.onSetStarRating = function(_arg) {
    var key, newRating, path, sectionId;
    sectionId = _arg.sectionId, key = _arg.key, newRating = _arg.newRating;
    path = ['template', 'templateData', 'assessment', 'criteria', sectionId, 'starRating'];
    this.setState(this.state.updateIn(path, Immutable.Map(), function(currentRating) {
      return currentRating.set(key, newRating);
    }));
    return this._save();
  };

  EtdStore.prototype.onSetJudgementPerTableCell = function(_arg) {
    var cellId, path, rowId, sectionId, value;
    sectionId = _arg.sectionId, rowId = _arg.rowId, cellId = _arg.cellId, value = _arg.value;
    path = ['template', 'templateData', 'assessment', 'criteria', sectionId, 'selectedOptions'];
    return this.setState(this.state.updateIn(path, Immutable.Map(), function(selectedOptions) {
      return selectedOptions.setIn([rowId, cellId], value);
    }));
  };

  EtdStore.prototype.onSetSelectedOptions = function(_arg) {
    var intervention, interventionId, noAutoSave, path, sectionId, value;
    sectionId = _arg.sectionId, intervention = _arg.intervention, value = _arg.value, noAutoSave = _arg.noAutoSave;
    path = ['template', 'templateData', 'conclusions', 'sections', sectionId, 'selectedOptions'];
    interventionId = intervention.get('id');
    this.setState(this.state.updateIn(path, Immutable.Map(), function(selectedOptions) {
      if (selectedOptions.get(interventionId) === value) {
        return selectedOptions.set(interventionId, '');
      } else {
        return selectedOptions.set(interventionId, value);
      }
    }));
    if (!noAutoSave) {
      return this._save();
    }
  };

  EtdStore.prototype.isIsofVisibleInPremiumView = function() {
    return this.state.getIn(['_meta', 'isIsofVisibleInPremiumView'], false);
  };

  EtdStore.prototype.onToggleSofTableInPremiumView = function() {
    return this.setState(this.state.setIn(['_meta', 'isIsofVisibleInPremiumView'], !this.isIsofVisibleInPremiumView()));
  };

  EtdStore.prototype.getActiveContentEditable = function() {
    return this.state.getIn(['_meta', 'activeContentEditableId']);
  };

  EtdStore.prototype.getInitialSelectedOutcomes = function(qId) {
    return this.state.getIn(['_meta', 'initialSelectedOutcomes', qId], Immutable.List());
  };

  EtdStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'isFetchingEtd']);
  };

  EtdStore.prototype.onTemplateUndefined = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'templateUndefined'], true);
      return state.setIn(['_meta', 'isFetchingEtd'], false);
    }));
  };

  EtdStore.prototype.isTemplateUndefined = function() {
    return this.state.getIn(['_meta', 'templateUndefined']);
  };

  EtdStore.prototype.isTemplateEmpty = function() {
    return this.state.get('template').isEmpty();
  };

  EtdStore.prototype.getPresentationType = function() {
    return this.state.getIn(['_meta', 'presentationType']);
  };

  EtdStore.prototype.shouldShowDefaultTemplateDialog = function() {
    return this.state.getIn(['_meta', 'showDefaultTemplateDialog']);
  };

  return EtdStore;

})();

module.exports = alt.createStore(immutable(EtdStore), 'EtdStore');
