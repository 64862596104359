{ forwardRef } = React
ConnectStore = require 'components/enhancers/connect_store'
AlgorithmsStore = require 'stores/algorithms_store'

storeConnector =
  AlgorithmsStore: (Store, props) ->
    algorithm: Store.getAlgorithm(props.algorithmId)

AlgorithmToolbar = ({ algorithmId, algorithm }) ->
  <div className='algorithm-toolbar'>
    <h2>{algorithm?.title}</h2>
  </div>

module.exports = ConnectStore AlgorithmToolbar, AlgorithmsStore, storeConnector
