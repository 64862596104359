var KeyMessage, KeyMessageRecommendationView, KeyMessageToolbar, ReactComponent, ReactToolbarView, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

KeyMessageToolbar = require('components/key_messages/key_message_toolbar');

KeyMessage = require('components/key_messages/key_message');

module.exports = KeyMessageRecommendationView = (function(_super) {
  __extends(KeyMessageRecommendationView, _super);

  function KeyMessageRecommendationView() {
    return KeyMessageRecommendationView.__super__.constructor.apply(this, arguments);
  }

  KeyMessageRecommendationView.prototype.container = '#page-container';

  KeyMessageRecommendationView.prototype.autoRender = true;

  KeyMessageRecommendationView.prototype.initialize = function() {
    KeyMessageRecommendationView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: KeyMessageToolbar,
      props: {
        questionId: this.options.id
      }
    }));
    this._stopInputsPropagation = false;
    return this.enable(ReactComponent);
  };

  KeyMessageRecommendationView.prototype.afterRender = function() {
    var questionId;
    KeyMessageRecommendationView.__super__.afterRender.apply(this, arguments);
    questionId = this.options.id;
    return this.renderSimpleComponent(KeyMessage, {
      questionId: questionId
    });
  };

  KeyMessageRecommendationView.prototype.dispose = function() {
    this.unmountComponent();
    return KeyMessageRecommendationView.__super__.dispose.apply(this, arguments);
  };

  return KeyMessageRecommendationView;

})(View);
