OverReviewActions = require 'actions/over_review_actions'
OutcomeTableViewActions = require 'actions/outcomes_table_view_actions'
EditableCell = require 'components/evidence_syntheses/outcomes/cells/editable_cell'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ SelectionList, SelectionListOption } =
  require 'components/evidence_syntheses/outcomes/cells/selection_list'
{ getKeyValObject } = require 'base/lib/utils'


optionNames = [ 'critical', 'important', 'notImportant' ]

ImportanceCell = createReactClass
  displayName: 'ImportanceCell'

  propTypes:
    outcomeAttrs: PropTypes.object

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]

  _ref: (el) ->
    @el = el

  handleOptionSelect: (importance) ->
    OverReviewActions.updateSourceOutcome
      outcomeId: @props.outcomeAttrs['_id']
      sourceId: @props.sourceId
      questionId: @props.questionId
      outcomeData: { importance }
    OutcomeTableViewActions.stopOutcomeEdit()

  renderImportanceOption: (importance) ->
    importanceLabel = if importance > 6
      @i18n 'critical'
    else if importance > 3
      @i18n 'important'
    else
      @i18n 'not_important'

    <SelectionListOption
      key={importance}
      label={"#{importance} - #{importanceLabel}"}
      value={"#{importance}"}
    />

  render: ->
    <EditableCell {...@props} ref={@_ref} title={@i18n 'importance'}>
      <SelectionList
        onClear={@handleOptionSelect}
        onSelect={@handleOptionSelect}
        selectedValue={@props.outcomeAttrs.importance}
      >
        {[9..1].map @renderImportanceOption}
      </SelectionList>
    </EditableCell>

module.exports = ImportanceCell
