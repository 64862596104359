CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ValidationContainer = require 'components/common/validation_container'
{ string, func } = PropTypes

{ string, func, bool } = PropTypes

MemberFormGroup = createReactClass

  mixins: [CustomRenderMixin]

  propTypes:
    attr: string.isRequired
    error: string
    label: string
    memberId: string
    updateAttr: func.isRequired
    value: string.isRequired
    inline: bool
    required: bool

  getDefaultProps: ->
    inline: false
    required: false

  handleChange: (e) ->
    @props.updateAttr @props.attr, e.target.value

  render: ->
    groupClass = classNames "inline": @props.inline, "col-12": not @props.inline
    labelClass= classNames "pull-left", 'required': @props.required
    inputClass = classNames "form-control", @props.attr
    <div className="form-group">
      <div className={groupClass}>
        <label className={labelClass} htmlFor={@props.attr}>
          {@props.label}
        </label>
        <div className="pull-left input-container">
          <ValidationContainer errorMsg={@props.error}>
            <input
              id={@props.attr}
              type="text"
              className={inputClass}
              disabled={@props.disabled}
              value={@props.value ? ""}
              onChange={@handleChange}/>
          </ValidationContainer>
        </div>
      </div>
      <div className="clearfix" />
    </div>

module.exports = MemberFormGroup
