var EditableItemView, Question, QuestionGroupView, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

EditableItemView = require('base/views/editable_item_view');

Question = require('models/question');

template = require('views/templates/evidence_syntheses/question_group');

module.exports = QuestionGroupView = (function(_super) {
  __extends(QuestionGroupView, _super);

  function QuestionGroupView() {
    return QuestionGroupView.__super__.constructor.apply(this, arguments);
  }

  QuestionGroupView.prototype.template = template;

  QuestionGroupView.prototype.tagName = 'div';

  QuestionGroupView.prototype.className = 'question-group-edit-view';

  QuestionGroupView.prototype.ellipsisSelector = '.caption span';

  QuestionGroupView.prototype.bindings = {
    '.caption span': 'caption',
    '#caption': 'caption'
  };

  QuestionGroupView.prototype["delete"] = function() {
    return QuestionGroupView.__super__["delete"].call(this, $.t('es:question.groups.delete_confirmation'));
  };

  return QuestionGroupView;

})(EditableItemView);
