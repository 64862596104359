ContentEditor = require 'components/decision_aid/content_editor'
DecisionAidsActions = require 'actions/decision_aids_actions'
IconButton = require 'components/common/icon_button'
OutcomesTable = require 'components/decision_aid/outcomes_table'
SubchapterControls = require 'components/decision_aid/subchapter_controls'
SubchapterIllustrationModal = require 'components/decision_aid/subchapter_illustration_insert_modal'
SubchapterTable = require 'components/decision_aid/subchapter_table'
SubchapterIllustration = require 'components/decision_aid/subchapter_illustration'
DecisionAidOutcomesModal = require 'components/decision_aid/decision_aid_outcomes_modal'
Translation = require 'components/mixins/translation'
ModalVisibility = require 'components/mixins/modal_visibility'
{ Card, CardContent } = ReactComponents


DecisionAidSubchapter = createReactClass
  displayName: 'DecisionAidSubchapter'

  propTypes:
    data: PropTypes.instanceOf(Immutable.Map).isRequired
    daId: PropTypes.string.isRequired
    chapterId: PropTypes.string.isRequired
    comparison: PropTypes.string.isRequired
    intervention: PropTypes.string.isRequired
    outcomes: PropTypes.instanceOf(Immutable.List).isRequired
    subchapterIdx: PropTypes.number.isRequired
    deletable: PropTypes.bool.isRequired
    questionId: PropTypes.string.isRequired

  mixins: [
    Translation('da:details-screen'),
    ModalVisibility # provides @showModal, @hideModal, @isModalVisible methods
  ]

  editorRef: (el) ->
    @subchapterEditor = el

  _subchapterAction: (actionName, payload = {}) ->
    DecisionAidsActions[actionName] R.mergeRight payload,
      daId: @props.daId
      chapterId: @props.chapterId
      subchapterIdx: @props.subchapterIdx

  handleEditorContentUpdate: (subchapterContent) ->
    @_subchapterAction 'updateSubchapterContent',
      content: @subchapterEditor.getContentJSON()

  handleTableContentSave: (tableContent) ->
    @_subchapterAction 'saveSubchapterTableContent',
      tableContent:
        columns: tableContent.toJS()

  handleIllustrationApply: (illustrationData) ->
    @_subchapterAction 'updateSubchapterIllustration',
      illustrationData: illustrationData

    @hideModal 'SubchapterIllustrationModal'

  handleSubchapterIllustrationDelete: ->
    @_subchapterAction 'deleteSubchapterIllustration'

  handleDecisionAidOutcomesUpdate: (updateDecisionAidOutcomeList) ->
    DecisionAidsActions.updateQuestion
      daId: @props.daId
      key: 'outcomes'
      value: updateDecisionAidOutcomeList.toJS()

  renderContent: ->
    { comparison, daId, data, intervention, outcomes } = @props

    switch data.get 'type'
      when 'editor'
        <ContentEditor
          editorContent={data.get('content')}
          onBlur={@handleEditorContentUpdate}
          ref={@editorRef}
          />
      when 'table'
        <SubchapterTable
          columns={data.getIn ['tableContent', 'columns']}
          onSave={@handleTableContentSave}
        />
      when 'outcomesTable'
        <OutcomesTable
          daId={daId}
          comparison={comparison}
          intervention={intervention}
          outcomes={outcomes} />
      else
        null

  render: ->
    { data } = @props

    subchapterTitle = data.get 'title'
    subchapterTitleHidden = data.get 'titleHidden'
    subchapterIllustration = data.get 'illustration'
    subchapterType = data.get 'type'

    <div>
      <div className='subchapter-header'>
        {unless subchapterTitleHidden
          <h4 className='subchapter-title'>{subchapterTitle}</h4>
        }
        <SubchapterControls
          deletable={@props.deletable}
          handleAction={@_subchapterAction}
          onAddIllustration={=> @showModal 'SubchapterIllustrationModal'}
          onManageOutcomes={=> @showModal 'DecisionAidOutcomesModal'}
          subchapterTitle={subchapterTitle}
          subchapterTitleHidden={subchapterTitleHidden}
          subchapterType={subchapterType}
        />
      </div>
      <Card className='decision-aid__chapter-editor-card'>
        <CardContent>
          {@renderContent()}
          {if subchapterIllustration?
            <SubchapterIllustration
              data={subchapterIllustration}
              onEdit={=> @showModal 'SubchapterIllustrationModal'}
              onDelete={@handleSubchapterIllustrationDelete}
            />
          }
        </CardContent>
      </Card>
      <SubchapterIllustrationModal
        isOpen={@isModalVisible 'SubchapterIllustrationModal'}
        data={subchapterIllustration}
        onApply={@handleIllustrationApply}
        onClose={=> @hideModal 'SubchapterIllustrationModal'}
      />
      {if subchapterType is 'outcomesTable'
        <DecisionAidOutcomesModal
          isOpen={@isModalVisible 'DecisionAidOutcomesModal'}
          onClose={=> @hideModal 'DecisionAidOutcomesModal'}
          outcomes={@props.outcomes}
          questionId={@props.questionId}
          onApply={@handleDecisionAidOutcomesUpdate}
        />
      }
    </div>

module.exports = DecisionAidSubchapter
