var OUTCOMES_GENERATION_STEPS, cleanUpDrag, convertComparisonData, createOutcomeObject, createQuestionGroupObject, deleteGroup, getComparisonQuestions, getImportanceForRating, getNextStepName, getProjectId, groupQuestionsByGroup, mediator, moveOutcomes, processComparisonData, sortQuestionGroups, updateWithComparisonData, utils, _ensureOutcomesKeys, _ref;

mediator = require('mediator');

utils = require('base/lib/utils');

_ref = require('lib/questions_helper'), OUTCOMES_GENERATION_STEPS = _ref.OUTCOMES_GENERATION_STEPS, groupQuestionsByGroup = _ref.groupQuestionsByGroup;

getProjectId = function() {
  return mediator.project.id;
};

getNextStepName = function(currentStep) {
  return OUTCOMES_GENERATION_STEPS[OUTCOMES_GENERATION_STEPS.indexOf(currentStep) + 1];
};

moveOutcomes = function(state, fromGroup, toGroup) {
  var activeTab, fromGroupOutcomesPath, outcomesToMove, toGroupOutcomes, toGroupOutcomesPath;
  activeTab = state.getIn(['_meta', 'activeTab']);
  fromGroupOutcomesPath = ['outcomesGenerationData', 'steps', activeTab, 'questionGroups', fromGroup, 'outcomes'];
  toGroupOutcomesPath = ['outcomesGenerationData', 'steps', activeTab, 'questionGroups', toGroup, 'outcomes'];
  outcomesToMove = state.getIn(fromGroupOutcomesPath);
  toGroupOutcomes = state.getIn(toGroupOutcomesPath);
  state.setIn(fromGroupOutcomesPath, Immutable.List());
  return state.setIn(toGroupOutcomesPath, toGroupOutcomes.concat(outcomesToMove));
};

createQuestionGroupObject = function(question, outcomes, options) {
  var name;
  if (outcomes == null) {
    outcomes = Immutable.List();
  }
  if (options == null) {
    options = {};
  }
  name = options.noName ? null : 'New Group';
  return Immutable.Map({
    name: name,
    questions: Immutable.List([question]),
    outcomes: outcomes
  });
};

createOutcomeObject = function(name, step) {
  return Immutable.Map({
    key: utils.generateGUID(),
    name: name,
    source: step
  });
};

sortQuestionGroups = function(questionGroups) {
  return questionGroups.sort(function(a, b) {
    a = a.get('name') != null ? 1 : 0;
    b = b.get('name') != null ? 1 : 0;
    return b - a;
  });
};

deleteGroup = function(state, gIndex) {
  var activeTab, groupsPath, targetGroups;
  activeTab = state.getIn(['_meta', 'activeTab']);
  groupsPath = ['outcomesGenerationData', 'steps', activeTab, 'questionGroups'];
  targetGroups = state.getIn(groupsPath);
  return state.setIn(groupsPath, targetGroups.splice(gIndex, 1));
};

cleanUpDrag = function(state) {
  return state.setIn(['_meta', 'stateSnapshot'], null).setIn(['_meta', 'startDragPosition'], null).setIn(['_meta', 'currentDragPosition'], null);
};

getComparisonQuestions = function() {
  return mediator.questions.models;
};

processComparisonData = function(questionGroupsDoc, comparisonQuestions) {
  var questionGroups, questionsOrdering, result;
  if (_.isEmpty(comparisonQuestions)) {
    return null;
  }
  questionGroups = _.pick(questionGroupsDoc, 'groups', 'mapping', 'questionsOrdering');
  questionsOrdering = R.propOr({}, 'questionsOrdering', questionGroups);
  result = Immutable.Map({
    questions: Immutable.fromJS(comparisonQuestions.map(function(q) {
      return _.extend({}, _.pick(q.attributes, '_id', 'question', 'type'), {
        'orderNumber': questionsOrdering[q.get('_id')] || 0
      });
    }))
  });
  if (_.isEmpty(questionGroups)) {
    return result;
  } else {
    return result.set('questionGroups', Immutable.fromJS(questionGroups));
  }
};

convertComparisonData = function(comparisonData) {
  var comparisonQuestions, groupped, groups, questionGroups, ungroupped;
  if (!comparisonData) {
    return Immutable.List();
  }
  comparisonQuestions = comparisonData.get('questions');
  questionGroups = comparisonData.get('questionGroups', Immutable.Map());
  groupped = groupQuestionsByGroup(comparisonQuestions, questionGroups);
  groups = groupped.filterNot(function(group) {
    return group.get('questions').isEmpty() || group.get('_id') === "questionsWithoutGroup";
  }).map(function(group) {
    return group.withMutations(function(g) {
      g.set('name', g.get('caption', null));
      g["delete"]('caption');
      g.set('questions', g.get('questions').toList());
      return g.set('outcomes', Immutable.List());
    });
  }).toList();
  ungroupped = groupped.getIn(['questionsWithoutGroup', 'questions'], Immutable.Map()).map(function(q) {
    return createQuestionGroupObject(q, null, {
      noName: true
    });
  });
  return groups.concat(ungroupped);
};

updateWithComparisonData = function(state) {
  var comparisonQuestions, groupsPath, initialStep, outcomesGenerationGroups, outcomesGenerationQuestions, updOutcomesGenerationGroups;
  initialStep = OUTCOMES_GENERATION_STEPS[0];
  if (state.getIn(['outcomesGenerationData', 'currentStep']) !== initialStep) {
    return;
  }
  groupsPath = ['outcomesGenerationData', 'steps', initialStep, 'questionGroups'];
  outcomesGenerationGroups = state.getIn(groupsPath);
  outcomesGenerationQuestions = Immutable.Set(outcomesGenerationGroups.flatMap(function(qGroup) {
    return qGroup.get('questions');
  }));
  comparisonQuestions = state.getIn(['comparisonData', 'questions'], Immutable.List());
  if (Immutable.is(outcomesGenerationQuestions, Immutable.Set(comparisonQuestions))) {
    return;
  }
  updOutcomesGenerationGroups = outcomesGenerationGroups.map(function(qGroup) {
    return qGroup.set('questions', qGroup.get('questions').reduce(function(result, q) {
      var comparisonQuestion, comparisonQuestionEntry, qIndex;
      comparisonQuestionEntry = comparisonQuestions.findEntry(function(cq) {
        return cq.get('_id') === q.get('_id');
      });
      if (!comparisonQuestionEntry) {
        return result;
      }
      qIndex = comparisonQuestionEntry[0], comparisonQuestion = comparisonQuestionEntry[1];
      comparisonQuestions = comparisonQuestions["delete"](qIndex);
      return result.push(comparisonQuestion);
    }, Immutable.List()));
  }).filterNot(function(qGroup) {
    return qGroup.get('questions').isEmpty();
  });
  if (!comparisonQuestions.isEmpty()) {
    updOutcomesGenerationGroups = updOutcomesGenerationGroups.concat(comparisonQuestions.map(function(q) {
      return createQuestionGroupObject(q, null, {
        noName: true
      });
    }));
  }
  state.setIn(groupsPath, updOutcomesGenerationGroups);
  return state.setIn(['_meta', 'dataUpdateNotify'], true);
};

_ensureOutcomesKeys = function(outcomesGenerationData) {
  return outcomesGenerationData.set('steps', outcomesGenerationData.get('steps').map(function(stepData) {
    return stepData.set('questionGroups', stepData.get('questionGroups').map(function(qGroup) {
      return qGroup.set('outcomes', qGroup.get('outcomes').map(function(outcome) {
        if (outcome.get('key') != null) {
          return outcome;
        } else {
          return outcome.set('key', utils.generateGUID());
        }
      }));
    }));
  }));
};

getImportanceForRating = function(rating) {
  if (rating > 6) {
    return 'critical';
  } else if (rating > 4) {
    return 'important';
  } else {
    return 'notImportant';
  }
};

module.exports = {
  getProjectId: getProjectId,
  getNextStepName: getNextStepName,
  moveOutcomes: moveOutcomes,
  createQuestionGroupObject: createQuestionGroupObject,
  createOutcomeObject: createOutcomeObject,
  sortQuestionGroups: sortQuestionGroups,
  deleteGroup: deleteGroup,
  cleanUpDrag: cleanUpDrag,
  getComparisonQuestions: getComparisonQuestions,
  processComparisonData: processComparisonData,
  convertComparisonData: convertComparisonData,
  updateWithComparisonData: updateWithComparisonData,
  _ensureOutcomesKeys: _ensureOutcomesKeys,
  getImportanceForRating: getImportanceForRating
};
