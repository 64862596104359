Translation = require 'components/mixins/translation'
Modal = require 'components/common/modal'
Input = require 'components/common/input'
Checkbox = require 'components/common/checkbox_input'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'


SubchapterTitleEditModal = createReactClass
  displayName: 'SubchapterTitleEditModal'

  propTypes:
    isOpen: PropTypes.bool.isRequired
    titleHidden: PropTypes.bool.isRequired
    onClose: PropTypes.func.isRequired
    onApply: PropTypes.func.isRequired
    title: PropTypes.string.isRequired

  mixins: [Translation('da:details-screen')]

  getInitialState: ->
    title: @props.title
    titleHidden: @props.titleHidden

  handleTitleChange: (evt) ->
    @setState title: evt.target.value

  handleNoTitleChange: (evt) ->
    @setState titleHidden: evt.target.checked

  handleApply: ->
    @props.onApply
      title: @state.title
      titleHidden: @state.titleHidden

  componentDidUpdate: (prevProps, prevState) ->
    if @props.isOpen and not prevProps.isOpen
      @setState @getInitialState()

  render: ->
    <Modal
      className='decision-aid-subchapter-title-edit-modal'
      isOpen={@props.isOpen}
      modalSize='standard'
      onClose={@props.onClose}
      onRequestClose={@props.onClose}
      title={@i18n 'subchapter_name'}
    >
      <div>
        <Input
          onChange={@handleTitleChange}
          onRequestSave={@handleApply}
          onRequestCancel={@props.onClose}
          type='text'
          value={@state.title}
        />
      </div>
      <label>
        <Checkbox checked={@state.titleHidden} onChange={@handleNoTitleChange} />
        <span>{@i18n 'hide_title'}</span>
      </label>
      <ApplyCancelButtons
        onCancel={@props.onClose}
        onApply={@handleApply}
        applyLabel={@i18n '/actions.save'}
      />
    </Modal>

module.exports = SubchapterTitleEditModal
