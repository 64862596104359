var ClearRadioContextMenuView, ContentEditableElements, Etd, ImageInsertion, Outcome, OutcomesSelectionView, PanelVoiceEtd, Qualities, ReactComponent, RecommendationsHeaderView, RecommendationsMigrationView, RecommendationsView, SelectionView, View, alt, etdHelper, gdtMediumOptions, mediator, su, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

Etd = require('components/etd/etd');

RecommendationsHeaderView = require('views/evidence_syntheses/recommendations_header_view');

SelectionView = require('base/views/selection_view');

OutcomesSelectionView = require('./outcomes_selection_view');

Qualities = require('models/outcome/qualities');

su = require('lib/statistical_utils');

etdHelper = require('lib/etd_helper');

RecommendationsMigrationView = require('views/evidence_syntheses/recommendations_migration_view');

mediator = require('mediator');

Outcome = require('models/outcome');

ContentEditableElements = require('lib/traits/content_editable_elements');

ImageInsertion = require('lib/traits/image_insertion');

ClearRadioContextMenuView = require('views/evidence_syntheses/clear_radio_context_menu_view');

utils = require('base/lib/utils');

alt = require('alt');

gdtMediumOptions = require('lib/medium_editor_gdt_options');

PanelVoiceEtd = require('components/etd/panel_voice_etd/panel_voice_etd');

module.exports = RecommendationsView = (function(_super) {
  __extends(RecommendationsView, _super);

  function RecommendationsView() {
    this._recommendationsModeUpdated = __bind(this._recommendationsModeUpdated, this);
    this._onValueSet = __bind(this._onValueSet, this);
    return RecommendationsView.__super__.constructor.apply(this, arguments);
  }

  RecommendationsView.prototype._MAX_OUTCOMES_NO = 7;

  RecommendationsView.prototype.id = 'recommendations';

  RecommendationsView.prototype.autoRender = false;

  RecommendationsView.prototype.bindings = {
    '#strongly_negative-recommendation': {
      observe: 'consequencesBalance',
      visible: function(val) {
        return val === 'clearly_negative';
      }
    },
    '#negative-recommendation': {
      observe: 'consequencesBalance',
      visible: function(val) {
        return val === 'probably_negative';
      }
    },
    '#no-recommendation': {
      observe: 'consequencesBalance',
      visible: function(val) {
        return val === 'balance_too_certain' || val === 'balance_very_similar';
      }
    },
    '#positive-recommendation': {
      observe: 'consequencesBalance',
      visible: function(val) {
        return val === 'probably_positive';
      }
    },
    '#strongly_positive-recommendation': {
      observe: 'consequencesBalance',
      visible: function(val) {
        return val === 'clearly_positive';
      }
    }
  };

  RecommendationsView.prototype._onValueSet = function(model, property, selector, saveModel) {
    return (function(_this) {
      return function(val) {
        var blobPattern, elementType;
        elementType = _this.$(selector)[0].getAttribute('type');
        if (elementType === 'radio') {
          model.set(property, val);
          if (saveModel) {
            model.save();
          }
          return val;
        } else {
          blobPattern = /\ssrc="blob:\S+"/g;
          return val.replace(blobPattern, '');
        }
      };
    })(this);
  };

  RecommendationsView.prototype._stickitBinding = function(model, property, updateMethod, selector, saveModel) {
    if (updateMethod == null) {
      updateMethod = 'text';
    }
    if (selector == null) {
      selector = '';
    }
    if (saveModel == null) {
      saveModel = true;
    }
    return {
      observe: property,
      events: ['keyup', 'change', 'cut', 'paste', 'input'],
      updateView: function() {
        return !(selector && this.$(selector).is(':focus'));
      },
      updateMethod: updateMethod,
      onSet: this._onValueSet(model, property, selector, saveModel)
    };
  };

  RecommendationsView.prototype.getTemplateFunction = function(forceInitial) {
    var baseDir, baseTpl;
    baseDir = 'views/templates/evidence_syntheses';
    baseTpl = this.model.isDiagnostic() ? 'diagnostic_recommendations' : 'recommendations';
    if (this.projectRecommendationsMode === 'initial' || forceInitial) {
      return require("" + baseDir + "/" + baseTpl);
    } else {
      return function() {
        return '';
      };
    }
  };

  RecommendationsView.prototype.initialize = function() {
    this._setProjectRecommendationsMode();
    this.enable(ReactComponent);
    this.autoInsertionCells = {
      'benefitsHarmsResearchEvidence': Handlebars.partials.relativeImportanceMainOutcomesTable,
      'diagnosticTestAccuracyEvidence': Handlebars.partials.testAccuracySummary,
      'accuracyEvidenceCertaintyEvidence': Handlebars.partials.recommendationDxEvidenceTable
    };
    mediator.project.on('change:recommendationsMode', this._recommendationsModeUpdated);
    this.delegate('click', 'button.choose-outcomes', this._openOutcomesDialog);
    this.delegate('contextmenu', '.radio-group', (function(_this) {
      return function(e) {
        var $target, params;
        e.stopPropagation();
        $target = $(e.target).closest('.radio-group');
        params = {
          radioButtonsUnchecked: _this._findCheckedRadioButtonsInEl($target)
        };
        return _this._showContextMenu(e, params);
      };
    })(this));
    this.delegate('contextmenu', '.judgement.radio-group', (function(_this) {
      return function(e) {
        var $target, params;
        e.stopPropagation();
        $target = $(e.target).closest('.judgement');
        params = {
          judgement: true,
          radioButtonsUnchecked: _this._findCheckedRadioButtonsInEl($target),
          judgementRadioButtonsUnchecked: _this._findCheckedRadioButtonsBySel('.judgement')
        };
        return _this._showContextMenu(e, params);
      };
    })(this));
    this.on('editNotify', this._showEditOptionsInfo);
    this.editOptionsInfoShown = null;
    if (this.model.get('outcomeUtilites')) {
      this._setupBenefitsAndHarmsForUtilities();
    } else {
      this._setupBenefitsAndHarmsWoUtilities();
    }
    RecommendationsView.__super__.initialize.apply(this, arguments);
    return this._checkForEtrUpdates();
  };

  RecommendationsView.prototype._recommendationsModeUpdated = function(model, mode) {
    this._disposeCurrentMode();
    this.projectRecommendationsMode = mode;
    return this.render();
  };

  RecommendationsView.prototype._clearRadioInputsInfo = function() {
    var params, viewportHeight;
    if (window.hopscotch.getCurrStepNum()) {
      return;
    }
    params = {
      customData: {
        dontShowAgain: true,
        infoId: "radio-inputs"
      },
      title: $.t(''),
      content: $.t('es:recommendations.table.tooltips.clear_radio_input'),
      target: '.hopscotch-target',
      placement: 'right',
      xOffset: -50
    };
    viewportHeight = Math.min(window.innerHeight, document.documentElement.clientHeight, document.documentElement.offsetHeight, document.documentElement.scrollHeight);
    if (viewportHeight <= 715) {
      _(params).extend({
        yOffset: -125,
        arrowOffset: 130
      });
    }
    return hopscotch.startTour({
      id: 'radio-inputs',
      showCloseBtn: true,
      i18n: {
        doneBtn: $.t('translation:actions.ok'),
        stepNums: false
      },
      steps: [params],
      onEnd: (function(_this) {
        return function() {
          _this._dontShowAgain('dontShowRadioInputsClearInfo');
          return hopscotch.endTour();
        };
      })(this)
    });
  };

  RecommendationsView.prototype.showNewEtdTemplateInfo = function() {
    if (window.hopscotch.getCurrStepNum()) {
      return;
    }
    return hopscotch.startTour({
      id: 'recommendations-view',
      showCloseBtn: true,
      i18n: {
        doneBtn: $.t('translation:actions.ok'),
        stepNums: false
      },
      steps: [
        {
          customData: {
            dontShowAgain: true
          },
          title: $.t(''),
          content: $.t('es:recommendations.table.tooltips.new_etd_template_version'),
          target: '.outcome-view',
          placement: 'left',
          yOffset: -5
        }
      ],
      onEnd: (function(_this) {
        return function() {
          _this._dontShowAgain('dontShowNewEtdTemplateInfo');
          return hopscotch.endTour();
        };
      })(this)
    });
  };

  RecommendationsView.prototype._showEditOptionsInfo = function(selector) {
    if (window.hopscotch.getCurrStepNum()) {
      return;
    }
    return hopscotch.startTour({
      id: 'edit-options',
      showCloseBtn: true,
      bubbleWidth: 400,
      i18n: {
        doneBtn: $.t('translation:actions.ok'),
        stepNums: false
      },
      steps: [
        {
          customData: {
            dontShowAgain: true
          },
          title: $.t(''),
          content: $.t('es:recommendations.table.tooltips.editing_feature_notification'),
          target: selector,
          placement: 'left',
          xOffset: -10
        }
      ],
      onEnd: (function(_this) {
        return function() {
          _this._dontShowAgain('dontShowEditOptionsInfo');
          return hopscotch.endTour();
        };
      })(this)
    });
  };

  RecommendationsView.prototype._dontShowAgain = function(dbAttribute) {
    return mediator.user.save(dbAttribute, $('#dont-show-again-input').prop('checked'));
  };

  RecommendationsView.prototype._findCheckedRadioButtonsInEl = function($el) {
    return $el.find('input[type="radio"]:checked').length === 0;
  };

  RecommendationsView.prototype._findCheckedRadioButtonsBySel = function(sel) {
    return this.$(sel).find('input[type="radio"]:checked').length === 0;
  };

  RecommendationsView.prototype._showContextMenu = function(e, params) {
    var $target, clearRadioContextMenu, options;
    if (params == null) {
      params = null;
    }
    e.preventDefault();
    $target = $(e.target);
    options = {
      model: this.model,
      target: $target
    };
    _.extend(options, params);
    clearRadioContextMenu = new ClearRadioContextMenuView(options);
    this.subview('clearRadioContextMenu', clearRadioContextMenu);
    return clearRadioContextMenu.show(utils.getEventCoordinates(e));
  };

  RecommendationsView.prototype._checkForEtrUpdates = function() {
    var manualMigrationNeeded, namespace, notMigratedData, updateNeeded;
    updateNeeded = this.model.updateEtrIfNecessary();
    if (updateNeeded) {
      this.model.set('migrationDone', false);
    }
    if (this.model.get('migrationDone') === false) {
      notMigratedData = this._getNotMigratedData();
      manualMigrationNeeded = _(notMigratedData).filter(function(data) {
        var _ref;
        return _.string.trim((_ref = data.value) != null ? _ref : '');
      }).length > 0;
      namespace = 'es:recommendations.';
      mediator.dialogs.info({
        title: $.t("" + namespace + "data_migration.automatically_migrated.title"),
        message: manualMigrationNeeded ? $.t("" + namespace + "data_migration.automatically_migrated.description_manual") : $.t("" + namespace + "data_migration.automatically_migrated.description_no_manual"),
        closeBtn: false,
        buttons: [
          {
            'data-handler': 'destroy',
            text: $.t('actions.ok')
          }
        ],
        modal: true
      });
      if (manualMigrationNeeded) {
        return this._displayMigrationBottomPane(notMigratedData);
      } else {
        this.model.set('migrationDone', true);
        return this.model.save().otherwise(mediator.dialogs.fatalError);
      }
    }
  };

  RecommendationsView.prototype._getNotMigratedData = function() {
    var namespace;
    namespace = 'es:recommendations.';
    return [
      {
        label: $.t("" + namespace + "data_migration.values_preferences_explanation"),
        value: this.model.get('valuesPreferencesExplanation')
      }, {
        label: $.t("" + namespace + "table.panel_decisions"),
        value: this.model.get('panelDecisions')
      }, {
        label: $.t("" + namespace + "data_migration.recommendations_explanation"),
        value: this.model.get('recommendationExplanation')
      }
    ];
  };

  RecommendationsView.prototype._displayMigrationBottomPane = function(notMigratedData) {
    var paneContentData;
    paneContentData = {
      notMigratedData: notMigratedData
    };
    return this.subview('bottomPane', new RecommendationsMigrationView({
      container: '#page-container',
      paneContentData: paneContentData,
      parentView: this,
      questionModel: this.model
    }));
  };

  RecommendationsView.prototype._setupBenefitsAndHarmsForUtilities = function() {
    Handlebars.registerPartial('recommendationBenefitsHarms', require('views/templates/evidence_syntheses/partials/recommendation_bh_utilities'));
    if (!this.model.get('effectDenominatorForRecommendation')) {
      this.model.set('effectDenominatorForRecommendation', su.effectsCommonDenominator(this._getIncludedOutcomes()));
    }
    return this.delegate('click', '.research-evidence-sof .denominator', this._denominatorChoiceList);
  };

  RecommendationsView.prototype._setupBenefitsAndHarmsWoUtilities = function() {
    return Handlebars.registerPartial('recommendationBenefitsHarms', require('views/templates/evidence_syntheses/partials/recommendation_bh'));
  };

  RecommendationsView.prototype._getIncludedOutcomes = function() {
    var chosenOutcome, chosenOutcomes, id, outcome, outcomes, _i, _j, _len, _len1, _ref, _results;
    outcomes = this.model.getCriticalAndImportantOutcomes();
    this._chooseOutcomes = outcomes.length > this._MAX_OUTCOMES_NO;
    if (this._chooseOutcomes) {
      if (!this.model.get('outcomesForRecommendation')) {
        this.model.set('outcomesForRecommendation', _(outcomes).chain().pluck('id').take(this._MAX_OUTCOMES_NO).value());
      }
      chosenOutcomes = [];
      _ref = this.model.get('outcomesForRecommendation');
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        id = _ref[_i];
        chosenOutcome = this.model.get('outcomes').get(id);
        if (chosenOutcome) {
          chosenOutcomes.push(chosenOutcome.toJSON());
        }
      }
      return chosenOutcomes;
    } else {
      _results = [];
      for (_j = 0, _len1 = outcomes.length; _j < _len1; _j++) {
        outcome = outcomes[_j];
        _results.push(outcome.toJSON());
      }
      return _results;
    }
  };

  RecommendationsView.prototype._openOutcomesDialog = function() {
    var form;
    this.subview('chooseOutcomes', form = new OutcomesSelectionView({
      outcomes: this.model.getCriticalAndImportantOutcomes(),
      selection: this.model.get('outcomesForRecommendation')
    }));
    return form.promise().then((function(_this) {
      return function(selection) {
        return _this.model.save('outcomesForRecommendation', selection);
      };
    })(this)).then((function(_this) {
      return function() {
        return _this.render();
      };
    })(this), function(reason) {
      if (reason !== 'cancelled') {
        return mediator.dialogs.fatalError(reason);
      }
    });
  };

  RecommendationsView.prototype._denominatorChoiceList = function(e) {
    var items, list, subviewName;
    subviewName = 'denominatorChoice';
    items = [
      {
        value: '100',
        text: '100'
      }, {
        value: '1000',
        text: '1000'
      }, {
        value: '10000',
        text: '10000'
      }, {
        value: '1000000',
        text: '1000000'
      }
    ];
    list = new SelectionView({
      listItems: items,
      acceptHandler: (function(_this) {
        return function() {
          return _this.model.save('effectDenominatorForRecommendation', _this.subview(subviewName).getSelectedItemValue());
        };
      })(this),
      abortHandler: (function(_this) {
        return function() {
          var _ref;
          return (_ref = _this.subview(subviewName)) != null ? _ref.hide() : void 0;
        };
      })(this)
    });
    this.subview(subviewName, list);
    return list.show({
      left: e.clientX,
      top: e.clientY
    }, this.model.get('effectDenominatorForRecommendation'));
  };

  RecommendationsView.prototype._extendBindings = function(updateMethod, selector, saveModel) {
    var bindingSelector, el, name;
    return _(this.bindings).extend(_.object((function() {
      var _i, _len, _ref, _results;
      _ref = this.$(selector);
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        el = _ref[_i];
        name = el.getAttribute('name');
        bindingSelector = "" + selector + "[name=" + name + "]";
        _results.push([bindingSelector, this._stickitBinding(this.model, name, updateMethod, bindingSelector, saveModel)]);
      }
      return _results;
    }).call(this)));
  };

  RecommendationsView.prototype._enableMediumEditor = function() {
    return new MediumEditor(this.$('.editable-content'), gdtMediumOptions["default"]);
  };

  RecommendationsView.prototype.renderEtdComponent = function() {
    var etdId, metadocId, projectId;
    etdId = _.last(this.model.get('recommendationIds'));
    if (mediator.services.switches.isOn('voting')) {
      projectId = mediator.project.id;
      metadocId = "" + projectId + ":panel-voice-metadoc";
      return mediator.services.storePersistenceAdapter.fetch(projectId, metadocId).then((function(_this) {
        return function(panelVoiceMetadoc) {
          _this.votingVersion = 'new';
          if (etdId in panelVoiceMetadoc.etds) {
            return _this.renderSimpleComponent(PanelVoiceEtd, {
              questionId: _this.model.get('_id')
            });
          } else {
            _this.votingVersion = 'old';
            return _this.renderSimpleComponent(Etd, {
              questionId: _this.model.get('_id')
            });
          }
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          if (err.status !== 404) {
            throw err;
          }
          _this.votingVersion = 'old';
          return _this.renderSimpleComponent(Etd, {
            questionId: _this.model.get('_id')
          });
        };
      })(this));
    } else {
      return this.renderSimpleComponent(Etd, {
        questionId: this.model.get('_id')
      });
    }
  };

  RecommendationsView.prototype.afterRender = function() {
    var bindings, outcome, _i, _len, _ref;
    RecommendationsView.__super__.afterRender.apply(this, arguments);
    this._enableRecommendationViewTypeSwitch();
    if (this.projectRecommendationsMode === 'static') {
      this.renderEtdComponent();
      return;
    }
    this.subview('header', new RecommendationsHeaderView({
      model: this.model,
      container: 'div.recommendations-header',
      parentView: this
    }));
    this._extendBindings('text', 'input[data-bind-attr]');
    this._extendBindings('html', 'div.editable-content', false);
    this.stickit();
    _ref = this._getIncludedOutcomes();
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      outcome = _ref[_i];
      bindings = {};
      bindings["[name=" + outcome.id + "-researchEvidenceRelativeImportance]"] = this._stickitBinding(outcome, 'researchEvidenceRelativeImportance');
      this.stickit(outcome, bindings);
    }
    this.enable(ImageInsertion, {
      container: 'table.judgement-table'
    });
    this.enable(ContentEditableElements);
    this._runAutoInsert();
    this._enableMediumEditor();
    mediator.publish('recommendationsViewRendered');
    if (!mediator.user.get('dontShowRadioInputsClearInfo')) {
      _.defer((function(_this) {
        return function() {
          return _this._clearRadioInputsInfo();
        };
      })(this));
    }
    if (!mediator.user.get('dontShowNewEtdTemplateInfo') && this.projectRecommendationsMode === 'initial') {
      return _.defer((function(_this) {
        return function() {
          return _this.showNewEtdTemplateInfo();
        };
      })(this));
    }
  };

  RecommendationsView.prototype._setProjectRecommendationsMode = function() {
    var canSetProjectRecommendationsMode, contentEditableDivs, dataAttributes, fakeDoc, inputElements, recommendationsHtml;
    this.projectRecommendationsMode = mediator.project.get('recommendationsMode');
    if (this.projectRecommendationsMode) {
      return;
    }
    recommendationsHtml = this.getTemplateFunction(true)(this.getTemplateData());
    fakeDoc = document.implementation.createHTMLDocument('fake');
    fakeDoc.body.innerHTML = recommendationsHtml;
    inputElements = _(fakeDoc.body.querySelectorAll('input[data-bind-attr]')).toArray();
    contentEditableDivs = _(fakeDoc.body.querySelectorAll('div.editable-content')).toArray();
    dataAttributes = _(inputElements).chain().union(contentEditableDivs).map(function(el) {
      return el.getAttribute('name');
    }).uniq().value();
    fakeDoc = null;
    canSetProjectRecommendationsMode = !_(mediator.questions.models).chain().pluck('attributes').map(function(attributesObject) {
      return _(attributesObject).keys();
    }).uniq().flatten().value().some(function(attr) {
      return dataAttributes.indexOf(attr) >= 0;
    });
    this.projectRecommendationsMode = canSetProjectRecommendationsMode ? 'static' : 'initial';
    mediator.project.set('recommendationsMode', this.projectRecommendationsMode);
    return mediator.project.save();
  };

  RecommendationsView.prototype._enableRecommendationViewTypeSwitch = function() {
    if (this.projectRecommendationsMode === 'initial' && !this.model.get('switchableRecommendationsView')) {
      return this.model.save('switchableRecommendationsView', true);
    }
  };

  RecommendationsView.prototype._runAutoInsert = function() {
    var autoInsertedCellsObject, cellName, content, insertData, templateData, _ref, _ref1, _results;
    if (!this.autoInsertionCells) {
      return;
    }
    _ref = this.autoInsertionCells;
    _results = [];
    for (cellName in _ref) {
      insertData = _ref[cellName];
      if ((_ref1 = this.model.get('autoInsertedCells')) != null ? _ref1[cellName] : void 0) {
        continue;
      }
      this.currentEditableContent = this.$(".editable-content[name=" + cellName + "]");
      this.currentStaticContent = this.$(".static-content[name=" + cellName + "]");
      if (this.currentEditableContent.length === 0 || this.currentStaticContent.length === 0) {
        continue;
      }
      content = _.isString(insertData) ? insertData : _.isFunction(insertData) ? (templateData = _.clone(this.getTemplateData()), insertData(templateData)) : void 0;
      this._updateContentEditable(content);
      this._editContentApply();
      autoInsertedCellsObject = this.model.get('autoInsertedCells') || {};
      autoInsertedCellsObject[cellName] = true;
      _results.push(this.model.save('autoInsertedCells', autoInsertedCellsObject));
    }
    return _results;
  };

  RecommendationsView.prototype._prepareRowspansForIncludedOutcomes = function(outcomes) {
    var controlRisks, enabledRisks, outcome, riskControls, _i, _len;
    controlRisks = Outcome.prototype.getControlAttributes();
    for (_i = 0, _len = outcomes.length; _i < _len; _i++) {
      outcome = outcomes[_i];
      riskControls = [outcome.calculatedControl, outcome.lowControl, outcome.moderateControl, outcome.highControl];
      enabledRisks = _(controlRisks).filter(function(name, idx) {
        return riskControls[idx];
      });
      outcome.headControl = enabledRisks[0];
      outcome.tailControls = enabledRisks.slice(1);
      outcome.rowspan = enabledRisks.length;
    }
    return outcomes;
  };

  RecommendationsView.prototype.getTemplateData = function() {
    var data, outcome;
    data = RecommendationsView.__super__.getTemplateData.apply(this, arguments);
    data.printout = this.options.printout;
    data.includedOutcomes = this._prepareRowspansForIncludedOutcomes(this._getIncludedOutcomes());
    data.includedOutcomesNumber = data.includedOutcomes.length;
    data.chooseOutcomes = this._chooseOutcomes;
    data.dxL1Outcomes = (function() {
      var _i, _len, _ref, _results;
      _ref = this.model.get('outcomes').models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        outcome = _ref[_i];
        _results.push(outcome.toJSON());
      }
      return _results;
    }).call(this);
    return data;
  };

  RecommendationsView.prototype._disposeReactComponent = function() {
    return this.unmountComponent();
  };

  RecommendationsView.prototype._disposeBBView = function() {
    this.unstickit();
    this.stopObservations();
    return this.runDelayedActions();
  };

  RecommendationsView.prototype._disposeCurrentMode = function() {
    if (this.projectRecommendationsMode === 'initial') {
      return this._disposeBBView();
    } else {
      return this._disposeReactComponent();
    }
  };

  RecommendationsView.prototype.dispose = function() {
    var _ref;
    this._disposeCurrentMode();
    if (((_ref = window.hopscotch.getCurrTour()) != null ? _ref.id : void 0) !== 'gdt-walkthrough') {
      window.hopscotch.endTour();
    }
    alt.recycle('EtdStore', 'AdolopedEtdStore', 'QuestionsStore', 'EtdVotingStore', 'OverarchingQuestionsStore');
    if (this.votingVersion === 'new') {
      alt.recycle('PanelVoiceStore', 'PanelVoiceEtdStore');
    }
    return RecommendationsView.__super__.dispose.apply(this, arguments);
  };

  return RecommendationsView;

})(View);
