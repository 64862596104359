var MultiComparisonDetails, MultiComparisonDetailsView, MultiComparisonsToolbar, ReactComponent, ReactToolbarView, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

MultiComparisonDetails = require('components/multi_comparisons/multi_comparison_details');

MultiComparisonsToolbar = require('components/multi_comparisons/multi_comparisons_toolbar');

module.exports = MultiComparisonDetailsView = (function(_super) {
  __extends(MultiComparisonDetailsView, _super);

  function MultiComparisonDetailsView() {
    return MultiComparisonDetailsView.__super__.constructor.apply(this, arguments);
  }

  MultiComparisonDetailsView.prototype.container = '#page-container';

  MultiComparisonDetailsView.prototype.className = 'multi-comparisons-view';

  MultiComparisonDetailsView.prototype.autoRender = true;

  MultiComparisonDetailsView.prototype.initialize = function(params) {
    MultiComparisonDetailsView.__super__.initialize.apply(this, arguments);
    this.mcId = params.mcId;
    this.subview('toolbar', new ReactToolbarView({
      component: MultiComparisonsToolbar,
      props: {
        view: 'details'
      }
    }));
    return this.enable(ReactComponent);
  };

  MultiComparisonDetailsView.prototype.afterRender = function() {
    MultiComparisonDetailsView.__super__.afterRender.apply(this, arguments);
    this._stopInputsPropagation = false;
    return this.renderSimpleComponent(MultiComparisonDetails, {
      mcId: this.mcId
    });
  };

  MultiComparisonDetailsView.prototype.dispose = function() {
    this.unmountComponent();
    return MultiComparisonDetailsView.__super__.dispose.apply(this, arguments);
  };

  return MultiComparisonDetailsView;

})(View);
