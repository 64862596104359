mediator =  require 'mediator'
KeyMessagesActions = require 'actions/key_messages_actions'
QuestionsActions = require 'actions/questions_actions'
EllipsizedText = require 'components/common/ellipsized_text'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ getQuestionSubmoduleUrl } = require 'lib/questions_helper'
{ GdtNavBar, GdtNavBarItem } = require 'components/common/gdt_navigation_bar'
{ bool, string } = PropTypes

DEFAULT_SUBMODULES = ['quality_of_evidence', 'recommendations', 'presentations']
MDG_SUBMODULES = ['mdg_table', 'mdg_recommendations']

QuestionTextWithNavigation = createReactClass
  displayName: 'QuestionTextWithNavigation'

  mixins: [
    CustomRenderMixin
    Translation()
  ]

  propTypes:
    locked: bool
    overReviewModuleState: bool
    questionId: string.isRequired
    questionType: string
    text: string.isRequired

  getInitialState: ->
    isHovered: false
    locked: false

  getQuestionSubmodules: ->
    modules = _.map DEFAULT_SUBMODULES, (submodule) ->
      mediator.services.modules.isVisible "evidence_syntheses##{submodule}", mediator.project
    if @props.questionType is 'keyMessage'
      ['recommendations']
    else if @props.questionType is 'mdgQuestion'
      MDG_SUBMODULES
    else if Boolean(@props.overReviewModuleState)
      ['over_review'].concat DEFAULT_SUBMODULES
    else
      modules

  handleMouseEnter: ->
    @setState isHovered: true

    unless @props.overReviewModuleState?
      QuestionsActions.getQuestionOverReviewModuleState @props.questionId

  handleMouseLeave: ->
    @setState isHovered: false

  # FIXME: to be removed together with comment in render()
  navigateToOutcomes: ->
    { questionId, questionType, locked } = @props
    if locked and not mediator.user.isAdmin()
      mediator.dialogs.info @i18n 'projects:locked'
      return
    if questionType is 'keyMessage'
      KeyMessagesActions.goToKeyMessageDetails questionId
    else if questionType is 'mdgQuestion'
      link = getQuestionSubmoduleUrl 'evidence_syntheses', 'mdg_evidence_table', questionId
      mediator.publish '!router:route', link
    else
      isQOEEnabled = mediator.services.modules.isVisible 'evidence_syntheses#quality_of_evidence',
        mediator.project
      submodule = if isQOEEnabled then 'quality-of-evidence' else 'recommendations'
      link = getQuestionSubmoduleUrl 'evidence_syntheses', submodule, questionId
      mediator.publish '!router:route', link

  render: ->
    { questionId, text } = @props
    { isHovered } = @state
    submodules = @getQuestionSubmodules()

    # FIXME: removed onMouseEnter={@handleMouseEnter} and onMouseLeave={@handleMouseLeave}
    # from main container as T1754 requested to postpone new navigation and restore old one
    <div className='question-text'>
      <EllipsizedText text={text} height={40} onClick={@navigateToOutcomes}/>
      {if isHovered
        <div className='question-tables-navigation'>
          <GdtNavBar>
            {submodules.map (subModuleName) =>
              tableTitle = @i18n "nav.evidence_syntheses_submodules.#{subModuleName}"
              href = getQuestionSubmoduleUrl 'evidence_syntheses', subModuleName, questionId

              <GdtNavBarItem key={subModuleName}>
                <a href={"/#{href}"}>{tableTitle}</a>
              </GdtNavBarItem>
            }
          </GdtNavBar>
        </div>
      }
    </div>

module.exports = QuestionTextWithNavigation
