var ALGORITHM_PREFIX, AlgorithmsDoc, errorHandlerWithMsg, generateActions, generateGUID, mediator;

AlgorithmsDoc = require('lib/db_docs/algorithms_doc');

ALGORITHM_PREFIX = require('lib/doc_prefixes').algorithms;

generateActions = require('actions/utils/generate_actions');

generateGUID = require('base/lib/utils').generateGUID;

mediator = require('mediator');

errorHandlerWithMsg = require('lib/app_utils').errorHandlerWithMsg;

module.exports = generateActions({
  autoGenerate: ['fetchSuccess', 'fetchError', 'toggleAlgorithmEdit'],
  name: 'AlgorithmsActions',
  actions: {
    fetch: function(_arg) {
      var dbId;
      dbId = _arg.dbId;
      this.dispatch();
      return AlgorithmsDoc.at(dbId).fetchMany().then((function(_this) {
        return function(_arg1) {
          var rows;
          rows = _arg1.rows;
          return _this.actions.fetchSuccess(_.pluck(rows, 'doc'));
        };
      })(this))["catch"](this.actions.fetchError);
    },
    dbChange: function(change, _pending, _lastSeq) {
      if (change.doc._id.indexOf(ALGORITHM_PREFIX) !== 0) {
        return;
      }
      return this.dispatch(change);
    },
    addNewAlgorithm: function(projectId) {
      var maxOrderNumberItem, newId, orderNumber, _ref;
      newId = "" + ALGORITHM_PREFIX + (generateGUID());
      maxOrderNumberItem = this.alt.stores.AlgorithmsStore.getAlgorithms().valueSeq().maxBy(function(algorithm) {
        return _.get(algorithm, 'orderNumber');
      });
      orderNumber = ((_ref = _.get(maxOrderNumberItem, 'orderNumber')) != null ? _ref : 0) + 1;
      this.dispatch(newId);
      return AlgorithmsDoc.at(projectId, newId).create({
        _id: newId,
        orderNumber: orderNumber
      });
    },
    updateAlgorithm: function(projectId, algorithmId, change, showMsg) {
      if (showMsg == null) {
        showMsg = true;
      }
      return AlgorithmsDoc.at(projectId, algorithmId).update(change).then(function() {
        if (showMsg) {
          return mediator.dialogs.success($.t('algorithms:list.save_success'));
        }
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('algorithms:list.save_error'));
      });
    },
    deleteAlgorithm: function(projectId, algorithmId) {
      return AlgorithmsDoc.at(projectId, algorithmId)["delete"]().then(function() {
        return mediator.dialogs.success($.t('algorithms:list.delete_success'));
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('algorithms:list.delete_error'));
      });
    }
  }
});
