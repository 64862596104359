{ oneOf, bool, string } = PropTypes
{ StepsContainer, Step } = require 'components/common/steps'
Modal = require 'components/common/modal'
ConnectStore = require 'components/enhancers/connect_store'
Translation = require 'components/mixins/translation'
ImportActions = require 'actions/import_actions'
ImportStore = require 'stores/import_store'
QuestionsStore = require 'stores/questions_store'
ImportChooseSource = require 'components/import_dialog/import_choose_source'
ImportQuestionsList = require 'components/import_dialog/import_questions_list'
ImportProjectName = require 'components/import_dialog/import_project_name'
RevmanCsvConflictMethodDialog = require 'components/import_dialog/import_revman_csv_conflict_method'
withLimits = require 'components/enhancers/with_limits'

ImportQuestionsListWithLimits = withLimits ImportQuestionsList

storeConnectors =
  ImportStore: (Store) ->
    importType: Store.getImportType()
    currentStep: Store.getCurrentStep()
    isSourceChosen: Store.isSourceChosen()
    isAnyItemChosen: Store.isAnyItemChosen()
    isImporting: Store.isImporting()
    isParsing: Store.isParsing()
    isOpen: Store.isImportModalOpened()
  QuestionsStore: (Store, props) ->
    comparisonsQuestionsCount: if props.importType is 'project'
      0
    else
      Store.getQuestions().size

ImportDialog = createReactClass
  displayName: 'ImportDialog'
  mixins: [Translation('projects:import_dialog')]

  propTypes:
    importType: oneOf(['project', 'question', 'outcome'])
    currentStep: string.isRequired
    isSourceChosen: bool.isRequired
    isAnyItemChosen: bool.isRequired
    isImporting: bool.isRequired
    isParsing: bool.isRequired
    isOpen: bool.isRequired

  _isNextStepEnabled: ->
    switch @props.currentStep
      when 'import-project-choose-source'
        @props.isSourceChosen
      else
        @props.isAnyItemChosen

  _getChooseQuestionsStepLabel: ->
    labelKey = if @props.importType is 'project' then 'question' else @props.importType
    @i18n "choose_#{labelKey}s_step_title"

  _getTitle: ->
    if @props.importType is 'project'
      @i18n 'title'
    else
      @i18n "title_#{@props.importType}s_only"

  _isApplying: ->
    switch @props.currentStep
      when 'import-project-choose-source'
        @props.isParsing
      when 'import-project-choose-questions'
        @props.isImporting

  parseQuestions: ->
    rawImportData = ImportStore.getRawImportData()
    return unless rawImportData
    ImportActions.parseQuestions rawImportData

  import: ->
    importData = ImportStore.getImportData()
    return unless importData

    if @props.importType is 'project'
      ImportActions.importProject importData
    else
      ImportActions.importQuestionsOrOutcomes importData

  render: ->
    return null unless @props.isOpen

    classes = classNames
      'import-modal': true
      'choose-questions-step': @props.currentStep is 'import-project-choose-questions'

    <Modal
      isOpen
      className={classes}
      title={@_getTitle()}
      closeButton={not @props.isImporting and not @props.isParsing}
      onClose={ImportActions.closeImportModal}
    >
      <StepsContainer
        applying={@_isApplying()}
        isNextStepEnabled={@_isNextStepEnabled()}
        currentStep={@props.currentStep}
      >
        <Step
          stepKey="import-project-choose-source"
          stepLabel={@i18n 'choose_source_step_title'}
          nextLabel={@i18n '/actions.next'}
          backLabel={@i18n '/actions.cancel'}
          onNext={@parseQuestions}
          onBack={ImportActions.closeImportModal}
        >
          <ImportChooseSource />
        </Step>
        <Step
          stepKey="import-project-choose-questions"
          stepLabel={@_getChooseQuestionsStepLabel()}
          backLabel={@i18n '/actions.back'}
          nextLabel={@i18n "/import.#{@props.importType}s"}
          onBack={ImportActions.goBack}
          onNext={@import}
        >
          <ImportProjectName />
          {if @props.importType is 'outcome'
            <ImportQuestionsList />
          else
            <ImportQuestionsListWithLimits
              comparisonsQuestionsCount={@props.comparisonsQuestionsCount}
            />
          }
        </Step>
      </StepsContainer>
      <RevmanCsvConflictMethodDialog />
    </Modal>

ImportDialog.defaultProps = importType: 'question'

module.exports = ConnectStore ImportDialog, [ImportStore, QuestionsStore], storeConnectors
