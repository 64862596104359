OverReviewActions = require 'actions/over_review_actions'
Translation = require 'components/mixins/translation'
EditableTextItem = require 'components/over_review/editable_text_item'
{ array, string } = PropTypes

OutcomeItem = (props) ->
  { isEditing, label, onChange, onDelete, onEdit, onRequestCancel, onRequestSave, name } = props

  <div className='reviewed-outcome'>
    <EditableTextItem
      isEditing={isEditing}
      label={label}
      onChange={onChange}
      onDelete={onDelete}
      onEdit={onEdit}
      onRequestCancel={onRequestCancel}
      onRequestSave={onRequestSave}
      text={name}
    />
  </div>

ReviewedOutcomesList = createReactClass
  displayName: 'ReviewedOutcomesList'

  propTypes:
    reviewedOutcomes: array
    questionId: string.isRequired

  getInitialState: ->
    editingOutcome: null # {id: reviewedOutcomeId, name: string}

  mixins: [
    Translation('over_review:actions')
  ]

  startEditingOutcome: (reviewedOutcomeId, name) -> =>
    @saveEditingOutcome(false) if @state.editingOutcome

    @setState editingOutcome:
      id: reviewedOutcomeId
      name: name

  stopEditingOutcome: ->
    @setState editingOutcome: null

  handleEditingOutcomeChange: (evt) ->
    @setState _.extend @state.editingOutcome, name: evt.target.value

  saveEditingOutcome: (stopEditing = true) ->
    { id, name } = @state.editingOutcome
    { questionId } = @props

    unless _.isEmpty name
      if id
        OverReviewActions.updateReviewedOutcome
          questionId: questionId
          reviewedOutcomeId: id
          outcomeData: { name }
      else
        OverReviewActions.createReviewedOutcome { questionId, name }

    @stopEditingOutcome() if stopEditing

  handleReviewedOutcomeDelete: (reviewedOutcomeId) -> =>
    { questionId } = @props
    OverReviewActions.deleteReviewedOutcome { questionId, reviewedOutcomeId }

  renderOutcomeItem: ({ name, _id }, idx) ->
    isEditing = @state.editingOutcome?.id is _id

    <OutcomeItem
      key={idx}
      isEditing={isEditing}
      label={$.t 'over_review:outcome'}
      name={if isEditing then @state.editingOutcome.name else name}
      onChange={@handleEditingOutcomeChange}
      onDelete={@handleReviewedOutcomeDelete(_id)}
      onEdit={@startEditingOutcome(_id, name)}
      onRequestCancel={@stopEditingOutcome}
      onRequestSave={@saveEditingOutcome}
    />

  render: ->
    { editingOutcome } = @state
    <div className='reviewed-outcomes-list'>
      <div>
        {@props.reviewedOutcomes.map @renderOutcomeItem}
        {if editingOutcome? and not editingOutcome.id
          <OutcomeItem
            isEditing
            label=''
            onChange={@handleEditingOutcomeChange}
            onDelete={@stopEditingOutcome}
            onRequestCancel={@stopEditingOutcome}
            onRequestSave={@saveEditingOutcome}
            name={editingOutcome.name}
          />
        }
      </div>
      <div className='bottom-buttons'>
        <button className='btn btn-apply' onClick={@startEditingOutcome(null, '')}>
          {@i18n 'add_reviewed_outcome'}
        </button>
      </div>
    </div>

module.exports = ReviewedOutcomesList
