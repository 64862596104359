Translation = require 'components/mixins/translation'

StatusLegend = createReactClass

  displayName: "StatusLegend"

  mixins: [ Translation('voting:panel_voice') ]

  render: ->
    <div className="status-legend" >
      <div className="arrow">
        <div className="inside-arrow" />
      </div>
      <h3>{@i18n 'labels.status'}</h3>
      <ul>
        <li><span className="bold">A</span> - {@i18n 'labels.assessment'}</li>
        <li><span className="bold">R</span> - {@i18n 'labels.recommendation'}</li>
        <li><span className="bold">C</span> - {@i18n 'labels.conclusion'}</li>
      </ul>
      <div className="separator" />
      <div className="legend-rects">
        <div className="left-col">
          <div className="rect-container">
            <div className="rect ongoing" />
            <div className="label">{@i18n 'statuses.ongoing'}</div>
          </div>
          <div className="rect-container">
            <div className="rect closed" />
            <div className="label">{@i18n 'statuses.closed'}</div>
          </div>
        </div>
        <div className="right-col">
          <div className="rect-container">
            <div className="rect unsent" />
            <div className="label">{@i18n 'statuses.unsent'}</div>
          </div>
          <div className="rect-container">
            <div className="rect skipped" />
            <div className="label">{@i18n 'statuses.skipped'}</div>
          </div>
        </div>
      </div>
    </div>

module.exports = StatusLegend
