ConnectStore = require 'components/enhancers/connect_store'
DropdownMenu = require 'components/common/dropdown_menu'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
Modal = require 'components/common/modal'
NMAInterventionEditModal = require 'components/nma/nma_intervention_edit_modal'
NMAQuestionActions = require 'actions/nma_question_actions'
NMAQuestionStore = require 'stores/nma_question_store'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'
{ DndProvider, useDrag, useDrop } = ReactDnD
{ useRef } = React

NMA_INTERVENTION_DND_TYPE = 'nma_intervention'

NMAIntervention = ({ index, intervention, questionId }) ->
  i18n = useI18n 'nma:question.interventions'

  interventionName = intervention.get 'name', ''
  interventionId = intervention.get '_id'

  onMenuSelect = useCoffeeCallback [intervention, interventionName, questionId], (option) ->
    switch option
      when 'edit'
        NMAQuestionActions.openInterventionEditModal intervention.toJS()
      when 'delete'
        interventionName = intervention.get 'name', ''
        confirmOptions =
          title: i18n 'confirm_delete.title'
          message: i18n 'confirm_delete.message', { interventionName }
          confirmText: i18n '/actions.delete'
          declineText: i18n '/actions.cancel'
          yesClass: 'danger'
        mediator.dialogs.confirm confirmOptions, (confirmed) ->
          return unless confirmed
          NMAQuestionActions.deleteIntervention questionId, intervention.toJS()

  ref = useRef null

  [{ isDragging }, drag, dragPreview] = useDrag
    item: { index, interventionId, type: NMA_INTERVENTION_DND_TYPE }
    collect: (monitor) ->
      isDragging: monitor.isDragging()

  [{ isOver }, drop] = useDrop
    accept: NMA_INTERVENTION_DND_TYPE
    collect: (monitor) ->
      isOver: monitor.isOver()
    drop: ->
      NMAQuestionActions.saveMovedIntervention questionId
    hover: (item, monitor) ->
      return unless ref.current
      return if item.index is index
      hoverBoundingRect = ref.current.getBoundingClientRect()
      hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      clientOffset = monitor.getClientOffset()
      hoverClientY = clientOffset.y - hoverBoundingRect.top
      return if item.index < index and hoverClientY < hoverMiddleY
      return if item.index > index and hoverClientY > hoverMiddleY
      placementDelta = if item.index < index then 1 else 0
      NMAQuestionActions.moveIntervention
        hoverInterventionId: interventionId
        movingInterventionId: item.interventionId
        placementDelta: placementDelta
      item.index = index

  drop drag dragPreview ref

  className = classNames 'nma-intervention-management-modal__intervention',
    dragging: isDragging
    over: isOver

  <div className={className} ref={ref}>
    <div className="intervention__names">
      <Tooltip>
        <div className="intervention__name" title={interventionName}>
          {interventionName}
        </div>
      </Tooltip>
      <div className="intervention__short-name">
        {i18n 'short_name', shortName: intervention.get 'shortName', '-'}
      </div>
    </div>
    <DropdownMenu
      menuPosition={alignment: 'end'}
      onSelect={onMenuSelect}
      opener={<IconButton className="intervention__menu" iconName="more" />}
      options={[
        className: 'edit'
        text: i18n '/actions.edit'
        value: 'edit'
      ,
        className: 'delete'
        text: i18n '/actions.delete'
        value: 'delete'
      ]}
    />
  </div>

NMAIntervention.propTypes =
  index: PropTypes.number.isRequired
  intervention: PropTypes.instanceOf(Immutable.Map).isRequired
  questionId: PropTypes.string.isRequired

NMAInterventionManagementModal = ({ isOpen, question }) ->
  i18n = useI18n 'nma:question.interventions'

  interventions = question.get 'interventions', Immutable.List()

  onCreateIntervention = useCoffeeCallback [], ->
    NMAQuestionActions.openInterventionEditModal()

  <Modal
    className="nma-intervention-management-modal"
    closeButton
    isOpen={isOpen}
    modalSize="large"
    onClose={NMAQuestionActions.closeInterventionManagementModal}
    title={i18n 'title'}
  >
    <div className="nma-intervention-management-modal__header">
      <button className="btn btn-apply" onClick={onCreateIntervention}>
        {i18n '/actions.add_intervention'}
      </button>
    </div>
    <div className="nma-intervention-management-modal__interventions">
      <DndProvider backend={ReactDnDHTML5Backend.default}>
        {interventions.map (intervention, index) ->
          <NMAIntervention
            index={index}
            intervention={intervention}
            key={intervention.get '_id'}
            questionId={question.get '_id'}
          />
        }
      </DndProvider>
      {interventions.isEmpty() and <div
        className="nma-intervention-management-modal__no-interventions"
      >
        {i18n 'no_interventions'}
      </div>}
    </div>
    <NMAInterventionEditModal />
  </Modal>

NMAInterventionManagementModal.propTypes =
  isOpen: PropTypes.bool.isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired

storeConnector =
  NMAQuestionStore: (Store) ->
    isOpen: Store.isInterventionManagementModalOpen()
    question: Store.getCurrentQuestion()

module.exports = ConnectStore NMAInterventionManagementModal, [NMAQuestionStore], storeConnector
