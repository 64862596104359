RadioWithLabel = require 'components/common/radio_with_label'

HorizontalRadios = createReactClass
  displayName: 'HorizontalRadios'

  propTypes:
    groupName: PropTypes.string.isRequired
    options: PropTypes.instanceOf(Immutable.List).isRequired
    additionalOptions: PropTypes.instanceOf(Immutable.List).isRequired
    selectedOption: PropTypes.string
    proposedValue: PropTypes.string
    onChange: PropTypes.func.isRequired

  getDefaultProps: ->
    proposedValue: null
    selectedOption: null

  onChange: (e) ->
    @props.onChange e.currentTarget.value

  _renderRadioCell: (options, cellWidth, additionalOptions = false) ->

    { editable, selectedOption, groupName, proposedValue } = @props

    options.map (optionObj, idx) =>
      classes = classNames
        'additional-options-border': idx is 0 and additionalOptions
        proposed: optionObj.get('value') is proposedValue
      <td className={classes} style={width: "#{cellWidth}%"} key={optionObj.get 'value'}>
        <RadioWithLabel
          disabled={not editable}
          label={optionObj.get 'text'}
          name={groupName}
          value={optionObj.get 'value'}
          checked={optionObj.get('value') is selectedOption}
          onChange={@onChange}
        />
      </td>

  render: ->
    cellWidth = 100 / (@props.options.size + @props.additionalOptions.size)
    <table className="horizontal-radios">
      <tbody>
        <tr>
          {@_renderRadioCell @props.options, cellWidth}
          {@_renderRadioCell @props.additionalOptions, cellWidth, true}
        </tr>
      </tbody>
    </table>

module.exports = HorizontalRadios
