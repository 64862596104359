Modal = require 'components/common/modal'
ExportDialog = require 'components/common/export_dialog'
exportDocTemplate = require 'views/templates/printouts/scope/outcomes_generation_printout'
{ OUTCOMES_GENERATION_STEPS } = require 'lib/questions_helper'
Translation = require 'components/mixins/translation'
CommentsExportMixin = require 'components/mixins/scope_comments_export'

CommentsExportModal = createReactClass
  displayName: "OutcomesGenertionCommentsExport"

  propTypes:
    questionGroups: PropTypes.object.isRequired

  mixins: [
    Translation('scope:questions')
    CommentsExportMixin
  ]

  _getMemberString: (memberId) ->
    @props.membersMap.get memberId

  _getQuestionGroupsWithOrderedOutcomes: ->
    @props.questionGroups.map (qGroup) ->
      qGroup.set 'outcomes', qGroup.get('outcomes').sortBy (outcome) ->
        - outcome.get('medianRating')

  _anonymizeComments: ->
    @props.questionGroupsWithComments.map (qGroup) =>
      qGroup.set 'outcomes', qGroup.get('outcomes').map (outcome) =>
        outcome.set 'memberComments', outcome.get('memberComments').map (step) =>
          step.mapEntries ([memberId, comment]) =>
            [ @_getMemberString(memberId), comment ]

  getTemplateData: ->
    projectName: @props.projectName
    step: @props.tabName
    questionGroups: @_getQuestionGroupsWithOrderedOutcomes().toJS()
    questionGroupsWithComments: @_anonymizeComments().toJS()

  _getProposalExportOptionsSpec: ->
    name: 'exportContent'
    label: @i18n 'proposal.choose_export_content'
    isRequired: true
    optionsList: [
      { label: @i18n('proposal.rating_table_with_comments'), value: 'tableAndComments' }
      { label: @i18n('proposal.comments_only'), value: 'commentsOnly' }
    ]

  _getAdditionalOptions: ->
    hasCommentedOutcomes = not @props.questionGroupsWithComments.isEmpty()
    # on Proposal tab we must show additional options if there are any comments available
    if @props.tabName is OUTCOMES_GENERATION_STEPS[4] and hasCommentedOutcomes
      [ @_getProposalExportOptionsSpec() ]

  onDownload: (exportOptions) ->
    @exportToFile exportOptions, exportDocTemplate

  render: ->
    <Modal isOpen
      className="export-comments-modal"
      closeButton={false}
      title={@i18n '../outcomes.export_outcomes'}
      >
      <ExportDialog
        additionalOptions={@_getAdditionalOptions()}
        onClose={@props.onClose}
        onDownload={@onDownload}
      />
    </Modal>

module.exports = CommentsExportModal
