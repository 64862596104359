Button = require 'components/common/button'
Translation = require 'components/mixins/translation'

ScopeExportButton = createReactClass
  displayName: 'ScopeExportButton'

  propTypes:
    onClick: PropTypes.func.isRequired

  mixins: [Translation('')]

  render: ->
    toolbarClass = classNames 'toolbar-menu', @props.className
    <div className={toolbarClass}>
      <menu type='toolbar'>
        <Button
          className='export'
          title={@i18n 'actions.export'}
          onClick={@props.onClick}
          disabled={@props.disabled}
        />
      </menu>
    </div>

module.exports = ScopeExportButton
