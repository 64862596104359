Exceptions = require 'lib/exceptions'
mediator = require 'mediator'
PasswordInput = require 'components/common/password_input'
OauthLogin = require './oauth_login'
Translation = require 'components/mixins/translation'
Validation = require 'base/validation'

RegistrationForm = createReactClass
  displayName: 'RegistrationForm'

  mixins: [Translation('registration')]

  propTypes:
    email: PropTypes.string
    onEmailChange: PropTypes.func
    password: PropTypes.string
    onPasswordChange: PropTypes.func
    forgotPassword: PropTypes.func
    service: PropTypes.shape
      register: PropTypes.func.isRequired
    onLogin: PropTypes.func
    onInit: PropTypes.func
    onNoSubscription: PropTypes.func

  getInitialState: ->
    firstName: ''
    lastName: ''
    tos: false
    newsletterOptIn: false
    submitting: false
    errors: {}

  fieldChanged: (prop) -> ({target}) =>
    value = if target.type is 'checkbox' then target.checked else target.value
    @setState("#{prop}": value)

  setFieldError: (prop, err) -> @setState (state) ->
    errors: _(state.errors).extend("#{prop}": err)

  validateNonEmpty: (prop, value = @state[prop]?.trim()) ->
    return true if value
    @setFieldError(prop, @i18n("/errors:authentication.empty_#{prop}"))
    false

  validateEmail: ->
    return true if Validation.isValidEmail(@props.email)
    @setFieldError('email', @i18n("/errors:authentication.invalid_email"))
    false

  validateTos: ->
    return true if @state.tos
    @setFieldError('tos', @i18n("/errors:authentication.tos_not_accepted"))
    false

  validateFields: ->
    _.every([
      @validateNonEmpty('firstName')
      @validateNonEmpty('lastName')
      @validateEmail()
      @validateNonEmpty('password', @props.password)
      @validateTos()
    ])

  submit: (e) ->
    e.preventDefault()
    @setState(sharedError: null, errors: {})
    return unless @validateFields()
    @setState(submitting: true)
    @props.service.register(@state.firstName, @state.lastName, @props.email, @props.password,
      @state.tos, @state.newsletterOptIn).then =>
      @props.service.login(@props.email, @props.password)
    .then @props.onLogin, (error) =>
      if error instanceof Exceptions.authentication.email_in_use
        @setFieldError('email', error)
        @setState(submitting: false)
      else if error instanceof Exceptions.authentication.no_active_subscription
        @props.onNoSubscription(error)
      else
        @setState(sharedError: error, submitting: false)
    , @props.onInit

  getErrorMessage: ->
    return unless @state.sharedError
    <div className="error-shared" dangerouslySetInnerHTML={__html: @state.sharedError?.message} />

  getFieldErrorMessage: (field) ->
    error = @state.errors[field]
    if error instanceof Exceptions.authentication.email_in_use
      <div className="error">
        {error.message}<br/>
        <button type="button" onClick={@props.forgotPassword}>{@i18n('forgot_password')}</button>
      </div>
    else
      <div className="error">{error}</div>

  render: ->
    <form onSubmit={@submit} className="register-form">
      <a
        className="logo"
        href="http://gradepro.org"
        target="_blank"
        rel="noopener noreferrer">
          <img src="images/logo_new.png" />
      </a>
      <h1 className="register-form__title">{@i18n('new_account')}</h1>
      {@getErrorMessage()}

      <label>
        {@i18n('first_name')}
        <input value={@state.firstName} onChange={@fieldChanged('firstName')}
          autoFocus={true} />
      </label>
      {@getFieldErrorMessage('firstName')}

      <label>
        {@i18n('last_name')}
        <input value={@state.lastName} onChange={@fieldChanged('lastName')} />
      </label>
      {@getFieldErrorMessage('lastName')}

      <label>
        {@i18n('email')}
        <input
          placeholder={@i18n('email_placeholder')}
          value={@props.email}
          onChange={@props.onEmailChange}
        />
      </label>
      {@getFieldErrorMessage('email')}

      <label>
        {@i18n('password')}
        <PasswordInput value={@props.password} onChange={@props.onPasswordChange} />
      </label>
      {@getFieldErrorMessage('password')}

      <label className="tos-acceptance">
        <input className="tos" type="checkbox" checked={@state.tos}
          onChange={@fieldChanged('tos')} />
        {@i18n('i_agree_to')} <a href={@i18n('/terms_of_service_url')} target="_blank">
          {@i18n('/terms_of_service')}
        </a> {@i18n('and')} <a href={@i18n('/privacy_policy_url')} target="_blank">
          {@i18n('/privacy_policy')}
        </a><span className="muted-text"> ({@i18n("required")})</span>
      </label>
      {@getFieldErrorMessage('tos')}

      <label className="newsletter-opt-in">
        <input className="newsletter" type="checkbox" checked={@state.newsletterOptIn}
          onChange={@fieldChanged('newsletterOptIn')} />
        {@i18n('newsletter_opt_in')}
      </label>

      <button
        type="submit"
        className={classNames 'btn btn-info btn-block', loading: @state.submitting}
        disabled={@state.submitting}>
        {@i18n('create_account')}
      </button>

      <OauthLogin />
    </form>

module.exports = RegistrationForm
