{ instanceOf, bool, func, string, oneOfType, oneOf, number } = PropTypes
{ StepsContainer, Step} = require 'components/common/steps'
mediator = require 'mediator'
{ errorHandler }   = require 'lib/app_utils'
PanelMemberSelection = require 'components/panel_voice/panel_member_selection'
QuestionsSelectorForSending = require 'components/panel_voice/questions_selector_for_sending'
SectionSelection = require 'components/panel_voice/section_selection'
Translation = require 'components/mixins/translation'
MessageForm = require 'components/panel_voice/message_form'
PanelVoiceActions = require 'actions/panel_voice_actions'
{ panelVoiceMailAtachmentsLimit } = require 'lib/constants'
{ isAcpFeaturesOn } = require 'stores/utils/etd_template_helper'

ModalSendWithSteps = createReactClass

  displayName: "ModalSendWithSteps"

  mixins: [Translation("voting:voting")]

  propTypes:
    type: oneOf ['start', 'resend', 'test']
    contactEmail: string
    onCancel: func.isRequired
    onApply: func.isRequired
    currentQuestionId: string.isRequired
    members: oneOfType([instanceOf(Immutable.List), instanceOf(Immutable.OrderedMap)]).isRequired
    questions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)]
    ).isRequired
    overarchingQuestions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)]
    ).isRequired
    questionGroups: instanceOf(Immutable.Map).isRequired
    votingStages: instanceOf(Immutable.List).isRequired
    panelVoicePreferences: instanceOf(Immutable.Map).isRequired
    votingMessageAttachments: instanceOf(Immutable.Map)
    votingMessageAttachmentsSize: number
    isSending: bool.isRequired
    overarching: bool.isRequired

  getInitialState: ->
    currentStep: 'etd-sections-selection'
    selectedQuestions: new Immutable.List([@props.currentQuestionId])
    selectedMembers: @getInitialSelectedMembers(@props.members)
    sections: Immutable.Map()
    disableRecommendationPart: true

  getInitialSelectedMembers: (members) ->
    new Immutable.List(@props.votingStages.last()?.get("members") ? [])
    .filter (member) -> members.has member

  getInitialDueDate: ->
    (@props.votingStages.last() ? new Immutable.Map()).get('dueDateTimestamp')

  getInitialSections: ->
    { votingStages, overarching } = @props
    { disableRecommendationPart } = @state

    currentStatus = (votingStages.last() ? new Immutable.Map()).get('status', 'unsent')
    findPartByNameInLastStage = (partName) ->
      (votingStages.last() ? new Immutable.Map()).get('parts', new Immutable.List()).find (part) ->
        part.get('name') is partName
      , null, new Immutable.Map()

    getStageByPartName = (partName) ->
      votingStages.find (stage) ->
        stage.get('parts').find (part) ->
          part.get('name') is partName
      , null, new Immutable.Map()

    getPartByName = (stage, partName) ->
      stage.get('parts', new Immutable.Map()).find (part) ->
        part.get('name') is partName
      , null, new Immutable.Map()

    getSection = (partName) ->
      stage = getStageByPartName(partName)
      status = stage.get('status', 'unsent')
      preselected = getPartByName(stage, partName).get('preselectedJudgement', overarching)

      disabled = switch currentStatus
        when 'unsent' then false
        when 'ongoing' then findPartByNameInLastStage(partName).isEmpty() or status is 'ongoing'

      preselectDisabled = switch currentStatus
        when 'unsent' then false
        when 'ongoing' then findPartByNameInLastStage(partName).isEmpty()

      if status is 'closed'
        disabled = true
        preselectDisabled = true

      if disableRecommendationPart and partName is 'recommendation'
        disabled = true
        preselectDisabled = true
        preselected = false

      new Immutable.Map
        checked: not stage.isEmpty()
        name: partName
        preselected: preselected
        disabled: disabled
        preselectDisabled: preselectDisabled
        status: status

    new Immutable.Map
      assessment: getSection("assessment")
      recommendation: getSection("recommendation")
      conclusion: getSection("conclusion")

  componentDidMount: ->
    { questions, currentQuestionId } = @props
    mediator.services.storePersistenceAdapter.fetch mediator.project.id,
      questions.getIn([currentQuestionId, 'recommendationIds']).first()
    .then ({ templateDef }) =>
      @setState disableRecommendationPart: templateDef.id.indexOf('overarching') isnt -1 and
        templateDef.id isnt 'overarchingTx-v2'
    .catch errorHandler
    .finally =>
      @setState sections: @getInitialSections()

  updateCurrentStep: (step) ->
    @setState currentStep: step

  onApply: ->
    @props.onApply @props.type, @_getDataForSending()

  onCancel: ->
    @updatePanelVoicePreferences()
    @props.onCancel()

  goToEdtSectionSelection: ->
    @setState currentStep: 'etd-sections-selection'

  goToQuestionsSelection: ->
    @updatePanelVoicePreferences()
    @setState currentStep: 'questions-selection'

  goToMessageComposing: ->
    @setState currentStep: 'message-composing'

  goToMembersSelection: ->
    @updatePanelVoicePreferences()
    data = @getMessageFormContent()
    validation = @validateData data
    if validation.success
      @setState currentStep: "members-selection", validationFault: []
    else
      @setState validationFault: validation.failedFields

  updateSelectedQuestions: (selectedQuestions) ->
    @setState {selectedQuestions}

  updateSelectedMembers: (selectedMembers) ->
    @setState {selectedMembers}

  updateSections: (sections) ->
    @setState {sections}

  getParts: (parts) ->
    parts.reduce (acc, part, key) ->
      if part.get('checked') and part.get('status') isnt 'closed'
        acc.push { preselectedJudgement: part.get('preselected'), name: key }
      acc
    , []

  _getQuestionEtdsMapping: (questionIds) ->
    { overarchingQuestions, questions } = @props
    questionIds.reduce (acc, questionId) =>
      question = if overarchingQuestions.has questionId
        overarchingQuestions.get questionId
      else
        questions.find((question) -> question.get("_id") is questionId)
      etdIds = question.get("recommendationIds", Immutable.List())
      acc.set questionId, etdIds
    , new Immutable.Map()

  _getDataForSending: ->
    {
      emailBody
      contactEmail
      keepMessage
      dueDateTimestamp
    } = @state.messageFormContent

    messageAttachments = @props.votingMessageAttachments.map (att) -> att.get('fileData')

    data =
      questionEtds: @_getQuestionEtdsMapping(@state.selectedQuestions).toJS()
      memberIds: @state.selectedMembers.toJS()
      parts: @getParts(@state.sections)
    _.extend data, { emailBody, contactEmail, keepMessage, dueDateTimestamp, messageAttachments }
    data

  getMessageFormContent: ->
    return unless @votingMessageForm
    @votingMessageForm.getFormData()

  validateData: (data) ->
    @votingMessageForm.validateData data

  updatePanelVoicePreferences: ->
    return unless @votingMessageForm

    data = @getMessageFormContent()
    @setState messageFormContent: data

    if @props.type is 'test'
      data.testEmailBody = data.emailBody
      data = _.omit data, 'emailBody'

    PanelVoiceActions.updatePanelVoicePreferences data

  isNextStepEnabled: ->
    switch @state.currentStep
      when 'etd-sections-selection'
        @state.sections.some (s) ->
          s.get('checked') and (not s.get('disabled') or not s.get('preselectDisabled') or
          s.get('status') is 'ongoing')
      when 'members-selection'
        not @state.selectedMembers.isEmpty()
      when 'message-composing'
        @props.votingMessageAttachmentsSize < panelVoiceMailAtachmentsLimit
      else
        true

  _getMessage: (type) ->
    key = if type is 'test' then 'testEmailBody' else 'emailBody'
    @props.panelVoicePreferences.get(key) ? @_getDefaultMessage type

  _getDefaultMessage: ->
    projectName = mediator.project.get 'name'
    messageTpl = 'voting:voting.messages.default_vote_message_text'
    $.t messageTpl, { projectName }

  _refVotingMessageForm: (e) -> @votingMessageForm = e

  filterQuestionsSelectedForSending: ->
    @props.questions.filter (question) => @state.selectedQuestions.contains(question.get('_id'))

  render: ->
    {
      currentQuestionId
      isSending
      members
      onCancel
      overarching
      panelVoicePreferences
      questionGroups
      questions
      type
    } = @props

    <div className="voting-send-with-steps">
      <StepsContainer
        allowToGoBack={true}
        applying={isSending}
        applyLabel={@i18n "/actions.apply"}
        cancelLabel={@i18n "/actions.cancel"}
        currentStep={@state.currentStep}
        isNextStepEnabled={@isNextStepEnabled()}
        onCancel={onCancel}
        updateCurrentStep={@updateCurrentStep}
      >
        <Step
          nextLabel={@i18n "/actions.next"}
          onBack={onCancel}
          onNext={@goToQuestionsSelection}
          stepKey="etd-sections-selection"
          stepLabel={@i18n 'modals.etd_selection'}
        >
          <div className="etd-sections-selection">
            <SectionSelection
              overarching={overarching}
              sections={@state.sections}
              updateSections={@updateSections}
            />
          </div>
        </Step>
        <Step
          backLabel={@i18n "/actions.back"}
          nextLabel={@i18n "/actions.next"}
          onBack={@goToEdtSectionSelection}
          onNext={@goToMessageComposing}
          stepKey="questions-selection"
          stepLabel={@i18n 'modals.questions_selection'}
        >
          <div className="questions-selection">
            <QuestionsSelectorForSending
              currentQuestionId={currentQuestionId}
              questionGroups={questionGroups}
              questions={questions}
              selectedQuestions={@state.selectedQuestions}
              updateSelected={@updateSelectedQuestions}
            />
          </div>
        </Step>
        <Step
          backLabel={@i18n "/actions.back"}
          nextLabel={@i18n "/actions.next"}
          onBack={@goToQuestionsSelection}
          onNext={@goToMembersSelection}
          stepKey="message-composing"
          stepLabel={@i18n 'modals.message_composing'}
        >
          <MessageForm
            contactEmail={panelVoicePreferences.get('contactEmail') ? contactEmail}
            dueDateTimestamp={@getInitialDueDate()}
            keepMessage={panelVoicePreferences.get('keepMessage')}
            message={@_getMessage(type)}
            ref={@_refVotingMessageForm}
            type={type}
            validationFault={@state.validationFault}
          />
        </Step>
        <Step
          backLabel={@i18n "/actions.back"}
          onBack={@goToMessageComposing}
          onNext={@onApply}
          stepKey="members-selection"
          stepLabel={@i18n 'modals.members_selection'}
        >
          <div className="step2">
            <PanelMemberSelection
              members={members}
              questions={@filterQuestionsSelectedForSending(@state.selectedQuestions)}
              selectedMembers={@state.selectedMembers}
              updateMembersList={@updateSelectedMembers}
            />
          </div>
        </Step>
      </StepsContainer>
    </div>

module.exports = ModalSendWithSteps
