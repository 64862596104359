var DBEPPublicationActions, DBEPScreen, DBEPView, DisseminationToolbar, MDGPublicationActions, MDGPublicationScreen, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

DBEPPublicationActions = require('actions/dbep_publication_actions');

DBEPScreen = require('components/dissemination/dbep_screen');

DisseminationToolbar = require('components/dissemination/dissemination_toolbar');

MDGPublicationActions = require('actions/mdg_publication_actions');

MDGPublicationScreen = require('components/dissemination/mdg_publication_screen/mdg_publication_screen');

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = DBEPView = (function(_super) {
  __extends(DBEPView, _super);

  function DBEPView() {
    return DBEPView.__super__.constructor.apply(this, arguments);
  }

  DBEPView.prototype.container = '#page-container';

  DBEPView.prototype.className = 'dbep-view';

  DBEPView.prototype.autoRender = true;

  DBEPView.prototype._stopInputsPropagation = false;

  DBEPView.prototype.initialize = function() {
    DBEPView.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    this.mode = mediator.services.switches.isServerSwitchOn('mdgFeatures') ? 'mdg' : 'regular';
    return this.subview('toolbar', new ReactToolbarView({
      component: DisseminationToolbar,
      props: {
        submodule: this.mode === 'mdg' ? 'mdg_publication' : this.options.submodule
      }
    }));
  };

  DBEPView.prototype.afterRender = function() {
    var Actions, Component;
    DBEPView.__super__.afterRender.apply(this, arguments);
    Actions = this.mode === 'mdg' ? MDGPublicationActions : DBEPPublicationActions;
    Component = this.mode === 'mdg' ? MDGPublicationScreen : DBEPScreen;
    return this.renderComponent(Component, Actions, null, {
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  DBEPView.prototype.dispose = function() {
    var Store;
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    Store = this.mode === 'mdg' ? 'MDGPublicationStore' : 'DBEPPublicationStore';
    alt.recycle(Store);
    return DBEPView.__super__.dispose.apply(this, arguments);
  };

  return DBEPView;

})(View);
