module.exports = {
  getInitialState: function() {
    return {};
  },
  isModalVisible: function(modalName) {
    var _ref;
    return (_ref = this.state["" + modalName + "Visible"]) != null ? _ref : false;
  },
  showModal: function(modalName, optionalUpdatedState) {
    if (optionalUpdatedState == null) {
      optionalUpdatedState = {};
    }
    return this.setState(R.assoc("" + modalName + "Visible", true, optionalUpdatedState));
  },
  hideModal: function(modalName, optionalUpdatedState) {
    if (optionalUpdatedState == null) {
      optionalUpdatedState = {};
    }
    return this.setState(R.assoc("" + modalName + "Visible", false, optionalUpdatedState));
  }
};
