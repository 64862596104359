var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "  <label for=\"units\">"
    + escapeExpression(((helpers.i18nCapitalize || (depth0 && depth0.i18nCapitalize) || helperMissing).call(depth0, "unit", {"name":"i18nCapitalize","hash":{},"data":data})))
    + "</label>\n  <input class=\"number half\" type=\"text\" name=\"units\" value=\""
    + escapeExpression(((helper = (helper = helpers.units || (depth0 != null ? depth0.units : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"units","hash":{},"data":data}) : helper)))
    + "\">\n  <label class=\"middle\">&nbsp;</label>\n  <div class=\"inline\">\n    <div class=\"countable-uncountable-select\">\n      "
    + escapeExpression(((helpers.unitSelectableAttr || (depth0 && depth0.unitSelectableAttr) || helperMissing).call(depth0, depth0, {"name":"unitSelectableAttr","hash":{},"data":data})))
    + "\n    </div>\n  </div>\n  <div class=\"sup\"></div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}