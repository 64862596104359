Popover = require 'components/common/popover'
IconButton = require 'components/common/icon_button'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
ProjectReplicationSwitch = require 'components/projects/project_replication_switch'

ProjectActionsPopup = createReactClass
  displayName: 'ProjectActionsPopup'

  propTypes:
    actions: PropTypes.arrayOf(PropTypes.string)
    displayPopover: PropTypes.bool.isRequired
    lastSeq: PropTypes.number
    onActionClick: PropTypes.func.isRequired
    onTogglePopover: PropTypes.func.isRequired
    projectId: PropTypes.string.isRequired
    projectStatus: PropTypes.string.isRequired
    updateSeq: PropTypes.number

  mixins: [ Translation() ]

  getActionsLists: ->
    return unless @props.actions?
    # the actions box should contain actions (other than "Delete" one) followed with separator and
    # "Delete" actions at the very end
    R.compose(
      R.intersperse 'separator'
      R.reject R.isEmpty # do not need empty actions list (e.g. when delete action is not allowed)
      R.partition R.compose(R.not, R.equals('delete'))
    ) @props.actions

  handleAction: (actionName) -> =>
    @props.onActionClick actionName

  render: ->
    actionsLists = @getActionsLists()
    { displayPopover, onTogglePopover, projectStatus, projectId, lastSeq, updateSeq } = @props

    <Popover
      onRequestClose={onTogglePopover}
      visible={displayPopover}
      positionParams={alignment: 'center'}
    >
      <IconButton iconName='gear' onClick={onTogglePopover} />
      <div className='project-actions-box'>
        {if actionsLists?
          R.chain (actionsListOrSeparator) =>
            if actionsListOrSeparator is 'separator'
              <div className='actions-separator' key='separator' />
            else
              actionsListOrSeparator.map (action) =>
                <div key={action} className='project-action'>
                  <IconButton
                    iconName={_.str.dasherize action}
                    labelPosition='right'
                    label= {@i18n "actions.#{_.str.underscored action}"}
                    onClick={@handleAction action}
                  />
                </div>
          , actionsLists
        else
          <Spinner />
        }
        <div className='actions-separator' />
        <span className="actions-group-label">{@i18n 'projects:synchronization_status'}</span>
        <ProjectReplicationSwitch
          projectId={projectId}
          lastSeq={lastSeq}
          projectStatus={projectStatus}
          updateSeq={updateSeq}
        />
      </div>

    </Popover>

module.exports = ProjectActionsPopup
