var DbDoc, MDG_DIAGNOSTIC_SCORE_CRITERIA, MDG_DIAGNOSTIC_TYPES, MDG_QUESTION_PREFIX, MDG_TREATMENT_SCORE_CRITERIA, MdgScore, MdgStudy, cloneDeep, mdgQuestionDocShape, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

cloneDeep = require('lodash').cloneDeep;

DbDoc = require('lib/db_docs/db_doc');

_ref = require('lib/mdg_helper'), MDG_DIAGNOSTIC_SCORE_CRITERIA = _ref.MDG_DIAGNOSTIC_SCORE_CRITERIA, MDG_DIAGNOSTIC_TYPES = _ref.MDG_DIAGNOSTIC_TYPES, MDG_TREATMENT_SCORE_CRITERIA = _ref.MDG_TREATMENT_SCORE_CRITERIA;

mdgQuestionDocShape = require('lib/db_docs/doc_shapes/mdg_question_doc_shape');

MDG_QUESTION_PREFIX = require('lib/doc_prefixes').mdgQuestion;

MdgScore = require('lib/db_docs/field_types/mdg_score_type');

MdgStudy = require('lib/db_docs/field_types/mdg_study_type');

module.exports = DbDoc(mdgQuestionDocShape, {
  dbSetup: {
    idPrefix: MDG_QUESTION_PREFIX
  },
  methods: {
    addStudy: function(studyId, defaults) {
      var isDiagnosticOrImaging, score, study;
      if (defaults == null) {
        defaults = {};
      }
      isDiagnosticOrImaging = this.isDiagnosticOrImaging();
      score = MdgScore.defaults({
        criteria: isDiagnosticOrImaging ? MDG_DIAGNOSTIC_SCORE_CRITERIA : MDG_TREATMENT_SCORE_CRITERIA
      });
      study = MdgStudy.defaults(_.extend({
        id: studyId,
        score: score
      }, _.omit(defaults, 'id')));
      return this.updateAt('studies')(function(studies) {
        studies.push(study);
        return studies;
      });
    },
    isDiagnosticOrImaging: function() {
      var _ref1;
      return _ref1 = this.getAt('type'), __indexOf.call(MDG_DIAGNOSTIC_TYPES, _ref1) >= 0;
    },
    updateStudy: function(studyId, field, value) {
      return this.updateAt('studies')(function(studies) {
        var study;
        study = _.find(studies, function(_arg) {
          var id;
          id = _arg.id;
          return id === studyId;
        });
        if (study != null) {
          study[field] = value;
        }
        return studies;
      });
    },
    updateStudyScoreAndComment: function(studyId, score, comments) {
      return this.updateAt('studies')(function(studies) {
        var study;
        study = _.find(studies, function(_arg) {
          var id;
          id = _arg.id;
          return id === studyId;
        });
        if (study != null) {
          study['score'] = score;
          study['comments'] = comments;
        }
        return studies;
      });
    },
    duplicateStudy: function(studyId, newStudyId) {
      return this.updateAt('studies')(function(studies) {
        var newStudy, study;
        study = _.find(studies, function(_arg) {
          var id;
          id = _arg.id;
          return id === studyId;
        });
        if (!study) {
          return studies;
        }
        newStudy = _.extend({}, cloneDeep(study), {
          id: newStudyId
        });
        studies.push(newStudy);
        return studies;
      });
    },
    deleteStudy: function(studyId) {
      return this.updateAt('studies')(function(studies) {
        return _.reject(studies, function(_arg) {
          var id;
          id = _arg.id;
          return id === studyId;
        });
      });
    },
    setRecommendationsIds: function(recIds) {
      return this.setAt('recommendationIds')(recIds);
    },
    connectParentStudy: function(studyId, parentId) {
      return this.updateAt('studies')(function(studies) {
        return _.map(studies, function(study) {
          if (study.id === studyId) {
            return _.extend({}, study, {
              parentStudy: parentId
            });
          } else {
            return study;
          }
        });
      });
    },
    disconnectParentStudy: function(studyId) {
      return this.updateAt('studies')(function(studies) {
        return _.map(studies, function(study) {
          if (study.id === studyId) {
            return _.omit(study, 'parentStudy');
          } else {
            return study;
          }
        });
      });
    }
  }
});
