var arrayOf, boolean, docShape, docTypes, documentId, initial, mapOf, number, optional, shape, string, taskDocShape, _ref;

arrayOf = require('lib/db_docs/field_types/array_of');

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

docTypes = require('lib/db_docs/doc_types');

initial = require('lib/db_docs/field_types/type_decorators').initial;

mapOf = require('lib/db_docs/field_types/map_of');

shape = require('lib/db_docs/field_types/shape');

_ref = require('lib/db_docs/field_types/built_in_types'), string = _ref.string, number = _ref.number, boolean = _ref.boolean;

optional = shape.typeDecorators.optional;

taskDocShape = docShape({
  docType: initial(docTypes.TASK)(string),
  start: optional(number),
  end: optional(number),
  dueDate: optional(number),
  allday: initial(true)(boolean),
  done: initial(false)(boolean),
  assignedTo: optional(string),
  reviewedBy: optional(string),
  title: initial('')(string),
  description: initial('')(string),
  orderNumber: optional(number),
  condition: optional(string)
});

module.exports = taskDocShape;
