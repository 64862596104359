alt = require 'alt'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
CadthGuidelinesActions = require 'actions/cadth_guidelines_actions'
CadthGuidelinesStore = require 'stores/cadth_guidelines_store'
ConnectStore = require 'components/enhancers/connect_store'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
Modal = require 'components/common/modal'
Spinner = require 'components/common/spinner'
StringField = require 'components/covid_extraction_form/string_field'
{ useCoffeeCallback, useCoffeeEffect, useCoffeeMemo, useI18n } = require 'lib/react_utils'
{ useState } = React

getTitle = (guideline) ->
  shortTitle = guideline.get 'shortTitle'
  title = guideline.get 'title'
  if shortTitle and shortTitle isnt '' then shortTitle else title

CadthGuidelinesFieldModal = ({ guidelines, isOpen, onClose, onSave }) ->
  i18n = useI18n('key_messages:guidelines.field')
  [formData, setFormData] = useState({ guidelineId: null, quality: '' })

  onChange = useCoffeeCallback [formData, setFormData], (field, value) ->
    obj = {}
    obj[field] = value
    setFormData _.extend {}, formData, obj

  isSubmitEnabled = useCoffeeMemo [formData], ->
    formData.guidelineId? and formData.quality isnt ''

  onApply = useCoffeeCallback [formData, onSave, setFormData], ->
    setFormData { guidelineId: null, quality: '' }
    onSave formData

  onCancel = useCoffeeCallback [onClose, setFormData], ->
    setFormData { guidelineId: null, quality: '' }
    onClose()

  form = useCoffeeMemo [guidelines], ->
    guidelineOptions = guidelines
      .valueSeq()
      .map (guideline) ->
        text: getTitle guideline
        value: guideline.get '_id'
      .toJS()

    main: [
      fieldKey: 'guidelineId'
      options: guidelineOptions
      showClear: false
      type: 'select'
    ,
      fieldKey: 'quality'
      showClear: false
      type: 'text'
    ]

  <Modal
    className="cadth-guidelines-field-modal"
    isOpen={isOpen}
    modalSize="medium"
    title={i18n 'add_guideline'}
  >
    <ExtractionForm
      data={formData}
      form={form}
      i18n={i18n}
      inModal
      onChange={onChange}
    />
    <ApplyCancelButtons
      applyLabel={i18n 'add_guideline'}
      isSubmitEnabled={isSubmitEnabled}
      onApply={onApply}
      onCancel={onCancel}
    />
  </Modal>

CadthGuidelinesFieldModal.propTypes =
  guidelines: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
  isOpen: PropTypes.bool.isRequired
  onClose: PropTypes.func.isRequired
  onSave: PropTypes.func.isRequired

storeConnector =
  CadthGuidelinesStore: (Store) ->
    guidelines: Store.getGuidelines()
    isFetching: Store.isFetching()

CadthGuidelinesField = ({ isFetching, guidelines, onChange, value }) ->
  i18n = useI18n('key_messages:guidelines.field')

  useCoffeeEffect [], ->
    CadthGuidelinesActions.fetch dbId: mediator.project.id
    -> alt.recycle 'CadthGuidelinesStore'

  [isModalOpen, setIsModalOpen] = useState(false)
  onOpenAddGuidelineModal = useCoffeeCallback [setIsModalOpen], -> setIsModalOpen true
  onCloseAddGuidelineModal = useCoffeeCallback [setIsModalOpen], -> setIsModalOpen false

  onAddGuideline = useCoffeeCallback [onChange, setIsModalOpen, value], (data) ->
    setIsModalOpen false
    onChange _.union value, [data]

  onChangeGuidelineQuality = useCoffeeCallback [onChange, value],
    (guidelineIdToChange) -> (quality) ->
      onChange _.map value, (guidelineEntry) ->
        { guidelineId } = guidelineEntry
        return guidelineEntry unless guidelineId is guidelineIdToChange
        { guidelineId, quality }

  onRemoveGuideline = useCoffeeCallback [onChange, value], (guidelineIdToRemove) -> ->
    onChange _.reject value, ({ guidelineId }) -> guidelineId is guidelineIdToRemove

  guidelinesToShowInModal = useCoffeeMemo [guidelines, value], ->
    guidelineIdsInValue = _.pluck value, 'guidelineId'
    guidelines
      .filterNot (guideline) -> guideline.get('_id') in guidelineIdsInValue
      .sortBy (guideline) -> getTitle guideline

  <div className={classNames 'cadth-guidelines-field', loading: isFetching}>
    {if isFetching
      <Spinner scale={0.75} />
    else
      <React.Fragment>
        <div className="cadth-guidelines-field__guidelines-list">
          {_.map value, ({ guidelineId, quality }) ->
            guideline = guidelines.get guidelineId
            return null unless guideline

            <div className="guidelines-list__item" key={guidelineId}>
              <div className="item__inner">
                <div className="inner__title">
                  {getTitle guideline}
                </div>
                <div className="inner__quality">
                  <div className="quality__label">{i18n 'quality'}</div>
                  <StringField
                    className="quality__input"
                    onChange={onChangeGuidelineQuality guidelineId}
                    value={quality}
                  />
                </div>
              </div>
              <div className="item__remove">
                <IconButton iconName="remove" onClick={onRemoveGuideline guidelineId} />
              </div>
            </div>
          }
        </div>
        <div className="cadth-guidelines-field__add-guideline">
          <IconButton
            disabled={guidelinesToShowInModal.isEmpty()}
            iconName="add"
            label={i18n 'add_guideline'}
            labelPosition="right"
            onClick={onOpenAddGuidelineModal}
          />
        </div>
        <CadthGuidelinesFieldModal
          guidelines={guidelinesToShowInModal}
          isOpen={isModalOpen}
          onClose={onCloseAddGuidelineModal}
          onSave={onAddGuideline}
        />
      </React.Fragment>
    }
  </div>

CadthGuidelinesField.propTypes =
  guidelines: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
  isFetching: PropTypes.bool.isRequired
  onChange: PropTypes.func.isRequired
  value: PropTypes.arrayOf(PropTypes.shape(
    guidelineId: PropTypes.string.isRequired
    quality: PropTypes.string
  ))

CadthGuidelinesField.defaultProps =
  value: []

module.exports = ConnectStore CadthGuidelinesField, CadthGuidelinesStore, storeConnector
