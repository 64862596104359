var OverReviewActions, alt, generateGUID, immIso, lensPath, mapped, mediator, overReviewDocPrefix, _ref;

alt = require('alt');

mediator = require('mediator');

overReviewDocPrefix = require('lib/doc_prefixes').overReview;

_ref = require('lib/lens_utils'), immIso = _ref.immIso, lensPath = _ref.lensPath, mapped = _ref.mapped;

generateGUID = require('base/lib/utils').generateGUID;

OverReviewActions = (function() {
  function OverReviewActions() {
    this.generateActions('fetchOverReviewSuccess', 'fetchOverReviewError', 'createReviewedOutcomeDraft', 'startSourceEdit', 'cancelSourceEdit', 'changeTab');
  }

  OverReviewActions.prototype.dbChange = function(data) {
    return this.dispatch(data);
  };

  OverReviewActions.prototype.fetch = function(_arg) {
    var questionId;
    questionId = _arg.docId;
    this.dispatch(questionId);
    return mediator.services.overReviewService.fetchOverReviewDoc(questionId).then(this.actions.fetchOverReviewSuccess)["catch"](this.actions.fetchOverReviewError);
  };

  OverReviewActions.prototype.createReviewedOutcome = function(_arg) {
    var name, questionId;
    questionId = _arg.questionId, name = _arg.name;
    this.dispatch({
      questionId: questionId,
      name: name
    });
    return mediator.services.overReviewService.createReviewedOutcome(questionId, name);
  };

  OverReviewActions.prototype.deleteReviewedOutcome = function(_arg) {
    var questionId, reviewedOutcomeId;
    questionId = _arg.questionId, reviewedOutcomeId = _arg.reviewedOutcomeId;
    this.dispatch({
      questionId: questionId,
      reviewedOutcomeId: reviewedOutcomeId
    });
    return mediator.services.overReviewService.deleteReviewedOutcome(questionId, reviewedOutcomeId);
  };

  OverReviewActions.prototype.updateReviewedOutcome = function(_arg) {
    var outcomeData, questionId, reviewedOutcomeId;
    questionId = _arg.questionId, reviewedOutcomeId = _arg.reviewedOutcomeId, outcomeData = _arg.outcomeData;
    this.dispatch({
      questionId: questionId,
      reviewedOutcomeId: reviewedOutcomeId,
      outcomeData: outcomeData
    });
    return mediator.services.overReviewService.updateReviewedOutcome(questionId, reviewedOutcomeId, outcomeData);
  };

  OverReviewActions.prototype.createSource = function(_arg) {
    var questionId, sourceData, studies;
    questionId = _arg.questionId, sourceData = _arg.sourceData, studies = _arg.studies;
    sourceData = R.mergeRight(sourceData, {
      _id: generateGUID()
    });
    this.dispatch({
      questionId: questionId,
      sourceData: sourceData,
      studies: studies
    });
    return mediator.services.overReviewService.createSource(questionId, sourceData, studies);
  };

  OverReviewActions.prototype.deleteSource = function(_arg) {
    var questionId, sourceId;
    questionId = _arg.questionId, sourceId = _arg.sourceId;
    this.dispatch({
      questionId: questionId,
      sourceId: sourceId
    });
    return mediator.services.overReviewService.deleteSource(questionId, sourceId);
  };

  OverReviewActions.prototype.updateSource = function(_arg) {
    var questionId, sourceData, sourceId, studies;
    questionId = _arg.questionId, sourceId = _arg.sourceId, sourceData = _arg.sourceData, studies = _arg.studies;
    this.dispatch({
      questionId: questionId,
      sourceId: sourceId,
      sourceData: sourceData,
      studies: studies
    });
    return mediator.services.overReviewService.updateSource(questionId, sourceId, sourceData, studies);
  };

  OverReviewActions.prototype.saveImportedSources = function(_arg) {
    var questionId, sources, studies;
    questionId = _arg.questionId, sources = _arg.sources, studies = _arg.studies;
    this.dispatch({
      questionId: questionId,
      sources: sources,
      studies: studies
    });
    return mediator.services.overReviewService.createSource(questionId, sources, studies);
  };

  OverReviewActions.prototype.startOverReview = function(questionId) {
    return mediator.services.overReviewService.startOverReview(questionId);
  };

  OverReviewActions.prototype.mapSourceOutcome = function(_arg) {
    var questionId, reviewedOutcomeId, sourceId, sourceOutcomeId;
    questionId = _arg.questionId, reviewedOutcomeId = _arg.reviewedOutcomeId, sourceId = _arg.sourceId, sourceOutcomeId = _arg.sourceOutcomeId;
    this.dispatch({
      questionId: questionId,
      reviewedOutcomeId: reviewedOutcomeId,
      sourceId: sourceId,
      sourceOutcomeId: sourceOutcomeId
    });
    return mediator.services.overReviewService.mapSourceOutcome(questionId, reviewedOutcomeId, sourceId, sourceOutcomeId);
  };

  OverReviewActions.prototype.rateSourceReviewQuality = function(_arg) {
    var questionId, rating, sourceId;
    questionId = _arg.questionId, sourceId = _arg.sourceId, rating = _arg.rating;
    this.dispatch({
      questionId: questionId,
      sourceId: sourceId,
      rating: rating
    });
    return mediator.services.overReviewService.updateSourceReviewQuality(questionId, sourceId, rating);
  };

  OverReviewActions.prototype.updateSourceOutcome = function(_arg) {
    var outcomeData, outcomeId, questionId, sourceId;
    questionId = _arg.questionId, sourceId = _arg.sourceId, outcomeId = _arg.outcomeId, outcomeData = _arg.outcomeData;
    this.dispatch({
      questionId: questionId,
      sourceId: sourceId,
      outcomeId: outcomeId,
      outcomeData: outcomeData
    });
    return mediator.services.overReviewService.updateSourceOutcome(questionId, sourceId, outcomeId, outcomeData);
  };

  OverReviewActions.prototype.updateStudiesMatrix = function(payload) {
    var questionId, sourceId, studyId, studyStatus;
    questionId = payload.questionId, sourceId = payload.sourceId, studyId = payload.studyId, studyStatus = payload.studyStatus;
    this.dispatch(payload);
    return mediator.services.overReviewService.updateStudiesMatrix(questionId, sourceId, studyId, studyStatus);
  };

  OverReviewActions.prototype.mergeStudies = function(payload) {
    var masterStudyText, mergeStudyIds, questionId;
    questionId = payload.questionId, mergeStudyIds = payload.mergeStudyIds, masterStudyText = payload.masterStudyText;
    this.dispatch(payload);
    return mediator.services.overReviewService.mergeStudies(questionId, mergeStudyIds, masterStudyText);
  };

  OverReviewActions.prototype.updateSourceComment = function(payload) {
    var comment, questionId, sourceId;
    questionId = payload.questionId, sourceId = payload.sourceId, comment = payload.comment;
    this.dispatch(payload);
    return mediator.services.overReviewService.updateSourceComment(questionId, sourceId, comment);
  };

  return OverReviewActions;

})();

module.exports = alt.createActions(OverReviewActions);
