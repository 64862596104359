PanelVoiceEtdActions = require 'actions/panel_voice_etd_actions'
Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
{ ETD_PARTS_LETTERS_MAP } = require 'lib/voting_helper'
{ instanceOf, string } = PropTypes

getDefaultStage = (stageOptions) ->
  lastStageValue = _.last(stageOptions).value
  # try to pick last non-test stage by default
  if lastStageValue is 'test' and stageOptions.length > 1
    stageOptions[stageOptions.length - 2].value
  # if that fails or all options are non-test then simply pick the last one
  else
    lastStageValue

getDefaultRound = (roundOptions) ->
  # since 'draft' round option is always prepended to the round options list, it is safe to simply
  # pick the last round option
  _.last(roundOptions)?.value

HistoryFilters = createReactClass
  displayName: 'HistoryFilters'

  propTypes:
    votingStages: instanceOf(Immutable.List).isRequired
    activeStage: string
    activeRound: string

  mixins: [
    Translation('es:recommendations.table')
  ]

  componentDidMount: ->
    { activeStage, activeRound } = @props
    # set default activeStage and activeRound if there was none provided via props
    unless activeStage?
      stageOptions = @getStageOptions()
      activeStage = getDefaultStage stageOptions
      PanelVoiceEtdActions.updateActiveStage activeStage
    @_setDefaultRound(activeStage) unless activeRound?

  componentDidUpdate: ->
    # when stage gets changes the activeRound option is reset, so need to recalc it for newly
    # selected stage
    @_setDefaultRound() unless @props.activeRound?

  _etdPartsToLabel: (parts) ->
    partsString = parts.map (p) ->
      ETD_PARTS_LETTERS_MAP[p.get('name')]
    .join '+'

    "#{@i18n 'part'} #{partsString}"

  _setDefaultRound: (activeStage = @props.activeStage) ->
    roundOptions = @getRoundOptions activeStage
    activeRound = getDefaultRound roundOptions
    PanelVoiceEtdActions.updateActiveRound activeRound

  getStageOptions: ->
    { votingStages } = @props

    votedStages = votingStages
      .filterNot (stage) -> stage.get('status') is 'test'
      .map (stage, idx) =>
        value: idx.toString()
        text: @_etdPartsToLabel stage.get('rounds').last().get('parts')
      .toJS()
    # append 'test' option if there is any test round present
    hasTestRounds = votingStages.some (stage) ->
      stage.get('rounds').some (round) ->
        round.get('isTest')

    if hasTestRounds
      votedStages.concat value: 'test', text: @i18n 'test'
    else
      votedStages

  getRoundOptions: (activeStage = @props.activeStage) ->
    { votingStages } = @props

    if activeStage is 'test'
      # prepare list of all test rounds. Text value is same as for stage options, that is string
      # containing letters of parts sent in that test round
      votingStages.reduce (options, stage) =>
        stageRounds = stage.get('rounds')
        testRounds = stageRounds.filter (round) -> round.get('isTest')
        # last test round should be shown in Workspace tab unless that part is sent for real voting
        testRounds = testRounds.butLast() if testRounds.last().equals stageRounds.last()
        return options if testRounds.isEmpty()

        testRounds.reduce (optionsAcc, roundData, timestamp) =>
          optionsAcc.concat
            value: timestamp.toString()
            text: @_etdPartsToLabel roundData.get 'parts'
        , options
      , []
    else
      # prepare list of round options based on active stage
      activeStageData = votingStages.get parseInt(activeStage)
      votingRoundsOptions = activeStageData.get('rounds')
        .butLast() # skip last round as its data is presented in Workspace tab
        .filterNot (round) -> round.get('isTest')
        .reduce (options, round, timestamp) =>
          options.concat
            value: timestamp.toString()
            text: "#{@i18n 'round'} #{options.length + 1}"
        , []
      # prepend 'draft' option which is always present in non-test stages
      [ { value: 'draft', text: @i18n 'initial_draft' } ].concat votingRoundsOptions

  render: ->
    { activeStage, activeRound } = @props
    return null unless activeStage? and activeRound?

    <div className='panel-voice-etd__history-filters'>
      <span>{@i18n 'show'}:</span>
      <Select
        options={@getStageOptions()}
        selected={activeStage}
        onChange={PanelVoiceEtdActions.updateActiveStage}
      />
      <Select
        options={@getRoundOptions()}
        selected={activeRound}
        onChange={PanelVoiceEtdActions.updateActiveRound}
      />
    </div>

module.exports = HistoryFilters
