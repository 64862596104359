ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
EtdsStore = require 'stores/etds_store'
ItemWithCheckbox = require 'components/common/item_with_checkbox'
mediator = require 'mediator'
QuestionsSelector = require 'components/common/questions_selector'
{ RECOMMENDATION_SECTION_APPENDICES_ORDER } = require 'lib/document_sections_v2_helper'
Translation = require 'components/mixins/translation'

INITIAL_APPENDICES_MAP = Immutable.fromJS
  additionalInformation: false
  evidenceTable: false
  etd: false

storeConnector =
  EtdsStore: (Store) ->
    etds: Store.getEtds()

InsertRecommendation = createReactClass
  displayName: 'InsertRecommendation'

  mixins: [
    Translation('docsec:management')
  ]

  propTypes:
    alreadyUsedRecommendationsQuestionsIds: PropTypes.instanceOf(Immutable.Set).isRequired
    etds: PropTypes.instanceOf(Immutable.Map).isRequired
    onCancel: PropTypes.func.isRequired
    onInsert: PropTypes.func.isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    questions: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
    withRecommendationText: PropTypes.bool
    displayAppendices: PropTypes.bool

  getDefaultProps: ->
    withRecommendationText: false
    displayAppendices: true

  _areStandardRecsUsed: ->
    not mediator.services.switches.isOn 'mdgTables'

  getInitialState: ->
    selectedAppendices: INITIAL_APPENDICES_MAP
    selectedQuestions: Immutable.List()

  handleUpdateSelectedQuestions: (nextSelectedQuestions) ->
    @setState
      selectedQuestions: nextSelectedQuestions

  handleInsert: ->
    appendicesToPass = @state.selectedAppendices.toJS() if @_areStandardRecsUsed()
    @props.onInsert(@state.selectedQuestions.toJS(), appendicesToPass)

  handleAppendixChange: (key) -> =>
    @setState selectedAppendices: @state.selectedAppendices.update key, (selected) -> not selected

  getQuestionsWithoutRecommendations: ->
    { questions } = @props
    questions.filter (question) -> question.get('recommendationIds', Immutable.List()).isEmpty()
    .keySeq()

  render: ->
    {
      alreadyUsedRecommendationsQuestionsIds
      etds
      onCancel
      displayAppendices
      questionGroups
      questions
      withRecommendationText
    } = @props
    { selectedAppendices, selectedQuestions } = @state

    blockedQuestions = if @_areStandardRecsUsed()
      alreadyUsedRecommendationsQuestionsIds
      .concat @getQuestionsWithoutRecommendations()
      .toSet()
    else
      @getQuestionsWithoutRecommendations().toSet()

    <div className="insert-recommendation-modal">
      <QuestionsSelector
        updateSelected={@handleUpdateSelectedQuestions}
        selectedQuestions={selectedQuestions}
        questionGroups={questionGroups}
        questions={questions}
        etds={etds}
        blockedQuestions={blockedQuestions.toList()}
        blockedQuestionTooltip={@i18n 'blocked_question_tooltip'}
        withSelectAll
        withSearch
        withNumbers
        withRecommendationText={withRecommendationText}
      />
      {@_areStandardRecsUsed() and displayAppendices and <div className="mt-10">
        <p className="choose-appendices-title">{@i18n 'section_appendices.title'}</p>
        {_.map RECOMMENDATION_SECTION_APPENDICES_ORDER, (key) =>
          selected = selectedAppendices.get(key) ? false
          onChange = @handleAppendixChange key
          <ItemWithCheckbox
            checked={selected}
            className="mt-10"
            key={key}
            onChange={onChange}
          >
            <div>{@i18n "section_appendices.#{key}"}</div>
          </ItemWithCheckbox>
        }
      </div>}
      <div className="mt-10">
        <ApplyCancelButtons
          onApply={@handleInsert}
          onCancel={onCancel}
          isSubmitEnabled={!selectedQuestions.isEmpty()}
          applyLabel={@i18n 'insert_recommendation_label'}
        />
      </div>
    </div>

module.exports = ConnectStore InsertRecommendation,
  [EtdsStore],
  storeConnector
