GeneralScopeRow = require './general_scope_row'
GeneralScopeStore = require 'stores/general_scope_store'
GeneralScopeActions = require 'actions/general_scope_actions'
CustomRenderMixin = require '../mixins/custom_render_mixin'
Translation = require '../mixins/translation'
Spinner = require 'components/common/spinner'
ConnectStore = require 'components/enhancers/connect_store'
{ bool, string, instanceOf } = PropTypes

SCOPE_SECTIONS = ['title', 'purpose', 'perspective', 'target_population', 'healthcare_setting',
  'key_coexisting_conditions', 'intervention_types', 'key_stakeholders_users', 'key_resources',
  'key_issues', 'existing_documents']

storeConnectors =
  GeneralScopeStore: (GeneralScopeStore) ->
    isFetching: GeneralScopeStore.isFetching()
    currentEditable: GeneralScopeStore.getActiveContentEditable()
    scope: GeneralScopeStore.getScopeData()

GeneralScopeTable = createReactClass
  displayName: 'GeneralScopeTable'

  propTypes:
    isFetching: bool.isRequired
    currentEditable: string.isRequired
    scope: instanceOf(Immutable.Map).isRequired

  mixins: [
    CustomRenderMixin
    Translation()
  ]

  render: ->
    if @props.isFetching and @props.scope.isEmpty()
      <Spinner />
    else
      <table className="standard-table scope-table">
        <tbody>
          {for attr in SCOPE_SECTIONS
            <GeneralScopeRow
              key={attr}
              title={@i18n "scope:#{attr}"}
              content={@props.scope.get(attr) ? ''}
              currentEditable={@props.currentEditable}
              description={@i18n "scope:descriptions.#{attr}"}
              fieldName={attr}
            />
          }
        </tbody>
      </table>

module.exports =
  GeneralScopeTable: GeneralScopeTable
  GeneralScopeTableConnected: ConnectStore GeneralScopeTable, GeneralScopeStore, storeConnectors
