var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "short";
  },"3":function(depth0,helpers,partials,data) {
  return "      <tr class=\"table-header\">\n        <td colspan=\"6\" class=\"title question-data\">\n        </td>\n      </tr>\n";
  },"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "            ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_participants_follow_up", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "            ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.no_of_participants", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <th rowspan=\"2\" class=\"blue-cell regular-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.relative_effect", {"name":"i18n","hash":{},"data":data})))
    + "<br/>(95% CI)</p>\n        </th>\n        <th colspan=\"2\" class=\"grey-cell regular-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.anticipated_absolute_effects", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n        </th>\n";
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <th colspan=\"3\" rowspan=\"2\" class=\"impact narrative-header\">\n          <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.impact", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n        </th>\n";
},"13":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "          <th class=\"grey-cell regular-header\">\n            <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.comparison || (depth0 != null ? depth0.comparison : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparison","hash":{},"data":data}) : helper)))
    + "</p>\n          </th>\n          <th class=\"grey-cell regular-header\">\n            <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.risk_difference_with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + "</p>\n          </th>\n";
},"15":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "  <tbody>\n    <tr>\n      <td "
    + escapeExpression(((helpers.sofPrintoutColspan || (depth0 && depth0.sofPrintoutColspan) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), {"name":"sofPrintoutColspan","hash":{},"data":data})))
    + ">\n        "
    + escapeExpression(((helpers.i18nNoEsc || (depth0 && depth0.i18nNoEsc) || helperMissing).call(depth0, "es:outcome.printout.asterisk_text", {"name":"i18nNoEsc","hash":{},"data":data})))
    + "\n        <br>\n        <br>\n        ";
  stack1 = ((helper = (helper = helpers.conjunctions || (depth0 != null ? depth0.conjunctions : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"conjunctions","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n      </td>\n    </tr>\n  </tbody>\n  <tbody>\n    <tr>\n      <td "
    + escapeExpression(((helpers.sofPrintoutColspan || (depth0 && depth0.sofPrintoutColspan) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), {"name":"sofPrintoutColspan","hash":{},"data":data})))
    + ">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.acpServer : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.program(18, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </td>\n    </tr>\n  </tbody>\n";
},"16":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(((helpers.i18nNoEsc || (depth0 && depth0.i18nNoEsc) || helperMissing).call(depth0, "es:outcome.printout.certainty_legend_acp", {"name":"i18nNoEsc","hash":{},"data":data})))
    + "\n";
},"18":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(((helpers.i18nNoEsc || (depth0 && depth0.i18nNoEsc) || helperMissing).call(depth0, "es:outcome.printout.certainty_legend", {"name":"i18nNoEsc","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<table class=\"outcomes-table ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" valign=\"top\" cellpadding=\"5\" width=\"100%\">\n  <thead>\n    <tr class=\"table-header\">\n      <td colspan=\"6\" class=\"title printout\">\n        <p class=\"title\"><b>"
    + escapeExpression(((helper = (helper = helpers.tableTitle || (depth0 != null ? depth0.tableTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tableTitle","hash":{},"data":data}) : helper)))
    + "</b></p>\n      </td>\n    </tr>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <tr>\n      <th rowspan=\"2\" class=\"blue-cell\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcomes", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n      </th>\n      <th rowspan=\"2\" class=\"blue-cell\">\n        <p>\n";
  stack1 = ((helpers.serverSwitchOn || (depth0 && depth0.serverSwitchOn) || helperMissing).call(depth0, "acpFeatures", {"name":"serverSwitchOn","hash":{},"fn":this.program(5, data),"inverse":this.program(7, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "          <br/>("
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.studies", {"name":"i18n","hash":{},"data":data})))
    + ")<br/>\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.follow_up", {"name":"i18n","hash":{},"data":data})))
    + "\n        </p>\n      </th>\n      <th rowspan=\"2\" class=\"blue-cell\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.printout.certainty_of_evidence", {"name":"i18n","hash":{},"data":data})))
    + "<br>(GRADE)</p>\n      </th>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "regular", {"name":"is","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "narrative", {"name":"is","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </tr>\n      <tr class=\"border-bottom\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "regular", {"name":"is","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </tr>\n  </thead>\n\n  <tbody class=\"printout-rows\"></tbody>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</table>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}