Question = require 'components/scope/outcomes/question'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

QuestionsList = createReactClass
  displayName: 'QuestionsList'
  mixins: [CustomRenderMixin]

  _questionsMapper: (qData, idx) ->
    <Question question={qData.get 'question'} key={qData.get '_id'} />

  render: ->
    <div className="questions">
      {@props.questions.map @_questionsMapper}
    </div>

module.exports = QuestionsList
