var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", blockHelperMissing=helpers.blockHelperMissing, buffer = "<div class=\"choose-format\">\n  <label class=\"choose-format-title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_output", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n  <div class=\"formats-list\">\n    <label>\n      <input type=\"radio\" name=\"exportType\" value=\"image\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.image", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n    <label>\n";
  stack1 = ((helper = (helper = helpers.isConnected || (depth0 != null ? depth0.isConnected : depth0)) != null ? helper : helperMissing),(options={"name":"isConnected","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.isConnected) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </label>\n    <label>\n";
  stack1 = ((helper = (helper = helpers.isConnected || (depth0 != null ? depth0.isConnected : depth0)) != null ? helper : helperMissing),(options={"name":"isConnected","hash":{},"fn":this.program(6, data),"inverse":this.program(8, data),"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.isConnected) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </label>\n  </div>\n</div>\n<div class=\"buttons\">\n  <div class=\"row mt-10\">\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-cancel cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-export download finish\" disabled>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "button_download", {"name":"i18n","hash":{},"data":data})))
    + "\n      </button>\n    </div>\n  </div>\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"exportType\" value=\"externalLink\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_link", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"exportType\" value=\"externalLink\" disabled>\n        <span class=\"label-disabled-disconnected\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_link", {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n        <span class=\"question-mark-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_link_offline", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n";
},"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"exportType\" value=\"externalEmbeddable\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_embeddable_code", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"exportType\" value=\"externalEmbeddable\" disabled>\n        <span class=\"label-disabled-disconnected\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_embeddable_code", {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n        <span class=\"question-mark-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.external_embeddable_offline", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<h2 class=\"title\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:export_dialog", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}