Button = require 'components/common/button'
CadthGuidelinesActions = require 'actions/cadth_guidelines_actions'
useTranslation = require 'components/hooks/use_translation'

CadthGuidelinesToolbar = ->
  i18n = useTranslation('key_messages:guidelines')

  <div className="cadth-guidelines-toolbar">
    <h3>{i18n 'title'}</h3>
    <div className="cadth-guidelines-toolbar__button">
      <Button
        className="btn btn-apply"
        label={i18n 'add_guideline'}
        onClick={-> CadthGuidelinesActions.openDialog()}
      />
    </div>
  </div>

module.exports = CadthGuidelinesToolbar
