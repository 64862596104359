var QuestionsStatusesActions, QuestionsStatusesStore, alt, convertRowsToMap, errorHandler, immutable, _ref;

alt = require('alt');

_ref = require('lib/app_utils'), convertRowsToMap = _ref.convertRowsToMap, errorHandler = _ref.errorHandler;

immutable = require('stores/utils/immutable');

QuestionsStatusesActions = require('actions/questions_statuses_actions');

QuestionsStatusesStore = (function() {
  function QuestionsStatusesStore() {
    var stateJS;
    stateJS = {
      meta: {
        fetching: false,
        deletedQuestionsStatuses: Immutable.Map()
      },
      statuses: {}
    };
    this.state = Immutable.fromJS(stateJS);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      getQuestionStatuses: this.getQuestionStatuses,
      getQuestionsStatuses: this.getQuestionsStatuses,
      getDeletedQuestionStatusesDoc: this.getDeletedQuestionStatusesDoc
    });
    this.bindActions(QuestionsStatusesActions);
  }

  QuestionsStatusesStore.prototype.isFetching = function() {
    return this.state.getIn(['meta', 'fetching']);
  };

  QuestionsStatusesStore.prototype.onDbChange = function(doc) {
    return this.setState(this.state.setIn(['statuses', doc.questionId], Immutable.fromJS(doc)));
  };

  QuestionsStatusesStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['meta', 'fetching'], true));
  };

  QuestionsStatusesStore.prototype.onFetchSuccess = function(doc) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetching'], false);
      return state.set('statuses', convertRowsToMap(doc.rows, 'questionId'));
    }));
  };

  QuestionsStatusesStore.prototype.onFetchError = function(err) {
    this.setState(this.state.setIn(['meta', 'fetching'], false));
    return errorHandler(err);
  };

  QuestionsStatusesStore.prototype.getQuestionsStatuses = function() {
    return this.state.get('statuses') || Immutable.Map();
  };

  QuestionsStatusesStore.prototype.getQuestionStatuses = function(questionId) {
    return this.state.getIn(['statuses', questionId]) || Immutable.Map();
  };

  QuestionsStatusesStore.prototype.getDeletedQuestionStatusesDoc = function(questionId) {
    return this.state.getIn(['meta', 'deletedQuestionsStatuses', questionId]) || Immutable.Map();
  };

  QuestionsStatusesStore.prototype.onRemoveQuestionStatuses = function(questionsStatusesDoc) {
    var questionId;
    questionId = questionsStatusesDoc['questionId'];
    return this.setState(this.state.setIn(['meta', 'deletedQuestionsStatuses', questionId], questionsStatusesDoc));
  };

  return QuestionsStatusesStore;

})();

module.exports = alt.createStore(immutable(QuestionsStatusesStore), 'QuestionsStatusesStore');
