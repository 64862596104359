Input = require 'components/common/input'
Button = require 'components/common/button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

QuestionDxEdit = createReactClass
  displayName: 'QuestionDxEdit'

  propTypes:
    data: PropTypes.object.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:question')
  ]

  getInitialState: ->
    withComparator: @props.data.get('comparatorTestPresent') or false

  toggleComparator: ->
    current = @state.withComparator
    @setState withComparator: not current

  getData: ->
    indexTest: @_indexTest.value
    comparatorTest: @_comparatorTest?.value or @props.data.get 'comparatorTest'
    targetCondition: @_targetCondition.value
    healthProblemOrPopulation: @_healthProblemOrPopulation.value
    comparatorTestPresent: @state.withComparator
    diagnosticVerb: @_diagnosticVerb.value

  renderIndexTest: ->
    <Input
      id='indexTest'
      inputRef={(el) => @_indexTest = el}
      defaultValue={@props.data.get 'indexTest'}
      key='1'
    />

  renderComparatorTest: ->
    <Input
      id='comparatorTest'
      inputRef={(el) => @_comparatorTest = el}
      defaultValue={@props.data.get 'comparatorTest'}
    />

  renderComparatorToggle: ->
    buttonClass = classNames 'input-button',
      'add-comparator-test add': not @state.withComparator
      'remove-comparator-test remove': @state.withComparator

    <Button
      className={buttonClass}
      title={@i18n 'add_comparator_test'}
      onClick={@toggleComparator}
    />

  renderDiagnosticVerbSelect: ->
    <select
      id='diagnosticVerb'
      ref={(el) => @_diagnosticVerb = el}
      defaultValue={@props.data.get 'diagnosticVerb'}
    >
      <option value='diagnose'>{@i18n 'diagnose_label'}</option>
      <option value='screen'>{@i18n 'screen_label'}</option>
    </select>

  componentDidMount: ->
    @_indexTest.focus()

  render: ->
    containerClass = classNames 'diagnostic expanded',
      if @state.withComparator then 'diagnostic-comparator' else 'diagnostic-index'

    <div className={containerClass}>
      {@i18n 'should'}
      {' '}
      {if @state.withComparator
        [
          @renderIndexTest(),
          ' ',
          @i18n('vs'),
          <span className='input-with-button' key='3'>
            {@renderComparatorTest()}
            {@renderComparatorToggle()}
          </span>
        ]
      else
        <span className='input-with-button'>
          {@renderIndexTest()}
          {@renderComparatorToggle()}
        </span>
      }
      {' '}
      {@i18n 'be_used'}
      {' '}
      {@i18n 'to_label'}
      {' '}
      {@renderDiagnosticVerbSelect()}
      {' '}
      <Input
        id='targetCondition'
        inputRef={(el) => @_targetCondition = el}
        defaultValue={@props.data.get 'targetCondition'}
      />
      {' '}
      {@i18n 'in'}
      {' '}
      <Input
        id='healthProblemOrPopulation'
        inputRef={(el) => @_healthProblemOrPopulation = el}
        defaultValue={@props.data.get 'healthProblemOrPopulation'}
      />
      {'?'}
    </div>

module.exports = QuestionDxEdit
