var BoxView, SortOutcomesView, template, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

BoxView = require('base/views/box_view');

template = require('views/templates/evidence_syntheses/sort_outcomes');

utils = require('base/lib/utils');

module.exports = SortOutcomesView = (function(_super) {
  __extends(SortOutcomesView, _super);

  function SortOutcomesView() {
    return SortOutcomesView.__super__.constructor.apply(this, arguments);
  }

  SortOutcomesView.prototype.template = template;

  SortOutcomesView.prototype.initialize = function() {
    SortOutcomesView.__super__.initialize.apply(this, arguments);
    this.outcomes = this.options.parentView.model.collection;
    this.currentPosition = this.outcomes.indexOf(this.options.parentView.model);
    return this.delegate('change', '.outcomes-sort-select', this.sortOutcomes);
  };

  SortOutcomesView.prototype.getTemplateData = function() {
    return {
      outcomes: this.outcomes.models,
      currentPosition: this.currentPosition
    };
  };

  SortOutcomesView.prototype.sortOutcomes = function(e) {
    var newPosition, questionWithOutcomesView, silent, viewType;
    newPosition = parseInt(e.target.value);
    this.outcomes.switchItems(this.currentPosition, newPosition, silent = true);
    questionWithOutcomesView = this.options.outcomesView.options.parentView;
    viewType = questionWithOutcomesView.viewType;
    return questionWithOutcomesView.showOutcomesSubview(viewType, viewType);
  };

  return SortOutcomesView;

})(BoxView);
