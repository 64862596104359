alt = require 'alt'
Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
IconButton = require 'components/common/icon_button'
MDGTopicActions = require 'actions/mdg_topic_actions'
MDGTopicStore = require 'stores/mdg_topic_store'
mediator = require 'mediator'
Modal = require 'components/common/modal'
Spinner = require 'components/common/spinner'
{ useCoffeeCallback, useCoffeeEffect, useCoffeeMemo, useI18n } = require 'lib/react_utils'
{ useState } = React

storeConnector =
  MDGTopicStore: (Store) ->
    isFetching: Store.isFetching()
    topics: Store.getTopics()

MDGTopicsModal = ({ isOpen, onChange, onClose, topics, value }) ->
  i18n = useI18n 'settings:mdg_topics'

  onToggle = useCoffeeCallback [onChange, value], (topicId) -> (e) ->
    e.preventDefault()
    e.stopPropagation()
    if topicId in value
      onChange _.without value, topicId
    else
      onChange _.union value, [topicId]

  <Modal
    className="mdg-topics-field-modal"
    isOpen={isOpen}
    modalSize="medium"
    title={i18n 'select_topics'}
  >
    <div className="mdg-topics-field-modal__description">
      {i18n 'select_topics_description'}
    </div>
    <div className="mdg-topics-field-modal__topics">
      {if topics.isEmpty()
        <div className="mdg-topics-field-modal__no-topics">{i18n 'no_topics'}</div>
      else
        topics.valueSeq().map (topic) ->
          topicId = topic.get '_id'
          isSelected = topicId in value

          <div className="topics__topic" key={topicId} onClick={onToggle topicId}>
            <div className="topic__checkbox">
              <input
                checked={isSelected}
                onChange={onToggle topicId}
                type="checkbox"
              />
            </div>
            <div className="topic__name">
              {topic.get 'name', ''}
            </div>
          </div>
      }
    </div>
    <div className="mdg-topics-field-modal__button">
      <Button
        className="btn btn-apply"
        label={i18n "/actions.close"}
        onClick={onClose}
      />
    </div>
  </Modal>

MDGTopicsModal.propTypes =
  isOpen: PropTypes.bool.isRequired
  onChange: PropTypes.func.isRequired
  onClose: PropTypes.func.isRequired
  topics: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
  value: PropTypes.arrayOf(PropTypes.string).isRequired

MDGTopicsField = ({ isFetching, onChange, topics, value }) ->
  i18n = useI18n 'settings:mdg_topics'

  useCoffeeEffect [], ->
    # Delay for dispatch in the middle of dispatch
    _.delay ->
      MDGTopicActions.fetch dbId: mediator.project.id
    , 0
    -> alt.recycle 'MDGTopicStore'

  [isModalOpen, setIsModalOpen] = useState false
  onOpenModal = -> setIsModalOpen true
  onCloseModal = -> setIsModalOpen false

  topicsText = useCoffeeMemo [i18n, topics, value], ->
    topicsToShow = topics.valueSeq().filter (topic) -> topic.get('_id') in value
    if topicsToShow.isEmpty()
      i18n 'no_topics_selected'
    else
      topicsToShow
        .map (topic) -> topic.get 'name', ''
        .interpose ', '

  <div className={classNames 'mdg-topics-field', loading: isFetching}>
    {if isFetching
      <Spinner scale={0.5} />
    else
      <React.Fragment>
        <div className="mdg-topics-field__list">{topicsText}</div>
        <div className="mdg-topics-field__button-edit">
          <IconButton iconName="edit" onClick={onOpenModal} />
        </div>
      </React.Fragment>
    }
    <MDGTopicsModal
      isOpen={isModalOpen}
      onChange={onChange}
      onClose={onCloseModal}
      topics={topics}
      value={value}
    />
  </div>

MDGTopicsField.propTypes =
  isFetching: PropTypes.bool.isRequired
  onChange: PropTypes.func.isRequired
  topics: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
  value: PropTypes.arrayOf(PropTypes.string)

MDGTopicsField.defaultProps =
  value: []

module.exports = ConnectStore MDGTopicsField, MDGTopicStore, storeConnector
