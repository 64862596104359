{ instanceOf } = PropTypes
ConnectStore = require 'components/enhancers/connect_store'
Translation = require 'components/mixins/translation'
IconButton = require 'components/common/icon_button'
Tooltip = require 'components/common/tooltip'
ImportStore = require 'stores/import_store'
ImportActions = require 'actions/import_actions'
ImportFileInput = require 'components/import_dialog/import_file_input'

storeConnectors =
  ImportStore: (Store) ->
    fileData: Store.getFileData()

ImportChooseSource = createReactClass
  displayName: 'ImportChooseSource'
  mixins: [Translation('projects:import_dialog')]

  propTypes:
    fileData: instanceOf(Immutable.Map).isRequired

  _fileInputRef: (el) ->
    @fileInput = el

  _fileFormRef: (el) ->
    @fileInputForm = el

  onFileClicked: ->
    @fileInput?.click()

  _getFileButtonText: ->
    fileName = @props.fileData.get 'fileName'
    if fileName
      fileName
    else
      @i18n 'choose_rm5_grd_file'

  _isFileChosen: ->
    Boolean(@props.fileData.get 'file')

  resetSource: ->
    @fileInputForm?.reset()
    ImportActions.resetSource()

  _renderCross: ->
    <Tooltip>
      <IconButton
        title={@i18n '/actions.remove'}
        iconName="import-source-cross decline-icon"
        onClick={@resetSource}
      />
    </Tooltip>

  _renderFileButton: ->
    isFileChosen = @_isFileChosen()
    title = if isFileChosen then @props.fileData.get('fileName') else ''
    classes = classNames
      'import-source-button-container': true
      'active': isFileChosen

    <div className={classes}>
      <form ref={@_fileFormRef}>
        <ImportFileInput ref={@_fileInputRef} />
      </form>
      <Tooltip>
        <button
          className="btn btn-apply import-source-button"
          onClick={@onFileClicked}
          title={title}
        >
          {@_getFileButtonText()}
        </button>
      </Tooltip>
      {isFileChosen and @_renderCross()}
    </div>

  render: ->
    <div className="import-choose-source">
      {@_renderFileButton()}
    </div>

module.exports = ConnectStore ImportChooseSource, [ImportStore], storeConnectors
