var W, base64ImageToBlob, errorHandler, getFileName, mediator, saveFile, toBase64String, toImageFile;

errorHandler = require('lib/app_utils').errorHandler;

saveFile = require('lib/file_saver').saveFile;

mediator = require('mediator');

W = require('when');

base64ImageToBlob = function(dataURLString) {
  var base64Str, char, contentMeta, contentType, idx, raw, rawLength, uInt8Array, _i, _len, _ref;
  _ref = dataURLString.split(';base64,'), contentMeta = _ref[0], base64Str = _ref[1];
  contentType = contentMeta.split(':')[1];
  raw = window.atob(base64Str);
  rawLength = raw.length;
  uInt8Array = new Uint8Array(rawLength);
  for (idx = _i = 0, _len = raw.length; _i < _len; idx = ++_i) {
    char = raw[idx];
    uInt8Array[idx] = raw.charCodeAt(idx);
  }
  return new Blob([uInt8Array], {
    type: contentType
  });
};

getFileName = function() {
  var questionText;
  questionText = mediator.questions.get(mediator.currentQuestionId).get('question');
  return "" + questionText + "_isof.png";
};

toBase64String = function(targetEl) {
  var canvas, clonedEl, fakeDoc;
  fakeDoc = document.implementation.createHTMLDocument();
  clonedEl = targetEl.cloneNode(true);
  canvas = document.createElement('canvas');
  canvas.height = targetEl.offsetHeight;
  canvas.width = targetEl.offsetWidth;
  fakeDoc.head.innerHTML = document.head.innerHTML;
  fakeDoc.body.appendChild(clonedEl);
  return W(rasterizeHTML.drawDocument(fakeDoc, canvas).then(function() {
    var base64Str;
    base64Str = canvas.toDataURL();
    fakeDoc = null;
    canvas = null;
    return base64Str;
  }, function(err) {
    return errorHandler(err);
  }));
};

toImageFile = function(targetEl) {
  var loadingDialog;
  loadingDialog = mediator.dialogs.loading($.t('preparing_image'));
  return toBase64String(targetEl).then(function(base64Str) {
    var blob;
    blob = base64ImageToBlob(base64Str);
    return saveFile(blob, getFileName());
  }).ensure(function() {
    return loadingDialog.destroy();
  });
};

module.exports = {
  toBase64String: toBase64String,
  toImageFile: toImageFile
};
