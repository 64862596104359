ConnectStore = require 'components/enhancers/connect_store'
DBEPPublicationActions = require 'actions/dbep_publication_actions'
DBEPPublicationStore = require 'stores/dbep_publication_store'
Exception = require 'base/lib/exception'
ItemWithCheckbox = require 'components/common/item_with_checkbox'
ProgressBar = require 'components/common/progress_bar'
Translation = require 'components/mixins/translation'

storeConnector =
  DBEPPublicationStore: (Store) ->
    mode: Store.getMode()
    questions: Store.getQuestions()
    selectedQuestions: Store.getSelectedQuestions()
    progressBarPercentage: Store.getProgressBarPercentage()
    isValidating: Store.isValidating()
    validationResult: Store.getValidationResult()
    validationError: Store.getValidationError()
    validationOverride: Store.areErrorsOverridden()
    topAccepted: Store.areTOPAccepted()
    publishResult: Store.getPublishResult()

Validation = createReactClass
  displayName: 'DBEPPublicationValidation'
  mixins: [Translation('dbep:publish_dialog.validation')]

  propTypes:
    questions: PropTypes.instanceOf(Immutable.Map).isRequired
    selectedQuestions: PropTypes.instanceOf(Immutable.List).isRequired
    mode: PropTypes.oneOf(['preview', 'publish', 'publish-no-moderation']).isRequired
    progressBarPercentage: PropTypes.number.isRequired
    isValidating: PropTypes.bool.isRequired
    validationResult: PropTypes.instanceOf(Immutable.Map).isRequired
    validationError: PropTypes.oneOfType([
      PropTypes.string, PropTypes.instanceOf(Error), PropTypes.instanceOf(Exception)
    ])
    validationOverride: PropTypes.bool.isRequired
    topAccepted: PropTypes.bool.isRequired
    publishResult: PropTypes.instanceOf(Immutable.Map).isRequired

  _renderErrors: ->
    { validationResult, questions } = @props
    validationErrors = validationResult.get('errors') ? Immutable.Map()
    return null if validationErrors.isEmpty()
    <ul className="validation__errors">
      {validationErrors.keySeq().map (questionId) ->
        <li key={questionId}>{questions.getIn [questionId, 'question']}</li>
      }
    </ul>

  _renderValidationScreen: ->
    { mode, progressBarPercentage } = @props
    <div className="dbep-modal__validation">
      <p>{@i18n if mode is 'preview' then 'uploading' else 'validating'}</p>
      <ProgressBar className="validation__progress-bar" percentage={progressBarPercentage} />
    </div>

  _renderValidationResult: ->
    { validationResult, mode, topAccepted, validationOverride } = @props
    isOk = validationResult.get('ok') ? false
    link = validationResult.get 'link'

    termsOfPublicationLink = <a
      href="https://gradepro.org/terms-of-service"
      rel="noopener noreferrer"
      target="_blank"
      onClick={(e) -> e.stopPropagation()}
    >
      {@i18n 'terms_of_publication'}
    </a>

    switch mode
      when 'preview'
        <div className="dbep-modal__validation">
          <p style={textAlign: 'center'}>{@i18n 'upload_success'}</p>
          {### TODO: Display errors in PDF (in T2864).###}
          {not isOk and <p style={textAlign: 'center'}>{@i18n 'validation_error_preview'}</p>}
          {not isOk and @_renderErrors()}
          <p style={textAlign: 'center'}>
            <a href={link} target="_blank" rel="noreferrer noopener">
              {@i18n 'link_preview'}
            </a>
          </p>
        </div>
      else
        key = if isOk then 'validation_success' else 'validation_error'

        <div className="dbep-modal__validation">
          <p style={textAlign: 'center'}>{@i18n key}</p>
          {### TODO: Display errors in PDF (in T2864). ###}
          {not isOk and @_renderErrors()}
          {not isOk and <p style={textAlign: 'center'}>{@i18n 'override_info'}</p>}
          <p style={textAlign: 'center'}>{@i18n 'contact_info'}</p>
          {not isOk and <ItemWithCheckbox
            className="validation__override"
            checked={validationOverride}
            onChange={DBEPPublicationActions.toggleValidationOverride}
          >
            <span>{@i18n 'acknowledge_errors'}</span>
          </ItemWithCheckbox>}
          {mode is 'publish' and <ItemWithCheckbox
            className="validation__top"
            checked={topAccepted}
            onChange={DBEPPublicationActions.toggleTOPAccepted}
          >
            <span>
              {@i18n 'accept_terms_of_publication', {}, { termsOfPublicationLink }}
            </span>
          </ItemWithCheckbox>}
        </div>

  _renderValidationError: ->
    { validationError, mode } = @props

    reason = if _.isString validationError
      validationError
    else if _.isString validationError.message
      validationError.message
    else
      @i18n '/errors:dbep.no_connection'
    key = if mode is 'preview' then 'upload_error' else 'validation_error'

    <div className="dbep-modal__validation">
      <p style={textAlign: 'center'}>{@i18n key, { reason }}</p>
    </div>

  _renderPublishResult: ->
    { publishResult, mode } = @props
    link = publishResult.get 'link'
    key = if mode is 'publish'
      'publish_success'
    else
      'publish_success_without_moderation'

    <div className="dbep-modal__validation">
      <p style={textAlign: 'center'}>{@i18n key}</p>
      {###DISABLED FOR NOW UNTIL WE HAVE A PROPER WAY TO DISPLAY
      <p style={textAlign: 'center'}>
        <a href={link} target="_blank" rel="noreferrer noopener">
          {@i18n 'link'}
        </a>
      </p>
      ###}
    </div>

  render: ->
    { isValidating, validationError, publishResult } = @props
    if isValidating
      @_renderValidationScreen()
    else if validationError?
      @_renderValidationError()
    else if publishResult.get 'ok'
      @_renderPublishResult()
    else
      @_renderValidationResult()

module.exports = ConnectStore Validation, [DBEPPublicationStore], storeConnector
