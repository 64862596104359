var Dialogs, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

utils = require('base/lib/utils');

module.exports = Dialogs = (function(_super) {
  __extends(Dialogs, _super);

  function Dialogs(options) {
    if (options == null) {
      options = {};
    }
    this.infoBox = __bind(this.infoBox, this);
    this.couchError = __bind(this.couchError, this);
    this.fatalError = __bind(this.fatalError, this);
    this.error = __bind(this.error, this);
    Dialogs.__super__.constructor.call(this, _.defaults(options, {
      types: ['warning', 'error', 'info', 'success', 'default'],
      el: 'body',
      ms: 3000,
      theme: 'gdt',
      zIndex: 102,
      screenOpacity: 0.85
    }));
  }

  Dialogs.prototype.info = function(args) {
    if (args == null) {
      args = {};
    }
    return this._notify(args, {
      type: 'info',
      closeBtn: true
    });
  };

  Dialogs.prototype.loading = function(args) {
    if (args == null) {
      args = {};
    }
    return this._notify(args, {
      message: 'Loading...',
      position: 'center',
      modal: true,
      loader: true,
      fadeInMs: 0,
      fadeOutMs: 0,
      ms: null
    });
  };

  Dialogs.prototype.undo = function(args) {
    if (args == null) {
      args = {};
    }
    return this._notify(args, {
      message: '',
      el: 'body',
      type: 'info',
      closeBtn: true,
      hideOnClick: false,
      buttons: [
        {
          'data-role': 'undo',
          text: $.t('actions.undo')
        }
      ],
      ms: null
    });
  };

  Dialogs.prototype.confirm = function(args, callback) {
    var noClass, yesClass, _ref, _ref1;
    if (args == null) {
      args = {};
    }
    if (_(args).isFunction() && !callback) {
      callback = args;
      args = {};
    }
    yesClass = (_ref = args.yesClass) != null ? _ref : 'success';
    noClass = (_ref1 = args.noClass) != null ? _ref1 : 'cancel';
    return this._notify(args, {
      message: $.t('messages.confirm'),
      closeBtn: false,
      hideOnClick: false,
      modal: true,
      position: 'center',
      buttons: [
        {
          'data-role': 'no',
          text: args.declineText || $.t('actions.no'),
          'class': noClass
        }, {
          'data-role': 'yes',
          text: args.confirmText || $.t('actions.yes'),
          'class': yesClass
        }
      ],
      ms: null
    }).on('click:no', 'destroy').on('click:yes', 'destroy').on('click:yes', _.bind(callback, null, true)).on('click:no', _.bind(callback, null, false));
  };

  Dialogs.prototype.error = function(args) {
    var dialog;
    if (args == null) {
      args = {};
    }
    dialog = this._notify(args, {
      message: '',
      position: 'center',
      modal: true,
      type: 'error',
      ms: false,
      buttons: [
        {
          'data-handler': 'destroy',
          text: $.t('actions.ok')
        }
      ]
    });
    dialog.$el.find('.notifier-inner a').on('click', function(e) {
      return e.stopPropagation();
    });
    return dialog;
  };

  Dialogs.prototype.fatalError = function(error) {
    var errorMsg, _ref;
    utils.reportRavenError(error);
    errorMsg = (_ref = error.message) != null ? _ref : error;
    return this.error({
      message: "" + errorMsg + "<br>" + ($.t('errors:general.fatal')),
      zIndex: 10003
    });
  };

  Dialogs.prototype.modalDefault = function(args) {
    if (args == null) {
      args = {};
    }
    return this._notify(args, {
      message: '',
      position: 'center',
      type: 'default',
      modal: true,
      ms: false,
      buttons: [
        {
          'data-handler': 'destroy',
          text: $.t('actions.ok')
        }
      ]
    });
  };

  Dialogs.prototype.modalSuccess = function(args) {
    if (args == null) {
      args = {};
    }
    return this._notify(args, {
      message: '',
      position: 'center',
      type: 'success',
      modal: true,
      ms: false,
      buttons: [
        {
          'data-handler': 'destroy',
          text: $.t('actions.ok')
        }
      ]
    });
  };

  Dialogs.prototype.modalSaved = function(args) {
    if (args == null) {
      args = {};
    }
    return this._notify(args, {
      message: '',
      position: 'center',
      type: 'saved',
      modal: true,
      ms: false,
      buttons: [
        {
          'data-handler': 'destroy',
          text: $.t('actions.ok')
        }
      ]
    });
  };

  Dialogs.prototype.warning = function(args) {
    if (args == null) {
      args = {};
    }
    return this._notify(args, {
      message: '',
      position: 'center',
      type: 'warning',
      modal: true,
      ms: false,
      buttons: [
        {
          'data-handler': 'destroy',
          text: $.t('actions.ok')
        }
      ]
    });
  };

  Dialogs.prototype.couchError = function(response) {
    return this.error({
      message: response.responseText
    });
  };

  Dialogs.prototype._setDialogContent = function(dialog, el) {
    return dialog.$el.find('.notifier-message').empty().append(el);
  };

  Dialogs.prototype.modal = function(title, el, args) {
    var dialog;
    if (el == null) {
      el = null;
    }
    if (args == null) {
      args = {};
    }
    dialog = this._notify(args, {
      message: '',
      modal: true,
      dialog: true,
      title: title
    });
    dialog.$el.find('.notifier-inner').off('click');
    if (el) {
      this._setDialogContent(dialog, el);
    }
    return dialog;
  };

  Dialogs.prototype.infoBox = function(el, args) {
    var dialog;
    if (el == null) {
      el = null;
    }
    if (args == null) {
      args = {};
    }
    dialog = this._notify(args, {
      message: '',
      position: 'center',
      cls: 'info-box',
      modal: true,
      dialog: true,
      closeBtn: true,
      screenOpacity: 0.1,
      hideOnClick: true
    });
    if (el) {
      this._setDialogContent(dialog, el);
    }
    return dialog;
  };

  Dialogs.prototype._notify = function(args, defaults) {
    var durations;
    durations = {
      'none': null,
      'short': 3000,
      'medium': 5000,
      'long': 8000
    };
    if (args instanceof Error) {
      args = {
        message: args.message
      };
    } else if (_(args).isString()) {
      args = {
        message: args
      };
    } else if (args.statusText) {
      args = {
        message: args.statusText
      };
    }
    if (_(durations).has(args.duration)) {
      args.ms = durations[args.duration];
    }
    return this.notify(_.defaults(args, defaults));
  };

  return Dialogs;

})(Backbone.Notifier);
