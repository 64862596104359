var OrganizationsActions, OrganizationsStore, alt, errorHandler, immutable, mediator, storeHelpers;

alt = require('alt');

mediator = require('mediator');

immutable = require('stores/utils/immutable');

OrganizationsActions = require('actions/organizations_actions');

storeHelpers = require('stores/utils/store_helpers');

errorHandler = require('lib/app_utils').errorHandler;

OrganizationsStore = (function() {
  function OrganizationsStore() {
    this.state = Immutable.fromJS({
      meta: {
        fetching: false,
        fetchingMembers: false,
        online: true,
        welcomeDialogShown: false
      },
      organizationMembers: [],
      organizations: []
    });
    this.bindActions(OrganizationsActions);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      isFetchingMembers: this.isFetchingMembers,
      getOrganizationMembers: this.getOrganizationMembers,
      getOrganizations: this.getOrganizations,
      isCurrentProjectFromOrganization: this.isCurrentProjectFromOrganization,
      isOnline: this.isOnline,
      shouldShowWelcomeMessage: this.shouldShowWelcomeMessage
    });
  }

  OrganizationsStore.prototype.isFetching = function() {
    return this.state.getIn(['meta', 'fetching']);
  };

  OrganizationsStore.prototype.isFetchingMembers = function() {
    return this.state.getIn(['meta', 'fetchingMembers']);
  };

  OrganizationsStore.prototype.onConnected = function() {
    return this.setState(this.state.setIn(['meta', 'online'], true));
  };

  OrganizationsStore.prototype.onDisconnected = function() {
    return this.setState(this.state.setIn(['meta', 'online'], false));
  };

  OrganizationsStore.prototype.isOnline = function() {
    return this.state.getIn(['meta', 'online'], true);
  };

  OrganizationsStore.prototype.onFetching = function() {
    return this.state.setIn(['meta', 'fetching'], true);
  };

  OrganizationsStore.prototype.onFetchSuccess = function(organizations) {
    return this.setState(this.state.withMutations(function(s) {
      s.set('organizations', Immutable.fromJS(organizations));
      return s.setIn(['meta', 'fetching'], false);
    }));
  };

  OrganizationsStore.prototype.fetchOrganizationMembersError = function(err) {
    this.setState(this.state.setIn(['meta', 'fetchingMembers'], false));
    return errorHandler(err);
  };

  OrganizationsStore.prototype.fetchOrganizationMembersSuccess = function(members) {
    members = Immutable.fromJS(members).map(function(m) {
      return m.set('_id', m.get('email'));
    });
    return this.setState(this.state.withMutations(function(s) {
      s.set('organizationMembers', members);
      return s.setIn(['meta', 'fetchingMembers'], false);
    }));
  };

  OrganizationsStore.prototype.getOrganizationMembers = function() {
    return this.state.get('organizationMembers') || Immutable.List();
  };

  OrganizationsStore.prototype.onFetchError = function(err) {
    this.setState(this.state.setIn(['meta', 'fetching'], false));
    return errorHandler(err);
  };

  OrganizationsStore.prototype.isCurrentProjectFromOrganization = function() {
    return this.state.getIn(['meta', 'isCurrentProjectFromOrganization']) || false;
  };

  OrganizationsStore.prototype.onCheckedIfOrganizationProject = function(organizationProject) {
    return this.setState(this.state.setIn(['meta', 'isCurrentProjectFromOrganization'], organizationProject));
  };

  OrganizationsStore.prototype.getOrganizations = function() {
    return this.state.getIn(['organizations'] || Immutable.List());
  };

  OrganizationsStore.prototype.shouldShowWelcomeMessage = function() {
    var _ref;
    if (this.state.getIn(['meta', 'welcomeDialogShown'])) {
      return false;
    }
    return (_ref = !mediator.user.get('skipOrganizationsWelcomeDialog')) != null ? _ref : false;
  };

  return OrganizationsStore;

})();

module.exports = alt.createStore(immutable(OrganizationsStore), 'OrganizationsStore');
