EllipsizedText = require 'components/common/ellipsized_text'
InfoIconWithTooltip = require 'components/common/info_icon_with_tooltip'
Collapsible = require 'components/common/collapsible_with_controls'
ImportActions = require 'actions/import_actions'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Question = require 'models/question'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Modal = require 'components/common/modal'
Spinner = require 'components/common/spinner'
DynamicHeightContainer = require 'components/common/dynamic_height_container'
{ lensPath, lensWhere } = require 'lib/lens_utils'
{ generateGUID, callMethod } = require 'base/lib/utils'
{ bool, arrayOf, instanceOf, oneOfType, string, func } = PropTypes

# outcomesToStudyIds :: [outcomes] -> [studyIds]
outcomesToStudyIds = R.compose(
  R.uniq
  R.flatten
  R.map(R.compose(R.keys, callMethod('get', 'studies')))
)

OverReviewImportModal = createReactClass
  displayName: 'OverReviewImportModal'

  propTypes:
    fileName: string
    onClose: func.isRequired
    isLoading: bool.isRequired
    questions: oneOfType([arrayOf(instanceOf(Question)), instanceOf(Immutable.List)]).isRequired
    studies: instanceOf(Immutable.Map)

  mixins: [
    CustomRenderMixin,
    Translation('over_review:actions')
  ]

  getInitialState: ->
    questions: {}

  canSave: ->
    @props.questions.some (q) => @canQuestionBeImported q.cid

  getQuestionOutcomesCIDs: (qCID) ->
    question = R.find R.whereEq(cid: qCID), @props.questions
    question
      .get('outcomes')
      .map R.prop 'cid'

  canQuestionBeImported: (qCID) ->
    return false if _.isEmpty @state.questions[qCID]
    R.both(
      R.compose(R.not, _.isEmpty, R.view(lensPath(qCID, 'outcomes', lensWhere(R.identity))))
      R.compose(R.not, _.isEmpty, R.view(lensPath(qCID, 'sourceName')))
    ) @state.questions

  getQuestionSelectedOutcomes: (question) ->
    question
      .get('outcomes')
      .filter (o) =>
        R.view lensPath(question.cid, 'outcomes', o.cid), @state.questions

  studyIdsToStudySpecs: (studyIds) ->
    studyIds.map (studyId) =>
      studyData = @props.studies.get studyId
      return unless studyData?

      _id: studyId
      text: "#{studyData.name}, #{studyData.year}"

  handleSave: ->
    allStudyIds = []
    sources = @props.questions
      .filter R.compose(@canQuestionBeImported, R.prop 'cid')
      .map (question) =>
        selectedOutcomes = @getQuestionSelectedOutcomes question
        studyIds = outcomesToStudyIds selectedOutcomes
        allStudyIds = R.union allStudyIds, studyIds

        sourceName: R.view lensPath(question.cid, 'sourceName'), @state.questions
        population: question.get 'healthProblemOrPopulation'
        intervention: question.get 'intervention'
        comparison: question.get 'comparison'
        outcomes: selectedOutcomes.map (o) -> R.mergeRight o.toJSON(), _id: generateGUID()
        studyIds: studyIds

    @props.onSave { sources, studies: @studyIdsToStudySpecs allStudyIds }
    @props.onClose()

  handleQuestionCheckboxChange: (evt) ->
    qCID = evt.target.getAttribute 'data-qcid'
    checked = evt.target.checked
    { questions} = @state
    questionOutcomes = lensPath qCID, 'outcomes'

    if R.view questionOutcomes, questions
      @setState
        questions: R.over(
          lensPath(qCID),
          R.compose(
            R.over lensPath('outcomes'), R.map(if checked then R.T else R.F)
            R.over lensPath('expanded'), (expanded) -> if checked then true else expanded
          )
          questions
        )
    else
      @setState
        questions: R.assoc qCID, {
          expanded: true
          outcomes: R.fromPairs @getQuestionOutcomesCIDs(qCID).map (oCID) -> [ oCID, true ]
        }, questions

  handleQuestionCollapse: (qCID) -> =>
    @setState
      questions: R.over lensPath(qCID, 'expanded'), R.F, @state.questions

  handleSourceNameChange: (evt) ->
    qCID = evt.target.getAttribute 'data-qcid'
    sourceName = evt.target.value

    @setState
      questions: R.set lensPath(qCID, 'sourceName'), sourceName, @state.questions

  handleOutcomeCheckboxChange: (evt) ->
    oCID = evt.target.getAttribute 'data-ocid'
    qCID = evt.target.getAttribute 'data-qcid'
    checked = evt.target.checked

    @setState
      questions: R.set lensPath(qCID, 'outcomes', oCID), checked, @state.questions

  toggleQuestion: (evt) ->
    qCID = evt.currentTarget.getAttribute 'data-qcid'

    @setState
      questions: R.over lensPath(qCID, 'expanded'), R.not, @state.questions

  componentWillUnmount: ->
    ImportActions.closeImportModal()

  renderOutcome: (qCID) -> (outcome, idx) =>
    oCID = outcome.cid

    <div className='outcome' key={oCID}>
      <label>
        <span className='item-select'>
          <input
            type='checkbox'
            onChange={@handleOutcomeCheckboxChange}
            data-qcid={qCID}
            data-ocid={oCID}
            checked={R.view(lensPath(qCID, 'outcomes', oCID), @state.questions) ? false}
          />
        </span>
        <div className='item-text'>
          <EllipsizedText
            text={outcome.get 'name'}
            height={40}
          />
        </div>
      </label>
    </div>

  renderQuestion: (question) ->
    qCID = question.cid
    { expanded, sourceName, outcomes } = @state.questions[qCID] ? {}
    anyOutcomeSelected = outcomes? and R.compose(R.any(R.identity), R.values)(outcomes)

    <div key={qCID} className='question'>
      <Collapsible forcedExpand={expanded} onCollapse={@handleQuestionCollapse(qCID)}>
        <label className='question-name'>
          <span className='item-select'>
            <input
              type='checkbox'
              data-qcid={qCID}
              checked={anyOutcomeSelected}
              onChange={@handleQuestionCheckboxChange}
            />
          </span>
          <div className='item-text' onClick={@toggleQuestion} data-qcid={qCID}>
            <EllipsizedText
              text={question.get 'question'}
              height={40}
            />
          </div>
        </label>
        <div className='question-details'>
          <div className='field-block'>
            <span className='field-name'>
              {@i18n "../short_name"}
              <InfoIconWithTooltip info={@i18n '../source_name_info'} />
            </span>
            <input
              autoFocus
              name={'sourceName'}
              onChange={@handleSourceNameChange}
              data-qcid={qCID}
              value={sourceName ? ''}
            />
          </div>
          <div className='outcomes-selection'>
            <div data-qcid={qCID} className='selection-title'>
              {@i18n '../actions.choose_outcomes'}
            </div>
            <div className='outcomes-list'>
              {question.get('outcomes').map @renderOutcome qCID}
            </div>
          </div>
        </div>
      </Collapsible>
    </div>

  render: ->
    { isLoading, questions, onClose } = @props

    <Modal
      isOpen
      onRequestClose={onClose}
      title={@i18n '../titles.choose_questions_and_outcomes'}
      className='over-review-revman-import'
    >
      {if isLoading
        <Spinner />
      else
        <div>
          <span>{@i18n '../module.import_help_text'}</span>
          <div className='quesitons-list'>
            {questions.map @renderQuestion}
          </div>
          <ApplyCancelButtons
            onApply={@handleSave}
            onCancel={onClose}
            isSubmitEnabled={@canSave()}
            applyLabel={@i18n '/actions.save'}
            applyClass='btn btn-import'
          />
        </div>
      }
    </Modal>

module.exports = OverReviewImportModal
