QuestionWithCheckbox = require 'components/common/question_with_checkbox'
QuestionsSelector = require 'components/common/questions_selector'
Translation = require 'components/mixins/translation'
{ bool, instanceOf, func, string, oneOfType } = PropTypes

QuestionsSelectorForSending = createReactClass

  displayName: "QuestionsSelectorForSending"

  propTypes:
    # should be only those questions that has the same status (e.g questions that has been sent
    # only with assessment sections to panel voice)
    questions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    currentQuestionId: string.isRequired
    questionGroups: instanceOf(Immutable.Map).isRequired
    updateSelected: func.isRequired
    selectedQuestions: instanceOf(Immutable.List).isRequired

  mixins: [Translation("voting:voting")]

  getCurrentQuestion: ->
    { currentQuestionId, questions } = @props
    questions.find (question) -> question.get("_id") is currentQuestionId

  onSelectQuestion: (etdId) ->
    selectedQuestions = if @props.selectedQuestions.contains etdId
      @props.selectedQuestions.delete @props.selectedQuestions.indexOf etdId
    else
      @props.selectedQuestions.push etdId
    @props.updateSelected selectedQuestions

  render: ->
    {
      questions
      currentQuestionId
      questionGroups
      updateSelected
      selectedQuestions
    } = @props

    <div className="selecting-questions">
      <div className="current-question">
        <QuestionWithCheckbox
          question={@getCurrentQuestion()}
          onSelectQuestion={@onSelectQuestion}
          checked={true}
          readonly={true}
        />
      </div>
      <div>
        <h3 className="text-center">{@i18n 'modals.questions_same_status'}</h3>
        <QuestionsSelector
          questions={questions}
          currentQuestionId={currentQuestionId}
          questionGroups={questionGroups}
          updateSelected={updateSelected}
          selectedQuestions={selectedQuestions}
          withoutBorder
        />
      </div>
    </div>

module.exports = QuestionsSelectorForSending
