ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
{ mdgFormat } = require 'lib/references_utils'
MDGTableActions = require 'actions/mdg_table_actions'
MDGTableStore = require 'stores/mdg_table_store'
Modal = require 'components/common/modal'
ReferencesStore = require 'stores/references_store'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

storeConnector =
  MDGTableStore: (Store) ->
    applying: Store.getParentDialogIsApplying()
    currentStudyId: Store.getParentDialogCurrentStudyId()
    questionId: Store.getCurrentQuestion().get '_id'
    selectedStudyId: Store.getParentDialogSelectedStudyId()
    studies: Store.getCurrentQuestion().get('studies', Immutable.List())
  ReferencesStore: (Store) ->
    references: Store.getReferences()

ParentStudySelector = ({
  applying
  currentStudyId
  questionId
  references
  selectedStudyId
  studies
}) ->
  i18n = useI18n 'mdg_tables:parent_study_selector_dialog'
  studiesToShow = useCoffeeMemo [currentStudyId, studies], -> studies.filter (study) ->
    study.get('referenceId')? and # don't show studies without references
      study.get('id') isnt currentStudyId and # don't show current study
      study.get('parentStudy') isnt currentStudyId # don't allow for direct cycles
  formatReference = useCoffeeCallback [references], (referenceId) ->
    reference = references.find (reference) -> reference.get('_id') is referenceId
    return '' unless reference
    mdgFormat reference.toJS()
  onSelect = useCoffeeCallback [], (studyId) -> (e) ->
    e.stopPropagation()
    MDGTableActions.selectParentStudy studyId
  onApply = useCoffeeCallback [currentStudyId, questionId, selectedStudyId], ->
    MDGTableActions.connectParentStudy questionId, currentStudyId, selectedStudyId

  <Modal
    className="parent-study-selector-dialog"
    isOpen={currentStudyId?}
    modalSize="medium"
    title={i18n 'title'}
  >
    <div className="parent-study-selector-dialog__description">
      {i18n 'description'}
    </div>
    <div className="parent-study-selector-dialog__selection">
      {studiesToShow.isEmpty() and <div className="selection__no-studies">
        {i18n 'no_studies'}
      </div>}
      {studiesToShow.map (study) ->
        studyId = study.get 'id'
        <div className="selection__item" key={studyId} onClick={onSelect studyId}>
          <div className="item__radio">
            <input
              checked={selectedStudyId is studyId}
              name="parent-study-selector"
              onChange={onSelect studyId}
              type="radio"
            />
          </div>
          <div className="item__study-name">
            {formatReference study.get 'referenceId'}
          </div>
        </div>
      }
    </div>
    <ApplyCancelButtons
      applying={applying}
      isSubmitEnabled={selectedStudyId?}
      onApply={onApply}
      onCancel={MDGTableActions.closeParentStudyDialog}
    />
  </Modal>

ParentStudySelector.propTypes =
  applying: PropTypes.bool.isRequired
  currentStudyId: PropTypes.string
  selectedStudyId: PropTypes.string
  studies: PropTypes.instanceOf(Immutable.List).isRequired

ParentStudySelector.defaultProps =
  currentStudyId: null
  selectedStudyId: null

module.exports = ConnectStore ParentStudySelector, [MDGTableStore, ReferencesStore], storeConnector
