_.namespace(module, function(require) {
  var Exceptions, Request, W, adapter, mediator;
  Request = require('models/request');
  Exceptions = require('lib/exceptions');
  mediator = require('mediator');
  adapter = require('lib/pouch_adapter');
  W = require('when');
  return {
    _createRequestStub: function() {
      var request;
      request = new Request({
        type: 'revman_csv_import',
        status: 'stub'
      });
      mediator.user.getRequests().add(request);
      return request.save();
    },
    _createRequest: function(source) {
      return this._createRequestStub().then(function(request) {
        return adapter.attach(request, 'archive.zip', new Blob([source], {
          type: 'application/zip'
        }));
      });
    },
    _sendRequest: function(request) {
      return request.save({
        status: 'new'
      });
    },
    _watchForStatusChange: function(request) {
      return W.promise(function(resolve, reject) {
        return request.on('change:status', function() {
          switch (request.get('status')) {
            case 'complete':
              return resolve(request);
            case 'error':
              return reject(new Exceptions.server_reported(request.get('error_message')));
          }
        });
      });
    },
    "import": function(source) {
      return this._createRequest(source).then((function(_this) {
        return function(request) {
          var promise;
          promise = _this._watchForStatusChange(request);
          return _this._sendRequest(request).then(function() {
            return promise;
          });
        };
      })(this));
    }
  };
});
