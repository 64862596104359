IconButton = require 'components/common/icon_button'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ChecklistActions = require 'actions/checklist_actions'
EllipsizedText = require 'components/common/ellipsized_text'
CheckboxInput = require 'components/common/checkbox_input'
Button = require 'components/common/button'
Modal = require 'components/common/modal'
Collapsible = require 'components/common/collapsible_with_controls'
TopicStep = require 'components/checklist/checklist_topic_step'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ i18nForMixin } = require 'lib/react_utils'

ChecklistTopic = createReactClass
  displayName: 'ChecklistTopic'

  propTypes:
    steps: PropTypes.instanceOf(Immutable.Map).isRequired
    stepsOrder: PropTypes.instanceOf(Immutable.List).isRequired
    sources: PropTypes.instanceOf(Immutable.Map).isRequired
    topicId: PropTypes.string.isRequired
    projectChecklistData: PropTypes.object
    type: PropTypes.oneOf(['mdg', 'regular']).isRequired

  mixins: [
    CustomRenderMixin,
    Translation('gd_checklist:topics')
  ]

  getInitialState: ->
    showingStepSources: null
    showingCommentInputFor: null
    showingCommentFor: null


  closeStepSourcesModal: ->
    @setState showingStepSources: null

  getSources: (sourceIdsOrRanges) ->
    { sources } = @props

    sourceIdsOrRanges
      .flatMap (sourceIdOrRange) ->
        [ rangeStart, rangeEnd ] = sourceIdOrRange.split '-'
        if rangeEnd?
          Immutable.Range(Number(rangeStart), Number(rangeEnd) + 1)
            .map (sourceId) -> sources.get "#{sourceId}"
            .toList()
        else
          Immutable.List [ sources.get "#{rangeStart}" ]
      .toArray()

  showStepSources: (sourceIdsOrRanges) -> =>
    @setState showingStepSources: @getSources sourceIdsOrRanges

  showCommentInput: (stepId) -> =>
    @setState showingCommentInputFor: stepId

  hideCommentInput: ->
    @setState showingCommentInputFor: null

  getStepStatus: (stepId) ->
    @props.projectChecklistData?[stepId]?.status

  getStepComment: (stepId) ->
    @props.projectChecklistData?[stepId]?.comment

  handleStepStatusChange: (topicId, stepId) -> (evt) =>
    status = evt.target.value
    currentStatus = @getStepStatus stepId
    status = null if status is currentStatus

    ChecklistActions.updateStepStatus { stepId, topicId, status }

  handleStepCommentApply: (stepId) -> (comment) =>
    { topicId } = @props
    ChecklistActions.updateStepComment { stepId, topicId, comment }
    @hideCommentInput()

  render: ->
    { stepsOrder, steps, topicId, type } = @props

    i18n = if type is 'mdg'
      i18nForMixin 'gd_checklist:topicsMdg'
    else
      @i18n

    <div className='checklist-topic' key={topicId}>
      <Collapsible withTogglableCaption>
        <div className='topic-name'>
          <EllipsizedText
            text={i18n "#{topicId}.name"}
            height={50}
          />
        </div>
        <div className='topic-steps'>
          {stepsOrder.map (stepId) =>
            stepData = steps.get stepId

            <TopicStep
              key={stepId}
              implementationTools={stepData.get 'implementationTools'}
              isEditingComment={@state.showingCommentInputFor is stepId}
              learningTools={stepData.get 'learningTools'}
              onCommentApply={@handleStepCommentApply stepId}
              onShowStepSources={@showStepSources stepData.get 'sources'}
              onStatusChange={@handleStepStatusChange topicId, stepId}
              onRequestCommentEdit={@showCommentInput stepId}
              onRequestCommentEditClose={@hideCommentInput}
              stepComment={@getStepComment stepId}
              stepId={stepId}
              stepStatus={@getStepStatus stepId}
              topicId={topicId}
              titlePresent={stepData.get 'titlePresent'}
              type={type}
            />
          }
          {if @state.showingStepSources?
            <Modal
              isOpen
              className='step-sources-modal'
              closeButton
              onClose={@closeStepSourcesModal}
              onRequestClose={@closeStepSourcesModal}
            >
              <ol>
                {@state.showingStepSources.map (sourceText, idx) ->
                  <li key={idx}>{sourceText}</li>
                }
              </ol>
            </Modal>
          }
        </div>
      </Collapsible>
    </div>

module.exports = ChecklistTopic
