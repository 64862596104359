Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
PublicationPlatformActions = require 'actions/publication_platform_actions'
PublicationPlatformStore = require 'stores/publication_platform_store'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

storeConnector =
  PublicationPlatformStore: (Store) ->
    guidelinePublicationDate: Store.getGuidelinePublicationDate()

PublicationPlatformToolbar = ({ guidelinePublicationDate }) ->
  i18n = useI18n()

  openPublicationPlatformGuidelineModal = useCoffeeCallback [guidelinePublicationDate],
    (preview) -> ->
      republish = guidelinePublicationDate?
      PublicationPlatformActions.openModal { mode: 'guideline', preview, republish }

  buttonText = useCoffeeMemo [guidelinePublicationDate, i18n], ->
    if guidelinePublicationDate?
      i18n 'actions.republish_guideline'
    else
      i18n 'actions.publish_guideline'

  tooltip = useCoffeeMemo [guidelinePublicationDate, i18n], ->
    if guidelinePublicationDate?
      date = moment(guidelinePublicationDate).format('MM/DD/YYYY HH:mm:ss')
      i18n 'dissemination:publication_platform.guideline_tooltip', { date }
    else
      undefined

  <div className="toolbar-publication-platform">
    <h2 className="toolbar-publication-platform__title">
      {i18n 'dissemination:titles.publication_platform'}
    </h2>
    <div className="toolbar-publication-platform__buttons">
      <Button
        className="btn btn-primary mr-5"
        label={i18n 'actions.preview_guideline'}
        onClick={openPublicationPlatformGuidelineModal(true)}
      />
      <Tooltip>
        <Button
          className="btn btn-apply"
          label={buttonText}
          onClick={openPublicationPlatformGuidelineModal(false)}
          title={tooltip}
        />
      </Tooltip>
    </div>
  </div>

PublicationPlatformToolbar.propTypes =
  guidelinePublicationDate: PropTypes.number

module.exports = ConnectStore PublicationPlatformToolbar, PublicationPlatformStore, storeConnector
