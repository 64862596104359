var FeedbackView, ModalView, W, mediator, template, toBase64String,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

W = require('when');

template = require('./templates/feedback');

mediator = require('mediator');

toBase64String = require('lib/html_to_image').toBase64String;

module.exports = FeedbackView = (function(_super) {
  __extends(FeedbackView, _super);

  FeedbackView.prototype.className = 'feedback-view';

  FeedbackView.prototype.template = template;

  FeedbackView.prototype.title = $.t('feedback.title');

  function FeedbackView() {
    FeedbackView.__super__.constructor.apply(this, arguments);
    this.toBase64String = toBase64String;
  }

  FeedbackView.prototype.initialize = function() {
    FeedbackView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.cancel', this.dispose);
    return this.delegate('click', 'button.send', this._send);
  };

  FeedbackView.prototype._send = function() {
    var height, takeScreenshot, text, type, width, _ref;
    text = this.$('.text').val();
    type = this.$('.type').val();
    takeScreenshot = this.$('.attach-screenshot').prop('checked');
    _ref = window.screen, width = _ref.width, height = _ref.height;
    this.dispose();
    return this.dialog.on('destroy', (function(_this) {
      return function() {
        return _this._getScreenshot(takeScreenshot).then(function(screenshot) {
          var _ref1;
          return mediator.user.getRequests().create({
            type: 'feedback',
            email: mediator.user.get('email'),
            project: (_ref1 = mediator.project) != null ? _ref1.id : void 0,
            feedbackType: type,
            feedbackText: text,
            platformInfo: platform.description,
            screenResolution: "" + width + "x" + height,
            locationHash: window.location.hash,
            versionTimestamp: window.gdt.versionTimestamp,
            screenshot: screenshot
          });
        }).then(function() {
          return mediator.dialogs.success($.t('feedback.thanks'));
        }, function(error) {
          return mediator.fatalError(error);
        });
      };
    })(this));
  };

  FeedbackView.prototype._getScreenshot = function(takeScreenshot) {
    if (!takeScreenshot) {
      return W.resolve();
    }
    return this.toBase64String(document.body);
  };

  return FeedbackView;

})(ModalView);
