var ComparisonQuestionGroupsDoc, DbHelper, OverarchingQuestionDoc, QuestionGroupsActions, QuestionsActions, Router, W, alt, appUtils, callMethod, generateActions, getCurrentQuestion, mediator;

appUtils = require('lib/app_utils');

generateActions = require('actions/utils/generate_actions');

QuestionsActions = require('actions/questions_actions');

QuestionGroupsActions = require('actions/question_groups_actions');

OverarchingQuestionDoc = require('lib/db_docs/overarching_question_doc');

ComparisonQuestionGroupsDoc = require('lib/db_docs/comparisons_question_groups_doc');

callMethod = require('base/lib/utils').callMethod;

Router = require('router');

mediator = require('mediator');

alt = require('alt');

DbHelper = require('base/lib/db_helper');

W = require('when/when');

getCurrentQuestion = function() {
  return alt.stores.OverarchingQuestionsStore.getCurrentQuestion();
};

module.exports = generateActions({
  autoGenerate: ['addCode', 'addIncludedQuestion', 'addTag', 'clearIncludedTags', 'dbChange', 'destroyCurrentQuestion', 'fetchCodesSuccess', 'fetchError', 'fetchOneSuccess', 'fetchSuccess', 'handleToggleTag', 'removeCode', 'removeIncludedQuestion', 'removeTag', 'showExportDialog', 'hideExportDialog', 'updateCurrentQuestion', 'updateCurrentQuestionType', 'updateIncludedQuestion', 'updateMetadata', 'updateTag'],
  name: 'OverarchingQuestionsActions',
  actions: {
    fetch: function(projectId, fetchOthers) {
      if (fetchOthers == null) {
        fetchOthers = true;
      }
      this.dispatch();
      OverarchingQuestionDoc.at(projectId).fetchMany().then((function(_this) {
        return function(docs) {
          return _this.actions.fetchSuccess(Immutable.fromJS(_.chain(docs).compact().map(function(doc) {
            return [doc._id, doc];
          }).object().value()));
        };
      })(this))["catch"](this.actions.fetchError);
      if (fetchOthers) {
        QuestionsActions.fetch();
        return QuestionGroupsActions.fetch();
      }
    },
    fetchOne: function(questionId) {
      this.dispatch();
      return OverarchingQuestionDoc.at(mediator.project.id, questionId).fetch().then((function(_this) {
        return function(res) {
          _this.actions.fetchOneSuccess(res);
          QuestionsActions.fetch();
          QuestionGroupsActions.fetch();
          return res;
        };
      })(this))["catch"](this.actions.fetchError);
    },
    saveCurrentQuestion: function() {
      var currentQuestion;
      this.dispatch();
      currentQuestion = getCurrentQuestion();
      return OverarchingQuestionDoc.at(mediator.project.id).save(currentQuestion.toJS());
    },
    createQuestion: function(groupId) {
      if (groupId == null) {
        groupId = null;
      }
      return OverarchingQuestionDoc.at(mediator.project.id).create({}).then(function(doc) {
        if (groupId) {
          ComparisonQuestionGroupsDoc.at(mediator.project.id).updateAsInstance(callMethod('addQuestionToGroup', doc._id, groupId));
        }
        return doc;
      }).then((function(_this) {
        return function(doc) {
          return _this.actions.goToOverarchingCreator(doc._id, true);
        };
      })(this))["catch"](appUtils.errorHandler);
    },
    goToOverarchingCreator: function(questionId, creating) {
      var route, submodule;
      if (creating == null) {
        creating = false;
      }
      submodule = creating ? 'create' : 'edit';
      route = Router.prototype.getProjectRelativeUrl("/overarching-questions/" + questionId + "/" + submodule);
      return mediator.publish('!router:route', route);
    },
    goToOverarchingRecommendations: function(questionId) {
      var route;
      route = Router.prototype.getProjectRelativeUrl("/overarching-questions/" + questionId + "/details");
      return mediator.publish('!router:route', route);
    },
    "delete": function(projectId, questionId) {
      return OverarchingQuestionDoc.at(projectId, questionId)["delete"]();
    },
    duplicate: function(questionId) {
      return QuestionsActions.duplicateQuestion({
        questionId: questionId,
        type: 'overarching'
      });
    }
  }
});
