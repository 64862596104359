Etd = require 'components/etd/etd'

KeyMessageRecommendation = ({ questionId }) ->
  <div id="recommendations">
    <Etd type="keyMessages" questionId={questionId} />
  </div>

KeyMessageRecommendation.propTypes = {
  questionId: PropTypes.string.isRequired
}

module.exports = KeyMessageRecommendation
