var DocSectionsFromTemplateActions, DocSectionsFromTemplateToolbar, DocumentSectionsFromTemplateContainer, DocumentSectionsFromTemplateView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DocumentSectionsFromTemplateContainer = require('components/document_sections/from_template/document_sections_from_template_container');

DocSectionsFromTemplateToolbar = require('components/document_sections/from_template/doc_sections_from_template_toolbar');

DocSectionsFromTemplateActions = require('actions/doc_sections_from_template_actions');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

mediator = require('mediator');

alt = require('alt');

module.exports = DocumentSectionsFromTemplateView = (function(_super) {
  __extends(DocumentSectionsFromTemplateView, _super);

  function DocumentSectionsFromTemplateView() {
    return DocumentSectionsFromTemplateView.__super__.constructor.apply(this, arguments);
  }

  DocumentSectionsFromTemplateView.prototype.container = '#page-container';

  DocumentSectionsFromTemplateView.prototype.id = 'document-sections-from-template-container';

  DocumentSectionsFromTemplateView.prototype.autoRender = true;

  DocumentSectionsFromTemplateView.prototype.initialize = function() {
    DocumentSectionsFromTemplateView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: DocSectionsFromTemplateToolbar
    }));
    return this.enable(ReactComponent);
  };

  DocumentSectionsFromTemplateView.prototype.afterRender = function() {
    DocumentSectionsFromTemplateView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(DocumentSectionsFromTemplateContainer, DocSectionsFromTemplateActions, 'document_sections_from_template', {
      fetchAndListen: {
        dbId: mediator.project.id,
        opts: {
          attachments: true
        }
      }
    });
  };

  DocumentSectionsFromTemplateView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('QuestionsStore', 'DocSectionsFromTemplateStore');
    return DocumentSectionsFromTemplateView.__super__.dispose.apply(this, arguments);
  };

  return DocumentSectionsFromTemplateView;

})(View);
