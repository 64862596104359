var MDGQuestionDoc, MDGSearchStrategyDoc, MDGTableActions, MDG_SEARCH_STRATEGY_PREFIX, W, appUtils, callMethod, docTypes, generateActions, generateGUID, mediator, _ref;

appUtils = require('lib/app_utils');

_ref = require('base/lib/utils'), callMethod = _ref.callMethod, generateGUID = _ref.generateGUID;

docTypes = require('lib/db_docs/doc_types');

generateActions = require('actions/utils/generate_actions');

MDGQuestionDoc = require('lib/db_docs/mdg_question_doc');

MDGSearchStrategyDoc = require('lib/db_docs/mdg_search_strategy_doc');

MDG_SEARCH_STRATEGY_PREFIX = require('lib/doc_prefixes').mdgSearchStrategy;

MDGTableActions = require('actions/mdg_table_actions');

mediator = require('mediator');

W = require('when/when');

module.exports = generateActions({
  autoGenerate: ['fetchSuccess', 'fetchError', 'setActiveTab', 'collapseRow', 'expandRow', 'openGeneralInformationModal', 'closeGeneralInformationModal'],
  name: 'MDGSearchStrategyActions',
  actions: {
    fetch: function(_arg) {
      var dbId, docId;
      dbId = _arg.dbId, docId = _arg.docId;
      this.dispatch();
      return MDGTableActions.fetch({
        dbId: dbId,
        docId: docId,
        fetchReferences: false,
        throwErr: true
      }).then((function(_this) {
        return function(question) {
          var creatingSearchStrategy, newSearchStrategyId, promise, searchStrategyData, searchStrategyId, searchStrategyType, updatingQuestion;
          searchStrategyId = question.searchStrategyId, searchStrategyType = question.searchStrategyType;
          promise = searchStrategyId != null ? MDGSearchStrategyDoc.at(dbId, searchStrategyId).fetch().then(function(searchStrategy) {
            return [searchStrategy];
          }) : (newSearchStrategyId = "" + MDG_SEARCH_STRATEGY_PREFIX + (generateGUID()), updatingQuestion = MDGQuestionDoc.at(dbId, docId).updateAsInstance(function(doc) {
            return doc.setAt('searchStrategyId')(newSearchStrategyId);
          }), searchStrategyData = {
            _id: newSearchStrategyId,
            questionId: docId,
            searchStrategyType: searchStrategyType
          }, creatingSearchStrategy = MDGSearchStrategyDoc.at(dbId, newSearchStrategyId).create(searchStrategyData), W.all([creatingSearchStrategy, updatingQuestion]));
          return promise.then(function(_arg1) {
            var searchStrategy;
            searchStrategy = _arg1[0];
            return _this.actions.fetchSuccess(searchStrategy);
          });
        };
      })(this))["catch"](this.actions.fetchError);
    },
    dbChange: function(change, _pending, _lastSeq) {
      var doc;
      doc = change.doc;
      if (doc.docType === docTypes.MDG_QUESTION) {
        MDGTableActions.dbChange(change, _pending, _lastSeq);
      }
      if (doc.docType === docTypes.MDG_SEARCH_STRATEGY) {
        return this.dispatch(change);
      }
    },
    saveGeneralInformation: function(searchStrategyId, value) {
      this.dispatch();
      return MDGSearchStrategyDoc.at(mediator.project.id, searchStrategyId).updateAsInstance(callMethod('updateGeneralInformation', value))["catch"](appUtils.errorHandler);
    },
    updateGeneralInformationField: function(field, value) {
      return this.dispatch({
        field: field,
        value: value
      });
    },
    updateCriterionField: function(searchStrategyId, tabId, typeId, field, value) {
      return MDGSearchStrategyDoc.at(mediator.project.id, searchStrategyId).updateAsInstance(callMethod('updateCriterion', tabId, typeId, field, value))["catch"](appUtils.errorHandler);
    }
  }
});
