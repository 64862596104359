{ Tab, TabsContainer } = require 'components/common/tabs_container'
InitialTab = require 'components/scope/questions/initial_tab'
BrainstormingTab = require 'components/scope/questions/brainstorming_tab'
ModerationTab = require 'components/scope/questions/moderation_tab'
RatingTab = require 'components/scope/questions/rating_tab'
ProposalTab = require 'components/scope/questions/proposal_tab'
ApprovalTab = require 'components/scope/questions/approval_tab'
FinishedTab = require 'components/scope/questions/finished_tab'
GeneratedQuestionsSelectModal =
  require 'components/scope/questions/generated_questions_select_modal'
FetchingSpinner = require 'components/mixins/fetching_spinner'
NextStepConfirmationModal = require 'components/scope/questions/next_step_confirmation_modal'
CommentsExportModal = require 'components/scope/questions/comments_export_modal'
QuestionsGenerationActions = require 'actions/questions_generation_actions'
QuestionsGenerationStore = require 'stores/questions_generation_store'
MembersStore = require 'stores/members_store'
QuestionsStore = require 'stores/questions_store'
LimitsStore = require 'stores/limits_store'
CreateStoreMixin = require 'components/mixins/create_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'
{ checkConnection } = require 'lib/app_utils'
[
  initialStep
  brainstormingStep
  moderationStep
  ratingStep
  proposalStep
  approvalStep
  finishedStep
] = require('lib/questions_helper').QUESTION_GENERATION_STEPS
{ DndProvider } = ReactDnD

QuestionsGeneration = createReactClass
  displayName: 'QuestionsGeneration'

  mixins: [
    CustomRenderMixin
    CreateStoreMixin(QuestionsGenerationStore, MembersStore, QuestionsStore, LimitsStore)
    FetchingSpinner
    Translation('scope:questions')
  ]

  # GETTERS

  getStateFromStores: ->
    isFetching: QuestionsGenerationStore.isFetching() or MembersStore.isFetching()
    isFetchingResults: QuestionsGenerationStore.isFetchingResults()
    isClosingStep: QuestionsGenerationStore.isClosingStep()
    isExportingComments: QuestionsGenerationStore.isExportingComments()
    activeTab: QuestionsGenerationStore.getActiveTab()
    currentStep: QuestionsGenerationStore.getCurrentStep()
    nextStep: QuestionsGenerationStore.getNextStep()
    steps: QuestionsGenerationStore.getStepsData()
    todos: QuestionsGenerationStore.getTodos()
    editingQuestion: QuestionsGenerationStore.getEditingQuestion()
    addingQuestion: QuestionsGenerationStore.getAddingQuestion()
    members: MembersStore.getPanelMembers()
    comparisonsQuestionsCount: QuestionsStore.getQuestions().size
    questionsLimit: LimitsStore
      .getLimits(mediator.activeWorkspace, mediator.project.id)
      .get 'questions'
    closingStep: null

  getTabQuestions: (tabName) ->
    questions = @state.steps.getIn [tabName, 'questions'], Immutable.List()
    if tabName in [approvalStep, finishedStep]
      questions.reduce (result, question) ->
        questionId = question.get 'id'
        result.set questionId, question
      , Immutable.Map()
    else
      questions

  getTabTodos: (tabName) ->
    @state.todos.get tabName, Immutable.Map()

  getFormsDocId: ->
    { activeTab } = @state
    switch activeTab
      when brainstormingStep then @state.steps.getIn [activeTab, 'formsDocId']
      when ratingStep, approvalStep
        @state.steps.getIn([activeTab, 'formsIds'])

  _getMembersMap: ->
    @state.members.mapEntries ([memberId], index) =>
      [memberId, "#{@i18n 'team_member'} #{index + 1}"]

  _getMembersSentTo: (stepName) ->
    @state.steps.getIn [stepName, 'memberIds'], Immutable.List()

  _getSubmittedData: (stepName) ->
    switch stepName
      when ratingStep, approvalStep
        @state.steps.getIn [stepName, 'submittedMemberIds'], Immutable.List()

  _getApprovalQuestionGroups: ->
    @state.steps.getIn [approvalStep, 'questionGroups'], Immutable.Map()

  fetchResults: ->
    # fetching is allowed for currentStep only
    if @state.activeTab is @state.currentStep
      return @_showNoConnectionError() unless checkConnection()
      formsDocId = @getFormsDocId()
      QuestionsGenerationActions.fetchResults { step: @state.activeTab, formsDocId }

  _isStepClosed: (step) ->
    @state.steps.getIn [step, 'closed'], false

  _showNoConnectionError: ->
    mediator.dialogs.error @i18n '../no_connection_message'

  _showApprovedQuestionsSelectModal: (questionsToSelectFrom) ->
    @setState questionsToSelectFrom: questionsToSelectFrom

  _hideApprovedQuestionsSelectModal: ->
    @setState questionsToSelectFrom: null

  # HANDLERS

  handleApprovedQuestionsSelect: (selectQuestionsIds) ->
    formsDocId = @getFormsDocId()
    questions = @getTabQuestions(approvalStep)

    QuestionsGenerationActions.closeStep
      step: approvalStep,
      formsDocId: @getFormsDocId()
      questions: @getTabQuestions(approvalStep)
      acceptedIds: selectQuestionsIds

    @_hideApprovedQuestionsSelectModal()
    @resetClosingStep()

  handleApprovedQuestionsSelectCancel: ->
    @_hideApprovedQuestionsSelectModal()
    @resetClosingStep()

  onQuestionAdd: (type) ->
    QuestionsGenerationActions.addQuestion type

  onSend: (step) -> =>
    switch step
      when initialStep, moderationStep, proposalStep
        @_sendToMembers step
      when brainstormingStep, ratingStep
        formsDocId = @getFormsDocId()
        QuestionsGenerationActions.closeStep { step, formsDocId }
      when approvalStep
        questions = @getTabQuestions(step)
        acceptedIds = @_getApprovalQuestionGroups().get 'accepted'
        if acceptedIds.size + @state.comparisonsQuestionsCount > @state.questionsLimit
          questionsToSelectFrom = questions
            .filter (q) -> acceptedIds.contains q.get 'id'
            .toList()
          return @_showApprovedQuestionsSelectModal questionsToSelectFrom
        else
          formsDocId = @getFormsDocId()
          QuestionsGenerationActions.closeStep { step, formsDocId, questions, acceptedIds }

    @resetClosingStep()

  onApplyAdminComment: (stepName) -> (data) ->
    data.step = stepName
    QuestionsGenerationActions.saveAdminComment data

  _sendToMembers: (stepName) ->
    params = {
      questions: @getTabQuestions(stepName).toJS()
      type: @state.nextStep
      members: @state.members
    }
    QuestionsGenerationActions.sendToMembers params

  closeStep: (stepName) -> =>
    return mediator.dialogs.modalDefault @i18n '../no_members_error' if @state.members.isEmpty()
    return @_showNoConnectionError() unless checkConnection()
    @setState closingStep: stepName

  resetClosingStep: ->
    @setState closingStep: null

  renderFetched: ->
    <DndProvider backend={ReactDnDHTML5Backend.default}>
      <div className='questions-generation-container'>
        <TabsContainer activeTab={@state.activeTab}>
          <Tab tabName={initialStep}>
            <InitialTab
              questions={@getTabQuestions initialStep}
              onQuestionAdd={@onQuestionAdd}
              onCloseStep={@closeStep initialStep}
              editingQuestion={@state.editingQuestion}
              addingQuestion={@state.addingQuestion}
              isClosingStep={@state.isClosingStep}
              readOnly={@_isStepClosed initialStep}
              questionsLimit={@state.questionsLimit}
            />
          </Tab>
          <Tab tabName={brainstormingStep}>
            <BrainstormingTab
              questions={@getTabQuestions brainstormingStep}
              membersMap={@_getMembersMap()}
              fetchResults={@fetchResults}
              isFetchingResults={@state.isFetchingResults}
              isClosingStep={@state.isClosingStep}
              hasConnection={checkConnection()}
              readOnly={@_isStepClosed brainstormingStep}
              onApplyAdminComment={@onApplyAdminComment brainstormingStep}
              onCloseStep={@closeStep brainstormingStep}
            />
          </Tab>
          <Tab tabName={moderationStep}>
            <ModerationTab
              questions={@getTabQuestions moderationStep}
              membersMap={@_getMembersMap()}
              onQuestionAdd={@onQuestionAdd}
              onCloseStep={@closeStep moderationStep}
              onApplyAdminComment={@onApplyAdminComment brainstormingStep}
              editingQuestion={@state.editingQuestion}
              addingQuestion={@state.addingQuestion}
              isClosingStep={@state.isClosingStep}
              readOnly={@_isStepClosed moderationStep}
              questionsLimit={@state.questionsLimit}
            />
          </Tab>
          <Tab tabName={ratingStep}>
            <RatingTab
              members={@state.members}
              membersSentTo={@_getMembersSentTo ratingStep}
              membersSubmitted={@_getSubmittedData ratingStep}
              fetchResults={@fetchResults}
              isFetchingResults={@state.isFetchingResults}
              onCloseStep={@closeStep ratingStep}
              readOnly={@_isStepClosed ratingStep}
            />
          </Tab>
          <Tab tabName={proposalStep}>
            <ProposalTab
              questions={@getTabQuestions proposalStep}
              membersMap={@_getMembersMap()}
              isClosingStep={@state.isClosingStep}
              onChangeApprovalStatus={QuestionsGenerationActions.changeQuestionApprovalStatus}
              onApplyAdminComment={@onApplyAdminComment}
              onCloseStep={@closeStep proposalStep}
              readOnly={@_isStepClosed proposalStep}
            />
          </Tab>
          <Tab tabName={approvalStep}>
            <ApprovalTab
              questions={@getTabQuestions approvalStep}
              questionGroups={@_getApprovalQuestionGroups()}
              membersMap={@_getMembersMap()}
              membersSentTo={@_getMembersSentTo approvalStep}
              membersSubmitted={@_getSubmittedData approvalStep}
              todos={@getTabTodos approvalStep}
              isFetchingResults={@state.isFetchingResults}
              isClosingStep={@state.isClosingStep}
              fetchResults={@fetchResults}
              onCloseStep={@closeStep approvalStep}
              readOnly={@_isStepClosed approvalStep}
              questionsLimit={@state.questionsLimit}
            />
          </Tab>
          <Tab tabName={finishedStep}>
            <FinishedTab
              questions={@getTabQuestions finishedStep}
              questionGroups={@_getApprovalQuestionGroups()}
            />
          </Tab>
        </TabsContainer>
        {if @state.closingStep
          <NextStepConfirmationModal
            onConfirm={@onSend @state.closingStep}
            onCancel={@resetClosingStep}
          />
        }
        {if @state.isExportingComments
          <CommentsExportModal
            questions={@getTabQuestions @state.activeTab}
            questionGroups={@_getApprovalQuestionGroups()}
            membersMap={@_getMembersMap()}
            tabName={@state.activeTab}
            projectName={mediator.project.get('name')}
            onClose={QuestionsGenerationActions.hideCommentsExport}
          />
        }
        {if @state.questionsToSelectFrom?
          <GeneratedQuestionsSelectModal
            comparisonsQuestionsCount={@state.comparisonsQuestionsCount}
            onClose={@handleApprovedQuestionsSelectCancel}
            onSubmit={@handleApprovedQuestionsSelect}
            questions={@state.questionsToSelectFrom}
            questionsLimit={@state.questionsLimit}
          />
        }
      </div>
    </DndProvider>

module.exports = QuestionsGeneration
