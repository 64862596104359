var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "comments", "", {"name":"compoundEdit","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:automatic_narrative", {"name":"i18nNamespace","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <div class=\"row mt-10\">\n      <div class=\"col-12 section-label\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.what_happens", {"name":"i18n","hash":{},"data":data})))
    + "\n      </div>\n    </div>\n    <div class=\"edit-input-block form-group row mt-10 comments-management\">\n      <div class=\"inline\">\n        <textarea id=\"comments\" class=\"cell-edit-textarea\" name=\"comments\">"
    + escapeExpression(((helper = (helper = helpers.comments || (depth0 != null ? depth0.comments : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comments","hash":{},"data":data}) : helper)))
    + "</textarea>\n      </div>\n      <div class=\"sup\"></div>\n    </div>\n";
  stack1 = this.invokePartial(partials.interpretationOfResults, '    ', 'interpretationOfResults', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <div class=\"automatic-narrative\">\n        <div class=\"row mt-10 automatic-narrative-section\">\n          <div class=\"col-12 section-label\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "\n          </div>\n        </div>\n        <div class=\"row mt-10 automatic-narrative-section form\">\n          <div class=\"col-4\">\n            <div class=\"form-group automatic-narrative-effect-size-container\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "size_of_effect", {"name":"i18nNamespace","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </div>\n          </div>\n          <div class=\"col-6\">\n            <div class=\"form-group\">\n              <label for=\"automatic-narrative-narrative-statements\">\n                "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "narrative_statements.title", {"name":"i18n","hash":{},"data":data})))
    + "\n              </label>\n              "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "automatic-narrative-narrative-statements", "notSelected", (depth0 != null ? depth0.anNarrativeStatements : depth0), (depth0 != null ? depth0.anNarrativeStatementsOptions : depth0), {"name":"selectableAttr","hash":{},"data":data})))
    + "\n            </div>\n          </div>\n          <div class=\"col-2 insert-button\">\n            <button\n              class=\"btn insert btn-apply";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.anDisabled : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n              ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.anDisabled : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            >\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.insert", {"name":"i18n","hash":{},"data":data})))
    + "\n            </button>\n          </div>\n        </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.anDisabled : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <label for=\"automatic-narrative-narrative-effect-size\">\n                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "\n                </label>\n                "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "automatic-narrative-effect-size", "notSelected", (depth0 != null ? depth0.anEffectSizes : depth0), (depth0 != null ? depth0.anEffectSizesOptions : depth0), {"name":"selectableAttr","hash":{},"data":data})))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
  return " disabled";
  },"8":function(depth0,helpers,partials,data) {
  return "disabled";
  },"10":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <div class=\"error-msg\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "errors.no_certainty", {"name":"i18n","hash":{},"data":data})))
    + "\n          </div>\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "comments", "comments", {"name":"compoundEdit","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <div class=\"edit-input-block form-group row mt-10\">\n      <div class=\"inline\">\n        <textarea id=\"comments\" class=\"cell-edit-textarea\" name=\"comments\">"
    + escapeExpression(((helper = (helper = helpers.comments || (depth0 != null ? depth0.comments : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comments","hash":{},"data":data}) : helper)))
    + "</textarea>\n      </div>\n      <div class=\"sup\"></div>\n    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isManagement : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}