Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ExportModal = require 'components/overarching_questions/export_modal'
QuestionsStore = require 'stores/questions_store'
ToolbarQuestionsList = require 'components/evidence_syntheses/toolbar_questions_list'
Translation = require 'components/mixins/translation'

storeConnector =
  QuestionsStore: (Store, props) ->
    currentQuestion: Store.getQuestion props.questionId

KeyMessageToolbar = createReactClass

  displayName: "KeyMessageToolbar"

  mixins: [Translation(''), CustomRenderMixin]

  propTypes:
    questionId: PropTypes.string.isRequired

  getInitialState: ->
    isExportModalOpen: false

  render: ->
    { questionId, currentQuestion } = @props
    { isExportModalOpen } = @state

    <div className="flex">
      <div className="questions flex-grow relative items-center">
        <ToolbarQuestionsList
          currentQuestion={currentQuestion.get('question', '')}
          questionsCollectionType="regular"
        />
      </div>
      <div className="button-export-container mr-10">
        <Button
          className='export'
          title={@i18n 'actions.export'}
          onClick={() => @setState isExportModalOpen: true}
          disabled={isExportModalOpen}
        />
      </div>
      {isExportModalOpen and <ExportModal
        currentQuestion={currentQuestion}
        onClose={() => @setState isExportModalOpen: false}
      />}
    </div>

module.exports = ConnectStore(
  KeyMessageToolbar,
  [ QuestionsStore ],
  storeConnector
)
