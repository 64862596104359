floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')

PercentInput = createReactClass
  displayName: "PercentInput"

  getInitialState: ->
    value: @props.value

  onChange: (e) ->
    @setState { value: e.target.value.replace(",", ".")
      .replace(/[^0-9.,]/g, "").replace(/(\..*)\./g, "$1")
    }

  onBlur: ->
    @props.onChange parseFloat(@state.value or 0)

  componentDidUpdate: (prevProps, prevState) ->
    if @props.value isnt prevProps.value
      @setState value: @props.value

  render: ->
    { i18n, fieldKey, placeholderToUse } = @props
    <span>
      <input
        className="text-right"
        type="text"
        onChange={@onChange}
        onBlur={@onBlur}
        value={@state.value}
        placeholder={placeholderToUse}
      />
      <span className="ml-10">%</span>
    </span>

module.exports = PercentInput
