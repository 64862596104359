VotingStore = require 'stores/voting_store'
MembersStore = require 'stores/members_store'
EtdsStore = require 'stores/etds_store'
VotingActions = require 'actions/voting_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
FetchingSpinner = require 'components/mixins/fetching_spinner'
CreateStoreMixin = require 'components/mixins/create_store'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'
{ ETD_VOTING_PHASES } = require 'lib/questions_helper'
{ NoConnectionModal, HelpInfoModal } = require 'components/administration/voting/voting_modals'
{ Tab, TabsContainer } = require 'components/common/tabs_container'
QuestionsList = require 'components/administration/voting/voting_questions_list'
VotingSummary = require 'components/administration/voting/voting_summary'
Phase1VotingStart = require 'components/administration/voting/voting_phase1_start'
Phase2VotingStart = require 'components/administration/voting/voting_phase2_start'
INFO_IMAGES =
  'votingFormAssessment': 'help/images/voting_examples_-_assessment.png'
  'votingFormJudgement': 'help/images/voting_examples_-_judgments.png'
  'votingFormConclusions': 'help/images/voting_examples_-_conclusions.png'
  'votingFormAllSections': 'help/images/voting_examples_-_all_sections.png'
  'votingFormEtdParts': 'help/images/voting_examples_-_parts_of_etd_.png'
  'votingFormEtdPartsPhase2': 'help/images/voting_examples_-_parts_of_etd_phase2.png'

ETD_PARTS_LABELS =
  'A': $.t 'settings:etd_templates.assessment'
  'T': $.t 'voting:voting.type_of_recommendation'
  'C': $.t 'settings:etd_templates.conclusions'

Voting = createReactClass
  displayName: 'Voting'
  mixins: [
    CustomRenderMixin
    CreateStoreMixin(MembersStore, VotingStore, EtdsStore)
    Translation('voting:voting')
    FetchingSpinner
  ]

  getStateFromStores: ->
    activeTab: VotingStore.getActiveTab()
    members: MembersStore.getPanelMembers()
    votingMembers: VotingStore.getVotingMembers()
    etds: EtdsStore.getEtds()
    projectQuestions: VotingStore.getProjectQuestions()
    questionGroups: VotingStore.getQuestionGroups()
    notVotedQuestions: VotingStore.getNotVotedQuestions()
    etdsWithResultsPerPhase: VotingStore.getEtdsWithResultsPerPhase()
    votingResults: VotingStore.getVotingResults()
    selectedJudgmentsOption: VotingStore.getSelectedJudgmentsOption()
    selectedEtdPartsOption: VotingStore.getSelectedEtdPartsOption()
    selectedContentOption: VotingStore.getSelectedContentOption()
    sendVoteMessage: VotingStore.getMessage 'sendVote'
    remindVoteMessage: VotingStore.getMessage 'remindVote'
    dueDate: VotingStore.getDueDate()
    contactEmail: VotingStore.getContactEmail()
    isFetching: VotingStore.isFetching() or MembersStore.isFetching() or EtdsStore.isFetching()

  componentDidMount: ->
    @checkConnection()

  checkConnection: ->
    if mediator.services.replication.isConnected()
      true
    else
      @setState showNoConnectionModal: true
      false

  _getTabQuestions: (tabName) ->
    return @state.notVotedQuestions if tabName is 'phase1a'
    phaseEtds = @state.etdsWithResultsPerPhase.get tabName
    tabQuestions = @state.projectQuestions.filter (q) ->
      etds = q.get('etds')
      etds.size and etds.every (etdId) -> phaseEtds.has etdId

    @_addVotingStats tabName, tabQuestions, phaseEtds

  _addVotingStats: (tabName, questions, phaseEtds) ->
    if tabName is 'phase2a'
      # need to know the etd part each questions has been lastly voted on
      questions.map (q) ->
        questionResult = phaseEtds.find (etdResult, etdId) -> q.get('etds').includes etdId
        lastlyVoted = if questionResult.getIn(['phase1', 'blocks']).has 'recommendation'
          'recommendation'
        else 'judgements'
        q.set 'lastlyVotedEtdPart', lastlyVoted
    else
      phaseName = tabName.slice(0, -1)
      questions.map (q) =>
        questionResult = phaseEtds.find (etdResult, etdId) -> q.get('etds').includes etdId
        dueDateTimestamp = questionResult.getIn [phaseName, 'dueDateTimestamp'], '-'
        voted = questionResult.getIn([phaseName, 'statuses'], Immutable.List()).count (status) ->
          status is 'completed'
        q.merge {dueDateTimestamp, votes: "#{voted} #{@i18n 'of'} #{@state.votingMembers.size}"}

  _getEtdPartLables: (etdPartsString) ->
    etdPartsString.split('').map (key) -> ETD_PARTS_LABELS[key]

  _groupQuestionsByLastlyVotedEtdPart: (questions) ->
    questions
    .groupBy (q) -> q.get 'lastlyVotedEtdPart'
    # have to make sure that questions, where only judgements were voted, will go first
    .sortBy (etdPartQuestions, etdGroup) -> if etdGroup is 'judgements' then -1 else 1

  _canVotingBeStarted: ->
    if @state.members.isEmpty()
      mediator.dialogs.modalDefault @i18n 'no_members_error'
      return false
    if not @checkConnection()
      @_showNoConnectionError()
      return false
    true

  _getDefaultMessage: (type) ->
    projectName = mediator.project.get 'name'
    messageTpl = switch type
      when 'sendVote' then 'voting:voting.messages.default_vote_message_text'
      when 'remindVote' then 'voting:voting.messages.default_vote_reminder_message_text'

    $.t messageTpl, { projectName }

  _getMessage: (type) ->
    @state["#{type}Message"] ? @_getDefaultMessage type

  _hasMessageTextChanged: (messageType, messageText) ->
    return false if _.isEmpty(messageText) or not (messageType? and messageText?)
    messageText isnt @_getMessage messageType

  _resetPhaseVotingOptions: (phaseName) ->
    VotingActions.resetVotingPreference if phaseName is 'phase1a'
      ['selectedEtdPartsOption', 'selectedJudgmentsOption']
    else
      'selectedContentOption'

  _hasAnyVotingStarted: ->
    { votingResults } = @state
    votingResults and not votingResults.isEmpty()

# ACTION HANDLERS
  handleTabChange: (tabName) ->
    @setState activeTab: tabName

  updateMessageDetails: (type, data) ->
    return unless @_hasMessageTextChanged type, data.emailBody

    if type is 'votingStart'
      VotingActions.updateSendVoteDetails data
    else if type is 'reminder'
      VotingActions.updateReminderDetails data

  handleSendingVote: (questionIds, blocks, votingDetails, phaseName) ->
    return mediator.dialogs.modalDefault @i18n 'no_members_error' unless @state.members.size

    if @checkConnection()
      # once voting was initiated for any question(s) in project - updateVoting method must be used
      if @state.votingResults
        VotingActions.updateVoting {questionIds, blocks, votingDetails, phaseName}
      else
        memberIds = @state.members.keySeq().toJS()
        VotingActions.startVoting {memberIds, questionIds, blocks, votingDetails}
      @_resetPhaseVotingOptions phaseName
    else
      @setState showNoConnectionModal: true

  handleSendingReminder: (questionIds, data) ->
    # no connection - no reminder
    if @checkConnection()
      VotingActions.sendReminder {questionIds, data}
    else
      @setState showNoConnectionModal: true

  handleCloseVoting: (questionIds) ->
    # no connection - no vote closing
    if @checkConnection()
      VotingActions.closeVoting {questionIds}
    else
      @setState showNoConnectionModal: true

  onInfoLinkClick: (infoFor) -> =>
    @setState showHelpInfoFor: infoFor

  componentWillUnmount: -> VotingActions.saveVotingPreferences()

# RENDERS
  renderTabComponent: (phaseName) ->
    switch phaseName
      when 'phase1a'
        <Phase1VotingStart
          projectId={mediator.project.id}
          questions={@_getTabQuestions phaseName}
          hasAnyVotingStarted={@_hasAnyVotingStarted()}
          questionGroups={@state.questionGroups}
          etds={@state.etds}
          getEtdPartLables={@_getEtdPartLables}
          selectedJudgmentsOption={@state.selectedJudgmentsOption}
          selectedEtdPartsOption={@state.selectedEtdPartsOption}
          message={@_getMessage 'sendVote'}
          dueDate={@state.dueDate}
          contactEmail={@state.contactEmail}
          onInfoLinkClick={@onInfoLinkClick}
          updateVotingOption={VotingActions.updateVotingOption}
          canVotingBeStarted={@_canVotingBeStarted}
          sendVoting={@handleSendingVote}
          updateMessageDetails={@updateMessageDetails}
        />
      when 'phase2a'
        <Phase2VotingStart
          projectId={mediator.project.id}
          questionsByEtdPart={@_groupQuestionsByLastlyVotedEtdPart @_getTabQuestions phaseName}
          questionGroups={@state.questionGroups}
          selectedContentOption={@state.selectedContentOption}
          message={@_getMessage 'sendVote'}
          dueDate={@state.dueDate}
          contactEmail={@state.contactEmail}
          getEtdPartLables={@_getEtdPartLables}
          canVotingBeStarted={@_canVotingBeStarted}
          sendVoting={@handleSendingVote}
          updateMessageDetails={@updateMessageDetails}
          updateVotingOption={VotingActions.updateVotingOption}
          onInfoLinkClick={@onInfoLinkClick}
          etds={@state.etds}
        />
      when 'phase1b', 'phase2b'
        <VotingSummary
          projectId={mediator.project.id}
          questionGroups={@state.questionGroups}
          questions={@_getTabQuestions phaseName}
          reminderMessage={@_getMessage 'remindVote'}
          votingResults={@state.etdsWithResultsPerPhase.get phaseName}
          getEtdPartLables={@_getEtdPartLables}
          votingPhase={phaseName}
          updateMessageDetails={@updateMessageDetails}
          handleSendingReminder={@handleSendingReminder}
          handleCloseVoting={@handleCloseVoting}
          saveVotingPreferences={VotingActions.saveVotingPreferences}
        />
      when 'finished'
        <div>
          <div className="voting-finished">
            <div className="section-text">
              {@i18n 'click_to_see_results'}
            </div>
            <QuestionsList
              ref="questionsList"
              noToggle={true}
              projectId={mediator.project.id}
              questions={@_getTabQuestions phaseName}
              questionGroups={@state.questionGroups}
            />
          </div>
        </div>

  renderFetched: ->
    <div className='voting-container'>
      <TabsContainer activeTab={@state.activeTab}>
        {ETD_VOTING_PHASES.map (phaseName, idx) =>
          <Tab tabName={phaseName} key={idx}>
            {@renderTabComponent phaseName}
          </Tab>
        }
      </TabsContainer>
      {if @state.showNoConnectionModal
        <NoConnectionModal onClose={=> @setState showNoConnectionModal: false} />
      }
      {if @state.showHelpInfoFor
        <HelpInfoModal
          infoFor={@state.showHelpInfoFor}
          imageSrc={INFO_IMAGES[@state.showHelpInfoFor]}
          onClose={=> @setState showHelpInfoFor: null}
        />
      }

    </div>

module.exports = Voting
