var Migration, TeamMembersAsSeparateCollection, W, sequence,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

sequence = require('when/sequence');

module.exports = TeamMembersAsSeparateCollection = (function(_super) {
  __extends(TeamMembersAsSeparateCollection, _super);

  function TeamMembersAsSeparateCollection() {
    TeamMembersAsSeparateCollection.__super__.constructor.call(this, false);
  }

  TeamMembersAsSeparateCollection.prototype.up = function(project, colls) {
    var createMember, member;
    TeamMembersAsSeparateCollection.__super__.up.apply(this, arguments);
    createMember = function(member) {
      return function() {
        return W.promise(function(resolve, reject) {
          member.lastName = member.name;
          return colls.teamMembers.create(member, {
            success: resolve,
            error: function(model, resp) {
              return reject(new Error("Save failed for " + (JSON.stringify(resp))));
            }
          });
        });
      };
    };
    return sequence((function() {
      var _i, _len, _ref, _ref1, _results;
      _ref1 = (_ref = project.get('members')) != null ? _ref : [];
      _results = [];
      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        member = _ref1[_i];
        _results.push(createMember(member));
      }
      return _results;
    })()).then(function() {
      project.unset('members');
      return project.save();
    });
  };

  return TeamMembersAsSeparateCollection;

})(Migration);
