var Question, W, exportAssessmentSummary, exportConclusions, exportInterventions, exportIsof, exportJustification, exportPopulation, exportQuestionAndRecommendation, get, getCertaintyOfEvidenceAndLabel, getEtdPrintout, getRecommendationDirectionAndStrength, getRecommendationMetadata, getRecommendationText, gradeProCodeToWHOCode, mediator, renderEditorValue, _ref;

get = require('lodash').get;

_ref = require('lib/services/covid_exporter/utils'), exportConclusions = _ref.exportConclusions, exportIsof = _ref.exportIsof, exportJustification = _ref.exportJustification, getCertaintyOfEvidenceAndLabel = _ref.getCertaintyOfEvidenceAndLabel, getEtdPrintout = _ref.getEtdPrintout, getRecommendationDirectionAndStrength = _ref.getRecommendationDirectionAndStrength, getRecommendationMetadata = _ref.getRecommendationMetadata, getRecommendationText = _ref.getRecommendationText, gradeProCodeToWHOCode = _ref.gradeProCodeToWHOCode, renderEditorValue = _ref.renderEditorValue;

mediator = require('mediator');

Question = require('models/question');

W = require('when/when');

exportInterventions = function(question) {
  var interventions, _ref1, _ref2, _ref3, _ref4;
  switch (question.type) {
    case 'diagnostic':
      interventions = [
        {
          '@id': '_:int1',
          '@type': 'WHOIntervention',
          'name': question['indexTest'],
          'codes': _.chain((_ref1 = get(question, 'codes.indexTest')) != null ? _ref1 : {}).values().map(gradeProCodeToWHOCode).value()
        }
      ];
      if (question['comparatorTestPresent']) {
        interventions.push({
          '@id': '_:int2',
          '@type': 'WHOIntervention',
          'name': question['comparatorTest'],
          'codes': _.chain((_ref2 = get(question, 'codes.comparatorTest')) != null ? _ref2 : {}).values().map(gradeProCodeToWHOCode).value()
        });
      }
      return interventions;
    default:
      return [
        {
          '@id': '_:int1',
          '@type': 'WHOIntervention',
          'name': question['intervention'],
          'codes': _.chain((_ref3 = get(question, 'codes.intervention')) != null ? _ref3 : {}).values().map(gradeProCodeToWHOCode).value()
        }, {
          '@id': '_:int2',
          '@type': 'WHOIntervention',
          'name': question['comparison'],
          'codes': _.chain((_ref4 = get(question, 'codes.comparison')) != null ? _ref4 : {}).values().map(gradeProCodeToWHOCode).value()
        }
      ];
  }
};

exportPopulation = function(question, recommendation) {
  var setting, _ref1;
  setting = renderEditorValue(get(recommendation, 'templateData.question.sections.setting.content'));
  if (!setting) {
    setting = question['settings'];
  }
  if (!setting) {
    setting = void 0;
  }
  return [
    {
      '@id': '_:pop1',
      '@type': 'WHOPopulation',
      'ages': _.reject(getRecommendationMetadata(recommendation, 'age'), function(v) {
        return v === 'any';
      }),
      'codes': _.chain((_ref1 = get(question, 'codes.population')) != null ? _ref1 : {}).values().map(gradeProCodeToWHOCode).value(),
      'description': question['healthProblemOrPopulation'],
      'setting': setting
    }
  ];
};

exportAssessmentSummary = function(recommendation) {
  var sections, sectionsOrder, _ref1;
  sectionsOrder = (_ref1 = get(recommendation, 'templateData.assessment.sectionsOrder')) != null ? _ref1 : [];
  sections = _.chain(sectionsOrder).map(function(key) {
    var criterionId, value;
    criterionId = get(recommendation, "templateData.assessment.sections." + key + ".criterionId");
    if (!criterionId) {
      return null;
    }
    value = get(recommendation, "templateData.assessment.criteria." + criterionId + ".selectedOption");
    if ((value == null) || value === '') {
      return null;
    }
    return [key, value];
  }).compact().object().value();
  if (!sections) {
    return void 0;
  }
  return {
    sections: sections,
    sectionsOrder: sectionsOrder
  };
};

exportQuestionAndRecommendation = function(question, recommendations, projectId) {
  var certaintyOfEvidence, coexistingConditions, coi, comparison, conclusions, gradeCertaintyOfEvidenceLabel, isDiagnostic, isGps, isofData, perspective, plainLanguageSummary, printoutPromise, projectName, questionModel, recommendation, recommendationDirection, recommendationId, recommendationStrength, recommendationText, _ref1, _ref2;
  projectName = mediator.projects.get(projectId).get('name');
  questionModel = new Question(question);
  isDiagnostic = question['type'] === 'diagnostic';
  isGps = question['type'] === 'good-practice-statement';
  recommendationId = _.first(question['recommendationIds']);
  recommendation = get(recommendations, recommendationId);
  if (!recommendation) {
    return W.resolve();
  }
  recommendationText = getRecommendationText(recommendation, isGps);
  if (!recommendationText) {
    return W.resolve();
  }
  _ref1 = getRecommendationDirectionAndStrength(recommendation), recommendationDirection = _ref1[0], recommendationStrength = _ref1[1];
  conclusions = exportConclusions(recommendation, ['recommendation', 'goodPracticeStatement']);
  conclusions['sections']['justification'] = exportJustification(recommendation);
  coexistingConditions = _.chain(getRecommendationMetadata(recommendation, 'coexistingCondition', '')[0].split(';')).map(function(value) {
    return _.str.capitalize(value.trim());
  }).compact().value();
  _ref2 = getCertaintyOfEvidenceAndLabel(recommendation), certaintyOfEvidence = _ref2[0], gradeCertaintyOfEvidenceLabel = _ref2[1];
  coi = renderEditorValue(get(recommendation, 'templateData.question.sections.coi.content'));
  if (!coi) {
    coi = void 0;
  }
  perspective = renderEditorValue(get(recommendation, 'templateData.question.sections.perspective.content'));
  if (!perspective) {
    perspective = void 0;
  }
  comparison = question.type === 'diagnostic' && !question.comparatorTestPresent ? void 0 : [
    {
      '@id': '_:int2'
    }
  ];
  isofData = exportIsof(questionModel, projectName);
  plainLanguageSummary = _.reject(getRecommendationMetadata(recommendation, 'plainLanguageSummary'), function(plr) {
    return _.isEmpty(plr.link);
  });
  printoutPromise = getEtdPrintout(projectId, questionModel, recommendationId);
  return printoutPromise.then(function(etdPrintout) {
    return {
      '@id': question['_id'],
      '@type': 'WHORecommendation',
      'intervention': exportInterventions(question),
      'interventionDescription': "" + question['intervention'] + ", " + question['comparison'],
      'population': exportPopulation(question, recommendation),
      'populationDescription': question['healthProblemOrPopulation'],
      'question': [
        {
          '@id': '_:q1',
          '@type': 'WHOQuestion',
          'intervention': [
            {
              '@id': '_:int1'
            }
          ],
          'comparison': comparison,
          'population': [
            {
              '@id': '_:pop1'
            }
          ],
          'gradeCertaintyOfEvidence': certaintyOfEvidence,
          'gradeCertaintyOfEvidenceLabel': gradeCertaintyOfEvidenceLabel,
          'perspective': perspective
        }
      ],
      'recommendation': {
        'coi': coi,
        'direction': recommendationDirection,
        'strength': recommendationStrength,
        'assessmentSummary': exportAssessmentSummary(recommendation),
        'conclusions': conclusions,
        'value': recommendationText
      },
      'recommendationIntent': _.union(['all'], getRecommendationMetadata(recommendation, 'recommendationIntent')),
      'intendedPopulation': getRecommendationMetadata(recommendation, 'intendedPopulation'),
      'coexistingCondition': coexistingConditions,
      'adapted': getRecommendationMetadata(recommendation, 'adapted', false)[0],
      'informal': getRecommendationMetadata(recommendation, 'informal', false)[0],
      'research': getRecommendationMetadata(recommendation, 'research', false)[0],
      'reportedAsGPS': getRecommendationMetadata(recommendation, 'reportedAsGPS', false)[0],
      'transformed': getRecommendationMetadata(recommendation, 'transformed', false)[0],
      'gps': isGps,
      'diagnostic': isDiagnostic,
      'isofData': isofData,
      'etdPrintout': etdPrintout,
      'plainLanguageSummary': plainLanguageSummary
    };
  });
};

module.exports = exportQuestionAndRecommendation;
