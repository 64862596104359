_.namespace(module, function(require) {
  var GeneralInformationDoc, QUESTIONS_DB_VIEW, QUESTION_GROUPS_DOC_ID, W, exportGuideline, exportQuestionAndRecommendation, groupQuestionsByGroup, mediator, questionStatusPrefix, sequence, _ref, _ref1;
  GeneralInformationDoc = require('lib/db_docs/general_information_doc');
  mediator = require('mediator');
  _ref = require('lib/questions_helper'), QUESTIONS_DB_VIEW = _ref.QUESTIONS_DB_VIEW, (_ref1 = _ref.QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE, QUESTION_GROUPS_DOC_ID = _ref1.regular), groupQuestionsByGroup = _ref.groupQuestionsByGroup;
  W = require('when/when');
  sequence = require('when/sequence');
  exportGuideline = require('lib/services/who_tb_exporter/guideline');
  exportQuestionAndRecommendation = require('lib/services/who_tb_exporter/question');
  questionStatusPrefix = require('lib/doc_prefixes').questionStatus;
  return {
    _getGuidelineDoc: function(projectId) {
      return GeneralInformationDoc.at(projectId).fetch();
    },
    _getQuestionsAndRecommendations: function(projectId) {
      var adapter;
      adapter = mediator.services.storePersistenceAdapter;
      return adapter.fetchWithView(projectId, 'questions', QUESTIONS_DB_VIEW).then(function(questions) {
        return _.filter(questions, function(_arg) {
          var _id;
          _id = _arg._id;
          return _id.indexOf(':adoloped') < 0;
        });
      }).then(function(questions) {
        var recommendationIds;
        recommendationIds = _.chain(questions).pluck('recommendationIds').compact().flatten().value();
        if (_.isEmpty(recommendationIds)) {
          return {
            questions: questions,
            recommendations: {}
          };
        }
        return adapter.fetch(projectId, recommendationIds).then(function(_arg) {
          var recommendations, rows;
          rows = _arg.rows;
          recommendations = _.chain(rows).pluck('doc').compact().map(function(doc) {
            return [doc['_id'], doc];
          }).object().value();
          return {
            questions: questions,
            recommendations: recommendations
          };
        });
      });
    },
    _getQuestionStatuses: function(projectId) {
      var adapter;
      adapter = mediator.services.storePersistenceAdapter;
      return adapter.fetchWithKeyPrefix(projectId, questionStatusPrefix).then(function(_arg) {
        var rows;
        rows = _arg.rows;
        return _.chain(rows).pluck('doc').compact().map(function(doc) {
          return [doc.questionId, doc];
        }).object().value();
      });
    },
    _getGroupsDoc: function(projectId) {
      return mediator.services.storePersistenceAdapter.fetch(projectId, QUESTION_GROUPS_DOC_ID)["catch"](function(err) {
        if (err.status !== 404) {
          throw err;
        }
        return {};
      }).then(Immutable.fromJS);
    },
    _createQuestionOrderMap: function(questions, groups) {
      var grouppedQuestions, questionsInOrder, questionsToPass;
      questionsToPass = Immutable.fromJS(_.map(questions, function(q) {
        return {
          '_id': q['@id']
        };
      }));
      grouppedQuestions = groupQuestionsByGroup(questionsToPass, groups);
      questionsInOrder = grouppedQuestions.reduce(function(acc, group) {
        return acc.concat(group.get('questions').keySeq().toJS());
      }, []);
      return _(questionsInOrder).reduce(function(acc, questionId, idx) {
        acc[questionId] = idx + 1;
        return acc;
      }, {});
    },
    _getOrderNumberForQuestion: function(orderMap, questionsLength, question) {
      var ordering;
      ordering = orderMap[question['@id']];
      if (ordering != null) {
        return ordering;
      }
      ordering = question.orderNumber;
      if (ordering != null) {
        return ordering;
      }
      return questionsLength + 1;
    },
    _putOrderNumberToQuestion: (function(_this) {
      return function(orderMap, questionsLength) {
        return function(question) {
          var orderNumber;
          orderNumber = _this._getOrderNumberForQuestion(orderMap, questionsLength, question);
          return _.extend({}, question, {
            orderNumber: orderNumber
          });
        };
      };
    })(this),
    "export": function(projectId) {
      var promises;
      promises = [this._getGuidelineDoc(projectId), this._getQuestionsAndRecommendations(projectId), this._getQuestionStatuses(projectId), this._getGroupsDoc(projectId)];
      return W.all(promises).then((function(_this) {
        return function(_arg) {
          var exportedGuideline, groupsDoc, guidelineDoc, processQuestion, questionStatuses, questions, recommendations, _ref2;
          guidelineDoc = _arg[0], (_ref2 = _arg[1], questions = _ref2.questions, recommendations = _ref2.recommendations), questionStatuses = _arg[2], groupsDoc = _arg[3];
          exportedGuideline = exportGuideline(projectId, guidelineDoc);
          processQuestion = function(question) {
            return function() {
              return exportQuestionAndRecommendation(question, recommendations, questionStatuses, projectId);
            };
          };
          return sequence(_.map(questions, processQuestion)).then(function(exportedQuestions) {
            var orderMap;
            exportedQuestions = _.chain(exportedQuestions).map(function(question) {
              if (!question) {
                return void 0;
              }
              return _.extend({}, {
                guideline: exportedGuideline
              }, question);
            }).compact().value();
            orderMap = _this._createQuestionOrderMap(exportedQuestions, groupsDoc);
            return _.map(exportedQuestions, _this._putOrderNumberToQuestion(orderMap, exportedQuestions.length));
          });
        };
      })(this));
    }
  };
});
