_.namespace(module, function(require) {
  var FileSaver, mediator;
  mediator = require('mediator');
  FileSaver = require('lib/file_saver');
  return {
    exportProject: function(projectId) {
      var projectDb;
      projectDb = PouchDB(projectId, window.gdt.POUCHDB_OPTIONS);
      return projectDb.allDocs({
        include_docs: true,
        attachments: true
      }).then(function(res) {
        var blob, docAsString, docs;
        docs = _(res.rows).chain().filter(function(r) {
          return !_.string.startsWith(r.id, '_design');
        }).pluck('doc').value();
        docAsString = JSON.stringify(docs);
        blob = new Blob([docAsString], {
          type: 'application/json;charset=utf-8'
        });
        return FileSaver.saveFile(blob, "" + projectId + ".json").otherwise(function(reason) {
          if (reason !== 'cancelled') {
            return mediator.dialogs.error(reason);
          }
        });
      });
    }
  };
});
