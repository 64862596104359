var CloneSharedEtdDocs, Migration, W, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

mediator = require('mediator');

module.exports = CloneSharedEtdDocs = (function(_super) {
  __extends(CloneSharedEtdDocs, _super);

  function CloneSharedEtdDocs() {
    CloneSharedEtdDocs.__super__.constructor.call(this, false);
  }

  CloneSharedEtdDocs.prototype._getQuestionsWithRecommendationIds = function(questions) {
    return _(questions.models).map(function(question) {
      var recommendationIds;
      recommendationIds = question.get('recommendationIds');
      return {
        id: question.id,
        recommendationIds: recommendationIds
      };
    });
  };

  CloneSharedEtdDocs.prototype._cloneEtdDoc = function(projectId, etdDoc) {
    etdDoc.$_cloneInfo = "This EtD doc was cloned during CloneSharedEtdDocs migration from " + etdDoc._id;
    delete etdDoc._id;
    delete etdDoc._rev;
    return mediator.services.storePersistenceAdapter.save(projectId, etdDoc).then(function(doc) {
      return doc._id;
    });
  };

  CloneSharedEtdDocs.prototype._updateQuestion = function(projectId, question) {
    return mediator.services.storePersistenceAdapter.fetch(projectId, question.get('recommendationIds')[0]).then((function(_this) {
      return function(etdDoc) {
        return _this._cloneEtdDoc(projectId, etdDoc).then(function(newEtdId) {
          return question.save({
            recommendationIds: [newEtdId]
          });
        });
      };
    })(this));
  };

  CloneSharedEtdDocs.prototype.up = function(project, colls) {
    var i, intersect, j, k, qIdWithRecs, qSize, question, questionsWithSharedEtds, sharedEtds, _i, _j, _k, _len, _ref, _ref1;
    CloneSharedEtdDocs.__super__.up.apply(this, arguments);
    qIdWithRecs = this._getQuestionsWithRecommendationIds(colls.questions);
    qSize = qIdWithRecs.length;
    sharedEtds = {};
    for (i = _i = 0; 0 <= qSize ? _i < qSize : _i > qSize; i = 0 <= qSize ? ++_i : --_i) {
      for (j = _j = _ref = i + 1; _ref <= qSize ? _j < qSize : _j > qSize; j = _ref <= qSize ? ++_j : --_j) {
        intersect = _(qIdWithRecs[i].recommendationIds).intersection(qIdWithRecs[j].recommendationIds);
        if (intersect.length > 0) {
          _ref1 = [i, j];
          for (_k = 0, _len = _ref1.length; _k < _len; _k++) {
            k = _ref1[_k];
            sharedEtds[qIdWithRecs[k].id] = intersect;
          }
        }
      }
    }
    questionsWithSharedEtds = _(sharedEtds).map(function(recommendationIds, questionId) {
      return colls.questions.get(questionId);
    });
    return W.all((function() {
      var _l, _len1, _results;
      _results = [];
      for (_l = 0, _len1 = questionsWithSharedEtds.length; _l < _len1; _l++) {
        question = questionsWithSharedEtds[_l];
        _results.push(this._updateQuestion(project.id, question));
      }
      return _results;
    }).call(this));
  };

  return CloneSharedEtdDocs;

})(Migration);
