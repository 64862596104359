Translation = require 'components/mixins/translation'

UserGuideDialog = createReactClass
  displayName: "UserGuide"

  mixins: [Translation()]

  getDefaultProps: ->
    section: ''

  componentDidMount: ->
    @_setContentainerHeight()
    window.addEventListener 'resize', @_setContentainerHeight

  _setContentainerHeight: ->
    $('#webpage-content').height $('.etd-templates-help-modal').height() - 50

  componentWillUnmount: ->
    window.removeEventListener 'resize', @_setContentainerHeight

  render: ->
    <div className="webpage-view">
      <iframe id="webpage-content" src="help/user_guide/index.html##{@props.section}" />
      <div className="buttons-block row">
        <div className="col-6">
          <a className="btn btn-block btn-cancel close" onClick={@props.onClose}>
            {@i18n 'actions.close'}
          </a>
        </div>
        <div className="col-6">
          <a className="btn btn-block btn-apply open-in-new-window"
           href="help/user_guide/index.html##{@props.section}" target="_blank">
            {@i18n 'help.open_in_new_window'}
          </a>
        </div>
      </div>
    </div>

module.exports = UserGuideDialog
