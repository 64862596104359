RadioInput = require 'components/common/radio_input'
LabelTextBox = require 'components/projects/label_text_box'
LabelTextEdit = require 'components/projects/label_text_edit'
Translation = require 'components/mixins/translation'
{ instanceOf, string, func } = PropTypes

ProjectLabelForm = createReactClass
  displayName: 'ProjectLabelForm'

  propTypes:
    labelsData: instanceOf(Immutable.Map).isRequired
    projectLabelId: string
    onChangeLabel: func.isRequired
    onChangeLabelText: func.isRequired
    hideTooltip: func.isRequired

  mixins: [ Translation() ]

  _labelRef: (labelId) -> (el) =>
    @["label-#{labelId}"] = el

  onLabelSelect: (evt) ->
    selectedId = evt.target.value
    # process select only if new label is selected
    @props.onChangeLabel selectedId unless selectedId is @props.projectLabelId
    @props.hideTooltip()

  componentWillUnmount: ->
    updatedLabelsData = @props.labelsData.map (labelData, labelId) =>
      updatedText = @["label-#{labelId}"].getText()
      labelData.set 'text', updatedText
    unless updatedLabelsData.equals @props.labelsData
      @props.onChangeLabelText updatedLabelsData

  renderLabel: (label, labelId) ->
    isChecked = labelId is @props.projectLabelId
    checkClass = classNames 'label-check', checked: isChecked

    <div key={labelId} className='label'>
      <RadioInput value={labelId} defaultChecked={isChecked} onChange={@onLabelSelect} />
      <LabelTextEdit ref={@_labelRef labelId} {...label.toJS()} />
    </div>

  render: ->
    { projectLabelId } = @props

    <div className='label-change-tooltip'>
      {@props.labelsData.map(@renderLabel).toList()}
      <div className='label'>
        <RadioInput
          id='no-label'
          value={'none'}
          defaultChecked={projectLabelId is 'none'}
          onChange={@onLabelSelect}
        />
        <label htmlFor='no-label'>{_.str.capitalize @i18n 'none'}</label>
      </div>
    </div>

module.exports = ProjectLabelForm
