var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<menu class=\"button\">\n  <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "nav.goto", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n  <li><a href='"
    + escapeExpression(((helpers.projectRelUrl || (depth0 && depth0.projectRelUrl) || helperMissing).call(depth0, "/most-recent-change", {"name":"projectRelUrl","hash":{},"data":data})))
    + "'>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "nav.most_recent_change", {"name":"i18n","hash":{},"data":data})))
    + "</a></li>\n  <li><a href='"
    + escapeExpression(((helpers.projectRelUrl || (depth0 && depth0.projectRelUrl) || helperMissing).call(depth0, "/my-most-recent-change", {"name":"projectRelUrl","hash":{},"data":data})))
    + "'>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "nav.my_most_recent_change", {"name":"i18n","hash":{},"data":data})))
    + "</a></li>\n</menu>\n<input name=\"search\" class=\"button\" id=\"search-box\" type=\"search\" placeholder='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "nav.search", {"name":"i18n","hash":{},"data":data})))
    + "'>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}