ConnectStore = require 'components/enhancers/connect_store'
GeneralScopeStore = require 'stores/general_scope_store'
GeneralScopeActions = require 'actions/general_scope_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ExportButton = require 'components/scope/scope_export_button'
GeneralScopeTableExportModal = require 'components/scope/general_scope_table_export_modal'
{ bool, instanceOf } = PropTypes

storeConnectors =
  GeneralScopeStore: (GeneralScopeStore) ->
    isShowingExportDialog: GeneralScopeStore.isShowingExportDialog()
    isFetching: GeneralScopeStore.isFetching()
    scope: GeneralScopeStore.getScopeData()

GeneralScopeToolbar = createReactClass
  displayName: 'GeneralScopeToolbar'

  propTypes:
    isShowingExportDialog: bool.isRequired
    isFetching: bool.isRequired
    scope: instanceOf(Immutable.Map).isRequired

  mixins: [
    CustomRenderMixin
  ]

  render: ->
    { isFetching, isShowingExportDialog, scope } = @props

    <div className='scope-toolbar general-scope'>
      {if isShowingExportDialog
        <GeneralScopeTableExportModal
          onClose={GeneralScopeActions.toggleExportDialog}
          scopeData={scope}
        />
      }
      <ExportButton
        onClick={GeneralScopeActions.toggleExportDialog}
        disabled={isShowingExportDialog or isFetching }
      />
    </div>

module.exports = ConnectStore GeneralScopeToolbar, GeneralScopeStore, storeConnectors
