var combineStrategies, matchAny, oneOfStrategies,
  __slice = [].slice;

combineStrategies = function() {
  var strategies;
  strategies = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return R.allPass(strategies);
};

oneOfStrategies = function() {
  var strategies;
  strategies = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return R.anyPass(strategies);
};

matchAny = R.T;

module.exports = {
  combineStrategies: combineStrategies,
  oneOfStrategies: oneOfStrategies,
  matchAny: matchAny
};
