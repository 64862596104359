CommentsBlock = require 'components/scope/comments_block'
Collapsible = require 'components/common/collapsible'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ QUESTION_GENERATION_STEPS } = require 'lib/questions_helper'

GroupedCommentsBlocks = createReactClass
  displayName: 'GroupedCommentsBlocks'

  propTypes:
    itemIdOrKey: PropTypes.string.isRequired
    membersComments: PropTypes.instanceOf(Immutable.Map).isRequired
    membersMap: PropTypes.instanceOf(Immutable.Map).isRequired
    onApplyAdminComment: PropTypes.func.isRequired
    noCollapsePhase: PropTypes.string
    adminComments: PropTypes.instanceOf(Immutable.Map)
    readOnly: PropTypes.bool

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  customApplyAdminComment: (phaseName) ->
    # here we have to use custom admin saving mechanism because this component can have 2 different
    # (for each phase) comments blocks at the same time, so mixin method cannot be used here.
    # outerHandler provides the higher level onApplyaAdminComment handler (calls Action method)
    outerHandler = @props.onApplyAdminComment phaseName
    # return lowlevel handler which is given to component and which has access to outerHandler
    (newText) =>
      idOrKey = @props.itemIdOrKey
      outerHandler { idOrKey, newText }

  # sorts comments by phase name. Latest phase comes first
  _getSortedComments: ->
    return @props.membersComments if @props.membersComments.isEmpty()
    @props.membersComments.toOrderedMap()
      .sortBy (comments, phaseName) -> - QUESTION_GENERATION_STEPS.indexOf phaseName

  _getPhaseTitle: (phaseName) ->
    [ ..., phase ] = phaseName.split '.'
    @i18n "#{phase}_phase"

  renderCommentsBlock: (comments, phaseName) ->
    phaseTitle = @_getPhaseTitle phaseName
    isNoCollapsePhase = phaseName is @props.noCollapsePhase
    caption = <div className='step-name'>{ phaseTitle }</div>
    commentsBlock =
      <CommentsBlock
        className='question-comments-container'
        membersComments={comments}
        adminComment={@props.adminComments?.get phaseName}
        membersMap={@props.membersMap}
        readOnlyComments={@props.readOnly}
        onApplyAdminComment={@customApplyAdminComment phaseName}
      />

    if isNoCollapsePhase
      <div key={phaseName}>
        { caption }
        { commentsBlock }
      </div>
    else
      <div key={phaseName}>
        <Collapsible caption={caption}>
          { commentsBlock }
        </Collapsible>
      </div>

  render: ->
    <div>
      { @_getSortedComments().map(@renderCommentsBlock).toList() }
    </div>

module.exports = GroupedCommentsBlocks
