var RevManWebActions, defaultState, generateStore;

generateStore = require('stores/utils/generate_store');

RevManWebActions = require('actions/revman_web_actions');

defaultState = Immutable.fromJS({
  status: 'syncing',
  error: null
});

module.exports = generateStore({
  name: 'RevManWebStore',
  initialState: defaultState,
  boundActions: RevManWebActions,
  methods: {
    onSyncToRevMan: function() {
      return this.setState(this.state.withMutations(function(s) {
        s.set('status', 'syncing');
        return s.set('error', null);
      }));
    },
    onSyncToRevManSuccess: function() {
      return this.setState(this.state.withMutations(function(s) {
        s.set('status', 'success');
        return s.set('error', null);
      }));
    },
    onSyncToRevManError: function(err) {
      return this.setState(this.state.withMutations(function(s) {
        s.set('status', 'error');
        return s.set('error', err);
      }));
    },
    onSyncToRevManOffline: function() {
      return this.setState(this.state.withMutations(function(s) {
        s.set('status', 'offline');
        return s.set('error', null);
      }));
    },
    getStatus: function() {
      return this.state.get('status');
    },
    getError: function() {
      return this.state.get('error');
    }
  }
});
