var CellFootnotesAndReferencesView, CharactersLimit, ContextMenuView, EditableItemView, HelpBoxView, Question, QuestionProgressView, QuestionView, RevmanWebActions, Router, WysiwygFootnotes, alt, mediator, template, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

EditableItemView = require('base/views/editable_item_view');

template = require('views/templates/evidence_syntheses/question');

Question = require('models/question');

Router = require('router');

mediator = require('mediator');

utils = require('base/lib/utils');

CellFootnotesAndReferencesView = require('views/evidence_syntheses/cell_footnotes_and_references_view');

WysiwygFootnotes = require('lib/traits/wysiwyg_footnotes');

ContextMenuView = require('views/evidence_syntheses/context_menu_view');

HelpBoxView = require('views/help_box_view');

CharactersLimit = require('lib/traits/characters_limit');

QuestionProgressView = require('views/evidence_syntheses/question_progress_view');

RevmanWebActions = require('actions/revman_web_actions');

module.exports = QuestionView = (function(_super) {
  __extends(QuestionView, _super);

  function QuestionView() {
    this.onFocusLost = __bind(this.onFocusLost, this);
    return QuestionView.__super__.constructor.apply(this, arguments);
  }

  QuestionView.prototype.template = template;

  QuestionView.prototype.className = 'question';

  QuestionView.prototype.ellipsisSelector = '.caption a';

  QuestionView.prototype.confirmDelete = false;

  QuestionView.prototype.getBindings = function(tableTitle) {
    var binds;
    binds = {
      '#intervention': 'intervention',
      '#comparison': 'comparison',
      '#healthProblemOrPopulation': 'healthProblemOrPopulation',
      '#questionFormat': {
        observe: 'questionFormat',
        selectOptions: {
          collection: (function() {
            return Question.QUESTION_FORMATS;
          })
        }
      },
      '#tableTitle': 'tableTitle',
      '#bibliography': 'bibliography',
      '#authors': 'authors',
      '#settings': 'settings',
      '#indexTest': 'indexTest',
      '#comparatorTest': 'comparatorTest',
      '#targetCondition': 'targetCondition',
      '#indexTestCutOff': 'indexTestCutOff',
      '#comparatorTestCutOff': 'comparatorTestCutOff',
      '#referenceTest': 'referenceTest',
      '#referenceTestShortName': 'referenceTestShortName',
      '#referenceTestThreshold': 'referenceTestThreshold',
      '#healthCondition': 'healthCondition',
      '#population': {
        observe: 'population',
        selectOptions: {
          collection: this.model.getManagementPopulations
        }
      },
      '#populationOther': 'populationOther',
      '#overTime': 'overTime'
    };
    _(binds).extend(tableTitle ? {
      '.caption a': 'tableTitle'
    } : {
      '.caption a': 'question'
    });
    return binds;
  };

  QuestionView.prototype.footnotesBindings = function() {
    var bindings, property;
    bindings = {
      'intervention': [
        {
          selector: '#intervention ~ .sup.intervention',
          method: 'append'
        }, {
          selector: 'label[for="question"] ~ .sup',
          method: 'after'
        }
      ],
      'indexTest': [
        {
          selector: '#indexTest ~ .sup.indexTest',
          method: 'append'
        }
      ],
      'comparatorTest': [
        {
          selector: '#comparatorTest  ~ .sup.comparatorTest',
          method: 'append'
        }
      ],
      'targetCondition': [
        {
          selector: '#targetCondition ~ .sup.targetCondition',
          method: 'append'
        }
      ],
      'comparison': [
        {
          selector: '#comparison ~ .sup.comparison',
          method: 'append'
        }, {
          selector: 'label[for="question"] ~ .sup',
          method: 'after'
        }
      ],
      'healthProblemOrPopulation': [
        {
          selector: '#healthProblemOrPopulation ~ .sup.healthProblemOrPopulation',
          method: 'append'
        }, {
          selector: 'label[for="question"] ~ .sup',
          method: 'after'
        }
      ],
      'healthCondition': [
        {
          selector: '#healthCondition ~ .sup.healthCondition',
          method: 'append'
        }
      ],
      'populationOther': [
        {
          selector: '#populationOther ~ .sup.populationOther',
          method: 'append'
        }
      ],
      'overTime': [
        {
          selector: '#overTime ~ .sup.overTime',
          method: 'append'
        }
      ],
      'settings': [
        {
          selector: 'label[for="settings"] ~ .sup',
          method: 'append'
        }
      ],
      'indexTestCutOff': [
        {
          selector: 'label[for="indexTestCutOff"] ~ .sup',
          method: 'append'
        }
      ],
      'comparatorTestCutOff': [
        {
          selector: 'label[for="comparatorTestCutOff"] ~ .sup',
          method: 'append'
        }
      ],
      'tableTitle': [
        {
          selector: 'label[for="tableTitle"] ~ .sup',
          method: 'append'
        }, {
          selector: 'label[for="question"] ~ .sup',
          method: 'after'
        }
      ],
      'referenceTest': [
        {
          selector: 'label[for="referenceTest"] ~ .sup',
          method: 'append'
        }
      ],
      'referenceTestShortName': [
        {
          selector: 'label[for="referenceTestShortName"] ~ .sup',
          method: 'append'
        }
      ],
      'referenceTestThreshold': [
        {
          selector: 'label[for="referenceTestThreshold"] ~ .sup',
          method: 'append'
        }
      ],
      'bibliography': [
        {
          selector: 'label[for="bibliography"] ~ .sup',
          method: 'append'
        }
      ],
      'authors': [
        {
          selector: 'label[for=authors] ~ .sup',
          method: 'append'
        }
      ]
    };
    for (property in bindings) {
      bindings[property].push({
        selector: '.question-footnotes-attr',
        method: 'append'
      });
    }
    return bindings;
  };

  QuestionView.prototype.initialize = function() {
    QuestionView.__super__.initialize.apply(this, arguments);
    this.bindings = this.getBindings(this.options.tableTitle);
    this._delegateEvents();
    this.modelBind('change:intervention change:comparison change:healthProblemOrPopulation', (function(_this) {
      return function() {
        return _this.publishEvent('questionsListUpdated');
      };
    })(this));
    this.modelBind('destroy', this.questionDeleted);
    this.modelBind('change:population', this._togglePopulationOther);
    this.enable(WysiwygFootnotes);
    this.enable(CharactersLimit, {
      limits: [
        {
          selector: '#referenceTestShortName',
          limit: 100
        }
      ]
    });
    return this.subview('questionProgress', new QuestionProgressView({
      model: this.model,
      parentView: this
    }));
  };

  QuestionView.prototype._delegateEvents = function() {
    this.delegate('click', 'button.save-proceed', this._saveAndProceed);
    this.delegate('click', 'button.duplicate', this._duplicate);
    this.delegate('click', '#edit-title', this._editTitle);
    this.delegate('focus', '.separated input', function(e) {
      return _.defer(function() {
        return $(e.target).select();
      });
    });
    this.delegate('focus', 'input, textarea, select', this._propertyActivated);
    this.delegate('click', '.disabled-sof-title > div', function(e) {
      this.$("[name=" + this.property + "]").blur();
      return this._propertyActivated(e);
    });
    this.delegate('click', '.add-comparator-test', this._addComparatorTest);
    this.delegate('click', '.remove-comparator-test', this._removeComparatorTest);
    this.delegate('contextmenu', 'input, textarea, select', this.showContextMenu);
    this.delegate('contextmenu', '.disabled-sof-title > div', this.showContextMenu);
    return this.delegate('click', 'button.delete-question', this.deleteQuestion);
  };

  QuestionView.prototype._togglePopulationOther = function() {
    var population;
    population = this.model.get('population');
    if (population === 'other') {
      this.$('span.populationOther').show();
    } else {
      this.$('span.populationOther').hide();
    }
    if (_.isEmpty(population) || population === 'other') {
      return this.$('select.prognostic').removeClass('default');
    } else {
      return this.$('select.prognostic').addClass('default');
    }
  };

  QuestionView.prototype.focusInput = function() {
    return this.$('#intervention, #indexTest').focus();
  };

  QuestionView.prototype._addComparatorTest = function() {
    this.model.set('comparatorTestPresent', true);
    return this.render();
  };

  QuestionView.prototype._removeComparatorTest = function() {
    this.model.set('comparatorTestPresent', false);
    return this.render();
  };

  QuestionView.prototype._propertyActivated = function(e) {
    this.property = $(e.target).attr('name');
    return this.publishEvent('activatedProperty', this.property, $(e.target), this.model);
  };

  QuestionView.prototype._editTitle = function() {
    var toggleManualTitle;
    if (!this.editing) {
      return;
    }
    toggleManualTitle = (function(_this) {
      return function(msg, confirmText, declineText, isManualTitle) {
        return mediator.dialogs.confirm({
          yesClass: '',
          noClass: 'cancel',
          message: msg,
          confirmText: confirmText,
          declineText: declineText
        }, function(confirmed) {
          if (confirmed) {
            _this.model.set('manualTableTitle', isManualTitle);
            if (!isManualTitle) {
              _this.model.set(_this.model.calculateTitles());
            }
            return _this.render();
          } else {
            return _this.focusInput();
          }
        });
      };
    })(this);
    if (this.model.get('manualTableTitle')) {
      return toggleManualTitle($.t('es:question.switch_to_auto_table_title_warning'), $.t('es:question.change_to_auto_title'), $.t('es:question.keep_manual_title'), false);
    } else {
      return toggleManualTitle($.t('es:question.switch_to_manual_table_title_warning'), $.t('es:question.change_to_manual_title'), $.t('es:question.keep_auto_title'), true);
    }
  };

  QuestionView.prototype.showContextMenu = function(e) {
    mediator.setFocus(this, (function(_this) {
      return function() {
        var $target, contextMenu;
        $target = $(e.target);
        contextMenu = new ContextMenuView({
          questionModel: _this.model,
          footnotesBindingModel: _this.model,
          property: _this.property,
          propertyView: _this,
          cell: $target,
          abortHandler: function() {
            return mediator.setFocus(_this);
          }
        });
        _this.subview('contextMenu', contextMenu);
        return contextMenu.show(utils.getEventCoordinates(e));
      };
    })(this));
    return false;
  };

  QuestionView.prototype.isAbandoningChanges = function(focusTo) {
    return !_["instanceof"](focusTo, [HelpBoxView, CellFootnotesAndReferencesView]) && (this.model.isNew() || this.model.isDirty());
  };

  QuestionView.prototype._saveAndProceed = function() {
    return this.saveChanges().then((function(_this) {
      return function() {
        return mediator.publish('!router:route', Router.prototype.getProjectRelativeUrl("/evidence-syntheses/" + _this.model.id));
      };
    })(this));
  };

  QuestionView.prototype._duplicate = function() {
    return this.saveChanges().then((function(_this) {
      return function() {
        return _this.model.collection.create(_this.model.duplicate());
      };
    })(this));
  };

  QuestionView.prototype.onFocusLost = function(focusTo) {
    var allowedToBeFocused;
    allowedToBeFocused = [CellFootnotesAndReferencesView, ContextMenuView, HelpBoxView];
    if (!_["instanceof"](focusTo, allowedToBeFocused)) {
      this._close();
    }
    return this.publishEvent('deactivatedProperty');
  };

  QuestionView.prototype.afterRender = function() {
    var _ref;
    QuestionView.__super__.afterRender.apply(this, arguments);
    this.stickFootnotes(this.model);
    if (this.model.get('comparatorTestPresent')) {
      this.$('button.add-comparator-test').remove();
    }
    this.$el.attr('data-question-id', this.model.id);
    this.$el.attr('data-question-order-number', this.model.get('orderNumber'));
    this._setDiagnosticQuestionClass();
    this._togglePopulationOther();
    return (_ref = this.subview('questionProgress')) != null ? _ref.render() : void 0;
  };

  QuestionView.prototype._setDiagnosticQuestionClass = function() {
    var _base;
    if (typeof (_base = this.model).isDiagnostic === "function" ? _base.isDiagnostic() : void 0) {
      if (this.model.get('comparatorTestPresent')) {
        this.$el.addClass('diagnostic-comparator');
        return this.$el.removeClass('diagnostic-index');
      } else {
        this.$el.addClass('diagnostic-index');
        return this.$el.removeClass('diagnostic-comparator');
      }
    }
  };

  QuestionView.prototype.getTemplateData = function() {
    var data, _ref;
    data = QuestionView.__super__.getTemplateData.apply(this, arguments);
    return _(data).extend({
      isPrintout: this.options.isPrintout,
      viewTypeName: this.options.viewTypeName,
      tableTitle: this.options.tableTitle,
      lastUpdate: (_ref = data.$timestamp) != null ? _ref : data.lastUpdate
    });
  };

  QuestionView.prototype.questionDeleted = function(deletedQuestion) {
    var _ref;
    if (deletedQuestion.isNew()) {
      return;
    }
    return (_ref = this.parentView) != null ? typeof _ref.questionDeleted === "function" ? _ref.questionDeleted(deletedQuestion) : void 0 : void 0;
  };

  QuestionView.prototype.deleteQuestion = function() {
    var dialog, revmanWebReviewId, revmanWebTableId;
    revmanWebReviewId = this.model.get('revManWebReviewId');
    revmanWebTableId = this.model.get('revManWebTableId');
    if ((revmanWebReviewId != null) && (revmanWebTableId != null)) {
      dialog = {
        message: $.t('archie:revman.question_delete.confirm'),
        declineText: $.t('actions.cancel'),
        confirmText: $.t('actions.delete')
      };
      return mediator.dialogs.confirm(dialog, (function(_this) {
        return function(confirmed) {
          if (!confirmed) {
            return;
          }
          return RevmanWebActions.deleteTable(revmanWebReviewId, revmanWebTableId).then(function() {
            return _this.model.destroy({
              wait: true
            }).then(function() {
              var route;
              route = Router.prototype.getProjectRelativeUrl('/evidence-syntheses');
              return mediator.publish('!router:route', route);
            });
          })["catch"](function(err) {
            var _ref;
            if ((_ref = window.gdt) != null ? _ref.debug : void 0) {
              console.error(err);
            } else {
              utils.reportRavenError(err);
            }
            return mediator.dialogs.error({
              message: $.t('archie:revman.question_delete.error')
            });
          });
        };
      })(this));
    } else {
      dialog = {
        message: $.t('es:confirm_delete'),
        declineText: $.t('actions.cancel'),
        confirmText: $.t('actions.delete')
      };
      return mediator.dialogs.confirm(dialog, (function(_this) {
        return function(confirmed) {
          var path;
          if (!confirmed) {
            return;
          }
          path = _this.model.isPrognostic() ? '/prognosis' : '/evidence-syntheses';
          return _this.model.destroy({
            wait: true
          }).then(function() {
            var route;
            route = Router.prototype.getProjectRelativeUrl(path);
            return mediator.publish('!router:route', route);
          });
        };
      })(this));
    }
  };

  QuestionView.prototype.dispose = function() {
    this.unstickFootnotes();
    return QuestionView.__super__.dispose.apply(this, arguments);
  };

  return QuestionView;

})(EditableItemView);
