var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"inline\">\n  <div>\n    "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "designStudies", ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.fullName : stack1), "ObsStudies.cohort_studies: cohort_studies,\n      ObsStudies.single_arm_studies: single_arm_studies", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n  </div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}