var DbHelper, W, appUtils, convertToList, createTag, debouncePromise, generateGUID, getCleanCodeLabel, getCodeLabel, getGrouppedTagsFromQuestion, includedTagAdder, includedTagRemover, prepareTags, searchCode, stripHTML,
  __slice = [].slice;

appUtils = require('lib/app_utils');

DbHelper = require('base/lib/db_helper');

generateGUID = require('base/lib/utils').generateGUID;

W = require('when/when');

prepareTags = function(tags) {
  return _.map(tags, function(tag) {
    return {
      id: generateGUID(),
      codes: {},
      name: '',
      type: tag,
      included: true
    };
  });
};

createTag = function(id, tagKey, question) {
  var qTagKey;
  if (question == null) {
    question = null;
  }
  qTagKey = (function() {
    switch (tagKey) {
      case 'population':
        return 'healthProblemOrPopulation';
      default:
        return tagKey;
    }
  })();
  return Immutable.fromJS({
    id: id,
    name: question ? question.get(qTagKey, "") : "",
    questionId: question ? question.get('questionId') : null,
    included: true,
    codes: {},
    type: tagKey
  });
};

includedTagAdder = function(question, tagKey) {
  return function(tags) {
    if (tags == null) {
      tags = Immutable.List();
    }
    return tags.push(createTag(generateGUID(), tagKey, question));
  };
};

includedTagRemover = function(questionId) {
  return function(tags) {
    if (tags == null) {
      tags = Immutable.List();
    }
    return tags.filter(function(tag) {
      return tag.get('questionId') !== questionId;
    });
  };
};

getGrouppedTagsFromQuestion = function(question) {
  return question.get('includedTags', Immutable.List()).groupBy(function(tag) {
    return tag.get('type');
  });
};

searchCode = function(field, searchQuery, startAt, limitAt, sorting) {
  if (startAt == null) {
    startAt = 0;
  }
  if (limitAt == null) {
    limitAt = null;
  }
  if (sorting == null) {
    sorting = 'regular';
  }
  return W($.ajax({
    type: 'POST',
    data: JSON.stringify({
      field: field,
      searchQuery: searchQuery,
      startAt: startAt,
      limitAt: limitAt,
      sorting: sorting
    }),
    contentType: 'application/json',
    xhrFields: {
      withCredentials: true
    },
    url: "" + (DbHelper.getBackendUrl()) + "/dbep/_codes/_search"
  }))["catch"](appUtils.errorHandler);
};

stripHTML = function(str) {
  if (str == null) {
    str = "";
  }
  return str.replace(/<[^>]*>?/gm, '');
};

getCodeLabel = function(code) {
  return "" + (code.get('value')) + " " + (code.get('name'));
};

getCleanCodeLabel = function(code) {
  var label;
  label = stripHTML(code.get('value'));
  if (!_.isEmpty(code.get('name'))) {
    label += " - " + (stripHTML(code.get('name')));
  }
  return label;
};

convertToList = function(tags) {
  if (_.isObject(tags)) {
    return _.toArray(tags);
  } else {
    return tags;
  }
};

debouncePromise = function(f, interval) {
  var timer;
  timer = null;
  return function() {
    var args;
    args = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
    clearTimeout(timer);
    return new Promise(function(resolve) {
      return timer = setTimeout(function() {
        return resolve(f.apply(null, args));
      }, interval);
    });
  };
};

module.exports = {
  createTag: createTag,
  getCleanCodeLabel: getCleanCodeLabel,
  getCodeLabel: getCodeLabel,
  getGrouppedTagsFromQuestion: getGrouppedTagsFromQuestion,
  includedTagAdder: includedTagAdder,
  includedTagRemover: includedTagRemover,
  prepareTags: prepareTags,
  searchCode: debouncePromise(searchCode, 300),
  stripHTML: stripHTML
};
