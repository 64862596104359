Button = require 'components/common/button'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
ConnectStore = require 'components/enhancers/connect_store'
EllipsizedText = require 'components/common/ellipsized_text'
MdaTopicsActions = require 'actions/mda_topics_actions'
mediator = require 'mediator'
ProgressSelect = require 'components/evidence_syntheses/progress_select'
QuestionsActions = require 'actions/questions_actions'
QuestionsStatusesStore = require 'stores/questions_statuses_store'
Router = require 'router'
{ TOPIC_STATUSES } = require 'lib/mda_helper'
{ useCoffeeCallback, useI18n, useCoffeeMemo } = require 'lib/react_utils'
{ getMemberFullName, isCurrentUserAdmin } = require 'lib/members_helper'

storeConnector =
  QuestionsStatusesStore: (Store, props) ->
    questionStatuses: Store.getQuestionStatuses props.data.get '_id'

MdaTopicsListItem = ({ data: topic, questionStatuses }) ->

  i18n = useI18n('mda:topics')

  handleEdit = useCoffeeCallback [], (topic) -> ->
    MdaTopicsActions.openCreateOrEditDialog topic

  handleDelete = useCoffeeCallback [topic], ->
    QuestionsActions.deleteQuestion topic.get '_id'

  handleDuplicate = useCoffeeCallback [topic], ->
    QuestionsActions.duplicateQuestion questionId: topic.get '_id'

  userAccessRights = mediator.user.getAccessRights()

  { controls, isAdmin } = useCoffeeMemo [topic, userAccessRights], ->
    isAdmin = _.contains userAccessRights, 'admin'
    controls = if isAdmin
      [
        name: 'edit'
        handler: handleEdit topic
      ,
        name: 'duplicate'
        handler: handleDuplicate
      ,
        name: 'delete'
        handler: handleDelete
      ]
    else []
    { controls, isAdmin }

  currentStatus = useCoffeeMemo [questionStatuses], ->
    questionStatuses.get('mdaTopicsStatus', Immutable.Map())

  currentUserEmail = useCoffeeMemo [], -> mediator.user.getEmail().toLowerCase()

  showClaimButton = useCoffeeMemo [currentStatus, topic], ->
    status = currentStatus.get('status')
    # #user must have MAB access rights
    _.contains(mediator.user.getAccessRights(), 'mda_mab') and
    (
      # checking if status is right and user wasn't already assigned as a second MAB reviewer
      (status is 'durationReview1' and _.isEmpty(topic.getIn(['members', 'mab1'])) and
        topic.getIn(['members', 'mab2', 'email'], '').toLowerCase() isnt currentUserEmail
      ) or
      (status is 'durationReview2' and _.isEmpty(topic.getIn(['members', 'mab2'])) and
        topic.getIn(['members', 'mab1', 'email'], '').toLowerCase() isnt currentUserEmail
      ) or
      (status is 'durationReview3' and _.isEmpty(topic.getIn(['members', 'mab3'])) and
        topic.getIn(['members', 'mab1', 'email'], '').toLowerCase() isnt currentUserEmail and
        topic.getIn(['members', 'mab2', 'email'], '').toLowerCase() isnt currentUserEmail
      )
    )

  canEnterTopic = useCoffeeMemo [topic, userAccessRights, currentUserEmail], ->
    if _.intersection(userAccessRights, ['admin', 'mda_mar', 'mda_editor']).length isnt 0
      return true
    else
      topic.getIn(['members', 'mab1', 'email'], '').toLowerCase() is currentUserEmail or
      topic.getIn(['members', 'mab2', 'email'], '').toLowerCase() is currentUserEmail or
      topic.getIn(['members', 'mab3', 'email'], '').toLowerCase() is currentUserEmail

  handleClick = useCoffeeCallback [topic, canEnterTopic], ->
    if canEnterTopic
      link = Router::routeForSubmodule 'mda-topics', null, questionId: topic.get '_id'
      mediator.publish '!router:route', link
    else
      mediator.dialogs.infoBox null,
        message: i18n 'claim_topic_first'

  onOpenAssignModal = useCoffeeCallback [topic], (memberType) -> ->
    MdaTopicsActions.openAssignMemberDialog { topic, memberType }

  claimTopic = useCoffeeCallback [topic, currentStatus], ->
    MdaTopicsActions.claimTopic { currentStatus: currentStatus.get('status', null), topic }
    .then ->
      link = Router::routeForSubmodule 'mda-topics', null, questionId: topic.get '_id'
      mediator.publish '!router:route', link

  assignOptions = useCoffeeMemo [topic, currentStatus], ->
    baseOptions = ['editor', 'mar', 'mab1', 'mab2']
    return baseOptions if currentStatus.get('status', null) isnt 'durationReview3' and
      (topic.getIn(['members', 'mab3']) or Immutable.Map()).isEmpty()
    baseOptions.concat 'mab3'

  <CollapsibleWithControls controls={controls}>
    <div className="question-text flex flex-row items-center">
      <div onClick={handleClick} className="flex-grow cursor-pointer">
        <EllipsizedText height={40} text={topic.get('name') or ''} />
      </div>
      <div>
        <ProgressSelect
          questionId={topic.get '_id'}
          questionStatuses={questionStatuses}
          showLabel={false}
          statusesList={Immutable.fromJS(TOPIC_STATUSES)}
          groupKey='mdaTopicsStatus'
          withCompleted={false}
          editable={isAdmin}
        />
      </div>
      {showClaimButton &&
        <button className="mda-claim-button" onClick={claimTopic}>
          {i18n 'claim'}
        </button>
      }
    </div>
    {if isAdmin
      <div>
        <div className="flex flex-row">
           {_.map(assignOptions, (memberType) ->
            <MdaMemberAssign
              key={memberType}
              type={memberType}
              topicId={topic.get('_id')}
              assignedMember={topic.getIn(['members', memberType], Immutable.Map())}
              onOpenAssignModal={onOpenAssignModal}
            />
          )}
        </div>
      </div>
    else
      <div className="p-5" />
    }
  </CollapsibleWithControls>

MdaTopicsListItem.propTypes =
  data: PropTypes.instanceOf(Immutable.Map)
  questionStatuses: PropTypes.instanceOf(Immutable.Map)

MdaMemberAssign = ({ type, topicId, assignedMember, onOpenAssignModal }) ->
  i18n = useI18n('mda:topics')

  unassignMember = useCoffeeCallback [topicId], (memberType) -> ->
    MdaTopicsActions.unassignMember { memberType, topicId }

  <div className="member-assign-row flex flex-row flex-grow items-center">
    <div className="mr-5">{i18n("assign.#{type}")}</div>
    <div>
    {if not assignedMember || assignedMember.isEmpty()
      <Button
        className="btn-round bg-transparent"
        label={i18n("/actions.assign")}
        onClick={onOpenAssignModal(type)}
      />
    else
      <div>
        {getMemberFullName(assignedMember)}
        <button className="member-assign-row__unassign-member" onClick={unassignMember(type)}>
          &times;
        </button>
      </div>
    }
    </div>
  </div>

MdaMemberAssign.propTypes =
  type: PropTypes.oneOf(['editor', 'mar', 'mab1', 'mab2', 'mab3']).isRequired
  onOpenAssignModal: PropTypes.func.isRequired
  assignedMember: PropTypes.instanceOf(Immutable.Map)

MdaMemberAssign.defaultProps =
  assignedMember: null

module.exports = ConnectStore MdaTopicsListItem, [QuestionsStatusesStore], storeConnector
