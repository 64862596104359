var OverarchingQuestionRecommendationsView, OverarchingQuestionsRecommendations, OverarchingToolbar, ReactComponent, ReactToolbarView, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

OverarchingToolbar = require('components/overarching_questions/overarching_toolbar');

OverarchingQuestionsRecommendations = require('components/overarching_questions/overarching_question_recommendations');

mediator = require('mediator');

module.exports = OverarchingQuestionRecommendationsView = (function(_super) {
  __extends(OverarchingQuestionRecommendationsView, _super);

  function OverarchingQuestionRecommendationsView() {
    return OverarchingQuestionRecommendationsView.__super__.constructor.apply(this, arguments);
  }

  OverarchingQuestionRecommendationsView.prototype.container = '#page-container';

  OverarchingQuestionRecommendationsView.prototype.autoRender = true;

  OverarchingQuestionRecommendationsView.prototype.initialize = function() {
    OverarchingQuestionRecommendationsView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: OverarchingToolbar
    }));
    this._stopInputsPropagation = false;
    return this.enable(ReactComponent);
  };

  OverarchingQuestionRecommendationsView.prototype.afterRender = function() {
    var projectId, questionId;
    OverarchingQuestionRecommendationsView.__super__.afterRender.apply(this, arguments);
    projectId = mediator.project.id;
    questionId = this.options.id;
    return mediator.services.storePersistenceAdapter.fetch(projectId, questionId).then(function(questionDoc) {
      return _.last(questionDoc.recommendationIds);
    }).then((function(_this) {
      return function(etdId) {
        var metadocId;
        metadocId = "" + projectId + ":panel-voice-metadoc";
        return mediator.services.storePersistenceAdapter.fetch(projectId, metadocId)["catch"](function(err) {
          if (err.status !== 404) {
            throw err;
          }
          return {
            etds: {}
          };
        }).then(function(panelVoiceMetadoc) {
          var props;
          props = etdId in panelVoiceMetadoc.etds ? {
            questionId: questionId,
            panelVoice: true
          } : {
            questionId: questionId
          };
          return _this.renderSimpleComponent(OverarchingQuestionsRecommendations, props);
        });
      };
    })(this));
  };

  OverarchingQuestionRecommendationsView.prototype.dispose = function() {
    this.unmountComponent();
    return OverarchingQuestionRecommendationsView.__super__.dispose.apply(this, arguments);
  };

  return OverarchingQuestionRecommendationsView;

})(View);
