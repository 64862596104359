AgeField = require 'components/mdg_tables/age_field'
CoIField = require 'components/mdg_tables/coi_field'
ConfirmationModal = require 'components/common/confirmation_modal'
IconButton = require 'components/common/icon_button'
{ comparativeTests, MDGTabs, tableTemplates } = MDGTables
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
{ MDG_QUESTION_TYPES } = require 'lib/mdg_helper'
MDGTableActions = require 'actions/mdg_table_actions'
ReferenceIdField = require 'components/mdg_tables/reference_id_field'
SampleSizeField = require 'components/mdg_tables/sample_size_field'
ScoreField = require 'components/mdg_tables/score_field'
SexField = require 'components/mdg_tables/sex_field'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'
{ useState } = React

studyShape = PropTypes.shape(
  age: PropTypes.shape(
    type: PropTypes.oneOf(['mean', 'range', 'other']).isRequired
    value: PropTypes.string.isRequired
    valueFrom: PropTypes.string.isRequired
    valueTo: PropTypes.string.isRequired
  ).isRequired
  areaOfBody: PropTypes.string.isRequired
  caseDefinition: PropTypes.string.isRequired
  category: PropTypes.string.isRequired
  coi: PropTypes.shape(
    selected: PropTypes.bool
    value: PropTypes.string.isRequired
  ).isRequired
  comments: PropTypes.string.isRequired
  internalComments: PropTypes.string
  comparativeTests: PropTypes.arrayOf(PropTypes.string).isRequired
  comparisons: PropTypes.string.isRequired
  conclusion: PropTypes.string.isRequired
  diagnoses: PropTypes.string.isRequired
  dropoutRate: PropTypes.string.isRequired
  exposure: PropTypes.string.isRequired
  followUp: PropTypes.string.isRequired
  id: PropTypes.string.isRequired
  location: PropTypes.string.isRequired
  longTermFollowUp: PropTypes.string.isRequired
  mriOrCt: PropTypes.string.isRequired
  referenceId: PropTypes.string.isRequired
  results: PropTypes.string.isRequired
  sampleSize: PropTypes.shape(
    count: PropTypes.number
    description: PropTypes.string.isRequired
  ).isRequired
  score: PropTypes.shape(
    criteria: PropTypes.objectOf(PropTypes.oneOf([0, 0.5, 1])).isRequired
    epidemiologicalCriteria: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
    value: PropTypes.number.isRequired
  ).isRequired
  sex: PropTypes.arrayOf(PropTypes.shape(
    count: PropTypes.number
    name: PropTypes.string
    selected: PropTypes.bool.isRequired
    value: PropTypes.string.isRequired
  )).isRequired
  shortName: PropTypes.string.isRequired
  spectOrSpet: PropTypes.string.isRequired
  studyDesign: PropTypes.string.isRequired
  studyType: PropTypes.string.isRequired
  typeOfCT: PropTypes.string.isRequired
  typeOfMRI: PropTypes.string.isRequired
  typeOfXRays: PropTypes.string.isRequired
)

CUSTOM_FIELDS =
  age: AgeField
  coi: CoIField
  referenceId: ReferenceIdField
  sampleSize: SampleSizeField
  score: ScoreField
  sex: SexField

TEXTAREA_FIELDS = [
  'results'
  'conclusion'
  'comments'
  'internalComments'
]

TableEditTab = ({ data, fields, onChange, questionType }) ->
  i18n = useI18n('mdg_tables:edit_form')
  form = useCoffeeMemo [fields, questionType], -> _.map fields, (fieldKey) ->
    if fieldKey is 'comparativeTests'
      options = comparativeTests[questionType]
      { fieldKey, options, showClear: false, type: 'checkboxes' }
    else if fieldKey of CUSTOM_FIELDS
      {
        component: CUSTOM_FIELDS[fieldKey],
        fieldKey,
        showClear: false,
        type: 'custom',
        additionalProps: { questionType, comments: data.comments or "" }
      }
    else if fieldKey in TEXTAREA_FIELDS
      { fieldKey, showClear: false, type: 'textarea' }
    else
      { fieldKey, showClear: false, type: 'string' }

  <ExtractionForm
    className="mdg-tables-edit-form"
    data={data}
    form={main: form}
    i18n={i18n}
    onChange={onChange}
  />

TableEditTab.propTypes =
  data: studyShape.isRequired
  fields: PropTypes.arrayOf(PropTypes.string).isRequired
  onChange: PropTypes.func.isRequired
  questionType: PropTypes.oneOf(MDG_QUESTION_TYPES).isRequired

TableEditRowControls = ({ questionId, studyId }) ->
  i18n = useI18n()

  [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)

  onCancelDelete = useCoffeeCallback [setDeleteConfirmationOpen], ->
    setDeleteConfirmationOpen false

  onConfirmDelete = useCoffeeCallback [
    MDGTableActions
    setDeleteConfirmationOpen
    questionId
    studyId
  ], ->
    setDeleteConfirmationOpen false
    MDGTableActions.deleteRow questionId, studyId

  onDelete = useCoffeeCallback [setDeleteConfirmationOpen], ->
    setDeleteConfirmationOpen true

  onDuplicate = useCoffeeCallback [MDGTableActions, questionId, studyId], ->
    MDGTableActions.duplicateRow questionId, studyId

  onSave = useCoffeeCallback [MDGTableActions, studyId], ->
    MDGTableActions.saveRow studyId

  <div className="mdg-table-edit-component__controls">
    <IconButton
      iconName="delete"
      label={i18n 'actions.delete'}
      labelPosition="right"
      onClick={onDelete}
    />
    <IconButton
      iconName="duplicate"
      label={i18n 'actions.duplicate_short'}
      labelPosition="right"
      onClick={onDuplicate}
    />
    <IconButton
      iconName="save"
      label={i18n 'actions.save'}
      labelPosition="right"
      onClick={onSave}
    />
    <ConfirmationModal
      isOpen={isDeleteConfirmationOpen}
      onCancel={onCancelDelete}
      onConfirm={onConfirmDelete}
      confirmLabel={i18n 'actions.delete'}
      message={i18n 'mdg_tables:tables.edit_table.delete_message'}
      question={i18n 'mdg_tables:tables.edit_table.delete_confirmation'}
    />
  </div>

TableEditRowControls.propTypes =
  questionId: PropTypes.string.isRequired
  studyId: PropTypes.string.isRequired

TableEditRow = ({ questionId, questionType, study }) ->
  { id: studyId } = study

  i18n = useI18n('mdg_tables:tables.edit_table')

  onChange = useCoffeeCallback [MDGTableActions, questionId, studyId], (field, value) ->
    MDGTableActions.updateStudy questionId, studyId, field, value

  TABS = _.map tableTemplates[questionType]?.table?.editTabs ? [], ({ fields, tabNameI18nKey }) ->
    name: i18n tabNameI18nKey
    renderComponent: ->
      <TableEditTab
        data={study}
        fields={fields}
        onChange={onChange}
        questionType={questionType}
      />

  <div className="mdg-table-edit-component">
    <MDGTabs
      controls={<TableEditRowControls questionId={questionId} studyId={study.id} />}
      tabs={TABS}
    />
  </div>

TableEditRow.propTypes =
  questionId: PropTypes.string.isRequired
  questionType: PropTypes.oneOf(MDG_QUESTION_TYPES).isRequired
  study: studyShape.isRequired

module.exports = TableEditRow
