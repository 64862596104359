var Exceptions, Model, ProjectMigrationService, W, mediator, sequence;

Model = require('models/base/model');

sequence = require('when/sequence');

Exceptions = require('lib/exceptions');

mediator = require('mediator');

W = require('when');

module.exports = ProjectMigrationService = (function() {
  function ProjectMigrationService(migrationsList) {
    this.migrationsList = migrationsList;
  }

  ProjectMigrationService.prototype._runStep = function(migrationOrConstructor, direction, project, colls, i) {
    return function() {
      var migration;
      if (_.isFunction(migrationOrConstructor)) {
        migration = new migrationOrConstructor();
      } else {
        migration = migrationOrConstructor;
      }
      return migration[direction].call(migration, project, colls).then(function(result) {
        return project.save('$data_version', i + 1).then(function() {
          if (result != null ? result.message : void 0) {
            return mediator.dialogs.info({
              message: result.message,
              duration: 'none'
            });
          }
        });
      }, function(error) {
        mediator.user.getRequests().create({
          type: 'incident',
          error: error.message,
          stack: typeof Raven !== "undefined" && Raven !== null ? Raven.TraceKit.computeStackTrace(error).stack : void 0,
          migration: migration.title,
          email: mediator.user.get('email'),
          project: project.id
        });
        throw Exceptions.migration_failed(migration.title);
      });
    };
  };

  ProjectMigrationService.prototype.upgrade = function(project, colls) {
    var current, end, i, _ref;
    current = (_ref = project.get('$data_version')) != null ? _ref : 0;
    if (current === -1) {
      return W.resolve();
    }
    end = this.getCurrentDataVersion();
    if (current >= end) {
      return W.resolve();
    } else {
      Model.prototype.migrationMode = true;
      return sequence((function() {
        var _i, _results;
        _results = [];
        for (i = _i = current; current <= end ? _i < end : _i > end; i = current <= end ? ++_i : --_i) {
          _results.push(this._runStep(this.migrationsList[i], 'up', project, colls, i));
        }
        return _results;
      }).call(this)).ensure(function() {
        return delete Model.prototype.migrationMode;
      });
    }
  };

  ProjectMigrationService.prototype.getCurrentDataVersion = function() {
    return this.migrationsList.length;
  };

  return ProjectMigrationService;

})();
