EtdActions = require 'actions/etd_actions'
PanelVoiceEtdActions = require 'actions/panel_voice_etd_actions'
Modal = require 'components/common/modal'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ToggleSwitch = require 'components/common/toggle_switch'
Judgement = require 'components/etd/judgements/judgement'
EtdEditor = require 'components/etd/etd_editor'
Translation = require 'components/mixins/translation'
ReferenceCitationMixin = require 'components/mixins/reference_citation_mixin'
ReferencesInsertModal = require 'components/etd/references_insert_modal'
PanelVoiceEtdSectionEditModal = require 'components/mixins/panel_voice_etd_section_edit_modal_mixin'
{ getSectionEditToggleHandler } = require 'lib/panel_voice_helper'
GdtEditor = Editor.Editor
{ func, instanceOf, string, bool } = PropTypes

{ Card, CardContent } = ReactComponents

AssessmentSectionEditModal = createReactClass
  displayName: 'AssessmentSectionEditModal'

  propType:
    criterion: instanceOf(Immutable.Map).isRequired
    researchEvidences: instanceOf(Immutable.Map).isRequired
    additionalConsiderations: instanceOf(Immutable.Map).isRequired
    attachments: instanceOf(Immutable.Map).isRequired
    sectionName: string.isRequired
    criticalOutcomes: instanceOf(Immutable.List).isRequired
    outcomes: instanceOf(Immutable.List).isRequired
    overarchingQuestionData: instanceOf(Immutable.Map)
    sectionId: string.isRequired
    sectionData: instanceOf(Immutable.Map).isRequired
    questionType: string.isRequired
    renderMode: string.isRequired
    highlightToPanelMembers: bool
    templateId: string.isRequired

  getDefaultProps: ->
    overarchingQuestionData: Immutable.Map()

  mixins: [
    Translation('es:recommendations.table')
    PanelVoiceEtdSectionEditModal
    ReferenceCitationMixin('additionalConsiderationsEditor')
  ]

  researchEvidencesEditorRef: (el) ->
    @researchEvidencesEditor = el

  additionalConsiderationsEditorRef: (el) ->
    @additionalConsiderationsEditor = el

  onClose: ->
    PanelVoiceEtdActions.toggleEtdSectionEdit @props.sectionId

  saveChanges: ->
    # save research evidences content
    @researchEvidencesEditor.saveContent()
    # save additional considerations content
    additionalConsiderationsContent = @additionalConsiderationsEditor.getEditorContent()
    EtdActions.updateEditorContent
      cellId: "#{@props.sectionData.get('additionalConsiderationId')}#additionalConsiderations"
      content: additionalConsiderationsContent
      options: {}

    @onClose()

  render: ->
    {
      additionalConsiderations
      attachments
      criterion
      criticalOutcomes
      highlightToPanelMembers
      outcomes
      overarchingQuestionData
      questionType
      researchEvidence
      sectionData
      sectionId
      sectionName
      templateId
    } = @props

    assessmentContainerClass = classNames 'assessment-data',
      highlighted: highlightToPanelMembers

    <Modal isOpen
      className='assessment-section-edit-dialog'
      onClose={@onCancel}
      title={@i18n 'editor'}
    >
      <div className='section-details'>
        <span>{sectionName.toUpperCase()}: </span>
        <span>{criterion.get('description')}</span>
      </div>
      <div className='section-data-container'>
        <div id='controls-block'>
          <ToggleSwitch
            label={@i18n 'highlight_for_panel_members'}
            onChange={@toggleSectionHighlight}
            checked={highlightToPanelMembers}
          />
        </div>
        <div className={assessmentContainerClass}>
          <div className='judgement'>
            <h2>{@i18n 'judgements'}</h2>
            <Card>
              <CardContent>
                <Judgement
                  criterion={criterion}
                  criticalOutcomes={criticalOutcomes}
                  editable
                  isRating={sectionData.get('rating', false)}
                  noAutoSave
                  onJudgementChange={@onContentChange}
                  overarchingQuestionData={overarchingQuestionData}
                  questionType={questionType}
                  sectionData={sectionData}
                  sectionId={sectionId}
                  sectionName={"#{sectionName}-edit-modal"}
                  selectedOption={criterion.get('selectedOption')}
                  templateId={templateId}
                />
              </CardContent>
            </Card>
          </div>
          <div className='research-evidences'>
            <h2>{@i18n 'research_evidence'}</h2>
            <Card className="ep-card-with-etd-editor">
              <CardContent className="ep-card-with-etd-editor__card-content">
                <EtdEditor editable
                  attachments={attachments}
                  cellId={"#{sectionData.get('researchEvidenceId')}#researchEvidences"}
                  content={researchEvidence.get 'content'}
                  onChange={@onContentChange}
                  outcomes={outcomes}
                  onSaveContent={EtdActions.updateEditorContent}
                  onSaveAttachment={EtdActions.saveAttachment}
                  questionType={questionType}
                  ref={@researchEvidencesEditorRef}
                  withApplyCancelButtons={false}
                  noAutoSave
                />
              </CardContent>
            </Card>
          </div>
          <div className='additional-considerations'>
            <h2>{@i18n 'additional_considerations'}</h2>
            <Card className="ep-card-with-etd-editor">
              <CardContent className="ep-card-with-etd-editor__card-content">
                <GdtEditor
                  editorContent={additionalConsiderations.get 'content'}
                  customControls={[@getReferenceToggleSpec()]}
                  customDecorators={@getReferenceCitationDecorator()}
                  onChange={@onContentChange}
                  ref={@additionalConsiderationsEditorRef}
                />
                {if @state.showReferencesInsert
                  <ReferencesInsertModal
                    onInsert={@onInsertReferences}
                    onClose={@toggleReferencesInsert}
                  />
                }
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <ApplyCancelButtons
        onApply={@saveChanges}
        onCancel={@onCancel}
        applyLabel={@i18n '/translation:actions.save'}
        applyClass='btn-send'
      />
    </Modal>

module.exports = AssessmentSectionEditModal
