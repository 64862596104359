var Collection, TeamMember, TeamMembers, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Collection = require('models/base/collection');

TeamMember = require('./team_member');

W = require('when');

module.exports = TeamMembers = (function(_super) {
  __extends(TeamMembers, _super);

  function TeamMembers() {
    return TeamMembers.__super__.constructor.apply(this, arguments);
  }

  TeamMembers.prototype.model = TeamMember;

  TeamMembers.prototype.pouch = {
    fetch: 'projectMembers',
    views: {
      projectMembers: {
        map: function(doc) {
          if (doc.docType === 'projectMember') {
            return emit(doc.docType, doc);
          }
        }
      }
    },
    filters: {
      projectMembers: function(doc) {
        return doc._deleted || doc.docType === 'projectMember';
      }
    }
  };

  TeamMembers.prototype.initialize = function() {
    TeamMembers.__super__.initialize.apply(this, arguments);
    if (!this.url) {
      throw new Error('url property must be specified');
    }
  };

  TeamMembers.prototype.fetch = function() {
    return TeamMembers.__super__.fetch.apply(this, arguments).then((function(_this) {
      return function() {
        var member;
        return W.all((function() {
          var _i, _len, _ref, _results;
          _ref = this.models;
          _results = [];
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            member = _ref[_i];
            _results.push(member.checkCoiFormStatus());
          }
          return _results;
        }).call(_this));
      };
    })(this));
  };

  TeamMembers.prototype.getMembersFormsIds = function(docsKey) {
    return _(this.pluck(docsKey)).compact();
  };

  TeamMembers.prototype.getMembersCount = function() {
    return this.models.filter(function(m) {
      return !m.get('deleted');
    }).length;
  };

  TeamMembers.prototype.getPanelMembersColl = function() {
    return new TeamMembers(_.sortBy(this.models.filter(function(m) {
      return m.get('panelMember') && !m.get('deleted');
    }), function(model) {
      return model.get('lastName');
    }), {
      url: this.url
    });
  };

  return TeamMembers;

})(Collection);
