Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'

InfoMessageModal = createReactClass
  displayName: 'InfoMessageModal'

  propTypes:
    isOpen: PropTypes.bool.isRequired
    onClose: PropTypes.func.isRequired
    text: PropTypes.string.isRequired

  mixins: [ Translation('translation:actions') ]

  getDefaultProps: ->
    className: 'info-message-modal'

  render: ->
    <Modal
      isOpen={@props.isOpen}
      onClose={@props.onClose}
      className={@props.className}
      closeButton={true}
    >
      <div className="message">
        <div>{@props.text}</div>
        <button className='btn' onClick={@props.onClose}>
          {@i18n 'ok'}
        </button>
      </div>
    </Modal>

module.exports = InfoMessageModal
