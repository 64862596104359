IconButton = require 'components/common/icon_button'
InputWithControls = require 'components/common/input_with_controls'
{ prepareCode } = require 'lib/questions_helper'
QuestionCodes = require 'components/common/question_codes'
{ generateGUID } = require 'base/lib/utils'

tagType =
  id: PropTypes.string.isRequired
  value: PropTypes.string.isRequired
  codes: PropTypes.objectOf PropTypes.shape
    id: PropTypes.string.isRequired
    codeType: PropTypes.string
    value: PropTypes.string
    name: PropTypes.string

# TODO Refactor this and EditableTag (tagging_step.cjsx) to be more DRY
GuidelineTag = createReactClass
  displayName: "GuidelineTag"

  propTypes:
    tag: PropTypes.shape(tagType).isRequired
    codeGroup: PropTypes.string.isRequired
    updateTag: PropTypes.func.isRequired
    removeTag: PropTypes.func.isRequired

  getInitialState: ->
    isEditing: false

  handleEdit: ->
    @setState isEditing: true

  stopEditing: ->
    @setState isEditing: false

  onRequestSave: (val) ->
    @updateTag 'value', val
    @setState isEditing: false

  updateTag: (key, value)->
    @props.updateTag _.extend {}, @props.tag, { "#{key}": value }

  removeTag: ->
    @props.removeTag @props.tag.id

  addCode: ({ codes }) ->
    code = codes.first()
    newCodes = _.extend {}, @props.tag.codes, { "#{code.get('@id')}": prepareCode(code) }
    @updateTag 'codes', newCodes

  removeCode: ({ code }) ->
    codes = _.omit @props.tag.codes, code.get('id')
    @updateTag 'codes', codes

  render: ->
    { tag, codeGroup } = @props

    <div className="tag-row">
      <InputWithControls
        className="tag-input"
        controls={
          <div className="flex flex-row">
            <IconButton
              iconName='edit'
              onClick={@handleEdit}
              inlined={true}
            />
            <IconButton
              iconName='delete'
              onClick={@removeTag}
              inlined={true}
            />
          </div>
        }
        editing={@state.isEditing}
        key={tag.id}
        onRequestSave={@onRequestSave}
        onRequestCancel={@stopEditing}
        value={tag.value}
      />
      <QuestionCodes
        codeGroup={codeGroup}
        codes={Immutable.fromJS(tag.codes or {})}
        onAdd={@addCode}
        onRemove={@removeCode}
        parentId={'general_information_form'}
      />
    </div>

GuidelineTags = createReactClass
  displayName: "GuidelineTags"

  propTypes:
    tags: PropTypes.arrayOf(PropTypes.shape(tagType))
    onChange: PropTypes.func.isRequired
    i18n: PropTypes.func.isRequired
    codeGroup: PropTypes.string.isRequired
    fieldKey: PropTypes.string.isRequired

  getDefaultProps: ->
    tags: []

  addTag: ->
    tags = @props.tags or []
    @props.onChange tags.concat { id: generateGUID(), value: '', codes: {} }

  removeTag: (tagId) ->
    @props.onChange @props.tags.filter (tag) -> tag.id isnt tagId

  updateTag: (tag) ->
    tagIndex = @props.tags.findIndex (t) -> t.id is tag.id
    tags = [
      @props.tags.slice(0, tagIndex)...
      tag
      @props.tags.slice(tagIndex + 1)...
    ]
    @props.onChange tags

  render: ->
    { codeGroup, tags, fieldKey, i18n } = @props
    <div>
      {_.map tags, (tag) =>
        <GuidelineTag
          key={tag.id}
          codeGroup={codeGroup}
          removeTag={@removeTag}
          tag={tag}
          updateTag={@updateTag}
        />
      }
      <div className="add-tag-button-container mb-10">
        <IconButton
          className="add-tag-button"
          iconName="add"
          label={i18n '/overarching_questions:labels.add_tag', {
            tagName: i18n("fields.#{fieldKey}").toLowerCase()
          }}
          labelPosition='right'
          onClick={@addTag}
        />
      </div>
    </div>

module.exports = GuidelineTags
