var AdaptiveLayout, ModalView, UserGuideView, W, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

template = require('views/templates/user_guide');

W = require('when');

mediator = require('mediator');

module.exports = UserGuideView = (function(_super) {
  __extends(UserGuideView, _super);

  function UserGuideView() {
    return UserGuideView.__super__.constructor.apply(this, arguments);
  }

  UserGuideView.prototype.className = 'webpage-view';

  UserGuideView.prototype.template = template;

  UserGuideView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  UserGuideView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = 50;
    this.options.width = 850;
    this.options.fadeInMs = 0;
    this.options.fadeOutMs = 0;
    UserGuideView.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.close', this.dispose);
    return this.enable(AdaptiveLayout);
  };

  UserGuideView.prototype.afterRender = function() {
    UserGuideView.__super__.afterRender.apply(this, arguments);
    return this.trigger('!fixLayout');
  };

  UserGuideView.prototype.fixLayout = function() {
    return this.$('#webpage-content').height($('#page-container').height() - this.options.offsetY);
  };

  return UserGuideView;

})(ModalView);
