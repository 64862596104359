CommentsBlock = require 'components/scope/comments_block'
{ instanceOf, bool, func } = PropTypes

CommentsBlocks = createReactClass
  displayName: 'CommentsBlocks'

  propTypes:
    memberComments: instanceOf(Immutable.Map)
    adminComments: instanceOf(Immutable.Map)
    membersMap: instanceOf(Immutable.OrderedMap)
    readOnly: bool.isRequired
    onApplyAdminComment: func

  render: ->
    <div style={position: 'relative'}>
      {@props.memberComments.map (phaseComments, phaseName) =>
        adminComment = @props.adminComments.get phaseName

        <CommentsBlock
          key={phaseName}
          className='question-comments-container'
          membersComments={phaseComments}
          adminComment={adminComment}
          membersMap={@props.membersMap}
          readOnlyComments={@props.readOnly}
          onApplyAdminComment={@props.onApplyAdminComment}
        />
      .toList()}
    </div>

module.exports = CommentsBlocks
