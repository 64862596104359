Button = require 'components/common/button'
MarketingScreen = require 'components/common/marketing_screen'
Translation = require 'components/mixins/translation'

module.exports = createReactClass
  displayName: 'MobileScreen'
  mixins: [Translation('dissemination:mobile')]

  propTypes:
    onPreview: PropTypes.func.isRequired
    onPublish: PropTypes.func.isRequired
    status: PropTypes.string

  onPublish: ->
    { onPublish, status } = @props
    onPublish() if status is 'notPublished'

  renderPublishButton: ->
    { status, onPreview } = @props
    status ?= 'notPublished'
    publishEnabled = status is 'notPublished'
    title = if publishEnabled
      null
    else
      @i18n "/dissemination:dialogs.mobile_#{_.str.underscored status}"
    labelKey =
      if publishEnabled
        'order_app'
      else
        "/dissemination:publish.status.mobile_#{_.str.underscored status}"
    <div className="dissemination-mobile-publish">
      <Button
        className="btn"
        label={@i18n '/actions.preview'}
        onClick={onPreview}
      />
      <Button
        className="btn btn-success"
        label={@i18n labelKey}
        onClick={@onPublish}
        disabled={not publishEnabled}
        title={title}
      />
    </div>

  render: ->
    <MarketingScreen
      descriptions={@i18n 'description', returnObjects: true}
      licenseInfoTitle={@i18n 'order_app'}
      licenseInfoDesc={@i18n 'order_app_description'}
      licenseChildren={@renderPublishButton()}
      imgName="mobile.png"
    />
