CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ numberToLetter } = require 'base/lib/utils'

style =
  footnotesList:
    fontSize: '8pt'
    fontFamily: "Verdana, 'Arial Narrow', Arial, sans-serif"
    listStyle: 'lower-alpha'
  sup:
    marginLeft: '5px'

OutcomeTable = createReactClass
  displayName: 'OutcomeTable'

  propTypes:
    data: PropTypes.object.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]
  # by default all footnotes in outcome cells are ordered by their index in
  # props.data.footnotesList. But there are tables which markup doesn't hold all possible outcome
  # cells, so the footnotes from not displayed cells should not influence the order of footnotes in
  # cells which are displayed. Also such not displayed footnotes should not be listed under table
  _getNormalizedFootnoteIndex: (footnoteIndex) ->
    @normalizedFootnotesOrder ?= []
    normalizedIndex = @normalizedFootnotesOrder.indexOf footnoteIndex
    if normalizedIndex < 0
      @normalizedFootnotesOrder.push footnoteIndex
    else
      normalizedIndex + 1

  getAlphabeticalSup: (supString) ->
    return '' unless supString

    supString.split(' ').map (num) =>
      normalizedIndex = @_getNormalizedFootnoteIndex Number.parseInt(num)
      numberToLetter normalizedIndex
    .join ' '

  renderCells: (cells, isTh = false) ->
    cells.map ({ colspan, rowspan, textContent, type, name, sup }, idx) =>
      type ?= 'td'
      attributes =
        name: name
        rowSpan: rowspan
        colSpan: colspan
        key: idx
        style: _.extend {}, @props.style.cell, @props.style[name], (@props.style.thCell if isTh)

      React.createElement type, attributes,
        React.createElement 'span', dangerouslySetInnerHTML: __html: textContent
        React.createElement 'sup', style: style.sup, @getAlphabeticalSup sup

  renderRows: (rows, header = false) ->
    return null unless rows
    rows.map ({ cells }, idx) =>
      <tr key={idx}>
        {@renderCells(cells, header)}
      </tr>

  renderOutcomes: ->
    {type, outcomes} = @props.data
    if type is 'diag'
      <tbody>
        {outcomes.map ({ rows }, idx) =>
          @renderRows rows}
      </tbody>
    else
      outcomes.map ({ rows }, idx) =>
        <tbody key={idx}>
          {@renderRows rows}
        </tbody>

  render: ->
    { thead, footnotesList } = @props.data

    <div>
      <table style={@props.style.table}>
        <thead style={@props.style.thead}>
          {@renderRows(thead, true)}
        </thead>
        {@renderOutcomes()}
      </table>
      {unless _.isEmpty @normalizedFootnotesOrder
        <ol style={style.footnotesList}>
          {@normalizedFootnotesOrder.map (footnoteIdx, idx) ->
            { note } = footnotesList[footnoteIdx - 1]

            <li key={idx}>{ note }</li>
          }
        </ol>
      }
    </div>

module.exports = OutcomeTable
