Button = require 'components/common/button'
Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
{ string, object, autoSuggest, func, bool, array } = PropTypes

styles =
  suggestions:
    position: 'absolute'
    left: 0
    width: '100%'
    maxHeight: '200px'
    textAlign: 'left'
    overflowY: 'auto'
    border: '1px solid #cecece'
    zIndex: 111
    backgroundColor: 'white'

ACTION_KEYS =
  ENTER: 13
  ESCAPE: 27

SearchResults = createReactClass
  displayName: 'SuggestionsContainer'

  componentDidMount: ->
    suggestionsNode = ReactDOM.findDOMNode(this)
    { height: parentHeight } = suggestionsNode.parentNode.getBoundingClientRect()
    suggestionsNode.style.top = "#{parentHeight}px"

  render: ->
    searchResultsStyle = _.extend styles.suggestions, @props.searchResultsStyle

    <div style={searchResultsStyle}>
      {@props.results.map (suggestion, idx) ->
        <div key={idx}>{suggestion}</div>
      }
    </div>

SearchBarComponent = createReactClass
  displayName: 'SearchBarComponent'

  propTypes:
    autoFocus: bool
    containerClass: string
    searchResultsStyle: object
    searchOnChange: bool
    searchText: string
    searchResults: array
    searchFilters: array
    activeSearchFilter: string
    onSearchFilterChange: func
    onSearchReset: func
    onSearch: func.isRequired
    disabled: bool
    lowerCaseSearchText: bool

  mixins: [
    Translation('')
  ]

  getDefaultProps: ->
    autoFocus: true
    disabled: false
    lowerCaseSearchText: true
    searchLabel: $.t 'nav.search'

  getInitialState: ->
    searchText: @props.searchText

  resetSearch: ->
    @setState searchText: ''
    @props.onSearchReset?()

  canSearch: ->
    not _.isEmpty @state.searchText

  onKeyDown: (ev) ->
    key = ev.which or ev.keyCode
    switch key
      when ACTION_KEYS.ESCAPE
        @resetSearch()
      when ACTION_KEYS.ENTER
        @doSearch()

  onChange: (evt) ->
    @setState searchText: evt.target.value, =>
      @doSearch() if @props.searchOnChange

  doSearch: ->
    return @resetSearch() unless @canSearch()
    searchText = @state.searchText.trim()
    searchText = searchText.toLowerCase() if @props.lowerCaseSearchText

    @props.onSearch if @props.activeSearchFilter?
      filter: @props.activeSearchFilter, searchText: searchText
    else
      searchText

  componentDidMount: ->
    @input.focus() if @props.autoFocus

  render: ->
    { containerClass, searchResultsStyle } = @props
    canSearch = @canSearch()
    containerCls = classNames 'search-container', containerClass,
      'with-search-filter': @props.searchFilters?
    clearBtnClass = classNames 'clear-search', visible: canSearch

    <div className={containerCls}>
      {if @props.searchFilters?
        <Select
          options={@props.searchFilters}
          selected={@props.activeSearchFilter}
          onChange={@props.onSearchFilterChange}
        />
      }
      <div className='input-container'>
        <input
          ref={(el) => @input = el}
          type='text'
          placeholder={@i18n 'nav.search'}
          onChange={@onChange}
          onKeyDown={@onKeyDown}
          value={@state.searchText}
          readOnly={@props.disabled}
        />
        <Button
          className={clearBtnClass}
          onClick={@resetSearch}
          title={@i18n 'actions.cancel_search'}
        />
      </div>
      <button
        className='search'
        onClick={@doSearch}
        style={styles.button}
        disabled={@props.disabled}
      />
      {unless _.isEmpty @props.searchResults
        <SearchResults results={@props.searchResults} style={searchResultsStyle} />
      }
    </div>

module.exports = SearchBarComponent
