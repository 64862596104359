Reference = Editor.ReferenceCitation
ReferencesStore = require 'stores/references_store'
{ Entity } = Draft

ReferenceCitation = createReactClass
  getReferences: ->
    # there are 2 ways to get reference data: it can either be included in entity data during
    # reference insert, or it can be obtained from ReferenceStore
    { refIds, references } = Entity.get(@props.entityKey).getData()
    if references?
      refIds.reduce (refsList, refId) ->
        return refsList unless refId of references
        refsList.push Immutable.fromJS references[refId]
      , Immutable.List()
    else
      ReferencesStore.getReferenceByIds refIds

  render: ->
    <Reference references={@getReferences()} {...@props} />

module.exports = ReferenceCitation
