module.exports = {
  _hasVotingStarted: function() {
    return this.props.votingData && !this.props.votingData.isEmpty();
  },
  _inEditMode: function() {
    var _ref;
    return ((_ref = this.props.activeTab) === 'votingResults' || _ref === 'workspace') || !this._hasVotingStarted();
  },
  _getVotingPropsForSection: function(sectionId) {
    if (!this._hasVotingStarted()) {
      return {};
    }
    if (!this.props.votingData.get('sections').has(sectionId)) {
      return {};
    }
    return {
      votingResults: this.props.votingData.getIn(['sections', sectionId, 'memberVotes'], Immutable.List()),
      noOfVotingForms: this.props.votingData.get('noOfSentForms', 0),
      isProposedVoting: this.props.votingData.getIn(['sections', sectionId, 'proposedByAdmin'], false)
    };
  },
  switchVotingTab: function(tabName) {
    if (this._hasVotingStarted()) {
      return this.props.switchVotingTab(tabName);
    }
  }
};
