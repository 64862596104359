PanelResponsesGroup = (props) ->
  { votedOptionLabel, responses } = props
  <div className='responses-group'>
    <div className='option-name'>{votedOptionLabel.toUpperCase()}</div>
    {responses.map (r, idx) ->
      <div className='response-container' key={idx}>
        <div className='member-name'>{r.get 'memberName'}</div>
        <div className='member-comment'>{r.get 'responseText'}</div>
      </div>
    }
  </div>

module.exports = PanelResponsesGroup
