Input = require 'components/common/input'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'

DocumentSectionsTitle = createReactClass
  displayName: 'DocumentSectionsTitle'

  propTypes:
    editable: PropTypes.bool
    title: PropTypes.string.isRequired
    onCancel: PropTypes.func
    onChange: PropTypes.func
    onSave: PropTypes.func.isRequired
    onDelete: PropTypes.func
    onInsertReferences: PropTypes.func.isRequired
    sectionId: PropTypes.string.isRequired
    sectionType: PropTypes.string
    hasReferences: PropTypes.bool

  getDefaultProps: ->
    sectionType: ''
    editable: true
    hasReferences: false

  getInitialState: ->
    editing: false
    titleValue: @props.title

  toggleEdit: ->
    @setState ({ editing }) ->
      editing: !editing

  handleChange: (evt) ->
    nextTitleValue = evt.target.value
    @setState titleValue: nextTitleValue, () =>
      if @props.onChange
        @props.onChange(nextTitleValue)

  handleCancel: ->
    @setState { editing: false, titleValue: @props.title }, =>
      @props.onCancel?()

  handleSave: ->
    @props.onSave @state.titleValue
    @setState
      editing: false

  openReferenceModal: (e) ->
    { chapterId, onInsertReferences, sectionId } = @props
    e.preventDefault()
    e.stopPropagation()
    onInsertReferences({ chapterId, sectionId })

  render: ->
    { editable, title, onDelete, sectionType, hasReferences } = @props
    { editing, titleValue } = @state

    className = classNames 'document-sections-title', sectionType

    <div className={className}>
      {
        if editing
          <React.Fragment>
            <Input
              autoFocus
              keyboardSupport
              type="text"
              value={titleValue}
              onChange={@handleChange}
              onRequestSave={@handleSave}
              className="title-input"
            />
            <IconButton iconName="clear" className="title-icon" onClick={@handleCancel} />
            <IconButton iconName="save" className="title-icon" onClick={@handleSave} />

          </React.Fragment>
        else
          <React.Fragment>
            <span className="title-text">{title}</span>
            {editable and <React.Fragment>
              {onDelete && (
                <IconButton iconName="delete" className="title-icon" onClick={onDelete} />
              )}
              <IconButton iconName="edit" className="title-icon" onClick={@toggleEdit} />
              {hasReferences and mediator.services.switches.isServerSwitchOn('evolentFeatures') and
                <IconButton
                  iconName="reference"
                  className="add-reference-button"
                  onClick={@openReferenceModal}
                />
              }
            </React.Fragment>}
          </React.Fragment>
      }
    </div>

module.exports = DocumentSectionsTitle
