var ItemWithCommentsMixin, QuestionCommentsMixin;

ItemWithCommentsMixin = require('components/mixins/item_with_comments_mixin');

QuestionCommentsMixin = _.extend({}, ItemWithCommentsMixin, {
  onApplyAdminComment: function(newText) {
    var idOrKey;
    idOrKey = this.props.data.get('id');
    return this.props.onApplyAdminComment({
      idOrKey: idOrKey,
      newText: newText
    });
  }
});

module.exports = QuestionCommentsMixin;
