var CollectionView, OrderedCollection, Sortable, ViewTrait, mediator, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('base/lib/traits/view_trait');

CollectionView = require('base/views/collection_view');

OrderedCollection = require('models/base/ordered_collection');

utils = require('base/lib/utils');

mediator = require('mediator');

module.exports = Sortable = (function(_super) {
  __extends(Sortable, _super);

  function Sortable() {
    return Sortable.__super__.constructor.apply(this, arguments);
  }

  Sortable.prototype.containerSelector = 'ul';

  Sortable.prototype.draggingAxis = 'y';

  Sortable.prototype.draggingBoundaries = null;

  Sortable.prototype.draggableElSelector = null;

  Sortable.prototype.adjustWidthTo = null;

  Sortable.prototype.wrapperClass = 'standard-list';

  Sortable.prototype.sortedItems = '> *';

  Sortable.prototype.connectedLists = false;

  Sortable.prototype.comparatorAttribute = 'orderNumber';

  Sortable.prototype.orderStartIndex = 1;

  Sortable.prototype.delay = 0;

  Sortable.prototype.scrollSensitivity = 10;

  Sortable.prototype.apply = function(view) {
    Sortable.__super__.apply.apply(this, arguments);
    this._addFunction(view, 'afterRender');
    this._addFunction(view, 'applySortable');
    return this._addFunction(view, '_switchCollectionItems');
  };

  Sortable.prototype.afterRender = function(originalFunction, trait) {
    originalFunction.call(this);
    return this.applySortable();
  };

  Sortable.prototype.applySortable = function(originalFunction, trait) {
    var $container, adjustWidthTo, draggingBoundaries, itemSelector, scroll, _ref, _ref1, _ref2, _ref3, _ref4;
    draggingBoundaries = (_ref = trait.draggingBoundaries) != null ? _ref : this.$el;
    if (utils.isTraitEnabled(this, 'Scrolling')) {
      draggingBoundaries = (_ref1 = trait.draggingBoundaries) != null ? _ref1 : '.scrolled';
    }
    scroll = true;
    $container = this.$(trait.containerSelector);
    $container.addClass('ui-sortable');
    adjustWidthTo = (_ref2 = trait.adjustWidthTo) != null ? _ref2 : $container;
    $container.sortable({
      axis: trait.draggingAxis,
      containment: draggingBoundaries,
      placeholder: 'sortable-placeholder',
      tolerance: 'pointer',
      appendTo: draggingBoundaries,
      connectWith: trait.connectedLists,
      scroll: scroll,
      items: trait.sortedItems,
      scrollSensitivity: trait.scrollSensitivity,
      delay: trait.delay,
      helper: (_ref3 = trait.helper) != null ? _ref3 : function(event, $el) {
        return $('<div>').addClass(trait.wrapperClass).append($el.clone()).width($(adjustWidthTo).width()).css('margin-left', '-5px');
      }
    });
    itemSelector = (_ref4 = this.itemSelector) != null ? _ref4 : 'li';
    $container.off('sortstart');
    $container.off('sortupdate');
    $container.on('sortstart', (function(_this) {
      return function(e, ui) {
        return _this._fromIndex = _this.$("" + trait.containerSelector + " " + itemSelector).index(ui.item);
      };
    })(this));
    return $container.on('sortupdate', (function(_this) {
      return function(e, ui) {
        var toIndex;
        if (e.target !== ui.item.parent()[0]) {
          return;
        }
        toIndex = _this.$("" + trait.containerSelector + " " + itemSelector).index(ui.item);
        return _this._switchCollectionItems(_this._fromIndex, toIndex, ui.item, ui.sender);
      };
    })(this));
  };

  Sortable.prototype._switchCollectionItems = function(originalFunction, trait, fromIdx, toIdx, item, sourceList) {
    if (originalFunction) {
      return originalFunction.call(this, fromIdx, toIdx, item, sourceList);
    }
    return this.collection.switchItems(fromIdx, toIdx);
  };

  return Sortable;

})(ViewTrait);
