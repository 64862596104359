InfoIconWithTooltip = require 'components/common/info_icon_with_tooltip'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'

AssessmentTableHeader = createReactClass
  displayName: 'AssessmentTableHeader'
  mixins: [Translation('es:recommendations.table')]

  render: ->
    <tbody>
      <tr className='headers'>
        <td className='judgements with-info'>
          <span className='header-cell-label'>
            <InfoIconWithTooltip info={@i18n 'tooltips.judgements'} />
            {@i18n 'judgement'}
          </span>
        </td>
        <td className='research-evidences with-info'
            colSpan={if @props.additionalConsiderationsVisible then 1 else 2}>
          <span className='header-cell-label'>
            <InfoIconWithTooltip info={@i18n 'tooltips.research_evidence'} />
            {@i18n 'research_evidence'}
          </span>
        </td>
        {if @props.additionalConsiderationsVisible
          <td className='additional-considerations with-info'>
            <span className='header-cell-label'>
              <InfoIconWithTooltip info={@i18n 'tooltips.additional_considerations'} />
              {@i18n 'additional_considerations'}
            </span>
          </td>
        }
        {if @props.ongoingPanelVoice and @props.renderMode isnt 'printout'
          <td className='blank edit-button-column'></td>
        }
      </tr>
    </tbody>

module.exports = AssessmentTableHeader
