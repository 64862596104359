var DbDoc, generalInformationDocId, generalInformationDocShape;

DbDoc = require('lib/db_docs/db_doc');

generalInformationDocId = require('lib/doc_ids').GENERAL_INFORMATION;

generalInformationDocShape = require('lib/db_docs/doc_shapes/general_information_doc_shape');

module.exports = DbDoc(generalInformationDocShape, {
  dbSetup: {
    docId: generalInformationDocId
  },
  methods: {
    updateField: function(fieldId, value) {
      return this.setAt(fieldId)(value);
    }
  }
});
