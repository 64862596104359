var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"edit-input-block row mt-10\" data-property=\""
    + escapeExpression(((helper = (helper = helpers.propertyTitle || (depth0 != null ? depth0.propertyTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyTitle","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"col-12\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.sourceOfDxData : depth0), "rangeFromStudies", {"name":"is","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    <div class=\"sup\"></div>\n  </div>\n</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <label><span class=\"capitalize\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "range", {"name":"i18n","hash":{},"data":data})))
    + "</span> "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "from", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <input type=\"text\" name=\""
    + escapeExpression(((helper = (helper = helpers.propertyRangeFromTitle || (depth0 != null ? depth0.propertyRangeFromTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyRangeFromTitle","hash":{},"data":data}) : helper)))
    + "\" class=\"form-control\"\n        data-tip-position=\"bottom\" data-target-position=\"top\"\n        id=\""
    + escapeExpression(((helper = (helper = helpers.propertyRangeFromTitle || (depth0 != null ? depth0.propertyRangeFromTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyRangeFromTitle","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.propertyRangeFromValue || (depth0 != null ? depth0.propertyRangeFromValue : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyRangeFromValue","hash":{},"data":data}) : helper)))
    + "\">\n      <label class=\"middle\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "to", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <input type=\"text\" name=\""
    + escapeExpression(((helper = (helper = helpers.propertyRangeToTitle || (depth0 != null ? depth0.propertyRangeToTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyRangeToTitle","hash":{},"data":data}) : helper)))
    + "\" class=\"form-control\"\n        data-tip-position=\"bottom\" data-target-position=\"top\"\n        id=\""
    + escapeExpression(((helper = (helper = helpers.propertyRangeToTitle || (depth0 != null ? depth0.propertyRangeToTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyRangeToTitle","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.propertyRangeToValue || (depth0 != null ? depth0.propertyRangeToValue : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyRangeToValue","hash":{},"data":data}) : helper)))
    + "\">\n";
},"5":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <input type=\"text\" name=\""
    + escapeExpression(((helper = (helper = helpers.propertyTitle || (depth0 != null ? depth0.propertyTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyTitle","hash":{},"data":data}) : helper)))
    + "\" class=\"form-control\"\n        data-tip-position=\"bottom\" data-target-position=\"top\"\n        id=\""
    + escapeExpression(((helper = (helper = helpers.propertyTitle || (depth0 != null ? depth0.propertyTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyTitle","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.propertyValue || (depth0 != null ? depth0.propertyValue : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyValue","hash":{},"data":data}) : helper)))
    + "\">\n      <label class=\"middle\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "from", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <input type=\"text\" name=\""
    + escapeExpression(((helper = (helper = helpers.propertyFromTitle || (depth0 != null ? depth0.propertyFromTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyFromTitle","hash":{},"data":data}) : helper)))
    + "\" class=\"form-control\"\n        data-tip-position=\"bottom\" data-target-position=\"top\"\n        id=\""
    + escapeExpression(((helper = (helper = helpers.propertyFromTitle || (depth0 != null ? depth0.propertyFromTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyFromTitle","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.propertyFromValue || (depth0 != null ? depth0.propertyFromValue : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyFromValue","hash":{},"data":data}) : helper)))
    + "\">\n      <label class=\"middle\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "to", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      <input type=\"text\" name=\""
    + escapeExpression(((helper = (helper = helpers.propertyToTitle || (depth0 != null ? depth0.propertyToTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyToTitle","hash":{},"data":data}) : helper)))
    + "\" class=\"form-control\"\n        data-tip-position=\"bottom\" data-target-position=\"top\"\n        id=\""
    + escapeExpression(((helper = (helper = helpers.propertyToTitle || (depth0 != null ? depth0.propertyToTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyToTitle","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.propertyToValue || (depth0 != null ? depth0.propertyToValue : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyToValue","hash":{},"data":data}) : helper)))
    + "\">\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "pooledSensitivitySpecificityEdit", (depth0 != null ? depth0.editTitleKey : depth0), {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}