mediator = require 'mediator'
Migration = require './migration'
W = require 'when'
{ filterQuestionKeys } = require 'lib/multi_comparisons_helper'
{ multiComparison: multiComparisonPrefix } = require 'lib/doc_prefixes'

module.exports = class MultiComparisonDiagnosticQuestionIncludedInterventions extends Migration
  constructor: -> super false

  up: (project, colls) ->
    super

    adapter = mediator.services.storePersistenceAdapter

    W adapter.fetchWithKeyPrefix project.id, multiComparisonPrefix
    .then ({ rows }) ->
      W.resolve { mcQuestionDocs: [], selectedQuestionIds: [] } if  _.isEmpty rows
      mcQuestionDocs = _.pluck(rows, 'doc')
      selectedQuestionIds = _.chain(mcQuestionDocs)
        .map((doc) -> doc.selectedQuestions or [])
        .flatten()
        .pluck('_id')
        .compact()
        .value()

      { mcQuestionDocs, selectedQuestionIds}
    .then ({ mcQuestionDocs, selectedQuestionIds }) ->
      adapter.fetch project.id, selectedQuestionIds
      .then ({ rows }) ->
        questionDocs = _.pluck(rows, 'doc')
        updatedMcQuestionDocs = _.map mcQuestionDocs, (mcQuestionDoc) ->
          mcType = _.first(mcQuestionDoc.selectedQuestions)?.type
          return mcQuestionDoc if mcType is 'management'
          selectedQuestions = mcQuestionDoc.selectedQuestions or []
          selectedInterventions = _.reduce selectedQuestions, (acc, selectedQuestion) ->
            acc = acc.concat selectedQuestion.indexTest
            if selectedQuestion.comparatorTestPresent
              acc = acc.concat selectedQuestion.comparatorTest
            acc
          , []

          _.extend {}, mcQuestionDoc, selectedInterventions: selectedInterventions

    .then (updatedMcQuestionDocs) ->
      adapter.bulkDocs project.id, updatedMcQuestionDocs
