CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EditableSectionCheckbox = require 'components/administration/editable_section_checkbox'
SectionCheckbox = require 'components/administration/section_checkbox'
TogglePanel = require 'components/common/toggle_panel'
Translation = require 'components/mixins/translation'

BlockSections = createReactClass
  displayName: 'AdministrationBlockSections'

  mixins: [Translation('settings:etd_templates'), CustomRenderMixin]

  render: ->
    panelClassName = classNames 'block', @props.className
    <TogglePanel title={@i18n @props.blockName} className={panelClassName}>
      <div>
        <ul className='block-sections-list'>
          {@props.blockDef.map (sectionDef) =>
            id = sectionDef.get('id')
            sectionName = @props.blockData.getIn(['sections', id, 'name']) or
              @props.blockData.getIn ['sections', id, 'description']
            sectionDesc = if @props.descriptionKey
              @props.blockData.getIn [@props.descriptionKey, id, 'description']
            sectionTooltip = if @props.descriptionKey
              @props.blockData.getIn [@props.descriptionKey, id, 'info'], ''

            <li key={id}>
              {if id is 'additionalSection'
                <EditableSectionCheckbox
                  blockName={@props.blockName}
                  blockData={@props.blockData.getIn [@props.descriptionKey, id]}
                  sectionDef={sectionDef}
                  description={sectionDesc}
                  onSectionToggle={@props.onSectionToggle}
                  label={sectionName}
                  tooltip={sectionTooltip}
                />
              else
                <SectionCheckbox
                  blockName={@props.blockName}
                  sectionDef={sectionDef}
                  description={sectionDesc}
                  onSectionToggle={@props.onSectionToggle}
                  label={sectionName}
                />
              }
            </li>
          }
        </ul>
      </div>
    </TogglePanel>

module.exports = BlockSections
