ImportanceEditor = require 'components/etd/soj_importance_editor'
EtdActions = require 'actions/etd_actions'
OptionsList = require 'components/common/options_list'
Confirmation = require 'components/common/confirmation'
DialogBoxMixin = require 'components/mixins/dialog_box_mixin'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

Importance = createReactClass
  displayName: 'SojImportance'
  mixins: [
    DialogBoxMixin
    Translation('es:recommendations.table')
    CustomRenderMixin
  ]

  getDefaultProps: ->
    value: ''

  cancelCbk: ->
    @closeDialogBox()

  applyCbk: (newValue) ->
    @closeDialogBox()
    EtdActions.changeImportance @props.sectionKey, newValue, @props.isConsensus

  edit: (e) ->
    props =
      cancelCbk: @cancelCbk
      applyCbk: @applyCbk
      value: @props.value
      sectionName: @props.sectionName
    @showInDialogBox e, ImportanceEditor, props, 'soj-importance-editor-dialog'

  confirmCbk: ->
    @closeDialogBox()
    EtdActions.clearImportance @props.isConsensus

  clearAllOption: (e) ->
    e.persist() # React-related. To access the event properties in an asynchronous way
    props =
      options: [
          text: @i18n 'option_importance_clear'
          actionCbk: =>
            @closeDialogBox()
            @clearAllAction(e)
      ]
    @showInDialogBox e, OptionsList, props, 'soj-options-list-dialog'
    e.preventDefault()

  clearAllAction: (e)->
    props =
      onCancel: @cancelCbk
      onConfirm: @confirmCbk
      question: @i18n 'confirm_importance_clear'
    @showInDialogBox e, Confirmation, props, 'confirmation-modal soj-clear-dialog'
    e.preventDefault()

  render: ->
    return <td className={classNames 'importance', @props.value }/> if @props.hidden
    <td
      className={classNames 'importance', @props.value }
      onClick={@edit if @props.editable}
      onContextMenu={@clearAllOption}>
      {@i18n 'importance_value_' + @props.value if @props.value and @props.value isnt 'clear'}
    </td>

module.exports = Importance
