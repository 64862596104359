Modal = require 'components/common/modal'
Confirmation = require 'components/common/confirmation'
Translation = require 'components/mixins/translation'

NextStepConfirmationModal = createReactClass
  displayName: 'NextStepConfirmationModal'

  propTypes:
    onConfirm: PropTypes.func.isRequired
    onCancel: PropTypes.func.isRequired

  mixins: [Translation('scope:questions')]

  render: ->
    <Modal className='confirmation-modal' isOpen>
      <Confirmation
        question={@i18n 'confirm_next_step'}
        onCancel={@props.onCancel}
        onConfirm={@props.onConfirm}
      />
    </Modal>

module.exports = NextStepConfirmationModal
