module.exports = function() {
    return {
        "assessment": {
            "additionalConsiderations": {
                "acceptability": {
                    "content": ""
                },
                "additionalSection": {
                    "content": ""
                },
                "balanceOfEffects": {
                    "content": ""
                },
                "balanceOfHealthBenefitsAndHarms": {
                    "content": ""
                },
                "certaintyOfEffects": {
                    "content": ""
                },
                "certaintyOfEvidence": {
                    "content": ""
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestResult": {
                    "content": ""
                },
                "costEffectiveness": {
                    "content": ""
                },
                "desirableEffects": {
                    "content": ""
                },
                "equity": {
                    "content": ""
                },
                "feasibility": {
                    "content": ""
                },
                "feasibilityAndHealthSystemConsiderations": {
                    "content": ""
                },
                "feasible": {
                    "content": ""
                },
                "financialAndEconomicConsiderations": {
                    "content": ""
                },
                "healthEquityEqualityAndNonDiscrimination": {
                    "content": ""
                },
                "humanRights": {
                    "content": ""
                },
                "netBalance": {
                    "content": ""
                },
                "problem": {
                    "content": ""
                },
                "qualityOfEvidence": {
                    "content": ""
                },
                "relevantDirect": {
                    "content": ""
                },
                "resourcesRequired": {
                    "content": ""
                },
                "responsive": {
                    "content": ""
                },
                "scientificallySound": {
                    "content": ""
                },
                "societalImplications": {
                    "content": ""
                },
                "socioCulturalAcceptability": {
                    "content": ""
                },
                "testAccuracy": {
                    "content": ""
                },
                "undesirableEffects": {
                    "content": ""
                },
                "values": {
                    "content": ""
                }
            },
            "criteria": {
                "acceptability": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Esta intervenção é aceitável para as partes interessadas?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Há partes interessadas importantes que não aceitariam a distribuição dos benefícios, riscos e custos?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Há partes interessadas importantes que não aceitariam os custos ou efeitos indesejáveis a curto prazo em troca de efeitos desejáveis (benefícios) no futuro?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Há partes interessadas importantes que não concordariam com os valores atribuídos aos efeitos desejáveis ou indesejáveis (devido à forma como podem ser afetados pessoalmente ou devido às suas percepções da importância relativa dos efeitos para outros)?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "A intervenção (opção) afetaria negativamente a autonomia das pessoas?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Há partes interessadas importantes que desaprovariam moralmente a intervenção (opção), por outras razões que não os seus efeitos na autonomia das pessoas (ou seja, em relação a princípios éticos como a não maleficência, a beneficência ou a justiça)?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "É provável que as principais partes interessadas não aceitem a distribuição dos benefícios, danos e custos, ou os custos ou efeitos indesejáveis a curto prazo em troca de efeitos desejáveis (benefícios) no futuro? É provável que discordem dos valores atribuídos aos efeitos desejáveis ou indesejáveis, ou que não aceitem a intervenção de diagnóstico devido a preocupações éticas?",
                    "options": [
                        {
                            "text": "Não",
                            "value": "no"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Sim",
                            "value": "yes"
                        }
                    ],
                    "ratingDescription": "Qual é a intervenção mais aceitável para as principais partes interessadas?",
                    "ratingOptions": [
                        "best_acceptability",
                        "intermediate_acceptability",
                        "worst_acceptability"
                    ],
                    "selectedOption": ""
                },
                "acceptabilityEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": " Esta intervenção é aceitável para as partes interessadas?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não inclui estudos",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Muito baixo",
                                        "value": "very_low"
                                    },
                                    {
                                        "text": "Baixo",
                                        "value": "low"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Alto",
                                        "value": "high"
                                    }
                                ],
                                "question": "Qual é a certeza da aceitabilidade da intervenção entre a população afetada?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Variabilidade importante",
                                        "value": "important"
                                    },
                                    {
                                        "text": "Provavelmente uma variabilidade importante",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "Provavelmente uma variabilidade não importante",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "Nenhuma variabilidade importante",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "Há variabilidade na aceitação da intervenção ou exposição entre os beneficiários da recomendação e outras partes interessadas?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Será provável que as principais partes interessadas não aceitem a distribuição dos benefícios, riscos e custos, ou os custos ou efeitos indesejáveis a curto prazo em função dos efeitos desejáveis (benefícios) no futuro? Será  provável que discordem dos valores atribuídos aos efeitos desejáveis ou indesejáveis, ou não aceitem a intervenção diagnóstica por questões éticas?",
                    "options": [
                        {
                            "text": "Não",
                            "value": "no"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Sim",
                            "value": "yes"
                        }
                    ],
                    "ratingDescription": "Qual é a intervenção mais aceitável para as principais partes interessadas?",
                    "ratingOptions": [
                        "best_acceptability",
                        "intermediate_acceptability",
                        "worst_acceptability"
                    ],
                    "selectedOption": ""
                },
                "additionalSection": {
                    "additionalOptions": [
                        {
                            "text": "Julgamento adicional 1",
                            "value": "additionalOption_1"
                        },
                        {
                            "text": "Julgamento adicional 2",
                            "value": "additionalOption_2"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Descrição personalizada do critério",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [],
                        "questionsType": "general"
                    },
                    "info": "Fornecer uma sugestão de ferramenta personalizada",
                    "options": [
                        {
                            "text": "Julgamento 1",
                            "value": "option_1"
                        },
                        {
                            "text": "Julgamento 2",
                            "value": "option_2"
                        },
                        {
                            "text": "Julgamento 3",
                            "value": "option_3"
                        },
                        {
                            "text": "Julgamento 4",
                            "value": "option_4"
                        },
                        {
                            "text": "Julgamento 5",
                            "value": "option_5"
                        }
                    ],
                    "selectedOption": ""
                },
                "balanceOfEffects": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "O equilíbrio entre os efeitos desejáveis e indesejáveis favorece a intervenção ou a comparação?",
                    "details": {
                        "additionalInfos": [
                            {
                                "criteriaAnswers": [
                                    {
                                        "criterionId": "values"
                                    },
                                    {
                                        "criterionId": "certaintyOfEvidence"
                                    },
                                    {
                                        "criterionId": "desirableEffects"
                                    },
                                    {
                                        "criterionId": "undesirableEffects"
                                    }
                                ],
                                "description": "As apreciações pormenorizadas relativas a este critério incluem apreciações relativas a cada um dos quatro critérios anteriores:"
                            },
                            {
                                "additions": [
                                    "Quão menos as pessoas valorizam os resultados futuros em comparação com os resultados atuais (as suas taxas de desconto)?",
                                    "Atitudes das pessoas face a efeitos indesejáveis (aversão ao risco).",
                                    "Atitudes das pessoas face aos efeitos desejáveis (até que ponto procuram correr riscos)."
                                ],
                                "description": "Além disso, os painéis poderão querer considerar (e, se for caso disso, documentar) a medida em que as seguintes considerações podem influenciar o equilíbrio entre os efeitos desejáveis e indesejáveis:"
                            }
                        ],
                        "panelDiscussion": ""
                    },
                    "info": "Qual é o equilíbrio entre os efeitos desejáveis e indesejáveis, tendo em conta o valor que os indivíduos atribuem aos principais resultados, à importância dos efeitos desejáveis e indesejáveis, à certeza dessas estimativas, às taxas de desconto, à aversão ao risco e à procura do risco?",
                    "options": [
                        {
                            "text": "Favorece a comparação",
                            "value": "favors_comparison"
                        },
                        {
                            "text": "Provavelmente favorece a comparação",
                            "value": "probably_favors_comparison"
                        },
                        {
                            "text": "Não favorece nem a intervenção nem a comparação",
                            "value": "doesnt_favor_any"
                        },
                        {
                            "text": "Provavelmente favorece a intervenção",
                            "value": "probably_favors_intervention"
                        },
                        {
                            "text": "Favorece a intervenção",
                            "value": "favors_intervention"
                        }
                    ],
                    "ratingDescription": " O equilíbrio entre os efeitos desejáveis e indesejáveis favorece a intervenção ou a comparação para cada intervenção?",
                    "ratingOptions": [
                        "best_balance",
                        "intermediate",
                        "worst_balance"
                    ],
                    "selectedOption": ""
                },
                "balanceOfEffectsEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "O equilíbrio entre os efeitos desejáveis e indesejáveis favorece a intervenção ou a comparação?",
                    "details": {
                        "additionalInfos": [
                            {
                                "criteriaAnswers": [
                                    {
                                        "criterionId": "values"
                                    },
                                    {
                                        "criterionId": "certaintyOfEvidence"
                                    },
                                    {
                                        "criterionId": "desirableEffects"
                                    },
                                    {
                                        "criterionId": "undesirableEffects"
                                    }
                                ],
                                "description": "As apreciações pormenorizadas relativas a este critério incluem apreciações relativas a cada um dos quatro critérios anteriores:"
                            },
                            {
                                "additions": [
                                    "Quanto menos as pessoas valorizam os resultados futuros, em comparação com os resultados atuais (as suas taxas de desconto)?",
                                    "A atitude das pessoas face a efeitos indesejáveis (a sua aversão ao risco).",
                                    "As atitudes das pessoas em relação aos efeitos desejáveis (até que ponto procuram correr riscos)."
                                ],
                                "description": "Além disso, os painéis poderão querer considerar (e, se for caso disso, documentar) a medida em que as seguintes considerações podem influenciar o equilíbrio entre os efeitos desejáveis e indesejáveis:"
                            }
                        ],
                        "panelDiscussion": ""
                    },
                    "info": "Qual é o equilíbrio entre os efeitos desejáveis e indesejáveis, tendo em conta o valor que os indivíduos atribuem aos principais resultados, à importância dos efeitos desejáveis e indesejáveis, à certeza dessas estimativas, às taxas de desconto, à aversão ao risco e à procura do risco?",
                    "options": [
                        {
                            "text": "Favorece a comparação",
                            "value": "favors_comparison"
                        },
                        {
                            "text": "Provavelmente favorece a comparação",
                            "value": "probably_favors_comparison"
                        },
                        {
                            "text": "Não favorece nem a intervenção nem a comparação",
                            "value": "doesnt_favor_any"
                        },
                        {
                            "text": "Provavelmente favorece a intervenção",
                            "value": "probably_favors_intervention"
                        },
                        {
                            "text": "Favorece a intervenção",
                            "value": "favors_intervention"
                        }
                    ],
                    "ratingDescription": " O equilíbrio entre os efeitos desejáveis e indesejáveis favorece a intervenção ou a comparação para cada intervenção?",
                    "ratingOptions": [
                        "best_balance",
                        "intermediate",
                        "worst_balance"
                    ],
                    "selectedOption": ""
                },
                "balanceOfHealthBenefitsAndHarms": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "O equilíbrio entre efeitos desejáveis e indesejáveis para a saúde favorece a intervenção ou a comparação?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "A <strong>eficácia</strong> de curto e longo prazo (sob circunstâncias controladas, muitas vezes ideais) ou <strong>eficácia</strong> (num ambiente da vida real) da intervenção na <strong>saúde de indivíduos</strong>, incluindo resultados relatados pelo paciente, favorecem a intervenção ou a comparação?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "A <strong>eficácia</strong> ou <strong>impacto</strong> de curto e longo prazo da intervenção na <strong>saúde da população</strong>, inclusive nos resultados relatados pelos beneficiários, favorece a intervenção ou a comparação? (Isto deve incluir considerações sobre se os resultados a nível da população representam resultados agregados a nível individual ou emergem através das dinâmicas do sistema.)",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "A forma como os <strong>pacientes/beneficiários valorizam</strong> diferentes resultados de saúde favorece a intervenção ou a comparação?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "A <strong>probabilidade e a gravidade dos efeitos adversos</strong> associados à intervenção (incluindo o risco de utilização incorrecta da intervenção) favorecem a intervenção ou a comparação?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "Os <strong>impactos mais amplos, positivos ou negativos, relacionados com a saúde</strong> (por exemplo, redução do estigma, impacto positivo noutras doenças, efeitos secondários para além dos doentes/beneficiários) associados à intervenção favorecem a intervenção ou a comparação?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Quanto maior o benefício para a saúde associado a uma intervenção, maior a probabilidade de uma recomendação geral favorável a essa intervenção.",
                    "options": [
                        {
                            "text": "Favorece a comparação",
                            "value": "favors_the_comparison"
                        },
                        {
                            "text": "Provavelmente favorece a comparação",
                            "value": "probably_favors_the_comparison"
                        },
                        {
                            "text": "Não favorece nem a intervenção nem a comparação",
                            "value": "does_not_favor_either_the_intervention_or_the_comparison"
                        },
                        {
                            "text": "Provavelmente favorece a intervenção",
                            "value": "probably_favors_the_intervention"
                        },
                        {
                            "text": "Favorece a intervenção",
                            "value": "favors_the_intervention"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEffects": {
                    "additionalOptions": [
                        {
                            "text": "Não inclui estudos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual é o grau de certeza global da evidência dos efeitos do teste?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "info": "Em que medida é que a investigação fornece uma boa indicação dos efeitos prováveis em todos os resultados críticos, ou seja, a probabilidade de os efeitos serem suficientemente diferentes dos resultados da investigação para poderem afetar uma decisão sobre a intervenção de diagnóstico?",
                    "options": [
                        {
                            "text": "Muito baixo",
                            "value": "very_low"
                        },
                        {
                            "text": "Baixo",
                            "value": "low"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Alto",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidence": {
                    "additionalOptions": [
                        {
                            "text": "Não inclui estudos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual é o grau de certeza global da evidencia dos efeitos?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "info": "Em que medida é que a investigação fornece uma boa indicação dos efeitos prováveis em todos os resultados críticos, ou seja, a probabilidade de os efeitos serem suficientemente diferentes dos resultados da investigação para poderem afetar uma decisão sobre a intervenção?",
                    "options": [
                        {
                            "text": "Muito baixo",
                            "value": "very_low"
                        },
                        {
                            "text": "Baixo",
                            "value": "low"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Alto",
                            "value": "high"
                        }
                    ],
                    "ratingDescription": "Qual é o grau de certeza global da evidência dos efeitos para cada intervenção?",
                    "selectedOption": ""
                },
                "certaintyOfEvidenceEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Sem estudos incluídos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual é o grau de certeza global da evidencia dos efeitos?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "info": "Em que medida é que a investigação fornece uma boa indicação dos efeitos prováveis em todos os resultados críticos, ou seja, a probabilidade de os efeitos serem suficientemente diferentes dos resultados da investigação para poderem afetar uma decisão sobre a intervenção?",
                    "options": [
                        {
                            "text": "Muito baixo",
                            "value": "very_low"
                        },
                        {
                            "text": "Baixo",
                            "value": "low"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Alto",
                            "value": "high"
                        }
                    ],
                    "ratingDescription": "Qual é o grau de certeza global da evidência dos efeitos para cada intervenção?",
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "additionalOptions": [
                        {
                            "text": "Não inclui estudos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual é o grau de certeza global da evidência dos efeitos da gestão orientada pelos resultados dos testes?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "Muito baixo",
                            "value": "very_low"
                        },
                        {
                            "text": "Baixo",
                            "value": "low"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Alto",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "additionalOptions": [
                        {
                            "text": "Não inclui estudos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual é a certeza da evidência relativa aos requisitos dos recursos (custos)?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Foram identificados todos os elementos importantes da utilização dos recursos que podem diferir entre as opções consideradas?",
                                "resources": []
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Incerteza importante",
                                        "value": "important"
                                    },
                                    {
                                        "text": "Incerteza possivelmente importante",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "Incerteza provavelmente não importante",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "Incerteza não importante",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "Qual o grau de certeza do custo do uso dos itens dos recursos, que diferem entre as opções que são consideradas?",
                                "resources": []
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Variabilidade importante",
                                        "value": "important"
                                    },
                                    {
                                        "text": "Variabilidade possivelmente importante",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "Provavelmente uma variabilidade não importante",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "Nenhuma variabilidade importante",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "Existe variabilidade importante no custo do uso dos itens de recurso, que diferem entre as opções consideradas?",
                                "resources": []
                            }
                        ],
                        "questionsType": "resources"
                    },
                    "info": "Quão certa é a evidência da diferença para  o uso de cada tipo de recursos (por exemplo, medicamentos, hospitalizações) e o custo dos recursos?",
                    "options": [
                        {
                            "text": "Muito baixo",
                            "value": "very_low"
                        },
                        {
                            "text": "Baixo",
                            "value": "low"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Alto",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfRequiredResourcesEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Não inclui estudos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual é o grau de certeza da evidência das necessidades de recursos?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Foram identificados todos os tipos de recursos relevantes?",
                                "resources": []
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Variabilidade importante",
                                        "value": "important"
                                    },
                                    {
                                        "text": "Provavelmente uma variabilidade importante",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "Provavelmente uma variabilidade não importante",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "Nenhuma variabilidade importante",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "Existe uma variabilidade importante no custo dos tipos de recursos em consideração?",
                                "resources": []
                            }
                        ],
                        "questionsType": "resources"
                    },
                    "info": "Quão certa é a evidência de uma diferença para cada tipo de recursos utilizados (por exemplo, medicamentos, hospitalizações) e o custo dos recursos?",
                    "options": [
                        {
                            "text": "Muito baixo",
                            "value": "very_low"
                        },
                        {
                            "text": "Baixo",
                            "value": "low"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Alto",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "additionalOptions": [
                        {
                            "text": "Não inclui estudos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual o grau de certeza global da evidência da precisão do teste?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "Muito baixo",
                            "value": "very_low"
                        },
                        {
                            "text": "Baixo",
                            "value": "low"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Alto",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "additionalOptions": [
                        {
                            "text": "Não inclui estudos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual é o grau de certeza global das eviências relativas a quaisquer benefícios diretos críticos ou importantes, efeitos adversos ou ónus do ensaio?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "Muito baixo",
                            "value": "very_low"
                        },
                        {
                            "text": "Baixo",
                            "value": "low"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Alto",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfTestResult": {
                    "additionalOptions": [
                        {
                            "text": "Não inclui estudos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual é o grau de certeza da ligação entre os resultados dos testes e as decisões da gestão?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "Muito baixo",
                            "value": "very_low"
                        },
                        {
                            "text": "Baixo",
                            "value": "low"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Alto",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "costEffectiveness": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não inclui estudos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "A relação custo-eficácia da intervenção favorece a intervenção ou a comparação?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não inclui estudos",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "O rácio custo-eficácia é sensível a análises de sensibilidade unidireccionais?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não inclui estudos",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "O rácio custo-eficácia é sensível à análise de sensibilidade multivariada?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não inclui estudos",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Limitações muito graves",
                                        "value": "very_serious"
                                    },
                                    {
                                        "text": "Limitações graves",
                                        "value": "serious"
                                    },
                                    {
                                        "text": "Não há limitações graves",
                                        "value": "no_serious"
                                    }
                                ],
                                "question": "A avaliação económica na qual se baseia a estimativa de custo-efetividade é fiável?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não inclui estudos",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Limitações muito graves",
                                        "value": "very_serious"
                                    },
                                    {
                                        "text": "Limitações graves",
                                        "value": "serious"
                                    },
                                    {
                                        "text": "Não há limitações graves",
                                        "value": "no_serious"
                                    }
                                ],
                                "question": "A avaliação económica em que se baseia a estimativa da relação custo-eficácia é aplicável ao(s) contexto(s) de interesse?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "A intervenção é rentável, tendo em conta a incerteza ou a variabilidade dos custos, a incerteza ou a variabilidade do benefício, as análises de sensibilidade e a fiabilidade e aplicabilidade da avaliação económica?",
                    "options": [
                        {
                            "text": "Favorece a comparação",
                            "value": "favors_comparison"
                        },
                        {
                            "text": "Provavelmente favorece a comparação",
                            "value": "probably_favors_comparison"
                        },
                        {
                            "text": "Não favorece nem a intervenção nem a comparação",
                            "value": "doesnt_favor_any"
                        },
                        {
                            "text": "Provavelmente favorece a intervenção",
                            "value": "probably_favors_intervention"
                        },
                        {
                            "text": "Favorece a intervenção",
                            "value": "favors_intervention"
                        }
                    ],
                    "ratingDescription": "Qual é a intervenção que a relação custo-eficácia favorece",
                    "ratingOptions": [
                        "best_cost_effectiveness",
                        "intermediate_cost_effectiveness",
                        "worst_cost_effectiveness"
                    ],
                    "selectedOption": ""
                },
                "costEffectivenessEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não inclui estudos",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "A relação custo-eficácia da intervenção favorece a intervenção ou a comparação?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não inclui estudos",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "doesnt_favor_any"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_intervention"
                                    }
                                ],
                                "question": "Qual é o grau de certeza da análise custo-eficácia?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "A intervenção é rentável, tendo em conta a incerteza ou a variabilidade dos custos, a incerteza ou a variabilidade do benefício, as análises de sensibilidade e a fiabilidade e aplicabilidade da avaliação económica?",
                    "options": [
                        {
                            "text": "Favorece a comparação",
                            "value": "favors_comparison"
                        },
                        {
                            "text": "Provavelmente favorece a comparação",
                            "value": "probably_favors_comparison"
                        },
                        {
                            "text": "Não favorece nem a intervenção nem a comparação",
                            "value": "doesnt_favor_any"
                        },
                        {
                            "text": "Provavelmente favorece a intervenção",
                            "value": "probably_favors_intervention"
                        },
                        {
                            "text": "Favorece a intervenção",
                            "value": "favors_intervention"
                        }
                    ],
                    "ratingDescription": "Qual é a intervenção que a relação custo-eficácia favorece?",
                    "ratingOptions": [
                        "best_cost_effectiveness",
                        "intermediate_cost_effectiveness",
                        "worst_cost_effectiveness"
                    ],
                    "selectedOption": ""
                },
                "desirableEffects": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual a importância dos efeitos desejáveis previstos?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    }
                                ],
                                "question": "Em que medida é substancial o efeito previsto (diferença) para cada resultado principal para o qual existe um efeito desejável?",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "Qual é a dimensão dos efeitos desejáveis da intervenção, tendo em conta a importância dos resultados (o quanto são valorizados) e a dimensão do efeito (a probabilidade de se obter um benefício ou o grau de melhoria que os indivíduos poderão obter)?",
                    "options": [
                        {
                            "text": "Trivial / banal",
                            "value": "trivial"
                        },
                        {
                            "text": "Pequeno",
                            "value": "small"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Grande",
                            "value": "large"
                        }
                    ],
                    "ratingDescription": "Em que medida são substanciais os efeitos desejáveis previstos para cada intervenção?",
                    "ratingOptions": [
                        "most_effective",
                        "intermediate_effectiveness",
                        "least_effective"
                    ],
                    "selectedOption": ""
                },
                "desirableEffectsEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual a importância dos efeitos desejáveis previstos?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    }
                                ],
                                "question": "Qual é a dimensão do impacto desejável previsto (efeito) da intervenção ou exposição?",
                                "selectedOptions": {}
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    }
                                ],
                                "question": "Qual o período de tempo necessário para que a intervenção atinja a sua plena eficácia?",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "Qual é a dimensão dos efeitos desejáveis da intervenção, tendo em conta a importância dos resultados (o quanto são valorizados) e a dimensão do efeito (a probabilidade de se obter um benefício ou o grau de melhoria que os indivíduos poderão experienciar)?",
                    "options": [
                        {
                            "text": "Trivial / banal",
                            "value": "trivial"
                        },
                        {
                            "text": "Pequeno",
                            "value": "small"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Grande",
                            "value": "large"
                        }
                    ],
                    "ratingDescription": "Quão substanciais são os efeitos desejáveis previstos para cada intervenção?",
                    "ratingOptions": [
                        "most_effective",
                        "intermediate_effectiveness",
                        "least_effective"
                    ],
                    "selectedOption": ""
                },
                "equity": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual será o impacto na equidade na saúde?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Existem grupos ou contextos que possam estar em desvantagem em relação ao problema ou às opções consideradas?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Existem razões plausíveis para prever diferenças na eficácia relativa da opção para grupos ou contextos desfavorecidos?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Existem diferentes condições de base entre grupos ou contextos que afetem a eficácia absoluta da opção ou a importância do problema para grupos ou contextos desfavorecidos?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Existem considerações importantes que devem ser tidas em conta aquando da implementação da intervenção (opção), a fim de garantir que as desigualdades sejam reduzidas, se possível, e que não sejam aumentadas?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Existem razões plausíveis para prever diferenças na eficácia relativa da intervenção para subgrupos desfavorecidos, ou diferentes condições de base entre subgrupos desfavorecidos, que afetem a eficácia absoluta da intervenção ou a importância do problema?",
                    "options": [
                        {
                            "text": "Reduzido",
                            "value": "reduced"
                        },
                        {
                            "text": "Provavelmente reduzido",
                            "value": "probably_reduced"
                        },
                        {
                            "text": "Provavelmente sem impacto",
                            "value": "probably_no_impact"
                        },
                        {
                            "text": "Provavelmente aumentado",
                            "value": "probably_increased"
                        },
                        {
                            "text": "Aumentado",
                            "value": "increased"
                        }
                    ],
                    "ratingDescription": "Se recomendada, qual a intervenção que mais reduziria as desigualdades no domínio da saúde?",
                    "ratingOptions": [
                        "most_reduction",
                        "intermediate_reduction",
                        "less_reduction"
                    ],
                    "selectedOption": ""
                },
                "equityEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual seria o impacto na equidade?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    },
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Existem grupos ou contextos (por exemplo, populações ou fases de vida susceptíveis) que possam sofrer um impacto (efeito) diferente da intervenção ou exposição devido à variabilidade das condições de base na população afetada?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    },
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Os impactos são excessivamente desproporcionados para grupos específicos (por exemplo, fatores de stress complexos ou não quantificados)?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    },
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "A implementação da intervenção ou exposição reduziria ou aumentaria as desigualdades vividas pelos membros marginalizados da população afetada?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    },
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "As questões de igualdade (por exemplo, grupos sub-representados ou pouco estudados) são abordadas?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    },
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "As questões de justiça social são abordadas? (por exemplo, é respeitado o espírito do \"princípio do poluidor-pagador\" no que respeita à distribuição dos impactos/benefícios?)",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Existem razões plausíveis para prever diferenças na eficácia relativa da intervenção para subgrupos desfavorecidos ou diferentes condições de base entre subgrupos desfavorecidos, que afetem a eficácia absoluta da intervenção ou a importância do problema?",
                    "options": [
                        {
                            "text": "Reduzido",
                            "value": "reduced"
                        },
                        {
                            "text": "Provavelmente reduzido",
                            "value": "probably_reduced"
                        },
                        {
                            "text": "Provavelmente sem impacto",
                            "value": "probably_no_impact"
                        },
                        {
                            "text": "Provavelmente aumentado",
                            "value": "probably_increased"
                        },
                        {
                            "text": "Aumentado",
                            "value": "increased"
                        }
                    ],
                    "ratingDescription": "Se recomendada, qual a intervenção que mais reduziria as desigualdades no domínio da saúde?",
                    "ratingOptions": [
                        "most_reduction",
                        "intermediate_reduction",
                        "less_reduction"
                    ],
                    "selectedOption": ""
                },
                "feasibility": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "É viável implementar a intervenção?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "A intervenção (opção) é sustentável?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Existem obstáculos importantes susceptíveis de limitar a viabilidade da implementação da intervenção (opção) ou que devam ser tidos em conta aquando da sua implementação?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "É viável manter a utilização da intervenção diagnóstica e abordar os potenciais obstáculos à sua utilização?",
                    "options": [
                        {
                            "text": "Não",
                            "value": "no"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Sim",
                            "value": "yes"
                        }
                    ],
                    "ratingDescription": "Qual a intervenção mais viável para ser implementada?",
                    "ratingOptions": [
                        "most_feasible",
                        "intermediate_feasibility",
                        "least_feasible"
                    ],
                    "selectedOption": ""
                },
                "feasibilityAndHealthSystemConsiderations": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "É viável implementar a intervenção?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Existem <strong>barreiras legais</strong> que possam limitar a viabilidade da implementação da intervenção?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    }
                                ],
                                "question": "Existem <strong>aspectos de gestão</strong> (por exemplo, considerações estratégicas, decisões anteriores) que possam limitar a viabilidade da implementação da intervenção? (Isto deve incluir considerações relativas à presença ou ausência de instituições formais ou de informação que possam proporcionar uma liderança, supervisão e responsabilidade efetivas na implementação da intervenção e que influenciem a viabilidade da implementação)",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": " Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Implicações benéficas importantes",
                                        "value": "large_beneficial_implications"
                                    },
                                    {
                                        "text": "Implicações benéficas moderadas",
                                        "value": "moderate_beneficial_implications"
                                    },
                                    {
                                        "text": "Implicações benéficas e adversas insignificantes",
                                        "value": "negligible_beneficial_and_adverse_implications"
                                    },
                                    {
                                        "text": "Implicações adversas moderadas",
                                        "value": "moderate_adverse_implications"
                                    },
                                    {
                                        "text": "Implicações adversas importantes",
                                        "value": "large_adverse_implications"
                                    }
                                ],
                                "question": "Quais são as <strong>implicações</strong> da intervenção <strong>, interação e adequação com o sistema de saúde existente?</strong> (Isto inclui considerações sobre a interação da intervenção ou o seu impacto no sistema de saúde existente e seus componentes? )",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Implicações benéficas importantes",
                                        "value": "large_beneficial_implications"
                                    },
                                    {
                                        "text": "Implicações benéficas moderadas",
                                        "value": "moderate_beneficial_implications"
                                    },
                                    {
                                        "text": "Implicações benéficas e adversas insignificantes",
                                        "value": "negligible_beneficial_and_adverse_implications"
                                    },
                                    {
                                        "text": "Implicações adversas moderadas",
                                        "value": "moderate_adverse_implications"
                                    },
                                    {
                                        "text": "Implicações negativas importantes",
                                        "value": "large_adverse_implications"
                                    }
                                ],
                                "question": "Quais são as <strong>implicações</strong> da intervenção <strong>para a força de trabalho da saúde e recursos humanos mais amplos</strong> (no setor da saúde ou em outros setores? (Isso deve incluir considerações sobre a necessidade, uso de e impacto na força de trabalho da saúde e outros recursos humanos, bem como sua distribuição)",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Implicações benéficas importantes",
                                        "value": "large_beneficial_implications"
                                    },
                                    {
                                        "text": "Implicações benéficas moderadas",
                                        "value": "moderate_beneficial_implications"
                                    },
                                    {
                                        "text": "Implicações benéficas e adversas insignificantes",
                                        "value": "negligible_beneficial_and_adverse_implications"
                                    },
                                    {
                                        "text": "Implicações adversas moderadas",
                                        "value": "moderate_adverse_implications"
                                    },
                                    {
                                        "text": "Implicações negativas  importantes",
                                        "value": "large_adverse_implications"
                                    }
                                ],
                                "question": "Quais são as <strong>implicações</strong> da intervenção <strong>para a infraestrutura do sistema de saúde e infraestrutura mais ampla</strong>? (Isso deve incluir considerações sobre a necessidade, uso e impacto sobre recursos não humanos e infraestrutura, bem como sua distribuição)",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Quanto maior for a viabilidade de uma opção na perspectiva de todas ou da maioria das partes interessadas, maior será a probabilidade de uma recomendação geral a favor da intervenção. Quanto mais vantajosas forem as implicações para o sistema de saúde no seu conjunto, maior será a probabilidade de uma recomendação geral a favor da intervenção.",
                    "options": [
                        {
                            "text": "Não",
                            "value": "no"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_not"
                        },
                        {
                            "text": "Incerto",
                            "value": "uncertain"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Sim",
                            "value": "yes"
                        }
                    ],
                    "selectedOption": ""
                },
                "feasibilityEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "É viável implementar a intervenção?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "A intervenção (opção) é sustentável durante o período de tempo relevante?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Existem barreiras importantes (por exemplo, ausência de leis/regulamentos) susceptíveis de limitar a viabilidade da implementação da intervenção (opção)?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Existem soluções para ultrapassar quaisquer obstáculos importantes à implementação da intervenção?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Existem fatores facilitadores importantes (por exemplo, a existência de leis/regulamentos) susceptíveis de melhorar a viabilidade da implementação da intervenção (opção)?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "É viável manter a utilização da intervenção diagnóstica e abordar os potenciais obstáculos à sua utilização?",
                    "options": [
                        {
                            "text": "Não",
                            "value": "no"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Sim",
                            "value": "yes"
                        }
                    ],
                    "ratingDescription": "Qual a intervenção mais viável para ser implementada?",
                    "ratingOptions": [
                        "most_feasible",
                        "intermediate_feasibility",
                        "least_feasible"
                    ],
                    "selectedOption": ""
                },
                "feasible": {
                    "description": "IIdentificáveis e mensuráveis, e não susceptíveis de manipulação",
                    "options": [],
                    "ratingOptions": [
                        "very_feasible",
                        "feasible",
                        "least_feasible"
                    ],
                    "selectedOptions": ""
                },
                "financialAndEconomicConsiderations": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "As considerações financeiras e económicas favorecem a intervenção ou a comparação?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "Os impactos de <strong>custo e orçamento</strong> da implementação e manutenção da intervenção favorecem a intervenção ou a comparação? (Isto deve incluir considerações sobre a forma como os impactos de custo e orçamento variam no curto versus longo prazo.)",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "O <strong>impacto geral da intervenção na economia</strong> favorece a intervenção ou a comparação? (Isto deve incluir considerações sobre como os diferentes tipos de impacto económico são distribuídos pelos diferentes setores ou níveis organizacionais, se a intervenção contribui ou limita a conquista de metas mais amplas de desenvolvimento e redução da pobreza e como isso afeta a força de trabalho disponível.)",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "A <strong>relação entre custos e benefícios</strong> (por exemplo, com base em estimativas de custo-efetividade, custo-benefício ou custo-utilidade) favorece a intervenção ou a comparação?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Quanto mais vantajosas as implicações financeiras e económicas de uma intervenção, maior a probabilidade de uma recomendação geral a favor dessa intervenção",
                    "options": [
                        {
                            "text": "Favorece a comparação",
                            "value": "favors_the_comparison"
                        },
                        {
                            "text": "Provavelmente favorece a comparação",
                            "value": "probably_favors_the_comparison"
                        },
                        {
                            "text": "Não favorece nem a intervenção nem a comparação",
                            "value": "does_not_favor_either_the_intervention_or_the_comparison"
                        },
                        {
                            "text": "Provavelmente favorece a intervenção",
                            "value": "probably_favor_the_intervention"
                        },
                        {
                            "text": "Favorece a intervenção",
                            "value": "favors_the_intervention"
                        }
                    ],
                    "selectedOption": ""
                },
                "healthEquityEqualityAndNonDiscrimination": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual seria o impacto da intervenção na equidade em saúde, na igualdade e na não discriminação?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": " A intervenção é susceptível de aumentar as desigualdades existentes e/ou as desigualdades no estado de saúde ou nos seus determinantes? (Isto deve incluir considerações sobre as mudanças prováveis nas desigualdades ao longo do tempo, por exemplo, se os aumentos iniciais são susceptíveis de se equilibrarem ao longo do tempo, à medida que a intervenção é alargada?).",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "É provável que os benefícios e os riscos da intervenção se distribuam de forma equitativa? (Isto deve incluir uma atenção especial às implicações para os grupos populacionais vulneráveis, marginalizados ou socialmente desfavorecidos).",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "A intervenção é accessível aos grupos populacionais afetados e, por conseguinte, financeiramente accessível? (Isto deve incluir o impacto nas despesas de saúde dos agregados familiares, incluindo o risco de despesas de saúde catastróficas e os riscos financeiros relacionados com a saúde).",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "A intervenção é accessível aos grupos populacionais afetados? (Isto deve incluir considerações relativas ao acesso físico e informativo).",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": " A intervenção aborda uma condição particularmente grave (por exemplo, risco de vida, fim de vida, afetando indivíduos com um estado de saúde pré-existente baixo) ou rara?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "A intervenção representa a única opção disponível? (Isto deve incluir considerações sobre se a intervenção é proporcional à necessidade e se será objeto de revisão periódica).",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Quanto maior for a probabilidade de a intervenção aumentar a equidade e/ou a igualdade na saúde e de reduzir a discriminação contra qualquer grupo específico, maior será a probabilidade de uma recomendação geral a favor desta intervenção.",
                    "options": [
                        {
                            "text": "Negativo",
                            "value": "negative"
                        },
                        {
                            "text": "Provavelmente negativo",
                            "value": "probably_negative"
                        },
                        {
                            "text": "Nem negativo nem positivo",
                            "value": "neither_negative_nor_positive"
                        },
                        {
                            "text": "Provavelmente positivo",
                            "value": "probably_positive"
                        },
                        {
                            "text": "Positivo",
                            "value": "positive"
                        }
                    ],
                    "selectedOption": ""
                },
                "humanRights": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "A intervenção está em conformidade com as normas e os princípios universais em matéria de direitos humanos?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [],
                        "questionsType": "general"
                    },
                    "info": "Todas as recomendações devem estar em conformidade com as normas e os princípios universais em matéria de direitos humanos.",
                    "options": [
                        {
                            "text": "Não",
                            "value": "no"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Incerto",
                            "value": "uncertain"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Sim",
                            "value": "yes"
                        }
                    ],
                    "selectedOption": ""
                },
                "netBalance": {
                    "additionalOptions": [],
                    "description": "Saldo líquido",
                    "info": "Qual é o saldo líquido global dos efeitos?",
                    "options": [
                        {
                            "text": "É desejável uma rede grande",
                            "value": "large_net_desirable"
                        },
                        {
                            "text": "É desejável uma rede moderada",
                            "value": "moderate_net_desirable"
                        },
                        {
                            "text": "É desejável uma rede pequena",
                            "value": "small_net_desirable"
                        },
                        {
                            "text": "Saldo líquido nulo",
                            "value": "null_net_balance"
                        },
                        {
                            "text": "Pequena rede não desejável",
                            "value": "small_net_undesirable"
                        },
                        {
                            "text": "Rede moderada não desejável",
                            "value": "moderate_net_undesirable"
                        },
                        {
                            "text": " Rede grande indesejável",
                            "value": "large_net_undesirable"
                        }
                    ],
                    "selectedOptions": {}
                },
                "problem": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "O problema é prioritário?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": " As consequências do problema são graves (ou seja, severas ou importantes em termos de benefícios ou poupanças potenciais)?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": " O problema é urgente?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Trata-se de uma prioridade reconhecida (por exemplo, com base numa decisão política ou de política)?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Quanto mais grave ou urgente for um problema, mais provável é que uma opção que o resolva o seja uma prioridade",
                    "options": [
                        {
                            "text": "Não",
                            "value": "no"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Sim",
                            "value": "yes"
                        }
                    ],
                    "selectedOption": ""
                },
                "problemEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "O problema é prioritário?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "As consequências do problema são graves (isto é, severas, irreversíveis ou importantes)?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "O problema é urgente, emergente ou sem precedentes?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "O problema é uma prioridade reconhecida do sistema político (local ou nacional)?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": " O problema é uma preocupação reconhecida, profissional ou de saúde pública?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Não",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Provavelmente não",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Provavelmente sim",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Sim",
                                        "value": "yes"
                                    }
                                ],
                                "question": "O problema é uma prioridade reconhecida para uma comunidade local?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Quanto mais grave ou urgente for um problema, mais provável é que uma opção que o resolva seja uma prioridade",
                    "options": [
                        {
                            "text": "Não",
                            "value": "no"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Sim",
                            "value": "yes"
                        }
                    ],
                    "selectedOption": ""
                },
                "qualityOfEvidence": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual a qualidade geral da evidência?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [],
                        "questionsType": "general"
                    },
                    "info": "Quanto maior a qualidade da evidência em diferentes critérios na estrutura WHO-INTEGRATE, maior será a probabilidade de uma recomendação geral.",
                    "options": [
                        {
                            "text": "Muito baixo",
                            "value": "very_low"
                        },
                        {
                            "text": "Baixo",
                            "value": "low"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Alto",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "relevantDirect": {
                    "description": " para o problema selecionado e no domínio de aplicação",
                    "options": [],
                    "ratingOptions": [
                        "very_relevant",
                        "relevant",
                        "least_relevant"
                    ],
                    "selectedOptions": ""
                },
                "resourcesRequired": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual é a dimensão dos recursos necessários (custos)?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    }
                                ],
                                "question": "Qual a dimensão da diferença em cada item de recurso, sendo necessários menos recursos?",
                                "resources": []
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    }
                                ],
                                "question": "Qual é o valor da diferença em cada item na utilização de recursos, sendo necessários mais recursos?",
                                "resources": []
                            }
                        ],
                        "questionsType": "resources"
                    },
                    "info": "Qual é o custo da diferença na utilização dos recursos entre a intervenção e a comparação?",
                    "options": [
                        {
                            "text": "Custos elevados",
                            "value": "large_costs"
                        },
                        {
                            "text": "Custos moderados",
                            "value": "moderate_costs"
                        },
                        {
                            "text": "Custos e poupanças insignificantes",
                            "value": "negligible_costs_savings"
                        },
                        {
                            "text": "Poupanças moderadas",
                            "value": "moderate_savings"
                        },
                        {
                            "text": "Grandes poupanças",
                            "value": "large_savings"
                        }
                    ],
                    "ratingDescription": "Qual é a dimensão dos recursos necessários (custos) para cada intervenção?",
                    "ratingOptions": [
                        "less_costs",
                        "intermediate_costs",
                        "most_costs"
                    ],
                    "selectedOption": ""
                },
                "resourcesRequiredEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual é a dimensão dos recursos necessários (custos)?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    }
                                ],
                                "question": "Para cada tipo de recurso, a intervenção ou exposição em causa produziria custos ou poupanças adicionais?",
                                "resources": []
                            }
                        ],
                        "questionsType": "resources"
                    },
                    "info": "Qual é o custo da diferença na utilização dos recursos entre a intervenção e a comparação?",
                    "options": [
                        {
                            "text": "Custos elevados",
                            "value": "large_costs"
                        },
                        {
                            "text": "Custos moderados",
                            "value": "moderate_costs"
                        },
                        {
                            "text": "Custos e poupanças insignificantes",
                            "value": "negligible_costs_savings"
                        },
                        {
                            "text": "Poupanças moderadas",
                            "value": "moderate_savings"
                        },
                        {
                            "text": "Grandes poupanças",
                            "value": "large_savings"
                        }
                    ],
                    "ratingDescription": "Qual é a dimensão dos recursos necessários (custos) para cada intervenção?",
                    "ratingOptions": [
                        "less_costs",
                        "intermediate_costs",
                        "most_costs"
                    ],
                    "selectedOption": ""
                },
                "responsive": {
                    "description": "sensíveis à mudança, o que significa que devem ser capazes de captar possíveis alterações no sistema",
                    "options": [],
                    "ratingOptions": [
                        "very_responsive",
                        "responsive",
                        "least_responsive"
                    ],
                    "selectedOptions": ""
                },
                "scientificallySound": {
                    "description": "based on evidence (GRADE criteria)",
                    "options": [],
                    "ratingOptions": [
                        "very_scientifically_sound",
                        "scientifically_sound",
                        "least_scientifically_sound"
                    ],
                    "selectedOptions": ""
                },
                "societalImplications": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "O equilíbrio entre as implicações sociais desejáveis e indesejáveis favorece a intervenção ou a comparação?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": " O impacto social e as consequências sociais da intervenção (como o aumento ou a redução do estigma, os resultados educativos, a coesão social ou a consecução de vários direitos humanos para além da saúde) favorecem a intervenção ou a comparação?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "O impacto ambiental e as implicações para a sustentabilidade ecológica (por exemplo, proteção dos recursos naturais, atenuação ou adaptação às alterações climáticas) favorecem a intervenção ou a comparação?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Quanto maior for o benefício social associado a uma intervenção, maior será a probabilidade de uma recomendação geral a favor dessa intervenção.",
                    "options": [
                        {
                            "text": "Favorece a comparação",
                            "value": "favors_the_comparison"
                        },
                        {
                            "text": "Provavelmente favorece a comparação",
                            "value": "probably_favors_the_comparison"
                        },
                        {
                            "text": "Não favorece nem a intervenção nem a comparação",
                            "value": "does_not_favor_either_the_intervention_or_the_comparison"
                        },
                        {
                            "text": "Provavelmente favorece a intervenção",
                            "value": "probably_favors_the_intervention"
                        },
                        {
                            "text": "Favorece a intervenção",
                            "value": "favors_the_intervention"
                        }
                    ],
                    "selectedOption": ""
                },
                "socioCulturalAcceptability": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "É esta intervenção aceitável para as partes interessadas?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    }
                                ],
                                "question": "Qual o grau de ingerência da intervenção em termos de violação das liberdades individuais (incluindo a privacidade e a dignidade)? (A ingerência varia de trivial - por exemplo, ao permitir a escolha (por exemplo, construir ciclovias) - a elevada - por exemplo, ao restringir ou eliminar a escolha (por exemplo, proibir os cigarros)).",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    }
                                ],
                                "question": "Em que medida é substancial o impacto da intervenção na autonomia dos indivíduos, grupos populacionais e/ou organizações (no que respeita à sua capacidade de tomar uma decisão competente, informada e voluntária)?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": " Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "A aceitabilidade sociocultural da intervenção entre os beneficiários previstos favorece a intervenção ou a comparação?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "A aceitabilidade sociocultural da intervenção entre as pessoas que pretendem implementar a intervenção favorece a intervenção ou a comparação?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "A aceitabilidade sociocultural da intervenção entre outros grupos de partes interessadas relevantes favorece a intervenção ou a comparação?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Favorece a comparação",
                                        "value": "favors_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a comparação",
                                        "value": "probably_favors_the_comparison"
                                    },
                                    {
                                        "text": "Não favorece nem a intervenção nem a comparação",
                                        "value": "does_not_favor_either_the_intervention_or_the_comparison"
                                    },
                                    {
                                        "text": "Provavelmente favorece a intervenção",
                                        "value": "probably_favors_the_intervention"
                                    },
                                    {
                                        "text": "Favorece a intervenção",
                                        "value": "favors_the_intervention"
                                    }
                                ],
                                "question": "A aceitabilidade sociocultural da intervenção entre o público em geral favorece a intervenção ou a comparação?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Quanto maior for a aceitabilidade sociocultural de uma intervenção para todos ou para a maioria dos intervenientes relevantes, maior será a probabilidade de uma recomendação geral a favor dessa intervenção.",
                    "options": [
                        {
                            "text": "Não",
                            "value": "no"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Incerto",
                            "value": "uncertain"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Sim",
                            "value": "yes"
                        }
                    ],
                    "selectedOption": ""
                },
                "testAccuracy": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Quão preciso é o teste?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "Muito impreciso",
                            "value": "very_inaccurate"
                        },
                        {
                            "text": "Impreciso",
                            "value": "inaccurate"
                        },
                        {
                            "text": "Preciso",
                            "value": "accurate"
                        },
                        {
                            "text": "Muito preciso",
                            "value": "very_accurate"
                        }
                    ],
                    "selectedOption": ""
                },
                "undesirableEffects": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual a importância dos efeitos indesejáveis previstos?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    }
                                ],
                                "question": "Quão substancial é o efeito previsto (diferença) para cada resultado principal para o qual existe um efeito indesejável?",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "Qual é a dimensão dos efeitos indesejáveis da intervenção, tendo em conta a importância dos resultados (o quanto são valorizados) e a dimensão do efeito (a probabilidade de se obter um benefício ou a quantidade de melhoria que os indivíduos provavelmente experimentariam)?",
                    "options": [
                        {
                            "text": "Trivial / banal",
                            "value": "trivial"
                        },
                        {
                            "text": "Pequeno",
                            "value": "small"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Grande",
                            "value": "large"
                        }
                    ],
                    "ratingDescription": "Qual a importância dos efeitos indesejáveis previstos para cada intervenção?",
                    "ratingOptions": [
                        "least_harmful",
                        "intermediate",
                        "more_harmful"
                    ],
                    "selectedOption": ""
                },
                "undesirableEffectsEOHR": {
                    "additionalOptions": [
                        {
                            "text": "Varia",
                            "value": "varies"
                        },
                        {
                            "text": "Não sei",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Qual a importância dos efeitos indesejáveis previstos?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    }
                                ],
                                "question": "Qual é a dimensão do impacto indesejável previsto (efeito) da intervenção ou exposição?",
                                "selectedOptions": {}
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varia",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Grande",
                                        "value": "large"
                                    },
                                    {
                                        "text": "Moderado",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Pequeno",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Trivial / banal",
                                        "value": "trivial"
                                    }
                                ],
                                "question": "Qual é a duração dos efeitos indesejáveis da intervenção?",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "Qual é a dimensão dos efeitos indesejáveis da intervenção, tendo em conta a importância dos resultados (o quanto são valorizados) e a dimensão do efeito (a probabilidade de se obter um benefício ou a quantidade de melhoria que os indivíduos provavelmente experimentariam)?",
                    "options": [
                        {
                            "text": "Grande",
                            "value": "large"
                        },
                        {
                            "text": "Moderado",
                            "value": "moderate"
                        },
                        {
                            "text": "Pequeno",
                            "value": "small"
                        },
                        {
                            "text": "Trivial / banal",
                            "value": "trivial"
                        }
                    ],
                    "ratingDescription": "Qual a importância dos efeitos indesejáveis previstos para cada intervenção?",
                    "ratingOptions": [
                        "least_harmful",
                        "intermediate",
                        "more_harmful"
                    ],
                    "selectedOption": ""
                },
                "values": {
                    "additionalOptions": [],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Existe uma incerteza importante ou variabilidade na forma como as pessoas valorizam os principais resultados?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "options": [
                                    {
                                        "text": "Incerteza importante",
                                        "value": "important"
                                    },
                                    {
                                        "text": "Incerteza possivelmente importante",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "Incerteza provavelmente não importante",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "Incerteza não importante",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "Existe uma incerteza importante quanto ao valor que as pessoas atribuem aos principais resultados?",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "Quanto é que os indivíduos valorizam cada um dos principais resultados? A incerteza sobre o valor que atribuem a cada um dos resultados, ou a variabilidade no valor que diferentes indivíduos atribuem aos resultados é suficientemente grande para poder conduzir a decisões diferentes?",
                    "options": [
                        {
                            "text": "Incerteza ou variabilidade importante",
                            "value": "important_uncertainty"
                        },
                        {
                            "text": "Possível incerteza ou variabilidade importante",
                            "value": "possible_important"
                        },
                        {
                            "text": "Provavelmente nenhuma incerteza ou variabilidade importante",
                            "value": "probably_no_important"
                        },
                        {
                            "text": "Nenhuma incerteza ou variabilidade importante",
                            "value": "no_important"
                        }
                    ],
                    "selectedOption": ""
                },
                "valuesEOHR": {
                    "additionalOptions": [],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Existe uma incerteza importante ou variabilidade na forma como as pessoas valorizam os principais resultados?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Incerteza importante",
                                        "value": "important"
                                    },
                                    {
                                        "text": "Incerteza provavelmente importante",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "Incerteza provavelmente não importante",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "Incerteza não importante",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "Existe uma incerteza importante quanto ao valor que as pessoas atribuem aos principais resultados?",
                                "selectedOptions": {}
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Não sei",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Variabilidade importante",
                                        "value": "important"
                                    },
                                    {
                                        "text": "Provavelmente uma variabilidade importante",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "Provavelmente uma variabilidade não importante",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "Sem variabilidade importante",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "Existe uma variabilidade significativa na forma como as pessoas valorizam os principais resultados?",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "Quanto é que os indivíduos valorizam cada um dos principais resultados? A incerteza sobre o valor que atribuem a cada um dos resultados ou a variabilidade no valor que diferentes indivíduos atribuem aos resultados é suficientemente grande para poder conduzir a decisões diferentes?",
                    "options": [
                        {
                            "text": "Incerteza ou variabilidade importante",
                            "value": "important_uncertainty"
                        },
                        {
                            "text": "Possível incerteza ou variabilidade importante",
                            "value": "possible_important"
                        },
                        {
                            "text": "Provavelmente nenhuma incerteza ou variabilidade importante",
                            "value": "probably_no_important"
                        },
                        {
                            "text": "Sem incerteza ou variabilidade importante",
                            "value": "no_important"
                        }
                    ],
                    "selectedOption": ""
                }
            },
            "researchEvidences": {
                "acceptability": {
                    "content": ""
                },
                "additionalSection": {
                    "content": ""
                },
                "balanceOfEffects": {
                    "content": ""
                },
                "balanceOfHealthBenefitsAndHarms": {
                    "content": ""
                },
                "certaintyOfEffects": {
                    "content": ""
                },
                "certaintyOfEvidence": {
                    "content": ""
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestResult": {
                    "content": ""
                },
                "costEffectiveness": {
                    "content": ""
                },
                "desirableEffects": {
                    "content": ""
                },
                "equity": {
                    "content": ""
                },
                "feasibility": {
                    "content": ""
                },
                "feasibilityAndHealthSystemConsiderations": {
                    "content": ""
                },
                "feasible": {
                    "content": ""
                },
                "financialAndEconomicConsiderations": {
                    "content": ""
                },
                "healthEquityEqualityAndNonDiscrimination": {
                    "content": ""
                },
                "humanRights": {
                    "content": ""
                },
                "netBalance": {
                    "content": ""
                },
                "problem": {
                    "content": ""
                },
                "qualityOfEvidence": {
                    "content": ""
                },
                "relevantDirect": {
                    "content": ""
                },
                "resourcesRequired": {
                    "content": ""
                },
                "responsive": {
                    "content": ""
                },
                "scientificallySound": {
                    "content": ""
                },
                "societalImplications": {
                    "content": ""
                },
                "socioCulturalAcceptability": {
                    "content": ""
                },
                "testAccuracy": {
                    "content": ""
                },
                "undesirableEffects": {
                    "content": ""
                },
                "values": {
                    "content": ""
                }
            },
            "sections": {
                "acceptability": {
                    "additionalConsiderationId": "acceptability",
                    "criterionId": "acceptability",
                    "name": "Admissibilidade",
                    "researchEvidenceId": "acceptability"
                },
                "acceptabilityEOHR": {
                    "additionalConsiderationId": "acceptability",
                    "criterionId": "acceptabilityEOHR",
                    "name": "Admissibilidade",
                    "researchEvidenceId": "acceptability"
                },
                "additionalSection": {
                    "additionalConsiderationId": "additionalSection",
                    "criterionId": "additionalSection",
                    "name": "Critério de custo",
                    "researchEvidenceId": "additionalSection"
                },
                "balanceOfEffects": {
                    "additionalConsiderationId": "balanceOfEffects",
                    "criterionId": "balanceOfEffects",
                    "name": "Equilibrio dos efeitos",
                    "researchEvidenceId": "balanceOfEffects"
                },
                "balanceOfEffectsEOHR": {
                    "additionalConsiderationId": "balanceOfEffects",
                    "criterionId": "balanceOfEffectsEOHR",
                    "name": "Equilíbrio de efeitos",
                    "researchEvidenceId": "balanceOfEffects"
                },
                "balanceOfHealthBenefitsAndHarms": {
                    "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
                    "criterionId": "balanceOfHealthBenefitsAndHarms",
                    "name": "Equilíbrio entre os benefícios e riscos para a saúde",
                    "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
                },
                "certaintyOfEffects": {
                    "additionalConsiderationId": "certaintyOfEffects",
                    "criterionId": "certaintyOfEffects",
                    "name": "Certeza dos efeitos",
                    "researchEvidenceId": "certaintyOfEffects"
                },
                "certaintyOfEvidence": {
                    "additionalConsiderationId": "certaintyOfEvidence",
                    "criterionId": "certaintyOfEvidence",
                    "name": "Certeza da evidência",
                    "researchEvidenceId": "certaintyOfEvidence"
                },
                "certaintyOfEvidenceEOHR": {
                    "additionalConsiderationId": "certaintyOfEvidence",
                    "criterionId": "certaintyOfEvidenceEOHR",
                    "name": "Certeza da evidência",
                    "researchEvidenceId": "certaintyOfEvidence"
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
                    "criterionId": "certaintyOfEvidenceOfManagementEffects",
                    "name": "Certeza da evidência dos efeitos da gestão",
                    "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
                    "criterionId": "certaintyOfEvidenceOfRequiredResources",
                    "name": "Certeza da evidência dos recursos necessários",
                    "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
                },
                "certaintyOfEvidenceOfRequiredResourcesEOHR": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
                    "criterionId": "certaintyOfEvidenceOfRequiredResourcesEOHR",
                    "name": "Certeza da evidência dos recursos necessários",
                    "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
                    "criterionId": "certaintyOfEvidenceOfTestAccuracy",
                    "name": "Certeza sobre a evidência da precisão do teste",
                    "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
                    "criterionId": "certaintyOfEvidenceOfTestEffects",
                    "name": "Certeza da evidência dos efeitos dos testes",
                    "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
                },
                "certaintyOfEvidenceOfTestResult": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
                    "criterionId": "certaintyOfEvidenceOfTestResult",
                    "name": "Certeza da evidência do resultado/gestão do teste",
                    "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
                },
                "costEffectiveness": {
                    "additionalConsiderationId": "costEffectiveness",
                    "criterionId": "costEffectiveness",
                    "name": "Custo-benefício",
                    "researchEvidenceId": "costEffectiveness"
                },
                "costEffectivenessEOHR": {
                    "additionalConsiderationId": "costEffectiveness",
                    "criterionId": "costEffectivenessEOHR",
                    "name": "Custo-efetividade",
                    "researchEvidenceId": "costEffectiveness"
                },
                "desirableEffects": {
                    "additionalConsiderationId": "desirableEffects",
                    "criterionId": "desirableEffects",
                    "name": "Efeitos desejáveis",
                    "researchEvidenceId": "desirableEffects"
                },
                "desirableEffectsEOHR": {
                    "additionalConsiderationId": "desirableEffects",
                    "criterionId": "desirableEffectsEOHR",
                    "name": "Efeitos desejáveis",
                    "researchEvidenceId": "desirableEffects"
                },
                "equity": {
                    "additionalConsiderationId": "equity",
                    "criterionId": "equity",
                    "name": "Equidade",
                    "researchEvidenceId": "equity"
                },
                "equityEOHR": {
                    "additionalConsiderationId": "equity",
                    "criterionId": "equityEOHR",
                    "name": "Equidade",
                    "researchEvidenceId": "equity"
                },
                "feasibility": {
                    "additionalConsiderationId": "feasibility",
                    "criterionId": "feasibility",
                    "name": "Viabilidade",
                    "researchEvidenceId": "feasibility"
                },
                "feasibilityAndHealthSystemConsiderations": {
                    "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
                    "criterionId": "feasibilityAndHealthSystemConsiderations",
                    "name": "Aplicabilidade e considerações sobre os sistemas de saúde",
                    "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
                },
                "feasibilityEOHR": {
                    "additionalConsiderationId": "feasibility",
                    "criterionId": "feasibilityEOHR",
                    "name": "Viabilidade",
                    "researchEvidenceId": "feasibility"
                },
                "feasible": {
                    "additionalConsiderationId": "feasible",
                    "criterionId": "feasible",
                    "name": "Viável",
                    "researchEvidenceId": "feasible"
                },
                "financialAndEconomicConsiderations": {
                    "additionalConsiderationId": "financialAndEconomicConsiderations",
                    "criterionId": "financialAndEconomicConsiderations",
                    "name": "Considerações económicas e financeiras",
                    "researchEvidenceId": "financialAndEconomicConsiderations"
                },
                "healthEquityEqualityAndNonDiscrimination": {
                    "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
                    "criterionId": "healthEquityEqualityAndNonDiscrimination",
                    "name": "Equidade em saúde, igualdade e não-discriminação",
                    "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
                },
                "humanRights": {
                    "additionalConsiderationId": "humanRights",
                    "criterionId": "humanRights",
                    "name": "Direitos Humanos",
                    "researchEvidenceId": "humanRights"
                },
                "netBalance": {
                    "additionalConsiderationId": "netBalance",
                    "criterionId": "netBalance",
                    "name": "Saldo líquido",
                    "researchEvidenceId": "netBalance"
                },
                "problem": {
                    "additionalConsiderationId": "problem",
                    "criterionId": "problem",
                    "name": "Problema",
                    "researchEvidenceId": "problem"
                },
                "problemEOHR": {
                    "additionalConsiderationId": "problem",
                    "criterionId": "problemEOHR",
                    "name": "Problema",
                    "researchEvidenceId": "problem"
                },
                "qualityOfEvidence": {
                    "additionalConsiderationId": "qualityOfEvidence",
                    "criterionId": "qualityOfEvidence",
                    "name": "Qualidade da evidência",
                    "researchEvidenceId": "qualityOfEvidence"
                },
                "relevantDirect": {
                    "additionalConsiderationId": "relevantDirect",
                    "criterionId": "relevantDirect",
                    "name": "Relevante (DIRETO)",
                    "researchEvidenceId": "relevantDirect"
                },
                "resourcesRequired": {
                    "additionalConsiderationId": "resourcesRequired",
                    "criterionId": "resourcesRequired",
                    "name": "Recursos necessários",
                    "researchEvidenceId": "resourcesRequired"
                },
                "resourcesRequiredEOHR": {
                    "additionalConsiderationId": "resourcesRequired",
                    "criterionId": "resourcesRequiredEOHR",
                    "name": "Recursos necessários",
                    "researchEvidenceId": "resourcesRequired"
                },
                "responsive": {
                    "additionalConsiderationId": "responsive",
                    "criterionId": "responsive",
                    "name": "Receptivo",
                    "researchEvidenceId": "responsive"
                },
                "scientificallySound": {
                    "additionalConsiderationId": "scientificallySound",
                    "criterionId": "scientificallySound",
                    "name": "Científicamente correta (certeza da evidência)",
                    "researchEvidenceId": "scientificallySound"
                },
                "societalImplications": {
                    "additionalConsiderationId": "societalImplications",
                    "criterionId": "societalImplications",
                    "name": "Implicações sociais",
                    "researchEvidenceId": "societalImplications"
                },
                "socioCulturalAcceptability": {
                    "additionalConsiderationId": "socioCulturalAcceptability",
                    "criterionId": "socioCulturalAcceptability",
                    "name": "Aceitabilidade sociocultural",
                    "researchEvidenceId": "socioCulturalAcceptability"
                },
                "testAccuracy": {
                    "additionalConsiderationId": "testAccuracy",
                    "criterionId": "testAccuracy",
                    "name": "Precisão do teste",
                    "researchEvidenceId": "testAccuracy"
                },
                "undesirableEffects": {
                    "additionalConsiderationId": "undesirableEffects",
                    "criterionId": "undesirableEffects",
                    "name": "Efeitos indesejados",
                    "researchEvidenceId": "undesirableEffects"
                },
                "undesirableEffectsEOHR": {
                    "additionalConsiderationId": "undesirableEffects",
                    "criterionId": "undesirableEffectsEOHR",
                    "name": "Efeitos indesejados",
                    "researchEvidenceId": "undesirableEffects"
                },
                "values": {
                    "additionalConsiderationId": "values",
                    "criterionId": "values",
                    "name": "Valores",
                    "researchEvidenceId": "values"
                },
                "valuesEOHR": {
                    "additionalConsiderationId": "values",
                    "criterionId": "valuesEOHR",
                    "name": "Valores",
                    "researchEvidenceId": "values"
                }
            },
            "sectionsOrder": []
        },
        "conclusions": {
            "sections": {
                "benefits": {
                    "content": "",
                    "description": "Benefícios"
                },
                "clearAndActionable": {
                    "content": "",
                    "description": "Claros e accionáveis",
                    "options": [
                        {
                            "text": "Sim",
                            "value": "yes"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Não",
                            "value": "no"
                        }
                    ],
                    "selectedOption": ""
                },
                "clinicalConsiderations": {
                    "content": "",
                    "description": "Considerações clinicas"
                },
                "collectingAndSummarizing": {
                    "content": "",
                    "description": "A recolha e síntese das provas é uma má utilização do tempo e energia limitados do painel de peritos para as recomendações (custo de oportunidade elevado)",
                    "options": [
                        {
                            "text": "Sim",
                            "value": "yes"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Não",
                            "value": "no"
                        }
                    ],
                    "selectedOption": ""
                },
                "considerations": {
                    "content": "",
                    "description": "Considerações"
                },
                "contextAndSystemConsiderations": {
                    "content": "",
                    "description": "Considerações sobre o contexto e o sistema"
                },
                "decision": {
                    "content": "",
                    "description": "Decisão",
                    "options": [
                        {
                            "text": "Implementação completa",
                            "value": "full_implementation"
                        },
                        {
                            "text": "Avaliação de impacto",
                            "value": "impact_evaluation"
                        },
                        {
                            "text": "Estudo piloto",
                            "value": "pilot_study"
                        },
                        {
                            "text": "Adiar",
                            "value": "postpone"
                        },
                        {
                            "text": "Não implementar",
                            "value": "do_not_implement"
                        }
                    ]
                },
                "decisionTypeCoverage": {
                    "content": "",
                    "description": "Tipo de decisão",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Não cobrir / não tapar",
                            "value": "do_not_cover"
                        },
                        {
                            "direction": "positive",
                            "text": "Cobertura com o desenvolvimento da evidência",
                            "value": "cover_with_evidence"
                        },
                        {
                            "direction": "positive",
                            "text": "Cobertura com negociação de preço",
                            "value": "cover_with_price"
                        },
                        {
                            "direction": "positive",
                            "text": "Cobertura limitada",
                            "value": "restricted_coverage"
                        },
                        {
                            "direction": "positive",
                            "text": "Cobrir / tapar",
                            "value": "cover"
                        }
                    ],
                    "selectedOption": ""
                },
                "decisionTypeImplementation": {
                    "content": "",
                    "description": "Tipo de decisão",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Não implementar a opção",
                            "value": "do_not_implement"
                        },
                        {
                            "direction": "none",
                            "text": "Adiar a decisão",
                            "value": "postpone"
                        },
                        {
                            "direction": "none",
                            "text": "Realizar um estudo piloto da opção",
                            "value": "conduct_pilot_study"
                        },
                        {
                            "direction": "positive",
                            "text": "Implementar a opção com avaliação do impacto",
                            "value": "implement_with_evaluation"
                        },
                        {
                            "direction": "positive",
                            "text": "Implementar a opção",
                            "value": "implement"
                        }
                    ],
                    "selectedOption": ""
                },
                "evidence": {
                    "content": "",
                    "description": "Evidência"
                },
                "frequencyDoseDuration": {
                    "content": "",
                    "description": "Frequência/Dose/Duração"
                },
                "goodPracticeStatement": {
                    "content": "",
                    "description": "Declaração de boas práticas"
                },
                "harms": {
                    "content": "",
                    "description": "Riscos"
                },
                "implementationConsiderations": {
                    "content": "",
                    "description": "Considerações sobre a implementação",
                    "details": {
                        "content": "",
                        "description": "Implementação detalhada"
                    }
                },
                "implementationConsiderationsOfAllRelevantOutcomes": {
                    "content": "",
                    "description": "Após consideração de todos os resultados relevantes e potenciais consequências a jusante, a implementação da declaração de boas práticas apresenta importantes repercussões positivas globais",
                    "options": [
                        {
                            "text": "Sim",
                            "value": "yes"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Não",
                            "value": "no"
                        }
                    ],
                    "selectedOption": ""
                },
                "implementationConsiderationsOrRemarks": {
                    "content": "",
                    "description": "Considerações sobre a implementação",
                    "options": [
                        {
                            "text": "Sim",
                            "value": "yes"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Não",
                            "value": "no"
                        }
                    ],
                    "selectedOption": ""
                },
                "indications": {
                    "content": "",
                    "description": "Indicações"
                },
                "indicationsForDiscontinuation": {
                    "content": "",
                    "description": "Indicações para a interrupção"
                },
                "justification": {
                    "content": "",
                    "description": "Justificação",
                    "details": {
                        "description": "Justificação detalhada"
                    },
                    "overallDescription": "Justificação geral"
                },
                "keyMessage": {
                    "content": "",
                    "description": "Mensagem chave"
                },
                "limitations": {
                    "content": "",
                    "description": "Limitações"
                },
                "mdgRecommendation": {
                    "content": "",
                    "description": "Recomendação",
                    "options": [
                        {
                            "direction": "positive",
                            "text": "Recomendado",
                            "value": "recommend"
                        },
                        {
                            "direction": "negative",
                            "text": " Não recomendado",
                            "value": "recommend_against"
                        },
                        {
                            "direction": "negative",
                            "text": "Por vezes recomendado",
                            "value": "suggest_against"
                        },
                        {
                            "direction": "none",
                            "text": "Sem recomendação",
                            "value": "suggest_either"
                        }
                    ],
                    "selectedOption": ""
                },
                "monitoringAndEvaluation": {
                    "content": "",
                    "description": "Controlo e avaliação",
                    "details": {
                        "content": "",
                        "description": "Controlo e avaliação pormenorizados"
                    }
                },
                "monitoringAndEvaluationQI": {
                    "content": "",
                    "description": "Controlo e avaliação"
                },
                "multipleChronicConditionsAndPolypharmacy": {
                    "content": "",
                    "description": "Múltiplas doenças crónicas e polimedicado"
                },
                "multipleRecommendations": {
                    "description": "Recomendação(ões)",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Recomendação forte contra a intervenção",
                            "value": "recommend_against"
                        },
                        {
                            "direction": "negative",
                            "text": "Recomendação condicional contra a intervenção",
                            "value": "suggest_against"
                        },
                        {
                            "direction": "none",
                            "text": "Recomendação condicional para a intervenção ou para a comparação",
                            "value": "suggest_either"
                        },
                        {
                            "direction": "positive",
                            "text": "Recomendação condicional para a intervenção",
                            "value": "suggest"
                        },
                        {
                            "direction": "positive",
                            "text": "Forte recomendação para a intervenção",
                            "value": "recommend"
                        }
                    ],
                    "recommendations": [
                        {
                            "content": "",
                            "selectedOption": ""
                        }
                    ]
                },
                "rationale": {
                    "content": "",
                    "description": "Justificação"
                },
                "rationaleConnectingIndirectEvidence": {
                    "content": "",
                    "description": "Existe uma justificação clara e explícita bem documentada que liga as eviências indiretas",
                    "options": [
                        {
                            "text": "Sim",
                            "value": "yes"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Não",
                            "value": "no"
                        }
                    ],
                    "selectedOption": ""
                },
                "recommendation": {
                    "content": "",
                    "description": "Recomendação"
                },
                "recommendationTypeIntervention": {
                    "content": "",
                    "description": "Tipo de recomendação",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Recomendação forte contra a intervenção",
                            "value": "recommend_against"
                        },
                        {
                            "direction": "negative",
                            "text": "Recomendação condicional contra a intervenção",
                            "value": "suggest_against"
                        },
                        {
                            "direction": "none",
                            "text": "Recomendação condicional para a intervenção ou para a comparação",
                            "value": "suggest_either"
                        },
                        {
                            "direction": "positive",
                            "text": "Recomendação condicional para a intervenção",
                            "value": "suggest"
                        },
                        {
                            "direction": "positive",
                            "text": "Forte recomendação para a intervenção",
                            "value": "recommend"
                        }
                    ],
                    "selectedOption": ""
                },
                "recommendationTypeInterventionMultipleChoice": {
                    "description": "Tipo de recomendação",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Recomendação forte contra a intervenção",
                            "value": "recommend_against"
                        },
                        {
                            "direction": "negative",
                            "text": "Recomendação condicional contra a intervenção",
                            "value": "suggest_against"
                        },
                        {
                            "direction": "none",
                            "text": "Recomendação condicional para a intervenção ou para a comparação",
                            "value": "suggest_either"
                        },
                        {
                            "direction": "positive",
                            "text": "Recomendação condicional para a intervenção",
                            "value": "suggest"
                        },
                        {
                            "direction": "positive",
                            "text": "Forte recomendação para a intervenção",
                            "value": "recommend"
                        }
                    ],
                    "selectedOptions": {}
                },
                "recommendationTypeOption": {
                    "content": "",
                    "description": "Tipo de recomendação",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Forte recomendação contra a opção",
                            "value": "recommend_against"
                        },
                        {
                            "direction": "negative",
                            "text": "Recomendação condicional contra a opção",
                            "value": "suggest_against"
                        },
                        {
                            "direction": "none",
                            "text": "Recomendação condicional para a opção ou para a comparação",
                            "value": "suggest_either"
                        },
                        {
                            "direction": "positive",
                            "text": "Recomendação condicional para a opção",
                            "value": "suggest"
                        },
                        {
                            "direction": "positive",
                            "text": "Forte recomendação para a opção",
                            "value": "recommend"
                        }
                    ],
                    "selectedOption": ""
                },
                "researchPriorities": {
                    "content": "",
                    "description": "Prioridades de investigação"
                },
                "restrictions": {
                    "content": "",
                    "description": "Restrições"
                },
                "subgroupConsiderations": {
                    "content": "",
                    "description": "Considerações do subgrupo"
                },
                "theMessageIsNecessary": {
                    "content": "",
                    "description": "A mensagem é necessária no que diz respeito à prática efetiva dos cuidados de saúde",
                    "options": [
                        {
                            "text": "Sim",
                            "value": "yes"
                        },
                        {
                            "text": "Provavelmente sim",
                            "value": "probably_yes"
                        },
                        {
                            "text": "Provavelmente não",
                            "value": "probably_no"
                        },
                        {
                            "text": "Não",
                            "value": "no"
                        }
                    ],
                    "selectedOption": ""
                }
            }
        },
        "presentations": {
            "sections": {
                "clinicians": {
                    "name": "Médicos",
                    "sections": {
                        "background": {
                            "name": "Antecedentes"
                        },
                        "detailedJustification": {
                            "name": "Justificação detalhada"
                        },
                        "implementationConsiderations": {
                            "name": "Considerações sobre a implementação"
                        },
                        "justification": {
                            "name": "Justificação"
                        },
                        "relatedRecommendations": {
                            "name": "Recomendações relacionadas"
                        },
                        "subgroupConsiderations": {
                            "name": "Considerações do subgrupo"
                        },
                        "summaryOfFindings": {
                            "name": "Resumo das conclusões"
                        }
                    },
                    "sectionsOrder": []
                },
                "patients": {
                    "name": "Pacientes",
                    "sections": {
                        "relatedRecommendations": {
                            "name": "Recomendações relacionadas"
                        },
                        "summaryOfFindings": {
                            "name": "SoF"
                        },
                        "whatItMeansForYou": {
                            "name": "O que significa isto para si?",
                            "parts": {
                                "speakWithHCProfessional": {
                                    "additionalFields": [
                                        {
                                            "content": ""
                                        },
                                        {
                                            "content": ""
                                        }
                                    ],
                                    "content": "",
                                    "name": "Falar com um profissional de saúde"
                                },
                                "whatYouCanDo": {
                                    "content": "",
                                    "name": "O que pode fazer"
                                }
                            },
                            "sectionsOrder": [
                                "whatYouCanDo",
                                "speakWithHCProfessional"
                            ]
                        },
                        "whoIsThisFor": {
                            "name": "A quem se destina isto?",
                            "parts": {
                                "whoIsThisFor": {
                                    "additionalFields": [
                                        {
                                            "content": ""
                                        }
                                    ],
                                    "content": "",
                                    "name": "A quem se destina isto"
                                }
                            },
                            "sectionsOrder": [
                                "whoIsThisFor"
                            ]
                        },
                        "whyThisRecommendation": {
                            "name": "Porquê esta recomendação?",
                            "parts": {
                                "additionalInformation": {
                                    "content": "",
                                    "name": "Informação adicional"
                                },
                                "benefitsAndHarms": {
                                    "content": "",
                                    "name": "Benefícios e riscos"
                                },
                                "whyThisRecommendation": {
                                    "content": "",
                                    "name": "Com esta recomendação"
                                }
                            },
                            "sectionsOrder": [
                                "whyThisRecommendation",
                                "benefitsAndHarms",
                                "additionalInformation"
                            ]
                        }
                    },
                    "sectionsOrder": []
                },
                "policymakers": {
                    "name": "Decisores políticos",
                    "sections": {
                        "assessment": {
                            "name": "Avaliação"
                        },
                        "background": {
                            "description": "Detalhes da pergunta para recomendação",
                            "details": {
                                "name": "Acerca desta decisão",
                                "sections": {
                                    "date": {
                                        "content": "",
                                        "description": "Data"
                                    },
                                    "decisionMakers": {
                                        "content": "",
                                        "description": "Decisores"
                                    },
                                    "perspective": {
                                        "content": "",
                                        "description": "Perspectiva"
                                    },
                                    "setting": {
                                        "content": "",
                                        "description": "Contexto"
                                    }
                                },
                                "sectionsOrder": [
                                    "setting",
                                    "perspective",
                                    "decisionMakers",
                                    "date"
                                ]
                            },
                            "name": "Antecedentes"
                        },
                        "decision": {
                            "content": "",
                            "name": "Decisão",
                            "options": [
                                {
                                    "text": "Implementação completa",
                                    "value": "full_implementation"
                                },
                                {
                                    "text": "Avaliação de impacto",
                                    "value": "impact_evaluation"
                                },
                                {
                                    "text": "Estudo piloto",
                                    "value": "pilot_study"
                                },
                                {
                                    "text": "Adiar",
                                    "value": "postpone"
                                },
                                {
                                    "text": "Não implementar",
                                    "value": "do_not_implement"
                                }
                            ],
                            "selectedOption": ""
                        },
                        "implementation": {
                            "name": "Implementação"
                        },
                        "justification": {
                            "name": "Justificação"
                        },
                        "monitoringAndEvaluation": {
                            "name": "Controlo e avaliação"
                        },
                        "relatedRecommendations": {
                            "name": "Recomendações relacionadas"
                        },
                        "summaryOfFindings": {
                            "name": "Resumo das conclusões"
                        }
                    },
                    "sectionsOrder": []
                }
            },
            "sectionsOrder": []
        },
        "question": {
            "docId": "",
            "sections": {
                "age": {
                    "content": "",
                    "name": "Idade"
                },
                "anticipatedOutcomes": {
                    "content": "",
                    "name": "Resultados esperados"
                },
                "background": {
                    "content": "",
                    "name": "Antecedentes"
                },
                "coi": {
                    "content": "",
                    "name": "Conflito de interesses"
                },
                "comparison": {
                    "content": "",
                    "name": "Comparação"
                },
                "intent": {
                    "content": "",
                    "name": "Intenção"
                },
                "intervention": {
                    "content": "",
                    "name": "Intervenção"
                },
                "linkedTreatments": {
                    "content": "",
                    "name": "Tratamentos associados"
                },
                "mainOutcomes": {
                    "content": "",
                    "name": " Principais resultados"
                },
                "option": {
                    "content": "",
                    "name": "Opção"
                },
                "perspective": {
                    "content": "",
                    "name": "Perspectiva"
                },
                "population": {
                    "content": "",
                    "name": "População"
                },
                "problem": {
                    "content": "",
                    "name": "Problema"
                },
                "purpose": {
                    "content": "",
                    "name": "Objetivo do teste"
                },
                "requirements": {
                    "content": "",
                    "name": "Requisitos"
                },
                "role": {
                    "content": "",
                    "name": "Papel do teste"
                },
                "setting": {
                    "content": "",
                    "name": "Contexto"
                },
                "subgroups": {
                    "content": "",
                    "name": "Subgrupos"
                }
            },
            "sectionsOrder": [],
            "type": ""
        }
    };
};
