AddManualResultsModal = require 'components/panel_voice/modals/add_manual_results_modal'
AdolopedEtdStore = require 'stores/adoloped_etd_store'
AdolopmentDataStore = require 'stores/adolopment_data_store'
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdActions = require 'actions/etd_actions'
EtdStore = require 'stores/etd_store'
Header = require 'components/etd/etd_header'
HistoryEtdSections = require 'components/etd/panel_voice_etd/history_etd_sections'
HistoryFilters = require 'components/etd/panel_voice_etd/history_filters'
mediator = require 'mediator'
MembersStore = require 'stores/members_store'
OrganizationsStore = require 'stores/organizations_store'
OverarchingQuestionsStore = require 'stores/overarching_questions_store'
PanelVoiceActions = require 'actions/panel_voice_actions'
PanelVoiceEtdActions = require 'actions/panel_voice_etd_actions'
PanelVoiceEtdStore = require 'stores/panel_voice_etd_store'
PanelVoiceStore = require 'stores/panel_voice_store'
QuestionsActions = require 'actions/questions_actions'
QuestionsStatusesStore = require 'stores/questions_statuses_store'
QuestionsStore = require 'stores/questions_store'
ReferencesStore = require 'stores/references_store'
Spinner = require 'components/common/spinner'
ToggleSwitch = require 'components/common/toggle_switch'
Translation = require 'components/mixins/translation'
{ OVERARCHING_V2_TEMPLATES } = require 'lib/etd_helper'
UserProjectDataStore = require 'stores/user_project_data_store'
ViewSettingsControl = require 'components/etd/panel_voice_etd/etd_view_settings_control'
WorkspaceTab = require 'components/etd/panel_voice_etd/workspace_tab'
{ getFirstKey } = require 'lib/immutable_utils'
{ Tab, TabsContainer } = require 'components/common/tabs_container'

storeConnectors =
  AdolopmentDataStore: (Store) ->
    adolopmentData: Store.getAdolopmentData()
  AdolopedEtdStore: (Store) ->
    adolopments: Store.getAdolopments()
    isFetchingAdolopment: Store.isFetching()
  EtdStore: (Store) ->
    additionalConsiderations: Store.getAdditionalConsiderations()
    assessmentSections: Store.getAssessmentSections()
    attachments: Store.getAttachments()
    conclusionsSections: Store.getConclusionsSections()
    criterions: Store.getCriterions()
    etdId: Store.getEtdId()
    headerSections: Store.getHeaderSections()
    isFetchingEtd: Store.isFetching()
    isHeaderEditing: Store.isHeaderEditing()
    isHeaderExpanded: Store.isHeaderExpanded()
    researchEvidences: Store.getResearchEvidences()
    sojStatus: Store.getSojStatus()
    templateUndefined: Store.isTemplateUndefined()
    template: Store.getTemplate()
  MembersStore: (Store) ->
    isFetchingMembers: Store.isFetching()
  OrganizationsStore: (Store) ->
    projectFromOrganization: Store.isCurrentProjectFromOrganization()
  OverarchingQuestionsStore: (Store) ->
    isFetchingOverarchingQuestion: Store.isFetching()
    overarchingQuestionData: Store.getCurrentQuestion()
  PanelVoiceStore: (Store, props) ->
    { questionId } = props
    addManualResultsModalOpened: Store.isAddManualResultsModalOpened()
    denormalizedEtdVotingData: Store.getDenormalizedEtdVotingData(questionId)
    etdHighlights: Store.getEtdHighlights(questionId)
    etdSnapshots: Store.getEtdSnapshots(questionId)
    isFetchingEtdHighlightDocs: Store.isFetchingEtdHighlightDocs()
    isFetchingEtdSnapshots: Store.isFetchingEtdSnapshots()
    isFetchingPannelVoiceData: Store.isFetchingMetadata() or Store.isFetchingVotingResults()
    panelVoicePreferences: Store.getPanelVoicePreferences()
    votingResultsByTimestamp: Store.getQuestionVotingResultsByTimestamp(questionId)
  PanelVoiceEtdStore: (Store) ->
    activeJudgmentOptionBySection: Store.getActiveJudgmentOptionBySection()
    activeResultsViewTypeBySection: Store.getResultsViewTypeBySection()
    activeRound: Store.getActiveRound()
    activeStage: Store.getActiveStage()
    activeTab: Store.getActiveTab()
    editingSectionId: Store.getEditingSectionId()
    getAdminVotingSummary: Store.getAdminVotingSummary
    isAnonymousMode: Store.getAnonymousMode()
    isFetchingAdminSummaries: Store.isFetchingAdminSummaries()
    isFetchingViewSettings: Store.isFetchingViewSettings()
    viewSettings: Store.getViewSettings()
  QuestionsStore: (Store, props) ->
    questionData: Store.getQuestion(props.questionId)
    outcomes: Store.getAllOutcomes(props.questionId)
    criticalOutcomes: Store.getCriticalOutcomes(props.questionId)
    isFetchingQuestion: Store.isFetching()
  QuestionsStatusesStore: (Store, props) ->
    questionStatuses: Store.getQuestionStatuses props.questionId
  ReferencesStore: (Store) ->
    isFetchingReferences: Store.isFetching()
  UserProjectDataStore: (Store, props) ->
    collapsedAssessmentSections: Store.getCollapsedAssessmentSections()
    etdViewSettings: Store.getEtdViewSettings()
    hiddenSojSections: Store.getHiddenSojSections()
    isFetchingUserData: Store.isFetching()

PanelVoiceEtd = createReactClass
  displayName: 'PanelVoiceEtd'

  propTypes:
    additionalConsiderations: PropTypes.instanceOf(Immutable.Map)
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired,
    assessmentSections: PropTypes.instanceOf(Immutable.Map)
    attachments: PropTypes.instanceOf(Immutable.Map)
    collapsedAssessmentSections: PropTypes.instanceOf(Immutable.Map)
    conclusionsSections: PropTypes.instanceOf(Immutable.Map)
    criterions: PropTypes.instanceOf(Immutable.Map)
    criticalOutcomes: PropTypes.instanceOf(Immutable.List)
    denormalizedEtdVotingData: PropTypes.instanceOf(Immutable.Map)
    editingSectionId: PropTypes.string
    etdId: PropTypes.string.isRequired,
    etdSnapshots: PropTypes.instanceOf(Immutable.Map)
    etdViewSettings: PropTypes.instanceOf(Immutable.Map).isRequired,
    getAdminVotingSummary: PropTypes.func.isRequired
    headerSections: PropTypes.instanceOf(Immutable.Map)
    isFetchingAdminSummaries: PropTypes.bool.isRequired
    isFetchingEtd: PropTypes.bool.isRequired
    isFetchingEtdSnapshots: PropTypes.bool.isRequired
    isFetchingMembers: PropTypes.bool.isRequired
    isFetchingPannelVoiceData: PropTypes.bool.isRequired
    isFetchingQuestion: PropTypes.bool.isRequired
    isFetchingReferences: PropTypes.bool.isRequired
    isHeaderEditing: PropTypes.bool.isRequired
    isHeaderExpanded: PropTypes.bool.isRequired
    outcomes: PropTypes.instanceOf(Immutable.List)
    template: PropTypes.instanceOf(Immutable.Map).isRequired
    type: PropTypes.oneOf(['overarching', 'keyMessages', 'regular', 'qualityIndicators'])
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map)
    questionData: PropTypes.instanceOf(Immutable.Map).isRequired
    renderMode: PropTypes.oneOf(['regular', 'printout', 'mcSource']).isRequired
    researchEvidences: PropTypes.instanceOf(Immutable.Map)
    showResultingDataOnly: PropTypes.bool
    sojStatus: PropTypes.string.isRequired
    templateUndefined: PropTypes.bool
    viewSettings: PropTypes.instanceOf(Immutable.Map).isRequired

  getDefaultProps: ->
    renderMode: 'regular'
    type: 'regular'
    overarchingQuestionData: Immutable.Map()
    qualityIndicators: Immutable.List()
    showResultingDataOnly: false

  mixins: [
    Translation('es:recommendations.table')
    CustomRenderMixin
  ]

  containerRef: (el) ->
    @recommendationsContainerParent = el?.parentNode

  tabRef: (el) ->
    @tabContainerRef = el

  recalculateTabSize: ->
    @tabContainerRef.forceUpdate()

  routeToAdministrationModule: (e) ->
    e.preventDefault()
    mediator.publish '!router:route', Router::getProjectRelativeUrl("/administration")

  _isFetchingData: ->
    @props.isFetchingEtd or
      @props.isFetchingAdolopments or
      @props.isFetchingQuestion or
      @props.isFetchingPannelVoiceData or
      @props.isFetchingEtdHighlightDocs or
      @props.isFetchingEtdSnapshots or
      @props.isFetchingReferences or
      @props.isFetchingMembers or
      @props.isFetchingViewSettings or
      @props.isFetchingAdminSummaries or
      @props.isFetchingUserData or
      @props.isFetchingOverarchingQuestion

  _getInterventionComparison: ->
    if @props.questionData.get('type') is 'diagnostic'
      [@props.questionData.get('indexTest'), @props.questionData.get('comparatorTest')]
    else
      [@props.questionData.get('intervention'), @props.questionData.get('comparison')]

  _getQuestionType: ->
    if @props.questionData.get('type') is 'diagnostic' then 'dx' else 'tx'

  keepSojStatus: (status) ->
    EtdActions.keepSojStatus status

  getLastVotingResultsByPart: ->
    # pick last timestamp in each stage and get corresponding results
    # we could prepare this data somewhere within Store, but that way we wouldn't be able to reuse
    # already calculated `questionVotingResultsByTimestamp`, which are not stored as separate
    # value in Store and which calculation is expensive
    { votingResultsByTimestamp, denormalizedEtdVotingData } = @props
    denormalizedEtdVotingData.get('stages')
      .map (stage) ->
        stage.get('rounds').keySeq().last()
      .reduce (acc, timestamp) ->
        acc.merge votingResultsByTimestamp.get "#{timestamp}"
      , Immutable.Map()

  getEtdViewSettings: ->
    { viewSettings } = @props
    _.map ['anonymousVotingResults', 'hideVotingResults'], (settingName) =>
      label: @i18n _.str.underscored settingName
      isActive: viewSettings.get settingName, false
      name: settingName
      # disabled anonymous setting if voting results are hidden
      disabled: settingName is 'anonymousVotingResults' and viewSettings.get 'hideVotingResults'

  handleViewSettingChange: (evt) ->
    settingName = evt.target.getAttribute 'name'
    isActive = evt.target.checked
    { etdId } = @props

    PanelVoiceEtdActions.updateViewSetting { settingName, isActive, etdId }

  historyTab: ->
    {
      activeJudgmentOptionBySection
      activeResultsViewTypeBySection
      activeRound
      activeStage
      collapsedAssessmentSections
      denormalizedEtdVotingData
      etdId
      etdSnapshots
      etdViewSettings
      getAdminVotingSummary
      type
      overarchingQuestionData
      qualityIndicators
      questionData
      renderMode
      renderOptions
      template
      viewSettings
      votingResultsByTimestamp
    } = @props

    isAnonymousMode = viewSettings.get 'anonymousVotingResults'
    collapsedAssessmentSections = collapsedAssessmentSections.get(etdId, Immutable.Map())
    templateId = template.getIn(['templateDef', 'id'])
    votingStages = denormalizedEtdVotingData.get 'stages'
    showRecommendationsPart = templateId in OVERARCHING_V2_TEMPLATES or type isnt 'overarching'

    <div className='panel-voice-etd__history-tab'>
      <HistoryEtdSections
        activeJudgmentOptionBySection={activeJudgmentOptionBySection}
        activeResultsViewTypeBySection={activeResultsViewTypeBySection}
        activeRound={activeRound}
        activeStage={activeStage}
        collapsedAssessmentSections={collapsedAssessmentSections}
        etdHighlightsByRoundMap={PanelVoiceStore.getEtdHighlightsByRoundMap etdId}
        etdId={etdId}
        etdSnapshots={etdSnapshots}
        etdViewSettings={etdViewSettings.get(etdId, Immutable.Map())}
        getAdminVotingSummary={getAdminVotingSummary}
        hideVotingResults={viewSettings.get 'hideVotingResults'}
        memberNamesMap={MembersStore.getMemberNamesMap isAnonymousMode}
        showRecommendationsPart={showRecommendationsPart}
        overarchingQuestionData={overarchingQuestionData}
        qualityIndicators={qualityIndicators}
        questionName={questionData.get 'question', ""}
        questionType={@_getQuestionType()}
        renderMode={renderMode}
        renderOptions={renderOptions}
        templateId={templateId}
        type={type}
        votingResultsByTimestamp={votingResultsByTimestamp}
        votingStages={votingStages}
      />
    </div>

  workspaceTab: ->
    {
      activeJudgmentOptionBySection
      activeResultsViewTypeBySection
      additionalConsiderations
      adolopmentData
      adolopments
      assessmentSections
      attachments
      collapsedAssessmentSections
      conclusionsSections
      criterions
      criticalOutcomes
      editingSectionId
      etdHighlights
      etdId
      etdSnapshots
      etdViewSettings
      getAdminVotingSummary
      hiddenSojSections
      outcomes
      overarchingQuestionData
      qualityIndicators
      questionData
      renderMode
      renderOptions
      researchEvidences
      showResultingDataOnly
      sojStatus
      template
      type
      viewSettings
    } = @props

    hiddenSojSections = hiddenSojSections.get(etdId, Immutable.Map())
    collapsedAssessmentSections = collapsedAssessmentSections.get(etdId, Immutable.Map())
    templateId = template.getIn(['templateDef', 'id'])
    isAnonymousMode = viewSettings.get 'anonymousVotingResults'
    showRecommendationsPart = templateId in OVERARCHING_V2_TEMPLATES or type isnt 'overarching'
    unless questionData.isEmpty()
      [ intervention, comparison ] = @_getInterventionComparison()
    <WorkspaceTab
      activeJudgmentOptionBySection={activeJudgmentOptionBySection}
      activeResultsViewTypeBySection={activeResultsViewTypeBySection}
      additionalConsiderations={additionalConsiderations}
      adolopmentData={adolopmentData.get(etdId, Immutable.Map())}
      adolopments={adolopments}
      assessmentSections={assessmentSections}
      attachments={attachments}
      collapsedAssessmentSections={collapsedAssessmentSections}
      comparison={comparison}
      conclusionsSections={conclusionsSections}
      criterions={criterions}
      criticalOutcomes={criticalOutcomes}
      editingSectionId={editingSectionId}
      etdHighlights={etdHighlights}
      etdId={etdId}
      etdSnapshots={etdSnapshots}
      etdViewSettings={etdViewSettings.get(etdId, Immutable.Map())}
      getAdminVotingSummary={getAdminVotingSummary}
      hiddenSojSections={hiddenSojSections}
      hideVotingResults={viewSettings.get 'hideVotingResults'}
      intervention={intervention}
      keepSojStatus={@keepSojStatus}
      memberNamesMap={MembersStore.getMemberNamesMap isAnonymousMode}
      showRecommendationsPart={showRecommendationsPart}
      overarchingQuestionData={overarchingQuestionData}
      outcomes={outcomes}
      questionName={questionData.get 'question', ""}
      questionType={@_getQuestionType()}
      renderMode={renderMode}
      renderOptions={renderOptions}
      researchEvidences={researchEvidences}
      showResultingDataOnly={showResultingDataOnly}
      sojStatus={sojStatus}
      templateId={templateId}
      type={type}
      qualityIndicators={qualityIndicators}
      votingResults={@getLastVotingResultsByPart()}
    />

  renderPrintout: ->
    if @props.activeTab is "history"
      @historyTab()
    else
      @workspaceTab()

  renderTabsContainer: ->
    <TabsContainer
      activeTab={@props.activeTab}
      changeTab={PanelVoiceEtdActions.changeTab}
      fitToScreenBottom
      withTabsPane
      ref={@tabRef}
    >
      <Tab tabName='history' tabLabel={@i18n 'tabs.history'}>
        {@historyTab()}
      </Tab>
      <Tab tabName='workspace' tabLabel={@i18n 'tabs.workspace'}>
        {@workspaceTab()}
      </Tab>
    </TabsContainer>

  render: ->
    return <Spinner /> if @_isFetchingData()

    if @props.templateUndefined
      <div>
        {@i18n 'template_undefined', {},
          link: <a href='#' onClick={@routeToAdministrationModule}>{@i18n 'etd_templates'}</a>
        }
      </div>
    else
      questionType = @_getQuestionType()
      { assessmentSections } = @props

      <div className='panel-voice-etd' ref={@containerRef}>
        <Header
          headerSections={@props.headerSections}
          headerTransitionEnd={@recalculateTabSize}
          isHeaderEditing={@props.isHeaderEditing}
          isHeaderExpanded={@props.isHeaderExpanded}
          type={@props.type}
          overarchingQuestionData={@props.overarchingQuestionData}
          projectFromOrganization={@props.projectFromOrganization}
          question={@props.questionData}
          questionOutcomes={@props.outcomes}
          questionStatuses={@props.questionStatuses}
          questionType={questionType}
          renderMode={@props.renderMode}
        />

        { if @props.renderMode is 'printout'
            @renderPrintout()
          else
            <div>
              <div className='table-container'>
                <div>
                  {if @props.activeTab is 'history' and @props.renderMode isnt 'printout'
                    <HistoryFilters
                      votingStages={@props.denormalizedEtdVotingData.get 'stages'}
                      activeStage={@props.activeStage}
                      activeRound={@props.activeRound}
                    />
                  }
                  <div className='controls-container'>
                    {if @props.activeTab is 'workspace'
                      <button
                        className='plus add-results'
                        onClick={PanelVoiceActions.showAddManualResultsModal}
                      >
                        {@i18n 'add_results_manually'}
                      </button>
                    }
                    {if @props.addManualResultsModalOpened
                      <AddManualResultsModal
                        questionId={@props.questionId}
                        etdId={@props.etdId}
                      />
                    }
                    <ViewSettingsControl
                      tooltipProps={
                        onSettingChange: @handleViewSettingChange
                        settings: @getEtdViewSettings()
                      }
                    />
                  </div>
                  {@renderTabsContainer()}
                </div>
              </div>
            </div>
          }
      </div>

module.exports = ConnectStore(
  PanelVoiceEtd
  [
    AdolopedEtdStore,
    AdolopmentDataStore,
    EtdStore,
    MembersStore,
    OrganizationsStore
    OverarchingQuestionsStore
    PanelVoiceEtdStore,
    PanelVoiceStore,
    QuestionsStatusesStore,
    QuestionsStore,
    ReferencesStore,
    UserProjectDataStore,
  ],
  storeConnectors
)
