var DEFAULT_CONTENT_BLOCKS, DOC_SECTIONS, DocSectionsFromTemplatePrintout, createContentBlock, exportDocTemplate, getAllSubsectionsContent, getChapters, getChaptersOrder, getHTMLMarkupForDocumentSectionsFromTemplate, getOutcomesContentBlocks, getQuestionsContentBlocks, getReferenceIds, mediator, referenceIdsFromContent;

createContentBlock = Editor.EditorUtils.createContentBlock;

DocSectionsFromTemplatePrintout = require('components/document_sections/from_template/doc_sections_from_template_printout');

exportDocTemplate = require('views/templates/printouts/document_sections_from_template');

mediator = require('mediator');

referenceIdsFromContent = require('lib/references_utils').referenceIdsFromContent;

DOC_SECTIONS = ['document_title', 'document_subtitle', 'authors', 'disclosure_coi', 'review_group', 'table_of_contents', 'executive_summary', 'introduction', 'methodology', 'how_to_use', 'key_questions', 'recommendations', 'introduction_and_background', 'scope', 'methods', 'group_composition', 'group_meetings', 'appendices'];

DEFAULT_CONTENT_BLOCKS = [
  {
    type: 'DOCUMENT_SECTION',
    text: ' ',
    data: {
      _isProtected: true,
      sectionName: 'document_title'
    }
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.document_subtitle'),
    data: {
      sectionName: 'document_subtitle'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.authors'),
    data: {
      _isProtected: true,
      sectionName: 'authors'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.disclosure_coi'),
    data: {
      _isProtected: true,
      sectionName: 'disclosure_coi'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.review_group'),
    data: {
      _isProtected: true,
      sectionName: 'review_group'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.table_of_contents'),
    data: {
      _isProtected: true,
      sectionName: 'table_of_contents'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }, {
    type: 'ordered-list-item',
    text: '...'
  }, {
    type: 'ordered-list-item',
    text: '...'
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.executive_summary'),
    data: {
      _isProtected: true,
      sectionName: 'executive_summary'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.introduction'),
    data: {
      _isProtected: true,
      sectionName: 'introduction'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD', 'ITALIC']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.methodology'),
    data: {
      _isProtected: true,
      sectionName: 'methodology'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD', 'ITALIC']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.how_to_use'),
    data: {
      _isProtected: true,
      sectionName: 'how_to_use'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD', 'ITALIC']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.key_questions'),
    data: {
      _isProtected: true,
      sectionName: 'key_questions'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD', 'ITALIC']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.recommendations'),
    data: {
      _isProtected: true,
      sectionName: 'recommendations'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.introduction_and_background'),
    data: {
      _isProtected: true,
      sectionName: 'introduction_and_background'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.scope'),
    data: {
      _isProtected: true,
      sectionName: 'scope'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.methods'),
    data: {
      _isProtected: true,
      sectionName: 'methods'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.group_composition'),
    data: {
      _isProtected: true,
      sectionName: 'group_composition'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD', 'ITALIC']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.group_meetings'),
    data: {
      _isProtected: true,
      sectionName: 'group_meetings'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD', 'ITALIC']
  }, {
    type: 'unstyled',
    text: ' '
  }, {
    type: 'DOCUMENT_SECTION',
    text: $.t('docsec:sections.appendices'),
    data: {
      _isProtected: true,
      sectionName: 'appendices'
    },
    inlineStyles: ['LARGE_FONT', 'BOLD']
  }
];

getQuestionsContentBlocks = function(questions) {
  var mentionedQuestions, mentionedQuestionsContentBlocks, questionsContentBlocks, rejectedQuestions, rejectedQuestionsContentBlocks;
  questionsContentBlocks = [];
  rejectedQuestions = questions.filter(function(q) {
    return q.get('approvalStatus') === 'rejected';
  });
  mentionedQuestions = questions.filter(function(q) {
    return q.get('approvalStatus') === 'mentioned';
  });
  if (mentionedQuestions.isEmpty() && rejectedQuestions.isEmpty()) {
    return questionsContentBlocks;
  }
  if (!mentionedQuestions.isEmpty()) {
    mentionedQuestionsContentBlocks = mentionedQuestions.reduce(function(questions, q) {
      return questions.concat({
        type: 'unordered-list-item',
        text: q.get('question')
      });
    }, []);
    questionsContentBlocks = questionsContentBlocks.concat({
      type: 'DOCUMENT_SECTION',
      text: $.t('docsec:sections.mentioned_questions'),
      data: {
        sectionName: 'questions_mentioned'
      },
      inlineStyles: ['BOLD']
    }, mentionedQuestionsContentBlocks);
  }
  if (!rejectedQuestions.isEmpty()) {
    rejectedQuestionsContentBlocks = rejectedQuestions.reduce(function(questions, q) {
      return questions.concat({
        type: 'unordered-list-item',
        text: q.get('question')
      });
    }, []);
    questionsContentBlocks = questionsContentBlocks.concat({
      type: 'DOCUMENT_SECTION',
      text: $.t('docsec:sections.rejected_questions'),
      data: {
        sectionName: 'questions_rejected'
      },
      inlineStyles: ['BOLD']
    }, rejectedQuestionsContentBlocks);
  }
  return questionsContentBlocks;
};

getOutcomesContentBlocks = function(qGroups) {
  return [
    {
      type: 'DOCUMENT_SECTION',
      text: $.t('docsec:all_considered_outcomes'),
      data: {
        sectionName: 'generated_outcomes'
      },
      inlineStyles: ['BOLD']
    }, {
      type: 'atomic',
      text: ' ',
      entityData: {
        type: 'OUTCOMES_TABLE',
        mutability: 'IMMUTABLE',
        data: {
          qGroups: qGroups
        }
      }
    }, {
      type: 'unstyled',
      text: ' '
    }
  ];
};

getChapters = function(document) {
  if (document == null) {
    document = Immutable.Map();
  }
  return document.getIn(['templateData', 'chapters', 'sections']);
};

getChaptersOrder = function(document) {
  if (document == null) {
    document = Immutable.Map();
  }
  return document.getIn(['templateData', 'chapters', 'sectionsOrder']);
};

getAllSubsectionsContent = function(document) {
  var chapters, chaptersOrder;
  if (document == null) {
    document = Immutable.Map();
  }
  chaptersOrder = getChaptersOrder(document);
  if (!chaptersOrder) {
    return Immutable.List();
  }
  chapters = getChapters(document);
  return chaptersOrder.flatMap(function(chapter) {
    return chapters.getIn([chapter, 'subsections']);
  }).map(function(subsection) {
    return subsection.get('content');
  });
};

getReferenceIds = function(document) {
  return _.uniq(getAllSubsectionsContent(document).flatMap(referenceIdsFromContent).toArray());
};

getHTMLMarkupForDocumentSectionsFromTemplate = function(props) {
  var docSectionsHTML;
  docSectionsHTML = ReactDOMServer.renderToStaticMarkup(React.createElement(DocSectionsFromTemplatePrintout, _.extend({}, props, {
    renderMode: 'printout'
  })));
  return exportDocTemplate({
    docSectionsHTML: docSectionsHTML
  });
};

module.exports = {
  DOC_SECTIONS: DOC_SECTIONS,
  DEFAULT_CONTENT_BLOCKS: DEFAULT_CONTENT_BLOCKS,
  getOutcomesContentBlocks: getOutcomesContentBlocks,
  getQuestionsContentBlocks: getQuestionsContentBlocks,
  getHTMLMarkupForDocumentSectionsFromTemplate: getHTMLMarkupForDocumentSectionsFromTemplate,
  getChapters: getChapters,
  getChaptersOrder: getChaptersOrder,
  getAllSubsectionsContent: getAllSubsectionsContent,
  getReferenceIds: getReferenceIds
};
