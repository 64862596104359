AddProjectSplash = require 'components/projects/add_project_splash'
AnimatedCollectionItem = require 'components/enhancers/animated_collection_item'
Collapsible = require 'components/common/collapsible_with_controls'
CreateAdolopmentModal = require 'components/projects/create_adolopment_modal'
GlobalExportActions = require 'actions/global_export_actions'
mediator = require 'mediator'
ProjectActionsPopup = require 'components/projects/project_actions_popup'
ProjectEditModal = require 'components/projects/project_edit_modal'
ProjectLabelPopover = require 'components/projects/project_label_popup'
ProjectQuestions = require 'components/projects/project_questions'
ProjectReplication = require 'components/projects/project_replication'
ProjectsActions = require 'actions/projects_actions'
ProjectStartCounter = require 'components/projects/project_start_counter'
SendProjectCopyModal = require 'components/projects/send_project_copy_modal'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
{ MDG_WORKSPACE } = require 'lib/mdg_helper'
{ HEALTH_ADVISOR_WORKSPACE_ID } = require 'lib/mda_helper'
{ format: formatDate } = require 'lib/date_utils'
{ HEALTH_ADVISOR_WORKSPACE_ID } = require 'lib/mda_helper'
{ MDG_WORKSPACE } = require 'lib/mdg_helper'
{ getPublicationDateText, getDateModifiedText } = require 'lib/projects_helper'

{ filterOutAdoloped } = require 'lib/questions_helper'

ProjectsListItem = createReactClass
  displayName: 'ProjectsListItem'

  propTypes:
    project: PropTypes.instanceOf(Immutable.Map).isRequired
    projectQuestions: PropTypes.instanceOf(Immutable.Map)
    label: PropTypes.object
    organizationId: PropTypes.string
    className: PropTypes.string

  mixins: [ Translation() ]

  getDefaultProps: ->
    organizationId: null
    projectQuestions: Immutable.Map()

  getInitialState: ->
    showCreateAdolopmentDialog: false
    displayProjectActionsPopover: false
    showProjectEdit: false
    showSendCopyModal: false

  goToProject: ->
    { project } = @props
    return if project.get('locked') and not
      project.get('accessRights', Immutable.List()).includes 'admin'
    projectURL = "/projects/#{project.get 'id'}"

    mediator.publish '!router:route', projectURL

  saveProjectEdit: (editState) ->
    projectId = @props.project.get 'id'

    ProjectsActions.updateProjectData projectId, editState, @props.organizationId
    @toggleProjectEdit()

  toggleProjectEdit: ->
    @setState showProjectEdit: not @state.showProjectEdit

  toggleSendCopyDialog: ->
    @setState showSendCopyModal: not @state.showSendCopyModal

  toggleCreateAdolopmentDialog: ->
    @setState showCreateAdolopmentDialog: not @state.showCreateAdolopmentDialog

  handleActionsPopoverToggle: ->
    @maybeGetPermittedActions()
    @setState displayProjectActionsPopover: not @state.displayProjectActionsPopover

  handleProjectAction: (actionName) ->
    { project, organizationId } = @props
    projectId = project.get 'id'
    switch actionName
      when 'edit'
        @toggleProjectEdit()
      when 'delete'
        mediator.dialogs.confirm @i18n('projects:delete_confirmation'),
          (confirmed) -> ProjectsActions.deleteProject projectId if confirmed
      when 'copy'
        ProjectsActions.copyProject projectId, organizationId
      when 'archive'
        ProjectsActions.archiveProject projectId
      when 'moveToActive'
        ProjectsActions.unarchiveProject projectId
      when 'makeActive'
        ProjectsActions.restoreCopy projectId, organizationId
      when 'moveToOrganization'
        ProjectsActions.toggleMoveToOrganizationModal projectId
      when 'createAdolopment'
        @toggleCreateAdolopmentDialog()
      when 'sendCopy'
        @toggleSendCopyDialog()
      when 'globalExport'
        GlobalExportActions.openModal projectId

    @handleActionsPopoverToggle()

  maybeGetPermittedActions: ->
    { permittedActions, project } = @props
    projectId = project.get 'id'

    ProjectsActions.getPossibleActionsForProject(projectId) unless permittedActions?

  render: ->
    { project, className } = @props

    projectQuestions = @props.projectQuestions.filter filterOutAdoloped
    projectId = project.get 'id'
    name = _.string.unescapeHTML(project.get 'name')
    customer = project.get('customer') or '--'
    captionClassName = classNames active: @state.displayProjectActionsPopover, className

    <Collapsible captionClassName={captionClassName}>
      <div className='projects-list__project--inner'>
        <ProjectLabelPopover
          projectId={projectId}
          labelsData={@props.labelsData}
          projectLabel={@props.projectLabel}
          projectLabelId={@props.projectLabelId}
        />
        <div className='project-name-customer-details' onClick={@goToProject}>
          <div className='project-name'>
            {project.get('locked') and <Tooltip>
                <span title={@i18n 'projects:locked'} className="button project-locked" />
              </Tooltip>
            }
            <Tooltip>
              <span title={_.string.escapeHTML(name)}>
                {name}
              </span>
            </Tooltip>
          </div>
          {mediator.activeWorkspace not in [MDG_WORKSPACE, HEALTH_ADVISOR_WORKSPACE_ID] and
            <div className='project-customer'>{_.string.unescapeHTML(customer)}</div>
          }
          <div className='project-last-modified'>
            <div>
              <span className="project-last-modified__label">{@i18n 'projects:last_updated'}:</span>
              {getDateModifiedText(project.toJS())}
            </div>
            <div>
              <span className="project-last-modified__label">
                {@i18n 'projects:last_published'}:
              </span>
              {getPublicationDateText(project.toJS())}
            </div>
          </div>
        </div>
        {mediator.activeWorkspace is MDG_WORKSPACE and <ProjectStartCounter
          startDate={project.get('startDate', null)}
        />}
        <div className='project-replication'>
          <ProjectReplication
            projectId={projectId}
            collectionType={@props.collectionType}
            projectStatus={@props.projectStatus}
            lastSeq={@props.replicationProgress?.get 'lastSeq'}
            updateSeq={@props.replicationProgress?.get 'updateSeq'}
            dataSize={@props.dataSize}
          />
        </div>

        <div className='project-actions'>
          <ProjectActionsPopup
            projectId={projectId}
            projectStatus={@props.projectStatus}
            lastSeq={@props.replicationProgress?.get 'lastSeq'}
            updateSeq={@props.replicationProgress?.get 'updateSeq'}
            actions={@props.permittedActions}
            displayPopover={@state.displayProjectActionsPopover}
            onTogglePopover={@handleActionsPopoverToggle}
            onActionClick={@handleProjectAction}
          />
          {@state.showProjectEdit and
            <ProjectEditModal
              projectName={@props.project.get 'name'}
              customer={@props.project.get 'customer'}
              onRequestClose={@toggleProjectEdit}
              onSave={@saveProjectEdit}
            />
          }
          {@state.showSendCopyModal and
            <SendProjectCopyModal
              onClose={@toggleSendCopyDialog}
              projectId={projectId}
            />
          }
          {@state.showCreateAdolopmentDialog and
            <CreateAdolopmentModal
              onRequestClose={@toggleCreateAdolopmentDialog}
              organizationId={@props.organizationId}
              projectId={projectId}
            />
          }
        </div>
      </div>
      <div className='project-questions'>
        <h2>{@i18n('questions').toUpperCase()}</h2>
        <div className='items-list-container--numbered'>
          <ProjectQuestions questions={projectQuestions} projectId={projectId} />
        </div>
      </div>
    </Collapsible>

module.exports = AnimatedCollectionItem(
  AddProjectSplash(ProjectsListItem)
  animationsDuration: 300
)
