var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<colgroup>\n  <col class=\"c-1\" span=\"1\">\n  <col class=\"c-2\" span=\"1\">\n  <col class=\"c-3\" span=\"1\">\n  <col class=\"c-4\" span=\"1\">\n  <col class=\"right-button\" span=\"1\">\n</colgroup>\n  <tbody>\n    <tr class=\"title\">\n      <td colspan=\"4\">\n        <h1 class=\"";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.expand : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "question", {"name":"i18n","hash":{},"data":data})))
    + "</h1>\n      </td>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.expand : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tr>\n    <tr>\n      <td class=\"question first\" colspan=\"4\">\n        "
    + escapeExpression(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"question","hash":{},"data":data}) : helper)))
    + "\n      </td>\n    </tr>\n";
},"2":function(depth0,helpers,partials,data) {
  return "expanded";
  },"4":function(depth0,helpers,partials,data) {
  return "collapsed";
  },"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <td class=\"right-button\" rowspan=\"";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.program(13, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </td>\n";
},"7":function(depth0,helpers,partials,data) {
  return "12";
  },"9":function(depth0,helpers,partials,data) {
  return "10";
  },"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <button class=\"save\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.save_changes_title", {"name":"i18n","hash":{},"data":data})))
    + "\"></button>\n";
},"13":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <button class=\"edit\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "edit", {"name":"i18n","hash":{},"data":data})))
    + "\"></button>\n";
},"15":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.expand : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </tbody>\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <tr>\n      <td class=\"first\">\n        <span class=\"section-name\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "population", {"name":"i18n","hash":{},"data":data})))
    + ":\n        </span>\n      </td>\n      <td>\n        <span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(17, data),"inverse":this.program(19, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </span>\n      </td>\n      <td rowspa class=\"first\"n=\"";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"if","hash":{},"fn":this.program(21, data),"inverse":this.program(23, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n        <span class=\"section-name\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../table.background", {"name":"i18n","hash":{},"data":data})))
    + ":\n        </span>\n      </td>\n      <td rowspan=\"";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"if","hash":{},"fn":this.program(21, data),"inverse":this.program(23, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n        <span class=\"background-section\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(25, data),"inverse":this.program(27, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </span>\n      </td>\n    </tr>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"if","hash":{},"fn":this.program(29, data),"inverse":this.program(38, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"if","hash":{},"fn":this.program(47, data),"inverse":this.program(60, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <tr>\n      <td class=\"first\">\n        <span class=\"section-name\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../table.setting", {"name":"i18n","hash":{},"data":data})))
    + ":\n        </span>\n      </td>\n      <td>\n        <span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(65, data),"inverse":this.program(67, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </span>\n      </td>\n    </tr>\n    <tr>\n      <td class=\"first\">\n        <span class=\"section-name\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../table.perspective", {"name":"i18n","hash":{},"data":data})))
    + ":\n        </span>\n      </td>\n      <td>\n        <span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(69, data),"inverse":this.program(71, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </span>\n      </td>\n    </tr>\n";
},"17":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <div class=\"rows-2\" data-hook=\"headerPatientsField\" contenteditable=\"true\">\n            "
    + escapeExpression(((helper = (helper = helpers.headerPatientsField || (depth0 != null ? depth0.headerPatientsField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerPatientsField","hash":{},"data":data}) : helper)))
    + "\n          </div>\n";
},"19":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "          ";
  stack1 = ((helper = (helper = helpers.headerPatientsField || (depth0 != null ? depth0.headerPatientsField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerPatientsField","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"21":function(depth0,helpers,partials,data) {
  return "8";
  },"23":function(depth0,helpers,partials,data) {
  return "6";
  },"25":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <div class=\"rows-max editable-content\" data-hook=\"headerBackgroundField\"\n          contenteditable=\"true\">\n            "
    + escapeExpression(((helper = (helper = helpers.headerBackgroundField || (depth0 != null ? depth0.headerBackgroundField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerBackgroundField","hash":{},"data":data}) : helper)))
    + "\n          </div>\n";
},"27":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "          ";
  stack1 = ((helper = (helper = helpers.headerBackgroundField || (depth0 != null ? depth0.headerBackgroundField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerBackgroundField","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"29":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <tr>\n        <td class=\"first\">\n            <span class=\"section-name\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "intervention", {"name":"i18n","hash":{},"data":data})))
    + ":\n            </span>\n        </td>\n        <td>\n          <span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(30, data),"inverse":this.program(32, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          </span>\n        </td>\n      </tr>\n      <tr>\n        <td class=\"first\">\n          <span class=\"section-name\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../table.comparison", {"name":"i18n","hash":{},"data":data})))
    + ":\n          </span>\n        </td>\n        <td>\n          <span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(34, data),"inverse":this.program(36, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          </span>\n        </td>\n      </tr>\n";
},"30":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <input type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.headerIndexTestField || (depth0 != null ? depth0.headerIndexTestField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerIndexTestField","hash":{},"data":data}) : helper)))
    + "\" disabled=\"true\">\n";
},"32":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "            ";
  stack1 = ((helper = (helper = helpers.headerIndexTestField || (depth0 != null ? depth0.headerIndexTestField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerIndexTestField","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"34":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <input type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.headerComparatorTestField || (depth0 != null ? depth0.headerComparatorTestField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerComparatorTestField","hash":{},"data":data}) : helper)))
    + "\" disabled=\"true\">\n";
},"36":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "              ";
  stack1 = ((helper = (helper = helpers.headerComparatorTestField || (depth0 != null ? depth0.headerComparatorTestField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerComparatorTestField","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"38":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <tr>\n      <td class=\"first\">\n          <span class=\"section-name\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "intervention", {"name":"i18n","hash":{},"data":data})))
    + ":\n          </span>\n        </td>\n        <td>\n          <span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(39, data),"inverse":this.program(41, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          </span>\n        </td>\n      </tr>\n      <tr>\n      <td class=\"first\">\n        <span class=\"section-name\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../table.comparison", {"name":"i18n","hash":{},"data":data})))
    + ":\n          </span>\n        </td>\n        <td>\n          <span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(43, data),"inverse":this.program(45, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          </span>\n        </td>\n      </tr>\n";
},"39":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <input type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + "\" disabled=\"true\">\n";
},"41":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "            ";
  stack1 = ((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"43":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <input type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.comparison || (depth0 != null ? depth0.comparison : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparison","hash":{},"data":data}) : helper)))
    + "\" disabled=\"true\">\n";
},"45":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "              ";
  stack1 = ((helper = (helper = helpers.comparison || (depth0 != null ? depth0.comparison : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparison","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"47":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <tr>\n        <td class=\"first\">\n          <span class=\"section-name\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "purpose", {"name":"i18n","hash":{},"data":data})))
    + ":\n          </span>\n        </td>\n        <td>\n          <span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(48, data),"inverse":this.program(50, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          </span>\n        </td>\n      </tr>\n      <tr>\n        <td class=\"first\">\n          <span class=\"section-name\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "linked_treatment", {"name":"i18n","hash":{},"data":data})))
    + ":\n          </span>\n        </td>\n        <td>\n          <span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(52, data),"inverse":this.program(54, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "          </span>\n        </td>\n      </tr>\n      <tr>\n        <td class=\"first wider\">\n          <span class=\"section-name\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "anticipated_outcomes", {"name":"i18n","hash":{},"data":data})))
    + ":\n          </span>\n        </td>\n        <td>\n          <span class=\"outcomes-list\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(56, data),"inverse":this.program(58, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          </span>\n        </td>\n      </tr>\n";
},"48":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <div class=\"rows-1\" data-hook=\"headerPurposeField\" contenteditable=\"true\">\n              "
    + escapeExpression(((helper = (helper = helpers.headerPurposeField || (depth0 != null ? depth0.headerPurposeField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerPurposeField","hash":{},"data":data}) : helper)))
    + "\n            </div>\n";
},"50":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "              ";
  stack1 = ((helper = (helper = helpers.headerPurposeField || (depth0 != null ? depth0.headerPurposeField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerPurposeField","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"52":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <div class=\"rows-1\" data-hook=\"headerLinkedTreatmentField\" contenteditable=\"true\">\n              "
    + escapeExpression(((helper = (helper = helpers.headerLinkedTreatmentField || (depth0 != null ? depth0.headerLinkedTreatmentField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerLinkedTreatmentField","hash":{},"data":data}) : helper)))
    + "\n            </div>\n";
},"54":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "              ";
  stack1 = ((helper = (helper = helpers.headerLinkedTreatmentField || (depth0 != null ? depth0.headerLinkedTreatmentField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerLinkedTreatmentField","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"56":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <div class=\"rows-6 editable-content\" data-hook=\"headerAnticipatedOutcomes\"\n            contenteditable=\"true\">\n              "
    + escapeExpression(((helper = (helper = helpers.headerAnticipatedOutcomes || (depth0 != null ? depth0.headerAnticipatedOutcomes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerAnticipatedOutcomes","hash":{},"data":data}) : helper)))
    + "\n            </div>\n";
},"58":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "            <div>\n              ";
  stack1 = ((helper = (helper = helpers.headerAnticipatedOutcomes || (depth0 != null ? depth0.headerAnticipatedOutcomes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerAnticipatedOutcomes","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n            </div>\n";
},"60":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <tr>\n        <td class=\"first\">\n          <span class=\"section-name\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "main_outcomes", {"name":"i18n","hash":{},"data":data})))
    + ":\n          </span>\n        </td>\n        <td>\n          <span class=\"outcomes-list\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.editing : depth0), {"name":"if","hash":{},"fn":this.program(61, data),"inverse":this.program(63, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          </span>\n        </td>\n      </tr>\n";
},"61":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <div class=\"rows-6 editable-content\" data-hook=\"headerMainOutcomes\"\n            contenteditable=\"true\">\n              "
    + escapeExpression(((helper = (helper = helpers.headerMainOutcomes || (depth0 != null ? depth0.headerMainOutcomes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerMainOutcomes","hash":{},"data":data}) : helper)))
    + "\n            </div>\n";
},"63":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "            <div>\n              ";
  stack1 = ((helper = (helper = helpers.headerMainOutcomes || (depth0 != null ? depth0.headerMainOutcomes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerMainOutcomes","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n            </div>\n";
},"65":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <div class=\"rows-2\" data-hook=\"headerSettingField\" contenteditable=\"true\">\n            "
    + escapeExpression(((helper = (helper = helpers.headerSettingField || (depth0 != null ? depth0.headerSettingField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerSettingField","hash":{},"data":data}) : helper)))
    + "\n          </div>\n";
},"67":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "            ";
  stack1 = ((helper = (helper = helpers.headerSettingField || (depth0 != null ? depth0.headerSettingField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerSettingField","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"69":function(depth0,helpers,partials,data) {
  return "          <select data-hook=\"headerPerspectiveField\">\n          </select>\n";
  },"71":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helper = (helper = helpers.headerPerspectiveField || (depth0 != null ? depth0.headerPerspectiveField : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerPerspectiveField","hash":{},"data":data}) : helper)))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:question", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:recommendations.header", {"name":"i18nNamespace","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}