RadioInput = require 'components/common/radio_input'
Collapsible = require 'components/common/collapsible'
CommentsBlock = require 'components/scope/comments_block'
CommentButton = require 'components/common/empty_comment_button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
CommentsMixin = require 'components/mixins/outcomes_comments_mixin'
MedianRatingValue = require 'components/scope/common/median_rating_value'
IMPORTANCE_VALUES = ['critical', 'important', 'notImportant']

RatingTableRowWithComments = createReactClass
  displayName: 'RatingTableRowWithComments'

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
    CommentsMixin
  ]

  _getToggleButton: ->
    buttonClass = classNames 'comment-blank-hide': @state.showingComments,
      'comment-blank': not @state.showingComments
    <CommentButton
      className={buttonClass}
      title={@i18n 'view_members_comments'}
      onClick={@toggleComments}
    />

  _getCommentsBlocks: ->
    adminComments = @props.outcome.get 'adminComments'
    membersComments = @props.outcome.get 'memberComments'

    membersComments.toOrderedMap()
    .sortBy ((comments, stepName) -> stepName), ((a, b) -> b.localeCompare a)
    .map (comments, stepName) =>
      isRating = stepName is 'rating'
      adminComment = adminComments?.get stepName
      caption = <div className='step-name'>{@i18n "tabs.#{stepName}"}</div>
      commentsBlock =
        <CommentsBlock
          membersComments={comments}
          adminComment={adminComment}
          membersMap={@props.membersMap}
          readOnlyComments={@props.readOnly}
          onApplyAdminComment={@onApplyAdminComment(stepName)}
        />

      if isRating
        <div key={stepName}>
          { caption }
          { commentsBlock }
        </div>
      else
        <div key={stepName}>
          <Collapsible caption={caption}>
            { commentsBlock }
          </Collapsible>
        </div>
    .toList()

  render: ->

    name = @props.outcome.get 'name'
    importance = @props.outcome.get 'importance'
    gIndex = @props.gIndex
    outcomeKey = @props.outcome.get 'key'
    medianRating = @props.outcome.get 'medianRating'

    <tbody className={_.str.dasherize importance}>
      <tr>
        <td className='name'>{name}</td>
        {IMPORTANCE_VALUES.map (importanceVal, idx) =>
          <td key={idx}>
            <label>
              <RadioInput
                name="rating-#{gIndex}-#{outcomeKey}"
                value={importanceVal}
                checked={importance is importanceVal}
                disabled={@props.readOnly}
                onChange={@props.changeOutcomeImportance @props.gIndex, outcomeKey, importanceVal}
              />
            </label>
          </td>
        }
        <td>
          <MedianRatingValue medianRating={medianRating}/>
        </td>
        <td className='with-comments'>{@_getToggleButton()}</td>
      </tr>
      {if @state.showingComments
        <tr>
          <td colSpan={6} className='proposal-comments-summary'>
            {@_getCommentsBlocks()}
          </td>
        </tr>
      }
    </tbody>

module.exports = RatingTableRowWithComments
