var MDG_INITIAL_SEX, MdgScore, MdgSex, MdgStudy, arrayOf, boolean, documentId, documentIdWithAutoGUID, initial, number, oneOf, optional, shape, string, _ref;

arrayOf = require('lib/db_docs/field_types/array_of');

_ref = require('lib/db_docs/field_types/built_in_types'), boolean = _ref.boolean, number = _ref.number, string = _ref.string;

documentId = require('lib/db_docs/field_types/document_id');

documentIdWithAutoGUID = require('lib/db_docs/field_types/document_id_with_auto_guid');

initial = require('lib/db_docs/field_types/type_decorators').initial;

MDG_INITIAL_SEX = require('lib/mdg_helper').MDG_INITIAL_SEX;

MdgScore = require('lib/db_docs/field_types/mdg_score_type');

MdgSex = require('lib/db_docs/field_types/mdg_sex_type');

oneOf = require('lib/db_docs/field_types/one_of');

shape = require('lib/db_docs/field_types/shape');

optional = shape.typeDecorators.optional;

MdgStudy = shape({
  age: shape({
    type: initial('mean')(oneOf(['mean', 'range', 'other'])),
    value: initial('')(string),
    valueFrom: initial('')(string),
    valueTo: initial('')(string)
  }),
  areaOfBody: initial('')(string),
  caseDefinition: initial('')(string),
  category: initial('')(string),
  coi: shape({
    selected: optional(boolean),
    value: initial('')(string)
  }),
  comments: initial('')(string),
  internalComments: optional(string),
  comparativeTests: initial([])(arrayOf(string)),
  comparisons: initial('')(string),
  conclusion: initial('')(string),
  diagnoses: initial('')(string),
  dropoutRate: initial('')(string),
  exposure: initial('')(string),
  followUp: initial('')(string),
  id: documentIdWithAutoGUID,
  location: initial('')(string),
  longTermFollowUp: initial('')(string),
  mriOrCt: initial('')(string),
  parentStudy: optional(documentId),
  population: optional(string),
  referenceId: optional(documentId),
  results: initial('')(string),
  sampleSize: shape({
    count: optional(number),
    description: initial('')(string)
  }),
  score: MdgScore,
  sex: initial(MDG_INITIAL_SEX)(arrayOf(MdgSex)),
  shortName: initial('')(string),
  spectOrSpet: initial('')(string),
  studyDesign: initial('')(string),
  studyType: initial('')(string),
  typeOfCT: initial('')(string),
  typeOfMRI: initial('')(string),
  typeOfXRays: initial('')(string)
});

module.exports = MdgStudy;
