ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Button = require 'components/common/button'
EtdActions = require 'actions/etd_actions'
Modal = require 'components/common/modal'
Select = require 'components/common/select_custom'
MultiInterventionJudgementOption =
  require 'components/etd/judgements/multiintervention_judgement_option'
{ useState } = React
{ findWhere, convertListToOrderedMap } = require 'lib/immutable_utils'
{ useI18n, useCoffeeCallback, useCoffeeMemo } = require 'lib/react_utils'
{ getTagsByKeys } = require 'lib/etd_helper'

JudgementTable = ({
  criterion
  editable
  overarchingQuestionData
  parentSectionsData
  renderMode
  sectionId
  questionType
  templateId
}) ->
  i18n = useI18n('es:recommendations.table')
  [ modalOpen, setModalOpen ] = useState(false)
  openModal = useCoffeeCallback [], -> setModalOpen(true)
  closeModal = useCoffeeCallback [], -> setModalOpen(false)

  onApply = useCoffeeCallback [], ->
    EtdActions.save()
    closeModal()

  handleJudgement = useCoffeeCallback [], (sectionId, rowId, cellId) -> (value) ->
    EtdActions.setJudgementPerTableCell { sectionId, rowId, cellId, value }

  interventions = useCoffeeMemo [overarchingQuestionData], ->
    convertListToOrderedMap(
      getTagsByKeys(overarchingQuestionData.get('includedTags'), ['intervention'])
    , 'id')

  outcomes = useCoffeeMemo [overarchingQuestionData], ->
    convertListToOrderedMap(
      getTagsByKeys(overarchingQuestionData.get('includedTags'), ['outcome'])
    , 'id')

  parentSectionsCells = useCoffeeMemo [parentSectionsData],  -> 
    parentSectionsData.reduce (acc, data) ->
      acc.set data.get('criterionId'), Immutable.fromJS {
        id: data.get('criterionId')
        name: data.get('name')
        selectedOptions: data.get('selectedOptions')
        options: data.get('options').concat(data.get('additionalOptions'))
      }
    , Immutable.Map()

  grouppedTagsByOption = criterion.get('selectedOptions', Immutable.Map())
    .map (intervention) -> intervention.get('overall')
    .groupBy _.identity

  <div className="flex flex-col h-full">
    <div className="flex flex-col flex-grow">
      {criterion.get('options').map (option) ->
        <div key={option}>
          <MultiInterventionJudgementOption
            option={option}
            tags={interventions}
            grouppedTagsByOption={grouppedTagsByOption}
          />
        </div>
      .toList()}
      {unless criterion.get('additionalOptions', Immutable.Map()).isEmpty()
        <div>
          <hr />
          {criterion.get('additionalOptions').map (option) ->
            <div key={option}>
              <MultiInterventionJudgementOption
                option={option}
                tags={interventions}
                grouppedTagsByOption={grouppedTagsByOption}
              />
            </div>
          .toList()}
        </div>
      }
    </div>
    {renderMode isnt 'printout' and
      <div className="mt-10">
        <Button
          className="btn btn-block detailed-judgements-btn"
          label={i18n "multiintervention_judgement.judgement"}
          onClick={openModal}
        />
        <JudgementModal
          criterion={criterion}
          handleJudgement={handleJudgement}
          isOpen={modalOpen}
          onApply={onApply}
          onCloseModal={closeModal}
          questionType={questionType}
          sectionId={sectionId}
          templateId={templateId}
          useParentSectionsValues={not parentSectionsCells.isEmpty()}
          columns={if parentSectionsCells.isEmpty() then outcomes else parentSectionsCells}
          rows={interventions}
        />
      </div>
    }
  </div>

JudgementTable.propTypes =
  criterion: PropTypes.instanceOf(Immutable.Map).isRequired
  editable: PropTypes.bool.isRequired
  parentSectionsData: PropTypes.instanceOf(Immutable.List).isRequired
  overarchingQuestionData: PropTypes.instanceOf(Immutable.Map).isRequired
  renderMode: PropTypes.string.isRequired
  sectionId: PropTypes.string.isRequired
  templateId: PropTypes.string.isRequired

JudgementModal = ({
  columns
  criterion
  handleJudgement
  isOpen
  onApply
  onCloseModal
  questionType
  rows
  sectionId
  useParentSectionsValues
  templateId
}) ->
  i18n = useI18n('es:recommendations.table')

  options = useCoffeeMemo [criterion, sectionId, templateId], ->
    options = criterion.get('options', Immutable.List())
    additionalOptions = criterion.get('additionalOptions', Immutable.List())
    return options if additionalOptions.isEmpty()
    options.concat [{text: "-------", value: null, disabled: true}]
      .concat(additionalOptions)

  optionsWithBlank = useCoffeeMemo [options, sectionId, templateId], ->
    if templateId is 'overarchingTx-v2' and sectionId in ['desirableEffects', 'undesirableEffects']
      switch sectionId
        when 'desirableEffects'
          options.concat [{ text: i18n('ranking_judgement.undesirable_leave_blank'), value: 'undesirable'}]
        when 'undesirableEffects'
          options.concat [{ text: i18n('ranking_judgement.desirable_leave_blank'), value: 'desirable' }]
        else
          options
    else
      options

  selectedValueText = useCoffeeCallback [], (option) ->
    return '' if _.isEmpty(option.value)
    if option.value in ['desirable', 'undesirable'] then '---' else option?.text

  <Modal
    className="ranking-judgements"
    isOpen={isOpen}
    closeButton={true}
    onClose={onCloseModal}
    title={i18n 'multiintervention_judgement.title'}
  >
    <div className="ranking-modal-content">
      <div className="ranking-modal-content__description">
        <div className="mb-10">
          {criterion.get('description')}
        </div>
      </div>
      <table className="interventions-rating #{sectionId}">
        <thead>
          <tr>
            <th className="blank"/>
            {columns.map (column) ->
              <th className="blue-cell" key={column.get('id')}>
                {column.get('name')}
              </th>
            .toList()}
            <th>{i18n 'multiintervention_judgement.overall'}</th>
          </tr>
        </thead>
        <tbody>
          {rows.map (row) ->
            <tr key={row.get('id')}>
              <td>{row.get('name')}</td>
              {columns.map (column) ->
                optionPath = ['selectedOptions', row.get('id'), if useParentSectionsValues
                    'overall'
                  else
                    column.get('id')]
                <td key={column.get('id')} className={criterion.getIn(optionPath, "")}>
                  {if useParentSectionsValues
                    option = findWhere(column.get('options'), 'value', column.getIn(optionPath, ""))
                    <div>{option?.get('text')}</div>
                  else
                    <Select
                      listClassName="ranking-judgements__select #{sectionId}"
                      options={optionsWithBlank.toJS()}
                      selectValueComponent={selectedValueText}
                      onChange={handleJudgement(sectionId, row.get('id'), column.get('id'))}
                      selected={criterion.getIn optionPath, ""}
                      optionLabelComponent={SelectItem(sectionId)}
                    />
                  }
                </td>
              .toList()}
              <td className={criterion.getIn ['selectedOptions', row.get('id'), 'overall']}>
                <Select
                  listClassName="ranking-judgements__select #{sectionId}"
                  options={options.toJS()}
                  onChange={handleJudgement(sectionId, row.get('id'), 'overall')}
                  selected={criterion.getIn ['selectedOptions', row.get('id'), 'overall']}
                  optionLabelComponent={SelectItem(sectionId)}
                />
              </td>
            </tr>
          .toList()}
        </tbody>
      </table>
    </div>
    <ApplyCancelButtons
      onApply={onApply}
      onCancel={onCloseModal}
    />
  </Modal>

JudgementModal.propTypes =
  columns: PropTypes.instanceOf(Immutable.Map).isRequired
  handleJudgement: PropTypes.func.isRequired
  isOpen: PropTypes.bool.isRequired
  onApply: PropTypes.func.isRequired
  onCloseModal: PropTypes.func.isRequired
  questionType: PropTypes.string.isRequired
  rows: PropTypes.instanceOf(Immutable.Map).isRequired
  sectionId: PropTypes.string.isRequired
  templateId: PropTypes.string.isRequired
  useParentSectionsValues: PropTypes.bool.isRequired


SelectItem = (sectionId) -> ({text, value}) ->
  if sectionId in ['desirableEffects', 'undesirableEffects']
    <div className="select-item #{value}">
      {text}
    </div>
  else
    <span>{text}</span>

module.exports = JudgementTable
