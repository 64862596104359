var BOOLEAN_OPTIONS, CadthGuidelinesField, KEY_MESSAGE_RECOMMENDATION_FORM_ITEMS, RECOMMENDATION_INTENTS, REPORT_TYPES;

CadthGuidelinesField = require('components/cadth_guidelines/cadth_guidelines_field');

REPORT_TYPES = _.map(['rapidResponseReferenceList', 'rapidResponseSummaryOfAbstract', 'rapidResponseSummaryWithCriticalAppraisal', 'policyBrief', 'externalReport', 'qualitativeReview'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.reportType." + value)
  };
});

RECOMMENDATION_INTENTS = _.map(['prevention', 'identification', 'treatment', 'management'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.recommendationIntent." + value)
  };
});

BOOLEAN_OPTIONS = [
  {
    value: true,
    text: $.t('es:recommendations.metadataExtractionForm.values.yes')
  }, {
    value: false,
    text: $.t('es:recommendations.metadataExtractionForm.values.no')
  }
];

KEY_MESSAGE_RECOMMENDATION_FORM_ITEMS = {
  main: [
    {
      fieldKey: 'reportType',
      type: 'select',
      options: REPORT_TYPES
    }, {
      fieldKey: 'noEvidenceFound',
      type: 'switcher',
      hasBooleans: true
    }, {
      fieldKey: 'guidelines',
      type: 'custom',
      customWithArray: true,
      component: CadthGuidelinesField,
      showClear: false
    }, {
      fieldKey: 'recommendationIntent',
      type: 'multiSelect',
      options: RECOMMENDATION_INTENTS
    }, {
      fieldKey: 'guidelinesRelated',
      type: 'select',
      options: BOOLEAN_OPTIONS,
      hasBooleans: true
    }, {
      fieldKey: 'evidenceTablesUrl',
      type: 'multiText',
      initialValue: [""],
      addValueLabel: $.t('actions.add_link')
    }, {
      fieldKey: 'fullReferenceCitation',
      type: 'text'
    }, {
      fieldKey: 'sourceOfEvidence',
      type: 'text'
    }, {
      fieldKey: 'linkToSource',
      type: 'text'
    }, {
      fieldKey: 'publicationYear',
      type: 'text'
    }
  ]
};

module.exports = {
  KEY_MESSAGE_RECOMMENDATION_FORM_ITEMS: KEY_MESSAGE_RECOMMENDATION_FORM_ITEMS
};
