var appUtils, generateActions, mediator;

mediator = require('mediator');

appUtils = require('lib/app_utils');

generateActions = require('actions/utils/generate_actions');

module.exports = generateActions({
  autoGenerate: ['fetchLimitsSuccess', 'fetchLimitsError'],
  name: 'LimitsActions',
  actions: {
    fetchLimits: function(workspace, projectId) {
      var method;
      if (projectId == null) {
        projectId = null;
      }
      method = projectId ? function() {
        return mediator.services.licenseLimitsService.getProjectLimits(projectId);
      } : function() {
        return mediator.services.licenseLimitsService.getWorkspaceLimits(workspace);
      };
      return method().then((function(_this) {
        return function(limits) {
          return _this.actions.fetchLimitsSuccess({
            workspace: workspace,
            projectId: projectId,
            limits: limits
          });
        };
      })(this))["catch"](appUtils.errorHandler);
    }
  }
});
