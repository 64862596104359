DocumentSectionsFromTemplate =
  require 'components/document_sections/from_template/document_sections_from_template'
DocSectionsFromTemplateStore = require 'stores/doc_sections_from_template_store'
QuestionsStore = require 'stores/questions_store'
ReferencesStore = require 'stores/references_store'
ConnectStore = require 'components/enhancers/connect_store'

storeConnectors =
  DocSectionsFromTemplateStore: (Store) ->
    document: Store.getDocument()
    isDocSectionsFetching: Store.isFetching()
    chapters: Store.getChapters()
    chaptersOrder: Store.getChaptersOrder()
    attachments: Store.getAttachments()
    editingSubchapterData: Store.getEditingSubchapterData()
    addingSubchapterTo: Store.getAddingSubchapterTo()
    deletingSubchapter: Store.isDeletingSubchapter()
    collapsedChapters: Store.getCollapsedChapters()
    referenceIds: Store.getReferenceIds()
  QuestionsStore: (Store) ->
    isQuestionsFetching: Store.isFetching()
  ReferencesStore: (Store) ->
    isReferencesFetching: Store.isFetching()

module.exports =
  ConnectStore DocumentSectionsFromTemplate, DocSectionsFromTemplateStore, storeConnectors
