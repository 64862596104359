ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
mediator = require 'mediator'
Modal = require 'components/common/modal'
ReferencesActions = require 'actions/references_actions'
ReferencesStore = require 'stores/references_store'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
{ forbiddenFileExtensions, referenceAttachmentLimit } = require 'lib/constants'
{ formatFileSize, getFileExtension } = require 'base/lib/utils'

storeConnector =
  ReferencesStore: (Store) ->
    referenceId: Store.getAttachmentReferenceId()
    isOpen: Store.isUploadAttachmentModalOpen()
    file: Store.getAttachmentFile()
    isUploading: Store.isUploadingAttachment()

ReferenceAttachmentUploadModal = createReactClass
  displayName: 'ReferenceAttachmentUploadModal'

  propTypes:
    file: PropTypes.object
    isOpen: PropTypes.bool.isRequired
    isUploading: PropTypes.bool.isRequired
    referenceId: PropTypes.string

  mixins: [ CustomRenderMixin, Translation('references:attachment') ]

  onFileChange: (e) ->
    file = e.target.files[0]
    return unless file
    fileExtension = getFileExtension file.name
    if fileExtension in forbiddenFileExtensions
      mediator.dialogs.error
        title: $.t 'references:attachment.fileExtensionForbidden.title'
        message: $.t 'references:attachment.fileExtensionForbidden.message',
          extensions: forbiddenFileExtensions.join ', '
    else if file.size > referenceAttachmentLimit
      mediator.dialogs.error
        title: $.t 'references:attachment.fileSizeExceeded.title'
        message: $.t 'references:attachment.fileSizeExceeded.message',
          fileName: file.name
          fileSize: formatFileSize file.size
          fileLimit: formatFileSize referenceAttachmentLimit
    else
      ReferencesActions.setAttachmentFile file

  onFileClick: ->
    @fileInput?.click()

  onFileUpload: ->
    { referenceId, file } = @props
    ReferencesActions.uploadAttachment referenceId, file

  getFileName: ->
    { file } = @props
    if not file or not file.name
      @i18n 'chooseFile'
    else if file.name.length > 40
      "#{file.name.substring(0, 39)}..."
    else
      file.name

  render: ->
    { file, isOpen, isUploading } = @props
    <Modal isOpen={isOpen} modalSize="small" title={@i18n 'uploadTitle'}>
      <input
        onChange={@onFileChange}
        ref={(el) => @fileInput = el}
        style={display: 'none'}
        type="file"
      />
      <Tooltip>
        <Button
          className="btn btn-block btn-apply mb-10"
          disabled={isUploading}
          onClick={@onFileClick}
          label={@getFileName()}
          title={file?.name ? ''}
        />
      </Tooltip>
      <ApplyCancelButtons
        applying={isUploading}
        applyLabel={@i18n 'upload'}
        isSubmitEnabled={file?}
        onApply={@onFileUpload}
        onCancel={ReferencesActions.closeUploadAttachmentModal}
      />
    </Modal>

module.exports = ConnectStore ReferenceAttachmentUploadModal, ReferencesStore, storeConnector
