ConnectStore = require 'components/enhancers/connect_store'
Translation = require 'components/mixins/translation'
ConfirmationModal = require 'components/common/confirmation_modal'
DiscardChangesStore = require 'stores/discard_changes_store'
DiscardChangesActions = require 'actions/discard_changes_actions'

DiscardChanges = createReactClass
  displayName: 'DiscardChanges'
  mixins: [Translation('translation:discard_changes')]

  propTypes:
    callback: PropTypes.func

  handleDiscardChanges: ->
    @props.callback()
    DiscardChangesActions.clear()

  handleContinueEditing: ->
    DiscardChangesActions.setCallback(null)

  render: ->
    { callback } = @props

    return null unless callback

    <ConfirmationModal
      isOpen
      question={"<span class='bold'>#{@i18n 'title'}</span>"}
      message={@i18n 'description'}
      confirmLabel={@i18n 'confirm_label'}
      cancelLabel={@i18n 'cancel_label'}
      onConfirm={@handleDiscardChanges}
      onCancel={@handleContinueEditing}
      confirmClass="btn-danger"
      cancelClass="btn-apply"
    />

storeConnectors =
  DiscardChangesStore: (Store) ->
    callback: Store.getCallback()

module.exports =
  ConnectStore DiscardChanges, DiscardChangesStore, storeConnectors
