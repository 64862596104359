Translation = require 'components/mixins/translation'
ChapterIconImage = require 'components/decision_aid/chapter_icon_image'
DragDropTypes = require 'components/decision_aid/drag_drop_types'
{
  dragSpec
  dropSpec
  dragCollect
  dropCollect
} = require 'components/decision_aid/chapter_dnd_spec'

{ DragSource, DropTarget } = ReactDnD

DraggableDecisionAidChapterTitle = createReactClass
  displayName: 'DraggableDecisionAidChapterTitle'

  propTypes:
    chapterId: PropTypes.string.isRequired # Implicitly used in DND spec
    illustration: PropTypes.string.isRequired
    title: PropTypes.string.isRequired

  mixins: [Translation('da:details-screen')]

  render: ->
    { connectDragSource, connectDropTarget, isDragging } = @props

    connectDragSource(
      connectDropTarget(
        <div className={classNames 'draggable-chapter-title', dragging: isDragging}>
          <div className='title-image'>
            <ChapterIconImage iconName={@props.illustration} />
          </div>
          <div className='title-text'>
            {@props.title}
          </div>
        </div>
      )
    )

DragSourcedChapterTitle = DragSource(
  DragDropTypes.CHAPTER_TITLE, dragSpec, dragCollect
)(DraggableDecisionAidChapterTitle)
DropTargetedChapterTitle = DropTarget(
  DragDropTypes.CHAPTER_TITLE, dropSpec, dropCollect
)(DragSourcedChapterTitle)

module.exports = DropTargetedChapterTitle
