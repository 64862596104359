var keyboardSupport;

keyboardSupport = {
  cellsMapping: {},
  countColumns: function() {
    return _(this.$('.outcomes-table > tbody > tr.outcome-row').first().find('td')).reduce((function(acc, el) {
      return acc + $(el).prop('colspan');
    }), 0);
  },
  countRows: function() {
    return this.$('.outcomes-table tbody tr.outcome-row').length;
  },
  setCellsMatrix: function() {
    var $cell, colIdx, colShift, outcomeCell, outcomeRow, rowIdx, _i, _len, _ref, _results;
    this.cellsMapping = {};
    if (this.colsNo == null) {
      this.colsNo = this.countColumns();
    }
    this.rowsNo = this.countRows();
    if (this.colsNo === 0 && this.rowsNo === 0) {
      this.colsNo = this.rowsNo = null;
      return;
    }
    _ref = this.$('tr.outcome-row');
    _results = [];
    for (rowIdx = _i = 0, _len = _ref.length; _i < _len; rowIdx = ++_i) {
      outcomeRow = _ref[rowIdx];
      colShift = 0;
      _results.push((function() {
        var _j, _len1, _ref1, _results1;
        _ref1 = $(outcomeRow).find('td');
        _results1 = [];
        for (colIdx = _j = 0, _len1 = _ref1.length; _j < _len1; colIdx = ++_j) {
          outcomeCell = _ref1[colIdx];
          $cell = $(outcomeCell);
          this._setMappingForCell($cell, rowIdx, colIdx, $(outcomeRow).data('viewCid'), this.cellsMapping);
          _results1.push(colShift += $cell.prop('colspan') - 1);
        }
        return _results1;
      }).call(this));
    }
    return _results;
  },
  constructSelectorForCell: function($cell) {
    var cid, property;
    cid = $cell.closest('tr').data('viewCid');
    property = $cell.data('property');
    return "tr[data-view-cid=" + cid + "] > td[data-property=" + property + "]";
  },
  cellsMappingKey: function(rowIdx, colIdx) {
    return "" + rowIdx + ", " + colIdx;
  },
  cellRowAndCol: function(cellsMappingKey) {
    var rowAndCol;
    rowAndCol = _(cellsMappingKey.split(',')).map(function(key) {
      return parseInt(key.trim());
    });
    return {
      row: rowAndCol[0],
      col: rowAndCol[1]
    };
  },
  _getFirstEmptyCol: function(mapping, rowIdx, colIdx) {
    var empty;
    empty = colIdx;
    while (mapping[this.cellsMappingKey(rowIdx, empty)] && empty < this.colsNo) {
      empty += 1;
    }
    if (empty < this.colsNo) {
      return empty;
    } else {
      return null;
    }
  },
  _setMappingForCell: function($cell, rowIdx, colIdx, viewCid, mapping) {
    var emptyColIdx, i, j, _i, _ref, _results;
    emptyColIdx = this._getFirstEmptyCol(mapping, rowIdx, colIdx);
    if (emptyColIdx == null) {
      return;
    }
    _results = [];
    for (i = _i = 1, _ref = $cell.prop('rowspan'); 1 <= _ref ? _i <= _ref : _i >= _ref; i = 1 <= _ref ? ++_i : --_i) {
      _results.push((function() {
        var _j, _ref1, _results1;
        _results1 = [];
        for (j = _j = 1, _ref1 = $cell.prop('colspan'); 1 <= _ref1 ? _j <= _ref1 : _j >= _ref1; j = 1 <= _ref1 ? ++_j : --_j) {
          _results1.push(mapping[this.cellsMappingKey(rowIdx + i - 1, emptyColIdx + j - 1)] = "tr[data-view-cid=" + viewCid + "] > td[data-property=" + ($cell.data().property) + "]");
        }
        return _results1;
      }).call(this));
    }
    return _results;
  },
  _getNextCellKey: function(rowIdx, colIdx) {
    var currentCellKey, currentFirstCellKey, downFirstCellKey, nextCellKey, rowAndCol;
    currentCellKey = this.cellsMappingKey(rowIdx, colIdx);
    currentFirstCellKey = this.cellsMappingKey(rowIdx, 0);
    nextCellKey = colIdx === this.colsNo - 1 ? (this.setActiveCell(rowIdx, 0), downFirstCellKey = this.cellsMappingKey(this.cellRowAndCol(this._getDownCellKey(rowIdx, 0)).row, 0), $(this.cellsMapping[downFirstCellKey])[0] === $(this.cellsMapping[currentFirstCellKey])[0] ? currentCellKey : downFirstCellKey) : this.cellsMappingKey(rowIdx, ++colIdx);
    while ($(this.cellsMapping[nextCellKey])[0] === this.activeCell.el[0]) {
      rowAndCol = this.cellRowAndCol(nextCellKey);
      nextCellKey = this._getNextCellKey(rowAndCol.row, rowAndCol.col);
    }
    return nextCellKey;
  },
  _getPrevCellKey: function(rowIdx, colIdx) {
    var currentFirstCellKey, prevCellKey, rowAndCol, upCellKey, upFirstCellKey;
    currentFirstCellKey = this.cellsMappingKey(rowIdx, 0);
    upCellKey = this._getUpCellKey(rowIdx, colIdx);
    upFirstCellKey = this.cellsMappingKey(this.cellRowAndCol(upCellKey).row, 0);
    prevCellKey = colIdx === 0 ? (upCellKey = this.cellsMappingKey(this.cellRowAndCol(upCellKey).row, this.colsNo - 1), $(this.cellsMapping[upFirstCellKey])[0] === $(this.cellsMapping[currentFirstCellKey])[0] ? currentFirstCellKey : upCellKey) : this.cellsMappingKey(rowIdx, colIdx - 1);
    if ($(this.cellsMapping[upFirstCellKey])[0] !== $(this.cellsMapping[currentFirstCellKey])[0]) {
      while ($(this.cellsMapping[prevCellKey])[0] === this.activeCell.el[0]) {
        rowAndCol = this.cellRowAndCol(prevCellKey);
        prevCellKey = this._getPrevCellKey(rowAndCol.row, rowAndCol.col);
      }
    }
    return prevCellKey;
  },
  _getDownCellKey: function(rowIdx, colIdx) {
    var downCellKey, rowAndCol;
    downCellKey = rowIdx === this.rowsNo - 1 ? this.cellsMappingKey(rowIdx, colIdx) : this.cellsMappingKey(rowIdx + 1, colIdx);
    rowAndCol = this.cellRowAndCol(downCellKey);
    while ($(this.cellsMapping[downCellKey])[0] === this.activeCell.el[0]) {
      rowAndCol = this.cellRowAndCol(downCellKey);
      if (rowAndCol.row === this.rowsNo - 1) {
        break;
      }
      downCellKey = this._getDownCellKey(rowAndCol.row, rowAndCol.col);
    }
    return downCellKey;
  },
  _getUpCellKey: function(rowIdx, colIdx) {
    var rowAndCol, upCellKey;
    upCellKey = rowIdx === 0 ? this.cellsMappingKey(0, colIdx) : this.cellsMappingKey(rowIdx - 1, colIdx);
    rowAndCol = this.cellRowAndCol(upCellKey);
    while ($(this.cellsMapping[upCellKey])[0] === this.activeCell.el[0]) {
      rowAndCol = this.cellRowAndCol(upCellKey);
      if (rowAndCol.row === 0) {
        break;
      }
      upCellKey = this._getUpCellKey(rowAndCol.row, rowAndCol.col);
    }
    return upCellKey;
  },
  setNextActiveCell: function() {
    var rowAndCol;
    rowAndCol = this.cellRowAndCol(this._getNextCellKey(this.activeCell.row, this.activeCell.col));
    this.setActiveCell(rowAndCol.row, rowAndCol.col);
    this.scrollPage();
    return false;
  },
  setPrevActiveCell: function() {
    var rowAndCol;
    rowAndCol = this.cellRowAndCol(this._getPrevCellKey(this.activeCell.row, this.activeCell.col));
    this.setActiveCell(rowAndCol.row, rowAndCol.col);
    this.scrollPage();
    return false;
  },
  setRowUp: function() {
    var rowAndCol;
    rowAndCol = this.cellRowAndCol(this._getUpCellKey(this.activeCell.row, this.activeCell.col));
    this.setActiveCell(rowAndCol.row, rowAndCol.col);
    this.scrollPage();
    return false;
  },
  setRowDown: function() {
    var rowAndCol;
    rowAndCol = this.cellRowAndCol(this._getDownCellKey(this.activeCell.row, this.activeCell.col));
    this.setActiveCell(rowAndCol.row, rowAndCol.col);
    this.scrollPage();
    return false;
  },
  scrollPage: function(el) {
    if (el == null) {
      el = this.activeCell.el;
    }
    return this.options.parentView.trigger('!scroll', el, 0, 25);
  }
};

module.exports = keyboardSupport;
