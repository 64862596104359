ConnectStore = require 'components/enhancers/connect_store'
EllipsizedText = require 'components/common/ellipsized_text'
IconButton = require 'components/common/icon_button'
MDGPublicationActions = require 'actions/mdg_publication_actions'
MDGPublicationModal =
  require 'components/dissemination/mdg_publication_screen/mdg_publication_modal'
MDGPublicationStore = require 'stores/mdg_publication_store'
mediator = require 'mediator'
Spinner = require 'components/common/spinner'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

storeConnector =
  MDGPublicationStore: (Store) ->
    fetchingError: Store.getFetchingError()
    generalInformationDoc: Store.getGeneralInformationDoc()
    isFetching: Store.isFetching()
    lastMdgUpload: Store.getLastMdgUpload()

MDGPublicationScreen = ({ fetchingError, generalInformationDoc, isFetching, lastMdgUpload }) ->
  i18n = useI18n('dbep:mdg_publication_screen')

  onPublish = useCoffeeCallback [i18n, lastMdgUpload, MDGPublicationActions], ->
    message = if lastMdgUpload then i18n('confirm.republish') else i18n('confirm.publish')
    mediator.dialogs.confirm { message, title: i18n 'confirm.title' }, (confirmed) ->
      return unless confirmed
      MDGPublicationActions.publish()

  dateFormatted = useCoffeeMemo [lastMdgUpload], ->
    return '--' unless lastMdgUpload
    moment(lastMdgUpload).format 'D MMM YYYY'

  status = useCoffeeMemo [lastMdgUpload], ->
    if lastMdgUpload? then 'published' else 'unpublished'

  return <Spinner /> if isFetching
  return null if fetchingError

  <div className="mdg-publication-screen">
    <div className="mdg-publication-screen__table">
      <div className="table__row header">
        <div className="row__title">{i18n 'columns.title'}</div>
        <div className="row__lastUpdate">{i18n 'columns.lastUpdate'}</div>
        <div className="row__status">{i18n 'columns.status'}</div>
        <div className="row__actions">{i18n 'columns.actions'}</div>
      </div>
      <div className="table__row">
        <div className="row__title">
          <EllipsizedText height={50} text={generalInformationDoc.get 'title'} />
        </div>
        <div className="row__last-update">
          {lastMdgUpload? and <div className="last-update__icon" />}
          <div>{dateFormatted}</div>
        </div>
        <div className="row__status">
          <div className={"status__square #{status}"} />
          <div className="status__text">{i18n "statuses.#{status}"}</div>
        </div>
        <div className="row__actions">
          <IconButton
            iconName={if lastMdgUpload? then 'refresh' else 'bookmark'}
            label={if lastMdgUpload? then i18n('/actions.update') else i18n('/actions.publish')}
            onClick={onPublish}
          />
        </div>
      </div>
    </div>
    <MDGPublicationModal />
  </div>

MDGPublicationScreen.propTypes =
  fethingError: PropTypes.object
  generalInformationDoc: PropTypes.instanceOf(Immutable.Map).isRequired
  isFetching: PropTypes.bool.isRequired
  lastMdgUpload: PropTypes.string

module.exports = ConnectStore MDGPublicationScreen, [MDGPublicationStore], storeConnector
