var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, 8, "{\"className\": \"intervention\", \"dataProperty\": \"intervention\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n"
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "control", depth0, "{\"colNo\": 9, \"viewType\": \"one_row\"}", {"name":"cell","hash":{},"data":data})))
    + "\n"
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "absolute_effect", depth0, "{\"colNo\": 11, \"viewType\": \"one_row\"}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}