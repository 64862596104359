{ bool, func } = PropTypes
Button = require 'components/common/button'
ItemWithCheckbox = require 'components/common/item_with_checkbox'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'

ArchieRemovalDialog = createReactClass
  displayName: 'ArchieRemovalDialog'

  propTypes:
    isOpen: bool.isRequired
    isRead: bool.isRequired
    onClose: func.isRequired
    onToggleRead: func.isRequired

  mixins: [Translation('archie:removal_dialog')]

  render: ->
    { isOpen, isRead, onClose, onToggleRead } = @props

    <Modal
      className='archie-removal-dialog'
      closeButton={false}
      isOpen={isOpen}
      modalSize='large'
      onClose={onClose}
      position='centered'
      title={@i18n 'title'}
    >
      <img
        alt=''
        className='archie-removal-dialog__archie_logo'
        src='images/archie-logo.png'
      />
      <h3>{@i18n 'subtitle'}</h3>
      <p>{@i18n 'info'}</p>
      <p className='archie-removal-dialog__underlined'>
        {@i18n 'only_archie_removed'}
      </p>
      <p>{@i18n 'detailed_information'}</p>
      <h4>{@i18n 'what_changes'}</h4>
      <ul>
        {_.map @i18n('changes'), (change, idx) ->
          <li key={idx}>{change}</li>
        }
      </ul>
      <h4>{@i18n 'what_remains_unchanged'}</h4>
      <ul>
        {_.map @i18n('unchanged'), (noChange, idx) ->
          <li key={idx}>{noChange}</li>
        }
      </ul>
      <p>
        {@i18n 'need_assistance', {},
          supportMailLink:
            <a href='mailto:support@gradepro.org'>
              support@gradepro.org
            </a>
        }
      </p>
      <div className='archie-removal-dialog__footer'>
        <div className='footer__left'>
          <p>{@i18n 'best_regards'}<br />{@i18n 'gradepro_team'}</p>
        </div>
        <div className='footer__right'>
          <ItemWithCheckbox onChange={onToggleRead} checked={isRead}>
            <span>{@i18n '/actions.dont_show_again'}</span>
          </ItemWithCheckbox>
        </div>
      </div>
      <div className='archie-removal-dialog__button'>
        <Button
          className='btn'
          label={@i18n '/actions.close'}
          onClick={onClose}
        />
      </div>
    </Modal>

module.exports = ArchieRemovalDialog
