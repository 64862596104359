require('../proposals/array-find-from-last');
require('../proposals/array-is-template-object');
require('../proposals/decorators');
require('../proposals/iterator-helpers');
require('../proposals/map-upsert');
require('../proposals/set-methods');
require('../proposals/using-statement');
var parent = require('./3');

module.exports = parent;
