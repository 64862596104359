CustomRenderMixin = require 'components/mixins/custom_render_mixin'

SectionCheckbox = createReactClass
  displayName: 'SectionCheckbox'

  mixins: [CustomRenderMixin]

  propTypes:
    sectionDef: PropTypes.object.isRequired
    label: PropTypes.string.isRequired
    blockName: PropTypes.string.isRequired
    description: PropTypes.string
    onSectionToggle: PropTypes.func.isRequired

  onChange: ->
    @props.onSectionToggle @props.blockName, @props.sectionDef.get('id')

  render: ->
    sectionDef = @props.sectionDef
    id = sectionDef.get('id')

    readOnlyOrChangeProp = if sectionDef.get('required')
      readOnly: true
    else
      onChange: @onChange

    labelClasses = classNames 'section-checkbox', disabled: sectionDef.get('required')
    sectionNameClasses = classNames 'section-name', 'with-desc': @props.description

    <div className={@props.className}>
      <label className={labelClasses}>
        <input
          type="checkbox"
          value={id}
          checked={sectionDef.get('required') or sectionDef.get('checked')}
          {...readOnlyOrChangeProp} />
        <span className={sectionNameClasses}>{@props.label}</span>
      </label>
      <div className='section-desc'>{@props.description}</div>
    </div>

module.exports = SectionCheckbox
