ConnectStore = require 'components/enhancers/connect_store'
ReferencesStore = require 'stores/references_store'
{ useI18n } = require 'lib/react_utils'

storeConnector =
  ReferencesStore: (ReferencesStore) ->
    allReferencesNumber: ReferencesStore.getDeduplicatedReferences().size
    currentlyShownReferencesNumber: ReferencesStore.getReferencesWithSearch().size
    hasEverFetched: ReferencesStore.hasEverFetched()

ReferencesToolbar = ({
  allReferencesNumber
  currentlyShownReferencesNumber
  hasEverFetched
  mode
}) ->
  i18n = useI18n 'references:toolbar'

  <div className="references-toolbar">
    <h2>
      {switch mode
        when 'duplicates'
          i18n 'duplicates'
        when 'references'
          if not hasEverFetched
            i18n 'references_not_fetched'
          else if allReferencesNumber is currentlyShownReferencesNumber
            i18n 'references', { allReferencesNumber }
          else
            i18n 'references_search', { allReferencesNumber, currentlyShownReferencesNumber }
        else
          null
      }
    </h2>
  </div>

ReferencesToolbar.propTypes =
  allReferencesNumber: PropTypes.number.isRequired
  currentlyShownReferencesNumber: PropTypes.number.isRequired
  hasEverFetched: PropTypes.bool.isRequired
  mode: PropTypes.oneOf(['duplicates', 'references']).isRequired

module.exports = ConnectStore ReferencesToolbar, ReferencesStore, storeConnector
