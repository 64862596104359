RadioInput = require 'components/common/radio_input'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
MedianRatingValue = require 'components/scope/common/median_rating_value'

IMPORTANCE_VALUES = ['critical', 'important', 'notImportant']

RatingTableRow = createReactClass
  displayName: 'RatingTableRow'

  mixins: [CustomRenderMixin]

  render: ->
    name = @props.outcome.get 'name'
    importance = @props.outcome.get 'importance'
    gIndex = @props.gIndex
    outcomeKey = @props.outcome.get 'key'
    medianRating = @props.outcome.get 'medianRating'

    <tbody className={_.str.dasherize importance}>
      <tr>
        <td className='name'>{name}</td>
        {IMPORTANCE_VALUES.map (importanceVal, idx) =>
          <td key={idx}>
            <label>
              <RadioInput
                name="rating-#{gIndex}-#{outcomeKey}"
                value={importanceVal}
                checked={importance is importanceVal}
                disabled={@props.readOnly}
                onChange={@props.changeOutcomeImportance @props.gIndex, outcomeKey, importanceVal}
              />
            </label>
          </td>
        }
        <td>
          <MedianRatingValue medianRating={medianRating} />
        </td>
        <td></td>
      </tr>
    </tbody>

module.exports = RatingTableRow
