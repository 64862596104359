var UserProjectDataActions, UserProjectDataStore, alt, errorHandler, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

UserProjectDataActions = require('actions/user_project_data_actions');

errorHandler = require('lib/app_utils').errorHandler;

UserProjectDataStore = (function() {
  function UserProjectDataStore() {
    var stateJS;
    stateJS = {
      meta: {
        fetching: false
      },
      data: {
        hiddenSojSections: {},
        collapsedAssessementSections: {},
        etdViewSettings: {}
      }
    };
    this.state = Immutable.fromJS(stateJS);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      getCollapsedAssessmentSections: this.getCollapsedAssessmentSections,
      getHiddenSojSections: this.getHiddenSojSections,
      getEtdViewSettings: this.getEtdViewSettings
    });
    this.bindActions(UserProjectDataActions);
  }

  UserProjectDataStore.prototype.isFetching = function() {
    return this.state.getIn(['meta', 'fetching']);
  };

  UserProjectDataStore.prototype.onDbChange = function(doc) {
    return this.setState(this.state.set('data', Immutable.fromJS(doc)));
  };

  UserProjectDataStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['meta', 'fetching'], true));
  };

  UserProjectDataStore.prototype.onFetchSuccess = function(doc) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetching'], false);
      return state.set('data', Immutable.fromJS(doc));
    }));
  };

  UserProjectDataStore.prototype.onFetchError = function(err) {
    this.setState(this.state.setIn(['meta', 'fetching'], false));
    return errorHandler(err);
  };

  UserProjectDataStore.prototype.getHiddenSojSections = function() {
    return this.state.getIn(['data', 'hiddenSojSections'], Immutable.Map());
  };

  UserProjectDataStore.prototype.getCollapsedAssessmentSections = function() {
    return this.state.getIn(['data', 'collapsedAssessementSections'], Immutable.Map());
  };

  UserProjectDataStore.prototype.getEtdViewSettings = function() {
    return this.state.getIn(['data', 'etdViewSettings'], Immutable.Map());
  };

  return UserProjectDataStore;

})();

module.exports = alt.createStore(immutable(UserProjectDataStore), 'UserProjectDataStore');
