ConnectStore = require 'components/enhancers/connect_store'
ImportActions = require 'actions/import_actions'
ImportStore = require 'stores/import_store'
IncludeReferencesCheckbox = require 'components/import_dialog/include_references_checkbox'
Outcomes = require 'models/outcomes'
Question = require 'models/question'
Translation = require 'components/mixins/translation'

{ number, oneOf, bool, string, arrayOf, instanceOf } = PropTypes
{ license_url } = require 'lib/constants'

OutcomeToImport = createReactClass
  displayName: 'OutcomeToImport'

  propTypes:
    cid: string.isRequired
    name: string.isRequired
    selectedItems: arrayOf(string).isRequired
    disabled: bool.isRequired

  _isChecked: ->
    @props.cid in @props.selectedItems

  handleChange: (evt) ->
    ImportActions.selectItems [@props.cid], evt.target.checked

  render: ->
    isChecked = @_isChecked()

    <div className="outcome">
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={@handleChange}
          value={@props.cid}
          name="import-outcomes-id"
          disabled={@props.disabled and not isChecked}
        />
        <span>
          {@props.name}
        </span>
      </label>
    </div>

QuestionToImport = createReactClass
  displayName: 'QuestionToImport'

  propTypes:
    importType: oneOf(['question', 'outcome', 'project']).isRequired
    cid: string.isRequired
    name: string.isRequired
    outcomes: instanceOf(Outcomes).isRequired
    selectedItems: arrayOf(string).isRequired
    disabled: bool.isRequired

  handleChange: (evt) ->
    { checked } = evt.target
    if @props.importType in ['question', 'project']
      ImportActions.selectItems [@props.cid], checked
    else
      ImportActions.selectItems @props.outcomes.map((o) -> o.cid), checked

  _isChecked: ->
    if @props.importType in ['question', 'project']
      @props.cid in @props.selectedItems
    else
      selectedOutcomesFromQuestion = @props.outcomes.filter (outcome) =>
        outcome.cid in @props.selectedItems
      selectedOutcomesFromQuestion.length is @props.outcomes.length

  render: ->
    return null if @props.importType is 'outcome' and @props.outcomes.isEmpty()
    isChecked = @_isChecked()
    isDisabled = @props.disabled and not isChecked
    classes = classNames
      'question': true
      'bold': @props.importType is 'outcome'

    <div className={classes}>
      <div className="question-select">
        <label disabled={isDisabled}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={@handleChange}
            value={@props.cid}
            name="import-questions-id"
            disabled={isDisabled}
          />
          <span>
            {@props.name}
          </span>
        </label>
      </div>
      {@props.importType is 'outcome' and <div className="outcomes">
        {@props.outcomes.map (outcome) =>
          <OutcomeToImport
            key={outcome.cid}
            cid={outcome.cid}
            name={outcome.get 'name'}
            selectedItems={@props.selectedItems}
            disabled={@props.disabled}
          />
        }
      </div>}
    </div>

storeConnectors =
  ImportStore: (Store) ->
    questions: Store.getQuestions()
    selectedItems: Store.getSelectedItems()
    importType: Store.getImportType()

ImportQuestionsList = createReactClass
  displayName: 'ImportQuestionsList'
  mixins: [Translation('projects:import_dialog')]

  propTypes:
    importType: oneOf(['question', 'outcome', 'project']).isRequired
    selectedItems: arrayOf(string).isRequired
    questions: arrayOf(instanceOf(Question)).isRequired
    limits: instanceOf(Immutable.Map)
    comparisonsQuestionsCount: number

  getDefaultProps: ->
    comparisonsQuestionsCount: 0

  selectAll: ->
    areAllChecked = @_areAllChecked()
    if @props.importType in ['question', 'project']
      allQuestions = @props.questions.map (q) -> q.cid
      ImportActions.selectItems allQuestions, not areAllChecked
    else
      ImportActions.selectItems @_getAllOutcomes(), not areAllChecked

  _getImportLimit: ->
    return null unless @props.limits?
    @props.limits.get('questions') - @props.comparisonsQuestionsCount

  _selectAllDisabled: ->
    return false unless @props.limits?
    qLimit = @props.limits.get('questions')
    qLimit > -1 and @_getImportLimit() < @props.questions.length

  _areAllChecked: ->
    if @props.importType in ['question', 'project']
      @props.selectedItems.length is @props.questions.length
    else
      @props.selectedItems.length is @_getAllOutcomes().length

  _getAllOutcomes: ->
    _.chain @props.questions
      .map (question) ->
        question.get('outcomes').map (outcome) -> outcome.cid
      .flatten()
      .value()

  renderLimitsWarning: ->
    { limits, comparisonsQuestionsCount, importType } = @props
    return if importType is 'outcome'
    return null unless limits? or limits.isEmpty()

    questionsLimits = limits.get('questions')
    return null if questionsLimits < 0

    remaining = questionsLimits - comparisonsQuestionsCount

    <div
      className='gdt-limits-content-box'
      dangerouslySetInnerHTML={
        __html: @i18n 'questions_limit_status', {
          current: comparisonsQuestionsCount,
          remaining,
          link: license_url
        }
      }
    />

  render: ->
    { importType, limits, selectedItems, comparisonsQuestionsCount, questions } = @props
    chooseLabel = @i18n "choose_#{if importType is 'project' then 'question' else importType}s"
    importLimit = @_getImportLimit()

    <div className="import-select-questions">
      {@renderLimitsWarning()}
      <div className="import-select-questions-header">
        {chooseLabel}
      </div>
      <div className="import-select-questions-list-container">
        <div className="import-select-questions-select-all">
          <label>
            <input
              type="checkbox"
              checked={@_areAllChecked()}
              name="select-all"
              onChange={@selectAll}
              disabled={@_selectAllDisabled()}
            />
            <span>
              {@i18n '/actions.select_all'}
            </span>
          </label>
        </div>
        <div className="import-select-questions-list">
          {_.map questions, (question, idx) =>
            <QuestionToImport
              key={question.cid}
              cid={question.cid}
              name={question.get 'question'}
              outcomes={question.get 'outcomes'}
              importType={importType}
              selectedItems={selectedItems}
              disabled={importLimit? and selectedItems.length is importLimit}
            />
          }
        </div>
      </div>
      <IncludeReferencesCheckbox />
    </div>

module.exports = ConnectStore ImportQuestionsList, [ImportStore], storeConnectors
