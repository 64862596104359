Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
Spinner = require 'components/common/spinner'
{ MASTER_PROJECT_ID } = require 'lib/mda_helper'
MdaTopicsActions = require 'actions/mda_topics_actions'
MdaVerificationStore = require 'stores/mda_verification_store'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

storeConnector =
  MdaVerificationStore: (Store) ->
    isFetching: Store.isFetching()
    usedTables: Store.getUsedTables()
    usedTopics: Store.getUsedTopics()
    masterTopics: Store.getMasterTopics()

VerificationScreen = ({ isFetching, usedTables, usedTopics, masterTopics }) ->
  i18n = useI18n 'mda:verification'

  usedTopicsIds = useCoffeeMemo [usedTopics], ->
    usedTopics.map((topic) -> topic.get('_id')).keySeq().toList()

  verificationNeeded = usedTables.some (table) ->
    masterTopics.some (topic) ->
      topic.get('insertedTables').includes(table.get('_id')) and
      not usedTopicsIds.includes(topic.get('_id'))

  return <Spinner /> if isFetching
  return <div>{i18n 'no_table_needs_verification'}</div> unless verificationNeeded
  return <table className="verification-table">
    <thead>
      <tr>
        <th>{i18n '../tables.tables'}</th>
        <th>{i18n 'not_used_topics'}</th>
      </tr>
    </thead>
    <tbody>
      {usedTables.map (table) ->
        <TableRow
          key={table.get('_id')}
          table={table}
          masterTopics={masterTopics}
          usedTopicsIds={usedTopicsIds}
          />
      .toList()}
    </tbody>
  </table>

VerificationScreen.propTypes =
  isFetching: PropTypes.bool.isRequired
  usedTables: PropTypes.instanceOf(Immutable.Map).isRequired
  usedTopics: PropTypes.instanceOf(Immutable.Map).isRequired
  masterTopics: PropTypes.instanceOf(Immutable.Map).isRequired

TableRow = ({ table, masterTopics, usedTopicsIds }) ->
  topicsWithThisTable = masterTopics.filter (topic) ->
    topic.get('insertedTables').includes(table.get('_id')) and
    not usedTopicsIds.includes(topic.get('_id'))

  return null if topicsWithThisTable.size is 0
  <React.Fragment>
    <tr>
      <td rowSpan={topicsWithThisTable.size}>{table.get('name')}</td>
      <TopicCell topic={topicsWithThisTable.first()} />
    </tr>
    {topicsWithThisTable?.rest().map (topic) ->
      <tr key={topic.get('_id')}>
        <TopicCell topic={topic} />
      </tr>
    .toList()}
  </React.Fragment>

TableRow.propTypes =
  table: PropTypes.instanceOf(Immutable.Map).isRequired
  masterTopics: PropTypes.instanceOf(Immutable.Map).isRequired
  usedTopicsIds: PropTypes.instanceOf(Immutable.List).isRequired


TopicCell = ({ topic }) ->
  i18n = useI18n 'mda:verification'
  importTopic = useCoffeeCallback [], (topic) -> ->
    MdaTopicsActions.importTopics MASTER_PROJECT_ID, Immutable.fromJS [topic]
  <td>
    <div className="flex flex-row">
      <div className="flex-grow">
        {topic.get('name')}
      </div>
      <div>
        <Button
          className="btn btn-apply"
          label={i18n "/actions.import"}
          onClick={importTopic(topic)}
        />
      </div>
    </div>
  </td>

TopicCell.propTypes =
  topic: PropTypes.instanceOf(Immutable.Map).isRequired


module.exports = ConnectStore VerificationScreen, [
  MdaVerificationStore
], storeConnector
