var MDA_TABLE_DOC_TYPE, MDA_TABLE_PREFIX, MDA_TOPIC_DOC_TYPE, MdaTableDoc, MdaTopicDoc, QuestionsListComponentActions, W, appUtils, callMethod, generateActions, generateGUID, _ref, _ref1;

appUtils = require('lib/app_utils');

_ref = require('base/lib/utils'), callMethod = _ref.callMethod, generateGUID = _ref.generateGUID;

generateActions = require('actions/utils/generate_actions');

MdaTableDoc = require('lib/db_docs/mda_table_doc');

MdaTopicDoc = require('lib/db_docs/mda_topic_doc');

MDA_TABLE_PREFIX = require('lib/doc_prefixes').mdaTable;

_ref1 = require('lib/db_docs/doc_types'), MDA_TABLE_DOC_TYPE = _ref1.MDA_TABLE, MDA_TOPIC_DOC_TYPE = _ref1.MDA_TOPIC;

QuestionsListComponentActions = require('actions/questions_list_component_actions');

W = require('when/when');

module.exports = generateActions({
  autoGenerate: ['fetchSuccess', 'openCreateOrEditDialog', 'closeCreateOrEditDialog'],
  name: 'MDATableActions',
  actions: {
    createTable: function(dbId, name) {
      var id;
      this.dispatch();
      id = "" + MDA_TABLE_PREFIX + (generateGUID());
      return MdaTableDoc.at(dbId, id).create({
        _id: id,
        name: name
      })["catch"](function(err) {
        return appUtils.errorHandlerWithMsg(err, $.t('mda:tables.create_error'));
      })["finally"]((function(_this) {
        return function() {
          return _this.actions.closeCreateOrEditDialog();
        };
      })(this));
    },
    dbChange: function(change, _pending, _lastSeq) {
      var isCurrentTable, _ref2, _ref3;
      if (change.deleted || ((_ref2 = change.doc) != null ? _ref2.docType : void 0) === MDA_TABLE_DOC_TYPE) {
        QuestionsListComponentActions.dbChange(change);
      }
      isCurrentTable = change.id === this.alt.stores.MdaTableStore.getCurrentTableId();
      if (isCurrentTable || change.deleted || ((_ref3 = change.doc) != null ? _ref3.docType : void 0) === MDA_TOPIC_DOC_TYPE) {
        return this.dispatch(change);
      }
    },
    fetch: function(_arg) {
      var dbId, docId;
      dbId = _arg.dbId, docId = _arg.docId;
      this.dispatch(docId);
      QuestionsListComponentActions.fetch({
        questionsCollectionType: MDA_TABLE_DOC_TYPE,
        questionType: MDA_TABLE_DOC_TYPE
      });
      return W.all([MdaTableDoc.at(dbId, docId).fetch(), MdaTopicDoc.at(dbId).fetchMany()]).then((function(_this) {
        return function(_arg1) {
          var table, topicsRows, _ref2;
          table = _arg1[0], (_ref2 = _arg1[1], topicsRows = _ref2.rows);
          return _this.actions.fetchSuccess({
            table: table,
            topicsRows: topicsRows
          });
        };
      })(this))["catch"](function(err) {
        return appUtils.errorHandlerWithMsg(err, $.t('mda:tables.fetch_error'));
      });
    },
    updateContent: function(dbId, docId, content) {
      return MdaTableDoc.at(dbId, docId).updateAsInstance(callMethod('updateContent', content))["catch"](function(err) {
        return appUtils.errorHandlerWithMsg(err, $.t('mda:tables.update_content_error'));
      });
    },
    updateName: function(dbId, docId, name) {
      this.dispatch();
      return MdaTableDoc.at(dbId, docId).updateAsInstance(callMethod('updateName', name))["catch"](function(err) {
        return appUtils.errorHandlerWithMsg(err, $.t('mda:tables.update_name_error'));
      })["finally"]((function(_this) {
        return function() {
          return _this.actions.closeCreateOrEditDialog();
        };
      })(this));
    }
  }
});
