var ArchieAPI, RevManWebActions, W, alt, mediator;

alt = require('alt');

mediator = require('mediator');

ArchieAPI = require('actions/async/archie_api');

W = require('when/when');

RevManWebActions = (function() {
  function RevManWebActions() {
    this.generateActions('syncToRevManSuccess', 'syncToRevManError', 'syncToRevManOffline');
  }

  RevManWebActions.prototype.syncToRevMan = function(reviewId, tableId, tableBody, tableFootnotes, tableTitle, isUserAction) {
    if (isUserAction == null) {
      isUserAction = false;
    }
    this.dispatch();
    if (mediator.services.replication.isConnected()) {
      return ArchieAPI.revmanWebUpdateTable(reviewId, tableId, tableBody, tableFootnotes, tableTitle, isUserAction).then(this.actions.syncToRevManSuccess)["catch"](this.actions.syncToRevManError);
    } else {
      return this.actions.syncToRevManOffline();
    }
  };

  RevManWebActions.prototype.deleteTable = function(reviewId, tableId) {
    this.dispatch();
    return ArchieAPI.deleteTable(reviewId, tableId);
  };

  return RevManWebActions;

})();

module.exports = alt.createActions(RevManWebActions);
