var DEFAULT_CONTENT_BLOCKS, DOC_SECTIONS, DocumentSectionsActions, DocumentSectionsStore, OUTCOMES_GENERATION_STEPS, OutcomesGenStore, QUESTION_GENERATION_STEPS, QuestionsGenStore, alt, createContentBlock, errorHandler, finishedOutcomesGenStep, finishedQuestionGenStep, getOutcomesContentBlocks, getQuestionsContentBlocks, immutable, rawContentBlocksToRawContent, _ref, _ref1, _ref2;

alt = require('alt');

immutable = require('stores/utils/immutable');

DocumentSectionsActions = require('actions/document_sections_actions');

QuestionsGenStore = require('stores/questions_generation_store');

OutcomesGenStore = require('stores/outcomes_generation_store');

errorHandler = require('lib/app_utils').errorHandler;

_ref = require('lib/questions_helper'), OUTCOMES_GENERATION_STEPS = _ref.OUTCOMES_GENERATION_STEPS, QUESTION_GENERATION_STEPS = _ref.QUESTION_GENERATION_STEPS;

_ref1 = require('lib/document_sections_utils'), DOC_SECTIONS = _ref1.DOC_SECTIONS, DEFAULT_CONTENT_BLOCKS = _ref1.DEFAULT_CONTENT_BLOCKS, getQuestionsContentBlocks = _ref1.getQuestionsContentBlocks, getOutcomesContentBlocks = _ref1.getOutcomesContentBlocks;

_ref2 = Editor.EditorUtils, rawContentBlocksToRawContent = _ref2.rawContentBlocksToRawContent, createContentBlock = _ref2.createContentBlock;

finishedQuestionGenStep = QUESTION_GENERATION_STEPS[6];

finishedOutcomesGenStep = OUTCOMES_GENERATION_STEPS[6];

DocumentSectionsStore = (function() {
  function DocumentSectionsStore() {
    this.state = Immutable.fromJS({
      _meta: {
        fetching: false
      },
      content: null,
      activeSections: DOC_SECTIONS
    });
    this.bindActions(DocumentSectionsActions);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      getContent: this.getContent,
      getActiveSections: this.getActiveSections
    });
  }

  DocumentSectionsStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  DocumentSectionsStore.prototype.onFetchSuccess = function(data) {
    var activeSections, content;
    content = data.content, activeSections = data.activeSections;
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], false);
      state.set('content', Immutable.fromJS(data.content));
      return state.set('activeSections', Immutable.fromJS(activeSections));
    }));
  };

  DocumentSectionsStore.prototype.onFetchError = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    if (err.status !== 404) {
      return errorHandler(err);
    }
  };

  DocumentSectionsStore.prototype.onToggleSection = function(sectionName) {
    var activeSections, isActive;
    activeSections = this.getActiveSections();
    isActive = activeSections.contains(sectionName);
    return this.setState(this.state.set('activeSections', isActive ? activeSections.filterNot(function(name) {
      return name === sectionName;
    }) : activeSections.push(sectionName)));
  };

  DocumentSectionsStore.prototype.getContent = function() {
    var contentBlocks, qGroups, questions, savedContent;
    savedContent = this.state.get('content');
    if (savedContent != null) {
      return savedContent;
    }
    contentBlocks = [].concat(DEFAULT_CONTENT_BLOCKS);
    if (QuestionsGenStore.getCurrentStep() === finishedQuestionGenStep) {
      questions = QuestionsGenStore.getStepsData().getIn([finishedQuestionGenStep, 'questions']);
      contentBlocks = contentBlocks.concat(getQuestionsContentBlocks(questions));
    }
    if (OutcomesGenStore.getCurrentStep() === finishedOutcomesGenStep) {
      qGroups = OutcomesGenStore.getStepsData().getIn([finishedOutcomesGenStep, 'questionGroups']);
      contentBlocks = contentBlocks.concat(getOutcomesContentBlocks(qGroups));
    }
    return rawContentBlocksToRawContent(contentBlocks.map(createContentBlock));
  };

  DocumentSectionsStore.prototype.getActiveSections = function() {
    return this.state.get('activeSections');
  };

  DocumentSectionsStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  return DocumentSectionsStore;

})();

module.exports = alt.createStore(immutable(DocumentSectionsStore), 'DocumentSectionsStore');
