var EtdTemplateDefinitionsChange, Migration, W, mediator, migrationName, requiredFields,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'etd_template_definitions_change';

requiredFields = ['recommendationTypeIntervention', 'recommendationTypeOption', 'decisionTypeCoverage', 'decisionTypeImplementation', 'recommendation', 'decision', 'justification'];

module.exports = EtdTemplateDefinitionsChange = (function(_super) {
  __extends(EtdTemplateDefinitionsChange, _super);

  function EtdTemplateDefinitionsChange() {
    EtdTemplateDefinitionsChange.__super__.constructor.call(this, false);
  }

  EtdTemplateDefinitionsChange.prototype.up = function(project, colls) {
    var adapter, projectId;
    EtdTemplateDefinitionsChange.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return adapter.fetch(projectId, ['etd-tx-template', 'etd-dx-template']).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return;
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        doc.templateDef.conclusions = _.map(doc.templateDef.conclusions, function(section) {
          var sectionId;
          sectionId = section.id;
          if (__indexOf.call(requiredFields, sectionId) >= 0) {
            return {
              id: sectionId,
              required: true
            };
          } else {
            return section;
          }
        });
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return EtdTemplateDefinitionsChange;

})(Migration);
