var CATEGORIES, CONDITION_SEVERITY, LEVEL_OF_CONFIDENCE, MDGTopicsField, MDG_RECOMMENDATION_FORM_ITEMS, OPERATIVE_PHASE, PHASE_OF_CARE, RelatedTableSelector, STRENGTH_OF_EVIDENCE, fieldMapper;

MDGTopicsField = require('components/administration/mdg_topics_field');

RelatedTableSelector = require('components/mdg_tables/related_table_selector');

fieldMapper = function(key) {
  return function(value) {
    return {
      value: value,
      text: $.t("es:recommendations.metadataExtractionForm.values." + key + "." + value)
    };
  };
};

STRENGTH_OF_EVIDENCE = _.map(["stronglyRecommended", "moderatelyRecommended", "recommendedEvidence", "recommendedInsufficientEvidence", "noRecommendationInsufficientEvidence", "notRecommendedInsufficientEvidence", "notRecommended", "moderatelyNotRecommended", "stronglyNotRecommended"], fieldMapper('strengthOfEvidence'));

LEVEL_OF_CONFIDENCE = _.map(['high', 'moderate', 'low'], fieldMapper('levelOfConfidence'));

CATEGORIES = _.map(['diagnosticInterventions', 'activityModificationAndExercise', 'medications', 'alliedHealthInterventions', 'electricalTherapies', 'hotAndColdTherapies', 'devices', 'injectionTherapy', 'surgicalConsiderations', 'behavioralAndPsychologicalInterventions', 'rehabilitationPrograms'], fieldMapper('category'));

PHASE_OF_CARE = _.map(['acute', 'subacute', 'chronic', 'notApplicable'], fieldMapper('phaseOfCare'));

CONDITION_SEVERITY = _.map(['mild', 'moderate', 'severe', 'notApplicable'], fieldMapper('conditionSeverity'));

OPERATIVE_PHASE = _.map(['preoperative', 'perioperative', 'postoperative', 'notApplicable'], fieldMapper('operativePhase'));

MDG_RECOMMENDATION_FORM_ITEMS = {
  main: [
    {
      fieldKey: 'recommendationTitle',
      type: 'text'
    }, {
      fieldKey: 'strengthOfEvidence',
      type: 'select',
      options: STRENGTH_OF_EVIDENCE
    }, {
      fieldKey: 'levelOfConfidence',
      type: 'select',
      options: LEVEL_OF_CONFIDENCE
    }, {
      fieldKey: 'topic',
      type: 'custom',
      customWithArray: true,
      component: MDGTopicsField
    }, {
      fieldKey: 'category',
      type: 'multiSelect',
      options: CATEGORIES
    }, {
      fieldKey: 'phaseOfCare',
      type: 'multiSelect',
      options: PHASE_OF_CARE
    }, {
      fieldKey: 'conditionSeverity',
      type: 'multiSelect',
      options: CONDITION_SEVERITY
    }, {
      fieldKey: 'operativePhase',
      type: 'multiSelect',
      options: OPERATIVE_PHASE
    }, {
      fieldKey: 'relatedTable',
      type: 'custom',
      customWithArray: true,
      component: RelatedTableSelector,
      showClear: false
    }, {
      fieldKey: 'links',
      type: 'multiText',
      addValueLabel: $.t('actions.add_link')
    }
  ]
};

module.exports = {
  MDG_RECOMMENDATION_FORM_ITEMS: MDG_RECOMMENDATION_FORM_ITEMS
};
