var DashboardController, DashboardView, GdtRouter, ModuleController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DashboardView = require('views/dashboard/dashboard_view');

GdtRouter = require('router');

mediator = require('mediator');

ModuleController = require('controllers/base/module_controller');

module.exports = DashboardController = (function(_super) {
  __extends(DashboardController, _super);

  function DashboardController() {
    return DashboardController.__super__.constructor.apply(this, arguments);
  }

  DashboardController.prototype.index = function() {
    var hasDashboardFeatureSwitch, isMdg, redirectFn;
    redirectFn = (function(_this) {
      return function() {
        _this.redirectTo(GdtRouter.prototype.routeForModule('evidence-syntheses', {
          projectId: mediator.project.id
        }));
      };
    })(this);
    if (!mediator.user.isAdmin()) {
      return redirectFn();
    }
    isMdg = mediator.services.switches.isServerSwitchOn('mdgFeatures');
    hasDashboardFeatureSwitch = mediator.services.switches.isOn('dashboard');
    if (!(isMdg || hasDashboardFeatureSwitch)) {
      return redirectFn();
    }
    return this.view = new DashboardView;
  };

  return DashboardController;

})(ModuleController);
