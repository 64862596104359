{func, string, bool} = PropTypes
Switcher = require 'components/common/switcher'

SectionSelectionRow = createReactClass

  displayName: "SectionSelectionRow"

  propTypes:
    checked: bool.isRequired
    name: string.isRequired
    onCheck: func.isRequired
    onPreselect: func.isRequired
    preselected: bool.isRequired
    title: string.isRequired
    disabled: bool
    preselectDisabled: bool

  getInitialProps: ->
    disabled: false
    preselectDisabled: false

  onCheck: ->
    return if @props.disabled
    @props.onCheck(@props.name)

  onPreselect: ->
    return if @props.preselectDisabled or not @props.checked
    @props.onPreselect(@props.name)

  render: ->
    classes = classNames "section-selection-row",
      "checked": @props.checked and not @props.disabled
      "disabled": @props.disabled and @props.preselectDisabled and @props.status isnt 'ongoing'

    <div className={classes}>
      <label className="pull-left">
        <input type="checkbox"
          checked={@props.checked}
          onChange={@onCheck}
          disabled={@props.disabled}
        />
        {@props.title}
      </label>
      <Switcher
        className="preselect pull-right"
        buttonText=""
        checked={@props.preselected}
        disabled={@props.preselectDisabled or not @props.checked}
        onChange={@onPreselect}
      />
      <div className="clearfix" />
    </div>

module.exports = SectionSelectionRow
