var colWidths, provider, style;

provider = require('lib/gdt_tables/cell_props_provider').provider;

colWidths = {
  sof: {
    riskWithControl: '12%',
    riskWithIntervention: '12%',
    riskDifferenceWithIntervention: '12%',
    control: '12%',
    correspondingRisk: '12%',
    relativeEffect: '12%',
    noOfParticipantsStudies: '12%',
    certainty: '12%',
    quality: '12%',
    comments: '26%',
    tableMode: '30px',
    editControls: '30px'
  },
  one_row: {
    noOfStudies: '4%',
    designStudies: '8%',
    studyDesign: '8%',
    calculatedControlRisk: '8%',
    lowControlRisk: '8%',
    moderateControlRisk: '8%',
    highControlRisk: '8%',
    riskOfBias: '7%',
    inconsistency: '7%',
    indirectness: '7%',
    imprecision: '7%',
    otherConsiderations: '11%',
    intervention: '8%',
    control: '8%',
    relativeEffect: '8%',
    absoluteEffect: '8%',
    calculatedControlAbsoluteEffect: '8%',
    certainty: '6%',
    quality: '6%',
    narrativeDesc: '32%',
    tableMode: '30px',
    source: '6%',
    importance: '6%'
  }
};

style = function(cellId, outcomeAttrs) {
  var baseStyleObj, tableViewType, _ref, _ref1;
  tableViewType = outcomeAttrs._viewTypeName;
  baseStyleObj = {
    wordBreak: 'break-word',
    width: (_ref = (_ref1 = colWidths[tableViewType]) != null ? _ref1[cellId] : void 0) != null ? _ref : 'auto'
  };
  if (cellId === 'sofOutcomeEdit') {
    return _.extend(baseStyleObj, {
      display: 'none'
    });
  } else if (cellId === 'source') {
    return _.extend(baseStyleObj, {
      fontWeight: 'bold'
    });
  } else {
    return baseStyleObj;
  }
};

module.exports = provider('style', style);
