ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

AddOutcomeModal = createReactClass
  displayName: 'AddOutcomeModal'
  mixins: [
    Translation('scope:outcomes')
    CustomRenderMixin
  ]

  propTypes:
    questionGroups: PropTypes.object.isRequired
    isOpen: PropTypes.bool.isRequired
    onClose: PropTypes.func.isRequired
    onAdd: PropTypes.func.isRequired

  getInitialState: ->
    selectedQuestions: []

  _areAllChecked: ->
    @state.selectedQuestions.length is @props.questionGroups.size

  _isQuestionChecked: (gIndex) ->
    @state.selectedQuestions.indexOf(gIndex) > -1

  _questionGroupMapper: (qGroup, idx) ->
    checked = @_isQuestionChecked idx
    # if this is a group then show a group name otherwise it is a single question no-name
    # group, so just show the text of the first (and only) question
    labelText = qGroup.get('name') or qGroup.get('questions').get(0).get('question')

    <li key={idx}>
      <label>
        <input type='checkbox' onChange={@toggleQuesiton(idx)} checked={checked} />
        <span>{labelText}</span>
      </label>
    </li>

  toggleAll: ->
    if @_areAllChecked()
      @setState selectedQuestions: []
    else
      @setState selectedQuestions: [0..@props.questionGroups.size - 1]

  toggleQuesiton: (gIndex) -> =>
    selectedQuestions = @state.selectedQuestions
    if selectedQuestions.indexOf(gIndex) is -1
      @setState selectedQuestions: selectedQuestions.concat [gIndex]
    else
      @setState selectedQuestions: _.reject selectedQuestions, (idx) -> idx is gIndex

  onAdd: ->
    @props.onAdd groups: @state.selectedQuestions, name: @refs.outcomeText.value
    @props.onClose()
    @setState selectedQuestions: []

  render: ->
    outcomeText = @props.outcomeName or "[#{@i18n 'new_outcome'}]"
    allChecked = @_areAllChecked()
    isAddDisabled = _.isEmpty @state.selectedQuestions

    <Modal
      isOpen={@props.isOpen}
      onClose={@props.onClose}
      className="add-outcome-modal"
      closeButton={true}
    >
      <div>
        <div className="header">
          {@i18n 'add_outcome'}
        </div>
        <div className="details">
          <input ref='outcomeText' type='text' defaultValue={outcomeText} />
          <div className="add-to">{@i18n 'add_to'}:</div>
          <ul className="outcome-list">
            <li className="all">
              <label>
                <input type='checkbox' checked={allChecked} onChange={@toggleAll} />
                <span>{@i18n 'select_all'}</span>
              </label>
            </li>
            <hr />
            {@props.questionGroups.map @_questionGroupMapper}
          </ul>
        </div>
        <ApplyCancelButtons
          onApply={@onAdd}
          onCancel={@props.onClose}
          isSubmitEnabled={not isAddDisabled}
          applyLabel={@i18n 'add_outcome_to_selected'}
        />
      </div>
    </Modal>

module.exports = AddOutcomeModal
