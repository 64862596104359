CustomRenderMixin = require 'components/mixins/custom_render_mixin'

TabsPane = createReactClass
  displayName: 'TabsPane'

  propTypes:
    activeTab: PropTypes.string.isRequired
    tabs: PropTypes.array.isRequired
    onTabClick: PropTypes.func.isRequired

  mixins: [CustomRenderMixin]

  changeTab: (e) ->
    return if e.currentTarget.classList.contains 'disabled'
    tabName = e.currentTarget.getAttribute 'data-tab'
    @props.onTabClick tabName

  render: ->
    <div className='breadcrumbs-container'>
      <ol className='breadcrumbs progress-bar'>
        {
          @props.tabs.map (tabDetails, idx) =>
            {tabName, tabLabel, additionalLabel, isDisabled} = tabDetails
            isSelected = tabName is @props.activeTab
            liClasses = classNames 'breadcrumbs-tab',
              'selected': isSelected
              'disabled': isDisabled
              'with-additional-label': additionalLabel

            <li
              className={liClasses}
              data-tab={tabName}
              onClick={@changeTab}
              key={idx}
            >
              <span className="text-label">{ tabLabel }</span>
              {if additionalLabel
                <span className="additional-text-label">
                  { additionalLabel }
                </span>
              }
            </li>
        }
      </ol>
    </div>

module.exports = TabsPane
