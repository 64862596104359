CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdEditor = require 'components/etd/etd_editor'
EtdActions = require 'actions/etd_actions'
{ bool, instanceOf, oneOfType, string, func } = PropTypes

ConclusionsTextSection = createReactClass
  displayName: 'ConclusionsTextSection'

  propTypes:
    cellId: string.isRequired
    content: oneOfType [instanceOf(Immutable.Map), string]
    editable: bool
    isConsensus: bool
    noAutoSave: bool
    noInsertOption: bool
    onChange: func
    placeholder: string
    readOnly: bool
    withApplyCancelButtons: bool

  mixins: [ CustomRenderMixin ]

  getDefaultProps: ->
    editable: true
    isConsensus: false
    noAutoSave: false
    noInsertOption: true
    onChange: null
    readOnly: false
    withApplyCancelButtons: true

  render: ->

    {
      attachments
      cellId
      content
      editable
      isConsensus
      noAutoSave
      noInsertOption
      onChange
      placeholder
      readOnly
      refToEditor
      renderMode
      withApplyCancelButtons
    } = @props

    editable = if (renderMode is 'printout' or readOnly) then false else editable

    <EtdEditor
      attachments={attachments}
      cellId={cellId}
      content={content}
      editable={editable}
      forceTableExpand={readOnly or renderMode is 'printout'}
      isConsensus={isConsensus}
      noAutoSave={noAutoSave}
      noInsertOption={noInsertOption}
      ref={refToEditor}
      onChange={onChange}
      placeholder={placeholder}
      renderMode={renderMode}
      withApplyCancelButtons={withApplyCancelButtons}
      onSaveContent={EtdActions.updateEditorContent}
      onSaveAttachment={EtdActions.saveAttachment}
    />

module.exports = ConclusionsTextSection
