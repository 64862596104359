Button = require 'components/common/button'
Translation = require 'components/mixins/translation'

CellContentModeDialog = createReactClass
  displayName: 'CellContentModeDialog'
  mixins: [Translation('es:question')]

  render: ->
    if @props.currentlyInAutoMode
      confirmText = @i18n 'change_to_manual_title'
      declineText = @i18n 'keep_auto_title'
      msg = @i18n '../recommendations.header.switch_to_manual_header_cell_text'
    else
      confirmText = @i18n 'change_to_auto_title'
      declineText = @i18n 'keep_manual_title'
      msg = @i18n '../recommendations.header.switch_to_auto_header_cell_text'

    <div>
      <p>{msg}</p>
      <div className="buttons">
        <Button
          className="btn-cancel"
          label={declineText}
          onClick={@props.handleInput.bind null, false}
        />
        <Button
          className="btn"
          label={confirmText}
          onClick={@props.handleInput.bind null, true}
        />
      </div>
    </div>

module.exports = CellContentModeDialog
