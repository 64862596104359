ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
NumberedItemsList = require 'components/common/numbered_questions_list'
ProjectsActions = require 'actions/projects_actions'
QuestionGroupsActions = require 'actions/question_groups_actions'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsActions = require 'actions/questions_actions'
QuestionsSelector = require 'components/common/questions_selector'
QuestionsStore = require 'stores/questions_store'
SpinnerSmall = require 'components/common/spinner_small'
Translation = require 'components/mixins/translation'
{ fetchQuestionsFromProjectPromise } = require 'lib/adolopments_helper'
{ getQuestionsWithoutEtd, groupQuestionsByGroup } = require 'lib/questions_helper'


storeConnectors =
  QuestionsStore: (Store) ->
    isFetchingQuestions: Store.isFetching()
    questions: Store.getQuestions()
  QuestionGroupsStore: (Store) ->
    isFetchingGroups: Store.isFetching()
    questionGroups: Store.getQuestionGroups()

CreateAdolopment = createReactClass

  displayName: "CreateAdolopment"
  mixins: [Translation('projects:adolopment_dialog')]

  propTypes:
    isFetchingGroups: PropTypes.bool.isRequired,
    isFetchingQuestions: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired
    organizationId: PropTypes.string
    projectId: PropTypes.string.isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    questions: PropTypes.instanceOf(Immutable.Map).isRequired

  getInitialState: ->
    loading: false
    selectedQuestions: Immutable.List()

  componentDidMount: ->
    QuestionGroupsActions.fetch('regular', @props.projectId)
    QuestionsActions.fetch(projectId: @props.projectId)

  isFetching: ->
    @props.isFetchingGroups or @props.isFetchingQuestions

  createAdolopment: ->
    @setState loading: true
    { projectId, organizationId, onRequestClose } = @props

    ProjectsActions.createAdolopment(projectId, organizationId, @state.selectedQuestions).then =>
      @setState loading: false
      onRequestClose()

  onUpdateSelected: (newSelected) ->
    @setState selectedQuestions: newSelected

  render: ->
    { questionGroups, questions } = @props
    questionsWithoutEtd = getQuestionsWithoutEtd(questions).reduce (acc, question) ->
      acc.push question.get('_id')
    , Immutable.List()

    <div>
      <div className="description" dangerouslySetInnerHTML={{__html: @i18n 'description'}} />
      {@isFetching() and <SpinnerSmall />}
      {not @isFetching() and <QuestionsSelector
          blockedQuestions={questionsWithoutEtd}
          updateSelected={@onUpdateSelected}
          selectedQuestions={@state.selectedQuestions}
          withSelectAll
          withSearch
          withNumbers
          questionGroups={questionGroups}
          questions={questions}
      />}

      <div className="mt-10">
        <ApplyCancelButtons
          onApply={@createAdolopment}
          onCancel={@props.onRequestClose}
          applying={@state.loading}
          isSubmitEnabled={not @isFetching() and not @state.selectedQuestions.isEmpty()}
          applyLabel={@i18n '/actions.create'}
        />
      </div>
    </div>


module.exports = ConnectStore(CreateAdolopment,
  [ QuestionGroupsStore, QuestionsStore ]
, storeConnectors)
