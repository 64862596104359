var Exception;

module.exports = Exception = (function() {
  Exception.prototype.name = 'Exception';

  Exception.prototype.message = 'Generic exception';

  function Exception(name, message) {
    this.name = name;
    this.message = message;
  }

  return Exception;

})();
