var MdgSex, boolean, initial, number, optional, shape, string, _ref;

_ref = require('lib/db_docs/field_types/built_in_types'), boolean = _ref.boolean, number = _ref.number, string = _ref.string;

initial = require('lib/db_docs/field_types/type_decorators').initial;

shape = require('lib/db_docs/field_types/shape');

optional = shape.typeDecorators.optional;

MdgSex = shape({
  count: optional(number),
  name: optional(string),
  selected: initial(false)(boolean),
  value: initial('')(string)
});

module.exports = MdgSex;
