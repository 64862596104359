ContentEditable = require './content_editable'
Button = require './button'
Translation = require 'components/mixins/translation'

ContentEditableWithButtons = createReactClass
  mixins: [Translation('')]
  displayName: "ContentEditableWithButtons"

  propTypes:
    actions: PropTypes.object.isRequired
    # need to be able to identify the content editable
    id: PropTypes.string.isRequired
    # need to know how to render the contentEditable
    currentEditable: PropTypes.string.isRequired

  componentDidMount: ->
    @initialHTML = @props.content

  shouldComponentUpdate: (nextProps, nextState) ->
    return if nextProps.id isnt @props.id
    editModeChanged = @_activeEditable() isnt @_activeEditable(nextProps)
    contentHtml = @props.content
      .replace(/<\w+([^<>]*)>/g,(m) -> m.replace(/'/g, '"'))
      .replace(/⨁/g,'&#x2a01;')
      .replace(/◯/g,'&#x25ef;')
    contentChanged = contentHtml? and contentHtml isnt nextProps.content
    return editModeChanged or contentChanged

  componentWillReceiveProps: (nextProps) ->
    # below ensures that once we switch to another editable we update the value of @initialHTML,
    # otherwise if we come back to this editable and during edit press cancel we will get the
    # very first HTML back and not the one which was applied when we moved to another editable.
    # Also we trigger content saving to DB
    if @_activeEditable() and not @_activeEditable(nextProps) and not @cancelled
      currentHTML = ReactDOM.findDOMNode(@refs.contentEditable).innerHTML
      contentWasUpdated = @initialHTML isnt currentHTML
      if contentWasUpdated
        @initialHTML = currentHTML
        @props.actions.updateContentEditableContent @props.id, currentHTML, @props.options
    else
      @cancelled = false

  focus: ->
    if @_activeEditable()
      @refs.contentEditable.focus()

  componentWillUnmount: ->
    @_close() if @props.isActiveEditable

  onClick: ->
    @props.actions.enableContentEditable @props.id

  cancel: ->
    @props.actions.updateContentEditableContent @props.id, @initialHTML, @props.options
    @cancelled = true
    @_close()

  apply: ->
    @props.actions.updateContentEditableContent @props.id,
      ReactDOM.findDOMNode(@refs.contentEditable).innerHTML, @props.options
    @_close()

  _close: ->
    @props.actions.disableContentEditable()

  _activeEditable: (props = @props) ->
    props.currentEditable is props.id

  render: ->
    <div className="editor-container">
      {if @_activeEditable()
        <div>
          {if @props.topButtons
            <div className="top-buttons row">
              {@props.topButtons}
            </div>
          }
          <ContentEditable
            ref="contentEditable"
            html={@props.content}
            withEditor={@props.withEditor}
          />
          <div className="row buttons-react">
            <div className="col-6">
              <Button
                className="cancel btn btn-block btn-cancel"
                label={@i18n 'actions.cancel'}
                onMouseDown={@cancel}/>
            </div>
            <div className="col-6">
              <Button
                className="apply btn btn-block btn-apply"
                label={@i18n 'actions.apply'}
                onMouseDown={@apply}/>
            </div>
          </div>
        </div>
      else
        <ContentEditable
          contentEditable={false}
          ref="contentEditable"
          onClick={@onClick}
          html={@props.content}
        />
      }
    </div>

module.exports = ContentEditableWithButtons
