AccessRights = require 'components/team/form/access_rights'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Button = require 'components/common/button'
ConnectStores = require 'components/enhancers/connect_store'
EditableNameCell = require 'components/team/form/editable_name_cell'
LimitsWarning = require 'components/common/limits_warning'
MemberFormGroup = require 'components/team/member_form_group'
MembersActions = require 'actions/members_actions'
MembersLimitsInfo = require 'components/team/members_limits_info'
MembersStore = require 'stores/members_store'
PanelMemberCell = require 'components/team/form/panel_member_cell'
Translation = require 'components/mixins/translation'
Tooltip = require 'components/common/tooltip'
{
  createMember
  filterAdminsAndResearchers
  getMemberAccessRights
  getMembersWithPendingInvitationsCount
} = require 'lib/members_helper'
withLimits = require 'components/enhancers/with_limits'
{ isValidLimit } = require 'lib/limits_helper'


SingleMemberForm = createReactClass

  displayName: "SingleMemberForm"

  propTypes:
    limits: PropTypes.instanceOf(Immutable.Map).isRequired
    members: PropTypes.instanceOf(Immutable.Map).isRequired
    onApply: PropTypes.func.isRequired
    onCancel: PropTypes.func.isRequired

  mixins: [Translation('team:member')]

  getInitialState: ->
    member: Immutable.fromJS createMember()

  save: ->
    @props.onApply @state.member

  cancel: ->
    # clear errors for this member
    MembersActions.resetErrors @state.member.get("_id")
    @props.onCancel()

  togglePanelMemberRole: ->
    @updateMember 'panelMember', not @state.member.get('panelMember')

  updateMember: (attr, value) ->
    @setState member: @state.member.set(attr, value)
    MembersActions.updateMember @state.member.get('_id'), attr, value

  updateAccessRights: (value) ->
    @updateMember 'accessRights', Immutable.List [value]

  _getTotalAdminsCount: ->
    { members } = @props
    currentAdminsCount = filterAdminsAndResearchers(members).size
    isUserAddingNewAdmin = @state.member.get('accessRights').first() in ['admin', 'researcher']
    pendingInvitationsCount = getMembersWithPendingInvitationsCount members
    currentAdminsCount + pendingInvitationsCount + Number(isUserAddingNewAdmin)

  getDisabledAccessRights: ->
    { limits } = @props
    if @_getTotalAdminsCount() >= limits.get('teamAdmins') then ['admin', 'researcher'] else []

  render: ->

    { limits, members } = @props

    membersLimit = limits.get('teamMembers')
    adminsLimit = limits.get('teamAdmins')

    currentMembersCount = members.size
    currentAdminsCount = filterAdminsAndResearchers(members).size

    disabledAccessRights = @getDisabledAccessRights()

    isSubmitEnabled = isValidLimit(currentMembersCount, membersLimit) and
      isValidLimit(@_getTotalAdminsCount(), adminsLimit, 'lte')

    <div className="single-team-member-form content">

      <MembersLimitsInfo
        adminsCount={currentAdminsCount}
        i18n={@i18n}
        limits={limits}
        membersCount={currentMembersCount}
      />

      <LimitsWarning
        value={@_getTotalAdminsCount()}
        limit={adminsLimit}
        message={@i18n '../limits.admins_limit_reached'}
      />

      <EditableNameCell
        updateAttr={@updateMember}
        member={@state.member}
        errors={@props.errors.get(@state.member.get('_id'))}
        displayNotesGroup={false}
      />

      <div className="form-group access-rights">
        <div className="col-12">
          <label className="pull-left required" htmlFor='access-rights'>
            {@i18n 'access_rights'}
          </label>
          <div className="pull-left select-container">
            <AccessRights
              adminsLimit={adminsLimit}
              disabledAccessRights={disabledAccessRights}
              error={@props.errors.getIn([@state.member.get('_id'), 'accessRights'])}
              member={@state.member}
              onChange={@updateAccessRights}
              selected={getMemberAccessRights(@state.member)}
            />
          </div>
        </div>
      </div>

      <div className="form-group panel-member-toggle">
        <div className="col-12">
          <label className="pull-left" htmlFor='panel-member'>
            {@i18n 'panel_participation'}
            <Tooltip tooltipType="info" tipJoint="bottom left" targetJoint="top right">
              <span
                className="info-sign"
                title={@i18n "panel_member_description"} />
            </Tooltip>
          </label>
          <div className="pull-left select-container">
            <PanelMemberCell
              el="div"
              expanded
              withLabel={false}
              className="panel-member"
              member={@state.member}
              customUpdate={@togglePanelMemberRole}
            />
          </div>
        </div>
      </div>
      <ApplyCancelButtons
        isSubmitEnabled={isSubmitEnabled}
        applyLabel={@i18n '/actions.save'}
        onApply={@save}
        onCancel={@cancel}
        wrappedWithCol={false}
      />
    </div>

storeConnectors = MembersStore: (MembersStore) ->
  errors: MembersStore.getValidationErrors()
  members: MembersStore.getMembers()

module.exports = {
  PureSingleMemberForm: SingleMemberForm
  SingleMemberForm: withLimits(ConnectStores SingleMemberForm, MembersStore, storeConnectors)
}
