ReferencesActions = require 'actions/references_actions'
Translation = require 'components/mixins/translation'
IconButton = require 'components/common/icon_button'
Modal = require 'components/common/modal'
Confirmation = require 'components/common/confirmation'

ReferenceControls = createReactClass
  displayName: 'ReferenceControls'

  propTypes:
    referenceId: PropTypes.string.isRequired

  mixins: [ Translation() ]

  getInitialState: ->
    showDeleteConfirmation: false

  removeReference: ->
    ReferencesActions.removeReference @props.referenceId

  hideDeleteConfirmation: ->
    @setState showDeleteConfirmation: false

  onDelete: (evt) ->
    evt.stopPropagation()
    @setState showDeleteConfirmation: true

  onEdit: ->
    ReferencesActions.toggleReferenceEdit()

  render: ->
    <div className='reference-controls'>
      <IconButton iconName='delete' onClick={@onDelete} />
      <IconButton iconName='edit' onClick={@onEdit} />
      {if @state.showDeleteConfirmation
        <Modal className='confirmation-modal-react' onClose={@hideDeleteConfirmation} isOpen>
          <Confirmation
            question={@i18n 'references:delete_reference_confirmation'}
            onConfirm={@removeReference}
            onCancel={@hideDeleteConfirmation}
          />
        </Modal>
      }
    </div>

module.exports = ReferenceControls
