QuestionsList = require 'components/administration/voting/voting_questions_list'
{
  getRecommendationTypeSections
  hasJudgementsFilled
  hasRecommendationFilled
  hasConclusionsFilled
 } = require 'lib/etd_helper'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

TYPE_OF_RECOMMENDATION_SECTIONS = getRecommendationTypeSections()

ValidatedQuestionsList = createReactClass
  displayName: 'ValidatebleQuestions'
  propTypes:
    questions: PropTypes.object.isRequired
    etds: PropTypes.object.isRequired
    phase: PropTypes.string.isRequired
    projectId: PropTypes.string.isRequired

  mixins: [
    CustomRenderMixin
    Translation('voting:voting')
  ]

# GETTERS, HELPERS
  _phase1validator: (etd) ->
    # if selected ETD parts option is 'ATC', that is all parts are being sent, check them all,
    # which basically means phase2 validation where we check all sections content
    if @props.selectedEtdPartsOption is 'ATC'
      @_phase2validator etd
    else
      # Otherwise just ensure that all judgments are selected, that is check all judgments'
      # selectedOption
      hasJudgementsFilled etd

  _phase2validator: (etd) ->
    hasJudgements = hasJudgementsFilled etd, true
    hasRecommendation = hasRecommendationFilled etd, true
    hasJustification = hasConclusionsFilled etd, true

    hasJudgements and hasRecommendation and hasJustification

  _etdValidator: (etdId) ->
    etd = @props.etds.get etdId
    if @props.phase is 'phase1a'
      @_phase1validator etd
    else if @props.phase is 'phase2a'
      @_phase2validator etd
    else
      false

  _validateEtds: (etdIds) ->
    # questions without Etd tables automatically fail validation
    return false unless etdIds.size
    # no validation is required if
    return true if (
      # judgements are not 'proposed' (selectedJudgmentsOption isnt 'all' and
      # selectedEtdPartsOption isnt ATC)
      @props.phase is 'phase1a' and
      @props.selectedJudgmentsOption isnt 'all' and
      @props.selectedEtdPartsOption isnt 'ATC' or
      # Type of recommendation and Conclusions are not 'proposed' that is selectedContentOption
      # is 'empty'
      @props.phase is 'phase2a' and @props.selectedContentOption is 'empty'
    )
    etdIds.every @_etdValidator

  _getValidQuestionsIds: ->
    @props.questions.reduce (result, q) =>
      return result unless @_validateEtds q.get 'etds'
      result.push q.get 'id'
    , Immutable.List()

  _getInvalidQuestionsIds: ->
    @props.questions.reduce (result, q) =>
      return result if @_validateEtds q.get 'etds'
      result.push q.get 'id'
    , Immutable.List()

  _disableQuestion: (question) ->
    question.set 'isDisabled', not @_validateEtds question.get 'etds'

  getSelectedQuestionsIds: ->
    @_questionsList.getSelectedQuestionsIds()

  resetSelectedQuestions: ->
    @_questionsList.resetSelectedQuestions()

  render: ->
    <QuestionsList
      ref={(element) => @_questionsList = element}
      projectId={@props.projectId}
      questions={@props.questions}
      disabledQuestions={@_getInvalidQuestionsIds()}
      questionGroups={@props.questionGroups}
    />

module.exports = ValidatedQuestionsList
