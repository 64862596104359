ConnectStore = require 'components/enhancers/connect_store'
EtdActions = require 'actions/etd_actions'
EtdStore = require 'stores/etd_store'
EtdEditor = require 'components/etd/etd_editor'
QualityIndicatorsActions = require 'actions/quality_indicators_actions'
QualityIndicatorsStore = require 'stores/quality_indicators_store'
SelectCustom = require 'components/common/select_custom'
QualityIndicatorsTable = require 'components/quality_indicators/quality_indicators_table'
{ useMemo, useCallback, useState } = React
{ useI18n } = require 'lib/react_utils'
ConfirmationModal = require 'components/common/confirmation_modal'

storeConnector =
  EtdStore: (Store) ->
    attachments: Store.getAttachments()
  QualityIndicatorsStore: (Store) ->
    qualityIndicators: Store.getQualityIndicators()

QIMonitoringAndEvaluation = ({
  attachments
  editable
  qualityIndicators
  readOnly
  renderMode
  section
  sectionId
}) ->
  i18n = useI18n('quality_indicators:table')
  [isConfirmationOpen, setConfirmationOpen] = useState(false)

  openConfirmationDialog = useCallback ->
    setConfirmationOpen true
  , []

  closeConfirmationDialog = useCallback ->
    setConfirmationOpen false
  , []

  sendToEtd = (content) -> ->
    QualityIndicatorsActions.sendToMonitoringAndEvaluation(content)
    closeConfirmationDialog()

  editable = if (renderMode is 'printout' or readOnly) then false else editable

  qualityIndicatorsToUse = useMemo ->
    qualityIndicators.filter((qi) -> qi.get('includeForMonitoring') is 'yes')
  , [qualityIndicators]

  <div className="quality-indicators-monitoring-and-evaluation">
    <div className="text-right">
      {renderMode is 'regular' and  <button onClick={openConfirmationDialog}>
        {i18n 'send_to_etd'}
      </button>}
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        question={i18n '/messages.confirm_sending_to_etd'}
        confirmLabel={i18n '/actions.send'}
        onConfirm={sendToEtd(section.get('content'))}
        onCancel={closeConfirmationDialog}
      />
    </div>
    <IndicatorsTableChecker qualityIndicators={qualityIndicators} />
    <EtdEditor
      attachments={attachments}
      cellId={"#{sectionId}#conclusions"}
      content={section.get('content')}
      editable={editable}
      forceTableExpand={true}
      additionalOptions={
        activeQuestion: 'qualityIndicators'
        qualityIndicators: qualityIndicatorsToUse
      }
      renderMode={renderMode}
      withApplyCancelButtons={true}
      onSaveContent={EtdActions.updateEditorContent}
      onSaveAttachment={EtdActions.saveAttachment}
    />
  </div>

QIMonitoringAndEvaluation.propTypes =
  attachments: PropTypes.instanceOf(Immutable.Map).isRequired
  editable: PropTypes.bool
  readOnly: PropTypes.bool
  qualityIndicators: PropTypes.instanceOf(Immutable.List).isRequired
  renderMode: PropTypes.oneOf(['regular', 'printout'])
  sectionId: PropTypes.string.isRequired
  section: PropTypes.instanceOf(Immutable.Map).isRequired

QIMonitoringAndEvaluation.defaultProps =
  renderMode: 'regular'
  editable: true
  readOnly: false

OptionLabel = ({ text }) ->
  <div className="qi-select-option">{text}</div>

OptionLabel.propTypes =
  text: PropTypes.string.isRequired

IndicatorsTableChecker = ({ qualityIndicators }) ->
  i18n = useI18n('quality_indicators:table')

  options = useMemo -> _.map ['no', 'yes'], (value) ->
    {
      text: i18n "../#{value}"
      value
    }
  , [i18n]

  onChange = useCallback (qiId) ->
    (value) ->
      QualityIndicatorsActions.setIncludeForMonitoring(qiId, value)
  , [QualityIndicatorsActions]

  <table className="w-full quality-indicators-table mb-10">
    <thead>
      <tr>
        <th className="empty" />
        {qualityIndicators.map (qualityIndicator) ->
          <th key={qualityIndicator.get('id')}>{qualityIndicator.get('name')}</th>
        }
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="label-cell">
          <div>
            {i18n 'use_for_monitoring_and_evaluation'}
          </div>
        </td>
        {qualityIndicators.map (qualityIndicator) ->
          <td className="value-cell" key={qualityIndicator.get('id')}>
            <div>
              <SelectCustom
                options={options}
                optionLabelComponent={OptionLabel}
                onChange={onChange(qualityIndicator.get('id'))}
                selected={qualityIndicator.get('includeForMonitoring', '')}
              />
            </div>
          </td>
        }
      </tr>
    </tbody>
  </table>

IndicatorsTableChecker.propTypes =
  qualityIndicators: PropTypes.instanceOf(Immutable.List).isRequired

module.exports = ConnectStore QIMonitoringAndEvaluation,
  [EtdStore, QualityIndicatorsStore], storeConnector
