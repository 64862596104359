var AuthenticationView, Controller, DbHelper, Exceptions, Projects, SessionController, W, domainsMapping, mediator, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Controller = require('controllers/base/controller');

Projects = require('models/projects');

AuthenticationView = require('views/authentication_view');

Exceptions = require('lib/exceptions');

W = require('when');

mediator = require('mediator');

utils = require('base/lib/utils');

domainsMapping = require('lib/domainsMapping');

DbHelper = require('base/lib/db_helper');

module.exports = SessionController = (function(_super) {
  __extends(SessionController, _super);

  function SessionController() {
    return SessionController.__super__.constructor.apply(this, arguments);
  }

  SessionController.prototype.initialize = function(_arg) {
    this.service = _arg.authenticationService, this.udbService = _arg.udbService, this.container = _arg.container;
    SessionController.__super__.initialize.apply(this, arguments);
    this.subscribeEvent('replication.unauthorized', this._sessionExpired);
    this.subscribeEvent('logout', this.logout);
    this.subscribeEvent('!setLanguage', this._setLanguage);
    window.onstorage = function(e) {
      if (e.key === 'active_user' && !e.newValue) {
        return mediator.publish('!reloadApplication');
      }
    };
    return this._checkLogin();
  };

  SessionController.prototype._sessionExpired = function() {
    this.view = new AuthenticationView({
      service: this.service,
      modal: true
    });
    return this.subscribeEvent('login', (function(_this) {
      return function(username, loginStatus) {
        return _this._handleLogin()(username, loginStatus).then(function() {
          return _this._addUserInfo(loginStatus);
        });
      };
    })(this));
  };

  SessionController.prototype._newSession = function(extraParams) {
    var deferred;
    if (extraParams == null) {
      extraParams = {};
    }
    deferred = W.defer();
    this.container.empty();
    this.view = new AuthenticationView(_(extraParams).extend({
      service: this.service,
      modal: false
    }));
    this.subscribeEvent('login', this._handleLogin(deferred));
    return deferred.promise;
  };

  SessionController.prototype._setLanguage = function(newLng) {
    if (i18next.language === newLng) {
      return;
    }
    return window.gdt.storage.set('gdtLanguage', newLng).then(function() {
      if (mediator.user) {
        return mediator.user.save({
          language: newLng
        });
      } else {
        return W.resolve();
      }
    }).then((function(_this) {
      return function() {
        return i18next.changeLanguage(newLng, _this._reloadApplication);
      };
    })(this));
  };

  SessionController.prototype._updateUserLanguageSettings = function() {
    return window.gdt.storage.get('gdtLanguage').then(function(lng) {
      if (mediator.user.get('language') !== lng) {
        return mediator.user.save({
          language: lng
        });
      }
    });
  };

  SessionController.prototype._parseRegisterHash = function(paramsHash) {
    var key, param, params, value, whitelistedKeys, _i, _len, _ref, _ref1;
    params = {};
    whitelistedKeys = ['licence', 'term', 'currency'];
    _ref = paramsHash.split('&');
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      param = _ref[_i];
      _ref1 = param.split('=', 2), key = _ref1[0], value = _ref1[1];
      if (__indexOf.call(whitelistedKeys, key) >= 0) {
        params[key] = value;
      }
    }
    return {
      intent: 'register',
      preselection: params
    };
  };

  SessionController.prototype._isMigratedToHttps = function(login, udbMigratedToHttps) {
    var searchParams, _ref;
    searchParams = ((_ref = window.location.search) != null ? _ref : '').replace('?', '').split('&');
    if (__indexOf.call(searchParams, 'migratedToHttps=true') >= 0) {
      return window.gdt.storage.set("" + login + "-migratedToHttps", true).then(function() {
        return true;
      });
    } else if (__indexOf.call(searchParams, 'forceHttpsMigration=true') >= 0) {
      return W.resolve(false);
    } else {
      return W.resolve(true);
    }
  };

  SessionController.prototype._checkLogin = function() {
    var locationHash, loggingIn, registerPrefix;
    locationHash = window.location.hash.substring(1);
    registerPrefix = 'register/';
    loggingIn = locationHash.indexOf(registerPrefix) === 0 ? (window.location.hash = '', mediator.services.authentication.logout().then((function(_this) {
      return function() {
        var paramsHash;
        paramsHash = locationHash.substring(registerPrefix.length);
        return _this._newSession(_this._parseRegisterHash(paramsHash)).then(function(_arg) {
          var login, status;
          login = _arg[0], status = _arg[1];
          return {
            login: login,
            status: status
          };
        });
      };
    })(this))) : this.service.getLoginStatus().then((function(_this) {
      return function(info) {
        if (info.status === 'no') {
          return _this._newSession().then(function(_arg) {
            var login, status;
            login = _arg[0], status = _arg[1];
            return {
              login: login,
              status: status
            };
          });
        } else {
          return info;
        }
      };
    })(this));
    return loggingIn.then((function(_this) {
      return function(_arg) {
        var login, status;
        login = _arg.login, status = _arg.status;
        if (typeof Raven !== "undefined" && Raven !== null) {
          Raven.setUser({
            id: login
          });
        }
        window.dataLayer.push({
          'userId': login
        });
        Backbone.sync = mediator.services.persistenceAdapter.sync({
          author: login,
          errorHandler: function() {
            return mediator.dialogs.error({
              message: $.t('messages.sync_error')
            });
          }
        });
        return _this.udbService.initialize(login).then(function() {
          var loadingUserData, loadingUserOrganizations;
          loadingUserData = _this.udbService.loadUserData(login);
          loadingUserOrganizations = _this.udbService.loadUserOrganizations(login);
          return W.all([loadingUserData, loadingUserOrganizations, status]);
        });
      };
    })(this)).otherwise((function(_this) {
      return function(error) {
        utils.reportRavenError(error);
        return _this.service.logout().then(function() {
          throw new Exceptions.authentication.cannot_load_local_data;
        });
      };
    })(this)).then((function(_this) {
      return function(_arg) {
        var login, loginStatus, udbMigratedToHttps, userData, userOrganizations, _ref;
        userData = _arg[0], userOrganizations = _arg[1], loginStatus = _arg[2];
        mediator.user = userData;
        mediator.userOrganizations = userOrganizations;
        _this._updateUserLanguageSettings();
        if (typeof Raven !== "undefined" && Raven !== null) {
          Raven.setUser({
            id: mediator.user.id,
            email: mediator.user.get('email')
          });
        }
        login = mediator.user.get('name');
        udbMigratedToHttps = (_ref = mediator.user.get('migratedToHttps')) != null ? _ref : false;
        return _this._isMigratedToHttps(login, udbMigratedToHttps).then(function(isMigrated) {
          var host, oldDomain, queryString;
          host = window.location.host;
          oldDomain = domainsMapping[host];
          if (isMigrated || !oldDomain) {
            _this._addUserInfo(loginStatus);
            mediator.projects = new Projects();
            mediator.user.on('change:projects', function() {
              return mediator.projects.fetch();
            });
            return mediator.projects.fetch();
          } else {
            queryString = "?fromUrl=" + host + "&login=" + login;
            if (window.gdt.debugMode) {
              return window.location.replace("" + oldDomain + queryString);
            } else {
              return window.location.replace("" + oldDomain + "/migration-app/" + queryString);
            }
          }
        });
      };
    })(this)).then((function(_this) {
      return function() {
        return mediator.publish('sessionStarted');
      };
    })(this), function(error) {
      return mediator.dialogs.fatalError(error);
    });
  };

  SessionController.prototype._reloadApplication = function() {
    return window.location.reload();
  };

  SessionController.prototype._handleLogin = function(deferred) {
    return (function(_this) {
      return function(username, loginStatus) {
        mediator.unsubscribe('login', null, _this);
        _this.view.dispose();
        return (deferred != null ? deferred : W.defer()).resolve([username, loginStatus]);
      };
    })(this);
  };

  SessionController.prototype.logout = function() {
    return mediator.services.authentication.logout().then(function() {
      return mediator.publish('!reloadApplication', true);
    });
  };

  SessionController.prototype._addUserInfo = function(loginStatus) {
    var sessionLogEntry;
    sessionLogEntry = {
      timestamp: mediator.services.clock.getTimestamp(),
      status: loginStatus,
      platformInfo: platform.description,
      protocol: window.location.protocol
    };
    return $.ajax({
      url: "" + (DbHelper.getBackendUrl()) + "/user-sessions",
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify(sessionLogEntry),
      xhrFields: {
        withCredentials: true
      },
      error: function(xhr) {
        var err;
        if (xhr.readyState === 4) {
          return;
        }
        err = new Error("Could not save user's session log: " + xhr.status + ": " + xhr.responseText);
        return utils.reportRavenError(err, {
          extra: {
            status: xhr.status,
            response: xhr.responseText
          }
        });
      }
    });
  };

  return SessionController;

})(Controller);
