var CoiView, MembersView, ModuleController, TeamController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

MembersView = require('views/team/members_view');

CoiView = require('views/team/coi_view');

mediator = require('mediator');

module.exports = TeamController = (function(_super) {
  __extends(TeamController, _super);

  function TeamController() {
    return TeamController.__super__.constructor.apply(this, arguments);
  }

  TeamController.prototype.defaultSubmodule = 'members';

  TeamController.prototype.viewsForSubmodules = {
    'members': MembersView,
    'coi': CoiView
  };

  TeamController.prototype._viewParams = function(params) {
    if (params.submodule === 'members') {
      return {
        collection: mediator.colls.teamMembers
      };
    } else {
      return TeamController.__super__._viewParams.apply(this, arguments);
    }
  };

  return TeamController;

})(ModuleController);
