module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل التدخل مقبول لأصحاب المصلحة الرئيسيين؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا",
                    "value": "no"
                  }
                ],
                "question": "هل هناك أصحاب مصلحة رئيسيون من الممكن أن لا يقبلوا توزيع الفوائد والأضرار والتكاليف؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا",
                    "value": "no"
                  }
                ],
                "question": "هل هناك أصحاب مصلحة رئيسيون من الممكن أن لا يقبلوا التكاليف أو الآثار غير المرغوب فيها على المدى القصير للتأثيرات المرغوبة (الفوائد) في المستقبل؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا",
                    "value": "no"
                  }
                ],
                "question": "هل من المحتمل أن لا يتفق أصحاب المصلحة الرئيسيون مع القيم المرتبطة بالتأثيرات المرغوبة أو غير المرغوب فيها (بسبب كيفية تأثرهم شخصيًا أو بسبب إدراكهم للأهمية النسبية للآثار بالنسبة للآخرين)؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا",
                    "value": "no"
                  }
                ],
                "question": "هل سيؤثر (خيار) التدخل سلبًا على استقلالية الناس؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا",
                    "value": "no"
                  }
                ],
                "question": "هل من المحتمل أن يرفض أصحاب المصلحة الرئيسيون (خيار) التدخل أخلاقياً ، لأسباب أخرى غير آثاره على استقلالية الناس (أي فيما يتعلق بالمبادئ الأخلاقية مثل عدم الإساءة أو الإحسان أو العدالة)؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هل من المحتمل ألا يقبل أصحاب المصلحة الرئيسيون توزيع الفوائد والأضرار والتكاليف ؛ أو التكاليف أو الآثار غير المرغوب فيها على المدى القصير للتأثيرات المرغوبة (المنافع) في المستقبل؟ هل من المحتمل أن يختلفوا مع القيم المرتبطة بالتأثيرات المرغوبة أو غير المرغوب فيها ، أو أن لا يقبلوا التدخل التشخيصي بسبب مخاوف أخلاقية؟",
          "options": [
            {
              "text": "لا",
              "value": "no"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "نعم",
              "value": "yes"
            }
          ],
          "ratingDescription": "ما هو التدخل الأكثر قبولًا لأصحاب المصلحة الرئيسيين؟",
          "ratingOptions": [
            "best_acceptability",
            "intermediate_acceptability",
            "worst_acceptability"
          ],
          "selectedOption": ""
        },
        "additionalSection": {
          "additionalOptions": [
            {
              "text": "حكم إضافي 1",
              "value": "additionalOption_1"
            },
            {
              "text": "حكم إضافي 2",
              "value": "additionalOption_2"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "وصف المعيار المخصص",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "توفير أدوات تلميح مخصصة",
          "options": [
            {
              "text": "الحكم 1",
              "value": "option_1"
            },
            {
              "text": "الحكم 2",
              "value": "option_2"
            },
            {
              "text": "الحكم 3",
              "value": "option_3"
            },
            {
              "text": "الحكم 4",
              "value": "option_4"
            },
            {
              "text": "الحكم 5",
              "value": "option_5"
            }
          ],
          "selectedOption": ""
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل التوازن بين الآثار المرغوبة وغير المرغوب فيها يفضل التدخل أو المقارنة؟",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "الأحكام التفصيلية لهذا المعيار تشمل الأحكام المتعلقة بكل من المعايير الأربعة السابقة:"
              },
              {
                "additions": [
                  "إلى أي درجة عدد الأشخاص الذين يقدرون النتائج المستقبلية مقارنة بالنتائج التي تحدث الآن (معدلات الخصم الخاصة بهم) هو قليل؟",
                  "مواقف الناس تجاه الآثار غير المرغوب فيها (مدى تجنبهم للمخاطرة).",
                  "مواقف الناس تجاه التأثيرات المرغوبة (مدى السعي وراء المخاطرة)."
                ],
                "description": "بالإضافة إلى ذلك ، قد ترغب اللجان في النظر (وفي حالة وجود صلة أن تقوم بالتوثيق) في مدى تأثير الاعتبارات التالية على التوازن بين التأثيرات المرغوبة وغير المرغوب فيها:"
              }
            ],
            "panelDiscussion": ""
          },
          "info": "ما هو التوازن بين الآثار المرغوبة وغير المرغوب فيها ، مع الأخذ في الاعتبار مدى تقدير الأفراد للنتائج الرئيسية ، ومدى أهمية الآثار المرغوبة وغير المرغوب فيها ، واليقين من تلك التقديرات ، ومعدلات الخصم ، تجنب المخاطر ، السعي وراء المخاطر؟",
          "options": [
            {
              "text": " يفضل المقارنة",
              "value": "favors_comparison"
            },
            {
              "text": "ربما يفضل المقارنة",
              "value": "probably_favors_comparison"
            },
            {
              "text": "لا يحبذ أياً من التدخل أو المقارنة",
              "value": "doesnt_favor_any"
            },
            {
              "text": "ربما يفضل المقارنة",
              "value": "probably_favors_intervention"
            },
            {
              "text": "يفضل التدخل",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?",
          "ratingOptions": [
            "best_balance",
            "intermediate",
            "worst_balance"
          ],
          "selectedOption": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل التوازن بين الآثار الصحية المرغوبة وغير المرغوب فيها لصالح التدخل أو المقارنة؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ التدخل أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "يفضل التدخل",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل <strong> الفعالية </ strong> قصيرة وطويلة المدى (في ظل ظروف خاضعة للرقابة ، وغالبًا ما تكون مثالية) أو <strong> الفاعلية </ strong> (في بيئة واقعية) للتدخل على <strong> صحة الأفراد </ strong> ، بما في ذلك النتائج التي أبلغ عنها المريض ، تفضل التدخل أم المقارنة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من الوحدة التجربية أو وحدة المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل تدعم <strong> الفعالية </ strong> القصيرة والطويلة المدى أو <strong> التأثير </ strong> للتدخل على <strong> صحة السكان </ strong> ، بما في ذلك النتائج التي يبلغ عنها المستفيدون، التدخل أم المقارنة؟ (يجب أن يشمل ذلك اعتبارات تتعلق بما إذا كانت النتائج على مستوى السكان تمثل نتائج مجمعة على المستوى الفردي أو تظهر من خلال ديناميكيات النظام.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من التدخل  أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "ربما يفضل التدخل ",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل مدى <strong> تقييم المرضى / المستفيدين </ strong> للنتائج الصحية المختلفة يفضل التدخل أو المقارنة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من التدخل أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل التدخل ",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "ربما يفضل التدخل ",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل <strong> احتمال وشدة الآثار الضارة </ strong> المرتبطة بالتدخل (بما في ذلك خطر إساءة استخدام التدخل) يُفضل التدخل أو المقارنة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من التدخل أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل <strong> الآثار الإيجابية أو السلبية الأوسع المتعلقة بالصحة </ strong> (مثل الحد من وصمة العار ، والتأثير الإيجابي على الأمراض الأخرى ، والآثار غير المباشرة التي تتجاوز المرضى / المستفيدين) المرتبطة بالتدخل تفضل التدخل أو المقارنة؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "كلما زادت الفائدة الصحية الصافية المرتبطة بالتدخل، زاد احتمال التوصية العامة لصالح هذا التدخل",
          "options": [
            {
              "text": " يفضل المقارنة",
              "value": "favors_the_comparison"
            },
            {
              "text": "ربما يفضل المقارنة",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "لا يحبذ أياً من التدخل أو المقارنة",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "ربما يفضل التدخل",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "يفضل التدخل",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "لا توجد دراسات متضمنة",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما هو اليقين العام للأدلة على آثار الاختبار؟",
          "details": {
            "panelDiscussion": ""
          },
          "info": "ما مدى جودة إشارة البحث إلى التأثيرات المحتملة على صعيد جميع النتائج الحرجة؛ أي احتمال أن تكون التأثيرات مختلفة بدرجة كافية عما وجد البحث أنه قد يؤثر على قرار بشأن التدخل التشخيصي؟",
          "options": [
            {
              "text": "قليل جداً",
              "value": "very_low"
            },
            {
              "text": "قليل",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "عالي",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "لا توجد دراسات متضمنة",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما هو اليقين العام للأدلة على الاثار؟",
          "details": {
            "panelDiscussion": ""
          },
          "info": "ما مدى جودة إشارة البحث إلى التأثيرات المحتملة على صعيد جميع النتائج الحرجة؛ أي احتمال أن تكون التأثيرات مختلفة بدرجة كافية عما وجد البحث أنه قد يؤثر على قرار بشأن التدخل؟",
          "options": [
            {
              "text": "قليل جداً",
              "value": "very_low"
            },
            {
              "text": "قليل",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "عالي",
              "value": "high"
            }
          ],
          "ratingDescription": "What is the overall certainty of the evidence of effects for each intervention?",
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "لا توجد دراسات متضمنة",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما هو اليقين العام للدليل على تأثيرات الإدارة التي تسترشد بنتائج الاختبار؟",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "قليل جداً",
              "value": "very_low"
            },
            {
              "text": "قليل",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "عالي",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "لا توجد دراسات متضمنة",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما هو اليقين من دليل متطلبات الموارد (التكاليف)؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل تم تحديد جميع العناصر المهمة لاستخدام الموارد التي قد تختلف بين الخيارات المطروحة؟",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "عدم تيقن مهم",
                    "value": "important"
                  },
                  {
                    "text": "من المحتمل عدم يقين مهم",
                    "value": "probably_important"
                  },
                  {
                    "text": "من المحتمل لا يوجد عدم يقين مهم",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "لا وجود لعدم يقين هامّ",
                    "value": "no_important"
                  }
                ],
                "question": "ما مدى التأكد من تكلفة عناصر استخدام الموارد التي تختلف بين الخيارات قيد النظر؟",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا أعلم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "تباين مهم",
                    "value": "important"
                  },
                  {
                    "text": "إحتمالية وجود تباين مهم",
                    "value": "probably_important"
                  },
                  {
                    "text": "إحتمالية عدم وجود تباين مهم",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "لا وجود تباين مهم",
                    "value": "no_important"
                  }
                ],
                "question": "هل هناك تباين مهم في تكلفة عناصر استخدام الموارد التي تختلف بين الخيارات قيد النظر؟",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "ما مدى التأكد من الدليل على وجود اختلاف لكل نوع من أنواع استخدام الموارد (مثل الأدوية والاستشفاء) وتكلفة الموارد؟",
          "options": [
            {
              "text": "قليل جداً",
              "value": "very_low"
            },
            {
              "text": "قليل",
              "value": "low"
            },
            {
              "text": "معتدل",
              "value": "moderate"
            },
            {
              "text": "عالي",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "لا توجد دراسات متضمنة",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما هو اليقين العام للدليل على دقة الاختبار؟",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "قليل جداً",
              "value": "very_low"
            },
            {
              "text": "قليل",
              "value": "low"
            },
            {
              "text": "معتدل",
              "value": "moderate"
            },
            {
              "text": "عالي",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "لا توجد دراسات متضمنة",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما هو اليقين العام للدليل على أي فوائد مباشرة حرجة أو مهمة أو آثار ضارة أو عبء  للاختبار؟",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "قليل جداً",
              "value": "very_low"
            },
            {
              "text": "قليل",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "عالي",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "لا توجد دراسات متضمنة",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما مدى التأكد من الصلة بين نتائج الاختبار وقرارات الإدارة؟",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "قليل جداً",
              "value": "very_low"
            },
            {
              "text": "قليل",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "عالي",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا توجد دراسات متضمنة",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل فعالية التكلفة للتدخل في صالح التدخل أم المقارنة؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "لا توجد دراسات متضمنة",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل نسبة فعالية التكلفة حساسة لتحليلات الاستجابة الأحادية الاتجاه؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا توجد دراسات متضمنة",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل نسبة فعالية التكلفة حساسة لتحليل الاستجابة المتعدد المتغيرات؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا توجد دراسات متضمنة",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "قيود خطيرة للغاية",
                    "value": "very_serious"
                  },
                  {
                    "text": "قيود خطيرة",
                    "value": "serious"
                  },
                  {
                    "text": "لا قيود خطيرة",
                    "value": "no_serious"
                  }
                ],
                "question": "هل التقييم الاقتصادي الذي يستند إليه تقدير فعالية التكلفة قابل للتكرار؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا توجد دراسات متضمنة",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "قيود خطيرة للغاية",
                    "value": "very_serious"
                  },
                  {
                    "text": "قيود خطيرة",
                    "value": "serious"
                  },
                  {
                    "text": "لا قيود خطيرة",
                    "value": "no_serious"
                  }
                ],
                "question": "هل التقييم الاقتصادي الذي يستند إليه تقدير فعالية التكلفة قابل للتطبيق على الإعداد (الإعدادات) موضع الاهتمام؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هل التدخل فعال من حيث التكلفة ، مع الأخذ في الاعتبار عدم اليقين أو التباين في التكاليف ، وعدم اليقين أو التباين في صافي المنفعة ، وتحليلات الإستجابة، وموثوقية وإمكانية تطبيق التقييم الاقتصادي؟",
          "options": [
            {
              "text": " يفضل المقارنة",
              "value": "favors_comparison"
            },
            {
              "text": "ربما يفضل المقارنة",
              "value": "probably_favors_comparison"
            },
            {
              "text": "لا يحبذ أياً من التدخل أو المقارنة",
              "value": "doesnt_favor_any"
            },
            {
              "text": "ربما يفضل التدخل",
              "value": "probably_favors_intervention"
            },
            {
              "text": "يفضل التدخل",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "ما التدخل الذي تفضله فعالية التكلفة؟",
          "ratingOptions": [
            "best_cost_effectiveness",
            "intermediate_cost_effectiveness",
            "worst_cost_effectiveness"
          ],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما مدى أهمية التأثيرات المرغوبة المتوقعة؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " بديهي",
                    "value": "trivial"
                  },
                  {
                    "text": "صغير",
                    "value": "small"
                  },
                  {
                    "text": "معتدل",
                    "value": "moderate"
                  },
                  {
                    "text": "كبير",
                    "value": "large"
                  }
                ],
                "question": "ما مدى أهمية التأثير المتوقع (الفرق) لكل نتيجة رئيسية يكون لها تأثير مرغوب فيه؟",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "ما حجم التأثيرات المرغوبة للتدخل مع الأخذ في الاعتبار أهمية النتائج (مقدار تقييمها)، وحجم تأثيرها (احتمال الحصول على الفائدة أو مقدار التحسن الذي من المرجح أن يختبره الأفراد)؟",
          "options": [
            {
              "text": " بديهي",
              "value": "trivial"
            },
            {
              "text": "صغير",
              "value": "small"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "كبير",
              "value": "large"
            }
          ],
          "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
          "ratingOptions": [
            "most_effective",
            "intermediate_effectiveness",
            "least_effective"
          ],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ماذا سيكون التأثير على العدالة الصحية؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا",
                    "value": "no"
                  }
                ],
                "question": "هل هناك مجموعات أو إعدادات قد تكون محرومة فيما يتعلق بالمشكلة أو الخيارات التي يتم النظر فيها؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا",
                    "value": "no"
                  }
                ],
                "question": "هل توجد أسباب معقولة لتوقع الاختلافات في الفعالية النسبية للخيار بالنسبة للمجموعات أو الأماكن المحرومة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا",
                    "value": "no"
                  }
                ],
                "question": "هل هناك شروط أساسية مختلفة عبر المجموعات أو الإعدادات التي تؤثر على الفعالية المطلقة للخيار أو على أهمية المشكلة للمجموعات أو الأماكن المحرومة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا",
                    "value": "no"
                  }
                ],
                "question": "هل هناك اعتبارات مهمة يجب مراعاتها عند تنفيذ (خيار) التدخل من أجل ضمان الحد من عدم المساواة ، إن أمكن ، وعدم زيادتها؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هل توجد أسباب معقولة لتوقع الاختلافات في الفعالية النسبية للتدخل للمجموعات الفرعية المحرومة أو شروط أساسية مختلفة عبر المجموعات الفرعية المحرومة التي تؤثر على الفعالية المطلقة للتدخل أو أهمية المشكلة؟",
          "options": [
            {
              "text": "قلل",
              "value": "reduced"
            },
            {
              "text": "ربما قلل",
              "value": "probably_reduced"
            },
            {
              "text": "ربما لا تأثير",
              "value": "probably_no_impact"
            },
            {
              "text": "ربما زاد",
              "value": "probably_increased"
            },
            {
              "text": "زيادة",
              "value": "increased"
            }
          ],
          "ratingDescription": "في حال التوصية, ما هو التدخل الذي سيقلل من عدم المساواة الصحية أكثر من غيره؟",
          "ratingOptions": [
            "most_reduction",
            "intermediate_reduction",
            "less_reduction"
          ],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل تنفيذ التدخل ممكن؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل (خيار) التدخل مستدام؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل هناك حواجز مهمة من المحتمل أن تحد من إمكانية تنفيذ (خيار) التدخل أو أن تتطلب الأخذ بعين الاعتبار عند تنفيذه؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هل من المجدي الاستمرار في استخدام التدخل التشخيصي ومعالجة العوائق المحتملة لاستخدامه؟",
          "options": [
            {
              "text": "لا",
              "value": "no"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "نعم",
              "value": "yes"
            }
          ],
          "ratingDescription": "ما هو التدخل الأكثر إمكانية للتنفيذ؟",
          "ratingOptions": [
            "most_feasible",
            "intermediate_feasibility",
            "least_feasible"
          ],
          "selectedOption": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل تنفيذ التدخل ممكن؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "يختلف ",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم ",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا ",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا ",
                    "value": "no"
                  }
                ],
                "question": "هل هناك <strong> حواجز قانونية </ strong> قد تحد من قابلية تنفيذ التدخل؟.",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نعم",
                    "value": "yes"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "لا",
                    "value": "no"
                  }
                ],
                "question": "هل هناك <strong> جوانب حوكمة </ strong> (مثل الاعتبارات الإستراتيجية والقرارات السابقة) التي قد تحد من قابلية تنفيذ التدخل؟ (يجب أن يشمل ذلك الاعتبارات المتعلقة بوجود أو عدم وجود مؤسسات رسمية أو معلوماتية يمكن أن توفر قيادة فعالة وإشرافًا ومساءلة في تنفيذ التدخل الذي قد يؤثر على جدوى التنفيذ)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "آثار مفيدة كبيرة",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "آثار مفيدة معتدلة",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "الآثار المفيدة والضارة التي لا تذكر",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "آثار سلبية معتدلة",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "الآثار السلبية الكبيرة",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "ما هي <strong> تداعيات </ strong> التدخل <strong> في التفاعل والتوافق مع النظام الصحي الحالي؟ </ strong> (وهذا يشمل الاعتبارات المتعلقة بتفاعل التدخل أو تأثيره على النظام الصحي الحالي ومكوناته؟ )",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "آثار مفيدة كبيرة",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "آثار مفيدة معتدلة",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "الآثار المفيدة والضارة التي لا تذكر",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "آثار سلبية معتدلة",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "الآثار السلبية الكبيرة",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "ما هي <strong> آثار </ strong> التدخل <strong> على القوى العاملة الصحية والموارد البشرية الشاملة </ strong> (في قطاع الصحة أو القطاعات الأخرى؟ (يجب أن يشمل ذلك الاعتبارات المتعلقة بالحاجة إلى ، والاستفادة من والتأثير على القوى العاملة الصحية والموارد البشرية الأخرى وتوزيعها",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "آثار مفيدة كبيرة",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "آثار مفيدة معتدلة",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "الآثار المفيدة والضارة التي لا تذكر",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "آثار سلبية معتدلة",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "الآثار السلبية الكبيرة",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "ما هي <strong> تداعيات </ strong> التدخل <strong> على البنية التحتية للنظام الصحي والبنية التحتية الأوسع </ strong>؟ (يجب أن يشمل ذلك الاعتبارات المتعلقة بالحاجة إلى الموارد غير البشرية والبنية التحتية واستخدامها والتأثير عليها وكذلك توزيعها)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "كلما زادت قابلية التنفيذ الخيار من منظور جميع أصحاب المصلحة أو معظمهم ، زاد احتمال وجود توصية عامة لصالح التدخل. وكلما كانت الآثار مفيدة للنظام الصحي ككل ، زادت احتمالية التوصية العامة لصالح التدخل.",
          "options": [
            {
              "text": "لا",
              "value": "no"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_not"
            },
            {
              "text": "غير مؤكد",
              "value": "uncertain"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "نعم",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "feasible": {
          "description": "to identify and measure, and not susceptible to manipulation",
          "options": [],
          "ratingOptions": [
            "very_feasible",
            "feasible",
            "least_feasible"
          ],
          "selectedOptions": ""
        },
        "financialAndEconomicConsiderations": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل الاعتبارات المالية والاقتصادية تفضل التدخل أم المقارنة؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "تفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ التدخل أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": " يحبذ التدخل ",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل ال<strong> تكلفة وتأثيرات الميزانية </strong> لتنفيذ التدخل والحفاظ عليه تفضل التدخل أو المقارنة؟ (يجب أن يشمل ذلك اعتبارات حول كيفية اختلاف تأثيرات التكلفة والميزانية على المدى القصير مقابل المدى الطويل.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من الوحدة التجربية أو وحدة المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "يفضل التدخل",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل <strong> التأثير العام للوحدة التجريبية على الاقتصاد </ strong> يفضل الوحدة التجريبية أم وحدة المقارنة؟ (يجب أن يشمل ذلك اعتبارات حول كيفية توزيع الأنواع المختلفة من التأثير الاقتصادي عبر مختلف القطاعات أو المستويات التنظيمية سواء كانت الوحدة التجريبية تساهم أو تحد من تحقيق الأهداف الواسعة للتنمية وأهداف التقليل من الفقر، وكيف تؤثر على القوة العاملة المتاحة).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من الوحدة التجربية أو وحدة المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل الوحدة التجريبية ",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "ربما يفضل الوحدة التجريبية ",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل <strong> نسبة التكاليف والفوائد </ strong> (على سبيل المثال استنادًا إلى تقديرات فعالية التكلفة أوعوائد التكلفة أو فائدة التكلفة) تفضل يفضل الوحدة التجريبية أم وحدة المقارنة ؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "كلما كانت الآثار المالية والاقتصادية للتدخل أكثر فائدة ، زادت احتمالية التوصية العامة لصالح هذا التدخل.",
          "options": [
            {
              "text": "ربما يفضل المقارنة",
              "value": "favors_the_comparison"
            },
            {
              "text": "ربما يفضل المقارنة",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "لا يحبذ أياً من الوحدة التجربية أو وحدة المقارنة",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "ربما يفضل الوحدة التجريبية ",
              "value": "probably_favor_the_intervention"
            },
            {
              "text": "ربما يفضل الوحدة التجريبية ",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ماذا سيكون تأثير التدخل على الإنصاف في الصحة والمساواة وعدم التمييز؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل من المحتمل أن يؤدي التدخل إلى زيادة عدم المساواة و / أو عدم الإنصاف في الحالة الصحية أو محدداتها؟ (يجب أن يشمل ذلك اعتبارات التغييرات المحتملة في عدم المساواة بمرور الوقت ، على سبيل المثال ، ما إذا كان من المرجح أن تتوازن الزيادات الأولية بمرور الوقت ، حيث يتم توسيع نطاق التدخل؟)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل من المرجح أن يتم توزيع فوائد وأضرار التدخل بطريقة منصفة؟ (يجب أن يشمل ذلك تركيزًا خاصًا على الآثار المترتبة على الفئات السكانية الضعيفة والمهمشة أو المحرومة اجتماعياً.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل التدخل ميسور التكلفة بين مجموعات السكان المتضررة ، وبالتالي يمكن الوصول إليه من الناحية المالية؟ (يجب أن يشمل ذلك التأثير على نفقات صحة الأسرة ، بما في ذلك مخاطر النفقات الصحية الكارثية والمخاطر المالية المتعلقة بالصحة).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل التدخل متاح بين مجموعات السكان المتأثرة؟ (يجب أن يشمل ذلك الاعتبارات المتعلقة بالوصول المادي وكذلك الوصول إلى المعلومات.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل يعالج التدخل حالة خطيرة بشكل خاص (على سبيل المثال تهدد الحياة ، نهاية الحياة ، تؤثر على الأفراد الذين يعانون من وضع صحي متدني موجود مسبقًا) أو حالة نادرة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل يمثل التدخل الخيار الوحيد المتاح؟ (يجب أن يشمل ذلك اعتبارات ما إذا كان التدخل متناسبًا مع الحاجة ، وما إذا كان سيخضع لمراجعة دورية.)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "كلما زاد احتمال أن يزيد التدخل من الإنصاف و / أو المساواة في الصحة و أن يقلل من التمييز ضد أي مجموعة معينة ، زاد احتمال التوصية العامة لصالح هذا التدخل.",
          "options": [
            {
              "text": "سلبي",
              "value": "negative"
            },
            {
              "text": "على الأرجح سلبي",
              "value": "probably_negative"
            },
            {
              "text": "ليست سلبية ولا إيجابية",
              "value": "neither_negative_nor_positive"
            },
            {
              "text": "على الأرجح إيجابية",
              "value": "probably_positive"
            },
            {
              "text": "إيجابي",
              "value": "positive"
            }
          ],
          "selectedOption": ""
        },
        "humanRights": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل التدخل يتوافق مع المعايير والمبادئ العالمية لحقوق الإنسان؟",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "يجب أن تكون جميع التوصيات متوافقة مع المعايير والمبادئ العالمية لحقوق الإنسان.",
          "options": [
            {
              "text": "لا",
              "value": "no"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "غير مؤكد",
              "value": "uncertain"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "نعم",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problem": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل المشكلة أولوية؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل عواقب المشكلة خطيرة (أي خطيرة أو مهمة من حيث الفوائد أو المدخرات المحتملة)؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل المشكلة ملحة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "لا",
                    "value": "no"
                  },
                  {
                    "text": "على الأرجح لا",
                    "value": "probably_no"
                  },
                  {
                    "text": "على الأرجح نعم",
                    "value": "probably_yes"
                  },
                  {
                    "text": "نعم",
                    "value": "yes"
                  }
                ],
                "question": "هل هي أولوية معترف بها (على سبيل المثال تستند إلى قرار سياسي أو قرار يتعلق بالسياسة العامة)؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "كلما كانت المشكلة أكثر خطورة أو إلحاحًا ، زاد احتمال أن يكون الخيار الذي يعالج المشكلة أولوية",
          "options": [
            {
              "text": "لا",
              "value": "no"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "نعم",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "qualityOfEvidence": {
          "additionalOptions": [
            {
              "text": "يتغير",
              "value": "varies"
            },
            {
              "text": "لا أعلم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما هي الجودة الإجمالية للأدلة؟",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "كلما زادت جودة الدليل على إختلاف المعايير في إطار WHO-INTEGRATE، زاد احتمال صدور توصية عامة.",
          "options": [
            {
              "text": "قليل جداً",
              "value": "very_low"
            },
            {
              "text": "قليل",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "عالي",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "relevantDirect": {
          "description": "to the selected problem and in the field of application",
          "options": [],
          "ratingOptions": [
            "very_relevant",
            "relevant",
            "least_relevant"
          ],
          "selectedOptions": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما كبر متطلبات الموارد (التكاليف)؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " بديهي",
                    "value": "trivial"
                  },
                  {
                    "text": "صغير",
                    "value": "small"
                  },
                  {
                    "text": "متوسط",
                    "value": "moderate"
                  },
                  {
                    "text": "كبير",
                    "value": "large"
                  }
                ],
                "question": "ما كبر الاختلاف في كل عنصر من عناصر الموارد التي تتطلب موارد أقل؟",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "كبير",
                    "value": "large"
                  },
                  {
                    "text": "متوسط",
                    "value": "moderate"
                  },
                  {
                    "text": "صغير",
                    "value": "small"
                  },
                  {
                    "text": " بديهي",
                    "value": "trivial"
                  }
                ],
                "question": "ما كبر الاختلاف في كل عنصر من عناصر استخدام الموارد التي تتطلب المزيد من الموارد؟",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "ما كبر تكلفة الاختلاف في استخدام الموارد بين التدخل والمقارنة؟",
          "options": [
            {
              "text": "تكاليف كبيرة",
              "value": "large_costs"
            },
            {
              "text": "تكاليف متوسطة",
              "value": "moderate_costs"
            },
            {
              "text": "تكاليف ومدخرات ضئيلة",
              "value": "negligible_costs_savings"
            },
            {
              "text": "مدخرات متوسطة",
              "value": "moderate_savings"
            },
            {
              "text": "مدخرات كبيرة",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
          "ratingOptions": [
            "less_costs",
            "intermediate_costs",
            "most_costs"
          ],
          "selectedOption": ""
        },
        "responsive": {
          "description": "sensitive to change that means they should be able to capture possible changes in the system",
          "options": [],
          "ratingOptions": [
            "very_responsive",
            "responsive",
            "least_responsive"
          ],
          "selectedOptions": ""
        },
        "scientificallySound": {
          "description": "based on evidence (GRADE criteria) and strongly correlated with the quality of care provided",
          "options": [],
          "ratingOptions": [
            "very_scientifically_sound",
            "scientifically_sound",
            "least_scientifically_sound"
          ],
          "selectedOptions": ""
        },
        "societalImplications": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل التوازن بين الآثار المجتمعية المرغوبة وغير المرغوب فيها لصالح التدخل أو المقارنة؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من التدخل أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "يفضل التدخل",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل التأثير الاجتماعي و الآثار الاجتماعية للتدخل (مثل زيادة أو تقليل وصمة العار ، أو النتائج التعليمية ، أو التماسك الاجتماعي ، أو تحقيق مختلف حقوق الإنسان أبعد من نطاق الصحة) تفضل التدخل أو المقارنة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من التدخل أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "يفضل التدخل",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل التأثير البيئي والآثار المترتبة على الاستدامة البيئية (مثل حماية الموارد الطبيعية أو التخفيف أو التكيف مع تغير المناخ) يفضل التدخل أو المقارنة؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "كلما زادت صافي المنفعة المجتمعية المرتبطة بالتدخل، زاد احتمال التوصية العامة لصالح هذا التدخل",
          "options": [
            {
              "text": " يفضل المقارنة",
              "value": "favors_the_comparison"
            },
            {
              "text": "ربما يفضل المقارنة",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "لا يحبذ أياً من التدخل أو المقارنة",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "ربما يفضل التدخل",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "يفضل التدخل",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "socioCulturalAcceptability": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل التدخل مقبول لأصحاب المصلحة الرئيسيين؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "كبير",
                    "value": "large"
                  },
                  {
                    "text": "متوسط",
                    "value": "moderate"
                  },
                  {
                    "text": "صغير",
                    "value": "small"
                  },
                  {
                    "text": " بديهي",
                    "value": "trivial"
                  }
                ],
                "question": "ما مدى أهمية تطفل التدخل من حيث التعدي على الحريات الفردية (بما في ذلك الخصوصية والكرامة)؟ (يتراوح التطفل من كونه بديهي- على سبيل المثال من خلال تمكين الاختيار (مثل بناء مسارات للدراجات) إلى كونه مرتفع - على سبيل المثال عن طريق تقييد أو إلغاء الاختيار (مثل حظر السجائر)).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "كبير",
                    "value": "large"
                  },
                  {
                    "text": "متوسط",
                    "value": "moderate"
                  },
                  {
                    "text": "صغير",
                    "value": "small"
                  },
                  {
                    "text": " بديهي",
                    "value": "trivial"
                  }
                ],
                "question": "ما مدى أهمية تأثير التدخل على استقلالية الأفراد و / أو مجموعات السكان و / أو المنظمات (فيما يتعلق بقدرتهم على اتخاذ قرار مؤهل ومستنير وطوعي)؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من التدخل أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "يفضل التدخل",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل القبول الاجتماعي والثقافي للتدخل بين المستفيدين المقصودين يفضل التدخل أو المقارنة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من التدخل أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "يفضل التدخل",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل القبول الاجتماعي والثقافي للتدخل بين أولئك الذين يعتزمون تنفيذ التدخل لصالح التدخل أو المقارنة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من التدخل أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "يفضل التدخل",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل القبول الاجتماعي والثقافي للتدخل بين مجموعات أصحاب المصلحة الأخرى ذات الصلة يفضل التدخل أو المقارنة؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": " يفضل المقارنة",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "ربما يفضل المقارنة",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "لا يحبذ أياً من التدخل أو المقارنة",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "ربما يفضل التدخل",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "يفضل التدخل",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "هل القبول الاجتماعي والثقافي للتدخل بين عامة الناس يفضل التدخل أو المقارنة؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "كلما زاد القبول الاجتماعي والثقافي للتدخل لجميع أصحاب المصلحة أو معظمهم ، زاد احتمال التوصية العامة لصالح هذا التدخل.",
          "options": [
            {
              "text": "لا",
              "value": "no"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "غير مؤكد",
              "value": "uncertain"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "نعم",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما مدى دقة الاختبار؟",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "غير دقيق للغاية",
              "value": "very_inaccurate"
            },
            {
              "text": "غير دقيق",
              "value": "inaccurate"
            },
            {
              "text": "دقيق",
              "value": "accurate"
            },
            {
              "text": "دقيق للغاية",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": "يختلف",
              "value": "varies"
            },
            {
              "text": "لا اعرف",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ما مدى أهمية التأثيرات غير المرغوبة المتوقعة؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "يختلف",
                    "value": "varies"
                  },
                  {
                    "text": "لا اعرف",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "كبير",
                    "value": "large"
                  },
                  {
                    "text": "متوسط",
                    "value": "moderate"
                  },
                  {
                    "text": "صغير",
                    "value": "small"
                  },
                  {
                    "text": " بديهي",
                    "value": "trivial"
                  }
                ],
                "question": "ما مدى أهمية التأثير (الفرق) المتوقع لكل نتيجة رئيسية يكون لها تأثير غير مرغوب فيه؟",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "ما حجم التأثيرات غير المرغوبة للتدخل مع الأخذ في الاعتبار أهمية النتائج (مقدار تقييمها)، وحجم تأثيرها (احتمال الحصول على الفائدة أو مقدار التحسن الذي من المرجح أن يختبره الأفراد)؟",
          "options": [
            {
              "text": "كبير",
              "value": "large"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "صغير",
              "value": "small"
            },
            {
              "text": " بديهي",
              "value": "trivial"
            }
          ],
          "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
          "ratingOptions": [
            "least_harmful",
            "intermediate",
            "more_harmful"
          ],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "هل هناك عدم يقين مهم أو تباين في مقدار تقدير الناس للنتائج الرئيسية؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "عدم تيقن مهم",
                    "value": "important"
                  },
                  {
                    "text": "من المحتمل عدم يقين مهم",
                    "value": "probably_important"
                  },
                  {
                    "text": "من المحتمل لا وجود لعدم يقين هام",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "لا وجود لعدم يقين هامّ",
                    "value": "no_important"
                  }
                ],
                "question": "هل هناك عدم يقين مهم بشأن مقدار تقدير الناس للنتائج الرئيسية؟",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "ما مدى تقدير الأفراد لكل نتيجة من النتائج الرئيسية؟ هل عدم اليقين بشأن مدى تقديرهم لكل من النتائج أو التباين في مدى تقدير الأفراد المختلفين للنتائج كبير بما يكفي بحيث يمكن أن يؤدي إلى قرارات مختلفة؟",
          "options": [
            {
              "text": "عدم يقين مهم أو تباين",
              "value": "important_uncertainty"
            },
            {
              "text": "من المحتمل وجود عدم يقين مهم أو تباين",
              "value": "possible_important"
            },
            {
              "text": "من المحتمل لا وجود لعدم يقين مهم أو تباين",
              "value": "probably_no_important"
            },
            {
              "text": " لا لعدم يقين مهم أو تباين",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "القبول",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "معيار مخصص",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "توازن التأثيرات",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
          "criterionId": "balanceOfHealthBenefitsAndHarms",
          "name": "التوازن بين الفوائد الصحية والأضرار",
          "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "اليقين من الآثار",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "اليقين من الأدلة",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "اليقين من دليل آثار الإدارة",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "يقين الأدلة من الموارد المطلوبة",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "اليقين من دليل دقة الاختبار",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "ليقين من دليل آثار الاختبار",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "اليقين من دليل نتيجة الاختبار / الإدارة",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "الفعالية من حيث التكلفة",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "التأثيرات المرغوبة ",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "عدالة",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "قابلية التنفيذ",
          "researchEvidenceId": "feasibility"
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
          "criterionId": "feasibilityAndHealthSystemConsiderations",
          "name": "اعتبارات قابلية التنفيذ والنظام الصحي",
          "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
        },
        "feasible": {
          "additionalConsiderationId": "feasible",
          "criterionId": "feasible",
          "name": "FEASIBLE",
          "researchEvidenceId": "feasible"
        },
        "financialAndEconomicConsiderations": {
          "additionalConsiderationId": "financialAndEconomicConsiderations",
          "criterionId": "financialAndEconomicConsiderations",
          "name": " الاعتبارات المالية والاقتصادية ",
          "researchEvidenceId": "financialAndEconomicConsiderations"
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
          "criterionId": "healthEquityEqualityAndNonDiscrimination",
          "name": "العدالة الصحية والمساواة وعدم التمييز",
          "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
        },
        "humanRights": {
          "additionalConsiderationId": "humanRights",
          "criterionId": "humanRights",
          "name": "حقوق الانسان",
          "researchEvidenceId": "humanRights"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "مشكلة",
          "researchEvidenceId": "problem"
        },
        "qualityOfEvidence": {
          "additionalConsiderationId": "qualityOfEvidence",
          "criterionId": "qualityOfEvidence",
          "name": "جودة الدليل",
          "researchEvidenceId": "qualityOfEvidence"
        },
        "relevantDirect": {
          "additionalConsiderationId": "relevantDirect",
          "criterionId": "relevantDirect",
          "name": "RELEVANT (DIRECT)",
          "researchEvidenceId": "relevantDirect"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "الموارد المطلوبة",
          "researchEvidenceId": "resourcesRequired"
        },
        "responsive": {
          "additionalConsiderationId": "responsive",
          "criterionId": "responsive",
          "name": "RESPONSIVE",
          "researchEvidenceId": "responsive"
        },
        "scientificallySound": {
          "additionalConsiderationId": "scientificallySound",
          "criterionId": "scientificallySound",
          "name": "SCIENTIFICALLY SOUND (CERTAINTY OF EVIDENCE)",
          "researchEvidenceId": "scientificallySound"
        },
        "societalImplications": {
          "additionalConsiderationId": "societalImplications",
          "criterionId": "societalImplications",
          "name": "الآثار المجتمعية",
          "researchEvidenceId": "societalImplications"
        },
        "socioCulturalAcceptability": {
          "additionalConsiderationId": "socioCulturalAcceptability",
          "criterionId": "socioCulturalAcceptability",
          "name": "القبول الاجتماعي والثقافي",
          "researchEvidenceId": "socioCulturalAcceptability"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": " دقة الاختبار",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "التأثيرات غير المرغوبة",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "قيم",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "benefits": {
          "content": "",
          "description": "فوائد"
        },
        "clearAndActionable": {
          "content": "",
          "description": "واضح وقابل للتنفيذ",
          "options": [
            {
              "text": "نعم",
              "value": "yes"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "لا",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "clinicalConsiderations": {
          "content": "",
          "description": "Clinical considerations"
        },
        "collectingAndSummarizing": {
          "content": "",
          "description": "يُعد جمع الأدلة وتلخيصها استخدامًا سيئًا للوقت والطاقة المحدودين للجنة المبادئ التوجيهية (تكلفة الفرصة البديلة بشكل عام)",
          "options": [
            {
              "text": "نعم",
              "value": "yes"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "لا",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "considerations": {
          "content": "",
          "description": "الاعتبارات"
        },
        "contextAndSystemConsiderations": {
          "content": "",
          "description": "اعتبارات السياق والنظام"
        },
        "decision": {
          "content": "",
          "description": "قرار",
          "options": [
            {
              "text": "التنفيذ الكامل",
              "value": "full_implementation"
            },
            {
              "text": "تقييم الأثر",
              "value": "impact_evaluation"
            },
            {
              "text": "دراسة تجريبية",
              "value": "pilot_study"
            },
            {
              "text": "يؤجل",
              "value": "postpone"
            },
            {
              "text": "لا تنفذ",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "نوع القرار",
          "options": [
            {
              "direction": "negative",
              "text": "لا تغطي",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "تغطية مع تطوير الأدلة",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "تغطية مع التفاوض على السعر",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "تغطية محدودة",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "تغطية",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "نوع القرار",
          "options": [
            {
              "direction": "negative",
              "text": "لا تنفذ الخيار",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "تأجيل القرار",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "إجراء دراسة تجريبية للخيار",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "طبق الخيار مع تقييم النتيجة/الأثر",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "تنفيذ الخيار",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "evidence": {
          "content": "",
          "description": "دليل"
        },
        "frequencyDoseDuration": {
          "content": "",
          "description": "التكرار / الجرعة / المدة"
        },
        "goodPracticeStatement": {
          "content": "",
          "description": "بيان الممارسات الجيدة"
        },
        "harms": {
          "content": "",
          "description": "الأضرار"
        },
        "implementationConsiderations": {
          "content": "",
          "description": "اعتبارات التنفيذ",
          "details": {
            "content": "",
            "description": "التنفيذ التفصيلي"
          }
        },
        "implementationConsiderationsOfAllRelevantOutcomes": {
          "content": "",
          "description": "بعد النظر في جميع النتائج ذات الصلة والنتائج النهائية المحتملة ، يؤدي تنفيذ بيان الممارسة الجيدة إلى نتائج إيجابية صافية كبيرة",
          "options": [
            {
              "text": "نعم",
              "value": "yes"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "لا",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderationsOrRemarks": {
          "content": "",
          "description": "اعتبارات التنفيذ",
          "options": [
            {
              "text": "نعم",
              "value": "yes"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "لا",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "indications": {
          "content": "",
          "description": "دواعي الإستعمال"
        },
        "indicationsForDiscontinuation": {
          "content": "",
          "description": "مؤشرات للتوقف"
        },
        "justification": {
          "content": "",
          "description": "التبرير",
          "details": {
            "description": "تبرير مفصل"
          },
          "overallDescription": "المبرر العام"
        },
        "keyMessage": {
          "content": "",
          "description": "الرسالة الرئيسية"
        },
        "limitations": {
          "content": "",
          "description": "قيود"
        },
        "mdgRecommendation": {
          "content": "",
          "description": "توصية",
          "options": [
            {
              "direction": "positive",
              "text": "موصى به",
              "value": "recommend"
            },
            {
              "direction": "negative",
              "text": "غير موصى به",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Sometimes Recommended",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "لا توجد توصية",
              "value": "suggest_either"
            }
          ],
          "selectedOption": ""
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "رصد وتقييم",
          "details": {
            "content": "",
            "description": "الرصد والتقييم التفصيليان"
          }
        },
        "monitoringAndEvaluationQI": {
          "content": "",
          "description": "رصد وتقييم"
        },
        "multipleChronicConditionsAndPolypharmacy": {
          "content": "",
          "description": "الحالات المزمنة المتعددة وتعدد الأدوية"
        },
        "multipleRecommendations": {
          "description": "توصية (توصيات)",
          "options": [
            {
              "direction": "negative",
              "text": "توصية قوية ضد التدخل",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "توصية مشروطة ضد التدخل",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "توصية مشروطة إما للتدخل أو للمقارنة",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "التوصية المشروطة للتدخل",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "توصية قوية للتدخل",
              "value": "recommend"
            }
          ],
          "recommendations": [
            {
              "content": "",
              "selectedOption": ""
            }
          ]
        },
        "rationale": {
          "content": "",
          "description": "عرض الأسباب"
        },
        "rationaleConnectingIndirectEvidence": {
          "content": "",
          "description": "هناك سبب منطقي جيد التوثيق وواضح وصريح يربط بين الأدلة غير المباشرة",
          "options": [
            {
              "text": "نعم",
              "value": "yes"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "لا",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "recommendation": {
          "content": "",
          "description": "توصية"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "نوع التوصية",
          "options": [
            {
              "direction": "negative",
              "text": "توصية قوية ضد التدخل",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "توصية مشروطة ضد التدخل",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "توصية مشروطة إما للتدخل أو للمقارنة",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "التوصية المشروطة للتدخل",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "توصية قوية للتدخل",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "نوع التوصية",
          "options": [
            {
              "direction": "negative",
              "text": "توصية قوية ضد الخيار",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "توصية مشروطة ضد الخيار",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "توصية شرطية للخيار أو المقارنة",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "توصية مشروطة للخيار",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "توصية قوية للخيار",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "أولويات البحث"
        },
        "restrictions": {
          "content": "",
          "description": "قيود"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "اعتبارات المجموعة الفرعية"
        },
        "theMessageIsNecessary": {
          "content": "",
          "description": "الرسالة ضرورية فيما يتعلق بممارسة الرعاية الصحية الفعلية",
          "options": [
            {
              "text": "نعم",
              "value": "yes"
            },
            {
              "text": "على الأرجح نعم",
              "value": "probably_yes"
            },
            {
              "text": "على الأرجح لا",
              "value": "probably_no"
            },
            {
              "text": "لا",
              "value": "no"
            }
          ],
          "selectedOption": ""
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "الأطباء",
          "sections": {
            "background": {
              "name": "خلفية"
            },
            "detailedJustification": {
              "name": "تبرير مفصل"
            },
            "implementationConsiderations": {
              "name": "اعتبارات التنفيذ"
            },
            "justification": {
              "name": "التبرير"
            },
            "relatedRecommendations": {
              "name": "التوصيات ذات الصلة"
            },
            "subgroupConsiderations": {
              "name": "اعتبارات المجموعة الفرعية"
            },
            "summaryOfFindings": {
              "name": "ملخص النتائج"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "المرضى",
          "sections": {
            "relatedRecommendations": {
              "name": "التوصيات ذات الصلة "
            },
            "summaryOfFindings": {
              "name": "SoF"
            },
            "whatItMeansForYou": {
              "name": "ماذا يعني هذا بالنسبة لك؟",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "تحدث مع أخصائي الرعاية الصحية الخاص بك"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "ما تستطيع فعله"
                }
              }
            },
            "whoIsThisFor": {
              "name": "لمن هذا",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "لمن هذا"
                }
              }
            },
            "whyThisRecommendation": {
              "name": "لماذا هذه التوصية؟",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "معلومة اضافية"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "فوائد واضرار"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "لماذا هذه التوصية"
                }
              }
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "صناع السياسة",
          "sections": {
            "assessment": {
              "name": "تقييم"
            },
            "background": {
              "description": "تفاصيل السؤال للتوصية",
              "details": {
                "name": "حول هذا القرار",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "تاريخ"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "صناع القرار"
                  },
                  "perspective": {
                    "content": "",
                    "description": "وجهة نظر"
                  },
                  "setting": {
                    "content": "",
                    "description": "الإعدادات"
                  }
                },
                "sectionsOrder": [
                  "setting",
                  "perspective",
                  "decisionMakers",
                  "date"
                ]
              },
              "name": "خلفية"
            },
            "decision": {
              "content": "",
              "name": "قرار",
              "options": [
                {
                  "text": "التنفيذ الكامل",
                  "value": "full_implementation"
                },
                {
                  "text": "تقييم الأثر",
                  "value": "impact_evaluation"
                },
                {
                  "text": "دراسة تجريبية",
                  "value": "pilot_study"
                },
                {
                  "text": "يؤجل",
                  "value": "postpone"
                },
                {
                  "text": "لا تنفذ",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "تطبيق"
            },
            "justification": {
              "name": "التبرير"
            },
            "monitoringAndEvaluation": {
              "name": "الرصد والتقييم"
            },
            "relatedRecommendations": {
              "name": "التوصيات ذات الصلة"
            },
            "summaryOfFindings": {
              "name": "ملخص النتائج"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "age": {
          "content": "",
          "name": "العمر"
        },
        "anticipatedOutcomes": {
          "content": "",
          "name": "النتائج المتوقعة"
        },
        "background": {
          "content": "",
          "name": "خلفية"
        },
        "coi": {
          "content": "",
          "name": "تضارب المصالح"
        },
        "comparison": {
          "content": "",
          "name": "مقارنة"
        },
        "intent": {
          "content": "",
          "name": "نية"
        },
        "intervention": {
          "content": "",
          "name": "التدخل"
        },
        "linkedTreatments": {
          "content": "",
          "name": "العلاجات المرتبطة"
        },
        "mainOutcomes": {
          "content": "",
          "name": "النتائج الرئيسية"
        },
        "option": {
          "content": "",
          "name": "خيار"
        },
        "perspective": {
          "content": "",
          "name": "وجهة نظر"
        },
        "population": {
          "content": "",
          "name": "السكان"
        },
        "problem": {
          "content": "",
          "name": "مشكلة"
        },
        "purpose": {
          "content": "",
          "name": "الغرض من الاختبار"
        },
        "requirements": {
          "content": "",
          "name": "Requirements"
        },
        "role": {
          "content": "",
          "name": "دور الاختبار"
        },
        "setting": {
          "content": "",
          "name": "الإعدادات"
        },
        "subgroups": {
          "content": "",
          "name": "المجموعات الفرعية"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
