{ useCoffeeCallback, useCoffeeEffect } = require 'lib/react_utils'
{ useState } = React

TextAreaField = ({ onChange, placeholder, value }) ->
  [text, setText] = useState(value)

  onBlur = useCoffeeCallback [onChange, text], -> onChange text

  onTextAreaChange = useCoffeeCallback [setText], (evt) -> setText evt.target.value

  useCoffeeEffect [setText, value], -> setText value

  <textarea
    onBlur={onBlur}
    onChange={onTextAreaChange}
    placeholder={placeholder}
    value={text}
  />

TextAreaField.propTypes =
  onChange: PropTypes.func.isRequired
  placeholder: PropTypes.string
  value: PropTypes.string.isRequired

module.exports = TextAreaField
