var caseControlDesignStudies, caseControlStudiesDichOutcome, caseControlStudiesOutcome, caseReportSeriesDesignStudies, caseReportSeriesStudiesCont, caseReportSeriesStudiesDich, caseReportsSeriesOutcome, combineStrategies, continuousOutcome, dichotomousOutcome, editingCell, notMeasuredMetatypes, notMeasuredNotReportedOutcome, notPooledContOutcome, notPooledDichOutcome, notPooledOutcome, oneRowViewOutcome, rangeOfEffectsDichOutcome, rangeOfEffectsOutcome, sofViewOutcome,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

combineStrategies = require('lib/gdt_tables/cell_match_strategies').combineStrategies;

caseControlDesignStudies = ['case_control_studies', 'case_control_and_other_combined'];

caseReportSeriesDesignStudies = ['case_series', 'case_reports'];

notMeasuredMetatypes = ['not_measured', 'not_reported'];

notMeasuredNotReportedOutcome = function(cellId, data) {
  var _ref, _ref1;
  return _ref = (_ref1 = data.metaType) != null ? _ref1.toLowerCase() : void 0, __indexOf.call(notMeasuredMetatypes, _ref) >= 0;
};

caseReportsSeriesOutcome = function(cellId, data) {
  var _ref, _ref1;
  return _ref = (_ref1 = data.designStudies) != null ? _ref1.value : void 0, __indexOf.call(caseReportSeriesDesignStudies, _ref) >= 0;
};

caseControlStudiesOutcome = function(cellId, data) {
  var _ref, _ref1;
  return _ref = (_ref1 = data.designStudies) != null ? _ref1.value : void 0, __indexOf.call(caseControlDesignStudies, _ref) >= 0;
};

notPooledOutcome = function(cellId, data) {
  var _ref;
  return ((_ref = data.metaType) != null ? _ref.toLowerCase() : void 0) === 'not_pooled';
};

rangeOfEffectsOutcome = function(cellId, data) {
  var _ref;
  return ((_ref = data.metaType) != null ? _ref.toLowerCase() : void 0) === 'range_of_effects';
};

continuousOutcome = function(cellId, data) {
  return data.type === 'cont';
};

dichotomousOutcome = function(cellId, data) {
  return data.type === 'dich';
};

sofViewOutcome = function(cellId, data) {
  var tableViewType;
  tableViewType = data._viewTypeName;
  return tableViewType === 'sof';
};

oneRowViewOutcome = function(cellId, data) {
  var tableViewType;
  tableViewType = data._viewTypeName;
  return tableViewType === 'one_row';
};

editingCell = function(cellId, _arg) {
  var outcomeEditMetadata, _id;
  outcomeEditMetadata = _arg.outcomeEditMetadata, _id = _arg._id;
  return outcomeEditMetadata && outcomeEditMetadata.get('cellId') === cellId && outcomeEditMetadata.get('outcomeId') === _id;
};

notPooledDichOutcome = combineStrategies(dichotomousOutcome, notPooledOutcome);

caseReportSeriesStudiesDich = combineStrategies(sofViewOutcome, dichotomousOutcome, caseReportsSeriesOutcome);

caseReportSeriesStudiesCont = combineStrategies(sofViewOutcome, continuousOutcome, caseReportsSeriesOutcome);

caseControlStudiesDichOutcome = combineStrategies(dichotomousOutcome, caseControlStudiesOutcome);

notPooledContOutcome = combineStrategies(continuousOutcome, notPooledOutcome);

rangeOfEffectsDichOutcome = combineStrategies(rangeOfEffectsOutcome, dichotomousOutcome);

module.exports = {
  notMeasuredNotReportedOutcome: notMeasuredNotReportedOutcome,
  caseReportsSeriesOutcome: caseReportsSeriesOutcome,
  caseControlStudiesOutcome: caseControlStudiesOutcome,
  notPooledOutcome: notPooledOutcome,
  rangeOfEffectsOutcome: rangeOfEffectsOutcome,
  continuousOutcome: continuousOutcome,
  dichotomousOutcome: dichotomousOutcome,
  sofViewOutcome: sofViewOutcome,
  oneRowViewOutcome: oneRowViewOutcome,
  notPooledDichOutcome: notPooledDichOutcome,
  caseControlStudiesDichOutcome: caseControlStudiesDichOutcome,
  notPooledContOutcome: notPooledContOutcome,
  rangeOfEffectsDichOutcome: rangeOfEffectsDichOutcome,
  editingCell: editingCell
};
