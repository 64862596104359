var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  return "<header id=\"header-container\"></header>\n<div class=\"container outer-container\">\n  <div id=\"sidebar-container\">\n  </div>\n  <div id=\"right-container\">\n    <div id=\"topbar\">\n      <div id=\"searchbox-container\"></div>\n      <div id=\"toolbar-container\"></div>\n    </div>\n    <div id=\"page-container\">\n    </div>\n  </div>\n  <div id=\"right-sidebar-container\">\n  </div>\n</div>\n";
  },"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}