Translation = require 'components/mixins/translation'
{ shape, func, string, bool, instanceOf } = PropTypes
SectionSelectionRow = require 'components/panel_voice/section_selection_row'

section =
  status: string
  checked: bool
  preselected: bool
  disabled: bool
  preselectDisabled: bool

SectionSelection = createReactClass

  displayName: "SectionSelection"

  mixins: [Translation('voting:voting')]

  propTypes:
    overarching: bool
    sections: shape
      assessment: shape section
      recommendation: shape section
      conclusion: shape section
    updateSections: func.isRequired

  getDefaultProps: ->
    overarching: false

  onCheck: (name) ->
    { sections, updateSections, overarching } = @props
    checked = sections.getIn [name, 'checked']
    nextSections = sections.setIn [name, 'checked'], not checked
    if checked and not overarching
    # when uncheck section, we want to uncheck preselect as well, unless it's overarching
      nextSections = nextSections.setIn [name, 'preselected'], false
    updateSections nextSections

  onPreselect: (name) ->
    { sections, updateSections } = @props
    updateSections sections.setIn [name, 'preselected'], not sections.getIn [name, 'preselected']

  render: ->
    <div className="section-selection-container">
      <div className='section-selection'>
        <div className="pull-left">
          <div className="head">
            {@i18n 'select_etd_sections'}
          </div>
        </div>
        <div className="pull-right text-right">
          <div className="head">
            {@i18n "include_preselected"}
          </div>
        </div>
        <div className="clearfix" />
        {@props.sections.get('assessment') and <SectionSelectionRow
          {...@props.sections.get('assessment', Immutable.Map()).toJS()}
          title={@i18n 'assessment'}
          onCheck={@onCheck}
          onPreselect={@onPreselect}
        />}
        {@props.sections.get('recommendation') and <SectionSelectionRow
          {...@props.sections.get('recommendation', Immutable.Map()).toJS()}
          title={@i18n 'type_of_recommendation'}
          onCheck={@onCheck}
          onPreselect={@onPreselect}
        />}
        {@props.sections.get('conclusion') and <SectionSelectionRow
          {...@props.sections.get('conclusion', Immutable.Map()).toJS()}
          title={@i18n 'conclusion'}
          onCheck={@onCheck}
          onPreselect={@onPreselect}
        />}
      </div>
      <div className="section-description">
        {@i18n 'sections_description'}
      </div>
    </div>

module.exports = SectionSelection
