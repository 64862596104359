var Migration, RECOMMENDATION_INTENT_PATH, RemoveCovidCausation, W, mediator, migrationName, updateRecommendation,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'covid_remove_causation';

RECOMMENDATION_INTENT_PATH = ['templateData', 'conclusions', 'sections', 'recommendation', 'recommendationIntent'];

updateRecommendation = function(rec) {
  return rec.updateIn(RECOMMENDATION_INTENT_PATH, function(intent) {
    if ((intent == null) || intent === 'causation') {
      return void 0;
    } else {
      return intent;
    }
  }).toJS();
};

module.exports = RemoveCovidCausation = (function(_super) {
  __extends(RemoveCovidCausation, _super);

  function RemoveCovidCausation() {
    RemoveCovidCausation.__super__.constructor.call(this, false);
  }

  RemoveCovidCausation.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    RemoveCovidCausation.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds'));
    }, []);
    return adapter.fetch(projectId, _.compact(_.uniq(docIds))).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(recs) {
      var updatedDocs;
      if (_.isEmpty(recs)) {
        return W.resolve();
      }
      updatedDocs = _.map(recs, function(doc) {
        doc = updateRecommendation(Immutable.fromJS(doc));
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return RemoveCovidCausation;

})(Migration);
