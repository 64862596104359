GdtEditorWithApply = Editor.EditorWithApply
InsertModal = require 'components/etd/insert_modal'
InsertedGdtTable = require 'components/evidence_syntheses/inserted_gdt_table'
DocSectionsFromTemplateActions = require 'actions/doc_sections_from_template_actions'
ReferencesInsertModal = require 'components/etd/references_insert_modal'
ReferenceCitationMixin = require 'components/mixins/reference_citation_mixin'
DocSectionsReferenceCitation =
  require 'components/document_sections/from_template/doc_sections_reference_citation'
{ Card, CardContent } = ReactComponents
editorUtils = Editor.EditorUtils

DocSectionsEditor = createReactClass
  displayName: 'DocSectionsEditor'

  propTypes:
    chapterId: PropTypes.string.isRequired
    subsectionIdx: PropTypes.number.isRequired
    content: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.Map)
      PropTypes.string
    ])
    attachments: PropTypes.instanceOf(Immutable.Map)
    referenceIds: PropTypes.arrayOf(PropTypes.string)
    refreshEditors: PropTypes.func
    referencesState: PropTypes.number

  mixins: [
    ReferenceCitationMixin()
  ]

  getInitialState: ->
    showInsertModal: false

  editorRef: (el) ->
    @gdtEditor = el?.gdtEditor

  _getCustomControls: ->
    insertToggle =
      label: 'insert'
      onToggle: =>
        @gdtEditor.keepEditorActive()
        @setState showInsertModal: true

    [ insertToggle, @getReferenceToggleSpec() ]

  _getCustomEntityRenderers: ->
    'GDT_TABLE': (Editor) =>
      component: InsertedGdtTable
      editable: false
      props:
        renderMode: @props.renderMode
        focusEditor: Editor.focus
        updateData: @updateEntityData
        isEditing: Editor.state.isEditing

  _getCustomReferenceRenderer: ->
    strategy: editorUtils.findTextByEntityType 'REFERENCE'
    component: DocSectionsReferenceCitation
    props:
      displayAsIndex: true
      referenceIds: @props.referenceIds
      references: @props.references
      renderMode: @props.renderMode

  updateEntityData: (entityKey, newData, silent = false) ->
    editorUtils.updatedEntityData entityKey, newData
    if silent
      @saveContent()
    else
      mediator.dialogs.success 'Updated successfully'
      @gdtEditor.focus()

  closeInsertModal: ->
    @setState showInsertModal: false
    @gdtEditor.focus()

  onCancel: ->
    @gdtEditor.resetContent @props.content

  saveAttachment: (file) ->
    attachmentName = "#{@props.chapterId}/#{file.name.replace(/\s/g, '_')}"
    DocSectionsFromTemplateActions.saveAttachment { attachmentName, file }
    attachmentName

  saveContent: ->
    content = @gdtEditor.getEditorContent()
    # do not save if content's not changed
    return if content.equals @props.content

    DocSectionsFromTemplateActions
      .updateChapterSubsection @props.chapterId, @props.subsectionIdx, content

    # It may not be defined for printouts
    _.defer => @props.refreshEditors?()

  handleInsert: (insertData) ->
    { contentType, content } = insertData
    @closeInsertModal()

    if contentType is 'GDT_TABLE'
      gdtTableBlockSpec = type: 'GDT_TABLE', mutability: 'IMMUTABLE', data: content
      @gdtEditor.insertCustomBlock gdtTableBlockSpec
    else
      @gdtEditor.insertContent insertData

  componentDidUpdate: (prevProps, prevState) ->
    # if content has changed - force editor re-render
    prevProps.content ?= ''
    hasContentChanged = if prevProps.referencesState isnt @props.referencesState
      true
    else if _.isString prevProps.content
      prevProps.content isnt @props.content
    else if prevProps.content and @props.content
      not prevProps.content?.equals @props.content
    else
      false

    @gdtEditor.resetContent @props.content if hasContentChanged

  render: ->
    readOnly = @props.readOnly ? false

    <Card className="document-sections__chapter-editor-card">
      <CardContent>
        <GdtEditorWithApply
          ref={@editorRef}
          readOnly={readOnly}
          editorContent={@props.content}
          customControls={@_getCustomControls()}
          customEntityRenderers={@_getCustomEntityRenderers()}
          customDecorators={@_getCustomReferenceRenderer()}
          attachments={@props.attachments}
          saveAttachment={@saveAttachment}
          onCancel={@onCancel}
          onApply={@saveContent}
          onBlur={@saveContent}
          customHandlers={handlePastedText: @handleReferencePaste}
          stickControlsTo='#document-sections-from-template-container'
        />
        {if @state.showInsertModal
          <InsertModal
            onClose={@closeInsertModal}
            onInsert={@handleInsert}
          />
        }
        {if @state.showReferencesInsert
          <ReferencesInsertModal
            onInsert={@onInsertReferences}
            onClose={@toggleReferencesInsert}
          />
        }
      </CardContent>
    </Card>

module.exports = DocSectionsEditor
