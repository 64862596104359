RadioInput = require 'components/common/radio_input'
TextWithHelpLink = require 'components/administration/voting/voting_text_with_links'
VotingStart = require 'components/administration/voting/voting_start_block'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

VotingPhase2QuetionsBlock = createReactClass
  displayName: 'VotingPhase2QuetionsBlock'

  mixins: [
    CustomRenderMixin
    Translation('voting:voting')
  ]

  _getSectionHeadingText: ->
    if @props.lastlyVoted is 'recommendation'
      <span>
        <b>{@i18n '/settings:etd_templates.conclusions'}</b>
        <TextWithHelpLink
          text={@i18n 'phase2_conclusions_heading_text'}
          links={link1: ['votingFormConclusions', @i18n 'voting_forms_conclusions_help_text']}
          onInfoLinkClick={@props.onInfoLinkClick}
        />
      </span>
    else
      <span>
        <b> {@i18n 'type_of_recommendation'}</b> {@i18n 'and'}
        <b> {@i18n '/settings:etd_templates.conclusions'}</b>
      </span>

  _getEtdPartsCode: ->
    if @props.lastlyVoted is 'recommendation' then 'C' else 'TC'

  _getVotingSteps: ->
    <ol className="old-voting-ol">
      <li>
        <div className="section-text">
          <TextWithHelpLink
            text={@i18n 'phase2_step1_text'}
            links={link1: ['votingFormEtdPartsPhase2', @i18n 'voting_form_help_text']}
            onInfoLinkClick={@props.onInfoLinkClick}
          />
        </div>
        <div className="voting-options">
          <label>
            <RadioInput name="content-proposal" value="proposed"
              checked={@props.selectedContentOption is 'proposed'}
              onChange={@onChangeContentOption 'proposed'}
            />
            {@i18n 'content'} <b>{@i18n 'proposed'}</b> {@i18n 'in_both'}
            <b> {@i18n 'type_of_recommendation'}</b> {@i18n 'and'}
            <b> {@i18n '/settings:etd_templates.conclusions'}</b> ({@i18n 'agree_disagree_voting'})
          </label>
          <label>
            <RadioInput name="content-proposal" value="empty"
              checked={@props.selectedContentOption is 'empty'}
              onChange={@onChangeContentOption 'empty'}
            />
            {@i18n 'content'} <b>{@i18n 'empty'}</b> {@i18n 'in_both'}
            <b> {@i18n 'type_of_recommendation'}</b> {@i18n 'and'}
            <b> {@i18n '/settings:etd_templates.conclusions'}</b> ({@i18n 'full_scale_voting'})
          </label>
        </div>
      </li>
      <li>
        <div className="section-text">
          {@i18n 'which_questions'}
        </div>
        <p>{@i18n 'required_data_note'}</p>
      </li>
    </ol>

  _getSelectedQuestionsIds: ->
    @refs.questionsList.getSelectedQuestionsIds()

  _getSelectedQuestions: ->
    @props.questions.filter (q) => @_getSelectedQuestionsIds().contains q.get 'id'

  onChangeContentOption: (value) -> =>
    @props.updateVotingOption {name: 'selectedContentOption', value }

  render: ->
    <div className="phase2-block">
      <div className="section-text">
        {@i18n 'will_be_sent_for_voting'}: {@_getSectionHeadingText()}
      </div>
      {if @props.lastlyVoted is 'judgements'
        @_getVotingSteps()
      }
      <VotingStart
        phase='phase2a'
        projectId={@props.projectId}
        questions={@props.questions}
        questionGroups={@props.questionGroups}
        etds={@props.etds}
        lastlyVoted={@props.lastlyVoted}
        selectedContentOption={@props.selectedContentOption}
        etdPartLabels={@props.getEtdPartLables @_getEtdPartsCode()}
        message={@props.message}
        dueDate={@props.dueDate}
        contactEmail={@props.contactEmail}
        canVotingBeStarted={@props.canVotingBeStarted}
        updateMessageDetails={@props.updateMessageDetails}
        sendVoting={@props.sendVoting}
      />
    </div>

module.exports = VotingPhase2QuetionsBlock
