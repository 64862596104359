ConnectStore = require 'components/enhancers/connect_store'
QualityIndicatorsActions = require 'actions/quality_indicators_actions'
QualityIndicatorsStore = require 'stores/quality_indicators_store'
{ useState, useEffect } = React
{ useI18n } = require 'lib/react_utils'

storeConnector =
  QualityIndicatorsStore: (Store, props) ->
    qualityIndicator: Store.getQualityIndicatorForOutcome(props.outcome?._id)

QualityIndicatorsInput = ({ outcome, qualityIndicator }) ->
  i18n = useI18n('quality_indicators:table')
  [qiName, setQiName] = useState('')
  [inputChecked, setInputChecked] = useState(false)

  # outcomes view is rendered before quality indicators are fetched
  # therefore we need to update component
  useEffect(->
    initialName = qualityIndicator.get('name', null) or outcome?.name
    inputChecked = outcome._id and qualityIndicator.get('outcome') is outcome._id
    setQiName(initialName)
    setInputChecked(inputChecked)
  , [outcome, qualityIndicator])

  saveQiName = ->
    QualityIndicatorsActions.updateValue qualityIndicator.get('id'), 'name', qiName

  markAsQICandidate = ->
    if qualityIndicator.isEmpty()
      QualityIndicatorsActions.createWithOutcome outcome
    else
      QualityIndicatorsActions.updateValue qualityIndicator.get('id'), 'outcome', ''

  # faking input checkbox because of its weird behavior without onChange handler
  labelClassNames = classNames 'quality-indicators-checkbox',
    'quality-indicators-checkbox--checked': inputChecked

  <div className="mt-10">
    <div>
      {
        ###
          using onMouseDown instead of onChange on input or onClick on label
          because e.stopPropagation() in outcome_edit_row_view
          would prevent markAsQICandidate from being called
        ###
      }
      {outcome._id &&
        <label className={labelClassNames} onMouseDown={markAsQICandidate}>
          {i18n 'quality_indicator_candidate'}
        </label>
      }
    </div>
    <div className="my-10">
      {inputChecked and <input
        className="w-full"
        type="text"
        value={qiName}
        onChange={(e) => setQiName(e.target.value)}
        onBlur={saveQiName}
      />}
    </div>
  </div>


QualityIndicatorsInput.propTypes =
  outcome: PropTypes.object.isRequired
  qualityIndicator: PropTypes.instanceOf(Immutable.Map)

QualityIndicatorsInput.defaultProps =
  qualityIndicator: Immutable.Map()

module.exports = ConnectStore QualityIndicatorsInput, QualityIndicatorsStore, storeConnector
