module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿La intervención es aceptable para las partes interesadas?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "¿hay partes interesadas que no aceptarían la distribución de los beneficios, daños y costos?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "¿Hay partes interesadas que no acceptarían los costos o efectos indeseables a corto plazo por los efectos deseables (beneficios) en el futuro?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "¿Hay partes interesadas que no estarían de acuerdo con los valores ligados a los efectos deseables o indeseables (por cómo se verían afectados personalmente o por su percepción de la importancia relativa de los efectos para otros)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "¿La intervención (opción) podría afectar adversamente la autonomía de la gente?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "¿Hay partes interesadas que desaprobarían la intervención (opción) moralmente, por razones distintas a su efecto en la autonomía de las personas (i.e. en relación con principios éticos tales como no-maleficencia, beneficencia o justicia)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "¿Es probable que las partes interesadas no acepten la distribución de los beneficios, daños y costos; o los costos o los efectos indeseables a corto plazo por efectos deseables (beneficios) en el futuro? ¿Es probable que estén en desacuerdo con los valores ligados a los efectos deseables o indeseables, o que no acepten la intervención diagnóstica por preocupaciones éticas?",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Sí",
              "value": "yes"
            }
          ],
          "ratingDescription": "¿Qué intervención es más aceptable para las partes interesadas?",
          "ratingOptions": [
            "best_acceptability",
            "intermediate_acceptability",
            "worst_acceptability"
          ],
          "selectedOption": ""
        },
        "additionalSection": {
          "additionalOptions": [
            {
              "text": "Juicio adicional 1",
              "value": "additionalOption_1"
            },
            {
              "text": "Juicio adicional 2",
              "value": "additionalOption_2"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Descripción del criterio personalizado",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Proporcione información sobre herramientas personalizada",
          "options": [
            {
              "text": "Juicio 1",
              "value": "option_1"
            },
            {
              "text": "Juicio 2",
              "value": "option_2"
            },
            {
              "text": "Juicio 3",
              "value": "option_3"
            },
            {
              "text": "Juicio 4",
              "value": "option_4"
            },
            {
              "text": "Juicio 5",
              "value": "option_5"
            }
          ],
          "selectedOption": ""
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿El balance entre los efectos deseables e indeseables favorece la intervención o la comparación? ",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "Juicios detallados para este criterio incluyen juicios respecto a cada uno de los cuatro criterios precedentes:"
              },
              {
                "additions": [
                  "¿Cuánta menos gente valora desenlaces que son en el futuro comparado con desenlaces que ocurren ahora (sus tasas de descuento)?",
                  "Actitudes de las personas frente a desenlaces indeseables (qué tan aversivos son al riesgo)",
                  "Las actitudes de las personas hacia los efectos deseables (qué riesgo tienen de encontrarlos)."
                ],
                "description": "Adicionalmente,  los paneles podrían querer considerar (si el documento es relevante) la extensión en la que las consideraciones siguientes pueden alcanzar a influir el balance entre los efectos deseables e indeseables: "
              }
            ],
            "panelDiscussion": ""
          },
          "info": "¿Cuál es el balance entre los efectos deseables e indeseables, teniendo en cuenta qué tanto los individuos valoran los desenlaces principales, qué tan significativos son estos efectos, la certeza de los estimados, tasas de descuento, aversión al riesgo y búsqueda de riesgo?",
          "options": [
            {
              "text": "Favorece la comparación",
              "value": "favors_comparison"
            },
            {
              "text": "Probablemente favorece la comparación",
              "value": "probably_favors_comparison"
            },
            {
              "text": "No favorece la intervención ni la comparación",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Probablemente favorece la intervención",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Favorece la intervención",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?",
          "ratingOptions": [
            "best_balance",
            "intermediate",
            "worst_balance"
          ],
          "selectedOption": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿El equilibrio entre los efectos deseables e indeseables sobre la salud favorece la intervención o la comparación?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "¿La <strong>eficacia</strong> a corto y largo plazo (en circunstancias controladas, a menudo ideales), o la <strong>efectividad</strong> (en un contexto de la vida real) de la intervención en la <strong>salud de los individuos</strong> (incluidos los resultados informados por los pacientes), favorecen la intervención o la comparación?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "¿La <strong>eficacia</strong> o el <strong>impacto</strong> a corto y largo plazo de la intervención sobre la <strong>salud de la población</strong> (incluidos los resultados informados por los beneficiarios), favorecen la intervención o la comparación? (Esto debe incluir consideraciones sobre si los resultados a nivel de población representan desenlaces agregados a nivel individual o surgen a través de dinámicas de los sistemas).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "¿La medida en que <strong>los pacientes/beneficiarios valoran</strong> los diferentes desenlaces de salud favorecen la intervención o la comparación?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "¿La <strong>probabilidad y gravedad de los eventos adversos</strong> asociados con la intervención (incluido el riesgo de que la intervención sea mal utilizada) favorece la intervención o la comparación?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "¿Los <strong>impactos positivos o negativos relacionados con la salud</strong> más amplios (por ejemplo, reducción del estigma, impacto positivo en otras enfermedades, efectos secundarios más allá de los pacientes/beneficiarios) asociados con la intervención, favorecen la intervención o la comparación?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Cuanto mayor sea el beneficio neto para la salud asociado con una intervención, mayor será la probabilidad de una recomendación general a favorezca la intervención.",
          "options": [
            {
              "text": "Favorece la comparación",
              "value": "favors_the_comparison"
            },
            {
              "text": "Probablemente favorece la comparación",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "No favorece ni la intervención ni la comparación",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Probablemente favorece la intervención",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "Favorece la intervención",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "Ningún estudio incluído",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Cuál es certeza general de la evidencia sobre efectos del test?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "¿Qué tan buena indicación proporciona la investigación sobre los efectos probables en todos los enlaces críticos; es decir, la probabilidad que los efectos sean suficientemente diferentes de lo encontrado por la búsqueda, que pueda afectar una decisión sobre la intervención diagnóstica? ",
          "options": [
            {
              "text": "Muy baja",
              "value": "very_low"
            },
            {
              "text": "Baja",
              "value": "low"
            },
            {
              "text": "Moderado",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "Ningún estudio incluído",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Cuál es la certeza general de la evidencia sobre efectos?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "¿Qué tan buena indicación provee la investigación sobre los efectos probables en todos los desenlaces críticos; i.e. la probabilidad que los efectos sean suficientemente diferentes de lo encontrado por la investigación para que puedan afectar una decisión sobre la intervención?",
          "options": [
            {
              "text": "Muy baja",
              "value": "very_low"
            },
            {
              "text": "Baja",
              "value": "low"
            },
            {
              "text": "Moderado",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "ratingDescription": "What is the overall certainty of the evidence of effects for each intervention?",
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "Ningún estudio incluído",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Cuál es la certeza general de la evidencia sobre los efectos del manejo clínico que es guiado por los resultados del test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Muy baja",
              "value": "very_low"
            },
            {
              "text": "Baja",
              "value": "low"
            },
            {
              "text": "Moderado",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "Ningún estudio incluído",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Cuál es la certeza de la evidencia sobre los recursos encesarios (costos)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿Se han identificado todos los elementos importantes de uso de recursos que puedan diferir entre las opciones consideradas?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Incertidumbre importante",
                    "value": "important"
                  },
                  {
                    "text": "Incertidumbre posiblemente importante",
                    "value": "probably_important"
                  },
                  {
                    "text": "Incertidumbre probablemente no importante",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "No hay incertidumbre importante",
                    "value": "no_important"
                  }
                ],
                "question": "¿Qué tan certero es el costo de los elementos de uso de recursos que difieren entre las opciones consideradas?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Variabilidad importante",
                    "value": "important"
                  },
                  {
                    "text": "Posiblemente hay variabilidad importante",
                    "value": "probably_important"
                  },
                  {
                    "text": "Probablemente no hay variabilidad importante",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "No hay variabilidad importante",
                    "value": "no_important"
                  }
                ],
                "question": "¿Hay variabilidad importante en el costo de los elementos de uso de recursos que pueda diferir entre las opciones consideradas?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "¿Qué tan certera es la evidencia de una diferencia para cada tipo de uso de recursos (p.e. medicamentos, hospitalizaciones) y para el costo de los recursos?",
          "options": [
            {
              "text": "Muy baja",
              "value": "very_low"
            },
            {
              "text": "Baja",
              "value": "low"
            },
            {
              "text": "Moderado",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "Ningún estudio incluído",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Cuál es la certeza general de la evidencia sobre la precisión del test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Muy baja",
              "value": "very_low"
            },
            {
              "text": "Baja",
              "value": "low"
            },
            {
              "text": "Moderado",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "Ningún estudio incluído",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Cuál es la certeza general de la evidencia para desenlaces críticos o importante al tratarse de beneficios directos, efectos adversos o carga del test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Muy baja",
              "value": "very_low"
            },
            {
              "text": "Bajo",
              "value": "low"
            },
            {
              "text": "Moderado",
              "value": "moderate"
            },
            {
              "text": "Alto",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "Ningún estudio incluído",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Qué tan certera es la asociación entre los resultados del test y las decisiones de manejo clínico?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Muy baja",
              "value": "very_low"
            },
            {
              "text": "Baja",
              "value": "low"
            },
            {
              "text": "Moderado",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "Ningún estudio incluído",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿La costo-efectividad de la intervención beneficia la intervención o la comparación?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Ningún estudio incluído",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿La razón de costo-efectividad es susceptible de análisis de sensibilidad de una vía?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ningún estudio incluído",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿La razón de costo-efectividad es susceptible a un análisis de sensibilidad multi-variable?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ningún estudio incluído",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Limitaciones muy serias",
                    "value": "very_serious"
                  },
                  {
                    "text": "Limitaciones graves",
                    "value": "serious"
                  },
                  {
                    "text": "No hay limitaciones graves",
                    "value": "no_serious"
                  }
                ],
                "question": "¿La evaluación económica en la que se basa el estimado de costo-efectividad es confiable?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ningún estudio incluído",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Limitaciones muy graves",
                    "value": "very_serious"
                  },
                  {
                    "text": "Limitaciones graves",
                    "value": "serious"
                  },
                  {
                    "text": "No hay limitaciones graves",
                    "value": "no_serious"
                  }
                ],
                "question": "¿La evaluación económica en la que está basada el estimado de costo-efectividad es aplicable al/los escenario(s) de interés?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "¿La intervención es costo-efectiva, teniendo en cuenta la incertidumbre acerca de o variabilidad de los costos y beneficio neto, análisis de sensibilidad, y la confianza y aplicabilidad de la evaluación económica? ",
          "options": [
            {
              "text": "Favorece la comparación",
              "value": "favors_comparison"
            },
            {
              "text": "Probablemente favorece la comparación",
              "value": "probably_favors_comparison"
            },
            {
              "text": "No favorece la intervención ni la comparación",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Probablemente favorece la intervención",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Favorece la intervención",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "¿Qué intervención favorece la costo-efectividad?",
          "ratingOptions": [
            "best_cost_effectiveness",
            "intermediate_cost_effectiveness",
            "worst_cost_effectiveness"
          ],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Qué tan significativos son los efectos deseables anticipados?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Trivial",
                    "value": "trivial"
                  },
                  {
                    "text": "Pequeño",
                    "value": "small"
                  },
                  {
                    "text": "Moderado",
                    "value": "moderate"
                  },
                  {
                    "text": "Grande",
                    "value": "large"
                  }
                ],
                "question": "¿Qué tan significativo es el efecto anticipado (diferencia) para cada desenlace principal para el que haya un efecto deseado?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "¿Qué tan grandes son los efectos indeseables de la intervención teniendo en cuenta la importancia de los desenlaces (qué tanto son valorados), y el tamaño del efecto (la probabilidad de experimentar un beneficio o que tanta mejoría podrían llegar a experimentar los individuos más probablemente)?",
          "options": [
            {
              "text": "Trivial",
              "value": "trivial"
            },
            {
              "text": "Pequeño",
              "value": "small"
            },
            {
              "text": "Moderado",
              "value": "moderate"
            },
            {
              "text": "Grande",
              "value": "large"
            }
          ],
          "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
          "ratingOptions": [
            "most_effective",
            "intermediate_effectiveness",
            "least_effective"
          ],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Cuál sería el impacto en equidad en salud?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "¿Hay grupos o escenarios que puedan estar en desventaja en relación al problema u opciones que están siendo consideradas?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "¿Hay razones plausibles para anticipar diferencias en la efectividad relativa de la opción para escenarios y grupos perjudicados?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "¿Existen condiciones de base diferentes entre los grupos o escenarios que afecten la efectividad absoluta de la opción o la importancia del problema para escenarios o grupos perjudicados?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No ",
                    "value": "no"
                  }
                ],
                "question": "¿Hay consideraciones importantes que deban hacerse al implementar la intervención (opción) para asegurar que las desigualdades se reduzcan, si es posible, y que no aumenten?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "¿Hay razones plausibles para anticipar diferencias en la efectividad relativa de la intervención para grupos perjudicados o condiciones de base diferentes en subgrupos perjudicados que afecten la efectividad absoluta de la intervención o la importancia del problema?",
          "options": [
            {
              "text": "Reducido",
              "value": "reduced"
            },
            {
              "text": "Probablemente reducido",
              "value": "probably_reduced"
            },
            {
              "text": "Probablemente ningún impacto",
              "value": "probably_no_impact"
            },
            {
              "text": "Probablemente aumentado",
              "value": "probably_increased"
            },
            {
              "text": "Aumentado",
              "value": "increased"
            }
          ],
          "ratingDescription": "Si se recomienda, ¿Qué intervención reduciría más las inequidades en salud?",
          "ratingOptions": [
            "most_reduction",
            "intermediate_reduction",
            "less_reduction"
          ],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Es factible implementar la intervención?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿La intervención (opción) es sostenible?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No ",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿Existen barreras importantes que puedan limitar la factibilidad de implementar la intervención (opción) o que requieran consideración al implementarla?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "¿Es factible sostener el uso de la intervención diagnóstica y abordar las barreras potenciales de usarla?",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Sí",
              "value": "yes"
            }
          ],
          "ratingDescription": "¿Qué intervención es más factible de implementar?",
          "ratingOptions": [
            "most_feasible",
            "intermediate_feasibility",
            "least_feasible"
          ],
          "selectedOption": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Es factible de implementar la intervención?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "¿Existen <strong>barreras legales</strong> que puedan limitar la viabilidad de implementar la intervención? ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Sí",
                    "value": "yes"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "No",
                    "value": "no"
                  }
                ],
                "question": "¿Existen <strong>aspectos de gobernanza</strong> (por ejemplo, consideraciones estratégicas, decisiones pasadas) que puedan limitar la viabilidad de implementar la intervención? (Esto debe incluir consideraciones con respecto a la presencia o ausencia de instituciones formales o de información que puedan proporcionar liderazgo, supervisión y responsabilidad efectiva en la viabilidad de la implementación de la intervención)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Implicancias beneficiosas grandes",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Implicancias beneficiosas moderadas",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Implicancias beneficiosas y adversas insignificantes ",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Implicancias adversas moderadas",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Implicancias adversas grandes",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "¿Cuáles son las <strong>implicancias</strong> de la <strong>interacción y adecuación de la intervención con el sistema de salud existente?</strong> (Esto incluye consideraciones sobre la interacción o el impacto de la intervención en el sistema de salud existente y sus componentes)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Implicancias beneficiosas grandes",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Implicancias beneficiosas moderadas",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Implicancias beneficiosas y adversas insignificantes ",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Implicancias adversas moderadas",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Implicancias adversas grandes",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "¿Cuáles son las <strong>implicancias</strong> de la intervención <strong>para el personal sanitario y los recursos humanos en general </strong>? (en el sector de la salud u otros sectores) (Esto debe incluir consideraciones sobre la necesidad del uso de y el impacto en la fuerza laboral de salud y otros recursos humanos, así como su distribución) ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Implicancias beneficiosas grandes",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Implicancias beneficiosas moderadas",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Implicancias beneficiosas y adversas insignificantes ",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Implicancias adversas moderadas",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Implicancias adversas grandes",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "¿Cuáles son las <strong>implicancias</strong> de la intervención <strong>para la infraestructura del sistema de salud y la infraestructura en general</strong>? (Esto debe incluir consideraciones sobre la necesidad, el uso y el impacto en los recursos no humanos y la infraestructura, así como su distribución).",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Cuanto mayor sea la viabilidad de una opción desde la perspectiva de todas o la mayoría de las partes interesadas, mayor será la probabilidad de que una recomendación general favorezca a la intervención. Cuanto más ventajosas sean las implicancias para el sistema de salud en su conjunto, mayor será la probabilidad de que una recomendación general favorezca la intervención. ",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probablemente no",
              "value": "probably_not"
            },
            {
              "text": "Incierto",
              "value": "uncertain"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Sí",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "feasible": {
          "description": "to identify and measure, and not susceptible to manipulation",
          "options": [],
          "ratingOptions": [
            "very_feasible",
            "feasible",
            "least_feasible"
          ],
          "selectedOptions": ""
        },
        "financialAndEconomicConsiderations": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Las consideraciones financieras y económicas favorecen la intervención o la comparación?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "¿Los impactos de <strong>costo y presupuesto</strong> de implementación y mantener la intervención favorecen la intervención o la comparación? (Esto debe incluir consideraciones sobre cómo varían los impactos en el costo y el presupuesto a corto y largo plazo).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "¿El <strong> impacto global de la intervención en la economía </strong> favorece la intervención o la comparación? (Esto debe incluir consideraciones sobre cómo se distribuyen los diferentes tipos de impacto económico entre los diferentes sectores o niveles organizacionales, si la intervención contribuye o limita el logro de metas más amplias de desarrollo y reducción de la pobreza, y cómo impacta la fuerza laboral disponible).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "¿La <strong>proporción de costos y beneficios </strong> (por ejemplo, basada en estimaciones de costo-efectividad, costo-beneficio o costo-utilidad), favorece la intervención o la comparación?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Cuanto más ventajosas sean las implicaciones financieras y económicas de una intervención, mayor será la probabilidad de una recomendación general a favor de esta intervención. ",
          "options": [
            {
              "text": "Favorece la comparación",
              "value": "favors_the_comparison"
            },
            {
              "text": "Probablemente favorece la comparación",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "No favorece ni la intervención ni la comparación",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Probablemente favorece la intervención",
              "value": "probably_favor_the_intervention"
            },
            {
              "text": "Favorece la intervención",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Cuál sería el impacto de la intervención en la equidad, igualdad y no discriminación en salud?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿Es probable que la intervención aumente las desigualdades existentes y/o inequidades en la condición de salud o sus determinantes? (Esto debe incluir consideraciones de posibles cambios en las desigualdades a lo largo del tiempo, por ejemplo, si es probable que los incrementos iniciales se equilibren con el tiempo, a medida que se amplía la intervención). ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿Es probable que los beneficios y daños de la intervención se distribuyan de manera equitativa? (Esto debe incluir un enfoque especial en las implicancias para los grupos de poblaciones vulnerables, marginados o socialmente desfavorecidos). ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿Es la intervención asequible entre los grupos de población afectados y, por tanto, económicamente accesible? (Esto debe incluir el impacto en los gastos de salud de los hogares, incluido el riesgo de gastos de salud catastróficos y riesgos financieros relacionados con la salud). ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿La intervención es accesible entre los grupos de población afectados? (Esto debe incluir consideraciones con respecto al acceso físico e informativo de la intervención). ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿La intervención aborda una condición particularmente grave (por ejemplo, que amenaza la vida, al final de la vida, que afecta a personas con un estado de salud preexistente) o rara? ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿Representa la intervención la única opción disponible? (Esto debe incluir consideraciones sobre si la intervención es proporcional a la necesidad y si estará sujeta a revisión periódica). ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Cuanto mayor sea la probabilidad de que la intervención aumente la equidad y/o igualdad en salud y de que reduzca la discriminación contra un grupo en particular, mayor será la probabilidad de que una recomendación general favorezca esta intervención.",
          "options": [
            {
              "text": "Negativo",
              "value": "negative"
            },
            {
              "text": "Probablemente negativo",
              "value": "probably_negative"
            },
            {
              "text": "Ni negativo ni positivo",
              "value": "neither_negative_nor_positive"
            },
            {
              "text": "Probablemente positivo",
              "value": "probably_positive"
            },
            {
              "text": "Positivo",
              "value": "positive"
            }
          ],
          "selectedOption": ""
        },
        "humanRights": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿La intervención se ajusta a las normas y principios universales de derechos humanos?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Todas las recomendaciones deben acordarse con las normas y principios universales de derechos humanos.",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "Incierto",
              "value": "uncertain"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Sí",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problem": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿El problema es una prioridad?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no ",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿Las consecuencias del problema son graves (severas o importantes en términos de los beneficios o ahorros potenciales)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿el problema es urgente?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "No",
                    "value": "no"
                  },
                  {
                    "text": "Probablemente no",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablemente sí",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Sí",
                    "value": "yes"
                  }
                ],
                "question": "¿Es una prioridad reconocida (p.e. basada en una decisión política o alguno norma)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Entre más serio y más urgente sea un problema, más probable es que una opción que aborda el problema sea una prioridad",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Sí",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "qualityOfEvidence": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Cuál es la calidad general de la evidencia?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Cuanto mayor sea la calidad de la evidencia a través de diferentes criterios en el marco de referencia WHO-INTEGRATE, mayor será la probabilidad de una recomendación general.",
          "options": [
            {
              "text": "Muy baja",
              "value": "very_low"
            },
            {
              "text": "Baja",
              "value": "low"
            },
            {
              "text": "Moderada",
              "value": "moderate"
            },
            {
              "text": "Alta",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "relevantDirect": {
          "description": "to the selected problem and in the field of application",
          "options": [],
          "ratingOptions": [
            "very_relevant",
            "relevant",
            "least_relevant"
          ],
          "selectedOptions": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Qué tan grandes son los recursos necesarios (costos)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Trivial",
                    "value": "trivial"
                  },
                  {
                    "text": "Pequeño",
                    "value": "small"
                  },
                  {
                    "text": "Moderado",
                    "value": "moderate"
                  },
                  {
                    "text": "Grande",
                    "value": "large"
                  }
                ],
                "question": "¿Qué tan grande es la diferencia para cada elemento de recursos en los que se requieren menos recursos?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grande",
                    "value": "large"
                  },
                  {
                    "text": "Moderado",
                    "value": "moderate"
                  },
                  {
                    "text": "Pequeña",
                    "value": "small"
                  },
                  {
                    "text": "Trivial",
                    "value": "trivial"
                  }
                ],
                "question": "¿Qué tan grande es la diferencia en cada elemento de uso de recursos para los que se requieren más recursos?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "¿Qué tan grande es el costo de la diferencia en uso de recursos entre la intervención y la comparación?",
          "options": [
            {
              "text": "Costos extensos",
              "value": "large_costs"
            },
            {
              "text": "Costos moderados",
              "value": "moderate_costs"
            },
            {
              "text": "Costos y ahorros despreciables",
              "value": "negligible_costs_savings"
            },
            {
              "text": "Ahorros moderados",
              "value": "moderate_savings"
            },
            {
              "text": "Ahorros extensos",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
          "ratingOptions": [
            "less_costs",
            "intermediate_costs",
            "most_costs"
          ],
          "selectedOption": ""
        },
        "responsive": {
          "description": "sensitive to change that means they should be able to capture possible changes in the system",
          "options": [],
          "ratingOptions": [
            "very_responsive",
            "responsive",
            "least_responsive"
          ],
          "selectedOptions": ""
        },
        "scientificallySound": {
          "description": "based on evidence (GRADE criteria) and strongly correlated with the quality of care provided",
          "options": [],
          "ratingOptions": [
            "very_scientifically_sound",
            "scientifically_sound",
            "least_scientifically_sound"
          ],
          "selectedOptions": ""
        },
        "societalImplications": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿El balance entre las implicancias sociales deseables e indeseables favorecen a la intervención o la comparación?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "El impacto social y las consecuencias sociales de la intervención (como el aumento o la reducción del estigma, los resultados educativos, la cohesión social o la consecución de varios derechos humanos más allá de la salud) ¿favorecen a la intervención o a la comparación? ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "El impacto ambiental y las implicancias para la sustentabilidad ecológica (por ejemplo, protección de los recursos naturales, mitigación o adaptación al cambio climático) ¿favorecen a la intervención o a la comparación? ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Cuanto mayor sea el beneficio social neto asociado con una intervención, mayor será la probabilidad de una recomendación general favorezca esta intervención.",
          "options": [
            {
              "text": "Favorece la comparación",
              "value": "favors_the_comparison"
            },
            {
              "text": "Probablemente favorece la comparación",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "No favorece ni a la intervención ni a la comparación",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Probablemente favorece la intervención",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "Favorece la intervención",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "socioCulturalAcceptability": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿La intervención es aceptable para las partes interesadas?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grande",
                    "value": "large"
                  },
                  {
                    "text": "Moderada",
                    "value": "moderate"
                  },
                  {
                    "text": "Pequeña",
                    "value": "small"
                  },
                  {
                    "text": "Trivial",
                    "value": "trivial"
                  }
                ],
                "question": "¿Qué tan sustancial es la intromisión de la intervención en términos de infringir las libertades individuales (incluida la privacidad y la dignidad)? (La intrusión varía de trivial – por ejemplo, al permitir la elección (ej., construir ciclovías) a alta – por ejemplo, al restringir o eliminar la elección (ej., prohibir los cigarrillos)). ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grande",
                    "value": "large"
                  },
                  {
                    "text": "Moderada",
                    "value": "moderate"
                  },
                  {
                    "text": "Pequeña",
                    "value": "small"
                  },
                  {
                    "text": "Trivial",
                    "value": "trivial"
                  }
                ],
                "question": "¿Cuán sustancial es el impacto de la intervención en la autonomía de los individuos, grupos poblacionales y/u organizaciones (en cuanto a su capacidad para tomar una decisión competente, informada y voluntaria)? ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "¿La aceptabilidad sociocultural de la intervención entre los beneficiarios previstos favorece a la intervención o a la comparación? ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "La aceptabilidad sociocultural de la intervención entre los destinados a implementar la intervención ¿favorece a la intervención, o a la comparación?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "La aceptabilidad sociocultural de la intervención entre otros grupos relevantes de partes interesadas ¿favorece a la intervención o a la comparación? ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorece la comparación",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la comparación",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "No favorece ni la intervención ni la comparación",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Probablemente favorece la intervención",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorece la intervención",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "La aceptabilidad sociocultural de la intervención entre el público en general ¿favorece a la intervención o a la comparación? ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Cuanto mayor sea la aceptabilidad sociocultural de una intervención para todos o a las partes interesadas más relevantes, mayor será la probabilidad de que una recomendación general favorezca esta intervención. ",
          "options": [
            {
              "text": "No",
              "value": "no"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "Incierto ",
              "value": "uncertain"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Sí",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Qué tan precisa es el test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Muy imprecisa",
              "value": "very_inaccurate"
            },
            {
              "text": "Imprecisa",
              "value": "inaccurate"
            },
            {
              "text": "Precisa",
              "value": "accurate"
            },
            {
              "text": "Muy precisa",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": "Varía",
              "value": "varies"
            },
            {
              "text": "No lo sé",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Qué tan significativos son los efectos indeseables anticipados?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varía",
                    "value": "varies"
                  },
                  {
                    "text": "No lo sé",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Grande",
                    "value": "large"
                  },
                  {
                    "text": "Moderado",
                    "value": "moderate"
                  },
                  {
                    "text": "Pequeña",
                    "value": "small"
                  },
                  {
                    "text": "Trivial",
                    "value": "trivial"
                  }
                ],
                "question": "¿Qué tan significativo es el efecto (diferencia) anticipado para cada desenlace principal para el que hay un efecto indeseable?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "¿Qué tan grandes son los efectos indeseables de la intervención, teniendo en cuenta la importancia de los desenlaces (que tan valorados son), y el tamaño del efecto (la probabilidad de experimentar un beneficio o qué tanta mejoría pueden llegar a experimentar los individuos)? ",
          "options": [
            {
              "text": "Grande",
              "value": "large"
            },
            {
              "text": "Moderado",
              "value": "moderate"
            },
            {
              "text": "Pequeña",
              "value": "small"
            },
            {
              "text": "Trivial",
              "value": "trivial"
            }
          ],
          "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
          "ratingOptions": [
            "least_harmful",
            "intermediate",
            "more_harmful"
          ],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "¿Hay incertidumbre importante o variabilidad sobre qué tanto valora la gente los desenlaces principales?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "Incertidumbre importante",
                    "value": "important"
                  },
                  {
                    "text": "Posiblemente incertidumbre importante",
                    "value": "probably_important"
                  },
                  {
                    "text": "Probablemente no hay incertidumbre importante",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "No hay incertidumbre importante",
                    "value": "no_important"
                  }
                ],
                "question": "¿Hay incertidumbre importante respecto a cuánta gente valora los desenlaces principales?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "¿Los individuos, qué tanto valoran cada uno de los resultados principales? ¿Hay suficiente incertidumbre en este respecto o variabilidad en la diferencia de valoraciones de los desenlaces por parte de individuos que podría llevar a decisiones diferentes?",
          "options": [
            {
              "text": "Hay incertidumbre o variabilidad importantes",
              "value": "important_uncertainty"
            },
            {
              "text": "Posiblemente hay incertidumbre o variabilidad importantes",
              "value": "possible_important"
            },
            {
              "text": "Probablemente no hay incertidumbre ni variabilidad importantes ",
              "value": "probably_no_important"
            },
            {
              "text": "No hay hay variabilidad o incertidumbre importante",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "Aceptabilidad",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "Otros criterios",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "Balance de efectos",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
          "criterionId": "balanceOfHealthBenefitsAndHarms",
          "name": "Beneficios y daños",
          "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "Certeza de los efectos",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "Certeza de la evidencia",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "Certeza de la evidencia de efectos del manejo",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "Certeza de la evidencia de recursos necesarios",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "Certeza sobre la evidencia de la precisión del test",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "Certeza de la evidencia de efectos del test",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "Certeza de la evidencia del resultado del test/manejo clínico",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "Costo-efectividad",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "Efectos deseables",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "Equidad",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "Viabilidad",
          "researchEvidenceId": "feasibility"
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
          "criterionId": "feasibilityAndHealthSystemConsiderations",
          "name": "Consideraciones de factibilidad y del sistema de salud",
          "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
        },
        "feasible": {
          "additionalConsiderationId": "feasible",
          "criterionId": "feasible",
          "name": "FEASIBLE",
          "researchEvidenceId": "feasible"
        },
        "financialAndEconomicConsiderations": {
          "additionalConsiderationId": "financialAndEconomicConsiderations",
          "criterionId": "financialAndEconomicConsiderations",
          "name": "Consideraciones financieras y económicas",
          "researchEvidenceId": "financialAndEconomicConsiderations"
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
          "criterionId": "healthEquityEqualityAndNonDiscrimination",
          "name": "Equidad",
          "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
        },
        "humanRights": {
          "additionalConsiderationId": "humanRights",
          "criterionId": "humanRights",
          "name": "Derechos humanos",
          "researchEvidenceId": "humanRights"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "¿el problema es grave? ",
          "researchEvidenceId": "problem"
        },
        "qualityOfEvidence": {
          "additionalConsiderationId": "qualityOfEvidence",
          "criterionId": "qualityOfEvidence",
          "name": "Calidad de la evidencia ",
          "researchEvidenceId": "qualityOfEvidence"
        },
        "relevantDirect": {
          "additionalConsiderationId": "relevantDirect",
          "criterionId": "relevantDirect",
          "name": "RELEVANT (DIRECT)",
          "researchEvidenceId": "relevantDirect"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "Recursos necesarios",
          "researchEvidenceId": "resourcesRequired"
        },
        "responsive": {
          "additionalConsiderationId": "responsive",
          "criterionId": "responsive",
          "name": "RESPONSIVE",
          "researchEvidenceId": "responsive"
        },
        "scientificallySound": {
          "additionalConsiderationId": "scientificallySound",
          "criterionId": "scientificallySound",
          "name": "SCIENTIFICALLY SOUND (CERTAINTY OF EVIDENCE)",
          "researchEvidenceId": "scientificallySound"
        },
        "societalImplications": {
          "additionalConsiderationId": "societalImplications",
          "criterionId": "societalImplications",
          "name": "Implicancias sociales",
          "researchEvidenceId": "societalImplications"
        },
        "socioCulturalAcceptability": {
          "additionalConsiderationId": "socioCulturalAcceptability",
          "criterionId": "socioCulturalAcceptability",
          "name": "Aceptabilidad socio-cultural",
          "researchEvidenceId": "socioCulturalAcceptability"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": "Precisión del test",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "Efectos indeseables",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "Valores",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "benefits": {
          "content": "",
          "description": "Beneficios"
        },
        "clearAndActionable": {
          "content": "",
          "description": "Claro y procesable",
          "options": [
            {
              "text": "Sí",
              "value": "yes"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "clinicalConsiderations": {
          "content": "",
          "description": "Clinical considerations"
        },
        "collectingAndSummarizing": {
          "content": "",
          "description": "Recopilar y resumir esta evidencia es un mal uso de la energía y tiempo limitado del panel de la guía clínica (costo de oportunidad en general)",
          "options": [
            {
              "text": "Sí",
              "value": "yes"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "considerations": {
          "content": "",
          "description": "Consideraciones"
        },
        "contextAndSystemConsiderations": {
          "content": "",
          "description": "Consideraciones de contexto y sistema"
        },
        "decision": {
          "content": "",
          "description": "Decisión",
          "options": [
            {
              "text": "Implementación completa",
              "value": "full_implementation"
            },
            {
              "text": "Evaluación de impacto",
              "value": "impact_evaluation"
            },
            {
              "text": "Estudio piloto",
              "value": "pilot_study"
            },
            {
              "text": "Posponer",
              "value": "postpone"
            },
            {
              "text": "No implementar",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "Tipo de decisión",
          "options": [
            {
              "direction": "negative",
              "text": "No cubrir",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "Cubrir con desarrollo de evidencia",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "Cubrir con negociación de precio",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "Cubrimiento restringido",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "Cubrir",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "Tipo de decisión",
          "options": [
            {
              "direction": "negative",
              "text": "No implementar la opción",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "Posponer la decisión",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "Conducir un estudio piloto de la opción",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "Implementar la opción con una evaluación de impacto",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "Implementar la opción",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "evidence": {
          "content": "",
          "description": "Evidencia "
        },
        "frequencyDoseDuration": {
          "content": "",
          "description": "Frecuencia/dosis/duración"
        },
        "goodPracticeStatement": {
          "content": "",
          "description": "Declaración de buenas prácticas"
        },
        "harms": {
          "content": "",
          "description": "Daños"
        },
        "implementationConsiderations": {
          "content": "",
          "description": "Consideraciones de implementación",
          "details": {
            "content": "",
            "description": "Implementación detallada"
          }
        },
        "implementationConsiderationsOfAllRelevantOutcomes": {
          "content": "",
          "description": "Después de considerar todos los desenlaces relevantes y las posibles consecuencias posteriores, la implementación de la declaración de buenas prácticas da como resultado una gran cantidad de consecuencias positivas netas",
          "options": [
            {
              "text": "Sí",
              "value": "yes"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderationsOrRemarks": {
          "content": "",
          "description": "Consideraciones de implementación",
          "options": [
            {
              "text": "Sí",
              "value": "yes"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "indications": {
          "content": "",
          "description": "Indicaciones"
        },
        "indicationsForDiscontinuation": {
          "content": "",
          "description": "Indicaciones para discontinuar"
        },
        "justification": {
          "content": "",
          "description": "Justificación",
          "details": {
            "description": "Justificación detallada"
          },
          "overallDescription": "Justificación general "
        },
        "keyMessage": {
          "content": "",
          "description": "Mensajes clave"
        },
        "limitations": {
          "content": "",
          "description": "Limitaciones"
        },
        "mdgRecommendation": {
          "content": "",
          "description": "Recomendación",
          "options": [
            {
              "direction": "positive",
              "text": "recomendado",
              "value": "recommend"
            },
            {
              "direction": "negative",
              "text": "No recomendado",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Sometimes Recommended",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Sin recomendación",
              "value": "suggest_either"
            }
          ],
          "selectedOption": ""
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "Monitoreo y evaluación",
          "details": {
            "content": "",
            "description": "Supervisión y evaluación detalladas"
          }
        },
        "monitoringAndEvaluationQI": {
          "content": "",
          "description": "Monitoreo y evaluación"
        },
        "multipleChronicConditionsAndPolypharmacy": {
          "content": "",
          "description": "Múltiples condiciones crónicas y polifarmacia"
        },
        "multipleRecommendations": {
          "description": "Recomendación(es)",
          "options": [
            {
              "direction": "negative",
              "text": "Recomendación fuerte en contra de la intervención",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Recomendación condicional en contra de la intervención",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Recomendación condicional a favor de la intervención o la comparación",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Recomendación condicional a favor de la intervención",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Recomendación fuerte a favor de la intervención",
              "value": "recommend"
            }
          ],
          "recommendations": [
            {
              "content": "",
              "selectedOption": ""
            }
          ]
        },
        "rationale": {
          "content": "",
          "description": "Razón-base lógica-"
        },
        "rationaleConnectingIndirectEvidence": {
          "content": "",
          "description": "Existe una racionalidad clara y explícita bien documentada que conecta la evidencia indirecta",
          "options": [
            {
              "text": "Sí",
              "value": "yes"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "recommendation": {
          "content": "",
          "description": "Recomendación"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "Tipo de recomendación",
          "options": [
            {
              "direction": "negative",
              "text": "Recomendación fuerte en contra de la intervención",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Recomendación condicional en contra de la intervención",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Recomendación condicional a favor de la intervención o la comparación",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Recomendación condicional a favor de la intervención",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Recomendación fuerte a favor de la intervención",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "Tipo de recomendación",
          "options": [
            {
              "direction": "negative",
              "text": "Recomendación fuerte en contra de la opción",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Recomendación condicional en contra de la opción",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Recomendación condicional a favor de la opción o la comparación",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Recomendación condicional a favor de la opción",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Recomendación fuerte a favor de la opción",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "Prioridades de investigación"
        },
        "restrictions": {
          "content": "",
          "description": "Restricciones"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "Consideraciones del subgrupo"
        },
        "theMessageIsNecessary": {
          "content": "",
          "description": "El mensaje es necesario con respecto a la práctica real de la atención en salud",
          "options": [
            {
              "text": "Sí",
              "value": "yes"
            },
            {
              "text": "Probablemente sí",
              "value": "probably_yes"
            },
            {
              "text": "Probablemente no",
              "value": "probably_no"
            },
            {
              "text": "No",
              "value": "no"
            }
          ],
          "selectedOption": ""
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "Clínicos",
          "sections": {
            "background": {
              "name": "Contexto"
            },
            "detailedJustification": {
              "name": "Justificación detallada"
            },
            "implementationConsiderations": {
              "name": "Consideraciones de implementación"
            },
            "justification": {
              "name": "Justificación"
            },
            "relatedRecommendations": {
              "name": "Recomendaciones relacionadas"
            },
            "subgroupConsiderations": {
              "name": "Consideraciones del subgrupo"
            },
            "summaryOfFindings": {
              "name": "Resumen de hallazgos"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "Pacientes",
          "sections": {
            "relatedRecommendations": {
              "name": "Recomendaciones relacionadas"
            },
            "summaryOfFindings": {
              "name": "SoF"
            },
            "whatItMeansForYou": {
              "name": "¿Qué significa esto para ti?",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Hable con el profesional de la salud encargado de su cuidado"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "Lo que puedes hacer"
                }
              }
            },
            "whoIsThisFor": {
              "name": "¿Para quién es esto?",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Para quién es esto"
                }
              }
            },
            "whyThisRecommendation": {
              "name": "¿Por qué esta recomendación?",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "Información adicional"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "Beneficios y daños"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "Por qué esta recomendación"
                }
              }
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "Creadores de políticas públicas",
          "sections": {
            "assessment": {
              "name": "Evaluación"
            },
            "background": {
              "description": "Detalles de la pregunta para recomendación",
              "details": {
                "name": "Acerca de esta decisión",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "Fecha"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "Tomadores de decisiones"
                  },
                  "perspective": {
                    "content": "",
                    "description": "Perspectiva"
                  },
                  "setting": {
                    "content": "",
                    "description": "Escenario"
                  }
                },
                "sectionsOrder": [
                  "setting",
                  "perspective",
                  "decisionMakers",
                  "date"
                ]
              },
              "name": "Contexto"
            },
            "decision": {
              "content": "",
              "name": "Decisión",
              "options": [
                {
                  "text": "Implementación completa",
                  "value": "full_implementation"
                },
                {
                  "text": "Evaluación del impacto",
                  "value": "impact_evaluation"
                },
                {
                  "text": "Estudio piloto",
                  "value": "pilot_study"
                },
                {
                  "text": "Posponer",
                  "value": "postpone"
                },
                {
                  "text": "No implementar",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "Implementación"
            },
            "justification": {
              "name": "Justificación"
            },
            "monitoringAndEvaluation": {
              "name": "Supervisión y Evaluación"
            },
            "relatedRecommendations": {
              "name": "Recomendaciones relacionadas"
            },
            "summaryOfFindings": {
              "name": "Resumen de hallazgos"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "age": {
          "content": "",
          "name": "Edad"
        },
        "anticipatedOutcomes": {
          "content": "",
          "name": "Desenlaces anticipados"
        },
        "background": {
          "content": "",
          "name": "Contexto"
        },
        "coi": {
          "content": "",
          "name": "Conflictos de interés"
        },
        "comparison": {
          "content": "",
          "name": "Comparación"
        },
        "intent": {
          "content": "",
          "name": "Intención"
        },
        "intervention": {
          "content": "",
          "name": "Intervención"
        },
        "linkedTreatments": {
          "content": "",
          "name": "Tratamientos asociados"
        },
        "mainOutcomes": {
          "content": "",
          "name": "Desenlaces principales"
        },
        "option": {
          "content": "",
          "name": "Opción"
        },
        "perspective": {
          "content": "",
          "name": "Perspectiva"
        },
        "population": {
          "content": "",
          "name": "Población"
        },
        "problem": {
          "content": "",
          "name": "Problema"
        },
        "purpose": {
          "content": "",
          "name": "Propósito del test"
        },
        "requirements": {
          "content": "",
          "name": "Requirements"
        },
        "role": {
          "content": "",
          "name": "Rol del test"
        },
        "setting": {
          "content": "",
          "name": "Escenario"
        },
        "subgroups": {
          "content": "",
          "name": "Subgrupos"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
