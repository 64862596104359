IconButton = require 'components/common/icon_button'
TextAreaWithApply = require 'components/common/text_area_with_apply'
{ useI18n } = require 'lib/react_utils'

EMPTY_PLR = {
  text: "",
  link: ""
}

PlainLanguageLinksField = ({ onChange, value: plrs }) ->

  i18n = useI18n('es:recommendations.metadataExtractionForm')

  onTextAreaApply = (index, key) -> (value) ->
    updatedPlr = plrs[index]
    updatedPlr[key] = value
    onChange([plrs.slice(0, index)..., updatedPlr, plrs.slice(index + 1)...])

  onAddPlr = ->
    onChange([plrs..., _.clone(EMPTY_PLR)])

  onDeletePlr = (index) -> ->
    onChange([plrs.slice(0, index)..., plrs.slice(index + 1)...])

  linkPlaceholder = i18n('placeholders.plainLanguageSummaryUrl')
  textfieldPlaceholder = i18n('placeholders.plainLanguageSummaryText')

  <div className="multiple-inputs">
    {_.map plrs, ({ text, link }, index) ->
      <div className="multiple-inputs__input" key={index}>
        <div className="flex-grow">
          <div className="input__textarea mb-5">
            <TextAreaWithApply
              content={text}
              onApply={onTextAreaApply(index, 'text')}
              placeholder={textfieldPlaceholder}
            />
          </div>
          <div className="input__textarea mb-5">
            <TextAreaWithApply
              content={link}
              onApply={onTextAreaApply(index, 'link')}
              placeholder={linkPlaceholder}
            />
          </div>
        </div>
        <div>
          <IconButton
            className="ml-10"
            iconName="remove"
            onClick={onDeletePlr(index)}
          />
        </div>
      </div>
    }
    <div className="multiple-inputs__add">
      <IconButton
        iconName="add"
        label={i18n '/actions.add_plain_language_summary'}
        labelPosition="right"
        onClick={onAddPlr}
      />
    </div>
  </div>

PlainLanguageLinksField.propTypes =
  onChange: PropTypes.func.isRequired
  value: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string
  }))

PlainLanguageLinksField.defaultProps =
  value: []

module.exports = PlainLanguageLinksField
