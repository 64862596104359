var Collection, DraftQuestion, DraftQuestions,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Collection = require('models/base/collection');

DraftQuestion = require('./draft_question');

module.exports = DraftQuestions = (function(_super) {
  __extends(DraftQuestions, _super);

  function DraftQuestions() {
    return DraftQuestions.__super__.constructor.apply(this, arguments);
  }

  DraftQuestions.prototype.model = DraftQuestion;

  return DraftQuestions;

})(Collection);
