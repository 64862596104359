Translation = require 'components/mixins/translation'
Validation = require 'base/validation'
mediator = require 'mediator'

PersonalInformation = createReactClass
  displayName: 'PersonalInformation'

  mixins: [Translation('account.personal_information')]

  getInitialState: ->
    editing: false
    errors: {}
    firstName: @props.user.get('firstName')
    lastName: @props.user.get('lastName')
    email: @props.user.get('email')

  attrChanged: (attr) -> (e) => @setState("#{attr}": e.target.value)

  inputOrSpan: (attr) ->
    if @state.editing
      <div>
        <input className="form-control" type="text" value={@state[attr]}
          onChange={@attrChanged(attr)} />
        <div className="error">{@state.errors[attr]}</div>
      </div>
    else
      <span className="value">{@state[attr]}</span>

  setFieldError: (prop, err) -> @setState (state) ->
    console.log(state.errors)
    errors: _(state.errors).extend("#{prop}": err)

  validateNonEmpty: (prop, value = @state[prop]?.trim()) ->
    return true if value
    @setFieldError(prop, @i18n("/errors:authentication.empty_#{prop}"))
    false

  validateEmail: ->
    return true if Validation.isValidEmail(@state.email)
    @setFieldError('email', @i18n("/errors:authentication.invalid_email"))
    false

  validateFields: ->
    _.every([
      @validateNonEmpty('firstName')
      @validateNonEmpty('lastName')
      # TODO: T1663 @validateEmail()
    ])

  submit: ->
    @setState(errors: {})
    return unless @validateFields()
    @setState(submitting: true)
    @props.service.changePersonalData(_.pick(@state, 'firstName', 'lastName'))
    .then => @setState(editing: false)
    .catch mediator.dialogs.fatalError
    .finally => @setState(submitting: false)

  toggleEdit: ->
    if @state.editing and not @state.submitting
      @submit()
    else
      @setState(editing: true)

  render: ->
    <div className="form form-horizontal">
      <div className="row">
          <h3>{@i18n('title')}</h3>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-3">
            <label>{@i18n('/registration.first_name')}</label>
          </div>
          <div className="col-9">
            {@inputOrSpan('firstName')}
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <label>{@i18n('/registration.last_name')}</label>
          </div>
          <div className="col-9">
            {@inputOrSpan('lastName')}
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <label>{@i18n('/registration.email')}</label>
          </div>
          <div className="col-9">
            <span className="value">{@state.email}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3 col-offset-9">
          <button className={classNames("btn btn-block", loading: @state.submitting)}
            onClick={@toggleEdit}>
            {@i18n("/actions.#{if @state.editing then 'save' else 'edit'}")}
          </button>
        </div>
      </div>
    </div>

module.exports = PersonalInformation
