DuplicatesGroup = require 'components/references/duplicates_group'
ReferencesActions = require 'actions/references_actions'
ReferencesStore = require 'stores/references_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
CreateStoreMixin = require 'components/mixins/create_store'
FetchingSpinner = require 'components/mixins/fetching_spinner'
Translation = require 'components/mixins/translation'

duplicatesTableColumns = [
  'authors'
  'title'
  'year'
  'publishedIn'
  'volume'
  'issue'
  'pages'
]

DuplicatesTable = createReactClass
  displayName: 'DuplicatesTable'

  mixins: [
    CustomRenderMixin
    CreateStoreMixin(ReferencesStore)
    FetchingSpinner
    Translation('references:columns')
  ]

  getStateFromStores: ->
    duplicateGroups: ReferencesStore.getDuplicatesGroups()
    isFetching: ReferencesStore.isFetching()

  keepDuplicates: (hashOrIds) ->
    duplicates = if _.isString hashOrIds
      @state.duplicateGroups.get hashOrIds
    else
      hashOrIds

    ReferencesActions.keepDuplicates duplicates

  mergeDuplicates: (hashOrIds) ->
    duplicates = if _.isString hashOrIds
      @state.duplicateGroups.get hashOrIds
    else
      hashOrIds

    ReferencesActions.mergeDuplicates duplicates

  _renderHeaderColumns: ->
    <tr>
      {duplicatesTableColumns.map (columnName, idx) =>
        <th className={columnName} key={idx}>{@i18n columnName}</th>
      }
    </tr>

  _renderDuplicateGroups: ->
    @state.duplicateGroups.map (duplicates, hash) =>
      <DuplicatesGroup
        key={hash}
        duplicates={duplicates}
        columns={duplicatesTableColumns}
        keepDuplicates={@keepDuplicates}
        mergeDuplicates={@mergeDuplicates}
        showDuplicationDetails={@showComparison}
        duplicatesHash={hash}
      />
    .valueSeq()

  componentWillReceiveProps: (nextProps) ->
    @setState duplicateGroups: nextProps.duplicateGroups

  renderFetched: ->
    <div className='duplicates'>
      {if @state.duplicateGroups.isEmpty()
        <p>{@i18n '../no_duplicates_text'}</p>
      else
        <div>
          <div className='header-table'>
            <table className='gdt-table'>
              <thead>{@_renderHeaderColumns()}</thead>
            </table>
          </div>
          <div className='group-tables' ref={(el) => @groupTable = el}>
            {@_renderDuplicateGroups()}
          </div>
        </div>
      }
    </div>

module.exports = DuplicatesTable
