var AccountView, ArchieRemovalDialogView, DbHelper, DiscardChangesView, DonationView, Ellipsis, FeedbackView, Focusable, HandbookView, HeaderProjectsListView, HeaderView, ImportDialogView, OrganizationsActions, PopupMenuView, Scrolling, SelectionView, UserMenuView, UserView, View, W, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

FeedbackView = require('views/feedback_view');

HandbookView = require('views/handbook_view');

UserView = require('views/user_view');

AccountView = require('views/account_view');

UserMenuView = require('views/user_menu_view');

HeaderProjectsListView = require('views/header_projects_list_view');

Scrolling = require('base/lib/traits/scrolling');

Ellipsis = require('base/lib/traits/ellipsis');

Focusable = require('base/lib/traits/focusable');

template = require('./templates/header');

mediator = require('mediator');

SelectionView = require('base/views/selection_view');

PopupMenuView = require('base/views/popup_menu_view');

ImportDialogView = require('views/import_dialog_view');

DiscardChangesView = require('views/discard_changes_view');

ArchieRemovalDialogView = require('views/archie/archie_removal_dialog_view');

DbHelper = require('base/lib/db_helper');

OrganizationsActions = require('actions/organizations_actions');

DonationView = require('views/donation_view');

W = require('when');

module.exports = HeaderView = (function(_super) {
  __extends(HeaderView, _super);

  function HeaderView() {
    this._updateConfirmationStatus = __bind(this._updateConfirmationStatus, this);
    this.hideSettings = __bind(this.hideSettings, this);
    return HeaderView.__super__.constructor.apply(this, arguments);
  }

  HeaderView.prototype.template = template;

  HeaderView.prototype.id = 'header';

  HeaderView.prototype.container = '#header-container';

  HeaderView.prototype.autoRender = true;

  HeaderView.prototype.messageDisplayed = {};

  HeaderView.prototype.initialize = function() {
    HeaderView.__super__.initialize.apply(this, arguments);
    this.subscribeEvent('loginStatus', this.render);
    this.subscribeEvent('projectChanged', this.render);
    this.delegate('click', 'button.settings', this.showSettings);
    this.delegate('click', 'button.feedback', this.showFeedback);
    this.delegate('click', 'button.help', this.showHelp);
    this.delegate('click', 'button.connection', this._showConnectionMenu);
    this.delegate('click', '#header-update-info a', this._handleCacheInvalidation);
    this.delegate('click', '#header-connection-timeout-warning a', this._reloadApplication);
    this.delegate('click', '#header-email-not-confirmed a', this._resendConfirmation);
    this.delegate('click', 'button.donate', this.showDonationDialog);
    this.enable(Ellipsis);
    this.enable(Focusable);
    this._setupConnectionStates();
    this.subscribeEvent('updateAvailable', this._updateAvailable);
    this.subscribeEvent('updateInstalled', this._displayUpdateInfos);
    this.subview('importDialogView', new ImportDialogView());
    this.subview('DiscardChangesView', new DiscardChangesView());
    this.subview('archieRemovalDialogView', new ArchieRemovalDialogView());
    return mediator.user.on('change:emailConfirmed', this._updateConfirmationStatus);
  };

  HeaderView.prototype._reloadApplication = function(e) {
    e.preventDefault();
    return window.location.reload();
  };

  HeaderView.prototype._handleCacheInvalidation = function(e) {
    e.preventDefault();
    return this._invalidateCache();
  };

  HeaderView.prototype._invalidateCache = function() {
    var reloadPage;
    reloadPage = function() {
      return window.location.reload();
    };
    window.sw.workboxInstance.addEventListener('controlling', reloadPage);
    return window.sw.workboxInstance.messageSkipWaiting();
  };

  HeaderView.prototype._updateAvailable = function() {
    return this._showHeaderMessage('update-info');
  };

  HeaderView.prototype._disconnected = function() {
    this._hideHeaderMessage('connection-timeout-warning');
    this._showHeaderMessage('disconnected-warning');
    return OrganizationsActions.disconnected();
  };

  HeaderView.prototype._connected = function() {
    this._hideHeaderMessage('disconnected-warning');
    this._hideHeaderMessage('connection-timeout-warning');
    return OrganizationsActions.connected();
  };

  HeaderView.prototype._connectionTimeout = function() {
    this._hideHeaderMessage('disconnected-warning');
    return this._showHeaderMessage('connection-timeout-warning');
  };

  HeaderView.prototype._showHeaderMessage = function(type) {
    var $header, $headerMessage;
    $headerMessage = $("#header-" + type);
    $headerMessage.show();
    $header = $('#header');
    if (!this.messageDisplayed[type]) {
      $header.height($headerMessage.height() + $header.height());
      $('.outer-container').css('top', $('.outer-container').position().top + $headerMessage.height());
      return this.messageDisplayed[type] = true;
    }
  };

  HeaderView.prototype._hideHeaderMessage = function(type) {
    var $header, $headerMessage;
    $headerMessage = $("#header-" + type);
    $headerMessage.hide();
    $header = $('#header');
    if (this.messageDisplayed[type]) {
      $header.height($header.height() - $headerMessage.height());
      $('.outer-container').css('top', $('.outer-container').position().top - $headerMessage.height());
      return this.messageDisplayed[type] = false;
    }
  };

  HeaderView.prototype.getTemplateData = function() {
    var emailConfirmationMessage, _ref;
    emailConfirmationMessage = mediator.user.get('emailConfirmed') == null ? $.t('messages.email_not_confirmed_send') : !mediator.user.get('emailConfirmed') ? $.t('messages.email_not_confirmed_resend') : void 0;
    return {
      isWelcomeScreen: mediator.project == null,
      userEmail: mediator.user.get('email'),
      whatsNewLink: window.gdt.versionUpdateLink,
      isChrome: /google/i.test(window.navigator.vendor),
      emailConfirmationMessage: emailConfirmationMessage,
      showDonateButton: mediator.services.switches.isServerSwitchOn('donate'),
      isWorkspaceSelectScreen: mediator.activeWorkspace === null,
      activeWorkspaceName: (_ref = mediator.userOrganizations[mediator.activeWorkspace]) != null ? _ref.organizationName : void 0,
      projectsListURL: mediator.activeWorkspace === 'personal' ? '/projects' : "/organizations/" + mediator.activeWorkspace + "/projects"
    };
  };

  HeaderView.prototype.afterRender = function() {
    var lastState;
    HeaderView.__super__.afterRender.apply(this, arguments);
    this.subview('userMenuView', new UserMenuView({
      parentView: this,
      isOrganizationAdmin: R.any(R.prop('isAdmin'), R.values(mediator.userOrganizations))
    }));
    if (mediator.project != null) {
      this.subview('projectsList', new HeaderProjectsListView({
        organizationId: mediator.project.get('organizationId')
      }));
    }
    lastState = this._updateConnectionState.lastState || mediator.services.replication.state;
    this._updateConnectionState(lastState, true);
    this._displayUpdateInfos();
    return this._updateConfirmationStatus();
  };

  HeaderView.prototype._displayUpdateInfos = function() {
    var updateInfo;
    if (_.get(window, ['sw', 'registration', 'waiting'])) {
      this._updateAvailable();
    }
    if (window.gdt.updateInstalled && !this.updateInfoDisplayed) {
      updateInfo = this.$('.update-installed-info');
      updateInfo.show();
      this.updateInfoDisplayed = true;
      return _.delay((function() {
        return updateInfo.hide();
      }), 15000);
    }
  };

  HeaderView.prototype._getSettingsListItems = function() {
    var getValue, manualQualityItem, user;
    user = mediator.user;
    manualQualityItem = (mediator.project != null) && mediator.services.switches.isOn('manualQuality') ? (getValue = function() {
      return Boolean(mediator.project.get('manualQuality'));
    }, {
      id: 'manualQuality',
      value: getValue(),
      text: $.t('settings.manual_quality'),
      type: 'check',
      handler: function() {
        mediator.project.save('manualQuality', !getValue());
        return mediator.publish('changedManualQualitySettings');
      }
    }) : null;
    return [
      manualQualityItem, {
        id: 'forceFootnotes',
        value: user.get('forceFootnotes'),
        text: $.t('settings.force_footnotes'),
        type: 'check',
        handler: function() {
          return user.save('forceFootnotes', !user.get('forceFootnotes'));
        }
      }, {
        id: 'showDropQuestionGroupArea',
        value: user.get('showDropQuestionGroupArea'),
        text: $.t('settings.show_question_drop_area'),
        type: 'check',
        handler: function() {
          return user.save('showDropQuestionGroupArea', !user.get('showDropQuestionGroupArea'));
        }
      }, {
        text: $.t('language'),
        type: 'submenu',
        subId: 'language',
        items: _(window.gdt.languages).map(function(lng) {
          return {
            id: lng.value,
            value: user.get('language') === lng.value,
            active: user.get('language') === lng.value,
            text: lng.text,
            type: 'link',
            handler: function() {
              return mediator.publish('!setLanguage', lng.value);
            }
          };
        })
      }
    ];
  };

  HeaderView.prototype.showSettings = function(e) {
    var listItems, offset, settingsView;
    e.preventDefault();
    e.stopPropagation();
    listItems = _(this._getSettingsListItems()).compact();
    settingsView = new PopupMenuView({
      menuItems: listItems,
      addClass: 'settings'
    });
    this.subview('settingsView', settingsView);
    offset = this.$('button.settings').offset();
    offset.top = offset.top + this.$('button.settings').height();
    offset.left -= 255;
    return settingsView.show(offset);
  };

  HeaderView.prototype.showHelp = function() {
    var $helpButton, helpListItems, hideMenu, menu, offset, panelVoiceOnboardingOption;
    hideMenu = (function(_this) {
      return function() {
        var _ref;
        return (_ref = _this.subview('help')) != null ? _ref.hide() : void 0;
      };
    })(this);
    helpListItems = [
      {
        value: 'handbook',
        text: $.t('help.handbook')
      }, {
        value: 'projectsScreenOnboarding',
        text: $.t('help.projects_screen_v2_tour'),
        disabled: mediator.activeWorkspace === null
      }, {
        type: 'hline'
      }
    ];
    if (mediator.activeWorkspace === 'personal' || mediator.services.switches.isOn('voting')) {
      panelVoiceOnboardingOption = {
        value: 'panelVoiceOnboarding',
        text: $.t('help.panel_voice_tour')
      };
      if (!((mediator.project != null) && mediator.services.modules.isEnabled('voting', mediator.project))) {
        _.extend(panelVoiceOnboardingOption, {
          disabled: true
        });
      }
      helpListItems.splice(1, 0, panelVoiceOnboardingOption);
    }
    helpListItems.push({
      type: 'hline'
    }, {
      value: 'feedback',
      text: $.t('feedback.title')
    });
    menu = new SelectionView({
      className: 'help-selection-view',
      listItems: helpListItems,
      acceptHandler: (function(_this) {
        return function() {
          switch (_this.subview('help').getSelectedItemValue()) {
            case 'handbook':
              _this.showHandbook();
              break;
            case 'feedback':
              _this.showFeedback();
              break;
            case 'walkthrough':
              mediator.services.walkthrough.startTour();
              break;
            case 'panelVoiceOnboarding':
              mediator.services.panelVoiceOnboarding.startTour();
              break;
            case 'projectsScreenOnboarding':
              mediator.services.projectsScreenOnboarding.routeAndStartTour();
          }
          return hideMenu();
        };
      })(this),
      abortHandler: hideMenu
    });
    this.subview('help', menu);
    $helpButton = this.$('button.help');
    offset = $helpButton.offset();
    offset.top = offset.top + $helpButton.height();
    return menu.show(offset);
  };

  HeaderView.prototype.showHandbook = function() {
    return this.subview('handbook', new HandbookView());
  };

  HeaderView.prototype.showFeedback = function() {
    return this.subview('feedback', new FeedbackView());
  };

  HeaderView.prototype.showAccountSettings = function() {
    return this.subview('account', new AccountView({
      externalAccountsService: mediator.services.externalAccounts,
      authenticationService: mediator.services.authentication
    }));
  };

  HeaderView.prototype.hideSettings = function() {
    var sv;
    sv = this.subview('settingsView');
    sv.unstickit();
    return sv.hide();
  };

  HeaderView.prototype._showConnectionMenu = function() {
    return this.subview('user', new UserView({
      model: mediator.user,
      lastBackup: this.lastBackup,
      centralConnection: this._updateConnectionState.lastState === 'connected',
      authenticationService: mediator.services.authentication,
      replicationService: mediator.services.replication
    }));
  };

  HeaderView.prototype._setupConnectionStates = function() {
    this.subscribeEvent('replication.connected', function() {
      return this._updateConnectionState('connected');
    });
    this.subscribeEvent('replication.disconnected', function() {
      return this._updateConnectionState('disconnected');
    });
    this.subscribeEvent('replication.unauthorized', function() {
      return this._updateConnectionState('unauthorized');
    });
    this.subscribeEvent('replication.connectionTimeout', function() {
      return this._updateConnectionState('connectionTimeout');
    });
    return this.subscribeEvent('replication.backedUp', function(_arg) {
      var timestamp;
      timestamp = _arg.timestamp;
      return this.lastBackup = timestamp;
    });
  };

  HeaderView.prototype._updateConnectionState = _.throttle(function(state, addTooltip) {
    if (addTooltip == null) {
      addTooltip = false;
    }
    this._updateConnectionIndicator(state, addTooltip);
    this._updateConnectionState.lastState = state;
    if (state === 'connectionTimeout') {
      return this._connectionTimeout();
    } else if (state === 'disconnected') {
      return this._disconnected();
    } else {
      return this._connected();
    }
  }, 10000, {
    leading: false
  });

  HeaderView.prototype._updateConnectionIndicator = function(state, addTooltip) {
    var indicator;
    if (addTooltip == null) {
      addTooltip = false;
    }
    indicator = this.$('button.connection');
    indicator.prop('class', "connection " + state);
    if (addTooltip) {
      return this._addTooltips('dynamicInfo', indicator, (function(_this) {
        return function() {
          return $.t("connection." + _this._updateConnectionState.lastState);
        };
      })(this));
    }
  };

  HeaderView.prototype._updateConfirmationStatus = function() {
    if (mediator.user.get('emailConfirmed')) {
      return this._hideHeaderMessage('email-not-confirmed');
    } else {
      return this._showHeaderMessage('email-not-confirmed');
    }
  };

  HeaderView.prototype._resendConfirmation = function(e) {
    var url;
    e.preventDefault();
    url = "" + (DbHelper.getBackendUrl()) + "/accounts/resend-confirmation";
    return W($.ajax(url, {
      type: 'POST',
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      }
    })).then(function() {
      return mediator.dialogs.info({
        message: $.t('messages.email_confirmation_sent'),
        ms: false
      });
    }, function(err) {
      return mediator.dialogs.error({
        message: $.t('messages.email_confirmation_error')
      });
    });
  };

  HeaderView.prototype.showDonationDialog = function(evt) {
    return this.subview('donationView', new DonationView({
      explicit: true
    }));
  };

  return HeaderView;

})(View);
