ContentEditor = require 'components/decision_aid/content_editor'
DecisionAidsActions = require 'actions/decision_aids_actions'
OutcomeName = require 'components/decision_aid/outcome_name'
RadioWithLabel = require 'components/common/radio_with_label'
Translation = require 'components/mixins/translation'
{
  notMeasuredNotReported
  contSofControl
  dichSofControl
  riskDifference
  risksToBeDisplayed
  quality
} = require 'lib/decision_aid_formatters'

EffectCell = createReactClass

  displayName: "EffectCell"

  propTypes:
    cellValue: PropTypes.string.isRequired
    content: PropTypes.string.isRequired
    daId: PropTypes.string.isRequired
    metaType: PropTypes.string
    outcomeId: PropTypes.string.isRequired
    riskKey: PropTypes.string.isRequired

  getDefaultProps: ->
    metaType: "POOLED"

  mixins: [Translation('es:outcome')]

  markAsPreferred: (cellValue) -> =>
    { daId, outcomeId, riskKey, preferred } = @props
    DecisionAidsActions.markRiskValuePreferred { daId, outcomeId, riskKey, cellValue}

  render: ->
    { cellValue, content, preferred, metaType, outcomeId, riskKey } = @props
    <div className='cell'>
      <div dangerouslySetInnerHTML={{__html: content}} />
      {if metaType not in notMeasuredNotReported.concat('NOT_POOLED')
        <div>
          <RadioWithLabel
            name={"#{outcomeId}-#{riskKey}-mark-as-preferred"}
            label={@i18n 'mark_as_preferred'}
            onChange={@markAsPreferred(cellValue)}
            value={cellValue}
            checked={preferred is cellValue}
          />
        </div>
      }
    </div>

NarrativeContent = createReactClass
  displayName: "NarrativeContent"

  propTypes:
    daId: PropTypes.string.isRequired
    outcome: PropTypes.instanceOf(Immutable.Map).isRequired

  narrativeDescEditorRef: (el) ->
    @editor = el

  handleNarrativeDescriptionUpdate: ->
    { daId, outcome } = @props
    outcomeId = outcome.get('_id')
    narrativeDesc = @editor.getContentJSON()
    outcomeData = { narrativeDesc }
    DecisionAidsActions.updateOutcome { daId, outcomeId, outcomeData }

  render: ->
    { outcome } = @props
    <div className='outcome-row__narrative-content'>
      <ContentEditor
        editorContent={outcome.get('narrativeDesc')}
        onBlur={@handleNarrativeDescriptionUpdate}
        ref={@narrativeDescEditorRef}
      />
    </div>

DichotomousContent = ({ daId, outcome }) ->
  attributes = outcome.toJS()
  risks = Immutable.fromJS(risksToBeDisplayed attributes)

  <div className="outcome-row__dich-cell">
    {risks.map (risk) ->
      riskKey = risk.get('key')
      cellProps =
        daId: daId
        metaType: outcome.get('metaType')
        outcomeId: outcome.get('_id')
        preferred: outcome.get("#{riskKey}ControlFavours")
        riskKey: riskKey

      <div className="outcome-row-container" key={riskKey}>
        {if risks.size > 1 and riskKey isnt 'study-population'
          <div className='outcome-row__label'>
            {risk.get('label')}
          </div>
        }
        <div className='outcome-row__cells'>
          <EffectCell
            cellValue="comparison"
            content={dichSofControl(attributes, riskKey)}
            {...cellProps}
          />
          <EffectCell
            cellValue="intervention"
            content={riskDifference(attributes, riskKey)}
            {...cellProps}
          />
        </div>
      </div>
    .toList()
    }
  </div>

DichotomousContent.propTypes =
  daId: PropTypes.string.isRequired
  outcome: PropTypes.instanceOf(Immutable.Map).isRequired

ContinuousContent = ({ daId, outcome }) ->
  attributes = outcome.toJS()
  cellProps =
    daId: daId
    metaType: outcome.get('metaType')
    outcomeId: outcome.get('_id')
    preferred: outcome.get("calculatedControlFavours")
    riskKey: 'calculated'

  <div className='outcome-row__cells'>
    <EffectCell
      cellValue="comparison"
      content={contSofControl(attributes)}
      {...cellProps}
    />
    <EffectCell
      cellValue="intervention"
      content={riskDifference(attributes)}
      {...cellProps}
    />
  </div>

ContinuousContent.propTypes =
  daId: PropTypes.string.isRequired,
  outcome: PropTypes.instanceOf(Immutable.Map).isRequired

Outcome = createReactClass
  displayName: "Outcome"

  propTypes:
    daId: PropTypes.string.isRequired
    outcome: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [Translation('es:outcome')]

  commentEditorRef: (el) ->
    @commentEditor = el

  handleCommentUpdate: ->
    { daId, outcome } = @props
    outcomeId = outcome.get('_id')
    comment = @commentEditor.getContentJSON()
    DecisionAidsActions.updateOutcomeComment { daId, outcomeId, comment }

  render: ->
    { daId, outcome } = @props
    <div className="outcome">
      <div className="outcome-row">
        <OutcomeName daId={daId} outcome={outcome} />
        {switch outcome.get('type')
          when 'narrative'
            <NarrativeContent daId={daId} outcome={outcome} />
          when 'cont'
            <ContinuousContent daId={daId} outcome={outcome} />
          when 'dich'
            <DichotomousContent daId={daId} outcome={outcome} />
        }
        <div className="outcome-row__certainty">
          <div
            dangerouslySetInnerHTML={{__html: quality(outcome.toJS())}}
          />
        </div>
      </div>
      {outcome.get('type') isnt 'narrative' and
        <div className="outcome-row">
          <div className="what-happens__label">{@i18n 'what_happens'}</div>
          <div className="what-happens__content">
            <ContentEditor
              editorContent={outcome.get('comments')}
              onBlur={@handleCommentUpdate}
              ref={@commentEditorRef}
            />
          </div>
        </div>
      }
    </div>


module.exports = Outcome
