var Exceptions, INACTIVE_PROJECT_STATUSES, MEMBERS_DB_VIEW, QUESTIONS_DB_VIEW, UDBListeners, UDB_FILTER, UDB_VIEW, W, abandonInvitation, acceptInvitation, alt, appUtils, archiveProject, assignAccessRights, copyProject, createAdolopment, declineInvitation, deleteProject, fetchProject, fetchProjectMembers, fetchProjectQuestions, fetchUdbData, getKeyValObject, getProjectAccessRights, listenUDB, mediator, moveProjectToOrganization, processInvitationsDoc, processUDBDocs, processUDBMetaDoc, restoreCopy, sendProjectCopy, shareProject, unarchiveProject, unlistenUDB, updateProjectData, updateProjectLabel, updateUserLabels, _fetchProjectCollection, _getUdbName,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

getKeyValObject = require('base/lib/utils').getKeyValObject;

QUESTIONS_DB_VIEW = require('lib/questions_helper').QUESTIONS_DB_VIEW;

MEMBERS_DB_VIEW = require('lib/members_helper').MEMBERS_DB_VIEW;

appUtils = require('lib/app_utils');

mediator = require('mediator');

alt = require('alt');

W = require('when');

W.lift = require('when/function').lift;

Exceptions = require('lib/exceptions');

UDB_FILTER = function(doc) {
  var docId, docType, isProjectInvitationsDoc, isProjectModelOrMetaDoc, isSharingRequestDoc, type;
  docId = doc._id, docType = doc.docType, type = doc.type;
  isProjectModelOrMetaDoc = docType === 'model' || docType === 'user';
  isSharingRequestDoc = docType === 'request' && (type === 'share_project' || type === 'share_project_copy');
  isProjectInvitationsDoc = docId === 'projects-invitations';
  return isProjectModelOrMetaDoc || isSharingRequestDoc || isProjectInvitationsDoc;
};

UDB_VIEW = {
  map: function(doc) {
    var docId, docType, isProjectInvitationsDoc, isProjectModelOrMetaDoc, isSharingRequestDoc, type;
    docId = doc._id, docType = doc.docType, type = doc.type;
    isProjectModelOrMetaDoc = docType === 'model' || docType === 'user';
    isSharingRequestDoc = docType === 'request' && (type === 'share_project' || type === 'share_project_copy');
    isProjectInvitationsDoc = docId === 'projects-invitations';
    if (isProjectModelOrMetaDoc || isSharingRequestDoc || isProjectInvitationsDoc) {
      return emit(docId, doc);
    }
  }
};

INACTIVE_PROJECT_STATUSES = ['deleted', 'broken', 'access_revoked', 'duplication_needed'];

UDBListeners = [];

_getUdbName = function() {
  return mediator.user.id;
};

processUDBMetaDoc = function(doc) {
  var userData;
  userData = _.pick(doc, ['email', 'name', 'language', 'projects', 'projectsLabels', 'projectsView', 'roles', 'docType']);
  userData.projects = _.chain(userData.projects).filter(function(p) {
    var _ref;
    return !(p.inTrash || (_ref = p.status, __indexOf.call(INACTIVE_PROJECT_STATUSES, _ref) >= 0));
  }).reduce(function(pMap, p) {
    pMap[p.name] = p;
    return pMap;
  }, {}).value();
  return userData;
};

processInvitationsDoc = function(doc) {
  var invitations;
  invitations = doc.invitations;
  return _.chain(invitations).keys().reduce(function(reduced, invitationId) {
    var createCopy, customer, invitationData, name, organizationId, projectId, status, _ref;
    invitationData = invitations[invitationId];
    createCopy = invitationData.createCopy, status = invitationData.status;
    if (status !== 'pending' && status !== 'accepted' && status !== 'invalid') {
      return reduced;
    }
    _ref = invitationData.projectData, organizationId = _ref.organizationId, name = _ref.name, customer = _ref.customer, projectId = _ref.id;
    return reduced.concat({
      id: invitationId,
      from: invitationData.from,
      name: name,
      customer: customer,
      createCopy: createCopy,
      status: status,
      projectId: projectId,
      organizationId: organizationId
    });
  }, []).value();
};

processUDBDocs = function(fetchedDocs) {
  return fetchedDocs.reduce(function(acc, doc) {
    var invitationId, projectsInvitations, projectsModels, sharingStatusesByInvitationId, status, userData;
    projectsModels = acc.projectsModels, userData = acc.userData, projectsInvitations = acc.projectsInvitations, sharingStatusesByInvitationId = acc.sharingStatusesByInvitationId;
    switch (doc.docType) {
      case 'user':
        userData = processUDBMetaDoc(doc);
        break;
      case 'model':
        if (doc._id === 'external_accounts') {
          break;
        }
        projectsModels[doc._id] = doc;
        break;
      case 'request':
        invitationId = doc.invitationId, status = doc.status;
        if (status !== 'new') {
          sharingStatusesByInvitationId = _.extend(sharingStatusesByInvitationId, getKeyValObject(invitationId, status));
        }
        break;
      default:
        if (doc._id === 'projects-invitations') {
          projectsInvitations = processInvitationsDoc(doc);
        }
    }
    return {
      projectsModels: projectsModels,
      userData: userData,
      projectsInvitations: projectsInvitations,
      sharingStatusesByInvitationId: sharingStatusesByInvitationId
    };
  }, {
    projectsModels: {},
    userData: {},
    projectsInvitations: {},
    sharingStatusesByInvitationId: {}
  });
};

fetchUdbData = function() {
  return mediator.services.storePersistenceAdapter.fetchWithView(_getUdbName(), 'user_projects_data', UDB_VIEW).then(processUDBDocs);
};

fetchProject = function(projectId) {
  return mediator.services.storePersistenceAdapter.fetch(projectId, [projectId, 'latestChange']).then(function(_arg) {
    var latestChangeData, latestChangeDoc, metaDocFetchError, projectDoc, rows, _ref;
    rows = _arg.rows;
    metaDocFetchError = ((_ref = rows[0].value) != null ? _ref.deleted : void 0) ? 'metadoc deleted' : rows[0].error;
    if (metaDocFetchError != null) {
      throw metaDocFetchError;
    }
    projectDoc = rows[0].doc;
    latestChangeDoc = rows[1].doc;
    latestChangeData = latestChangeDoc != null ? _.pick(latestChangeDoc, ['timestamp', 'rev_author']) : {
      timestamp: projectDoc['$timestamp']
    };
    return _.extend({}, projectDoc, {
      id: projectDoc._id
    }, latestChangeData);
  });
};

_fetchProjectCollection = function(projectId, viewName, viewDefinition) {
  var fetchCentral;
  fetchCentral = mediator.services.replication.isConnected() ? mediator.services.projectDb.fetchCentralProject(projectId)["catch"](function(e) {
    if (!(e instanceof Exceptions.project_missing_on_server)) {
      throw e;
    }
  }) : W.resolve();
  return fetchCentral.then(function() {
    return mediator.services.storePersistenceAdapter.fetchWithView(projectId, viewName, viewDefinition);
  });
};

fetchProjectQuestions = function(projectId) {
  return _fetchProjectCollection(projectId, 'questions', QUESTIONS_DB_VIEW).then(function(questions) {
    questions = questions.map(function(q) {
      return _.pick(q, '_id', 'question', 'recommendationIds');
    });
    return {
      projectId: projectId,
      questions: questions
    };
  });
};

fetchProjectMembers = function(projectId) {
  return _fetchProjectCollection(projectId, 'projectMembers', MEMBERS_DB_VIEW).then(function(members) {
    return {
      projectId: projectId,
      members: members
    };
  });
};

getProjectAccessRights = function(projectId) {
  var email;
  email = mediator.user.get('email').toLowerCase();
  return fetchProjectMembers(projectId).then(function(_arg) {
    var member, members, _ref;
    members = _arg.members;
    member = _(members).find(function(m) {
      return m.email.toLowerCase() === email;
    });
    return (_ref = member != null ? member.accessRights : void 0) != null ? _ref : [];
  });
};

createAdolopment = function(projectId, organizationId, selectedQuestions) {
  return mediator.user.createProjectAdolopment(projectId, organizationId, selectedQuestions);
};

copyProject = function(projectId, organizationId) {
  return mediator.user.createProjectSnapshot(projectId, organizationId);
};

restoreCopy = function(projectId, organizationId) {
  return mediator.user.restoreProjectSnapshot(projectId, organizationId);
};

moveProjectToOrganization = function(projectId, organizationId) {
  return mediator.user.moveProjectToOrganization(projectId, organizationId);
};

shareProject = function(projectId, recipient) {
  return W.lift(mediator.services.projectSharing.sendProjectInvitation)(projectId, recipient, false)["catch"](appUtils.errorHandler);
};

sendProjectCopy = function(projectId, recipient) {
  return W.lift(mediator.services.projectSharing.sendProjectInvitation)(projectId, recipient, true)["catch"](appUtils.errorHandler);
};

archiveProject = function(projectId) {
  var udbName;
  udbName = _getUdbName();
  return mediator.services.storePersistenceAdapter.updateOrCreate(udbName, udbName, function(doc) {
    doc.projects = doc.projects.map(function(p) {
      if (p.name !== projectId) {
        return p;
      }
      p.isArchived = true;
      p.status = 'replication_off';
      return p;
    });
    return doc;
  }).then(function(doc) {
    var isSwitchOn;
    if (mediator.activeWorkspace === 'personal' || _.isEmpty(mediator.activeWorkspace)) {
      return doc;
    }
    isSwitchOn = mediator.services.switches.isOn('organizationArchiveProjectForEveryone');
    if (!isSwitchOn) {
      return doc;
    }
    return W(mediator.user.getRequests().create({
      type: 'archive_project_for_everyone',
      status: 'new',
      projectId: projectId,
      organizationId: mediator.activeWorkspace
    })).then(function() {
      return doc;
    });
  })["catch"](appUtils.errorHandler);
};

unarchiveProject = function(projectId) {
  var udbName;
  udbName = _getUdbName();
  return mediator.services.storePersistenceAdapter.updateOrCreate(udbName, udbName, function(doc) {
    doc.projects = doc.projects.map(function(p) {
      if (p.name !== projectId) {
        return p;
      }
      p.isArchived = false;
      return p;
    });
    return doc;
  }).then(function(doc) {
    var isSwitchOn;
    if (mediator.activeWorkspace === 'personal' || _.isEmpty(mediator.activeWorkspace)) {
      return doc;
    }
    isSwitchOn = mediator.services.switches.isOn('organizationActivateProjectForOrganizationAdmins');
    if (!isSwitchOn) {
      return doc;
    }
    return W(mediator.user.getRequests().create({
      type: 'activate_project_for_organization_admins',
      status: 'new',
      projectId: projectId,
      organizationId: mediator.activeWorkspace
    })).then(function() {
      return doc;
    });
  })["catch"](appUtils.errorHandler);
};

deleteProject = function(projectId) {
  var udbName;
  udbName = _getUdbName();
  return mediator.services.storePersistenceAdapter.updateOrCreate(udbName, udbName, function(doc) {
    doc.projects = doc.projects.map(function(p) {
      if (p.name !== projectId) {
        return p;
      }
      p.isArchived = false;
      p.status = 'deleted';
      return p;
    });
    return doc;
  })["catch"](appUtils.errorHandler);
};

updateProjectLabel = function(projectId, labelId) {
  return mediator.services.storePersistenceAdapter.updateOrCreate(_getUdbName(), projectId, function(doc) {
    return _.extend(doc, {
      userLabel: labelId
    });
  })["catch"](appUtils.errorHandler);
};

updateProjectData = function(projectId, data) {
  return mediator.services.storePersistenceAdapter.updateOrCreate(projectId, projectId, function(doc) {
    return _.extend(doc, data);
  }).then(mediator.services.replication.sendLocalChanges([projectId]))["catch"](appUtils.errorHandler);
};

updateUserLabels = function(labelsData) {
  var udbName;
  udbName = _getUdbName();
  return mediator.services.storePersistenceAdapter.updateOrCreate(udbName, udbName, function(doc) {
    var projectsLabels, updatedProjectsLabels;
    projectsLabels = doc.projectsLabels || {};
    updatedProjectsLabels = _.extend(projectsLabels, labelsData);
    return _.extend(doc, {
      projectsLabels: updatedProjectsLabels
    });
  })["catch"](appUtils.errorHandler);
};

listenUDB = function(action) {
  var listener, options;
  options = {
    filter: UDB_FILTER
  };
  listener = mediator.services.storePersistenceAdapter.setupListening(_getUdbName(), options, action);
  return UDBListeners.push(listener);
};

unlistenUDB = function() {
  UDBListeners.map(function(listener) {
    return listener.cancel();
  });
  return UDBListeners.length = 0;
};

acceptInvitation = function(_arg) {
  var invitationId, organizationId;
  invitationId = _arg.invitationId, organizationId = _arg.organizationId;
  return mediator.services.projectSharing.acceptInvitation(invitationId, organizationId)["catch"](appUtils.errorHandler);
};

declineInvitation = function(invitationId) {
  return mediator.services.projectSharing.rejectInvitation(invitationId)["catch"](appUtils.errorHandler);
};

abandonInvitation = function(invitationId) {
  return mediator.services.projectSharing.abandonInvitation(invitationId)["catch"](appUtils.errorHandler);
};

assignAccessRights = function(project) {
  return getProjectAccessRights(project.id).then(function(accessRights) {
    return _.extend({}, project, {
      accessRights: accessRights
    });
  });
};

module.exports = {
  UDB_VIEW: UDB_VIEW,
  processUDBMetaDoc: processUDBMetaDoc,
  processInvitationsDoc: processInvitationsDoc,
  processUDBDocs: processUDBDocs,
  fetchUdbData: fetchUdbData,
  fetchProject: fetchProject,
  fetchProjectQuestions: fetchProjectQuestions,
  fetchProjectMembers: fetchProjectMembers,
  getProjectAccessRights: getProjectAccessRights,
  copyProject: copyProject,
  restoreCopy: restoreCopy,
  shareProject: shareProject,
  sendProjectCopy: sendProjectCopy,
  archiveProject: archiveProject,
  unarchiveProject: unarchiveProject,
  deleteProject: deleteProject,
  updateProjectLabel: updateProjectLabel,
  updateProjectData: updateProjectData,
  updateUserLabels: updateUserLabels,
  listenUDB: listenUDB,
  unlistenUDB: unlistenUDB,
  acceptInvitation: acceptInvitation,
  declineInvitation: declineInvitation,
  abandonInvitation: abandonInvitation,
  moveProjectToOrganization: moveProjectToOrganization,
  createAdolopment: createAdolopment,
  assignAccessRights: assignAccessRights
};
