var CalendarView, CollectionView, Event, ReactComponent, ReactToolbarView, TaskView, Tasks, TasksActions, TasksToolbar, TasksView, alt, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

CollectionView = require('base/views/collection_view');

CalendarView = require('views/tasks/calendar_view');

TaskView = require('views/tasks/task_view');

TasksToolbar = require('components/tasks/tasks_toolbar');

Event = require('models/event');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

template = require('views/templates/tasks/tasks');

Tasks = require('components/tasks/tasks');

TasksActions = require('actions/tasks_actions');

mediator = require('mediator');

module.exports = TasksView = (function(_super) {
  __extends(TasksView, _super);

  function TasksView() {
    return TasksView.__super__.constructor.apply(this, arguments);
  }

  TasksView.prototype.container = '#page-container';

  TasksView.prototype.className = 'tasks-page';

  TasksView.prototype.template = template;

  TasksView.prototype.itemView = TaskView;

  TasksView.prototype.animationDuration = 10;

  TasksView.prototype.listSelector = 'ul';

  TasksView.prototype.addCollectionSizeValidation = true;

  TasksView.prototype.initialize = function() {
    this.enable(ReactComponent);
    TasksView.__super__.initialize.apply(this, arguments);
    if (mediator.services.switches.isServerSwitchOn('mdgFeatures')) {
      this.subview('toolbar', new ReactToolbarView({
        component: TasksToolbar
      }));
    }
    this.subview('calendar', new CalendarView({
      collection: this.collection,
      parentView: this
    }));
    return this.delegate('click', 'button.add-task', this.addTask);
  };

  TasksView.prototype.afterRender = function() {
    TasksView.__super__.afterRender.apply(this, arguments);
    if (mediator.services.switches.isServerSwitchOn('mdgFeatures')) {
      this.renderComponent(Tasks, TasksActions, null, {
        fetchAndListen: {
          dbId: mediator.project.id
        }
      });
    }
    return this.$('#calendar-container').html(this.subview('calendar').render().el);
  };

  TasksView.prototype.addTask = function() {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        return _this.collection.add(new Event());
      };
    })(this));
  };

  TasksView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('TasksStore');
    return TasksView.__super__.dispose.apply(this, arguments);
  };

  return TasksView;

})(CollectionView);
