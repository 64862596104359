ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
EllipsizedText = require 'components/common/ellipsized_text'
IconButton = require 'components/common/icon_button'
Modal = require 'components/common/modal'
OverarchingQuestionsActions = require 'actions/overarching_questions_actions'
Popover = require 'components/common/popover'
SearchBar = require 'components/common/search_bar'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
{ useCoffeeMemo, useCoffeeCallback, useI18n, useCoffeeEffect } = require 'lib/react_utils'
{ useState } = React
{ Button } = ReactComponents
{ searchCode, stripHTML } = require 'lib/question_tags_helper'

defaultCodeFilterer = (needle) -> (code) ->
  return true if _.isEmpty needle
  searchText = needle.toLowerCase()
  _.any ['codeType', 'value', 'name'], (field) ->
    code.get(field, '').toLowerCase().indexOf(searchText) isnt -1

codeSorter = (code) -> [code.get('codeType'), code.get('value')]

TopicCodesModal = createReactClass
  displayName: "TopicCodesModal"
  mixins: [Translation('mda:topics')]

  propTypes:
    codes: PropTypes.instanceOf(Immutable.Map)
    parentId: PropTypes.string.isRequired
    onAdd: PropTypes.func.isRequired
    onRemove: PropTypes.func.isRequired

  getInitialState: ->
    searching: false
    codesList: Immutable.List()
    isModalOpen: false
    checkedCodesMap: @props.codes

  getDefaultProps: ->
    codes: Immutable.Map()

  onApply: (codesToAdd) ->
    { parentId, onAdd } = @props
    codes = codesToAdd.map (code) -> code.update 'name', (name) -> stripHTML name
    onAdd { parentId, codes }

  onCancel: ->
    @setState
      codesList: Immutable.List()
      checkedCodesMap: @props.codes
      isModalOpen: false
      searching: false

  handleDeleteCode: (code) -> =>
    { parentId, onRemove } = @props
    onRemove { parentId, code }

  openAddCodesModal: ->
    @setState isModalOpen: true

  onSearch: (searchText) ->
    return unless searchText.length > 1
    @setState { searching: true }
    searchCode('all', searchText, 0, null, 'mdg')
      .then((res) =>
        @setState codesList: Immutable.fromJS(res.codes))
      .finally =>
        @setState searching: false

  resetSearchText: ->
    @setState
      codesList: Immutable.List()
      searching: false

  render: ->
    { codes, title } = @props
    {
      codesList
      isModalOpen
      searching
    } = @state

    <div>
      <Button className='add-code-trigger mr-5' onClick={@openAddCodesModal}>
        {@i18n '/actions.add_code'}
      </Button>
      <Modal
        isOpen={isModalOpen}
        modalSize="full-size"
        title={if _.isEmpty(title) then @i18n 'add_code_to_topic' else title}
      >
        <TopicCodes
          includedCodes={codes}
          handleDeleteCode={@handleDeleteCode}
          onApply={@onApply}
          onCancel={@onCancel}
          onSearch={@onSearch}
          searching={searching}
          codesToInclude={codesList}
        />
      </Modal>
    </div>

TopicCodes = ({
  includedCodes
  handleDeleteCode
  onApply
  onCancel
  onSearch
  searching
  codesToInclude
}) ->
  i18n = useI18n('mda:topics')

  [codesToAdd, setCodesToAdd] = useState(includedCodes)
  [existingCodesSearchText, setExistingCodesSearchText] = useState('')
  [searchText, setSearchText] = useState('')

  useCoffeeEffect [includedCodes], -> setCodesToAdd includedCodes

  handleAddCode = useCoffeeCallback [codesToAdd], (code) -> =>
    updatedCodesList = if codesToAdd.has code.get('id')
      codesToAdd.delete code.get('id')
    else
      codesToAdd.set code.get('id'), code

    setCodesToAdd updatedCodesList

  changeExistingCodesSearchText = useCoffeeCallback [], (searchText) ->
    setExistingCodesSearchText searchText

  resetExistingCodesSearchText = useCoffeeCallback [], ->
    setExistingCodesSearchText ''

  changeSearchText = useCoffeeCallback [onSearch], (searchText) ->
    setSearchText(searchText)
    onSearch?(searchText)

  resetSearchText = useCoffeeCallback [onSearch], ->
    setSearchText ''
    onSearch?('')

  _onApply = useCoffeeCallback [onApply, codesToAdd], ->
    onApply(codesToAdd)

  filteredIncludedCodes = useCoffeeMemo [includedCodes, existingCodesSearchText], ->
    includedCodes.filter defaultCodeFilterer existingCodesSearchText
    .sortBy codeSorter

  filteredCodesToInclude = useCoffeeMemo [codesToInclude, searchText, onSearch], ->
    return codesToInclude if _.isFunction onSearch
    codesToInclude.filter defaultCodeFilterer searchText
    .sortBy codeSorter

  <div className="mda-add-code-modal">
    <div className="flex flex-row">
      <CodesList
        changeSearchText={changeExistingCodesSearchText}
        codes={filteredIncludedCodes}
        handleDeleteCode={handleDeleteCode}
        resetSearchText={resetExistingCodesSearchText}
        searchText={existingCodesSearchText}
      />
      <CodesList
        changeSearchText={changeSearchText}
        codes={filteredCodesToInclude}
        selectedCodes={codesToAdd}
        onItemClick={handleAddCode}
        resetSearchText={resetSearchText}
        searching={searching}
        searchText={searchText}
      />
    </div>
    <div className="mt-10">
      <ApplyCancelButtons
        applyClass="btn-alternative"
        isSubmitEnabled={!codesToAdd.isEmpty()}
        onApply={_onApply}
        onCancel={onCancel}
        applyLabel={i18n '/actions.add_codes'}
        cancelLabel={i18n '/actions.close'}
      />
    </div>
  </div>

CodesList = ({
  changeSearchText
  codes
  selectedCodes
  handleDeleteCode
  onItemClick
  resetSearchText
  searching
  searchText
}) ->
  i18n = useI18n('mda:topics')

  [selectedGroup, setSelectedGroup] = useState("")

  codeTypes = useCoffeeMemo [codes], ->
    codes.map (code) -> code.get('codeType')
    .valueSeq()
    .toSet()

  onChangeType = (e) -> setSelectedGroup e.target.value

  filteredCodes = useCoffeeMemo [codes, selectedGroup], ->
    return codes if selectedGroup is ''
    codes.filter (code) -> code.get('codeType') is selectedGroup

  codesCount = filteredCodes.size

  <div className="mda-add-code-modal__codes-list">
    <SearchBar
      onSearch={changeSearchText}
      onSearchReset={resetSearchText}
      searchOnChange
      searchText={searchText}
      lowerCaseSearchText={false}
    />
    {searching and <div className="py-10"><Spinner /></div>}
    <div className="codes-list--header">
      {!codeTypes.isEmpty() and <div>
        <select
          className="code-types-selector"
          defaultValue={selectedGroup}
          onChange={onChangeType}
        >
          <option value="">{i18n "../codes.all"}</option>
          {codeTypes.map (type) ->
            <option value={type} key={type}>
              {type}
            </option>
          }
        </select>
      </div>}
      {codesCount isnt 0 and <div>
        {i18n '../codes.count', count: codesCount}
      </div>}
    </div>
    {filteredCodes.isEmpty() and <div>{i18n 'no_results'}</div>}
    <div className="codes-list--scrollable">
      <table className="w-full">
        <tbody>
          {filteredCodes
          .map (code) ->
            codeId = code.get('id')
            classes = classNames 'codes-list__code', included: selectedCodes.has codeId
            <tr className={classes} key={codeId} onClick={onItemClick?(code)}>
              <Code code={code} />
              {handleDeleteCode? and
                <td>
                  <IconButton iconName="cross" onClick={handleDeleteCode(code)} />
                </td>
              }
            </tr>
          .toList()}
        </tbody>
      </table>
    </div>
  </div>

CodesList.propTypes =
  codes: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.List)
    PropTypes.instanceOf(Immutable.Map)
  ]).isRequired
  selectedCodes: PropTypes.instanceOf(Immutable.Map)
  changeSearchText: PropTypes.func.isRequired
  handleDeleteCode: PropTypes.func
  resetSearchText: PropTypes.func.isRequired
  searching: PropTypes.bool
  searchText: PropTypes.string.isRequired
  onItemClick: PropTypes.func

CodesList.defaultProps =
  handleDeleteCode: null
  onItemClick: null
  searching: false
  selectedCodes: Immutable.Map()

Code = ({ code}) ->
  <React.Fragment>
    <td>
      <div className="mr-5 code-type" dangerouslySetInnerHTML={{
        __html: code.get('codeType') or code.get('@type')
        }}
      />
    </td>
    <td>
      <div className="mr-5 code-value" dangerouslySetInnerHTML={{__html: code.get('value')}}/>
    </td>
    <td>
      <div className='w-full code-name' dangerouslySetInnerHTML={{__html: code.get('name')}} />
    </td>
  </React.Fragment>


Code.propTypes =
  code: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports =
  TopicCodesModal: TopicCodesModal
  TopicCodes: TopicCodes
