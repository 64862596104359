var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<menu type=\"toolbar\" class=\"document-sections-view\">\n  <button title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "toolbar.save", {"name":"i18n","hash":{},"data":data})))
    + "\" class=\"save\"></button>\n</menu>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}