{ QUESTION_GENERATION_STEPS } = require 'lib/questions_helper'
Modal = require 'components/common/modal'
ExportDialog = require 'components/common/export_dialog'
commentsExportTemplate = require 'views/templates/printouts/questions_generation_comments_printout'
questionsExportTemplate =
  require 'views/templates/printouts/questions_generation_questions_printout'
Translation = require 'components/mixins/translation'
CommentsExportMixin = require 'components/mixins/scope_comments_export'

CommentsExportModal = createReactClass
  displayName: 'QuestionsGenerationCommentsExportModal'

  propTypes:
    projectName: PropTypes.string.isRequired
    questions: PropTypes.instanceOf(Immutable.List).isRequired
    onClose: PropTypes.func.isRequired

  mixins: [
    Translation('scope:questions')
    CommentsExportMixin
  ]

  _getSortedQuestions: ->
    return Immutable.List() if @props.questions.isEmpty()
    @props.questions.sortBy((q) -> - q.get 'medianRating')

  _prepareCommentsDataPerPhase: (membersComments, adminComments) ->
    # mixin the admin comment and phase name for each step comments data
    # ensure following phases ordering: approval, prioritizing, brainstorming
    membersComments.toOrderedMap().sortBy (commentsData, stepName) ->
      - QUESTION_GENERATION_STEPS.indexOf stepName
    .map (commentsData, stepName) =>
      {
        stepName
        adminComment: adminComments.get stepName, ''
        membersComments: commentsData.map((comment, memberId) =>
          membersRowSpan: commentsData.size
          memberComment: comment
          memberName: @props.membersMap.get memberId
        ).toList().toJS()
      }
    .toArray()

  _getCommentedQuestions: ->
    @_getSortedQuestions()
      .filterNot (q) -> q.get('memberComments', Immutable.Map()).isEmpty()

  getTemplate: ->
    return questionsExportTemplate if @props.tabName is QUESTION_GENERATION_STEPS[6]
    commentsExportTemplate

  getTemplateData: ->
    if @props.tabName is QUESTION_GENERATION_STEPS[6]
      getQuestion = (qId) => @props.questions.getIn [qId, 'question']
      questionGroups = @props.questionGroups
        .filterNot (questionIds, approvalStatus) -> questionIds.isEmpty()
        .reduce (result, questionIds, approvalStatus) =>
          questions = questionIds.map(getQuestion).toJS()
          result.push
            groupType: @i18n "approval.questions_#{approvalStatus}"
            questions: questions
            questionsCount: questions.length
        , Immutable.List()
        .toJS()
      { questionGroups }
    else
      questions: @_getSortedQuestions().toJS()
      questionsComments: @_getCommentedQuestions().map (q) =>
        memberComments = q.get 'memberComments'
        adminComments = q.get 'adminComments', Immutable.Map()

        question: q.get 'question'
        phases: @_prepareCommentsDataPerPhase memberComments, adminComments
      .toArray()

  _getProposalExportOptionsSpec: ->
    name: 'exportContent'
    label: @i18n 'proposal.choose_export_content'
    isRequired: true
    optionsList: [
      { label: @i18n('proposal.rating_table_with_comments'), value: 'tableAndComments' }
      { label: @i18n('proposal.comments_only'), value: 'commentsOnly' }
    ]

  _getAdditionalOptions: ->
    # on Proposal tab we must show additional options if there are any comments available
    if @props.tabName is QUESTION_GENERATION_STEPS[4] and not @_getCommentedQuestions().isEmpty()
      [ @_getProposalExportOptionsSpec() ]

  _getModalTitle: ->
    isProposalTab = @props.tabName is QUESTION_GENERATION_STEPS[4]
    return @i18n 'export_questions' if isProposalTab
    isFinishedTab = @props.tabName is QUESTION_GENERATION_STEPS[6]
    return @i18n 'export_questions_list' if isFinishedTab
    @i18n 'export_all_comments'

  onDownload: (exportOptions) ->
    @exportToFile exportOptions, @getTemplate()

  render: ->
    <Modal isOpen
      className="export-comments-modal"
      closeButton={false}
      title={@_getModalTitle()}
    >
      <ExportDialog
        additionalOptions={@_getAdditionalOptions()}
        onClose={@props.onClose}
        onDownload={@onDownload}
      />
    </Modal>

module.exports = CommentsExportModal
