Router = require 'router'
mediator =  require 'mediator'
EllipsizedText = require 'components/common/ellipsized_text'
Spinner = require 'components/common/spinner'
Tooltip = require 'components/common/tooltip'
IconButton = require 'components/common/icon_button'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
QuestionsList = require 'components/evidence_syntheses/questions_list/questions_list'
OverarchingQuestionsStore = require 'stores/overarching_questions_store'
QuestionsStore = require 'stores/questions_store'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsHelper = require 'lib/questions_helper'
ConnectStore = require 'components/enhancers/connect_store'
ToolbarOverarchingQuestion =
  require 'components/overarching_questions/questions_list/toolbar_overarching_question'
ToolbarMDGQuestion = require 'components/mdg_tables/toolbar_question'
Translation = require 'components/mixins/translation'
{ oneOf, oneOfType, instanceOf, bool, string, object } = PropTypes

Question = ({ data, additionalProps }) ->
  classes = classNames 'bold': data.get('_id') is mediator.currentQuestionId
  { collapse } = additionalProps ? {}
  isQOEEnabled = mediator.services.modules.isVisible 'evidence_syntheses#quality_of_evidence',
    mediator.project
  submodule = if isQOEEnabled then 'quality-of-evidence' else 'recommendations'
  link = Router::routeForSubmodule 'evidence-syntheses', submodule, questionId: data.get '_id'
  onClick = -> collapse?()

  <a href={"/#{link}"} className={classes} onClick={onClick}>{data.get 'question'}</a>

storeConnector =
  QuestionsStore: (Store, props) ->
    question: Store.getQuestion()
    questions: Store.getQuestions props.questionsCollectionType

  QuestionGroupsStore: (Store, props) ->
    questionGroups: Store.getQuestionGroups props.questionsCollectionType

  OverarchingQuestionsStore: (Store, props) ->
    overarchingQuestions: if props.questionsCollectionType is 'regular'
      Store.getOverarchingQuestionsByGroup()
    else
      Immutable.OrderedMap()

ToolbarQuestionsList = createReactClass
  displayName: 'ToolbarQuestionsList'

  propTypes:
    question: instanceOf(Immutable.Map).isRequired
    questionsCollectionType: oneOf(['regular', 'prognosis']).isRequired
    questionGroups: instanceOf(Immutable.Map).isRequired
    questions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)]
    ).isRequired
    overarchingQuestions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)]
    ).isRequired
    currentQuestion: string.isRequired
    additionalProps: object

  mixins: [Translation()]

  getSortedQuestionsIds: ->
    { questions, questionGroups } = @props
    QuestionsHelper.getQuestionsIdsSorted questionGroups, questions

  goToNextQuestion: (e) ->
    e.stopPropagation()
    hash = Backbone.history.getHash()
    subModule = _.last(hash.split('/'))
    sortedQuestions = @getSortedQuestionsIds()

    currentQuestionIdx = sortedQuestions.indexOf @props.question.get('_id')
    nextQuestionId = sortedQuestions.get currentQuestionIdx + 1

    link = Router::routeForSubmodule 'evidence-syntheses', subModule, questionId: nextQuestionId
    mediator.publish '!router:route', link

  isLastQuestionOnList: ->
    sortedQuestions = @getSortedQuestionsIds()
    currentQuestionIdx = sortedQuestions.indexOf @props.question.get('_id')
    currentQuestionIdx is sortedQuestions.size - 1

  render: ->
    <CollapsibleWithControls
      additionalProps={@props.additionalProps}
      controlsPosition='left'
      withTogglableCaption
      passAdditionalProps
    >
      <div>
        <Tooltip>
          <div className='current-question' href='#' title={@i18n 'toolbar.questions_menu'}>
            <div className="current-question__text">{@props.currentQuestion}</div>
            {@props.question?.get('_id') and not @isLastQuestionOnList() and
              <IconButton className="ml-20" iconName="nav-arrow-right" onClick={@goToNextQuestion} />
            }
          </div>
        </Tooltip>
      </div>
      <QuestionsList
        questions={@props.questions}
        questionGroups={@props.questionGroups}
        overarchingQuestions={@props.overarchingQuestions}
        displayOverarchingQuestions={@props.questionsCollectionType is 'regular'}
        overarchingQuestionComponent={ToolbarOverarchingQuestion}
        mdgQuestionComponent={ToolbarMDGQuestion}
      >
        <Question />
      </QuestionsList>
    </CollapsibleWithControls>

module.exports = ConnectStore(
  ToolbarQuestionsList,
  [ QuestionsStore, QuestionGroupsStore, OverarchingQuestionsStore ],
  storeConnector
)
