var etdHelper, getValueKey, isImmutable,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

etdHelper = require('lib/etd_helper');

isImmutable = require('lib/immutable_utils').isImmutable;

getValueKey = function(sectionId) {
  if (__indexOf.call(etdHelper.getRecommendationTypeSections(), sectionId) >= 0 || sectionId === 'criteria') {
    return 'selectedOption';
  } else {
    return 'content';
  }
};

module.exports = {
  getAdolopmentSectionProps: function(adolopment, sectionId, sectionData) {
    var acId, assessment, criterionId, reId;
    criterionId = sectionData.get('criterionId');
    reId = sectionData.get('researchEvidenceId');
    acId = sectionData.get('additionalConsiderationId');
    assessment = adolopment.getIn(['templateData', 'assessment']);
    return {
      attachments: adolopment.get('_attachments') || Immutable.Map(),
      sectionData: sectionData,
      sectionName: sectionData.get('name'),
      criterion: assessment.getIn(['criteria', criterionId]),
      researchEvidence: assessment.getIn(['researchEvidences', reId]),
      additionalConsiderations: assessment.getIn(['additionalConsiderations', acId])
    };
  },
  mergeEtdsBasedOnAdolopmentData: function(original, adoloped, adolopedSections) {
    var result;
    original = isImmutable(original) ? original : Immutable.fromJS(original);
    adoloped = isImmutable(adoloped) ? adoloped : Immutable.fromJS(adoloped);
    adolopedSections = isImmutable(adolopedSections) ? adolopedSections : Immutable.fromJS(adolopedSections);
    result = Immutable.fromJS({});
    if (adolopedSections.isEmpty()) {
      result = adoloped.withMutations(function(s) {
        s.set('_attachments', original.get('_attachments'));
        s.setIn(['templateData', 'assessment'], original.getIn(['templateData', 'assessment']));
        return s.setIn(['templateData', 'conclusions'], original.getIn(['templateData', 'conclusions']));
      });
    } else {
      result = adoloped.withMutations(function(a) {
        a.set('_attachments', a.get('_attachments', Immutable.Map()).merge(original.get('_attachments')));
        a.setIn(['templateData', 'assessment'], original.getIn(['templateData', 'assessment']).map(function(field, fieldId) {
          return field.map(function(section, sectionId) {
            var valueKey, withAdolopment;
            valueKey = getValueKey(fieldId);
            withAdolopment = sectionId === 'effects' ? Immutable.fromJS(['desirableEffects', 'undesirableEffects']).some(function(sId) {
              return adolopedSections.getIn(['assessment', sId, 'withAdolopment'], false);
            }) : adolopedSections.getIn(['assessment', sectionId, 'withAdolopment'], false);
            if (withAdolopment) {
              section = section.set(valueKey, adoloped.getIn(['templateData', 'assessment', fieldId, sectionId, valueKey]));
            }
            return section;
          });
        }));
        return a.setIn(['templateData', 'conclusions', 'sections'], original.getIn(['templateData', 'conclusions', 'sections']).map(function(section, sectionId) {
          var valueKey;
          valueKey = getValueKey(sectionId);
          if (adolopedSections.getIn(['conclusions', sectionId, 'withAdolopment'], false)) {
            section = section.set(valueKey, adoloped.getIn(['templateData', 'conclusions', 'sections', sectionId, valueKey]));
          }
          return section;
        }));
      });
    }
    return result.toJS();
  },
  getAssessmentSelectedOption: function(original, adolopmentTemplateData, sectionId, adolopmentData) {
    var templateData;
    templateData = !adolopmentData.getIn(['assessment', sectionId, 'withAdolopment'], false) ? original.get('templateData') : adolopmentTemplateData;
    return etdHelper.getAssessmentSelectedOption(templateData, sectionId);
  }
};
