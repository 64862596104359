ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
MdaTableActions = require 'actions/mda_table_actions'
MdaTableStore = require 'stores/mda_table_store'
Modal = require 'components/common/modal'
{ useCoffeeCallback, useCoffeeEffect, useI18n } = require 'lib/react_utils'
{ useState } = React

storeConnector =
  MdaTableStore: (Store) ->
    applying: Store.isModalApplying()
    isOpen: Store.isModalOpen()
    name: Store.getModalName()
    tableId: Store.getTableId()

TABLE_FORM =
  main: [
    fieldKey: 'name'
    type: 'string'
    showClear: false
    useControlledInput: true
  ]

MdaTableModal = ({ applying, isOpen, name, projectId, tableId }) ->
  i18n = useI18n 'mda:tables.modal'
  [currentName, setCurrentName] = useState name ? ''

  useCoffeeEffect [name, isOpen, setCurrentName], ->
    setCurrentName name ? ''

  onChange = useCoffeeCallback [setCurrentName], (_field, value) ->
    setCurrentName value

  onApply = useCoffeeCallback [currentName, projectId, tableId], ->
    if tableId?
      MdaTableActions.updateName projectId, tableId, currentName
    else
      MdaTableActions.createTable projectId, currentName

  <Modal
    className="mda-table-modal"
    isOpen={isOpen}
    modalSize="medium"
    title={i18n if tableId? then 'edit_title' else 'create_title'}
  >
    <ExtractionForm
      data={name: currentName}
      form={TABLE_FORM}
      i18n={i18n}
      inModal
      onChange={onChange}
    />
    <ApplyCancelButtons
      applying={applying}
      applyLabel={i18n '/actions.save'}
      containerClass="mt-10"
      isSubmitEnabled={currentName.length > 0}
      onApply={onApply}
      onCancel={MdaTableActions.closeCreateOrEditDialog}
    />
  </Modal>

MdaTableModal.propTypes =
  applying: PropTypes.bool.isRequired
  isOpen: PropTypes.bool.isRequired
  name: PropTypes.string
  projectId: PropTypes.string.isRequired
  tableId: PropTypes.string

module.exports = ConnectStore MdaTableModal, MdaTableStore, storeConnector
