ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Modal = require 'components/common/modal'
ContentEditable = require 'components/common/content_editable'
Translation = require 'components/mixins/translation'

styles =
  buttons:
    container:
      textAlign: 'center'
      marginTop: '15px'
    defaultBtn:
      lineHeight: '36px'
      width: '170px'
      backgroundColor: '#cecece'
      color: '#5b666e'
    successBtn:
      marginLeft: '10px'
      backgroundColor: '#c9dac2'
      color: '#17a01b'
  modal:
    content:
      top: '10vh'
      bottom: 'initial'
      paddingTop: '30px'
      backgroundColor: '#eaeaea'

EmbeddedHTMLEditor = createReactClass
  displayName: 'EmbeddedHTMLEditor'

  mixins: [
    Translation('')
  ]

  propTypes:
    onClose: PropTypes.func.isRequired
    onApply: PropTypes.func.isRequired
    html: PropTypes.string.isRequired

  onApply: ->
    @props.onApply ReactDOM.findDOMNode(@_Editable).innerHTML

  render: ->
    { buttons, modal } = styles
    { defaultBtn, successBtn, container } = buttons

    <Modal isOpen closeButton onClose={@props.onClose} style={modal}>
      <ContentEditable
        autoFocus
        html={@props.html}
        ref={(e) => @_Editable = e}
        style={backgroundColor: '#fff'}
      />
      <div style={container}>
        <ApplyCancelButtons
          onApply={@onApply}
          onCancel={@props.onClose}
        />
      </div>
    </Modal>

module.exports = EmbeddedHTMLEditor
