ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
{ func, bool } = PropTypes

CompoundEditBlock = createReactClass
  displayName: 'CompoundEditBlock'

  propTypes:
    label: PropTypes.string.isRequired

  render: ->
    <div className='edit-input-block'>
      <label className='attr-name'>{@props.label}</label>
      {@props.children}
    </div>

# TODO: add children validation
CompoundEditSection = createReactClass
  displayName: 'CompoundEditSection'

  propTypes:
    className: PropTypes.string
    sectionTitle: PropTypes.string

  render: ->
    { className, sectionTitle, children } = @props

    <div className={className}>
      {if sectionTitle
        <h4>{sectionTitle}</h4>
      }
      {children}
    </div>

# TODO: add children validation
CompoundEdit = createReactClass
  displayName: 'CompoundEdit'

  propTypes:
    onApply: func.isRequired
    onCancel: func.isRequired
    applyEnabled: bool

  getDefaultProps: ->
    applyEnabled: true

  render: ->
    { children, onApply, onCancel, applyEnabled } = @props

    <div className='outcome-edit__compound-edit'>
      {children}
      <ApplyCancelButtons
        onApply={onApply}
        onCancel={onCancel}
        isSubmitEnabled={applyEnabled}
      />
    </div>

module.exports = { CompoundEdit, CompoundEditSection, CompoundEditBlock }
