var ALGORITHM_PREFIX, AlgorithmsActions, AlgorithmsList, AlgorithmsToolbar, AlgorithmsView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

AlgorithmsActions = require('actions/algorithms_actions');

AlgorithmsToolbar = require('components/algorithms/algorithms_toolbar');

AlgorithmsList = require('components/algorithms/algorithms_list');

ALGORITHM_PREFIX = require('lib/doc_prefixes').algorithms;

alt = require('alt');

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = AlgorithmsView = (function(_super) {
  __extends(AlgorithmsView, _super);

  function AlgorithmsView() {
    return AlgorithmsView.__super__.constructor.apply(this, arguments);
  }

  AlgorithmsView.prototype.container = '#page-container';

  AlgorithmsView.prototype.autoRender = true;

  AlgorithmsView.prototype._stopInputsPropagation = false;

  AlgorithmsView.prototype.initialize = function() {
    AlgorithmsView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: AlgorithmsToolbar,
      props: {
        addAlgorithm: this.addAlgorithm
      }
    }));
    return this.enable(ReactComponent);
  };

  AlgorithmsView.prototype.addAlgorithm = function() {
    return AlgorithmsActions.addNewAlgorithm(mediator.project.id);
  };

  AlgorithmsView.prototype.afterRender = function() {
    AlgorithmsView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(AlgorithmsList, AlgorithmsActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id,
        opts: {
          filter: function(doc) {
            return doc._id.indexOf(ALGORITHM_PREFIX) === 0;
          }
        }
      }
    });
  };

  AlgorithmsView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('AlgorithmsStore');
    return AlgorithmsView.__super__.dispose.apply(this, arguments);
  };

  return AlgorithmsView;

})(View);
