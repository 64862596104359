var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  return "<p>\n  Recentemente è stato/a invitato/a dal Sistema Nazionale Linee Guida a compilare il documento sul Conflitto di Interessi per i membri e i partecipanti al Panel della Linea Guida.\n</p>\n<p>\n  Non abbiamo ancora ricevuto il suo documento compilato.\n  La preghiamo di collegarsi al link indicato di seguito e compilare il documento il più presto possibile <a href=\"__link__\">__link__</a>.\n</p>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}