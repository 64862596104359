ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
mediator = require 'mediator'
Modal = require 'components/common/modal'
QuestionsSelector = require 'components/common/questions_selector'
{ instanceOf, func, bool } = PropTypes

RelatedRecommendationsModal = createReactClass

  displayName: "RelatedRecommendationsModal"

  mixins: [
    CustomRenderMixin,
  ]

  propTypes:
    questions: instanceOf(Immutable.Map).isRequired
    questionGroups: instanceOf(Immutable.Map)
    relatedQuestionsIds: instanceOf(Immutable.List)
    updateRelatedQuestionsIds: func.isRequired
    withSelectAll: bool

  getDefaultProps: ->
    questionGroups: Immutable.Map()
    relatedQuestionsIds: Immutable.List()
    withSelectAll: false
    withSearch: false

  getInitialState: ->
    showModal: false
    relatedQuestionsIds: @props.relatedQuestionsIds

  onApply: ->
    @props.updateRelatedQuestionsIds @state.relatedQuestionsIds

  updateSelected: (questionIds) ->
    @setState relatedQuestionsIds: questionIds

  render: ->
    <Modal
      isOpen
      onClose={@props.onClose}
      closeButton={true}
      className="related-recommendations-modal"
    >
      <div className="selecting-questions">
        <QuestionsSelector
          currentQuestionId={mediator.currentQuestionId}
          questions={@props.questions}
          questionGroups={@props.questionGroups}
          updateSelected={@updateSelected}
          selectedQuestions={@state.relatedQuestionsIds}
          withSelectAll={@props.withSelectAll}
          withSearch={@props.withSearch}
        />
      </div>
      <ApplyCancelButtons
        applying={@props.applying}
        onApply={@onApply}
        onCancel={@props.onClose}
        wrappedWithCol={false}
      />
    </Modal>

module.exports = RelatedRecommendationsModal
