var mediator;

mediator = require('mediator');

module.exports = {
  getDXQuestions: function(questions) {
    var comparisonQuestions;
    comparisonQuestions = mediator.questions;
    return questions.filter(function(q) {
      var comparisonQuestion, type;
      type = q.get('type');
      if (type != null) {
        return type === 'diagnostic';
      }
      comparisonQuestion = comparisonQuestions.find(function(question) {
        return question.get('_id') === q.get('_id');
      });
      return comparisonQuestion != null ? comparisonQuestion.isDiagnostic() : void 0;
    });
  },
  getAllQuestions: function() {
    return this.props.questionGroups.flatMap(function(qG) {
      return qG.get('questions');
    });
  }
};
