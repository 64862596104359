var DbHelper, EventBroker, SynchronizationWatcher, W, mediator;

EventBroker = require('chaplin').EventBroker;

DbHelper = require('base/lib/db_helper');

mediator = require('mediator');

W = require('when/when');

module.exports = SynchronizationWatcher = (function() {
  _(SynchronizationWatcher.prototype).extend(EventBroker);

  function SynchronizationWatcher() {
    this.seqsMap = {};
    this.subscribeEvent('localDbModified', this.localDbModified);
    this.subscribeEvent('localChangesSent', this.localChangesSent);
  }

  SynchronizationWatcher.prototype._logReplStatus = function(source) {
    var seqs;
    if (window.gdt.debugMode) {
      seqs = this.seqsMap[source];
      return console.log("" + source + ": localSeq: " + (seqs != null ? seqs.local_seq : void 0) + " - lastSeq: " + (seqs != null ? seqs.last_seq : void 0));
    }
  };

  SynchronizationWatcher.prototype.localChangesSent = function(data) {
    var _base, _name;
    if ((_base = this.seqsMap)[_name = data.db_name] == null) {
      _base[_name] = {};
    }
    this.seqsMap[data.db_name].last_seq = data.last_seq;
    return this._logReplStatus(data.db_name);
  };

  SynchronizationWatcher.prototype.localDbModified = function(dbName, e) {
    var _base;
    if ((_base = this.seqsMap)[dbName] == null) {
      _base[dbName] = {};
    }
    this.seqsMap[dbName].local_seq = e.seq;
    return this._logReplStatus(dbName);
  };

  SynchronizationWatcher.prototype._checkUnsyncedDbDocs = function(dbName) {
    var centralDbUrl, localReplica;
    localReplica = DbHelper.localReplica(dbName);
    centralDbUrl = DbHelper.centralDbUrl(dbName);
    return localReplica.unsyncedLocalDocs({
      remote: centralDbUrl,
      keys: ''
    }).then((function(_this) {
      return function(unsyncedDocs) {
        if (_(unsyncedDocs).isEmpty()) {
          return _this.seqsMap[dbName] = null;
        }
      };
    })(this));
  };

  SynchronizationWatcher.prototype._checkForUnsyncedDocs = function() {
    var dbName, dbNames;
    dbNames = _(this.seqsMap).keys();
    return W.all((function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = dbNames.length; _i < _len; _i++) {
        dbName = dbNames[_i];
        _results.push(this._checkUnsyncedDbDocs(dbName));
      }
      return _results;
    }).call(this));
  };

  SynchronizationWatcher.prototype.syncStatus = function() {
    var notModified;
    notModified = _(this.seqsMap).reduce(function(acc, dbSeqs) {
      return acc && ((dbSeqs != null ? dbSeqs.local_seq : void 0) === (dbSeqs != null ? dbSeqs.last_seq : void 0) || !(dbSeqs != null ? dbSeqs.local_seq : void 0));
    }, true);
    if (mediator.services.replication.isConnected()) {
      if (!notModified) {
        this._checkForUnsyncedDocs();
      }
      return {
        inSync: notModified,
        reason: (!notModified ? 'replication_in_progress' : void 0)
      };
    } else {
      return {
        inSync: notModified,
        reason: (!notModified ? 'not_connected_changes_made' : void 0)
      };
    }
  };

  return SynchronizationWatcher;

})();
