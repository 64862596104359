var templatesDefinitions;

templatesDefinitions = [
  {
    id: 'document-sections',
    name: 'Document sections',
    chapters: [
      {
        id: 'abstract_Common',
        checked: true
      }, {
        id: 'executiveSummary_Common',
        checked: true
      }, {
        id: 'tableOfContents_Common',
        checked: true
      }, {
        id: 'introduction_Common',
        checked: true
      }, {
        id: 'scopeAndPurpose_Common',
        checked: true
      }, {
        id: 'methods_Common',
        checked: true
      }, {
        id: 'howToUse_Common',
        checked: true
      }, {
        id: 'generalIssues_Common',
        checked: true
      }, {
        id: 'keyQuestions_Common',
        checked: true
      }, {
        id: 'recommendations_Common',
        checked: true
      }, {
        id: 'updatingGuidelinesPlans_Common',
        checked: true
      }, {
        id: 'updatingRecommendationsLocally_Common',
        checked: true
      }, {
        id: 'conclusions_Common',
        checked: true
      }, {
        id: 'references_Common',
        checked: true
      }, {
        id: 'appendices_Common',
        checked: true
      }
    ]
  }, {
    id: 'document-sections-haigekassa',
    name: 'Document sections',
    chapters: [
      {
        id: 'abstract',
        checked: true
      }, {
        id: 'tableOfContents',
        checked: true
      }, {
        id: 'abbreviationsAndDefinitions',
        checked: true
      }, {
        id: 'introduction',
        checked: true
      }, {
        id: 'methods',
        checked: true
      }, {
        id: 'recommendations',
        checked: true
      }, {
        id: 'recommendationsWithShortSummary',
        checked: true
      }, {
        id: 'annexes',
        checked: true
      }, {
        id: 'references',
        checked: true
      }
    ]
  }, {
    id: 'recommendation-medicamento',
    name: 'Relatório de recomendaçã - Medicamento',
    featureSwitch: 'docSectionsTemplate',
    chapters: [
      {
        id: 'context',
        checked: true
      }, {
        id: 'presentation',
        checked: true
      }, {
        id: 'executiveSummary',
        checked: true
      }, {
        id: 'executiveSummaryEn',
        checked: true
      }, {
        id: 'clinicalCondition',
        checked: true
      }, {
        id: 'technology',
        checked: true
      }, {
        id: 'evidenceAnalysis',
        checked: true
      }, {
        id: 'preliminaryRecommendations',
        checked: true
      }, {
        id: 'publicConsultation',
        checked: true
      }, {
        id: 'finalRecommendation',
        checked: true
      }, {
        id: 'decision',
        checked: true
      }, {
        id: 'references',
        checked: true
      }, {
        id: 'annexes',
        checked: true
      }
    ]
  }, {
    id: 'recommendation-producto',
    name: 'Relatório de recomendaçã - Producto',
    featureSwitch: 'docSectionsTemplate',
    chapters: [
      {
        id: 'context',
        checked: true
      }, {
        id: 'presentation',
        checked: true
      }, {
        id: 'executiveSummary',
        checked: true
      }, {
        id: 'executiveSummaryEn',
        checked: true
      }, {
        id: 'clinicalCondition',
        checked: true
      }, {
        id: 'technology',
        checked: true
      }, {
        id: 'evidenceAnalysis',
        checked: true
      }, {
        id: 'preliminaryRecommendations',
        checked: true
      }, {
        id: 'publicConsultation',
        checked: true
      }, {
        id: 'finalRecommendation',
        checked: true
      }, {
        id: 'decision',
        checked: true
      }, {
        id: 'references',
        checked: true
      }, {
        id: 'annexes',
        checked: true
      }
    ]
  }, {
    id: 'technical-opinion-medicamento',
    name: 'Parecer técnico científico (PTC) - Medicamento',
    featureSwitch: 'docSectionsTemplate',
    chapters: [
      {
        id: 'context',
        checked: true
      }, {
        id: 'presentation',
        checked: true
      }, {
        id: 'executiveSummary',
        checked: true
      }, {
        id: 'executiveSummaryEn',
        checked: true
      }, {
        id: 'clinicalCondition',
        checked: true
      }, {
        id: 'technology',
        checked: true
      }, {
        id: 'evidenceAnalysis',
        checked: true
      }, {
        id: 'preliminaryRecommendations',
        checked: true
      }, {
        id: 'publicConsultation',
        checked: true
      }, {
        id: 'finalRecommendation',
        checked: true
      }, {
        id: 'decision',
        checked: true
      }, {
        id: 'references',
        checked: true
      }, {
        id: 'annexes',
        checked: true
      }
    ]
  }, {
    id: 'technical-opinion-producto',
    name: 'Parecer técnico científico (PTC) - Producto',
    featureSwitch: 'docSectionsTemplate',
    chapters: [
      {
        id: 'context',
        checked: true
      }, {
        id: 'presentation',
        checked: true
      }, {
        id: 'executiveSummary',
        checked: true
      }, {
        id: 'executiveSummaryEn',
        checked: true
      }, {
        id: 'clinicalCondition',
        checked: true
      }, {
        id: 'technology',
        checked: true
      }, {
        id: 'evidenceAnalysis',
        checked: true
      }, {
        id: 'preliminaryRecommendations',
        checked: true
      }, {
        id: 'publicConsultation',
        checked: true
      }, {
        id: 'finalRecommendation',
        checked: true
      }, {
        id: 'decision',
        checked: true
      }, {
        id: 'references',
        checked: true
      }, {
        id: 'annexes',
        checked: true
      }
    ]
  }, {
    id: 'technical-protocols-and-guidelines',
    name: 'Protocolos Clínicos e Diretrizes Terapêuticas (PCDT)',
    featureSwitch: 'docSectionsTemplate',
    chapters: [
      {
        id: 'context',
        checked: true
      }, {
        id: 'presentation',
        checked: true
      }, {
        id: 'executiveSummary',
        checked: true
      }, {
        id: 'executiveSummaryEn',
        checked: true
      }, {
        id: 'introduction',
        checked: true
      }, {
        id: 'icd10',
        checked: true
      }, {
        id: 'eligibilityCriteria',
        checked: true
      }, {
        id: 'methodology',
        checked: true
      }, {
        id: 'diagnosis',
        checked: true
      }, {
        id: 'ranking',
        checked: true
      }, {
        id: 'treatment',
        checked: true
      }, {
        id: 'drugsAndAdministrationScheme',
        checked: true
      }, {
        id: 'followUpAndMonitoring',
        checked: true
      }, {
        id: 'managementAndControl',
        checked: true
      }, {
        id: 'flows',
        checked: true
      }, {
        id: 'clarityAndResponsibilityTerm',
        checked: true
      }, {
        id: 'publicConsultation',
        checked: true
      }, {
        id: 'decision',
        checked: true
      }, {
        id: 'references',
        checked: true
      }, {
        id: 'annexes',
        checked: true
      }
    ]
  }
];

module.exports = templatesDefinitions;
