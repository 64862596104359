var GdtOpentip, Tooltips,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

GdtOpentip = (function(_super) {
  __extends(GdtOpentip, _super);

  GdtOpentip.visibleTooltips = {};

  function GdtOpentip() {
    GdtOpentip.__super__.constructor.apply(this, arguments);
    GdtOpentip.tips.length = 0;
    Opentip.lastZIndex = 10010;
  }

  GdtOpentip.prototype.getPosition = function(tipJoint, targetJoint, stem) {
    var el, height, paddingRight, position, tooltipHeight, width, yPosition, _ref;
    position = GdtOpentip.__super__.getPosition.apply(this, arguments);
    if (!this.options.target && this.options.targetY) {
      this.options.target = this.triggerElement;
      yPosition = GdtOpentip.__super__.getPosition.apply(this, arguments);
      position.top = yPosition.top;
      this.options.target = false;
    }
    if (this.options.tooltipContainer && this.options.tipJoint.left === 'left') {
      el = this.options.tooltipContainer;
      _ref = el.getBoundingClientRect(), width = _ref.width, height = _ref.height;
      tooltipHeight = this.dimensions.height;
      paddingRight = window.getComputedStyle(el).getPropertyValue('padding-right');
      position.left = width - parseInt(paddingRight) + 5;
      position.top = -(Math.abs(tooltipHeight - height)) / 2;
    }
    return position;
  };

  GdtOpentip.prototype._buildElements = function() {
    var headerElement, titleElement, _ref;
    this.tooltipElement = this.adapter.create("<div class=\"" + this["class"].opentip + "\">\n<div class=\"" + this["class"].header + "\"></div><div class=\"" + this["class"].content + "\"></div></div>");
    this.backgroundCanvas = this.adapter.wrap(document.createElement("canvas"));
    this.adapter.css(this.backgroundCanvas, {
      position: "absolute"
    });
    if (typeof G_vmlCanvasManager !== "undefined" && G_vmlCanvasManager !== null) {
      G_vmlCanvasManager.initElement(this.adapter.unwrap(this.backgroundCanvas));
    }
    headerElement = this.adapter.find(this.tooltipElement, "." + this["class"].header);
    if (this.options.title) {
      titleElement = this.adapter.create("<h1></h1>");
      this.adapter.update(titleElement, this.options.title, this.options.escapeTitle);
      this.adapter.append(headerElement, titleElement);
    }
    if (this.options.ajax && !this.loaded) {
      this.adapter.append(this.tooltipElement, this.adapter.create("<div class=\"" + this["class"].loadingIndicator + "\">\n<span>↻</span></div>"));
    }
    if (__indexOf.call(this.options.hideTriggers, "closeButton") >= 0) {
      this.closeButtonElement = this.adapter.create("<a href=\"javascript:undefined;\"\nclass=\"" + this["class"].close + "\"><span>Close</span></a>");
      this.adapter.append(headerElement, this.closeButtonElement);
    }
    this.adapter.append(this.container, this.backgroundCanvas);
    this.adapter.append(this.container, this.tooltipElement);
    this.adapter.append((_ref = this.options.tooltipContainer) != null ? _ref : document.body, this.container);
    this._newContent = true;
    return this.redraw = true;
  };

  GdtOpentip.prototype.show = function() {
    GdtOpentip.__super__.show.apply(this, arguments);
    return GdtOpentip.visibleTooltips[this.id] = this;
  };

  GdtOpentip.prototype.hide = function() {
    GdtOpentip.__super__.hide.apply(this, arguments);
    if (this.options.target) {
      this.options.target.removeData('tooltip-id');
      this.options.target.removeData('opentips');
    }
    return delete GdtOpentip.visibleTooltips[this.id];
  };

  return GdtOpentip;

})(Opentip);

module.exports = Tooltips = (function() {
  function Tooltips() {
    this.activeTooltips = {};
    GdtOpentip.styles.info = {
      "extends": 'alert',
      fixed: true,
      targetY: true,
      targetJoint: 'bottom',
      tipJoint: 'top center',
      background: '#000048',
      borderColor: '#000048',
      hideEffect: null,
      hideTriggers: ['trigger', 'trigger'],
      hideOn: ['mouseout', 'click'],
      shadow: false,
      removeElementsOnHide: true,
      delay: 0.5
    };
    GdtOpentip.styles.questionMark = {
      "extends": 'info',
      showOn: 'click',
      delay: 0
    };
    GdtOpentip.styles.loginInfo = {
      "extends": 'info',
      showOn: 'creation',
      hideOn: 'focusout',
      target: true,
      targetJoint: 'top left',
      tipJoint: 'right bottom',
      delay: 0
    };
    GdtOpentip.styles.error = {
      "extends": 'info',
      background: '#ce1717',
      borderColor: '#ce1717',
      delay: 0
    };
    GdtOpentip.styles.validationError = {
      "extends": 'error',
      showOn: 'creation',
      target: true,
      targetJoint: 'right',
      tipJoint: 'left',
      hideOn: 'never',
      showEffect: null
    };
    GdtOpentip.styles.reactValidationError = {
      "extends": 'validationError',
      background: '#ce1717',
      containInViewport: false
    };
  }

  Tooltips.prototype._getOptionsFromData = function($elem) {
    var _ref;
    return {
      escapeContent: !((_ref = $elem.data('tooltip-html')) != null ? _ref : true)
    };
  };

  Tooltips.prototype._addTooltips = function(jqueryObject, options, content) {
    var $elem, elem, title, tooltip, tooltipId, _i, _len, _results;
    this.hideTooltip(jqueryObject);
    _results = [];
    for (_i = 0, _len = jqueryObject.length; _i < _len; _i++) {
      elem = jqueryObject[_i];
      $elem = $(elem);
      title = content;
      if (!title) {
        title = $elem.prop('title');
        $elem.removeAttr('title');
        $elem.data('original-title', title);
      }
      if (title) {
        tooltip = new GdtOpentip($elem, title, _(options).extend(this._getOptionsFromData($elem)));
        tooltipId = tooltip.id;
        $elem.data('tooltip-id', tooltipId);
        _results.push(this.activeTooltips[tooltipId] = tooltip);
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  Tooltips.prototype.info = function(jqueryObject, options, content) {
    if (options == null) {
      options = {};
    }
    options.style = 'info';
    return this._addTooltips(jqueryObject, options, content);
  };

  Tooltips.prototype.questionMark = function(jqueryObject, options, content) {
    if (options == null) {
      options = {};
    }
    options.style = 'questionMark';
    return this._addTooltips(jqueryObject, options, content);
  };

  Tooltips.prototype.loginInfo = function(jqueryObject, content) {
    var options;
    options = {
      style: 'loginInfo'
    };
    return this._addTooltips(jqueryObject, options, content);
  };

  Tooltips.prototype.dynamicInfo = function(jqueryObject, contentFunction) {
    return this._addTooltips(jqueryObject, {
      style: 'info',
      cache: false
    }, contentFunction);
  };

  Tooltips.prototype.error = function(jqueryObject) {
    return this._addTooltips(jqueryObject, {
      style: 'error'
    });
  };

  Tooltips.prototype._bindTooltips = function(jqueryObject, error, style, options) {
    var $elem, elem, targetPosition, tipPosition, _i, _len;
    if (options == null) {
      options = {};
    }
    for (_i = 0, _len = jqueryObject.length; _i < _len; _i++) {
      elem = jqueryObject[_i];
      $elem = $(elem);
      tipPosition = $elem.data('tip-position');
      if (tipPosition) {
        _(options).extend({
          tipJoint: tipPosition
        });
      }
      targetPosition = $elem.data('target-position');
      if (targetPosition) {
        _(options).extend({
          targetJoint: targetPosition
        });
      }
    }
    _(options).extend({
      style: style
    });
    return this._addTooltips(jqueryObject, options, error);
  };

  Tooltips.prototype.validationError = function(jqueryObject, error) {
    return this._bindTooltips(jqueryObject, error, 'validationError');
  };

  Tooltips.prototype.reactValidationError = function(jqueryObject, options, error) {
    return this._bindTooltips(jqueryObject, error, 'reactValidationError', options);
  };

  Tooltips.prototype.hideTooltip = function(jqueryObject) {
    var $elem, elem, tooltip, tooltipId, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = jqueryObject.length; _i < _len; _i++) {
      elem = jqueryObject[_i];
      $elem = $(elem);
      tooltipId = $elem.data('tooltip-id');
      if (!tooltipId) {
        continue;
      }
      tooltip = GdtOpentip.visibleTooltips[tooltipId];
      _results.push(tooltip != null ? tooltip.hide() : void 0);
    }
    return _results;
  };

  Tooltips.prototype.deactivateTooltip = function(jqueryObject) {
    var $elem, elem, tooltipId, _i, _len, _ref, _results;
    _results = [];
    for (_i = 0, _len = jqueryObject.length; _i < _len; _i++) {
      elem = jqueryObject[_i];
      $elem = $(elem);
      tooltipId = $elem.data('tooltip-id');
      if (!tooltipId) {
        continue;
      }
      if ((_ref = this.activeTooltips[tooltipId]) != null) {
        _ref.deactivate();
      }
      $elem.attr('title', $elem.data('original-title'));
      $elem.data('original-title', null);
      $elem.data('tooltip-id', null);
      $elem.data('opentips', null);
      _results.push(this.activeTooltips[tooltipId] = null);
    }
    return _results;
  };

  return Tooltips;

})();
