Todo = require 'components/common/todos_todo'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

TodosComponent = createReactClass
  displayName: 'TodosComponent'

  propTypes:
    todos: PropTypes.instanceOf(Immutable.List).isRequired
    saveTodos: PropTypes.func.isRequired
    readOnly: PropTypes.bool

  mixins: [
    CustomRenderMixin
    Translation('')
  ]

  getInitialState: ->
    editingTodo: null
    todos: @props.todos

  getTodos: -> @state.todos

  _saveTodos: (newTodos) ->
    @setState
      todos: newTodos
      editingTodo: null
    , => @props.saveTodos @state.todos

  _updateTodo: (todos = @state.todos, todoIdx = @state.editingTodo, newData) ->
    todos.update todoIdx, (currentData) -> currentData.merge newData

  handleTodoEdit: (todoIdx) -> =>
    # save any editing todo before setting the new one
    { editingTodo } = @state
    @["todo#{editingTodo}"].onSave() if editingTodo?

    @setState editingTodo: todoIdx

  handleTodoSave: (todoIdx) -> (newData) =>
    newTodos = @_updateTodo null, todoIdx, newData
    @_saveTodos newTodos

  handleTodoDelete: (todoIdx) -> =>
    newTodos = @state.todos.delete todoIdx
    @_saveTodos newTodos

  onAddTodo: ->
    newTodos = @state.todos.withMutations (todos) =>
      # save any editing todo as we will reset editing todo with new one
      { editingTodo } = @state
      if editingTodo?
        todos = @_updateTodo todos, editingTodo, text: @["todo#{editingTodo}"].getText()
      # add new todo
      todos.push Immutable.Map
        completed: false
        text: @i18n 'scope:new_task'

    @setState
      todos: newTodos
      editingTodo: newTodos.size - 1

  componentWillUnmount: ->
    # save any unsave editing todo
    { editingTodo } = @state
    if editingTodo?
      todos = @_updateTodo null, editingTodo, text: @["todo#{editingTodo}"].getText()
      @props.saveTodos todos

  renderTodo: (todo, todoIdx) ->
    <Todo {...todo.toJS()}
      ref={(el) => @["todo#{todoIdx}"] = el}
      key={todoIdx}
      readOnly={@props.readOnly}
      isEditing={@state.editingTodo is todoIdx}
      onEdit={@handleTodoEdit todoIdx}
      onSave={@handleTodoSave todoIdx}
      onDelete={@handleTodoDelete todoIdx}
    />

  render: ->
    <div className='todos-component-container'>
      <div className='todos'>
        {@state.todos.map(@renderTodo).toList()}
      </div>
      <div className='add-todo'>
        <button className='btn' onClick={@onAddTodo} disabled={@props.readOnly}>
          {@i18n 'scope:add_task'}
        </button>
      </div>
    </div>

module.exports = TodosComponent
