ConnectStore = require 'components/enhancers/connect_store'
DBEPPublicationActions = require 'actions/dbep_publication_actions'
DBEPPublicationStore = require 'stores/dbep_publication_store'
DBEPQuestionsTableQuestion = require './questions_table_question'
IconButton = require 'components/common/icon_button'
QuestionsList = require 'components/evidence_syntheses/questions_list/questions_list'
Scrollable = require 'components/common/scrollable'
SearchBar = require 'components/common/search_bar'
Select = require 'components/common/select_custom'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'

QUESTION_STATUSES = ['all', 'published', 'in_review', 'unpublished']

storeConnector =
  DBEPPublicationStore: (Store) ->
    questions: Store.getQuestions()
    questionGroups: Store.getQuestionGroups()
    questionStatuses: Store.getQuestionStatuses()
    searchText: Store.getTableSearchText()
    statusFilter: Store.getTableStatusFilter()
    isFetchingQuestions: Store.isFetchingQuestions()
    isFetchingStatuses: Store.isFetchingStatuses()

DBEPQuestionsTable = createReactClass
  displayName: 'DBEPQuestionsTable'
  mixins: [Translation('dbep:questions_list_screen')]

  propTypes:
    questions: PropTypes.instanceOf(Immutable.Map).isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    questionStatuses: PropTypes.instanceOf(Immutable.Map).isRequired
    searchText: PropTypes.string.isRequired
    statusFilter: PropTypes.oneOf(QUESTION_STATUSES).isRequired
    isFetchingQuestions: PropTypes.bool.isRequired
    isFetchingStatuses: PropTypes.bool.isRequired

  changeSearchText: (searchText) ->
    DBEPPublicationActions.searchOnTable searchText

  resetSearchText: ->
    @changeSearchText ''

  getStatusFilterOptions: ->
    _.map QUESTION_STATUSES, (status) =>
      text: @i18n "statuses.#{status}"
      value: status

  previewQuestions: ->
    DBEPPublicationActions.openModal true

  publishQuestions: ->
    DBEPPublicationActions.openModal false

  render: ->
    {
      searchText,
      statusFilter,
      questions,
      questionGroups,
      questionStatuses,
      isFetchingQuestions,
      isFetchingStatuses
    } = @props

    return <Spinner /> if isFetchingQuestions or isFetchingStatuses

    filteredQuestions = questions
      .filter (question) ->
        question.get('question')?.indexOf(searchText) >= 0
      .filter (question) ->
        status = questionStatuses.getIn ['statuses', question.get '_id']
        status ?= 'unpublished'
        statusFilter in ['all', status]

    <div className="dbep-questions-table">
      <div className="dbep-questions-table__search-bar">
        <div className="search-bar__search">
          <SearchBar
            searchOnChange
            containerClass='questions-search'
            onSearch={@changeSearchText}
            onSearchReset={@resetSearchText}
            searchText={searchText}
          />
        </div>
        <div className="search-bar__controls">
          {###DISABLED UNTIL PLAY IS ABLE TO PROCESS HIGHER LOAD <div className="controls__buttons">
            <IconButton
              iconName="eye"
              label={@i18n '/actions.preview_questions'}
              onClick={@previewQuestions}
            />
            <IconButton
              iconName="bookmark"
              label={@i18n '/actions.publish_questions'}
              onClick={@publishQuestions}
            />
          </div>###}
          <div className="controls__status-filter">
            <div className="status-filter__text">{@i18n 'status'}</div>
            <Select
              className="status-filter__select"
              options={@getStatusFilterOptions()}
              selected={statusFilter}
              onChange={DBEPPublicationActions.changeStatusFilterOnTable}
            />
          </div>
        </div>
      </div>
      <div className="dbep-questions-table__dbep-table">
        <div className="dbep-table__header">
          <div className="dbep-table__row">
            {_.map ['question', 'status', 'actions'], (cell) =>
              <div className={"dbep-table__cell #{cell}"} key={cell}>
                <div className="cell-content">{@i18n "cells.#{cell}"}</div>
              </div>
            }
          </div>
        </div>
        <div className="dbep-table__body">
          {if filteredQuestions.isEmpty()
            status = @i18n "statuses.#{statusFilter}"
            text = if searchText is '' and statusFilter is 'all'
              @i18n '/messages.no_questions_in_project'
            else if searchText isnt '' and statusFilter isnt 'all'
              @i18n 'search.no_questions_matching_both_criteria', { status, searchText }
            else if searchText isnt ''
              @i18n 'search.no_questions_matching_search_text', { searchText }
            else
              @i18n 'search.no_questions_matching_status', { status }
            <p>{text}</p>
          else
            <Scrollable>
              <QuestionsList
                questions={filteredQuestions}
                questionGroups={questionGroups}
                withEmptyQuestionGroups={false}
              >
                <DBEPQuestionsTableQuestion />
              </QuestionsList>
            </Scrollable>
          }
        </div>
      </div>
    </div>

module.exports = ConnectStore DBEPQuestionsTable, [DBEPPublicationStore], storeConnector
