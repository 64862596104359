var DuplicatesView, ModuleController, ReferencesController, ReferencesView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

mediator = require('mediator');

ReferencesView = require('views/references/references_view');

DuplicatesView = require('views/references/duplicates_view');

module.exports = ReferencesController = (function(_super) {
  __extends(ReferencesController, _super);

  function ReferencesController() {
    return ReferencesController.__super__.constructor.apply(this, arguments);
  }

  ReferencesController.prototype.viewsForSubmodules = {
    'duplicates': DuplicatesView
  };

  ReferencesController.prototype.index = function() {
    return this.view = new ReferencesView({
      collection: mediator.colls.references
    });
  };

  return ReferencesController;

})(ModuleController);
