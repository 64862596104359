var DraftQuestion, Model, QUESTION_DEFAULTS, calculateTitles, utils, _ref,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('base/models/model');

utils = require('base/lib/utils');

_ref = require('base/lib/questions_helper'), QUESTION_DEFAULTS = _ref.QUESTION_DEFAULTS, calculateTitles = _ref.calculateTitles;

module.exports = DraftQuestion = (function(_super) {
  __extends(DraftQuestion, _super);

  function DraftQuestion() {
    return DraftQuestion.__super__.constructor.apply(this, arguments);
  }

  DraftQuestion.QUESTION_FORMATS = [
    {
      value: 'FOR_HP',
      label: $.t('es:question.for_hp_label')
    }, {
      value: 'IN_POP',
      label: $.t('es:question.in_pop_label')
    }
  ];

  DraftQuestion.DIAGNOSTIC_VERBS = [
    {
      value: 'diagnose',
      label: $.t('es:question.diagnose_label')
    }, {
      value: 'screen',
      label: $.t('es:question.screen_label')
    }
  ];

  DraftQuestion.prototype.defaults = _.pick(QUESTION_DEFAULTS, 'healthProblemOrPopulation');

  DraftQuestion.prototype.managementDefaults = _.pick(QUESTION_DEFAULTS, ['intervention', 'comparison', 'questionFormat']);

  DraftQuestion.prototype.diagnosticDefaults = _.pick(QUESTION_DEFAULTS, ['indexTest', 'comparatorTest', 'targetCondition', 'comparatorTestPresent', 'diagnosticVerb']);

  DraftQuestion.prototype.freeformDefaults = _.pick(QUESTION_DEFAULTS, ['question', 'questionShort']);

  DraftQuestion.prototype.isDiagnostic = function() {
    return this.get('type') === 'diagnostic';
  };

  DraftQuestion.prototype._updateDiagnosticQuestion = function() {
    if (!this.isDiagnostic()) {
      return;
    }
    if (this.get('indexTest') == null) {
      this.set('indexTest', this.get('intervention'));
    }
    if (this.get('comparatorTest') == null) {
      return this.set('comparatorTest', this.get('comparison'));
    }
  };

  DraftQuestion.prototype.initialize = function() {
    var additionalDefaults;
    if (!this.get('type')) {
      throw new Error('No question type specified');
    }
    this._updateDiagnosticQuestion();
    DraftQuestion.__super__.initialize.apply(this, arguments);
    additionalDefaults = (function() {
      switch (this.get('type')) {
        case 'management':
          return this.managementDefaults;
        case 'diagnostic':
          return this.diagnosticDefaults;
        case 'freeform':
          return this.freeformDefaults;
        default:
          throw new Error('Invalid question type');
      }
    }).call(this);
    _(this.attributes).defaults(additionalDefaults);
    this.titleAttributes = _.union(_(this.defaults).keys(), _(additionalDefaults).keys());
    this.on((_(this.titleAttributes).map(function(prop) {
      return "change:" + prop;
    })).join(' '), function() {
      return this.set(this._calculateTitles());
    });
    this.set(this._calculateTitles());
    return this._dirty = false;
  };

  DraftQuestion.prototype.parse = function(response) {
    response = DraftQuestion.__super__.parse.apply(this, arguments);
    return _(response).extend(this._calculateTitles(response));
  };

  DraftQuestion.prototype._calculateTitles = function(attributes) {
    var params;
    if (attributes == null) {
      attributes = this.attributes;
    }
    params = _.extend(_(attributes).pick(this.titleAttributes), {
      type: attributes.type
    });
    return calculateTitles(params);
  };

  DraftQuestion.prototype.hasComment = function(section) {
    var comments;
    if (_.isEmpty(this.get(section))) {
      return false;
    }
    comments = _.without(this.get(section), "");
    return !_.isEmpty(comments);
  };

  DraftQuestion.prototype.duplicate = function() {
    var attrs;
    attrs = this.parse(_(this.toJSON()).omit('id', 'orderNumber'));
    return new DraftQuestion(attrs);
  };

  return DraftQuestion;

})(Model);
