VotingJudgementCell = require 'components/etd/voting_judgement_cell'
VotingCommentsCell = require 'components/etd/voting_comments_cell'
VotingPropositionsCell = require 'components/etd/voting_propositions_cell'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

{ SectionRow, Card, CardContent, CellWithTitle } = ReactComponents

ConclusionsSectionVotingDetailsRow = createReactClass
  displayName: 'ConclusionsSectionVotingDetailsRow'

  propTypes:
    sectionId: PropTypes.string.isRequired
    getPossibleVotingOptions: PropTypes.func.isRequired
    isProposedVoting: PropTypes.bool.isRequired

  mixins: [CustomRenderMixin]

  render: ->
    return <tr /> unless @props.votingResults

    <SectionRow className="voting-details">
      <CellWithTitle className="voting-details__judgement">
        <Card>
          <CardContent>
            <VotingJudgementCell
              etdPart={@props.etdPart}
              renderMode={@props.renderMode}
              draftJudgement={@props.draftJudgement}
              isProposedVoting={@props.isProposedVoting}
              votingResults={@props.votingResults}
              votingOptions={@props.getPossibleVotingOptions()}
              votesExpected={@props.noOfVotingForms}
            />
          </CardContent>
        </Card>
      </CellWithTitle>
      <CellWithTitle className="voting-details__comments">
        <Card>
          <CardContent>
            <VotingCommentsCell
              colSpan={4}
              etdPart={@props.etdPart}
              renderMode={@props.renderMode}
              sectionId={@props.sectionId}
              votingResults={@props.votingResults}
              votingOptions={@props.getPossibleVotingOptions()}
              isProposedVoting={@props.isProposedVoting}
              adminComment={@props.adminComment}
              hiddenVotes={@props.hiddenVotes}
            />
          </CardContent>
        </Card>
      </CellWithTitle>
    </SectionRow>

module.exports = ConclusionsSectionVotingDetailsRow
