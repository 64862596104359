mediator = require 'mediator'
BaseTemplateSelection = require 'components/administration/base_template_selection'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ConfirmationModal = require 'components/common/confirmation_modal'
EditableItem = require 'components/common/editable_item'
EtdTemplatesActions = require 'actions/etd_templates_actions'
IconButton = require 'components/common/icon_button'
TemplateButtons = require 'components/administration/template_buttons'
TemplateSectionsSelection = require 'components/administration/template_sections_selection'
Translation = require 'components/mixins/translation'

SelectedTemplate = createReactClass
  displayName: 'SelectedTemplate'

  mixins: [CustomRenderMixin, Translation()]

  getInitialState: ->
    confirmationModalOpen: false
    baseTemplateChanged: false

  editTemplate: ->
    @props.editTemplate @props.templateType

  changeBaseTemplate: ->
    @setState baseTemplateChanged: true
    @props.changeBaseTemplate arguments...

  componentWillReceiveProps: (nextProps) ->
    unless nextProps.editingTemplate
      @setState baseTemplateChanged: false

  onConfirmRefresh: ->
    { template, templateType } = @props
    EtdTemplatesActions.refreshTemplate template.getIn(['templateDef', 'id']), templateType
    @closeConfirmationDialog()

  refreshTemplate: ->
    @setState confirmationModalOpen: true

  closeConfirmationDialog: ->
    @setState confirmationModalOpen: false

  render: ->
    {
      baseTemplateChanged
      baseTemplateName
      editingTemplate
      SectionsSelectionComponent
      template
      templateChanged
      templateDescription
      templateLanguages
      templateName
      templatesOptions
      templateType
    } = @props

    # if currently editing template is not this template then do not display it
    if editingTemplate and editingTemplate isnt templateType
      null
    else if templateName
      <div className="selected-template-block">
        <div>{templateDescription}</div>
        <div className="template-box">
          <ul className="standard-list">
            <EditableItem.Item
              editing={editingTemplate is templateType}
              edit={@editTemplate}>
              <EditableItem.Static>
                <div>{templateName}</div>
              </EditableItem.Static>
              <EditableItem.Editing renderSave={false} renderDelete={false}>
                <div className="template-selection-editing">
                  <div className="flex flex-row">
                    <div className="flex-grow">
                      <BaseTemplateSelection
                        templatesOptions={templatesOptions}
                        templateLanguages={templateLanguages}
                        listTxTemplates={templateType is 'tx'}
                        listDxTemplates={templateType is 'dx'}
                        listOverarchingDxTemplates={templateType is 'overarchingDx'}
                        listOverarchingTxTemplates={templateType is 'overarchingTx'}
                        listKeyMessageTemplates={templateType is 'keyMessage'}
                        selectedTemplate={template.getIn ['templateDef', 'id']}
                        selectedTemplateLanguage={template.getIn(['templateDef', 'lang'], 'en')}
                        changeBaseTemplate={@changeBaseTemplate}
                      />
                    </div>
                    {mediator.user.isAdmin() and <IconButton
                      title={@i18n "actions.refresh_template"}
                      iconName="refresh"
                      onClick={@refreshTemplate}
                    />}
                  </div>
                  <SectionsSelectionComponent
                    template={template}
                    templateType={templateType}
                    templateName={baseTemplateName}
                    nameEditable={true}
                    templateChanged={templateChanged or @state.baseTemplateChanged}
                    autoUpdateName={false}
                    templateInUse={true} />
                </div>
              </EditableItem.Editing>
            </EditableItem.Item>
          </ul>
        </div>
        <ConfirmationModal
          isOpen={@state.confirmationModalOpen}
          question={@i18n 'messages.are_you_sure_you_want_to_refresh_template'}
          confirmLabel={@i18n 'actions.refresh'}
          onConfirm={@onConfirmRefresh}
          onCancel={@closeConfirmationDialog}
        />
      </div>
    else
      null

module.exports = SelectedTemplate
