MultiComparisonsActions = require 'actions/multi_comparisons_actions'
OverarchingQuestion = require './overarching_question'
Router = require 'router'
mediator = require 'mediator'

MultiComparisonQuestionsGroup = createReactClass
  displayName: 'MultiComparisonQuestionsGroup'

  propTypes:
    mcId: PropTypes.string.isRequired
    multiComparison: PropTypes.instanceOf(Immutable.Map).isRequired,
    onDelete: PropTypes.func.isRequired

  editMultiComparison: ->
    MultiComparisonsActions.editMultiComparison @props.mcId, true

  onDelete: ->
    @props.onDelete @props.mcId

  onSave: ->
    MultiComparisonsActions.saveComparison()

  isEditing: ->
    @props.editingComparison and @props.comparisonsEditData.get('_id') is @props.mcId

  goToTable: ->
    MultiComparisonsActions.goToMultiComparisonsTable @props.mcId

  componentWillUnmount: ->
    MultiComparisonsActions.saveComparison() if @props.editingComparison

  render: ->
    <OverarchingQuestion
      editingComparison={@isEditing()}
      questions={@props.questions}
      questionGroups={@props.questionGroups}
      comparisonsEditData={@props.comparisonsEditData}
      multiComparison={@props.multiComparison}
      editing={@isEditing()}
      headerClickAction={@goToTable}
      onSave={@onSave}
      onDelete={@onDelete}
      onEdit={@editMultiComparison}
    />

module.exports = MultiComparisonQuestionsGroup
