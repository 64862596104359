DocSectionsFromTemplateActions = require 'actions/doc_sections_from_template_actions'
DocSectionsEditor = require 'components/document_sections/from_template/doc_sections_editor'
DocSectionsReferencesChapter =
  require 'components/document_sections/from_template/doc_sections_references_chapter'
Translation = require 'components/mixins/translation'
{ SectionHeaderWithCounterAndExpand } = ReactComponents
IconButton = require 'components/common/icon_button'
AddCustomTooltip = require 'components/enhancers/add_custom_tooltip'

SettingsButton = (props) ->
  <IconButton
    iconName="gear"
    {...props}
  />

SettingsList = ({ i18n, settingsList }) ->
  <ul className="document-sections__chapter-settings">
    {settingsList.map (setting) ->
      <li key={setting['key']} onClick={setting.action}>{setting['label']}</li>
    }
  </ul>

tooltipParams = trigger: 'click', position: 'bottom', alignment: 'end', positionOffset: -5
SettingsButtonWithList = AddCustomTooltip SettingsButton, SettingsList, tooltipParams

DocSectionsChapter = createReactClass
  displayName: 'DocSectionsChapter'

  mixins: [ Translation('docsec:from_template') ]

  propTypes:
    index: PropTypes.number.isRequired
    renderMode: PropTypes.string
    chapterId: PropTypes.string.isRequired
    chapterData: PropTypes.instanceOf(Immutable.Map)
    attachments: PropTypes.instanceOf(Immutable.Map)
    referenceIds: PropTypes.arrayOf(PropTypes.string)
    refreshEditors: PropTypes.func
    referencesState: PropTypes.number

  toggleExpand: ->
    DocSectionsFromTemplateActions.toggleChapterExpand @props.chapterId

  getSettingsListOptions: (subsectionIdx ,subsectionsSize) ->
    options = [
        key: 'edit'
        label: @i18n 'section_settings_menu.edit_title'
        action: =>
          DocSectionsFromTemplateActions.editChapterSubsection(@props.chapterId, subsectionIdx)
      ,
        key: 'add'
        label: @i18n 'section_settings_menu.new_subchapter'
        action: =>
          DocSectionsFromTemplateActions.showAddChapterSubsection(@props.chapterId)
      ,
        key: 'duplicate'
        label: @i18n 'section_settings_menu.duplicate_subchapter'
        action: =>
          DocSectionsFromTemplateActions.duplicateSubchapter @props.chapterId, subsectionIdx
      ]

    if subsectionsSize > 1
      options.concat
        key: 'delete'
        label: @i18n 'section_settings_menu.delete_subchapter'
        action: =>
          DocSectionsFromTemplateActions
            .markChapterSubsectionToDelete(@props.chapterId, subsectionIdx)
    else
      options

  showSettingsOptions: ->
    @setState settingsOpened: true

  render: ->
    {
      expanded,
      index,
      renderMode,
      chapterData,
      chapterId,
      attachments,
      referenceIds,
      refreshEditors,
      referencesState
    } = @props
    <tbody className="document-sections__chapter">
      <SectionHeaderWithCounterAndExpand
        className={
          classNames(
            "chapter-section-header",
            "chapter-section-header--collapsed": not expanded
          )
        }
        expanded={expanded}
        index={index}
        onToggle={@toggleExpand}
        renderMode={renderMode}
        withIndex={renderMode isnt 'printout'}
        withToggle={renderMode isnt 'printout'}
      >
        <div className="chapter-section-header__title" onClick={@toggleExpand}>
          {chapterData.get 'name'}
        </div>
      </SectionHeaderWithCounterAndExpand>
      {if expanded
        subsectionsSize = chapterData.get('subsections').size
        chapterType = chapterData.get('type')
        chapterData.get('subsections').map (subsection, idx) =>
          <React.Fragment>
            <tr>
              <td className="chapter-section-title-row">
                <span className="chapter-section-title-row__title">
                  {subsection.get('title') unless subsection.get('titleHidden')}
                </span>

                {unless renderMode is 'printout' or chapterType is 'references'
                  <SettingsButtonWithList
                    tooltipProps={
                      settingsList: @getSettingsListOptions(idx, subsectionsSize)
                    }
                  />
                }
              </td>
            </tr>
            <tr>
              <td>
                {if chapterType is 'references'
                  <DocSectionsReferencesChapter renderMode={renderMode} />
                else
                  <DocSectionsEditor
                    content={subsection.get 'content'}
                    chapterId={chapterId}
                    attachments={attachments}
                    subsectionIdx={idx}
                    referenceIds={referenceIds}
                    refreshEditors={refreshEditors}
                    referencesState={referencesState}
                  />
                }
              </td>
            </tr>
          </React.Fragment>
      }
      {if expanded
        <tr className="document-sections__chapter--separator" />
      }
    </tbody>

module.exports = DocSectionsChapter
