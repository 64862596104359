CollapsibleWithControls = require 'components/common/collapsible_with_controls'
ConnectStore = require 'components/enhancers/connect_store'
DynamicHeightContainer = require 'components/common/dynamic_height_container'
EditableQuestionsList =
  require 'components/evidence_syntheses/questions_list/connected_editable_questions_list'
EllipsizedText = require 'components/common/ellipsized_text'
MdaTableActions = require 'actions/mda_table_actions'
{ MDA_TABLE: MDA_TABLE_DOC_TYPE } = require 'lib/db_docs/doc_types'
mediator = require 'mediator'
OrganizationsStore = require 'stores/organizations_store'
QuestionsActions = require 'actions/questions_actions'
QuestionsList = require 'components/evidence_syntheses/questions_list/questions_list'
QuestionsStore = require 'stores/questions_store'
QuestionGroupsStore = require 'stores/question_groups_store'
Router = require 'router'
Spinner = require 'components/common/spinner'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

questionGroups = Immutable.fromJS({ groups: [], mapping: {} })

storeConnector =
  QuestionsStore: (Store) ->
    isFetchingQuestions: Store.isFetching()
    questions: Store.getSortedQuestionsByKey MDA_TABLE_DOC_TYPE
  OrganizationsStore: (Store) ->
    projectFromOrganization: Store.isCurrentProjectFromOrganization()

MdaTableListItem = ({ data }) ->
  i18n = useI18n('mda:tables')

  handleClick = useCoffeeCallback [data], ->
    link = Router::routeForSubmodule 'mda-tables', null, questionId: data.get '_id'
    mediator.publish '!router:route', link

  handleEdit = useCoffeeCallback [data], ->
    name = data.get 'name'
    tableId = data.get '_id'
    MdaTableActions.openCreateOrEditDialog { name, tableId }

  handleDelete = useCoffeeCallback [data], ->
    QuestionsActions.deleteQuestion data.get '_id'

  handleDuplicate = useCoffeeCallback [data], ->
    QuestionsActions.duplicateQuestion questionId: data.get '_id'

  notUsed = useCoffeeMemo [data], -> data.get('topics', Immutable.List()).isEmpty()

  CONTROLS = [
    name: 'edit'
    handler: handleEdit
  ,
    name: 'duplicate'
    handler: handleDuplicate
  ,
    name: 'delete'
    handler: handleDelete
  ]

  <CollapsibleWithControls containerClassName="mda-table-list__item" controls={CONTROLS}>
    <div className="table-name">
      <div>
        <EllipsizedText height={40} onClick={handleClick} text={data.get('name') or ''} />
      </div>
       {notUsed and <span className="not-used-flag">{i18n 'not_used'}</span>}
    </div>
    <div />
  </CollapsibleWithControls>

MdaTableListItem.propTypes =
  data: PropTypes.instanceOf(Immutable.Map)

MdaTableList = ({
  isFetchingQuestions
  projectFromOrganization
  questions
}) ->
  i18n = useI18n 'mda:tables'

  return <Spinner /> if isFetchingQuestions
  <div className='comparison-questions-list'>
    <div>
      <DynamicHeightContainer className="mda-table-list">
        <EditableQuestionsList
          customNewGroupLabel={i18n 'new_group_area'}
          projectFromOrganization={projectFromOrganization}
          questions={questions}
          questionGroups={questionGroups}
          withNewGroupDropArea={false}
          withDnD={false}
        >
          <MdaTableListItem />
        </EditableQuestionsList>
      </DynamicHeightContainer>
    </div>
  </div>

MdaTableList.propTypes =
  isFetchingQuestions: PropTypes.bool.isRequired
  projectFromOrganization: PropTypes.bool.isRequired
  questions: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.OrderedMap),
    PropTypes.instanceOf(Immutable.Map)]
  ).isRequired
  questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore MdaTableList, [
  OrganizationsStore
  QuestionsStore
], storeConnector
