var INITIAL_STATE, MDA_TABLE, MdaVerificationActions, appUtils, generateStore, mediator;

appUtils = require('lib/app_utils');

generateStore = require('stores/utils/generate_store');

MDA_TABLE = require('lib/db_docs/doc_types').MDA_TABLE;

MdaVerificationActions = require('actions/mda_verification_actions');

mediator = require('mediator');

INITIAL_STATE = Immutable.fromJS({
  isFetching: false,
  masterTopics: {},
  usedTables: {},
  usedTopics: {}
});

module.exports = generateStore({
  name: 'MdaVerificationStore',
  initialState: INITIAL_STATE,
  boundActions: [MdaVerificationActions],
  methods: {
    onDbChange: function(_arg) {
      var doc, id, keyToUpdate;
      id = _arg.id, doc = _arg.doc;
      keyToUpdate = doc.docType === MDA_TABLE ? 'usedTables' : 'usedTopics';
      if (doc._deleted) {
        return this.setState(this.state.deleteIn([keyToUpdate, id]));
      } else {
        return this.setState(this.state.setIn([keyToUpdate, id], Immutable.fromJS(doc)));
      }
    },
    onFetch: function() {
      return this.setState(this.state.set('isFetching', true));
    },
    onFetchError: function() {
      return this.setState(INITIAL_STATE);
    },
    onFetchSuccess: function(_arg) {
      var masterTopics, usedTables, usedTopics;
      masterTopics = _arg.masterTopics, usedTables = _arg.usedTables, usedTopics = _arg.usedTopics;
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetching', false);
        state.set('masterTopics', Immutable.fromJS(masterTopics));
        state.set('usedTables', Immutable.fromJS(usedTables));
        return state.set('usedTopics', Immutable.fromJS(usedTopics));
      }));
    },
    isFetching: function() {
      return this.state.get('isFetching');
    },
    getMasterTopics: function() {
      return this.state.get('masterTopics');
    },
    getUsedTables: function() {
      return this.state.get('usedTables');
    },
    getUsedTopics: function() {
      return this.state.get('usedTopics');
    }
  }
});
