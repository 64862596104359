var Focusable, ModalView, TimeToEventModal, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

Focusable = require('base/lib/traits/focusable');

template = require('views/templates/outcome_edit/time_to_event_modal');

mediator = require('mediator');

module.exports = TimeToEventModal = (function(_super) {
  __extends(TimeToEventModal, _super);

  function TimeToEventModal() {
    return TimeToEventModal.__super__.constructor.apply(this, arguments);
  }

  TimeToEventModal.prototype.template = template;

  TimeToEventModal.prototype.id = 'time-to-event';

  TimeToEventModal.prototype.title = '';

  TimeToEventModal.prototype.shortcuts = {
    'esc': 'cancel'
  };

  TimeToEventModal.prototype.initialize = function() {
    TimeToEventModal.__super__.initialize.apply(this, arguments);
    this.delegate('click', function(e) {
      return e.stopPropagation();
    });
    this.delegate('click', 'span.close', this.cancel);
    this.delegate('click', 'button.btn-apply', this.save);
    this.delegate('click', 'button.btn-cancel', this.cancel);
    this.delegate('change', 'input[type="radio"]', this.updateEventType);
    this.delegate('change', 'input[data-property="nonEvent"]', this.updateNonEvent);
    return this.enable(Focusable);
  };

  TimeToEventModal.prototype.updateNonEvent = function(e) {
    return this.model.set('nonEvent', this.$(e.target).val());
  };

  TimeToEventModal.prototype.updateEventType = function(e) {
    if (this.$(e.target).val() === 'non_event') {
      this.$('div.non-event-input').show();
    } else {
      this.$('div.non-event-input').hide();
    }
    this.model.set('eventType', this.$(e.target).val());
    return this.model.save();
  };

  TimeToEventModal.prototype.save = function() {
    var _ref;
    this.model.save();
    if ((_ref = this.parentView) != null) {
      _ref.render();
    }
    this.parentView.openEditView(false);
    return this.close();
  };

  TimeToEventModal.prototype.afterRender = function() {
    TimeToEventModal.__super__.afterRender.apply(this, arguments);
    if (this.model.get('eventType') === 'non_event') {
      return this.$('div.non-event-input').show();
    } else {
      return this.$('div.non-event-input').hide();
    }
  };

  TimeToEventModal.prototype.getTemplateData = function() {
    return _(TimeToEventModal.__super__.getTemplateData.apply(this, arguments)).extend({
      outcome: this.model.get('name')
    });
  };

  TimeToEventModal.prototype.close = function() {
    return this.dispose();
  };

  TimeToEventModal.prototype.cancel = function(e) {
    e.stopPropagation();
    return this.close();
  };

  return TimeToEventModal;

})(ModalView);
