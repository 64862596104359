ConfirmationModal = require 'components/common/confirmation_modal'
ConnectStore = require 'components/enhancers/connect_store'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
MdaTopicModal = require 'components/mda/topics/mda_topic_modal'
MdaTopicsActions = require 'actions/mda_topics_actions'
MdaWorkflowDialogStore = require 'stores/mda_workflow_dialog_store'
MdaWorkflowDialogActions = require 'actions/mda_workflow_dialog_actions'
MdaImportTopics = require 'components/mda/workflow/import_topics_modal'
MdaSplitWorkflowDialog = require 'components/mda/workflow/split_workflow_dialog'
MdaTopicsStore = require 'stores/mda_topics_store'
mediator = require 'mediator'
ProgressSelect = require 'components/evidence_syntheses/progress_select'
QuestionsStatusesStore = require 'stores/questions_statuses_store'
{ TopicCodesModal } = require 'components/common/topic_codes'
Router = require 'router'
{ isCurrentUserAdmin } = require 'lib/members_helper'
{ TOPIC_STATUSES, MASTER_PROJECT_ID } = require 'lib/mda_helper'
{ useCallback, useState } = React
{ Button } = ReactComponents
{ useCoffeeCallback, useI18n, useCoffeeMemo } = require 'lib/react_utils'

storeConnector =
  QuestionsStatusesStore: (Store, props) ->
    questionStatuses: Store.getQuestionStatuses props.currentTopicId
  MdaTopicsStore: (Store) ->
    createOrEditDialogOpen: Store.isCreateOrEditDialogOpen()
    topicName: Store.getCurrentTopicName()
    topicCodes: Store.getCurrentTopicCodes()
  MdaWorkflowDialogStore: (Store) ->
    isImportDialogOpen: Store.isOpen 'import'
    isSplitDialogOpen: Store.isOpen 'split'

MdaTopicsToolbar = ({
  createOrEditDialogOpen
  currentTopicId
  isImportDialogOpen
  isSplitDialogOpen
  questionStatuses
  topicCodes
  topicName
}) ->
  i18n = useI18n('mda:topics')
  userAccessRights = mediator.user.getAccessRights()

  [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)

  isAdmin = useCoffeeMemo [userAccessRights], -> _.contains(userAccessRights, 'admin')

  isMabOrMarUser = useCoffeeMemo [userAccessRights], ->
    _.intersection(userAccessRights, ['mda_mab', 'mda_mar']).length > 0

  currentStatus = useCoffeeMemo [questionStatuses], ->
    questionStatuses.getIn ['mdaTopicsStatus', 'status'], null

  openCreateOrEditDialog = useCoffeeCallback [], -> MdaTopicsActions.openCreateOrEditDialog()

  openImportOrSplitDialog = useCoffeeCallback [], (mode) -> ->
    if mediator.services.replication.isConnected()
      organizationId = mediator.activeWorkspace
      organizationId = null if organizationId is 'personal'
      MdaWorkflowDialogActions.openDialog { mode, organizationId }
    else
      mediator.dialogs.error $.t 'mda:topics.offline_error'

  openConfirmationDialog = useCoffeeCallback [], -> setConfirmationDialogOpen true

  closeConfirmationDialog = useCoffeeCallback [], -> setConfirmationDialogOpen false

  sendTopicToNextStep = useCoffeeCallback [currentTopicId, currentStatus], ->
    MdaTopicsActions.sendTopicToNextStep({ currentStatus, currentTopicId })
    .then ->
      route = Router::getProjectRelativeUrl "/mda-topics"
      mediator.publish '!router:route', route

  onUpdateCodes = useCoffeeCallback [], ({ parentId, codes }) ->
    MdaTopicsActions.updateCodes { parentId, codes }

  onRemoveCode = useCoffeeCallback [], ({ parentId, code }) ->
    MdaTopicsActions.removeCode { parentId, code }

  showDoneBtn = (isAdmin or isMabOrMarUser) and currentStatus in
    ['mar', 'durationReview1', 'durationReview2', 'durationReview3']

  <div className="flex flex-row mx-10">
    <h2 className="flex-grow">
      {if topicName then topicName else i18n('toolbar_title')}
    </h2>
    <div>
      <div className="flex flex-row items-center">
        {!_.isEmpty(currentTopicId) and <Button
          className="mr-5"
          onClick={DocumentSectionsV2Actions.expandAllChapters}
          >
            {i18n '/actions.expand_all'}
          </Button>
        }
        {isAdmin and !_.isEmpty(currentTopicId) and <TopicCodesModal
          codes={topicCodes}
          onAdd={onUpdateCodes}
          onRemove={onRemoveCode}
          parentId={currentTopicId}
        />}
        <ProgressSelect
          questionId={currentTopicId}
          questionStatuses={questionStatuses}
          showLabel={false}
          statusesList={TOPIC_STATUSES}
          groupKey='mdaTopicsStatus'
          withCompleted={false}
          editable={isAdmin}
        />
        {showDoneBtn and
          <Button className="mr-2" onClick={openConfirmationDialog}>
            {i18n 'done'}
          </Button>
        }
        <ConfirmationModal
          isOpen={confirmationDialogOpen}
          question={i18n 'are_you_sure_review_is_completed'}
          confirmLabel={i18n 'completed'}
          onConfirm={sendTopicToNextStep}
          onCancel={closeConfirmationDialog}
        />
        {isCurrentUserAdmin() and _.isEmpty(currentTopicId) and  <div className="flex flex-row">
          <Button
            className="btn btn-apply"
            label={i18n 'new_topic'}
            onClick={openCreateOrEditDialog}
          />
          {mediator.project?.id isnt MASTER_PROJECT_ID and <React.Fragment>
            <Button
              className="btn btn-apply ml-5"
              label={i18n 'import_topics'}
              onClick={openImportOrSplitDialog 'import'}
            />
            <Button
              className="btn btn-danger ml-5"
              label={i18n 'split_topics'}
              onClick={openImportOrSplitDialog 'split'}
            />
          </React.Fragment>}
        </div>}
      </div>
    </div>
    {createOrEditDialogOpen and <MdaTopicModal />}
    {isImportDialogOpen and <MdaImportTopics />}
    <MdaSplitWorkflowDialog />
  </div>

module.exports = ConnectStore MdaTopicsToolbar,
  [MdaTopicsStore, QuestionsStatusesStore, MdaWorkflowDialogStore], storeConnector
