{ AutoSizer, List } = ReactVirtualized
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
mediator = require 'mediator'
Reference = require 'components/references/reference'
ReferenceAttachmentDeleteModal = require 'components/references/attachment_delete_modal'
ReferenceAttachmentUploadModal = require 'components/references/attachment_upload_modal'
ReferenceUsageModal = require 'components/references/reference_usage_modal'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'

COLUMNS = [
  key: 'authors'
  type: 'visible'
,
  key: 'title'
  type: 'visible'
,
  key: 'year'
  type: 'visible'
,
  key: 'publishedIn'
  type: 'visible'
,
  key: 'lastUpdated'
  type: 'visible'
,
  key: 'attachment'
  type: if mediator.services.switches.isServerSwitchOn('mdgFeatures')
    'visible'
  else
    'metadata'
,
  key: 'researchResults'
  type: 'visible'
,
  key: '_id'
  type: 'metadata'
]

ReferencesTable = createReactClass
  displayName: 'ReferencesTable'

  mixins: [ CustomRenderMixin, Translation('references:columns') ]

  propTypes:
    hasEverFetched: PropTypes.bool.isRequired
    listRef: PropTypes.func.isRequired
    selectedReferenceId: PropTypes.string
    references: PropTypes.instanceOf(Immutable.List).isRequired
    sortAsc: PropTypes.bool.isRequired
    sortedBy: PropTypes.string.isRequired
    sortByColumn: PropTypes.func.isRequired
    toggleReferenceSelection: PropTypes.func.isRequired
    usedReferences: PropTypes.instanceOf(Immutable.Map).isRequired

  _tableHeaderRef: (el) ->
    @tableHeader = el

  _adjustTableHeaderWidth: ->
    element = document.getElementById 'references-table-container'
    return unless element?
    { clientHeight, scrollHeight } = element

    if scrollHeight > clientHeight
      @tableHeader.classList.add 'with-scroll-offset'
    else if scrollHeight is clientHeight
      @tableHeader.classList.remove 'with-scroll-offset'

  _getVisibleColumns: ->
    _(COLUMNS).chain()
      .filter (column) -> column.type is 'visible'
      .pluck 'key'
      .value()

  sortByColumn: (columnKey) -> =>
    @props.sortByColumn columnKey

  sortClass: (columnKey) ->
    if @props.sortedBy is columnKey
      "sorted #{if @props.sortAsc then 'asc' else 'desc'}"
    else
      ''

  renderHeaderCell: (column, idx) ->
    className = classNames column, @sortClass column
    <div className={className} onClick={@sortByColumn column} key={idx}>
      <div>
        {@i18n "#{column}"}
      </div>
    </div>

  renderRow: (columns) -> ({ index, style }) =>
    { references, selectedReferenceId, toggleReferenceSelection, usedReferences } = @props

    referenceData = references.get index
    referenceId = referenceData.get '_id'

    styleToPass = _.extend {}, style, top: 2 + style.top

    <div key={referenceId} style={styleToPass}>
      <Reference
        columns={columns}
        isSelected={selectedReferenceId is referenceId}
        referenceData={referenceData}
        toggleSelection={toggleReferenceSelection}
        referenceUsedIn={usedReferences.get(referenceId)}
      />
    </div>

  componentDidMount: ->
    _.defer @_adjustTableHeaderWidth
    window.addEventListener 'resize', @_adjustTableHeaderWidth

  componentWillUnmount: ->
    window.removeEventListener 'resize', @_adjustTableHeaderWidth

  componentDidUpdate: ->
    _.defer @_adjustTableHeaderWidth

  render: ->
    { hasEverFetched, listRef, references } = @props

    return <Spinner /> unless hasEverFetched

    columns = @_getVisibleColumns()
    className = classNames 'references-table', 'with-attachments': 'attachment' in columns

    <div className={className}>
      <div className="table-header" ref={@_tableHeaderRef}>
        {columns.map @renderHeaderCell}
      </div>
      <div className="table-body">
        <AutoSizer>
          {({ height, width }) =>
            <List
              height={height}
              id="references-table-container"
              overscanRowCount={10}
              ref={listRef}
              rowCount={references.size}
              rowHeight={58}
              rowRenderer={@renderRow(columns)}
              width={width}
            />
          }
        </AutoSizer>
      </div>
      <ReferenceAttachmentDeleteModal />
      <ReferenceAttachmentUploadModal />
      <ReferenceUsageModal />
    </div>

module.exports = ReferencesTable
