var MdaAddSlugToTopics, MdaTopicDoc, Migration, W, kebabCase, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

kebabCase = require('lodash').kebabCase;

MdaTopicDoc = require('lib/db_docs/mda_topic_doc');

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'mda_add_slug_to_topics';

module.exports = MdaAddSlugToTopics = (function(_super) {
  __extends(MdaAddSlugToTopics, _super);

  function MdaAddSlugToTopics() {
    MdaAddSlugToTopics.__super__.constructor.call(this, false);
  }

  MdaAddSlugToTopics.prototype.up = function(project) {
    var adapter, projectId;
    MdaAddSlugToTopics.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return MdaTopicDoc.at(projectId).fetchMany()["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
      return {
        rows: []
      };
    }).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(row) {
        return row.doc != null;
      }).pluck('doc').value();
    }).then(function(docs) {
      var updatedDocs;
      if (_.isEmpty(docs)) {
        return W.resolve();
      }
      updatedDocs = _.map(docs, function(doc) {
        if (doc.slug) {
          return doc;
        }
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName,
          slug: kebabCase(doc.name)
        });
      });
      return adapter.bulkDocs(projectId, _.compact(updatedDocs));
    });
  };

  return MdaAddSlugToTopics;

})(Migration);
