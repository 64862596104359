var CalendarView, Event, View, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

Event = require('models/event');

template = require('views/templates/tasks/calendar');

mediator = require('mediator');

module.exports = CalendarView = (function(_super) {
  __extends(CalendarView, _super);

  function CalendarView() {
    this.filterEvent = __bind(this.filterEvent, this);
    this.getEvents = __bind(this.getEvents, this);
    this.destroy = __bind(this.destroy, this);
    this.change = __bind(this.change, this);
    this.add = __bind(this.add, this);
    this.reset = __bind(this.reset, this);
    return CalendarView.__super__.constructor.apply(this, arguments);
  }

  CalendarView.prototype.template = template;

  CalendarView.prototype.initialize = function() {
    CalendarView.__super__.initialize.apply(this, arguments);
    this.modelBind('reset', this.reset);
    this.modelBind('add', this.add);
    this.modelBind('change', this.change);
    return this.modelBind('destroy', this.destroy);
  };

  CalendarView.prototype.afterRender = function() {
    CalendarView.__super__.afterRender.apply(this, arguments);
    if (!this.calendar) {
      this.calendar = this.$('#calendar');
      this.calendar.fullCalendar({
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'month,agendaWeek,agendaDay'
        },
        selectable: true,
        selectHelper: true,
        editable: false,
        columnFormat: {
          month: $.t('tasks:calendar.column_format.month'),
          week: $.t('tasks:calendar.column_format.week'),
          day: $.t('tasks:calendar.column_format.day')
        },
        titleFormat: {
          month: $.t('tasks:calendar.title_format.month'),
          week: $.t('tasks:calendar.title_format.week'),
          day: $.t('tasks:calendar.title_format.day')
        },
        buttonText: {
          prev: '&nbsp;&lsaquo;&nbsp;',
          next: '&nbsp;&rsaquo;&nbsp;',
          prevYear: '&nbsp;&laquo;&nbsp;',
          nextYear: '&nbsp;&raquo;&nbsp;',
          today: $.t('tasks:calendar.today'),
          month: $.t('tasks:calendar.month'),
          week: $.t('tasks:calendar.week'),
          day: $.t('tasks:calendar.day')
        },
        allDayText: $.t('tasks:calendar.allday'),
        axisFormat: $.t('tasks:calendar.axisformat'),
        timeformat: {
          agenda: $.t('tasks:calendar.agenda_timeformat'),
          '': $.t('tasks:calendar.timeformat')
        },
        monthNames: $.t('tasks:calendar.month_names').split(','),
        monthNamesShort: $.t('tasks:calendar.month_names_short').split(','),
        dayNames: $.t('tasks:calendar.day_names').split(','),
        dayNamesShort: $.t('tasks:calendar.day_names_short').split(',')
      });
      this.initializeEventSources();
    }
    return setTimeout(((function(_this) {
      return function() {
        return _this.calendar.fullCalendar('render');
      };
    })(this)), 0);
  };

  CalendarView.prototype.initializeEventSources = function() {
    return this.calendar.fullCalendar('addEventSource', this.getEvents);
  };

  CalendarView.prototype.showDate = function(moment) {
    return this.calendar.fullCalendar('gotoDate', moment.unix());
  };

  CalendarView.prototype.reset = function() {
    return this.calendar.fullCalendar('rerenderEvents');
  };

  CalendarView.prototype.add = function(event) {
    if (event.isNew() || _.contains(_.pluck(this.calendar.fullCalendar('clientEvents'), '_id'), event.id)) {
      return;
    }
    return this.calendar.fullCalendar('renderEvent', this.constructFcEvent(event));
  };

  CalendarView.prototype.change = function(event) {
    var fcEvent;
    if (event.isNew()) {
      return;
    }
    fcEvent = this.calendar.fullCalendar('clientEvents', event.id)[0];
    if (!(fcEvent || event.get('done') === true)) {
      this.add(event);
      return;
    }
    if (this.filterEvent(event)) {
      return this.calendar.fullCalendar('updateEvent', _.extend(fcEvent, this.constructFcEvent(event)));
    } else {
      return this.destroy(event);
    }
  };

  CalendarView.prototype.destroy = function(event) {
    if (event.isNew()) {
      return;
    }
    return this.calendar.fullCalendar('removeEvents', event.id);
  };

  CalendarView.prototype.getEvents = function(start, end, callback) {
    return callback(_(this.collection.filter(this.filterEvent)).map(this.constructFcEvent));
  };

  CalendarView.prototype.constructFcEvent = function(event) {
    var fcEvent;
    fcEvent = event.toJSON();
    fcEvent.editable = false;
    fcEvent.className = fcEvent.type;
    fcEvent.title = _.string.prune(fcEvent.title, 50);
    if (fcEvent.description == null) {
      fcEvent.description = '';
    }
    return fcEvent;
  };

  CalendarView.prototype.filterEvent = function(event) {
    return !event.get('done') || this.collection.contains(event);
  };

  return CalendarView;

})(View);
