var AdaptiveLayout, Scrolling, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

AdaptiveLayout = require('./adaptive_layout');

utils = require('base/lib/utils');

mediator = require('mediator');

module.exports = Scrolling = (function(_super) {
  __extends(Scrolling, _super);

  function Scrolling() {
    this._getContentHeight = __bind(this._getContentHeight, this);
    this._getMaxHeight = __bind(this._getMaxHeight, this);
    return Scrolling.__super__.constructor.apply(this, arguments);
  }

  Scrolling.prototype.adjustTo = '#page-container';

  Scrolling.prototype.content = '.standard-list';

  Scrolling.prototype.wrapped = true;

  Scrolling.prototype.forceCustom = false;

  Scrolling.prototype.fixedMaxHeight = false;

  Scrolling.prototype.options = {
    advanced: {
      autoScrollOnFocus: false
    }
  };

  Scrolling.prototype._customOnScroll = function(view) {
    return view.on('!scroll', (function(_this) {
      return function(target, delay, margin) {
        var doScrolling;
        if (delay == null) {
          delay = 0;
        }
        if (margin == null) {
          margin = 0;
        }
        doScrolling = function() {
          var behindViewport, scrolledHeight, scrolledOffset, targetHeight, targetOffset;
          if (!_this.scrollbar) {
            return;
          }
          if (_this.layoutFixed) {
            _this.layoutFixed = null;
            _(doScrolling).delay(delay);
            return;
          }
          if (target instanceof jQuery) {
            targetOffset = target.offset();
            targetHeight = target.height();
            scrolledOffset = _this.scrolled.offset();
            scrolledHeight = _this.scrolled.height();
            behindViewport = targetOffset.top + targetHeight - scrolledOffset.top > scrolledHeight;
            if (behindViewport) {
              target = target.position().top - scrolledHeight + targetHeight + margin;
            } else if (targetOffset.top - margin < scrolledOffset.top) {
              target = target.position().top - margin;
            } else {
              return;
            }
          }
          return _this.scrollbar.mCustomScrollbar('scrollTo', target);
        };
        return _(doScrolling).delay(delay);
      };
    })(this));
  };

  Scrolling.prototype._nativeOnScroll = function(view) {
    return view.on('!scroll', (function(_this) {
      return function(target, debounce, margin) {
        var doScrolling;
        if (debounce == null) {
          debounce = 200;
        }
        if (margin == null) {
          margin = 0;
        }
        doScrolling = function() {
          var behindViewport, positionTop, scrollTop, scrolledHeight, scrolledOffset, targetHeight, targetOffset;
          if (!_this.scrolled) {
            return;
          }
          if (target instanceof jQuery) {
            targetOffset = target.offset();
            targetHeight = target.height();
            scrolledOffset = _this.scrolled.offset();
            scrolledHeight = _this.scrolled.height();
            behindViewport = targetOffset.top + targetHeight + margin > scrolledHeight + scrolledOffset.top;
            scrollTop = behindViewport ? _this.scrolled[0].scrollTop + targetHeight + margin : targetOffset.top - margin < scrolledOffset.top ? (positionTop = target.position().top - _this.scrolled.position().top, _this.scrolled[0].scrollTop + positionTop - margin) : null;
          }
          if (scrollTop != null) {
            return utils.scrollTo(_this.scrolled[0], scrollTop, 100);
          }
        };
        return _(doScrolling).debounce(debounce)();
      };
    })(this));
  };

  Scrolling.prototype.apply = function(view) {
    Scrolling.__super__.apply.apply(this, arguments);
    this._addFunction(view, '_customFixLayout');
    this._addFunction(view, '_nativeFixLayout');
    this._addFunction(view, 'dispose');
    if (this.forceCustom) {
      return this._customOnScroll(view);
    } else {
      return this._nativeOnScroll(view);
    }
  };

  Scrolling.prototype._customFixLayout = function(originalFunction, trait) {
    var el, maxHeight, _i, _len, _ref;
    if (this.disposed) {
      return;
    }
    trait.layoutFixed = true;
    Scrolling.__super__.fixLayout.apply(this, arguments);
    if (!(trait.scrolled && utils.isElementAttached(trait.scrolled))) {
      trait.contentEl = this.$(trait.content);
      trait.contentHeight = trait.contentEl.outerHeight();
      if (trait.scrolled) {
        this.$(trait.scrolled).mCustomScrollbar('destroy');
      }
      if (trait.wrapped) {
        trait.scrolled = trait.contentEl.wrap('<div class="scrolled"></div>').parent();
      } else {
        trait.scrolled = trait.contentEl;
      }
      trait.scrollbar = null;
    }
    if (trait.wrapped) {
      trait.contentHeight = trait.contentEl.outerHeight();
    }
    maxHeight = $(trait.adjustTo).height();
    if (trait.fixedElements) {
      _ref = this.$(trait.fixedElements);
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        el = _ref[_i];
        maxHeight -= $(el).outerHeight(true);
      }
    } else if (trait.fixedElementsHeight) {
      maxHeight -= trait.fixedElementsHeight;
    }
    if (trait.contentHeight > maxHeight) {
      trait.scrolled.height(maxHeight);
    } else {
      trait.scrolled.height(trait.contentHeight);
    }
    if (trait.scrollbar) {
      return trait.scrollbar.mCustomScrollbar('update');
    } else {
      _(trait.options).extend({
        scrollEasing: "linear",
        scrollInertia: 100
      });
      return trait.scrollbar = this.$(trait.scrolled).mCustomScrollbar(trait.options);
    }
  };

  Scrolling.prototype._getMaxHeight = function(view) {
    var el, maxHeight, _i, _len, _ref;
    maxHeight = $(this.adjustTo).height();
    if (this.fixedElements) {
      _ref = view.$(this.fixedElements);
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        el = _ref[_i];
        maxHeight -= $(el).outerHeight(true);
      }
    }
    return maxHeight;
  };

  Scrolling.prototype._getContentHeight = function() {
    return this.contentEl.outerHeight();
  };

  Scrolling.prototype._nativeFixLayout = function(originalFunction, trait) {
    var maxHeight;
    Scrolling.__super__.fixLayout.apply(this, arguments);
    if (!(trait.scrolled && utils.isElementAttached(trait.scrolled))) {
      trait.contentEl = this.$(trait.content);
      trait.contentHeight = trait.contentEl.outerHeight();
      if (trait.wrapped) {
        trait.scrolled = trait.contentEl.wrap('<div class="scrolled native-scroll"></div>').parent();
      } else {
        trait.scrolled = trait.contentEl;
      }
    }
    if (trait.wrapped) {
      trait.contentHeight = trait._getContentHeight();
    }
    trait.contentHeight += 1;
    if (trait.additionalContentSpace) {
      trait.contentHeight += trait.additionalContentSpace();
    }
    maxHeight = trait._getMaxHeight(this);
    if (trait.fixedMaxHeight) {
      return trait.scrolled.height(maxHeight);
    } else if (trait.contentHeight > maxHeight) {
      return trait.scrolled.height(maxHeight);
    } else {
      return trait.scrolled.height(trait.contentHeight);
    }
  };

  Scrolling.prototype.fixLayout = function(originalFunction, trait) {
    if (trait.forceCustom) {
      return this._customFixLayout();
    } else {
      return this._nativeFixLayout();
    }
  };

  Scrolling.prototype.dispose = function() {
    var args, originalFunction, trait;
    originalFunction = arguments[0], trait = arguments[1], args = 3 <= arguments.length ? __slice.call(arguments, 2) : [];
    this.off('!scroll');
    trait.scrolled = null;
    trait.scrollbar = null;
    trait.contentEl = null;
    return originalFunction.apply(this, args);
  };

  return Scrolling;

})(AdaptiveLayout);
