CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdSectionTitle = require 'components/etd/etd_section_title'
EtdVotingMixin = require 'components/mixins/etd_voting_mixin'
TypeOfRecommendation = require 'components/etd/type_of_recommendation'
Translation = require 'components/mixins/translation'

{ string, instanceOf, func, bool } = PropTypes

Recommendation = createReactClass

  displayName: "Recommendation"

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
    EtdVotingMixin
  ]

  propTypes:
    editable: bool,
    section: instanceOf(Immutable.Map).isRequired,
    sectionId: string.isRequired,
    renderMode: string.isRequired

  render: ->
    <div className='recommendation-section'>
      <EtdSectionTitle renderMode={@props.renderMode} title={@i18n 'type_of_recommendation'} />
      <TypeOfRecommendation {...@props} />
    </div>

module.exports = Recommendation
