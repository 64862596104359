var CoiImportView, DbHelper, ModalView, W, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DbHelper = require('base/lib/db_helper');

mediator = require('mediator');

ModalView = require('base/views/modal_view');

template = require('views/templates/team/coi_import');

W = require('when/when');

module.exports = CoiImportView = (function(_super) {
  __extends(CoiImportView, _super);

  function CoiImportView() {
    return CoiImportView.__super__.constructor.apply(this, arguments);
  }

  CoiImportView.prototype.template = template;

  CoiImportView.prototype.className = 'coi-import-view';

  CoiImportView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = 50;
    this.options.width = 600;
    this.title = $.t('team:coi.import.title');
    CoiImportView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.cancel', this.dispose);
    this.delegate('click', 'button.import', this["import"]);
    this.delegate('change', '.awmf-id input', this.awmfIdChange);
    return mediator.project.on('change:awmfId', this.render);
  };

  CoiImportView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    mediator.project.off('change:awmfId', this.render);
    return CoiImportView.__super__.dispose.apply(this, arguments);
  };

  CoiImportView.prototype["import"] = function() {
    return mediator.dialogs.confirm($.t('team:coi.import.confirm'), (function(_this) {
      return function(confirmed) {
        var options;
        if (!confirmed) {
          return;
        }
        options = {
          type: 'POST',
          xhrFields: {
            withCredentials: true
          }
        };
        return W($.ajax("" + (DbHelper.getBackendUrl()) + "/awmf-coi/" + mediator.project.id + "/_update", options)).then(function() {
          mediator.dialogs.info($.t('team:coi.import.success'));
          return mediator.questions.fetch();
        })["catch"](function() {
          return mediator.dialogs.error($.t('team:coi.import.error'));
        })["finally"](function() {
          return _this.dispose();
        });
      };
    })(this));
  };

  CoiImportView.prototype.awmfIdChange = function(e) {
    return mediator.project.save({
      awmfId: e.target.value
    });
  };

  CoiImportView.prototype.getTemplateData = function() {
    var awmfId, _ref;
    awmfId = (_ref = mediator.project.get('awmfId')) != null ? _ref : '';
    return {
      awmfId: awmfId,
      disabled: awmfId.length <= 0
    };
  };

  return CoiImportView;

})(ModalView);
