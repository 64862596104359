QuestionRow = require 'components/scope/questions/question_approval_row'
ApprovalDetailsRow = require 'components/scope/questions/approval_question_details_row'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
QuestionCommentsMixin = require 'components/mixins/question_comments_mixin'
dndSpec = require 'components/scope/questions/question_approval_dnd_spec'
DragDropTypes = require 'components/scope/questions/drag_drop_types'
{ getDragWrapper } = require 'lib/react_dnd'
QuestionDrag = getDragWrapper DragDropTypes.APPROVAL_TAB_QUESTION, dndSpec

ApprovalQuestionWithComments = createReactClass
  displayName: 'ApprovalQuestionWithComments'

  propTypes:
    question: PropTypes.instanceOf(Immutable.Map).isRequired
    membersMap: PropTypes.instanceOf(Immutable.Map).isRequired
    todos: PropTypes.instanceOf(Immutable.List).isRequired

  mixins: [
    CustomRenderMixin
    QuestionCommentsMixin
  ]

  _getUnfinishedTodosCount: ->
    @props.todos.filterNot((todo) -> todo.get 'completed').size

  render: ->
    id = @props.question.get 'id'
    { groupType, qIndex } = @props
    tbodyClass = classNames expanded: @state.showingComments, groupType
    canDrag = not @props.readOnly and not @state.showingComments

    <QuestionDrag id={id} qIndex={qIndex} groupType={groupType} canDrag={canDrag}>
      <tbody className={tbodyClass}>
        <QuestionRow
          question={@props.question}
          questionOnly={@state.showingComments}
          todosCount={@_getUnfinishedTodosCount()}
          onCommentToggle={@toggleComments}
          showingComments={@state.showingComments}
          groupType={groupType}
        />
        {if @state.showingComments
          <ApprovalDetailsRow
            question={@props.question}
            membersMap={@props.membersMap}
            groupType={groupType}
            todos={@props.todos}
            onCommentToggle={@toggleComments}
            showingComments={@state.showingComments}
            readOnly={@props.readOnly}
          />
        }
      </tbody>
    </QuestionDrag>

module.exports = ApprovalQuestionWithComments
