Spinner = require 'components/common/spinner'
Tooltip = require 'components/common/tooltip'
{ string, number, bool, func } = PropTypes

SpinnerButton = createReactClass
  displayName: "SpinnerButton"

  propTypes: ->
    loading: bool.isRequired
    onClick: func.isRequired
    label: string.isRequired
    disabled: bool
    className: string
    spinnerScale: number
    title: string

  getDefaultProps: ->
    spinnerScale: 0.5
    disabled: false
    className: ''

  render: ->
    <Tooltip>
      <button
        className={@props.className}
        style={position: 'relative'}
        disabled={@props.loading or @props.disabled}
        onClick={@props.onClick}
        title={@props.title}
      >
        {if @props.loading
          <Spinner scale={@props.spinnerScale}/>
        }
        {@props.label}
      </button>
    </Tooltip>

module.exports = SpinnerButton
