{ QUESTION_OVER_REVIEW_STUDIES_STATUSES: STD_STATUSES } = require 'lib/questions_helper'

STATUSES_ORDER = [
  STD_STATUSES.INCLUDED
  STD_STATUSES.EXCLUDED
  STD_STATUSES.POSSIBLE
  STD_STATUSES.NONE
]

StudyStatusSelect = createReactClass
  displayName: 'StudyStatusSelect'

  propTypes:
    selected: PropTypes.oneOf STATUSES_ORDER
    onRequestClose: PropTypes.func.isRequired

  handleStudyStatusSelect: (evt) ->
    selected = evt.target.getAttribute 'data-status-value'
    @props.onSelect selected
    @props.onRequestClose()

  renderStatusSelectOption: (status) ->
    return null if status is @props.selected

    <div
      className="study-status-select-option #{status}"
      key={status}
      data-status-value={status}
      onClick={@handleStudyStatusSelect}
      title={status}
    />

  render: ->
    { selected } = @props

    <div className='study-status-select'>
      {STATUSES_ORDER.map @renderStatusSelectOption}
    </div>

module.exports = StudyStatusSelect
