var ETD_VOTING_PHASES, PouchSource, VotingActions, VotingStore, alt, errorHandler, getNextTab, getPhaseEtds, getProjectQuestions, getVotingDocId, immutable, mediator, votingPreferencesToStore, votingResultsToStore,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

mediator = require('mediator');

immutable = require('stores/utils/immutable');

errorHandler = require('lib/app_utils').errorHandler;

ETD_VOTING_PHASES = require('lib/questions_helper').ETD_VOTING_PHASES;

VotingActions = require('actions/voting_actions');

PouchSource = require('stores/sources/pouch_source');

getNextTab = function(currentTab) {
  var currentTabIndex;
  currentTabIndex = ETD_VOTING_PHASES.indexOf(currentTab);
  return ETD_VOTING_PHASES[currentTabIndex + 1];
};

getProjectQuestions = function() {
  return Immutable.fromJS(mediator.questions.models.map(function(qm) {
    return {
      id: qm.get('_id'),
      text: qm.get('question'),
      etds: qm.get('recommendationIds').slice()
    };
  }));
};

getPhaseEtds = function(phaseName, votingResults) {
  var closedVotingsOnly, groupedByPhase, phaseNames, projectQuestions;
  if (!votingResults) {
    return Immutable.List();
  }
  if (phaseName !== 'phase1b' && phaseName !== 'phase2a' && phaseName !== 'phase2b' && phaseName !== 'finished') {
    return Immutable.List();
  }
  closedVotingsOnly = phaseName === 'phase2a' || phaseName === 'finished';
  phaseNames = (function() {
    switch (phaseName) {
      case 'phase1b':
      case 'phase2a':
        return ['phase1'];
      case 'finished':
        return ['phase1', 'phase2'];
      default:
        return ['phase2'];
    }
  })();
  projectQuestions = getProjectQuestions();
  groupedByPhase = votingResults.groupBy(function(result) {
    return result.get('currentPhase');
  });
  return groupedByPhase.filter(function(results, phase) {
    return __indexOf.call(phaseNames, phase) >= 0;
  }).flatten(true).filter(function(etdResults, etdId) {
    var currentPhase, votedEtdPartNumber;
    currentPhase = etdResults.get('currentPhase');
    votedEtdPartNumber = etdResults.getIn([currentPhase, 'blocks']).size;
    if (phaseName === 'phase2a' && votedEtdPartNumber === 3) {
      return false;
    }
    if (phaseName === 'finished' && currentPhase === 'phase1' && votedEtdPartNumber !== 3) {
      return false;
    }
    return etdResults.getIn([currentPhase, 'closed'], false) === closedVotingsOnly && projectQuestions.some(function(q) {
      return q.get('etds').contains(etdId);
    });
  });
};

getVotingDocId = function(projectId) {
  var docId;
  return docId = "" + projectId + ":etd-voting";
};

votingResultsToStore = function(state, votingDoc) {
  if (!votingDoc) {
    return state;
  }
  state.set('votingResults', Immutable.fromJS(votingDoc.votingResults));
  return state.set('votingMembers', Immutable.fromJS(votingDoc.memberIds));
};

votingPreferencesToStore = function(state, doc) {
  if (doc != null) {
    return state.mergeIn(['votingPreferences'], _.omit(doc, ['_rev', 'rev_author', '$timestamp']));
  } else {
    return state.setIn(['votingPreferences', 'contactEmail'], mediator.project.get('contactEmail'));
  }
};

({
  _getVotingTabCount: function(tabName) {
    switch (tabName) {
      case 'phase1a':
        return this._getNotVotedQuestions().size;
      case 'phase1b':
      case 'phase2a':
      case 'phase2b':
      case 'finished':
        return this._getTabResults(tabName).size;
      default:
        return 0;
    }
  }
});

VotingStore = (function() {
  function VotingStore() {
    this.state = Immutable.fromJS({
      _meta: {
        hasUpdatedPreferences: false
      },
      projectId: null,
      questionGroups: null,
      votingResults: null,
      votingPreferences: {
        activeTab: 'phase1a',
        sendVoteMessage: null,
        remindVoteMessage: null,
        contactEmail: null,
        selectedJudgmentsOption: null,
        selectedEtdPartsOption: null
      },
      dueDateTimestamp: null
    });
    this.bindActions(VotingActions);
    this.registerAsync(PouchSource(VotingActions));
    this.exportPublicMethods({
      getProjectQuestions: this.getProjectQuestions,
      getQuestionGroups: this.getQuestionGroups,
      getMessage: this.getMessage,
      isFetching: this.isFetching,
      getContactEmail: this.getContactEmail,
      getVotingMembers: this.getVotingMembers,
      getDueDate: this.getDueDate,
      getVotingResults: this.getVotingResults,
      getActiveTab: this.getActiveTab,
      getNotVotedQuestions: this.getNotVotedQuestions,
      getEtdsWithResultsPerPhase: this.getEtdsWithResultsPerPhase,
      getSelectedJudgmentsOption: this.getSelectedJudgmentsOption,
      getSelectedEtdPartsOption: this.getSelectedEtdPartsOption,
      getSelectedContentOption: this.getSelectedContentOption
    });
  }

  VotingStore.prototype.onFetch = function(projectId) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], true);
      return state.set('projectId', projectId);
    }));
  };

  VotingStore.prototype.onPouchFetchSuccess = function(data) {
    var projectId, votingDocId;
    projectId = this.state.get('projectId');
    votingDocId = getVotingDocId(projectId);
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], false);
      return data.rows.forEach(function(row) {
        switch (row.key) {
          case votingDocId:
            return votingResultsToStore(state, row.doc);
          case 'etd-voting-preferences':
            if (state.getIn(['_meta', 'hasUpdatedPreferences'])) {
              return;
            }
            return votingPreferencesToStore(state, row.doc);
        }
      });
    }));
  };

  VotingStore.prototype.onPouchFetchError = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    if (err.status !== 404) {
      return errorHandler(err);
    }
  };

  VotingStore.prototype.onFetchQuestionGroupsSuccess = function(doc) {
    var groups;
    groups = doc.get('groups');
    if (!_.isEmpty(groups)) {
      return this.setState(this.state.set('questionGroups', Immutable.fromJS({
        groups: groups,
        mapping: doc.get('mapping')
      })));
    }
  };

  VotingStore.prototype.onFetchQuestionGroupsError = errorHandler;

  VotingStore.prototype.onUpdateSendVoteDetails = function(data) {
    var contactEmail, dueDateTimestamp, emailBody, votingPreferences;
    dueDateTimestamp = data.dueDateTimestamp, contactEmail = data.contactEmail, emailBody = data.emailBody;
    votingPreferences = {
      contactEmail: contactEmail,
      sendVoteMessage: emailBody
    };
    if (dueDateTimestamp) {
      this.setState(this.state.set('dueDateTimestamp', dueDateTimestamp));
    }
    this._updateVotingPreference(votingPreferences);
    return this._savePreferences();
  };

  VotingStore.prototype.onUpdateReminderDetails = function(data) {
    this._updateVotingPreference('remindVoteMessage', data.emailBody);
    return this.onSaveVotingPreferences();
  };

  VotingStore.prototype.onUpdateVotingOption = function(params) {
    var name, value;
    name = params.name, value = params.value;
    return this._updateVotingPreference(name, value);
  };

  VotingStore.prototype.onSaveVotingPreferences = function() {
    var hasUpdatedPreferences;
    hasUpdatedPreferences = this.state.getIn(['_meta', 'hasUpdatedPreferences']);
    if (!hasUpdatedPreferences) {
      return;
    }
    this.setState(this.state.setIn(['_meta', 'hasUpdatedPreferences'], false));
    return this._savePreferences();
  };

  VotingStore.prototype.onResetVotingPreference = function(prefName) {
    var prefNames;
    if (_.isArray(prefName)) {
      prefNames = prefName;
      return prefNames.forEach((function(_this) {
        return function(prefName) {
          return _this._updateVotingPreference(prefName, null);
        };
      })(this));
    } else {
      return this._updateVotingPreference(prefName, null);
    }
  };

  VotingStore.prototype.onChangeTab = function(tabName) {
    return this._updateVotingPreference('activeTab', tabName);
  };

  VotingStore.prototype.onStartVotingSuccess = function() {
    return this._activateNextTab();
  };

  VotingStore.prototype.onCloseVotingSuccess = function() {
    return this._activateNextTab();
  };

  VotingStore.prototype.onUpdateVotingSuccess = function() {
    return this._activateNextTab();
  };

  VotingStore.prototype._savePreferences = function() {
    var doc, docId, projectId;
    docId = 'etd-voting-preferences';
    projectId = this.state.get('projectId');
    doc = this.state.get('votingPreferences').toJS();
    if (!doc._id) {
      doc._id = docId;
    }
    return this.getInstance().save(projectId, doc);
  };

  VotingStore.prototype._updateVotingPreference = function(prefName, prefValue) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'hasUpdatedPreferences'], true);
      if (_.isObject(prefName)) {
        return state.mergeIn(['votingPreferences'], prefName);
      } else {
        return state.setIn(['votingPreferences', prefName], prefValue);
      }
    }));
  };

  VotingStore.prototype._activateNextTab = function() {
    return this._updateVotingPreference('activeTab', getNextTab(this.getActiveTab()));
  };

  VotingStore.prototype.getProjectQuestions = getProjectQuestions;

  VotingStore.prototype.getQuestionGroups = function() {
    return this.state.get('questionGroups');
  };

  VotingStore.prototype.getMessage = function(type) {
    return this.state.getIn(['votingPreferences', "" + type + "Message"]);
  };

  VotingStore.prototype.getContactEmail = function() {
    return this.state.getIn(['votingPreferences', 'contactEmail']);
  };

  VotingStore.prototype.getDueDate = function() {
    return this.state.get('dueDateTimestamp');
  };

  VotingStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  VotingStore.prototype.getVotingResults = function() {
    return this.state.get('votingResults');
  };

  VotingStore.prototype.getVotingMembers = function() {
    return this.state.get('votingMembers', new Immutable.List());
  };

  VotingStore.prototype.getActiveTab = function() {
    return this.state.getIn(['votingPreferences', 'activeTab']);
  };

  VotingStore.prototype.getSelectedJudgmentsOption = function() {
    return this.state.getIn(['votingPreferences', 'selectedJudgmentsOption']) || 'all';
  };

  VotingStore.prototype.getNotVotedQuestions = function() {
    var votingResults;
    votingResults = this.getVotingResults();
    return getProjectQuestions().filterNot(function(q) {
      var etds;
      etds = q.get('etds');
      if (!etds.size) {
        return false;
      }
      return etds.every(function(etdId) {
        return votingResults != null ? votingResults.has(etdId) : void 0;
      });
    });
  };

  VotingStore.prototype.getEtdsWithResultsPerPhase = function() {
    var votingResults;
    votingResults = this.getVotingResults();
    return ETD_VOTING_PHASES.slice(1).reduce(function(result, phaseName) {
      return result.set(phaseName, getPhaseEtds(phaseName, votingResults));
    }, Immutable.Map());
  };

  VotingStore.prototype.getSelectedEtdPartsOption = function() {
    return this.state.getIn(['votingPreferences', 'selectedEtdPartsOption']) || 'A';
  };

  VotingStore.prototype.getSelectedContentOption = function() {
    return this.state.getIn(['votingPreferences', 'selectedContentOption']) || 'proposed';
  };

  return VotingStore;

})();

module.exports = alt.createStore(immutable(VotingStore), 'VotingStore');
