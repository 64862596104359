QuestionGroupsContainer = require 'components/scope/outcomes/question_groups_scroll_on_drag'
QuestionGroup = require 'components/scope/outcomes/question_group'
DraggableQuestionGroup = require 'components/scope/outcomes/question_group_draggable'
TextWithLinks = require 'components/common/text_with_links'
Footer = require 'components/scope/outcomes/footer'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

InitialTab = createReactClass
  displayName: 'InitialTab'

  propTypes:
    projectId: PropTypes.string.isRequired
    questionGroups: PropTypes.object.isRequired
    readOnly: PropTypes.bool.isRequired
    onFinishAndSend: PropTypes.func.isRequired
    isSendingToMembers: PropTypes.bool.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  _canGenerateOutcomes: ->
    not @props.questionGroups.isEmpty()

  _getLink: (moduleName) ->
    switch moduleName
      when 'questions' then "/projects/#{@props.projectId}/scope/questions"
      when 'comparisons' then "/projects/#{@props.projectId}/evidence-syntheses"

  _getIntroText: ->
    if @_canGenerateOutcomes()
      <span className="intro-text">{@i18n 'intro_text'}</span>
    else
      <TextWithLinks
        className="intro-text"
        text={@i18n 'no_questions_intro_text'}
        links={
          link1: [@_getLink('questions'), @i18n 'questions_module']
          link2: [@_getLink('comparisons'), @i18n 'comparisons_module']
        }
      />

  _questionGroupMapper: (qGroup, idx) ->
    if @props.readOnly
      <QuestionGroup key={idx} data={qGroup} gIndex={idx} />
    else
      <DraggableQuestionGroup
        key={idx}
        data={qGroup}
        gIndex={idx}
        hasQuestionDropped={@props.hasQuestionDropped}
        startQuestionDrag={@props.startQuestionDrag}
        draggingQuestionPosition={@props.draggingQuestionPosition}
        editingOutcome={@props.editingOutcome}
        updateQuestionGroupTitle={@props.updateQuestionGroupTitle}
        deleteQuestionGroup={@props.deleteQuestionGroup}
        undoItemMovement={@props.undoItemMovement}
        onCopyOutcome={@props.onCopyOutcome}
      />

  componentDidUpdate: (prevProps, prevState) ->
    if @props.scrollToGroup?
      @refs.questionsContainer.getDecoratedComponentInstance().scrollToGroup @props.scrollToGroup
      @props.afterScroll()

  render: ->
    <div>
      <div className="information">
        {@_getIntroText()}
      </div>
      <QuestionGroupsContainer ref='questionsContainer' releaseQuestion={@props.releaseQuestion}>
        {@props.questionGroups.map @_questionGroupMapper}
      </QuestionGroupsContainer>
      <Footer
        disabled={@props.readOnly or not @_canGenerateOutcomes()}
        isSending={@props.isSendingToMembers}
        onQuestionDrop={@props.onNewGroupDrop}
        onAddOutcome={@props.onAddOutcome}
        onFinishAndSend={@props.onFinishAndSend}
      />
    </div>

module.exports = InitialTab
