var KeyMessageCreatorView, KeyMessagesCreator, KeyMessagesCreatorToolbar, ReactComponent, ReactToolbarView, View, alt,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

KeyMessagesCreator = require('components/key_messages/creator/key_message_creator');

KeyMessagesCreatorToolbar = require('components/key_messages/creator/toolbar');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = KeyMessageCreatorView = (function(_super) {
  __extends(KeyMessageCreatorView, _super);

  function KeyMessageCreatorView() {
    return KeyMessageCreatorView.__super__.constructor.apply(this, arguments);
  }

  KeyMessageCreatorView.prototype.container = '#page-container';

  KeyMessageCreatorView.prototype.className = 'key-message-creator';

  KeyMessageCreatorView.prototype.autoRender = true;

  KeyMessageCreatorView.prototype._stopInputsPropagation = false;

  KeyMessageCreatorView.prototype.initialize = function() {
    KeyMessageCreatorView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: KeyMessagesCreatorToolbar,
      props: {
        creating: this.options.submodule === 'create'
      }
    }));
    return this.enable(ReactComponent);
  };

  KeyMessageCreatorView.prototype.afterRender = function() {
    var creating, questionId, submodule, _ref;
    KeyMessageCreatorView.__super__.afterRender.apply(this, arguments);
    _ref = this.options, questionId = _ref.id, submodule = _ref.submodule;
    creating = submodule === 'create';
    return this.renderSimpleComponent(KeyMessagesCreator, {
      questionId: questionId,
      creating: creating
    });
  };

  KeyMessageCreatorView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('KeyMessagesStore');
    return KeyMessageCreatorView.__super__.dispose.apply(this, arguments);
  };

  return KeyMessageCreatorView;

})(View);
