var QUESTION_GENERATION_STEPS, W, calculateTitles, helpers, mediator, questionGenerationHelpers, updateQuestionsWithRatingData, utils, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

W = require('when');

mediator = require('mediator');

utils = require('base/lib/utils');

_ref = require('lib/questions_helper'), QUESTION_GENERATION_STEPS = _ref.QUESTION_GENERATION_STEPS, calculateTitles = _ref.calculateTitles, updateQuestionsWithRatingData = _ref.updateQuestionsWithRatingData;

questionGenerationHelpers = helpers = {
  getQuestionsGenerationDataObject: function(stepName, questions) {
    var stepIndex, steps;
    if (stepName == null) {
      stepName = QUESTION_GENERATION_STEPS[0];
    }
    if (questions == null) {
      questions = [];
    }
    stepIndex = QUESTION_GENERATION_STEPS.indexOf(stepName);
    steps = QUESTION_GENERATION_STEPS.reduce(function(result, stepName, idx) {
      if (idx > stepIndex) {
        return result;
      }
      result[stepName] = {
        questions: questions
      };
      if (idx < stepIndex) {
        result[stepName].closed = true;
      }
      return result;
    }, {});
    return {
      currentStep: stepName,
      steps: steps
    };
  },
  getNextStepDataObject: function(prevStepData, nextStepName) {
    var data, prevStepName;
    prevStepName = QUESTION_GENERATION_STEPS[QUESTION_GENERATION_STEPS.indexOf(nextStepName) - 1];
    data = utils.deepCloneObject(prevStepData);
    data.currentStep = nextStepName;
    data.steps[prevStepName].closed = true;
    data.steps[nextStepName] = {};
    return data;
  },
  cloneStepQuestions: function(stepData) {
    return stepData.questions.map(function(q) {
      return utils.deepCloneObject(q);
    });
  },
  getAdminCommentsForStep: function(stepName) {
    var adminStepName, _ref1;
    adminStepName = stepName.split('.')[1];
    return (_ref1 = mediator.project.get('draftQuestionsComments')) != null ? _ref1[adminStepName] : void 0;
  },
  updateQuestionsWithAdminComments: function(questions, stepName) {
    var adminComments;
    adminComments = helpers.getAdminCommentsForStep(stepName);
    if (adminComments == null) {
      return questions;
    }
    return questions.map(function(q) {
      var updatedQ;
      if (!(q.id in adminComments)) {
        return q;
      }
      updatedQ = utils.deepCloneObject(q);
      if (updatedQ.adminComments == null) {
        updatedQ.adminComments = {};
      }
      updatedQ.adminComments[stepName] = adminComments[updatedQ.id];
      return updatedQ;
    });
  },
  prepareBrainstormingData: function(questions, brainStormingDocId) {
    var data, initialQuestions, initialStepData, stepName;
    stepName = QUESTION_GENERATION_STEPS[1];
    data = helpers.getQuestionsGenerationDataObject(stepName);
    initialQuestions = questions.filter(function(q) {
      return q.source === 'initial';
    });
    initialStepData = data.steps[QUESTION_GENERATION_STEPS[0]];
    initialStepData.questions = initialQuestions;
    questions = helpers.updateQuestionsWithAdminComments(questions, stepName);
    _.extend(data.steps[stepName], {
      questions: questions,
      formsDocId: brainStormingDocId
    });
    return data;
  },
  prepareModerationData: function(draftQuestions, brainstormingData) {
    var brainstormingQuestions, brainstormingStep, data, draftQuestionsIds, moderationDraftQuestions, moderationQuestions, stepName;
    stepName = QUESTION_GENERATION_STEPS[2];
    brainstormingStep = QUESTION_GENERATION_STEPS[1];
    data = helpers.getNextStepDataObject(brainstormingData, stepName);
    draftQuestionsIds = draftQuestions.pluck('id');
    brainstormingQuestions = helpers.cloneStepQuestions(data.steps[brainstormingStep]);
    moderationDraftQuestions = draftQuestions.models.filter(function(m) {
      return m.attributes.source === 'moderation';
    }).map(function(m) {
      return m.attributes;
    });
    moderationQuestions = brainstormingQuestions.concat(moderationDraftQuestions).filter(function(q) {
      var _ref1;
      return _ref1 = q.id, __indexOf.call(draftQuestionsIds, _ref1) >= 0;
    });
    moderationQuestions = helpers.updateQuestionsWithAdminComments(moderationQuestions, brainstormingStep);
    data.steps[stepName].questions = moderationQuestions;
    return data;
  },
  prepareRatingData: function(members, moderationData) {
    var data, formsIds, memberIds, membersData, moderationStepName, questions, stepName;
    stepName = QUESTION_GENERATION_STEPS[3];
    moderationStepName = QUESTION_GENERATION_STEPS[2];
    data = helpers.getNextStepDataObject(moderationData, stepName);
    membersData = members.filter(function(member) {
      return member.get('questionsRatingDocId') != null;
    }).reduce(function(result, member) {
      var id;
      id = member.get('_id');
      result[id] = member.get('questionsRatingDocId');
      return result;
    }, {});
    memberIds = _.keys(membersData);
    formsIds = _.values(membersData);
    questions = helpers.cloneStepQuestions(moderationData.steps[moderationStepName]);
    _.extend(data.steps[stepName], {
      memberIds: memberIds,
      formsIds: formsIds,
      questions: questions
    });
    return data;
  },
  prepareProposalData: function(draftQuestions, ratingData) {
    var data, ratingQuestions, ratingStepName, stepName;
    stepName = QUESTION_GENERATION_STEPS[4];
    ratingStepName = QUESTION_GENERATION_STEPS[3];
    data = helpers.getNextStepDataObject(ratingData, stepName);
    ratingQuestions = helpers.cloneStepQuestions(ratingData.steps[ratingStepName]);
    ratingQuestions = helpers.updateQuestionsWithAdminComments(ratingQuestions, ratingStepName).map(function(q) {
      var draftQ;
      draftQ = draftQuestions.get(q.id);
      if (!draftQ) {
        return q;
      }
      q.approvalStatus = draftQ.get('approvalStatus');
      return q;
    });
    data.steps[stepName].questions = ratingQuestions;
    return data;
  },
  prepareApprovalData: function(members, proposalData) {
    var data, formsIds, memberIds, membersData, proposalStepName, questions, stepName;
    stepName = QUESTION_GENERATION_STEPS[5];
    proposalStepName = QUESTION_GENERATION_STEPS[4];
    data = helpers.getNextStepDataObject(proposalData, stepName);
    membersData = members.filter(function(member) {
      return member.get('questionsApprovalDocId') != null;
    }).reduce(function(result, member) {
      var id;
      id = member.get('_id');
      result[id] = member.get('questionsApprovalDocId');
      return result;
    }, {});
    memberIds = _.keys(membersData);
    formsIds = _.values(membersData);
    questions = helpers.cloneStepQuestions(proposalData.steps[proposalStepName]);
    _.extend(data.steps[stepName], {
      memberIds: memberIds,
      formsIds: formsIds,
      questions: questions
    });
    return data;
  },
  prepareFinishedData: function(approvalData) {
    var stepName;
    stepName = QUESTION_GENERATION_STEPS[6];
    approvalData.steps[QUESTION_GENERATION_STEPS[5]].closed = true;
    approvalData.currentStep = stepName;
    return approvalData;
  },
  ratingDataWithSubmittedDetails: function(ratingData, formsMap) {
    var completedForms, stepData, stepName, submittedMemberIds;
    stepName = QUESTION_GENERATION_STEPS[3];
    submittedMemberIds = _.keys(formsMap);
    ratingData.steps[stepName].submittedMemberIds = submittedMemberIds;
    stepData = ratingData.steps[stepName];
    completedForms = _.values(formsMap);
    stepData.questions = updateQuestionsWithRatingData(stepData.questions, completedForms).toJS();
    return ratingData;
  },
  approvalDataWithSubmittedDetails: function(approvalData, submittedApprovals) {
    var stepName;
    stepName = QUESTION_GENERATION_STEPS[5];
    approvalData.steps[stepName].submittedApprovals = submittedApprovals;
    return approvalData;
  }
};

module.exports = questionGenerationHelpers;
