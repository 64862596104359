AdolopmentSectionSeparator = require 'components/etd/adolopment_section_separator'
ConclusionsSectionTitleRow = require 'components/etd/conclusions_section_title_row'
ConclusionsTextSection = require 'components/etd/conclusions_text_section'
{ getConsensusBasedField } = require 'lib/etd_helper'

AdolopmentRecommendationDecisionSection = ({
  adolopments,
  attachments,
  contentCellClass,
  isConsensus,
  i18n,
  section,
  sectionId
}) ->
  adolopment = adolopments.last()
  adolopmentSection = adolopment.getIn(['templateData', 'conclusions', 'sections', sectionId])
  <tbody>
    <ConclusionsSectionTitleRow title={section.get('description')} colSpan={2} />
      <tr key={adolopment.get('_id')}>
        <td
          className={contentCellClass}
          colSpan={2}
        >
          {if adolopmentSection.has 'content'
            content = getConsensusBasedField(adolopmentSection, 'content', isConsensus)
            <ConclusionsTextSection
              isConsensus={isConsensus}
              content={content}
              cellId={"#{sectionId}#conclusions"}
              editable={false}
              readOnly={true}
              attachments={attachments}
            />
          else
            throw new Error 'Unsupported conclusions section'
          }
        </td>
      </tr>
  </tbody>

module.exports = AdolopmentRecommendationDecisionSection
