ModalVisibility = require 'components/mixins/modal_visibility'
Translation = require 'components/mixins/translation'
ChapterEditModal = require 'components/decision_aid/chapter_edit_modal'
ChapterIconImage = require 'components/decision_aid/chapter_icon_image'
IconButton = require 'components/common/icon_button'
{ getChaperIconPath } = require 'lib/da_helper'


DecisionAidChapterTitle = createReactClass
  displayName: 'DecisionAidChapterTitle'

  propTypes:
    daId: PropTypes.string.isRequired
    deletable: PropTypes.bool.isRequired
    title: PropTypes.string.isRequired
    illustration: PropTypes.string.isRequired
    onUpdate: PropTypes.func.isRequired
    onDelete: PropTypes.func.isRequired

  mixins: [
    Translation('da:details-screen'),
    ModalVisibility # provides @showModal, @hideModal, @isModalVisible methods
  ]

  showEditModal: (evt) ->
    evt.stopPropagation()
    @showModal 'ChapterEditModal'

  handleDelete: (evt) ->
    @hideModal 'ChapterEditModal'
    @props.onDelete()

  handleChapterEditApply: (updatedData) ->
    @hideModal 'ChapterEditModal'
    @props.onUpdate updatedData

  render: ->
    <div className='chapter-title'>
      <div className='title-image'>
        <ChapterIconImage iconName={@props.illustration} />
      </div>
      <div className='title-text'>
        {@props.title}
      </div>
      <div className='title-edit'>
        <IconButton iconName='edit' onClick={@showEditModal} />
      </div>
      <ChapterEditModal
        daId={@props.daId}
        illustration={@props.illustration}
        isOpen={@isModalVisible 'ChapterEditModal'}
        onApply={@handleChapterEditApply}
        onClose={=> @hideModal 'ChapterEditModal'}
        onDelete={if @props.deletable then @handleDelete}
        title={@props.title}
      />
    </div>

module.exports = DecisionAidChapterTitle
