var Migration, UpdateGpsTemplateTexts, W, mediator, migrationName, updateTemplateDataAndDefinition,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'update_gps_template_texts';

updateTemplateDataAndDefinition = function(templateData) {
  templateData.conclusions.sections.implementationConsiderationsOrRemarks.description = "Implementation considerations";
  templateData.conclusions.sections.collectingAndSummarizing.description = "Collecting and summarizing the evidence is a poor use of the guideline panel's limited time and energy (opportunity cost in large)";
  return templateData;
};

module.exports = UpdateGpsTemplateTexts = (function(_super) {
  __extends(UpdateGpsTemplateTexts, _super);

  function UpdateGpsTemplateTexts() {
    UpdateGpsTemplateTexts.__super__.constructor.call(this, false);
  }

  UpdateGpsTemplateTexts.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    UpdateGpsTemplateTexts.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds')).concat(q.get('recommendationAdolopmentIds'));
    }, []);
    docIds = docIds.concat(['etd-goodPracticeStatement-template']);
    return adapter.fetch(projectId, _.compact(_.uniq(docIds))).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return W.resolve();
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        var data, templateData;
        if (doc.templateDef.id !== 'good-practice-statement') {
          return doc;
        }
        data = doc.templateData;
        templateData = updateTemplateDataAndDefinition(data);
        doc.templateData = templateData;
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return UpdateGpsTemplateTexts;

})(Migration);
