var DnDActions, DragDropTypes, isDraggedItem;

DnDActions = require('actions/react_dnd_actions');

DragDropTypes = require('components/scope/outcomes/drag_drop_types');

isDraggedItem = function(props, monitor) {
  var dragGindex, dragItemIndex, hoverGindex, hoverItemIndex, _ref;
  hoverGindex = props.gIndex, hoverItemIndex = props.index;
  _ref = monitor.getItem(), dragGindex = _ref.gIndex, dragItemIndex = _ref.oIndex;
  return hoverGindex === dragGindex && hoverItemIndex === dragItemIndex;
};

module.exports = {
  dragSpec: {
    beginDrag: function(props, monitor, component) {
      var gIndex, oIndex, _key;
      oIndex = props.index, gIndex = props.gIndex, _key = props._key;
      DnDActions.beginDrag({
        type: DragDropTypes.OUTCOME,
        gIndex: gIndex,
        oIndex: oIndex
      });
      return {
        gIndex: gIndex,
        oIndex: oIndex,
        _key: _key
      };
    },
    isDragging: function(props, monitor) {
      return props._key === monitor.getItem()._key;
    },
    endDrag: function(props, monitor) {
      if (!monitor.didDrop()) {
        return DnDActions.endDrag();
      }
    }
  },
  dropSpec: {
    hover: function(props, monitor, component) {
      var hoverGindex, hoverItemIndex;
      if (props._key === monitor.getItem()._key) {
        return;
      }
      hoverGindex = props.gIndex, hoverItemIndex = props.index;
      return DnDActions.hover({
        hoverType: DragDropTypes.OUTCOME,
        type: monitor.getItemType(),
        hoverGindex: hoverGindex,
        hoverItemIndex: hoverItemIndex
      });
    },
    canDrop: function() {
      return false;
    }
  },
  dragCollect: function(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget()
    };
  }
};
