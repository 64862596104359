CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
ConnectStore = require 'components/enhancers/connect_store'
OverReviewStore = require 'stores/over_review_store'
OverReviewInitialStep = require 'components/over_review/initial_step'
SourcesTab = require 'components/over_review/sources_tab'
EvidenceTableTab = require 'components/over_review/evidence_table_tab'
StudiesTab = require 'components/over_review/studies_tab'
OverReviewActions = require 'actions/over_review_actions'
OverReviewQuestionWithEdit = require 'components/over_review/over_reviewed_question_with_edit'
{ Tab, TabsContainer } = require 'components/common/tabs_container'
Spinner = require 'components/common/spinner'
{ bool, object, string, shape } = PropTypes

storeConnectors =
  OverReviewStore: (OverReviewStore, { questionId }) ->
    overReview: OverReviewStore.getOverReviewFor questionId
    editingSourceId: OverReviewStore.getEdtingSourceId()
    isFetching: OverReviewStore.isFetchingFor questionId
    activeTab: OverReviewStore.getActiveTab questionId

OverReview = createReactClass
  displayName: 'OverReview'

  propTypes:
    activeTab: string
    questionId: string
    questionText: string
    questionPico: shape(
      intervention: string.isRequired
      comparison: string.isRequired
      population: string.isRequired
    ).isRequired
    overReview: object
    editingSourceId: string
    isFetching: bool.isRequired

  mixins: [
    CustomRenderMixin
    Translation('over_review:module')
  ]

  handleTabChange: (tabName) ->
    OverReviewActions.changeTab { questionId: @props.questionId, tabName }

  render: ->
    { isFetching, overReview, questionId, editingSourceId, activeTab, questionText } = @props

    if isFetching
      <Spinner />
    else
      if overReview?
        reviewedOutcomes = overReview.getAt 'reviewedOutcomes'
        sources = overReview.getAt 'sources'
        sourcesOrder = overReview.getAt 'sourcesOrder'
        studiesMatrix = overReview.getAt 'studiesMatrix'
        studies = overReview.getAt 'studies'

        <div className='grade-dispute-module'>
          {if overReview.getAt 'hasStarted'
            <div className='over-review-tabs'>
              <OverReviewQuestionWithEdit
                editingSourceId={editingSourceId}
                questionId={questionId}
                questionText={questionText}
                reviewedOutcomes={reviewedOutcomes}
                sources={sources}
                sourcesOrder={sourcesOrder}
              />
              <TabsContainer activeTab={activeTab} changeTab={@handleTabChange} withTabsPane>
                <Tab tabName='sources' tabLabel={@i18n '../tabs.sources'}>
                  <SourcesTab
                    questionId={questionId}
                    questionPico={@props.questionPico}
                    reviewedOutcomes={reviewedOutcomes}
                    sources={sources}
                    sourcesOrder={sourcesOrder}
                  />
                </Tab>
                <Tab tabName='studies' tabLabel={@i18n '../tabs.studies'}>
                  <StudiesTab
                    questionId={questionId}
                    studiesMatrix={studiesMatrix}
                    studies={R.reject R.has('mergedInto'), studies}
                    sources={sources}
                    sourcesOrder={sourcesOrder}
                  />
                </Tab>
                <Tab tabName='evidenceTable' tabLabel={@i18n '../tabs.evidenceTable'}>
                  <EvidenceTableTab
                    questionId={questionId}
                    question={@props.questionPico}
                    reviewedOutcomes={reviewedOutcomes}
                    sources={sources}
                  />
                </Tab>
              </TabsContainer>
            </div>
          else
            <OverReviewInitialStep
              editingSourceId={editingSourceId}
              questionId={questionId}
              reviewedOutcomes={reviewedOutcomes}
              sources={sources}
              sourcesOrder={sourcesOrder}
            />
          }
        </div>
      else
        <div>
          {@i18n 'module_not_enabled'}
        </div>

module.exports = ConnectStore OverReview, OverReviewStore, storeConnectors
