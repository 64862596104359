var DEFAULT_MAX_TRIES, getError, retryRequest;

DEFAULT_MAX_TRIES = 3;

getError = function(xhr) {
  if (xhr.readyState === 4) {
    return new Error('no_connection');
  }
  return new Error("server_error: " + xhr.responseText);
};

retryRequest = function(apiCall, maxTries) {
  var handler, triesCount;
  if (maxTries == null) {
    maxTries = DEFAULT_MAX_TRIES;
  }
  triesCount = 0;
  handler = function(xhr) {
    if ((xhr.readyState < 4 || xhr.status >= 500) && triesCount < maxTries) {
      triesCount += 1;
      return apiCall()["catch"](handler);
    } else {
      throw getError(xhr);
    }
  };
  return apiCall()["catch"](handler);
};

module.exports = {
  retryRequest: retryRequest
};
