var DbHelper, ProjectsInvitations;

DbHelper = require('base/lib/db_helper');

module.exports = ProjectsInvitations = (function() {
  function ProjectsInvitations() {}

  ProjectsInvitations.prototype.getInvitationsDoc = function(login) {
    var udbName;
    udbName = this.udbService.getUdbName(login);
    return this.adapter.fetch(udbName, 'projects-invitations');
  };

  ProjectsInvitations.prototype._getInvitation = function(invitationsDoc, invitationId) {
    var inv, invs, _ref;
    invs = (_ref = invitationsDoc.invitations) != null ? _ref : {};
    inv = invs[invitationId];
    if (!inv) {
      throw new Error("Cannot find invitation with ID = " + invitationId);
    }
    return inv;
  };

  ProjectsInvitations.prototype.getInvitation = function(login, invitationId) {
    return this.getInvitationsDoc(login).then((function(_this) {
      return function(doc) {
        return _this._getInvitation(doc, invitationId);
      };
    })(this));
  };

  ProjectsInvitations.prototype._updateInvitationStatus = function(login, invitationId, newStatus) {
    return this.getInvitationsDoc(login).then((function(_this) {
      return function(doc) {
        var inv, udbName;
        inv = _this._getInvitation(doc, invitationId);
        inv.status = newStatus;
        doc.invitations[invitationId] = inv;
        udbName = _this.udbService.getUdbName(login);
        return _this.adapter.save(udbName, doc);
      };
    })(this));
  };

  ProjectsInvitations.prototype.invitationAccepted = function(login, invitationId) {
    return this._updateInvitationStatus(login, invitationId, 'accepted');
  };

  ProjectsInvitations.prototype.invitationRejected = function(login, invitationId) {
    return this._updateInvitationStatus(login, invitationId, 'rejected');
  };

  ProjectsInvitations.prototype.invitationAbandoned = function(login, invitationId) {
    return this._updateInvitationStatus(login, invitationId, 'abandoned');
  };

  return ProjectsInvitations;

})();
