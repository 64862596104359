var TosCheckService, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

mediator = require('mediator');

module.exports = TosCheckService = (function() {
  function TosCheckService() {
    this.check = __bind(this.check, this);
  }

  TosCheckService.prototype.notices = {
    accept: $.t('tos_notification.tos_accept_notice'),
    update: $.t('tos_notification.tos_update_notice')
  };

  TosCheckService.prototype.check = function() {
    if (!this._tosAccepted()) {
      return this._showTosNotice(mediator.user.get('tosAcceptedVersion') != null ? this.notices.update : this.notices.accept);
    } else {
      return mediator.publish('tosAccepted');
    }
  };

  TosCheckService.prototype._showTosNotice = function(message) {
    var self;
    self = this;
    return mediator.dialogs.notify({
      width: 600,
      message: message,
      closeBtn: false,
      hideOnClick: false,
      modal: true,
      position: 'center',
      ms: null,
      buttons: [
        {
          'data-role': 'log_out',
          text: $.t('user.log_out'),
          'class': 'danger'
        }, {
          'data-role': 'see_tos',
          text: $.t('tos_notification.display_terms')
        }, {
          'data-role': 'accept',
          text: $.t('tos_notification.i_confirm'),
          'class': 'success'
        }
      ]
    }).on('click:log_out', function() {
      this.destroy();
      return mediator.publish('logout');
    }).on('click:see_tos', function() {
      return window.open($.t('terms_of_service_url'), '_blank');
    }).on('click:accept', function() {
      this.destroy();
      self._acceptTOS();
      return mediator.publish('tosAccepted');
    });
  };

  TosCheckService.prototype._tosAccepted = function() {
    return mediator.user.get('tosAcceptedVersion') === window.gdt.tosVersion;
  };

  TosCheckService.prototype._acceptTOS = function() {
    return mediator.user.save('tosAcceptedVersion', window.gdt.tosVersion);
  };

  TosCheckService.prototype.getCurrentVersion = function() {
    return window.gdt.tosVersion;
  };

  return TosCheckService;

})();
