module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": "variiert",
              "value": "varies"
            },
            {
              "text": "nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Ist die Intervention für die wichtigsten Interessenvertreter annehmbar?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Vermutlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Gibt es wichtige Interessengruppen, die die Verteilung von Nutzen, Schaden und Kosten nicht akzeptieren würden?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Vermutlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Gibt es wichtige Interessengruppen, die die Kosten oder unerwünschten Auswirkungen auf kurze Sicht nicht für erwünschte Auswirkungen (Vorteile) in der Zukunft in Kauf nehmen würden?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich schon ",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Gibt es wichtige Interessengruppen, die mit den Werten, die den erwünschten oder unerwünschten Auswirkungen beigemessen werden, nicht einverstanden sind (weil sie persönlich betroffen sein könnten oder weil sie die relative Bedeutung der Auswirkungen für andere einschätzen)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Würde sich die Intervention (Option) negativ auf die Autonomie der Menschen auswirken?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Gibt es wichtige Interessengruppen, die die Intervention (Option) aus anderen Gründen als den Auswirkungen auf die Autonomie der Menschen moralisch ablehnen würden (d. h. in Bezug auf ethische Grundsätze wie Schadenverhütung, Wohltätigkeit oder Gerechtigkeit)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Ist es wahrscheinlich, dass die wichtigsten Interessengruppen die Verteilung des Nutzens, der Schäden und der Kosten bzw. die Kosten oder unerwünschten Auswirkungen auf kurze Sicht für die erwünschten Auswirkungen (Nutzen) in der Zukunft nicht akzeptieren? Ist es wahrscheinlich, dass sie mit den Werten, die den erwünschten oder unerwünschten Auswirkungen beigemessen werden, nicht einverstanden sind oder die diagnostische Intervention aufgrund ethischer Bedenken nicht akzeptieren?",
          "options": [
            {
              "text": "Nein",
              "value": "no"
            },
            {
              "text": "Wahrscheinlich nicht",
              "value": "probably_no"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Ja",
              "value": "yes"
            }
          ],
          "ratingDescription": "Welche Maßnahme ist für die wichtigsten Interessengruppen am akzeptabelsten?",
          "ratingOptions": [
            "best_acceptability",
            "intermediate_acceptability",
            "worst_acceptability"
          ],
          "selectedOption": ""
        },
        "additionalSection": {
          "additionalOptions": [
            {
              "text": "Zusätzliche Beurteilung 1",
              "value": "additionalOption_1"
            },
            {
              "text": "Zusätzliche Beurteilung 2",
              "value": "additionalOption_2"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Beschreibung des benutzerdefinierten Kriteriums",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Benutzerdefinierten Tooltip bereitstellen",
          "options": [
            {
              "text": "Beurteilung 1",
              "value": "option_1"
            },
            {
              "text": "Beurteilung 2",
              "value": "option_2"
            },
            {
              "text": "Beurteilung 3",
              "value": "option_3"
            },
            {
              "text": "Beurteilung 4",
              "value": "option_4"
            },
            {
              "text": "Beurteilung 5",
              "value": "option_5"
            }
          ],
          "selectedOption": ""
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": "Variiert ",
              "value": "varies"
            },
            {
              "text": "Nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Bevorzugt die Abwägung zwischen erwünschten und unerwünschten Wirkungen die Intervention oder die Vergleichsintervention?",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "Detaillierte Beurteilungen für dieses Kriterium umfassen Bewertungen für jedes der vier vorangegangenen Kriterien:"
              },
              {
                "additions": [
                  "Wie viel weniger schätzen die Menschen Ergebnisse, die in der Zukunft liegen, im Vergleich zu Ergebnissen, die jetzt eintreten (ihre Diskountraten)?",
                  "Die Einstellung der Menschen zu unerwünschten Auswirkungen (wie risikoscheu sie sind).",
                  "Die Einstellung der Menschen zu erwünschten Wirkungen (wie risikofreudig sie sind)."
                ],
                "description": "Darüber hinaus sollten die Panels prüfen (und gegebenenfalls dokumentieren), inwieweit die folgenden Überlegungen das Gleichgewicht zwischen erwünschten und unerwünschten Wirkungen beeinflussen könnten:"
              }
            ],
            "panelDiscussion": ""
          },
          "info": "Wie sieht das Gleichgewicht zwischen den erwünschten und den unerwünschten Auswirkungen aus, wenn man berücksichtigt, wie hoch der Einzelne die Hauptergebnisse einschätzt, wie erheblich die erwünschten und die unerwünschten Auswirkungen sind, wie sicher diese Einschätzungen sind, wie hoch die Diskountraten sind und wie risikoscheu und risikofreudig der Einzelne ist?",
          "options": [
            {
              "text": "Bevorzugt die Kontrollintervention",
              "value": "favors_comparison"
            },
            {
              "text": "Bevorzugt wahrscheinlich die Kontrollintervention",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Bevorzugt weder die Intervention noch die Kontrollintervention",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Bevorzugt wahrscheinlich die Intervention",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Bevorzugt die Intervention",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?",
          "ratingOptions": [
            "best_balance",
            "intermediate",
            "worst_balance"
          ],
          "selectedOption": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalOptions": [
            {
              "text": "variiert",
              "value": "varies"
            },
            {
              "text": "nicht bekannt ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Bevorzugt die Abwägung zwischen erwünschten und unerwünschten gesundheitlichen Auswirkungen die Intervention oder die Vergleichsintervention?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Vergleichsintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Vergleichsintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch Vergleichsintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Bedingt eine kurz- und längerfristige  <strong>Wirksamkeit<strong> (unter kontrollierten, oft idealen Bedingungen) oder  <strong>Effektivität<strong> (in einer realen Umgebung) der Intervention auf die  <strong>Gesundheit des Einzelnen<strong>, einschließlich der von den Patienten berichteten Ergebnisse, einen Vorteil zugunsten der Intervention oder des Vergleichs?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Vergleichsintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Vergleichsintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Vergleichsintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Sind die kurz- und längerfristigen Auswirkungen der Intervention auf die Gesundheit der Bevölkerung, einschließlich der von den Begünstigten berichteten Ergebnisse, zugunsten der Intervention oder des Vergleichs? (Dabei sollte auch berücksichtigt werden, ob die Ergebnisse auf Bevölkerungsebene aggregierte Ergebnisse auf individueller Ebene darstellen oder durch die Dynamik des Systems entstehen).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Vergleichsintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Vergleichsintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Vergleichsintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Begünstigt das Ausmaß, in dem <strong>die Patienten/Begünstigten unterschiedliche Gesundheitsergebnisse </strong>bewerten, die Intervention oder den Vergleich?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt  ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Vergleichsintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Vergleichsintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Vergleichsintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Ergibt sich aus der <strong>Wahrscheinlichkeit und Schwere der mit der Intervention verbundenen unerwünschten Wirkungen</strong> (einschließlich des Risikos, dass die Intervention falsch durchgeführt wird) ein Vorteil zugunsten der Intervention oder des Vergleichs?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Vergleichsintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Vergleichsintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Vergleichsintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Ergibt sich <strong>aus den weiteren positiven oder negativen gesundheitsbezogenen Auswirkungen</strong> (z. B. Abbau von Stigmata, positive Auswirkungen auf andere Erkrankungen, Übertragungseffekte auf andere Patienten/Begünstigte), die mit der Intervention in Verbindung stehen, ein Vorteil zugunsten der Intervention oder des Vergleichs?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je größer der mit einer Intervention verbundene Netto-Gesundheitsnutzen ist, desto größer ist die Wahrscheinlichkeit, dass eine allgemeine Empfehlung zugunsten dieser Intervention ausgesprochen wird.",
          "options": [
            {
              "text": "Bevorzugt die Vergleichsintervention",
              "value": "favors_the_comparison"
            },
            {
              "text": "Bevorzugt wahrscheinlich die Vergleichsintervention",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Bevorzugt weder die Intervention noch die Vergleichsintervention",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Bevorzugt wahrscheinlich die Intervention",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "Bevorzugt die Intervention",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "Keine Studien eingeschlossen",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie groß ist die Vertrauenswürdigkeit der Evidenz für die Auswirkungen des Tests?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Wie gut gibt die Forschung Aufschluss über die wahrscheinlichen Auswirkungen auf alle kritischen Ergebnisse, d. h. wie hoch ist die Wahrscheinlichkeit, dass die Auswirkungen so stark von den Forschungsergebnissen abweichen, dass sie eine Entscheidung über die diagnostische Maßnahme beeinflussen könnten?",
          "options": [
            {
              "text": "Sehr niedrig",
              "value": "very_low"
            },
            {
              "text": "Niedrig",
              "value": "low"
            },
            {
              "text": "Moderat",
              "value": "moderate"
            },
            {
              "text": "Hoch",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "Keine Studien eingeschlossen",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie groß ist die Vertrauenswürdigkeit der Evidenz für die Auswirkungen des Tests?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Wie gut gibt die Forschung Aufschluss über die wahrscheinlichen Auswirkungen auf alle kritischen Ergebnisse, d. h. wie hoch ist die Wahrscheinlichkeit, dass die Auswirkungen so stark von den Forschungsergebnissen abweichen, dass sie eine Entscheidung über die Maßnahme beeinflussen könnten?",
          "options": [
            {
              "text": "Sehr niedrig",
              "value": "very_low"
            },
            {
              "text": "Niedrig",
              "value": "low"
            },
            {
              "text": "Moderat",
              "value": "moderate"
            },
            {
              "text": "Hoch",
              "value": "high"
            }
          ],
          "ratingDescription": "What is the overall certainty of the evidence of effects for each intervention?",
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "Keine Studien eingeschlossen",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Mit welcher Sicherheit lassen sich die Wirkungen der Maßnahmen, die sich an den Testergebnissen orientieren, insgesamt nachweisen?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Sehr niedrig",
              "value": "very_low"
            },
            {
              "text": "Niedrig",
              "value": "low"
            },
            {
              "text": "Moderat",
              "value": "moderate"
            },
            {
              "text": "Hoch",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "Keine Studien eingeschlossen",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie hoch ist die Vertrauenswürdigkeit der Evidenz in Bezug auf die erforderlichen Ressourcen (Kosten)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Schwankend",
                    "value": "varies"
                  },
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein oder nicht",
                    "value": "no"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Sind alle wesentlichen Punkte der Ressourcennutzung, die sich zwischen den geprüften Optionen unterscheiden könnten, ermittelt worden?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Wichtige Unsicherheit",
                    "value": "important"
                  },
                  {
                    "text": "Möglicherweise wichtige Unsicherheit",
                    "value": "probably_important"
                  },
                  {
                    "text": "Wahrscheinlich keine wichtige Unsicherheit",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Keine wichtige Unsicherheit",
                    "value": "no_important"
                  }
                ],
                "question": "Wie zuverlässig ist die Berechnung der Kosten für die verschiedenen Optionen, die sich in der Ressourcennutzung unterscheiden?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Wichtige Variabilität",
                    "value": "important"
                  },
                  {
                    "text": "Möglicherweise wichtige Variabilität",
                    "value": "probably_important"
                  },
                  {
                    "text": "Möglicherweise keine wichtige Variabiltität",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Keine wichtige Variabilität",
                    "value": "no_important"
                  }
                ],
                "question": "Gibt es erhebliche Unterschiede bei den Kosten für die verschiedenen Optionen, die für die Ressourcennutzung in Frage kommen?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Wie zuverlässig ist die Evidenz für einen Unterschied bei den einzelnen Arten des Ressourcenverbrauchs (z. B. Medikamente, Krankenhausaufenthalte) und den Kosten der Ressourcen?",
          "options": [
            {
              "text": "Sehr niedrig",
              "value": "very_low"
            },
            {
              "text": "Niedrig",
              "value": "low"
            },
            {
              "text": "moderat",
              "value": "moderate"
            },
            {
              "text": "Hoch ",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "Keine Studien eingeschlossen",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie hoch ist die Vertrauenswürdigkeit der Evidenz insgesamt für die Testgenauigkeit?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Sehr niedrig",
              "value": "very_low"
            },
            {
              "text": "Niedrig",
              "value": "low"
            },
            {
              "text": "Moderat",
              "value": "moderate"
            },
            {
              "text": "Hoch",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "Keine Studien eingeschlossen",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie vertrauenswürdig ist die Evidenz für kritische oder wichtige direkte Vorteile, unerwünschte Wirkungen oder Belastungen durch den Test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Sehr niedrig",
              "value": "very_low"
            },
            {
              "text": "Niedrig",
              "value": "low"
            },
            {
              "text": "Moderat",
              "value": "moderate"
            },
            {
              "text": "Hoch",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "Keine Studien eingeschlossen",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie sicher ist die Verbindung zwischen den Testergebnissen und der Therapieentscheidung?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Sehr niedrig",
              "value": "very_low"
            },
            {
              "text": "Niedrig",
              "value": "low"
            },
            {
              "text": "Moderat",
              "value": "moderate"
            },
            {
              "text": "Hoch",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": "variiert ",
              "value": "varies"
            },
            {
              "text": "Keine Studien eingeschlossen ",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wirkt sich die Kostenwirksamkeit einer Intervention als Vorteil zugunsten der Intervention oder des Vergleichs aus?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Keine Studien eingeschlossen ",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Nein ",
                    "value": "no"
                  },
                  {
                    "text": "Vermutlich nicht ",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich schon",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja ",
                    "value": "yes"
                  }
                ],
                "question": "Spricht die Berechnung des Kosten-Nutzen-Verhältnis auf einseitige Sensitivitätsanalysen an?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Keine Studien eingeschlossen",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Vermutlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich schon",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Spricht die Berechnung des Kosten-Nutzen-Verhältnisses auf multivariable Sensitivitätsanalysen an?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Keine Studien eingeschlossen ",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Sehr erhebliche Einschränkungen",
                    "value": "very_serious"
                  },
                  {
                    "text": "Erhebliche Einschränkungen",
                    "value": "serious"
                  },
                  {
                    "text": "Keine erheblichen Einschränkungen",
                    "value": "no_serious"
                  }
                ],
                "question": "Ist die wirtschaftliche Bewertung, auf die sich die Kostenwirksamkeitsschätzung stützt, zuverlässig?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Keine Studien eingeschlossen",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Sehr erhebliche Einschränkungen",
                    "value": "very_serious"
                  },
                  {
                    "text": "Erhebliche Einschränkungen",
                    "value": "serious"
                  },
                  {
                    "text": "Keine erheblichen Einschränkungen",
                    "value": "no_serious"
                  }
                ],
                "question": "Ist die wirtschaftliche Bewertung, auf die sich die Kostenwirksamkeitsschätzung stützt, auf das betreffende Umfeld anwendbar?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Ist die Intervention kostenwirksam, unter Berücksichtigung der Unsicherheit oder Variabilität der Kosten, der Unsicherheit oder Variabilität des Nettonutzens, der Sensitivitätsanalysen sowie der Zuverlässigkeit und Anwendbarkeit der wirtschaftlichen Bewertung?",
          "options": [
            {
              "text": "Bevorzugt die Kontrollintervention",
              "value": "favors_comparison"
            },
            {
              "text": "Bevorzugt wahrscheinlich die Kontrollintervention",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Bevorzugt weder die Intervention noch die Kontrollintervention",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Bevorzugt wahrscheinlich die Intervention",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Bevorzugt die Intervention",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Welche Intervention spricht für die Kostenwirksamkeit?",
          "ratingOptions": [
            "best_cost_effectiveness",
            "intermediate_cost_effectiveness",
            "worst_cost_effectiveness"
          ],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": "Schwankend",
              "value": "varies"
            },
            {
              "text": "nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie erheblich sind die erwünschten, erwarteten Wirkungen?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Schwankend ",
                    "value": "varies"
                  },
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Unbedeutend",
                    "value": "trivial"
                  },
                  {
                    "text": "gering",
                    "value": "small"
                  },
                  {
                    "text": "moderat",
                    "value": "moderate"
                  },
                  {
                    "text": "Groß",
                    "value": "large"
                  }
                ],
                "question": "Wie zutreffend ist die angenommene Wirkung (Unterschied) für jedes Hauptergebnis, für das es einen geschätzten Effekt gibt?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Wie groß sind die erwarteten Auswirkungen der Intervention unter Berücksichtigung der Relevanz der Ergebnisse (wie sehr sie geschätzt werden) und der Stärke des Effekts (die Wahrscheinlichkeit, einen Nutzen zu erfahren oder wie groß die Verbesserung ist, die der Einzelne wahrscheinlich erfahren wird)?",
          "options": [
            {
              "text": "Unbedeutend",
              "value": "trivial"
            },
            {
              "text": "kaum ",
              "value": "small"
            },
            {
              "text": "moderat",
              "value": "moderate"
            },
            {
              "text": "Groß",
              "value": "large"
            }
          ],
          "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
          "ratingOptions": [
            "most_effective",
            "intermediate_effectiveness",
            "least_effective"
          ],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": "Variiert ",
              "value": "varies"
            },
            {
              "text": "Nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Was wären die Auswirkungen auf die gesundheitliche Chancengleichheit?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich schon ",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Gibt es Gruppen oder Milieus, die in Bezug auf das Problem oder die in Betracht gezogenen Optionen benachteiligt sein könnten?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Wahrscheinlich nein",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Gibt es plausible Gründe, Unterschiede in der relativen Wirksamkeit der Option für benachteiligte Gruppen oder Settings zu erwarten?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Gibt es unterschiedliche Ausgangsbedingungen in den verschiedenen Gruppen oder Milieus, die die absolute Wirksamkeit der Option oder die Bedeutung des Problems für benachteiligte Gruppen oder Milieus beeinflussen?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Vermutlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Gibt es wichtige Überlegungen, die bei der Umsetzung der Intervention (Option) angestellt werden sollten, um sicherzustellen, dass Ungleichheiten nach Möglichkeit verringert und nicht vergrößert werden?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Gibt es plausible Gründe, Unterschiede in der relativen Wirksamkeit der Intervention für benachteiligte Untergruppen oder unterschiedliche Ausgangsbedingungen zwischen benachteiligten Untergruppen zu erwarten, die die absolute Wirksamkeit der Intervention oder die Bedeutung des Problems beeinflussen?",
          "options": [
            {
              "text": "Reduziert",
              "value": "reduced"
            },
            {
              "text": "Wahrscheinlich reduziert",
              "value": "probably_reduced"
            },
            {
              "text": "Wahrscheinlich kein Einfluss",
              "value": "probably_no_impact"
            },
            {
              "text": "Wahrscheinlich gestiegen",
              "value": "probably_increased"
            },
            {
              "text": "Gestiegen",
              "value": "increased"
            }
          ],
          "ratingDescription": "Falls empfohlen, welche Maßnahme würde gesundheitliche Ungleichheiten am stärksten verringern?",
          "ratingOptions": [
            "most_reduction",
            "intermediate_reduction",
            "less_reduction"
          ],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": "kommt darauf an",
              "value": "varies"
            },
            {
              "text": "nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Ist die Intervention durchführbar?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "variiert",
                    "value": "varies"
                  },
                  {
                    "text": "nicht bekannt ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich schon",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Ist die Intervention (Option) nachhaltig?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "variiert ",
                    "value": "varies"
                  },
                  {
                    "text": "nicht bekannt ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich schon",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Gibt es wichtige Faktoren, die die Durchführbarkeit der Intervention (Option) wahrscheinlich einschränken oder die bei der Umsetzung berücksichtigt werden müssen?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Lässt sich die Anwendung der diagnostischen Intervention aufrechterhalten und können mögliche Schwierigkeiten dadurch beseitigt werden?",
          "options": [
            {
              "text": "Nein",
              "value": "no"
            },
            {
              "text": "Vermutlich nein ",
              "value": "probably_no"
            },
            {
              "text": "Wahrscheinlich schon ",
              "value": "probably_yes"
            },
            {
              "text": "Ja",
              "value": "yes"
            }
          ],
          "ratingDescription": "Welche Intervention ist eher durchführbar?",
          "ratingOptions": [
            "most_feasible",
            "intermediate_feasibility",
            "least_feasible"
          ],
          "selectedOption": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalOptions": [
            {
              "text": "Variiert",
              "value": "varies"
            },
            {
              "text": "Nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Ist die Option durchführbar?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Gibt es <strong>rechtliche Hindernisse</strong>, die die Durchführbarkeit der Intervention einschränken könnten?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ja",
                    "value": "yes"
                  },
                  {
                    "text": "Wahrscheinlich schon ",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Vermutlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Nein",
                    "value": "no"
                  }
                ],
                "question": "Gibt es <strong>Governance-Aspekte</strong> (z. B. strategische Überlegungen, frühere Entscheidungen), die die Durchführbarkeit der Intervention einschränken könnten? (Dies sollte Überlegungen zum Vorhandensein oder Fehlen formeller oder informeller Institutionen einschließen, die eine wirksame Führung, Aufsicht und Rechenschaftspflicht bei der Umsetzung der Intervention gewährleisten können, was sich auf die Durchführbarkeit auswirkt)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Große positive Auswirkungen",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Moderate positive Auswirkungen",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Vernachlässigbare positive und negative Auswirkungen",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Moderate nachteilige Auswirkungen",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Große nachteilige Auswirkungen",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Welche <strong>Auswirkungen</strong> hat die Intervention auf das bestehende Gesundheitssystem?</strong> (Dies schließt Überlegungen zur Interaktion der Intervention mit dem bestehenden Gesundheitssystem und seinen Komponenten oder deren Auswirkungen auf das System ein).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Große positive Auswirkungen",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Moderate positive Auswirkungen",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Vernachlässigbare positive und negative Auswirkungen",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Moderate nachteilige Auswirkungen",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Große nachteilige Auswirkungen",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Welche <strong>Auswirkungen</strong> hat die Intervention <strong>auf das Gesundheitspersonal und die Humanressourcen im weiteren Sinne</strong> im Gesundheitssektor oder in anderen Sektoren)? (Dabei sollten auch Überlegungen zum Bedarf, zur Nutzung und zu den Auswirkungen auf das Gesundheitspersonal und andere Humanressourcen sowie zu deren Verteilung angestellt werden).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Große positive Auswirkungen",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Moderate positive Auswirkungen",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Vernachlässigbare positive und negative Auswirkungen",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Moderate nachteilige Auswirkungen",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Große nachteilige Auswirkungen",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Welche <strong>Auswirkungen</strong> hat die Intervention <strong>auf die Infrastruktur des Gesundheitssystems und die Infrastruktur im Allgemeinen</strong>? im Gesundheitssektor oder in anderen Sektoren? (Dies sollte Überlegungen zum Bedarf, zur Nutzung und zu den Auswirkungen auf nicht-menschliche Ressourcen und Infrastrukturen sowie zu deren Verteilung beinhalten).",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je besser die Durchführbarkeit einer Option aus der Sicht aller oder der meisten Beteiligten ist, desto größer ist die Wahrscheinlichkeit einer allgemeinen Empfehlung zugunsten der Intervention. Je vorteilhafter die Auswirkungen auf das Gesundheitssystem insgesamt sind, desto größer ist die Wahrscheinlichkeit einer allgemeinen Empfehlung für die Intervention.",
          "options": [
            {
              "text": "Nein",
              "value": "no"
            },
            {
              "text": "Wahrscheinlich nein ",
              "value": "probably_not"
            },
            {
              "text": "Unsicher",
              "value": "uncertain"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Ja",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "feasible": {
          "description": "to identify and measure, and not susceptible to manipulation",
          "options": [],
          "ratingOptions": [
            "very_feasible",
            "feasible",
            "least_feasible"
          ],
          "selectedOptions": ""
        },
        "financialAndEconomicConsiderations": {
          "additionalOptions": [
            {
              "text": "Schwankend",
              "value": "varies"
            },
            {
              "text": "nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Ergeben sich aus der finanziellen und wirtschaftlichen Sicht Vorteile für die Intervention oder die Kontrollgruppe?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Kontrollintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Kontrollgruppe",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Kontrollgruppe",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Wirken sich <strong>Kosten und Budget</strong>für die Durchführung und Aufrechterhaltung einer Intervention zugunsten der Intervention oder des Vergleichs aus? (Dabei sollte auch kurz- und langfristige Interventionen in Kosten und Budget berücksichtigt werden.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Kontrollgruppe",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Kontrollgruppe",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Kontrollgruppe",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Ergibt sich <strong>aus der Gesamtwirkung der Intervention auf die Wirtschaft</strong> ein Vorteil zugunsten der Intervention oder des Vergleichs? (Dabei sollte berücksichtigt werden, wie sich die verschiedenen Arten von wirtschaftlichen Auswirkungen auf die verschiedenen Sektoren oder Organisationsebenen verteilen, ob die Intervention zur Erreichung allgemeiner Entwicklungs- und Armutsbekämpfungsziele beiträgt oder diese einschränkt und wie sie sich auf die verfügbaren Arbeitskräfte auswirkt.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "nicht bekannt ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Kontrollgruppe",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Kontrollgruppe",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Kontrollgruppe",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Ergibt sich aus <strong>der Abwägung von Kosten und Nutzen</strong> (z. B. geschätzte Kostenwirksamkeit, Kosten-Nutzen oder Gesamtkosten) eine bevorzugte Entscheidung für die Intervention oder den Vergleich?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je mehr Vorteile eine Intervention aus finanzieller und wirtschaftlicher Sicht bietet, umso größer ist die Wahrscheinlichkeit einer generellen Empfehlung zugunsten dieser Intervention.",
          "options": [
            {
              "text": "Bevorzugt die Kontrollgruppe",
              "value": "favors_the_comparison"
            },
            {
              "text": "Bevorzugt wahrscheinlich die Kontrollgruppe",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Bevorzugt weder die Intervention noch die Kontrollgruppe",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Bevorzugt wahrscheinlich die Intervention",
              "value": "probably_favor_the_intervention"
            },
            {
              "text": "Bevorzugt die Intervention",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalOptions": [
            {
              "text": "Variiert ",
              "value": "varies"
            },
            {
              "text": "Nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie würde sich die Intervention auf gesundheitliche Chancengleichheit, Gleichberechtigung und Nichtdiskriminierung auswirken?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Wird die Intervention wahrscheinlich bestehende Ungleichheiten und/oder Ungleichheiten in Bezug auf den Gesundheitszustand oder seine Determinanten verstärken? (Dies sollte auch Überlegungen zu den wahrscheinlichen Veränderungen der Ungleichheiten im Laufe der Zeit beinhalten, z. B. ob sich die anfänglichen Zunahmen im Laufe der Zeit ausgleichen werden, wenn die Intervention ausgeweitet wird).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Sind Nutzen und Schaden der Intervention wahrscheinlich gleichmäßig verteilt? (Dabei sollte ein besonderes Augenmerk auf die Auswirkungen auf gefährdete, marginalisierte oder anderweitig sozial benachteiligte Bevölkerungsgruppen gelegt werden).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert ",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Ist die Intervention für die betroffenen Bevölkerungsgruppen erschwinglich und somit finanziell zugänglich? (Dabei sollten auch die Auswirkungen auf die Haushaltsausgaben für Gesundheit, einschließlich des Risikos schwerwiegender Gesundheitsausgaben und gesundheitsbezogener finanzieller Risiken, berücksichtigt werden).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Ist die Intervention für die betroffenen Bevölkerungsgruppen zugänglich? (Dies sollte sowohl Überlegungen zum physischen Zugang als auch zur Verständlichkeit von Informationen beinhalten).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Vermutlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Betrifft die Intervention eine besonders schwerwiegende (z. B. lebensbedrohliche, Personen mit schlechtem Gesundheitszustand betreffende) oder seltene Erkrankung?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Vermutlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Stellt die Maßnahme die einzige verfügbare Option dar? (Dabei sollte auch berücksichtigt werden, ob die Maßnahme in einem angemessenen Verhältnis zum Bedarf steht und ob sie in regelmäßigen Abständen überprüft wird).",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je größer die Wahrscheinlichkeit ist, dass die Intervention die gesundheitliche Chancengleichheit und/oder Gleichstellung erhöht und die Diskriminierung einer bestimmten Gruppe verringert, desto größer ist die Wahrscheinlichkeit einer allgemeinen Empfehlung zugunsten dieser Intervention.",
          "options": [
            {
              "text": "Negativ",
              "value": "negative"
            },
            {
              "text": "Wahscheinlich negativ",
              "value": "probably_negative"
            },
            {
              "text": "Weder negativ noch positiv",
              "value": "neither_negative_nor_positive"
            },
            {
              "text": "Wahrscheinlich positiv",
              "value": "probably_positive"
            },
            {
              "text": "Positiv",
              "value": "positive"
            }
          ],
          "selectedOption": ""
        },
        "humanRights": {
          "additionalOptions": [
            {
              "text": "Variiert",
              "value": "varies"
            },
            {
              "text": "Nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Steht die Intervention im Einklang mit den allgemeinen Menschenrechtsstandards und -grundsätzen?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Alle Empfehlungen sollten im Einklang mit den allgemeinen Menschenrechtsstandards und -grundsätzen stehen.",
          "options": [
            {
              "text": "Nein",
              "value": "no"
            },
            {
              "text": "Wahrscheinlich nicht",
              "value": "probably_no"
            },
            {
              "text": "Unsicher",
              "value": "uncertain"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Ja",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problem": {
          "additionalOptions": [
            {
              "text": "Variiert",
              "value": "varies"
            },
            {
              "text": "Nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Ist das Problem eine Priotität?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Sind die Folgen des Problems gravierend (d. h. schwerwiegend oder wichtig im Hinblick auf den potenziellen Nutzen oder die Einsparungen)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Ist das Problem dringend?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nein",
                    "value": "no"
                  },
                  {
                    "text": "Wahrscheinlich nicht",
                    "value": "probably_no"
                  },
                  {
                    "text": "Wahrscheinlich ja",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ja",
                    "value": "yes"
                  }
                ],
                "question": "Handelt es sich um eine anerkannte Priorität (z. B. auf der Grundlage einer politischen Entscheidung)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je schwerwiegender und dringender das Problem ist, desto wahrscheinlicher wird die Option, die das Problem adressiert, zu einer Priorität",
          "options": [
            {
              "text": "Nein",
              "value": "no"
            },
            {
              "text": "Wahrscheinlich nicht",
              "value": "probably_no"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Ja",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "qualityOfEvidence": {
          "additionalOptions": [
            {
              "text": "variiert ",
              "value": "varies"
            },
            {
              "text": "nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie hoch ist die gesamte Qualität der Evidenz?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Je besser die Qualität der Evidenz für die verschiedenen Kriterien des WHO-INTEGRATE-Rahmens ist, desto wahrscheinlicher ist eine allgemeine Empfehlung.",
          "options": [
            {
              "text": "Sehr niedrig",
              "value": "very_low"
            },
            {
              "text": "Niedrig",
              "value": "low"
            },
            {
              "text": "moderat",
              "value": "moderate"
            },
            {
              "text": "Hoch",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "relevantDirect": {
          "description": "to the selected problem and in the field of application",
          "options": [],
          "ratingOptions": [
            "very_relevant",
            "relevant",
            "least_relevant"
          ],
          "selectedOptions": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": "variiert",
              "value": "varies"
            },
            {
              "text": "nicht bekannt ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie hoch ist der Aufwand an erforderlichen Ressourcen (Kosten)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "variiert ",
                    "value": "varies"
                  },
                  {
                    "text": "nicht bekannt ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "geringfügig",
                    "value": "trivial"
                  },
                  {
                    "text": "kaum",
                    "value": "small"
                  },
                  {
                    "text": "moderat",
                    "value": "moderate"
                  },
                  {
                    "text": "stark",
                    "value": "large"
                  }
                ],
                "question": "Wie unterscheiden sich die Kosten pro Ressourceneinheit, wenn weniger Aufwand erforderlich war?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "variiert",
                    "value": "varies"
                  },
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "stark ",
                    "value": "large"
                  },
                  {
                    "text": "moderat",
                    "value": "moderate"
                  },
                  {
                    "text": "kaum ",
                    "value": "small"
                  },
                  {
                    "text": "geringfügig",
                    "value": "trivial"
                  }
                ],
                "question": "Wie unterscheiden sich die Kosten pro Ressourceneinheit, wenn mehr Aufwand erforderlich war?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Wie unterscheiden sich die Kosten für die Intervention und den Vergleich hinsichtlich der eingesetzten Ressourcen?",
          "options": [
            {
              "text": "Hohe Ausgaben",
              "value": "large_costs"
            },
            {
              "text": "Moderate Ausgaben ",
              "value": "moderate_costs"
            },
            {
              "text": "vermeidbare Ausgaben und Einsparungen",
              "value": "negligible_costs_savings"
            },
            {
              "text": "Moderate Einsparungen",
              "value": "moderate_savings"
            },
            {
              "text": "große Einsparungen",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
          "ratingOptions": [
            "less_costs",
            "intermediate_costs",
            "most_costs"
          ],
          "selectedOption": ""
        },
        "responsive": {
          "description": "sensitive to change that means they should be able to capture possible changes in the system",
          "options": [],
          "ratingOptions": [
            "very_responsive",
            "responsive",
            "least_responsive"
          ],
          "selectedOptions": ""
        },
        "scientificallySound": {
          "description": "based on evidence (GRADE criteria) and strongly correlated with the quality of care provided",
          "options": [],
          "ratingOptions": [
            "very_scientifically_sound",
            "scientifically_sound",
            "least_scientifically_sound"
          ],
          "selectedOptions": ""
        },
        "societalImplications": {
          "additionalOptions": [
            {
              "text": "Variiert ",
              "value": "varies"
            },
            {
              "text": "Nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Fällt der Vergleich zwischen erwünschten und unerwünschten gesellschaftlichen Auswirkungen zugunsten der Intervention oder des Vergleichs aus?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Kontrollintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Kontrollintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Kontrollintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Sind die sozialen Auswirkungen und Folgen der Intervention (z. B. Zunahme oder Verringerung der Stigmatisierung, Bildungsergebnisse, sozialer Zusammenhalt oder die Verwirklichung verschiedener Menschenrechte über die Gesundheit hinaus) zugunsten der Intervention oder der Vergleichsintervention?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Kontrollintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Kontrollintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Kontrollintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Sind die Umweltauswirkungen und die Auswirkungen auf die ökologische Nachhaltigkeit (z. B. Schutz der natürlichen Ressourcen, Abschwächung des Klimawandels oder Anpassung an den Klimawandel) für die Intervention oder den Vergleich günstig?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je größer der mit einer Intervention verbundene gesellschaftliche Nettonutzen ist, desto größer ist die Wahrscheinlichkeit, dass eine allgemeine Empfehlung zugunsten dieser Intervention ausgesprochen wird.",
          "options": [
            {
              "text": "Bevorzugt die Kontrollintervention",
              "value": "favors_the_comparison"
            },
            {
              "text": "Bevorzugt wahrscheinlich die Kontrollintervention",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Bevorzugt weder die Intervention noch die Kontrollintervention",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Bevorzugt wahrscheinlich die Intervention",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "Bevorzugt die Intervention",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "socioCulturalAcceptability": {
          "additionalOptions": [
            {
              "text": "Variiert ",
              "value": "varies"
            },
            {
              "text": "Nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Ist die Intervention für die wichtigsten Interessenvertreter annehmbar?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Variiert ",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Groß",
                    "value": "large"
                  },
                  {
                    "text": "Moderat",
                    "value": "moderate"
                  },
                  {
                    "text": "Klein",
                    "value": "small"
                  },
                  {
                    "text": "Unbedeutend",
                    "value": "trivial"
                  }
                ],
                "question": "Wie groß ist der Eingriff in die Freiheitsrechte des Einzelnen (einschließlich Privatsphäre und Würde)? (Der Grad des Eingriffs reicht von geringfügig - z. B. durch Ermöglichung von Wahlmöglichkeiten (z. B. Bau von Radwegen) - bis hoch - z. B. durch Einschränkung oder Beseitigung von Wahlmöglichkeiten (z. B. Verbot von Zigaretten)).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Variiert ",
                    "value": "varies"
                  },
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Groß",
                    "value": "large"
                  },
                  {
                    "text": "Moderat",
                    "value": "moderate"
                  },
                  {
                    "text": "Klein",
                    "value": "small"
                  },
                  {
                    "text": "Unbedeutend",
                    "value": "trivial"
                  }
                ],
                "question": "Wie erheblich sind die Auswirkungen der Intervention auf die Autonomie von Einzelpersonen, Bevölkerungsgruppen und/oder Organisationen (in Bezug auf ihre Fähigkeit, eine kompetente, informierte und freiwillige Entscheidung zu treffen)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Kontrollintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Kontrollintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Kontrollintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Spricht die soziokulturelle Akzeptanz der Intervention bei den vorgesehenen Begünstigten für die Intervention oder den Vergleich?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Kontrollintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Kontrollintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Kontrollintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Spricht die soziokulturelle Akzeptanz der Intervention bei denjenigen, die die Intervention durchführen sollen, für die Intervention oder für den Vergleich?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Kontrollintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Vergleichsintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Kontrollintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Spricht die soziokulturelle Akzeptanz der Intervention bei anderen relevanten Interessengruppen für die Intervention oder den Vergleich?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Bevorzugt die Kontrollintervention",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Vergleichsintervention",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Bevorzugt weder die Intervention noch die Kontrollintervention",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Bevorzugt wahrscheinlich die Intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Bevorzugt die Intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Spricht die soziokulturelle Akzeptanz der Intervention in der Bevölkerung für die Intervention oder den Vergleich?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je größer die soziokulturelle Akzeptanz einer Intervention bei allen oder den meisten relevanten Akteuren ist, desto größer ist die Wahrscheinlichkeit, dass eine allgemeine Empfehlung für diese Intervention ausgesprochen wird.",
          "options": [
            {
              "text": "Nein",
              "value": "no"
            },
            {
              "text": "Wahrscheinlich nicht",
              "value": "probably_no"
            },
            {
              "text": "Unsicher",
              "value": "uncertain"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Ja",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": "Variiert ",
              "value": "varies"
            },
            {
              "text": "Nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie hoch ist die Testgenauigkeit?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Sehr ungenau",
              "value": "very_inaccurate"
            },
            {
              "text": "Ungenau",
              "value": "inaccurate"
            },
            {
              "text": "Genau",
              "value": "accurate"
            },
            {
              "text": "Sehr genau",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": "variiert ",
              "value": "varies"
            },
            {
              "text": "nicht bekannt",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Wie erheblich sind die unerwünschten erwarteten Wirkungen?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "variiert ",
                    "value": "varies"
                  },
                  {
                    "text": "nicht bekannt",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Groß",
                    "value": "large"
                  },
                  {
                    "text": "Moderat",
                    "value": "moderate"
                  },
                  {
                    "text": "klein",
                    "value": "small"
                  },
                  {
                    "text": "Unbedeutend",
                    "value": "trivial"
                  }
                ],
                "question": "Wie groß ist der erwartete Effekt (Unterschied) für jedes Hauptergebnis, bei dem eine unerwünschte Wirkung auftritt?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Wie groß sind die unerwünschten Auswirkungen der Intervention, wenn man die Bedeutung der Ergebnisse (wie hoch sie eingeschätzt werden) und die Größe des Effekts (die Wahrscheinlichkeit, einen Nutzen zu erfahren oder wie groß die Verbesserung ist, die der Einzelne wahrscheinlich erfahren wird) berücksichtigt?",
          "options": [
            {
              "text": "Groß",
              "value": "large"
            },
            {
              "text": "Moderat",
              "value": "moderate"
            },
            {
              "text": "Klein",
              "value": "small"
            },
            {
              "text": "Unbedeutend",
              "value": "trivial"
            }
          ],
          "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
          "ratingOptions": [
            "least_harmful",
            "intermediate",
            "more_harmful"
          ],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Besteht eine große Unsicherheit oder Variabilität in Bezug auf den Wert der Hauptergebnisse?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "Wichtige Unsicherheit",
                    "value": "important"
                  },
                  {
                    "text": "Möglicherweise wichtige Unsicherheit",
                    "value": "probably_important"
                  },
                  {
                    "text": "Wahrscheinlich keine wichtige Unsicherheit",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Keine wichtige Unsicherheit",
                    "value": "no_important"
                  }
                ],
                "question": "Gibt es relevante Unsicherheit bezüglich der Bewertung der wesentlichen Endpunkte?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Welchen Stellenwert hat jedes der Hauptergebnisse für den Einzelnen? Ist die Ungewissheit darüber, wie viel Einzelne die Ergebnisse wertschätzen, oder die Variabilität in der Wertschätzung der Ergebnisse durch verschiedene Personen groß genug, um zu unterschiedlichen Entscheidungen zu führen?",
          "options": [
            {
              "text": "Wichtige Unsicherheit oder Variabilität",
              "value": "important_uncertainty"
            },
            {
              "text": "Möglicherweise wichtige Unsicherheit oder Variabilität",
              "value": "possible_important"
            },
            {
              "text": "Wahrscheinlich keine wichtige Unsicherheit oder Variabilität",
              "value": "probably_no_important"
            },
            {
              "text": "Keine wichtige Unsicherheit oder Variabilität",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "Annehmbarkeit",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "Nutzerspezifisches Kriterium",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "Effektintervall",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
          "criterionId": "balanceOfHealthBenefitsAndHarms",
          "name": "Ausgewogenheit von gesundheitlichen Nutzen und Schäden",
          "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "Vertrauenswürdigkeit in die Effekte",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "Vertrauenswürdigkeit der Evidenz",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "Vertrauenswürdigkeit der Evidenz hinsichtlich der Wirksamkeit des Managements",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "Vertrauenswürdigkeit der Evidenz anhand der erforderlichen Quellen",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "Vertrauenswürdigkeit der Evidenz für die Testgenauigkeit",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "Vertrauenswürdigkeit der Evidenz in Bezug auf die Wirksamkeit der Tests",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "Vertrauenswürdigkeit der Evidenz in das Testergebnis/Management",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "Kostenwirksamkeit",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "Erwünschte Wirkungen",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "Gerechtigkeit",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "Durchführbarkeit",
          "researchEvidenceId": "feasibility"
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
          "criterionId": "feasibilityAndHealthSystemConsiderations",
          "name": "Durchführbarkeit und gesundheitspolitische Faktoren",
          "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
        },
        "feasible": {
          "additionalConsiderationId": "feasible",
          "criterionId": "feasible",
          "name": "FEASIBLE",
          "researchEvidenceId": "feasible"
        },
        "financialAndEconomicConsiderations": {
          "additionalConsiderationId": "financialAndEconomicConsiderations",
          "criterionId": "financialAndEconomicConsiderations",
          "name": "Finanzielle und wirtschaftliche Überlegungen",
          "researchEvidenceId": "financialAndEconomicConsiderations"
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
          "criterionId": "healthEquityEqualityAndNonDiscrimination",
          "name": "Gesundheitliche Gerechtigkeit, Gleichheit und Anti-Diskriminierung",
          "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
        },
        "humanRights": {
          "additionalConsiderationId": "humanRights",
          "criterionId": "humanRights",
          "name": "Menschenrechte",
          "researchEvidenceId": "humanRights"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "Problem",
          "researchEvidenceId": "problem"
        },
        "qualityOfEvidence": {
          "additionalConsiderationId": "qualityOfEvidence",
          "criterionId": "qualityOfEvidence",
          "name": "Qualität der Evidenz",
          "researchEvidenceId": "qualityOfEvidence"
        },
        "relevantDirect": {
          "additionalConsiderationId": "relevantDirect",
          "criterionId": "relevantDirect",
          "name": "RELEVANT (DIRECT)",
          "researchEvidenceId": "relevantDirect"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "Erforderliche Ressourcen ",
          "researchEvidenceId": "resourcesRequired"
        },
        "responsive": {
          "additionalConsiderationId": "responsive",
          "criterionId": "responsive",
          "name": "RESPONSIVE",
          "researchEvidenceId": "responsive"
        },
        "scientificallySound": {
          "additionalConsiderationId": "scientificallySound",
          "criterionId": "scientificallySound",
          "name": "SCIENTIFICALLY SOUND (CERTAINTY OF EVIDENCE)",
          "researchEvidenceId": "scientificallySound"
        },
        "societalImplications": {
          "additionalConsiderationId": "societalImplications",
          "criterionId": "societalImplications",
          "name": "gesellschaftliche Auswirkungen",
          "researchEvidenceId": "societalImplications"
        },
        "socioCulturalAcceptability": {
          "additionalConsiderationId": "socioCulturalAcceptability",
          "criterionId": "socioCulturalAcceptability",
          "name": "Sozio-kulturelle Akzeptanz",
          "researchEvidenceId": "socioCulturalAcceptability"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": "Testgenauigkeit",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "Unerwünschte Wirkungen",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "Werte",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "benefits": {
          "content": "",
          "description": "Nutzen"
        },
        "clearAndActionable": {
          "content": "",
          "description": "Eindeutig und umsetzbar",
          "options": [
            {
              "text": "Ja",
              "value": "yes"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Vermutlich nicht",
              "value": "probably_no"
            },
            {
              "text": "Nein",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "clinicalConsiderations": {
          "content": "",
          "description": "Clinical considerations"
        },
        "collectingAndSummarizing": {
          "content": "",
          "description": "Das Sammeln und Zusammenfassen der Evidenz ist eine schlechte Nutzung der begrenzten Zeit und Ressourcen des Leitliniengremiums (hohe Opportunitätskosten)",
          "options": [
            {
              "text": "Ja",
              "value": "yes"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Wahrscheinlich nicht",
              "value": "probably_no"
            },
            {
              "text": "Nein",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "considerations": {
          "content": "",
          "description": "Überlegungen"
        },
        "contextAndSystemConsiderations": {
          "content": "",
          "description": "Kontext und Systemüberlegungen"
        },
        "decision": {
          "content": "",
          "description": "Entscheidung",
          "options": [
            {
              "text": "Volle Implementierung",
              "value": "full_implementation"
            },
            {
              "text": "Bewertung der Auswirkungen",
              "value": "impact_evaluation"
            },
            {
              "text": "Pilotstudie",
              "value": "pilot_study"
            },
            {
              "text": "Verschieben",
              "value": "postpone"
            },
            {
              "text": "Nicht implementieren",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "Art der Entscheidung",
          "options": [
            {
              "direction": "negative",
              "text": "Keine Kostenübernahme ",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "Kostenübernahme unter Berücksichtigung der Weiterentwicklung der Evidenz",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "Kostenübernahme mit Verhandlung des Preises",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "Begrenzte Kostenübernahme",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "Kostenübernahme",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "Art der Entscheidung",
          "options": [
            {
              "direction": "negative",
              "text": "Option nicht implementieren",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "Entscheidung verschieben",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "Pilotstudie zur Option durchführen",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "Option mit einer Wirkungsbewertung implementieren ",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "Option implementieren",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "evidence": {
          "content": "",
          "description": "Evidenz"
        },
        "frequencyDoseDuration": {
          "content": "",
          "description": "Häufigkeit/Dosis/Dauer"
        },
        "goodPracticeStatement": {
          "content": "",
          "description": "Good-Practice-Erklärung "
        },
        "harms": {
          "content": "",
          "description": "Schäden"
        },
        "implementationConsiderations": {
          "content": "",
          "description": "Überlegungen bezüglich der Umsetzung",
          "details": {
            "content": "",
            "description": "Detaillierte Implementierung"
          }
        },
        "implementationConsiderationsOfAllRelevantOutcomes": {
          "content": "",
          "description": "Nach Abwägung aller relevanten Ergebnisse und potenziellen nachgelagerten Folgen führt die Umsetzung der guten Praxis zu großen positiven Nettoauswirkungen",
          "options": [
            {
              "text": "Ja",
              "value": "yes"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Wahrscheinlich nicht",
              "value": "probably_no"
            },
            {
              "text": "Nein",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderationsOrRemarks": {
          "content": "",
          "description": "Überlegungen bezüglich der Umsetzung",
          "options": [
            {
              "text": "Ja",
              "value": "yes"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Wahrscheinlich nicht",
              "value": "probably_no"
            },
            {
              "text": "Nein",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "indications": {
          "content": "",
          "description": "Indikationen"
        },
        "indicationsForDiscontinuation": {
          "content": "",
          "description": "Indikationen für das Absetzen"
        },
        "justification": {
          "content": "",
          "description": "Begründung",
          "details": {
            "description": "Detaillierte Begründung"
          },
          "overallDescription": "Gesamtbegründung"
        },
        "keyMessage": {
          "content": "",
          "description": "Kernaussage"
        },
        "limitations": {
          "content": "",
          "description": "Einschränkungen"
        },
        "mdgRecommendation": {
          "content": "",
          "description": "Empfehlung",
          "options": [
            {
              "direction": "positive",
              "text": "empfohlen",
              "value": "recommend"
            },
            {
              "direction": "negative",
              "text": "Nicht empfohlen",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Sometimes Recommended",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Keine Empfehlung",
              "value": "suggest_either"
            }
          ],
          "selectedOption": ""
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "Monitoring und Evaluation",
          "details": {
            "content": "",
            "description": "Detailliertes Monitoring und Evaluation"
          }
        },
        "monitoringAndEvaluationQI": {
          "content": "",
          "description": "Monitoring und Evaluation"
        },
        "multipleChronicConditionsAndPolypharmacy": {
          "content": "",
          "description": "Multiple chronische Erkrankungen und Polypharmazie"
        },
        "multipleRecommendations": {
          "description": "Empfehlung(en)",
          "options": [
            {
              "direction": "negative",
              "text": "Starke Empfehlung gegen die Intervention",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Bedingte Empfehlung gegen die Intervention",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Bedingte Empfehlung für die Intervention oder die Kontrollintervention",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Bedingte Empfehlung für die Intervention",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Starke Empfehlung für die Intervention",
              "value": "recommend"
            }
          ],
          "recommendations": [
            {
              "content": "",
              "selectedOption": ""
            }
          ]
        },
        "rationale": {
          "content": "",
          "description": "Begründung"
        },
        "rationaleConnectingIndirectEvidence": {
          "content": "",
          "description": "Es gibt eine gut dokumentierte, klare und eindeutige Begründung für die Verbindung der indirekten Beweise",
          "options": [
            {
              "text": "Ja",
              "value": "yes"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Wahrscheinlich nicht",
              "value": "probably_no"
            },
            {
              "text": "Nein",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "recommendation": {
          "content": "",
          "description": "Empfehlung"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "Art der Empfehlung",
          "options": [
            {
              "direction": "negative",
              "text": "Starke Empfehlung gegen die Intervention",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Bedingte Empfehlung gegen die Intervention",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Bedingte Empfehlung für die Intervention oder die Kontrollintervention",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Bedingte Empfehlung für die Intervention",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Starke Empfehlung für die Intervention",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "Art der Empfehlung",
          "options": [
            {
              "direction": "negative",
              "text": "Starke Empfehlung gegen die Option",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Bedingte Empfehlung gegen die Option",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Bedingte Empfehlung für die Option oder die Kontrollintervention",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Bedingte Empfehlung für die Option",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Starke Empfehlung für die Option",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "Forschungsprioritäten"
        },
        "restrictions": {
          "content": "",
          "description": "Einschränkungen"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "Überlegungen zur Subgruppe"
        },
        "theMessageIsNecessary": {
          "content": "",
          "description": "Die Botschaft ist im Hinblick auf die Praxis der Gesundheitsversorgung notwendig",
          "options": [
            {
              "text": "Ja",
              "value": "yes"
            },
            {
              "text": "Wahrscheinlich ja",
              "value": "probably_yes"
            },
            {
              "text": "Wahrscheinlich nicht",
              "value": "probably_no"
            },
            {
              "text": "Nein",
              "value": "no"
            }
          ],
          "selectedOption": ""
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "Klinikpersonal",
          "sections": {
            "background": {
              "name": "Hintergrund"
            },
            "detailedJustification": {
              "name": "Detaillierte Begründung"
            },
            "implementationConsiderations": {
              "name": "Überlegungen bezüglich der Umsetzung"
            },
            "justification": {
              "name": "Begründungen"
            },
            "relatedRecommendations": {
              "name": "verwandte Empfehlungen"
            },
            "subgroupConsiderations": {
              "name": "Überlegungen der Untergruppe"
            },
            "summaryOfFindings": {
              "name": "Zusammenfassung der Ergebnisse (Summary of Findings)"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "Patienten",
          "sections": {
            "relatedRecommendations": {
              "name": "verwandte Empfehlungen"
            },
            "summaryOfFindings": {
              "name": "SoF (Summary of Findings)"
            },
            "whatItMeansForYou": {
              "name": "Was bedeutet das für Sie?",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Sprechen Sie mit Ihrem Arzt oder ihrer Ärztin"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "Wie Sie tun können"
                }
              }
            },
            "whoIsThisFor": {
              "name": "Für wen ist dies?",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Für wen dies gedacht ist"
                }
              }
            },
            "whyThisRecommendation": {
              "name": "Warum diese Empfehlung?",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "Zusätzliche Informationen"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "Nutzen und Schäden"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "Warum diese Empfehlung"
                }
              }
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "Politische Entscheidungsträger",
          "sections": {
            "assessment": {
              "name": "Beurteilung"
            },
            "background": {
              "description": "Details zur Frage für die Empfehlung",
              "details": {
                "name": "Über diese Entscheidung",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "Datum"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "Entscheidungsträger"
                  },
                  "perspective": {
                    "content": "",
                    "description": "Perspektive"
                  },
                  "setting": {
                    "content": "",
                    "description": "Setting"
                  }
                },
                "sectionsOrder": [
                  "setting",
                  "perspective",
                  "decisionMakers",
                  "date"
                ]
              },
              "name": "Hintergrund"
            },
            "decision": {
              "content": "",
              "name": "Entscheidung",
              "options": [
                {
                  "text": "Volle Implementierung",
                  "value": "full_implementation"
                },
                {
                  "text": "Bewertung der Auswirkungen",
                  "value": "impact_evaluation"
                },
                {
                  "text": "Pilotstudie",
                  "value": "pilot_study"
                },
                {
                  "text": "Verschieben",
                  "value": "postpone"
                },
                {
                  "text": "Nicht implementieren",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "Implementierung"
            },
            "justification": {
              "name": "Begründungen"
            },
            "monitoringAndEvaluation": {
              "name": "Monitoring und Evaluation"
            },
            "relatedRecommendations": {
              "name": "verwandte Empfehlungen"
            },
            "summaryOfFindings": {
              "name": "Zusammenfassung der Ergebnisse"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "age": {
          "content": "",
          "name": "Alter"
        },
        "anticipatedOutcomes": {
          "content": "",
          "name": "Erwartete Ergebnisse"
        },
        "background": {
          "content": "",
          "name": "Hintergrund"
        },
        "coi": {
          "content": "",
          "name": "Interessenkonflikte"
        },
        "comparison": {
          "content": "",
          "name": "Vergleich"
        },
        "intent": {
          "content": "",
          "name": "Absicht"
        },
        "intervention": {
          "content": "",
          "name": "Intervention"
        },
        "linkedTreatments": {
          "content": "",
          "name": "Verknüpfte Behandlungen"
        },
        "mainOutcomes": {
          "content": "",
          "name": "Hauptergebnisse"
        },
        "option": {
          "content": "",
          "name": "Option"
        },
        "perspective": {
          "content": "",
          "name": "Perspektive"
        },
        "population": {
          "content": "",
          "name": "Population"
        },
        "problem": {
          "content": "",
          "name": "Problem"
        },
        "purpose": {
          "content": "",
          "name": "Ziel des Tests"
        },
        "requirements": {
          "content": "",
          "name": "Requirements"
        },
        "role": {
          "content": "",
          "name": "Rolle des Tests"
        },
        "setting": {
          "content": "",
          "name": "Setting"
        },
        "subgroups": {
          "content": "",
          "name": "Untergruppen"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
