var Entity;

module.exports = Entity = (function() {
  function Entity(attrs) {
    if (attrs == null) {
      attrs = {};
    }
    _.extend(this, _.defaults(attrs, this.defaults));
  }

  return Entity;

})();
