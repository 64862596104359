var ChecklistView, ModuleController, TasksController, TasksView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

TasksView = require('views/tasks/tasks_view');

ChecklistView = require('views/tasks/checklist_view');

mediator = require('mediator');

module.exports = TasksController = (function(_super) {
  __extends(TasksController, _super);

  function TasksController() {
    return TasksController.__super__.constructor.apply(this, arguments);
  }

  TasksController.prototype.defaultSubmodule = 'tasks';

  TasksController.prototype.viewsForSubmodules = {
    'tasks': TasksView,
    'checklist': ChecklistView
  };

  TasksController.prototype._viewParams = function(params) {
    if (params.submodule === 'tasks') {
      return {
        collection: mediator.events
      };
    } else {
      return TasksController.__super__._viewParams.apply(this, arguments);
    }
  };

  return TasksController;

})(ModuleController);
