CustomRenderMixin = require 'components/mixins/custom_render_mixin'

EtdHeaderLabelCell = createReactClass
  displayName: 'EtdHeaderLabelCell'
  mixins: [CustomRenderMixin]

  render: ->
    text = if @props.text then "#{@props.text}:" else ''
    classes = classNames 'label-cell', {'second-col': @props.secondCol}

    <td className={classes} rowSpan={@props.rowSpan}>
      <p className="section-name">
        {text}
      </p>
    </td>

module.exports = EtdHeaderLabelCell
