Translation = require 'components/mixins/translation'
Select = require 'components/common/select_custom'
Modal = require 'components/common/modal'
IconButton = require 'components/common/icon_button'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConfirmationModal = require 'components/common/confirmation_modal'
ChapterIconImage = require 'components/decision_aid/chapter_icon_image'
ValidatedInput = require 'components/common/validated_input'
ModalVisibility = require 'components/mixins/modal_visibility'
DecisionAidsStore = require 'stores/decision_aids_store'
{ CHAPTER_ICONS } = require 'lib/da_helper'


CHAPTER_ICON_OPTIONS = CHAPTER_ICONS.map (iconName) ->
  value: iconName
  text: iconName

ChapterIconOption = (props) -> <ChapterIconImage iconName={props.value} />
failedValidation = (errorText) -> -> [ false, errorText ]

ChapterEditModal = createReactClass
  displayName: 'ChapterEditModal'

  propTypes:
    daId: PropTypes.string.isRequired
    illustration: PropTypes.string
    isOpen: PropTypes.bool.isRequired
    isNew: PropTypes.bool
    onApply: PropTypes.func.isRequired
    onClose: PropTypes.func.isRequired
    onDelete: PropTypes.func
    title: PropTypes.string

  mixins: [
    Translation('da:details-screen')
    ModalVisibility # provides @showModal, @hideModal, @isModalVisible methods
  ]

  getDefaultProps: ->
    title: ''
    illustration: CHAPTER_ICONS[0]
    isNew: false

  getInitialState: ->
    title: @props.title
    chapterIcon: @props.illustration

  validateChapterName: (name) ->
    titleDuplicationChecker = DecisionAidsStore.chapterExists.bind DecisionAidsStore, @props.daId

    R.cond([
      [ R.isEmpty, failedValidation @i18n 'empty_chapter_name_validation_error' ],
      [
        R.compose(titleDuplicationChecker, R.trim),
        failedValidation @i18n 'duplicate_chapter_name_validation_error'
      ]
      [ R.T, R.always([ true, null ])]
    ]) name

  handleTitleChange: (evt) ->
    @setState title: evt.target.value

  handleConfirmDelete: ->
    @hideModal 'ConfirmationModal'
    @props.onDelete()

  handleChapterIconChange: (newIconName) ->
    @setState chapterIcon: newIconName

  handleApply: ->
    @props.onApply
      title: @state.title
      illustration: @state.chapterIcon

  componentDidUpdate: (prevProps, prevState) ->
    if @props.isOpen and not prevProps.isOpen
      @setState @getInitialState()

  render: ->
    { isNew, isOpen, onClose, onDelete } = @props
    canSave = R.head @validateChapterName @state.title

    <Modal
      className='decision-aid-title-edit-modal'
      isOpen={isOpen}
      modalSize='standard'
      onClose={onClose}
      onRequestClose={onClose}
      title={@i18n if isNew then 'new_chapter' else 'chapter_name'}
    >
      {if isNew
        <div className='new-chapter-caption'>
          {@i18n 'new_chapter_caption'}
        </div>
      }
      <div className='title-edit-inputs'>
        <Select
          options={CHAPTER_ICON_OPTIONS}
          selected={@state.chapterIcon}
          onChange={@handleChapterIconChange}
          optionLabelComponent={ChapterIconOption}
          selectValueComponent={ChapterIconOption}
          listClassName='chapter-icon-options'
        />
        <ValidatedInput
          autoFocus
          onChange={@handleTitleChange}
          onRequestSave={@handleApply if canSave}
          type='text'
          validation={@validateChapterName}
          value={@state.title}
        />
      </div>
      <div className='delete-control'>
        {unless isNew or not onDelete?
          <IconButton
            label={@i18n 'delete_chapter'}
            labelPosition='right'
            iconName='delete'
            onClick={=> @showModal 'ConfirmationModal'}
          />
        }
      </div>
      <ApplyCancelButtons
        onCancel={onClose}
        onApply={@handleApply}
        applyLabel={@i18n '/actions.save'}
        isSubmitEnabled={canSave}
      />
      <ConfirmationModal
        isOpen={@isModalVisible 'ConfirmationModal'}
        question={@i18n 'delete_chapter_confirmation'}
        cancelLabel={@i18n '/actions.no'}
        onConfirm={@handleConfirmDelete}
        onCancel={=> @hideModal 'ConfirmationModal'}
      />
    </Modal>

module.exports = ChapterEditModal
