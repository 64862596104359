var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <tr>\n      <td class=\"left-column no-left-border no-bottom-border\">\n        <div class=\"cell-label\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "scope:questions.members_comments", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n      </td>\n      <td class=\"zero-padding no-right-border no-bottom-border\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.memberComments : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </td>\n    </tr>\n";
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          "
    + escapeExpression(((helpers.teamMemberComment || (depth0 && depth0.teamMemberComment) || helperMissing).call(depth0, depth0, {"name":"teamMemberComment","hash":{
    'key': ((data && data.key))
  },"data":data})))
    + "\n";
},"4":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "    <tr>\n      <td class=\"left-column no-left-border no-bottom-border\">\n        <div class=\"cell-label\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "scope:questions.my_comment", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n      </td>\n      <td class=\"no-right-border no-bottom-border\">\n        <div class=\"comment\">\n          "
    + escapeExpression(((helper = (helper = helpers.adminComment || (depth0 != null ? depth0.adminComment : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"adminComment","hash":{},"data":data}) : helper)))
    + "\n        </div>\n      </td>\n    </tr>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<table border=\"0\" class=\"nested\" cellpadding=\"0\" >\n  <tbody>\n    <tr>\n      <td colspan=\"2\" class=\"centered cell-label phase no-right-border no-left-border\">\n        "
    + escapeExpression(((helper = (helper = helpers.phase || (depth0 != null ? depth0.phase : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"phase","hash":{},"data":data}) : helper)))
    + "\n      </td>\n    </tr>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.memberComments : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.adminComment : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </tbody>\n</table>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}