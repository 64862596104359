Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'

{ func, string } = PropTypes

ProjectLicenceSelect = createReactClass
  displayName: 'ProjectLicenceSelect'
  mixins: [Translation('projects:new_project_dialog')]

  propTypes:
    onLicenseOptionSelect: func.isRequired,
    selectedLicenseType: string,

  getDefaultProps: ->
    selectedLicenseType: null

  getLicenseOptions: ->
    [ 'academic', 'commercial' ].map (optionName) =>
      value: optionName
      text: @i18n "../#{optionName}"
      label: @i18n "../welcome.#{optionName}"
      annotation: @i18n "../welcome.start_new_section.#{optionName}_annotation"

  render: ->
    <label>
      <span>{@i18n 'license'}</span>
      <div className='input-el'>
        <Select
          commonAnnotation={@i18n '../welcome.start_new_section.license_type_annotation'}
          options={@getLicenseOptions()}
          onChange={@props.onLicenseOptionSelect}
          selected={@props.selectedLicenseType}
        />
      </div>
    </label>

module.exports = ProjectLicenceSelect
