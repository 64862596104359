CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ExportDialog = require 'components/common/export_dialog'
HtmlExport = require 'lib/html_export'
mediator = require 'mediator'
Modal = require 'components/common/modal'
PanelVoiceEtdStore = require 'stores/panel_voice_etd_store'
PanelVoiceStore = require 'stores/panel_voice_store'
printoutOptions = require 'lib/printouts_map'
printoutTemplate = require 'views/templates/printouts/recommendations_printout_ietd'
Switcher = require 'components/common/switcher'
Translation = require 'components/mixins/translation'
{ exportDataOptions, getExportOptions, getAppendicesList } =
  require 'stores/utils/panel_voice_helpers'
{ exportToFile } = require 'lib/export_to_file'

ExportOption = ({
  onToggleSection
  onToggleSwitcher
  i18n
  optionKey
  option
  }) ->
  labelClasses = classNames 'flex flex-row items-center',
    'disabled': option.disabled
    'selected': option.checked
  <div className="export-section-options">
    <label
      className={labelClasses}
      htmlFor={optionKey}
      onClick={onToggleSection}
    >
      <input
        id={optionKey}
        type="radio"
        value={optionKey}
        checked={option.checked}
      />
      <span className="title">{i18n "voting:panel_voice.labels.#{optionKey}"}</span>
      <span className="judgment">
        <span className="judgment-toggle-with-text">
          <Switcher
            disabled={option.judgment.disabled}
            checked={option.judgment.checked}
            onChange={onToggleSwitcher('judgment')}
          />
        </span>
      </span>

      <span className="results #{if option.results.disabled then 'disabled' else ''}">
        <span className="results-toggle-with-text">
          <Switcher
            disabled={option.results.disabled}
            checked={option.results.checked}
            onChange={onToggleSwitcher('results')}
          />
        </span>
      </span>
    </label>
  </div>

ExportModal = createReactClass

  displayName: "ExportModal"

  mixins: [Translation(''), CustomRenderMixin]

  onDownload: ({outputFormat}) ->
    { currentQuestion, onClose } = @props
    etdVersion = 'v2'
    renderOptions = @state.exportOptions
    fileName = currentQuestion.get('question')
    printoutType = 'recommendations-v2'
    printoutHTML = printoutTemplate {
      orientation: @state.orientation, margins: {}
    }

    { spliceTag } = printoutOptions[printoutType]
    new HtmlExport({}, printoutType, {
      etdVersion
      renderOptions
      votingStarted: @isVotingStarted()
      type: 'overarching'
    }).exportRecommendations document, currentQuestion.get('_id')
    .then (printoutEl) ->
      $(printoutEl).find('td[style="display: none;"],th[style="display: none;"]').remove()
      spliceIdx = printoutHTML.indexOf(spliceTag) + spliceTag.length
      [printoutHTML.slice(0, spliceIdx),
        printoutEl.innerHTML,
        printoutHTML.slice(spliceIdx) ].join('')
    .then (html) ->
      exportToFile outputFormat, html, { fileName }
    .catch mediator.dialogs.error
    .ensure onClose

  getInitialState: ->
    exportOptions: R.assocPath ['recommendation', 'disabled'], true,
      getExportOptions @props.currentQuestion.get('_id')
    orientation: 'landscape'

  toggleSection: (sectionKey) -> (e) =>
    e.preventDefault()
    { exportOptions } = @state
    return if R.path [sectionKey, 'disabled'], exportOptions
    isChecked = R.path [sectionKey, 'checked'], exportOptions
    newExportOptions = R.assocPath [sectionKey, 'checked'], not isChecked, exportOptions
    newSectionValue = if not isChecked then true else false
    newExportOptions = R.assocPath [sectionKey, 'judgment', 'checked'], newSectionValue,
      newExportOptions
    if newSectionValue is false
      newExportOptions = R.assocPath [sectionKey, 'results', 'checked'], newSectionValue,
        newExportOptions
    @setState exportOptions: newExportOptions

  toggleSwitcher: (sectionKey) -> (optionKey) => (e) =>
    e.preventDefault()
    e.stopPropagation()
    { exportOptions } = @state
    return if R.not R.path [sectionKey, 'checked'], exportOptions
    return if R.path [sectionKey, optionKey, 'disabled'], exportOptions
    newExportOptions = R.assocPath [sectionKey, optionKey, 'checked'],
      not R.path([sectionKey, optionKey, 'checked'], exportOptions), exportOptions
    @setState exportOptions: newExportOptions

  isVotingStarted: ->
    { currentQuestion } = @props
    votingStages = PanelVoiceStore.getQuestionVotingStages currentQuestion.get('_id')
    not votingStages.isEmpty()

  setOrientation: (orientation) -> (e) =>
    e.preventDefault()
    @setState orientation: orientation

  render: ->
    outputFormats = ['html', 'doc', 'pdf']
    { onClose, currentQuestion } = @props

    isVotingStarted = @isVotingStarted()
    # TODO fix exporting appendices T3958
    appendices = getAppendicesList(currentQuestion.get('_id'))

    <Modal
      isOpen
      className="overarching-export-modal"
      closeButton={false}
      title={@i18n 'toolbar.export_etd'}
    >
      <div id="panel-voice-export-dialog" className='panel-voice-export-dialog--react'>
        <div className="choose-sections">
          <div className="choose-etd-view-title">
            <span className="title">{@i18n "projects:export_dialog.choose_etd_view"}</span>
            <span className="judgment">{@i18n "projects:export_dialog.with_judgment"}</span>
            <span className="results #{if isVotingStarted then '' else 'disabled'}">
              {@i18n "projects:export_dialog.with_results"}
            </span>
          </div>
          {_.map @state.exportOptions, (option, key) =>
            [
              key is 'conclusion' and
                <div className="choose-etd-view-title">
                  <span className="title" />
                  <span className="judgment">{@i18n "projects:export_dialog.with_content"}</span>
                  <span className="results #{if isVotingStarted then '' else 'disabled'}">
                    {@i18n "projects:export_dialog.with_results"}
                  </span>
                </div>
              <ExportOption
                key={key}
                optionKey={key}
                onToggleSection={@toggleSection(key)}
                onToggleSwitcher={@toggleSwitcher(key)}
                option={option}
                i18n={@i18n}
              />
            ]
          }
        </div>
        <div className="choose-orientation mt-20">
          <label className="choose-orientation-title">
            {@i18n "projects:export_dialog.choose_page_orientation"}
          </label>
          <div className="orientations-list">
            <label className="flex flex-row items-center" onClick={@setOrientation('landscape')}>
              <input
                type="radio"
                name="orientation"
                value="landscape"
                checked={@state.orientation is 'landscape'}
                onChange={@setOrientation('landscape')}
              />
              {@i18n "projects:export_dialog.orientations.landscape"}
              <span className="icon-orientation-landscape"></span>
            </label>
            <label className="flex flex-row items-center" onClick={@setOrientation('portrait')}>
              <input
                type="radio"
                name="orientation"
                value="portrait"
                checked={@state.orientation is 'portrait'}
                onChange={@setOrientation('portrait')}
              />
              {@i18n "projects:export_dialog.orientations.portrait"}
              <span className="icon-orientation-portrait"></span>
            </label>
          </div>
        </div>
        {not _.isEmpty(appendices) and <div>
          <label className="choose-appendices-title">
            {@i18n "projects:export_dialog.export_as_appendix"}
          </label>
          <div className="appendices-to-export">
            <ul>
              {_.map(appendices, (appendix, appendixId) =>
                <li>
                  <input name="exportAppendix" type="checkbox" id={appendixId} />
                  <label for="{appendixId}">
                    {if appendix.isImage
                      <span>{appendix.name} <b>({appendix.section})</b></span>
                    else
                      <span>{@i18n appendixId}</span>
                    }
                  </label>
                </li>
              )}
            </ul>
          </div>
        </div>}
        <div>
          <ExportDialog
            outputFormats={outputFormats}
            onClose={onClose}
            onDownload={@onDownload}
          />
        </div>
      </div>
    </Modal>

module.exports = ExportModal
