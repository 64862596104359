var INCLUDED_TAGS_PATH, INITIAL_STATE, KeyMessagesActions, generateStore, includedTagAdder, stripHTML, _ref,
  __slice = [].slice;

generateStore = require('stores/utils/generate_store');

_ref = require('lib/question_tags_helper'), includedTagAdder = _ref.includedTagAdder, stripHTML = _ref.stripHTML;

KeyMessagesActions = require('actions/key_messages_actions');

INCLUDED_TAGS_PATH = ['currentQuestion', 'includedTags'];

INITIAL_STATE = Immutable.fromJS({
  currentQuestion: Immutable.Map(),
  meta: {
    isFetching: false,
    fetchingError: null
  }
});

module.exports = generateStore({
  name: 'KeyMessagesStore',
  initialState: INITIAL_STATE,
  boundActions: [KeyMessagesActions],
  methods: {
    onFetchOne: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'isFetching'], true);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchOneSuccess: function(question) {
      var includedTags, questionToSet, _ref1;
      includedTags = Immutable.fromJS((_ref1 = question.includedTags) != null ? _ref1 : []).toList();
      questionToSet = Immutable.fromJS(_.omit(question, 'includedTags')).set('includedTags', includedTags);
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'isFetching'], false);
        return state.set('currentQuestion', questionToSet);
      }));
    },
    onFetchOneError: function(err) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'isFetching'], false);
        return state.setIn(['meta', 'fetchingError'], err);
      }));
    },
    onUpdateCurrentQuestion: function(_arg) {
      var field, value;
      field = _arg.field, value = _arg.value;
      return this.setState(this.state.setIn(['currentQuestion', field], value));
    },
    onDestroyCurrentQuestion: function() {
      return this.setState(this.state.set('currentQuestion', Immutable.Map()));
    },
    onAddTag: function(tagKey) {
      return this.setState(this.state.updateIn(INCLUDED_TAGS_PATH, includedTagAdder(null, tagKey)));
    },
    onRemoveTag: function(tag) {
      return this.setState(this.state.updateIn(INCLUDED_TAGS_PATH, function(tags) {
        return tags.filterNot(function(t) {
          return t.get('id') === tag.get('id');
        });
      }));
    },
    onUpdateTag: function(tag) {
      var tagIndex;
      tagIndex = this.state.getIn(__slice.call(INCLUDED_TAGS_PATH), Immutable.List()).findIndex(function(t) {
        return t.get('id') === tag.get('id');
      });
      return this.setState(this.state.setIn(__slice.call(INCLUDED_TAGS_PATH).concat([tagIndex]), tag));
    },
    onAddCode: function(_arg) {
      var code, codesPath, newCode, tagId;
      tagId = _arg.parentId, code = _arg.code;
      codesPath = __slice.call(INCLUDED_TAGS_PATH).concat([tagId], ['codes']);
      newCode = Immutable.fromJS({
        id: code.get('@id'),
        codeType: code.get('@type'),
        value: stripHTML(code.get('value')),
        name: stripHTML(code.get('name'))
      });
      return this.setState(this.state.updateIn(codesPath, function(codes) {
        return codes.set(code.get('@id'), newCode);
      }));
    },
    onRemoveCode: function(_arg) {
      var code, codesPath, tagId;
      tagId = _arg.parentId, code = _arg.code;
      codesPath = __slice.call(INCLUDED_TAGS_PATH).concat([tagId], ['codes']);
      return this.setState(this.state.updateIn(codesPath, function(codes) {
        return codes["delete"](code.get('id'));
      }));
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'isFetching']);
    },
    getCurrentQuestion: function() {
      return this.state.get('currentQuestion', Immutable.Map());
    }
  }
});
