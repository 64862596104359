var DUPLICATE_DIALOG_STATE, INITIAL_STATE, MDGTableActions, PARENT_STUDY_DIALOG_STATE, addIdFn, filterOutFn, generateStore;

generateStore = require('stores/utils/generate_store');

MDGTableActions = require('actions/mdg_table_actions');

addIdFn = function(idToAdd) {
  return function(rows) {
    return rows.push(idToAdd);
  };
};

filterOutFn = function(idToFilterOut) {
  return function(rows) {
    return rows.filter(function(id) {
      return id !== idToFilterOut;
    });
  };
};

DUPLICATE_DIALOG_STATE = Immutable.fromJS({
  studyId: null,
  isOpen: false,
  duplicating: false
});

PARENT_STUDY_DIALOG_STATE = Immutable.fromJS({
  currentStudyId: null,
  applying: false,
  selectedStudyId: null
});

INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false,
    fetchingError: null,
    exportDialogOpen: false,
    duplicateDialogState: DUPLICATE_DIALOG_STATE,
    parentStudyDialogState: PARENT_STUDY_DIALOG_STATE
  },
  currentQuestion: Immutable.Map(),
  editRows: Immutable.List(),
  expandedRows: Immutable.List()
});

module.exports = generateStore({
  name: 'MDGTableStore',
  initialState: INITIAL_STATE,
  boundActions: [MDGTableActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.set('currentQuestion', Immutable.Map());
        state.setIn(['meta', 'isFetching'], true);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchSuccess: function(question) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('currentQuestion', Immutable.fromJS(question));
        state.setIn(['meta', 'isFetching'], false);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchError: function(err) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('currentQuestion', Immutable.Map());
        state.setIn(['meta', 'isFetching'], false);
        return state.setIn(['meta', 'fetchingError'], err);
      }));
    },
    onAddRow: function(studyId) {
      return this.setState(this.state.withMutations(function(state) {
        state.update('editRows', addIdFn(studyId));
        return state.update('expandedRows', addIdFn(studyId));
      }));
    },
    onCollapseRow: function(studyId) {
      return this.setState(this.state.withMutations(function(state) {
        state.update('editRows', filterOutFn(studyId));
        return state.update('expandedRows', filterOutFn(studyId));
      }));
    },
    onDuplicateRow: function(studyId) {
      return this.setState(this.state.withMutations(function(state) {
        state.update('editRows', addIdFn(studyId));
        return state.update('expandedRows', addIdFn(studyId));
      }));
    },
    onDeleteRow: function(studyId) {
      return this.setState(this.state.withMutations(function(state) {
        state.update('editRows', filterOutFn(studyId));
        return state.update('expandedRows', filterOutFn(studyId));
      }));
    },
    onEditRow: function(studyId) {
      return this.setState(this.state.update('editRows', addIdFn(studyId)));
    },
    onExpandRow: function(studyId) {
      return this.setState(this.state.update('expandedRows', addIdFn(studyId)));
    },
    onSaveRow: function(studyId) {
      return this.setState(this.state.update('editRows', filterOutFn(studyId)));
    },
    onDbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      return this.setState(this.state.set('currentQuestion', Immutable.fromJS(doc)));
    },
    onOpenExportDialog: function() {
      return this.setState(this.state.setIn(['meta', 'exportDialogOpen'], true));
    },
    onCloseExportDialog: function() {
      return this.setState(this.state.setIn(['meta', 'exportDialogOpen'], false));
    },
    onOpenDuplicateStudyDialog: function(_arg) {
      var studyId;
      studyId = _arg.studyId;
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['meta', 'duplicateDialogState', 'isOpen'], true);
        return s.setIn(['meta', 'duplicateDialogState', 'studyId'], studyId);
      }));
    },
    onCloseDuplicateStudyDialog: function() {
      return this.setState(this.state.setIn(['meta', 'duplicateDialogState'], DUPLICATE_DIALOG_STATE));
    },
    onDuplicateStudyToAnotherQuestion: function() {
      return this.setState(this.state.setIn(['meta', 'duplicateDialogState', 'duplicating'], true));
    },
    onStudyDuplicationFinished: function() {
      return this.setState(this.state.setIn(['meta', 'duplicateDialogState'], DUPLICATE_DIALOG_STATE));
    },
    onOpenParentStudyDialog: function(studyId) {
      return this.setState(this.state.setIn(['meta', 'parentStudyDialogState', 'currentStudyId'], studyId));
    },
    onCloseParentStudyDialog: function() {
      return this.setState(this.state.setIn(['meta', 'parentStudyDialogState'], PARENT_STUDY_DIALOG_STATE));
    },
    onSelectParentStudy: function(studyId) {
      return this.setState(this.state.setIn(['meta', 'parentStudyDialogState', 'selectedStudyId'], studyId));
    },
    onConnectParentStudy: function() {
      return this.setState(this.state.setIn(['meta', 'parentStudyDialogState', 'applying'], true));
    },
    onConnectParentStudyFinished: function() {
      return this.setState(this.state.setIn(['meta', 'parentStudyDialogState'], PARENT_STUDY_DIALOG_STATE));
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'isFetching']);
    },
    getFetchingError: function() {
      return this.state.getIn(['meta', 'fetchingError']);
    },
    getCurrentQuestion: function() {
      return this.state.get('currentQuestion');
    },
    getEditRows: function() {
      return this.state.get('editRows');
    },
    getExpandedRows: function() {
      return this.state.get('expandedRows');
    },
    isExportDialogOpen: function() {
      return this.state.getIn(['meta', 'exportDialogOpen']);
    },
    getDuplicateStudyDialogState: function() {
      return this.state.getIn(['meta', 'duplicateDialogState']);
    },
    getParentDialogCurrentStudyId: function() {
      return this.state.getIn(['meta', 'parentStudyDialogState', 'currentStudyId']);
    },
    getParentDialogIsApplying: function() {
      return this.state.getIn(['meta', 'parentStudyDialogState', 'applying']);
    },
    getParentDialogSelectedStudyId: function() {
      return this.state.getIn(['meta', 'parentStudyDialogState', 'selectedStudyId']);
    }
  }
});
