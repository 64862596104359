{ getKeyValObject } = require('base/lib/utils')

ModalDialogsMixin =
  showModal: (modalName) -> =>
    @setState getKeyValObject modalName, true

  closeModal: (modalName) -> =>
    @setState getKeyValObject modalName, false

module.exports = ModalDialogsMixin
