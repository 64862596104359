var ALGORITHM_PREFIX, DbDoc, algorithmDocShape;

DbDoc = require('lib/db_docs/db_doc');

algorithmDocShape = require('lib/db_docs/doc_shapes/algorithms_doc_shape');

ALGORITHM_PREFIX = require('lib/doc_prefixes').algorithms;

module.exports = DbDoc(algorithmDocShape, {
  dbSetup: {
    idPrefix: ALGORITHM_PREFIX
  }
});
