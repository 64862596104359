var ModalView, NewOrganizationProjectModal, NewProjectView, Project, ReactComponent, StartNewProjectModal, W, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Project = require('models/project');

StartNewProjectModal = require('components/projects/start_new_project_modal');

NewOrganizationProjectModal = require('components/projects/new_organization_project_modal');

ModalView = require('base/views/modal_view');

ReactComponent = require('base/lib/traits/react_component');

mediator = require('mediator');

W = require('when/when');

module.exports = NewProjectView = (function(_super) {
  __extends(NewProjectView, _super);

  function NewProjectView() {
    this.createProject = __bind(this.createProject, this);
    return NewProjectView.__super__.constructor.apply(this, arguments);
  }

  NewProjectView.prototype.id = 'new-project-dialog';

  NewProjectView.prototype.title = $.t('projects:new_project_dialog.title');

  NewProjectView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  NewProjectView.prototype.creating = false;

  NewProjectView.prototype.savingError = null;

  NewProjectView.prototype.initialize = function() {
    this._stopInputsPropagation = false;
    this.options.cls = 'start-new-project-modal';
    this.options.offsetY = 50;
    this.enable(ReactComponent);
    return NewProjectView.__super__.initialize.apply(this, arguments);
  };

  NewProjectView.prototype._getProps = function() {
    var props;
    props = {
      savingError: this.savingError,
      onCancel: this.dispose,
      onStart: this.createProject,
      creating: this.creating
    };
    if (this.options.organizationId) {
      _.extend(props, {
        organizationId: this.options.organizationId
      });
    }
    return props;
  };

  NewProjectView.prototype.afterRender = function() {
    var Component, props;
    NewProjectView.__super__.afterRender.apply(this, arguments);
    Component = this.options.organizationId != null ? NewOrganizationProjectModal : StartNewProjectModal;
    props = this._getProps();
    return this.renderSimpleComponent(Component, props);
  };

  NewProjectView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    return NewProjectView.__super__.dispose.apply(this, arguments);
  };

  NewProjectView.prototype.createProject = function(data, organizationData) {
    var extraData, members, options, organizationId;
    if (organizationData == null) {
      organizationData = null;
    }
    if (this.creating) {
      return;
    }
    extraData = {
      recommendationsMode: 'static'
    };
    if (organizationData != null) {
      extraData.organizationId = this.options.organizationId;
    }
    this.model = new Project(_.extend(data, extraData));
    if (!this.model.isValid(true)) {
      return;
    }
    this.creating = true;
    this.updateRenderedComponent(this._getProps());
    options = {
      success: (function(_this) {
        return function() {
          mediator.publish('!router:route', "/projects/" + _this.model.id, function(routed) {
            if (!routed) {
              throw new Error('Switching to newly created project was unsuccessful');
            }
          });
          return _this.dispose();
        };
      })(this),
      error: (function(_this) {
        return function(err) {
          _this.savingError = $.t('projects:new_project_dialog.error', {
            error: err.message
          });
          _this.creating = false;
          return _this.updateRenderedComponent(_this._getProps());
        };
      })(this)
    };
    if (organizationData != null) {
      members = organizationData.members, organizationId = organizationData.organizationId;
      return mediator.projects.createOrganizationProject(this.model, organizationId, members, options);
    } else {
      return mediator.projects.create(this.model, options);
    }
  };

  return NewProjectView;

})(ModalView);
