{ bool, func } = PropTypes

Input = createReactClass
  displayName: 'Input'

  propTypes:
    focusSupport: bool
    keyboardSupport: bool
    onRequestSave: func
    onRequestCancel: func
    onBlur: func
    inputRef: func

  _ref: (el) ->
    @input = el
    @props.inputRef? el

  getDefaultProps: ->
    keyboardSupport: true
    focusSupport: false

  handleBlur: (evt) ->
    if @props.onBlur
      @props.onBlur(evt)

    return unless @props.focusSupport

    @props.onRequestSave?(evt)

  handleKeyDown: (evt) ->
    return unless @props.keyboardSupport

    switch evt.keyCode
      # enter
      when 13
        return @props.onRequestSave?(evt)
      # escape
      when 27
        return @props.onRequestCancel?(evt)

  render: ->
    props = _.omit @props, 'keyboardSupport', 'onRequestCancel', 'onRequestSave', 'inputRef',
      'focusSupport'
    <input ref={@_ref} {...props} onKeyDown={@handleKeyDown} onBlur={@handleBlur} />

module.exports = Input
