OutcomeTableViewActions = require 'actions/outcomes_table_view_actions'
Popover = require 'components/common/popover'
DefaultCell = require 'components/common/gdt_table/default_cell'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'


CellEditBox = createReactClass
  displayName: 'CellEditBox'

  propTypes:
    title: PropTypes.string.isRequired
    onClose: PropTypes.func.isRequired

  render: ->
    <div className='cell-edit-box'>
      <div className='title-container'>
        <h4 dangerouslySetInnerHTML={__html: @props.title} />
        <button className='close' onClick={@props.onClose}>×</button>
      </div>
      <div className='edit-content'>
        {@props.children}
      </div>
    </div>

EditableCell = createReactClass
  displayName: 'EditableCell'

  propTypes:
    className: PropTypes.string
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    disabled: PropTypes.bool
    id: PropTypes.string
    editMetadata: PropTypes.instanceOf(Immutable.Map)
    style: PropTypes.object
    onEditClose: PropTypes.func
    outcomeAttrs: PropTypes.object

  mixins: [CustomRenderMixin]

  startEdit: ->
    OutcomeTableViewActions.startOutcomeEdit
      outcomeId: @props.outcomeAttrs['_id']
      cellId: @props.id
      sourceId: @props.sourceId
      questionId: @props.questionId

  stopEdit: ->
    OutcomeTableViewActions.stopOutcomeEdit()

  handleKeyDown: (evt) ->
    if evt.keyCode is 13 and not @props.editMetadata?
      @startEdit()

  handlePopoverCloseRequest: ->
    @props.onEditClose?()
    @stopEdit()

  render: ->
    propsFiltered = _.omit @props, 'onEditClose', 'editMetadata'
    return <DefaultCell {...propsFiltered} /> if @props.disabled

    <Popover
      onRequestClose={@handlePopoverCloseRequest}
      positionParams={position: 'cover', alignment: 'center'}
      visible={@props.editMetadata?}
      draggable
    >
      <DefaultCell {...propsFiltered} onClick={@startEdit}/>
      <CellEditBox title={@props.title} onClose={@stopEdit}>
        {React.Children.only @props.children}
      </CellEditBox>
    </Popover>

module.exports = EditableCell
