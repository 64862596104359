PanelMemberCell = require 'components/team/form/panel_member_cell'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'

PanelMemberFormGroup = createReactClass

  displayName: "PanelMemberFormGroup"

  mixins: [Translation('team:member')]

  render: ->
    <div className="form-group panel-member">
      <div className="inline">
        <label className="pull-left">{@i18n 'panel_participation'}
          <Tooltip tooltipType="info" tipJoint="bottom left" targetJoint="top right">
            <span
              className="info-sign"
              title={@i18n "panel_member_description"} />
          </Tooltip>
        </label>
        <PanelMemberCell
          expanded={true}
          withLabel={false}
          el="div"
          className="panel-member"
          member={@props.member}
          customUpdate={@props.onUpdate}
        />
      </div>
    </div>

module.exports = PanelMemberFormGroup
