{ kebabCase } = require 'lodash'
Input = require 'components/common/input'
IconButton = require 'components/common/icon_button'
Translation = require 'components/mixins/translation'

SidebarChapterForm = createReactClass
  displayName: 'SidebarChapterForm'

  mixins: [
    Translation('docsec:management')
  ]

  propTypes:
    data: PropTypes.shape({
      isFromAcoem: PropTypes.bool
      slug: PropTypes.string.isRequired
      title: PropTypes.string.isRequired
    })
    mdaTopicId: PropTypes.string
    onCancel: PropTypes.func
    onSubmit: PropTypes.func.isRequired

  getInitialState: ->
    data: _.extend isFromAcoem: false, @props.data || {
      isFromAcoem: false
      slug: ''
      title: ''
    }

  handleTitleChange: (evt) ->
    nextTitle = evt.target.value
    @setState ({ data }) =>
      nextData = _.assign {}, data, {title: nextTitle}
      if !@props.data
        nextData.slug = kebabCase(nextTitle)
      data: nextData

  handleSlugChange: (evt) ->
    nextSlug = evt.target.value
    @setState ({ data }) ->
      data: _.assign {}, data, {slug: nextSlug}

  handleSubmit: (evt) ->
    evt.preventDefault()
    { title, slug } = @state.data
    if !title || !slug
      return
    @props.onSubmit(@state.data)

  toggleFromAcoem: -> @setState ({ data }) ->
    data: _.extend data, isFromAcoem: not data.isFromAcoem

  render: ->
    { mdaTopicId, onCancel } = @props
    { isFromAcoem, slug, title } = @state.data

    <form className="sidebar-chapter-form" onSubmit={@handleSubmit}>
      <div className="sidebar-chapter-form-field">
        <label className="sidebar-chapter-form-field-label">{@i18n 'chapter_title'}:</label>
        <Input
          autoFocus
          type="text"
          value={title}
          onChange={@handleTitleChange}
          className="sidebar-chapter-form-field-input"
        />
      </div>
      <div className="sidebar-chapter-form-field">
        <label className="sidebar-chapter-form-field-label">{@i18n 'chapter_url'}:</label>
        <Input
          type="text"
          value={slug}
          onChange={@handleSlugChange}
          className="sidebar-chapter-form-field-input"
        />
      </div>
      {mdaTopicId and <div className="sidebar-chapter-form-field">
        <label className="sidebar-chapter-form-field-label">{@i18n 'chapter_from_acoem'}:</label>
        <Input
          checked={isFromAcoem}
          type="checkbox"
          onChange={@toggleFromAcoem}
          className="sidebar-chapter-form-field-input"
        />
      </div>}
      <div className="sidebar-chapter-form-buttons">
        {onCancel && (
          <IconButton
            iconName="clear"
            onClick={onCancel}
            className="sidebar-chapter-form-button"
          />
        )}
        <IconButton
          type="submit"
          iconName="save"
          className="sidebar-chapter-form-button"
          disabled={!title || !slug}
        />
      </div>
    </form>

module.exports = SidebarChapterForm
