var Model, User, W, createOrganizationProjectRequestPrefix, createProjectRequestPrefix, createProjectSnapshotRequestPrefix, mediator, sequence, utils, _ref,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

mediator = require('mediator');

sequence = require('when/sequence');

W = require('when');

utils = require('base/lib/utils');

_ref = require('lib/doc_prefixes'), createProjectRequestPrefix = _ref.createProjectRequest, createOrganizationProjectRequestPrefix = _ref.createOrganizationProjectRequest, createProjectSnapshotRequestPrefix = _ref.createProjectSnapshotRequest;

module.exports = User = (function(_super) {
  __extends(User, _super);

  function User() {
    this._updateStatusAndRemoveProject = __bind(this._updateStatusAndRemoveProject, this);
    this.addProject = __bind(this.addProject, this);
    this.addOrganizationProject = __bind(this.addOrganizationProject, this);
    return User.__super__.constructor.apply(this, arguments);
  }

  User.prototype.defaults = function() {
    return _.defaults({
      forceFootnotes: true,
      showDropQuestionGroupArea: true,
      settings: {
        timezone: 'auto'
      },
      tosAcceptedVersion: null,
      topAccepted: null,
      projects: [],
      narrativeInfoShownForQuestion: {}
    }, mediator.services.esView.getDefaultViewAttrs());
  };

  User.prototype._supportOldFormatOfProjects = function() {
    if (_.isString(this.get('projects')[0])) {
      return this.set('projects', _(this.get('projects')).map(function(project) {
        return {
          name: project,
          status: 'new'
        };
      }));
    }
  };

  User.prototype.initialize = function() {
    User.__super__.initialize.apply(this, arguments);
    this._supportOldFormatOfProjects();
    this.urlRoot = this.id;
    return this.on('change:projects', this._projectsChanged);
  };

  User.prototype._projectsChanged = function(user, newProjects) {
    this._duplicateProjectsIfNeeded();
    return this._replicateToCentralIfNeeded(newProjects, user.previousAttributes().projects);
  };

  User.prototype._replicateToCentralIfNeeded = function(projects, previousProjects) {
    var prevProj, project, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = projects.length; _i < _len; _i++) {
      project = projects[_i];
      prevProj = _(previousProjects).findWhere({
        name: project.name
      });
      if (project.status === 'replication_on' && prevProj && prevProj.status !== 'replication_on') {
        _results.push(mediator.services.replication.sendLocalChanges([project.name]));
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  User.prototype.getLogin = function() {
    return this.get('name');
  };

  User.prototype.getEmail = function() {
    return this.get('email');
  };

  User.prototype._duplicateProject = function(id, markOldOneAsBroken, newName, newOrderNumber) {
    var login;
    if (markOldOneAsBroken == null) {
      markOldOneAsBroken = false;
    }
    if (newName == null) {
      newName = null;
    }
    if (newOrderNumber == null) {
      newOrderNumber = null;
    }
    login = this.get('name');
    return mediator.services.projectDb.duplicate(login, id, newName).then((function(_this) {
      return function(newId) {
        var projects, _ref1;
        projects = (_ref1 = _this.get('projects')) != null ? _ref1 : [];
        if (markOldOneAsBroken) {
          _(projects).findWhere({
            name: id
          }).status = 'broken';
        }
        projects.push(_(_this._newProject(newId)).extend({
          orderNumber: newOrderNumber
        }));
        return _this.save('projects', projects).then(function() {
          return W(_this.getRequests().create({
            _id: "" + createProjectRequestPrefix + "_" + newId,
            type: 'create_project',
            status: 'new',
            projectId: newId,
            login: _this.get('name')
          })).then(function() {
            return newId;
          });
        });
      };
    })(this));
  };

  User.prototype.createMdaWorkflow = function(organizationId, name, docIds) {
    var login;
    login = this.get('name');
    return mediator.services.projectDb.createMdaWorkflow(login, name, docIds).then((function(_this) {
      return function(newProjectId) {
        var projects, _ref1;
        projects = (_ref1 = _this.get('projects')) != null ? _ref1 : [];
        projects.push(_this._newProject(newProjectId));
        return _this.save('projects', projects).then(function() {
          var request;
          request = organizationId != null ? _this._createOrganizationProjectRequest(newProjectId, organizationId, [login]) : _this._createProjectRequest(newProjectId);
          return W(_this.getRequests().create(request));
        }).then(function() {
          return newProjectId;
        });
      };
    })(this));
  };

  User.prototype.createMdaWorkflowSnapshot = function(organizationId, projectId, docIds) {
    var login;
    login = this.get('name');
    return mediator.services.projectDb.createMdaWorkflowSnapshot(login, projectId, docIds).then((function(_this) {
      return function(_arg) {
        var newProjectId, newProjectName, projects, _ref1;
        newProjectId = _arg.newId, newProjectName = _arg.newProjectName;
        projects = (_ref1 = _this.get('projects')) != null ? _ref1 : [];
        projects.push(_.extend(_this._newProject(newProjectId), {
          isCopy: true
        }));
        return _this.save('projects', projects).then(function() {
          return W(_this.getRequests().create({
            _id: "" + createProjectSnapshotRequestPrefix + "_" + newProjectId,
            type: 'create_project_snapshot',
            status: 'new',
            projectId: newProjectId,
            organizationId: organizationId,
            login: login
          })).then(function() {
            return newProjectName;
          });
        });
      };
    })(this));
  };

  User.prototype.createProjectAdolopment = function(projectId, organizationId, selectedQuestions) {
    var login;
    login = this.get('name');
    return mediator.services.projectDb.createAdolopment(login, projectId, selectedQuestions).then((function(_this) {
      return function(newProjectId) {
        var projects, _ref1;
        projects = (_ref1 = _this.get('projects')) != null ? _ref1 : [];
        projects.push(_this._newProject(newProjectId));
        return _this.save('projects', projects).then(function() {
          var request;
          request = organizationId != null ? _this._createOrganizationProjectRequest(newProjectId, organizationId, [login]) : _this._createProjectRequest(newProjectId);
          return W(_this.getRequests().create(request));
        }).then(function() {
          return newProjectId;
        });
      };
    })(this));
  };

  User.prototype.createProjectSnapshot = function(projectId, organizationId) {
    var login;
    login = this.get('name');
    return mediator.services.projectDb.createSnapshot(login, projectId).then((function(_this) {
      return function(newProjectId) {
        var projects, _ref1;
        projects = (_ref1 = _this.get('projects')) != null ? _ref1 : [];
        projects.push(_.extend(_this._newProject(newProjectId), {
          isCopy: true
        }));
        return _this.save('projects', projects).then(function() {
          return W(_this.getRequests().create({
            _id: "" + createProjectSnapshotRequestPrefix + "_" + newProjectId,
            type: 'create_project_snapshot',
            status: 'new',
            projectId: newProjectId,
            organizationId: organizationId,
            login: login
          })).then(function() {
            return newProjectId;
          });
        });
      };
    })(this));
  };

  User.prototype.restoreProjectSnapshot = function(projectId, organizationId) {
    var login;
    login = this.get('name');
    return mediator.services.projectDb.restoreSnapshot(login, projectId).then((function(_this) {
      return function(newProjectId) {
        var projects, _ref1;
        projects = (_ref1 = _this.get('projects')) != null ? _ref1 : [];
        projects.push(_this._newProject(newProjectId));
        return _this.save('projects', projects).then(function() {
          var request;
          request = organizationId != null ? _this._createOrganizationProjectRequest(newProjectId, organizationId, [login]) : _this._createProjectRequest(newProjectId);
          return W(_this.getRequests().create(request));
        }).then(function() {
          return newProjectId;
        });
      };
    })(this));
  };

  User.prototype.moveProjectToOrganization = function(projectId, organizationId) {
    return W(this.getRequests().create({
      type: 'move_project_to_organization',
      status: 'new',
      projectId: projectId,
      organizationId: organizationId,
      login: this.get('name')
    }));
  };

  User.prototype._duplicateProjectsIfNeeded = function() {
    var duplicate, entry;
    duplicate = (function(_this) {
      return function(entry) {
        return function() {
          return _this._duplicateProject(entry.name, true)["catch"](function(error) {
            return utils.reportRavenError('Error during "remote" project duplication\n' + error.message + '\n\n' + error.stack, {
              extra: {
                duplicated_project: entry.name
              }
            });
          });
        };
      };
    })(this);
    return sequence((function() {
      var _i, _len, _ref1, _results;
      _ref1 = _(this.get('projects')).where({
        status: 'duplication_needed'
      });
      _results = [];
      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        entry = _ref1[_i];
        _results.push(duplicate(entry));
      }
      return _results;
    }).call(this));
  };

  User.prototype._newProject = function(projectDbName) {
    return {
      name: projectDbName,
      status: 'new',
      worker: 'scala'
    };
  };

  User.prototype.addOrganizationProject = function(projectDbName, organizationId, members) {
    this.get('projects').push(this._newProject(projectDbName));
    return this.save().then((function(_this) {
      return function(newUdb) {
        var request;
        request = _this._createOrganizationProjectRequest(projectDbName, organizationId, members);
        return W(_this.getRequests().create(request)).then(function() {
          return newUdb;
        });
      };
    })(this));
  };

  User.prototype.addProject = function(projectDbName) {
    this.get('projects').push(this._newProject(projectDbName));
    return this.save().then((function(_this) {
      return function(newUdb) {
        var request;
        request = _this._createProjectRequest(projectDbName);
        return W(_this.getRequests().create(request)).then(function() {
          return newUdb;
        });
      };
    })(this));
  };

  User.prototype._createProjectRequest = function(projectDbName) {
    return {
      _id: "" + createProjectRequestPrefix + "_" + projectDbName,
      type: 'create_project',
      status: 'new',
      projectId: projectDbName,
      login: this.get('name')
    };
  };

  User.prototype._createOrganizationProjectRequest = function(projectDbName, organizationId, members) {
    return {
      _id: "" + createOrganizationProjectRequestPrefix + "_" + projectDbName,
      type: 'create_organization_project',
      status: 'new',
      projectId: projectDbName,
      organizationId: organizationId,
      members: members,
      createdBy: this.get('name')
    };
  };

  User.prototype.markAsAccessRevoked = function(projectDbs) {
    return this._updateStatusAndRemoveProjects('access_revoked', projectDbs, 'projects:access_revoked', 'projects:access_revoked_many');
  };

  User.prototype.markProjectsAsDeleted = function(projectDbs) {
    return this._updateStatusAndRemoveProjects('deleted', projectDbs, 'projects:deleted', 'projects:deleted_many');
  };

  User.prototype._updateStatusAndRemoveProjects = function(newStatus, projectDbs, warningMsgKey, warningManyMsgKey) {
    return W.map(projectDbs, this._updateStatusAndRemoveProject(newStatus)).then(function(projects) {
      if (projects.length === 1) {
        return mediator.dialogs.warning($.t(warningMsgKey, {
          projectName: projects[0]
        }));
      } else if (projects.length > 1) {
        return mediator.dialogs.warning($.t(warningManyMsgKey) + '<br>' + projects.join('<br>'));
      }
    });
  };

  User.prototype._updateStatusAndRemoveProject = function(newStatus) {
    return (function(_this) {
      return function(projectDbName) {
        var projectName, _ref1, _ref2;
        _this._getProjectData(projectDbName).status = newStatus;
        projectName = (_ref1 = (_ref2 = mediator.projects.get(projectDbName)) != null ? _ref2.get('name') : void 0) != null ? _ref1 : projectDbName;
        return _this.save().then(function() {
          return mediator.projects.removeProject(projectDbName);
        }).then(function() {
          return projectName;
        });
      };
    })(this);
  };

  User.prototype.moveProjectToTrash = function(projectDbName) {
    this._getProjectData(projectDbName).inTrash = true;
    return this.save().then(function() {
      return mediator.projects.removeProject(projectDbName);
    });
  };

  User.prototype.restoreProjectFromTrash = function(projectDbName) {
    delete this._getProjectData(projectDbName).inTrash;
    return this.save();
  };

  User.prototype.irreversiblyDestroyProjectAndAllItsData = function(projectDbName) {
    this._getProjectData(projectDbName).status = 'deleted';
    return this.save().then(function() {
      return mediator.services.projectDb.destroy(projectDbName);
    }).then(function() {
      return mediator.projects.removeProject(projectDbName);
    });
  };

  User.prototype.getReplicatedProjects = function() {
    return _(this.get('projects')).chain().where({
      status: 'replication_on'
    }).pluck('name').value();
  };

  User.prototype.getListedProjects = function() {
    return _(this.get('projects')).chain().filter(function(project) {
      var _ref1;
      return (_ref1 = project.status) === 'replication_on' || _ref1 === 'replication_off';
    }).pluck('name').value();
  };

  User.prototype.getProjectsFromTrash = function() {
    var inTrash;
    inTrash = _(this.get('projects')).chain().where({
      inTrash: true
    });
    return inTrash.reject(function(item) {
      return item.status === 'deleted';
    }).pluck('name').value();
  };

  User.prototype.getActiveProjectIds = function() {
    return _(this.get('projects')).chain().where({
      status: 'replication_on'
    }).reject(function(p) {
      return p.isArchived;
    }).pluck('name').value();
  };

  User.prototype._getProjectsData = function() {
    return _(this.get('projects')).reject(function(p) {
      var _ref1;
      return ((_ref1 = p.status) === 'deleted' || _ref1 === 'broken' || _ref1 === 'access_revoked' || _ref1 === 'duplication_needed') || p.inTrash;
    });
  };

  User.prototype.getProjects = function() {
    return _(this._getProjectsData()).pluck('name');
  };

  User.prototype.getProjectsWithOrderNumbers = function() {
    var projectsWithOrderNumbers;
    projectsWithOrderNumbers = {};
    _(this._getProjectsData()).each(function(p) {
      return projectsWithOrderNumbers[p.name] = p.orderNumber;
    });
    return projectsWithOrderNumbers;
  };

  User.prototype.getProjectsMaxOrderNumber = function() {
    var ns;
    ns = _(this._getProjectsData()).chain().pluck('orderNumber').filter(function(n) {
      return _.isNumber(n);
    }).value();
    ns.push(0);
    return _.max(ns);
  };

  User.prototype._getProjectData = function(projectId) {
    return _(this.get('projects')).findWhere({
      name: projectId
    });
  };

  User.prototype.setProjectOrderNumber = function(projectId, orderNumber) {
    return this._getProjectData(projectId).orderNumber = orderNumber;
  };

  User.prototype.getProjectOrderNumber = function(projectId) {
    var projectData;
    projectData = this._getProjectData(projectId);
    if (!projectData) {
      return null;
    }
    return projectData.orderNumber;
  };

  User.prototype._narrativeInfoForQuestionKey = function(question) {
    return "" + mediator.project.id + "/" + question.id;
  };

  User.prototype.wasNarrativeInfoShownForQuestion = function(question) {
    return this.get('narrativeInfoShownForQuestion')[this._narrativeInfoForQuestionKey(question)];
  };

  User.prototype.narrativeInfoShownForQuestion = function(question) {
    var infos;
    infos = this.get('narrativeInfoShownForQuestion');
    infos[this._narrativeInfoForQuestionKey(question)] = true;
    this.set('narrativeInfoShownForQuestion', infos);
    return this.save().otherwise(mediator.dialogs.fatalError);
  };

  User.prototype.getRequests = function() {
    return this.requests;
  };

  User.prototype.getShareProjects = function() {
    return this.shareProjects;
  };

  User.prototype.getAccessRights = function() {
    var userEmail, userMember, _ref1, _ref2;
    userEmail = (_ref1 = mediator.user.get('email')) != null ? _ref1.toLowerCase() : void 0;
    userMember = mediator.colls.teamMembers.find(function(member) {
      var _ref2;
      return ((_ref2 = member.get('email')) != null ? _ref2.toLowerCase() : void 0) === userEmail && !member.get('deleted');
    });
    return (_ref2 = userMember != null ? userMember.get('accessRights') : void 0) != null ? _ref2 : [];
  };

  User.prototype.getFullName = function() {
    return [this.get('firstName'), this.get('lastName')].join(' ').trim();
  };

  User.prototype.hasRole = function(role) {
    var rolesToCheck;
    rolesToCheck = _.isArray(role) ? role : [role];
    return _.intersection(this.getAccessRights(), rolesToCheck).length > 0;
  };

  User.prototype.isAdmin = function() {
    return this.hasRole('admin');
  };

  return User;

})(Model);
