Translation = require 'components/mixins/translation'
{ STATUSES } = require 'lib/references_utils'

GridFilter = createReactClass
  displayName: 'GridFilter'

  mixins: [
    Translation('references:statuses')
  ]

  updateFilterText: ->
    @props.updateFilterText @filterInput.value

  filterByStatus: ->
    @props.filterByStatus @statusFilter.value

  componentDidMount: ->
    @filterInput.focus()

  _renderOptions: ->
    STATUSES.map (status, idx) =>
      <option value={status} key={idx}>
        {"#{@i18n "#{status}"} (#{@props.refsByStatus.get(status)?.size ? 0})"}
      </option>

  render: ->
    <div className='filter-container'>
      <select
        ref={(el) => @statusFilter = el}
        defaultValue={@props.selectStatus}
        onChange={@filterByStatus}
      >
        <option value='none'>{"- #{@i18n 'selectStatus'} -"}</option>
        {@_renderOptions()}
      </select>
      <input
        ref={(el) => @filterInput = el}
        type='text'
        placeholder='Filter'
        value={@props.filterText}
        onChange={@updateFilterText}
      />
    </div>

module.exports = GridFilter
