CustomRenderMixin = require 'components/mixins/custom_render_mixin'

TogglePanel = createReactClass
  displayName: 'TogglePanel'

  mixins: [CustomRenderMixin]

  getInitialState: ->
    status: @props.status or 'closed'

  toggle: (e) ->
    @setState
      status: if @state.status is 'open' then 'closed' else 'open',
      -> @props.statusCbk? @state.status

  render: ->
    <div className={classNames 'toggle-panel', @props.className}>
      <div className={ classNames('header', @props.headerClass, @state.status) } onClick={@toggle}>
        <h2>{@props.title}</h2>
      </div>
      <div className={ classNames('content', @state.status)}>
        {@props.children}
      </div>
    </div>

module.exports = TogglePanel
