var QUESTION_GENERATION_STEPS, W, approvalDataWithSubmittedDetails, calculateTitles, getApprovalData, getBrainstormingData, getConvertedData, getFinishedData, getModerationData, getProposalData, getQuestionsGenerationDataObject, getRatingData, mediator, prepareApprovalData, prepareBrainstormingData, prepareFinishedData, prepareModerationData, prepareProposalData, prepareRatingData, questionsGenerationAsync, ratingDataWithSubmittedDetails, updateQuestionsWithRatingData, utils, _ref, _ref1;

W = require('when');

mediator = require('mediator');

utils = require('base/lib/utils');

_ref = require('lib/questions_helper'), QUESTION_GENERATION_STEPS = _ref.QUESTION_GENERATION_STEPS, calculateTitles = _ref.calculateTitles, updateQuestionsWithRatingData = _ref.updateQuestionsWithRatingData;

_ref1 = require('lib/questions_generation_converter_helper'), getQuestionsGenerationDataObject = _ref1.getQuestionsGenerationDataObject, prepareBrainstormingData = _ref1.prepareBrainstormingData, prepareModerationData = _ref1.prepareModerationData, prepareRatingData = _ref1.prepareRatingData, prepareProposalData = _ref1.prepareProposalData, prepareApprovalData = _ref1.prepareApprovalData, prepareFinishedData = _ref1.prepareFinishedData, ratingDataWithSubmittedDetails = _ref1.ratingDataWithSubmittedDetails, approvalDataWithSubmittedDetails = _ref1.approvalDataWithSubmittedDetails;

questionsGenerationAsync = require('actions/async/questions_generation');

getBrainstormingData = function() {
  var brainstormingDocId, stepName;
  brainstormingDocId = mediator.project.get('questionScopeCentralDoc');
  stepName = QUESTION_GENERATION_STEPS[1];
  return questionsGenerationAsync.fetchStepResults({
    step: stepName,
    formsDocId: brainstormingDocId
  }).then(function(questions) {
    return prepareBrainstormingData(questions, brainstormingDocId);
  });
};

getModerationData = function(draftQuestions) {
  return getBrainstormingData().then(function(brainstormingData) {
    return prepareModerationData(draftQuestions, brainstormingData);
  });
};

getRatingData = function(draftQuestions, members) {
  var stepName;
  stepName = QUESTION_GENERATION_STEPS[3];
  return getModerationData(draftQuestions).then(function(moderationData) {
    return prepareRatingData(members, moderationData);
  }).then(function(ratingData) {
    var formsIds;
    formsIds = ratingData.steps[stepName].formsIds;
    return questionsGenerationAsync.fetchStepResults({
      step: stepName,
      formsDocId: formsIds
    }).then(function(formsMap) {
      return ratingDataWithSubmittedDetails(ratingData, formsMap);
    });
  });
};

getProposalData = function(draftQuestions, members) {
  return getRatingData(draftQuestions, members, true).then(function(ratingData) {
    return prepareProposalData(draftQuestions, ratingData);
  });
};

getApprovalData = function(draftQuestions, members) {
  var stepName;
  stepName = QUESTION_GENERATION_STEPS[5];
  return getProposalData(draftQuestions, members).then(function(proposalData) {
    return prepareApprovalData(members, proposalData);
  }).then(function(approvalData) {
    var formsIds;
    formsIds = approvalData.steps[stepName].formsIds;
    return questionsGenerationAsync.fetchStepResults({
      step: stepName,
      formsDocId: formsIds
    }).then(function(submittedApprovals) {
      return approvalDataWithSubmittedDetails(approvalData, submittedApprovals);
    });
  });
};

getFinishedData = function(draftQuestions, members) {
  return getApprovalData(draftQuestions, members).then(prepareFinishedData);
};

getConvertedData = function(stepName, draftQuestions, members) {
  var initialStep, questions;
  if (draftQuestions && (stepName == null)) {
    initialStep = QUESTION_GENERATION_STEPS[0];
    questions = draftQuestions.models.map(function(m) {
      var q;
      q = utils.deepCloneObject(m.attributes);
      q.source = 'initial';
      return q;
    });
    return W.resolve(getQuestionsGenerationDataObject(initialStep, questions));
  }
  return W.resolve((function() {
    switch (stepName) {
      case QUESTION_GENERATION_STEPS[1]:
        return getBrainstormingData();
      case QUESTION_GENERATION_STEPS[2]:
        return getModerationData(draftQuestions);
      case QUESTION_GENERATION_STEPS[3]:
        return getRatingData(draftQuestions, members);
      case QUESTION_GENERATION_STEPS[4]:
        return getProposalData(draftQuestions, members);
      case QUESTION_GENERATION_STEPS[5]:
        return getApprovalData(draftQuestions, members);
      case QUESTION_GENERATION_STEPS[6]:
        return getFinishedData(draftQuestions, members);
    }
  })());
};

module.exports = {
  getConvertedData: getConvertedData
};
