var adolopmentDataDocId, appUtils, generateActions, mediator;

appUtils = require('lib/app_utils');

generateActions = require('actions/utils/generate_actions');

mediator = require('mediator');

adolopmentDataDocId = require('lib/doc_ids').ADOLOPMENT_DATA;

module.exports = generateActions({
  autoGenerate: ['fetchAdolopmentDataSuccess', 'fetchAdolopmentDataError'],
  name: 'AdolopmentDataActions',
  actions: {
    dbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      if (doc['_id'] === adolopmentDataDocId) {
        return this.dispatch(doc);
      }
    },
    fetchAdolopmentData: function() {
      return mediator.services.adolopmentDataService.fetch().then((function(_this) {
        return function(_arg) {
          var etdsData;
          etdsData = _arg.etdsData;
          return _this.actions.fetchAdolopmentDataSuccess(etdsData);
        };
      })(this))["catch"](function(err) {
        if (err.status !== 404) {
          return appUtils.errorHandler(err);
        }
      });
    },
    toggleAdolopmentForSection: function(etdId, block, sectionId) {
      return mediator.services.adolopmentDataService.toggleAdolopmentForSection(etdId, block, sectionId);
    },
    toggleAdolopmentForSections: function(etdId, block, sectionIds) {
      return mediator.services.adolopmentDataService.toggleAdolopmentForSections(etdId, block, sectionIds);
    }
  }
});
