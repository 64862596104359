OutcomesTableViewActions = require 'actions/outcomes_table_view_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ getKeyValObject } = require 'base/lib/utils'
{ instanceOf } = PropTypes

OUTCOME_META_TYPES = [
  'POOLED'
  'SINGLE_STUDY'
  'NOT_POOLED'
  'NOT_MEASURED'
  'RANGE_OF_EFFECTS'
  'NOT_REPORTED'
]

OUTCOME_TYPES = [
  'dich'
  'cont'
  'narrative'
]

updateOutcomeAttribute = _.compose(
  OutcomesTableViewActions.updateEditedOutcomeAttributes
  getKeyValObject
)

OneRowOutcomeLabelCell = createReactClass
  displayName: 'OneRowOutcomeLabelCell'

  mixins: [
    Translation('es:outcome')
    CustomRenderMixin
  ]

  propTypes: {
    editMetadata: instanceOf(Immutable.Map)
  }

  startEdit: ->
    OutcomesTableViewActions.startOutcomeEdit
      outcomeId: @props.outcomeAttrs['_id']
      cellId: @props.id
      sourceId: @props.outcomeAttrs['sourceId']
      questionId: @props.outcomeAttrs['questionId']

  handleTextInputChange: (evt) ->
    attrName = evt.target.getAttribute 'name'
    attrValue = evt.target.value

    updateOutcomeAttribute attrName, attrValue

  handleRadioInputChange: (attrName) -> (evt) ->
    updateOutcomeAttribute attrName, evt.target.value

  applyChanges: ->
    unless @props.editMetadata?.get('editedAttributes', Immutable.Map()).isEmpty()
      OutcomesTableViewActions.saveEditedAttributes()
    OutcomesTableViewActions.stopOutcomeEdit()

  _getEditableFieldValue: (fieldName) ->
    @props.editMetadata?.getIn(['editedAttributes', fieldName]) ? @props.outcomeAttrs[fieldName]

  render: ->
    cellProps = _.omit @props, 'content', 'outcomeAttrs', 'editMetadata'
    { content, outcomeAttrs, isCaseControl, editMetadata} = @props
    { _id: outcomeId, forceTypeSelection } = outcomeAttrs
    isEditing = editMetadata?

    isCaseControl = outcomeAttrs.designStudies?.value in ['case_control_studies',
      'case_control_and_other_combined']

    <td {...cellProps}>
      <div className={classNames 'outcome-label-row-container', editing: isEditing}>
        {if isEditing
          <div className='outcome-label-edit'>
            <button className='save' title={@i18n 'apply'} onClick={@applyChanges} />
            <div className='label-input'>
              <input
                className='footnote-name'
                name='name'
                type='text'
                value={@_getEditableFieldValue 'name'}
                onChange={@handleTextInputChange}
              />
            </div>
          </div>
        else
          <div className='outcome-label'>
            <button className='edit' onClick={@startEdit} />
            <div className='name-block'>
              <span className='outcome-name' dangerouslySetInnerHTML={{__html: content}} />
            </div>
          </div>
        }
        <div className='outcome-edit-block'>
          <div className='outcome-toggleable-editor row'>
            <div className='outcome-edit col-12'>
              <div className='row'>
                <div className='outcome-sort-delete'>
                  <div>
                    <button className='delete' title={@i18n 'delete'} tabIndex='-1' />
                  </div>
                </div>
                <div className='left-side-edit col-6'>
                  <div className='row mt-10'>
                    <div className='short-name edit-top-row col-5'>
                      <label htmlFor='shortName' className='footnote-shortName'>
                        {@i18n 'short_name'}
                      </label>
                      <input
                        id='shortName'
                        className='form-control'
                        name='shortName'
                        type='text'
                        value={@_getEditableFieldValue 'shortName'}
                        onChange={@handleTextInputChange}
                      />
                    </div>
                    <div className='edit-top-row col-6'>
                      <label htmlFor='measuredWith' className='footnote-measuredWith'>
                        {@i18n 'measured_with'}
                      </label>
                      <input
                        id='measuredWith'
                        className='form-control'
                        name='measuredWith'
                        type='text'
                        value={@_getEditableFieldValue 'measuredWith'}
                        onChange={@handleTextInputChange}
                      />
                    </div>
                    <div className='col-1'></div>
                  </div>
                  <div className='left-side-col2 col-12'>
                    '[followUpLength]'
                  </div>
                  <div className='left-side-col2 col-12 range-of-scores'>
                    <div className='form-group row mt-10 scale-to'>
                      <div>
                        <label
                          className='footnote-scaleFrom footnote-scaleTo'
                          htmlFor='possible-scores-range'
                        >
                          {@i18n 'range_of_possible_scores'}
                        </label>
                        <input
                          id='possible-scores-range'
                          name='scaleFrom'
                          type='text'
                          value={@_getEditableFieldValue 'scaleFrom'}
                          onChange={@handleTextInputChange}
                        />
                        <label className='short'>{@i18n 'to'}</label>
                        <input
                          id='possible-scores-range'
                          name='scaleTo'
                          type='text'
                          value={@_getEditableFieldValue 'scaleTo'}
                          onChange={@handleTextInputChange}
                        />
                        <div className='sup'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='right-side-edit col-5'>
                  <div className='row mt-10'>
                    <span className='outcome-type-text footnote-type'>
                      {@i18n 'type'}
                      {if forceTypeSelection
                        <span className='select-outcome-type-warning'>{@i18n 'select_type'}</span>
                      }
                    </span>
                  </div>
                  <div className='edit-top-row row'>
                    <div className='outcome-type'>
                      <div className={classNames 'col-4', 'force-type': forceTypeSelection}>
                        <div className='box-bordered'>
                          <ul>
                            {OUTCOME_TYPES.map (type) =>
                              isCaseControlCont = type is 'cont' and isCaseControl
                              labelClassName = classNames 'radio-label',
                                disabled: isCaseControlCont

                              <li key={type}>
                                <label className={labelClassName}>
                                  <input
                                    checked={@_getEditableFieldValue('type') is type}
                                    disabled={isCaseControlCont}
                                    name="type-#{outcomeId}"
                                    onChange={@handleRadioInputChange 'type'}
                                    type='radio'
                                    value={type}
                                  />
                                  {@i18n type}
                                </label>
                              </li>
                            }
                          </ul>
                        </div>
                      </div>
                      <div className='col-8'>
                        <div className='box-bordered'>
                          <ul className='horizontal'>
                            {OUTCOME_META_TYPES.map (mType) =>
                              <li key={mType}>
                                <label className='radio-label'>
                                  <input
                                    checked={@_getEditableFieldValue('metaType') is mType}
                                    name="metaType-#{outcomeId}"
                                    onChange={@handleRadioInputChange 'metaType'}
                                    type='radio'
                                    value={mType}
                                  />
                                  {@i18n mType.toLowerCase()}
                                </label>
                              </li>
                            }
                          </ul>
                          <div className='clearfix'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>

module.exports = OneRowOutcomeLabelCell
