var ContentEditableContentToTextareaContent, Migration, NOT_TEXTAREA_SECTIONS, W, convertConclusions, convertHeaderSections, convertJustificationCriterias, mediator,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

mediator = require('mediator');

W = require('when');

NOT_TEXTAREA_SECTIONS = ['intervention', 'comparison', 'mainOutcomes', 'anticipatedOutcomes', 'background'];

convertJustificationCriterias = function(section) {
  var content, criterias, criterionId, fieldName, tempDiv, _i, _len, _ref, _ref1;
  if (section.details == null) {
    return;
  }
  tempDiv = document.createElement('div');
  _ref = ['criteria', 'criteriaConsensus'];
  for (_i = 0, _len = _ref.length; _i < _len; _i++) {
    fieldName = _ref[_i];
    criterias = section.details[fieldName];
    if (criterias == null) {
      continue;
    }
    _ref1 = section.details[fieldName];
    for (criterionId in _ref1) {
      content = _ref1[criterionId];
      tempDiv.innerHTML = content;
      section.details[fieldName][criterionId] = tempDiv.innerText;
    }
  }
  return tempDiv = null;
};

convertConclusions = function(sections) {
  var section, sectionName, _results;
  _results = [];
  for (sectionName in sections) {
    section = sections[sectionName];
    _results.push(convertJustificationCriterias(section));
  }
  return _results;
};

convertHeaderSections = function(sections) {
  var section, sectionName, tempDiv;
  tempDiv = document.createElement('div');
  for (sectionName in sections) {
    section = sections[sectionName];
    if (__indexOf.call(NOT_TEXTAREA_SECTIONS, sectionName) >= 0) {
      continue;
    }
    tempDiv.innerHTML = section.content;
    section.content = tempDiv.innerText;
  }
  return tempDiv = null;
};

module.exports = ContentEditableContentToTextareaContent = (function(_super) {
  __extends(ContentEditableContentToTextareaContent, _super);

  function ContentEditableContentToTextareaContent() {
    ContentEditableContentToTextareaContent.__super__.constructor.call(this, false);
  }

  ContentEditableContentToTextareaContent.prototype.up = function(project, colls) {
    var recommendationIds;
    ContentEditableContentToTextareaContent.__super__.up.apply(this, arguments);
    recommendationIds = colls.questions.reduce(function(recommendationIds, q) {
      return recommendationIds.concat(q.get('recommendationIds'));
    }, []);
    return mediator.services.storePersistenceAdapter.fetch(project.id, recommendationIds).then(function(_arg) {
      var rows, updatedEtdDocs;
      rows = _arg.rows;
      updatedEtdDocs = rows.map(function(_arg1) {
        var doc;
        doc = _arg1.doc;
        convertConclusions(doc.templateData.conclusions.sections);
        convertHeaderSections(doc.templateData.question.sections);
        return doc;
      });
      return mediator.services.storePersistenceAdapter.bulkDocs(project.id, updatedEtdDocs);
    })["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
    });
  };

  return ContentEditableContentToTextareaContent;

})(Migration);
