module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या प्रमुख हितधारकों को हस्तक्षेप स्वीकार्य है?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या ऐसे प्रमुख हितधारक हैं जो लाभ, हानि और लागत के वितरण को स्वीकार नहीं करेंगे?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या ऐसे प्रमुख हितधारक हैं जो भविष्य में वांछनीय प्रभावों (लाभ) के लिए अल्पावधि में लागत या अवांछनीय प्रभावों को स्वीकार नहीं करेंगे?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या ऐसे प्रमुख हितधारक हैं जो वांछनीय या अवांछनीय प्रभावों से जुड़े मूल्यों से सहमत नहीं होंगे (क्योंकि वे व्यक्तिगत रूप से कैसे प्रभावित हो सकते हैं या दूसरों के लिए प्रभावों के सापेक्ष महत्व की उनकी धारणा के कारण)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या हस्तक्षेप (विकल्प) लोगों की स्वायत्तता पर प्रतिकूल प्रभाव डालेगा?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या ऐसे प्रमुख हितधारक हैं जो लोगों की स्वायत्तता पर इसके प्रभावों के अलावा अन्य कारणों से हस्तक्षेप (विकल्प) को नैतिक रूप से अस्वीकार करेंगे (अर्थात गैर-दुर्भावना, उपकार या न्याय जैसे नैतिक सिद्धांतों के संबंध में)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "क्या प्रमुख हितधारक लाभ, हानि और लागत के वितरण को स्वीकार नहीं करेंगे; या भविष्य में वांछनीय प्रभावों (लाभ) के लिए अल्पावधि में लागत या अवांछनीय प्रभाव? क्या वे वांछनीय या अवांछनीय प्रभावों से जुड़े मूल्यों से असहमत होने की संभावना रखते हैं, या नैतिक चिंताओं के कारण नैदानिक ​​हस्तक्षेप को स्वीकार नहीं करते हैं?",
          "options": [
            {
              "text": "नहीं",
              "value": "no"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "हां",
              "value": "yes"
            }
          ],
          "ratingDescription": "प्रमुख हितधारकों के लिए कौन सा हस्तक्षेप अधिक स्वीकार्य है?",
          "ratingOptions": [
            "best_acceptability",
            "intermediate_acceptability",
            "worst_acceptability"
          ],
          "selectedOption": ""
        },
        "additionalSection": {
          "additionalOptions": [
            {
              "text": "अतिरिक्त विचार 1",
              "value": "additionalOption_1"
            },
            {
              "text": "अतिरिक्त विचार 2",
              "value": "additionalOption_2"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "कस्टम मानदंड विवरण",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "कस्टम टूलटिप प्रदान करें",
          "options": [
            {
              "text": "विचार 1",
              "value": "option_1"
            },
            {
              "text": "विचार 2",
              "value": "option_2"
            },
            {
              "text": "विचार 3",
              "value": "option_3"
            },
            {
              "text": "विचार 4",
              "value": "option_4"
            },
            {
              "text": "विचार 5",
              "value": "option_5"
            }
          ],
          "selectedOption": ""
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या वांछनीय और अवांछनीय प्रभावों के बीच संतुलन हस्तक्षेप या तुलना के पक्ष में है?",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "इस मानदंड के लिए विस्तृत निर्णयों में चार पूर्ववर्ती मानदंडों में से प्रत्येक के संबंध में निर्णय शामिल हैं:"
              },
              {
                "additions": [
                  "लोग अब होने वाले परिणामों (उनकी छूट दरों) की तुलना में भविष्य में होने वाले परिणामों को कितना कम महत्व देते हैं?",
                  "अवांछनीय प्रभावों के प्रति लोगों का नजरिया (वे कितना जोखिम से बचते हैं)।",
                  "वांछनीय प्रभावों के प्रति लोगों का दृष्टिकोण (वे कितने जोखिमी काम वाले हैं)।"
                ],
                "description": "इसके अलावा, पैनल इस बात पर विचार करना चाह सकते हैं (और, यदि प्रासंगिक दस्तावेज हैं) तो किस हद तक निम्नलिखित विचार वांछनीय और अवांछनीय प्रभावों के बीच संतुलन को प्रभावित कर सकते हैं:"
              }
            ],
            "panelDiscussion": ""
          },
          "info": "वांछनीय और अवांछनीय प्रभावों के बीच संतुलन क्या है, इस बात को ध्यान में रखते हुए कि व्यक्ति मुख्य परिणामों को कितना महत्व देते हैं, वांछनीय और अवांछनीय प्रभाव कितने महत्वपूर्ण हैं, उन अनुमानों की निश्चितता, छूट दर, जोखिम से बचने और जोखिम की मांग?",
          "options": [
            {
              "text": "तुलना के पक्षधर हैं",
              "value": "favors_comparison"
            },
            {
              "text": "शायद तुलना के पक्षधर हैं",
              "value": "probably_favors_comparison"
            },
            {
              "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
              "value": "doesnt_favor_any"
            },
            {
              "text": "शायद हस्तक्षेप के पक्षधर हैं",
              "value": "probably_favors_intervention"
            },
            {
              "text": "हस्तक्षेप का समर्थन करता है",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?",
          "ratingOptions": [
            "best_balance",
            "intermediate",
            "worst_balance"
          ],
          "selectedOption": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या वांछनीय और अवांछनीय स्वास्थ्य प्रभावों के बीच संतुलन हस्तक्षेप या तुलना के पक्ष में है?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या <strong>स्वास्थ्य पर हस्तक्षेप की अल्पकालिक और लंबी अवधि की <strong>प्रभावकारिता</strong> (नियंत्रित, अक्सर आदर्श परिस्थितियों में) या <strong>प्रभावकारिता</strong> (वास्तविक जीवन की परिस्थिति में) है व्यक्तियों के </strong>, रोगी द्वारा बयान किए गए परिणामों सहित, हस्तक्षेप या तुलना के पक्ष में हैं?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या लाभार्थी-रिपोर्ट किए गए परिणामों सहित, <strong>आबादी के स्वास्थ्य</strong> पर हस्तक्षेप की अल्पकालिक और लंबी अवधि की <strong>प्रभावशीलता</strong> या <strong>प्रभाव</strong> के पक्ष में है हस्तक्षेप या तुलना? (इसमें इस बारे में विचार शामिल होना चाहिए कि क्या जनसंख्या-स्तर के परिणाम समग्र व्यक्तिगत-स्तर के परिणामों का प्रतिनिधित्व करते हैं या सिस्टम की गतिशीलता के माध्यम से उभरते हैं।)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या विभिन्न स्वास्थ्य परिणाम <strong>मरीजों/लाभार्थियों का महत्व</strong> किस हद तक हस्तक्षेप या तुलना के पक्ष में हैं?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या हस्तक्षेप से जुड़ी <strong>प्रतिकूल प्रभावों की संभावना और गंभीरता</strong> (हस्तक्षेप के दुरुपयोग के जोखिम सहित) हस्तक्षेप या तुलना के पक्ष में है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या हस्तक्षेप से जुड़े <strong>व्यापक सकारात्मक या नकारात्मक स्वास्थ्य संबंधी प्रभाव</strong> (उदाहरण के लिए कलंक में कमी, अन्य बीमारियों पर सकारात्मक प्रभाव, रोगियों/लाभार्थियों से परे स्पिलओवर प्रभाव) हस्तक्षेप या तुलना के पक्ष में हैं?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "एक हस्तक्षेप से जुड़े शुद्ध स्वास्थ्य लाभ जितना अधिक होगा, इस हस्तक्षेप के पक्ष में एक सामान्य सिफारिश की संभावना उतनी ही अधिक होगी।",
          "options": [
            {
              "text": "तुलना के पक्षधर हैं",
              "value": "favors_the_comparison"
            },
            {
              "text": "शायद तुलना के पक्षधर हैं",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "शायद हस्तक्षेप के पक्षधर हैं",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "हस्तक्षेप का समर्थन करता है",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "कोई अध्ययन शामिल नहीं है",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "परीक्षण के प्रभावों के प्रमाण की समग्र निश्चितता क्या है?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "अनुसंधान सभी महत्वपूर्ण परिणामों में संभावित प्रभावों का कितना अच्छा संकेत देता है; यानी इस बात की संभावना कि अनुसंधान ने जो पाया है कि यह नैदानिक ​​हस्तक्षेप के बारे में निर्णय को प्रभावित कर सकता है, उससे काफी भिन्न होगा?",
          "options": [
            {
              "text": "बहुत कम",
              "value": "very_low"
            },
            {
              "text": "कम",
              "value": "low"
            },
            {
              "text": "संयत",
              "value": "moderate"
            },
            {
              "text": "उच्च",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "कोई अध्ययन शामिल नहीं है",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "प्रभावों के प्रमाण की समग्र निश्चितता क्या है?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "अनुसंधान सभी महत्वपूर्ण परिणामों में संभावित प्रभावों का कितना अच्छा संकेत देता है; यानी संभावना है कि प्रभाव काफी भिन्न होंगे जो अनुसंधान ने पाया कि यह हस्तक्षेप के बारे में निर्णय को प्रभावित कर सकता है?",
          "options": [
            {
              "text": "बहुत कम",
              "value": "very_low"
            },
            {
              "text": "कम",
              "value": "low"
            },
            {
              "text": "संयत",
              "value": "moderate"
            },
            {
              "text": "उच्च",
              "value": "high"
            }
          ],
          "ratingDescription": "What is the overall certainty of the evidence of effects for each intervention?",
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "कोई अध्ययन शामिल नहीं है",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "परीक्षण के परिणामों द्वारा निर्देशित प्रबंधन के प्रभावों के साक्ष्य की समग्र निश्चितता क्या है?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "बहुत कम",
              "value": "very_low"
            },
            {
              "text": "कम",
              "value": "low"
            },
            {
              "text": "संयत",
              "value": "moderate"
            },
            {
              "text": "उच्च",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "कोई अध्ययन शामिल नहीं है",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "संसाधन आवश्यकताओं (लागत) के प्रमाण की निश्चितता क्या है?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या संसाधन उपयोग की सभी महत्वपूर्ण मदों की पहचान की गई है जो उन विकल्पों के बीच भिन्न हो सकते हैं जिन पर विचार किया जा रहा है?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "महत्वपूर्ण अनिश्चितता",
                    "value": "important"
                  },
                  {
                    "text": "संभवतः महत्वपूर्ण अनिश्चितता",
                    "value": "probably_important"
                  },
                  {
                    "text": "शायद कोई महत्वपूर्ण अनिश्चितता नहीं",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "कोई महत्वपूर्ण अनिश्चितता नहीं",
                    "value": "no_important"
                  }
                ],
                "question": "संसाधन उपयोग की वस्तुओं की लागत कितनी निश्चित है जो विचार किए जा रहे विकल्पों के बीच भिन्न है?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "महत्वपूर्ण परिवर्तनशीलता",
                    "value": "important"
                  },
                  {
                    "text": "संभवतः महत्वपूर्ण परिवर्तनशीलता",
                    "value": "probably_important"
                  },
                  {
                    "text": "शायद कोई महत्वपूर्ण परिवर्तनशीलता नहीं",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "कोई महत्वपूर्ण परिवर्तनशीलता नहीं",
                    "value": "no_important"
                  }
                ],
                "question": "क्या संसाधन उपयोग की वस्तुओं की लागत में महत्वपूर्ण परिवर्तनशीलता है जो विचार किए जा रहे विकल्पों के बीच भिन्न है?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "प्रत्येक प्रकार के संसाधन उपयोग (जैसे दवाएं, अस्पताल में भर्ती) और संसाधनों की लागत के लिए अंतर का प्रमाण कितना निश्चित है?",
          "options": [
            {
              "text": "बहुत कम",
              "value": "very_low"
            },
            {
              "text": "कम",
              "value": "low"
            },
            {
              "text": "संयत",
              "value": "moderate"
            },
            {
              "text": "उच्च",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "कोई अध्ययन शामिल नहीं है",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "परीक्षण सटीकता के प्रमाण की समग्र निश्चितता क्या है?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "बहुत कम",
              "value": "very_low"
            },
            {
              "text": "कम",
              "value": "low"
            },
            {
              "text": "संयत",
              "value": "moderate"
            },
            {
              "text": "उच्च",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "कोई अध्ययन शामिल नहीं है",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "किसी भी महत्वपूर्ण या महत्वपूर्ण प्रत्यक्ष लाभ, प्रतिकूल प्रभाव या परीक्षण के बोझ के लिए साक्ष्य की समग्र निश्चितता क्या है?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "बहुत कम",
              "value": "very_low"
            },
            {
              "text": "कम",
              "value": "low"
            },
            {
              "text": "संयत",
              "value": "moderate"
            },
            {
              "text": "उच्च",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "कोई अध्ययन शामिल नहीं है",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "परीक्षण के परिणामों और प्रबंधन निर्णयों के बीच की कड़ी कितनी निश्चित है?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "बहुत कम",
              "value": "very_low"
            },
            {
              "text": "कम",
              "value": "low"
            },
            {
              "text": "संयत",
              "value": "moderate"
            },
            {
              "text": "उच्च",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "कोई अध्ययन शामिल नहीं है",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या हस्तक्षेप की लागत-प्रभावशीलता हस्तक्षेप या तुलना के पक्ष में है?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "कोई अध्ययन शामिल नहीं है",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या लागत-प्रभावशीलता अनुपात एकतरफा संवेदनशीलता विश्लेषण के प्रति संवेदनशील है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "कोई अध्ययन शामिल नहीं है",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या लागत-प्रभावशीलता अनुपात बहु-परिवर्तनीय संवेदनशीलता विश्लेषण के प्रति संवेदनशील है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "कोई अध्ययन शामिल नहीं है",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "बहुत गंभीर सीमाएं",
                    "value": "very_serious"
                  },
                  {
                    "text": "गंभीर सीमाएं",
                    "value": "serious"
                  },
                  {
                    "text": "कोई गंभीर सीमा नहीं",
                    "value": "no_serious"
                  }
                ],
                "question": "क्या आर्थिक मूल्यांकन जिस पर लागत-प्रभावशीलता का अनुमान आधारित है, विश्वसनीय है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "कोई अध्ययन शामिल नहीं है",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "बहुत गंभीर सीमाएं",
                    "value": "very_serious"
                  },
                  {
                    "text": "गंभीर सीमाएं",
                    "value": "serious"
                  },
                  {
                    "text": "कोई गंभीर सीमा नहीं",
                    "value": "no_serious"
                  }
                ],
                "question": "क्या आर्थिक मूल्यांकन जिस पर लागत-प्रभावशीलता का अनुमान आधारित है, पतिस्थिति पर लागू होता है",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "क्या हस्तक्षेप लागत प्रभावी है, लागत के बारे में अनिश्चितता या परिवर्तनशीलता, शुद्ध लाभ में अनिश्चितता या परिवर्तनशीलता, संवेदनशीलता विश्लेषण, और आर्थिक मूल्यांकन की विश्वसनीयता और प्रयोज्यता को ध्यान में रखते हुए हॆ?",
          "options": [
            {
              "text": "तुलना के पक्षधर हैं",
              "value": "favors_comparison"
            },
            {
              "text": "शायद तुलना के पक्षधर हैं",
              "value": "probably_favors_comparison"
            },
            {
              "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
              "value": "doesnt_favor_any"
            },
            {
              "text": "शायद हस्तक्षेप के पक्षधर हैं",
              "value": "probably_favors_intervention"
            },
            {
              "text": "हस्तक्षेप का समर्थन करता है",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "लागत प्रभावशीलता किस हस्तक्षेप का पक्ष लेती है?",
          "ratingOptions": [
            "best_cost_effectiveness",
            "intermediate_cost_effectiveness",
            "worst_cost_effectiveness"
          ],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "वांछनीय प्रत्याशित प्रभाव कितने महत्वपूर्ण हैं?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "मामूली",
                    "value": "trivial"
                  },
                  {
                    "text": "छोटा",
                    "value": "small"
                  },
                  {
                    "text": "संयत",
                    "value": "moderate"
                  },
                  {
                    "text": "बड़ा",
                    "value": "large"
                  }
                ],
                "question": "प्रत्येक मुख्य परिणाम के लिए प्रत्याशित प्रभाव (अंतर) कितना पर्याप्त है जिसके लिए एक वांछनीय प्रभाव है?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "परिणामों के महत्व को ध्यान में रखते हुए हस्तक्षेप के वांछनीय प्रभाव कितने बड़े हैं (उन्हें कितना महत्व दिया जाता है), और प्रभाव का आकार (लाभ का अनुभव करने की संभावना या कितना सुधार व्यक्तियों को अनुभव होने की संभावना होगी )?",
          "options": [
            {
              "text": "मामूली",
              "value": "trivial"
            },
            {
              "text": "छोटा",
              "value": "small"
            },
            {
              "text": "संयत",
              "value": "moderate"
            },
            {
              "text": "बड़ा",
              "value": "large"
            }
          ],
          "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
          "ratingOptions": [
            "most_effective",
            "intermediate_effectiveness",
            "least_effective"
          ],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "स्वास्थ्य न्यायसम्य पर क्या प्रभाव पड़ेगा?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या ऐसे समूह या सेटिंग्स हैं जो समस्या या विकल्पों के संबंध में वंचित हो सकते हैं जिन पर विचार किया गया है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या वंचित समूहों या स्थति के लिए विकल्प की सापेक्ष प्रभावशीलता में अंतर की आशंका के लिए प्रशंसनीय कारण हैं?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या समूहों या सेटिंग्स में अलग-अलग आधारभूत स्थितियां हैं जो विकल्प की पूर्ण प्रभावशीलता या वंचित समूहों या सेटिंग्स के लिए समस्या के महत्व को प्रभावित करती हैं?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या ऐसे महत्वपूर्ण विचार हैं जिन्हें हस्तक्षेप (विकल्प) को लागू करते समय किया जाना चाहिए ताकि यह सुनिश्चित किया जा सके कि असमानताओं को कम किया जा सके, यदि संभव हो तो और उन्हें बढ़ाया न जाए?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "क्या वंचित उपसमूहों के लिए हस्तक्षेप की सापेक्ष प्रभावशीलता में अंतर की आशंका के लिए या वंचित उपसमूहों में विभिन्न आधारभूत स्थितियों में अंतर की आशंका के कारण हैं जो हस्तक्षेप की पूर्ण प्रभावशीलता या समस्या के महत्व को प्रभावित करते हैं?",
          "options": [
            {
              "text": "कम किया हुआ",
              "value": "reduced"
            },
            {
              "text": "शायद कम",
              "value": "probably_reduced"
            },
            {
              "text": "शायद कोई असर नहीं",
              "value": "probably_no_impact"
            },
            {
              "text": "शायद बढ़ गया",
              "value": "probably_increased"
            },
            {
              "text": "बढ़ा हुआ",
              "value": "increased"
            }
          ],
          "ratingDescription": "यदि अनुशंसा दी जाती है, तो कौन सा हस्तक्षेप स्वास्थ्य असमानताओं को सबसे अधिक कम करेगा?",
          "ratingOptions": [
            "most_reduction",
            "intermediate_reduction",
            "less_reduction"
          ],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या हस्तक्षेप लागू करना संभव है?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या हस्तक्षेप (विकल्प) टिकाऊ है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या ऐसी महत्वपूर्ण बाधाएं हैं जो हस्तक्षेप (विकल्प) को लागू करने की व्यवहार्यता को सीमित कर सकती हैं या इसे लागू करते समय विचार करने की आवश्यकता है?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "क्या नैदानिक ​​हस्तक्षेप के उपयोग को बनाए रखना और इसका उपयोग करने के लिए संभावित बाधाओं को दूर करना संभव है",
          "options": [
            {
              "text": "नहीं",
              "value": "no"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "हां",
              "value": "yes"
            }
          ],
          "ratingDescription": "कौन सा हस्तक्षेप लागू करने के लिए अधिक व्यवहार्य है?",
          "ratingOptions": [
            "most_feasible",
            "intermediate_feasibility",
            "least_feasible"
          ],
          "selectedOption": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या हस्तक्षेप लागू करना संभव है?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या कोई <strong>कानूनी बाधाएं</strong> हैं जो हस्तक्षेप को लागू करने की व्यवहार्यता को सीमित कर सकती हैं?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "हां",
                    "value": "yes"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "नहीं",
                    "value": "no"
                  }
                ],
                "question": "क्या ऐसे <strong>शासन पहलू</strong> (जैसे रणनीतिक विचार, पिछले निर्णय) हैं जो हस्तक्षेप को लागू करने की व्यवहार्यता को सीमित कर सकते हैं? (इसमें औपचारिक या सूचना संस्थानों की उपस्थिति या अनुपस्थिति के संबंध में विचार शामिल होना चाहिए जो कार्यान्वयन की व्यवहार्यता को प्रभावित करने वाले हस्तक्षेप को लागू करने में प्रभावी नेतृत्व, निरीक्षण और जवाबदेही प्रदान कर सकते हैं)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "बड़े लाभकारी प्रभाव",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "मध्यम लाभकारी प्रभाव",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "नगण्य लाभकारी और प्रतिकूल प्रभाव",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "मध्यम प्रतिकूल प्रभाव",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "बड़े प्रतिकूल प्रभाव",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "हस्तक्षेप के <strong>निहितार्थ</strong> <strong>मौजूदा स्वास्थ्य प्रणाली के साथ बातचीत और फिट क्या हैं?</strong> (इसमें मौजूदा स्वास्थ्य प्रणाली और उसके घटकों के साथ हस्तक्षेप की बातचीत या प्रभाव के बारे में विचार शामिल हैं? )",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "बड़े लाभकारी प्रभाव",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "मध्यम लाभकारी प्रभाव",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "नगण्य लाभकारी और प्रतिकूल प्रभाव",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "मध्यम प्रतिकूल प्रभाव",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "बड़े प्रतिकूल प्रभाव",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "हस्तक्षेप के <strong>निहितार्थ</strong> <strong>स्वास्थ्य कार्यबल और व्यापक मानव संसाधनों के लिए</strong> (स्वास्थ्य क्षेत्र या अन्य क्षेत्रों में) क्या हैं? (इसमें इसकी आवश्यकता, उपयोग के संबंध में विचार शामिल होने चाहिए , और स्वास्थ्य कार्यबल और अन्य मानव संसाधनों के साथ-साथ उनके वितरण पर प्रभाव)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "बड़े लाभकारी प्रभाव",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "मध्यम लाभकारी प्रभाव",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "नगण्य लाभकारी और प्रतिकूल प्रभाव",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "मध्यम प्रतिकूल प्रभाव",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "बड़े प्रतिकूल प्रभाव",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "हस्तक्षेप के <strong>निहितार्थ</strong> <strong>स्वास्थ्य प्रणाली के बुनियादी ढांचे और व्यापक बुनियादी ढांचे के लिए</strong> क्या हैं? (इसमें गैर-मानव संसाधनों और बुनियादी ढांचे के साथ-साथ उनके वितरण की आवश्यकता, उपयोग और प्रभाव पर विचार शामिल होना चाहिए)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "सभी या अधिकांश हितधारकों के दृष्टिकोण से एक विकल्प की व्यवहार्यता जितनी अधिक होगी, हस्तक्षेप के पक्ष में एक सामान्य सिफारिश की संभावना उतनी ही अधिक होगी। समग्र रूप से स्वास्थ्य प्रणाली के लिए जितने अधिक लाभप्रद प्रभाव होंगे, हस्तक्षेप के पक्ष में एक सामान्य सिफारिश की संभावना उतनी ही अधिक होगी।",
          "options": [
            {
              "text": "नहीं",
              "value": "no"
            },
            {
              "text": "शायद नहीं",
              "value": "probably_not"
            },
            {
              "text": "ढुलमुल",
              "value": "uncertain"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "हां",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "feasible": {
          "description": "to identify and measure, and not susceptible to manipulation",
          "options": [],
          "ratingOptions": [
            "very_feasible",
            "feasible",
            "least_feasible"
          ],
          "selectedOptions": ""
        },
        "financialAndEconomicConsiderations": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या वित्तीय और आर्थिक विचार हस्तक्षेप या तुलनाकारी के पक्ष में?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": " क्या <strong> हस्तक्षेप को लागू करने और बनाए रखने की लागत और बजट </strong> प्रभाव हस्तक्षेप या तुलना के पक्ष में हैं? (इसमें इस बात पर विचार शामिल होना चाहिए कि अल्पावधि बनाम लंबी अवधि में लागत और बजट प्रभाव कैसे भिन्न होते हैं।)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या <strong>अर्थव्यवस्था पर हस्तक्षेप का समग्र प्रभाव</strong> हस्तक्षेप या तुलना के पक्ष में है? (इसमें इस बात पर विचार शामिल होना चाहिए कि विभिन्न क्षेत्रों या संगठनात्मक स्तरों पर विभिन्न प्रकार के आर्थिक प्रभाव कैसे वितरित किए जाते हैं कि क्या हस्तक्षेप व्यापक विकास और गरीबी कम करने के लक्ष्यों की उपलब्धि में योगदान देता है या सीमित करता है, और यह कैसे उपलब्ध कार्यबल को प्रभावित करता है।)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या <strong>लागतों और लाभों का अनुपात</strong> (उदा. लागत-प्रभावशीलता, लागत-लाभ या लागत-उपयोगिता के अनुमानों के आधार पर) हस्तक्षेप या तुलना के पक्ष में है?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "एक हस्तक्षेप के वित्तीय और आर्थिक प्रभाव जितना अधिक फायदेमंद होगा, उसके पक्ष में एक सामान्य सिफारिश की संभावना अधिक होगी।",
          "options": [
            {
              "text": "तुलना के पक्षधर हैं",
              "value": "favors_the_comparison"
            },
            {
              "text": "शायद तुलना के पक्षधर हैं",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "शायद हस्तक्षेप के पक्षधर हैं",
              "value": "probably_favor_the_intervention"
            },
            {
              "text": "हस्तक्षेप का समर्थन करता है",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "स्वास्थ्य समानता, समानता और गैर-भेदभाव पर हस्तक्षेप का क्या प्रभाव होगा?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या हस्तक्षेप से स्वास्थ्य की स्थिति या इसके निर्धारकों में मौजूदा असमानताओं और/या असमानताओं में वृद्धि होने की संभावना है? (इसमें समय के साथ असमानताओं में संभावित परिवर्तनों पर विचार शामिल होना चाहिए, उदाहरण के लिए क्या प्रारंभिक वृद्धि समय के साथ संतुलित होने की संभावना है, जैसे हस्तक्षेप को बढ़ाया जाता है?)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या हस्तक्षेप के लाभ और हानि को समान रूप से वितरित किए जाने की संभावना है? (इसमें कमजोर, हाशिए पर या अन्यथा सामाजिक रूप से वंचित जनसंख्या समूहों के लिए निहितार्थ पर विशेष ध्यान देना शामिल होना चाहिए।)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या प्रभावित जनसंख्या समूहों के बीच हस्तक्षेप वहनीय है, और इसलिए आर्थिक रूप से सुलभ है? (इसमें विनाशकारी स्वास्थ्य व्यय और स्वास्थ्य संबंधी वित्तीय जोखिमों के जोखिम सहित घरेलू स्वास्थ्य व्यय पर प्रभाव शामिल होना चाहिए।)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या प्रभावित जनसंख्या समूहों के बीच हस्तक्षेप सुलभ है? (इसमें भौतिक और साथ ही सूचनात्मक पहुंच के संबंध में विचार शामिल होने चाहिए।)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या हस्तक्षेप विशेष रूप से गंभीर (जैसे जीवन के लिए खतरा, जीवन का अंत, कम पहले से मौजूद स्वास्थ्य स्थिति वाले व्यक्तियों को प्रभावित करना) या दुर्लभ स्थिति को संबोधित करता है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या हस्तक्षेप एकमात्र उपलब्ध विकल्प का प्रतिनिधित्व करता है? (इसमें यह विचार शामिल होना चाहिए कि क्या हस्तक्षेप आवश्यकता के अनुपात में है, और क्या यह आवधिक समीक्षा के अधीन होगा।)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "इस बात की संभावना जितनी अधिक होगी कि हस्तक्षेप स्वास्थ्य समानता और/या समानता को बढ़ाता है और यह किसी विशेष समूह के खिलाफ भेदभाव को कम करता है, इस हस्तक्षेप के पक्ष में एक सामान्य सिफारिश की संभावना उतनी ही अधिक होती है।",
          "options": [
            {
              "text": "नकारात्मक",
              "value": "negative"
            },
            {
              "text": "शायद नकारात्मक",
              "value": "probably_negative"
            },
            {
              "text": "न नकारात्मक और न ही सकारात्मक",
              "value": "neither_negative_nor_positive"
            },
            {
              "text": "शायद सकारात्मक",
              "value": "probably_positive"
            },
            {
              "text": "सकारात्मक",
              "value": "positive"
            }
          ],
          "selectedOption": ""
        },
        "humanRights": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या हस्तक्षेप सार्वभौमिक मानवाधिकार मानकों और सिद्धांतों के अनुसार है?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "सभी सिफारिशें सार्वभौमिक मानवाधिकार मानकों और सिद्धांतों के अनुसार होनी चाहिए।",
          "options": [
            {
              "text": "नहीं",
              "value": "no"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "ढुलमुल",
              "value": "uncertain"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "हां",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problem": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या समस्या प्राथमिकता है?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या समस्या के परिणाम गंभीर हैं (अर्थात संभावित लाभ या बचत के संदर्भ में गंभीर या महत्वपूर्ण)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या समस्या अत्यावश्यक है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "नहीं",
                    "value": "no"
                  },
                  {
                    "text": "संभवतः नहीँ",
                    "value": "probably_no"
                  },
                  {
                    "text": "शायद हां",
                    "value": "probably_yes"
                  },
                  {
                    "text": "हां",
                    "value": "yes"
                  }
                ],
                "question": "क्या यह एक मान्यता प्राप्त प्राथमिकता है (उदाहरण के लिए राजनीतिक या नीतिगत निर्णय के आधार पर)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "कोई समस्या जितनी गंभीर या अत्यावश्यक होती है, उतनी ही अधिक संभावना है कि समस्या का समाधान करने वाला विकल्प प्राथमिकता होगी",
          "options": [
            {
              "text": "नहीं",
              "value": "no"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "हां",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "qualityOfEvidence": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "सबूत की समग्र गुणवत्ता क्या है?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "WHO-INTEGRATE ढांचे में विभिन्न मानदंडों में साक्ष्य की गुणवत्ता जितनी अधिक होगी, सामान्य अनुशंसा की संभावना उतनी ही अधिक होगी।",
          "options": [
            {
              "text": "बहुत कम",
              "value": "very_low"
            },
            {
              "text": "कम",
              "value": "low"
            },
            {
              "text": "संयत",
              "value": "moderate"
            },
            {
              "text": "उच्च",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "relevantDirect": {
          "description": "to the selected problem and in the field of application",
          "options": [],
          "ratingOptions": [
            "very_relevant",
            "relevant",
            "least_relevant"
          ],
          "selectedOptions": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "संसाधन आवश्यकताएं (लागत) कितनी बड़ी हैं?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "मामूली",
                    "value": "trivial"
                  },
                  {
                    "text": "छोटा",
                    "value": "small"
                  },
                  {
                    "text": "संयत",
                    "value": "moderate"
                  },
                  {
                    "text": "बड़ा",
                    "value": "large"
                  }
                ],
                "question": "संसाधन की प्रत्येक मद में कितना अंतर है जिसके लिए कम संसाधनों की आवश्यकता होती है?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "बड़ा",
                    "value": "large"
                  },
                  {
                    "text": "संयत",
                    "value": "moderate"
                  },
                  {
                    "text": "छोटा",
                    "value": "small"
                  },
                  {
                    "text": "मामूली",
                    "value": "trivial"
                  }
                ],
                "question": "संसाधन उपयोग की प्रत्येक मद में कितना अधिक अंतर है जिसके लिए अधिक संसाधनों की आवश्यकता हो सकती है",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "हस्तक्षेप और तुलना के बीच संसाधन उपयोग में अंतर की लागत कितनी बड़ी है?",
          "options": [
            {
              "text": "बड़ी लागत",
              "value": "large_costs"
            },
            {
              "text": "मध्यम लागत",
              "value": "moderate_costs"
            },
            {
              "text": "नगण्य लागत और बचत",
              "value": "negligible_costs_savings"
            },
            {
              "text": "मध्यम बचत",
              "value": "moderate_savings"
            },
            {
              "text": "बड़ी बचत",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
          "ratingOptions": [
            "less_costs",
            "intermediate_costs",
            "most_costs"
          ],
          "selectedOption": ""
        },
        "responsive": {
          "description": "sensitive to change that means they should be able to capture possible changes in the system",
          "options": [],
          "ratingOptions": [
            "very_responsive",
            "responsive",
            "least_responsive"
          ],
          "selectedOptions": ""
        },
        "scientificallySound": {
          "description": "based on evidence (GRADE criteria) and strongly correlated with the quality of care provided",
          "options": [],
          "ratingOptions": [
            "very_scientifically_sound",
            "scientifically_sound",
            "least_scientifically_sound"
          ],
          "selectedOptions": ""
        },
        "societalImplications": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या वांछनीय और अवांछनीय सामाजिक प्रभावों के बीच संतुलन हस्तक्षेप या तुलना के पक्ष में है?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या हस्तक्षेप के सामाजिक प्रभाव और सामाजिक परिणाम (जैसे कलंक में वृद्धि या कमी, शैक्षिक परिणाम, सामाजिक सामंजस्य, या स्वास्थ्य से परे विभिन्न मानवाधिकारों की प्राप्ति) हस्तक्षेप या तुलना के पक्ष में हैं?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या पर्यावरणीय प्रभाव और पारिस्थितिक स्थिरता के लिए निहितार्थ (जैसे प्राकृतिक संसाधनों की सुरक्षा, शमन या जलवायु परिवर्तन के लिए अनुकूलन) हस्तक्षेप या तुलना के पक्ष में हैं?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "एक हस्तक्षेप से जुड़ा शुद्ध सामाजिक लाभ जितना अधिक होगा, इस हस्तक्षेप के पक्ष में एक सामान्य सिफारिश की संभावना उतनी ही अधिक होगी।",
          "options": [
            {
              "text": "तुलना के पक्षधर हैं",
              "value": "favors_the_comparison"
            },
            {
              "text": "शायद तुलना के पक्षधर हैं",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "शायद हस्तक्षेप के पक्षधर हैं",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "हस्तक्षेप का समर्थन करता है",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "socioCulturalAcceptability": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या प्रमुख हितधारकों को हस्तक्षेप स्वीकार्य है?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "बड़ा",
                    "value": "large"
                  },
                  {
                    "text": "संयत",
                    "value": "moderate"
                  },
                  {
                    "text": "छोटा",
                    "value": "small"
                  },
                  {
                    "text": "मामूली",
                    "value": "trivial"
                  }
                ],
                "question": "व्यक्तिगत स्वतंत्रता (गोपनीयता और गरिमा सहित) के उल्लंघन के मामले में हस्तक्षेप की घुसपैठ कितनी महत्वपूर्ण है? (घुसपैठ तुच्छ से होती है - उदाहरण के लिए विकल्प को सक्षम करने के माध्यम से (उदाहरण के लिए साइकिल पथ बनाना) उच्च तक - उदाहरण के लिए पसंद को प्रतिबंधित या समाप्त करके (उदाहरण के लिए सिगरेट पर प्रतिबंध लगाना)।",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "बड़ा",
                    "value": "large"
                  },
                  {
                    "text": "संयत",
                    "value": "moderate"
                  },
                  {
                    "text": "छोटा",
                    "value": "small"
                  },
                  {
                    "text": "मामूली",
                    "value": "trivial"
                  }
                ],
                "question": "व्यक्तियों, जनसंख्या समूहों और/या संगठनों की स्वायत्तता पर हस्तक्षेप का प्रभाव कितना महत्वपूर्ण है (एक सक्षम, सूचित और स्वैच्छिक निर्णय लेने की उनकी क्षमता के संबंध में)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या लक्षित लाभार्थियों के बीच हस्तक्षेप की सामाजिक-सांस्कृतिक स्वीकार्यता हस्तक्षेप या तुलना के पक्ष में है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या हस्तक्षेप को लागू करने के इरादे से हस्तक्षेप की सामाजिक-सांस्कृतिक स्वीकार्यता हस्तक्षेप या तुलना के पक्ष में है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या अन्य प्रासंगिक हितधारक समूहों के बीच हस्तक्षेप की सामाजिक-सांस्कृतिक स्वीकार्यता हस्तक्षेप या तुलना के पक्ष में है?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "तुलना के पक्षधर हैं",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "शायद तुलना के पक्षधर हैं",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "हस्तक्षेप या तुलना के पक्ष में नहीं है",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "शायद हस्तक्षेप के पक्षधर हैं",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "हस्तक्षेप का समर्थन करता है",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "क्या आम जनता के बीच हस्तक्षेप की सामाजिक-सांस्कृतिक स्वीकार्यता हस्तक्षेप या तुलना के पक्ष में है?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "सभी या सबसे अधिक प्रासंगिक हितधारकों के लिए एक हस्तक्षेप की सामाजिक-सांस्कृतिक स्वीकार्यता जितनी अधिक होगी, इस हस्तक्षेप के पक्ष में एक सामान्य सिफारिश की संभावना उतनी ही अधिक होगी।",
          "options": [
            {
              "text": "नहीं",
              "value": "no"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "ढुलमुल",
              "value": "uncertain"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "हां",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "परीक्षण कितना सटीक है?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "बहुत अयथार्थ",
              "value": "very_inaccurate"
            },
            {
              "text": "अयथार्थ",
              "value": "inaccurate"
            },
            {
              "text": "सटीक",
              "value": "accurate"
            },
            {
              "text": "बहुत सटीक",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": " विविध",
              "value": "varies"
            },
            {
              "text": "पता नहीं",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "अवांछित प्रत्याशित प्रभाव कितने महत्वपूर्ण हैं?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": " विविध",
                    "value": "varies"
                  },
                  {
                    "text": "पता नहीं",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "बड़ा",
                    "value": "large"
                  },
                  {
                    "text": "संयत",
                    "value": "moderate"
                  },
                  {
                    "text": "छोटा",
                    "value": "small"
                  },
                  {
                    "text": "मामूली",
                    "value": "trivial"
                  }
                ],
                "question": "प्रत्येक मुख्य परिणाम के लिए प्रत्याशित प्रभाव (अंतर) कितना पर्याप्त है जिसके लिए अवांछनीय प्रभाव है?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "परिणामों के महत्व को ध्यान में रखते हुए हस्तक्षेप के अवांछनीय प्रभाव कितने बड़े हैं (उन्हें कितना महत्व दिया जाता है), और प्रभाव का आकार (लाभ का अनुभव करने की संभावना या कितना सुधार व्यक्तियों को अनुभव होने की संभावना होगी )?",
          "options": [
            {
              "text": "बड़ा",
              "value": "large"
            },
            {
              "text": "संयत",
              "value": "moderate"
            },
            {
              "text": "छोटा",
              "value": "small"
            },
            {
              "text": "मामूली",
              "value": "trivial"
            }
          ],
          "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
          "ratingOptions": [
            "least_harmful",
            "intermediate",
            "more_harmful"
          ],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "क्या इस बारे में महत्वपूर्ण अनिश्चितता या परिवर्तनशीलता है कि लोग मुख्य परिणामों को कितना महत्व देते हैं?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "महत्वपूर्ण अनिश्चितता",
                    "value": "important"
                  },
                  {
                    "text": "संभवतः महत्वपूर्ण अनिश्चितता",
                    "value": "probably_important"
                  },
                  {
                    "text": "शायद कोई महत्वपूर्ण अनिश्चितता नहीं",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "कोई महत्वपूर्ण अनिश्चितता नहीं",
                    "value": "no_important"
                  }
                ],
                "question": "क्या इस बारे में महत्वपूर्ण अनिश्चितता है कि लोग मुख्य परिणामों को कितना महत्व देते हैं?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "प्रत्येक मुख्य परिणाम को व्यक्ति कितना महत्व देते हैं? क्या इस बारे में अनिश्चितता है कि वे प्रत्येक परिणाम या परिवर्तनशीलता को कितना महत्व देते हैं कि कितने अलग-अलग व्यक्ति परिणामों को इतना बड़ा मानते हैं कि इससे अलग-अलग निर्णय हो सकते हैं?",
          "options": [
            {
              "text": "महत्वपूर्ण अनिश्चितता या परिवर्तनशीलता",
              "value": "important_uncertainty"
            },
            {
              "text": "संभवतः महत्वपूर्ण अनिश्चितता या परिवर्तनशीलता",
              "value": "possible_important"
            },
            {
              "text": "शायद कोई महत्वपूर्ण अनिश्चितता या परिवर्तनशीलता नहीं है",
              "value": "probably_no_important"
            },
            {
              "text": "कोई महत्वपूर्ण अनिश्चितता या परिवर्तनशीलता नहीं",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "स्वीकार्यता",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "रीति मापदंड",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "प्रभावों का संतुलन",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
          "criterionId": "balanceOfHealthBenefitsAndHarms",
          "name": "स्वास्थ्य लाभ और हानि का संतुलन",
          "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "प्रभाव की निश्चितता",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "साक्ष्य की निश्चितता",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "प्रबंधन के प्रभावों के प्रमाण की निश्चितता",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "आवश्यक संसाधनों के साक्ष्य की निश्चितता",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "परीक्षण सटीकता के प्रमाण की निश्चितता",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "परीक्षण के प्रभावों के प्रमाण की निश्चितता",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "परीक्षा परिणाम/प्रबंधन के साक्ष्य की निश्चितता",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "लागत प्रभावशीलता",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "वांछनीय प्रभाव",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "निष्पक्षता",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "साध्यता",
          "researchEvidenceId": "feasibility"
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
          "criterionId": "feasibilityAndHealthSystemConsiderations",
          "name": "साध्यता और स्वास्थ्य प्रणाली के विचार",
          "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
        },
        "feasible": {
          "additionalConsiderationId": "feasible",
          "criterionId": "feasible",
          "name": "FEASIBLE",
          "researchEvidenceId": "feasible"
        },
        "financialAndEconomicConsiderations": {
          "additionalConsiderationId": "financialAndEconomicConsiderations",
          "criterionId": "financialAndEconomicConsiderations",
          "name": "वित्तीय और आर्थिक विचार",
          "researchEvidenceId": "financialAndEconomicConsiderations"
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
          "criterionId": "healthEquityEqualityAndNonDiscrimination",
          "name": "स्वास्थ्य समानता, बराबरी और निष्पक्ष",
          "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
        },
        "humanRights": {
          "additionalConsiderationId": "humanRights",
          "criterionId": "humanRights",
          "name": "मानव अधिकार",
          "researchEvidenceId": "humanRights"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "समस्या",
          "researchEvidenceId": "problem"
        },
        "qualityOfEvidence": {
          "additionalConsiderationId": "qualityOfEvidence",
          "criterionId": "qualityOfEvidence",
          "name": "साक्ष्य की गुणवत्ता",
          "researchEvidenceId": "qualityOfEvidence"
        },
        "relevantDirect": {
          "additionalConsiderationId": "relevantDirect",
          "criterionId": "relevantDirect",
          "name": "RELEVANT (DIRECT)",
          "researchEvidenceId": "relevantDirect"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "संसाधन आवश्यक",
          "researchEvidenceId": "resourcesRequired"
        },
        "responsive": {
          "additionalConsiderationId": "responsive",
          "criterionId": "responsive",
          "name": "RESPONSIVE",
          "researchEvidenceId": "responsive"
        },
        "scientificallySound": {
          "additionalConsiderationId": "scientificallySound",
          "criterionId": "scientificallySound",
          "name": "SCIENTIFICALLY SOUND (CERTAINTY OF EVIDENCE)",
          "researchEvidenceId": "scientificallySound"
        },
        "societalImplications": {
          "additionalConsiderationId": "societalImplications",
          "criterionId": "societalImplications",
          "name": "सामाजिक निहितार्थ",
          "researchEvidenceId": "societalImplications"
        },
        "socioCulturalAcceptability": {
          "additionalConsiderationId": "socioCulturalAcceptability",
          "criterionId": "socioCulturalAcceptability",
          "name": "सामाजिक-सांस्कृतिक स्वीकार्यता",
          "researchEvidenceId": "socioCulturalAcceptability"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": "परीक्षण सटीकता",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "अवांछनीय प्रभाव",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "मूल्यों",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "benefits": {
          "content": "",
          "description": "लाभ"
        },
        "clearAndActionable": {
          "content": "",
          "description": "स्पष्ट और कार्रवाई योग्य",
          "options": [
            {
              "text": "हां",
              "value": "yes"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "नहीं",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "clinicalConsiderations": {
          "content": "",
          "description": "Clinical considerations"
        },
        "collectingAndSummarizing": {
          "content": "",
          "description": "साक्ष्य एकत्र करना और सारांशित करना दिशानिर्देश पैनल के सीमित समय और ऊर्जा (बड़े पैमाने पर अवसर लागत) का खराब उपयोग है।",
          "options": [
            {
              "text": "हां",
              "value": "yes"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "नहीं",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "considerations": {
          "content": "",
          "description": "विचार"
        },
        "contextAndSystemConsiderations": {
          "content": "",
          "description": "संदर्भ और सिस्टम विचार"
        },
        "decision": {
          "content": "",
          "description": "फेसला",
          "options": [
            {
              "text": "पूर्ण कार्यान्वयन",
              "value": "full_implementation"
            },
            {
              "text": "प्रभाव मूल्यांकन",
              "value": "impact_evaluation"
            },
            {
              "text": "मूल अध्ययन",
              "value": "pilot_study"
            },
            {
              "text": "टाल देना",
              "value": "postpone"
            },
            {
              "text": "लागू न करें",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "निर्णय का प्रकार",
          "options": [
            {
              "direction": "negative",
              "text": "कवर न करें",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "सबूत के विकास के साथ कवर करें",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "मूल्य वार्ता के साथ कवर करें",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "प्रतिबंधित कवरेज",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "कवर करें",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "निर्णय का प्रकार",
          "options": [
            {
              "direction": "negative",
              "text": "विकल्प को लागू न करें",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "फैसला टालें",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "विकल्प का प्रायोगिक अध्ययन करें",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "प्रभाव मूल्यांकन के साथ विकल्प को लागू करें",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "विकल्प लागू करें",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "evidence": {
          "content": "",
          "description": "सबूत"
        },
        "frequencyDoseDuration": {
          "content": "",
          "description": "आवृत्ति/खुराक/अवधि"
        },
        "goodPracticeStatement": {
          "content": "",
          "description": "अच्छा अभ्यास कथन"
        },
        "harms": {
          "content": "",
          "description": "नुकसान"
        },
        "implementationConsiderations": {
          "content": "",
          "description": "कार्यान्वयन विचार",
          "details": {
            "content": "",
            "description": "विस्तृत कार्यान्वयन"
          }
        },
        "implementationConsiderationsOfAllRelevantOutcomes": {
          "content": "",
          "description": "सभी प्रासंगिक परिणामों और संभावित डाउनस्ट्रीम परिणामों पर विचार करने के बाद, अच्छे अभ्यास कथन को लागू करने से बड़े शुद्ध सकारात्मक परिणाम प्राप्त होते हैं",
          "options": [
            {
              "text": "हां",
              "value": "yes"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "नहीं",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderationsOrRemarks": {
          "content": "",
          "description": "कार्यान्वयन विचार",
          "options": [
            {
              "text": "हां",
              "value": "yes"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "नहीं",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "indications": {
          "content": "",
          "description": "संकेत"
        },
        "indicationsForDiscontinuation": {
          "content": "",
          "description": "बंद करने के संकेत"
        },
        "justification": {
          "content": "",
          "description": "औचित्य",
          "details": {
            "description": "विस्तृत औचित्य"
          },
          "overallDescription": "कुल मिलाकर औचित्य"
        },
        "keyMessage": {
          "content": "",
          "description": "प्रमुख संदेश"
        },
        "limitations": {
          "content": "",
          "description": "सीमाये"
        },
        "mdgRecommendation": {
          "content": "",
          "description": "अनुशंसा",
          "options": [
            {
              "direction": "positive",
              "text": "अनुशंसित",
              "value": "recommend"
            },
            {
              "direction": "negative",
              "text": "अनुशंसित नहीं की गई",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Sometimes Recommended",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "कोई अनुशंसा नहीं",
              "value": "suggest_either"
            }
          ],
          "selectedOption": ""
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "जाचना और परखना",
          "details": {
            "content": "",
            "description": "विस्तृत निगरानी और मूल्यांकन"
          }
        },
        "monitoringAndEvaluationQI": {
          "content": "",
          "description": "जाचना और परखना"
        },
        "multipleChronicConditionsAndPolypharmacy": {
          "content": "",
          "description": "एकाधिक पुरानी स्थितियां और पॉलीफार्मेसी"
        },
        "multipleRecommendations": {
          "description": "अनुशंसा(एं)",
          "options": [
            {
              "direction": "negative",
              "text": "हस्तक्षेप के खिलाफ कड़ी अनुशंसा",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "हस्तक्षेप के खिलाफ सशर्त अनुशंसा",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "हस्तक्षेप या तुलना के लिए सशर्त अनुशंसा",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "हस्तक्षेप के लिए सशर्त अनुशंसा",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "हस्तक्षेप के लिए मजबूत अनुशंसा",
              "value": "recommend"
            }
          ],
          "recommendations": [
            {
              "content": "",
              "selectedOption": ""
            }
          ]
        },
        "rationale": {
          "content": "",
          "description": "तर्काधार"
        },
        "rationaleConnectingIndirectEvidence": {
          "content": "",
          "description": "अप्रत्यक्ष साक्ष्य को जोड़ने वाला एक अच्छी तरह से प्रलेखित स्पष्ट और स्पष्ट तर्क है",
          "options": [
            {
              "text": "हां",
              "value": "yes"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "नहीं",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "recommendation": {
          "content": "",
          "description": "अनुशंसा"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "अनुशंसा का प्रकार",
          "options": [
            {
              "direction": "negative",
              "text": "हस्तक्षेप के खिलाफ कड़ी अनुशंसा",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "हस्तक्षेप के खिलाफ सशर्त अनुशंसा",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "हस्तक्षेप या तुलना के लिए सशर्त अनुशंसा",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "हस्तक्षेप के लिए सशर्त अनुशंसा",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "हस्तक्षेप के लिए मजबूत अनुशंसा",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "अनुशंसा का प्रकार",
          "options": [
            {
              "direction": "negative",
              "text": "विकल्प के खिलाफ जोरदार अनुशंसा",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "विकल्प के खिलाफ सशर्त अनुशंसा",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "विकल्प या तुलना के लिए सशर्त अनुशंसा",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "विकल्प के लिए सशर्त अनुशंसा",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "विकल्प के लिए मजबूत अनुशंसा",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "अनुसंधान प्राथमिकताएं"
        },
        "restrictions": {
          "content": "",
          "description": "प्रतिबंध"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "उपसमूह विचार"
        },
        "theMessageIsNecessary": {
          "content": "",
          "description": "वास्तविक स्वास्थ्य देखभाल अभ्यास के संबंध में संदेश आवश्यक है",
          "options": [
            {
              "text": "हां",
              "value": "yes"
            },
            {
              "text": "शायद हां",
              "value": "probably_yes"
            },
            {
              "text": "संभवतः नहीँ",
              "value": "probably_no"
            },
            {
              "text": "नहीं",
              "value": "no"
            }
          ],
          "selectedOption": ""
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "चिकित्सकों",
          "sections": {
            "background": {
              "name": "पृष्ठभूमि"
            },
            "detailedJustification": {
              "name": "विस्तृत औचित्य"
            },
            "implementationConsiderations": {
              "name": "कार्यान्वयन विचार"
            },
            "justification": {
              "name": "औचित्य"
            },
            "relatedRecommendations": {
              "name": "संबंधित अनुशंसा"
            },
            "subgroupConsiderations": {
              "name": "उपसमूह विचार"
            },
            "summaryOfFindings": {
              "name": "अनुसन्धान का सारांश"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "मरीजों",
          "sections": {
            "relatedRecommendations": {
              "name": "संबंधित अनुशंसा"
            },
            "summaryOfFindings": {
              "name": "अनुसन्धान का सारांश"
            },
            "whatItMeansForYou": {
              "name": "इसका आपके लिए क्या मतलब है?",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "अपने स्वास्थ्य देखभाल पेशेवर से बात करें"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "आप क्या कर सकते हैं"
                }
              }
            },
            "whoIsThisFor": {
              "name": "ये किसके लिए है",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "ये किसके लिए है"
                }
              }
            },
            "whyThisRecommendation": {
              "name": "यह अनुशंसा क्यों?",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "अतिरिक्त जानकारी"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "लाभ और हानि"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "यह अनुशंसा क्यों"
                }
              }
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "नीति निर्माताओं",
          "sections": {
            "assessment": {
              "name": "मूल्यांकन"
            },
            "background": {
              "description": "सिफारिश के लिए प्रश्न विवरण",
              "details": {
                "name": "इस निर्णय के बारे में",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "तारीख"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "निर्णयकर्ता"
                  },
                  "perspective": {
                    "content": "",
                    "description": "परिप्रेक्ष्य"
                  },
                  "setting": {
                    "content": "",
                    "description": "पतिस्थिति"
                  }
                },
                "sectionsOrder": [
                  "setting",
                  "perspective",
                  "decisionMakers",
                  "date"
                ]
              },
              "name": "पृष्ठभूमि"
            },
            "decision": {
              "content": "",
              "name": "फेसला",
              "options": [
                {
                  "text": "पूर्ण कार्यान्वयन",
                  "value": "full_implementation"
                },
                {
                  "text": "प्रभाव मूल्यांकन",
                  "value": "impact_evaluation"
                },
                {
                  "text": "मूल अध्ययन",
                  "value": "pilot_study"
                },
                {
                  "text": "टाल देना",
                  "value": "postpone"
                },
                {
                  "text": "लागू न करें",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "कार्यान्वयन"
            },
            "justification": {
              "name": "औचित्य"
            },
            "monitoringAndEvaluation": {
              "name": "निगरानी और मूल्यांकन"
            },
            "relatedRecommendations": {
              "name": "संबंधित अनुशंसा"
            },
            "summaryOfFindings": {
              "name": "अनुसन्धान का सारांश"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "age": {
          "content": "",
          "name": "उम्र"
        },
        "anticipatedOutcomes": {
          "content": "",
          "name": "प्रत्याशित परिणाम"
        },
        "background": {
          "content": "",
          "name": "पृष्ठभूमि"
        },
        "coi": {
          "content": "",
          "name": "रुचियों का भेद"
        },
        "comparison": {
          "content": "",
          "name": "तुलना"
        },
        "intent": {
          "content": "",
          "name": "इरादा"
        },
        "intervention": {
          "content": "",
          "name": "हस्तक्षेप"
        },
        "linkedTreatments": {
          "content": "",
          "name": "लिंक्ड उपचार"
        },
        "mainOutcomes": {
          "content": "",
          "name": "मुख्य परिणाम"
        },
        "option": {
          "content": "",
          "name": "विकल्प"
        },
        "perspective": {
          "content": "",
          "name": "परिप्रेक्ष्य"
        },
        "population": {
          "content": "",
          "name": "जनसंख्या"
        },
        "problem": {
          "content": "",
          "name": "समस्या"
        },
        "purpose": {
          "content": "",
          "name": "परीक्षण का उद्देश्य"
        },
        "requirements": {
          "content": "",
          "name": "Requirements"
        },
        "role": {
          "content": "",
          "name": "परीक्षण की भूमिका"
        },
        "setting": {
          "content": "",
          "name": "पतिस्थिति"
        },
        "subgroups": {
          "content": "",
          "name": "उपसमूहों"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
