Spinner = require 'components/common/spinner'
QuestionsListMixin = require 'components/mixins/questions_list_mixin'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ConnectStore = require 'components/enhancers/connect_store'
QuestionsListComponentStore = require 'stores/questions_list_component_store'
AnimatedCollectionItem = require 'components/enhancers/animated_collection_item'
DynamicHeightContainer = require 'components/common/dynamic_height_container'
{ childrenTypes } = require 'lib/react_children_validation_utils'
{ func, instanceOf, string, bool, oneOfType, object } = PropTypes
{ TransitionGroup } = ReactTransitionGroup
{ MDG_QUESTION } = require 'lib/db_docs/doc_types'

#
# QUESTIONS LIST QUESTION
#

QuestionsListQuestion = createReactClass
  displayName: 'QuestionsListQuestion'

  render: ->
    <div className='questions-list-container__question'>
      {@props.children}
    </div>

WrappedQuestionsListQuestion = AnimatedCollectionItem QuestionsListQuestion,
  animationsDuration: 300

#
# QUESTIONS LIST GROUP
#

QuestionsListGroup = createReactClass
  displayName: 'QuestionsListGroup'

  propTypes:
    caption: string
    groupId: string.isRequired
    children: childrenTypes(WrappedQuestionsListQuestion)

  render: ->
    { caption, children } = @props
    containerClassName = classNames 'questions-list-container__questions-group',
      'with-caption': caption?
    questionsContainerClassName = classNames 'questions-list-container__questions-container',
      'empty': React.Children.count(children) is 0

    <div className={containerClassName}>
      <div className='questions-list-container__group-title'>
        {caption}
      </div>
      <div className={questionsContainerClassName}>
        <DynamicHeightContainer>
          <TransitionGroup component='div'>
            {children}
          </TransitionGroup>
        </DynamicHeightContainer>
      </div>
    </div>

#
# QUESTIONS LIST
#

storeConnector =
  QuestionsListComponentStore: (Store) ->
    questionsByGroup: Store.getQuestionsByGroup withEmptyGroups: true
    isFetchingData: Store.isFetchingData()

QuestionsList = createReactClass
  displayName: 'QuestionsList'

  propTypes:
    questionsByGroup: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    questions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    questionGroups: instanceOf(Immutable.Map)
    overarchingQuestions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ])
    mdgQuestionComponent: func
    overarchingQuestionComponent: func
    displayOverarchingQuestions: bool
    isFetchingData: bool.isRequired
    questionGroups: instanceOf(Immutable.Map)
    withEmptyQuestionGroups: bool
    additionalProps: object
    passAdditionalProps: bool

  getDefaultProps: ->
    passAdditionalProps: false
    displayOverarchingQuestions: false
    mdgQuestionComponent: null
    overarchingQuestionComponent: null
    overarchingQuestions: Immutable.OrderedMap()

  mixins: [
    CustomRenderMixin,
    QuestionsListMixin
  ]

  renderQuestion: (questionComponent) -> (q) =>
    qId = q.get '_id'
    { additionalProps, mdgQuestionComponent: MDGQuestion, passAdditionalProps } = @props

    if q.get('docType') is MDG_QUESTION
      return null unless MDGQuestion
      componentProps = id: qId, question: q.get 'question'
      componentProps['additionalProps'] = additionalProps if passAdditionalProps
      <WrappedQuestionsListQuestion key={qId} qId={qId}>
        <MDGQuestion {...componentProps} />
      </WrappedQuestionsListQuestion>
    else
      componentProps = { data: q }
      componentProps['additionalProps'] = additionalProps if passAdditionalProps

      <WrappedQuestionsListQuestion key={qId} qId={qId}>
        {React.cloneElement questionComponent, componentProps}
      </WrappedQuestionsListQuestion>

  renderOverarchingQuestion: (question) ->
    {
      overarchingQuestionComponent: OverarchingQuestion,
      additionalProps,
      passAdditionalProps
    } = @props
    return null unless OverarchingQuestion
    id = question.get '_id'
    questionText = question.get 'question'
    propsToPass = { id, question: questionText, key: id }
    propsToPass.additionalProps = additionalProps if passAdditionalProps
    <OverarchingQuestion data={question} {...propsToPass} />

  renderQuestionGroup: (questionComponent) -> (group) =>
    groupId = group.get '_id'
    caption = group.get 'caption'
    questions = group.get 'questions'
    {
      overarchingQuestions
      displayOverarchingQuestions
      withEmptyQuestionGroups
    } = @props
    overarchingQuestionsForGroup = if displayOverarchingQuestions
      overarchingQuestions.getIn([groupId, 'questions']) ? Immutable.OrderedMap()
    else
      Immutable.OrderedMap()

    return null if not withEmptyQuestionGroups and
      questions.isEmpty() and
      overarchingQuestionsForGroup.isEmpty()

    <QuestionsListGroup
      caption={caption}
      groupId={groupId}
      key={groupId}
    >
      {displayOverarchingQuestions and
        overarchingQuestionsForGroup.map(@renderOverarchingQuestion).valueSeq()
      }
      {questions.map(@renderQuestion(questionComponent)).toArray()}
    </QuestionsListGroup>


  render: ->
    { questionsByGroup, isFetchingData } = @props
    questionComponent = React.Children.only @props.children

    if isFetchingData
      <div className='questions-list-container'>
        <Spinner />
      </div>
    else
      <div className='questions-list-container'>
        <div className='items'>
          {questionsByGroup.map(@renderQuestionGroup(questionComponent)).toArray()}
        </div>
      </div>

module.exports = ConnectStore QuestionsList, QuestionsListComponentStore, storeConnector
