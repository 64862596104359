var MDA_TOPIC, MdaTopics, MdaTopicsActions, MdaTopicsToolbar, MdaTopicsView, QuestionsListComponentActions, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

MdaTopics = require('components/mda/topics/list');

MdaTopicsActions = require('actions/mda_topics_actions');

MdaTopicsToolbar = require('components/mda/topics/toolbar');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

MDA_TOPIC = require('lib/db_docs/doc_types').MDA_TOPIC;

module.exports = MdaTopicsView = (function(_super) {
  __extends(MdaTopicsView, _super);

  function MdaTopicsView() {
    return MdaTopicsView.__super__.constructor.apply(this, arguments);
  }

  MdaTopicsView.prototype.container = '#page-container';

  MdaTopicsView.prototype.autoRender = true;

  MdaTopicsView.prototype.initialize = function() {
    MdaTopicsView.__super__.initialize.call(this);
    this.subview('toolbar', new ReactToolbarView({
      component: MdaTopicsToolbar
    }));
    return this.enable(ReactComponent);
  };

  MdaTopicsView.prototype.afterRender = function() {
    var projectId;
    MdaTopicsView.__super__.afterRender.call(this);
    projectId = mediator.project.id;
    return this.renderComponent(MdaTopics, QuestionsListComponentActions, null, {
      props: {
        projectId: mediator.project.id
      },
      fetchAndListen: {
        dbId: projectId
      },
      fetchParams: {
        questionsCollectionType: MDA_TOPIC,
        questionType: MDA_TOPIC
      }
    });
  };

  MdaTopicsView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('MdaTopicsStore', 'QuestionsStore', 'QuestionGroupsStore', 'MembersStore', 'QuestionsListComponentStore', 'QuestionsStatusesStore', 'OrganizationsStore', 'OverarchingQuestionsStore', 'MdaWorkflowDialogStore', 'QuestionGroupsStore');
    return MdaTopicsView.__super__.dispose.call(this);
  };

  return MdaTopicsView;

})(View);
