CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ string, bool, object, oneOfType, number } = PropTypes

DefaultCell = createReactClass
  displayName: 'DefaultCell'

  propTypes:
    disabled: bool
    content: oneOfType([string, number])
    style: object
    className: string
    id: string

  mixins: [CustomRenderMixin]

  render: ->
    cellProps = _.omit @props,
      'content', 'outcomeAttrs', 'questionType', 'isEditing', 'questionId', 'sourceId', 'id',
      'validate', 'allValid'

    <td {...cellProps}>
      <div className="cell-content">
        <span dangerouslySetInnerHTML={{__html: @props.content}} />
      </div>
    </td>

module.exports = DefaultCell
