OutcomesTableViewActions = require 'actions/outcomes_table_view_actions'
ValidatedInput = require 'components/common/validated_input'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
EditableCell = require 'components/evidence_syntheses/outcomes/cells/editable_cell'
{ CompoundEdit, CompoundEditBlock } =
  require 'components/evidence_syntheses/outcomes/cells/compound_edit'
{ getKeyValObject } = require 'base/lib/utils'


outcomeAttrNames = [ 'interventionCount', 'interventionTotal' ]
updateOutcomeAttribute = _.compose(
  OutcomesTableViewActions.updateEditedOutcomeAttributes
  getKeyValObject
)

InterventionCell = createReactClass
  displayName: 'InterventionCell'

  propTypes: {
    editMetadata: PropTypes.instanceOf(Immutable.Map)
    allValid: PropTypes.func.isRequired # provided by withValidation enhancer
    validate: PropTypes.func.isRequired # provided by withValidation enhancer
  }

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]

  _ref: (el) ->
    @el = el

  handleInputChange: (evt, validationError) ->
    outcomeAttrName = evt.target.getAttribute 'name'
    value = evt.target.value.trim()

    updateOutcomeAttribute outcomeAttrName, value

  handleApply: ->
    OutcomesTableViewActions.saveEditedAttributes()
    @resetEdit()

  resetEdit: ->
    @el.stopEdit()

  getCurrentValue: (attrName) ->
    @props.editMetadata?.getIn(['editedAttributes', attrName]) ? @props.outcomeAttrs[attrName]

  render: ->
    { outcomeAttrs, allValid, validate } = @props

    <EditableCell {...@props} ref={@_ref} title={@i18n 'intervention'}>
      <CompoundEdit applyEnabled={allValid()} onApply={@handleApply} onCancel={@resetEdit}>
        {if outcomeAttrs.type in ['dich', 'narrative']
          <CompoundEditBlock label={@i18n 'with_event'}>
            <ValidatedInput
              name='interventionCount'
              onChange={@handleInputChange}
              type='text'
              validation={validate 'interventionCount'}
              value={@getCurrentValue 'interventionCount'}
            />
          </CompoundEditBlock>
        }
        <CompoundEditBlock label={@i18n 'total'}>
          <ValidatedInput
            name='interventionTotal'
            onChange={@handleInputChange}
            type='text'
            validation={validate 'interventionTotal'}
            value={@getCurrentValue 'interventionTotal'}
          />
        </CompoundEditBlock>
      </CompoundEdit>
    </EditableCell>

module.exports = InterventionCell
