CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Button = require 'components/common/button'

SelectionButton = createReactClass
  displayName: 'SelectionButton'

  mixins: [CustomRenderMixin, OnClickOutside]

  propTypes:
    label: PropTypes.string.isRequired
    onElementSelected: PropTypes.func.isRequired
    btnClassName: PropTypes.string,
    listClassName: PropTypes.string,
    className: PropTypes.string,
    list: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.instanceOf(Immutable.List)
    ]).isRequired

  getInitialState: ->
    opened: @props.opened

  showSelectionList: ->
    @setState opened: true

  hideSelectionList: ->
    @setState opened: false

  componentDidMount: ->
    @buttonWidth =  ReactDOM.findDOMNode(@_selectionButton).offsetWidth

  listElementClicked: (value) -> =>
    @props.onElementSelected value
    @setState opened: false

  handleClickOutside: ->
    @hideSelectionList()

  render: ->
    <div className={classNames 'selection-button-container', @props.className}>
      <Button
        ref={(btn) => @_selectionButton = btn}
        className={classNames 'btn', @props.btnClassName, 'opened': @state.opened}
        label={@props.label}
        onClick={if @state.opened then @hideSelectionList else @showSelectionList}
      />
      {if @state.opened
        <div className={classNames 'opened-list', @props.listClassName} style={width: @buttonWidth}>
          <ul className='unstyled-list'>
            {@props.list.map (el) =>
              disabled = el.get 'disabled', false
              <li
                key={el.get 'value'}
                className={classNames disabled: disabled}
                onClick={@listElementClicked(el.get 'value') unless disabled}>
                  {el.get 'text'}
              </li>
            .toList()
            }
          </ul>
        </div>
      }
    </div>

module.exports = SelectionButton
