var DiagnosticEffectView, EditView, OutcomeCellEditView, View, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/evidence_syntheses/diagnostic/diagnostic_effect');

OutcomeCellEditView = require('views/outcome_cell_edit_view');

EditView = require('base/views/edit_view');

module.exports = DiagnosticEffectView = (function(_super) {
  __extends(DiagnosticEffectView, _super);

  function DiagnosticEffectView() {
    this.editClosed = __bind(this.editClosed, this);
    return DiagnosticEffectView.__super__.constructor.apply(this, arguments);
  }

  DiagnosticEffectView.prototype.template = template;

  DiagnosticEffectView.prototype.container = '.effect-container';

  DiagnosticEffectView.prototype.autoRender = true;

  DiagnosticEffectView.prototype.initialize = function() {
    DiagnosticEffectView.__super__.initialize.apply(this, arguments);
    return this.delegate('click', 'table.diagnostic-effect td.value-cell', this.editEffectDenominator);
  };

  DiagnosticEffectView.prototype.editEffectDenominator = function(e) {
    var editView;
    if (e != null) {
      e.preventDefault();
    }
    if (e != null) {
      e.stopPropagation();
    }
    this.property = $(e.target).data().property;
    editView = new OutcomeCellEditView({
      type: EditView.TYPES.COMPOUND,
      property: this.property,
      questionModel: this.model
    });
    template = require("views/templates/outcome_edit/effect");
    return editView.show(this, "[data-property=" + this.property + "]", template, this.model).then((function(_this) {
      return function() {
        return _this.subview("editView-" + editView.cid, editView);
      };
    })(this));
  };

  DiagnosticEffectView.prototype.editClosed = function(accepted) {
    if (accepted == null) {
      accepted = false;
    }
    return this.render();
  };

  DiagnosticEffectView.prototype.getTemplateData = function() {
    var absDenominator, data;
    data = DiagnosticEffectView.__super__.getTemplateData.apply(this, arguments);
    absDenominator = this.model.get('absDenominator') || 1000;
    data.absDenominator = Number(absDenominator).toLocaleString();
    return data;
  };

  return DiagnosticEffectView;

})(View);
