ReviewTableCell = createReactClass
  displayName: 'ReviewTableCell'

  propTypes:
    className: PropTypes.string
    hidden: PropTypes.bool.isRequired
    rowSpan: PropTypes.string

  getInitialProps: ->
    rowSpan: 1

  render: ->
    <td className={classNames 'review-table__cell', @props.className} rowSpan={@props.rowSpan}>
      {if @props.hidden
        <div></div>
      else
        @props.children
      }
    </td>

module.exports = ReviewTableCell
