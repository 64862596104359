var PARAMS_VALIDATORS, REQUIRED_PARAMS, SERVER_URL, Validation, W, ajax, mediator, rawContentToHTML, validateArgs, wrongArgsErr, _getEmailBodyForSending, _validatorFn;

Validation = require('base/validation');

W = require('when');

mediator = require('mediator');

SERVER_URL = require('base/lib/db_helper').getBackendUrl();

rawContentToHTML = require('lib/draft_utils').rawContentToHTML;

PARAMS_VALIDATORS = {
  projectId: function(id) {
    return _.isString(id) && !_.isEmpty(id);
  },
  memberIds: function(ids) {
    return _.isArray(ids) && !_.isEmpty(ids);
  },
  questionIds: function(ids) {
    return _.isArray(ids) && !_.isEmpty(ids);
  },
  emailBody: function(emailBody) {
    return Immutable.Map.isMap(emailBody);
  },
  contactEmail: function(email) {
    return !_.isEmpty(email);
  },
  blocks: function(blocks) {
    return !_.isEmpty(blocks);
  },
  phaseName: function(name) {
    return !_.isEmpty(name);
  }
};

REQUIRED_PARAMS = {
  startVoting: ['projectId', 'memberIds', 'questionIds', 'emailBody', 'contactEmail', 'blocks'],
  updateVoting: ['projectId', 'questionIds', 'emailBody', 'contactEmail', 'blocks', 'phaseName'],
  sendReminder: ['projectId', 'questionIds', 'emailBody'],
  closeVoting: ['projectId', 'questionIds']
};

wrongArgsErr = function() {
  return W.reject(new Error({
    status: 400,
    responseText: {
      message: 'Invalid arguments supplied'
    }
  }));
};

_validatorFn = function(params) {
  return function(validationResult, paramName) {
    if (!PARAMS_VALIDATORS[paramName](params[paramName])) {
      validationResult.valid = false;
      validationResult.invalidArgs[paramName] = params[paramName];
    }
    return validationResult;
  };
};

validateArgs = function(fnName, params) {
  var valResult;
  if (_.isEmpty(params)) {
    return {
      valid: false
    };
  }
  valResult = REQUIRED_PARAMS[fnName].reduce(_validatorFn(params), {
    valid: true,
    invalidArgs: {}
  });
  if (!_.isEmpty(valResult.invalidArgs)) {
    console.warn(valResult.invalidArgs);
  }
  return valResult.valid;
};

ajax = function(params) {
  return W.promise(function(resolve, reject) {
    if (_.isObject(params)) {
      return $.ajax(_(params).extend({
        contentType: 'application/json',
        error: reject,
        success: resolve
      }));
    } else {
      return reject('params argument must be an object');
    }
  });
};

_getEmailBodyForSending = function(emailBody) {
  emailBody = rawContentToHTML(emailBody);
  return "" + emailBody + "\n <p>" + ($.t('voting:voting.modals.please_follow_link')) + "</p>\n <p>{#link}</p>";
};

module.exports = {
  startVoting: function(params) {
    var blocks, contactEmail, dueDateTimestamp, emailBody, memberIds, projectId, questionIds;
    if (!validateArgs('startVoting', params)) {
      return wrongArgsErr();
    }
    projectId = params.projectId, memberIds = params.memberIds, questionIds = params.questionIds, emailBody = params.emailBody, dueDateTimestamp = params.dueDateTimestamp, contactEmail = params.contactEmail, blocks = params.blocks;
    return ajax({
      url: "" + SERVER_URL + "/voting",
      type: 'POST',
      data: JSON.stringify({
        projectId: projectId,
        memberIds: memberIds,
        questionIds: questionIds,
        contactEmail: contactEmail,
        dueDateTimestamp: Number(dueDateTimestamp),
        blocks: blocks,
        emailBody: _getEmailBodyForSending(emailBody)
      })
    });
  },
  updateVoting: function(params) {
    var blocks, contactEmail, dueDateTimestamp, emailBody, phaseName, projectId, questionIds, type, url, _ref;
    if (!validateArgs('updateVoting', params)) {
      return wrongArgsErr();
    }
    projectId = params.projectId, questionIds = params.questionIds, emailBody = params.emailBody, dueDateTimestamp = params.dueDateTimestamp, contactEmail = params.contactEmail, blocks = params.blocks, phaseName = params.phaseName;
    _ref = phaseName === 'phase1a' ? {
      url: "" + SERVER_URL + "/voting/" + projectId,
      type: 'PUT'
    } : phaseName === 'phase2a' ? {
      url: "" + SERVER_URL + "/voting/" + projectId + "/question-phases",
      type: 'PATCH'
    } : void 0, url = _ref.url, type = _ref.type;
    return ajax({
      url: url,
      type: type,
      data: JSON.stringify({
        questionIds: questionIds,
        contactEmail: contactEmail,
        dueDateTimestamp: Number(dueDateTimestamp),
        blocks: blocks,
        emailBody: _getEmailBodyForSending(emailBody)
      })
    });
  },
  sendReminder: function(params) {
    var emailBody, projectId, questionIds;
    if (!validateArgs('sendReminder', params)) {
      return wrongArgsErr();
    }
    questionIds = params.questionIds, emailBody = params.emailBody, projectId = params.projectId;
    return ajax({
      url: "" + SERVER_URL + "/voting/reminder/" + projectId,
      type: 'POST',
      data: JSON.stringify({
        questionIds: questionIds,
        emailBody: _getEmailBodyForSending(emailBody)
      })
    });
  },
  closeVoting: function(params) {
    var projectId, questionIds;
    if (!validateArgs('closeVoting', params)) {
      return wrongArgsErr();
    }
    projectId = params.projectId, questionIds = params.questionIds;
    return ajax({
      url: "" + SERVER_URL + "/voting/" + projectId + "/question-statuses",
      type: 'PATCH',
      data: JSON.stringify({
        closed: questionIds
      })
    });
  }
};
