Translation = require 'components/mixins/translation'

DocumentSectionsToolbar = createReactClass
  displayName: 'DocumentSectionsToolbar'

  propTypes:
    onSave: PropTypes.func.isRequired

  mixins: [
    Translation('')
  ]

  render: ->
    <menu type='toolbar' className='document-sections-view'>
      <button className='save' title={@i18n 'toolbar.save'} onClick={@props.onSave} />
    </menu>

module.exports = DocumentSectionsToolbar
