Confirmation = require 'components/common/confirmation'
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
FeedbackStatus = require 'components/panel_voice/feedback/feedback_status'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
MembersStore = require 'stores/members_store'
Modal = require 'components/common/modal'
ModalSendWithSteps = require 'components/panel_voice/modal_send_with_steps'
OverarchingQuestionsStore = require 'stores/overarching_questions_store'
PanelVoiceActions = require 'actions/panel_voice_actions'
PanelVoiceStore = require 'stores/panel_voice_store'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
QuestionsTable = require 'components/panel_voice/questions_table'
QuestionsTableBody = require 'components/panel_voice/questions_table_body'
QuestionsTableHeader = require 'components/panel_voice/questions_table_header'
SearchBar = require 'components/common/search_bar'
Select = require 'components/common/select_custom'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
{ sortQuestions, filterGoodPracticeStatement, filterKeyMessage } = require 'lib/questions_helper'

{ bool, number, func, oneOfType, instanceOf } = PropTypes

storeConnectors =
  PanelVoiceStore: (Store) ->
    panelVoiceMetadata: Store.getPanelVoiceMetadata()
    panelVoicePreferences: Store.getPanelVoicePreferences()
    isFetchingPanelVoiceMetadata: Store.isFetchingMetadata()
    isFetchingVotingResults: Store.isFetchingVotingResults()
    isFetchingEtdHighlightDocs: Store.isFetchingEtdHighlightDocs()
    isFetchingPreferences: Store.isFetchingPreferences()
    isFetchingUserData: Store.isFetchingUserData()
    shouldShowOnboardingProposal: Store.shouldShowOnboardingProposal()
    searchText: Store.getSearchText()
    statusFilter: Store.getStatusFilter()
    sendModalOptions: Store.getSendModalOptions()
    votingResults: Store.getVotingResults()
    isSendingReminders: Store.isSendingReminders()
    feedbackStatusDialogOpened: Store.isFeedbackStatusDialogOpened()
    isMemberInPanelVoiceFn: Store.isMemberInPanelVoice
    votingMessageAttachments: Store.getVotingMessageAttachments()
    votingMessageAttachmentsSize: Store.getVotingMessageAttachmentsSize()
  QuestionsStore: (Store) ->
    questions: Store.getQuestions()
    isFetchingQuestions: Store.isFetching()
  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups()
    isFetchingQuestionGroups: Store.isFetching()
  MembersStore: (Store) ->
    members: Store.getPanelMembers()
    isFetchingMembers: Store.isFetching()
  OverarchingQuestionsStore: (Store) ->
    overarchingQuestions: Store.getOverarchingQuestions()
    overarchingQuestionsGrouped: Store.getOverarchingQuestionsByGroup()

PanelVoice = createReactClass
  displayName: 'PanelVoice'

  propTypes:
    panelVoiceMetadata: instanceOf(Immutable.Map).isRequired
    panelVoicePreferences: instanceOf(Immutable.Map).isRequired
    sendModalOptions: instanceOf(Immutable.Map).isRequired
    overarchingQuestions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    overarchingQuestionsGrouped: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    questions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    questionGroups: instanceOf(Immutable.Map).isRequired
    votingResults: instanceOf(Immutable.List).isRequired
    members: oneOfType([instanceOf(Immutable.List), instanceOf(Immutable.OrderedMap)]).isRequired
    isFetchingPanelVoiceMetadata: bool.isRequired
    isFetchingVotingResults: bool.isRequired
    isFetchingQuestions: bool.isRequired
    isFetchingQuestionGroups: bool.isRequired
    isFetchingMembers: bool.isRequired
    isFetchingUserData: bool.isRequired
    shouldShowOnboardingProposal: bool.isRequired
    isSendingReminders: bool.isRequired
    isMemberInPanelVoiceFn: func.isRequired
    feedbackStatusDialogOpened: bool.isRequired
    votingMessageAttachments: instanceOf(Immutable.Map)
    votingMessageAttachmentsSize: number
    onboardingAutostart: bool

  mixins: [ Translation('voting:panel_voice') ]

  getDefaultProps: ->
    onboardingAutostart: false

  _isFetchingData: ->
    @props.isFetchingPanelVoiceMetadata or @props.isFetchingVotingResults or
      @props.isFetchingQuestions or @props.isFetchingQuestionGroups or @props.isFetchingMembers or
        @props.isFetchingPreferences or @props.isFetchingUserData

  getSortByOptions: ->
    [
      text: @i18n '../voting.all'
      value: 'all'
    ,
      text: @i18n 'statuses.unsent'
      value: 'unsent'
    ,
      text: @i18n 'statuses.ongoing'
      value: 'ongoing'
    ,
      text: @i18n 'statuses.closed'
      value: 'closed'
    ]

  getFilteredQuestions: ->
    { questions, statusFilter, searchText } = @props
    questions = questions.filterNot(filterGoodPracticeStatement).filterNot(filterKeyMessage)

    filteredByStatus = if statusFilter is 'all'
      questions
    else
      # todo: implement filtering based on status from meta and panelVoice metadata
      questions.filter (q) ->
        PanelVoiceStore.getQuestionVotingStatus(q.get('_id')) is statusFilter

    if _.isEmpty searchText
      filteredByStatus
    else
      filteredByStatus.filter (q) ->
        _.str.contains q.get('question').toLowerCase(), searchText

  getFilteredOverarchingQuestions: ->
    { overarchingQuestionsGrouped, statusFilter, searchText } = @props

    filteredByStatus = if statusFilter is 'all'
      overarchingQuestionsGrouped
    else
      # todo: implement filtering based on status from meta and panelVoice metadata
      overarchingQuestionsGrouped.map (group) ->
        group.set 'questions', group.get('questions').filter (q) ->
          PanelVoiceStore.getQuestionVotingStatus(q.get('_id')) is statusFilter

    if _.isEmpty searchText
      filteredByStatus
    else
      filteredByStatus.map (group) ->
        group.set 'questions', group.get('questions').filter (q) ->
          _.str.contains q.get('question').toLowerCase(), searchText

  onCloseVotingModal: ->
    PanelVoiceActions.closeSendVotingModal()

  onSend: (type, data) ->
    PanelVoiceActions.sendVoting type, data

  _filterQuestionsByPartsAndStatusCb: (currentQuestionStage) -> (question) ->

    stage = PanelVoiceStore.getQuestionVotingStages(question.get("_id"))?.last()
    # Can't send question without ETD so skip it
    return false if question.get('recommendationIds', Immutable.List()).isEmpty()
    # this means that question is being send for the first time
    return true if currentQuestionStage is undefined and stage is undefined
    if currentQuestionStage and stage
      # I guess we should only consider questions with the same parts and status, but skip
      # checking timestamps or members
      currentQuestionStage.get('parts').equals(stage.get('parts')) and
        currentQuestionStage.get('status') is stage.get('status')
    else
      false

  filterQuestionsByPartsAndStatus: (qId) ->
    currentQuestionStage = PanelVoiceStore.getQuestionVotingStages(qId)?.last()
    overarchingQuestions = @props.overarchingQuestions.filter(
      @_filterQuestionsByPartsAndStatusCb(currentQuestionStage))

    questions = @props.questions
      .filterNot filterGoodPracticeStatement
      .filter @_filterQuestionsByPartsAndStatusCb(currentQuestionStage)

    questions.merge overarchingQuestions

  filterOutClosedUnsentTestQuestions: ->
    @props.questions.filterNot (question) ->
      PanelVoiceStore.getQuestionVotingStatus(question.get('_id')) in ['closed', 'unsent', 'test']

  componentDidMount: ->
    if @props.onboardingAutostart
      mediator.services.panelVoiceOnboarding.startTour()

  render: ->
    { questionGroups, searchText, statusFilter, members,
      panelVoicePreferences, panelVoiceMetadata, sendModalOptions, votingResults } = @props
    isFetching = @_isFetchingData()

    <div className='panel-voice-container'>
      <div className='top-section'>
        <SearchBar searchOnChange
          containerClass='questions-search'
          onSearch={PanelVoiceActions.search}
          onSearchReset={PanelVoiceActions.resetSearch}
          searchText={searchText}
        />
        <div>
          <IconButton
            label={@i18n 'feedback_status'}
            iconName='feedback-icon'
            onClick={PanelVoiceActions.showFeedbackStatusDialog}
            disabled={isFetching}
          />
          <div className='status-filter-container'>
            <span className='status-caption'>{@i18n 'status'}</span>
            <Select
              options={@getSortByOptions()}
              selected={statusFilter}
              onChange={PanelVoiceActions.changeStatusFilter}
            />
          </div>
        </div>
      </div>
      <div className='panel-voice-questions-container'>
        <QuestionsTable>
          <QuestionsTableHeader withGroupsOffset={not questionGroups.get('groups')?.isEmpty()} />
          {if isFetching
            <Spinner />
          else
            <QuestionsTableBody
              overarchingQuestions={@getFilteredOverarchingQuestions()}
              questions={@getFilteredQuestions()}
              questionGroups={questionGroups}
            />
          }
        </QuestionsTable>
      </div>

      {if @props.feedbackStatusDialogOpened
        <Modal
          isOpen
          onClose={PanelVoiceActions.hideFeedbackStatusDialog}
          closeButton={true}
          className="feedback-status-modal"
        >
          <FeedbackStatus
            votingResults={votingResults}
            panelMembers={members}
            questionGroups={questionGroups}
            questionsList={@filterOutClosedUnsentTestQuestions()}
            isMemberInPanelVoiceFn={@props.isMemberInPanelVoiceFn}
            isSendingReminders={@props.isSendingReminders}
          />
        </Modal>
      }

      {if sendModalOptions.get('opened') and sendModalOptions.get("questionId")
        questionsWithTheSameStatus = @filterQuestionsByPartsAndStatus(
          sendModalOptions.get("questionId"))
        votingStages = PanelVoiceStore.getQuestionVotingStages sendModalOptions.get("questionId")

        <Modal
          isOpen={sendModalOptions.get("opened")}
          onClose={@onCloseVotingModal}
          closeButton={true}
          modalSize='medium'
          className="voting-send-modal"
        >
          <ModalSendWithSteps
            votingStages={votingStages}
            onCancel={@onCloseVotingModal}
            type={sendModalOptions.get('type')}
            contactEmail={panelVoiceMetadata.get('contactEmail', "")}
            onApply={@onSend}
            currentQuestionId={sendModalOptions.get("questionId")}
            isSending={sendModalOptions.get('sending')}
            questions={questionsWithTheSameStatus}
            members={members}
            questionGroups={questionGroups}
            panelVoicePreferences={panelVoicePreferences}
            votingMessageAttachmentsSize={@props.votingMessageAttachmentsSize}
            votingMessageAttachments={@props.votingMessageAttachments}
            overarching={sendModalOptions.get('overarching')}
            overarchingQuestions={@props.overarchingQuestions}
          />
        </Modal>
      }

      {if @props.shouldShowOnboardingProposal and not @props.onboardingAutostart
        <Modal
          isOpen
          onClose={PanelVoiceActions.hideOnboardingNotification}
          className='onboarding-notification confirmation-modal'
        >
          <Confirmation
            question={@i18n 'panel_voice_onboarding_notification_text'}
            onCancel={PanelVoiceActions.hideOnboardingNotification}
            onConfirm={PanelVoiceActions.startPanelVoiceOnboarding}
            cancelLabel={@i18n '/translation:actions.see_later'}
          />
        </Modal>
      }
    </div>

module.exports = ConnectStore(
  PanelVoice
  [ PanelVoiceStore, QuestionsStore, QuestionGroupsStore, MembersStore, OverarchingQuestionsStore ]
  storeConnectors
)
