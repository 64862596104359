NMAListActions = require 'actions/nma_list_actions'
NMAQuestionModal = require 'components/nma/nma_question_modal'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

NMAQuestionsListToolbar = ->
  i18n = useI18n 'nma:questions_list'

  onAddQuestion = useCoffeeCallback ->
    NMAListActions.openQuestionModal()

  <div className="nma-list-toolbar">
    <div className="toolbar__list">
      {i18n 'questions_list'}
    </div>
    <div className="toolbar__add-question-button">
      <button className="btn btn-apply" onClick={onAddQuestion}>
        {i18n '/actions.new_question'}
      </button>
    </div>
    <NMAQuestionModal />
  </div>

module.exports = NMAQuestionsListToolbar
