ProjectsActions = require 'actions/projects_actions'

# ensure animatable element is visible
onAnimationStart = (evt) ->
  evt.target.scrollIntoView
    block: 'center'
    behavior: 'smooth'

AddProjectSplash = (Child) ->
  # this wrapper is designed for ProjectListItem only
  unless Child.displayName is 'ProjectsListItem'
    throw Error 'Invalid Child component supplied! Expected "ProjectsListItem".'

  createReactClass
    displayName: 'AddProjectSplash'

    childRef: (childComponent) ->
      @childEl = ReactDOM.findDOMNode childComponent
      # we are wrapper component, so if child component was given a ref callback, we must call it
      # with child component's reference
      @props.ref? @childEl

    _setAnimationEventHandlers: ->
      return unless @childEl?
      @childEl.addEventListener 'animationstart', onAnimationStart
      # we want to show animation only once, so once it is over, we must reset Store value
      @childEl.addEventListener 'animationend', ProjectsActions.resetHighlightedProjectId

    _removeAnimationEventHandlers: ->
      return unless @childEl?
      @childEl.removeEventListener 'animationstart', onAnimationStart
      @childEl.removeEventListener 'animationend', ProjectsActions.resetHighlightedProjectId

    componentDidUpdate: ->
      @_removeAnimationEventHandlers()
      @_setAnimationEventHandlers() if @props.highlighted

    componentDidMount: ->
      @_setAnimationEventHandlers() if @props.highlighted

    componentWillUnmount: ->
      @_removeAnimationEventHandlers()

    render: ->
      childClass = classNames @props.className, 'highlight-project': @props.highlighted

      <Child {...@props} className={childClass} ref={@childRef} />

module.exports = AddProjectSplash
