ConnectStore = require 'components/enhancers/connect_store'
DiscardChangesActions = require 'actions/discard_changes_actions'
DiscardChangesStore = require 'stores/discard_changes_store'
{
  default: Editor,
  resetState: resetEditorState,
  createControlExtension,
  serializeState: serializeEditorState
} = Epiditor
MdaTableActions = require 'actions/mda_table_actions'
MdaTableStore = require 'stores/mda_table_store'
mediator = require 'mediator'
Router = require 'router'
Spinner = require 'components/common/spinner'
{ useCallback, useState } = React
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

storeConnector =
  DiscardChangesStore: (Store) ->
    hasChanges: Store.hasChanges()
  MdaTableStore: (Store) ->
    content: Store.getContent()
    isFetching: Store.isFetching()
    topicsForTable: Store.getTopicsForTable()

MdaTable = ({ content, isFetching, hasChanges, tableId, topicsForTable }) ->
  i18n = useI18n('translation:actions')
  [editing, setEditing] = useState false

  handleEditorCancel = useCoffeeCallback [content, setEditing], (view) ->
    DiscardChangesActions.setHasChanges false
    resetEditorState view, content?.toJS()
    setEditing false

  handleEditorChange = useCoffeeCallback [editing, content, hasChanges], (changedValue) ->
    return if hasChanges or not editing
    if not _.isEqual content?.toJS(), serializeEditorState(changedValue)
      DiscardChangesActions.setHasChanges true

  handleEditorChangeDebounced = useCallback(
    _.debounce handleEditorChange, 500
    [handleEditorChange]
  )

  handleEditorClick = useCoffeeCallback [editing, setEditing], ->
    return if editing
    setEditing true

  handleEditorSave = useCoffeeCallback [mediator.project, setEditing, tableId], (view) ->
    DiscardChangesActions.setHasChanges false
    MdaTableActions.updateContent mediator.project.id, tableId, serializeEditorState view.state
    setEditing false

  editorExtensions = useCoffeeMemo [handleEditorCancel, handleEditorSave], ->
    [
      createControlExtension 'applyCancelControlsExt', ({ view }) ->
        <div className="apply-cancel-editor-controls">
          <button className="cancel-btn" onClick={-> handleEditorCancel(view)}>
            {i18n 'cancel'}
          </button>
          <button className="apply-btn" onClick={-> handleEditorSave(view)}>
            {i18n 'save'}
          </button>
        </div>
    ]

  goToTopic = useCoffeeCallback [hasChanges], (topicId) -> (e) ->
    e.preventDefault()

    doGoToTopic = ->
      link = Router::routeForSubmodule 'mda-topics', null, questionId: topicId
      mediator.publish '!router:route', link

    if hasChanges
      DiscardChangesActions.setCallback doGoToTopic
    else
      doGoToTopic()

  return <Spinner /> if isFetching
  <div className="mda-table">
    <div className={classNames 'content', inactive: not editing}>
      <Editor
        extensions={editorExtensions}
        initialState={content?.toJS()}
        mode={if editing then 'edit' else 'readonly'}
        onChange={handleEditorChangeDebounced}
        onClick={handleEditorClick}
      />
    </div>
    {topicsForTable.size > 0 and <div className="mda-table__topics">
      <p className="bold">{i18n '/mda:tables.topics_with_table'}</p>
      <ul>
        {topicsForTable.map (topic) ->
          topicId = topic.get '_id'
          <li key={topicId}>
            <a onClick={goToTopic topicId}>{topic.get('name') ? ''}</a>
          </li>
        .toJS()}
      </ul>
    </div>}
  </div>

MdaTable.propTypes =
  content: PropTypes.instanceOf(Immutable.Map)
  isFetching: PropTypes.bool.isRequired
  hasChanges: PropTypes.bool.isRequired
  tableId: PropTypes.string.isRequired
  topicsForTable: PropTypes.instanceOf(Immutable.List).isRequired

module.exports = ConnectStore MdaTable, [DiscardChangesStore, MdaTableStore], storeConnector
