var Model, OutcomesGeneration,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

module.exports = OutcomesGeneration = (function(_super) {
  __extends(OutcomesGeneration, _super);

  function OutcomesGeneration() {
    return OutcomesGeneration.__super__.constructor.apply(this, arguments);
  }

  OutcomesGeneration.prototype.getGeneratedOutcomesGroups = function() {
    var finishedStep, qGroups, qg, _ref;
    finishedStep = (_ref = this.get('steps')) != null ? _ref.finished : void 0;
    if (!finishedStep) {
      return null;
    }
    qGroups = (function() {
      var _i, _len, _ref1, _results;
      _ref1 = finishedStep.questionGroups;
      _results = [];
      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        qg = _ref1[_i];
        qg.outcomes.sort(this.outcomesSortFn);
        _results.push(qg);
      }
      return _results;
    }).call(this);
    return qGroups;
  };

  OutcomesGeneration.prototype.outcomesSortFn = function(a, b) {
    var importanceOrder, importanceValues;
    importanceValues = {
      critical: 2,
      important: 1,
      notImportant: 0
    };
    importanceOrder = importanceValues[b.importance] - importanceValues[a.importance];
    if (importanceOrder) {
      return importanceOrder;
    } else {
      return b.medianRating - a.medianRating;
    }
  };

  return OutcomesGeneration;

})(Model);
