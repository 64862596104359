var Exceptions, FullProjects, OrderedCollection, Project, Projects, Set, User, W, mediator, sequence,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OrderedCollection = require('models/base/ordered_collection');

Project = require('models/project');

Projects = require('models/projects');

User = require('models/user');

Set = require('base/lib/set');

sequence = require('when/sequence');

mediator = require('mediator');

W = require('when');

Exceptions = require('lib/exceptions');

module.exports = FullProjects = (function(_super) {
  __extends(FullProjects, _super);

  function FullProjects() {
    return FullProjects.__super__.constructor.apply(this, arguments);
  }

  FullProjects.prototype.model = Project;

  FullProjects.prototype._redirects = {};

  FullProjects.prototype.setOrderOnAdd = false;

  FullProjects.prototype._fetchProjects = function(projectDBs) {
    var project;
    return sequence((function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = projectDBs.length; _i < _len; _i++) {
        project = projectDBs[_i];
        _results.push(this._fetchProject(project));
      }
      return _results;
    }).call(this)).then((function(_this) {
      return function(results) {
        var project, _i, _len;
        for (_i = 0, _len = results.length; _i < _len; _i++) {
          project = results[_i];
          _this._addData(project);
        }
        return _this.reset(_(results).compact());
      };
    })(this));
  };

  FullProjects.prototype.getMaxOrderNumber = function() {
    return this.models.length;
  };

  FullProjects.prototype._addData = function(project) {
    var _ref, _ref1, _ref2, _ref3;
    if (project != null) {
      project.orderNumber = (_ref = this.additionalProjectsData[project.id]) != null ? _ref.orderNumber : void 0;
      project.changeTime = (_ref1 = this.additionalProjectsData[project.id]) != null ? _ref1.changeTime : void 0;
      project.timestamp = (_ref2 = this.additionalProjectsData[project.id]) != null ? _ref2.timestamp : void 0;
      return project.shared = (_ref3 = this.additionalProjectsData[project.id]) != null ? _ref3.shared : void 0;
    }
  };

  FullProjects.prototype._getProjectsData = function(projectDBs) {
    var projectsMap, _setProjectData;
    projectsMap = {};
    _setProjectData = function(projectId, timestamp) {
      return projectsMap[projectId] = {
        timestamp: timestamp,
        changeTime: moment(timestamp).format('MMM Do, YYYY'),
        orderNumber: mediator.user.getProjectOrderNumber(projectId),
        shared: _(mediator.user.getRequests().models).find(function(req) {
          return req.attributes.type === 'share_project' && req.attributes.project === projectId;
        })
      };
    };
    return W.map(projectDBs, function(projectId) {
      var project;
      project = new Project({
        _id: projectId
      });
      return project.getDocFor('latestChange', false).then(function(changeDoc) {
        return _setProjectData(projectId, changeDoc.get('timestamp'));
      }, function(e) {
        _setProjectData(projectId, (new Date).getTime());
        if (!(e instanceof Exceptions.document_missing)) {
          throw e;
        }
      });
    }).then(function() {
      return projectsMap;
    });
  };

  FullProjects.prototype.remove = function(projectId) {
    delete this.additionalProjectsData[projectId];
    return FullProjects.__super__.remove.call(this, projectId);
  };

  FullProjects.prototype.add = function(project, additionalData) {
    this.additionalProjectsData[project.id] = additionalData;
    return FullProjects.__super__.add.call(this, project);
  };

  FullProjects.prototype.getAdditionalProjectData = function(projectId) {
    return this.additionalProjectsData[projectId];
  };

  FullProjects.prototype.updateOrderNumbers = function() {
    var fromIdx, i, toIdx, _i;
    fromIdx = 0;
    toIdx = this.length - 1;
    for (i = _i = fromIdx; fromIdx <= toIdx ? _i <= toIdx : _i >= toIdx; i = fromIdx <= toIdx ? ++_i : --_i) {
      this._setItemOrderNumber(i, i + 1);
      this.at(i).orderNumber = i + 1;
    }
    return this._saveReorderedItems(fromIdx, toIdx);
  };

  return FullProjects;

})(Projects);
