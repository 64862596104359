var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<!doctype html>\n<html class=\"no-js\" lang=\"en\">\n<head>\n  <meta charset=\"utf-8\">\n  <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge,chrome=1\">\n  <title>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "printout_title", {"name":"i18n","hash":{},"data":data})))
    + "</title>\n  <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n  <style type=\"text/css\">\n    @media print {\n      .new-page {\n        page-break-before: always;\n      }\n    }\n\n    body {\n      margin: 0 30mm;\n    }\n\n    * {\n      font-family: 'Carlito'\n    }\n\n    .chapter-title, .subchapter-title {\n      color: #C00000;\n      margin: 2pt 0;\n    }\n\n    .chapter-block {\n      line-height: 1.8;\n      font-size: 11pt;\n    }\n\n    div[data-block=\"true\"] {\n      text-indent: 40pt;\n      margin-top: 4pt;\n    }\n\n    div.references {\n      margin-left: 20pt;\n    }\n  </style>\n</head>\n<body>\n  ";
  stack1 = ((helper = (helper = helpers.docSectionsHTML || (depth0 != null ? depth0.docSectionsHTML : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"docSectionsHTML","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</body>\n</html>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}