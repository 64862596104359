var DiagnosticPrevalencesView, EditView, OutcomeCellEditView, View, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/evidence_syntheses/diagnostic/diagnostic_prevalences');

OutcomeCellEditView = require('views/outcome_cell_edit_view');

EditView = require('base/views/edit_view');

module.exports = DiagnosticPrevalencesView = (function(_super) {
  __extends(DiagnosticPrevalencesView, _super);

  function DiagnosticPrevalencesView() {
    this.editClosed = __bind(this.editClosed, this);
    return DiagnosticPrevalencesView.__super__.constructor.apply(this, arguments);
  }

  DiagnosticPrevalencesView.prototype.template = template;

  DiagnosticPrevalencesView.prototype.container = '.prevalences-container';

  DiagnosticPrevalencesView.prototype.autoRender = true;

  DiagnosticPrevalencesView.prototype.initialize = function() {
    DiagnosticPrevalencesView.__super__.initialize.apply(this, arguments);
    return this.delegate('click', 'table.diagnostic-prevalences td.value-cell', this.editPrevalences);
  };

  DiagnosticPrevalencesView.prototype.editPrevalences = function(e) {
    var editView;
    if (e != null) {
      e.preventDefault();
    }
    if (e != null) {
      e.stopPropagation();
    }
    this.property = $(e.target).data().property;
    editView = new OutcomeCellEditView({
      type: EditView.TYPES.COMPOUND,
      property: this.property,
      questionModel: this.model
    });
    template = require("views/templates/outcome_edit/prevalences");
    return editView.show(this, "[data-property=" + this.property + "]", template, this.model).then((function(_this) {
      return function() {
        return _this.subview("editView-" + editView.cid, editView);
      };
    })(this));
  };

  DiagnosticPrevalencesView.prototype.editClosed = function(accepted) {
    if (accepted == null) {
      accepted = false;
    }
    return this.render();
  };

  DiagnosticPrevalencesView.prototype.getTemplateData = function() {
    var data, pr, _i, _len, _ref, _ref1;
    data = DiagnosticPrevalencesView.__super__.getTemplateData.apply(this, arguments);
    _ref = ['prevalence1', 'prevalence2', 'prevalence3'];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      pr = _ref[_i];
      if ((_ref1 = data[pr]) != null ? _ref1.toString().length : void 0) {
        data[pr] = "" + data[pr] + "%";
      }
    }
    return data;
  };

  return DiagnosticPrevalencesView;

})(View);
