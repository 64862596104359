{ useState } = React
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
Modal = require 'components/common/modal'
{ useI18n } = require 'lib/react_utils'

BatchEditorOptionsModal = ({ batchEditorOptions, onClose }) ->

  i18n = useI18n('docsec:management')
  [applying, setApplying] = useState(false)
  [chapterOrFullDocument, setChapterOrFullDocument] = useState 'chapter'

  closeBatchEditorOptionsModal = ->
    setApplying false
    DocumentSectionsV2Actions.closeBatchEditorOptionsModal()

  clearHighlights = ->
    setApplying true
    chapterId = if chapterOrFullDocument is 'full_document' then null else batchEditorOptions.get('chapterId')
    DocumentSectionsV2Actions.removeAllHighlights(chapterId)
    .finally closeBatchEditorOptionsModal

  changeClearHighlightOption = (e) -> setChapterOrFullDocument e.target.value
  setAcceptSuggestion = (e) -> setChapterOrFullDocument e.target.value

  acceptSuggestions = ->
    setApplying true
    chapterId = if chapterOrFullDocument is 'full_document' or batchEditorOptions.get('chapterId') is 'full_document'
      null
    else
      batchEditorOptions.get('chapterId')

    DocumentSectionsV2Actions.resolveSuggestions(chapterId, batchEditorOptions.get('accept', true))
    .finally closeBatchEditorOptionsModal

  <Modal
    isOpen
    modalSize="medium"
  >
    {switch batchEditorOptions.get('type')
      when 'clearHighlights'
        <div>
          <div className="mb-10">{i18n 'clear_highlights_warning'}</div>
          <ClearOptions
            name="clearHighlights"
            selectedOption={chapterOrFullDocument}
            onSelect={changeClearHighlightOption}
          />
          <div className="mb-10">
            <b>{i18n 'actions_are_irreversible'}</b>
          </div>
          <ApplyCancelButtons
            onApply={clearHighlights}
            onCancel={closeBatchEditorOptionsModal}
            applying={applying}
          />
        </div>
      when 'suggestions'
        <div>
          <div className="mb-10">{if batchEditorOptions.get('accept')
            i18n 'accept_suggestions_warning'
          else
            i18n 'reject_suggestions_warning'}
          </div>
          {batchEditorOptions.get('chapterId') isnt 'full_document' &&
            <ClearOptions
              name="chapterOrFullDocument"
              selectedOption={chapterOrFullDocument}
              onSelect={setAcceptSuggestion}
            />
          }
          <div className="mb-10">
            <b>{if batchEditorOptions.get('chapterId') is 'full_document'
              i18n 'action_is_irreversible'
            else
              i18n 'actions_are_irreversible'}
            </b>
          </div>
          <ApplyCancelButtons
            onApply={acceptSuggestions}
            onCancel={closeBatchEditorOptionsModal}
            applying={applying}
          />
        </div>
    }
  </Modal>

ClearOptions = ({ name, selectedOption, onSelect }) ->
  i18n = useI18n('docsec:management')
  <React.Fragment>
    {_.map(['chapter', 'full_document'], (option) =>
      <div key={option} className="mb-10">
        <label className="flex flex-row items-center">
          <input
            type="radio"
            name={name}
            value={option}
            checked={option is selectedOption}
            onChange={onSelect}
          />{i18n option}
        </label>
      </div>
    )}
  </React.Fragment>

module.exports = BatchEditorOptionsModal
