ConnectStore = require 'components/enhancers/connect_store'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
EllipsizedText = require 'components/common/ellipsized_text'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
NMAListStore = require 'stores/nma_list_store'
NMAQuestionStore = require 'stores/nma_question_store'
Router = require 'router'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback } = require 'lib/react_utils'

NMAToolbarQuestion = ({ collapse, question }) ->
  onClick = useCoffeeCallback [collapse, question], ->
    collapse?()
    mediator.publish '!router:route', Router::getProjectRelativeUrl "/nma/#{question.get '_id'}"

  questionTitle = question.get 'question', ''

  <Tooltip>
    <div className="list__question" onClick={onClick} title={questionTitle}>
      <EllipsizedText height={40} lineHeight={30} text={questionTitle} />
    </div>
  </Tooltip>

NMAToolbarQuestion.propTypes =
  collapse: PropTypes.func
  question: PropTypes.instanceOf(Immutable.Map).isRequired

NMAToolbarQuestionList = ({ additionalProps: { collapse }, questions }) ->
  <div className="list__questions">
    {questions.map (question) ->
      <NMAToolbarQuestion collapse={collapse} key={question.get '_id'} question={question} />
    }
  </div>

NMAToolbarQuestionList.propTypes =
  additionalProps: PropTypes.shape(collapse: PropTypes.func)
  questions: PropTypes.instanceOf(Immutable.List).isRequired

NMAToolbarWithQuestionList = ({
  className
  isFetching
  question
  questions
  onBack
  rightElement
}) ->
  questionTitle = question.get 'question', ''

  return null if isFetching

  classNameToPass = classNames 'nma-questions-list-toolbar',
    'no-controls': not rightElement?
    'with-back': onBack?
  , className

  <div className={classNameToPass}>
    {onBack and <IconButton className="toolbar__back" iconName="arrow-left" onClick={onBack} />}
    <div className="toolbar__list nma-questions-list-toolbar__list">
      <CollapsibleWithControls passAdditionalProps withTogglableCaption>
        <Tooltip>
          <div className="list__title" title={questionTitle}>
            <EllipsizedText height={40} lineHeight={30} text={questionTitle} />
          </div>
        </Tooltip>
        <NMAToolbarQuestionList questions={questions} />
      </CollapsibleWithControls>
    </div>
    {rightElement and <div className="toolbar__controls">
      {rightElement}
    </div>}
  </div>

NMAToolbarWithQuestionList.propTypes =
  className: PropTypes.string
  isFetching: PropTypes.bool.isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  questions: PropTypes.instanceOf(Immutable.List).isRequired
  onBack: PropTypes.func
  rightElement: PropTypes.element

storeConnector =
  NMAListStore: (Store) ->
    questions: Store.getQuestions()
  NMAQuestionStore: (Store) ->
    isFetching: Store.isFetching()
    question: Store.getCurrentQuestion()

module.exports = ConnectStore NMAToolbarWithQuestionList,
  [NMAListStore, NMAQuestionStore],
  storeConnector
