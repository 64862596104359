var DbHelper, ajax, fetchDashboardHistory;

ajax = require('actions/async/api_helpers').ajax;

DbHelper = require('base/lib/db_helper');

fetchDashboardHistory = function(projectId) {
  return ajax({
    url: "" + (DbHelper.getBackendUrl()) + "/history/" + projectId + "/_dashboard",
    type: 'GET',
    dataType: 'json',
    xhrFields: {
      withCredentials: true
    }
  });
};

module.exports = {
  fetchDashboardHistory: fetchDashboardHistory
};
