CheckboxInput = require 'components/common/checkbox_input'
IconButton = require 'components/common/icon_button'
Button = require 'components/common/button'
Popover = require 'components/common/popover'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ i18nForMixin } = require 'lib/react_utils'

ChecklistTopicStep = createReactClass
  displayName: 'ChecklistTopicStep'

  propTypes:
    onShowStepSources: PropTypes.func.isRequired
    learningTools: PropTypes.instanceOf(Immutable.List).isRequired
    implementationTools: PropTypes.instanceOf(Immutable.List).isRequired
    isEditingComment: PropTypes.bool.isRequired
    onStatusChange: PropTypes.func.isRequired
    onCommentApply: PropTypes.func.isRequired
    onRequestCommentEdit: PropTypes.func.isRequired
    onRequestCommentEditClose: PropTypes.func.isRequired
    stepStatus: PropTypes.oneOf(['notApplicable', 'completed'])
    stepComment: PropTypes.string
    titlePresent: PropTypes.bool
    topicId: PropTypes.string.isRequired
    type: PropTypes.oneOf(['mdg', 'regular']).isRequired

  getDefaultProps: ->
    titlePresent: false

  mixins: [
    CustomRenderMixin,
    Translation('gd_checklist:topics')
  ]

  _stepCommentRef: (el) ->
    @commentInput = el

  handleStepCommentApply: (evt) ->
    comment = @commentInput.value
    @props.onCommentApply comment

  render: ->
    {
      implementationTools,
      isEditingComment,
      learningTools,
      onShowStepSources,
      onStatusChange,
      onRequestCommentEditClose
      onRequestCommentEdit,
      stepComment,
      stepId,
      stepStatus,
      titlePresent,
      topicId,
      type
    } = @props

    isMdg = type is 'mdg'
    i18n = if isMdg
      i18nForMixin 'gd_checklist:topicsMdg'
    else
      @i18n
    stepKeySuffix = if isMdg then '.text' else ''

    <div className='topic-step' key={stepId}>
      <div>
        <div className='topic-step-details'>
          {titlePresent and <div className="topic-step-title">
            {i18n "#{topicId}.steps.#{stepId}.title"}
          </div>}
          <div
            className='topic-step-text'
            dangerouslySetInnerHTML={__html: i18n "#{topicId}.steps.#{stepId}#{stepKeySuffix}"}
          />
          {not isMdg and <div className='topic-step-sources'>
            <Button
              className='btn-round'
              label={i18n '../references'}
              onClick={onShowStepSources}
            />
          </div>}
          {not isMdg and <div className='topic-step-tools'>
            <div className='learning-tools'>
              <div>{i18n '../learning_tools_caption'}</div>
              {if learningTools.isEmpty()
                <div className='help-text'>{i18n '../not_found'}</div>
              else
                <div dangerouslySetInnerHTML={__html: learningTools.join ''} />
              }
            </div>
            <div className='implementation-tools'>
              <div>{i18n '../implementation_tools_caption'}</div>
              {if implementationTools.isEmpty()
                <div className='help-text'>{i18n '../not_found'}</div>
              else
                <div dangerouslySetInnerHTML={__html: implementationTools.join ''} />
              }
            </div>
          </div>}
        </div>
        <div className='topic-step-actions'>
          <div className='topic-step-comment'>
            <Popover
              onRequestClose={onRequestCommentEditClose}
              positionParams={position: 'cover', alignment: 'center'}
              visible={isEditingComment}
            >
              {if stepComment?
                <IconButton
                  iconName='comment-blank'
                  onClick={onRequestCommentEdit}
                />
              else
                <div className='help-text' onClick={onRequestCommentEdit}>
                  {i18n '../add_comment'}
                </div>
              }
              <div className='step-commment-edit'>
                <textarea
                  autoFocus
                  data-stepId={stepId}
                  defaultValue={stepComment ? ''}
                  ref={@_stepCommentRef}
                />
                <ApplyCancelButtons
                  onCancel={onRequestCommentEditClose}
                  onApply={@handleStepCommentApply}
                />
              </div>
            </Popover>
          </div>
          <div className='topic-step-status'>
            <label>
              <CheckboxInput
                checked={stepStatus is 'completed'}
                name="step-status-#{stepId}"
                onChange={onStatusChange}
                value='completed'
              />
              {i18n '../completed'}
            </label>
            <label>
              <CheckboxInput
                checked={stepStatus is 'notApplicable'}
                name="step-status-#{stepId}"
                onChange={onStatusChange}
                value='notApplicable'
              />
              {i18n '../not_applicable'}
            </label>
          </div>
        </div>
      </div>
    </div>

module.exports = ChecklistTopicStep
