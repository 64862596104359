var TabsView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/tabs');

module.exports = TabsView = (function(_super) {
  __extends(TabsView, _super);

  function TabsView() {
    return TabsView.__super__.constructor.apply(this, arguments);
  }

  TabsView.prototype.template = template;

  TabsView.prototype.className = 'tabs-view';

  TabsView.prototype.initialize = function() {
    if (!this.options.tabViews) {
      throw new Error('No `tabViews` argument specified');
    }
    this.tabViews = this.options.tabViews;
    this.currentTab = 0;
    this.delegate('click', '.tab-elem', this.openTab);
    return TabsView.__super__.initialize.apply(this, arguments);
  };

  TabsView.prototype.afterRender = function() {
    TabsView.__super__.afterRender.apply(this, arguments);
    return this.showCurrentTab();
  };

  TabsView.prototype.showCurrentTab = function() {
    var tabView, tabViewData, _ref;
    this.trigger('tabSwitched', this.currentTab);
    tabViewData = this.tabViews[this.currentTab];
    tabView = new tabViewData.view(_((_ref = tabViewData.options) != null ? _ref : {}).extend({
      autoRender: false
    }));
    this.subview('currentTab', tabView);
    return this.$('.tab-container').html(tabView.render().$el);
  };

  TabsView.prototype.getTemplateData = function() {
    var tab;
    return {
      tabNames: (function() {
        var _i, _len, _ref, _results;
        _ref = this.tabViews;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          tab = _ref[_i];
          _results.push(tab.title);
        }
        return _results;
      }).call(this),
      currentTab: this.currentTab
    };
  };

  TabsView.prototype.openTab = function(e) {
    var $target, tabIndex;
    $target = $(e.target);
    tabIndex = $target.data('tabIndex');
    if (tabIndex === this.currentTab) {
      return;
    }
    this.currentTab = tabIndex;
    this.$('.current-tab').removeClass('current-tab');
    $target.addClass('current-tab');
    return this.showCurrentTab();
  };

  return TabsView;

})(View);
