DatePicker = require 'components/common/date_picker'
IconButton = require 'components/common/icon_button'
TasksActions = require 'actions/tasks_actions'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'

TABLE_COLUMNS = [
  'done'
  'assignedTo'
  'dueDate'
  'condition'
  'title'
  'reviewedBy'
]

TasksTable = ({ tasks, sort }) ->

  i18n = useI18n('tasks:form')

  <table className="tasks-table w-full">
    <thead>
      <tr>
        {TABLE_COLUMNS.map (column) ->

          upIconClassess = classNames
            'active': sort.get('direction') is 'asc' and sort.get('key') is column

          downIconClassess = classNames
            'active': sort.get('direction') is 'desc' and sort.get('key') is column

          <th key={column}>
            <div className="flex flex-row items-center">
              <div className="flex-grow">{i18n "fields.#{column}"}</div>
              <div className="flex flex-col column-sorters">
                <IconButton
                  className={upIconClassess}
                  iconName="collapse"
                  onClick={-> TasksActions.sort({ direction: 'asc', key: column})}
                />
                <IconButton
                  className={downIconClassess}
                  iconName="expand"
                  onClick={-> TasksActions.sort({ direction: 'desc', key: column})}
                />
              </div>
            </div>
          </th>
        }
        <th className="actions"/>
      </tr>
    </thead>
    <tbody>
      {tasks.map (task) -> <Task key={task.get('_id')} task={task} />}
    </tbody>
  </table>

Task = ({ task }) ->

  i18n = useI18n()

  onDateSave = useCoffeeCallback [], (_e, newDate) ->

    momentDate = moment(newDate)

    TasksActions.updateTask task.withMutations (t) ->
      t.set 'dueDate', momentDate.valueOf()
      t.set 'start', momentDate.startOf('day').unix()
      t.set 'end', momentDate.endOf('day').unix()

  onToogleTaskDone = -> TasksActions.toggleTaskDone(task.get('_id'))

  taskDate = moment(task.get('dueDate') or task.get('start')).format('DD-MM-YYYY')

  <tr>
    {TABLE_COLUMNS.map (column) ->
      <td key={column} className={column}>
        {switch(column)
          when 'done'
            <input
              type='checkbox'
              checked={task.get('done')}
              onChange={onToogleTaskDone}
            />
          when 'dueDate'
            <DatePicker
              inputHidden
              value={taskDate}
              onSave={onDateSave}
              selectedDays={[new Date(task.get('dueDate'))]}
              trigger={
                <span className="button mr-20">
                  {taskDate}
                </span>
              }
            />
          else
            task.get(column)
        }
      </td>
    }
    <td className="actions">
      <IconButton
        className="ml-5 mr-5"
        iconName="edit"
        title={i18n 'tasks:todo.edit'}
        onClick={-> TasksActions.openCreateOrEditDialog task}
      />
      <IconButton
        iconName="delete"
        title={i18n 'tasks:todo.delete'}
        onClick={-> TasksActions.openDeleteConfirmationDialog(task)}
      />
    </td>
  </tr>


module.exports = TasksTable
