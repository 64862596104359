# takes following mandatory arguments and returns API:
#   stateProp: String
#     the name of the key to save toggling state in @state
#   collectionProp: String
#     the name of the key under which the collection of toggleble items can be found
#     inside @props. IMPORTANT: colleciton itself must be an Immutable Iterable
#     object (we rely on Immutable API)
#   itemId: String
#     the name of the attr to be used as item's unique identifier (e.g. '_id'). It is assumed that
#     calling get(itemId) on an item will return an ID string. If itemId starts with
#     '*' it is considered to be a name of Component's method which when called on collection,
#     should return an Immutable List of ID strings - useful when initial collection should be
#     filterid with Component's method.


{ getKeyValObject } = require('base/lib/utils')

ToggleableItemsMixin = (stateProp, collectionProp, itemId) ->
  getInitialState: ->
    getKeyValObject stateProp, Immutable.List()

  _getAllItemsIds: ->
    throw new Error 'itemId must be a String' unless _.isString itemId
    if itemId.charAt(0) is '*'
      @[itemId.substr 1] @props[collectionProp]
    else
      @props[collectionProp].map (item) -> item.get itemId

  _areAllItemsSelected: ->
    Boolean @state[stateProp].size and
      @state[stateProp].size is @_getAllItemsIds().size

  toggleItem: (itemId) ->
    selectedItems = @state[stateProp]
    if selectedItems.includes itemId
      @setState getKeyValObject stateProp, selectedItems.filter (id) -> id isnt itemId
    else
      @setState getKeyValObject stateProp, selectedItems.push itemId

  checkAllItems: ->
    @setState getKeyValObject stateProp, @_getAllItemsIds()

  uncheckAllItems: ->
    @setState getKeyValObject stateProp, Immutable.List()

  toggleAllItems: ->
    if @_areAllItemsSelected() then @uncheckAllItems() else @checkAllItems()

module.exports = ToggleableItemsMixin
