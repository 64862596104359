var ProjectTask, UpdateMetadocAfterRevmanWebImport, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ProjectTask = require('lib/project_tasks/task');

mediator = require('mediator');

module.exports = UpdateMetadocAfterRevmanWebImport = (function(_super) {
  __extends(UpdateMetadocAfterRevmanWebImport, _super);

  function UpdateMetadocAfterRevmanWebImport() {
    UpdateMetadocAfterRevmanWebImport.__super__.constructor.call(this, 'update_metadoc_after_revman_web_import');
  }

  UpdateMetadocAfterRevmanWebImport.prototype.unsafeRun = function(projectId) {
    return mediator.projects.getOrFetch(projectId).then(function(project) {
      var currentDataVersion, dataVersion, versionFromMetadoc;
      currentDataVersion = mediator.services.projectMigration.getCurrentDataVersion();
      versionFromMetadoc = project.get('$data_version');
      dataVersion = versionFromMetadoc === -1 ? currentDataVersion : versionFromMetadoc;
      return mediator.services.modules.enableModulesForPreset('cochrane', project).then(function() {
        project.set('$data_version', dataVersion);
        return project.save();
      });
    }).then(function() {
      return {
        message: "Project metadoc has been updated for " + projectId + " (after Revman WEB import)"
      };
    });
  };

  return UpdateMetadocAfterRevmanWebImport;

})(ProjectTask);
