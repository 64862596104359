Translation = require 'components/mixins/translation'

DisseminationToolbar = createReactClass
  displayName: 'DisseminationToolbar'
  mixins: [Translation('dissemination:titles')]
  propTypes:
    submodule: PropTypes.string.isRequired

  render: ->
    { submodule } = @props
    title = @i18n _.str.underscored submodule
    return null if title is "dissemination:titles.#{_.str.underscored submodule}"
    <h2 className={"toolbar-title #{submodule}"}>{title}</h2>

module.exports = DisseminationToolbar
