var DiscardChangesActions, DiscardChangesStore, alt, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

DiscardChangesActions = require('actions/discard_changes_actions');

DiscardChangesStore = (function() {
  function DiscardChangesStore() {
    this.state = Immutable.fromJS({
      callback: null,
      hasChanges: false
    });
    this.bindActions(DiscardChangesActions);
    this.exportPublicMethods({
      getCallback: this.getCallback,
      hasChanges: this.hasChanges
    });
  }

  DiscardChangesStore.prototype.onSetCallback = function(callback) {
    return this.setState(this.state.set('callback', callback));
  };

  DiscardChangesStore.prototype.onSetHasChanges = function(hasChanges) {
    return this.setState(this.state.set('hasChanges', hasChanges));
  };

  DiscardChangesStore.prototype.onClear = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.set('callback', null);
      return state.set('hasChanges', false);
    }));
  };

  DiscardChangesStore.prototype.getCallback = function() {
    return this.state.get('callback');
  };

  DiscardChangesStore.prototype.hasChanges = function() {
    return this.state.get('hasChanges');
  };

  return DiscardChangesStore;

})();

module.exports = alt.createStore(immutable(DiscardChangesStore), 'DiscardChangesStore');
