Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
LimitCounter = require 'components/common/limit_counter'
MdgCreateQuestionDialog = require 'components/mdg_tables/create_question_dialog'
mediator = require 'mediator'
QuestionsActions = require 'actions/questions_actions'
QuestionsStore = require 'stores/questions_store'
Translation = require 'components/mixins/translation'
withLimits = require 'components/enhancers/with_limits'

storeConnector =
  QuestionsStore: (Store) ->
    isFetching: Store.isFetching()
    questionsCount: Store.getQuestionsCount()

QuestionsListToolbar = createReactClass
  displayName: "QuestionsListToolbar"

  propTypes:
    isFetching: PropTypes.bool.isRequired
    questionsCount: PropTypes.number.isRequired
    limits: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [Translation("es:question")]

  render: ->
    { isFetching, limits, questionsCount } = @props

    isMdgTablesFeatureOn = mediator.services.switches.isOn 'mdgTables'

    return null if isFetching
    <div className="flex flex-row">
      <div className="flex-grow">
        <LimitCounter
          count={questionsCount}
          limit={limits.get('questions')}
          title={@i18n 'question_list'}
        />
      </div>
      {isMdgTablesFeatureOn && <div className="flex flex-row items-center justify-end">
        <div className="mr-10">
          <Button
            className="btn btn-block btn-apply"
            label={@i18n '/actions.new_question'}
            onClick={QuestionsActions.openMdgCreateQuestionDialog}
          />
        </div>
        <MdgCreateQuestionDialog />
      </div>}
    </div>

module.exports = withLimits(ConnectStore(QuestionsListToolbar, [QuestionsStore], storeConnector))
