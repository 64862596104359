var IMPORT_MODAL_INITIAL_STATE, MembersActions, MembersStore, alt, errorHandler, errorHandlerWithMsg, immutable, mediator, parseAccessRights, parseRoles, sortMembers, storeHelpers, _ref;

alt = require('alt');

immutable = require('stores/utils/immutable');

mediator = require('mediator');

MembersActions = require('actions/members_actions');

storeHelpers = require('stores/utils/store_helpers');

_ref = require('lib/app_utils'), errorHandler = _ref.errorHandler, errorHandlerWithMsg = _ref.errorHandlerWithMsg;

parseRoles = function(roles) {
  if (!roles) {
    return "";
  }
  return roles.interpose(',').toArray().toString();
};

parseAccessRights = function(accessRights) {
  if (!accessRights) {
    return "";
  }
  return accessRights.interpose(',').toArray().toString();
};

sortMembers = function(members, sortBy) {
  var direction, key, _ref1, _ref2;
  if (!sortBy) {
    return members;
  }
  key = (_ref1 = sortBy.get('key')) != null ? _ref1 : "";
  direction = (_ref2 = sortBy.get('direction')) != null ? _ref2 : -1;
  switch (key) {
    case 'name':
      return members.sort(function(a, b) {
        var _ref3;
        return direction * ((_ref3 = a.get('lastName')) != null ? _ref3.localeCompare(b.get('lastName', "")) : void 0);
      });
    case 'panel-member':
      return members.sort(function(a, b) {
        var aPanelMember, bPanelMember;
        aPanelMember = a.get('panelMember') ? 1 : 0;
        bPanelMember = b.get('panelMember') ? 1 : 0;
        return direction * (aPanelMember - bPanelMember);
      });
    case 'access-rights':
      return members.sort(function(a, b) {
        var aAccess, bAccess;
        aAccess = parseAccessRights(a.get('accessRights'));
        bAccess = parseAccessRights(b.get('accessRights'));
        return direction * aAccess.localeCompare(bAccess);
      });
    default:
      return members;
  }
};

IMPORT_MODAL_INITIAL_STATE = Immutable.fromJS({
  _meta: {
    isOpen: false,
    isFetching: false,
    isImporting: false
  },
  panelMembers: [],
  searchText: '',
  selectedMemberIds: []
});

MembersStore = (function() {
  function MembersStore() {
    var stateJS;
    stateJS = {
      _meta: {
        filterByAccessRight: "all",
        editing: null,
        sorting: {
          key: "name",
          direction: 1
        },
        fetching: false,
        openedAddMemberModal: "",
        validationErrors: {}
      },
      members: {},
      importModal: IMPORT_MODAL_INITIAL_STATE
    };
    this.state = Immutable.fromJS(stateJS);
    this.bindActions(MembersActions);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      getEditingKey: this.getEditingKey,
      getFilterByAccessRightKey: this.getFilterByAccessRightKey,
      getErrorMessageForMember: this.getErrorMessageForMember,
      getMember: this.getMember,
      getMembers: this.getMembers,
      getMemberNamesMap: this.getMemberNamesMap,
      getMeta: this.getMeta,
      getPanelMembers: this.getPanelMembers,
      getOpenedAddMemberModal: this.getOpenedAddMemberModal,
      getSorting: this.getSorting,
      getValidationErrors: this.getValidationErrors,
      isImportModalOpen: this.isImportModalOpen,
      isImportModalFetchingMembers: this.isImportModalFetchingMembers,
      isImporting: this.isImporting,
      getImportPanelMembers: this.getImportPanelMembers,
      getImportSearchText: this.getImportSearchText,
      getImportSelectedMemberIds: this.getImportSelectedMemberIds
    });
  }

  MembersStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  MembersStore.prototype.getEditingKey = function() {
    return this.state.getIn(['_meta', 'editing']);
  };

  MembersStore.prototype.getFilterByAccessRightKey = function() {
    return this.state.getIn(['_meta', 'filterByAccessRight']);
  };

  MembersStore.prototype.getMember = function(memberId) {
    return this.getMembers().find(function(member) {
      return member.get('_id') === memberId;
    });
  };

  MembersStore.prototype.getMembers = function() {
    var filterByAccessRight, members;
    members = this.state.get('members').filter(function(member) {
      return member.get('_id') && !member.get('deleted');
    });
    filterByAccessRight = this.state.getIn(['_meta', 'filterByAccessRight']);
    if (filterByAccessRight === 'all') {
      return members;
    }
    return members.filter(function(member) {
      return member.get('accessRights', Immutable.List()).contains(filterByAccessRight);
    });
  };

  MembersStore.prototype.getMeta = function() {
    return this.state.get('_meta');
  };

  MembersStore.prototype.getPanelMembers = function() {
    return this.getMembers().filter(function(member) {
      return member.get('panelMember');
    });
  };

  MembersStore.prototype.getOpenedAddMemberModal = function() {
    return this.state.getIn(['_meta', 'openedAddMemberModal'], null);
  };

  MembersStore.prototype.getSorting = function() {
    return this.state.getIn(['_meta', 'sorting'], Immutable.Map());
  };

  MembersStore.prototype.onDbChange = function(doc) {
    var deleted, members, _id;
    deleted = doc.deleted, _id = doc._id;
    members = deleted ? this.state.get('members')["delete"](_id) : this.state.get('members').set(_id, Immutable.fromJS(doc));
    return this.setState(this.state.set('members', members));
  };

  MembersStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  MembersStore.prototype.onFetchSuccess = function(members) {
    var membersObj, state;
    membersObj = storeHelpers.docsArrayToOrderedMap(members);
    state = this.state.set('members', this.updatedMembersOrder(membersObj, this.state.getIn(['_meta', 'sorting']))).setIn(['_meta', 'fetching'], false);
    return this.setState(state);
  };

  MembersStore.prototype.onFetchError = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    return errorHandler(err);
  };

  MembersStore.prototype.onAdd = function() {
    return this.setState(this.state.withMutations(function(state) {
      return state.set('members', storeHelpers.addNewMapEntry(state.get('members')));
    }));
  };

  MembersStore.prototype.onSort = function(key) {
    var direction, sortBy, state;
    direction = (function() {
      if (this.state.getIn(['_meta', 'sorting', 'key']) === key) {
        switch (this.state.getIn(['_meta', 'sorting', 'direction'], -1)) {
          case 1:
            return -1;
          case -1:
            return 1;
        }
      } else {
        return 1;
      }
    }).call(this);
    sortBy = Immutable.fromJS({
      key: key,
      direction: direction
    });
    state = this.state.setIn(['_meta', 'sorting'], sortBy);
    state = state.set('members', this.updatedMembersOrder(this.state.get('members'), sortBy));
    return this.setState(state);
  };

  MembersStore.prototype.onFilterByAccessRight = function(key) {
    return this.setState(this.state.setIn(['_meta', 'filterByAccessRight'], key));
  };

  MembersStore.prototype.updatedMembersOrder = function(members, sortBy) {
    return sortMembers(members, sortBy);
  };

  MembersStore.prototype.onEdit = function(memberId) {
    return this.setState(this.state.setIn(['_meta', 'editing'], memberId));
  };

  MembersStore.prototype.onUpdateMember = function(_arg) {
    var attr, memberId, value;
    memberId = _arg.memberId, attr = _arg.attr, value = _arg.value;
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['members', memberId, attr], value);
      return s.updateIn(['_meta', 'validationErrors', memberId], function(errors) {
        if (errors) {
          return errors["delete"](attr);
        }
      });
    }));
  };

  MembersStore.prototype.onBatchUpdateMember = function(_arg) {
    var memberId, updates;
    memberId = _arg.memberId, updates = _arg.updates;
    return this.setState(this.state.withMutations(function(s) {
      return _.each(updates, function(_arg1) {
        var attr, value;
        attr = _arg1.attr, value = _arg1.value;
        s.setIn(['members', memberId, attr], value);
        return s.updateIn(['_meta', 'validationErrors', memberId], function(errors) {
          if (errors) {
            return errors["delete"](attr);
          }
        });
      });
    }));
  };

  MembersStore.prototype.getErrorMessageForMember = function(memberId) {
    return this.state.getIn(['_meta', 'validationErrors', memberId], Immutable.Map());
  };

  MembersStore.prototype.getValidationErrors = function() {
    return this.state.getIn(['_meta', 'validationErrors'], Immutable.Map());
  };

  MembersStore.prototype.onSaveSuccess = function(memberJS) {
    var sortBy, state, updatedMembers;
    updatedMembers = storeHelpers.replaceMapEntry(this.state.get('members'), memberJS._id, memberJS);
    sortBy = this.state.getIn(['_meta', 'sorting']);
    state = this.state.withMutations((function(_this) {
      return function(state) {
        return state.set('members', _this.updatedMembersOrder(updatedMembers, sortBy));
      };
    })(this));
    state = state.setIn(['_meta', 'status'], 'saved').setIn(['_meta', 'editing'], null).setIn(['_meta', 'validationErrors'], Immutable.Map());
    return this.setState(state);
  };

  MembersStore.prototype.deleteErrors = function(memberId) {
    return this.setState(this.state.deleteIn(['_meta', 'validationErrors', memberId]));
  };

  MembersStore.prototype.onResetErrors = function(memberId) {
    return this.deleteErrors(memberId);
  };

  MembersStore.prototype.onValidationSuccess = function(memberId) {
    return this.deleteErrors(memberId);
  };

  MembersStore.prototype.updateValidationErrors = function(errors) {
    return this.setState(this.state.setIn(['_meta', 'validationErrors'], errors));
  };

  MembersStore.prototype.onValidationFailure = function(data) {
    return this.setState(this.state.setIn(['_meta', 'validationErrors', data.key], Immutable.fromJS(data.validationErrors)));
  };

  MembersStore.prototype.onDeleteSuccess = function(memberId) {
    var state;
    state = this.state.deleteIn(['members', memberId]).setIn(['_meta', 'validationErrors'], Immutable.Map());
    return this.setState(state);
  };

  MembersStore.prototype.onSaveFailure = errorHandler;

  MembersStore.prototype.onDeleteFailure = errorHandler;

  MembersStore.prototype.onOpenModal = function(type) {
    return this.setState(this.state.setIn(['_meta', 'openedAddMemberModal'], type));
  };

  MembersStore.prototype.onCloseModal = function() {
    return this.setState(this.state.setIn(['_meta', 'openedAddMemberModal'], ''));
  };

  MembersStore.prototype.getMemberNamesMap = function(anonymous) {
    if (anonymous == null) {
      anonymous = false;
    }
    return this.state.get('members').reduce(function(acc, member, idx) {
      var memberId, memberName;
      memberId = member.get('_id');
      memberName = anonymous ? "" + ($.t('team:member.panel_member')) + " " + (acc.keySeq().size + 1) : "" + (member.get('givenNames', '')) + " " + (member.get('lastName', ''));
      return acc.set(memberId, memberName);
    }, Immutable.Map());
  };

  MembersStore.prototype.onOpenImportModal = function() {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['importModal', '_meta', 'isOpen'], true);
      return s.setIn(['importModal', '_meta', 'isFetching'], true);
    }));
  };

  MembersStore.prototype.onImportFetchSuccess = function(_arg) {
    var panelMembers;
    panelMembers = _arg.panelMembers;
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['importModal', '_meta', 'isFetching'], false);
      return s.setIn(['importModal', 'panelMembers'], Immutable.fromJS(panelMembers));
    }));
  };

  MembersStore.prototype.onImportFetchFailure = function(err) {
    this.setState(this.state.set('importModal', IMPORT_MODAL_INITIAL_STATE));
    return errorHandlerWithMsg(err, $.t('team:import_modal.fetch_failure'));
  };

  MembersStore.prototype.onCloseImportModal = function() {
    return this.setState(this.state.set('importModal', IMPORT_MODAL_INITIAL_STATE));
  };

  MembersStore.prototype.onClearImportSearchText = function() {
    return this.setState(this.state.setIn(['importModal', 'searchText'], ''));
  };

  MembersStore.prototype.onSetImportSearchText = function(searchText) {
    return this.setState(this.state.setIn(['importModal', 'searchText'], searchText));
  };

  MembersStore.prototype.onToggleImportSelectAllMembers = function() {
    var panelMembers, selectedMemberIds, updatedSelectedMemberIds;
    selectedMemberIds = this.state.getIn(['importModal', 'selectedMemberIds']);
    panelMembers = this.getImportPanelMembers();
    updatedSelectedMemberIds = panelMembers.size === selectedMemberIds.size ? Immutable.List() : panelMembers.map(function(member) {
      return member.get('_id');
    });
    return this.setState(this.state.setIn(['importModal', 'selectedMemberIds'], updatedSelectedMemberIds));
  };

  MembersStore.prototype.onToggleImportMember = function(memberId) {
    var selectedMemberIds, updatedSelectedMemberIds;
    selectedMemberIds = this.state.getIn(['importModal', 'selectedMemberIds']);
    updatedSelectedMemberIds = selectedMemberIds.includes(memberId) ? selectedMemberIds.filter(function(id) {
      return id !== memberId;
    }) : selectedMemberIds.push(memberId);
    return this.setState(this.state.setIn(['importModal', 'selectedMemberIds'], updatedSelectedMemberIds));
  };

  MembersStore.prototype.onImport = function() {
    return this.setState(this.state.setIn(['importModal', '_meta', 'isImporting'], true));
  };

  MembersStore.prototype.onImportSuccess = function() {
    this.setState(this.state.set('importModal', IMPORT_MODAL_INITIAL_STATE));
    return mediator.dialogs.info($.t('team:import_modal.success'));
  };

  MembersStore.prototype.onImportFailure = function(err) {
    this.setState(this.state.set('importModal', IMPORT_MODAL_INITIAL_STATE));
    return errorHandlerWithMsg(err, $.t('team:import_modal.failure'));
  };

  MembersStore.prototype.isImportModalOpen = function() {
    return this.state.getIn(['importModal', '_meta', 'isOpen']);
  };

  MembersStore.prototype.isImportModalFetchingMembers = function() {
    return this.state.getIn(['importModal', '_meta', 'isFetching']);
  };

  MembersStore.prototype.isImporting = function() {
    return this.state.getIn(['importModal', '_meta', 'isImporting']);
  };

  MembersStore.prototype.getImportPanelMembers = function() {
    var panelMembers, panelMembersToReturn, searchText;
    searchText = this.getImportSearchText().toLowerCase();
    panelMembers = this.state.getIn(['importModal', 'panelMembers']);
    panelMembersToReturn = searchText ? panelMembers.filter(function(member) {
      var givenNames, lastName, _ref1, _ref2;
      lastName = ((_ref1 = member.get('lastName')) != null ? _ref1 : '').toLowerCase();
      givenNames = ((_ref2 = member.get('givenNames')) != null ? _ref2 : '').toLowerCase();
      return lastName.indexOf(searchText) >= 0 || givenNames.indexOf(searchText) >= 0;
    }) : panelMembers;
    return panelMembersToReturn.sortBy(function(member) {
      var _ref1, _ref2;
      return [(_ref1 = member.get('lastName')) != null ? _ref1 : '', (_ref2 = member.get('givenNames')) != null ? _ref2 : ''];
    });
  };

  MembersStore.prototype.getImportSearchText = function() {
    return this.state.getIn(['importModal', 'searchText']);
  };

  MembersStore.prototype.getImportSelectedMemberIds = function() {
    return this.state.getIn(['importModal', 'selectedMemberIds']);
  };

  return MembersStore;

})();

module.exports = alt.createStore(immutable(MembersStore), 'MembersStore');
