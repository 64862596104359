CheckboxInput = createReactClass
  displayName: 'CheckboxInput'

  _ref: (el) ->
    @input = el

  isChecked: -> @input.checked

  render: ->
    <input {...@props} type="checkbox" ref={@_ref} />

module.exports = CheckboxInput
