var CADTH_GUIDELINE_DOC_TYPE, CadthGuidelines, CadthGuidelinesActions, CadthGuidelinesToolbar, CadthGuidelinesView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

CadthGuidelines = require('components/cadth_guidelines/cadth_guidelines');

CadthGuidelinesToolbar = require('components/cadth_guidelines/cadth_guidelines_toolbar');

CadthGuidelinesActions = require('actions/cadth_guidelines_actions');

CADTH_GUIDELINE_DOC_TYPE = require('lib/cadth_guidelines_helper').CADTH_GUIDELINE_DOC_TYPE;

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = CadthGuidelinesView = (function(_super) {
  __extends(CadthGuidelinesView, _super);

  function CadthGuidelinesView() {
    return CadthGuidelinesView.__super__.constructor.apply(this, arguments);
  }

  CadthGuidelinesView.prototype.container = '#page-container';

  CadthGuidelinesView.prototype.autoRender = true;

  CadthGuidelinesView.prototype._stopInputsPropagation = false;

  CadthGuidelinesView.prototype.initialize = function() {
    CadthGuidelinesView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: CadthGuidelinesToolbar
    }));
    return this.enable(ReactComponent);
  };

  CadthGuidelinesView.prototype.afterRender = function() {
    CadthGuidelinesView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(CadthGuidelines, CadthGuidelinesActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id,
        opts: {
          filter: function(doc) {
            return doc.docType === CADTH_GUIDELINE_DOC_TYPE;
          }
        }
      }
    });
  };

  CadthGuidelinesView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('CadthGuidelinesStore');
    return CadthGuidelinesView.__super__.dispose.apply(this, arguments);
  };

  return CadthGuidelinesView;

})(View);
