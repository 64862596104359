var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "    <div class=\"form-group row mt-10 non-event\" data-property=\"nonEvent\">\n      <div>\n        <label class=\"footnote-nonEvent\" for=\"non-event\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "event_mode", {"name":"i18n","hash":{},"data":data})))
    + "\n        </label>\n        <div class=\"inline\">\n          <div class=\"select-input-container non-event\">\n            "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "eventType", (depth0 != null ? depth0.eventType : depth0), "event: event, non_event: non_event", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n            <input id=\"non-event\" data-property=\"nonEvent\"\n              name=\"nonEvent\"\n              type=\"text\"\n              placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.time_to_event.non_event_placeholder", {"name":"i18n","hash":{},"data":data})))
    + "\"\n              value=\""
    + escapeExpression(((helper = (helper = helpers.nonEvent || (depth0 != null ? depth0.nonEvent : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"nonEvent","hash":{},"data":data}) : helper)))
    + "\"\n              ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.eventType : depth0), "event", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n            >\n          </div>\n        </div>\n        <div class=\"sup\"></div>\n      </div>\n    </div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "style=\"display: none\"";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div>\n  <label class=\"radio-label\"><input type=\"radio\" data-property=\"type\"\n    name=\"type"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\" "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "time_to_event", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "time_to_event", {"name":"i18n","hash":{},"data":data})))
    + "\n  </label>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "time_to_event", {"name":"is","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}