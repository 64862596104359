var MDGToolbarView, MdgRecommendation, ReactComponent, RecommendationView, RecommendationsToolbar, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

MDGToolbarView = require('views/mdg_tables/toolbar_view');

RecommendationsToolbar = require('components/mdg_tables/recommendations_toolbar');

MdgRecommendation = require('components/mdg_tables/recommendations');

module.exports = RecommendationView = (function(_super) {
  __extends(RecommendationView, _super);

  function RecommendationView() {
    return RecommendationView.__super__.constructor.apply(this, arguments);
  }

  RecommendationView.prototype.container = '#page-container';

  RecommendationView.prototype.autoRender = true;

  RecommendationView.prototype.initialize = function() {
    RecommendationView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new MDGToolbarView({
      mode: 'recommendation'
    }));
    this._stopInputsPropagation = false;
    return this.enable(ReactComponent);
  };

  RecommendationView.prototype.afterRender = function() {
    var questionId;
    RecommendationView.__super__.afterRender.apply(this, arguments);
    questionId = this.options.questionId;
    return this.renderSimpleComponent(MdgRecommendation, {
      questionId: questionId
    });
  };

  RecommendationView.prototype.dispose = function() {
    this.unmountComponent();
    return RecommendationView.__super__.dispose.apply(this, arguments);
  };

  return RecommendationView;

})(View);
