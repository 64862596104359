TogglePanel = require 'components/common/toggle_panel'
Translation = require 'components/mixins/translation'
SectionCheckbox = require 'components/administration/section_checkbox'
EtdTemplatesActions = require 'actions/etd_templates_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

PresentationsBlockSections = createReactClass
  displayName: 'PresentationsBlockSections'

  mixins: [Translation('settings:etd_templates'), CustomRenderMixin]

  togglePresentationSection: (presentationGroup, section) -> ->
    EtdTemplatesActions.togglePresentationSection presentationGroup, section

  render: ->
    <TogglePanel title={@i18n 'presentations'} className='block'>
      <table className='presentations-table'>
        <tbody>
          <tr>
            {@props.blockDef.map (presentationGroup) =>
              id = presentationGroup.get('id')
              sectionName = @props.blockData.getIn ['sections', id, 'name']
              <td key={id}>
                <SectionCheckbox
                  className='presentations-section-checkbox'
                  blockName={'presentations'}
                  sectionDef={presentationGroup}
                  label={sectionName}
                  onSectionToggle={EtdTemplatesActions.toggleSection}
                />
                <ul>
                  {presentationGroup.get('sections').map (presentationSection) =>
                    presentationSectionId = presentationSection.get('id')
                    disabled = not presentationGroup.get('checked')
                    classes = classNames 'section-checkbox', 'disabled': disabled
                    <li key={presentationSectionId}>
                      <label key={presentationSectionId} className={classes}>
                        <input
                          type="checkbox"
                          disabled={disabled}
                          checked={presentationSection.get('checked')}
                          onChange={@togglePresentationSection id, presentationSectionId}
                          />
                        <span>
                          {@props.blockData.getIn ['sections', id, 'sections',
                            presentationSectionId, 'name']}
                        </span>
                      </label>
                    </li>
                  }
                </ul>
              </td>
            }
          </tr>
        </tbody>
      </table>
    </TogglePanel>

module.exports = PresentationsBlockSections
