var INITIAL_STATE, QualityIndicatorsActions, addIdFn, filterOutFn, generateStore;

generateStore = require('stores/utils/generate_store');

QualityIndicatorsActions = require('actions/quality_indicators_actions');

addIdFn = function(idToAdd) {
  return function(rows) {
    return rows.push(idToAdd);
  };
};

filterOutFn = function(idToFilterOut) {
  return function(rows) {
    return rows.filter(function(id) {
      return id !== idToFilterOut;
    });
  };
};

INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false,
    fetchingError: null,
    editRows: Immutable.List(),
    showExportDialog: false
  },
  docId: null,
  qualityIndicators: [],
  currentView: 'candidates'
});

module.exports = generateStore({
  name: 'QualityIndicatorsStore',
  initialState: INITIAL_STATE,
  boundActions: [QualityIndicatorsActions],
  methods: {
    onDbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      return this.setState(this.state.set('qualityIndicators', Immutable.fromJS(doc.qualityIndicators)));
    },
    onFetch: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'isFetching'], true);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchSuccess: function(doc) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'isFetching'], false);
        state.set('docId', doc._id);
        state.set('qualityIndicators', Immutable.fromJS(doc.qualityIndicators));
        state.set('currentView', doc.currentView);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchError: function(err) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'isFetching'], false);
        state.set('docId', null);
        state.set('qualityIndicators', []);
        return state.setIn(['meta', 'fetchingError'], err);
      }));
    },
    onAddRow: function(qiId) {
      return this.setState(this.state.withMutations(function(state) {
        return state.updateIn(['meta', 'editRows'], addIdFn(qiId));
      }));
    },
    onDeleteRow: function(qiId) {
      return this.setState(this.state.withMutations(function(state) {
        return state.updateIn(['meta', 'editRows'], filterOutFn(qiId));
      }));
    },
    onEditRow: function(qiId) {
      return this.setState(this.state.updateIn(['meta', 'editRows'], addIdFn(qiId)));
    },
    onSaveRow: function(qiId) {
      return this.setState(this.state.updateIn(['meta', 'editRows'], filterOutFn(qiId)));
    },
    onChangeView: function(view) {
      return this.setState(this.state.set('currentView', view));
    },
    onOpenExportDialog: function() {
      return this.setState(this.state.setIn(['meta', 'showExportDialog'], true));
    },
    onHideExportDialog: function() {
      return this.setState(this.state.setIn(['meta', 'showExportDialog'], false));
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'isFetching']);
    },
    getFetchingError: function() {
      return this.state.getIn(['meta', 'fetchingError']);
    },
    getCurrentDocId: function() {
      return this.state.get('docId', null);
    },
    getQualityIndicators: function() {
      return this.state.get('qualityIndicators', Immutable.List());
    },
    getQualityIndicator: function(qiId) {
      return this.getQualityIndicators().find(function(qualityIndicator) {
        return qualityIndicator.get('id') === qiId;
      });
    },
    getCurrentView: function() {
      return this.state.get('currentView');
    },
    getQualityIndicatorForOutcome: function(outcomeId) {
      return this.getQualityIndicators().find(function(qualityIndicator) {
        return qualityIndicator.get('outcome') === outcomeId;
      });
    },
    getShowExportDialog: function() {
      return this.state.getIn(['meta', 'showExportDialog']);
    },
    getEditingRows: function() {
      return this.state.getIn(['meta', 'editRows'], Immutable.List());
    }
  }
});
