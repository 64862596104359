Popover = require 'components/common/popover'

DropdownMenu = createReactClass
  displayName: 'DropdownMenu'

  propTypes:
    onSelect: PropTypes.func.isRequired
    onMenuOpen: PropTypes.func
    className: PropTypes.string
    menuPosition: PropTypes.object # see Popover.cjsx `positionParams` prop
    options: PropTypes.arrayOf(PropTypes.shape({
      className: PropTypes.string
      text: PropTypes.string.isRequired
      value: PropTypes.string.isRequired
    })).isRequired
    selectedValue: PropTypes.string

  getInitialState: ->
    menuOpen: false

  toggleMenuOpen: (evt) ->
    evt.stopPropagation()
    nextMenuOpen = !@state.menuOpen
    @setState
      menuOpen: nextMenuOpen
    if nextMenuOpen and @props.onMenuOpen
      @props.onMenuOpen()

  handleSelect: (selectedValue) ->
    @props.onSelect selectedValue
    @setState
      menuOpen: false

  render: ->
    { opener, options, className, menuPosition, selectedValue } = @props
    { menuOpen } = @state

    <Popover visible={menuOpen} onRequestClose={@toggleMenuOpen} positionParams={menuPosition}>
      {React.cloneElement(opener, {onClick: @toggleMenuOpen})}
      <ul className={classNames('dropdown-menu-options', className)}>
        {
          options.map (option, idx) =>
            active = option.value is selectedValue
            if option.value is '__separator__'
              <li
                className='dropdown-menu-option separator'
                key={"#{option.value}-#{idx}"} 
                disabled
              />
            else
              <li
                key={option.value}
                className={classNames 'dropdown-menu-option', { active }, option.className}
                onClick={() => @handleSelect(option.value)}
              >
                {option.text}
              </li>
        }
      </ul>
    </Popover>

module.exports = DropdownMenu
