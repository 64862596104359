var TASK, TASK_PREFIX, TaskDoc, TaskDocShape, appUtils, generateActions, generateGUID, mediator;

appUtils = require('lib/app_utils');

generateActions = require('actions/utils/generate_actions');

mediator = require('mediator');

TASK = require('lib/db_docs/doc_types').TASK;

TaskDoc = require('lib/db_docs/task_doc');

TaskDocShape = require('lib/db_docs/doc_shapes/task_doc_shape');

TASK_PREFIX = require('lib/doc_prefixes').task;

generateGUID = require('base/lib/utils').generateGUID;

module.exports = generateActions({
  autoGenerate: ['fetchSuccess', 'fetchError', 'closeCreateOrEditDialog', 'openDeleteConfirmationDialog', 'closeDeleteConfirmationDialog', 'sort', 'search', 'toggleCompletedFilter'],
  name: 'TasksActions',
  actions: {
    dbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      if (doc.docType === TASK) {
        return this.dispatch(doc);
      }
    },
    fetch: function() {
      this.dispatch();
      return TaskDoc.at(mediator.project.id).fetchMany().then(this.actions.fetchSuccess)["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandler(err);
          return _this.actions.fetchError();
        };
      })(this));
    },
    createTask: function(task) {
      var id;
      id = "" + TASK_PREFIX + (generateGUID());
      return TaskDoc.at(mediator.project.id).create(_.extend({
        _id: id
      }, task.toJS()))["catch"](function(err) {
        return appUtils.errorHandlerWithMsg(err, $.t('tasks:errors.create_error'));
      })["finally"]((function(_this) {
        return function() {
          return _this.actions.closeCreateOrEditDialog();
        };
      })(this));
    },
    updateTask: function(task) {
      return TaskDoc.at(mediator.project.id, task.get('_id')).update(task.toJS())["catch"](function(err) {
        return appUtils.errorHandlerWithMsg(err, $.t('tasks:errors.update_error'));
      })["finally"]((function(_this) {
        return function() {
          return _this.actions.closeCreateOrEditDialog();
        };
      })(this));
    },
    deleteTask: function(task) {
      return TaskDoc.at(mediator.project.id, task.get('_id'))["delete"]()["catch"](function(err) {
        return appUtils.errorHandlerWithMsg(err, $.t('tasks:errors.delete_error'));
      })["finally"]((function(_this) {
        return function() {
          return _this.actions.closeDeleteConfirmationDialog();
        };
      })(this));
    },
    toggleTaskDone: function(taskId) {
      return TaskDoc.at(mediator.project.id, taskId).update(function(task) {
        task.done = !task.done;
        return task;
      })["catch"](function(err) {
        return appUtils.errorHandler(err);
      });
    },
    openCreateOrEditDialog: function(task) {
      if (task == null) {
        task = null;
      }
      return this.dispatch(task || Immutable.fromJS(_.omit(TaskDocShape.defaults(), '_id')));
    }
  }
});
