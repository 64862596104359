var __slice = [].slice;

module.exports = {
  enable: function() {
    var args, trait, _ref;
    trait = arguments[0], args = 2 <= arguments.length ? __slice.call(arguments, 1) : [];
    this.traitInstances = (_ref = this.traitInstances) != null ? _ref : [];
    trait = (function(func, args, ctor) {
      ctor.prototype = func.prototype;
      var child = new ctor, result = func.apply(child, args);
      return Object(result) === result ? result : child;
    })(trait, args, function(){});
    this.traitInstances.push(trait);
    return trait.apply(this);
  },
  _disableTraits: function() {
    var trait, _i, _len, _ref, _ref1;
    _ref1 = (_ref = this.traitInstances) != null ? _ref : [];
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      trait = _ref1[_i];
      trait.detachAttrs();
    }
    return this.traitInstances = null;
  }
};
