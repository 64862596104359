var Controller, GdtRouter, SubmoduleController, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Controller = require('./controller');

GdtRouter = require('router');

utils = require('base/lib/utils');

mediator = require('mediator');

module.exports = SubmoduleController = (function(_super) {
  __extends(SubmoduleController, _super);

  function SubmoduleController() {
    this.historyURL = __bind(this.historyURL, this);
    return SubmoduleController.__super__.constructor.apply(this, arguments);
  }

  SubmoduleController.prototype.historyURL = function(params) {
    return GdtRouter.prototype.routeForModule(params.module, params) + ("/" + params.submodule);
  };

  return SubmoduleController;

})(Controller);
