var ActiveFootnoteHighlight, AdaptiveLayout, CellFootnotesAndReferencesView, ContextMenuView, EditView, ExternalChangeHighlight, Footnotes, Outcome, OutcomeCellEditView, OutcomeView, View, WysiwygFootnotes, absoluteDifferenceTemplate, formatters, mediator, outcomeCellTypes, statisticalUtils, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

View = require('base/views/view');

EditView = require('base/views/edit_view');

OutcomeCellEditView = require('views/outcome_cell_edit_view');

Outcome = require('models/outcome');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

mediator = require('mediator');

utils = require('base/lib/utils');

statisticalUtils = require('lib/statistical_utils');

absoluteDifferenceTemplate = require('views/templates/evidence_syntheses/diagnostic/absolute_difference');

formatters = require('lib/formatters');

outcomeCellTypes = require('models/outcome/outcome_cell_types');

WysiwygFootnotes = require('lib/traits/wysiwyg_footnotes');

ActiveFootnoteHighlight = require('lib/traits/active_footnote_highlight');

ExternalChangeHighlight = require('lib/traits/external_change_highlight');

ContextMenuView = require('views/evidence_syntheses/context_menu_view');

Footnotes = require('models/evidence_syntheses/footnotes');

CellFootnotesAndReferencesView = require('views/evidence_syntheses/cell_footnotes_and_references_view');

module.exports = OutcomeView = (function(_super) {
  __extends(OutcomeView, _super);

  function OutcomeView() {
    this.manualQualityChanged = __bind(this.manualQualityChanged, this);
    this.forceQualityFootnotes = __bind(this.forceQualityFootnotes, this);
    this._toggleTableModeSpecificCell = __bind(this._toggleTableModeSpecificCell, this);
    this.showCellFootnotesView = __bind(this.showCellFootnotesView, this);
    this.editClosed = __bind(this.editClosed, this);
    return OutcomeView.__super__.constructor.apply(this, arguments);
  }

  OutcomeView.prototype.containerMethod = 'after';

  OutcomeView.prototype.tagName = 'tr';

  OutcomeView.prototype.renderItemViewsAfterNonStickedPropertyEdit = false;

  OutcomeView.prototype.attributes = function() {
    return {
      'data-view-cid': this.cid
    };
  };

  OutcomeView.prototype._getFormatterAttrsGetOnly = function(cellOptions) {
    var attr, attributes, model, value, _i, _len, _ref, _ref1, _ref2;
    attributes = {};
    _ref1 = (_ref = cellOptions.formatterAttrsGetOnly) != null ? _ref : [];
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      value = _ref1[_i];
      _ref2 = value.split('.'), model = _ref2[0], attr = _ref2[1];
      switch (model) {
        case 'question':
          attributes[attr] = this.questionModel.get(attr);
      }
    }
    return attributes;
  };

  OutcomeView.prototype._getFormatterAttributes = function(cellOptions, values) {
    var attributes, idx, value, _i, _len, _ref;
    if (cellOptions.formatterAttrsObserve) {
      attributes = {};
      for (idx = _i = 0, _len = values.length; _i < _len; idx = ++_i) {
        value = values[idx];
        attributes[cellOptions.formatterAttrsObserve[idx]] = value;
      }
      _(attributes).extend(this._getFormatterAttrsGetOnly(cellOptions));
      attributes._viewTypeName = (_ref = this.options) != null ? _ref.viewTypeName : void 0;
      return attributes;
    } else {
      return values;
    }
  };

  OutcomeView.prototype.bindCell = function(cellName, cellOptions) {
    var _ref;
    return {
      property: cellName,
      updateMethod: 'html',
      observe: (_ref = cellOptions.formatterAttrsObserve) != null ? _ref : cellName,
      onGet: function(values) {
        var attributes;
        if (_(formatters.outcome[cellOptions.cellFormatter]).isFunction()) {
          attributes = this._getFormatterAttributes(cellOptions, values);
          return formatters.outcome[cellOptions.cellFormatter].call(formatters.outcome, attributes);
        } else {
          return values;
        }
      }
    };
  };

  OutcomeView.prototype._getContentClass = function(contentDefaultClass) {
    return contentDefaultClass != null ? contentDefaultClass : 'cell-content';
  };

  OutcomeView.prototype._getCellSelector = function(cellName, cellOptions) {
    var _ref;
    return (_ref = cellOptions.cellCustomSelector) != null ? _ref : "[data-property=" + cellName + "]";
  };

  OutcomeView.prototype._getCellContentSelector = function(cellName, cellOptions) {
    var cellSelector, contentClass;
    contentClass = this._getContentClass(cellOptions.contentDefaultClass);
    cellSelector = this._getCellSelector(cellName, cellOptions);
    return "" + cellSelector + ":not(.disabled-cell) ." + contentClass;
  };

  OutcomeView.prototype._getOtherFormatterContentSelector = function(cellName, cellOptions, otherFormatter) {
    var cellSelector;
    cellSelector = this._getCellSelector(cellName, cellOptions);
    return "" + cellSelector + " ." + otherFormatter.contentDefaultClass;
  };

  OutcomeView.prototype._getOtherFormatterInnerCellSelector = function(cellName, cellOptions, otherFormatter) {
    var cellSelector;
    cellSelector = this._getCellSelector(cellName, cellOptions);
    return "" + cellSelector + " .inner-cell:has(." + otherFormatter.contentDefaultClass + ")";
  };

  OutcomeView.prototype.bindingsForCells = function() {
    var binds, cellName, cellOptions, otherFormatter, _i, _len, _ref;
    binds = {};
    for (cellName in outcomeCellTypes) {
      cellOptions = outcomeCellTypes[cellName];
      binds["" + (this._getCellContentSelector(cellName, cellOptions)) + " > span"] = this.bindCell(cellName, cellOptions);
      if (cellOptions.otherFormatters) {
        _ref = cellOptions.otherFormatters;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          otherFormatter = _ref[_i];
          binds[("" + (this._getOtherFormatterContentSelector(cellName, cellOptions, otherFormatter)) + " ") + ' > span'] = this.bindCell(cellName, otherFormatter);
        }
      }
    }
    return binds;
  };

  OutcomeView.prototype.bindings = OutcomeView.prototype.bindingsForCells();

  OutcomeView.prototype._pushAttrBindings = function(attrFootnotesBindings, cellName, selector, attrsObserve, footnotesAttrs) {
    var attr, attrs, selectorAndMethod, viewType, _i, _len, _results, _results1;
    selectorAndMethod = {
      selector: selector,
      method: 'append'
    };
    if (footnotesAttrs) {
      _results = [];
      for (viewType in footnotesAttrs) {
        attrs = footnotesAttrs[viewType];
        _results.push((function() {
          var _i, _len, _results1;
          _results1 = [];
          for (_i = 0, _len = attrs.length; _i < _len; _i++) {
            attr = attrs[_i];
            if (viewType === this.options.viewTypeName || viewType === 'common') {
              if (attrFootnotesBindings[attr] == null) {
                attrFootnotesBindings[attr] = [];
              }
              _results1.push(attrFootnotesBindings[attr].push(selectorAndMethod));
            } else {
              _results1.push(void 0);
            }
          }
          return _results1;
        }).call(this));
      }
      return _results;
    } else if (attrsObserve) {
      _results1 = [];
      for (_i = 0, _len = attrsObserve.length; _i < _len; _i++) {
        attr = attrsObserve[_i];
        if (attrFootnotesBindings[attr] == null) {
          attrFootnotesBindings[attr] = [];
        }
        _results1.push(attrFootnotesBindings[attr].push(selectorAndMethod));
      }
      return _results1;
    } else {
      if (attrFootnotesBindings[cellName] == null) {
        attrFootnotesBindings[cellName] = [];
      }
      return attrFootnotesBindings[cellName].push(selectorAndMethod);
    }
  };

  OutcomeView.prototype.footnotesForAttributes = function(selectContent) {
    var attrFootnotesBindings, cellName, cellOptions, otherFormatter, selector, _i, _len, _ref;
    if (selectContent == null) {
      selectContent = true;
    }
    attrFootnotesBindings = {};
    for (cellName in outcomeCellTypes) {
      cellOptions = outcomeCellTypes[cellName];
      selector = selectContent ? this._getCellContentSelector(cellName, cellOptions) : this._getCellSelector(cellName, cellOptions);
      this._pushAttrBindings(attrFootnotesBindings, cellName, selector, cellOptions.formatterAttrsObserve, cellOptions.footnotesAttrs);
      if (cellOptions.otherFormatters) {
        _ref = cellOptions.otherFormatters;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          otherFormatter = _ref[_i];
          selector = selectContent ? this._getOtherFormatterContentSelector(cellName, cellOptions, otherFormatter) : this._getOtherFormatterInnerCellSelector(cellName, cellOptions, otherFormatter);
          this._pushAttrBindings(attrFootnotesBindings, cellName, selector, otherFormatter.formatterAttrsObserve, otherFormatter.footnotesAttrs);
        }
      }
    }
    return attrFootnotesBindings;
  };

  OutcomeView.prototype.footnotesBindings = function() {
    return _(this.footnotesForAttributes(true)).extend({
      importance: [
        {
          selector: '[data-property="importance"] > .cell-content',
          method: 'after'
        }
      ]
    });
  };

  OutcomeView.prototype.highlightFootnoteBingings = function() {
    return _(this.footnotesForAttributes(false)).extend({
      importance: [
        {
          selector: '[data-property="importance"]'
        }
      ]
    });
  };

  OutcomeView.prototype.initialize = function() {
    this.template = this.options.template;
    OutcomeView.__super__.initialize.apply(this, arguments);
    this.questionModel = this.options.questionModel;
    this.delegate('click', 'td', this.editCell);
    this.delegate('click', 'td button.footnote-required', this.editCellOrFootnotes);
    this.delegate('contextmenu', 'td', this.showCellContextMenu);
    this.modelBind((Outcome.QUALITY_MODIFIERS.map(function(prop) {
      return "change:" + prop;
    })).join(' '), this.forceQualityFootnotes);
    this.modelBind('change:effectMeasure', (function(_this) {
      return function() {
        var _ref;
        return (_ref = _this.parentView) != null ? _ref.renderOutcomeRowsForItem(_this.model) : void 0;
      };
    })(this));
    this.questionModel.on('change:$tableMode', this._toggleTableModeSpecificCell);
    mediator.subscribe('changedManualQualitySettings', this.manualQualityChanged);
    this.editViewTemplateData = {};
    this.enable(AdaptiveLayout);
    this.enable(WysiwygFootnotes);
    this.enable(ActiveFootnoteHighlight);
    return this.enable(ExternalChangeHighlight);
  };

  OutcomeView.prototype.calculatedControlOnly = function() {
    var controls;
    controls = _(this.model.attributes).pick('calculatedControl', 'lowControl', 'moderateControl', 'highControl');
    return controls.calculatedControl && !controls.lowControl && !controls.moderateControl && !controls.highControl && this.model.get('type') === 'dich';
  };

  OutcomeView.prototype.getTemplateData = function() {
    var data, _ref;
    data = OutcomeView.__super__.getTemplateData.apply(this, arguments);
    data.viewTypeName = this.options.viewTypeName;
    data.calculatedControlOnly = this.calculatedControlOnly();
    data.$tableMode = this.questionModel.get('$tableMode');
    data.manualQualityInput = Boolean((_ref = mediator.project) != null ? _ref.get('manualQuality') : void 0);
    return data;
  };

  OutcomeView.prototype.editCell = function(e, activeCell) {
    if (!this.parentView.currentOutcomeEditView) {
      e.stopPropagation();
      return this.openCellEditor(e, activeCell);
    }
  };

  OutcomeView.prototype.getEditViewTemplateByTypeName = function(typeName) {
    var dashedProperty;
    if (typeName === 'sofV3OutcomeLabel' || typeName === 'sofV4OutcomeLabel') {
      typeName = 'sofOutcomeLabel';
    }
    dashedProperty = utils.toUnderscore(typeName);
    return require("views/templates/outcome_edit/" + dashedProperty);
  };

  OutcomeView.prototype.getOutcomeCellEditType = function(cellName) {
    return outcomeCellTypes[cellName].editType;
  };

  OutcomeView.prototype.openCellEditor = function(e, activeCell) {
    var editParams, editView, outcomeCellEditType, target, template, _ref, _ref1;
    if (this.parentView.currentEditView && !this.parentView.currentEditView.disposed) {
      if ((_ref = this.parentView.currentEditView) != null) {
        _ref.conditionalAccept();
      }
    }
    target = activeCell || $(e.target).closest('td');
    this.property = target.data().property;
    if (!this.property) {
      return;
    }
    if (target.hasClass('disabled-cell') || target.hasClass('not-editable-cell')) {
      return;
    }
    this.propertyType = target.data().propertyType;
    if (this.propertyType && this.model.get('type') !== this.propertyType) {
      return;
    }
    template = this.getEditViewTemplateByTypeName(this.property);
    outcomeCellEditType = this.getOutcomeCellEditType(this.property);
    editParams = {
      type: outcomeCellEditType,
      property: this.property,
      viewTypeName: this.options.viewTypeName,
      questionModel: this.questionModel,
      additionalTemplateData: _.extend({}, this.editViewTemplateData, {
        sof3Table: this.property === 'sofV3OutcomeLabel',
        manualQualityInput: Boolean((_ref1 = mediator.project) != null ? _ref1.get('manualQuality') : void 0),
        acpServer: mediator.services.switches.isServerSwitchOn('acpFeatures')
      })
    };
    editView = new OutcomeCellEditView(editParams);
    return editView.show(this, "[data-property=" + this.property + "]", template, this.model).then((function(_this) {
      return function() {
        return _this.publishEvent('cellEditorOpened', editView, target);
      };
    })(this));
  };

  OutcomeView.prototype.editClosed = function(accepted) {
    var _ref, _ref1;
    if (accepted == null) {
      accepted = false;
    }
    if (this.renderItemViewsAfterNonStickedPropertyEdit) {
      if (_ref = this.property, __indexOf.call(_(this.bindings).chain().values().pluck('property').value(), _ref) < 0) {
        if ((_ref1 = this.parentView) != null) {
          _ref1.renderItemViews(this.model);
        }
      }
    }
    if (!this.parentView) {
      return;
    }
    this.removeWarningHighlightForCell(this.parentView.activeCell.el);
    this.parentView.trigger('!fixLayout');
    this.publishEvent('activatedProperty', this.property, this.parentView.activeCell.el, this.questionModel, this.model);
    this.parentView.currentEditView = null;
    if (accepted) {
      return this.parentView.setNextActiveCell();
    }
  };

  OutcomeView.prototype.showCellFootnotesView = function(property, options) {
    var cell, _ref;
    if (options == null) {
      options = {};
    }
    this.property = property != null ? property : this.parentView.activeCell.el.data()['property'];
    cell = (_ref = options.cell) != null ? _ref : this.$("[data-property=" + this.property + "]");
    _.defer((function(_this) {
      return function() {
        return _this.parentView.showCellFootnotesAndReferencesView.call(_this.parentView, _this.model, cell, _this.property, options);
      };
    })(this));
    return false;
  };

  OutcomeView.prototype.editCellOrFootnotes = function(e) {
    var cellProperty, cellTarget, outcomeCellEditType;
    cellTarget = $(e.target).closest('td');
    cellProperty = cellTarget.data().property;
    outcomeCellEditType = this.getOutcomeCellEditType(cellProperty);
    if (outcomeCellEditType === EditView.TYPES.COMPOUND) {
      return this.editCell(e);
    }
    return this.editFootnotes(e);
  };

  OutcomeView.prototype.editFootnotes = function(e) {
    e.preventDefault();
    e.stopPropagation();
    this.property = $(e.target).attr("data-property");
    if (!this.property) {
      return;
    }
    this.showCellFootnotesView(this.property);
    return false;
  };

  OutcomeView.prototype.showCellContextMenu = function(e) {
    mediator.setFocus((function(_this) {
      return function() {
        var $cell, cellContextMenu, evtCoord, property;
        $cell = $(e.target).closest('td');
        _this.parentView.setActiveCell($cell);
        property = $cell.data('property');
        cellContextMenu = new ContextMenuView({
          footnotesDisabled: !CellFootnotesAndReferencesView.prototype.footnotesEnabled('outcome', property),
          questionModel: _this.questionModel,
          footnotesBindingModel: _this.model,
          property: property,
          propertyView: mediator.heirView,
          cell: $cell,
          abortHandler: function() {
            return mediator.setFocus();
          }
        });
        _this.subview('cellContextMenu', cellContextMenu);
        evtCoord = utils.getEventCoordinates(e);
        evtCoord.top += 5;
        evtCoord.left += 5;
        return cellContextMenu.show(evtCoord);
      };
    })(this));
    return false;
  };

  OutcomeView.prototype._toggleTableModeSpecificCell = function() {
    if (this.questionModel.get('$tableMode') === 'narrative') {
      this.$('[data-table-mode-visible="regular"]').hide();
      return this.$('[data-table-mode-visible="narrative"]').show();
    } else {
      this.$('[data-table-mode-visible="regular"]').show();
      return this.$('[data-table-mode-visible="narrative"]').hide();
    }
  };

  OutcomeView.prototype.render = function() {
    OutcomeView.__super__.render.apply(this, arguments);
    return this;
  };

  OutcomeView.prototype.afterRender = function() {
    OutcomeView.__super__.afterRender.apply(this, arguments);
    this.checkForDataProperties();
    this.stickit();
    this.stickFootnotes(this.questionModel);
    this.stickHighlightFootnotes();
    this.highlightCellsWithWarnings();
    return this._toggleTableModeSpecificCell();
  };

  OutcomeView.prototype.checkForDataProperties = function() {
    var cellsProperties;
    cellsProperties = [];
    return _(this.$('td')).each((function(_this) {
      return function(item) {
        var $item, property;
        $item = $(item);
        property = $item.data().property;
        if (!property) {
          throw new Error("No data-property attribute in cell with class '" + ($item.attr('class')) + "' (cid = " + _this.cid + ")");
        } else {
          if (_(cellsProperties).contains(property)) {
            throw new Error("Duplicated data-property in cell with class '" + ($item.attr('class')) + "', (data-property = " + property + " cid = " + _this.cid + ")");
          }
        }
        return cellsProperties.push(property);
      };
    })(this));
  };

  OutcomeView.prototype.forceQualityFootnotes = function(model, changed, options) {
    var changedAttr, _ref;
    if (this.disposed) {
      return;
    }
    if (this.model.changedAttributes() && mediator.user.get('forceFootnotes') && !options.external) {
      if (((_ref = this.parentView.currentEditView) != null ? _ref.editType : void 0) === OutcomeCellEditView.TYPES.SELECTION) {
        changedAttr = _(this.model.changedAttributes()).keys()[0];
        if (__indexOf.call(_(this.$el.find('td')).chain().map(function(cell) {
          return $(cell).data();
        }).pluck('property').compact().value(), changedAttr) < 0) {
          return;
        }
        if (this.model.isQualityUpgraded(changedAttr)) {
          return this.model.unbindFootnote(Footnotes.prototype.noExplanationProvided, changedAttr);
        } else if (this.model.isQualityDowngradedWithoutExplanation(changedAttr)) {
          this.model.bindFootnote(Footnotes.prototype.noExplanationProvided, changedAttr);
          return this.forceFootnoteDialog(changedAttr, $.t('es:force_footnotes.certainty_downgrade'));
        }
      }
    }
  };

  OutcomeView.prototype.forceFootnoteDialog = function(changedAttr, message, options) {
    return this.forceDialog = mediator.dialogs.confirm({
      message: message,
      confirmText: $.t('es:force_footnotes.add_now'),
      declineText: $.t('es:force_footnotes.add_later')
    }, (function(_this) {
      return function(confirmed) {
        if (confirmed) {
          return _this.showCellFootnotesView(changedAttr, _.extend({}, options, {
            forceQualityFootnote: true
          }));
        }
      };
    })(this));
  };

  OutcomeView.prototype.highlightCellsWithWarnings = function() {
    var $cell, $cells;
    if (this.qualityModifiersSet() && !this.model.get('designStudies')) {
      $cells = this.$('.no-of-participants, .participants, .design-studies');
      $cells.addClass('warning').data('warning', 'empty_study_design');
    }
    if (this.options.previousViewTypeName === 'SOF' && this.options.viewTypeName === 'OneRow') {
      $cell = this.$('td.intervention');
      if (this.model.get('type') === 'dich' && !_(this.model.get('interventionCount')).isNumber()) {
        return $cell.addClass('warning').data('warning', 'empty_intervention');
      }
    }
  };

  OutcomeView.prototype.removeWarningHighlightForCell = function($cell) {
    if ($cell.hasClass('intervention') && _(this.model.get('interventionCount')).isNumber()) {
      return $cell.removeClass('warning').data('warning', '');
    }
  };

  OutcomeView.prototype.removeStudyDesignWarningHighlightForCell = function() {
    var $cells;
    if (!this.qualityModifiersSet() || this.model.get('designStudies')) {
      $cells = this.$('.no-of-participants, .participants, .design-studies');
      return $cells.removeClass('warning').data('warning', '');
    }
  };

  OutcomeView.prototype.qualityModifiersSet = function() {
    var qm, qms;
    qms = (function() {
      var _i, _len, _ref, _results;
      _ref = Outcome.QUALITY_MODIFIERS;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        qm = _ref[_i];
        _results.push(this.model.get(qm));
      }
      return _results;
    }).call(this);
    return Outcome.areQualityModifiersSet(qms);
  };

  OutcomeView.prototype.manualQualityChanged = function() {
    if (this.options.viewTypeName.toLowerCase().indexOf('sof') >= 0) {
      return;
    }
    if (Boolean(mediator.project.get('manualQuality'))) {
      return this.$('td.quality').removeClass('not-editable-cell');
    } else {
      return this.$('td.quality').addClass('not-editable-cell');
    }
  };

  OutcomeView.prototype.dispose = function() {
    var _ref;
    if (this.disposed) {
      return;
    }
    mediator.unsubscribe('changedManualQualitySettings', this.manualQualityChanged);
    if ((_ref = this.forceDialog) != null) {
      _ref.destroy();
    }
    this.unstickit();
    this.unstickFootnotes();
    this.unstickHighlightFootnotes();
    this.questionModel.off('change:$tableMode', this._toggleTableModeSpecificCell);
    return OutcomeView.__super__.dispose.apply(this, arguments);
  };

  return OutcomeView;

})(View);
