mediator = require 'mediator'
Tooltip = require 'components/common/tooltip'
useTranslation = require 'components/hooks/use_translation'

EditingByInfo = ({ data }) ->
  i18n = useTranslation('')
  userId = data.getIn(['user', 'id'])
  return null if userId is mediator.user.id

  name = data.getIn(['user', 'name'])
  timestamp = data.get('timestamp');

  <Tooltip>
    <span
      className="editing-by-info bold inline-flex flex-row items-center"
      title={moment(timestamp).fromNow()}
    >
      {name} {i18n 'docsec:is_editing'}
    </span>
  </Tooltip>

module.exports = EditingByInfo
