var CoiToolbarView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/team/coi_toolbar');

module.exports = CoiToolbarView = (function(_super) {
  __extends(CoiToolbarView, _super);

  function CoiToolbarView() {
    return CoiToolbarView.__super__.constructor.apply(this, arguments);
  }

  CoiToolbarView.prototype.container = '#toolbar-container';

  CoiToolbarView.prototype.template = template;

  CoiToolbarView.prototype.autoRender = true;

  return CoiToolbarView;

})(View);
