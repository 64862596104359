var NMAList, NMAListActions, NMAListToolbar, NMAListView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

NMAListToolbar = require('components/nma/nma_questions_list_toolbar');

NMAListActions = require('actions/nma_list_actions');

NMAList = require('components/nma/nma_questions_list');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = NMAListView = (function(_super) {
  __extends(NMAListView, _super);

  function NMAListView() {
    return NMAListView.__super__.constructor.apply(this, arguments);
  }

  NMAListView.prototype.container = '#page-container';

  NMAListView.prototype.id = 'nma-list';

  NMAListView.prototype.autoRender = true;

  NMAListView.prototype._stopInputsPropagation = false;

  NMAListView.prototype.initialize = function() {
    NMAListView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: NMAListToolbar
    }));
    return this.enable(ReactComponent);
  };

  NMAListView.prototype.afterRender = function() {
    NMAListView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(NMAList, NMAListActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  NMAListView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('NMAListStore');
    return NMAListView.__super__.dispose.apply(this, arguments);
  };

  return NMAListView;

})(View);
