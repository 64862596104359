ConnectStore = require 'components/enhancers/connect_store'
EllipsizedText = require 'components/common/ellipsized_text'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
PublicationPlatformActions = require 'actions/publication_platform_actions'
PublicationPlatformStore = require 'stores/publication_platform_store'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

DATE_FORMAT = 'MM/DD/YYYY'
DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss'

storeConnector =
  PublicationPlatformStore: (Store) ->
    questionStatuses: Store.getQuestionStatuses()

PublicationPlatformQuestion = ({ data, questionStatuses }) ->
  i18n = useI18n 'dbep:questions_list_screen'
  questionId = data.get '_id'
  [status, publicationDate] = useCoffeeMemo [questionStatuses], ->
    publicationDate = questionStatuses.get questionId
    if publicationDate?
      ['published', moment publicationDate]
    else
      ['unpublished', publicationDate]
  actionI18nKey = if status is 'published' then 'republish' else 'publish'
  publishQuestion = useCoffeeCallback [questionId, status], (preview) -> ->
    PublicationPlatformActions.openModal {
      mode: 'question'
      preview
      questionId
      republish: status is 'published'
    }

  <div className="publication-platform-questions-table__row">
    <div className="publication-platform-questions-table__cell question">
      <div className="cell-content">
        <EllipsizedText text={data.get 'question'} height={50} />
      </div>
    </div>
    <div className="publication-platform-questions-table__cell status">
      <div className={"status__square #{status}"} />
      <div>{i18n "statuses.#{status}"}</div>
      {publicationDate and <Tooltip>
        <div className="ml-5" title={publicationDate.format DATE_TIME_FORMAT}>
          ({publicationDate.format DATE_FORMAT})
        </div>
      </Tooltip>}
    </div>
    <div className="publication-platform-questions-table__cell actions">
      <div className="cell-content">
        <IconButton
          className="mr-10"
          iconName="eye"
          label={i18n '/actions.preview'}
          onClick={publishQuestion(true)}
        />
        <IconButton
          iconName="bookmark"
          label={i18n "/actions.#{actionI18nKey}"}
          onClick={publishQuestion(false)}
        />
      </div>
    </div>
  </div>

PublicationPlatformQuestion.propTypes =
  data: PropTypes.instanceOf(Immutable.Map).isRequired
  questionStatuses: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore PublicationPlatformQuestion, PublicationPlatformStore, storeConnector
