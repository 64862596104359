{ number, instanceOf } = PropTypes
PanelVoiceStore = require 'stores/panel_voice_store'
PanelVoiceActions = require 'actions/panel_voice_actions'
ConnectStore = require 'components/enhancers/connect_store'
Translation = require 'components/mixins/translation'
Modal = require 'components/common/modal'
Tooltip = require 'components/common/tooltip'
{ panelVoiceMailAtachmentsLimit, forbiddenFileExtensions } = require 'lib/constants'
{ getFileExtension, formatFileSize } = require 'base/lib/utils'

storeConnectors =
  PanelVoiceStore: (Store) ->
    votingMessageAttachments: Store.getVotingMessageAttachments()
    votingMessageAttachmentsSize: Store.getVotingMessageAttachmentsSize()

MailAttachmentsForm = createReactClass
  displayName: "MailAttachmentsForm"

  mixins: [Translation('voting:voting')]

  propTypes:
    votingMessageAttachments: instanceOf(Immutable.Map).isRequired
    votingMessageAttachmentsSize: number

  getInitialState: ->
    forbiddenFileExtension: null

  addAttachment: ->
    @inputFileEl.click()

  removeAttachment: (name) ->
    PanelVoiceActions.removeVotingMessageAttachment name
    @resetFileInput()

  resetFileInput: ->
    @inputFileEl?.value = ''

  fileChanged: (e) ->
    file = e.target.files[0]
    return unless file?
    fileName = file.name
    # verify the file extension
    extension = getFileExtension fileName
    if extension in forbiddenFileExtensions
      # reset the file selection
      @resetFileInput()
      return @setState forbiddenFileExtension: extension
    reader = new FileReader()
    reader.onload = () =>
      PanelVoiceActions.addVotingMessageAttachment
        name: fileName
        size: file.size
        # remove 'data:...;base64' from the begging
        fileData: reader.result.split(',')[1]

    reader.readAsDataURL file

  _inputFileRef: (inputEl) ->
    @inputFileEl = inputEl

  isAttachmentsSizeLimitExceeded: ->
    @props.votingMessageAttachmentsSize > panelVoiceMailAtachmentsLimit

  closeFileExtensionWarningModal: ->
    @setState forbiddenFileExtension: null

  render: ->
    attachmentsSize = @props.votingMessageAttachmentsSize
    attachmentsLimitExceeded = @isAttachmentsSizeLimitExceeded()
    extensions = _.map(forbiddenFileExtensions, (ext) -> ".#{ext.toUpperCase()}").join(', ')

    sizeDisplayClasses = classNames 'attachments-bottom-panel__attachemts-size',
      'attachments-bottom-panel__attachemts-size--error': attachmentsLimitExceeded

    <div className="mail-attachments-form">
      <ul className="attachments-list">
        {@props.votingMessageAttachments.map (file) =>
          fileName = file.get('name')
          <li key={fileName} className="attachments-list__item">
            <span className="attachments-list__item-name">{fileName}</span>
            <span
              className="attachments-list__item-remove"
              onClick={@removeAttachment.bind(this, fileName)}
            />
          </li>
        .toList() }
      </ul>

      <input
        ref={@_inputFileRef}
        style={{ display: 'none' }}
        type="file"
        onChange={@fileChanged}
      />

      <div className="attachments-bottom-panel">
        <span className={sizeDisplayClasses}>
          {@i18n 'modals.attachments_size'}: {formatFileSize attachmentsSize}
          <Tooltip>
            <span
              className="info-icon"
              title={@i18n 'modals.attachment_limitation_tooltip', forbiddenExtensions: extensions}
            />
          </Tooltip>
        </span>
        <button
          className="btn btn-opaque attachment attachments-bottom-panel__add-attachment-btn"
          onClick={@addAttachment}
        >
          {@i18n 'modals.attach_files'}
        </button>
      </div>
      {if @state.forbiddenFileExtension
        <Modal className='wrong-file-extension-modal' isOpen>
          <div>
            <h4>{@i18n 'modals.cannot_attach_file'}</h4>
            <div>
              <span>{@i18n 'modals.filetypes_you_cannot_include'}:</span>
              <div>
                {_.map forbiddenFileExtensions, (ext, idx) =>
                  <span className={'active' if ext is @state.forbiddenFileExtension} key={idx}>
                    {".#{ext}"}
                    {unless idx is forbiddenFileExtensions.length - 1
                      ', '
                    }
                  </span>
                }
              </div>
            </div>
            <button className='btn btn-block' onClick={@closeFileExtensionWarningModal}>
              {@i18n '/translation:actions.ok'}
            </button>
          </div>
        </Modal>
      }
    </div>

module.exports = ConnectStore(
  MailAttachmentsForm
  [ PanelVoiceStore ]
  storeConnectors
)
