{ useI18n, useCoffeeMemo } = require 'lib/react_utils'

ProjectStartCounter = ({ startDate }) ->

  i18n = useI18n('')
  return null unless startDate

  [diff, label] = useCoffeeMemo [startDate], ->
    diff = moment().diff(moment(startDate), 'days')
    label = if Math.abs(diff) is 1 
      i18n 'dates.relative.one_day'
    else
      i18n 'dates.relative.days'
    if diff < 0
      label += " #{i18n 'dates.relative.to_start'}"
  
    [diff, label]

  <div className="flex flex-row items-center">
    <div className="project-start-counter">{Math.abs(diff)}</div>
    <div className="project-start-label">{label}</div>
  </div>

module.exports = ProjectStartCounter
