var ModelTrait, PouchMemento,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModelTrait = require('base/lib/traits/model_trait');

module.exports = PouchMemento = (function(_super) {
  __extends(PouchMemento, _super);

  function PouchMemento() {
    return PouchMemento.__super__.constructor.apply(this, arguments);
  }

  PouchMemento.prototype.apply = function(model) {
    PouchMemento.__super__.apply.apply(this, arguments);
    return _.extend(model, new Backbone.Memento(model, {
      ignore: ['_rev']
    }));
  };

  return PouchMemento;

})(ModelTrait);
