var ProjectsHelper, Router, W, checkIfCurrentProjectIsCopy, checkQuestionModules, getLinkTitle, getModuleProps, getModules, getSubmoduleProps, isModuleEnabledInCopy, markSelectedModule, mediator, _getModules;

mediator = require('mediator');

Router = require('router');

W = require('when');

ProjectsHelper = require('lib/projects_helper');

isModuleEnabledInCopy = require('lib/modules_helper').isModuleEnabledInCopy;

checkIfCurrentProjectIsCopy = function() {
  if (!mediator.project) {
    return false;
  }
  return ProjectsHelper.isProjectACopy(mediator.project.id);
};

getLinkTitle = function(moduleId, submoduleId, missing, disabled, disabledDueToCopy) {
  var key;
  if (disabledDueToCopy == null) {
    disabledDueToCopy = false;
  }
  key = missing ? 'nav_tooltips.missing' : disabledDueToCopy ? 'nav_tooltips.disabled_in_project_copies' : disabled ? 'nav_tooltips.disabled' : submoduleId ? "nav_tooltips." + moduleId + "_submodules." + submoduleId : "nav_tooltips." + moduleId;
  return $.t(key);
};

checkQuestionModules = function(modules, currentModule, currentQuestionId, currentSubmodule) {
  if (currentModule === 'evidence_syntheses') {
    return mediator.services.overReviewService.isModuleEnabled(currentQuestionId).then(function(isEnabled) {
      if (isEnabled) {
        return modules.map(function(module) {
          if (module.id !== 'evidence_syntheses') {
            return module;
          }
          module.submodules = [
            {
              id: 'over_review'
            }
          ].concat(module.submodules);
          return module;
        });
      } else {
        return modules;
      }
    });
  } else {
    return W.resolve(modules);
  }
};

markSelectedModule = function(currentModule, currentQuestionId, currentSubmodule) {
  return function(modules) {
    var module, _ref;
    module = _(modules).findWhere({
      id: currentModule
    });
    if (!module) {
      return modules;
    }
    if (currentModule === 'evidence_syntheses' && (currentQuestionId == null)) {
      delete module.submodules;
    }
    module.selected = true;
    if (module.submodules) {
      if ((_ref = _(module.submodules).find(function(v) {
        return Router.prototype.urlizeModuleName(v.id) === currentSubmodule;
      })) != null) {
        _ref.selected = true;
      }
    }
    return modules;
  };
};

_getModules = function(currentModule, currentQuestionId, currentSubmodule) {
  var modules;
  modules = mediator.services.modules.getVisibleProjectModules(mediator.project);
  if (currentQuestionId) {
    return checkQuestionModules(modules, currentModule, currentQuestionId, currentSubmodule);
  } else {
    return W.resolve(modules);
  }
};

getModules = function(currentModule, currentQuestionId, currentSubmodule) {
  var markSelected;
  if (!mediator.project) {
    return W.resolve(null);
  }
  markSelected = markSelectedModule(currentModule, currentQuestionId, currentSubmodule);
  return _getModules(currentModule, currentQuestionId, currentSubmodule).then(markSelected);
};

getModuleProps = function(_arg) {
  var classes, disabled, disabledDueToCopy, href, isCopy, label, missing, moduleId, selected, title, urlized;
  moduleId = _arg.id, selected = _arg.selected, disabled = _arg.disabled, missing = _arg.missing;
  urlized = Router.prototype.urlizeModuleName(moduleId);
  isCopy = checkIfCurrentProjectIsCopy();
  disabledDueToCopy = isCopy && isModuleEnabledInCopy(moduleId);
  disabled = disabledDueToCopy || disabled;
  if (!disabled) {
    href = Router.prototype.getProjectRelativeUrl("/" + urlized);
  }
  classes = classNames(urlized, {
    'selected': selected,
    'disabled': disabled
  });
  title = getLinkTitle(moduleId, null, missing, disabled, disabledDueToCopy);
  label = $.t("nav." + moduleId);
  return {
    href: href,
    classes: classes,
    title: title,
    label: label
  };
};

getSubmoduleProps = function(moduleId, currentQuestionId, submodule) {
  var classes, disabled, href, label, missing, moduleUrlized, nonEmptyParts, selected, submoduleId, submoduleUrlized, title;
  submoduleId = submodule.id, selected = submodule.selected, disabled = submodule.disabled, missing = submodule.missing;
  moduleUrlized = Router.prototype.urlizeModuleName(moduleId);
  submoduleUrlized = Router.prototype.urlizeModuleName(submoduleId);
  nonEmptyParts = _([moduleUrlized, currentQuestionId, submoduleUrlized]).compact();
  if (!disabled) {
    href = Router.prototype.getProjectRelativeUrl('/' + nonEmptyParts.join('/'));
  }
  classes = classNames(submoduleUrlized, {
    'disabled': disabled,
    'current': selected
  });
  title = getLinkTitle(moduleId, submoduleId, missing, disabled);
  label = $.t("nav." + moduleId + "_submodules." + submoduleId);
  return {
    href: href,
    classes: classes,
    title: title,
    label: label
  };
};

module.exports = {
  getLinkTitle: getLinkTitle,
  getModules: getModules,
  getModuleProps: getModuleProps,
  getSubmoduleProps: getSubmoduleProps
};
