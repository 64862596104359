Popover = require 'components/common/popover'
PopoverContent = require 'components/common/popover_content'
Translation = require 'components/mixins/translation'

RobLevelIndicator = createReactClass
  displayName: 'RobLevelIndicator'

  mixins: [Translation('rob:annotations')]

  getInitialState: ->
    isSelectBoxVisible: false
    showAnnotationFor: null

  toggleSelectBox: ->
    @setState isSelectBoxVisible: not @state.isSelectBoxVisible

  commonAnnotation: (domain) ->
    domainUnderscored = _.str.underscored domain

    <div>
      <h2>{@i18n "common.#{domainUnderscored}.title"}</h2>
      <span>{@i18n "common.#{domainUnderscored}.text"}</span>
    </div>

  getOptions: (domain) ->
    domainUnderscored = _.str.underscored domain

    ['low', 'unclear', 'high'].map (riskValue) =>
      value: riskValue
      optionText: @i18n "../risk_values.#{riskValue}"
      label: @i18n "../risk_labels.#{riskValue}"
      annotation: @i18n "#{_.str.underscored domain}.#{riskValue}"

  _getChoiceDataKey: ->
    "rob-#{@props.outcomeId}-#{@props.domain}"

  onChange: (evt) ->
    value = evt.target.value
    dataKey = @_getChoiceDataKey()
    currentChoice = @props.reference.get dataKey
    value = null if currentChoice is value

    @props.onChange(dataKey, value, @props.reference)
    @toggleSelectBox()

  hideAnnotation: ->
    @setState showAnnotationFor: null

  showAnnotation: (optionValue) -> =>
    @setState showAnnotationFor: optionValue

  render: ->
    choice = @props.reference.get @_getChoiceDataKey()
    choiceClass = classNames 'choice',
      active: @state.isSelectBoxVisible
      selected: choice?

    <Popover
      visible={@state.isSelectBoxVisible}
      onRequestClose={@toggleSelectBox}
      positionParams={position: 'right', alignment: 'start'}
    >
      <div className={choiceClass} onClick={@toggleSelectBox}>
        {if choice?
          <div className="level #{choice}" />
        }
      </div>
      <div className='risk-values-list'>
        {@getOptions(@props.domain).map (option) =>
          { value, optionText, annotation, label} = option

          <Popover
            key={value}
            visible={@state.showAnnotationFor is value}
            onRequestClose={@hideAnnotation}
            positionParams={position: 'right', alignment: 'start'}
          >
            <div
              className='risk-value-container'
              onMouseOver={@showAnnotation value}
              onMouseLeave={@hideAnnotation}
            >
              <input
                type='radio'
                name='risk-value'
                value={value}
                checked={value is choice}
                id={value}
                onChange={@onChange}
              />
              <label htmlFor={value}>
                {optionText}
              </label>
            </div>
            <PopoverContent>
              <div className='risk-value-annotation'>
                {@commonAnnotation @props.domain}
                {if label?
                  <div className='annotation-label'>
                    {label}
                  </div>
                }
                <div className='annotation-text'>
                  {annotation}
                </div>
              </div>
            </PopoverContent>
          </Popover>
        }
      </div>
    </Popover>

module.exports = RobLevelIndicator
