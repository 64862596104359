Modal = require 'components/common/modal'
ExportDialog = require 'components/common/export_dialog'
{ useI18n } = require 'lib/react_utils'

outputFormats = ['pdf', 'external_link', 'external_embeddable_code']

ExportModal = ({ onClose, onExport, isOpen }) ->

  i18n = useI18n('vi:export')

  <Modal
    isOpen={isOpen}
    className="vi-guidelines-export-modal"
    closeButton={true}
    title={i18n 'title'}
  >
    <ExportDialog
      outputFormats={outputFormats}
      onClose={onClose}
      onDownload={onExport}
    />
  </Modal>
module.exports = ExportModal
