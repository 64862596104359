Popover =  require 'components/common/popover'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'


optionType = PropTypes.shape
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  text: PropTypes.string
  disabled: PropTypes.bool
  title: PropTypes.string

OptionLabel = ({ text }) ->
  <span>{text}</span>

SelectValue = ({ text }) ->
  <div dangerouslySetInnerHTML={{__html: text}} />

AnnotatedOption = createReactClass
  displayName: 'AnnotatedOption'

  propTypes:
    annotationPosition: PropTypes.object

  getDefaultProps: ->
    annotationPosition: { positionOffset: 0, position: 'right' }

  getInitialState: ->
    annotationVisible: false

  annotationRef: (el) ->
    @annotation = el

  showAnnotation: ->
    @setState annotationVisible: true

  hideAnnotation: ->
    @setState annotationVisible: false

  handleOptionLeave: (evt) ->
    # do not hide annotation if cursor is above it
    return if evt.relatedTarget instanceof Node and @annotation?.contains evt.relatedTarget
    @hideAnnotation()

  render: ->
    optionProps = R.omit [
      'annotation',
      'annotationLabel',
      'commonAnnotation',
      'annotationClassName',
      'annotationPosition',
      'optionLabelComponent'
      'text'
    ], @props
    annotationClass = classNames 'select-option-annotation', @props.annotationClassName
    OptionLabel = @props.optionLabelComponent

    <Popover
      visible={@state.annotationVisible}
      onRequestClose={@hideAnnotation}
      positionParams={@props.annotationPosition}
    >
      <div onMouseOver={@showAnnotation} onMouseLeave={@handleOptionLeave}>
        <li {...optionProps}>
          <OptionLabel text={@props.text} value={@props['data-value']}/>
        </li>
      </div>
      <div className={annotationClass} ref={@annotationRef} onMouseLeave={@hideAnnotation}>
        {if @props.commonAnnotation?
          <div style={marginBottom: '10px'}>{@props.commonAnnotation}</div>
        }
        {if @props.annotationLabel?
          <div style={fontWeight: 'bold', marginBottom: '10px'}>{@props.annotationLabel}</div>
        }
        <div>{@props.annotation}</div>
      </div>
    </Popover>

SelectCustom = createReactClass
  displayName: 'SelectCustom'

  propTypes:
    className: PropTypes.string
    controlled: PropTypes.bool
    onChange: PropTypes.func
    onOptionHover: PropTypes.func
    onOptionLeave: PropTypes.func
    icon: PropTypes.string
    signPosition: PropTypes.oneOf ['left', 'right']
    signClass: PropTypes.string
    listClassName: PropTypes.string
    options: PropTypes.arrayOf optionType
    groupedOptions: PropTypes.arrayOf PropTypes.shape {
      caption: PropTypes.string
      options: PropTypes.arrayOf optionType
    }
    popoverClassName: PropTypes.string
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    disabled: PropTypes.bool
    orderedList: PropTypes.bool
    withNumberOptions: PropTypes.bool
    withClearOption: PropTypes.bool
    optionLabelComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    selectValueComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    # controls how the options container will change its width depending on the width option in
    # comparison to width of selected value container.
    # `auto` - default behavior, options container is at least of same width as selected value
    #          container. If any option width is greater than that of selected value container,
    #          the options container will get wider to fit in that option.
    # `fixed` - options container will always be of the same width as selected option container
    optionsContainerWidthMode: PropTypes.oneOf(['auto', 'fixed']).isRequired

  mixins: [
    CustomRenderMixin
    Translation()
  ]

  getDefaultProps: ->
    controlled: false
    popoverClassName: ''
    signPosition: 'right'
    icon: null
    orderedList: false
    withNumberOptions: false
    groupedOptions: []
    withClearOption: false
    optionLabelComponent: OptionLabel
    selectValueComponent: SelectValue
    optionsContainerWidthMode: 'auto'

  getInitialState: ->
    selectedValue: @props.selected
    showingOptions: false

  _containerRef: (el) ->
    @container = el

  _storeContainerWidth: ->
    @setState containerWidth: @container.offsetWidth

  getSelectedOptionText: ->
    if @props.selected?
      allOptions = _.flatten @props.options.concat _.pluck @props.groupedOptions, 'options'
      _.findWhere(allOptions, value: @props.selected)?.text or '&zwnj;'
    else
      '&zwnj;'

  toggleOptions: ->
    unless @props.disabled
      @setState showingOptions: not @state.showingOptions

  showOptions: ->
    @setState showingOptions: true

  hideOptions: ->
    @setState showingOptions: false

  onOptionOver: (ev) ->
    return unless @props.onOptionHover
    value = ev.target.getAttribute('data-value')
    @props.onOptionHover value, ev.target

  onOptionOut: (ev) ->
    @props.onOptionLeave? ev

  handleSelect: (option) -> (evt) =>
    evt.stopPropagation()
    evt.preventDefault()
    # ignore self selection and disabled option selection
    return if option.disabled
    return @setState showingOptions: false if option.value is @state.selectedValue
    # update state and hide options
    @setState
      selectedValue: option.value
      showingOptions: false
    # notify consumer
    @props.onChange? option.value

  handleOptionClear: ->
    @hideOptions()
    @props.onChange null

  componentDidMount: ->
    @_storeContainerWidth()
    window.addEventListener 'resize', @_storeContainerWidth

  componentWillUnmount: ->
    window.removeEventListener 'resize', @_storeContainerWidth

  componentDidUpdate: (prevProps) ->
    if prevProps.controlled and @props.controlled and prevProps.selected isnt @props.selected
      @setState selectedValue: @props.selected

  renderSelectValue: ->
    SelectValueComponent = @props.selectValueComponent

    <SelectValueComponent value={@state.selectedValue} text={@getSelectedOptionText()} />

  renderOptionsGroup: (group, idx) ->
    { caption, options } = group
    <li
      className='options-group'
      key={idx}
    >
      <div className='options-group__caption'>{caption}</div>
      <div className='options-group__options'>
        <ul>
          {options.map @renderOption}
        </ul>
      </div>
    </li>

  renderOption: (option, idx) ->
    { value, text, optionText, disabled, title, annotation, label } = option
    isSelected = @state.selectedValue is value
    itemClass = classNames 'option', _.string.dasherize(value), selected: isSelected
    OptionLabel = @props.optionLabelComponent

    text = optionText ? text
    optionProps = {
      className: itemClass
      'data-index': idx
      'data-value': value
      onClick: @handleSelect(option)
      key: idx
      disabled
      title
    }

    if annotation
      <AnnotatedOption {...optionProps}
        annotation={annotation}
        annotationLabel={label}
        annotationClassName={@props.annotationClassName}
        annotationPosition={@props.annotationPosition}
        commonAnnotation={@props.commonAnnotation}
        optionLabelComponent={OptionLabel}
        text={text}
      />
    else
      <li {...optionProps}>
        <OptionLabel value={value} text={text} />
      </li>

  render: ->

    { icon } = @props

    containerClass = classNames 'options-select-container', @props.className,
      disabled: @props.disabled
      'with-icon': icon
      'showing-options': @state.showingOptions
    selectInputClass = classNames 'select-input', "sign-position-#{@props.signPosition}",
      if _.isString(@props.selected) then _.string.dasherize(@props.selected) else ''
    optionsClass = classNames 'options-select-container__options', @props.listClassName,
      'with-numbered-options': @props.withNumberOptions
    signClass = classNames 'select-sign', @props.signClass
    ListTag = if @props.orderedList then 'ol' else 'ul'
    optionsContainerWidth = R.objOf(
      if @props.optionsContainerWidthMode is 'auto' then 'minWidth' else 'width'
      "#{@state.containerWidth}px"
    )

    <Popover
      className={@props.popoverClassName}
      visible={@state.showingOptions}
      onRequestClose={@hideOptions}
      positionParams={positionOffset: 0}
    >
      <div
        className={containerClass}
        onClick={@toggleOptions unless @props.disabled}
        ref={@_containerRef}
      >
        <div className={selectInputClass}>
          {icon && <span className="select-icon icon #{icon}" />}
          <div className='select-value'>{@renderSelectValue()}</div>
          <div className={signClass} />
        </div>
      </div>
      <ListTag
        className={optionsClass}
        onMouseOver={@onOptionOver}
        onMouseLeave={@onOptionOut}
        style={optionsContainerWidth}
      >
        {@props.groupedOptions.map(@renderOptionsGroup)}
        {@props.options.map(@renderOption)}
        {if @props.withClearOption and @props.selected
          <li className='clear-option btn-popup' onClick={@handleOptionClear}>
            {@i18n 'actions.clear' }
          </li>
        }
      </ListTag>
    </Popover>

module.exports = SelectCustom
