var Migration, PrognosticModule, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = PrognosticModule = (function(_super) {
  __extends(PrognosticModule, _super);

  function PrognosticModule() {
    PrognosticModule.__super__.constructor.call(this, false);
  }

  PrognosticModule.prototype.up = function(project, colls) {
    var module;
    PrognosticModule.__super__.up.apply(this, arguments);
    if (_(project.get('modules')).findWhere({
      id: 'administration'
    }) || project.get('modules') === '*') {
      return W.resolve();
    }
    module = {
      id: 'administration',
      submodules: [
        {
          id: 'etd_templates'
        }
      ],
      disabled: true
    };
    project.get('modules').unshift(module);
    return project.save();
  };

  return PrognosticModule;

})(Migration);
