var ChecklistDocShape, StepData, TopicSteps, docShape, mapOf, oneOf, optional, shape, string;

docShape = require('lib/db_docs/field_types/doc_shape');

shape = require('lib/db_docs/field_types/shape');

mapOf = require('lib/db_docs/field_types/map_of');

oneOf = require('lib/db_docs/field_types/one_of');

string = require('lib/db_docs/field_types/built_in_types').string;

optional = shape.typeDecorators.optional;

StepData = shape({
  comment: optional(string),
  status: optional(oneOf(['completed', 'notApplicable']))
});

TopicSteps = mapOf(StepData);

ChecklistDocShape = docShape({
  topics: mapOf(TopicSteps)
});

module.exports = ChecklistDocShape;
