Tooltip = require 'components/common/tooltip'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{
  getSofControl
  getCorrespondingRisk
  getAbsoluteEffect
  getRelativeEffect
} = require 'lib/outcome_fields_generator'

styles =
  table:
    width: '100%'
    tableLayout: 'fixed'
    margin: '0'
    fontSize: '8pt'
    fontFamily: "Verdana, 'Arial Narrow', Arial, sans-serif"
  th:
    height: '75px'
  cell:
    fontSize: '8pt'
    border: '1px solid #bfbfbf'

SofShort = createReactClass
  displayName: 'SofShort'

  propTypes:
    questionData: PropTypes.object.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]

  renderOutcomeFirstRow: (outcome) ->
    { type, name, rowspan, narrativeDesc, headControl } = outcome

    <tr>
      <td style={styles.cell} rowSpan={rowspan}>{name}</td>
      {if type is 'narrative'
        <td style={styles.cell} colSpan='4'>{narrativeDesc}</td>
      else
        [
          <td style={styles.cell} key={1}>
            <div dangerouslySetInnerHTML={__html: getSofControl outcome, headControl} />
          </td>
          <td style={styles.cell} key={2}>
            <div dangerouslySetInnerHTML={__html: getCorrespondingRisk outcome, headControl} />
          </td>
          <td style={styles.cell} key={3}>
            <div dangerouslySetInnerHTML={__html: getAbsoluteEffect outcome, headControl} />
          </td>
          <td style={styles.cell} key={4}>
            <div dangerouslySetInnerHTML={__html: getRelativeEffect outcome} />
          </td>
        ]
      }
    </tr>

  renderOutcomeControlsRows: (outcome) ->
    outcome.tailControls.map (controlName, idx) ->
      <tr key={idx}>
        <td style={styles.cell}>
          <div dangerouslySetInnerHTML={__html: getSofControl outcome, controlName} />
        </td>
        <td style={styles.cell}>
          <div dangerouslySetInnerHTML={__html: getCorrespondingRisk outcome, controlName} />
        </td>
        <td style={styles.cell}>
          <div dangerouslySetInnerHTML={__html: getAbsoluteEffect outcome, controlName} />
        </td>
      </tr>

  renderOutcome: (outcome, idx) ->
    <tbody key={idx}>
      {@renderOutcomeFirstRow outcome }
      {unless _.isEmpty outcome.tailControls
        @renderOutcomeControlsRows outcome
      }
    </tbody>

  render: ->
    { comparison, intervention, $tableMode } = @props.questionData
    withText = @i18n '../recommendations.table.benefits_harms.with'
    confidenceInterval = @i18n '../recommendations.table.benefits_harms.confidence_interval'
    confidenceIntervalTooltip = @i18n '../recommendations.table.tooltips.confidence_interval'
    relativeEffectSofTooltip = @i18n '../recommendations.table.tooltips.relative_effect_sof'
    thStyle = _.extend {}, styles.th, styles.cell

    <table style={styles.table}>
      <thead>
        <tr>
          <th style={thStyle}>
            {@i18n 'outcome'}
          </th>
          {if $tableMode is 'narrative'
            <th style={thStyle} colSpan='4'>
              {@i18n 'impact'}
            </th>
          else
            [
              <th style={thStyle} key={1}>
                {"#{withText} #{comparison}"}
              </th>
              <th style={thStyle} key={2}>
                {"#{withText} #{intervention}"}
              </th>
              <th className='with-info' style={thStyle} key={3}>
                <Tooltip>
                  <span className='info-sign' title={confidenceIntervalTooltip} />
                </Tooltip>
                {"#{@i18n 'difference'} #{confidenceInterval}"}
              </th>
              <th className='with-info' style={thStyle} key={4}>
                <Tooltip>
                  <span className='info-sign' title={relativeEffectSofTooltip} />
                </Tooltip>
                {"#{@i18n 'relative_effect'} #{confidenceInterval}"}
              </th>
            ]
          }
        </tr>
      </thead>
      {@props.questionData.includedOutcomes.map @renderOutcome}
    </table>

module.exports = SofShort
