var arrayOf, documentIdWithAutoGUID, initial, interventionShape, optional, shape, string;

arrayOf = require('lib/db_docs/field_types/array_of');

documentIdWithAutoGUID = require('lib/db_docs/field_types/document_id_with_auto_guid');

initial = require('lib/db_docs/field_types/type_decorators').initial;

shape = require('lib/db_docs/field_types/shape');

string = require('lib/db_docs/field_types/built_in_types').string;

optional = shape.typeDecorators.optional;

interventionShape = shape({
  _id: documentIdWithAutoGUID,
  name: string,
  shortName: optional(string),
  namesFromFiles: initial([])(arrayOf(string))
});

module.exports = interventionShape;
