var AlignmentType, HEALTH_ADVISOR_WORKSPACE_ID, ShadingType, convertInchesToTwip, defaultExport, defaultFont, defaultFontSize, gradeProParagraphStyles, heading1Color, heading2Color, mdgRecommendationParagraphStyle, mdgWordExportStyles, mediator, paragraphStyle, recommendationParagraphStyle, runStyle, titleColor, titleStyle, wordExportStyles;

AlignmentType = docx.AlignmentType, convertInchesToTwip = docx.convertInchesToTwip, ShadingType = docx.ShadingType;

mediator = require('mediator');

HEALTH_ADVISOR_WORKSPACE_ID = require('lib/mda_helper').HEALTH_ADVISOR_WORKSPACE_ID;

defaultFont = 'Calibri';

defaultFontSize = 24;

titleColor = "#0b6dad";

heading1Color = "#0b6dad";

heading2Color = "#0d80cc";

paragraphStyle = {
  font: defaultFont,
  spacing: {
    before: 120,
    after: 120
  },
  alignment: AlignmentType.BOTH
};

runStyle = {
  size: defaultFontSize,
  font: defaultFont
};

titleStyle = {
  size: 56,
  bold: 1,
  font: defaultFont,
  color: titleColor,
  spacing: {
    line: 560,
    before: 320,
    after: 320
  }
};

recommendationParagraphStyle = {
  spacing: {
    before: 0,
    after: 0
  }
};

mdgRecommendationParagraphStyle = {
  spacing: {
    before: 0,
    after: 0
  },
  shading: {
    type: ShadingType.SOLID,
    color: "#f2f2f2"
  }
};

gradeProParagraphStyles = [
  {
    id: "gradepro",
    name: "Normal",
    basedOn: "Normal",
    next: "Normal",
    quickFormat: true,
    run: runStyle,
    paragraph: paragraphStyle
  }, {
    id: 'footer',
    name: 'Footer',
    basedOn: 'Normal',
    next: 'Normal',
    quickFormat: true,
    run: {
      size: 20,
      font: defaultFont
    },
    paragraph: paragraphStyle
  }
];

mdgWordExportStyles = {
  "default": {
    heading1: {
      run: {
        size: 28,
        bold: true,
        font: defaultFont,
        color: heading1Color
      },
      paragraph: {
        spacing: {
          after: 120
        },
        shading: {
          type: ShadingType.SOLID,
          color: "#D9D9D9"
        }
      }
    },
    heading2: {
      run: {
        size: 28,
        bold: true,
        font: defaultFont,
        color: heading2Color,
        allCaps: true
      },
      paragraph: {
        spacing: {
          before: 120,
          after: 120
        },
        shading: {
          type: ShadingType.SOLID,
          color: "#D9D9D9"
        }
      }
    },
    heading3: {
      run: {
        size: 24,
        bold: true,
        font: defaultFont,
        color: heading2Color,
        allCaps: true
      },
      paragraph: {
        spacing: {
          before: 120,
          after: 120
        },
        shading: {
          type: ShadingType.SOLID,
          color: "#FBE4D5"
        }
      }
    },
    paragraph: {
      quickFormat: true,
      run: {
        font: defaultFont
      },
      paragraph: paragraphStyle
    },
    listParagraph: {
      run: runStyle,
      indent: {
        left: convertInchesToTwip(0.1)
      }
    }
  },
  paragraphStyles: gradeProParagraphStyles
};

wordExportStyles = {
  "default": {
    heading1: {
      run: {
        size: 28,
        bold: true,
        font: defaultFont,
        color: heading1Color
      },
      paragraph: {
        spacing: {
          before: 560,
          after: 120
        }
      }
    },
    heading2: {
      run: {
        size: 28,
        bold: true,
        font: defaultFont,
        color: heading2Color
      },
      paragraph: {
        spacing: {
          before: 480,
          after: 240
        }
      }
    },
    heading3: {
      run: {
        size: 24,
        bold: true,
        font: defaultFont,
        color: heading2Color
      },
      paragraph: {
        spacing: {
          before: 360,
          after: 120
        }
      }
    },
    heading4: {
      run: {
        size: 22,
        bold: true,
        font: defaultFont,
        color: heading2Color
      },
      paragraph: {
        spacing: {
          before: 240,
          after: 120
        }
      }
    },
    heading5: {
      run: {
        size: 20,
        bold: true,
        font: defaultFont,
        color: heading2Color
      },
      paragraph: {
        spacing: {
          before: 120,
          after: 120
        }
      }
    },
    paragraph: {
      font: defaultFont,
      quickFormat: true,
      run: {
        font: defaultFont
      },
      paragraph: paragraphStyle
    },
    listParagraph: {
      run: runStyle,
      indent: {
        left: convertInchesToTwip(0.1)
      }
    }
  },
  paragraphStyles: gradeProParagraphStyles
};

defaultExport = {
  paragraphStyle: paragraphStyle,
  runStyle: runStyle,
  titleStyle: titleStyle,
  wordExportStyles: wordExportStyles,
  recommendationParagraphStyle: recommendationParagraphStyle
};

module.exports = function() {
  if (mediator.services.switches.isOn('mdgTables') || mediator.activeWorkspace === HEALTH_ADVISOR_WORKSPACE_ID) {
    return _.extend({}, defaultExport, {
      recommendationParagraphStyle: mdgRecommendationParagraphStyle,
      wordExportStyles: mdgWordExportStyles
    });
  } else {
    return defaultExport;
  }
};
