var DnDActions, DragDropTypes;

DnDActions = require('actions/react_dnd_actions');

DragDropTypes = require('components/scope/outcomes/drag_drop_types');

module.exports = {
  dragSpec: {
    beginDrag: function(props, monitor, component) {
      var gIndex, id, qIndex;
      id = props.id, gIndex = props.gIndex, qIndex = props.qIndex;
      DnDActions.beginDrag({
        gIndex: gIndex,
        qIndex: qIndex,
        type: DragDropTypes.QUESTION
      });
      return {
        gIndex: gIndex,
        qIndex: qIndex,
        id: id
      };
    },
    isDragging: function(props, monitor) {
      return props.id === monitor.getItem().id;
    },
    endDrag: function(props, monitor) {
      if (!monitor.didDrop()) {
        return DnDActions.endDrag();
      }
    },
    canDrag: function(props, monitor) {
      return props.canDrag;
    }
  },
  dropSpec: {
    hover: function(props, monitor, component) {
      var hoverGindex, hoverItemIndex;
      hoverGindex = props.gIndex, hoverItemIndex = props.qIndex;
      if (!props.canDrag) {
        return;
      }
      if (monitor.getItem().id === props.id) {
        return;
      }
      return DnDActions.hover({
        hoverType: DragDropTypes.QUESTION,
        type: monitor.getItemType(),
        hoverGindex: hoverGindex,
        hoverItemIndex: hoverItemIndex
      });
    },
    canDrop: function() {
      return false;
    }
  },
  dragCollect: function(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    };
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget()
    };
  }
};
