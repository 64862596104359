var __slice = [].slice;

module.exports = {
  childrenTypes: function() {
    var validTypes;
    validTypes = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
    return function(props, propName, componentName) {
      var children, hasInvalidTypeChild, validTypeNames;
      children = props[propName];
      hasInvalidTypeChild = children.some(function(_arg) {
        var type;
        type = _arg.type;
        return validTypes.every(function(validType) {
          return type !== validType;
        });
      });
      if (hasInvalidTypeChild) {
        validTypeNames = validTypes.map(function(_arg) {
          var displayName, type;
          displayName = _arg.displayName, type = _arg.type;
          return "`" + (displayName || type) + "`";
        }).join(' or ');
        return new Error("Invalid prop `children` supplied to `" + componentName + "`. Children must be of " + validTypeNames + " type.");
      }
    };
  },
  childrenCount: function(validCount) {
    return function(props, propName, componentName) {
      var children, providedCount;
      children = props[propName];
      providedCount = React.Children.count(children);
      if (providedCount !== validCount) {
        return new Error("Invalid prop `children` supplied to `" + componentName + "`. Received " + providedCount + " " + (validCount > 1 ? 'children' : 'child') + " but exactly " + validCount + " must be provided.");
      }
    };
  }
};
