Translation = require "components/mixins/translation"
{ func, string, instanceOf, bool } = PropTypes

ExternalMember = createReactClass

  displayName: "ExternalMember"

  mixins: [Translation("")]

  propTypes:
    member: instanceOf(Immutable.Map).isRequired
    updateAttribute: func.isRequired
    onRemove: func.isRequired
    memberId: string.isRequired
    checked: bool

  onCheck: ->
    @props.updateAttribute @props.memberId, 'checked', @checked.checked

  onChangeEmail: ->
    @props.updateAttribute @props.memberId, 'email', @email.value

  onRemove: ->
    @props.onRemove @props.memberId

  _refCheckedField: (e) ->
    @checked = e

  _refEmailField: (e) ->
    @email = e

  render: ->
    { member, checked, memberId } = @props
    <div className="member">
      <div className="checkbox">
        <input
          ref={@_refCheckedField}
          type="checkbox"
          onChange={@onCheck}
          checked={checked}
        />
      </div>
      <div className="member-row">
        <input type="text"
          ref={@_refEmailField}
          value={member.get("email")}
          onChange={@onChangeEmail}
          placeholder={@i18n 'placeholders.voting_email'}
        />
        <button className="remove-member" onClick={@onRemove} />
      </div>
      <div className="clearfix" />
    </div>

module.exports = ExternalMember
