{ authorsToString } = require 'lib/references_utils'
IconButton = require 'components/common/icon_button'
ReferencesActions = require 'actions/references_actions'
ReferenceControls = require 'components/references/reference_controls'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
{ bool, string, oneOfType, number, instanceOf } = PropTypes

ReferenceCell = createReactClass
  displayName: 'ReferenceCell'

  mixins: [Translation('references:reference_usage_modal')]

  propTypes:
    cellName: string.isRequired
    cellData: oneOfType([ bool, number, string, instanceOf Immutable.List ]).isRequired
    className: string
    isRowSelected: bool.isRequired
    referenceUsedIn: instanceOf(Immutable.Map)

  getDefaultProps: ->
    referenceUsedIn: Immutable.Map()

  openReferenceUsageModal: ->
    ReferencesActions.openReferenceUsageModal @props.referenceId

  _getContent: ->
    { cellData, cellName, isRowSelected, referenceId, referenceUsedIn } = @props

    switch cellName
      when 'lastUpdated' then moment(cellData).format 'YYYY-MM-DD'
      when 'year' then cellData.match(/\d{4}/)?[0]
      when 'authors' then authorsToString cellData
      when 'tags', 'authorKeywords' then cellData.join ', '
      when 'researchResults'
        <div>
          {if cellData
            <span className='research-results-checkmark' />
          }
          {if isRowSelected
            <ReferenceControls referenceId={referenceId} />
          }
        </div>
      when 'title'
        <div>
          <div className="text">{cellData}</div>
          {not referenceUsedIn.isEmpty() and
            <Tooltip>
              <IconButton
                className="reference-usage-icon"
                iconName="connections"
                onClick={@openReferenceUsageModal}
                title={@i18n 'see_usage'}
              />
            </Tooltip>
          }
        </div>
      else cellData

  render: ->
    cellContent = @_getContent()
    <Tooltip>
      <div
        className={@props.className}
        title={cellContent if @props.cellName is 'authors'}
      >
        {cellContent}
      </div>
    </Tooltip>

module.exports = ReferenceCell
