var __slice = [].slice,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

_.namespace(module, function(require) {
  var DbHelper, ImportActions, ImportUtils, Outcomes, QuestionsActions, QuestionsStore, Rm5ImportVerificationView, W, callMethod, createReferenceDoc, mediator, mergeOutcomes, mergeQuestions, sequence, studyDocPrefix, uid, _ref, _ref1;
  mediator = require('mediator');
  W = require('when');
  sequence = require('when/sequence');
  DbHelper = require('base/lib/db_helper');
  _ref = require('base/lib/utils'), callMethod = _ref.callMethod, uid = _ref.uid;
  ImportActions = require('actions/import_actions');
  ImportUtils = require('lib/import_utils');
  createReferenceDoc = require('lib/references_utils').createReferenceDoc;
  studyDocPrefix = require('lib/doc_prefixes').study;
  Outcomes = require('models/outcomes');
  Rm5ImportVerificationView = require('views/rm5_import_verification_view');
  QuestionsActions = require('actions/questions_actions');
  QuestionsStore = require('stores/questions_store');
  _ref1 = require('lib/revman_csv_import'), mergeOutcomes = _ref1.mergeOutcomes, mergeQuestions = _ref1.mergeQuestions;
  return {
    importProjectFromJsonFile: function(file) {
      var reader, readingFile;
      readingFile = W.defer();
      reader = new FileReader();
      reader.onload = (function(_this) {
        return function(loadEvent) {
          var error;
          try {
            return readingFile.resolve(_this._importJSONProject(loadEvent.target.result, file.name));
          } catch (_error) {
            error = _error;
            return readingFile.reject(error);
          }
        };
      })(this);
      reader.readAsText(file, 'utf-8');
      return readingFile.promise;
    },
    _importJSONProject: function(projectAsJson) {
      var docs, projectDb, projectDoc, projectId;
      docs = JSON.parse(projectAsJson);
      projectDoc = _(docs).findWhere({
        docType: 'project'
      });
      projectId = projectDoc._id;
      projectDb = DbHelper.localReplica(projectId);
      return W(projectDb.bulkDocs(docs, {
        new_edits: false
      })).then(function() {
        var userProject, userProjects;
        userProjects = mediator.user.get('projects');
        userProject = _(userProjects).findWhere({
          name: projectId
        });
        if (userProject) {
          userProject.status = 'replication_on';
        } else {
          userProjects.push({
            name: projectId,
            status: 'replication_on'
          });
        }
        return mediator.user.save({
          projects: userProjects
        });
      });
    },
    importProject: function(_arg) {
      var includeReferences, organizationId, parsedProjects, projectName;
      parsedProjects = _arg.parsedProjects, projectName = _arg.projectName, includeReferences = _arg.includeReferences, organizationId = _arg.organizationId;
      if (organizationId != null) {
        this._addOrganizationIdToProjects(parsedProjects, organizationId);
      }
      return this._createProjects(parsedProjects, includeReferences, organizationId, projectName);
    },
    _parseProjectQuestions: function(isRm5OrArchie, isArchie) {
      if (isArchie == null) {
        isArchie = false;
      }
      return (function(_this) {
        return function(parsedProjects) {
          return _this._parseQuestions(parsedProjects, isRm5OrArchie, isArchie);
        };
      })(this);
    },
    parseFile: function(importOptions) {
      var file, fileExtension;
      file = importOptions.file;
      fileExtension = ImportUtils.getFileExtension(file);
      return W(ImportUtils.parseFile(file)).then(this._parseProjectQuestions(fileExtension === 'rm5'));
    },
    _addOrganizationIdToProjects: function(parsedProjects, organizationId) {
      return _.each(parsedProjects, function(_arg) {
        var project;
        project = _arg.project;
        return project.set('organizationId', organizationId);
      });
    },
    _createProjects: function(parsedProjects, includeReferences, organizationId, projectName) {
      var createFn, creatingProjects, project, questions;
      createFn = (function(_this) {
        return function(project, questions) {
          return _this._createProjectWithQuestions(project, questions, organizationId, projectName);
        };
      })(this);
      creatingProjects = (function() {
        var _i, _len, _ref2, _results;
        _results = [];
        for (_i = 0, _len = parsedProjects.length; _i < _len; _i++) {
          _ref2 = parsedProjects[_i], project = _ref2.project, questions = _ref2.questions;
          _results.push(createFn(project, questions));
        }
        return _results;
      })();
      if (!parsedProjects.length) {
        throw new Exceptions["import"].no_projects_in_file;
      }
      return sequence(creatingProjects).then((function(_this) {
        return function(projects) {
          return _this.verifyRm5Import(parsedProjects).then(function() {
            var importMetadata, _ref2;
            _ref2 = parsedProjects[0], project = _ref2.project, importMetadata = _ref2.importMetadata;
            if (importMetadata) {
              return _this._saveImportDoc(importMetadata, project.id);
            }
          }).then(function() {
            if (_(parsedProjects).size() === 1) {
              project = parsedProjects[0].project;
              mediator.publish('!router:route', "/projects/" + project.id, function(routed) {
                if (routed) {
                  return mediator.dialogs.success($.t('projects:import_dialog.import_project_successful'));
                } else {
                  return Raven.captureMessage('Switching to the newly imported project was unsuccessful');
                }
              });
            } else {
              mediator.services.replication.sendLocalChanges(_(projects).pluck('id')).then(function() {
                var message;
                message = $.t('projects:import_dialog.import_several_projects_successful');
                return mediator.dialogs.success(message);
              });
            }
            return projects;
          }, function(err) {
            if (err !== 'cancelled') {
              throw err;
            }
          });
        };
      })(this), function(err) {
        throw new Error("Import failed. Reason: " + err.message);
      }).then((function(_this) {
        return function(projects) {
          if (!projects) {
            return;
          }
          if (!includeReferences) {
            return;
          }
          return W.all(_.map(projects, function(project, idx) {
            var studies;
            studies = parsedProjects[idx].studies;
            return _this._createStudiesWithReferences(studies, project.id);
          }));
        };
      })(this));
    },
    _createProjectWithQuestions: function(project, questions, organizationId, projectName) {
      return function() {
        var createProjectFn;
        createProjectFn = function(model) {
          var userName;
          if (!_.isEmpty(projectName)) {
            model.set('name', projectName);
          }
          if (organizationId != null) {
            userName = mediator.user.get('name');
            return mediator.projects.createOrganizationProject(model, organizationId, [userName]);
          } else {
            return mediator.projects.create(model);
          }
        };
        return W(createProjectFn(project)).then(function(project) {
          return QuestionsActions.createQuestions({
            questionsCollectionType: 'regular',
            questions: _.map(questions, callMethod('toJSON')),
            projectId: project.id
          }).then(function() {
            return project;
          });
        });
      };
    },
    _saveImportDoc: function(importMetadata, projectId) {
      var fileName, importDocId, importId, importedDataDoc;
      if (projectId == null) {
        projectId = mediator.project.id;
      }
      fileName = importMetadata.fileName;
      importId = "imported_data_" + fileName;
      importDocId = importId.toLowerCase().replace(/\s/g, '_');
      importedDataDoc = _.extend(importMetadata, {
        _id: importDocId,
        docType: 'importData',
        type: 'revman'
      });
      return mediator.services.storePersistenceAdapter.updateOrCreate(projectId, importDocId, function(doc) {
        return _.extend(doc, importedDataDoc);
      })["catch"](function(err) {
        throw err;
      });
    },
    verifyRm5Import: function(parsedProjects) {
      var questions, verificationNeeded;
      questions = parsedProjects[0].questions;
      verificationNeeded = false;
      verificationNeeded = _(questions).any(function(q) {
        return _(q.get('outcomes').models).any(function(o) {
          return o.rm5importVerification;
        });
      });
      if (verificationNeeded) {
        return new Rm5ImportVerificationView({
          questions: questions
        }).promise();
      } else {
        return W.resolve();
      }
    },
    _createStudiesWithReferences: function(studies, projectId) {
      if (_.isEmpty(studies)) {
        return;
      }
      return W.map(studies, function(study) {
        var referenceDocs, studyDoc;
        referenceDocs = _.map(study.references, createReferenceDoc);
        studyDoc = _.extend(study, {
          _id: "" + studyDocPrefix + (study.revmanId.toLowerCase()),
          docType: 'study',
          references: _.pluck(referenceDocs, '_id')
        });
        return mediator.services.storePersistenceAdapter.bulkDocs(projectId, [studyDoc].concat(__slice.call(referenceDocs)))["catch"](function(err) {
          if (err.status === 409) {
            return;
          }
          throw err;
        });
      });
    },
    _parseQuestions: function(parsedProjects, isRm5OrArchie, isArchie) {
      var messageKey, questions, type;
      questions = _.chain(parsedProjects).pluck('questions').flatten().value();
      if (!questions.length && isRm5OrArchie) {
        type = parsedProjects[0].project.get('rm5ReviewType');
        messageKey = "projects:import_dialog.no_questions_included_" + (type.toLowerCase());
        if (isArchie) {
          messageKey = "" + messageKey + "_archie";
        }
        throw new Error($.t(messageKey));
      }
      return {
        parsedProjects: parsedProjects,
        questions: questions
      };
    },
    importQuestions: function(importData) {
      var absentQuestions, checkedQuestions, currentQuestions, importMetadata, includeReferences, parsedProjects, presentQuestions, promise, questions, selectedItems, studies, _ref2;
      parsedProjects = importData.parsedProjects, selectedItems = importData.selectedItems, questions = importData.questions, includeReferences = importData.includeReferences;
      studies = _.chain(parsedProjects).pluck('studies').flatten().compact().value();
      importMetadata = parsedProjects[0].importMetadata;
      checkedQuestions = questions.filter(function(q) {
        var _ref2;
        return _ref2 = q.cid, __indexOf.call(selectedItems, _ref2) >= 0;
      });
      currentQuestions = QuestionsStore.getQuestions();
      _ref2 = _.partition(checkedQuestions, function(question) {
        return currentQuestions.has(question.get('_id'));
      }), presentQuestions = _ref2[0], absentQuestions = _ref2[1];
      promise = presentQuestions.length > 0 ? this._openRevmanCsvResolutionDialogAndGetSelectedMethod().then(function(conflictResolutionMethod) {
        var createQuestionsPromise, questionsToCreate, updateQuestionsPromise;
        switch (conflictResolutionMethod) {
          case 'merge':
            updateQuestionsPromise = sequence(_.map(presentQuestions, function(question) {
              return function() {
                var currentQuestion, questionData, questionId;
                questionId = question.get('_id');
                currentQuestion = currentQuestions.get(questionId).toJS();
                questionData = mergeQuestions(question, currentQuestion);
                return QuestionsActions.updateQuestion({
                  questionId: questionId,
                  questionData: questionData
                });
              };
            }));
            createQuestionsPromise = QuestionsActions.createQuestions({
              questionsCollectionType: 'regular',
              questions: _.map(absentQuestions, callMethod('toJSON')),
              projectId: mediator.project.id
            });
            return W.all([updateQuestionsPromise, createQuestionsPromise]);
          case 'import_new':
            questionsToCreate = _.map(checkedQuestions, function(question) {
              if (currentQuestions.has(question.get('_id'))) {
                question.set('_id', "" + (question.get('_id')) + "_" + (uid()));
              }
              return question.toJSON();
            });
            return QuestionsActions.createQuestions({
              questionsCollectionType: 'regular',
              questions: questionsToCreate,
              projectId: mediator.project.id
            });
          default:
            return W.reject('unknown_method');
        }
      }) : QuestionsActions.createQuestions({
        questionsCollectionType: 'regular',
        questions: _.map(checkedQuestions, callMethod('toJSON')),
        projectId: mediator.project.id
      });
      return promise.then((function(_this) {
        return function() {
          if (importMetadata != null) {
            return _this._saveImportDoc(importMetadata);
          } else {
            return W.resolve();
          }
        };
      })(this)).then((function(_this) {
        return function() {
          var usedStudies, usedStudiesIds;
          if (!includeReferences) {
            return W.resolve();
          }
          if (checkedQuestions.length === questions.length) {
            return _this._createStudiesWithReferences(studies, mediator.project.id);
          } else {
            usedStudiesIds = _.chain(checkedQuestions).map(function(q) {
              return q.get('outcomes').pluck('studies');
            }).flatten().compact().map(function(studiesMap) {
              return _.keys(studiesMap);
            }).flatten().uniq().value();
            usedStudies = studies.filter(function(_arg) {
              var revmanId;
              revmanId = _arg.revmanId;
              return __indexOf.call(usedStudiesIds, revmanId) >= 0;
            });
            return _this._createStudiesWithReferences(usedStudies, mediator.project.id);
          }
        };
      })(this)).then(function() {
        return mediator.dialogs.success($.t('projects:import_dialog.import_questions_successful'));
      });
    },
    importOutcomes: function(importData) {
      var deferred;
      deferred = W.defer();
      setTimeout((function(_this) {
        return function() {
          return _this._importOutcomes(importData).then(deferred.resolve, deferred.reject);
        };
      })(this), 1000);
      return deferred.promise;
    },
    _importOutcomes: function(importData) {
      var absentOutcomes, addOutcome, checkedOutcomes, conflictResolutionMethodPromise, importMetadata, importOutcome, includeReferences, model, outcomesArray, parsedProjects, presentOutcomes, presentOutcomesIds, questions, selectedItems, studies, _ref2;
      questions = importData.questions, parsedProjects = importData.parsedProjects, selectedItems = importData.selectedItems, includeReferences = importData.includeReferences, model = importData.model;
      studies = _.chain(parsedProjects).pluck('studies').flatten().compact().value();
      importMetadata = parsedProjects[0].importMetadata;
      outcomesArray = _.chain(questions).pluck('attributes').pluck('outcomes').pluck('models').flatten().value();
      checkedOutcomes = _.filter(outcomesArray, function(o) {
        var _ref2;
        return _ref2 = o.cid, __indexOf.call(selectedItems, _ref2) >= 0;
      });
      presentOutcomesIds = [];
      _ref2 = _.partition(checkedOutcomes, function(outcome) {
        var outcomePresent;
        outcomePresent = model.get('outcomes').get(outcome.get('_id')) != null;
        if (outcomePresent) {
          presentOutcomesIds.push(outcome.get('_id'));
        }
        return outcomePresent;
      }), presentOutcomes = _ref2[0], absentOutcomes = _ref2[1];
      conflictResolutionMethodPromise = presentOutcomes.length > 0 ? this._openRevmanCsvResolutionDialogAndGetSelectedMethod() : W.resolve();
      addOutcome = function(outcome) {
        outcome.collection = null;
        outcome.set(Outcomes.prototype.comparatorAttribute, null);
        return model.get('outcomes').add(outcome, {
          external: true
        });
      };
      importOutcome = function(conflictResolutionMethod) {
        return function(outcome) {
          return function() {
            var mergedOutcome, outcomeId;
            outcomeId = outcome.get('_id');
            if (__indexOf.call(presentOutcomesIds, outcomeId) >= 0) {
              switch (conflictResolutionMethod) {
                case 'merge':
                  mergedOutcome = mergeOutcomes(outcome, model.get('outcomes').get(outcomeId).toJSON());
                  return model.get('outcomes').get(outcomeId).set(mergedOutcome, {
                    external: true
                  });
                case 'import_new':
                  outcome.set('_id', "" + outcomeId + "_" + (uid()));
                  return addOutcome(outcome);
                default:
                  return W.reject('unknown_method');
              }
            } else {
              return addOutcome(outcome);
            }
          };
        };
      };
      return conflictResolutionMethodPromise.then(function(conflictResolutionMethod) {
        return sequence(_.map(checkedOutcomes, importOutcome(conflictResolutionMethod)));
      }).then(function() {
        return W(model.save());
      }).then((function(_this) {
        return function() {
          if (importMetadata != null) {
            return _this._saveImportDoc(importMetadata);
          } else {
            return W.resolve();
          }
        };
      })(this)).then((function(_this) {
        return function() {
          var usedStudies, usedStudiesIds;
          if (!includeReferences) {
            return W.resolve();
          }
          if (outcomesArray.length === checkedOutcomes.length) {
            return _this._createStudiesWithReferences(studies, mediator.project.id);
          } else {
            usedStudiesIds = _.chain(checkedOutcomes).map(function(o) {
              return o.get('studies');
            }).compact().map(function(studiesMap) {
              return _.keys(studiesMap);
            }).flatten().uniq().value();
            usedStudies = studies.filter(function(_arg) {
              var revmanId;
              revmanId = _arg.revmanId;
              return __indexOf.call(usedStudiesIds, revmanId) >= 0;
            });
            return _this._createStudiesWithReferences(usedStudies, mediator.project.id);
          }
        };
      })(this)).then(function() {
        return mediator.dialogs.success($.t('projects:import_dialog.import_outcomes_successful'));
      });
    },
    _openRevmanCsvResolutionDialogAndGetSelectedMethod: function() {
      var deferred;
      return W.resolve('import_new');
      deferred = W.defer();
      ImportActions.openRevmanCsvConflictMethodDialog(deferred);
      return deferred.promise;
    }
  };
});
