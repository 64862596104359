var DocumentInstance, TypeValidationError, docInst, getNestedType, immIso, immPath, isImmutable, mediator, path, typeValidationInvariant, utils, _ref,
  __slice = [].slice;

mediator = require('mediator');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

_ref = require('lib/lens_utils'), path = _ref.lensPath, immPath = _ref.immLensPath, immIso = _ref.immIso;

utils = require('base/lib/utils');

isImmutable = require('lib/immutable_utils').isImmutable;

getNestedType = require('lib/db_docs/field_types/utils').getNestedType;

typeValidationInvariant = require('lib/db_docs/document_instance_helpers').typeValidationInvariant;

DocumentInstance = (function() {
  function DocumentInstance(docShape, data, methods) {
    this.docShape = docShape;
    this._data = data;
    this._methods = methods;
    this.isImmutable = isImmutable(data);
    _.extend(this, this._methods);
  }

  DocumentInstance.prototype._keysToLensPath = function(keysOrLenses) {
    if (this.isImmutable) {
      return immPath.apply(null, keysOrLenses.concat(immIso));
    } else {
      return path.apply(null, keysOrLenses);
    }
  };

  DocumentInstance.prototype.getAt = function() {
    var focusedType, keysOrLenses, virtualFieldParent, virtualVal;
    keysOrLenses = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
    focusedType = getNestedType(this.docShape, keysOrLenses);
    if (focusedType != null ? focusedType.is('virtual') : void 0) {
      virtualFieldParent = keysOrLenses.length === 1 ? this._data : R.view(this._keysToLensPath(R.init(keysOrLenses)), this._data);
      virtualVal = focusedType['_typeMeta'].valueFn(virtualFieldParent);
      return typeValidationInvariant(R.identity, R.always(focusedType.typeDefault))(focusedType.check(virtualVal));
    } else {
      return R.view(this._keysToLensPath(keysOrLenses), this._data);
    }
  };

  DocumentInstance.prototype.setAt = function() {
    var keysOrLenses;
    keysOrLenses = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
    return (function(_this) {
      return function(val) {
        return _this.updateAt.apply(_this, keysOrLenses)(R.always(val));
      };
    })(this);
  };

  DocumentInstance.prototype.updateAt = function() {
    var keysOrLenses;
    keysOrLenses = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
    return (function(_this) {
      return function(updater) {
        var docData, focusedType, lensPath, maybeUpdatedDoc, updated, updatedData;
        docData = _this.json();
        lensPath = _this._keysToLensPath(keysOrLenses);
        focusedType = getNestedType(_this.docShape, keysOrLenses);
        if (focusedType == null) {
          throw new Error('Document Instance Update: key to be updated is not declared.');
        }
        updated = R.over(lensPath, function(focused) {
          if (_.isUndefined(focused)) {
            focused = focusedType.typeDefault;
          }
          return focusedType.parse(updater(focused, focusedType));
        }, docData);
        maybeUpdatedDoc = _this.docShape.parse(updated);
        if (utils.isInstanceOf(TypeValidationError, maybeUpdatedDoc)) {
          mediator.dialogs.warning(maybeUpdatedDoc.message);
          return _this;
        } else {
          updatedData = _this.isImmutable ? Immutable.fromJS(maybeUpdatedDoc) : maybeUpdatedDoc;
          if (utils.objectsEqual(updatedData, _this._data)) {
            return _this;
          } else {
            return new DocumentInstance(_this.docShape, updatedData, _this._methods);
          }
        }
      };
    })(this);
  };

  DocumentInstance.prototype.json = function() {
    if (this.isImmutable) {
      return this._data.toJS();
    } else {
      return JSON.parse(JSON.stringify(this._data));
    }
  };

  return DocumentInstance;

})();

docInst = function(docShape, methods) {
  if (methods == null) {
    methods = {};
  }
  return function(data) {
    var maybeParsed;
    maybeParsed = docShape.parse(isImmutable(data) ? data.toJS() : data);
    if (utils.isInstanceOf(TypeValidationError, maybeParsed)) {
      throw new Error(maybeParsed.message);
    } else {
      return new DocumentInstance(docShape, data, methods);
    }
  };
};

module.exports = docInst;
