ConslusionsSectionVotingDetailsRow =
  require 'components/etd/conclusions_section_voting_details_row'
VotingTitleRow = require 'components/etd/voting_section_title_row'
EtdSectionWithVotingMixin = require 'components/mixins/etd_section_with_voting_data_mixin'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

TypeOfRecommendationWithVoting = createReactClass

  displayName: "TypeOfRecommendationWithVoting"

  mixins: [
    CustomRenderMixin
    EtdSectionWithVotingMixin 'recommendation'
  ]

  render: ->
    votingProps = @_getVotingProps()
    <tbody>
      <VotingTitleRow
        renderMode={@props.renderMode}
        sectionId={@props.sectionId}
        sectionName={@props.section.get 'description'}
        {...votingProps}
      />
      <ConslusionsSectionVotingDetailsRow
        sectionId={@props.sectionId}
        renderMode={@props.renderMode}
        isProposedVoting={@props.isProposedVoting}
        noOfVotingForms={@props.noOfVotingForms}
        {...votingProps}
      />
    </tbody>

module.exports = TypeOfRecommendationWithVoting
