{ bool, string, number, shape } = PropTypes

animation = shape
  name: string.isRequired
  duration: number
  timingFn: string

defaultInAnimation =
  name: 'fade-in'

defaultOutAnimation =
  name: 'fade-out'

AnimatedCollectionItem = (Component, wrapProps = {}) ->
  createReactClass
    displayName: 'AnimatedCollectionItem'

    propTypes:
      animationsDuration: number
      animationsTimingFn: string
      appearAnimation: animation.isRequired
      enterAnimation: animation.isRequired
      leaveAnimation: animation.isRequired
      skipAppearAnimation: bool
      skipEnterAnimation: bool
      skipLeaveAnimation: bool
      scrollEnteredIntoView: bool

    getDefaultProps: ->
      animationsDuration: wrapProps.animationsDuration ? 700
      animationsTimingFn: wrapProps.animationsTimingFn ? 'ease-in-out'
      appearAnimation: wrapProps.appearAnimation ? defaultInAnimation
      enterAnimation: wrapProps.enterAnimation ? defaultInAnimation
      leaveAnimation: wrapProps.leaveAnimation ? defaultOutAnimation
      skipAppearAnimation: wrapProps.skipAppearAnimation ? false
      skipEnterAnimation: wrapProps.skipEnterAnimation ? false
      skipLeaveAnimation: wrapProps.skipLeaveAnimation ? false
      scrollEnteredIntoView: wrapProps.scrollEnteredIntoView ? false

    # lifeCyclePhase = appear | enter | leave
    addAnimationStyles: (lifeCyclePhase) ->
      $el = ReactDOM.findDOMNode(this)
      @beforeAnimationStyles = $el.getAttribute('style') ? ''
      { name, duration, timingFn } = @props["#{lifeCyclePhase}Animation"]

      styles = "#{@beforeAnimationStyles}
        animation-name: #{name};
        animation-duration: #{duration ? @props.animationsDuration}ms;
        animation-timing-function: #{timingFn ? @props.animationsTimingFn};
      "
      $el.setAttribute 'style', styles

    _wrapCb: (cb) -> =>
      # protects provided callback fn from being called in the context of already unmounted
      # component
      cb() if @mounted

    removeAnimationStyles: ->
      $el = ReactDOM.findDOMNode(this)
      $el.setAttribute 'style', @beforeAnimationStyles
      @beforeAnimationStyles = null

    _startAnimation: (lifeCyclePhase, cb) ->
      animationDuration = @props["#{lifeCyclePhase}Animation"].duration ? @props.animationsDuration
      @addAnimationStyles lifeCyclePhase
      _.delay @_wrapCb(cb), animationDuration

    componentWillAppear: (cb) ->
      return cb() if @props.skipAppearAnimation
      @_startAnimation 'appear', cb

    componentDidAppear: ->
      return if @props.skipAppearAnimation
      _.defer @_wrapCb(@removeAnimationStyles)

    componentWillEnter: (cb) ->
      return cb() if @props.skipEnterAnimation
      if @props.scrollEnteredIntoView
        @scrollItemIntoView()
        # need to intercept any element scrolling during animation to ensure the animated element
        # is visible (thus passing scrollItemIntoView as event listener)
        window.addEventListener 'scroll', @scrollItemIntoView, true
      @_startAnimation 'enter', cb

    componentDidEnter: ->
      if @props.scrollEnteredIntoView
        window.removeEventListener 'scroll', @scrollItemIntoView, true

      return if @props.skipEnterAnimation
      _.defer @_wrapCb(@removeAnimationStyles)

    componentWillLeave: (cb) ->
      return cb() if @props.skipLeaveAnimation
      @_startAnimation 'leave', cb

    componentDidLeave: ->
      return if @props.skipLeaveAnimation
      _.defer @_wrapCb(@removeAnimationStyles)

    componentDidMount: ->
      @mounted = true

    componentWillUnmount: ->
      @mounted = false

    scrollItemIntoView: ->
      $el = ReactDOM.findDOMNode(this)
      $el.scrollIntoView(false)

    render: ->
      # do not pass animation props further
      sanitizedProps = _.omit @props, ['animationsDuration', 'animationsTimingFn',
        'appearAnimation', 'enterAnimation', 'leaveAnimation', 'skipAppearAnimtion',
        'skipEnterAnimation', 'skipLeaveAnimation']
      <Component {...sanitizedProps} />

module.exports = AnimatedCollectionItem
