QuestionsListWithButtons =
  require 'components/administration/voting/voting_questions_list_with_buttons'
SendVoteModal = require 'components/administration/voting/voting_sending_modal'
VotingButton = require 'components/administration/voting/voting_button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ModalDialogsMixin = require 'components/mixins/modal_dialogs_mixin'
{ string, array, func, instanceOf, oneOfType } = PropTypes

VotingStart = createReactClass
  displayName: 'VotingStartBlock'
  propTypes:
    phase: string.isRequired
    projectId: string.isRequired
    questions: instanceOf(Immutable.List).isRequired
    etds: instanceOf(Immutable.Map).isRequired
    etdPartLabels: array.isRequired
    message: oneOfType([instanceOf(Immutable.Map), string]).isRequired
    contactEmail: string.isRequired
    updateMessageDetails: func.isRequired
    sendVoting: func.isRequired
  mixins: [
    CustomRenderMixin
    ModalDialogsMixin
  ]

  getInitialState: ->
    sendVotingModal: false

  _getSelectedQuestionsIds: ->
    @_questionsList.getSelectedQuestionsIds()

  _getSelectedQuestions: ->
    @props.questions.filter (q) => @_getSelectedQuestionsIds().contains q.get 'id'

  _getPhase1EtdPartsForSending: ->
      etdParts =
        judgements:
          proposed: @props.selectedJudgmentsOption
      switch @props.selectedEtdPartsOption
        when 'AT'
          etdParts.recommendation = proposed: 'none'
        when 'ATC'
          etdParts.recommendation = proposed: 'all'
          etdParts.conclusions = proposed: 'all'
      etdParts

  _getPhase2EtdPartsForSending: ->
    if @props.lastlyVoted is 'recommendation'
      conclusions:
        proposed: 'all'
    else
      if @props.selectedContentOption is 'proposed'
        recommendation:
          proposed: 'all'
        conclusions:
          proposed: 'all'
      else
        recommendation:
          proposed: 'none'
        conclusions:
          proposed: 'none'

  _getEtdPartsForSending: ->
    return @_getPhase1EtdPartsForSending() if @props.phase is 'phase1a'
    @_getPhase2EtdPartsForSending() if @props.phase is 'phase2a'

  handleSendingCancellation: (data) ->
    @props.updateMessageDetails('votingStart', data) if data
    @closeModal('sendVotingModal')()

  sendVoting: (data) ->
    questionIds = @_getSelectedQuestionsIds().toArray()
    blocks = @_getEtdPartsForSending()
    @props.sendVoting questionIds, blocks, data, @props.phase
    @_questionsList.resetSelectedQuestions()

  startVoting: ->
    @showModal('sendVotingModal')() if @props.canVotingBeStarted()

  render: ->
    <div>
      <QuestionsListWithButtons
          ref={(element) => @_questionsList = element}
          withValidation={true}
          phase={@props.phase}
          projectId={@props.projectId}
          questions={@props.questions}
          questionGroups={@props.questionGroups}
          etds={@props.etds}
          selectedContentOption={@props.selectedContentOption}
          selectedEtdPartsOption={@props.selectedEtdPartsOption}
          selectedJudgmentsOption={@props.selectedJudgmentsOption}
        >
          <VotingButton type='start' onClick={@startVoting} />
        </QuestionsListWithButtons>
        {if @state.sendVotingModal
          <SendVoteModal
            type="start"
            message={@props.message}
            dueDate={@props.dueDate}
            contactEmail={@props.contactEmail}
            questions={@_getSelectedQuestions()}
            etdPartLabels={@props.etdPartLabels}
            onClose={@handleSendingCancellation}
            handleSending={@sendVoting}
          />
        }
    </div>

module.exports = VotingStart
