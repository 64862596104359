IconButton = require 'components/common/icon_button'
QuestionCodes = require 'components/common/question_codes'
Translation = require 'components/mixins/translation'

QUESTION_TAGS_PROPS =
  onAddCode: PropTypes.func.isRequired
  onAddTag: PropTypes.func.isRequired
  onRemoveCode: PropTypes.func.isRequired
  onRemoveTag: PropTypes.func.isRequired
  onUpdateTag: PropTypes.func.isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  tags: PropTypes.arrayOf(PropTypes.string).isRequired

TAG_ITEM_PROPS =
  displayCodes: PropTypes.bool.isRequired
  displayDelete: PropTypes.bool.isRequired
  tag: PropTypes.instanceOf(Immutable.Map).isRequired

TagItem = createReactClass
  displayName: 'QuestionTagItem'
  propTypes: _.extend TAG_ITEM_PROPS, _.pick QUESTION_TAGS_PROPS,
    'onAddCode', 'onRemoveCode', 'onRemoveTag', 'onUpdateTag'

  handleTagNameChange: (e) ->
    { onUpdateTag, tag } = @props
    updatedTag = tag.set 'name', e.target.value
    onUpdateTag updatedTag

  handleTagDelete: ->
    { onRemoveTag, tag } = @props
    onRemoveTag tag

  render: ->
    { displayCodes, displayDelete, onAddCode, onRemoveCode, tag } = @props

    <div className="tags__tag-item mb-10">
      <div className="tag-item__name">
        <input
          type="text"
          value={tag.get 'name'}
          onChange={@handleTagNameChange}
        />
      </div>
      {displayDelete and <div className="tag-item__delete-tag-item">
        <IconButton iconName="decline-icon" onClick={@handleTagDelete} />
      </div>}
      {displayCodes and <div className="tag-item__codes">
        <QuestionCodes
          codeGroup={tag.get 'type'}
          codes={tag.get 'codes'}
          onAdd={onAddCode}
          onRemove={onRemoveCode}
          parentId={tag.get 'id'}
        />
      </div>}
    </div>

TAG_PROPS =
  tagType: PropTypes.string.isRequired

Tag = createReactClass
  displayName: 'QuestionTag'
  mixins: [Translation()]
  propTypes: _.extend TAG_PROPS, _.pick QUESTION_TAGS_PROPS,
    'onAddCode', 'onAddTag', 'onRemoveCode', 'onRemoveTag', 'onUpdateTag', 'question'

  handleAddTagItem: ->
    { onAddTag, tagType } = @props
    onAddTag tagType

  render: ->
    { onAddCode, onRemoveCode, onRemoveTag, onUpdateTag, question, tagType } = @props
    displayAddButton = not _.include ['comparison', 'comparatorTest'], tagType
    displayCodes = not _.include ['comparison' ,'comparatorTest', 'outcome'], tagType

    tags = question
      .get 'includedTags', Immutable.List()
      .filter (tag) -> tag.get('type') is tagType and tag.get('included') is true

    showDelete = tags.size > 1

    <div className="mb-20 question-tags__question-tag">
      <div className="question-tag__label">
        {_.str.capitalize @i18n "es:question.#{_.str.underscored tagType}"}
      </div>
      <div className="question-tag__tags">
        {tags.map (tag) =>
          <TagItem
            displayCodes={displayCodes}
            displayDelete={showDelete}
            key={tag.get 'id'}
            onAddCode={onAddCode}
            onRemoveCode={onRemoveCode}
            onRemoveTag={onRemoveTag}
            onUpdateTag={onUpdateTag}
            tag={tag}
          />
        .toList()}
        {displayAddButton and <div className="tags__add-tag-item">
          <IconButton
            iconName="add"
            inlined
            label={@i18n "actions.add_#{_.str.underscored tagType}"}
            labelPosition="right"
            onClick={@handleAddTagItem}
          />
        </div>}
      </div>
    </div>

Tags = createReactClass
  displayName: 'QuestionTags'
  propTypes: QUESTION_TAGS_PROPS

  render: ->
    {
      onAddCode
      onAddTag
      onRemoveCode
      onRemoveTag
      onUpdateTag
      question
      tags
    } = @props

    <div className="question-tags">
      {_.map tags, (tagType) =>
        <Tag
          key={tagType}
          onAddCode={onAddCode}
          onAddTag={onAddTag}
          onRemoveCode={onRemoveCode}
          onRemoveTag={onRemoveTag}
          onUpdateTag={onUpdateTag}
          question={question}
          tagType={tagType}
        />
      }
    </div>

module.exports = {
  QUESTION_TAGS_PROPS
  QuestionTags: Tags
}
