ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStores = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
IconButton = require 'components/common/icon_button'
Modal = require 'components/common/modal'
QuestionsStore = require 'stores/questions_store'
QuestionGroupsStore = require 'stores/question_groups_store'
RelatedTableModal = require 'components/etd/related_recommendations_modal'
mediator = require 'mediator'
{ useI18n } = require 'lib/react_utils'
{ useState, useCallback, useMemo } = React
immutableUtils = require 'lib/immutable_utils'

storeConnectors =
  QuestionsStore: (Store) ->
    questions: Store.getQuestions()
  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups()


RelatedTableSelector = ({onChange, value: relatedTables, questions, questionGroups}) ->
  i18n = useI18n('es:recommendations.metadataExtractionForm')

  [showRelatedTableModal, setShowRelatedTableModal] = useState(false)

  openRelatedTableModal = useCallback ->
    setShowRelatedTableModal(true)
  , []

  closeRelatedTableModal = useCallback ->
    setShowRelatedTableModal(false)
  , []

  updateRelatedTableIds = useCallback (ids) ->
    onChange if immutableUtils.isImmutable(ids) then ids.toJS() else ids
    closeRelatedTableModal()
  , [onChange, closeRelatedTableModal]

  onDeletePlr = (id) -> -> updateRelatedTableIds _.without relatedTables, id

  <div className="multiple-inputs">
    {_.map relatedTables, (id) ->
      <div className="multiple-inputs__input" key={id}>
        <div className="flex-grow">
          <div className="question">
            {questions.getIn([id, 'question'], '')}
          </div>
        </div>
        <div>
          <IconButton
            className="ml-10"
            iconName="remove"
            onClick={onDeletePlr(id)}
          />
        </div>
      </div>
    }
    <div className="multiple-inputs__add">
      <IconButton
        iconName="add"
        label={i18n '/actions.add_related_table'}
        labelPosition="right"
        onClick={openRelatedTableModal}
      />
    </div>
    {showRelatedTableModal and <RelatedTableModal
      questions={questions}
      questionGroups={questionGroups}
      relatedQuestionsIds={Immutable.fromJS(relatedTables)}
      updateRelatedQuestionsIds={updateRelatedTableIds}
      onClose={closeRelatedTableModal}
      withSelectAll
      withSearch
    />}
  </div>

module.exports = ConnectStores RelatedTableSelector,
  [QuestionsStore, QuestionGroupsStore], storeConnectors
