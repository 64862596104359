var SwitchesService, mediator,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

mediator = require('mediator');

module.exports = SwitchesService = (function() {
  SwitchesService.prototype._cache = {};

  SwitchesService.prototype._serverCache = {};

  SwitchesService.prototype._organizationsCache = {};

  function SwitchesService() {
    mediator.subscribe('sessionStarted', (function(_this) {
      return function() {
        _this._cache = {};
        _this._serverCache = {};
        return _this._organizationsCache = {};
      };
    })(this));
  }

  SwitchesService.prototype._checkSwitch = function(name) {
    var cachedValue, organizationId, switchValue, switches, _base, _ref, _ref1, _ref2, _ref3, _ref4;
    if ((mediator.activeWorkspace == null) || mediator.activeWorkspace === 'personal') {
      if (_(this._cache).has(name)) {
        return this._cache[name];
      } else {
        switches = (_ref = mediator.user.get('switches')) != null ? _ref : [];
        return this._cache[name] = __indexOf.call(switches, '*') >= 0 || __indexOf.call(switches, name) >= 0;
      }
    } else {
      organizationId = mediator.activeWorkspace;
      cachedValue = (_ref1 = this._organizationsCache[organizationId]) != null ? _ref1[name] : void 0;
      if (cachedValue != null) {
        return cachedValue;
      }
      switchValue = (_ref2 = (_ref3 = mediator.userOrganizations[organizationId]) != null ? (_ref4 = _ref3.permissions) != null ? _ref4[name] : void 0 : void 0) != null ? _ref2 : false;
      if ((_base = this._organizationsCache)[organizationId] == null) {
        _base[organizationId] = {};
      }
      this._organizationsCache[organizationId][name] = switchValue;
      return switchValue;
    }
  };

  SwitchesService.prototype.isOn = function(name) {
    if (mediator.user != null) {
      return this._checkSwitch(name);
    } else {
      return false;
    }
  };

  SwitchesService.prototype.isServerSwitchOn = function(name) {
    var serverSwitches, _ref;
    if (_(this._serverCache).has(name)) {
      return this._serverCache[name];
    } else {
      serverSwitches = (_ref = window.gdt.serverSwitches) != null ? _ref : [];
      return this._serverCache[name] = __indexOf.call(serverSwitches, name) >= 0;
    }
  };

  return SwitchesService;

})();
