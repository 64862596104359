SpinnerButton = require 'components/common/spinner_button'
Translation = require 'components/mixins/translation'
DragDropTypes = require 'components/scope/outcomes/drag_drop_types'
DropTarget = ReactDnD.DropTarget

#
# DRAG 'N' DROP
#
targetDropSpec =
  drop: (props, monitor) -> props.onQuestionDrop()

targetDropCollect = (connect, monitor) ->
  connectDropTarget: connect.dropTarget()
  isTargetHovered: monitor.isOver() and monitor.canDrop()

Footer = createReactClass
  displayName: 'OutcomesGenerationFooter'
  mixins: [
    Translation('scope:outcomes')
  ]

  render: ->
    { connectDropTarget, isTargetHovered } = @props
    dropTargetClasses = classNames 'target', 'hovered': isTargetHovered

    <div className="footer">
      {connectDropTarget(
        <div className={dropTargetClasses}>
          {@i18n 'question_group_target'}
        </div>
      ) unless @props.disabled}
      <div className="buttons">
        <button
          className="btn add-outcome"
          onClick={@props.onAddOutcome}
          disabled={@props.disabled}
        >
          {@i18n 'add_outcome'}
        </button>
        <SpinnerButton
          className="btn btn-submit"
          disabled={@props.disabled}
          label={@i18n 'finish_and_send'}
          loading={@props.isSending}
          onClick={@props.onFinishAndSend}
        />
      </div>
    </div>

module.exports = DropTarget([DragDropTypes.QUESTION, DragDropTypes.QGROUP],
  targetDropSpec, targetDropCollect) Footer
