var armData, boolean, certaintyAssessment, documentId, effectType, initial, number, oneOf, optional, outcomeDataShape, shape, string, _ref;

documentId = require('lib/db_docs/field_types/document_id');

initial = require('lib/db_docs/field_types/type_decorators').initial;

oneOf = require('lib/db_docs/field_types/one_of');

shape = require('lib/db_docs/field_types/shape');

_ref = require('lib/db_docs/field_types/built_in_types'), boolean = _ref.boolean, number = _ref.number, string = _ref.string;

optional = shape.typeDecorators.optional;

certaintyAssessment = shape({
  rct: optional(boolean),
  riskOfBias: optional(oneOf(['Not serious', 'Serious', 'Very serious', 'Extremely serious'])),
  inconsistency: optional(oneOf(['Not serious', 'Serious', 'Very serious'])),
  indirectness: optional(oneOf(['Not serious', 'Serious', 'Very serious'])),
  imprecision: optional(oneOf(['Not serious', 'Serious', 'Very serious', 'Extremely serious'])),
  publicationBias: optional(oneOf(['Undetected', 'Strongly suspected'])),
  largeEffect: optional(oneOf(['No', 'Large', 'Very large'])),
  plausibleConfounding: optional(oneOf(['No', 'Would reduce demonstrated effect', 'Would suggest spurious effect'])),
  doseResponseGradient: optional(oneOf(['No', 'Yes'])),
  overall: optional(oneOf(['High', 'Moderate', 'Low', 'Very Low']))
});

armData = shape({
  numberOfParticipants: optional(number),
  numberOfEvents: optional(number),
  mean: optional(number)
});

effectType = shape({
  value: optional(number),
  ciLow: optional(number),
  ciHigh: optional(number),
  ciLevel: optional(number),
  effectMeasure: optional(oneOf(['OR', 'RR', 'Rate Ratio', 'HR', 'RD', 'MD', 'SMD', 'Other'])),
  effectMeasureOther: optional(string),
  certaintyAssessment: optional(certaintyAssessment),
  pValue: optional(number),
  i2: optional(number),
  incoherence: optional(number),
  autoCalculateAbsoluteEffect: optional(boolean),
  absoluteEffectValue: optional(number),
  absoluteEffectCiLow: optional(number),
  absoluteEffectCiHigh: optional(number),
  absoluteEffectCiLevel: optional(number)
});

outcomeDataShape = shape({
  interventionId: documentId,
  comparatorId: documentId,
  outcomeId: documentId,
  nmaEffect: optional(effectType),
  directEffect: optional(effectType),
  indirectEffect: optional(effectType),
  interpretation: optional(string),
  reverseInterpretation: optional(string),
  effectToUse: optional(oneOf(['network', 'direct', 'indirect'])),
  effectClassification: optional(oneOf(['Decreased', 'Increased', 'InsufficientData', 'NoEffect'])),
  interventionData: optional(armData),
  comparisonData: optional(armData),
  numberOfStudies: optional(number),
  totalNumberOfParticipants: optional(number)
});

module.exports = outcomeDataShape;
