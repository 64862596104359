Input = require 'components/common/input'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

QuestionFreeEdit = createReactClass
  displayName: 'QuestionFreeEdit'

  propTypes:
    data: PropTypes.object.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:question')
  ]

  getData: ->
    question: @_question.value

  componentDidMount: ->
    @_question.focus()

  render: ->
    <div className="freeform expanded">
      <Input
        inputRef={(el) => @_question = el}
        defaultValue={@props.data.get 'question'}
      />
    </div>

module.exports = QuestionFreeEdit
