var INITIAL_STATE, MDGPublicationActions, generalInformationDocId, generateStore, mediator;

generalInformationDocId = require('lib/doc_ids').GENERAL_INFORMATION;

generateStore = require('stores/utils/generate_store');

MDGPublicationActions = require('actions/mdg_publication_actions');

mediator = require('mediator');

INITIAL_STATE = Immutable.fromJS({
  meta: {
    fetchingError: null,
    isFetching: false,
    publishDialogOpen: false
  },
  generalInformationDoc: Immutable.Map(),
  lastMdgUpload: null,
  publishProgress: 0,
  publishProgressMaxCount: null,
  publishSuccess: false
});

module.exports = generateStore({
  name: 'MDGPublicationStore',
  initialState: INITIAL_STATE,
  boundActions: [MDGPublicationActions],
  methods: {
    onClosePublishDialog: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'publishDialogOpen'], false);
        state.set('publishProgress', 0);
        state.set('publishProgressMaxCount', null);
        return state.set('publishSuccess', false);
      }));
    },
    onDbChange: function(_arg) {
      var doc, _ref;
      doc = _arg.doc;
      if (doc._id === mediator.project.id) {
        this.setState(this.state.set('lastMdgUpload', (_ref = doc.lastMdgUpload) != null ? _ref : null));
      }
      if (doc._id === generalInformationDocId) {
        return this.setState(this.state.set('generalInformationDoc', Immutable.fromJS(doc)));
      }
    },
    onFetch: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'isFetching'], true);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchError: function(err) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'isFetching'], false);
        return state.setIn(['meta', 'fetchingError'], err);
      }));
    },
    onFetchSuccess: function(_arg) {
      var generalInformationDoc, lastMdgUpload;
      generalInformationDoc = _arg.generalInformationDoc, lastMdgUpload = _arg.lastMdgUpload;
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'isFetching'], false);
        state.setIn(['meta', 'fetchingError'], null);
        state.set('generalInformationDoc', Immutable.fromJS(generalInformationDoc));
        return state.set('lastMdgUpload', lastMdgUpload != null ? lastMdgUpload : null);
      }));
    },
    onPublish: function() {
      return this.setState(this.state.setIn(['meta', 'publishDialogOpen'], true));
    },
    onPublishError: function(err) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'publishDialogOpen'], false);
        state.set('publishProgress', 0);
        state.set('publishProgressMaxCount', null);
        return state.set('publishSuccess', false);
      }));
    },
    onPublishSuccess: function() {
      return this.setState(this.state.set('publishSuccess', true));
    },
    onSetPublishProgressMaxCount: function(maxCount) {
      return this.setState(this.state.set('publishProgressMaxCount', maxCount));
    },
    onUpdatePublishProgress: function() {
      return this.setState(this.state.update('publishProgress', function(progress) {
        return progress + 1;
      }));
    },
    getFetchingError: function() {
      return this.state.getIn(['meta', 'fetchingError']);
    },
    getGeneralInformationDoc: function() {
      return this.state.get('generalInformationDoc');
    },
    getLastMdgUpload: function() {
      return this.state.get('lastMdgUpload');
    },
    getPublishProgress: function() {
      var progress, progressMaxCount;
      progress = this.state.get('publishProgress');
      progressMaxCount = this.state.get('publishProgressMaxCount');
      if (!progressMaxCount) {
        return 0;
      }
      return progress / progressMaxCount;
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'isFetching']);
    },
    isPublishDialogOpen: function() {
      return this.state.getIn(['meta', 'publishDialogOpen']);
    },
    isPublishSuccess: function() {
      return this.state.get('publishSuccess');
    }
  }
});
