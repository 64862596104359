var DocSectionsTemplatesActions, alt, docSectionsTemplateHelper, docSectionsTemplateId, mediator, templatesDefinitions;

alt = require('alt');

mediator = require('mediator');

docSectionsTemplateHelper = require('stores/utils/doc_sections_template_helper');

templatesDefinitions = require('lib/doc_sections_templates/templates_definitions');

docSectionsTemplateId = 'doc-sections-template';

DocSectionsTemplatesActions = (function() {
  function DocSectionsTemplatesActions() {
    this.generateActions('fetchSuccess', 'fetchFailure', 'saveSuccess', 'saveFailure', 'editTemplate', 'cancelEdit', 'revertChanges');
  }

  DocSectionsTemplatesActions.prototype.fetch = function() {
    return mediator.services.storePersistenceAdapter.fetch(mediator.project.id, docSectionsTemplateId).then(this.actions.fetchSuccess)["catch"]((function(_this) {
      return function(err) {
        var baseTemplate;
        if (err.status === 404) {
          baseTemplate = docSectionsTemplateHelper.prepareBaseTemplate(mediator.project.get('templateType') || templatesDefinitions[0].id).set('_id', docSectionsTemplateId);
          _this.actions.save(baseTemplate);
          return _this.actions.fetchSuccess(baseTemplate);
        } else {
          return _this.actions.fetchFailure(err);
        }
      };
    })(this));
  };

  DocSectionsTemplatesActions.prototype.save = function(template) {
    var projectId;
    projectId = mediator.project.id;
    return docSectionsTemplateHelper.updateDocumentSectionsDoc(projectId, template).then((function(_this) {
      return function() {
        return mediator.services.storePersistenceAdapter.save(projectId, template.toJS()).then(_this.actions.saveSuccess);
      };
    })(this))["catch"](this.actions.saveFailure);
  };

  DocSectionsTemplatesActions.prototype.saveTemplate = function(template) {
    var projectDbName;
    projectDbName = mediator.project.id;
    return mediator.services.storePersistenceAdapter.fetch(projectDbName, docSectionsTemplateId).then((function(_this) {
      return function(doc) {
        var updtedTemplate;
        updtedTemplate = docSectionsTemplateHelper.prepareTemplate(template.get('templateDef'), template.get('templateData')).withMutations(function(template) {
          template.set('_id', doc._id);
          return template.set('_rev', doc._rev);
        });
        return _this.actions.save(updtedTemplate);
      };
    })(this));
  };

  DocSectionsTemplatesActions.prototype.prepareTemplate = function(templateOrTemplateId) {
    var baseTemplate;
    if (_.isString(templateOrTemplateId)) {
      baseTemplate = docSectionsTemplateHelper.prepareBaseTemplate(templateOrTemplateId);
      this.actions.save(baseTemplate);
      return this.dispatch(baseTemplate);
    } else {
      return this.dispatch(docSectionsTemplateHelper.prepareTemplate(templateOrTemplateId.get('templateDef'), templateOrTemplateId.get('templateData'), true).withMutations(function(template) {
        template.set('_id', templateOrTemplateId.get('_id'));
        return template.set('_rev', templateOrTemplateId.get('_rev'));
      }));
    }
  };

  DocSectionsTemplatesActions.prototype.toggleSection = function(blockName, sectionId) {
    return this.dispatch({
      blockName: blockName,
      sectionId: sectionId
    });
  };

  return DocSectionsTemplatesActions;

})();

module.exports = alt.createActions(DocSectionsTemplatesActions);
