ConnectStore = require 'components/enhancers/connect_store'
IconButton = require 'components/common/icon_button'
NMAImportModalActions = require 'actions/nma_import_modal_actions'
NMAImportModalStore = require 'stores/nma_import_modal_store'
NMAQuestionStore = require 'stores/nma_question_store'
RadioWithLabel = require 'components/common/radio_with_label'
SelectCustom = require 'components/common/select_custom'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

storeConnector =
  NMAImportModalStore: (Store) ->
    mappingStepData: Store.getImportMappingStepData()
    outcome: Store.getImportOutcome()
  NMAQuestionStore: (Store) ->
    question: Store.getCurrentQuestion()

SPECIAL_MAPPING_VALUES = ['_add', '_assign', '_delete']

NMAMappingOption = ({ text }) ->
  <Tooltip>
    <span title={text}>{text}</span>
  </Tooltip>

NMAMappingOption.propTypes =
  text: PropTypes.string.isRequired

NMAMappingIntervention = ({ intervention, mapping, question, type }) ->
  i18n = useI18n 'nma:import_modal'
  interventionName = intervention.get 'name'
  interventionId = intervention.get 'id'
  mappingForIntervention = mapping.getIn ['mapping', interventionId]

  deleteIntervention = useCoffeeCallback [interventionId], ->
    NMAImportModalActions.setImportMappingEntry { interventionId, value: '_delete' }

  setInterventionMapping = useCoffeeCallback [interventionId, mappingForIntervention], (evt) ->
    value = evt.target.value
    # Ignore clicking on assign radio if already some intervention from question is assigned
    return if value is '_assign' and mappingForIntervention? and
      mappingForIntervention not in SPECIAL_MAPPING_VALUES
    NMAImportModalActions.setImportMappingEntry { interventionId, value }

  assignIntervention = useCoffeeCallback [interventionId], (value) ->
    NMAImportModalActions.setImportMappingEntry { interventionId, value }

  assignOptions = useCoffeeMemo [i18n, mapping, mappingForIntervention, question], ->
    idsUsed = mapping.get('mapping', Immutable.Map())
      .valueSeq()
      .toList()
      .filterNot (key) -> key in SPECIAL_MAPPING_VALUES

    interventionOptions = question.get('interventions', Immutable.List())
      .filterNot (intervention) ->
        id = intervention.get '_id'
        id isnt mappingForIntervention and idsUsed.includes id
      .map (intervention) ->
        value: intervention.get '_id'
        text: intervention.get 'name'
      .toJS()

    _.union [{ value: '_assign', text: i18n 'choose_intervention' }], interventionOptions

  assignOptionsValues = useCoffeeMemo [assignOptions], ->
    _.pluck assignOptions, 'value'

  assignDisabled = assignOptions.length is 1 # (only '_assign' option)

  return null if type is 'matched' and mappingForIntervention is '_delete'

  <React.Fragment>
    <div className="nma-import-outcome-modal__intervention">
      <Tooltip>
        <div className="intervention__name" title={interventionName}>
          {interventionName}
        </div>
      </Tooltip>
      {type is 'matched' and
        <IconButton
          className="intervention__delete"
          iconName="delete"
          label={i18n '/actions.delete'}
          labelPosition="right"
          onClick={deleteIntervention}
        />
      }
    </div>
    {type is 'unmatched' and <div className="nma-import-outcome-modal__intervention-options">
      <div className={"assign-option #{if assignDisabled then 'disabled' else ''}"}>
        <div className="assign-option__radio">
          <RadioWithLabel
            checked={mappingForIntervention? and mappingForIntervention not in ['_add', '_delete']}
            disabled={assignDisabled}
            label={i18n 'options.assign'}
            name={interventionId}
            onChange={setInterventionMapping}
            value="_assign"
          />
        </div>
        <div className="assign-option__select">
          <SelectCustom
            disabled={assignDisabled}
            onChange={assignIntervention}
            optionLabelComponent={NMAMappingOption}
            options={assignOptions}
            popoverClassName="nma-assign-option-select-popover"
            selected={
              if mappingForIntervention in assignOptionsValues
               mappingForIntervention
              else
                '_assign'
            }
          />
        </div>
      </div>
      <RadioWithLabel
        checked={mappingForIntervention is '_add'}
        label={i18n 'options.add'}
        name={interventionId}
        onChange={setInterventionMapping}
        value="_add"
      />
      <RadioWithLabel
        checked={mappingForIntervention is '_delete'}
        label={i18n 'options.skip'}
        name={interventionId}
        onChange={setInterventionMapping}
        value="_delete"
      />
    </div>}
  </React.Fragment>

NMAMappingIntervention.propTypes =
  intervention: PropTypes.instanceOf(Immutable.Map).isRequired
  mapping: PropTypes.instanceOf(Immutable.Map).isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  type: PropTypes.oneOf(['matched', 'unmatched']).isRequired

NMAMappingStep = ({ mappingStepData, outcome, question }) ->
  i18n = useI18n 'nma:import_modal'

  matchedInterventions = mappingStepData.get 'matchedInterventions', Immutable.List()
  unmatchedInterventions = mappingStepData.get 'unmatchedInterventions', Immutable.List()
  noInterventionUsed = matchedInterventions.isEmpty() and unmatchedInterventions.isEmpty()

  <React.Fragment>
    <div className="nma-import-outcome-modal__step-description">
      {i18n 'step_descriptions.mapping'}
    </div>
    <div className="nma-import-outcome-modal__mapping-step">
      {if noInterventionUsed
        <div className="nma-import-outcome-modal__no-interventions">
          {i18n 'no_interventions'}
        </div>
      else
        <div className="nma-import-outcome-modal__interventions">
          {not unmatchedInterventions.isEmpty() and
            <div className="nma-import-outcome-modal__unmatched-interventions">
              {i18n 'unmatched_interventions'}
            </div>
          }
          {unmatchedInterventions.map (intervention) ->
            <NMAMappingIntervention
              intervention={intervention}
              key={intervention.get 'id'}
              mapping={mappingStepData}
              question={question}
              type="unmatched"
            />
          }
          {not unmatchedInterventions.isEmpty() and not matchedInterventions.isEmpty() and <hr />}
          {matchedInterventions.map (intervention) ->
            <NMAMappingIntervention
              intervention={intervention}
              key={intervention.get 'id'}
              mapping={mappingStepData}
              question={question}
              type="matched"
            />
          }
        </div>
      }
    </div>
  </React.Fragment>

NMAMappingStep.propTypes =
  mappingStepData: PropTypes.instanceOf(Immutable.Map).isRequired
  outcome: PropTypes.instanceOf(Immutable.Map).isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore NMAMappingStep,
  [NMAImportModalStore, NMAQuestionStore],
  storeConnector
