ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ ExtractionForm, extractionFormShape } = require 'components/covid_extraction_form/extraction_form'
GeneralInformationActions = require 'actions/general_information_actions'
GeneralInformationStore = require 'stores/general_information_store'
mediator = require 'mediator'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'

storeConnector =
  GeneralInformationStore: (Store, props) ->
    fields = _.reduce props.form, (acc, group) ->
      acc.concat group
    , []

    docProps = _.chain fields
      .map ({ fieldKey }) -> [fieldKey, Store.getDocField(fieldKey)]
      .object()
      .value()
    doc: docProps
    fetching: Store.isFetching()
    error: Store.getError()

GeneralInformation = createReactClass
  displayName: 'GeneralInformation'
  mixins: [
    CustomRenderMixin
    Translation('settings:general_information')
  ]

  propTypes:
    doc: PropTypes.object
    fetching: PropTypes.bool.isRequired
    error: PropTypes.oneOfType([
      PropTypes.string
      PropTypes.instanceOf(Error)
    ])
    asterixedFields: PropTypes.arrayOf(PropTypes.string).isRequired
    form: extractionFormShape.isRequired

  render: ->
    { asterixedFields, fetching, error, doc, form } = @props
    <div>
      <div className="general-information-fields">
        {fetching and <Spinner />}
        {error and <div>{@i18n 'error'}</div>}
        {not fetching and not error and <ExtractionForm
          data={doc}
          form={form}
          asterixedFields={asterixedFields}
          i18n={@i18n}
          onChange={GeneralInformationActions.updateField}
        />}
      </div>
    </div>

module.exports = ConnectStore GeneralInformation, GeneralInformationStore, storeConnector
