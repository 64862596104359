var ORDoc, OverReviewService, callMethod, createSubDoc, currentProject, docInstUpdateAt, mediator, orDocId, overReviewDocPrefix, removeItemById, _ref;

mediator = require('mediator');

ORDoc = require('lib/db_docs/over_review_doc');

callMethod = require('base/lib/utils').callMethod;

overReviewDocPrefix = require('lib/doc_prefixes').overReview;

_ref = require('lib/db_docs/document_instance_helpers'), docInstUpdateAt = _ref.docInstUpdateAt, createSubDoc = _ref.createSubDoc, removeItemById = _ref.removeItemById;

orDocId = function(questionId) {
  return "" + overReviewDocPrefix + questionId;
};

currentProject = function() {
  return mediator.project.id;
};

module.exports = OverReviewService = (function() {
  function OverReviewService() {}

  OverReviewService.prototype._updateAsInstance = function(questionId, instanceUpdaterFn) {
    return ORDoc.at(currentProject(), orDocId(questionId)).updateAsInstance(instanceUpdaterFn);
  };

  OverReviewService.prototype.fetchOverReviewDoc = function(questionId) {
    return ORDoc.at(currentProject(), orDocId(questionId)).fetch();
  };

  OverReviewService.prototype.restoreOverReviewDocument = function(doc) {
    return ORDoc.at(currentProject()).save(R.dissoc('_deleted', doc));
  };

  OverReviewService.prototype.toggleModuleState = function(questionId, isEnabled) {
    return ORDoc.at(currentProject(), orDocId(questionId)).update({
      isEnabled: isEnabled
    });
  };

  OverReviewService.prototype.isModuleEnabled = function(questionId) {
    return this.fetchOverReviewDoc(questionId).then(function(doc) {
      return doc.isEnabled;
    })["catch"](function(err) {
      if (err.status === 404) {
        return false;
      }
      throw err;
    });
  };

  OverReviewService.prototype.startOverReview = function(questionId) {
    return ORDoc.at(currentProject(), orDocId(questionId)).update({
      hasStarted: true
    });
  };

  OverReviewService.prototype.deleteOverReview = function(questionId) {
    return ORDoc.at(currentProject(), orDocId(questionId))["delete"]()["catch"](function(err) {
      if (err.status === 404) {
        return null;
      }
    });
  };

  OverReviewService.prototype.restoreORDocument = function(ORDoc) {
    return ORDoc.at(projectId).save(_.omit(ORDoc, '_deleted'));
  };

  OverReviewService.prototype.mergeStudies = function(questionId, mergeStudyIds, masterStudyText) {
    return this._updateAsInstance(questionId, callMethod('mergeStudies', mergeStudyIds, masterStudyText));
  };

  OverReviewService.prototype.deleteSource = function(questionId, sourceId) {
    return this._updateAsInstance(questionId, callMethod('deleteSource', sourceId));
  };

  OverReviewService.prototype.createSource = function(questionId, sourceData, studies) {
    return this._updateAsInstance(questionId, callMethod('createSource', sourceData, studies));
  };

  OverReviewService.prototype.updateSource = function(questionId, sourceId, sourceData, studies) {
    return this._updateAsInstance(questionId, callMethod('updateSource', sourceId, sourceData, studies));
  };

  OverReviewService.prototype.createSourceOutcome = function(questionId, sourceId, outcomeData) {
    return this._updateAsInstance(questionId, callMethod('createSourceOutcome', sourceId, outcomeData));
  };

  OverReviewService.prototype.deleteSourceOutcome = function(questionId, sourceId, outcomeId) {
    return this._updateAsInstance(questionId, callMethod('deleteSourceOutcome', sourceId, outcomeId));
  };

  OverReviewService.prototype.updateSourceOutcome = function(questionId, sourceId, outcomeId, outcomeData) {
    return this._updateAsInstance(questionId, callMethod('updateSourceOutcome', sourceId, outcomeId, outcomeData));
  };

  OverReviewService.prototype.createReviewedOutcome = function(questionId, name) {
    return this._updateAsInstance(questionId, callMethod('createReviewedOutcome', name));
  };

  OverReviewService.prototype.deleteReviewedOutcome = function(questionId, reviewedOutcomeId) {
    return this._updateAsInstance(questionId, callMethod('deleteReviewedOutcome', reviewedOutcomeId));
  };

  OverReviewService.prototype.updateReviewedOutcome = function(questionId, reviewedOutcomeId, outcomeData) {
    return this._updateAsInstance(questionId, callMethod('updateReviewedOutcome', reviewedOutcomeId, outcomeData));
  };

  OverReviewService.prototype.mapSourceOutcome = function(questionId, reviewedOutcomeId, sourceId, sourceOutcomeId) {
    return this._updateAsInstance(questionId, callMethod('mapSourceOutcome', reviewedOutcomeId, sourceId, sourceOutcomeId));
  };

  OverReviewService.prototype.updateSourceReviewQuality = function(questionId, sourceId, quality) {
    return this._updateAsInstance(questionId, callMethod('updateSourceQualityOfReview', sourceId, quality));
  };

  OverReviewService.prototype.updateStudiesMatrix = function(questionId, sourceId, studyId, studyStatus) {
    return this._updateAsInstance(questionId, callMethod('updateStudiesMatrix', sourceId, studyId, studyStatus));
  };

  OverReviewService.prototype.updateSourceComment = function(questionId, sourceId, comment) {
    return this._updateAsInstance(questionId, callMethod('updateSourceComment', sourceId, comment));
  };

  return OverReviewService;

})();
