var INITIAL_STATE, QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID, QuestionGroupsActions, QuestionGroupsStore, alt, errorHandler, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

QuestionGroupsActions = require('actions/question_groups_actions');

errorHandler = require('lib/app_utils').errorHandler;

QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID = require('lib/questions_helper').QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID;

INITIAL_STATE = Immutable.fromJS({
  meta: {
    fetching: false,
    fetchingType: 'regular'
  },
  questionGroups: {}
});

QuestionGroupsStore = (function() {
  function QuestionGroupsStore() {
    this.state = INITIAL_STATE;
    this.bindActions(QuestionGroupsActions);
    this.exportPublicMethods({
      getQuestionGroups: this.getQuestionGroups,
      isFetching: this.isFetching,
      getGroupId: this.getGroupId,
      getQuestionOrderNumber: this.getQuestionOrderNumber
    });
  }

  QuestionGroupsStore.prototype.onDbChange = function(change) {
    var doc, groups, id, mapping, questionsOrdering, questionsType;
    id = change.id, doc = change.doc;
    groups = doc.groups, mapping = doc.mapping, questionsOrdering = doc.questionsOrdering;
    if (questionsOrdering == null) {
      questionsOrdering = {};
    }
    questionsType = QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID[id];
    return this.setState(this.state.setIn(['questionGroups', questionsType], Immutable.fromJS({
      groups: groups,
      mapping: mapping,
      questionsOrdering: questionsOrdering
    })));
  };

  QuestionGroupsStore.prototype.onFetch = function(type) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetching'], true);
      return state.setIn(['meta', 'fetchingType'], type);
    }));
  };

  QuestionGroupsStore.prototype.onFetchSuccess = function(data) {
    var fetchingType, groups, mapping, questionsOrdering;
    groups = data.groups, mapping = data.mapping, questionsOrdering = data.questionsOrdering;
    fetchingType = this.state.getIn(['meta', 'fetchingType']);
    if (questionsOrdering == null) {
      questionsOrdering = {};
    }
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetching'], false);
      state.setIn(['meta', 'fetchingType'], fetchingType);
      if ((groups != null) && (mapping != null)) {
        return state.setIn(['questionGroups', fetchingType], Immutable.fromJS({
          groups: groups,
          mapping: mapping,
          questionsOrdering: questionsOrdering
        }));
      }
    }));
  };

  QuestionGroupsStore.prototype.onFetchError = function(err) {
    if (err.status !== 404) {
      errorHandler(err);
    }
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetching'], false);
      return state.setIn(['meta', 'fetchingType'], null);
    }));
  };

  QuestionGroupsStore.prototype.getQuestionGroups = function(questionsType) {
    if (questionsType == null) {
      questionsType = 'regular';
    }
    return this.state.getIn(['questionGroups', questionsType], Immutable.Map({
      groups: Immutable.List(),
      mapping: Immutable.Map()
    }));
  };

  QuestionGroupsStore.prototype.isFetching = function() {
    return this.state.getIn(['meta', 'fetching']);
  };

  QuestionGroupsStore.prototype.getGroupId = function(questionsType, questionId) {
    return this.state.getIn(['questionGroups', questionsType, 'mapping', questionId], 'questionsWithoutGroup');
  };

  QuestionGroupsStore.prototype.getQuestionOrderNumber = function(questionsType, questionId) {
    return this.state.getIn(['questionGroups', questionsType, 'questionsOrdering', questionId]);
  };

  return QuestionGroupsStore;

})();

module.exports = alt.createStore(immutable(QuestionGroupsStore), 'QuestionGroupsStore');
