EtdEditor = require 'components/etd/etd_editor'
EtdActions = require 'actions/etd_actions'

SearchStrategySummary = ({
  attachments
  cellId
  content
  editable
  isFetching
  readOnly
  renderMode
  }) ->

  return <Spinner /> if isFetching

  <div>
    <EtdEditor
      attachments={attachments}
      cellId={cellId}
      content={content}
      editable={editable}
      forceTableExpand={readOnly or renderMode is 'printout'}
      isConsensus={false}
      noAutoSave={false}
      noInsertOption
      ref={null}
      renderMode={renderMode}
      withApplyCancelButtons
      onSaveContent={EtdActions.updateEditorContent}
      onSaveAttachment={EtdActions.saveAttachment}
    />
  </div>

SearchStrategySummary.propTypes =
  attachments: PropTypes.instanceOf Immutable.Map
  cellId: PropTypes.string.isRequired
  content: PropTypes.oneOfType [PropTypes.instanceOf(Immutable.Map), PropTypes.string]
  editable: PropTypes.bool
  isFetching: PropTypes.bool
  readOnly: PropTypes.bool
  renderMode: PropTypes.string

SearchStrategySummary.defaultProps =
  attachments: Immutable.Map()
  content: ""
  editable: true
  readOnly: false
  renderMode: 'regular'

module.exports = SearchStrategySummary
