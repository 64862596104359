var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.riskOfBias : depth0)) != null ? stack1.name : stack1), "None", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.not_serious", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.riskOfBias : depth0)) != null ? stack1.name : stack1), "Serious", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.serious", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.riskOfBias : depth0)) != null ? stack1.name : stack1), "VerySerious", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.very_serious", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.robinsIEnabled : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"unless","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.clearOption, '  ', 'clearOption', ((stack1 = (depth0 != null ? depth0.riskOfBias : depth0)) != null ? stack1.name : stack1), undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.riskOfBias : depth0)) != null ? stack1.name : stack1), "ExtremelySerious", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.extremely_serious", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <hr>\n    <li class=\"edit-option\" modifier=\"assess\">\n      <span class=\"compass-icon\"></span>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.assess_rob", {"name":"i18n","hash":{},"data":data})))
    + "\n    </li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.selectionList || (depth0 && depth0.selectionList) || helperMissing).call(depth0, "riskOfBias", "risk_of_bias", {"name":"selectionList","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}