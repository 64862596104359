var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "  <li>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.question : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </li>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "    <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:welcome.changelog.question", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n    <a href=\"#\" class=\"caption\">"
    + escapeExpression(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"question","hash":{},"data":data}) : helper)))
    + "</a>\n    <ol>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.edits : depth0), {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </ol>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "      <li>\n        ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:welcome.changelog.change_summary", {"name":"i18n","hash":{
    'date': ((depth0 != null ? depth0.date : depth0)),
    'link': ((depth0 != null ? depth0.link : depth0)),
    'section': ((depth0 != null ? depth0.section : depth0)),
    'user': ((depth0 != null ? depth0.user : depth0))
  },"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n      </li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<ol>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.changes : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</ol>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}