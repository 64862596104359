CustomRenderMixin = require 'components/mixins/custom_render_mixin'

Summary = createReactClass
  displayName: 'SummaryComponent'

  mixins: [CustomRenderMixin]

  _optionMapper: (option, key, arr) ->
    text = option.get('text')
    isChecked = option.get('value') is @props.selectedOption
    size = switch arr.size
      when 4 then 'big'
      when 5 then 'small'

    classes = classNames 'option', size,
      'checked': isChecked,
      'dark-cell': @props.selectedOption in ['varies', 'dont_know']

    <td className={classes} key={key}>{text}</td>

  render: ->

    { criterion, hidden } = @props

    options = criterion.get 'options'
    additionalOptions = criterion.get 'additionalOptions', Immutable.List()
    return <td className="options-container"/> if hidden

    <td className="options-container">
      <table cellPadding="0" cellSpacing="0" height="100%">
        <tbody>
          <tr>
            <td className="options">
              <table cellPadding="0" cellSpacing="0" height="100%">
                <tbody>
                  <tr>
                    {options.map(@_optionMapper).toList()}
                  </tr>
                </tbody>
              </table>
            </td>
            <td className="additional-options">
              <table cellPadding="0" cellSpacing="0" height="100%">
                <tbody>
                  <tr>
                    {unless additionalOptions.isEmpty()
                      additionalOptions.map(@_optionMapper).toList()
                    else
                      <td className="option"/>
                    }
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </td>

module.exports = Summary
