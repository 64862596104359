Checkboxes = require './checkboxes'
DatePicker = require 'components/common/date_picker'
EditorText = require './editor_text'
GuidelineTags = require 'components/common/guideline_tags'
IconButton = require 'components/common/icon_button'
immutableUtils = require 'lib/immutable_utils'
InfoIconWithTooltip = require 'components/common/info_icon_with_tooltip'
mediator = require 'mediator'
MultipleInputs = require './multiple_inputs'
PercentInput = require 'components/common/percent_input'
RadioOptions = require './radio_options'
SelectCustom = require 'components/common/select_custom'
SelectWithOther = require './select_with_other'
SwitchableText = require 'components/common/switchable_text'
Switcher = require 'components/common/switcher'
StringField = require './string_field'
TextAreaField = require './textarea_field'
TextAreaWithApply = require 'components/common/text_area_with_apply'
{ SelectWithCheckboxes } = ReactComponents

showClearIcon = (showClear, value) ->
  return false unless showClear
  if Immutable.List.isList(value)
    not value.isEmpty()
  else
    _.isBoolean(value) or _.isNumber(value) or not _.isEmpty(value)

fieldShape =
  fieldKey: PropTypes.string.isRequired
  type: PropTypes.oneOf([
    'checkboxes'
    'codes'
    'custom'
    'date'
    'editorText'
    'multiSelect'
    'multiText'
    'percent'
    'questionsSelector'
    'radioOptions'
    'select'
    'selectWithOther'
    'string'
    'switchableText'
    'switcher'
    'text'
    'textarea'
  ]).isRequired
  placeholder: PropTypes.string
  placeholderFromI18n: PropTypes.bool
  helperTextFromI18n: PropTypes.bool
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string
        PropTypes.bool
        PropTypes.number
      ]).isRequired
      text: PropTypes.string
      textKey: PropTypes.string
    })
    PropTypes.string
  ]))
  infoText: PropTypes.bool
  withOther: PropTypes.bool
  addValueLabel: PropTypes.string
  initialValue: PropTypes.any
  featureSwitch: PropTypes.string
  hide: PropTypes.oneOf([PropTypes.func, PropTypes.bool])
  readOnly: PropTypes.bool
  component: (props, propName) ->
    if props[propName] and not ReactIs.isValidElementType(props[propName])
      msg = 'Invalid prop "component" passed to extraction form:
             the prop is not a valid React component'
      new Error msg
  customWithArray: PropTypes.bool
  showClear: PropTypes.bool
  useControlledInput: PropTypes.bool

ExtractionFormField = createReactClass
  displayName: 'ExtractionFormField'

  propTypes: _.extend {}, fieldShape,
    i18n: PropTypes.func.isRequired
    onChange: PropTypes.func.isRequired
    withAsterix: PropTypes.bool.isRequired
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.Map)
      PropTypes.instanceOf(Immutable.List)
      PropTypes.arrayOf(PropTypes.string)
      PropTypes.string
      PropTypes.bool
      PropTypes.object
    ])
    renderMode: PropTypes.oneOf(['regular', 'printout'])
    additionalProps: PropTypes.object

  getDefaultProps: ->
    showClear: true
    readOnly: false
    customWithArray: false
    additionalProps: {}
    renderMode: 'regular'
    hide: false

  onDateSave: (_e, newDate) ->
    @onChange newDate.toISOString()

  onChange: (newValue) ->
    { onChange, fieldKey } = @props
    onChange fieldKey, newValue

  onClear: ->
    { customWithArray, type } = @props
    newValue = switch type
      when 'text', 'date', 'editorText', 'string', 'textarea'
        ''
      when 'multiSelect', 'codes', 'multiText', 'checkboxes'
        []
      when 'percent'
        0
      when 'switchableText'
        {}
      when 'custom'
        if customWithArray then [] else {}
      else
        null

    @onChange newValue

  toggleSwitcher: ->
    { value } = @props
    @onChange not value

  render: ->
    {
      addValueLabel
      component: Component
      featureSwitch
      hide
      fieldKey
      helperTextFromI18n
      i18n
      infoText
      initialValue
      options
      parentValue
      placeholder
      placeholderFromI18n
      additionalProps
      readOnly
      renderMode
      showClear
      type
      value
      withAsterix
      withOther
      useControlledInput
    } = @props

    hideField = if _.isFunction(hide) then hide() else hide
    return null if hideField
    return null if featureSwitch? and not mediator.services.switches.isOn(featureSwitch)

    <div className="extraction-field #{fieldKey}">
      <div className="extraction-field__name">
        {infoText and <InfoIconWithTooltip info={i18n "infoTexts.#{fieldKey}"} />}
        {i18n "fields.#{fieldKey}"}{withAsterix and "*"}
      </div>
      <div className={"extraction-field__value #{type}"}>
        <div>
          {switch type
            when 'textarea'
              placeholderToUse = if placeholderFromI18n
                i18n "placeholders.#{fieldKey}"
              else
                placeholder ? ''
              <TextAreaField
                onChange={@onChange}
                disabled={readOnly}
                placeholder={placeholderToUse}
                value={value}
              />
            when 'text'
              placeholderToUse = if placeholderFromI18n
                i18n "placeholders.#{fieldKey}"
              else
                placeholder ? ''
              textarea = <TextAreaWithApply
                readOnly={readOnly}
                onApply={@onChange}
                content={value}
                placeholder={placeholderToUse}
              />
              if helperTextFromI18n
                <div className="text-with-helper">
                  <div className="helper-text">{i18n "helperTexts.#{fieldKey}"}</div>
                  <div>{textarea}</div>
                </div>
              else
                textarea
            when 'editorText'
              <EditorText readOnly={readOnly} onSave={@onChange} value={value} />
            when 'multiText'
              placeholderToUse = if placeholderFromI18n
                i18n "placeholders.#{fieldKey}"
              else
                placeholder ? ''
              valueToPass = if not value?
                []
              else if Immutable.List.isList(value)
                value.toJS()
              else
                value

              if renderMode is 'printout'
                value?.join(', ')
              else
                <MultipleInputs
                  value={valueToPass}
                  initialValue={initialValue}
                  onApply={@onChange}
                  addValueLabel={addValueLabel}
                  placeholder={placeholderToUse}
                />
            when 'date'
              valueToPass = if (value and value isnt 'not_reported')
                moment(value).format('DD-MM-YYYY')
              else
                ''

              <div className="flex items-center">
                {fieldKey is 'latestLiteratureSearchDate' and <label className="mr-10">
                    <input
                      className="mr-5"
                      type="checkbox"
                      onChange={() =>
                        @onChange(if value is 'not_reported' then '' else 'not_reported')
                      }
                      disabled={readOnly}
                      checked={value is 'not_reported'}
                    />
                    {i18n "not_reported"}
                  </label>
                }
                {value isnt 'not_reported' and
                  <DatePicker
                    placeholder='dd-mm-yyyy'
                    value={valueToPass}
                    onSave={@onDateSave}
                  />
                }
              </div>
            when 'select'
              options = if _.isFunction(@props.filteredOptions)
                @props.filteredOptions(parentValue)
              else
                options
              <SelectCustom
                selected={value}
                disabled={readOnly}
                options={options}
                onChange={@onChange}
                optionsContainerWidthMode="fixed"
              />
            when 'selectWithOther'
              placeholderToUse = if placeholderFromI18n
                i18n "placeholders.#{fieldKey}"
              else
                placeholder ? ''
              options = if _.isFunction(@props.filteredOptions)
                @props.filteredOptions(parentValue)
              else
                options
              <SelectWithOther
                onChange={@onChange}
                options={options}
                disabled={readOnly}
                placeholder={placeholderToUse}
                value={value}
              />
            when 'multiSelect'
              selectedOptions = if _.isNull(value) or _.isUndefined(value)
                []
              else if _.isString(value)
                [value]
              else value

              if renderMode is 'printout'
                _.map(value, (v) -> i18n "values.#{fieldKey}.#{v}").join(', ')
              else
                <SelectWithCheckboxes
                  disabled={readOnly}
                  selectedOptions={selectedOptions}
                  options={options}
                  onChange={@onChange}
                  name={fieldKey}
                  withSelectAll={false}
                />
            when 'radioOptions'
              placeholderToUse = if placeholderFromI18n
                i18n "placeholders.#{fieldKey}"
              else
                placeholder ? ''
              <RadioOptions
                i18n={i18n}
                options={options}
                value={value}
                fieldKey={fieldKey}
                placeholder={placeholderToUse}
                helperTextFromI18n={helperTextFromI18n}
                withOther={withOther}
                onChange={@onChange}
              />
            when 'percent'
              placeholderToUse = if placeholderFromI18n
                i18n "placeholders.#{fieldKey}"
              else
                placeholder ? ''
              <PercentInput
                i18n={i18n}
                value={value}
                fieldKey={fieldKey}
                placeholder={placeholderToUse}
                onChange={@onChange}
              />
            when 'switchableText'
              placeholderToUse = if placeholderFromI18n
                i18n "placeholders.#{fieldKey}"
              else
                placeholder ? ''
              <SwitchableText
                i18n={i18n}
                options={options}
                fieldKey={fieldKey}
                value={value}
                placeholder={placeholderToUse}
                onChange={@onChange}
              />
            when 'codes'
              codeGroup = switch fieldKey
                when 'guidelineInterventions' then 'intervention'
                when 'guidelinePopulations' then 'population'
              <div className='field' key={codeGroup}>
                <div className='field__label'>
                  {i18n "#{codeGroup}_code"}
                </div>
                <div className='field__text'>
                  <GuidelineTags
                    i18n={i18n}
                    fieldKey={fieldKey}
                    codeGroup={codeGroup}
                    onChange={@onChange}
                    tags={value}
                  />
                </div>
              </div>
            when 'switcher'
              <Switcher
                checked={value}
                onChange={@toggleSwitcher}
              />
            when 'string'
              <StringField
                onChange={@onChange}
                step={additionalProps?.step}
                type={additionalProps?.type ? 'text'}
                useControlledInput={useControlledInput}
                value={value}
              />
            when 'custom'
              if Component
                valueToPass = if immutableUtils.isImmutable value
                  value.toJS()
                else
                  value
                <Component
                  field={fieldKey}
                  onChange={@onChange}
                  parentValue={parentValue}
                  value={valueToPass}
                  additionalProps={additionalProps}
                />
              else
                null
            when 'checkboxes'
              selectedOptions = if _.isNull(value) or _.isUndefined(value)
                []
              else if _.isString(value)
                [value]
              else
                value

              <Checkboxes
                i18n={(optionKey) -> i18n "#{fieldKey}.#{optionKey}"}
                onChange={(selectedOptions) => @onChange selectedOptions}
                options={options}
                readOnly={readOnly}
                selectedOptions={selectedOptions}
              />
            else
              null
          }
        </div>
        {renderMode isnt 'printout' and showClearIcon(showClear, value) and <div className="clear-btn">
          <IconButton
            iconName="clear"
            onClick={@onClear}
          />
        </div>}
      </div>
    </div>

module.exports = { ExtractionFormField, fieldShape }
