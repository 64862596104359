CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

EtdSectionTitle = createReactClass
  displayName: 'EtdSectionTitle'

  propTypes:
    renderMode: PropTypes.string.isRequired
    title: PropTypes.string.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  render: ->
    if @props.renderMode is 'printout'
      <h1 className="section-header">{@props.title}</h1>
    else
      <div className={classNames "etd-section-title", @props.className}>
        <h1 className="section-header">{@props.title}</h1>
        {@props.children}
      </div>

module.exports = EtdSectionTitle
