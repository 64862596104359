MarketingScreen = require 'components/common/marketing_screen'
Translation = require 'components/mixins/translation'
{ license_url } = require 'lib/constants'

PanelVoiceMarketingScreen = createReactClass
  displayName: 'PanelVoiceMarketingScreen'

  mixins: [Translation('')]

  render: ->
    <MarketingScreen
      title={@i18n 'marketing.panel_voice.title'}
      descriptions={@i18n 'marketing.panel_voice.description', returnObjects: true}
      licenseInfoTitle={@i18n 'marketing.panel_voice.license_info_title'}
      licenseInfoDesc={@i18n 'marketing.license_info_desc', link: license_url}
      infoLink="https://gradepro.org/panel-voice/"
      readAlsoLink="https://evidenceprime.com/2017/10/20/panelvoice-examples-use/"
      imgName="panel.png"
    />

module.exports = PanelVoiceMarketingScreen
