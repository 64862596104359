var FixedSizeQueue;

module.exports = FixedSizeQueue = (function() {
  function FixedSizeQueue(maxSize) {
    this.maxSize = maxSize;
    this.data = [];
  }

  FixedSizeQueue.prototype.push = function(value) {
    this.data.push(value);
    if (this.data.length > this.maxSize) {
      this.data.shift();
    }
    return this;
  };

  FixedSizeQueue.prototype.size = function() {
    return this.data.length;
  };

  FixedSizeQueue.prototype.asArray = function() {
    return this.data;
  };

  return FixedSizeQueue;

})();
