var ImportExportView, ModalView,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

module.exports = ImportExportView = (function(_super) {
  __extends(ImportExportView, _super);

  function ImportExportView() {
    return ImportExportView.__super__.constructor.apply(this, arguments);
  }

  ImportExportView.prototype.markSelected = function() {
    return this.$('input[type="checkbox"]').each(function(index, item) {
      var $item;
      $item = $(item);
      if ($item.prop('checked')) {
        return $item.closest('li').addClass('selected');
      }
    });
  };

  ImportExportView.prototype.toggleSelected = function(e) {
    var $target, $targetContainer;
    $target = this.$(e.target);
    $targetContainer = $target.closest('li');
    if ($target.prop('checked')) {
      return $targetContainer.addClass('selected');
    } else {
      return $targetContainer.removeClass('selected');
    }
  };

  return ImportExportView;

})(ModalView);
