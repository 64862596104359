Tooltip = require 'components/common/tooltip'
Popover = require 'components/common/popover'

pointSize = 4
labelRectSize = 6 * pointSize
cellPadding = 2 * pointSize

labelCellStyle = (valueToColorMapping, value) ->
  background: valueToColorMapping?[value]
  borderRadius: '2px'
  width: labelRectSize
  height: labelRectSize
  margin: cellPadding
  textAlign: 'center'

checkboxStyle =
  width: labelRectSize
  height: labelRectSize
  margin: cellPadding

MatrixRow = createReactClass
  displayName: 'MatrixRow'

  getInitialState: ->
    showColumnComponentFor: null

  toggleColumnComponentVisibility: (columnId) -> =>
    @setState showColumnComponentFor: if @state.showColumnComponentFor is columnId
      null
    else
      columnId

  render: ->
    {row, rowLabels, valueToColorMapping, rowNr} = @props
    rowLabel = rowLabels[row.rowId].label

    <tr key={row.rowId}>
      <td style={{verticalAlign: 'middle'}} className="status-matrix__row-label">
        <Tooltip>
          <div title={rowLabels[row.rowId].tooltip}>
            {if _.isString rowLabel
              "#{if rowNr then "#{rowNr}. " else ''}#{rowLabel}"
            else
              rowLabel
            }
          </div>
        </Tooltip>
      </td>
      {_(row.columns).map (column) =>
        { columnId, component: ColumnComponent, value: columnValue, className } = column
        <td key={columnId} className="status-matrix__label-cell">
          {if ColumnComponent
            toggleCb = @toggleColumnComponentVisibility columnId
            <Popover
              positionParams={position: 'cover', alignment: 'center'}
              onRequestClose={toggleCb}
              visible={@state.showColumnComponentFor is columnId}
            >
              <div
                onClick={toggleCb}
                style={labelCellStyle(valueToColorMapping, columnValue)}
                className={className ? ''}
              />
              {React.cloneElement ColumnComponent, onRequestClose: toggleCb}
            </Popover>
          else
            <div
              style={labelCellStyle(valueToColorMapping, columnValue)}
              className={className ? ''}
            />
          }
        </td>
      }
      <td style={width: 'auto'} />
    </tr>

EmptyRow = ({ columnLabels }) ->
  <tr className="status-matrix__empty-group-row">
    <td colSpan={columnLabels.length + 1} />
    <td style={width: 'auto'} />
  </tr>

rowsPropTypes = PropTypes.arrayOf(PropTypes.shape(
    rowId: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape(
      columnId: PropTypes.string.isRequired,
      value: PropTypes.string,
      className: PropTypes.string
    )).isRequired
  )).isRequired

StatusMatrix = createReactClass

  displayName: 'StatusMatrix'

  propTypes:
    grouppedRows: PropTypes.arrayOf(PropTypes.shape(
      caption: PropTypes.string.isRequired,
      rows: rowsPropTypes
    )).isRequired
    rowLabels: PropTypes.objectOf(PropTypes.shape(
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      tooltip: PropTypes.string
    )).isRequired
    columnLabels: PropTypes.arrayOf(PropTypes.shape(
      label: PropTypes.string
      tooltip: PropTypes.string
    )).isRequired
    # TODO - clickable when implementing overrview
    type: PropTypes.oneOf(['column-check', 'clickable']).isRequired
    valueToColorMapping: PropTypes.objectOf(PropTypes.string)
    # if we display checkboxes in the last row (like in panel voice feedback)
    # then we should provide this
    checkedColumnsMap: PropTypes.objectOf(PropTypes.bool)
    toggleCheckbox: PropTypes.func
    withRowNumbers: PropTypes.bool

  getDefaultProps: ->
    withRowNumbers: true

  render: ->
    rowIdx = 0
    {
      grouppedRows,
      rowLabels,
      columnLabels,
      type,
      valueToColorMapping,
      checkedColumnsMap,
      toggleCheckbox,
      withRowNumbers
    } = @props

    <div className="status-matrix">
      <table>
        <tbody>
          <tr>
            <td style={{width: pointSize * 20}}/>
            {_(columnLabels).map (colLab) ->
              <td key={colLab.label} style={{textAlign: 'center', width: labelRectSize}}>
                <Tooltip>
                  <div title={colLab.tooltip}>
                    {colLab.label}
                  </div>
                </Tooltip>
              </td>
            }
            <td style={width: 'auto'} />
          </tr>
        </tbody>
        {_(grouppedRows).map ({ caption, rows }) ->
          <React.Fragment key={caption}>
            <tbody className="status-matrix__group">
              {unless _.isEmpty caption
                <tr>
                  <td
                    colSpan={columnLabels.length + 1}
                    className="status-matrix__group-caption"
                  >
                    {caption}
                  </td>
                  <td style={width: 'auto'} />
                </tr>
              }
              {rows.map (row) ->
                <MatrixRow
                  key={row.rowId}
                  row={row}
                  rowLabels={rowLabels}
                  valueToColorMapping={valueToColorMapping}
                  rowNr={withRowNumbers and ++rowIdx}
                />
              }
              <EmptyRow columnLabels={columnLabels} />
            </tbody>
            <tbody>
              <EmptyRow columnLabels={columnLabels} />
            </tbody>
          </React.Fragment>
        }
        <tbody>
          <EmptyRow columnLabels={columnLabels} />
        </tbody>
        <tbody>
          {switch type
            when 'column-check'
              <tr className="status-matrix__checkboxes-row">
                <td />
                {_(checkedColumnsMap).map (checked, columnId) ->
                  <td
                    key={columnId}
                    className="status-matrix__checkbox-cell"
                  >
                    <input
                      type="checkbox"
                      style={checkboxStyle}
                      value={columnId}
                      checked={checked}
                      onChange={toggleCheckbox}
                    />
                  </td>
                }
                <td style={width: 'auto'} />
              </tr>
          }
        </tbody>
      </table>
    </div>

module.exports = StatusMatrix
