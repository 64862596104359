var DbHelper, EtdStore, PresentationEmbeddableLinkDialog, PresentationsExportView, QuestionExportView, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DbHelper = require('base/lib/db_helper');

EtdStore = require('stores/etd_store');

QuestionExportView = require('views/question_export_view');

PresentationEmbeddableLinkDialog = require('views/isof_embeddable_link_dialog');

template = require('views/templates/presentations_export');

mediator = require('mediator');

PresentationsExportView = (function(_super) {
  __extends(PresentationsExportView, _super);

  function PresentationsExportView() {
    return PresentationsExportView.__super__.constructor.apply(this, arguments);
  }

  PresentationsExportView.prototype.id = 'presentations-export-dialog';

  PresentationsExportView.prototype.template = template;

  PresentationsExportView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  PresentationsExportView.prototype.initialize = function() {
    PresentationsExportView.__super__.initialize.apply(this, arguments);
    this.delegate('change', 'input[name="exportType"]', this.toggleDownloadButton);
    this.delegate('change', 'input[name="presentationFormat"]', this.toggleDownloadButton);
    return this.delegate('click', 'button.preview', this.handleFormatPreview);
  };

  PresentationsExportView.prototype.getTemplateData = function() {
    var premiumPresentations, presentationType;
    presentationType = EtdStore.getPresentationType();
    premiumPresentations = mediator.services.switches.isOn('premium-presentations');
    return _(PresentationsExportView.__super__.getTemplateData.apply(this, arguments)).extend({
      presentationType: presentationType,
      premiumPresentations: premiumPresentations,
      title: $.t('projects:export_dialog.export_table_title', {
        viewName: $.t("es:outcome_view_type.presentation_for_" + presentationType)
      })
    });
  };

  PresentationsExportView.prototype._getCheckedExportFormat = function() {
    return this.$('input[name="exportType"]:checked').val();
  };

  PresentationsExportView.prototype._getCheckedPresentationFormat = function() {
    return this.$('input[name="presentationFormat"]:checked').val();
  };

  PresentationsExportView.prototype.toggleDownloadButton = function() {
    var disabled, outputFormatSelected, presentationFormatSelected, presentationType;
    presentationType = EtdStore.getPresentationType();
    presentationFormatSelected = presentationType === 'patients' || presentationType === 'premium-patients' ? this._getCheckedPresentationFormat() != null : true;
    outputFormatSelected = this._getCheckedExportFormat() != null;
    disabled = !(outputFormatSelected && presentationFormatSelected);
    return this.$('button.download').prop('disabled', disabled);
  };

  PresentationsExportView.prototype.handleFormatPreview = function(evt) {
    var format, win;
    format = evt.target.getAttribute('data-format');
    if (format === 'premium') {
      win = window.open(DbHelper.getStaticAssetsUrl('/presentations/bedaquiline-01.jpg'));
      win.opener = null;
      return win.focus();
    }
  };

  PresentationsExportView.prototype.download = function() {
    var presentationType;
    this.$('button.download').prop('disabled', true).addClass('loading').forceRedraw();
    presentationType = this._getCheckedPresentationFormat() || EtdStore.getPresentationType();
    switch (this._getCheckedExportFormat()) {
      case 'pdf':
        return mediator.services.presentationService.saveToPDF(this.model, presentationType).ensure((function(_this) {
          return function() {
            _this.$('button.download').removeClass('loading');
            return _this.dispose();
          };
        })(this));
      case 'externalLink':
        if (!presentationType) {
          return;
        }
        return mediator.services.presentationService.exportEtdPresentation(this.model, presentationType).then(this.dispose).ensure((function(_this) {
          return function() {
            return _this.$('button.download').removeClass('loading');
          };
        })(this));
      case 'externalEmbeddable':
        if (!presentationType) {
          return;
        }
        return mediator.services.presentationService.getEmbeddablePresentationLink(this.model, presentationType).then((function(_this) {
          return function(embeddableLink) {
            var embeddableLinkView;
            embeddableLinkView = new PresentationEmbeddableLinkDialog({
              link: embeddableLink,
              closeBtn: true
            });
            _this.subview('PresentationEmbeddableLinkDialog', embeddableLinkView);
            return embeddableLinkView.promise().then(_this.dispose);
          };
        })(this)).ensure((function(_this) {
          return function() {
            return _this.$('button.download').removeClass('loading');
          };
        })(this));
    }
  };

  return PresentationsExportView;

})(QuestionExportView);

module.exports = PresentationsExportView;
