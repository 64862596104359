var Migration, QUESTION_GENERATION_DOC_ID, QuestionsGenerationDataConverter, getConvertedData, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

mediator = require('mediator');

getConvertedData = require('lib/questions_generation_data_converter').getConvertedData;

QUESTION_GENERATION_DOC_ID = require('lib/questions_helper').QUESTION_GENERATION_DOC_ID;

module.exports = QuestionsGenerationDataConverter = (function(_super) {
  __extends(QuestionsGenerationDataConverter, _super);

  function QuestionsGenerationDataConverter() {
    QuestionsGenerationDataConverter.__super__.constructor.call(this, false);
  }

  QuestionsGenerationDataConverter.prototype.up = function(project, colls) {
    var currentStep, draftQuestions, storePersistenceAdapter;
    QuestionsGenerationDataConverter.__super__.up.apply(this, arguments);
    storePersistenceAdapter = mediator.services.storePersistenceAdapter;
    currentStep = project.get('questionScopeWorkflowStep');
    draftQuestions = project.get('draftQuestions');
    if (!((currentStep != null) || (draftQuestions != null))) {
      return W.resolve();
    }
    return getConvertedData(currentStep, draftQuestions, colls.teamMembers).then(function(convertedData) {
      var doc;
      doc = _.extend({
        _id: QUESTION_GENERATION_DOC_ID
      }, convertedData);
      return storePersistenceAdapter.save(project.id, doc);
    });
  };

  return QuestionsGenerationDataConverter;

})(Migration);
