var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <div class=\"choose-etd-view-title\">\n    <span class=\"title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_etd_view", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n    <span class=\"judgment\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "with_judgment", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n    <span class=\"results ";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.votingStarted : depth0), {"name":"unless","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "with_results", {"name":"i18n","hash":{},"data":data})))
    + "\n    </span>\n  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "disabled";
  },"4":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (data && data.key), "conclusion", {"name":"is","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.panelVoiceExportOption, '      ', 'panelVoiceExportOption', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:export_dialog", {"name":"i18nNamespace","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "          <div class=\"choose-etd-view-title\">\n            <span class=\"title\" />\n            <span class=\"judgment\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "with_content", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n            <span class=\"results ";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.votingStarted : depth0), {"name":"unless","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "with_results", {"name":"i18n","hash":{},"data":data})))
    + "\n            </span>\n          </div>\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", blockHelperMissing=helpers.blockHelperMissing, buffer = "<div class=\"choose-format\">\n  <label class=\"choose-format-title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_export_format", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n  <div class=\"formats-list\">\n    <label>\n      <input type=\"radio\" name=\"formatType\" value=\"doc\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.doc", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n    <label>\n      <input type=\"radio\" name=\"formatType\" value=\"html\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.html", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n    <label>\n";
  stack1 = ((helper = (helper = helpers.isConnected || (depth0 != null ? depth0.isConnected : depth0)) != null ? helper : helperMissing),(options={"name":"isConnected","hash":{},"fn":this.program(9, data),"inverse":this.program(11, data),"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.isConnected) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </label>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isAdolopment : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </div>\n</div>\n<div class=\"choose-orientation\">\n  <label class=\"choose-orientation-title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_page_orientation", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n  <div class=\"orientations-list\">\n    <label>\n      <input type=\"radio\" name=\"orientation\" value=\"landscape\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "orientations.landscape", {"name":"i18n","hash":{},"data":data})))
    + "\n      <span class=\"icon-orientation-landscape\"></span>\n    </label>\n    <label>\n      <input type=\"radio\" name=\"orientation\" value=\"portrait\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "orientations.portrait", {"name":"i18n","hash":{},"data":data})))
    + "\n      <span class=\"icon-orientation-portrait\"></span>\n    </label>\n  </div>\n</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.appendices : depth0), {"name":"if","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<div class=\"buttons\">\n  <div class=\"row mt-10\">\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-cancel cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n    <div class=\"col-6\">\n      <button class=\"btn btn-block btn-export download finish\" disabled>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "button_download", {"name":"i18n","hash":{},"data":data})))
    + "\n      </button>\n    </div>\n  </div>\n</div>\n";
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"formatType\" value=\"pdf\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.pdf", {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"11":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <input type=\"radio\" name=\"formatType\" value=\"pdf\" disabled>\n        <span class=\"label-disabled-disconnected\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.pdf", {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n        <span class=\"question-mark-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "types.pdf_offline", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n";
},"13":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <label>\n        <input type=\"checkbox\" name=\"showResultingDataOnly\" value=\"showResultingDataOnly\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "adolopment_only", {"name":"i18n","hash":{},"data":data})))
    + "\n      </label>\n";
},"15":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <label class=\"choose-appendices-title\">\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "export_as_appendix", {"name":"i18n","hash":{},"data":data})))
    + "\n  </label>\n  <div class=\"appendices-to-export\">\n    <ul>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.appendices : depth0), {"name":"each","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </ul>\n  </div>\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <li>\n          <input name=\"exportAppendix\" type=\"checkbox\" id=\""
    + escapeExpression(((helper = (helper = helpers.appendixId || (depth0 != null ? depth0.appendixId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"appendixId","hash":{},"data":data}) : helper)))
    + "\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:recommendations.insert_modal", {"name":"i18nNamespace","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </li>\n";
},"17":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "          <label for=\""
    + escapeExpression(((helper = (helper = helpers.appendixId || (depth0 != null ? depth0.appendixId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"appendixId","hash":{},"data":data}) : helper)))
    + "\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isImage : depth0), {"name":"if","hash":{},"fn":this.program(18, data),"inverse":this.program(20, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          </label>\n";
},"18":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + " <b>("
    + escapeExpression(((helper = (helper = helpers.section || (depth0 != null ? depth0.section : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"section","hash":{},"data":data}) : helper)))
    + ")</b>\n";
},"20":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, (depth0 != null ? depth0.appendixId : depth0), {"name":"i18n","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<h2 class=\"title\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n<div class=\"choose-sections\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:export_dialog", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"view-options\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.etdViewOptions : depth0), {"name":"each","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </div>\n</div>\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:export_dialog", {"name":"i18nNamespace","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}