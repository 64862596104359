mediator = require 'mediator'
Translation = require 'components/mixins/translation'
ProjectReplicationSwitch = require 'components/projects/project_replication_switch'
{ formatFileSize } = require 'base/lib/utils'

{ Switcher } = ReactComponents

ProjectReplication = createReactClass
  displayName: 'ProjectReplication'

  mixins: [
    Translation('projects:replication')
  ]

  propTypes:
    projectId: PropTypes.string.isRequired
    collectionType: PropTypes.string.isRequired
    projectStatus: PropTypes.string.isRequired
    updateSeq: PropTypes.number
    lastSeq: PropTypes.number
    dataSize: PropTypes.number

  dataSizeString: ->
    if @props.dataSize
      formatFileSize @props.dataSize
    else
      "#{@i18n 'waiting'}..."

  downloadProgress: ->
    sizeString = formatFileSize @props.dataSize
    sizeNumber = parseFloat(sizeString)
    downloaded = Math.round((@props.lastSeq / @props.updateSeq) * sizeNumber * 100) / 100
    <span>
      {unless downloaded is sizeNumber
        <span>
          <span className="replication-progress__progress--downloaded">
            {downloaded}
          </span>
          {" / "}
        </span>
      }
      {sizeString}
    </span>

  render: ->
    { projectStatus, projectId, lastSeq, updateSeq, dataSize } = @props

    if mediator.services.replication.isConnected()
      <div>
        <span className="replication-progress">
          {if projectStatus is 'replication_off'
            <span>{@dataSizeString()}</span>
          else if updateSeq and dataSize
            <span>{@downloadProgress()}</span>
          else
            <span>{@i18n 'waiting'}&hellip;</span>
          }
        </span>
      </div>
    else
      null

module.exports = ProjectReplication
