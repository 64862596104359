Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'

MedianRatingValue = createReactClass
  displayName: 'MedianRatingValue'

  propTypes:
    medianRating: PropTypes.number.isRequired

  mixins: [Translation('')]

  render: ->
    if @props.medianRating is -1
      <Tooltip>
        <span title={@i18n 'scope:all_chose_dont_know'}>-</span>
      </Tooltip>
    else
      <span>{@props.medianRating}</span>

module.exports = MedianRatingValue
