var DataFromService, DisseminationToolbar, MobilePreviewView, MobilePublicationContactForm, MobileScreen, MobileView, ReactComponent, ReactToolbarView, View, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DataFromService = require('base/lib/traits/data_from_service');

DisseminationToolbar = require('components/dissemination/dissemination_toolbar');

mediator = require('mediator');

MobilePreviewView = require('views/dissemination/mobile_preview_view');

MobilePublicationContactForm = require('views/dissemination/mobile_publication_contact_form_view');

MobileScreen = require('components/dissemination/mobile_screen');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = MobileView = (function(_super) {
  __extends(MobileView, _super);

  function MobileView() {
    this.onPreview = __bind(this.onPreview, this);
    this.onPublish = __bind(this.onPublish, this);
    return MobileView.__super__.constructor.apply(this, arguments);
  }

  MobileView.prototype.container = '#page-container';

  MobileView.prototype.className = 'mobile-view';

  MobileView.prototype.autoRender = true;

  MobileView.prototype._stopInputsPropagation = false;

  MobileView.prototype.status = null;

  MobileView.prototype._getProps = function() {
    return {
      onPreview: this.onPreview,
      onPublish: this.onPublish,
      status: this.status
    };
  };

  MobileView.prototype.initialize = function() {
    MobileView.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    this.on('loadingCompleted', (function(_this) {
      return function(_arg) {
        _this.status = _arg[0];
        return _this.updateRenderedComponent(_this._getProps());
      };
    })(this));
    this.enable(DataFromService, {
      dataContainer: '.publication-channels',
      serviceMethods: [_.partial(this.options.service.checkStatus, this.model, 'mobile')]
    });
    return this.subview('toolbar', new ReactToolbarView({
      component: DisseminationToolbar,
      props: {
        submodule: this.options.submodule
      }
    }));
  };

  MobileView.prototype.onPublish = function() {
    var form;
    this.subview('contactForm', form = new MobilePublicationContactForm());
    return form.promise().then((function(_this) {
      return function(contactInfo) {
        return _this.options.service.publish(_this.model, 'mobile', contactInfo);
      };
    })(this)).then((function(_this) {
      return function() {
        _this.trigger('!reloadData');
        return mediator.dialogs.warning($.t('dissemination:dialogs.mobile_in_progress'));
      };
    })(this), function(reason) {
      if (reason !== 'cancelled') {
        return mediator.dialogs.error(reason);
      }
    });
  };

  MobileView.prototype.onPreview = function() {
    return this.subview('previewForm', new MobilePreviewView());
  };

  MobileView.prototype.afterRender = function() {
    MobileView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(MobileScreen, this._getProps());
  };

  MobileView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    return MobileView.__super__.dispose.apply(this, arguments);
  };

  return MobileView;

})(View);
