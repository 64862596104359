var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<!doctype html>\n<html lang=\"en\">\n<head>\n  <meta charset=\"utf-8\">\n  <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge,chrome=1\">\n  <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n  <style type=\"text/css\">\n    body {\n      -webkit-print-color-adjust:exact;\n      font-stretch: condensed;\n      color: black;\n      padding:5px;\n      margin:0;\n      font: normal 8pt Arial, 'Arial Narrow', sans-serif;\n    }\n    table {\n      width: 100%;\n      border: 1px solid black;\n      border-collapse: collapse;\n    }\n\n    td, th {\n      border: 1px solid black;\n    }\n    div {\n      margin-bottom: 20px;\n    }\n\n    .caption {\n      text-align: center;\n    }\n  </style>\n<body>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.questionGroups : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</body>\n</html>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <div>\n      <table>\n        <tbody>\n          <tr>\n            <td class=\"caption\">"
    + escapeExpression(((helper = (helper = helpers.groupType || (depth0 != null ? depth0.groupType : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"groupType","hash":{},"data":data}) : helper)))
    + " ("
    + escapeExpression(((helper = (helper = helpers.questionsCount || (depth0 != null ? depth0.questionsCount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"questionsCount","hash":{},"data":data}) : helper)))
    + ")</td>\n          </tr>\n        </tbody>\n        <tbody>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.questions : depth0), {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </tbody>\n      </table>\n    </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "            <tr>\n              <td>"
    + escapeExpression(lambda(depth0, depth0))
    + "</td>\n            </tr>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "scope:questions.approval", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}