Translation = require 'components/mixins/translation'
EllipsizedText = require 'components/common/ellipsized_text'
IconButton = require 'components/common/icon_button'
Input = require 'components/common/input'
ContentEditor = require 'components/decision_aid/content_editor'
AnimatedCollectionItem = require 'components/enhancers/animated_collection_item'
SubchapterIllustrationModal =
  require 'components/decision_aid/subchapter_illustration_insert_modal'
SubchapterIllustration = require 'components/decision_aid/subchapter_illustration'
immUtils = require 'lib/immutable_utils'
{ TransitionGroup } = ReactTransitionGroup


emptyColumn = (orderNum) ->
  Immutable.Map
    header: $.t 'da:table_headers.click_here_to_add'
    content: null
    orderNum: orderNum

_TableColumn = ({ children }) ->
  <div className='subchapter-table__column'>{children}</div>

TableColumn = AnimatedCollectionItem _TableColumn,
  animationsDuration: 300
  skipAppearAnimation: true

SubchapterTable = createReactClass
  displayName: 'SubchapterTable'

  mixins: [Translation('da:details-screen')]

  getInitialState: ->
    editingHeader: null # { columnIdx, text }
    showColumnIllustrationModalFor: null

  propTypes:
    columns: PropTypes.instanceOf(Immutable.List)
    onSave: PropTypes.func.isRequired

  columnEditorRef: (columnIdx) -> (el) =>
    @["columnContentEditor#{columnIdx}"] = el

  getDefaultProps: ->
    columns: Immutable.List [emptyColumn(0), emptyColumn(1)]

  handleColumnAdd: ->
    maxExistingOrderNum = Math.max.apply null, immUtils.pluck(@props.columns, 'orderNum').toArray()
    @props.onSave @props.columns.push emptyColumn maxExistingOrderNum + 1

  handleHeaderEdit: (columnIdx) -> =>
    @setState editingHeader:
      columnIdx: columnIdx
      text: @props.columns.getIn [columnIdx, 'header']

  handleEditingHeaderChange: (evt) ->
    @setState
      editingHeader: R.assoc 'text', evt.target.value, @state.editingHeader

  stopHeaderEdit: ->
    @setState editingHeader: null

  handleHeaderEditSave: ->
    { columnIdx, text } = @state.editingHeader
    @props.onSave @props.columns.setIn [columnIdx, 'header'], text
    @stopHeaderEdit()

  handleColumnDelete: (columnIdx) -> =>
    @props.onSave @props.columns.delete columnIdx

  handleColumnContentSave: (columnIdx) -> =>
    @props.onSave @props.columns.setIn [columnIdx, 'content'],
      @["columnContentEditor#{columnIdx}"].getContentJSON()

  showIllustrationModal: (columnOrderNum) -> =>
    @setState showColumnIllustrationModalFor: columnOrderNum

  hideIllustrationModal: ->
    @setState showColumnIllustrationModalFor: null

  handleIllustrationApply: ({ illustration }) ->
    { showColumnIllustrationModalFor } = @state
    columnIdx = @props.columns.findIndex (col) ->
      showColumnIllustrationModalFor is  col.get 'orderNum'

    @props.onSave @props.columns.setIn [columnIdx, 'illustration', 'illustration'], illustration
    @hideIllustrationModal()

  handleIllustrationDelete: (columnOrderNum) -> =>
    columnIdx = @props.columns.findIndex (col) -> columnOrderNum is  col.get 'orderNum'
    @props.onSave @props.columns.deleteIn [columnIdx, 'illustration']

  render: ->
    deletableColumns = @props.columns.size > 2
    canAddColumns = @props.columns.size < 4
    { editingHeader } = @state

    <TransitionGroup component='div' className='subchapter-table'>
      {@props.columns.map (column, idx) =>
        orderNum = column.get 'orderNum'
        illustration = column.get 'illustration'

        <TableColumn key={orderNum}>
          <div className='header'>
            {if editingHeader?.columnIdx is idx
              <Input
                autoFocus
                focusSupport
                onChange={@handleEditingHeaderChange}
                onRequestSave={@handleHeaderEditSave}
                onRequestCancel={@stopHeaderEdit}
                type='text'
                value={editingHeader.text}
              />
            else
              <div className='header-text' onClick={@handleHeaderEdit idx}>
                <EllipsizedText height={70} text={column.get 'header'} />
              </div>
            }
            {unless editingHeader?
              <div className='column-controls'>
                <IconButton iconName='edit' onClick={@handleHeaderEdit idx} inlined={false} />
                {if deletableColumns
                  <IconButton iconName='delete' onClick={@handleColumnDelete idx} inlined={false} />
                }
              </div>
            }
          </div>
          <div className='content'>
            <ContentEditor
              editorContent={column.get 'content'}
              onBlur={@handleColumnContentSave idx}
              ref={@columnEditorRef idx}
            />
          </div>
          <div className='column-illustration'>
            {if illustration?
              <SubchapterIllustration
                data={illustration}
                onEdit={@showIllustrationModal orderNum}
                onDelete={@handleIllustrationDelete orderNum}
              />
            else
              <IconButton
                iconName='add'
                label={@i18n 'add_illustration'}
                labelPosition='right'
                onClick={@showIllustrationModal orderNum}
              />
            }
            <SubchapterIllustrationModal
              isOpen={@state.showColumnIllustrationModalFor is orderNum}
              data={column.get 'illustration'}
              withCustomPosition={false}
              onApply={@handleIllustrationApply}
              onClose={@hideIllustrationModal}
            />
          </div>
        </TableColumn>
      }
      {if canAddColumns
        <IconButton iconName='add' onClick={@handleColumnAdd} />
      }
    </TransitionGroup>


module.exports = SubchapterTable
