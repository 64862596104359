var GdtRouter, ModuleController, PrognosisController, QuestionWithOutcomesView, QuestionsView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

GdtRouter = require('router');

QuestionsView = require('views/evidence_syntheses/questions_view');

QuestionWithOutcomesView = require('views/evidence_syntheses/question_with_outcomes_view');

mediator = require('mediator');

module.exports = PrognosisController = (function(_super) {
  __extends(PrognosisController, _super);

  function PrognosisController() {
    return PrognosisController.__super__.constructor.apply(this, arguments);
  }

  PrognosisController.prototype.defaultSubmodule = 'prognosis-outcomes';

  PrognosisController.prototype.viewsForSubmodules = {
    'prognosis-outcomes': QuestionWithOutcomesView
  };

  PrognosisController.prototype.index = function() {
    return this.view = new QuestionsView({
      moduleName: this.moduleName,
      prognosticQuestions: true,
      collection: mediator.prognosticQuestions,
      groupsPromise: mediator.project.getDocFor('prognostic_question_groups')
    });
  };

  PrognosisController.prototype.question = function(params) {
    if (mediator.prognosticQuestions.get(params.id)) {
      return this.redirectTo(GdtRouter.prototype.routeForModule(this.moduleName, params) + ("/" + params.id + "/" + this.defaultSubmodule));
    } else {
      return this.redirectTo(GdtRouter.prototype.routeForModule(this.moduleName, params));
    }
  };

  PrognosisController.prototype._viewParams = function(params) {
    var question;
    question = mediator.prognosticQuestions.get(params.id);
    return {
      model: question
    };
  };

  return PrognosisController;

})(ModuleController);
