var Code, Footnotes, NameWithCodes, Outcome, QuestionModel, array, arrayOf, boolean, calculated, convertToList, docShape, docTypes, documentId, extendedParse, initial, mapOf, number, numberDefaultForFailedParse, object, oneOf, optional, optionalNumber, optionalString, questionFieldCalculate, questionShape, questionShortFieldCalculate, shape, string, tableTitleCalculate, utils, _ref, _ref1, _ref2;

utils = require('base/lib/utils');

Footnotes = require('models/evidence_syntheses/footnotes');

documentId = require('lib/db_docs/field_types/document_id');

docTypes = require('lib/db_docs/doc_types');

shape = require('lib/db_docs/field_types/shape');

docShape = require('lib/db_docs/field_types/doc_shape');

oneOf = require('lib/db_docs/field_types/one_of');

arrayOf = require('lib/db_docs/field_types/array_of');

mapOf = require('lib/db_docs/field_types/map_of');

_ref = require('lib/db_docs/field_types/built_in_types'), string = _ref.string, boolean = _ref.boolean, number = _ref.number, array = _ref.array, object = _ref.object;

_ref1 = require('lib/db_docs/field_types/type_decorators'), initial = _ref1.initial, extendedParse = _ref1.extendedParse;

Outcome = require('lib/db_docs/doc_shapes/outcome_doc_shape');

QuestionModel = require('models/question');

_ref2 = shape.typeDecorators, optional = _ref2.optional, calculated = _ref2.calculated;

NameWithCodes = require('lib/db_docs/field_types/name_with_codes_type');

Code = require('lib/db_docs/field_types/code_type');

convertToList = require('lib/question_tags_helper').convertToList;

numberDefaultForFailedParse = _.constant(number.typeDefault);

optionalNumber = optional(number);

optionalString = optional(string);

questionFieldCalculate = function(data) {
  var diagnosticVerb, isVs, suffix, templateKey, _ref3;
  if (data.type === 'diagnostic') {
    isVs = data.comparatorTestPresent;
    diagnosticVerb = data.diagnosticVerb;
    templateKey = isVs ? 'diagnostic_vs' : 'diagnostic';
    suffix = diagnosticVerb === 'screen' ? '_screen' : '';
    return $.t("es:question.templates." + templateKey + suffix, data);
  } else if (data.type === 'keyMessage') {
    return (_ref3 = data.question) != null ? _ref3 : '';
  } else {
    return $.t("es:question.templates." + data.questionFormat, data);
  }
};

questionShortFieldCalculate = function(data) {
  var diagnosticVerb, isVs, suffix, templateKey;
  if (data.type === 'diagnostic') {
    isVs = data.comparatorTestPresent;
    diagnosticVerb = data.diagnosticVerb;
    templateKey = isVs ? 'diagnostic_vs' : 'diagnostic';
    suffix = diagnosticVerb === 'screen' ? '_screen' : '';
    return $.t("es:question.templates." + templateKey + "_short" + suffix, data);
  } else {
    return $.t("es:question.templates." + data.questionFormat + "_short", data);
  }
};

tableTitleCalculate = function(data) {
  var messageKey, params, suffix;
  if (data.manualTableTitle) {
    return data.tableTitle;
  } else {
    params = _.pick(data, QuestionModel.TITLE_ATTRIBUTES);
    messageKey = data.type === 'diagnostic' ? (suffix = data.comparatorTestPresent ? '' : '_short', data.diagnosticVerb === 'screen' ? suffix += '_screen' : void 0, "es:question.templates.diagnostic_sof" + suffix) : "es:question.templates." + data.questionFormat + "_sof";
    return _.str.capitalize($.t(messageKey, params));
  }
};

questionShape = docShape({
  docType: initial(docTypes.QUESTION)(string),
  question: calculated(questionFieldCalculate)(string),
  questionShort: calculated(questionShortFieldCalculate)(string),
  intervention: initial(utils.bracketize($.t('es:question.intervention')))(string),
  comparison: initial(utils.bracketize($.t('es:question.comparison')))(string),
  questionFormat: initial('FOR_HP')(string),
  indexTest: initial(utils.bracketize($.t('es:question.index_test')))(string),
  comparatorTest: initial(utils.bracketize($.t('es:question.comparator_test')))(string),
  targetCondition: initial(utils.bracketize($.t('es:question.target_condition')))(string),
  healthProblemOrPopulation: initial(utils.bracketize($.t('es:question.healthProblemOrPopulation')))(string),
  comparatorTestPresent: boolean,
  codes: optional(shape({
    intervention: optional(initial({})(mapOf(Code))),
    population: optional(initial({})(mapOf(Code))),
    comparison: optional(initial({})(mapOf(Code))),
    indexTest: optional(initial({})(mapOf(Code))),
    comparatorTest: optional(initial({})(mapOf(Code)))
  })),
  includedTags: extendedParse(convertToList)(optional(initial([])(arrayOf(NameWithCodes)))),
  type: optionalString,
  manualTableTitle: boolean,
  questionNo: number,
  date: string,
  authors: string,
  orderNumber: optionalNumber,
  sourceOfDxData: optional(oneOf(['pooledAcrossStudies', 'fromSingleStudy', 'rangeFromStudies'])),
  pooledSensitivity: optionalNumber,
  pooledSensitivityFrom: optionalNumber,
  pooledSensitivityTo: optionalNumber,
  pooledSpecificity: optionalNumber,
  pooledSpecificityFrom: optionalNumber,
  pooledSpecificityTo: optionalNumber,
  pooledSensitivityComparison: optionalNumber,
  pooledSensitivityComparisonFrom: optionalNumber,
  pooledSensitivityComparisonTo: optionalNumber,
  pooledSpecificityComparison: optionalNumber,
  pooledSpecificityComparisonFrom: optionalNumber,
  pooledSpecificityComparisonTo: optionalNumber,
  pooledSensitivityRangeFrom: optionalNumber,
  pooledSensitivityRangeTo: optionalNumber,
  pooledSensitivityComparisonRangeFrom: optionalNumber,
  pooledSensitivityComparisonRangeTo: optionalNumber,
  pooledSpecificityRangeFrom: optionalNumber,
  pooledSpecificityRangeTo: optionalNumber,
  pooledSpecificityComparisonRangeFrom: optionalNumber,
  pooledSpecificityComparisonRangeTo: optionalNumber,
  prevalence1: number,
  prevalence2: extendedParse(numberDefaultForFailedParse)(optionalNumber),
  prevalence3: extendedParse(numberDefaultForFailedParse)(optionalNumber),
  prevalence1Characteristics: initial($.t('es:question.prevalence_characteristics_default'))(string),
  prevalence2Characteristics: initial($.t('es:question.prevalence_characteristics_default'))(string),
  prevalence3Characteristics: initial($.t('es:question.prevalence_characteristics_default'))(string),
  absDenominator: optionalNumber,
  recommendationIds: array,
  outcomes: arrayOf(Outcome),
  $tableMode: oneOf(['regular', 'narrative']),
  version_etr_treatment: initial('1.0')(string),
  version_etr_diagnostic: initial('1.0')(string),
  coi: optional(object),
  footnotes: object,
  tableTitle: calculated(tableTitleCalculate)(string),
  footnotesList: optional(object),
  settings: optionalString,
  references: object,
  referencesOrder: optional(array),
  revManId: optionalString,
  bibliography: optionalString,
  indexTestCutOff: optionalString,
  referenceTest: optionalString,
  referenceTestShortName: optionalString,
  referenceTestThreshold: optionalString,
  outcomesForRecommendation: optional(array),
  source: optionalString,
  userId: optionalString,
  approvalStatus: optional(oneOf(['rejected', 'accepted', 'mentioned'])),
  approvals: optional(mapOf(oneOf(['yes', 'no']))),
  priorityDisagreementResolved: optional(boolean),
  archieId: optionalString,
  archieTimestamp: optionalString,
  archieDiscardedTimestamp: optionalString,
  recommendationAdolopmentIds: optional(array),
  key: optionalString,
  revManWebReviewId: optionalString,
  revManWebTableId: optionalString,
  revManWebAnalysisGroupId: optionalString,
  revManWebAnalysisGroupName: optionalString,
  revManWebDeletedOutcomeIds: optional(arrayOf(string)),
  importedFileContent: optionalString,
  importedFileName: optionalString,
  qualityIndicatorsId: optionalString,
  diagnosticVerb: initial('diagnose')(oneOf(['diagnose', 'screen'])),
  cadthPublication: optional(boolean)
});

module.exports = questionShape;
