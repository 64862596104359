var Set;

module.exports = Set = (function() {
  function Set(initialData) {
    var val, _i, _len, _ref;
    if (initialData && !_(initialData).isArray()) {
      throw Error('Initial data of Set should be an array');
    }
    this.data = {};
    _ref = initialData != null ? initialData : [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      val = _ref[_i];
      this.add(val);
    }
  }

  Set.prototype.add = function(val) {
    this.data[val] = val;
    return this;
  };

  Set.prototype.remove = function(val) {
    delete this.data[val];
    return this;
  };

  Set.prototype.contains = function(val) {
    return this.data[val] != null;
  };

  Set.prototype.size = function() {
    return _(this.data).keys().length;
  };

  Set.prototype.asArray = function() {
    return _(this.data).values();
  };

  return Set;

})();
