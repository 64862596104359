mediator = require 'mediator'
Translation = require 'components/mixins/translation'
ConfirmationModal = require 'components/common/confirmation_modal'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
SidebarChapterContent = require 'components/document_sections/v2/sidebar_chapter_content'
{ SIDEBAR_CHAPTER_ITEM_DNT_TYPE } = require 'lib/document_sections_v2_helper'
{
  dragSpec, dropSpec, dragCollect, dropCollect
} = require 'components/document_sections/v2/sidebar_chapter_item_dnd_spec'

SidebarChapterItem = createReactClass
  displayName: 'SidebarChapterItem'

  mixins: [
    Translation('docsec:management')
  ]

  propTypes:
    chapter: PropTypes.instanceOf(Immutable.Map).isRequired
    ancestors: PropTypes.array.isRequired
    connectDragSource: PropTypes.func.isRequired
    connectDropTarget: PropTypes.func.isRequired
    isDragging: PropTypes.bool.isRequired
    isOver: PropTypes.bool.isRequired
    mdaTopicId: PropTypes.string
    withEditButtons: PropTypes.bool

  getDefaultProps: ->
    withEditButtons: true

  getInitialState: () ->
    isConfirmationOpen: false

  getRef: (el) ->
    @item = el

  handleSave: (data) ->
    projectId = mediator.project.id
    chapterId = @props.chapter.get('_id')
    DocumentSectionsV2Actions.setChapterData(projectId, chapterId, data)

  handleDelete: () ->
    @setState
      isConfirmationOpen: true

  handleScrollToChapter: ->
    DocumentSectionsV2Actions.setScrollToChapter @props.chapter.get('_id')

  confirmDelete: ->
    DocumentSectionsV2Actions.deleteChapter(mediator.project.id, @props.chapter.get('_id'))
    @closeDeleteConfirmation()

  closeDeleteConfirmation: ->
    @setState
      isConfirmationOpen: false

  render: ->
    {
      ancestors
      chapter
      connectDragSource
      connectDropTarget
      isDragging
      isOver
      mdaTopicId
      withEditButtons
    } = @props
    { isConfirmationOpen } = @state

    className = classNames 'sidebar-chapter-item', { dragging: isDragging, over: isOver }
    style = { paddingLeft: (ancestors.length + 1) * 10 + 'px' }

    connectDragSource(
      connectDropTarget(
        <div ref={@getRef} className={className} style={style} onClick={@handleScrollToChapter}>
          <SidebarChapterContent
            chapter={chapter}
            mdaTopicId={mdaTopicId}
            onSave={@handleSave}
            onDelete={@handleDelete}
            withEditButtons={withEditButtons}
          />
          <ConfirmationModal
            isOpen={isConfirmationOpen}
            question={@i18n 'delete_chapter_confirmation_question'}
            confirmLabel={@i18n 'delete_chapter_confirmation_label'}
            onConfirm={@confirmDelete}
            onCancel={@closeDeleteConfirmation}
          />
        </div>
      )
    )

DragSourced = ReactDnD.DragSource(
  SIDEBAR_CHAPTER_ITEM_DNT_TYPE, dragSpec, dragCollect
) SidebarChapterItem
DropTargeted = ReactDnD.DropTarget(
  SIDEBAR_CHAPTER_ITEM_DNT_TYPE, dropSpec, dropCollect
) DragSourced

module.exports = DropTargeted
