var DbHelper, MASTER_PROJECT_ID, MDA_TABLE, MDA_TOPIC, MdaTableDoc, MdaTopicDoc, W, appUtils, fetchDoc, generateActions, mediator, _ref;

appUtils = require('lib/app_utils');

DbHelper = require('base/lib/db_helper');

generateActions = require('actions/utils/generate_actions');

MASTER_PROJECT_ID = require('lib/mda_helper').MASTER_PROJECT_ID;

MdaTableDoc = require('lib/db_docs/mda_table_doc');

MdaTopicDoc = require('lib/db_docs/mda_topic_doc');

_ref = require('lib/db_docs/doc_types'), MDA_TABLE = _ref.MDA_TABLE, MDA_TOPIC = _ref.MDA_TOPIC;

mediator = require('mediator');

W = require('when/when');

fetchDoc = function(Doc, projectId) {
  if (projectId == null) {
    projectId = MASTER_PROJECT_ID;
  }
  return Doc.at(projectId).fetchMany().then(function(_arg) {
    var rows;
    rows = _arg.rows;
    return _.chain(rows).pluck('doc').compact().map(function(doc) {
      return [doc._id, doc];
    }).object().value();
  });
};

module.exports = generateActions({
  autoGenerate: ['fetchError', 'fetchSuccess'],
  name: 'MdaVerificationActions',
  actions: {
    dbChange: function(change, _pending, _lastSeq) {
      var doc, _ref1;
      doc = change.doc;
      if ((_ref1 = doc.docType) !== MDA_TABLE && _ref1 !== MDA_TOPIC) {
        return;
      }
      return this.dispatch(change);
    },
    fetch: function() {
      var centralMasterProjectReplica, localMasterProjectReplica;
      this.dispatch();
      centralMasterProjectReplica = DbHelper.centralReplica(MASTER_PROJECT_ID);
      localMasterProjectReplica = DbHelper.localReplica(MASTER_PROJECT_ID);
      return centralMasterProjectReplica.replicate.to(localMasterProjectReplica).then(function() {
        return W.all([fetchDoc(MdaTopicDoc), fetchDoc(MdaTableDoc, mediator.project.id), fetchDoc(MdaTopicDoc, mediator.project.id)]);
      }).then((function(_this) {
        return function(_arg) {
          var masterTopics, usedTables, usedTopics;
          masterTopics = _arg[0], usedTables = _arg[1], usedTopics = _arg[2];
          return _this.actions.fetchSuccess({
            masterTopics: masterTopics,
            usedTables: usedTables,
            usedTopics: usedTopics
          });
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          _this.actions.fetchError();
          return appUtils.errorHandlerWithMsg(err, $.t('mda:workflow.fetch_error'));
        };
      })(this));
    }
  }
});
