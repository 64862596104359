Translation = require 'components/mixins/translation'
Tooltip = require 'components/common/tooltip'
AddCustomTooltip = require 'components/enhancers/add_custom_tooltip'
StatusLegend = require 'components/panel_voice/status_legend'
{ ETD_PARTS, ETD_PARTS_LETTERS_MAP } = require 'lib/voting_helper'
{ instanceOf, bool } = PropTypes

StatusRect = (props) ->
  { parts, status, isInactive } = props
  hoverProps = _.pick props, 'onMouseOver', 'onMouseLeave'
  multiplePartsClass = if parts.length > 1 then "multi-#{parts.length}" else ''
  partClass = classNames 'etd-part', status, multiplePartsClass, 'inactive': isInactive

  <div className={partClass} {...hoverProps}>
    {props.parts.map (partName, idx) ->
      <span className='label' key={idx}>{ETD_PARTS_LETTERS_MAP[partName]}</span>
    }
  </div>

tooltipParams = trigger: 'hover', position: 'bottom', alignment: 'center', positionOffset: 5
StatusRectWithLegend = AddCustomTooltip StatusRect, StatusLegend, tooltipParams

QuestionVotingStatus = createReactClass
  displayName: 'QuestionVotingStatus'

  mixins: [Translation('voting:panel_voice.statuses')]

  propTypes:
    inactive: bool
    votingStages: instanceOf(Immutable.List)

  getDefaultProps: ->
    inactive: false

  getInactiveMessage: ->
    <Tooltip>
      <span className="inactive" title={@i18n '../inactive_desc'}>
        {@i18n 'inactive'}
      </span>
    </Tooltip>

  getStatusText: (partSpecs) ->
    # all unsent
    return @getInactiveMessage() if @props.inactive
    return @i18n 'unsent' if @props.votingStages.isEmpty()
    lastStatus = @props.votingStages.last().get 'status'
    return @i18n 'test_sent' if lastStatus is 'test'
    # 'ongoing' status has the highest "weight", so return it disregarding of number of sent parts
    return @i18n 'ongoing' if lastStatus is 'ongoing'
    # if we are here then the last stage status is 'closed'. Depending on remaining parts statuses
    # we need to either return 'unsent' or 'closed' status
    # if three is any 'unsent' part - return 'unsent'. Otherwise return 'closed'
    for partName of partSpecs
      return @i18n 'unsent' if partSpecs[partName].status is 'unsent'
    @i18n 'closed'

  getPartSpecs: ->
    defaultSpecs =
      assessment: status: 'unsent'
      recommendation: status: 'unsent'
      conclusion: status: 'unsent'
    if @props.votingStages.isEmpty()
      defaultSpecs
    else
      @props.votingStages.reduce (acc, stage, stageIdx) ->
        stageParts = stage.get 'parts'
        status = stage.get 'status'
        # treat 'test' status as undefined in this component
        status = 'unsent' if status is 'test'
        stageParts.forEach (p) ->
          partName = p.get 'name'
          # update part's status in acc
          acc[partName] = _.extend acc[partName], { status, stageIdx }
          # since it is possible to send etd parts not on order, e.g. skip assessment, need to
          # see previous part's status and update it with value "skipped" if its current value is
          # "unsent"
          prevParts = ETD_PARTS.slice(0, ETD_PARTS.indexOf(partName))
          _(prevParts).each (part) ->
            prevPartStatus = acc[part].status
            if prevPartStatus is 'unsent'
              acc[part] = _.extend acc[part], status: 'skipped'
        acc
      , defaultSpecs

  getRects: (partSpecs) ->
    _.reduce ETD_PARTS, (acc, partName) ->
      [ others..., last ] = acc
      { status, stageIdx } = partSpecs[partName]
      # combine parts if they were sent together. Since etd parts can only be sent one after
      # another - it is safe to compare this part with the last acc record only
      if last and stageIdx? and stageIdx is last.stageIdx
        # update last record with this part name
        last.parts.push partName
        others.concat last
      else
        acc.push { status, stageIdx, parts: [ partName ] }
        acc
    , []

  renderRects: (partSpecs) ->
    { inactive } = @props
    _.map @getRects(partSpecs), (rect, idx ) ->
      { parts, status } = rect
      <div key={idx}>
        <StatusRectWithLegend isInactive={inactive} parts={parts} status={status} />
      </div>

  render: ->
    partSpecs = @getPartSpecs()
    statusText = @getStatusText(partSpecs)
    <div className='question-voting-status'>
      <div className='etd-parts-statuses'>
        {@renderRects(partSpecs)}
      </div>
        {unless _.isEmpty statusText
          <div className='status-text'>{statusText}</div>
        }
    </div>

module.exports = QuestionVotingStatus
