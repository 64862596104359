AdolopmentDataActions = require 'actions/adolopment_data_actions'
AdolopmentSectionSeparator = require 'components/etd/adolopment_section_separator'
AdolopmentTypeOfRecommendation = require 'components/etd/adolopment_type_of_recommendation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdPart = require 'components/etd/etd_part'
EtdSectionTitle = require 'components/etd/etd_section_title'
EtdVotingMixin = require 'components/mixins/etd_voting_mixin'
Switcher = require 'components/common/switcher'
Translation = require 'components/mixins/translation'
TypeOfRecommendation = require 'components/etd/type_of_recommendation'
TypeOfRecommendationWithVoting = require 'components/etd/type_of_recommendation_with_voting'
VotingTabs = require 'components/etd/voting_tabs'
{ getSelectedOptionFromSection } = require 'lib/etd_helper'


Recommendation = createReactClass
  displayName: 'Recommendation'
  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
    EtdVotingMixin
  ]

  propTypes:
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired
    adolopmentData: PropTypes.instanceOf(Immutable.Map).isRequired
    etdId: PropTypes.string.isRequired
    etdViewSettings: PropTypes.instanceOf(Immutable.Map).isRequired
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map)
    sectionId: PropTypes.string.isRequired
    showResultingDataOnly: PropTypes.bool
    switchVotingTab: PropTypes.func

  getDefaultProps: ->
    overarchingQuestionData: Immutable.Map()
    showResultingDataOnly: false

  _isConsensus: ->
    @props.activeTab? and @props.activeTab isnt 'draftJudgement'

  _getSectionProps: (section, sectionId) ->
    editable: if @props.editable? then @props.editable else @_inEditMode()
    etdId: @props.etdId
    etdViewSettings: @props.etdViewSettings
    isConsensus: @_isConsensus()
    overarchingQuestionData: @props.overarchingQuestionData
    renderMode: @props.renderMode
    sectionId: sectionId

  toggleAdolopmentForSection: ->
    AdolopmentDataActions.toggleAdolopmentForSection @props.etdId, 'conclusions', @props.sectionId

  render: ->
    {
      activeTab
      adolopmentData
      adolopments
      etdId
      etdViewSettings
      renderMode
      section
      sectionId
      switchVotingTab
      titleKey
      showResultingDataOnly
    } = @props

    # withAdolopment means that one wants to update this section
    # if it is set to false than content of the section is copied from orginal etd and marked as
    # readonly
    withAdolopment = adolopmentData.getIn([sectionId, 'withAdolopment'], false)

    sectionProps = @_getSectionProps section, sectionId
    selectedOption = getSelectedOptionFromSection @_isConsensus(), section

    highlightAsDiff = if not adolopments.isEmpty()
      originalSection = adolopments.last()
        .getIn(['templateData', 'conclusions', 'sections', sectionId])
      originalOption = getSelectedOptionFromSection false, originalSection
      selectedOption != originalOption and not _.isEmpty(selectedOption)
    else false

    sectionToBeDisplayedAsCurrent = if adolopments.isEmpty()
      section
    else if withAdolopment
      section
    else
      adolopments.last()
        .getIn(['templateData', 'conclusions', 'sections', sectionId])

    adolopmentSectionCollapsed = etdViewSettings.getIn(
      [etdId, 'collapsedAdolopmentSections', sectionId], false)

    showAdolopmentPart = not adolopments.isEmpty() and not showResultingDataOnly and withAdolopment

    if section and not section.isEmpty()
      <EtdPart titleKey='type_of_recommendation'
        renderMode={renderMode}
        activeTab={activeTab}
        onTabClick={switchVotingTab}
      >
        {not adolopments.isEmpty() and renderMode isnt 'printout' and
          <div className="type_of_recommendation__adolopment-switcher">
            <Switcher
              checked={withAdolopment}
              onChange={@toggleAdolopmentForSection}
              buttonText={@i18n '/es:recommendations.table-view-options.toggle_adolopment'}
            />
          </div>
        }

        {showAdolopmentPart and <AdolopmentTypeOfRecommendation
          className='recommendation-table-static'
          adolopments={adolopments}
          key={"#{sectionId}-adoloped"}
          {...sectionProps}
        />
        }
        {not adolopmentSectionCollapsed and <TypeOfRecommendation
          key={sectionId}
          className='recommendation-table-static'
          highlightAsDiff={highlightAsDiff}
          section={sectionToBeDisplayedAsCurrent}
          {...sectionProps}
        />
        }
        {if @_hasVotingStarted() and activeTab is 'votingResults'
          votingProps = if @_hasVotingStarted() then @_getVotingPropsForSection(sectionId) else {}

          <table className="standard-table recommendation-table-static">
            <TypeOfRecommendationWithVoting key={sectionId}
              section={section}
              {...sectionProps}
              {...votingProps}
            />
          </table>
        }
      </EtdPart>
    else
      null

module.exports = Recommendation
