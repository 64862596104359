var Event, Events, OrderedCollection,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OrderedCollection = require('models/base/ordered_collection');

Event = require('./event');

module.exports = Events = (function(_super) {
  __extends(Events, _super);

  function Events() {
    return Events.__super__.constructor.apply(this, arguments);
  }

  Events.prototype.model = Event;

  Events.prototype.pouch = {
    fetch: 'events',
    views: {
      events: {
        map: function(doc) {
          if (doc.docType === 'event') {
            return emit(doc.docType, doc);
          }
        }
      }
    },
    filters: {
      events: function(doc) {
        return doc._deleted || doc.docType === 'event';
      }
    }
  };

  return Events;

})(OrderedCollection);
