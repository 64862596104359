var W, ajax, invalidArgumentsErr;

W = require('when');

invalidArgumentsErr = function() {
  return W.reject(new Error({
    status: 400,
    responseText: {
      message: 'Invalid arguments provided'
    }
  }));
};

ajax = function(params) {
  return W.promise(function(resolve, reject) {
    var defaultParams;
    if (_.isObject(params)) {
      defaultParams = {
        contentType: 'application/json',
        error: reject,
        success: resolve
      };
      return $.ajax(_.extend({}, defaultParams, params));
    } else {
      return reject('params argument must be an object');
    }
  });
};

module.exports = {
  invalidArgumentsErr: invalidArgumentsErr,
  ajax: ajax
};
