var referenceNode;

referenceNode = {
  attrs: {
    refIds: {
      "default": []
    },
    references: {
      "default": {}
    }
  },
  inline: true,
  group: 'inline',
  draggable: true,
  atom: true,
  toDOM: function(node) {
    var refIds, references, _ref;
    _ref = node.attrs, refIds = _ref.refIds, references = _ref.references;
    return [
      'reference', {
        "class": 'reference',
        'data-ref-ids': refIds,
        'data-refs': JSON.stringify(references)
      }
    ];
  },
  parseDOM: [
    {
      tag: 'reference',
      getAttrs: function(dom) {
        var refIds, referencesData, _ref;
        refIds = (_ref = dom.getAttribute('data-ref-ids')) != null ? _ref.split(',') : void 0;
        referencesData = dom.getAttribute('data-refs');
        if (!(refIds && referencesData)) {
          return false;
        }
        return {
          refIds: refIds,
          references: JSON.parse(referencesData)
        };
      }
    }
  ]
};

module.exports = referenceNode;
