var Collection, Model, OrderedCollection, W, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Collection = require('./collection');

W = require('when/when');

mediator = require('mediator');

Model = require('models/base/model');

module.exports = OrderedCollection = (function(_super) {
  __extends(OrderedCollection, _super);

  OrderedCollection.prototype.setOrderOnAdd = true;

  OrderedCollection.prototype.checkOrderOnReset = true;

  OrderedCollection.prototype.comparatorAttribute = 'orderNumber';

  function OrderedCollection(models, options) {
    var model, _comparator, _i, _len, _ref, _ref1;
    _comparator = this.comparator;
    this.comparator = null;
    OrderedCollection.__super__.constructor.apply(this, arguments);
    this.comparator = _comparator;
    _ref1 = (_ref = this.models) != null ? _ref : [];
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      model = _ref1[_i];
      if (!model.has(this.comparatorAttribute)) {
        model.set(this.comparatorAttribute, this.getMaxOrderNumber() + 1);
      }
    }
    this.sort();
  }

  OrderedCollection.prototype.initialize = function() {
    OrderedCollection.__super__.initialize.apply(this, arguments);
    if (this.setOrderOnAdd) {
      this.on('add', this._assignOrderNumber);
    }
    if (this.checkOrderOnReset) {
      return this.on('reset', this._checkOrder);
    }
  };

  OrderedCollection.prototype._idsComparator = function(m1, m2) {
    if (m1.id < m2.id) {
      return -1;
    } else if (m1.id > m2.id) {
      return 1;
    } else {
      return 0;
    }
  };

  OrderedCollection.prototype.getMaxOrderNumber = function() {
    return _(this.pluck(this.comparatorAttribute)).chain().filter(function(n) {
      return _.isNumber(n);
    }).union([0]).max().value();
  };

  OrderedCollection.prototype._checkOrder = function() {
    var maxOrderNumber, model, saves;
    maxOrderNumber = this.getMaxOrderNumber();
    saves = (function() {
      var _i, _len, _ref, _results;
      _ref = this.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        model = _ref[_i];
        if (!(!_.isNumber(model.get(this.comparatorAttribute)))) {
          continue;
        }
        maxOrderNumber += 1;
        model.set(this.comparatorAttribute, maxOrderNumber);
        _results.push(model);
      }
      return _results;
    }).call(this);
    return W.all(saves).otherwise(mediator.dialogs.fatalError);
  };

  OrderedCollection.prototype.comparator = function(m1, m2) {
    var m1Order, m2Order;
    m1Order = m1.get(this.comparatorAttribute);
    m2Order = m2.get(this.comparatorAttribute);
    if (m1Order - m2Order) {
      return m1Order - m2Order;
    } else {
      return this._idsComparator(m1, m2);
    }
  };

  OrderedCollection.prototype._assignOrderNumber = function(model) {
    if (!_.isNumber(model.get(this.comparatorAttribute))) {
      return model.set(this.comparatorAttribute, this.getMaxOrderNumber() + 1);
    }
  };

  OrderedCollection.prototype._getItemOrderNumber = function(idx) {
    return this.at(idx).get(this.comparatorAttribute);
  };

  OrderedCollection.prototype._setItemOrderNumber = function(idx, value) {
    return this.at(idx).set(this.comparatorAttribute, value);
  };

  OrderedCollection.prototype.switchItems = function(fromIdx, toIdx, silent) {
    var i, sign, tmpOrder, _i, _ref;
    if (silent == null) {
      silent = false;
    }
    if (fromIdx === toIdx) {
      return;
    }
    tmpOrder = this._getItemOrderNumber(toIdx);
    sign = fromIdx < toIdx ? 1 : -1;
    for (i = _i = toIdx, _ref = fromIdx + sign; toIdx <= _ref ? _i <= _ref : _i >= _ref; i = toIdx <= _ref ? ++_i : --_i) {
      this._setItemOrderNumber(i, this._getItemOrderNumber(i - sign));
    }
    this._setItemOrderNumber(fromIdx, tmpOrder);
    this.sort({
      silent: silent
    });
    return this._saveReorderedItems(fromIdx, toIdx);
  };

  OrderedCollection.prototype._saveReorderedItems = function(fromIdx, toIdx) {
    var i, saves;
    saves = (function() {
      var _i, _results;
      _results = [];
      for (i = _i = fromIdx; fromIdx <= toIdx ? _i <= toIdx : _i >= toIdx; i = fromIdx <= toIdx ? ++_i : --_i) {
        _results.push(this.at(i).save());
      }
      return _results;
    }).call(this);
    return W.all(saves).otherwise(mediator.dialogs.fatalError);
  };

  OrderedCollection.prototype.add = function(models, options) {
    var res, _comparator;
    _comparator = this.comparator;
    this.comparator = null;
    res = OrderedCollection.__super__.add.call(this, models, options);
    this.comparator = _comparator;
    if (this.comparator) {
      this.sort({
        silent: true
      });
    }
    return res;
  };

  return OrderedCollection;

})(Collection);
