EllipsizedText = require 'components/common/ellipsized_text'
{ MASTER_PROJECT_ID } = require 'lib/mda_helper'
MdaWorkflowPublicationActions = require 'actions/mda_workflow_publication_actions'
mediator = require 'mediator'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

DATE_FORMAT = 'MM/DD/YYYY'
DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss'

MdaTopic = ({ data, pendingTopics, selectedTopics, topicPublicationDates }) ->
  inMaster = mediator.project?.id is MASTER_PROJECT_ID
  i18n = useI18n 'dbep:questions_list_screen'
  mdaTopicId = data.get '_id'
  [status, publicationDate] = useCoffeeMemo [mdaTopicId, pendingTopics, topicPublicationDates], ->
    publicationDate = topicPublicationDates.get mdaTopicId
    publicationDateMoment = if publicationDate?
      moment publicationDate
    else
      undefined
    if pendingTopics.includes mdaTopicId
      ['pending', publicationDateMoment]
    else if publicationDate?
      ['published', publicationDateMoment]
    else
      ['unpublished', publicationDateMoment]

  toggleCheckbox = useCoffeeCallback [mdaTopicId], ->
    MdaWorkflowPublicationActions.toggleTopics [mdaTopicId]

  workflowTitle = data.get 'lastUpdateProjectName'
  topicTitle = data.get 'name'

  <div className="publication-platform-questions-table__row">
    {inMaster and <div className="publication-platform-questions-table__cell checkbox">
      <div className="cell-content">
        <input
          checked={selectedTopics.includes mdaTopicId}
          onChange={toggleCheckbox}
          type="checkbox"
        />
      </div>
    </div>}
    <div className="publication-platform-questions-table__cell question">
      <Tooltip>
        <div className="cell-content" title={topicTitle}>
          <EllipsizedText text={topicTitle} height={50} />
        </div>
      </Tooltip>
    </div>
    <div className="publication-platform-questions-table__cell status">
      <div className={"status__square #{status}"} />
      <div>{i18n "statuses.#{status}"}</div>
      {publicationDate and not inMaster and <Tooltip>
        <div className="ml-5" title={publicationDate.format DATE_TIME_FORMAT}>
          ({publicationDate.format DATE_FORMAT})
        </div>
      </Tooltip>}
    </div>
    {inMaster and <React.Fragment>
      <div
        className="publication-platform-questions-table__cell publicationDate"
      >
        <Tooltip>
          <div className="cell-content" title={publicationDate?.format DATE_TIME_FORMAT}>
            {publicationDate?.format(DATE_FORMAT) ? '-'}
          </div>
        </Tooltip>
      </div>
      <div className="publication-platform-questions-table__cell workflow">
        <Tooltip>
          <div className="cell-content" title={workflowTitle}>
            <EllipsizedText text={workflowTitle or '-'} height={50} />
          </div>
        </Tooltip>
      </div>
    </React.Fragment>}
  </div>

MdaTopic.propTypes =
  data: PropTypes.instanceOf(Immutable.Map).isRequired
  pendingTopics: PropTypes.instanceOf(Immutable.List).isRequired
  selectedTopics: PropTypes.instanceOf(Immutable.List).isRequired
  topicPublicationDates: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = MdaTopic
