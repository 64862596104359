var DocumentSectionsCommonActions, DocumentSectionsCommonStore, alt, errorHandler, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

errorHandler = require('lib/app_utils').errorHandler;

DocumentSectionsCommonActions = require('actions/document_sections_common_actions');

DocumentSectionsCommonStore = (function() {
  function DocumentSectionsCommonStore() {
    this.state = Immutable.fromJS({
      _meta: {
        fetching: false
      },
      alreadyUsedRecommendations: {}
    });
    this.bindActions(DocumentSectionsCommonActions);
    this.exportPublicMethods({
      getAlreadyUsedRecommendations: this.getAlreadyUsedRecommendations,
      getAlreadyUsedRecommendationsQuestionsIds: this.getAlreadyUsedRecommendationsQuestionsIds,
      isFetching: this.isFetching
    });
  }

  DocumentSectionsCommonStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  DocumentSectionsCommonStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  DocumentSectionsCommonStore.prototype.onFetchSuccess = function(_arg) {
    var alreadyUsedRecommendations;
    alreadyUsedRecommendations = _arg.alreadyUsedRecommendations;
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], false);
      return state.set('alreadyUsedRecommendations', _.reduce(alreadyUsedRecommendations, function(acc, entry) {
        return acc.set(entry.sectionId, Immutable.fromJS(entry));
      }, Immutable.Map()));
    }));
  };

  DocumentSectionsCommonStore.prototype.onFetchFailure = function(err) {
    this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'fetching'], false);
      return s.setIn(['_meta', 'fetchingSectionsForSearch'], false);
    }));
    return errorHandler(err);
  };

  DocumentSectionsCommonStore.prototype.onRemoveAlreadyUsedRecommendations = function(sectionIds) {
    return this.setState(this.state.update('alreadyUsedRecommendations', function(alreadyUsedRecommendations) {
      return alreadyUsedRecommendations.filter(function(_section, key) {
        return !_.includes(sectionIds, key);
      });
    }));
  };

  DocumentSectionsCommonStore.prototype.onAddAlreadyUsedRecommendation = function(_arg) {
    var data, sectionId;
    sectionId = _arg.sectionId, data = _arg.data;
    return this.setState(this.state.setIn(['alreadyUsedRecommendations', sectionId], data));
  };

  DocumentSectionsCommonStore.prototype.getAlreadyUsedRecommendations = function() {
    return this.state.get('alreadyUsedRecommendations', Immutable.Map());
  };

  DocumentSectionsCommonStore.prototype.getAlreadyUsedRecommendationsQuestionsIds = function() {
    return this.state.get('alreadyUsedRecommendations').reduce(function(acc, entry) {
      return acc.add(entry.get('questionId'));
    }, Immutable.Set());
  };

  return DocumentSectionsCommonStore;

})();

module.exports = alt.createStore(immutable(DocumentSectionsCommonStore), 'DocumentSectionsCommonStore');
