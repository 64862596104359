var Outcome, mediator, stats;

Outcome = require('models/outcome');

mediator = require('mediator');

stats = require('lib/statistical_utils');

module.exports = {
  convertDiagnostic: function(question, comparison, projectName) {
    var absDenominator, footnotesList, getFootnote, getPrevalenceGroups, outcomeWithLowerQuality, pickMax, pickMin, prevalenceGroup, prevalences, qualityFootnote, sensitivity, sensitivityFrom, sensitivityOutcome, sensitivityTo, specificity, specificityFrom, specificityOutcome, specificityTo, testInfix, testNameAttr, _ref, _ref1, _ref2;
    if (question.get('sourceOfDxData') === 'rangeFromStudies') {
      return {
        ok: false,
        message: $.t('es:isof.range_from_studies')
      };
    }
    footnotesList = question.get('footnotesList');
    getFootnote = function(attr, model) {
      var _ref;
      if (model == null) {
        model = question;
      }
      return _((_ref = model.get('footnotes')) != null ? _ref[attr] : void 0).map(footnotesList.get).join('\n');
    };
    absDenominator = parseInt((_ref = question.get('absDenominator')) != null ? _ref : 1000);
    prevalences = _(['prevalence1', 'prevalence2', 'prevalence3']).chain().map(function(attr) {
      return {
        value: question.get(attr),
        description: question.get("" + attr + "Characteristics"),
        footnote: getFootnote(attr)
      };
    }).filter(function(o) {
      return o.value != null;
    }).value();
    if (!prevalences.length) {
      return {
        ok: false,
        message: $.t('es:isof.no_prevalence')
      };
    }
    sensitivityOutcome = question.get('outcomes').at(0);
    specificityOutcome = question.get('outcomes').at(2);
    pickMin = function(attr) {
      return Math.min(sensitivityOutcome.get(attr), specificityOutcome.get(attr));
    };
    pickMax = function(attr) {
      return Math.max(sensitivityOutcome.get(attr), specificityOutcome.get(attr));
    };
    outcomeWithLowerQuality = sensitivityOutcome.get('quality') < specificityOutcome.get('quality') ? sensitivityOutcome : specificityOutcome;
    qualityFootnote = _(Outcome.QUALITY_MODIFIERS).map(function(attr) {
      return getFootnote(attr, outcomeWithLowerQuality);
    }).join('\n');
    testInfix = comparison ? 'Comparison' : '';
    _ref1 = [question.get("pooledSpecificity" + testInfix + "From"), question.get("pooledSpecificity" + testInfix), question.get("pooledSpecificity" + testInfix + "To")], specificityFrom = _ref1[0], specificity = _ref1[1], specificityTo = _ref1[2];
    _ref2 = [question.get("pooledSensitivity" + testInfix + "From"), question.get("pooledSensitivity" + testInfix), question.get("pooledSensitivity" + testInfix + "To")], sensitivityFrom = _ref2[0], sensitivity = _ref2[1], sensitivityTo = _ref2[2];
    prevalenceGroup = function(name, _arg) {
      var description, footnote, prevalence;
      prevalence = _arg.value, description = _arg.description, footnote = _arg.footnote;
      return {
        name: name,
        prevalenceExplanation: name,
        prevalence: prevalence * absDenominator / 100,
        prevalence_explanation: description,
        prevalence_footnote: footnote,
        false_negatives: stats.diagnosticEffectFN(sensitivity, prevalence, absDenominator),
        fn_from: stats.diagnosticEffectFN(sensitivityFrom, prevalence, absDenominator),
        fn_to: stats.diagnosticEffectFN(sensitivityTo, prevalence, absDenominator),
        false_positives: stats.diagnosticEffectFP(specificity, prevalence, absDenominator),
        fp_from: stats.diagnosticEffectFP(specificityFrom, prevalence, absDenominator),
        fp_to: stats.diagnosticEffectFP(specificityTo, prevalence, absDenominator),
        true_positives: stats.diagnosticEffectTP(sensitivity, prevalence, absDenominator),
        tp_from: stats.diagnosticEffectTP(sensitivityFrom, prevalence, absDenominator),
        tp_to: stats.diagnosticEffectTP(sensitivityTo, prevalence, absDenominator),
        true_negatives: stats.diagnosticEffectTN(specificity, prevalence, absDenominator),
        tn_from: stats.diagnosticEffectTN(specificityFrom, prevalence, absDenominator),
        tn_to: stats.diagnosticEffectTN(specificityTo, prevalence, absDenominator),
        atp_positive: stats.diagnosticSpecificityOfPositiveResult(sensitivity, specificity, prevalence, absDenominator),
        atp_positive_from: stats.diagnosticSpecificityOfPositiveResult(sensitivityFrom, specificityFrom, prevalence, absDenominator),
        atp_positive_to: stats.diagnosticSpecificityOfPositiveResult(sensitivityTo, specificityTo, prevalence, absDenominator),
        atp_negative: stats.diagnosticSpecificityOfNegativeResult(sensitivity, specificity, prevalence, absDenominator),
        atp_negative_from: stats.diagnosticSpecificityOfNegativeResult(sensitivityFrom, specificityFrom, prevalence, absDenominator),
        atp_negative_to: stats.diagnosticSpecificityOfNegativeResult(sensitivityTo, specificityTo, prevalence, absDenominator)
      };
    };
    getPrevalenceGroups = function(prevalences) {
      var p, t, titles, _i, _len, _ref3, _ref4, _results;
      titles = (function() {
        switch (prevalences.length) {
          case 1:
            return ['Normal probability'];
          case 2:
            return ['Low probability', 'High probability'];
          case 3:
            return ['Low probability', 'Medium probability', 'High probability'];
        }
      })();
      _ref3 = _.zip(titles, prevalences);
      _results = [];
      for (_i = 0, _len = _ref3.length; _i < _len; _i++) {
        _ref4 = _ref3[_i], t = _ref4[0], p = _ref4[1];
        _results.push(prevalenceGroup(t, p));
      }
      return _results;
    };
    testNameAttr = comparison ? 'comparatorTest' : 'indexTest';
    return {
      ok: true,
      acp: mediator.services.switches.isServerSwitchOn('acpFeatures'),
      id: question.id,
      is_public: true,
      isDiagnostic: question.isDiagnostic(),
      name: question.get('targetCondition'),
      test: question.get(testNameAttr),
      test_long: question.get(testNameAttr),
      test_footnote: getFootnote(testNameAttr),
      title: question.get('tableTitle'),
      who_prepared_this_sof: question.get('authors'),
      based_on: projectName,
      denom: absDenominator,
      grade: pickMin('quality'),
      grade_footnote: qualityFootnote.trim(),
      groups: getPrevalenceGroups(prevalences),
      participants: pickMax('noOfPatients'),
      studies: pickMax('noOfStudies'),
      patients: question.get('healthProblemOrPopulation'),
      patients_footnote: getFootnote('healthProblemOrPopulation'),
      settings: question.get('settings'),
      settings_footnote: getFootnote('settings'),
      sens_from: sensitivityFrom,
      sensitivity: sensitivity,
      sens_to: sensitivityTo,
      spec_from: specificityFrom,
      specificity: specificity,
      spec_to: specificityTo,
      cutoff: question.get("" + testNameAttr + "CutOff"),
      cutoff_footnote: getFootnote("" + testNameAttr + "CutOff"),
      reference_test: question.get('referenceTestShortName'),
      reference_test_footnote: getFootnote('referenceTestShortName'),
      threshold: question.get('referenceTestThreshold'),
      threshold_footnote: getFootnote('referenceTestThreshold')
    };
  }
};


/*
    Following attributes are either not available in the GDT:

    "negative_lr": # Negative likelihood ratio
    "negative_lr_from": # Negative likelihood ratio confidence interval from
    "negative_lr_to": # Negative likelihood ratio confidence interval to
    "positive_lr": # Not used
    "positive_lr_from": # Not used
    "positive_lr_to": # Not used
    Doesn't seem to work:
    "outcomes": [
        {
            "comments": "Uninterpretable, intermediate, or indeterminate test results."
            "grade": 1,
            "name": "Inconclusive results",
            "participants": 11238,
            "result": "140 per 1000 (14%) in 1 study;\n10 per 1000 (1%) to 50 per 1000 (5%) in ..."
            "studies": 16,
            "type": "non_standard"
        },
        {
            "comments": "",
            "grade": "-",
            "name": "Complications",
            "participants": "-",
            "result": "Not reported",
            "studies": "",
            "type": "non_standard"
        }
    ]
 */
