ConnectStore = require 'components/enhancers/connect_store'
OrganizationsActions = require 'actions/organizations_actions'
OrganizationsStore = require 'stores/organizations_store'
AccountOption = require 'components/organizations/account_option'
PersonalAccountOption = require 'components/organizations/personal_account_option'
Translation = require 'components/mixins/translation'
DbHelper = require 'base/lib/db_helper'
mediator = require 'mediator'
DontShowAgainCheckbox = require 'components/common/dont_show_again_checkbox'
Button = require 'components/common/button'
Modal = require 'components/common/modal'
{ getRouteToProjectsList } = require 'lib/projects_helper'


storeConnector =
  OrganizationsStore: (Store) ->
    isOnline: Store.isOnline()

Organizations = createReactClass
  displayName: 'Organizations'

  mixins: [Translation()]

  propTypes:
    organizations: PropTypes.object.isRequired
    hasPersonalAccount: PropTypes.bool.isRequired
    onRedirectToProjectsList: PropTypes.func.isRequired
    onSubscriptionSuccess: PropTypes.func.isRequired
    isOnline: PropTypes.bool.isRequired

  goToProjectsList: (organizationId) -> =>
    mediator.publish '!router:route', getRouteToProjectsList organizationId

  getOrganizationLogoUrl: (organizationId) ->
    if @props.isOnline
      "#{DbHelper.getBackendUrl()}/organizations/#{organizationId}/logo?#{Date.now()}"
    else
      'images/organization_logo_placeholder.svg'

  renderAccountOption: (organization) ->
    { organizationName, organizationId } = organization
    organizationLogoUrl = @getOrganizationLogoUrl organizationId

    <div key={organizationId}>
      <div className='organization-name text'>
        {organizationName.toUpperCase()}
      </div>
      <AccountOption onOpen={@goToProjectsList organizationId}>
        <div className='logo-wrapper'>
          <img className='organization-logo' src={organizationLogoUrl} alt={organizationName} />
        </div>
      </AccountOption>
    </div>

  render: ->
    { hasPersonalAccount, organizations } = @props

    <div className='organizations-container'>
      <h1>{@i18n 'select_workspace'}</h1>
      <div className="workspace-list">
        <div className='accounts-list'>
          {@props.organizations.map @renderAccountOption}
        </div>
        {unless mediator.services.switches.isServerSwitchOn 'organizationProjectsOnly'
          <PersonalAccountOption
            hasPersonalAccount={hasPersonalAccount}
            onGoToPersonalAccount={@goToProjectsList()}
            onSubscriptionSuccess={@props.onSubscriptionSuccess}
          />
        }
      </div>
    </div>

module.exports = ConnectStore Organizations, OrganizationsStore, storeConnector
