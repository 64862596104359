var MdaPublicationTable, MdaTopicsWorkflow, MdaTopicsWorkflowToolbar, MdaWorkflowPublicationActions, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

MdaPublicationTable = require('components/dissemination/mda_topics_workflow/mda_publication_table');

MdaTopicsWorkflowToolbar = require('components/dissemination/mda_topics_workflow/mda_topics_workflow_toolbar');

MdaWorkflowPublicationActions = require('actions/mda_workflow_publication_actions');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = MdaTopicsWorkflow = (function(_super) {
  __extends(MdaTopicsWorkflow, _super);

  function MdaTopicsWorkflow() {
    return MdaTopicsWorkflow.__super__.constructor.apply(this, arguments);
  }

  MdaTopicsWorkflow.prototype.container = '#page-container';

  MdaTopicsWorkflow.prototype.className = 'publication-platform-view';

  MdaTopicsWorkflow.prototype.autoRender = true;

  MdaTopicsWorkflow.prototype._stopInputsPropagation = false;

  MdaTopicsWorkflow.prototype.initialize = function() {
    MdaTopicsWorkflow.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    return this.subview('toolbar', new ReactToolbarView({
      component: MdaTopicsWorkflowToolbar
    }));
  };

  MdaTopicsWorkflow.prototype.afterRender = function() {
    var projectId;
    MdaTopicsWorkflow.__super__.afterRender.apply(this, arguments);
    projectId = mediator.project.id;
    return this.renderComponent(MdaPublicationTable, MdaWorkflowPublicationActions, null, {
      props: {
        projectId: projectId
      },
      fetchAndListen: {
        dbId: projectId
      }
    });
  };

  MdaTopicsWorkflow.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('QuestionsStore', 'QuestionGroupsStore', 'QuestionsListComponentStore', 'QuestionsStatusesStore', 'OrganizationsStore', 'OverarchingQuestionsStore', 'MdaWorkflowPublicationStore');
    return MdaTopicsWorkflow.__super__.dispose.apply(this, arguments);
  };

  return MdaTopicsWorkflow;

})(View);
