var NMA_OUTCOME_PREFIX, NMA_QUESTION_PREFIX, NmaInterventionType, NmaOutcomeDataType, NmaOutcomeDocShape, NmaQuestionDocShape, OUTCOME_QUALITIES, REGULAR_TO_NMA_QUALITY_MODIFIERS, TypeValidationError, convertOutcomeData, convertToNMA, generateGUID, getQualityComponentString, isNaN, sanitizeInt, sanitizeStr, throwIfInvalid, _ref;

generateGUID = require('base/lib/utils').generateGUID;

isNaN = require('lodash').isNaN;

_ref = require('lib/doc_prefixes'), NMA_OUTCOME_PREFIX = _ref.nmaOutcome, NMA_QUESTION_PREFIX = _ref.nmaQuestion;

NmaInterventionType = require('lib/db_docs/field_types/nma_intervention_type');

NmaOutcomeDataType = require('lib/db_docs/field_types/nma_outcome_data_type');

NmaOutcomeDocShape = require('lib/db_docs/doc_shapes/nma_outcome_doc_shape');

NmaQuestionDocShape = require('lib/db_docs/doc_shapes/nma_question_doc_shape');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

OUTCOME_QUALITIES = [void 0, 'Very Low', 'Low', 'Moderate', 'High'];

REGULAR_TO_NMA_QUALITY_MODIFIERS = {
  'ExtremelySerious': 'Extremely serious',
  'VerySerious': 'Very serious',
  'Serious': 'Serious',
  'None': 'Not serious',
  'No': 'No',
  'Yes': 'Yes',
  'Large': 'Large',
  'VeryLarge': 'Very large',
  'ReducedForRR': 'Would reduce demonstrated effect',
  'IncreasedForRR': 'Would suggest spurious effect',
  'publication_bias_None': 'Undetected',
  'publication_bias_Serious': 'Strongly suspected'
};

sanitizeInt = function(value) {
  var valueInt;
  if (value == null) {
    return;
  }
  if (value === '') {
    return;
  }
  valueInt = parseInt(value);
  if (isNaN(valueInt)) {
    return;
  }
  return valueInt;
};

sanitizeStr = function(value) {
  var trimmed;
  if (value == null) {
    return;
  }
  trimmed = value.trim();
  if (trimmed === '') {
    return;
  }
  return trimmed;
};

throwIfInvalid = function(value) {
  if (!value || value instanceof TypeValidationError) {
    throw new Error($.t('nma:converter.invalid_data'));
  }
};

getQualityComponentString = function(qualityComponent, prefix) {
  if (prefix == null) {
    prefix = '';
  }
  if (!(qualityComponent != null ? qualityComponent.label : void 0)) {
    return;
  }
  return REGULAR_TO_NMA_QUALITY_MODIFIERS["" + prefix + qualityComponent.name];
};

convertOutcomeData = function(outcome, interventionId, comparatorId, outcomeId) {
  var controlParticipants, interventionParticipants, totalParticipants, _ref1;
  interventionParticipants = sanitizeInt(outcome.interventionTotal);
  controlParticipants = sanitizeInt(outcome.controlTotal);
  totalParticipants = sanitizeInt(outcome.noOfPatients);
  if (!totalParticipants) {
    totalParticipants = interventionParticipants + controlParticipants;
  }
  return NmaOutcomeDataType.defaults({
    interventionId: interventionId,
    comparatorId: comparatorId,
    outcomeId: outcomeId,
    effectToUse: 'direct',
    directEffect: {
      effectMeasure: sanitizeStr(outcome.effectMeasure),
      effectMeasureOther: outcome.otherMeasure,
      certaintyAssessment: {
        overall: OUTCOME_QUALITIES[outcome.quality],
        rct: ((_ref1 = outcome.designStudies) != null ? _ref1.name : void 0) === 'RandTrials',
        riskOfBias: getQualityComponentString(outcome.riskOfBias),
        inconsistency: getQualityComponentString(outcome.inconsistency),
        indirectness: getQualityComponentString(outcome.indirectness),
        imprecision: getQualityComponentString(outcome.imprecision),
        publicationBias: getQualityComponentString(outcome.publicationBias, 'publication_bias_'),
        largeEffect: getQualityComponentString(outcome.largeEffect),
        plausibleConfounding: getQualityComponentString(outcome.plausibleConfounding),
        doseResponseGradient: getQualityComponentString(outcome.doseResponseGradient)
      },
      ciLevel: 0.95,
      value: outcome.effectSize,
      ciLow: outcome.confidenceIntervalFrom,
      ciHigh: outcome.confidenceIntervalTo,
      autoCalculateAbsoluteEffect: outcome.absEffectAutoCalc,
      absoluteEffectValue: outcome.absEffectSize,
      absoluteEffectCiLow: outcome.absEffectFrom,
      absoluteEffectCiHigh: outcome.absEffectTo,
      absoluteEffectCiLevel: 0.95
    },
    interventionData: {
      numberOfParticipants: sanitizeInt(outcome.interventionTotal),
      numberOfEvents: sanitizeInt(outcome.interventionCount)
    },
    comparisonData: {
      numberOfParticipants: sanitizeInt(outcome.controlTotal),
      numberOfEvents: sanitizeInt(outcome.controlCount)
    },
    numberOfStudies: sanitizeInt(outcome.noOfStudies),
    totalNumberOfParticipants: sanitizeInt(outcome.noOfPatients),
    interpretation: outcome.narrativeDesc || outcome.comments
  });
};

convertToNMA = function(question) {
  var comparatorId, interventionId, interventions, nmaQuestion, outcomeIds, outcomes, questionId, _ref1, _ref2;
  if (((_ref1 = question.type) != null ? _ref1 : '') !== '') {
    return;
  }
  questionId = "" + NMA_QUESTION_PREFIX + (generateGUID());
  interventions = _.chain([question.intervention, question.comparison]).map(function(intervention) {
    return intervention != null ? intervention.trim() : void 0;
  }).compact().map(function(intervention) {
    return NmaInterventionType.defaults({
      _id: md5(intervention),
      name: intervention
    });
  }).value();
  if (interventions.length !== 2) {
    return;
  }
  _ref2 = _.pluck(interventions, '_id'), interventionId = _ref2[0], comparatorId = _ref2[1];
  outcomes = _.chain(question.outcomes).compact().map(function(outcome) {
    var nmaOutcome, outcomeData, outcomeId, outcomeType;
    outcomeId = "" + NMA_OUTCOME_PREFIX + (generateGUID());
    outcomeData = convertOutcomeData(outcome, interventionId, comparatorId, outcomeId);
    throwIfInvalid(outcomeData);
    outcomeType = outcome.type;
    if (outcomeType === 'narrative') {
      outcomeType = 'dich';
    }
    nmaOutcome = NmaOutcomeDocShape.defaults({
      _id: outcomeId,
      name: outcome.name,
      type: outcomeType,
      questionId: questionId,
      data: [outcomeData]
    });
    throwIfInvalid(nmaOutcome);
    return nmaOutcome;
  }).value();
  outcomeIds = _.pluck(outcomes, '_id');
  nmaQuestion = NmaQuestionDocShape.defaults({
    _id: questionId,
    author: question.authors,
    interventions: interventions,
    outcomes: outcomeIds,
    population: question.healthProblemOrPopulation,
    question: question.question,
    setting: question.settings
  });
  throwIfInvalid(nmaQuestion);
  return {
    outcomes: outcomes,
    question: nmaQuestion
  };
};

module.exports = convertToNMA;
