var AddAdditionalSectionToEtdTemplate, Migration, W, addSectionToTemplate, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'add_additional_section_to_etd_template';

addSectionToTemplate = function(templateDef) {
  var alreadyHasSection, assessmentDef;
  assessmentDef = R.prop('assessment', templateDef);
  alreadyHasSection = R.find(R.propEq('id', 'additionalSection'))(assessmentDef);
  if (!alreadyHasSection) {
    templateDef = R.assoc('assessment', assessmentDef.concat({
      id: 'additionalSection',
      checked: false
    }), templateDef);
  }
  return templateDef;
};

module.exports = AddAdditionalSectionToEtdTemplate = (function(_super) {
  __extends(AddAdditionalSectionToEtdTemplate, _super);

  function AddAdditionalSectionToEtdTemplate() {
    AddAdditionalSectionToEtdTemplate.__super__.constructor.call(this, false);
  }

  AddAdditionalSectionToEtdTemplate.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    AddAdditionalSectionToEtdTemplate.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds')).concat(q.get('recommendationAdolopmentIds', []));
    }, []);
    docIds = docIds.concat(['etd-tx-template', 'etd-dx-template', 'etd-overarchingDx-template', 'etd-overarchingTx-template']);
    return adapter.fetch(projectId, _.compact(_.uniq(docIds))).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return W.resolve();
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        doc.templateDef = addSectionToTemplate(R.prop('templateDef', doc));
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return AddAdditionalSectionToEtdTemplate;

})(Migration);
