var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.viewTypeName : depth0), "OneRow", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.colNo : depth0), "{\"className\": \"control\", \"dataProperty\": \"control\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.colNo : depth0), "{\"className\": \"control\", \"dataProperty\": \"control\",\n      \"messageKey\": \"see_comment\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <td "
    + escapeExpression(((helper = (helper = helpers.colNo || (depth0 != null ? depth0.colNo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"colNo","hash":{},"data":data}) : helper)))
    + " data-property=\"control\" class=\"control lightgray-cell\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"is","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "dich", {"name":"is","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </td>\n";
},"7":function(depth0,helpers,partials,data) {
  return "      <div class=\"inner-cell\">\n        <div class=\"cell-content main-content\">\n          <span></span>\n        </div>\n      </div>\n";
  },"9":function(depth0,helpers,partials,data) {
  return "        <div class=\"inner-cell\">\n          <div class=\"cell-content main-content\">\n            <span></span>\n          </div>\n        </div>\n\n        <div class=\"inner-cell\">\n          <div class=\"cell-content low-value\">\n            <span></span>\n          </div>\n        </div>\n\n        <div class=\"inner-cell\">\n          <div class=\"cell-content moderate-value\">\n            <span></span>\n          </div>\n        </div>\n\n        <div class=\"inner-cell\">\n          <div class=\"cell-content high-value\">\n            <span></span>\n          </div>\n        </div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "NOT_MEASURED, NOT_REPORTED", (depth0 != null ? depth0.metaType : depth0), {"name":"inStringArray","hash":{},"fn":this.program(1, data),"inverse":this.program(6, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}