var InsertedGdtTable;

InsertedGdtTable = require('components/evidence_syntheses/inserted_gdt_table');

module.exports = {
  'GDT_TABLE': function(Editor) {
    return {
      component: InsertedGdtTable,
      editable: false,
      props: {
        isEditing: false,
        renderMode: 'printout'
      }
    };
  }
};
