ProjectLabelForm = require 'components/projects/project_label_form'
Popover = require 'components/common/popover'
ProjectsActions = require 'actions/projects_actions'


ProjectLabelPopup = createReactClass
  displayName: 'ProjectLabelPopup'

  propTypes:
    projectId: PropTypes.string.isRequired,
    projectLabel: PropTypes.instanceOf(Immutable.Map),
    projectLabelId: PropTypes.string,
    labelsData: PropTypes.instanceOf(Immutable.Map).isRequired,

  getInitialState: ->
    displayPopover: false

  togglePoppover: ->
    @setState displayPopover: not @state.displayPopover

  onChangeLabel: (labelId) ->
    ProjectsActions.changeProjectLabel @props.projectId, labelId

  onChangeLabelText: (labelsData) ->
    ProjectsActions.updateUserLabels labelsData.toJS()

  render: ->
    <Popover
      onRequestClose={@togglePoppover}
      visible={@state.displayPopover}
      positionParams={position: 'right'}
    >
      <div
        className='project-label'
        style={{background: @props.projectLabel?.get('color')}}
        onClick={@togglePoppover}
      />
      <div className="project-label__container">
        <ProjectLabelForm
          labelsData={@props.labelsData}
          projectLabelId={@props.projectLabelId}
          onChangeLabel={@onChangeLabel}
          onChangeLabelText={@onChangeLabelText}
          hideTooltip={@togglePoppover}
        />
      </div>
    </Popover>

module.exports = ProjectLabelPopup
