var Printout, alt, createPrintoutContainer, exportToFile, mediator, removeHTMLNode, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

mediator = require('mediator');

exportToFile = require('lib/export_to_file').exportToFile;

Printout = MDGTables.Printout;

_ref = require('base/lib/utils'), removeHTMLNode = _ref.removeHTMLNode, createPrintoutContainer = _ref.createPrintoutContainer;

_.namespace(module, function() {
  return {
    "export": function(i18n, question, studiesForExport, references, exportFormat) {
      var $printout, fakeDoc, meta, printoutContainer, printoutDocHTML, studies;
      printoutContainer = createPrintoutContainer();
      studies = question.get('studies').filter(function(study) {
        var _ref1;
        return _ref1 = study.get('id'), __indexOf.call(studiesForExport, _ref1) >= 0;
      });
      ReactDOM.render(React.createElement(Printout, {
        type: question.get('type'),
        i18n: function(key, params, componentParams) {
          return i18n("/mdg_tables:tables." + key, params, componentParams);
        },
        references: references.toJS(),
        studies: studies.toJS()
      }), printoutContainer);
      $printout = printoutContainer.querySelector('#mdg-printout');
      fakeDoc = document.implementation.createHTMLDocument('');
      fakeDoc.documentElement.setAttribute('xmlns:v', 'urn:schemas-microsoft-com:vml');
      fakeDoc.documentElement.setAttribute('xmlns:o', 'urn:schemas-microsoft-com:office:office');
      fakeDoc.documentElement.setAttribute('xmlns:w', 'urn:schemas-microsoft-com:office:word');
      fakeDoc.documentElement.setAttribute('xmlns:m', 'http://schemas.microsoft.com/office/2004/12/omml');
      fakeDoc.documentElement.setAttribute('xmlns', 'http://www.w3.org/TR/REC-html40');
      meta = document.createElement('meta');
      meta.setAttribute("charset", "UTF-8");
      meta.setAttribute("http-equiv", "X-UA-Compatible");
      meta.setAttribute("content", "IE=edge,chrome=1");
      fakeDoc.head.append(meta);
      fakeDoc.body.innerHTML = $printout.outerHTML;
      printoutDocHTML = '<!doctype html>' + fakeDoc.documentElement.outerHTML;
      return exportToFile(exportFormat, printoutDocHTML, {
        fileName: question.get('question').substring(0, 100),
        orientation: 'portrait'
      }).then(function() {
        return mediator.dialogs.success({
          message: i18n('/projects:export_dialog.table_export_successful', {
            'tablename': question.get('question')
          }),
          ms: 10000,
          closeBtn: true
        });
      })["catch"](function(reason) {
        if (reason !== 'cancelled') {
          return mediator.dialogs.error(reason);
        }
      })["finally"](function() {
        return removeHTMLNode(printoutContainer);
      });
    },
    openInNewWindow: function(i18n, question) {
      var $printout, el, printoutContainer, references, tableContainer, tableWindowRef, title;
      printoutContainer = createPrintoutContainer();
      references = alt.stores.ReferencesStore.getReferences();
      ReactDOM.render(React.createElement(Printout, {
        type: question.get('type'),
        i18n: function(key, params, componentParams) {
          return i18n("/mdg_tables:tables." + key, params, componentParams);
        },
        references: references.toJS(),
        studies: question.get('studies').toJS()
      }), printoutContainer);
      $printout = printoutContainer.querySelector('#mdg-printout');
      tableWindowRef = window.open('about:blank', "_blank", 'resizable=yes, scrollable=yes, status=yes, menubar=no');
      if (tableWindowRef) {
        tableWindowRef.opener = null;
        el = document.createElement('div');
        title = document.createElement('h3');
        title.innerHTML = question.get('question');
        tableContainer = document.createElement('div');
        tableContainer.innerHTML = $printout.innerHTML;
        el.appendChild(title);
        el.appendChild(tableContainer);
        return tableWindowRef.document.body.appendChild(el);
      }
    }
  };
});
