ApprovalDetails = require 'components/scope/questions/approval_details'
CommentsBlock = require 'components/scope/comments_block'
CommentsBlocks = require 'components/scope/questions/grouped_comments_blocks'
{ QUESTION_GENERATION_STEPS } = require 'lib/questions_helper'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

QuestionApprovalDetails = createReactClass
  displayName: 'QuestionApprovalDetails'

  propTypes:
    question: PropTypes.instanceOf(Immutable.Map).isRequired
    onApplyAdminComment: PropTypes.func.isRequired
    membersMap: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:questions')
  ]

  onApplyAdminComment: (newText) ->
    onApplyAdminComment = @props.onApplyAdminComment QUESTION_GENERATION_STEPS[5]
    onApplyAdminComment {newText, id: @props.question.get 'id'}

  render: ->
    { question } = @props
    approvalsCount = question.get('approvals').size
    originalPriority = question.get 'approvalStatusOriginal', question.get 'approvalStatus'
    approvalComments = question.getIn ['memberComments', QUESTION_GENERATION_STEPS[5]],
      Immutable.Map()
    approvalAdminComment = question.getIn ['adminComments', QUESTION_GENERATION_STEPS[5]]
    previousStepsComments = question.get('memberComments', Immutable.Map())
      .filterNot (comments, stepName) -> stepName is QUESTION_GENERATION_STEPS[5]

    <div className='question-details'>
      <div className='step-name'>
        {@i18n '../outcomes.approval_phase'}
      </div>
      <div>
        <ApprovalDetails
          approvals={@props.question.get 'approvals'}
          originalPriority={originalPriority}
          groupType={@props.groupType}
        />
        <CommentsBlock
          membersComments={approvalComments}
          membersMap={@props.membersMap}
          adminComment={approvalAdminComment}
          onApplyAdminComment={@onApplyAdminComment}
          readOnlyComments={@props.readOnly}
        />
        <CommentsBlocks
          itemIdOrKey={@props.question.get 'id'}
          membersComments={previousStepsComments}
          adminComments={@props.question.get 'adminComments'}
          membersMap={@props.membersMap}
          noCollapsePhase={QUESTION_GENERATION_STEPS[5]}
          onApplyAdminComment={@props.onApplyAdminComment}
          readOnly={@props.readOnly}
        />
      </div>
    </div>

module.exports = QuestionApprovalDetails
