var ImageInsertion, MutationsObserver, Set, URL, ViewTrait, W, adapter,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('base/lib/traits/view_trait');

adapter = require('lib/pouch_adapter');

Set = require('base/lib/set');

W = require('when');

MutationsObserver = window.MutationObserver || window.WebKitMutationObserver;

URL = window.URL || window.webkitURL;

module.exports = ImageInsertion = (function(_super) {
  __extends(ImageInsertion, _super);

  function ImageInsertion() {
    return ImageInsertion.__super__.constructor.apply(this, arguments);
  }

  ImageInsertion.prototype.container = null;

  ImageInsertion.prototype.dataAttr = 'db-image';

  ImageInsertion.prototype.scheduledActions = {};

  ImageInsertion.prototype.apply = function(view) {
    ImageInsertion.__super__.apply.apply(this, arguments);
    if (!this.container) {
      throw new Error('Image container element must be defind');
    }
    if (!(_(this.container).isString() || this.container instanceof HTMLElement)) {
      throw new Error('Invalid container provided');
    }
    this._addFunction(view, 'saveImage');
    this._addFunction(view, 'stopObservations');
    this._addFunction(view, 'runDelayedActions');
    this._addFunction(view, 'flushDelayedActions');
    this.addImagesSrc(view);
    return this.startImageObserving(view);
  };

  ImageInsertion.prototype.startImageObserving = function(view) {
    var config, target;
    target = _(this.container).isString() ? view.el.querySelector(this.container) : this.container;
    config = {
      childList: true,
      subtree: true
    };
    this.observer = new MutationObserver((function(_this) {
      return function(mutations) {
        return _(mutations).forEach(function(mutation) {
          var addedImages, removedImages;
          removedImages = _this._filterImages(mutation.removedNodes);
          addedImages = _this._filterImages(mutation.addedNodes);
          if (!_(removedImages).isEmpty() && !_(addedImages).isEmpty()) {
            removedImages = _this._uniqueNodes(removedImages, addedImages);
          }
          _this._removeImages(removedImages, view.model);
          return _this.addImagesSrc(view, addedImages);
        });
      };
    })(this));
    return this.observer.observe(target, config);
  };

  ImageInsertion.prototype.addImagesSrc = function(view, imagesList) {
    var imageNamesByNodes;
    if (imagesList == null) {
      imagesList = null;
    }
    imagesList = imagesList || (function(_this) {
      return function() {
        var allImages;
        allImages = view.$(_this.container).find("img[" + _this.dataAttr + "]");
        return imagesList = _(allImages).filter(function(img) {
          return !img.src;
        });
      };
    })(this)();
    if (_(imagesList).isEmpty()) {
      return;
    }
    imageNamesByNodes = _(imagesList).groupBy((function(_this) {
      return function(node) {
        return node.getAttribute(_this.dataAttr);
      };
    })(this));
    return _(imageNamesByNodes).keys().forEach((function(_this) {
      return function(imageName) {
        var imageNodes;
        imageNodes = imageNamesByNodes[imageName];
        return _this._addImageSrc(imageName, imageNodes, view.model);
      };
    })(this));
  };

  ImageInsertion.prototype._addImageSrc = function(imageName, imageNodes, model) {
    return adapter.getAttachment(model, imageName).then((function(_this) {
      return function(imageBlob) {
        return _this._transformToDataURL(imageBlob);
      };
    })(this)).then(function(imageDataURI) {
      return imageNodes.forEach(function(imageNode) {
        return imageNode.src = imageDataURI;
      });
    })["catch"](function(e) {
      return console.log(e);
    });
  };

  ImageInsertion.prototype._transformToDataURL = function(imageBlob) {
    return W.promise(function(resolve, reject) {
      var reader;
      reader = new FileReader;
      reader.onload = function(e) {
        var imageDataURI;
        imageDataURI = e.target.result;
        return resolve(imageDataURI);
      };
      reader.onerror = function(e) {
        return reject(e.target.error);
      };
      return reader.readAsDataURL(imageBlob);
    });
  };

  ImageInsertion.prototype._filterImages = function(nodesList, result) {
    var imageNodes, otherNodes;
    if (result == null) {
      result = [];
    }
    if (!nodesList.length) {
      return result;
    }
    imageNodes = _(nodesList).filter(this._isImageNode);
    otherNodes = _(nodesList).chain().filter((function(_this) {
      return function(node) {
        return !_this._isImageNode(node);
      };
    })(this)).map(function(node) {
      return _.toArray(node.childNodes);
    }).flatten().compact().value();
    result = _.union(result, imageNodes);
    return this._filterImages(otherNodes, result);
  };

  ImageInsertion.prototype._removeImages = function(imageNodes, model) {
    var imageNames;
    if (_(imageNodes).isEmpty()) {
      return;
    }
    imageNames = _(imageNodes).chain().groupBy((function(_this) {
      return function(node) {
        return node.getAttribute(_this.dataAttr);
      };
    })(this)).keys().value();
    return imageNames.forEach((function(_this) {
      return function(imageName) {
        return _this.scheduledActions['_removeImage'] = [imageName, model];
      };
    })(this));
  };

  ImageInsertion.prototype._removeImage = function(imageName, model) {
    return adapter.removeAttachment(model, imageName);
  };

  ImageInsertion.prototype.saveImage = function(originalFunction, trait, imageBlob, options) {
    var attachmentName, dbAttr, fileName;
    dbAttr = options.dbAttr;
    fileName = imageBlob.name || options.fileName;
    if (!fileName) {
      throw new Error("Image file name is not defined!");
    }
    attachmentName = "" + dbAttr + "/" + fileName;
    return adapter.attach(this.model, attachmentName, imageBlob).then((function(_this) {
      return function() {
        var img;
        img = new Image;
        img.style.width = "100%";
        img.setAttribute(trait.dataAttr, attachmentName);
        return _this._updateContentEditable(img);
      };
    })(this));
  };

  ImageInsertion.prototype.stopObservations = function(originalFunction, trait) {
    trait.observer.disconnect();
    trait = null;
    return this;
  };

  ImageInsertion.prototype.runDelayedActions = function(originalFunction, trait) {
    var action, paramsArray, _ref, _results;
    if (_(trait.scheduledActions).isEmpty()) {
      return;
    }
    _ref = trait.scheduledActions;
    _results = [];
    for (action in _ref) {
      paramsArray = _ref[action];
      _results.push(trait[action].apply(trait, paramsArray));
    }
    return _results;
  };

  ImageInsertion.prototype.flushDelayedActions = function(originalFunction, trait) {
    return trait.scheduledActions = {};
  };

  ImageInsertion.prototype._isImageNode = function(node) {
    return node.localName === 'img';
  };

  ImageInsertion.prototype._uniqueNodes = function(nodesArrayA, nodesArrayB) {
    var dataAttrValues, node, _i, _len, _results;
    dataAttrValues = new Set(_((function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = nodesArrayB.length; _i < _len; _i++) {
        node = nodesArrayB[_i];
        _results.push(node.getAttribute(this.dataAttr));
      }
      return _results;
    }).call(this)).compact());
    if (!dataAttrValues.contains(node.getAttribute(this.dataAttr))) {
      _results = [];
      for (_i = 0, _len = nodesArrayA.length; _i < _len; _i++) {
        node = nodesArrayA[_i];
        _results.push(node);
      }
      return _results;
    }
  };

  return ImageInsertion;

})(ViewTrait);
