EllipsizedText = require 'components/common/ellipsized_text'
OverarchingQuestionsActions = require 'actions/overarching_questions_actions'

ToolbarOverarchingQuestion = createReactClass
  displayName: 'ToolbarOverarchingQuestion'

  propTypes:
    id: PropTypes.string.isRequired
    question: PropTypes.string.isRequired
    additionalProps: PropTypes.shape
      collapse: PropTypes.func

  getDefaultProps: ->
    additionalProps: {}

  onClick: ->
    { collapse } = @props.additionalProps
    OverarchingQuestionsActions.goToOverarchingRecommendations @props.id
    collapse?()

  render: ->
    { question, id } = @props
    <div className="toolbar-overarching-question">
      <div className="question-text">
        <a onClick={@onClick}>
          <EllipsizedText height={40} text={question} />
        </a>
      </div>
    </div>

module.exports = ToolbarOverarchingQuestion
