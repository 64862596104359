var ManagementQuestionExporter, QuestionExporter, formatter, statisticalUtils, utils, _controlRisksNames, _effectMeasures, _metaTypes, _outcomeTypes, _studyDesignTypes, _unitCodes,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

QuestionExporter = require('./question_exporter');

utils = require('./exporter_utils');

formatter = require('lib/formatters').outcome;

statisticalUtils = require('lib/statistical_utils');

_unitCodes = {
  'weeks': 'WEE',
  'years': 'ANN',
  'months': 'MON',
  'days': 'DAY',
  'patientYears': 'dbep:PTY',
  'others': 'dbep:OTH'
};

_outcomeTypes = {
  'narrative': 'NarrativeSummary',
  'dich': 'DichotomousData',
  'cont': 'ContinuousData',
  'time_to_event': 'DichotomousData'
};

_metaTypes = {
  'POOLED': 'Pooled',
  'SINGLE_STUDY': 'SingleStudy',
  'NOT_POOLED': 'NotPooled',
  'RANGE_OF_EFFECTS': 'RangeOfEffects',
  'NOT_REPORTED': 'NotReported',
  'NOT_MEASURED': 'NotMeasured'
};

_controlRisksNames = {
  'lowControl': 'LowControlRisk',
  'moderateControl': 'ModerateControlRisk',
  'highControl': 'HighControlRisk'
};

_effectMeasures = {
  'RR': 'RiskRatio',
  'HR': 'HazardRatio',
  'OR': 'OddsRatio',
  'other': 'OtherEffectMeasure',
  'rateRatio': 'RateRatio',
  'MD': 'MeanDifference',
  'SMD': 'StandardizedMeanDifference',
  'median': 'Median',
  'mean': 'Mean'
};

_studyDesignTypes = {
  'RandTrials': 'RandomisedTrials',
  'ObsStudies.interrupted_time_series': 'ObservationalStudiesInterruptedTimeSeries',
  'ObsStudies.before_after_studies': 'ObservationalStudiesBeforeAfter',
  'ObsStudies.cohort_studies': 'ObservationalStudiesCohort',
  'ObsStudies.case_control_studies': 'ObservationalStudiesCaseControl',
  'ObsStudies.cross_sectional_studies': 'ObservationalStudiesCrossSectional',
  'ObsStudies.case_series': 'ObservationalStudiesCaseSeries',
  'ObsStudies.case_reports': 'ObservationalStudiesCaseReports',
  'ObsStudies.other_design': 'ObservationalStudiesOtherDesign',
  'ObsStudies.case_control_and_other_combined': 'ObservationalStudiesCaseControlAndOtherCombined'
};

module.exports = ManagementQuestionExporter = (function(_super) {
  __extends(ManagementQuestionExporter, _super);

  function ManagementQuestionExporter(_originalQuestion, _referencesExporter) {
    this.exportEvidenceSummary = __bind(this.exportEvidenceSummary, this);
    this._exportEffectSummaryAndControlRisks = __bind(this._exportEffectSummaryAndControlRisks, this);
    ManagementQuestionExporter.__super__.constructor.call(this, _originalQuestion, 'management', _referencesExporter);
    this._currentCRId = 1;
  }

  ManagementQuestionExporter.prototype._exportPatientGroup = function(outcome) {
    var caseControlPatientGroup, controlGroup, interventionGroup, patientGroup, _ref, _ref1;
    if (outcome['type'] === 'narrative') {
      return [];
    }
    interventionGroup = {
      '@type': 'InterventionGroup',
      'totalCount': this.getValueAndFootnotes(outcome, 'interventionTotal')
    };
    if ((outcome['interventionCount'] != null) && outcome['interventionCount'] !== '') {
      interventionGroup['withOutcome'] = this.getValueAndFootnotes(outcome, 'interventionCount');
    }
    controlGroup = {
      '@type': 'ControlGroup',
      'totalCount': this.getValueAndFootnotes(outcome, 'controlTotal')
    };
    if ((outcome['controlCount'] != null) && outcome['controlCount'] !== '') {
      controlGroup['withOutcome'] = this.getValueAndFootnotes(outcome, 'controlCount');
    }
    patientGroup = [];
    if (interventionGroup['totalCount']['value'] !== '') {
      patientGroup.push(interventionGroup);
    }
    if (controlGroup['totalCount']['value'] !== '') {
      patientGroup.push(controlGroup);
    }
    if ((_ref = (_ref1 = outcome['designStudies']) != null ? _ref1['fullName'] : void 0) === 'ObsStudies.case_control_studies' || _ref === 'ObsStudies.case_control_and_other_combined') {
      caseControlPatientGroup = {
        '@type': 'CaseControlGroup',
        'cases': this.getValueAndFootnotes(outcome, 'withOutcome'),
        'controls': this.getValueAndFootnotes(outcome, 'withoutOutcome')
      };
      patientGroup.push(caseControlPatientGroup);
    }
    return patientGroup;
  };

  ManagementQuestionExporter.prototype._followUpUnitCode = function(unit) {
    if (unit in _unitCodes) {
      return _unitCodes[unit];
    } else {
      return "";
    }
  };

  ManagementQuestionExporter.prototype._followUpUnitText = function(unit, otherUnit) {
    var unitText;
    unitText = unit !== 'others' ? unit : otherUnit;
    if (unitText !== 'patientYears') {
      return unitText;
    } else {
      return 'patient years';
    }
  };

  ManagementQuestionExporter.prototype._exportFollowUp = function(outcome) {
    var followUp, followUpFootnotes, followUpType, lowerUnitCode, lowerUnitText, lowerValue, name, text, toText, type, unitCode, unitText, upperUnitCode, upperUnitText, upperValue, value, _ref, _ref1, _ref2, _ref3, _ref4, _ref5;
    followUpType = outcome['followUpType'];
    followUpFootnotes = this.findFootnotes(outcome['footnotes'], outcome['references'], 'followUpLength', 0);
    if (followUpType === 'range') {
      lowerUnitCode = this._followUpUnitCode((_ref = outcome['followUpTimeUnit']) != null ? _ref : '');
      upperUnitCode = this._followUpUnitCode((_ref1 = outcome['followUpToTimeUnit']) != null ? _ref1 : '');
      lowerUnitText = (_ref2 = this._followUpUnitText(outcome['followUpTimeUnit'], outcome['othersFollowUpTimeUnit'])) != null ? _ref2 : '';
      upperUnitText = (_ref3 = this._followUpUnitText(outcome['followUpToTimeUnit'], outcome['othersFollowUpToTimeUnit'])) != null ? _ref3 : '';
      lowerValue = outcome['followUpLength'].toString();
      upperValue = outcome['followUpLengthTo'].toString();
      toText = $.t('es:outcome.to');
      text = "" + lowerValue + " " + lowerUnitText + " " + toText + " " + upperValue + " " + upperUnitText;
      followUp = {
        '@type': 'RangeFollowUp',
        'text': text,
        'lowerBound': {
          '@type': 'QuantitativeValue',
          'value': lowerValue,
          'unitCode': lowerUnitCode,
          'unitText': lowerUnitText
        },
        'upperBound': {
          '@type': 'QuantitativeValue',
          'value': upperValue,
          'unitCode': upperUnitCode,
          'unitText': upperUnitText
        }
      };
      if (followUpFootnotes.length > 0) {
        followUp['footnote'] = followUpFootnotes;
      }
      return followUp;
    } else if (followUpType === 'mean' || followUpType === 'median') {
      unitCode = this._followUpUnitCode((_ref4 = outcome['followUpTimeUnit']) != null ? _ref4 : '');
      unitText = (_ref5 = this._followUpUnitText(outcome['followUpTimeUnit'], outcome['othersFollowUpTimeUnit'])) != null ? _ref5 : '';
      value = outcome['followUpLength'].toString();
      name = "" + value + " " + unitText;
      type = followUpType === 'mean' ? 'MeanFollowUp' : 'MedianFollowUp';
      followUp = {
        '@type': [type, 'QuantitativeValue'],
        'name': name,
        'value': value,
        'unitCode': unitCode,
        'unitText': unitText
      };
      if (followUpFootnotes.length > 0) {
        followUp['footnote'] = followUpFootnotes;
      }
      return followUp;
    }
  };

  ManagementQuestionExporter.prototype._exportEffectMeasure = function(outcome) {
    var effectMeasure;
    effectMeasure = outcome['effectMeasure'];
    if (effectMeasure !== 'other') {
      if (effectMeasure in _effectMeasures) {
        effectMeasure = _effectMeasures[effectMeasure];
      }
      return [effectMeasure];
    } else {
      return ['OtherEffectMeasure', outcome['otherMeasure']];
    }
  };

  ManagementQuestionExporter.prototype._exportEffectSummaryType = function(outcome) {
    if (outcome['metaType'] in _metaTypes) {
      return _metaTypes[outcome['metaType']];
    } else {
      return null;
    }
  };

  ManagementQuestionExporter.prototype._getStudyDesign = function(outcome) {
    var _ref, _ref1, _ref2, _ref3;
    return (_ref = (_ref1 = (_ref2 = outcome['designStudies']) != null ? _ref2['fullName'] : void 0) != null ? _ref1 : (_ref3 = outcome['designStudies']) != null ? _ref3['name'] : void 0) != null ? _ref : 'Unknown';
  };

  ManagementQuestionExporter.prototype._exportStudyDesignType = function(outcome) {
    var studyDesignTypeLabel;
    studyDesignTypeLabel = this._getStudyDesign(outcome);
    if (studyDesignTypeLabel in _studyDesignTypes) {
      return _studyDesignTypes[studyDesignTypeLabel];
    } else {
      return 'UnknownStudyDesign';
    }
  };

  ManagementQuestionExporter.prototype._exportStudyDesignName = function(outcome) {
    var obsStudiesName, studiesName, studyDesignTypeLabel, studyLabel;
    studyDesignTypeLabel = this._getStudyDesign(outcome);
    if (studyDesignTypeLabel === 'RandTrials') {
      return $.t('es:outcome.randomised_trials_plural');
    } else {
      studyLabel = studyDesignTypeLabel.slice('ObsStudies.'.length);
      obsStudiesName = $.t('es:outcome.observational_studies_plural');
      studiesName = $.t("es:outcome." + studyLabel);
      return "" + obsStudiesName + " (" + studiesName + ")";
    }
  };

  ManagementQuestionExporter.prototype._exportCalculatedControlEffectSummaryAndControlRisk = function(id, outcome, controlRisks, exportAbsoluteEffect, absoluteEffects) {
    var absoluteEffect, absoluteEffectAutoCalcFootnotes, absoluteEffectSizeFootnotes, absoluteEffectToFootnotes, controlRiskFootnotes, controlRiskValue, cr, effectTypePrefix, explanations, references, _ref;
    explanations = outcome['footnotes'];
    references = outcome['references'];
    controlRiskFootnotes = this.findFootnotes(explanations, references, 'calculatedControlValue', 0);
    controlRiskValue = (_ref = outcome['calculatedControlValue']) != null ? _ref : utils.calculatedControlPercentage(outcome);
    cr = {
      '@id': '_:cr' + id,
      '@type': 'CalculatedControlRisk',
      'value': controlRiskValue
    };
    if (controlRiskFootnotes.length > 0) {
      cr['footnote'] = controlRiskFootnotes;
    }
    controlRisks.push(cr);
    effectTypePrefix = outcome['absEffectAutoCalc'] ? 'AutoCalculated' : 'Custom';
    if (exportAbsoluteEffect) {
      absoluteEffectAutoCalcFootnotes = this.findFootnotes(explanations, references, 'absEffectAutoCalc', 0);
      absoluteEffectSizeFootnotes = this.findFootnotes(explanations, references, 'absEffectSize', 0);
      absoluteEffectToFootnotes = this.findFootnotes(explanations, references, 'absEffectTo', 0);
      absoluteEffect = {
        '@type': "" + effectTypePrefix + "AbsoluteEffect",
        'forControlRisk': {
          '@id': cr['@id']
        },
        'value': {
          'value': -parseFloat(outcome['absEffectSize'])
        },
        'confidenceLevel': {
          'value': statisticalUtils.confidenceLevel
        },
        'confidenceIntervalFrom': -parseFloat(outcome['absEffectFrom']),
        'confidenceIntervalTo': -parseFloat(outcome['absEffectTo']),
        'denominator': outcome['absDenominator']
      };
      if (absoluteEffectAutoCalcFootnotes.length > 0) {
        absoluteEffect['footnoteForType'] = absoluteEffectAutoCalcFootnotes;
      }
      if (absoluteEffectSizeFootnotes.length > 0) {
        absoluteEffect['value']['footnote'] = absoluteEffectSizeFootnotes;
      }
      if (absoluteEffectToFootnotes.length > 0) {
        absoluteEffect['confidenceLevel']['footnote'] = absoluteEffectToFootnotes;
      }
      return absoluteEffects.push(absoluteEffect);
    }
  };

  ManagementQuestionExporter.prototype._exportControlRisksAndAbsoluteEffect = function(id, field, outcome, controlRisks, exportAbsoluteEffect, absoluteEffects) {
    var absoluteEffect, absoluteEffectAutoCalcFootnotes, absoluteEffectSizeFootnotes, absoluteEffectToFootnotes, controlRiskFootnotes, cr, effectSizeFieldSuffix, effectTypePrefix, explanations, references;
    explanations = outcome['footnotes'];
    references = outcome['references'];
    controlRiskFootnotes = this.findFootnotes(explanations, references, "" + field + "Risk", 0);
    cr = {
      '@id': '_:cr' + id,
      '@type': _controlRisksNames[field],
      'value': outcome["" + field + "Risk"]
    };
    if (controlRiskFootnotes.length > 0) {
      cr['footnote'] = controlRiskFootnotes;
    }
    controlRisks.push(cr);
    if (exportAbsoluteEffect) {
      effectSizeFieldSuffix = utils.capitalizeFirstLetter(field.slice(0, -'Control'.length));
      absoluteEffectAutoCalcFootnotes = this.findFootnotes(explanations, references, "absEffectAutoCalc" + effectSizeFieldSuffix, 0);
      absoluteEffectSizeFootnotes = this.findFootnotes(explanations, references, "absEffectSize" + effectSizeFieldSuffix, 0);
      absoluteEffectToFootnotes = this.findFootnotes(explanations, references, "absEffectTo" + effectSizeFieldSuffix, 0);
      effectTypePrefix = outcome["absEffectAutoCalc" + effectSizeFieldSuffix] ? 'AutoCalculated' : 'Custom';
      absoluteEffect = {
        '@type': "" + effectTypePrefix + "AbsoluteEffect",
        'forControlRisk': {
          '@id': cr['@id']
        },
        'value': {
          'value': -parseFloat(outcome["absEffectSize" + effectSizeFieldSuffix])
        },
        'confidenceLevel': {
          'value': statisticalUtils.confidenceLevel
        },
        'confidenceIntervalFrom': -parseFloat(outcome["absEffectFrom" + effectSizeFieldSuffix]),
        'confidenceIntervalTo': -parseFloat(outcome["absEffectTo" + effectSizeFieldSuffix]),
        'denominator': outcome['absDenominator']
      };
      if (absoluteEffectAutoCalcFootnotes.length > 0) {
        absoluteEffect['footnoteForType'] = absoluteEffectAutoCalcFootnotes;
      }
      if (absoluteEffectSizeFootnotes.length > 0) {
        absoluteEffect['value']['footnote'] = absoluteEffectSizeFootnotes;
      }
      if (absoluteEffectToFootnotes.length > 0) {
        absoluteEffect['confidenceLevel']['footnote'] = absoluteEffectToFootnotes;
      }
      return absoluteEffects.push(absoluteEffect);
    }
  };

  ManagementQuestionExporter.prototype._exportRelativeEffect = function(outcome, effectMeasure) {
    var explanations, references, relativeEffect, relativeEffectCIFootnotes, relativeEffectSizeFootnotes, relativeEffectValue, relativeEffectValueHTML;
    explanations = outcome['footnotes'];
    references = outcome['references'];
    relativeEffectSizeFootnotes = this.findFootnotes(explanations, references, 'effectSize', 0);
    relativeEffectCIFootnotes = this.findFootnotes(explanations, references, 'confidenceIntervalTo', 0);
    relativeEffect = {
      '@type': effectMeasure
    };
    if (outcome['metaType'] === 'RANGE_OF_EFFECTS') {
      relativeEffectValueHTML = formatter.relativeEffect(outcome);
      relativeEffectValue = relativeEffectValueHTML.replace(/<\/?[^>]+>/g, '');
      relativeEffect['value'] = {
        'value': relativeEffectValue
      };
      if (relativeEffectSizeFootnotes.length > 0) {
        relativeEffect['footnoteForType'] = relativeEffectSizeFootnotes;
      }
      if (relativeEffectCIFootnotes.length > 0) {
        relativeEffect['value']['footnote'] = relativeEffectCIFootnotes;
      }
      relativeEffect['lowerBound'] = outcome['confidenceIntervalFrom'];
      relativeEffect['upperBound'] = outcome['confidenceIntervalTo'];
    } else {
      relativeEffect['value'] = {
        'value': outcome['effectSize']
      };
      if (relativeEffectSizeFootnotes.length > 0) {
        relativeEffect['value']['footnote'] = relativeEffectSizeFootnotes;
      }
      relativeEffect['confidenceLevel'] = {
        'value': statisticalUtils.confidenceLevel
      };
      if (relativeEffectCIFootnotes.length > 0) {
        relativeEffect['confidenceLevel']['footnote'] = relativeEffectCIFootnotes;
      }
      relativeEffect['confidenceIntervalFrom'] = outcome['confidenceIntervalFrom'];
      relativeEffect['confidenceIntervalTo'] = outcome['confidenceIntervalTo'];
    }
    return relativeEffect;
  };

  ManagementQuestionExporter.prototype._exportDichotomousEffectSummaryAndControlRisks = function(outcome) {
    var absoluteEffect, absoluteEffects, controlRisks, controlRisksFields, effectMeasure, effectSummary, exportAbsoluteEffect, field, id, otherMeasure, relativeEffect, summaryType, _i, _j, _len, _len1, _ref, _ref1, _ref2;
    summaryType = this._exportEffectSummaryType(outcome);
    if (outcome['metaType'] === 'NOT_POOLED' || ((_ref = (_ref1 = outcome['designStudies']) != null ? _ref1['fullName'] : void 0) === 'ObsStudies.case_series' || _ref === 'ObsStudies.case_reports')) {
      effectSummary = {
        '@type': summaryType
      };
      return [effectSummary];
    } else {
      exportAbsoluteEffect = outcome['metaType'] !== 'RANGE_OF_EFFECTS';
      _ref2 = this._exportEffectMeasure(outcome), effectMeasure = _ref2[0], otherMeasure = _ref2[1];
      id = this._currentCRId;
      controlRisks = [];
      absoluteEffects = [];
      controlRisksFields = ['lowControl', 'moderateControl', 'highControl'];
      if (utils.existsAndIsTrue(outcome['calculatedControl'])) {
        this._exportCalculatedControlEffectSummaryAndControlRisk(id++, outcome, controlRisks, exportAbsoluteEffect, absoluteEffects);
      }
      for (_i = 0, _len = controlRisksFields.length; _i < _len; _i++) {
        field = controlRisksFields[_i];
        if (utils.existsAndIsTrue(outcome[field])) {
          this._exportControlRisksAndAbsoluteEffect(id++, field, outcome, controlRisks, exportAbsoluteEffect, absoluteEffects);
        }
      }
      this._currentCRId = id;
      relativeEffect = this._exportRelativeEffect(outcome, effectMeasure);
      effectSummary = {
        '@type': summaryType,
        'relativeEffect': relativeEffect
      };
      if (otherMeasure != null) {
        effectSummary['relativeEffect']['name'] = otherMeasure;
        if (exportAbsoluteEffect) {
          for (_j = 0, _len1 = absoluteEffects.length; _j < _len1; _j++) {
            absoluteEffect = absoluteEffects[_j];
            absoluteEffect['name'] = otherMeasure;
          }
        }
      }
      if (exportAbsoluteEffect) {
        effectSummary['absoluteEffect'] = absoluteEffects;
      }
      return [effectSummary, controlRisks];
    }
  };

  ManagementQuestionExporter.prototype._exportEffectSummaryAndControlRisks = function(outcome) {
    var absoluteEffect, absoluteEffectSizeFootnotes, absoluteEffectToFootnotes, absoluteEffectUnitsFootnotes, effectMeasure, effectSummary, explanations, narrativeFootnotes, otherMeasure, references, _ref, _ref1, _ref2, _ref3, _ref4;
    _ref = this._exportEffectMeasure(outcome), effectMeasure = _ref[0], otherMeasure = _ref[1];
    explanations = (_ref1 = outcome['footnotes']) != null ? _ref1 : {};
    references = (_ref2 = outcome['references']) != null ? _ref2 : {};
    switch (outcome['type']) {
      case 'narrative':
        narrativeFootnotes = this.findFootnotes(explanations, references, 'narrativeDesc', 0);
        effectSummary = {
          '@type': this._exportEffectSummaryType(outcome),
          'narrativeSummary': {
            'value': (_ref3 = outcome['narrativeDesc']) != null ? _ref3 : ''
          }
        };
        if (narrativeFootnotes.length > 0) {
          effectSummary['narrativeSummary']['footnote'] = narrativeFootnotes;
        }
        return [effectSummary];
      case 'cont':
        absoluteEffect = null;
        if ((_ref4 = outcome['metaType']) === 'POOLED' || _ref4 === 'SINGLE_STUDY') {
          absoluteEffectUnitsFootnotes = this.findFootnotes(explanations, references, 'effectSizeUnits', 0);
          absoluteEffectSizeFootnotes = this.findFootnotes(explanations, references, 'effectSize', 0);
          absoluteEffectToFootnotes = this.findFootnotes(explanations, references, 'confidenceIntervalTo', 0);
          absoluteEffect = {
            '@type': [effectMeasure, 'QuantitativeValue'],
            'value': {
              'value': outcome['effectSize']
            },
            'confidenceLevel': {
              'value': statisticalUtils.confidenceLevel
            },
            'confidenceIntervalFrom': outcome['confidenceIntervalFrom'],
            'confidenceIntervalTo': outcome['confidenceIntervalTo']
          };
          if (absoluteEffectSizeFootnotes.length > 0) {
            absoluteEffect['value']['footnote'] = absoluteEffectSizeFootnotes;
          }
          if (absoluteEffectToFootnotes.length > 0) {
            absoluteEffect['confidenceLevel']['footnote'] = absoluteEffectToFootnotes;
          }
          if (otherMeasure != null) {
            absoluteEffect['name'] = otherMeasure;
          }
          if ((outcome['units'] != null) && outcome['units'] !== '') {
            absoluteEffect['unitText'] = {
              'value': outcome['units']
            };
            if (absoluteEffectUnitsFootnotes.length > 0) {
              absoluteEffect['unitText']['footnote'] = absoluteEffectUnitsFootnotes;
            }
            absoluteEffect['unitCode'] = _unitCodes['others'];
          }
        }
        effectSummary = {
          '@type': this._exportEffectSummaryType(outcome)
        };
        if (absoluteEffect != null) {
          effectSummary['absoluteEffect'] = absoluteEffect;
        }
        return [effectSummary];
      case 'dich':
      case 'time_to_event':
        return this._exportDichotomousEffectSummaryAndControlRisks(outcome);
      default:
        throw new Error("Unknown outcome's type");
    }
  };

  ManagementQuestionExporter.prototype._exportQuality = function(outcome) {
    var doseResponseGradient, doseResponseGradientFootnotes, doseResponseGradientValue, explanations, imprecision, imprecisionFootnotes, imprecisionValue, inconsistency, inconsistencyFootnotes, inconsistencyValue, indirectness, indirectnessFootnotes, indirectnessValue, largeEffect, largeEffectFootnotes, largeEffectValue, plausibleConfounding, plausibleConfoundingFootnotes, plausibleConfoundingValue, publicationBias, publicationBiasFootnotes, publicationBiasValue, quality, qualityValue, references, riskOfBias, riskOfBiasFootnotes, riskOfBiasValue, _ref, _ref1, _ref10, _ref11, _ref12, _ref13, _ref14, _ref15, _ref2, _ref3, _ref4, _ref5, _ref6, _ref7, _ref8, _ref9;
    qualityValue = outcome['quality'];
    explanations = outcome['footnotes'];
    references = outcome['references'];
    riskOfBias = (_ref = outcome['riskOfBias']) != null ? _ref['label'] : void 0;
    riskOfBiasValue = (_ref1 = outcome['riskOfBias']) != null ? _ref1['score'] : void 0;
    riskOfBiasFootnotes = this.findFootnotes(explanations, references, 'riskOfBias', riskOfBiasValue);
    inconsistency = (_ref2 = outcome['inconsistency']) != null ? _ref2['label'] : void 0;
    inconsistencyValue = (_ref3 = outcome['inconsistency']) != null ? _ref3['score'] : void 0;
    inconsistencyFootnotes = this.findFootnotes(explanations, references, 'inconsistency', inconsistencyValue);
    indirectness = (_ref4 = outcome['indirectness']) != null ? _ref4['label'] : void 0;
    indirectnessValue = (_ref5 = outcome['indirectness']) != null ? _ref5['score'] : void 0;
    indirectnessFootnotes = this.findFootnotes(explanations, references, 'indirectness', indirectnessValue);
    imprecision = (_ref6 = outcome['imprecision']) != null ? _ref6['label'] : void 0;
    imprecisionValue = (_ref7 = outcome['imprecision']) != null ? _ref7['score'] : void 0;
    imprecisionFootnotes = this.findFootnotes(explanations, references, 'imprecision', imprecisionValue);
    publicationBias = this._getQualityOptionForLabel((_ref8 = outcome['publicationBias']) != null ? _ref8['label'] : void 0, 'publicationBias');
    publicationBiasValue = (_ref9 = outcome['publicationBias']) != null ? _ref9['score'] : void 0;
    publicationBiasFootnotes = this.findFootnotes(explanations, references, 'publicationBias', publicationBiasValue);
    doseResponseGradient = this._getQualityOptionForLabel((_ref10 = outcome['doseResponseGradient']) != null ? _ref10['label'] : void 0);
    doseResponseGradientValue = (_ref11 = outcome['doseResponseGradient']) != null ? _ref11['score'] : void 0;
    doseResponseGradientFootnotes = this.findFootnotes(explanations, references, 'doseResponseGradient', doseResponseGradientValue);
    plausibleConfounding = this._getQualityOptionForLabel((_ref12 = outcome['plausibleConfounding']) != null ? _ref12['label'] : void 0);
    plausibleConfoundingValue = (_ref13 = outcome['plausibleConfounding']) != null ? _ref13['score'] : void 0;
    plausibleConfoundingFootnotes = this.findFootnotes(explanations, references, 'plausibleConfounding', plausibleConfoundingValue);
    largeEffect = this._getQualityOptionForLabel((_ref14 = outcome['largeEffect']) != null ? _ref14['label'] : void 0);
    largeEffectValue = (_ref15 = outcome['largeEffect']) != null ? _ref15['score'] : void 0;
    largeEffectFootnotes = this.findFootnotes(explanations, references, 'largeEffect', largeEffectValue);
    quality = {
      '@type': 'GradeQuality',
      'value': qualityValue,
      'name': this._qualityNameForValue(qualityValue),
      'riskOfBias': {
        '@type': this._qualityLabelForScore(riskOfBiasValue),
        'name': this._getQualityOptionForLabel(riskOfBias)
      },
      'inconsistency': {
        '@type': this._qualityLabelForScore(inconsistencyValue),
        'name': this._getQualityOptionForLabel(inconsistency)
      },
      'indirectness': {
        '@type': this._qualityLabelForScore(indirectnessValue),
        'name': this._getQualityOptionForLabel(indirectness)
      },
      'imprecision': {
        '@type': this._qualityLabelForScore(imprecisionValue),
        'name': this._getQualityOptionForLabel(imprecision)
      },
      'otherConsiderations': {
        'name': formatter.otherConsiderations(outcome),
        'publicationBias': {
          '@type': this._qualityLabelForScore(publicationBiasValue),
          'name': publicationBias === 'not serious' ? 'not detected' : publicationBias
        },
        'doseResponseGradient': {
          '@type': this._qualityLabelForScore(doseResponseGradientValue),
          'name': doseResponseGradient
        },
        'plausibleConfounding': {
          '@type': this._qualityLabelForScore(plausibleConfoundingValue),
          'name': plausibleConfounding
        },
        'largeEffect': {
          '@type': this._qualityLabelForScore(largeEffectValue),
          'name': largeEffect
        }
      }
    };
    if (riskOfBiasFootnotes.length > 0) {
      quality['riskOfBias']['footnote'] = riskOfBiasFootnotes;
    }
    if (inconsistencyFootnotes.length > 0) {
      quality['inconsistency']['footnote'] = inconsistencyFootnotes;
    }
    if (indirectnessFootnotes.length > 0) {
      quality['indirectness']['footnote'] = indirectnessFootnotes;
    }
    if (imprecisionFootnotes.length > 0) {
      quality['imprecision']['footnote'] = imprecisionFootnotes;
    }
    if (publicationBiasFootnotes.length > 0) {
      quality['otherConsiderations']['publicationBias']['footnote'] = publicationBiasFootnotes;
    }
    if (doseResponseGradientFootnotes.length > 0) {
      quality['otherConsiderations']['doseResponseGradient']['footnote'] = doseResponseGradientFootnotes;
    }
    if (plausibleConfoundingFootnotes.length > 0) {
      quality['otherConsiderations']['plausibleConfounding']['footnote'] = plausibleConfoundingFootnotes;
    }
    if (largeEffectFootnotes.length > 0) {
      quality['otherConsiderations']['largeEffect']['footnote'] = largeEffectFootnotes;
    }
    return quality;
  };

  ManagementQuestionExporter.prototype._processOutcomeToEvidenceSummary = function(outcome) {
    var comments, controlRisks, effectSummary, explanations, exportedEvidenceSummary, followUp, importanceFootnotes, measuredWithFootnotes, metaType, metaTypeFootnotes, outcomeId, outcomeType, outcomeTypeFootnotes, references, studyDesignFootnotes, _ref, _ref1, _ref2, _ref3, _ref4, _ref5, _ref6, _ref7;
    outcomeId = this._outcomes[outcome['_id']];
    outcomeType = _outcomeTypes[outcome['type']];
    outcomeTypeFootnotes = this.findFootnotes(explanations, references, 'type', 0);
    metaType = outcome['metaType'];
    metaTypeFootnotes = this.findFootnotes(explanations, references, 'metaType', 0);
    comments = outcome['comments'];
    explanations = outcome['footnotes'];
    references = outcome['references'];
    importanceFootnotes = this.findFootnotes(explanations, references, 'importance', 0);
    followUp = this._exportFollowUp(outcome);
    measuredWithFootnotes = this.findFootnotes(explanations, references, 'measuredWith', 0);
    exportedEvidenceSummary = {};
    if (metaType === 'NOT_MEASURED' || metaType === 'NOT_REPORTED') {
      exportedEvidenceSummary = {
        '@type': outcomeType,
        'forOutcome': {
          '@id': outcomeId
        },
        'followUp': followUp,
        'measuredWith': {
          '@type': 'OutcomeMeasure',
          'name': (_ref = outcome['measuredWith']) != null ? _ref : ''
        },
        'effectSummary': {
          '@type': this._exportEffectSummaryType(outcome)
        }
      };
      if (outcome['importance']) {
        exportedEvidenceSummary['importance'] = {
          '@type': 'GradeImportance',
          'value': (_ref1 = outcome['importance']) != null ? _ref1 : '',
          'name': (_ref2 = outcome['importanceLabel']) != null ? _ref2 : ''
        };
        if ((importanceFootnotes != null) && importanceFootnotes.length > 0) {
          exportedEvidenceSummary['importance']['footnote'] = importanceFootnotes;
        }
      }
      if (outcome['type'] === 'narrative') {
        exportedEvidenceSummary['effectSummary']['narrativeSummary'] = (_ref3 = outcome['narrativeDesc']) != null ? _ref3 : '';
      }
    } else {
      studyDesignFootnotes = this.findFootnotes(explanations, references, 'designStudies', 0);
      _ref4 = this._exportEffectSummaryAndControlRisks(outcome), effectSummary = _ref4[0], controlRisks = _ref4[1];
      exportedEvidenceSummary = {
        '@type': outcomeType,
        'forOutcome': {
          '@id': outcomeId
        },
        'studyDesign': {
          '@type': this._exportStudyDesignType(outcome),
          'name': this._exportStudyDesignName(outcome)
        },
        'numberOfStudies': this.getValueAndFootnotes(outcome, 'noOfStudies'),
        'patientGroup': this._exportPatientGroup(outcome),
        'followUp': followUp,
        'measuredWith': {
          '@type': 'OutcomeMeasure',
          'name': (_ref5 = outcome['measuredWith']) != null ? _ref5 : ''
        },
        'effectSummary': effectSummary,
        'quality': this._exportQuality(outcome)
      };
      if (controlRisks != null) {
        exportedEvidenceSummary['controlRisk'] = controlRisks;
      }
      if (studyDesignFootnotes.length > 0) {
        exportedEvidenceSummary['studyDesign']['footnote'] = studyDesignFootnotes;
      }
      if (outcome['importance']) {
        exportedEvidenceSummary['importance'] = {
          '@type': 'GradeImportance',
          'value': (_ref6 = outcome['importance']) != null ? _ref6 : '',
          'name': (_ref7 = outcome['importanceLabel']) != null ? _ref7 : ''
        };
        if ((importanceFootnotes != null) && importanceFootnotes.length > 0) {
          exportedEvidenceSummary['importance']['footnote'] = importanceFootnotes;
        }
      }
    }
    if (utils.existsAndNotEmpty(comments)) {
      exportedEvidenceSummary['comments'] = comments;
    }
    if (outcome['type'] === 'cont') {
      if (utils.existsAndNotEmpty(outcome['scaleTo'])) {
        exportedEvidenceSummary['measuredWith']['upperBound'] = outcome['scaleTo'];
      }
      if (utils.existsAndNotEmpty(outcome['scaleFrom'])) {
        exportedEvidenceSummary['measuredWith']['lowerBound'] = outcome['scaleFrom'];
      }
    }
    if (measuredWithFootnotes.length > 0) {
      exportedEvidenceSummary['measuredWith']['footnote'] = measuredWithFootnotes;
    }
    if (metaTypeFootnotes.length > 0) {
      exportedEvidenceSummary['effectSummary']['footnoteForType'] = metaTypeFootnotes;
    }
    if (outcomeTypeFootnotes.length > 0) {
      exportedEvidenceSummary['footnoteForType'] = outcomeTypeFootnotes;
    }
    return exportedEvidenceSummary;
  };

  ManagementQuestionExporter.prototype.exportEvidenceSummary = function() {
    var outcome, _i, _len, _ref, _results;
    _ref = this._originalQuestion['outcomes'];
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      outcome = _ref[_i];
      _results.push(this._processOutcomeToEvidenceSummary(outcome));
    }
    return _results;
  };

  return ManagementQuestionExporter;

})(QuestionExporter);
