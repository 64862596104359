var ModalView, TopAcceptanceNotificationView, W, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

W = require('when');

template = require('views/templates/dissemination/top_acceptance_notification');

mediator = require('mediator');

module.exports = TopAcceptanceNotificationView = (function(_super) {
  __extends(TopAcceptanceNotificationView, _super);

  function TopAcceptanceNotificationView() {
    return TopAcceptanceNotificationView.__super__.constructor.apply(this, arguments);
  }

  TopAcceptanceNotificationView.prototype.className = 'top-acceptence-notification';

  TopAcceptanceNotificationView.prototype.template = template;

  TopAcceptanceNotificationView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = '30%';
    this.options.width = 750;
    TopAcceptanceNotificationView.__super__.initialize.apply(this, arguments);
    this._deferred = W.defer();
    this.delegate('click', '[data-role="cancel"]', this.dispose);
    this.delegate('click', '[data-role="accept"]', this._topAccepted);
    return this.delegate('click', '[data-role="see_top"]', this._openTop);
  };

  TopAcceptanceNotificationView.prototype.promise = function() {
    return this._deferred.promise;
  };

  TopAcceptanceNotificationView.prototype._topAccepted = function() {
    mediator.user.save('topAccepted', true);
    return this._deferred.resolve().then(this.dispose);
  };

  TopAcceptanceNotificationView.prototype._openTop = function() {
    return window.open($.t('terms_of_publication_url'), '_blank');
  };

  return TopAcceptanceNotificationView;

})(ModalView);
