QuestionsList = require 'components/scope/outcomes/questions_list'
QuestionGroupTitle = require 'components/scope/outcomes/question_group_title'
OutcomesList = require 'components/scope/outcomes/outcomes_list'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

QuestionGroup = createReactClass
  displayName: 'QuestionGroupComponent'
  mixins: [CustomRenderMixin]
  propTypes:
    data: PropTypes.object.isRequired
    gIndex: PropTypes.number.isRequired

  render: ->
    name = @props.data.get 'name'
    questions = @props.data.get 'questions'
    outcomes = @props.data.get 'outcomes'

    <div className='question-group'>
      <QuestionGroupTitle text={name} />
      <QuestionsList questions={questions} groupName={name} />
      <OutcomesList
        outcomes={outcomes}
        gIndex={@props.gIndex}
        membersMap={@props.membersMap}
        readOnly={@props.readOnly}
        showingComments={@props.showingComments}
        onCommentToggle={@props.onCommentToggle}
      />
    </div>

module.exports = QuestionGroup
