var CADTH_GUIDELINE_DOC_PREFIX, CadthGuidelineDocShape, DbDoc;

CadthGuidelineDocShape = require('lib/db_docs/doc_shapes/cadth_guideline_doc_shape');

CADTH_GUIDELINE_DOC_PREFIX = require('lib/cadth_guidelines_helper').CADTH_GUIDELINE_DOC_PREFIX;

DbDoc = require('lib/db_docs/db_doc');

module.exports = DbDoc(CadthGuidelineDocShape, {
  dbSetup: {
    idPrefix: CADTH_GUIDELINE_DOC_PREFIX
  }
});
