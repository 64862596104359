var ArchieHelper, ArchieTokenExpiredModalView, CellFootnotesAndReferencesView, DbHelper, ETD_PARTS, EvidenceSynthesesToolbarQuestionsView, EvidenceSynthesesToolbarView, Focusable, HtmlExport, IsofExportView, PanelVoiceExportView, QuestionExportView, REVMAN_SYNC_TIMEOUT, RevManStatusView, RevManWebActions, SelectionView, View, W, alt, exceptions, getExportOptions, mediator, removeAttrs, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

View = require('base/views/view');

HtmlExport = require('lib/html_export');

CellFootnotesAndReferencesView = require('views/evidence_syntheses/cell_footnotes_and_references_view');

SelectionView = require('base/views/selection_view');

Focusable = require('base/lib/traits/focusable');

template = require('views/templates/evidence_syntheses_toolbar');

mediator = require('mediator');

PanelVoiceExportView = require('views/panel_voice_export_view');

IsofExportView = require('views/isof_export_view');

QuestionExportView = require('views/question_export_view');

DbHelper = require('base/lib/db_helper');

ArchieHelper = require('lib/archie_helper');

ETD_PARTS = require('lib/voting_helper').ETD_PARTS;

getExportOptions = require('stores/utils/panel_voice_helpers').getExportOptions;

EvidenceSynthesesToolbarQuestionsView = require('views/evidence_syntheses_toolbar_questions_view');

ArchieTokenExpiredModalView = require('views/archie/archie_token_expired_modal_view');

RevManStatusView = require('views/archie/revman_status_view');

RevManWebActions = require('actions/revman_web_actions');

exceptions = require('lib/exceptions');

W = require('when');

REVMAN_SYNC_TIMEOUT = 5 * 1000;

removeAttrs = function($el) {
  var whitelist;
  whitelist = ['colspan', 'rowspan', 'valign'];
  $el.each(function() {
    var attributes, handle, whitelistForCurrentTag;
    whitelistForCurrentTag = this.tagName.toLowerCase() === 'a' ? _.union(whitelist, ['type', 'href']) : whitelist;
    attributes = $.map(this.attributes, function(item) {
      return item.name.toString().toLowerCase();
    });
    attributes = $.grep(attributes, function(item, index) {
      return $.inArray(item, whitelistForCurrentTag) < 0;
    });
    handle = $(this);
    $.each(attributes, function(idx, item) {
      return handle.removeAttr(item);
    });
    return $el.children().each(function() {
      return removeAttrs($(this));
    });
  });
  return $el;
};

module.exports = EvidenceSynthesesToolbarView = (function(_super) {
  __extends(EvidenceSynthesesToolbarView, _super);

  function EvidenceSynthesesToolbarView() {
    this.hideViewsListBox = __bind(this.hideViewsListBox, this);
    this.changeOutcomeView = __bind(this.changeOutcomeView, this);
    this.showFootnotesBox = __bind(this.showFootnotesBox, this);
    this.deactivatedProperty = __bind(this.deactivatedProperty, this);
    this.activatedProperty = __bind(this.activatedProperty, this);
    this._getViewType = __bind(this._getViewType, this);
    return EvidenceSynthesesToolbarView.__super__.constructor.apply(this, arguments);
  }

  EvidenceSynthesesToolbarView.prototype.template = template;

  EvidenceSynthesesToolbarView.prototype.container = '#toolbar-container';

  EvidenceSynthesesToolbarView.prototype.className = 'evidence-syntheses-toolbar';

  EvidenceSynthesesToolbarView.prototype.autoRender = true;

  EvidenceSynthesesToolbarView.prototype.assessmentComponent = null;

  EvidenceSynthesesToolbarView.prototype.listenToParentKeyEvents = true;

  EvidenceSynthesesToolbarView.prototype.isRevManEnabled = false;

  EvidenceSynthesesToolbarView.prototype.revManSynchronization = {
    syncToRevManTimer: null
  };

  EvidenceSynthesesToolbarView.prototype.shortcuts = {
    'alt+v': 'showViewsList'
  };

  EvidenceSynthesesToolbarView.prototype.initialize = function() {
    EvidenceSynthesesToolbarView.__super__.initialize.apply(this, arguments);
    this._stopInputsPropagation = false;
    this.submodule = this.options.submodule;
    this.paneOpened = false;
    this.listItems = this.getListItems();
    this.enable(Focusable);
    this.delegate('click', 'button.outcome-view', this.showViewsList);
    this.delegate('click', '.export', this.exportTable);
    this.delegate('click', 'button.toggle-footnotes-pane', this.toggleFootnotesPane);
    this.delegate('click', '.switcher--footnotes-pane', this.toggleFootnotesPane);
    this.delegate('click', 'button.footnotes', this.showFootnotesBox);
    this.subscribeEvent('activatedProperty', this.activatedProperty);
    this.subscribeEvent('deactivatedProperty', this.deactivatedProperty);
    ArchieHelper.getRevManWebConnectionData().then((function(_this) {
      return function(revManWebConnectionData) {
        _this.isRevManEnabled = revManWebConnectionData.connected;
        return _this.render();
      };
    })(this));
    this.subview('archieTokenExpiredModal', new ArchieTokenExpiredModalView);
    if (this.model != null) {
      return this.modelBind('change:$timestamp', (function(_this) {
        return function() {
          var syncFn, _ref;
          if (((_ref = _this.model) != null ? _ref.get('revManWebReviewId') : void 0) == null) {
            return;
          }
          if (_this.revManSynchronization.syncToRevManTimer != null) {
            clearTimeout(_this.revManSynchronization.syncToRevManTimer);
          }
          syncFn = function() {
            _this.syncRevman(false)();
            return _this.revManSynchronization.syncToRevManTimer = null;
          };
          return _this.revManSynchronization.syncToRevManTimer = setTimeout(syncFn, REVMAN_SYNC_TIMEOUT);
        };
      })(this));
    }
  };

  EvidenceSynthesesToolbarView.prototype._getViewType = function() {
    if (!this.model) {
      return null;
    }
    return mediator.services.esView.getPreferredType(mediator.project, this.model.get('type'));
  };

  EvidenceSynthesesToolbarView.prototype.activatedProperty = function(property, activeElement, question, outcome, editView) {
    if (editView == null) {
      editView = false;
    }
    this.activeProperty = property;
    this.activeElement = activeElement;
    this.activeQuestion = question;
    this.activeOutcome = outcome;
    this.isEditView = editView;
    return this._updateFootnotesButton();
  };

  EvidenceSynthesesToolbarView.prototype.deactivatedProperty = function() {
    this.activeProperty = this.activeElement = this.activeQuestion = this.activeOutcome = null;
    return this._updateFootnotesButton();
  };

  EvidenceSynthesesToolbarView.prototype.toggleFootnotesPane = function() {
    if (this.$('button.toggle-footnotes-pane').prop('disabled')) {
      return;
    }
    this.paneOpened = this.options.toggleFootnotesBottomPane();
    return this.$('.switcher--footnotes-pane input[type="checkbox"]').prop('checked', this.paneOpened);
  };

  EvidenceSynthesesToolbarView.prototype._updateFootnotesButton = function() {
    var $footnotesButton, $footnotesTooltip, disabled, modelType;
    $footnotesButton = this.$el.find('.footnotes-button-container button.footnotes');
    $footnotesTooltip = this.$el.find('.footnotes-button-container .disabled-tooltip');
    modelType = this.activeOutcome != null ? 'outcome' : 'question';
    disabled = !this.activeProperty || !(this.isEditView || CellFootnotesAndReferencesView.prototype.footnotesEnabled(modelType, this.activeProperty));
    $footnotesButton.prop('disabled', disabled);
    if (disabled) {
      return $footnotesTooltip.show();
    } else {
      return $footnotesTooltip.hide();
    }
  };

  EvidenceSynthesesToolbarView.prototype.showFootnotesBox = function(e) {
    var footnotesView, footnotesViewOptions, _ref;
    e.stopPropagation();
    if (!this.activeProperty || mediator.currentFocus instanceof CellFootnotesAndReferencesView) {
      return;
    }
    footnotesView = new CellFootnotesAndReferencesView({
      questionModel: this.activeQuestion,
      footnotesBindingModel: (_ref = this.activeOutcome) != null ? _ref : this.activeQuestion,
      property: this.activeProperty,
      propertyView: mediator.currentFocus,
      parentView: this
    });
    footnotesViewOptions = {
      container: $('#page-container'),
      cell: this.activeElement
    };
    if (this.options.parentView) {
      return this.options.parentView.showFootnotesSubview(footnotesView, footnotesViewOptions);
    } else {
      this.subview('footnotes', footnotesView);
      return footnotesView.show(footnotesViewOptions);
    }
  };

  EvidenceSynthesesToolbarView.prototype._isRecommendationsSubmodule = function() {
    return /recommendations/.test(this.submodule);
  };

  EvidenceSynthesesToolbarView.prototype.getListItems = function() {
    if (this._isRecommendationsSubmodule()) {
      return [
        {
          value: 'initial',
          text: $.t('es:recommendations.view_type.initial')
        }, {
          value: 'static',
          text: $.t('es:recommendations.view_type.static')
        }
      ];
    }
  };

  EvidenceSynthesesToolbarView.prototype.afterRender = function() {
    var _ref, _ref1, _ref2;
    EvidenceSynthesesToolbarView.__super__.afterRender.apply(this, arguments);
    this.subview('questionsList', new EvidenceSynthesesToolbarQuestionsView({
      submodule: this.submodule,
      moduleName: this.options.moduleName,
      model: this.model
    }));
    if ((_ref = this.model) != null ? _ref.isPrognostic() : void 0) {
      this.$('button.outcome-view').prop('disabled', true);
    }
    if ((_ref1 = this.model) != null ? _ref1.get('switchableRecommendationsView') : void 0) {
      this.$('button.outcome-view').prop('disabled', false);
    }
    if (_.result(this.options, 'disableExportButton')) {
      this.$('button.export').prop('disabled', true);
    }
    this.$('.footnotes-button-container .disabled-tooltip').hide();
    this.syncRevman(false)();
    if (this.isRevManEnabled && ((_ref2 = this.model) != null ? _ref2.get('revManWebReviewId') : void 0)) {
      return this.subview('revManWebStatusView', new RevManStatusView({
        onClick: this.syncRevman(true)
      }));
    }
  };

  EvidenceSynthesesToolbarView.prototype.exportPanelVoice = function(_arg) {
    var exportOptions, votingStarted;
    votingStarted = _arg.votingStarted;
    exportOptions = getExportOptions(this.model.get('_id'));
    return this.subview('panelVoiceExportView', new PanelVoiceExportView({
      model: this.model,
      submodule: this.submodule,
      closeBtn: true,
      etdVersion: "v2",
      votingStarted: votingStarted,
      exportDataOptions: exportOptions,
      isAdolopment: mediator.project.isAdolopment()
    }));
  };

  EvidenceSynthesesToolbarView.prototype.exportQuestionView = function() {
    var isISoF, viewType, _ref;
    viewType = this._getViewType();
    isISoF = ((_ref = this.submodule) !== 'recommendations' && _ref !== 'recommendations-static') && Boolean(viewType != null ? viewType.isof : void 0);
    if (isISoF) {
      return this.subview('isofExportView', new IsofExportView({
        model: this.model,
        submodule: this.submodule,
        closeBtn: true
      }));
    } else {
      return this.subview('questionExportView', new QuestionExportView({
        model: this.model,
        submodule: this.submodule,
        closeBtn: true
      }));
    }
  };

  EvidenceSynthesesToolbarView.prototype.exportTable = function() {
    var projectId;
    if (this.submodule === 'recommendations-static') {
      projectId = mediator.project.id;
      return mediator.services.storePersistenceAdapter.exists(projectId, 'etd-voting-preferences').then((function(_this) {
        return function(oldVoting) {
          var metadocId;
          if (oldVoting) {
            return _this.exportQuestionView();
          } else {
            metadocId = "" + projectId + ":panel-voice-metadoc";
            return mediator.services.storePersistenceAdapter.fetch(projectId, metadocId).then(function(panelVoiceMetadoc) {
              var votingStarted;
              votingStarted = _.last(_this.model.get('recommendationIds')) in panelVoiceMetadoc.etds;
              return _this.exportPanelVoice({
                votingStarted: votingStarted
              });
            });
          }
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          if (err.status !== 404) {
            throw err;
          }
          return _this.exportQuestionView();
        };
      })(this));
    } else {
      return this.exportQuestionView();
    }
  };

  EvidenceSynthesesToolbarView.prototype._getRecommendationsSubmoduleName = function(viewTypeName) {
    return "recommendations-" + viewTypeName;
  };

  EvidenceSynthesesToolbarView.prototype.changeOutcomeView = function(viewTypeName) {
    if (this._isRecommendationsSubmodule()) {
      mediator.project.set('recommendationsMode', viewTypeName).save();
      return this.submodule = this._getRecommendationsSubmoduleName(viewTypeName);
    }
  };

  EvidenceSynthesesToolbarView.prototype.showViewsList = function(e) {
    var activeItem, offset, selectionView, theEye;
    e.preventDefault();
    e.stopPropagation();
    theEye = this.$('button.outcome-view');
    if (theEye.prop('disabled')) {
      return;
    }
    selectionView = new SelectionView({
      listItems: this.listItems,
      acceptHandler: (function(_this) {
        return function() {
          return _this.changeOutcomeView(selectionView.getSelectedItemValue());
        };
      })(this),
      abortHandler: this.hideViewsListBox
    });
    this.subview('selectionView', selectionView);
    offset = theEye.offset();
    offset.top = offset.top + theEye.height();
    if (this._isRecommendationsSubmodule()) {
      activeItem = mediator.project.get('recommendationsMode');
    }
    return this.subview('selectionView').show(offset, activeItem);
  };

  EvidenceSynthesesToolbarView.prototype.hideViewsListBox = function() {
    var _ref;
    return (_ref = this.subview('selectionView')) != null ? _ref.hide() : void 0;
  };

  EvidenceSynthesesToolbarView.prototype.getTemplateData = function() {
    var data, viewType, _ref;
    viewType = this._getViewType();
    data = EvidenceSynthesesToolbarView.__super__.getTemplateData.apply(this, arguments);
    _(data).extend({
      viewType: viewType != null ? viewType.name : void 0,
      paneOpened: this.paneOpened,
      submodule: this.submodule,
      isRevManEnabled: this.isRevManEnabled && Boolean((_ref = this.model) != null ? _ref.get('revManWebReviewId') : void 0),
      isRecommendationsSubmodule: this._isRecommendationsSubmodule()
    });
    return data;
  };

  EvidenceSynthesesToolbarView.prototype.syncRevman = function(isUserAction) {
    if (isUserAction == null) {
      isUserAction = true;
    }
    return (function(_this) {
      return function() {
        var exportParams, isofLinkPromise, projectName, reviewId, tableId, tableSourcePromise, _ref;
        if (!(_this.isRevManEnabled && (((_ref = _this.model) != null ? _ref.get('revManWebReviewId') : void 0) != null))) {
          return;
        }
        reviewId = _this.model.get('revManWebReviewId');
        tableId = _this.model.get('revManWebTableId');
        exportParams = {
          word: true,
          outcomesOrAppendicesIds: _this.model.get('outcomes').map(function(outcome) {
            return outcome.get('_id');
          })
        };
        projectName = mediator.project.get('name');
        isofLinkPromise = mediator.services.presentationService.exportIsof(_this.model.toJSON(), projectName, false);
        tableSourcePromise = new HtmlExport(_this.model, 'sof', exportParams).getSource('portrait', {}, 'PrintoutSOF');
        return W.all([tableSourcePromise, isofLinkPromise]).then(function(_arg) {
          var div, first, footnotes, footnotesText, isofLink, isofLinkHTML, isofLinkText, linkHTML, source, tableBody, tableFootnotes, tableTitle, tagWhitelist;
          source = _arg[0], isofLink = _arg[1];
          div = document.createElement('div');
          div.innerHTML = source.replace(/⨁/g, '&#8853;').replace(/◯/g, '&#8861;');
          $(div).find('thead > tr').first().remove();
          $(div).find('tbody.printout-rows tr td').each(function(_, cell) {
            return $(cell).attr('valign', 'top');
          });
          footnotes = $(div).find('.footnotes-list > div');
          tableFootnotes = footnotes.length ? (footnotesText = '', first = true, $.each(footnotes, function() {
            var brIfNeeded, footnoteText, letter;
            letter = $(this).find('span:first-child, sup:first-child').text();
            footnoteText = $(this).find('span:last-child').text().replace(/</g, '&lt;').replace(/>/g, '&gt;');
            brIfNeeded = first ? '' : '<br />';
            first = false;
            return footnotesText += "" + brIfNeeded + "<sup>" + letter + "</sup> " + footnoteText;
          }), "<p>" + footnotesText + "</p>") : '';
          linkHTML = "<a href='" + isofLink + "' type='EXTERNAL'>" + isofLink + "</a>";
          isofLinkText = $.t('archie:revman.status.isofLink', {
            link: linkHTML
          });
          isofLinkHTML = "<tbody><tr><td colspan='7'>" + isofLinkText + "</td></tr></tbody>";
          $(div).find('table').append(isofLinkHTML);
          tagWhitelist = ['table', 'tbody', 'thead', 'tr', 'td', 'th', 'b', 'br', 'sup', 'a'];
          tableBody = removeAttrs($(div).find('table')).prop('outerHTML').replace(/<(\/)?([a-zA-Z]+)(.*?)>/g, function(_match, tagClose, tag, attrs) {
            var _ref1, _ref2;
            if (tagClose == null) {
              tagClose = '';
            }
            if (((_ref1 = tag.toLowerCase()) === 'p' || _ref1 === 'div') && tagClose !== '') {
              return '<br>';
            } else if (_ref2 = tag.toLowerCase(), __indexOf.call(tagWhitelist, _ref2) >= 0) {
              return "<" + tagClose + tag + attrs + ">";
            } else {
              return '';
            }
          }).replace(/<\/tbody>(\s*)<tbody(.*?)>/g, '').replace(/<br>/g, '<br />').replace(/&nbsp;/g, '').replace(/<tr><\/tr>/g, '');
          div.remove();
          tableTitle = _.escape($.t('archie:revman.status.tableTitle', {
            question: _this.model.get('tableTitle') || _this.model.get('questionShort')
          }));
          return RevManWebActions.syncToRevMan(reviewId, tableId, tableBody, tableFootnotes, tableTitle, isUserAction);
        });
      };
    })(this);
  };

  EvidenceSynthesesToolbarView.prototype.dispose = function() {
    this.activeProperty = this.activeElement = this.activeQuestion = this.activeOutcome = null;
    return EvidenceSynthesesToolbarView.__super__.dispose.apply(this, arguments);
  };

  return EvidenceSynthesesToolbarView;

})(View);
