IconButton = require 'components/common/icon_button'
Spinner = require 'components/common/spinner'
{ bool } = PropTypes

SpinnerIconButon = createReactClass
  displayName: 'SpinnerIconButon'

  propTypes:
    isLoading: bool.isRequired

  render: ->
    { isLoading } = @props
    buttonProps = _.omit @props, 'isLoading'

    <div className='icon-button__with-spinner'>
      <IconButton {...buttonProps} disabled={isLoading} />
      {if isLoading
        <Spinner scale={0.5} />
      }
    </div>

module.exports = SpinnerIconButon
