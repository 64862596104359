ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
MdaTopicsActions = require 'actions/mda_topics_actions'
QuestionsStore = require 'stores/questions_store'
MdaWorkflowDialogActions = require 'actions/mda_workflow_dialog_actions'
MdaWorkflowDialogStore = require 'stores/mda_workflow_dialog_store'
Modal = require 'components/common/modal'
SelectCustom = require 'components/common/select_custom'
TopicsTable = require 'components/mda/workflow/topics_table'
{ useState } = React
{ MDA_TOPIC } = require 'lib/db_docs/doc_types'
{ useI18n, useCoffeeCallback, useCoffeeMemo } = require 'lib/react_utils'

storeConnector =
  MdaWorkflowDialogStore: (Store) ->
    allTables: Store.getAllTables()
    isFetchingTopics: Store.isFetchingTopics()
    projects: Store.getProjects()
    searchString: Store.getSearchString()
    selectedProjectId: Store.getSelectedProjectId()
    selectedTopics: Store.getSelectedTopics()
    sortColumn: Store.getSortColumn()
    sortType: Store.getSortType()
    topics: Store.getTopics()
  QuestionsStore: (Store) ->
    includedTopics: Store.getQuestions MDA_TOPIC

MdaImportTopicsModal = ({
  allTables
  isFetchingTopics
  projects
  searchString
  selectedProjectId
  selectedTopics
  sortColumn
  sortType
  includedTopics
  topics
}) ->
  i18n = useI18n('mda:topics')

  onCancel = useCoffeeCallback [], -> MdaWorkflowDialogActions.closeDialog()

  onImportTopics = useCoffeeCallback [selectedProjectId, selectedTopics, topics], ->
    topicsToImport = topics.filter (topic) -> selectedTopics.includes topic.get('_id')
    MdaTopicsActions.importTopics selectedProjectId, topicsToImport
    .finally MdaWorkflowDialogActions.closeDialog

  notImportedTopics = useCoffeeMemo [includedTopics, topics], ->
    topics.filterNot (topic) -> includedTopics.has(topic.get('_id'))

  onProjectChange = useCoffeeCallback [], (projectId) ->
    MdaWorkflowDialogActions.fetchTopics projectId

  projectOptions = useCoffeeMemo [projects], ->
    _.map projects.toJS(), ({ id: value, name: text }) -> { text, value }

  projectSelect = useCoffeeMemo [onProjectChange, projectOptions, selectedProjectId], ->
    <SelectCustom
      className="project-selector"
      onChange={onProjectChange}
      options={projectOptions}
      selected={selectedProjectId}
    />

  <Modal
    className="mda-workflow-modal"
    isOpen
    modalSize="large"
    title={i18n 'import_topics'}
  >
    <div className="mda-workflow-modal__content">
      <TopicsTable
        allTables={allTables}
        isFetching={isFetchingTopics}
        noTopicsMessageKey="topics.no_topics_to_import"
        rightHeaderElement={projectSelect}
        searchString={searchString}
        topics={notImportedTopics}
        sortColumn={sortColumn}
        sortType={sortType}
        selectedTopics={selectedTopics}
      />
      <ApplyCancelButtons
        onCancel={onCancel}
        onApply={onImportTopics}
        applyLabel={i18n '/actions.import'}
        isSubmitEnabled={not selectedTopics.isEmpty()}
      />
    </div>
  </Modal>

module.exports = ConnectStore MdaImportTopicsModal, [
  MdaWorkflowDialogStore
  QuestionsStore
], storeConnector
