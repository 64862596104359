Tooltip = require 'components/common/tooltip'
ReferencesStore = require 'stores/references_store'
{ findWhere } = require 'lib/immutable_utils'
{ Entity } = Draft
{ filterReferencesByIds, vancouverFormat } = require 'lib/references_utils'

DocSectionsReferenceCitation = createReactClass
  displayName: 'DocSectionsReferenceCitation'

  getLocalReferenceIds: ->
    { refIds } = Entity.get(@props.entityKey).getData()
    refIds

  getRefsWithIdxs: (localRefIds) ->
    refIds = _.union @props.referenceIds, localRefIds

    _.chain refIds
    .map (refId, idx) ->
      if refId in localRefIds
        idx: idx + 1
        refId: refId
      else
        null
    .compact()
    .value()

  referencesWithTooltips: ->
    localReferenceIds = @getLocalReferenceIds()
    references = if @props.references?
      filterReferencesByIds @props.references, localReferenceIds
    else
      ReferencesStore.getReferenceByIds localReferenceIds

    @getRefsWithIdxs(localReferenceIds)?.map (refWithIdx) =>
      reference = findWhere references, '_id', refWithIdx.refId
      tooltip = reference and vancouverFormat reference.toJS()
      return null unless reference
      <Tooltip key={reference.get('_id')}>
        <span title={tooltip}>
          {refWithIdx.idx}{' '}
        </span>
      </Tooltip>

  render: ->
    <span style={backgroundColor: '#ebebeb'} data-gdt-ref-ids={@getLocalReferenceIds()}>
      <span>{'( '}</span>
      {@referencesWithTooltips()}
      {@props.children}
    </span>

module.exports = DocSectionsReferenceCitation
