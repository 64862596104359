var ReactComponent, UserMenu, UserMenuView, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

UserMenu = require('components/header/user_menu');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

mediator = require('mediator');

module.exports = UserMenuView = (function(_super) {
  __extends(UserMenuView, _super);

  function UserMenuView() {
    return UserMenuView.__super__.constructor.apply(this, arguments);
  }

  UserMenuView.prototype.container = '#user-account-menu';

  UserMenuView.prototype.autoRender = true;

  UserMenuView.prototype.initialize = function() {
    UserMenuView.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    this.subscribeEvent('!projects-screen-onboarding:end', this.handleProjectsOnboardingEnd);
    return this.subscribeEvent('!projects-screen-onboarding:stepChange', this.handleProjectsOnboardingStepChange);
  };

  UserMenuView.prototype.handleProjectsOnboardingStepChange = function() {
    if (window.hopscotch.getCurrStepNum() !== 2) {
      return this._updateComponent();
    }
  };

  UserMenuView.prototype.handleProjectsOnboardingEnd = function() {
    if (R.last(window.hopscotch.getSkippedStepsIndexes()) === '2') {
      return this._updateComponent({
        forceMenuOpen: true
      }, _.defer(function() {
        return mediator.services.projectsScreenOnboarding.startTour(2);
      }));
    } else {
      return this._updateComponent();
    }
  };

  UserMenuView.prototype._updateComponent = function(newProps, onRenderCb) {
    if (newProps == null) {
      newProps = {
        forceMenuOpen: false
      };
    }
    return this.updateRenderedComponent(R.mergeRight(this.options, newProps), onRenderCb);
  };

  UserMenuView.prototype.afterRender = function() {
    UserMenuView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(UserMenu, this.options);
  };

  UserMenuView.prototype.dispose = function() {
    this.unmountComponent();
    return UserMenuView.__super__.dispose.apply(this, arguments);
  };

  return UserMenuView;

})(View);
