ConnectStore = require 'components/enhancers/connect_store'
CreateUpdateFormModal = require 'components/common/create_update_form_modal'
NMAQuestionActions = require 'actions/nma_question_actions'
NMAQuestionStore = require 'stores/nma_question_store'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

storeConnector =
  NMAQuestionStore: (Store) ->
    intervention: Store.getModalIntervention()
    isApplying: Store.isInterventionEditModalApplying()
    isOpen: Store.isInterventionEditModalOpen()
    mode: Store.getInterventionModalMode()
    questionId: Store.getCurrentQuestion().get '_id', ''

FORM =
  main: [
    fieldKey: 'name'
    type: 'string'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'shortName'
    type: 'string'
    showClear: false
    useControlledInput: true
  ]

NMAInterventionEditModal = ({ intervention, isApplying, isOpen, mode, questionId }) ->
  i18n = useI18n 'nma:question.interventions.modal'

  onCreateIntervention = useCoffeeCallback [intervention, questionId], ->
    NMAQuestionActions.createIntervention questionId, intervention.toJS()

  onUpdateIntervention = useCoffeeCallback [intervention, questionId], ->
    NMAQuestionActions.updateIntervention questionId, intervention.toJS()

  <CreateUpdateFormModal
    className="nma-intervention-edit-modal"
    data={intervention.toJS()}
    form={FORM}
    i18n={i18n}
    isApplying={isApplying}
    isOpen={isOpen}
    isSubmitEnabled={not isApplying and intervention.get('name')?.length > 0}
    mode={mode}
    onCancel={NMAQuestionActions.closeInterventionEditModal}
    onCreate={onCreateIntervention}
    onChange={NMAQuestionActions.updateInterventionInModal}
    onUpdate={onUpdateIntervention}
    title={i18n "title.#{mode}"}
  />

NMAInterventionEditModal.propTypes =
  intervention: PropTypes.instanceOf(Immutable.Map).isRequired
  isApplying: PropTypes.bool.isRequired
  isOpen: PropTypes.bool.isRequired
  mode: PropTypes.oneOf(['create', 'edit']).isRequired
  questionId: PropTypes.string.isRequired

module.exports = ConnectStore NMAInterventionEditModal, [NMAQuestionStore], storeConnector
