Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

VotingTeamComment = createReactClass
  displayName: 'VotingTeamComment'
  mixins: [CustomRenderMixin, Translation('es:recommendations.table')]

  _prepareNamesOnlyVotes: (votes) ->
    votes.map (voteDetails, idx, arr) =>
      isLastVoter = idx + 1 is arr.size
      userWasDeleted = voteDetails.get 'userWasDeleted'
      lastNameText = if isLastVoter
        " #{voteDetails.get 'lastName'}"
      else
        " #{voteDetails.get 'lastName'}, "

      <span key={idx}>
        {voteDetails.get 'givenNames' unless userWasDeleted}
        {if userWasDeleted
          <span className="member-name">{@i18n 'user_was_deleted'}</span>
        else
          <span className="member-name">{lastNameText}</span>
        }
      </span>
    .toList()

  _getShowOrHideButton: ->
    return if @props.renderMode is 'printout'
    if @props.hide
      <button className="btn btn-edit hide-comment" onClick={@props.hide}>{@i18n 'hide'}</button>
    else if @props.show
      <button className="btn btn-edit show-comment" onClick={@props.show}>{@i18n 'show'}</button>
    else false

  render: ->
    return false if @props.hidden

    if @props.namesOnly
      <div className='comment'>
        {@_getShowOrHideButton()}
        {@_prepareNamesOnlyVotes(@props.votes)}
      </div>
    else
      <div className='comment'>
        {@_getShowOrHideButton()}
        <span>
          {@props.name}
          <span className="member-name"> {@props.lastName}</span>
        </span>
        <p className="comment-text">{@props.comment}</p>
      </div>

module.exports = VotingTeamComment
