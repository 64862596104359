AccessRights = require 'components/team/form/access_rights'
CheckboxInput = require 'components/common/checkbox_input'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EditableNameCell = require 'components/team/form/editable_name_cell'
MembersActions = require 'actions/members_actions'
MemberControls = require 'components/team/member_controls'
MembersStore = require 'stores/members_store'
PanelMemberCell = require 'components/team/form/panel_member_cell'
MEMBER_SCHEMA = require 'components/team/member_schema'
Translation = require 'components/mixins/translation'
{ getMemberAccessRights, getMemberPendingAccessRights } = require 'lib/members_helper'

MemberCollapsed = createReactClass
  displayName: "MemberCollapsed"

  mixins:[
    mixins: [Translation('team:member'), CustomRenderMixin]
  ]

  propTypes:
    edit: PropTypes.func.isRequired
    member: PropTypes.instanceOf(Immutable.Map).isRequired
    onSelectMember: PropTypes.func.isRequired

  renderCells: ({ name, component, tooltip }, idx) ->
    props =
      el: 'td'
      key: idx,
      name: _.string.underscored name
      member: @props.member
      title: @i18n _.string.underscored tooltip
      className: classNames 'cell', name
    React.createElement component, props

  render: ->
    <tr>
      {if @props.showCheckboxes
        <td className="checkbox">
          <CheckboxInput
            value={@props.member.get('_id')}
            type="checkbox"
            name="check"
            checked={@props.checked}
            onChange={@props.onSelectMember} />
        </td>
      }
      {MEMBER_SCHEMA.listItemCollapsed.map(@renderCells)}
      <MemberControls
        member={@props.member}
        itemKey={@props.itemKey}
        edit={@props.edit}
        expanded={false}
      />
    </tr>

MemberExpanded = createReactClass

  displayName: "MemberExpanded"

  mixins: [CustomRenderMixin]

  propTypes:
    adminsLimit: PropTypes.number.isRequired
    disabledAccessRights: PropTypes.instanceOf(Array)
    itemKey: PropTypes.string.isRequired
    member: PropTypes.instanceOf(Immutable.Map).isRequired

  getDefaultProps: ->
    disabledAccessRights: []

  batchUpdateMember: (updates) ->
    MembersActions.batchUpdateMember @props.itemKey, updates

  updateMemberAttribute: (attr, value) ->
    MembersActions.updateMember @props.itemKey, attr, value

  updateAccessRights: (value) ->
    isPendingInvitation = @props.member.get 'pendingInvitation'
    attributeToUpdate = if isPendingInvitation
      'pendingInvitationAccessRights'
    else
      'accessRights'
    if value is 'acp_panel_member'
      @batchUpdateMember [
        { attr: attributeToUpdate, value: Immutable.List([value]) }
        { attr: 'panelMember', value: true }
      ]
    else
      @updateMemberAttribute attributeToUpdate, Immutable.List([value])

  _getSelectedAccessRights: (member) ->
    isPendingInvitation = member.get 'pendingInvitation'
    if isPendingInvitation
      getMemberPendingAccessRights member
    else
      getMemberAccessRights member

  render: ->
    { adminsLimit, disabledAccessRights, member } = @props
    <tr className="expanded">
      {if @props.showCheckboxes
        <td className="checkbox"></td>
      }
      <td className="name">
        <EditableNameCell
          updateAttr={@updateMemberAttribute}
          {...@props}
          member={member}
          />
      </td>
      <PanelMemberCell
        expanded={true}
        el="td"
        className="panel-member"
        member={member}
      />
      <td className="access-rights">
        <AccessRights
          adminsLimit={adminsLimit}
          disabledAccessRights={disabledAccessRights}
          member={member}
          onChange={@updateAccessRights}
          selected={@_getSelectedAccessRights member}
        />
      </td>
      <MemberControls
        itemKey={@props.itemKey}
        member={member}
        save={@props.save}
        delete={@props.delete}
        expanded={true} />
    </tr>

module.exports = { MemberCollapsed, MemberExpanded }
