var DB_VIEW, ReferencesExporter, getReferencesFromEntityMap,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

DB_VIEW = require('lib/references_utils').DB_VIEW;

getReferencesFromEntityMap = require('lib/etd_helper').getReferencesFromEntityMap;

module.exports = ReferencesExporter = (function() {
  function ReferencesExporter(_project, _pouchAdapter) {
    this._project = _project;
    this._pouchAdapter = _pouchAdapter;
    this.exportReferencesForEtdCell = __bind(this.exportReferencesForEtdCell, this);
    this.getUsedReferencesAndClearIds = __bind(this.getUsedReferencesAndClearIds, this);
    this.getReferenceJSONLDId = __bind(this.getReferenceJSONLDId, this);
    this._idMap = {};
    this._reverseIdMap = {};
    this._id = 1;
    this._referencesIdsUsed = [];
    this._references = {};
  }

  ReferencesExporter.prototype.fetchReferences = function() {
    var projectId;
    projectId = this._project.id;
    return this._pouchAdapter.fetchWithView(projectId, 'references', DB_VIEW).then((function(_this) {
      return function(references) {
        return _.forEach(references, function(reference) {
          var copiedReferenceAttrs, id, key, keywords, oldId, referenceObj, tags, value, year, _ref, _ref1, _ref2, _ref3;
          oldId = _this._id;
          _this._id += 1;
          id = "_:r" + oldId;
          copiedReferenceAttrs = _.pick(reference, 'abstract', 'source', 'volume', 'issue', 'pages', 'title', 'DOI', 'PMID');
          referenceObj = _.extend(copiedReferenceAttrs, {
            '@type': 'Reference',
            '@id': id,
            'author': _.map(reference.authors, function(author) {
              return {
                '@type': 'Person',
                'name': "" + author.lastName + ", " + (author.firstNames.join(' '))
              };
            }),
            'publisher': reference.publishedIn
          });
          tags = (_ref = reference.tags) != null ? _ref : [];
          keywords = (_ref1 = reference.authorKeywords) != null ? _ref1 : [];
          year = (_ref2 = reference.year) != null ? (_ref3 = _ref2.match(/\d{4}/)) != null ? _ref3[0] : void 0 : void 0;
          if (year) {
            referenceObj['year'] = year;
          }
          if (tags.length > 0) {
            referenceObj['tags'] = tags;
          }
          if (keywords.length > 0) {
            referenceObj['keywords'] = keywords;
          }
          _this._idMap[reference['_id']] = id;
          _this._reverseIdMap[id] = reference['_id'];
          for (key in referenceObj) {
            value = referenceObj[key];
            if (!value) {
              delete referenceObj[key];
            }
          }
          return _this._references[reference['_id']] = referenceObj;
        });
      };
    })(this));
  };

  ReferencesExporter.prototype.getReferenceJSONLDId = function(id) {
    if (this._idMap[id] != null) {
      this._referencesIdsUsed.push(id);
      return this._idMap[id];
    } else {
      return null;
    }
  };

  ReferencesExporter.prototype.getReferenceGdtId = function(id) {
    return this._reverseIdMap[id];
  };

  ReferencesExporter.prototype.getUsedReferencesAndClearIds = function() {
    var usedReferences;
    usedReferences = _.chain(this._referencesIdsUsed).uniq().map((function(_this) {
      return function(id) {
        return _this._references[id];
      };
    })(this)).sortBy(function(reference) {
      return parseInt(reference['@id'].replace('_:r', ''));
    }).clone().value();
    this._referencesIdsUsed = [];
    return usedReferences;
  };

  ReferencesExporter.prototype.exportReferencesForEtdCell = function(draftJSContent) {
    var content, contentImmutable, entityMap;
    content = _.isString(draftJSContent) ? {} : draftJSContent;
    contentImmutable = Immutable.fromJS(content != null ? content : {});
    entityMap = contentImmutable.get('entityMap', Immutable.Map());
    return _.chain(getReferencesFromEntityMap(entityMap)).map(this.getReferenceJSONLDId).filter(function(id) {
      return id != null;
    }).sortBy(function(id) {
      return parseInt(id.replace('_:r', ''));
    }).map(function(id) {
      return {
        '@id': id
      };
    }).value();
  };

  return ReferencesExporter;

})();
