var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"footnotes-header\">\n  <div class=\"switcher-container\">\n    <label class=\"switcher switcher--cell-filter\">\n      <input type=\"checkbox\" disabled=\"disabled\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.filterByActiveCell : depth0), {"name":"if","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n      <div class=\"slider round\"></div>\n    </label>\n    <button class=\"filter-by-active-cell-btn\">\n      <span class=\"switch-btn-text\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "filter_by_active_cell", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n    </button>\n  </div>\n  <div class=\"tabspane\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.tabs : depth0), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "checked=\"checked\"";
  },"4":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:footnotes", {"name":"i18nNamespace","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "        <div data-tab=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\" class=\"tab ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depths[2] != null ? depths[2].activeTab : depths[2]), depths[1], {"name":"is","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, depth0, {"name":"i18n","hash":{},"data":data})))
    + "\n        </div>\n";
},"6":function(depth0,helpers,partials,data) {
  return "tab--selected";
  },"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "    <div class=\"footnotes-tab-panel active\">\n";
  stack1 = ((helpers.empty || (depth0 && depth0.empty) || helperMissing).call(depth0, (depth0 != null ? depth0.footnotesList : depth0), {"name":"empty","hash":{},"fn":this.program(9, data),"inverse":this.program(11, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n";
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"no-footnotes\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:footnotes.no_explanations", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <ol class=\"footnotes-list\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.footnotesList : depth0), {"name":"each","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </ol>\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:footnotes", {"name":"i18nNamespace","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <li data-footnote-id=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-footnote-special=\""
    + escapeExpression(((helper = (helper = helpers.special || (depth0 != null ? depth0.special : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"special","hash":{},"data":data}) : helper)))
    + "\">\n              <div class=\"counter\">"
    + escapeExpression(((helper = (helper = helpers.counter || (depth0 != null ? depth0.counter : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"counter","hash":{},"data":data}) : helper)))
    + ".&nbsp;</div>\n              <div class=\"display-footnote\">\n                <div class=\"inner\">\n                  <div class=\"note\">\n                    <span class=\"note-text\">"
    + escapeExpression(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"note","hash":{},"data":data}) : helper)))
    + "</span>\n                  </div>\n                  <div class=\"item-controls\">\n                    <button class=\"edit\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "edit", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n                  </div>\n                </div>\n              </div>\n              <div class=\"edit-footnote hidden\">\n                <div class=\"inner\">\n                  <div class=\"footnote-input-container\">\n                    <span class=\"limit-info\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "text_limit_info", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n                    <textarea class=\"footnote-input-field\" name=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"note","hash":{},"data":data}) : helper)))
    + "</textarea>\n                  </div>\n                  <div class=\"item-controls\">\n                    <button class=\"save\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "save", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n                    <button class=\"delete\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "delete", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n                  </div>\n                </div>\n              </div>\n            </li>\n";
},"15":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "    <div class=\"references-tab-panel active\">\n";
  stack1 = ((helpers.empty || (depth0 && depth0.empty) || helperMissing).call(depth0, (depth0 != null ? depth0.referencesList : depth0), {"name":"empty","hash":{},"fn":this.program(16, data),"inverse":this.program(18, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n";
},"16":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"no-footnotes\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:footnotes.no_references", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n";
},"18":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <ol class=\"references-list\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.referencesList : depth0), {"name":"each","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </ol>\n";
},"19":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <li data-reference-id=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n              <div class=\"counter\">"
    + escapeExpression(((helper = (helper = helpers.counter || (depth0 != null ? depth0.counter : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"counter","hash":{},"data":data}) : helper)))
    + ".&nbsp;</div>\n              <span>"
    + escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"text","hash":{},"data":data}) : helper)))
    + "</span>\n            </li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:footnotes", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n<div class=\"footnotes-block\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.activeTab : depth0), "footnotes", {"name":"is","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.activeTab : depth0), "references", {"name":"is","hash":{},"fn":this.program(15, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}