var INITIAL_STATE, MultiComparisonsActions, MultiComparisonsStore, alt, errorHandler, immutable, swapItems;

alt = require('alt');

errorHandler = require('lib/app_utils').errorHandler;

swapItems = require('lib/immutable_utils').swapItems;

immutable = require('stores/utils/immutable');

MultiComparisonsActions = require('actions/multi_comparisons_actions');

INITIAL_STATE = Immutable.fromJS({
  meta: {
    fetching: false,
    fetchingEtdsTemplateData: false,
    savingComparison: false,
    editingComparison: null,
    editingComparisonInCollapsableView: false,
    showingExportDialog: false
  },
  comparisonsEditData: {
    _id: null,
    _rev: null,
    selectedQuestions: [],
    selectedInterventions: [],
    overarchingQuestion: '',
    interventionsComparableDesc: '',
    recommendations: [
      {
        strength: 'strong',
        text: null
      }
    ],
    justification: null,
    subgroupConsiderations: null,
    implementationConsiderations: null,
    monitoringAndEvaluation: null,
    researchPriorities: null,
    sectionsImportanceForDecision: {},
    sectionGradingComments: {},
    interventionsRating: {},
    hiddenSections: {
      soj: {},
      grading: {}
    },
    hiddenInterventions: {}
  },
  multiComparisonsList: [],
  etds: {}
});

MultiComparisonsStore = (function() {
  function MultiComparisonsStore() {
    this.state = INITIAL_STATE;
    this.bindActions(MultiComparisonsActions);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      isFetchingEtdsTemplateData: this.isFetchingEtdsTemplateData,
      isEditingComparison: this.isEditingComparison,
      isEditingComparisonInCollapsableView: this.isEditingComparisonInCollapsableView,
      isSavingComparison: this.isSavingComparison,
      isShowingExportDialog: this.isShowingExportDialog,
      getComparisonsEditStep: this.getComparisonsEditStep,
      getComparisonsEditData: this.getComparisonsEditData,
      getMultiComparisonsList: this.getMultiComparisonsList,
      getComparisonData: this.getComparisonData,
      getEtds: this.getEtds
    });
  }

  MultiComparisonsStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['meta', 'fetching'], true));
  };

  MultiComparisonsStore.prototype.onFetchSuccess = function(docs) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetching'], false);
      return state.set('multiComparisonsList', Immutable.fromJS(docs));
    }));
  };

  MultiComparisonsStore.prototype.onFetchFailure = function(err) {
    errorHandler(err);
    return this.setState(this.state.setIn(['meta', 'fetching'], false));
  };

  MultiComparisonsStore.prototype.onFetchDetails = function() {
    return this.setState(this.state.setIn(['meta', 'fetching'], true));
  };

  MultiComparisonsStore.prototype.onFetchDetailsSuccess = function(mcData) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetching'], false);
      return state.set('comparisonsEditData', mcData);
    }));
  };

  MultiComparisonsStore.prototype.onFetchDetailsFailure = function(err) {
    errorHandler(err);
    return this.setState(this.state.setIn(['meta', 'fetching'], false));
  };

  MultiComparisonsStore.prototype.onFetchEtdsTemplateData = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingEtdsTemplateData'], true));
  };

  MultiComparisonsStore.prototype.onFetchEtdsTemplateDataSuccess = function(etds) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetchingEtdsTemplateData'], false);
      return state.set('etds', Immutable.fromJS(etds));
    }));
  };

  MultiComparisonsStore.prototype.onFetchEtdsTemplateDataFailure = function(err) {
    errorHandler(err);
    return this.setState(this.state.setIn(['meta', 'fetchingEtdsTemplateData'], false));
  };

  MultiComparisonsStore.prototype.onAddEtdTemplateData = function(_arg) {
    var etdId, etdTemplateData;
    etdId = _arg.etdId, etdTemplateData = _arg.etdTemplateData;
    return this.setState(this.state.setIn(['etds', etdId], Immutable.fromJS(etdTemplateData)));
  };

  MultiComparisonsStore.prototype.onCreateNewComparison = function() {
    return this.setState(this.state.withMutations(function(state) {
      return state.set('comparisonsEditData', INITIAL_STATE.get('comparisonsEditData'));
    }));
  };

  MultiComparisonsStore.prototype.onEditMultiComparison = function(_arg) {
    var comparisonData, editInCollapsableView, mcId;
    mcId = _arg.mcId, editInCollapsableView = _arg.editInCollapsableView;
    comparisonData = this.getComparisonData(mcId);
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'editingComparison'], true);
      state.setIn(['meta', 'editingComparisonInCollapsableView'], editInCollapsableView);
      return state.set('comparisonsEditData', comparisonData);
    }));
  };

  MultiComparisonsStore.prototype.onUpdateMultiComparison = function(doc) {
    var comparisonDataIdx, comparisonsList, updatedList;
    comparisonsList = this.state.get('multiComparisonsList');
    comparisonDataIdx = comparisonsList.findIndex(function(mc) {
      return mc.get('_id') === doc._id;
    });
    if (doc._deleted) {
      return this.setState(this.state.set('multiComparisonsList', comparisonsList["delete"](comparisonDataIdx)));
    } else {
      updatedList = comparisonDataIdx === -1 ? comparisonsList.push(Immutable.fromJS(doc)) : comparisonsList.update(comparisonDataIdx, function() {
        return Immutable.fromJS(doc);
      });
      return this.setState(this.state.set('multiComparisonsList', updatedList));
    }
  };

  MultiComparisonsStore.prototype._getInterventionsList = function(questions) {
    return questions.flatMap(function(q) {
      var list;
      if (q.get('type') === 'diagnostic') {
        list = Immutable.List([q.get('indexTest')]);
        if (q.get('comparatorTestPresent')) {
          return list.push(q.get('comparatorTest'));
        } else {
          return list;
        }
      } else {
        return Immutable.List([q.get('intervention'), q.get('comparison')]);
      }
    }).toSet().toList();
  };

  MultiComparisonsStore.prototype.onSelectComparisonQuestion = function(questionData) {
    var interventionsPath, questionsPath, updatedQuestions;
    if (!questionData) {
      return;
    }
    questionsPath = ['comparisonsEditData', 'selectedQuestions'];
    interventionsPath = ['comparisonsEditData', 'selectedInterventions'];
    updatedQuestions = this.state.getIn(questionsPath).push(questionData);
    return this.setState(this.state.withMutations((function(_this) {
      return function(state) {
        state.setIn(questionsPath, updatedQuestions);
        return state.setIn(interventionsPath, _this._getInterventionsList(updatedQuestions));
      };
    })(this)));
  };

  MultiComparisonsStore.prototype.onRemoveComparisonQuestion = function(questionId) {
    var filteredOutQuestions, interventionsPath, questionsPath;
    questionsPath = ['comparisonsEditData', 'selectedQuestions'];
    interventionsPath = ['comparisonsEditData', 'selectedInterventions'];
    filteredOutQuestions = this.state.getIn(questionsPath).filter(function(q) {
      return q.get('questionId') !== questionId;
    });
    return this.setState(this.state.withMutations((function(_this) {
      return function(state) {
        state.setIn(questionsPath, filteredOutQuestions);
        return state.setIn(interventionsPath, _this._getInterventionsList(filteredOutQuestions));
      };
    })(this)));
  };

  MultiComparisonsStore.prototype.onUpdateComparisonQuestion = function(_arg) {
    var interventionsPath, newQuestionData, oldQuestionId, oldQuestionIndex, questionsPath, updatedQuestions;
    oldQuestionId = _arg.oldQuestionId, newQuestionData = _arg.newQuestionData;
    if (!newQuestionData) {
      return;
    }
    questionsPath = ['comparisonsEditData', 'selectedQuestions'];
    interventionsPath = ['comparisonsEditData', 'selectedInterventions'];
    oldQuestionIndex = this.state.getIn(questionsPath).findIndex(function(q) {
      return q.get('questionId') === oldQuestionId;
    });
    updatedQuestions = this.state.getIn(questionsPath).set(oldQuestionIndex, newQuestionData);
    return this.setState(this.state.withMutations((function(_this) {
      return function(state) {
        state.setIn(questionsPath, updatedQuestions);
        return state.setIn(interventionsPath, _this._getInterventionsList(updatedQuestions));
      };
    })(this)));
  };

  MultiComparisonsStore.prototype.onUpdateOverarchingQuestion = function(newValue) {
    return this.setState(this.state.setIn(['comparisonsEditData', 'overarchingQuestion'], newValue));
  };

  MultiComparisonsStore.prototype.onUpdateInterventionsComparableDesc = function(description) {
    return this.setState(this.state.setIn(['comparisonsEditData', 'interventionsComparableDesc'], description));
  };

  MultiComparisonsStore.prototype.onUpdateRecommendationText = function(_arg) {
    var attrName, path, value;
    attrName = _arg.attrName, value = _arg.value;
    path = ['comparisonsEditData', 'recommendations', attrName, 'text'];
    return this.setState(this.state.setIn(path, value));
  };

  MultiComparisonsStore.prototype.onSetRecommendationStrength = function(_arg) {
    var idx, value;
    idx = _arg.idx, value = _arg.value;
    return this.setState(this.state.updateIn(['comparisonsEditData', 'recommendations', idx], function(recommendation) {
      return recommendation.set('strength', value);
    }));
  };

  MultiComparisonsStore.prototype.onAddRecommendation = function() {
    return this.setState(this.state.updateIn(['comparisonsEditData', 'recommendations'], function(recommendations) {
      return recommendations.push(Immutable.Map({
        text: null,
        value: null
      }));
    }));
  };

  MultiComparisonsStore.prototype.onRemoveRecommendation = function(idx) {
    return this.setState(this.state.updateIn(['comparisonsEditData', 'recommendations'], function(recommendations) {
      return recommendations["delete"](idx);
    }));
  };

  MultiComparisonsStore.prototype.onUpdateComparisonsTextAttribute = function(_arg) {
    var attrName, value;
    attrName = _arg.attrName, value = _arg.value;
    return this.setState(this.state.setIn(['comparisonsEditData', attrName], value));
  };

  MultiComparisonsStore.prototype.onGoToComparisonsList = function() {
    return this.setState(this.state.setIn(['meta', 'editingComparison'], false));
  };

  MultiComparisonsStore.prototype.onSaveComparison = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.set('comparisonsEditData', INITIAL_STATE.get('comparisonsEditData'));
      return state.setIn(['meta', 'savingComparison'], true);
    }));
  };

  MultiComparisonsStore.prototype.onCreateComparisonSuccess = function(updatedDoc) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'savingComparison'], false);
      state.setIn(['meta', 'editingComparison'], false);
      state.setIn(['comparisonsEditData', '_id'], updatedDoc._id);
      return state.setIn(['comparisonsEditData', '_rev'], updatedDoc._rev);
    }));
  };

  MultiComparisonsStore.prototype.onSaveInBackgroundSuccess = function(updatedDoc) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'savingComparison'], false);
      state.setIn(['meta', 'editingComparison'], true);
      state.setIn(['comparisonsEditData', '_id'], updatedDoc._id);
      return state.setIn(['comparisonsEditData', '_rev'], updatedDoc._rev);
    }));
  };

  MultiComparisonsStore.prototype.onSaveComparisonSuccess = function(updatedDoc) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'savingComparison'], false);
      state.setIn(['meta', 'editingComparison'], false);
      state.setIn(['comparisonsEditData', '_id'], updatedDoc._id);
      return state.setIn(['comparisonsEditData', '_rev'], updatedDoc._rev);
    }));
  };

  MultiComparisonsStore.prototype.onSaveComparisonFailure = function(err) {
    errorHandler(err);
    return this.setState(this.state.setIn(['meta', 'savingComparison'], false));
  };

  MultiComparisonsStore.prototype.onUpdateSectionImportanceForDecision = function(_arg) {
    var importance, path, section;
    section = _arg.section, importance = _arg.importance;
    path = ['comparisonsEditData', 'sectionsImportanceForDecision', section];
    return this.setState(this.state.setIn(path, importance));
  };

  MultiComparisonsStore.prototype.onUpdateSectionGradingComments = function(_arg) {
    var comment, path, section;
    section = _arg.section, comment = _arg.comment;
    path = ['comparisonsEditData', 'sectionGradingComments', section];
    return this.setState(this.state.setIn(path, comment));
  };

  MultiComparisonsStore.prototype._moveListItem = function(list, itemIdx, direction) {
    return swapItems(list, itemIdx, itemIdx + direction);
  };

  MultiComparisonsStore.prototype.onMoveSelectedQuestion = function(_arg) {
    var direction, path, questionId, questionIdx, selectedQuestions, updatedSelectedQuestions;
    questionId = _arg.questionId, direction = _arg.direction;
    path = ['comparisonsEditData', 'selectedQuestions'];
    selectedQuestions = this.state.getIn(path);
    questionIdx = selectedQuestions.findIndex(function(q) {
      return q.get('questionId') === questionId;
    });
    updatedSelectedQuestions = this._moveListItem(selectedQuestions, questionIdx, direction);
    return this.setState(this.state.setIn(path, updatedSelectedQuestions));
  };

  MultiComparisonsStore.prototype.onMoveSelectedIntervention = function(_arg) {
    var direction, intervention, interventionIdx, path, selectedInterventions, updatedSelectedInterventions;
    intervention = _arg.intervention, direction = _arg.direction;
    path = ['comparisonsEditData', 'selectedInterventions'];
    selectedInterventions = this.state.getIn(path);
    interventionIdx = selectedInterventions.findIndex(function(i) {
      return i === intervention;
    });
    updatedSelectedInterventions = this._moveListItem(selectedInterventions, interventionIdx, direction);
    return this.setState(this.state.setIn(path, updatedSelectedInterventions));
  };

  MultiComparisonsStore.prototype.onUpdateInterventionRating = function(_arg) {
    var intervention, path, rating, section;
    section = _arg.section, intervention = _arg.intervention, rating = _arg.rating;
    path = ['comparisonsEditData', 'interventionsRating', section, intervention];
    return this.setState(this.state.setIn(path, rating));
  };

  MultiComparisonsStore.prototype.onToggleReviewTableRow = function(_arg) {
    var hidden, path, section, tableName;
    section = _arg.section, tableName = _arg.tableName;
    path = ['comparisonsEditData', 'hiddenSections', tableName, section];
    hidden = this.state.getIn(path, false);
    return this.setState(this.state.setIn(path, !hidden));
  };

  MultiComparisonsStore.prototype.onToggleInterventionColumn = function(_arg) {
    var hidden, intervention, path;
    intervention = _arg.intervention;
    path = ['comparisonsEditData', 'hiddenInterventions', intervention];
    hidden = this.state.getIn(path, false);
    return this.setState(this.state.setIn(path, !hidden));
  };

  MultiComparisonsStore.prototype.onShowExportDialog = function() {
    return this.setState(this.state.setIn(['meta', 'showingExportDialog'], true));
  };

  MultiComparisonsStore.prototype.onHideExportDialog = function() {
    return this.setState(this.state.setIn(['meta', 'showingExportDialog'], false));
  };

  MultiComparisonsStore.prototype.onDelete = function() {
    return this.setState(this.state.update('comparisonsEditData', function(data) {
      return data.set('_deleted', true);
    }));
  };

  MultiComparisonsStore.prototype.isFetching = function() {
    return this.state.getIn(['meta', 'fetching']);
  };

  MultiComparisonsStore.prototype.isFetchingEtdsTemplateData = function() {
    return this.state.getIn(['meta', 'fetchingEtdsTemplateData']);
  };

  MultiComparisonsStore.prototype.isEditingComparison = function() {
    return this.state.getIn(['meta', 'editingComparison']);
  };

  MultiComparisonsStore.prototype.isEditingComparisonInCollapsableView = function() {
    return this.state.getIn(['meta', 'editingComparisonInCollapsableView']);
  };

  MultiComparisonsStore.prototype.isSavingComparison = function() {
    return this.state.getIn(['meta', 'savingComparison']);
  };

  MultiComparisonsStore.prototype.isShowingExportDialog = function() {
    return this.state.getIn(['meta', 'showingExportDialog']);
  };

  MultiComparisonsStore.prototype.getComparisonsEditStep = function() {
    return this.state.getIn(['meta', 'comparisonsEditStep']);
  };

  MultiComparisonsStore.prototype.getComparisonsEditData = function() {
    return this.state.get('comparisonsEditData');
  };

  MultiComparisonsStore.prototype.getMultiComparisonsList = function() {
    return this.state.get('multiComparisonsList');
  };

  MultiComparisonsStore.prototype.getComparisonData = function(comparisonId) {
    return this.state.get('multiComparisonsList').find(function(mc) {
      return mc.get('_id') === comparisonId;
    });
  };

  MultiComparisonsStore.prototype.getEtds = function() {
    return this.state.get('etds');
  };

  return MultiComparisonsStore;

})();

module.exports = alt.createStore(immutable(MultiComparisonsStore), 'MultiComparisonsStore');
