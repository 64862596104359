var CellFootnotesAndReferencesView, ContextMenuView, HelpBoxView, HelpResources, SelectionView, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

SelectionView = require('base/views/selection_view');

CellFootnotesAndReferencesView = require('views/evidence_syntheses/cell_footnotes_and_references_view');

mediator = require('mediator');

HelpBoxView = require('views/help_box_view');

HelpResources = require('lib/help_resources');

utils = require('base/lib/utils');

module.exports = ContextMenuView = (function(_super) {
  __extends(ContextMenuView, _super);

  function ContextMenuView() {
    this.showFootnotesBox = __bind(this.showFootnotesBox, this);
    this._acceptHandler = __bind(this._acceptHandler, this);
    return ContextMenuView.__super__.constructor.apply(this, arguments);
  }

  ContextMenuView.prototype.className = 'outcomes-context-menu';

  ContextMenuView.prototype.initialize = function() {
    var _base;
    this.options.listItems = [
      {
        value: 'footnotes',
        text: $.t('es:footnotes.title_plural'),
        button: true,
        disabled: this.options.footnotesDisabled,
        disabledTooltip: $.t('es:footnotes.tooltip_disabled')
      }, {
        value: 'references',
        text: $.t('es:footnotes.references'),
        button: true,
        disabled: this.options.footnotesDisabled,
        disabledTooltip: $.t('es:footnotes.tooltip_disabled')
      }
    ];
    if (HelpResources.prototype.helpEnabled(this.options.property)) {
      this.options.listItems.push({
        value: 'help',
        text: $.t('help.title'),
        button: true,
        buttonClass: 'context-help'
      });
    }
    if ((_base = this.options).acceptHandler == null) {
      _base.acceptHandler = this._acceptHandler;
    }
    return ContextMenuView.__super__.initialize.apply(this, arguments);
  };

  ContextMenuView.prototype.show = function(offset) {
    this.offset = offset;
    return ContextMenuView.__super__.show.apply(this, arguments);
  };

  ContextMenuView.prototype._acceptHandler = function() {
    var helpBoxView;
    switch (this.getSelectedItemValue()) {
      case 'footnotes':
        return this.showFootnotesBox();
      case 'references':
        return this.showFootnotesBox('references');
      case 'help':
        helpBoxView = new HelpBoxView({
          parentView: this.options.propertyView,
          property: this.options.property
        });
        return helpBoxView.show(this.offset);
    }
  };

  ContextMenuView.prototype.showFootnotesBox = function(tabToShow) {
    if (tabToShow == null) {
      tabToShow = 'footnotes';
    }
    if (!this.options.property) {
      return;
    }
    this.subview('footnotes', new CellFootnotesAndReferencesView({
      questionModel: this.options.questionModel,
      footnotesBindingModel: this.options.footnotesBindingModel,
      activeTab: tabToShow,
      property: this.options.property,
      propertyView: this.options.propertyView
    }));
    return this.subview('footnotes').show({
      container: $('#page-container'),
      cell: this.options.cell
    });
  };

  ContextMenuView.prototype.hide = function() {
    if (!this.hidden) {
      this.$el.remove();
      return this.hidden = true;
    }
  };

  return ContextMenuView;

})(SelectionView);
