ConnectStore = require 'components/enhancers/connect_store'
RevManWebStore = require 'stores/revman_web_store'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
{ instanceOf, func, oneOf, oneOfType, string } = PropTypes

storeConnectors =
  RevManWebStore: (Store) ->
    error: Store.getError()
    status: Store.getStatus()

RevManStatus = createReactClass
  displayName: 'RevManStatus'
  mixins: [Translation('archie:revman.status')]
  propTypes:
    onClick: func.isRequired
    status: oneOf(['offline', 'error', 'success', 'syncing']).isRequired
    error: oneOfType([string, instanceOf(Error)])

  getDefaultProps: ->
    error: null

  onClick: ->
    { onClick, status } = @props
    onClick() if status isnt 'syncing'

  render: ->
    { error, status } = @props
    title = if error
      errorMessage = if _.isString error
        error ? @i18n 'error'
      else
        error.message ? @i18n 'error'
      @i18n 'statuses.error.description', { errorMessage }
    else
      @i18n "statuses.#{status}.description"

    <Tooltip>
      <span
        className={classNames('revman-sync-status', "revman-sync-status__#{status}")}
        onClick={@onClick}
        title={title}
      >
        {status isnt 'offline' and <span className='revman-sync-status__image' />}
        {status isnt 'syncing' and <span className='revman-sync-status__text'>
          {@i18n "statuses.#{status}.title"}
        </span>}
      </span>
    </Tooltip>

module.exports = ConnectStore RevManStatus, [RevManWebStore], storeConnectors
