PanelVoiceEtdVotingAction = require 'actions/panel_voice_etd_actions'
IconButton = require 'components/common/icon_button'
{
  VOTING_RESULTS_VIEW_TYPES
} = require 'lib/etd_helper'

VotingControls = ({
  etdPart,
  isProposedVoting,
  renderMode
  resultsViewType,
  sectionId,
  votingStatus,
}) ->
  isConclusionsSectionWithOpenVoting = etdPart is 'conclusions' and not isProposedVoting

  toggleResultsViewType = (type) -> ->
    PanelVoiceEtdVotingAction.setSectionResultsViewType { type, sectionId }

  return null if renderMode is 'printout'
  <div className='results-view-type-controls'>
    {VOTING_RESULTS_VIEW_TYPES.map (type) =>
      <IconButton
        className={classNames inactive: resultsViewType isnt type }
        disabled={isConclusionsSectionWithOpenVoting or votingStatus is 'test'}
        iconName={type}
        key={type}
        onClick={toggleResultsViewType type}
      />
    }
  </div>

module.exports = VotingControls
