var ModuleController, MultiComparisonCreateView, MultiComparisonDetailsView, MultiComparisonsController, MultiComparisonsListView,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

MultiComparisonsListView = require('views/multi_comparisons/multi_comparisons_list_view');

MultiComparisonDetailsView = require('views/multi_comparisons/multi_comparison_details_view');

MultiComparisonCreateView = require('views/multi_comparisons/multi_comparison_create_view');

module.exports = MultiComparisonsController = (function(_super) {
  __extends(MultiComparisonsController, _super);

  function MultiComparisonsController() {
    return MultiComparisonsController.__super__.constructor.apply(this, arguments);
  }

  MultiComparisonsController.prototype.defaultSubmodule = 'list';

  MultiComparisonsController.prototype.viewsForSubmodules = {
    'list': MultiComparisonsListView,
    'details': MultiComparisonDetailsView,
    'create': MultiComparisonCreateView
  };

  return MultiComparisonsController;

})(ModuleController);
