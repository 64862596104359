var MDGTable, MDGTableActions, MDGTableView, MDGToolbarView, ReactComponent, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

MDGTableActions = require('actions/mdg_table_actions');

MDGTable = require('components/mdg_tables/table');

MDGToolbarView = require('views/mdg_tables/toolbar_view');

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

View = require('base/views/view');

module.exports = MDGTableView = (function(_super) {
  __extends(MDGTableView, _super);

  function MDGTableView() {
    return MDGTableView.__super__.constructor.apply(this, arguments);
  }

  MDGTableView.prototype.container = '#page-container';

  MDGTableView.prototype.autoRender = true;

  MDGTableView.prototype._stopInputsPropagation = false;

  MDGTableView.prototype.initialize = function() {
    MDGTableView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new MDGToolbarView({
      mode: 'table'
    }));
    return this.enable(ReactComponent);
  };

  MDGTableView.prototype.afterRender = function() {
    var questionId;
    MDGTableView.__super__.afterRender.apply(this, arguments);
    questionId = this.options.questionId;
    return this.renderComponent(MDGTable, MDGTableActions, questionId, {
      fetchAndListen: {
        dbId: mediator.project.id,
        opts: {
          filter: function(doc) {
            return doc._id === questionId || doc.docType === 'reference';
          }
        }
      }
    });
  };

  MDGTableView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('MDGTableStore');
    return MDGTableView.__super__.dispose.apply(this, arguments);
  };

  return MDGTableView;

})(View);
