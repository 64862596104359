Criterion = require 'components/etd/criterion'
JudgementCell = require 'components/etd/judgement_cell'
ResearchEvidence = require 'components/etd/research_evidence'
AdditionalConsideration = require 'components/etd/additional_consideration'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EditButton = require 'components/common/edit_button'
PanelVoiceEtdActions = require 'actions/panel_voice_etd_actions'
Translation = require 'components/mixins/translation'

{ getSelectedOptionFromSection } = require 'lib/etd_helper'


{ SectionRow } = ReactComponents

AssessmentSectionRow = createReactClass
  displayName: 'AssessmentSectionRow'
  mixins: [CustomRenderMixin, Translation('es:recommendations')]

  propTypes:
    activeTab: PropTypes.string
    additionalConsiderations: PropTypes.instanceOf(Immutable.Map).isRequired
    additionalConsiderationsVisible: PropTypes.bool.isRequired
    attachments: PropTypes.instanceOf(Immutable.Map).isRequired
    criterion: PropTypes.instanceOf(Immutable.Map).isRequired
    criticalOutcomes: PropTypes.instanceOf(Immutable.List).isRequired
    editable: PropTypes.bool.isRequired
    isAdoloped: PropTypes.bool,
    outcomes: PropTypes.instanceOf(Immutable.List).isRequired
    originalJudgements: PropTypes.instanceOf(Immutable.Map)
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map)
    qualityIndicators: PropTypes.instanceOf(Immutable.List)
    questionType: PropTypes.string.isRequired
    renderMode: PropTypes.string.isRequired
    researchEvidence: PropTypes.instanceOf(Immutable.Map).isRequired
    sectionData: PropTypes.instanceOf(Immutable.Map).isRequired
    sectionId: PropTypes.string.isRequired
    sectionKey: PropTypes.string.isRequired
    sectionName: PropTypes.string.isRequired
    showJudgement: PropTypes.bool
    templateId: PropTypes.string.isRequired
    type: PropTypes.oneOf(['overarching', 'keyMessages', 'regular', 'qualityIndicators'])

  getDefaultProps: ->
    activeTab: null
    criticalOutcomes: Immutable.List()
    editable: true
    isAdoloped: false
    outcomes: Immutable.List()
    originalJudgements: Immutable.Map()
    overarchingQuestionData: Immutable.Map()
    qualityIndicators: Immutable.List()
    showJudgement: true
    type: 'regular'

  _getSectionAttachments: ->
    { attachments, sectionId } = @props

    attachments.filter (attachment, attachmentId) ->
      [ attachmentSectionId ] = attachmentId.split('#')
      if sectionId in ['desirableEffects', 'undesirableEffects']
        attachmentSectionId in ['effects', sectionId]
      else
        attachmentSectionId is sectionId

  _isConsensus: ->
    @props.activeTab? and @props.activeTab isnt 'draftJudgement'

  render: ->
    {
      activeTab
      additionalConsiderations
      additionalConsiderationsVisible
      criterion
      criticalOutcomes
      currentEditable
      editable
      highlightToPanelMembers
      isAdoloped
      onEditClick
      originalJudgements
      outcomes
      overarchingQuestionData
      parentSectionsData
      qualityIndicators
      questionType
      readOnly
      renderMode
      researchEvidence
      sectionData
      sectionId
      sectionKey
      sectionName
      showJudgement
      templateId
      type
      withAdolopment
      withPanelVoice
    } = @props

    isVotingResultsView = withPanelVoice or activeTab is 'votingResults'
    rowSpan = if isVotingResultsView then 3 else 1

    researchEvidenceColSpan = if additionalConsiderationsVisible then 1 else 2
    selectedOption = getSelectedOptionFromSection @_isConsensus(), criterion

    highlightAsDiff = if not originalJudgements.isEmpty()
      originalOption = getSelectedOptionFromSection false, originalJudgements.last().get(sectionId)
      selectedOption != originalOption and not _.isEmpty(selectedOption)
    else false

    judgementClassName = classNames 'judgement',
      highlighted: highlightToPanelMembers
      changed: highlightAsDiff

    <SectionRow className={_.string.underscored sectionId}>
      <JudgementCell
        className={judgementClassName}
        criterion={criterion}
        criticalOutcomes={criticalOutcomes}
        editable={editable}
        highlightAsDiff={highlightAsDiff}
        highlighted={highlightToPanelMembers}
        isConsensus={@_isConsensus()}
        sectionData={sectionData}
        parentSectionsData={parentSectionsData}
        onEditClick={onEditClick}
        qualityIndicators={qualityIndicators}
        overarchingQuestionData={overarchingQuestionData}
        questionType={questionType}
        readOnly={readOnly}
        renderMode={renderMode}
        sectionId={sectionId}
        sectionName={sectionName}
        selectedOption={selectedOption}
        showJudgement={showJudgement}
        templateId={templateId}
        type={type}
      />
      {rePlaceholder = if isAdoloped and withAdolopment
          @i18n 'placeholders.research_evidence'
        else null
        <ResearchEvidence
          attachments={@_getSectionAttachments()}
          editable={editable}
          cellId={"#{sectionData.get('researchEvidenceId')}#researchEvidences"}
          colSpan={researchEvidenceColSpan}
          currentEditable={currentEditable}
          highlighted={highlightToPanelMembers}
          isConsensus={@_isConsensus()}
          key={"#{sectionKey}-research-evidence"}
          outcomes={outcomes}
          onEditClick={onEditClick}
          placeholder={rePlaceholder}
          questionType={questionType}
          readOnly={readOnly}
          renderMode={renderMode}
          researchEvidence={researchEvidence}
        />
      }
      {if additionalConsiderationsVisible
        acPlaceholder = if isAdoloped and withAdolopment
          @i18n 'placeholders.additional_considerations'
        else null
        <AdditionalConsideration
          additionalConsiderations={additionalConsiderations}
          cellId=
            {"#{sectionData.get('additionalConsiderationId')}#additionalConsiderations"}
          currentEditable={currentEditable}
          editable={editable}
          highlighted={highlightToPanelMembers}
          isConsensus={@_isConsensus()}
          key={"#{sectionKey}-additional-considerations"}
          onEditClick={onEditClick}
          placeholder={acPlaceholder}
          readOnly={readOnly}
          renderMode={renderMode}
          rowSpan={additionalConsiderations.get 'rowSpan'}
        />
      }
    </SectionRow>

module.exports = AssessmentSectionRow
