Confirmation = require 'components/common/confirmation'
ConnectStore = require 'components/enhancers/connect_store'
Modal = require 'components/common/modal'
MultiComparisonQuestionsGroup = require './multi_comparison_questions_group'
MultiComparisonsActions = require 'actions/multi_comparisons_actions'
MultiComparisonsStore = require 'stores/multi_comparisons_store'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
{ filterGoodPracticeStatement } = require 'lib/questions_helper'
{ filterQuestionKeys } = require 'lib/multi_comparisons_helper'

storeConnectors =
  MultiComparisonsStore: (Store) ->
    editingComparison: Store.isEditingComparison()
    comparisonsEditData: Store.getComparisonsEditData()
    isFetchingMultiComparisons: Store.isFetching()
    multiComparisonsList: Store.getMultiComparisonsList()
  QuestionsStore: (Store) ->
    # We don't need all question fields here.
    # Question ID is stored under 'questionId' field to indicate that it's not a document
    questions: Store.getQuestions().filterNot(filterGoodPracticeStatement).map filterQuestionKeys
    isFetchingQuestions: Store.isFetching()
  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups()
    isFetchingQuestionGroups: Store.isFetching()

MultiComparisons = createReactClass
  displayName: 'MultiComparisons'

  mixins: [ Translation('')]

  propTypes:
    editingComparison: PropTypes.oneOfType [
      PropTypes.bool,
      PropTypes.string
    ]
    comparisonsEditData: PropTypes.instanceOf(Immutable.Map)
    multiComparisonsList: PropTypes.instanceOf(Immutable.List).isRequired
    isFetchingMultiComparisons: PropTypes.bool.isRequired
    isFetchingQuestions: PropTypes.bool.isRequired

  isFetching: ->
    @props.isFetchingMultiComparisons or @props.isFetchingQuestionGroups

  getInitialState: ->
    showConfirmDeleteModal: false
    mcIdToDelete: null

  openDeleteConfirmation: (mcId) ->
    @setState showConfirmDeleteModal: true, mcIdToDelete: mcId

  closeDeleteConfirmation: ->
    @setState showConfirmDeleteModal: false, mcIdToDelete: null

  delete: ->
    MultiComparisonsActions.delete @state.mcIdToDelete
    @setState showConfirmDeleteModal: false, mcIdToDelete: null

  render: ->
    { comparisonsEditData,
      editingComparison,
      questions,
      questionGroups
    } = @props

    if @isFetching()
      <Spinner />
    else
      <div>
        <div className="multi-comparisons-top-container">
          <div className="mc-info">{@i18n 'mc:module_info'}</div>
          <button className="btn" onClick={MultiComparisonsActions.createNewComparison}>
            {@i18n 'mc:create_new'}
          </button>
        </div>
        {@props.multiComparisonsList.map (multiComparison) =>
          <MultiComparisonQuestionsGroup
            editingComparison={editingComparison}
            questions={questions}
            questionGroups={questionGroups}
            comparisonsEditData={comparisonsEditData}
            onDelete={@openDeleteConfirmation}
            key={multiComparison.get '_id'}
            mcId={multiComparison.get '_id'}
            multiComparison={multiComparison}
          />
        }

        {@state.showConfirmDeleteModal and
          <Modal className='confirmation-modal' isOpen>
            <Confirmation
              question={@i18n 'mc:confirmation_messages.delete_confirmation'}
              onCancel={@closeDeleteConfirmation}
              onConfirm={@delete}
            />
          </Modal>
        }

      </div>

module.exports = ConnectStore MultiComparisons,
  [MultiComparisonsStore, QuestionsStore, QuestionGroupsStore], storeConnectors
