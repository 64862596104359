{ dropSpec, dropCollect } = require 'components/scope/outcomes/question_list_dnd_spec'
DraggableQuestion = require 'components/scope/outcomes/question_draggable'
DragDropTypes = require 'components/scope/outcomes/drag_drop_types'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
DropTarget = ReactDnD.DropTarget

EditableQuestionsList = createReactClass
  displayName: 'EditableQuestionsList'
  propTypes:
    undoItemMovement: PropTypes.func.isRequired
    gIndex: PropTypes.number.isRequired
    questions: PropTypes.object.isRequired

  mixins: [CustomRenderMixin]

  componentWillReceiveProps: (nextProps) ->
    # cancel previous movement if dragged item is still being draged above not droppable place
    if @props.isOver and not nextProps.isOver
      @props.undoItemMovement()

  _questionsMapper: (qData, idx) ->
    <DraggableQuestion
      key={qData.get '_id'}
      id={qData.get '_id'}
      question={qData.get 'question'}
      qIndex={idx}
      gIndex={@props.gIndex}
      canDrag={@props.groupName? }
      isDraggingGroupQuestion={@props.draggingQuestionId is qData.get '_id'}
    />

  render: ->
    { connectDropTarget } = @props

    connectDropTarget(
      <div className="questions">
        {@props.questions.map @_questionsMapper}
      </div>
    )

module.exports = DropTarget([DragDropTypes.QUESTION, DragDropTypes.QGROUP],
  dropSpec, dropCollect) EditableQuestionsList
