var combineProviders, getKeyValObject, matchAny, oneOfProviders, provider,
  __slice = [].slice;

getKeyValObject = require('base/lib/utils').getKeyValObject;

matchAny = require('lib/gdt_tables/cell_match_strategies').matchAny;

provider = function(propName, providerFn, strategy) {
  if (strategy == null) {
    strategy = matchAny;
  }
  return function(cellId, data) {
    if (strategy(cellId, data)) {
      return getKeyValObject(propName, providerFn(cellId, data));
    } else {
      return null;
    }
  };
};

combineProviders = function() {
  var cellPropsProviders;
  cellPropsProviders = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return function(cellId, data) {
    return _.reduce(cellPropsProviders, function(accProps, providerFn) {
      return _.extend(accProps, providerFn(cellId, data));
    }, {});
  };
};

oneOfProviders = function() {
  var cellPropsProviders;
  cellPropsProviders = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return function(cellId, data) {
    var providerFn, result, _i, _len;
    result = null;
    for (_i = 0, _len = cellPropsProviders.length; _i < _len; _i++) {
      providerFn = cellPropsProviders[_i];
      result = providerFn(cellId, data);
      if (result != null) {
        break;
      }
    }
    return result;
  };
};

module.exports = {
  provider: provider,
  utils: {
    combineProviders: combineProviders,
    oneOfProviders: oneOfProviders
  }
};
