ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
Modal = require 'components/common/modal'
ReferencesActions = require 'actions/references_actions'
ReferencesStore = require 'stores/references_store'
Translation = require 'components/mixins/translation'

storeConnector =
  ReferencesStore: (Store) ->
    isOpen: Store.isDeleteAttachmentModalOpen()
    isDeleting: Store.isDeletingAttachment()
    reference: Store.getAttachmentReference()
    referenceId: Store.getAttachmentReferenceId()

ReferenceAttachmentDeleteModal = createReactClass
  displayName: 'ReferenceAttachmentDeleteModal'

  propTypes:
    isOpen: PropTypes.bool.isRequired
    isDeleting: PropTypes.bool.isRequired
    reference: PropTypes.instanceOf(Immutable.Map)
    referenceId: PropTypes.string

  mixins: [Translation('references:attachment')]

  onDelete: ->
    { referenceId } = @props
    ReferencesActions.deleteAttachment referenceId

  render: ->
    { isOpen, isDeleting, reference } = @props
    fileName = reference?.getIn(['attachment', 'fileName']) ? ' '
    <Modal isOpen={isOpen}>
      <p>{@i18n 'deleteConfirmation', { fileName }}</p>
      <ApplyCancelButtons
        applying={isDeleting}
        applyLabel={@i18n '/actions.delete'}
        onApply={@onDelete}
        onCancel={ReferencesActions.closeDeleteAttachmentModal}
      />
    </Modal>

module.exports = ConnectStore ReferenceAttachmentDeleteModal, ReferencesStore, storeConnector
