var DiscardChanges, DiscardChangesView, ReactComponent, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

DiscardChanges = require('components/discard_changes/discard_changes');

module.exports = DiscardChangesView = (function(_super) {
  __extends(DiscardChangesView, _super);

  function DiscardChangesView() {
    return DiscardChangesView.__super__.constructor.apply(this, arguments);
  }

  DiscardChangesView.prototype.autoRender = true;

  DiscardChangesView.prototype._stopInputsPropagation = false;

  DiscardChangesView.prototype.initialize = function() {
    this.enable(ReactComponent);
    return DiscardChangesView.__super__.initialize.apply(this, arguments);
  };

  DiscardChangesView.prototype.afterRender = function() {
    DiscardChangesView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(DiscardChanges);
  };

  DiscardChangesView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    return DiscardChangesView.__super__.dispose.apply(this, arguments);
  };

  return DiscardChangesView;

})(View);
