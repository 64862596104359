mediator = require 'mediator'
ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
ACPVisualGuidelinesStore = require 'stores/acp_visual_guidelines_store'
ConnectStore = require 'components/enhancers/connect_store'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
DynamicHeightContainer = require 'components/common/dynamic_height_container'
EditableQuestionsList =
  require 'components/evidence_syntheses/questions_list/editable_questions_list'
EllipsizedText = require 'components/common/ellipsized_text'
OrganizationsStore = require 'stores/organizations_store'
Router = require 'router'
Spinner = require 'components/common/spinner'
VisualGuidelinesModal =
  require 'components/dissemination/acp_visual_guidelines/visual_guidelines_modal'
VisualGuidelinesDetail =
  require 'components/dissemination/acp_visual_guidelines/visual_guidelines_details'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'

storeConnector =
  ACPVisualGuidelinesStore: (Store) ->
    applying: Store.isApplying()
    editingVisualization: Store.getEditingVisualization()
    isFetching: Store.isFetching()
    isModalOpen: Store.isCreateOrEditDialogOpen()
    visualizations: Store.getVisualizations()

  OrganizationsStore: (Store) ->
    projectFromOrganization: Store.isCurrentProjectFromOrganization()

GuidelineItem = ({ data }) ->

  handleClick = useCoffeeCallback [data], ->
    link = Router::getProjectRelativeUrl(
      "/dissemination/acp-visual-guidelines/details/#{data.get('_id')}")
    mediator.publish '!router:route', link

  handleEdit = useCoffeeCallback [data], ->
    name = data.get 'name'
    tableId = data.get '_id'
    ACPVisualGuidelinesActions.openCreateOrEditDialog data

  handleDelete = useCoffeeCallback [data], ->
    ACPVisualGuidelinesActions.removeVisualization data

  CONTROLS = [
    name: 'edit'
    handler: handleEdit
  ,
  #   name: 'duplicate'
  #   handler: handleDuplicate
  # ,
    name: 'delete'
    handler: handleDelete
  ]

  <CollapsibleWithControls
    key={data.get('_id')}
    containerClassName="mda-table-list__item"
    controls={CONTROLS}
  >
    <div className="table-name" onClick={handleClick}>
      <EllipsizedText height={40} text={data.get('name') or ''} />
    </div>
    <div />
  </CollapsibleWithControls>


VisualGuidelines = ({
    applying
    editingVisualization
    isFetching
    isModalOpen
    projectFromOrganization
    visualizations
    itemId
    submoduleScreen
}) ->

  i18n = useI18n()

  return <Spinner /> if isFetching
  questionsByGroup = Immutable.fromJS({questionsWithoutGroup : {questions: visualizations}})
  <div>
    <VisualGuidelinesModal
      applying={applying}
      isModalOpen={isModalOpen}
      editingVisualization={editingVisualization}
    />
    {if submoduleScreen is 'details'
      <VisualGuidelinesDetail itemId={itemId} />
    else
      <div className='comparison-questions-list'>
        <div>
          <DynamicHeightContainer className="mda-table-list">
            <EditableQuestionsList
              isFetchingQuestions={isFetching}
              isFetchingGroups={false}
              isFetchingOverarchingQuestions={false}
              customNewGroupLabel=""
              projectFromOrganization={projectFromOrganization}
              questionGroups={Immutable.Map()}
              questions={visualizations}
              withDnD={false}
              withNewGroupDropArea={false}
              questionsByGroup={questionsByGroup}
              groupEditState={Immutable.Map()}
              questionsStatuses={Immutable.Map()}
              overarchingQuestions={Immutable.Map()}
              isQuestionBeingAdded={false}
              isQuestionBeingDuplicated={false}
            >
              <GuidelineItem />
            </EditableQuestionsList>
          </DynamicHeightContainer>
        </div>
      </div>
    }
  </div>

module.exports = ConnectStore VisualGuidelines, [
  ACPVisualGuidelinesStore
], storeConnector
