module.exports = {
  getInitialState: function() {
    return {
      showingAllComments: false
    };
  },
  showComments: function() {
    return this.setState({
      showingAllComments: true
    });
  },
  onItemCommentToggle: function(closed) {
    if (this.state.showingAllComments && closed) {
      return this.setState({
        showingAllComments: false
      });
    }
  }
};
