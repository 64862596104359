OutcomesTableViewActions = require 'actions/outcomes_table_view_actions'
EditableCell = require 'components/evidence_syntheses/outcomes/cells/editable_cell'
{ CompoundEdit } = require 'components/evidence_syntheses/outcomes/cells/compound_edit'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
Select = require 'components/common/select_custom'
QS = require 'models/outcome/quality_scores'
{ getKeyValObject } = require 'base/lib/utils'

outcomeAttrNames = [ 'publicationBias', 'largeEffect', 'plausibleConfounding',
  'doseResponseGradient' ]

outcomeAttrOptions =
  publicationBias: [
    value: 'None'
    text: $.t 'es:outcome.undetected'
  ,
    value: 'Serious'
    text: $.t 'es:outcome.publication_bias_strongly_suspected_short'
  ]
  largeEffect: [
    value: 'No'
    text: $.t 'es:outcome.no'
  ,
    value: 'Large'
    text: $.t 'es:outcome.large'
  ,
    value: 'VeryLarge'
    text: $.t 'es:outcome.very_large'
  ]
  plausibleConfounding: [
    value: 'No'
    text: $.t 'es:outcome.no'
  ,
    value: 'ReducedForRR'
    text: $.t 'es:outcome.reduced_for_RR'
  ,
    value: 'IncreasedForRR'
    text: $.t 'es:outcome.increased_for_RR'
  ]
  doseResponseGradient: [
    value: 'No'
    text: $.t 'es:outcome.no'
  ,
    value: 'Yes'
    text: $.t 'es:outcome.yes'
  ]

OtherConsiderationsCell = createReactClass
  displayName: 'OtherConsiderationsCell'

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]

  _ref: (el) ->
    @el = el

  _saveData: ->
    return if @props.editMetadata?.get('editedAttributes', Immutable.Map()).isEmpty()
    OutcomesTableViewActions.saveEditedAttributes()

  handleApply: ->
    @_saveData()
    @resetEdit()

  resetEdit: ->
    @el.stopEdit()

  handleAttrChange: (attrName) -> (changedVal) ->
    newVal = QS.get changedVal
    OutcomesTableViewActions.updateEditedOutcomeAttributes getKeyValObject attrName, newVal

  handleEditClose: ->
    hasUnsavedEditData = not @props.editMetadata.get('editedAttributes', Immutable.Map()).isEmpty()
    @_saveData() if hasUnsavedEditData
    @resetEdit()

  renderAttrSelect: (outcomeAttrName) ->
    wasEdited = @props.editMetadata?.get('editedAttributes')?.has outcomeAttrName
    currentVal = if wasEdited
      @props.editMetadata?.getIn(['editedAttributes', outcomeAttrName, 'name']) ? ''
    else
      @props.outcomeAttrs[outcomeAttrName]?.name

    <div className='edit-input-block' key={outcomeAttrName}>
      <label className='attr-name'>{@i18n _.str.underscored outcomeAttrName}</label>
      <Select
        options={outcomeAttrOptions[outcomeAttrName]}
        selected={currentVal}
        onChange={@handleAttrChange outcomeAttrName}
        withClearOption
      />
    </div>

  render: ->
    <EditableCell {...@props}
      onEditClose={@handleEditClose}
      ref={@_ref}
      title={@i18n 'other_considerations'}
    >
      <CompoundEdit onApply={@handleApply} onCancel={@resetEdit}>
        {outcomeAttrNames.map @renderAttrSelect}
      </CompoundEdit>
    </EditableCell>

module.exports = OtherConsiderationsCell
