Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
Modal = require 'components/common/modal'
Input = require 'components/common/input'
Checkbox = require 'components/common/checkbox_input'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'


SubchapterTitleEditModal = createReactClass
  displayName: 'SubchapterTitleEditModal'

  propTypes:
    isOpen: PropTypes.bool.isRequired
    onClose: PropTypes.func.isRequired
    onApply: PropTypes.func.isRequired

  mixins: [Translation('da:details-screen')]

  getInitialState: ->
    title: @i18n 'new_subchapter'
    titleHidden: false
    position: 'above'

  handleTitleChange: (evt) ->
    @setState title: evt.target.value

  handleNoTitleChange: (evt) ->
    @setState titleHidden: evt.target.checked

  handlePositionChange: (selectedOption) ->
    @setState position: selectedOption

  handleApply: ->
    @props.onApply
      title: @state.title
      titleHidden: @state.titleHidden
      position: @state.position

  componentDidUpdate: (prevProps, prevState) ->
    if @props.isOpen and not prevProps.isOpen
      @setState @getInitialState()

  getPositionOptions: ->
    ['above', 'below'].map (position) =>
      value: position
      text: @i18n "insert_#{position}_current"

  render: ->
    <Modal
      className='decision-aid-new-subchapter-modal'
      isOpen={@props.isOpen}
      modalSize='standard'
      onClose={@props.onClose}
      onRequestClose={@props.onClose}
      title={@i18n 'subchapter_name'}
    >
      <div>
        <Input
          onChange={@handleTitleChange}
          onRequestSave={@handleApply}
          onRequestCancel={@props.onClose}
          type='text'
          value={@state.title}
        />
      </div>
      <label>
        <Checkbox checked={@state.titleHidden} onChange={@handleNoTitleChange} />
        <span>{@i18n 'hide_title'}</span>
      </label>
      <div>
        <Select
          options={@getPositionOptions()}
          selected={@state.position}
          onChange={@handlePositionChange}
        />
      </div>
      <ApplyCancelButtons
        onCancel={@props.onClose}
        onApply={@handleApply}
        applyLabel={@i18n '/actions.save'}
      />
    </Modal>

module.exports = SubchapterTitleEditModal
