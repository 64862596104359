var CollectionView, FullWypasCollectionView,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

module.exports = FullWypasCollectionView = (function(_super) {
  __extends(FullWypasCollectionView, _super);

  function FullWypasCollectionView() {
    this.renderAllItems = __bind(this.renderAllItems, this);
    return FullWypasCollectionView.__super__.constructor.apply(this, arguments);
  }

  FullWypasCollectionView.prototype.enableAnimation = false;

  FullWypasCollectionView.prototype.extraItemClass = 'extra-item';

  FullWypasCollectionView.prototype.extraItemSelector = "." + FullWypasCollectionView.prototype.extraItemClass;

  FullWypasCollectionView.prototype.getSubviewsStartingWith = function(startsWith) {
    var itemViews, name, view, _ref;
    itemViews = {};
    _ref = this.subviewsByName;
    for (name in _ref) {
      view = _ref[name];
      if (_.string.startsWith(name, startsWith)) {
        itemViews[name] = view;
      }
    }
    return itemViews;
  };

  FullWypasCollectionView.prototype.getExtraSubviewsByItemCid = function(cid) {
    return this.getSubviewsStartingWith("" + cid + ":extraItem");
  };

  FullWypasCollectionView.prototype.beforeRemoveViewForItem = function(item) {
    return this.removeExtraSubviewsByItemCid(item.cid);
  };

  FullWypasCollectionView.prototype.removeExtraSubviewsByItemCid = function(itemCid) {
    var name, view, _ref, _results;
    _ref = this.getExtraSubviewsByItemCid(itemCid);
    _results = [];
    for (name in _ref) {
      view = _ref[name];
      _results.push(this.removeSubview(view));
    }
    return _results;
  };

  FullWypasCollectionView.prototype.removeViewForItem = function(item) {
    var newActiveRow, rowToRemove;
    this.beforeRemoveViewForItem(item);
    rowToRemove = this.collection.indexOf(item) + 1;
    newActiveRow = rowToRemove < this.activeCell.row ? this.activeCell.row - 1 : this.activeCell.row;
    return FullWypasCollectionView.__super__.removeViewForItem.apply(this, arguments);
  };

  FullWypasCollectionView.prototype.renderItemViews = function(item) {
    this.getItemView(item).render();
    return this.renderExtraItemViews(item, this.getItemView(item));
  };

  FullWypasCollectionView.prototype.getExtraItemSubviewName = function(item, subviewName) {
    return "" + item.cid + ":extraItem-" + subviewName;
  };

  FullWypasCollectionView.prototype.extraItemSubview = function(item, subviewName, view) {
    var extraItemSubviewName;
    extraItemSubviewName = this.getExtraItemSubviewName(item, subviewName);
    if (view) {
      return this.subview(extraItemSubviewName, view);
    } else {
      return this.subview(extraItemSubviewName);
    }
  };

  FullWypasCollectionView.prototype.renderExtraItemSubview = function(item, subviewName, extraItemView, extraItemViewOptions, containerMethod, prevItem) {
    var view;
    if (containerMethod == null) {
      containerMethod = 'after';
    }
    if (prevItem == null) {
      prevItem = null;
    }
    if (this.extraItemSubview(item, subviewName)) {
      return this.extraItemSubview(item, subviewName).render();
    } else {
      view = new extraItemView(extraItemViewOptions);
      view.parentView = this;
      this.extraItemSubview(item, subviewName, view);
      if (prevItem) {
        this.extraItemSubview(item, prevItem).$el[containerMethod](view.render().el);
      } else {
        this.getItemView(item).$el[containerMethod](view.render().el);
      }
      if (this.extraItemClass) {
        view.$el.addClass("" + this.extraItemClass);
      }
      return view;
    }
  };

  FullWypasCollectionView.prototype.renderExtraItemViews = function(item, itemView) {};

  FullWypasCollectionView.prototype.insertView = function(item, view, index, enableAnimation) {
    if (index == null) {
      index = null;
    }
    if (enableAnimation == null) {
      enableAnimation = this.enableAnimation;
    }
    this.insertItemView(item, view, enableAnimation);
    return this.renderExtraItemViews(item, view);
  };

  FullWypasCollectionView.prototype.insertItemView = function(item, view, enableAnimation) {
    var $list, $next, $previous, $viewEl, children, childrenWithExtras, extras, included, length, position, previousItemCid, previousItemPosision, viewEl;
    if (enableAnimation == null) {
      enableAnimation = this.enableAnimation;
    }
    position = this.collection.indexOf(item);
    included = typeof this.filterer === 'function' ? this.filterer(item) : true;
    if (included) {
      viewEl = view.el;
      $viewEl = view.$el;
      if (enableAnimation) {
        if (this.useCssAnimation) {
          $viewEl.addClass('animated-item-view');
        } else {
          $viewEl.css('opacity', 0);
        }
      }
      $list = this.$list;
      if (!this.itemSelector) {
        throw new Error("No item selector provided!");
      }
      children = $list.children(this.itemSelector);
      childrenWithExtras = $list.children("" + this.itemSelector + ", " + this.extraItemSelector);
      extras = $list.children(this.extraItemSelector);
      if (children.get(position) !== viewEl) {
        length = children.length;
        if (length === 0 || position === length) {
          $list.append(viewEl);
        } else {
          if (position === 0) {
            $next = childrenWithExtras.eq(0);
            $next.before(viewEl);
          } else {
            previousItemPosision = childrenWithExtras.index(children.eq(position - 1));
            previousItemCid = (childrenWithExtras.eq(previousItemPosision)).data('itemCid');
            while (childrenWithExtras.eq(previousItemPosision + 1).data('itemCid') === previousItemCid) {
              previousItemPosision += 1;
            }
            $previous = childrenWithExtras.eq(previousItemPosision);
            $previous.after(viewEl);
          }
        }
      }
      view.parentView || (view.parentView = this);
      view.trigger('addedToDOM');
      this.updateVisibleItems(item, included);
      if (enableAnimation && included) {
        if (this.useCssAnimation) {
          return setTimeout(function() {
            return $viewEl.addClass('animated-item-view-end');
          }, 0);
        } else {
          return $viewEl.animate({
            opacity: 1
          }, this.animationDuration);
        }
      }
    }
  };

  FullWypasCollectionView.prototype.renderAllItems = function() {
    var cid, index, item, items, remainingViewsByCid, view, _i, _j, _len, _len1, _ref;
    items = this.collection.models;
    this.visibleItems = [];
    remainingViewsByCid = {};
    for (_i = 0, _len = items.length; _i < _len; _i++) {
      item = items[_i];
      view = this.subview("itemView:" + item.cid);
      if (view) {
        remainingViewsByCid[item.cid] = view;
      }
    }
    _ref = this.getItemViews();
    for (cid in _ref) {
      if (!__hasProp.call(_ref, cid)) continue;
      view = _ref[cid];
      if (!(!(cid in remainingViewsByCid))) {
        continue;
      }
      this.removeSubview("itemView:" + cid);
      this.removeExtraSubviewsByItemCid(cid);
    }
    for (index = _j = 0, _len1 = items.length; _j < _len1; index = ++_j) {
      item = items[index];
      view = this.subview("itemView:" + item.cid);
      if (view) {
        this.insertView(item, view, index, false);
      } else {
        this.renderAndInsertItem(item, index);
      }
    }
    this.trigger('allItemsRendered');
    if (!items.length) {
      return this.trigger('visibilityChange', this.visibleItems);
    }
  };

  return FullWypasCollectionView;

})(CollectionView);
