var BOOLEAN_WITH_ADDITIONAL_OPTIONS_FIELDS, CODE_FIELDS, FIELDS_MAP, FIELDS_WITH_MISSING, FIELDS_WITH_SEMICOLON, REST_FIELDS, YES_NO_TEXT_FIELDS, convertFromRaw, convertMaybeDraftJsToPlainText, exportAgreeScore, exportAndSplitBySemicolon, exportBooleanWithAdditionalOptions, exportCodeField, exportFieldWithMissing, exportGuideline, exportGuidelineFields, exportYesNoTextField, get, gradeProCodeToWHOCode, renderEditorValue, _ref;

get = require('lodash').get;

_ref = require('lib/services/covid_exporter/utils'), exportFieldWithMissing = _ref.exportFieldWithMissing, exportGuidelineFields = _ref.exportGuidelineFields, gradeProCodeToWHOCode = _ref.gradeProCodeToWHOCode, renderEditorValue = _ref.renderEditorValue;

convertFromRaw = Draft.convertFromRaw;

YES_NO_TEXT_FIELDS = ['guidelineGroupDetails', 'consumerEngagement', 'peopleWithLivedExperience'];

BOOLEAN_WITH_ADDITIONAL_OPTIONS_FIELDS = ['includeNonEnglishDatabases'];

CODE_FIELDS = ['guidelineInterventions', 'guidelinePopulations'];

FIELDS_WITH_MISSING = ['secondWorldRegionCategory'];

FIELDS_MAP = {
  'fullReference': 'citation',
  'targetAudience': 'users',
  'guidelineInterventions': 'intervention',
  'guidelinePopulations': 'population',
  'secondWorldRegionCategory': 'whoWorldRegion'
};

FIELDS_WITH_SEMICOLON = ['source'];

REST_FIELDS = ['title', 'fullReference', 'publicationDate', 'version', 'organizationType', 'sourceDocumentLink', 'isbn', 'keywords', 'doi', 'pmid', 'declarationOfInterest', 'describedAsRapid', 'describedAsLiving', 'latestLiteratureSearchDate', 'gradingEvidenceMethod', 'focus', 'progress', 'plus', 'progressPlus', 'targetAudience', 'field', 'worldRegion', 'country', 'guidelineNoId', 'whoIrisId', 'authors'];

convertMaybeDraftJsToPlainText = function(value) {
  if (value == null) {
    return '';
  }
  if (_.isString(value)) {
    return value;
  }
  return convertFromRaw(value).getPlainText();
};

exportYesNoTextField = function(value) {
  var plainText, showTextInput, text;
  if (value == null) {
    return void 0;
  }
  showTextInput = value.showTextInput, text = value.text;
  plainText = convertMaybeDraftJsToPlainText(text);
  if (plainText === 'notReported') {
    return plainText;
  }
  if (showTextInput) {
    if (_.isString(text)) {
      return text;
    } else {
      return renderEditorValue(text);
    }
  } else {
    return 'no';
  }
};

exportBooleanWithAdditionalOptions = function(value) {
  if (value == null) {
    return void 0;
  }
  return value.toString();
};

exportAgreeScore = function(guidelineDoc) {
  var _ref1;
  return {
    'scopeAndPurpose': get(guidelineDoc, 'scopeAndPurpose'),
    'rigorOfDevelopment': get(guidelineDoc, 'rigorOfDevelopment'),
    'editorialProcess': get(guidelineDoc, 'editorialProcess'),
    'scorePassed': (_ref1 = get(guidelineDoc, 'scorePassed')) != null ? _ref1 : false
  };
};

exportCodeField = function(fieldValue) {
  if (_.isEmpty(fieldValue)) {
    return [];
  }
  return _.map(fieldValue, function(_arg) {
    var codes, codesRaw, id, value;
    id = _arg.id, value = _arg.value, codesRaw = _arg.codes;
    codes = _.chain(codesRaw).values().map(gradeProCodeToWHOCode).value();
    return {
      '@id': id,
      'name': value,
      'codes': codes
    };
  });
};

exportAndSplitBySemicolon = function(fieldValue) {
  if (_.isEmpty(fieldValue)) {
    return [];
  }
  return _.map(fieldValue.split(';'), function(value) {
    return value.trim();
  });
};

exportGuideline = function(projectId, guidelineDoc) {
  var agreeScore, booleanWithAdditionalOptionsFields, codesFields, fieldsWithMissing, fieldsWithSemicolon, publicationYear, restFields, yesNoTextFields;
  agreeScore = exportAgreeScore(guidelineDoc);
  publicationYear = guidelineDoc['publicationDate'] ? moment(guidelineDoc['publicationDate']).format('YYYY') : void 0;
  yesNoTextFields = exportGuidelineFields(exportYesNoTextField, YES_NO_TEXT_FIELDS, FIELDS_MAP, guidelineDoc);
  booleanWithAdditionalOptionsFields = exportGuidelineFields(exportBooleanWithAdditionalOptions, BOOLEAN_WITH_ADDITIONAL_OPTIONS_FIELDS, FIELDS_MAP, guidelineDoc);
  codesFields = exportGuidelineFields(exportCodeField, CODE_FIELDS, FIELDS_MAP, guidelineDoc);
  fieldsWithMissing = exportGuidelineFields(exportFieldWithMissing, FIELDS_WITH_MISSING, FIELDS_MAP, guidelineDoc);
  fieldsWithSemicolon = exportGuidelineFields(exportAndSplitBySemicolon, FIELDS_WITH_SEMICOLON, FIELDS_MAP, guidelineDoc);
  restFields = exportGuidelineFields(_.identity, REST_FIELDS, FIELDS_MAP, guidelineDoc);
  return _.extend({
    '@id': projectId,
    agreeScore: agreeScore,
    publicationYear: publicationYear
  }, yesNoTextFields, booleanWithAdditionalOptionsFields, codesFields, fieldsWithMissing, fieldsWithSemicolon, restFields);
};

module.exports = exportGuideline;
