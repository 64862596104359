var W, generateActions, mediator;

generateActions = require('actions/utils/generate_actions');

mediator = require('mediator');

W = require('when');

module.exports = generateActions({
  autoGenerate: ['fetchSuccess', 'fetchError'],
  name: 'ProjectActions',
  actions: {
    dbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      if (doc.docType === 'project') {
        return this.dispatch(doc);
      }
    },
    fetch: function() {
      this.dispatch();
      return W.resolve();
    },
    startProject: function(date) {
      var project;
      project = mediator.project;
      project.set('startDate', date);
      return project.save();
    },
    lockOrUnlockProject: function() {
      var project;
      project = mediator.project;
      project.set('locked', !project.get('locked'));
      return project.save();
    },
    toggleReview: function(reviewType) {
      var project;
      project = mediator.project;
      return project.set(reviewType, !project.get(reviewType)).save();
    }
  }
});
