var DOCUMENT_SECTIONS_V2_ROOT_ID, DbDoc, rootDocShape;

DbDoc = require('lib/db_docs/db_doc');

rootDocShape = require('lib/db_docs/doc_shapes/document_sections_v2_root_doc_shape');

DOCUMENT_SECTIONS_V2_ROOT_ID = require('lib/doc_ids').DOCUMENT_SECTIONS_V2_ROOT_ID;

module.exports = DbDoc(rootDocShape, {
  dbSetup: {
    docId: DOCUMENT_SECTIONS_V2_ROOT_ID
  },
  methods: {
    updateDiscussions: function(discussions) {
      return this.setAt('discussions')(discussions);
    },
    addChapter: function(chapterId) {
      return this.updateAt('chaptersOrder')(function(chaptersOrder) {
        return chaptersOrder.concat(chapterId);
      });
    },
    deleteChapter: function(deletableChapterId) {
      return this.updateAt('chaptersOrder')(function(chaptersOrder) {
        return chaptersOrder.filter(function(chapterId) {
          return chapterId !== deletableChapterId;
        });
      });
    }
  }
});
