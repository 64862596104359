var FootnotesService, mediator, outcomeCellTypes, questionFootnotesAttributesOrder,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

mediator = require('mediator');

outcomeCellTypes = require('models/outcome/outcome_cell_types');

questionFootnotesAttributesOrder = ['indexTest', 'comparatorTest', 'targetCondition', 'intervention', 'comparison', 'healthProblemOrPopulation', 'settings', 'indexTestCutOff', 'comparatorTestCutOff', 'referenceTest', 'referenceTestShortName', 'referenceTestThreshold', 'tableTitle', 'bibliography', 'authors'];

module.exports = FootnotesService = (function() {
  function FootnotesService(options) {
    this.options = options;
    this.model = options.model;
  }

  FootnotesService.prototype._isOutcomeRowPresent = function($outcomesTable) {
    return $outcomesTable.find('.outcome-label-row').length > 0;
  };

  FootnotesService.prototype._getOutcomePropertyObjs = function(outcome, $outcomesTable) {
    var propertyObjs;
    propertyObjs = this._getOutcomeCellPropertyObjs(outcome, $outcomesTable);
    if (this._isOutcomeRowPresent($outcomesTable)) {
      propertyObjs.unshift({
        property: 'oneRowOutcomeLabel'
      });
    }
    return propertyObjs;
  };

  FootnotesService.prototype._getOutcomeCellPropertyObjs = function(outcome, $outcomesTable) {
    var $cell, $cellContent, cell, cellContentClasses, cellPropertyObjs, outcomeCells, propObj;
    outcomeCells = $outcomesTable.find("tr.outcome-row[data-item-cid=" + outcome.cid + "] > td");
    outcomeCells = _(outcomeCells).sortBy(function(cell) {
      return Number(cell.dataset.colNo);
    });
    return cellPropertyObjs = (function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = outcomeCells.length; _i < _len; _i++) {
        cell = outcomeCells[_i];
        $cell = $(cell);
        $cellContent = $cell.find('.cell-content');
        cellContentClasses = $cellContent.length ? _($cellContent.attr('class').split(/\s+/)).without('cell-content') : [];
        _results.push(propObj = {
          property: $cell.data('property'),
          cellContentClass: cellContentClasses[0]
        });
      }
      return _results;
    })();
  };

  FootnotesService.prototype._prepareItemsIdsArrayForSorting = function(itemsName, outcomes, $outcomesTable, currentViewName) {
    var attr, itemsIds, outcome, outcomeFootnotes, propertyObjs, _i, _j, _len, _len1, _ref, _ref1;
    itemsIds = this._getQuestionItemsIds(itemsName);
    outcomes = outcomes != null ? outcomes : this.model.get('outcomes').models;
    for (_i = 0, _len = outcomes.length; _i < _len; _i++) {
      outcome = outcomes[_i];
      outcomeFootnotes = outcome.get(itemsName);
      if (_.isEmpty(outcomeFootnotes)) {
        continue;
      }
      propertyObjs = this._getOutcomePropertyObjs(outcome, $outcomesTable);
      _ref = this._mapCellPropertiesToOutcomeAttributes(propertyObjs, currentViewName);
      for (_j = 0, _len1 = _ref.length; _j < _len1; _j++) {
        attr = _ref[_j];
        itemsIds = _.union(itemsIds, (_ref1 = outcomeFootnotes[attr]) != null ? _ref1 : []);
      }
    }
    return itemsIds;
  };

  FootnotesService.prototype.getOutcomesTableItemsObjects = function(listType, outcomes, $outcomesTable, currentViewName) {
    var capListType, objects, outcomeIds, tableItemsIds;
    if ($outcomesTable == null) {
      $outcomesTable = $('#page-container .outcomes');
    }
    capListType = _.string.capitalize(listType);
    tableItemsIds = this._prepareItemsIdsArrayForSorting(listType, outcomes, $outcomesTable, currentViewName);
    outcomeIds = _.map(outcomes, function(o) {
      return o.get('_id');
    });
    objects = this.model["get" + capListType + "ObjectsArray"](outcomeIds);
    return _.filter(objects, function(item) {
      var _ref;
      return _ref = item.id, __indexOf.call(tableItemsIds, _ref) >= 0;
    });
  };

  FootnotesService.prototype.automaticallySortFootnotes = function(outcomes, $outcomesTable) {
    var footnotesList, outcomesTableFootnoteIds, reordered;
    if ($outcomesTable == null) {
      $outcomesTable = $('#page-container .outcomes');
    }
    outcomesTableFootnoteIds = this._prepareItemsIdsArrayForSorting('footnotes', outcomes, $outcomesTable);
    footnotesList = this.model.get('footnotesList');
    reordered = footnotesList.sortFootnotes(outcomesTableFootnoteIds);
    if (reordered) {
      return this._saveOnReorder('footnotes');
    }
  };

  FootnotesService.prototype.automaticallySortReferences = function(outcomes, $outcomesTable) {
    var currentOrder, draftOrder, orderChanged, outcomesReferencesIds, outcomesTableReferencesIds, prevOrder, questionAndOutcomesReferencesIds, questionReferenceIds, referencesOrder, _ref;
    if ($outcomesTable == null) {
      $outcomesTable = $('#page-container .outcomes');
    }
    outcomesTableReferencesIds = this._prepareItemsIdsArrayForSorting('references', outcomes, $outcomesTable);
    outcomesReferencesIds = _(this.model.get('outcomes').map(function(o) {
      return o.getReferencesIds();
    })).chain().flatten().uniq().value();
    questionReferenceIds = this.model._getQuestionItemsIds('references');
    questionAndOutcomesReferencesIds = _.union(questionReferenceIds, outcomesReferencesIds);
    currentOrder = (_ref = this.model.get('referencesOrder')) != null ? _ref : [];
    draftOrder = _(currentOrder).intersection(questionAndOutcomesReferencesIds).concat(_(questionAndOutcomesReferencesIds).difference(currentOrder));
    prevOrder = _.clone(currentOrder);
    referencesOrder = _(draftOrder).sortBy(function(v) {
      var idx;
      idx = outcomesTableReferencesIds.indexOf(v);
      if (idx < 0) {
        return outcomesTableReferencesIds.length;
      } else {
        return idx;
      }
    });
    orderChanged = !_.isEqual(prevOrder, referencesOrder);
    if (orderChanged) {
      this.model.set('referencesOrder', referencesOrder);
      return this._saveOnReorder('references');
    }
  };

  FootnotesService.prototype.getOutcomeAnnotationsForProperty = function(annotationsType, outcome, property, contentDefaultClass, currentViewName) {
    var attrs;
    attrs = this._getFootnoteAttributesForProperty(property, contentDefaultClass, currentViewName);
    return _(outcome.get(annotationsType)).pick(attrs);
  };

  FootnotesService.prototype._getFootnoteAttributesForProperty = function(property, contentDefaultClass, currentViewName) {
    var attrs, propAttrs, propObj, viewName, _ref;
    propAttrs = [];
    propObj = contentDefaultClass ? _(outcomeCellTypes[property].otherFormatters).findWhere({
      contentDefaultClass: contentDefaultClass
    }) : outcomeCellTypes[property];
    if (!propObj) {
      return [];
    }
    if (currentViewName == null) {
      currentViewName = mediator.services.esView.getPreferredType(mediator.project, this.model.get('type')).name;
    }
    if (propObj.footnotesAttrs) {
      _ref = propObj.footnotesAttrs;
      for (viewName in _ref) {
        attrs = _ref[viewName];
        if (viewName === currentViewName || viewName === 'common') {
          propAttrs = propAttrs.concat(attrs);
        }
      }
    } else if (propObj.formatterAttrsObserve) {
      propAttrs = propAttrs.concat(propObj.formatterAttrsObserve);
    } else {
      propAttrs.push(property);
    }
    return propAttrs;
  };

  FootnotesService.prototype._mapCellPropertiesToOutcomeAttributes = function(cellPropertyObjs, currentViewName) {
    var cellPropertyObj, contentClass, outcomeAttrs, prop, _i, _len;
    outcomeAttrs = [];
    for (_i = 0, _len = cellPropertyObjs.length; _i < _len; _i++) {
      cellPropertyObj = cellPropertyObjs[_i];
      prop = cellPropertyObj.property, contentClass = cellPropertyObj.cellContentClass;
      outcomeAttrs = outcomeAttrs.concat(this._getFootnoteAttributesForProperty(prop, contentClass, currentViewName));
    }
    return outcomeAttrs;
  };

  FootnotesService.prototype._saveOnReorder = function(itemsType) {
    return this.model.save().then(function() {
      return mediator.publish("" + itemsType + "Reordered");
    }).otherwise(mediator.dialogs.fatalError);
  };

  FootnotesService.prototype._getQuestionItemsIds = function(itemsName) {
    var questionFootnotesAttrs;
    questionFootnotesAttrs = this.options.questionFootnotesAttr ? _(questionFootnotesAttributesOrder).intersection(this.options.questionFootnotesAttr) : questionFootnotesAttributesOrder;
    return _(questionFootnotesAttrs).chain().map((function(_this) {
      return function(attr) {
        return _this.model.get(itemsName)[attr];
      };
    })(this)).flatten().uniq().value();
  };

  return FootnotesService;

})();
