RatingTableRow = require 'components/scope/outcomes/outcomes_rating_table_row'
OutcomesGenerationActions = require 'actions/outcomes_generation_actions'
RatingTableRowWithComments =
  require 'components/scope/outcomes/outcomes_rating_table_row_with_comments'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

OutcomesRatingTable = createReactClass
  displayName: 'OutcomesRatingTable'

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  onChangeOutcomeImportance: (gIndex, outcomeKey, importance) -> ->
    OutcomesGenerationActions.updateOutcomeImportance { gIndex, outcomeKey, importance }

  _outcomesMapper: (outcome, idx) ->
    hasComments = outcome.get('memberComments')?.flatten().size

    if hasComments
      <RatingTableRowWithComments
        key={idx}
        gIndex={@props.gIndex}
        index={outcome.get 'initialIndex'}
        outcome={outcome}
        membersMap={@props.membersMap}
        readOnly={@props.readOnly}
        changeOutcomeImportance={@onChangeOutcomeImportance}
        showingComments={@props.showingComments}
        onCommentToggle={@props.onCommentToggle}
      />
    else
      <RatingTableRow
        key={idx}
        gIndex={@props.gIndex}
        outcome={outcome}
        readOnly={@props.readOnly}
        changeOutcomeImportance={@onChangeOutcomeImportance}
      />

  _sortedByMedian: ->
    @props.outcomes.map (o, idx) -> o.set 'initialIndex', idx
    .sort (o1, o2) ->
      o2.get('medianRating') - o1.get 'medianRating'

  render: ->
    <div className='outcomes-rating-container'>
      <table className='outcomes-rating'>
        <thead>
          <tr>
            <th className='name c-5' rowSpan={2}>{@i18n 'outcomes'}</th>
            <th className='c-2' colSpan={2}>
              <b>{@i18n 'included'}</b>
            </th>
            <th>
              <b>{@i18n 'excluded'}</b>
            </th>
            <th rowSpan={2}>{@i18n '../questions.proposal.rating'}</th>
            <th rowSpan={2}>{@i18n '../questions.proposal.comments'}</th>
          </tr>
          <tr>
            <th>{@i18n 'critical'}</th>
            <th>{@i18n 'important'}</th>
            <th>{@i18n 'not_important'}</th>
          </tr>
        </thead>
        {@_sortedByMedian().map(@_outcomesMapper).toList()}
      </table>
    </div>

module.exports = OutcomesRatingTable
