var alt, getPublicMethodNames, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

getPublicMethodNames = R.compose(R.reject(R.test(/^on|^_/)), R.keys);

module.exports = function(_arg) {
  var boundActions, initialState, methods, name, _StoreClass;
  name = _arg.name, initialState = _arg.initialState, boundActions = _arg.boundActions, methods = _arg.methods;
  _StoreClass = (function() {
    function _StoreClass() {}

    return _StoreClass;

  })();
  _StoreClass.prototype = methods;
  _StoreClass.prototype.constructor = function() {
    this.state = initialState;
    ([].concat(boundActions)).forEach((function(_this) {
      return function(Actions) {
        return _this.bindActions(Actions);
      };
    })(this));
    return this.exportPublicMethods(getPublicMethodNames(methods).reduce((function(_this) {
      return function(publicMethods, methodName) {
        if (methodName === 'constructor') {
          return publicMethods;
        }
        publicMethods[methodName] = _this[methodName];
        return publicMethods;
      };
    })(this), {}));
  };
  return alt.createStore(immutable(_StoreClass), name);
};
