var ChecklistActions, ChecklistDoc, ChecklistStore, INITIAL_STATE, alt, callMethod, errorHandler, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

ChecklistActions = require('actions/checklist_actions');

ChecklistDoc = require('lib/db_docs/checklist_doc');

errorHandler = require('lib/app_utils').errorHandler;

callMethod = require('base/lib/utils').callMethod;

INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false,
    isShowingDiagram: false
  },
  topics: [],
  sources: {},
  projectChecklist: {}
});

ChecklistStore = (function() {
  function ChecklistStore() {
    this.state = INITIAL_STATE;
    this.bindActions(ChecklistActions);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      isShowingDiagram: this.isShowingDiagram,
      getTopics: this.getTopics,
      getSources: this.getSources,
      getProjectChecklist: this.getProjectChecklist
    });
  }

  ChecklistStore.prototype.onDbChange = function(_arg) {
    var doc;
    doc = _arg.doc;
    return this.setState(this.state.set('projectChecklist', ChecklistDoc.of(doc)));
  };

  ChecklistStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['meta', 'isFetching'], true));
  };

  ChecklistStore.prototype.onFetchSuccess = function(_arg) {
    var checklist, projectChecklistData;
    checklist = _arg[0], projectChecklistData = _arg[1];
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'isFetching'], false);
      state.set('topics', Immutable.fromJS(checklist.topics));
      state.set('sources', Immutable.fromJS(checklist.sources));
      return state.set('projectChecklist', ChecklistDoc.of(projectChecklistData));
    }));
  };

  ChecklistStore.prototype.onFetchError = function(error) {
    this.setState(this.state.setIn(['meta', 'isFetching'], false));
    if (error.status === 404) {
      return;
    }
    return errorHandler(error);
  };

  ChecklistStore.prototype.onToggleProcessDiagram = function() {
    return this.setState(this.state.updateIn(['meta', 'isShowingDiagram'], R.not));
  };

  ChecklistStore.prototype.updateStepStatus = function(_arg) {
    var status, stepId, topicId;
    topicId = _arg.topicId, stepId = _arg.stepId, status = _arg.status;
    return this.setState(this.state.update('projectChecklist', callMethod('updateStepData', topicId, stepId, {
      status: status
    })));
  };

  ChecklistStore.prototype.updateStepComment = function(_arg) {
    var comment, stepId, topicId;
    topicId = _arg.topicId, stepId = _arg.stepId, comment = _arg.comment;
    return this.setState(this.state.update('projectChecklist', callMethod('updateStepData', topicId, stepId, {
      comment: comment
    })));
  };

  ChecklistStore.prototype.isFetching = function() {
    return this.state.getIn(['meta', 'isFetching']);
  };

  ChecklistStore.prototype.getTopics = function() {
    return this.state.get('topics');
  };

  ChecklistStore.prototype.getSources = function() {
    return this.state.get('sources');
  };

  ChecklistStore.prototype.getProjectChecklist = function() {
    return this.state.get('projectChecklist');
  };

  ChecklistStore.prototype.isShowingDiagram = function() {
    return this.state.getIn(['meta', 'isShowingDiagram']);
  };

  return ChecklistStore;

})();

module.exports = alt.createStore(immutable(ChecklistStore), 'ChecklistStore');
