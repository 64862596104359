var getChaptersOrder;

getChaptersOrder = function(decisionAid) {
  var storedOrder;
  storedOrder = decisionAid.get('chaptersOrder');
  if (storedOrder.isEmpty()) {
    return decisionAid.get('chapters').keySeq().toList();
  }
  return storedOrder;
};

module.exports = {
  getChaptersOrder: getChaptersOrder
};
