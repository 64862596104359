var ACP_VISUAL_GUIDELINES_PREFIX, DbDoc, acpVisualGuidelinesShape;

DbDoc = require('lib/db_docs/db_doc');

acpVisualGuidelinesShape = require('lib/db_docs/doc_shapes/acp_visual_guidelines_doc_shape');

ACP_VISUAL_GUIDELINES_PREFIX = require('lib/doc_prefixes').acpVisualGuidelines;

module.exports = DbDoc(acpVisualGuidelinesShape, {
  dbSetup: {
    idPrefix: ACP_VISUAL_GUIDELINES_PREFIX
  },
  methods: {
    updateSelectedQuestions: function(selectedQuestions) {
      return this.updateAt('selectedQuestions')(function(sq) {
        return _.chain(sq).concat(selectedQuestions).uniq().value();
      });
    }
  }
});
