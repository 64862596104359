CustomRenderMixin = require 'components/mixins/custom_render_mixin'

QuestionGroupTitle = createReactClass
  displayName: 'QuestionGroupTitle'
  mixins: [CustomRenderMixin]

  render: ->
    return false unless @props.text

    <div className="group-name">
      <span>{@props.text}</span>
      {@props.children}
    </div>

module.exports = QuestionGroupTitle
