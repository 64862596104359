var NMA_OUTCOME_PREFIX, NMA_QUESTION_PREFIX, NmaListActions, NmaOutcomeDoc, NmaQuestionActions, W, appUtils, callMethod, generateActions, mediator, nmaDataToGdtData, _ref;

appUtils = require('lib/app_utils');

callMethod = require('base/lib/utils').callMethod;

generateActions = require('actions/utils/generate_actions');

mediator = require('mediator');

nmaDataToGdtData = require('lib/nma_helper').nmaDataToGdtData;

_ref = require('lib/doc_prefixes'), NMA_OUTCOME_PREFIX = _ref.nmaOutcome, NMA_QUESTION_PREFIX = _ref.nmaQuestion;

NmaListActions = require('actions/nma_list_actions');

NmaOutcomeDoc = require('lib/db_docs/nma_outcome_doc');

NmaQuestionActions = require('actions/nma_question_actions');

W = require('when/when');

module.exports = generateActions({
  autoGenerate: ['fetchError', 'fetchSuccess'],
  name: 'NMAOutcomeActions',
  actions: {
    dbChange: function(change, _pending, _lastSeq) {
      var isOutcome, isQuestion;
      NmaListActions.dbChange(change, _pending, _lastSeq);
      NmaQuestionActions.dbChange(change, _pending, _lastSeq);
      isOutcome = change.id.indexOf(NMA_OUTCOME_PREFIX) === 0;
      isQuestion = change.id.indexOf(NMA_QUESTION_PREFIX) === 0;
      if (!(isOutcome || isQuestion)) {
        return;
      }
      return this.dispatch(change);
    },
    fetch: function(_arg) {
      var dbId, docId, questionId;
      dbId = _arg.dbId, docId = _arg.docId, questionId = _arg.questionId;
      this.dispatch();
      return W.all([
        NmaListActions.fetch({
          dbId: dbId
        }), NmaOutcomeDoc.at(dbId, docId).fetch()
      ]).then((function(_this) {
        return function(_arg1) {
          var outcome, question, questions;
          questions = _arg1[0], outcome = _arg1[1];
          if (!outcome) {
            throw new Error("No outcome " + docId);
          }
          question = _.find(questions, function(doc) {
            return doc._id === questionId;
          });
          if (!question) {
            throw new Error("No question " + questionId);
          }
          NmaQuestionActions.fetchQuestionSuccess(question);
          return _this.actions.fetchSuccess(outcome);
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('nma:outcome.fetch_error'));
          return _this.actions.fetchError(err);
        };
      })(this));
    },
    updateOutcomeDescription: function(outcomeId, newContent) {
      return NmaOutcomeDoc.at(mediator.project.id, outcomeId).updateAsInstance(callMethod('updateDescription', newContent)).then(function() {
        return mediator.dialogs.info($.t('nma:outcome.update_description_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:outcome.update_description_error')));
    },
    updateOutcomeData: function(outcomeId, interventionId, comparatorId, data) {
      return NmaOutcomeDoc.at(mediator.project.id, outcomeId).updateAsInstance(callMethod('updateOutcomeData', interventionId, comparatorId, data)).then(function() {
        return mediator.dialogs.info($.t('nma:outcome.update_data_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:outcome.update_data_error')));
    }
  }
});
