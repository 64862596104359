var ChromeAppAdapter, LocalStorageAdapter, Storage, W, timeout, utils;

W = require('when');

timeout = require('when/timeout');

utils = require('base/lib/utils');

module.exports = Storage = (function() {
  function Storage() {
    this.adapter = window.gdt.chromeapp ? new ChromeAppAdapter() : new LocalStorageAdapter();
  }

  Storage.prototype.get = function(key) {
    return this.adapter.get(key);
  };

  Storage.prototype.set = function(key, value) {
    return this.adapter.set(key, value);
  };

  Storage.prototype.remove = function(key) {
    return this.adapter.remove(key);
  };

  return Storage;

})();

LocalStorageAdapter = (function() {
  function LocalStorageAdapter() {}

  LocalStorageAdapter.prototype.get = function(key) {
    return W(window.localStorage[key]);
  };

  LocalStorageAdapter.prototype.set = function(key, value) {
    return W(window.localStorage[key] = value);
  };

  LocalStorageAdapter.prototype.remove = function(key) {
    return W(delete window.localStorage[key]);
  };

  return LocalStorageAdapter;

})();

ChromeAppAdapter = (function() {
  function ChromeAppAdapter() {}

  ChromeAppAdapter.prototype.get = function(key) {
    return W.promise(function(resolve) {
      return chrome.storage.local.get(key, function(value) {
        return resolve(value[key]);
      });
    });
  };

  ChromeAppAdapter.prototype.set = function(key, value) {
    return W.promise(function(resolve) {
      var object;
      object = {};
      object[key] = value;
      return chrome.storage.local.set(object, resolve);
    });
  };

  ChromeAppAdapter.prototype.remove = function(key) {
    return W.promise(function(resolve) {
      return chrome.storage.local.remove(key, resolve);
    });
  };

  return ChromeAppAdapter;

})();
