ConnectStore = require 'components/enhancers/connect_store'
DBEPPublicationActions = require 'actions/dbep_publication_actions'
DBEPPublicationStore = require 'stores/dbep_publication_store'
QuestionsSelector = require 'components/common/questions_selector'
Translation = require 'components/mixins/translation'

storeConnector =
  DBEPPublicationStore: (Store) ->
    mode: Store.getMode()
    questions: Store.getQuestions()
    questionGroups: Store.getQuestionGroups()
    questionStatuses: Store.getQuestionStatuses()
    selectedQuestions: Store.getSelectedQuestions()

QuestionsSelection = createReactClass
  displayName: 'DBEPPublicationQuestionsSelection'
  mixins: [Translation('dbep:publish_dialog.selectQuestions')]

  propTypes:
    questions: PropTypes.instanceOf(Immutable.Map).isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    questionStatuses: PropTypes.instanceOf(Immutable.Map).isRequired
    selectedQuestions: PropTypes.instanceOf(Immutable.List).isRequired
    mode: PropTypes.oneOf(['preview', 'publish', 'publish-no-moderation']).isRequired

  _getSelectText: ->
    switch @props.mode
      when 'preview'
        @i18n 'select_preview'
      else
        @i18n 'select'

  onUpdateSelected: (newSelected) ->
    DBEPPublicationActions.updateSelectedQuestions newSelected

  render: ->
    { mode, questions, questionGroups, questionStatuses, selectedQuestions } = @props

    filteredQuestions = if mode is 'preview'
      questions
    else
      questions.filter (question) ->
        status = questionStatuses.getIn ['statuses', question.get '_id']
        status ?= 'unpublished'
        status is 'unpublished'

    <div className="dbep-modal__questions-list">
      <p>{@_getSelectText()}</p>
      <QuestionsSelector
        className="questions-list__list"
        updateSelected={@onUpdateSelected}
        selectedQuestions={selectedQuestions}
        withSelectAll
        withSearch
        withNumbers
        questionGroups={questionGroups}
        questions={filteredQuestions}
      />
    </div>

module.exports = ConnectStore QuestionsSelection, [DBEPPublicationStore], storeConnector
