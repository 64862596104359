QuestionGroupTitle = require 'components/scope/outcomes/question_group_title'
DeleteButton = require 'components/common/delete_button'
EditButton = require 'components/common/edit_button'
SaveButton = require 'components/common/save_button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

QuestionGroupTitleWithButtons = createReactClass
  displayName: 'QuestionGroupTitleWithButtons'
  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  getInitialState: ->
    editing: false

  onSave: ->
    @props.onSave @refs.titleText.value
    @setState editing: false

  onDelete: ->
    @props.onDelete()
    @setState editing: false

  componentDidUpdate: (prevProps, prevState) ->
    @refs.titleText.focus() if @state.editing

  render: ->
    return false unless @props.text

    if @state.editing
      <div className="group-name">
        <input ref='titleText' defaultValue={@props.text} />
        <div className="buttons">
          <SaveButton
            title={@i18n 'question_group_save'}
            onClick={@onSave}
          />
          <DeleteButton
            title={@i18n 'question_group_delete'}
            onClick={@onDelete}
          />
        </div>
      </div>
    else
      <QuestionGroupTitle text={@props.text}>
        <EditButton
          title={@i18n 'edit_question_group'}
          onClick={=> @setState editing: true}
        />
      </QuestionGroupTitle>

module.exports = QuestionGroupTitleWithButtons
