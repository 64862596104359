var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "<label for=\"units\">"
    + escapeExpression(((helpers.i18nCapitalize || (depth0 && depth0.i18nCapitalize) || helperMissing).call(depth0, "es:outcome.unit", {"name":"i18nCapitalize","hash":{},"data":data})))
    + "</label>\n<input id=\"units\" class=\"number\" type=\"text\" name=\"units\" value=\""
    + escapeExpression(((helper = (helper = helpers.units || (depth0 != null ? depth0.units : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"units","hash":{},"data":data}) : helper)))
    + "\">\n<div class=\"sup\"></div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}