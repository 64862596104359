var EMPTY_TOPIC, INITIAL_DIALOG_STATE, INITIAL_MEMBER_ASSIGN_DIALOG_STATE, INITIAL_STATE, MdaTopicsActions, convertListToOrderedMap, generateStore;

generateStore = require('stores/utils/generate_store');

MdaTopicsActions = require('actions/mda_topics_actions');

convertListToOrderedMap = require('lib/immutable_utils').convertListToOrderedMap;

EMPTY_TOPIC = Immutable.fromJS({
  name: '',
  template: '',
  codes: []
});

INITIAL_DIALOG_STATE = Immutable.fromJS({
  applying: false,
  isOpen: false,
  confirmResetDialogOpen: false,
  topic: EMPTY_TOPIC
});

INITIAL_MEMBER_ASSIGN_DIALOG_STATE = Immutable.fromJS({
  applying: false,
  isOpen: false,
  memberType: null,
  topic: null
});

INITIAL_STATE = Immutable.fromJS({
  dialog: INITIAL_DIALOG_STATE,
  memberAssignDialog: INITIAL_MEMBER_ASSIGN_DIALOG_STATE,
  isFetching: false,
  topic: {}
});

module.exports = generateStore({
  name: 'MdaTopicsStore',
  initialState: INITIAL_STATE,
  boundActions: [MdaTopicsActions],
  methods: {
    isFetching: function() {
      return this.state.get('fetching');
    },
    onDbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      return this.setState(this.state.set('topic', Immutable.fromJS(doc)));
    },
    isCreateOrEditDialogOpen: function() {
      return this.state.getIn(['dialog', 'isOpen']);
    },
    isApplying: function() {
      return this.state.getIn(['dialog', 'applying']);
    },
    getEditingTopic: function() {
      return this.state.getIn(['dialog', 'topic']);
    },
    isConfirmResetDialogOpen: function() {
      return this.state.getIn(['dialog', 'confirmResetDialogOpen']);
    },
    getAssignMemberDialogData: function() {
      return this.state.get('memberAssignDialog', Immutable.Map());
    },
    onOpenCreateOrEditDialog: function(topic) {
      if (topic == null) {
        topic = EMPTY_TOPIC;
      }
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['dialog', 'isOpen'], true);
        return s.setIn(['dialog', 'topic'], topic);
      }));
    },
    onCloseCreateOrEditDialog: function() {
      return this.setState(this.state.set('dialog', INITIAL_DIALOG_STATE));
    },
    onCreateTopic: function() {
      return this.setState(this.state.setIn(['dialog', 'applying'], true));
    },
    onResetChapterStructureAndUpdateTopic: function() {
      return this.setState(this.state.setIn(['dialog', 'applying'], true));
    },
    onUpdateTopic: function() {
      return this.setState(this.state.setIn(['dialog', 'applying'], true));
    },
    onFetch: function() {
      return this.setState(this.state.set('fetching', true));
    },
    onFetchSuccess: function(topic) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('fetching', false);
        return state.set('topic', Immutable.fromJS(topic));
      }));
    },
    isAssignMemberDialogOpen: function() {
      return this.state.getIn(['memberAssignDialog', 'isOpen']);
    },
    onOpenConfirmResetDialog: function() {
      return this.setState(this.state.setIn(['dialog', 'confirmResetDialogOpen'], true));
    },
    onCloseConfirmResetDialog: function() {
      return this.setState(this.state.setIn(['dialog', 'confirmResetDialogOpen'], false));
    },
    onOpenAssignMemberDialog: function(_arg) {
      var memberType, topic;
      topic = _arg.topic, memberType = _arg.memberType;
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['memberAssignDialog', 'isOpen'], true);
        s.setIn(['memberAssignDialog', 'topic'], topic);
        return s.setIn(['memberAssignDialog', 'memberType'], memberType);
      }));
    },
    onCloseAssignMemberDialog: function() {
      return this.setState(this.state.set('memberAssignDialog', INITIAL_MEMBER_ASSIGN_DIALOG_STATE));
    },
    getCurrentTopicCodes: function() {
      return convertListToOrderedMap(this.state.getIn(['topic', 'codes'], Immutable.Map()), 'id');
    },
    getCurrentTopicId: function() {
      return this.state.getIn(['topic', '_id']);
    },
    getCurrentTopicName: function() {
      return this.state.getIn(['topic', 'name']);
    }
  }
});
