var AddOptionsForGPSTemplates, Migration, W, mediator, migrationName, templatesSections, updateTemplateDataAndDefinition,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

templatesSections = require('lib/etd_templates/templates_sections');

migrationName = 'add_options_for_gps_templates';

updateTemplateDataAndDefinition = function(templateData) {
  var conclusions;
  conclusions = templatesSections().conclusions;
  return templateData.updateIn(['conclusions', 'sections'], function(sections) {
    return sections.map(function(section, sectionId) {
      var sectionTemplate;
      if (sectionId === 'recommendation' || sectionId === 'goodPracticeStatement') {
        return section;
      }
      sectionTemplate = conclusions.sections[sectionId];
      if (!sectionTemplate) {
        return section;
      }
      return Immutable.fromJS(sectionTemplate).merge(section);
    });
  }).toJS();
};

module.exports = AddOptionsForGPSTemplates = (function(_super) {
  __extends(AddOptionsForGPSTemplates, _super);

  function AddOptionsForGPSTemplates() {
    AddOptionsForGPSTemplates.__super__.constructor.call(this, false);
  }

  AddOptionsForGPSTemplates.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    AddOptionsForGPSTemplates.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds'));
    }, []);
    docIds = docIds.concat(['etd-goodPracticeStatement-template']);
    return adapter.fetch(projectId, _.compact(_.uniq(docIds))).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return W.resolve();
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        if (doc.templateDef.id !== 'good-practice-statement') {
          return doc;
        }
        doc.templateData = updateTemplateDataAndDefinition(Immutable.fromJS(doc.templateData));
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return AddOptionsForGPSTemplates;

})(Migration);
