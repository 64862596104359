module.exports = createReactClass
  displayName: 'ProgressBar'
  propTypes:
    percentage: PropTypes.number.isRequired
    displayPercentage: PropTypes.bool
    className: PropTypes.string
    innerClassName: PropTypes.string
    textClassName: PropTypes.string

  getDefaultProps: ->
    displayPercentage: true
    className: null
    innerClassName: null
    textClassName: null

  render: ->
    { percentage, displayPercentage, className, innerClassName, textClassName } = @props
    progressPercent = "#{Math.round 100 * percentage}%"

    <div className={classNames('progress-bar', className)}>
      <div
        className={classNames('progress-bar__inner', innerClassName)}
        style={{ width: progressPercent }}
      />
      {displayPercentage and <div
        className={classNames('progress-bar__text', textClassName)}
      >
        <div>{progressPercent}</div>
      </div>}
    </div>
