var W, utils,
  __slice = [].slice;

W = require('when/when');

utils = {
  getFirstKey: function(map) {
    if (Immutable.Map.isMap(map)) {
      return map.keySeq().first();
    } else {
      return null;
    }
  },
  swapItems: function(list, aIndex, bIndex) {
    var aItem, bItem;
    aItem = list.get(aIndex);
    bItem = list.get(bIndex);
    return list.withMutations(function(list) {
      list.set(aIndex, bItem);
      return list.set(bIndex, aItem);
    });
  },
  pluck: function(collection, key, defaultValue) {
    return collection.map(function(item) {
      return item.get(key, defaultValue);
    });
  },
  findWhere: function(collection, key, val) {
    return collection.find(function(item) {
      return item.get(key) === val;
    });
  },
  convertListToOrderedMap: function(list, key) {
    if (key == null) {
      key = '_id';
    }
    return list.toOrderedMap().mapEntries(function(_arg) {
      var el, _k;
      _k = _arg[0], el = _arg[1];
      return [el.get(key), el];
    });
  },
  isImmutable: R.both(R.is(Object), R.hasIn('toJS')),
  getImmutableJsOrStringContent: function(immutableJsOrString) {
    if (utils.isImmutable(immutableJsOrString)) {
      return immutableJsOrString.toJS();
    } else {
      return immutableJsOrString;
    }
  },
  reduceImmutableWithPromise: function(immutableJs) {
    return function(fn, initialValue) {
      return immutableJs.reduce(function() {
        var accPromise, args;
        accPromise = arguments[0], args = 2 <= arguments.length ? __slice.call(arguments, 1) : [];
        return accPromise.then(function(acc) {
          return fn.apply(null, [acc].concat(__slice.call(args)));
        });
      }, W.resolve(initialValue));
    };
  },
  mapImmutableToJsWithPromise: function(immutableJs) {
    return function(fn) {
      return W.all(immutableJs.map(fn).toJS());
    };
  },
  partition: function(list, predicate) {
    return list.reduce(function(_arg, item) {
      var falseItems, trueItems;
      trueItems = _arg[0], falseItems = _arg[1];
      if (predicate(item)) {
        return [trueItems.push(item), falseItems];
      } else {
        return [trueItems, falseItems.push(item)];
      }
    }, [Immutable.List(), Immutable.List()]);
  }
};

module.exports = utils;
