var EditView, Focusable, OutcomeCellEditView, PooledSensitivitySpecificityView, View, formatters, template, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/evidence_syntheses/pooled_sensitivity_specificity');

OutcomeCellEditView = require('views/outcome_cell_edit_view');

EditView = require('base/views/edit_view');

Focusable = require('base/lib/traits/focusable');

formatters = require('lib/formatters');

utils = require('base/lib/utils');

module.exports = PooledSensitivitySpecificityView = (function(_super) {
  __extends(PooledSensitivitySpecificityView, _super);

  function PooledSensitivitySpecificityView() {
    this.editClosed = __bind(this.editClosed, this);
    return PooledSensitivitySpecificityView.__super__.constructor.apply(this, arguments);
  }

  PooledSensitivitySpecificityView.prototype.template = template;

  PooledSensitivitySpecificityView.prototype.container = '.pooled-values-container';

  PooledSensitivitySpecificityView.prototype.autoRender = true;

  PooledSensitivitySpecificityView.prototype._fieldsToObserve = function(sensitivity, comparison) {
    var sensitivitySpecificity;
    comparison = comparison ? 'Comparison' : '';
    sensitivitySpecificity = sensitivity ? 'Sensitivity' : 'Specificity';
    return ['sourceOfDxData', "pooled" + sensitivitySpecificity + comparison, "pooled" + sensitivitySpecificity + comparison + "From", "pooled" + sensitivitySpecificity + comparison + "To", "pooled" + sensitivitySpecificity + comparison + "RangeFrom", "pooled" + sensitivitySpecificity + comparison + "RangeTo"];
  };

  PooledSensitivitySpecificityView.prototype._bindingsData = function(sensitivity, comparison) {
    return {
      observe: this._fieldsToObserve(sensitivity, comparison),
      onGet: (function(_this) {
        return function(values) {
          var data;
          if (_this.model.get('sourceOfDxData')) {
            data = _.object(_this._fieldsToObserve(sensitivity, comparison), values);
            return formatters.question.sensitivitySpecificity(data, sensitivity, comparison);
          } else {
            return $.t('es:outcome.diagnostic.no_source_of_data_selected');
          }
        };
      })(this)
    };
  };

  PooledSensitivitySpecificityView.prototype.bindings = function() {
    return {
      '[data-property=pooledSensitivity]': this._bindingsData(true, false),
      '[data-property=pooledSensitivityComparison]': this._bindingsData(true, true),
      '[data-property=pooledSpecificity]': this._bindingsData(false, false),
      '[data-property=pooledSpecificityComparison]': this._bindingsData(false, true)
    };
  };

  PooledSensitivitySpecificityView.prototype.initialize = function() {
    PooledSensitivitySpecificityView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'table.diagnostic-pooled-values td', this.editPooledDiagnosticValue);
    return this.enable(Focusable);
  };

  PooledSensitivitySpecificityView.prototype._getEditTitleKey = function(sensitivitySpecificity) {
    var key;
    key = (function() {
      switch (this.model.get('sourceOfDxData')) {
        case 'fromSingleStudy':
          return 'single_study_';
        case 'rangeFromStudies':
          return 'range_';
        case 'pooledAcrossStudies':
          return 'pooled_';
        default:
          return '';
      }
    }).call(this);
    key += sensitivitySpecificity;
    return "diagnostic." + key;
  };

  PooledSensitivitySpecificityView.prototype._getTemplateProperties = function(sensitivitySpecificity) {
    var comparison, propName;
    propName = _.string.capitalize(sensitivitySpecificity);
    comparison = this.property.indexOf('Comparison') > -1 ? 'Comparison' : '';
    return {
      propertyTitle: "pooled" + propName + comparison,
      propertyValue: this.model.get("pooled" + propName + comparison),
      propertyRangeFromTitle: "pooled" + propName + comparison + "RangeFrom",
      propertyRangeFromValue: this.model.get("pooled" + propName + comparison + "RangeFrom"),
      propertyRangeToTitle: "pooled" + propName + comparison + "RangeTo",
      propertyRangeToValue: this.model.get("pooled" + propName + comparison + "RangeTo"),
      propertyFromTitle: "pooled" + propName + comparison + "From",
      propertyFromValue: this.model.get("pooled" + propName + comparison + "From"),
      propertyToTitle: "pooled" + propName + comparison + "To",
      propertyToValue: this.model.get("pooled" + propName + comparison + "To")
    };
  };

  PooledSensitivitySpecificityView.prototype.editPooledDiagnosticValue = function(e) {
    var additionalTemplateData, editView, sensitivitySpecificity;
    if (e != null) {
      e.preventDefault();
    }
    if (e != null) {
      e.stopPropagation();
    }
    if (!this.model.get('sourceOfDxData')) {
      return;
    }
    this.property = $(e.target).data().property;
    if (!this.property) {
      return;
    }
    sensitivitySpecificity = this.property.toLowerCase().indexOf('sensitivity') > -1 ? 'sensitivity' : 'specificity';
    additionalTemplateData = {
      editTitleKey: this._getEditTitleKey(sensitivitySpecificity)
    };
    _(additionalTemplateData).extend(this._getTemplateProperties(sensitivitySpecificity));
    editView = new OutcomeCellEditView({
      type: EditView.TYPES.COMPOUND,
      property: this.property,
      questionModel: this.model,
      additionalTemplateData: additionalTemplateData
    });
    template = require("views/templates/outcome_edit/sensitivity_specificity_edit");
    return editView.show(this, "[data-property=" + this.property + "]", template, this.model).then((function(_this) {
      return function() {
        return _this.subview("editView-" + editView.cid, editView);
      };
    })(this));
  };

  PooledSensitivitySpecificityView.prototype.editClosed = function(accepted) {
    if (accepted == null) {
      accepted = false;
    }
    return this.render();
  };

  PooledSensitivitySpecificityView.prototype.afterRender = function() {
    PooledSensitivitySpecificityView.__super__.afterRender.apply(this, arguments);
    return this.stickit(this.model, this.bindings());
  };

  PooledSensitivitySpecificityView.prototype.dispose = function() {
    this.unstickit();
    return PooledSensitivitySpecificityView.__super__.dispose.apply(this, arguments);
  };

  return PooledSensitivitySpecificityView;

})(View);
