var DnDActions, DragDropTypes;

DnDActions = require('actions/react_dnd_actions');

DragDropTypes = require('components/scope/questions/drag_drop_types');

module.exports = {
  dragSpec: {
    canDrag: function(props, monitor) {
      return props.canDrag;
    },
    beginDrag: function(props, monitor, component) {
      var groupType, id, qIndex;
      id = props.id, qIndex = props.qIndex, groupType = props.groupType;
      DnDActions.beginDrag({
        type: DragDropTypes.APPROVAL_TAB_QUESTION,
        qIndex: qIndex,
        groupType: groupType
      });
      return {
        id: id,
        qIndex: qIndex,
        groupType: groupType
      };
    },
    isDragging: function(props, monitor) {
      return props.id === monitor.getItem().id;
    },
    endDrag: function(props, monitor) {
      return DnDActions.endDrag();
    }
  },
  dropSpec: {
    hover: function(props, monitor, component) {
      if (props.id === monitor.getItem().id) {
        return;
      }
      return DnDActions.hover({
        type: DragDropTypes.APPROVAL_TAB_QUESTION,
        hoverIndex: props.qIndex,
        hoverGroupType: props.groupType
      });
    },
    canDrop: function() {
      return false;
    }
  },
  dragCollect: function(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget()
    };
  }
};
