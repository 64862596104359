Modal = require 'components/common/modal'
Button = require 'components/common/button'
Spinner = require 'components/common/spinner'
mediator = require 'mediator'
ConnectStore = require 'components/enhancers/connect_store'
ChecklistActions = require 'actions/checklist_actions'
ChecklistStore = require 'stores/checklist_store'
ChecklistTopic = require 'components/checklist/checklist_topic'
Translation = require 'components/mixins/translation'

storeConnector =
  ChecklistStore: (Store) ->
    isFetching: Store.isFetching()
    isShowingDiagram: Store.isShowingDiagram()
    topics: Store.getTopics()
    sources: Store.getSources()
    projectChecklist: Store.getProjectChecklist()

Checklist = createReactClass
  displayName: 'Checklist'

  propTypes:
    isFetching: PropTypes.bool.isRequired
    isShowingDiagram: PropTypes.bool.isRequired
    topics: PropTypes.instanceOf(Immutable.List).isRequired
    sources: PropTypes.instanceOf(Immutable.Map).isRequired
    projectChecklist: PropTypes.object.isRequired
    type: PropTypes.oneOf(['mdg', 'regular']).isRequired

  mixins: [
    Translation('gd_checklist:topics')
  ]

  render: ->
    { sources, isFetching, topics, projectChecklist, isShowingDiagram, type } = @props

    titleKey = if type is 'mdg' then 'checklist_mdg_title' else 'checklist_title'

    if isFetching
      <Spinner />
    else
      <div className='checklist-container'>
        <div className='checklist-header'>
          <h3>{@i18n "../#{titleKey}"}</h3>
          {type isnt 'mdg' and <Button
            className=''
            label={@i18n '../guideline_development_diagram'}
            onClick={ChecklistActions.toggleProcessDiagram}
          />}
        </div>
        <div className='checklist-topics'>
          {topics.map (topic) ->
            topicId = topic.get 'id'

            <ChecklistTopic
              key={topicId}
              steps={topic.get 'steps'}
              stepsOrder={topic.get 'stepsOrder'}
              sources={sources}
              topicId={topicId}
              projectChecklistData={projectChecklist.getAt 'topics', topicId }
              type={type}
            />
          }
        </div>
        {if isShowingDiagram
          <Modal
            isOpen
            onRequestClose={ChecklistActions.toggleProcessDiagram}
            className='process-diagram-modal'
          >
            <div>
              <img src='images/guideline_development_diagram.png' />
            </div>
          </Modal>
        }
      </div>

module.exports = ConnectStore Checklist, ChecklistStore, storeConnector
