ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConclusionsJustification = require 'components/etd/conclusions_justification'
ConclusionsTextSection = require 'components/etd/conclusions_text_section'
EtdActions = require 'actions/etd_actions'
Modal = require 'components/common/modal'
MultipleRecommendations = require 'components/etd/multiple_recommendations'
OptionsTypeSection = require 'components/etd/conclusions_options_type_selection'
PanelVoiceEtdActions = require 'actions/panel_voice_etd_actions'
PanelVoiceEtdSectionEditModal = require 'components/mixins/panel_voice_etd_section_edit_modal_mixin'
RelatedRecommendations = require 'components/etd/related_recommendations'
ToggleSwitch = require 'components/common/toggle_switch'
Translation = require 'components/mixins/translation'
GdtEditor = Editor.Editor
{ func, instanceOf, string, bool } = PropTypes

ConclusionsSectionEditModal = createReactClass
  displayName: 'ConclusionsSectionEditModal'

  propType:
    assessmentSections: instanceOf(Immutable.OrderedMap)
    etdId: string.isRequired
    highlightToPanelMembers: bool
    renderMode: string.isRequired
    section: instanceOf(Immutable.Map)
    sectionId: string.isRequired

  mixins: [
    Translation('es:recommendations.table')
    PanelVoiceEtdSectionEditModal
  ]

  onClose: ->
    PanelVoiceEtdActions.toggleEtdSectionEdit @props.sectionId

  saveChanges: ->
    @conclusionsTextEditorContentRef?.saveContent()
    EtdActions.save()
    @onClose()

  conclusionsContentRef: (e) ->
    @conclusionsTextEditorContentRef = e

  render: ->
    conclusionsContainerClass = classNames 'conclusions-data',
      highlighted: @props.highlightToPanelMembers
    sectionName = @props.section.get 'description'

    {
      assessmentSections
      highlightToPanelMembers
      isConsensus
      editable
      readOnly
      renderMode
      section
      sectionId
    } = @props

    <Modal isOpen
      className='conclusions-section-edit-dialog'
      onClose={@onCancel}
      title={@i18n 'editor'}
    >
      <div className='section-details'>
        <span>{sectionName.toUpperCase()}: </span>
      </div>
      <div className='section-data-container'>
        <div id='controls-block'>
          <ToggleSwitch
            label={@i18n 'highlight_for_panel_members'}
            onChange={@toggleSectionHighlight}
            checked={highlightToPanelMembers}
          />
        </div>
        <div className={conclusionsContainerClass}>
          {if sectionId is 'justification'
            <ConclusionsJustification
              renderMode={renderMode}
              justification={section}
              assessmentSections={assessmentSections}
              onChange={@onContentChange}
              noAutoSave
              editable
            />
          else if sectionId is 'multipleRecommendations'
            <MultipleRecommendations
              renderMode={renderMode}
              editable={editable}
              readOnly={readOnly}
              sectionId={sectionId}
              section={section}
            />
          else if section.get('options')? and sectionId isnt 'decision'
            <OptionsTypeSection
              options={section.get 'options'}
              renderMode={renderMode}
              selectedOption={section.get 'selectedOption'}
              sectionId={sectionId}
              content={section.get 'content'}
              cellId={"#{sectionId}#conclusions"}
              onChange={@onContentChange}
              noAutoSave
            />
          else if section.has 'content'
            <ConclusionsTextSection
              isConsensus={isConsensus}
              editable
              refToEditor={@conclusionsContentRef}
              content={section.get('content') or null}
              cellId={"#{sectionId}#conclusions"}
              withApplyCancelButtons={false}
              onChange={@onContentChange}
              noAutoSave
            />
          }
        </div>
        {if sectionId in ['recommendation', 'decision']
          <RelatedRecommendations
            editable
          />
        }
      </div>
      <ApplyCancelButtons
        onApply={@saveChanges}
        onCancel={@onCancel}
        applyLabel={@i18n '/translation:actions.save'}
        applyClass='btn-send'
      />
    </Modal>

module.exports = ConclusionsSectionEditModal
