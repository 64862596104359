IconButton = require 'components/common/icon_button'

QuestionGroupIcons = ({editing, expanded, onDelete, onEdit, onSave, toggleExpand}) ->
  <div>
    {not editing and toggleExpand and
      <IconButton
        className='multi-comparisons__collapse-expand-icon'
        iconName={if expanded then 'collapse-icon' else 'expand-icon'}
        onClick={toggleExpand}
        inlined={false}
      />
    }
    {(expanded and not editing and onEdit) and
      <IconButton
        iconName="edit"
        onClick={onEdit}
        inlined={false}
      />
    }
    {(expanded and editing) and
      <div>
        {onSave and
          <IconButton
            iconName="save"
            onClick={onSave}
            inlined={false}
          />
        }
        {onDelete and
          <IconButton
            iconName="delete"
            onClick={onDelete}
            inlined={false}
          />
        }
      </div>
    }
  </div>

QuestionGroupIcons.propTypes =
  toggleExpand: PropTypes.func
  onEdit: PropTypes.func
  onSave: PropTypes.func
  onDelete: PropTypes.func
  editing: PropTypes.bool
  expanded: PropTypes.bool

module.exports = QuestionGroupIcons
