EllipsizedText = require 'components/common/ellipsized_text'
MdaWorkflowDialogActions = require 'actions/mda_workflow_dialog_actions'
SearchBar = require 'components/common/search_bar'
Spinner = require 'components/common/spinner'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

LAST_UPDATE_DATE_FORMAT = 'MM/DD/YYYY'
LAST_UPDATE_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss'

TopicsTable = ({
  allTables
  isFetching
  noTopicsMessageKey
  rightHeaderElement
  searchString
  selectedTopics
  sortColumn
  sortType
  topics
}) ->
  i18n = useI18n 'mda:workflow.dialog'

  onSearch = useCoffeeCallback [], (searchText) ->
    MdaWorkflowDialogActions.updateSearchString searchText

  onSearchReset = useCoffeeCallback [], ->
    MdaWorkflowDialogActions.updateSearchString ''

  onUpdateSort = useCoffeeCallback [], (newSortColumn) -> ->
    MdaWorkflowDialogActions.updateSort newSortColumn

  headerClass = useCoffeeCallback [sortColumn, sortType], (fieldKey) ->
    if fieldKey is sortColumn
      "table__header #{fieldKey} #{sortType}"
    else
      "table__header #{fieldKey}"

  <div className="content__topics">
    <div className="topics__select">
      <span>{i18n 'topics.select'}</span>
      <span>{i18n 'topics.selected', count: selectedTopics.size}</span>
    </div>
    <div className="topics__search">
      <SearchBar
        autoFocus={false}
        lowerCaseSearchText={false}
        onSearch={onSearch}
        onSearchReset={onSearchReset}
        searchOnChange
        searchText={searchString}
      />
      {rightHeaderElement}
    </div>
    {if isFetching
      <div className="topics__spinner">
        <Spinner />
      </div>
    else if topics.isEmpty()
      <div className="topics__info">
        {if searchString.length > 0
          i18n 'topics.search_results_empty', { searchString }
        else
          i18n noTopicsMessageKey
        }
      </div>
    else
      <div className="topics__table">
        <div className="table__header checkbox" />
        <div
          className={headerClass 'name'}
          onClick={onUpdateSort 'name'}
        >
          <div>{i18n 'topics.name'}</div>
        </div>
        <div className={headerClass 'tables'}>
          <div>{i18n 'topics.tables'}</div>
        </div>
        <div
          className={headerClass 'lastUpdateDate'}
          onClick={onUpdateSort 'lastUpdateDate'}
        >
          <div>{i18n 'topics.last_update'}</div>
        </div>
        {topics.map (topic) ->
          topicId = topic.get '_id'
          <MDAWorkflowDialogTopic
            checked={selectedTopics.includes topicId}
            key={topicId}
            tables={allTables}
            topic={topic}
          />
        .toJS()}
      </div>
    }
  </div>

TopicsTable.propTypes =
  allTables: PropTypes.instanceOf(Immutable.Map).isRequired
  isFetching: PropTypes.bool
  noTopicsMessageKey: PropTypes.string
  rightHeaderElement: PropTypes.node
  searchString: PropTypes.string.isRequired
  selectedTopics: PropTypes.instanceOf(Immutable.List).isRequired
  topics: PropTypes.instanceOf(Immutable.List).isRequired

TopicsTable.defaultProps =
  isFetching: false
  noTopicsMessageKey: 'topics.no_topics'

MDAWorkflowDialogTopic = ({ checked, tables, topic }) ->
  onToggleTopic = useCoffeeCallback [topic], ->
    MdaWorkflowDialogActions.toggleTopic topic.get '_id'

  [tablesForTopicNum, tablesForTopicNames] = useCoffeeMemo [tables, topic], ->
    tablesIdsForTopic = topic.get('insertedTables') ? Immutable.List()
    tablesForTopic = tablesIdsForTopic.reduce (acc, tableId) ->
      maybeTable = tables.get tableId
      return acc unless maybeTable
      acc.push maybeTable
    , Immutable.List()
    tablesNames = tablesForTopic
      .map (table) -> table.get 'name', ''
      .filterNot (name) -> name.length <= 0
      .sort()
      .toJS()
      .join ', '
    [tablesForTopic.size, tablesNames]

  lastUpdateDate = useCoffeeMemo [topic], ->
    date = topic.get 'lastUpdateDate'
    return <div /> unless date
    dateMoment = moment date
    dateFormatted = dateMoment.format LAST_UPDATE_DATE_FORMAT
    dateTimeFormatted = dateMoment.format LAST_UPDATE_DATE_TIME_FORMAT
    <Tooltip>
      <div title={dateTimeFormatted}>
        {dateFormatted}
      </div>
    </Tooltip>

  name = topic.get 'name', ''

  <div className="table__topic" onClick={onToggleTopic}>
    <div className="checkbox">
      <input checked={checked} onChange={->} type="checkbox" />
    </div>
    <Tooltip>
      <div className="name" title={name}>
        <EllipsizedText height={40} text={name} />
      </div>
    </Tooltip>
    <Tooltip>
      <div className="tables" title={tablesForTopicNames}>
        <div>{tablesForTopicNum}</div>
      </div>
    </Tooltip>
    <div className="lastUpdateDate">
      {lastUpdateDate}
    </div>
  </div>

MDAWorkflowDialogTopic.propTypes =
  checked: PropTypes.bool.isRequired
  tables: PropTypes.instanceOf(Immutable.Map).isRequired
  topic: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = TopicsTable
