var AdolopedEtdActions, AdolopmentDataActions, DbHelper, Etd, EtdActions, EtdsActions, Exceptions, GlobalExportService, HtmlExport, Project, QUESTIONS_VIEW_TYPE_TO_I18N_KEY, QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE, Question, QuestionGroupsActions, QuestionsActions, REFERENCES_VIEW, Reference, References, ReferencesActions, Request, W, adolopmentDataDocId, ajax, alt, createPrintoutContainer, getDocumentSectionsChapters, getDocumentSectionsChaptersOrder, getDocumentSectionsReferenceIds, getHTMLMarkupForDocumentSectionsFromTemplate, groupQuestionsByGroup, linksDocTemplate, mediator, pluck, poll, removeHTMLNode, sequence, _ref, _ref1, _ref2,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

ajax = require('actions/async/api_helpers').ajax;

alt = require('alt');

_ref = require('base/lib/utils'), createPrintoutContainer = _ref.createPrintoutContainer, removeHTMLNode = _ref.removeHTMLNode;

DbHelper = require('base/lib/db_helper');

AdolopmentDataActions = require('actions/adolopment_data_actions');

AdolopedEtdActions = require('actions/adoloped_etd_actions');

Etd = require('components/etd/etd');

EtdActions = require('actions/etd_actions');

EtdsActions = require('actions/etds_actions');

Exceptions = require('lib/exceptions');

_ref1 = require('lib/document_sections_utils'), getHTMLMarkupForDocumentSectionsFromTemplate = _ref1.getHTMLMarkupForDocumentSectionsFromTemplate, getDocumentSectionsChaptersOrder = _ref1.getChaptersOrder, getDocumentSectionsChapters = _ref1.getChapters, getDocumentSectionsReferenceIds = _ref1.getReferenceIds;

HtmlExport = require('lib/html_export');

linksDocTemplate = require('views/templates/export/links_doc_template');

mediator = require('mediator');

poll = require('when/poll');

Project = require('models/project');

Question = require('models/question');

_ref2 = require('lib/questions_helper'), QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE = _ref2.QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE, QUESTIONS_VIEW_TYPE_TO_I18N_KEY = _ref2.QUESTIONS_VIEW_TYPE_TO_I18N_KEY, groupQuestionsByGroup = _ref2.groupQuestionsByGroup;

pluck = require('lib/immutable_utils').pluck;

QuestionsActions = require('actions/questions_actions');

QuestionGroupsActions = require('actions/question_groups_actions');

Reference = require('models/references/reference');

References = require('models/references/references');

ReferencesActions = require('actions/references_actions');

REFERENCES_VIEW = require('lib/references_utils').DB_VIEW;

adolopmentDataDocId = require('lib/doc_ids').ADOLOPMENT_DATA;

Request = require('models/request');

sequence = require('when/sequence');

W = require('when/when');

module.exports = GlobalExportService = (function() {
  function GlobalExportService() {}

  GlobalExportService.prototype.documentsNo = 0;

  GlobalExportService.prototype.progress = 0;

  GlobalExportService.prototype.exportOptions = null;

  GlobalExportService.prototype.exporting = false;

  GlobalExportService.prototype.oldMediatorColls = null;

  GlobalExportService.prototype.oldMediatorProject = null;

  GlobalExportService.prototype.questionGroups = null;

  GlobalExportService.prototype.questionGroupsImm = null;

  GlobalExportService.prototype.etds = null;

  GlobalExportService.prototype.references = null;

  GlobalExportService.prototype.referencesImm = null;

  GlobalExportService.prototype.projectMetadoc = null;

  GlobalExportService.prototype._getRequiredData = function() {
    var adolopedProject, adolopmentDataPromise, adolopmentRecIds, adolopmentsEtdPromise, etdsPromise, projectId, promises, recommendationIds, referencesPromise;
    projectId = this.exportOptions.get('projectId');
    referencesPromise = this.pouchAdapter.fetchWithView(projectId, 'references_view', REFERENCES_VIEW);
    recommendationIds = this.exportOptions.get('questions').map(function(q) {
      return q.get('recommendationIds');
    });
    recommendationIds = _.chain(recommendationIds.toJS()).compact().flatten().uniq().value();
    adolopmentRecIds = this.exportOptions.get('questions').map(function(q) {
      return q.get('recommendationAdolopmentIds');
    });
    adolopmentRecIds = _.chain(adolopmentRecIds.toJS()).compact().flatten().uniq().value();
    etdsPromise = this.pouchAdapter.fetch(projectId, recommendationIds);
    adolopedProject = this.exportOptions.getIn(['projectMetadoc', 'adolopmentIteration'], -1) !== -1;
    adolopmentDataPromise = adolopedProject ? this.pouchAdapter.fetch(projectId, adolopmentDataDocId) : W.resolve({});
    adolopmentsEtdPromise = adolopedProject ? this.pouchAdapter.fetch(projectId, adolopmentRecIds) : W.resolve({});
    promises = [referencesPromise, etdsPromise, adolopmentDataPromise, adolopmentsEtdPromise];
    return W.all(promises).then((function(_this) {
      return function(_arg) {
        var adolopmentData, adolopmentsRows, etds, etdsRows, references;
        references = _arg[0], etdsRows = _arg[1], adolopmentData = _arg[2], adolopmentsRows = _arg[3];
        _this.projectMetadoc = _this.exportOptions.get('projectMetadoc').toJS();
        _this.questions = _this.exportOptions.get('questions');
        QuestionsActions.fetchSuccess(_.values(_this.questions.toJS()));
        EtdsActions.fetchSuccess(etdsRows);
        etds = etdsRows.rows;
        _this.etds = Immutable.fromJS(_.chain(etds).pluck('doc').compact().reduce(function(acc, etd) {
          acc[etd._id] = etd;
          return acc;
        }, {}).value());
        AdolopmentDataActions.fetchAdolopmentDataSuccess(adolopmentData.etdsData || {});
        AdolopedEtdActions.fetchSuccess(adolopmentsRows);
        _this.references = new References(_.map(references, function(attrs) {
          return new Reference(attrs);
        }));
        _this.referencesImm = Immutable.fromJS(references);
        ReferencesActions.fetchSuccess([
          {}, {
            rows: _.map(references, function(r) {
              return {
                doc: r
              };
            })
          }
        ]);
        mediator.colls = {
          references: _this.references
        };
        mediator.project = new Project(_this.projectMetadoc);
        _this.questionOrder = _this._createQuestionOrderMap();
        return _this.documentsNo = _this._calculateDocumentsNo();
      };
    })(this));
  };

  GlobalExportService.prototype["export"] = function(exportOptions) {
    if (this.exporting) {
      return W.reject(new Error('Service is already exporting'));
    }
    this.exportOptions = exportOptions;
    this.documentsNo = 0;
    this.exporting = true;
    this.oldMediatorProject = mediator.project;
    this.oldMediatorColls = mediator.colls;
    this.questionGroupsImm = this.exportOptions.get('questionGroups');
    this.questionGroups = this.questionGroupsImm.toJS();
    QuestionGroupsActions.fetchSuccess(this.questionGroups);
    return this._getRequiredData().then((function(_this) {
      return function() {
        var documentSectionsTask, etdsTasks, evidenceTablesTasks, isofTasks, presentationsTasks, selectedQuestions, tasks;
        selectedQuestions = _this.exportOptions.get('selectedQuestions').toJS();
        evidenceTablesTasks = function() {
          if (_this.exportOptions.getIn(['selectedDocuments', 'evidenceTables'])) {
            return sequence(_.map(selectedQuestions, function(questionId) {
              return function() {
                return _this._exportQuestionTables(questionId).then(function(docs) {
                  return _.map(docs, function(id) {
                    if (id != null) {
                      return {
                        id: id
                      };
                    } else {
                      return null;
                    }
                  });
                });
              };
            }));
          } else {
            return W.resolve([]);
          }
        };
        presentationsTasks = function() {
          if (_this.exportOptions.getIn(['selectedDocuments', 'presentations'])) {
            return sequence(_.map(selectedQuestions, function(questionId) {
              return function() {
                return _this._exportPresentations(questionId);
              };
            }));
          } else {
            return W.resolve([]);
          }
        };
        isofTasks = function() {
          if (_this.exportOptions.getIn(['selectedDocuments', 'isofs'])) {
            return sequence(_.map(selectedQuestions, function(questionId) {
              return function() {
                return _this._exportIsof(questionId);
              };
            }));
          } else {
            return W.resolve([]);
          }
        };
        documentSectionsTask = function() {
          if (_this.exportOptions.getIn(['selectedDocuments', 'documentSections'])) {
            return _this._exportDocumentSections();
          } else {
            return W.resolve([]);
          }
        };
        etdsTasks = function() {
          if (_this.exportOptions.getIn(['selectedDocuments', 'etds'])) {
            return sequence(_.map(selectedQuestions, function(questionId) {
              return function() {
                return _this._exportEtd(questionId);
              };
            }));
          } else {
            return W.resolve([]);
          }
        };
        tasks = [evidenceTablesTasks, presentationsTasks, isofTasks, documentSectionsTask, etdsTasks];
        return sequence(tasks).then(function(taskResults) {
          var exportedDocs, exportedDocumentSections, exportedEtds, exportedEtdsFlattened, exportedEvidenceTables, exportedEvidenceTablesFlattened, exportedIsofs, exportedPresentations, exportedPresentationsFlattened, fileName, ids, links, linksPromise, linksSource;
          exportedEvidenceTables = taskResults[0], exportedPresentations = taskResults[1], exportedIsofs = taskResults[2], exportedDocumentSections = taskResults[3], exportedEtds = taskResults[4];
          exportedEvidenceTablesFlattened = _.flatten(exportedEvidenceTables);
          exportedPresentationsFlattened = _.flatten(exportedPresentations);
          exportedEtdsFlattened = _.flatten(exportedEtds);
          exportedDocs = _.union(exportedEvidenceTablesFlattened, exportedPresentationsFlattened, exportedIsofs, exportedDocumentSections, exportedEtdsFlattened);
          ids = _.chain(exportedDocs).pluck('id').compact().value();
          links = _.chain(exportedDocs).pluck('link').compact().value();
          linksPromise = _.isEmpty(links) ? W.resolve([]) : (fileName = _this._escapeFileName($.t('projects:global_export_dialog.links_document.file_name')), linksSource = _this._prepareLinksDocument(links), _this._putHTMLSource('links', fileName, [], 'links', linksSource).then(function(id) {
            return [id];
          }));
          return linksPromise.then(function(maybeLink) {
            var fileIds, request;
            fileIds = _.union(ids, maybeLink);
            request = new Request({
              docType: 'request',
              type: 'global_export',
              status: 'new',
              fileIds: fileIds,
              rev_author: mediator.user.get('name')
            });
            mediator.user.getRequests().add(request);
            return W(request.save()).then(function() {
              return _this._watchForStatusChange(request);
            }).then(_this._getZipFileUrl);
          });
        });
      };
    })(this))["finally"]((function(_this) {
      return function() {
        _this.exporting = false;
        _this.exportOptions = null;
        _this.progress = 0;
        _this.documentsNo = 0;
        mediator.colls = _this.oldMediatorColls;
        mediator.project = _this.oldMediatorProject;
        _this.oldMediatorColls = null;
        _this.oldMediatorProject = null;
        _this.questionGroups = null;
        _this.questionGroupsImm = null;
        _this.etds = null;
        _this.projectMetadoc = null;
        _this.references = null;
        _this.referencesImm = null;
        return alt.recycle('EtdsStore', 'EtdStore', 'QuestionsStore', 'QuestionGroupsStore');
      };
    })(this));
  };

  GlobalExportService.prototype._calculateDocumentsNo = function() {
    var documentsNo, isAnyLinkSelected, selectedDiagnosticQuestionViews, selectedDocuments, selectedFormats, selectedManagementQuestionViews, selectedPresentationAudiences, selectedPresentationAudiencesNo, selectedPresentationFormatsNo, selectedQuestionIds, selectedQuestions, selectedQuestionsNo, _ref3;
    selectedDocuments = this.exportOptions.get('selectedDocuments');
    selectedFormats = this.exportOptions.get('selectedFormats');
    selectedPresentationAudiences = this.exportOptions.get('selectedPresentationAudiences');
    selectedDiagnosticQuestionViews = this.exportOptions.get('selectedDiagnosticQuestionViews');
    selectedManagementQuestionViews = this.exportOptions.get('selectedManagementQuestionViews');
    selectedQuestionIds = this.exportOptions.get('selectedQuestions');
    selectedQuestionsNo = selectedQuestionIds.size;
    documentsNo = 0;
    if (selectedDocuments.get('documentSections')) {
      documentsNo += 1;
    }
    if (selectedDocuments.get('evidenceTables')) {
      selectedQuestions = this.exportOptions.get('questions').filter(function(question) {
        return selectedQuestionIds.contains(question.get('_id'));
      }).toList();
      _ref3 = _.partition(selectedQuestions.toJS(), function(q) {
        return q.type === 'diagnostic';
      }), this.diagnosticQuestions = _ref3[0], this.managementQuestions = _ref3[1];
      documentsNo += this.diagnosticQuestions.length * selectedDiagnosticQuestionViews.size;
      documentsNo += this.managementQuestions.length * selectedManagementQuestionViews.size;
    }
    _.forEach(['isofs', 'etds'], function(documentType) {
      if (selectedDocuments.get(documentType)) {
        return documentsNo += selectedQuestionsNo;
      }
    });
    if (selectedDocuments.get('presentations')) {
      selectedPresentationFormatsNo = selectedFormats.get('presentations').size;
      selectedPresentationAudiencesNo = selectedPresentationAudiences.size;
      documentsNo += selectedQuestionsNo * selectedPresentationAudiencesNo * selectedPresentationFormatsNo;
    }
    isAnyLinkSelected = selectedFormats.some(function(formats, key) {
      if (key !== 'isofs') {
        return formats.contains('link');
      } else {
        return selectedDocuments.get('isofs');
      }
    });
    if (isAnyLinkSelected) {
      documentsNo += 1;
    }
    return documentsNo;
  };

  GlobalExportService.prototype._exportQuestionTables = function(questionId) {
    var isDiagnostic, questionAttributes, questionModel, questions, selectedViews, selectedViewsKey;
    questions = this.exportOptions.get('questions');
    questionAttributes = questions.get(questionId).toJS();
    questionModel = new Question(questionAttributes);
    isDiagnostic = questionAttributes.type === 'diagnostic';
    selectedViewsKey = isDiagnostic ? 'selectedDiagnosticQuestionViews' : 'selectedManagementQuestionViews';
    selectedViews = this.exportOptions.get(selectedViewsKey).toJS();
    return sequence(_.map(selectedViews, (function(_this) {
      return function(viewName) {
        return function() {
          return _this._exportQuestionTable(questionModel, viewName);
        };
      };
    })(this)));
  };

  GlobalExportService.prototype._exportQuestionTable = function(questionModel, viewName) {
    var directory, fileName, htmlExport, printoutType;
    printoutType = QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE[viewName];
    htmlExport = new HtmlExport(questionModel, printoutType, {
      word: true
    });
    fileName = this._escapeFileName($.t("es:outcome_view_type." + QUESTIONS_VIEW_TYPE_TO_I18N_KEY[viewName]));
    directory = this._getDirectory(questionModel.attributes);
    return htmlExport.getSource('landscape', {}, viewName).then((function(_this) {
      return function(htmlSource) {
        return _this._putHTMLSource('evidenceTable', fileName, directory, 'evidenceTables', htmlSource);
      };
    })(this));
  };

  GlobalExportService.prototype._exportPresentations = function(questionId) {
    var presentationAudiences, question, questions, selectedFormats;
    questions = this.exportOptions.get('questions');
    question = questions.get(questionId).toJS();
    presentationAudiences = this.exportOptions.get('selectedPresentationAudiences').toJS();
    selectedFormats = this.exportOptions.getIn(['selectedFormats', 'presentations']).toJS();
    return sequence(_.map(presentationAudiences, (function(_this) {
      return function(audience) {
        return function() {
          return _this._exportPresentation(questions, question, selectedFormats, audience);
        };
      };
    })(this)));
  };

  GlobalExportService.prototype._exportPresentation = function(questions, question, selectedFormats, audience) {
    var directory, documentPromise, etd, etdId, fileName, linkPromise, presentationType, projectId, projectName, source, _ref3;
    etdId = (_ref3 = question.recommendationIds) != null ? _ref3[0] : void 0;
    if (!etdId) {
      this._progressCallback(selectedFormats.length);
      return W.resolve([]);
    }
    etd = this.etds.get(etdId);
    if (!etd) {
      this._progressCallback(selectedFormats.length);
      return W.resolve([]);
    }
    presentationType = audience === 'patients' && this.switchesService.isOn('premium-presentations') ? 'premium-patients' : audience;
    projectId = this.projectMetadoc._id;
    projectName = this.projectMetadoc.name;
    documentPromise = __indexOf.call(selectedFormats, 'pdf') >= 0 ? (source = this.presentationService.exportPresentationDocHTML(presentationType, question, questions, this.questionGroupsImm, etd, this.etds, projectName), fileName = this._escapeFileName($.t("projects:global_export_dialog.links.presentation_" + audience)), directory = this._getDirectory(question), this._putHTMLSource('presentation', fileName, directory, 'presentations', source).then(function(id) {
      return {
        id: id
      };
    })) : W.resolve({});
    linkPromise = __indexOf.call(selectedFormats, 'link') >= 0 ? this.presentationService.exportPresentation(projectId, presentationType, question, questions, this.questionGroupsImm, this.etds, projectName).then((function(_this) {
      return function(url) {
        _this._progressCallback();
        return {
          link: {
            headingId: question._id,
            headingTitle: question.question,
            title: $.t("projects:global_export_dialog.links.presentation_" + audience),
            url: url
          }
        };
      };
    })(this)) : W.resolve({});
    return W.all([documentPromise, linkPromise]).then(function(_arg) {
      var maybeDocument, maybeLink;
      maybeDocument = _arg[0], maybeLink = _arg[1];
      return _.extend({}, maybeDocument, maybeLink);
    });
  };

  GlobalExportService.prototype._exportIsof = function(questionId) {
    var projectName, question;
    question = this.exportOptions.getIn(['questions', questionId]).toJS();
    projectName = this.projectMetadoc.name;
    return this.presentationService.exportIsof(question, projectName).then((function(_this) {
      return function(url) {
        _this._progressCallback();
        return {
          link: {
            headingId: question._id,
            headingTitle: question.question,
            title: $.t('projects:global_export_dialog.links.isof'),
            url: url
          }
        };
      };
    })(this));
  };

  GlobalExportService.prototype._exportDocumentSections = function() {
    var document, extraData, fileName, props, source;
    document = this.exportOptions.get('documentSections');
    props = {
      document: document,
      chapters: getDocumentSectionsChapters(document),
      chaptersOrder: getDocumentSectionsChaptersOrder(document),
      attachments: document.get('_attachments'),
      collapsedChapters: Immutable.List(),
      referenceIds: getDocumentSectionsReferenceIds(document),
      references: this.referencesImm
    };
    source = getHTMLMarkupForDocumentSectionsFromTemplate(props);
    fileName = $.t('projects:global_export_dialog.file_names.document_sections');
    extraData = {
      margins: {
        top: 0,
        left: 0,
        right: 0
      },
      orientation: 'portrait',
      pdfTemplate: document.getIn(['templateDef', 'id']),
      pdfTemplateData: {
        title: this.projectMetadoc.name
      }
    };
    return this._putHTMLSource('documentSections', fileName, [], 'documentSections', source, extraData).then(function(id) {
      return [
        {
          id: id
        }
      ];
    });
  };

  GlobalExportService.prototype._exportEtd = function(questionId) {
    var etd, etdId, htmlExport, margins, orientation, printoutEl, question, questionModel, _ref3;
    question = this.exportOptions.getIn(['questions', questionId]).toJS();
    questionModel = new Question(question);
    etdId = (_ref3 = question.recommendationIds) != null ? _ref3[0] : void 0;
    etd = etdId != null ? this.etds.get(etdId) : null;
    if (!etdId || !etd) {
      this._progressCallback();
      return W.resolve([]);
    }
    EtdActions.setTemplateWithDataForPdf(etd);
    printoutEl = createPrintoutContainer();
    ReactDOM.render(React.createElement(Etd, {
      questionId: questionId
    }), printoutEl);
    htmlExport = new HtmlExport(questionModel, 'recommendations-v2', {
      word: true,
      printoutEl: printoutEl,
      etdViewMode: 'judgements-consensus#recommendation-consensus#conclusions-proposed',
      outcomesOrAppendicesIds: []
    });
    orientation = 'landscape';
    margins = {
      top: 12.7,
      right: 12.7,
      bottom: 12.7,
      left: 12.7
    };
    return htmlExport.getSource(orientation, margins, 'recommendations-v2').then((function(_this) {
      return function(htmlSource) {
        var directory, extraData, fileName;
        directory = _this._getDirectory(question);
        fileName = $.t('projects:global_export_dialog.file_names.etd');
        extraData = {
          format: {
            orientation: orientation,
            margins: margins
          }
        };
        return _this._putHTMLSource('etd', fileName, directory, 'etds', htmlSource, extraData);
      };
    })(this)).then(function(id) {
      return [
        {
          id: id
        }
      ];
    })["finally"](function() {
      ReactDOM.unmountComponentAtNode(printoutEl);
      removeHTMLNode($(printoutEl));
      return alt.recycle('EtdStore');
    });
  };

  GlobalExportService.prototype._putHTMLSource = function(documentType, fileName, directory, formatsKey, htmlSource, extraData) {
    if (extraData == null) {
      extraData = {};
    }
    return W.promise((function(_this) {
      return function(resolve, reject) {
        var ajaxParams, document, formatsRequested, projectId, retries, selectedFormats, task;
        selectedFormats = formatsKey === 'links' ? ['docx', 'pdf'] : _this.exportOptions.getIn(['selectedFormats', formatsKey]).toJS();
        formatsRequested = _.without(selectedFormats, 'link');
        if (_.isEmpty(formatsRequested)) {
          return resolve(null);
        }
        projectId = _this.exportOptions.get('projectId');
        document = {
          formatsRequested: formatsRequested,
          projectId: projectId,
          type: documentType,
          source: htmlSource,
          directory: directory,
          fileName: fileName,
          extraData: extraData
        };
        ajaxParams = {
          url: "" + (DbHelper.getBackendUrl()) + "/export/" + projectId,
          xhrFields: {
            withCredentials: true
          },
          type: 'POST',
          data: JSON.stringify(document)
        };
        retries = 3;
        task = function() {
          return ajax(ajaxParams).then(function(_arg) {
            var id;
            id = _arg.id;
            if (!id) {
              if (!id) {
                reject(new Error('No id returned'));
              }
              return true;
            }
            _this._progressCallback();
            resolve(id);
            return true;
          })["catch"](function(e) {
            retries -= 1;
            if (retries > 0) {
              return false;
            } else {
              reject(e);
              return true;
            }
          });
        };
        return poll(task, 500, _.identity);
      };
    })(this));
  };

  GlobalExportService.prototype._progressCallback = function(progressDelta) {
    var callback;
    if (progressDelta == null) {
      progressDelta = 1;
    }
    callback = this.exportOptions.get('callback');
    this.progress += progressDelta;
    return callback(this._calculateProgress());
  };

  GlobalExportService.prototype._calculateProgress = function() {
    if (this.documentsNo <= 0) {
      return 0;
    }
    return this.progress / this.documentsNo;
  };

  GlobalExportService.prototype._createQuestionOrderMap = function() {
    var grouppedQuestions, questions;
    grouppedQuestions = groupQuestionsByGroup(this.exportOptions.get('questions'), this.exportOptions.get('questionGroups'));
    questions = grouppedQuestions.reduce(function(acc, group) {
      return acc.concat(group.get('questions').keySeq().toJS());
    }, []);
    return _(questions).reduce(function(acc, questionId, idx) {
      acc[questionId] = idx + 1;
      return acc;
    }, {});
  };

  GlobalExportService.prototype._getOrderNumberForQuestion = function(question) {
    var ordering;
    ordering = this.questionOrder[question._id];
    if (ordering != null) {
      return ordering;
    }
    ordering = question.orderNumber;
    if (ordering != null) {
      return ordering;
    }
    return this.questions.size + 1;
  };

  GlobalExportService.prototype._escapeFileName = function(name) {
    return name.trim().replace(/[^a-zA-Z0-9]/g, '_').replace(/_+/g, '_');
  };

  GlobalExportService.prototype._getDirectory = function(question) {
    var idx, _ref3;
    if (question == null) {
      question = null;
    }
    if (!question) {
      return [];
    }
    idx = this._getOrderNumberForQuestion(question);
    return [this._escapeFileName("" + idx + "." + ((_ref3 = question.questionShort) != null ? typeof _ref3.substring === "function" ? _ref3.substring(0, 100) : void 0 : void 0))];
  };

  GlobalExportService.prototype._watchForStatusChange = function(request) {
    return W.promise(function(resolve, reject) {
      return request.on('change:status', function() {
        switch (request.get('status')) {
          case 'complete':
            return resolve(request.get('zipDocId'));
          case 'error':
            return reject(new Exceptions.server_reported(request.get('error_message')));
        }
      });
    });
  };

  GlobalExportService.prototype._getZipFileUrl = function(docId) {
    return "" + (DbHelper.getBackendUrl()) + "/export/zip/" + docId;
  };

  GlobalExportService.prototype._prepareLinksDocument = function(links) {
    var headings, projectName;
    projectName = this.projectMetadoc.name;
    headings = _.chain(links).reduce(function(acc, _arg) {
      var headingId, headingTitle, title, url;
      headingId = _arg.headingId, headingTitle = _arg.headingTitle, title = _arg.title, url = _arg.url;
      if (acc[headingId] == null) {
        acc[headingId] = {
          headingTitle: headingTitle,
          links: []
        };
      }
      acc[headingId].links.push({
        title: title,
        url: url
      });
      return acc;
    }, {}).pairs().map(function(_arg) {
      var headingId, headingTitle, unsortedLinks, _ref3;
      headingId = _arg[0], (_ref3 = _arg[1], headingTitle = _ref3.headingTitle, unsortedLinks = _ref3.links);
      links = _.sortBy(unsortedLinks, 'title');
      return {
        headingTitle: headingTitle,
        links: links
      };
    }).sortBy('headingTitle').value();
    return linksDocTemplate({
      projectName: projectName,
      headings: headings
    });
  };

  return GlobalExportService;

})();
