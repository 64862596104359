ConnectStore = require 'components/enhancers/connect_store'
QuestionsListComponentStore = require 'stores/questions_list_component_store'
QuestionsStatusesStore = require 'stores/questions_statuses_store'
QuestionsStore = require 'stores/questions_store'
OverarchingQuestionsStore = require 'stores/overarching_questions_store'
EditableQuestionsList =
  require 'components/evidence_syntheses/questions_list/editable_questions_list'

storeConnector =
  QuestionsListComponentStore: (Store, props) ->
    questionsByGroup: Store.getQuestionsByGroup withEmptyGroups: props.withEmptyQuestionGroups
    groupEditState: Store.getGroupEditState()
    hasDraggingQuestion: Store.getDraggingQuestion() ? false
    hasEverSorted: Store.hasEverSorted()
    isFetchingData: Store.isFetchingData()

  QuestionsStore: (Store) ->
    isQuestionBeingAdded: Store.isQuestionBeingAdded()
    isQuestionBeingDuplicated: Store.isQuestionBeingDuplicated()
    isFetchingQuestions: Store.isFetching()
    editingQuestionId: Store.getEditingQuestionId()

  QuestionsStatusesStore: (Store) ->
    questionsStatuses: Store.getQuestionsStatuses()
    isFetchingGroups: Store.isFetching()

  OverarchingQuestionsStore: (Store, props) ->
    overarchingQuestions: Store.getOverarchingQuestionsByGroup
      withEmptyGroups: props.withEmptyQuestionGroups
    isFetchingOverarchingQuestions: Store.isFetching()

ConnectedQuestionsList = ConnectStore EditableQuestionsList,
  [
    QuestionsListComponentStore
    QuestionsStore
    QuestionsStatusesStore
    OverarchingQuestionsStore
  ],
  storeConnector

module.exports = ConnectedQuestionsList
