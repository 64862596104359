QuestionTxEdit = require 'components/scope/questions/question_tx_edit'
QuestionDxEdit = require 'components/scope/questions/question_dx_edit'
QuestionFreeEdit = require 'components/scope/questions/question_free_edit'
DeleteButton = require 'components/common/delete_button'
SaveButton = require 'components/common/save_button'
CopyButton = require 'components/common/copy_button'
Modal = require 'components/common/modal'
Confirmation = require 'components/common/confirmation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ QUESTION_DEFAULTS } = require 'lib/questions_helper'

QuestionEdit = createReactClass
  displayName: 'QuestionEdit'

  propTypes:
    type: PropTypes.string.isRequired
    data: PropTypes.object.isRequired
    onSave: PropTypes.func.isRequired
    onDelete: PropTypes.func.isRequired

  mixins: [
    CustomRenderMixin
    Translation('')
  ]

  getInitialState: ->
    confirmDelete: false

  getDefaultProps: ->
    data: Immutable.fromJS QUESTION_DEFAULTS

  # handlers
  onSave: (opts = {}) ->
    { withCopy } = opts
    data = _.extend @_question.getData(), type: @props.type
    id = @props.data.get 'id'
    @props.onSave { id, data, withCopy }

  onDelete: ->
    id = @props.data.get 'id'
    @props.onDelete id
    @closeDeleteConfirmation()

  onCopy: -> @onSave withCopy: true

  # public methods
  save: -> @onSave()

  closeDeleteConfirmation: ->
    @setState confirmDelete: false

  render: ->
    <div className='question-edit'>
      {if @props.type is 'management'
        <QuestionTxEdit ref={(el) => @_question = el} data={@props.data} />
      else if @props.type is 'diagnostic'
        <QuestionDxEdit ref={(el) => @_question = el} data={@props.data} />
      else if @props.type is 'freeform'
        <QuestionFreeEdit ref={(el) => @_question = el} data={@props.data} />
      }
      <div className="buttons" >
        <SaveButton
          title={@i18n 'actions.save'}
          onClick={@onSave}
        />
        <DeleteButton
          title={@i18n 'actions.delete'}
          onClick={=> @setState confirmDelete: true}
        />
        <CopyButton
          title={@i18n 'actions.duplicate'}
          onClick={@onCopy}
        />
      </div>
      {if @state.confirmDelete
        <Modal className='confirmation-modal' isOpen>
          <Confirmation
            onCancel={@closeDeleteConfirmation}
            onConfirm={@onDelete}
          />
        </Modal>
      }
    </div>

module.exports = QuestionEdit
