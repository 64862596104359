var MobilePublicationContactFormView, ModalView, W, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

W = require('when');

template = require('views/templates/dissemination/mobile_publication_contact_form');

mediator = require('mediator');

module.exports = MobilePublicationContactFormView = (function(_super) {
  __extends(MobilePublicationContactFormView, _super);

  function MobilePublicationContactFormView() {
    return MobilePublicationContactFormView.__super__.constructor.apply(this, arguments);
  }

  MobilePublicationContactFormView.prototype.className = 'mobile-contact-form';

  MobilePublicationContactFormView.prototype.template = template;

  MobilePublicationContactFormView.prototype.title = $.t('dissemination:publish.contact_form.title');

  MobilePublicationContactFormView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = 50;
    MobilePublicationContactFormView.__super__.initialize.apply(this, arguments);
    this._deferred = W.defer();
    this.delegate('click', 'button.cancel', this.dispose);
    return this.delegate('submit', 'form', this._submit);
  };

  MobilePublicationContactFormView.prototype.promise = function() {
    return this._deferred.promise;
  };

  MobilePublicationContactFormView.prototype.afterRender = function() {
    MobilePublicationContactFormView.__super__.afterRender.apply(this, arguments);
    return this.$('input[name=email]').val(mediator.user.get('email'));
  };

  MobilePublicationContactFormView.prototype.dispose = function() {
    if (this._deferred.promise.inspect().state === 'pending') {
      this._deferred.reject('cancelled');
    }
    return MobilePublicationContactFormView.__super__.dispose.apply(this, arguments);
  };

  MobilePublicationContactFormView.prototype._submit = function(e) {
    var data;
    e.preventDefault();
    data = _(this.$('form').serializeArray()).chain().map(function(_arg) {
      var k, v;
      k = _arg.name, v = _arg.value;
      return [k, v];
    }).object().value();
    return this._deferred.resolve(data).then(this.dispose);
  };

  return MobilePublicationContactFormView;

})(ModalView);
