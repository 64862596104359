var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "<label for=\"from_ci\">\n  95% CI ("
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.confidence_limits", {"name":"i18n","hash":{},"data":data})))
    + ") "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.from", {"name":"i18n","hash":{},"data":data})))
    + "\n</label>\n<input class=\"number\" id=\"from_ci\"\n  type=\"text\" name=\"confidenceIntervalFrom\" value=\""
    + escapeExpression(((helper = (helper = helpers.confidenceIntervalFrom || (depth0 != null ? depth0.confidenceIntervalFrom : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"confidenceIntervalFrom","hash":{},"data":data}) : helper)))
    + "\">\n<label for=\"to_ci\" class=\"middle\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.to", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n<input class=\"number\" id=\"to_ci\" type=\"text\"\n  name=\"confidenceIntervalTo\" value=\""
    + escapeExpression(((helper = (helper = helpers.confidenceIntervalTo || (depth0 != null ? depth0.confidenceIntervalTo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"confidenceIntervalTo","hash":{},"data":data}) : helper)))
    + "\">\n<div class=\"sup\"></div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}