Input = require 'components/common/input'
IconButton = require 'components/common/icon_button'
{ bool, func, string } = PropTypes

EditableTextItem = createReactClass
  displayName: 'EditableTextItem'

  propTypes:
    autoFocus: bool
    text: string
    onChange: func
    onFocus: func
    onRequestSave: func
    onRequestCancel: func
    onDelete: func
    onEdit: func
    isEditing: bool.isRequired
    inputplaceholder: string
    label: string

  getDefaultProps: ->
    autoFocus: true

  render: ->
    { text, onChange, onRequestSave, onRequestCancel, onDelete, onEdit, isEditing, label } = @props
    containerClassName = classNames 'editable-text-item', editing: isEditing

    <div className={containerClassName}>
      {if label?
        <div className='item-label'>
          {label}
        </div>
      }
      <div className='item-content'>
        {if isEditing
          <Input
            autoFocus={@props.autoFocus}
            focusSupport
            onChange={onChange}
            onFocus={@props.onFocus}
            onRequestCancel={onRequestCancel}
            onRequestSave={onRequestSave}
            value={text}
            placeholder={@props.inputPlaceholder}
          />
        else
          <div className='item-text'>
            {text}
            <IconButton iconName='edit' onClick={onEdit} />
          </div>
        }
      </div>
      <IconButton iconName='decline-icon' onClick={onDelete} />
    </div>

module.exports = EditableTextItem
