CustomRenderMixin = require 'components/mixins/custom_render_mixin'

Question = createReactClass
  displayName: 'Question'

  mixins: [CustomRenderMixin]

  propTypes:
    text: PropTypes.string.isRequired

  render: ->
    <div className={@props.className}>
      {@props.text}
      {@props.children}
    </div>

module.exports = Question
