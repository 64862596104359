var EtdTemplateDefAddRelatedRecommendations, Migration, W, mediator, migrationName, updateTemplateDataAndDefinition,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'etd_template_definitions_add_related_recommendations';

updateTemplateDataAndDefinition = function(templateDef, templateData) {
  var presentationSections;
  templateDef.presentations = _.map(templateDef.presentations, function(presentationType) {
    var hasRR;
    hasRR = presentationType.sections.find(function(section) {
      return section.id === "relatedRecommendations";
    });
    if (hasRR) {
      return presentationType;
    }
    presentationType.sections = presentationType.sections.concat({
      id: "relatedRecommendations",
      checked: true
    });
    return presentationType;
  });
  presentationSections = _(templateData.presentations.sections).reduce(function(acc, type, key) {
    var rrIdx;
    rrIdx = type.sectionsOrder.indexOf('relatedRecommendations');
    if (rrIdx === -1) {
      type.sections.relatedRecommendations = {
        name: "Related recommendations"
      };
      type.sectionsOrder = type.sectionsOrder.concat("relatedRecommendations");
    }
    acc[key] = type;
    return acc;
  }, {});
  templateData.presentations.sections = presentationSections;
  return {
    templateDef: templateDef,
    templateData: templateData
  };
};

module.exports = EtdTemplateDefAddRelatedRecommendations = (function(_super) {
  __extends(EtdTemplateDefAddRelatedRecommendations, _super);

  function EtdTemplateDefAddRelatedRecommendations() {
    EtdTemplateDefAddRelatedRecommendations.__super__.constructor.call(this, false);
  }

  EtdTemplateDefAddRelatedRecommendations.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    EtdTemplateDefAddRelatedRecommendations.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds'));
    }, []);
    docIds = docIds.concat(['etd-tx-template', 'etd-dx-template']);
    return adapter.fetch(projectId, docIds).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return W.resolve();
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        var data, def, templateData, templateDef, _ref;
        def = doc.templateDef;
        data = doc.templateData;
        _ref = updateTemplateDataAndDefinition(def, data), templateDef = _ref.templateDef, templateData = _ref.templateData;
        doc.templateDef = templateDef;
        doc.templateData = templateData;
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return EtdTemplateDefAddRelatedRecommendations;

})(Migration);
