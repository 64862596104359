CustomRenderMixin = require 'components/mixins/custom_render_mixin'
IconButton = require 'components/common/icon_button'

Controls = createReactClass

  displayName: 'Controls'

  propTypes:
    controls: PropTypes.array.isRequired

  mixins: [CustomRenderMixin]

  renderControl: (key, iconName, handler, label, disabled) ->
    <div className="control" key={key}>
      <IconButton iconName={iconName} label={label} onClick={handler} disabled={disabled} />
    </div>

  render: ->
    <td className="controls">
      {@props.controls.map (controlOptions) =>
        { name, handler, label, disabled } = controlOptions
        iconName = _.str.dasherize(name)
        @renderControl name, iconName, handler, label, disabled
      }
    </td>

module.exports = Controls
