VotingTeamComment = require 'components/etd/voting_team_comment'
TYPE_OF_RECOMMENDATION_SECTIONS = require('lib/etd_helper').getRecommendationTypeSections()
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

# sorts comments alphabetically by member's lastname
_commentsSorter = (com1, com2) ->
  a = com1.get('lastName').toLowerCase()
  b = com2.get('lastName').toLowerCase()
  a.localeCompare b

VotingTeamComments = createReactClass
  displayName: 'VotingTeamCommentsBlock'
  mixins: [CustomRenderMixin, Translation('es:recommendations.table')]

  _getShowHideProps: (voteId) ->
    { sectionId, etdPart } = @props

    { voteId, sectionId, etdPart }

  hideVote: (memberId) ->
    @props.actions.hideVote @_getShowHideProps memberId

  showVote: (memberId) ->
    @props.actions.showVote @_getShowHideProps memberId

  _isInSummaryMode: ->
    @props.displayMode is 'summary'

  _getContentFieldName: ->
    # if section doesn't have a `votingOptions` it means members' proposal are expected. Proposals
    # are stored under 'content' field.
    # If `votingOptions` then members vote either agree/disagree or on full scale (chose option)
    # themselved. In both cases the can leave comments under 'comment' field
    if @props.votingOptions
      'comment'
    else 'content'

  _getVotesByVoteOption: ->
    # depending on the displayMode filter hidden votes and then group all them by voted option
    memberVotesByOption = (if @props.displayMode is 'summary'
      @props.votingResults
    else
      @props.votingResults.filterNot (v) => @_isVoteHidden v.get 'memberId'
    ).groupBy (v) -> v.get 'selectedOption'
    # filter possible voting options leaving only those which have votes. Using possbile options
    # here instead of memberVotesByOption to keep keep same order of options as judgements have
    @props.votingOptions.filter (option) -> memberVotesByOption.has option.get 'value'
    # convert toMap with key being an option name
    .groupBy (option) -> option.get 'value'
    # populated each option with corresponding vote data
    .map (optionData, optionName) -> memberVotesByOption.get optionName

  _isVoteHidden: (memberId) ->
    @props.hiddenVotes.includes memberId

  _votesMapper: (voteDetails, idx) ->
    voteDetails = voteDetails.toJS()
    { memberId, givenNames, lastName } = voteDetails
    isHidden = @_isVoteHidden memberId
    content = voteDetails[@_getContentFieldName()]

    voteProps =
      key: idx
      name: givenNames
      lastName: lastName
      comment: content
      hidden: isHidden
      renderMode: @props.renderMode
    # depending on display mode we should either be able to HIDE a comment or SHOW it in table if
    # it was hidden
    if @_isInSummaryMode() and isHidden
      <VotingTeamComment
        key={idx}
        name={givenNames}
        lastName={lastName}
        comment={content}
        show={@showVote.bind this, memberId}
      />
    else if @_isInSummaryMode()
      <VotingTeamComment {...voteProps} />
    else
      <VotingTeamComment {...voteProps} hide={@hideVote.bind this, memberId} />

  _groupNotCommentedVotes: (votes) ->
    return false if votes.isEmpty()

    voterIds = votes.map (voteDetails) -> voteDetails.get 'memberId'
    allVotesAreHidden = voterIds.isSubset @props.hiddenVotes

    votesProps = {
      votes
      namesOnly: true
      renderMode: @props.renderMode
    }
    # passing different buttons (hide/show) handlers depending on the mode the component is in and
    # the vote being hidden or not.
    if @_isInSummaryMode() and allVotesAreHidden
      votesProps.show = @showVote.bind this, voterIds
    else unless @_isInSummaryMode()
      votesProps.hide = @hideVote.bind this, voterIds

    <VotingTeamComment {...votesProps} />

  _getVotesAndCommentsSummary: ->
    commentsByVoteOption = @_getVotesByVoteOption()
    commentsByVoteOption.map (votes, voteOptionName) =>
      splittedVotes = votes.reduce (acc, vote) ->
        updateKey = if vote.get('comment')? then 'commented' else 'noComments'
        acc.update updateKey, Immutable.List(), (votesList) -> votesList.push vote
      , Immutable.Map()
      commentedVotes = splittedVotes.get 'commented', Immutable.List()
      noCommentsVotes = splittedVotes.get 'noComments',Immutable.List()

      optionLabel = if voteOptionName in ['agree', 'disagree', 'dont_know']
        @i18n voteOptionName
      else if @props.etdPart in ['conclusions', 'recommendation']
        @props.votingOptions.find((o) -> o.get('value') is voteOptionName).get 'text'
      else
        @i18n "radio_values.#{voteOptionName}"

      <div key={voteOptionName} className="comments-votes">
        <span className="comments-label">{optionLabel}</span>
        {commentedVotes.map(@_votesMapper).toList()}
        {@_groupNotCommentedVotes noCommentsVotes}
      </div>
    .toList()

  _getProposalsSummary: ->
    <div className='comments-votes'>
      {@props.votingResults.map(@_votesMapper).toList()}
    </div>

  _getTeamVotesSummary: ->
    if @_getContentFieldName() is 'content'
      @_getProposalsSummary()
    else
      @_getVotesAndCommentsSummary()

  render: ->
    if @props.votingResults.size is 0
      <div className="comment empty">
        <span>{@i18n 'no_votes_yet'}</span>
      </div>
    else if @props.votingResults.size is @props.hiddenVotes.size and not @_isInSummaryMode()
      <div className="hidden-info">
        <span>{@i18n 'all_hidden'}</span>
      </div>
    else
      <div className="team-comments">
        {@_getTeamVotesSummary()}
        {if @props.hiddenVotes.size and not @_isInSummaryMode()
          <div className="hidden-info">
            <span>{@props.hiddenVotes.size} {@i18n 'hidden'}</span>
          </div>
        }
      </div>

module.exports = VotingTeamComments
