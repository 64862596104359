ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
MdaWorkflowDialogActions = require 'actions/mda_workflow_dialog_actions'
MdaWorkflowDialogStore = require 'stores/mda_workflow_dialog_store'
Modal = require 'components/common/modal'
Spinner = require 'components/common/spinner'
TopicsTable = require 'components/mda/workflow/topics_table'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

storeConnector =
  MdaWorkflowDialogStore: (Store) ->
    allTables: Store.getAllTables()
    allTopics: Store.getAllTopics()
    isCreating: Store.isCreating()
    isFetchingTopics: Store.isFetchingTopics()
    isOpen: Store.isOpen 'create'
    name: Store.getName()
    organizationId: Store.getOrganizationId()
    searchString: Store.getSearchString()
    selectedTopics: Store.getSelectedTopics()
    sortColumn: Store.getSortColumn()
    sortType: Store.getSortType()
    topics: Store.getTopics()

WORKFLOW_FORM =
  main: [
    fieldKey: 'name'
    type: 'string'
    showClear: false
    useControlledInput: true
  ]

MDAWorkflowDialog = ({
  allTables
  allTopics
  isCreating
  isFetchingTopics
  isOpen
  name
  organizationId
  searchString
  selectedTopics
  sortColumn
  sortType
  topics
}) ->
  i18n = useI18n 'mda:workflow.dialog'

  onApply = useCoffeeCallback [allTopics, name, organizationId, selectedTopics], ->
    MdaWorkflowDialogActions.createWorkflow { allTopics, name, organizationId, selectedTopics }

  onChange = useCoffeeCallback [], (_field, value) ->
    MdaWorkflowDialogActions.updateName value

  <Modal
    className="mda-workflow-modal"
    isOpen={isOpen}
    modalSize="large"
    title={i18n '../create'}
  >
    {if isFetchingTopics
      <Spinner />
    else
      <div className="mda-workflow-modal__content">
        <ExtractionForm
          data={{ name }}
          form={WORKFLOW_FORM}
          i18n={i18n}
          inModal
          onChange={onChange}
        />
        <TopicsTable
          allTables={allTables}
          searchString={searchString}
          topics={topics}
          sortColumn={sortColumn}
          sortType={sortType}
          selectedTopics={selectedTopics}
        />
        <ApplyCancelButtons
          applyLabel={i18n '../create'}
          applying={isCreating}
          isCancelEnabled={not isCreating}
          isSubmitEnabled={name.length > 0 and not selectedTopics.isEmpty()}
          onApply={onApply}
          onCancel={MdaWorkflowDialogActions.closeDialog}
        />
      </div>
    }
  </Modal>

MDAWorkflowDialog.propTypes =
  allTables: PropTypes.instanceOf(Immutable.Map).isRequired
  allTopics: PropTypes.instanceOf(Immutable.Map).isRequired
  isCreating: PropTypes.bool.isRequired
  isFetchingTopics: PropTypes.bool.isRequired
  isOpen: PropTypes.bool.isRequired
  name: PropTypes.string.isRequired
  organizationId: PropTypes.string
  searchString: PropTypes.string.isRequired
  selectedTopics: PropTypes.instanceOf(Immutable.List).isRequired
  sortColumn: PropTypes.oneOf(['name', 'lastUpdateDate']).isRequired
  sortType: PropTypes.oneOf(['asc', 'desc']).isRequired
  topics: PropTypes.instanceOf(Immutable.List).isRequired

module.exports = ConnectStore MDAWorkflowDialog, MdaWorkflowDialogStore, storeConnector
