{ FixedHeaderTable, Header, Body } = require 'components/common/fixed_header_table'


gdtTableSchemeType = PropTypes.shape
  headerScheme: PropTypes.arrayOf(PropTypes.object).isRequired
  itemSchemeProvider: PropTypes.func.isRequired
  cellSpecs: PropTypes.object.isRequired

GdtTable = createReactClass
  displayName: 'GdtTable'

  propTypes:
    items: PropTypes.array.isRequired
    table: PropTypes.func.isRequired
    additionalProps: PropTypes.object
    tableContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
    tableClassName: PropTypes.string
    bodyClassName: PropTypes.string

  renderRow: (rowCells, idx) ->
    { table } = @props
    <tr key={idx}>
      {rowCells.map (cell) ->
        { id, attrs } = cell
        { Component } = attrs
        props = R.omit ['Component'], attrs

        <Component id={id} {...props} key={id} />
      }
    </tr>

  renderItemRows: (itemRows, idx) ->
    <tbody key={idx}>
      {itemRows.map @renderRow}
    </tbody>

  render: ->
    { table, items, tableContainer, tableClassName, bodyClassName, additionalProps } = @props
    { header, body } = table(items, additionalProps)

    <FixedHeaderTable container={tableContainer} className={tableClassName} >
      <Header>
        <thead>
          {header.map @renderRow}
        </thead>
      </Header>
      <Body className={bodyClassName}>
        {body.map @renderItemRows}
      </Body>
    </FixedHeaderTable>

module.exports = GdtTable
