var Migration, PresentationSubModule, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = PresentationSubModule = (function(_super) {
  __extends(PresentationSubModule, _super);

  function PresentationSubModule() {
    PresentationSubModule.__super__.constructor.call(this, false);
  }

  PresentationSubModule.prototype.up = function(project, colls) {
    var esModule, module;
    PresentationSubModule.__super__.up.apply(this, arguments);
    if (_(project.get('modules')).findWhere({
      id: 'presentations'
    }) || project.get('modules') === '*') {
      return W.resolve();
    }
    module = {
      id: 'presentations',
      disabled: false
    };
    esModule = _(project.get('modules')).findWhere({
      id: 'evidence_syntheses'
    });
    if (esModule != null) {
      esModule.submodules.splice(8, 0, module);
    }
    return project.save();
  };

  return PresentationSubModule;

})(Migration);
