var CI_LEVEL, NmaOutcomeDoc, NmaQuestionDoc, W, appUtils, gdtInterventionToNmaVizIntervention, gdtOutcomeToNmaVizOutcomeForImport, gdtOutcomeTypeToDefaultEffectMeasure, generateActions, importNMAData, importNetTableResults, mediator, parseFile, parseImportResultsToGdtOutcome, _ref;

appUtils = require('lib/app_utils');

_ref = require('lib/nma_helper'), CI_LEVEL = _ref.CI_LEVEL, gdtInterventionToNmaVizIntervention = _ref.gdtInterventionToNmaVizIntervention, gdtOutcomeToNmaVizOutcomeForImport = _ref.gdtOutcomeToNmaVizOutcomeForImport, gdtOutcomeTypeToDefaultEffectMeasure = _ref.gdtOutcomeTypeToDefaultEffectMeasure, importNMAData = _ref.importNMAData, parseImportResultsToGdtOutcome = _ref.parseImportResultsToGdtOutcome;

generateActions = require('actions/utils/generate_actions');

importNetTableResults = NmaComponents.importNetTableResults, parseFile = NmaComponents.parseFile;

mediator = require('mediator');

NmaOutcomeDoc = require('lib/db_docs/nma_outcome_doc');

NmaQuestionDoc = require('lib/db_docs/nma_question_doc');

W = require('when/when');

module.exports = generateActions({
  autoGenerate: ['closeImportDialog', 'importFinished', 'goBackOnImportDialog', 'openImportDialog', 'parseImportFileError', 'parseImportFileSuccess', 'resetImportFile', 'setImportFile', 'setImportMappingEntry'],
  name: 'NMAOutcomeActions',
  actions: {
    parseImportFile: function(fileData, outcome, question) {
      var file, fileName;
      this.dispatch();
      file = fileData.file, fileName = fileData.fileName;
      return parseFile(file).then((function(_this) {
        return function(rows) {
          var dataToPass, effectType, outcomeId, outcomeToPass, outcomeType, rawResults, results;
          outcomeToPass = gdtOutcomeToNmaVizOutcomeForImport(outcome);
          dataToPass = {
            interventions: question.get('interventions', Immutable.List()).map(gdtInterventionToNmaVizIntervention).toJS(),
            outcomes: [outcomeToPass],
            comparisons: []
          };
          outcomeId = outcome.get('_id');
          outcomeType = outcome.get('type');
          effectType = gdtOutcomeTypeToDefaultEffectMeasure(outcomeType);
          rawResults = importNetTableResults(rows, dataToPass, outcomeToPass, effectType, CI_LEVEL);
          results = parseImportResultsToGdtOutcome(rawResults, outcomeId);
          return _this.actions.parseImportFileSuccess({
            parsedData: results,
            question: question
          });
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('nma:import_modal.parse_error', {
            fileName: fileName
          }));
          return _this.actions.parseImportFileError();
        };
      })(this));
    },
    "import": function(mappingData, question, outcome, fileName) {
      var data, mapping, matchedInterventions, outcomeAfterChanges, questionAfterChanges, unmatchedInterventions, usedInterventions, _ref1;
      this.dispatch();
      data = mappingData.get('data');
      mapping = mappingData.get('mapping');
      matchedInterventions = mappingData.get('matchedInterventions', Immutable.List());
      unmatchedInterventions = mappingData.get('unmatchedInterventions', Immutable.List());
      usedInterventions = matchedInterventions.concat(unmatchedInterventions).toJS();
      _ref1 = importNMAData(data.toJS(), usedInterventions, question, outcome, mapping, fileName), outcomeAfterChanges = _ref1.outcome, questionAfterChanges = _ref1.question;
      return W.all([NmaQuestionDoc.at(mediator.project.id, question.get('_id')).update(questionAfterChanges.toJS()), NmaOutcomeDoc.at(mediator.project.id, outcome.get('_id')).update(outcomeAfterChanges.toJS())]).then(function() {
        return mediator.dialogs.info($.t('nma:import_modal.import_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:import_modal.import_error')))["finally"]((function(_this) {
        return function() {
          return _this.actions.importFinished();
        };
      })(this));
    }
  }
});
