ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
Modal = require 'components/common/modal'
MdgTableActions = require 'actions/mdg_table_actions'
MDGTableStore = require 'stores/mdg_table_store'
QuestionsStore = require 'stores/questions_store'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsSelector = require 'components/common/questions_selector'
Translation = require 'components/mixins/translation'

storeConnector =
  MDGTableStore: (Store) ->
    duplicateStudyDialogState: Store.getDuplicateStudyDialogState()
    currentQuestion: Store.getCurrentQuestion()
  QuestionsStore: (Store) ->
    questions: Store.getQuestions()
  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups()

DuplicateStudiesDialog = createReactClass
  displayName: 'DuplicateStudiesDialog'

  mixins: [Translation('mdg_tables:duplicate_study_dialog')]

  propTypes:
    duplicateStudyDialogState: PropTypes.instanceOf(Immutable.Map).isRequired
    questions: PropTypes.instanceOf(Immutable.Map).isRequired
    currentQuestion: PropTypes.instanceOf(Immutable.Map).isRequired

  getInitialState: ->
    targetQuestionIds: Immutable.List()

  isSubmitEnabled: ->
    not @state.targetQuestionIds.isEmpty()

  duplicateStudy: ->
    { currentQuestion, duplicateStudyDialogState } = @props
    { targetQuestionIds } = @state
    studyId = duplicateStudyDialogState.get('studyId')

    study = currentQuestion.get('studies').find (study) ->
      study.get('id') is studyId
    MdgTableActions.duplicateStudyToAnotherQuestion study, targetQuestionIds
    .finally =>
      @setState targetQuestionIds: Immutable.List()

  updateSelected: (questionIds) ->
    @setState targetQuestionIds: questionIds

  closeDuplicateStudyDialog: ->
    MdgTableActions.closeDuplicateStudyDialog()
    @setState targetQuestionIds: Immutable.List()

  render: ->
    { duplicateStudyDialogState, questions, questionGroups, currentQuestion } = @props
    { targetQuestionIds } = @state

    blockedQuestions = Immutable.fromJS([currentQuestion.get('_id')])

    <Modal
      className="mdg-create-question-dialog"
      isOpen={duplicateStudyDialogState.get('isOpen')}
      modalSize="medium"
      title={@i18n 'title'}
    >
      <QuestionsSelector
        className="mb-10"
        questions={questions}
        blockedQuestions={blockedQuestions}
        questionGroups={questionGroups}
        updateSelected={@updateSelected}
        selectedQuestions={targetQuestionIds}
        withSelectAll={false}
        withNumbers
      />
      <ApplyCancelButtons
        applying={duplicateStudyDialogState.get('duplicating')}
        applyLabel={@i18n 'duplicate_study'}
        isSubmitEnabled={@isSubmitEnabled()}
        onApply={@duplicateStudy}
        onCancel={@closeDuplicateStudyDialog}
      />
    </Modal>

module.exports = ConnectStore DuplicateStudiesDialog,
  [MDGTableStore, QuestionsStore, QuestionGroupsStore],
  storeConnector
