var INITIAL_STATE, MDGSearchStrategyActions, UNFILLED_GENERAL_INFORMATION_MODAL_STATE, addIdFn, filterOutFn, generateStore;

generateStore = require('stores/utils/generate_store');

MDGSearchStrategyActions = require('actions/mdg_search_strategy_actions');

addIdFn = function(idToAdd) {
  return function(rows) {
    return rows.push(idToAdd);
  };
};

filterOutFn = function(idToFilterOut) {
  return function(rows) {
    return rows.filter(function(id) {
      return id !== idToFilterOut;
    });
  };
};

UNFILLED_GENERAL_INFORMATION_MODAL_STATE = Immutable.fromJS({
  diagnosis: '',
  lastUpdate: '',
  notes: '',
  numberOfSavedArticles: '',
  searchDate: {
    from: '',
    to: ''
  },
  searchTermUsed: '',
  searchTime: '',
  treatmentTopic: ''
});

INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false,
    fetchingError: null
  },
  activeTab: 'pubMed',
  currentSearchStrategy: Immutable.Map(),
  expandedRows: Immutable.List(),
  generalInformationModalState: UNFILLED_GENERAL_INFORMATION_MODAL_STATE,
  isGeneralInformationModalOpen: false
});

module.exports = generateStore({
  name: 'MDGSearchStrategyStore',
  initialState: INITIAL_STATE,
  boundActions: [MDGSearchStrategyActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'isFetching'], true);
        state.setIn(['meta', 'fetchingError'], null);
        return state.set('currentSearchStrategy', Immutable.Map());
      }));
    },
    onFetchSuccess: function(searchStrategy) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('currentSearchStrategy', Immutable.fromJS(searchStrategy));
        state.set('activeTab', 'pubMed');
        state.setIn(['meta', 'isFetching'], false);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchError: function(err) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('currentSearchStrategy', Immutable.Map());
        state.set('activeTab', 'pubMed');
        state.setIn(['meta', 'isFetching'], false);
        return state.setIn(['meta', 'fetchingError'], err);
      }));
    },
    onDbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      return this.setState(this.state.set('currentSearchStrategy', Immutable.fromJS(doc)));
    },
    onSetActiveTab: function(activeTab) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('activeTab', activeTab);
        return state.set('expandedRows', Immutable.List());
      }));
    },
    onCollapseRow: function(typeId) {
      return this.setState(this.state.update('expandedRows', filterOutFn(typeId)));
    },
    onExpandRow: function(typeId) {
      return this.setState(this.state.update('expandedRows', addIdFn(typeId)));
    },
    onOpenGeneralInformationModal: function() {
      var generalInformation;
      generalInformation = this.state.getIn(['currentSearchStrategy', 'generalInformation']);
      return this.setState(this.state.withMutations(function(state) {
        state.set('isGeneralInformationModalOpen', true);
        return state.set('generalInformationModalState', generalInformation);
      }));
    },
    onCloseGeneralInformationModal: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.set('isGeneralInformationModalOpen', false);
        return state.set('generalInformationModalState', UNFILLED_GENERAL_INFORMATION_MODAL_STATE);
      }));
    },
    onUpdateGeneralInformationField: function(_arg) {
      var field, newValue, value;
      field = _arg.field, value = _arg.value;
      newValue = field === 'searchDate' ? Immutable.fromJS(value) : value;
      return this.setState(this.state.setIn(['generalInformationModalState', field], newValue));
    },
    onSaveGeneralInformation: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.set('isGeneralInformationModalOpen', false);
        return state.set('generalInformationModalState', UNFILLED_GENERAL_INFORMATION_MODAL_STATE);
      }));
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'isFetching']);
    },
    getFetchingError: function() {
      return this.state.getIn(['meta', 'fetchingError']);
    },
    getCurrentSearchStrategy: function() {
      return this.state.get('currentSearchStrategy');
    },
    getActiveTab: function() {
      return this.state.get('activeTab');
    },
    getExpandedRows: function() {
      return this.state.get('expandedRows');
    },
    isGeneralInformationModalOpen: function() {
      return this.state.get('isGeneralInformationModalOpen');
    },
    getGeneralInformationModalState: function() {
      return this.state.get('generalInformationModalState');
    }
  }
});
