var Collection, Model, ModelTrait, Trait,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Trait = require('base/lib/traits/trait');

Model = require('base/models/model');

Collection = require('base/models/collection');

module.exports = ModelTrait = (function(_super) {
  __extends(ModelTrait, _super);

  function ModelTrait() {
    return ModelTrait.__super__.constructor.apply(this, arguments);
  }

  ModelTrait.prototype.apply = function(model) {
    if (!((model instanceof Model) || (model instanceof Collection))) {
      throw new Error('ModelTrait can only be applied to Model or Collection');
    }
    return ModelTrait.__super__.apply.apply(this, arguments);
  };

  return ModelTrait;

})(Trait);
