ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
TasksActions = require 'actions/tasks_actions'
TasksStore = require 'stores/tasks_store'
Modal = require 'components/common/modal'
{ useCoffeeCallback, useCoffeeEffect, useI18n } = require 'lib/react_utils'
{ useState } = React

storeConnector =
  TasksStore: (Store) ->
    editingTask: Store.getEditingTask()

TASK_FORM =
  main: [
    fieldKey: 'title'
    type: 'string'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'dueDate'
    type: 'date'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'assignedTo'
    type: 'string'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'reviewedBy'
    type: 'string'
    showClear: false
    useControlledInput: true
  ,
    fieldKey: 'condition'
    type: 'string'
    showClear: false
    useControlledInput: true
  ]

TaskFormModal = ({ editingTask }) ->
  i18n = useI18n 'tasks:form'

  [currentTask, setCurrentTask] = useState(editingTask)

  useCoffeeEffect [editingTask], -> setCurrentTask editingTask

  onApply = useCoffeeCallback [currentTask], ->
    if currentTask.get('_id')?
      TasksActions.updateTask currentTask
    else
      TasksActions.createTask currentTask

  onChange = (key, value) -> setCurrentTask (task) ->
    if key is 'dueDate'
      task.withMutations (t) ->
        t.set key, moment(value).valueOf()
        t.set 'start', moment(value).startOf('day').unix()
        t.set 'end', moment(value).endOf('day').unix()
    else
      task.set key, value

  <Modal
    className="tasks-modal"
    isOpen={Boolean editingTask}
    modalSize="medium"
    title={i18n if editingTask?.get('_id')? then '../todo.edit_title' else '../todo.create_title'}
  >
    <ExtractionForm
      data={(currentTask or Immutable.Map()).toJS()}
      form={TASK_FORM}
      i18n={i18n}
      inModal
      onChange={onChange}
    />
    <ApplyCancelButtons
      applyLabel={i18n '/actions.save'}
      containerClass="mt-10"
      isSubmitEnabled={not _.isEmpty(currentTask?.get('title'))}
      onApply={onApply}
      onCancel={TasksActions.closeCreateOrEditDialog}
    />
  </Modal>

TaskFormModal.propTypes =
  editingTask: PropTypes.instanceOf(Immutable.Map)

TaskFormModal.defaultProps =
  editingTask: Immutable.Map()

module.exports = ConnectStore TaskFormModal, TasksStore, storeConnector
