SpinnerSmall = require 'components/common/spinner_small'
QuestionsActions = require 'actions/questions_actions'
Translation = require 'components/mixins/translation'
Slider = require 'components/common/slider'
{ bool, string } = PropTypes

OverReviewModuleStateToggle = createReactClass
  displayName: 'OverReviewModuleStateToggle'

  mixins: [
    Translation()
  ]

  propTypes:
    currentState: bool
    questionId: string.isRequired

  handleOverReviewToggle: (evt) ->
    questionId = @props.questionId
    isEnabled = evt.target.checked

    QuestionsActions.toggleOverReview { questionId, isEnabled }

  componentDidMount: ->
    unless @props.currentState?
      _.defer =>
        QuestionsActions.getQuestionOverReviewModuleState @props.questionId

  render: ->
    { currentState} = @props

    <div className='over-review-toggle'>
      {if currentState?
        <Slider onChange={@handleOverReviewToggle} checked={currentState} />
      else
        <SpinnerSmall />
      }
      <span>{@i18n 'es:over_review'}</span>
    </div>

module.exports = OverReviewModuleStateToggle
