GdtEditor = Editor.Editor
{ builtInStyleControls: controls } = Editor
{ rawContentToHTML } = require 'lib/draft_utils'
editorControls = [
  controls.BOLD
  controls.ITALIC
  controls.UNDERLINE
  controls.UNORDERED_LIST
  controls.CLEAR_FORMATING
]

NarrativeDescEditCell = createReactClass
  displayName: "NarrativeDescEditCell"

  propTypes:
    outcomeModel: PropTypes.instanceOf(Immutable.Map).isRequired

  onBlur: ->
    @props.outcomeModel.set 'narrativeDesc', rawContentToHTML(@editor.getEditorContent())

  render: ->
    <div>
      <GdtEditor
        controlsOrder={editorControls}
        ref={(el) => @editor = el}
        onBlur={@onBlur}
        editorContent={@props.outcomeModel.get('narrativeDesc')}
      />
    </div>

module.exports = NarrativeDescEditCell
