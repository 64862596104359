var ReactComponent, ReactToolbarView, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

module.exports = ReactToolbarView = (function(_super) {
  __extends(ReactToolbarView, _super);

  function ReactToolbarView() {
    return ReactToolbarView.__super__.constructor.apply(this, arguments);
  }

  ReactToolbarView.prototype.container = '#toolbar-container';

  ReactToolbarView.prototype.className = 'react-toolbar-view';

  ReactToolbarView.prototype.autoRender = true;

  ReactToolbarView.prototype.initialize = function() {
    var component;
    component = this.options.component;
    if (!component) {
      throw new Error('component was not provided');
    }
    ReactToolbarView.__super__.initialize.apply(this, arguments);
    this.Component = component;
    this.componentProps = this.options.props || {};
    return this.enable(ReactComponent);
  };

  ReactToolbarView.prototype.afterRender = function() {
    ReactToolbarView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(this.Component, this.componentProps);
  };

  ReactToolbarView.prototype.dispose = function() {
    this.unmountComponent();
    return ReactToolbarView.__super__.dispose.apply(this, arguments);
  };

  return ReactToolbarView;

})(View);
