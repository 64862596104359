VoteOption = ({
  text,
  votesCount,
  membersVotedCount,
  onClick,
  isActive,
  resultsViewType
}) ->

  votesRatio = membersVotedCount and votesCount/membersVotedCount
  # magic number below (220) comes from static width of judgment container and static .votes-count
  # container width
  barWidth = "#{Math.round(220 * votesRatio)}px"
  containerClass = classNames 'vote-option-container', active: isActive, selectable: onClick?

  <div className={containerClass} onClick={onClick}>
    <div className='option-text'>{text}</div>
    <div className='votes-bar' style={width: barWidth} />
    <div className='votes-count'>
      <span>
        {if resultsViewType is 'numeric'
          votesCount
        else
          "#{Math.round 100 * votesRatio}%"
        }
      </span>
    </div>
  </div>

module.exports = VoteOption
