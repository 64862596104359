var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = this.invokePartial(partials.searchBar, '', 'searchBar', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"tabspane\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.tabs : depth0), {"name":"each","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n<div class=\"tab-panels\">\n  <div class=\"footnotes-tab-panel ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.activeTab : depth0), "footnotes", {"name":"is","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n    <ul class=\"footnotes-list\">\n    </ul>\n  </div>\n    <div class=\"references-tab-panel ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.activeTab : depth0), "references", {"name":"is","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n      <ul class=\"references-list\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.references : depth0), {"name":"each","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </ul>\n    </div>\n</div>\n<button class=\"add-new\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "add_new", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n<div class=\"row\">\n  <div class=\"col-6\">\n    <button class=\"btn btn-cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/translation:actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n  <div class=\"col-6\">\n    <button class=\"btn btn-save\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/translation:actions.save", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n</div>\n";
},"2":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:footnotes", {"name":"i18nNamespace","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "      <div data-tab=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\" class=\"tab ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depths[2] != null ? depths[2].activeTab : depths[2]), depths[1], {"name":"is","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, depth0, {"name":"i18n","hash":{},"data":data})))
    + "\n      </div>\n";
},"4":function(depth0,helpers,partials,data) {
  return "tab--selected";
  },"6":function(depth0,helpers,partials,data) {
  return "active";
  },"8":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "          <li>\n            <div class=\"display-reference\">\n              <div class=\"checkbox\">\n                <input id=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" type=\"checkbox\" value=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.checked : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n              </div>\n              <label for=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                <span>"
    + escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"text","hash":{},"data":data}) : helper)))
    + "</span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.attachmentLink : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "              </label>\n            </div>\n          </li>\n";
},"9":function(depth0,helpers,partials,data) {
  return "checked";
  },"11":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                  <div class=\"attachment-link\">\n                    <a href=\""
    + escapeExpression(((helper = (helper = helpers.attachmentLink || (depth0 != null ? depth0.attachmentLink : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"attachmentLink","hash":{},"data":data}) : helper)))
    + "\" rel=\"noreferrer noopener\" target=\"_blank\">\n                      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "translation:actions.open", {"name":"i18n","hash":{},"data":data})))
    + "\n                    </a>\n                  </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:footnotes", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}