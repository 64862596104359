var DisseminationModuleChangesMigration, Migration, SUBMODULES, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

SUBMODULES = ['decision_aids', 'dbep', 'mobile'];

module.exports = DisseminationModuleChangesMigration = (function(_super) {
  __extends(DisseminationModuleChangesMigration, _super);

  function DisseminationModuleChangesMigration() {
    DisseminationModuleChangesMigration.__super__.constructor.call(this, false);
  }

  DisseminationModuleChangesMigration.prototype.up = function(project) {
    var disabled, disseminationModule, modules;
    DisseminationModuleChangesMigration.__super__.up.apply(this, arguments);
    modules = project.get('modules');
    if (modules === '*') {
      return W.resolve();
    }
    disseminationModule = R.last(modules);
    if (disseminationModule.id !== 'dissemination') {
      disseminationModule = {
        id: 'dissemination',
        disabled: false,
        submodules: []
      };
      modules.push(disseminationModule);
    }
    disabled = disseminationModule.disabled;
    disseminationModule.submodules = _.map(SUBMODULES, function(id) {
      return {
        id: id,
        disabled: disabled
      };
    });
    modules[modules.length - 1] = disseminationModule;
    project.set('modules', modules);
    return project.save();
  };

  return DisseminationModuleChangesMigration;

})(Migration);
