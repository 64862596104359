ComparisonsReviewSummary = require './comparisons_review_summary'
ComparisonsSelection = require './comparisons_selection'
ConnectStore = require 'components/enhancers/connect_store'
MultiComparisonsActions = require 'actions/multi_comparisons_actions'
MultiComparisonsStore = require 'stores/multi_comparisons_store'
MultiComparisonsTitle = require './multi_comparisons_title'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
Spinner = require 'components/common/spinner'
SpinnerButton = require 'components/common/spinner_button'
Translation = require 'components/mixins/translation'
{ filterGoodPracticeStatement } = require 'lib/questions_helper'
{ filterQuestionKeys, filterQuestionsWithTheSameType } = require 'lib/multi_comparisons_helper'

storeConnectors =
  MultiComparisonsStore: (Store) ->
    savingComparison: Store.isSavingComparison()
    comparisonsEditData: Store.getComparisonsEditData()
  QuestionsStore: (Store) ->
    # We don't need all question fields here.
    # Question ID is stored under 'questionId' field to indicate that it's not a document
    questions: Store.getQuestions().filterNot(filterGoodPracticeStatement).map filterQuestionKeys
    isFetchingQuestions: Store.isFetching()
  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups()
    isFetchingQuestionGroups: Store.isFetching()

MultiComparisonsCreate = createReactClass
  displayName: 'MultiComparisonsCreate'

  mixins: [ Translation('') ]

  propTypes:
    questions: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.OrderedMap),
      PropTypes.instanceOf(Immutable.Map)]
    ).isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    comparisonsEditData: PropTypes.instanceOf(Immutable.Map)
    savingComparison: PropTypes.bool.isRequired
    isFetchingQuestions: PropTypes.bool.isRequired
    isFetchingQuestionGroups: PropTypes.bool.isRequired

  getDefaultProps: ->
    comparisonsEditData: Immutable.Map()

  isFetching: ->
    @props.isFetchingMultiComparisons or @props.isFetchingQuestionGroups

  render: ->
    {
      questions
      questionGroups
      comparisonsEditData
      savingComparison
    } = @props

    selectedQuestions = comparisonsEditData.get('selectedQuestions', Immutable.Map())
    currentSelectedQuestionType = selectedQuestions.first()?.get('type')

    if currentSelectedQuestionType
      questions = questions.filter(filterQuestionsWithTheSameType(currentSelectedQuestionType))

    if @isFetching()
      <Spinner />
    else
      <div className="multi-comparisons__comparisons-edit">
        <MultiComparisonsTitle
          isEditing
          overarchingQuestion={comparisonsEditData.get 'overarchingQuestion'}
        />
        <ComparisonsSelection
          editingComparison="new"
          questions={questions}
          questionGroups={questionGroups}
          selectedQuestions={selectedQuestions}
          interventionsComparableDesc={comparisonsEditData.get 'interventionsComparableDesc'}
        />
        <div className="bottom-buttons">
          <button className="btn btn-cancel" onClick={MultiComparisonsActions.goToComparisonsList}>
            {@i18n 'actions.back'}
          </button>
          <span className="divider"/>
          <SpinnerButton
            className="btn btn-apply"
            onClick={MultiComparisonsActions.createComparison}
            label={@i18n 'actions.create'}
            loading={@props.savingComparison}
          />
        </div>
      </div>


module.exports = ConnectStore MultiComparisonsCreate,
  [MultiComparisonsStore, QuestionsStore, QuestionGroupsStore], storeConnectors
