var AddImplementationConsiderationsOrRemarskToGPSTemplatesDef, Migration, W, mediator, migrationName, updateTemplateDataAndDefinition,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'add_implementation_considerations_or_remarks_to_gps_templates_def';

updateTemplateDataAndDefinition = function(templateDef, templateData) {
  templateData.conclusions.sections = _.extend(templateData.conclusions.sections, {
    implementationConsiderationsOrRemarks: {
      description: "Implementation considerations or remarks",
      content: ""
    }
  });
  templateData.conclusions.sectionsOrder = templateData.conclusions.sectionsOrder.concat('implementationConsiderationsOrRemarks');
  templateDef.conclusions.sections = templateDef.conclusions.concat({
    id: 'implementationConsiderationsOrRemarks',
    checked: true
  });
  return {
    templateData: templateData,
    templateDef: templateDef
  };
};

module.exports = AddImplementationConsiderationsOrRemarskToGPSTemplatesDef = (function(_super) {
  __extends(AddImplementationConsiderationsOrRemarskToGPSTemplatesDef, _super);

  function AddImplementationConsiderationsOrRemarskToGPSTemplatesDef() {
    AddImplementationConsiderationsOrRemarskToGPSTemplatesDef.__super__.constructor.call(this, false);
  }

  AddImplementationConsiderationsOrRemarskToGPSTemplatesDef.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    AddImplementationConsiderationsOrRemarskToGPSTemplatesDef.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds'));
    }, []);
    docIds = docIds.concat(['etd-goodPracticeStatement-template']);
    return adapter.fetch(projectId, _.compact(_.uniq(docIds))).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return W.resolve();
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        var data, def, templateData, templateDef, _ref;
        if (doc.templateDef.id !== 'good-practice-statement') {
          return doc;
        }
        def = doc.templateDef;
        data = doc.templateData;
        _ref = updateTemplateDataAndDefinition(def, data), templateDef = _ref.templateDef, templateData = _ref.templateData;
        doc.templateDef = templateDef;
        doc.templateData = templateData;
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return AddImplementationConsiderationsOrRemarskToGPSTemplatesDef;

})(Migration);
