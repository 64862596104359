var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "      <option value=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (data && data.index), (depths[1] != null ? depths[1].currentPosition : depths[1]), {"name":"is","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n        "
    + escapeExpression(((helpers.add || (depth0 && depth0.add) || helperMissing).call(depth0, (data && data.index), 1, {"name":"add","hash":{},"data":data})))
    + "\n      </option>\n";
},"2":function(depth0,helpers,partials,data) {
  return "selected='selected'";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"sort-outcomes\">\n  <div>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.sort_text", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n  <select class=\"outcomes-sort-select\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.outcomes : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </select>\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}