var DbHelper, ajax, checkIfProjectCanBeMovedToOrganization, fetchOrganizationMembers, fetchOrganizations, urlPrefix;

DbHelper = require('base/lib/db_helper');

ajax = require('actions/async/api_helpers').ajax;

urlPrefix = "" + (DbHelper.getBackendUrl()) + "/organizations-dashboard";

fetchOrganizations = function() {
  var url;
  url = "" + urlPrefix + "/organizations";
  return ajax({
    url: url,
    xhrFields: {
      withCredentials: true
    }
  });
};

fetchOrganizationMembers = function(organizationId) {
  var url;
  url = "" + urlPrefix + "/" + organizationId + "/members";
  return ajax({
    url: url,
    xhrFields: {
      withCredentials: true
    }
  });
};

checkIfProjectCanBeMovedToOrganization = function(projectId, organizationId) {
  var url;
  url = "" + (DbHelper.getBackendUrl()) + "/organizations/" + organizationId + "/_validateMembers/" + projectId;
  return ajax({
    url: url,
    type: 'POST',
    data: JSON.stringify({}),
    xhrFields: {
      withCredentials: true
    }
  });
};

module.exports = {
  fetchOrganizations: fetchOrganizations,
  fetchOrganizationMembers: fetchOrganizationMembers,
  checkIfProjectCanBeMovedToOrganization: checkIfProjectCanBeMovedToOrganization
};
