ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
mediator = require 'mediator'
Modal = require 'components/common/modal'
ProgressBar = require 'components/common/progress_bar'
PublicationPlatformActions = require 'actions/publication_platform_actions'
PublicationPlatformStore = require 'stores/publication_platform_store'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

storeConnector =
  PublicationPlatformStore: (Store) ->
    isOpen: Store.isModalOpened()
    isPreview: Store.isPreview()
    isPublishing: Store.isPublishing()
    isRepublish: Store.isRepublish()
    mode: Store.getDialogMode()
    progressBarPercentage: Store.getProgressBarPercentage()
    questionId: Store.getPublishingQuestionId()

PublicationPlatformDialog = ({
  isOpen
  isPreview
  isPublishing
  isRepublish
  mode
  progressBarPercentage
  questionId
}) ->
  i18n = useI18n('dissemination:publication_platform.modal')

  onApply = useCoffeeCallback [isPreview, mode, questionId], ->
    switch mode
      when 'guideline'
        PublicationPlatformActions.publishGuideline isPreview
      when 'question'
        PublicationPlatformActions.publishQuestion questionId, isPreview
      else
        PublicationPlatformActions.closeModal()

  <Modal
    className="publication-platform-dialog"
    isOpen={isOpen}
    onClose={PublicationPlatformActions.closeModal}
  >
    <div className="mb-10">
      <p>{i18n "#{mode ? 'guideline'}#{if isPreview then '_preview' else ''}"}</p>
      {isRepublish and not isPreview and <p className="bold">{i18n 'overwrite_warning'}</p>}
      {isPreview and <p className="bold">{i18n 'overwrite_warning_preview'}</p>}
    </div>
    {isPublishing and mode is 'guideline' and <ProgressBar
      className="publication-platform-dialog__progress-bar"
      percentage={progressBarPercentage}
    />}
    <ApplyCancelButtons
      applying={isPublishing}
      isCancelEnabled={not isPublishing}
      onApply={onApply}
      applyLabel={i18n "/actions.#{if isPreview then 'preview' else 'publish'}"}
      onCancel={PublicationPlatformActions.closeModal}
    />
  </Modal>

PublicationPlatformDialog.propTypes =
  isOpen: PropTypes.bool.isRequired
  isPreview: PropTypes.bool.isRequired
  isPublishing: PropTypes.bool.isRequired
  isRepublish: PropTypes.bool.isRequired
  mode: PropTypes.oneOf ['guideline', 'question']
  progressBarPercentage: PropTypes.number.isRequired
  questionId: PropTypes.string

module.exports = ConnectStore PublicationPlatformDialog, PublicationPlatformStore, storeConnector
