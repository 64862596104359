ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
LimitCounter = require 'components/common/limit_counter'
Modal = require 'components/common/modal'
MultiSelect = require 'components/common/multi_select'
Translation = require 'components/mixins/translation'
{ license_url } = require 'lib/constants'


GeneratedQuestionsSelectModal = createReactClass
  displayName: 'GeneratedQuestionsSelectModal'

  propTypes:
    onClose: PropTypes.func.isRequired
    onSubmit: PropTypes.func.isRequired
    questions: PropTypes.instanceOf(Immutable.List).isRequired
    questionsLimit: PropTypes.number.isRequired
    comparisonsQuestionsCount: PropTypes.number.isRequired

  mixins: [Translation('')]

  getInitialState: ->
    selectedQuestionsIds: []

  getQuestionsSelectOptions: ->
    { questionsLimit, comparisonsQuestionsCount, questions } = @props
    { selectedQuestionsIds } = @state
    limitReached = not questionsLimit is -1 and
      selectedQuestionsIds.length >= questionsLimit - comparisonsQuestionsCount

    questions.reduce (acc, q) ->
      qId = q.get 'id'

      acc.concat
        value: qId
        label: q.get 'question'
        disabled: limitReached and not (qId in selectedQuestionsIds)
    , []

  handleApply: ->
    @props.onSubmit Immutable.List @state.selectedQuestionsIds

  handleQuestionSelect: (selected) ->
    @setState selectedQuestionsIds: selected

  render: ->
    { questionsLimit, comparisonsQuestionsCount } = @props
    remainingLimit = Math.max(0, questionsLimit - comparisonsQuestionsCount)
    questionsOptions = @getQuestionsSelectOptions()

    <Modal
      isOpen
      className='questions-generation-approved-questions-select-modal'
      modalSize='medium'
      onClose={@props.onClose}
      onRequestClose={@props.onClose}
      title={@i18n 'scope:questions.export_questions'}
    >
      <div className='questions-limit-info'>
        {questionsLimit isnt -1 and <span
          dangerouslySetInnerHTML={
            __html: @i18n 'scope:questions.questions_limit_warning', limit: questionsLimit
          }
        />}
        <LimitCounter limit={questionsLimit} count={comparisonsQuestionsCount} />
      </div>
      {questionsLimit isnt -1 and <div className='gdt-limits-content-box questions-limit-status'>
        <span dangerouslySetInnerHTML={
          __html: @i18n 'scope:questions.questions_limit_status',
            limit: questionsLimit
            remaining: remainingLimit
            link: license_url
        }/>
      </div>}
      <MultiSelect
        onChange={@handleQuestionSelect}
        options={questionsOptions}
        selectedOptions={@state.selectedQuestionsIds}
        selectAllDisabled={remainingLimit < questionsOptions.length and questionsLimit isnt -1}
      />
      <ApplyCancelButtons
        onCancel={@props.onClose}
        onApply={@handleApply}
        applyLabel={@i18n 'actions.export'}
      />
    </Modal>

module.exports = GeneratedQuestionsSelectModal
