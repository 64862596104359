OutcomesTableViewActions = require 'actions/outcomes_table_view_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
ValidatedInput = require 'components/common/validated_input'
EditableCell = require 'components/evidence_syntheses/outcomes/cells/editable_cell'
{ CompoundEdit } = require 'components/evidence_syntheses/outcomes/cells/compound_edit'


NoOfStudiesCell = createReactClass
  displayName: 'NoOfStudiesCell'

  propTypes:
    outcomeAttrs: PropTypes.object
    editMetadata: PropTypes.instanceOf(Immutable.Map)
    allValid: PropTypes.func.isRequired # provided by withValidation enhancer
    validate: PropTypes.func.isRequired # provided by withValidation enhancer

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]

  _ref: (el) ->
    @el = el

  handleApply: ->
    OutcomesTableViewActions.saveEditedAttributes()
    @resetEdit()

  resetEdit: ->
    @el.stopEdit()

  handleInputChange: (evt, validationError) ->
    value = evt.target.value.trim()

    OutcomesTableViewActions.updateEditedOutcomeAttributes noOfStudies: value

  render: ->
    { allValid, validate, editMetadata, outcomeAttrs } = @props
    noOfStudies = editMetadata?.getIn(['editedAttributes', 'noOfStudies']) ?
      outcomeAttrs['noOfStudies']

    <EditableCell {...@props} ref={@_ref} title={@i18n 'no_of_studies'}>
      <CompoundEdit applyEnabled={allValid()} onApply={@handleApply} onCancel={@resetEdit}>
        <ValidatedInput
          name='noOfStudies'
          onChange={@handleInputChange}
          type='text'
          validation={validate 'noOfStudies'}
          value={noOfStudies}
        />
      </CompoundEdit>
    </EditableCell>

module.exports = NoOfStudiesCell
