var statisticalUtils;

statisticalUtils = {
  calculateAbsoluteEffectSize: function(outcomeAttributes, cer) {
    var absEffect, eventType, _ref;
    eventType = (_ref = outcomeAttributes.eventType) != null ? _ref : 'event';
    if (cer === void 0) {
      cer = this.getControlEventRate(outcomeAttributes);
    }
    absEffect = (function() {
      switch (outcomeAttributes.effectMeasure) {
        case 'RR':
          return this._absoluteEffectForRiskRatio(outcomeAttributes.absDenominator, cer, outcomeAttributes.effectSize);
        case 'OR':
          return this._absoluteEffectForRiskRatio(outcomeAttributes.absDenominator, cer, this.riskRatioFromOddsRatio(cer, outcomeAttributes.effectSize));
        case 'HR':
          return this._absoluteEffectForRiskRatio(outcomeAttributes.absDenominator, cer, this.riskRatioFromHazardRatio(cer, outcomeAttributes.effectSize, eventType));
        default:
          return null;
      }
    }).call(this);
    return this._toFixed(absEffect, 0);
  },
  _absoluteEffectForRiskRatio: function(absDenominator, cer, effectSize) {
    var absEffect;
    if (!effectSize) {
      return null;
    }
    if (isNaN(cer)) {
      return effectSize;
    }
    absEffect = cer * (1 - parseFloat(effectSize));
    if (absEffect > 1) {
      absEffect = 1;
    } else if (absEffect < -1) {
      absEffect = -1;
    }
    return absDenominator * absEffect;
  },
  riskRatioFromOddsRatio: function(cer, effectSize) {
    if (isNaN(cer)) {
      return effectSize;
    }
    return effectSize / (1 - cer * (1 - effectSize));
  },
  riskRatioFromHazardRatio: function(cer, effectSize, eventType) {
    if (isNaN(cer)) {
      return effectSize;
    }
    if (eventType === 'non_event') {
      return Math.pow(cer, effectSize - 1);
    } else {
      return (1 - Math.exp(effectSize * Math.log(1 - cer))) / cer;
    }
  },
  oddsRatioFromRiskRatio: function(cer, effectSize) {
    if (isNaN(cer)) {
      return effectSize;
    }
    return (effectSize * (1 - cer)) / (1 - effectSize * cer);
  },
  hazardRatioFromRiskRatio: function(cer, effectSize) {
    if (isNaN(cer)) {
      return effectSize;
    }
    return Math.log(1 - effectSize * cer) / Math.log(1 - cer);
  },
  riskRatioFromRiskDifference: function(cer, effectSize) {
    if (isNaN(cer)) {
      return effectSize;
    }
    return (cer + effectSize) / cer;
  },
  _toFixed: function(number, scale) {
    if (scale == null) {
      scale = 2;
    }
    if ((number == null) || isNaN(number) || !isFinite(number)) {
      return null;
    } else {
      return parseFloat(parseFloat(number).toFixed(scale));
    }
  },
  getControlEventRate: function(attributesOrControlCount, controlTotal) {
    if (arguments.length === 2) {
      return attributesOrControlCount / controlTotal;
    } else {
      return attributesOrControlCount.controlCount / attributesOrControlCount.controlTotal;
    }
  },
  calculateAbsoluteEffectInterval: function(outcomeAttributes, cer) {
    var calculateInterval, end1, end2, eventType, mappingRatioFunction, _ref;
    eventType = (_ref = outcomeAttributes.eventType) != null ? _ref : 'event';
    if (cer === void 0) {
      cer = this.getControlEventRate(outcomeAttributes);
    }
    mappingRatioFunction = (function() {
      switch (outcomeAttributes.effectMeasure) {
        case 'RR':
          return function(cer, value) {
            return value;
          };
        case 'OR':
          return this.riskRatioFromOddsRatio;
        case 'HR':
          return this.riskRatioFromHazardRatio;
        default:
          return null;
      }
    }).call(this);
    if (mappingRatioFunction) {
      calculateInterval = (function(_this) {
        return function(fromTo) {
          var ciVal;
          ciVal = outcomeAttributes["confidenceInterval" + fromTo];
          if (ciVal != null) {
            return _this._absoluteEffectForRiskRatio(outcomeAttributes.absDenominator, cer, mappingRatioFunction(cer, ciVal, eventType));
          } else {
            return null;
          }
        };
      })(this);
      end1 = calculateInterval("From");
      end2 = calculateInterval("To");
      return [this._toFixed(end1, 0), this._toFixed(end2, 0)].sort(function(a, b) {
        return Math.abs(a) - Math.abs(b);
      });
    } else {
      return [null, null];
    }
  },
  getRREffectSize: function(cer, effectSize, effectMeasure, eventType) {
    switch (effectMeasure) {
      case 'OR':
        return this.riskRatioFromOddsRatio(cer, effectSize);
      case 'HR':
        return this.riskRatioFromHazardRatio(cer, effectSize, eventType);
      default:
        return effectSize;
    }
  },
  calculateRiskDifference: function(cer, effectSize, effectMeasure, eventType) {
    if (eventType == null) {
      eventType = 'event';
    }
    return (1 - this.getRREffectSize(cer, effectSize, effectMeasure, eventType)) * cer;
  },
  calculateCorrespondingRisk: function(cer, effectSize, effectMeasure, eventType) {
    var correspondingRisk;
    if (eventType == null) {
      eventType = 'event';
    }
    correspondingRisk = this.getRREffectSize(cer, effectSize, effectMeasure, eventType) * cer;
    if (effectMeasure === 'RR' || effectMeasure === 'OR' || effectMeasure === 'HR') {
      if (correspondingRisk > 1) {
        correspondingRisk = 1;
      } else if (correspondingRisk < -1) {
        correspondingRisk = -1;
      }
    }
    return correspondingRisk;
  },
  diagnosticEffectTP: function(sensitivity, prevalence, denominator) {
    if (denominator == null) {
      denominator = 1000;
    }
    return this._toFixed(sensitivity * (prevalence / 100) * denominator, 0);
  },
  diagnosticEffectFN: function(sensitivity, prevalence, denominator) {
    var tp;
    if (denominator == null) {
      denominator = 1000;
    }
    tp = this.diagnosticEffectTP(sensitivity, prevalence, denominator);
    return this._toFixed((prevalence / 100) * denominator - tp, 0);
  },
  diagnosticEffectTN: function(specificity, prevalence, denominator) {
    if (denominator == null) {
      denominator = 1000;
    }
    return this._toFixed(specificity * (1 - (prevalence / 100)) * denominator, 0);
  },
  diagnosticEffectFP: function(specificity, prevalence, denominator) {
    var tn;
    if (denominator == null) {
      denominator = 1000;
    }
    tn = this.diagnosticEffectTN(specificity, prevalence, denominator);
    return this._toFixed((1 - (prevalence / 100)) * denominator - tn, 0);
  },
  diagnosticSpecificityOfPositiveResult: function(sensitivity, specificity, prevalence, absDenominator) {
    var fp, tp;
    tp = this.diagnosticEffectTP(sensitivity, prevalence, absDenominator);
    fp = this.diagnosticEffectFP(specificity, prevalence, absDenominator);
    return this._toFixed(100 * tp / (tp + fp), 0);
  },
  diagnosticSpecificityOfNegativeResult: function(sensitivity, specificity, prevalence, absDenominator) {
    var fn, tn;
    tn = this.diagnosticEffectTN(specificity, prevalence, absDenominator);
    fn = this.diagnosticEffectFN(sensitivity, prevalence, absDenominator);
    return this._toFixed(100 * fn / (tn + fn), 0);
  },
  effectsCommonDenominator: function(outcomes) {
    var denominators, gcd, outcome;
    gcd = function(a, b) {
      if (b) {
        return gcd(b, a % b);
      } else {
        return Math.abs(a);
      }
    };
    denominators = (function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = outcomes.length; _i < _len; _i++) {
        outcome = outcomes[_i];
        _results.push(outcome.get('absDenominator'));
      }
      return _results;
    })();
    return _(denominators).reduce((function(acc, val) {
      return (acc * val) / gcd(acc, val);
    }), 1);
  },
  confidenceLevel: 0.95
};

module.exports = statisticalUtils;
