var OutcomesTableViewActions, OutcomesTableViewStore, alt, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

OutcomesTableViewActions = require('actions/outcomes_table_view_actions');

OutcomesTableViewStore = (function() {
  function OutcomesTableViewStore() {
    this.state = Immutable.fromJS({
      viewType: 'one_row',
      outcomeEditMetadata: {}
    });
    this.bindActions(OutcomesTableViewActions);
    this.exportPublicMethods({
      getViewType: this.getViewType,
      getCurrentlyEditedOutcomeId: this.getCurrentlyEditedOutcomeId,
      getCurrentlyEditedCell: this.getCurrentlyEditedCell,
      getOutcomeEditMetadata: this.getOutcomeEditMetadata,
      getEditedOutcomeAttribites: this.getEditedOutcomeAttribites,
      getCurrentlyEditedOutcomeSourceId: this.getCurrentlyEditedOutcomeSourceId,
      getCurrentlyEditedOutcomeQuestionId: this.getCurrentlyEditedOutcomeQuestionId
    });
  }

  OutcomesTableViewStore.prototype.onChangeOutcomesView = function(viewType) {
    if (viewType !== this.getViewType()) {
      return this.setState(this.state.set('viewType', viewType));
    }
  };

  OutcomesTableViewStore.prototype.onStartOutcomeEdit = function(_arg) {
    var cellId, outcomeId, questionId, sourceId;
    outcomeId = _arg.outcomeId, cellId = _arg.cellId, sourceId = _arg.sourceId, questionId = _arg.questionId;
    return this.setState(this.state.set('outcomeEditMetadata', Immutable.Map({
      outcomeId: outcomeId,
      cellId: cellId,
      sourceId: sourceId,
      questionId: questionId
    })));
  };

  OutcomesTableViewStore.prototype.onStopOutcomeEdit = function() {
    return this.setState(this.state.set('outcomeEditMetadata', Immutable.Map()));
  };

  OutcomesTableViewStore.prototype.onUpdateEditedOutcomeAttributes = function(updatedAttrs) {
    return this.setState(this.state.updateIn(['outcomeEditMetadata', 'editedAttributes'], Immutable.Map(), function(editedAttributes) {
      return editedAttributes.merge(updatedAttrs);
    }));
  };

  OutcomesTableViewStore.prototype.getViewType = function() {
    return this.state.get('viewType');
  };

  OutcomesTableViewStore.prototype.getCurrentlyEditedOutcomeId = function() {
    return this.state.getIn(['outcomeEditMetadata', 'outcomeId']);
  };

  OutcomesTableViewStore.prototype.getCurrentlyEditedCell = function() {
    return this.state.getIn(['outcomeEditMetadata', 'cellId']);
  };

  OutcomesTableViewStore.prototype.getOutcomeEditMetadata = function() {
    return this.state.get('outcomeEditMetadata');
  };

  OutcomesTableViewStore.prototype.getEditedOutcomeAttribites = function() {
    return this.state.getIn(['outcomeEditMetadata', 'editedAttributes'], Immutable.Map());
  };

  OutcomesTableViewStore.prototype.getCurrentlyEditedOutcomeSourceId = function() {
    return this.state.getIn(['outcomeEditMetadata', 'sourceId']);
  };

  OutcomesTableViewStore.prototype.getCurrentlyEditedOutcomeQuestionId = function() {
    return this.state.getIn(['outcomeEditMetadata', 'questionId']);
  };

  return OutcomesTableViewStore;

})();

module.exports = alt.createStore(immutable(OutcomesTableViewStore), 'OutcomesTableViewStore');
