var AdaptiveLayout, ISoFDx, ISoFDxConverter, ISoFTx, ISoFTxConverter, ISoFView, Outcome, OutcomeViewType, View, mediator, stats, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

Outcome = require('models/outcome');

OutcomeViewType = require('models/outcome/view_types');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

stats = require('lib/statistical_utils');

template = require('views/templates/evidence_syntheses/isof');

mediator = require('mediator');

ISoFDx = require('views/evidence_syntheses/isof-dx');

ISoFTx = require('views/evidence_syntheses/isof-tx');

ISoFTxConverter = require('models/evidence_syntheses/isof-tx-converter');

ISoFDxConverter = require('models/evidence_syntheses/isof-dx-converter');

module.exports = ISoFView = (function(_super) {
  __extends(ISoFView, _super);

  function ISoFView() {
    this.updatePlainLanguageSummary = __bind(this.updatePlainLanguageSummary, this);
    this._testSwitched = __bind(this._testSwitched, this);
    return ISoFView.__super__.constructor.apply(this, arguments);
  }

  ISoFView.prototype.className = 'isof-view';

  ISoFView.prototype.template = template;

  ISoFView.prototype.initialize = function() {
    ISoFView.__super__.initialize.apply(this, arguments);
    this.enable(AdaptiveLayout);
    this.indexTestData = this._fetchData();
    if (Boolean(this.options.questionModel.get('comparatorTestPresent'))) {
      this.testChoiceDropdown = true;
      this.comparatorTestData = this._fetchData(true);
      this.delegate('change', '.test-dropdown', this._testSwitched);
      this.data = {
        ok: this.indexTestData.ok && this.comparatorTestData.ok
      };
      this.data.message = this.indexTestData.message || this.comparatorTestData.message;
    } else {
      this.testSelected = 'index';
      this.data = this.indexTestData;
    }
    this.delegate('click', 'button.table-mode', this.options.showTableViewsMenu);
    return this.delegate('click', 'button.go-to-source', (function(_this) {
      return function() {
        var editView;
        editView = (_this.options.previousViewType != null) && !_this.options.previousViewType.isof ? _this.options.previousViewType.name : 'LayerOne';
        return _this.parentView.subview('toolbar').changeOutcomeView(editView);
      };
    })(this));
  };

  ISoFView.prototype._testSwitched = function(e) {
    this.testSelected = $(e.target).val();
    this.data = (function() {
      switch (this.testSelected) {
        case 'index':
          return this.indexTestData;
        case 'comparator':
          return this.comparatorTestData;
        default:
          this.testSelected = null;
          return {
            ok: true
          };
      }
    }).call(this);
    return this.render();
  };

  ISoFView.prototype._fetchData = function(comparison) {
    var projectName;
    if (comparison == null) {
      comparison = false;
    }
    if (this.options.questionModel.isDiagnostic()) {
      projectName = mediator.project.get('name');
      return ISoFDxConverter.convertDiagnostic(this.options.questionModel, comparison, projectName);
    } else {
      return ISoFTxConverter.convertTreatment(this.options.questionModel, comparison);
    }
  };

  ISoFView.prototype.updatePlainLanguageSummary = function(outcomeId, text) {
    this.options.questionModel.get('outcomes').get(outcomeId).set('plain_language_summary', text);
    return this.options.questionModel.save();
  };

  ISoFView.prototype.afterRender = function() {
    ISoFView.__super__.afterRender.apply(this, arguments);
    this.trigger('!fixLayout');
    if (this.options.questionModel.isDiagnostic()) {
      if (this.data.ok) {
        new ISoFDx('#isof-dx-content', this.data);
      }
    } else {
      if (this.data.ok) {
        new ISoFTx('#isof-tx-content', this.data, this.updatePlainLanguageSummary);
      }
    }
    return this.publishEvent('deactivatedProperty');
  };

  ISoFView.prototype.fixLayout = function() {
    if (!this.data.ok) {
      return;
    }
    return this.$('#isof-content').height($('#page-container').height() - 10);
  };

  ISoFView.prototype.getTemplateData = function() {
    return _.extend({
      testChoiceDropdown: this.testChoiceDropdown,
      testSelected: this.testSelected,
      indexTest: this.options.questionModel.get('indexTest'),
      comparatorTest: this.options.questionModel.get('comparatorTest'),
      isDiagnostic: this.options.questionModel.isDiagnostic()
    }, !this.data.ok ? {
      message: this.data.message
    } : void 0);
  };

  return ISoFView;

})(View);
