var DbHelper, MASTER_PROJECT_ID, MdaTableDoc, MdaTopicDoc, W, alt, appUtils, fetchDoc, generateActions, getChapterAndSectionIds, getDocIds, getDocsIdsForTopics, mediator, sequence, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

appUtils = require('lib/app_utils');

DbHelper = require('base/lib/db_helper');

generateActions = require('actions/utils/generate_actions');

_ref = require('lib/mda_helper'), getChapterAndSectionIds = _ref.getChapterAndSectionIds, getDocIds = _ref.getDocIds, MASTER_PROJECT_ID = _ref.MASTER_PROJECT_ID;

MdaTableDoc = require('lib/db_docs/mda_table_doc');

MdaTopicDoc = require('lib/db_docs/mda_topic_doc');

mediator = require('mediator');

sequence = require('when/sequence');

W = require('when/when');

fetchDoc = function(projectId) {
  return function(Doc) {
    return Doc.at(projectId).fetchMany().then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).pluck('doc').compact().map(function(doc) {
        return [doc._id, doc];
      }).object().value();
    });
  };
};

getDocsIdsForTopics = function(allTopics, selectedTopics, projectId) {
  var rootDocsIds, tablesIds, topicsIds, topicsToUse, _ref1;
  topicsToUse = allTopics.filter(function(topic) {
    return selectedTopics.includes(topic.get('_id'));
  }).valueSeq().toJS();
  _ref1 = getDocIds(topicsToUse), rootDocsIds = _ref1.rootDocsIds, tablesIds = _ref1.tablesIds, topicsIds = _ref1.topicsIds;
  return mediator.services.storePersistenceAdapter.fetch(projectId, rootDocsIds).then(function(_arg) {
    var rootDocs, rows;
    rows = _arg.rows;
    rootDocs = _.chain(rows).pluck('doc').compact().value();
    return sequence(_.map(rootDocs, function(rootDoc) {
      return function() {
        return getChapterAndSectionIds(projectId, rootDoc);
      };
    }));
  }).then(function(results) {
    var chapterIds, documentIds, sectionIds;
    chapterIds = appUtils.flatPluckCompact(results, 'chapterIds');
    sectionIds = appUtils.flatPluckCompact(results, 'sectionIds');
    documentIds = _.uniq(_.union([], rootDocsIds, tablesIds, topicsIds, chapterIds, sectionIds));
    return {
      documentIds: documentIds,
      tablesIds: tablesIds,
      topicsIds: topicsIds
    };
  });
};

module.exports = generateActions({
  autoGenerate: ['closeDialog', 'fetchError', 'fetchTopicsSuccess', 'toggleTopic', 'updateName', 'updateSearchString', 'updateSort'],
  name: 'MDAWorkflowDialogActions',
  actions: {
    createWorkflow: function(_arg) {
      var allTopics, name, organizationId, selectedTopics;
      allTopics = _arg.allTopics, name = _arg.name, organizationId = _arg.organizationId, selectedTopics = _arg.selectedTopics;
      this.dispatch();
      return getDocsIdsForTopics(allTopics, selectedTopics, MASTER_PROJECT_ID).then(function(_arg1) {
        var documentIds;
        documentIds = _arg1.documentIds;
        return mediator.user.createMdaWorkflow(organizationId, name, documentIds);
      }).then((function(_this) {
        return function(newProjectId) {
          return W.promise(function(resolve, reject) {
            _this.actions.closeDialog();
            return mediator.publish('!router:route', "/projects/" + newProjectId, function(routed) {
              if (routed) {
                return resolve();
              } else {
                return reject(new Error('Switching to newly created project was unsuccessful'));
              }
            });
          })["catch"](function(err) {
            _this.actions.closeDialog();
            return appUtils.errorHandlerWithMsg(err, $.t('mda:workflow.create_error'));
          });
        };
      })(this));
    },
    fetch: function(_arg) {
      var masterAndProjectCopiesInOrganization, mode, organizationId, projectCopiesIds;
      mode = _arg.mode, organizationId = _arg.organizationId;
      if (mode === 'import') {
        projectCopiesIds = _.chain(mediator.user.get('projects')).filter(function(_arg1) {
          var isCopy;
          isCopy = _arg1.isCopy;
          return isCopy;
        }).pluck('name').value();
        masterAndProjectCopiesInOrganization = _.chain(mediator.projects.toJSON()).filter(function(_arg1) {
          var projectOrganizationId, _id;
          _id = _arg1._id, projectOrganizationId = _arg1.organizationId;
          if (_id !== MASTER_PROJECT_ID && __indexOf.call(projectCopiesIds, _id) < 0) {
            return false;
          }
          if (_.isEmpty(organizationId) && _.isEmpty(projectOrganizationId)) {
            return true;
          }
          return organizationId === projectOrganizationId;
        }).map(function(_arg1) {
          var name, _id;
          _id = _arg1._id, name = _arg1.name;
          return {
            id: _id,
            name: name
          };
        }).value();
        this.dispatch(masterAndProjectCopiesInOrganization);
      } else {
        this.dispatch([]);
      }
      return this.actions.fetchTopics(mode === 'split' ? mediator.project.id : MASTER_PROJECT_ID);
    },
    fetchTopics: function(projectId) {
      var centralProjectReplica, localProjectReplica;
      this.dispatch(projectId);
      centralProjectReplica = DbHelper.centralReplica(projectId);
      localProjectReplica = DbHelper.localReplica(projectId);
      return centralProjectReplica.replicate.to(localProjectReplica, {
        checkpoint: false
      }).then(function() {
        return W.all(_.map([MdaTableDoc, MdaTopicDoc], fetchDoc(projectId)));
      }).then((function(_this) {
        return function(_arg) {
          var tables, topics;
          tables = _arg[0], topics = _arg[1];
          _this.actions.fetchTopicsSuccess({
            tables: tables,
            topics: topics
          });
          return {
            tables: tables,
            topics: topics
          };
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          _this.actions.fetchError();
          return appUtils.errorHandlerWithMsg(err, $.t('mda:workflow.fetch_error'));
        };
      })(this));
    },
    openDialog: function(_arg) {
      var mode, organizationId;
      mode = _arg.mode, organizationId = _arg.organizationId;
      this.dispatch({
        mode: mode,
        organizationId: organizationId != null ? organizationId : null
      });
      return this.actions.fetch({
        mode: mode,
        organizationId: organizationId
      });
    },
    splitWorkflow: function(_arg) {
      var allTopics, organizationId, selectedTopics;
      allTopics = _arg.allTopics, organizationId = _arg.organizationId, selectedTopics = _arg.selectedTopics;
      this.dispatch();
      return getDocsIdsForTopics(allTopics, selectedTopics, mediator.project.id).then(function(_arg1) {
        var documentIds, tablesIds, topicsIds;
        documentIds = _arg1.documentIds, tablesIds = _arg1.tablesIds, topicsIds = _arg1.topicsIds;
        return mediator.user.createMdaWorkflowSnapshot(organizationId, mediator.project.id, documentIds).then(function(newProjectName) {
          var deletingDocsPromise, documentsToDelete, tablesNotToDelete;
          tablesNotToDelete = _.reject(tablesIds, function(tableId) {
            return allTopics.filter(function(topic) {
              var _ref1;
              if (_ref1 = topic.get('_id'), __indexOf.call(topicsIds, _ref1) >= 0) {
                return false;
              }
              return (topic.get('insertedTables') || Immutable.List()).includes(tableId);
            }).isEmpty();
          });
          documentsToDelete = _.difference(documentIds, tablesNotToDelete);
          deletingDocsPromise = _.isEmpty(documentsToDelete) ? W.resolve() : mediator.services.storePersistenceAdapter.fetch(mediator.project.id, documentsToDelete).then(function(_arg2) {
            var docsToWrite, rows;
            rows = _arg2.rows;
            docsToWrite = _.chain(rows).pluck('doc').compact().map(function(_arg3) {
              var docType, _id, _rev;
              _id = _arg3._id, _rev = _arg3._rev, docType = _arg3.docType;
              return {
                _id: _id,
                _rev: _rev,
                docType: docType,
                _deleted: true
              };
            }).value();
            return mediator.services.storePersistenceAdapter.bulkDocs(mediator.project.id, docsToWrite);
          });
          return deletingDocsPromise.then(function() {
            return mediator.dialogs.success($.t('mda:topics.split.created_snapshot', {
              newProjectName: newProjectName
            }));
          });
        });
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('mda:topics.split.create_snapshot_error')))["finally"]((function(_this) {
        return function() {
          return _this.actions.closeDialog();
        };
      })(this));
    }
  }
});
