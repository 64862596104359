var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div>\n  <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/references:new_title", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n  <div class=\"edit-container\">\n    <table class=\"reference-edit-table\">\n      <tbody>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "authors", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"authors\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"title\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "year", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"year\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "publishedIn", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"publishedIn\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "volume", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"volume\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "issue", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"issue\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "pages", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"pages\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "abstract", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"abstract\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "tags", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"tags\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "authorKeywords", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"authorKeywords\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "DOI", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"DOI\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "PMID", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"PMID\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "source", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          </td>\n          <td class=\"field-edit\">\n            <textarea name=\"source\" rows=\"1\"></textarea>\n          </td>\n        </tr>\n        <tr>\n          <td class=\"field-name\">\n            <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "researchResults", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n            <span class=\"icon info-icon\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/references:research_results_tooltip", {"name":"i18n","hash":{},"data":data})))
    + "\">\n            </span>\n          </td>\n          <td class=\"field-edit\">\n            <input type=\"checkbox\" name=\"researchResults\">\n          </td>\n        </tr>\n      </tbody>\n    </table>\n  </div>\n  <div class=\"row buttons\">\n    <div class=\"col-6\">\n      <button class=\"btn btn-cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/translation:actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n    <div class=\"col-6\">\n      <button class=\"btn btn-save\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/translation:actions.save", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    </div>\n  </div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "references:columns", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}