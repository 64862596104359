ConnectStore = require 'components/enhancers/connect_store'
{ gdtDataToNmaData, nmaDataToVizData } = require 'lib/nma_helper'
mediator = require 'mediator'
Modal = require 'components/common/modal'
NMAVizActions = require 'actions/nma_viz_actions'
NMAVizStore = require 'stores/nma_viz_store'
{ pluck } = require 'lib/immutable_utils'
Router = require 'router'
Spinner = require 'components/common/spinner'
{
  CommonComparatorTableWithI18n
  ForestPlotWithI18n
  NetworkGraphWithI18n
  SidebarButton
  SidebarWithI18n
  TableSettingsContext
  STANDARD_DENOMINATOR
} = NmaComponents
{ useCoffeeCallback, useCoffeeEffect, useCoffeeMemo } = require 'lib/react_utils'
{ useState } = React

storeConnector =
  NMAVizStore: (Store) ->
    forestPlotOutcomeId: Store.getForestPlotOutcomeId()
    isFetching: Store.isFetching()
    isNetworkGraphOpen: Store.isNetworkGraphOpen()
    outcomes: Store.getOutcomes()
    question: Store.getQuestion()

NMAViz = ({
  forestPlotOutcomeId
  isFetching
  isNetworkGraphOpen
  outcomes: outcomesFromStore
  question
}) ->
  [comparatorId, setComparatorId] = useState question.getIn(['interventions', 0, '_id'])
  [denominator, setDenominator] = useState STANDARD_DENOMINATOR
  [expandedRows, setExpandedRows] = useState []
  [selectedInterventionIds, setSelectedInterventionIds] = useState []
  [selectedOutcomeIds, setSelectedOutcomeIds] = useState []
  [transposed, setTransposed] = useState false
  [isSidebarOpen, setIsSidebarOpen] = useState false

  interventionsFromQuestion = useCoffeeMemo [question], ->
    question.get 'interventions', Immutable.List()

  useCoffeeEffect [comparatorId, interventionsFromQuestion], ->
    interventionIds = pluck interventionsFromQuestion, '_id'
    newComparatorId = comparatorId or interventionsFromQuestion.getIn [0, '_id']
    setSelectedInterventionIds _.without interventionIds.toJS(), newComparatorId
    setComparatorId newComparatorId
    return # no need for clean up

  outcomesFiltered = useCoffeeMemo [outcomesFromStore, question], ->
    outcomesFromStore.filter (outcome) -> outcome.get('questionId') is question.get('_id')

  useCoffeeEffect [outcomesFiltered], ->
    return if selectedOutcomeIds.length
    outcomeIds = pluck outcomesFiltered, '_id'
    setSelectedOutcomeIds outcomeIds.toJS()
    return # no need for clean up

  onExpand = useCoffeeCallback [expandedRows], (rowId) ->
    setExpandedRows (prev) ->
      if rowId in prev
        prev.filter (id) -> id isnt rowId
      else
        prev.concat rowId

  { interventions, comparisons, outcomes } = useCoffeeMemo [outcomesFiltered, question], ->
    gdtDataToNmaData question, outcomesFiltered

  tableSettingsContextValue = useCoffeeMemo [denominator, outcomes], ->
    { denominator, outcomes }

  openSidebar = useCoffeeCallback [], ->
    setIsSidebarOpen true

  closeSidebar = useCoffeeCallback [], ->
    setIsSidebarOpen false

  toggleTransposed = useCoffeeCallback [], ->
    setTransposed (prev) -> not prev

  openLeagueTable = useCoffeeCallback [question], (outcomeId) ->
    route = Router::getProjectRelativeUrl "/nma/#{question.get('_id')}/outcomes/#{outcomeId}"
    mediator.publish '!router:route', route

  comparatorChanged = useCoffeeCallback [comparatorId], (newComparatorId) ->
    oldComparatorId = comparatorId
    setComparatorId newComparatorId
    setSelectedInterventionIds (current) ->
      _.chain current
        .reject (interventionId) -> interventionId is newComparatorId
        .concat oldComparatorId
        .value()

  data = useCoffeeMemo [comparatorId, comparisons, interventions, selectedInterventionIds], ->
    selectedInterventions = interventions.filter (intervention) ->
      intervention.id in selectedInterventionIds
    nmaDataToVizData selectedInterventions, comparisons, comparatorId

  networkGraphData = useCoffeeMemo [outcomesFromStore, question], ->
    gdtDataToNmaData question, outcomesFromStore

  return <Spinner /> if isFetching

  <div className="nma-viz">
    <TableSettingsContext.Provider value={tableSettingsContextValue}>
      <CommonComparatorTableWithI18n
        data={data}
        expandedRows={expandedRows}
        expandDetails={onExpand}
        forestPlotSelected={NMAVizActions.openForestPlot}
        i18nInstance={i18next}
        leagueTableSelected={openLeagueTable}
        outcomes={outcomes}
        showCoeInsufficientLabel={mediator.services.switches.isServerSwitchOn('acpFeatures')}
        transposed={transposed}
      />
      <SidebarWithI18n
        comparatorId={comparatorId}
        denominator={denominator}
        i18nInstance={i18next}
        interventions={interventions}
        isVisible={isSidebarOpen}
        onComparatorChange={comparatorChanged}
        onDenominatorChange={setDenominator}
        onHide={closeSidebar}
        onSelectedInterventionsChange={setSelectedInterventionIds}
        onSelectedOutcomesChange={setSelectedOutcomeIds}
        onToggleTransposed={toggleTransposed}
        outcomes={outcomes}
        selectedInterventions={selectedInterventionIds}
        selectedOutcomes={selectedOutcomeIds}
      />
      {forestPlotOutcomeId? and <Modal
        className="nma-viz-plot"
        closeButton
        isOpen
        onClose={NMAVizActions.closeForestPlot}
      >
        <ForestPlotWithI18n
          comparisonName={interventions.find(({ id }) -> id is comparatorId).name}
          data={data}
          i18nInstance={i18next}
          outcome={outcomes.find ({ id }) -> id is forestPlotOutcomeId}
        />
      </Modal>}
      {isNetworkGraphOpen and <Modal
        className="nma-viz-plot"
        closeButton
        isOpen
        onClose={NMAVizActions.closeNetworkGraph}
      >
        <NetworkGraphWithI18n data={networkGraphData} i18nInstance={i18next} />
      </Modal>}
    </TableSettingsContext.Provider>
    <span className="nma-viz__sidebar-button">
      <SidebarButton onClick={openSidebar} />
    </span>
  </div>

NMAViz.propTypes =
  isFetching: PropTypes.bool.isRequired
  outcomes: PropTypes.instanceOf(Immutable.List).isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore NMAViz, NMAVizStore, storeConnector
