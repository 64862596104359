module.exports = function() {
  return {
    chapters: {
      sectionsOrder: [],
      sections: {
        abstract: {
          name: 'Ravijuhendi Koostajad',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        tableOfContents: {
          name: 'Sisukord',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        abbreviationsAndDefinitions: {
          name: 'Lühendid ja mõisted',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        introduction: {
          name: 'Sissejuhatus',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        methods: {
          name: 'Ravijuhendi koostamine',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        recommendations: {
          name: 'Ravijuhendi soovituste loetelu',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        recommendationsWithShortSummary: {
          name: 'Ravijuhendi soovitused koos tõenduse lühikokkuvõttega',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        annexes: {
          name: 'Lisad',
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        },
        references: {
          name: 'Kasutatud kirjandus',
          type: "references",
          subsections: [
            {
              title: '',
              titleHidden: true,
              content: ''
            }
          ]
        }
      }
    }
  };
};
