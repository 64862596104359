var MDGRemoveLackOfBiasFromStudy, MdgQuestionDoc, Migration, W, calculateScore, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

MdgQuestionDoc = require('lib/db_docs/mdg_question_doc');

W = require('when');

calculateScore = require('lib/mdg_helper').calculateScore;

migrationName = 'mdg_remove_lack_of_bias_from_study';

module.exports = MDGRemoveLackOfBiasFromStudy = (function(_super) {
  __extends(MDGRemoveLackOfBiasFromStudy, _super);

  function MDGRemoveLackOfBiasFromStudy() {
    MDGRemoveLackOfBiasFromStudy.__super__.constructor.call(this, false);
  }

  MDGRemoveLackOfBiasFromStudy.prototype.up = function(project, colls) {
    var adapter, projectId;
    MDGRemoveLackOfBiasFromStudy.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return MdgQuestionDoc.at(projectId).fetchMany().then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').map(function(question) {
        var studies;
        studies = _.map(question.studies, function(study) {
          return R.compose(R.assocPath(['score', 'value'], R.path(['score', 'excludedFromScoring'], study) ? R.path(['score', 'value'], study) : calculateScore(R.prop('score', study))), R.assocPath(['score', 'criteria'], R.omit(['lackOfBias'], study.score.criteria)))(study);
        });
        return R.assoc('studies', studies, question);
      }).value();
    }).then(function(docs) {
      var updatedDocs;
      if (_.isEmpty(docs)) {
        return W.resolve();
      }
      updatedDocs = _.map(docs, function(doc) {
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, _.compact(updatedDocs));
    })["catch"](function(err) {
      return console.log(err);
    });
  };

  return MDGRemoveLackOfBiasFromStudy;

})(Migration);
