DynamicHeightContainer = require 'components/common/dynamic_height_container'
IconButton = require 'components/common/icon_button'
{ bool, arrayOf, object, oneOf, func, shape, string } = PropTypes
{ childrenCount } = require 'lib/react_children_validation_utils'

CollapsibleContent = createReactClass
  displayName: 'CollapsibleContent'

  propTypes:
    children: childrenCount(2)
    forcedExpand: bool
    onExpand: func
    onCollapse: func
    onTransitionEnd: func
    controls: arrayOf(shape(
      name: oneOf(['delete', 'save', 'duplicate', 'edit']).isRequired
      handler: func.isRequired
      disabled: bool
    ))
    controlsPosition: oneOf ['left', 'right']
    withTogglableCaption: bool
    captionClassName: string
    contentClassName: string
    containerClassName: string
    collapseOnBlur: bool
    additionalProps: object
    passAdditionalProps: bool
    hideExpandControls: bool

  getInitialState: ->
    expanded: @props.forcedExpand ? false

  getDefaultProps: ->
    additionalProps: {}
    controls: []
    controlsPosition: 'right'
    collapseOnBlur: false
    passAdditionalProps: false
    withTogglableCaption: false
    hideExpandControls: false

  collapse: ->
    @props.onCollapse?()

    @setState expanded: false

  expand: ->
    @props.onExpand?()

    @setState expanded: true

  handleBlur: (evt) ->
    @collapse() if @props.collapseOnBlur and not evt.target.contains evt.relatedTarget

  renderControl: ({ name, handler, disabled }) ->
    <IconButton iconName={name} onClick={handler} key={name} disabled={disabled} inlined={false} />

  componentDidUpdate: (prevProps, prevState) ->
    @expand() if @props.forcedExpand and not prevProps.forcedExpand
    @collapse() if prevProps.forcedExpand and not @props.forcedExpand

  render: ->
    {
      additionalProps,
      controls,
      controlsPosition,
      children,
      forcedExpand,
      passAdditionalProps,
      withTogglableCaption,
      hideExpandControls
    } = @props
    expanded = @state.expanded or forcedExpand
    [ caption, contentEl ] = React.Children.toArray children
    containerClass = classNames 'collapsible-edit-container', "--controls-#{controlsPosition}",
      @props.containerClassName
    captionClickCb = if withTogglableCaption
      if expanded then @collapse else @expand

    content = if passAdditionalProps
      additionalPropsToPass = _.extend {}, additionalProps, { @collapse, @expand }
      props = { passAdditionalProps, additionalProps: additionalPropsToPass }
      React.cloneElement contentEl, props
    else
      contentEl

    captionCls = classNames 'caption-row', @props.captionClassName
    contentCls = classNames 'collapsed-content-row', @props.contentClassName

    <DynamicHeightContainer onTransitionEnd={@props.onTransitionEnd}>
      <div className={containerClass} onBlur={@handleBlur} tabIndex='-1'>
        <div className={captionCls} onClick={captionClickCb}>
          <div className='caption-row__caption'>
            {caption}
          </div>
          {not hideExpandControls and (
            <div className='controls'>
              <IconButton
                iconName={if expanded then 'collapse' else 'expand'}
                onClick={if expanded then @collapse else @expand}
              />
            </div>
          )}
        </div>
        {if expanded
          <div className={contentCls}>
            <div className='collapsed-content'>
              {content}
            </div>
            <div className='controls'>
              {controls.map @renderControl}
            </div>
          </div>
        }
      </div>
    </DynamicHeightContainer>

module.exports = CollapsibleContent
