CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ dropSpec, dropCollect } = require 'components/scope/outcomes/outcome_drop_area_dnd_spec'
DragDropTypes = require 'components/scope/outcomes/drag_drop_types'
DropTarget = ReactDnD.DropTarget

OutcomeDropTarget = createReactClass
  displayName: 'OutcomeDropTargetComponent'

  propTypes:
    gIndex: PropTypes.number.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  render: ->
    { connectDropTarget, isTargetHovered } = @props
    dropTargetClasses = classNames 'target', 'hovered': isTargetHovered

    connectDropTarget(
      <div className={dropTargetClasses}>
        <span>{@i18n 'drag_outcome_here'}</span>
      </div>
    )

module.exports = DropTarget(DragDropTypes.OUTCOME, dropSpec, dropCollect) OutcomeDropTarget
