Modal = require 'components/common/modal'
Button = require 'components/common/button'
Translation = require 'components/mixins/translation'

NoQuestionsSelectedModal = createReactClass
  displayName: 'NoQuestionsSelectedModal'
  mixins: [Translation('voting:voting')]

  render: ->
    defaultMessage = @i18n 'no_questions_selected_text'

    <Modal
      className='voting-notify-modal'
      isOpen={true}
      modalSize="standard"
      closeButton={false}
      onClose={@props.closeModal}
    >
      <div>
        <div>
          <span>{@props.message or defaultMessage}</span>
        </div>
        <div className="buttons">
          <button className="btn" onClick={@props.closeModal}>
            {@i18n '/settings:etd_templates.ok'}
          </button>
        </div>
      </div>
    </Modal>

NoConnectionModal = createReactClass
  displayName: 'NoConnectionModal'
  mixins: [Translation('voting:voting')]

  render: ->
    <Modal
      className='voting-notify-modal'
      isOpen={true}
      modalSize="standard"
      closeButton={false}
      onClose={@props.onClose}
    >
      <div>
        <div className='error-message'>{@i18n 'no_connection_message'}</div>
        <Button
          className='btn'
          label={@i18n '/actions.ok'}
          onClick={@props.onClose}
        />
      </div>
    </Modal>

HelpInfoModal = createReactClass
  displayName: 'HelpInfoModal'
  mixins: [Translation('voting:voting')]

  render: ->
    classes = classNames 'voting-help-modal',
      'full-width': @props.infoFor in ['votingFormAssessment', 'votingFormAllSections']
    <Modal
      className={classes}
      isOpen={true}
      closeButton={true}
      onClose={@props.onClose}
      scrollableContent={true}
    >
      <div className="voting-help scrollable-content">
        <img src={@props.imageSrc} />
      </div>
    </Modal>

VotingModal = createReactClass
  displayName: 'VotingModal'

  render: ->
    <Modal
      className='voting-modal'
      isOpen={true}
      modalSize="standard"
      closeButton={true}
      onClose={@props.onClose}
    >
      {@props.children}
    </Modal>

MessageEditedModal = createReactClass
  displayName: 'MessageEditedModal'
  mixins: [Translation('voting:voting.modals')]

  render: ->
    <Modal
      className='voting-notify-modal'
      isOpen={true}
      modalSize="standard"
      closeButton={false}
    >
      <div>
        <div>
          <span>{@i18n 'keep_message'}</span>
        </div>
        <div className="buttons">
          <button className="btn btn-cancel" onClick={@props.onNo}>
            {@i18n 'no'}
          </button>
          <button className="btn btn-apply" onClick={@props.onYes}>
            {@i18n 'yes'}
          </button>
        </div>
      </div>
    </Modal>

CloseVotingModal = createReactClass
  displayName: 'CloseVotingModal'
  mixins: [Translation('voting:voting')]

  render: ->
    <Modal
      className='close-voting-modal'
      isOpen={true}
      modalSize="standard"
      closeButton={false}
    >
      <div>
        <div className="message">{@props.message}</div>
        <div className="buttons">
          <button className="btn btn-cancel" onClick={@props.onCancel}>
            {@i18n '/settings:etd_templates.cancel'}
          </button>
          <button className="btn btn-success" onClick={@props.onAccept}>
            {@props.acceptLabel}
          </button>
        </div>
      </div>
    </Modal>

module.exports = {
  NoQuestionsSelectedModal
  NoConnectionModal
  HelpInfoModal
  VotingModal
  MessageEditedModal
  CloseVotingModal
}
