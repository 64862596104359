var DnDActions, DragDropTypes, QuestionsListComponentActions;

DnDActions = require('actions/react_dnd_actions');

DragDropTypes = require('components/evidence_syntheses/questions_list/drag_drop_types');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

module.exports = {
  dragSpec: {
    beginDrag: function(props, monitor, component) {
      var groupId, qId;
      qId = props.qId, groupId = props.groupId;
      DnDActions.beginDrag({
        type: DragDropTypes.QUESTION,
        qId: qId,
        groupId: groupId
      });
      return {
        qId: qId,
        groupId: groupId
      };
    },
    isDragging: function(props, monitor) {
      return props.qId === monitor.getItem().qId;
    },
    endDrag: function(props, monitor) {
      var questionDragData, _ref;
      questionDragData = _.extend({
        type: DragDropTypes.QUESTION
      }, monitor.getItem());
      if ((_ref = monitor.getDropResult()) != null ? _ref.newGroup : void 0) {
        return DnDActions.endDrag(questionDragData);
      } else {
        return QuestionsListComponentActions.updateQuestionPosition(questionDragData).then(function() {
          return DnDActions.endDrag(questionDragData);
        });
      }
    },
    canDrag: function() {
      return true;
    }
  },
  dropSpec: {
    hover: function(props, monitor, component) {
      var draggingQuestionId, hoveredGroupId, hoveredQuestionId;
      hoveredQuestionId = props.qId;
      draggingQuestionId = monitor.getItem().qId;
      if (hoveredQuestionId === draggingQuestionId) {
        return;
      }
      hoveredGroupId = props.groupId;
      return DnDActions.hover({
        type: DragDropTypes.QUESTION,
        hoveredGroupId: hoveredGroupId,
        hoveredQuestionId: hoveredQuestionId,
        draggingQuestionId: draggingQuestionId
      });
    },
    canDrop: function() {
      return false;
    }
  },
  dragCollect: function(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget()
    };
  }
};
