var storeHelpers;

storeHelpers = {
  docsArrayToOrderedMap: function(array) {
    var obj;
    obj = _(array).reduce(function(obj, model) {
      obj[model._id] = model;
      return obj;
    }, {});
    return Immutable.OrderedMap(Immutable.fromJS(obj));
  },
  addNewMapEntry: function(map) {
    if (map.get('_new')) {
      return;
    }
    return map.set('_new', Immutable.Map());
  },
  replaceMapEntry: function(map, id, newEntryJS) {
    var oldEntry;
    oldEntry = map.get(id);
    if (!oldEntry) {
      map = map["delete"]('_new');
    }
    map = map.set(id, Immutable.fromJS(newEntryJS));
    return map;
  }
};

module.exports = storeHelpers;
