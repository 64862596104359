{ func, instanceOf, bool, string } = PropTypes
ItemWithCheckbox = require 'components/common/item_with_checkbox'
GdtEditor = Editor.Editor
{ referenceDecorator } = require 'lib/editor_decorators'
EllipsizedText = require 'components/common/ellipsized_text'
{ isOverarching } = require 'lib/questions_helper'

RecommendationWithCheckbox = createReactClass

  displayName: "RecommendationWithCheckbox"

  propTypes:
    checked: bool
    onSelect: func.isRequired
    readonly: bool
    questionId: string.isRequired
    recommendation: instanceOf(Immutable.Map).isRequired
    withNumbers: bool

  getDefaultProps: ->
    checked: false
    readonly: false
    questionField: 'question'
    withNumbers: false

  onSelect: ->
    { recommendation, questionId, readonly } = @props
    @props.onSelect "#{questionId}_#{recommendation.get('_id')}" unless readonly

  render: ->
    { recommendation, readonly, checked, withNumbers } = @props

    <ItemWithCheckbox
      readonly={readonly}
      onChange={@onSelect}
      checked={checked}
    >
      <div className={classNames(
        'recommendation-row',
        'with-numbers': withNumbers,
        'readOnly': readonly
      )}>
        <GdtEditor
          editorContent={recommendation.get('content')}
          readOnly
          customDecorators={referenceDecorator}
        />
      </div>
    </ItemWithCheckbox>

module.exports = RecommendationWithCheckbox
