ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
DocumentsAndFormats = require 'components/global_export/documents_and_formats_list'
GlobalExportActions = require 'actions/global_export_actions'
GlobalExportStore = require 'stores/global_export_store'
QuestionsList = require 'components/global_export/questions_list'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'

storeConnectors =
  GlobalExportStore: (Store) ->
    isLoading: Store.isLoading()
    isExportEnabled: Store.isExportEnabled()
    isFetchError: Store.isFetchError()
    isOnline: Store.isOnline()

DataSelection = createReactClass
  displayName: 'GlobalExportDataSelection'
  mixins: [Translation('projects:global_export_dialog')]
  propTypes:
    isLoading: PropTypes.bool.isRequired
    isExportEnabled: PropTypes.bool.isRequired
    isFetchError: PropTypes.bool.isRequired
    isOnline: PropTypes.bool.isRequired

  onExport: ->
    exportOptions = GlobalExportStore.getExportOptions()
    GlobalExportActions.export exportOptions

  _renderError: ->
    { isOnline } = @props
    descriptionI18nKey = if isOnline then 'description' else 'offline_description'

    <div className="global-export-modal__fetch_error">
      <p className="fetch-error__header">{@i18n 'fetch_error.title'}</p>
      <p className="fetch-error__description">{@i18n "fetch_error.#{descriptionI18nKey}"}</p>
      <div className="fetch-error__buttons-row">
        <Button
          onClick={GlobalExportActions.closeModal}
          label={@i18n '/actions.close'}
          className="btn btn-block btn-apply"
        />
      </div>
    </div>

  render: ->
    { isLoading, isFetchError, isExportEnabled } = @props
    return <Spinner /> if isLoading
    return @_renderError() if isFetchError

    <div className="global-export-modal__data-selection">
      <QuestionsList />
      <DocumentsAndFormats />
      <ApplyCancelButtons
        onApply={@onExport}
        onCancel={GlobalExportActions.closeModal}
        applyLabel={@i18n '/actions.export'}
        isSubmitEnabled={isExportEnabled}
      />
    </div>

module.exports = ConnectStore DataSelection, [GlobalExportStore], storeConnectors
