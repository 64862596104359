ApprovalDetails = require 'components/scope/outcomes/approval_details'
CommentsBlock = require 'components/scope/comments_block'
CommentsBlocks = require 'components/scope/questions/grouped_comments_blocks'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
approvalStep = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS[5]

ApprovalOutcomeDetails = createReactClass
  displayName: 'ApprovalOutcomeDetails'

  propTypes:
    outcome: PropTypes.instanceOf(Immutable.Map).isRequired
    onApplyAdminComment: PropTypes.func.isRequired
    membersMap: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  onApplyAdminComment: (newText) ->
    onApplyAdminComment = @props.onApplyAdminComment approvalStep
    onApplyAdminComment {newText, key: @props.outcome.get 'key'}

  render: ->
    { outcome } = @props
    originalImportance = outcome.get 'importanceOriginal', outcome.get 'importance'
    approvalComments = outcome.getIn ['memberComments', approvalStep], Immutable.Map()
    approvalAdminComment = outcome.getIn ['adminComments', approvalStep]
    previousStepsComments = outcome.get('memberComments', Immutable.Map())
      .filterNot (comments, stepName) -> stepName is approvalStep

    <div className='outcome-details'>
      <div className='step-name'>
        {@i18n 'approval_phase'}
      </div>
      <div>
        <ApprovalDetails
          approvals={@props.outcome.get 'approvals', Immutable.Map()}
          originalImportance={originalImportance}
          groupType={@props.groupType}
        />
        <CommentsBlock
          membersComments={approvalComments}
          membersMap={@props.membersMap}
          adminComment={approvalAdminComment}
          onApplyAdminComment={@onApplyAdminComment}
          readOnlyComments={@props.readOnly}
        />
        <CommentsBlocks
          itemIdOrKey={@props.outcome.get 'key'}
          membersComments={previousStepsComments}
          adminComments={@props.outcome.get 'adminComments'}
          membersMap={@props.membersMap}
          noCollapsePhase={approvalStep}
          onApplyAdminComment={@props.onApplyAdminComment}
          readOnly={@props.readOnly}
        />
      </div>
    </div>

module.exports = ApprovalOutcomeDetails
