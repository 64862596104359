var Rm5ExportTableCell, captionCells, rm5ExportConfig, tableCaption;

rm5ExportConfig = require('lib/rm5_export_config');

tableCaption = rm5ExportConfig.tableCaptionSchemas;

captionCells = rm5ExportConfig.tableCaptionCells;

Rm5ExportTableCell = rm5ExportConfig.Rm5ExportTableCell;

module.exports = function(viewType, attributes) {
  var captionCell, tableHeader, _i, _len, _ref;
  tableHeader = [];
  _ref = tableCaption[viewType];
  for (_i = 0, _len = _ref.length; _i < _len; _i++) {
    captionCell = _ref[_i];
    tableHeader.push({
      cells: [
        new Rm5ExportTableCell({
          textContent: captionCells[captionCell](attributes),
          colspan: attributes.colsNo,
          name: captionCell
        })
      ]
    });
  }
  return tableHeader;
};
