var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"recommendations-header\"></div>\n\n<table class=\"standard-table judgement-table\" width=\"100%\">\n   <colgroup class=\"diagnostic-initial\">\n    <col class=\"blank\" span=\"1\">\n    <col class=\"criteria\" span=\"1\">\n    <col class=\"judgements\" span=\"1\">\n    <col class=\"research-evidences\" span=\"1\">\n    <col class=\"additional-considerations\" span=\"1\">\n  </colgroup>\n  <thead>\n    <tr>\n      <th colspan=\"5\">\n        <h1>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "assessment", {"name":"i18n","hash":{},"data":data})))
    + "</h1>\n      </th>\n    </tr>\n    <tr class=\"diagnostic-headers\">\n      <th class=\"blank\"></th>\n      <th class=\"criteria\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "domain", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "judgements", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "research_evidence", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n      <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "additional_considerations_explanations", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n    </tr>\n  </thead>\n  <tbody>\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "problem", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "diagnostic_test_accuracy", {"name":"i18nNamespace","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "values_and_preferences", {"name":"i18nNamespace","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "evidence_certainty", {"name":"i18nNamespace","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "benefits_harms", {"name":"i18nNamespace","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "resources", {"name":"i18nNamespace","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "equity", {"name":"i18nNamespace","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "acceptability", {"name":"i18nNamespace","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "feasibility", {"name":"i18nNamespace","hash":{},"fn":this.program(20, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </tbody>\n</table>\n<p class=\"judgement-table-asterisks\">\n  <div>* "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "feasibility.asterisk_text", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n</p>\n";
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <tr class=\"problem\">\n        <td class=\"rotated h130\">\n          <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </td>\n        <td class=\"domain-cell\">\n          <div><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "is_priority", {"name":"i18n","hash":{},"data":data})))
    + "</b></div>\n          <br>\n          <div><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "is_severe", {"name":"i18n","hash":{},"data":data})))
    + "</b></div>\n          <br>\n          <div>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "may_skip", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n        </td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "no, probably_no, uncertain, probably_yes, yes, varies", "problemPriorityJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "no, probably_no, uncertain, probably_yes, yes, varies", "problemSevereJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "problemResearchEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "problemAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <tr class=\"diagnostic-test-accuracy\">\n        <td class=\"rotated h200\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "what_test_accuracy", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "very_inaccurate, inaccurate, uncertain,\n            accurate, very_accurate, varies", "diagnosticTestAccuracyJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "diagnosticTestAccuracyEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "diagnosticTestAccuracyAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
},"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <tr class=\"values-and-preferences\">\n        <td class=\"rotated h180\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "is_similarity", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "similar, probably_similar, uncertain, probably_not_similar, not_similar, varies", "mainOutcomesValuesSimilarityJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "valuesAndPreferencesEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "valuesAndPreferencesAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <tr class=\"evidence-certainty\">\n        <td rowspan=\"5\" class=\"rotated h490\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "accuracy_certainty", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "very_low, low, moderate, high", "accuracyEvidenceCertaintyJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td colspan=\"2\">\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "accuracyEvidenceCertaintyEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "adverse_effects_certainty", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "very_low, low, moderate, high", "adverseEffectsCertaintyJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "adverseEffectsCertaintyEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "adverseEffectsCertaintyAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "management_consequences_effects_certainty", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "very_low, low, moderate, high", "managementConsequencesEffectsCertaintyJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "managementConsequencesEffectsCertaintyEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "managementConsequencesEffectsCertaintyAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "decisions_link_certainty", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "very_low, low, moderate, high", "decisionsLinkCertaintyJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "decisionsLinkCertaintyEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "decisionsLinkCertaintyAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "overall_effects_certainty", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "very_low, low, moderate, high", "overallEffectsCertaintyJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "overallEffectsCertaintyEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "overallEffectsCertaintyAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <tr class=\"benefits-harms\">\n        <td rowspan=\"4\" class=\"rotated h360\"><span class=\"dx\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title_dx", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "how_important_outcomes", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td></td>\n        <td rowspan=\"3\"></td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "outcomeImportanceAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "how_substantial_desirable_effects", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td></td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "substantialDesirableEffectsAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "how_substantial_undesirable_effects", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td></td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "substantialUndesirableEffectsAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "effects_balance_favour", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"radio-group\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "../radio_values", {"name":"i18nNamespace","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </td>\n        <td></td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "effectsBalanceFavourAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "favors_comparison", "probably_favors_comparison", "doesnt_favor_any", "probably_favors_intervention", "favors_intervention", {"name":"forList","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "              "
    + escapeExpression(((helpers.recommendationJudgementOption || (depth0 && depth0.recommendationJudgementOption) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), "effectsBalanceFavourJudgement", {"name":"recommendationJudgementOption","hash":{},"data":data})))
    + "\n";
},"14":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <tr class=\"resources\">\n        <td rowspan=\"2\" class=\"rotated h170\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "are_resources_small", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "no, probably_no, uncertain, probably_yes, yes, varies", "areResourcesSmallJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "areResourcesSmallEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "areResourcesSmallAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n      <tr>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "is_incremental_cost_small", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "no, probably_no, uncertain, probably_yes, yes, varies", "isIncrementalCostSmallJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "isIncrementalCostSmallEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "isIncrementalCostSmallAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
},"16":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <tr class=\"equity\">\n        <td class=\"rotated h85\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "what_happens_to_health_inequities", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "increased, probably_increased, uncertain, probably_reduced, reduced, varies", "healthInequitiesJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "healthInequitiesEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "healthInequitiesAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
},"18":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <tr class=\"acceptability\">\n        <td class=\"rotated h125\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "is_option_acceptable", {"name":"i18n","hash":{},"data":data})))
    + "</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "no, probably_no, uncertain, probably_yes, yes, varies", "isOptionAcceptableJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "isOptionAcceptableEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "isOptionAcceptableAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
},"20":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <tr class=\"feasibility\">\n        <td class=\"rotated h125\"><span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{},"data":data})))
    + "</span></td>\n        <td class=\"domain-cell\"><b>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "is_option_feasible", {"name":"i18n","hash":{},"data":data})))
    + "*</b></td>\n        <td class=\"judgement radio-group\">\n          "
    + escapeExpression(((helpers.recommendationRadiosTable || (depth0 && depth0.recommendationRadiosTable) || helperMissing).call(depth0, "no, probably_no, uncertain, probably_yes, yes, varies", "isOptionFeasibleJudgement", {"name":"recommendationRadiosTable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "isOptionFeasibleEvidence", "with_insert", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n        <td>\n          "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "isOptionFeasibleAdditionalConsiderations", {"name":"contentEditable","hash":{},"data":data})))
    + "\n        </td>\n      </tr>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:recommendations.table", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + escapeExpression(((helpers.recommendationDxTable || (depth0 && depth0.recommendationDxTable) || helperMissing).call(depth0, depth0, {"name":"recommendationDxTable","hash":{},"data":data})))
    + "\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}