OutcomeTableViewActions = require 'actions/outcomes_table_view_actions'
OverReviewActions = require 'actions/over_review_actions'
EditableCell = require 'components/evidence_syntheses/outcomes/cells/editable_cell'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
DesignStudies = require 'models/outcome/design_studies'
{
  SelectionList,
  SelectionListOption,
  SelectionListOptionGroup
} = require 'components/evidence_syntheses/outcomes/cells/selection_list'


# TODO: extract this to some proper place
designStudies = Immutable.OrderedMap
  'RandTrials': Immutable.Map
    group: 'RandTrials'
    questionTypes: Immutable.List [ 'treatment', 'prognostic' ]
  'interrupted_time_series': Immutable.Map
    group: 'ObsStudies'
    value: 'interrupted_time_series'
    questionTypes: Immutable.List [ 'treatment' ]
  'before_after_studies': Immutable.Map
    group: 'ObsStudies'
    value: 'before_after_studies'
    questionTypes: Immutable.List [ 'treatment', 'prognostic' ]
  'cohort_studies': Immutable.Map
    group: 'ObsStudies'
    value: 'cohort_studies'
    questionTypes: Immutable.List [ 'treatment', 'prognostic' ]
  'case_control_studies': Immutable.Map
    group: 'ObsStudies'
    value: 'case_control_studies'
    questionTypes: Immutable.List [ 'treatment', 'prognostic' ]
  'cross_sectional_studies': Immutable.Map
    group: 'ObsStudies'
    value: 'cross_sectional_studies'
    questionTypes: Immutable.List [ 'treatment', 'prognostic' ]
  'case_series': Immutable.Map
    group: 'ObsStudies'
    value: 'case_series'
    questionTypes: Immutable.List [ 'treatment', 'prognostic' ]
  'case_reports': Immutable.Map
    group: 'ObsStudies'
    value: 'case_reports'
    questionTypes: Immutable.List [ 'treatment', 'prognostic' ]
  'case_control_and_other_combined': Immutable.Map
    group: 'ObsStudies'
    value: 'case_control_and_other_combined'
    questionTypes: Immutable.List [ 'treatment', 'prognostic' ]
  'other_design': Immutable.Map
    group: 'ObsStudies'
    value: 'other_design'
    questionTypes: Immutable.List [ 'treatment', 'prognostic' ]
  'cross_sectional_cohort_type': Immutable.Map
    group: 'ObsStudies'
    value: 'cross_sectional_cohort_type'
    questionTypes: Immutable.List ['diagnostic']
  'case_control_type_accuracy': Immutable.Map
    group: 'ObsStudies'
    value: 'case_control_type_accuracy'
    questionTypes: Immutable.List [ 'diagnostic' ]
  'cohort_and_case_control_type': Immutable.Map
    group: 'ObsStudies'
    value: 'cohort_and_case_control_type'
    questionTypes: Immutable.List [ 'diagnostic' ]
  'single_arm_studies': Immutable.Map
    group: 'ObsStudies'
    value: 'single_arm_studies'
    questionTypes: Immutable.List [ 'prognostic' ]

getDesignStudyOptionGroupsForQuestionType = (questionType) ->
  designStudies
    .filter (ds) -> ds.get('questionTypes').includes questionType
    .groupBy (ds) -> ds.get 'group'

DesignStudiesCell = createReactClass
  displayName: 'DesignStudiesCell'

  propTypes:
    outcomeAttrs: PropTypes.object.isRequired
    questionType: PropTypes.string.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]

  _ref: (el) ->
    @el = el

  getDefaultProps: ->
    questionType: 'treatment'

  renderOption: (option, optionIdx) ->
    dsGroup = option.get 'group'
    dsValue = option.get 'value'

    dsSpec = DesignStudies.get dsGroup, dsValue
    { label, value } = dsSpec
    optionValue = if dsValue? then dsValue else dsGroup

    <SelectionListOption
      key={optionIdx}
      label={@i18n value ? label}
      value={optionValue}
    />

  renderDesignStudyOptions: ->
    getDesignStudyOptionGroupsForQuestionType @props.questionType
    .flatMap (dsOptions) => dsOptions.map @renderOption
    .toList()

  renderGroupedDesignStudyOptions: ->
    getDesignStudyOptionGroupsForQuestionType @props.questionType
    .map (dsOptions, groupName) =>
      grouLabel = @i18n if groupName is 'ObsStudies'
        'observational_studies'
      else
        'randomised_trials'

      <SelectionListOptionGroup key={groupName} groupLabel={grouLabel}>
        {dsOptions.map(@renderOption).toList()}
      </SelectionListOptionGroup>
    .toList()

  handleOptionSelect: (newVal) ->
    newDesignStudiesValue = if newVal
      optionSpec = designStudies.get newVal
      dsGroup = optionSpec.get 'group'
      dsValue = optionSpec.get 'value'
      DesignStudies.get dsGroup, dsValue
    else
      null

    OverReviewActions.updateSourceOutcome(
      outcomeId: @props.outcomeAttrs['_id']
      sourceId: @props.sourceId
      questionId: @props.questionId
      outcomeData:
        designStudies: newDesignStudiesValue
    )

    OutcomeTableViewActions.stopOutcomeEdit()

  render: ->
    designStudiesAttr = @props.outcomeAttrs.designStudies
    selectedOption = designStudiesAttr?.value ? designStudiesAttr?.name

    <EditableCell {...@props} ref={@_ref} title={@i18n 'study_design'}>
      <SelectionList
        onClear={@handleOptionSelect}
        onSelect={@handleOptionSelect}
        selectedValue={selectedOption}
      >
        {switch @props.questionType
          when 'diagnostic', 'prognostic'
            @renderDesignStudyOptions()
          else
            @renderGroupedDesignStudyOptions()
        }
      </SelectionList>
    </EditableCell>

module.exports = DesignStudiesCell
