ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
{
  MDG_CRITERIA_ORDER
  MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_CASE_STUDIES
  MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_COHORT_STUDIES
  MDG_EPIDEMIOLOGICAL_SCORE_POINTS
  MDG_QUESTION_TYPES
} = require 'lib/mdg_helper'
mediator = require 'mediator'
Modal = require 'components/common/modal'
{ objectsEqual } = require 'base/lib/utils'
ReferenceAttachmentLink = require 'components/references/attachment_link'
ReferencesStore = require 'stores/references_store'
SelectCustom = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
{ SelectWithCheckboxes } = ReactComponents
{ useI18n } = require 'lib/react_utils'

MULTICHOICE_FIELDS = [
  'comparabilityOfCohortsOnTheBasisOfTheDesignOrAnalysis'
  'comparabilityOfCasesAndControlsOnTheBasisOfTheDesignOrAnalysis'
]

scoreCalculate = (criteria) ->
  _.reduce criteria, (acc, criterion) ->
    acc + (criterion ? 0)
  , 0

epidemiologicalScoreCalculate = (criteria) ->
  _.reduce _.flatten(_.values(criteria)), (acc, criterion) ->
    acc + (MDG_EPIDEMIOLOGICAL_SCORE_POINTS[criterion] ? 0)
  , 0

getEpidemiologicalForm = (studyType) ->
  if studyType is 'cohort_studies'
    return MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_COHORT_STUDIES
  else
    return MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_CASE_STUDIES

storeConnectors =
  ReferencesStore: (ReferencesStore, { parentValue: referenceId }) ->
    reference: if referenceId
      ReferencesStore.getReferenceById(referenceId)
    else
      Immutable.Map()

CriteriaRow = ({ excludedFromScoring, criterionId, onChange, options, selectedValue }) ->
  i18n = useI18n('mdg_tables:edit_form')
  criteriaClasses = classNames 'criteria__criterion', 'disabled': excludedFromScoring
  <div className={criteriaClasses} key={criterionId}>
    <div className="criterion__name">
      {i18n "score.criteria.#{criterionId}"}
    </div>
    <div className="criterion__value">
      {if criterionId in MULTICHOICE_FIELDS
        <SelectWithCheckboxes
          disabled={excludedFromScoring}
          options={options}
          onChange={onChange(criterionId)}
          selectedOptions={selectedValue}
          withSelectAll={false}
        />
      else
        selected = if _.isArray(selectedValue)
          _.first(_.compact(selectedValue))
        else selectedValue
        <SelectCustom
          className="value__select"
          disabled={excludedFromScoring}
          onChange={onChange(criterionId)}
          options={options}
          selected={selected}
        />
      }
    </div>
  </div>

CriteriaRow.propTypes =
  excludedFromScoring: PropTypes.bool
  criterionId: PropTypes.string.isRequired
  onChange: PropTypes.func.isRequired
  options: PropTypes.arrayOf(PropTypes.string).isRequired
  selectedValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])

CriteriaRow.defaultProps =
  excludedFromScoring: false

ScoreField = createReactClass
  displayName: 'ScoreField'

  mixins: [Translation('mdg_tables:edit_form')]

  propTypes:
    additionalProps: PropTypes.shape(
      questionType: PropTypes.oneOf(MDG_QUESTION_TYPES).isRequired
      comments: PropTypes.string
    ).isRequired
    onChange: PropTypes.func.isRequired
    parentValue: PropTypes.string
    value: PropTypes.shape(
      excludedFromScoring: PropTypes.bool
      criteria: PropTypes.objectOf(PropTypes.oneOf([0, 0.5, 1]))
      epidemiologicalCriteria: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
      value: PropTypes.number
      studyType: PropTypes.oneOf(['case_studies', 'cohort_studies'])
    ).isRequired
    reference: PropTypes.instanceOf(Immutable.Map).isRequired

  getDefaultProps: ->
    parentValue: null

  getInitialState: ->
    isModalOpen: false
    value: @props.value
    comments: @props.additionalProps?.comments or ""

  componentDidUpdate: (prevProps) ->
    unless objectsEqual prevProps.value, @props.value
      @setState
        value: @props.value

  openModal: ->
    @setState isModalOpen: true

  onCancel: ->
    @setState isModalOpen: false, value: @props.value

  onApply: ->
    { onChange } = @props
    { value, comments } = @state
    @setState isModalOpen: false
    onChange { value, comments }

  onChangeStudyType: (studyType) ->
    { value } = @state
    { onChange } = @props
    # reset score field
    @setState { value: _.extend {}, value, {
      studyType,
      epidemiologicalCriteria: {},
      value: 0
    },
    comments: ""
    }, => onChange value: @state.value, comments: @state.comments

  onChange: (field) -> (newValueOrEvt) =>
    { additionalProps: { questionType } } = @props
    { value } = @state
    if field is 'comments'
      @setState comments: newValueOrEvt.target.value
    else if field is 'score'
      @setState value: _.extend {}, value, { value: parseFloat(newValueOrEvt.target.value)}
    else if questionType is 'epidemiological'
      { epidemiologicalCriteria: oldEpidemiologicalCriteria } = value
      obj = {}
      obj[field] = _.compact if _.isArray(newValueOrEvt) then newValueOrEvt else [newValueOrEvt]
      epidemiologicalCriteria = _.extend {}, oldEpidemiologicalCriteria, obj
      score = epidemiologicalScoreCalculate epidemiologicalCriteria
      @setState value: _.extend {}, value, { epidemiologicalCriteria, value: score }
    else
      { criteria: oldCriteria } = value
      obj = {}
      obj[field] = newValueOrEvt
      criteria = _.extend {}, oldCriteria, obj
      score = scoreCalculate criteria
      @setState value: _.extend {}, value, { criteria, value: score }

  toggleExludedFromScoring: ->
    { value } = @state
    @setState value: _.extend {}, value, { excludedFromScoring: !value.excludedFromScoring }

  render: ->
    {
      isModalOpen,
      value: { excludedFromScoring, criteria, epidemiologicalCriteria, value: score }
      comments
    } = @state

    {
      value: { value: currentScore, studyType }
      reference
      parentValue: referenceId
      additionalProps: { questionType }
    } = @props

    attachmentFileName = reference?.getIn ['attachment', 'fileName']

    criteriaClasses = classNames 'criteria__criterion', 'disabled': excludedFromScoring
    regularOptions = _.map [0, 0.5, 1], (value) -> { value, text: "#{value}" }

    <div className="score-field">
      <div className="score-field__score" onClick={@openModal}>
        {currentScore}
      </div>
      <Modal
        className="score-field-modal"
        isOpen={isModalOpen}
        title={@i18n 'fields.score'}
      >
        <div className="mb-10 flex flex-row items-center">
          <label className="flex flex-row flex-grow items-center">
            <input
              type="checkbox"
              checked={excludedFromScoring}
              onChange={@toggleExludedFromScoring}
            />
            <span className="ml-5">{@i18n 'score.excluded_from_scoring'}</span>
          </label>
          {attachmentFileName and <div>
            <ReferenceAttachmentLink
              attachmentFileName={attachmentFileName}
              projectId={mediator.project.id}
              referenceId={referenceId}
            />
          </div>}
        </div>
        <div className="score-field-modal__criteria">
          {if questionType is 'epidemiological'
            <div>
              <div className="flex flex-row">
                <span className="ml-5 flex-grow">{@i18n 'study.select_study_type'}</span>
                <SelectCustom
                  className="value__select"
                  onChange={@onChangeStudyType}
                  options={_.map ['case_studies', 'cohort_studies'],
                    (value) => { value, text: @i18n "study.#{value}" }}
                  selected={studyType}
                />
              </div>
              <div>
                {getEpidemiologicalForm(studyType).map (section, key) =>
                  <div className="epidemiological-criteria mb-10" key={key}>
                    <div className="bold">{@i18n "score.#{key}"}</div>
                    {section.map (sectionCriteria, criterionId) =>
                      options = sectionCriteria.map (value) =>
                        { value, text: @i18n "score.values.#{criterionId}.#{value}"}
                      selectedValues = _.get(epidemiologicalCriteria, criterionId, [])
                      <CriteriaRow
                        key={criterionId}
                        criterionId={criterionId}
                        excludedFromScoring={excludedFromScoring}
                        onChange={@onChange}
                        options={options.toJS()}
                        selectedValue={selectedValues}
                      />
                    .toList()}
                  </div>
                .toList()}
              </div>
            </div>
          else
            _.chain(criteria)
            .pairs()
            .sortBy ([criterionId]) -> MDG_CRITERIA_ORDER.indexOf criterionId
            .map ([criterionId, criterionScore]) =>
              <CriteriaRow
                key={criterionId}
                criterionId={criterionId}
                excludedFromScoring={excludedFromScoring}
                onChange={@onChange}
                options={regularOptions}
                selectedValue={criterionScore}
              />
            .value()
          }
        </div>
        <div className="score-field-modal__comments">
          <div className="comments__text">{@i18n 'score.comments'}</div>
          <div className="comments__value">
            <textarea onChange={@onChange('comments')} value={comments} />
          </div>
        </div>
        <div className="score-field-modal__score">
          <div className="score__inner">
            <div className="inner__text">{@i18n 'score.value'}</div>
            {if excludedFromScoring
              <input
                type="number"
                className="number number--with-arrows"
                max={12}
                min={0}
                step={0.5}
                value={score}
                onChange={@onChange('score')}
              />
            else
              <div className="inner__value">{score}</div>
            }
          </div>
        </div>
        <ApplyCancelButtons onApply={@onApply} onCancel={@onCancel} />
      </Modal>
    </div>

module.exports = ConnectStore ScoreField, ReferencesStore, storeConnectors
