TitleRow = require 'components/scope/outcomes/approval_outcome_title_row'
DetailsRow = require 'components/scope/outcomes/approval_outcome_details_row'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
OutcomesCommentsMixin = require 'components/mixins/outcomes_comments_mixin'
approvalStep = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS[5]
DragDropTypes = require 'components/scope/outcomes/drag_drop_types'
{ dragSpec, dropSpec, dragCollect, dropCollect } =
  require 'components/scope/outcomes/approval_outcome_dnd_spec'
DragSource = ReactDnD.DragSource
DropTarget = ReactDnD.DropTarget

ApprovalOutcome = createReactClass
  displayName: 'ApprovalOutcome'

  propTypes:
    outcome: PropTypes.instanceOf(Immutable.Map).isRequired
    membersMap: PropTypes.instanceOf(Immutable.OrderedMap)
    todos: PropTypes.instanceOf Immutable.List
    onCommentToggle: PropTypes.func
    showingComments: PropTypes.bool
    readOnly: PropTypes.bool

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
    OutcomesCommentsMixin
  ]

  _hasComments: ->
    not @props.outcome.getIn(['memberComments', approvalStep], Immutable.Map()).isEmpty()

  _getUnfinishedTodosCount: ->
    return null unless @props.todos?
    @props.todos.filterNot((todo) -> todo.get 'completed').size

  render: ->
    hasComments = @_hasComments()
    { connectDragSource, connectDropTarget, isDragging, readOnly } = @props
    tbodyClass = classNames 'approval-outcome',
      collapsed: if hasComments then not @state.showingComments else true
      dragging: isDragging

    connectDragSource(
      connectDropTarget(
        <tbody className={tbodyClass}>
          <TitleRow
            outcome={@props.outcome}
            todosCount={@_getUnfinishedTodosCount()}
            questionOnly={hasComments and @state.showingComments}
            onCommentToggle={@toggleComments if hasComments}
            readOnly={@props.readOnly}
          />
          {if hasComments and @state.showingComments
            <DetailsRow
              outcome={@props.outcome}
              membersMap={@props.membersMap}
              todos={@props.todos}
              groupType={@props.groupType}
              gIndex={@props.gIndex}
              readOnly={@props.readOnly}
              onCommentToggle={@toggleComments}
            />
          }
        </tbody>
      )
    )

DragSourced = DragSource(DragDropTypes.APPROVAL_TAB_OUTCOME, dragSpec, dragCollect) ApprovalOutcome
DropTargeted = DropTarget(DragDropTypes.APPROVAL_TAB_OUTCOME, dropSpec, dropCollect) DragSourced

module.exports = DropTargeted
