var documentId, extendedCheck, extraChecks, string, stringChecks, _ref;

_ref = require('lib/db_docs/field_types/built_in_types'), string = _ref.string, extraChecks = _ref.extraChecks;

extendedCheck = require('lib/db_docs/field_types/type_decorators').extendedCheck;

stringChecks = extraChecks.string;

documentId = extendedCheck(stringChecks.minLength(1))(string);

module.exports = documentId;
