mediator = require 'mediator'
{ NetworkGraphSvg } = NmaComponents
NMAVizActions = require 'actions/nma_viz_actions'
NMAToolbarWithQuestionList = require 'components/nma/nma_toolbar_with_question_list'
Router = require 'router'
{ useCoffeeCallback } = require 'lib/react_utils'

NMAVizToolbar = ({ questionId }) ->
  onBack = useCoffeeCallback [questionId], ->
    route =  Router::getProjectRelativeUrl "/nma/#{questionId}"
    mediator.publish '!router:route', route

  rightElement =
    <button className="network-graph-button" onClick={NMAVizActions.openNetworkGraph}>
      <NetworkGraphSvg />
    </button>

  <NMAToolbarWithQuestionList
    className="nma-viz-toolbar"
    onBack={onBack}
    rightElement={rightElement}
  />

module.exports = NMAVizToolbar
