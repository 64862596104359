# Sometimes it is neaded to embed dynamic links into translation text. This component does this for
# you. Needs 2 mandatory props:
#     text - a simple string containing translation text with {link}
#            placeholders. Link placeholder must contain 'link' and appearance order number.
#            For example, {link1}.
#     links - object whose keys are link ids of the same name as placehoders in the translation
#            text. The value of the key must be a link text to put. Example the links object:
#            {link1: 'Questions'}

TextWithLink = createReactClass
  displayName: 'TextWithLink'
  propTypes:
    text: PropTypes.string.isRequired
    links: PropTypes.object.isRequired

  _getParts: -> @props.text.split /\{([\w]+)\}/

  _getInfoLink: (link, linkText) ->
    <a className="info-link" href={link}>
      {linkText}
    </a>

  _isLinkPlaceholder: (text) ->
    /^link[\d]+/.test text

  render: ->
    <span {...@props}>
      {@_getParts().map (partText, idx) =>
        if @_isLinkPlaceholder partText
          [link, linkText] = @props.links[partText]
          <span key={idx}>
            {@_getInfoLink link, linkText}
          </span>
        else
          <span key={idx}>{partText}</span>
      }
    </span>

module.exports = TextWithLink
