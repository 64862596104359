CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ string, bool, object, oneOfType, number, node, element } = PropTypes

HeaderCell = createReactClass
  displayName: 'HeaderCell'

  propTypes:
    disabled: bool
    content: oneOfType([string, number, node, element])
    style: object
    className: string
    id: string

  mixins: [CustomRenderMixin]

  render: ->
    cellProps = _.omit @props, 'content', 'outcomeAttrs', 'questionType', 'isEditing'

    <th {...cellProps}>
      {@props.content}
    </th>

module.exports = HeaderCell
