var ChapterDoc, ChapterDocShape, DOCUMENT_SECTIONS_V2_SECTION_TYPE, DocumentSectionsV2Actions, MDA_TABLE_DOC_TYPE, MDA_TOPIC_CHAPTER_PREFIX, MDA_TOPIC_PREFIX, MDA_TOPIC_ROOT_PREFIX, MDA_TOPIC_SECTION_PREFIX, MdaTableDoc, MdaTopicDoc, MdaTopicRootDoc, MembersActions, QuestionGroupsActions, QuestionsActions, QuestionsStatusesActions, ReferencesActions, SectionDoc, TOPIC_STATUSES, TOPIC_TEMPLATES, W, alt, callMethod, convertRowsToArray, createChaptersStructure, discussionsDbView, docInstSetAt, docInstUpdateAt, duplicateNestedDocs, errorHandlerWithMsg, fetchMdaTablesDiscussions, fetchTables, flatPluckCompact, generateActions, generateGUID, getChapterAndSectionIds, getDocIds, kebabCase, maybeResolveSectionsConflicts, mdaChaptersView, mdaSectionsWithTablesView, mdaTablesDiscussionView, mediator, prepareCodeWithSanitizedValue, removeItemById, sequence, _ref, _ref1, _ref2, _ref3, _ref4, _ref5, _ref6;

alt = require('alt');

ChapterDoc = require('lib/db_docs/document_sections_v2_chapter_doc');

ChapterDocShape = require('lib/db_docs/doc_shapes/document_sections_v2_chapter_doc_shape');

SectionDoc = require('lib/db_docs/document_sections_v2_section_doc');

DocumentSectionsV2Actions = require('actions/document_sections_v2_actions');

generateActions = require('actions/utils/generate_actions');

_ref = require('lib/db_docs/doc_types'), MDA_TABLE_DOC_TYPE = _ref.MDA_TABLE, DOCUMENT_SECTIONS_V2_SECTION_TYPE = _ref.DOCUMENT_SECTIONS_V2_SECTION_TYPE;

kebabCase = require('lodash').kebabCase;

MdaTableDoc = require('lib/db_docs/mda_table_doc');

MdaTopicDoc = require('lib/db_docs/mda_topic_doc');

MdaTopicRootDoc = require('lib/db_docs/mda_topic_root_doc');

mediator = require('mediator');

MembersActions = require('actions/members_actions');

QuestionGroupsActions = require('actions/question_groups_actions');

QuestionsActions = require('actions/questions_actions');

QuestionsStatusesActions = require('actions/questions_statuses_actions');

ReferencesActions = require('actions/references_actions');

TOPIC_TEMPLATES = require('lib/mda_topics/mda_topic_templates');

_ref1 = require('lib/mda_helper'), TOPIC_STATUSES = _ref1.TOPIC_STATUSES, getDocIds = _ref1.getDocIds, getChapterAndSectionIds = _ref1.getChapterAndSectionIds;

_ref2 = require('lib/db_docs/document_instance_helpers'), docInstUpdateAt = _ref2.docInstUpdateAt, docInstSetAt = _ref2.docInstSetAt, removeItemById = _ref2.removeItemById;

W = require('when/when');

sequence = require('when/sequence');

_ref3 = require('lib/app_utils'), convertRowsToArray = _ref3.convertRowsToArray, errorHandlerWithMsg = _ref3.errorHandlerWithMsg, flatPluckCompact = _ref3.flatPluckCompact;

_ref4 = require('base/lib/utils'), callMethod = _ref4.callMethod, generateGUID = _ref4.generateGUID;

prepareCodeWithSanitizedValue = require('lib/questions_helper').prepareCodeWithSanitizedValue;

_ref5 = require('lib/doc_prefixes'), MDA_TOPIC_PREFIX = _ref5.mdaTopic, MDA_TOPIC_ROOT_PREFIX = _ref5.mdaRootTopicDoc, MDA_TOPIC_CHAPTER_PREFIX = _ref5.mdaChapterTopicDoc, MDA_TOPIC_SECTION_PREFIX = _ref5.mdaSectionTopicDoc;

_ref6 = require('lib/document_sections_v2_helper'), discussionsDbView = _ref6.discussionsDbView, maybeResolveSectionsConflicts = _ref6.maybeResolveSectionsConflicts;

mdaChaptersView = {
  map: function(doc) {
    if (doc.type === 'document_sections_chapter') {
      return emit(doc.mdaTopicId, doc);
    }
  }
};

mdaSectionsWithTablesView = {
  map: function(doc) {
    var chapterId, mdaTableId, mdaTopicId, sectionId;
    if (doc.type === 'document_sections_section' && (doc.mdaTableId != null)) {
      sectionId = doc._id;
      chapterId = doc.parent;
      mdaTableId = doc.mdaTableId;
      mdaTopicId = doc.mdaTopicId;
      return emit(mdaTopicId, {
        chapterId: chapterId,
        mdaTableId: mdaTableId,
        sectionId: sectionId
      });
    }
  }
};

mdaTablesDiscussionView = {
  map: function(doc) {
    var annotations, _ref7;
    if (doc.docType === 'mdaTable' && (doc.content != null)) {
      annotations = Object.values((_ref7 = doc.content.annotations) != null ? _ref7 : {});
      if (annotations.length) {
        return emit(doc._id, {
          annotations: doc.content.annotations,
          changeTracker: {},
          mdaTableId: doc._id
        });
      }
    }
  }
};

duplicateNestedDocs = function(chapterDoc, key, chapterId) {
  var Doc, PREFIX, projectId;
  projectId = mediator.project.id;
  Doc = key === 'subchapters' ? ChapterDoc : SectionDoc;
  PREFIX = key === 'subchapters' ? MDA_TOPIC_CHAPTER_PREFIX : MDA_TOPIC_SECTION_PREFIX;
  return Doc.at(projectId).fetch(chapterDoc[key]).then(function(_arg) {
    var rows;
    rows = _arg.rows;
    return _.pluck(rows, 'doc');
  }).then(function(docs) {
    return _.map(docs, function(doc) {
      return _.omit(_.extend(doc, {
        _id: "" + PREFIX + (generateGUID()),
        parent: chapterId
      }), '_rev');
    });
  }).then(function(subDocs) {
    return Doc.at(projectId).createMany(subDocs);
  });
};

fetchMdaTablesDiscussions = function(projectId, topicId) {
  var adapter;
  if (projectId == null) {
    projectId = mediator.project.id;
  }
  adapter = mediator.services.storePersistenceAdapter;
  return W.all([adapter.fetchWithView(projectId, 'mdaSectionsWithTablesView', mdaSectionsWithTablesView, topicId), adapter.fetchWithView(projectId, 'mdaTablesDiscussionsView', mdaTablesDiscussionView)]).then(function(_arg) {
    var discussions, mdaTablesDiscussions, sections, sectionsByTableId;
    sections = _arg[0], discussions = _arg[1];
    sectionsByTableId = _.groupBy(sections, 'mdaTableId');
    mdaTablesDiscussions = _.chain(discussions).map(function(discussionItem) {
      var _ref7;
      return _.map((_ref7 = sectionsByTableId[discussionItem.mdaTableId]) != null ? _ref7 : [], function(sectionData) {
        return _.extend({}, discussionItem, sectionData);
      });
    }).flatten().value();
    return {
      mdaTablesDiscussions: mdaTablesDiscussions,
      mdaTablesSections: sectionsByTableId
    };
  });
};

fetchTables = function(projectId) {
  return MdaTableDoc.at(projectId).fetchMany({
    conflicts: true
  }).then(function(_arg) {
    var rows;
    rows = _arg.rows;
    return convertRowsToArray(rows);
  }).then(maybeResolveSectionsConflicts(projectId));
};

createChaptersStructure = function(topic) {
  var template;
  template = _.find(TOPIC_TEMPLATES, function(topicTpl) {
    return topicTpl.id === topic.template;
  });
  return _.reduce(template.chapters, function(acc, chapter) {
    var chapterId, doc, subchapterDocs;
    chapterId = "" + MDA_TOPIC_CHAPTER_PREFIX + (generateGUID());
    subchapterDocs = chapter.subchapters ? _.map(chapter.subchapters, function(subchapter) {
      return ChapterDocShape.defaults({
        _id: "" + MDA_TOPIC_CHAPTER_PREFIX + (generateGUID()),
        parent: chapterId,
        slug: kebabCase(subchapter.title),
        title: $.t("mda:topics.templates.chapters." + subchapter.title),
        mdaTopicId: topic._id
      });
    }) : [];
    doc = ChapterDocShape.defaults({
      _id: chapterId,
      title: $.t("mda:topics.templates.chapters." + chapter.title),
      isFromAcoem: chapter.isFromAcoem || false,
      slug: kebabCase(chapter.title),
      subchapters: _.pluck(subchapterDocs, '_id'),
      mdaTopicId: topic._id
    });
    acc.rootChapterIds = acc.rootChapterIds.concat(chapterId);
    acc.docs = acc.docs.concat(doc, subchapterDocs);
    return acc;
  }, {
    rootChapterIds: [],
    docs: []
  });
};

module.exports = generateActions({
  autoGenerate: ['closeAssignMemberDialog', 'closeCreateOrEditDialog', 'fetchSuccess', 'openConfirmResetDialog', 'closeConfirmResetDialog', 'openCreateOrEditDialog'],
  name: 'MdaTopicsActions',
  actions: {
    dbChange: function(change, _pending, _lastSeq) {
      var currentTopicId, _ref10, _ref7, _ref8, _ref9;
      currentTopicId = alt.stores.MdaTopicsStore.getCurrentTopicId();
      if (change.deleted || ((_ref7 = change.doc) != null ? _ref7._id : void 0) === currentTopicId || ((_ref8 = change.doc) != null ? _ref8.docType : void 0) === MDA_TABLE_DOC_TYPE || ((_ref9 = change.doc) != null ? _ref9.type : void 0) === DOCUMENT_SECTIONS_V2_SECTION_TYPE) {
        DocumentSectionsV2Actions.dbChange(change);
      }
      QuestionsStatusesActions.dbChange(change);
      if (((_ref10 = change.doc) != null ? _ref10._id : void 0) === currentTopicId) {
        return this.dispatch(change);
      }
    },
    fetch: function(_arg) {
      var adapter, dbId, docId;
      dbId = _arg.dbId, docId = _arg.docId;
      this.dispatch();
      ReferencesActions.fetch();
      QuestionsActions.fetch({
        questionType: MDA_TABLE_DOC_TYPE
      });
      QuestionGroupsActions.fetch(MDA_TABLE_DOC_TYPE);
      QuestionsStatusesActions.fetch();
      adapter = mediator.services.storePersistenceAdapter;
      return MdaTopicDoc.at(dbId, docId).fetch().then((function(_this) {
        return function(topic) {
          var chaptersPromise, discussionsPromise, mdaTablesDiscussionsPromise, mdaTablesPromise, rootPromise;
          rootPromise = MdaTopicRootDoc.at(dbId, topic.topicRootId).fetch();
          chaptersPromise = adapter.fetchWithView(dbId, 'mda_topic_chapters', mdaChaptersView, docId);
          mdaTablesPromise = fetchTables(dbId);
          discussionsPromise = adapter.fetchWithView(dbId, 'doc_sec_v2_discussions', discussionsDbView, docId);
          mdaTablesDiscussionsPromise = fetchMdaTablesDiscussions(dbId, docId);
          return W.all([rootPromise, chaptersPromise, discussionsPromise, mdaTablesDiscussionsPromise, mdaTablesPromise]).then(function(_arg1) {
            var chapters, discussions, mdaTables, mdaTablesDiscussions, mdaTablesSections, root, _ref7;
            root = _arg1[0], chapters = _arg1[1], discussions = _arg1[2], (_ref7 = _arg1[3], mdaTablesDiscussions = _ref7.mdaTablesDiscussions, mdaTablesSections = _ref7.mdaTablesSections), mdaTables = _arg1[4];
            DocumentSectionsV2Actions.fetchSuccess({
              alreadyUsedRecommendations: [],
              chapters: chapters,
              discussions: _.union(mdaTablesDiscussions, discussions),
              mdaTables: mdaTables,
              mdaTablesSections: mdaTablesSections,
              mdaTopicId: docId,
              recommendations: [],
              root: root
            });
            return _this.actions.fetchSuccess(topic);
          });
        };
      })(this))["catch"](DocumentSectionsV2Actions.fetchFailure);
    },
    createTopic: function(topic) {
      this.dispatch();
      return MdaTopicDoc.at(mediator.project.id).create(_.extend(topic, {
        _id: "" + MDA_TOPIC_PREFIX + (generateGUID()),
        topicRootId: "" + MDA_TOPIC_ROOT_PREFIX + (generateGUID())
      })).then(function(topic) {
        return QuestionsStatusesActions.updateQuestionStatus(topic._id, 'mdaTopicsStatus', 'editSetup').then(function() {
          return topic;
        });
      }).then(this.actions.createTopicDoc)["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:topics.create_error'));
      })["finally"](this.actions.closeCreateOrEditDialog);
    },
    createTopicDoc: function(topic) {
      var chapterDocs;
      chapterDocs = createChaptersStructure(topic);
      return MdaTopicRootDoc.at(mediator.project.id).create({
        _id: topic.topicRootId,
        chaptersOrder: chapterDocs.rootChapterIds,
        mdaTopicId: topic._id
      }).then(function() {
        return ChapterDoc.at(mediator.project.id).createMany(chapterDocs.docs);
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:topics.create_topic_root_error'));
      });
    },
    resetChapterStructureAndUpdateTopic: function(topic) {
      var projectId;
      this.dispatch();
      projectId = mediator.project.id;
      return W.all([ChapterDoc.at(projectId).fetchMany(topic._id), SectionDoc.at(projectId).fetchMany(topic._id)]).then(function(_arg) {
        var chapters, docs, otherSections, sections, sectionsWithTable, _ref7;
        chapters = _arg[0], sections = _arg[1];
        _ref7 = _.partition(sections, function(_arg1) {
          var mdaTableId;
          mdaTableId = _arg1.mdaTableId;
          return !_.isEmpty(mdaTableId);
        }), sectionsWithTable = _ref7[0], otherSections = _ref7[1];
        docs = _.chain(chapters).union(otherSections).map(function(doc) {
          return {
            _id: doc._id,
            _rev: doc._rev,
            _deleted: true
          };
        }).value();
        return mediator.services.storePersistenceAdapter.bulkDocs(projectId, docs).then(function() {
          return sectionsWithTable;
        });
      }).then(function(sectionsWithTable) {
        var chapterDocs, docs, lengthOfDisabilityChapter, rootChapterIds;
        chapterDocs = createChaptersStructure(topic);
        rootChapterIds = chapterDocs.rootChapterIds, docs = chapterDocs.docs;
        lengthOfDisabilityChapter = _.find(docs, function(doc) {
          return doc.slug === 'length-of-disability';
        });
        lengthOfDisabilityChapter.sections = _.pluck(sectionsWithTable, '_id');
        _.each(sectionsWithTable, function(section) {
          return section.parent = lengthOfDisabilityChapter._id;
        });
        return W.all([MdaTopicRootDoc.at(projectId, topic.topicRootId).updateAsInstance(callMethod('setRootChaptersOrder', rootChapterIds)), ChapterDoc.at(mediator.project.id).createMany(docs), SectionDoc.at(mediator.project.id).createMany(sectionsWithTable)]);
      }).then(function() {
        return MdaTopicDoc.at(mediator.project.id, topic._id).update(_.omit(topic, '_rev'));
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:topics.topic_reset_error'));
      });
    },
    updateTopic: function(topic) {
      this.dispatch();
      return MdaTopicDoc.at(mediator.project.id, topic._id).update(topic)["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:topics.save_error'));
      })["finally"]((function(_this) {
        return function() {
          return _this.actions.closeCreateOrEditDialog();
        };
      })(this));
    },
    duplicateTopicRootAndChapters: function(duplicatedTopic) {
      var newTopicRootDocId, projectId;
      projectId = mediator.project.id;
      newTopicRootDocId = "" + MDA_TOPIC_ROOT_PREFIX + (generateGUID());
      return MdaTopicRootDoc.at(projectId, duplicatedTopic.topicRootId).fetch().then(function(topicRootDoc) {
        return ChapterDoc.at(projectId).fetch(topicRootDoc.chaptersOrder);
      }).then(function(_arg) {
        var chapterDocs, rows;
        rows = _arg.rows;
        chapterDocs = _.pluck(rows, 'doc');
        return _.map(chapterDocs, function(chapterDoc) {
          var newChapterId;
          newChapterId = "" + MDA_TOPIC_CHAPTER_PREFIX + (generateGUID());
          return ChapterDoc.at(projectId).create(_.omit(_.extend(chapterDoc, {
            _id: newChapterId,
            mdaTopicId: duplicatedTopic._id
          }), '_rev')).then(function() {
            var sectionsPromise, subchaptersPromise;
            subchaptersPromise = duplicateNestedDocs(chapterDoc, 'subchapters', newChapterId);
            sectionsPromise = duplicateNestedDocs(chapterDoc, 'sections', newChapterId);
            return W.all([subchaptersPromise, sectionsPromise]).then(function(_arg1) {
              var sections, subchapters;
              subchapters = _arg1[0], sections = _arg1[1];
              return ChapterDoc.at(projectId, newChapterId).updateAsInstance(R.compose(callMethod('setProperty', 'subchapters', _.pluck(subchapters, 'id')), callMethod('setProperty', 'sections', _.pluck(sections, 'id'))));
            });
          });
        });
      }).then(W.all).then(function(chaptersToSave) {
        var rootChapterIds;
        rootChapterIds = _.pluck(_.flatten(chaptersToSave), '_id');
        return MdaTopicRootDoc.at(projectId, duplicatedTopic.topicRootId).fetch().then(function(topicRootDoc) {
          return MdaTopicRootDoc.at(projectId, newTopicRootDocId).create(_.omit(_.extend(topicRootDoc, {
            _id: newTopicRootDocId,
            chaptersOrder: rootChapterIds,
            mdaTopicId: duplicatedTopic._id
          }), '_rev'));
        });
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:topics.duplicate_topic_root_error'));
      });
    },
    openAssignMemberDialog: function(_arg) {
      var assignedMembers, memberType, topic;
      topic = _arg.topic, memberType = _arg.memberType, assignedMembers = _arg.assignedMembers;
      if (alt.stores.MembersStore.getMembers().isEmpty()) {
        return MembersActions.fetch().then((function(_this) {
          return function() {
            return _this.dispatch({
              topic: topic,
              memberType: memberType,
              assignedMembers: assignedMembers
            });
          };
        })(this))["catch"](function(err) {
          return errorHandlerWithMsg(err, $.t('mda:topics.cannot_fetch_team_members'));
        });
      } else {
        return this.dispatch({
          topic: topic,
          memberType: memberType,
          assignedMembers: assignedMembers
        });
      }
    },
    assignMemberToTopic: function(_arg) {
      var member, memberData, memberId, memberType, modalData, topicId;
      memberId = _arg.memberId, modalData = _arg.modalData;
      member = alt.stores.MembersStore.getMember(memberId);
      memberType = modalData.get('memberType');
      topicId = modalData.getIn(['topic', '_id']);
      memberData = _.pick(member.toJS(), '_id', 'lastName', 'givenNames', 'accessRights', 'email');
      return MdaTopicDoc.at(mediator.project.id, topicId).updateAsInstance(callMethod('assignMember', memberType, memberData))["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:topics.cannot_assign_team_members'));
      })["finally"](this.actions.closeAssignMemberDialog);
    },
    unassignMember: function(_arg) {
      var memberType, topicId;
      memberType = _arg.memberType, topicId = _arg.topicId;
      return MdaTopicDoc.at(mediator.project.id, topicId).updateAsInstance(callMethod('assignMember', memberType, null))["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:topics.cannot_unassign_team_members'));
      });
    },
    claimTopic: function(_arg) {
      var currentStatus, memberType, topic, topicId;
      currentStatus = _arg.currentStatus, topic = _arg.topic;
      topicId = topic.get('_id');
      memberType = (function() {
        switch (currentStatus) {
          case 'durationReview1':
            return 'mab1';
          case 'durationReview2':
            return 'mab2';
          case 'durationReview3':
            return 'mab3';
        }
      })();
      return MembersActions.fetch().then(function(members) {
        var member, memberData;
        member = _.find(members, function(member) {
          return member.email.toLowerCase() === mediator.user.getEmail().toLowerCase();
        });
        memberData = _.pick(member, '_id', 'lastName', 'givenNames', 'accessRights', 'email');
        return MdaTopicDoc.at(mediator.project.id, topicId).updateAsInstance(callMethod('assignMember', memberType, memberData));
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:topics.cannot_assign_team_members'));
      });
    },
    sendTopicToNextStep: function(_arg) {
      var currentStatus, currentTopicId, indexStep, nextStatus;
      currentStatus = _arg.currentStatus, currentTopicId = _arg.currentTopicId;
      if (currentStatus === 'done') {
        return;
      }
      indexStep = currentStatus === 'durationReview2' ? 2 : 1;
      nextStatus = TOPIC_STATUSES[TOPIC_STATUSES.indexOf(currentStatus) + indexStep];
      return QuestionsStatusesActions.updateQuestionStatus(currentTopicId, 'mdaTopicsStatus', nextStatus);
    },
    importTopics: function(projectId, topics) {
      var rootDocsIds, tablesIds, topicsIds, _ref7;
      _ref7 = getDocIds(topics.toJS()), rootDocsIds = _ref7.rootDocsIds, tablesIds = _ref7.tablesIds, topicsIds = _ref7.topicsIds;
      return mediator.services.storePersistenceAdapter.fetch(projectId, rootDocsIds).then(function(_arg) {
        var rootDocs, rows;
        rows = _arg.rows;
        rootDocs = _.chain(rows).pluck('doc').compact().value();
        return sequence(_.map(rootDocs, function(rootDoc) {
          return function() {
            return getChapterAndSectionIds(projectId, rootDoc);
          };
        }));
      }).then(function(results) {
        var chapterIds, documentIds, sectionIds;
        chapterIds = flatPluckCompact(results, 'chapterIds');
        sectionIds = flatPluckCompact(results, 'sectionIds');
        documentIds = _.union([], rootDocsIds, tablesIds, topicsIds, chapterIds, sectionIds);
        return mediator.services.projectDb.importMdaTopic(projectId, _.uniq(documentIds));
      })["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:verification.import_topic_error'));
      });
    },
    updateCodes: function(_arg) {
      var codes, newCodes, parentId, projectId;
      parentId = _arg.parentId, codes = _arg.codes;
      projectId = mediator.project.id;
      newCodes = codes.map(function(c) {
        return Immutable.fromJS(prepareCodeWithSanitizedValue(c));
      });
      return MdaTopicDoc.at(projectId, parentId).updateAsInstance(docInstSetAt('codes')(newCodes.toJS()))["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:topics.save_error'));
      });
    },
    removeCode: function(_arg) {
      var code, parentId, projectId;
      parentId = _arg.parentId, code = _arg.code;
      projectId = mediator.project.id;
      return MdaTopicDoc.at(projectId, parentId).updateAsInstance(docInstUpdateAt('codes')(removeItemById(code.get('id'), 'id')))["catch"](function(err) {
        return errorHandlerWithMsg(err, $.t('mda:topics.save_error'));
      });
    }
  }
});
