var NmaInterventionType, arrayOf, docShape, docTypes, documentId, initial, nmaQuestionDocShape, optional, shape, string;

arrayOf = require('lib/db_docs/field_types/array_of');

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

docTypes = require('lib/db_docs/doc_types');

initial = require('lib/db_docs/field_types/type_decorators').initial;

NmaInterventionType = require('lib/db_docs/field_types/nma_intervention_type');

shape = require('lib/db_docs/field_types/shape');

string = require('lib/db_docs/field_types/built_in_types').string;

optional = shape.typeDecorators.optional;

nmaQuestionDocShape = docShape({
  author: optional(string),
  docType: initial(docTypes.NMA_QUESTION)(string),
  interventions: initial([])(arrayOf(NmaInterventionType)),
  outcomes: initial([])(arrayOf(documentId)),
  population: optional(string),
  question: string,
  setting: optional(string)
});

module.exports = nmaQuestionDocShape;
