var ChapterDoc, MdaTopicRootDoc, W, convertRowsToArray, etdHelper, flatPluckCompact, getChapterIds, mdaUtils, mediator, sequence, unfold, _ref,
  __slice = [].slice;

ChapterDoc = require('lib/db_docs/document_sections_v2_chapter_doc');

_ref = require('lib/app_utils'), convertRowsToArray = _ref.convertRowsToArray, flatPluckCompact = _ref.flatPluckCompact;

etdHelper = require('lib/etd_helper');

MdaTopicRootDoc = require('lib/db_docs/mda_topic_root_doc');

mediator = require('mediator');

sequence = require('when/sequence');

unfold = require('when/unfold');

W = require('when/when');

getChapterIds = function(_arg) {
  var chapterDocs, rows;
  rows = _arg.rows;
  chapterDocs = _.pluck(rows, 'doc');
  return W.all(_.reduce(chapterDocs, function(acc, doc) {
    return acc.concat(W.resolve([doc._id].concat(__slice.call(doc.sections), __slice.call(doc.subchapters))), ChapterDoc.at(mediator.project.id, doc._id).fetch(doc.subchapters).then(getChapterIds));
  }, []));
};

module.exports = mdaUtils = {
  HEALTH_ADVISOR_WORKSPACE_ID: 'o_health_advisor_0',
  MASTER_PROJECT_ID: 'p_mda_master',
  TOPIC_STATUSES: ['editSetup', 'medicalEditing', 'mar', 'copyEditing', 'medicalCoding', 'finalEditingCheck', 'durationSetup', 'durationReview1', 'durationReview2', 'durationReview3', 'durationEditing', 'finalDurationCheck', 'doneDR', 'done'],
  getChapterAndSectionIds: function(projectId, rootDoc) {
    var handler, predicate, seed, _ref1, _ref2;
    handler = function(_arg) {
      var chapterId, chapterIds, queue, sectionIds;
      chapterIds = _arg.chapterIds, queue = _arg.queue, sectionIds = _arg.sectionIds;
      chapterId = queue.pop();
      return mediator.services.storePersistenceAdapter.fetch(projectId, chapterId)["catch"](function(err) {
        var newSeed;
        if (err.status !== 404) {
          throw err;
        }
        newSeed = {
          chapterIds: chapterIds,
          queue: queue,
          sectionIds: sectionIds
        };
        return [newSeed, newSeed];
      }).then(function(chapter) {
        var newSeed, _ref1, _ref2, _ref3;
        chapterIds = _.union([], chapterIds, (_ref1 = chapter.subchapters) != null ? _ref1 : []);
        sectionIds = _.union([], sectionIds, (_ref2 = chapter.sections) != null ? _ref2 : []);
        queue = _.union([], queue, (_ref3 = chapter.subchapters) != null ? _ref3 : []);
        newSeed = {
          chapterIds: chapterIds,
          queue: queue,
          sectionIds: sectionIds
        };
        return [newSeed, newSeed];
      });
    };
    predicate = function(_arg) {
      var queue;
      queue = _arg.queue;
      return queue.length === 0;
    };
    seed = {
      chapterIds: Array.from((_ref1 = rootDoc.chaptersOrder) != null ? _ref1 : []),
      queue: Array.from((_ref2 = rootDoc.chaptersOrder) != null ? _ref2 : []),
      sectionIds: []
    };
    return unfold(handler, predicate, _.noop, seed);
  },
  deleteTopicRelatedDocs: function(topicRootId) {
    var projectId;
    if (_.isEmpty(topicRootId)) {
      return W.resolve([]);
    }
    projectId = mediator.project.id;
    return MdaTopicRootDoc.at(projectId, topicRootId).fetch().then(function(res) {
      return ChapterDoc.at(projectId).fetch(res.chaptersOrder);
    }).then(getChapterIds).then(function(chaptersAndSectionIds) {
      return etdHelper.removeRecommendationTable(_.unique(_.compact(_.flatten(chaptersAndSectionIds.concat(topicRootId)))));
    });
  },
  filterTopicsByRoleAndStatus: function(topics, statuses) {
    var isAdmin, userAccessRights, userEmail;
    userEmail = mediator.user.getEmail().toLowerCase();
    userAccessRights = mediator.user.getAccessRights();
    isAdmin = _.contains(mediator.user.getAccessRights(), 'admin');
    return topics.filter(function(topic) {
      var editor, editorEmail, mab1, mab1Email, mab2, mab2Email, mab3, mab3Email, mar, marEmail, status, _ref1, _ref2, _ref3, _ref4, _ref5, _ref6;
      status = statuses.getIn([topic.get('_id'), 'mdaTopicsStatus', 'status'], null);
      _ref1 = topic.get('members', Immutable.Map()).toJS(), editor = _ref1.editor, mab1 = _ref1.mab1, mab2 = _ref1.mab2, mab3 = _ref1.mab3, mar = _ref1.mar;
      marEmail = (mar != null ? (_ref2 = mar.email) != null ? _ref2.toLowerCase() : void 0 : void 0) || '';
      editorEmail = (editor != null ? (_ref3 = editor.email) != null ? _ref3.toLowerCase() : void 0 : void 0) || '';
      mab1Email = (mab1 != null ? (_ref4 = mab1.email) != null ? _ref4.toLowerCase() : void 0 : void 0) || '';
      mab2Email = (mab2 != null ? (_ref5 = mab2.email) != null ? _ref5.toLowerCase() : void 0 : void 0) || '';
      mab3Email = (mab3 != null ? (_ref6 = mab3.email) != null ? _ref6.toLowerCase() : void 0 : void 0) || '';
      return isAdmin || ((status === 'mar' && marEmail === userEmail && _.contains(userAccessRights, 'mda_mar')) || (status === 'copyEditing' && editorEmail === userEmail && _.contains(userAccessRights, 'mda_editor')) || (status === 'durationReview1' && userEmail === mab1Email) || (status === 'durationReview2' && userEmail === mab2Email) || (status === 'durationReview3' && userEmail === mab3Email) || (_.contains(userAccessRights, 'mda_mab') && ((status === 'durationReview1' && _.isEmpty(mab1Email)) || (status === 'durationReview2' && _.isEmpty(mab2Email) && mab1Email !== userEmail) || (status === 'durationReview3' && _.isEmpty(mab3Email) && (userEmail !== mab1Email && userEmail !== mab2Email)))));
    });
  },
  getDocIds: function(topicsToUse) {
    var rootDocsIds, tablesIds, topicsIds;
    topicsIds = _.chain(topicsToUse).pluck('_id').uniq().value();
    tablesIds = _.chain(topicsToUse).pluck('insertedTables').compact().flatten().uniq().value();
    rootDocsIds = _.chain(topicsToUse).pluck('topicRootId').compact().uniq().value();
    return {
      rootDocsIds: rootDocsIds,
      tablesIds: tablesIds,
      topicsIds: topicsIds
    };
  },
  fetchIdsForTopics: function(projectId, topics) {
    var rootDocsIds, tablesIds, topicsIds, _ref1;
    _ref1 = mdaUtils.getDocIds(topics), rootDocsIds = _ref1.rootDocsIds, tablesIds = _ref1.tablesIds, topicsIds = _ref1.topicsIds;
    return mediator.services.storePersistenceAdapter.fetch(projectId, rootDocsIds).then(function(_arg) {
      var rootDocs, rows;
      rows = _arg.rows;
      rootDocs = convertRowsToArray(rows);
      return sequence(_.map(rootDocs, function(rootDoc) {
        return function() {
          return mdaUtils.getChapterAndSectionIds(projectId, rootDoc);
        };
      }));
    }).then(function(results) {
      var chapterIds, sectionIds;
      chapterIds = flatPluckCompact(results, 'chapterIds');
      sectionIds = flatPluckCompact(results, 'sectionIds');
      return _.uniq(_.union([], chapterIds, rootDocsIds, sectionIds, tablesIds, topicsIds));
    });
  }
};
