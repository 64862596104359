var DocDbInterface, GdtDocFieldType, TypeValidationError, callMethod, docDbInterface, docInst, fetchManyWithKey, fetchWithView, isInstanceOf, mediator, mergeNewData, rejectWithValidationMessage, typeValidationInvariant, _ref, _ref1;

GdtDocFieldType = require('lib/db_docs/field_types/FieldType');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

docInst = require('lib/db_docs/document_instance');

_ref = require('base/lib/utils'), callMethod = _ref.callMethod, isInstanceOf = _ref.isInstanceOf;

_ref1 = require('lib/db_docs/document_instance_helpers'), typeValidationInvariant = _ref1.typeValidationInvariant, rejectWithValidationMessage = _ref1.rejectWithValidationMessage, mergeNewData = _ref1.mergeNewData;

mediator = require('mediator');

fetchManyWithKey = function(prefix) {
  return function(dbId, options) {
    return mediator.services.storePersistenceAdapter.fetchWithKeyPrefix(dbId, prefix, options);
  };
};

fetchWithView = function(viewName, viewFn) {
  return function(dbId, key) {
    return mediator.services.storePersistenceAdapter.fetchWithView(dbId, viewName, viewFn, key);
  };
};

DocDbInterface = (function() {
  function DocDbInterface(docShape, toDocInst, dbSetup, middlewares) {
    var dbView, newDocIdFn;
    this.docShape = docShape;
    this.toDocInst = toDocInst;
    this.dbSetup = dbSetup;
    this.middlewares = middlewares != null ? middlewares : {};
    this.idPrefix = dbSetup.idPrefix, this.dbId = dbSetup.dbId, dbView = dbSetup.dbView, this.docId = dbSetup.docId, newDocIdFn = dbSetup.newDocIdFn;
    this._setFetchManyStrategy(dbSetup);
  }

  DocDbInterface.prototype._setFetchManyStrategy = function(dbSetup) {
    var dbView, idPrefix;
    idPrefix = dbSetup.idPrefix, dbView = dbSetup.dbView;
    return this.fetchManyStrategy = idPrefix ? fetchManyWithKey(idPrefix) : dbView ? fetchWithView(dbView.name, dbView.view) : mediator.services.storePersistenceAdapter.fetchAllDocs;
  };

  DocDbInterface.prototype._maybeSaveDoc = function(maybeDoc) {
    var dbService;
    dbService = mediator.services.storePersistenceAdapter;
    return typeValidationInvariant(dbService.save.bind(dbService, this.dbId), rejectWithValidationMessage)(maybeDoc);
  };

  DocDbInterface.prototype._maybeSaveDocs = function(maybeValidDocs) {
    var firstFailedValidation;
    firstFailedValidation = R.find(isInstanceOf(TypeValidationError), maybeValidDocs);
    if (firstFailedValidation != null) {
      return rejectWithValidationMessage(firstFailedValidation);
    } else {
      return mediator.services.storePersistenceAdapter.bulkDocs(this.dbId, maybeValidDocs);
    }
  };

  DocDbInterface.prototype._withMiddleware = function(dbInterfaceMethodName, nextFn) {
    var methodMiddleware;
    methodMiddleware = this.middlewares[dbInterfaceMethodName];
    if (methodMiddleware != null) {
      return R.compose(nextFn, methodMiddleware);
    } else {
      return nextFn;
    }
  };

  DocDbInterface.prototype.dbSetup = function(newSetup) {
    this.dbSetup = R.mergeRight(this.dbSetup, newSetup);
    this._setFetchManyStrategy(this.dbSetup);
    return this;
  };

  DocDbInterface.prototype.fetchMany = function(optionsOrKey) {
    return this.fetchManyStrategy(this.dbId, optionsOrKey);
  };

  DocDbInterface.prototype.fetch = function(docId) {
    if (docId == null) {
      docId = this.docId;
    }
    return mediator.services.storePersistenceAdapter.fetch(this.dbId, docId);
  };

  DocDbInterface.prototype["delete"] = function() {
    var _updater;
    _updater = this._withMiddleware('delete', R.assoc('_deleted', true));
    return this.update(_updater);
  };

  DocDbInterface.prototype.create = function(doc) {
    var _create;
    if (doc == null) {
      doc = {};
    }
    _create = this._withMiddleware('create', this.docShape.defaults.bind(this.docShape));
    return this._maybeSaveDoc(_create(_.defaults(doc, {
      '_id': this.docId
    })));
  };

  DocDbInterface.prototype.createMany = function(docs) {
    var _docDefaults;
    _docDefaults = this._withMiddleware('createMany', this.docShape.defaults.bind(this.docShape));
    return this._maybeSaveDocs(docs.map(_docDefaults));
  };

  DocDbInterface.prototype.save = function(doc) {
    var _save;
    if (doc == null) {
      doc = {};
    }
    _save = this._withMiddleware('save', this.docShape.parse.bind(this.docShape));
    return this._maybeSaveDoc(_save(_.defaults(doc, {
      '_id': this.docId
    })));
  };

  DocDbInterface.prototype.saveMany = function(docs) {
    var _parseDoc;
    _parseDoc = this._withMiddleware('saveMany', this.docShape.parse.bind(this.docShape));
    return this._maybeSaveDocs(docs.map(_parseDoc));
  };

  DocDbInterface.prototype.update = function(newAttrsOrFn) {
    var updater, updaterWithMiddleware;
    updater = _.isFunction(newAttrsOrFn) ? newAttrsOrFn : mergeNewData(newAttrsOrFn);
    updaterWithMiddleware = this._withMiddleware('update', updater);
    return mediator.services.storePersistenceAdapter.updateOrCreate(this.dbId, this.docId, (function(_this) {
      return function(doc) {
        var docData, maybeValid, updatedDoc;
        docData = _.isEmpty(doc) ? _this.docShape.defaults() : doc;
        updatedDoc = updaterWithMiddleware(docData);
        if (updatedDoc == null) {
          return null;
        }
        maybeValid = _this.docShape.parse(updatedDoc);
        if (isInstanceOf(TypeValidationError, maybeValid)) {
          mediator.dialogs.error(maybeValid.message);
          return docData;
        } else {
          return maybeValid;
        }
      };
    })(this));
  };

  DocDbInterface.prototype.updateAsInstance = function(updater) {
    return this.update((function(_this) {
      return function(docData) {
        var dbInst, updatedInst;
        dbInst = _this.toDocInst(docData);
        updatedInst = updater(dbInst);
        if (updatedInst !== dbInst) {
          return updatedInst.json();
        } else {
          return null;
        }
      };
    })(this));
  };

  return DocDbInterface;

})();

docDbInterface = function(docShape, docInstProvider, middlewares) {
  if (!(docShape instanceof GdtDocFieldType)) {
    throw new Error('Document shape argument must be instance of GdtDocField');
  }
  return function(dbSetup) {
    if (_.isEmpty(dbSetup) || !('dbId' in dbSetup)) {
      throw new Error('Proper dbSetup was not provided');
    } else {
      return new DocDbInterface(docShape, docInstProvider, dbSetup, middlewares);
    }
  };
};

module.exports = docDbInterface;
