var AddImplementationConsiderationsOrRemarskToGPSTemplatesDef, Migration, OverarchingQuestionDoc, W, mediator, migrationName, updateTemplateData,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

OverarchingQuestionDoc = require('lib/db_docs/overarching_question_doc');

W = require('when');

migrationName = 'add_description_to_multirecommendation_field';

updateTemplateData = function(templateData) {
  return R.assocPath(['conclusions', 'sections', 'multipleRecommendations', 'description'], 'Recommendation(s)', templateData);
};

module.exports = AddImplementationConsiderationsOrRemarskToGPSTemplatesDef = (function(_super) {
  __extends(AddImplementationConsiderationsOrRemarskToGPSTemplatesDef, _super);

  function AddImplementationConsiderationsOrRemarskToGPSTemplatesDef() {
    AddImplementationConsiderationsOrRemarskToGPSTemplatesDef.__super__.constructor.call(this, false);
  }

  AddImplementationConsiderationsOrRemarskToGPSTemplatesDef.prototype.up = function(project, colls) {
    var adapter, projectId;
    AddImplementationConsiderationsOrRemarskToGPSTemplatesDef.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return OverarchingQuestionDoc.at(projectId).fetchMany()["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
      return [];
    }).then(function(res) {
      return _.chain(res).reduce(function(docIds, q) {
        var _ref;
        return docIds.concat((_ref = q.recommendationIds) != null ? _ref : []);
      }, []).concat(['etd-overarchingTx-template']).compact().value();
    }).then(function(docIds) {
      return adapter.fetch(projectId, docIds);
    }).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return W.resolve();
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        if (doc.templateDef.id.indexOf('overarching') === -1) {
          return doc;
        }
        doc.templateData = updateTemplateData(doc.templateData);
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return AddImplementationConsiderationsOrRemarskToGPSTemplatesDef;

})(Migration);
