Discussions = require 'components/document_sections/v2/discussions'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
{ exportToWord, recordRevision, exportDiff } = require 'lib/word_export/document_sections_export'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
SidebarChapters = require 'components/document_sections/v2/sidebar_chapters'
SidebarMenu = require 'components/document_sections/v2/sidebar_menu'
Switcher = require 'components/common/switcher'
Translation = require 'components/mixins/translation'
DropdownMenu = require 'components/common/dropdown_menu'

MENU_ITEMS =
  CHAPTERS:
    key: 'CHAPTERS'
    icon: 'chapters'
  COMMENTS:
    key: 'COMMENTS'
    icon: 'comment'
  SUGGESTIONS:
    key: 'SUGGESTIONS'
    icon: 'suggestion'
# TODO: Uncomment when search is implemented.
#  SEARCH:
#    key: 'SEARCH'
#    icon: 'search'

sidebarMenuItems = _.values(MENU_ITEMS)

Sidebar = createReactClass
  displayName: 'Sidebar'

  mixins: [
    Translation('docsec:management')
  ]

  propTypes:
    activeDiscussionItem: PropTypes.oneOfType [
      PropTypes.string
      PropTypes.arrayOf PropTypes.string
    ]
    activeTab: PropTypes.string.isRequired
    chapters: PropTypes.instanceOf(Immutable.Map).isRequired
    chaptersOrder: PropTypes.instanceOf(Immutable.List).isRequired
    discussions: PropTypes.instanceOf(Immutable.List).isRequired
    currentDiscussionsFilters: PropTypes.instanceOf(Immutable.Map).isRequired
    editingDiscussionItems: PropTypes.instanceOf(Immutable.Map).isRequired
    editingDiscussionReplies: PropTypes.instanceOf(Immutable.Map).isRequired
    mdaTopicId: PropTypes.string
    suggesting: PropTypes.bool.isRequired

  getInitialState: ->
    contentVisible: true

  toggleContentVisible: () ->
    @setState
      contentVisible: !@state.contentVisible

  toggleSuggesting: () ->
    DocumentSectionsV2Actions.setSuggesting not @props.suggesting

  handleMenuItemSelect: (tabId) ->
    DocumentSectionsV2Actions.setActiveSidebarTab tabId

  shouldShowSuggestingSwitch: ->
    not mediator.user.hasRole(['mda_mab', 'mda_mar', 'mdg_panel_reviewer', 'evolent_panel_reviewer'])

  onManageRevisionsMenuSelect: (option) ->
    switch option
      when 'record-revision'
        recordRevision()
      when 'export-diff'
        exportDiff()

  render: ->
    {
      activeDiscussionItem,
      activeTab,
      chapters,
      chaptersOrder,
      discussions,
      currentDiscussionsFilters,
      editingDiscussionItems,
      editingDiscussionReplies,
      mdaTopicId
      suggesting,
    } = @props
    { contentVisible } = @state

    <div className="sidebar flex-none overflow-auto">
      <SidebarMenu
        contentVisible={contentVisible}
        items={sidebarMenuItems}
        onContentVisibleChange={@toggleContentVisible}
        onItemChange={@handleMenuItemSelect}
        selectedItemKey={activeTab}
      />
      {contentVisible && (
        <div className="sidebar-content flex flex-col">
          <div className="sidebar-content-top flex-none">
            <div className="suggesting-container">
              {@shouldShowSuggestingSwitch() and <React.Fragment>
                <span className="suggesting">{@i18n 'suggesting'}</span>
                <Switcher checked={suggesting} onChange={@toggleSuggesting}/>
              </React.Fragment>}
            </div>
            <div>
              {mediator.services.switches.isServerSwitchOn('evolentFeatures') &&
                <DropdownMenu
                  opener={(
                    <IconButton
                      iconName='clock'
                      title={@i18n 'manage_revisions'}
                      className="mr-10"
                    />
                  )}
                  menuPosition={alignment: 'end'}
                  options={[
                    className: 'record-revision'
                    text: @i18n 'record_revision'
                    value: 'record-revision'
                  ,
                    className: 'export-diff'
                    text: @i18n 'export_diff'
                    value: 'export-diff'
                  ]}
                  onSelect={@onManageRevisionsMenuSelect}
                />
              }
              <IconButton
                iconName='export'
                onClick={exportToWord}
                title={@i18n '../export'}
              />
            </div>
          </div>
          <hr className="flex-none my-10" />
          <div className="sidebar-content-main flex-1 overflow-auto">
            {activeTab is MENU_ITEMS.CHAPTERS.key && (
              <SidebarChapters
                chapters={chapters}
                chaptersOrder={chaptersOrder}
                mdaTopicId={mdaTopicId}
              />
            )}
            {activeTab in [MENU_ITEMS.COMMENTS.key, MENU_ITEMS.SUGGESTIONS.key] && (
              <Discussions
                activeTab={activeTab}
                activeDiscussionItem={activeDiscussionItem}
                discussions={discussions}
                currentDiscussionsFilters={currentDiscussionsFilters}
                editingDiscussionItems={editingDiscussionItems}
                editingDiscussionReplies={editingDiscussionReplies}
                mdaTopicId={mdaTopicId}
              />
            )}
          </div>
        </div>
      )}
    </div>

module.exports = Sidebar
