Translation = require 'components/mixins/translation'


DontShowAgainCheckbox = createReactClass
  displayName: 'DontShowAgainCheckbox'

  mixins: [ Translation() ]

  propTypes:
    onChange: PropTypes.func.isRequired
    checked: PropTypes.bool.isRequired

  render: ->
    <label className='dont-show-again'>
      <input type='checkbox' checked={@props.checked} onChange={@props.onChange} />
      <span>{@i18n '/actions.dont_show_again'}</span>
    </label>

module.exports = DontShowAgainCheckbox
