Button = require 'components/common/button'
Translation = require 'components/mixins/translation'

SendCopyButton = createReactClass
  displayName: 'SendCopyButton'

  mixins: [Translation()]

  propTypes:
    onClick: PropTypes.func.isRequired
    title: PropTypes.string

  render: ->
    title = @props.title ? @i18n 'actions.send_copy'
    classes = classNames 'send-copy', @props.className
    <Button {...@props} className={classes} title={title} />

module.exports = SendCopyButton
