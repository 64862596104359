CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ConnectStores = require 'components/enhancers/connect_store'
EtdActions = require 'actions/etd_actions'
EtdStore = require 'stores/etd_store'
EtdsStore = require 'stores/etds_store'
mediator = require 'mediator'
QuestionsHelper = require 'lib/questions_helper'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
RecommendationMetadata = require 'components/etd/recommendation_metadata'
RelatedRecommendation = require 'components/etd/related_recommendation'
RelatedRecommendationsModal = require 'components/etd/related_recommendations_modal'
Translation = require 'components/mixins/translation'
{ getRelatedRecommendations } = require 'lib/etds_helper'
{ instanceOf, string, bool } = PropTypes

storeConnectors =
  EtdStore: (Store) ->
    relatedQuestionsIds: Store.getRelatedQuestionsIds()
    hiddenRelatedQuestions: Store.getHiddenRelatedQuestions()
  EtdsStore: (Store) ->
    etds: Store.getEtds()
  QuestionsStore: (Store) ->
    questions: Store.getQuestionsWithEtd()
  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups()

RelatedRecommendations = createReactClass

  displayName: "RelatedRecommendations"
  mixins: [
    CustomRenderMixin,
    Translation('')
  ]

  propTypes:
    relatedQuestionsIds: instanceOf(Immutable.List).isRequired
    hiddenRelatedQuestions: instanceOf(Immutable.Map).isRequired
    questions: instanceOf(Immutable.Map).isRequired
    questionGroups: instanceOf(Immutable.Map).isRequired
    renderMode: string
    editable: bool

  getInitialState: ->
    showRRModal: false

  updateRelatedQuestionsIds: (relatedQuestionsIds) ->
    EtdActions.updateRelatedQuestionsIds relatedQuestionsIds
    @setState showRRModal: false

  toggleRelatedQuestion: (relatedQuestionId) -> (e) ->
    e.stopPropagation() # stopping propagation so it won't open edit modal window in panelvoice
    EtdActions.toggleRelatedQuestion relatedQuestionId

  openRRModal: ->
    @setState showRRModal: true

  closeRRModal: ->
    @setState showRRModal: false

  render: ->
    {
      editable
      etds
      hiddenRelatedQuestions
      questions
      questionGroups
      relatedQuestionsIds
      renderMode
    } = @props

    buttonLabel = if relatedQuestionsIds.isEmpty()
      'actions.add_related_recommendations'
    else
      'actions.edit_related_recommendations'

    relatedQuestions = QuestionsHelper.getRelatedQuestions questionGroups, questions,
      relatedQuestionsIds
    relatedRecommendations = getRelatedRecommendations(etds, relatedQuestions)

    <div className="related-recommendations">
      {renderMode isnt 'printout' and
        <div>
          <button className="btn btn-add" onClick={@openRRModal} disabled={not editable}>
            {@i18n buttonLabel}
          </button>
          <RecommendationMetadata editable={editable} />
          {@state.showRRModal and
            <RelatedRecommendationsModal
              questions={questions}
              questionGroups={questionGroups}
              relatedQuestionsIds={relatedQuestionsIds}
              updateRelatedQuestionsIds={@updateRelatedQuestionsIds}
              onClose={@closeRRModal}
            />
          }
        </div>
      }
      <div className="clearfix" />
      {not relatedQuestions.isEmpty() and
        <div className="related-recommendations__caption conclusions-section-title">
          <h2>{@i18n 'es:recommendations.related_recommendations'}</h2>
        </div>
      }
      {relatedQuestions.mapEntries ([questionId, question], index) =>
        rrList = relatedRecommendations.get(questionId, Immutable.Map()).map (etd, etdId) =>
          <RelatedRecommendation
            index={index}
            renderMode={renderMode}
            key={etdId}
            question={question}
            onToggle={@toggleRelatedQuestion(questionId)}
            expanded={hiddenRelatedQuestions.get(questionId, false) or renderMode is 'printout'}
            relatedRecommendation={etd}
          />
        [questionId, rrList.toList()]
      .toList()
      }
    </div>

module.exports = ConnectStores RelatedRecommendations,
  [EtdStore, EtdsStore, QuestionsStore, QuestionGroupsStore], storeConnectors
