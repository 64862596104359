var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "      <div>\n        <input id=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\" class=\"amount-option\" type=\"radio\" name=\"amount\" value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\">\n        <label for=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\">"
    + escapeExpression(lambda(depth0, depth0))
    + " $</label>\n      </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <label id=\"dont-show-again\">\n        <input type=\"checkbox\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.skipDonationDialog : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:welcome.new_user.dont_show_again", {"name":"i18n","hash":{},"data":data})))
    + "\n      </label>\n";
},"4":function(depth0,helpers,partials,data) {
  return "checked";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div>\n  <div class=\"caption\">\n    ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "donation.donation_text", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  </div>\n  <h4>\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "donation.select_amount", {"name":"i18n","hash":{},"data":data})))
    + "\n  </h4>\n  <form target=\"_blank\" id=\"paypal-donate\" action=\"https://www.paypal.com/cgi-bin/webscr\" method=\"post\">\n    <input type=\"hidden\" name=\"business\" value=\"paypal@evidenceprime.com\" />\n    <input type=\"hidden\" name=\"cmd\" value=\"_donations\" />\n    <input type=\"hidden\" name=\"currency_code\" value=\"USD\" />\n    <div class=\"amount-options\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.donateAmountOptions : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      <div>\n        <input id=\"other\" class=\"amount-option\" type=\"radio\" name=\"amount\" value=\"\">\n        <label for=\"other\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "donation.other_amount", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      </div>\n    </div>\n    <div class=\"buttons-block row mt-20\">\n      <div class=\"text-center mt-10\">\n        <button form=\"paypal-donate\" class=\"btn donate\" disabled>\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "donation.donate", {"name":"i18n","hash":{},"data":data})))
    + "\n        </button>\n      </div>\n    </div>\n  </form>\n  <div class=\"mt-20\">\n    <div class=\"col-6 text-left\">\n      <a class=\"other-donations\" href=\"https://gradepro.org/donate\" target=\"_blank\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "donation.other_ways", {"name":"i18n","hash":{},"data":data})))
    + "\n      </a>\n    </div>\n    <div class=\"col-6 text-right\">\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.explicitlyTriggered : depth0), {"name":"unless","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}