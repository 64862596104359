var DocumentSectionsToolbarView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/document_sections_toolbar');

module.exports = DocumentSectionsToolbarView = (function(_super) {
  __extends(DocumentSectionsToolbarView, _super);

  function DocumentSectionsToolbarView() {
    return DocumentSectionsToolbarView.__super__.constructor.apply(this, arguments);
  }

  DocumentSectionsToolbarView.prototype.template = template;

  DocumentSectionsToolbarView.prototype.container = '#toolbar-container';

  DocumentSectionsToolbarView.prototype.autoRender = true;

  DocumentSectionsToolbarView.prototype.shortcuts = {
    'ctrl+shift+1': 'toggleSecretButton'
  };

  DocumentSectionsToolbarView.prototype.initialize = function() {
    DocumentSectionsToolbarView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.save', this.saveDocument);
    return this.delegate('click', 'button.edit', this.resetDocument);
  };

  DocumentSectionsToolbarView.prototype.saveDocument = function() {
    this.model.set('documentSections', $(this.options.frameContentSelector).contents().find('#editor').html());
    return this.model.save();
  };

  DocumentSectionsToolbarView.prototype.toggleSecretButton = function() {
    return this.$('button.edit').toggle();
  };

  DocumentSectionsToolbarView.prototype.resetDocument = function() {
    return $(this.options.frameContentSelector).contents().find('#editor').html(this.options.startingContent);
  };

  return DocumentSectionsToolbarView;

})(View);
