var AbsoluteEffectCell, ControlCell, DefaultQualityModifierCell, DesignStudiesCell, DichAbsEffectNodes, DichCaseControlNodes, ExtendedControlCell, Header, ImportanceCell, InterventionCell, NoOfStudiesCell, Node, OtherConsiderationsCell, OverReviewGradeTable, RelativeEffectCell, TN, TableItem, absEffectCellSpec, absoluteEffectValidation, callMethod, caseControlStudiesDichOutcome, caseControlValues, caseReportsSeriesOutcome, caseReportsSeriesProviders, cellComponentProvider, cellContentProvider, cellSpecs, classNameProvider, combineProviders, concatNodes, contOutcome, contentPropProvider, continuousOutcome, defaultCellProvider, defaultCellSpec, defaultPropsProvider, dichControlsNode, dichOutcome, disabledPropProvider, editMetadataProvider, emptyContentPropProvider, extendedControlCellValidation, getCheckedControls, headerCellAttrsProvider, headerCellContentProvider, headerCellProvider, headerCellSpec, headerCellStyleProvider, interventionValidation, mappedOutcomeToTableFragment, narrativeOutcome, noOfStudiesValidation, notMeasureNotReportedValues, notMeasuredNotReportedOutcome, notPooledContOutcome, notPooledDichOutcome, oneOfProviders, oneOfStrategies, outcomeAttrsProvider, propsProviderUtils, provider, qualityModifierCellSpec, questionIdProvider, relativeEffectValidation, riskCellSpec, sourceIdProvider, styleAndClassNameProvider, styleProvider, tableFragment, validatedCellProvider, withValidation, _ref, _ref1, _ref2,
  __slice = [].slice,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

callMethod = require('base/lib/utils').callMethod;

TN = require('lib/gdt_tables/gdt_table_node');

withValidation = require('components/enhancers/with_validation');

AbsoluteEffectCell = require('components/evidence_syntheses/outcomes/cells/absolute_effect_cell');

absoluteEffectValidation = require('components/evidence_syntheses/outcomes/cell_validations/absolute_effect');

ExtendedControlCell = require('components/evidence_syntheses/outcomes/cells/control_cell_extended');

extendedControlCellValidation = require('components/evidence_syntheses/outcomes/cell_validations/extended_control');

NoOfStudiesCell = require('components/evidence_syntheses/outcomes/cells/no_of_studies_cell');

noOfStudiesValidation = require('components/evidence_syntheses/outcomes/cell_validations/no_of_studies');

DesignStudiesCell = require('components/evidence_syntheses/outcomes/cells/design_studies_cell');

RelativeEffectCell = require('components/evidence_syntheses/outcomes/cells/relative_effect_cell');

relativeEffectValidation = require('components/evidence_syntheses/outcomes/cell_validations/relative_effect');

ImportanceCell = require('components/evidence_syntheses/outcomes/cells/importance_cell');

DefaultQualityModifierCell = require('components/evidence_syntheses/outcomes/cells/default_quality_modifier_cell');

OtherConsiderationsCell = require('components/evidence_syntheses/outcomes/cells/other_considerations_cell');

InterventionCell = require('components/evidence_syntheses/outcomes/cells/intervention_cell');

interventionValidation = require('components/evidence_syntheses/outcomes/cell_validations/intervention');

ControlCell = require('components/evidence_syntheses/outcomes/cells/control_cell');

getCheckedControls = require('lib/gdt_tables/outcome_table_description_helpers').getCheckedControls;

_ref = require('lib/gdt_tables/cell_props_provider'), provider = _ref.provider, propsProviderUtils = _ref.utils;

classNameProvider = require('lib/gdt_tables/props_providers/classname_provider');

styleProvider = require('lib/gdt_tables/props_providers/outcome_cell_style_provider');

editMetadataProvider = require('lib/gdt_tables/props_providers/outcome_cell_edit_metadata_provider');

outcomeAttrsProvider = require('lib/gdt_tables/props_providers/outcome_attrs_provider');

cellContentProvider = require('lib/gdt_tables/props_providers/outcome_cell_content_provider');

_ref1 = require('lib/gdt_tables/match_strategies/outcomes_table_match_strategies'), notMeasuredNotReportedOutcome = _ref1.notMeasuredNotReportedOutcome, caseControlStudiesDichOutcome = _ref1.caseControlStudiesDichOutcome, caseReportsSeriesOutcome = _ref1.caseReportsSeriesOutcome, continuousOutcome = _ref1.continuousOutcome, notPooledDichOutcome = _ref1.notPooledDichOutcome, notPooledContOutcome = _ref1.notPooledContOutcome;

oneOfStrategies = require('lib/gdt_tables/cell_match_strategies').oneOfStrategies;

_ref2 = require('lib/gdt_tables/cell_component_provider'), cellComponentProvider = _ref2.cellComponentProvider, defaultCellProvider = _ref2.defaultCellProvider, headerCellProvider = _ref2.headerCellProvider;

oneOfProviders = propsProviderUtils.oneOfProviders, combineProviders = propsProviderUtils.combineProviders;

concatNodes = TN.concatNodes;

tableFragment = TN.bind(TN, null, null);

validatedCellProvider = R.compose(cellComponentProvider, withValidation);

caseControlValues = ['case_control_studies', 'case_control_and_other_combined'];

notMeasureNotReportedValues = ['not_measured', 'not_reported'];

Node = function() {
  var Component, cellId, cellSpec, children, data, props;
  cellId = arguments[0], data = arguments[1], children = 3 <= arguments.length ? __slice.call(arguments, 2) : [];
  if (data == null) {
    data = {};
  }
  cellSpec = cellSpecs[cellId];
  props = cellSpec.propsProvider(cellId, data);
  Component = cellSpec.componentProvider(cellId, data);
  return TN.apply(TN, [
    cellId, R.mergeRight({
      Component: Component
    }, props)
  ].concat(children));
};

Header = function(_items, additionalData) {
  return tableFragment(Node('th_source', additionalData), Node('th_noOfStudies', additionalData), Node('th_studyDesign', additionalData), Node('th_riskOfBias', additionalData), Node('th_inconsistency', additionalData), Node('th_indirectness', additionalData), Node('th_imprecision', additionalData), Node('th_otherConsiderations', additionalData), Node('th_noOfPatients', null, Node('th_intervention', additionalData), Node('th_control', additionalData)), Node('th_effect', null, Node('th_relativeEffect', additionalData), Node('th_absoluteEffect', additionalData)), Node('th_certainty', additionalData), Node('th_importance', additionalData)).toRowsList();
};

dichControlsNode = function(controls, data) {
  return concatNodes(_.map(controls, function(_, controlKey) {
    return Node("" + controlKey + "Risk", data);
  }));
};

DichCaseControlNodes = function(checkedControls, data) {
  var controlsNode, designStudy, interventionsNode, metaType, _ref3, _ref4;
  designStudy = (_ref3 = data.designStudies) != null ? _ref3.value : void 0;
  metaType = (_ref4 = data.metaType) != null ? _ref4.toLowerCase() : void 0;
  if (__indexOf.call(notMeasureNotReportedValues, metaType) >= 0) {
    return [Node('caseControl', data), Node('intervention', data)];
  }
  controlsNode = dichControlsNode(checkedControls, data);
  if (__indexOf.call(caseControlValues, designStudy) >= 0) {
    interventionsNode = concatNodes(_.map(checkedControls, function(_, _key) {
      return Node('intervention', data);
    }));
    return Node('caseControl', null, interventionsNode, controlsNode);
  } else {
    return [Node('intervention', data), controlsNode];
  }
};

DichAbsEffectNodes = function(checkedControls, data) {
  var absEffectNodes, designStudy, metaType, _ref3, _ref4;
  designStudy = (_ref3 = data.designStudies) != null ? _ref3.value : void 0;
  metaType = (_ref4 = data.metaType) != null ? _ref4.toLowerCase() : void 0;
  if (__indexOf.call(notMeasureNotReportedValues, metaType) >= 0) {
    return Node('absoluteEffect');
  }
  absEffectNodes = concatNodes(_.map(checkedControls, function(_, controlKey) {
    return Node("" + controlKey + "AbsoluteEffect", data);
  }));
  if (__indexOf.call(caseControlValues, designStudy) >= 0) {
    return Node('absoluteEffect', null, absEffectNodes);
  } else {
    return absEffectNodes;
  }
};

dichOutcome = function(data) {
  var checkedControls;
  checkedControls = getCheckedControls(data);
  if (_.isEmpty(checkedControls)) {
    checkedControls = {
      calculatedControl: true
    };
  }
  return tableFragment(Node('source', data), Node('noOfStudies', data), Node('designStudies', data), Node('riskOfBias', data), Node('inconsistency', data), Node('indirectness', data), Node('imprecision', data), Node('otherConsiderations', data), DichCaseControlNodes(checkedControls, data), Node('relativeEffect', data), DichAbsEffectNodes(checkedControls, data), Node('quality', data), Node('importance', data));
};

contOutcome = function(data) {
  return tableFragment(Node('source', data), Node('noOfStudies', data), Node('designStudies', data), Node('riskOfBias', data), Node('inconsistency', data), Node('indirectness', data), Node('imprecision', data), Node('otherConsiderations', data), Node('intervention', data), Node('control', data), Node('relativeEffect', data), Node('absoluteEffect', data), Node('quality', data), Node('importance', data));
};

narrativeOutcome = function(data) {
  return tableFragment(Node('source', data), Node('noOfStudies', data), Node('designStudies', data), Node('riskOfBias', data), Node('inconsistency', data), Node('indirectness', data), Node('imprecision', data), Node('otherConsiderations', data), Node('narrativeDesc', R.mergeRight(data, {
    colSpan: 4
  })), Node('quality', data), Node('importance', data));
};

mappedOutcomeToTableFragment = function(additionalData) {
  return function(outcomeAttrs) {
    var data, _ref3;
    data = R.mergeRight(outcomeAttrs, additionalData);
    switch ((_ref3 = outcomeAttrs.type) != null ? _ref3 : 'dich') {
      case 'dich':
        return dichOutcome(data);
      case 'cont':
        return contOutcome(data);
      case 'narrative':
        return narrativeOutcome(data);
    }
  };
};

TableItem = function(additionalData) {
  return function(_arg) {
    var labelRow, mappedOutcomes, mappedOutcomesRows, name;
    name = _arg.name, mappedOutcomes = _arg.mappedOutcomes;
    labelRow = tableFragment(Node('reviewedOutcomeName', {
      name: name
    })).toRowsList();
    mappedOutcomesRows = R.chain(R.compose(callMethod('toRowsList'), mappedOutcomeToTableFragment(additionalData)), mappedOutcomes);
    return R.concat(labelRow, mappedOutcomesRows);
  };
};

contentPropProvider = provider.bind(null, 'content');

disabledPropProvider = provider.bind(null, 'disabled', R.T);

emptyContentPropProvider = provider.bind(null, 'content', R.always('-'));

headerCellContentProvider = contentPropProvider(function(cellId, data) {
  var cellIdClean, cellLabel;
  cellIdClean = cellId.replace('th_', '');
  if (cellId === 'th_intervention' || cellId === 'th_control') {
    return data.question[cellIdClean];
  } else {
    cellLabel = $.t("es:outcome." + (_.str.underscored(cellIdClean)));
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: cellLabel
      }
    });
  }
});

questionIdProvider = provider('questionId', function(_cellId, data) {
  return data.questionId;
});

sourceIdProvider = provider('sourceId', function(_cellId, data) {
  return data.sourceId;
});

headerCellStyleProvider = function(cellId, data) {
  var cellIdClean;
  cellIdClean = cellId.replace('th_', '');
  return styleProvider(cellIdClean, data);
};

styleAndClassNameProvider = combineProviders(styleProvider, classNameProvider);

defaultPropsProvider = combineProviders(disabledPropProvider(notMeasuredNotReportedOutcome), oneOfProviders(emptyContentPropProvider(notMeasuredNotReportedOutcome), cellContentProvider), styleAndClassNameProvider, outcomeAttrsProvider, editMetadataProvider, questionIdProvider, sourceIdProvider);

headerCellAttrsProvider = combineProviders(headerCellStyleProvider, headerCellContentProvider);

caseReportsSeriesProviders = combineProviders(defaultPropsProvider, disabledPropProvider(caseReportsSeriesOutcome), emptyContentPropProvider(caseReportsSeriesOutcome));

headerCellSpec = {
  componentProvider: headerCellProvider,
  propsProvider: headerCellAttrsProvider
};

defaultCellSpec = {
  componentProvider: defaultCellProvider,
  propsProvider: defaultPropsProvider
};

riskCellSpec = {
  componentProvider: validatedCellProvider(extendedControlCellValidation, ExtendedControlCell),
  propsProvider: caseReportsSeriesProviders
};

absEffectCellSpec = {
  componentProvider: validatedCellProvider(absoluteEffectValidation, AbsoluteEffectCell),
  propsProvider: caseReportsSeriesProviders
};

qualityModifierCellSpec = function(modifierName) {
  return {
    componentProvider: cellComponentProvider(DefaultQualityModifierCell(modifierName)),
    propsProvider: defaultPropsProvider
  };
};

cellSpecs = {
  th_source: headerCellSpec,
  th_noOfStudies: headerCellSpec,
  th_studyDesign: headerCellSpec,
  th_riskOfBias: headerCellSpec,
  th_inconsistency: headerCellSpec,
  th_indirectness: headerCellSpec,
  th_imprecision: headerCellSpec,
  th_otherConsiderations: headerCellSpec,
  th_noOfPatients: headerCellSpec,
  th_intervention: headerCellSpec,
  th_control: headerCellSpec,
  th_effect: headerCellSpec,
  th_relativeEffect: headerCellSpec,
  th_absoluteEffect: headerCellSpec,
  th_certainty: headerCellSpec,
  th_importance: headerCellSpec,
  reviewedOutcomeName: {
    componentProvider: defaultCellProvider,
    propsProvider: combineProviders(contentPropProvider(function(_cellId, data) {
      return data.name;
    }), provider('colSpan', R.always(14)), provider('className', R.always('outcome-name')))
  },
  source: defaultCellSpec,
  noOfStudies: {
    componentProvider: validatedCellProvider(noOfStudiesValidation, NoOfStudiesCell),
    propsProvider: defaultPropsProvider
  },
  designStudies: {
    componentProvider: cellComponentProvider(DesignStudiesCell),
    propsProvider: defaultPropsProvider
  },
  riskOfBias: qualityModifierCellSpec('riskOfBias'),
  inconsistency: qualityModifierCellSpec('inconsistency'),
  indirectness: qualityModifierCellSpec('indirectness'),
  imprecision: qualityModifierCellSpec('imprecision'),
  otherConsiderations: {
    componentProvider: cellComponentProvider(OtherConsiderationsCell),
    propsProvider: defaultPropsProvider
  },
  intervention: {
    componentProvider: validatedCellProvider(interventionValidation, InterventionCell),
    propsProvider: combineProviders(defaultPropsProvider, disabledPropProvider(caseControlStudiesDichOutcome), emptyContentPropProvider(caseReportsSeriesOutcome))
  },
  control: {
    componentProvider: validatedCellProvider(extendedControlCellValidation, ControlCell),
    propsProvider: caseReportsSeriesProviders
  },
  relativeEffect: {
    componentProvider: validatedCellProvider(relativeEffectValidation, RelativeEffectCell),
    propsProvider: combineProviders(defaultPropsProvider, disabledPropProvider(oneOfStrategies(continuousOutcome, notPooledDichOutcome, caseReportsSeriesOutcome)), oneOfProviders(contentPropProvider(_.constant($.t('es:outcome.not_pooled')), notPooledDichOutcome), emptyContentPropProvider(caseReportsSeriesOutcome)))
  },
  absoluteEffect: {
    componentProvider: validatedCellProvider(absoluteEffectValidation, AbsoluteEffectCell),
    propsProvider: combineProviders(defaultPropsProvider, disabledPropProvider(oneOfStrategies(notPooledContOutcome, caseReportsSeriesOutcome)), oneOfProviders(contentPropProvider(_.constant($.t('es:outcome.not_pooled')), notPooledContOutcome), emptyContentPropProvider(caseReportsSeriesOutcome)))
  },
  quality: defaultCellSpec,
  importance: {
    componentProvider: cellComponentProvider(ImportanceCell),
    propsProvider: defaultPropsProvider
  },
  narrativeDesc: {
    componentProvider: defaultCellProvider,
    propsProvider: combineProviders(cellContentProvider, classNameProvider, styleProvider, provider('colSpan', R.always(4)))
  },
  caseControl: defaultCellSpec,
  calculatedControlRisk: riskCellSpec,
  lowControlRisk: riskCellSpec,
  moderateControlRisk: riskCellSpec,
  highControlRisk: riskCellSpec,
  calculatedControlAbsoluteEffect: absEffectCellSpec,
  lowControlAbsoluteEffect: absEffectCellSpec,
  moderateControlAbsoluteEffect: absEffectCellSpec,
  highControlAbsoluteEffect: absEffectCellSpec
};

OverReviewGradeTable = function(itemsList, additionalData) {
  if (itemsList == null) {
    itemsList = [];
  }
  if (additionalData == null) {
    additionalData = {};
  }
  return {
    header: Header(itemsList, additionalData),
    body: R.map(TableItem(additionalData), itemsList)
  };
};

module.exports = OverReviewGradeTable;
