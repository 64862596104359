var EtdAsync, MembersActions, OverarchingQuestionsActions, PanelVoiceActions, PanelVoiceApi, QuestionGroupsActions, QuestionsActions, QuestionsListComponentActions, alt, etdHighlightsPrefix, getVotingMetadocId, mediator, panelVoicePreferencesDoc, panelVoiceResultsPrefix, _ref;

alt = require('alt');

mediator = require('mediator');

PanelVoiceApi = require('actions/async/panel_voice_api');

EtdAsync = require('actions/async/etd');

OverarchingQuestionsActions = require('actions/overarching_questions_actions');

QuestionsActions = require('actions/questions_actions');

QuestionGroupsActions = require('actions/question_groups_actions');

MembersActions = require('actions/members_actions');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

_ref = require('lib/doc_prefixes'), panelVoiceResultsPrefix = _ref.panelVoiceResults, etdHighlightsPrefix = _ref.etdHighlights;

panelVoicePreferencesDoc = function(projectId) {
  return "" + projectId + ":panel-voice-preferences";
};

getVotingMetadocId = function(projectId) {
  return "" + projectId + ":panel-voice-metadoc";
};

PanelVoiceActions = (function() {
  function PanelVoiceActions() {
    this.generateActions('fetchMetadataSuccess', 'fetchMetadataError', 'changeStatusFilter', 'search', 'resetSearch', 'fetchVotingResultsSuccess', 'fetchVotingResultsError', 'fetchPreferencesSuccess', 'fetchPreferencesError', 'fetchEtdSnapshotsSuccess', 'fetchEtdSnapshotsError', 'fetchEtdHighlightDocsSuccess', 'fetchEtdHighlightDocsError', 'sendVotingSuccess', 'sendVotingError', 'closeVotingSuccess', 'closeVotingError', 'sendRemindersSuccess', 'sendRemindersError', 'closeSendVotingModal', 'updateSendVoteDetails', 'resetMetadata', 'updateResultsWithResponse', 'updateEtdHighlights', 'updatePanelVoicePreferencesError', 'showRemindersModal', 'hideRemindersModal', 'showFeedbackStatusDialog', 'hideFeedbackStatusDialog', 'showAddManualResultsModal', 'hideAddManualResultsModal', 'addVotingMessageAttachment', 'removeVotingMessageAttachment', 'fetchUserDataSuccess', 'fetchUserDataError');
  }

  PanelVoiceActions.prototype.dbChange = function(_arg) {
    var doc, docId, projectId;
    doc = _arg.doc;
    docId = doc._id;
    projectId = mediator.project.id;
    if (docId === getVotingMetadocId(projectId)) {
      return this.actions.resetMetadata(doc);
    } else if (_.str.startsWith(docId, panelVoiceResultsPrefix(projectId))) {
      return this.actions.updateResultsWithResponse(doc);
    } else if (_.str.startsWith(docId, etdHighlightsPrefix(projectId))) {
      return this.actions.updateEtdHighlights(doc);
    }
  };

  PanelVoiceActions.prototype.fetch = function() {
    this.actions.fetchMetadata();
    this.actions.fetchVotingResults();
    this.actions.fetchEtdHighlightDocs();
    this.actions.fetchPreferences();
    this.actions.fetchUserData();
    MembersActions.fetch();
    QuestionsListComponentActions.fetch();
    return OverarchingQuestionsActions.fetch(mediator.project.id);
  };

  PanelVoiceActions.prototype.fetchMetadata = function() {
    var metadocId, projectId;
    projectId = mediator.project.id;
    metadocId = getVotingMetadocId(projectId);
    this.dispatch(projectId);
    return mediator.services.storePersistenceAdapter.fetch(projectId, metadocId).then(this.actions.fetchMetadataSuccess)["catch"](this.actions.fetchMetadataError);
  };

  PanelVoiceActions.prototype.fetchVotingResults = function() {
    var projectId, resultsDocsPrefix;
    projectId = mediator.project.id;
    resultsDocsPrefix = panelVoiceResultsPrefix(projectId);
    return mediator.services.storePersistenceAdapter.fetchWithKeyPrefix(projectId, resultsDocsPrefix).then(this.actions.fetchVotingResultsSuccess)["catch"](this.actions.fetchVotingResultsError);
  };

  PanelVoiceActions.prototype.fetchPreferences = function() {
    var preferencesDoc, projectId;
    projectId = mediator.project.id;
    preferencesDoc = panelVoicePreferencesDoc(projectId);
    return mediator.services.storePersistenceAdapter.fetch(projectId, preferencesDoc).then(this.actions.fetchPreferencesSuccess)["catch"](this.actions.fetchPreferencesError);
  };

  PanelVoiceActions.prototype.fetchUserData = function() {
    var userId;
    userId = mediator.user.id;
    return mediator.services.storePersistenceAdapter.fetch(userId, userId).then(this.actions.fetchUserDataSuccess)["catch"](this.actions.fetchUserDataError);
  };

  PanelVoiceActions.prototype.fetchEtdSnapshots = function(etdId, projectId) {
    if (projectId == null) {
      projectId = mediator.project.id;
    }
    this.dispatch();
    return EtdAsync.fetchEtdSnapshots(projectId, etdId).then((function(_this) {
      return function(_arg) {
        var snapshots, snapshotsData, _attachments;
        _attachments = _arg._attachments, snapshots = _arg.snapshots;
        snapshotsData = _.extend({
          _attachments: _attachments
        }, snapshots);
        return _this.actions.fetchEtdSnapshotsSuccess({
          etdId: etdId,
          snapshotsData: snapshotsData
        });
      };
    })(this))["catch"](this.actions.fetchEtdSnapshotsError);
  };

  PanelVoiceActions.prototype.fetchEtdHighlightDocs = function(projectId) {
    var docsPrefix;
    if (projectId == null) {
      projectId = mediator.project.id;
    }
    this.dispatch();
    docsPrefix = etdHighlightsPrefix(projectId);
    return mediator.services.storePersistenceAdapter.fetchWithKeyPrefix(projectId, docsPrefix).then(this.actions.fetchEtdHighlightDocsSuccess)["catch"](this.actions.fetchEtdHighlightDocsError);
  };

  PanelVoiceActions.prototype.sendVoting = function(type, data) {
    var roundType;
    roundType = (function() {
      switch (type) {
        case 'start':
          return 'sendNewParts';
        case 'resend':
          return 'resend';
        case 'test':
          return 'testRound';
      }
    })();
    data = _.extend({}, data, {
      roundType: roundType,
      projectId: mediator.project.id
    });
    return PanelVoiceApi.sendVoting(data).then(this.actions.sendVotingSuccess)["catch"](this.actions.sendVotingError);
  };

  PanelVoiceActions.prototype.closeVoting = function(etdId) {
    var data, projectId;
    projectId = mediator.project.id;
    data = {
      projectId: projectId,
      etdId: etdId
    };
    return PanelVoiceApi.closeVoting(data).then(this.actions.closeVotingSuccess)["catch"](this.actions.closeVotingError);
  };

  PanelVoiceActions.prototype.sendReminders = function(memberIds, messageBody, etdId) {
    var data, projectId;
    projectId = mediator.project.id;
    data = {
      projectId: projectId,
      memberIds: memberIds,
      messageBody: messageBody
    };
    return PanelVoiceApi.sendReminders(data, etdId).then(this.actions.sendRemindersSuccess)["catch"](this.actions.sendRemindersError);
  };

  PanelVoiceActions.prototype.updatePanelVoicePreferences = function(data) {
    var clonedData, docId, projectId;
    clonedData = _.clone(data);
    if (!clonedData.keepMessage) {
      delete clonedData.emailBody;
      delete clonedData.testEmailBody;
    }
    this.dispatch(clonedData);
    projectId = mediator.project.id;
    docId = panelVoicePreferencesDoc(projectId);
    if (clonedData.emailBody) {
      clonedData.emailBody = clonedData.emailBody.toJS();
    }
    if (clonedData.testEmailBody) {
      clonedData.testEmailBody = clonedData.testEmailBody.toJS();
    }
    return mediator.services.storePersistenceAdapter.updateOrCreate(projectId, docId, function(doc) {
      return _.extend(doc, clonedData);
    })["catch"](this.actions.updatePanelVoicePreferencesError);
  };

  PanelVoiceActions.prototype.openSendVotingModal = function(questionId, type, overarching) {
    if (overarching == null) {
      overarching = false;
    }
    return this.dispatch({
      questionId: questionId,
      type: type,
      overarching: overarching
    });
  };

  PanelVoiceActions.prototype.toggleSectionHighlight = function(etdDocId, sectionId) {
    var projectId;
    projectId = mediator.project.id;
    return EtdAsync.toggleEtdSectionHighlight(projectId, etdDocId, sectionId);
  };

  PanelVoiceActions.prototype.startPanelVoiceOnboarding = function() {
    this.dispatch();
    return mediator.services.panelVoiceOnboarding.startTour();
  };

  PanelVoiceActions.prototype.hideOnboardingNotification = function() {
    var proposalsCount, _ref1;
    this.dispatch();
    proposalsCount = (_ref1 = mediator.user.get('onboardingProposalCount')) != null ? _ref1 : 0;
    mediator.user.set('onboardingProposalCount', proposalsCount + 1);
    return mediator.user.save();
  };

  PanelVoiceActions.prototype.updateUserPanelVoicePreferences = function(newPrefs) {
    var userId;
    userId = mediator.user.id;
    this.dispatch(newPrefs);
    return mediator.services.storePersistenceAdapter.updateOrCreate(userId, userId, function(userDoc) {
      return _.extend(userDoc, newPrefs);
    });
  };

  return PanelVoiceActions;

})();

module.exports = alt.createActions(PanelVoiceActions);
