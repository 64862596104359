module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "netBalance": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je intervence přijatelná pro klíčové zainteresované strany?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují klíčové zainteresované strany, které by nesouhlasily s poměrem přínosů, rizik a nákladů?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují klíčové zainteresované strany, které by neakceptovaly krátkodobé náklady nebo nežádoucí účinky navzdory žádoucí účinkům (přínosům) v dlouhodobém horizontu?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují klíčové zainteresované strany, které by nesouhlasily s váhou přisuzovanou žádoucím nebo nežádoucím účinkům (např. kvůli tomu, jak by mohly být osobně ovlivněny, nebo kvůli jejich vnímání relativního významu účinků pro ostatní)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Měla by intervence (varianta) nepříznivý vliv na autonomii jedinců?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují klíčové zainteresované strany, které by s danou intervencí (alternativou) morálně nesouhlasily z jiných důvodů, než je její vliv na autonomii osob (tj. ve vztahu k etickým principům, jako je princip neškodlivosti, dobročinnosti nebo spravedlnosti)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je pravděpodobné, že klíčové zainteresované strany nebudou akceptovat poměr přínosů, rizik a nákladů? Nebo nebudou akceptovat krátkodobé náklady či nežádoucí účinky navzdory žádoucím účinkům (přínosům) v dlouhodobém horizontu? Je pravděpodobné, že nebudou souhlasit s váhou přisuzovanou žádoucím nebo nežádoucím účinkům, nebo že nebudou diagnostickou intervenci akceptovat z etických důvodů?",
          "options": [
            {
              "text": "Ne",
              "value": "no"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Ano",
              "value": "yes"
            }
          ],
          "ratingDescription": "Která intervence je pro klíčové zainteresované strany přijatelnější?",
          "ratingOptions": ["best_acceptability", "intermediate_acceptability", "worst_acceptability"],
          "selectedOption": ""
        },
        "acceptabilityEOHR": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je intervence přijatelná pro klíčové zainteresované strany?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Žádné zahrnuté studie",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Velmi nízká",
                    "value": "very_low"
                  },
                  {
                    "text": "Nízká",
                    "value": "low"
                  },
                  {
                    "text": "Středně významná",
                    "value": "moderate"
                  },
                  {
                    "text": "Vysoká",
                    "value": "high"
                  }
                ],
                "question": "Jaká je jistota přijatelnosti intervence mezi ovlivněnou populací?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významná variabilita",
                    "value": "important"
                  },
                  {
                    "text": "Pravděpodobně významná variabilita",
                    "value": "probably_important"
                  },
                  {
                    "text": "Pravděpodobně žádná významná variabilita",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Žádná významná variabilita",
                    "value": "no_important"
                  }
                ],
                "question": "Existuje variabilita v přijatelnosti intervence nebo expozice mezi příjemci doporučení a dalšími zúčastněnými stranami?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je pravděpodobné, že klíčové zainteresované strany nebudou akceptovat poměr přínosů, rizik a nákladů? Nebo nebudou akceptovat krátkodobé náklady či nežádoucí účinky navzdory žádoucím účinkům (přínosům) v dlouhodobém horizontu? Je pravděpodobné, že nebudou souhlasit s hodnotami přisuzovanými žádoucím nebo nežádoucím účinkům, nebo že nepřijmou diagnostickou intervenci z etických důvodů?",
          "options": [
            {
              "text": "Ne",
              "value": "no"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Ano",
              "value": "yes"
            }
          ],
          "ratingDescription": "Která intervence je pro klíčové zainteresované strany přijatelnější?",
          "ratingOptions": ["best_acceptability", "intermediate_acceptability", "worst_acceptability"],
          "selectedOption": ""
        },
        "additionalSection": {
          "additionalOptions": [
            {
              "text": "Dodatečné hodnocení 1",
              "value": "additionalOption_1"
            },
            {
              "text": "Dodatečné hodnocení 2",
              "value": "additionalOption_2"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Popis vlastního kritéria",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Poskytnout vlastní nápovědu",
          "options": [
            {
              "text": "Hodnocení 1",
              "value": "option_1"
            },
            {
              "text": "Hodnocení 2",
              "value": "option_2"
            },
            {
              "text": "Hodnocení 3",
              "value": "option_3"
            },
            {
              "text": "Hodnocení 4",
              "value": "option_4"
            },
            {
              "text": "Hodnocení 5",
              "value": "option_5"
            }
          ],
          "selectedOption": ""
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je poměr žádoucích a nežádoucích účinků ve prospěch intervence, nebo komparace?",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "Podrobné hodnocení tohoto kritéria zahrnuje hodnocení každého ze čtyř předchozích kritérií:"
              },
              {
                "additions": ["O kolik méně si lidé cení dlouhodobých výstupů, v porovnání s výstupy v krátkodobém horizontu?", "Postoje lidí k nežádoucím účinkům (jak velkou averzi k riziku mají).", "Postoje lidí k žádoucím účinkům (jak moc jsou ochotni riskovat)."],
                "description": "Kromě toho by panely mohly chtít zvážit (a případně zdokumentovat), do jaké míry může být poměr žádoucích a nežádoucích účinků ovlivněn následujícími okolnostmi:"
              }
            ],
            "panelDiscussion": ""
          },
          "info": "Jaký je poměr žádoucích a nežádoucích účinků s ohledem na to, jak si jedinci cení hlavních výstupů, jak významné jsou žádoucí a nežádoucí účinky, jaká je jistota těchto odhadů, jaký je tento poměr s ohledem na hodnoty v budoucnu a na míru ochoty či averze k riziku?",
          "options": [
            {
              "text": "Ve prospěch komparace",
              "value": "favors_comparison"
            },
            {
              "text": "Pravděpodobně ve prospěch komparace",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Pravděpodobně ve prospěch intervence",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Ve prospěch intervence",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Je poměr žádoucích a nežádoucích účinků u každé intervence ve prospěch dané intervence, nebo její komparace?",
          "ratingOptions": ["best_balance", "intermediate", "worst_balance"],
          "selectedOption": ""
        },
        "balanceOfEffectsEOHR": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je poměr žádoucích a nežádoucích účinků ve prospěch intervence, nebo komparace?",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "Podrobné hodnocení tohoto kritéria zahrnuje hodnocení každého ze čtyř předchozích kritérií:"
              },
              {
                "additions": ["O kolik méně si jednotlivci cení dlouhodobých výstupů, v porovnání s výstupy v krátkodobém horizontu?", "Postoje jednotlivců k nežádoucím účinkům (jak velkou averzi mají k riziku).", "Postoje jednotlivců k žádoucím účinkům (do jaké míry jsou ochotni riskovat)."],
                "description": "Kromě toho by panely mohly chtít zvážit (a případně zdokumentovat), do jaké míry může být poměr žádoucích a nežádoucích účinků ovlivněn následujícími okolnostmi:"
              }
            ],
            "panelDiscussion": ""
          },
          "info": "Jaký je poměr žádoucích a nežádoucích účinků s ohledem na to, jak si jednotlivci cení hlavních výstupů, jak významné jsou žádoucí a nežádoucí účinky, jaká je jistota těchto odhadů, snížené ceny a na míru ochoty riskovat?",
          "options": [
            {
              "text": "Ve prospěch komparace",
              "value": "favors_comparison"
            },
            {
              "text": "Pravděpodobně ve prospěch komparace",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Pravděpodobně ve prospěch intervence",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Ve prospěch intervence",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Je poměr žádoucích a nežádoucích účinků u každé intervence ve prospěch intervence, nebo její komparace?",
          "ratingOptions": ["best_balance", "intermediate", "worst_balance"],
          "selectedOption": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je poměr žádoucích a nežádoucích účinků ve prospěch intervence, nebo komparace?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska krátkodobé a dlouhodobé <strong>účinnosti</strong> (za kontrolovaných, často ideálních, podmínek) nebo <strong>reálné účinnosti</strong> intervence (za běžných podmínek) na <strong>zdraví jednotlivců</strong>, včetně výstupů hlášených pacientem, vhodnější intervence, nebo komparace?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska krátkodobé a dlouhodobé <strong>účinnosti</strong> nebo <strong>dopadu</strong> intervence na <strong>zdraví populace</strong>, včetně výstupů uváděných příjemci, vhodnější intervence, nebo komparace? (Mělo by být zohledněno, zda výstupy na úrovni populace představují souhrnné výstupy jednotlivců, nebo vznikají v důsledku systémové dynamiky.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska toho, jak si <strong>pacienti/příjemci cení</strong> různých zdravotních výstupů, vhodnější intervence, nebo komparace?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska <strong>pravděpodobnosti výskytu nežádoucích účinků a jejich závažnosti</strong> spojených s intervencí (včetně rizika zneužití intervence) vhodnější intervence, nebo komparace?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska <strong>širších pozitivních nebo negativních zdravotních dopadů</strong> (např. snížení stigmatu, pozitivní vliv na jiná onemocnění a další účinky nezahrnující pacienty/příjemce) vhodnější intervence, nebo komparace?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím větší je čistý zdravotní přínos spojený s intervencí, tím větší je pravděpodobnost obecného doporučení ve prospěch této intervence.",
          "options": [
            {
              "text": "Ve prospěch komparace",
              "value": "favors_the_comparison"
            },
            {
              "text": "Pravděpodobně ve prospěch komparace",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Pravděpodobně ve prospěch intervence",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "Ve prospěch intervence",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaká je celková jistota důkazů o účincích testu?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Jak přesnou představu poskytuje výzkum o pravděpodobných účincích na všechny kritické výstupy (tj. pravděpodobnost, že se reálné účinky budou natolik lišit od výsledků výzkumu, že by to mohlo ovlivnit rozhodování o diagnostické intervenci)?",
          "options": [
            {
              "text": "Velmi nízká",
              "value": "very_low"
            },
            {
              "text": "Nízká",
              "value": "low"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaká je celková jistota důkazů o účincích?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Jak přesnou představu poskytuje výzkum o pravděpodobných účincích na všechny kritické výstupy (tj. pravděpodobnost, že se účinky budou natolik lišit od výsledků výzkumu, že by to mohlo ovlivnit rozhodování o intervenci)?",
          "options": [
            {
              "text": "Velmi nízká",
              "value": "very_low"
            },
            {
              "text": "Nízká",
              "value": "low"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "ratingDescription": "Jaká je celková jistota důkazů o účincích každé intervence?",
          "selectedOption": ""
        },
        "certaintyOfEvidenceEOHR": {
          "additionalOptions": [
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaká je celková jistota důkazů o účincích?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Jak přesnou představu poskytuje výzkum o pravděpodobných účincích na všechny kritické výstupy (tj. jaká je pravděpodobnost, že se účinky budou natolik lišit od výsledků výzkumu, že by to mohlo ovlivnit rozhodování o intervenci)?",
          "options": [
            {
              "text": "Velmi nízká",
              "value": "very_low"
            },
            {
              "text": "Nízká",
              "value": "low"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "ratingDescription": "Jaká je celková jistota důkazů o účincích každé intervence?",
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaká je celková jistota důkazů o účincích léčby, která se řídí výsledky testu?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Velmi nízká",
              "value": "very_low"
            },
            {
              "text": "Nízká",
              "value": "low"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaká je jistota důkazů o požadavcích na zdroje (náklady)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Byly identifikovány všechny důležité aspekty využití zdrojů, které se mohou mezi jednotlivými zvažovanými variantami lišit?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významná nejistota",
                    "value": "important"
                  },
                  {
                    "text": "Možná významná nejistota",
                    "value": "probably_important"
                  },
                  {
                    "text": "Pravděpodobně žádná významná nejistota",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Žádná významná nejistota",
                    "value": "no_important"
                  }
                ],
                "question": "Jak jisté jsou náklady na položky využití zdrojů, které se liší mezi zvažovanými alternativami?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významná variabilita",
                    "value": "important"
                  },
                  {
                    "text": "Možná významná variabilita",
                    "value": "probably_important"
                  },
                  {
                    "text": "Pravděpodobně žádná významná variabilita",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Žádná významná variabilita",
                    "value": "no_important"
                  }
                ],
                "question": "Existuje významná variabilita v nákladech na položky využití zdrojů, které se liší mezi zvažovanými variantami?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Jak jisté jsou důkazy o rozdílu u jednotlivých typů využití zdrojů (např. léky, hospitalizace) a nákladech na tyto zdroje?",
          "options": [
            {
              "text": "Velmi nízká",
              "value": "very_low"
            },
            {
              "text": "Nízká",
              "value": "low"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResourcesEOHR": {
          "additionalOptions": [
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaká je jistota důkazů o požadavcích na zdroje (náklady)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Byly identifikovány všechny relevantní typy zdrojů?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významná variabilita",
                    "value": "important"
                  },
                  {
                    "text": "Pravděpodobně významná variabilita",
                    "value": "probably_important"
                  },
                  {
                    "text": "Pravděpodobně žádná významná variabilita",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Žádná významná variabilita",
                    "value": "no_important"
                  }
                ],
                "question": "Existuje významná variabilita v nákladech na typy zdrojů, o kterých se uvažuje?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Jak jisté jsou důkazy o rozdílu u jednotlivých typů využití zdrojů (např. léky, hospitalizace) a nákladech na tyto zdroje?",
          "options": [
            {
              "text": "Velmi nízká",
              "value": "very_low"
            },
            {
              "text": "Nízká",
              "value": "low"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaká je celková jistota důkazů o přesnosti testu?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Velmi nízká",
              "value": "very_low"
            },
            {
              "text": "Nízká",
              "value": "low"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaká je celková jistota důkazů o kritických nebo důležitých přímých přínosech, nežádoucích účincích nebo zátěži testu?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Velmi nízká",
              "value": "very_low"
            },
            {
              "text": "Nízká",
              "value": "low"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaká je jistota vazby mezi výsledkem testu a rozhodnutím o další léčbě?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Velmi nízká",
              "value": "very_low"
            },
            {
              "text": "Nízká",
              "value": "low"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Vychází porovnání nákladové efektivity ve prospěch intervence, nebo komparace?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Žádné zahrnuté studie",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je poměr nákladové efektivity (cost-effectiveness ratio) citlivý na jednocestnou (one-way) analýzu senzitivity?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Žádné zahrnuté studie",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je poměr nákladové efektivity (cost-effectiveness ratio) citlivý na analýzu sensitivity s více proměnnými (multi-variable sensitivity analysis)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Žádné zahrnuté studie",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Velmi vážná omezení",
                    "value": "very_serious"
                  },
                  {
                    "text": "Vážná omezení",
                    "value": "serious"
                  },
                  {
                    "text": "Žádná vážná omezení",
                    "value": "no_serious"
                  }
                ],
                "question": "Je ekonomické hodnocení, na němž je založen odhad nákladové efektivity, spolehlivé?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Žádné zahrnuté studie",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Velmi vážná omezení",
                    "value": "very_serious"
                  },
                  {
                    "text": "Vážná omezení",
                    "value": "serious"
                  },
                  {
                    "text": "Žádná vážná omezení",
                    "value": "no_serious"
                  }
                ],
                "question": "Je ekonomické hodnocení, na němž je založen odhad nákladové efektivity, použitelné pro dané prostředí?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je intervence nákladově efektivní s ohledem na nejistotu nebo variabilitu nákladů, nejistotu nebo variabilitu čistého přínosu, analýzu citlivosti a spolehlivost a použitelnost ekonomického hodnocení?",
          "options": [
            {
              "text": "Ve prospěch komparace",
              "value": "favors_comparison"
            },
            {
              "text": "Pravděpodobně ve prospěch komparace",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Pravděpodobně ve prospěch intervence",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Ve prospěch intervence",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Která intervence je výhodnější z hlediska nákladové efektivity?",
          "ratingOptions": ["best_cost_effectiveness", "intermediate_cost_effectiveness", "worst_cost_effectiveness"],
          "selectedOption": ""
        },
        "costEffectivenessEOHR": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Žádné zahrnuté studie",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je porovnání nákladové efektivity ve prospěch intervence, nebo komparace?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Žádné zahrnuté studie",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "doesnt_favor_any"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_intervention"
                  }
                ],
                "question": "Jaká je jistota v analýze nákladové efektivity?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je intervence nákladově efektivní s ohledem na nejistotu nebo variabilitu nákladů, nejistotu nebo variabilitu čistého přínosu, analýzu citlivosti a spolehlivost a použitelnost ekonomického hodnocení?",
          "options": [
            {
              "text": "Ve prospěch komparace",
              "value": "favors_comparison"
            },
            {
              "text": "Pravděpodobně ve prospěch komparace",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Pravděpodobně ve prospěch intervence",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Ve prospěch intervence",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Která intervence je výhodnější z hlediska nákladové efektivity?",
          "ratingOptions": ["best_cost_effectiveness", "intermediate_cost_effectiveness", "worst_cost_effectiveness"],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jak významné jsou očekávané žádoucí účinky?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nevýznamný",
                    "value": "trivial"
                  },
                  {
                    "text": "Málo významný",
                    "value": "small"
                  },
                  {
                    "text": "Středně významný",
                    "value": "moderate"
                  },
                  {
                    "text": "Významný",
                    "value": "large"
                  }
                ],
                "question": "Jak významný je předpokládaný účinek (rozdíl) pro každý hlavní výstup, pro nějž existuje žádoucí účinek?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Jak významné jsou žádoucí účinky intervence s ohledem na důležitost výstupů (jak moc si jich jednotlivci cení) a na velikost účinku (pravděpodobnost, že dojde k přínosu, nebo jak velké zlepšení by jedinci pravděpodobně pocítili)?",
          "options": [
            {
              "text": "Nevýznamné",
              "value": "trivial"
            },
            {
              "text": "Málo významné",
              "value": "small"
            },
            {
              "text": "Středně významné",
              "value": "moderate"
            },
            {
              "text": "Významné",
              "value": "large"
            }
          ],
          "ratingDescription": "Jak významné jsou očekávané žádoucí účinky pro každou intervenci?",
          "ratingOptions": ["most_effective", "intermediate_effectiveness", "least_effective"],
          "selectedOption": ""
        },
        "desirableEffectsEOHR": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jak významné jsou očekávané žádoucí účinky?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nevýznamný",
                    "value": "trivial"
                  },
                  {
                    "text": "Málo významný",
                    "value": "small"
                  },
                  {
                    "text": "Středně významný",
                    "value": "moderate"
                  },
                  {
                    "text": "Velký",
                    "value": "large"
                  }
                ],
                "question": "Jak významný je očekávaný žádoucí dopad (efekt) intervence nebo expozice?",
                "selectedOptions": {}
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nevýznamné",
                    "value": "trivial"
                  },
                  {
                    "text": "Málo významné",
                    "value": "small"
                  },
                  {
                    "text": "Středně významné",
                    "value": "moderate"
                  },
                  {
                    "text": "Významné",
                    "value": "large"
                  }
                ],
                "question": "Jak významné je časové rozpětí pro dosažení plné účinnosti intervence?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Jak významné jsou žádoucí účinky intervence s ohledem na důležitost výstupů (jak moc jsou oceňovány) a na velikost účinku (pravděpodobnost, že jednotlivec pocítí přínos, a jak velké zlepšení by jednotlivci pravděpodobně pocítili)?",
          "options": [
            {
              "text": "Nevýznamné",
              "value": "trivial"
            },
            {
              "text": "Málo významné",
              "value": "small"
            },
            {
              "text": "Středně významné",
              "value": "moderate"
            },
            {
              "text": "Významné",
              "value": "large"
            }
          ],
          "ratingDescription": "Jak významné jsou očekávané žádoucí účinky pro každou intervenci?",
          "ratingOptions": ["most_effective", "intermediate_effectiveness", "least_effective"],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaký by to mělo dopad na rovnost v oblasti zdraví?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují skupiny nebo prostředí, které by mohly být v souvislosti se zvažovaným problémem nebo variantami znevýhodněny?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují pádné důvody pro předpokládání rozdílu v relativní účinnosti dané alternativy pro znevýhodněné skupiny nebo zařízení?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují v různých skupinách nebo prostředích různé výchozí podmínky, které ovlivňují absolutní účinnost dané varianty nebo význam problému pro znevýhodněné skupiny nebo prostředí?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují důležitá hlediska, která by měla být při implementaci intervence (varianty) zohledněna, aby bylo zajištěno, že nerovnosti budou pokud možno sníženy a nebudou dále prohlubovány?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Existují pádné důvody pro předpokládání rozdílu v relativní účinnosti intervence u znevýhodněných skupin nebo u skupin s rozdílnými výchozími podmínkami, které ovlivňují absolutní účinnost intervence nebo důležitost problému?",
          "options": [
            {
              "text": "Zhoršení",
              "value": "reduced"
            },
            {
              "text": "Pravděpodobně sníženo",
              "value": "probably_reduced"
            },
            {
              "text": "Pravděpodobně žádný vliv",
              "value": "probably_no_impact"
            },
            {
              "text": "Pravděpodobně zvýšeno",
              "value": "probably_increased"
            },
            {
              "text": "Zlepšení",
              "value": "increased"
            }
          ],
          "ratingDescription": "Která intervence by v případě doporučení nejvíce snížila nerovnosti v oblasti zdraví?",
          "ratingOptions": ["most_reduction", "intermediate_reduction", "less_reduction"],
          "selectedOption": ""
        },
        "equityEOHR": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaký to bude mít dopad na rovnost?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  },
                  {
                    "text": "Liší se",
                    "value": "varies"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují skupiny nebo prostředí (např. náchylné populace nebo životní etapy), u nichž může dojít k odlišnému dopadu (účinku) intervence nebo expozice v důsledku variability výchozích podmínek v dotčené populaci?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  },
                  {
                    "text": "Liší se",
                    "value": "varies"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Jsou dopady nepřiměřeně neúměrné směrem k určitým skupinám (např. komplexní nebo nekvantifikované stresory)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  },
                  {
                    "text": "Liší se",
                    "value": "varies"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Snížila nebo zvýšila by intervence či expozice nerovnosti, s nimiž se setkávají marginalizovaní členové dotčené populace?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  },
                  {
                    "text": "Liší se",
                    "value": "varies"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Jsou řešeny otázky rovnosti (např. nedostatečně zastoupené nebo nedostatečně prostudované skupiny)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  },
                  {
                    "text": "Liší se",
                    "value": "varies"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": 'Řeší se otázky sociální spravedlnosti? (např. v duchu zásady "znečišťovatel platí", pokud jde o rozdělení dopadů/přínosů)',
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Existují pádné důvody pro předpokládání rozdílu v relativní účinnosti intervence u znevýhodněných skupin nebo u skupin s rozdílnými výchozími podmínkami, které ovlivňují absolutní účinnost intervence nebo důležitost problému?",
          "options": [
            {
              "text": "Zhoršení",
              "value": "reduced"
            },
            {
              "text": "Pravděpodobně sníženo",
              "value": "probably_reduced"
            },
            {
              "text": "Pravděpodobně žádný vliv",
              "value": "probably_no_impact"
            },
            {
              "text": "Pravděpodobně zlepšeno",
              "value": "probably_increased"
            },
            {
              "text": "Zvýšeno",
              "value": "increased"
            }
          ],
          "ratingDescription": "Která intervence by v případě doporučení nejvíce snížila nerovnosti v oblasti zdraví?",
          "ratingOptions": ["most_reduction", "intermediate_reduction", "less_reduction"],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je intervenci možné implementovat?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je intervence (varianta) udržitelná?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Existují významné překážky, které by mohly omezit implementaci této (varianty) intervence nebo které vyžadují zvážení při její implementaci?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je možné zachovat používání diagnostické intervence a vyřešit potenciální překážky jejího použití?",
          "options": [
            {
              "text": "Ne",
              "value": "no"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Ano",
              "value": "yes"
            }
          ],
          "ratingDescription": "Kterou intervenci lze snadněji implementovat?",
          "ratingOptions": ["most_feasible", "intermediate_feasibility", "least_feasible"],
          "selectedOption": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je intervenci možné implementovat?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují <strong>právní překážky</strong>, které mohou omezit proveditelnost intervence?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ano",
                    "value": "yes"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Ne",
                    "value": "no"
                  }
                ],
                "question": "Existují <strong>vládní aspekty</strong> (např. strategické hlediska, minulá rozhodnutí), které mohou limitovat proveditelnost intervence? (Měla by být zohledněna přítomnost či nepřítomnost formálních nebo informačních institucí, které mohou zajistit účinné vedení, dohled a odpovědnost při provádění intervence, ovlivňují proveditelnost její implementace)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Velké příznivé důsledky",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Mírné příznivé důsledky",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Zanedbatelné příznivé a nepříznivé důsledky",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Mírné nepříznivé důsledky",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Velké nepříznivé důsledky",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Jaké jsou <strong>důsledky</strong> implementace intervence <strong> do stávajícího zdravotnického systému</strong>? (Tedy z hlediska interakce intervence se stávajícím zdravotnickým systémem a o jejím dopadu na něj.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Velké příznivé důsledky",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Mírné příznivé důsledky",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Zanedbatelné příznivé a nepříznivé důsledky",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Mírné nepříznivé důsledky",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Velké nepříznivé důsledky",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Jaké jsou <strong>důsledky</strong> intervence <strong>pro pracovníky ve zdravotnictví i mimo něj</strong>? (Včetně hledisek týkající se potřeby, využití a dopadu intervence na pracovníky ve zdravotnictví a další lidské zdroje i jejich distribuce)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Velké příznivé důsledky",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Mírné příznivé důsledky",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Zanedbatelné příznivé a nepříznivé důsledky",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Mírné nepříznivé důsledky",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Velké nepříznivé důsledky",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Jaké jsou <strong>důsledky</strong> intervence <strong>pro infrastrukturu zdravotnického systému a širší infrastrukturu</strong>? (Včetně potřeby a dopadu na infrastrukturu a na zdroje (mimo zdrojů lidských), na jejich distribuci a využití)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím vyšší je proveditelnost dané alternativy z pohledu všech (nebo většiny) zainteresovaných stran, tím vyšší je pravděpodobnost obecného doporučení ve prospěch dané intervence. Čím výhodnější jsou důsledky pro zdravotní systém jako celek, tím vyšší je pravděpodobnost obecného doporučení ve prospěch intervence.",
          "options": [
            {
              "text": "Ne",
              "value": "no"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_not"
            },
            {
              "text": "Není jisté",
              "value": "uncertain"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Ano",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "feasibilityEOHR": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je intervenci možné implementovat?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je intervence (alternativa) udržitelná po příslušnou dobu?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Existují významné překážky (např. neexistence zákonů/předpisů), které by mohly omezit proveditelnost intervence (alternativy)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Jsou k dispozici nápravná opatření k odstranění zásadních překážek pro implementaci intervence?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Existují důležité podpůrné faktory (např. existence zákonů/regulací), které by mohly zlepšit proveditelnost intervence (možnosti)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Je možné udržet používání diagnostické intervence a postavit se k potenciálním překážkám jejího použití?",
          "options": [
            {
              "text": "Ne",
              "value": "no"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Ano",
              "value": "yes"
            }
          ],
          "ratingDescription": "Kterou intervenci lze snadněji implementovat?",
          "ratingOptions": ["most_feasible", "intermediate_feasibility", "least_feasible"],
          "selectedOption": ""
        },
        "feasible": {
          "description": "identifikovatelné a měřitelné a nepodléhající manipulaci",
          "options": [],
          "ratingOptions": ["very_feasible", "feasible", "least_feasible"],
          "selectedOptions": ""
        },
        "financialAndEconomicConsiderations": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je z finančního a ekonomického hlediska výhodnější intervence, nebo komparace?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska <strong>nákladů a dopadů na rozpočet</strong> při zavádění a provádění výhodnější intervence, nebo komparace? (Měly by být zahrnuty úvahy o tom, jak se liší náklady a dopad na rozpočet v krátkodobém a dlouhodobém horizontu.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska <strong>celkového dopadu na ekonomiku</strong> výhodnější intervence, nebo komparace? (Mělo by být zohledněno i to, jak jsou různé typy ekonomických dopadů rozděleny mezi různá odvětví nebo organizační úrovně, zda intervence přispívá nebo omezuje dosažení širších cílů rozvoje a snižování chudoby a jak ovlivňuje dostupnou pracovní sílu.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je <strong>poměr nákladů a přínosů</strong> (např. na základě odhadu nákladové efektivity, odhadu nákladů a přínosů nebo odhadu nákladů a užitku) ve prospěch intervence, nebo komparace?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím výhodnější jsou finanční a ekonomické dopady intervence, tím vyšší je pravděpodobnost formulace doporučení ve prospěch této intervence.",
          "options": [
            {
              "text": "Ve prospěch komparace",
              "value": "favors_the_comparison"
            },
            {
              "text": "Pravděpodobně ve prospěch komparace",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Pravděpodobně ve prospěch intervence",
              "value": "probably_favor_the_intervention"
            },
            {
              "text": "Ve prospěch intervence",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaký by byl dopad intervence na spravedlnost, rovnost a nediskriminaci v oblasti zdraví?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je pravděpodobné, že intervence zvýší stávající nespravedlnost a/nebo nerovnost v oblasti zdraví nebo jeho determinant? (Měly by být zohledněny úvahy o pravděpodobných změnách v nerovnostech v průběhu času, např. zda se počáteční výskyt nerovností vyrovná postupem času tím, jak se bude intervence rozšiřovat.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je pravděpodobné, že budou přínosy a rizika intervence rozděleny rovným způsobem? (Mělo by být zohledněno zvláštní zaměření se na důsledky intervence pro zranitelné, marginalizované nebo jinak sociálně znevýhodněné skupiny obyvatel.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je intervence finančně dostupná pro dotčené skupiny obyvatelstva? (Měl by být zohledněn dopad na výdaje domácností na zdraví, včetně rizika výdajů domácností na zdraví, které přesahují jejich hranici příjmu, a dalších finančních rizik souvisejících se zdravím.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je intervence dostupná pro dotčené skupiny obyvatel? (Mělo by být zohledněno i hledisko fyzické a informační dostupnosti.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Řeší intervence obzvláště závažný zdravotní problém (např. život ohrožující stav, stav na konci života, problém postihující osoby se špatným zdravotním stavem), nebo vzácné onemocnění?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Představuje intervence jedinou dostupnou možnost? (Mělo by být zohledněno, zda je intervence přiměřená potřebě a zda bude podléhat pravidelnému přezkoumávání.)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím vyšší je pravděpodobnost, že intervence zvyšuje spravedlnost a/nebo rovnost v oblasti zdraví a snižuje diskriminaci určitých skupin, tím vyšší je pravděpodobnost obecného doporučení ve prospěch této intervence.",
          "options": [
            {
              "text": "Negativní",
              "value": "negative"
            },
            {
              "text": "Pravděpodobně negativní",
              "value": "probably_negative"
            },
            {
              "text": "Ani negativní, ani pozitivní",
              "value": "neither_negative_nor_positive"
            },
            {
              "text": "Pravděpodobně pozitivní",
              "value": "probably_positive"
            },
            {
              "text": "Pozitivní",
              "value": "positive"
            }
          ],
          "selectedOption": ""
        },
        "humanRights": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je intervence v souladu s obecně platnými zásadami dodržování lidských práv?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Všechna doporučení by měla být v souladu se zásadami lidských práv a jejich obecnými principy.",
          "options": [
            {
              "text": "Ne",
              "value": "no"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Není jisté",
              "value": "uncertain"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Ano",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "netBalance": {
          "additionalOptions": [],
          "description": "Čistá bilance",
          "info": "Jaká je celková čistá bilance účinků?",
          "options": [
            {
              "text": "Žádoucí velká čistá hodnota",
              "value": "large_net_desirable"
            },
            {
              "text": "Žádoucí mírná čistá hodnota",
              "value": "moderate_net_desirable"
            },
            {
              "text": "Žádoucí malá čistá hodnota",
              "value": "small_net_desirable"
            },
            {
              "text": "Nulová čistá bilance",
              "value": "null_net_balance"
            },
            {
              "text": "Nežádoucí malá čistá hodnota",
              "value": "small_net_undesirable"
            },
            {
              "text": "Nežádoucí střední čistá hodnota",
              "value": "moderate_net_undesirable"
            },
            {
              "text": "Nežádoucí velká čistá hodnota",
              "value": "large_net_undesirable"
            }
          ],
          "selectedOptions": {}
        },
        "problem": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je řešení tohoto problému prioritou?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Jsou důsledky problému závažné (tj. důležité z hlediska možných přínosů nebo úspor)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je tento problém naléhavý?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je to uznaná priorita (např. na základě politického nebo strategického rozhodnutí)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím je problém závažnější a naléhavější, tím je pravděpodobnější, že varianta, která se problémem zabývá, bude mít prioritu.",
          "options": [
            {
              "text": "Ne",
              "value": "no"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Ano",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problemEOHR": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je řešení tohoto problému prioritou?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Jsou důsledky problému závažné (tj. závažné, nevratné, nebo důležité)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je tento problém naléhavý, neodkladný, nebo bezprecedentní??",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je tento problém považován za prioritu politického systému (na místní nebo národní úrovni)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je tento problém brán jako problém v oblasti pracovního nebo veřejného zdraví?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ne",
                    "value": "no"
                  },
                  {
                    "text": "Pravděpodobně ne",
                    "value": "probably_no"
                  },
                  {
                    "text": "Pravděpodobně ano",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Ano",
                    "value": "yes"
                  }
                ],
                "question": "Je tento problém brán jako priorita pro místní komunitu?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím je problém závažnější nebo naléhavější, tím je pravděpodobnější, že varianta, která se problémem zabývá, bude mít prioritu.",
          "options": [
            {
              "text": "Ne",
              "value": "no"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Ano",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "qualityOfEvidence": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jaká je celková kvalita důkazů?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Čím vyšší je kvalita důkazů napříč různými kritérii rámce WHO-INTEGRATE, tím vyšší je pravděpodobnost všeobecného doporučení.",
          "options": [
            {
              "text": "Velmi nízká",
              "value": "very_low"
            },
            {
              "text": "Nízká",
              "value": "low"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Vysoká",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "relevantDirect": {
          "description": "k vybranému problému a v oblasti použití",
          "options": [],
          "ratingOptions": ["very_relevant", "relevant", "least_relevant"],
          "selectedOptions": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jak velké jsou požadavky na zdroje (náklady)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Zanedbatelný",
                    "value": "trivial"
                  },
                  {
                    "text": "Malý",
                    "value": "small"
                  },
                  {
                    "text": "Střední",
                    "value": "moderate"
                  },
                  {
                    "text": "Významný",
                    "value": "large"
                  }
                ],
                "question": "Jak velký je rozdíl v jednotlivých položkách využití zdrojů, pro které je zapotřebí méně zdrojů?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významný",
                    "value": "large"
                  },
                  {
                    "text": "Střední",
                    "value": "moderate"
                  },
                  {
                    "text": "Malý",
                    "value": "small"
                  },
                  {
                    "text": "Nevýznamný",
                    "value": "trivial"
                  }
                ],
                "question": "Jak velký je rozdíl v jednotlivých položkách využití zdrojů, pro které je zapotřebí více zdrojů?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Jak velké jsou náklady na rozdíl ve využití zdrojů mezi intervencí a komparací?",
          "options": [
            {
              "text": "Vysoké náklady",
              "value": "large_costs"
            },
            {
              "text": "Středně vysoké náklady",
              "value": "moderate_costs"
            },
            {
              "text": "Zanedbatelné náklady a úspory",
              "value": "negligible_costs_savings"
            },
            {
              "text": "Středně vysoké úspory",
              "value": "moderate_savings"
            },
            {
              "text": "Velké úspory",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "Jak velké jsou požadavky na zdroje (náklady) pro jednotlivé intervence?",
          "ratingOptions": ["less_costs", "intermediate_costs", "most_costs"],
          "selectedOption": ""
        },
        "resourcesRequiredEOHR": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jak velké jsou požadavky na zdroje (náklady)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nevýznamně",
                    "value": "trivial"
                  },
                  {
                    "text": "Málo významně",
                    "value": "small"
                  },
                  {
                    "text": "Středně významně",
                    "value": "moderate"
                  },
                  {
                    "text": "Významně",
                    "value": "large"
                  }
                ],
                "question": "Přinesla by zvažovaná intervence nebo expozice u každého typu zdroje dodatečné náklady nebo úspory?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Jak velké jsou náklady v rozdílném využití zdrojů mezi intervencí a komparací?",
          "options": [
            {
              "text": "Velké náklady",
              "value": "large_costs"
            },
            {
              "text": "Střední náklady",
              "value": "moderate_costs"
            },
            {
              "text": "Zanedbatelné náklady a úspory",
              "value": "negligible_costs_savings"
            },
            {
              "text": "Střední úspory",
              "value": "moderate_savings"
            },
            {
              "text": "Velké úspory",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "Jak velké jsou požadavky na zdroje (náklady) pro jednotlivé intervence?",
          "ratingOptions": ["less_costs", "intermediate_costs", "most_costs"],
          "selectedOption": ""
        },
        "responsive": {
          "description": "citlivé na změny, což znamená, že by měly být schopny zachytit případné změny v systému.",
          "options": [],
          "ratingOptions": ["very_responsive", "responsive", "least_responsive"],
          "selectedOptions": ""
        },
        "scientificallySound": {
          "description": "based on evidence (GRADE criteria)",
          "options": [],
          "ratingOptions": ["very_scientifically_sound", "scientifically_sound", "least_scientifically_sound"],
          "selectedOptions": ""
        },
        "societalImplications": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je z hlediska poměru žádoucích a nežádoucích společenských důsledků vhodnější intervence, nebo komparace?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska sociálního dopadu a sociálních důsledků (např. snížení nebo zvýšení stigmatizace, vzdělanostní výstupy, sociální soudržnost nebo nabytí různých lidských práv mimo zdraví) vhodnější intervence, nebo komparace?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska dopadu na životní prostředí a důsledků pro ekologickou udržitelnost (např. ochrana přírodních zdrojů, zmírnění klimatické změny nebo přizpůsobení se této změně) vhodnější intervence, nebo komparace?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím větší je čistý společenský přínos spojený s intervencí, tím větší je pravděpodobnost obecného doporučení ve prospěch této intervence.",
          "options": [
            {
              "text": "Ve prospěch komparace",
              "value": "favors_the_comparison"
            },
            {
              "text": "Pravděpodobně ve prospěch komparace",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Pravděpodobně ve prospěch intervence",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "Ve prospěch intervence",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "socioCulturalAcceptability": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Je intervence přijatelná pro klíčové zainteresované strany?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významný",
                    "value": "large"
                  },
                  {
                    "text": "Střední",
                    "value": "moderate"
                  },
                  {
                    "text": "Malý",
                    "value": "small"
                  },
                  {
                    "text": "Nevýznamný",
                    "value": "trivial"
                  }
                ],
                "question": "Jak významný je dopad intervence z hlediska zásahu do osobních svobod (včetně soukromí a důstojnosti)? Míra zásahu se pohybuje od zanedbatelné – např. umožněním volby (např. budování cyklostezek) až po vysokou – např. omezením nebo vyloučením volby (např. zákaz cigaret).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významný",
                    "value": "large"
                  },
                  {
                    "text": "Střední",
                    "value": "moderate"
                  },
                  {
                    "text": "Malý",
                    "value": "small"
                  },
                  {
                    "text": "Nevýznamný",
                    "value": "trivial"
                  }
                ],
                "question": "Jak významný je dopad intervence na autonomii jednotlivců, skupin obyvatel a/nebo organizací (s ohledem na jejich schopnost učinit kompetentní, informované a dobrovolné rozhodnutí)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska sociokulturní přijatelnosti mezi zamýšlenými příjemci vhodnější intervence nebo komparace?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska sociokulturní přijatelnosti u jedinců, kteří mají intervenci provádět, vhodnější intervence, nebo komparace?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska sociokulturní přijatelnosti pro ostatní zainteresované strany vhodnější intervence, nebo komparace?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Ve prospěch komparace",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch komparace",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ani ve prospěch intervence, ani ve prospěch komparace",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Pravděpodobně ve prospěch intervence",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Ve prospěch intervence",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Je z hlediska sociokulturní přijatelnosti pro širokou veřejnost vhodnější intervence, nebo komparace?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Čím větší je sociokulturní přijatelnost intervence pro všechny, nebo pro většinu relevantních zainteresovaných stran, tím větší je pravděpodobnost obecného doporučení ve prospěch této intervence.",
          "options": [
            {
              "text": "Ne",
              "value": "no"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Není jisté",
              "value": "uncertain"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Ano",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jak přesný je test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Velmi nepřesný",
              "value": "very_inaccurate"
            },
            {
              "text": "Nepřesný",
              "value": "inaccurate"
            },
            {
              "text": "Přesný",
              "value": "accurate"
            },
            {
              "text": "Velmi přesný",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jak významné jsou očekávané nežádoucí účinky?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Nevýznamné",
                    "value": "trivial"
                  },
                  {
                    "text": "Málo významné",
                    "value": "small"
                  },
                  {
                    "text": "Středně významné",
                    "value": "moderate"
                  },
                  {
                    "text": "Významné",
                    "value": "large"
                  }
                ],
                "question": "Jak významný je předpokládaný účinek (rozdíl) pro každý hlavní výstup, pro nějž existuje nežádoucí účinek?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Jak velké jsou nežádoucí účinky intervence s ohledem na důležitost výstupů (jak velká hodnota je jim přisuzována) a velikost účinku (pravděpodobnost, že se dostaví přínos, nebo jak velké zlepšení by jedinci pravděpodobně pocítili)?",
          "options": [
            {
              "text": "Nevýznamné",
              "value": "trivial"
            },
            {
              "text": "Malý",
              "value": "small"
            },
            {
              "text": "Střední",
              "value": "moderate"
            },
            {
              "text": "Velký",
              "value": "large"
            }
          ],
          "ratingDescription": "Jak významné jsou očekávané nežádoucí účinky pro každou intervenci?",
          "ratingOptions": ["least_harmful", "intermediate", "more_harmful"],
          "selectedOption": ""
        },
        "undesirableEffectsEOHR": {
          "additionalOptions": [
            {
              "text": "Liší se",
              "value": "varies"
            },
            {
              "text": "Není známo",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Jak významné jsou očekávané nežádoucí účinky?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významný",
                    "value": "large"
                  },
                  {
                    "text": "Středně významný",
                    "value": "moderate"
                  },
                  {
                    "text": "Málo významný",
                    "value": "small"
                  },
                  {
                    "text": "Nevýznamný",
                    "value": "trivial"
                  }
                ],
                "question": "Jak významný je očekávaný nežádoucí dopad (účinek) intervence nebo expozice?",
                "selectedOptions": {}
              },
              {
                "additionalOptions": [
                  {
                    "text": "Liší se",
                    "value": "varies"
                  },
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významné",
                    "value": "large"
                  },
                  {
                    "text": "Střední",
                    "value": "moderate"
                  },
                  {
                    "text": "Málo významné",
                    "value": "small"
                  },
                  {
                    "text": "Nevýznamné",
                    "value": "trivial"
                  }
                ],
                "question": "Jak významné je časové rozpětí nežádoucích účinků intervence?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Jak velké jsou nežádoucí účinky intervence s ohledem na důležitost výstupů (do jaké míry jsou oceňovány) a velikost účinku (pravděpodobnost, že dojde k přínosu, nebo jak velké zlepšení by jedinci pravděpodobně pociťovali)?",
          "options": [
            {
              "text": "Významné",
              "value": "large"
            },
            {
              "text": "Středně významné",
              "value": "moderate"
            },
            {
              "text": "Málo významné",
              "value": "small"
            },
            {
              "text": "Nevýznamné",
              "value": "trivial"
            }
          ],
          "ratingDescription": "Jak významné jsou očekávané nežádoucí účinky pro každou intervenci?",
          "ratingOptions": ["least_harmful", "intermediate", "more_harmful"],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Existuje významná nejistota nebo variabilita v tom, nakolik si lidé cení hlavních výstupů?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "Významná nejistota",
                    "value": "important"
                  },
                  {
                    "text": "Možná významná nejistota",
                    "value": "probably_important"
                  },
                  {
                    "text": "Pravděpodobně žádná významná nejistota",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Žádná významná nejistota",
                    "value": "no_important"
                  }
                ],
                "question": "Existuje významná nejistota v tom, nakolik si lidé cení hlavních výstupů?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Jak moc si jedinci cení jednotlivých hlavních výstupů? Je nejistota nebo variabilita ohledně toho, jak si cení jednotlivých výstupů, dostatečně velká, aby mohla vést k rozdílným rozhodnutím?",
          "options": [
            {
              "text": "Existuje významná nejistota nebo variabilita",
              "value": "important_uncertainty"
            },
            {
              "text": "Pravděpodobně existuje významná nejistota nebo variabilita",
              "value": "possible_important"
            },
            {
              "text": "Pravděpodobně neexistuje významná nejistota nebo variabilita",
              "value": "probably_no_important"
            },
            {
              "text": "Neexistuje významná nejistota nebo variabilita",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        },
        "valuesEOHR": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Existuje významná nejistota nebo variabilita v tom, nakolik si lidé cení hlavních výstupů?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významná nejistota",
                    "value": "important"
                  },
                  {
                    "text": "Pravděpodobně významná nejistota",
                    "value": "probably_important"
                  },
                  {
                    "text": "Pravděpodobně žádná významná nejistota",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Žádná významná nejistota",
                    "value": "no_important"
                  }
                ],
                "question": "Existuje významná nejistota v tom, nakolik si lidé cení hlavních výstupů?",
                "selectedOptions": {}
              },
              {
                "additionalOptions": [
                  {
                    "text": "Není známo",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Významná variabilita",
                    "value": "important"
                  },
                  {
                    "text": "Pravděpodobně významná variabilita",
                    "value": "probably_important"
                  },
                  {
                    "text": "Pravděpodobně žádná významná variabilita",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Žádná významná variabilita",
                    "value": "no_important"
                  }
                ],
                "question": "Existuje smysluplná variabilita v tom, nakolik si lidé cení hlavních výstupů?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Jak moc si jednotlivci cení jednotlivých hlavních výstupů? Existuje nejistota nebo variabilita ohledně toho, jak si cení jednotlivých výstupů, dostatečně velká, aby mohla vést k rozdílným rozhodnutím?",
          "options": [
            {
              "text": "Významná nejistota nebo variabilita",
              "value": "important_uncertainty"
            },
            {
              "text": "Pravděpodobně významná nejistota nebo variabilita",
              "value": "possible_important"
            },
            {
              "text": "Pravděpodobně žádná významná nejistota nebo variabilita",
              "value": "probably_no_important"
            },
            {
              "text": "Žádná významná nejistota nebo variabilita",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "netBalance": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "Přijatelnost",
          "researchEvidenceId": "acceptability"
        },
        "acceptabilityEOHR": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptabilityEOHR",
          "name": "Přijatelnost",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "Vlastní kritérium",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "Poměr účinků",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfEffectsEOHR": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffectsEOHR",
          "name": "Poměr účinků",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
          "criterionId": "balanceOfHealthBenefitsAndHarms",
          "name": "Poměr zdravotních přínosů a rizik",
          "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "Jistota účinků",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "Jistota důkazů",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceEOHR": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidenceEOHR",
          "name": "Jistota důkazů",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "Jistota důkazů o účincích léčby",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "Jistota důkazů o požadavcích na zdroje",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfRequiredResourcesEOHR": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResourcesEOHR",
          "name": "Jistota důkazů o požadovaných zdrojích",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "Jistota důkazů o přesnosti testu",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "Jistota důkazů o účincích testu",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "Jistota důkazů o výsledcích testu/léčby",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "Nákladová efektivita",
          "researchEvidenceId": "costEffectiveness"
        },
        "costEffectivenessEOHR": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectivenessEOHR",
          "name": "Nákladová efektivita",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "Žádoucí účinky",
          "researchEvidenceId": "desirableEffects"
        },
        "desirableEffectsEOHR": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffectsEOHR",
          "name": "Žádoucí účinky",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "Zdravotní ekvita",
          "researchEvidenceId": "equity"
        },
        "equityEOHR": {
          "additionalConsiderationId": "equity",
          "criterionId": "equityEOHR",
          "name": "Zdravotní ekvita",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "Proveditelnost",
          "researchEvidenceId": "feasibility"
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
          "criterionId": "feasibilityAndHealthSystemConsiderations",
          "name": "Úvahy o proveditelnosti a zdravotnickém systému",
          "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
        },
        "feasibilityEOHR": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibilityEOHR",
          "name": "Proveditelnost",
          "researchEvidenceId": "feasibility"
        },
        "feasible": {
          "additionalConsiderationId": "feasible",
          "criterionId": "feasible",
          "name": "PROVEDITELNÉ",
          "researchEvidenceId": "feasible"
        },
        "financialAndEconomicConsiderations": {
          "additionalConsiderationId": "financialAndEconomicConsiderations",
          "criterionId": "financialAndEconomicConsiderations",
          "name": "Finanční a ekonomická hlediska",
          "researchEvidenceId": "financialAndEconomicConsiderations"
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
          "criterionId": "healthEquityEqualityAndNonDiscrimination",
          "name": "Zdravotní ekvita, rovnost a nediskriminace v oblasti zdraví",
          "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
        },
        "humanRights": {
          "additionalConsiderationId": "humanRights",
          "criterionId": "humanRights",
          "name": "Lidská práva",
          "researchEvidenceId": "humanRights"
        },
        "netBalance": {
          "additionalConsiderationId": "netBalance",
          "criterionId": "netBalance",
          "name": "Čistý zůstatek",
          "researchEvidenceId": "netBalance"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "Problém",
          "researchEvidenceId": "problem"
        },
        "problemEOHR": {
          "additionalConsiderationId": "problem",
          "criterionId": "problemEOHR",
          "name": "Problém",
          "researchEvidenceId": "problem"
        },
        "qualityOfEvidence": {
          "additionalConsiderationId": "qualityOfEvidence",
          "criterionId": "qualityOfEvidence",
          "name": "Kvalita důkazů",
          "researchEvidenceId": "qualityOfEvidence"
        },
        "relevantDirect": {
          "additionalConsiderationId": "relevantDirect",
          "criterionId": "relevantDirect",
          "name": "RELEVANTNÍ (PŘÍMÉ)",
          "researchEvidenceId": "relevantDirect"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "Požadavky na zdroje",
          "researchEvidenceId": "resourcesRequired"
        },
        "resourcesRequiredEOHR": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequiredEOHR",
          "name": "Požadované zdroje",
          "researchEvidenceId": "resourcesRequired"
        },
        "responsive": {
          "additionalConsiderationId": "responsive",
          "criterionId": "responsive",
          "name": "CITLIVÉ",
          "researchEvidenceId": "responsive"
        },
        "scientificallySound": {
          "additionalConsiderationId": "scientificallySound",
          "criterionId": "scientificallySound",
          "name": "VĚDECKY PODLOŽENÉ (JISTOTA DŮKAZŮ)",
          "researchEvidenceId": "scientificallySound"
        },
        "societalImplications": {
          "additionalConsiderationId": "societalImplications",
          "criterionId": "societalImplications",
          "name": "Společenské dopady",
          "researchEvidenceId": "societalImplications"
        },
        "socioCulturalAcceptability": {
          "additionalConsiderationId": "socioCulturalAcceptability",
          "criterionId": "socioCulturalAcceptability",
          "name": "Sociokulturní přijatelnost",
          "researchEvidenceId": "socioCulturalAcceptability"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": "Přesnost testu",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "Nežádoucí účinky",
          "researchEvidenceId": "undesirableEffects"
        },
        "undesirableEffectsEOHR": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffectsEOHR",
          "name": "Nežádoucí účinky",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "Hodnoty",
          "researchEvidenceId": "values"
        },
        "valuesEOHR": {
          "additionalConsiderationId": "values",
          "criterionId": "valuesEOHR",
          "name": "Hodnoty",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "benefits": {
          "content": "",
          "description": "Přínosy"
        },
        "clearAndActionable": {
          "content": "",
          "description": "Jasné a proveditelné",
          "options": [
            {
              "text": "Ano",
              "value": "yes"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Ne",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "clinicalConsiderations": {
          "content": "",
          "description": "Klinické okolnosti"
        },
        "collectingAndSummarizing": {
          "content": "",
          "description": "Shromažďování a shrnutí důkazů by nebylo efektivním využitím omezeného času a energie panelu (vyžadovalo by vynaložení velkého úsilí)",
          "options": [
            {
              "text": "Ano",
              "value": "yes"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Ne",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "considerations": {
          "content": "",
          "description": "Okolnosti"
        },
        "contextAndSystemConsiderations": {
          "content": "",
          "description": "Kontext a systémová hlediska"
        },
        "decision": {
          "content": "",
          "description": "Rozhodnutí",
          "options": [
            {
              "text": "Plná implementace",
              "value": "full_implementation"
            },
            {
              "text": "Hodnocení dopadu",
              "value": "impact_evaluation"
            },
            {
              "text": "Pilotní studie",
              "value": "pilot_study"
            },
            {
              "text": "Odložit",
              "value": "postpone"
            },
            {
              "text": "Neimplementovat",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "Typ rozhodnutí",
          "options": [
            {
              "direction": "negative",
              "text": "Nepokrývat",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "Pokrýt tvorbou důkazů",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "Pokrýt vyjednáváním o ceně",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "Omezené pokrytí",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "Pokrýt",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "Typ rozhodnutí",
          "options": [
            {
              "direction": "negative",
              "text": "Neimplementovat možnost",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "Odložit rozhodnutí",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "Provést pilotní studii o této možnosti",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "Implementovat variantu s hodnocením dopadu",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "Implementovat variantu",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "evidence": {
          "content": "",
          "description": "Důkazy"
        },
        "frequencyDoseDuration": {
          "content": "",
          "description": "Frekvence/Dávka/Trvání"
        },
        "goodPracticeStatement": {
          "content": "",
          "description": "Doporučení dobré praxe"
        },
        "harms": {
          "content": "",
          "description": "Rizika"
        },
        "implementationConsiderations": {
          "content": "",
          "description": "Poznámky k implementaci",
          "details": {
            "content": "",
            "description": "Podrobná implementace"
          }
        },
        "implementationConsiderationsOfAllRelevantOutcomes": {
          "content": "",
          "description": "Po zvážení všech relevantních výstupů a možných důsledků vede přijetí doporučení dobré praxe k významným přínosům",
          "options": [
            {
              "text": "Ano",
              "value": "yes"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Ne",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderationsOrRemarks": {
          "content": "",
          "description": "Okolnosti implementace",
          "options": [
            {
              "text": "Ano",
              "value": "yes"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Ne",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "indications": {
          "content": "",
          "description": "Indikace"
        },
        "indicationsForDiscontinuation": {
          "content": "",
          "description": "Indikace pro přerušení"
        },
        "justification": {
          "content": "",
          "description": "Zdůvodnění",
          "details": {
            "description": "Podrobné zdůvodnění"
          },
          "overallDescription": "Souhrnné zdůvodnění"
        },
        "keyMessage": {
          "content": "",
          "description": "Klíčové sdělení"
        },
        "limitations": {
          "content": "",
          "description": "Omezení"
        },
        "mdgRecommendation": {
          "content": "",
          "description": "Doporučení",
          "options": [
            {
              "direction": "positive",
              "text": "Doporučeno",
              "value": "recommend"
            },
            {
              "direction": "negative",
              "text": "Není doporučeno",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Někdy doporučeno",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Žádné doporučení",
              "value": "suggest_either"
            }
          ],
          "selectedOption": ""
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "Sledování a hodnocení",
          "details": {
            "content": "",
            "description": "Podrobné sledování a hodnocení"
          }
        },
        "monitoringAndEvaluationQI": {
          "content": "",
          "description": "Sledování a hodnocení"
        },
        "multipleChronicConditionsAndPolypharmacy": {
          "content": "",
          "description": "Více chronických onemocnění a polyfarmacie"
        },
        "multipleRecommendations": {
          "description": "Doporučení",
          "options": [
            {
              "direction": "negative",
              "text": "Silné doporučení proti intervenci",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Podmíněné doporučení proti intervenci",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Podmíněné doporučení buď pro intervenci, nebo pro komparaci",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Podmíněné doporučení pro intervenci",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Silné doporučení pro intervenci",
              "value": "recommend"
            }
          ],
          "recommendations": [
            {
              "content": "",
              "selectedOption": ""
            }
          ]
        },
        "rationale": {
          "content": "",
          "description": "Odůvodnění"
        },
        "rationaleConnectingIndirectEvidence": {
          "content": "",
          "description": "Existuje dobře zdokumentované jasné a explicitní odůvodnění, které spojuje nepřímé důkazy",
          "options": [
            {
              "text": "Ano",
              "value": "yes"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Ne",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "recommendation": {
          "content": "",
          "description": "Doporučení"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "Typ doporučení",
          "options": [
            {
              "direction": "negative",
              "text": "Silné doporučení proti intervenci",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Podmíněné doporučení proti intervenci",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Podmíněné doporučení buď pro intervenci, nebo pro komparaci",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Podmíněné doporučení pro intervenci",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Silné doporučení pro intervenci",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeInterventionMultipleChoice": {
          "description": "Typ doporučení",
          "options": [
            {
              "direction": "negative",
              "text": "Silné doporučení proti intervenci",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Podmíněné doporučení proti intervenci",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Podmíněné doporučení buď pro intervenci, nebo pro komparaci",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Podmíněné doporučení pro intervenci",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Silné doporučení pro intervenci",
              "value": "recommend"
            }
          ],
          "selectedOptions": {}
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "Typ doporučení",
          "options": [
            {
              "direction": "negative",
              "text": "Silné doporučení proti této variantě",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Podmíněné doporučení proti této variantě",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Podmíněné doporučení buď pro tuto variantu, nebo pro komparaci",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Podmíněné doporučení pro tuto variantu",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Silné doporučení pro tuto variantu",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "Výzkumné priority"
        },
        "restrictions": {
          "content": "",
          "description": "Omezení"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "Specifika některých podskupin"
        },
        "theMessageIsNecessary": {
          "content": "",
          "description": "Tato zpráva je potřebná s ohledem na současnou zdravotnickou praxi",
          "options": [
            {
              "text": "Ano",
              "value": "yes"
            },
            {
              "text": "Pravděpodobně ano",
              "value": "probably_yes"
            },
            {
              "text": "Pravděpodobně ne",
              "value": "probably_no"
            },
            {
              "text": "Ne",
              "value": "no"
            }
          ],
          "selectedOption": ""
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "Kliničtí pracovníci",
          "sections": {
            "background": {
              "name": "Východiska"
            },
            "detailedJustification": {
              "name": "Podrobné zdůvodnění"
            },
            "implementationConsiderations": {
              "name": "Poznámky k implementaci"
            },
            "justification": {
              "name": "Zdůvodnění"
            },
            "relatedRecommendations": {
              "name": "Související doporučení"
            },
            "subgroupConsiderations": {
              "name": "Hlediska týkající se různých skupin"
            },
            "summaryOfFindings": {
              "name": "Souhrn zjištění"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "Pacienti",
          "sections": {
            "relatedRecommendations": {
              "name": "Související doporučení"
            },
            "summaryOfFindings": {
              "name": "SoF (Summary of Findings, tj. souhrn zjištění)"
            },
            "whatItMeansForYou": {
              "name": "Co to pro vás znamená?",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Poraďte se se zdravotnickým pracovníkem"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "Co můžete dělat"
                }
              },
              "sectionsOrder": ["whatYouCanDo", "speakWithHCProfessional"]
            },
            "whoIsThisFor": {
              "name": "Pro koho je doporučení určeno?",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Toto doporučení je pro Vás, pokud:"
                }
              },
              "sectionsOrder": ["whoIsThisFor"]
            },
            "whyThisRecommendation": {
              "name": "Proč takovéto doporučení?",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "Dodatečné informace"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "Přínosy a rizika"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "Vysvětlení doporučení"
                }
              },
              "sectionsOrder": ["whyThisRecommendation", "benefitsAndHarms", "additionalInformation"]
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "Zákonodárci",
          "sections": {
            "assessment": {
              "name": "Hodnocení"
            },
            "background": {
              "description": "Podrobnosti k otázce pro doporučení",
              "details": {
                "name": "O tomto rozhodnutí",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "Datum"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "Subjekty s rozhodovací pravomocí"
                  },
                  "perspective": {
                    "content": "",
                    "description": "Perspektiva"
                  },
                  "setting": {
                    "content": "",
                    "description": "Prostředí"
                  }
                },
                "sectionsOrder": ["setting", "perspective", "decisionMakers", "date"]
              },
              "name": "Východiska"
            },
            "decision": {
              "content": "",
              "name": "Rozhodnutí",
              "options": [
                {
                  "text": "Plná implementace",
                  "value": "full_implementation"
                },
                {
                  "text": "Hodnocení dopadu",
                  "value": "impact_evaluation"
                },
                {
                  "text": "Pilotní studie",
                  "value": "pilot_study"
                },
                {
                  "text": "Odložit",
                  "value": "postpone"
                },
                {
                  "text": "Neimplementovat",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "Implementace"
            },
            "justification": {
              "name": "Zdůvodnění"
            },
            "monitoringAndEvaluation": {
              "name": "Sledování a hodnocení"
            },
            "relatedRecommendations": {
              "name": "Související doporučení"
            },
            "summaryOfFindings": {
              "name": "Souhrn zjištění"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "age": {
          "content": "",
          "name": "Věk"
        },
        "anticipatedOutcomes": {
          "content": "",
          "name": "Očekávané výstupy"
        },
        "background": {
          "content": "",
          "name": "Východiska"
        },
        "coi": {
          "content": "",
          "name": "Konflikt zájmů"
        },
        "comparison": {
          "content": "",
          "name": "Komparace"
        },
        "intent": {
          "content": "",
          "name": "Záměr"
        },
        "intervention": {
          "content": "",
          "name": "Intervence"
        },
        "linkedTreatments": {
          "content": "",
          "name": "Související léčby"
        },
        "mainOutcomes": {
          "content": "",
          "name": "Hlavní výstupy"
        },
        "option": {
          "content": "",
          "name": "Varianta"
        },
        "perspective": {
          "content": "",
          "name": "Perspektiva"
        },
        "population": {
          "content": "",
          "name": "Populace"
        },
        "problem": {
          "content": "",
          "name": "Problém"
        },
        "purpose": {
          "content": "",
          "name": "Účel testu"
        },
        "requirements": {
          "content": "",
          "name": "Požadavky"
        },
        "role": {
          "content": "",
          "name": "Úloha testu"
        },
        "setting": {
          "content": "",
          "name": "Prostředí"
        },
        "subgroups": {
          "content": "",
          "name": "Podskupiny"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
