Modal = require 'components/common/modal'
ValidationContainer = require 'components/common/validation_container'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ProjectsActions = require 'actions/projects_actions'
Translation = require 'components/mixins/translation'
utils = require 'base/lib/utils'
W = require 'when/when'
{
  checkIfEmailUsed,
  validateMemberModel,
  getAttributesFromEmail,
  createMember
} = require 'lib/members_helper'
{ DataTable, DataTableBody, DataTableRow, DataTableCell } = ReactComponents


SendProjectCopyModal = createReactClass
  displayName: 'SendProjectCopyModal'

  propTypes:
    projectId: PropTypes.string.isRequired
    onClose: PropTypes.func.isRequired

  mixins: [
    Translation 'projects:share_project_dialog'
  ]

  getInitialState: ->
    errors: Immutable.List()
    email: ''

  handleInputChange: (evt) ->
    @setState
      email: evt.target.value
      errors: @state.errors.clear()

  handleSubmit: ->
    email = utils.removeNonPrintableChars @state.email

    recipient =
      email: email
      lastName: getAttributesFromEmail(email).lastName
      accessRights: ['admin']
      panelMember: false

    @validateAndSendForm recipient

  validateAndSendForm: (recipient) ->
    recipientValidationErrors = validateMemberModel recipient

    if recipientValidationErrors.isEmpty()
      checkIfEmailUsed(recipient.email).then (isUsed) =>
        if isUsed
          @setState errors: @state.errors.clear()
          ProjectsActions.sendProjectCopy @props.projectId, recipient
          @props.onClose()
        else
          @setState errors: Immutable.List [ @i18n 'no_user_with_email' ]
    else
      @setState errors: Immutable.List [ recipientValidationErrors.get 'email' ]

  render: ->
    <Modal
      className='send-project-copy-dialog'
      isOpen={true}
      modalSize='standard'
      onRequestClose={@props.onClose}
      title={@i18n 'send_copy'}
    >
      <div className='email-form'>
        <label>
          <span>{@i18n 'email'}</span>
          <ValidationContainer errorMsg={@state.errors.join ', '}>
            <input type='email' autoFocus onChange={@handleInputChange} value={@state.email} />
          </ValidationContainer>
        </label>
      </div>
      <ApplyCancelButtons
        onApply={@handleSubmit}
        onCancel={@props.onClose}
        applyLabel={@i18n '/actions.send'}
      />
    </Modal>

module.exports = SendProjectCopyModal
