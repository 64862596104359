{ isValidLimit } = require 'lib/limits_helper'


LimitsWarning = ({ value, limit, message }) ->
  return null if isValidLimit(value, limit)
  <div className="limits-warning">{message}</div>

LimitsWarning.propTypes =
  value: PropTypes.number.isRequired
  limit: PropTypes.number.isRequired
  message: PropTypes.string.isRequired

module.exports = LimitsWarning
