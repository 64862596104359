var Code, KEY_MESSAGES_TAGS, NameWithCodes, QUESTION_TAGS, TAGS, boolean, documentId, initial, mapOf, oneOf, optional, shape, string, _ref;

shape = require('lib/db_docs/field_types/shape');

Code = require('lib/db_docs/field_types/code_type');

documentId = require('lib/db_docs/field_types/document_id');

_ref = require('lib/db_docs/field_types/built_in_types'), string = _ref.string, boolean = _ref.boolean;

initial = require('lib/db_docs/field_types/type_decorators').initial;

mapOf = require('lib/db_docs/field_types/map_of');

oneOf = require('lib/db_docs/field_types/one_of');

optional = shape.typeDecorators.optional;

QUESTION_TAGS = require('lib/overarching_question_helper').QUESTION_TAGS;

KEY_MESSAGES_TAGS = require('lib/key_messages_helper').KEY_MESSAGES_TAGS;

TAGS = _.chain(QUESTION_TAGS).union(KEY_MESSAGES_TAGS).uniq().value();

NameWithCodes = shape({
  id: documentId,
  codes: initial({})(mapOf(Code)),
  name: string,
  questionId: optional(documentId),
  type: oneOf(TAGS),
  included: optional(boolean)
});

module.exports = NameWithCodes;
