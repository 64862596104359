Input = require 'components/common/input'


LabeledInput = (props) ->
  { label, labelPosition } = props
  restProps = R.omit ['label', 'labelPosition'], props
  clsName = classNames 'labeled-input', "label-position-#{labelPosition}"

  <label className={clsName}>
    <span className='labeled-input__label'>{label}</span>
    <Input className='labeled-input__input' {...restProps} />
  </label>

LabeledInput.propTypes =
  label: PropTypes.string.isRequired
  labelPosition: PropTypes.oneOf(['left', 'right']).isRequired

LabeledInput.defaultProps =
  labelPosition: 'left'

module.exports = LabeledInput
