var DEFAULT_ORDER_NUMBER, QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE, createGroupUpdater, mediator, utils, _getMaxQuestionsOrdering;

QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE = require('lib/questions_helper').QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE;

utils = require('base/lib/utils');

mediator = require('mediator');

DEFAULT_ORDER_NUMBER = 0;

createGroupUpdater = function(groupsDoc) {
  var newGroupId;
  newGroupId = utils.generateGUID();
  groupsDoc.groups.push({
    caption: $.t('es:question.groups.new_group'),
    '_id': newGroupId
  });
  return groupsDoc;
};

_getMaxQuestionsOrdering = function(groupsDoc) {
  var questionsOrdering, _ref, _ref1;
  questionsOrdering = Immutable.fromJS((_ref = groupsDoc.questionsOrdering) != null ? _ref : {});
  return (_ref1 = questionsOrdering.max()) != null ? _ref1 : DEFAULT_ORDER_NUMBER;
};

module.exports = {
  updateGroupsDoc: function(questionType, updaterFn, projectId) {
    if (projectId == null) {
      projectId = mediator.project.id;
    }
    return mediator.services.storePersistenceAdapter.updateOrCreate(projectId, QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE[questionType], function(groupsDoc) {
      if (groupsDoc.groups == null) {
        groupsDoc.groups = [];
      }
      if (groupsDoc.mapping == null) {
        groupsDoc.mapping = {};
      }
      if (groupsDoc.questionsOrdering == null) {
        groupsDoc.questionsOrdering = {};
      }
      return updaterFn(groupsDoc);
    });
  },
  createGroupUpdater: createGroupUpdater,
  questionToGroupUpdater: function(qId, groupId) {
    return function(groupsDoc) {
      if (groupId != null) {
        groupsDoc.mapping[qId] = groupId;
      } else {
        groupsDoc = createGroupUpdater(groupsDoc);
        groupsDoc.mapping[qId] = _.last(groupsDoc.groups)['_id'];
      }
      return groupsDoc;
    };
  },
  removeQuestionFromGroupUpdater: function(qId) {
    return function(groupsDoc) {
      delete groupsDoc.mapping[qId];
      return groupsDoc;
    };
  },
  questionsOrderingUpdate: function(qId, orderNumber) {
    return function(groupsDoc) {
      groupsDoc.questionsOrdering[qId] = orderNumber != null ? orderNumber : _getMaxQuestionsOrdering(groupsDoc) + 1;
      return groupsDoc;
    };
  },
  getMaxQuestionsOrdering: function(questionType, projectId) {
    if (projectId == null) {
      projectId = mediator.project.id;
    }
    return mediator.services.storePersistenceAdapter.fetch(projectId, QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE[questionType]).then(_getMaxQuestionsOrdering)["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
      return DEFAULT_ORDER_NUMBER;
    });
  }
};
