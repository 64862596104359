var PublicationPlatformDocShape, arrayOf, docShape, documentId, initial, mapOf, number, optional;

arrayOf = require('lib/db_docs/field_types/array_of');

docShape = require('lib/db_docs/field_types/doc_shape');

documentId = require('lib/db_docs/field_types/document_id');

initial = require('lib/db_docs/field_types/type_decorators').initial;

mapOf = require('lib/db_docs/field_types/map_of');

number = require('lib/db_docs/field_types/built_in_types').number;

optional = require('lib/db_docs/field_types/shape').typeDecorators.optional;

PublicationPlatformDocShape = docShape({
  lastGuidelinePublicationDate: optional(number),
  lastQuestionsPublicationDate: initial({})(mapOf(number)),
  questionsPending: optional(arrayOf(documentId))
});

module.exports = PublicationPlatformDocShape;
