var GdtDocFieldType, TypeValidationError, oneOf, oneOfCheck;

GdtDocFieldType = require('lib/db_docs/field_types/FieldType');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

oneOfCheck = function(values) {
  return function(value) {
    var isValidValue, valuesString;
    isValidValue = values.some(function(v) {
      return _.isEqual(v, value);
    });
    if (isValidValue) {
      return value;
    }
    valuesString = values.map(JSON.stringify).join(', ');
    return new TypeValidationError({
      message: "Expected value to be one of the following: [" + valuesString + "], but \"" + value + "\" was provided.",
      validation: 'OneOfType validation'
    });
  };
};

oneOf = function(values) {
  var valuesString;
  valuesString = values.map(JSON.stringify).join(', ');
  return new GdtDocFieldType({
    name: "OneOfType(" + valuesString + ")",
    check: oneOfCheck(values),
    typeDefault: values[0]
  });
};

module.exports = oneOf;
