mediator = require 'mediator'
{ EditorBlock } = Draft

DocSection = createReactClass
  displayName: 'DocSectionBlock'

  _getSectionName: ->
    @props.block.getIn ['data', 'sectionName']

  shouldBeDisplayed: ->
    sectionName = @_getSectionName()
    @props.blockProps.isSectionActive sectionName

  render: ->
    return null unless @shouldBeDisplayed()
    sectionName = @_getSectionName()

    <div data-section-name={sectionName}>
      {if sectionName is 'document_title'
        <h1>{mediator.project.get 'name'}</h1>
      else
        <EditorBlock {...@props} />
      }
    </div>

module.exports = DocSection
