var CellFootnoteView, CellFootnotesAndReferencesView, Draggable, EditView, Footnotes, FootnotesService, NewReferenceView, Shortcuts, Tabs, View, getAttachmentUrl, mediator, outcomeCellTypes, template, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

View = require('base/views/view');

CellFootnoteView = require('views/evidence_syntheses/cell_footnote_view');

NewReferenceView = require('views/evidence_syntheses/new_reference_view');

template = require('views/templates/evidence_syntheses/cell_footnotes_and_references');

mediator = require('mediator');

Shortcuts = require('base/lib/traits/shortcuts');

utils = require('base/lib/utils');

Footnotes = require('models/evidence_syntheses/footnotes');

outcomeCellTypes = require('models/outcome/outcome_cell_types');

EditView = require('base/views/edit_view');

Draggable = require('base/lib/traits/draggable');

Tabs = require('lib/traits/tabs');

FootnotesService = require('lib/services/footnotes_service');

getAttachmentUrl = require('lib/references_utils').getAttachmentUrl;

module.exports = CellFootnotesAndReferencesView = (function(_super) {
  __extends(CellFootnotesAndReferencesView, _super);

  function CellFootnotesAndReferencesView() {
    this.show = __bind(this.show, this);
    this.renderFootnote = __bind(this.renderFootnote, this);
    this.onFocusLost = __bind(this.onFocusLost, this);
    this.referenceAdded = __bind(this.referenceAdded, this);
    this.close = __bind(this.close, this);
    this.saveAppliedChangesAndClose = __bind(this.saveAppliedChangesAndClose, this);
    this.applySaveAndClose = __bind(this.applySaveAndClose, this);
    this.addNewItem = __bind(this.addNewItem, this);
    this.onReferencesChange = __bind(this.onReferencesChange, this);
    return CellFootnotesAndReferencesView.__super__.constructor.apply(this, arguments);
  }

  CellFootnotesAndReferencesView.prototype.className = 'box footnotes-box';

  CellFootnotesAndReferencesView.prototype.template = template;

  CellFootnotesAndReferencesView.prototype.shortcuts = {
    'esc': 'close',
    'up': 'selectPrevFootnote',
    'down': 'selectNextFootnote',
    'ctrl+a': 'addFootnote',
    'ctrl+e': 'editActiveFootnote'
  };

  CellFootnotesAndReferencesView.prototype.initialize = function() {
    var tabs, _ref;
    CellFootnotesAndReferencesView.__super__.initialize.apply(this, arguments);
    this.enable(Shortcuts);
    this.enable(Draggable);
    tabs = ['footnotes', 'references'];
    this.enable(Tabs, {
      tabs: tabs,
      activeTab: (_ref = this.options.activeTab) != null ? _ref : 'footnotes'
    });
    this.questionModel = this.options.questionModel;
    this.footnotesService = new FootnotesService({
      model: this.questionModel
    });
    this.questionModel.store();
    this.footnotesBindingModel = this.options.footnotesBindingModel;
    this.subscribeEvent('focusLost', this.onFocusLost);
    mediator.colls.references.on('add', this.referenceAdded);
    this.delegate('mouseover', '.footnotes-list li', this.selectFootnoteOnMouseOver);
    this.delegate('click', this.stopPropagation);
    this.delegate('click', '.add-new', this.addNewItem);
    this.delegate('click', '.footnotes-list button.edit', this.editFootnote);
    this.delegate('click', '.footnotes-list button.save', this.applyActiveFootnoteChanges);
    this.delegate('click', '.footnotes-list button.delete', this.deleteFootnote);
    this.delegate('click', '.btn-cancel', this.cancelAppliedChangesAndClose);
    this.delegate('click', '.btn-save', this.saveAppliedChangesAndClose);
    this.delegate('click', 'button.clear-search', this.resetSearch);
    this.delegate('click', '.search-container .submit', this.handleSearch);
    this.delegate('keyup', '.search-container input', this.toggleClearSearch);
    this.on('tabChanged', this.tabChanged);
    this.newReferences = [];
    return this.setFootnotesList();
  };

  CellFootnotesAndReferencesView.prototype.isPopupView = function() {
    return true;
  };

  CellFootnotesAndReferencesView.prototype.footnotesEnabled = function(modelType, property) {
    var cellType, disabledProperties, isNotComplexCompound;
    if (modelType !== 'outcome') {
      return true;
    }
    if (property === 'quality' && mediator.services.switches.isOn('manualQuality')) {
      return true;
    }
    cellType = outcomeCellTypes[property];
    isNotComplexCompound = (cellType != null ? cellType.editType : void 0) !== EditView.TYPES.COMPOUND || ((cellType.formatterAttrsObserve == null) && (cellType.otherFormatters == null));
    disabledProperties = ['editSofOutcome'];
    return __indexOf.call(disabledProperties, property) < 0 && isNotComplexCompound;
  };

  CellFootnotesAndReferencesView.prototype.filterReferences = function(e) {
    var filterVal;
    filterVal = e.currentTarget.value;
    return this.$('.references-list > li').each(function(i, el) {
      var $el;
      $el = $(el);
      if (_.string.contains($el.find('label').text().toLowerCase(), filterVal.toLowerCase())) {
        return $el.show();
      } else {
        return $el.hide();
      }
    });
  };

  CellFootnotesAndReferencesView.prototype.setFootnotesList = function() {
    return this.footnotesList = this.getFootnotesList();
  };

  CellFootnotesAndReferencesView.prototype.getFootnotesList = function() {
    var note, notes, outcomeCellNotes, _i, _len, _ref, _ref1;
    notes = [];
    outcomeCellNotes = (_ref = this.options.footnotesBindingModel.get('footnotes')[this.options.property]) != null ? _ref : [];
    _ref1 = this.questionModel.get('footnotesList').getNotesList();
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      note = _ref1[_i];
      note.checked = outcomeCellNotes && outcomeCellNotes.indexOf(note.id) !== -1;
      if (!(note.id === Footnotes.prototype.noExplanationProvided && !note.checked)) {
        notes.push(note);
      }
    }
    return notes;
  };

  CellFootnotesAndReferencesView.prototype.getTemplateData = function() {
    var outcomeCellRefs, templateData, _ref;
    templateData = {};
    outcomeCellRefs = (_ref = this.footnotesBindingModel.get('references')[this.options.property]) != null ? _ref : [];
    templateData.noFootnotes = _.isEmpty(this.footnotesList);
    templateData.references = mediator.colls.references.sortBy(function(ref) {
      return -ref.get('lastUpdated');
    }).map(function(ref) {
      var id;
      id = ref.get('_id');
      return {
        id: id,
        text: ref.vancouverFormat(),
        checked: __indexOf.call(outcomeCellRefs, id) >= 0,
        attachmentLink: ref.get('attachment') ? getAttachmentUrl(mediator.project.id, id) : null
      };
    });
    return templateData;
  };

  CellFootnotesAndReferencesView.prototype.stopPropagation = function(e) {
    return e.stopPropagation();
  };

  CellFootnotesAndReferencesView.prototype.onReferencesChange = function(evt) {
    return this.render();
  };

  CellFootnotesAndReferencesView.prototype._filterItemsWithText = function(searchText, $items) {
    return $items.each(function(i, el) {
      var $el;
      $el = $(el);
      if (_.string.contains($el.find('label').text().toLowerCase(), searchText.toLowerCase())) {
        return $el.show();
      } else {
        return $el.hide();
      }
    });
  };

  CellFootnotesAndReferencesView.prototype._resetItemsFilter = function($items) {
    return $items.each(function(i, el) {
      return $(el).show();
    });
  };

  CellFootnotesAndReferencesView.prototype.handleSearch = function() {
    var searchText;
    searchText = this.$('.search-container input').val().trim();
    if (_.isEmpty(searchText)) {
      return;
    }
    this._filterItemsWithText(searchText, this.$('.references-list > li'));
    return this._filterItemsWithText(searchText, this.$('.footnotes-list > li'));
  };

  CellFootnotesAndReferencesView.prototype.toggleClearSearch = function() {
    if (this.$('.search-container input').val().length > 0) {
      return this.$('.clear-search').addClass('visible');
    } else {
      return this.$('.clear-search').removeClass('visible');
    }
  };

  CellFootnotesAndReferencesView.prototype.resetSearch = function() {
    this.$('.clear-search').toggleClass('visible');
    this.$('.search-container input').val('').focus();
    this._resetItemsFilter(this.$('.references-list > li'));
    return this._resetItemsFilter(this.$('.footnotes-list > li'));
  };

  CellFootnotesAndReferencesView.prototype.addNewItem = function(e) {
    var view;
    if (this.getActiveTab() === 'footnotes') {
      return this.addFootnote(e);
    } else {
      this.subview('newReference', view = new NewReferenceView);
      return view.promise().then((function(_this) {
        return function() {
          return _this.removeSubview('newReference');
        };
      })(this));
    }
  };

  CellFootnotesAndReferencesView.prototype.addFootnote = function(e) {
    var $footnote, footnote, newNoteId, renderIndex;
    this.applyActiveFootnoteChanges();
    newNoteId = this.questionModel.addFootnote();
    this.footnotesBindingModel.bindFootnote(newNoteId, this.options.property);
    this.setFootnotesList();
    footnote = _.findWhere(this.footnotesList, {
      id: newNoteId
    });
    renderIndex = _.pluck(this.footnotesList, 'id').indexOf(newNoteId);
    this.renderFootnote(footnote, renderIndex);
    $footnote = this.$el.find("li[data-footnote-id=" + newNoteId + "]");
    this.editFootnote(e, $footnote);
    return $footnote[0].scrollIntoView();
  };

  CellFootnotesAndReferencesView.prototype.editActiveFootnote = function(e) {
    e.preventDefault();
    return this.editFootnote(e, this.itemsRows.eq(this.activeFootnoteIdx));
  };

  CellFootnotesAndReferencesView.prototype.editFootnote = function(e, $footnoteElement) {
    var $editContainer, $editInput, footnoteId;
    this.applyActiveFootnoteChanges();
    this.setDefaultScope('textarea');
    if ($footnoteElement) {
      this.$editingFootnoteElement = $footnoteElement;
    } else {
      footnoteId = $(e.target).closest('li').data()['footnoteId'];
      this.$editingFootnoteElement = this.$el.find("li[data-footnote-id=" + footnoteId + "]");
    }
    if (this.$editingFootnoteElement.data()['special']) {
      this.$editingFootnoteElement = void 0;
      this.unsetDefaultScope();
      return;
    }
    this.$editingFootnoteElement.children('.display-footnote').toggleClass('hidden');
    $editContainer = this.$editingFootnoteElement.children('.edit-footnote');
    $editContainer.toggleClass('hidden');
    $editInput = $editContainer.find('.footnote-input-field');
    $editInput.focus();
    return autosize($editInput);
  };

  CellFootnotesAndReferencesView.prototype.deleteFootnote = function() {
    var footnoteId;
    if (!this.$editingFootnoteElement) {
      return;
    }
    footnoteId = this.$editingFootnoteElement.find('.footnote-input-field').attr('name');
    this.subview("footnote-" + footnoteId).dispose();
    this.footnotesBindingModel.unbindFootnote(footnoteId, this.options.property);
    this.questionModel.deleteFootnote(footnoteId);
    this.setFootnotesList();
    return this.closeEditFootnote();
  };

  CellFootnotesAndReferencesView.prototype.closeEditFootnote = function() {
    if (this.$editingFootnoteElement != null) {
      this.$editingFootnoteElement.children('.display-footnote').toggleClass('hidden');
      this.$editingFootnoteElement.children('.edit-footnote').toggleClass('hidden');
    }
    return this.$editingFootnoteElement = null;
  };

  CellFootnotesAndReferencesView.prototype.applyActiveFootnoteChanges = function() {
    var $footnoteInput, footnote, footnoteId, footnoteVal, renderIndex;
    if (!this.$editingFootnoteElement) {
      return;
    }
    this.unsetDefaultScope();
    this.newNotAppliedFootnote = false;
    $footnoteInput = this.$editingFootnoteElement.find('.footnote-input-field');
    footnoteId = parseInt($footnoteInput.attr('name'));
    footnoteVal = utils.removeLineBreaks($footnoteInput.val());
    this.questionModel.updateFootnote(footnoteId, footnoteVal);
    this.setFootnotesList();
    footnote = _.findWhere(this.footnotesList, {
      id: footnoteId
    });
    renderIndex = _.pluck(this.footnotesList, 'id').indexOf(footnoteId);
    this.closeEditFootnote();
    return this.renderFootnote(footnote, renderIndex);
  };

  CellFootnotesAndReferencesView.prototype.saveFootnotes = function() {
    return this.footnotesBindingModel.save();
  };

  CellFootnotesAndReferencesView.prototype.applySaveAndClose = function() {
    this.applyActiveFootnoteChanges();
    return this.saveAppliedChangesAndClose();
  };

  CellFootnotesAndReferencesView.prototype.forceFootnotesIfNecessary = function() {
    var _ref;
    if (!((_ref = this.footnotesBindingModel.get('footnotes')[this.options.property]) != null ? _ref.length : void 0)) {
      if (this.forceFootnote) {
        this.footnotesBindingModel.bindFootnote(Footnotes.prototype.noExplanationProvided, this.options.property);
        return this.saveFootnotes();
      }
    }
  };

  CellFootnotesAndReferencesView.prototype.updateOutcomeItemBindings = function(itemType, existingBindings) {
    var capItemType;
    capItemType = _.string.capitalize(itemType);
    return this.$el.find("li .display-" + itemType + " input[type=\"checkbox\"]").each((function(_this) {
      return function(idx, checkbox) {
        var $checkbox, checkboxValue, isChecked, itemId;
        $checkbox = $(checkbox);
        checkboxValue = $checkbox.attr('value');
        itemId = itemType === 'reference' ? checkboxValue : parseInt(checkboxValue);
        isChecked = $checkbox.prop('checked');
        if (isChecked && __indexOf.call(existingBindings, itemId) < 0) {
          return _this.footnotesBindingModel["bind" + capItemType](itemId, _this.options.property);
        } else if (__indexOf.call(existingBindings, itemId) >= 0 && !isChecked) {
          return _this.footnotesBindingModel["unbind" + capItemType](itemId, _this.options.property);
        }
      };
    })(this));
  };

  CellFootnotesAndReferencesView.prototype.updateOutcomeBindings = function() {
    var outcomeCellNotes, outcomeCellRefs, _ref, _ref1;
    if (this.changesCancelled) {
      return;
    }
    outcomeCellNotes = (_ref = this.footnotesBindingModel.get('footnotes')[this.options.property]) != null ? _ref : [];
    outcomeCellRefs = (_ref1 = this.footnotesBindingModel.get('references')[this.options.property]) != null ? _ref1 : [];
    this.updateOutcomeItemBindings('footnote', outcomeCellNotes);
    return this.updateOutcomeItemBindings('reference', outcomeCellRefs);
  };

  CellFootnotesAndReferencesView.prototype.cancelAppliedChangesAndClose = function() {
    this.changesCancelled = true;
    return this.close();
  };

  CellFootnotesAndReferencesView.prototype.saveAppliedChangesAndClose = function() {
    this.applyActiveFootnoteChanges();
    this.updateOutcomeBindings();
    this.footnotesService.automaticallySortFootnotes();
    this.footnotesService.automaticallySortReferences();
    this.questionModel.store();
    return this.saveFootnotes().then(this.close).otherwise(mediator.dialogs.fatalError);
  };

  CellFootnotesAndReferencesView.prototype.close = function() {
    if (this.disposed) {
      return;
    }
    if (this.changesCancelled) {
      this.forceFootnotesIfNecessary();
      this.closeEditFootnote();
      this.questionModel.restore();
      this.publishEvent('footnoteRestored');
      if (!_.isEmpty(this.newReferences)) {
        mediator.colls.references.remove(this.newReferences);
        this.footnotesService.automaticallySortReferences();
      }
    } else {
      this.forceFootnotesIfNecessary();
    }
    if (this.options.propertyView && !this.options.propertyView.disposed) {
      mediator.setFocus(this.options.propertyView, (function(_this) {
        return function() {
          _this.dispose();
          return mediator.publish('cellFootnotesClosed');
        };
      })(this));
    } else {
      this.dispose();
      mediator.publish('cellFootnotesClosed');
    }
    return mediator.actionsQueue.push("Closed CellFootnotesView");
  };

  CellFootnotesAndReferencesView.prototype.referenceAdded = function(newRef) {
    var refId;
    refId = newRef.get('_id');
    this.newReferences.push(refId);
    this.footnotesBindingModel.bindReference(refId, this.options.property);
    return this.render();
  };

  CellFootnotesAndReferencesView.prototype.onFocusLost = function(focusTo) {
    if (focusTo.constructor !== NewReferenceView) {
      return this.applySaveAndClose();
    }
  };

  CellFootnotesAndReferencesView.prototype.setActiveFootnote = function(idx) {
    if (!this.itemsRows.length) {
      return;
    }
    this.itemsRows.eq(this.activeFootnoteIdx).removeClass('active');
    this.activeFootnoteIdx = idx;
    this.itemsRows.eq(this.activeFootnoteIdx).addClass('active');
    return false;
  };

  CellFootnotesAndReferencesView.prototype.selectNextFootnote = function(e) {
    return this.setActiveFootnote(utils.cycleUp(this.activeFootnoteIdx, this.itemsRows.length));
  };

  CellFootnotesAndReferencesView.prototype.selectPrevFootnote = function(e) {
    return this.setActiveFootnote(utils.cycleDown(this.activeFootnoteIdx, this.itemsRows.length));
  };

  CellFootnotesAndReferencesView.prototype.selectFootnoteOnMouseOver = function(e) {
    return this.setActiveFootnote(this.itemsRows.index($(e.target).closest('li')));
  };

  CellFootnotesAndReferencesView.prototype.renderFootnotesList = function() {
    if (_.isEmpty(this.footnotesList)) {
      return;
    }
    return _.each(this.footnotesList, this.renderFootnote);
  };

  CellFootnotesAndReferencesView.prototype.renderFootnote = function(footnote, idx) {
    var container, containerMethod, id;
    id = footnote.id;
    container = idx === 0 ? this.$('ul.footnotes-list') : this.$('ul.footnotes-list').children()[idx - 1];
    if (container == null) {
      return;
    }
    containerMethod = idx === 0 ? 'prepend' : 'after';
    return this.subview("footnote-" + id, new CellFootnoteView({
      container: container,
      containerMethod: containerMethod,
      footnote: footnote
    }));
  };

  CellFootnotesAndReferencesView.prototype.tabChanged = function(activeTab) {
    return this.itemsRows = this.$("." + activeTab + "-list").find('li');
  };

  CellFootnotesAndReferencesView.prototype.afterRender = function() {
    this.itemsRows = this.$('.footnotes-list').find('li');
    this.activeFootnoteIdx = this.itemsRows.length ? 0 : -1;
    this.itemsRows.eq(0).addClass('active');
    return this.renderFootnotesList();
  };

  CellFootnotesAndReferencesView.prototype.show = function(options) {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        var cell, container, containerHeight, containerWidth, elHeight, elWidth, leftOffset, topOffset;
        options = options != null ? options : {};
        _this.render();
        cell = options.cell;
        if (!cell.length) {
          return;
        }
        container = options.container;
        container.append(_this.$el);
        leftOffset = cell.offset().left - container.offset().left;
        topOffset = cell.offset().top - container.offset().top;
        elWidth = _this.$el.outerWidth();
        elHeight = _this.$el.outerHeight();
        containerWidth = container.width();
        containerHeight = container.height();
        if (leftOffset + elWidth > containerWidth) {
          leftOffset = leftOffset - (leftOffset + elWidth - containerWidth);
        }
        if (topOffset + elHeight > containerHeight) {
          topOffset = topOffset - (topOffset + elHeight - containerHeight);
        }
        $(_this.$el).css({
          display: 'block',
          left: leftOffset,
          top: topOffset
        });
        _this.trigger('!fixLayout');
        return _this.makeDraggable();
      };
    })(this));
  };

  CellFootnotesAndReferencesView.prototype.dispose = function() {
    var _ref;
    if (this.undoNotifier) {
      this.undoNotifier.destroy();
    }
    if ((_ref = this.forceDialog) != null) {
      _ref.destroy();
    }
    this.footnotesList = null;
    this.footnotesService = null;
    mediator.colls.references.off('add', this.referenceAdded);
    return CellFootnotesAndReferencesView.__super__.dispose.apply(this, arguments);
  };

  return CellFootnotesAndReferencesView;

})(View);
