var OrganizationsComponent, OrganizationsView, ReactComponent, View, dbHelper, errorHandler, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ReactComponent = require('base/lib/traits/react_component');

OrganizationsComponent = require('components/organizations/organizations');

View = require('base/views/view');

mediator = require('mediator');

dbHelper = require('base/lib/db_helper');

errorHandler = require('lib/app_utils').errorHandler;

module.exports = OrganizationsView = (function(_super) {
  __extends(OrganizationsView, _super);

  function OrganizationsView() {
    this.renderOrganizationsScreen = __bind(this.renderOrganizationsScreen, this);
    this.handleSubscriptionSuccess = __bind(this.handleSubscriptionSuccess, this);
    return OrganizationsView.__super__.constructor.apply(this, arguments);
  }

  OrganizationsView.prototype.container = '#page-container';

  OrganizationsView.prototype.autoRender = true;

  OrganizationsView.prototype.initialize = function() {
    this._stopInputsPropagation = false;
    OrganizationsView.__super__.initialize.apply(this, arguments);
    mediator.publish('projectChanged', null);
    return this.enable(ReactComponent);
  };

  OrganizationsView.prototype.handleSubscriptionSuccess = function() {
    return dbHelper.replicateFromCentral(mediator.services.udb.getUdbName(mediator.user.get('name'))).then(this.renderOrganizationsScreen);
  };

  OrganizationsView.prototype.renderOrganizationsScreen = function() {
    return this.renderSimpleComponent(OrganizationsComponent, {
      organizations: R.map(R.last, R.toPairs(mediator.userOrganizations)),
      hasPersonalAccount: mediator.user.get('hasPersonalSubscription'),
      onSubscriptionSuccess: this.handleSubscriptionSuccess
    });
  };

  OrganizationsView.prototype.afterRender = function() {
    document.getElementById('page-container').classList.add('organizations-screen');
    OrganizationsView.__super__.afterRender.apply(this, arguments);
    $('.outer-container').addClass('organizations-screen-container');
    $('#topbar').css('display', 'none');
    return this.renderOrganizationsScreen();
  };

  OrganizationsView.prototype.dispose = function() {
    this.unmountComponent();
    $('.outer-container').removeClass('organizations-screen-container');
    document.getElementById('page-container').classList.remove('organizations-screen');
    $('#topbar').css('display', 'block');
    return OrganizationsView.__super__.dispose.apply(this, arguments);
  };

  return OrganizationsView;

})(View);
