var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"insert-options\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDiagnostic : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(5, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  <label for=\"picture\">\n    <input id=\"picture\" type=\"radio\" name=\"insert-object\" value=\"picture\">\n    <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "image", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n    <input id=\"file\" type=\"file\">\n    <button class=\"file btn\" disabled>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_file", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </label>\n</div>\n<div class=\"error hidden\">\n  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "unsupported_image_format", {"name":"i18n","hash":{},"data":data})))
    + "\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "evidence_short", "l1-sof", "l2-sof", "test_accuracy", {"name":"forList","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <label for=\""
    + escapeExpression(((helper = (helper = helpers.it || (depth0 != null ? depth0.it : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"it","hash":{},"data":data}) : helper)))
    + "\">\n        <input id=\""
    + escapeExpression(((helper = (helper = helpers.it || (depth0 != null ? depth0.it : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"it","hash":{},"data":data}) : helper)))
    + "\" type=\"radio\" name=\"insert-object\" value="
    + escapeExpression(((helper = (helper = helpers.it || (depth0 != null ? depth0.it : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"it","hash":{},"data":data}) : helper)))
    + ">\n        <span>\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, (depth0 != null ? depth0.it : depth0), {"name":"i18n","hash":{},"data":data})))
    + "\n        </span>\n      </label>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.forList || (depth0 && depth0.forList) || helperMissing).call(depth0, "relative_importance", "sof_short", "sof_v1", "sof_v2", "sof_v3", "sof_v4", {"name":"forList","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:recommendations.insert_modal", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<div class=\"row mt-20\">\n  <div class=\"col-6\">\n    <button class=\"btn btn-block btn-cancel cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n  <div class=\"col-6\">\n    <button type=\"submit\" class=\"btn btn-block btn-success\" disabled>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.insert", {"name":"i18n","hash":{},"data":data})))
    + "\n    </button>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}