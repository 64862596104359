ExternalMember = require 'components/panel_voice/external_member'
PanelMemberSelection = require 'components/panel_voice/panel_member_selection'
Translation = require "components/mixins/translation"
{instanceOf, string, oneOfType} = PropTypes
utils = require 'base/lib/utils'

PanelMemberSelectionWithExternalMembers = createReactClass

  displayName: "PanelMemberSelectionWithExternalMembers"

  mixins: [Translation("voting:voting")]

  propTypes:
    members: oneOfType([instanceOf(Immutable.List), instanceOf(Immutable.OrderedMap)]).isRequired
    questions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)]
    )

  getInitialState: ->
    selectedMembers: new Immutable.List()
    externalMembers: new Immutable.Map()

  updateMembersList: (selectedMembers) ->
    @setState {selectedMembers}

  addExternalMember: ->
    externalMembers = @state.externalMembers.set utils.generateGUID(),
      Immutable.fromJS
        email: ""
        checked: false
    @setState {externalMembers}

  updateAttribute: (memberId, attr, value) ->
    externalMembers = @state.externalMembers.update memberId, (member) ->
      member.set(attr, value)
    @setState {externalMembers}

  onRemove: (memberId) ->
    externalMembers = @state.externalMembers.delete memberId
    @setState {externalMembers}

  render: ->
    <div className="send-test">
      <h2>{@i18n "modals.send_test"}</h2>
      <PanelMemberSelection
        {...@props}
        updateMembersList={@updateMembersList}
        selectedMembers={@state.selectedMembers}
      />
      <div className="external-members">
        {@state.externalMembers.map (member, id) =>
          <ExternalMember
            key={id}
            member={member}
            memberId={id}
            checked={@state.externalMembers.getIn [id, 'checked'], false}
            updateAttribute={@updateAttribute}
            onRemove={@onRemove}
          />
        .toList()}
      </div>
      <button className="add-new-member pull-right" onClick={@addExternalMember}>
        {@i18n "modals.add_external_member"}
      </button>
      <div className="clearfix" />
    </div>

module.exports = PanelMemberSelectionWithExternalMembers
