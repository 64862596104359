EditorDecorators = require 'lib/editor_decorators'
GdtEditor = Editor.Editor
{ getImmutableJsOrStringContent } = require 'lib/immutable_utils'
{ getRecommendationDirectionAndStrength, getCertaintyOfEvidenceCircles } = require 'lib/services/covid_exporter/utils'
{ RECOMMENDATION_SECTION_APPENDICES_ORDER } = require 'lib/document_sections_v2_helper'
{ useCoffeeMemo, useI18n } = require 'lib/react_utils'

CertaintyOfEvidence = ({ question, recommendation }) ->
  { label, signs } = useCoffeeMemo [question, recommendation], ->
    { label, circles } = getCertaintyOfEvidenceCircles question, recommendation
    circles = _.map circles, (sign, idx) ->
      <span className="quality-sign" key={idx} dangerouslySetInnerHTML={{ __html: sign }} />
    { label, signs: circles }

  <div className="certainty-of-evidence">
    <div>{signs}</div>
    {label and <div>{label}</div>}
  </div>

CertaintyOfEvidence.propTypes =
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  recommendation: PropTypes.instanceOf(Immutable.Map).isRequired

RecommendationSectionContent = ({ question, recommendation, selectedAppendices }) ->
  i18n = useI18n('es:recommendations.metadataExtractionForm.fields')

  recommendationText = recommendation.getIn(
    ['templateData', 'conclusions', 'sections', 'recommendation', 'content']
  )
  [ _recommendationDirection, recommendationStrength ] =
    getRecommendationDirectionAndStrength recommendation.toJS()

  isAnyAppendixChecked = useCoffeeMemo [selectedAppendices], ->
    selectedAppendices.some _.identity

  <div className="recommendation">
    <div className="recommendation-text">
      <GdtEditor
        customDecorators={EditorDecorators.referenceDecorator}
        editorContent={getImmutableJsOrStringContent recommendationText}
        readOnly
      />
    </div>
    <div className="recommendation-attributes">
      <div>
        <div>{i18n 'certaintyOfEvidence'}</div>
        <CertaintyOfEvidence question={question} recommendation={recommendation} />
      </div>
      <div>
        <div>{i18n 'strengthOfRecommendation'}</div>
        <div>
          {if recommendationStrength
            i18n "/docsec:recommendation_strength.#{recommendationStrength}"
          else null}
        </div>
      </div>
    </div>
    {isAnyAppendixChecked and <div className="recommendation-appendices">
      <p className="recommendation-appendices__title">
        {i18n '/docsec:management.section_appendices.title'}
      </p>
      {_.chain RECOMMENDATION_SECTION_APPENDICES_ORDER
        .map (key) ->
          return null unless selectedAppendices.get key
          <p className="mt-10" key={key}>
            {i18n "/docsec:management.section_appendices.#{key}"}
          </p>
        .compact()
        .value()
      }
    </div>}
  </div>

RecommendationSectionContent.propTypes =
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  recommendation: PropTypes.instanceOf(Immutable.Map).isRequired
  selectedAppendices: PropTypes.instanceOf(Immutable.Map)

RecommendationSectionContent.defaultProps =
  selectedAppendices: Immutable.Map()

module.exports = RecommendationSectionContent
