var DiagnosticQuestionExporter, QuestionExporter, formatter, isDefined, statisticalUtils, utils, _dataSources, _studiesTypes, _testResults,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

statisticalUtils = require('lib/statistical_utils');

QuestionExporter = require('./question_exporter');

formatter = require('lib/formatters').outcome;

utils = require('./exporter_utils');

_studiesTypes = {
  'cross_sectional_cohort_type': 'CrossSectionalCohortAccuracyStudy',
  'case_control_type_accuracy': 'CaseControlAccuracyStudy',
  'cohort_and_case_control_type': 'CohortAndCaseControlStudy'
};

_dataSources = {
  'rangeFromStudies': 'DiagnosticRangeFromStudiesDataSource',
  'pooledAcrossStudies': 'DiagnosticPooledDataSource',
  'fromSingleStudy': 'DiagnosticSingleStudyDataSource'
};

_testResults = {
  'rangeFromStudies': 'RangeFromStudiesTestResult',
  'pooledAcrossStudies': 'PooledTestResult',
  'fromSingleStudy': 'SingleStudyTestResult'
};

isDefined = function(val) {
  return Boolean(val);
};

module.exports = DiagnosticQuestionExporter = (function(_super) {
  __extends(DiagnosticQuestionExporter, _super);

  function DiagnosticQuestionExporter(_originalQuestion, _referencesExporter) {
    var _ref;
    DiagnosticQuestionExporter.__super__.constructor.call(this, _originalQuestion, 'diagnostic', _referencesExporter);
    switch (this._originalQuestion['sourceOfDxData']) {
      case 'rangeFromStudies':
        this._sensitivity = 0;
        this._specificity = 0;
        this._sensitivityFrom = this._originalQuestion['pooledSensitivityRangeFrom'];
        this._sensitivityTo = this._originalQuestion['pooledSensitivityRangeTo'];
        this._specificityFrom = this._originalQuestion['pooledSpecificityRangeFrom'];
        this._specificityTo = this._originalQuestion['pooledSpecificityRangeTo'];
        if (this._hasComparatorTest()) {
          this._sensitivityC = 0;
          this._specificityC = 0;
          this._sensitivityFromC = this._originalQuestion['pooledSensitivityComparisonRangeFrom'];
          this._sensitivityToC = this._originalQuestion['pooledSensitivityComparisonRangeTo'];
          this._specificityFromC = this._originalQuestion['pooledSpecificityComparisonRangeFrom'];
          this._specificityToC = this._originalQuestion['pooledSpecificityComparisonRangeTo'];
        }
        break;
      default:
        this._sensitivity = this._originalQuestion['pooledSensitivity'];
        this._specificity = this._originalQuestion['pooledSpecificity'];
        this._sensitivityFrom = this._originalQuestion['pooledSensitivityFrom'];
        this._sensitivityTo = this._originalQuestion['pooledSensitivityTo'];
        this._specificityFrom = this._originalQuestion['pooledSpecificityFrom'];
        this._specificityTo = this._originalQuestion['pooledSpecificityTo'];
        if (this._hasComparatorTest()) {
          this._sensitivityC = this._originalQuestion['pooledSensitivityComparison'];
          this._specificityC = this._originalQuestion['pooledSpecificityComparison'];
          this._sensitivityFromC = this._originalQuestion['pooledSensitivityComparisonFrom'];
          this._sensitivityToC = this._originalQuestion['pooledSensitivityComparisonTo'];
          this._specificityFromC = this._originalQuestion['pooledSpecificityComparisonFrom'];
          this._specificityToC = this._originalQuestion['pooledSpecificityComparisonTo'];
        }
    }
    this._absDenominator = (_ref = this._originalQuestion['absDenominator']) != null ? _ref : 1000;
  }

  DiagnosticQuestionExporter.prototype._processPrevalence = function(key, index) {
    var characteristicsSuffix, explanations, prevalence, prevalenceFootnotes, references;
    characteristicsSuffix = 'Characteristics';
    explanations = this._originalQuestion['footnotes'];
    references = this._originalQuestion['references'];
    prevalenceFootnotes = this.findFootnotes(explanations, references, "prevalence" + index, 0);
    prevalence = {
      '@id': "_:p" + index,
      '@type': 'ConditionPrevalence',
      'value': this._originalQuestion[key],
      'name': this._originalQuestion["" + key + characteristicsSuffix]
    };
    if (prevalenceFootnotes.length > 0) {
      prevalence['footnote'] = prevalenceFootnotes;
    }
    if (prevalence.value) {
      return prevalence;
    }
  };

  DiagnosticQuestionExporter.prototype.exportPrevalence = function() {
    var exportedPrevalence, i, index, key, keys, p, _i, _j, _len, _len1;
    keys = ['prevalence1', 'prevalence2', 'prevalence3'];
    exportedPrevalence = _.compact((function() {
      var _i, _len, _results;
      _results = [];
      for (index = _i = 0, _len = keys.length; _i < _len; index = ++_i) {
        key = keys[index];
        _results.push(this._processPrevalence(key, index + 1));
      }
      return _results;
    }).call(this));
    this._prevalencesValues = {};
    for (i = _i = 0, _len = exportedPrevalence.length; _i < _len; i = ++_i) {
      p = exportedPrevalence[i];
      this._prevalencesValues[i] = p['value'];
    }
    this._prevalencesIds = {};
    for (i = _j = 0, _len1 = exportedPrevalence.length; _j < _len1; i = ++_j) {
      p = exportedPrevalence[i];
      this._prevalencesIds[i + 1] = p['@id'];
    }
    return exportedPrevalence;
  };

  DiagnosticQuestionExporter.prototype._hasComparatorTest = function() {
    var _ref;
    return (_ref = this._originalQuestion['comparatorTestPresent']) != null ? _ref : false;
  };

  DiagnosticQuestionExporter.prototype._processTest = function(key, check, index) {
    if (!check) {
      return null;
    }
    return {
      '@id': "_:t" + index,
      '@type': 'MedicalTest',
      'name': this.getValueAndFootnotes(this._originalQuestion, key)
    };
  };

  DiagnosticQuestionExporter.prototype.exportTests = function() {
    var checks, exportedTests, i, key, keys;
    keys = ['indexTest', 'comparatorTest', 'referenceTest'];
    checks = [true, this._hasComparatorTest(), isDefined(this._originalQuestion['referenceTest'])];
    exportedTests = _.compact((function() {
      var _i, _len, _results;
      _results = [];
      for (i = _i = 0, _len = keys.length; _i < _len; i = ++_i) {
        key = keys[i];
        _results.push(this._processTest(key, checks[i], i + 1));
      }
      return _results;
    }).call(this));
    this._tests = {
      'indexTest': '_:t1'
    };
    if (this._hasComparatorTest()) {
      this._tests['comparatorTest'] = '_:t2';
    }
    return exportedTests;
  };

  DiagnosticQuestionExporter.prototype._isDataReady = function() {
    return (this._tests != null) && (this._explanations != null) && (this._outcomes != null) && (this._prevalencesValues != null);
  };

  DiagnosticQuestionExporter.prototype._findOutcomeByName = function(name, outcomes) {
    return _.findWhere(outcomes, {
      'name': name
    });
  };

  DiagnosticQuestionExporter.prototype._exportTestParameters = function() {
    var comparatorTestParameters, indexTestParameters, parameters, referenceTestParameters;
    parameters = [];
    if (utils.existsAndNotEmpty(this._originalQuestion['indexTestCutOff'])) {
      indexTestParameters = {
        '@type': 'CutOffValue',
        'forTest': {
          '@id': '_:t1'
        },
        'value': this.getValueAndFootnotes(this._originalQuestion, 'indexTestCutOff')
      };
      parameters.push(indexTestParameters);
    }
    if (utils.existsAndNotEmpty(this._originalQuestion['comparatorTestCutOff'])) {
      comparatorTestParameters = {
        '@type': 'CutOffValue',
        'forTest': {
          '@id': '_:t2'
        },
        'value': this.getValueAndFootnotes(this._originalQuestion, 'comparatorTestCutOff')
      };
      parameters.push(comparatorTestParameters);
    }
    if (utils.existsAndNotEmpty(this._originalQuestion['referenceTestThreshold'] && utils.existsAndNotEmpty(this._originalQuestion['referenceTest']))) {
      referenceTestParameters = {
        '@type': 'CutOffValue',
        'forTest': {
          '@id': '_:t3'
        },
        'value': this.getValueAndFootnotes(this._originalQuestion, 'referenceTestThreshold')
      };
      parameters.push(referenceTestParameters);
    }
    if (parameters.length > 0) {
      return parameters;
    } else {
      return null;
    }
  };

  DiagnosticQuestionExporter.prototype._result = function(testId, testValue, prevalenceNum, footnotes) {
    var fromValue, prevalenceId, result, toValue, value;
    value = testValue[0], fromValue = testValue[1], toValue = testValue[2];
    prevalenceId = String(prevalenceNum + 1);
    result = {
      '@type': _testResults[this._originalQuestion['sourceOfDxData']],
      'denominator': this._absDenominator,
      'forPrevalence': {
        '@id': this._prevalencesIds[prevalenceId]
      },
      'forTest': {
        '@id': testId
      }
    };
    if (this._originalQuestion['sourceOfDxData'] === 'rangeFromStudies') {
      result['value'] = "" + fromValue[prevalenceNum] + " to " + toValue[prevalenceNum];
      result['lowerBound'] = fromValue[prevalenceNum];
      result['upperBound'] = toValue[prevalenceNum];
    } else {
      result['value'] = value[prevalenceNum];
      result['confidenceLevel'] = statisticalUtils.confidenceLevel;
      result['confidenceIntervalFrom'] = fromValue[prevalenceNum];
      result['confidenceIntervalTo'] = toValue[prevalenceNum];
    }
    if (footnotes.length > 0) {
      result['footnote'] = footnotes;
    }
    return result;
  };

  DiagnosticQuestionExporter.prototype._results = function(typeName, indexValue, comparatorValue) {
    var comparatorResults, comparatorTestId, explanations, getFootnotes, getFootnotesComparison, i, outcome, outcomes, p, prevalenceNums, r, references, results, testId, _i, _len, _ref, _ref1, _ref2, _ref3, _ref4;
    outcomes = (_ref = (_ref1 = this._originalQuestion) != null ? _ref1['outcomes'] : void 0) != null ? _ref : [];
    outcome = (_ref2 = this._findOutcomeByName(typeName, outcomes)) != null ? _ref2 : {};
    explanations = (_ref3 = outcome['footnotes']) != null ? _ref3 : {};
    references = (_ref4 = outcome['references']) != null ? _ref4 : {};
    testId = this._tests['indexTest'];
    prevalenceNums = (function() {
      var _ref5, _results;
      _ref5 = this._prevalencesValues;
      _results = [];
      for (i in _ref5) {
        p = _ref5[i];
        _results.push(parseInt(i));
      }
      return _results;
    }).call(this);
    getFootnotes = (function(_this) {
      return function(i) {
        return _this.findFootnotes(explanations, references, "diagnosticEffect-index" + (i + 1), 0);
      };
    })(this);
    getFootnotesComparison = (function(_this) {
      return function(i) {
        return _this.findFootnotes(explanations, references, "diagnosticEffect-comparison" + (i + 1), 0);
      };
    })(this);
    results = (function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = prevalenceNums.length; _i < _len; _i++) {
        i = prevalenceNums[_i];
        _results.push(this._result(testId, indexValue, i, getFootnotes(i)));
      }
      return _results;
    }).call(this);
    if (comparatorValue != null) {
      comparatorTestId = this._tests['comparatorTest'];
      comparatorResults = (function() {
        var _i, _len, _results;
        _results = [];
        for (_i = 0, _len = prevalenceNums.length; _i < _len; _i++) {
          i = prevalenceNums[_i];
          _results.push(this._result(comparatorTestId, comparatorValue, i, getFootnotesComparison(i)));
        }
        return _results;
      }).call(this);
      for (_i = 0, _len = comparatorResults.length; _i < _len; _i++) {
        r = comparatorResults[_i];
        results.push(r);
      }
    }
    return results;
  };

  DiagnosticQuestionExporter.prototype._dataTP = function() {
    var cVal, diagTP, i, p, val;
    diagTP = (function(_this) {
      return function(sensitivity, prevalence) {
        return statisticalUtils.diagnosticEffectTP(sensitivity, prevalence, _this._absDenominator);
      };
    })(this);
    val = [
      (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTP(this._sensitivity, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTP(this._sensitivityFrom, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTP(this._sensitivityTo, p));
        }
        return _results;
      }).call(this)
    ];
    cVal = null;
    if (this._hasComparatorTest()) {
      cVal = [
        (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTP(this._sensitivityC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTP(this._sensitivityFromC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTP(this._sensitivityToC, p));
          }
          return _results;
        }).call(this)
      ];
    }
    return this._results('TP', val, cVal);
  };

  DiagnosticQuestionExporter.prototype._dataFN = function() {
    var cVal, diagFN, i, p, val;
    diagFN = (function(_this) {
      return function(sensitivity, prevalence) {
        return statisticalUtils.diagnosticEffectFN(sensitivity, prevalence, _this._absDenominator);
      };
    })(this);
    val = [
      (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFN(this._sensitivity, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFN(this._sensitivityFrom, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFN(this._sensitivityTo, p));
        }
        return _results;
      }).call(this)
    ];
    cVal = null;
    if (this._hasComparatorTest()) {
      cVal = [
        (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFN(this._sensitivityC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFN(this._sensitivityFromC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFN(this._sensitivityToC, p));
          }
          return _results;
        }).call(this)
      ];
    }
    return this._results('FN', val, cVal);
  };

  DiagnosticQuestionExporter.prototype._dataTN = function() {
    var cVal, diagTN, i, p, val;
    diagTN = (function(_this) {
      return function(specificity, prevalence) {
        return statisticalUtils.diagnosticEffectTN(specificity, prevalence, _this._absDenominator);
      };
    })(this);
    val = [
      (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTN(this._specificity, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTN(this._specificityFrom, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagTN(this._specificityTo, p));
        }
        return _results;
      }).call(this)
    ];
    cVal = null;
    if (this._hasComparatorTest()) {
      cVal = [
        (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTN(this._specificityC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTN(this._specificityFromC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagTN(this._specificityToC, p));
          }
          return _results;
        }).call(this)
      ];
    }
    return this._results('TN', val, cVal);
  };

  DiagnosticQuestionExporter.prototype._dataFP = function() {
    var cVal, diagFP, i, p, val;
    diagFP = (function(_this) {
      return function(specificity, prevalence) {
        return statisticalUtils.diagnosticEffectFP(specificity, prevalence, _this._absDenominator);
      };
    })(this);
    val = [
      (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFP(this._specificity, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFP(this._specificityFrom, p));
        }
        return _results;
      }).call(this), (function() {
        var _ref, _results;
        _ref = this._prevalencesValues;
        _results = [];
        for (i in _ref) {
          p = _ref[i];
          _results.push(diagFP(this._specificityTo, p));
        }
        return _results;
      }).call(this)
    ];
    cVal = null;
    if (this._hasComparatorTest()) {
      cVal = [
        (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFP(this._specificityC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFP(this._specificityFromC, p));
          }
          return _results;
        }).call(this), (function() {
          var _ref, _results;
          _ref = this._prevalencesValues;
          _results = [];
          for (i in _ref) {
            p = _ref[i];
            _results.push(diagFP(this._specificityToC, p));
          }
          return _results;
        }).call(this)
      ];
    }
    return this._results('FP', val, cVal);
  };

  DiagnosticQuestionExporter.prototype._processQuality = function(outcome, otherConsiderationsWithOnlyPublicationBias) {
    var doseResponseGradient, doseResponseGradientFootnotes, doseResponseGradientValue, explanations, imprecision, imprecisionFootnotes, imprecisionValue, inconsistency, inconsistencyFootnotes, inconsistencyValue, indirectness, indirectnessFootnotes, indirectnessValue, largeEffect, largeEffectFootnotes, largeEffectValue, otherConsiderations, plausibleConfounding, plausibleConfoundingFootnotes, plausibleConfoundingValue, publicationBias, publicationBiasFootnotes, publicationBiasValue, quality, qualityValue, references, riskOfBias, riskOfBiasFootnotes, riskOfBiasValue, _ref, _ref1, _ref10, _ref11, _ref12, _ref13, _ref14, _ref15, _ref2, _ref3, _ref4, _ref5, _ref6, _ref7, _ref8, _ref9;
    if (otherConsiderationsWithOnlyPublicationBias == null) {
      otherConsiderationsWithOnlyPublicationBias = false;
    }
    qualityValue = outcome['quality'];
    explanations = outcome['footnotes'];
    references = outcome['references'];
    riskOfBias = (_ref = outcome['riskOfBias']) != null ? _ref['label'] : void 0;
    riskOfBiasValue = (_ref1 = outcome['riskOfBias']) != null ? _ref1['score'] : void 0;
    riskOfBiasFootnotes = this.findFootnotes(explanations, references, 'riskOfBias', riskOfBiasValue);
    inconsistency = (_ref2 = outcome['inconsistency']) != null ? _ref2['label'] : void 0;
    inconsistencyValue = (_ref3 = outcome['inconsistency']) != null ? _ref3['score'] : void 0;
    inconsistencyFootnotes = this.findFootnotes(explanations, references, 'inconsistency', inconsistencyValue);
    indirectness = (_ref4 = outcome['indirectness']) != null ? _ref4['label'] : void 0;
    indirectnessValue = (_ref5 = outcome['indirectness']) != null ? _ref5['score'] : void 0;
    indirectnessFootnotes = this.findFootnotes(explanations, references, 'indirectness', indirectnessValue);
    imprecision = (_ref6 = outcome['imprecision']) != null ? _ref6['label'] : void 0;
    imprecisionValue = (_ref7 = outcome['imprecision']) != null ? _ref7['score'] : void 0;
    imprecisionFootnotes = this.findFootnotes(explanations, references, 'imprecision', imprecisionValue);
    publicationBias = this._getQualityOptionForLabel((_ref8 = outcome['publicationBias']) != null ? _ref8['label'] : void 0, 'publicationBias');
    publicationBiasValue = (_ref9 = outcome['publicationBias']) != null ? _ref9['score'] : void 0;
    publicationBiasFootnotes = this.findFootnotes(explanations, references, 'publicationBias', publicationBiasValue);
    quality = {
      '@type': 'GradeQuality',
      'value': qualityValue,
      'name': this._qualityNameForValue(qualityValue),
      'riskOfBias': {
        '@type': this._qualityLabelForScore(riskOfBiasValue),
        'name': this._getQualityOptionForLabel(riskOfBias)
      },
      'inconsistency': {
        '@type': this._qualityLabelForScore(inconsistencyValue),
        'name': this._getQualityOptionForLabel(inconsistency)
      },
      'indirectness': {
        '@type': this._qualityLabelForScore(indirectnessValue),
        'name': this._getQualityOptionForLabel(indirectness)
      },
      'imprecision': {
        '@type': this._qualityLabelForScore(imprecisionValue),
        'name': this._getQualityOptionForLabel(imprecision)
      },
      'otherConsiderations': {
        'name': formatter.otherConsiderations(outcome),
        'publicationBias': {
          '@type': this._qualityLabelForScore(publicationBiasValue),
          'name': publicationBias
        }
      }
    };
    if (riskOfBiasFootnotes.length > 0) {
      quality['riskOfBias']['footnote'] = riskOfBiasFootnotes;
    }
    if (inconsistencyFootnotes.length > 0) {
      quality['inconsistency']['footnote'] = inconsistencyFootnotes;
    }
    if (indirectnessFootnotes.length > 0) {
      quality['indirectness']['footnote'] = indirectnessFootnotes;
    }
    if (imprecisionFootnotes.length > 0) {
      quality['imprecision']['footnote'] = imprecisionFootnotes;
    }
    if (publicationBiasFootnotes.length > 0) {
      quality['otherConsiderations']['publicationBias']['footnote'] = publicationBiasFootnotes;
    }
    if (!otherConsiderationsWithOnlyPublicationBias) {
      otherConsiderations = quality['otherConsiderations'];
      doseResponseGradient = this._getQualityOptionForLabel((_ref10 = outcome['doseResponseGradient']) != null ? _ref10['label'] : void 0);
      doseResponseGradientValue = (_ref11 = outcome['doseResponseGradient']) != null ? _ref11['score'] : void 0;
      doseResponseGradientFootnotes = this.findFootnotes(explanations, references, 'doseResponseGradient', doseResponseGradientValue);
      otherConsiderations['doseResponseGradient'] = {
        '@type': this._qualityLabelForScore(doseResponseGradientValue),
        'name': doseResponseGradient
      };
      if (doseResponseGradientFootnotes.length > 0) {
        otherConsiderations['doseResponseGradient']['footnote'] = doseResponseGradientFootnotes;
      }
      plausibleConfounding = this._getQualityOptionForLabel((_ref12 = outcome['plausibleConfounding']) != null ? _ref12['label'] : void 0);
      plausibleConfoundingValue = (_ref13 = outcome['plausibleConfounding']) != null ? _ref13['score'] : void 0;
      plausibleConfoundingFootnotes = this.findFootnotes(explanations, references, 'plausibleConfounding', plausibleConfoundingValue);
      otherConsiderations['plausibleConfounding'] = {
        '@type': this._qualityLabelForScore(plausibleConfoundingValue),
        'name': plausibleConfounding
      };
      if (plausibleConfoundingFootnotes.length > 0) {
        otherConsiderations['plausibleConfounding']['footnote'] = plausibleConfoundingFootnotes;
      }
      largeEffect = this._getQualityOptionForLabel((_ref14 = outcome['largeEffect']) != null ? _ref14['label'] : void 0);
      largeEffectValue = (_ref15 = outcome['largeEffect']) != null ? _ref15['score'] : void 0;
      largeEffectFootnotes = this.findFootnotes(explanations, references, 'largeEffect', largeEffectValue);
      otherConsiderations['largeEffect'] = {
        '@type': this._qualityLabelForScore(largeEffectValue),
        'name': largeEffect
      };
      if (largeEffectFootnotes.length > 0) {
        otherConsiderations['largeEffect']['footnote'] = largeEffectFootnotes;
      }
    }
    return quality;
  };

  DiagnosticQuestionExporter.prototype._evidenceSummaryForOutcome = function(outcome, outcomeResult) {
    var comments, commentsFootnotes, explanations, importanceFootnotes, outcomeId, references, studyDesignFootnotes, studyType, summary, testParameters, _ref, _ref1, _ref2, _ref3;
    studyType = (_ref = outcome['designStudies']) != null ? _ref['value'] : void 0;
    outcomeId = this._outcomes[outcome['_id']];
    explanations = outcome['footnotes'];
    references = outcome['references'];
    comments = outcome['comments'];
    commentsFootnotes = this.findFootnotes(explanations, references, 'comments', 0);
    importanceFootnotes = this.findFootnotes(explanations, references, 'importance', 0);
    studyDesignFootnotes = this.findFootnotes(explanations, references, 'designStudies', 0);
    summary = {
      '@type': 'DiagnosticData',
      'forOutcome': {
        '@id': outcomeId
      },
      'numberOfStudies': this.getValueAndFootnotes(outcome, 'noOfStudies'),
      'patientGroup': {
        '@type': 'ObservedGroup',
        'totalCount': this.getValueAndFootnotes(outcome, 'noOfPatients')
      },
      'studyDesign': {
        '@type': _studiesTypes[studyType],
        'name': $.t("es:outcome." + studyType)
      },
      'effectSummary': {
        '@type': _dataSources[this._originalQuestion['sourceOfDxData']],
        'testResult': outcomeResult
      },
      'quality': this._processQuality(outcome)
    };
    testParameters = this._exportTestParameters();
    if (outcome['importance']) {
      summary['importance'] = {
        '@type': 'GradeImportance',
        'value': outcome['importance'],
        'name': outcome['importanceLabel']
      };
      if (importanceFootnotes.length > 0) {
        if ((_ref1 = summary['importance']) != null) {
          _ref1['footnote'] = importanceFootnotes;
        }
      }
    }
    if (testParameters != null) {
      summary['testParameter'] = testParameters;
    }
    if (comments) {
      summary['comments'] = {
        'value': comments
      };
      if (commentsFootnotes.length > 0) {
        if ((_ref2 = summary['comments']) != null) {
          _ref2['footnote'] = commentsFootnotes;
        }
      }
    }
    if (studyDesignFootnotes.length > 0) {
      if ((_ref3 = summary['studyDesign']) != null) {
        _ref3['footnote'] = studyDesignFootnotes;
      }
    }
    return summary;
  };

  DiagnosticQuestionExporter.prototype._copyQualityAndBasicProperties = function(from, to) {
    var propertiesToCopy, property, _i, _len;
    propertiesToCopy = ['quality', 'noOfStudies', 'noOfPatients', 'riskOfBias', 'inconsistency', 'indirectness', 'imprecision', 'publicationBias', 'plausibleConfounding', 'largeEffect', 'doseResponseGradient', 'designStudies'];
    for (_i = 0, _len = propertiesToCopy.length; _i < _len; _i++) {
      property = propertiesToCopy[_i];
      to[property] = from[property];
    }
    return to;
  };

  DiagnosticQuestionExporter.prototype._processInconclusive = function(outcome) {
    var comments, commentsFootnotes, effectSummaryFootnotes, explanations, importanceFootnotes, references, summary, _ref, _ref1, _ref2;
    comments = outcome['comments'];
    explanations = (_ref = outcome['footnotes']) != null ? _ref : {};
    references = (_ref1 = outcome['references']) != null ? _ref1 : {};
    effectSummaryFootnotes = this.findFootnotes(explanations, references, 'inconclusivePrevalencesDesc', 0);
    commentsFootnotes = this.findFootnotes(explanations, references, 'comments', 0);
    importanceFootnotes = this.findFootnotes(explanations, references, 'importance', 0);
    summary = {
      '@type': 'NarrativeDiagnosticData',
      'forOutcome': {
        '@id': this._outcomes[outcome['_id']]
      },
      'numberOfStudies': this.getValueAndFootnotes(outcome, 'noOfStudies'),
      'patientGroup': {
        '@type': 'ObservedGroup',
        'totalCount': this.getValueAndFootnotes(outcome, 'noOfPatients')
      },
      'effectSummary': {
        '@type': _dataSources[this._originalQuestion['sourceOfDxData']],
        'narrativeSummary': {
          'value': outcome['inconclusivePrevalencesDesc']
        }
      }
    };
    if (outcome['importance']) {
      summary['importance'] = {
        '@type': 'GradeImportance',
        'value': outcome['importance'],
        'name': outcome['importanceLabel']
      };
      if (importanceFootnotes.length > 0) {
        if ((_ref2 = summary['importance']) != null) {
          _ref2['footnote'] = importanceFootnotes;
        }
      }
    }
    if (comments) {
      summary['comments'] = {
        'value': comments
      };
      if (commentsFootnotes.length > 0) {
        summary['comments']['footnote'] = commentsFootnotes;
      }
    }
    if (effectSummaryFootnotes.length > 0) {
      summary['effectSummary']['narrativeSummary']['footnote'] = effectSummaryFootnotes;
    }
    return summary;
  };

  DiagnosticQuestionExporter.prototype._processComplications = function(outcome) {
    var comments, commentsFootnotes, effectSummaryFootnotes, explanations, importanceFootnotes, references, summary, _ref, _ref1, _ref2;
    comments = outcome['comments'];
    explanations = (_ref = outcome['footnotes']) != null ? _ref : {};
    references = (_ref1 = outcome['references']) != null ? _ref1 : {};
    effectSummaryFootnotes = this.findFootnotes(explanations, references, 'complicationsPrevalencesDesc', 0);
    commentsFootnotes = this.findFootnotes(explanations, references, 'comments', 0);
    importanceFootnotes = this.findFootnotes(explanations, references, 'importance', 0);
    summary = {
      '@type': 'NarrativeDiagnosticData',
      'forOutcome': {
        '@id': this._outcomes[outcome['_id']]
      },
      'numberOfStudies': this.getValueAndFootnotes(outcome, 'noOfStudies'),
      'patientGroup': {
        '@type': 'ObservedGroup',
        'totalCount': this.getValueAndFootnotes(outcome, 'noOfPatients')
      },
      'effectSummary': {
        '@type': _dataSources[this._originalQuestion['sourceOfDxData']],
        'narrativeSummary': {
          'value': outcome['complicationsPrevalencesDesc']
        }
      },
      'quality': this._processQuality(outcome, true)
    };
    if (outcome['importance']) {
      summary['importance'] = {
        '@type': 'GradeImportance',
        'value': outcome['importance'],
        'name': outcome['importanceLabel']
      };
      if (importanceFootnotes.length > 0) {
        if ((_ref2 = summary['importance']) != null) {
          _ref2['footnote'] = importanceFootnotes;
        }
      }
    }
    if (comments) {
      summary['comments'] = {
        'value': comments
      };
      if (commentsFootnotes.length > 0) {
        summary['comments']['footnote'] = commentsFootnotes;
      }
    }
    if (effectSummaryFootnotes.length > 0) {
      summary['effectSummary']['narrativeSummary']['footnote'] = effectSummaryFootnotes;
    }
    return summary;
  };

  DiagnosticQuestionExporter.prototype._processSummary = function(outcomes) {
    var FN, FNEvidenceSummary, FNResults, FP, FPEvidenceSummary, FPResults, TN, TNEvidenceSummary, TNResults, TP, TPEvidenceSummary, TPResults, complications, complicationsSummary, inconclusive, inconclusiveSummary;
    TP = this._findOutcomeByName('TP', outcomes);
    FN = this._findOutcomeByName('FN', outcomes);
    TN = this._findOutcomeByName('TN', outcomes);
    FP = this._findOutcomeByName('FP', outcomes);
    inconclusive = this._findOutcomeByName('Inconclusive', outcomes);
    complications = this._findOutcomeByName('Complications', outcomes);
    FN = this._copyQualityAndBasicProperties(TP, FN);
    FP = this._copyQualityAndBasicProperties(TN, FP);
    TPResults = this._dataTP();
    FNResults = this._dataFN();
    TNResults = this._dataTN();
    FPResults = this._dataFP();
    TPEvidenceSummary = this._evidenceSummaryForOutcome(TP, TPResults);
    FNEvidenceSummary = this._evidenceSummaryForOutcome(FN, FNResults);
    TNEvidenceSummary = this._evidenceSummaryForOutcome(TN, TNResults);
    FPEvidenceSummary = this._evidenceSummaryForOutcome(FP, FPResults);
    inconclusiveSummary = this._processInconclusive(inconclusive);
    complicationsSummary = this._processComplications(complications);
    return [].concat.apply([], [TPEvidenceSummary, FNEvidenceSummary, TNEvidenceSummary, FPEvidenceSummary, inconclusiveSummary, complicationsSummary]);
  };

  DiagnosticQuestionExporter.prototype.exportEvidenceSummary = function() {
    var originalOutcomes;
    originalOutcomes = this._originalQuestion['outcomes'];
    if (!this._isDataReady()) {
      this.exportExplanations();
      this.exportOutcomes();
      this.exportPrevalence();
      this.exportTests();
    }
    return this._processSummary(originalOutcomes);
  };

  return DiagnosticQuestionExporter;

})(QuestionExporter);
