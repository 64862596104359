MarkerCell = require 'components/scope/outcomes/approval_outcome_marker_cell'
CommentButton = require 'components/common/empty_comment_button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

ApprovalOutcomeTitleRow = createReactClass
  displayName: 'ApprovalOutcomeTitleRow'

  propTypes:
    outcome: PropTypes.instanceOf(Immutable.Map).isRequired
    todosCount: PropTypes.number
    questionOnly: PropTypes.bool
    readOnly: PropTypes.bool
    onCommentToggle: PropTypes.func

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  # TODO: very much alike same func in Questions Generation
  _getApprovalRatio: ->
    approvals = @props.outcome.get 'approvals', Immutable.Map()
    return null if approvals.isEmpty()
    approved = approvals.reduce ((count, approved) -> if approved then count + 1 else count), 0
    ratio = Math.floor approved/approvals.size*100

  # TODO: very much alike same func in Questions Generation
  renderApprovalRatio: (approvalRatio) ->
    return @i18n 'n_a' unless approvalRatio?
    hasDisagreementResolved = @props.outcome.get 'importanceDisagreementResolved', false
    ratioClass = classNames
      'red': approvalRatio? and approvalRatio < 100 and not hasDisagreementResolved

    <div className={ratioClass}>
      {"#{approvalRatio}%"}
      {if @props.todosCount > 0
        <div className='todos-count'>
          {"#{@i18n '../questions.approval.to_do'} (#{@props.todosCount})"}
        </div>
      }
    </div>

  renderCommentsTogglee: ->
    return null unless @props.onCommentToggle?

    <CommentButton onClick={@props.onCommentToggle} />

  render: ->
    { outcome, onCommentToggle } = @props
    approvalRatio = @_getApprovalRatio()
    notAllApproved = approvalRatio? and approvalRatio < 100
    approvedCls = classNames 'approved', 'no-border-right': notAllApproved
    commentsCls = classNames 'comments', 'no-border-left': notAllApproved
    outcomeNameCls = classNames 'with-drag-marker': not @props.readOnly

    <tr className='outcome-title-row'>
      <MarkerCell importance={outcome.get 'importance'} />
      <td colSpan={3 if @props.questionOnly}>
        <div className={outcomeNameCls}>{outcome.get 'name'}</div>
      </td>
      {unless @props.questionOnly
        [
          <td className={approvedCls} onClick={onCommentToggle if notAllApproved} key=1>
            {@renderApprovalRatio approvalRatio}
          </td>
          <td className={commentsCls} onClick={onCommentToggle if notAllApproved} key=2>
            {@renderCommentsTogglee()}
          </td>
        ]
      }
    </tr>

module.exports = ApprovalOutcomeTitleRow
