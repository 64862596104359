Translation = require 'components/mixins/translation'

Toolbar = createReactClass
  displayName: 'OverarchingQuestionCreatorToolbar'
  mixins: [Translation('overarching_questions:labels')]

  render: ->
    <div className="px-10">
      <h3>{@i18n 'creator'}</h3>
    </div>

module.exports = Toolbar
