var SelectableAttrList, SelectionView, ViewTrait, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('base/lib/traits/view_trait');

SelectionView = require('base/views/selection_view');

mediator = require('mediator');

module.exports = SelectableAttrList = (function(_super) {
  __extends(SelectableAttrList, _super);

  function SelectableAttrList() {
    return SelectableAttrList.__super__.constructor.apply(this, arguments);
  }

  SelectableAttrList.prototype.inputsSelector = 'input, select, textarea';

  SelectableAttrList.prototype.apply = function(view) {
    SelectableAttrList.__super__.apply.apply(this, arguments);
    this._addFunction(view, 'showSelectionList');
    this._addFunction(view, '_prepareOptionsList');
    this._addFunction(view, 'createSelectionSubview');
    this._addFunction(view, 'afterRender');
    view.delegate('click', 'span.selection-list-value', function(e) {
      return $(e.target).trigger('focus');
    });
    view.delegate('click', 'span.selection-list-value', view.showSelectionList.bind(view));
    view.delegate('focus', '.selection-list-value', function(e) {
      return $(e.target).addClass('focused');
    });
    return view.delegate('blur', '.selection-list-value', function(e) {
      return $(e.target).removeClass('focused');
    });
  };

  SelectableAttrList.prototype.showSelectionList = function(originalFunction, trait, e) {
    var $target;
    if (originalFunction) {
      return originalFunction.call(this, e);
    } else {
      e.stopPropagation();
      $target = $(e.target).closest('.selection-list-value');
      if ($target.attr('disabled')) {
        return;
      }
      return this.createSelectionSubview($target);
    }
  };

  SelectableAttrList.prototype.createSelectionSubview = function(originalFunction, trait, $target, optionsList) {
    if (optionsList == null) {
      optionsList = this._prepareOptionsList($target.find('span.selection-list-options > option'));
    }
    return mediator.setFocus(this, (function(_this) {
      return function() {
        var offset, selectionView;
        offset = $target.offset();
        selectionView = new SelectionView({
          className: 'options-selection',
          listItems: optionsList,
          clearOption: $target.hasClass('clear-option'),
          noTextTransform: $target.hasClass('no-transform'),
          wrapLine: $target.hasClass('wrap-line'),
          compoundSelection: true,
          displayCssOptions: {
            display: 'block',
            minWidth: $target.closest('.selection-list-value').outerWidth(),
            left: offset.left,
            top: offset.top + 30
          },
          abortHandler: function() {
            return mediator.setFocus(_this);
          },
          acceptHandler: function() {
            var $selectionText, text, value, _ref, _ref1;
            value = (_ref = _this.subview('selectionView').getSelectedItemValue()) != null ? _ref : '';
            text = (_ref1 = _this.subview('selectionView').getSelectedItemText()) != null ? _ref1 : '';
            $target.find('.selection-input').val(value).trigger('change');
            $selectionText = $target.find('.selection-text');
            $selectionText.text(text);
            trait._uppercaseEffectMeasure($selectionText, value);
            if (typeof trait.acceptHandler === "function") {
              trait.acceptHandler(value);
            }
            return mediator.setFocus(_this);
          }
        });
        _this.subview('selectionView', selectionView);
        selectionView.show($target.offset(), $target.find('input').val());
        return selectionView.$el.find('li[data-list-value]').each(function(i, item) {
          var $el;
          $el = $(item);
          return trait._uppercaseEffectMeasure($el.find('span'), $el.attr('data-list-value'));
        });
      };
    })(this));
  };

  SelectableAttrList.prototype._prepareOptionsList = function(originalFunction, trait, options) {
    var $option, option, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = options.length; _i < _len; _i++) {
      option = options[_i];
      $option = $(option);
      _results.push({
        value: $option.val(),
        text: $option.text()
      });
    }
    return _results;
  };

  SelectableAttrList.prototype._uppercaseEffectMeasure = function($el, value) {
    if (value === 'RR' || value === 'HR' || value === 'OR' || value === 'MD' || value === 'SMD') {
      return $el.addClass('uppercase');
    } else {
      return $el.removeClass('uppercase');
    }
  };

  SelectableAttrList.prototype.afterRender = function(originalFunction, trait) {
    var $selectionText;
    originalFunction.call(this);
    $selectionText = this.$el.find('.selection-text');
    return _($selectionText).each(function(item, index) {
      var $item;
      $item = $(item);
      return trait._uppercaseEffectMeasure($item, $item.text().trim());
    });
  };

  return SelectableAttrList;

})(ViewTrait);
