Button = require 'components/common/button'
QualityIndicatorRow = require 'components/quality_indicators/quality_indicator_row'
QualityIndicatorEditRow = require 'components/quality_indicators/quality_indicator_edit_row'
{ useI18n } = require 'lib/react_utils'

QualityIndicatorsTable = ({ qualityIndicators, editingRows, currentQuestion, readOnly }) ->
  i18n = useI18n('quality_indicators:table')

  return null if qualityIndicators.isEmpty()
  <table className="standard-table quality-indicators-table">
    <thead>
      <tr>
        <th>{i18n 'quality_indicator'}</th>
        <th>{i18n 'performance_measure'}</th>
        <th>{i18n 'performance_indicator_rate'}</th>
      </tr>
    </thead>
    <tbody>
      {qualityIndicators.map (qi) ->
        if editingRows.includes(qi.get('id'))
          <QualityIndicatorEditRow
            currentQuestion={currentQuestion}
            key={qi.get('id')}
            qualityIndicator={qi}
          />
        else
          <QualityIndicatorRow
            readOnly={readOnly}
            key={qi.get('id')}
            qualityIndicator={qi}
          />
      }
    </tbody>
  </table>

QualityIndicatorsTable.propTypes =
  currentQuestion: PropTypes.instanceOf(Immutable.Map)
  editingRows: PropTypes.instanceOf(Immutable.List)
  qualityIndicators: PropTypes.instanceOf(Immutable.List).isRequired
  readOnly: PropTypes.bool

QualityIndicatorsTable.defaultProps =
  currentQuestion: null
  editingRows: Immutable.List()
  readOnly: false

module.exports = QualityIndicatorsTable
