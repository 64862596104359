DialogBox = createReactClass
  mixins: [
    OnClickOutside
  ],

  displayName: 'DialogBox'

  componentDidMount: ->
    @boxEl = $(ReactDOM.findDOMNode(this))
    @boxEl.css display: 'block'
    windowHeight = $(window).height()
    windowWidth = $(window).width()
    if @props.isOptionsDialog
      left = Math.min @props.offset.left, windowWidth - @boxEl.width() -
      (windowWidth-@props.offset.left) + 20
      top = Math.min @props.offset.top + 10, windowHeight - @boxEl.height()
    else
      left = Math.min @props.offset.left, windowWidth - @boxEl.width() - 40
      top = Math.min @props.offset.top - 20, windowHeight - @boxEl.height() - 40

    @boxEl.css
      left: if left < 0 then 0 else left
      top: top

  handleClickOutside: ->
    @hide()

  hide: ->
    parent = @boxEl[0].parentNode
    ReactDOM.unmountComponentAtNode parent
    $(parent).remove()

  render: ->
    <div className={ classNames("dialog-box", @props.dialogClass) }>
      <span className={classNames 'dot', 'options-dialog': @props.isOptionsDialog}></span>
      <div className="box-content">
        { @props.children }
      </div>
    </div>

module.exports = DialogBox
