mediator = require 'mediator'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
{ PLACEMENT_BEFORE, PLACEMENT_AFTER } = require 'lib/document_sections_v2_helper'

module.exports =
  RECOMMENDATION_ITEM_DND_TYPE: 'recommendationItem'
  dragSpec:
    beginDrag: (props) ->
      recommendationId: props.recommendationId
      populationId: props.populationId
      section: props.section
      index: props.index
    endDrag: (props, monitor) ->
      item = monitor.getItem()
      dropResult = monitor.getDropResult()
      if !dropResult
        return

      DocumentSectionsV2Actions.saveSection(props.section)
    isDragging: (props, monitor) ->
      props.recommendationId is monitor.getItem().recommendationId

  dropSpec:
    hover: (props, monitor, component) ->
      if !component
        return
      node = component.item
      if !node
        return

      draggingItem = monitor.getItem()
      dragIndex = draggingItem.index
      hoverIndex = props.index
      hoverPopulationId = props.populationId

      if dragIndex is hoverIndex
        return

      hoverBoundingRect = node.getBoundingClientRect()
      hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      clientOffset = monitor.getClientOffset()
      hoverClientY = clientOffset.y - hoverBoundingRect.top

      if dragIndex < hoverIndex && hoverClientY < hoverMiddleY
        return
      if dragIndex > hoverIndex && hoverClientY > hoverMiddleY
        return

      hoverRecommendationId = props.recommendationId
      placement = if dragIndex < hoverIndex then PLACEMENT_AFTER else PLACEMENT_BEFORE

      DocumentSectionsV2Actions.moveRecommendation({
        section: props.section
        populationId: draggingItem.populationId
        recommendationId: draggingItem.recommendationId,
        targetRecommendationId: hoverRecommendationId,
        targetPopulationId: hoverPopulationId,
        placement
      })

      draggingItem.index = hoverIndex
      draggingItem.populationId = hoverPopulationId

    drop: (props, monitor, component) ->
      # we need this part only happen when moving to empty population
      # the hover will take care of moving the recommendation in other cases
      if props.recommendationId
        return

      if !component
        return

      draggingItem = monitor.getItem()
      DocumentSectionsV2Actions.moveRecommendation({
        section: props.section,
        populationId: draggingItem.populationId,
        recommendationId: draggingItem.recommendationId,
        targetRecommendationId: null,
        targetPopulationId: props.populationId,
        placement: PLACEMENT_AFTER
      })

  dragCollect: (connect, monitor) ->
    connectDragSource: connect.dragSource()
    isDragging: monitor.isDragging()


  dropCollect: (connect, monitor) ->
    connectDropTarget: connect.dropTarget()
    isOver: monitor.isOver()
