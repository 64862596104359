PanelVoiceQuestion = require 'components/panel_voice/question'

PanelVoiceOverarchingQuestion = createReactClass
  displayName: 'PanelVoiceOverarchingQuestion'
  propTypes:
    data: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.Map),
      PropTypes.instanceOf(Immutable.OrderedMap),
    ]).isRequired

  render: ->
    <PanelVoiceQuestion data={@props.data} overarching />

module.exports = PanelVoiceOverarchingQuestion
