var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "regular", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "narrative_desc", depth0, "{\"colNo\": 2, \"colspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n        "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "empty_cell", depth0, "{\"colNo\": 3, \"colspan\": 1}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "narrative_desc", depth0, "{\"colNo\": 2, \"colspan\": 3, \"className\": \"white-cell\"}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.calculatedControlOnly : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "accp/risk_with_control", depth0, "{\"colNo\": 2, \"viewType\": \"SOF\", \"preFix\": \"main\"}", {"name":"cell","hash":{},"data":data})))
    + "\n      "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "corresponding_risk", depth0, "{\"colNo\": 3, \"preFix\": \"main\"}", {"name":"cell","hash":{},"data":data})))
    + "\n      "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "relative_effect", depth0, "{\"colNo\": 4}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.controlFirst || (depth0 && depth0.controlFirst) || helperMissing).call(depth0, "control", depth0, "{\"colNo\": 2}", {"name":"controlFirst","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "accp/cont_risk_difference_with_intervention", depth0, "{\"colNo\": 3}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "relative_effect", depth0, "{\"colNo\": 4}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "comments", depth0, "{\"colNo\": 7}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "sof/outcome_label", depth0, "{\"colNo\": 1}", {"name":"cell","hash":{},"data":data})))
    + "\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "narrative", {"name":"is","hash":{},"fn":this.program(1, data),"inverse":this.program(6, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "sof/no_of_participants_studies", depth0, "{\"colNo\": 5}", {"name":"cell","hash":{},"data":data})))
    + "\n"
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 6}", {"name":"cell","hash":{},"data":data})))
    + "\n";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "narrative", {"name":"isnt","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}