ImportActions = require 'actions/import_actions'
ImportUtils = require 'lib/import_utils'
mediator = require 'mediator'
{ string } = PropTypes

ImportFileInput = createReactClass
  displayName: 'ImportFileInput'

  propTypes:
    className: string

  _fileInputRef: (el) ->
    @fileInput = el

  click: ->
    @fileInput?.click()

  reset: ->
    @fileInput.value = ''

  onFileChanged: (e) ->
    file = e.target.files[0]
    return unless file
    if ImportUtils.isValidFile file
      ImportActions.setFile file
    else
      mediator.dialogs.error @i18n 'invalid_format'

  render: ->
    <input
      type="file"
      ref={@_fileInputRef}
      className="import-source-input-file"
      accept=".grd, .rm5, .zip"
      onChange={@onFileChanged}
    />

module.exports = ImportFileInput
