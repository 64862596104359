var PanelVoiceOnboardingService, Router, hopscotchTemplate, mediator, timesToShowOnboardingProposal;

mediator = require('mediator');

hopscotchTemplate = require('views/templates/panel_voice_hopscotch');

timesToShowOnboardingProposal = require('lib/constants').timesToShowOnboardingProposal;

Router = require('router');

PanelVoiceOnboardingService = (function() {
  function PanelVoiceOnboardingService() {
    window.hopscotch.templates.gdt = hopscotchTemplate;
    window.hopscotch.setRenderer('gdt');
    this._toggleActiveTargetClass = this._toggleActiveTargetClass.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleTourEnd = this.handleTourEnd.bind(this);
    this.stepProps = {
      placement: 'bottom',
      xOffset: 'center',
      arrowOffset: 'center',
      width: 340,
      showPrevButton: true,
      onShow: this._toggleActiveTargetClass,
      onNext: this.handleStepChange,
      onPrev: this.handleStepChange
    };
  }

  PanelVoiceOnboardingService.prototype.startTour = function() {
    var onboardingProposalCount, _ref;
    if (!_.str.endsWith(window.location.hash, 'voting')) {
      return this.routeAndStartTour();
    }
    this._tour = this._constructTour();
    window.hopscotch.startTour(this._tour, 0);
    onboardingProposalCount = (_ref = mediator.user.get('onboardingProposalCount')) != null ? _ref : 0;
    if (onboardingProposalCount < timesToShowOnboardingProposal) {
      mediator.user.set('onboardingProposalCount', timesToShowOnboardingProposal);
      return mediator.user.save();
    }
  };

  PanelVoiceOnboardingService.prototype.routeAndStartTour = function() {
    var panelVoiceRoute, projectId, routeParams;
    projectId = mediator.project.id;
    panelVoiceRoute = Router.prototype.getProjectRelativeUrl('/voting');
    routeParams = {
      onboardingTourAutostart: true
    };
    return mediator.publish('!router:route', panelVoiceRoute, {
      routeParams: routeParams
    });
  };

  PanelVoiceOnboardingService.prototype._toggleActiveTargetClass = function() {
    var _ref;
    return (_ref = this._currTarget) != null ? _ref.classList.toggle('onboarding-target') : void 0;
  };

  PanelVoiceOnboardingService.prototype.handleStepChange = function() {
    this._toggleActiveTargetClass();
    return this._currTarget = window.hopscotch.getCurrTarget();
  };

  PanelVoiceOnboardingService.prototype.handleTourEnd = function() {
    mediator.unsubscribe(null, null, 'walkthrough');
    this._toggleActiveTargetClass();
    return this._currTarget = null;
  };

  PanelVoiceOnboardingService.prototype._i18nContent = function(stepNo) {
    return $.t("voting:panel_voice_onboarding.step" + stepNo);
  };

  PanelVoiceOnboardingService.prototype._constructTour = function() {
    return {
      id: 'panel-voice-onboarding',
      templateToUse: 'gdt',
      onStart: (function(_this) {
        return function() {
          return _this._currTarget = window.hopscotch.getCurrTarget();
        };
      })(this),
      onClose: this.handleTourEnd,
      onEnd: this.handleTourEnd,
      steps: [
        _.extend(Object.create(this.stepProps), {
          content: this._i18nContent(1),
          target: '.panel-voice-questions__header div.questions'
        }), _.extend(Object.create(this.stepProps), {
          content: this._i18nContent(2),
          target: '.panel-voice-questions__header div.responses'
        }), _.extend(Object.create(this.stepProps), {
          content: this._i18nContent(3),
          target: '.panel-voice-questions__header div.status'
        }), _.extend(Object.create(this.stepProps), {
          content: this._i18nContent(4),
          target: '.panel-voice-questions__header div.actions',
          xOffset: -200,
          arrowOffset: 300
        }), _.extend(Object.create(this.stepProps), {
          content: this._i18nContent(5),
          target: '.top-section .btn-icon-container',
          showNextButton: false
        })
      ]
    };
  };

  return PanelVoiceOnboardingService;

})();

module.exports = PanelVoiceOnboardingService;
