{bool, func, element, string, arrayOf, number, object} = PropTypes
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Translation = require "components/mixins/translation"

Step = createReactClass

  displayName: "Step"

  propTypes:
    children: element
    stepKey: string.isRequired
    stepLabel: string.isRequired
    # this two function are used as onApply and onCancel
    onNext: func.isRequired
    onBack: func.isRequired

  render: ->
    <div>
      {this.props.children}
    </div>

StepIndicator = createReactClass

  displayName: "StepIndicator"

  propTypes:
    currentStep: string.isRequired
    steps: arrayOf(object).isRequired

  render: ->
    <div className="steps-indicator">
      <div className="labels">
        {@props.steps.map((step, index) =>
          indicatorClass = classNames "step", "filled": step.stepKey is @props.currentStep
          <div key={"label-#{step.stepKey}"}
            className="container"
            data-step={step.stepKey}
          >
            <div className="step-content">
              <div className="step-label">{step.stepLabel}</div>
              <div className="indicator">
                <div className="space-left" />
                <div className={indicatorClass} />
                  <div className="space-right" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>

StepsContainer = createReactClass

  displayName: "StepsContainer"

  propTypes:
    children: arrayOf(element).isRequired
    currentStep: string.isRequired,
    isCancelEnabled: bool
    isNextStepEnabled: bool
    applying: bool
    displayApplyButton: bool
    displayCancelButton: bool
    displayButtons: bool
    displayStepIndicator: bool

  mixins: [Translation('')]

  getDefaultProps: ->
    isCancelEnabled: true
    isNextStepEnabled: true
    displayApplyButton: true
    displayCancelButton: true
    displayButtons: true
    displayStepIndicator: true

  getInitialState: ->
    applyLabel: @props.applyLabel
    cancelLabel: @props.cancelLabel

  componentDidMount: ->
    @stepListKeys = @getStepsList().reduce (prev, curr) ->
      prev.concat curr.stepKey
    , []
    @setLabels @props

  setLabels: (props) ->
    { cancelLabel, applyLabel, currentStep} = props
    nextLabel = @getStepComponent(currentStep).props.nextLabel ? applyLabel
    backLabel = @getStepComponent(currentStep).props.backLabel ? cancelLabel
    @setState
      applyLabel: nextLabel
      cancelLabel: backLabel

  componentWillReceiveProps: (nextProps) ->
    @setLabels nextProps

  getStepsList: ->
    React.Children.map @props.children, (step) -> step.props

  isFirstStep: (step) ->
    step is @stepListKeys[0]

  isLastStep: (step) ->
    step is @stepListKeys[@stepListKeys.length - 1]

  getStepComponent: (step) ->
    @props.children.find (stepComponent) => stepComponent.props.stepKey is step

  onCancel: ->
    @getStepComponent(@props.currentStep).props.onBack()

  onApply: ->
    @getStepComponent(@props.currentStep).props.onNext()

  render: ->
    <div className="steps-container">
      {@props.displayStepIndicator and <StepIndicator
        currentStep={@props.currentStep}
        steps={@getStepsList()}
      />}
      <div className="steps">
        {React.Children.toArray(@props.children).filter (step) =>
          step.props.stepKey is @props.currentStep
        }
      </div>
      {@props.displayButtons and <ApplyCancelButtons
        applyLabel={@state.applyLabel}
        cancelLabel={@state.cancelLabel}
        applying={@props.applying}
        onApply={@onApply}
        onCancel={@onCancel}
        wrappedWithCol={false}
        isCancelEnabled={@props.isCancelEnabled}
        isSubmitEnabled={@props.isNextStepEnabled}
        displayApplyButton={@props.displayApplyButton}
        displayCancelButton={@props.displayCancelButton}
      />}
    </div>

module.exports = {StepsContainer, StepIndicator, Step}
