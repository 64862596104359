var TasksToolbarView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/tasks_toolbar');

module.exports = TasksToolbarView = (function(_super) {
  __extends(TasksToolbarView, _super);

  function TasksToolbarView() {
    return TasksToolbarView.__super__.constructor.apply(this, arguments);
  }

  TasksToolbarView.prototype.template = template;

  return TasksToolbarView;

})(View);
