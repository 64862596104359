var DraftQuestionsView, GeneralScopeView, ModuleController, OutcomesGenerationView, ScopeController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

GeneralScopeView = require('views/scope/general_view');

DraftQuestionsView = require('views/scope/draft_questions_view');

OutcomesGenerationView = require('views/scope/outcomes_generation_view');

mediator = require('mediator');

module.exports = ScopeController = (function(_super) {
  __extends(ScopeController, _super);

  function ScopeController() {
    return ScopeController.__super__.constructor.apply(this, arguments);
  }

  ScopeController.prototype.defaultSubmodule = 'general';

  ScopeController.prototype.viewsForSubmodules = {
    'general': GeneralScopeView,
    'questions': DraftQuestionsView,
    'outcomes': OutcomesGenerationView
  };

  return ScopeController;

})(ModuleController);
