Spinner = require 'components/common/spinner'
Modal = require 'components/common/modal'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
PasswordInput = require 'components/common/password_input'
Confirmation = require 'components/common/confirmation'
Translation = require 'components/mixins/translation'
Exceptions = require 'lib/exceptions'
mediator = require 'mediator'
W = require 'when/when'

getEnabledProviders = ->
  providers = ['archie']
  if mediator.services.switches.isServerSwitchOn 'loginWithGoogle'
    providers.push 'google'
  providers

LinkedAccounts = createReactClass
  displayName: 'LinkedAccounts'

  mixins: [Translation('account.linked_accounts')]

  propTypes:
    service: PropTypes.shape
      getConnectionStatus: PropTypes.func.isRequired
      connect: PropTypes.func.isRequired
      disconnect: PropTypes.func.isRequired

  componentDidMount: ->
    @fetchInfo()

  getInitialState: ->
    status: Immutable.Map()
    loading: true
    enteringPassword: false
    confirming: false

  fetchInfo: ->
    @setState loading: true, error: null
    @props.service.getConnectionStatusMap getEnabledProviders(), mediator.user.getLogin()
    .then (status) =>
      @setState (state) -> status: Immutable.fromJS status
    , (error) => @setState { error }
    .finally => @setState loading: false

  askForPassword: (callback) -> @setState(enteringPassword: true, passwordCallback: callback)
  passwordEntered: (password) ->
    @state.passwordCallback?(password)
    @passwordPromptClosed()
  passwordPromptClosed: -> @setState(enteringPassword: false, passwordCallback: null)

  link: (provider) -> =>
    @askForPassword (password) =>
      @props.service.connect(provider, mediator.user.getLogin(), password).then (account) =>
        @setState((state) =>
          status: state.status.set(provider, Immutable.fromJS {connected: true, account}))
      , (err) ->
        # otherwise, the message was already displayed
        mediator.dialogs.error(err) if err instanceof Exceptions.authentication.oauth_flow_failed

  confirm: (callback) -> @setState(confirming: true, confirmationCallback: callback)
  confirmationClosed: -> @setState(confirming: false, confirmationCallback: null)
  confirmed: ->
    @state.confirmationCallback?()
    @confirmationClosed()

  unlink: (provider) -> =>
    @confirm =>
      @props.service.disconnect(provider, mediator.user.getLogin()).then (account) =>
        @setState((state) =>
          status: state.status.set(provider, Immutable.fromJS {connected: false}))
      , mediator.dialogs.fatalError

  renderProvider: (provider) ->
    providerLogoName = if provider is 'archie' then 'cochrane-logo.png' else "#{provider}-logo.png"
    <div className="row external-accounts__provider" key={provider}>
      <div className="col-3">
        <img src={"images/#{providerLogoName}"} />
      </div>
      {if @state.status.getIn([provider, 'connected'])
        <div>
          <div className="col-6">
            {@i18n('linked', login: @state.status.getIn([provider, 'account']))}
          </div>
          <div className="col-3">
            <button className="btn btn-block" onClick={@unlink(provider)}>
              {@i18n('unlink')}
            </button>
          </div>
        </div>
      else
        <div>
          <div className="col-6 value">
            {@i18n('not_linked')}
          </div>
          <div className="col-3">
            <button className="btn btn-block" onClick={@link(provider)}>
              {@i18n('link')}
            </button>
          </div>
        </div>
      }
    </div>

  renderFormGroup: ->
    return <Spinner /> if @state.loading
    if @state.error
      return <div className="form-group">
        <div className="row">
          <div className="error">{@state.error.message}</div>
        </div>
      </div>

    <div className="form-group">
      {_.map getEnabledProviders(), @renderProvider}
    </div>

  render: ->
    <div className="form form-horizontal external-accounts">
      <div className="row">
          <h3>{@i18n('title')}</h3>
      </div>
      {@renderFormGroup()}
      {@state.enteringPassword and
        <PasswordDialog isOpen onClose={@passwordPromptClosed} onSubmit={@passwordEntered} />
      }
      {@state.confirming and
        <Modal
          isOpen
          onClose={@confirmationClosed}
          title={@i18n('unlink')}
          className="confirmation-modal"
        >
          <Confirmation
            question={@i18n('unlink_confirmation')}
            onCancel={@confirmationClosed}
            onConfirm={@confirmed}
          />
        </Modal>
      }
    </div>

PasswordDialog = createReactClass
  displayName: 'PasswordDialog'
  mixins: [Translation('account.password_dialog')]

  getInitialState: ->
    password: ''

  passwordChanged: (e) -> @setState(password: e.target.value)
  submit: -> @props.onSubmit(@state.password)

  render: ->
    <Modal
      isOpen={@props.isOpen}
      onClose={@props.onClose}
      title={@i18n('title')}
      closeButton={true}
      className="confirmation-modal password-confirmation-dialog"
      >
      <div>
        <form onSubmit={(e) -> e.preventDefault()}>
          <div className="password-confirmation-content">
            <div>{@i18n('message')}</div>
            <PasswordInput value={@state.password} onChange={@passwordChanged} />
          </div>
        </form>
        <ApplyCancelButtons
          onApply={@submit}
          onCancel={@props.onClose}
          isSubmitEnabled={Boolean(@state.password?.trim())}
          applyClass="btn-alternative"
        />
      </div>
    </Modal>

module.exports = LinkedAccounts
