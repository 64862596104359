var DbDoc, OVERARCHING_QUESTIONS_DB_VIEW, overarchingQuestionDocShape;

DbDoc = require('lib/db_docs/db_doc');

overarchingQuestionDocShape = require('lib/db_docs/doc_shapes/overarching_question_doc_shape');

OVERARCHING_QUESTIONS_DB_VIEW = require('lib/overarching_question_helper').OVERARCHING_QUESTIONS_DB_VIEW;

module.exports = DbDoc(overarchingQuestionDocShape, {
  dbSetup: {
    dbView: {
      name: 'overarching_questions',
      view: OVERARCHING_QUESTIONS_DB_VIEW
    }
  },
  methods: {
    setRecommendationsIds: function(recIds) {
      return this.setAt('recommendationIds')(recIds);
    }
  }
});
