Modal = require 'components/common/modal'
ExportDialog = require 'components/common/export_dialog'
Translation = require 'components/mixins/translation'
exportDocTemplate = require 'views/templates/printouts/scope/general_scope_table_printout'
{ GeneralScopeTable } = require 'components/scope/general_scope_table'
{ exportToFile, defaultMargins, defaultOrientation } = require 'lib/export_to_file'

GeneralScopeTableExportModal = createReactClass
  displayName: 'GeneralScopeTableExportModal'

  mixins: [
    Translation()
  ]

  onDownload: ({ outputFormat }) ->
    scopeTableHTML = ReactDOMServer.renderToStaticMarkup(
      React.createElement GeneralScopeTable,
        scope: @props.scopeData
    )
    docHTML = exportDocTemplate { scopeTableHTML }

    exportToFile outputFormat, docHTML, fileName: @i18n 'scope:general_scope_table_export'
      .then @props.onClose

  render: ->
    <Modal isOpen
      className="general-scope-table-export"
      closeButton={false}
      title={@i18n 'scope:general_scope_table_export'}
      >
      <ExportDialog
        onClose={@props.onClose}
        onDownload={@onDownload}
      />
    </Modal>

module.exports = GeneralScopeTableExportModal
