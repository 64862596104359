var HAIGEKASSA_WORKSPACE, mediator, templateHelpers, templateLanguages, templatesDefinitions;

templatesDefinitions = require('lib/doc_sections_templates/templates_definitions');

templateLanguages = require('lib/doc_sections_templates/templates_languages');

templateHelpers = require('stores/utils/template_helpers');

mediator = require('mediator');

HAIGEKASSA_WORKSPACE = 'o_haigekassa.ee_0';

_.namespace(module, function(require) {
  var helpers;
  helpers = templateHelpers(templateLanguages);
  return {
    _defaultLanguage: function() {
      if (mediator.services.switches.isOn('docSectionsTemplate')) {
        return 'pt';
      } else if (mediator.activeWorkspace === HAIGEKASSA_WORKSPACE) {
        return 'et';
      } else {
        return 'en';
      }
    },
    getDefaultTemplateId: function() {
      if (mediator.services.switches.isOn('docSectionsTemplate')) {
        return 'recommendation-medicamento';
      } else if (mediator.activeWorkspace === HAIGEKASSA_WORKSPACE) {
        return 'document-sections-haigekassa';
      } else {
        return 'document-sections';
      }
    },
    prepareBaseTemplate: function(templateId, language) {
      return this.prepareTemplate(Immutable.fromJS(_(templatesDefinitions).findWhere({
        id: templateId
      })).set('lang', language != null ? language : this._defaultLanguage()), Immutable.Map(), true);
    },
    prepareTemplate: function(templateDef, templateData, forCustomization, preserveCurrentSections) {
      if (forCustomization == null) {
        forCustomization = false;
      }
      return helpers.prepareTemplate(templateDef, templateData, forCustomization, preserveCurrentSections, ['chapters']);
    },
    updateTemplate: function(currentTemplate, newTemplate) {
      return helpers.updateTemplate(currentTemplate, newTemplate, ['chapters']);
    },
    updateDocumentSectionsDoc: function(projectId, newTemplate) {
      var docId;
      docId = 'document_sections_from_template';
      return mediator.services.storePersistenceAdapter.fetch(projectId, docId).otherwise((function(_this) {
        return function(err) {
          var docToSave;
          if (err.status === 404) {
            docToSave = newTemplate.set('_id', docId)["delete"]('_rev').toJS();
            return mediator.services.storePersistenceAdapter.save(projectId, docToSave);
          } else {
            throw err;
          }
        };
      })(this)).then((function(_this) {
        return function(docSectionsDoc) {
          var updatedDoc;
          updatedDoc = _this.updateTemplate(Immutable.fromJS(docSectionsDoc), newTemplate);
          return mediator.services.storePersistenceAdapter.save(projectId, updatedDoc.toJS());
        };
      })(this));
    }
  };
});
