IconButton = require 'components/common/icon_button'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
{ getAttachmentUrl } = require 'lib/references_utils'

AttachmentLink = createReactClass
  displayName: 'ReferenceAttachmentLink'

  mixins: [Translation('references:attachment')]

  propTypes:
    attachmentFileName: PropTypes.string
    className: PropTypes.string
    projectId: PropTypes.string.isRequired
    referenceId: PropTypes.string.isRequired

  getDefaultProps: ->
    className: ''

  openAttachment: (e) ->
    e.stopPropagation()
    { projectId, referenceId } = @props
    url = getAttachmentUrl projectId, referenceId
    window.open url, '_blank', 'noopener'

  render: ->
    { attachmentFileName, className } = @props

    <Tooltip>
      <div className={className} title={attachmentFileName ? ''}>
        <IconButton
          className="reference-attachment-link"
          iconName="file-icon"
          inlined
          onClick={@openAttachment}
          label={@i18n 'openFile'}
          labelPosition="right"
        />
      </div>
    </Tooltip>

module.exports = AttachmentLink
