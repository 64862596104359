QuestionsListWithButtons =
  require 'components/administration/voting/voting_questions_list_with_buttons'
SendVoteModal = require 'components/administration/voting/voting_sending_modal'
{ CloseVotingModal } = require 'components/administration/voting/voting_modals'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
VotingButton = require 'components/administration/voting/voting_button'
Translation = require 'components/mixins/translation'
ModalDialogsMixin = require 'components/mixins/modal_dialogs_mixin'

SummaryBlock = createReactClass
  displayName: 'SummaryBlock'
  mixins: [
    CustomRenderMixin
    Translation('voting:voting')
    ModalDialogsMixin
  ]

  getInitialState: ->
    sendReminderModal: false
    closeVotingModal: false

  _getEtdPartsText: () ->
    if @props.etdPartNames.length > 1
      @props.etdPartNames.map (name, idx, etdPartNames) =>
        if idx is etdPartNames.length - 1
          <b key={idx}>{name}</b>
        else
          <span key={idx}><b>{name}</b> {@i18n 'and'} </span>
    else
      <span><b>{@props.etdPartNames[0]}</b> {@i18n 'only'}</span>

  _isFinalVoting: ->
    @props.etdGroup is 'ATC' or @props.votingPhase is 'phase2b'

  _getCloseVotingMessage: ->
    if @_isFinalVoting()
      @i18n 'modals.phase2_closing_message'
    else
      @i18n 'modals.phase1_closing_message'

  _getCloseVotingAcceptLabel: ->
    if @_isFinalVoting()
      @i18n 'modals.phase2_accept_text'
    else
      @i18n 'modals.phase1_accept_text'

  _getCloseVotingType: ->
    if @_isFinalVoting() then 'closeFinal' else 'closePhase1'

  _getSelectedQuestionsIds: ->
    @refs.questionsList.getSelectedQuestionsIds()

  _getSelectedQuestions: ->
    @props.questions.filter (q) => @_getSelectedQuestionsIds().contains q.get 'id'

  handleSendingCancellation: (data) ->
    @props.updateMessageDetails 'reminder', data  if data
    @closeModal('sendReminderModal')()

  sendReminder: (data) ->
    questionIds = @refs.questionsList.getSelectedQuestionsIds().toArray()
    @props.handleSendingReminder questionIds, data

  closeVoting: ->
    @props.handleCloseVoting @_getSelectedQuestionsIds().toArray()
    @closeModal('closeVotingModal')()

  render: ->
    <div className="summary-block">
      <div className="section-text">
        {@i18n 'voting_on'}: {@_getEtdPartsText()}
      </div>
      <QuestionsListWithButtons
        ref='questionsList'
        projectId={@props.projectId}
        questions={@props.questions}
        questionGroups={@props.questionGroups}
      >
        <VotingButton type='reminder' onClick={@showModal 'sendReminderModal'} />
        <VotingButton type={@_getCloseVotingType()} onClick={@showModal 'closeVotingModal'} />
      </QuestionsListWithButtons>

      {if @state.sendReminderModal
        <SendVoteModal
          type="reminder"
          message={@props.reminderMessage}
          questions={@_getSelectedQuestions()}
          onClose={@handleSendingCancellation}
          handleSending={@sendReminder}
        />
      }
      {if @state.closeVotingModal
        <CloseVotingModal
          message={@_getCloseVotingMessage()}
          acceptLabel={@_getCloseVotingAcceptLabel()}
          onCancel={@closeModal 'closeVotingModal'}
          onAccept={@closeVoting}
        />
      }
    </div>

module.exports = SummaryBlock
