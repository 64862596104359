var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div>\n  <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "capabilities_caption", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n  <div id=\"capabilities\"></div>\n</div>\n<div>\n  <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "learn_caption", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n  <div id=\"learn\" class=\"row\">\n    <div class=\"youtube-container\">\n      <iframe width=\"280\" height=\"175\" src=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "introductory_video_url", {"name":"i18n","hash":{},"data":data})))
    + "\"\n        frameborder=\"0\" allowfullscreen></iframe>\n    </div>\n    <ul class=\"unstyled-list\">\n      <li><button class=\"guide\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "guide", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n      <li><button class=\"tutorials\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "tutorials", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n    </ul>\n  </div>\n</div>\n<div class=\"buttons-block row mt-20\">\n  <div class=\"text-center mt-10\">\n    <button class=\"btn get-started close\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "get_started", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n  <div class=\"text-right mt-10\">\n    <label id=\"dont-show-again\">\n      <input type=\"checkbox\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dont_show_again", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n  </div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:welcome.new_user", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}