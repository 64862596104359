var ReactDnDActions, alt;

alt = require('alt');

ReactDnDActions = (function() {
  function ReactDnDActions() {
    this.generateActions('beginDrag', 'endDrag', 'hover', 'drop', 'dropAreaDrop');
  }

  return ReactDnDActions;

})();

module.exports = alt.createActions(ReactDnDActions);
