CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

ApprovalDetails = createReactClass
  displayName: 'ApprovalDetails'

  propTypes:
    approvals: PropTypes.instanceOf(Immutable.Map).isRequired
    originalPriority: PropTypes.string.isRequired
    groupType: PropTypes.string

  mixins: [
    CustomRenderMixin
    Translation('scope:questions.approval')
  ]

  _getOriginalPriority: ->
    @i18n "questions_#{@props.originalPriority}"

  _getApprovalStats: ->
    approvalsCount = @_getApprovalsCount()
    approvalsForCount = @_getApprovalAgreeCount()
    ratio = "#{Math.floor(approvalsForCount/approvalsCount*100)}%"

    "#{ratio} (#{approvalsForCount}/#{approvalsCount})"

  _getApprovalsCount: ->
    @props.approvals.size

  _getApprovalAgreeCount: ->
    @props.approvals.filter((v) -> v is 'yes').size

  render: ->
    groupChanged = @props.originalPriority isnt @props.groupType
    priorityContainerClass = classNames 'original-priority-container',
      'group-changed': groupChanged

    <div className='approval-details'>
      <div className={priorityContainerClass}>
        <div className='original-priority'>
          {@i18n 'originally_suggested_priority'}:
          <span>{@_getOriginalPriority()}</span>
        </div>
      </div>
      <div className='approval-stats-container'>
        <div className='approval-stats'>{@i18n 'agreed'}:</div>
        <span>{@_getApprovalStats()}</span>
      </div>
    </div>

module.exports = ApprovalDetails
