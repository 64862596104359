CreateAdolopment = require 'components/projects/create_adolopment'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'


CreateAdolopmentModal = createReactClass

  displayName: "CreateAdolopmentModal"

  mixins: [Translation('projects:adolopment_dialog')]

  propTypes:
    onRequestClose: PropTypes.func.isRequired
    projectId: PropTypes.string.isRequired
    organizationId: PropTypes.string

  render: ->
    <Modal
      isOpen={true}
      onRequestClose={@props.onRequestClose}
      title={@i18n 'title'}
      modalSize='medium'
      className='create-adolopment-modal'
    >
      <CreateAdolopment
        projectId={@props.projectId}
        organizationId={@props.organizationId}
        onRequestClose={@props.onRequestClose}
      />
    </Modal>

module.exports = CreateAdolopmentModal
