GdtEditorWithApply = Editor.EditorWithApply

EditorText = createReactClass
  displayName: 'EditorText'

  propTypes:
    onSave: PropTypes.func.isRequired
    readOnly: PropTypes.bool
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])

  getDefaultProps: ->
    value: ''
    readOnly: false

  saveContent: ->
    @props.onSave @gdtEditor.getEditorContent()

  onCancel: ->
    @gdtEditor.resetContent @props.value

  editorRef: (el) ->
    @gdtEditor = el?.gdtEditor

  componentDidUpdate: ->
    @gdtEditor.resetContent @props.value

  render: ->
    <GdtEditorWithApply
      editorContent={@props.value}
      onApply={@saveContent}
      onBlur={@saveContent}
      onCancel={@onCancel}
      ref={@editorRef}
      readOnly={@props.readOnly}
      stickControlsTo=".extraction-field__value"
    />

module.exports = EditorText
