var AddIdToMultipleRecommendationsEntries, Migration, OVERARCHING_QUESTIONS_DB_VIEW, W, generateGUID, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

generateGUID = require('base/lib/utils').generateGUID;

W = require('when');

OVERARCHING_QUESTIONS_DB_VIEW = require('lib/overarching_question_helper').OVERARCHING_QUESTIONS_DB_VIEW;

migrationName = 'add_id_to_multiple_recommendations_entries';

module.exports = AddIdToMultipleRecommendationsEntries = (function(_super) {
  __extends(AddIdToMultipleRecommendationsEntries, _super);

  function AddIdToMultipleRecommendationsEntries() {
    AddIdToMultipleRecommendationsEntries.__super__.constructor.call(this, false);
  }

  AddIdToMultipleRecommendationsEntries.prototype.up = function(project, colls) {
    var adapter, projectId;
    AddIdToMultipleRecommendationsEntries.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return adapter.fetchWithView(projectId, 'overarching_questions', OVERARCHING_QUESTIONS_DB_VIEW).then(function(res) {
      return _.chain(res).pluck('recommendationIds').flatten().compact().uniq().value();
    }).then(function(recIds) {
      return adapter.fetch(projectId, recIds);
    }).then(function(_arg) {
      var recDocs, rows, updatedDocs;
      rows = _arg.rows;
      recDocs = _.chain(rows).pluck('doc').value();
      if (_.isEmpty(recDocs)) {
        return W.resolve();
      }
      updatedDocs = _.map(recDocs, function(doc) {
        var immDoc, multiRecPath;
        immDoc = Immutable.fromJS(doc);
        multiRecPath = ['templateData', 'conclusions', 'sections', 'multipleRecommendations', 'recommendations'];
        immDoc = immDoc.hasIn(multiRecPath) ? immDoc.updateIn(multiRecPath, function(recs) {
          return recs.map(function(rec) {
            if (rec.get('_id') && rec.get('_id') !== '__uid__') {
              return rec;
            } else {
              return rec.set('_id', generateGUID());
            }
          });
        }) : immDoc;
        return _.extend(immDoc.toJS(), {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return AddIdToMultipleRecommendationsEntries;

})(Migration);
