Button = require 'components/common/button'

AccountOption = (props) ->
  <div className='accounts-list-item'>
    <div className='option-content'>
      {props.children}
    </div>
    <Button
      className='btn open-account'
      label={$.t 'translation:actions.open'}
      onClick={props.onOpen}
    />
  </div>

module.exports = AccountOption
