var Exceptions, OUTCOME_ATTRIBUTES_TO_MERGE, Outcome, Outcomes, Project, QUESTION_ATTRIBUTES_TO_MERGE, Question, W, mediator, mergeOutcomes, mergeQuestions, parseFile;

Exceptions = require('lib/exceptions');

mediator = require('mediator');

Outcome = require('models/outcome');

Outcomes = require('models/outcomes');

Project = require('models/project');

Question = require('models/question');

W = require('when/when');

QUESTION_ATTRIBUTES_TO_MERGE = ['comparison', 'healthProblemOrPopulation', 'intervention', 'questionFormat'];

OUTCOME_ATTRIBUTES_TO_MERGE = ['name', 'metaType', 'type', 'noOfStudies', 'interventionCount', 'controlCount', 'interventionTotal', 'controlTotal', 'effectSize', 'confidenceIntervalFrom', 'confidenceIntervalTo', 'effectMeasure', 'otherMeasure'];

parseFile = function(file) {
  if (!mediator.services.replication.isConnected()) {
    return W.reject(Exceptions.server.no_connection);
  }
  return mediator.services.revmanCSVImportService["import"](file).then(function(request) {
    var importMetadata, project, questions, _ref;
    importMetadata = {
      fileName: file.name,
      requestId: request.get('_id'),
      requestUser: mediator.user.getLogin()
    };
    project = new Project({
      name: file.name
    });
    questions = _.map((_ref = request.get('result')) != null ? _ref : [], function(questionAttrs, idx) {
      var question;
      question = new Question(_.extend(questionAttrs, {
        'questionNo': idx + 1
      }));
      question.get('outcomes').each(function(outcome) {
        return outcome.recalculateOutcomeData();
      });
      return question;
    });
    return [
      {
        project: project,
        questions: questions,
        importMetadata: importMetadata
      }
    ];
  });
};

mergeQuestions = function(newQuestionModel, oldQuestionData) {
  var absentOutcomes, newOutcomes, presentOutcomes, question, _ref;
  question = new Question(oldQuestionData);
  presentOutcomes = {};
  absentOutcomes = [];
  newQuestionModel.get('outcomes').each(function(outcome) {
    var outcomePresent, _ref;
    outcomePresent = _.any((_ref = oldQuestionData.outcomes) != null ? _ref : [], function(_arg) {
      var _id;
      _id = _arg._id;
      return _id === outcome.get('_id');
    });
    if (outcomePresent) {
      return presentOutcomes[outcome.get('_id')] = outcome;
    } else {
      return absentOutcomes.push(outcome.toJSON());
    }
  });
  newOutcomes = _.chain((_ref = oldQuestionData.outcomes) != null ? _ref : []).map(function(outcome) {
    if (outcome._id in presentOutcomes) {
      return mergeOutcomes(presentOutcomes[outcome._id], outcome);
    } else {
      return outcome;
    }
  }).union(absentOutcomes).value();
  _.each(QUESTION_ATTRIBUTES_TO_MERGE, function(attribute) {
    if (newQuestionModel.get(attribute) !== question.get(attribute)) {
      return question.set(attribute, newQuestionModel.get(attribute));
    }
  });
  question.set('outcomes', new Outcomes(newOutcomes));
  question.calculateTitles();
  return question.toJSON();
};

mergeOutcomes = function(newOutcomeModel, oldOutcomeData) {
  var outcome;
  outcome = new Outcome(oldOutcomeData);
  _.each(OUTCOME_ATTRIBUTES_TO_MERGE, function(attribute) {
    if (newOutcomeModel.get(attribute) !== outcome.get(attribute)) {
      return outcome.set(attribute, newOutcomeModel.get(attribute));
    }
  });
  outcome.recalculateOutcomeData();
  return outcome.toJSON();
};

module.exports = {
  mergeOutcomes: mergeOutcomes,
  mergeQuestions: mergeQuestions,
  parseFile: parseFile
};
