Modal = require 'components/common/modal'
Confirmation = require 'components/common/confirmation'

ConfirmationModal = createReactClass
  displayName: 'ConfirmationModal'

  propTypes:
    isOpen: PropTypes.bool
    loading: PropTypes.bool
    question: PropTypes.string
    message: PropTypes.string
    onConfirm: PropTypes.func.isRequired
    onCancel: PropTypes.func.isRequired
    confirmLabel: PropTypes.string
    cancelLabel: PropTypes.string
    modalSize: PropTypes.oneOf(['small', 'auto', 'standard', 'medium', 'large', 'full-size']),
    title: PropTypes.string
    confirmClass: PropTypes.string
    cancelClass: PropTypes.string

  getDefaultProps: ->
    loading: false
    modalSize: 'standard'

  render: ->
    {
      isOpen
      loading
      question
      message
      onConfirm
      onCancel
      confirmLabel
      cancelLabel
      modalSize
      title
      confirmClass
      cancelClass
    } = @props

    <Modal
      key="confirmation-modal"
      isOpen={isOpen}
      className="confirmation-modal"
      modalSize={modalSize}
      title={title}
    >
      <Confirmation
        cancelClass={cancelClass}
        cancelLabel={cancelLabel}
        confirmClass={confirmClass}
        confirmLabel={confirmLabel}
        message={message}
        onCancel={onCancel}
        onConfirm={onConfirm}
        question={question}
        loading={loading}
      />
    </Modal>

module.exports = ConfirmationModal
