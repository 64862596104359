{ instanceOf, object, func, bool, string } = PropTypes
ArchieHelper = require 'lib/archie_helper'
Button = require 'components/common/button'
{ support_email } = require 'lib/constants'
ImportActions = require 'actions/import_actions'
mediator = require 'mediator'
MdaWorkflowDialogActions = require 'actions/mda_workflow_dialog_actions'
MdaWorkflowDialog = require 'components/mda/workflow/create_mda_workflow_dialog'
Modal = require 'components/common/modal'
NewProjectView = require 'views/new_project_view'
SearchBar = require 'components/common/search_bar'
Translation = require 'components/mixins/translation'
{ useI18n } = require 'lib/react_utils'

ProjectsListButtons = ({ onCreate, onCreateWorkflow, onImport }) ->
  i18n = useI18n()

  isMda = mediator.services.switches.isOn 'mda'
  hideImportProject = mediator.services.switches.isOn 'mdgTables'

  if isMda
    <div className='projects-buttons'>
      <button className='btn new-project' onClick={onCreateWorkflow}>
        {i18n '/mda:workflow.create'}
      </button>
      <MdaWorkflowDialog />
    </div>
  else
    <div className='projects-buttons'>
      <button className='btn new-project' onClick={onCreate}>
        {i18n '/projects:new_project'}
      </button>
      {not hideImportProject and <button className='btn import-project' onClick={onImport}>
        {i18n 'import.project'}
      </button>}
    </div>

ProjectsListButtons.propTypes =
  onCreate: func.isRequired
  onCreateWorkflow: func.isRequired
  onImport: func.isRequired

ProjectsListHeader = createReactClass
  displayName: 'ProjectsHeader'

  propTypes:
    organizationId: string
    activeSearch: instanceOf(Immutable.Map).isRequired
    onSearch: func.isRequired
    onSearchReset: func.isRequired

  getDefaultProps: ->
    organizationId: null

  getInitialState: ->
    infoOpened: false

  mixins: [
    Translation('')
  ]

  hideInfo: ->
    @setState infoOpened: false

  _checkIfCanCreateProject: (whatNextIfPossible) ->
    { organizationId } = @props
    if mediator.services.projectPermissionsService.canCreateProject organizationId
      whatNextIfPossible()
    else
      @setState infoOpened: true

  createWorkflow: ->
    @_checkIfCanCreateProject =>
      if mediator.services.replication.isConnected()
        { organizationId } = @props
        MdaWorkflowDialogActions.openDialog { mode: 'create', organizationId }
      else
        mediator.dialogs.error $.t 'mda:workflow.offline_error'

  startNewProject: ->
    @_checkIfCanCreateProject =>
      @newProjectView = new NewProjectView organizationId: @props.organizationId

  importProject: ->
    @_checkIfCanCreateProject =>
      { organizationId } = @props
      options = { importType: 'project', organizationId }
      ImportActions.openImportModal options

  componentWillUnmount: ->
    @newProjectView?.dispose()

  render: ->
    { organizationId } = @props
    { infoOpened } = @state

    infoKey = if organizationId is ArchieHelper.COCHRANE_ORGANIZATION_ID
      '/projects:messages.cochrane_organization_creating_disabled'
    else
      '/projects:messages.organization_creating_disabled'

    <div className='projects-header-container'>
      <SearchBar searchOnChange
        containerClass='projects-search'
        onSearch={@props.onSearch}
        onSearchReset={@props.onSearchReset}
        searchText={@props.activeSearch.get 'searchText'}
      />
      <ProjectsListButtons
        onCreate={@startNewProject}
        onCreateWorkflow={@createWorkflow}
        onImport={@importProject}
      />
      <Modal className="projects-info-modal" isOpen={infoOpened}>
        {_.map @i18n(infoKey, returnObjects: true), (text, idx) ->
          <p style={textAlign: 'center'} key={"info-#{idx}"}>{text}</p>
        }
        <p style={textAlign: 'center'}>
          {@i18n '/projects:messages.require_assistance', {},
            emailLink: <a href={"mailto:#{support_email}"}>{support_email}</a>
          }
        </p>
        <Button
          className="btn btn-block btn-apply"
          label={@i18n 'actions.ok'}
          onClick={@hideInfo}
        />
      </Modal>
    </div>

module.exports = ProjectsListHeader
