var AdolopmentActions, INITIAL_STATE, generateStore;

generateStore = require('stores/utils/generate_store');

AdolopmentActions = require('actions/adolopment_data_actions');

INITIAL_STATE = Immutable.fromJS({
  meta: {
    fetching: false
  },
  etdsData: {}
});

module.exports = generateStore({
  name: 'AdolopmentDataStore',
  initialState: INITIAL_STATE,
  boundActions: [AdolopmentActions],
  methods: {
    onDbChange: function(_arg) {
      var etdsData;
      etdsData = _arg.etdsData;
      return this.setState(this.state.set('etdsData', Immutable.fromJS(etdsData)));
    },
    onFetchLimits: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], true));
    },
    onFetchAdolopmentDataSuccess: function(etdsData) {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['meta', 'fetching'], false);
        return s.setIn(['etdsData'], Immutable.fromJS(etdsData));
      }));
    },
    isFetchingAdolopmentData: function() {
      return this.state.getIn(['meta', 'fetching']);
    },
    getAdolopmentData: function() {
      return this.state.getIn(['etdsData'], Immutable.Map());
    }
  }
});
