{ useState } = React
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'
IconButton = require 'components/common/icon_button'
Input = require 'components/common/input'

AlgorithmsListItem = (props) ->
  {
    id,
    algorithm,
    isEditing,
    navigateToAlgorithm,
    deleteAlgorithm,
    updateAlgorithm,
    editAlgorithm,
  } = props

  i18n = useI18n('algorithms')
  { title, orderNumber } = algorithm
  [updatedTitle, setUpdatedTitle] = useState(title)

  handleDelete = useCoffeeCallback [deleteAlgorithm, id], (evt) ->
    deleteAlgorithm(id)

  handleSave = useCoffeeCallback [updateAlgorithm, id, updatedTitle], (evt) ->
    updatedAlgorithm = _.assign({}, algorithm, { title: updatedTitle })
    updateAlgorithm(id, updatedAlgorithm)

  handleEdit = useCoffeeCallback [editAlgorithm, id], () ->
    editAlgorithm(id)

  handleOnTitleClick = useCoffeeCallback [navigateToAlgorithm, isEditing, id], () ->
    return if isEditing
    navigateToAlgorithm(id)

  <div className='algorithms-list__item'>
    <div className='algorithms-list__item-order-number'>{orderNumber}</div>
    <div className='algorithms-list__item-title' onClick={handleOnTitleClick}>
      {if isEditing
        <Input
          onChange={(evt) -> setUpdatedTitle(evt.target.value)}
          placeholder={i18n('new_algorithm')}
          type='text'
          value={updatedTitle}
         />
      else
        <span>{title}</span>
      }
    </div>
    {if isEditing
      <div className='algorithms-list__item-controls--editing'>
        <IconButton iconName='delete' onClick={handleDelete} />
        <IconButton iconName='save' onClick={handleSave} />
      </div>
    else
      <div className='algorithms-list__item-controls'>
        <IconButton iconName='edit' onClick={handleEdit} />
      </div>
    }
  </div>

AlgorithmsListItem.propTypes =
  id: PropTypes.string.isRequired
  algorithm: PropTypes.object.isRequired
  isEditing: PropTypes.bool.isRequired
  navigateToAlgorithm: PropTypes.func.isRequired
  deleteAlgorithm: PropTypes.func.isRequired
  updateAlgorithm: PropTypes.func.isRequired
  editAlgorithm: PropTypes.func.isRequired

module.exports = AlgorithmsListItem
