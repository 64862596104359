
AdolopmentSectionSeparator = ({ i18n, section, type, sectionColSpan, onClick }) ->
  <tr className="adolopment-section-separator #{type}">
    <td className="adolopment-section-separator__color-marker
      adolopment-section-separator__color-marker--#{section}"></td>
    <td className="adolopment-section-separator__section" colSpan={sectionColSpan}>
      <div onClick={onClick}>{i18n section}</div>
    </td>
  </tr>

AdolopmentSectionSeparator.propTypes =
  i18n: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  section: PropTypes.oneOf(['adolopment', 'original']).isRequired,
  sectionColSpan: PropTypes.number,
  type: PropTypes.oneOf(['conclusions', 'assessment']).isRequired

AdolopmentSectionSeparator.defaultProps =
  sectionColSpan: 1
  onClick: () -> null

module.exports = AdolopmentSectionSeparator
