var ConnectedProgressSelect, QuestionProgressView, ReactComponent, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

ConnectedProgressSelect = require('components/evidence_syntheses/connected_progress_select');

ReactComponent = require('base/lib/traits/react_component');

View = require('base/views/view');

QuestionProgressView = (function(_super) {
  __extends(QuestionProgressView, _super);

  function QuestionProgressView() {
    return QuestionProgressView.__super__.constructor.apply(this, arguments);
  }

  QuestionProgressView.prototype.container = '.evidence-table-progress';

  QuestionProgressView.prototype.initialize = function() {
    QuestionProgressView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  QuestionProgressView.prototype.afterRender = function() {
    QuestionProgressView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(ConnectedProgressSelect, {
      questionId: this.model.get('_id'),
      groupKey: 'evidenceTableStatus'
    });
  };

  QuestionProgressView.prototype.dispose = function() {
    this.unmountComponent();
    return QuestionProgressView.__super__.dispose.apply(this, arguments);
  };

  return QuestionProgressView;

})(View);

module.exports = QuestionProgressView;
