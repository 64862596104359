var Question, W, exportAssessmentSummary, exportConclusions, exportInterventions, exportIsof, exportJustification, exportPopulation, exportProgress, exportQuestionAndRecommendation, get, getCertaintyOfEvidenceAndLabel, getEtdPrintout, getRecommendationDirectionAndStrength, getRecommendationMetadata, getRecommendationMetadataOrUndefined, getRecommendationText, gradeProCodeToWHOCode, mediator, renderEditorValue, renderEditorValueFromRecommendationMetadata, _ref;

get = require('lodash').get;

_ref = require('lib/services/covid_exporter/utils'), exportConclusions = _ref.exportConclusions, exportIsof = _ref.exportIsof, exportJustification = _ref.exportJustification, getCertaintyOfEvidenceAndLabel = _ref.getCertaintyOfEvidenceAndLabel, getEtdPrintout = _ref.getEtdPrintout, getRecommendationDirectionAndStrength = _ref.getRecommendationDirectionAndStrength, getRecommendationMetadata = _ref.getRecommendationMetadata, getRecommendationMetadataOrUndefined = _ref.getRecommendationMetadataOrUndefined, getRecommendationText = _ref.getRecommendationText, gradeProCodeToWHOCode = _ref.gradeProCodeToWHOCode, renderEditorValue = _ref.renderEditorValue, renderEditorValueFromRecommendationMetadata = _ref.renderEditorValueFromRecommendationMetadata;

mediator = require('mediator');

Question = require('models/question');

W = require('when/when');

exportInterventions = function(question) {
  var interventions, _ref1, _ref2, _ref3, _ref4;
  switch (question.type) {
    case 'diagnostic':
      interventions = [
        {
          '@id': '_:int1',
          '@type': 'WHOIntervention',
          'name': question['indexTest'],
          'codes': _.chain((_ref1 = get(question, 'codes.indexTest')) != null ? _ref1 : {}).values().map(gradeProCodeToWHOCode).value()
        }
      ];
      if (question['comparatorTestPresent']) {
        interventions.push({
          '@id': '_:int2',
          '@type': 'WHOIntervention',
          'name': question['comparatorTest'],
          'codes': _.chain((_ref2 = get(question, 'codes.comparatorTest')) != null ? _ref2 : {}).values().map(gradeProCodeToWHOCode).value()
        });
      }
      return interventions;
    default:
      return [
        {
          '@id': '_:int1',
          '@type': 'WHOIntervention',
          'name': question['intervention'],
          'codes': _.chain((_ref3 = get(question, 'codes.intervention')) != null ? _ref3 : {}).values().map(gradeProCodeToWHOCode).value()
        }, {
          '@id': '_:int2',
          '@type': 'WHOIntervention',
          'name': question['comparison'],
          'codes': _.chain((_ref4 = get(question, 'codes.comparison')) != null ? _ref4 : {}).values().map(gradeProCodeToWHOCode).value()
        }
      ];
  }
};

exportPopulation = function(question, recommendation) {
  var setting, _ref1;
  setting = renderEditorValue(get(recommendation, 'templateData.question.sections.setting.content'));
  if (!setting) {
    setting = question['settings'];
  }
  if (!setting) {
    setting = void 0;
  }
  return [
    {
      '@id': '_:pop1',
      '@type': 'WHOPopulation',
      'ages': _.reject(getRecommendationMetadata(recommendation, 'age'), function(v) {
        return v === 'any';
      }),
      'codes': _.chain((_ref1 = get(question, 'codes.population')) != null ? _ref1 : {}).values().map(gradeProCodeToWHOCode).value(),
      'description': question['healthProblemOrPopulation'],
      'setting': setting
    }
  ];
};

exportAssessmentSummary = function(recommendation) {
  var sections, sectionsOrder, _ref1;
  sectionsOrder = (_ref1 = get(recommendation, 'templateData.assessment.sectionsOrder')) != null ? _ref1 : [];
  sections = _.chain(sectionsOrder).map(function(key) {
    var additionalOptions, criterionId, name, options, selectedOption, value;
    criterionId = get(recommendation, "templateData.assessment.sections." + key + ".criterionId");
    if (!criterionId) {
      return null;
    }
    name = get(recommendation, "templateData.assessment.sections." + key + ".name");
    selectedOption = get(recommendation, "templateData.assessment.criteria." + criterionId + ".selectedOption");
    options = get(recommendation, "templateData.assessment.criteria." + criterionId + ".options");
    additionalOptions = get(recommendation, "templateData.assessment.criteria." + criterionId + ".additionalOptions", []);
    value = options.concat(additionalOptions).find(function(option) {
      return option.value === selectedOption;
    });
    if ((value == null) || value === '') {
      return null;
    }
    return [
      key, {
        name: name,
        text: value.text
      }
    ];
  }).compact().object().value();
  if (!sections) {
    return void 0;
  }
  return {
    sections: sections,
    sectionsOrder: sectionsOrder
  };
};

exportProgress = function(recommendation) {
  var progress;
  if (!recommendation) {
    return void 0;
  }
  progress = _.chain(['progress', 'plus', 'progressPlus']).map(function(field) {
    var value;
    value = getRecommendationMetadata(recommendation, field, void 0);
    if (!value) {
      return void 0;
    }
    return [field, value];
  }).compact().object().value();
  if (_.isEmpty(progress)) {
    return void 0;
  }
  return progress;
};

exportQuestionAndRecommendation = function(question, recommendations, questionStatuses, projectId) {
  var certaintyOfEvidence, coexistingConditions, coi, comparison, conclusions, emlLink, evidenceTableLink, gradeCertaintyOfEvidenceLabel, individualRecommendationLink, isDiagnostic, isGps, isofData, loveLink, perspective, plainLanguageSummary, presentationStatus, printoutPromise, projectName, questionModel, questionStatus, recommendation, recommendationDirection, recommendationId, recommendationStatus, recommendationStrength, recommendationText, symptoms, _ref1, _ref2;
  recommendationStatus = get(questionStatuses, "" + question._id + ".recommendationStatus.status");
  if (recommendationStatus !== 'done') {
    return W.resolve();
  }
  projectName = mediator.projects.get(projectId).get('name');
  questionModel = new Question(question);
  isDiagnostic = question['type'] === 'diagnostic';
  isGps = question['type'] === 'good-practice-statement';
  recommendationId = _.first(question['recommendationIds']);
  recommendation = get(recommendations, recommendationId);
  if (!recommendation) {
    return W.resolve();
  }
  recommendationText = getRecommendationText(recommendation, isGps);
  if (!recommendationText) {
    return W.resolve();
  }
  _ref1 = getRecommendationDirectionAndStrength(recommendation), recommendationDirection = _ref1[0], recommendationStrength = _ref1[1];
  conclusions = exportConclusions(recommendation, ['recommendation', 'goodPracticeStatement']);
  conclusions['sections']['justification'] = exportJustification(recommendation);
  coexistingConditions = _.chain(getRecommendationMetadata(recommendation, 'coexistingCondition', '')[0].split(';')).map(function(value) {
    return _.str.capitalize(value.trim());
  }).compact().value();
  _ref2 = getCertaintyOfEvidenceAndLabel(recommendation), certaintyOfEvidence = _ref2[0], gradeCertaintyOfEvidenceLabel = _ref2[1];
  coi = renderEditorValue(get(recommendation, 'templateData.question.sections.coi.content'));
  if (!coi) {
    coi = void 0;
  }
  perspective = renderEditorValue(get(recommendation, 'templateData.question.sections.perspective.content'));
  if (!perspective) {
    perspective = void 0;
  }
  comparison = question.type === 'diagnostic' && !question.comparatorTestPresent ? void 0 : [
    {
      '@id': '_:int2'
    }
  ];
  evidenceTableLink = getRecommendationMetadata(recommendation, 'evidenceTableUrl')[0];
  if (_.isEmpty(evidenceTableLink)) {
    evidenceTableLink = void 0;
  }
  loveLink = getRecommendationMetadata(recommendation, 'loveUrl')[0];
  if (_.isEmpty(loveLink)) {
    loveLink = void 0;
  }
  emlLink = getRecommendationMetadata(recommendation, 'emlUrl');
  if (_.isEmpty(emlLink)) {
    emlLink = void 0;
  }
  individualRecommendationLink = getRecommendationMetadata(recommendation, 'individualRecommendation')[0];
  if (_.isEmpty(individualRecommendationLink)) {
    individualRecommendationLink = void 0;
  }
  questionStatus = get(questionStatuses, "" + question._id + ".evidenceTableStatus.status");
  presentationStatus = get(questionStatuses, "" + question._id + ".presentationStatus.status");
  isofData = questionStatus === 'done' ? exportIsof(questionModel, projectName) : void 0;
  plainLanguageSummary = _.reject(getRecommendationMetadata(recommendation, 'plainLanguageSummary'), function(plr) {
    return _.isEmpty(plr.link);
  });
  printoutPromise = presentationStatus === 'done' ? getEtdPrintout(projectId, questionModel, recommendationId) : W.resolve(void 0);
  symptoms = getRecommendationMetadata(recommendation, 'symptoms');
  return printoutPromise.then(function(etdPrintout) {
    return {
      '@id': question['_id'],
      '@type': 'WHORecommendation',
      'intervention': exportInterventions(question),
      'interventionDescription': "" + question['intervention'] + ", " + question['comparison'],
      'population': exportPopulation(question, recommendation),
      'populationDescription': question['healthProblemOrPopulation'],
      'question': [
        {
          '@id': '_:q1',
          '@type': 'WHOQuestion',
          'intervention': [
            {
              '@id': '_:int1'
            }
          ],
          'comparison': comparison,
          'population': [
            {
              '@id': '_:pop1'
            }
          ],
          'gradeCertaintyOfEvidence': certaintyOfEvidence,
          'gradeCertaintyOfEvidenceLabel': gradeCertaintyOfEvidenceLabel,
          'perspective': perspective
        }
      ],
      'recommendation': {
        'coi': coi,
        'direction': recommendationDirection,
        'strength': recommendationStrength,
        'assessmentSummary': exportAssessmentSummary(recommendation),
        'conclusions': conclusions,
        'value': recommendationText
      },
      'adolopment': getRecommendationMetadata(recommendation, 'adolopment')[0],
      'recommendationIntent': _.union(['all'], getRecommendationMetadata(recommendation, 'recommendationIntent')),
      'pccModule': _.union(['all'], getRecommendationMetadata(recommendation, 'canPCCModule')),
      'recommendationIntentWHO': _.union(['all'], getRecommendationMetadata(recommendation, 'recommendationIntentWHO')),
      'intendedPopulation': getRecommendationMetadata(recommendation, 'intendedPopulation'),
      'symptoms': _.first(symptoms) === 'not_applicable' ? void 0 : symptoms,
      'interventionTypes': getRecommendationMetadata(recommendation, 'interventionTypes'),
      'hospitalization': getRecommendationMetadata(recommendation, 'hospitalization'),
      'vaccination': getRecommendationMetadata(recommendation, 'vaccination'),
      'coexistingCondition': coexistingConditions,
      'progress': exportProgress(recommendation),
      'adapted': getRecommendationMetadata(recommendation, 'adapted', false)[0],
      'informal': getRecommendationMetadata(recommendation, 'informal', false)[0],
      'research': getRecommendationMetadata(recommendation, 'research', false)[0],
      'reportedAsGPS': getRecommendationMetadata(recommendation, 'reportedAsGPS', false)[0],
      'transformed': getRecommendationMetadata(recommendation, 'transformed', false)[0],
      'sofSupplemented': getRecommendationMetadata(recommendation, 'sofSupplemented', false)[0],
      'etdModified': getRecommendationMetadata(recommendation, 'etdModified', false)[0],
      'gradingEvidenceMethod': getRecommendationMetadata(recommendation, 'gradingEvidenceMethod')[0],
      'gps': isGps,
      'diagnostic': isDiagnostic,
      'isofData': isofData,
      'evidenceTableLink': evidenceTableLink,
      'etdPrintout': etdPrintout,
      'loveLink': loveLink,
      'emlLink': emlLink,
      'individualRecommendationLink': individualRecommendationLink,
      'expertComment': renderEditorValueFromRecommendationMetadata(recommendation, 'expertComment'),
      'authors': renderEditorValueFromRecommendationMetadata(recommendation, 'authors'),
      'publicationDate': getRecommendationMetadataOrUndefined(recommendation, 'publicationDate'),
      'plainLanguageSummaryDescription': renderEditorValueFromRecommendationMetadata(recommendation, 'plainLanguageSummaryDescription'),
      'plainLanguageSummary': plainLanguageSummary,
      'keywords': getRecommendationMetadata(recommendation, 'keywords')
    };
  });
};

module.exports = exportQuestionAndRecommendation;
