appUtils = require 'lib/app_utils'
mediator = require 'mediator'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
DropdownMenu = require 'components/common/dropdown_menu'
IconButton = require 'components/common/icon_button'
useTranslation = require 'components/hooks/use_translation'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
{ scrollIntoView } = require 'lib/epiditor_utils'
{ getAccessRightsList } = require 'lib/members_helper'
{ MDG_WORKSPACE } = require 'lib/mdg_helper'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

{ default: Editor } = Epiditor
{ useRef, useCallback, useMemo, useRef, useEffect, useState } = React

actionsMenuPosition = {
  position: 'bottom'
  alignment: 'end'
}

CommentItem = React.memo ({
  activeDiscussionItem,
  data,
  editingDiscussionItems,
  editingReplyId,
  isDraft,
  mdaTableId,
  nestedComment,
  sectionId,
}) ->

  i18n = useTranslation('')
  elRef = useRef(null)
  {
    id
    resolved
    time
    data: itemData
    author
    from
    parentId
    visibleFor
  } = data.toJS()
  editing = editingDiscussionItems.get(id)
  { text } = itemData || {}

  active = if _.isArray(activeDiscussionItem)
    _.includes(activeDiscussionItem, id)
  else
    activeDiscussionItem is id

  editorViewRef = useRef(null)

  commentEditorRef = useCallback((editorView) ->
    editorViewRef.current = editorView
  , [])

  itemActions = useCoffeeMemo [author.id, resolved, i18n], ->
    isCurrentUserAdmin = author.id is mediator.user.get('_id')
    hasUserAdminLikeAccessRight = mediator.user.hasRole ['admin', 'mdg_editor_in_chief']
    _.chain ['edit', (if resolved then 'reopen' else 'resolve'), 'delete']
    .map (value) ->
      if (isCurrentUserAdmin and value not in ['resolve', 'reopen']) or hasUserAdminLikeAccessRight
        {
          value,
          text: i18n "docsec:comment_actions.#{value}"
        }
    .compact()
    .value()

  updateItem = (updatedFields) ->
    if editorViewRef.current
      payload = {
        id
        sectionId
        mdaTableId
        discussionType: 'comments'
        updatedData: updatedFields
      }

      if isDraft
        DocumentSectionsV2Actions.updateDraftDiscussion payload
      else
        DocumentSectionsV2Actions.saveDiscussionItem _.extend payload, {
          projectId: mediator.project.id
        }

  removeItem = ->
    payload = { item: data.toJS(), mdaTableId, sectionId, discussionType: 'comments' }
    if isDraft
      DocumentSectionsV2Actions.removeDraftDiscussion payload
    else
      DocumentSectionsV2Actions.removeDiscussionItem _.extend payload, {
        projectId: mediator.project.id
      }

  handleAction = (action) ->
    switch action
      when 'edit'
        DocumentSectionsV2Actions.editDiscussion { id }
      when 'resolve'
        updateItem resolved: true
      when 'delete'
        removeItem()
      when 'reopen'
        updateItem resolved: false

  closeCommentEdit = ->
    DocumentSectionsV2Actions.closeDiscussionEdit id

  handleCancel = (evt) ->
    evt.stopPropagation()
    if editing?.isNew
      removeItem()
    else
      closeCommentEdit()

  editorPluginProps = useMemo(
    ->
      annotations: { disabled: true }
    []
  )

  handleSaveComment = (evt) ->
    evt.stopPropagation()
    newItemData = _.extend {}, itemData, text: editorViewRef.current.state.toJSON()
    updateItem data: newItemData
    closeCommentEdit()

  handleClick = (evt) ->
    return if editing
    evt.preventDefault()
    evt.stopPropagation()
    scrollToContent = { sectionId, contentPos: from }
    projectId = mediator.project.id
    if active
      DocumentSectionsV2Actions.setScrollToSectionContent _.extend scrollToContent, { projectId }
    else
      DocumentSectionsV2Actions.setActiveDiscussionItem { projectId, id: [id], scrollToContent }

  toggleCommentVisibility = useCoffeeCallback [data], (e) ->
    e.stopPropagation()
    e.preventDefault()
    # reset visibility to default state, sort of uncheck
    newRole = e.target.getAttribute('data-role')
    visibleFor = if _.contains(visibleFor or [], newRole)
      _.without(visibleFor or [], newRole)
    else
      (visibleFor or []).concat newRole
    updateItem { visibleFor }

  # scroll this item into view when it gets activated
  useEffect(->
    scrollIntoView(elRef.current) if active and elRef.current
    return
  , [active])

  showReplyInput = useCoffeeMemo [editing, nestedComment, active, isDraft, id], ->
    not editing and not nestedComment and active and not _.isEmpty id

  <div>
    <div
      ref={elRef}
      onClick={handleClick}
      className={classNames 'discussion-item', 'comment', {
        'active': active and !nestedComment,
        editing,
        nestedComment
      }}
    >
      <div className="flex flex-row items-center">
        <div className="author-name flex-1 bold truncate">
          {author?.name}
        </div>
        <div className="flex-none">
          <span className="ts mr-10">{moment(parseInt(time)).format('DD MMM YYYY')}</span>
          {not editing and not _.isEmpty(itemActions) and <DropdownMenu
            opener={<IconButton iconName="more" className="more-icon" />}
            options={itemActions}
            onSelect={handleAction}
            menuPosition={actionsMenuPosition}
          />}
        </div>
      </div>
      <div>
        <Editor
          mode={if editing then 'edit' else 'readonly'}
          initialState={text}
          onLoad={commentEditorRef}
          pluginProps={editorPluginProps}
          minimal
        />

        {!nestedComment and <VisibilityMarks
          visibleFor={visibleFor}
          toggleCommentVisibility={toggleCommentVisibility}
        />}
        {editing and
          <ApplyCancelButtons
            applyLabel={i18n 'actions.submit'}
            onApply={handleSaveComment}
            onCancel={handleCancel}
          />
        }
      </div>
      {not data.get('replies', Immutable.List()).isEmpty() and <div className="comment-replies">
        {data.get('replies').map (reply) ->
          <CommentItem
            key={reply.get('id')}
            activeDiscussionItem={activeDiscussionItem}
            data={reply.get('data')}
            editingDiscussionItems={editingDiscussionItems}
            isDraft={reply.get('isDraft')}
            mdaTableId={mdaTableId}
            sectionId={sectionId}
            nestedComment
          />
        }
      </div>
      }
      {showReplyInput and <div className="comment__reply-container">
        <ReplyToComment
          sectionId={sectionId}
          mdaTableId={mdaTableId}
          parent={data.toJS()}
          editorPluginProps={editorPluginProps}
        />
      </div>}
    </div>
  </div>

CommentItem.propTypes =
  data: PropTypes.instanceOf(Immutable.Map).isRequired
  sectionId: PropTypes.string.isRequired
  editingDiscussionItems: PropTypes.instanceOf(Immutable.Map).isRequired
  active: PropTypes.bool
  isDraft: PropTypes.bool
  editingReplyId: PropTypes.string
  nestedComment: PropTypes.bool

CommentItem.defaultProps =
  nestedComment: false

VisibilityMarks = React.memo ({ visibleFor, toggleCommentVisibility }) ->
  i18n = useI18n('team:member_access_rights')
  accessRightsList = useCoffeeMemo [], -> getAccessRightsList().map ({ text, value }) ->
    {
      value,
      text: switch value
        when 'mdg_editor_in_chief' then i18n 'mdg_editor_in_chief.short_label'
        when 'mdg_panel_reviewer' then i18n 'mdg_panel_reviewer.short_label'
        when 'evolent_panel_reviewer' then i18n 'evolent_panel_reviewer.short_label'
        else text
    }

  canUserToggleVisibility = if appUtils.isActiveWorkspaceOrFeatureSwitchOn(
    MDG_WORKSPACE, 'mdgDocSecFeatures'
  )
    mediator.user.hasRole ['admin', 'mdg_editor_in_chief', 'researcher']
  else
    true

  <div className="mark-for-role-buttons">
    {accessRightsList.map (role) ->
      buttonClassNames = classNames
        marked: role.value in visibleFor,
        enabled: canUserToggleVisibility
      return if role.value is 'no_access'
      <button
        key={role.value}
        className={buttonClassNames}
        data-role={role.value}
        onClick={toggleCommentVisibility if canUserToggleVisibility}
      >
        {role.text}
      </button>
    }
  </div>

VisibilityMarks.propTypes =
  visibleFor: PropTypes.arrayOf(PropTypes.string)
  toggleCommentVisibility: PropTypes.func.isRequired
  accessRights: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }))

VisibilityMarks.defaultProps =
  visibleFor: []

ReplyToComment = ({ sectionId, mdaTableId, parent, editorPluginProps }) ->
  i18n = useI18n('docsec:discussions')
  [focus, setFocus] = useState(false)
  editorViewRef = useRef(null)

  commentEditorRef = useCallback((editorView) ->
    editorViewRef.current = editorView
  , [])

  handleSaveComment = useCoffeeCallback [sectionId, mdaTableId, parent, editorViewRef], (e) ->
    e.stopPropagation()
    e.preventDefault()
    DocumentSectionsV2Actions.addDiscussionReply {
      sectionId,
      mdaTableId,
      parent,
      text: editorViewRef.current.state.toJSON()
    }
    .then clearCommentReply

  clearCommentReply = useCoffeeCallback [editorViewRef], ->
    { state } = editorViewRef.current
    newState = state.apply(state.tr.delete(0, state.doc.content.size))
    editorViewRef.current.updateState(newState)
    setFocus false

  onFocus = useCoffeeCallback [],  ->
    setFocus true

  onBlur = ->
    _.delay ->
      setFocus(false)
    , 250

  <div>
    <div className="reply-to-comment-editor" >
      <Editor
        mode={'edit'}
        initialState={null}
        onLoad={commentEditorRef}
        pluginProps={editorPluginProps}
        onFocus={onFocus}
        onBlur={onBlur}
        minimal
      />
    </div>
    {focus && <ApplyCancelButtons
      applyLabel={i18n '/actions.reply'}
      onApply={handleSaveComment}
      onCancel={clearCommentReply}
    />}
  </div>


module.exports = CommentItem
