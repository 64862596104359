var CollectionView, EvidenceSynthesesToolbarView, ImportActions, PrognosticQuestion, Question, QuestionGroupView, QuestionView, QuestionsView, Scrolling, Shortcuts, Sortable, mediator, questionGroupElement, template, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CollectionView = require('base/views/collection_view');

Question = require('models/question');

PrognosticQuestion = require('models/prognostic_question');

QuestionView = require('views/evidence_syntheses/question_view');

QuestionGroupView = require('views/evidence_syntheses/question_group_view');

EvidenceSynthesesToolbarView = require('views/evidence_syntheses_toolbar_view');

Shortcuts = require('base/lib/traits/shortcuts');

Scrolling = require('base/lib/traits/scrolling');

template = require('views/templates/evidence_syntheses/questions');

mediator = require('mediator');

Sortable = require('base/lib/traits/sortable');

utils = require('base/lib/utils');

questionGroupElement = require('views/templates/evidence_syntheses/partials/question_group_element');

ImportActions = require('actions/import_actions');

module.exports = QuestionsView = (function(_super) {
  __extends(QuestionsView, _super);

  function QuestionsView() {
    this._createGroup = __bind(this._createGroup, this);
    this.toggleNewQuestionGroup = __bind(this.toggleNewQuestionGroup, this);
    this._showNewQuestionGroup = __bind(this._showNewQuestionGroup, this);
    this._hideNewQuestionGroup = __bind(this._hideNewQuestionGroup, this);
    this._groupRemoved = __bind(this._groupRemoved, this);
    this._questionMoved = __bind(this._questionMoved, this);
    this._groupCreated = __bind(this._groupCreated, this);
    return QuestionsView.__super__.constructor.apply(this, arguments);
  }

  QuestionsView.prototype.container = '#page-container';

  QuestionsView.prototype.className = 'questions-view';

  QuestionsView.prototype.template = template;

  QuestionsView.prototype.itemView = QuestionView;

  QuestionsView.prototype.autoRender = false;

  QuestionsView.prototype.addCollectionSizeValidation = true;

  QuestionsView.prototype.animationDuration = 10;

  QuestionsView.prototype.listSelector = 'ul.standard-list';

  QuestionsView.prototype.itemSelector = 'li.question';

  QuestionsView.prototype.sortableDelay = 150;

  QuestionsView.prototype.switchItems = true;

  QuestionsView.prototype.initialize = function(options) {
    this.enable(Scrolling, {
      fixedElements: '.row.mt-10, #new-question-group-container',
      additionalContentSpace: (function(_this) {
        return function() {
          return parseInt(_this.$('.group-list').css('margin-bottom'));
        };
      })(this)
    });
    this.enable(Shortcuts);
    this.enable(Sortable, {
      containerSelector: '.grouped-questions',
      draggingBoundaries: '.scrolled',
      connectedLists: '.grouped-questions',
      sortedItems: '.question',
      adjustWidthTo: this.$el,
      delay: this.sortableDelay,
      scrollSensitivity: 75
    });
    QuestionsView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.add-management-question', this.addQuestion);
    this.delegate('click', 'button.add-diagnostic-question', this.addDiagnosticQuestion);
    this.delegate('click', 'button.add-prognostic-question', this.addPrognosticQuestion);
    this.delegate('click', 'button.import-question', this.importQuestion);
    this.delegate('click', '#new-question-group', function() {
      return this.groups.create();
    });
    mediator.subscribe('editBoxOpened', this._hideNewQuestionGroup, 'editableItem');
    mediator.subscribe('editBoxClosed', (function(_this) {
      return function() {
        if (mediator.user.get("showDropQuestionGroupArea")) {
          return _this._showNewQuestionGroup();
        }
      };
    })(this), 'editableItem');
    mediator.user.on('change:showDropQuestionGroupArea', this.toggleNewQuestionGroup);
    this.modelBind('add', (function(_this) {
      return function() {
        return _this.publishEvent('questionsListUpdated');
      };
    })(this));
    this.modelBind('change', (function(_this) {
      return function() {
        return _this.publishEvent('questionsListUpdated');
      };
    })(this));
    this.modelBind('destroy', (function(_this) {
      return function() {
        return _this.publishEvent('questionsListUpdated');
      };
    })(this));
    this.subview('toolbar', new EvidenceSynthesesToolbarView({
      moduleName: this.options.moduleName,
      baseUrl: options.baseUrl,
      disableExportButton: true,
      groupsPromise: this.options.groupsPromise
    }));
    return this.options.groupsPromise.then((function(_this) {
      return function(groups) {
        if (_this.disposed) {
          return;
        }
        _this.groups = groups;
        _this.groups.on('groupCreated', _this._groupCreated);
        _this.groups.on('groupRemoved', _this._groupRemoved);
        _this.groups.on('questionMoved', _this._questionMoved);
        return _this.render();
      };
    })(this));
  };

  QuestionsView.prototype._renderGroup = function(groupModel, $groupElement) {
    var view;
    if (!$groupElement) {
      $groupElement = $(questionGroupElement({
        index: this.groups.groupsSize() - 1,
        _id: groupModel.id
      }));
      this.$('li.questions-wo-group').before($groupElement);
    }
    this.subview("qg:" + groupModel.id, view = new QuestionGroupView({
      model: groupModel,
      parentView: this
    }));
    return $groupElement.prepend(view.render().$el);
  };

  QuestionsView.prototype._groupCreated = function(groupId, groupIdx) {
    var model;
    model = this.groups.getGroupModel(groupId);
    this._renderGroup(model);
    return _.defer((function(_this) {
      return function() {
        _this._triggerFixLayout();
        _this.applySortable();
        return _this.publishEvent('questionsListUpdated');
      };
    })(this));
  };

  QuestionsView.prototype._isQuestionInGroup = function(questionId, groupIdx) {
    return this.$(".grouped-questions[data-idx=" + groupIdx + "] .question[data-question-id=" + questionId + "]").length;
  };

  QuestionsView.prototype._questionMoved = function(questionId, groupIdx) {
    var $questionEl;
    if (!this._isQuestionInGroup(questionId, groupIdx)) {
      $questionEl = this.$(".question[data-question-id=" + questionId + "]").detach();
      this.$(".grouped-questions[data-idx=" + groupIdx + "]").append($questionEl);
      this.$(".grouped-questions[data-idx=" + groupIdx + "]").removeClass('empty');
      _.defer((function(_this) {
        return function() {
          return _this._triggerFixLayout();
        };
      })(this));
    }
    return this.publishEvent('questionsListUpdated');
  };

  QuestionsView.prototype._groupRemoved = function(groupId, groupIdx) {
    var $questionEl, $questionsWoGroup, from, model, questionCid, questionEl, to, _i, _len, _ref;
    $questionsWoGroup = this.$('.questions-wo-group ul');
    _ref = this.$(".question-group[data-group-id=" + groupId + "] .grouped-questions .question");
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      questionEl = _ref[_i];
      $questionEl = $(questionEl);
      $questionEl.detach();
      questionCid = $questionEl.data('itemCid');
      model = this.collection.getByCid(questionCid);
      from = this.collection.indexOf(model);
      to = this.collection.length - 1;
      this.collection.switchItems(from, to, true);
      this.insertView(model, this.getItemViews()[questionCid]);
    }
    this.$(".question-group[data-group-id=" + groupId + "]").remove();
    this.removeSubview("qg:" + groupId);
    return _.defer((function(_this) {
      return function() {
        return _this.publishEvent('questionsListUpdated');
      };
    })(this));
  };

  QuestionsView.prototype._hideNewQuestionGroup = function() {
    this.$('.questions-container').removeClass('drop-area-visible');
    return this.$('.new-question-group-container').hide();
  };

  QuestionsView.prototype._showNewQuestionGroup = function() {
    this.$('.questions-container').addClass('drop-area-visible');
    return this.$('.new-question-group-container').show();
  };

  QuestionsView.prototype.toggleNewQuestionGroup = function() {
    var $container;
    $container = this.$('.questions-container');
    if (mediator.user.get('showDropQuestionGroupArea')) {
      $container.addClass('drop-area-visible');
    } else {
      $container.removeClass('drop-area-visible');
    }
    this.$('.new-question-group-container').toggle(mediator.user.get('showDropQuestionGroupArea'));
    return this._triggerFixLayout();
  };

  QuestionsView.prototype._getItemElements = function() {
    return this.$list.find(this.itemSelector);
  };

  QuestionsView.prototype._insertViewElement = function(item, viewEl, position) {
    var $questionGroup, added, elOrderNumber, questionGroupIdx;
    if (!this.groups) {
      return;
    }
    questionGroupIdx = this.groups.getGroupIndexForQuestionId(item.id);
    $questionGroup = questionGroupIdx != null ? this.$(".grouped-questions[data-idx=" + questionGroupIdx + "]") : this.$('.questions-wo-group ul');
    $questionGroup.removeClass('empty');
    if ($.contains($questionGroup[0], viewEl)) {
      return;
    }
    elOrderNumber = $(viewEl).data('questionOrderNumber');
    added = false;
    $questionGroup.each(function(item, i) {
      var $item, itemOrderNumber;
      $item = $(item);
      itemOrderNumber = $item.data('questionOrderNumber');
      if (elOrderNumber <= itemOrderNumber) {
        $item.before(viewEl);
        return added = true;
      }
    });
    if (!added) {
      return $questionGroup.append(viewEl);
    }
  };

  QuestionsView.prototype.removeViewForItem = function(item) {
    var $parentList;
    $parentList = this.getItemViewByCid(item.cid).$el.parent();
    if ($parentList && $parentList.find(this.itemSelector).length === 1) {
      $parentList.addClass('empty');
    }
    return QuestionsView.__super__.removeViewForItem.apply(this, arguments);
  };

  QuestionsView.prototype._switchCollectionItems = function(fromIdx, toIdx, item, sourceList) {
    var fromIndex, groupIdx, ids, itemCid, nextIndex, nextItem, nextQuestionId, prevIndex, prevItem, prevQuestionId, promise, questionId, targetList, toIndex;
    itemCid = item.data('item-cid');
    questionId = this.collection.getByCid(itemCid).id;
    ids = this.collection.pluck('_id');
    fromIndex = ids.indexOf(questionId);
    prevItem = item.prev(this.itemSelector);
    nextItem = item.next(this.itemSelector);
    toIndex = nextItem.length ? (nextQuestionId = this.collection.getByCid(nextItem.data('item-cid')).id, nextIndex = ids.indexOf(nextQuestionId), nextIndex > fromIndex ? nextIndex - 1 : nextIndex) : prevItem.length ? (prevQuestionId = this.collection.getByCid(prevItem.data('item-cid')).id, prevIndex = ids.indexOf(prevQuestionId), prevIndex < fromIndex ? prevIndex + 1 : prevIndex) : fromIndex;
    targetList = item.parent();
    groupIdx = targetList.data('idx');
    promise = groupIdx != null ? this.groups.moveQuestion(questionId, groupIdx) : this.groups.removeQuestionFromGroup(questionId);
    targetList.removeClass('empty');
    if (sourceList && !sourceList.find(this.itemSelector).length) {
      sourceList.addClass('empty');
    }
    return promise.then((function(_this) {
      return function() {
        _this.collection.switchItems(fromIndex, toIndex);
        return _this.publishEvent('questionsListUpdated');
      };
    })(this)).otherwise(mediator.dialogs.error);
  };

  QuestionsView.prototype.getTemplateData = function() {
    var data, idx;
    idx = 0;
    data = _(QuestionsView.__super__.getTemplateData.apply(this, arguments)).extend({
      groups: _.indexObjectsArray(_(this.groups.getGroupModels()).pluck('attributes')),
      enableImport: true,
      prognosticQuestions: this.options.prognosticQuestions
    });
    return data;
  };

  QuestionsView.prototype._triggerFixLayout = _(function() {
    return this.trigger('!fixLayout');
  }).debounce(300);

  QuestionsView.prototype.afterRender = function() {
    var groupElement, i, models, sortableLists, toggleActive, _i, _len, _ref;
    QuestionsView.__super__.afterRender.apply(this, arguments);
    this.$('#new-question-group').droppable({
      hoverClass: 'about-to-drop',
      drop: (function(_this) {
        return function(e, ui) {
          _this.switchItems = false;
          return _this._createGroup(e, ui);
        };
      })(this)
    });
    this.toggleNewQuestionGroup();
    models = this.groups.getGroupModels();
    _ref = this.$('li.question-group');
    for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
      groupElement = _ref[i];
      this._renderGroup(models[i], $(groupElement));
    }
    toggleActive = (function(_this) {
      return function($el, state) {
        $el.toggleClass('active', state);
        return $('.grouped-questions').sortable('refresh');
      };
    })(this);
    this.$('.questions-wo-group').on('sortactivate', function(e) {
      return toggleActive($(e.target).parent(), true);
    });
    sortableLists = this.$('.grouped-questions');
    sortableLists.on('sortactivate', (function(_this) {
      return function() {
        if (!mediator.user.get('showDropQuestionGroupArea')) {
          return _this._showNewQuestionGroup();
        }
      };
    })(this));
    return sortableLists.on('sortdeactivate', (function(_this) {
      return function() {
        toggleActive(_this.$('li.active'), false);
        if (!mediator.user.get('showDropQuestionGroupArea')) {
          return _this._hideNewQuestionGroup();
        }
      };
    })(this));
  };

  QuestionsView.prototype._createGroup = function(event, info) {
    var $el, question;
    $el = info.draggable;
    question = this.getItemViewByCid($el.data('itemCid')).model;
    return this.groups.create().then((function(_this) {
      return function(groupIdx) {
        return _this.groups.moveQuestion(question.id, groupIdx);
      };
    })(this)).then((function(_this) {
      return function() {
        _this.trigger('!scroll', _this.$('li.question-group').last(), 100);
        return _this.switchItems = true;
      };
    })(this));
  };

  QuestionsView.prototype.addQuestion = function(e) {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        _this.collection.add(new Question());
        return _this.collection.sort();
      };
    })(this));
  };

  QuestionsView.prototype.addDiagnosticQuestion = function(e) {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        var question;
        question = new Question({
          type: 'diagnostic'
        });
        _this.collection.add(question);
        return _this.collection.sort();
      };
    })(this));
  };

  QuestionsView.prototype.addPrognosticQuestion = function() {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        _this.collection.add(new PrognosticQuestion());
        return _this.collection.sort();
      };
    })(this));
  };

  QuestionsView.prototype.importQuestion = function() {
    return ImportActions.openImportModal({
      importType: 'question'
    });
  };

  QuestionsView.prototype.rerenderAllItems = function() {
    var item, _i, _len, _ref;
    _ref = this.collection.models;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      item = _ref[_i];
      this.removeViewForItem(item);
    }
    return this.renderAllItems();
  };

  QuestionsView.prototype._undoDeleteQuestion = function() {
    this.undoNotifier.destroy();
    this._triggerFixLayout();
    this.collection.add(this.deletedQuestion);
    return this.deletedQuestion.undoDelete().then((function(_this) {
      return function() {
        return _this.rerenderAllItems();
      };
    })(this)).then(function() {
      return mediator.dialogs.success($.t('es:question.delete_undone'));
    }).otherwise(mediator.dialogs.fatalError);
  };

  QuestionsView.prototype.questionDeleted = function(deletedQuestion) {
    var _ref;
    if ((_ref = this.undoNotifier) != null) {
      _ref.destroy();
    }
    this.deletedQuestion = deletedQuestion;
    this.undoNotifier = mediator.dialogs.undo($.t('es:question.deleted')).on('click:undo', (function(_this) {
      return function() {
        return _this._undoDeleteQuestion();
      };
    })(this));
    return mediator.setFocus();
  };

  QuestionsView.prototype.dispose = function() {
    var _ref;
    mediator.user.off('change:showDropQuestionGroupArea');
    mediator.unsubscribe(null, null, 'editableItem');
    if (this.groups) {
      this.groups.off('groupCreated', this._groupCreated);
      this.groups.off('groupRemoved', this._groupRemoved);
      this.groups.off('questionMoved', this._questionMoved);
      this.groups = null;
    }
    this.options.groupsPromise = null;
    if ((_ref = this.undoNotifier) != null) {
      _ref.destroy();
    }
    this.undoNotifier = null;
    this.deletedQuestion = null;
    return QuestionsView.__super__.dispose.apply(this, arguments);
  };

  return QuestionsView;

})(CollectionView);
