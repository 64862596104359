var OutcomeEditRowView, OutcomeEditSofRowView, SelectableAttrList, mediator, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OutcomeEditRowView = require('views/evidence_syntheses/outcome_edit_row_view');

SelectableAttrList = require('lib/traits/selectable_attr_list');

template = require('views/templates/evidence_syntheses/outcome_edit_sof_row');

mediator = require('mediator');

module.exports = OutcomeEditSofRowView = (function(_super) {
  __extends(OutcomeEditSofRowView, _super);

  function OutcomeEditSofRowView() {
    this.hideEditCallback = __bind(this.hideEditCallback, this);
    return OutcomeEditSofRowView.__super__.constructor.apply(this, arguments);
  }

  OutcomeEditSofRowView.prototype.template = template;

  OutcomeEditSofRowView.prototype.footnotesBindings = {
    'type': [
      {
        selector: '.edit-container > span',
        method: 'after'
      }
    ],
    'metaType': [
      {
        selector: '.edit-container > span',
        method: 'after'
      }
    ]
  };

  OutcomeEditSofRowView.prototype.highlightFootnoteBingings = OutcomeEditSofRowView.prototype.footnotesBindings;

  OutcomeEditSofRowView.prototype.initialize = function() {
    OutcomeEditSofRowView.__super__.initialize.apply(this, arguments);
    return this.enable(SelectableAttrList);
  };

  OutcomeEditSofRowView.prototype.delegateFootnotesEvents = function() {
    return this.delegate('focus click', 'input[data-property="type"], input[data-property="metaType"]', function(e) {
      return this._setPropertyAndPublishActivatedPropertyEvent('type', $(e.target));
    });
  };

  OutcomeEditSofRowView.prototype.hideEditCallback = function() {
    if (this.disposed) {
      return;
    }
    this.render();
    if (this.options.hidden) {
      this.$el.hide();
    }
    this.$('.outcome-edit-block').hide();
    this.trigger('hidden');
    return this.parentView.$el.forceRedraw();
  };

  OutcomeEditSofRowView.prototype.showOutcomeEditView = function(e) {
    if (e != null) {
      e.preventDefault();
    }
    if (e != null) {
      e.stopPropagation();
    }
    return this.openEditView();
  };

  OutcomeEditSofRowView.prototype.openEditView = function(withSlide) {
    if (withSlide == null) {
      withSlide = true;
    }
    return mediator.setFocus(this, (function(_this) {
      return function() {
        _this._assignCurrentOutcomeEditView();
        if (_this.options.hidden) {
          _this.$el.css({
            display: 'table-row'
          });
        }
        _this.$('.outcome-edit-block').show();
        return _this.$('.outcome-toggleable-editor').slideDown({
          duration: withSlide ? 400 : 0
        }, function() {
          _this.parentView.trigger('!fixLayout');
          return _this.parentView.$el.forceRedraw();
        });
      };
    })(this));
  };

  OutcomeEditSofRowView.prototype.afterRender = function() {
    OutcomeEditSofRowView.__super__.afterRender.apply(this, arguments);
    setTimeout((function() {
      return this.$('.label-input input').focus();
    }), 0);
    if (this.options.hidden) {
      return this.$el.hide();
    }
  };

  return OutcomeEditSofRowView;

})(OutcomeEditRowView);
