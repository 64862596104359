ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Translation = require 'components/mixins/translation'
Select = require 'components/common/select'

ImportanceEditor = createReactClass
  displayName: 'SojImportanceEditor'
  mixins: [Translation('es:recommendations.table')]

  getInitialState: ->
    selected: @props.value || 'clear'

  apply: ->
    @props.applyCbk if @state.selected isnt 'clear' then @state.selected else @options[0].value

  select: (value) ->
    @setState selected: value

  render: ->
    @options = [
        text: @i18n 'importance_value_low'
        value: "low"
      ,
        text: @i18n 'importance_value_moderate'
        value: "moderate"
      ,
        text: @i18n 'importance_value_high'
        value: "high"
    ]
    if @props.value and @props.value isnt 'clear'
      @options.unshift
        text: @i18n 'importance_value_clear'
        value: "clear"

    <div className="soj-editor">
      <div className="title">{@i18n 'importance_for_decisions'}</div>
      <div className="main-question">
        <div className="label">{@props.sectionName}:</div>
        <div className="question">{@i18n 'importance_question',
          section: @props.sectionName.toLowerCase()}</div>
      </div>
      <div className="evaluation-question">
        <div>{@i18n 'importance_evaluation_question'}</div>
        <Select   options={@options}
                  selected={@state.selected}
                  onChange={@select} />
      </div>
      <ApplyCancelButtons
        onApply={@apply}
        onCancel={@props.cancelCbk}
      />
    </div>

module.exports = ImportanceEditor
