Modal = require 'components/common/modal'
{ SingleMemberForm } = require 'components/team/form/single_team_member_form'
Translation = require 'components/mixins/translation'

AddSingleTeamMember = createReactClass

  displayName: "AddSingleTeamMember"

  mixins: [Translation('team:member')]

  propTypes:
    onApply: PropTypes.func.isRequired
    onCancel: PropTypes.func.isRequired

  render: ->
    <Modal
      className="add-team-member-modal single"
      isOpen={true}
      closeButton={true}
      title={@i18n 'add_title'}
      onClose={@props.onCancel}>
      <SingleMemberForm
        onApply={@props.onApply}
        onCancel={@props.onCancel}
      />
    </Modal>

module.exports = AddSingleTeamMember
