var DEFAULT_PROJECT_LABELS, INITIAL_STATE, ProjectsActions, ProjectsStore, alt, applySearchFilter, callMethod, errorHandler, getKeyValObject, immutable, mediator, sortProjectsByField, _ref, _ref1;

alt = require('alt');

mediator = require('mediator');

immutable = require('stores/utils/immutable');

ProjectsActions = require('actions/projects_actions');

_ref = require('lib/projects_helper'), DEFAULT_PROJECT_LABELS = _ref.DEFAULT_PROJECT_LABELS, applySearchFilter = _ref.applySearchFilter, sortProjectsByField = _ref.sortProjectsByField;

errorHandler = require('lib/app_utils').errorHandler;

_ref1 = require('base/lib/utils'), getKeyValObject = _ref1.getKeyValObject, callMethod = _ref1.callMethod;

INITIAL_STATE = Immutable.fromJS({
  _meta: {
    everFetched: {},
    fetching: false,
    fetchingProjectsWorkspace: null,
    projectsReplicationProgress: {},
    projectsRemoteInfo: {},
    highlightedProjectId: '',
    activeTab: 'active',
    sortBy: {
      fieldName: 'dateModified',
      ascending: false
    },
    search: {
      isActive: false,
      searchText: ''
    },
    permittedProjectActions: {},
    successfulInvitationsToNotify: [],
    movingProjectToOrganizationStatus: {
      invalidProjectMembers: [],
      modalOpen: false,
      projectId: '',
      response: ''
    }
  },
  projects: {},
  projectsQuestions: {},
  projectsModels: {},
  projectsMembers: {},
  projectsInvitations: [],
  sharingStatusesByInvitationId: {},
  userData: {}
});

ProjectsStore = (function() {
  function ProjectsStore() {
    this.state = INITIAL_STATE;
    this.bindActions(ProjectsActions);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      getProjectsReplicationProgress: this.getProjectsReplicationProgress,
      hasEverFetched: this.hasEverFetched,
      getProjectsIdsForType: this.getProjectsIdsForType,
      getProjectsByType: this.getProjectsByType,
      getProjectQuestions: this.getProjectQuestions,
      getProjectsQuestions: this.getProjectsQuestions,
      getProjectsMembers: this.getProjectsMembers,
      getProjectsLabels: this.getProjectsLabels,
      getLabelsData: this.getLabelsData,
      getProjectsNotes: this.getProjectsNotes,
      getLanguage: this.getLanguage,
      getSortBy: this.getSortBy,
      getActiveTab: this.getActiveTab,
      getSharingInvitations: this.getSharingInvitations,
      isProjectActive: this.isProjectActive,
      getActiveSearch: this.getActiveSearch,
      getHighlightedProjectId: this.getHighlightedProjectId,
      getProjectsStatuses: this.getProjectsStatuses,
      getProjectsRemoteInfo: this.getProjectsRemoteInfo,
      getProject: this.getProject,
      getPermittedProjectActions: this.getPermittedProjectActions,
      getSuccessfulInvitationsToNotify: this.getSuccessfulInvitationsToNotify,
      getMovingProjectToOrganizationStatus: this.getMovingProjectToOrganizationStatus
    });
  }

  ProjectsStore.prototype.onDbChange = function(data) {
    var invitationId, status;
    return this.setState((function() {
      switch (data.docType) {
        case 'model':
          return this.state.setIn(['projectsModels', data._id], Immutable.fromJS(data));
        case 'user':
          return this.state.setIn(['userData'], Immutable.fromJS(data));
        case 'request':
          invitationId = data.invitationId, status = data.status;
          return this.state.withMutations(function(state) {
            state.mergeIn(['sharingStatusesByInvitationId'], Immutable.Map([[invitationId, status]]));
            if (status === 'complete') {
              return state.updateIn(['_meta', 'successfulInvitationsToNotify'], callMethod('push', invitationId));
            }
          });
        default:
          return this.state.set('projectsInvitations', Immutable.fromJS(data));
      }
    }).call(this));
  };

  ProjectsStore.prototype.onFetch = function(fetchParams) {
    if (fetchParams == null) {
      fetchParams = {};
    }
    return this.setState(this.state.withMutations(function(state) {
      var _ref2;
      if (!fetchParams.silent) {
        state.setIn(['_meta', 'fetching'], true);
      }
      return state.setIn(['_meta', 'fetchingProjectsWorkspace'], (_ref2 = fetchParams.organizationId) != null ? _ref2 : 'personal');
    }));
  };

  ProjectsStore.prototype.onUdbFetchSuccess = function(udbData) {
    var projectsInvitations, projectsModels, sharingStatusesByInvitationId, userData;
    projectsModels = udbData.projectsModels, userData = udbData.userData, projectsInvitations = udbData.projectsInvitations, sharingStatusesByInvitationId = udbData.sharingStatusesByInvitationId;
    return this.setState(this.state.withMutations(function(state) {
      state.set('projectsModels', Immutable.fromJS(projectsModels));
      state.set('userData', Immutable.fromJS(userData));
      state.set('projectsInvitations', Immutable.fromJS(projectsInvitations));
      return state.set('sharingStatusesByInvitationId', Immutable.fromJS(sharingStatusesByInvitationId));
    }));
  };

  ProjectsStore.prototype.onFetchSuccess = function(projects) {
    var projectsMap, workspaceId;
    projectsMap = Immutable.Map(_.map(projects, function(p) {
      return [p.id, Immutable.fromJS(p)];
    }));
    workspaceId = this.state.getIn(['_meta', 'fetchingProjectsWorkspace']) || 'personal';
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], false);
      state.setIn(['_meta', 'everFetched', workspaceId], true);
      state.setIn(['_meta', 'fetchingProjectsWorkspace'], null);
      return state.setIn(['projects', workspaceId], projectsMap);
    }));
  };

  ProjectsStore.prototype.onFetchProjectSuccess = function(_arg) {
    var organizationId, project, workspaceId;
    project = _arg.project, organizationId = _arg.organizationId;
    if (!project) {
      return;
    }
    workspaceId = organizationId != null ? organizationId : 'personal';
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['projects', workspaceId, project.id], Immutable.fromJS(project));
      return state.setIn(['_meta', 'fetching'], false);
    }));
  };

  ProjectsStore.prototype.onUpdateProjectReplicationProgress = function(_arg) {
    var lastSeq, projectId, updateSeq;
    projectId = _arg.projectId, lastSeq = _arg.lastSeq, updateSeq = _arg.updateSeq;
    if (lastSeq == null) {
      lastSeq = this.state.getIn(['_meta', 'projectsReplicationProgress', projectId, 'lastSeq'], 0);
    }
    return this.setState(this.state.setIn(['_meta', 'projectsReplicationProgress', projectId], Immutable.Map({
      lastSeq: lastSeq,
      updateSeq: updateSeq
    })));
  };

  ProjectsStore.prototype.onUpdateProjectRemoteInfo = function(_arg) {
    var info, projectId;
    projectId = _arg.projectId, info = _arg.info;
    return this.setState(this.state.setIn(['_meta', 'projectsRemoteInfo', projectId], Immutable.fromJS(info)));
  };

  ProjectsStore.prototype.onFetchReplicatedProjectSuccess = function(project) {
    var workspaceId, _ref2;
    workspaceId = (_ref2 = project.organizationId) != null ? _ref2 : 'personal';
    return this.setState(this.state.withMutations(function(state) {
      return state.mergeDeepIn(['projects', workspaceId, project.id], Immutable.fromJS(project));
    }));
  };

  ProjectsStore.prototype.onFetchProjectQuestionsSuccess = function(_arg) {
    var projectId, questions;
    projectId = _arg.projectId, questions = _arg.questions;
    return this.setState(this.state.setIn(['projectsQuestions', projectId], Immutable.fromJS(questions)));
  };

  ProjectsStore.prototype.onFetchProjectMembersSuccess = function(_arg) {
    var members, projectId;
    projectId = _arg.projectId, members = _arg.members;
    return this.setState(this.state.setIn(['projectsMembers', projectId], Immutable.fromJS(members)));
  };

  ProjectsStore.prototype.onFetchError = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    return errorHandler(err);
  };

  ProjectsStore.prototype.onFetchProjectError = function(err) {
    return errorHandler(err);
  };

  ProjectsStore.prototype.onCopyProjectSuccess = function(copyId) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'activeTab'], 'copies');
      state.setIn(['_meta', 'highlightedProjectId'], copyId);
      return state.setIn(['_meta', 'permittedProjectActions'], Immutable.Map());
    }));
  };

  ProjectsStore.prototype.onRestoreCopySuccess = function(newId) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'activeTab'], 'active');
      state.setIn(['_meta', 'highlightedProjectId'], newId);
      return state.setIn(['_meta', 'permittedProjectActions'], Immutable.Map());
    }));
  };

  ProjectsStore.prototype.onResetHighlightedProjectId = function() {
    return this.setState(this.state.setIn(['_meta', 'highlightedProjectId'], ''));
  };

  ProjectsStore.prototype.onChangeProjectLabel = function(_arg) {
    var labelId, projectId;
    projectId = _arg.projectId, labelId = _arg.labelId;
    return this.setState(this.state.setIn(['projectsLabels', projectId], labelId));
  };

  ProjectsStore.prototype.onUpdateProjectData = function(_arg) {
    var organizationId, projectId, updatedData, workspaceId;
    projectId = _arg.projectId, updatedData = _arg.updatedData, organizationId = _arg.organizationId;
    workspaceId = organizationId != null ? organizationId : 'personal';
    return this.setState(this.state.withMutations(function(state) {
      var projectData;
      if ('notes' in updatedData) {
        state.setIn(['projectsModels', projectId, 'userNotes'], updatedData.notes);
      }
      projectData = _.omit(updatedData, 'notes');
      if (!_.isEmpty(projectData)) {
        return state.mergeIn(['projects', workspaceId, projectId], Immutable.fromJS(projectData));
      }
    }));
  };

  ProjectsStore.prototype.onSortProjects = function(_arg) {
    var ascending, currentAsc, currentField, fieldName, _ref2;
    fieldName = _arg.fieldName, ascending = _arg.ascending;
    _ref2 = this.state.getIn(['_meta', 'sortBy']).toJS(), currentField = _ref2.fieldName, currentAsc = _ref2.ascending;
    if (ascending == null) {
      ascending = true;
    }
    if (fieldName === currentField) {
      return this.setState(this.state.setIn(['_meta', 'sortBy', 'ascending'], !currentAsc));
    } else {
      return this.setState(this.state.setIn(['_meta', 'sortBy'], Immutable.Map({
        fieldName: fieldName,
        ascending: ascending
      })));
    }
  };

  ProjectsStore.prototype.onChangeTab = function(tabName) {
    if (this.state.getIn(['_meta', 'fetching'])) {
      return;
    }
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'activeTab'], tabName);
      return state.setIn(['_meta', 'permittedProjectActions'], Immutable.Map());
    }));
  };

  ProjectsStore.prototype.onSearch = function(text) {
    return this.setState(this.state.withMutations(function(state) {
      return state.setIn(['_meta', 'search'], Immutable.Map(_.extend({
        isActive: true,
        searchText: text
      })));
    }));
  };

  ProjectsStore.prototype.onSetFetchingWorkspace = function(workspaceId) {
    return this.setState(this.state.setIn(['_meta', 'fetchingProjectsWorkspace'], workspaceId));
  };

  ProjectsStore.prototype.onResetSearch = function() {
    return this.setState(this.state.mergeIn(['_meta', 'search'], Immutable.Map({
      isActive: false,
      searchText: ''
    })));
  };

  ProjectsStore.prototype.onGetPossibleActionsForProjectSuccess = function(_arg) {
    var permittedActionsList, projectId;
    projectId = _arg.projectId, permittedActionsList = _arg.permittedActionsList;
    return this.setState(this.state.setIn(['_meta', 'permittedProjectActions', projectId], permittedActionsList));
  };

  ProjectsStore.prototype.onGetPossibleActionsForProjectError = function(err) {
    return errorHandler(err);
  };

  ProjectsStore.prototype.onSuccessfulNotificationShown = function(successfulInvitationId) {
    var idx;
    idx = this.state.getIn(['_meta', 'successfulInvitationsToNotify']).findIndex(R.equals(successfulInvitationId));
    return this.setState(this.state.deleteIn(['_meta', 'successfulInvitationsToNotify', idx]));
  };

  ProjectsStore.prototype.onToggleMoveToOrganizationModal = function(projectId) {
    var responsePath, statusPath;
    statusPath = ['_meta', 'movingProjectToOrganizationStatus'];
    if (!_.isEmpty(projectId)) {
      responsePath = statusPath.concat('response');
      this.setState(this.state.setIn(responsePath, ''));
    }
    return this.setState(this.state.withMutations(function(s) {
      var modalOpenPath, projectIdPath;
      modalOpenPath = statusPath.concat('modalOpen');
      projectIdPath = statusPath.concat('projectId');
      s.setIn(modalOpenPath, !s.getIn(modalOpenPath));
      return s.setIn(projectIdPath, projectId);
    }));
  };

  ProjectsStore.prototype.onMoveProjectToOrganizationSuccess = function() {
    var reponsePath;
    reponsePath = ['_meta', 'movingProjectToOrganizationStatus', 'response'];
    return this.setState(this.state.setIn(reponsePath, 'ok'));
  };

  ProjectsStore.prototype.onMoveProjectToOrganizationError = function(response) {
    var statusPath;
    statusPath = ['_meta', 'movingProjectToOrganizationStatus'];
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(statusPath.concat('response'), 'err');
      return s.setIn(statusPath.concat('invalidProjectMembers'), Immutable.fromJS(response.invalidProjectMembers));
    }));
  };

  ProjectsStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  ProjectsStore.prototype.hasEverFetched = function(organizationId) {
    var workspaceId;
    workspaceId = organizationId != null ? organizationId : 'personal';
    return this.state.getIn(['_meta', 'everFetched', workspaceId], false);
  };

  ProjectsStore.prototype.getProjectsIdsForType = function(type) {
    if (type !== 'archived' && type !== 'active' && type !== 'copies') {
      return Immutable.List();
    }
    return this.state.getIn(['userData', 'projects'], Immutable.List()).reduce(function(acc, p) {
      var _ref2;
      if (type === 'active') {
        if (p.get('isArchived') || p.get('isCopy') || ((_ref2 = p.get('status')) === 'deleted' || _ref2 === 'access_revoked')) {
          return acc;
        }
      } else if (type === 'archived') {
        if (!p.get('isArchived', false)) {
          return acc;
        }
      } else if (type === 'copies') {
        if (!p.get('isCopy', false)) {
          return acc;
        }
      }
      return acc.push(p.get('name'));
    }, Immutable.List());
  };

  ProjectsStore.prototype.getProjectsByType = function(type, organizationId) {
    var ascending, fieldName, filtered, projects, typeIds, workspaceId, _ref2;
    workspaceId = organizationId != null ? organizationId : 'personal';
    typeIds = this.getProjectsIdsForType(type);
    projects = this.state.getIn(['projects', workspaceId], Immutable.Map()).filter(function(p) {
      return typeIds.contains(p.get('id'));
    });
    filtered = applySearchFilter(projects, this.state.getIn(['_meta', 'search', 'searchText']));
    if (filtered.isEmpty()) {
      return filtered;
    } else {
      _ref2 = this.state.getIn(['_meta', 'sortBy']).toJS(), fieldName = _ref2.fieldName, ascending = _ref2.ascending;
      return sortProjectsByField(filtered, fieldName, ascending);
    }
  };

  ProjectsStore.prototype.getProject = function(projectId, organizationId) {
    var workspaceId;
    workspaceId = organizationId != null ? organizationId : 'personal';
    return this.state.getIn(['projects', workspaceId, projectId], Immutable.Map());
  };

  ProjectsStore.prototype.getProjectsLabels = function() {
    return this.state.get('projectsModels').reduce(function(acc, model, projectId) {
      var labelId;
      labelId = model.get('userLabel');
      if (!labelId) {
        return acc;
      }
      return acc.set(projectId, labelId);
    }, Immutable.Map());
  };

  ProjectsStore.prototype.getProjectsNotes = function() {
    return this.state.get('projectsModels').reduce(function(acc, model, projectId) {
      var notes;
      notes = model.get('userNotes');
      if (!notes) {
        return acc;
      }
      return acc.set(projectId, notes);
    }, Immutable.Map());
  };

  ProjectsStore.prototype.getLabelsData = function() {
    return this.state.getIn(['userData', 'projectsLabels'], DEFAULT_PROJECT_LABELS);
  };

  ProjectsStore.prototype.getProjectQuestions = function(projectId) {
    return this.state.getIn(['projectsQuestions', projectId], Immutable.List());
  };

  ProjectsStore.prototype.getProjectsQuestions = function() {
    return this.state.get('projectsQuestions');
  };

  ProjectsStore.prototype.getProjectsMembers = function() {
    return this.state.get('projectsMembers');
  };

  ProjectsStore.prototype.getLanguage = function() {
    return this.state.getIn(['userData', 'language']);
  };

  ProjectsStore.prototype.getSortBy = function() {
    return this.state.getIn(['_meta', 'sortBy']);
  };

  ProjectsStore.prototype.getActiveTab = function() {
    return this.state.getIn(['_meta', 'activeTab']);
  };

  ProjectsStore.prototype.getProjectsStatuses = function() {
    var _ref2;
    return (_ref2 = this.state.getIn(['userData', 'projects'])) != null ? _ref2.reduce(function(acc, projData) {
      return acc.set(projData.get('name'), projData.get('status'));
    }, Immutable.Map()) : void 0;
  };

  ProjectsStore.prototype.getProjectsReplicationProgress = function() {
    return this.state.getIn(['_meta', 'projectsReplicationProgress']);
  };

  ProjectsStore.prototype.getProjectsRemoteInfo = function() {
    return this.state.getIn(['_meta', 'projectsRemoteInfo']);
  };

  ProjectsStore.prototype.isProjectActive = function(projectId) {
    return (this.state.getIn(['userData', 'projects', projectId]) != null) || false;
  };

  ProjectsStore.prototype.getSharingInvitations = function() {
    var sharingStatusesByInvitationId;
    sharingStatusesByInvitationId = this.state.get('sharingStatusesByInvitationId');
    return this.state.get('projectsInvitations').map(function(inv) {
      var sharingRequestStatus;
      sharingRequestStatus = sharingStatusesByInvitationId.get(inv.get('id'));
      if (!((sharingRequestStatus != null) && sharingRequestStatus !== 'new')) {
        return inv;
      }
      return inv.set('status', sharingRequestStatus);
    }).filter(function(inv) {
      var _ref2;
      return (_ref2 = inv.get('status')) === 'pending' || _ref2 === 'accepted' || _ref2 === 'failure' || _ref2 === 'invalid';
    });
  };

  ProjectsStore.prototype.getActiveSearch = function() {
    return this.state.getIn(['_meta', 'search']);
  };

  ProjectsStore.prototype.getCopyingProjects = function() {
    return this.state.getIn(['_meta', 'copyingProjectIds']);
  };

  ProjectsStore.prototype.getHighlightedProjectId = function() {
    return this.state.getIn(['_meta', 'highlightedProjectId']);
  };

  ProjectsStore.prototype.getPermittedProjectActions = function() {
    return this.state.getIn(['_meta', 'permittedProjectActions']);
  };

  ProjectsStore.prototype.getSuccessfulInvitationsToNotify = function() {
    var successfulInvitations;
    successfulInvitations = this.state.getIn(['_meta', 'successfulInvitationsToNotify']);
    return this.state.get('projectsInvitations').filter(function(invitation) {
      return successfulInvitations.contains(invitation.get('id'));
    });
  };

  ProjectsStore.prototype.getMovingProjectToOrganizationStatus = function() {
    return this.state.getIn(['_meta', 'movingProjectToOrganizationStatus']);
  };

  return ProjectsStore;

})();

module.exports = alt.createStore(immutable(ProjectsStore), 'ProjectsStore');
