var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <div class=\"import-type-line\">\n        <div class=\"error-message\">"
    + escapeExpression(((helper = (helper = helpers.error || (depth0 != null ? depth0.error : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"error","hash":{},"data":data}) : helper)))
    + "</div>\n      </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <div class=\"error-message\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "errors:import.error_during_import", {"name":"i18n","hash":{},"data":data})))
    + "<br>\n          "
    + escapeExpression(((helper = (helper = helpers.importError || (depth0 != null ? depth0.importError : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"importError","hash":{},"data":data}) : helper)))
    + "\n      </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<fieldset>\n  <div class=\"import-source\">\n    <div class=\"from-disk\">\n      <input id=\"file\" type=\"file\">\n      <button class=\"file standard btn btn-block\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:import_dialog.choose_file", {"name":"i18n","hash":{},"data":data})))
    + "\n      </button>\n    </div>\n  </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.importError : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</fieldset>\n<div class=\"row mt-20\">\n  <div class=\"col-6\">\n    <button class=\"btn btn-block btn-cancel cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n  <div class=\"col-6\">\n    <button class=\"btn btn-block btn-import import\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "projects:import", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}