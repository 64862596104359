var MDG_TOPIC, docShape, documentId, initial, mdgTopicShape, number, string, _ref;

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

initial = require('lib/db_docs/field_types/type_decorators').initial;

MDG_TOPIC = require('lib/db_docs/doc_types').MDG_TOPIC;

_ref = require('lib/db_docs/field_types/built_in_types'), number = _ref.number, string = _ref.string;

mdgTopicShape = docShape({
  docType: initial(MDG_TOPIC)(string),
  name: initial('')(string),
  orderNumber: initial(1)(number)
});

module.exports = mdgTopicShape;
