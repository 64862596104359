var CustomRenderMixin, utils;

utils = require('base/lib/utils');

CustomRenderMixin = {
  shouldComponentUpdate: function(nextProps, nextState) {
    return !utils.objectsEqual(nextProps, this.props) || !utils.objectsEqual(nextState, this.state);
  }
};

module.exports = CustomRenderMixin;
