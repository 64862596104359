Router = require 'router'
mediator = require 'mediator'
LabeledInput = require 'components/common/labeled_input'

DecisionAidTitle = ({ isEditing, title, onChange, decisionAidId }) ->
  onClick = ->
    mediator.publish '!router:route',
      Router::getProjectRelativeUrl "/dissemination/decision-aids/details/#{decisionAidId}"

  if isEditing
    <LabeledInput
      label={$.t 'da:create-screen.title'}
      name='title'
      onChange={onChange}
      type='text'
      value={title}
    />
  else
    <div className='decision-aid__title' onClick={onClick}>
      {title}
    </div>


module.exports = DecisionAidTitle
