_.namespace(module, function(require) {
  var exportEpiditorContent;
  exportEpiditorContent = require('lib/services/document_sections_exporter/utils').exportEpiditorContent;
  return {
    exportSection: function(section, chapterPath, sectionParentPaths, chaptersPaths, exportParams) {
      var content, exportDate, guidelineId, linkPrefix, sectionId, title;
      sectionId = section._id, content = section.content, title = section.title;
      exportDate = exportParams.exportDate, guidelineId = exportParams.guidelineId, linkPrefix = exportParams.linkPrefix;
      return exportEpiditorContent({
        chapterPath: chapterPath,
        chaptersPaths: chaptersPaths,
        content: content,
        exportDate: exportDate,
        guidelineId: guidelineId,
        linkPrefix: linkPrefix,
        sectionId: sectionId,
        sectionParentPaths: sectionParentPaths,
        title: title,
        type: 'text'
      });
    }
  };
});
