Translation = require 'components/mixins/translation'
Switcher = require 'components/common/switcher'
{ instanceOf, func, oneOfType } = PropTypes
MemberInputItem = require 'components/team/form/member_input_item'

PanelMemberSelection = createReactClass
  displayName: "PanelMemberSelection"

  propTypes:
    members: oneOfType([
      instanceOf(Immutable.List),
      instanceOf(Immutable.OrderedMap)
    ]).isRequired
    questions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    selectedMembers: instanceOf(Immutable.List).isRequired
    updateMembersList: func.isRequired

  mixins: [Translation('voting:voting')]

  getInitialState: ->
    showUsersWithCoi: false
    allSelected: false

  componentWillReceiveProps: (nextProps) ->
    @checkIfAllSelected(nextProps.selectedMembers)

  membersWithoutCoi: ->
    {members, questions} = @props
    members.filterNot (member) ->
      questions.every (question) ->
        question.getIn ["coi", member.get("_id")], false

  membersWithCoi: ->
    {members, questions} = @props
    members.filter (member) ->
      questions.every (question) ->
        question.getIn ["coi", member.get("_id")], false

  onChange: (e) ->
    memberId = e.target.value
    selectedMembers = if @props.selectedMembers.contains memberId
      @props.selectedMembers.delete(@props.selectedMembers.indexOf(memberId))
    else
      @props.selectedMembers.push memberId
    @props.updateMembersList selectedMembers

  checkIfAllSelected: (selectedMembers) ->
    allSelected = if @state.showUsersWithCoi
      selectedMembers.size is @props.members.size
    else
      selectedMembers.size is @membersWithoutCoi().size
    @setState {allSelected} unless @props.members.size is 0

  toggleAll: ->
    membersWithoutCoi = @membersWithoutCoi()
    membersWithCoi = @membersWithCoi()
    visibleMembersCount = membersWithoutCoi.size

    if @state.showUsersWithCoi
      visibleMembersCount += membersWithCoi.size

    if visibleMembersCount is @props.selectedMembers.size
      selectedMembers = new Immutable.List()
    else
      selectedMembers = membersWithoutCoi.reduce (prev, member) ->
        prev.push member.get("_id")
      , new Immutable.List()

      if @state.showUsersWithCoi
        selectedMembers = membersWithCoi.reduce (prev, member) ->
          prev.push member.get("_id")
        , selectedMembers

    @props.updateMembersList selectedMembers

  showAllUsers: ->
    # we want to delete members with coi from selectedMembers list when hiding this list
    selectedMembers = if @state.showUsersWithCoi
      @membersWithCoi().reduce (prev, member) =>
        index = prev.indexOf(member.get("_id"))
        if index is -1 then prev else prev.delete(index)
      , @props.selectedMembers
    else @props.selectedMembers

    # we want to uncheck select all checkbox when showing list with members that has coi
    allSelected = if not @state.showUsersWithCoi
      false
    else @state.allSelected

    @setState {showUsersWithCoi: not @state.showUsersWithCoi, allSelected}
    @props.updateMembersList selectedMembers

  render: ->
    {members, questions} = @props

    <div className="panel-member-selection">
      <div className="options-row">
        <div className="pull-left">
          <div className="checkbox">
            <label>
              <input
                className="select-all"
                type="checkbox"
                onChange={@toggleAll}
                checked={@state.allSelected}
              />
              {@i18n "modals.select_all"}
            </label>
          </div>
        </div>
        <div className="pull-right">
          {if not @membersWithCoi().isEmpty()
            <Switcher
              className="show-users-with-coi"
              buttonText={@i18n 'modals.show_users_with_coi'}
              checked={@state.showUsersWithCoi}
              onChange={@showAllUsers}
            />
          }
        </div>
        <div className="clearfix" />
      </div>
      <div className="members">
        <div className="without-coi">
          <div className="title">{@i18n 'modals.members_without_coi'}</div>
            {@membersWithoutCoi().map (member, id) =>
              <MemberInputItem
                key={member.get("_id")}
                type="checkbox"
                member={member}
                onChange={@onChange}
                checked={@props.selectedMembers.contains(member.get("_id"))}
              />
            .toList()}
        </div>
        {if @state.showUsersWithCoi
          <div className="with-coi">
            <div className="title">{@i18n 'modals.members_with_coi'}</div>
              {@membersWithCoi().map (member, id) =>
                <MemberInputItem
                  key={member.get("_id")}
                  type="checkbox"
                  member={member}
                  onChange={@onChange}
                  checked={@props.selectedMembers.contains(member.get("_id"))}
                />
              .toList()}
          </div>
        }
      </div>
    </div>

module.exports = PanelMemberSelection
