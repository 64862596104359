ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
{ AutoSizer, CellMeasurerCache, CellMeasurer, List } = ReactVirtualized
Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
{ createReferenceDoc, mdgFormat, vancouverFormat } = require 'lib/references_utils'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
Modal = require 'components/common/modal'
RadioInput = require 'components/common/radio_input'
ReferenceAttachmentLink = require 'components/references/attachment_link'
ReferenceEditModal = require 'components/references/reference_edit_modal'
ReferencesActions = require 'actions/references_actions'
ReferencesStore = require 'stores/references_store'
SearchBar = require 'components/common/search_bar'
Spinner = require 'components/common/spinner'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useCoffeeMemo, useI18n, useSetState } = require 'lib/react_utils'

storeConnector =
  ReferencesStore: (Store, props) ->
    isFetchingReferences: Store.isFetching()
    reference: if props.value then Store.getReferenceById(props.value) else null
    references: Store.getReferencesWithSearch()
    searchString: Store.getActiveSearch()?.searchString ? ''

ReferencesSelectModal = ({
  isFetchingReferences
  isOpen
  onCancel
  onApply
  references
  searchString
}) ->
  [referenceIdSelected, setReferenceIdSelected] = React.useState(null)

  listRef = React.useRef null

  onSearch = useCoffeeCallback [listRef.current], (searchString) ->
    ReferencesActions.search searchString
    _.defer -> listRef.current?.scrollToPosition 0

  onResetSearch = useCoffeeCallback [listRef.current], ->
    ReferencesActions.resetSearch()
    _.defer -> listRef.current?.scrollToPosition 0

  onSelect = useCoffeeCallback [setReferenceIdSelected], (referenceId) -> ->
    setReferenceIdSelected referenceId

  onSave = useCoffeeCallback [onApply, referenceIdSelected], ->
    onApply referenceIdSelected

  i18n = useI18n('mdg_tables:edit_form')

  sortedReferences = useCoffeeMemo [references], ->
    references.sortBy (reference) -> vancouverFormat reference.toJS()

  cellMeasurerCache = useCoffeeMemo [sortedReferences], ->
    new CellMeasurerCache
      defaultHeight: 42
      fixedWidth: true
      keyMapper: (index) -> sortedReferences.getIn [index, '_id']

  rowRenderer = useCoffeeCallback [
    cellMeasurerCache
    onSelect
    referenceIdSelected
    sortedReferences
  ], ({ index, key, style }) ->
    reference = sortedReferences.get(index)
    refId = reference.get '_id'
    attachmentFileName = reference.getIn ['attachment', 'fileName']

    <CellMeasurer
      cache={cellMeasurerCache}
      columnIndex={0}
      key={key}
      parent={listRef.current}
      rowIndex={index}
    >
      {({ registerChild }) ->
        <div className="references__reference" ref={registerChild} style={style}>
          <div className="reference__radio-button">
            <RadioInput checked={referenceIdSelected is refId} onChange={onSelect(refId)}/>
          </div>
          <div className="reference__inner">
            <div className="inner_text" onClick={onSelect(refId)}>
              {vancouverFormat reference.toJS()}
            </div>
            {attachmentFileName and <ReferenceAttachmentLink
              attachmentFileName={attachmentFileName}
              className="inner__attachment"
              projectId={mediator.project.id}
              referenceId={refId}
            />}
          </div>
        </div>
      }
    </CellMeasurer>

  <Modal
    className="reference-select-modal"
    isOpen={isOpen}
    title={i18n 'fields.referenceId'}
  >
    {if isFetchingReferences
      <Spinner />
    else
      <React.Fragment>
        <div className="reference-select-modal__text">{i18n 'study.select'}</div>
        <div className="reference-select-modal__search">
          <SearchBar
            onSearch={onSearch}
            onSearchReset={onResetSearch}
            searchText={searchString}
          />
        </div>
        <div className="reference-select-modal__references">
          <AutoSizer>
            {({ height, width }) ->
              <List
                deferredMeasurementCache={cellMeasurerCache}
                height={height}
                ref={listRef}
                rowCount={sortedReferences.size}
                rowHeight={cellMeasurerCache.rowHeight}
                rowRenderer={rowRenderer}
                width={width}
              />
            }
          </AutoSizer>
        </div>
      </React.Fragment>
    }
    <ApplyCancelButtons
      applyLabel={i18n '/actions.add'}
      isSubmitEnabled={referenceIdSelected?}
      onApply={onSave}
      onCancel={onCancel}
    />
  </Modal>

INITIAL_STATE =
  isAddModalOpen: false
  isSelectModalOpen: false

ReferenceIdField = ({ isFetchingReferences, onChange, reference, references, searchString }) ->
  [{ isAddModalOpen, isSelectModalOpen }, setState] = useSetState INITIAL_STATE

  i18n = useI18n('mdg_tables:edit_form')

  openAddModal = useCoffeeCallback [setState], -> setState isAddModalOpen: true

  openSelectModal = useCoffeeCallback [setState], -> setState isSelectModalOpen: true

  onCancel = useCoffeeCallback [onChange, setState], ->
    setState INITIAL_STATE
    ReferencesActions.resetSearch()

  onSelect = useCoffeeCallback [onChange, setState], (referenceIdSelected) ->
   onChange referenceIdSelected
   setState INITIAL_STATE
   ReferencesActions.resetSearch()

  onAdd = useCoffeeCallback [onChange, references, setState], (reference) ->
    tmpDoc = createReferenceDoc reference
    refId = tmpDoc._id
    setState INITIAL_STATE
    ReferencesActions.saveReference reference, undefined, references
    .then -> onChange refId

  onClear = useCoffeeCallback [onChange], ->
    onChange undefined

  <div className="reference-field">
    <div className="reference-field__value">
      {if isFetchingReferences
        <Spinner scale={0.2} />
      else if reference
        <React.Fragment>
          <div className="value__text">{mdgFormat(reference.toJS())}</div>
          <div className="value__clear">
            <Tooltip>
              <IconButton
                iconName="cross"
                onClick={onClear}
                title={i18n 'study.clear'}
              />
            </Tooltip>
          </div>
        </React.Fragment>
      else
        <React.Fragment>
          <Button
            className="btn btn-apply value__select-reference"
            label={i18n 'study.select_study'}
            onClick={openSelectModal}
          />
          <Button
            className="btn btn-info value__add-manually"
            label={i18n 'study.add_manually'}
            onClick={openAddModal}
          />
        </React.Fragment>
      }
    </div>
    <ReferencesSelectModal
      isFetchingReferences={isFetchingReferences}
      isOpen={isSelectModalOpen}
      onCancel={onCancel}
      onApply={onSelect}
      references={references}
      searchString={searchString}
    />
    <ReferenceEditModal
      action="add"
      data={Immutable.Map()}
      isOpen={isAddModalOpen}
      onClose={onCancel}
      onSave={onAdd}
    />
  </div>

ReferenceIdField.propTypes =
  isFetchingReferences: PropTypes.bool.isRequired
  onChange: PropTypes.func.isRequired
  reference: PropTypes.instanceOf(Immutable.Map)
  references: PropTypes.instanceOf(Immutable.List).isRequired
  searchString: PropTypes.string.isRequired
  value: PropTypes.string

module.exports = ConnectStore ReferenceIdField, ReferencesStore, storeConnector
