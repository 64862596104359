DraggableChapterTitle = require 'components/decision_aid/draggable_decision_aid_chapter_title'
{ DndProvider } = ReactDnD

DraggableChaptersList = createReactClass
  displayName: 'DraggableChaptersList'

  propTypes:
    chaptersOrder: PropTypes.instanceOf(Immutable.List).isRequired
    chapters: PropTypes.instanceOf(Immutable.Map).isRequired

  render: ->
    { chapters } = @props

    <DndProvider backend={ReactDnDHTML5Backend.default}>
      <div className='chapters-list'>
        {@props.chaptersOrder.map (chapterId) ->
          chapterData = chapters.get chapterId

          <DraggableChapterTitle
            chapterId={chapterId}
            illustration={chapterData.get 'illustration'}
            key={chapterId}
            title={chapterData.get 'title'}
          />
        }
      </div>
    </DndProvider>

module.exports = DraggableChaptersList
