ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
{
  ExtractionForm
  extractionFormShape
} = require 'components/covid_extraction_form/extraction_form'
Modal = require 'components/common/modal'
{ useCoffeeCallback } = require 'lib/react_utils'

CreateUpdateFormModal = ({
  applyLabel
  cancelLabel
  className
  data
  form
  i18n
  isApplying
  isOpen
  isSubmitEnabled
  modalSize
  mode
  onCancel
  onCreate
  onChange
  onUpdate
  title
}) ->
  onApply = useCoffeeCallback [data, mode, onCreate, onUpdate], ->
    action = if mode is 'create' then onCreate else onUpdate
    action data

  applyLabelToUse = applyLabel or i18n(
    if mode is 'create' then '/actions.create' else '/actions.update'
  )
  cancelLabelToUse = cancelLabel or i18n '/actions.cancel'

  <Modal
    className={"create-update-form-modal #{className || ''}"}
    isOpen={isOpen}
    modalSize={modalSize}
    title={title}
  >
    <ExtractionForm
      data={data}
      form={form}
      i18n={i18n}
      inModal
      onChange={onChange}
    />
    <ApplyCancelButtons
      applying={isApplying}
      applyLabel={applyLabelToUse}
      cancelLabel={cancelLabelToUse}
      isCancelEnabled={not isApplying}
      isSubmitEnabled={isSubmitEnabled}
      onApply={onApply}
      onCancel={onCancel}
    />
  </Modal>

CreateUpdateFormModal.propTypes =
  applyLabel: PropTypes.string
  cancelLabel: PropTypes.string
  className: PropTypes.string
  data: PropTypes.object.isRequired
  form: extractionFormShape.isRequired
  i18n: PropTypes.func.isRequired
  isApplying: PropTypes.bool.isRequired
  isOpen: PropTypes.bool.isRequired
  isSubmitEnabled: PropTypes.bool.isRequired
  mode: PropTypes.oneOf(['create', 'edit']).isRequired
  onCancel: PropTypes.func.isRequired
  onCreate: PropTypes.func.isRequired
  onChange: PropTypes.func.isRequired
  onUpdate: PropTypes.func.isRequired
  title: PropTypes.string.isRequired

CreateUpdateFormModal.defaultProps =
  className: ''
  modalSize: 'medium'

module.exports = CreateUpdateFormModal
