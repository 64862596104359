var BottomPaneToggleableView, RecommendationsMigrationView, mediator, recommendationsMigrationTemplate,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

BottomPaneToggleableView = require('views/bottom_pane_toggleable_view');

recommendationsMigrationTemplate = require('views/templates/evidence_syntheses/recommendations_migration');

mediator = require('mediator');

module.exports = RecommendationsMigrationView = (function(_super) {
  __extends(RecommendationsMigrationView, _super);

  function RecommendationsMigrationView() {
    this.migrationDone = __bind(this.migrationDone, this);
    return RecommendationsMigrationView.__super__.constructor.apply(this, arguments);
  }

  RecommendationsMigrationView.prototype.paneContentTemplate = recommendationsMigrationTemplate;

  RecommendationsMigrationView.prototype.paneTitle = $.t('es:recommendations.data_migration.not_migrated_data');

  RecommendationsMigrationView.prototype.initialize = function() {
    RecommendationsMigrationView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.done', this.migrationDone);
    return this.questionModel = this.options.questionModel;
  };

  RecommendationsMigrationView.prototype.migrationDone = function() {
    return mediator.dialogs.confirm({
      yesClass: 'danger',
      message: $.t('es:recommendations.data_migration.confirm_migration_done')
    }, (function(_this) {
      return function(confirmed) {
        if (!confirmed) {
          return;
        }
        _this.questionModel.set('migrationDone', true);
        return _this.questionModel.save().then(function() {
          return _this._hide();
        }).otherwise(mediator.dialogs.fatalError);
      };
    })(this));
  };

  return RecommendationsMigrationView;

})(BottomPaneToggleableView);
