var ReactComponent, ReactToolbarView, ReferencesActions, ReferencesGrid, ReferencesToolbar, ReferencesView, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

ReferencesActions = require('actions/references_actions');

ReferencesGrid = require('components/references/references');

ReferencesToolbar = require('components/references/references_toolbar');

View = require('base/views/view');

module.exports = ReferencesView = (function(_super) {
  __extends(ReferencesView, _super);

  function ReferencesView() {
    return ReferencesView.__super__.constructor.apply(this, arguments);
  }

  ReferencesView.prototype.container = '#page-container';

  ReferencesView.prototype.autoRender = true;

  ReferencesView.prototype.initialize = function() {
    ReferencesView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: ReferencesToolbar,
      props: {
        mode: 'references'
      }
    }));
    return this.enable(ReactComponent);
  };

  ReferencesView.prototype.afterRender = function() {
    ReferencesView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(ReferencesGrid, ReferencesActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id,
        opts: {
          filter: '_view',
          view: 'references/references'
        }
      }
    });
  };

  ReferencesView.prototype.dispose = function() {
    this.unmountComponent();
    return ReferencesView.__super__.dispose.apply(this, arguments);
  };

  return ReferencesView;

})(View);
