var DragDropTypes, QuestionGroupsActions, QuestionsActions, QuestionsListComponentActions, QuestionsListComponentStore, ReactDnDActions, alt, convertListToOrderedMapByElementId, groupQuestionsByGroup, immutable, mediator, pluck, sortByOrderNumber, sortingInputDataQuestionsGroupsPath, sortingInputDataQuestionsPath, _ref;

alt = require('alt');

mediator = require('mediator');

immutable = require('stores/utils/immutable');

ReactDnDActions = require('actions/react_dnd_actions');

QuestionsActions = require('actions/questions_actions');

QuestionGroupsActions = require('actions/question_groups_actions');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

DragDropTypes = require('components/evidence_syntheses/questions_list/drag_drop_types');

_ref = require('lib/questions_helper'), sortByOrderNumber = _ref.sortByOrderNumber, convertListToOrderedMapByElementId = _ref.convertListToOrderedMapByElementId, groupQuestionsByGroup = _ref.groupQuestionsByGroup;

pluck = require('lib/immutable_utils').pluck;

sortingInputDataQuestionsPath = ['meta', 'sortingInputData', 'questions'];

sortingInputDataQuestionsGroupsPath = ['meta', 'sortingInputData', 'questionGroups'];

QuestionsListComponentStore = (function() {
  function QuestionsListComponentStore() {
    this.state = Immutable.Map({
      meta: Immutable.Map({
        editingGroup: Immutable.Map(),
        dndData: Immutable.Map(),
        addingQuestion: false,
        sortingInputData: Immutable.Map({
          questions: Immutable.OrderedMap(),
          questionGroups: Immutable.Map()
        })
      }),
      groupedQuestions: Immutable.OrderedMap()
    });
    this.exportPublicMethods({
      getCurrentDraggedOverGroupId: this.getCurrentDraggedOverGroupId,
      getDraggedFromGroupId: this.getDraggedFromGroupId,
      getDraggingQuestion: this.getDraggingQuestion,
      getExpandedQuestions: this.getExpandedQuestions,
      getGroupEditState: this.getGroupEditState,
      getGroupQuestions: this.getGroupQuestions,
      getQuestionsByGroup: this.getQuestionsByGroup,
      getQuestionsCollectionType: this.getQuestionsCollectionType,
      groupQuestionsByGroup: groupQuestionsByGroup,
      hasEverSorted: this.hasEverSorted,
      isFetchingData: this.isFetchingData,
      shouldSort: this.shouldSort
    });
    this.bindActions(QuestionsListComponentActions);
    this.bindActions(ReactDnDActions);
  }

  QuestionsListComponentStore.prototype.onFetch = function(questionsCollectionType) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'questionsCollectionType'], questionsCollectionType);
      return state.setIn(['meta', 'fetchingData'], true);
    }));
  };

  QuestionsListComponentStore.prototype.onFetchSuccess = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingData'], false));
  };

  QuestionsListComponentStore.prototype.onFetchError = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingData'], false));
  };

  QuestionsListComponentStore.prototype.onSortQuestions = function(_arg) {
    var questionGroups, questions;
    questions = _arg.questions, questionGroups = _arg.questionGroups;
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(sortingInputDataQuestionsPath, questions);
      state.setIn(sortingInputDataQuestionsGroupsPath, questionGroups);
      state.set('groupedQuestions', groupQuestionsByGroup(questions, questionGroups));
      return state.setIn(['meta', 'hasEverSorted'], true);
    }));
  };

  QuestionsListComponentStore.prototype.onUpdateTextualData = function(_arg) {
    var questionGroups, questions, updatedState;
    questions = _arg.questions, questionGroups = _arg.questionGroups;
    updatedState = this.state.withMutations(function(state) {
      var groupedQuestions, withFilteredGroups;
      if (!state.getIn(sortingInputDataQuestionsPath).equals(questions)) {
        state.setIn(sortingInputDataQuestionsPath, questions);
        state.set('groupedQuestions', state.get('groupedQuestions').map(function(qGroup) {
          return qGroup.set('questions', qGroup.get('questions').map(function(q) {
            return q.merge(questions.get(q.get('_id')));
          }));
        }));
      }
      if (!state.getIn(sortingInputDataQuestionsGroupsPath).equals(questionGroups)) {
        groupedQuestions = state.get('groupedQuestions');
        withFilteredGroups = questionGroups.update('groups', function(groups) {
          return groups.filter(function(qg) {
            return groupedQuestions.has(qg.get('_id'));
          });
        });
        if (withFilteredGroups.get('groups').isEmpty()) {
          return;
        }
        state.setIn(sortingInputDataQuestionsGroupsPath, withFilteredGroups);
        state.update('groupedQuestions', function(qGroups) {
          return qGroups.mergeDeep(convertListToOrderedMapByElementId(withFilteredGroups.get('groups')));
        });
      }
      return state;
    });
    if (!this.state.equals(updatedState)) {
      return this.setState(updatedState);
    }
  };

  QuestionsListComponentStore.prototype.onToggleGroupEdit = function(groupId) {
    var newEditingGroupValue;
    newEditingGroupValue = this.isGroupBeingEdited(groupId) ? Immutable.Map() : Immutable.Map({
      groupId: groupId
    });
    return this.setState(this.state.setIn(['meta', 'editingGroup'], newEditingGroupValue));
  };

  QuestionsListComponentStore.prototype.onUpdateGroupCaption = function(groupId) {
    if (this.isGroupBeingEdited(groupId)) {
      return this.setState(this.state.setIn(['meta', 'editingGroup', 'isBeingSaved'], true));
    }
  };

  QuestionsListComponentStore.prototype.onUpdateGroupCaptionSuccess = function(groupId) {
    if (this.isGroupBeingEdited(groupId)) {
      return this.setState(this.state.setIn(['meta', 'editingGroup'], Immutable.Map()));
    }
  };

  QuestionsListComponentStore.prototype.onUpdateGroupCaptionError = function(groupId) {
    if (this.isGroupBeingEdited(groupId)) {
      return this.setState(this.state.setIn(['meta', 'editingGroup', 'isBeingSaved'], false));
    }
  };

  QuestionsListComponentStore.prototype.onDeleteGroup = function(groupId) {
    if (this.isGroupBeingEdited(groupId)) {
      return this.setState(this.state.setIn(['meta', 'editingGroup', 'isBeingDeleted'], true));
    }
  };

  QuestionsListComponentStore.prototype.onDeleteGroupSuccess = function(groupId) {
    if (this.isGroupBeingEdited(groupId)) {
      return this.setState(this.state.setIn(['meta', 'editingGroup'], Immutable.Map()));
    }
  };

  QuestionsListComponentStore.prototype.onDeleteGroupError = function(_arg) {
    var groupId;
    groupId = _arg.groupId;
    if (this.isGroupBeingEdited(groupId)) {
      return this.setState(this.state.setIn(['meta', 'editingGroup', 'isBeingDeleted'], false));
    }
  };

  QuestionsListComponentStore.prototype.onBeginDrag = function(dragData) {
    var draggingQuestion, groupId, qId, type;
    if (dragData == null) {
      dragData = {};
    }
    type = dragData.type, qId = dragData.qId, groupId = dragData.groupId;
    if (type !== DragDropTypes.QUESTION) {
      return;
    }
    draggingQuestion = this.state.getIn(['groupedQuestions', groupId, 'questions', qId]);
    return this.setState(this.state.setIn(['meta', 'dndData'], Immutable.Map({
      question: draggingQuestion,
      currentGroupId: groupId,
      initialGroupId: groupId
    })));
  };

  QuestionsListComponentStore.prototype.onEndDrag = function(dragData) {
    var type;
    if (dragData == null) {
      dragData = {};
    }
    type = dragData.type;
    if (type !== DragDropTypes.QUESTION) {
      return;
    }
    return this.setState(this.state.updateIn(['meta', 'dndData'], function(data) {
      return data.clear();
    }));
  };

  QuestionsListComponentStore.prototype.onHover = function(dragData) {
    var currentGroupId, draggingQuestion, draggingQuestionId, hoveredGroupId, hoveredQuestionId, type;
    type = dragData.type, hoveredGroupId = dragData.hoveredGroupId, hoveredQuestionId = dragData.hoveredQuestionId, draggingQuestionId = dragData.draggingQuestionId;
    if (type !== DragDropTypes.QUESTION) {
      return;
    }
    currentGroupId = this.getCurrentDraggedOverGroupId();
    draggingQuestion = this.getDraggingQuestion();
    return this.setState(this.state.withMutations(function(state) {
      if (hoveredGroupId !== currentGroupId) {
        state.updateIn(['groupedQuestions', currentGroupId, 'questions'], function(questions) {
          return questions["delete"](draggingQuestionId);
        });
        state.setIn(['meta', 'dndData', 'currentGroupId'], hoveredGroupId);
      }
      if (hoveredQuestionId != null) {
        return state.updateIn(['groupedQuestions', hoveredGroupId, 'questions'], function(questions) {
          var afterDragged, afterHovered, afterHoveredQuestionOrderNumber, beforeDragged, beforeHovered, beforeHoveredQuestionOrderNumber, draggingQuestionNewOrderNumber, draggingQuestionWithUpdatedOrderNumber, hoveredQuestion, hoveredQuestionOrderNumber;
          beforeDragged = questions.takeUntil(function(q) {
            return q.get('_id') === draggingQuestionId;
          });
          afterDragged = questions.skip(beforeDragged.size + 1);
          hoveredQuestion = questions.get(hoveredQuestionId);
          hoveredQuestionOrderNumber = hoveredQuestion.get('orderNumber', questions.size);
          if (beforeDragged.has(hoveredQuestionId)) {
            beforeHovered = beforeDragged.takeUntil(function(q) {
              return q.get('_id') === hoveredQuestionId;
            });
            afterHovered = beforeDragged.skip(beforeHovered.size);
            beforeHoveredQuestionOrderNumber = beforeHovered.isEmpty() ? 0 : beforeHovered.last().get('orderNumber', hoveredQuestionOrderNumber - 1);
            draggingQuestionNewOrderNumber = (beforeHoveredQuestionOrderNumber + hoveredQuestionOrderNumber) / 2;
            draggingQuestionWithUpdatedOrderNumber = draggingQuestion.set('orderNumber', draggingQuestionNewOrderNumber);
            return beforeHovered.set(draggingQuestionId, draggingQuestionWithUpdatedOrderNumber).concat(afterHovered).concat(afterDragged);
          } else {
            afterHovered = afterDragged.skipUntil(function(q) {
              return q.get('_id') === hoveredQuestionId;
            }).rest();
            beforeHovered = afterDragged.skipLast(afterHovered.size);
            afterHoveredQuestionOrderNumber = afterHovered.isEmpty() ? hoveredQuestionOrderNumber + 1 : afterHovered.first().get('orderNumber', hoveredQuestionOrderNumber + 1);
            draggingQuestionNewOrderNumber = (hoveredQuestionOrderNumber + afterHoveredQuestionOrderNumber) / 2;
            draggingQuestionWithUpdatedOrderNumber = draggingQuestion.set('orderNumber', draggingQuestionNewOrderNumber);
            return beforeDragged.concat(beforeHovered).set(draggingQuestionId, draggingQuestionWithUpdatedOrderNumber).concat(afterHovered);
          }
        });
      } else {
        return state.setIn(['groupedQuestions', hoveredGroupId, 'questions', draggingQuestionId], draggingQuestion);
      }
    }));
  };

  QuestionsListComponentStore.prototype.isGroupBeingEdited = function(groupId) {
    return this.state.getIn(['meta', 'editingGroup', 'groupId']) === groupId;
  };

  QuestionsListComponentStore.prototype.getQuestionsByGroup = function(_arg) {
    var groupedQuestions, withEmptyGroups;
    withEmptyGroups = (_arg != null ? _arg : {}).withEmptyGroups;
    if (withEmptyGroups == null) {
      withEmptyGroups = true;
    }
    groupedQuestions = this.state.get('groupedQuestions');
    if (withEmptyGroups) {
      return groupedQuestions;
    }
    return groupedQuestions.filterNot(function(group) {
      return group.get('questions').isEmpty();
    });
  };

  QuestionsListComponentStore.prototype.getQuestionsCollectionType = function() {
    return this.state.getIn(['meta', 'questionsCollectionType']);
  };

  QuestionsListComponentStore.prototype.getGroupEditState = function() {
    return this.state.getIn(['meta', 'editingGroup']);
  };

  QuestionsListComponentStore.prototype.getGroupQuestions = function(groupId) {
    return this.state.getIn(['groupedQuestions', groupId, 'questions']);
  };

  QuestionsListComponentStore.prototype.getCurrentDraggedOverGroupId = function() {
    return this.state.getIn(['meta', 'dndData', 'currentGroupId']);
  };

  QuestionsListComponentStore.prototype.getDraggedFromGroupId = function() {
    return this.state.getIn(['meta', 'dndData', 'initialGroupId']);
  };

  QuestionsListComponentStore.prototype.getDraggingQuestion = function() {
    return this.state.getIn(['meta', 'dndData', 'question']);
  };

  QuestionsListComponentStore.prototype.shouldSort = function(_arg) {
    var questionGroups, questionIds, questions, sameGroups, sameMapping, sameQuestionsOrder, sortedGroups, sortedMapping, sortedQuestionGroups, sortedQuestions, sortedQuestionsIds, sortedQuestionsOrder;
    questions = _arg.questions, questionGroups = _arg.questionGroups;
    if (this.isFetchingData()) {
      return false;
    }
    sortedQuestions = this.state.getIn(sortingInputDataQuestionsPath);
    sortedQuestionsIds = pluck(sortedQuestions, '_id');
    questionIds = pluck(questions, '_id');
    if (!sortedQuestionsIds.equals(questionIds)) {
      return true;
    }
    sortedQuestionGroups = this.state.getIn(sortingInputDataQuestionsGroupsPath);
    sortedGroups = pluck(sortedQuestionGroups.get('groups', Immutable.List()), '_id');
    sameGroups = sortedGroups.equals(pluck(questionGroups.get('groups', Immutable.List()), '_id'));
    if (!sameGroups) {
      return true;
    }
    sortedMapping = sortedQuestionGroups.get('mapping', Immutable.Map());
    sameMapping = sortedMapping.equals(questionGroups.get('mapping', Immutable.Map()));
    if (!sameMapping) {
      return true;
    }
    sortedQuestionsOrder = sortedQuestionGroups.get('questionsOrdering', Immutable.Map());
    sameQuestionsOrder = sortedQuestionsOrder.equals(questionGroups.get('questionsOrdering'));
    if (!sameQuestionsOrder) {
      return true;
    }
    return false;
  };

  QuestionsListComponentStore.prototype.isFetchingData = function() {
    return this.state.getIn(['meta', 'fetchingData']);
  };

  QuestionsListComponentStore.prototype.hasEverSorted = function() {
    return this.state.getIn(['meta', 'hasEverSorted']);
  };

  QuestionsListComponentStore.prototype.getExpandedQuestions = function() {
    return this.state.getIn(['meta', 'expandedQuestions'], Immutable.Map());
  };

  return QuestionsListComponentStore;

})();

module.exports = alt.createStore(immutable(QuestionsListComponentStore), 'QuestionsListComponentStore');
