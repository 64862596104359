IconButton = require 'components/common/icon_button'
Tooltip = require 'components/common/tooltip'
DropdownMenu = require 'components/common/dropdown_menu'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
mediator = require 'mediator'
{ CHAPTER_SECTION_DND_TYPE, getSettingsOptions, getSettingsOrder } =
  require 'lib/document_sections_v2_helper'
{
  dropSpec, dropCollect
} = require 'components/document_sections/v2/chapter_header_dnd_spec'
Translation = require 'components/mixins/translation'
ChapterManagementMixin = require 'components/mixins/chapter_management_mixin'

ChapterHeader = createReactClass
  displayName: 'ChapterHeader'

  propTypes:
    chapterId: PropTypes.string.isRequired
    connectDropTarget: PropTypes.func.isRequired
    isExpanded: PropTypes.bool.isRequired
    isFromAcoem: PropTypes.bool
    isOver: PropTypes.bool.isRequired
    hasReferences: PropTypes.bool
    markedAsImportant: PropTypes.bool.isRequired
    onAddSection: PropTypes.func.isRequired
    onInsertMdaTable: PropTypes.func.isRequired
    onInsertRecommendation: PropTypes.func.isRequired
    onInsertReferences: PropTypes.func.isRequired
    onMark: PropTypes.func.isRequired
    title: PropTypes.string.isRequired
    view: PropTypes.string.isRequired

  getDefaultProps: ->
    hasReferences: false

  mixins: [Translation('docsec:management'), ChapterManagementMixin]

  handleSettingSelect: (selectedSetting) ->
    switch selectedSetting
      when 'add_new_text_field' then @props.onAddSection()
      when 'insert_mda_table' then @props.onInsertMdaTable()
      when 'insert_recommendation' then @props.onInsertRecommendation()
      when 'mark_for_panel_review' then @props.onMark()
      when 'insert_subchapter' then @props.handleInsertChapter()
      when 'insert_references' then @props.onInsertReferences()
      when 'clear_highlights'
        DocumentSectionsV2Actions.setBatchEditorOptions
          chapterId: @props.chapterId
          type: 'clearHighlights'
      when 'accept_all_changes'
        DocumentSectionsV2Actions.setBatchEditorOptions
          chapterId: @props.chapterId,
          accept: true
          type: 'suggestions'


  openReferencesModal: (e) ->
    { chapterId, onInsertReferences } = @props
    e.preventDefault()
    e.stopPropagation()
    onInsertReferences chapterId

  render: ->
    {
      connectDropTarget
      isExpanded
      isFromAcoem
      isOver
      hasReferences
      markedAsImportant
      title
      view
    } = @props

    connectDropTarget(
      <div className={classNames('chapter-header', { expanded: isExpanded, over: isOver })}>
        <div className="chapter-header-left">
          <div className="flex-grow flex flex-row">
            <h2 className="chapter-header-text">
              {title}
            </h2>
            {hasReferences && mediator.services.switches.isServerSwitchOn('evolentFeatures') and
              <IconButton
                iconName="reference"
                className="add-reference-button"
                onClick={@openReferencesModal}
              />
            }
            {isFromAcoem and <div className="ml-5 chapter-from-acoem">
              {@i18n 'chapter_from_acoem'}
            </div>}
          </div>
          {markedAsImportant and
            <Tooltip>
              <div
                title={@i18n 'marked_by_editor_for_panel_review'}
                className="chapter-mark-for-panel-review"
              >
                {@i18n 'marked_for_review'}
              </div>
            </Tooltip>
          }
        </div>
        {@showChapterManagement() and <DropdownMenu
          opener={(
            <IconButton iconName="more" className="settings-toggle" />
          )}
          options={getSettingsOptions getSettingsOrder('chapter-header', view)}
          onSelect={@handleSettingSelect}
        />}
      </div>
    )

DropTargeted = ReactDnD.DropTarget(
  CHAPTER_SECTION_DND_TYPE, dropSpec, dropCollect
) ChapterHeader

module.exports = DropTargeted
