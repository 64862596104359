var constants;

constants = {
  panelVoiceMailAtachmentsLimit: 10 * 1000 * 1000,
  referenceAttachmentLimit: 50 * 1000 * 1000,
  editorSupportedImageTypes: ['image/jpeg', 'image/png'],
  facebook_url: "https://www.facebook.com/GRADEproGDT",
  twitter_url: "https://twitter.com/GRADEproGDT",
  license_url: "https://gradepro.org/licences/",
  example_da_url: "https://gdt.gradepro.org/decision-aids/#/p_l_helena_czerwinska_evidenceprime_com_0_16f7dd8b-566a-43c8-a77e-f076d93caf22/decision_aid_a163095b-8946-409a-aae3-0f97148ba30c/1566897331581",
  contact_email: "sales@gradepro.org",
  support_email: 'support@evidenceprime.com',
  etdAttachmentSizeLimit: 500 * 1000,
  docSectionsAttachmentSizeLimit: 500 * 1000,
  timesToShowOnboardingProposal: 3,
  prices: {
    monthly: 29,
    yearly: 300
  },
  forbiddenFileExtensions: ['ade', 'adp', 'bat', 'chm', 'cmd', 'com', 'cpl', 'exe', 'hta', 'ins', 'isp', 'jar', 'js', 'jse', 'lib', 'lnk', 'mde', 'msc', 'msi', 'msp', 'mst', 'nsh', 'pif', 'scr', 'sct', 'shb', 'sys', 'vb', 'vbe', 'vbs', 'vxd', 'wsc', 'wsf', 'wsh'],
  defaultLimits: {
    personal: {
      teamMembers: 3,
      teamAdmins: 3,
      questions: 12
    },
    personalEC: {
      teamMembers: 12,
      teamAdmins: 12,
      questions: 24
    },
    org: {
      teamMembers: -1,
      teamAdmins: -1,
      questions: -1
    }
  }
};

module.exports = constants;
