var DraftRenderer, EditorDecorators, EditorRenderMaps;

DraftRenderer = require('lib/draft_utils').DraftContentRenderer;

EditorDecorators = require('lib/editor_decorators');

EditorRenderMaps = require('lib/editor_render_maps');

module.exports = {
  renderDraftJsContentToHTML: function(data, attachments, referencesMap) {
    var draftRenderer, renderOptions, rendered;
    if (attachments == null) {
      attachments = {};
    }
    if (referencesMap == null) {
      referencesMap = {};
    }
    renderOptions = {
      customDecorators: EditorDecorators.referenceDecorator,
      customEntityRenderers: EditorRenderMaps,
      attachments: Immutable.fromJS(attachments)
    };
    draftRenderer = new DraftRenderer(renderOptions);
    rendered = draftRenderer.rawContentToHTML(data);
    draftRenderer.dispose();
    return _.chain(referencesMap).pairs().reduce(function(content, _arg) {
      var gdtReferenceId, jsonldReferenceId;
      gdtReferenceId = _arg[0], jsonldReferenceId = _arg[1];
      return content.replace(RegExp("data-refid=\\\"" + gdtReferenceId, "g"), "data-refid=\"" + jsonldReferenceId);
    }, rendered).value();
  }
};
