{ useState, useRef } = React
{ Button } = ReactComponents
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'
ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
ACPVisualGuidelinesStore = require 'stores/acp_visual_guidelines_store'
ConnectStore = require 'components/enhancers/connect_store'
DbHelper = require 'base/lib/db_helper'
ExportEmbeddableCodeDialog = require 'components/common/export_embeddable_code_dialog'
ExportModal = require 'components/dissemination/acp_visual_guidelines/export_modal'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'

storeConnector =
  ACPVisualGuidelinesStore: (Store) ->
    isExportDialogOpen: Store.isExportDialogOpen()

VisualGuidelinesToolbar = ({ itemId, submoduleScreen, isExportDialogOpen }) ->

  i18n = useI18n()

  [iframeCode, setIframeCode] = useState()
  createVisualization = useCoffeeCallback [], -> ACPVisualGuidelinesActions.openCreateOrEditDialog()

  onExport = useCoffeeCallback [itemId], (options) ->

    { outputFormat } = options

    VIService = mediator.services.viService
    switch (outputFormat)
      when 'pdf'
        VIService.exportVIGuidelinesToPdf(itemId)
        .then (ACPVisualGuidelinesActions.closeExportDialog)
      when 'external_link'
        VIService.exportVIGuidelines(itemId)
        .then (ACPVisualGuidelinesActions.closeExportDialog)
      when 'external_embeddable_code'
        VIService.exportVi(itemId).then ({ _id: viDocId }) ->
          setIframeCode VIService.getEmbeddableHTML DbHelper.getVIGuidelinesUrl viDocId
        .then (ACPVisualGuidelinesActions.closeExportDialog)

  <div className="flex flex-row mx-10">
    <h2 className="flex-grow">
      {i18n 'nav.dissemination_submodules.acp_visual_guidelines'}
    </h2>

    {switch submoduleScreen
      when 'details'
        <div>
          <div className="flex flex-row items-center">
            <IconButton
              className="buttons__export-table"
              iconName="export"
              label={i18n('actions.export_vi')}
              labelPosition="right"
              onClick={ACPVisualGuidelinesActions.openExportDialog}
            />
          </div>
          <ExportModal
            isOpen={isExportDialogOpen}
            onClose={ACPVisualGuidelinesActions.closeExportDialog}
            onExport={onExport}
          />
          <ExportEmbeddableCodeDialog
            isOpen={not _.isEmpty(iframeCode)}
            code={iframeCode}
            onClose={() -> setIframeCode(null)}
          />
        </div>
      when 'list'
        <div>
          <div className="flex flex-row items-center">
            <Button
              className="btn btn-apply"
              label={i18n 'actions.create_visualization'}
              onClick={createVisualization}
            />
          </div>
        </div>
    }
  </div>

VisualGuidelinesToolbar.propTypes =
  itemId: PropTypes.string
  isExportDialogOpen: PropTypes.bool.isRequired
  submoduleScreen: PropTypes.oneOf(['details', 'list']).isRequired

VisualGuidelinesToolbar.defaultProps =
  itemId: null
  submoduleScreen: 'list'

module.exports = ConnectStore VisualGuidelinesToolbar, [ACPVisualGuidelinesStore], storeConnector
