{ string, func, instanceOf } = PropTypes
Translation = require "components/mixins/translation"
PanelVoiceActions = require 'actions/panel_voice_actions'
PanelVoiceStore = require 'stores/panel_voice_store'
MembersStore = require 'stores/members_store'
ConnectStore = require 'components/enhancers/connect_store'
Modal = require 'components/common/modal'
AddManualResultsForm = require '../forms/add_manual_results_form'

storeConnector =
  MembersStore: (Store) ->
    panelMembers: Store.getPanelMembers()
  PanelVoiceStore: (Store, props) ->
    votedMemberIds: Store.getVotedMemberIds(props.questionId)
    sentMemberIds: Store.getSentMemberIds(props.questionId)
    panelVoicePreferences: Store.getPanelVoicePreferences()

AddManualResultsModal = createReactClass
  displayName: "AddManualResultsModal"

  mixins: [Translation('voting:voting')]

  propTypes:
    votedMemberIds: instanceOf(Immutable.List).isRequired
    sentMemberIds: instanceOf(Immutable.List).isRequired
    etdId: string.isRequired
    questionId: string.isRequired

  render: ->
    {
      panelMembers
      votedMemberIds
      sentMemberIds
      etdId
      questionId
    } = @props

    notVotedMembers = sentMemberIds.filterNot (mId) -> votedMemberIds.contains mId

    <Modal
      className="reminder-modal"
      isOpen
      closeButton
      onClose={PanelVoiceActions.hideAddManualResultsModal}
    >
      <h2 className="text-center">{@i18n 'modals.members_who_didnt_fill_form'}</h2>
      <AddManualResultsForm
        members={panelMembers.filter (member) -> notVotedMembers.contains member.get('_id')}
        questionId={questionId}
        etdId={etdId}
      />
    </Modal>

module.exports = ConnectStore AddManualResultsModal, [MembersStore, PanelVoiceStore], storeConnector
