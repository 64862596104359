var BoxView, Shortcuts, View, boxTemplate, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

Shortcuts = require('base/lib/traits/shortcuts');

boxTemplate = require('base/views/templates/box');

mediator = require('mediator');

module.exports = BoxView = (function(_super) {
  __extends(BoxView, _super);

  function BoxView() {
    return BoxView.__super__.constructor.apply(this, arguments);
  }

  BoxView.prototype.shortcuts = {
    'esc': 'hide',
    'enter': 'hide'
  };

  BoxView.prototype.initialize = function() {
    BoxView.__super__.initialize.apply(this, arguments);
    this.enable(Shortcuts);
    return this.delegate('click', function(e) {
      return e.stopPropagation();
    });
  };

  BoxView.prototype.show = function(offset) {
    return mediator.setFocus(this, (function(_this) {
      return function() {
        var box, top, windowHeight, windowWidth;
        _this.render();
        box = boxTemplate();
        _this.$box = $(box);
        if (_this.className) {
          _this.$box.addClass("" + _this.className + "-container");
        }
        _this.$box.find('.content').html(_this.$el);
        $('body').append(_this.$box);
        _this.$box.css({
          display: 'block'
        });
        windowHeight = $(window).height();
        windowWidth = $(window).width();
        top = Math.min(offset.top, windowHeight - _this.$el.height());
        return _this.$box.css({
          left: Math.min(offset.left, windowWidth - _this.$el.width() - 30),
          top: top,
          'max-height': $(window).height() - top
        });
      };
    })(this));
  };

  BoxView.prototype.hide = function(e) {
    if (e != null) {
      e.preventDefault();
    }
    if (!this.options.parentView || this.options.parentView.disposed) {
      return this.dispose();
    } else {
      return mediator.setFocus(this.options.parentView, (function(_this) {
        return function() {
          return _this.dispose();
        };
      })(this));
    }
  };

  BoxView.prototype.onFocusLost = function() {
    return this.hide();
  };

  BoxView.prototype.dispose = function() {
    this.$box.remove();
    return BoxView.__super__.dispose.apply(this, arguments);
  };

  return BoxView;

})(View);
