var AddImplmentationConsiderationsToClinicianPresentation, Migration, W, mediator, migrationName, updateTemplateDataAndDefinition,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'add_implementation_considerations_to_clinicians_presentation';

updateTemplateDataAndDefinition = function(templateDef, templateData) {
  var presentationSections;
  if (_.isEmpty(templateDef.presentations) || _.isEmpty(templateData.presentations)) {
    return {
      templateDef: templateDef,
      templateData: templateData
    };
  }
  templateDef.presentations = _.map(templateDef.presentations, function(presentationType) {
    var hasIC;
    hasIC = presentationType.sections.find(function(section) {
      return section.id === "implementationConsiderations";
    });
    if (hasIC || presentationType !== 'clinicians') {
      return presentationType;
    }
    presentationType.sections = presentationType.sections.concat({
      id: "implementationConsiderations",
      checked: mediator.services.switches.isServerSwitchOn('minsalFeatures')
    });
    return presentationType;
  });
  presentationSections = _(templateData.presentations.sections).reduce(function(acc, type, key) {
    var rrIdx, _ref;
    rrIdx = (_ref = type.sectionsOrder) != null ? _ref.indexOf('implementationConsiderations') : void 0;
    if (rrIdx === -1) {
      type.sections.implementationConsiderations = {
        name: "Implementation considerations"
      };
      type.sectionsOrder = type.sectionsOrder.concat("implementationConsiderations");
    }
    acc[key] = type;
    return acc;
  }, {});
  templateData.presentations.sections = presentationSections;
  return {
    templateDef: templateDef,
    templateData: templateData
  };
};

module.exports = AddImplmentationConsiderationsToClinicianPresentation = (function(_super) {
  __extends(AddImplmentationConsiderationsToClinicianPresentation, _super);

  function AddImplmentationConsiderationsToClinicianPresentation() {
    AddImplmentationConsiderationsToClinicianPresentation.__super__.constructor.call(this, false);
  }

  AddImplmentationConsiderationsToClinicianPresentation.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    AddImplmentationConsiderationsToClinicianPresentation.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds'));
    }, []);
    docIds = docIds.concat(['etd-tx-template', 'etd-dx-template']);
    return adapter.fetch(projectId, _.unique(docIds)).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(docs) {
      var updatedDocs;
      if (_.isEmpty(docs)) {
        return W.resolve();
      }
      updatedDocs = _.map(docs, function(doc) {
        var data, def, templateData, templateDef, _ref;
        def = doc.templateDef;
        data = doc.templateData;
        _ref = updateTemplateDataAndDefinition(def, data), templateDef = _ref.templateDef, templateData = _ref.templateData;
        doc.templateDef = templateDef;
        doc.templateData = templateData;
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return AddImplmentationConsiderationsToClinicianPresentation;

})(Migration);
