_.namespace(module, function(require) {
  var AdolopmentDataDoc, callMethod, mediator;
  AdolopmentDataDoc = require('lib/db_docs/adolopment_data_doc');
  mediator = require('mediator');
  callMethod = require('base/lib/utils').callMethod;
  return {
    fetch: function(projectId) {
      if (projectId == null) {
        projectId = mediator.project.id;
      }
      return AdolopmentDataDoc.at(projectId).fetch()["catch"](function(err) {
        if (err.status === 404) {
          return AdolopmentDataDoc.at(projectId).create();
        } else {
          throw err;
        }
      });
    },
    toggleAdolopmentForSection: function(etdId, block, sectionId) {
      return AdolopmentDataDoc.at(mediator.project.id).updateAsInstance(callMethod('toggleAdolopmentForSection', etdId, block, sectionId));
    },
    toggleAdolopmentForSections: function(etdId, block, sectionIds) {
      return AdolopmentDataDoc.at(mediator.project.id).updateAsInstance(callMethod('toggleAdolopmentForSections', etdId, block, sectionIds));
    }
  };
});
