var getResultToPushFromCurrentGroup, mergeHistoryEntries, sameFields;

getResultToPushFromCurrentGroup = function(currentGroup) {
  var resultToPush;
  resultToPush = currentGroup[0];
  resultToPush.rev = currentGroup[currentGroup.length - 1].rev;
  return resultToPush;
};

sameFields = function(prevEntry, entry) {
  var docId, duration, prevDocId, prevRevAuthor, prevTimestampMoment, revAuthor, sameDayAndHour, sameDocId, sameRevAuthor, timestampMoment;
  docId = entry.docId, revAuthor = entry.revAuthor, timestampMoment = entry.timestampMoment;
  prevDocId = prevEntry.docId, prevRevAuthor = prevEntry.revAuthor, prevTimestampMoment = prevEntry.timestampMoment;
  sameDocId = docId === prevDocId;
  sameRevAuthor = revAuthor === prevRevAuthor;
  sameDayAndHour = timestampMoment && prevTimestampMoment ? (duration = moment.duration(prevTimestampMoment.diff(timestampMoment)), duration.asHours() < 1) : false;
  return sameRevAuthor && sameDayAndHour && sameDocId;
};

mergeHistoryEntries = function(history) {
  var currentGroup, result, _ref;
  _ref = _.reduce(history, function(_arg, entry) {
    var currentGroup, docId, prevEntry, result, revAuthor, somethingNotSet, timestamp, _ref;
    currentGroup = _arg.currentGroup, result = _arg.result;
    docId = entry.docId, revAuthor = entry.revAuthor, timestamp = entry.timestamp;
    if (timestamp != null) {
      entry.timestampMoment = moment(timestamp);
    }
    somethingNotSet = !docId || !revAuthor || !timestamp;
    prevEntry = (_ref = _.first(currentGroup)) != null ? _ref : {};
    if ((somethingNotSet || !sameFields(prevEntry, entry)) && currentGroup.length > 0) {
      result.push(getResultToPushFromCurrentGroup(currentGroup));
      currentGroup = [];
    }
    currentGroup.push(entry);
    return {
      currentGroup: currentGroup,
      result: result
    };
  }, {
    currentGroup: [],
    result: []
  }), currentGroup = _ref.currentGroup, result = _ref.result;
  if (currentGroup.length > 0) {
    result.push(getResultToPushFromCurrentGroup(currentGroup));
  }
  return result;
};

module.exports = {
  mergeHistoryEntries: mergeHistoryEntries
};
