ConnectStore = require 'components/enhancers/connect_store'
LicenceTooltipText = require 'components/common/licence_tooltip_text'
LimitCounter = require 'components/common/limit_counter'
MembersStore = require 'stores/members_store'
Translation = require 'components/mixins/translation'
withLimits = require 'components/enhancers/with_limits'
{ contact_email } = require 'lib/constants'
{ filterAdminsAndResearchers } = require 'lib/members_helper'

storeConnector =
  MembersStore: (Store) ->
    isFetching: Store.isFetching()
    members: Store.getMembers()

LimitTooltip = ({ i18n, limit, userKey }) ->
  <LicenceTooltipText text={i18n '/team:limits.members_limit_tooltip',
    count: limit
    email: contact_email
    key: i18n("/team:toolbar.#{userKey}").toLowerCase() }
  />

TeamMembersToolbar = createReactClass

  displayName: "TeamMembersToolbar"

  propTypes:
    isFetching: PropTypes.bool.isRequired
    members: PropTypes.instanceOf(Immutable.Map).isRequired
    limits: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [Translation("team:toolbar")]

  render: ->

    { isFetching, members, limits } = @props
    membersLimit = limits.get('teamMembers')
    adminsLimit = limits.get('teamAdmins')

    return null if isFetching
    <div>
      <div className="limits-container">
        <LimitCounter
          limit={membersLimit}
          count={members.size}
          title={@i18n 'members'}
          tooltip={<LimitTooltip limit={membersLimit} userKey='members' i18n={@i18n} />}
        />
        {membersLimit isnt adminsLimit and
          <LimitCounter
            limit={adminsLimit}
            count={filterAdminsAndResearchers(members).size}
            title={@i18n 'admins_researchers'}
            tooltip={<LimitTooltip
              limit={adminsLimit}
              userKey='admins_researchers'
              i18n={@i18n} />}
          />
        }
      </div>
    </div>


module.exports = withLimits(ConnectStore(TeamMembersToolbar, [MembersStore], storeConnector))
