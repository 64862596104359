{ dragSpec, dropSpec, dragCollect, dropCollect } =
  require 'components/scope/outcomes/outcome_dnd_spec'
DragDropTypes = require 'components/scope/outcomes/drag_drop_types'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
DragSource = ReactDnD.DragSource
DropTarget = ReactDnD.DropTarget

DraggableOutcome = createReactClass
  displayName: 'DraggableOutcome'
  mixins: [CustomRenderMixin]

  render: ->
    { connectDragSource, connectDropTarget, isDragging } = @props
    outcomeClasses = classNames 'outcome', 'draggable', 'dragging': isDragging

    connectDragSource(
      connectDropTarget(
        <div>
          {React.cloneElement React.Children.only(@props.children), className: outcomeClasses}
        </div>
      )
    )

DragSourced = DragSource(DragDropTypes.OUTCOME, dragSpec, dragCollect) DraggableOutcome
DropTargeted = DropTarget(DragDropTypes.OUTCOME, dropSpec, dropCollect) DragSourced

module.exports = DropTargeted
