module.exports = {
  getRelatedRecommendations: function(etds, relatedQuestions) {
    return relatedQuestions.reduce(function(acc, relatedQuestion, questionId) {
      var rquestion;
      rquestion = relatedQuestion.get('recommendationIds', Immutable.List()).reduce(function(acc, etdId) {
        var sectionId;
        sectionId = etds.getIn([etdId, 'conclusions', 'sectionsOrder'], Immutable.Map()).get(1);
        if (sectionId) {
          return acc = acc.set(etdId, etds.getIn([etdId, 'conclusions', 'sections', sectionId]));
        } else {
          return acc;
        }
      }, Immutable.Map());
      return acc.set(questionId, rquestion);
    }, Immutable.Map());
  }
};
