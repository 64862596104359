_.namespace(module, function(require) {
  var GeneralInformationDoc, QUESTIONS_DB_VIEW, W, exportGuideline, exportQuestionAndRecommendation, get, mediator, questionStatusPrefix, renderEditorValue, sequence;
  get = require('lodash').get;
  GeneralInformationDoc = require('lib/db_docs/general_information_doc');
  mediator = require('mediator');
  QUESTIONS_DB_VIEW = require('lib/questions_helper').QUESTIONS_DB_VIEW;
  W = require('when/when');
  sequence = require('when/sequence');
  exportGuideline = require('lib/services/covid_exporter/guideline');
  exportQuestionAndRecommendation = require('lib/services/covid_exporter/question');
  renderEditorValue = require('lib/services/covid_exporter/utils').renderEditorValue;
  questionStatusPrefix = require('lib/doc_prefixes').questionStatus;
  return {
    _getGuidelineDoc: function(projectId) {
      return GeneralInformationDoc.at(projectId).fetch();
    },
    _getQuestionsAndRecommendations: function(projectId, onlyCadthRecommendations) {
      var adapter;
      adapter = mediator.services.storePersistenceAdapter;
      return adapter.fetchWithView(projectId, 'questions', QUESTIONS_DB_VIEW).then(function(questions) {
        return _.filter(questions, function(_arg) {
          var cadthPublication, _id;
          _id = _arg._id, cadthPublication = _arg.cadthPublication;
          return _id.indexOf(':adoloped') < 0 && (!onlyCadthRecommendations || cadthPublication);
        });
      }).then(function(questions) {
        var recommendationIds;
        recommendationIds = _.chain(questions).pluck('recommendationIds').compact().flatten().value();
        if (_.isEmpty(recommendationIds)) {
          return {
            questions: questions,
            recommendations: {}
          };
        }
        return adapter.fetch(projectId, recommendationIds).then(function(_arg) {
          var recommendations, rows;
          rows = _arg.rows;
          recommendations = _.chain(rows).pluck('doc').compact().map(function(doc) {
            return [doc['_id'], doc];
          }).object().value();
          return {
            questions: questions,
            recommendations: recommendations
          };
        });
      });
    },
    _getQuestionStatuses: function(projectId) {
      var adapter;
      adapter = mediator.services.storePersistenceAdapter;
      return adapter.fetchWithKeyPrefix(projectId, questionStatusPrefix).then(function(_arg) {
        var rows;
        rows = _arg.rows;
        return _.chain(rows).pluck('doc').compact().map(function(doc) {
          return [doc.questionId, doc];
        }).object().value();
      });
    },
    _getDocSectionsFirstSection: function(doc, sectionId) {
      var content;
      content = get(doc, "templateData.chapters.sections." + sectionId + "_Common.subsections[0].content");
      return renderEditorValue(content);
    },
    _getDocumentSections: function(projectId) {
      var adapter;
      adapter = mediator.services.storePersistenceAdapter;
      return adapter.fetch(projectId, 'document_sections_from_template').then((function(_this) {
        return function(documentSectionsDoc) {
          return {
            abstract: _this._getDocSectionsFirstSection(documentSectionsDoc, 'abstract'),
            keyQuestions: _this._getDocSectionsFirstSection(documentSectionsDoc, 'keyQuestions'),
            recommendations: _this._getDocSectionsFirstSection(documentSectionsDoc, 'recommendations')
          };
        };
      })(this))["catch"](function(err) {
        if (err.status !== 404) {
          throw err;
        }
        return {
          abstract: '',
          keyQuestions: '',
          recommendations: ''
        };
      });
    },
    "export": function(projectId, onlyCadthRecommendations) {
      var promises;
      if (onlyCadthRecommendations == null) {
        onlyCadthRecommendations = false;
      }
      promises = [this._getGuidelineDoc(projectId), this._getQuestionsAndRecommendations(projectId, onlyCadthRecommendations), this._getQuestionStatuses(projectId)];
      return W.all(promises).then(function(_arg) {
        var exportedGuideline, guidelineDoc, processQuestion, questionStatuses, questions, recommendations, _ref;
        guidelineDoc = _arg[0], (_ref = _arg[1], questions = _ref.questions, recommendations = _ref.recommendations), questionStatuses = _arg[2];
        exportedGuideline = exportGuideline(projectId, guidelineDoc);
        processQuestion = function(question) {
          return function() {
            return exportQuestionAndRecommendation(question, recommendations, questionStatuses, projectId);
          };
        };
        return sequence(_.map(questions, processQuestion)).then(function(questions) {
          return _.chain(questions).map(function(exportedQuestion) {
            if (!exportedQuestion) {
              return void 0;
            }
            return _.extend({}, {
              guideline: exportedGuideline
            }, exportedQuestion);
          }).compact().value();
        });
      });
    },
    exportForWHO: function(projectId) {
      var promises;
      promises = [this._getGuidelineDoc(projectId), this._getDocumentSections(projectId)];
      return W.all(promises).then(function(_arg) {
        var documentSections, exportedGuideline, guidelineDoc;
        guidelineDoc = _arg[0], documentSections = _arg[1];
        exportedGuideline = exportGuideline(projectId, guidelineDoc);
        return _.extend({}, exportedGuideline, documentSections);
      });
    }
  };
});
