var DOCUMENT_SECTIONS_V2_CHAPTER_TYPE, arrayOf, boolean, chapterDocShape, docShape, documentId, initial, object, optional, shape, string, _ref;

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

initial = require('lib/db_docs/field_types/type_decorators').initial;

shape = require('lib/db_docs/field_types/shape');

arrayOf = require('lib/db_docs/field_types/array_of');

_ref = require('lib/db_docs/field_types/built_in_types'), boolean = _ref.boolean, string = _ref.string, object = _ref.object;

optional = shape.typeDecorators.optional;

DOCUMENT_SECTIONS_V2_CHAPTER_TYPE = require('lib/db_docs/doc_types').DOCUMENT_SECTIONS_V2_CHAPTER_TYPE;

chapterDocShape = docShape({
  type: initial(DOCUMENT_SECTIONS_V2_CHAPTER_TYPE)(string),
  title: string,
  slug: string,
  parent: optional(documentId),
  subchapters: initial([])(arrayOf(documentId)),
  sections: initial([])(arrayOf(documentId)),
  mdaTopicId: optional(documentId),
  visualizationId: optional(documentId),
  refIds: optional(arrayOf(documentId)),
  references: optional(object),
  isFromAcoem: optional(boolean),
  markedAsImportant: optional(boolean),
  chapterType: optional(string),
  editable: optional(boolean),
  deletable: optional(boolean)
});

module.exports = chapterDocShape;
