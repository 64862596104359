ConfirmationModal = require 'components/common/confirmation_modal'
EtdActions = require 'actions/etd_actions'
{ useI18n } = require 'lib/react_utils'
{ useState, useCallback } = React

ImportFromQualityIndicatorsButton = ->
  i18n = useI18n('quality_indicators:table')

  [isConfirmationOpen, setConfirmationOpen] = useState(false)

  openConfirmationDialog = useCallback ->
    setConfirmationOpen true
  , []

  closeConfirmationDialog = useCallback ->
    setConfirmationOpen false
  , []

  importFromQi = useCallback ->
    EtdActions.importMonitoringAndEvaluationToEtd()
    closeConfirmationDialog()
  , [EtdActions, closeConfirmationDialog]

  <div className="text-right">
    <button onClick={openConfirmationDialog}>{i18n 'import_from_qi'}</button>
    <ConfirmationModal
      isOpen={isConfirmationOpen}
      question={i18n '/messages.confirm_importing_from_qi'}
      confirmLabel={i18n '/actions.import'}
      onConfirm={importFromQi}
      onCancel={closeConfirmationDialog}
    />
  </div>

module.exports = ImportFromQualityIndicatorsButton
