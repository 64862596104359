CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdTemplatesActions = require 'actions/etd_templates_actions'
IconButton = require 'components/common/icon_button'
TextAreaWithApply = require 'components/common/text_area_with_apply'
Translation = require 'components/mixins/translation'

EditableOptions = ({i18n, options, optionsKey, onChange, onDelete, onAdd }) ->
  <div className="#{optionsKey} mb-10">
    <div className="bold">{i18n _.string.underscored(optionsKey)}</div>
    {_.map options, (option) =>
      <div key={option.value} className="flex flex-row">
        <input
          type="text"
          value={option.text}
          onChange={onChange(optionsKey, option.value)}
        />
        <IconButton
          iconName="delete"
          onClick={onDelete(optionsKey, option.value)}
        />
      </div>
    }
    <IconButton
      iconName="add"
      inlined
      labelPosition="right"
      label={i18n '/actions.add'}
      onClick={onAdd(optionsKey)}
    />
  </div>

EditableSectionCheckbox = createReactClass
  displayName: 'EditableSectionCheckbox'

  mixins: [Translation('settings:etd_templates')]

  propTypes:
    sectionDef: PropTypes.object.isRequired
    label: PropTypes.string.isRequired
    blockName: PropTypes.string.isRequired
    description: PropTypes.string
    onSectionToggle: PropTypes.func.isRequired

  getInitialState: ->
    isEditing: false
    label: @props.label
    description: @props.description
    tooltip: @props.tooltip
    options: @props.blockData?.get('options').toJS()
    additionalOptions: @props.blockData?.get('additionalOptions').toJS()

  onChange: ->
    @props.onSectionToggle @props.blockName, @props.sectionDef.get('id')

  onChangeLabel: (e) ->
    @setState label: e.target.value

  onChangeDescription: (value) ->
    @setState description: value

  onChangeTooltip: (value) ->
    @setState tooltip: value

  saveSection: ->
    { label, description, tooltip, options, additionalOptions } = @state
    { sectionDef } = @props
    EtdTemplatesActions.updateSection {
      sectionId: sectionDef.get('id')
      label
      description
      info: tooltip
      options
      additionalOptions
    }
    @setState isEditing: false

  onChangeOption: (key, optionValue) -> (e) =>
    @setState "#{key}": _.map @state[key], (option) ->
      if option.value is optionValue
        option.text = e.target.value
      option

  deleteOption: (key, optionValue) -> =>
    @setState "#{key}": _.filter @state[key], (option) ->
      option.value isnt optionValue

  addOption: (key) -> =>
    nextValueIndex = _.last(@state[key])?.value?.split('_')[1] or 0
    @setState "#{key}": @state[key].concat(
      {value: "#{key}_#{parseInt(nextValueIndex) + 1}", text: ""})

  onCancel: ->
    @setState @getInitialState()

  render: ->
    { blockData, sectionDef, description, className, label } = @props
    id = sectionDef.get('id')

    readOnlyOrChangeProp = if sectionDef.get('required')
      readOnly: true
    else
      onChange: @onChange

    labelClasses = classNames 'section-checkbox', disabled: sectionDef.get('required')
    sectionNameClasses = classNames 'section-name', 'with-desc': description

    <div className={className}>
      <div className="flex flex-row items-center">
        <div className="flex-grow">
          <label className={labelClasses}>
            <input
              type="checkbox"
              value={id}
              checked={sectionDef.get('required') or sectionDef.get('checked')}
              {...readOnlyOrChangeProp}
            />
            {if @state.isEditing
              <input type="text" value={@state.label} onChange={@onChangeLabel}/>
            else
              <span className={sectionNameClasses}>{label}</span>
            }
          </label>
          <div className='section-desc'>
            {if @state.isEditing
              <div>
                <div className="mb-10">
                  <label>{@i18n 'description'}</label>
                  <TextAreaWithApply
                    onApply={@onChangeDescription}
                    content={@state.description}
                  />
                </div>
                <div className="mb-10">
                  <label>{@i18n 'tooltip'}</label>
                  <TextAreaWithApply
                    onApply={@onChangeTooltip}
                    content={@state.tooltip}
                  />
                </div>
              </div>
            else
              <div>{@state.description}</div>
            }
          </div>
          {@state.isEditing && <div className="section-options ml-20">
            <EditableOptions
              i18n={@i18n}
              onAdd={@addOption}
              onChange={@onChangeOption}
              onDelete={@deleteOption}
              options={@state.options}
              optionsKey='options'
            />
            <EditableOptions
              i18n={@i18n}
              onAdd={@addOption}
              onChange={@onChangeOption}
              onDelete={@deleteOption}
              options={@state.additionalOptions}
              optionsKey='additionalOptions'
            />
          </div>}
        </div>
        <div>
          {if @state.isEditing
            <div className="flex flex-col">
              <IconButton iconName="save" onClick={@saveSection} />
              <IconButton iconName="decline-icon" onClick={@onCancel} />
            </div>
          else
            <IconButton iconName="edit" onClick={() => @setState({isEditing: true})} />
          }
        </div>
      </div>
    </div>

module.exports = EditableSectionCheckbox
