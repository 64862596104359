VotingTeamComments = require 'components/etd/voting_team_comments'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

VotingPropositionsCell = createReactClass
  displayName: 'VotingPropositionsCell'

  propTypes:
    sectionId: PropTypes.string.isRequired
    votingResults: PropTypes.object.isRequired
    colSpan: PropTypes.number
    currentEditable: PropTypes.string
    adminComment: PropTypes.string
    hiddenVotes: PropTypes.instanceOf Immutable.List

  mixins: [
    CustomRenderMixin
  ]

  render: ->
    <td colSpan={@props.colSpan}>
      <span>{@i18n 'team_members_propositions'}</span>

    </td>

module.exports = VotingPropositionsCell
