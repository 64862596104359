var TypeValidationError, customCheck, extendedCheck, extendedParse, initial, typeUtils,
  __slice = [].slice;

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

typeUtils = require('lib/db_docs/field_types/built_in_types').typeUtils;

initial = function(initialVal) {
  return function(typeRecord) {
    var initialValCheck;
    initialValCheck = typeRecord.check(initialVal);
    if (initialVal !== initialValCheck) {
      throw new Error("Initial value must be of correct type. " + initialValCheck.message);
    }
    return typeRecord.set('typeDefault', initialVal);
  };
};

extendedCheck = function() {
  var validators;
  validators = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return function(typeRecord) {
    validators.unshift(typeRecord.check);
    return typeRecord.set('check', typeUtils.checksProduct.apply(null, validators));
  };
};

customCheck = function(customCheck) {
  return function(typeRecord) {
    return typeRecord.set('check', customCheck);
  };
};

extendedParse = function() {
  var otherParsers;
  otherParsers = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return function(typeRecord) {
    var initialParse;
    initialParse = typeRecord.parse.bind(typeRecord);
    return typeRecord.set('parse', function(val) {
      var initialParseResult, maybeParsed, parser, _i, _len, _ref;
      initialParseResult = initialParse(val);
      if (initialParseResult instanceof TypeValidationError) {
        _ref = _.compact(otherParsers);
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          parser = _ref[_i];
          maybeParsed = typeRecord.check(parser(val));
          if (!(maybeParsed instanceof TypeValidationError)) {
            return maybeParsed;
          }
        }
      }
      return initialParseResult;
    });
  };
};

module.exports = {
  initial: initial,
  extendedCheck: extendedCheck,
  customCheck: customCheck,
  extendedParse: extendedParse
};
