ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
{ isValidLimit } = require 'lib/limits_helper'
KeyMessagesActions = require 'actions/key_messages_actions'
mediator = require 'mediator'
QuestionPicoEdit = require 'components/evidence_syntheses/question_pico_edit'
QuestionsActions = require 'actions/questions_actions'
QuestionMetaFields = require 'components/evidence_syntheses/question_meta_fields'
QuestionStatus = require 'components/evidence_syntheses/questions_list/question_status'
QuestionIcons = require 'components/evidence_syntheses/questions_list/question_icons'
QuestionsStore = require 'stores/questions_store'
QuestionTextWithNavigation = require 'components/evidence_syntheses/question_text_with_navigation'
MdgQuestionMetaFields = require 'components/evidence_syntheses/mdg/question_meta_fields'
RevmanWebActions = require 'actions/revman_web_actions'
Translation = require 'components/mixins/translation'
W = require 'when/when'
withLimits = require 'components/enhancers/with_limits'
OverReviewModuleStateToggle =
  require 'components/evidence_syntheses/over_review_module_state_toggle'
utils = require 'base/lib/utils'
{ MDG_QUESTION } = require 'lib/db_docs/doc_types'

storeConnector =
  QuestionsStore: (Store, props) ->
    qId = props.data.get '_id'
    editState: Store.getQuestionEditState qId
    overReviewModuleState: Store.getQuestionOverReviewModuleState qId

DX_META_FIELDS = ['settings', 'indexTestCutOff', 'referenceTest', 'referenceTestShortName',
  'referenceTestThreshold', 'tableTitle', 'authors']

EditableQuestion = createReactClass
  displayName: 'EditableQuestion'

  propTypes:
    data: PropTypes.instanceOf(Immutable.Map).isRequired
    editState: PropTypes.instanceOf(Immutable.Map)
    includedInDocumentSections: PropTypes.bool.isRequired
    limits: PropTypes.instanceOf(Immutable.Map).isRequired,
    openLimitWarningModal: PropTypes.func.isRequired
    overReviewModuleState: PropTypes.bool
    questionsCount: PropTypes.number.isRequired
    questionStatus: PropTypes.instanceOf(Immutable.Map)

  mixins: [
    CustomRenderMixin
    Translation('es:question')
  ]

  getDefaultProps: ->
    questionStatus: Immutable.Map()

  _containerRef: (el) ->
    @container = el

  _picoFieldsRef: (el) ->
    @picoFields = el

  _metaFieldsRef: (el) ->
    @metaFields = el

  getQuestionId: ->
    @props.data.get('_id')

  getInitialState: ->
    comparatorTestPresent: false

  getQuestionControls: ->
    controlsList = ['duplicate', 'delete']
    controlsList.unshift if @props.editState? then 'save' else 'edit'
    controlsList.map (controlName) =>
      capitalized = _.str.capitalize controlName
      disabled = if controlName in ['save', 'delete', 'duplicate']
        @props.editState?.get("isBeing#{capitalized}d", false)
      else
        false

      name: controlName
      handler: @["handle#{capitalized}"]
      disabled: disabled

  getChangedFields: ->
    # for mdgQuestion there are no picoFields, and editing is done on modal window instead
    _.extend {},
      @picoFields?.getEditedValues(),
      @metaFields?.getEditedValues()

  save: ->
    changedFields = @getChangedFields()
    questionId = @getQuestionId()

    if _.isEmpty changedFields
      @handleEditCancel()
    else
      QuestionsActions.updateQuestion { questionId, questionData: changedFields }
        .then @handleEditCancel

  handleEdit: ->
    { data } = @props
    if data.get('type') is 'keyMessage'
      KeyMessagesActions.goToKeyMessageCreator @getQuestionId()
    else if data.get('docType') is MDG_QUESTION
      QuestionsActions.openMdgEditQuestionDialog @props.data
    else
      QuestionsActions.startQuestionEdit @getQuestionId()

  handleEditCancel: ->
    QuestionsActions.stopQuestionEdit @getQuestionId()

  handleSave: ->
    return @container.collapse() unless @props.editState
    @save()

  handleDelete: ->
    questionId = @getQuestionId()
    revmanWebReviewId = @props.data.get 'revManWebReviewId', null
    revmanWebTableId = @props.data.get 'revManWebTableId', null
    if revmanWebReviewId? and revmanWebTableId?
      dialog =
        message: $.t 'archie:revman.question_delete.confirm'
        declineText: $.t 'actions.cancel'
        confirmText: $.t 'actions.delete'
      mediator.dialogs.confirm dialog, (confirmed) ->
        return W.resolve() unless confirmed
        RevmanWebActions.deleteTable revmanWebReviewId, revmanWebTableId
        .then -> QuestionsActions.deleteQuestion questionId, false
        .catch (err) ->
          if window.gdt?.debug
            console.error err
          else
            utils.reportRavenError err
          mediator.dialogs.error message: $.t 'archie:revman.question_delete.error'
    else
      QuestionsActions.deleteQuestion questionId

  handleDuplicate: ->
    if not isValidLimit(@props.questionsCount, @props.limits.get('questions'))
      @props.openLimitWarningModal()
      return

    questionId = @getQuestionId()
    maybeSaveQuestion = if @props.editState
      changedFields = @getChangedFields()

      if _.isEmpty changedFields
        W.resolve()
      else
        QuestionsActions.updateQuestion { questionId, questionData: changedFields }
    else
      W.resolve()

    maybeSaveQuestion.then -> QuestionsActions.duplicateQuestion { questionId }

  handleCollapse: ->
    if @props.editState and not @props.editState.get 'isBeingSaved'
      @save()

  componentDidUpdate: (prevProps, prevState) ->
    @container.collapse() if prevProps.editState and not @props.editState
    if prevProps.data.get('comparatorTestPresent') isnt @props.data.get('comparatorTestPresent')
      @setState comparatorTestPresent: @props.data.get('comparatorTestPresent')

  render: ->
    {
      data,
      editState,
      overReviewModuleState,
      questionStatus,
      projectFromOrganization,
      includedInDocumentSections
    } = @props

    isEditing = editState?

    questionId = @getQuestionId()
    hasTable = data.get('docType') is 'mdgQuestion' and not
      data.get('studies', Immutable.Map()).isEmpty()
    locked = questionStatus.get('locked', false)

    lastUpdate = data.get '$timestamp', data.get 'lastUpdate'
    questionNavigationClasses = classNames 'question-navigation',
      'question-navigation-with-progress': projectFromOrganization

    questionType = if data.get('docType') is 'mdgQuestion' then 'mdgQuestion' else data.get 'type'
    mdgQuestion = questionType is MDG_QUESTION

    <CollapsibleWithControls
      ref={@_containerRef}
      forcedExpand={isEditing}
      controls={@getQuestionControls()}
      onCollapse={@handleCollapse}
    >
      <div className='question-pico-fields'>
        {if isEditing and not mdgQuestion
          <QuestionPicoEdit
            questionData={data}
            onRequestSave={@handleSave}
            onRequestCancel={@handleEditCancel}
            toggleComparatorTestPresent={(checked) => @setState comparatorTestPresent: checked}
            ref={@_picoFieldsRef}
          />
        else
          <div className={questionNavigationClasses}>
            <QuestionTextWithNavigation
              overReviewModuleState={overReviewModuleState}
              questionId={questionId}
              questionType={questionType}
              text={data.get 'question'}
              locked={locked}
            />
            {projectFromOrganization and
              <React.Fragment>
                <QuestionIcons
                  includedInDocumentSections={includedInDocumentSections}
                  locked={locked}
                  hasTable={hasTable}
                />
                <QuestionStatus
                  questionStatus={questionStatus}
                  questionId={questionId}
                 />
              </React.Fragment>
            }
          </div>
        }
      </div>
      {if mdgQuestion
        <MdgQuestionMetaFields
          i18n={@i18n}
          question={data}
        />
      else
        <div>
          <QuestionMetaFields
            isEditing={isEditing}
            fields={switch data.get 'type'
              when 'diagnostic'
                DX_META_FIELDS
              when 'keyMessage'
                []
              else
                undefined
            }
            comparatorTestPresent={@state.comparatorTestPresent}
            onRequestSave={@handleSave}
            onRequestCancel={@handleEditCancel}
            questionData={data}
            ref={@_metaFieldsRef}
          />
          {unless _.contains ['diagnostic', 'keyMessage'], data.get('type')
            <OverReviewModuleStateToggle
              currentState={overReviewModuleState}
              questionId={questionId}
            />
          }
          <div className='last-update'>
            <span>{@i18n 'last_update'}: {moment(lastUpdate)?.format($.t('time_format')) ? ''}</span>
          </div>
        </div>
      }
    </CollapsibleWithControls>

module.exports = withLimits(ConnectStore EditableQuestion, QuestionsStore, storeConnector)
