CustomRenderMixin = require 'components/mixins/custom_render_mixin'

MembersComments = createReactClass
  displayName: 'MembersComments'

  propTypes:
    membersComments: PropTypes.instanceOf(Immutable.Map).isRequired
    membersMap: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [
    CustomRenderMixin
  ]

  getDefaultProps: ->
    blockTitleText: $.t 'scope:questions.members_comments'

  renderMemberComment: (comment, memberId) ->
    <div className='member-comment' key={memberId}>
      <div>{@props.membersMap.get memberId}</div>
      <div dangerouslySetInnerHTML={{__html: comment}} />
    </div>

  render: ->
    { membersComments } = @props
    <div className='comments-section'>
      <div className='title'>{@props.blockTitleText}</div>
      <div className='members-comments'>
        {if membersComments.isEmpty()
          <div className='member-comment' />
        else
          @props.membersComments.map(@renderMemberComment).toList()
        }
      </div>
    </div>

module.exports = MembersComments
