var Collection, Request, Requests,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Collection = require('models/base/collection');

Request = require('models/request');

module.exports = Requests = (function(_super) {
  __extends(Requests, _super);

  function Requests() {
    return Requests.__super__.constructor.apply(this, arguments);
  }

  Requests.prototype.model = Request;

  Requests.prototype.pouch = {
    fetch: 'requests',
    views: {
      requests: {
        map: function(doc) {
          if (doc.docType === 'request') {
            return emit(doc.docType, doc);
          }
        }
      }
    },
    filters: {
      requests: function(doc) {
        return doc._deleted || doc.docType === 'request';
      }
    }
  };

  Requests.prototype.initialize = function() {
    Requests.__super__.initialize.apply(this, arguments);
    if (!this.url) {
      throw new Error('url property must be specified');
    }
  };

  return Requests;

})(Collection);
