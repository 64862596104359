var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div>\n  ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:dialogs.topAcceptance", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div>\n<div class=\"row mt-20\">\n  <div class=\"col-4\">\n    <button class=\"btn btn-block btn-cancel\" data-role=\"cancel\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "\n    </button>\n  </div>\n  <div class=\"col-4\">\n    <button class=\"btn btn-block\" data-role=\"see_top\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:dialogs.display_top_terms", {"name":"i18n","hash":{},"data":data})))
    + "\n    </button>\n  </div>\n  <div class=\"col-4\">\n    <button class=\"btn btn-block btn-success\" data-role=\"accept\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/tos_notification.i_confirm", {"name":"i18n","hash":{},"data":data})))
    + "\n    </button>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}