var INITIAL_QUESTION_STATUSES, MDG_INITIAL_QUESTION_STATUSES, MDG_WORKSPACE, QUESTION_STATUSES_DOC_TYPE, QuestionsStatusesActions, W, alt, appUtils, mediator, questionStatusDocTemplate, questionStatusPrefix, _ref,
  __slice = [].slice;

alt = require('alt');

appUtils = require('lib/app_utils');

mediator = require('mediator');

INITIAL_QUESTION_STATUSES = require('lib/questions_helper').INITIAL_QUESTION_STATUSES;

QUESTION_STATUSES_DOC_TYPE = require('lib/db_docs/doc_types').QUESTION_STATUSES_DOC_TYPE;

_ref = require('lib/mdg_helper'), MDG_WORKSPACE = _ref.MDG_WORKSPACE, MDG_INITIAL_QUESTION_STATUSES = _ref.MDG_INITIAL_QUESTION_STATUSES;

questionStatusPrefix = require('lib/doc_prefixes').questionStatus;

W = require('when/when');

questionStatusDocTemplate = {
  docType: 'questionStatus',
  completed: false,
  evidenceTableStatus: {},
  recommendationStatus: {},
  presentationStatus: {}
};

QuestionsStatusesActions = (function() {
  function QuestionsStatusesActions() {
    this.generateActions('fetchSuccess', 'fetchError', 'removeQuestionStatuses');
  }

  QuestionsStatusesActions.prototype.dbChange = function(change, _pending, _lastSeq) {
    var doc;
    doc = change.doc;
    if (doc.docType !== QUESTION_STATUSES_DOC_TYPE) {
      return;
    }
    return this.dispatch(doc);
  };

  QuestionsStatusesActions.prototype.fetch = function(projectId) {
    if (projectId == null) {
      projectId = mediator.project.id;
    }
    this.dispatch();
    return mediator.services.storePersistenceAdapter.fetchWithKeyPrefix(projectId, questionStatusPrefix).then((function(_this) {
      return function(docs) {
        _this.actions.fetchSuccess(docs);
        return docs;
      };
    })(this))["catch"](this.actions.fetchError);
  };

  QuestionsStatusesActions.prototype.toggleQuestionLocked = function(questionId, value) {
    var dbName, docId;
    dbName = mediator.project.id;
    docId = "" + questionStatusPrefix + questionId;
    return mediator.services.storePersistenceAdapter.updateOrCreate(dbName, docId, function(doc) {
      var _ref1;
      return _.extend({}, questionStatusDocTemplate, doc, {
        locked: !((_ref1 = doc.locked) != null ? _ref1 : false),
        questionId: questionId
      });
    })["catch"](appUtils.errorHandler);
  };

  QuestionsStatusesActions.prototype.toggleQuestionCompleted = function(questionId, value) {
    var dbName, docId;
    dbName = mediator.project.id;
    docId = "" + questionStatusPrefix + questionId;
    return mediator.services.storePersistenceAdapter.updateOrCreate(dbName, docId, function(doc) {
      var completed;
      completed = doc.completed !== void 0 ? !doc.completed : true;
      return _.extend({}, questionStatusDocTemplate, doc, {
        completed: completed,
        questionId: questionId
      });
    })["catch"](appUtils.errorHandler);
  };

  QuestionsStatusesActions.prototype.updateQuestionStatus = function(questionId, pathOrKey, value) {
    var dbName, docId, path;
    dbName = mediator.project.id;
    docId = "" + questionStatusPrefix + questionId;
    path = _.isArray(pathOrKey) ? pathOrKey : [pathOrKey];
    return mediator.services.storePersistenceAdapter.updateOrCreate(dbName, docId, function(doc) {
      var immDoc, newStatus;
      immDoc = Immutable.fromJS(doc);
      newStatus = !_.isEmpty(immDoc.getIn(path)) && immDoc.getIn(__slice.call(path).concat(['status'])) === value ? {
        status: null
      } : {
        status: value
      };
      immDoc = immDoc.mergeIn(path, newStatus);
      return _.extend({}, questionStatusDocTemplate, immDoc.toJS(), {
        questionId: questionId
      });
    })["catch"](appUtils.errorHandler);
  };

  QuestionsStatusesActions.prototype.removeQuestionStatusesDoc = function(questionId) {
    var dbName, docId;
    dbName = mediator.project.id;
    docId = "" + questionStatusPrefix + questionId;
    return mediator.services.storePersistenceAdapter.exists(dbName, docId).then((function(_this) {
      return function(exists) {
        if (!exists) {
          return;
        }
        return mediator.services.storePersistenceAdapter.updateOrCreate(dbName, docId, function(doc) {
          _this.actions.removeQuestionStatuses(doc);
          return {
            _id: doc._id,
            _rev: doc._rev,
            _deleted: true
          };
        });
      };
    })(this))["catch"](appUtils.errorHandler);
  };

  QuestionsStatusesActions.prototype.restoreDeletedDoc = function(docToRestore) {
    var adapter;
    if (docToRestore.isEmpty()) {
      return W.resolve();
    }
    adapter = mediator.services.storePersistenceAdapter;
    return adapter.updateOrCreate(mediator.project.id, docToRestore._id, function(doc) {
      return _.extend({}, _.omit(doc, '_deleted'), docToRestore);
    })["catch"](appUtils.errorHandler);
  };

  QuestionsStatusesActions.prototype.resetAllStatuses = function() {
    var initialStatuses, projectId;
    projectId = mediator.project.id;
    initialStatuses = mediator.activeWorkspace === MDG_WORKSPACE ? MDG_INITIAL_QUESTION_STATUSES : INITIAL_QUESTION_STATUSES;
    return mediator.services.storePersistenceAdapter.fetchWithKeyPrefix(projectId, questionStatusPrefix).then(function(_arg) {
      var rows, updatedDocs;
      rows = _arg.rows;
      updatedDocs = _.map(rows, function(_arg1) {
        var doc;
        doc = _arg1.doc;
        return _.extend(doc, initialStatuses);
      });
      return mediator.services.storePersistenceAdapter.bulkDocs(projectId, updatedDocs);
    })["catch"](this.actions.fetchError);
  };

  return QuestionsStatusesActions;

})();

module.exports = alt.createActions(QuestionsStatusesActions);
