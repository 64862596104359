module.exports = [
  {
    id: 'condition',
    chapters: [
      {
        title: 'relatedTerms'
      }, {
        title: 'overview',
        subchapters: [
          {
            title: 'incidenceAndPrevalence'
          }
        ]
      }, {
        title: 'causationAndKnownRiskFactors'
      }, {
        title: 'illustration'
      }, {
        title: 'diagnosis',
        subchapters: [
          {
            title: 'history'
          }, {
            title: 'physicalExamination'
          }, {
            title: 'tests'
          }
        ]
      }, {
        title: 'treatment'
      }, {
        title: 'prognosis'
      }, {
        title: 'differentialDiagnosis'
      }, {
        title: 'rehabilitation'
      }, {
        title: 'comorbidConditions'
      }, {
        title: 'complications'
      }, {
        title: 'lengthOfDisability',
        subchapters: [
          {
            title: 'factorsInfluencingDuration'
          }
        ]
      }, {
        title: 'abilityToWork',
        subchapters: [
          {
            title: 'risk'
          }, {
            title: 'capacity'
          }, {
            title: 'tolerance'
          }, {
            title: 'accommodations'
          }
        ]
      }, {
        title: 'activityRestrictionsAndModifications'
      }, {
        title: 'maximumMedicalImprovement'
      }, {
        title: 'failureToRecover',
        subchapters: [
          {
            title: 'regardingDiagnosis'
          }, {
            title: 'regardingTreatment'
          }, {
            title: 'regardingPrognosis'
          }
        ]
      }, {
        title: "relatedACOEMGuidelinesContent",
        isFromAcoem: true,
        subchapters: [
          {
            title: "workflows"
          }, {
            title: "riskFactors"
          }, {
            title: "causation"
          }, {
            title: "workRelatedness"
          }, {
            title: "signsAndSymptoms"
          }, {
            title: "redFlags"
          }, {
            title: "initialAssessment"
          }, {
            title: "diagnosticCriteria"
          }, {
            title: "classification"
          }, {
            title: "medicalHistoryQuestionnaire"
          }, {
            title: "testRecommendations"
          }, {
            title: "treatmentRecommendations"
          }, {
            title: "jobAnalysis"
          }, {
            title: "followUpCare"
          }, {
            title: "attribution"
          }
        ]
      }, {
        title: 'references'
      }
    ]
  }, {
    id: 'procedure',
    chapters: [
      {
        title: 'relatedTerms'
      }, {
        title: 'overview'
      }, {
        title: 'illustration'
      }, {
        title: 'reasonForProcedure'
      }, {
        title: 'howProcedureIsPerformed'
      }, {
        title: 'prognosis'
      }, {
        title: 'rehabilitation'
      }, {
        title: 'comorbidConditions'
      }, {
        title: 'complications'
      }, {
        title: 'lengthOfDisability',
        subchapters: [
          {
            title: 'factorsInfluencingDuration'
          }
        ]
      }, {
        title: 'abilityToWork',
        subchapters: [
          {
            title: 'risk'
          }, {
            title: 'capacity'
          }, {
            title: 'tolerance'
          }, {
            title: 'accommodations'
          }
        ]
      }, {
        title: 'activityRestrictionsAndModifications'
      }, {
        title: 'maximumMedicalImprovement'
      }, {
        title: "relatedACOEMGuidelinesContent",
        isFromAcoem: true,
        subchapters: [
          {
            title: "workflows"
          }, {
            title: "riskFactors"
          }, {
            title: "causation"
          }, {
            title: "workRelatedness"
          }, {
            title: "signsAndSymptoms"
          }, {
            title: "redFlags"
          }, {
            title: "initialAssessment"
          }, {
            title: "diagnosticCriteria"
          }, {
            title: "classification"
          }, {
            title: "medicalHistoryQuestionnaire"
          }, {
            title: "testRecommendations"
          }, {
            title: "treatmentRecommendations"
          }, {
            title: "jobAnalysis"
          }, {
            title: "followUpCare"
          }, {
            title: "attribution"
          }
        ]
      }, {
        title: 'references'
      }
    ]
  }
];
