var authorStringToObject, getKeyValObject, parseReferenceIdentifiers, parseStudies, parseStudy, referenceNodeToSpec,
  __slice = [].slice;

authorStringToObject = require('lib/references_utils').authorStringToObject;

getKeyValObject = require('base/lib/utils').getKeyValObject;

parseReferenceIdentifiers = function(refNode) {
  var identifierNodes;
  identifierNodes = refNode.querySelector('IDENTIFIERS').children;
  return _.reduce(identifierNodes, function(result, node) {
    var type, value;
    type = node.getAttribute('TYPE');
    if (type === 'PUBMED') {
      type = 'PMID';
    }
    value = node.getAttribute('VALUE');
    return result[type] = value;
  }, {});
};

referenceNodeToSpec = function(refNode) {
  var authors, identifiers, refSpec, _ref, _ref1, _ref2, _ref3, _ref4, _ref5;
  authors = _.map((_ref = refNode.querySelector('AU')) != null ? _ref.textContent.split(/, ?/) : void 0, function(author) {
    var firstNames, lastName, _ref1;
    _ref1 = author.split(' '), lastName = _ref1[0], firstNames = 2 <= _ref1.length ? __slice.call(_ref1, 1) : [];
    return authorStringToObject(__slice.call(firstNames).concat([lastName]).join(' '));
  });
  identifiers = parseReferenceIdentifiers(refNode);
  refSpec = {
    itemType: refNode.getAttribute('TYPE'),
    authors: authors,
    title: (_ref1 = refNode.querySelector('TI') || refNode.querySelector('TO')) != null ? _ref1.textContent : void 0,
    year: (_ref2 = refNode.querySelector('YR')) != null ? _ref2.textContent : void 0,
    publishedIn: (_ref3 = refNode.querySelector('SO')) != null ? _ref3.textContent : void 0,
    volume: (_ref4 = refNode.querySelector('VL')) != null ? _ref4.textContent : void 0,
    pages: (_ref5 = refNode.querySelector('PG')) != null ? _ref5.textContent : void 0
  };
  return _.extend(refSpec, identifiers);
};

parseStudy = function($studyNode) {
  var references, studyData;
  studyData = {
    name: $studyNode.attr('NAME'),
    year: $studyNode.attr('YEAR'),
    revmanId: $studyNode.attr('ID')
  };
  $studyNode.find('> IDENTIFIERS IDENTIFIER').each(function(idx, identifierNode) {
    var identifierType, identifierValue;
    identifierType = identifierNode.getAttribute('TYPE');
    identifierValue = identifierNode.getAttribute('VALUE');
    return _.extend(studyData, getKeyValObject(identifierType, identifierValue));
  });
  references = _.map($studyNode.find('REFERENCE'), referenceNodeToSpec);
  return _.extend(studyData, {
    references: references
  });
};

parseStudies = function($xml, studyIds) {
  var $studies, $study, studyId, _i, _len, _results;
  $studies = $xml.find('STUDIES_AND_REFERENCES > STUDIES');
  _results = [];
  for (_i = 0, _len = studyIds.length; _i < _len; _i++) {
    studyId = studyIds[_i];
    $study = $studies.find("STUDY[ID=\"" + studyId + "\"]");
    _results.push(parseStudy($study));
  }
  return _results;
};

module.exports = parseStudies;
