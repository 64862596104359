var QuestionsListComponentStore, adolopmentsHelper, etdHelper, filterQuestionKeys, filterQuestionsWithTheSameType, getAssessmentSelectedOption, prepareQuestionsSelectionList, questionToSelectOption, withSelectedQuestionIdx;

etdHelper = require('lib/etd_helper');

adolopmentsHelper = require('lib/adolopments_helper');

QuestionsListComponentStore = require('stores/questions_list_component_store');

filterQuestionKeys = function(question) {
  var filteredKeysQuestion;
  filteredKeysQuestion = question.filter(function(v, k) {
    return k === 'question' || k === 'intervention' || k === 'comparison' || k === 'orderNumber' || k === 'type' || k === 'indexTest' || k === 'comparatorTest' || k === 'comparatorTestPresent' || k === 'healthProblemOrPopulation' || k === 'recommendationIds' || k === '_id' || k === 'recommendationAdolopmentIds';
  });
  return filteredKeysQuestion.set('questionId', question.get('_id')).update('type', function(type) {
    return type != null ? type : 'management';
  });
};

getAssessmentSelectedOption = function(question, section, adolopments, templateData, adolopmentData) {
  var recommendationId, _ref;
  recommendationId = question.get('recommendationIds', Immutable.List()).first();
  if (question.get('recommendationAdolopmentIds', Immutable.List()).isEmpty()) {
    return etdHelper.getAssessmentSelectedOption(templateData, section);
  } else {
    return adolopmentsHelper.getAssessmentSelectedOption(adolopments.get((_ref = question.get('recommendationAdolopmentIds', Immutable.List())) != null ? _ref.last() : void 0), templateData, section, adolopmentData.get(recommendationId, Immutable.Map()));
  }
};

prepareQuestionsSelectionList = function(questions, selectedQuestions, questionGroups, etdRequired) {
  var questionMapper, questionsByGroup, selectedQuestionsIds, ungroupedQuestions;
  if (etdRequired == null) {
    etdRequired = true;
  }
  selectedQuestionsIds = selectedQuestions.map(function(q) {
    return q.get('questionId');
  });
  questionsByGroup = QuestionsListComponentStore.groupQuestionsByGroup(questions, questionGroups);
  ungroupedQuestions = questionsByGroup.getIn(['questionsWithoutGroup', 'questions'], Immutable.Map());
  questionMapper = _.compose(questionToSelectOption(etdRequired), withSelectedQuestionIdx(selectedQuestionsIds));
  return {
    ungroupedOptions: ungroupedQuestions.map(questionMapper).toArray(),
    groupedOptions: questionsByGroup.filterNot(function(_qg, groupId) {
      return groupId === 'questionsWithoutGroup';
    }).map(function(qg) {
      return {
        caption: qg.get('caption'),
        options: qg.get('questions').map(questionMapper).toArray()
      };
    }).toArray()
  };
};

questionToSelectOption = function(etdRequired) {
  return function(q) {
    var hasBeenSelected, hasNoETD, selectedQuestionIdx;
    selectedQuestionIdx = q.get('selectedQuestionIdx');
    hasBeenSelected = selectedQuestionIdx >= 0;
    hasNoETD = q.get('recommendationIds', Immutable.List()).isEmpty();
    return {
      value: q.get('_id'),
      text: q.get('question'),
      disabled: hasBeenSelected || (etdRequired && hasNoETD),
      title: hasBeenSelected ? $.t('mc:disabled_selection_tooltips.is_selected', {
        selectedIdx: selectedQuestionIdx + 1
      }) : hasNoETD ? $.t('mc:disabled_selection_tooltips.no_etd') : void 0
    };
  };
};

withSelectedQuestionIdx = function(selectedQuestionsIds) {
  return function(q) {
    var questionId, selectedQuestionIdx;
    questionId = q.get('_id');
    selectedQuestionIdx = selectedQuestionsIds.indexOf(questionId);
    return q.set('selectedQuestionIdx', selectedQuestionIdx);
  };
};

filterQuestionsWithTheSameType = function(questionType) {
  return function(q) {
    if (questionType) {
      return q.get('type', 'management') === questionType;
    } else {
      return true;
    }
  };
};

module.exports = {
  filterQuestionKeys: filterQuestionKeys,
  filterQuestionsWithTheSameType: filterQuestionsWithTheSameType,
  getAssessmentSelectedOption: getAssessmentSelectedOption,
  prepareQuestionsSelectionList: prepareQuestionsSelectionList
};
