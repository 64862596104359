var Outcome, OverReviewDocShape, ReviewedOutcome, STD_STATUSES, Source, StudiesMatrixValue, Study, array, arrayOf, boolean, calculated, docShape, extendedCheck, extraChecks, mapOf, number, numberChecks, oneOf, optional, shape, string, _ref, _ref1;

STD_STATUSES = require('lib/questions_helper').QUESTION_OVER_REVIEW_STUDIES_STATUSES;

Outcome = require('lib/db_docs/doc_shapes/outcome_doc_shape');

shape = require('lib/db_docs/field_types/shape');

docShape = require('lib/db_docs/field_types/doc_shape');

oneOf = require('lib/db_docs/field_types/one_of');

mapOf = require('lib/db_docs/field_types/map_of');

arrayOf = require('lib/db_docs/field_types/array_of');

_ref = require('lib/db_docs/field_types/built_in_types'), string = _ref.string, boolean = _ref.boolean, number = _ref.number, array = _ref.array, extraChecks = _ref.extraChecks;

extendedCheck = require('lib/db_docs/field_types/type_decorators').extendedCheck;

_ref1 = shape.typeDecorators, optional = _ref1.optional, calculated = _ref1.calculated;

numberChecks = extraChecks.number;

ReviewedOutcome = docShape({
  name: string,
  sourcesOutcomesMapping: mapOf(string)
});

Study = docShape({
  text: string,
  mergedInto: optional(string)
});

Source = docShape({
  sourceName: string,
  population: string,
  intervention: string,
  comparison: string,
  outcomes: arrayOf(Outcome),
  studyIds: arrayOf(string),
  qualityOfReview: extendedCheck(numberChecks.min(0), numberChecks.max(5))(number),
  comment: optional(string)
});

StudiesMatrixValue = mapOf(oneOf(R.values(STD_STATUSES)));

OverReviewDocShape = docShape({
  reviewedOutcomes: arrayOf(ReviewedOutcome),
  sources: mapOf(Source),
  sourcesOrder: arrayOf(string),
  studies: arrayOf(Study),
  studiesMatrix: mapOf(StudiesMatrixValue),
  isEnabled: boolean,
  hasStarted: optional(boolean)
});

module.exports = OverReviewDocShape;
