var MdaTable, MdaTableActions, MdaTableToolbar, MdaTablesView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

MdaTable = require('components/mda/tables/mda_table');

MdaTableActions = require('actions/mda_table_actions');

MdaTableToolbar = require('components/mda/tables/mda_table_toolbar');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = MdaTablesView = (function(_super) {
  __extends(MdaTablesView, _super);

  function MdaTablesView() {
    return MdaTablesView.__super__.constructor.apply(this, arguments);
  }

  MdaTablesView.prototype.container = '#page-container';

  MdaTablesView.prototype.id = 'mda-table';

  MdaTablesView.prototype.autoRender = true;

  MdaTablesView.prototype.initialize = function() {
    MdaTablesView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: MdaTableToolbar,
      props: {
        currentTableId: this.options.tableId,
        projectId: mediator.project.id
      }
    }));
    return this.enable(ReactComponent);
  };

  MdaTablesView.prototype.afterRender = function() {
    MdaTablesView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(MdaTable, MdaTableActions, this.options.tableId, {
      props: {
        tableId: this.options.tableId
      },
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  MdaTablesView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('QuestionsStore', 'QuestionGroupsStore', 'QuestionsListComponentStore', 'QuestionsStatusesStore', 'OrganizationsStore', 'OverarchingQuestionsStore', 'MdaTableStore', 'DiscardChangesStore');
    return MdaTablesView.__super__.dispose.apply(this, arguments);
  };

  return MdaTablesView;

})(View);
