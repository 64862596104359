var DocumentSectionsV2SectionDoc, MdaTableDoc, Migration, W, WrapTableRowsWithTableBodyInEpiditorContent, mediator, migrationName, wrapRowsWithBody,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

MdaTableDoc = require('lib/db_docs/mda_table_doc');

DocumentSectionsV2SectionDoc = require('lib/db_docs/document_sections_v2_section_doc');

W = require('when');

migrationName = 'wrap_table_rows_with_table_body_in_epiditor_content';

wrapRowsWithBody = function(table) {
  var body, rowsContent, _ref;
  rowsContent = R.path(['content'], table);
  if (((_ref = _.first(rowsContent)) != null ? _ref.type : void 0) === 'table_body' || _.isEmpty(rowsContent)) {
    return table;
  }
  body = [
    {
      type: "table_body",
      content: rowsContent
    }
  ];
  return R.assoc('content', body, table);
};

module.exports = WrapTableRowsWithTableBodyInEpiditorContent = (function(_super) {
  __extends(WrapTableRowsWithTableBodyInEpiditorContent, _super);

  function WrapTableRowsWithTableBodyInEpiditorContent() {
    WrapTableRowsWithTableBodyInEpiditorContent.__super__.constructor.call(this, false);
  }

  WrapTableRowsWithTableBodyInEpiditorContent.prototype.up = function(project, colls) {
    var adapter, projectId;
    WrapTableRowsWithTableBodyInEpiditorContent.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return W.all([
      MdaTableDoc.at(projectId).fetchMany()["catch"](function(err) {
        if (err.status !== 404) {
          throw err;
        }
        return [];
      }), DocumentSectionsV2SectionDoc.at(projectId).fetchMany()["catch"](function(err) {
        if (err.status !== 404) {
          throw err;
        }
        return [];
      })
    ]).then(function(_arg) {
      var contentPath, docs, sections, tables, wrapTableRowsWithBody, _ref;
      (_ref = _arg[0], tables = _ref.rows), sections = _arg[1];
      contentPath = ['content', 'doc', 'content'];
      docs = _.pluck(tables, 'doc').concat(sections);
      wrapTableRowsWithBody = function(contentArray) {
        if (!contentArray) {
          return;
        }
        return contentArray.map(function(content) {
          if (content.type === 'table') {
            return wrapRowsWithBody(content);
          } else if (R.path(['content'], content)) {
            return R.assocPath(['content'], wrapTableRowsWithBody(R.path(['content'], content)), content);
          } else {
            return content;
          }
        });
      };
      return _.compact(docs.map(function(doc) {
        var content;
        content = R.path(contentPath, doc);
        if (!content) {
          return null;
        }
        return R.assocPath(contentPath, wrapTableRowsWithBody(content), doc);
      }));
    }).then(function(docs) {
      var updatedDocs;
      if (_.isEmpty(docs)) {
        return W.resolve();
      }
      updatedDocs = _.map(docs, function(doc) {
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return WrapTableRowsWithTableBodyInEpiditorContent;

})(Migration);
