var ArrayOfType, GdtDocFieldType, TypeValidationError, array, arrayOf, arrayOfCheck, typeUtils, _ref,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

GdtDocFieldType = require('lib/db_docs/field_types/FieldType');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

_ref = require('lib/db_docs/field_types/built_in_types'), array = _ref.array, typeUtils = _ref.typeUtils;

ArrayOfType = (function(_super) {
  __extends(ArrayOfType, _super);

  function ArrayOfType() {
    return ArrayOfType.__super__.constructor.apply(this, arguments);
  }

  ArrayOfType.prototype.getItemType = function() {
    return this['_typeMeta'].ItemType;
  };

  ArrayOfType.prototype.defaults = function(providedData) {
    var ItemType, parsedAsArray;
    if (typeUtils.getValueType(providedData) === 'Array') {
      ItemType = this.getItemType();
      return this.parse(providedData.map(ItemType.defaults.bind(ItemType)));
    } else {
      parsedAsArray = this.parse(providedData);
      if (parsedAsArray instanceof TypeValidationError) {
        return parsedAsArray;
      } else {
        return this.defaults(parsedAsArray);
      }
    }
  };

  return ArrayOfType;

})(GdtDocFieldType);

arrayOfCheck = function(ItemType) {
  return function(values) {
    var val, valCheck, _i, _len;
    for (_i = 0, _len = values.length; _i < _len; _i++) {
      val = values[_i];
      if (val instanceof TypeValidationError) {
        return val;
      } else {
        valCheck = ItemType.check(val);
        if (valCheck instanceof TypeValidationError) {
          return new TypeValidationError({
            message: "Array validation fail: Item validation message: " + valCheck.message,
            validation: 'Array validation'
          });
        }
      }
    }
    return values;
  };
};

arrayOf = function(ItemType) {
  if (!(ItemType instanceof GdtDocFieldType)) {
    throw new Error('ItemType parameter must be an instance of `Type`');
  }
  return new ArrayOfType({
    name: "ArrayOf(" + ItemType.name + ")",
    check: typeUtils.checksProduct(array.check, arrayOfCheck(ItemType)),
    parse: function(v) {
      var checkResult, itemParsed, parsed, parsedAsArray;
      checkResult = this.check(v);
      if (!(checkResult instanceof TypeValidationError)) {
        return v;
      }
      if (typeUtils.getValueType(v) === 'Array') {
        parsed = v.map(ItemType.parse.bind(ItemType));
        return this.check(parsed);
      } else {
        parsedAsArray = array.parse(v);
        if (parsedAsArray instanceof TypeValidationError) {
          itemParsed = ItemType.parse(v);
          if (!(ItemType.check(itemParsed) instanceof TypeValidationError)) {
            return this.check([itemParsed]);
          } else {
            return checkResult;
          }
        } else {
          return this.parse(parsedAsArray);
        }
      }
    },
    typeDefault: [],
    _typeMeta: {
      ItemType: ItemType
    }
  });
};

arrayOf.typeConstructor = ArrayOfType;

module.exports = arrayOf;
