var Code, documentId, initial, shape, string;

shape = require('lib/db_docs/field_types/shape');

documentId = require('lib/db_docs/field_types/document_id');

string = require('lib/db_docs/field_types/built_in_types').string;

initial = require('lib/db_docs/field_types/type_decorators').initial;

Code = shape({
  id: documentId,
  codeType: string,
  value: string,
  name: initial('')(string)
});

module.exports = Code;
