CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Importance = require 'components/etd/soj_importance'
Summary = require 'components/etd/soj_summary'
SummaryPrintout = require 'components/etd/soj_summary_printout'
SummaryOfJudgementSectionNameCell = require 'components/etd/soj_section_name_cell'
Translation = require 'components/mixins/translation'

getCompleteOptionsList = (criterion) ->
  normalLength = 7
  options = criterion.get 'options', Immutable.List()
  additionalOptions = criterion.get 'additionalOptions', Immutable.List()

  isOptionsListComplete = (options.size + additionalOptions.size) >= normalLength
  completeOptionsList = if isOptionsListComplete
    options.concat additionalOptions
  else
    noOfMissingOptions = normalLength - options.size - additionalOptions.size
    missingOptionsList = Immutable.fromJS(new Array(noOfMissingOptions))
    options.concat missingOptionsList, additionalOptions

SummaryOfJudgementsSection = createReactClass
  displayName: 'SojSectionComponent'
  mixins: [Translation('es:recommendations.table'), CustomRenderMixin]

  _getSojValueFor: (sectionName) ->
    if @props.isConsensus
      @props.section.get("#{sectionName}Consensus") or @props.section.get sectionName
     else
      @props.section.get sectionName

  _getSelectedOption: ->
    if @props.isConsensus
      @props.criterion.get('selectedOptionConsensus') or @props.criterion.get 'selectedOption'
    else
      @props.criterion.get 'selectedOption'

  render: ->
    sectionName = @props.section.get('name')
    importance = @_getSojValueFor 'importance'
    selectedOption = @_getSelectedOption()
    rowClassName = classNames _.string.dasherize(@props.criterionId),
      'hidden-section': @props.sectionHidden

    <tr className={rowClassName}>
      <SummaryOfJudgementSectionNameCell
        displayCellContent={not @props.sectionHidden or @props.renderMode is 'printout'}
        renderMode={@props.renderMode}
        sectionHidden={@props.sectionHidden}
        sectionName={sectionName}
        onHideSection={@props.onHideSection}
      />
      {
        if @props.renderMode is 'printout'
          completeList = getCompleteOptionsList @props.criterion
          completeList.map (option, key) ->
            isChecked = selectedOption is option?.get 'value'
            <SummaryPrintout
              option={option}
              isChecked={isChecked}
              key={key}
            />
          .toList()
        else
          <Summary
            criterion={@props.criterion}
            hidden={@props.sectionHidden}
            selectedOption={selectedOption}
          />
      }
      {
        unless @props.renderMode is 'printout'
          <Importance
            editable={@props.editable}
            hidden={@props.sectionHidden}
            isConsensus={@props.isConsensus}
            value={importance}
            sectionKey={@props.sectionKey}
            sectionName={sectionName}
          />
      }
    </tr>

module.exports = SummaryOfJudgementsSection
