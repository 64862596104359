var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "";
},"3":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "\n  ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.viewTypeName : depth0), "sof_v4", {"name":"is","hash":{},"fn":this.program(4, data, depths),"inverse":this.program(6, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"footnotes-list\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.notes : depth0), {"name":"each","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n";
},"4":function(depth0,helpers,partials,data) {
  return "<br>";
  },"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "\n    <h4>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:footnotes.footnotes", {"name":"i18n","hash":{},"data":data})))
    + "</h4>\n";
},"8":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, buffer = "      <div>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depths[1] != null ? depths[1].activeWorkspace : depths[1]), "o_cochrane_0", {"name":"is","hash":{},"fn":this.program(9, data, depths),"inverse":this.program(11, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        <span>"
    + escapeExpression(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"note","hash":{},"data":data}) : helper)))
    + "</span>\n      </div>\n";
},"9":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <sup class=\"sup\">"
    + escapeExpression(((helper = (helper = helpers.counter || (depth0 != null ? depth0.counter : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"counter","hash":{},"data":data}) : helper)))
    + "</sup>\n";
},"11":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <span>"
    + escapeExpression(((helper = (helper = helpers.counter || (depth0 != null ? depth0.counter : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"counter","hash":{},"data":data}) : helper)))
    + ". </span>\n";
},"13":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "\n  ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.viewTypeName : depth0), "sof_v4", {"name":"is","hash":{},"fn":this.program(4, data, depths),"inverse":this.program(14, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"footnotes-list\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.references : depth0), {"name":"each","hash":{},"fn":this.program(16, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n";
},"14":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "\n    <h4>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:footnotes.references", {"name":"i18n","hash":{},"data":data})))
    + "</h4>\n";
},"16":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, buffer = "      <div>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depths[1] != null ? depths[1].activeWorkspace : depths[1]), "o_cochrane_0", {"name":"is","hash":{},"fn":this.program(17, data, depths),"inverse":this.program(19, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        <span>"
    + escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"text","hash":{},"data":data}) : helper)))
    + "</span>\n      </div>\n";
},"17":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <sup>"
    + escapeExpression(((helper = (helper = helpers.counter || (depth0 != null ? depth0.counter : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"counter","hash":{},"data":data}) : helper)))
    + "</sup>\n";
},"19":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <span>"
    + escapeExpression(((helper = (helper = helpers.counter || (depth0 != null ? depth0.counter : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"counter","hash":{},"data":data}) : helper)))
    + ".</span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.empty || (depth0 && depth0.empty) || helperMissing).call(depth0, (depth0 != null ? depth0.notes : depth0), {"name":"empty","hash":{},"fn":this.program(1, data, depths),"inverse":this.program(3, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.empty || (depth0 && depth0.empty) || helperMissing).call(depth0, (depth0 != null ? depth0.references : depth0), {"name":"empty","hash":{},"fn":this.program(1, data, depths),"inverse":this.program(13, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}