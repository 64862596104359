var DnDActions;

DnDActions = require('actions/react_dnd_actions');

module.exports = {
  dropSpec: {
    canDrop: function(props, monitor) {
      return monitor.getItem().gIndex !== props.gIndex;
    },
    drop: function(props, monitor) {
      var dropAreaPosition;
      dropAreaPosition = [props.gIndex];
      return DnDActions.dropAreaDrop({
        type: monitor.getItemType(),
        dropAreaPosition: dropAreaPosition
      });
    }
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget(),
      isTargetHovered: monitor.isOver() && monitor.canDrop()
    };
  }
};
