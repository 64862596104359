OverReviewActions = require 'actions/over_review_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
ReviewedOutcomesList = require 'components/over_review/reviewed_outcomes_list'
SourcesList = require 'components/over_review/sources_list'
{ string, arrayOf, array, object } = PropTypes

OverReviewInitialStep = createReactClass
  displayName: 'OverReviewInitialStep'

  propTypes:
    editingSourceId: string
    questionId: string.isRequired
    reviewedOutcomes: array
    sources: object.isRequired
    sourcesOrder: arrayOf(string).isRequired

  mixins: [
    CustomRenderMixin
    Translation('over_review:module')
  ]

  handleOverReviewStart: ->
    OverReviewActions.startOverReview @props.questionId

  render: ->
    { editingSourceId, questionId, reviewedOutcomes, sourcesOrder, sources, studies } = @props

    <div>
      <h2 className='module-title'>{@i18n 'title'}</h2>
        <div className='module-intro'>
          {@i18n 'intro_text'}
        </div>
        <div className='steps-container'>
          <div className='step'>
            <div className='step-title'>
              {@i18n 'step_one_title'}
            </div>
            <div className='step-items'>
              <ReviewedOutcomesList
                questionId={questionId}
                reviewedOutcomes={reviewedOutcomes}
              />
            </div>
          </div>
          <br />
          <div className='step'>
            <div className='step-title'>
              {@i18n 'step_two_title'}
            </div>
            <div className='step-items'>
              <SourcesList
                questionId={questionId}
                sources={sources}
                sourcesOrder={sourcesOrder}
                editingSourceId={editingSourceId}
              />
            </div>
          </div>
        </div>
        <div className='buttons-row'>
          <button
            className='btn'
            disabled={_.isEmpty(reviewedOutcomes) or _.isEmpty(sourcesOrder)}
            onClick={@handleOverReviewStart}
          >
            {@i18n '/actions.create'}
          </button>
        </div>
    </div>

module.exports = OverReviewInitialStep
