Button = require 'components/common/button'
Translation = require 'components/mixins/translation'
DecisionAidsList = require 'components/decision_aid/decision_aids_list'
CreateDecisionAid = require 'components/decision_aid/create_decision_aid'
DecisionAidDetails = require 'components/decision_aid/decision_aids_details'
ConnectStore = require 'components/enhancers/connect_store'
DecisionAidsStore = require 'stores/decision_aids_store'
Spinner = require 'components/common/spinner'


storeConnector =
  DecisionAidsStore: (Store) ->
    isFetching: Store.isFetching()
    decisionAids: Store.getDecisionAids()
    editingDecisionAidId: Store.getEditingDecisionAidId()
    editingChaptersOrder: Store.getEditingChaptersOrder()
    editModalVisible: Store.getEditModalVisible()
    expandedChapters: Store.getExpandedChapters()

DecisionAids = createReactClass
  displayName: 'DecisionAids'

  mixins: [Translation('')]

  propTypes:
    screen: PropTypes.oneOf(['list', 'details', 'create']).isRequired
    decisionAids: PropTypes.instanceOf(Immutable.List).isRequired
    isFetching: PropTypes.bool.isRequired
    editingDecisionAid: PropTypes.instanceOf(Immutable.Map)
    editingChaptersOrder: PropTypes.instanceOf(Immutable.List)

  render: ->
    { decisionAids, editingDecisionAidId, isFetching } = @props

    <div className='decision-aids-module'>
      {if @props.isFetching
        <Spinner />
      else
        switch @props.screen
          when 'create' then <CreateDecisionAid />
          when 'details'
            <DecisionAidDetails
              decisionAid={@props.decisionAids.find (da) => da.get('_id') is @props.decisionAidId}
              isEditingHeader={editingDecisionAidId is @props.decisionAidId}
              editingChaptersOrder={@props.editingChaptersOrder}
              editModalVisible={@props.editModalVisible}
              expandedChapters={@props.expandedChapters}
            />
          when 'list'
            <DecisionAidsList
              decisionAids={decisionAids}
              editingDecisionAidId={editingDecisionAidId}
            />
      }
    </div>

module.exports = ConnectStore DecisionAids, DecisionAidsStore, storeConnector
