Modal = require 'components/common/modal'
Etd = require 'components/etd/etd'

MultiComparisonEtdSourceModal = createReactClass
  displayName: 'MultiComparisonEtdSourceModal'

  propTypes:
    questionId: PropTypes.string.isRequired
    intervention: PropTypes.string.isRequired
    comparison: PropTypes.string.isRequired
    onClose: PropTypes.func.isRequired

  render: ->
    <Modal
      isOpen={true}
      closeButton={true}
      onClose={@props.onClose}
    >
      <div id="recommendations">
        <Etd
          questionId={@props.questionId}
          renderMode="mcSource"
          intervention={@props.intervention}
          comparison={@props.comparison}
        />
      </div>
    </Modal>

module.exports = MultiComparisonEtdSourceModal
