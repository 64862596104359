var MembersActions, OutcomesGenerationActions, OutcomesGenerationService, W, alt, errorHandler, fetchOrCreateComparisonQuestionGroups, fetchOutcomesGeneration, fetchOutcomesGenerationDoc, mediator, outcomesAPI, outcomesGenerationDocId;

alt = require('alt');

W = require('when');

mediator = require('mediator');

outcomesGenerationDocId = require('lib/doc_ids').OUTCOMES_GENERATION;

errorHandler = require('lib/app_utils').errorHandler;

fetchOrCreateComparisonQuestionGroups = require('actions/async/comparisons_question_groups').fetchOrCreateComparisonQuestionGroups;

fetchOutcomesGeneration = require('actions/async/outcomes_generation').fetchOutcomesGeneration;

MembersActions = require('actions/members_actions');

outcomesAPI = require('actions/async/outcomes_generation');

OutcomesGenerationService = require('lib/services/outcomes_generation_service');

fetchOutcomesGenerationDoc = function() {
  return fetchOutcomesGeneration(mediator.project.id, outcomesGenerationDocId);
};

OutcomesGenerationActions = (function() {
  function OutcomesGenerationActions() {
    this.generateActions('fetchSuccess', 'fetchError', 'pouchFetchSuccess', 'pouchFetchError', 'pouchSaveSuccess', 'pouchSaveError', 'sendOutcomesSuccess', 'sendOutcomesError', 'fetchResultsSuccess', 'fetchResultsError', 'closeStepSuccess', 'closeStepError', 'moveApprovedOutcomesSuccess', 'moveApprovedOutcomesError', 'updateQuestionGroup', 'deleteQuestionGroup', 'releaseQuestionFromGroup', 'addOutcome', 'editOutcome', 'updateOutcome', 'undoItemMovement', 'createQuestionGroup', 'changeTab', 'saveAdminComment', 'updateOutcomeImportance', 'confirmUpdateNotification', 'showCommentsExport', 'hideCommentsExport', 'setDisagreementResolved', 'saveTodos');
  }

  OutcomesGenerationActions.prototype.fetch = function() {
    this.dispatch();
    MembersActions.fetch();
    return W.sequence([fetchOrCreateComparisonQuestionGroups, fetchOutcomesGenerationDoc]).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  OutcomesGenerationActions.prototype.deleteOutcome = function(name) {
    return this.actions.updateOutcome({
      name: name,
      deleteOutcome: true
    });
  };

  OutcomesGenerationActions.prototype.sendOutcomes = function(params) {
    outcomesAPI.start(params).then(function() {
      return outcomesAPI.getResults(params);
    }).then((function(_this) {
      return function(results) {
        return _this.actions.sendOutcomesSuccess(results);
      };
    })(this))["catch"](this.actions.sendOutcomesError);
    return this.dispatch(params);
  };

  OutcomesGenerationActions.prototype.fetchResults = function(params) {
    this.dispatch();
    return outcomesAPI.getResults(params).then(this.actions.fetchResultsSuccess)["catch"](this.actions.fetchResultsError);
  };

  OutcomesGenerationActions.prototype.closeStep = function(params) {
    this.dispatch();
    return this.actions.fetchResults(params).then((function(_this) {
      return function() {
        return outcomesAPI.closeStep(params).then(_this.actions.closeStepSuccess)["catch"](_this.actions.closeStepError);
      };
    })(this));
  };

  OutcomesGenerationActions.prototype.moveApprovedOutcomes = function(projectId, questionGroups) {
    var service;
    this.dispatch();
    service = new OutcomesGenerationService({
      comparisonQuestions: mediator.questions
    });
    return service.moveOutcomesToComparisons(questionGroups).then((function(_this) {
      return function(_updatedQuestions) {
        _this.actions.moveApprovedOutcomesSuccess();
        return _this.actions.closeStep({
          projectId: projectId
        });
      };
    })(this))["catch"](this.actions.moveApprovedOutcomesError);
  };

  return OutcomesGenerationActions;

})();

module.exports = alt.createActions(OutcomesGenerationActions);
