var FIELDS_MAP, REST_FIELDS, YES_NO_TEXT_FIELDS, exportGuideline, exportGuidelineFields, exportYesNoTextField, rejectNulls, renderEditorValue, _ref;

_ref = require('lib/services/covid_exporter/utils'), exportGuidelineFields = _ref.exportGuidelineFields, renderEditorValue = _ref.renderEditorValue;

YES_NO_TEXT_FIELDS = ['guidelineGroupDetails'];

FIELDS_MAP = {
  'fullReference': 'citation',
  'targetAudience': 'users'
};

REST_FIELDS = ['title', 'fullReference', 'publicationDate', 'version', 'source', 'sourceDocumentLink', 'kspLink', 'isbn', 'doi', 'pmid', 'declarationOfInterest', 'latestLiteratureSearchDate', 'gradingEvidenceMethod', 'guidelineNoId', 'intent', 'subIntent', 'targetAudience', 'field'];

exportYesNoTextField = function(value) {
  var showTextInput, text;
  if (value == null) {
    return void 0;
  }
  showTextInput = value.showTextInput, text = value.text;
  if (showTextInput) {
    if (_.isString(text)) {
      return text;
    } else {
      return renderEditorValue(text);
    }
  } else {
    return 'no';
  }
};

rejectNulls = function(value) {
  if (value == null) {
    return void 0;
  }
  return value;
};

exportGuideline = function(projectId, guidelineDoc) {
  var publicationYear, restFields, yesNoTextFields;
  publicationYear = guidelineDoc['publicationDate'] ? moment(guidelineDoc['publicationDate']).format('YYYY') : void 0;
  yesNoTextFields = exportGuidelineFields(exportYesNoTextField, YES_NO_TEXT_FIELDS, FIELDS_MAP, guidelineDoc);
  restFields = exportGuidelineFields(rejectNulls, REST_FIELDS, FIELDS_MAP, guidelineDoc);
  return _.extend({
    '@id': projectId,
    publicationYear: publicationYear
  }, yesNoTextFields, restFields);
};

module.exports = exportGuideline;
