AdolopmentTypeOfRecommendation = require 'components/etd/adolopment_type_of_recommendation'
AdolopmentDataActions = require 'actions/adolopment_data_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdSectionTitle = require 'components/etd/etd_section_title'
PanelVoiceResultsRow = require 'components/etd/panel_voice_etd/panel_voice_results_row'
RecommendationEditModal = require 'components/etd/panel_voice_etd/recommendation_edit_modal'
Switcher = require 'components/common/switcher'
Translation = require 'components/mixins/translation'
TypeOfRecommendation = require 'components/etd/type_of_recommendation'
{ getSectionEditToggleHandler } = require 'lib/panel_voice_helper'

{string, instanceOf, func, bool} = PropTypes
{ CellWithTitle, SectionRow, Card, CardContent } = ReactComponents

TypeOfRecommendationWithPanelVoiceResults = createReactClass
  displayName: "TypeOfRecommendationWithPanelVoiceResults"

  propTypes:
    adolopments: instanceOf(Immutable.Map)
    renderMode: string.isRequired,
    votingResults: instanceOf(Immutable.Map).isRequired,
    editingSectionId: string,
    highlightToPanelMembers: bool,
    memberNamesMap: instanceOf(Immutable.Map).isRequired,
    getAdminVotingSummary: func.isRequired,
    etdId: string.isRequired,
    etdTab: string.isRequired
    sectionId: string.isRequired,
    etdViewSettings: instanceOf(Immutable.Map)
    overarchingQuestionData: instanceOf(Immutable.Map)

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  getDefaultProps: ->
    adolopments: Immutable.Map()
    etdViewSettings: Immutable.Map()
    overarchingQuestionData: Immutable.Map()

  toggleAdolopmentForSection: ->
    AdolopmentDataActions.toggleAdolopmentForSection @props.etdId, 'conclusions', @props.sectionId

  render: ->
    recommendationProps = _(@props).pick(
      ['etdId', 'renderMode', 'sectionId', 'section', 'votingResults',
        'highlightToPanelMembers', 'etdViewSettings', 'overarchingQuestionData']
    )

    {
      activeJudgmentOptionBySection,
      activeResultsViewTypeBySection
      adolopments,
      adolopmentData,
      editingSectionId,
      etdId,
      etdTab,
      getAdminVotingSummary
      memberNamesMap,
      renderMode,
      section,
      sectionId,
      templateId,
      votedEtdData,
      votingResults,
    } = @props

    sectionVotingResults = votingResults.getIn ['sections', sectionId]
    votingStatus = votingResults.get 'status'
    closedOrTestVoting = votingStatus in ['closed', 'test']

    withAdolopment = adolopmentData.getIn [sectionId, 'withAdolopment'], false

    _.extend recommendationProps,
      editable: closedOrTestVoting and @props.etdTab is 'workspace'
      onEditClick: if closedOrTestVoting then null else getSectionEditToggleHandler sectionId
      contentColSpan: 2

    sectionToBeDisplayedAsCurrent = if adolopments.isEmpty() or withAdolopment
      section
    else
      adolopments.last().getIn(['templateData', 'conclusions', 'sections', sectionId])

    <div className='recommendation-section'>
      <EtdSectionTitle renderMode={renderMode} title={@i18n 'type_of_recommendation'} />
      {not adolopments.isEmpty() and renderMode isnt 'printout' and
        <div className="type_of_recommendation__adolopment-switcher">
          <Switcher
            checked={withAdolopment}
            onChange={@toggleAdolopmentForSection}
            buttonText={@i18n '/es:recommendations.table-view-options.toggle_adolopment'}
          />
        </div>
      }
      {not adolopments.isEmpty() and withAdolopment and
        <AdolopmentTypeOfRecommendation
          adolopments={adolopments}
          {...recommendationProps}
          className='recommendation-table-static'
        />
      }
      <TypeOfRecommendation
        section={sectionToBeDisplayedAsCurrent}
        {...recommendationProps}
        className='recommendation-table-static'
      />
      <table className="standard-table type-of-recommendation-table-static">
        <tbody>
          <PanelVoiceResultsRow
            activeJudgmentOptionBySection={activeJudgmentOptionBySection}
            activeResultsViewTypeBySection={activeResultsViewTypeBySection}
            etdId={etdId}
            etdPart='recommendation'
            etdTab={etdTab}
            getAdminVotingSummary={getAdminVotingSummary}
            key={"#{sectionId}-panel-voice-results"}
            memberNamesMap={memberNamesMap}
            renderMode={renderMode}
            responseColSpan={1}
            sectionId={sectionId}
            templateId={templateId}
            votedEtdData={votedEtdData}
            votingResults={votingResults}
          />
          {if editingSectionId is sectionId
            <tr>
              <td>
                <RecommendationEditModal {...recommendationProps}>
                  <TypeOfRecommendation
                    section={sectionToBeDisplayedAsCurrent}
                    {...recommendationProps}
                    editable
                    noAutoSave
                    className='recommendation-table-static'
                  />
                </RecommendationEditModal>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

module.exports = TypeOfRecommendationWithPanelVoiceResults
