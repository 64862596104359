AdolopmentSojRow = require 'components/etd/adolopment_soj_row'
EtdSectionTitle = require 'components/etd/etd_section_title'
UserProjectDataActions = require 'actions/user_project_data_actions'
{ Card, CardContent } = ReactComponents

toggleSection = (etdId, sectionName) -> =>
  UserProjectDataActions.toggleSojSection etdId, sectionName

AdolopmentSummaryOfJudgements = ({
  adolopments
  adolopmentData
  criterions
  etdId
  etdViewSettings
  editable
  hiddenSections
  isConsensus
  i18n
  renderMode
  sections
}) ->

  adolopmentsCriteria = adolopments.map (adolopment) ->
    adolopment.getIn ['templateData', 'assessment', 'criteria']

  adolopmentSections = adolopments.map (adolopment) ->
    adolopment.getIn ['templateData', 'assessment', 'sections']

  showOriginalJudgement = etdViewSettings.get('showOriginalJudgement', true)

  showAdolopmentJudgement = etdViewSettings.get('showAdolopmentJudgement', true)

  adolopment = adolopmentsCriteria.last()

  <div className="soj-section">
    <EtdSectionTitle
      renderMode={renderMode} title={i18n 'summary_of_judgements'}
    />
    <Card>
      <CardContent>
        <table className="summary-of-judgements adolopments">
          <thead className="header">
            <tr>
              <th className="criteria">{i18n 'criteria'}</th>
              <th key={"original"} className="option">
                <div className="section-title-original">{i18n 'original'}</div>
              </th>
              <th key={"importance"} className="importance">
                {i18n 'importance_for_decision'}
              </th>
              <th className="option">
                <div className="section-title-adolopment">{i18n 'adolopment'}</div>
              </th>
              <th className="importance">{i18n 'importance_for_decision'}</th>
            </tr>
          </thead>
          <tbody>
            {sections.map (section, sectionId) ->
              criterionId = section.get 'criterionId'
              sectionHidden = hiddenSections.get(criterionId, false)
              withAdolopment = adolopmentData.getIn [criterionId, 'withAdolopment'], false
              <AdolopmentSojRow
                adolopmentsCriteria={adolopmentsCriteria}
                adolopmentSections={adolopmentSections}
                criterion={criterions.get criterionId}
                criterionId={criterionId}
                editable={editable}
                isConsensus={isConsensus}
                i18n={i18n}
                key={sectionId}
                onHideSection={toggleSection etdId, criterionId}
                renderMode={renderMode}
                sectionKey={sectionId}
                section={section}
                sectionHidden={sectionHidden}
                showOriginalJudgement={showOriginalJudgement}
                showAdolopmentJudgement={showAdolopmentJudgement}
                withAdolopment={withAdolopment}
              />
            .toList()
            }
          </tbody>
        </table>
      </CardContent>
    </Card>
  </div>

AdolopmentSummaryOfJudgements.propTypes = {
  adolopments: PropTypes.instanceOf(Immutable.Map).isRequired,
  criterions: PropTypes.instanceOf(Immutable.Map).isRequired,
  editable: PropTypes.bool.isRequired,
  etdId: PropTypes.string.isRequired,
  hiddenSections: PropTypes.instanceOf(Immutable.Map).isRequired,
  isConsensus: PropTypes.bool.isRequired,
  i18n: PropTypes.func.isRequired,
  renderMode: PropTypes.string.isRequired,
  sections: PropTypes.instanceOf(Immutable.Map).isRequired,
}

module.exports = AdolopmentSummaryOfJudgements
