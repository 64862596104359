AssessmentSectionRow = require 'components/etd/assessment_section_row'
AdolopmentSectionSeparator = require 'components/etd/adolopment_section_separator'
UserProjectDataActions = require 'actions/user_project_data_actions'
{ getAdolopmentSectionProps } = require 'lib/adolopments_helper'

toggleOriginalSection = (etdId, sectionId) -> ->
  UserProjectDataActions.toggleOriginalDataSection etdId, sectionId

toggleAdolopmentSection = (etdId, sectionId) -> ->
  UserProjectDataActions.toggleAdolopmentDataSection etdId, sectionId

AssessmentAdolopments = ({
  activeTab
  adolopments
  additionalConsiderationsVisible
  criticalOutcomes
  etdId
  etdViewSettings
  i18n
  outcomes
  questionType
  sectionId
  sectionName
  sectionData
  renderMode
  templateId
}) ->
  adolopment = adolopments.last()
  sectionProps = getAdolopmentSectionProps adolopment, sectionId, sectionData
  [
    <AdolopmentSectionSeparator
      key={"#{sectionId}-original-separator"}
      type="assessment"
      section="original"
      i18n={i18n}
      sectionColSpan={2}
      onClick={toggleOriginalSection(etdId, sectionId)}
    />
    if etdViewSettings.getIn(['collapsedOriginalSections', sectionId], false) is false
      <AssessmentSectionRow
        activeTab={activeTab}
        additionalConsiderationsVisible={additionalConsiderationsVisible}
        criticalOutcomes={criticalOutcomes}
        key={"#{sectionId}-#{adolopment.get('_id')}-original"}
        outcomes={outcomes}
        questionType={questionType}
        readOnly={true}
        renderMode={renderMode}
        sectionId={sectionId}
        sectionKey={"#{sectionId}-#{adolopment.get('_id')}-original"}
        sectionName={sectionName}
        templateId={templateId}
        showJudgement={etdViewSettings.get('showOriginalJudgement', true)}
        {...sectionProps}
      />

    <AdolopmentSectionSeparator
      i18n={i18n}
      key={"#{sectionId}-adolopment-separator"}
      onClick={toggleAdolopmentSection(etdId, sectionId)}
      section="adolopment"
      sectionColSpan={2}
      type="assessment"
    />
  ]

module.exports = AssessmentAdolopments
