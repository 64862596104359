var AuthenticationScreen, AuthenticationView, EnterpriseAuthenticationScreen, KeyCloakSessionExpiredNotification, ReactComponent, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

AuthenticationScreen = require('components/auth/authentication_screen');

KeyCloakSessionExpiredNotification = require('components/auth/keycloak_session_expired_notification');

EnterpriseAuthenticationScreen = require('components/auth/enterprise_authentication_screen');

mediator = require('mediator');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

module.exports = AuthenticationView = (function(_super) {
  __extends(AuthenticationView, _super);

  function AuthenticationView() {
    return AuthenticationView.__super__.constructor.apply(this, arguments);
  }

  AuthenticationView.prototype.container = '#gdt-app';

  AuthenticationView.prototype.autoRender = true;

  AuthenticationView.prototype._stopInputsPropagation = false;

  AuthenticationView.prototype.initialize = function() {
    AuthenticationView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  AuthenticationView.prototype.afterRender = function() {
    AuthenticationView.__super__.afterRender.apply(this, arguments);
    if (mediator.services.switches.isServerSwitchOn('loginWithKeycloakOnly')) {
      return this.renderSimpleComponent(KeyCloakSessionExpiredNotification);
    } else {
      return this.renderScreen();
    }
  };

  AuthenticationView.prototype.renderScreen = function() {
    var ScreenComponent, props;
    ScreenComponent = window.gdt.enterpriseId != null ? EnterpriseAuthenticationScreen : AuthenticationScreen;
    props = _.extend({}, this.options, (function() {
      switch (window.gdt.enterpriseId) {
        case 'ec':
          return {
            administratorEmail: 'jrc-cancer-policy-support@ec.europa.eu'
          };
        case 'elsevier':
          return {
            administratorEmail: 'k.brunnhuber@elsevier.com'
          };
        case 'conitec':
        case 'merck':
        case 'acp':
        case 'covid':
        case 'ash':
        case 'minsal':
        case 'cadth':
        case 'tb':
        case 'mdg':
          return {
            administratorEmail: 'support@gradepro.org'
          };
        default:
          return {};
      }
    })());
    return this.renderSimpleComponent(ScreenComponent, props);
  };

  AuthenticationView.prototype.dispose = function() {
    this.unmountComponent();
    return AuthenticationView.__super__.dispose.apply(this, arguments);
  };

  return AuthenticationView;

})(View);
