Translation = require 'components/mixins/translation'

FeedbackLegend = createReactClass

  displayName: 'FeedbackLegend'
  mixins: [Translation('')]

  render: ->
    <div className="legend">
      <div className="legend-item">
        <div className="
          legend-item__box
          panel-voice-feedback-matrix__default-status
          panel-voice-feedback-matrix__finished-status"
        />
        <div className="description">{@i18n 'voting:panel_voice.finished'}</div>
      </div>
      <div className="legend-item">
        <div className="
          legend-item__box
          panel-voice-feedback-matrix__default-status
          panel-voice-feedback-matrix__sent-status"
        />
        <div className="description">{@i18n 'voting:panel_voice.sent'}</div>
      </div>
      <div className="legend-item">
        <div className="
          legend-item__box
          panel-voice-feedback-matrix__default-status"
        />
        <div className="description">{@i18n 'voting:panel_voice.unsent'}</div>
      </div>
      <div className="legend-item">
        <div className="
          legend-item__box
          panel-voice-feedback-matrix__default-status
          exclamation-mark"
        />
        <div className="description">{@i18n 'voting:panel_voice.coi'}</div>
      </div>
    </div>

module.exports = FeedbackLegend
