var ComparisonsQuestionGroupsDocShape, Group, arrayOf, docShape, mapOf, number, oneOf, string, _ref;

docShape = require('lib/db_docs/field_types/doc_shape');

arrayOf = require('lib/db_docs/field_types/array_of');

mapOf = require('lib/db_docs/field_types/map_of');

oneOf = require('lib/db_docs/field_types/one_of');

_ref = require('lib/db_docs/field_types/built_in_types'), number = _ref.number, string = _ref.string;

Group = docShape({
  caption: string
});

ComparisonsQuestionGroupsDocShape = docShape({
  $timestamp: number,
  groups: arrayOf(Group),
  mapping: mapOf(string),
  questionsOrdering: mapOf(number)
});

module.exports = ComparisonsQuestionGroupsDocShape;
