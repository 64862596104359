var DBEPPublicationActions, DBEPPublicationStore, INITIAL_DIALOG_STATE, INITIAL_STATE, alt, filterGoodPracticeStatement, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

DBEPPublicationActions = require('actions/dbep_publication_actions');

filterGoodPracticeStatement = require('lib/questions_helper').filterGoodPracticeStatement;

INITIAL_DIALOG_STATE = Immutable.fromJS({
  meta: {
    isModalOpened: false,
    isValidating: false,
    validationError: null,
    isPublishing: false,
    step: 'selectQuestions',
    mode: 'preview',
    type: 'multi'
  },
  progress: 0,
  selectedQuestions: Immutable.List(),
  profiles: Immutable.List(),
  validationResult: {
    ok: false,
    errors: {},
    link: ''
  },
  publishResult: {
    ok: false,
    link: ''
  },
  topAccepted: false,
  validationOverride: false
});

INITIAL_STATE = Immutable.fromJS({
  common: {
    meta: {
      isFetchingQuestions: false,
      isFetchingStatuses: false
    },
    questions: Immutable.Map(),
    questionGroups: Immutable.Map(),
    questionStatuses: Immutable.Map()
  },
  dialog: INITIAL_DIALOG_STATE,
  table: {
    searchText: '',
    statusFilter: 'all'
  }
});

DBEPPublicationStore = (function() {
  function DBEPPublicationStore() {
    this.state = INITIAL_STATE;
    this.bindActions(DBEPPublicationActions);
    this.exportPublicMethods({
      isFetchingQuestions: this.isFetchingQuestions,
      isFetchingStatuses: this.isFetchingStatuses,
      getQuestions: this.getQuestions,
      getQuestionGroups: this.getQuestionGroups,
      getQuestionStatuses: this.getQuestionStatuses,
      getMode: this.getMode,
      getType: this.getType,
      getCurrentStep: this.getCurrentStep,
      isModalOpened: this.isModalOpened,
      isValidating: this.isValidating,
      getSelectedQuestions: this.getSelectedQuestions,
      getProgressBarPercentage: this.getProgressBarPercentage,
      getValidationResult: this.getValidationResult,
      getValidationError: this.getValidationError,
      getProfiles: this.getProfiles,
      isPublishing: this.isPublishing,
      areTOPAccepted: this.areTOPAccepted,
      areErrorsOverridden: this.areErrorsOverridden,
      getPublishResult: this.getPublishResult,
      getTableSearchText: this.getTableSearchText,
      getTableStatusFilter: this.getTableStatusFilter
    });
  }

  DBEPPublicationStore.prototype.onOpenModal = function(mode) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['dialog', 'meta', 'isModalOpened'], true);
      s.setIn(['dialog', 'meta', 'mode'], mode);
      return s.setIn(['dialog', 'meta', 'type'], 'multi');
    }));
  };

  DBEPPublicationStore.prototype.onOpenModalForOneQuestion = function(_arg) {
    var mode, questionId;
    mode = _arg.mode, questionId = _arg.questionId;
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['dialog', 'meta', 'isModalOpened'], true);
      s.setIn(['dialog', 'meta', 'mode'], mode);
      s.setIn(['dialog', 'meta', 'type'], 'single');
      s.setIn(['dialog', 'meta', 'step'], 'publication');
      s.setIn(['dialog', 'meta', 'isValidating'], true);
      s.setIn(['dialog', 'selectedQuestions'], Immutable.fromJS([questionId]));
      return s.setIn(['dialog', 'progress'], 0);
    }));
  };

  DBEPPublicationStore.prototype.onCloseModal = function() {
    var timeoutFn;
    this.setState(this.state.setIn(['dialog', 'meta', 'isModalOpened'], false));
    timeoutFn = (function(_this) {
      return function() {
        return _this.setState(_this.state.set('dialog', INITIAL_DIALOG_STATE));
      };
    })(this);
    return setTimeout(timeoutFn, 200);
  };

  DBEPPublicationStore.prototype.onFetch = function() {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['common', 'meta', 'isFetchingQuestions'], true);
      return s.setIn(['common', 'meta', 'isFetchingStatuses'], true);
    }));
  };

  DBEPPublicationStore.prototype.onFetchQuestionsSuccess = function(_arg) {
    var questionGroups, questions;
    questions = _arg.questions, questionGroups = _arg.questionGroups;
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['common', 'meta', 'isFetchingQuestions'], false);
      s.setIn(['common', 'questions'], Immutable.fromJS(questions));
      return s.setIn(['common', 'questionGroups'], Immutable.fromJS(questionGroups));
    }));
  };

  DBEPPublicationStore.prototype.onFetchStatusesSuccess = function(questionStatuses) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['common', 'meta', 'isFetchingStatuses'], false);
      return s.setIn(['common', 'questionStatuses'], Immutable.fromJS(questionStatuses));
    }));
  };

  DBEPPublicationStore.prototype.onFetchQuestionsError = function() {
    return this.setState(this.state.set('dialog', INITIAL_DIALOG_STATE));
  };

  DBEPPublicationStore.prototype.onValidate = function() {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['dialog', 'meta', 'step'], 'publication');
      s.setIn(['dialog', 'meta', 'isValidating'], true);
      return s.setIn(['dialog', 'progress'], 0);
    }));
  };

  DBEPPublicationStore.prototype.onValidationSuccess = function(validationResult) {
    var profiles;
    profiles = validationResult.profiles;
    validationResult = _.omit(validationResult, 'profiles');
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['dialog', 'meta', 'isValidating'], false);
      s.setIn(['dialog', 'validationResult'], Immutable.fromJS(validationResult));
      return s.setIn(['dialog', 'profiles'], Immutable.fromJS(profiles));
    }));
  };

  DBEPPublicationStore.prototype.onValidationError = function(err) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['dialog', 'meta', 'isValidating'], false);
      return s.setIn(['dialog', 'meta', 'validationError'], err);
    }));
  };

  DBEPPublicationStore.prototype.onUpdateSelectedQuestions = function(newSelectedQuestions) {
    return this.setState(this.state.setIn(['dialog', 'selectedQuestions'], newSelectedQuestions));
  };

  DBEPPublicationStore.prototype.onSendPreview = function() {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['dialog', 'meta', 'step'], 'publication');
      return s.setIn(['dialog', 'meta', 'isValidating'], true);
    }));
  };

  DBEPPublicationStore.prototype.onSendPreviewSuccess = function(validationResult) {
    var profiles;
    profiles = validationResult.profiles;
    validationResult = _.omit(validationResult, 'profiles');
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['dialog', 'meta', 'isValidating'], false);
      s.setIn(['dialog', 'validationResult'], Immutable.fromJS(validationResult));
      return s.setIn(['dialog', 'profiles'], Immutable.fromJS(profiles));
    }));
  };

  DBEPPublicationStore.prototype.onSendPreviewError = function(err) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['dialog', 'meta', 'isValidating'], false);
      return s.setIn(['dialog', 'meta', 'validationError'], err);
    }));
  };

  DBEPPublicationStore.prototype.onUpdateProgress = function() {
    var currentProgress;
    currentProgress = this.state.getIn(['dialog', 'progress']);
    return this.setState(this.state.setIn(['dialog', 'progress'], currentProgress + 1));
  };

  DBEPPublicationStore.prototype.onToggleValidationOverride = function() {
    var previousValue;
    previousValue = this.state.getIn(['dialog', 'validationOverride']);
    return this.setState(this.state.setIn(['dialog', 'validationOverride'], !previousValue));
  };

  DBEPPublicationStore.prototype.onToggleTOPAccepted = function() {
    var previousValue;
    previousValue = this.state.getIn(['dialog', 'topAccepted']);
    return this.setState(this.state.setIn(['dialog', 'topAccepted'], !previousValue));
  };

  DBEPPublicationStore.prototype.onPublish = function() {
    return this.setState(this.state.setIn(['dialog', 'meta', 'isPublishing'], true));
  };

  DBEPPublicationStore.prototype.onPublishSuccess = function(publishResult) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['dialog', 'meta', 'isPublishing'], false);
      return s.setIn(['dialog', 'publishResult'], Immutable.fromJS(publishResult));
    }));
  };

  DBEPPublicationStore.prototype.onPublishError = function() {
    return this.setState(this.state.set('dialog', INITIAL_DIALOG_STATE));
  };

  DBEPPublicationStore.prototype.onSearchOnTable = function(text) {
    return this.setState(this.state.setIn(['table', 'searchText'], text));
  };

  DBEPPublicationStore.prototype.onChangeStatusFilterOnTable = function(status) {
    return this.setState(this.state.setIn(['table', 'statusFilter'], status));
  };

  DBEPPublicationStore.prototype.getQuestions = function() {
    return this.state.getIn(['common', 'questions'], Immutable.Map()).filterNot(filterGoodPracticeStatement);
  };

  DBEPPublicationStore.prototype.getQuestionGroups = function() {
    return this.state.getIn(['common', 'questionGroups']);
  };

  DBEPPublicationStore.prototype.getQuestionStatuses = function() {
    return this.state.getIn(['common', 'questionStatuses']);
  };

  DBEPPublicationStore.prototype.isFetchingQuestions = function() {
    return this.state.getIn(['common', 'meta', 'isFetchingQuestions']);
  };

  DBEPPublicationStore.prototype.isFetchingStatuses = function() {
    return this.state.getIn(['common', 'meta', 'isFetchingStatuses']);
  };

  DBEPPublicationStore.prototype.getMode = function() {
    return this.state.getIn(['dialog', 'meta', 'mode']);
  };

  DBEPPublicationStore.prototype.getType = function() {
    return this.state.getIn(['dialog', 'meta', 'type']);
  };

  DBEPPublicationStore.prototype.getCurrentStep = function() {
    return this.state.getIn(['dialog', 'meta', 'step']);
  };

  DBEPPublicationStore.prototype.isModalOpened = function() {
    return this.state.getIn(['dialog', 'meta', 'isModalOpened']);
  };

  DBEPPublicationStore.prototype.getSelectedQuestions = function() {
    return this.state.getIn(['dialog', 'selectedQuestions']);
  };

  DBEPPublicationStore.prototype.isValidating = function() {
    return this.state.getIn(['dialog', 'meta', 'isValidating']);
  };

  DBEPPublicationStore.prototype.getValidationError = function() {
    return this.state.getIn(['dialog', 'meta', 'validationError']);
  };

  DBEPPublicationStore.prototype.getValidationResult = function() {
    return this.state.getIn(['dialog', 'validationResult']);
  };

  DBEPPublicationStore.prototype.getProgressBarPercentage = function() {
    return this.state.getIn(['dialog', 'progress']) / this.state.getIn(['dialog', 'selectedQuestions']).size;
  };

  DBEPPublicationStore.prototype.getProfiles = function() {
    return this.state.getIn(['dialog', 'profiles']);
  };

  DBEPPublicationStore.prototype.areTOPAccepted = function() {
    return this.state.getIn(['dialog', 'topAccepted']);
  };

  DBEPPublicationStore.prototype.areErrorsOverridden = function() {
    return this.state.getIn(['dialog', 'validationOverride']);
  };

  DBEPPublicationStore.prototype.isPublishing = function() {
    return this.state.getIn(['dialog', 'meta', 'isPublishing']);
  };

  DBEPPublicationStore.prototype.getPublishResult = function() {
    return this.state.getIn(['dialog', 'publishResult']);
  };

  DBEPPublicationStore.prototype.getTableSearchText = function() {
    return this.state.getIn(['table', 'searchText']);
  };

  DBEPPublicationStore.prototype.getTableStatusFilter = function() {
    return this.state.getIn(['table', 'statusFilter']);
  };

  return DBEPPublicationStore;

})();

module.exports = alt.createStore(immutable(DBEPPublicationStore), 'DBEPPublicationStore');
