var DefaultCell, HeaderCell, cellComponentProvider, matchAny, oneOfProviders,
  __slice = [].slice;

matchAny = require('lib/gdt_tables/cell_match_strategies').matchAny;

DefaultCell = require('components/common/gdt_table/default_cell');

HeaderCell = require('components/common/gdt_table/header_cell');

cellComponentProvider = function(Component, matchStrategy) {
  if (matchStrategy == null) {
    matchStrategy = matchAny;
  }
  return function(cellId, data) {
    if (matchStrategy(cellId, data)) {
      return Component;
    } else {
      return null;
    }
  };
};

oneOfProviders = function() {
  var cellComponentProviders;
  cellComponentProviders = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return function(cellId, data) {
    var cellComponentProviderFn, resultingComponent, _i, _len;
    resultingComponent = null;
    for (_i = 0, _len = cellComponentProviders.length; _i < _len; _i++) {
      cellComponentProviderFn = cellComponentProviders[_i];
      resultingComponent = cellComponentProviderFn(cellId, data);
      if (resultingComponent != null) {
        break;
      }
    }
    return resultingComponent;
  };
};

module.exports = {
  cellComponentProvider: cellComponentProvider,
  defaultCellProvider: cellComponentProvider(DefaultCell),
  headerCellProvider: cellComponentProvider(HeaderCell),
  utils: oneOfProviders
};
