CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ReferenceAttachmentCell = require 'components/references/attachment_cell'
ReferenceCell = require 'components/references/reference_cell'
{ arrayOf, bool, func, instanceOf, string } = PropTypes

ReferenceRow = createReactClass
  displayName: 'ReferenceRow'

  propTypes:
    className: string
    columns: arrayOf(string).isRequired
    isRowSelected: bool.isRequired
    onClick: func.isRequired
    referenceData: instanceOf(Immutable.Map).isRequired
    referenceUsedIn: instanceOf(Immutable.Map)

  getDefaultProps: ->
    referenceUsedIn: Immutable.Map()

  mixins: [ CustomRenderMixin ]

  renderCell: (colName, idx) ->
    <div key={idx}>
      {if colName is 'attachment'
        <ReferenceAttachmentCell
          attachmentData={@props.referenceData.get 'attachment'}
          referenceId={@props.referenceData.get '_id'}
        />
      else
        <ReferenceCell
          cellData={@props.referenceData.get colName, ''}
          cellName={colName}
          className={colName}
          isRowSelected={@props.isRowSelected}
          referenceId={@props.referenceData.get '_id'}
          referenceUsedIn={@props.referenceUsedIn}
        />}
    </div>

  render: ->
    <div onClick={@props.onClick} className={@props.className}>
      {@props.columns.map @renderCell}
    </div>

module.exports = ReferenceRow
