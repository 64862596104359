var DbHelper, W, alt, appUtils, generateActions;

generateActions = require('actions/utils/generate_actions');

appUtils = require('lib/app_utils');

DbHelper = require('base/lib/db_helper');

W = require('when');

alt = require('alt');

module.exports = generateActions({
  autoGenerate: ['changeViewType', 'closeImportDialog', 'fetchError', 'fetchSuccess', 'openImportDialog', 'searchSuccess', 'setSubmitting', 'toggleSelectedFile'],
  name: 'EDMSActions',
  actions: {
    fetchCabinet: function(cabinetId, searchQuery) {
      var options, url;
      if (cabinetId == null) {
        cabinetId = null;
      }
      if (searchQuery == null) {
        searchQuery = null;
      }
      this.dispatch(cabinetId);
      url = "" + (DbHelper.getBackendUrl()) + "/mayan/folders" + (cabinetId ? "/" + cabinetId : '');
      options = {
        contentType: 'application/json',
        xhrFields: {
          withCredentials: true
        },
        data: searchQuery ? {
          searchQuery: searchQuery
        } : {}
      };
      return W($.ajax(url, options)).then(this.actions.fetchSuccess)["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('docsec.editor.edms_fetch_error'));
          return _this.actions.fetchError(err);
        };
      })(this));
    },
    insertFile: function(documentId) {
      var options, url;
      this.dispatch(documentId);
      url = "" + (DbHelper.getBackendUrl()) + "/mayan/documents/" + documentId + "/embed";
      options = {
        type: 'POST',
        xhrFields: {
          withCredentials: true
        }
      };
      return W($.ajax(url, options))["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('docsec.editor.insert_error'));
          return _this.actions.fetchError(err);
        };
      })(this))["finally"]((function(_this) {
        return function() {
          return _this.actions.setSubmitting(false);
        };
      })(this));
    },
    setLocalSearchQuery: function(searchQuery, reset) {
      var currentCabinetId;
      if (reset == null) {
        reset = false;
      }
      this.dispatch(searchQuery);
      if (_.isEmpty(searchQuery) && !reset) {
        return;
      }
      currentCabinetId = alt.stores.EDMSStore.getCurrentCabinetId();
      return this.actions.fetchCabinet(currentCabinetId, searchQuery);
    },
    setGlobalSearchQuery: function(searchQuery) {
      var options, url;
      this.dispatch(searchQuery);
      if (_.isEmpty(searchQuery)) {
        return;
      }
      url = "" + (DbHelper.getBackendUrl()) + "/mayan/_search";
      options = {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          searchQuery: searchQuery
        }),
        xhrFields: {
          withCredentials: true
        }
      };
      return W($.ajax(url, options)).then(this.actions.searchSuccess)["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('docsec.editor.insert_error'));
          return _this.actions.fetchError(err);
        };
      })(this))["finally"]((function(_this) {
        return function() {
          return _this.actions.setSubmitting(false);
        };
      })(this));
    }
  }
});
