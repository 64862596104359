MultiComparisonsActions = require 'actions/multi_comparisons_actions'


MultiComparisonsTitle = ({ onClick, overarchingQuestion, isEditing }) ->
  if isEditing
    handleChange = (evt) ->
      MultiComparisonsActions.updateOverarchingQuestion evt.target.value

    <label className="multi-comparisons__overarching-question">
      <span className="label-text">{$.t 'mc:overarching_question'}</span>
      <input
        className="question-value"
        type="text"
        value={overarchingQuestion}
        onChange={handleChange}
      />
    </label>
  else
    <div className="multi-comparisons__header" onClick={onClick}>
      <a className="multi-comparisons__header-label">{ overarchingQuestion }</a>
    </div>

MultiComparisonsTitle.defaultProps =
  isEditing: false
  overarchingQuestion: ''

MultiComparisonsTitle.propTypes =
  onClick: PropTypes.func
  overarchingQuestion: PropTypes.string
  isEditing: PropTypes.bool

module.exports = MultiComparisonsTitle
