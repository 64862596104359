ModalDialogs = require '../mixins/modal_dialogs'
ContentEditableWithButtons = require './content_editable_with_buttons'
Button = require './button'
InsertModal = require 'components/etd/insert_modal_legacy'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

ContentEditableWithInsert = createReactClass
  displayName: 'ContentEditableWithInsert'
  mixins: [ModalDialogs, CustomRenderMixin, Translation('es:recommendations')]
  propTypes:
    actions: PropTypes.object.isRequired
    attachments: PropTypes.object.isRequired

  handleInsert: (data) ->
    data.options = @props.options ? {}
    @props.actions.insertContent data

  insert: ->
    @displayModal InsertModal,
      title: @i18n 'insert_modal.title'
      insertOptions: @props.insertOptions
      actions: @props.actions
      onSubmit: @handleInsert
      questionType: @props.questionType
      outcomes: @props.outcomes
    ,
      cls: 'etd-insert-modal-legacy'
      closeBtn: true

  componentDidUpdate: (prevProps, prevState) ->
    @refs.contentEditable.focus()

  render: ->
    content = ContentEditableWithInsert.setImagesSrc @props.content, @props.attachments
    if @props.renderMode is 'printout'
      <div dangerouslySetInnerHTML={{__html: content}} />
    else
      <ContentEditableWithButtons
        {...@props}
        content={content}
        ref="contentEditable"
        topButtons={
          <Button
            className="insert btn btn-block"
            label={$.t("translation:actions.insert")}
            onMouseDown={@insert}
          />
        }
      />

ContentEditableWithInsert.setImagesSrc = (content, attachments) ->
    regExp = /<img[^>]+db-image="([\S]+)"[^>]+src=("")/g
    srcSetter = (imageString, imageName, srcValue) =>
      imageString.replace srcValue, ContentEditableWithInsert.getDataURL(imageName, attachments)
    content.replace regExp, srcSetter

ContentEditableWithInsert.getDataURL = (imageName, attachments) ->
    imageAttachment = attachments.get imageName
    return "\"\"" unless imageAttachment

    {content_type, data} = imageAttachment.toJS()
    if content_type and data
      "\"data:#{content_type};base64,#{data}\""
    else
      "\"\""

module.exports = ContentEditableWithInsert
