CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'
Button = require 'components/common/button'
SelectionButton = require 'components/common/selection_button'
ButtonsRow = require 'components/common/buttons_row'
EtdActions = require 'actions/etd_actions'
EtdStore = require 'stores/etd_store'
alt = require 'alt'
EditButton = require 'components/common/edit_button'
SaveButton = require 'components/common/save_button'
DeleteButton = require 'components/common/delete_button'
mediator = require 'mediator'

GdtEditor = Editor.Editor

consensusBasedFieldName = (fieldName, isConsensus) ->
  if isConsensus then "#{fieldName}Consensus" else fieldName

ModalBody = createReactClass
  displayName: 'ConclusionsJustificationModalBody'

  mixins: [CustomRenderMixin, Translation('es:recommendations.table.detailed_justification')]

  addCriterionJustification: (criterion) ->
    @props.addCriterionCbk criterion

  prepareAssessmantSectionsList: ->
    @props.assessmentSections.map (section, sectionKey) =>
      Immutable.Map
        value: sectionKey
        text: section.get 'name'
        disabled: @props.definedDetailNamesList?.contains sectionKey

  setCriterionJustificationValue: (criterion) -> (e) =>
    { value } = e.currentTarget
    @props.changeCriteriaCbk criterion, value

  deleteCriterion: (criterion) -> =>
    @props.deleteCriterionCbk criterion

  getOverallDescriptionContent: ->
    @gdtEditor.getEditorContent()

  criterionJustificationEditor: (section, sectionKey) ->
    draftCriteriaDetails = @props.justification.getIn ['details', 'criteria'], Immutable.Map()
    criterias = if @props.isConsensus
      @props.justification.getIn ['details', 'criteriaConsensus'], draftCriteriaDetails
    else
      draftCriteriaDetails

    <div key={sectionKey} className='detail-editors'>
      <span className='section-name'>{section.get 'name'}</span>
      <div className='editor-buttons'>
        <DeleteButton key='delete-button' onClick={@deleteCriterion sectionKey} />
      </div>
      <textarea
        className='detailed-justification-content'
        onChange={@setCriterionJustificationValue sectionKey}
        defaultValue={criterias.get sectionKey}
      />
    </div>

  render: ->
    { justification } = @props
    justificationContent = if @props.isConsensus
      justification.get('contentConsensus') or justification.get 'content'
    else
      justification.get 'content'

    covidFeaturesOn = mediator.services.switches.isServerSwitchOn('covidFeatures')
    overallExplanation = if covidFeaturesOn
      @i18n '/es:recommendations.explanations.overall_justification'
    else
      ""
    <div>
      <div className='overall-description'>
        <div className='title'>
          {justification.get('overallDescription') ? @i18n('overall_justification')}
          {!_.isEmpty(overallExplanation) and
            <span className="explanation">- {overallExplanation}</span>
          }
        </div>
        <GdtEditor
          stickControlsTo='.overall-description'
          ref={(el) => @gdtEditor = el}
          editorContent={justificationContent}
        />
      </div>
      <div className='detailed-justification'>
        <div className='title'>
          {justification.getIn ['details', 'description']}
        </div>
        <div className='detailed-info'>{@i18n 'detailed_info'}</div>
        {@props.filteredAssessmentSections.map (section, sectionKey) =>
          @criterionJustificationEditor section, sectionKey
        .toList()
        }
      </div>
      <SelectionButton
        className='btn-block add-criteria-btn'
        label={@i18n 'add_criterion'}
        list={@prepareAssessmantSectionsList()}
        onElementSelected={@addCriterionJustification}/>
      <ButtonsRow className='bottom-buttons'>
        <Button
          className='btn-cancel'
          label={@i18n '/actions.cancel'}
          onClick={@props.onCancel} />
        <Button
          className='btn-apply'
          label={@i18n '/actions.apply'}
          onClick={@props.onApply}/>
      </ButtonsRow>
    </div>

ConclusionsJustification = createReactClass
  displayName: 'ConclusionsJustification'

  mixins: [Translation('es:recommendations.table.detailed_justification')]

  getDefaultProps: ->
    noAutoSave: false

  shouldUpdateCriteria: true #needed for preview mode

  getInitialState: ->
    isModalOpened: false
    initialJustification: @props.justification
    justification: @props.justification

  openModal: ->
    @etdStoreSnapshot = alt.takeSnapshot EtdStore
    @setState isModalOpened: true

  closeModal: ->
    @setState isModalOpened: false

  saveJustification: (evt) ->
    evt.stopPropagation()
    @shouldUpdateCriteria = true
    justification = @_getJustificationWithContent()
    @setState
      'justification': justification
      'initialJustification': justification
    actionName = if @props.noAutoSave
      'setOverallJustification'
    else
      'setOverallJustificationAndSave'
    EtdActions[actionName] justification
    @gdtEditor.resetContent @_getJustificationContent justification
    @props.onChange?(justification)
    @closeModal()

  cancelModal: (evt) ->
    evt.stopPropagation()
    @setState 'justification': @state.initialJustification
    alt.bootstrap @etdStoreSnapshot
    @closeModal()

  areDetailsDefined: ->
    draftDetails = @state.justification.getIn ['details', 'criteria'], Immutable.Map()
    details = if @props.isConsensus
      @state.justification.getIn ['details', 'criteriaConsensus'], draftDetails
    else
      draftDetails
    not details.isEmpty()

  _getCriteriaPath: ->
    ['details', consensusBasedFieldName('criteria', @props.isConsensus)]

  addCriterion: (criterion) ->
    @shouldUpdateCriteria = true
    updatePath = @_getCriteriaPath().concat [criterion]
    @setState 'justification': @_getJustificationWithContent().setIn updatePath, ''

  deleteCriterion: (criterion) ->
    @shouldUpdateCriteria = true
    updatePath = @_getCriteriaPath().concat [criterion]
    @setState 'justification': @_getJustificationWithContent().deleteIn updatePath

  changeCriteria: (criterion, value) ->
    @shouldUpdateCriteria = false
    updatePath = @_getCriteriaPath().concat [criterion]
    @setState 'justification': @_getJustificationWithContent().setIn updatePath, value

  _getJustificationWithContent: ->
    updateField = consensusBasedFieldName 'content', @props.isConsensus
    @state.justification.set updateField, @_modalBody.getOverallDescriptionContent()

  getFilteredAssessmentSections: ->
    draftCriteriaDetails = @state.justification.getIn ['details', 'criteria'], Immutable.Map()
    detailsCriteria = if @props.isConsensus
      @state.justification.getIn ['details', 'criteriaConsensus'], draftCriteriaDetails
    else
      draftCriteriaDetails

    if detailsCriteria.isEmpty()
      Immutable.Map()
    else
      @props.assessmentSections.filter (section, sectionKey) ->
        detailsCriteria.has sectionKey

  _getJustificationContent: (justification) ->
    if @props.isConsensus
      justification.get('contentConsensus') or justification.get 'content'
    else
      justification.get 'content'

  renderDetailedJustification: (filteredAssessmentSections) ->
    draftDetails = @state.justification.getIn ['details', 'criteria'], Immutable.Map()
    details = if @props.isConsensus
     @state.justification.getIn ['details', 'criteriaConsensus'], draftDetails
    else
      draftDetails

    <div className='detailed-justification'>
      <p className='description'>
        <b>{@state.justification.getIn ['details', 'description']}</b>
      </p>
      {filteredAssessmentSections.map (section, sectionKey) =>
        <div key={sectionKey}>
          <p className='criterion-name'><i>{section.get 'name'}</i></p>
          <div
            className='criterion-content'
            dangerouslySetInnerHTML={{__html: details.get sectionKey}} />
        </div>
      .toList()
      }
    </div>

  componentWillReceiveProps: (nextProps) ->
    # reset editor content if it has changed
    nextJustification = nextProps.justification
    unless nextJustification.equals @state.justification
      @gdtEditor.resetContent @_getJustificationContent nextJustification
    @setState
      'initialJustification': nextProps.justification
      'justification': nextProps.justification

  shouldComponentUpdate: ->
    @shouldUpdateCriteria

  getDefinedDetailNamesList: ->
    (if @props.isConsensus
      @state.justification.getIn(['details', 'criteriaConsensus'],
        @state.justification.getIn(['details', 'criteria'], Immutable.Map()))
    else
      @state.justification.getIn(['details', 'criteria'], Immutable.Map())).keySeq()

  render: ->
    { editable, readOnly } = @props

    detailsDefined = @areDetailsDefined()
    justification = @state.justification
    justificationContent = @_getJustificationContent justification
    filteredAssessmentSections = @getFilteredAssessmentSections()
    containerClasses = classNames 'conclusions-justification',
      editable: @props.editable and not @props.readOnly
      readOnly: @props.readOnly or not @props.editable

    <div className={containerClasses} onClick={@openModal if editable and not readOnly}>
      <div className='overall-justification'>
        {if detailsDefined
          <div className='description'>
            <b>{justification.get('overallDescription') ? @i18n('overall_justification')}</b>
          </div>
        }
        <GdtEditor
          readOnly
          ref={(el) => @gdtEditor = el}
          editorContent={justificationContent}
        />
      </div>
      {if detailsDefined
        @renderDetailedJustification filteredAssessmentSections
      }
      {if editable and @props.renderMode isnt 'printout' and not readOnly
        <Modal
          className='detailed-justification-modal'
          isOpen={@state.isModalOpened}
          closeButton={false}
          onClose={@closeModal}
          title={justification.get 'description'}>
            <ModalBody
              ref={(node) => @_modalBody = node}
              justification={justification}
              addCriterionCbk={@addCriterion}
              deleteCriterionCbk={@deleteCriterion}
              changeCriteriaCbk={@changeCriteria}
              assessmentSections={@props.assessmentSections}
              filteredAssessmentSections={filteredAssessmentSections}
              definedDetailNamesList={@getDefinedDetailNamesList()}
              isConsensus={@props.isConsensus}
              onCancel={@cancelModal}
              onApply={@saveJustification} />
        </Modal>
      }
    </div>

module.exports = ConclusionsJustification
