{ OUTCOME, QUESTION, QGROUP } = require 'components/scope/outcomes/drag_drop_types'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
DropTarget = ReactDnD.DropTarget

#
# Scrolling
#

# THRESHOLD distance in px to the top and bottom edge of the questions container. Reaching
# threshold causes auto scroll
THRESHOLD = 55
# SCROLL_STEP in px is how much should the container scroll per each frame
SCROLL_STEP = 10

scrollFrame = null
scrollDistance = null

getScrollDistance = (component, pointerY) ->
  { componentTop, componentBottom, currentScrollTop } = component.state

  return SCROLL_STEP if componentBottom - pointerY <= THRESHOLD
  return -SCROLL_STEP if currentScrollTop and pointerY - componentTop <= THRESHOLD
  0

scrollComponent = (component) ->
  component.scroll scrollDistance
  scrollFrame = window.requestAnimationFrame scrollComponent.bind component, component

cancelScroll = ->
  if scrollFrame
    window.cancelAnimationFrame scrollFrame
    scrollFrame = null

#
# DnD spec
#
dropSpec =
  canDrop: (props, monitor) ->
    monitor.getItemType() is 'question' and monitor.isOver shallow: true
  hover: (props, monitor, component) ->
    pointerY = monitor.getClientOffset().y
    scrollDistance = getScrollDistance component, pointerY
    return cancelScroll() unless scrollDistance

    window.requestAnimationFrame scrollComponent.bind(component, component) unless scrollFrame
  drop: (props, monitor, component) ->
    props.releaseQuestion monitor.getItem()

dropCollect = (connect, monitor) ->
  connectDropTarget: connect.dropTarget()
  isOver: monitor.isOver()
  isHovered: monitor.canDrop()

# Component
QuestionGroupsAutoScrollContainer = createReactClass
  displayName: 'QuestionGroupsAutoScrollContainer'

  propTypes:
    releaseQuestion: PropTypes.func.isRequired

  mixins: [CustomRenderMixin]

  getInitialState: ->
    currentScrollTop: 0

  scroll: (amount) ->
    # positive amount results in scroll down, negative amount - scroll up
    ReactDOM.findDOMNode(this).scrollTop = @state.currentScrollTop + amount

  scrollToGroup: (gIndex) ->
    { currentScrollTop, componentTop } = @state
    qGroupNode = ReactDOM.findDOMNode @refs["qGroup#{gIndex}"].getDecoratedComponentInstance()
    qGroupTop = qGroupNode.getBoundingClientRect().top
    scrollDistance = if qGroupTop < componentTop
      # scroll up
      scrollDistance = -1 * (Math.abs(qGroupTop) + componentTop)
    else if qGroupTop > (currentScrollTop + componentTop)
      # scroll down
      qGroupTop - componentTop
    else 0

    @scroll scrollDistance

  _updateRect: ->
    { top, bottom } = ReactDOM.findDOMNode(this).getBoundingClientRect()
    @setState { componentTop: top, componentBottom: bottom }

  _updateScrollTop: ->
    @setState currentScrollTop: ReactDOM.findDOMNode(this).scrollTop

  componentWillReceiveProps: (nextProps) ->
    cancelScroll() if @props.isOver and not nextProps.isOver

  componentDidMount: ->
    @_updateRect()

    window.addEventListener 'resize', @_updateRect
    ReactDOM.findDOMNode(this).addEventListener 'scroll', @_updateScrollTop

  componentWillUnmount: ->
    window.removeEventListener 'resize', @_updateRect
    ReactDOM.findDOMNode(this).removeEventListener 'scroll', @_updateScrollTop

  render: ->
    { connectDropTarget, children, isHovered } = @props
    classes = classNames 'questions-container', 'hovered': isHovered

    connectDropTarget(
      <div className={classes}>
        {React.Children.map children, (child) ->
          React.cloneElement child, ref: "qGroup#{child.props.gIndex}"
        }
      </div>
    )

module.exports = DropTarget([OUTCOME, QUESTION, QGROUP], dropSpec, dropCollect)(
  QuestionGroupsAutoScrollContainer
)
