var Migration, OtherQualityModifiersNoneToNo, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = OtherQualityModifiersNoneToNo = (function(_super) {
  __extends(OtherQualityModifiersNoneToNo, _super);

  function OtherQualityModifiersNoneToNo() {
    OtherQualityModifiersNoneToNo.__super__.constructor.call(this, false);
  }

  OtherQualityModifiersNoneToNo.prototype.up = function(project, colls) {
    var noneToNo, question, setToNo;
    OtherQualityModifiersNoneToNo.__super__.up.apply(this, arguments);
    setToNo = function(outcome, attrName) {
      var _ref;
      if (((_ref = outcome.get(attrName)) != null ? _ref.name : void 0) === 'None') {
        return outcome.set(attrName, 'No');
      }
    };
    noneToNo = function(question) {
      var outcome, _i, _len, _ref;
      _ref = question.get('outcomes').models;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        outcome = _ref[_i];
        setToNo(outcome, 'plausibleConfounding');
        setToNo(outcome, 'doseResponseGradient');
      }
      return question.save();
    };
    return W.all((function() {
      var _i, _len, _ref, _results;
      _ref = colls.questions.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        question = _ref[_i];
        if (!question.isDiagnostic()) {
          _results.push(noneToNo(question));
        }
      }
      return _results;
    })());
  };

  return OtherQualityModifiersNoneToNo;

})(Migration);
