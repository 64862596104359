var ImportActions, ImportUtils, alt, defaultState, generateStore, immIso, immutable, lensPath, mapped, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

immutable = require('stores/utils/immutable');

ImportActions = require('actions/import_actions');

ImportUtils = require('lib/import_utils');

_ref = require('lib/lens_utils'), lensPath = _ref.lensPath, immIso = _ref.immIso, mapped = _ref.mapped;

generateStore = require('stores/utils/generate_store');

defaultState = Immutable.fromJS({
  _meta: {
    isOpen: false,
    isImporting: false,
    isParsing: false
  },
  importType: null,
  organizationId: null,
  currentStep: 'import-project-choose-source',
  includeReferences: true,
  fileData: {
    file: null,
    fileName: null
  },
  parsedProjects: [],
  projectName: '',
  questions: [],
  selectedItems: [],
  model: null,
  revmanCsvPromise: null,
  revmanCsvMethod: null,
  revmanCsvResolving: false
});

module.exports = generateStore({
  name: 'ImportStore',
  initialState: defaultState,
  boundActions: [ImportActions],
  methods: {
    onGoBack: function() {
      return this.setState(this.state.set('currentStep', 'import-project-choose-source'));
    },
    onSetFile: function(file) {
      var fileName;
      fileName = ImportUtils.getFileName(file);
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['fileData', 'file'], file);
        return state.setIn(['fileData', 'fileName'], fileName);
      }));
    },
    onResetSource: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['fileData', 'file'], null);
        return state.setIn(['fileData', 'fileName'], null);
      }));
    },
    onOpenImportModal: function(_arg) {
      var importType, model, organizationId;
      importType = _arg.importType, model = _arg.model, organizationId = _arg.organizationId;
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['_meta', 'isOpen'], true);
        state.set('importType', importType);
        state.set('model', model);
        return state.set('organizationId', organizationId);
      }));
    },
    onCloseImportModal: function() {
      return this.setState(defaultState);
    },
    onToggleIncludeReferences: function() {
      var includeReferences;
      includeReferences = this.state.get('includeReferences');
      return this.setState(this.state.set('includeReferences', !includeReferences));
    },
    onParseQuestions: function() {
      return this.setState(this.state.setIn(['_meta', 'isParsing'], true));
    },
    onParseQuestionsSuccess: function(_arg) {
      var parsedProjects, questions;
      parsedProjects = _arg.parsedProjects, questions = _arg.questions;
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['_meta', 'isParsing'], false);
        state.set('currentStep', 'import-project-choose-questions');
        state.set('parsedProjects', parsedProjects);
        state.set('questions', questions);
        state.set('selectedItems', []);
        return state.set('projectName', parsedProjects.length === 1 ? parsedProjects[0].project.get('name') : '');
      }));
    },
    onParseQuestionsError: function() {
      return this.setState(this.state.setIn(['_meta', 'isParsing'], false));
    },
    onImportProject: function() {
      return this.setState(this.state.setIn(['_meta', 'isImporting'], true));
    },
    onImportProjectSuccess: function() {
      return this.setState(defaultState);
    },
    onImportProjectError: function() {
      return this.setState(defaultState);
    },
    onImportQuestionsOrOutcomes: function() {
      return this.setState(this.state.setIn(['_meta', 'isImporting'], true));
    },
    onImportQuestionsOrOutcomesSuccess: function() {
      return this.setState(defaultState);
    },
    onImportQuestionsOrOutcomesError: function() {
      return this.setState(defaultState);
    },
    onSelectItems: function(_arg) {
      var checked, itemsToSelect, newItems, oldItems;
      itemsToSelect = _arg.itemsToSelect, checked = _arg.checked;
      oldItems = this.state.get('selectedItems');
      newItems = _.chain(oldItems).filter(function(cid) {
        return __indexOf.call(itemsToSelect, cid) < 0;
      }).union(checked ? itemsToSelect : []).value();
      return this.setState(this.state.set('selectedItems', newItems));
    },
    onChangeProjectName: function(name) {
      return this.setState(this.state.set('projectName', name));
    },
    onOpenRevmanCsvConflictMethodDialog: function(deferred) {
      return this.setState(this.state.set('revmanCsvPromise', deferred));
    },
    onCancelResolveRevmanCsvConflict: function() {
      var deferred;
      deferred = this.state.get('revmanCsvPromise');
      this.setState(defaultState);
      return deferred.reject('cancelled');
    },
    onSelectRevmanCsvConflictMethod: function(method) {
      return this.setState(this.state.set('revmanCsvMethod', method));
    },
    onResolveRevmanCsvConflict: function() {
      var csvMethod, deferred;
      deferred = this.state.get('revmanCsvPromise');
      csvMethod = this.state.get('revmanCsvMethod');
      this.setState(this.state.set('revmanCsvResolving', true));
      return deferred.resolve(csvMethod);
    },
    isImporting: function() {
      return this.state.getIn(['_meta', 'isImporting']);
    },
    isParsing: function() {
      return this.state.getIn(['_meta', 'isParsing']);
    },
    isImportModalOpened: function() {
      return this.state.getIn(['_meta', 'isOpen']);
    },
    isAnyItemChosen: function() {
      var selectedItems;
      selectedItems = this.state.get('selectedItems');
      if (selectedItems.size != null) {
        return selectedItems.size > 0;
      } else {
        return selectedItems.length > 0;
      }
    },
    getCurrentStep: function() {
      return this.state.get('currentStep');
    },
    getFileData: function() {
      return this.state.get('fileData');
    },
    getImportData: function() {
      var importType;
      if (!this.isSourceChosen()) {
        return null;
      }
      importType = this.state.get('importType');
      if (importType === 'project') {
        return this.getProjectImportData();
      } else {
        return this.getQuestionsOrOutcomesImportData();
      }
    },
    getRawImportData: function() {
      return this.state.get('fileData').toJS();
    },
    getProjectImportData: function() {
      var includeReferences, organizationId, parsedProjects, projectName, selectedItems;
      selectedItems = this.state.get('selectedItems');
      parsedProjects = this.state.get('parsedProjects');
      includeReferences = this.state.get('includeReferences');
      organizationId = this.state.get('organizationId');
      projectName = this.state.get('projectName');
      return {
        includeReferences: includeReferences,
        organizationId: organizationId,
        parsedProjects: R.over(lensPath(immIso, mapped, 'questions'), R.filter(R.compose(R.includes(R.__, selectedItems), R.prop('cid'))), parsedProjects).toJS(),
        projectName: projectName
      };
    },
    getQuestionsOrOutcomesImportData: function() {
      var importType, includeReferences, model, parsedProjects, questions, selectedItems;
      if (!this.isAnyItemChosen()) {
        return null;
      }
      importType = this.state.get('importType');
      selectedItems = this.state.get('selectedItems');
      parsedProjects = this.state.get('parsedProjects');
      questions = this.state.get('questions');
      includeReferences = this.state.get('includeReferences');
      model = this.state.get('model');
      return {
        importType: importType,
        selectedItems: selectedItems,
        parsedProjects: parsedProjects,
        questions: questions,
        includeReferences: includeReferences,
        model: model
      };
    },
    getImportType: function() {
      return this.state.get('importType');
    },
    getQuestions: function() {
      return this.state.get('questions');
    },
    getSelectedItems: function() {
      return this.state.get('selectedItems');
    },
    isSourceChosen: function() {
      return Boolean(this.state.getIn(['fileData', 'file']));
    },
    getIncludeReferences: function() {
      return this.state.get('includeReferences');
    },
    getStudies: function() {
      return R.compose(Immutable.Map, R.map(function(study) {
        return [study.revmanId, study];
      }), R.flatten, R.view(lensPath(immIso, 'parsedProjects', mapped, 'studies')))(this.state);
    },
    canChangeProjectName: function() {
      return this.state.get('importType') === 'project' && this.state.get('parsedProjects').length === 1;
    },
    getProjectName: function() {
      return this.state.get('projectName');
    },
    isRevmanCsvConflictMethodDialogOpen: function() {
      return this.state.get('revmanCsvPromise') != null;
    },
    isRevmanCsvResolvingConflicts: function() {
      return this.state.get('revmanCsvResolving');
    },
    getRevmanCsvSelectedConflictMethod: function() {
      return this.state.get('revmanCsvMethod');
    }
  }
});
