var EditableItemView, RecommendationsHeaderView, gdtMediumOptions, mediator, template, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

EditableItemView = require('base/views/editable_item_view');

utils = require('base/lib/utils');

template = require('views/templates/evidence_syntheses/recommendations_header');

gdtMediumOptions = require('lib/medium_editor_gdt_options');

module.exports = RecommendationsHeaderView = (function(_super) {
  __extends(RecommendationsHeaderView, _super);

  function RecommendationsHeaderView() {
    return RecommendationsHeaderView.__super__.constructor.apply(this, arguments);
  }

  RecommendationsHeaderView.prototype.template = template;

  RecommendationsHeaderView.prototype.autoRender = true;

  RecommendationsHeaderView.prototype.tagName = 'table';

  RecommendationsHeaderView.prototype.shortcuts = {
    'esc': '_close'
  };

  RecommendationsHeaderView.prototype.initialize = function() {
    RecommendationsHeaderView.__super__.initialize.apply(this, arguments);
    this.expand = true;
    return this.delegate('click', 'h1', this.toggleExpand);
  };

  RecommendationsHeaderView.prototype.getTemplateData = function() {
    return _(RecommendationsHeaderView.__super__.getTemplateData.apply(this, arguments)).extend({
      expand: this.expand,
      editing: this.editing,
      isDiagnostic: this.model.isDiagnostic()
    });
  };

  RecommendationsHeaderView.prototype.setBindings = function() {
    var attrName, bindingAttr, el;
    return this.bindings = _.object((function() {
      var _i, _len, _ref, _results;
      _ref = this.$('[data-hook]');
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        el = _ref[_i];
        attrName = el.getAttribute('data-hook');
        bindingAttr = "[data-hook=" + attrName + "]";
        if (el.localName === 'select') {
          _results.push([
            bindingAttr, {
              observe: attrName,
              selectOptions: {
                collection: this._getPerspectiveOptionsList
              }
            }
          ]);
        } else {
          _results.push([
            bindingAttr, {
              observe: attrName,
              events: ['keyup', 'change', 'cut', 'paste', 'input']
            }
          ]);
        }
      }
      return _results;
    }).call(this));
  };

  RecommendationsHeaderView.prototype.render = function() {
    this._setDefaultSources();
    return RecommendationsHeaderView.__super__.render.apply(this, arguments);
  };

  RecommendationsHeaderView.prototype._setDefaultSources = function() {
    var DEFAULT_DATA_SOURCES;
    DEFAULT_DATA_SOURCES = {
      'headerPatientsField': 'healthProblemOrPopulation',
      'headerSettingField': 'settings'
    };
    _(DEFAULT_DATA_SOURCES).extend(this.model.isDiagnostic() ? {
      'headerIndexTestField': 'indexTest',
      'headerComparatorTestField': this._getComparatorTestValue.bind(this)
    } : {
      'headerMainOutcomes': this._getMainOutcomesString.bind(this)
    });
    return _(DEFAULT_DATA_SOURCES).keys().filter((function(_this) {
      return function(headerDbAttr) {
        return !_this.model.get(headerDbAttr);
      };
    })(this)).forEach((function(_this) {
      return function(headerDbAttr) {
        var dataSource, defaultData;
        dataSource = DEFAULT_DATA_SOURCES[headerDbAttr];
        defaultData = _(dataSource).isString() ? _this.model.get(dataSource) : dataSource();
        return _this.model.set(headerDbAttr, defaultData);
      };
    })(this));
  };

  RecommendationsHeaderView.prototype._getMainOutcomesString = function() {
    var criticalOutcomes, resultString;
    criticalOutcomes = this.model.getCriticalAndImportantOutcomes();
    if (_(criticalOutcomes).isEmpty()) {
      return;
    }
    resultString = '<ul>';
    _(criticalOutcomes).chain().pluck('attributes').pluck('name').forEach(function(outcomeName) {
      return resultString += "<li>" + outcomeName + "</li>";
    });
    return resultString += '</ul>';
  };

  RecommendationsHeaderView.prototype._getComparatorTestValue = function() {
    var comparatorTest, placeholderString;
    placeholderString = utils.bracketize($.t('es:question.comparator_test'));
    comparatorTest = this.model.get('comparatorTest');
    if (comparatorTest === placeholderString) {
      return '';
    } else {
      return comparatorTest;
    }
  };

  RecommendationsHeaderView.prototype._getPerspectiveOptionsList = function() {
    var options, stickitOptionsList;
    options = ["clinicians", "health_system", "patient", "policymaker", "public_health", "societal", "other"];
    stickitOptionsList = [];
    options.forEach(function(option) {
      var text;
      text = $.t("es:recommendations.header." + option);
      return stickitOptionsList.push({
        value: text,
        label: text
      });
    });
    return stickitOptionsList;
  };

  RecommendationsHeaderView.prototype.afterRender = function() {
    RecommendationsHeaderView.__super__.afterRender.apply(this, arguments);
    this.setBindings();
    this.stickit();
    return this._handleMediumEditor();
  };

  RecommendationsHeaderView.prototype.showEditBox = function() {
    if (!this.expand) {
      this.toggleExpand();
    }
    return RecommendationsHeaderView.__super__.showEditBox.apply(this, arguments);
  };

  RecommendationsHeaderView.prototype._close = function() {
    this.expand = false;
    return RecommendationsHeaderView.__super__._close.apply(this, arguments);
  };

  RecommendationsHeaderView.prototype.onFocusLost = function() {};

  RecommendationsHeaderView.prototype.toggleExpand = function() {
    if (this.editing) {
      this.saveChanges();
    }
    if (this.expand) {
      this.expand = false;
      return this._close();
    } else {
      return mediator.setFocus(this, (function(_this) {
        return function() {
          _this.expand = true;
          _this.render();
          return _this._animate();
        };
      })(this));
    }
  };

  RecommendationsHeaderView.prototype.toggleEdit = function() {
    this.editing = !this.editing;
    this.render();
    return this._animate();
  };

  RecommendationsHeaderView.prototype.saveChanges = function() {
    return this.model.save().then((function(_this) {
      return function() {
        return _this.toggleEdit();
      };
    })(this));
  };

  RecommendationsHeaderView.prototype._handleMediumEditor = function() {
    if (this.editing && !this.editor) {
      return this.editor = new MediumEditor(this.$('.editable-content'), gdtMediumOptions["default"]);
    } else if (this.editor && !this.editing) {
      this.editor.destroy();
      return this.editor = null;
    }
  };

  RecommendationsHeaderView.prototype.dispose = function() {
    var _ref;
    this.unstickit();
    if ((_ref = this.editor) != null) {
      _ref.destroy();
    }
    return RecommendationsHeaderView.__super__.dispose.apply(this, arguments);
  };

  return RecommendationsHeaderView;

})(EditableItemView);
