EllipsizedText = require 'components/common/ellipsized_text'
{ getQuestionSubmoduleUrl } = require 'lib/questions_helper'
mediator = require 'mediator'
{ useCoffeeCallback } = require 'lib/react_utils'

ToolbarMDGQuestion = ({ additionalProps, id, question }) ->
  { collapse, mode } = additionalProps
  classes = classNames 'bold': id is mediator.currentQuestionId
  onClick = useCoffeeCallback [collapse, mode, id], ->
    submodule = switch mode
      when 'searchStrategy'
        'mdg_search_strategy'
      else
        'mdg_evidence_table'
    link = getQuestionSubmoduleUrl 'evidence_syntheses', submodule, id
    mediator.publish '!router:route', link
    collapse?()

  <div className="toolbar-mdg-question">
    <div className="question-text">
      <a onClick={onClick} className={classes} >
        <EllipsizedText height={40} text={question} />
      </a>
    </div>
  </div>

ToolbarMDGQuestion.propTypes =
  id: PropTypes.string.isRequired
  question: PropTypes.string.isRequired
  additionalProps: PropTypes.shape
    collapse: PropTypes.func

ToolbarMDGQuestion.defaultProps =
  additionalProps: {}

module.exports = ToolbarMDGQuestion
