{ string, func, instanceOf } = PropTypes
PanelVoiceActions = require 'actions/panel_voice_actions'
Translation = require 'components/mixins/translation'
MemberInputItem = require 'components/team/form/member_input_item'
getEtdPanelVoiceFormLink = require('base/lib/db_helper').getEtdPanelVoiceFormLink
mediator = require 'mediator'

AddManualResultsForm = createReactClass
  displayName: "AddManualResultsForm"

  mixins: [Translation('voting:voting')]

  propTypes:
    members: instanceOf(Immutable.OrderedMap).isRequired
    etdId: string.isRequired
    questionId: string.isRequired

  getInitialState: ->
    selectedMember: null

  onSelect: (e) ->
    @setState selectedMember: e.target.value

  openPanelVoiceForm: ->
    { questionId, etdId } = @props

    panelVoiceFormLink =
      getEtdPanelVoiceFormLink mediator.project.id, @state.selectedMember, questionId, etdId
    window.open panelVoiceFormLink, '_blank'

    @setState selectedMember: null

  render: ->
    linkEnabled = @state.selectedMember
    buttonClass = classNames 'btn', 'btn-block', 'send-button', 'disabled': not linkEnabled

    <div>
      <div className="members-list">
        <div className="panel-member-selection">
          <div className="members">
            {@props.members.map (member) =>
              <MemberInputItem
                key={member.get('_id')}
                type="radio"
                member={member}
                name="addManualResultsFormMember"
                onChange={@onSelect}
              />
            .toList()
            }
          </div>
        </div>
      </div>
      <button
        className={buttonClass}
        onClick={@openPanelVoiceForm}
        disabled={not linkEnabled}
      >
        {@i18n 'modals.go_to_form'}
      </button>
    </div>

module.exports = AddManualResultsForm
