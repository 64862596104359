NarrativeDescEditCell =
  require 'components/evidence_syntheses/outcomes/forms/narrative_desc_edit_cell'

ReactCellEditContainer = createReactClass
  displayName: 'ReactCellEditContainer'

  render: ->
    switch @props.property
      when 'narrativeDesc' then <NarrativeDescEditCell {...@props}/>
      else
        null

module.exports = ReactCellEditContainer
