var COVID_ASTERIXED_FIELDS, COVID_GENERAL_INFORMATION_FORM_ITEMS, GeneralInformation, GeneralInformationActions, GeneralInformationToolbar, GeneralInformationView, MDG_GENERAL_INFORMATION_FORM_ITEMS, PUBLICATION_PLATFORM_GENERAL_INFORMATION_FORM_ITEMS, ReactComponent, ReactToolbarView, TB_ASTERIXED_FIELDS, TB_GENERAL_INFORMATION_FORM_ITEMS, View, alt, mediator, _ref, _ref1,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

_ref = require('lib/covid_extraction_form_helper'), COVID_GENERAL_INFORMATION_FORM_ITEMS = _ref.COVID_GENERAL_INFORMATION_FORM_ITEMS, COVID_ASTERIXED_FIELDS = _ref.COVID_ASTERIXED_FIELDS, PUBLICATION_PLATFORM_GENERAL_INFORMATION_FORM_ITEMS = _ref.PUBLICATION_PLATFORM_GENERAL_INFORMATION_FORM_ITEMS;

GeneralInformation = require('components/administration/general_information');

GeneralInformationActions = require('actions/general_information_actions');

GeneralInformationToolbar = require('components/administration/general_information_toolbar');

MDG_GENERAL_INFORMATION_FORM_ITEMS = require('lib/mdg_helper').MDG_GENERAL_INFORMATION_FORM_ITEMS;

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

_ref1 = require('lib/tb_extraction_form_helper'), TB_GENERAL_INFORMATION_FORM_ITEMS = _ref1.TB_GENERAL_INFORMATION_FORM_ITEMS, TB_ASTERIXED_FIELDS = _ref1.TB_ASTERIXED_FIELDS;

View = require('base/views/view');

module.exports = GeneralInformationView = (function(_super) {
  __extends(GeneralInformationView, _super);

  function GeneralInformationView() {
    return GeneralInformationView.__super__.constructor.apply(this, arguments);
  }

  GeneralInformationView.prototype.container = '#page-container';

  GeneralInformationView.prototype.className = 'general-information-view';

  GeneralInformationView.prototype.autoRender = true;

  GeneralInformationView.prototype.initialize = function() {
    GeneralInformationView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: GeneralInformationToolbar
    }));
    return this.enable(ReactComponent);
  };

  GeneralInformationView.prototype._getForm = function() {
    var covidFeaturesOn, ecFeaturesOn, mdgFeaturesOn, publicationPlatformOn, switchesService, tbFeaturesOn;
    switchesService = mediator.services.switches;
    tbFeaturesOn = switchesService.isServerSwitchOn('tbFeatures');
    if (tbFeaturesOn) {
      return TB_GENERAL_INFORMATION_FORM_ITEMS;
    }
    covidFeaturesOn = switchesService.isServerSwitchOn('covidFeatures');
    if (covidFeaturesOn) {
      return COVID_GENERAL_INFORMATION_FORM_ITEMS;
    }
    mdgFeaturesOn = switchesService.isServerSwitchOn('mdgFeatures');
    if (mdgFeaturesOn) {
      return MDG_GENERAL_INFORMATION_FORM_ITEMS;
    }
    publicationPlatformOn = switchesService.isOn('publicationPlatform');
    if (publicationPlatformOn) {
      return PUBLICATION_PLATFORM_GENERAL_INFORMATION_FORM_ITEMS;
    }
    ecFeaturesOn = switchesService.isServerSwitchOn('ecFeatures');
    if (ecFeaturesOn) {
      return PUBLICATION_PLATFORM_GENERAL_INFORMATION_FORM_ITEMS;
    }
  };

  GeneralInformationView.prototype._getAsterixedFields = function() {
    var switchesService;
    switchesService = mediator.services.switches;
    if (switchesService.isServerSwitchOn('covidFeatures')) {
      return COVID_ASTERIXED_FIELDS;
    }
    if (switchesService.isServerSwitchOn('tbFeatures')) {
      return TB_ASTERIXED_FIELDS;
    }
    return [];
  };

  GeneralInformationView.prototype.afterRender = function() {
    GeneralInformationView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(GeneralInformation, GeneralInformationActions, null, {
      props: {
        form: this._getForm(),
        asterixedFields: this._getAsterixedFields()
      },
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  GeneralInformationView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('GeneralInformationStore');
    return GeneralInformationView.__super__.dispose.apply(this, arguments);
  };

  return GeneralInformationView;

})(View);
