var MdaTopicView, MdaTopicsController, MdaTopicsView, ModuleController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

MdaTopicsView = require('views/mda/mda_topics_view');

MdaTopicView = require('views/mda/mda_topic_view');

mediator = require('mediator');

module.exports = MdaTopicsController = (function(_super) {
  __extends(MdaTopicsController, _super);

  function MdaTopicsController() {
    return MdaTopicsController.__super__.constructor.apply(this, arguments);
  }

  MdaTopicsController.prototype.index = function() {
    return this.view = new MdaTopicsView;
  };

  MdaTopicsController.prototype.topic = function(params) {
    return this.view = new MdaTopicView({
      topicId: params.id
    });
  };

  return MdaTopicsController;

})(ModuleController);
