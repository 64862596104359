var arrayOf, getNestedType, isArrayOfType, isContainerType, isHigherOrderedType, isMapOfType, isShapeType, mapOf, shape, typeValidationInvariant, _isType;

shape = require('lib/db_docs/field_types/shape');

mapOf = require('lib/db_docs/field_types/map_of');

arrayOf = require('lib/db_docs/field_types/array_of');

typeValidationInvariant = require('lib/db_docs/document_instance_helpers').typeValidationInvariant;

_isType = function(TypeConstructor) {
  return function(Type) {
    return Type instanceof TypeConstructor;
  };
};

isMapOfType = _isType(mapOf.typeConstructor);

isArrayOfType = _isType(arrayOf.typeConstructor);

isShapeType = _isType(shape.typeConstructor);

isContainerType = function(type) {
  return isMapOfType(type) || isArrayOfType(type);
};

isHigherOrderedType = function(type) {
  return isContainerType(type) || isShapeType(type);
};

getNestedType = function(inputType, path) {
  var key, keyType, remainingPath;
  if (_.isEmpty(path)) {
    return inputType;
  }
  if (!isHigherOrderedType(inputType)) {
    return inputType;
  }
  remainingPath = path.slice(1);
  if (isShapeType(inputType)) {
    key = path[0];
    keyType = inputType['_typeMeta'].valuesSpec[key];
    return getNestedType(keyType, remainingPath);
  } else {
    return getNestedType(inputType['_typeMeta'].ItemType, remainingPath);
  }
};

module.exports = {
  isMapOfType: isMapOfType,
  isArrayOfType: isArrayOfType,
  isShapeType: isShapeType,
  getNestedType: getNestedType,
  isHigherOrderedType: isHigherOrderedType
};
