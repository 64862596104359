QuestionRatingRow = require 'components/scope/questions/question_rating_row'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

QuestionRatings = createReactClass
  displayName: 'QuestionRatings'

  propTypes:
    data: PropTypes.object.isRequired

  mixins: [
    CustomRenderMixin
  ]

  render: ->
    approvalStatus = @props.data.get 'approvalStatus'

    <tbody className={approvalStatus}>
      <QuestionRatingRow
        id={@props.data.get 'id'}
        question={@props.data.get 'question'}
        medianRating={@props.data.get 'medianRating'}
        approvalStatus={approvalStatus}
        onChangeApprovalStatus={@props.onChangeApprovalStatus}
        readOnly={@props.readOnly}
      />
    </tbody>

module.exports = QuestionRatings
