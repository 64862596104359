var Chaplin, Collection,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Chaplin = require('chaplin');

module.exports = Collection = (function(_super) {
  __extends(Collection, _super);

  function Collection() {
    return Collection.__super__.constructor.apply(this, arguments);
  }

  Collection.prototype.update = function(response, idAttribute, options) {
    var attrs, i, ids, model, preexistent, _i, _ids, _len, _results;
    if (options == null) {
      options = {};
    }
    if (response instanceof Collection) {
      response = response.toJSON();
    }
    ids = _(response).pluck(idAttribute);
    _ids = _(ids);
    i = this.models.length;
    while (i--) {
      model = this.models[i];
      if (!_ids.include(model.id)) {
        this.remove(model);
      }
    }
    _results = [];
    for (i = _i = 0, _len = response.length; _i < _len; i = ++_i) {
      attrs = response[i];
      preexistent = this.get(ids[i]);
      if (preexistent) {
        _results.push(preexistent.set(attrs, options));
      } else {
        _results.push(this.add(attrs, _(options).extend({
          at: i
        })));
      }
    }
    return _results;
  };

  Collection.prototype.create = function(model, options) {
    var preparedModel, wholeModelValidationError;
    preparedModel = this._prepareModel(model, options);
    if (!preparedModel) {
      return false;
    }
    wholeModelValidationError = preparedModel.validate();
    if (wholeModelValidationError) {
      if (options != null) {
        if (typeof options.error === "function") {
          options.error(preparedModel, wholeModelValidationError, options);
        }
      }
      return false;
    } else {
      return Collection.__super__.create.apply(this, arguments);
    }
  };

  return Collection;

})(Chaplin.Collection);
