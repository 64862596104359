Translation = require 'components/mixins/translation'

Toolbar = createReactClass
  displayName: 'KeyMessagesCreatorToolbar'
  mixins: [Translation('key_messages:creator')]
  propTypes:
    creating: PropTypes.bool.isRequired

  render: ->
    { creating } = @props

    <div className="px-10">
      <h3>{@i18n if creating then 'creating_title' else 'editing_title'}</h3>
    </div>

module.exports = Toolbar
