mediator = require 'mediator'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
{ PLACEMENT_BEFORE, PLACEMENT_AFTER } = require 'lib/document_sections_v2_helper'

module.exports =
  POPULATION_GROUP_ITEM_DND_TYPE: 'populationGroupItem'

  dragSpec:
    beginDrag: (props) ->
      populationId: props.population.get('_id')
      initialParentId: props.population.get('parentGroup')
      index: props.index
      ancestors: props.ancestors
    endDrag: (props, monitor) ->
      item = monitor.getItem()
      dropResult = monitor.getDropResult()

      if !dropResult
        return
      if dropResult.populationId is item.populationId
        DocumentSectionsV2Actions.saveSection(props.section)
      else
        DocumentSectionsV2Actions.changeParentGroupId({
          section: props.section,
          populationId: item.populationId
          newParentId: dropResult.populationId
        })

    isDragging: (props, monitor) ->
      props.population.get('_id') is monitor.getItem().populationId

  dropSpec:
    hover: (props, monitor, component) ->
      if !component
        return
      node = component.item
      if !node
        return

      draggingItem = monitor.getItem()
      dragIndex = draggingItem.index
      hoverIndex = props.index

      if dragIndex is hoverIndex
        return
      hoverBoundingRect = node.getBoundingClientRect()
      hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      clientOffset = monitor.getClientOffset()
      hoverClientY = clientOffset.y - hoverBoundingRect.top

      if dragIndex < hoverIndex && hoverClientY < hoverMiddleY
        return
      if dragIndex > hoverIndex && hoverClientY > hoverMiddleY
        return
      if props.ancestors.includes draggingItem.populationId
        return

      hoverPopulationId = props.population.get('_id')
      placement = if dragIndex < hoverIndex then PLACEMENT_AFTER else PLACEMENT_BEFORE

      DocumentSectionsV2Actions.movePopulationGroup({
        section: props.section
        populationId: draggingItem.populationId,
        targetPopulationId: hoverPopulationId,
        placement
      })

      draggingItem.index = hoverIndex

    drop: (props, monitor, component) ->
      populationId: props.population.get('_id')

    canDrop: (props, monitor) ->
      draggingItem = monitor.getItem()
      !props.ancestors.includes draggingItem.populationId

  dragCollect: (connect, monitor) ->
    connectDragSource: connect.dragSource()
    isDragging: monitor.isDragging()

  dropCollect: (connect, monitor) ->
    connectDropTarget: connect.dropTarget()
    isOver: monitor.isOver()
