var localizeDates;

localizeDates = function() {
  var getDays, getMonths, getTranslationKey, months, weekdays;
  months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
  weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  getTranslationKey = function(type, key, suffix) {
    return "dates." + type + "." + key + suffix;
  };
  getMonths = function(suffix) {
    var mon, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = months.length; _i < _len; _i++) {
      mon = months[_i];
      _results.push($.t(getTranslationKey('months', mon, suffix)));
    }
    return _results;
  };
  getDays = function(suffix) {
    var day, _i, _len, _results;
    _results = [];
    for (_i = 0, _len = weekdays.length; _i < _len; _i++) {
      day = weekdays[_i];
      _results.push($.t(getTranslationKey('weekdays', day, suffix)));
    }
    return _results;
  };
  moment.lang('custom', {
    months: getMonths(''),
    monthsShort: getMonths('_short'),
    weekdays: getDays(''),
    weekdaysShort: getDays('_short'),
    weekdaysMin: getDays('_min'),
    calendar: {
      sameDay: $.t('dates.calendar.sameDay'),
      nextDay: $.t('dates.calendar.nextDay'),
      nextWeek: $.t('dates.calendar.nextWeek'),
      lastDay: $.t('dates.calendar.lastDay'),
      lastWeek: $.t('dates.calendar.lastWeek')
    },
    relativeTime: {
      future: "" + ($.t('dates.relative.future')) + " %s",
      past: "%s " + ($.t('dates.relative.past')),
      s: $.t('dates.relative.few_seconds'),
      m: $.t('dates.relative.minute'),
      mm: "%d " + ($.t('dates.relative.minutes')),
      h: $.t('dates.relative.hour'),
      hh: "%d " + ($.t('dates.relative.hours')),
      d: $.t('dates.relative.day'),
      dd: "%d " + ($.t('dates.relative.days')),
      M: $.t('dates.relative.month'),
      MM: "%d " + ($.t('dates.relative.months')),
      y: $.t('dates.relative.year'),
      yy: "%d " + ($.t('dates.relative.years'))
    }
  });
  moment.lang('custom');
  _($.datepicker.regional['']).extend({
    monthNames: getMonths(''),
    monthNamesShort: getMonths('_short'),
    dayNames: getDays(''),
    dayNamesShort: getDays('_short'),
    dayNamesMin: getDays('_min'),
    closeText: $.t('dates.date_picker.close_text'),
    prevText: $.t('dates.date_picker.prev_text'),
    nextText: $.t('dates.date_picker.next_text'),
    currentText: $.t('dates.date_picker.current_text'),
    weekHeader: $.t('dates.date_picker.week_header')
  });
  return $.datepicker.setDefaults($.datepicker.regional['']);
};

module.exports = localizeDates();
