var APPENDIX_EVIDENCE_TABLES_ID, APPENDIX_EVIDENCE_TABLES_TITLE, APPENDIX_REFERENCES_ID, APPENDIX_REFERENCES_SECTION_ID, APPENDIX_REFERENCES_TITLE, Editor, W, exportEpiditorContent, getHyperlinkPluginOptions, processMdgCodes, processTitle, referencesInsertExtension, removeHTMLNode, xmlCharactersMap, xmlRegex;

Editor = Epiditor["default"];

getHyperlinkPluginOptions = require('lib/document_sections_v2_helper').getHyperlinkPluginOptions;

referencesInsertExtension = require('components/document_sections/v2/editor_extensions/insert/insert');

removeHTMLNode = require('base/lib/utils').removeHTMLNode;

W = require('when/when');

APPENDIX_EVIDENCE_TABLES_ID = 'appendix-evidence-tables';

APPENDIX_EVIDENCE_TABLES_TITLE = $.t('docsec:v2.appendix_evidence_tables_title');

APPENDIX_REFERENCES_ID = 'appendix-references';

APPENDIX_REFERENCES_SECTION_ID = 'appendix-references-section';

APPENDIX_REFERENCES_TITLE = $.t('docsec:v2.appendix_references_title');

exportEpiditorContent = function(_arg) {
  var additionalSectionData, chapterPath, chaptersPaths, content, exportDate, guidelineId, linkPrefix, sectionId, sectionParentPaths, title, type;
  additionalSectionData = _arg.additionalSectionData, chapterPath = _arg.chapterPath, chaptersPaths = _arg.chaptersPaths, content = _arg.content, exportDate = _arg.exportDate, guidelineId = _arg.guidelineId, linkPrefix = _arg.linkPrefix, sectionId = _arg.sectionId, sectionParentPaths = _arg.sectionParentPaths, title = _arg.title, type = _arg.type;
  return W.promise(function(resolve) {
    var chapterLinkURLProvider, element, onLoad, reactContainer, sectionLinkURLProvider, sectionLinksExtension;
    sectionLinksExtension = require('components/document_sections/v2/editor_extensions/section_links/section_links');
    sectionLinkURLProvider = function(linkedSectionId) {
      var sectionParentPath;
      sectionParentPath = sectionParentPaths[linkedSectionId];
      return "" + linkPrefix + sectionParentPath + "#" + linkedSectionId;
    };
    chapterLinkURLProvider = function(linkedChapterId) {
      return "" + linkPrefix + chaptersPaths[linkedChapterId];
    };
    reactContainer = document.createElement('div');
    onLoad = function(_arg1) {
      var dom;
      dom = _arg1.dom;
      return _.defer(function() {
        var div, fullReferencesData, htmlContent, references;
        div = document.createElement('div');
        $(dom).find('span.epiditor--annotation').removeClass('epiditor--annotation');
        $(dom).find('span.changed').remove();
        $(dom).find('span.changed-reverted').removeClass('changed-reverted');
        htmlContent = $(div).addClass('epiditor--container').prop('innerHTML', dom.outerHTML).attr('id', sectionId).prop('outerHTML');
        references = [];
        fullReferencesData = [];
        $(div).find('span.reference').each(function(_idx, element) {
          var refIds, _ref, _ref1;
          refIds = (_ref = element.dataset['refIds']) != null ? _ref : '';
          if (!_.isEmpty(refIds)) {
            references.push(refIds.split(','));
          }
          return fullReferencesData.push(JSON.parse((_ref1 = element.dataset['refs']) != null ? _ref1 : '{}'));
        });
        removeHTMLNode(div);
        ReactDOM.unmountComponentAtNode(reactContainer);
        removeHTMLNode(reactContainer);
        return resolve({
          references: references,
          referencesFromTables: [],
          fullReferencesData: fullReferencesData,
          sections: [
            _.extend({}, additionalSectionData, {
              id: "" + guidelineId + "/" + chapterPath + "/" + sectionId,
              guid: sectionId,
              chapterId: chapterPath,
              guidelineId: guidelineId,
              htmlContent: htmlContent,
              lastModified: exportDate,
              title: (title != null) && title !== '' ? title : void 0,
              type: type
            })
          ]
        });
      });
    };
    element = React.createElement(Editor, {
      extensions: [referencesInsertExtension(true), sectionLinksExtension(sectionLinkURLProvider, chapterLinkURLProvider)],
      initialState: content,
      mode: 'readonly',
      onLoad: onLoad,
      pluginProps: {
        hyperlink: getHyperlinkPluginOptions(true)
      }
    });
    return ReactDOM.render(element, reactContainer);
  });
};

processMdgCodes = function(codes) {
  return _.chain(codes).values().map(function(code) {
    return {
      type: code.codeType,
      value: code.value
    };
  }).sortBy(['type', 'value']).value();
};

xmlCharactersMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&apos;'
};

xmlRegex = new RegExp("[" + (_.keys(xmlCharactersMap).join('')) + "]", 'g');

processTitle = function(title) {
  return title.replace(xmlRegex, function(c) {
    return xmlCharactersMap[c];
  });
};

module.exports = {
  APPENDIX_EVIDENCE_TABLES_ID: APPENDIX_EVIDENCE_TABLES_ID,
  APPENDIX_EVIDENCE_TABLES_TITLE: APPENDIX_EVIDENCE_TABLES_TITLE,
  APPENDIX_REFERENCES_ID: APPENDIX_REFERENCES_ID,
  APPENDIX_REFERENCES_SECTION_ID: APPENDIX_REFERENCES_SECTION_ID,
  APPENDIX_REFERENCES_TITLE: APPENDIX_REFERENCES_TITLE,
  exportEpiditorContent: exportEpiditorContent,
  processMdgCodes: processMdgCodes,
  processTitle: processTitle
};
