Spinner = require 'components/common/spinner'

FetchingSpinner =
  render: ->
    if @state.isFetching and not @_fetchedDataRendered
      <Spinner />
    else
      @_fetchedDataRendered = true
      @renderFetched()

module.exports = FetchingSpinner
