var INITIAL_STATE, ProjectActions, generateStore, mediator;

generateStore = require('stores/utils/generate_store');

ProjectActions = require('actions/project_actions');

mediator = require('mediator');

INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false
  },
  project: {}
});

module.exports = generateStore({
  name: 'ProjectStore',
  initialState: INITIAL_STATE,
  boundActions: [ProjectActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.set('project', Immutable.fromJS(mediator.project.toJSON())));
    },
    onDbChange: function(doc) {
      return this.setState(this.state.set('project', Immutable.fromJS(doc)));
    },
    getProject: function() {
      return this.state.get('project', Immutable.Map());
    }
  }
});
