QS = require 'models/outcome/quality_scores'
ReferencesActions = require 'actions/references_actions'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ReferencesStore = require 'stores/references_store'
StudyLevelRoB = require 'components/rob/study_level_rob'
OutcomeLevelRob = require 'components/rob/outcome_level_rob'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ConnectStore = require 'components/enhancers/connect_store'

storeConnectors =
  ReferencesStore: (ReferencesStore, props) ->
    hasEverFetched: ReferencesStore.hasEverFetched()
    isFetching: ReferencesStore.isFetching()
    references: ReferencesStore.getReferenceByIds(props.refIds)

RobScreen = createReactClass
  displayName: 'RobScreen'

  mixins: [
    Translation('es:outcome')
    CustomRenderMixin
  ]

  getInitialState: ->
    changedReferences: {}
    final: @props.model.get('riskOfBias')?.name
    acrossStudies: @props.model.get 'rob_acrossStudies'
    limitations: @props.model.get 'rob_limitations'

  getReferences: ->
    @props.references.map (ref) =>
      refId = ref.get('_id')
      if refId of @state.changedReferences
        ref.merge @state.changedReferences[refId]
      else
        ref

  handleApply: ->
    referencesToSave = R.toPairs @state.changedReferences
      .map ([refId, refData]) -> R.assoc '_id', refId, refData

    ReferencesActions.saveReferences referencesToSave, @props.references
    @props.model.set
      riskOfBias: QS.get @state.final
      rob_acrossStudies: @state.acrossStudies
      rob_limitations: @state.limitations
    @props.model.save()
    @props.onClose()

  handleRobLevelChange: (prop, value, ref) ->
    refId = ref.get '_id'
    updatedRef = R.assoc prop, value, @state.changedReferences[refId] ? {}

    @setState changedReferences: R.assoc refId, updatedRef, @state.changedReferences

  outcomeAssessmentChanged: (attrName) -> (v) =>
    @setState R.objOf attrName, v

  render: ->
    <Modal
      className='rob-view'
      isOpen={true}
      modalSize='medium'
      onRequestClose={@props.onClose}
      title={@i18n 'assess_rob'}
      >
      <div className="container">
        <StudyLevelRoB
          onLevelChange={@handleRobLevelChange}
          references={@getReferences()}
          outcomeId={@props.model.id}
          />
      </div>
      <OutcomeLevelRob
        outcome={@props.model}
        finalChanged={@outcomeAssessmentChanged 'final'}
        limiationsChanged={@outcomeAssessmentChanged 'limitations'}
        acrossStudiesChanged={@outcomeAssessmentChanged 'acrossStudies'}
        final={@state.final}
        acrossStudies={@state.acrossStudies}
        limitations={@state.limitations}
      />
      <ApplyCancelButtons onApply={@handleApply} onCancel={@props.onClose} />
    </Modal>

module.exports = ConnectStore RobScreen, ReferencesStore, storeConnectors
