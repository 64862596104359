ApprovalQuestionsGroup = require 'components/scope/questions/approval_questions_group'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
LoadingBox = require 'components/common/loading_box'
QuestionsShowCommentsMixin = require 'components/mixins/questions_show_comments_mixin'
ScopeDataFetchMixin = require 'components/mixins/scope_data_fetch_mixin'
Translation = require 'components/mixins/translation'

{ Button } = ReactComponents
{ license_url } = require 'lib/constants'

ApprovalTab = createReactClass
  displayName: 'ApprovalTab'

  propTypes:
    questions: PropTypes.instanceOf(Immutable.Map).isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    membersMap: PropTypes.instanceOf(Immutable.Map).isRequired
    membersSentTo: PropTypes.instanceOf(Immutable.List).isRequired
    membersSubmitted: PropTypes.instanceOf(Immutable.List).isRequired
    readOnly: PropTypes.bool
    questionsLimit: PropTypes.number.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:questions.approval')
    ScopeDataFetchMixin
    QuestionsShowCommentsMixin
  ]

  renderQuestionsGroups: ->
    @props.questionGroups.map (questionsIds, approvalStatus) =>
      questions = questionsIds.map (questionsId) => @props.questions.get questionsId

      <ApprovalQuestionsGroup
        key={approvalStatus}
        type={approvalStatus}
        questions={questions}
        membersMap={@props.membersMap}
        todos={@props.todos}
        onCommentToggle={@onItemCommentToggle}
        showingComments={@state.showingAllComments}
        readOnly={@props.readOnly}
      />
    .toList()

  render: ->
    total = @props.membersSentTo.size
    completed = @props.membersSubmitted.size

    <div className='questions-approval'>
      <div className='information' dangerouslySetInnerHTML={{__html: @i18n 'header'}} />
      {@props.questionsLimit isnt -1 and <div
        className='gdt-limits-content-box'
        dangerouslySetInnerHTML={{
          __html: @i18n '../questions_limits_info',
            limit: @props.questionsLimit,
            link: license_url
        }}
      />}
      <div className='buttons'>
        <span className="responded">
          {@i18n '../../outcomes.responded'} {completed} {@i18n '../../outcomes.of'} {total}
        </span>
        <Button
          className='btn-refresh'
          onClick={@props.fetchResults}
          disabled={@_isFetchDisabled()}
        >
          {@i18n '../brainstorming.refresh'}
        </Button>
        <Button
          className='show-all-comments'
          onClick={@showComments}
          disabled={@_isShowCommentsDisabled()}
        >
          {@i18n '../show_all_comments'}
        </Button>
      </div>
      {if @props.isFetchingResults
        <LoadingBox />
      else
        <div className='questions'>
          {@renderQuestionsGroups()}
        </div>
      }
      {unless @props.readOnly
        <div className='bottom-bar row mt-20'>
          <div className='col-12'>
            <button
              className='btn btn-block btn-send'
              onClick={@props.onCloseStep}
              disabled={@props.isClosingStep}
            >
              {@i18n 'next_step'}
            </button>
          </div>
        </div>
      }
    </div>

module.exports = ApprovalTab
