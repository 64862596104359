var GENERAL_INFORMATION_REGULAR_SWITCHES, GENERAL_INFORMATION_SERVER_SWITCHES, GeneralInformationView, MDGTopicsView, ModuleController, SettingsController, TemplatesSettingsView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

GeneralInformationView = require('views/settings/general_information_view');

mediator = require('mediator');

MDGTopicsView = require('views/settings/mdg_topics_view');

ModuleController = require('controllers/base/module_controller');

TemplatesSettingsView = require('views/settings/templates_settings_view');

GENERAL_INFORMATION_SERVER_SWITCHES = ['covidFeatures', 'tbFeatures', 'mdgFeatures'];

GENERAL_INFORMATION_REGULAR_SWITCHES = ['publicationPlatform'];

module.exports = SettingsController = (function(_super) {
  __extends(SettingsController, _super);

  function SettingsController() {
    return SettingsController.__super__.constructor.apply(this, arguments);
  }

  SettingsController.prototype.defaultSubmodule = function() {
    var generalInformationAvailable, regularSwitchOn, serverSwitchOn;
    serverSwitchOn = _.any(GENERAL_INFORMATION_SERVER_SWITCHES, function(serverSwitch) {
      return mediator.services.switches.isServerSwitchOn(serverSwitch);
    });
    regularSwitchOn = _.any(GENERAL_INFORMATION_REGULAR_SWITCHES, function(regularSwitch) {
      return mediator.services.switches.isOn(regularSwitch);
    });
    generalInformationAvailable = serverSwitchOn || regularSwitchOn;
    if (generalInformationAvailable) {
      return 'general-information';
    } else {
      return 'etd-templates';
    }
  };

  SettingsController.prototype.viewsForSubmodules = {
    'etd-templates': TemplatesSettingsView,
    'general-information': GeneralInformationView,
    'mdg-topics': MDGTopicsView
  };

  return SettingsController;

})(ModuleController);
