var ClearFormatButton;

ClearFormatButton = MediumEditor.extensions.button.extend({
  name: 'clearFormat',
  contentDefault: '<b>X</b>',
  aria: 'Clear formatting',
  action: 'clearFormat',
  init: function() {
    return MediumEditor.extensions.button.prototype.init.call(this);
  },
  handleClick: function(event) {
    this.replaceSelectedText(this.cleanHTML(this.getSelectionHtml()));
    return this.base.checkContentChanged();
  },
  getSelectionHtml: function() {
    var container, html, i, sel, _i, _ref;
    html = "";
    sel = window.getSelection();
    if (sel.rangeCount) {
      container = document.createElement("div");
      for (i = _i = 0, _ref = sel.rangeCount; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
        container.appendChild(sel.getRangeAt(i).cloneContents());
      }
      html = container.innerHTML;
    }
    return html;
  },
  pasteHtmlAtCaret: function(html, selectPastedContent) {
    var el, firstNode, frag, lastNode, node, range, sel;
    sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      el = document.createElement("div");
      el.innerHTML = html;
      frag = document.createDocumentFragment();
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      firstNode = frag.firstChild;
      range.insertNode(frag);
      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        if (selectPastedContent) {
          range.setStartBefore(firstNode);
        } else {
          range.collapse(true);
        }
        sel.removeAllRanges();
        return sel.addRange(range);
      }
    }
  },
  replaceSelectedText: function(replacementHtml) {
    var range, sel;
    sel = window.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      return this.pasteHtmlAtCaret(this.cleanHTML(replacementHtml), true);
    }
  },
  cleanHTML: function(input) {
    var attributeStripper, badAttribute, badAttributes, badTag, badTags, commentSripper, output, stringStripper, tagStripper, _i, _j, _len, _len1;
    stringStripper = /(\n|\r| class="?Mso[a-zA-Z]+"?)/g;
    output = input.replace(stringStripper, ' ');
    commentSripper = /<!--(.*?)-->/g;
    output = output.replace(commentSripper, '');
    tagStripper = new RegExp('<(/)*(meta|link|span|a|h1|h2|h3|h4|h5|h6|\\?xml:|st1:|o:|font)(.*?)>', 'gi');
    output = output.replace(tagStripper, '');
    badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript'];
    for (_i = 0, _len = badTags.length; _i < _len; _i++) {
      badTag = badTags[_i];
      tagStripper = new RegExp('<' + badTag + '.*?' + badTag + '(.*?)>', 'gi');
      output = output.replace(tagStripper, '');
    }
    badAttributes = ['style', 'start'];
    for (_j = 0, _len1 = badAttributes.length; _j < _len1; _j++) {
      badAttribute = badAttributes[_j];
      attributeStripper = new RegExp(' ' + badAttribute + '="(.*?)"', 'gi');
      output = output.replace(attributeStripper, '');
    }
    return output;
  }
});

module.exports = {
  "default": {
    toolbar: {
      buttons: ['bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'quote', 'clearFormat']
    },
    extensions: {
      clearFormat: new ClearFormatButton()
    },
    paste: {
      forcePlainText: false,
      cleanPastedHTML: false
    }
  }
};
