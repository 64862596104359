headerDisplayName = 'PanelVoiceQuestionsTableHeader'
bodyDisplayName = 'PanelVoiceQuestionsTableBody'

QuestionsTable = createReactClass
  displayName: 'PanelVoiceQuestionsTable'

  render: ->
    <div className='panel-voice-questions'>
      {React.Children.map @props.children, (Child, idx) =>
        childName = Child.type.displayName
        switch childName
          when headerDisplayName then React.cloneElement Child, ref: @_headerRef
          when bodyDisplayName then React.cloneElement Child, ref: @_bodyRef
          else Child
      }
    </div>

module.exports = QuestionsTable
