ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ItemWithCheckbox = require 'components/common/item_with_checkbox'
Modal = require 'components/common/modal'
{ RECOMMENDATION_SECTION_APPENDICES_ORDER } = require 'lib/document_sections_v2_helper'
{ useCoffeeCallback, useCoffeeEffect, useI18n } = require 'lib/react_utils'
{ useState } = React

EditAppendicesModal = ({ isOpen, onApply, onClose, selectedAppendices }) ->
  i18n = useI18n 'docsec:management.section_appendices'
  [currentSelectedAppendices, setCurrentSelectedAppendices] = useState(selectedAppendices)

  onChange = useCoffeeCallback [currentSelectedAppendices, setCurrentSelectedAppendices],
    (key) -> -> setCurrentSelectedAppendices currentSelectedAppendices.update key,
      (selected) -> not selected

  onSave = useCoffeeCallback [onApply, currentSelectedAppendices], ->
    onApply currentSelectedAppendices

  onCancel = useCoffeeCallback [onClose, setCurrentSelectedAppendices], ->
    setCurrentSelectedAppendices selectedAppendices
    onClose()

  useCoffeeEffect [selectedAppendices, setCurrentSelectedAppendices], ->
    setCurrentSelectedAppendices selectedAppendices

  <Modal isOpen={isOpen} title={i18n 'edit_appendices'}>
    <div className="mb-10">
      {_.map RECOMMENDATION_SECTION_APPENDICES_ORDER, (key) ->
        <ItemWithCheckbox
          checked={currentSelectedAppendices.get(key) ? false}
          className="mt-10"
          key={key}
          onChange={onChange key}
        >
          <div>{i18n key}</div>
        </ItemWithCheckbox>
      }
    </div>
    <ApplyCancelButtons
      applyLabel={i18n '/actions.save'}
      onApply={onSave}
      onCancel={onCancel}
    />
  </Modal>

module.exports = EditAppendicesModal
