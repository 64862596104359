Validation = require 'lib/validation'

withValidation = (validationSpec, Target) ->
  # TODO pass validation label to Validation()
  { allValid, validate } = Validation validationSpec, Target.displayName ? ''

  createReactClass
    displayName: 'ValidatedComponentWrapper'

    render: ->
      <Target {...@props} allValid={allValid} validate={validate(@props)}  />

module.exports = withValidation
