DialogBox = require 'components/common/dialog_box'
OptionsList = require 'components/common/options_list'

DialogBoxMixin =

  showInDialogBox: (e, InnerComponent, innerComponentProps, dialogClass) ->
    @boxContainer = $('<div></div>')
    $('body').append @boxContainer

    isOptionsDialog = InnerComponent.displayName is 'OptionsList'
    ReactDOM.render <DialogBox
        offset={top: e.clientY, left: e.clientX}
        dialogClass={classNames dialogClass} isOptionsDialog={isOptionsDialog}>
          <InnerComponent {...innerComponentProps}/>
      </DialogBox>
    , @boxContainer[0]

  closeDialogBox: ->
    ReactDOM.unmountComponentAtNode @boxContainer[0]
    @boxContainer.remove()

module.exports = DialogBoxMixin
