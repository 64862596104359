Button = require 'components/common/button'
mediator = require 'mediator'
Router = require 'router'
Translation = require 'components/mixins/translation'

isPublicationPlatformEnabled = -> mediator.services.switches.isOn 'publicationPlatform'

callFunctionOrGetValue = (value) -> if _.isFunction(value) then value() else value

ITEMS = [
  key: 'decision_aids'
  image: 'decision-aids.png'
  submodule: 'decision-aids'
,
  key: -> if isPublicationPlatformEnabled() then 'publication_platform' else 'dbep'
  image: 'dbep.svg'
  submodule: -> if isPublicationPlatformEnabled() then 'publication-platform' else 'dbep'
,
  key: 'mobile'
  image: 'mobile.png'
  submodule: 'mobile'
]

DisseminationChannel = createReactClass
  displayName: 'DisseminationChannel'
  mixins: [Translation('')]

  propTypes:
    name: PropTypes.string.isRequired
    description: PropTypes.string.isRequired
    image: PropTypes.string.isRequired
    submodule: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired

  navigateTo: ->
    submodule = callFunctionOrGetValue @props.submodule
    route = Router::getProjectRelativeUrl "/dissemination/#{submodule}"
    mediator.publish '!router:route', route

  render: ->
    { name, description, image } = @props
    <div className="dissemination-items__dissemination-item">
      <h4>{name}</h4>
      <p className="dissemination-item__image">
        <img src={"images/dissemination/#{image}"} alt={name} />
      </p>
      <p className="dissemination-item__description">
        {description}
      </p>
      <div className="dissemination-item__button">
        <Button
          className="btn btn-apply"
          onClick={@navigateTo}
          label={@i18n 'actions.go_to'}
        />
      </div>
    </div>

DisseminationScreen = createReactClass
  displayName: 'DisseminationScreen'
  mixins: [Translation('dissemination:titles')]

  render: ->
    <div className="dissemination-screen">
      <div className="dissemination-screen__dissemination-items">
        {_.map(ITEMS, ({ submodule, image, key }) =>
          key = callFunctionOrGetValue key
          <DisseminationChannel
            name={@i18n key}
            description={@i18n "../main_screen.#{key}"}
            submodule={submodule}
            image={image}
            key={key}
          />
        )}
      </div>
    </div>

module.exports = DisseminationScreen
