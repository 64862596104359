var EtdSeparateDesirableEffect, Migration, W, getInitialContent, mediator, migrationName,
  __slice = [].slice,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'etd_separate_desirable_effect';

getInitialContent = function(doc, contentPath, sectionKey) {
  var currentContent;
  currentContent = doc.getIn(__slice.call(contentPath).concat([sectionKey], ['content']));
  if (!_.isEmpty(currentContent)) {
    return doc.getIn(__slice.call(contentPath).concat([sectionKey]));
  } else if (!doc.hasIn(__slice.call(contentPath).concat(['effects']))) {
    return Immutable.fromJS({
      content: ""
    });
  } else {
    return doc.getIn(__slice.call(contentPath).concat(['effects']));
  }
};

module.exports = EtdSeparateDesirableEffect = (function(_super) {
  __extends(EtdSeparateDesirableEffect, _super);

  function EtdSeparateDesirableEffect() {
    EtdSeparateDesirableEffect.__super__.constructor.call(this, false);
  }

  EtdSeparateDesirableEffect.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    EtdSeparateDesirableEffect.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds')).concat(q.get('recommendationAdolopmentIds', []));
    }, []);
    docIds = docIds.concat(['etd-tx-template', 'etd-dx-template']);
    return adapter.fetch(projectId, _.compact(_.uniq(docIds))).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return;
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        var contentPath, immDoc, initialDesirableEffectsContent, initialUnDesirableEffectsContent, sectionsPath;
        immDoc = Immutable.fromJS(doc);
        sectionsPath = ['templateData', 'assessment', 'sections'];
        contentPath = ['templateData', 'assessment', 'researchEvidences'];
        immDoc = immDoc.setIn(__slice.call(sectionsPath).concat(['desirableEffects'], ['researchEvidenceId']), 'desirableEffects');
        immDoc = immDoc.setIn(__slice.call(sectionsPath).concat(['undesirableEffects'], ['researchEvidenceId']), 'undesirableEffects');
        initialDesirableEffectsContent = getInitialContent(immDoc, contentPath, 'desirableEffects');
        initialUnDesirableEffectsContent = getInitialContent(immDoc, contentPath, 'undesirableEffects');
        immDoc = immDoc.setIn(__slice.call(contentPath).concat(['desirableEffects']), initialDesirableEffectsContent);
        immDoc = immDoc.setIn(__slice.call(contentPath).concat(['undesirableEffects']), initialUnDesirableEffectsContent);
        immDoc = immDoc.deleteIn(__slice.call(contentPath).concat(['effects']));
        doc = immDoc.toJS();
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return EtdSeparateDesirableEffect;

})(Migration);
