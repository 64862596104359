var HeaderProjectsList, ReactComponent, UserMenuView, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

HeaderProjectsList = require('components/header/header_projects_list');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

module.exports = UserMenuView = (function(_super) {
  __extends(UserMenuView, _super);

  function UserMenuView() {
    return UserMenuView.__super__.constructor.apply(this, arguments);
  }

  UserMenuView.prototype.container = 'nav.projects';

  UserMenuView.prototype.autoRender = true;

  UserMenuView.prototype.initialize = function() {
    UserMenuView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  UserMenuView.prototype.afterRender = function() {
    UserMenuView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(HeaderProjectsList, this.options);
  };

  UserMenuView.prototype.dispose = function() {
    this.unmountComponent();
    return UserMenuView.__super__.dispose.apply(this, arguments);
  };

  return UserMenuView;

})(View);
