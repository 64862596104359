Button = require 'components/common/button'
CheckboxInput = require 'components/common/checkbox_input'
IconButton = require 'components/common/icon_button'
MdgSex = require 'lib/db_docs/field_types/mdg_sex_type'
StringField = require 'components/covid_extraction_form/string_field'
Translation = require 'components/mixins/translation'

SexField = createReactClass
  displayName: 'SexField'

  mixins: [Translation('mdg_tables:edit_form')]

  propTypes:
    onChange: PropTypes.func.isRequired
    value: PropTypes.arrayOf(PropTypes.shape(
      count: PropTypes.number
      name: PropTypes.string
      selected: PropTypes.bool.isRequired
      value: PropTypes.string.isRequired
    )).isRequired

  onChange: (field, idx, fieldType = 'text') -> (evtOrValue) =>
    { onChange, value } = @props
    newValue = switch fieldType
      when 'checkbox'
        evtOrValue.target.checked
      when 'number'
        parsed = parseInt(evtOrValue)
        if _.isNaN(parsed)
          undefined
        else
          parsed
      else
        evtOrValue
    obj = {}
    obj[field] = newValue
    value[idx] = _.extend {}, value[idx], obj
    onChange value

  onRemoveGroup: (idx) -> =>
    { onChange, value } = @props
    value.splice idx, 1
    onChange value

  onAddGroup: ->
    { onChange, value } = @props
    value.push MdgSex.defaults selected:true
    onChange value

  render: ->
    { value: groups } = @props

    <div className="sex-field">
      <div className="sex-field__groups">
        {_.map groups, ({ count, name, selected, value }, idx) =>
          <div className="groups__group" key={idx}>
            <div className="group__selected">
              <CheckboxInput
                checked={selected}
                onChange={@onChange('selected', idx, 'checkbox')}
              />
            </div>
            {name and <React.Fragment>
              <div className="group__name">{name}</div>
              <div className="group__count">
                <StringField
                  onChange={@onChange('count', idx, 'number')}
                  type="number"
                  value={count}
                />
              </div>
            </React.Fragment>}
            <div className="group__value">
              <StringField onChange={@onChange('value', idx)} value={value} />
            </div>
            <div className="group__remove-group-button">
              {not name and <IconButton iconName="cross" onClick={@onRemoveGroup(idx)} />}
            </div>
          </div>
        }
      </div>
      <div className="sex-field__add-group-button">
        <Button
          className="btn btn-apply"
          label={@i18n '/actions.add_group'}
          onClick={@onAddGroup}
        />
      </div>
    </div>

module.exports = SexField
