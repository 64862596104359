var ChapterDoc, ProjectActions, QUESTION_STATUSES_DOC_TYPE, QuestionsActions, QuestionsStatusesActions, ReferencesActions, SectionDoc, TasksActions, W, fetchDashboardHistory, generateActions, mediator;

ChapterDoc = require('lib/db_docs/document_sections_v2_chapter_doc');

fetchDashboardHistory = require('actions/async/history_api').fetchDashboardHistory;

generateActions = require('actions/utils/generate_actions');

mediator = require('mediator');

ProjectActions = require('actions/project_actions');

QuestionsActions = require('actions/questions_actions');

QuestionsStatusesActions = require('actions/questions_statuses_actions');

QUESTION_STATUSES_DOC_TYPE = require('lib/db_docs/doc_types').QUESTION_STATUSES_DOC_TYPE;

ReferencesActions = require('actions/references_actions');

SectionDoc = require('lib/db_docs/document_sections_v2_section_doc');

TasksActions = require('actions/tasks_actions');

W = require('when');

module.exports = generateActions({
  autoGenerate: ['clearFilters', 'closeConfirmationDialog', 'closeHistoryDialog', 'fetchError', 'fetchSuccess', 'fetchHistoryError', 'fetchHistorySuccess', 'openConfirmationDialog', 'selectFilterCategory', 'selectFilterItems'],
  name: 'DashboardActions',
  actions: {
    dbChange: function(_arg) {
      var doc;
      doc = _arg.doc;
      switch (doc.docType) {
        case 'project':
          return ProjectActions.dbChange({
            doc: doc
          });
        case QUESTION_STATUSES_DOC_TYPE:
          return QuestionsStatusesActions.dbChange({
            doc: doc
          });
      }
    },
    fetch: function() {
      this.dispatch();
      ReferencesActions.fetch();
      ReferencesActions.fetchUsedReferences();
      QuestionsActions.fetch(mediator.project.id);
      QuestionsStatusesActions.fetch(mediator.project.id);
      TasksActions.fetch(mediator.project.id);
      return ProjectActions.fetch(mediator.project.id).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
    },
    fetchHistory: function(errorShouldCloseModal) {
      if (errorShouldCloseModal == null) {
        errorShouldCloseModal = false;
      }
      this.dispatch();
      return W.all([fetchDashboardHistory(mediator.project.id), ChapterDoc.at(mediator.project.id).fetchMany(), SectionDoc.at(mediator.project.id).fetchMany()]).then((function(_this) {
        return function(_arg) {
          var docSecChapters, docSecSections, history;
          history = _arg[0], docSecChapters = _arg[1], docSecSections = _arg[2];
          return _this.actions.fetchHistorySuccess({
            docSecChapters: docSecChapters,
            docSecSections: docSecSections,
            history: history
          });
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          return _this.actions.fetchHistoryError({
            err: err,
            errorShouldCloseModal: errorShouldCloseModal
          });
        };
      })(this));
    },
    openHistoryDialogAndFetchHistory: function() {
      if (mediator.services.replication.isOnline()) {
        this.dispatch();
        return this.actions.fetchHistory(true);
      } else {
        return mediator.dialogs.error({
          message: $.t('dashboard.history_modal.offline_error')
        });
      }
    }
  }
});
