var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "  <div>\n    <div class=\"edit-input-block mt-20\" data-property=\"narrativeDesc\">\n      <div id=\"narrative-desc-react\" class=\"cell-edit-editor\" name=\"narrativeDesc\"></div>\n      <div class=\"sup\"></div>\n    </div>\n  </div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "narrativeDesc", "narrative_desc", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}