ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'
MultiSelect = require 'components/common/multi_select'
QuestionsStore = require 'stores/questions_store'
ValidationContainer = require 'components/common/validation_container'
{ pluck: immPluck } = require 'lib/immutable_utils'


DecisionAidOutcomesModal = createReactClass
  displayName: 'DecisionAidOutcomesModal'

  propTypes:
    isOpen: PropTypes.bool.isRequired
    onClose: PropTypes.func.isRequired
    onApply: PropTypes.func.isRequired
    outcomes: PropTypes.instanceOf(Immutable.List).isRequired
    questionId: PropTypes.string.isRequired

  mixins: [
    Translation('')
  ]

  getInitialState: ->
    { outcomes: decisionAidOutcomes } = @props
    questionOutcomes =
      QuestionsStore.getQuestion(@props.questionId)?.get('outcomes') ? Immutable.List()
    # filter-out outcomes which were already imported into Decision Aid
    questionOutcomesFiltered = questionOutcomes.filterNot (qOutcome) ->
      qOutcomeId = qOutcome.get '_id'
      decisionAidOutcomes.some (daOutcome) -> daOutcome.get('_id') is qOutcomeId

    outcomesList: decisionAidOutcomes.concat questionOutcomesFiltered
    selectedOutcomes: immPluck(@props.outcomes, '_id').toArray()

  getOutcomesSelectOptions: ->
    @state.outcomesList.map (o) ->
      label: o.get 'name'
      value: o.get '_id'
    .toArray()

  handleOutcomeSelect: (selectedOutcomes) ->
    @setState { selectedOutcomes }

  handleApply: ->
    { outcomesList, selectedOutcomes } = @state

    @props.onApply @state.outcomesList.filter (o) -> o.get('_id') in selectedOutcomes
    @props.onClose()

  componentDidUpdate: (prevProps, prevState) ->
    if @props.isOpen and not prevProps.isOpen
      @setState @getInitialState()

  render: ->
    { selectedOutcomes} = @state
    anyOutcomeSelected = not R.isEmpty selectedOutcomes

    <Modal
      className='decision-aid-outcomes-management-modal'
      isOpen={@props.isOpen}
      modalSize='medium'
      onClose={@props.onClose}
      onRequestClose={@props.onClose}
      title={@i18n 'es:outcomes'}
    >
      <div className={classNames 'outcomes-select', invalid: not anyOutcomeSelected}>
        {unless anyOutcomeSelected
          <div className='error'>
            {@i18n 'da:details-screen.outcomes_select_validation_error'}
          </div>
        }
        <MultiSelect
          onChange={@handleOutcomeSelect}
          options={@getOutcomesSelectOptions()}
          selectedOptions={selectedOutcomes}
        />
      </div>
      <ApplyCancelButtons
        onCancel={@props.onClose}
        onApply={@handleApply}
        isSubmitEnabled={anyOutcomeSelected}
      />
    </Modal>

module.exports = DecisionAidOutcomesModal
