var Chaplin, DiscardChangesActions, DiscardChangesStore, Layout, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Chaplin = require('chaplin');

mediator = require('mediator');

DiscardChangesStore = require('stores/discard_changes_store');

DiscardChangesActions = require('actions/discard_changes_actions');

module.exports = Layout = (function(_super) {
  __extends(Layout, _super);

  function Layout() {
    this.openLink = __bind(this.openLink, this);
    return Layout.__super__.constructor.apply(this, arguments);
  }

  Layout.prototype.initialize = function() {
    Layout.__super__.initialize.apply(this, arguments);
    this.$el.on('click', 'body', this.bodyClicked);
    $(window).on('resize', _.debounce(this.windowResized, 100));
    return this.subscribeEvent('startupController', this.setHeirView);
  };

  Layout.prototype.bodyClicked = function() {
    return mediator.fm.setFocus(mediator.heirView);
  };

  Layout.prototype.windowResized = function() {
    return mediator.publish('windowResized');
  };

  Layout.prototype.setHeirView = function(context) {
    var view;
    view = context.controller.view;
    if (view) {
      mediator.heirView = view;
      return mediator.setFocus(mediator.heirView);
    }
  };

  Layout.prototype.openLink = function(event) {
    var $el, el, href, internal, isAnchor, path, processOpenLink, skipRouting, type, _ref, _ref1, _ref2;
    if (event.target.href === this.currentHref) {
      return false;
    }
    this.currentHref = event.target.href;
    _((function(_this) {
      return function() {
        return _this.currentHref = null;
      };
    })(this)).delay(500);
    if (Chaplin.utils.modifierKeyPressed(event)) {
      return;
    }
    el = event.currentTarget;
    $el = $(el);
    isAnchor = el.nodeName === 'A';
    href = $el.attr('href') || $el.data('href') || null;
    if (href === null || href === void 0 || href === '' || href.charAt(0) === '#') {
      return;
    }
    if (isAnchor && ($el.attr('target') === '_blank' || ((_ref = $el.attr('rel')) != null ? _ref.indexOf('external') : void 0) >= 0 || ((_ref1 = el.protocol) !== 'http:' && _ref1 !== 'https:' && _ref1 !== 'file:' && _ref1 !== 'chrome-extension:'))) {
      return;
    }
    skipRouting = this.settings.skipRouting;
    type = typeof skipRouting;
    if (type === 'function' && !skipRouting(href, el) || type === 'string' && $el.is(skipRouting)) {
      return;
    }
    internal = !isAnchor || ((_ref2 = el.hostname) === location.hostname || _ref2 === '');
    if (!internal) {
      if (this.settings.openExternalToBlank) {
        event.preventDefault();
        window.open(el.href);
      }
      return;
    }
    if (isAnchor) {
      path = el.pathname + el.search;
      if (path.charAt(0) !== '/') {
        path = "/" + path;
      }
    } else {
      path = href;
    }
    mediator.currentFocus.willBeDisposed = true;
    processOpenLink = (function(_this) {
      return function() {
        return _this.publishEvent('!router:route', path, {}, function(routed) {
          if (routed) {
            event.preventDefault();
          } else if (!isAnchor) {
            location.href = path;
          }
        });
      };
    })(this);
    if (DiscardChangesStore.hasChanges()) {
      DiscardChangesActions.setCallback(processOpenLink);
      return false;
    }
    processOpenLink();
  };

  return Layout;

})(Chaplin.Layout);
