IconButton = require 'components/common/icon_button'

SidebarMenu = createReactClass
  displayName: 'SidebarMenu'

  propTypes:
    selectedItemKey: PropTypes.string.isRequired
    items: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired
    }))
    onItemChange: PropTypes.func.isRequired
    contentVisible: PropTypes.bool.isRequired
    onContentVisibleChange: PropTypes.func.isRequired

  render: ->
    { selectedItemKey, items, onItemChange, contentVisible, onContentVisibleChange } = @props

    <ul className="sidebar-menu">
      <li className="sidebar-menu-item">
        <IconButton
          iconName={if contentVisible then 'nav-arrow-right' else 'nav-arrow-left'}
          className="sidebar-menu-item-icon"
          onClick={() => onContentVisibleChange()}
        />
      </li>
      {items.map (item) => (
        <li key={item.key} className="sidebar-menu-item">
          <IconButton
            iconName={item.icon}
            className={classNames(
              'sidebar-menu-item-icon',
              {'sidebar-menu-item-icon-selected': selectedItemKey is item.key}
            )}
            onClick={() => onItemChange(item.key)}
          />
        </li>
      )}
    </ul>

module.exports = SidebarMenu
