DetailedOutcome = require './detailed_outcome'
EtdActions = require 'actions/etd_actions'
Translation = require 'components/mixins/translation'

OutcomesQuestions = createReactClass
  displayName: 'DetailedJudgementsOutcomesQuestions'

  mixins: [Translation('es:recommendations.table')]

  propTypes:
    groupPrefix: PropTypes.string.isRequired
    sectionId: PropTypes.string.isRequired
    questions: PropTypes.object.isRequired
    criticalOutcomes: PropTypes.object.isRequired

  changeRadioOption: (questionIdx) -> (outcomeId) => (value) =>
    EtdActions.updateDetailedOutcomesQuestion @props.sectionId, questionIdx, outcomeId, value,
      @props.isConsensus

  _getSelectedOptionForOutcome: (question, outcomeId) ->
    if @props.isConsensus
      # passing draft_judgement selected option as a notSetValue parameter here in case consenus
      # value is not set yet
      question.getIn ['selectedOptionsConsensus', outcomeId],
        question.getIn(['selectedOptions',outcomeId])
    else
      question.getIn(['selectedOptions', outcomeId])

  _getFilteredOutcomes: (outcomes) ->
    { sectionId } = @props
    outcomes.filter (outcome) ->
      switch sectionId
        when 'desirableEffects'
          return outcome.get('interpretationOfResults') in
            ['favorsIntervention', 'doesNotFavorEither', null]
        when 'undesirableEffects'
          return outcome.get('interpretationOfResults') in
            ['favorsComparison', 'doesNotFavorEither', null]
        else
          true

  render: ->

    { criticalOutcomes, questions, editable } = @props

    <div className="outcomes-questions">
      {questions.map (question, questionIdx) =>
        <div key={questionIdx} className="judgements-table-container">
          <span>{question.get 'question'}</span>
          <table className="judgements-table">
            <thead className="judgements-table-header">
              <tr className="judgement-table-row">
                <th>{@i18n 'main_outcomes'}</th>
                <th>{@i18n 'judgements'}</th>
              </tr>
            </thead>
            <tbody>
              {@_getFilteredOutcomes(criticalOutcomes).map (outcome, outcomeIdx) =>
                <DetailedOutcome
                  additionalOptions={question.get('additionalOptions', Immutable.List())}
                  editable={editable}
                  groupName={"#{@props.groupPrefix}_outcomes_quesitons_#{outcomeIdx}"}
                  key={outcomeIdx}
                  onChange={@changeRadioOption(questionIdx)}
                  options={question.get('options', Immutable.List())}
                  outcome={outcome}
                  selectedOption={@_getSelectedOptionForOutcome question, outcome.get('_id')}
                />
              }
            </tbody>
          </table>
        </div>
      }
    </div>

module.exports = OutcomesQuestions
