UserActions = require 'actions/user_actions'
{ capitalizeFirstLetter } = require 'base/lib/utils'
{ Switcher } = ReactComponents
{ useI18n, useCoffeeMemo, useCoffeeCallback } = require 'lib/react_utils'

ProjectReplicationSwitch = ({ projectStatus, projectId, lastSeq, updateSeq }) ->

  i18n = useI18n 'projects:replication'

  switchProjectReplication = useCoffeeCallback [projectId], ->
    UserActions.switchProjectReplication projectId

  switcherText = useCoffeeMemo [projectStatus, lastSeq, updateSeq], ->
    if projectStatus is 'replication_off'
      <span className="replication-progress__switcher-text">
        {capitalizeFirstLetter i18n 'not_synced'}
      </span>
    else if updateSeq and lastSeq is updateSeq
      <span className="replication-progress__switcher-text">
        {capitalizeFirstLetter i18n 'synced'}
      </span>
    else
      <span
        className="replication-progress__switcher-text replication-progress__switcher-text--loading"
      >
        {capitalizeFirstLetter i18n('loading')}&hellip;
      </span>

  <Switcher
    buttonText={switcherText}
    checked={projectStatus is 'replication_on'}
    onChange={switchProjectReplication}
  />

ProjectReplicationSwitch.propTypes = {
  lastSeq: PropTypes.number
  projectId: PropTypes.string.isRequired
  projectStatus: PropTypes.string.isRequired
  updateSeq: PropTypes.number
}

ProjectReplicationSwitch.defaultProps = {
  lastSeq: null
  updateSeq: null
}

module.exports = ProjectReplicationSwitch
