var array, boolean, documentIdWithAutoGUID, number, object, optional, shape, string, _ref;

_ref = require('lib/db_docs/field_types/built_in_types'), array = _ref.array, boolean = _ref.boolean, number = _ref.number, object = _ref.object, string = _ref.string;

documentIdWithAutoGUID = require('lib/db_docs/field_types/document_id_with_auto_guid');

shape = require('lib/db_docs/field_types/shape');

optional = shape.typeDecorators.optional;

module.exports = shape({
  '_id': documentIdWithAutoGUID,
  '_conflicts': optional(array),
  '_deleted': optional(boolean),
  '_rev': optional(string),
  'tech_comment': optional(string),
  'tech_old_rev': optional(object),
  'rev_author': optional(string),
  '$timestamp': optional(number),
  'docType': optional(string)
});
