var INITIAL_MODAL_STATE, INITIAL_STATE, NMAListActions, generateStore, sortQuestions;

generateStore = require('stores/utils/generate_store');

NMAListActions = require('actions/nma_list_actions');

INITIAL_MODAL_STATE = Immutable.fromJS({
  applying: false,
  isOpen: false,
  question: Immutable.Map(),
  mode: 'create'
});

INITIAL_STATE = Immutable.fromJS({
  questions: Immutable.List(),
  isFetching: false,
  questionModal: INITIAL_MODAL_STATE
});

sortQuestions = function(questions) {
  return questions.sortBy(function(question) {
    return question.get('question');
  });
};

module.exports = generateStore({
  name: 'NMAListStore',
  initialState: INITIAL_STATE,
  boundActions: [NMAListActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.set('isFetching', true));
    },
    onFetchAllQuestionsAndOutocmes: function() {
      return this.setState(this.state.set('isFetching', true));
    },
    onFetchSuccess: function(questions) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetching', false);
        return state.set('questions', sortQuestions(Immutable.fromJS(questions)));
      }));
    },
    onFetchError: function() {
      return this.setState(this.state.set('isFetching', false));
    },
    onDbChange: function(change) {
      var idx, questions;
      questions = this.state.get('questions');
      idx = questions.findIndex(function(question) {
        return question.get('_id') === change.id;
      });
      if (change.deleted) {
        if (!(idx >= 0)) {
          return;
        }
        return this.setState(this.state.set('questions', questions.filter(function(question) {
          return question.get('_id') !== change.id;
        })));
      } else if (idx >= 0) {
        return this.setState(this.state.set('questions', sortQuestions(questions.set(idx, Immutable.fromJS(change.doc)))));
      } else {
        return this.setState(this.state.set('questions', sortQuestions(questions.push(Immutable.fromJS(change.doc)))));
      }
    },
    onOpenQuestionModal: function(question) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['questionModal', 'isOpen'], true);
        state.setIn(['questionModal', 'question'], Immutable.fromJS(question != null ? question : {}));
        return state.setIn(['questionModal', 'mode'], question != null ? 'edit' : 'create');
      }));
    },
    onCloseQuestionModal: function() {
      this.setState(this.state.setIn(['questionModal', 'isOpen'], false));
      return setTimeout((function(_this) {
        return function() {
          return _this.setState(_this.state.set('questionModal', INITIAL_MODAL_STATE));
        };
      })(this), 300);
    },
    onCreateQuestion: function() {
      return this.setState(this.state.setIn(['questionModal', 'applying'], true));
    },
    onUpdateQuestion: function() {
      return this.setState(this.state.setIn(['questionModal', 'applying'], true));
    },
    onUpdateQuestionInModal: function(_arg) {
      var field, value;
      field = _arg.field, value = _arg.value;
      return this.setState(this.state.setIn(['questionModal', 'question', field], value));
    },
    getQuestions: function() {
      return this.state.get('questions');
    },
    getQuestion: function(questionId) {
      return this.state.get('questions', Immutable.List()).find(function(question) {
        return question.get('_id') === questionId;
      }) || Immutable.Map();
    },
    isFetching: function() {
      return this.state.get('isFetching');
    },
    isQuestionModalOpen: function() {
      return this.state.getIn(['questionModal', 'isOpen']);
    },
    isQuestionModalApplying: function() {
      return this.state.getIn(['questionModal', 'applying']);
    },
    getModalQuestion: function() {
      return this.state.getIn(['questionModal', 'question']);
    },
    getModalMode: function() {
      return this.state.getIn(['questionModal', 'mode']);
    }
  }
});
