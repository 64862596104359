var GdtDocFieldType, MapOfType, TypeValidationError, mapCheck, mapOf, object, typeUtils, _ref,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

GdtDocFieldType = require('lib/db_docs/field_types/FieldType');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

_ref = require('lib/db_docs/field_types/built_in_types'), object = _ref.object, typeUtils = _ref.typeUtils;

MapOfType = (function(_super) {
  __extends(MapOfType, _super);

  function MapOfType() {
    return MapOfType.__super__.constructor.apply(this, arguments);
  }

  MapOfType.prototype.getItemType = function() {
    return this['_typeMeta'].ItemType;
  };

  MapOfType.prototype.defaults = function(providedData) {
    var ItemType, parsedAsObject;
    if (typeUtils.getValueType(providedData) === 'Object') {
      ItemType = this.getItemType();
      return this.parse(R.map(ItemType.defaults.bind(ItemType), providedData));
    } else {
      parsedAsObject = object.parse(providedData);
      if (parsedAsObject instanceof TypeValidationError) {
        return parsedAsObject;
      } else {
        return this.parse(parsedAsObject);
      }
    }
  };

  return MapOfType;

})(GdtDocFieldType);

mapCheck = function(ItemType) {
  return function(mapValue) {
    var failedItems, itemId, itemVal, valCheck;
    failedItems = [];
    for (itemId in mapValue) {
      itemVal = mapValue[itemId];
      valCheck = ItemType.check(itemVal);
      if (valCheck instanceof TypeValidationError) {
        return new TypeValidationError({
          message: "At least one item of type `" + ItemType.name + "` failed validation. Item id: " + itemId + " Item validation message: " + valCheck.message,
          validation: 'Map validation',
          _meta: {
            itemId: itemId
          }
        });
      }
    }
    return mapValue;
  };
};

mapOf = function(ItemType) {
  if (!(ItemType instanceof GdtDocFieldType)) {
    throw new Error('ItemType parameter must be an instance of `Type`');
  }
  return new MapOfType({
    name: "MapOf(" + ItemType.name + ")",
    check: typeUtils.checksProduct(object.check, mapCheck(ItemType)),
    parse: function(mapValue) {
      var checkResult, key, parsed, parsedAsObject, parsedVal, val;
      checkResult = this.check(mapValue);
      if (!(checkResult instanceof TypeValidationError)) {
        return mapValue;
      }
      if (typeUtils.getValueType(mapValue) === 'Object') {
        parsed = {};
        for (key in mapValue) {
          val = mapValue[key];
          parsedVal = ItemType.parse(val);
          if (ItemType.check(parsedVal) instanceof TypeValidationError) {
            return checkResult;
          } else {
            parsed[key] = parsedVal;
          }
        }
        return parsed;
      } else {
        parsedAsObject = object.parse(mapValue);
        if (parsedAsObject instanceof TypeValidationError) {
          return checkResult;
        } else {
          return this.parse(parsedAsObject);
        }
      }
    },
    typeDefault: {},
    _typeMeta: {
      ItemType: ItemType
    }
  });
};

mapOf.typeConstructor = MapOfType;

module.exports = mapOf;
