StringField = require 'components/covid_extraction_form/string_field'
Translation = require 'components/mixins/translation'

SampleSizeField = createReactClass
  displayName: 'SampleSizeField'

  mixins: [Translation('mdg_tables:edit_form')]

  propTypes:
    onChange: PropTypes.func.isRequired
    value: PropTypes.shape(
      count: PropTypes.number.isRequired
      description: PropTypes.string.isRequired
    ).isRequired

  onChange: (field, isNumber = false) -> (newValue) =>
    { onChange, value } = @props
    obj = {}
    newValueParsed = if isNumber
      parsed = parseInt(newValue)
      if _.isNaN(parsed)
        undefined
      else
        parsed
    else
      newValue
    obj[field] = newValueParsed
    onChange _.extend {}, value, obj

  render: ->
    { value: { count, description }} = @props
    <div className="sample-size-field">
      <span>{'N = '}</span>
      <StringField
        className="sample-size-field__count"
        onChange={@onChange('count', true)}
        type="number"
        value={count}
      />
      <StringField
        className="sample-size-field__description"
        onChange={@onChange('description')}
        placeholder={@i18n 'placeholders.sampleSizeDescription'}
        value={description}
      />
    </div>

module.exports = SampleSizeField
