var GdtDocFieldType, TypeValidationError, Validation;

GdtDocFieldType = require('lib/db_docs/field_types/FieldType');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

Validation = function(validationSpec, validationLabel) {
  var _validationState;
  if (validationLabel == null) {
    validationLabel = '';
  }
  _validationState = {};
  return {
    allValid: function() {
      return R.isEmpty(_validationState) || R.all(R.head, R.values(_validationState));
    },
    validate: function(props) {
      return function(name) {
        return function(val) {
          var maybeParsed, validation, validationLabelStr, validationResult;
          if (name in validationSpec) {
            validation = validationSpec[name];
            validationResult = validation instanceof GdtDocFieldType ? (maybeParsed = validation.parse(val), maybeParsed instanceof TypeValidationError ? [false, maybeParsed.message] : [true, null]) : validation(val, props, name);
            _validationState[name] = validationResult;
            return validationResult;
          } else {
            validationLabelStr = validationLabel ? "(" + validationLabel + ")" : '';
            console.warn("Field name `" + name + "` is not present in validation " + validationLabelStr + ".");
            return val;
          }
        };
      };
    }
  };
};

module.exports = Validation;
