var number, optional, optionalNumber, shape;

shape = require('lib/db_docs/field_types/shape');

number = require('lib/db_docs/field_types/built_in_types').number;

optional = shape.typeDecorators.optional;

optionalNumber = optional(number);

module.exports = {
  interventionCount: optionalNumber,
  interventionTotal: optionalNumber
};
