ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
DocumentSectionsV2Store = require 'stores/document_sections_v2_store'
mediator = require 'mediator'
QuestionsSelector = require 'components/common/questions_selector'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'
{ useState } = React

storeConnector =
  DocumentSectionsV2Store: (Store) ->
    blockedQuestions: Store.getAlreadyUsedMdaTablesIds()

InsertMdaTable = ({ blockedQuestions, onCancel, onInsert, questionGroups, questions }) ->
  [selectedQuestions, setSelectedQuestions] = useState Immutable.List()

  onApply = useCoffeeCallback [onInsert, selectedQuestions], ->
    onInsert selectedQuestions.toJS()

  i18n = useI18n 'docsec:management'

  <div className="insert-recommendation-modal">
    <QuestionsSelector
      blockedQuestions={blockedQuestions}
      questionGroups={questionGroups}
      questionField="name"
      questions={questions}
      selectedQuestions={selectedQuestions}
      updateSelected={setSelectedQuestions}
      withNumbers
      withSearch
      withSelectAll
    />
    <div className="mt-10">
      <ApplyCancelButtons
        applyLabel={i18n 'insert_mda_table_label'}
        onApply={onApply}
        onCancel={onCancel}
        isSubmitEnabled={not selectedQuestions.isEmpty()}
      />
    </div>
  </div>

InsertMdaTable.propTypes =
  blockedQuestions: PropTypes.instanceOf(Immutable.Set).isRequired
  onCancel: PropTypes.func.isRequired
  onInsert: PropTypes.func.isRequired
  questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
  questions: PropTypes.instanceOf(Immutable.OrderedMap).isRequired

module.exports = ConnectStore InsertMdaTable, DocumentSectionsV2Store, storeConnector
