Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
GdtEditor = Editor.Editor
GdtEditorWithApply = Editor.EditorWithApply
{ bool, oneOfType, string, instanceOf, func } = PropTypes

VotingAdminComment = createReactClass
  displayName: 'VotingAdminComment'

  propTypes:
    adminComment: oneOfType [
      instanceOf(Immutable.Map)
      string
    ]
    renderMode: string.isRequired
    onApply: func
    readOnly: bool

  mixins: [
    CustomRenderMixin,
    Translation('es:recommendations.table')
  ]

  getDefaultProps: ->
    readOnly: false
    onApply: null

  onCancel: ->
    @gdtEditor.resetContent @props.adminComment?.toJS()

  onApply: ->
    content = @gdtEditor.getEditorContent()
    @props.onApply? content

  componentDidUpdate: (prevProps, prevState) ->
    @gdtEditor?.resetContent @props.adminComment

  render: ->
    { adminComment, readOnly, renderMode } = @props

    <div className="admin-comment">
      <span className='admin-comment-caption'>{@i18n 'summary'}</span>
      {if renderMode is 'printout'
        <GdtEditor editorContent={adminComment} readOnly />
      else
        <GdtEditorWithApply
          ref={(el) => @gdtEditor = el?.gdtEditor}
          stickControlsTo='#recommendations'
          editorContent={adminComment}
          onCancel={@onCancel}
          onApply={@onApply}
          onBlur={@onApply}
          readOnly={readOnly}
        />
      }
    </div>

module.exports = VotingAdminComment
