{ func, instanceOf } = PropTypes
DontShowAgainCheckbox = require 'components/common/dont_show_again_checkbox'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'
Confirmation = require 'components/common/confirmation'

CloseVotingConfirmation = createReactClass
  propTypes:
    onClose: func.isRequired
    onContinue: func.isRequired

  mixins: [Translation('voting:panel_voice')]

  getInitialState: ->
    dontShowAgain: false

  toggleDontShowAgain: (evt) ->
    @setState dontShowAgain: evt.target.checked

  handleContinue: ->
    @props.onContinue @state.dontShowAgain

  render: ->
    <Modal className="confirmation-modal resend-info" isOpen>
      <div className="confirmation">
        <div>
          {@i18n 'panel_voice_voting_resend_confirmation'}
        </div>
        <div>
          <DontShowAgainCheckbox checked={@state.dontShowAgain} onChange={@toggleDontShowAgain} />
        </div>
        <div className="buttons">
          <button className="btn btn-cancel" onClick={@props.onClose}>
            {@i18n '/translation:actions.cancel'}
          </button>
          <button className="btn btn-confirm" onClick={@handleContinue}>
            {@i18n '/translation:actions.continue'}
          </button>
        </div>
      </div>
    </Modal>

module.exports = CloseVotingConfirmation
