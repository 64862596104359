ProjectsActions = require 'actions/projects_actions'
DynamicHeightContainer = require 'components/common/dynamic_height_container'
ProjectInvitationSuccessDialog = require 'components/projects/project_invitation_success_dialog'
ProjectInvitation = require 'components/projects/project_invitation_item'
{ TransitionGroup } = ReactTransitionGroup


ProjectInvitationsList = createReactClass
  displayName: 'ProjectInvitationsList'

  propTypes:
    projectInvitations: PropTypes.instanceOf(Immutable.List).isRequired
    successfulInvitationsToNotify: PropTypes.instanceOf(Immutable.List).isRequired

  handleInvitationAccept: (invitation, organizationId) ->
    invitationId = invitation.get 'id'

    ProjectsActions.acceptProjectInvitation { invitationId, organizationId }

  handleInvitationReject: (invitation) ->
    ProjectsActions.declineProjectInvitation invitation.get 'id'

  handleSuccessDialogClose: (successfulInvitationId) -> ->
    ProjectsActions.successfulNotificationShown successfulInvitationId

  renderProjectInvitation: (projectInvitation) ->
    id = projectInvitation.get 'id'

    <ProjectInvitation
      key={id}
      invitation={projectInvitation}
      onReject={@handleInvitationReject}
      onAccept={@handleInvitationAccept}
    />

  renderSuccessfulInvitationModal: (completedInvitation) ->
    invitationId = completedInvitation.get 'id'

    <ProjectInvitationSuccessDialog
      key={invitationId}
      organizationId={completedInvitation.get 'organizationId'}
      projectId={completedInvitation.get 'projectId'}
      isCopy={completedInvitation.get 'createCopy'}
      onClose={@handleSuccessDialogClose invitationId}
    />

  render: ->
    { projectInvitations, successfulInvitationsToNotify } = @props

    return null if projectInvitations.isEmpty() and successfulInvitationsToNotify.isEmpty()
    containerClass = classNames 'projects-invitations-container',
        empty: projectInvitations.isEmpty()

    <DynamicHeightContainer className={containerClass}>
      <TransitionGroup component='div' className='projects-list'>
        {projectInvitations.map(@renderProjectInvitation).toList()}
      </TransitionGroup>
      {unless successfulInvitationsToNotify.isEmpty()
        successfulInvitationsToNotify.map(@renderSuccessfulInvitationModal).toList()
      }
    </DynamicHeightContainer>

module.exports = ProjectInvitationsList
