var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <td class=\"";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, depth0, "varies", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n        "
    + escapeExpression(((helpers.i18nWithNamespace || (depth0 && depth0.i18nWithNamespace) || helperMissing).call(depth0, "es:recommendations.table.radio_values.", depth0, {"name":"i18nWithNamespace","hash":{},"data":data})))
    + "\n      </td>\n";
},"2":function(depth0,helpers,partials,data) {
  return "varies";
  },"4":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "      <td class=\"";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, depth0, "varies", {"name":"is","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n        <input name=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].attrName : depths[1]), depth0))
    + "\" data-bind-attr=\"true\" type=\"radio\" value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\">\n      </td>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "<table class=\"standard-table radios-table\">\n  <tr>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.valuesList : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </tr>\n  <tr>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.valuesList : depth0), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </tr>\n</table>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}