IconButton = require 'components/common/icon_button'
Button = require 'components/common/button'
{ arrayOf, func, oneOf, string, shape, bool } = PropTypes

CONTROLS =
  'send':
    icon: 'send-copy'
    label: $.t 'voting:voting.control_labels.send'
  'sendPart':
    icon: 'send-copy'
    label: $.t 'voting:voting.control_labels.send_next'
  'sendTest':
    icon: 'send-pilot'
    label: $.t 'voting:voting.control_labels.send_test'
  'close':
    text: $.t 'voting:voting.control_labels.close'
    label: $.t 'voting:voting.control_labels.question'
  'closePart':
    text: $.t 'voting:voting.control_labels.close'
    label: $.t 'voting:voting.control_labels.current_part'
  'resend':
    icon: 'refresh'
    label: $.t 'voting:voting.control_labels.resend'

QuestionVotingControls = createReactClass
  displayName: 'QuestionVotingControls'

  propTypes:
    controls: arrayOf(shape(
      name: oneOf(['send', 'sendPart', 'sendTest', 'close', 'closePart','resend']).isRequired
      handler: func.isRequired
    )).isRequired

  renderControl: (spec, idx) ->
    { name, handler, disabled, helpText } = spec
    { icon, text, label } = CONTROLS[name]

    if icon?
      <IconButton
        iconName={icon}
        label={label}
        key={idx}
        onClick={handler}
        disabled={disabled}
        title={helpText}
      />
    else
      <div className='btn-icon-container' data-label-position="below" key={idx}>
        <Button className='literal' onClick={handler} label={text} disabled={disabled} />
        <div className='label-text'>{label} {disabled}</div>
      </div>

  render: ->
    <div className='question-voting-controls'>
      {_.map @props.controls, @renderControl}
    </div>

module.exports = QuestionVotingControls
