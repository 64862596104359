var Rm5ExportTableCell, getCellFootnotes, rm5ExportConfig, rm5TbodyCells, suffixGetter, _makeRiskDetailsRow;

rm5ExportConfig = require('lib/rm5_export_config');

suffixGetter = require('lib/view_helper_utils').controlRiskFormatterSuffix;

getCellFootnotes = require('lib/rm5export/rm5_export_utils').getCellFootnotes;

rm5TbodyCells = rm5ExportConfig.tbodyCells;

Rm5ExportTableCell = rm5ExportConfig.Rm5ExportTableCell;

_makeRiskDetailsRow = function(attributes, controlCheck) {
  var controlSuffix, correspondingRiskNotes, footnotes, riskDetailsRow, sofControlNotes, tableView;
  riskDetailsRow = {
    cells: []
  };
  tableView = attributes.tableView;
  footnotes = attributes.footnotes;
  controlSuffix = suffixGetter(controlCheck);
  if (!_(footnotes).isEmpty() && attributes.metaType !== 'NOT_POOLED') {
    sofControlNotes = getCellFootnotes("dichSofControl" + controlSuffix, footnotes);
    correspondingRiskNotes = getCellFootnotes('dichCorrespondingRisk', footnotes);
  }
  riskDetailsRow.cells.push(new Rm5ExportTableCell({
    textContent: rm5TbodyCells['dichSofControl'](attributes, controlCheck),
    sup: sofControlNotes || '',
    name: "dichSofControl" + controlSuffix
  }));
  switch (tableView) {
    case 'sof':
      riskDetailsRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichCorrespondingRisk'](attributes, controlCheck),
        sup: correspondingRiskNotes || '',
        name: 'dichCorrespondingRisk'
      }));
      break;
    case 'sof_accp':
      riskDetailsRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichRiskDifference'](attributes, controlCheck),
        sup: correspondingRiskNotes || '',
        name: 'dichRiskDifference'
      }));
      break;
    case 'sof_v3':
    case 'sof_v4':
      riskDetailsRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichCorrespondingRisk'](attributes, controlCheck),
        sup: correspondingRiskNotes || '',
        name: 'dichCorrespondingRisk'
      }));
      riskDetailsRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichRiskDifference'](attributes, controlCheck),
        name: 'dichRiskDifference'
      }));
      break;
    case 'sof_short':
      riskDetailsRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichCorrespondingRisk'](attributes, controlCheck),
        name: 'dichCorrespondingRisk'
      }));
      riskDetailsRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichRiskDifference'](attributes, controlCheck),
        name: 'dichRiskDifference'
      }));
  }
  return riskDetailsRow;
};

module.exports = function(attributes, rowspan, controlChecks) {
  var cellData, cellName, cellNotes, colspan, controlCheck, dichRows, firstControlCheck, firstRow, firstRowSchema, formatter, outcomeHasFootnotes, placeholder, prefix, tableType, tableView, _i, _len, _ref;
  dichRows = [];
  outcomeHasFootnotes = !_(attributes.footnotes).isEmpty();
  tableView = attributes.tableView;
  tableType = "" + tableView + "_regular";
  firstRowSchema = rm5ExportConfig.theadSchemas[tableType][0];
  firstRow = {
    cells: []
  };
  firstControlCheck = controlChecks.shift();
  for (cellName in firstRowSchema) {
    placeholder = (_ref = cellName.match(/\[[a-z]+\]/)) != null ? _ref[0] : void 0;
    cellName = cellName.replace(placeholder, '');
    if (cellName === 'absoluteEffect') {
      firstRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichNotReportedSofControl'](),
        rowspan: rowspan,
        name: cellName
      }));
      firstRow.sofControlIndex = firstRow.cells.length - 1;
      firstRow.cells.push(new Rm5ExportTableCell({
        textContent: rm5TbodyCells['dichNotReportedCorrespondingRisk'](),
        rowspan: rowspan,
        name: cellName
      }));
      if (tableView === 'sof_v3' || tableView === 'sof_v4') {
        firstRow.cells.push(new Rm5ExportTableCell);
        ({
          textContent: rm5TbodyCells['dichNotReportedRiskDifference'](),
          rowspan: rowspan,
          name: cellName
        });
      }
    } else if (cellName === 'sofControl' || cellName === 'correspondingRisk' || cellName === 'riskDifference') {
      prefix = 'dich';
      formatter = prefix + _.string.capitalize(cellName);
      cellData = {
        textContent: rm5TbodyCells[formatter](attributes, firstControlCheck),
        rowspan: 1,
        name: formatter
      };
      firstRow.cells.push(new Rm5ExportTableCell(cellData));
    } else {
      cellData = {
        textContent: rm5TbodyCells[cellName](attributes),
        rowspan: rowspan,
        name: cellName
      };
      if (outcomeHasFootnotes && cellData.textContent !== '-') {
        cellNotes = getCellFootnotes(cellName, attributes.footnotes);
        if (cellNotes) {
          cellData.sup = cellNotes;
        }
      }
      firstRow.cells.push(new Rm5ExportTableCell(cellData));
    }
  }
  if (rowspan === 1) {
    dichRows.push(firstRow);
    return dichRows;
  } else {
    if (tableView !== 'sof_short') {
      colspan = tableView === 'sof_v3' || tableView === 'sof_v4' ? 3 : 2;
      firstRow.cells.splice(firstRow.sofControlIndex, colspan, new Rm5ExportTableCell({
        textContent: rm5TbodyCells['controlLabel'](attributes, firstControlCheck),
        colspan: colspan,
        name: 'controlLabel'
      }));
    }
    dichRows.push(firstRow);
    if (tableView !== 'sof_short') {
      dichRows.push(_makeRiskDetailsRow(attributes, firstControlCheck));
    }
    for (_i = 0, _len = controlChecks.length; _i < _len; _i++) {
      controlCheck = controlChecks[_i];
      if (tableView === 'sof_short') {
        dichRows.push(_makeRiskDetailsRow(attributes, controlCheck));
      } else {
        dichRows.push({
          cells: [
            new Rm5ExportTableCell({
              colspan: colspan,
              textContent: rm5TbodyCells['controlLabel'](attributes, controlCheck),
              name: 'controlLabel'
            })
          ]
        });
        dichRows.push(_makeRiskDetailsRow(attributes, controlCheck));
      }
    }
    return dichRows;
  }
};
