var MDA_TABLE, arrayOf, docShape, documentId, initial, mdaTableDocShape, mdaTableInitialContent, object, string, _ref;

arrayOf = require('lib/db_docs/field_types/array_of');

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

initial = require('lib/db_docs/field_types/type_decorators').initial;

MDA_TABLE = require('lib/db_docs/doc_types').MDA_TABLE;

mdaTableInitialContent = require('data/mda_table_content');

_ref = require('lib/db_docs/field_types/built_in_types'), object = _ref.object, string = _ref.string;

mdaTableDocShape = docShape({
  docType: initial(MDA_TABLE)(string),
  content: initial(mdaTableInitialContent)(object),
  name: initial('')(string),
  topics: initial([])(arrayOf(documentId))
});

module.exports = mdaTableDocShape;
