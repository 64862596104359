ButtonsRow = createReactClass
  displayName: 'ButtonsRow'

  getInitialState: ->
    elementWidth: null

  componentDidMount: ->
    if @props.stickToBottom
      @containerElement = document.querySelector(@props.stickToBottom.selector)
      if @containerElement
        width = @containerElement.offsetWidth
        @setState elementWidth: width - @props.stickToBottom.containerPadding
        @originalPaddingBottom = window.getComputedStyle(@containerElement)['padding-bottom']
        @containerElement.style.paddingBottom = '50px'

  componentWillUnmount: ->
    if @containerElement
      @containerElement.style.paddingBottom = @originalPaddingBottom

  render: ->
    childrenSize = React.Children.count @props.children
    classes = classNames
      'buttons-row row': true
      @props.className
      'sticked-bottom': @props.stickToBottom

    style = if @props.stickToBottom and @state.elementWidth
      width: "#{@state.elementWidth}px"
    else
      null

    <div className={classes} style={style}>
      {React.Children.map @props.children, (child) =>
        btnClassNames = classNames "btn btn-block", child.props.className
        <div className="button-container" style={width: "#{100/childrenSize}%"}>
          {React.cloneElement child, className: btnClassNames}
        </div>
      }
    </div>

module.exports = ButtonsRow
