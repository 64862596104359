var GENERAL_INFORMATION, Migration, RenameCategoryToProgressGeneralInformation, W, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

mediator = require('mediator');

GENERAL_INFORMATION = require('lib/doc_ids').GENERAL_INFORMATION;

module.exports = RenameCategoryToProgressGeneralInformation = (function(_super) {
  __extends(RenameCategoryToProgressGeneralInformation, _super);

  function RenameCategoryToProgressGeneralInformation() {
    RenameCategoryToProgressGeneralInformation.__super__.constructor.call(this, false);
  }

  RenameCategoryToProgressGeneralInformation.prototype.up = function(project, colls) {
    var adapter, projectId;
    RenameCategoryToProgressGeneralInformation.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    if (!mediator.services.switches.isServerSwitchOn('covidFeatures')) {
      return W.resolve();
    }
    return adapter.fetch(projectId, GENERAL_INFORMATION).then(function(doc) {
      if (_.has(doc, 'category') && !_.has(doc, 'progress')) {
        doc = _.extend({}, doc, {
          progress: doc.category
        });
        doc = _.omit(doc, 'category');
        return mediator.services.storePersistenceAdapter.save(projectId, doc);
      } else {
        return W.resolve();
      }
    })["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
    });
  };

  return RenameCategoryToProgressGeneralInformation;

})(Migration);
