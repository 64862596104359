_.namespace(module, function(require) {
  var DbHelper, Exception, Exceptions, URL_BASE, W, mediator;
  DbHelper = require('base/lib/db_helper');
  Exception = require('base/lib/exception');
  Exceptions = require('lib/exceptions');
  W = require('when');
  mediator = require('mediator');
  URL_BASE = "" + (DbHelper.getBackendUrl()) + "/subscription";
  return {
    checkoutStandard: function(term, currency) {
      var url;
      url = "" + URL_BASE + "/checkout/standard";
      return W($.ajax(url, {
        type: 'POST',
        data: {
          term: term,
          currency: currency
        },
        xhrFields: {
          withCredentials: true
        }
      })).then(function(hostedPage) {
        var chargebee;
        chargebee = Chargebee.init({
          site: hostedPage.site
        });
        return W.promise(function(resolve, reject) {
          return chargebee.openCheckout({
            hostedPageUrl: hostedPage.url,
            success: function() {
              return resolve(true);
            },
            close: function() {
              return resolve(false);
            },
            error: reject
          });
        });
      }).then(function(success) {
        var activateUrl;
        if (!success) {
          return false;
        }
        activateUrl = "" + URL_BASE + "/activate/standard";
        return W($.ajax(activateUrl, {
          type: 'POST',
          xhrFields: {
            withCredentials: true
          }
        })).then(function() {
          return true;
        });
      })["catch"](this._getError);
    },
    checkoutAcademic: function(affiliation) {
      var activateUrl;
      activateUrl = "" + URL_BASE + "/activate/academic";
      return W($.ajax(activateUrl, {
        type: 'POST',
        data: {
          affiliation: affiliation
        },
        xhrFields: {
          withCredentials: true
        }
      }))["catch"](this._getError);
    },
    info: function() {
      return W($.ajax(URL_BASE, {
        xhrFields: {
          withCredentials: true
        }
      }))["catch"](this._getError);
    },
    cancelAcademic: function() {
      var url;
      url = "" + URL_BASE + "/cancel";
      return W($.ajax(url, {
        type: 'POST',
        xhrFields: {
          withCredentials: true
        }
      })).then(function() {
        return mediator.publish('!reloadApplication');
      })["catch"](this._getError);
    },
    manage: function(closeCallback) {
      var url;
      url = "" + URL_BASE + "/manage";
      return W($.ajax(url, {
        xhrFields: {
          withCredentials: true
        }
      })).then(function(portalSession) {
        var chargebee, portal;
        chargebee = Chargebee.init({
          site: portalSession.site
        });
        chargebee.setPortalSession(portalSession.token);
        portal = chargebee.createChargebeePortal();
        return portal.open({
          close: closeCallback
        });
      })["catch"](this._getError);
    },
    _getError: function(error) {
      var result;
      result = error instanceof Exception ? error : error.status === 403 ? new Exceptions.authentication.forbidden : error.status === 0 ? new Exceptions.no_connection : new Exceptions.server_error;
      throw result;
    }
  };
});
