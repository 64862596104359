var HandbookView, ModalView, NewUserView, UserGuideView, W, WelcomeCapabilitiesListView, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

WelcomeCapabilitiesListView = require('views/welcome_capabilities_view');

HandbookView = require('views/handbook_view');

UserGuideView = require('views/user_guide_view');

W = require('when');

template = require('views/templates/new_user');

mediator = require('mediator');

module.exports = NewUserView = (function(_super) {
  __extends(NewUserView, _super);

  function NewUserView() {
    return NewUserView.__super__.constructor.apply(this, arguments);
  }

  NewUserView.prototype.title = $.t('projects:welcome.new_user.title');

  NewUserView.prototype.className = 'new-user-view';

  NewUserView.prototype.template = template;

  NewUserView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = 0;
    this.options.fadeInMs = 0;
    this.options.fadeOutMs = 0;
    this.options.screenOpacity = 0.83;
    this.options.closeBtn = true;
    this.options.cls = 'new-user-screen';
    delete this.shortcuts.esc;
    NewUserView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.close', this.dispose);
    this.delegate('click', 'button.handbook', (function(_this) {
      return function() {
        return _this.subview('handbook', new HandbookView());
      };
    })(this));
    this.delegate('click', 'button.guide', (function(_this) {
      return function() {
        return _this.subview('userGuide', new UserGuideView());
      };
    })(this));
    this.delegate('click', 'button.tutorials', function() {
      return window.open($.t('projects:welcome.learn_section.tutorials_homepage'), '_blank');
    });
    return this._deferred = W.defer();
  };

  NewUserView.prototype.afterRender = function() {
    NewUserView.__super__.afterRender.apply(this, arguments);
    this.subview('capabilities', new WelcomeCapabilitiesListView({
      container: this.$('#capabilities')
    }));
    this.subview('capabilities').render();
    return window.onresize = this._adoptElHeight.bind(this);
  };

  NewUserView.prototype.dispose = function() {
    if (!this.disposed) {
      mediator.user.save('skipNewUserScreen', this.$('#dont-show-again input').prop('checked'));
      this._deferred.resolve();
    }
    return NewUserView.__super__.dispose.apply(this, arguments);
  };

  NewUserView.prototype.promise = function() {
    return this._deferred.promise;
  };

  NewUserView.prototype._adoptElHeight = function() {
    var guaranteedFitHeight;
    if (window.innerHeight < 625) {
      guaranteedFitHeight = window.innerHeight - 60;
      return $(this.el).css({
        'max-height': guaranteedFitHeight,
        'overflowY': 'auto'
      });
    } else {
      return $(this.el).css({
        'max-height': '',
        'overflowY': ''
      });
    }
  };

  return NewUserView;

})(ModalView);
