var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "checked";
  },"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <span class=\"toolbar-separator\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "archie:revman.status.icon_title", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n    <span class=\"revman-logo\"></span>\n    <div class=\"revman-web-status\"></div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\"es-toolbar-questions\"></div>\n<menu type=\"toolbar\">\n  <span class=\"footnotes-toggle-with-text\">\n    <label class=\"switcher switcher--footnotes-pane\">\n      <input type=\"checkbox\" disabled=\"disabled\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.paneOpened : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n      <div class=\"slider round\"></div>\n    </label>\n    <button class=\"toggle-footnotes-pane\">\n      <span class=\"switch-btn-text\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:footnotes.show_explanations_references_pane", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n    </button>\n  </span>\n</menu>\n<menu type=\"toolbar\" class=\"context-buttons\">\n  <li class=\"footnotes-button-container\">\n    <button class=\"footnotes\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:footnotes.tooltip", {"name":"i18n","hash":{},"data":data})))
    + "'>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:footnotes.title_plural", {"name":"i18n","hash":{},"data":data})))
    + "\n    </button>\n    <div class=\"disabled-tooltip\"\n      title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:footnotes.tooltip_disabled", {"name":"i18n","hash":{},"data":data})))
    + "'>\n    </div>\n  </li>\n</menu>\n<menu type=\"toolbar\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isRevManEnabled : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  <span class=\"toolbar-separator\"></span>\n  <button title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "toolbar.export_question.export", {"name":"i18n","hash":{},"data":data})))
    + "' class='export'>\n  </button>\n</menu>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}