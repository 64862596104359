var ArchieHelper, DELETE_REVMAN_TIMEOUT, DbHelper, EXPORT_REVMAN_TIMEOUT, PERMISSIONS_CHECK_TIMEOUT, ajax;

ArchieHelper = require('lib/archie_helper');

DbHelper = require('base/lib/db_helper');

ajax = require('actions/async/api_helpers').ajax;

EXPORT_REVMAN_TIMEOUT = 60 * 1000;

DELETE_REVMAN_TIMEOUT = 60 * 1000;

PERMISSIONS_CHECK_TIMEOUT = 30 * 1000;

module.exports = {
  revmanWebUpdateTable: function(reviewId, tableId, tableBody, tableFootnotes, tableTitle, isUserAction) {
    var apiCall, dataToSend;
    if (isUserAction == null) {
      isUserAction = false;
    }
    dataToSend = {
      tableBody: tableBody,
      tableFootnotes: tableFootnotes,
      tableTitle: tableTitle
    };
    apiCall = function() {
      return ajax({
        contentType: 'application/json',
        dataType: 'json',
        url: "" + (DbHelper.getBackendUrl()) + "/revman/tables/content/" + reviewId + "/" + tableId,
        type: 'PUT',
        timeout: EXPORT_REVMAN_TIMEOUT,
        data: JSON.stringify(dataToSend),
        xhrFields: {
          withCredentials: true
        }
      });
    };
    if (isUserAction) {
      return ArchieHelper.reconnectToArchieIfTokenExpired(apiCall, 'revman_update_table');
    } else {
      return apiCall()["catch"](_.partial(ArchieHelper.handleConnectionError, 'revman_update_table'));
    }
  },
  deleteTable: function(reviewId, tableId) {
    var apiCall;
    apiCall = function() {
      return ajax({
        url: "" + (DbHelper.getBackendUrl()) + "/revman/tables/" + reviewId + "/" + tableId,
        type: 'DELETE',
        timeout: DELETE_REVMAN_TIMEOUT,
        xhrFields: {
          withCredentials: true
        }
      });
    };
    return ArchieHelper.reconnectToArchieIfTokenExpired(apiCall, 'revman_delete_table');
  },
  checkForPermissions: function(reviewId) {
    var apiCall;
    apiCall = function() {
      return ajax({
        url: "" + (DbHelper.getBackendUrl()) + "/revman/permissions/" + reviewId + "/check",
        type: 'POST',
        timeout: PERMISSIONS_CHECK_TIMEOUT,
        xhrFields: {
          withCredentials: true
        }
      });
    };
    return ArchieHelper.reconnectToArchieIfTokenExpired(apiCall, 'revman_check_permissions');
  }
};
