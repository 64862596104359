var Migration, MultiComparisonDiagnosticQuestion, W, filterQuestionKeys, mediator, multiComparisonPrefix,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

filterQuestionKeys = require('lib/multi_comparisons_helper').filterQuestionKeys;

multiComparisonPrefix = require('lib/doc_prefixes').multiComparison;

module.exports = MultiComparisonDiagnosticQuestion = (function(_super) {
  __extends(MultiComparisonDiagnosticQuestion, _super);

  function MultiComparisonDiagnosticQuestion() {
    MultiComparisonDiagnosticQuestion.__super__.constructor.call(this, false);
  }

  MultiComparisonDiagnosticQuestion.prototype.up = function(project, colls) {
    var adapter;
    MultiComparisonDiagnosticQuestion.__super__.up.apply(this, arguments);
    adapter = mediator.services.storePersistenceAdapter;
    return W(adapter.fetchWithKeyPrefix(project.id, multiComparisonPrefix)).then(function(_arg) {
      var mcQuestionDocs, rows, selectedQuestionIds;
      rows = _arg.rows;
      if (_.isEmpty(rows)) {
        W.resolve({
          mcQuestionDocs: [],
          selectedQuestionIds: []
        });
      }
      mcQuestionDocs = _.pluck(rows, 'doc');
      selectedQuestionIds = _.chain(mcQuestionDocs).map(function(doc) {
        return doc.selectedQuestions || [];
      }).flatten().map(function(question) {
        return question._id || question.questionId;
      }).compact().uniq().value();
      return {
        mcQuestionDocs: mcQuestionDocs,
        selectedQuestionIds: selectedQuestionIds
      };
    }).then(function(_arg) {
      var mcQuestionDocs, selectedQuestionIds;
      mcQuestionDocs = _arg.mcQuestionDocs, selectedQuestionIds = _arg.selectedQuestionIds;
      return adapter.fetch(project.id, selectedQuestionIds).then(function(_arg1) {
        var questionDocs, rows, updatedMcQuestionDocs;
        rows = _arg1.rows;
        questionDocs = _.pluck(rows, 'doc');
        return updatedMcQuestionDocs = _.map(mcQuestionDocs, function(mcQuestionDoc) {
          var updatedSelectedQuestions;
          updatedSelectedQuestions = _.map(mcQuestionDoc.selectedQuestions, function(selectedQuestion) {
            var qId, questionDoc;
            qId = selectedQuestion._id || selectedQuestion.questionId;
            questionDoc = _.find(questionDocs, function(qDoc) {
              return qDoc._id === qId;
            });
            return filterQuestionKeys(Immutable.fromJS(questionDoc)).toJS();
          });
          return _.extend(mcQuestionDoc, {
            selectedQuestions: updatedSelectedQuestions
          });
        });
      });
    }).then(function(updatedMcQuestionDocs) {
      return adapter.bulkDocs(project.id, updatedMcQuestionDocs);
    });
  };

  return MultiComparisonDiagnosticQuestion;

})(Migration);
