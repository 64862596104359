var immutable;

immutable = function(StoreModel) {
  StoreModel.config = {
    setState: function(currentState, nextState) {
      this.state = nextState;
      return this.state;
    },
    getState: function(currentState) {
      return currentState;
    },
    onSerialize: function(state) {
      return state.toJS();
    },
    onDeserialize: function(data) {
      return Immutable.fromJS(data);
    }
  };
  return StoreModel;
};

module.exports = immutable;
