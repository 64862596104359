InitialTab = require 'components/scope/outcomes/initial_tab'
BrainstormingTab = require 'components/scope/outcomes/brainstorming_tab'
CompletedListTab = require 'components/scope/outcomes/completed_list_tab'
RatingTab = require 'components/scope/outcomes/rating_tab'
ProposalTab = require 'components/scope/outcomes/proposal_tab'
ApprovalTab = require 'components/scope/outcomes/approval_tab'
FinishedTab = require 'components/scope/outcomes/finished_tab'
{ Tab, TabsContainer } = require 'components/common/tabs_container'
NextStepConfirmationModal = require 'components/scope/questions/next_step_confirmation_modal'
CommentsExportModal = require 'components/scope/outcomes/comments_export_modal'
AddOutcomeModal = require 'components/scope/outcomes/add_outcome_modal'
InfoModal = require 'components/common/info_message_modal'
MembersStore = require 'stores/members_store'
OutcomesGenerationStore = require 'stores/outcomes_generation_store'
OutcomesGenerationActions = require 'actions/outcomes_generation_actions'
Translation = require 'components/mixins/translation'
FetchingSpinner = require 'components/mixins/fetching_spinner'
CreateStoreMixin = require 'components/mixins/create_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
mediator = require 'mediator'
brainsromingMailTemplate = require './outcomes_generation_brainstorming_mail_template'
ratingMailTemplate = require './outcomes_generation_rating_mail_template'
approvalMailTemplate = require './outcomes_generation_approval_mail_template'
{ checkConnection } = require 'lib/app_utils'
[
  initialStep
  brainstormingStep
  moderationStep
  ratingStep
  proposalStep
  approvalStep
  finishedStep
] = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS
{ DndProvider } = ReactDnD

getProjectId = -> mediator.project.id

getProjectName = -> mediator.project.get 'name'

OutcomesGeneration = createReactClass
  displayName: 'OutcomesGenerationComponent'

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
    CreateStoreMixin(OutcomesGenerationStore, MembersStore)
    FetchingSpinner
  ]

  getInitialState: ->
    showAddOutcome: false
    noOutcomeConfirm: false

  getStateFromStores: ->
    steps: OutcomesGenerationStore.getStepsData()
    todos: OutcomesGenerationStore.getTodos()
    activeTab: OutcomesGenerationStore.getActiveTab()
    currentStep: OutcomesGenerationStore.getCurrentStep()
    questionGroupsWithComments: OutcomesGenerationStore.getStepQuestionGroupsWithComments()
    nextStep: OutcomesGenerationStore.getNextStep()
    hasItemDropped: OutcomesGenerationStore.hasItemDropped()
    editingOutcome: OutcomesGenerationStore.getEditingOutcome()
    members: MembersStore.getPanelMembers()
    isFetching: OutcomesGenerationStore.isFetching() or MembersStore.isFetching()
    isFetchingResults: OutcomesGenerationStore.isFetchingResults()
    isExportingComments: OutcomesGenerationStore.isExportingComments()
    isMovingOutcomes: OutcomesGenerationStore.isMovingOutcomes()
    comparisonDataUpdated: OutcomesGenerationStore.hasDataUpdated()
    isClosingStep: OutcomesGenerationStore.isClosingStep()
    isSendingToMembers: OutcomesGenerationStore.isSendingToMembers()

  _isTabDisabled: (tabName) ->
    not @state.steps.has tabName

  _getActiveTabQuestionGroups: ->
    @state.steps.getIn [@state.activeTab, 'questionGroups']

  _getTabTodos: (tabName) ->
    @state.todos.get tabName, Immutable.Map()

  _getMembersMap: ->
    @state.members.mapEntries ([memberId], index) =>
      [memberId, "#{@i18n 'team_member'} #{index + 1}"]

  _isStepClosed: (step) ->
    @state.steps.getIn [step, 'closed'], false

  _getEditableTabProps: ->
    return null unless @state.currentStep in [initialStep, moderationStep]
    isQuestionDragging: @state.isQuestionDragging
    scrollToGroup: @state.scrollToGroup
    afterScroll: => @setState scrollToGroup: null
    editingOutcome: @state.editingOutcome
    hasItemDropped: @state.hasItemDropped
    releaseQuestion: OutcomesGenerationActions.releaseQuestionFromGroup
    updateQuestionGroupTitle: OutcomesGenerationActions.updateQuestionGroup
    deleteQuestionGroup: OutcomesGenerationActions.deleteQuestionGroup
    undoItemMovement: OutcomesGenerationActions.undoItemMovement
    onNewGroupDrop: @onNewGroupDrop
    onAddOutcome: => @setState showAddOutcome: true
    onCopyOutcome: @onCopyOutcome

  _getMembersSubmissionStats: (step) ->
    membersSentTo = @state.steps.getIn [step, 'memberIds'], Immutable.List()
    membersSubmitted = @state.steps.getIn [step, 'submittedMemberIds'], Immutable.List()
    { membersSentTo, membersSubmitted }

  checkOutcomes: ->
    @_getActiveTabQuestionGroups().some (qGroup) -> not qGroup.get('outcomes').isEmpty()

  _showNoConnectionError: -> mediator.dialogs.error @i18n '../no_connection_message'

  onNewGroupDrop: ->
    OutcomesGenerationActions.createQuestionGroup()

  addOutcome: (params) ->
    { groups } = params
    topMostGroupAddedTo = groups.reduce (minIndex, gIndex) ->
      if gIndex <= minIndex then gIndex else minIndex
    , groups[0]

    @setState scrollToGroup: topMostGroupAddedTo
    OutcomesGenerationActions.addOutcome params

  onCopyOutcome: (name) ->
    @setState
      showAddOutcome: true
      addOutcomeName: name

  onAddOutcomeClose: ->
    @setState
      showAddOutcome: false
      addOutcomeName: null

  onExportComments: -> @setState showCommentsExport: true

  getMailTemplateForStep: (step) ->
    switch step
      when brainstormingStep
        subject: "Generation of outcomes for the guidelines: #{getProjectName()}"
        body: brainsromingMailTemplate projectName: getProjectName()
      when ratingStep
        subject: "Rating importance of outcomes for the guidelines: #{getProjectName()}"
        body: ratingMailTemplate projectName: getProjectName()
      when approvalStep
        subject:
          "Confirmation of the final list of outcomes for the guidelines: #{getProjectName()}"
        body: approvalMailTemplate projectName: getProjectName()

  sendOutcomes: ->
    return mediator.dialogs.modalDefault @i18n '../no_members_error' if @state.members.isEmpty()
    return @setState noOutcomeConfirm: true unless @checkOutcomes()
    return @_showNoConnectionError() unless checkConnection()

    @_doSend()

  sendWithoutOutcomes: ->
    @setState noOutcomeConfirm: false
    @_doSend()

  _doSend: ->
    mailTemplate = @getMailTemplateForStep @state.nextStep

    OutcomesGenerationActions.sendOutcomes
      projectId: getProjectId()
      memberIds: @state.members.keySeq().toArray()
      questionGroups: @_getActiveTabQuestionGroups().toJS()
      step: @state.nextStep
      emailSubject: mailTemplate.subject
      emailBody: mailTemplate.body

  fetchResults: ->
    # fetching is allowed for currentStep only
    if @state.activeTab is @state.currentStep
      OutcomesGenerationActions.fetchResults projectId: getProjectId()

  moveOutcomes: (questionGroups) ->
    if checkConnection()
      projectId = getProjectId()
      OutcomesGenerationActions.moveApprovedOutcomes projectId, questionGroups
    else
      @_showNoConnectionError()

  closeStep: ->
    # we must ensure latest data is fetched before closing the step. That is why, connection
    # availability is vital
    if checkConnection()
      OutcomesGenerationActions.closeStep projectId: getProjectId()
    else
      @_showNoConnectionError()

  renderFetched: ->
    { isClosingStep, isSendingToMembers } = @state

    <DndProvider backend={ReactDnDHTML5Backend.default}>
      <div className='outcomes-generation-container'>
        <TabsContainer activeTab={@state.activeTab}>
          <Tab tabName={initialStep}>
            <InitialTab {...@_getEditableTabProps()}
              projectId={getProjectId()}
              questionGroups={@_getActiveTabQuestionGroups()}
              readOnly={@_isStepClosed initialStep}
              onFinishAndSend={@sendOutcomes}
              isSendingToMembers={isSendingToMembers}
            />
          </Tab>
          <Tab tabName={brainstormingStep}>
            <BrainstormingTab {...@_getMembersSubmissionStats(brainstormingStep)}
              questionGroups={@_getActiveTabQuestionGroups()}
              membersMap={@_getMembersMap()}
              readOnly={@_isStepClosed brainstormingStep}
              fetchResults={@fetchResults}
              isFetchingResults={@state.isFetchingResults}
              closeStep={@closeStep}
              isClosingStep={isClosingStep}
            />
          </Tab>
          <Tab tabName={moderationStep}>
            <CompletedListTab {...@_getEditableTabProps()}
              questionGroups={@_getActiveTabQuestionGroups()}
              membersMap={@_getMembersMap()}
              readOnly={@_isStepClosed moderationStep}
              onFinishAndSend={@sendOutcomes}
              isSendingToMembers={isSendingToMembers}
            />
          </Tab>
          <Tab tabName={ratingStep}>
            <RatingTab {...@_getMembersSubmissionStats(ratingStep)}
              members={@state.members}
              readOnly={@_isStepClosed ratingStep}
              fetchResults={@fetchResults}
              isFetchingResults={@state.isFetchingResults}
              closeStep={@closeStep}
              isClosingStep={isClosingStep}
            />
          </Tab>
          <Tab tabName={proposalStep}>
            <ProposalTab
              questionGroups={@_getActiveTabQuestionGroups()}
              membersMap={@_getMembersMap()}
              readOnly={@_isStepClosed proposalStep}
              onFinishAndSend={@sendOutcomes}
              isSendingToMembers={isSendingToMembers}
            />
          </Tab>
          <Tab tabName={approvalStep}>
            <ApprovalTab {...@_getMembersSubmissionStats(approvalStep)}
              questionGroups = {@_getActiveTabQuestionGroups()}
              memberAgreements = {@state.steps.getIn [approvalStep, 'memberAgreements']}
              memberAgreementComments = {@state.steps.getIn [approvalStep, 'memberAgreementComments']}
              membersMap={@_getMembersMap()}
              members={@state.members}
              todos={@_getTabTodos approvalStep}
              readOnly={@_isStepClosed approvalStep}
              fetchResults={@fetchResults}
              isFetchingResults={@state.isFetchingResults}
              isMovingOutcomes={@state.isMovingOutcomes}
              moveOutcomes={@moveOutcomes}
            />
          </Tab>
          <Tab tabName={finishedStep}>
            <FinishedTab
              questionGroups={@_getActiveTabQuestionGroups()}
            />
          </Tab>
        </TabsContainer>
        <AddOutcomeModal
          questionGroups={@_getActiveTabQuestionGroups()}
          outcomeName={@state.addOutcomeName}
          isOpen={@state.showAddOutcome}
          onClose={@onAddOutcomeClose}
          onAdd={@addOutcome}
        />
        <InfoModal
          isOpen={@state.comparisonDataUpdated}
          onClose={OutcomesGenerationActions.confirmUpdateNotification}
          text={@i18n 'data_updated_message'}
        />
        {if @state.isExportingComments
          <CommentsExportModal
            projectName={mediator.project.get('name')}
            questionGroups={@_getActiveTabQuestionGroups()}
            questionGroupsWithComments={@state.questionGroupsWithComments}
            membersMap={@_getMembersMap()}
            tabName={@state.activeTab}
            onClose={OutcomesGenerationActions.hideCommentsExport}
          />
        }
        {if @state.noOutcomeConfirm
          <NextStepConfirmationModal
            onConfirm={@sendWithoutOutcomes}
            onCancel={=> @setState noOutcomeConfirm: false}
          />
        }
      </div>
    </DndProvider>

module.exports = OutcomesGeneration
