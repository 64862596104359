var mediator;

mediator = require('mediator');

module.exports = function(Actions) {
  return {
    fetch: {
      remote: function(state, dbName, ids, options) {
        return mediator.services.storePersistenceAdapter.fetch(dbName, ids, options);
      },
      success: Actions.pouchFetchSuccess,
      error: Actions.pouchFetchError
    },
    save: {
      remote: function(state, dbName, docs) {
        var saveMethod;
        saveMethod = _.isArray(docs) ? 'bulkDocs' : 'save';
        return mediator.services.storePersistenceAdapter[saveMethod](dbName, docs);
      },
      success: Actions.pouchSaveSuccess,
      error: Actions.pouchSaveError
    }
  };
};
