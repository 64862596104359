{ bool, shape, func } = PropTypes
Translation = require 'components/mixins/translation'
ConnectStore = require 'components/enhancers/connect_store'
Modal = require 'components/common/modal'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
PasswordInput = require 'components/common/password_input'
AccountStore = require 'stores/account_store'
AccountActions = require 'actions/account_actions'
mediator = require 'mediator'

storeConnectors =
  AccountStore: (Store) ->
    isOpen: Store.isArchieTokenExpiredModalOpen()
    isConnectingToArchie: Store.isConnectingToProvider()
    promise: Store.getArchieTokenExpiredModalPromise()

ArchieTokenExpiredModal = createReactClass
  displayName: 'ArchieTokenExpiredModal'
  mixins: [Translation('archie:token_expired_modal')]

  propTypes:
    isOpen: bool.isRequired
    promise: shape
      resolve: func.isRequired
      reject: func.isRequired
    isConnectingToArchie: bool.isRequired

  getDefaultProps: ->
    promise: null

  getInitialState: ->
    password: ''

  onPasswordChanged: (e) ->
    @setState password: e.target.value

  onClose: ->
    if @props.promise
      @props.promise.reject 'cancelled'
    AccountActions.closeArchieTokenExpiredModal()

  onApply: ->
    login = mediator.user.get 'name'
    AccountActions.connectToProvider
      provider: 'archie'
      login: login
      password: @state.password
      promise: @props.promise

  render: ->
    return null unless @props.isOpen

    <Modal
      isOpen
      title={@i18n 'title'}
      className="archie-token-expired-modal"
    >
      <p>{@i18n 'description'}</p>
      <div className="gdt-logo">
        <img
          src='images/logo_new.png'
          alt="GRADEpro GDT"
        />
      </div>
      <p dangerouslySetInnerHTML={ __html: @i18n 'enter_password' } />
      <div className="password-input-container">
        <PasswordInput
          value={@state.password}
          onChange={@onPasswordChanged}
        />
      </div>
      <ApplyCancelButtons
        onApply={@onApply}
        onCancel={@onClose}
        applying={@props.isConnectingToArchie}
        isSubmitEnabled={Boolean(@state.password?.trim())}
      />
    </Modal>

module.exports = ConnectStore ArchieTokenExpiredModal, [AccountStore], storeConnectors
