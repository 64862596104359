var PouchSource, QUESTION_GENERATION_DOC_ID, QUESTION_GENERATION_STEPS, QuestionsGenerationActions, QuestionsGenerationStore, ReactDnDActions, W, alt, calculateTitles, errorHandler, immutable, mediator, storeHelper, updateQuestionsWithRatingData, utils, _ref;

_ref = require('lib/questions_helper'), QUESTION_GENERATION_DOC_ID = _ref.QUESTION_GENERATION_DOC_ID, QUESTION_GENERATION_STEPS = _ref.QUESTION_GENERATION_STEPS, updateQuestionsWithRatingData = _ref.updateQuestionsWithRatingData, calculateTitles = _ref.calculateTitles;

storeHelper = require('stores/utils/questions_generation_store_helper');

errorHandler = require('lib/app_utils').errorHandler;

utils = require('base/lib/utils');

QuestionsGenerationActions = require('actions/questions_generation_actions');

ReactDnDActions = require('actions/react_dnd_actions');

PouchSource = require('stores/sources/pouch_source');

W = require('when');

alt = require('alt');

mediator = require('mediator');

immutable = require('stores/utils/immutable');

QuestionsGenerationStore = (function() {
  function QuestionsGenerationStore() {
    this.state = Immutable.fromJS({
      _meta: {
        activeTab: QUESTION_GENERATION_STEPS[0],
        editingQuestion: null,
        addingQuestion: null,
        fetching: false,
        dragData: null
      },
      questionsGenerationData: {
        currentStep: QUESTION_GENERATION_STEPS[0],
        steps: utils.getKeyValObject(QUESTION_GENERATION_STEPS[0], {
          questions: []
        }),
        todos: {}
      }
    });
    this.bindActions(QuestionsGenerationActions);
    this.bindActions(ReactDnDActions);
    this.registerAsync(PouchSource(QuestionsGenerationActions));
    this.exportPublicMethods({
      isFetching: this.isFetching,
      isFetchingResults: this.isFetchingResults,
      isClosingStep: this.isClosingStep,
      isExportingComments: this.isExportingComments,
      getActiveTab: this.getActiveTab,
      getCurrentStep: this.getCurrentStep,
      getNextStep: this.getNextStep,
      getEditingQuestion: this.getEditingQuestion,
      getAddingQuestion: this.getAddingQuestion,
      getStepsData: this.getStepsData,
      getStepQuestionsWithComments: this.getStepQuestionsWithComments,
      getTodos: this.getTodos
    });
  }

  QuestionsGenerationStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  QuestionsGenerationStore.prototype.onPouchFetchSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], false);
      state.mergeIn(['questionsGenerationData'], Immutable.fromJS(data));
      return state.setIn(['_meta', 'activeTab'], data.currentStep);
    }));
  };

  QuestionsGenerationStore.prototype.onPouchFetchError = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    if (err.status !== 404) {
      return errorHandler(err);
    }
  };

  QuestionsGenerationStore.prototype.onSendToMembers = function(params) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'sendingToMembers'], params.members.keySeq().toArray());
      return state.setIn(['_meta', 'closingStep'], state.getIn(['questionsGenerationData', 'currentStep']));
    }));
  };

  QuestionsGenerationStore.prototype.onSendToMembersSuccess = function(response) {
    return this._proceedToNextStep(response);
  };

  QuestionsGenerationStore.prototype.onSendToMembersError = function(err) {
    this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'sendingToMembers'], null);
      return state.setIn(['_meta', 'closingStep'], null);
    }));
    return errorHandler(err);
  };

  QuestionsGenerationStore.prototype.onFetchResults = function(params) {
    var step;
    step = params.step;
    return this.setState(this.state.setIn(['_meta', 'fetchingResults'], step));
  };

  QuestionsGenerationStore.prototype.onFetchResultsSuccess = function(data) {
    var fetchResultsSuccessApproval, fetchResultsSuccessBrainstorming, fetchResultsSuccessRating, stepFetchingFor;
    stepFetchingFor = this.state.getIn(['_meta', 'fetchingResults']);
    fetchResultsSuccessBrainstorming = storeHelper.fetchResultsSuccessBrainstorming, fetchResultsSuccessRating = storeHelper.fetchResultsSuccessRating, fetchResultsSuccessApproval = storeHelper.fetchResultsSuccessApproval;
    this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetchingResults'], false);
      switch (stepFetchingFor) {
        case 'questions.brainstorming':
          return fetchResultsSuccessBrainstorming(state, data);
        case 'questions.rating':
          return fetchResultsSuccessRating(state, data);
        case 'questions.approval':
          return fetchResultsSuccessApproval(state, data);
      }
    }));
    return this._save();
  };

  QuestionsGenerationStore.prototype.onFetchResultsError = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetchingResults'], false));
    return errorHandler(err);
  };

  QuestionsGenerationStore.prototype.onCloseStep = function(params) {
    var step;
    step = params.step;
    return this.setState(this.state.setIn(['_meta', 'closingStep'], step));
  };

  QuestionsGenerationStore.prototype.onCloseStepSuccess = function(response) {
    return this._proceedToNextStep(response);
  };

  QuestionsGenerationStore.prototype.onCloseStepError = function(err) {
    this.setState(this.state.setIn(['_meta', 'closingStep'], false));
    return errorHandler(err);
  };

  QuestionsGenerationStore.prototype.onChangeTab = function(tabName) {
    return this.setState(this.state.setIn(['_meta', 'activeTab'], tabName));
  };

  QuestionsGenerationStore.prototype.onEditQuestion = function(id) {
    return this.setState(this.state.setIn(['_meta', 'editingQuestion'], id));
  };

  QuestionsGenerationStore.prototype.onAddQuestion = function(type) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'addingQuestion'], type);
      return state.setIn(['_meta', 'editingQuestion'], null);
    }));
  };

  QuestionsGenerationStore.prototype.onSaveQuestion = function(params) {
    var data, id, newQuestionAttrs, qIndex, questionCopy, questionCopyId, questionToSave, questions, source, updatePath, updatedQuestions, withCopy;
    id = params.id, data = params.data, withCopy = params.withCopy;
    updatePath = this._getActiveTabQuestionsPath();
    questions = this.state.getIn(updatePath, Immutable.List());
    questionCopyId = withCopy ? utils.generateGUID() : null;
    updatedQuestions = id ? (qIndex = questions.findIndex(function(q) {
      return q.get('id') === id;
    }), questionToSave = questions.get(qIndex).merge(data, calculateTitles(data)), questions.set(qIndex, questionToSave)) : (source = this.getActiveTab().split('.')[1], newQuestionAttrs = Immutable.Map(data).merge({
      source: source,
      id: utils.generateGUID()
    }), questionToSave = newQuestionAttrs.merge(calculateTitles(newQuestionAttrs.toJS())), questions.push(questionToSave));
    if (questionCopyId) {
      questionCopy = questionToSave.withMutations(function(q) {
        q.set('adminComments', Immutable.Map());
        q.set('memberComments', Immutable.Map());
        return q.set('id', questionCopyId);
      });
      updatedQuestions = updatedQuestions.push(questionCopy);
    }
    this.setState(this.state.withMutations(function(state) {
      state.setIn(updatePath, updatedQuestions);
      state.setIn(['_meta', 'addingQuestion'], null);
      return state.setIn(['_meta', 'editingQuestion'], questionCopyId);
    }));
    return this._save();
  };

  QuestionsGenerationStore.prototype.onDeleteQuestion = function(id) {
    var didDelete, questions, updatePath;
    updatePath = this._getActiveTabQuestionsPath();
    questions = this.state.getIn(updatePath);
    didDelete = false;
    this.setState(this.state.withMutations(function(state) {
      if (id) {
        state.setIn(['_meta', 'editingQuestion'], null);
        state.setIn(updatePath, questions.splice(questions.findIndex(function(q) {
          return q.get('id') === id;
        }), 1));
        return didDelete = true;
      } else {
        return state.setIn(['_meta', 'addingQuestion'], null);
      }
    }));
    if (didDelete) {
      return this._save();
    }
  };

  QuestionsGenerationStore.prototype.onSaveAdminComment = function(params) {
    var idOrKey, newText, questionIdx, questions, questionsPath, step, updatePath;
    step = params.step, newText = params.newText, idOrKey = params.idOrKey;
    questionsPath = this._getActiveTabQuestionsPath();
    questions = this.state.getIn(questionsPath);
    questionIdx = questions.findIndex(function(q) {
      return q.get('id') === idOrKey;
    });
    if (questionIdx === -1) {
      return;
    }
    updatePath = questionsPath.concat([questionIdx, 'adminComments', step]);
    this.setState(this.state.setIn(updatePath, newText));
    return this._save();
  };

  QuestionsGenerationStore.prototype.onChangeQuestionApprovalStatus = function(params) {
    var approvalVal, id, questionIdx, questionsPath, updatePath;
    id = params.id, approvalVal = params.approvalVal;
    questionsPath = this._getActiveTabQuestionsPath();
    questionIdx = this.state.getIn(questionsPath).findIndex(function(q) {
      return q.get('id') === id;
    });
    updatePath = questionsPath.concat([questionIdx, 'approvalStatus']);
    this.setState(this.state.setIn(updatePath, approvalVal));
    return this._save();
  };

  QuestionsGenerationStore.prototype.onShowCommentsExport = function() {
    return this.setState(this.state.setIn(['_meta', 'isExportingComments'], true));
  };

  QuestionsGenerationStore.prototype.onHideCommentsExport = function() {
    return this.setState(this.state.setIn(['_meta', 'isExportingComments'], false));
  };

  QuestionsGenerationStore.prototype.onBeginDrag = function(params) {
    var dragData, groupType, qIndex, type;
    type = params.type, qIndex = params.qIndex;
    dragData = (function() {
      switch (type) {
        case 'question':
          return Immutable.Map({
            startPosition: qIndex,
            currentPosition: qIndex
          });
        case 'question:approval':
          groupType = params.groupType;
          return Immutable.Map({
            startPosition: [groupType, qIndex],
            currentPosition: [groupType, qIndex]
          });
      }
    })();
    return this.setState(this.state.setIn(['_meta', 'dragData'], dragData));
  };

  QuestionsGenerationStore.prototype.onHover = function(params) {
    var currentPosition, dragQuestion, hoverGroupType, hoverIndex, questionGroupsPath, questionId, questionsPath, stepName, type;
    type = params.type, hoverIndex = params.hoverIndex;
    currentPosition = this.state.getIn(['_meta', 'dragData', 'currentPosition']);
    switch (type) {
      case 'question':
        questionsPath = this._getActiveTabQuestionsPath();
        dragQuestion = this.state.getIn(questionsPath.concat([currentPosition]));
        return this.setState(this.state.withMutations(function(state) {
          var reorderedQuestions;
          state.setIn(['_meta', 'dragData', 'currentPosition'], hoverIndex);
          reorderedQuestions = state.getIn(questionsPath)["delete"](currentPosition).splice(hoverIndex, 0, dragQuestion);
          return state.setIn(questionsPath, reorderedQuestions);
        }));
      case 'question:approval':
        hoverGroupType = params.hoverGroupType;
        stepName = QUESTION_GENERATION_STEPS[5];
        questionGroupsPath = ['questionsGenerationData', 'steps', stepName, 'questionGroups'];
        questionId = this.state.getIn(questionGroupsPath.concat(currentPosition));
        return this.setState(this.state.withMutations(function(state) {
          var updatedQuestionGroups;
          state.setIn(['_meta', 'dragData', 'currentPosition'], [hoverGroupType, hoverIndex]);
          updatedQuestionGroups = state.getIn(questionGroupsPath).deleteIn(currentPosition).update(hoverGroupType, function(questionIds) {
            return questionIds.splice(hoverIndex, 0, questionId);
          });
          return state.setIn(questionGroupsPath, updatedQuestionGroups);
        }));
    }
  };

  QuestionsGenerationStore.prototype.onEndDrag = function() {
    var hasPositionChanged, startPosition;
    startPosition = this.state.getIn(['_meta', 'dragData', 'startPosition']);
    hasPositionChanged = this.state.getIn(['_meta', 'dragData', 'currentPosition']) !== startPosition;
    this.setState(this.state.setIn(['_meta', 'dragData'], null));
    if (hasPositionChanged) {
      return this._save();
    }
  };

  QuestionsGenerationStore.prototype.onSaveTodos = function(data) {
    var activeTab, questionId, todos;
    questionId = data.questionId, todos = data.todos;
    activeTab = this.getActiveTab();
    this.setState(this.state.setIn(['questionsGenerationData', 'todos', activeTab, questionId], todos));
    return this._save();
  };

  QuestionsGenerationStore.prototype.onSetDisagreementResolved = function(data) {
    var newQuestions, qIndex, questionId, questions, questionsPath, value;
    questionId = data.questionId, value = data.value;
    questionsPath = this._getActiveTabQuestionsPath();
    questions = this.state.getIn(questionsPath);
    qIndex = questions.findIndex(function(q) {
      return q.get('id') === questionId;
    });
    newQuestions = questions.setIn([qIndex, 'priorityDisagreementResolved'], value);
    return this.setState(this.state.setIn(questionsPath, newQuestions));
  };

  QuestionsGenerationStore.prototype.onSave = function() {
    return this._save();
  };

  QuestionsGenerationStore.prototype._proceedToNextStep = function(response) {
    var currentStep, currentStepPath, currentStepQuestionsPath, membersSentTo, nextStep, nextStepPath;
    membersSentTo = this.state.getIn(['_meta', 'sendingToMembers']);
    currentStep = this.getCurrentStep();
    nextStep = this.getNextStep();
    currentStepPath = ['questionsGenerationData', 'steps', currentStep];
    currentStepQuestionsPath = this._getActiveTabQuestionsPath();
    nextStepPath = ['questionsGenerationData', 'steps', nextStep];
    this.setState(this.state.withMutations(function(state) {
      if (membersSentTo) {
        state.setIn(['_meta', 'sendingToMembers'], null);
      }
      state.setIn(['_meta', 'activeTab'], nextStep);
      state.setIn(['_meta', 'closingStep'], null);
      state.setIn(['questionsGenerationData', 'currentStep'], nextStep);
      state.setIn(currentStepPath.concat(['closed']), true);
      if (membersSentTo) {
        state.setIn(nextStepPath.concat(['memberIds']), Immutable.List(membersSentTo));
      }
      if (nextStep === 'questions.brainstorming') {
        state.setIn(nextStepPath.concat(['formsDocId']), response);
      }
      if (currentStep === 'questions.brainstorming') {
        state.mergeDeepIn(currentStepQuestionsPath, Immutable.fromJS(response));
      }
      if (nextStep === 'questions.rating' || nextStep === 'questions.approval') {
        state.setIn(nextStepPath.concat(['formsIds']), response);
      }
      if (currentStep === 'questions.rating') {
        state = storeHelper.updateWithRatingData(state, response);
        state = storeHelper.recordSubmittedMemberData(state, currentStep, response);
      }
      if (currentStep === 'questions.proposal') {
        state = storeHelper.createApprovalQuestionGroupsFromRating(state);
      }
      if (currentStep === 'questions.approval') {
        state = storeHelper.fetchResultsSuccessApproval(state, response);
      }
      return state.setIn(nextStepPath.concat(['questions']), state.getIn(currentStepQuestionsPath));
    }));
    return this._save();
  };

  QuestionsGenerationStore.prototype._getActiveTabQuestionsPath = function() {
    var activeTab;
    activeTab = this.getActiveTab();
    return storeHelper.getStepQuestionsPath(activeTab);
  };

  QuestionsGenerationStore.prototype._save = function() {
    var doc, projectId;
    projectId = mediator.project.id;
    doc = this.state.get('questionsGenerationData').toJS();
    if (doc._id == null) {
      doc._id = QUESTION_GENERATION_DOC_ID;
    }
    return this.getInstance().save(projectId, doc);
  };

  QuestionsGenerationStore.prototype.getCurrentStep = function() {
    return this.state.getIn(['questionsGenerationData', 'currentStep']);
  };

  QuestionsGenerationStore.prototype.getNextStep = function() {
    return storeHelper.getNextStep(this.getCurrentStep());
  };

  QuestionsGenerationStore.prototype.getActiveTab = function() {
    return this.state.getIn(['_meta', 'activeTab']);
  };

  QuestionsGenerationStore.prototype.getEditingQuestion = function() {
    return this.state.getIn(['_meta', 'editingQuestion']);
  };

  QuestionsGenerationStore.prototype.getAddingQuestion = function() {
    return this.state.getIn(['_meta', 'addingQuestion']);
  };

  QuestionsGenerationStore.prototype.getStepsData = function() {
    return this.state.getIn(['questionsGenerationData', 'steps']);
  };

  QuestionsGenerationStore.prototype.getTodos = function() {
    return this.state.getIn(['questionsGenerationData', 'todos']);
  };

  QuestionsGenerationStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  QuestionsGenerationStore.prototype.isFetchingResults = function() {
    return this.state.getIn(['_meta', 'fetchingResults'], null);
  };

  QuestionsGenerationStore.prototype.isClosingStep = function() {
    return this.state.getIn(['_meta', 'closingStep'], null);
  };

  QuestionsGenerationStore.prototype.isExportingComments = function() {
    return this.state.getIn(['_meta', 'isExportingComments'], false);
  };

  QuestionsGenerationStore.prototype.getStepQuestionsWithComments = function() {
    return storeHelper.getStepQuestionsWithComments(this.state, this.getActiveTab());
  };

  return QuestionsGenerationStore;

})();

module.exports = alt.createStore(immutable(QuestionsGenerationStore), 'QuestionsGenerationStore');
