var isValidLimit;

isValidLimit = function(value, limit, condition) {
  if (condition == null) {
    condition = 'lt';
  }
  if (limit === -1) {
    return true;
  }
  switch (condition) {
    case 'lt':
      return value < limit;
    case 'lte':
      return value <= limit;
  }
};

module.exports = {
  isValidLimit: isValidLimit
};
