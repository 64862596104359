{ func, instanceOf } = PropTypes
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'
Confirmation = require 'components/common/confirmation'

CloseVotingConfirmation = createReactClass

  propTypes:
    etdHighlights: instanceOf(Immutable.Map).isRequired
    onCancel: func.isRequired
    onApply: func.isRequired

  mixins: [Translation('voting:panel_voice')]

  isEtdChanged: ->
    @props.etdHighlights.includes true

  getMessage: ->
    # checks if at least one section is highlighted (changed)
    @i18n if @isEtdChanged()
      'panel_voice_voting_changed_close_confirmation'
    else
      'panel_voice_voting_close_confirmation'

  getButtonsClasses: ->
    # this looks quite unintuitive
    # it's to make cancel button more 'important' when EtD has changed
    # and convince user to resend EtD
    if @isEtdChanged()
      apply: 'btn-cancel'
      cancel: 'btn-submit'
    else
      apply: 'btn-submit'
      cancel: 'btn-cancel'

  render: ->
    { apply: applyClass, cancel: cancelClass } = @getButtonsClasses()

    <Modal className="confirmation-modal" isOpen>
      <Confirmation
        question={@getMessage()}
        onConfirm={@props.onApply}
        onCancel={@props.onCancel}
        confirmLabel={@i18n '/actions.close'}
      />
    </Modal>

module.exports = CloseVotingConfirmation
