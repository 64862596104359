var MDGTopicActions, MDGTopics, MDGTopicsToolbar, MDGTopicsView, MDG_TOPIC, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

MDGTopicActions = require('actions/mdg_topic_actions');

MDGTopics = require('components/administration/mdg_topics');

MDGTopicsToolbar = require('components/administration/mdg_topics_toolbar');

MDG_TOPIC = require('lib/db_docs/doc_types').MDG_TOPIC;

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = MDGTopicsView = (function(_super) {
  __extends(MDGTopicsView, _super);

  function MDGTopicsView() {
    return MDGTopicsView.__super__.constructor.apply(this, arguments);
  }

  MDGTopicsView.prototype.container = '#page-container';

  MDGTopicsView.prototype.autoRender = true;

  MDGTopicsView.prototype._stopInputsPropagation = false;

  MDGTopicsView.prototype.initialize = function() {
    MDGTopicsView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: MDGTopicsToolbar
    }));
    return this.enable(ReactComponent);
  };

  MDGTopicsView.prototype.afterRender = function() {
    MDGTopicsView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(MDGTopics, MDGTopicActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id,
        opts: {
          filter: function(doc) {
            return doc.docType === MDG_TOPIC;
          }
        }
      }
    });
  };

  MDGTopicsView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('MDGTopicStore');
    return MDGTopicsView.__super__.dispose.apply(this, arguments);
  };

  return MDGTopicsView;

})(View);
