var editMetadata, editingCell, provider;

provider = require('lib/gdt_tables/cell_props_provider').provider;

editingCell = require('lib/gdt_tables/match_strategies/outcomes_table_match_strategies').editingCell;

editMetadata = function(_cellId, _arg) {
  var outcomeEditMetadata;
  outcomeEditMetadata = _arg.outcomeEditMetadata;
  return outcomeEditMetadata;
};

module.exports = provider('editMetadata', editMetadata, editingCell);
