mediator = require 'mediator'
W = require 'when'
Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'

{ func, string } = PropTypes

ProjectTypeSelect = createReactClass

  displayName: 'ProjectTypeSelect'

  mixins: [Translation('projects:new_project_dialog')]

  propTypes:
    onProjectTypeSelect: func.isRequired,
    selected: string,

  getDefaultProps: ->
    selected: null

  _enlargePicture: (e) ->
    $img = $(e.target).find('img').clone()
    $img.removeAttr 'height'
    $img.removeAttr 'width'
    $img.css 'max-height', $(document).height() - 50
    $img.css 'max-width', $(document).width() - 50
    mediator.dialogs.infoBox $img,
      position: 'top'

  showHelpInfo: (infoFor) -> =>
    # get info
    path = mediator.helpResources.resourceFor infoFor
    W $.get(path, contentType: 'text/html')
    .then (content) =>
      dialog = mediator.dialogs.infoBox null,
        title: @i18n "../welcome.capabilities.#{infoFor}"
        message: content
        width: 700
        position: 'top'
        offsetY: 50
        hideOnClick: false

      dialog.$el.find('.thumbnail').on 'click', @_enlargePicture
      dialog.$el.find('img').load ->
        height = Math.min(
          $( window ).height() - 100
          dialog.$el.find('.notifier-inner').outerHeight()
        )
        dialog.$el.find('.notifier-inner').css('height': height)

  getProjectTypeOptions: ->
    [ 'grade_ep' ,'sof' ,'etd' ,'full_guideline' ].map (optionName) =>
      moreInfoLink =
        <a onClick={@showHelpInfo optionName}>
          {@i18n '../welcome.start_new_section.more'}
        </a>

      value: optionName
      text: @i18n "../welcome.start_new_section.#{optionName}"
      label: @i18n "../welcome.capabilities.#{optionName}"
      annotation: @i18n "../welcome.start_new_section.#{optionName}_annotation", {},
        { moreInfoLink }

  render: ->
    <label>
      <span>{@i18n 'type'}</span>
      <div className='input-el'>
        <Select
          commonAnnotation={@i18n '../welcome.start_new_section.project_type_annotation'}
          options={@getProjectTypeOptions()}
          onChange={@props.onProjectTypeSelect}
          selected={@props.selected}
        />
      </div>
    </label>

module.exports = ProjectTypeSelect
