var NMA_OUTCOME_PREFIX, NMA_QUESTION_PREFIX, NmaInterventionType, NmaListActions, NmaOutcomeDoc, NmaQuestionDoc, W, alt, appUtils, callMethod, generateActions, generateGUID, mediator, _ref, _ref1;

alt = require('alt');

appUtils = require('lib/app_utils');

_ref = require('base/lib/utils'), callMethod = _ref.callMethod, generateGUID = _ref.generateGUID;

generateActions = require('actions/utils/generate_actions');

NmaInterventionType = require('lib/db_docs/field_types/nma_intervention_type');

mediator = require('mediator');

_ref1 = require('lib/doc_prefixes'), NMA_OUTCOME_PREFIX = _ref1.nmaOutcome, NMA_QUESTION_PREFIX = _ref1.nmaQuestion;

NmaListActions = require('actions/nma_list_actions');

NmaOutcomeDoc = require('lib/db_docs/nma_outcome_doc');

NmaQuestionDoc = require('lib/db_docs/nma_question_doc');

W = require('when/when');

module.exports = generateActions({
  autoGenerate: ['closeInterventionEditModal', 'closeInterventionManagementModal', 'closeOutcomeModal', 'fetchError', 'fetchQuestionSuccess', 'fetchOutcomesSuccess', 'moveIntervention', 'moveOutcome', 'openOutcomeModal', 'openInterventionEditModal', 'openInterventionManagementModal'],
  name: 'NMAListActions',
  actions: {
    dbChange: function(change, _pending, _lastSeq) {
      var isOutcome, isQuestion;
      NmaListActions.dbChange(change, _pending, _lastSeq);
      isOutcome = change.id.indexOf(NMA_OUTCOME_PREFIX) === 0;
      isQuestion = change.id.indexOf(NMA_QUESTION_PREFIX) === 0;
      if (!(isOutcome || isQuestion)) {
        return;
      }
      return this.dispatch(change);
    },
    fetch: function(_arg) {
      var dbId, docId;
      dbId = _arg.dbId, docId = _arg.docId;
      this.dispatch(docId);
      return NmaListActions.fetch({
        dbId: dbId
      }).then((function(_this) {
        return function(docs) {
          var doc, _ref2;
          doc = _.find(docs, function(doc) {
            return doc._id === docId;
          });
          if (!doc) {
            throw new Error("No question " + docId);
          }
          _this.actions.fetchQuestionSuccess(doc);
          return NmaOutcomeDoc.at(dbId, (_ref2 = doc.outcomes) != null ? _ref2 : []).fetch().then(function(_arg1) {
            var rows;
            rows = _arg1.rows;
            docs = _.compact(_.pluck(rows, 'doc'));
            _this.actions.fetchOutcomesSuccess(docs);
            return docs;
          });
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('nma:question.fetch_error'));
          return _this.actions.fetchError(err);
        };
      })(this));
    },
    createOutcome: function(questionId, outcomeData) {
      var outcomeId;
      this.dispatch();
      outcomeId = "" + NMA_OUTCOME_PREFIX + (generateGUID());
      return NmaOutcomeDoc.at(mediator.project.id).create(_.extend({}, outcomeData, {
        _id: outcomeId,
        questionId: questionId
      })).then(function() {
        return NmaQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('addOutcome', outcomeId));
      }).then(function() {
        return mediator.dialogs.info($.t('nma:question.outcomes.create_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:question.outcomes.create_error')))["finally"]((function(_this) {
        return function() {
          return _this.actions.closeOutcomeModal();
        };
      })(this));
    },
    updateOutcome: function(outcomeData) {
      this.dispatch();
      return NmaOutcomeDoc.at(mediator.project.id, outcomeData._id).update(outcomeData).then(function() {
        return mediator.dialogs.info($.t('nma:question.outcomes.update_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:question.outcomes.update_error')))["finally"]((function(_this) {
        return function() {
          return _this.actions.closeOutcomeModal();
        };
      })(this));
    },
    updateOutcomeInModal: function(field, value) {
      return this.dispatch({
        field: field,
        value: value
      });
    },
    deleteOutcome: function(outcome) {
      var outcomeId, questionId;
      outcomeId = outcome._id, questionId = outcome.questionId;
      return W.all([NmaQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('deleteOutcome', outcomeId)), NmaOutcomeDoc.at(mediator.project.id, outcomeId)["delete"]()]).then(function() {
        return mediator.dialogs.info($.t('nma:question.outcomes.delete_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:question.outcomes.delete_error')));
    },
    saveMovedOutcome: function(questionId) {
      var outcomes, question;
      question = alt.stores.NMAQuestionStore.getCurrentQuestion();
      outcomes = question.get('outcomes', Immutable.List()).toJS();
      return NmaQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('setOutcomes', outcomes))["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:question.outcomes.move_error')));
    },
    createIntervention: function(questionId, interventionData) {
      this.dispatch();
      return NmaQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('addIntervention', NmaInterventionType.defaults(interventionData))).then(function() {
        return mediator.dialogs.info($.t('nma:question.interventions.create_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:question.interventions.create_error')))["finally"]((function(_this) {
        return function() {
          return _this.actions.closeInterventionEditModal();
        };
      })(this));
    },
    updateIntervention: function(questionId, interventionData) {
      this.dispatch();
      return NmaQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('updateIntervention', interventionData)).then(function() {
        return mediator.dialogs.info($.t('nma:question.interventions.update_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:question.interventions.update_error')))["finally"]((function(_this) {
        return function() {
          return _this.actions.closeInterventionEditModal();
        };
      })(this));
    },
    updateInterventionInModal: function(field, value) {
      return this.dispatch({
        field: field,
        value: value
      });
    },
    deleteIntervention: function(questionId, intervention) {
      return NmaQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('deleteIntervention', intervention._id)).then(function() {
        return mediator.dialogs.info($.t('nma:question.interventions.delete_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:question.interventions.delete_error')));
    },
    saveMovedIntervention: function(questionId) {
      var interventions, question;
      question = alt.stores.NMAQuestionStore.getCurrentQuestion();
      interventions = question.get('interventions', Immutable.List()).toJS();
      return NmaQuestionDoc.at(mediator.project.id, questionId).updateAsInstance(callMethod('setInterventions', interventions))["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:question.interventions.move_error')));
    }
  }
});
