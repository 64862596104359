ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Button = require 'components/common/button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdActions = require 'actions/etd_actions'
Modal = require 'components/common/modal'
Rating = require 'components/common/rating'
RankingDescription = require 'components/etd/judgements/ranking_description'
Translation = require 'components/mixins/translation'
{ getTagsByKeys } = require 'lib/etd_helper'

DEFAULT_RATING_VALUES = [
  'most_effective'
  'intermediate'
  'least_effective'
]

MAX_RATING_VALUE = 3

RankingModal = createReactClass

  propTypes:
    handleRating: PropTypes.func.isRequired
    isOpen: PropTypes.bool.isRequired
    onApply: PropTypes.func.isRequired
    onCloseModal: PropTypes.func.isRequired
    questionType: PropTypes.string.isRequired
    rating: PropTypes.instanceOf(Immutable.Map).isRequired
    sectionId: PropTypes.string.isRequired
    tags: PropTypes.instanceOf(Immutable.Map).isRequired
    type: PropTypes.oneOf(['overarching', 'qualityIndicators']).isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  render: ->
    {
      handleRating
      isOpen
      onApply
      onCloseModal
      questionType
      rating
      sectionId
      tags
      type
    } = @props

    COMMON_OPTIONS = if type is 'overarching'
      ['no_included_studies', 'all_acceptable', 'dont_know']
    else
      ['dont_know']

    descriptionType = if type is 'overarching' then questionType else type

    <Modal
      className="ranking-judgements"
      isOpen={isOpen}
      closeButton={true}
      onClose={onCloseModal}
      title={@i18n 'ranking_judgement.name'}
    >
      <div className="ranking-modal-content">
        <div className="ranking-modal-content__description">
          <RankingDescription type={descriptionType} tags={tags} />
        </div>
        <div className="mt-10 mb-10">
          <div className="bold">{@i18n "#{_.string.underscored(sectionId)}.title"}</div>
        </div>
        <table className="interventions-rating">
          <thead>
            <tr>
              {tags.map (tag) ->
                <th className="blue-cell" key={tag.get('id')}>
                  {tag.get('name')}
                </th>
              .toList()}
              {COMMON_OPTIONS.map (option) =>
                <th key={option} className="gray-cell">{@i18n "radio_values.#{option}"}</th>
              }
            </tr>
          </thead>
          <tbody>
            <tr>
              {tags.map (tag) ->
                <td key={tag.get('id')}>
                  <Rating
                    onRate={handleRating(sectionId, tag.get('id'))}
                    rating={rating.get(tag.get('id'), 0)}
                    maxRate={MAX_RATING_VALUE}
                  />
                </td>
              .toList()}
              {COMMON_OPTIONS.map (radioBtnKey) =>
                <td key={radioBtnKey}>
                  <input
                    type="radio"
                    name="common-intervention-ranking"
                    value={radioBtnKey}
                    onChange={(e) ->
                      handleRating(sectionId, 'all')(e.target.value)}
                  />
                </td>
              }
            </tr>
          </tbody>
        </table>
      </div>
      <ApplyCancelButtons
        onApply={onApply}
        onCancel={onCloseModal}
      />
    </Modal>

RankingJudgement = createReactClass

  displayName: "RankingJudgement"

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  propTypes:
    criterion: PropTypes.instanceOf(Immutable.Map).isRequired
    editable: PropTypes.bool.isRequired
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map).isRequired
    qualityIndicators: PropTypes.instanceOf(Immutable.List)
    questionType: PropTypes.string.isRequired
    rating: PropTypes.instanceOf(Immutable.Map).isRequired
    renderMode: PropTypes.string.isRequired
    sectionId: PropTypes.string.isRequired
    type: PropTypes.oneOf(['overarching', 'qualityIndicators']).isRequired

  getDefaultProps: ->
    qualityIndicators: Immutable.List()

  getInitialState: ->
    isModalOpen: false

  openModal: ->
    return if @props.readOnly or not @props.editable
    @setState isModalOpen: true

  closeModal: ->
    @setState isModalOpen: false

  onApply: ->
    EtdActions.save()
    @closeModal()

  handleRating: (sectionId, key) -> (newRating) ->
    EtdActions.setRating { sectionId, key, newRating }

  getTags: ->
    { overarchingQuestionData, qualityIndicators, type } = @props
    if type is 'overarching'
      getTagsByKeys overarchingQuestionData.get('includedTags'), ['intervention', 'indexTest']
    else
      Immutable.Map qualityIndicators.map (v) -> [v.get('id'), v]

  render: ->
    { criterion, rating, renderMode, sectionId, questionType, type } = @props

    tags = @getTags()
    ratingValues = criterion.get('ratingOptions', Immutable.fromJS(DEFAULT_RATING_VALUES))

    <div className="flex flex-col h-full">
      {if rating.has('all')
        <div className="flex flex-grow">
          {@i18n "radio_values.#{rating.get('all')}"}
        </div>
      else
        groupedRating = rating.groupBy(_.identity)
        <div className="flex flex-col flex-grow">
          {ratingValues.map (key, index) =>
            <div className="mb-10" key={key}>
              <span className="bold inline-block mr-5">
                {@i18n "ranking_judgement.#{key}"}:
              </span>
              {groupedRating.get(MAX_RATING_VALUE - index, Immutable.Map()).map (__, key) ->
                <span className="inlne-block" key={key}>
                  {tags.getIn([key, 'name'])}
                </span>
              .toList().interpose(', ')}
            </div>
          }
        </div>
      }
      {renderMode isnt 'printout' and
        <div className="mt-10">
          <Button
            className="btn btn-block detailed-judgements-btn"
            label={@i18n "ranking_judgement.rank"}
            onClick={@openModal}
          />
          <RankingModal
            criterion={criterion}
            handleRating={@handleRating}
            isOpen={@state.isModalOpen}
            onApply={@onApply}
            onCloseModal={@closeModal}
            questionType={questionType}
            rating={rating}
            sectionId={sectionId}
            tags={tags}
            type={type}
          />
        </div>
      }
    </div>

module.exports = RankingJudgement
