DynamicHeightContainer = require 'components/common/dynamic_height_container'
GlobalExportModal = require 'components/global_export/global_export_modal'
NoProjects = require 'components/projects/no_projects'
ProjectsListItem = require 'components/projects/projects_list_item'
{ TransitionGroup } = ReactTransitionGroup


ProjectsList = createReactClass
  displayName: 'ProjectsList'

  propTypes:
    projects: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.List),
      PropTypes.instanceOf(Immutable.Map)]
    ).isRequired
    projectsQuestions: PropTypes.instanceOf(Immutable.Map)
    projectsLabels: PropTypes.instanceOf(Immutable.Map)
    projectsNotes: PropTypes.instanceOf(Immutable.Map)
    labelsData: PropTypes.instanceOf(Immutable.Map)
    highlightedProjectId: PropTypes.string
    tabName: PropTypes.string.isRequired
    onSort: PropTypes.func
    projectsStatuses: PropTypes.instanceOf(Immutable.Map)
    projectsReplicationProgress: PropTypes.instanceOf(Immutable.Map)
    projectsRemoteInfo: PropTypes.instanceOf(Immutable.Map)
    organizationId: PropTypes.string

  getDefaultProps: ->
    highlightedProjectId: null
    organizationId: null

  renderProject: (project) ->
    projectId = project.get 'id'
    projectLabelId = @props.projectsLabels?.get projectId
    projectLabel = @props.labelsData.get projectLabelId

    <ProjectsListItem
      key={projectId}
      project={project}
      projectLabelId={projectLabelId}
      projectLabel={projectLabel}
      labelsData={@props.labelsData}
      projectQuestions={@props.projectsQuestions?.get projectId}
      collectionType={@props.tabName}
      highlighted={@props.highlightedProjectId is projectId}
      projectStatus={@props.projectsStatuses?.get projectId}
      replicationProgress={@props.projectsReplicationProgress?.get projectId, Immutable.Map()}
      dataSize={project.get 'dataSize'}
      permittedActions={@props.permittedProjectActions.get projectId}
      organizationId={@props.organizationId}
    />

  render: ->
    <DynamicHeightContainer>
      <TransitionGroup component='div' className='projects-list'>
        {@props.projects.map(@renderProject).toList()}
      </TransitionGroup>
      <GlobalExportModal />
    </DynamicHeightContainer>

module.exports = ProjectsList
