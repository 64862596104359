var QualityIndicatorType, convertToDraftJS, documentIdWithAutoGUID, extendedParse, initial, object, optional, shape, string, _ref, _ref1;

shape = require('lib/db_docs/field_types/shape');

documentIdWithAutoGUID = require('lib/db_docs/field_types/document_id_with_auto_guid');

_ref = require('lib/db_docs/field_types/type_decorators'), initial = _ref.initial, extendedParse = _ref.extendedParse;

_ref1 = require('lib/db_docs/field_types/built_in_types'), string = _ref1.string, object = _ref1.object;

shape = require('lib/db_docs/field_types/shape');

optional = shape.typeDecorators.optional;

convertToDraftJS = require('lib/draft_utils').convertToDraftJS;

QualityIndicatorType = shape({
  calculationFormula: optional(extendedParse(convertToDraftJS)(object)),
  performanceIndicator: optional(extendedParse(convertToDraftJS)(object)),
  explanationOfTerms: initial('')(string),
  id: documentIdWithAutoGUID,
  name: initial('')(string),
  outcome: initial('')(string),
  type: initial('')(string),
  includeForMonitoring: initial('')(string)
});

module.exports = QualityIndicatorType;
