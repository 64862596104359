var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "<table class=\"standard-table diagnostic-effect\" cellpadding=\"0\">\n  <tr>\n    <td>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.effect_per", {"name":"i18n","hash":{},"data":data})))
    + "\n    </td>\n    <td class=\"value-cell\" data-property=\"absDenominator\">"
    + escapeExpression(((helper = (helper = helpers.absDenominator || (depth0 != null ? depth0.absDenominator : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"absDenominator","hash":{},"data":data}) : helper)))
    + "</td>\n  </tr>\n</table>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}