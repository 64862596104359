EditButton = require 'components/common/edit_button'
CommentButton = require 'components/common/empty_comment_button'
Question = require 'components/scope/questions/question'
QuestionEdit = require 'components/scope/questions/question_edit'
CommentsBlocks = require 'components/scope/questions/comments_blocks'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
QuestionCommentsMixin = require 'components/mixins/question_comments_mixin'

EditableQuestionWithComments = createReactClass
  displayName: 'EditableQuestionWithComments'

  mixins: [
    CustomRenderMixin
    Translation('')
    QuestionCommentsMixin
  ]
  componentWillReceiveProps: (nextProps) ->
    # close comments block if no longer in edit mode
    if @props.isEditing and not nextProps.isEditing
      @setState showingComments: false

  onEdit: ->
    @props.onEdit @props.data.get 'id'
    @setState showingComments: true

  renderCommentsToggle: ->
    buttonClass = classNames 'comment-blank-hide': @state.showingComments,
      'comment-blank': not @state.showingComments

    if @props.isEditing
      <span className='side-spacer' />
    else
      <CommentButton
        className={buttonClass}
        title={@i18n 'scope:outcomes.view_members_comments'}
        onClick={@toggleComments}
      />

  render: ->
    <div>
      {if @props.isEditing
        <QuestionEdit {...@props} />
      else
        <div>
          <div className='buttons'>
            <EditButton
              className='edit'
              title={@i18n 'actions.edit'}
              onClick={@onEdit}
            />
            {@renderCommentsToggle()}
          </div>
          <div className='caption'>
            <Question text={@props.data.get 'question'} />
          </div>
        </div>
      }
      {if @state.showingComments
        <CommentsBlocks
          memberComments={@props.memberComments}
          adminComments={@props.adminComments}
          membersMap={@props.membersMap}
          readOnly={@props.readOnly}
          onApplyAdminComment={@onApplyAdminComment}
        />
      }
    </div>

module.exports = EditableQuestionWithComments
