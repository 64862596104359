var DbHelper, EXPORT_TIMEOUT, ajax, retryRequest;

ajax = require('actions/async/api_helpers').ajax;

DbHelper = require('base/lib/db_helper');

retryRequest = require('actions/async/retry_helpers').retryRequest;

EXPORT_TIMEOUT = 60 * 1000;

module.exports = {
  publishDoc: function(docType, projectId, id, payload) {
    var apiCall;
    apiCall = function() {
      return ajax({
        contentType: 'application/json',
        dataType: 'json',
        url: "" + (DbHelper.getBackendUrl()) + "/dbep/document-sections/" + projectId + "/" + docType + "/" + id,
        type: 'PUT',
        timeout: EXPORT_TIMEOUT,
        data: JSON.stringify(payload),
        xhrFields: {
          withCredentials: true
        }
      });
    };
    return retryRequest(apiCall);
  }
};
