var FootnotesBinding, ModelTrait, Set,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModelTrait = require('base/lib/traits/model_trait');

Set = require('base/lib/set');

module.exports = FootnotesBinding = (function(_super) {
  __extends(FootnotesBinding, _super);

  function FootnotesBinding() {
    return FootnotesBinding.__super__.constructor.apply(this, arguments);
  }

  FootnotesBinding.prototype.apply = function(model) {
    FootnotesBinding.__super__.apply.apply(this, arguments);
    this._addFunction(model, 'getSetOfFootnoteIdsForProperty');
    this._addFunction(model, 'parseFootnoteId');
    this._addFunction(model, 'bindFootnote');
    this._addFunction(model, 'unbindFootnote');
    if (!model.get('footnotes')) {
      return model.set('footnotes', {});
    }
  };

  FootnotesBinding.prototype.getSetOfFootnoteIdsForProperty = function(originalFunction, trait, property) {
    var _ref;
    return new Set((_ref = this.get('footnotes')[property]) != null ? _ref : []);
  };

  FootnotesBinding.prototype.parseFootnoteId = function(originalFunction, trait, footnoteId) {
    if (isNaN(footnoteId)) {
      return footnoteId;
    } else {
      return parseInt(footnoteId);
    }
  };

  FootnotesBinding.prototype.bindFootnote = function(originalFunction, trait, footnoteId, property) {
    var footnoteIdsForProperty;
    footnoteIdsForProperty = this.getSetOfFootnoteIdsForProperty(property);
    this.get('footnotes')[property] = footnoteIdsForProperty.add(this.parseFootnoteId(footnoteId)).asArray();
    this.store();
    this.trigger('footnoteBound', this.parseFootnoteId(footnoteId), property);
    if (isNaN(footnoteId)) {
      return this.publishEvent('specialFootnoteBound');
    }
  };

  FootnotesBinding.prototype.unbindFootnote = function(originalFunction, trait, footnoteId, property) {
    var footnoteIdsForProperty, footnotes, _i, _len, _ref;
    if (property) {
      footnoteIdsForProperty = this.getSetOfFootnoteIdsForProperty(property);
      footnotes = this.get('footnotes');
      footnotes[property] = footnoteIdsForProperty.remove(this.parseFootnoteId(footnoteId)).asArray();
      this.trigger('footnoteUnbound', footnoteId, property);
      if (isNaN(footnoteId)) {
        this.publishEvent('specialFootnoteUnbound');
      }
    } else {
      _ref = _.keys(this.get('footnotes'));
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        property = _ref[_i];
        this.unbindFootnote(footnoteId, property);
      }
    }
    return this.store();
  };

  return FootnotesBinding;

})(ModelTrait);
