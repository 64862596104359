var NOT_MEASUED_NOT_REPORTED, formatters, risks,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

formatters = require('lib/formatters');

NOT_MEASUED_NOT_REPORTED = ['NOT_MEASURED', 'NOT_REPORTED'];

risks = ["calculated", "low", "moderate", "high"];

module.exports = {
  notMeasuredNotReported: NOT_MEASUED_NOT_REPORTED,
  quality: function(attributes) {
    return formatters.outcome.quality(attributes);
  },
  contSofControl: function(attributes) {
    var _ref;
    if (_ref = attributes.metaType, __indexOf.call(NOT_MEASUED_NOT_REPORTED, _ref) >= 0) {
      return '-';
    }
    if (attributes.metaType === 'NOT_POOLED') {
      return $.t('es:outcome.see_comment');
    }
    return formatters.outcome.contSofControl(attributes);
  },
  dichSofControl: function(attributes, risk) {
    var riskValue, _ref;
    if (_ref = attributes.metaType, __indexOf.call(NOT_MEASUED_NOT_REPORTED, _ref) >= 0) {
      return '-';
    }
    if (attributes.metaType === 'NOT_POOLED') {
      return $.t('es:outcome.see_comment');
    }
    riskValue = risk === 'calculated' ? attributes.controlCount / attributes.controlTotal : attributes["" + risk + "ControlRisk"] / 100;
    return formatters.outcome.formatDichSofControl(riskValue, attributes.absDenominator);
  },
  riskDifference: function(attributes, risk) {
    var _ref;
    if (risk == null) {
      risk = 'calculated';
    }
    if (_ref = attributes.metaType, __indexOf.call(NOT_MEASUED_NOT_REPORTED, _ref) >= 0) {
      return '-';
    }
    if (attributes.metaType === 'NOT_POOLED') {
      return $.t('es:outcome.see_comment');
    }
    if (risk === 'calculated') {
      return formatters.outcome.riskDifference(attributes);
    } else {
      return formatters.outcome["riskDifference" + (_.string.capitalize(risk))](attributes);
    }
  },
  risksToBeDisplayed: function(outcome) {
    return _.chain(risks).map(function(r) {
      var acc;
      acc = {};
      acc["label"] = r === "calculated" ? "Study population" : outcome["" + r + "ControlLabel"];
      acc["displayControl"] = outcome["" + r + "Control"];
      acc["key"] = r;
      return acc;
    }).filter(function(r) {
      return r["displayControl"];
    }).value();
  }
};
