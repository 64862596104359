QuestionGroupFinished = require 'components/scope/outcomes/question_group_for_finished'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
VotingDXQuestionsMixin = require 'components/mixins/voting_dx_questions_mixin'
mediator = require 'mediator'
Router = require 'router'

FinishedTab = createReactClass
  displayName: 'FinishedTab'

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
    VotingDXQuestionsMixin
]

  _gotoComparisons: ->
    mediator.publish '!router:route',
      Router::routeForModule 'evidence-syntheses', projectId: mediator.project.id

  _getFinishedSectionText: ->
    questions = @getAllQuestions()
    dxQuestions = @getDXQuestions questions

    if dxQuestions.isEmpty()
      @i18n 'finished_section_text'
    else if dxQuestions.size is questions.size
      @i18n 'finished_section_text_dx_only'
    else
      @i18n 'finished_section_text_dx_included'

  renderQuestionGroup: (qGroup, idx) ->
    <QuestionGroupFinished data={qGroup} gIndex={idx} key={idx} readOnly />

  render: ->
    <div>
      <div className='information'>
        <div>{@_getFinishedSectionText()}</div>
      </div>
      <div className='questions-container'>
        {@props.questionGroups.map @renderQuestionGroup}
      </div>
      <div className='buttons'>
        <button className='btn goto-comparisons-btn' onClick={@_gotoComparisons}>
          {@i18n 'go_to_comparisons'}
        </button>
      </div>
    </div>

module.exports = FinishedTab
