var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "      <h3 class=\"hopscotch-title\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h3>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "      <div class=\"hopscotch-content\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.content : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "        <div class=\"col-3\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "walkthrough.step", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.i18n : depth0)) != null ? stack1.stepNum : stack1), depth0))
    + "</div>\n        <div class=\"col-9\">\n";
},"7":function(depth0,helpers,partials,data) {
  return "        <div class=\"col-12 text-center\">\n";
  },"9":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "          <button class=\"btn btn-block hopscotch-nav-button prev hopscotch-prev\">\n            "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.i18n : depth0)) != null ? stack1.prevBtn : stack1), depth0))
    + "\n          </button>\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "          <button class=\"btn btn-block hopscotch-nav-button next hopscotch-cta\">\n            "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.buttons : depth0)) != null ? stack1.ctaLabel : stack1), depth0))
    + "\n          </button>\n";
},"13":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "          <button class=\"btn btn-block btn-success hopscotch-nav-button next hopscotch-next\">\n            "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.i18n : depth0)) != null ? stack1.nextBtn : stack1), depth0))
    + "\n          </button>\n";
},"15":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <div class=\"col-12 text-right\" id=\"dont-show-again\">\n            <label for=\"dont-show-again-checkbox\">\n              <input type=\"checkbox\" id=\"dont-show-again-input\" />\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "translation:actions.dont_show_again", {"name":"i18n","hash":{},"data":data})))
    + "\n              </label>\n          </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"hopscotch-bubble-container\" style=\"width: "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.width : stack1), depth0))
    + "px; \">\n  <div class=\"hopscotch-bubble-content\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.title : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.content : stack1), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </div>\n  <div class=\"hopscotch-actions\">\n    <div class=\"row\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.i18n : depth0)) != null ? stack1.stepNums : stack1), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.buttons : depth0)) != null ? stack1.showPrev : stack1), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.buttons : depth0)) != null ? stack1.showCTA : stack1), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.buttons : depth0)) != null ? stack1.showNext : stack1), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.customData : stack1)) != null ? stack1.dontShowAgain : stack1), {"name":"if","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n  </div>\n</div>\n<div class=\"hopscotch-bubble-arrow-container hopscotch-arrow\">\n  <div class=\"hopscotch-bubble-arrow-border\"></div>\n  <div class=\"hopscotch-bubble-arrow\"></div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}