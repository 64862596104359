var AddQuestionMixin;

AddQuestionMixin = {
  addQuestion: function(type) {
    return (function(_this) {
      return function() {
        if (_this.props.addingQuestion) {
          _this.questionsList.saveCurrentlyAdding();
        }
        return _this.props.onQuestionAdd(type);
      };
    })(this);
  }
};

module.exports = AddQuestionMixin;
