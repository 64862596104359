var DbDoc, NMA_OUTCOME_PREFIX, nmaOutcomeDataToGdtData, nmaOutcomeDocShape;

DbDoc = require('lib/db_docs/db_doc');

nmaOutcomeDocShape = require('lib/db_docs/doc_shapes/nma_outcome_doc_shape');

NMA_OUTCOME_PREFIX = require('lib/doc_prefixes').nmaOutcome;

nmaOutcomeDataToGdtData = require('lib/nma_helper').nmaOutcomeDataToGdtData;

module.exports = DbDoc(nmaOutcomeDocShape, {
  dbSetup: {
    idPrefix: NMA_OUTCOME_PREFIX
  },
  methods: {
    updateDescription: function(description) {
      return this.setAt('description')(description);
    },
    updateOutcomeData: function(interventionId, comparatorId, outcomeData) {
      return this.updateAt('data')(function(currentData) {
        var comparisonIdx, gdtData;
        if (currentData == null) {
          currentData = [];
        }
        gdtData = nmaOutcomeDataToGdtData(outcomeData, interventionId, comparatorId);
        comparisonIdx = _.findIndex(currentData, function(dataEntry) {
          return dataEntry.interventionId === interventionId && dataEntry.comparatorId === comparatorId;
        });
        if (comparisonIdx < 0) {
          comparisonIdx = _.findIndex(currentData, function(dataEntry) {
            return dataEntry.interventionId === comparatorId && dataEntry.comparatorId === interventionId;
          });
        }
        if (comparisonIdx < 0) {
          return _.union(currentData, [gdtData]);
        } else {
          if (interventionId !== currentData[comparisonIdx].interventionId) {
            gdtData.interventionId = comparatorId;
            gdtData.comparatorId = interventionId;
          }
          currentData[comparisonIdx] = gdtData;
          return currentData;
        }
      });
    }
  }
});
