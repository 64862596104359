{ getKeyValObject } = require 'base/lib/utils'

VALID_POSITIONS = [ 'top', 'right', 'bottom', 'left' ]
VALID_TRIGGERS = [ 'click', 'hover' ]
VALID_ALIGNMENTS = [ 'start', 'center', 'end' ]
OPPOSITES =
  top: 'bottom'
  right: 'left'
  bottom: 'top'
  left: 'right'

styles =
  tooltipWrapper:
    position: 'fixed'
    boxSizing: 'border-box'
    transform: 'translateZ(0)'
    outline: 'none'
    zIndex: 99

_getAlignmentOffset = (params) ->
  { position, alignment, sourceWidth, sourceHeight, tooltipWidth, tooltipHeight } = params
  switch alignment
    when 'start' then 0
    when 'center'
      if position in [ 'top', 'bottom' ]
        (sourceWidth - tooltipWidth)/2
      else
        (sourceHeight - tooltipHeight)/2
    when 'end'
      if position in [ 'top', 'bottom' ]
        -(tooltipWidth - sourceWidth)
      else
        -(tooltipHeight - sourceHeight)

_getTooltipOffsets = (params) ->
  { position, positionOffset, tooltipWidth, tooltipHeight, sourceDims, alignment } = params
  { width: sourceWidth, height: sourceHeight } = sourceDims
  alignmentOffset = _getAlignmentOffset { position, alignment, tooltipWidth, tooltipHeight,
    sourceWidth, sourceHeight }

  switch position
    when 'top', 'bottom'
      { clientWidth } = document.documentElement
      leftOffset = sourceDims.left + alignmentOffset
      if leftOffset + tooltipWidth > clientWidth
        leftOffset = clientWidth - tooltipWidth

      top: if position is 'top'
        sourceDims.top - (tooltipHeight + positionOffset)
      else
        sourceDims.top + sourceHeight + positionOffset
      left: leftOffset
    when 'right', 'left'
      { clientHeight } = document.documentElement
      topOffset = sourceDims.top + alignmentOffset
      if topOffset + tooltipHeight > clientHeight
        topOffset = clientHeight - tooltipHeight

      top: topOffset
      left: if position is 'left'
        sourceDims.left - (tooltipWidth + positionOffset)
      else
        sourceDims.left + sourceWidth + positionOffset

# adjusts the position of tooltip if there is not enough space to fit it at desired side of target
# TODO: should also take alignment value into account
_ensureAvailableSpace = (params) ->
  { position, positionOffset, tooltipWidth, tooltipHeight, sourceDims, alignment } = params
  # get available space on all sides of the source element
  availableSpace =
    top: sourceDims['top']
    right: document.documentElement.clientWidth - sourceDims['right']
    bottom: document.documentElement.clientHeight - sourceDims['bottom']
    left: sourceDims['left']

  requiredPositionSpace =  if position in [ 'top', 'bottom' ]
    tooltipHeight + positionOffset
  else
    tooltipWidth + positionOffset

  if requiredPositionSpace > availableSpace[position] then OPPOSITES[position] else position

prepareTooltipContainer = (className = 'custom-tooltip') ->
  node = document.createElement 'div'
  node.style.position = 'relative'
  node.style.display = 'inline'
  node.classList.add className
  node

TooltipWrapper = createReactClass
  displayName: 'TooltipWrapper'

  ref: (el) ->
    @wrapper = el

  focus: ->
    # consider this to be already focused if any child is focused
    @wrapper.focus() unless @wrapper.contains document.activeElement

  onBlur: (evt) ->
    return unless @props.trigger is 'click' and @props.autohide
    # ignore focusing source element to let its onClick handler execute as expected
    { sourceElement } = @props
    return if sourceElement.parentNode.querySelector(':active') is sourceElement
    # ignore focusing children
    return if @wrapper.contains evt.relatedTarget
    @props.hideTooltip()

  onMouseLeave: ->
    return unless @props.trigger is 'hover'
    @props.hideTooltip()

  componentDidMount: ->
    { position, sourceElement, alignment, positionOffset } = @props
    sourceDims = sourceElement.getBoundingClientRect()
    wrapperDims = @wrapper.getBoundingClientRect()
    { width: tooltipWidth, height: tooltipHeight } = wrapperDims
    # adjust the position and/or alignment if there isn't enough space to fit tooltip and requested
    # place
    position = _ensureAvailableSpace { position, positionOffset, tooltipWidth, tooltipHeight,
      sourceDims, alignment }
    offsets = _getTooltipOffsets { position, positionOffset, tooltipWidth, tooltipHeight,
      sourceDims, alignment }

    @wrapper.style.left = "#{offsets.left}px"
    @wrapper.style.top = "#{offsets.top}px"
    @focus()

  render: ->
    { Tooltip } = @props

    <div
      ref={@ref}
      style={styles.tooltipWrapper}
      className='tooltip'
      onBlur={@onBlur}
      onMouseLeave={@onMouseLeave}
      tabIndex='-1'
    >
      <Tooltip {...@props.tooltipProps} hideTooltip={@props.hideTooltip} />
    </div>

AddCustomTooltip = (Child, Tooltip, options) ->
  { autohide, trigger, position, className, alignment, positionOffset } = options
  position = 'top' unless position in VALID_POSITIONS
  trigger = 'click' unless trigger in VALID_TRIGGERS
  alignment = 'center' unless alignment in VALID_ALIGNMENTS
  positionOffset ?= 10
  autohide ?= true

  createReactClass
    displayName: 'WithCustomTooltip'

    _mouseLeaveHandler: ->
      # during "transit" movement over source element the tooltipWrapper won't be created, so
      # no need to hide it
      return unless @tooltipWrapper
      # do not hide if cursor is over the tooltip (child of tooltipContainer node)
      return if @tooltipContainer.querySelector(':hover')?
      @toggleTooltip()

    _mouseOverHandler: ->
      # ignore "transit" hovers, i.e. when user hovers source element on her way to somewhere else
      return unless @sourceElement.parentNode.querySelector(':hover') is @sourceElement
      @toggleTooltip()

    _getTriggerProps: ->
      switch trigger
        when 'click' then getKeyValObject 'onClick', @toggleTooltip
        when 'hover' then _.extend {},
          getKeyValObject 'onMouseOver', @_mouseOverHandler
          getKeyValObject 'onMouseLeave', @_mouseLeaveHandler

    toggleTooltip: ->
      if @tooltipWrapper? then @unmountTooltipWrapper() else @renderTooltipWrapper()

    componentDidMount: ->
      # attach temp container to the parent of the source element. Tooltip will be rendered there
      @sourceElement = ReactDOM.findDOMNode(this)
      @sourceParent = @sourceElement.parentNode
      @tooltipContainer = prepareTooltipContainer(className)
      @sourceParent.insertBefore @tooltipContainer, @sourceElement

    componentWillUnmount: ->
      @unmountTooltipWrapper()
      @sourceParent.removeChild @tooltipContainer
      @tooltipContainer = null
      @sourceElement = null
      @sourceParent = null

    componentDidUpdate: (prevProps, prevState) ->
      # update TooltipWrapper if it was rendered
      @renderTooltipWrapper() if @tooltipWrapper?

    unmountTooltipWrapper: ->
      ReactDOM.unmountComponentAtNode @tooltipContainer

    renderTooltipWrapper: ->
      ReactDOM.render(
        <TooltipWrapper
          ref={(el) => @tooltipWrapper = el}
          autohide={autohide}
          trigger={trigger}
          position={position}
          alignment={alignment}
          positionOffset={positionOffset}
          sourceElement={@sourceElement}
          hideTooltip={@toggleTooltip}
          Tooltip={Tooltip}
          tooltipProps={@props.tooltipProps}
        />,
        @tooltipContainer
      )

    render: ->
      triggerProps = @_getTriggerProps()
      childProps = _.omit @props, 'tooltipProps'

      <Child {...childProps} {...triggerProps} />

module.exports = AddCustomTooltip
