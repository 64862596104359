var QualityIndicatorType, arrayOf, docShape, docTypes, initial, qualityIndicatorsDocShape, string;

docShape = require('lib/db_docs/field_types/doc_shape');

docTypes = require('lib/db_docs/doc_types');

initial = require('lib/db_docs/field_types/type_decorators').initial;

arrayOf = require('lib/db_docs/field_types/array_of');

string = require('lib/db_docs/field_types/built_in_types').string;

QualityIndicatorType = require('lib/db_docs/field_types/quality_indicator_type');

qualityIndicatorsDocShape = docShape({
  docType: initial(docTypes.QUALITY_INDICATOR_TYPE)(string),
  qualityIndicators: initial([])(arrayOf(QualityIndicatorType)),
  recommendationIds: initial([])(arrayOf(string)),
  currentView: initial('candidates')(string)
});

module.exports = qualityIndicatorsDocShape;
