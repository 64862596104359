{ useState } = React
ConnectStore = require 'components/enhancers/connect_store'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
MembersStore = require 'stores/members_store'
Modal = require 'components/common/modal'
MdaTopicsStore = require 'stores/mda_topics_store'
MdaTopicsActions = require 'actions/mda_topics_actions'
Scrollable = require 'components/common/scrollable'
Spinner = require 'components/common/spinner'
{ useCoffeeCallback, useI18n, useCoffeeMemo } = require 'lib/react_utils'
{ getMemberFullName, getAccessRightsLabel, getMemberAccessRights } = require 'lib/members_helper'

storeConnector =
  MembersStore: (Store) ->
    isFetchingMembers: Store.isFetching()
    members: Store.getMembers()
  MdaTopicsStore: (Store) ->
    modalData: Store.getAssignMemberDialogData()

MdaAssignMemberModal = ({ isOpen, isFetchingMembers, members, modalData }) ->

  i18n = useI18n('mda:topics')

  [selectedMember, setSelectedMember] = useState(null)
  [showAll, setShowAll] = useState(false)
  memberType = if modalData.get('memberType') in ['mab1', 'mab2', 'mab3']
    'mab'
  else modalData.get('memberType')

  toggleShowAll = useCoffeeCallback [], -> setShowAll (showAll) -> not showAll

  alreadyAssignedMembers = modalData.getIn(['topic', 'members'], Immutable.Map())

  filteredMembers = useCoffeeMemo [showAll, members, memberType], ->
    return members if showAll
    if memberType is 'mab'
      members.filter (member) ->
        member.get('accessRights').includes("mda_#{memberType}") and
        alreadyAssignedMembers.getIn(['mab1', 'email']) isnt member.get('email') and
        alreadyAssignedMembers.getIn(['mab2', 'email']) isnt member.get('email')
    else
      members.filter (member) ->
        member.get('accessRights').includes("mda_#{memberType}") or
        member.get('pendingInvitationAccessRights', Immutable.List()).includes("mda_#{memberType}")

  onCancel = useCoffeeCallback [], ->
    setSelectedMember(null)
    MdaTopicsActions.closeAssignMemberDialog()

  onApply = useCoffeeCallback [selectedMember], ->
    MdaTopicsActions.assignMemberToTopic { memberId: selectedMember, modalData }
    .finally -> setSelectedMember null

  onChange = (e) ->
    setSelectedMember e.target.value

  modalLabel = if memberType
    i18n "assign_member_as_#{memberType}"
  else
    i18n "assign_member"

  <Modal
    className="mda-assign-member-modal"
    modalSize="medium"
    title={modalLabel}
    isOpen={isOpen}
  >
    <div className="members mb-10">
      {if isFetchingMembers
        <Spinner />
      else <div>
        <div className="text-right">
          <span
            className="link-button"
            onClick={toggleShowAll}
          >
            {if showAll
              i18n 'show_only_members_with_role', { role: memberType?.toUpperCase() }
            else
              i18n '/actions.show_all_members'
            }
          </span>
        </div>
        <Scrollable>
          {if filteredMembers.isEmpty()
            <div>{i18n 'no_members_with_this_role', { role: memberType?.toUpperCase() }}</div>
          else
            filteredMembers.map (member) ->
              inputDisabled = not member.get('pendingInvitation') and
                getMemberAccessRights(member) is 'no_access'

              <label key={member.get('_id')} className="flex flex-row items-center mb-5">
                <div className="checkbox">
                  <input
                    type="radio"
                    value={member.get("_id")}
                    onChange={onChange}
                    checked={member.get('_id') is selectedMember}
                    disabled={inputDisabled}
                    name="member"
                  />
                </div>
                <div className="member-row flex flex-grow flex-row">
                  <div className="flex-grow">{getMemberFullName(member)}</div>
                  <div>{getAccessRightsLabel(member, true)}</div>
                </div>
              </label>
            .toList()
          }
          </Scrollable>
        </div>
      }
    </div>
    <ApplyCancelButtons
      onCancel={onCancel}
      onApply={onApply}
      isSubmitEnabled={not _.isEmpty(selectedMember) || isFetchingMembers}
    />
  </Modal>

module.exports = module.exports = ConnectStore MdaAssignMemberModal, [
  MembersStore
  MdaTopicsStore
], storeConnector
