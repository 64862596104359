var ProjectsActions, actionsHelper, alt, appUtils, checkIfProjectCanBeMovedToOrganization, mediator;

alt = require('alt');

mediator = require('mediator');

actionsHelper = require('actions/async/projects');

appUtils = require('lib/app_utils');

checkIfProjectCanBeMovedToOrganization = require('actions/async/organizations_api').checkIfProjectCanBeMovedToOrganization;

ProjectsActions = (function() {
  function ProjectsActions() {
    this.generateActions('udbFetchSuccess', 'fetchSuccess', 'fetchError', 'fetchProjectError', 'fetchProjectSuccess', 'fetchReplicatedProjectSuccess', 'fetchProjectQuestionsSuccess', 'fetchProjectMembersSuccess', 'setFetchingWorkspace', 'sortProjects', 'changeTab', 'search', 'resetSearch', 'copyProjectSuccess', 'toggleMoveToOrganizationModal', 'moveProjectToOrganizationSuccess', 'moveProjectToOrganizationError', 'restoreCopySuccess', 'resetHighlightedProjectId', 'updateProjectReplicationProgress', 'updateProjectRemoteInfo', 'getPossibleActionsForProjectSuccess', 'getPossibleActionsForProjectError', 'resetPossibleActionsForProject', 'successfulNotificationShown');
  }

  ProjectsActions.prototype.dbChange = function(data) {
    var currentProjects, doc, newProjects, storeState, udbData;
    doc = data.doc;
    switch (doc.docType) {
      case 'request':
        if (doc.type === 'move_project_to_organization' && doc.status !== 'new') {
          this.actions.getChangelog();
          return void 0;
        } else {
          if (doc.status !== 'new') {
            return this.dispatch(doc);
          }
        }
        break;
      case 'model':
        return this.dispatch(doc);
      case 'user':
        udbData = actionsHelper.processUDBMetaDoc(doc);
        storeState = alt.stores.ProjectsStore.getState();
        currentProjects = storeState.getIn(['userData', 'projects'], Immutable.Map());
        newProjects = _.reduce(udbData.projects, function(acc, project) {
          return acc.set(project.name, Immutable.fromJS(project));
        }, Immutable.Map());
        this.dispatch(udbData);
        if (!currentProjects.equals(newProjects)) {
          return this.actions.getChangelog();
        }
        break;
      default:
        if (doc._id === 'projects-invitations') {
          return this.dispatch(actionsHelper.processInvitationsDoc(doc));
        }
    }
  };

  ProjectsActions.prototype.getChangelog = function() {
    var activeWorkspace, organizationId;
    activeWorkspace = mediator.activeWorkspace;
    organizationId = activeWorkspace === 'personal' ? null : activeWorkspace;
    this.actions.setFetchingWorkspace(organizationId);
    return mediator.services.projectChangelog.getChangelog({
      organizationId: organizationId
    }).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  ProjectsActions.prototype.fetch = function(params) {
    if (params == null) {
      params = {};
    }
    mediator.subscribe('replication.bgChanges.projectChange', this.actions.updateProjectReplicationProgress);
    this.dispatch(params);
    return actionsHelper.fetchUdbData().then((function(_this) {
      return function(udbData) {
        _this.actions.udbFetchSuccess(udbData);
        return mediator.services.projectChangelog.getChangelog({
          organizationId: params.organizationId
        });
      };
    })(this)).then(this.actions.fetchSuccess).then(this.actions.startListeningChanges)["catch"](this.actions.fetchError);
  };

  ProjectsActions.prototype.fetchProjectQuestions = function(projectId) {
    return actionsHelper.fetchProjectQuestions(projectId).then(this.actions.fetchProjectQuestionsSuccess)["catch"](this.actions.fetchError);
  };

  ProjectsActions.prototype.fetchProjectMembers = function(projectId) {
    return actionsHelper.fetchProjectMembers(projectId).then(this.actions.fetchProjectMembersSuccess)["catch"](this.actions.fetchError);
  };

  ProjectsActions.prototype.handleProjectReplication = function(info) {
    var projectId;
    projectId = info.project;
    if (!alt.stores.ProjectsStore.isProjectActive(projectId)) {
      return;
    }
    return actionsHelper.fetchProject(projectId).then(this.actions.fetchReplicatedProjectSuccess)["catch"](this.actions.fetchProjectError);
  };

  ProjectsActions.prototype.startListeningChanges = function() {
    actionsHelper.listenUDB(this.actions.dbChange.bind(this));
    return mediator.subscribe('replication.bgChanges.project', this.actions.handleProjectReplication);
  };

  ProjectsActions.prototype.stopListeningChanges = function() {
    actionsHelper.unlistenUDB();
    mediator.unsubscribe('replication.bgChanges.project', this.actions.handleProjectReplication);
    return mediator.unsubscribe('replication.bgChanges.projectChange', this.actions.updateProjectReplicationProgress);
  };

  ProjectsActions.prototype.changeProjectLabel = actionsHelper.updateProjectLabel;

  ProjectsActions.prototype.updateUserLabels = actionsHelper.updateUserLabels;

  ProjectsActions.prototype.createAdolopment = function(projectId, organizationId, selectedQuestions) {
    return actionsHelper.createAdolopment(projectId, organizationId, selectedQuestions).then(actionsHelper.fetchProject).then(actionsHelper.assignAccessRights).then((function(_this) {
      return function(project) {
        return _this.actions.fetchProjectSuccess({
          project: project,
          organizationId: organizationId
        });
      };
    })(this))["catch"](this.actions.fetchProjectError);
  };

  ProjectsActions.prototype.copyProject = function(projectId, organizationId) {
    return actionsHelper.copyProject(projectId, organizationId).then((function(_this) {
      return function(copyId) {
        _this.actions.copyProjectSuccess(copyId);
        return copyId;
      };
    })(this)).then(actionsHelper.fetchProject).then(actionsHelper.assignAccessRights).then((function(_this) {
      return function(project) {
        return _this.actions.fetchProjectSuccess({
          project: project,
          organizationId: organizationId
        });
      };
    })(this)).then(function() {
      return mediator.dialogs.success($.t('projects:copy_success'));
    })["catch"](this.actions.fetchProjectError);
  };

  ProjectsActions.prototype.restoreCopy = function(projectId, organizationId) {
    return actionsHelper.restoreCopy(projectId, organizationId).then((function(_this) {
      return function(newProjectId) {
        _this.actions.restoreCopySuccess(newProjectId);
        return newProjectId;
      };
    })(this)).then(actionsHelper.fetchProject).then(actionsHelper.assignAccessRights).then((function(_this) {
      return function(project) {
        return _this.actions.fetchProjectSuccess({
          project: project,
          organizationId: organizationId
        });
      };
    })(this)).then(function() {
      return mediator.dialogs.success($.t('projects:restore_success'));
    })["catch"](this.actions.fetchProjectError);
  };

  ProjectsActions.prototype.shareProject = actionsHelper.shareProject;

  ProjectsActions.prototype.sendProjectCopy = actionsHelper.sendProjectCopy;

  ProjectsActions.prototype.archiveProject = actionsHelper.archiveProject;

  ProjectsActions.prototype.unarchiveProject = actionsHelper.unarchiveProject;

  ProjectsActions.prototype.deleteProject = actionsHelper.deleteProject;

  ProjectsActions.prototype.acceptProjectInvitation = actionsHelper.acceptInvitation;

  ProjectsActions.prototype.declineProjectInvitation = actionsHelper.declineInvitation;

  ProjectsActions.prototype.removeProjectInvitation = actionsHelper.abandonInvitation;

  ProjectsActions.prototype.updateProjectData = function(projectId, updatedData, organizationId) {
    this.dispatch({
      projectId: projectId,
      updatedData: updatedData,
      organizationId: organizationId
    });
    return actionsHelper.updateProjectData(projectId, updatedData);
  };

  ProjectsActions.prototype.getPossibleActionsForProject = function(projectId) {
    this.dispatch(projectId);
    return mediator.services.projectPermissionsService.getPossibleActionsForProject(projectId).then((function(_this) {
      return function(permittedActionsList) {
        return _this.actions.getPossibleActionsForProjectSuccess({
          projectId: projectId,
          permittedActionsList: permittedActionsList
        });
      };
    })(this))["catch"](this.actions.getPossibleActionsForProjectError);
  };

  ProjectsActions.prototype.moveProjectToOrganization = function(projectId, organizationId) {
    return checkIfProjectCanBeMovedToOrganization(projectId, organizationId).then((function(_this) {
      return function(response) {
        if (response.result === true) {
          return actionsHelper.moveProjectToOrganization(projectId, organizationId).then(function(response) {
            return _this.actions.moveProjectToOrganizationSuccess({
              projectId: projectId,
              organizationId: organizationId
            });
          });
        } else {
          return _this.actions.moveProjectToOrganizationError(response);
        }
      };
    })(this))["catch"](appUtils.errorHandler);
  };

  ProjectsActions.prototype.startOnboardingTour = function() {
    return mediator.services.projectsScreenOnboarding.startTour();
  };

  return ProjectsActions;

})();

module.exports = alt.createActions(ProjectsActions);
