Button = require 'components/common/button'
SpinnerButton = require 'components/common/spinner_button'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
{ bool, func, string } = PropTypes

Confirmation = createReactClass
  displayName: 'ConfirmationDialog'

  propTypes:
    cancelClass: string
    cancelLabel: string
    confirmClass: string
    confirmLabel: string
    loading: bool
    message: string
    onConfirm: func.isRequired
    onCancel: func.isRequired
    question: string

  getDefaultProps: ->
    cancelClass: 'btn-cancel'
    cancelLabel: $.t 'actions.cancel'
    confirmClass: 'btn-confirm'
    confirmLabel: $.t 'actions.yes'
    loading: false
    question: $.t 'messages.confirm'

  render: ->
    {
      question
      message
      onCancel
      onConfirm
      confirmLabel
      cancelLabel
      className
      cancelClass
      confirmClass
      loading
    } = @props

    <div className={classNames "confirmation", className}>
      {not _.isEmpty(question) and
        <div className="question mb-10" dangerouslySetInnerHTML={__html: question} />
      }
      {not _.isEmpty(message) and
        <div className="message mb-10" dangerouslySetInnerHTML={__html: message} />
      }
      <ApplyCancelButtons
        applyLabel={confirmLabel}
        applyClass={confirmClass}
        cancelLabel={cancelLabel}
        cancelClass={cancelClass}
        onApply={onConfirm}
        onCancel={onCancel}
        applying={loading}
      />
    </div>

module.exports = Confirmation
