Tooltip = require 'components/common/tooltip'
IndividualRatingsModal = require 'components/scope/individual_ratings_modal'
QuestionWithRatings = require 'components/scope/questions/question_ratings'
QuestionWithRatingsWithComments = require 'components/scope/questions/question_ratings_wth_comments'
QuestionsShowCommentsMixin = require 'components/mixins/questions_show_comments_mixin'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
IndividualRatingsToggle = require 'components/mixins/individual_ratings_toggle'

{ Button } = ReactComponents

ProposalTab = createReactClass
  displayName: 'ProposalTab'

  propTypes:
    questions: PropTypes.instanceOf(Immutable.List).isRequired
    membersMap: PropTypes.object.isRequired
    readOnly: PropTypes.bool.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:questions.proposal')
    QuestionsShowCommentsMixin
    IndividualRatingsToggle
  ]

  getInitialState: ->
    showIndividualRatings: false

  _getSortedQuestions: ->
    @props.questions.sortBy (q) -> - q.get 'medianRating'

  renderQuestionRow: (qData, idx) ->
    props =
      data: qData
      onChangeApprovalStatus: @props.onChangeApprovalStatus
      readOnly: @props.readOnly

    if qData.get('memberComments', Immutable.Map()).isEmpty()
      <QuestionWithRatings key={idx} {...props} />
    else
      <QuestionWithRatingsWithComments {...props}
        key={idx}
        membersMap={@props.membersMap}
        showingComments={@state.showingAllComments}
        onCommentToggle={@onItemCommentToggle}
        onApplyAdminComment={@props.onApplyAdminComment}
      />

  renderTableHeader: ->
    <thead>
      <tr className="caption">
        <th>{@i18n 'question'}</th>
        <th className='with-info'>
          <Tooltip>
            <span className='info-sign' title={@i18n 'accepted_help'} />
          </Tooltip>
          {@i18n 'accepted'}
        </th>
        <th className='with-info'>
          <Tooltip>
            <span className='info-sign' title={@i18n 'mentioned_help'} />
          </Tooltip>
          {@i18n 'mentioned'}
        </th>
        <th className='with-info'>
          <Tooltip>
            <span className='info-sign' title={@i18n 'rejected_help'} />
          </Tooltip>
          {@i18n 'rejected'}
        </th>
        <th>{@i18n 'rating'}</th>
        <th>{@i18n 'comments'}</th>
      </tr>
    </thead>

  render: ->
    <div className='questions-proposal'>
      <div className='information'>
        <div>{@i18n 'header'}</div>
      </div>
      <div className='buttons'>
        <Button
          className='show-individual-ratings'
          onClick={@toggleIndividualRatings}
        >
          {@i18n '../../show_individual_ratings'}
        </Button>
        <Button
          className='show-all-comments'
          onClick={@showComments}
          disabled={@_isShowCommentsDisabled()}>
          {@i18n '../show_all_comments'}
        </Button>
      </div>
      <div className='questions-rating-container'>
        <div className="questions">
          <table className='standard-table questions-rating'>
            {@renderTableHeader()}
            {@_getSortedQuestions().map @renderQuestionRow}
          </table>
        </div>
      </div>
      {unless @props.readOnly
        <div className='bottom-bar row mt-20'>
          <div className='col-12'>
            <button
              className='btn btn-block btn-send'
              onClick={@props.onCloseStep}
              disabled={@props.isClosingStep}
            >
              {@i18n 'next_step'}
            </button>
          </div>
        </div>
      }
      <IndividualRatingsModal
        isOpen={@state.showIndividualRatings}
        onClose={@toggleIndividualRatings}
        questions={@props.questions}
        membersMap={@props.membersMap}
      />
    </div>

module.exports = ProposalTab
