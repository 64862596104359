var OrderedCollection, Outcome, Outcomes,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OrderedCollection = require('models/base/ordered_collection');

Outcome = require('./outcome');

module.exports = Outcomes = (function(_super) {
  __extends(Outcomes, _super);

  function Outcomes() {
    return Outcomes.__super__.constructor.apply(this, arguments);
  }

  Outcomes.prototype.model = Outcome;

  Outcomes.prototype.checkOrderOnReset = false;

  Outcomes.prototype.initialize = function() {
    Outcomes.__super__.initialize.apply(this, arguments);
    _.extend(this, new Backbone.Memento(this));
    return this.on('nested', this._checkOrder);
  };

  return Outcomes;

})(OrderedCollection);
