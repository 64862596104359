{ COCHRANE_ORGANIZATION_ID } = require 'lib/archie_helper'
{ CADTH_EXTRACTORS_ORG_ID } = require 'lib/key_messages_helper'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
mediator = require 'mediator'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'

ComparisonQuestionsListButtonsRow = createReactClass
  displayName: "ComparisonQuestionsListButtonsRow"

  mixins: [
    CustomRenderMixin
    Translation('es:question')
  ]

  propTypes:
    handleButtonAction: PropTypes.func.isRequired

  getButtons: ->
    { handleButtonAction } = @props
    activeWorkspace = mediator.activeWorkspace
    gpsFeatureOn =
      mediator.services.switches.isServerSwitchOn('covidFeatures') or
      mediator.services.switches.isServerSwitchOn('ecFeatures')
    cadthFeaturesOn = mediator.services.switches.isServerSwitchOn('cadthFeatures')
    overarchingQuestionsFeatureOn = mediator.services.switches.isOn('overarching-questions')
    isCADTHExtractorWorkspace = activeWorkspace is CADTH_EXTRACTORS_ORG_ID

    BUTTONS =
      'add-management-question':
        label: "add_title"
        visible: not isCADTHExtractorWorkspace
      'add-diagnostic-question':
        label: "add_diagnostic_title"
        visible: not isCADTHExtractorWorkspace
      'add-good-practice-question':
        label: "add_good_practice_title"
        visible: gpsFeatureOn and not isCADTHExtractorWorkspace
      'add-key-message':
        label: "add_key_message_title"
        visible: cadthFeaturesOn
      'add-overarching-question':
        label: "/actions.create_overarching_question"
        visible: overarchingQuestionsFeatureOn and not isCADTHExtractorWorkspace
      'import-questions':
        label: "/import.questions"
        visible: not isCADTHExtractorWorkspace
        tooltip: '/import.questions_title'
        disabled: activeWorkspace is COCHRANE_ORGANIZATION_ID

    _.reduce BUTTONS, (acc, buttonProps, buttonKey) =>
      if buttonProps.visible
        acc.push <Tooltip key={buttonKey}>
          <button
            className="btn btn-block"
            title={if buttonProps.tooltip then @i18n(buttonProps.tooltip) else null}
            onClick={handleButtonAction(buttonKey)}
            disabled={buttonProps.disabled or false}
          >
            {@i18n buttonProps.label}
          </button>
        </Tooltip>
      acc
    , []

  render: ->
    <div className='create-questions-container'>
      {@getButtons()}
    </div>

module.exports = ComparisonQuestionsListButtonsRow
