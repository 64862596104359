ConnectStore = require 'components/enhancers/connect_store'
IconButton = require 'components/common/icon_button'
PanelVoiceEtdActions = require 'actions/panel_voice_etd_actions'
PanelVoiceEtdStore = require 'stores/panel_voice_etd_store'
PanelVoiceResponsesDetails = require 'components/etd/panel_voice_etd/panel_voice_responses_details'
Select = require 'components/common/select_custom'
VotingControls = require 'components/etd/panel_voice_etd/voting_controls'
VotedEtdData = require 'components/etd/panel_voice_etd/voted_etd_data'
Tooltip = require 'components/common/tooltip'
{ getTagsByKeys } = require 'lib/etd_helper'
{ convertListToOrderedMap } = require 'lib/immutable_utils'
{ useI18n, useCoffeeMemo, useCoffeeCallback } = require 'lib/react_utils'

storeConnector =
  PanelVoiceEtdStore: (Store, props) ->
    sort: Store.getSortBySection(props.sectionId)
    showComments: Store.getShowComments(props.sectionId)
    selectedMember: Store.getActiveMemberBySection(props.sectionId)

OverarchingOpenPanelVoiceResults = ({
  activeOption
  adminVotingCommentUpdater
  criterion
  etdPart
  etdTab
  isProposedVoting
  getAdminVotingSummary
  memberNamesMap
  membersVotedCount
  membersVotingCount
  overarchingQuestionData
  renderMode
  resultsViewType
  sectionId
  sectionVotingResults
  selectedMember
  showComments
  sort
  votedEtdData
  votingStatus
  votingTimestamp
}) ->

  i18n = useI18n('es:recommendations.table')

  membersOptions = [{text: i18n('/docsec:comments_filters.all'), value: '_all'}].concat(
    memberNamesMap.reduce((acc, memberName, memberId) ->
      acc.push { text: memberName, value: memberId }
      acc
    , [])
  )

  resultsFilteredByMember = sectionVotingResults.filter (sectionResult) ->
    return true if not selectedMember or selectedMember is '_all'
    sectionResult.get('memberId') is selectedMember

  groupResultsByTagId = useCoffeeMemo [resultsFilteredByMember], ->
    resultsFilteredByMember.reduce (acc, memberResult) ->
      memberResult.get('interventionJudgments', Immutable.Map()).forEach (value, tagKey) ->
        if acc.hasIn([tagKey, value])
          acc = acc.updateIn([tagKey, value], (current) ->
            current.withMutations (c) ->
              newCount = c.get('count', 0) + 1
              c.set('count', newCount)
              c.set('members', c.get('members', Immutable.List()).concat(memberResult.get('memberId')))
          )
        else
          acc = acc.setIn([tagKey, value], Immutable.fromJS({
            count: 1
            members: [memberResult.get('memberId')]
          }))
      acc
    , Immutable.Map()

  sortedTagsByVotesCount = useCoffeeMemo [groupResultsByTagId], -> 
    groupResultsByTagId.sortBy (tag) -> tag.getIn([sort.get('key'), 'count'], 0)

  sortedTagIdsByVotesCount = if sort.get('direction') is 'asc'
    sortedTagsByVotesCount.keySeq()
  else
    sortedTagsByVotesCount.keySeq().reverse()

  tags = convertListToOrderedMap(getTagsByKeys(
    overarchingQuestionData.get('includedTags'), ['intervention', 'indexTest']
  ), 'id')

  votingResultsByOption = useCoffeeMemo [resultsFilteredByMember, activeOption], ->
    resultsFilteredByMember.reduce (acc, memberResult) ->
      return acc unless activeOption
      return acc unless memberResult.get('interventionJudgments', Immutable.Map()).some((judgement) ->
        judgement is activeOption
      )
      acc.update activeOption, Immutable.List(), (activeOption) -> activeOption.push(memberResult)

    , Immutable.fromJS({ _all: sectionVotingResults })

  updateActiveOption = useCoffeeCallback [activeOption], (newVal) ->
    # click on the same option again deselects the option
    newActiveOption = if activeOption is newVal then null else newVal

    PanelVoiceEtdActions.setSectionActiveJudgmentOption
      option: newActiveOption
      sectionId: sectionId

  onVoteDataClick = useCoffeeCallback [], ->
    updateActiveOption null

  toggleComments = useCoffeeCallback [sectionId, showComments], ->
    PanelVoiceEtdActions.setShowComments { sectionId, value: not showComments }

  onChangeCurrentMember = useCoffeeCallback [sectionId], (memberId) ->
    PanelVoiceEtdActions.setActiveMemberBySection { sectionId, memberId }

  isTestRound = votingStatus is 'test'
  votesCountLabel = if isTestRound then 'test_comments_stats' else 'votes_stats'

  <div className='voting-details w-full'>
    <div className='judgement-details w-full'>
      <VotedEtdData
        onClick={onVoteDataClick}
        votesCountLabel={votesCountLabel}
        votedCount={membersVotedCount}
        votingCount={membersVotingCount}
      />
      <div className="judgement-details__container">
        <div className="flex flex-row mt-10">
          <div className="flex flex-row flex-grow items-center">
            <Select
              icon="user"
              options={membersOptions}
              selected={selectedMember or '_all'}
              onChange={onChangeCurrentMember}
            />
            <VotingControls
              etdPart={etdPart}
              isProposedVoting={isProposedVoting}
              renderMode={renderMode}
              resultsViewType={resultsViewType}
              sectionId={sectionId}
              votingStatus={votingStatus}
            />
          </div>
          <div>
            <IconButton
              className="font-size-large"
              iconName="comment"
              label={i18n 'comments'}
              title={i18n 'comments'}
              labelPosition="right"
              onClick={toggleComments}
            />
          </div>
        </div>
        <div className="judgement-details__votes
          #{if showComments then 'comments-open' else 'comments-closed'}"
        >
          <table className="overarching-results">
            <thead>
              <tr>
                <td className="tag" />
                {criterion.get('options', Immutable.List()).map (option) ->
                  <OptionCell
                    activeOption={activeOption}
                    updateActiveOption={updateActiveOption}
                    isAdditional={false}
                    sectionId={sectionId}
                    sort={sort}
                    option={option}
                  />
                 }
                {criterion.get('additionalOptions', Immutable.List()).map (option) ->
                  <OptionCell
                    activeOption={activeOption}
                    updateActiveOption={updateActiveOption}
                    isAdditional
                    sectionId={sectionId}
                    sort={sort}
                    option={option}
                  />
                }
              </tr>
            </thead>
            <tbody>
              {sortedTagIdsByVotesCount.map (tagId) ->
                tag = tags.get(tagId)

                resultsByTag = groupResultsByTagId.get(tag.get('id'), Immutable.Map())
                <tr key={tag.get('id')}>
                  <td className="tag">
                   <Tooltip>
                      <div className="tag-name" title={tag.get('name')}>
                        {tag.get('name')}
                      </div>
                    </Tooltip>
                  </td>
                  {criterion.get('options', Immutable.List()).map (option) ->
                    <ResultCell
                      activeOption={activeOption}
                      additional={false}
                      results={resultsByTag}
                      memberNamesMap={memberNamesMap}
                      resultsViewType={resultsViewType}
                      membersVotedCount={membersVotedCount}
                      option={option}
                    />
                  }
                  {criterion.get('additionalOptions', Immutable.List()).map (option) ->
                    <ResultCell
                      activeOption={activeOption}
                      additional
                      results={resultsByTag}
                      memberNamesMap={memberNamesMap}
                      resultsViewType={resultsViewType}
                      membersVotedCount={membersVotedCount}
                      option={option}
                    />
                  }
                </tr>
              }
            </tbody>
          </table>
        </div>
        <div className="overarching__pv-comments #{if showComments then 'open' else 'closed'}">
          <IconButton className="font-size-large" iconName="cross" onClick={toggleComments} />
          <PanelVoiceResponsesDetails
            activeOption={activeOption}
            adminVotingComment={getAdminVotingSummary votingTimestamp, sectionId}
            etdPart='assessment'
            handleAdminVotingSummaryUpdate={adminVotingCommentUpdater}
            memberNamesMap={memberNamesMap}
            readOnly={etdTab is 'history'}
            renderMode={renderMode}
            sectionId={sectionId}
            votedEtdData={votedEtdData}
            votingResultsByOption={votingResultsByOption}
            selectedMember={selectedMember}
          />
        </div>
      </div>
    </div>
  </div>


OptionCell = ({ activeOption, updateActiveOption, isAdditional, sectionId, sort, option }) ->
  classes = classNames 'option',
    option.get('value'),
    'additional': isAdditional,
    active: activeOption is option.get('value')

  sortDirection = sort.get('direction')
  sortKey = sort.get('key')

  iconClasses = (direction) ->
    classNames 'active': sortDirection is direction and sortKey is option.get('value')

  onSort = (direction) -> (e) ->
    e.stopPropagation()
    PanelVoiceEtdActions.setSortBySection({
      sectionId, direction, key: option.get('value')
    })

  <td
    onClick={-> updateActiveOption(option.get('value'))}
    key={option.get('value')}
    className={classes}
  >
    <div className="flex flex-row items-center">
      <div className="flex-grow">{option.get('text')}</div>
      <div className="ml-5 flex flex-col column-sorters">
        <IconButton
          className={iconClasses('asc')}
          iconName="collapse"
          onClick={onSort('asc')}
        />
        <IconButton
          className={iconClasses('desc')}
          iconName="expand"
          onClick={onSort('desc')}
        />
      </div>
    </div>
  </td>

ResultCell = ({
  activeOption
  isAdditional
  results
  memberNamesMap
  resultsViewType
  membersVotedCount
  option
}) ->
  count = results.getIn([option.get('value'), 'count'], 0)
  votesRatio = membersVotedCount and count/membersVotedCount

  membersTooltip = results.getIn([option.get('value'), 'members'], Immutable.List()).map(
    (memberId) -> memberNamesMap.get(memberId))
  .toJS()
  .join(', ')

  classes = classNames 'result',
    option.get('value')
    'additional': isAdditional,
    active: activeOption is option.get('value')

  value = if resultsViewType is 'numeric'
    count
  else
    "#{Math.round 100 * votesRatio}%"

  <td key={option.get('value')} className={classes}>
    <Tooltip>
      <div className="votes_count" title={membersTooltip}>
        {if count is 0 then <div>&nbsp;</div> else value}
      </div>
    </Tooltip>
  </td>

module.exports = ConnectStore OverarchingOpenPanelVoiceResults, PanelVoiceEtdStore, storeConnector
