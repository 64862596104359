ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
ConnectStore = require 'components/enhancers/connect_store'
InsertRecommendation = require 'components/document_sections/v2/insert_recommendation'
Modal = require 'components/common/modal'
OverarchingQuestionsStore = require 'stores/overarching_questions_store'
PopulationItem = require 'components/dissemination/acp_visual_guidelines/population_item'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
{ DndProvider } = ReactDnD
{ useState } = React
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'

RecommendationsPopulationsList = ({
  chapterId
  overarchingQuestions
  questionGroups
  questions
  section
}) ->

  i18n = useI18n()

  populationsOrder = section.getIn(['additionalData', 'populationsOrder'], Immutable.List())
  populations = section.getIn(['additionalData', 'populations'], Immutable.Map())

  [modalOptions, setModalOptions] = useState({})

  openAddRecommendationModal = useCoffeeCallback [], ({section, populationId}) ->
    setModalOptions({section, populationId})

  closeInsertModal = useCoffeeCallback [], -> setModalOptions({})

  handleInsertRecommendation = useCoffeeCallback [modalOptions],
    (selectedQuestionsAndRecommendations) ->
      { section, populationId } = modalOptions
      ACPVisualGuidelinesActions.insertRecommendations({
        section, populationId, selectedQuestionsAndRecommendations
      })
      .then closeInsertModal

  renderItems = (populationIds, populations, ancestors = [], data = {index: 0}) ->
    <ol className="population-list-items">
      {
        populationIds.map (populationId) ->
          currentIndex = data.index
          data.index++
          population = populations.get populationId
          return null unless population
          subPopulationsIds = population.get 'subgroups'
          parent = population.get 'parentGroup'
          currentAncestors = ancestors.concat(parent || [])

          <li key={populationId} className="population-list-item">
            <PopulationItem
              ancestors={ancestors}
              index={currentIndex}
              section={section}
              chapterId={chapterId}
              population={population}
              populationId={populationId}
              populations={populations}
              openAddRecommendationModal={openAddRecommendationModal}
            />
            {if !subPopulationsIds.isEmpty()
              renderItems subPopulationsIds, populations, currentAncestors, data
            }
          </li>
      }
    </ol>

  <div>
    <div
      className="population-list flex flex-col overflow-auto"
    >
      {renderItems populationsOrder, populations}
    </div>
    <Modal
      isOpen={not _.isEmpty(modalOptions)}
      modalSize="medium"
      title={i18n 'vi:insert_recommendation'}
    >
      <InsertRecommendation
        alreadyUsedRecommendationsQuestionsIds={Immutable.Set()}
        onCancel={closeInsertModal}
        onInsert={handleInsertRecommendation}
        questionGroups={questionGroups}
        questions={questions.merge(overarchingQuestions)}
        withRecommendationText
        displayAppendices={false}
      />
    </Modal>
  </div>

storeConnector =
  QuestionGroupsStore: (Store) ->
    isFetchingQuestionGroups: Store.isFetching()
    questionGroups: Store.getQuestionGroups()
  QuestionsStore: (Store) ->
    isFetchingQuestions: Store.isFetching()
    questions: Store.getQuestions()
  OverarchingQuestionsStore: (Store) ->
    overarchingQuestions: Store.getOverarchingQuestions()

module.exports = ConnectStore RecommendationsPopulationsList, [
  OverarchingQuestionsStore
  QuestionGroupsStore
  QuestionsStore
], storeConnector
