CustomRenderMixin = require 'components/mixins/custom_render_mixin'

Header = createReactClass

  displayName: "EditableTableHeader"

  propTypes:
    children: PropTypes.oneOfType [
      PropTypes.element
      PropTypes.arrayOf PropTypes.element
    ]

  mixins: [CustomRenderMixin]

  render: ->
    <thead>
      {@props.children}
    </thead>

module.exports = Header
