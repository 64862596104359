Tooltip = require 'components/common/tooltip'
EmptyCommentIcon = require 'components/common/empty_comment_button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

getNameInitials = (memberName) ->
  memberName.split ' '
    .map (nameStr) -> _.first nameStr
    .join ''
    .toUpperCase()

getRatingClass = (memberRating) ->
  if _.isNumber memberRating
    "rating-#{memberRating}"
  else
    ''

getStatsClass = (value, isMean = false) ->
  valueClass = if value > 6
    'high'
  else if value > 3
    'moderate'
  else
    'low'

  classNames 'stats', valueClass, 'mean': isMean

IndividualRatingsTable = createReactClass
  displayName: 'IndividualRatingsTable'

  propTypes:
    membersMap: PropTypes.object.isRequired

  mixins: [
    Translation('scope:outcomes')
    CustomRenderMixin
  ]

  _getVotedMembersIds: ->
    return @_votedMembersIds if @_votedMembersIds?
    items = @_getRatingItems()

    @_votedMembersIds = items.reduce (result, item) ->
      result.merge item.get 'ratings'
    , Immutable.Map()
    .keySeq()

    @_votedMembersIds

  _getMembersNamesCells: ->
    @_getVotedMembersIds().map (memberId, idx) =>
      memberName = @props.membersMap.get memberId
      memberInitials = getNameInitials memberName
      <th className='member-name' key={idx}>
        <Tooltip>
          <span title={memberName}>
            {memberInitials}
          </span>
        </Tooltip>
      </th>

  _getMembersRatingCells: (itemRatings, itemComments) ->
    @_getVotedMembersIds().map (memberId, idx) =>
      memberRating = @_getMemberItemRating itemRatings, memberId
      ratingClass = classNames 'member-rating', getRatingClass memberRating
      memberComment = itemComments.get memberId

      <td key={idx} className={ratingClass}>
        {memberRating}
        {if memberComment
          <EmptyCommentIcon title={memberComment} />
        }
      </td>

  _getMemberItemRating: (itemRatings, memberId) ->
    rating = itemRatings.get memberId

    if rating and rating isnt -1
      rating
    else
      @i18n 'n_a'

  _getItemRaingStats: (itemRatings) ->
    validRatings = itemRatings.filter (rating) -> rating > 0

    if validRatings.isEmpty()
      noDataString = @i18n 'n_a'
      return ['mean', 'min', 'max'].reduce (result, statName) ->
        result[statName] = noDataString
        result
      , {}

    ratingsSum = validRatings.reduce (result, rating) ->
      result + rating
    , 0
    # return rounded to 2 dec places
    mean = (ratingsSum / validRatings.size).toFixed(2)
    validRatingsValues = validRatings.valueSeq().toArray()
    min = Math.min.apply null, validRatingsValues
    max = Math.max.apply null, validRatingsValues

    { mean, min, max }

  componentWillUnmount: ->
    @_votedMembersIds = null

  _getRatingsType: ->
    return 'outcomes' if @props.outcomes?
    return 'questions' if @props.questions?

  _getRatingItems: ->
    return @props.questions if @props.questions?
    return @props.outcomes if @props.outcomes?

  renderTableHeader: ->
    tableType = @_getRatingsType()

    <thead>
      <tr>
        <th className='labels'>
          <span className={tableType}>{@i18n tableType}</span>
          <span className='members'>{@i18n 'panel_members'}</span>
          <div className='diag-line' />
        </th>
        {@_getMembersNamesCells()}
        <th className='stats mean'>{@i18n 'mean'}</th>
        <th className='stats'>{@i18n 'median'}</th>
        <th className='stats'>{@i18n 'min'}</th>
        <th className='stats'>{@i18n 'max'}</th>
      </tr>
    </thead>

  renderTableRows: ->
    tableType = @_getRatingsType()
    items = @_getRatingItems()

    items.map (item, idx) =>
      itemRatings = item.get 'ratings'
      itemText = if tableType is 'outcomes' then item.get 'name' else item.get 'question'
      commentsPath = if tableType is 'outcomes'
        ['memberComments', 'rating']
      else
        ['memberComments', 'questions.rating']
      itemComments = item.getIn commentsPath, Immutable.Map()
      { mean, min, max} = @_getItemRaingStats itemRatings
      median = item.get('medianRating').toFixed(2)

      <tr className='ratings-data' key={idx}>
        <td className='item-text'>{itemText}</td>
        {@_getMembersRatingCells itemRatings, itemComments}
        <td className={getStatsClass(mean, true)}>{mean}</td>
        <td className={getStatsClass(median)}>{median}</td>
        <td className={getStatsClass(min)}>{min}</td>
        <td className={getStatsClass(max)}>{max}</td>
      </tr>

  render: ->
    tableType = @_getRatingsType()
    return null unless tableType

    tableClass = classNames 'individual-ratings', tableType

    <div>
      <table className={tableClass}>
        {@renderTableHeader()}
        <tbody>
          {@renderTableRows()}
        </tbody>
      </table>
    </div>

module.exports = IndividualRatingsTable
