{ bool } = PropTypes
ConnectStore = require 'components/enhancers/connect_store'
ImportActions = require 'actions/import_actions'
ImportStore = require 'stores/import_store'
Translation = require 'components/mixins/translation'

storeConnectors =
  ImportStore: (Store) ->
    checked: Store.getIncludeReferences()

IncludeReferencesCheckbox = createReactClass
  displayName: 'IncludeReferencesCheckbox'
  mixins: [Translation('projects:import_dialog')]

  propTypes:
    checked: bool.isRequired

  onChange: ->
    ImportActions.toggleIncludeReferences()

  render: ->
    <div className="import-include-references">
      <input
        type="checkbox"
        name="include-references"
        checked={@props.checked}
        onChange={@onChange}
      />
      <button onClick={@onChange}>
        {@i18n 'include_study_references'}
      </button>
    </div>

module.exports = ConnectStore IncludeReferencesCheckbox, [ImportStore], storeConnectors
