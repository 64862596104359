var DiagnosticQuestionExporter, DiagnosticRecommendationsExporter, JSONLDExportDiagnosticQuestionsService, ReferencesExporter, W, lift, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

DiagnosticQuestionExporter = require('./diagnostic_question_exporter');

DiagnosticRecommendationsExporter = require('./diagnostic_recommendations_exporter');

ReferencesExporter = require('./references_exporter');

utils = require('./exporter_utils');

lift = (require('when/function')).lift;

W = require('when');

mediator = require('mediator');

module.exports = JSONLDExportDiagnosticQuestionsService = (function() {
  function JSONLDExportDiagnosticQuestionsService() {
    this._export = __bind(this._export, this);
    this._exportRecommendations = __bind(this._exportRecommendations, this);
    this._exportReferences = __bind(this._exportReferences, this);
  }

  JSONLDExportDiagnosticQuestionsService.prototype._exportReferences = function() {
    var referencesExporter;
    referencesExporter = new ReferencesExporter(mediator.project, this.pouchAdapter);
    return referencesExporter.fetchReferences().then(function() {
      return referencesExporter;
    });
  };

  JSONLDExportDiagnosticQuestionsService.prototype._exportQuestion = function(originalQuestion, referencesExporter) {
    var authorFootnotes, exportedEvidenceSummary, exportedExplanations, exportedOutcomes, exportedPrevalence, exportedQuestion, exportedTests, questionExplanations, questionExporter, questionReferences;
    questionExporter = new DiagnosticQuestionExporter(originalQuestion, referencesExporter);
    exportedOutcomes = questionExporter.exportOutcomes();
    exportedExplanations = questionExporter.exportExplanations();
    exportedTests = questionExporter.exportTests();
    exportedPrevalence = questionExporter.exportPrevalence();
    exportedEvidenceSummary = questionExporter.exportEvidenceSummary();
    exportedQuestion = {
      '@id': originalQuestion['_id'],
      '@context': utils.diagnosticQuestionContext(),
      '@type': 'DiagnosticEvidenceProfile',
      'version': (originalQuestion['_rev'].split('-'))[0],
      'modificationTime': utils.timestampToISOWithTimeZone(originalQuestion['$timestamp']),
      'bibliography': questionExporter.getValueAndFootnotes(originalQuestion, 'bibliography'),
      'title': utils.getQuestionTitle(originalQuestion, 'question'),
      'sofTitle': utils.getQuestionTitle(originalQuestion, 'questionShort'),
      'question': {
        '@id': "questions/" + originalQuestion['_id'],
        '@type': 'PCTO',
        'targetCondition': {
          '@type': 'MedicalCondition',
          'name': questionExporter.getValueAndFootnotes(originalQuestion, 'targetCondition')
        },
        'healthProblemOrPopulation': {
          '@type': 'HealthProblemOrPopulation',
          'name': questionExporter.getValueAndFootnotes(originalQuestion, 'healthProblemOrPopulation'),
          'setting': questionExporter.getValueAndFootnotes(originalQuestion, 'settings')
        },
        'diagnosticTest': exportedTests,
        'outcome': exportedOutcomes
      },
      'explanation': exportedExplanations,
      'prevalence': exportedPrevalence,
      'evidenceSummary': exportedEvidenceSummary
    };
    if (originalQuestion['authors']) {
      questionExplanations = originalQuestion['footnotes'];
      questionReferences = originalQuestion['references'];
      authorFootnotes = questionExporter.findFootnotes(questionExplanations, questionReferences, 'authors', 0);
      exportedQuestion['author'] = {
        '@type': 'Person',
        'name': originalQuestion['authors']
      };
      if (authorFootnotes.length > 0) {
        exportedQuestion['author']['footnote'] = authorFootnotes;
      }
    }
    exportedQuestion['reference'] = referencesExporter.getUsedReferencesAndClearIds();
    return W(utils.removeFalseValuesFromObject(exportedQuestion));
  };

  JSONLDExportDiagnosticQuestionsService.prototype._exportRecommendations = function(originalQuestion, etdVersion, referencesExporter) {
    var exportingConclusions, exportingHeader, exportingSections, exportingType, recommendationsExporter;
    recommendationsExporter = new DiagnosticRecommendationsExporter(originalQuestion, etdVersion, this.pouchAdapter, referencesExporter);
    exportingSections = W(recommendationsExporter.exportSections());
    exportingConclusions = W(recommendationsExporter.exportConclusions());
    exportingHeader = W(recommendationsExporter.exportHeader());
    exportingType = W(recommendationsExporter.getJsonLdType());
    return W.all([exportingSections, exportingConclusions, exportingHeader, exportingType], function(_arg) {
      var conclusions, exportedRecommendations, header, jsonldType, sections;
      sections = _arg[0], conclusions = _arg[1], header = _arg[2], jsonldType = _arg[3];
      exportedRecommendations = _.extend(conclusions, {
        '@id': originalQuestion['_id'],
        '@context': utils.recommendationsContext(),
        '@type': ['DiagnosticRecommendation'],
        'version': (originalQuestion['_rev'].split('-'))[0],
        'modificationTime': utils.timestampToISOWithTimeZone(originalQuestion['$timestamp']),
        'title': originalQuestion['question'],
        'guidelineSubject': {
          '@id': "questions/" + originalQuestion['_id']
        },
        'section': sections,
        'header': header
      });
      if (jsonldType) {
        exportedRecommendations['@type'].push(jsonldType);
      }
      utils.removeFalseValuesFromObject(exportedRecommendations);
      if (originalQuestion['authors']) {
        exportedRecommendations['author'] = {
          '@type': 'Person',
          'name': originalQuestion['authors']
        };
      }
      exportedRecommendations['reference'] = referencesExporter.getUsedReferencesAndClearIds();
      return exportedRecommendations;
    });
  };

  JSONLDExportDiagnosticQuestionsService.prototype._export = function(originalQuestion, etdVersion) {
    if (etdVersion == null) {
      etdVersion = 'ietd';
    }
    return this._exportReferences().then((function(_this) {
      return function(referencesExporter) {
        return _this._exportQuestion(originalQuestion, referencesExporter).then(function(question) {
          var noEtdCheck1, noEtdCheck2, recommendationIds;
          if (etdVersion === 'ietd') {
            recommendationIds = originalQuestion['recommendationIds'];
            if ((recommendationIds != null ? recommendationIds.length : void 0) === 0 || !recommendationIds) {
              return W([question]);
            }
          } else {
            noEtdCheck1 = !originalQuestion['recommendationType'];
            noEtdCheck2 = !originalQuestion['consequencesBalance'];
            if (noEtdCheck1 && noEtdCheck2) {
              return W([question]);
            }
          }
          return _this._exportRecommendations(originalQuestion, etdVersion, referencesExporter).then(function(recommendations) {
            return [question, recommendations];
          });
        });
      };
    })(this));
  };

  JSONLDExportDiagnosticQuestionsService.prototype["export"] = function(originalQuestion, etdVersion) {
    if (etdVersion == null) {
      etdVersion = 'ietd';
    }
    return (lift(this._export, originalQuestion, etdVersion))();
  };

  return JSONLDExportDiagnosticQuestionsService;

})();
