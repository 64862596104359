mediator = require 'mediator'

module.exports =
  componentDidMount: ->
    @dialogs = {}

  _removeDialog: (containerId) ->
    container = @dialogs[containerId]
    # if there is API for deleting the modal container - use it
    if container.destroy
      @_removeModal containerId
    else
      ReactDOM.unmountComponentAtNode container
      container.remove()
      @dialogs[containerId] = null

  _removeModal: (modalId) ->
    modalDialog = @dialogs[modalId]
    ReactDOM.unmountComponentAtNode modalDialog.el.querySelector('.notifier-message')
    modalDialog.destroy()
    @dialogs[modalId] = null

  componentWillUnmount: ->
    for containerId of @dialogs
      @_removeDialog(containerId) if @dialogs[containerId]

  _createModalDialog: (title = '', options) ->
    mediator.dialogs.modal title, null, options

  _notifierInner: (DialogComponent, props, options, currentIndex) ->
    <div>
      {if props.title
        <div className="notifier-title">{props.title}</div>
      }
      {if options.closeBtn
        <div
          className="notifier-close"
          onClick={@_removeModal.bind this, currentIndex} />
      else
        null
      }
      <div className="notifier-message">
        <DialogComponent
          removeDialog={@_removeModal.bind this, currentIndex}
          {...props} />
      </div>
    </div>

  displayModal: (DialogComponent, props = {}, dialogOptions = {}) ->
    modalDialog = @_createModalDialog props?.title, dialogOptions
    currentIndex = _.keys(@dialogs).length
    @dialogs[currentIndex] = modalDialog
    ReactDOM.render(
      @_notifierInner(DialogComponent, props, dialogOptions, currentIndex),
      modalDialog.el.querySelector('.notifier-inner')
      )
