var EtdsActions, MembersActions, PouchSource, VotingAPI, VotingActions, alt, mediator, _fetchQuestionGroups;

alt = require('alt');

mediator = require('mediator');

MembersActions = require('actions/members_actions');

EtdsActions = require('actions/etds_actions');

VotingAPI = require('actions/async/voting_api');

PouchSource = require('stores/sources/pouch_source');

_fetchQuestionGroups = function() {
  return mediator.project.getDocFor('comparisons_question_groups');
};

VotingActions = (function() {
  function VotingActions() {
    this.generateActions('pouchFetchSuccess', 'pouchFetchError', 'pouchSaveSuccess', 'pouchSaveError', 'fetchQuestionGroupsSuccess', 'fetchQuestionGroupsError', 'startVotingSuccess', 'startVotingError', 'updateVotingSuccess', 'updateVotingError', 'sendReminderSuccess', 'sendReminderError', 'closeVotingSuccess', 'closeVotingError', 'sendMailMessageSuccess', 'sendMailMessageFailure', 'refreshVotingStatusesSuccess', 'refreshVotingStatusesFailure', 'fetchVotingResultsSuccess', 'fetchVotingResultsFailure', 'updateSendVoteDetails', 'updateReminderDetails', 'changeTab', 'saveVotingPreferences', 'resetVotingPreference', 'updateVotingOption');
  }

  VotingActions.prototype.fetch = function() {
    var docIds, pouchAdapter, projectId;
    projectId = mediator.project.id;
    this.dispatch(projectId);
    docIds = ["" + projectId + ":etd-voting", 'etd-voting-preferences'];
    pouchAdapter = mediator.services.storePersistenceAdapter;
    pouchAdapter.fetch(projectId, docIds).then(this.actions.pouchFetchSuccess)["catch"](this.actions.pouchFetchError);
    _fetchQuestionGroups().then(this.actions.fetchQuestionGroupsSuccess)["catch"](this.actions.fetchQuestionGroupsError);
    MembersActions.fetch();
    return EtdsActions.fetch();
  };

  VotingActions.prototype.startVoting = function(params) {
    var blocks, contactEmail, dueDateTimestamp, emailBody, memberIds, projectId, questionIds, votingDetails;
    projectId = mediator.project.id;
    memberIds = params.memberIds, blocks = params.blocks, questionIds = params.questionIds, votingDetails = params.votingDetails;
    emailBody = votingDetails.emailBody, contactEmail = votingDetails.contactEmail, dueDateTimestamp = votingDetails.dueDateTimestamp;
    VotingAPI.startVoting({
      projectId: projectId,
      memberIds: memberIds,
      questionIds: questionIds,
      blocks: blocks,
      emailBody: emailBody,
      dueDateTimestamp: dueDateTimestamp,
      contactEmail: contactEmail
    }).then(this.actions.startVotingSuccess)["catch"](this.actions.startVotingError);
    return this.actions.updateSendVoteDetails(votingDetails);
  };

  VotingActions.prototype.updateVoting = function(params) {
    var blocks, contactEmail, dueDateTimestamp, emailBody, phaseName, projectId, questionIds, votingDetails;
    projectId = mediator.project.id;
    questionIds = params.questionIds, blocks = params.blocks, votingDetails = params.votingDetails, phaseName = params.phaseName;
    emailBody = votingDetails.emailBody, contactEmail = votingDetails.contactEmail, dueDateTimestamp = votingDetails.dueDateTimestamp;
    VotingAPI.updateVoting({
      projectId: projectId,
      questionIds: questionIds,
      blocks: blocks,
      emailBody: emailBody,
      dueDateTimestamp: dueDateTimestamp,
      contactEmail: contactEmail,
      phaseName: phaseName
    }).then(this.actions.updateVotingSuccess)["catch"](this.actions.updateVotingError);
    return this.actions.updateSendVoteDetails(votingDetails);
  };

  VotingActions.prototype.sendReminder = function(params) {
    var data, emailBody, projectId, questionIds;
    projectId = mediator.project.id;
    questionIds = params.questionIds, data = params.data;
    emailBody = data.emailBody;
    VotingAPI.sendReminder({
      projectId: projectId,
      questionIds: questionIds,
      emailBody: emailBody
    }).then(this.actions.sendReminderSuccess)["catch"](this.actions.sendReminderError);
    return this.actions.updateReminderDetails(data);
  };

  VotingActions.prototype.closeVoting = function(params) {
    var projectId, questionIds;
    projectId = mediator.project.id;
    questionIds = params.questionIds;
    VotingAPI.closeVoting({
      projectId: projectId,
      questionIds: questionIds
    }).then(this.actions.closeVotingSuccess)["catch"](this.actions.closeVotingError);
    return this.dispatch();
  };

  return VotingActions;

})();

module.exports = alt.createActions(VotingActions);
