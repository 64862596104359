Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
MdaWorkflowPublicationActions = require 'actions/mda_workflow_publication_actions'
MdaWorkflowPublicationStore = require 'stores/mda_workflow_publication_store'
Modal = require 'components/common/modal'
ProgressBar = require 'components/common/progress_bar'
{ useI18n } = require 'lib/react_utils'

storeConnector =
  MdaWorkflowPublicationStore: (Store) ->
    currentTopicName: Store.getPublicationTopicName()
    isOpen: Store.isPublicationDialogOpen()
    isPublishSuccess: Store.isPublishSuccess()
    overallProgress: Store.getOverallProgressBarPercentage()
    topicProgress: Store.getTopicProgressBarPercentage()

MDAPublicationModal = ({
  currentTopicName
  isOpen
  isPublishSuccess
  overallProgress
  topicProgress
}) ->
  i18n = useI18n('dbep:mda_publication_screen.modal')

  <Modal
    className="mdg-publication-modal"
    isOpen={isOpen}
    modalSize="medium"
    title={i18n 'title'}
  >
    <div className="mdg-publication-modal__info">
      {if isPublishSuccess then i18n('success') else i18n('info')}
    </div>
    <ProgressBar className="mdg-publication-modal__progress" percentage={overallProgress} />
    {if isPublishSuccess
      <div className="mdg-publication-modal__button">
        <Button
          className="btn btn-apply"
          label={i18n '/actions.ok'}
          onClick={MdaWorkflowPublicationActions.closePublicationModal}
        />
      </div>
    else
      <React.Fragment>
        <div className="mdg-publication-modal__info">
          {i18n 'publishing_topic', { currentTopicName }}
        </div>
        <ProgressBar className="mdg-publication-modal__progress" percentage={topicProgress}/>
      </React.Fragment>
    }
  </Modal>

MDAPublicationModal.propTypes =
  currentTopicName: PropTypes.string.isRequired
  isOpen: PropTypes.bool.isRequired
  isPublishSuccess: PropTypes.bool.isRequired
  overallProgress: PropTypes.number.isRequired
  topicProgress: PropTypes.number.isRequired

module.exports = ConnectStore MDAPublicationModal, MdaWorkflowPublicationStore, storeConnector
