var ETD_PARTS, EtdVotingActions, EtdVotingStore, alt, errorHandler, etdHelper, immutable, isVotingEnabled, mediator, setDefaultVotingTabs, _parseVotingResults, _processMemberVotingData, _processPhaseData;

alt = require('alt');

mediator = require('mediator');

immutable = require('stores/utils/immutable');

errorHandler = require('lib/app_utils').errorHandler;

EtdVotingActions = require('actions/etd_voting_actions');

etdHelper = require('lib/etd_helper');

ETD_PARTS = etdHelper.getEtdParts();

_processMemberVotingData = function(memberVoteData, memberId) {
  return memberVoteData.get('sections').mapEntries(function(_arg) {
    var sectionName, voteData;
    sectionName = _arg[0], voteData = _arg[1];
    voteData = voteData.set('memberId', memberId);
    return [sectionName, Immutable.List([voteData])];
  });
};

_processPhaseData = function(phaseName, phaseData) {
  return phaseData.get('blocks').map(function(sectionData, sectionName) {
    var sectionVotes;
    sectionVotes = sectionData.get('votingData').reduce(function(acc, memberVoteData, memberId) {
      memberVoteData = _processMemberVotingData(memberVoteData, memberId);
      return acc.mergeWith(function(accumulatedVotes, memberVote) {
        return accumulatedVotes.concat(memberVote);
      }, memberVoteData);
    }, Immutable.Map());
    return Immutable.Map({
      noOfSentForms: phaseData.get('statuses').size,
      sentPhase: phaseName,
      sections: sectionData.get('sectionsInfo').mergeWith(function(sectionData, sectionVotes) {
        return sectionData.set('memberVotes', sectionVotes);
      }, sectionVotes)
    });
  });
};

_parseVotingResults = function(votingResults) {
  var rawResults, results;
  if (!votingResults) {
    return null;
  }
  rawResults = Immutable.fromJS(votingResults);
  results = _processPhaseData('phase1', rawResults.get('phase1'));
  if (rawResults.get('currentPhase') !== 'phase1') {
    return results.concat(_processPhaseData('phase2', rawResults.get('phase2')));
  } else {
    return results;
  }
};

setDefaultVotingTabs = function(state) {
  var votingData;
  votingData = state.get('votingResults');
  if (votingData.isEmpty()) {
    return Immutable.Map();
  }
  return state.setIn(['_meta', 'activeVotingTabs'], Immutable.Map(ETD_PARTS.map(function(etdPart) {
    return [etdPart, votingData.has(etdPart) ? 'votingResults' : null];
  })));
};

isVotingEnabled = function() {
  return mediator.services.switches.isOn('voting');
};

EtdVotingStore = (function() {
  function EtdVotingStore() {
    this.state = Immutable.fromJS({
      _meta: {
        activeVotingTabs: {}
      },
      etdId: '',
      votingResults: {}
    });
    this.exportPublicMethods({
      getVotingData: this.getVotingData,
      isFetching: this.isFetching,
      getActiveVotingTabs: this.getActiveVotingTabs
    });
    this.bindActions(EtdVotingActions);
  }

  EtdVotingStore.prototype.onFetch = function(params) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], true);
      return state.set('etdId', params.etdId);
    }));
  };

  EtdVotingStore.prototype.onFetchSuccess = function(data) {
    var etdId;
    etdId = this.state.get('etdId');
    return this.setState(this.state.withMutations(function(state) {
      state.mergeIn(['votingResults'], _parseVotingResults(data.votingResults[etdId]));
      state.setIn(['_meta', 'fetching'], false);
      return setDefaultVotingTabs(state);
    }));
  };

  EtdVotingStore.prototype.onFetchError = function(err) {
    if (err.status !== 404) {
      errorHandler(err);
    }
    return this.setState(this.state.setIn(['_meta', 'fetching'], false));
  };

  EtdVotingStore.prototype.onSetActiveVotingTab = function(data) {
    return this.setState(this.state.mergeIn(['_meta', 'activeVotingTabs'], data));
  };

  EtdVotingStore.prototype.getActiveVotingTabs = function() {
    return this.state.getIn(['_meta', 'activeVotingTabs']);
  };

  EtdVotingStore.prototype.getVotingData = function() {
    if (!isVotingEnabled()) {
      return Immutable.Map();
    }
    return this.state.get('votingResults');
  };

  EtdVotingStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  return EtdVotingStore;

})();

module.exports = alt.createStore(immutable(EtdVotingStore), 'EtdVotingStore');
