var ComparisonsQuestionGroupsDoc, mediator;

mediator = require('mediator');

ComparisonsQuestionGroupsDoc = require('lib/db_docs/comparisons_question_groups_doc');

module.exports = {
  fetchOrCreateComparisonQuestionGroups: function() {
    return ComparisonsQuestionGroupsDoc.at(mediator.project.id).fetch()["catch"](function(err) {
      if (err.status === 404) {
        return ComparisonsQuestionGroupsDoc.at(mediator.project.id).create();
      } else {
        throw err;
      }
    });
  }
};
