var Sandbox,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

module.exports = Sandbox = (function() {
  function Sandbox(content) {
    this._sendResponse = __bind(this._sendResponse, this);
    var sandboxFrame;
    sandboxFrame = $('<iframe width="0" height="0" frameborder="0"></iframe>');
    sandboxFrame.prop('src', content);
    $(body).append(sandboxFrame);
    this.sandbox = sandboxFrame[0].contentWindow;
    this.sandbox.postMessage({
      action: 'initialize'
    }, '*');
    if (typeof chrome !== "undefined" && chrome !== null ? chrome.runtime : void 0) {
      chrome.runtime.onSuspend.addListener((function(_this) {
        return function() {
          return _this.sandbox.postMessage({
            action: 'suspend'
          }, '*');
        };
      })(this));
    }
    this._setupMessageHandler();
  }

  Sandbox.prototype._sendResponse = function(response) {
    return sandbox.postMessage({
      guid: event.data.guid,
      response: response
    }, '*');
  };

  Sandbox.prototype._setupMessageHandler = function() {
    return window.addEventListener('message', function(event) {
      if (event.data.guid == null) {

      }
    });
  };

  return Sandbox;

})();
