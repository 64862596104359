AppearanceConfiguratorOption = require 'components/decision_aid/appearance_configurator_option'
{ DA_ILLUSTRATIONS, DA_THEMES } = require 'lib/da_helper'


AppearanceConfigurator = createReactClass
  displayName: 'AppearanceConfigurator'

  propTypes:
    onChange: PropTypes.func.isRequired
    selected: PropTypes.oneOf(R.concat DA_ILLUSTRATIONS, DA_THEMES)
    type: PropTypes.oneOf(['illustrations', 'themes'])

  getOptions: ->
    { type } = @props
    optionValues = if type is 'illustrations' then DA_ILLUSTRATIONS else DA_THEMES
    optionValues.map (value) -> { type, value }

  handleChange: (evt) ->
    @props.onChange evt.target.value

  render: ->
    { type, selected, onChange } = @props

    <div className='decision-aids-appearance-select'>
      {@getOptions().map ({ label, value, imagePath }) =>
        <AppearanceConfiguratorOption
          isSelected={value is selected}
          key={value}
          onChange={@handleChange}
          type={type}
          value={value}
        />
      }
    </div>

module.exports = AppearanceConfigurator
