var DEFAULT_PROJECT_LABELS, SORTABLE_FIELDS, applySearchFilter, comparatorFn, filterProjectsBySearchString, formatDate, getDateModifiedText, getPublicationDateText, getRouteToProjectsList, isProjectACopy, mediator, sortProjectsByField, storeConnectors;

mediator = require('mediator');

formatDate = require('lib/date_utils').format;

SORTABLE_FIELDS = ['name', 'customer', 'dateModified', 'startDate'];

DEFAULT_PROJECT_LABELS = Immutable.fromJS({
  1: {
    text: 'Name',
    color: '#29aae3'
  },
  2: {
    text: 'Name',
    color: '#ffb03b'
  },
  3: {
    text: 'Name',
    color: '#74ceb7'
  }
});

storeConnectors = {
  ProjectsStore: function(ProjectsStore, props) {
    var organizationId;
    organizationId = props.organizationId;
    return {
      isFetching: ProjectsStore.isFetching(),
      activeTab: ProjectsStore.getActiveTab(),
      hasEverFetched: ProjectsStore.hasEverFetched(organizationId),
      archivedProjects: ProjectsStore.getProjectsByType('archived', organizationId),
      activeProjects: ProjectsStore.getProjectsByType('active', organizationId),
      copiesOfProjects: ProjectsStore.getProjectsByType('copies', organizationId),
      projectsLabels: ProjectsStore.getProjectsLabels(),
      projectsNotes: ProjectsStore.getProjectsNotes(),
      sharingInvitations: ProjectsStore.getSharingInvitations(),
      labelsData: ProjectsStore.getLabelsData(),
      projectsQuestions: ProjectsStore.getProjectsQuestions(),
      projectsMembers: ProjectsStore.getProjectsMembers(),
      sortedBy: ProjectsStore.getSortBy(),
      activeSearch: ProjectsStore.getActiveSearch(),
      highlightedProjectId: ProjectsStore.getHighlightedProjectId(),
      projectsStatuses: ProjectsStore.getProjectsStatuses(),
      projectsReplicationProgress: ProjectsStore.getProjectsReplicationProgress(),
      projectsRemoteInfo: ProjectsStore.getProjectsRemoteInfo(),
      permittedProjectActions: ProjectsStore.getPermittedProjectActions(),
      successfulInvitationsToNotify: ProjectsStore.getSuccessfulInvitationsToNotify(),
      movingProjectToOrganizationStatus: ProjectsStore.getMovingProjectToOrganizationStatus()
    };
  }
};

comparatorFn = function(valA, valB) {
  if (typeof valA !== typeof valB) {
    return 0;
  }
  if (!((_.isString(valA) || _.isNumber(valA)) && (_.isString(valB) || _.isNumber(valB)))) {
    return 0;
  }
  if (_.isString(valA)) {
    return valA.localeCompare(valB);
  } else {
    return valA - valB;
  }
};

sortProjectsByField = function(projects, fieldName, ascending) {
  return projects.sortBy(function(p) {
    var sortField;
    sortField = fieldName === 'dateModified' ? 'timestamp' : fieldName;
    return p.get(sortField, fieldName === 'startDate' ? 0 : '--');
  }, function(valA, valB) {
    return comparatorFn(valA, valB) * (ascending ? 1 : -1);
  });
};

filterProjectsBySearchString = function(searchString, projects, projectFields) {
  if (projectFields == null) {
    projectFields = ['name', 'customer', 'notes'];
  }
  return projects.filter(function(p) {
    return projectFields.map(function(fieldName) {
      return p.get(fieldName, '');
    }).join('').toLowerCase().match(searchString);
  });
};

applySearchFilter = function(projects, searchText) {
  if (_.isEmpty(searchText)) {
    return projects;
  }
  return filterProjectsBySearchString(searchText, projects);
};

getRouteToProjectsList = function(organizationId) {
  if (organizationId) {
    return "organizations/" + organizationId + "/projects";
  } else {
    return 'projects';
  }
};

isProjectACopy = function(projectId) {
  var project, projects, _ref;
  if (!projectId) {
    return false;
  }
  projects = mediator.user.get('projects');
  project = _.find(projects, function(p) {
    return p.name === projectId;
  });
  return (_ref = project != null ? project.isCopy : void 0) != null ? _ref : false;
};

getPublicationDateText = function(project) {
  var dateString, published, rev_author;
  published = project.published, rev_author = project.rev_author;
  if (published == null) {
    return '--';
  }
  dateString = _.isObject(published) ? formatDate(published.publicationDate) : formatDate(published);
  if (mediator.user.get('name' === rev_author || 'name' === (published != null ? published.publishedBy : void 0))) {
    return "" + dateString + " " + ($.t('projects:by', {
      person: $.t('me')
    }));
  } else {
    return dateString;
  }
};

getDateModifiedText = function(project) {
  var dateString, rev_author, timestamp;
  timestamp = project.timestamp, rev_author = project.rev_author;
  if (timestamp == null) {
    return '--';
  }
  dateString = formatDate(timestamp);
  if (rev_author === mediator.user.get('name')) {
    return "" + dateString + " " + ($.t('projects:by', {
      person: $.t('me')
    }));
  } else {
    return dateString;
  }
};

module.exports = {
  SORTABLE_FIELDS: SORTABLE_FIELDS,
  DEFAULT_PROJECT_LABELS: DEFAULT_PROJECT_LABELS,
  storeConnectors: storeConnectors,
  sortProjectsByField: sortProjectsByField,
  applySearchFilter: applySearchFilter,
  getRouteToProjectsList: getRouteToProjectsList,
  isProjectACopy: isProjectACopy,
  getPublicationDateText: getPublicationDateText,
  getDateModifiedText: getDateModifiedText
};
