EtdActions = require 'actions/etd_actions'
EtdStore = require 'stores/etd_store'
Select = require 'components/common/select_custom'
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ Button, SelectWithCheckboxes } = ReactComponents
IconButton = require 'components/common/icon_button'
Translation = require 'components/mixins/translation'
GdtEditorWithApply = Editor.EditorWithApply

CERTAINTY_PATH = ['templateData', 'assessment', 'criteria', 'certaintyOfEvidence']

SingleRecommendation = createReactClass

  mixins: [CustomRenderMixin, Translation('es:recommendations')]

  editorRef: (el) ->
    @gdtEditor = el?.gdtEditor

  onApply: ->
    @saveContent()

  onBlur: ->
    return if @props.noAutoSave
    @saveContent()

  onChangeRecommendation: (value) ->
    EtdActions.updateSelectedRecommendationValue {index: @props.index, value}

  onChangeCertainty: (value) ->
    EtdActions.updateSelectedCertaintyValue { index: @props.index, value }

  onRemove: ->
    EtdActions.removeRecommendation @props.index

  onCancel: ->
    @gdtEditor.resetContent @props.content

  saveContent: ->
    content = @gdtEditor.getEditorContent()
    options = isConsensus: @props.isConsensus
    EtdActions.updateEditorContent {
      cellId: "multipleRecommendations_#{@props.index}#conclusionsMultiRecommendations",
      content, options
    }

  render: ->

    {
      content
      options
      renderMode
      selectedOption
      selectedCertainty
      showDeleteButton
      stickControlsTo
      certaintyOptions
    } = @props

    <div className="multiple-recommendations__single">
      <div className="single__header">
        <div className="header__select-options">
          <div className="mb-5">
            <div className="bold">{@i18n 'strength_of_recommendation'}</div>
            <Select
              options={options}
              selected={selectedOption}
              onChange={@onChangeRecommendation}
            />
          </div>
          <div>
            <div className="bold">{@i18n 'certainty'}</div>
            <SelectWithCheckboxes
              className="w-full"
              withSelectAll={false}
              options={certaintyOptions.toJS()}
              selectedOptions={selectedCertainty.toJS() or []}
              onChange={@onChangeCertainty}
            />
          </div>
        </div>
        {renderMode isnt 'printout' and showDeleteButton and <IconButton
            iconName="decline-icon"
            onClick={@onRemove}
          />
        }
      </div>
      <GdtEditorWithApply
        editorContent={content}
        onApply={@onApply}
        onBlur={@onBlur}
        onCancel={@onCancel}
        onChange={@onChange}
        ref={@editorRef}
        stickControlsTo={stickControlsTo}
      />
    </div>

MultipleRecommendations = createReactClass
  displayName: "MultipleRecommendations"
  propTypes:
    editable: PropTypes.bool
    renderMode: PropTypes.string
    template: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [ CustomRenderMixin, Translation() ]

  getDefaultProps: ->
    editable: true
    stickControlsTo: '#recommendations'

  addRecommendation: ->
    EtdActions.addRecommendation()

  render: ->
    { renderMode, editable, section, sectionId, readOnly, template } = @props
    options = section.get('options').toJS()
    showDeleteButton = section.get('recommendations').size > 1

    certaintyOptions = template.getIn([CERTAINTY_PATH..., 'options'], Immutable.List())
      .concat(template.getIn([CERTAINTY_PATH..., 'additionalOptions'], Immutable.List()))

    <div>
      {section.get('recommendations').map (recommendation, idx) =>
        <SingleRecommendation
          key={_.uniqueId()}
          index={idx}
          options={options}
          content={recommendation.get('content')}
          renderMode={renderMode}
          selectedCertainty={recommendation.get('selectedCertainty', Immutable.List())}
          selectedOption={recommendation.get('selectedOption')}
          showDeleteButton={showDeleteButton}
          certaintyOptions={certaintyOptions}
        />
      }
      {renderMode isnt 'printout' and <p className="text-right">
          <Button onClick={@addRecommendation}>{@i18n 'actions.add_recommendation'}</Button>
        </p>
      }
    </div>

storeConnector =
  EtdStore: (Store, props) ->
    template: Store.getTemplate()

module.exports = ConnectStore MultipleRecommendations, EtdStore, storeConnector
