mediator = require 'mediator'
Router = require 'router'
Translation = require 'components/mixins/translation'
QuestionsStore = require 'stores/questions_store'
DecisionAidsActions = require 'actions/decision_aids_actions'
ConnectStore = require 'components/enhancers/connect_store'
Select = require 'components/common/select_custom'
LabeledInput = require 'components/common/labeled_input'
DynamicHeightContainer = require 'components/common/dynamic_height_container'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
QuestionOutcomesSelect = require 'components/decision_aid/question_outcomes_select'
AppearanceConfigurator = require 'components/decision_aid/appearance_configurator'
{ pluck: immPluck } = require 'lib/immutable_utils'


storeConnector =
  QuestionsStore: (Store, props) ->
    questions: Store.getQuestions()

CreateDecisionAid = createReactClass
  displayName: 'CreateDecisionAid'

  mixins: [Translation('da:create-screen')]

  getInitialState: ->
    title: ''
    authorOrOrganization: ''
    selectedQuestionId: null
    selectedOutcomes: []
    selectedTheme: 'blue'
    selectedIllustration: 'bloodCells'

  handleTitleChange: (evt) ->
    @setState title: evt.target.value

  handleAuthorChange: (evt) ->
    @setState authorOrOrganization: evt.target.value

  handleQuestionSelect: (selectedQuestionId) ->
    @setState { selectedQuestionId, selectedOutcomes: [] }

  handleOutcomeSelect: (selectedOutcomes) ->
    @setState
      selectedOutcomes: immPluck(selectedOutcomes, '_id').toArray()

  handleThemChange: (selectedTheme) ->
    @setState { selectedTheme }

  handleIllustrationChange: (selectedIllustration) ->
    @setState { selectedIllustration }

  handleCreate: ->
    DecisionAidsActions.create({
      title: @state.title
      authorOrOrganization: @state.authorOrOrganization
      questionId: @state.selectedQuestionId
      outcomeIds: @state.selectedOutcomes
      selectedTheme: @state.selectedTheme
      selectedIllustration: @state.selectedIllustration
    })
    .then @handleCancel

  handleCancel: ->
    mediator.publish '!router:route', Router::getProjectRelativeUrl '/dissemination/decision-aids'

  canCreate: ->
    not _.isEmpty(@state.title) and not R.isEmpty @state.selectedOutcomes

  getQuestions: ->
    @props.questions.filter (q) -> q.get('type') isnt 'diagnostic'

  getQuestionSelectOptions: ->
    @getQuestions().reduce (questionOptions, q) ->
      questionOptions.concat
        value: q.get '_id'
        text: q.get 'question'
        disabled: q.get('outcomes').isEmpty()
    , []

  render: ->
    <div className='new-decision-aid'>
      <DynamicHeightContainer>
        <div>
          <div className='edit-block'>
            <LabeledInput
              type='text'
              value={@state.title}
              onChange={@handleTitleChange}
              label={@i18n 'title'}
            />
          </div>
          <div className='edit-block'>
            <LabeledInput
              type='text'
              value={@state.authorOrOrganization}
              onChange={@handleAuthorChange}
              label={@i18n 'author_organization'}
            />
          </div>
          <div className='process-step'>
            <h4>{@i18n 'step'} 1: {@i18n 'select_questions'}</h4>
            <Select
              options={@getQuestionSelectOptions()}
              selected={@state.selectedQuestionId}
              onChange={@handleQuestionSelect}
              optionsContainerWidthMode='fixed'
            />
          </div>
          {if @state.selectedQuestionId?
            <div>
              <div className='process-step'>
                <h4>{@i18n 'step'} 2: {@i18n 'choose_outcomes'}</h4>
                <QuestionOutcomesSelect
                  questionId={@state.selectedQuestionId}
                  onChange={@handleOutcomeSelect}
                  selected={@state.selectedOutcomes}
                />
              </div>
              <div className='process-step'>
                <h4>{@i18n 'step'} 3: {@i18n 'choose_illustration'}</h4>
                <AppearanceConfigurator
                  onChange={@handleIllustrationChange}
                  selected={@state.selectedIllustration}
                  type='illustrations'
                />
              </div>
              <div className='process-step'>
                <h4>{@i18n 'step'} 4: {@i18n 'choose_theme'}</h4>
                <AppearanceConfigurator
                  onChange={@handleThemChange}
                  selected={@state.selectedTheme}
                  type='themes'
                />
              </div>
            </div>
          }
        </div>
      </DynamicHeightContainer>
      <ApplyCancelButtons
        onApply={@handleCreate}
        onCancel={@handleCancel}
        applyClass='btn-success'
        applyLabel={@i18n '/actions.create'}
        isSubmitEnabled={@canCreate()}
      />
    </div>

module.exports = ConnectStore CreateDecisionAid, QuestionsStore, storeConnector
