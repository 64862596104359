var EtdAssessmentMixin;

EtdAssessmentMixin = {
  setCalcRowSpan: function(type, sharedCells) {
    return sharedCells.map((function(_this) {
      return function(cellDataObj, sharedCellId) {
        var domainSection, rowSpan, _ref;
        rowSpan = _this.props.assessmentSections.count(function(sectionData) {
          return sectionData.get("" + type + "Id") === sharedCellId;
        });
        domainSection = (_ref = _this.props.assessmentSections.findEntry(function(sectionData) {
          return sectionData.get("" + type + "Id") === sharedCellId;
        })) != null ? _ref[0] : void 0;
        if (!domainSection) {
          return;
        }
        return cellDataObj.set('rowSpan', rowSpan).set('domainSection', domainSection);
      };
    })(this));
  },
  getSectionProps: function(sectionId, sectionData) {
    var activeTab, adolopmentData, attachments, criterions, criticalOutcomes, editable, etdViewSettings, outcomes, questionType, renderMode, withSoj, _ref;
    _ref = this.props, activeTab = _ref.activeTab, adolopmentData = _ref.adolopmentData, attachments = _ref.attachments, criterions = _ref.criterions, criticalOutcomes = _ref.criticalOutcomes, editable = _ref.editable, etdViewSettings = _ref.etdViewSettings, outcomes = _ref.outcomes, questionType = _ref.questionType, renderMode = _ref.renderMode, withSoj = _ref.withSoj;
    return {
      activeTab: activeTab,
      additionalConsiderationsVisible: etdViewSettings.get('showAdditionalConsiderations', true),
      attachments: attachments,
      criticalOutcomes: criticalOutcomes,
      editable: editable != null ? editable : typeof this._inEditMode === "function" ? this._inEditMode() : void 0,
      outcomes: outcomes,
      questionType: questionType,
      renderMode: renderMode,
      sectionData: sectionData,
      sectionId: sectionId,
      withSoj: withSoj
    };
  }
};

module.exports = EtdAssessmentMixin;
