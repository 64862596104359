QuestionCodes = require 'components/common/question_codes'

PopulationInterventionRow = ({ i18n, question, codeGroup}) ->
  <React.Fragment>
    <div className="flex flex-row mb-10">
      <b>{_.string.capitalize(i18n(codeGroup))}</b>: {question.getIn([codeGroup, 'name'], "")}
    </div>
    <div className="flex flex-row mb-10">
      <b>{i18n 'short_name'}</b>: {question.getIn([codeGroup, 'shortName'], '')}
    </div>
    <div className="flex flex-row mb-10">
      <b>{i18n 'codes'}</b>:
      <QuestionCodes
        isEditing={false}
        codes={question.getIn([codeGroup, 'codes'], Immutable.Map())}
        parentId={question.get('_id')}
        codeGroup={codeGroup}
        onAdd={null}
        onRemove={null}
      />
    </div>
  </React.Fragment>


MdgQuestionMetaFields = ({ i18n, question }) ->
  <div>
    <div className="flex flex-row mb-10">
      <b>{i18n 'question_type'}</b>: {i18n "/mdg_tables:types.#{question.get('type')}"}
    </div>
    <PopulationInterventionRow i18n={i18n} question={question} codeGroup="intervention" />
    <PopulationInterventionRow i18n={i18n} question={question} codeGroup="population" />
  </div>

module.exports = MdgQuestionMetaFields
