ConnectStore = require 'components/enhancers/connect_store'
{ MDGTable, MDGTableDetailsRow, tableTemplates } = MDGTables
MDGTableActions = require 'actions/mdg_table_actions'
MDGTableStore = require 'stores/mdg_table_store'
ParentStudySelector = require 'components/mdg_tables/parent_study_selector'
ReferencesStore = require 'stores/references_store'
Spinner = require 'components/common/spinner'
TableEditRow = require 'components/mdg_tables/table_edit_row'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

UNDEFINED_TEMPLATE = table:
  columns: []
  editTabs: []

storeConnector =
  MDGTableStore: (Store) ->
    isFetchingQuestion: Store.isFetching()
    editRows: Store.getEditRows()
    expandedRows: Store.getExpandedRows()
    question: Store.getCurrentQuestion()
  ReferencesStore: (Store) ->
    isFetchingReferences: Store.isFetching()
    references: Store.getReferences()

MDGTableView = ({
  isFetchingQuestion
  isFetchingReferences
  editRows
  expandedRows
  question
  references
}) ->
  isFetching = isFetchingQuestion or isFetchingReferences
  i18n = useI18n('mdg_tables:tables')

  onCollapse = useCoffeeCallback [MDGTableActions], (study) -> MDGTableActions.collapseRow study.id
  onExpand = useCoffeeCallback [MDGTableActions], (study) -> MDGTableActions.expandRow study.id
  onEdit = useCoffeeCallback [MDGTableActions], (study) -> MDGTableActions.editRow study.id
  onDuplicateToAnotherQuestion = useCoffeeCallback [MDGTableActions], (study) ->
    MDGTableActions.openDuplicateStudyDialog studyId: study.id

  questionType = question.get 'type'
  questionId = question.get '_id'

  onDisconnectParentStudy = useCoffeeCallback [MDGTableActions, questionId], (study) ->
    MDGTableActions.disconnectParentStudy questionId, study.id
  onOpenParentStudyDialog = useCoffeeCallback [MDGTableActions], (study) ->
    MDGTableActions.openParentStudyDialog study.id

  <div className="mdg-table-view">
    {if isFetching
      <Spinner />
    else
      <React.Fragment>
        <MDGTable
          data={question.toJS()}
          detailsModeComponent={(study) ->
            connectOrDisconnectAction = if study.parentStudy?
              name: 'unlink-from-study'
              action: onDisconnectParentStudy
            else
              name: 'link-to-study'
              action: onOpenParentStudyDialog
            <MDGTableDetailsRow
              i18n={i18n}
              actions={[
                {
                  name: 'edit'
                  action: onEdit
                },
                {
                  name: 'duplicate-to-another-question'
                  action: onDuplicateToAnotherQuestion
                },
                connectOrDisconnectAction
              ]}
              readOnly={false}
              reference={references.find((ref) -> ref.get('_id') is study.referenceId)?.toJS()}
              study={study}
              type={questionType}
            />
          }
          editModeComponent={(study) ->
            <TableEditRow questionId={questionId} questionType={questionType} study={study} />
          }
          i18n={i18n}
          onCollapse={onCollapse}
          onExpand={onExpand}
          references={references.toJS()}
          rowsInEditMode={editRows.toJS()}
          rowsExpanded={expandedRows.toJS()}
          template={tableTemplates[questionType] ? UNDEFINED_TEMPLATE}
          type={questionType}
        />
        <ParentStudySelector />
      </React.Fragment>
    }
  </div>

MDGTableView.propTypes =
  isFetchingQuestion: PropTypes.bool.isRequired
  isFetchingReferences: PropTypes.bool.isRequired
  editRows: PropTypes.instanceOf(Immutable.List).isRequired
  expandedRows: PropTypes.instanceOf(Immutable.List).isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  references: PropTypes.instanceOf(Immutable.List).isRequired

module.exports = ConnectStore MDGTableView, [ MDGTableStore, ReferencesStore ], storeConnector
