var Chaplin, Exceptions, Model, Validation, W, enableTraitMixin, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Chaplin = require('chaplin');

Validation = require('base/validation');

W = require('when');

Exceptions = require('lib/exceptions');

utils = require('base/lib/utils');

enableTraitMixin = require('base/enable_trait_mixin');

module.exports = Model = (function(_super) {
  __extends(Model, _super);

  _(Model.prototype).extend(Validation.mixin);

  Model.prototype.mutedAttributes = ['_rev'];

  function Model() {
    this.isNumberPattern = __bind(this.isNumberPattern, this);
    this.get = __bind(this.get, this);
    this.set = __bind(this.set, this);
    this.nestCollection = __bind(this.nestCollection, this);
    _(this).extend(enableTraitMixin);
    Model.__super__.constructor.apply(this, arguments);
  }

  Model.prototype.initialize = function() {
    Model.__super__.initialize.apply(this, arguments);
    this._dirty = false;
    return this.bind('sync', ((function(_this) {
      return function() {
        return _this._dirty = false;
      };
    })(this)));
  };

  Model.prototype.clone = function() {
    var clonned;
    clonned = Model.__super__.clone.apply(this, arguments);
    if (this.collection) {
      clonned.collection = this.collection;
    }
    return clonned;
  };

  Model.prototype._triggerChangeOnNestedCollectionModels = function(attributeName, nestedCollection, options) {
    var attrs, attrsList, idAttribute, _i, _len, _ref, _ref1, _results;
    attrsList = _(nestedCollection.models).pluck('attributes');
    idAttribute = nestedCollection.model.prototype.idAttribute;
    _results = [];
    for (_i = 0, _len = attrsList.length; _i < _len; _i++) {
      attrs = attrsList[_i];
      _results.push((_ref = this.get(attributeName)) != null ? (_ref1 = _ref.get(attrs[idAttribute])) != null ? _ref1.set(attrs, options) : void 0 : void 0);
    }
    return _results;
  };

  Model.prototype.nestCollection = function(attributeName, nestedCollection, collectionConstructor, options) {
    if (collectionConstructor == null) {
      collectionConstructor = '';
    }
    if (!nestedCollection) {
      return;
    }
    if (collectionConstructor && _.functionName(nestedCollection.constructor) !== _.functionName(collectionConstructor)) {
      nestedCollection = new collectionConstructor(nestedCollection, {
        parse: true
      });
    }
    this._triggerChangeOnNestedCollectionModels(attributeName, nestedCollection, options);
    nestedCollection.parentModel = this;
    nestedCollection.name = attributeName;
    this.attributes[attributeName] = nestedCollection;
    this.nestedCollection = attributeName;
    nestedCollection.trigger('nested');
    return nestedCollection;
  };

  Model.prototype.save = function(key, value, options) {
    var collectionClass, collectionName, nestedModel, _i, _len, _ref, _ref1, _ref2, _ref3, _ref4;
    options = (_ref = _(options).clone()) != null ? _ref : {};
    options.syncing = true;
    if (!this.migrationMode && (this.validate != null) && !this.isValid(true)) {
      return W.reject(new Exceptions.validation(_.values(this.validate()).join(', ')));
    } else if (this._removedFromNestedCollection) {
      return W.resolve(false);
    }
    if ((_ref1 = this.collection) != null ? _ref1.parentModel : void 0) {
      if (this.isNew()) {
        this.set(this.idAttribute, utils.generateGUID());
      }
      this.collection.parentModel.nestCollection(this.collection.name, this.collection, '', options);
      return (_ref2 = this.collection) != null ? _ref2.parentModel.save(key, value, options) : void 0;
    } else if (this.nestedCollections) {
      _ref3 = this.nestedCollections;
      for (collectionName in _ref3) {
        collectionClass = _ref3[collectionName];
        _ref4 = this.get(collectionName).models;
        for (_i = 0, _len = _ref4.length; _i < _len; _i++) {
          nestedModel = _ref4[_i];
          if (nestedModel.isNew()) {
            nestedModel.set(nestedModel.idAttribute, utils.generateGUID());
          }
        }
      }
      return Model.__super__.save.apply(this, arguments);
    } else {
      return Model.__super__.save.apply(this, arguments);
    }
  };

  Model.prototype.destroy = function(options) {
    var modelCollection, modelToSave, _ref, _ref1;
    if ((_ref = this.collection) != null ? _ref.parentModel : void 0) {
      modelToSave = (_ref1 = this.collection) != null ? _ref1.parentModel : void 0;
      modelCollection = this.collection;
      this._removedFromNestedCollection = true;
      this.trigger('destroy', this, modelCollection, options);
      modelCollection.remove(this, {
        silent: true
      });
      modelToSave.nestCollection(modelCollection.name, modelCollection);
      return modelToSave.save(options);
    } else {
      return Model.__super__.destroy.apply(this, arguments);
    }
  };

  Model.prototype.fetch = function(options) {
    var fetching, _ref, _ref1;
    options = (_ref = _(options).clone()) != null ? _ref : {};
    options.syncing = true;
    if ((_ref1 = this.collection) != null ? _ref1.parentModel : void 0) {
      if (this.isNew()) {
        return W.resolve();
      }
      fetching = this.collection.parentModel.fetch();
      return fetching.then((function(_this) {
        return function(parentModel) {
          var newIncarnation;
          newIncarnation = parentModel.get(_this.collection.name).get(_this.id);
          _this.set(newIncarnation.attributes, options);
          return _this.collection.parentModel.nestCollection(_this.collection.name, _this.collection);
        };
      })(this));
    } else {
      return Model.__super__.fetch.call(this, options);
    }
  };

  Model.prototype.set = function(key, value, options) {
    var attr, attrs, mutedAttribute, returnValue, silentOption, _i, _len, _ref;
    if (_(key).isObject() || key === null) {
      options = value;
    }
    if (options == null) {
      options = {};
    }
    if (!options.syncing) {
      options.syncing = _.isFunction(options.error) && _.isFunction(options.success);
    }
    if (this.isNumberPattern(key) && utils.hasValue(value)) {
      if (!isNaN(value)) {
        value = parseFloat(_.string.trim(value));
      }
    }
    attrs = _.isObject(key) || key === null ? key : _.object([[key, value]]);
    if (this.nestedCollections) {
      for (attr in attrs) {
        if (!(__indexOf.call(_.keys(this.nestedCollections), attr) >= 0)) {
          continue;
        }
        if (this.get(attr)) {
          this.get(attr).update(attrs[attr], this.get(attr).model.prototype.idAttribute, options);
        } else {
          attrs[attr] = this.nestCollection(attr, attrs[attr], this.nestedCollections[attr]);
        }
        delete attrs[attr];
      }
    }
    if (options == null) {
      options = {};
    }
    silentOption = options.silent;
    options.silent = true;
    returnValue = Model.__super__.set.call(this, attrs, options);
    options.silent = silentOption;
    if (returnValue) {
      _ref = this.mutedAttributes;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        mutedAttribute = _ref[_i];
        delete this.changed[mutedAttribute];
        delete this._silent[mutedAttribute];
      }
      if (options.syncing) {
        this._dirty = false;
      } else {
        if (!(this._dirty || options.clean)) {
          this._dirty = !_(this._silent).isEmpty();
        }
      }
      if (!silentOption) {
        this.change(options);
      }
    }
    return returnValue;
  };

  Model.prototype.isDirty = function() {
    return this._dirty;
  };

  Model.prototype.get = function(attr) {
    var value;
    value = Model.__super__.get.apply(this, arguments);
    if (this.isNumberPattern(attr) && utils.hasValue(value)) {
      if (!isNaN(value)) {
        return parseFloat(_.string.trim(value));
      }
    } else {
      return value;
    }
  };

  Model.prototype.isNumberPattern = function(key) {
    var _ref;
    return this.validation && this.validation[key] && ((_ref = this.validation[key].pattern) === 'number' || _ref === 'numberOrEmpty');
  };

  Model.prototype.toJSON = function() {
    return JSON.parse(JSON.stringify(this.attributes));
  };

  Model.prototype.dispose = function() {
    this._disableTraits();
    return Model.__super__.dispose.apply(this, arguments);
  };

  return Model;

})(Chaplin.Model);
