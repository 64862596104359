DecisionAidsActions = require 'actions/decision_aids_actions'
IconButton = require 'components/common/icon_button'
InputWithControls = require 'components/common/input_with_controls'
Outcome = require 'components/decision_aid/outcome'
Translation = require 'components/mixins/translation'

INPUTS = ['comparison', 'intervention']

OutcomesTable = createReactClass

  displayName: "OutcomesTable"

  mixins: [Translation('es:outcome')]

  propTypes:
    comparison: PropTypes.string.isRequired
    daId: PropTypes.string.isRequired
    intervention: PropTypes.string.isRequired
    outcomes: PropTypes.instanceOf(Immutable.List).isRequired

  getInitialState: ->
    editing: null

  handleEdit: (key) -> =>
    @setState editing: key

  stopEditing: ->
    @setState editing: null

  saveHeader: (key) -> (value) =>
    { daId } = @props
    DecisionAidsActions.updateQuestion { daId, key, value }
    @stopEditing()

  render: ->
    { outcomes, daId } = @props
    <div className="outcomes-table">
      <div className="outcomes-table__header">
        <div className="outcome-row__outcome-name" />
        {INPUTS.map (inputKey) =>
          <InputWithControls
            controls={<IconButton
              iconName='edit'
              onClick={@handleEdit inputKey}
              inlined={false}
            />}
            editing={@state.editing is inputKey}
            key={inputKey}
            onRequestSave={@saveHeader inputKey}
            onRequestCancel={@stopEditing}
            value={@props[inputKey]}
          />
        }
      <div className="outcome-row__certainty">{@i18n 'printout.certainty_of_evidence'}</div>
      </div>
      {outcomes.map (outcome) ->
        <Outcome
          daId={daId}
          key={outcome.get('_id')}
          outcome={outcome}
        />
      }
    </div>


module.exports = OutcomesTable
