var ContentState, DraftContentRenderer, GdtEditor, blockStylesMap, contentBlockToHTML, convertToDraftJS, convertToRaw, ctag, decorateText, defaultDecorators, getBlockFragments, getEntityCharsMap, getHTMLBlock, getStyledCharsMap, inlineStylesMap, otag, rawContentToHTML, removeHTMLNode, renderMap, stringToDraftJSContent, stylesToString, textFragmentToHTML;

removeHTMLNode = require('base/lib/utils').removeHTMLNode;

convertToRaw = Draft.convertToRaw, ContentState = Draft.ContentState;

GdtEditor = Editor.Editor;

renderMap = Editor.EditorUtils.getBlockRenderMap();

inlineStylesMap = _.extend({}, Draft.DefaultDraftInlineStyle, Editor.EditorUtils.CUSTOM_STYLE_MAP);

blockStylesMap = {
  TEXT_ALIGN_LEFT: {
    textAlign: 'left'
  },
  TEXT_ALIGN_CENTER: {
    textAlign: 'center'
  },
  TEXT_ALIGN_RIGHT: {
    textAlign: 'right'
  }
};

DraftContentRenderer = (function() {
  function DraftContentRenderer(editorOptions) {
    if (editorOptions == null) {
      editorOptions = {};
    }
    this.tempContainer = document.createElement('div');
    ReactDOM.render(React.createElement(GdtEditor, _.extend(editorOptions, {
      editorContent: null,
      ref: (function(_this) {
        return function(el) {
          return _this.gdtEditor = el;
        };
      })(this)
    })), this.tempContainer);
    this;
  }

  DraftContentRenderer.prototype.rawContentToHTML = function(rawContent) {
    var emptyContent;
    if (_.isEmpty(rawContent) || _.isString(rawContent)) {
      return rawContent;
    }
    this.gdtEditor.resetContent(rawContent);
    emptyContent = _.isEmpty(this.tempContainer.querySelector('.public-DraftEditor-content').innerText.trim());
    if (emptyContent) {
      return '';
    }
    return this.tempContainer.querySelector('.public-DraftEditor-content').innerHTML;
  };

  DraftContentRenderer.prototype.dispose = function() {
    ReactDOM.unmountComponentAtNode(this.tempContainer);
    removeHTMLNode(this.tempContainer);
    return this.tempContainer = null;
  };

  return DraftContentRenderer;

})();

defaultDecorators = {
  'LINK': function(props) {
    return React.createElement('a', {
      href: props.data.get('url'),
      dangerouslySetInnerHTML: {
        __html: props.children
      }
    });
  }
};

otag = function(tag, attrs) {
  var attr, attrVal, tagStr;
  tagStr = "<" + tag;
  if ((attrs != null) && _.isObject(attrs)) {
    for (attr in attrs) {
      attrVal = attrs[attr];
      tagStr = "" + tagStr + " " + attr + "=\"" + attrVal + "\"";
    }
  }
  return tagStr += '>';
};

ctag = function(tag) {
  return "</" + tag + ">";
};

getHTMLBlock = function(tag, text, attrs) {
  return otag(tag, attrs).concat(text, ctag(tag));
};

getStyledCharsMap = function(styleRanges) {
  return styleRanges.map(function(rangeSpec) {
    var length, offset, style, _ref;
    _ref = rangeSpec.toJS(), offset = _ref.offset, length = _ref.length, style = _ref.style;
    return Immutable.OrderedMap(Immutable.Range(offset, offset + length).zip(Immutable.Repeat(Immutable.List([style]), length)));
  }).reduce(function(acc, inlineStylesMap) {
    return acc.mergeWith(function(prev, next) {
      return prev.concat(next);
    }, inlineStylesMap);
  }, Immutable.OrderedMap());
};

getEntityCharsMap = function(entityRanges) {
  return entityRanges.reduce(function(resultingMap, rangeSpec) {
    var key, length, offset, _ref;
    _ref = rangeSpec.toJS(), offset = _ref.offset, length = _ref.length, key = _ref.key;
    return resultingMap.merge(Immutable.OrderedMap(Immutable.Range(offset, offset + length).zip(Immutable.Repeat(key, length))));
  }, Immutable.OrderedMap());
};

stylesToString = function(styles) {
  return styles.reduce(function(result, style) {
    var styleRule, value, _ref;
    styleRule = inlineStylesMap[style] || blockStylesMap[style];
    if (styleRule == null) {
      return result;
    }
    _ref = _.pairs(styleRule)[0], style = _ref[0], value = _ref[1];
    return result.concat("" + (_.str.dasherize(style)) + ":" + value + ";");
  }, '');
};

textFragmentToHTML = function(textFragment) {
  var styles, text;
  text = textFragment.get('text');
  styles = textFragment.get('style');
  return getHTMLBlock('span', text, (!styles.isEmpty() ? {
    style: stylesToString(styles)
  } : void 0));
};

getBlockFragments = function(text, styleRanges, entityRanges) {
  var entityCharsMap, styledCharsMap;
  if (_.isEmpty(text)) {
    return Immutable.List();
  }
  styledCharsMap = getStyledCharsMap(styleRanges);
  entityCharsMap = getEntityCharsMap(entityRanges);
  return Immutable.List(text).reduce(function(fragments, char, charIdx) {
    var charEntityKey, charStyles, prevCharEntityKey, prevCharStyles, updatedLastFragment;
    charStyles = styledCharsMap.get(charIdx, Immutable.List());
    prevCharStyles = styledCharsMap.get(charIdx - 1, Immutable.List());
    charEntityKey = entityCharsMap.get(charIdx);
    prevCharEntityKey = entityCharsMap.get(charIdx - 1);
    if (charStyles.equals(prevCharStyles) && charEntityKey === prevCharEntityKey && charIdx !== 0) {
      updatedLastFragment = fragments.last().update('textFragments', function(textFragments) {
        return textFragments.set(textFragments.size - 1, textFragments.last().update('text', function(text) {
          return text.concat(char);
        }));
      });
      return fragments.set(fragments.size - 1, updatedLastFragment);
    } else if ((charEntityKey != null) && charEntityKey === prevCharEntityKey && charIdx !== 0) {
      updatedLastFragment = fragments.last().update('textFragments', function(textFragments) {
        return textFragments.push(Immutable.Map({
          text: char,
          style: charStyles
        }));
      });
      return fragments.set(fragments.size - 1, updatedLastFragment);
    } else {
      return fragments.push(Immutable.Map({
        textFragments: Immutable.List([
          Immutable.Map({
            text: char,
            style: charStyles
          })
        ]),
        entityKey: charEntityKey
      }));
    }
  }, Immutable.List());
};

decorateText = function(entityData, decorator, text) {
  return ReactDOMServer.renderToStaticMarkup(React.createElement(decorator, _.extend({
    data: entityData,
    children: text
  })));
};

contentBlockToHTML = function(entityMap, entityDecorators) {
  return function(block, idx, blocks) {
    var blockFragments, blockHTML, blockInnerHTML, blockStyles, blockStylesString, entityRanges, nextBlockType, prevBlockType, styleRanges, tag, text, type, wrapTag, wrapper, _ref;
    type = block.get('type');
    text = block.get('text');
    styleRanges = block.get('inlineStyleRanges');
    entityRanges = block.get('entityRanges');
    _ref = renderMap.get(type), tag = _ref.element, wrapper = _ref.wrapper;
    if (tag == null) {
      return '';
    }
    blockStyles = block.getIn(['data', 'styles'], Immutable.List());
    blockFragments = getBlockFragments(text, styleRanges, entityRanges);
    blockInnerHTML = blockFragments.map(function(blockFragment) {
      var decorator, entity, entityData, entityKey, entityType, textFragments, textFragmentsHTML;
      textFragments = blockFragment.get('textFragments');
      entityKey = blockFragment.get('entityKey');
      textFragmentsHTML = textFragments.map(textFragmentToHTML).join('');
      if (entityKey == null) {
        return textFragmentsHTML;
      }
      entity = entityMap.get("" + entityKey);
      entityType = entity.get('type');
      decorator = entityDecorators[entityType];
      if (decorator == null) {
        return textFragmentsHTML;
      }
      entityData = entity.get('data');
      return decorateText(entityData, decorator, textFragmentsHTML);
    }).join('');
    blockStylesString = stylesToString(blockStyles);
    blockHTML = getHTMLBlock(tag, blockInnerHTML, (blockStylesString ? {
      style: blockStylesString
    } : void 0));
    if (wrapper != null) {
      prevBlockType = blocks.getIn([idx - 1, 'type']);
      nextBlockType = blocks.getIn([idx + 1, 'type']);
      wrapTag = wrapper.type;
      if (prevBlockType !== type) {
        blockHTML = otag(wrapTag).concat(blockHTML);
      }
      if (nextBlockType !== type) {
        blockHTML = blockHTML.concat(ctag(wrapTag));
      }
    }
    return blockHTML;
  };
};

rawContentToHTML = function(rawContent, customDecorators) {
  var contentHTML, decorators, entityMap, rawContentBlocks;
  if (!rawContent) {
    return '';
  }
  decorators = _.extend({}, defaultDecorators, customDecorators);
  if (rawContent.toJS == null) {
    rawContent = Immutable.fromJS(rawContent);
  }
  rawContentBlocks = rawContent.get('blocks');
  entityMap = rawContent.get('entityMap');
  contentHTML = rawContentBlocks.map(contentBlockToHTML(entityMap, decorators)).join('');
  return getHTMLBlock('div', contentHTML);
};

stringToDraftJSContent = function(content) {
  if (content == null) {
    content = "";
  }
  return convertToRaw(Editor.EditorUtils.customHTML2Content(content));
};

convertToDraftJS = function(content) {
  var val;
  if (content == null) {
    content = '';
  }
  val = _.isString(content) ? Immutable.fromJS(convertToRaw(ContentState.createFromText(content))) : content;
  return val.toJS();
};

module.exports = {
  DraftContentRenderer: DraftContentRenderer,
  rawContentToHTML: rawContentToHTML,
  stringToDraftJSContent: stringToDraftJSContent,
  convertToDraftJS: convertToDraftJS
};
