{ useCoffeeMemo, useCoffeeCallback } = require 'lib/react_utils'
{ getTagsByKeys } = require 'lib/etd_helper'
EtdActions = require 'actions/etd_actions'


MultiInterventionTypeOfRecommendation = ({
  editable
  overarchingQuestionData
  options
  renderMode
  sectionId
  selectedOptions
  noAutoSave
}) ->

  interventions = useCoffeeMemo [overarchingQuestionData], ->
    getTagsByKeys overarchingQuestionData.get('includedTags', Immutable.Map()), ['intervention']

  setSelectedOptionForIntervention = useCoffeeCallback [interventions, noAutoSave],
    (intervention, value) -> ->
      EtdActions.setSelectedOptions { sectionId, intervention, value, noAutoSave }

  <div>
    <table className="mulitintervention-type-of-recommendation">
      <thead>
        <tr>
          <th className="blank" />
          {options.map (option) ->
            <th key={option.get('value')}>{option.get('text')}</th>
          .toList()}
        </tr>
      </thead>
      <tbody>
        {interventions.map (intervention) ->
          interventionId = intervention.get('id')
          <tr key={intervention.get('id')}>
            <td className="option-labels">{intervention.get('name')}</td>
            {options.map (option) ->
              checked = selectedOptions.get(interventionId, '') is option.get('value')
              <td
                key={option.get('value')}
                className="option-mark"
                onClick={setSelectedOptionForIntervention(
                  intervention, option.get('value')) if editable}
              >
                <input
                  type="radio"
                  name={intervention.get('id')}
                  value={option.get('value')}
                  checked={checked}
                  readOnly
                />
              </td>
            .toList()}
          </tr>
        .toList()}
      </tbody>
    </table>
  </div>

MultiInterventionTypeOfRecommendation.propTypes =
  noAutoSave: PropTypes.bool.isRequired
  editable: PropTypes.bool.isRequired
  overarchingQuestionData: PropTypes.instanceOf(Immutable.Map).isRequired,
  options: PropTypes.instanceOf(Immutable.List).isRequired,
  renderMode: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  selectedOptions: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = MultiInterventionTypeOfRecommendation
