var ProxyModel, QuestionGroup,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ProxyModel = require('models/base/proxy_model');

module.exports = QuestionGroup = (function(_super) {
  __extends(QuestionGroup, _super);

  function QuestionGroup() {
    return QuestionGroup.__super__.constructor.apply(this, arguments);
  }

  QuestionGroup.prototype.read = function() {
    return this.proxied.getGroupModel(this.id);
  };

  QuestionGroup.prototype.update = function(model) {
    return this.proxied.rename(this.id, model.get('caption'));
  };

  QuestionGroup.prototype["delete"] = function() {
    return this.proxied.remove(this.id);
  };

  return QuestionGroup;

})(ProxyModel);
