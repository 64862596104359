mediator = require 'mediator'
MembersActions = require 'actions/members_actions'
Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
ValidationContainer = require 'components/common/validation_container'
{ getAccessRightsList, isCurrentlyLogged } = require 'lib/members_helper'

AccessRights = createReactClass
  displayName: "AccessRights"

  mixins: [Translation('team:member_access_rights')]

  propTypes:
    adminsLimit: PropTypes.number.isRequired,
    disabledAccessRights: PropTypes.instanceOf(Array)
    error: PropTypes.string
    member: PropTypes.instanceOf(Immutable.Map).isRequired
    onChange: PropTypes.func.isRequired
    options: PropTypes.instanceOf(Array)
    selected: PropTypes.string

  getInitialState: ->
    error: null

  getDefaultProps: ->
    disabledAccessRights: []

  componentWillReceiveProps: (nextProps) ->
    @setState error: nextProps.error

  prepareOptions: ->
    { adminsLimit, disabledAccessRights, options } = @props
    options ?= getAccessRightsList()
    _.map options, (option) =>
      _.extend {}, option, {
        disabled: option.value in disabledAccessRights
        annotation:
          if option.value in disabledAccessRights
            @i18n "admins_researchers_limit_exceeded", limit: adminsLimit
          else
            option.annotation
      }

  resetError: ->
    @setState error: null

  render: ->
    <div onClick={@resetError}>
      {if @props.member.get('pendingInvitation')
        <p>{@i18n('/team:member.pending_invitation')}</p>
      }
      <ValidationContainer errorMsg={@state.error}>
        <Select
          options={@prepareOptions()}
          selected={@props.selected}
          disabled={isCurrentlyLogged(@props.member)}
          onChange={@props.onChange}
          className='member-access-rights'
          annotationClassName='member-access-annotation'
          annotationPosition={position: 'right', alignment: 'end', positionOffset: 10}
        />
      </ValidationContainer>
    </div>

module.exports = AccessRights
