AccessRights = require 'components/team/form/access_rights'
Translation = require 'components/mixins/translation'


AccessRightsFormGroup = createReactClass
  displayName: "AccessRightsFormGroup"

  mixins: [Translation('team:member')]

  propTypes:
    adminsLimit: PropTypes.number.isRequired
    disabledAccessRights: PropTypes.instanceOf(Array)
    error: PropTypes.instanceOf(Immutable.Map)
    member: PropTypes.instanceOf(Immutable.Map).isRequired
    updateAccessRights: PropTypes.func.isRequired

  getDefaultProps: ->
    disabledAccessRights: []
    error: null

  render: ->
    <div className="form-group access-rights">
      <div className="inline">
        <label className="pull-left required">{@i18n 'access_rights'}</label>
        <div className="pull-left input-container">
          <AccessRights
            adminsLimit={@props.adminsLimit}
            disabledAccessRights={@props.disabledAccessRights}
            error={@props.errors?.get('accessRights')}
            member={@props.member}
            onChange={@props.updateAccessRights}
            selected={@props.selected}
          />
        </div>
      </div>
    </div>

module.exports = AccessRightsFormGroup
