{ string, number, bool, func } = PropTypes

module.exports = createReactClass
  displayName: 'Spinner'

  propTypes: ->
    scale: number

  getDefaultProps: ->
    scale: 1

  componentDidMount: ->
    # z-index should be lower than notifiers.
    @spinner = new Spinner(zIndex: 100, scale: @props.scale).spin(@spinnerEl)

  componentWillUnmount: ->
    @spinner.stop()

  render: ->
    <div className='spinner-el' ref={(el) => @spinnerEl = el}/>
