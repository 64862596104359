var DbDoc, MDG_SEARCH_STRATEGY_PREFIX, mdgSearchStrategyDocShape;

DbDoc = require('lib/db_docs/db_doc');

mdgSearchStrategyDocShape = require('lib/db_docs/doc_shapes/mdg_search_strategy_doc_shape');

MDG_SEARCH_STRATEGY_PREFIX = require('lib/doc_prefixes').mdgSearchStrategy;

module.exports = DbDoc(mdgSearchStrategyDocShape, {
  dbSetup: {
    idPrefix: MDG_SEARCH_STRATEGY_PREFIX
  },
  methods: {
    updateGeneralInformation: function(newValue) {
      return this.setAt('generalInformation')(newValue);
    },
    updateCriterion: function(tabId, typeId, field, newValue) {
      return this.setAt('criteria', tabId, typeId, field)(newValue);
    }
  }
});
