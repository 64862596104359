Button = require 'components/common/button'
Translation = require 'components/mixins/translation'

CopyButton = createReactClass
  displayName: 'CopyButton'

  mixins: [Translation()]

  propTypes:
    onClick: PropTypes.func.isRequired
    title: PropTypes.string

  render: ->
    title = @props.title ? @i18n 'actions.copy'
    classes = classNames "copy", @props.className
    <Button className={classes} title={title} onClick={@props.onClick}/>

module.exports = CopyButton
