var ModelTrait, ReferencesBinding, Set,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModelTrait = require('base/lib/traits/model_trait');

Set = require('base/lib/set');

module.exports = ReferencesBinding = (function(_super) {
  __extends(ReferencesBinding, _super);

  function ReferencesBinding() {
    return ReferencesBinding.__super__.constructor.apply(this, arguments);
  }

  ReferencesBinding.prototype.apply = function(model) {
    ReferencesBinding.__super__.apply.apply(this, arguments);
    this._addFunction(model, 'getSetOfReferencesIdsForProperty');
    this._addFunction(model, 'bindReference');
    this._addFunction(model, 'unbindReference');
    if (!model.get('references')) {
      return model.set('references', {});
    }
  };

  ReferencesBinding.prototype.getSetOfReferencesIdsForProperty = function(originalFunction, trait, property) {
    var _ref;
    return new Set((_ref = this.get('references')[property]) != null ? _ref : []);
  };

  ReferencesBinding.prototype.bindReference = function(originalFunction, trait, referenceId, property) {
    var referenceIdsForProperty;
    referenceIdsForProperty = this.getSetOfReferencesIdsForProperty(property);
    this.get('references')[property] = referenceIdsForProperty.add(referenceId).asArray();
    this.store();
    this.trigger('referenceBound', referenceId, property);
    return this.publishEvent('referencesChanged');
  };

  ReferencesBinding.prototype.unbindReference = function(originalFunction, trait, referenceId, property) {
    var referenceIdsForProperty, references, _i, _len, _ref;
    if (property) {
      referenceIdsForProperty = this.getSetOfReferencesIdsForProperty(property);
      references = this.get('references');
      references[property] = referenceIdsForProperty.remove(referenceId).asArray();
      this.trigger('referenceUnbound', referenceId, property);
      this.publishEvent('referencesChanged');
    } else {
      _ref = _.keys(this.get('references'));
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        property = _ref[_i];
        this.unbindReference(referenceId, property);
      }
    }
    return this.store();
  };

  return ReferencesBinding;

})(ModelTrait);
