ConnectStore = require 'components/enhancers/connect_store'
ProjectStore = require 'stores/project_store'
ProjectActions = require 'actions/project_actions'
Statistics = require 'components/dashboard/statistics'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'

REVIEW_TYPES = ['panel_review', 'external_review']

Dashboard = ({ project }) ->

  i18n = useI18n("projects:dashboard")

  toggleReview = useCoffeeCallback [], (reviewType) -> -> ProjectActions.toggleReview reviewType

  <div className="review-statuses">
    {_.map REVIEW_TYPES, (reviewType) ->
      <div className="flex flex-row items-center" key={reviewType}>
        <div className="review-label">
          {i18n reviewType}
        </div>
        <ReviewToggle
          reviewCompleted={project.get(reviewType)}
          onChange={toggleReview(reviewType)}
        />
      </div>
    }
    <Statistics />
  </div>

Dashboard.propTypes =
  project: PropTypes.instanceOf(Immutable.Map).isRequired

ReviewToggle = ({ reviewCompleted, onChange }) ->

  i18n = useI18n("projects:dashboard")

  labelClassNames = classNames 'review-status-label',
    'complete': reviewCompleted,
    'incomplete': not reviewCompleted

  <label className={labelClassNames}>
    <input
      type="checkbox"
      checked={reviewCompleted}
      onChange={onChange}
    />
    {i18n if reviewCompleted then 'complete' else 'incomplete'}
  </label>

ReviewToggle.propTypes =
  reviewCompleted: PropTypes.bool.isRequired
  onChange: PropTypes.func.isRequired

storeConnectors =
  ProjectStore: (Store) ->
    project: Store.getProject()

module.exports = ConnectStore Dashboard, [ProjectStore], storeConnectors
