var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <!doctype html>\n  <html>\n    <head>\n      <title>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{
    'projectName': ((depth0 != null ? depth0.projectName : depth0))
  },"data":data})))
    + "</title>\n      <style>\n        * {\n          font-family: 'Times New Roman', sans-serif;\n        }\n        h1 {\n          font-size: 18pt;\n          font-weight: bold;\n        }\n        h2 {\n          font-size: 16pt;\n          font-weight: bold;\n        }\n        a {\n          font-size: 12pt;\n        }\n      </style>\n    </head>\n    <body>\n      <h1>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "title", {"name":"i18n","hash":{
    'projectName': ((depth0 != null ? depth0.projectName : depth0))
  },"data":data})))
    + "</h1>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.headings : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </body>\n  </html>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <h2>"
    + escapeExpression(((helper = (helper = helpers.headingTitle || (depth0 != null ? depth0.headingTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headingTitle","hash":{},"data":data}) : helper)))
    + "</h2>\n";
  stack1 = ((helpers.ul || (depth0 && depth0.ul) || helperMissing).call(depth0, (depth0 != null ? depth0.links : depth0), {"name":"ul","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "           <a href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + ": "
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "</a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:global_export_dialog.links_document", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}