OutcomesGenerationStore = require 'stores/outcomes_generation_store'
OutcomesGenerationActions = require 'actions/outcomes_generation_actions'
ExportButton = require 'components/scope/scope_export_button'
{ OUTCOMES_GENERATION_STEPS } = require 'lib/questions_helper'
TabsPane = require 'components/common/tabs_pane'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
CreateStoreMixin = require 'components/mixins/create_store'

OutcomesGenerationToolbar = createReactClass
  displayName: 'OutcomesGenerationToolbar'

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes.tabs')
    CreateStoreMixin(OutcomesGenerationStore)
  ]

  getStateFromStores: ->
    activeTab: OutcomesGenerationStore.getActiveTab()
    currentStep: OutcomesGenerationStore.getCurrentStep()
    questionGroupsWithComments: OutcomesGenerationStore.getStepQuestionGroupsWithComments()

  _getTabsList: ->
    currentStepIdx = OUTCOMES_GENERATION_STEPS.indexOf @state.currentStep
    OUTCOMES_GENERATION_STEPS.map (tabName, idx) =>
      tabLabel = @i18n tabName
      isDisabled = idx > currentStepIdx
      { tabName, tabLabel, isDisabled }

  _isExportDisabled: ->
    # always disabled on initial and rating tab
    return true if @state.activeTab in [
      OUTCOMES_GENERATION_STEPS[0]
      OUTCOMES_GENERATION_STEPS[3]
      OUTCOMES_GENERATION_STEPS[6]
    ]
    # TODO: always enabled on finished tab
    # return false if @state.activeTab is OUTCOMES_GENERATION_STEPS[6]
    # for the rest of the tabs it is enabled if there is any question group with commented
    # outcomes
    @state.questionGroupsWithComments.isEmpty()

  render: ->
    <div className='scope-toolbar'>
      <TabsPane
        activeTab={@state.activeTab}
        tabs={@_getTabsList()}
        onTabClick={OutcomesGenerationActions.changeTab}
      />
      <ExportButton
        className={@state.activeTab}
        onClick={OutcomesGenerationActions.showCommentsExport}
        disabled={@_isExportDisabled()}
      />
    </div>

module.exports = OutcomesGenerationToolbar
