var INITIAL_STATE, MDGTopicActions, addFn, appUtils, generateStore, removeFn;

appUtils = require('lib/app_utils');

generateStore = require('stores/utils/generate_store');

MDGTopicActions = require('actions/mdg_topic_actions');

addFn = function(topicId) {
  return function(topics) {
    return topics.push(topicId);
  };
};

removeFn = function(topicId) {
  return function(topics) {
    return topics.filterNot(function(id) {
      return id === topicId;
    });
  };
};

INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false,
    fetchingError: null
  },
  topicsInEditMode: Immutable.List(),
  topics: Immutable.OrderedMap()
});

module.exports = generateStore({
  name: 'MDGTopicStore',
  initialState: INITIAL_STATE,
  boundActions: [MDGTopicActions],
  methods: {
    onCreate: function(topicId) {
      return this.setState(this.state.update('topicsInEditMode', addFn(topicId)));
    },
    onDbChange: function(_arg) {
      var deleted, doc, id;
      deleted = _arg.deleted, doc = _arg.doc, id = _arg.id;
      if (deleted) {
        return this.setState(this.state.deleteIn(['topics', id]));
      } else {
        return this.setState(this.state.setIn(['topics', id], Immutable.fromJS(doc)));
      }
    },
    onFetch: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.set('topics', Immutable.OrderedMap());
        state.setIn(['meta', 'isFetching'], true);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchError: function(err) {
      appUtils.errorHandlerWithMsg(err, $.t('settings:mdg_topics.fetch_error'));
      return this.setState(this.state.withMutations(function(state) {
        state.set('topics', Immutable.OrderedMap());
        state.setIn(['meta', 'isFetching'], false);
        return state.setIn(['meta', 'fetchingError'], err);
      }));
    },
    onFetchSuccess: function(_arg) {
      var rows, topics;
      rows = _arg.rows;
      topics = _.chain(rows).pluck('doc').sortBy('orderNumber').reduce(function(acc, doc) {
        return acc.set(doc['_id'], Immutable.fromJS(doc));
      }, Immutable.OrderedMap()).value();
      return this.setState(this.state.withMutations(function(state) {
        state.set('topics', topics);
        state.setIn(['meta', 'isFetching'], false);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onToggleEditForRow: function(topicId) {
      return this.setState(this.state.update('topicsInEditMode', function(topics) {
        if (topics.includes(topicId)) {
          return removeFn(topicId)(topics);
        } else {
          return addFn(topicId)(topics);
        }
      }));
    },
    onUpdate: function(topicId) {
      return this.setState(this.state.update('topicsInEditMode', removeFn(topicId)));
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'isFetching']);
    },
    getFetchingError: function() {
      return this.state.getIn(['meta', 'fetchingError']);
    },
    getTopics: function() {
      return this.state.get('topics');
    },
    getTopicsInEditMode: function() {
      return this.state.get('topicsInEditMode');
    }
  }
});
