var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<p>A nome del Sistema Nazionale delle Linee Guida, la invitiamo a compilare\nil modulo sul Conflitto di Interessi per i membri e i partecipanti al Panel delle Linee Guida.</p>\n\n<p>Per favore segua il link <a href=\"__link__\">__link__</a>\nper il modulo online e dichiari il suo potenziale conflitto di interessi\nin relazione al "
    + escapeExpression(((helper = (helper = helpers.projectName || (depth0 != null ? depth0.projectName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"projectName","hash":{},"data":data}) : helper)))
    + ".</p>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}