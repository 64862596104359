SpinnerButton = require 'components/common/spinner_button'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ScopeDataFetchMixin = require 'components/mixins/scope_data_fetch_mixin'
ScopeRatingMixin = require 'components/mixins/scope_rating_mixin'

{ Button } = ReactComponents

RatingTab = createReactClass
  displayName: 'RatingTab'

  propTypes:
    members: PropTypes.object.isRequired
    membersSentTo: PropTypes.object.isRequired
    membersSubmitted: PropTypes.object.isRequired
    fetchResults: PropTypes.func.isRequired
    readOnly: PropTypes.bool.isRequired
    closeStep: PropTypes.func.isRequired
    isClosingStep: PropTypes.bool.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
    ScopeDataFetchMixin
    ScopeRatingMixin
  ]

  componentDidMount: ->
    # during first render sentTo will be empty, since this prop is obtained from API, need to fetch
    if @props.membersSentTo.isEmpty()
      @props.fetchResults()

  render: ->
      <div className='rating-tab'>
        <div className='information'>
          <div>{@i18n 'rating_section_text'}</div>
        </div>
        <div className='top-buttons'>
          <Button className='btn-refresh'
            onClick={@props.fetchResults}
            disabled={@_isFetchDisabled()}>
            {@i18n '../questions.brainstorming.refresh'}
          </Button>
        </div>
        <div className='questions-rating'>
          <ol className='standard-list'>
            {@props.membersSentTo.map(@membersMapper).toList()}
          </ol>
        </div>
        <div className="buttons">
          <SpinnerButton
            className="btn close-step"
            onClick={@props.closeStep}
            disabled={@props.readOnly}
            loading={@props.isClosingStep}
            label={@i18n 'close_rating'}
          />
        </div>
      </div>

module.exports = RatingTab
