Question = require 'components/scope/questions/question'
CommentButton = require 'components/common/empty_comment_button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

ApprovalQuestionRow = createReactClass
  displaName: 'ApprovalQuestionRow'

  propTypes:
    question: PropTypes.instanceOf(Immutable.Map).isRequired
    questionOnly: PropTypes.bool
    onCommentToggle: PropTypes.func

  mixins: [
    CustomRenderMixin
    Translation('scope:questions')
  ]

  _getApprovalRatio: ->
    approvals = @props.question.get 'approvals', Immutable.Map()
    return null if approvals.isEmpty()
    approved = approvals.filter (v) -> v is 'yes'

    ratio = Math.floor approved.size/approvals.size*100

  renderApprovalRatio: (approvalRatio) ->
    return null if @props.questionOnly
    return @i18n '../outcomes.n_a' unless approvalRatio?

    hasDisagreementResolved = @props.question.get 'priorityDisagreementResolved', false
    ratioClass = classNames
        'red': approvalRatio? and approvalRatio < 100 and not hasDisagreementResolved

    <div className={ratioClass}>
      {"#{approvalRatio}%"}
      {if @props.todosCount > 0
        <div className='todos-count'>
          {"#{@i18n 'approval.to_do'} (#{@props.todosCount})"}
        </div>
      }
    </div>

  renderCommentsToggle: ->
    return null unless @props.onCommentToggle?
    buttonClass = classNames 'comment-blank-hide': @props.showingComments,
      'comment-blank': not @props.showingComments
    <CommentButton className={buttonClass} onClick={@props.onCommentToggle} />

  render: ->
    question = @props.question.get 'question'
    approvalRatio = @_getApprovalRatio()
    notAllApproved = approvalRatio and approvalRatio < 100

    rowClass = classNames 'question-row',
      'question-only': @props.questionOnly
      'group-changed': @props.groupType isnt @props.question.get 'approvalStatus'
    approvedClass = classNames 'approved', 'no-border-right': notAllApproved
    commentsClass = classNames 'comments', 'no-border-left': notAllApproved

    <tr className={rowClass}>
      <td className='question'>{question}</td>
      <td className={approvedClass} onClick={@props.onCommentToggle if notAllApproved}>
        {@renderApprovalRatio approvalRatio}
      </td>
      <td className={commentsClass} onClick={@props.onCommentToggle if notAllApproved}>
        {@renderCommentsToggle()}
      </td>
    </tr>

module.exports = ApprovalQuestionRow
