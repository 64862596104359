var DuplicatesTable, DuplicatesView, ReactComponent, ReactToolbarView, ReferencesActions, ReferencesToolbar, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DuplicatesTable = require('components/references/duplicates_table');

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

ReferencesActions = require('actions/references_actions');

ReferencesToolbar = require('components/references/references_toolbar');

View = require('base/views/view');

module.exports = DuplicatesView = (function(_super) {
  __extends(DuplicatesView, _super);

  function DuplicatesView() {
    return DuplicatesView.__super__.constructor.apply(this, arguments);
  }

  DuplicatesView.prototype.container = '#page-container';

  DuplicatesView.prototype.autoRender = true;

  DuplicatesView.prototype.initialize = function() {
    DuplicatesView.__super__.initialize.apply(this, arguments);
    this._stopInputsPropagation = false;
    this.subscribeEvent('deduplicationCompleted', this.render);
    this.subview('toolbar', new ReactToolbarView({
      component: ReferencesToolbar,
      props: {
        mode: 'duplicates'
      }
    }));
    return this.enable(ReactComponent);
  };

  DuplicatesView.prototype.afterRender = function() {
    DuplicatesView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(DuplicatesTable, ReferencesActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  DuplicatesView.prototype.dispose = function() {
    this.unmountComponent();
    return DuplicatesView.__super__.dispose.apply(this, arguments);
  };

  return DuplicatesView;

})(View);
