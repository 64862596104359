var AdolopedEtdActions, AdolopmentDataActions, EtdActions, FluxModulesWatcher, KeyMessagesActions, MdgTableActions, OverarchingQuestionsActions, PanelVoiceEtdActions, QualityIndicatorsActions, QuestionsActions, QuestionsListComponentActions, UserProjectDataActions, W, alt, appUtils, mediator;

AdolopedEtdActions = require('actions/adoloped_etd_actions');

AdolopmentDataActions = require('actions/adolopment_data_actions');

alt = require('alt');

appUtils = require('lib/app_utils');

EtdActions = require('actions/etd_actions');

KeyMessagesActions = require('actions/key_messages_actions');

MdgTableActions = require('actions/mdg_table_actions');

mediator = require('mediator');

OverarchingQuestionsActions = require('actions/overarching_questions_actions');

PanelVoiceEtdActions = require('actions/panel_voice_etd_actions');

QualityIndicatorsActions = require('actions/quality_indicators_actions');

QuestionsActions = require('actions/questions_actions');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

UserProjectDataActions = require('actions/user_project_data_actions');

W = require('when');

module.exports = FluxModulesWatcher = (function() {
  FluxModulesWatcher.prototype.moduleName = '';

  FluxModulesWatcher.prototype.submoduleName = null;

  FluxModulesWatcher.prototype.listener = null;

  FluxModulesWatcher.prototype.submoduleListeners = [];

  function FluxModulesWatcher() {
    mediator.subscribe('moduleChanged', (function(_this) {
      return function(module) {
        if (_this.moduleName !== module) {
          _this.moduleName = module;
          _this.submoduleName = '';
          return _this.moduleChanged(module);
        }
      };
    })(this));
    mediator.subscribe('moduleParamsChanged', (function(_this) {
      return function(params) {
        var submodule;
        submodule = params.submodule;
        if (_this.submoduleName !== submodule || params.id !== _this.id) {
          _this.submoduleName = submodule;
          if (params.id) {
            _this.id = params.id;
          }
          return _this.moduleParamsChanged(params);
        }
      };
    })(this));
  }

  FluxModulesWatcher.prototype.moduleChanged = function(newModule) {
    var _ref;
    if ((_ref = this.listener) != null) {
      _ref.cancel();
    }
    this.listener = null;
    switch (newModule) {
      case 'multi_comparisons':
        return this.multiComparisonsModuleLoaded();
      case 'team':
        return this.teamModuleLoaded();
    }
  };

  FluxModulesWatcher.prototype.teamModuleLoaded = function(params) {
    var MembersActions, dbId, listenOpt;
    dbId = mediator.project.id;
    MembersActions = alt.actions.MembersActions;
    listenOpt = {
      filter: '_view',
      view: 'projectMembers'
    };
    return this.listener = mediator.services.storePersistenceAdapter.setupListening(dbId, listenOpt, MembersActions.dbChange);
  };

  FluxModulesWatcher.prototype.moduleParamsChanged = function(params) {
    var newSubmodule;
    this.submoduleListeners.map(function(listener) {
      return listener.cancel();
    });
    this.submoduleListeners.length = 0;
    newSubmodule = params.submodule;
    switch (this.moduleName) {
      case 'multi_comparisons':
        switch (newSubmodule) {
          case 'details':
            return this.multiComparisonDetailsLoaded(params);
        }
        break;
      case 'evidence_syntheses':
        switch (newSubmodule) {
          case 'recommendations':
          case 'recommendations-static':
            return this.recommendationSubModuleLoaded(params);
          case 'presentations':
            return this.presentationsSubModuleLoaded(params);
          case 'mdg-recommendations':
          case 'mdg-search-strategy':
            return this.mdgRecommendationsLoaded(params);
          case 'quality-indicators':
          case 'quality-of-evidence':
            return this.qualityIndicatorsLoaded(params);
        }
        break;
      case 'overarching_questions':
        switch (newSubmodule) {
          case 'details':
            return this.overarchingDetailsLoaded(params);
        }
        break;
      case 'key_messages':
        switch (newSubmodule) {
          case 'recommendations':
            return this.keyMessageDetailsLoaded(params);
        }
        break;
      case 'team':
        switch (newSubmodule) {
          case 'coi':
            return this.coiSubmoduleLoaded(params);
        }
    }
  };

  FluxModulesWatcher.prototype.coiSubmoduleLoaded = function(params) {
    var projectId;
    projectId = mediator.project.id;
    return QuestionsActions.fetch().then((function(_this) {
      return function() {
        return _this.submoduleListeners.push(mediator.services.storePersistenceAdapter.setupListening(projectId, {}, QuestionsActions.dbChange));
      };
    })(this));
  };

  FluxModulesWatcher.prototype.qualityIndicatorsLoaded = function(params) {
    var adapter, projectId, udbId;
    if (!mediator.services.switches.isOn('qualityIndicators')) {
      return;
    }
    projectId = mediator.project.id;
    udbId = mediator.user.id;
    adapter = mediator.services.storePersistenceAdapter;
    return W.all([
      UserProjectDataActions.fetch(projectId), QualityIndicatorsActions.fetch({
        dbId: projectId,
        docId: mediator.currentQuestionId
      })
    ]).then((function(_this) {
      return function() {
        _this.submoduleListeners.push(adapter.setupListening(udbId, {}, UserProjectDataActions.dbChange));
        return _this.submoduleListeners.push(adapter.setupListening(projectId, {}, QualityIndicatorsActions.dbChange));
      };
    })(this));
  };

  FluxModulesWatcher.prototype.mdgRecommendationsLoaded = function(params) {
    var projectId, questionId, udbId;
    projectId = mediator.project.id;
    udbId = mediator.user.id;
    questionId = params.id;
    return MdgTableActions.fetch({
      dbId: projectId,
      docId: questionId
    }).then((function(_this) {
      return function(res) {
        var questionType, recIds, recommendationAdolopmentIds;
        if (_.isEmpty(res)) {
          throw new Error("Couldn't fetch document " + projectId + "/" + questionId);
        }
        questionType = 'mdgQuestion';
        recIds = res.recommendationIds;
        recommendationAdolopmentIds = [];
        return _this._recommendationViewLoaded(projectId, udbId, questionId, questionType, recIds, recommendationAdolopmentIds);
      };
    })(this))["catch"](appUtils.errorHandler);
  };

  FluxModulesWatcher.prototype.keyMessageDetailsLoaded = function(params) {
    var projectId, questionId, udbId;
    projectId = mediator.project.id;
    udbId = mediator.user.id;
    questionId = params.id;
    return KeyMessagesActions.fetchOne(questionId).then((function(_this) {
      return function(res) {
        var questionType, recIds, recommendationAdolopmentIds;
        if (_.isEmpty(res)) {
          throw new Error("Couldn't fetch document " + projectId + "/" + questionId);
        }
        questionType = res.type;
        recIds = res.recommendationIds;
        recommendationAdolopmentIds = [];
        return _this._recommendationViewLoaded(projectId, udbId, questionId, questionType, recIds, recommendationAdolopmentIds);
      };
    })(this))["catch"](appUtils.errorHandler);
  };

  FluxModulesWatcher.prototype.overarchingDetailsLoaded = function(params) {
    var projectId, questionId, udbId;
    projectId = mediator.project.id;
    udbId = mediator.user.id;
    questionId = params.id;
    return OverarchingQuestionsActions.fetchOne(questionId).then((function(_this) {
      return function(res) {
        var questionType, recIds, recommendationAdolopmentIds;
        questionType = res.type;
        recIds = res.recommendationIds;
        recommendationAdolopmentIds = [];
        QuestionsListComponentActions.fetch();
        _this.submoduleListeners.push(mediator.services.storePersistenceAdapter.setupListening(projectId, {}, QuestionsListComponentActions.dbChange));
        return _this._recommendationViewLoaded(projectId, udbId, questionId, questionType, recIds, recommendationAdolopmentIds);
      };
    })(this))["catch"](appUtils.errorHandler);
  };

  FluxModulesWatcher.prototype._recommendationViewLoaded = function(projectId, udbId, qId, questionType, recIds, recommendationAdolopmentIds) {
    if (_.isEmpty(recIds)) {
      EtdActions.createRecommendationsTable(projectId, qId, questionType);
      this.submoduleListeners.push(mediator.services.storePersistenceAdapter.setupListening(projectId, {
        attachments: true
      }, EtdActions.dbChange));
    } else {
      mediator.services.storePersistenceAdapter.fetch(projectId, "" + projectId + ":panel-voice-metadoc").then((function(_this) {
        return function(panelVoiceMetadoc) {
          var Actions;
          Actions = _.last(recIds) in panelVoiceMetadoc.etds ? PanelVoiceEtdActions : EtdActions;
          Actions.fetch({
            dbId: projectId,
            docId: recIds
          });
          return _this.submoduleListeners.push(mediator.services.storePersistenceAdapter.setupListening(projectId, {
            attachments: true
          }, Actions.dbChange));
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          if (err.status !== 404) {
            throw err;
          }
          EtdActions.fetch({
            dbId: projectId,
            docId: recIds
          });
          return _this.submoduleListeners.push(mediator.services.storePersistenceAdapter.setupListening(projectId, {
            attachments: true
          }, EtdActions.dbChange));
        };
      })(this));
    }
    UserProjectDataActions.fetch(projectId);
    this.submoduleListeners.push(mediator.services.storePersistenceAdapter.setupListening(udbId, {}, UserProjectDataActions.dbChange));
    if (!_.isEmpty(recommendationAdolopmentIds)) {
      AdolopedEtdActions.fetch({
        dbId: projectId,
        docIds: recommendationAdolopmentIds
      });
      AdolopmentDataActions.fetchAdolopmentData();
      return this.submoduleListeners.push(mediator.services.storePersistenceAdapter.setupListening(projectId, {}, AdolopmentDataActions.dbChange));
    }
  };

  FluxModulesWatcher.prototype.recommendationSubModuleLoaded = function(params) {
    var projectId, qId, question, questionType, recIds, recommendationAdolopmentIds, udbId;
    projectId = mediator.project.id;
    udbId = mediator.user.id;
    qId = params.id;
    question = mediator.questions.get(qId);
    questionType = question.get('type');
    recIds = question.get('recommendationIds');
    recommendationAdolopmentIds = question.get('recommendationAdolopmentIds');
    return this._recommendationViewLoaded(projectId, udbId, qId, questionType, recIds, recommendationAdolopmentIds);
  };

  FluxModulesWatcher.prototype.presentationsSubModuleLoaded = function(params) {
    var projectId, qId, question, recIds, recommendationAdolopmentIds, udbId;
    projectId = mediator.project.id;
    udbId = mediator.user.id;
    qId = params.id;
    question = mediator.questions.get(qId);
    recIds = question.get('recommendationIds');
    recommendationAdolopmentIds = question.get('recommendationAdolopmentIds');
    EtdActions.fetch({
      dbId: projectId,
      docId: recIds
    });
    this.submoduleListeners.push(mediator.services.storePersistenceAdapter.setupListening(projectId, {
      attachments: true
    }, EtdActions.dbChange));
    if (!_.isEmpty(recommendationAdolopmentIds)) {
      AdolopedEtdActions.fetch({
        dbId: projectId,
        docIds: recommendationAdolopmentIds
      });
      AdolopmentDataActions.fetchAdolopmentData();
      return this.submoduleListeners.push(mediator.services.storePersistenceAdapter.setupListening(projectId, {}, AdolopmentDataActions.dbChange));
    }
  };

  FluxModulesWatcher.prototype.multiComparisonsModuleLoaded = function() {
    var MultiComparisonsActions, dbId;
    dbId = mediator.project.id;
    MultiComparisonsActions = alt.actions.MultiComparisonsActions;
    MultiComparisonsActions.fetch();
    return this.listener = mediator.services.storePersistenceAdapter.setupListening(dbId, {}, MultiComparisonsActions.dbChange);
  };

  FluxModulesWatcher.prototype.multiComparisonDetailsLoaded = function(params) {
    var projectId, udbId;
    projectId = mediator.project.id;
    udbId = mediator.user.id;
    alt.actions.MultiComparisonsActions.fetchDetails({
      mcId: params.mcId
    }).then(function(mcData) {
      var recommendationAdolopmentIds;
      recommendationAdolopmentIds = mcData.get('selectedQuestions').flatMap(function(sq) {
        return sq.get('recommendationAdolopmentIds', Immutable.List());
      });
      if (!recommendationAdolopmentIds.isEmpty()) {
        AdolopmentDataActions.fetchAdolopmentData();
        return AdolopedEtdActions.fetch({
          dbId: projectId,
          docIds: recommendationAdolopmentIds.toJS()
        });
      }
    });
    UserProjectDataActions.fetch(projectId);
    return this.submoduleListeners.push(mediator.services.storePersistenceAdapter.setupListening(udbId, {}, UserProjectDataActions.dbChange));
  };

  return FluxModulesWatcher;

})();
