AddMemberButton = require 'components/team/add_member_button'
{ COI_PROJECT_ID } = require 'lib/mdg_helper'
ConnectStores = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EditableMemberRow = require 'components/team/form/editable_member_row'
ImmutableUtils = require 'lib/immutable_utils'
LimitsWarning = require 'components/common/limits_warning'
mediator = require 'mediator'
Modal = require 'components/common/modal'
MemberInputItem = require 'components/team/form/member_input_item'
MembersActions = require 'actions/members_actions'
MembersStore = require 'stores/members_store'
MembersLimitsInfo = require 'components/team/members_limits_info'
OrganizationActions = require 'actions/organizations_actions'
OrganizationMemberFormRow = require 'components/team/form/organization_member_form_row'
OrganizationsStore = require 'stores/organizations_store'
Scrollable = require 'components/common/scrollable'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
withLimits = require 'components/enhancers/with_limits'
{ StepsContainer, Step} = require 'components/common/steps'
{
  filterAdminsAndResearchers
  getMembersWithPendingInvitationsCount
  parseTeamMembers
} = require 'lib/members_helper'
{ isValidLimit } = require 'lib/limits_helper'


AddOrganizationMember = createReactClass

  displayName: 'AddOrganizationMember'

  mixins: [
    CustomRenderMixin
    Translation('team:member')
  ]

  propTypes:
    isFetching: PropTypes.bool.isRequired,
    initialOrganizationMembers: PropTypes.instanceOf(Immutable.List).isRequired,
    currentMembers: PropTypes.instanceOf(Immutable.Map).isRequired,
    limits: PropTypes.instanceOf(Immutable.Map).isRequired

  getInitialState: ->
    currentStep: "members-checklist"
    errors: @props.errors
    organizationMembers: Immutable.List()
    panelMembersList: ""
    panelMembers: Immutable.List()
    selectedMembers: Immutable.List()
    showNewMembersTextarea: false
    panelMemberStringList: ""

  componentDidMount: ->
    OrganizationActions.fetchOrganizationMembers @props.organizationId

  componentWillReceiveProps: (nextProps) ->
    unless nextProps.initialOrganizationMembers.equals @state.organizationMembers
      { currentMembers } = @props
      currentMembersEmails = ImmutableUtils.pluck(currentMembers, 'email').toList()
      filtered = nextProps.initialOrganizationMembers.filterNot (m) ->
        currentMembersEmails.contains m.get('_id')
      @setState organizationMembers: filtered

    @setState errors: nextProps.errors, =>
      unless nextProps.errors.isEmpty()
        invalidElements = @listContainer.getElementsByClassName('invalid')
        return if _.isEmpty invalidElements
        $(invalidElements[0]).closest('.new-member')[0]?.scrollIntoView(true)

  allMembersSelected: ->
    @props.initialOrganizationMembers.size is @state.selectedMembers.size

  isMemberSelected: (member) ->
    @state.selectedMembers.contains member.get('_id')

  _getTotalMembersCount: ->
    { currentMembers } = @props
    membersToAddCount = @state.selectedMembers.size + (@state.panelMembers?.size or 0)
    currentMembersCount = currentMembers.size
    membersToAddCount + currentMembersCount

  _getTotalAdminsCount: ->
    { currentMembers } = @props
    currentAdminsCount = filterAdminsAndResearchers(currentMembers).size
    pendingInvitationsCount = getMembersWithPendingInvitationsCount currentMembers
    organizationMembersToAdd = @state.organizationMembers.filter(@isMemberSelected)
    adminsToAddCount = filterAdminsAndResearchers(organizationMembersToAdd).size
    adminsToAddCount + currentAdminsCount + pendingInvitationsCount

  isNextStepEnabled: (step) ->
    { limits } = @props
    adminsLimit = limits.get('teamAdmins')
    membersLimit = limits.get('teamMembers')

    switch @state.currentStep
      when 'members-checklist'
        (not @state.selectedMembers.isEmpty() or not _.isEmpty(@state.panelMemberStringList)) and
        isValidLimit(@_getTotalMembersCount(), membersLimit, 'lte')
      when 'members-list'
        isValidLimit(@_getTotalMembersCount(), membersLimit, 'lte') and
        isValidLimit(@_getTotalAdminsCount(), adminsLimit, 'lte')

  goToStep: (step) ->
    @setState currentStep: step

  onApply: ->
    { organizationMembers, selectedMembers, panelMembers } = @state

    return if selectedMembers.isEmpty() and panelMembers.isEmpty()

    organizationMembersToAdd = organizationMembers.filter @isMemberSelected

    @props.onApply organizationMembersToAdd, panelMembers

  onChangeMember: (e) ->
    memberId = e.target.value
    selectedMembers = if @state.selectedMembers.contains memberId
      @state.selectedMembers.delete(@state.selectedMembers.indexOf(memberId))
    else
      @state.selectedMembers.push memberId

    @setState selectedMembers: selectedMembers

  onChangeTeamMembersList: (e) -> @setState panelMemberStringList: e.target.value

  onRemoveMember: (member) ->
    panelMembers = @state.panelMembers.filterNot (m) -> m is member
    errors = @state.errors.delete member.get('_id')
    # Have to update errors in store
    MembersActions.updateValidationErrors errors
    @setState { panelMembers, errors }

  parseTeamMembers: ->
    panelMembers = parseTeamMembers @state.panelMemberStringList
    panelMembers = panelMembers.map (pm) ->
      pm.withMutations (m) ->
        m.set('accessRights', Immutable.List(['no_access']))
        m.set('panelMember', true)
    @setState { panelMembers }
    @goToStep 'members-list'

  toggleAll: ->
    @setState selectedMembers: if @allMembersSelected()
      Immutable.List()
    else
      ImmutableUtils.pluck @props.initialOrganizationMembers, '_id'

  toggleNewMembersTextarea: ->
    @setState showNewMembersTextarea: not @state.showNewMembersTextarea

  updateMembersList: (listKey, member, attr, value) ->
    list = @state[listKey].map (m) ->
      return m if m.get('_id') isnt member.get('_id')
      m.set(attr, value)

    # clear validation error on update field
    errors = @state.errors.update (member.get('_id')), (errors) ->
      errors.delete(attr) if errors
    @setState { "#{listKey}": list, errors: errors }

  _refListContainer: (e) -> @listContainer = e

  getDisabledAccessRights: ->
    { limits } = @props
    admins = limits.get('teamAdmins')
    if isValidLimit(@_getTotalAdminsCount(), admins, 'lt') then [] else ['admin', 'researcher']

  render: ->

    { organizationMembers, selectedMembers, showNewMembersTextarea } = @state
    { limits, currentMembers } = @props

    membersLimit = limits.get('teamMembers')
    adminsLimit = limits.get('teamAdmins')

    currentMembersCount = currentMembers.size
    currentAdminsCount = filterAdminsAndResearchers(currentMembers).size
    totalAdminsCount = @_getTotalAdminsCount()
    totalMembersCount = @_getTotalMembersCount()
    disabledAccessRights = @getDisabledAccessRights()
    checkboxDisabled = not isValidLimit(totalMembersCount, membersLimit)
    panelMembersEnabled = not mediator.services.switches.isServerSwitchOn('mdgFeatures') or
      mediator.project.id is COI_PROJECT_ID

    <Modal
      className="add-organization-member-modal multiple"
      isOpen={true}
      closeButton={true}
      modalSize="medium"
      title={@i18n 'team_members_and_panel_members'}
      onClose={@props.onCancel}
    >
      <StepsContainer
        applyLabel={@i18n "/actions.apply"}
        cancelLabel={@i18n "/actions.cancel"}
        isNextStepEnabled={@isNextStepEnabled(@state.currentStep)}
        currentStep={@state.currentStep}
      >
        <Step
          stepKey="members-checklist"
          stepLabel={@i18n 'add_multiple.step1'}
          nextLabel={@i18n "/actions.next"}
          onNext={@parseTeamMembers}
          onBack={@props.onCancel}
        >
          {if @props.isFetching
            <Spinner />
          else
            <div className="step1">
              <MembersLimitsInfo
                adminsCount={currentAdminsCount}
                i18n={@i18n}
                limits={limits}
                membersCount={currentMembersCount}
              />
              <LimitsWarning
                limit={membersLimit}
                message={@i18n '../limits.members_limit_reached'}
                value={@_getTotalMembersCount()}
              />
              <h3>{@i18n 'select_members_from_organization'}</h3>
              <div className="select-all checkbox">
                <label>
                  <input
                    checked={@allMembersSelected()}
                    disabled={checkboxDisabled unless @allMembersSelected()}
                    className="select-all"
                    onChange={@toggleAll}
                    type="checkbox"
                  />
                  {@i18n "/actions.select_all"}
                </label>
              </div>
              <div className="organization-members">
                <Scrollable>
                  {organizationMembers.map (member) =>
                    <MemberInputItem
                      checked={@isMemberSelected member}
                      className="member-row-with-checkbox"
                      disabled={checkboxDisabled unless @isMemberSelected member}
                      key={member.get('_id')}
                      member={member}
                      onChange={@onChangeMember}
                      type="checkbox"
                    />
                  .toList()}
                </Scrollable>
              </div>
              {panelMembersEnabled and <React.Fragment>
                <AddMemberButton
                  btnLabel={@i18n if showNewMembersTextarea then 'hide' else 'add_panel_members'}
                  disabled={checkboxDisabled}
                  i18n={@i18n}
                  onClick={@toggleNewMembersTextarea}
                />
                {showNewMembersTextarea and
                  <div>
                    <h3>{@i18n 'panel_members_from_outside_of_organization'}</h3>
                    <div>{@i18n 'add_multiple.description'}</div>
                    <div className="textarea-container">
                      <textarea
                        onChange={@onChangeTeamMembersList}
                        defaultValue={@state.panelMemberStringList}
                        placeholder={@i18n 'add_multiple.placeholder'}
                        autoFocus={true}
                      />
                    </div>
                  </div>
                }
              </React.Fragment>}
            </div>
          }
        </Step>
        <Step
          stepKey="members-list"
          stepLabel={@i18n 'add_multiple.step2'}
          onNext={@onApply}
          onBack={@props.onCancel}
        >
          <div className="step2" ref={@_refListContainer}>
            <MembersLimitsInfo
              adminsCount={currentAdminsCount}
              i18n={@i18n}
              limits={limits}
              membersCount={currentMembersCount}
            />
            <LimitsWarning
              limit={membersLimit}
              message={@i18n '../limits.members_limit_reached'}
              value={totalMembersCount}
            />
            <LimitsWarning
              limit={adminsLimit}
              message={@i18n '../limits.admins_limit_reached'}
              value={totalAdminsCount}
            />
            {not selectedMembers.isEmpty() and
              <div>
                <h3>{@i18n 'members_from_organization'}</h3>
                <div className="members-list">
                  <Scrollable>
                    {@state.organizationMembers.filter @isMemberSelected
                    .map (member) =>
                      <OrganizationMemberFormRow
                        adminsLimit={adminsLimit}
                        disabledAccessRights={disabledAccessRights}
                        errors={@state.errors?.get(member.get('_id'))}
                        key={member.get('_id')}
                        member={member}
                        removeMember={@onRemoveMember.bind(this, member)}
                        updateAttr={@updateMembersList.bind(this, 'organizationMembers', member)}
                      />
                    }
                  </Scrollable>
                </div>
              </div>
            }
            {not @state.panelMembers.isEmpty() and
              <div className="other-members">
                <div className="panel-member-separator" />
                <h3>{@i18n 'panel_members_from_outside_of_organization'}</h3>
                <div className="members-list">
                  <Scrollable>
                    {@state.panelMembers.map((member) =>
                      <EditableMemberRow
                        adminsLimit={adminsLimit}
                        errors={@state.errors?.get(member.get('_id'))}
                        fieldsToEdit={Immutable.List(['email', 'title', 'lastName', 'givenNames'])}
                        key={member.get('_id')}
                        member={member}
                        removeMember={@onRemoveMember.bind(this, member)}
                        updateAttr={@updateMembersList.bind(this, 'panelMembers', member)}
                      />
                    ).toList()}
                  </Scrollable>
                </div>
              </div>
            }
          </div>
        </Step>
      </StepsContainer>
    </Modal>

storeConnectors =
  OrganizationsStore: (Store) ->
    isFetching: Store.isFetchingMembers()
    initialOrganizationMembers: Store.getOrganizationMembers()
  MembersStore: (Store) ->
    currentMembers: Store.getMembers()

module.exports = withLimits(ConnectStores AddOrganizationMember,
  [MembersStore, OrganizationsStore], storeConnectors)
