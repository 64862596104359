CommentButton = require 'components/common/empty_comment_button'
Question = require 'components/scope/questions/question'
CommentsBlock = require 'components/scope/comments_block'
CommentsBlocks = require 'components/scope/questions/comments_blocks'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
QuestionCommentsMixin = require 'components/mixins/question_comments_mixin'
{ instanceOf, bool, func } = PropTypes

QuestionWithComments = createReactClass
  displayName: 'QuestionWithComments'

  propTypes:
    data: instanceOf(Immutable.Map).isRequired
    memberComments: instanceOf(Immutable.Map).isRequired
    adminComments: instanceOf(Immutable.Map).isRequired
    membersMap: instanceOf(Immutable.OrderedMap).isRequired
    readOnly: bool.isRequired
    onCommentToggle: func.isRequired
    onApplyAdminComment: func

  mixins: [
    CustomRenderMixin
    Translation('scope:questions')
    QuestionCommentsMixin
  ]

  renderCommentsToggle: ->
    buttonClass = classNames 'comment',
      'comment-blank-hide': @state.showingComments
      'comment-blank': not @state.showingComments

    <CommentButton
      className={buttonClass}
      title={@i18n '../outcomes.view_members_comments'}
      onClick={@toggleComments}
    />

  render: ->
    if @state.showingComments
      <div style={position: 'relative'}>
        {@renderCommentsToggle()}
        <Question text={@props.data.get 'question'} />
        <CommentsBlocks
          memberComments={@props.memberComments}
          adminComments={@props.adminComments}
          membersMap={@props.membersMap}
          readOnly={@props.readOnly}
          onApplyAdminComment={@onApplyAdminComment}
        />
      </div>
    else
      <div>
        {@renderCommentsToggle()}
        <div className='caption'>
          <Question text={@props.data.get 'question'} />
        </div>
      </div>

module.exports = QuestionWithComments
