var ReactComponent, Sidebar, SidebarView, View, getModules, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

Sidebar = require('components/sidebar/sidebar').Sidebar;

mediator = require('mediator');

getModules = require('lib/sidebar_helper').getModules;

module.exports = SidebarView = (function(_super) {
  __extends(SidebarView, _super);

  function SidebarView() {
    return SidebarView.__super__.constructor.apply(this, arguments);
  }

  SidebarView.prototype.container = '#sidebar-container';

  SidebarView.prototype.autoRender = true;

  SidebarView.prototype.initialize = function() {
    SidebarView.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    this.subscribeEvent('projectChanged', function() {
      this.currentQuestionId = null;
      this.currentSubmodule = null;
      return this.render();
    });
    this.subscribeEvent('moduleChanged', function(module) {
      this.currentQuestionId = null;
      this.currentSubmodule = null;
      this.currentModule = module;
      return this.render();
    });
    this.subscribeEvent('submoduleChanged', function(submodule, params) {
      if (this.currentModule === 'evidence_syntheses') {
        this.currentQuestionId = params.id;
      }
      this.currentSubmodule = submodule;
      return this.render();
    });
    this.subscribeEvent('questionChanged', function(questionId) {
      this.currentQuestionId = questionId;
      return this.render();
    });
    return this.subscribeEvent('modulesChanged', this.render);
  };

  SidebarView.prototype.afterRender = function() {
    SidebarView.__super__.afterRender.apply(this, arguments);
    return getModules(this.currentModule, this.currentQuestionId, this.currentSubmodule).then((function(_this) {
      return function(modules) {
        return _this.renderSimpleComponent(Sidebar, {
          currentModule: _this.currentModule,
          currentQuestionId: _this.currentQuestionId,
          modules: modules
        }, document.getElementById('sidebar-container'));
      };
    })(this));
  };

  SidebarView.prototype.dispose = function() {
    this.unmountComponent();
    return SidebarView.__super__.dispose.apply(this, arguments);
  };

  return SidebarView;

})(View);
