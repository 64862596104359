var PanelVoice, PanelVoiceActions, PanelVoiceMarketingScreen, ReactComponent, ReactToolbarView, View, Voting, VotingActions, VotingToolbar, VotingView, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

Voting = require('components/administration/voting/voting');

PanelVoice = require('components/panel_voice/panel_voice');

VotingActions = require('actions/voting_actions');

PanelVoiceActions = require('actions/panel_voice_actions');

VotingToolbar = require('components/etd/voting_toolbar');

ReactToolbarView = require('views/react_toolbar_view');

ReactComponent = require('base/lib/traits/react_component');

PanelVoiceMarketingScreen = require('components/panel_voice/panel_voice_marketing_screen');

mediator = require('mediator');

alt = require('alt');

module.exports = VotingView = (function(_super) {
  __extends(VotingView, _super);

  function VotingView() {
    return VotingView.__super__.constructor.apply(this, arguments);
  }

  VotingView.prototype.container = '#page-container';

  VotingView.prototype.className = 'voting-view';

  VotingView.prototype.autoRender = true;

  VotingView.prototype.initialize = function() {
    this._stopInputsPropagation = false;
    VotingView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  VotingView.prototype.afterRender = function() {
    VotingView.__super__.afterRender.apply(this, arguments);
    if (mediator.services.switches.isOn('voting') || mediator.colls.teamMembers.getMembersCount() < 4) {
      return mediator.services.storePersistenceAdapter.exists(mediator.project.id, 'etd-voting-preferences').then((function(_this) {
        return function(oldVoting) {
          if (oldVoting) {
            _this.votingVersion = 'old';
            _this.subview('toolbar', new ReactToolbarView({
              component: VotingToolbar
            }));
            return _this.renderComponent(Voting, VotingActions, null, {
              fetchAndListen: {
                dbId: mediator.project.id
              }
            });
          } else {
            _this.votingVersion = 'new';
            return _this.renderComponent(PanelVoice, PanelVoiceActions, null, {
              props: {
                onboardingAutostart: _this.options.onboardingAutostart
              },
              fetchAndListen: {
                dbId: mediator.project.id
              }
            });
          }
        };
      })(this))["catch"](function(err) {
        throw err;
      });
    } else {
      return this.renderSimpleComponent(PanelVoiceMarketingScreen);
    }
  };

  VotingView.prototype.dispose = function() {
    var _ref;
    this.unmountComponent();
    if (((_ref = window.hopscotch.getCurrTour()) != null ? _ref.id : void 0) === 'panel-voice-onboarding') {
      window.hopscotch.endTour();
    }
    switch (this.votingVersion) {
      case 'old':
        alt.recycle('EtdsStore', 'MembersStore', 'VotingStore', 'QuestionsStore');
        break;
      case 'new':
        alt.recycle('PanelVoiceStore', 'QuestionsStore', 'QuestionGroupsStore', 'MembersStore', 'QuestionsListComponentStore', 'OverarchingQuestionsStore');
    }
    return VotingView.__super__.dispose.apply(this, arguments);
  };

  return VotingView;

})(View);
