Translation = require 'components/mixins/translation'
Button = require 'components/common/button'

EditableItem =
  Editing: createReactClass
    propTypes:
      children: PropTypes.element.isRequired

    mixins: [Translation('')]

    displayName: 'Editing'

    getDefaultProps: ->
      renderSave: true
      renderDelete: true

    render: ->
      <div>
        {React.Children.only @props.children}
        {if @props.renderSave or @props.renderDelete
          <div className="side-buttons">
            {if @props.renderSave
              <Button
                className="save"
                title={@i18n 'actions.save_changes_title'}
                onClick={@props.save}/>
            }
            {if @props.renderDelete
              <Button
              className="delete"
              title={@i18n 'actions.delete'}
              onClick={@props.delete}/>
            }
          </div>
        }
      </div>

  Static: createReactClass
    propTypes:
      children: PropTypes.element.isRequired

    mixins: [Translation('')]

    displayName: 'Static'

    render: ->
      <div>
        <Button className="edit" title={@i18n 'actions.edit'} onClick={@props.edit}/>
        <div className="caption">
          {React.Children.only @props.children}
        </div>
      </div>

  Item: createReactClass
    propTypes:
      children: PropTypes.array.isRequired
      edit: PropTypes.func.isRequired
      save: PropTypes.func
      delete: PropTypes.func
      cancel: PropTypes.func

    displayName: 'EditableItem'

    save: ->
      @props.save @props.item

    delete: ->
      @props.delete @props.itemKey

    edit: ->
      @props.edit @props.itemKey

    cancel: ->
      @props.cancel @props.itemKey

    getEditingComponent: ->
      React.Children.map @props.children, (child) =>
        if child.type.displayName is 'Editing'
          React.cloneElement child, {@save, @delete}

    getStaticComponent: ->
      React.Children.map @props.children, (child) =>
        if child.type.displayName is 'Static'
          React.cloneElement child, {@edit}

    render: ->
      liClasses = classNames expanded: @props.editing
      <li className={liClasses}>
        {if @props.editing
          @getEditingComponent()
        else
          @getStaticComponent()
        }
      </li>

module.exports = EditableItem
