var DbDoc, PUBLICATION_PLATFORM_PUBLICATION_STATUS_DOC_ID_ID, PublicationPlatformStatusDocShape;

DbDoc = require('lib/db_docs/db_doc');

PublicationPlatformStatusDocShape = require('lib/db_docs/doc_shapes/publication_platform_status_doc_shape');

PUBLICATION_PLATFORM_PUBLICATION_STATUS_DOC_ID_ID = require('lib/doc_ids').PUBLICATION_PLATFORM_PUBLICATION_STATUS;

module.exports = DbDoc(PublicationPlatformStatusDocShape, {
  dbSetup: {
    docId: PUBLICATION_PLATFORM_PUBLICATION_STATUS_DOC_ID_ID
  },
  methods: {
    removeQuestionAsPending: function(questionId) {
      return this.updateAt('questionsPending')(function(currentIds) {
        if (currentIds == null) {
          currentIds = [];
        }
        return _.without(currentIds, questionId);
      });
    },
    setQuestionsAsPending: function(questionIds) {
      return this.updateAt('questionsPending')(function(currentIds) {
        if (currentIds == null) {
          currentIds = [];
        }
        return _.uniq(_.union(currentIds, questionIds));
      });
    },
    updateGuidelinePublicationDate: function(publicationDate, questionIds) {
      return _.reduce(questionIds, function(acc, questionId) {
        return acc.setAt('lastQuestionsPublicationDate', questionId)(publicationDate);
      }, this.setAt('lastGuidelinePublicationDate')(publicationDate));
    },
    updateQuestionPublicationDate: function(publicationDate, questionId) {
      return this.setAt('lastQuestionsPublicationDate', questionId)(publicationDate);
    }
  }
});
