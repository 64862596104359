var Migration, PatientsTypePresentationChange, getUpdatedPatientsSections, mediator, migrationName, updateTemplateData, updateTemplateDefinitions,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

migrationName = 'patients_type_presentation_change';

getUpdatedPatientsSections = function(currentPatientSections) {
  var _ref, _ref1;
  return {
    whyThisRecommendation: {
      name: 'Why this recommendation?',
      parts: {
        whyThisRecommendation: {
          name: 'Why this recommendation',
          content: ''
        },
        benefitsAndHarms: {
          name: 'Benefits and Harms',
          content: ''
        },
        additionalInformation: {
          name: 'Additional information',
          content: ''
        }
      }
    },
    whatItMeansForYou: {
      name: 'What does this mean for you?',
      parts: {
        whatYouCanDo: {
          name: 'What you can do',
          content: (_ref = currentPatientSections.whatItMeansForYou.content) != null ? _ref : ''
        },
        speakWithHCProfessional: {
          name: 'Speak with your health care professional',
          content: '',
          additionalFields: [
            {
              content: ''
            }, {
              content: ''
            }
          ]
        }
      }
    },
    whoIsThisFor: {
      name: 'Who is this for?',
      parts: {
        whoIsThisFor: {
          name: 'Who is this for',
          content: (_ref1 = currentPatientSections.whoIsThisFor.content) != null ? _ref1 : '',
          additionalFields: [
            {
              content: ''
            }
          ]
        }
      }
    },
    summaryOfFindings: {
      name: 'Summary of findings'
    }
  };
};

updateTemplateDefinitions = function(definitions) {
  definitions.presentations = _.map(definitions.presentations, function(presentationSpec) {
    if (presentationSpec.id !== 'patients') {
      return presentationSpec;
    }
    presentationSpec.sections = _.map(presentationSpec.sections, function(section) {
      if (section.id !== 'justification') {
        return section;
      }
      return _.extend(section, {
        id: 'whyThisRecommendation'
      });
    });
    return presentationSpec;
  });
  return definitions;
};

updateTemplateData = function(data) {
  var currentPatientsData, updatedSections, updatedSectionsOrder;
  currentPatientsData = data.presentations.sections.patients;
  updatedSectionsOrder = _.map(currentPatientsData.sectionsOrder, function(sectionName) {
    if (sectionName === 'justification') {
      return 'whyThisRecommendation';
    } else {
      return sectionName;
    }
  });
  updatedSections = getUpdatedPatientsSections(currentPatientsData.sections);
  data.presentations.sections.patients = _.extend(currentPatientsData, {
    sectionsOrder: updatedSectionsOrder,
    sections: updatedSections
  });
  return data;
};

module.exports = PatientsTypePresentationChange = (function(_super) {
  __extends(PatientsTypePresentationChange, _super);

  function PatientsTypePresentationChange() {
    PatientsTypePresentationChange.__super__.constructor.call(this, false);
  }

  PatientsTypePresentationChange.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    PatientsTypePresentationChange.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds'));
    }, ['etd-tx-template', 'etd-dx-template']);
    return adapter.fetch(projectId, docIds).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return;
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        return _.extend(doc, {
          rev_author: 'project_migration/#{migrationName}',
          templateDef: updateTemplateDefinitions(doc.templateDef),
          templateData: updateTemplateData(doc.templateData)
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return PatientsTypePresentationChange;

})(Migration);
