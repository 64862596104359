var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  <tr>\n    <td class=\"centered outcome-name no-right-border\">\n      <div class=\"cell-label outcome-label\">"
    + escapeExpression(((helper = (helper = helpers.outcomeName || (depth0 != null ? depth0.outcomeName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"outcomeName","hash":{},"data":data}) : helper)))
    + "</div>\n    </td>\n    <td class=\"zero-padding\">\n      "
    + escapeExpression(((helpers.outcomeCommentsTable || (depth0 && depth0.outcomeCommentsTable) || helperMissing).call(depth0, "approval", depth0, {"name":"outcomeCommentsTable","hash":{},"data":data})))
    + "\n      "
    + escapeExpression(((helpers.outcomeCommentsTable || (depth0 && depth0.outcomeCommentsTable) || helperMissing).call(depth0, "rating", depth0, {"name":"outcomeCommentsTable","hash":{},"data":data})))
    + "\n      "
    + escapeExpression(((helpers.outcomeCommentsTable || (depth0 && depth0.outcomeCommentsTable) || helperMissing).call(depth0, "brainstorming", depth0, {"name":"outcomeCommentsTable","hash":{},"data":data})))
    + "\n    </td>\n  </tr>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.commentsOnly : depth0), {"name":"unless","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <tr>\n      <td class=\"centered outcome-name no-right-border\">\n        <div class=\"cell-label outcome-label\">"
    + escapeExpression(((helper = (helper = helpers.outcomeName || (depth0 != null ? depth0.outcomeName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"outcomeName","hash":{},"data":data}) : helper)))
    + "</div>\n      </td>\n      <td class=\"no-comments\">\n        <div class=\"cell-value\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "scope:outcomes.no_comments", {"name":"i18n","hash":{},"data":data})))
    + "\n        </div>\n      </td>\n    </tr>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasComments : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}