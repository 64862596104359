Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ExportModal = require 'components/overarching_questions/export_modal'
ToolbarQuestionsList =   require 'components/evidence_syntheses/toolbar_questions_list'
Translation = require 'components/mixins/translation'
OverarchingQuestionsStore = require 'stores/overarching_questions_store'
OverarchingQuestionsActions = require 'actions/overarching_questions_actions'
PanelVoiceStore = require 'stores/panel_voice_store'
PanelVoiceEtdStore = require 'stores/panel_voice_etd_store'

storeConnector =
  OverarchingQuestionsStore: (Store) ->
    isExportModalOpen: Store.isExportModalOpen()
    currentQuestion: Store.getCurrentQuestion()

OverarchingToolbar = createReactClass

  displayName: "OverarchingToolbar"

  mixins: [Translation(''), CustomRenderMixin]

  propTypes:
    isExportModalOpen: PropTypes.bool.isRequired

  render: ->
    { isExportModalOpen, currentQuestion } = @props

    <div className="flex">
      <div className="questions flex-grow relative items-center">
        <ToolbarQuestionsList
          currentQuestion={currentQuestion.get('question', '')}
          questionsCollectionType="regular"
        />
      </div>
      <div className="button-export-container mr-10">
        <Button
          className='export'
          title={@i18n 'actions.export'}
          onClick={OverarchingQuestionsActions.showExportDialog}
          disabled={isExportModalOpen}
        />
      </div>
      {isExportModalOpen and <ExportModal
        currentQuestion={currentQuestion}
        onClose={OverarchingQuestionsActions.hideExportDialog}
      />}
    </div>

module.exports = ConnectStore(
  OverarchingToolbar,
  [ OverarchingQuestionsStore],
  storeConnector
)
