DatePicker = require 'components/common/date_picker'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'
{ useState } = React

maybeDateFormatter = (date) ->
  return '' unless date
  moment(date).format('MM/DD/YYYY')

SearchDateField = ({ onChange, value }) ->

  [ validationError, setValidationError ] = useState()
  i18n = useI18n()

  onDateSave = useCoffeeCallback [onChange, value], (field) -> (_e, newDate) ->
    obj = {}
    obj[field] = newDate.toISOString()
    valueToCompare = value[if field is 'to' then 'from' else 'to']
    compareFunc = if field is 'from' then 'isAfter' else 'isBefore'
    if obj[field] and valueToCompare
      if moment(valueToCompare)[compareFunc] moment(obj[field])
        onChange _.extend {}, value, obj
        setValidationError null
      else
        setValidationError i18n 'messages.date_range_invalid'
    else
      onChange _.extend {}, value, obj

  <React.Fragment>
    <div className="search-date-field">
      <div className="search-date-field__from">
        <DatePicker
          onSave={onDateSave 'from'}
          placeholder='MM/DD/YYYY'
          value={maybeDateFormatter value?.from}
        />
      </div>
      <div className="search-date-field__separator">{'-'}</div>
      <div className="search-date-field__to">
        <DatePicker
          onSave={onDateSave 'to'}
          placeholder='MM/DD/YYYY'
          value={maybeDateFormatter value?.to}
        />
    </div>
    </div>
    {validationError and <div className="error-message">
      {validationError}
    </div>}
  </React.Fragment>

SearchDateField.propTypes =
  onChange: PropTypes.func.isRequired
  value: PropTypes.shape(
    from: PropTypes.string.isRequired
    to: PropTypes.string.isRequired
  ).isRequired

module.exports = SearchDateField
