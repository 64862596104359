HorizontalRadios = require './horizontal_radios'
EtdActions = require 'actions/etd_actions'

GeneralQuestions = createReactClass
  displayName: 'DetailedJudgementsGeneralQuestions'

  propTypes:
    sectionId: PropTypes.string.isRequired
    groupPrefix: PropTypes.string.isRequired
    questions: PropTypes.object.isRequired

  changeRadioOption: (questionIdx) -> (value) =>
    EtdActions.updateDetailedGeneralQuestion @props.sectionId, questionIdx, value,
      @props.isConsensus

  _getSelectedOptionForQuestion: (question) ->
    if @props.isConsensus
      # passing draft_judgement selected option as a notSetValue parameter here in case consenus
      # value is not set yet
      question.get('selectedOptionConsensus', question.get 'selectedOption')
    else
      question.get 'selectedOption'

  render: ->
    <div>
      {@props.questions.map (question, idx) =>
        <div key={idx}>
        <div dangerouslySetInnerHTML={{__html: question.get 'question'}} />
        <HorizontalRadios
          editable={@props.editable}
          groupName={"#{@props.groupPrefix}_general_quesitons_#{idx}"}
          options={question.get 'options', Immutable.List()}
          additionalOptions={question.get 'additionalOptions', Immutable.List()}
          selectedOption={@_getSelectedOptionForQuestion question}
          onChange={@changeRadioOption idx}
        />
        </div>
      }
    </div>

module.exports = GeneralQuestions
