var ProjectPermissionsService, ProjectsHelper, ProjectsStore, W, mediator;

mediator = require('mediator');

W = require('when');

ProjectsHelper = require('actions/async/projects');

ProjectsStore = require('stores/projects_store');

module.exports = ProjectPermissionsService = (function() {
  function ProjectPermissionsService() {}

  ProjectPermissionsService.prototype.isOrganizationAdmin = function(organizationId) {
    var _ref, _ref1;
    return (_ref = (_ref1 = mediator.userOrganizations[organizationId]) != null ? _ref1.isAdmin : void 0) != null ? _ref : false;
  };

  ProjectPermissionsService.prototype._getProjectAccessRights = function(projectId, organizationId) {
    var accessRights;
    accessRights = ProjectsStore.getProject(projectId, organizationId).get('accessRights');
    if (accessRights != null) {
      return W(accessRights);
    }
    return ProjectsHelper.getProjectAccessRights(projectId).then(Immutable.fromJS);
  };

  ProjectPermissionsService.prototype._getProjectOrganizationId = function(projectId) {
    return mediator.services.storePersistenceAdapter.fetch(projectId, projectId).then(R.prop('organizationId'));
  };

  ProjectPermissionsService.prototype._getProjectState = function(projectId) {
    var project;
    project = mediator.user.get('projects').find(function(p) {
      return p.name === projectId;
    });
    if (!project) {
      throw new Error("Cannot find project " + projectId);
    }
    if (project.isArchived) {
      return 'archived';
    } else if (project.isCopy) {
      return 'copy';
    } else {
      return 'active';
    }
  };

  ProjectPermissionsService.prototype._isOrganizationAdminInAnyOrganization = function() {
    return _(mediator.userOrganizations).any(function(organization) {
      return organization.isAdmin === true;
    });
  };

  ProjectPermissionsService.prototype.canCreateProject = function(organizationId) {
    return (organizationId == null) || this.isOrganizationAdmin(organizationId);
  };

  ProjectPermissionsService.prototype.getPossibleActionsForProject = function(projectId) {
    return this._getProjectOrganizationId(projectId).then((function(_this) {
      return function(organizationId) {
        return W.all([_this._getProjectAccessRights(projectId, organizationId), W(organizationId), W(_this._getProjectState(projectId))]);
      };
    })(this)).then((function(_this) {
      return function(_arg) {
        var accessRights, globalExportDisabledForMdg, isOrganizationAdmin, organizationId, permissions, permittedActions, projectState;
        accessRights = _arg[0], organizationId = _arg[1], projectState = _arg[2];
        isOrganizationAdmin = _this.isOrganizationAdmin(organizationId);
        permittedActions = (function() {
          switch (projectState) {
            case 'active':
              if (organizationId != null) {
                if (isOrganizationAdmin) {
                  return ['edit', 'delete', 'archive', 'copy', 'sendCopy', 'createAdolopment', 'globalExport'];
                } else if (accessRights.contains('admin')) {
                  return ['edit', 'delete', 'archive', 'copy', 'globalExport'];
                } else {
                  return ['delete', 'archive', 'copy', 'globalExport'];
                }
              } else {
                if (accessRights.contains('admin')) {
                  permissions = ['edit', 'delete', 'archive', 'copy', 'sendCopy', 'createAdolopment'];
                  if (this._isOrganizationAdminInAnyOrganization()) {
                    permissions = permissions.concat('moveToOrganization');
                  }
                  return permissions.concat('globalExport');
                } else {
                  return ['delete', 'archive', 'globalExport'];
                }
              }
              break;
            case 'copy':
              if (organizationId != null) {
                if (isOrganizationAdmin) {
                  return ['delete', 'makeActive', 'globalExport'];
                } else {
                  return ['delete', 'globalExport'];
                }
              } else {
                return ['delete', 'makeActive', 'globalExport'];
              }
              break;
            case 'archived':
              return ['delete', 'moveToActive', 'globalExport'];
            default:
              throw new Error("Project " + projectId + " has unknown status " + projectState);
          }
        }).call(_this);
        if (!mediator.services.switches.isOn('adolopment')) {
          permittedActions = _(permittedActions).reject(function(a) {
            return a === 'createAdolopment';
          });
        }
        globalExportDisabledForMdg = mediator.services.switches.isOn('mdgTables');
        if (globalExportDisabledForMdg) {
          permittedActions = _(permittedActions).reject(function(a) {
            return a === 'globalExport';
          });
        }
        return permittedActions;
      };
    })(this));
  };

  return ProjectPermissionsService;

})();
