var DbHelper, Exceptions, Model, Requests, UdbService, User, W, organizationPermissionsDocPrefix, poll,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

Exceptions = require('lib/exceptions');

User = require('models/user');

Requests = require('models/requests');

Model = require('models/base/model');

DbHelper = require('base/lib/db_helper');

W = require('when');

poll = require('when/poll');

organizationPermissionsDocPrefix = require('lib/doc_prefixes').organizationPermissions;

module.exports = UdbService = (function() {
  function UdbService() {
    this._triggerInitialReplication = __bind(this._triggerInitialReplication, this);
  }

  UdbService.prototype.retryTimeout = null;

  UdbService.prototype.isInitialized = function(username) {
    var p;
    p = DbHelper.localReplica(this.getUdbName(username));
    return W(p.get(this.getUdbName(username)).then((function() {
      return true;
    }), function(error) {
      if (error.status === 404) {
        return false;
      } else {
        throw error;
      }
    }));
  };

  UdbService.prototype.initialize = function(username) {
    return W.promise((function(_this) {
      return function(resolve, reject, notify) {
        return _this.isInitialized(username).then(function(isInitialized) {
          var serverErrorRetriesLeft;
          if (isInitialized) {
            return resolve();
          }
          serverErrorRetriesLeft = 3;
          return poll((function() {
            return _this._triggerInitialReplication(username);
          }), _this.retryTimeout, function(v) {
            if (v === 'completed') {
              return true;
            }
            if (v === 'server_error') {
              serverErrorRetriesLeft--;
              return serverErrorRetriesLeft === 0;
            }
          }).then(function(result) {
            if (result === 'server_error') {
              throw new Exceptions.no_connection;
            }
          }).then(resolve, reject, notify);
        });
      };
    })(this));
  };

  UdbService.prototype._triggerInitialReplication = function(username) {
    var dbName;
    dbName = this.getUdbName(username);
    return DbHelper.localReplica(dbName).replicate.from(DbHelper.centralReplica(dbName)).then((function(_this) {
      return function() {
        return _this.isInitialized(username);
      };
    })(this)).then(function(isInitialized) {
      if (isInitialized) {
        return 'completed';
      } else {
        return 'not_initialized_yet';
      }
    }, function(error) {
      var _ref;
      if (!error.status) {
        throw error;
      }
      if (error.status === 500) {
        return 'not_initialized_yet';
      }
      if ((_ref = error.status) !== 403 && _ref !== 404) {
        return 'server_error';
      }
      return 'no_central_udb';
    });
  };

  UdbService.prototype.loadUserOrganizations = function(username) {
    var udbName;
    udbName = this.getUdbName(username);
    return this.storeAdapter.fetchWithKeyPrefix(udbName, organizationPermissionsDocPrefix).then(function(data) {
      return _.chain(data.rows).pluck('doc').filter(function(doc) {
        return doc.organizationEnabled;
      }).reduce(function(acc, doc) {
        acc[doc.organizationId] = doc;
        return acc;
      }, {}).value();
    });
  };

  UdbService.prototype.loadUserData = function(username) {
    var requests, udbName, user;
    user = new User({
      _id: this.getUdbName(username),
      name: username
    });
    udbName = this.getUdbName(username);
    requests = new Requests(null, {
      url: udbName
    });
    return requests.fetch().then(function(requests) {
      user.requests = requests;
      return user.fetch();
    });
  };

  UdbService.prototype.getLocalUserDocByEmail = function(email) {
    return gdt.storage.get('users').then((function(_this) {
      return function(value) {
        var users;
        users = value ? JSON.parse(value) : [];
        return W.map(users, function(user) {
          var dbName, udb;
          dbName = _this.getUdbName(user);
          udb = DbHelper.localReplica(dbName);
          return udb.get(dbName)["catch"](function(err) {
            if (err.status !== 404) {
              throw err;
            }
          });
        }).then(function(userDocs) {
          return _(userDocs).chain().compact().findWhere({
            email: email
          }).value();
        });
      };
    })(this));
  };

  UdbService.prototype.getLocalUserEmail = function(username) {
    var dbName, udb;
    dbName = this.getUdbName(username);
    udb = DbHelper.localReplica(dbName);
    return W(udb.get(dbName).then(function(userDoc) {
      return userDoc.email;
    }));
  };

  UdbService.prototype.getUdbName = function(username) {
    return "u_" + username;
  };

  UdbService.prototype.getDoc = function(username, id) {
    var udbName;
    udbName = this.getUdbName(username);
    return this.adapter.getDoc(Model, id, udbName);
  };

  return UdbService;

})();
