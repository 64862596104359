var PublicationService, W, exceptions, mediator;

W = require('when');

exceptions = require('lib/exceptions');

mediator = require('mediator');

module.exports = PublicationService = (function() {
  function PublicationService() {}

  PublicationService.prototype.checkStatus = function(project, channel) {
    return project.getDocFor('publication_status').then(function(doc) {
      var status, _ref;
      status = (_ref = doc.get(channel)) != null ? _ref.status : void 0;
      if (status === 'initiated') {
        return W.resolve('inProgress');
      } else if (status) {
        return W.resolve(status);
      } else {
        return W.resolve('notPublished');
      }
    }, function(err) {
      if (err.status === 404) {
        return W.resolve('notPublished');
      } else {
        return W.reject(err);
      }
    });
  };

  PublicationService.prototype._updatePublicationStatus = function(project, channel, id) {
    return project.getDocFor('publication_status').then(function(doc) {
      var statusObj;
      statusObj = doc.get(channel) || {};
      statusObj.status = 'initiated';
      statusObj.requestId = id;
      return doc.save(channel, statusObj);
    });
  };

  PublicationService.prototype.publish = function(project, channel, details) {
    var deferred;
    if (details == null) {
      details = {};
    }
    deferred = W.defer();
    return this.checkStatus(project, channel).then((function(_this) {
      return function(status) {
        var data;
        if (status === 'notPublished') {
          data = _(details).extend({
            type: 'publication',
            channel: channel,
            project: project.id,
            projectName: project.get('name'),
            status: 'stub'
          });
          mediator.user.getRequests().create(data, {
            success: function(request) {
              _this._updatePublicationStatus(project, channel, request.id);
              return deferred.resolve(request.save({
                status: 'new'
              }));
            }
          });
          return deferred.promise;
        } else {
          return deferred.resolve();
        }
      };
    })(this));
  };

  return PublicationService;

})();
