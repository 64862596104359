var AccountActions, AccountStore, alt, defaultState, immutable;

alt = require('alt');

immutable = require('stores/utils/immutable');

AccountActions = require('actions/account_actions');

defaultState = Immutable.fromJS({
  _meta: {
    isConnectingToProvider: false
  },
  archieTokenExpiredModal: {
    _meta: {
      isOpen: false
    },
    promise: null
  }
});

AccountStore = (function() {
  function AccountStore() {
    this.state = defaultState;
    this.bindActions(AccountActions);
    this.exportPublicMethods({
      isConnectingToProvider: this.isConnectingToProvider,
      isArchieTokenExpiredModalOpen: this.isArchieTokenExpiredModalOpen,
      getArchieTokenExpiredModalPromise: this.getArchieTokenExpiredModalPromise
    });
  }

  AccountStore.prototype.onOpenArchieTokenExpiredModal = function(promise) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['archieTokenExpiredModal', '_meta', 'isOpen'], true);
      return state.setIn(['archieTokenExpiredModal', 'promise'], promise);
    }));
  };

  AccountStore.prototype.onCloseArchieTokenExpiredModal = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['archieTokenExpiredModal', '_meta', 'isOpen'], false);
      return state.setIn(['archieTokenExpiredModal', 'promise'], null);
    }));
  };

  AccountStore.prototype.onConnectToProvider = function() {
    return this.setState(this.state.setIn(['_meta', 'isConnectingToProvider'], true));
  };

  AccountStore.prototype.onConnectToProviderSuccess = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['archieTokenExpiredModal', '_meta', 'isOpen'], false);
      state.setIn(['archieTokenExpiredModal', 'promise'], null);
      return state.setIn(['_meta', 'isConnectingToProvider'], false);
    }));
  };

  AccountStore.prototype.onConnectToProviderError = function() {
    return this.setState(this.state.setIn(['_meta', 'isConnectingToProvider'], false));
  };

  AccountStore.prototype.isConnectingToProvider = function() {
    return this.state.getIn(['_meta', 'isConnectingToProvider']);
  };

  AccountStore.prototype.isArchieTokenExpiredModalOpen = function() {
    return this.state.getIn(['archieTokenExpiredModal', '_meta', 'isOpen']);
  };

  AccountStore.prototype.getArchieTokenExpiredModalPromise = function() {
    return this.state.getIn(['archieTokenExpiredModal', 'promise']);
  };

  return AccountStore;

})();

module.exports = alt.createStore(immutable(AccountStore), 'AccountStore');
