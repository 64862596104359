PanelVoiceEtdVotingAction = require 'actions/panel_voice_etd_actions'
VoteOption =  require 'components/etd/panel_voice_etd/vote_option'
VotedEtdData = require 'components/etd/panel_voice_etd/voted_etd_data'
VotingControls = require 'components/etd/panel_voice_etd/voting_controls'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{
  getVotingOptionLabel
} = require 'lib/etd_helper'
{ string, instanceOf, number, bool } = PropTypes

PROPOSED_VOTING_OPTIONS = Immutable.fromJS [
  text: $.t 'es:recommendations.table.agree'
  value: 'agree'
,
  text: $.t 'es:recommendations.table.disagree'
  value: 'disagree'
,
  text: $.t 'es:recommendations.table.radio_values.dont_know'
  value: 'dont_know'
]

getVotingOptionsRenderer = ({
  votesCountByValue,
  activeOption,
  onClick,
  resultsViewType,
  membersVotedCount
}) ->

  (option, idx) ->
    text = option.get 'text'
    value = option.get 'value'
    votesCount = votesCountByValue.get(value, 0)
    optionClickHandler = if votesCount > 0
      -> onClick(value)

    <VoteOption
      isActive={value is activeOption}
      key={idx}
      membersVotedCount={membersVotedCount}
      onClick={optionClickHandler}
      text={text}
      votesCount={votesCount}
      resultsViewType={resultsViewType}
    />

JudgementPanelVoiceDetails = createReactClass
  displayName: 'JudgementPanelVoiceDetails'

  propTypes:
    activeOption: string
    etdPart: string.isRequired
    isProposedVoting: bool.isRequired
    membersVotedCount: number.isRequired
    membersVotingCount: number.isRequired
    renderMode: string.isRequired
    resultsViewType: string.isRequired
    sectionId: string.isRequired
    votedEtdData: instanceOf(Immutable.Map)
    votingResultsByOption: instanceOf(Immutable.Map)
    votingStatus: string.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]


  _getVotingOptionRenderer: ->
    { votingResultsByOption, membersVotedCount, activeOption, resultsViewType } = @props
    votesCountByValue = votingResultsByOption.map (results) -> results.size

    getVotingOptionsRenderer {
      membersVotedCount,
      votesCountByValue,
      activeOption,
      resultsViewType,
      onClick: @updateActiveOption
    }

  updateActiveOption: (newVal) ->
    # click on the same option again deselects the option
    newActiveOption = if @props.activeOption is newVal then null else newVal

    PanelVoiceEtdVotingAction.setSectionActiveJudgmentOption
      option: newActiveOption
      sectionId: @props.sectionId

  onVoteDataClick: ->
    @updateActiveOption null

  renderVotesSummary: ->
    { votedEtdData, votingStatus, isProposedVoting } = @props
    # no votes summary for test rounds as users cannot vote on options in test rounds
    return null if votingStatus is 'test'

    optionsMapper = @_getVotingOptionRenderer()

    if isProposedVoting
      <div className='votes-summary'>
        {PROPOSED_VOTING_OPTIONS.map(optionsMapper).toList()}
      </div>
    else
      votedOptions = votedEtdData.get 'options'
      votedAdditionalOptions = votedEtdData.get 'additionalOptions'
      return null unless votedOptions or votedAdditionalOptions

      <div className='votes-summary'>
        {votedOptions.map(optionsMapper).toList()}
        {if votedAdditionalOptions
          <div>
            <hr />
            {votedAdditionalOptions.map(optionsMapper).toList()}
          </div>
        }
      </div>

  render: ->
    {
      etdPart
      isProposedVoting
      membersVotedCount: votedCount
      membersVotingCount: votingCount
      renderMode
      resultsViewType
      sectionId
      votedEtdData
      votingStatus
    } = @props

    draftJudgement = votedEtdData.get 'selectedOption'
    isTestRound = votingStatus is 'test'
    votesCountLabel = if isTestRound then 'test_comments_stats' else 'votes_stats'

    <div className='judgement-details'>
      <VotedEtdData
        onClick={@onVoteDataClick}
        votesCountLabel={votesCountLabel}
        votedCount={votedCount}
        votingCount={votingCount}
      />
      <div className="voted-data__options">
        <div className='draft-judgement'>
          {if isProposedVoting and not isTestRound
            <span>
              {@i18n 'draft_judgement'}: {' '}
              <span className="bold">
                {getVotingOptionLabel votedEtdData, draftJudgement, etdPart}
              </span>
            </span>
          }
        </div>
        <VotingControls
          etdPart={etdPart}
          isProposedVoting={isProposedVoting}
          renderMode={renderMode}
          resultsViewType={resultsViewType}
          sectionId={sectionId}
          votingStatus={votingStatus}
        />
      </div>
      {@renderVotesSummary()}
    </div>

module.exports = JudgementPanelVoiceDetails
