Translation = require 'components/mixins/translation'

FinishedTab = createReactClass
  displayName: 'FinishedTab'

  propTypes:
    questions: PropTypes.instanceOf(Immutable.Map).isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [
    Translation('scope:questions')
  ]

  renderFirstRow: (groupType, questionsCount) ->
    <tbody>
      <tr className='caption'>
        <td className='group-title'>
          <span>
            {@i18n "approval.questions_#{groupType}"} ({questionsCount})
          </span>
        </td>
      </tr>
    </tbody>

  renderQuestions: (approvalStatus, questionsIds) ->
    questionsIds.map (questionsId, idx) =>
      question = @props.questions.getIn [questionsId, 'question']

      <tbody key={idx} className={approvalStatus}>
        <tr className='question-row'>
          <td className='question'>{question}</td>
        </tr>
      </tbody>

  renderQuestionsGroups: ->
    @props.questionGroups.filterNot (questionsIds, approvalStatus) -> questionsIds.isEmpty()
    .map (questionsIds, approvalStatus) =>
      <div className='approval-questions-group' key={approvalStatus}>
        <table>
          {@renderFirstRow approvalStatus, questionsIds.size}
          {@renderQuestions approvalStatus, questionsIds}
        </table>
      </div>
    .toList()

  render: ->
    <div className='questions-finished'>
      <div className='information'>
        <div className='row'>
          <div
            className='col-12'
            dangerouslySetInnerHTML={__html: @i18n 'finished.header'}
          />
        </div>
      </div>
      <div className='questions'>
        {@renderQuestionsGroups()}
      </div>
    </div>

module.exports = FinishedTab
