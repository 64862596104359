CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ bool, string, arrayOf, shape, func } = PropTypes

SelectionListOption = createReactClass
  displayName: 'SelectionListOption'

  propTypes:
    value: string
    label: string
    onSelect: func
    disabled: bool
    selected: bool

  handleSelect: ->
    return if @props.disabled
    @props.onSelect @props.value

  render: ->
    { selected, label, children, disabled } = @props
    optionClass = classNames 'select-option', { selected }

    <li className={optionClass} onClick={@handleSelect} disabled={disabled}>
      <span>{label}</span>
      {children}
    </li>

selectionListOptionGroupChildrenValidation = (props, propName, componentName) ->
  children = props[propName]
  childrenOfCorrectType = children.every (c) -> c.type is SelectionListOption
  unless childrenOfCorrectType
    new Error "All `#{componentName}` children must be of type `SelectionListOption`."

SelectionListOptionGroup = createReactClass
  displayName: 'SelectionListOptionGroup'

  propTypes:
    groupLabel: string.isRequired
    children: selectionListOptionGroupChildrenValidation

  render: ->
    { groupLabel, children, onSelect, selectedValue } = @props

    <SelectionListOption label={groupLabel} disabled>
      <ul className='group-options'>
        {React.Children.map children, (child) ->
          React.cloneElement child, { onSelect, selected: child.props.value is selectedValue }
        }
      </ul>
    </SelectionListOption>

selectionListChildrenValidation = (props, propName, componentName) ->
  children = props[propName]
  childrenOfCorrectType = children.every (c) ->
    { type } = c
    (type is SelectionListOption) or (type is SelectionListOptionGroup)

  unless childrenOfCorrectType
    new Error "All `#{componentName}` children must be of `SelectionListOption` or of
      `SelectionListOptionGroup` type."

SelectionList = createReactClass
  displayName: 'SelectionList'

  propTypes:
    onClear: func
    onSelect: func.isRequired
    children: selectionListChildrenValidation

  mixins: [Translation(), CustomRenderMixin]

  handleClear: ->
    @props.onClear()

  render: ->
    { children, selectedValue, onClear, onSelect } = @props

    <div className='outcome-edit__selection-list'>
      <ul>
        {React.Children.map children, (child) ->
          extendedProps = _.extend { onSelect },
            if child.type is SelectionListOptionGroup
              { selectedValue }
            else
              selected: child.props.value is selectedValue

          React.cloneElement child, extendedProps
        }
        {if selectedValue and onClear?
          <li className='clear-selected btn-popup' onClick={@handleClear}>
            {@i18n 'es:outcome.clear'}
          </li>
        }
      </ul>
    </div>

module.exports = {
  SelectionList,
  SelectionListOption,
  SelectionListOptionGroup
}
