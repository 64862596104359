Etd = require 'components/etd/etd'

QualityIndicatorsEtd = ({ questionId, renderMode }) ->
  <div id="recommendations">
    <Etd type="qualityIndicators" renderMode={renderMode} questionId={questionId} />
  </div>

QualityIndicatorsEtd.propTypes =
  renderMode: PropTypes.oneOf(['regular', 'printout'])
  questionId: PropTypes.string.isRequired

QualityIndicatorsEtd.defaultProps =
  renderMode: 'regular'

module.exports = QualityIndicatorsEtd
