LabelTextEdit = createReactClass
  displayName: 'LabelTextEdit'

  ref: (el) ->
    @input = el

  getText: ->
    @input.value.trim()

  render: ->
    style = backgroundColor: @props.color

    <input
      type="text"
      ref={@ref}
      defaultValue={@props.text}
      style={style}
    />

module.exports = LabelTextEdit
