Button = require 'components/common/button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ func, string, bool } = PropTypes

TextAreaWithApply = createReactClass
  displayName: 'TextAreaWithApply'
  mixins: [Translation(''), CustomRenderMixin]

  propTypes:
    onApply: func.isRequired
    onCancel: func
    content: string
    placeholder: string
    readOnly: bool
    editing: bool

  getDefaultProps: ->
    placeholder: ''

  getInitialState: ->
    editing: @props.editing ? false

  onApply: ->
    @props.onApply @textArea.value
    @_close()

  onCancel: ->
    if @props.onCancel
      @props.onCancel()
    else if @initialContent? and @initialContent isnt @textArea.value
      @props.onApply(@initialContent)
    @_close()

  _close: ->
    @initialContent = null
    @setState editing: false if @mounted

  textAreaRef: (el) ->
    @textArea = el

  componentDidMount: ->
    @mounted = true

  componentWillUnmount: ->
    @mounted = false

  componentWillUpdate: (nextProps, nextState) ->
    if @state.editing is false and nextState.editing is true
      @initialContent = @props.content

  startEditing: ->
    @setState editing: true unless @props.readOnly

  handleOnInput: (evt) ->
    el = evt.target
    if (el.scrollHeight > 50)
      el.style.height = (el.scrollHeight + 5)+"px";

  onFocus: (evt) ->
    if (evt.target?.scrollHeight > evt.target?.clientHeight)
      evt.target?.style.height = (evt.target?.scrollHeight + 5)+"px"

  render: ->
    <div className="text-area-container">
      {if @state.editing
        <React.Fragment>
          <textarea
            ref={@textAreaRef}
            defaultValue={@props.content}
            autoFocus={true}
            placeholder={@props.placeholder}
            onInput={@handleOnInput}
            onFocus={@onFocus}
          />
          <div className='buttons'>
            <button className="btn btn-cancel" onClick={@onCancel}>
              {@i18n 'actions.cancel'}
            </button>
            <button className="btn btn-apply" onClick={@onApply}>
              {@i18n 'actions.apply'}
            </button>
          </div>
        </React.Fragment>
      else
        <div
          className="start-editing-trigger"
          style={padding: '5px', minHeight: "22px"}
          onClick={@startEditing}
        >
          {if _.isEmpty(@props.content)
            <span className="placeholder">{@props.placeholder}</span>
          else
            @props.content
          }
        </div>
      }
    </div>

module.exports = TextAreaWithApply
