var AllModulesMigration, COCHRANE_ORGANIZATION_ID, Migration, W, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

COCHRANE_ORGANIZATION_ID = require('lib/archie_helper').COCHRANE_ORGANIZATION_ID;

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

migrationName = 'all_modules';

module.exports = AllModulesMigration = (function(_super) {
  __extends(AllModulesMigration, _super);

  function AllModulesMigration() {
    AllModulesMigration.__super__.constructor.call(this, false);
  }

  AllModulesMigration.prototype.up = function(project) {
    var disseminationModuleIdx, organizationId, projectModules, updatedModules;
    AllModulesMigration.__super__.up.apply(this, arguments);
    organizationId = project.get('organizationId');
    projectModules = project.get('modules');
    if (organizationId === COCHRANE_ORGANIZATION_ID && projectModules === '*') {
      return mediator.services.enableModulesForPreset('cochrane', project);
    } else {
      updatedModules = organizationId === COCHRANE_ORGANIZATION_ID ? (disseminationModuleIdx = R.findIndex(R.whereEq({
        id: 'dissemination'
      }), projectModules), R.set(R.lensPath([disseminationModuleIdx, 'disabled']), true, projectModules)) : '*';
      project.set('modules', updatedModules);
      project.set('rev_author', "project_migration/" + migrationName);
      return project.save();
    }
  };

  return AllModulesMigration;

})(Migration);
