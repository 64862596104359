var EtdV1ManagementRecommendationsExporter, EtdV2ManagementRecommendationsExporter, IEtdManagementRecommendationsExporter, ManagementRecommendationsExporter;

EtdV1ManagementRecommendationsExporter = require('./etdv1_management_recommendations_exporter');

EtdV2ManagementRecommendationsExporter = require('./etdv2_management_recommendations_exporter');

IEtdManagementRecommendationsExporter = require('./ietd_management_recommendations_exporter');

module.exports = ManagementRecommendationsExporter = (function() {
  function ManagementRecommendationsExporter(_originalQuestion, etdVersion, pouchAdapter, recommendationsExporter) {
    switch (etdVersion) {
      case "v1":
        _(this).extend(new EtdV1ManagementRecommendationsExporter(_originalQuestion));
        break;
      case "v2":
        _(this).extend(new EtdV2ManagementRecommendationsExporter(_originalQuestion));
        break;
      case "ietd":
        _(this).extend(new IEtdManagementRecommendationsExporter(_originalQuestion, pouchAdapter, recommendationsExporter));
        break;
      default:
        throw new Error("Unknown ETD version");
    }
  }

  return ManagementRecommendationsExporter;

})();
