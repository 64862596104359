DbHelper = require 'base/lib/db_helper'
mediator = require 'mediator'
Translation = require 'components/mixins/translation'

OAUTH_PROVIDERS_TO_SERVER_SWITCHES_MAP = Immutable.OrderedMap [
  ['google', 'loginWithGoogle']
  ['archie', 'loginWithArchie']
]

OAUTH_PROVIDERS_TO_COMPANY_NAMES_MAP =
  google: 'Google'
  archie: 'Cochrane'

LoginWithProviderButton = createReactClass
  displayName: 'LoginWithProviderButton'

  propTypes:
    enabled: PropTypes.bool
    provider: PropTypes.oneOf(['archie', 'google']).isRequired

  getDefaultProps: ->
    enabled: true

  render: ->
    { enabled, provider } = @props
    return null unless enabled

    <a
      rel="external noopener noreferrer"
      href={"#{DbHelper.getBackendUrl()}/oauth2/login/#{provider}"}
      className={"btn external-login__buttons__button external-login__#{provider}"}
    >
      <img src={"images/oauth-icon-#{provider}.svg"} />
      <div>{OAUTH_PROVIDERS_TO_COMPANY_NAMES_MAP[provider]}</div>
    </a>

OAuthLogin = createReactClass
  displayName: 'OAuthLogin'

  mixins: [Translation('registration')]

  getInitialState: ->
    providersEnabled: Immutable.OrderedMap()
    isAnyOauthProviderEnabled: false

  componentDidMount: ->
    return if window.gdt.enterpriseId?
    providersEnabled = OAUTH_PROVIDERS_TO_SERVER_SWITCHES_MAP.map (serverSwitch) ->
      mediator.services.switches.isServerSwitchOn serverSwitch
    isAnyOauthProviderEnabled = providersEnabled.some _.identity
    @setState { providersEnabled, isAnyOauthProviderEnabled }

  render: ->
    { providersEnabled, isAnyOauthProviderEnabled } = @state
    return null unless isAnyOauthProviderEnabled

    <div className="external-login">
      <div className="external-login__title">{@i18n 'log_in_with'}</div>
      <div className="external-login__buttons">
        {providersEnabled.map (enabled, provider) ->
          <LoginWithProviderButton key={provider} provider={provider} enabled={enabled} />
        .valueSeq()}
      </div>
    </div>

module.exports = OAuthLogin
