Button = require 'components/common/button'
DbHelper = require 'base/lib/db_helper'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

KeyCloakSessionExpiredNotification = ->
  onLogin = useCoffeeCallback [DbHelper], ->
    location.href = "#{DbHelper.getBackendUrl()}/oauth2/login/keycloak"

  i18n = useI18n('registration.session_expired_keycloak')

  <div className="authentication-view-keycloak">
    <div className="authentication-view-keycloak__inner">
      <p className="authentication-view-keycloak__message">
        {i18n('message')}
      </p>
      <div className="authentication-view-keycloak__actions">
        <Button
          className="btn btn-apply"
          label={i18n('log_in_button')}
          onClick={onLogin}
        />
      </div>
    </div>
    <div className="overlay" />
  </div>

module.exports = KeyCloakSessionExpiredNotification
