var Outcome, OutcomesGenerationService, W, mediator, utils;

Outcome = require('models/outcome');

W = require('when');

mediator = require('mediator');

utils = require('base/lib/utils');

module.exports = OutcomesGenerationService = (function() {
  function OutcomesGenerationService(params) {
    if (params == null) {
      params = {};
    }
    this.comparisonQuestions = params.comparisonQuestions;
  }

  OutcomesGenerationService.prototype._addOutcomesToQuestion = function(questionId, outcomes) {
    var comparisonQuestion;
    comparisonQuestion = this.comparisonQuestions.get(questionId);
    outcomes.filterNot(function(outcome) {
      return outcome.get('importance') === 'notImportant';
    }).map(function(outcome) {
      return new Outcome({
        name: outcome.get('name'),
        source: outcome.get('source'),
        forceTypeSelection: true,
        importance: Math.round(outcome.get('medianRating'))
      });
    }).forEach(function(outcome) {
      return comparisonQuestion.get('outcomes').add(outcome);
    });
    return comparisonQuestion;
  };

  OutcomesGenerationService.prototype.moveOutcomesToComparisons = function(questionGroups) {
    var question, updatedQuestions;
    updatedQuestions = questionGroups.flatMap((function(_this) {
      return function(qGroup) {
        return qGroup.get('questions').map(function(question) {
          return _this._addOutcomesToQuestion(question.get('_id'), qGroup.get('outcomes'));
        });
      };
    })(this)).toJS();
    return W.all((function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = updatedQuestions.length; _i < _len; _i++) {
        question = updatedQuestions[_i];
        _results.push(question.save());
      }
      return _results;
    })()).otherwise(function(err) {
      utils.reportRavenError(err);
      mediator.dialogs.error($.t('scope:outcomes.moving_outcomes_error'));
      throw err;
    });
  };

  OutcomesGenerationService.prototype._mergeOutcomes = function(currentOutcomes, outcomes) {
    return outcomes.reduce(function(acc, outcome) {
      var outcomeIdx;
      outcomeIdx = acc.findIndex(function(currOut) {
        return currOut.get('key') === outcome.get('key');
      });
      if (outcomeIdx > -1) {
        return acc.update(outcomeIdx, function(currentOutcome) {
          return currentOutcome.withMutations(function(currentOutcome) {
            return currentOutcome.set('memberComments', currentOutcome.get('memberComments', Immutable.Map()).mergeDeep(outcome.get('memberComments', Immutable.Map()))).set('ratings', currentOutcome.get('ratings', Immutable.Map()).mergeDeep(outcome.get('ratings', Immutable.Map()))).set('approvals', currentOutcome.get('approvals', Immutable.Map()).mergeDeep(outcome.get('approvals', Immutable.Map())));
          });
        });
      } else {
        return acc.push(outcome);
      }
    }, currentOutcomes);
  };

  OutcomesGenerationService.prototype._mergeQuestionGroups = function(currentGroup, group) {
    return currentGroup.updateIn(['outcomes'], (function(_this) {
      return function(currentOutcomes) {
        return _this._mergeOutcomes(currentOutcomes, group.get('outcomes'));
      };
    })(this));
  };

  OutcomesGenerationService.prototype.mergeQuestionGroups = function(currentQuestionGroups, questionGroups) {
    return questionGroups.reduce((function(_this) {
      return function(acc, group) {
        var groupIdx;
        groupIdx = acc.findIndex(function(qg) {
          return qg.get('key') === group.get('key');
        });
        if (groupIdx > -1) {
          return acc.update(groupIdx, function(currentGroup) {
            return _this._mergeQuestionGroups(currentGroup, group);
          });
        } else {
          return acc.push(group);
        }
      };
    })(this), currentQuestionGroups);
  };

  return OutcomesGenerationService;

})();
