Translation = require 'components/mixins/translation'

DuplicatesButtonsRow = createReactClass
  displayName: 'DuplicatesButtonsRow'

  mixins: [
    Translation('references:buttons')
  ]

  keepDuplicates: ->
    @props.keepDuplicates @props.duplicatesHash

  mergeDuplicates: ->
    @props.mergeDuplicates @props.duplicatesHash

  showDuplicationDetails: ->
    @props.showDuplicationDetails @props.duplicatesHash

  render: ->
    <tr className='buttons-row'>
      <td colSpan={@props.columns.length}>
        <div className='buttons'>
          <button className='btn btn-cancel' onClick={@keepDuplicates}>
            {@i18n 'keep'}
          </button>
          <button className='btn' onClick={@mergeDuplicates}>
            {@i18n 'merge'}
          </button>
          <button className='btn btn-info' onClick={@showDuplicationDetails}>
            {@i18n 'details'}
          </button>
        </div>
      </td>
    </tr>

module.exports = DuplicatesButtonsRow
