ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdActions = require 'actions/etd_actions'
EtdStore = require 'stores/etd_store'
{ ExtractionForm, extractionFormShape } = require 'components/covid_extraction_form/extraction_form'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'

storeConnector =
  EtdStore: (Store, props) ->
    data: Store.getRecommendationMetadata(props.form)

RecommendationMetadataFormModal = createReactClass
  displayName: 'RecommendationMetadataFormModal'

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.metadataExtractionForm')
  ]

  propTypes:
    data: PropTypes.object.isRequired
    isOpen: PropTypes.bool.isRequired
    onClose: PropTypes.func.isRequired
    form: extractionFormShape
    asterixedFields: PropTypes.arrayOf(PropTypes.string)

  render: ->
    { asterixedFields, data, isOpen, onClose, form } = @props

    <Modal
      className="recommendation-extraction-form-modal"
      closeButton
      isOpen={isOpen}
      modalSize="large"
      onClose={onClose}
      title={@i18n 'title'}
    >
      <ExtractionForm
        asterixedFields={asterixedFields}
        data={data}
        form={form}
        i18n={@i18n}
        inModal={true}
        onChange={EtdActions.updateRecommendationMetadata}
      />
      <div className="button-row">
        <button className="btn btn-default" onClick={onClose}>
          {@i18n '/actions.close'}
        </button>
      </div>
    </Modal>

module.exports = ConnectStore RecommendationMetadataFormModal, EtdStore, storeConnector
