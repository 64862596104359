var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "checked";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<label class=\"checkbox\" for=\"calculatedControl\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.calculated_control_label", {"name":"i18n","hash":{},"data":data})))
    + "\n<input id=\"calculatedControl\" name=\"calculatedControl\" type=\"checkbox\"\n  ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.calculatedControl : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n</label>\n<div class=\"inline input-group percent calculated-control\">\n  <input type=\"text\" class=\"form-control\" name=\"calculatedControlValue\"\n    value=\""
    + escapeExpression(((helpers.controlRatio || (depth0 && depth0.controlRatio) || helperMissing).call(depth0, depth0, {"name":"controlRatio","hash":{},"data":data})))
    + "\" disabled>\n</div>\n<div class=\"sup\"></div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}