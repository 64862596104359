DbHelper = require 'base/lib/db_helper'
PasswordInput = require 'components/common/password_input'
GradeProLogo = require 'components/common/grade_pro_logo'
LanguageSelector = require './language_selector'
OauthLogin = require './oauth_login'
Translation = require 'components/mixins/translation'
Validation = require 'base/validation'
Exceptions = require 'lib/exceptions'
mediator = require 'mediator'

LoginForm = createReactClass
  displayName: 'LoginForm'

  mixins: [Translation('registration')]

  propTypes:
    email: PropTypes.string
    onEmailChange: PropTypes.func
    emailDisabled: PropTypes.bool
    password: PropTypes.string
    onPasswordChange: PropTypes.func
    forgotPassword: PropTypes.func
    service: PropTypes.shape
      login: PropTypes.func.isRequired
    onLogin: PropTypes.func
    onInit: PropTypes.func
    onNoSubscription: PropTypes.func
    logoSrc: PropTypes.string
    withTitle: PropTypes.bool
    withLabeledFields: PropTypes.bool
    withPlaceholders: PropTypes.bool

  getDefaultProps: ->
    withTitle: true
    withLabeledFields: true
    withLanguageSelector: false
    withPlaceholders: false

  getInitialState: ->
    loggingIn: false

  submit: (e) ->
    e.preventDefault()
    @setState(emailError: null, passwordError: null, sharedError: null)
    unless Validation.isValidEmail(@props.email)
      @setState(emailError: @i18n("/errors:authentication.invalid_email"))
      return
    unless @props.password?.trim()
      @setState(passwordError: @i18n("/errors:authentication.empty_password"))
      return

    @setState(loggingIn: true)
    @props.service.login(@props.email, @props.password).then @props.onLogin, (error) =>
      if error instanceof Exceptions.authentication.no_active_subscription
        @props.onNoSubscription(error)
      else
        @setState(sharedError: error, loggingIn: false)
    , @props.onInit

  getErrorMessage: -> __html: @state.sharedError?.message

  render: ->
    { withTitle, withLabeledFields } = @props

    isArchieEnabled = mediator.services.switches.isServerSwitchOn 'loginWithArchie'
    isGoogleEnabled = mediator.services.switches.isServerSwitchOn 'loginWithGoogle'

    <form className="log-in-form" onSubmit={@submit}>
      <GradeProLogo logoSrc={@props.logoSrc} />
      {if withTitle
        <h1 className="log-in-form__title">{@i18n('sign_in')}</h1>
      }
      {@state.sharedError && (
        <div className="error-shared" dangerouslySetInnerHTML={@getErrorMessage()} />
      )}
      <label>
        {if withLabeledFields
          @i18n('email')
        }
        <input
          autoFocus={true}
          onChange={@props.onEmailChange}
          value={@props.email}
          disabled={@props.emailDisabled}
          placeholder={ if @props.withPlaceholders then @i18n('email') else '' }
        />
      </label>
      <div className="error email">{@state.emailError}</div>

      <label>
        {if withLabeledFields
          @i18n('password')
        }
        <PasswordInput
          value={@props.password}
          onChange={@props.onPasswordChange}
          placeholder={if @props.withPlaceholders then @i18n('password') else ''}
        />
      </label>
      <div className="error password">{@state.passwordError}</div>

      <button
        type="submit"
        className={classNames 'btn btn-alternative btn-block log-in', loading: @state.loggingIn}
        disabled={@state.loggingIn}>
        {@i18n('log_in')}
      </button>

      <OauthLogin />

      <div className='forgot-password-with-languages'>
        <button className="forgot-password" type="button" tabIndex="-1"
          disabled={@state.loggingIn}
          onClick={@props.forgotPassword}>
          {@i18n('click_here_to_reset_password')}
        </button>
        {if @props.withLanguageSelector
          <LanguageSelector />
        }
      </div>
    </form>

module.exports = LoginForm
