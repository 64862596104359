CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EMDSImport = require 'components/common/edms/edms_import'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'

EDMSInsertModal = createReactClass
  displayName: 'EDMSInsertModal'

  propTypes:
    onClose: PropTypes.func.isRequired
    onInsert: PropTypes.func.isRequired

  mixins: [
    CustomRenderMixin
    Translation()
  ]

  render: ->
    <Modal
      size='full-size'
      className='edms-insert-modal'
      closeButton
      isOpen
      onClose={@props.onClose}
      onRequestClose={@props.onClose}
    >
      <EMDSImport />
    </Modal>

module.exports = EDMSInsertModal
