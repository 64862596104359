var EtdHelper, EtdsActions, EtdsStore, alt, convertListToOrderedMap, errorHandler, immutable, mediator;

alt = require('alt');

mediator = require('mediator');

errorHandler = require('lib/app_utils').errorHandler;

immutable = require('stores/utils/immutable');

convertListToOrderedMap = require('lib/immutable_utils').convertListToOrderedMap;

EtdHelper = require('lib/etd_helper');

EtdsActions = require('actions/etds_actions');

EtdsStore = (function() {
  function EtdsStore() {
    this.state = Immutable.fromJS({
      _meta: {},
      etds: {}
    });
    this.bindActions(EtdsActions);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      getEtds: this.getEtds,
      getRecommendation: this.getRecommendation,
      getRecommendations: this.getRecommendations
    });
  }

  EtdsStore.prototype.onFetch = function(etdIds) {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  EtdsStore.prototype.onFetchAllSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], false);
      return state.set('etds', convertListToOrderedMap(Immutable.fromJS(data)));
    }));
  };

  EtdsStore.prototype.onFetchSuccess = function(data) {
    var etdsObj;
    etdsObj = data.rows.length === 0 ? {} : data.rows.reduce(function(result, row) {
      if (row.doc) {
        result[row.doc._id] = row.doc.templateData;
      }
      return result;
    }, {});
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], false);
      return state.set('etds', Immutable.fromJS(etdsObj));
    }));
  };

  EtdsStore.prototype.onFetchError = function(e) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    return errorHandler(e);
  };

  EtdsStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching'], false);
  };

  EtdsStore.prototype.getEtds = function() {
    return this.state.get('etds', Immutable.Map());
  };

  EtdsStore.prototype.getRecommendation = function(recommendationId) {
    return this.state.getIn(['etds', recommendationId], Immutable.Map());
  };

  EtdsStore.prototype.getRecommendations = function(recommendationIds) {
    return this.state.get('etds', Immutable.Map()).filter(function(rec) {
      return _.contains(recommendationIds, rec.get('_id'));
    });
  };

  return EtdsStore;

})();

module.exports = alt.createStore(immutable(EtdsStore), 'EtdsStore');
