var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "    <div class=\"uploading-dbep\">\n        <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:dialogs.dbep.uploading", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n        <div class=\"uploading-dbep-progress-bar\">\n            <span class=\"uploading-dbep-progress-bar-inner\" style=\"width: "
    + escapeExpression(((helper = (helper = helpers.questionProgressPercent || (depth0 != null ? depth0.questionProgressPercent : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"questionProgressPercent","hash":{},"data":data}) : helper)))
    + ";\">\n              <p class=\"uploading-dbep-progress-bar-text\">"
    + escapeExpression(((helper = (helper = helpers.questionProgressPercent || (depth0 != null ? depth0.questionProgressPercent : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"questionProgressPercent","hash":{},"data":data}) : helper)))
    + "</p>\n            </span>\n        </div>\n    </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <div class=\"results-dbep-upload\">\n        <div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isUploadSuccessful : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n        <div>\n            <button class=\"btn btn-block btn-success\" data-role=\"ok\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.ok", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n        </div>\n    </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "                <p class=\"dbep-upload-successful\">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:dialogs.dbep.upload_successful", {"name":"i18n","hash":{},"data":data})))
    + "\n                </p>\n                <p class=\"center\">\n                    <a href=\""
    + escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"link","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"dbep-link\">\n                        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:dialogs.dbep.upload_link", {"name":"i18n","hash":{},"data":data})))
    + "\n                    </a>\n                </p>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.profilesWithErrorsPresent : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "                    <p class=\"dbep-profiles-with-errors\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.mode : depth0), "dbep-publish", {"name":"is","hash":{},"fn":this.program(6, data),"inverse":this.program(8, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "                    </p>\n                    <ul>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.profilesWithErrors : depth0), {"name":"each","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    </ul>\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "                            ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:dialogs.dbep.errors_on_profiles_not_uploaded", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "                            ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:dialogs.dbep.errors_on_profiles", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"10":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                            <li>"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</li>\n";
},"12":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "                <p class=\"dbep-upload-unsuccessful\">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:dialogs.dbep.upload_unsuccessful", {"name":"i18n","hash":{},"data":data})))
    + ": "
    + escapeExpression(((helper = (helper = helpers.error || (depth0 != null ? depth0.error : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"error","hash":{},"data":data}) : helper)))
    + "\n                </p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.uploadFinished : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}