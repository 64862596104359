var EvidenceSynthesesToolbarQuestionsView, QuestionsListComponentActions, ReactComponent, ToolbarQuestionsList, View, alt, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

ReactComponent = require('base/lib/traits/react_component');

template = require('views/templates/evidence_syntheses_toolbar_questions');

ToolbarQuestionsList = require('components/evidence_syntheses/toolbar_questions_list');

View = require('base/views/view');

module.exports = EvidenceSynthesesToolbarQuestionsView = (function(_super) {
  __extends(EvidenceSynthesesToolbarQuestionsView, _super);

  function EvidenceSynthesesToolbarQuestionsView() {
    return EvidenceSynthesesToolbarQuestionsView.__super__.constructor.apply(this, arguments);
  }

  EvidenceSynthesesToolbarQuestionsView.prototype.template = template;

  EvidenceSynthesesToolbarQuestionsView.prototype.container = '#es-toolbar-questions';

  EvidenceSynthesesToolbarQuestionsView.prototype.className = '.questions-menu';

  EvidenceSynthesesToolbarQuestionsView.prototype.autoRender = true;

  EvidenceSynthesesToolbarQuestionsView.prototype.listenToParentKeyEvents = true;

  EvidenceSynthesesToolbarQuestionsView.prototype.initialize = function() {
    EvidenceSynthesesToolbarQuestionsView.__super__.initialize.apply(this, arguments);
    this._stopInputsPropagation = false;
    this.submodule = this.options.submodule;
    this.model = this.options.model;
    this.enable(ReactComponent);
    this.questionsCollectionType = this.options.moduleName === 'prognosis' ? 'prognosis' : 'regular';
    if (this.model != null) {
      return this.modelBind('change:question', this.onUpdateQuestion);
    }
  };

  EvidenceSynthesesToolbarQuestionsView.prototype._getRecommendationsSubmoduleName = function(viewTypeName) {
    return "recommendations-" + viewTypeName;
  };

  EvidenceSynthesesToolbarQuestionsView.prototype.getTemplateData = function() {
    var data;
    data = EvidenceSynthesesToolbarQuestionsView.__super__.getTemplateData.apply(this, arguments);
    _(data).extend({
      submodule: this.submodule
    });
    return data;
  };

  EvidenceSynthesesToolbarQuestionsView.prototype.getProps = function() {
    var _ref, _ref1;
    return {
      questionsCollectionType: this.questionsCollectionType,
      currentQuestion: (_ref = (_ref1 = this.model) != null ? _ref1.get('question') : void 0) != null ? _ref : $.t('toolbar.no_question_selected')
    };
  };

  EvidenceSynthesesToolbarQuestionsView.prototype.onUpdateQuestion = function() {
    return this.updateRenderedComponent(this.getProps());
  };

  EvidenceSynthesesToolbarQuestionsView.prototype.afterRender = function() {
    EvidenceSynthesesToolbarQuestionsView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(ToolbarQuestionsList, QuestionsListComponentActions, null, {
      fetchParams: {
        questionsCollectionType: this.questionsCollectionType
      },
      fetchAndListen: {
        dbId: mediator.project.id
      },
      props: this.getProps()
    }, this.el.querySelector('.questions'));
  };

  EvidenceSynthesesToolbarQuestionsView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('QuestionsStore', 'QuestionGroupsStore', 'QuestionsListComponentStore', 'QuestionsStatusesStore', 'OrganizationsStore', 'OverarchingQuestionsStore');
    return EvidenceSynthesesToolbarQuestionsView.__super__.dispose.apply(this, arguments);
  };

  return EvidenceSynthesesToolbarQuestionsView;

})(View);
