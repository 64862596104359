module.exports = {
  "doc": {
    "type": "doc",
    "content": [
      {
        "type": "table",
        "content": [
          {
            "type": "table_body",
            "content": [
              {
                "type": "table_row",
                "content": [
                  {
                    "type": "table_header",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        },
                        "content": [
                          {
                            "type": "text",
                            "marks": [
                              {
                                "type": "strong"
                              }
                            ],
                            "text": "JOB CLASS"
                          }
                        ]
                      }
                    ]
                  }, {
                    "type": "table_header",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        },
                        "content": [
                          {
                            "type": "text",
                            "marks": [
                              {
                                "type": "strong"
                              }
                            ],
                            "text": "MINIMUM"
                          }
                        ]
                      }
                    ]
                  }, {
                    "type": "table_header",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        },
                        "content": [
                          {
                            "type": "text",
                            "marks": [
                              {
                                "type": "strong"
                              }
                            ],
                            "text": "OPTIMUM"
                          }
                        ]
                      }
                    ]
                  }, {
                    "type": "table_header",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        },
                        "content": [
                          {
                            "type": "text",
                            "marks": [
                              {
                                "type": "strong"
                              }
                            ],
                            "text": "MAXIMUM"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }, {
                "type": "table_row",
                "content": [
                  {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        },
                        "content": [
                          {
                            "type": "text",
                            "marks": [
                              {
                                "type": "strong"
                              }
                            ],
                            "text": "SEDENTARY"
                          }
                        ]
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }
                ]
              }, {
                "type": "table_row",
                "content": [
                  {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        },
                        "content": [
                          {
                            "type": "text",
                            "marks": [
                              {
                                "type": "strong"
                              }
                            ],
                            "text": "LIGHT"
                          }
                        ]
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }
                ]
              }, {
                "type": "table_row",
                "content": [
                  {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        },
                        "content": [
                          {
                            "type": "text",
                            "marks": [
                              {
                                "type": "strong"
                              }
                            ],
                            "text": "MEDIUM"
                          }
                        ]
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }
                ]
              }, {
                "type": "table_row",
                "content": [
                  {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        },
                        "content": [
                          {
                            "type": "text",
                            "marks": [
                              {
                                "type": "strong"
                              }
                            ],
                            "text": "HEAVY"
                          }
                        ]
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }
                ]
              }, {
                "type": "table_row",
                "content": [
                  {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        },
                        "content": [
                          {
                            "type": "text",
                            "marks": [
                              {
                                "type": "strong"
                              }
                            ],
                            "text": "VERY HEAVY"
                          }
                        ]
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }, {
                    "type": "table_cell",
                    "attrs": {
                      "colspan": 1,
                      "rowspan": 1,
                      "colwidth": null
                    },
                    "content": [
                      {
                        "type": "paragraph",
                        "attrs": {
                          "alignment": "center",
                          "indentation": 0
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "selection": {
    "type": "text",
    "anchor": 39,
    "head": 39
  },
  "changeTracker": {
    "changes": {}
  },
  "annotations": {}
};
