RadioInput = require './radio_input'

RadioWithLabel = createReactClass
  displayName: 'RadioWithLabel'

  render: ->
    propsToPass = _.omit @props, 'label'
    <label className="radio-with-label">
      <RadioInput {...propsToPass} />
      <span onClick={@props.onChange}>{@props.label}</span>
    </label>

module.exports = RadioWithLabel
