ConnectStore = require 'components/enhancers/connect_store'
IconButton = require 'components/common/icon_button'
PublicationPlatformActions = require 'actions/publication_platform_actions'
PublicationPlatformDialog =
  require 'components/dissemination/publication_platform/publication_platform_dialog'
PublicationPlatformQuestion =
  require 'components/dissemination/publication_platform/publication_platform_question'
PublicationPlatformStore = require 'stores/publication_platform_store'
QuestionsList = require 'components/evidence_syntheses/questions_list/questions_list'
Scrollable = require 'components/common/scrollable'
SearchBar = require 'components/common/search_bar'
Select = require 'components/common/select_custom'
Spinner = require 'components/common/spinner'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

QUESTION_STATUSES = ['all', 'published', 'unpublished']

storeConnector =
  PublicationPlatformStore: (Store) ->
    isFetchingQuestions: Store.isFetchingQuestions()
    isFetchingStatuses: Store.isFetchingStatuses()
    questions: Store.getQuestions()
    questionGroups: Store.getQuestionGroups()
    questionStatuses: Store.getQuestionStatuses()
    searchText: Store.getSearchText()
    statusFilter: Store.getStatusFilter()

PublicationPlatformTable = ({
  isFetchingQuestions
  isFetchingStatuses
  questions
  questionGroups
  questionStatuses
  searchText
  statusFilter
}) ->
  i18n = useI18n 'dbep:questions_list_screen'

  changeSearchText = useCoffeeCallback [], (searchText) ->
    PublicationPlatformActions.setSearchText searchText

  resetSearchText = useCoffeeCallback [], ->
    PublicationPlatformActions.setSearchText ''

  statusFilterOptions = useCoffeeMemo [i18n], ->
    _.map QUESTION_STATUSES, (status) ->
      text: i18n "statuses.#{status}"
      value: status

  filteredQuestions = useCoffeeMemo [questions, searchText, questionStatuses], ->
    questions
      .filter (question) ->
        question.get('question')?.toLowerCase()?.indexOf(searchText.toLowerCase()) >= 0
      .filter (question) ->
        status = questionStatuses.getIn ['statuses', question.get '_id']
        status ?= 'unpublished'
        statusFilter in ['all', status]

  return <Spinner /> if isFetchingQuestions or isFetchingStatuses

  <div className="publication-platform-questions-table">
    <div className="publication-platform-questions-table__search-bar">
      <div className="search-bar__search">
        <SearchBar
          searchOnChange
          containerClass='questions-search'
          onSearch={changeSearchText}
          onSearchReset={resetSearchText}
          searchText={searchText}
        />
      </div>
      <div className="search-bar__controls">
        <div className="status-filter__text">{i18n 'status'}</div>
        <Select
          className="status-filter__select"
          options={statusFilterOptions}
          selected={statusFilter}
          onChange={PublicationPlatformActions.changeStatusFilter}
        />
      </div>
    </div>
    <div className="publication-platform-questions-table__table">
      <div className="publication-platform-questions-table__header">
        <div className="publication-platform-questions-table__row">
          {_.map ['question', 'status', 'actions'], (cell) ->
            <div className={"publication-platform-questions-table__cell #{cell}"} key={cell}>
              <div className="cell-content">{i18n "cells.#{cell}"}</div>
            </div>
          }
        </div>
      </div>
      <div className="publication-platform-questions-table__body">
        {if filteredQuestions.isEmpty()
          status = i18n "statuses.#{statusFilter}"
          text = if searchText is '' and statusFilter is 'all'
            i18n '/messages.no_questions_in_project'
          else if searchText isnt '' and statusFilter isnt 'all'
            i18n 'search.no_questions_matching_both_criteria', { status, searchText }
          else if searchText isnt ''
            i18n 'search.no_questions_matching_search_text', { searchText }
          else
            i18n 'search.no_questions_matching_status', { status }
          <p>{text}</p>
        else
          <Scrollable>
            <QuestionsList
              isFetchingData={false}
              questions={filteredQuestions}
              questionGroups={questionGroups}
              withEmptyQuestionGroups={false}
            >
              <PublicationPlatformQuestion />
            </QuestionsList>
          </Scrollable>
        }
      </div>
    </div>
    <PublicationPlatformDialog />
  </div>

PublicationPlatformTable.propTypes =
  isFetchingQuestions: PropTypes.bool.isRequired
  isFetchingStatuses: PropTypes.bool.isRequired
  questions: PropTypes.instanceOf(Immutable.Map).isRequired
  questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
  questionStatuses: PropTypes.instanceOf(Immutable.Map).isRequired
  searchText: PropTypes.string.isRequired
  statusFilter: PropTypes.oneOf(QUESTION_STATUSES).isRequired

module.exports = ConnectStore PublicationPlatformTable, PublicationPlatformStore, storeConnector
