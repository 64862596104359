var MdaTableView, MdaTablesController, MdaTablesView, ModuleController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

MdaTableView = require('views/mda/mda_table_view');

MdaTablesView = require('views/mda/mda_tables_view');

mediator = require('mediator');

module.exports = MdaTablesController = (function(_super) {
  __extends(MdaTablesController, _super);

  function MdaTablesController() {
    return MdaTablesController.__super__.constructor.apply(this, arguments);
  }

  MdaTablesController.prototype.index = function() {
    return this.view = new MdaTablesView;
  };

  MdaTablesController.prototype.table = function(params) {
    return this.view = new MdaTableView({
      tableId: params.id
    });
  };

  return MdaTablesController;

})(ModuleController);
