CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ConnectStores = require 'components/enhancers/connect_store'
MembersActions = require 'actions/members_actions'
{ MembersListWithLimits } = require 'components/team/members_list'

MembersStore = require 'stores/members_store'
OrganizationActions = require 'actions/organizations_actions'
OrganizationsStore = require 'stores/organizations_store'
Translation = require 'components/mixins/translation'
Spinner = require 'components/common/spinner'

TeamMembers = createReactClass

  displayName: "TeamMembers"

  mixins: [
    CustomRenderMixin
    Translation('team:member')
  ]

  propTypes:
    editing: PropTypes.string
    filterByAccessRight: PropTypes.string
    isFetching: PropTypes.bool.isRequired
    members: PropTypes.instanceOf(Immutable.Map)
    organizationMembers: PropTypes.instanceOf(Immutable.List)
    organizationId: PropTypes.string
    projectFromOrganization: PropTypes.bool.isRequired
    validationErrors: PropTypes.instanceOf(Immutable.Map).isRequired,

  getDefaultProps: ->
    organizationId: null

  componentDidMount: ->
    MembersActions.fetch()
    OrganizationActions.checkIfCurrentProjectIsInOrganization @props.projectId

  isFetching: ->
    @props.isFetching or @props.isFetchingMembers or @props.isFetchingOrganizations

  render: ->
    <div className="team-members-container">
      {if @props.isFetching
        <Spinner />
      else
        <MembersListWithLimits
          currentlyEditingKey={@props.editing}
          filterByAccessRight={@props.filterByAccessRight}
          isOnline={@props.isOnline}
          members={@props.members}
          organizationMembers={@props.organizationMembers}
          projectFromOrganization={@props.projectFromOrganization}
          openedAddMemberModal={@props.openedAddMemberModal}
          organizationId={@props.organizationId}
          sorting={@props.sorting}
          validationErrors={@props.validationErrors}
        />
      }
    </div>

storeConnectors =
  MembersStore: (Store) ->
    editing: Store.getEditingKey()
    filterByAccessRight: Store.getFilterByAccessRightKey()
    isFetching: Store.isFetching()
    members: Store.getMembers()
    openedAddMemberModal: Store.getOpenedAddMemberModal()
    sorting: Store.getSorting()
    validationErrors: Store.getValidationErrors()
  OrganizationsStore: (Store) ->
    isFetchingOrganizations: Store.isFetching()
    isOnline: Store.isOnline()
    projectFromOrganization: Store.isCurrentProjectFromOrganization()

module.exports = ConnectStores TeamMembers, [MembersStore, OrganizationsStore], storeConnectors
