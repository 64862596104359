var DecisionAidsActions, DragDropTypes, INITIAL_STATE, ReactDnDActions, generateStore, immUtils;

generateStore = require('stores/utils/generate_store');

DecisionAidsActions = require('actions/decision_aids_actions');

ReactDnDActions = require('actions/react_dnd_actions');

DragDropTypes = require('components/decision_aid/drag_drop_types');

immUtils = require('lib/immutable_utils');

INITIAL_STATE = Immutable.fromJS({
  meta: {
    fetching: false,
    editModalVisible: false,
    editingDecisionAid: null,
    expandedChapters: []
  },
  decisionAids: []
});

module.exports = generateStore({
  name: 'DecisionAidsStore',
  initialState: INITIAL_STATE,
  boundActions: [DecisionAidsActions, ReactDnDActions],
  methods: {
    onDbChange: function(change) {
      var deleted, doc, id, targetDecisionAidIdx;
      deleted = change.deleted, id = change.id, doc = change.doc;
      targetDecisionAidIdx = this._getDecisionAidIndex(id);
      if (deleted) {
        return this.onDeleteDecisionAid(id);
      } else if (targetDecisionAidIdx === -1) {
        return this.setState(this.state.update('decisionAids', function(decisionAids) {
          return decisionAids.push(Immutable.fromJS(doc));
        }));
      } else {
        return this._replaceDecisionAidData(doc);
      }
    },
    onFetch: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], true));
    },
    onFetchSuccess: function(response) {
      var decisionAids;
      decisionAids = R.pluck('doc', response.rows);
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['meta', 'fetching'], false);
        return state.set('decisionAids', Immutable.fromJS(decisionAids));
      }));
    },
    onSetExpandedChapters: function(expandedChapters) {
      return this.setState(this.state.setIn(['meta', 'expandedChapters'], Immutable.Set(expandedChapters)));
    },
    onStartDecisionAidEdit: function(decisionAidId) {
      return this.setState(this.state.setIn(['meta', 'editingDecisionAid'], decisionAidId));
    },
    onStopDecisionAidEdit: function() {
      return this.setState(this.state.setIn(['meta', 'editingDecisionAid'], null));
    },
    onToggleEditModal: function(visible) {
      return this.setState(this.state.setIn(['meta', 'editModalVisible'], visible));
    },
    onDeleteDecisionAid: function(decisionAidId) {
      var targetDecisionAidIdx;
      targetDecisionAidIdx = this._getDecisionAidIndex(decisionAidId);
      if (targetDecisionAidIdx === -1) {
        return;
      }
      return this.setState(this.state.deleteIn(['decisionAids', targetDecisionAidIdx]));
    },
    onEditChaptersOrder: function(decisionAidId) {
      var decisionAid, existingOrder, targetDecisionAidIdx;
      targetDecisionAidIdx = this._getDecisionAidIndex(decisionAidId);
      decisionAid = this.state.getIn(['decisionAids', targetDecisionAidIdx]);
      existingOrder = decisionAid.get('chaptersOrder');
      return this.setState(this.state.setIn(['meta', 'editingChaptersOrder'], existingOrder.isEmpty() ? decisionAid.get('chapters').keySeq().toList() : existingOrder));
    },
    onStopEditingChaptersOrder: function() {
      return this.setState(this.state.setIn(['meta', 'editingChaptersOrder'], null));
    },
    onHover: function(dragData) {
      var draggingChapterId, draggingIdx, editingChaptersOrder, hovereChapterId, hoveredIdx, type;
      type = dragData.type, hovereChapterId = dragData.hovereChapterId, draggingChapterId = dragData.draggingChapterId;
      if (type !== DragDropTypes.CHAPTER_TITLE) {
        return;
      }
      editingChaptersOrder = this.getEditingChaptersOrder();
      hoveredIdx = editingChaptersOrder.findIndex(R.equals(hovereChapterId));
      draggingIdx = editingChaptersOrder.findIndex(R.equals(draggingChapterId));
      return this.setState(this.state.setIn(['meta', 'editingChaptersOrder'], editingChaptersOrder["delete"](draggingIdx).splice(hoveredIdx, 0, draggingChapterId)));
    },
    onSaveDecisionAidChaptersOrder: function(_arg) {
      var chaptersOrder, daId, targetDecisionAidIdx;
      daId = _arg.daId, chaptersOrder = _arg.chaptersOrder;
      targetDecisionAidIdx = this._getDecisionAidIndex(daId);
      return this.setState(this.state.setIn(['decisionAids', targetDecisionAidIdx, 'chaptersOrder'], Immutable.List(chaptersOrder)));
    },
    _replaceDecisionAidData: function(decisionAidData) {
      var targetDecisionAidIdx;
      targetDecisionAidIdx = this._getDecisionAidIndex(decisionAidData['_id']);
      if (targetDecisionAidIdx === -1) {
        return;
      }
      return this.setState(this.state.setIn(['decisionAids', targetDecisionAidIdx], Immutable.fromJS(decisionAidData)));
    },
    _getDecisionAidIndex: function(decisionAidId) {
      return this.state.get('decisionAids').findIndex(function(da) {
        return da.get('_id') === decisionAidId;
      });
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'fetching']);
    },
    getDecisionAids: function() {
      return this.state.get('decisionAids');
    },
    getDecisionAid: function(decisionAidId) {
      return immUtils.findWhere(this.getDecisionAids(), '_id', decisionAidId);
    },
    getEditingDecisionAidId: function() {
      return this.state.getIn(['meta', 'editingDecisionAid']);
    },
    getEditingChaptersOrder: function() {
      return this.state.getIn(['meta', 'editingChaptersOrder']);
    },
    getExpandedChapters: function() {
      return this.state.getIn(['meta', 'expandedChapters']).toSet();
    },
    getEditModalVisible: function() {
      return this.state.getIn(['meta', 'editModalVisible']);
    },
    chapterExists: function(decisionAidId, chapterTitle) {
      return this.getDecisionAid(decisionAidId).get('chapters').has(_.str.underscored(chapterTitle));
    }
  }
});
