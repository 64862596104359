useTranslation = require 'components/hooks/use_translation'
{ sectionLinkTooltipPluginKey } = require './section_link_tooltip_plugin'
{ ToolbarControl, PMView, PMState, isMarkActive } = Epiditor

openSectionLinkEditorCommand = (state, dispatch) ->
  { selection } = state
  return false if (selection.empty or !(selection instanceof PMState.TextSelection))
  return false if (isMarkActive(state.schema.marks.sectionLink, state))

  dispatch(state.tr.setMeta(sectionLinkTooltipPluginKey, { action: 'create' })) if dispatch

  return true

SectionLinkControl = ({ view }) ->
  i18n = useTranslation('')
  disabled = not openSectionLinkEditorCommand(view.state, undefined)

  handleClick = (evt) ->
    evt.preventDefault();
    view.focus();

    openSectionLinkEditorCommand(view.state, view.dispatch)


  <ToolbarControl
    label={<span className="anchor-icon" />}
    title={i18n 'docsec:management.insert_section_link'}
    disabled={disabled}
    onClick={handleClick}
  />

SectionLinkControl.propTypes = {
  view: PropTypes.instanceOf(PMView.EditorView).isRequired
}

module.exports = SectionLinkControl
