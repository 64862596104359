SelectionButton = require 'components/common/selection_button'
Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
MultiComparisonsActions = require 'actions/multi_comparisons_actions'
IconButton = require 'components/common/icon_button'
ContentEditable = require 'components/common/content_editable'
Modal = require 'components/common/modal'
Confirmation = require 'components/common/confirmation'
QuestionsListComponentStore = require 'stores/questions_list_component_store'
{ sortQuestions } = require 'lib/questions_helper'
{ prepareQuestionsSelectionList } = require 'lib/multi_comparisons_helper'

SelectionStep = (props) ->
  <div className="multi-comparisons__selection-step">
    <hr />
    <div className="step-title">{ props.title }</div>
    { props.children }
  </div>

CompareInterventions = (props) ->
  questionType = props.selectedQuestions.first()?.get('type')
  isDiagnostic = questionType is 'diagnostic'

  <table className="questions-comparison-table">
    <tbody>
      <tr>
        <td></td>
        <td>{props.i18n 'mc:populations'}</td>
        <td>
          {props.i18n "mc:#{if isDiagnostic then 'indexTest' else 'intervention'}"}
        </td>
        <td>
          {props.i18n "mc:#{if isDiagnostic then 'comparatorTest' else 'comparison'}"}
        </td>
      </tr>
      {props.selectedQuestions.map (q, i) ->
        <tr key={q.get 'questionId'}>
          <td><span className="question-no">{props.i18n 'mc:question'} {i + 1}</span></td>
          <td className="questions-comparison-table__question-attr">
            { q.get 'healthProblemOrPopulation' }
          </td>
          <td className="questions-comparison-table__question-attr">
            { if isDiagnostic then q.get 'indexTest' else q.get 'intervention' }
          </td>
          <td className="questions-comparison-table__question-attr">
            { if isDiagnostic then q.get 'comparatorTest' else q.get 'comparison' }
          </td>
        </tr>
      }
    </tbody>
  </table>

ComparisonsSelection = createReactClass
  displayName: 'ComparisonsSelection'

  mixins: [ Translation('') ]

  propTypes:
    editingComparison: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired
    questions: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.OrderedMap),
      PropTypes.instanceOf(Immutable.Map)]
    ).isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    selectedQuestions: PropTypes.instanceOf(Immutable.List)
    interventionsComparableDesc: PropTypes.string.isRequired

  getInitialState: ->
    showConfirmModal: false,
    confirmType: "",
    questionToChange: null,

  updateInterventionsComparableDesc: (e) ->
    MultiComparisonsActions.updateInterventionsComparableDesc e.currentTarget.innerHTML

  confirmAddQuestion: (action) ->
    @setState showConfirmModal: true, confirmType: 'add', confirmAction: action

  confirmDeletingQuestion: (action) ->
    @setState showConfirmModal: true, confirmType: 'delete', confirmAction: action

  confirmUpdateQuestion: (action) ->
    @setState showConfirmModal: true, confirmType: 'update', confirmAction: action

  hideConfirmDialog: ->
    @setState showConfirmModal: false, confirmType: '', confirmAction: null

  updateQuestionSelection: (oldQuestionId) -> (newQuestionId) =>
    newQuestionData = @props.questions.find (q) -> q.get('questionId') is newQuestionId
    action = -> MultiComparisonsActions.updateComparisonQuestion { oldQuestionId, newQuestionData }

    if @props.editingComparison is 'new'
      action()
    else
      @confirmUpdateQuestion action

  removeQuestion: (qid) -> =>
    action = -> MultiComparisonsActions.removeComparisonQuestion qid

    if @props.editingComparison is 'new'
      action()
    else
      @confirmDeletingQuestion action

  addQuestion: (qid) ->
    question = @props.questions.find (q) -> q.get('questionId') is qid
    action = -> MultiComparisonsActions.selectComparisonQuestion question

    if @props.editingComparison is 'new'
      action()
    else
      @confirmAddQuestion action

  confirmModal: ->
    return if _.isEmpty(@state.confirmType)
    confirmationQuestion = @i18n switch @state.confirmType
      when 'add'
        'mc:confirmation_messages.add_question'
      when 'update'
        'mc:confirmation_messages.update_question'
      when 'delete'
        'mc:confirmation_messages.delete_question'

    onConfirm = =>
      @state.confirmAction()
      @hideConfirmDialog()

    <Modal className='confirmation-modal' isOpen>
      <Confirmation
        className="multi-comparisons__confirmation"
        question={confirmationQuestion}
        message={@i18n 'mc:confirmation_messages.confirm_change_message'}
        onCancel={@hideConfirmDialog}
        onConfirm={onConfirm}
      />
    </Modal>

  render: ->
    { selectedQuestions, questions, questionGroups } = @props
    # TODO: each re-render will cause questions re-sorting even though they've not changed
    { groupedOptions, ungroupedOptions } = prepareQuestionsSelectionList(
      questions, selectedQuestions, questionGroups)

    <div className="multi-comparisons__comparisons-selection">
      <SelectionStep title={@i18n 'mc:step_1'}>
        {selectedQuestions.map (question, i) =>
          questionId = question.get('questionId')

          <div key={questionId} className="selected-question-row">
            <span className="question-no">{@i18n 'mc:question'} {i + 1}</span>
            <Select
              className="selected-question"
              btnClassName="btn-block btn-primary btn-question-select"
              listClassName="multi-comparisons__questions-list"
              selected={questionId}
              options={ungroupedOptions}
              groupedOptions={groupedOptions}
              onChange={@updateQuestionSelection(questionId)}
              withNumberOptions
            />
            <IconButton
              className="btn-remove-question"
              iconName="decline-icon"
              onClick={@removeQuestion(questionId)}
            />
          </div>
        }
        {unless selectedQuestions.size is questions.size
          <div className="selected-question-row">
            <span className="question-no question-no--hidden">
              {@i18n 'mc:question'} {selectedQuestions.size}
            </span>
            <Select
              className="btn-add-question-container"
              listClassName="multi-comparisons__questions-list"
              label={" -- #{@i18n 'mc:add_question'} --"}
              options={ungroupedOptions}
              groupedOptions={groupedOptions}
              onChange={@addQuestion}
              withNumberOptions
            />
          </div>
        }
      </SelectionStep>

      <SelectionStep title={@i18n 'mc:step_2'}>
        <CompareInterventions selectedQuestions={selectedQuestions} i18n={@i18n} />
        <div>
          <p>{@i18n 'mc:are_interventions_comparable'}</p>
          <ContentEditable
            className="multi-comparisons__textarea"
            onInput={@updateInterventionsComparableDesc}
            html={@props.interventionsComparableDesc}
            autoFocus={false}
          />
        </div>
      </SelectionStep>

      {@state.showConfirmModal and @confirmModal()}

    </div>

module.exports = ComparisonsSelection
