ConnectStore = require 'components/enhancers/connect_store'
GlobalExportActions = require 'actions/global_export_actions'
GlobalExportStore = require 'stores/global_export_store'
QuestionsSelector = require 'components/common/questions_selector'
Translation = require 'components/mixins/translation'

storeConnectors =
  GlobalExportStore: (Store) ->
    areAllQuestionsSelected: Store.areAllQuestionsSelected()
    questions: Store.getQuestions()
    questionGroups: Store.getQuestionGroups()
    selectedQuestions: Store.getSelectedQuestions()

QuestionsList = createReactClass
  displayName: 'GlobalExportQuestionsList'
  mixins: [Translation('projects:global_export_dialog')]
  propTypes:
    areAllQuestionsSelected: PropTypes.bool.isRequired
    questions: PropTypes.instanceOf(Immutable.Map).isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    selectedQuestions: PropTypes.instanceOf(Immutable.List).isRequired

  onUpdateSelected: (newSelected) ->
    GlobalExportActions.updateSelectedQuestions newSelected

  render: ->
    { questions, questionGroups, selectedQuestions } = @props

    <div className="global-export-modal__question_list">
      <p className="question-list__header">{@i18n 'select_questions'}</p>
      <QuestionsSelector
        updateSelected={@onUpdateSelected}
        selectedQuestions={selectedQuestions}
        withSelectAll
        withSearch
        withNumbers
        questionGroups={questionGroups}
        questions={questions}
      />
    </div>

module.exports = ConnectStore QuestionsList, [GlobalExportStore], storeConnectors
