var MDGTableToolbar, MDGToolbarView, QuestionsListComponentActions, ReactComponent, RecommendationsToolbar, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

MDGTableToolbar = require('components/mdg_tables/table_toolbar');

RecommendationsToolbar = require('components/mdg_tables/recommendations_toolbar');

mediator = require('mediator');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

ReactComponent = require('base/lib/traits/react_component');

View = require('base/views/view');

module.exports = MDGToolbarView = (function(_super) {
  __extends(MDGToolbarView, _super);

  function MDGToolbarView() {
    return MDGToolbarView.__super__.constructor.apply(this, arguments);
  }

  MDGToolbarView.prototype.container = '#toolbar-container';

  MDGToolbarView.prototype.autoRender = true;

  MDGToolbarView.prototype._stopInputsPropagation = false;

  MDGToolbarView.prototype.initialize = function() {
    MDGToolbarView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  MDGToolbarView.prototype.afterRender = function() {
    var mode;
    MDGToolbarView.__super__.afterRender.apply(this, arguments);
    mode = this.options.mode;
    return this.renderComponent(mode === 'recommendation' ? RecommendationsToolbar : MDGTableToolbar, QuestionsListComponentActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id
      },
      fetchParams: {
        questionsCollectionType: 'regular'
      },
      props: {
        mode: mode
      }
    });
  };

  MDGToolbarView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('MDGTableStore', 'QuestionsStore', 'QuestionGroupsStore', 'QuestionsListComponentStore', 'QuestionsStatusesStore', 'OrganizationsStore', 'OverarchingQuestionsStore');
    return MDGToolbarView.__super__.dispose.apply(this, arguments);
  };

  return MDGToolbarView;

})(View);
