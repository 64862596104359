var COI_PROJECT_ID, DbHelper, Exceptions, MembersActions, W, alt, fetchProjectMembers, getAccessRights, getMemberAccessRights, getMembersForProject, hasAccessGranted, isMemberDuplicated, mediator, mocHelpers, overwriteIfDuplicatedMember, prepareRecipient, recoverIfDeleted, validateMemberAgainstMembersList, _ref;

alt = require('alt');

DbHelper = require('base/lib/db_helper');

COI_PROJECT_ID = require('lib/mdg_helper').COI_PROJECT_ID;

Exceptions = require('lib/exceptions');

mediator = require('mediator');

mocHelpers = require('actions/utils/moc_helpers');

W = require('when');

_ref = require('lib/members_helper'), getMemberAccessRights = _ref.getMemberAccessRights, isMemberDuplicated = _ref.isMemberDuplicated, validateMemberAgainstMembersList = _ref.validateMemberAgainstMembersList;

fetchProjectMembers = require('actions/async/projects').fetchProjectMembers;

getAccessRights = function(member) {
  if (!member) {
    return Immutable.List(['no_access']);
  }
  return member.get('accessRights', Immutable.List(['no_access']));
};

hasAccessGranted = function(member) {
  return !getAccessRights(member).includes('no_access');
};

overwriteIfDuplicatedMember = function(newMember, members) {
  var duplicatedMember;
  duplicatedMember = members.find(function(member) {
    return member.get('email') === newMember.get('email');
  });
  if (!duplicatedMember) {
    return newMember;
  }
  if (!_.isEmpty(duplicatedMember.get('lastName'))) {
    newMember = newMember["delete"]('lastName');
  }
  return duplicatedMember.merge(newMember["delete"]('_id')["delete"]('_rev'));
};

recoverIfDeleted = function(memberToRecover, members) {
  var deletedMember, email;
  email = memberToRecover.get('email');
  deletedMember = members.find(function(member) {
    return member.get('email') === email && member.get('deleted');
  });
  if (deletedMember) {
    return deletedMember.merge(memberToRecover["delete"]('_id')["delete"]('_rev'))["delete"]('deleted');
  } else {
    return memberToRecover;
  }
};

getMembersForProject = function(projectId) {
  var membersInStore;
  membersInStore = alt.stores.MembersStore.getState().get('members', Immutable.Map());
  if (membersInStore.isEmpty()) {
    return fetchProjectMembers(projectId);
  } else {
    return W.resolve({
      projectId: projectId,
      members: membersInStore
    });
  }
};

prepareRecipient = function(member, accessRights) {
  return {
    email: member.get('email'),
    givenNames: member.get('givenNames'),
    lastName: member.get('lastName'),
    panelMember: member.get('panelMember') || false,
    accessRights: accessRights
  };
};

MembersActions = (function() {
  function MembersActions() {
    this.generateActions('add', 'closeModal', 'deleteSuccess', 'deleteFailure', 'editSuccess', 'fetchSuccess', 'fetchError', 'filterByAccessRight', 'openModal', 'resetErrors', 'saveFailure', 'sort', 'updateValidationErrors', 'validationSuccess', 'validationFailure', 'validationsFailure', 'importFetchSuccess', 'importFetchFailure', 'closeImportModal', 'clearImportSearchText', 'setImportSearchText', 'toggleImportSelectAllMembers', 'toggleImportMember', 'importSuccess', 'importFailure');
  }

  MembersActions.prototype.dbChange = function(_arg) {
    var changes, doc;
    changes = _arg.changes, doc = _arg.doc;
    if (doc.docType === 'projectMember') {
      return this.dispatch(doc);
    }
  };

  MembersActions.prototype.manageAccessRights = function(projectId, currentAccessRights, newAccessRights, member) {
    var recipient, sharingService;
    if (currentAccessRights.equals(newAccessRights)) {
      return;
    }
    sharingService = mediator.services.projectSharing;
    member = Immutable.fromJS(member);
    if (currentAccessRights.first() === 'no_access' && newAccessRights.first() !== 'no_access') {
      recipient = prepareRecipient(member, newAccessRights);
      return sharingService.sendProjectInvitation(projectId, recipient, false);
    } else if (currentAccessRights.first() !== 'no_access' && newAccessRights.first() === 'no_access') {
      return sharingService.revokeAccess(projectId, member.get('email'));
    }
  };

  MembersActions.prototype.updateMember = function(memberId, attr, value) {
    return this.dispatch({
      memberId: memberId,
      attr: attr,
      value: value
    });
  };

  MembersActions.prototype.batchUpdateMember = function(memberId, updates) {
    return this.dispatch({
      memberId: memberId,
      updates: updates
    });
  };

  MembersActions.prototype.save = function(projectId, data) {
    if (Immutable.List.isList(data)) {
      return this.actions.saveMultiple(projectId, data);
    } else {
      return this.actions.saveSingle(projectId, data);
    }
  };

  MembersActions.prototype._validate = function(member, membersList) {
    return validateMemberAgainstMembersList(member, membersList).then((function(_this) {
      return function(validationErrors) {
        if (validationErrors.isEmpty()) {
          _this.actions.validationSuccess(member.get('_id'));
        } else {
          _this.actions.validationFailure({
            key: member.get('_id'),
            validationErrors: validationErrors
          });
        }
        return validationErrors;
      };
    })(this));
  };

  MembersActions.prototype.saveMultiple = function(projectId, members) {
    var currentMembersList, membersList, membersPromises;
    membersList = alt.stores.MembersStore.getState().get('members');
    membersPromises = members.map((function(_this) {
      return function(member) {
        return _this.actions._validate(member, membersList);
      };
    })(this)).toJS();
    currentMembersList = alt.stores.MembersStore.getState().get('members');
    return W.all(membersPromises).then((function(_this) {
      return function(validationErrorsList) {
        var hasNoErrors;
        hasNoErrors = _.every(validationErrorsList.reduce(function(acc, validationErrors) {
          acc.push(validationErrors.isEmpty());
          return acc;
        }, []));
        if (hasNoErrors) {
          members = members.map(function(member) {
            member = member.withMutations(function(m) {
              m["delete"]('_id');
              return m.set('docType', 'projectMember');
            });
            member = recoverIfDeleted(member, currentMembersList);
            if (getMemberAccessRights(member) === 'no_access') {
              return member;
            }
            return member.withMutations(function(m) {
              m.set('pendingInvitation', true);
              m.set('pendingInvitationAccessRights', m.get('accessRights'));
              return m.set('accessRights', Immutable.fromJS(['no_access']));
            });
          });
          return mediator.services.storePersistenceAdapter.bulkDocs(projectId, members.toJS()).then(function() {
            members.forEach(function(member) {
              var recipient;
              if (!member.get('pendingInvitation')) {
                return;
              }
              recipient = prepareRecipient(member, member.get('pendingInvitationAccessRights').toJS());
              return mediator.services.projectSharing.sendProjectInvitation(projectId, recipient, false);
            });
            return _this.actions.closeModal();
          })["catch"](function(e) {
            return _this.actions.saveFailure(e);
          });
        }
      };
    })(this));
  };

  MembersActions.prototype.saveSingle = function(projectId, member, newEditingMemberId) {
    var currentMembersList;
    if (newEditingMemberId == null) {
      newEditingMemberId = null;
    }
    currentMembersList = alt.stores.MembersStore.getState().get('members');
    return this.actions._validate(member, currentMembersList).then((function(_this) {
      return function(validationErrors) {
        member = recoverIfDeleted(member, currentMembersList);
        if (validationErrors.isEmpty()) {
          return _this.actions._save(projectId, member, newEditingMemberId);
        }
      };
    })(this));
  };

  MembersActions.prototype._save = function(projectId, member, newEditingMemberId) {
    var memberId, newAccessRights, previousAccessRights;
    if (newEditingMemberId == null) {
      newEditingMemberId = null;
    }
    memberId = member.get('_id');
    newAccessRights = getAccessRights(member);
    previousAccessRights = [];
    return mediator.services.storePersistenceAdapter.fetch(projectId, memberId)["catch"](function(e) {
      if (e.status === 404) {
        return {};
      } else {
        throw e;
      }
    }).then(function(previousMemberVersion) {
      previousMemberVersion = Immutable.fromJS(previousMemberVersion);
      previousAccessRights = getAccessRights(previousMemberVersion);
      if (!hasAccessGranted(previousMemberVersion) && hasAccessGranted(member)) {
        return member.withMutations(function(m) {
          m.set('invitationRejected', false);
          m.set('pendingInvitation', true);
          m.set('pendingInvitationAccessRights', newAccessRights);
          return m.set('accessRights', previousAccessRights);
        });
      } else {
        return member;
      }
    }).then(function(member) {
      return mediator.services.storePersistenceAdapter.save(projectId, member.toJS());
    }).then((function(_this) {
      return function(member) {
        _this.actions.manageAccessRights(projectId, previousAccessRights, newAccessRights, member);
        _this.actions.saveSuccess(member);
        if (newEditingMemberId !== null) {
          return _this.actions.edit(newEditingMemberId);
        }
      };
    })(this))["catch"]((function(_this) {
      return function(e) {
        return _this.actions.saveFailure(e);
      };
    })(this));
  };

  MembersActions.prototype.addMemberToProject = function(projectId, member) {
    return fetchProjectMembers(projectId).then((function(_this) {
      return function(_arg) {
        var members, projectId;
        projectId = _arg.projectId, members = _arg.members;
        member = Immutable.fromJS(member);
        members = Immutable.fromJS(members);
        member = recoverIfDeleted(member, members);
        member = overwriteIfDuplicatedMember(member, members);
        return _this.actions._save(projectId, member);
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        return _this.actions.fetchError(err);
      };
    })(this));
  };

  MembersActions.prototype.edit = function(memberId, currentlyEditedMember) {
    if (currentlyEditedMember == null) {
      currentlyEditedMember = null;
    }
    if (currentlyEditedMember) {
      return this.actions.save(currentlyEditedMember, memberId);
    } else {
      return this.dispatch(memberId);
    }
  };

  MembersActions.prototype.saveSuccess = function(memberJS) {
    this.dispatch(memberJS);
    return this.actions.closeModal();
  };

  MembersActions.prototype["delete"] = function(memberId) {
    var accessRights, email, memberModel, revokeAccess;
    memberModel = mediator.colls.teamMembers.get(memberId);
    if (!memberModel) {
      return this.actions.deleteSuccess(memberId);
    }
    accessRights = memberModel.getAccessRights();
    email = memberModel.get('email');
    revokeAccess = accessRights[0] !== 'no_access' && !isMemberDuplicated(email);
    memberModel.set('accessRights', ['no_access']);
    return mocHelpers.save(memberModel, {
      deleted: true
    }).then((function(_this) {
      return function(memberId) {
        if (revokeAccess) {
          mediator.services.projectSharing.revokeAccess(mediator.project.id, email);
        }
        return _this.actions.deleteSuccess(memberId);
      };
    })(this))["catch"]((function(_this) {
      return function(e) {
        return _this.actions.deleteFailure(e);
      };
    })(this));
  };

  MembersActions.prototype.fetch = function() {
    this.dispatch();
    return fetchProjectMembers(mediator.project.id).then((function(_this) {
      return function(_arg) {
        var members, projectId;
        projectId = _arg.projectId, members = _arg.members;
        _this.actions.fetchSuccess(members);
        return members;
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        return _this.actions.fetchError(err);
      };
    })(this));
  };

  MembersActions.prototype.openImportModal = function(source) {
    var err, url;
    if (source == null) {
      source = COI_PROJECT_ID;
    }
    if (mediator.services.replication.isConnected()) {
      this.dispatch();
      url = "" + (DbHelper.getBackendUrl()) + "/projects/" + source + "/panel-members";
      return W($.ajax(url, {
        xhrFields: {
          withCredentials: true
        }
      })).then(this.actions.importFetchSuccess)["catch"](this.actions.importFetchFailure);
    } else {
      err = new Exceptions.no_connection;
      return mediator.dialogs.error(err);
    }
  };

  MembersActions.prototype["import"] = function(memberIds, source, destination) {
    var err, options, url;
    if (source == null) {
      source = COI_PROJECT_ID;
    }
    if (destination == null) {
      destination = mediator.project.id;
    }
    if (mediator.services.replication.isConnected()) {
      this.dispatch();
      url = "" + (DbHelper.getBackendUrl()) + "/projects/panel-members/_copy";
      options = {
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify({
          memberIds: memberIds,
          from: source,
          to: destination
        }),
        type: 'POST',
        xhrFields: {
          withCredentials: true
        }
      };
      return W($.ajax(url, options)).then(this.actions.importSuccess)["catch"](this.actions.importFailure);
    } else {
      err = new Exceptions.no_connection;
      return mediator.dialogs.error(err);
    }
  };

  return MembersActions;

})();

module.exports = alt.createActions(MembersActions);
