var ReferenceCitation, editorUtils, getAttachmentUrl, mediator;

editorUtils = Editor.EditorUtils;

mediator = require('mediator');

ReferenceCitation = require('components/common/gdt_editor/reference_citation');

getAttachmentUrl = require('lib/references_utils').getAttachmentUrl;

module.exports = {
  referenceDecorator: {
    strategy: editorUtils.findTextByEntityType('REFERENCE'),
    component: ReferenceCitation,
    props: {
      getReferenceAttachmentUrl: function(referenceId) {
        return getAttachmentUrl(mediator.project.id, referenceId);
      }
    }
  }
};
