Translation = require 'components/mixins/translation'
Button = require 'components/common/button'
IconButton = require 'components/common/icon_button'
DocSectionsFromTemplateExportDialog =
  require 'components/document_sections/from_template/doc_sections_from_template_export_dialog'
DocSectionsFromTemplateStore = require 'stores/doc_sections_from_template_store'
DocSectionsFromTemplateActions = require 'actions/doc_sections_from_template_actions'
ConnectStore = require 'components/enhancers/connect_store'

storeConnectors =
  DocSectionsFromTemplateStore: (Store) ->
    chapters: Store.getChapters() or Immutable.Map()
    collapsedChapters: Store.getCollapsedChapters()
    templateId: Store.getTemplateId()

DocSectionsFromTemplateToolbar = createReactClass
  displayName: 'DocSectionsFromTemplateToolbar'

  propTypes:
    chapters: PropTypes.instanceOf(Immutable.Map)
    collapsedChapters: PropTypes.instanceOf(Immutable.List)
    templateId: PropTypes.string

  getInitialState: ->
    exportDialogOpened: false

  openExportDialog: ->
    @setState exportDialogOpened: true

  closeExportDialog: ->
    @setState exportDialogOpened: false

  mixins: [
    Translation('')
  ]

  render: ->
    { chapters, collapsedChapters, templateId } = @props
    allCollapsed = chapters.size is collapsedChapters.size

    clickAction = if allCollapsed
      DocSectionsFromTemplateActions.expandAllChapters
    else
      DocSectionsFromTemplateActions.collapseAllChapters

    labelKey = if allCollapsed then 'expand_all' else 'collapse_all'

    <div className='document-sections-from-template-toolbar'>
      {if @state.exportDialogOpened
        <DocSectionsFromTemplateExportDialog
          onClose={@closeExportDialog}
          templateId={templateId}
        />
      }
      <Button
        className="document-sections-from-template__toggle-button"
        shape="pill"
        onClick={clickAction}
        label={@i18n "actions.#{labelKey}"}
      />
      <IconButton
        iconName='export'
        title={@i18n 'actions.export'}
        onClick={@openExportDialog}
      />
    </div>

module.exports =
  ConnectStore DocSectionsFromTemplateToolbar, DocSectionsFromTemplateStore, storeConnectors
