var Ellipsis, Focusable, PresentationsExportView, PresentationsToolbarView, QuestionsHelper, QuestionsListComponentActions, ReactComponent, Scrolling, ToolbarQuestionsList, View, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

QuestionsListComponentActions = require('actions/questions_list_component_actions');

ToolbarQuestionsList = require('components/evidence_syntheses/toolbar_questions_list');

ReactComponent = require('base/lib/traits/react_component');

View = require('base/views/view');

Scrolling = require('base/lib/traits/scrolling');

Ellipsis = require('base/lib/traits/ellipsis');

Focusable = require('base/lib/traits/focusable');

template = require('views/templates/presentations_toolbar');

mediator = require('mediator');

QuestionsHelper = require('base/lib/questions_helper');

PresentationsExportView = require('views/presentations_export_view');

module.exports = PresentationsToolbarView = (function(_super) {
  __extends(PresentationsToolbarView, _super);

  function PresentationsToolbarView() {
    return PresentationsToolbarView.__super__.constructor.apply(this, arguments);
  }

  PresentationsToolbarView.prototype.template = template;

  PresentationsToolbarView.prototype.container = '#toolbar-container';

  PresentationsToolbarView.prototype.className = 'presentations-toolbar';

  PresentationsToolbarView.prototype.autoRender = true;

  PresentationsToolbarView.prototype.initialize = function() {
    PresentationsToolbarView.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.export', this.exportPresentations);
    return this.enable(ReactComponent);
  };

  PresentationsToolbarView.prototype.afterRender = function() {
    var _ref, _ref1, _ref2;
    PresentationsToolbarView.__super__.afterRender.apply(this, arguments);
    this.$('button.export').prop('disabled', _.result(this.options, 'disableExportButton') || !((_ref = this.model.get('recommendationIds')) != null ? _ref[0] : void 0));
    return this.renderComponent(ToolbarQuestionsList, QuestionsListComponentActions, null, {
      fetchParams: {
        questionsCollectionType: 'regular'
      },
      fetchAndListen: {
        dbId: mediator.project.id
      },
      props: {
        questionsCollectionType: 'regular',
        currentQuestion: (_ref1 = (_ref2 = this.model) != null ? _ref2.get('question') : void 0) != null ? _ref1 : $.t('toolbar.no_question_selected')
      }
    }, this.el.querySelector('.questions'));
  };

  PresentationsToolbarView.prototype.exportPresentations = function() {
    return this.subview('PresentationsExportView', new PresentationsExportView({
      model: this.model,
      submodule: this.submodule,
      closeBtn: false
    }));
  };

  return PresentationsToolbarView;

})(View);
