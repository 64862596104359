InfoIconWithTooltip = require 'components/common/info_icon_with_tooltip'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Tooltip = require 'components/common/tooltip'

Criterion = createReactClass
  displayName: 'CriterionComponent'
  mixins: [CustomRenderMixin]

  render: ->
    <td className="criteria-cell" rowSpan={@props.rowSpan}>
      <b>{@props.content}</b>
      {if @props.info
        <InfoIconWithTooltip info={@props.info} />
      }
    </td>

module.exports = Criterion
