var ModalView, Model, OutcomesSelectionView, W, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

ModalView = require('base/views/modal_view');

template = require('views/templates/evidence_syntheses/outcomes_selection');

W = require('when');

module.exports = OutcomesSelectionView = (function(_super) {
  __extends(OutcomesSelectionView, _super);

  function OutcomesSelectionView() {
    return OutcomesSelectionView.__super__.constructor.apply(this, arguments);
  }

  OutcomesSelectionView.prototype.className = 'choose-outcomes-form';

  OutcomesSelectionView.prototype.template = template;

  OutcomesSelectionView.prototype.title = $.t('es:recommendations.table.benefits_harms.choose_outcomes');

  OutcomesSelectionView.prototype.bindings = {
    '[name="outcomes"]': 'selection'
  };

  OutcomesSelectionView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = 50;
    this.model = new Model({
      selection: this.options.selection
    });
    OutcomesSelectionView.__super__.initialize.apply(this, arguments);
    this._deferred = W.defer();
    this.delegate('click', 'button.cancel', this.dispose);
    return this.delegate('click', 'button.ok', this._submit);
  };

  OutcomesSelectionView.prototype.promise = function() {
    return this._deferred.promise;
  };

  OutcomesSelectionView.prototype.afterRender = function() {
    OutcomesSelectionView.__super__.afterRender.apply(this, arguments);
    return this.stickit();
  };

  OutcomesSelectionView.prototype.dispose = function() {
    if (this._deferred.promise.inspect().state === 'pending') {
      this._deferred.reject('cancelled');
    }
    return OutcomesSelectionView.__super__.dispose.apply(this, arguments);
  };

  OutcomesSelectionView.prototype._submit = function(e) {
    return this._deferred.resolve(this.model.get('selection')).then(this.dispose);
  };

  OutcomesSelectionView.prototype.getTemplateData = function() {
    var o;
    return {
      outcomes: (function() {
        var _i, _len, _ref, _results;
        _ref = this.options.outcomes;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          o = _ref[_i];
          _results.push({
            id: o.id,
            name: o.get('name'),
            importance: o.get('importanceLabel')
          });
        }
        return _results;
      }).call(this)
    };
  };

  return OutcomesSelectionView;

})(ModalView);
