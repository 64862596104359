var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <div class=\"feedback-info-english\">\n    <span class=\"feedback-info-sign\"></span>\n    <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "english_info", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n  </div>\n  <div class=\"form\">\n    <div class=\"form-group\">\n      <div class=\"col-1\">\n        <label>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "type", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n      </div>\n      <div class=\"col-11\">\n";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "types", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </div>\n    <div class=\"form-group\">\n      <div class=\"col-12\">\n          <textarea class=\"text\" placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "text_placeholder", {"name":"i18n","hash":{},"data":data})))
    + "\"></textarea>\n          <label>\n            <input type=\"checkbox\" class=\"attach-screenshot\" checked>\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "attach_screenshot", {"name":"i18n","hash":{},"data":data})))
    + "\n          </label>\n      </div>\n    </div>\n      <div class=\"row\">\n        <div class=\"col-6 mt-20\">\n          <button class=\"btn btn-block btn-cancel cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n        </div>\n        <div class=\"col-6 mt-20\">\n          <button class=\"btn btn-block btn-apply send\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "send", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n        </div>\n      </div>\n  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <select class=\"type form-control\">\n              <option value=\"defect\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "defect", {"name":"i18n","hash":{},"data":data})))
    + "</option>\n              <option value=\"improvement\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "improvement", {"name":"i18n","hash":{},"data":data})))
    + "</option>\n              <option value=\"question\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "question", {"name":"i18n","hash":{},"data":data})))
    + "</option>\n              <option value=\"other\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "other", {"name":"i18n","hash":{},"data":data})))
    + "</option>\n            </select>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "feedback", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}