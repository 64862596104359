CustomRenderMixin = require 'components/mixins/custom_render_mixin'

Question = createReactClass
  displayName: 'QuestionComponent'
  mixins: [CustomRenderMixin]
  propTypes:
    question: PropTypes.string.isRequired

  getDefaultProps: ->
    className: 'question'

  render: ->
    <div className={@props.className}>
      <span>{@props.question}</span>
    </div>

module.exports = Question
