var AdaptiveLayout, CollectionView, ViewTrait,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

ViewTrait = require('./view_trait');

CollectionView = require('base/views/collection_view');

module.exports = AdaptiveLayout = (function(_super) {
  __extends(AdaptiveLayout, _super);

  function AdaptiveLayout() {
    return AdaptiveLayout.__super__.constructor.apply(this, arguments);
  }

  AdaptiveLayout.prototype.apply = function(view) {
    var triggerFixLayout;
    AdaptiveLayout.__super__.apply.apply(this, arguments);
    if (view.fixLayout) {
      view.off(null, null, 'AdaptiveLayout');
      view.unsubscribeEvent('windowResized', view.fixLayout);
    }
    this._addFunction(view, 'fixLayout');
    this._addFunction(view, 'dispose');
    triggerFixLayout = function() {
      return view.trigger('!fixLayout');
    };
    if (view instanceof CollectionView) {
      view.on('visibilityChange', _(triggerFixLayout).debounce(50), 'AdaptiveLayout');
    } else if (view.parentView != null) {
      view.parentView.on('!fixLayout', _.once(triggerFixLayout), 'AdaptiveLayout');
    } else {
      view.on('addedToDOM', triggerFixLayout, 'AdaptiveLayout');
    }
    view.on('!fixLayout', view.fixLayout, 'AdaptiveLayout');
    return view.subscribeEvent('windowResized', view.fixLayout);
  };

  AdaptiveLayout.prototype.fixLayout = function() {
    var args, originalFunction, trait;
    originalFunction = arguments[0], trait = arguments[1], args = 3 <= arguments.length ? __slice.call(arguments, 2) : [];
    if (originalFunction) {
      return originalFunction.apply(this, args);
    }
  };

  AdaptiveLayout.prototype.dispose = function() {
    var args, originalFunction, trait;
    originalFunction = arguments[0], trait = arguments[1], args = 3 <= arguments.length ? __slice.call(arguments, 2) : [];
    this.off(null, null, 'AdaptiveLayout');
    this.unsubscribeEvent('windowResized', this.fixLayout);
    return originalFunction.apply(this, args);
  };

  return AdaptiveLayout;

})(ViewTrait);
