{ useCoffeeCallback, useCoffeeEffect } = require 'lib/react_utils'
{ useState } = React

StringField = ({ className, onChange, placeholder, step, type, useControlledInput, value }) ->
  [text, setText] = useState(value)

  onBlur = useCoffeeCallback [onChange, text], -> onChange text

  onStringFieldChange = useCoffeeCallback [onChange, setText, useControlledInput], (evt) ->
    if useControlledInput
      onChange evt.target.value
    else
      setText evt.target.value

  useCoffeeEffect [setText, value], -> setText value

  <input
    className={className}
    onBlur={if useControlledInput then undefined else onBlur}
    onChange={onStringFieldChange}
    placeholder={placeholder}
    step={step}
    type={type}
    value={if useControlledInput then value else text}
  />

StringField.propTypes =
  className: PropTypes.string
  onChange: PropTypes.func.isRequired
  placeholder: PropTypes.string
  step: PropTypes.number
  type: PropTypes.oneOf(['text', 'number'])
  useControlledInput: PropTypes.bool
  value: PropTypes.string.isRequired

StringField.defaultProps =
  className: ''
  placeholder: ''
  type: 'text'
  useControlledInput: false
  value: ''

module.exports = StringField
