Translation = require 'components/mixins/translation'

AddSendButtons = createReactClass
  displayName: 'AddSendButtons'

  propTypes:
    onAddTxQuestion: PropTypes.func.isRequired
    onAddDxQuestion: PropTypes.func.isRequired
    onAddFreeQuestion: PropTypes.func.isRequired
    onCloseStep: PropTypes.func.isRequired

  mixins: [
    Translation('scope:questions')
  ]

  render: ->
    <div>
      <div className='row mt-10'>
        <div className='col-4'>
          <button className='btn btn-block add-management-question'
            onClick={@props.onAddTxQuestion}
          >
            {@i18n '/es:question.add_title'}
          </button>
        </div>
        <div className='col-4'>
          <button className='btn btn-block add-diagnostic-question'
            onClick={@props.onAddDxQuestion}
          >
            {@i18n '/es:question.add_diagnostic_title'}
          </button>
        </div>
        <div className='col-4'>
          <button className='btn btn-block add-freeform-question'
            onClick={@props.onAddFreeQuestion}
          >
            {@i18n '/es:question.add_freeform_title'}
          </button>
        </div>
      </div>
      <div className='bottom-bar row mt-10'>
        <div className='col-12'>
          <button
            className='btn btn-block btn-send'
            onClick={@props.onCloseStep}
            disabled={@props.isClosingStep}
          >
            {@i18n 'initial.next_step'}
          </button>
        </div>
      </div>
    </div>

module.exports = AddSendButtons
