var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div class=\"time-to-event-modal\">\n  <span class=\"close\">&times;</span>\n  <div>\n    <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "confirm_msg", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n    <p>";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "grade_handbook", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</p>\n    <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "which_category_best_describes", {"name":"i18n","hash":{},"data":data})))
    + " \""
    + escapeExpression(((helper = (helper = helpers.outcome || (depth0 != null ? depth0.outcome : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"outcome","hash":{},"data":data}) : helper)))
    + "\"</p>\n    <div>\n      <label>\n        <input type='radio' name=\"eventType\" value=\"event\" "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.eventType : depth0), "event", {"name":"checkedIfEq","hash":{},"data":data})))
    + "/>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "event_option", {"name":"i18n","hash":{},"data":data})))
    + "\n      </label>\n      <label>\n        <input\n          type='radio'\n          name=\"eventType\"\n          value=\"non_event\"\n         "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.eventType : depth0), "non_event", {"name":"checkedIfEq","hash":{},"data":data})))
    + "\n        />\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "non_event_option", {"name":"i18n","hash":{},"data":data})))
    + "\n      </label>\n      <div class=\"non-event-input\">\n        <div>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "provide_a_name", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n        <input id=\"non-event\" data-property=\"nonEvent\"\n          name=\"nonEvent\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.nonEvent || (depth0 != null ? depth0.nonEvent : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"nonEvent","hash":{},"data":data}) : helper)))
    + "\"\n          placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "non_event_placeholder", {"name":"i18n","hash":{},"data":data})))
    + "\"\n          ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.eventType : depth0), "event", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        >\n      </div>\n    </div>\n    <div class=\"row button-row\">\n      <div class=\"col-6\">\n        <button class=\"btn btn-cancel\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.cancel", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n      </div>\n      <div class=\"col-6\">\n        <button class=\"btn btn-apply\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/actions.save", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "style=\"display: none\"";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome.messages.time_to_event", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}