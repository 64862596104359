var useEffect, useRef;

useEffect = React.useEffect, useRef = React.useRef;

module.exports = function(value) {
  var ref;
  ref = useRef();
  useEffect(function() {
    ref.current = value;
  }, [value]);
  return ref.current;
};
