var DOCUMENT_SECTIONS_V2_SECTION_TYPE, DocumentSectionsV2Actions, PMState, Plugin, PluginKey, ReactTooltipView, SectionLinkEditor, SectionLinkTooltipView, findSelectedMarkByType, getSelectedLink, mediator, sectionLinkTooltipPluginKey,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

DocumentSectionsV2Actions = require('actions/document_sections_v2_actions');

SectionLinkEditor = require('./section_link_editor');

DOCUMENT_SECTIONS_V2_SECTION_TYPE = require('lib/db_docs/doc_types').DOCUMENT_SECTIONS_V2_SECTION_TYPE;

PMState = Epiditor.PMState, ReactTooltipView = Epiditor.ReactTooltipView, findSelectedMarkByType = Epiditor.findSelectedMarkByType;

Plugin = PMState.Plugin, PluginKey = PMState.PluginKey;

sectionLinkTooltipPluginKey = new PluginKey('documentSectionsLinkPlugin');

SectionLinkTooltipView = (function(_super) {
  __extends(SectionLinkTooltipView, _super);

  function SectionLinkTooltipView() {
    return SectionLinkTooltipView.__super__.constructor.apply(this, arguments);
  }

  SectionLinkTooltipView.prototype.isVisible = function(view) {
    return sectionLinkTooltipPluginKey.getState(view.state) != null;
  };

  SectionLinkTooltipView.prototype.dispatchAndClose = function(view, tr) {
    view.focus();
    return view.dispatch(tr.setMeta(sectionLinkTooltipPluginKey, {
      action: 'close'
    }));
  };

  SectionLinkTooltipView.prototype.handleSectionLinkCreate = function(view, payload) {
    var chapterLink, from, id, linkMark, pluginState, sectionLink, state, to, tr, type, _ref, _ref1;
    pluginState = sectionLinkTooltipPluginKey.getState(view.state);
    if (pluginState === null) {
      return;
    }
    id = payload.id, type = payload.type;
    state = view.state;
    _ref = state.schema.marks, sectionLink = _ref.sectionLink, chapterLink = _ref.chapterLink;
    _ref1 = pluginState.range, from = _ref1[0], to = _ref1[1];
    linkMark = type === DOCUMENT_SECTIONS_V2_SECTION_TYPE ? sectionLink.create({
      sectionId: id
    }) : chapterLink.create({
      chapterId: id
    });
    tr = state.tr.addMark(from, to, linkMark);
    return this.dispatchAndClose(view, tr.setSelection(PMState.TextSelection.create(tr.selection.$from.doc, to)));
  };

  SectionLinkTooltipView.prototype.handleSectionLinkRemove = function(view) {
    var chapterLink, from, linkMark, pluginState, sectionLink, to, tr, _ref, _ref1;
    pluginState = sectionLinkTooltipPluginKey.getState(view.state);
    if (pluginState === null) {
      return;
    }
    _ref = view.state.schema.marks, sectionLink = _ref.sectionLink, chapterLink = _ref.chapterLink;
    linkMark = pluginState.sectionId ? sectionLink : chapterLink;
    _ref1 = pluginState.range, from = _ref1[0], to = _ref1[1];
    tr = view.state.tr.removeMark(from, to, linkMark);
    return this.dispatchAndClose(view, tr);
  };

  SectionLinkTooltipView.prototype.goToSection = function(view) {
    var pluginState, projectId;
    projectId = mediator.project.id;
    pluginState = sectionLinkTooltipPluginKey.getState(view.state);
    if ('sectionId' in pluginState) {
      DocumentSectionsV2Actions.setScrollToSection({
        sectionId: pluginState.sectionId,
        projectId: projectId
      });
    }
    if ('chapterId' in pluginState) {
      DocumentSectionsV2Actions.setScrollToChapter(pluginState.chapterId);
    }
    return view.dispatch(view.state.tr.setMeta(sectionLinkTooltipPluginKey, {
      action: 'close'
    }));
  };

  SectionLinkTooltipView.prototype.renderTooltip = function(view) {
    var state;
    state = sectionLinkTooltipPluginKey.getState(view.state);
    return React.createElement(SectionLinkEditor, {
      isNew: !(state != null ? state.sectionId : void 0) && !(state != null ? state.chapterId : void 0),
      onCreate: (function(_this) {
        return function(payload) {
          return _this.handleSectionLinkCreate(view, payload);
        };
      })(this),
      onGoToSection: (function(_this) {
        return function() {
          return _this.goToSection(view);
        };
      })(this),
      onRemoveLink: (function(_this) {
        return function() {
          return _this.handleSectionLinkRemove(view);
        };
      })(this)
    });
  };

  return SectionLinkTooltipView;

})(ReactTooltipView);

getSelectedLink = function(state) {
  var chapterId, chapterLink, maybeFoundChapterLink, maybeFoundSectionLink, sectionId, sectionLink, _ref;
  _ref = state.schema.marks, sectionLink = _ref.sectionLink, chapterLink = _ref.chapterLink;
  maybeFoundSectionLink = findSelectedMarkByType(state, sectionLink);
  if (maybeFoundSectionLink) {
    sectionId = maybeFoundSectionLink.mark.attrs.sectionId;
    return {
      sectionId: sectionId,
      range: maybeFoundSectionLink.range
    };
  }
  maybeFoundChapterLink = findSelectedMarkByType(state, chapterLink);
  if (maybeFoundChapterLink) {
    chapterId = maybeFoundChapterLink.mark.attrs.chapterId;
    return {
      chapterId: chapterId,
      range: maybeFoundChapterLink.range
    };
  }
  return null;
};

module.exports = {
  sectionLinkTooltipPluginKey: sectionLinkTooltipPluginKey,
  "default": function(_arg) {
    var portalsAPI;
    portalsAPI = _arg.portalsAPI;
    return new Plugin({
      key: sectionLinkTooltipPluginKey,
      state: {
        init: function(_config, initialState) {
          return getSelectedLink(initialState);
        },
        apply: function(tr, prev, oldState, newState) {
          var action, maybeSelectedLink, _ref;
          action = (_ref = tr.getMeta(sectionLinkTooltipPluginKey)) != null ? _ref.action : void 0;
          if (action === 'create') {
            return {
              range: [newState.selection.from, newState.selection.to]
            };
          }
          if (action === 'close') {
            return null;
          }
          maybeSelectedLink = getSelectedLink(newState);
          if (maybeSelectedLink) {
            return maybeSelectedLink;
          }
          if (oldState.selection === newState.selection) {
            return prev;
          }
          return null;
        }
      },
      view: function(editorView) {
        return new SectionLinkTooltipView(editorView, portalsAPI);
      }
    });
  }
};
