IconButton = require 'components/common/icon_button'

SummaryOfJudgmentSectionNameCell = ({
  displayCellContent
  onHideSection
  renderMode
  sectionHidden
  sectionName
}) ->
  iconName = if sectionHidden then "eye" else "blind-eye"

  <td className='section-name'>
    {renderMode isnt 'printout' &&
      <IconButton
        className={classNames 'soj__hide-row-button',
          'soj__hide-row-button--section-hide': sectionHidden
        }
        iconName={iconName}
        onClick={onHideSection}
      />
    }
    {displayCellContent &&
      <p>{sectionName}</p>
    }
  </td>

module.exports = SummaryOfJudgmentSectionNameCell
