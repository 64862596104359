LoadingBox = require 'components/common/loading_box'
QuestionsList = require 'components/scope/questions/questions_list'
QuestionWithComments = require 'components/scope/questions/question_with_comments'
Question = require 'components/scope/questions/question'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ScopeDataFetchMixin = require 'components/mixins/scope_data_fetch_mixin'
QuestionsShowCommentsMixin = require 'components/mixins/questions_show_comments_mixin'

{ Button } = ReactComponents

BrainstormingTab = createReactClass
  displayName: 'BrainstormingTab'

  propTypes:
    questions: PropTypes.instanceOf(Immutable.List).isRequired
    readOnly: PropTypes.bool.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:questions.brainstorming')
    ScopeDataFetchMixin
    QuestionsShowCommentsMixin
  ]

  render: ->
    <div className='questions-brainstorming'>
      <div className='information'>
        <div dangerouslySetInnerHTML={__html: @i18n 'header', icon: '\e018'} />
      </div>
      <div className='buttons'>
        <Button
          className='btn-refresh'
          onClick={@props.fetchResults}
          disabled={@_isFetchDisabled()}
        >
          {@i18n 'refresh'}
        </Button>
        <Button
          className='show-all-comments'
          onClick={@showComments}
          disabled={@_isShowCommentsDisabled()}
        >
          {@i18n '../show_all_comments'}
        </Button>
      </div>
      {if @props.isFetchingResults
        <LoadingBox />
      else
        <div className='questions'>
          <QuestionsList
            questions={@props.questions}
            membersMap={@props.membersMap}
            showingAllComments={@state.showingAllComments}
            onCommentToggle={@onItemCommentToggle}
            onApplyAdminComment={@props.onApplyAdminComment}
            readOnly={@props.readOnly}
          />
        </div>
      }
      {unless @props.readOnly
        <div className='bottom-bar row mt-20'>
          <div className='col-12'>
            <button
              className='btn btn-block btn-send'
              onClick={@props.onCloseStep}
              disabled={@props.isClosingStep}
            >
              {@i18n 'next_step'}
            </button>
          </div>
        </div>
      }
    </div>

module.exports = BrainstormingTab
