Checkbox = require 'components/common/checkbox_input'
DocSection = require 'components/partials/document_section'
GeneratedOutcomesTable = require 'components/partials/generated_outcomes_table'
ReferencesInsertModal = require 'components/etd/references_insert_modal'
Modal = require 'components/common/modal'
ExportDialog = require 'components/common/export_dialog'
DocumentSectionsActions = require 'actions/document_sections_actions'
DocumentSectionsStore = require 'stores/document_sections_store'
QuestionsGenStore = require 'stores/questions_generation_store'
OutcomesGenStore = require 'stores/outcomes_generation_store'
ReferencesStore = require 'stores/references_store'
CreateStoreMixin = require 'components/mixins/create_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ReferenceCitationMixin = require 'components/mixins/reference_citation_mixin'
FetchingSpinner = require 'components/mixins/fetching_spinner'
Translation = require 'components/mixins/translation'
{ DOC_SECTIONS } = require 'lib/document_sections_utils'
{ getExportHTML, referenceIdsFromContent } = require 'lib/references_utils'
{ exportToFile } = require 'lib/export_to_file'

{ willAlterProtectedBlock } = Editor.EditorUtils
GdtEditor = Editor.Editor

styles =
  editorContainer:
    position: 'relative'
    display: 'inline-block'
    marginTop: '30px'
    width: '80%'
  sideBlock:
    marginTop: '30px'
    paddingLeft: '20px'
    float: 'right'
    width: '20%'
    fontSize: '1.6rem'
  sectionsList:
    listStyle: 'none'
    padding: '0'
    cursor: 'pointer'
  checkbox:
    marginRight: '10px'

getCustomHandlers = ->
  'handleBeforeInput': (editorState) ->
    # prevent altering protected blocks
    return editorState if willAlterProtectedBlock editorState, 'input'
    null
  'handleKeyCommand': (editorState, command) ->
    # don't do anything if this operation alters protected block
    return editorState if willAlterProtectedBlock editorState, command
    null
  'handlePastedText': (editorState) ->
    # don't do anything if this will alter protected blocks
    return editorState if willAlterProtectedBlock editorState, 'paste'
    null

getCustomBlockMapEntries = ->
  'DOCUMENT_SECTION':
    element: 'div'

getCustomEntityRenderers = ->
  'OUTCOMES_TABLE': (Editor) ->
    component: GeneratedOutcomesTable
    editable: false

DocumentSections = createReactClass
  displayName: 'DocumentSections'

  mixins: [
    CustomRenderMixin
    ReferenceCitationMixin()
    CreateStoreMixin(DocumentSectionsStore, QuestionsGenStore, OutcomesGenStore, ReferencesStore)
    FetchingSpinner
    Translation('docsec:sections')
  ]

  _refCb: (el) ->
    @gdtEditor = el
    return unless @gdtEditor
    draftEditorNode = ReactDOM.findDOMNode @gdtEditor.editor
    draftEditorNode.style.maxHeight = 'calc(100vh - 165px)'
    draftEditorNode.style.overflowY = 'auto'

  _getCustomBlockRenderers: ->
    'DOCUMENT_SECTION': (Editor, block) =>
      { activeSections } = @state

      component: DocSection
      props:
        isSectionActive: (sectionName) -> activeSections.contains sectionName

  getStateFromStores: ->
    content: DocumentSectionsStore.getContent()
    activeSections: DocumentSectionsStore.getActiveSections()
    isFetching: DocumentSectionsStore.isFetching() or
      QuestionsGenStore.isFetching() or
      OutcomesGenStore.isFetching() or
      ReferencesStore.isFetching()

  getEditorContent: ->
    @gdtEditor.getEditorContent()

  scrollToSection: (sectionName) ->
    editorNode = ReactDOM.findDOMNode @gdtEditor
    selectedSectionNode = editorNode.querySelector "[data-section-name=\"#{sectionName}\"]"
    selectedSectionNode?.scrollIntoView()

  toggleExportDialog: ->
    isShowing = @state.showExportDialog
    @setState showExportDialog: not isShowing

  toggleSection: (sectionName) -> =>
    DocumentSectionsActions.toggleSection sectionName
    @activeSectionsChanged ?= true
    @gdtEditor.triggerBlocksRerender()

  exportToFile: ({ outputFormat }) ->
    editorContent = @gdtEditor.getEditorContent()
    editorHTML = document.querySelector('.public-DraftEditor-content').outerHTML
    references = ReferencesStore.getReferenceByIds referenceIdsFromContent editorContent
    HTMLString = getExportHTML references, editorHTML

    exportToFile outputFormat, HTMLString
    @toggleExportDialog()

  saveContent: ->
    currentContent = @getEditorContent().toJS()
    { projectId } = @props
    DocumentSectionsActions.saveContent currentContent, @state.activeSections.toJS(), projectId

  componentWillUnmount: ->
    currentContent = @getEditorContent()
    contentHasChanged = not currentContent.equals @state.content
    @saveContent() if contentHasChanged or @activeSectionsChanged

  renderFetched: ->
    <div>
      <div style={styles.editorContainer}>
        <GdtEditor
          ref={@_refCb}
          editorContent={@state.content?.toJS()}
          controlsAutoHide={false}
          customControls={[@getReferenceToggleSpec()]}
          customHandlers={getCustomHandlers()}
          customDecorators={@getReferenceCitationDecorator()}
          customEntityRenderers={getCustomEntityRenderers()}
          customBlockMapEntries={getCustomBlockMapEntries()}
          customBlockRenderers={@_getCustomBlockRenderers()}
        />
      </div>
      <div style={styles.sideBlock}>
        <span>
          <b>{@i18n '../sections_list'}</b>
        </span>
        <ul style={styles.sectionsList}>
          {DOC_SECTIONS.map (sectionName, idx) =>
            isActive = @state.activeSections.contains sectionName
            <li key={idx} className={classNames 'disabled': not isActive}>
              <Checkbox
                style={styles.checkbox}
                checked={isActive}
                onChange={@toggleSection sectionName}
              />
              <span onClick={=> @scrollToSection sectionName}>
                <b>{@i18n sectionName}</b>
              </span>
            </li>
          }
        </ul>
        <button
          className='btn btn-block btn-export mt-20'
          title={@i18n '../export_title'}
          onClick={@toggleExportDialog}
        >
          {" #{@i18n '../export'}"}
        </button>
      </div>
      {if @state.showExportDialog
        <Modal isOpen
          className="export-comments-modal"
          closeButton={false}
          >
          <ExportDialog
            onClose={@toggleExportDialog}
            onDownload={@exportToFile}
          />
        </Modal>
      }
      {if @state.showReferencesInsert
        <ReferencesInsertModal
          onInsert={@onInsertReferences}
          onClose={@toggleReferencesInsert}
        />
      }
    </div>

module.exports = DocumentSections
