var Footnotes, FootnotesList, ModelTrait,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModelTrait = require('base/lib/traits/model_trait');

Footnotes = require('models/evidence_syntheses/footnotes');

module.exports = FootnotesList = (function(_super) {
  __extends(FootnotesList, _super);

  function FootnotesList() {
    return FootnotesList.__super__.constructor.apply(this, arguments);
  }

  FootnotesList.prototype.apply = function(model) {
    FootnotesList.__super__.apply.apply(this, arguments);
    this._addFunction(model, 'initialize');
    this._addFunction(model, 'parse');
    this._addFunction(model, 'addFootnote');
    this._addFunction(model, 'updateFootnote');
    this._addFunction(model, 'deleteFootnote');
    model.on('change:footnotesList', (function(_this) {
      return function(question, footnotesList) {
        return model.set('footnotesList', _this._parseFootnotes(footnotesList), {
          silent: true
        });
      };
    })(this));
    if (!model.get('footnotesList')) {
      model.set('footnotesList', new Footnotes());
    } else if (!(model.get('footnotesList') instanceof Footnotes)) {
      model.set('footnotesList', this._parseFootnotes(model.get('footnotesList')));
    }
    return model._dirty = false;
  };

  FootnotesList.prototype._parseFootnotes = function(footnotesList) {
    if (footnotesList instanceof Footnotes) {
      return footnotesList;
    }
    return new Footnotes(footnotesList);
  };

  FootnotesList.prototype.parse = function(originalFunction, trait, response) {
    response = originalFunction.call(this, response);
    if (response.footnotesList) {
      response.footnotesList = trait._parseFootnotes(response.footnotesList);
    }
    return response;
  };

  FootnotesList.prototype.addFootnote = function() {
    return this.get('footnotesList').addNote('');
  };

  FootnotesList.prototype.updateFootnote = function(originalFunction, trait, noteId, newVal) {
    return this.get('footnotesList').updateNote(noteId, newVal);
  };

  FootnotesList.prototype.deleteFootnote = function(originalFunction, trait, noteId) {
    this.get('footnotesList').removeNote(noteId);
    return this.publishEvent('footnoteDeleted');
  };

  return FootnotesList;

})(ModelTrait);
