ConnectStore = require 'components/enhancers/connect_store'
{ getCreatorFields } = require 'lib/key_messages_helper'
KeyMessagesActions = require 'actions/key_messages_actions'
KeyMessagesStore = require 'stores/key_messages_store'
mediator = require 'mediator'

{ QuestionCreator } = require 'components/common/question_creator'
Router = require 'router'
W = require 'when/when'

storeConnector =
  KeyMessagesStore: (Store) ->
    isFetching: Store.isFetching()
    question: Store.getCurrentQuestion()

KeyMessageQuestionCreator = createReactClass
  displayName: 'KeyMessageQuestionCreator'
  propTypes:
    creating: PropTypes.bool.isRequired
    isFetching: PropTypes.bool.isRequired
    question: PropTypes.instanceOf(Immutable.Map).isRequired
    questionId: PropTypes.string.isRequired

  componentDidMount: ->
    { questionId } = @props
    KeyMessagesActions.fetchOne questionId

  componentWillUnmount: ->
    KeyMessagesActions.destroyCurrentQuestion()

  _navigateToQuestionsList: ->
    route = Router::getProjectRelativeUrl("/evidence-syntheses")
    mediator.publish '!router:route', route

  onCancel: ->
    { creating } = @props
    promise = if creating
      KeyMessagesActions.deleteCurrentQuestion()
    else
      W.resolve()
    promise.then => @_navigateToQuestionsList()

  onChange: (field) -> (value) ->
    KeyMessagesActions.updateCurrentQuestion { field, value }

  onSave: ->
    KeyMessagesActions.saveCurrentQuestion().then => @_navigateToQuestionsList()

  render: ->
    { creating, isFetching, question } = @props

    <QuestionCreator
      creating={creating}
      fields={getCreatorFields KeyMessagesActions}
      isFetching={isFetching}
      onCancel={@onCancel}
      onChange={@onChange}
      onSave={@onSave}
      question={question}
    />

module.exports = ConnectStore KeyMessageQuestionCreator, KeyMessagesStore, storeConnector
