var DocumentSectionsToolbarView, DocumentSectionsView, DraftQuestions, Exceptions, FileSaver, Shortcuts, View, W, content_template, mediator, template, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DraftQuestions = require('base/models/draft_questions');

View = require('base/views/view');

template = require('views/templates/document_sections');

utils = require('base/lib/utils');

DocumentSectionsToolbarView = require('views/document_sections_toolbar_view');

Shortcuts = require('base/lib/traits/shortcuts');

FileSaver = require('lib/file_saver');

content_template = require('views/templates/document_sections_content');

mediator = require('mediator');

Exceptions = require('lib/exceptions');

W = require('when');

module.exports = DocumentSectionsView = (function(_super) {
  __extends(DocumentSectionsView, _super);

  function DocumentSectionsView() {
    this._getEditorContent = __bind(this._getEditorContent, this);
    return DocumentSectionsView.__super__.constructor.apply(this, arguments);
  }

  DocumentSectionsView.prototype.container = '#page-container';

  DocumentSectionsView.prototype.id = 'document-sections-container';

  DocumentSectionsView.prototype.template = template;

  DocumentSectionsView.prototype.autoRender = true;

  DocumentSectionsView.prototype.sectionsIds = ['document-title', 'document-subtitle', 'authors', 'disclosure-coi', 'review-group', 'table-of-contents', 'executive-summary', 'introduction', 'methodology', 'how-to-use', 'key-questions', 'recommendations', 'introduction-and-background', 'scope', 'methods', 'group-composition', 'group-meetings', 'appendices'];

  DocumentSectionsView.prototype.initialize = function() {
    DocumentSectionsView.__super__.initialize.apply(this, arguments);
    this.delegate('click', '.sections li', this.navigateTo);
    this.delegate('click', 'button.export', this._exportContent);
    return this.enable(Shortcuts);
  };

  DocumentSectionsView.prototype.loadGeneratedOutcomes = function() {
    return mediator.project.getDocFor('outcomes_generation', false).then((function(_this) {
      return function(doc) {
        return _this.generatedOutcomesGroups = doc.getGeneratedOutcomesGroups();
      };
    })(this)).otherwise(function(e) {
      if (!(e instanceof Exceptions.document_missing)) {
        throw e;
      }
    });
  };

  DocumentSectionsView.prototype.loadGeneratedQuestions = function() {
    return mediator.project.getDocFor('questions_generation', false).then((function(_this) {
      return function(doc) {
        return _this.draftQuestions = new DraftQuestions(doc.getGeneratedQuestions());
      };
    })(this)).otherwise((function(_this) {
      return function(e) {
        if (e.status === 404 || e.name === 'general.document_missing') {
          return _this.draftQuestions = _this.model.get('draftQuestions');
        }
      };
    })(this));
  };

  DocumentSectionsView.prototype.render = function() {
    this.loadingDialog = mediator.dialogs.loading({
      ms: null
    });
    return W.all([this.loadGeneratedOutcomes(), this.loadGeneratedQuestions()]).ensure((function(_this) {
      return function() {
        var _ref;
        _this.loadingDialog.destroy();
        _this.startingContent = content_template(_this.getTemplateData());
        _this.editorContent = (_ref = _this.model.get('documentSections')) != null ? _ref : _this.startingContent;
        _this.subview('toolbar', new DocumentSectionsToolbarView({
          model: _this.model,
          frameContentSelector: '#document-sections-container iframe',
          startingContent: _this.startingContent
        }));
        DocumentSectionsView.__super__.render.apply(_this, arguments);
        return _this._createEditor();
      };
    })(this));
  };

  DocumentSectionsView.prototype._createEditor = function() {
    return this.on('addedToDOM', new TINY.editor.edit('editor', {
      id: 'document-sections',
      width: '100%',
      height: 400,
      cssclass: 'te',
      controlclass: 'tecontrol',
      rowclass: 'teheader',
      dividerclass: 'tedivider',
      controls: ['bold', 'italic', 'underline', 'strikethrough', '|', 'subscript', 'superscript', '|', 'orderedlist', 'unorderedlist', '|', 'outdent', 'indent', '|', 'leftalign', 'centeralign', 'rightalign', 'blockjustify', '|', 'unformat', '|', 'undo', 'redo', 'n', 'font', 'size', 'style', '|', 'image', 'hr', 'link', 'unlink'],
      footer: true,
      fonts: ['Verdana', 'Arial', 'Georgia', 'Trebuchet MS'],
      xhtml: true,
      content: this.editorContent,
      bodyid: 'editor',
      footerclass: 'tefooter',
      toggle: {
        text: 'source',
        activetext: 'wysiwyg',
        cssclass: 'toggle'
      },
      resize: {
        cssclass: 'resize'
      }
    }));
  };

  DocumentSectionsView.prototype.navigateTo = function(e) {
    var $target, iframeContents;
    $target = $(e.target);
    iframeContents = this.$('iframe').contents();
    return iframeContents.scrollTop(iframeContents.find("#" + ($target.attr('name'))).offset().top);
  };

  DocumentSectionsView.prototype.getTemplateData = function() {
    return {
      sections: _.object(_(this.sectionsIds).map(function(sid) {
        return [sid, utils.dashToUnderscore(sid)];
      })),
      projectName: this.model.get('name'),
      mentionedQuestions: _.map(this.draftQuestions.where({
        approvalStatus: 'mentioned'
      }), function(q) {
        return q.get('question');
      }),
      rejectedQuestions: _.map(this.draftQuestions.where({
        approvalStatus: 'rejected'
      }), function(q) {
        return q.get('question');
      }),
      generatedOutcomes: this.generatedOutcomesGroups
    };
  };

  DocumentSectionsView.prototype._getEditorContent = function() {
    return this.$('iframe').contents().find('#editor').html();
  };

  DocumentSectionsView.prototype._exportContent = function() {
    var blob, content, fileName;
    content = this._getEditorContent();
    fileName = _.str.slugify(this.model.get('name')) + '.html';
    blob = new Blob(['<html><head><meta charset="utf-8"></head><body>', content, '</body></html>'], {
      type: 'text/html;charset=utf-8'
    });
    return FileSaver.saveFile(blob, fileName).otherwise(function(reason) {
      if (reason !== 'cancelled') {
        return mediator.dialogs.error(reason);
      }
    });
  };

  DocumentSectionsView.prototype.dispose = function() {
    this.model.save('documentSections', this._getEditorContent());
    return DocumentSectionsView.__super__.dispose.apply(this, arguments);
  };

  return DocumentSectionsView;

})(View);
