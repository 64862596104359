var BaseCollection, Collection, Model,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

BaseCollection = require('base/models/collection');

Model = require('./model');

module.exports = Collection = (function(_super) {
  __extends(Collection, _super);

  function Collection() {
    return Collection.__super__.constructor.apply(this, arguments);
  }

  Collection.prototype.model = Model;

  Collection.prototype.initialize = function(models, options) {
    Collection.__super__.initialize.apply(this, arguments);
    return this.url != null ? this.url : this.url = options != null ? options.url : void 0;
  };

  return Collection;

})(BaseCollection);
