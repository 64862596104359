var IEtdManagementRecommendationsExporter, IEtdRecommendationsExporter,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

IEtdRecommendationsExporter = require('./ietd_recommendations_exporter');

module.exports = IEtdManagementRecommendationsExporter = (function(_super) {
  __extends(IEtdManagementRecommendationsExporter, _super);

  function IEtdManagementRecommendationsExporter(_originalQuestion, pouchAdapter, referencesExporter) {
    var headerFieldsInQuestion, outcomesNames, outcomesString, recommendationTypes, sectionTypes, _ref, _ref1;
    sectionTypes = {
      'problem': 'ProblemSection',
      'desirableEffects': 'DesirableEffectsSection',
      'undesirableEffects': 'UndesirableEffectsSection',
      'certaintyOfEvidence': 'CertaintyOfEvidenceSection',
      'values': 'ValuesSection',
      'balanceOfEffects': 'BalanceOfEffectsSection',
      'resourcesRequired': 'ResourcesRequiredSection',
      'certaintyOfEvidenceOfRequiredResources': 'ResourcesRequiredCertaintyOfEvidenceSection',
      'costEffectiveness': 'CostEffectivenessSection',
      'equity': 'EquitySection',
      'acceptability': 'AcceptabilitySection',
      'feasibility': 'FeasibilitySection'
    };
    outcomesString = '';
    outcomesNames = _.pluck(_originalQuestion['outcomes'], 'name');
    outcomesString = _(outcomesNames).map(function(v) {
      return "<p>" + v + "</p>";
    }).join('');
    headerFieldsInQuestion = {
      'population': (_ref = _originalQuestion['healthProblemOrPopulation']) != null ? _ref : '',
      'setting': (_ref1 = _originalQuestion['settings']) != null ? _ref1 : '',
      'mainOutcomes': outcomesString,
      'perspective': null,
      'background': null
    };
    recommendationTypes = {
      'recommend_against': 'RecommendAgainstIntervention',
      'suggest_against': 'SuggestAgainstIntervention',
      'suggest_either': 'SuggestEither',
      'suggest': 'SuggestIntervention',
      'recommend': 'RecommendIntervention'
    };
    IEtdManagementRecommendationsExporter.__super__.constructor.call(this, _originalQuestion, pouchAdapter, headerFieldsInQuestion, false, sectionTypes, recommendationTypes, referencesExporter);
  }

  return IEtdManagementRecommendationsExporter;

})(IEtdRecommendationsExporter);
