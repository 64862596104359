var Footnotes, ViewTrait, WysiwygFootnotes, mediator, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('base/lib/traits/view_trait');

mediator = require('mediator');

utils = require('base/lib/utils');

Footnotes = require('models/evidence_syntheses/footnotes');

module.exports = WysiwygFootnotes = (function(_super) {
  __extends(WysiwygFootnotes, _super);

  function WysiwygFootnotes() {
    return WysiwygFootnotes.__super__.constructor.apply(this, arguments);
  }

  WysiwygFootnotes.prototype.apply = function(view) {
    WysiwygFootnotes.__super__.apply.apply(this, arguments);
    this._addFunction(view, '_showInitialItems');
    this._addFunction(view, '_getFootnotesList');
    this._addFunction(view, 'stickFootnotes');
    this._addFunction(view, '_removeFootnotesModelCallbacks');
    this._addFunction(view, 'unstickFootnotes');
    this._addFunction(view, '_rerenderItems');
    this._addFunction(view, 'rerenderFootnotes');
    this._addFunction(view, 'rerenderReferences');
    this._addFunction(view, '_sortItemElements');
    this._addFunction(view, '_getBindingsForProperty');
    this._addFunction(view, '_showWysiwygItems');
    this._addFunction(view, '_getFilteredModelIds');
    this._addFunction(view, 'showWysiwygFootnote');
    this._addFunction(view, 'showWysiwygFootnotes');
    this._addFunction(view, 'showWysiwygReference');
    this._addFunction(view, 'showWysiwygReferences');
    this._addFunction(view, '_removeWysiwygItem');
    this._addFunction(view, 'removeWysiwygFootnote');
    this._addFunction(view, 'removeWysiwygReference');
    this._addFunction(view, '_increaseDataNumberIfItemAlreadyPresent');
    this._addFunction(view, 'increaseDataNumberIfFootnoteAlreadyPresent');
    this._addFunction(view, 'increaseDataNumberIfReferenceAlreadyPresent');
    this._addFunction(view, 'dispose');
    this._addFunction(view, '_showRequiredFootnoteMark');
    this._addFunction(view, '_hideRequiredFootnoteMark');
    this._addFunction(view, '_renderCommas');
    return this._addFunction(view, '_toggleNoExplanationIsProvidedMarks');
  };

  WysiwygFootnotes.prototype._showInitialItems = function(originalFunction, trait, itemsType) {
    var binding, capType, collectionName, itemId, property, _results;
    if (this.disposed) {
      return;
    }
    collectionName = "" + itemsType + "s";
    capType = _.str.capitalize(itemsType);
    _results = [];
    for (property in this._footnotesBindings) {
      _results.push((function() {
        var _i, _len, _ref, _ref1, _results1;
        _ref1 = (_ref = this._getBindingsForProperty(property)) != null ? _ref : [];
        _results1 = [];
        for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
          binding = _ref1[_i];
          if (collectionName === "footnotes") {
            this._toggleNoExplanationIsProvidedMarks(binding, property);
          }
          _results1.push((function() {
            var _j, _len1, _ref2, _ref3, _results2;
            _ref3 = (_ref2 = this._footnotesModel.get(collectionName)[property]) != null ? _ref2 : [];
            _results2 = [];
            for (_j = 0, _len1 = _ref3.length; _j < _len1; _j++) {
              itemId = _ref3[_j];
              _results2.push(this["showWysiwyg" + capType](itemId, property, binding));
            }
            return _results2;
          }).call(this));
        }
        return _results1;
      }).call(this));
    }
    return _results;
  };

  WysiwygFootnotes.prototype._toggleNoExplanationIsProvidedMarks = function(originalFunction, trait, binding, property) {
    var cellEl, footnotes, hasNoExplanationsProvided, _ref;
    if (!this.$el) {
      return;
    }
    footnotes = (_ref = this._footnotesModel.get("footnotes")[property]) != null ? _ref : [];
    hasNoExplanationsProvided = footnotes.indexOf(Footnotes.prototype.noExplanationProvided) !== -1;
    cellEl = this.$el.find(binding.selector);
    if (footnotes.length === 1 && hasNoExplanationsProvided) {
      return this._showRequiredFootnoteMark(cellEl, property);
    } else {
      return this._hideRequiredFootnoteMark(property);
    }
  };

  WysiwygFootnotes.prototype._getFootnotesList = function() {
    return this._footnotesListModel.get('footnotesList');
  };

  WysiwygFootnotes.prototype.stickFootnotes = function(originalFunction, trait, footnotesListModel, footnotesModel) {
    if (footnotesModel == null) {
      footnotesModel = this.model;
    }
    if (!this.footnotesBindings) {
      throw new Error('No footnotesBindings defined!');
    }
    this._footnotesListModel = footnotesListModel;
    this._footnotesModel = footnotesModel;
    this._footnotesBindings = _(this).result('footnotesBindings');
    this._showInitialItems('footnote');
    this._showInitialItems('reference');
    this._footnotesModel.on('footnoteBound', this.showWysiwygFootnotes);
    this._footnotesModel.on('footnoteUnbound', this.removeWysiwygFootnote);
    this._footnotesModel.on('referenceBound', this.showWysiwygReferences);
    this._footnotesModel.on('referenceUnbound', this.removeWysiwygReference);
    this.subscribeEvent('referencesUpdated', this.rerenderReferences);
    this.subscribeEvent('footnoteDeleted', this.rerenderFootnotes);
    this.subscribeEvent('footnoteRestored', this.rerenderFootnotes);
    this.subscribeEvent('footnotesReordered', this.rerenderFootnotes);
    this.subscribeEvent('referencesReordered', this.rerenderReferences);
    this.subscribeEvent('specialFootnoteBound', this.rerenderFootnotes);
    this.subscribeEvent('specialFootnoteUnbound', this.rerenderFootnotes);
    this._footnotesModel.on('change:footnotes', this.rerenderFootnotes);
    return this._footnotesModel.on('change:references', this.rerenderReferences);
  };

  WysiwygFootnotes.prototype._removeFootnotesModelCallbacks = function(originalFunction, trait) {
    if (this._footnotesModel) {
      this._footnotesModel.off('footnoteBound', this.showWysiwygFootnotes);
      this._footnotesModel.off('footnoteUnbound', this.removeWysiwygFootnote);
      this._footnotesModel.off('referenceBound', this.showWysiwygReferences);
      this._footnotesModel.off('referenceUnbound', this.removeWysiwygReference);
      this._footnotesModel.off('change:footnotes', this.rerenderFootnotes);
      return this._footnotesModel.off('change:references', this.rerenderReferences);
    }
  };

  WysiwygFootnotes.prototype.unstickFootnotes = function(originalFunction, trait) {
    this._footnotesListModel = null;
    this._removeFootnotesModelCallbacks();
    this.unsubscribeEvent('referencesUpdated', this.rerenderReferences);
    this.unsubscribeEvent('footnoteDeleted', this.rerenderFootnotes);
    this.unsubscribeEvent('footnoteRestored', this.rerenderFootnotes);
    this.unsubscribeEvent('footnotesReordered', this.rerenderFootnotes);
    this.unsubscribeEvent('referencesReordered', this.rerenderReferences);
    this.unsubscribeEvent('specialFootnoteBound', this.rerenderFootnotes);
    this.unsubscribeEvent('specialFootnoteUnbound', this.rerenderFootnotes);
    this._footnotesListModel = null;
    this._footnotesModel = null;
    return this._footnotesBindings = null;
  };

  WysiwygFootnotes.prototype.rerenderFootnotes = function(originalFunction, trait) {
    return this._rerenderItems('footnote');
  };

  WysiwygFootnotes.prototype.rerenderReferences = function(originalFunction, trait) {
    return this._rerenderItems('reference');
  };

  WysiwygFootnotes.prototype._rerenderItems = function(originalFunction, trait, itemsType) {
    if (this.disposed) {
      return;
    }
    this.$("sup[data-" + itemsType + "-id]").remove();
    return this._showInitialItems(itemsType);
  };

  WysiwygFootnotes.prototype._sortItemElements = function(originalFunction, trait, cellEl, method) {
    var footnoteElements, sortingFunction;
    sortingFunction = function(a, b) {
      var aVal, bVal;
      aVal = a.textContent.replace(',', '');
      bVal = b.textContent.replace(',', '');
      if (utils.isNumeric(aVal) && utils.isNumeric(bVal)) {
        if (parseInt(aVal) > parseInt(bVal)) {
          return 1;
        } else {
          return -1;
        }
      } else if (utils.isNumeric(aVal)) {
        return -1;
      } else if (utils.isNumeric(bVal)) {
        return 1;
      } else {
        if (aVal > bVal) {
          return 1;
        } else {
          return -1;
        }
      }
    };
    if (method === 'after') {
      footnoteElements = cellEl.nextUntil(':not(sup[data-footnote-id], sup[data-reference-id])').remove();
    } else if (method === 'append') {
      footnoteElements = cellEl.find("sup[data-footnote-id], sup[data-reference-id]");
    }
    return cellEl[method](footnoteElements.sort(sortingFunction));
  };

  WysiwygFootnotes.prototype._getBindingsForProperty = function(originalFunction, trait, property) {
    return this._footnotesBindings[property];
  };

  WysiwygFootnotes.prototype._getFilteredModelIds = function() {
    var _ref;
    if ((_ref = this.parentView) != null ? _ref.filterer : void 0) {
      return _(this._footnotesModel.collection.filter(_(this.parentView.filterer).bind(this.parentView))).pluck('id');
    } else {
      return _(this._footnotesModel.collection.models).pluck('id');
    }
  };

  WysiwygFootnotes.prototype.showWysiwygFootnote = function(originalFunction, trait, footnoteId, property, binding) {
    var el, filteredModelIds, noteWithIdPassed, notesIds, notesList;
    if (!this.$el) {
      return;
    }
    el = this.$el.find(binding.selector);
    filteredModelIds = this._getFilteredModelIds();
    notesIds = _(this._footnotesListModel.getFootnotesObjectsArray(filteredModelIds)).pluck('id');
    notesList = this._getFootnotesList().getNotesList(notesIds);
    noteWithIdPassed = _(notesList).findWhere({
      id: footnoteId
    });
    if (!noteWithIdPassed) {
      return;
    }
    this._toggleNoExplanationIsProvidedMarks(binding, property);
    if (this.increaseDataNumberIfFootnoteAlreadyPresent(footnoteId, el, binding.method)) {
      return;
    }
    el[binding.method]("<sup data-footnote-number='1'\ndata-footnote-id='" + footnoteId + "'>" + noteWithIdPassed.counter + "</sup>");
    this._sortItemElements(el, binding.method);
    return this._renderCommas(el);
  };

  WysiwygFootnotes.prototype.showWysiwygReference = function(originalFunction, trait, referenceId, property, binding) {
    var el, referenceWithIdPassed, refsList;
    if (!this.$el) {
      return;
    }
    el = this.$el.find(binding.selector);
    refsList = this._footnotesListModel.getReferencesObjectsArray(this._getFilteredModelIds());
    referenceWithIdPassed = _(refsList).findWhere({
      id: referenceId
    });
    if (!referenceWithIdPassed) {
      return;
    }
    if (this.increaseDataNumberIfReferenceAlreadyPresent(referenceId, el, binding.method)) {
      return;
    }
    el[binding.method]("<sup data-reference-number='1'\ndata-reference-id='" + referenceId + "'>" + referenceWithIdPassed.counter + "</sup>");
    this._sortItemElements(el, binding.method);
    return this._renderCommas(el);
  };

  WysiwygFootnotes.prototype._renderCommas = function(originalFunction, trait, el) {
    var footnotes, index, _i, _len, _results;
    footnotes = $(el).find('sup');
    _results = [];
    for (index = _i = 0, _len = footnotes.length; _i < _len; index = ++_i) {
      el = footnotes[index];
      if ($(el).find('.comma').length === 0 && index !== (footnotes.length - 1)) {
        _results.push($(el).append('<span class="comma">,</span>'));
      } else {
        _results.push(void 0);
      }
    }
    return _results;
  };

  WysiwygFootnotes.prototype.showWysiwygFootnotes = function(originalFunction, trait, footnoteId, property) {
    return this._showWysiwygItems('footnote', footnoteId, property);
  };

  WysiwygFootnotes.prototype.showWysiwygReferences = function(originalFunction, trait, referenceId, property) {
    return this._showWysiwygItems('reference', referenceId, property);
  };

  WysiwygFootnotes.prototype._showWysiwygItems = function(originalFunction, trait, itemName, itemId, property) {
    var binding, _i, _len, _ref, _results;
    if (!this._getBindingsForProperty(property)) {
      return;
    }
    _ref = this._getBindingsForProperty(property);
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      binding = _ref[_i];
      _results.push(this["showWysiwyg" + (_.string.capitalize(itemName))](itemId, property, binding));
    }
    return _results;
  };

  WysiwygFootnotes.prototype._showRequiredFootnoteMark = function(originalFunction, trait, cellEl, property) {
    var button;
    if ($(cellEl).find("button.footnote-required").length === 0) {
      button = $(document.createElement('button'));
      button.attr("title", $.t("es:footnotes.special.no_explanation_provided"));
      button.addClass('footnote-required');
      button.attr("data-property", property);
      $(cellEl).append(button);
      if (_.isFunction(this._addTooltips)) {
        return this._addTooltips('info', button);
      }
    }
  };

  WysiwygFootnotes.prototype._hideRequiredFootnoteMark = function(originalFunction, traig, property) {
    return this.$el.find("button[data-property='" + property + "']").remove();
  };

  WysiwygFootnotes.prototype.increaseDataNumberIfFootnoteAlreadyPresent = function(originalFunction, trait, footnoteId, el, method) {
    return this._increaseDataNumberIfItemAlreadyPresent('footnote', footnoteId, el, method);
  };

  WysiwygFootnotes.prototype.increaseDataNumberIfReferenceAlreadyPresent = function(originalFunction, trait, referenceId, el, method) {
    return this._increaseDataNumberIfItemAlreadyPresent('reference', referenceId, el, method);
  };

  WysiwygFootnotes.prototype._increaseDataNumberIfItemAlreadyPresent = function(originalFunction, trait, itemName, itemId, el, method) {
    var increase, present;
    increase = function(items) {
      var sup, _i, _len;
      for (_i = 0, _len = items.length; _i < _len; _i++) {
        sup = items[_i];
        if (!(itemId === $(sup).data("" + itemName + "Id"))) {
          continue;
        }
        $(sup).data("" + itemName + "Number", $(sup).data("" + itemName + "Number") + 1);
        return true;
      }
      return false;
    };
    present = false;
    if (method === 'after') {
      present = increase(el.nextUntil(':not(sup)'));
    } else if (method === 'append') {
      present = increase(el.find('sup'));
    } else {
      throw new Error('Unsupported method.');
    }
    return present;
  };

  WysiwygFootnotes.prototype.removeWysiwygFootnote = function(originalFunction, trait, footnoteId, property) {
    return this._removeWysiwygItem('footnote', footnoteId, property);
  };

  WysiwygFootnotes.prototype.removeWysiwygReference = function(originalFunction, trait, referenceId, property) {
    return this._removeWysiwygItem('reference', referenceId, property);
  };

  WysiwygFootnotes.prototype._removeWysiwygItem = function(originalFunction, trait, itemName, itemId, property) {
    var binding, el, removeOrDecrease, selector, _i, _len, _ref;
    removeOrDecrease = function(item) {
      if (item.data("" + itemName + "Number") === 1) {
        return item.remove();
      } else {
        return item.data("" + itemName + "Number", item.data("" + itemName + "Number") - 1);
      }
    };
    if (!this._getBindingsForProperty(property)) {
      return;
    }
    _ref = this._getBindingsForProperty(property);
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      binding = _ref[_i];
      selector = binding.selector;
      if (!this.$el) {
        return;
      }
      el = this.$el.find(selector);
      if (binding.method === 'after') {
        removeOrDecrease(el.nextAll("sup[data-" + itemName + "-id='" + itemId + "']").eq(0));
      } else if (binding.method === 'append') {
        removeOrDecrease(el.find("sup[data-" + itemName + "-id='" + itemId + "']"));
      } else {
        throw new Error('Unsupported method.');
      }
      el.find('span.comma').remove();
      if (itemName === "footnote") {
        this._toggleNoExplanationIsProvidedMarks(binding, property);
      }
      this._renderCommas(el);
    }
  };

  WysiwygFootnotes.prototype.dispose = function(originalFunction) {
    this._removeFootnotesModelCallbacks();
    return originalFunction.apply(this);
  };

  return WysiwygFootnotes;

})(ViewTrait);
