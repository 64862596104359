var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "accp/risk_with_control", depth0, "{\"colNo\": 5}", {"name":"cell","hash":{},"data":data})))
    + "\n"
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "accp/risk_difference_with_intervention", depth0, "{\"colNo\": 6}", {"name":"cell","hash":{},"data":data})))
    + "\n\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}