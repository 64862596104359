module.exports = {
  _timestampToDate: function(timestamp, format) {
    if (format == null) {
      format = 'MMM DD YYYY';
    }
    if (timestamp === 0 || !_.isNumber(timestamp)) {
      return '-';
    }
    return moment(timestamp).format(format);
  },
  _getDueDateClassNames: function(timestamp) {
    var isDueSoon, isPastDue, todayTimestamp;
    if (timestamp) {
      todayTimestamp = new Date().getTime();
      isPastDue = todayTimestamp - timestamp >= 0;
      isDueSoon = !isPastDue && timestamp - todayTimestamp < 7 * 24 * 60 * 60 * 1000;
    }
    return classNames('due-date', {
      'past-due': timestamp && isPastDue,
      'due-soon': timestamp && isDueSoon
    });
  }
};
