var DnDActions, DragDropTypes;

DnDActions = require('actions/react_dnd_actions');

DragDropTypes = require('components/scope/outcomes/drag_drop_types');

module.exports = {
  dragSpec: {
    beginDrag: function(props) {
      var gIndex, id;
      gIndex = props.gIndex;
      id = props.data.getIn(['questions', 0, '_id']);
      DnDActions.beginDrag({
        gIndex: gIndex,
        type: DragDropTypes.QGROUP
      });
      return {
        gIndex: gIndex,
        id: id
      };
    },
    canDrag: function(props, monitor) {
      return !Boolean(props.data.get('name'));
    },
    isDragging: function(props, monitor) {
      return props.gIndex === monitor.getItem().gIndex;
    },
    endDrag: function(props, monitor) {
      if (!monitor.didDrop()) {
        return DnDActions.endDrag();
      }
    }
  },
  dropSpec: {
    canDrop: function() {
      return false;
    }
  },
  dragCollect: function(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget()
    };
  }
};
