var AdolopedEtdActions, AdolopmentDataActions, CERTAINTY_OF_EVIDENCE_CIRCLES, CERTAINTY_OF_EVIDENCE_CRITERIA, CERTAINTY_OF_EVIDENCE_TOTAL_CIRCLES, EtdActions, EtdVotingStore, HtmlExport, RECOMMENDATION_STRENGTHS_FROM_SELECTOR, W, alt, draftRenderer, etdHelper, etdTemplatesSections, exportConclusions, exportFieldWithMissing, exportGuidelineFields, exportIsof, exportJustification, exportJustificationText, get, getCertaintyOfEvidenceAndLabel, getCertaintyOfEvidenceCircles, getEtdPrintout, getQuestionIsofData, getRecommendationDirectionAndStrength, getRecommendationMetadata, getRecommendationMetadataOrUndefined, getRecommendationText, gradeProCodeToWHOCode, renderEditorValue, renderEditorValueFromRecommendationMetadata, sanitizeHTML,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

AdolopedEtdActions = require('actions/adoloped_etd_actions');

AdolopmentDataActions = require('actions/adolopment_data_actions');

alt = require('alt');

draftRenderer = require('lib/services/jsonld_exporter/draft_renderer');

EtdActions = require('actions/etd_actions');

etdHelper = require('lib/etd_helper');

etdTemplatesSections = require('lib/etd_templates/templates_sections');

EtdVotingStore = require('stores/etd_voting_store');

get = require('lodash').get;

getQuestionIsofData = require('lib/questions_helper').getQuestionIsofData;

HtmlExport = require('lib/html_export');

sanitizeHTML = require('base/lib/utils').sanitizeHTML;

W = require('when/when');

CERTAINTY_OF_EVIDENCE_TOTAL_CIRCLES = 4;

CERTAINTY_OF_EVIDENCE_CIRCLES = {
  'very_low': 1,
  'low': 2,
  'moderate': 3,
  'high': 4
};

gradeProCodeToWHOCode = function(_arg) {
  var codeType, name, value;
  codeType = _arg.codeType, value = _arg.value, name = _arg.name;
  return {
    'code': {
      '@type': codeType,
      'value': sanitizeHTML(value)
    },
    'name': sanitizeHTML(name)
  };
};

exportGuidelineFields = function(exportFunction, fields, fieldsMap, guidelineDoc) {
  return _.chain(fields).map(function(field) {
    return [field, exportFunction(guidelineDoc[field])];
  }).map(function(_arg) {
    var key, value;
    key = _arg[0], value = _arg[1];
    if (key in fieldsMap) {
      return [fieldsMap[key], value];
    } else {
      return [key, value];
    }
  }).object().value();
};

renderEditorValue = function(value) {
  if (!Boolean(value)) {
    return '';
  }
  return draftRenderer.renderDraftJsContentToHTML(value);
};

exportJustificationText = function(recommendation) {
  var details, detailsDescription, justification, overallContent, overallDescription, sectionsOrder, _ref;
  justification = get(recommendation, 'templateData.conclusions.sections.justification');
  if (!justification) {
    return void 0;
  }
  overallContent = renderEditorValue(get(justification, 'content'));
  if (!overallContent) {
    return void 0;
  }
  sectionsOrder = (_ref = get(recommendation, 'templateData.assessment.sectionsOrder')) != null ? _ref : [];
  details = _.reduce(sectionsOrder, function(acc, key) {
    var name, value;
    name = get(recommendation, "templateData.assessment.sections." + key + ".name");
    value = get(justification, "details.criteria." + key);
    if (!value) {
      return acc;
    }
    return "" + acc + "<br /><br /><span style='font-style: italic'>" + name + "</span><br />" + value;
  }, '');
  if (!details) {
    return overallContent;
  }
  overallDescription = get(etdTemplatesSections(), 'conclusions.sections.justification.overallDescription');
  detailsDescription = get(etdTemplatesSections(), 'conclusions.sections.justification.details.description');
  return "<div> <span style='font-weight: bold'>" + overallDescription + "</span><br /> " + overallContent + " <br /><br /> <span style='font-weight: bold'>" + detailsDescription + "</span><br /> " + details + " </div>";
};

exportJustification = function(recommendation) {
  var justificationText;
  justificationText = exportJustificationText(recommendation);
  if (!justificationText) {
    return void 0;
  }
  return {
    content: justificationText
  };
};

exportConclusions = function(recommendation, sectionsToOmit) {
  var sections, sectionsOrder, sectionsToReturn, _ref;
  sectionsOrder = (_ref = get(recommendation, 'templateData.conclusions.sectionsOrder')) != null ? _ref : [];
  sectionsOrder = _.reject(sectionsOrder, function(s) {
    return __indexOf.call(sectionsToOmit, s) >= 0 || __indexOf.call(etdHelper.getRecommendationTypeSections(), s) >= 0;
  });
  sectionsToReturn = _.reject(sectionsOrder, function(s) {
    return s === 'justification';
  });
  sections = _.chain(sectionsToReturn).map(function(sectionId) {
    var content, section, selectedOption;
    section = get(recommendation, "templateData.conclusions.sections." + sectionId);
    content = renderEditorValue(get(section, 'content'));
    if (!content) {
      content = void 0;
    }
    selectedOption = get(section, 'selectedOption');
    if (!selectedOption) {
      selectedOption = void 0;
    }
    if (!(content || selectedOption)) {
      return null;
    }
    return [
      sectionId, {
        content: content,
        selectedOption: selectedOption
      }
    ];
  }).compact().object().value();
  return {
    sectionsOrder: sectionsOrder,
    sections: sections
  };
};

exportFieldWithMissing = function(value) {
  if (value == null) {
    return void 0;
  }
  if (value === 'missing') {
    return void 0;
  }
  return value;
};

RECOMMENDATION_STRENGTHS_FROM_SELECTOR = {
  'recommend_against': ['negative', 'strong'],
  'suggest_against': ['negative', 'weak'],
  'suggest_either': ['none', 'weak'],
  'suggest': ['positive', 'weak'],
  'recommend': ['positive', 'strong']
};

getRecommendationMetadata = function(recommendation, key, defaultValue) {
  var _ref;
  if (defaultValue == null) {
    defaultValue = [];
  }
  return [].concat((_ref = get(recommendation, "templateData.conclusions.sections.recommendation." + key)) != null ? _ref : defaultValue);
};

getRecommendationMetadataOrUndefined = function(recommendation, key) {
  var value;
  value = getRecommendationMetadata(recommendation, key);
  if (_.isEmpty(value)) {
    return void 0;
  }
  value = value[0];
  if (value == null) {
    return void 0;
  }
  return value;
};

renderEditorValueFromRecommendationMetadata = function(recommendation, key) {
  var value;
  value = getRecommendationMetadataOrUndefined(recommendation, key);
  if (!value) {
    return void 0;
  }
  return renderEditorValue(value);
};

getRecommendationDirectionAndStrength = function(recommendation) {
  var conclusionsInTemplate, recommendationDirection, recommendationOption, recommendationOptionValue, recommendationOptions, recommendationStrength, recommendationTypeDef, recommendationTypeId, _ref;
  recommendationStrength = exportFieldWithMissing(getRecommendationMetadata(recommendation, 'strengthOfRecommendation')[0]);
  if (recommendationStrength != null) {
    if (recommendationStrength in RECOMMENDATION_STRENGTHS_FROM_SELECTOR) {
      return RECOMMENDATION_STRENGTHS_FROM_SELECTOR[recommendationStrength];
    }
    return ['none', recommendationStrength];
  }
  conclusionsInTemplate = (_ref = recommendation != null ? recommendation['templateDef']['conclusions'] : void 0) != null ? _ref : [];
  recommendationTypeDef = _.find(conclusionsInTemplate, function(_arg) {
    var checked, id, required;
    id = _arg.id, checked = _arg.checked, required = _arg.required;
    return (Boolean(checked) || Boolean(required)) && __indexOf.call(etdHelper.getRecommendationTypeSections(), id) >= 0;
  });
  if (!recommendationTypeDef) {
    return [void 0, void 0];
  }
  recommendationTypeId = recommendationTypeDef.id;
  recommendationOptionValue = get(recommendation, "templateData.conclusions.sections." + recommendationTypeId + ".selectedOption");
  recommendationOptions = get(recommendation, "templateData.conclusions.sections." + recommendationTypeId + ".options");
  recommendationOption = _.find(recommendationOptions, function(_arg) {
    var value;
    value = _arg.value;
    return value === recommendationOptionValue;
  });
  if (!recommendationOption) {
    return [void 0, void 0];
  }
  recommendationDirection = get(recommendationOption, 'direction');
  recommendationStrength = recommendationOptionValue.indexOf('recommend') >= 0 ? 'strong' : 'weak';
  return [recommendationDirection, recommendationStrength];
};

CERTAINTY_OF_EVIDENCE_CRITERIA = ['certaintyOfEvidence', 'certaintyOfEvidenceOfTestAccuracy'];

getCertaintyOfEvidenceAndLabel = function(recommendation) {
  var certaintyOfEvidence, gradeCertaintyOfEvidenceLabel;
  certaintyOfEvidence = exportFieldWithMissing(getRecommendationMetadata(recommendation, 'certaintyOfEvidence')[0]);
  if (certaintyOfEvidence != null) {
    return [certaintyOfEvidence, 'certainty_of_evidence'];
  }
  certaintyOfEvidence = _.chain(CERTAINTY_OF_EVIDENCE_CRITERIA).map(function(criterion) {
    return get(recommendation, "templateData.assessment.criteria." + criterion + ".selectedOption");
  }).find(function(val) {
    return Boolean(val);
  }).value();
  gradeCertaintyOfEvidenceLabel = certaintyOfEvidence != null ? 'certainty_of_evidence' : void 0;
  return [certaintyOfEvidence, gradeCertaintyOfEvidenceLabel];
};

getEtdPrintout = function(projectId, questionModel, recommendationId) {
  return W.promise(function(resolve, reject) {
    var actions, actionsToStoresMap, callbackId, exportAfterDataIsFetched, hasAdolopment, isFetchingInStores, recommendationAdolopmentIds;
    exportAfterDataIsFetched = function() {
      var htmlExport, options;
      options = EtdVotingStore.getVotingData().isEmpty() ? {} : {
        etdViewMode: 'judgements-consensus#recommendation-consensus#conclusions-proposed',
        showVotingResults: false
      };
      htmlExport = new HtmlExport(questionModel, 'recommendations-v2', options);
      return htmlExport.getSource()["finally"](function() {
        return alt.recycle('EtdStore', 'AdolopedEtdStore', 'QuestionsStore', 'EtdVotingStore', 'ReferencesStore', 'OverarchingQuestionsStore', 'PanelVoiceStore', 'PanelVoiceEtdStore');
      }).then(resolve, reject);
    };
    recommendationAdolopmentIds = questionModel.get('recommendationAdolopmentIds');
    hasAdolopment = !_.isEmpty(recommendationAdolopmentIds);
    isFetchingInStores = {
      etd: true,
      questions: true,
      voting: true,
      references: true,
      adolopmentData: hasAdolopment,
      adolopmentEtd: hasAdolopment
    };
    actionsToStoresMap = {
      'EtdActions.fetchSuccess': 'etd',
      'EtdActions.fetchError': 'etd',
      'ReferencesActions.fetchSuccess': 'references',
      'ReferencesActions.fetchError': 'references',
      'EtdVotingActions.fetchSuccess': 'voting',
      'EtdVotingActions.fetchError': 'voting',
      'QuestionsActions.fetchSuccess': 'questions',
      'QuestionsActions.fetchError': 'questions',
      'AdolopedEtdActions.fetchSuccess': 'adolopmentEtd',
      'AdolopedEtdActions.fetchError': 'adolopmentEtd',
      'AdolopmentDataActions.fetchAdolopmentDataSuccess': 'adolopmentData',
      'AdolopmentDataActions.fetchAdolopmentDataError': 'adolopmentData'
    };
    actions = _(actionsToStoresMap).keys();
    callbackId = alt.dispatcher.register(function(payload) {
      var _ref;
      if (_ref = payload.action, __indexOf.call(actions, _ref) < 0) {
        return;
      }
      isFetchingInStores[actionsToStoresMap[payload.action]] = false;
      if (_.any(_(isFetchingInStores).values())) {
        return;
      }
      alt.dispatcher.unregister(callbackId);
      return setTimeout(exportAfterDataIsFetched, 100);
    });
    EtdActions.fetch({
      dbId: projectId,
      docId: [recommendationId]
    });
    if (hasAdolopment) {
      AdolopedEtdActions.fetch({
        dbId: projectId,
        docIds: recommendationAdolopmentIds
      });
      return AdolopmentDataActions.fetchAdolopmentData(projectId);
    }
  });
};

exportIsof = function(questionModel, projectName) {
  return getQuestionIsofData(questionModel, projectName);
};

getRecommendationText = function(recommendation, isGps) {
  var sectionId;
  if (isGps == null) {
    isGps = false;
  }
  sectionId = isGps ? 'goodPracticeStatement' : _.some(get(recommendation, 'templateDef.conclusions'), function(_arg) {
    var id;
    id = _arg.id;
    return id === 'decision';
  }) ? 'decision' : 'recommendation';
  return renderEditorValue(get(recommendation, "templateData.conclusions.sections." + sectionId + ".content"));
};

getCertaintyOfEvidenceCircles = function(question, recommendation) {
  var certaintyNumericValue, certaintySelectedOption, circles, criterion, isDiagnostic, label, option, options, sectionName, _ref, _ref1;
  isDiagnostic = question.get('type') === 'diagnostic';
  sectionName = isDiagnostic ? 'certaintyOfEffects' : 'certaintyOfEvidence';
  criterion = recommendation.getIn(['templateData', 'assessment', 'criteria', sectionName]);
  certaintySelectedOption = criterion != null ? criterion.get('selectedOption') : void 0;
  options = (_ref = criterion != null ? criterion.get('options') : void 0) != null ? _ref : Immutable.List();
  label = certaintySelectedOption != null ? (option = options.find(function(option) {
    return option.get('value') === certaintySelectedOption;
  }), option != null ? option.get('text') : void 0) : null;
  certaintyNumericValue = (_ref1 = CERTAINTY_OF_EVIDENCE_CIRCLES[certaintySelectedOption]) != null ? _ref1 : 0;
  circles = Array(CERTAINTY_OF_EVIDENCE_TOTAL_CIRCLES).fill('').map(function(_elem, idx) {
    if (idx < certaintyNumericValue) {
      return "⨁";
    } else {
      return "◯";
    }
  });
  return {
    label: label,
    circles: circles
  };
};

module.exports = {
  exportConclusions: exportConclusions,
  exportFieldWithMissing: exportFieldWithMissing,
  exportGuidelineFields: exportGuidelineFields,
  exportIsof: exportIsof,
  exportJustification: exportJustification,
  getCertaintyOfEvidenceAndLabel: getCertaintyOfEvidenceAndLabel,
  getCertaintyOfEvidenceCircles: getCertaintyOfEvidenceCircles,
  getEtdPrintout: getEtdPrintout,
  getRecommendationDirectionAndStrength: getRecommendationDirectionAndStrength,
  getRecommendationMetadata: getRecommendationMetadata,
  getRecommendationMetadataOrUndefined: getRecommendationMetadataOrUndefined,
  getRecommendationText: getRecommendationText,
  gradeProCodeToWHOCode: gradeProCodeToWHOCode,
  renderEditorValue: renderEditorValue,
  renderEditorValueFromRecommendationMetadata: renderEditorValueFromRecommendationMetadata
};
