Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
{ MDG_WORKSPACE } = require 'lib/mdg_helper'
{ SORTABLE_FIELDS } = require 'lib/projects_helper'
{ instanceOf, func, string } = PropTypes
mediator = require 'mediator'

GridSort = createReactClass
  displayName: 'ProjectsSort'

  propTypes:
    tabName: string.isRequired
    sortedBy: instanceOf(Immutable.Map)
    onSort: func

  mixins: [
    Translation('projects:list')
  ]

  getSortingLabel: (fieldName, ascending) ->
    fieldLabel = @i18n "#{_.str.underscored fieldName}"

    if ascending
      ascLabel = if fieldName in ['dateModified', 'startDate']
        @i18n 'asc_date'
      else @i18n 'asc_alphabet'

      "#{fieldLabel} #{ascLabel}"
    else
      descLabel = if fieldName in ['dateModified', 'startDate']
        @i18n 'desc_date'
      else @i18n 'desc_alphabet'

      "#{fieldLabel} #{descLabel}"

  getSortByOptions: ->
    SORTABLE_FIELDS.reduce (acc, fieldName) =>
      # dateModified is not sortable in Invitations tab
      return acc if @props.tabName is 'invitations' and fieldName is 'dateModified'
      return acc if mediator.activeWorkspace isnt MDG_WORKSPACE and fieldName is 'startDate'
      fieldOptions = [true, false].map (ascending) =>
        value: JSON.stringify { fieldName, ascending }
        text: @getSortingLabel fieldName, ascending

      acc.concat fieldOptions
    , []

  sortBy: (val) ->
    @props.onSort JSON.parse val

  render: ->
    <div className='sort-by'>
      <span>{@i18n 'sort_by'}: </span>
      <Select
        options={@getSortByOptions()}
        selected={JSON.stringify @props.sortedBy.toJS()}
        onChange={@sortBy}
      />
    </div>

module.exports = GridSort
