MoveProjectToOrganizationModal = require 'components/projects/move_project_to_organization_modal'
ProjectsActions = require 'actions/projects_actions'
ProjectsSort = require 'components/projects/projects_sort'
ProjectsTab = require 'components/projects/projects_tab'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
{ sortProjectsByField } = require 'lib/projects_helper'
{ Tab, TabsContainer } = require 'components/common/tabs_container'

ProjectsTabs = createReactClass
  displayName: 'ProjectsTabs'

  mixins: [
    Translation('projects:welcome')
  ]

  getTabProps: (tabName) ->
    basicProps =
      sortedBy: @props.sortedBy
      onSort: @props.onSort
      activeTab: @props.activeTab
      tabName: tabName
      isSearchActive: @props.activeSearch.get 'isActive'
      organizationId: @props.organizationId

    switch @props.activeTab
      when 'active', 'archived', 'copies'
        { activeProjects, archivedProjects, copiesOfProjects } = @props

        projects = switch @props.activeTab
          when 'active' then activeProjects
          when 'archived' then archivedProjects
          when 'copies' then copiesOfProjects

        _.extend {}, basicProps, {
          projects
          projectsLabels: @props.projectsLabels
          projectsNotes: @props.projectsNotes
          labelsData: @props.labelsData
          projectsQuestions: @props.projectsQuestions
          projectsMembers: @props.projectsMembers
          projectsStatuses: @props.projectsStatuses
          projectsReplicationProgress: @props.projectsReplicationProgress
          projectsRemoteInfo: @props.projectsRemoteInfo
          permittedProjectActions: @props.permittedProjectActions
        }

  getTabLabel: (tabName) ->
    tabLabel = @i18n tabName
    itemsCount = switch tabName
      when 'active' then @props.activeProjects.size
      when 'archived' then @props.archivedProjects.size
      when 'copies' then @props.copiesOfProjects.size
    "#{tabLabel} (#{itemsCount})"

  toggleMoveToOrganizationModal: ->
    ProjectsActions.toggleMoveToOrganizationModal ''

  moveProjectToOrganization: (projectId, organizationId) ->
    ProjectsActions.moveProjectToOrganization projectId, organizationId

  render: ->
    {
      activeTab,
      sortedBy,
      onSort,
      onChangeTab,
      highlightedProjectId
    } = @props

    <div className='projects-tabs'>
      {if not @props.hasEverFetched
        <Spinner />
      else
        <div>
          <div className='sort-with-view-toggles'>
            <ProjectsSort onSort={onSort} sortedBy={sortedBy} tabName={activeTab} />
          </div>
          <TabsContainer activeTab={activeTab} changeTab={onChangeTab} withTabsPane>
            <Tab tabName='active' tabLabel={@getTabLabel 'active'}>
              <ProjectsTab
                highlightedProjectId={highlightedProjectId}
                {...@getTabProps('active')}
              />
            </Tab>
            <Tab tabName='copies' tabLabel={@getTabLabel 'copies'}>
              <ProjectsTab
                highlightedProjectId={highlightedProjectId}
                {...@getTabProps('copies')}
              />
            </Tab>
            <Tab tabName='archived' tabLabel={@getTabLabel 'archived'}>
              <ProjectsTab {...@getTabProps('archived')} />
            </Tab>
          </TabsContainer>
          <MoveProjectToOrganizationModal
            isOpen={@props.movingProjectToOrganizationStatus.get('modalOpen')}
            status={@props.movingProjectToOrganizationStatus}
            isOnline={@props.isOnline}
            onRequestClose={@toggleMoveToOrganizationModal}
            onMove={@moveProjectToOrganization}
          />
        </div>
        }
    </div>

module.exports = ProjectsTabs
