Translation = require 'components/mixins/translation'
Validation = require 'base/validation'
VotingMessageForm = require 'components/common/voting_message_form'
MailAttachmentsForm = require 'components/panel_voice/forms/mail_attachments_form'
mediator = require 'mediator'
{bool, oneOfType, instanceOf, string} = PropTypes

MessageForm = createReactClass
  displayName: "PanelVoiceMessageForm"

  mixins: [Translation("voting:panel_voice")]

  propTypes:
    keepMessage: bool
    message: oneOfType([instanceOf(Immutable.Map), string]).isRequired

  getInitialState: ->
    contactEmail: @props.contactEmail ? ""
    dueDateTimestamp: @props.dueDateTimestamp
    validationFault: []
    keepMessage: @props.keepMessage

  validateData: (data) ->
    validationFields = ['emailBody']
    validationFields.push 'contactEmail'
    validationFields.reduce @_dataValidator(data), {success: true, failedFields: []}

  setDueDate: (e, date, { disabled }) ->
    return if disabled
    @setState dueDateTimestamp: date.getTime()

  _dataValidator: (data) -> (result, fieldName) =>
    switch fieldName
      when 'contactEmail', 'emailBody'
        if @_isDataValid fieldName, data[fieldName]
          result
        else
          {success: false, failedFields: result.failedFields.concat [fieldName]}
      else
        result

  _isDataValid: (fieldName, text) ->
    return true unless @votingMessageForm
    switch fieldName
      when 'contactEmail' then Validation.isValidEmail text
      when 'emailBody'
        not _.isEmpty @votingMessageForm.getTextContent()

  getFormData: ->
    emailBody: @_getMessageText()
    contactEmail: @state.contactEmail ? @props.contactEmail
    dueDateTimestamp: @state.dueDateTimestamp
    keepMessage: @state.keepMessage

  updateEmail: (e) ->
    @setState contactEmail: e.target.value

  onChangeKeepMessageInput: (e) ->
    @setState keepMessage: not @state.keepMessage

  _getMessageText: ->
    return @props.message unless @votingMessageForm
    return Immutable.Map() if _.isEmpty @votingMessageForm.getTextContent()
    @votingMessageForm.editor.getEditorContent()

  _refVotingMessageForm: (messageEl) ->
    @votingMessageForm = messageEl

  render: ->
    <div className="panel-voice-message-form">
      <label className="keep-message">
        {@i18n 'labels.keep_message'}
        <input
          type="checkbox"
          checked={@state.keepMessage}
          onChange={@onChangeKeepMessageInput}
        />
      </label>
      <VotingMessageForm
        ref={@_refVotingMessageForm}
        {...@props}
        validationFault={@props.validationFault}
        contactEmail={@state.contactEmail}
        updateEmail={@updateEmail}
        dueDateTimestamp={@state.dueDateTimestamp}
        setDueDate={@setDueDate}
      />
      <MailAttachmentsForm />
    </div>

module.exports = MessageForm
