CustomRenderMixin = require 'components/mixins/custom_render_mixin'
DragSource = ReactDnD.DragSource
DropTarget = ReactDnD.DropTarget

Wrapper = createReactClass
  displayName: 'DragWrapper'

  mixins: [CustomRenderMixin]

  getDefaultProps: ->
    canDrag: true

  _getPropsForChild: ->
    className: @_getChildClassName()

  _getChildClassName: ->
    # update child element class with is-dragging class if item is being dragged
    childClass = React.Children.only(@props.children).props.className
    classNames childClass, 'draggable': @props.canDrag, 'is-dragging': @props.isDragging

  render: ->
    { connectDragSource, connectDropTarget } = @props
    child = React.cloneElement React.Children.only(@props.children), @_getPropsForChild()

    connectDragSource(
      if connectDropTarget? then connectDropTarget(child) else child
    )

module.exports =
  getDragWrapper: (dndType, dndSpec) ->
    { dragSpec, dropSpec, dragCollect, dropCollect } = dndSpec

    DragSourced = DragSource(dndType, dragSpec, dragCollect) Wrapper
    if dropSpec? and dropCollect?
      DropTarget(dndType, dropSpec, dropCollect) DragSourced
    else
      DragSourced
