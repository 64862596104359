Modal = require 'components/common/modal'
ExportDialog = require 'components/common/export_dialog'
Translation = require 'components/mixins/translation'
DocSectionsFromTemplateStore = require 'stores/doc_sections_from_template_store'
DocSectionsFromTemplatePrintout =
  require 'components/document_sections/from_template/doc_sections_from_template_printout'
exportDocTemplate = require 'views/templates/printouts/document_sections_from_template'
{ exportToFile } = require 'lib/export_to_file'
{ getHTMLMarkupForDocumentSectionsFromTemplate } = require 'lib/document_sections_utils'
ReferencesStore = require 'stores/references_store'
mediator = require 'mediator'

DocSectionsFromTemplateExportDialog = createReactClass
  displayName: 'DocSectionsFromTemplateExportDialog'

  propTypes:
    onClose: PropTypes.func.isRequired
    templateId: PropTypes.string

  mixins: [
    Translation('docsec:from_template.export')
  ]

  onDownload: ({ outputFormat }) ->
    Store = DocSectionsFromTemplateStore

    referenceIds = Store.getReferenceIds()
    references = ReferencesStore.getReferenceByIds referenceIds

    propsFromStores =
      document: Store.getDocument()
      chapters: Store.getChapters()
      chaptersOrder: Store.getChaptersOrder()
      attachments: Store.getAttachments()
      collapsedChapters: Immutable.List()
      referenceIds: referenceIds
      references: references

    docHTML = getHTMLMarkupForDocumentSectionsFromTemplate propsFromStores

    exportToFile(outputFormat, docHTML,
      fileName: "#{mediator.project.get('name')}_#{@i18n 'document_sections_filename'}"
      margins:
        top: 0
        left: 0
        right: 0
      orientation: 'portrait'
      pdfTemplate: @props.templateId
      pdfTemplateData:
        title: mediator.project.get 'name'
    ).then @props.onClose

  render: ->
    <Modal
      isOpen
      className="doc-sections-from-template-export-dialog"
      closeButton={false}
      title={@i18n 'title'}
    >
      <ExportDialog
        onClose={@props.onClose}
        onDownload={@onDownload}
      />
    </Modal>

module.exports = DocSectionsFromTemplateExportDialog
