var Migration, OutcomesSubmodule, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = OutcomesSubmodule = (function(_super) {
  __extends(OutcomesSubmodule, _super);

  function OutcomesSubmodule() {
    OutcomesSubmodule.__super__.constructor.call(this, false);
  }

  OutcomesSubmodule.prototype.up = function(project, colls) {
    var scopeModule, submodule;
    OutcomesSubmodule.__super__.up.apply(this, arguments);
    if (project.get('modules') === '*') {
      return W.resolve();
    }
    submodule = {
      id: 'outcomes',
      disabled: false
    };
    scopeModule = _(project.get('modules')).findWhere({
      id: 'scope'
    });
    if (scopeModule != null) {
      scopeModule.submodules.splice(2, 1, submodule);
    }
    return project.save();
  };

  return OutcomesSubmodule;

})(Migration);
