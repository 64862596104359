ReferencesInsertModal = require 'components/etd/references_insert_modal'
ReferencesActions = require 'actions/references_actions'
useTranslation = require 'components/hooks/use_translation'
{ insertReference } = require 'lib/epiditor_utils'
{ useState } = React
{ PMView } = Epiditor

ReferenceInsertControl = ({ view, onCloseMenu }) ->
  i18n = useTranslation('')
  canInsert = insertReference(view.state)({})

  closeModal = ->
    ReferencesActions.closeInsertModal()
    onCloseMenu()

  handleReferencesInsert = (data) ->
    view.focus()
    insertReference(view.state, view.dispatch)(data)
    closeModal()

  handleClick = (evt) ->
    evt.stopPropagation()
    ReferencesActions.openInsertModal({ handler: handleReferencesInsert })

  <div
    className={classNames 'reference-insert-toggle', disabled: !canInsert}
    onMouseDown={handleClick if canInsert}
  >
    <span>{i18n 'actions.insert_reference'}</span>
  </div>

ReferenceInsertControl.propTypes =
  view: PropTypes.instanceOf(PMView.EditorView).isRequired

module.exports = ReferenceInsertControl
