var controlHelpers, getCheckedControls, getRowDescriptionFunctionName,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

getRowDescriptionFunctionName = function(outcomeAttrs) {
  var caseControlValues, _ref, _ref1;
  caseControlValues = ['case_control_studies', 'case_control_and_other_combined'];
  if (_ref = (_ref1 = outcomeAttrs.designStudies) != null ? _ref1.value : void 0, __indexOf.call(caseControlValues, _ref) >= 0) {
    return 'caseControl';
  } else {
    return 'default';
  }
};

controlHelpers = {
  controlAttrToControlFormatter: function(controlAttrName) {
    if (controlAttrName === 'calculatedControl') {
      return 'control';
    } else {
      return "" + controlAttrName + "Risk";
    }
  },
  controlAttrToCorrespondingRiskFormatter: function(controlAttrName) {
    var riskLevel;
    if (controlAttrName === 'calculatedControl') {
      return 'correspondingRisk';
    } else {
      riskLevel = _.string.capitalize(controlAttrName.replace('Control', ''));
      return "correspondingRisk" + riskLevel;
    }
  },
  controlAttrToAbsoluteEffectFormatter: function(controlAttrName) {
    if (controlAttrName === 'calculatedControl') {
      return null;
    } else {
      return "absoluteEffect" + (_.string.capitalize(controlAttrName.replace('Control', '')));
    }
  }
};

getCheckedControls = function(outcomeAttrs) {
  var controls, controlsList, outcomeKeys;
  controlsList = ['calculatedControl', 'lowControl', 'moderateControl', 'highControl'];
  controls = _(outcomeAttrs).chain().pick(controlsList).compact().value();
  outcomeKeys = _(outcomeAttrs).keys();
  return _(controlsList).reduce(function(acc, controlName) {
    if (__indexOf.call(outcomeKeys, controlName) >= 0 && outcomeAttrs[controlName]) {
      acc[controlName] = outcomeAttrs[controlName];
    }
    return acc;
  }, {});
};

module.exports = {
  getCheckedControls: getCheckedControls,
  getRowDescriptionFunctionName: getRowDescriptionFunctionName,
  controlHelpers: controlHelpers
};
