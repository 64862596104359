var ChecklistModuleMigration, Migration, W, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

migrationName = 'add_checklist_module';

module.exports = ChecklistModuleMigration = (function(_super) {
  __extends(ChecklistModuleMigration, _super);

  function ChecklistModuleMigration() {
    ChecklistModuleMigration.__super__.constructor.call(this, false);
  }

  ChecklistModuleMigration.prototype.up = function(project, colls) {
    var projectModules, tasksModuleIdx, updateModules;
    ChecklistModuleMigration.__super__.up.apply(this, arguments);
    projectModules = project.get('modules');
    tasksModuleIdx = R.findIndex(R.whereEq({
      id: 'tasks'
    }), projectModules);
    if (projectModules === '*' || !R.isEmpty(projectModules[tasksModuleIdx].submodules)) {
      return W.resolve();
    } else {
      updateModules = R.set(R.lensPath([tasksModuleIdx, 'submodules']), [
        {
          id: 'tasks',
          optional: true,
          disabled: false
        }, {
          id: 'checklist',
          optional: true,
          disabled: false
        }
      ], projectModules);
      project.set('modules', updateModules);
      project.set('rev_author', "project_migration/" + migrationName);
      return project.save();
    }
  };

  return ChecklistModuleMigration;

})(Migration);
