var EtdHeaderCoiField, EtdTemplateHelper, EtdTemplateStore, EtdTemplatesActions, Migration, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

EtdTemplatesActions = require('actions/etd_templates_actions');

EtdTemplateHelper = require('stores/utils/etd_template_helper');

EtdTemplateStore = require('stores/etd_templates_store');

module.exports = EtdHeaderCoiField = (function(_super) {
  __extends(EtdHeaderCoiField, _super);

  function EtdHeaderCoiField() {
    EtdHeaderCoiField.__super__.constructor.call(this, false);
  }

  EtdHeaderCoiField.prototype.up = function(project, colls) {
    var addDataFields, addDefinitionFields;
    EtdHeaderCoiField.__super__.up.apply(this, arguments);
    addDefinitionFields = function(state, templateKey) {
      var alreadyHasField, questionDef;
      questionDef = state.getIn([templateKey, 'templateDef', 'question']);
      if (!questionDef) {
        return state;
      }
      alreadyHasField = questionDef.find(function(q) {
        return q.get('id') === 'coi';
      });
      if (alreadyHasField) {
        return state;
      }
      questionDef = questionDef.push(Immutable.Map({
        'id': 'coi',
        'checked': true
      }));
      return state.setIn([templateKey, 'templateDef', 'question'], questionDef);
    };
    addDataFields = function(state, templateKey) {
      var questionDataSections, questionSectionsOrder;
      questionDataSections = state.getIn([templateKey, 'templateData', 'question', 'sections']);
      if (!questionDataSections) {
        return state;
      }
      if (questionDataSections.has('coi')) {
        return state;
      }
      questionDataSections = questionDataSections.setIn(['coi'], Immutable.Map({
        'name': 'Conflict of interest',
        'content': ''
      }));
      state = state.setIn([templateKey, 'templateData', 'question', 'sections'], questionDataSections);
      questionSectionsOrder = state.getIn([templateKey, 'templateData', 'question', 'sectionsOrder']);
      if (questionSectionsOrder.indexOf('coi') !== -1) {
        return state;
      }
      questionSectionsOrder = questionSectionsOrder.push('coi');
      return state.setIn([templateKey, 'templateData', 'question', 'sectionsOrder', questionSectionsOrder]);
    };
    return W(EtdTemplateStore.fetch(project.id, ['etd-tx-template', 'etd-dx-template'])).then(function() {
      var state;
      state = EtdTemplateStore.getState();
      state = addDefinitionFields(state, 'dxTemplate');
      state = addDataFields(state, 'dxTemplate');
      if (!state.get('dxTemplate')) {
        return W.resolve();
      }
      return EtdTemplatesActions.saveTemplate(state.get('dxTemplate'), 'dx');
    }).then(function() {
      var state;
      state = EtdTemplateStore.getState();
      state = addDefinitionFields(state, 'txTemplate');
      state = addDataFields(state, 'txTemplate');
      if (!state.get('txTemplate')) {
        return W.resolve();
      }
      return EtdTemplatesActions.saveTemplate(state.get('txTemplate'), 'tx');
    }).otherwise(function(err) {
      console.log(err);
      throw err;
    });
  };

  return EtdHeaderCoiField;

})(Migration);
