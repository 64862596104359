LoadingBox = require 'components/common/loading_box'
ScopeDataFetchMixin = require 'components/mixins/scope_data_fetch_mixin'
ScopeRatingMixin = require 'components/mixins/scope_rating_mixin'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

{ Button } = ReactComponents

RatingTab = createReactClass
  displayName: 'RatingTab'

  propTypes:
    members: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
    membersSentTo: PropTypes.instanceOf(Immutable.List).isRequired
    membersSubmitted: PropTypes.instanceOf(Immutable.List).isRequired
    readOnly: PropTypes.bool.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:questions')
    ScopeDataFetchMixin
    ScopeRatingMixin
  ]

  render: ->
    <div className='questions-rating'>
      <div className='information'>
        {@i18n 'rating.header'}
      </div>
      <div className="buttons">
        <Button
          className='btn-refresh'
          onClick={@props.fetchResults}
          disabled={@_isFetchDisabled()}
        >
          {@i18n 'brainstorming.refresh'}
        </Button>
      </div>
      {if @props.isFetchingResults
        <LoadingBox />
      else
        <div className='members'>
          <ol className='standard-list'>
            {@props.membersSentTo.map @membersMapper}
          </ol>
        </div>
      }
      {unless @props.readOnly
        <div className='bottom-bar row mt-20'>
          <div className='col-12'>
            <button
              className='btn btn-block btn-send'
              onClick={@props.onCloseStep}
              disabled={@props.isClosingStep}
            >
              {@i18n 'rating.next_step'}
            </button>
          </div>
        </div>
      }
    </div>

module.exports = RatingTab
