_.namespace(module, function(require) {
  var W, coiCreateFormRequestPrefix, mediator, utils;
  mediator = require('mediator');
  utils = require('base/lib/utils');
  W = require('when');
  coiCreateFormRequestPrefix = require('lib/doc_prefixes').coiCreateFormRequest;
  return {
    _sendNotifications: function(membersFilter, mapper) {
      var filteredMembers;
      if (!mediator.project.get('coiFormType')) {
        mediator.dialogs.warning($.t('team:coi.form_type.not_selected'));
        return;
      }
      mediator.project.set('coiFormCreated', true);
      filteredMembers = mediator.colls.teamMembers.getPanelMembersColl().filter(membersFilter);
      return mediator.project.save().then(function() {
        return W.all(filteredMembers.map(mapper));
      }).then(function() {
        return W.all(filteredMembers.map(function(member) {
          return member.checkCoiFormStatus();
        }));
      }).otherwise(mediator.dialogs.fatalError);
    },
    sendReminderNotifications: function() {
      return this._sendNotifications((function(m) {
        return m.hasCoiFormStatus('new', 'mail-sent', 'in-progress');
      }), this._createCoiFormReminder);
    },
    sendNewNotifications: function() {
      return this._sendNotifications((function(m) {
        return !m.get('coiFormId');
      }), this._createCoiForm);
    },
    resendNotifications: function() {
      return this._sendNotifications((function(m) {
        return m.get('coiFormId');
      }), this._resendCoiNotification);
    },
    _getNotificationTemplateKey: function(formType) {
      switch (formType) {
        case 'nhf':
          return 'nhf';
        case 'nhi-it':
          return 'nhi-it';
        case 'mdg':
          return 'mdg';
        default:
          return 'standard';
      }
    },
    _resendCoiNotification: function(member) {
      var coiFormId, formType, projectName, requestPrefix, resendMailTemplate, templateKey;
      coiFormId = member.get('coiFormId');
      if (!coiFormId) {
        return;
      }
      formType = mediator.project.get('coiFormType');
      projectName = mediator.project.get('name');
      templateKey = this._getNotificationTemplateKey(formType);
      resendMailTemplate = require("views/templates/emails/coi/" + templateKey);
      requestPrefix = coiCreateFormRequestPrefix(mediator.project.id, member.id);
      return W(mediator.user.getRequests().create({
        type: 'coi_send_reminder',
        resetFormStatus: true,
        status: 'new',
        formType: formType,
        formId: coiFormId,
        firstName: member.get('givenNames'),
        lastName: member.get('lastName'),
        email: member.get('email'),
        subject: $.t("emails:coi." + templateKey + ".subject", {
          projectName: projectName
        }),
        body: resendMailTemplate({
          projectName: projectName,
          coiFormType: formType.toUpperCase()
        })
      }));
    },
    _createCoiFormReminder: function(member) {
      var coiFormId, coiReminderEmailTemplate, formType, projectName, reminderTemplate, templateKey;
      coiFormId = member.get('coiFormId');
      if (!coiFormId) {
        return;
      }
      formType = mediator.project.get('coiFormType');
      projectName = mediator.project.get('name');
      templateKey = this._getNotificationTemplateKey(formType);
      reminderTemplate = (function() {
        switch (formType) {
          case 'nhi-it':
            return 'nhi-it-reminder';
          case 'mdg':
            return 'mdg';
          default:
            return 'reminder';
        }
      })();
      coiReminderEmailTemplate = require("views/templates/emails/coi/" + reminderTemplate);
      return W(mediator.user.getRequests().create({
        type: 'coi_send_reminder',
        status: 'new',
        formType: formType,
        formId: coiFormId,
        firstName: member.get('givenNames'),
        lastName: member.get('lastName'),
        email: member.get('email'),
        subject: $.t("emails:coi." + templateKey + ".subject", {
          projectName: projectName
        }),
        body: coiReminderEmailTemplate({
          projectName: projectName
        })
      }));
    },
    _createCoiForm: function(member) {
      var formType, mailTemplate, projectName, requestPrefix, templateKey;
      formType = mediator.project.get('coiFormType');
      projectName = mediator.project.get('name');
      templateKey = this._getNotificationTemplateKey(formType);
      mailTemplate = require("views/templates/emails/coi/" + templateKey);
      requestPrefix = coiCreateFormRequestPrefix(mediator.project.id, member.id);
      return W(mediator.user.getRequests().create({
        _id: "" + requestPrefix + "_" + (utils.generateGUID()),
        type: 'coi_create_and_send_form',
        status: 'new',
        formType: formType,
        projectId: mediator.project.id,
        projectName: projectName,
        memberId: member.id,
        firstName: member.get('givenNames'),
        lastName: member.get('lastName'),
        email: member.get('email'),
        subject: $.t("emails:coi." + templateKey + ".subject", {
          projectName: projectName
        }),
        body: mailTemplate({
          projectName: projectName,
          coiFormType: formType.toUpperCase()
        })
      }));
    }
  };
});
