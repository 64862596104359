{ insertReference } = require 'lib/epiditor_utils'
ReferencesActions = require 'actions/references_actions'
ReferencesStore = require 'stores/references_store'
Tooltip = require 'components/common/tooltip'
{ useState, useEffect } = React
{ vancouverFormat } = require 'lib/references_utils'

refToString = (ref) ->
  { authors, year } = ref
  year = year.match(/\d{4}/)?[0]
  refAuthor = if R.isEmpty(authors) then "" else R.head(authors).lastName
  refAuthor = "#{refAuthor} et al." if authors.length > 1

  "#{refAuthor}, #{year or ''}"

Reference = ({ refIds, references, editorView, exportMode }) ->
  [currentReferences, setCurrentReferences] = useState(references)

  # get current reference data from References store
  useEffect(->
    setCurrentReferences _.indexBy ReferencesStore.getReferenceByIds(refIds).toJS(), '_id'
  , [])

  onInsertReference = (data) ->
    insertReference(editorView.state, editorView.dispatch)(data)
    ReferencesActions.closeInsertModal()

  handleReferenceClick = ->
    ReferencesActions.openInsertModal({ handler: onInsertReference, references })

  <span className="inserted-references" onClick={if exportMode then null else handleReferenceClick}>
    ({R.intersperse(', ', refIds.map (refId) ->
      currentReference = currentReferences[refId]
      reference = currentReference or references[refId]
      <Tooltip key={refId}>
        <span
          data-ref-id={refId}
          className={classNames 'inserted-reference', missing: R.isNil currentReference}
          title={vancouverFormat reference, true}
        >
          {refToString reference}
        </span>
      </Tooltip>
    )})
  </span>

class ReferenceNodeView
  constructor: (@node, @view, @getPos, @portalsAPI, @exportMode) ->
    @dom = document.createElement 'span'
    if @exportMode
      @dom.className = 'reference'
      @dom.setAttribute 'data-ref-ids', @node.attrs.refIds
      @dom.setAttribute 'data-refs', JSON.stringify @node.attrs.references

    @portalsAPI.show
      container: @dom
      children: <Reference {...@node.attrs} editorView={@view} exportMode={@exportMode}/>
  # stopEvent: -> true commented due to breaking editing inserted references
  ignoreMutation: -> true


module.exports = ReferenceNodeView
