var DocSectionsTemplatesActions, EtdTemplatesActions, TemplatesSettingsActions, alt, mediator;

alt = require('alt');

mediator = require('mediator');

EtdTemplatesActions = require('actions/etd_templates_actions');

DocSectionsTemplatesActions = require('actions/doc_sections_templates_actions');

TemplatesSettingsActions = (function() {
  function TemplatesSettingsActions() {}

  TemplatesSettingsActions.prototype.fetch = function() {
    EtdTemplatesActions.fetch({
      dbId: mediator.project.id,
      docId: ['etd-tx-template', 'etd-dx-template', 'etd-overarchingTx-template', 'etd-overarchingDx-template', 'etd-goodPracticeStatement-template', 'etd-keyMessage-template', 'etd-mdg-template']
    });
    return DocSectionsTemplatesActions.fetch();
  };

  return TemplatesSettingsActions;

})();

module.exports = alt.createActions(TemplatesSettingsActions);
