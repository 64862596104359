var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<td colspan=\""
    + escapeExpression(((helper = (helper = helpers.colspan || (depth0 != null ? depth0.colspan : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"colspan","hash":{},"data":data}) : helper)))
    + "\" class=\"outcome-label-row\">\n  <div class=\"outcome-label\">\n";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "OneRow, ACCP, Prognosis", (depth0 != null ? depth0.viewTypeName : depth0), {"name":"inStringArray","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <div class=\"name-block\"></div>\n  </div>\n";
  stack1 = ((helpers.ifNotPrintout || (depth0 && depth0.ifNotPrintout) || helperMissing).call(depth0, (depth0 != null ? depth0.viewTypeName : depth0), {"name":"ifNotPrintout","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</td>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <button\n        class=\"edit ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.forceTypeSelection : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\"\n        title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "edit", {"name":"i18n","hash":{},"data":data})))
    + "' />\n";
},"3":function(depth0,helpers,partials,data) {
  return "force-type";
  },"5":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "    <div class=\"outcome-edit-block\">\n      <div class=\"outcome-label-edit\">\n        <button class=\"save\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "apply", {"name":"i18n","hash":{},"data":data})))
    + "'></button>\n        <div class=\"label-input\">\n          <input class=\"footnote-name\" data-property=\"name\" name=\"name\"\n            type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n        </div>\n      </div>\n      <div class=\"outcome-toggleable-editor row\">\n        <div class=\"outcome-edit col-12\">\n          <div class=\"row\">\n            <div class=\"outcome-sort-delete\">\n              <div>\n                <button class=\"sort\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sort", {"name":"i18n","hash":{},"data":data})))
    + "' tabindex=\"-1\"/>\n              </div>\n              <div>\n                <button class=\"delete\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "delete", {"name":"i18n","hash":{},"data":data})))
    + "' tabindex=\"-1\"></button>\n              </div>\n            </div>\n            <div class=\"left-side-edit col-5\">\n              <div class=\"row mt-10\">\n                <div class=\"short-name edit-top-row col-5\">\n                  <label for=\"shortName\" class=\"footnote-shortName\">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "short_name", {"name":"i18n","hash":{},"data":data})))
    + "\n                  </label>\n                  <input id=\"shortName\" data-property=\"shortName\" class=\"form-control\"\n                    name=\"shortName\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.shortName || (depth0 != null ? depth0.shortName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"shortName","hash":{},"data":data}) : helper)))
    + "\">\n                </div>\n                <div class=\"edit-top-row col-6\">\n                  <label for=\"measuredWith\" class=\"footnote-measuredWith\">\n                    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "measured_with", {"name":"i18n","hash":{},"data":data})))
    + "\n                  </label>\n                  <input id=\"measuredWith\" data-property=\"measuredWith\" name=\"measuredWith\"\n                    type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.measuredWith || (depth0 != null ? depth0.measuredWith : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"measuredWith","hash":{},"data":data}) : helper)))
    + "\" class=\"form-control\">\n                </div>\n                <div class=\"col-1\"></div>\n              </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.thresholdFeature : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "              <div class=\"left-side-col2 col-12\">\n";
  stack1 = this.invokePartial(partials.followUpLength, '                ', 'followUpLength', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "              </div>\n              <div class=\"left-side-col2 col-12 mt-10 cont-outcome-scores-scale\">\n                <div class=\"scale-type-select\">\n                  <span class=\"label\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_scale_type", {"name":"i18n","hash":{},"data":data})))
    + ": </span>\n                  "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "scaleType", (depth0 != null ? depth0.scaleType : depth0), "ordinal: ordinal_measurement_scale, ratioOrInterval: ratio_interval_scale", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n                </div>\n                <div class=\"range-of-scores\">\n                  <div class=\"mt-10 scale-to\" data-property=\"scaleTo\">\n";
  stack1 = this.invokePartial(partials.rangeOfScores, '                    ', 'rangeOfScores', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "                  </div>\n                </div>\n                <div class=\"conventional-scale-limits\">\n                  <div class=\"mt-10\">\n";
  stack1 = this.invokePartial(partials.conventionalScaleLimits, '                    ', 'conventionalScaleLimits', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "                  </div>\n                </div>\n              </div>\n            </div>\n            <div class=\"right-side-edit col-7\">\n              <div class=\"row mt-10\">\n                <span class=\"outcome-type-text footnote-type\">\n                  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "type", {"name":"i18n","hash":{},"data":data})))
    + "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.forceTypeSelection : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                </span>\n              </div>\n              <div class=\"edit-top-row row\">\n                <div class=\"outcome-type\">\n                  <div class=\"col-6 ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.forceTypeSelection : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n                    <div class=\"box-bordered\">\n                      <ul>\n                        <li>\n                          <label class=\"radio-label\"><input type=\"radio\" data-property=\"type\"\n                            name=\"type"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\" "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "dich", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dich", {"name":"i18n","hash":{},"data":data})))
    + "\n                          </label>\n                        </li>\n                        <li>\n                          <label class=\"radio-label ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isCaseControl : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n                            <input type=\"radio\" data-property=\"type\" name=\"type"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                              "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"checkedIfEq","hash":{},"data":data})))
    + " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isCaseControl : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n                              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "cont", {"name":"i18n","hash":{},"data":data})))
    + "\n                          </label>\n                        </li>\n                        <li>\n";
  stack1 = this.invokePartial(partials.timeToEventSelector, '                          ', 'timeToEventSelector', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </li>\n                        <li>\n                          <label class=\"radio-label\"><input type=\"radio\" data-property=\"type\"\n                            name=\"type"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\" "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "narrative", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "narrative", {"name":"i18n","hash":{},"data":data})))
    + "\n                          </label>\n                        </li>\n                      </ul>\n                    </div>\n                  </div>\n                  <div class=\"col-5\">\n                    <div class=\"box-bordered\">\n                      <ul class=\"horizontal\">\n                        <li>\n                          <label class=\"radio-label\">\n                            <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                              "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "POOLED", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "pooled", {"name":"i18n","hash":{},"data":data})))
    + "\n                          </label>\n                        </li>\n                        <li>\n                          <label class=\"radio-label\">\n                            <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                              "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "SINGLE_STUDY", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "single_study", {"name":"i18n","hash":{},"data":data})))
    + "\n                          </label>\n                        </li>\n                        <li>\n                          <label class=\"radio-label\">\n                            <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                              "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "NOT_POOLED", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "not_pooled", {"name":"i18n","hash":{},"data":data})))
    + "\n                          </label>\n                        </li>\n                        <li>\n                          <label class=\"radio-label\">\n                            <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                              "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "NOT_MEASURED", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "not_measured", {"name":"i18n","hash":{},"data":data})))
    + "\n                          </label>\n                        </li>\n                        <li>\n                          <label class=\"radio-label\">\n                            <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                              "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "RANGE_OF_EFFECTS", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "range_of_effects", {"name":"i18n","hash":{},"data":data})))
    + "\n                          </label>\n                        </li>\n                        <li>\n                          <label class=\"radio-label\">\n                            <input type=\"radio\" data-property=\"metaType\" name=\"metaType"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\"\n                              "
    + escapeExpression(((helpers.checkedIfEq || (depth0 && depth0.checkedIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.metaType : depth0), "NOT_REPORTED", {"name":"checkedIfEq","hash":{},"data":data})))
    + ">\n                              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "not_reported", {"name":"i18n","hash":{},"data":data})))
    + "\n                          </label>\n                        </li>\n                      </ul>\n                      <div class=\"clearfix\"></div>\n                    </div>\n                    <div class='quality-indicators-input-container'></div>\n                  </div>\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n";
},"6":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "                <div class=\"row mt-10 thresholds\">\n                  <div class=\"short-name edit-top-row col-4\">\n                    <label for=\"utilityValue\" class=\"footnote-utilityValue\">\n                      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "utility_value", {"name":"i18n","hash":{},"data":data})))
    + "\n                    </label>\n                    <input id=\"utilityValue\" data-property=\"utilityValue\" class=\"form-control\"\n                      name=\"utilityValue\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.utilityValue || (depth0 != null ? depth0.utilityValue : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"utilityValue","hash":{},"data":data}) : helper)))
    + "\">\n                  </div>\n                  <div class=\"short-name edit-top-row col-8 thresholds-inputs\">\n                    <div class=\"row\">\n                      <div class=\"col-4\">\n                        <label for=\"thresholdT1\" class=\"threshold-T1\">\n                          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "threshold_T1", {"name":"i18n","hash":{},"data":data})))
    + "\n                        </label>\n                        <input id=\"thresholdT1\" data-property=\"thresholdT1\" class=\"form-control\"\n                          name=\"thresholdT1\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.thresholdT1 || (depth0 != null ? depth0.thresholdT1 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"thresholdT1","hash":{},"data":data}) : helper)))
    + "\">\n                      </div>\n                      <div class=\"col-4\">\n                        <label for=\"thresholdT2\" class=\"threshold-T2\">\n                          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "threshold_T2", {"name":"i18n","hash":{},"data":data})))
    + "\n                        </label>\n                        <input id=\"thresholdT2\" data-property=\"thresholdT2\" class=\"form-control\"\n                          name=\"thresholdT2\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.thresholdT2 || (depth0 != null ? depth0.thresholdT2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"thresholdT2","hash":{},"data":data}) : helper)))
    + "\">\n                      </div>\n                      <div class=\"col-4\">\n                        <label for=\"thresholdT3\" class=\"threshold-T3\">\n                          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "threshold_T3", {"name":"i18n","hash":{},"data":data})))
    + "\n                        </label>\n                        <input id=\"thresholdT3\" data-property=\"thresholdT3\" class=\"form-control\"\n                          name=\"thresholdT3\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.thresholdT3 || (depth0 != null ? depth0.thresholdT3 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"thresholdT3","hash":{},"data":data}) : helper)))
    + "\">\n                      </div>\n                    </div>\n                  </div>\n                </div>\n";
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    <span class=\"select-outcome-type-warning\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "select_type", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n";
},"10":function(depth0,helpers,partials,data) {
  return "disabled";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}