var ChapterManagementMixin, mediator;

mediator = require('mediator');

ChapterManagementMixin = {
  showChapterManagement: function() {
    return !mediator.user.hasRole(['mdg_panel_reviewer', 'evolent_panel_reviewer']);
  }
};

module.exports = ChapterManagementMixin;
