Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
SearchBar = require 'components/common/search_bar'
TasksActions = require 'actions/tasks_actions'
TasksStore = require 'stores/tasks_store'

{ useI18n } = require 'lib/react_utils'

TasksToolbar = ({ searchQuery, filters }) ->
  i18n = useI18n()
  <div className="flex flex-row mx-10">
    <div className="flex flex-row flex-grow items-center">
      <h2>
        {i18n('tasks:tasks')}
      </h2>
      <div className="ml-20 flex flex-row items-center">
        <SearchBar
          autoFocus={false}
          lowerCaseSearchText={false}
          onSearch={TasksActions.search}
          onSearchReset={-> TasksActions.search ''}
          searchOnChange
          searchText={searchQuery}
        />
        <label className="ml-20">
          <input
            type="checkbox"
            className="mr-5"
            value={filters.get('completed')}
            onChange={TasksActions.toggleCompletedFilter}
          />
          {i18n('tasks:todo.show_completed_only')}
        </label>
      </div>
    </div>
    <Button
      className="btn"
      label={i18n 'tasks:todo.add_title'}
      onClick={() -> TasksActions.openCreateOrEditDialog()}
    />
  </div>

storeConnector =
  TasksStore: (Store) ->
    searchQuery: Store.getSearchQuery()
    filters: Store.getFilters()

module.exports = ConnectStore TasksToolbar, [
  TasksStore
], storeConnector
