ConnectStore = require 'components/enhancers/connect_store'
ProjectsHeader = require 'components/projects/projects_header'
ProjectInvitationsList = require 'components/projects/project_invitations_list'
ProjectsTabs = require 'components/projects/projects_tabs'
ProjectsStore = require 'stores/projects_store'
ProjectsActions = require 'actions/projects_actions'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'
{ storeConnectors } = require 'lib/projects_helper'
{ bool, instanceOf, string } = PropTypes

Projects = createReactClass
  displayName: 'ProjectsComponent'

  mixins: [
    Translation('projects:welcome')
  ]

  propTypes:
    activeProjects: instanceOf(Immutable.Map),
    activeSearch: instanceOf(Immutable.Map),
    activeTab: string,
    archivedProjects: instanceOf(Immutable.Map),
    copiesOfProjects: instanceOf(Immutable.Map),
    recentlyCopiedProjectId: string,
    hasEverFetched: bool,
    isFetching: bool,
    labelsData: instanceOf(Immutable.Map),
    organizationId: string,
    projectsLabels: instanceOf(Immutable.Map),
    projectsMembers: instanceOf(Immutable.Map),
    projectsNotes: instanceOf(Immutable.Map),
    projectsQuestions: instanceOf(Immutable.Map),
    projectsRemoteInfo: instanceOf(Immutable.Map),
    projectsReplicationProgress: instanceOf(Immutable.Map),
    projectsStatuses: instanceOf(Immutable.Map),
    sharingInvitations: instanceOf(Immutable.List),
    sortedBy: instanceOf(Immutable.Map),
    permittedProjectActions: instanceOf(Immutable.Map)
    successfulInvitationsToNotify: instanceOf(Immutable.List)
    movingProjectToOrganizationStatus: instanceOf(Immutable.Map)
    onboardingAutostart: bool.isRequired

  componentDidMount: ->
    { organizationId, hasEverFetched } = @props
    if hasEverFetched
      ProjectsActions.fetch { silent: true, organizationId }
    else
      ProjectsActions.fetch { organizationId }
    ProjectsActions.startOnboardingTour() if @props.onboardingAutostart

  componentWillUnmount: ->
    ProjectsActions.stopListeningChanges()

  render: ->
    <div className='projects-container'>
      <ProjectsHeader
        activeSearch={@props.activeSearch}
        onSearch={ProjectsActions.search}
        onSearchReset={ProjectsActions.resetSearch}
        onSearchFilterChange={ProjectsActions.changeSearchFilter}
        organizationId={@props.organizationId}
      />
      <ProjectInvitationsList
        projectInvitations={@props.sharingInvitations}
        successfulInvitationsToNotify={@props.successfulInvitationsToNotify}
      />
      <ProjectsTabs {...@props}
        onSort={ProjectsActions.sortProjects}
        onChangeTab={ProjectsActions.changeTab}
      />
    </div>

module.exports = ConnectStore Projects, [ProjectsStore], storeConnectors
