Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Select = require 'components/common/select'

BaseTemplateSelection = createReactClass
  displayName: 'BaseTemplateSelection'

  propTypes:
    listTxTemplates: PropTypes.bool
    listDxTemplates: PropTypes.bool
    listOverarchingTxTemplates: PropTypes.bool
    listOverarchingDxTemplates: PropTypes.bool
    listKeyMessageTemplates: PropTypes.bool
    selectedTemplateLanguage: PropTypes.string
    templateLanguages: PropTypes.shape
      sections: PropTypes.array
      defaultLanguage: PropTypes.string
    templatesOptions: PropTypes.arrayOf PropTypes.shape
      value: PropTypes.string
      text: PropTypes.string
    selectedTemplate: PropTypes.string
    changeBaseTemplate: PropTypes.func

  mixins: [CustomRenderMixin, Translation('settings:etd_templates')]

  getLabel: ->
    {
      listDxTemplates,
      listKeyMessageTemplates
      listOverarchingDxTemplates
      listOverarchingTxTemplates,
      listTxTemplates,
    } = @props

    if _.every([
        listDxTemplates,
        listKeyMessageTemplates
        listOverarchingDxTemplates,
        listOverarchingTxTemplates,
        listTxTemplates,
      ], (list) -> list is true)
        @i18n 'select_base_template'
    else if listTxTemplates
      @i18n 'select_base_tx_template'
    else if listDxTemplates
      @i18n 'select_base_dx_template'
    else if listOverarchingTxTemplates
      @i18n 'select_base_overarchingTx_template'
    else if listOverarchingDxTemplates
      @i18n 'select_base_overarchingDx_template'
    else if listKeyMessageTemplates
      @i18n 'select_base_keyMessage_template'

  render: ->

    {
      changeBaseTemplate
      listDxTemplates
      listOverarchingDxTemplates
      listOverarchingTxTemplates
      listTxTemplates
      listKeyMessageTemplates
      selectedTemplate
      selectedTemplateLanguage
      templateLanguages
      templatesOptions
    } = @props


    languages = _(templateLanguages.sections).map (lng) ->
      value: lng.id
      text: _.findWhere(window.gdt.allLanguages, value: lng.id)?.text
    currentLanguage = selectedTemplateLanguage ? templateLanguages.defaultLanguage

    if _.every([listTxTemplates, listDxTemplates, listOverarchingDxTemplates,
      listOverarchingTxTemplates, listKeyMessageTemplates], (list) -> list is false)
      null
    else
      <p className='base-template-selection'>
        <span className='template-select-text'>{@getLabel()}</span>
        <span style={display: 'inline-block'}>
          <Select
            options={templatesOptions}
            selected={selectedTemplate}
            onChange={(v) => changeBaseTemplate(v, currentLanguage)} />
        </span>
        <span style={display: 'inline-block'}>
          <Select
            options={languages}
            selected={selectedTemplateLanguage}
            onChange={(v) => changeBaseTemplate(selectedTemplate, v)} />
        </span>
      </p>

module.exports = BaseTemplateSelection
