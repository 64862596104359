{ useI18n } = require 'lib/react_utils'
Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
MdaVerificationStore = require 'stores/mda_verification_store'
MdaVerificationActions = require 'actions/mda_verification_actions'

storeConnector =
  MdaVerificationStore: (Store) ->
    isFetching: Store.isFetching()

VerificationToolbar = ({ isFetching }) ->
  i18n = useI18n('mda:verification')

  <div className="flex flex-row mx-10">
    <h2 className="flex-grow">{i18n('toolbar_title')}</h2>
    <Button
      className="btn btn-apply"
      label={i18n "/actions.refresh"}
      disabled={isFetching}
      onClick={MdaVerificationActions.fetch}
    />
  </div>

VerificationToolbar.propTypes =
  isFetching: PropTypes.bool.isRequired

module.exports = ConnectStore VerificationToolbar, [
  MdaVerificationStore
], storeConnector
