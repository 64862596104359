CommentItem = require 'components/document_sections/v2/comment_item'
{ reportRavenError } = require 'base/lib/utils'

BasicErrorBoundary = createReactClass
  displayName: 'BasicErrorBoundary'

  propTypes:
    children: PropTypes.node.isRequired
    fallback: PropTypes.node

  getDefaultProps: ->
    fallback: null

  getInitialState: ->
    error: null

  componentDidCatch: (error, errorInfo) ->
    reportRavenError error, extra: { errorInfo }

  render: ->
    { error } = @state
    if error then @props.fallback else @props.children

BasicErrorBoundary.getDerivedStateFromError = (error) ->
  return { error }

module.exports = BasicErrorBoundary
