var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "  <div class=\"edit-input-block form-group row mt-10\">\n    <div class=\"col-5-5\">\n      <label for=\"noOfPatients\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "number_of_participants", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n    </div>\n    <div class=\"col-5-5\">\n      <input type=\"text\" class=\"form-control\"\n        name=\"noOfPatients\" id=\"noOfPatients\" value=\""
    + escapeExpression(((helper = (helper = helpers.noOfPatients || (depth0 != null ? depth0.noOfPatients : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"noOfPatients","hash":{},"data":data}) : helper)))
    + "\" >\n    </div>\n    <div class=\"sup col-1\"></div>\n  </div>\n  <div class=\"edit-input-block form-group row mt-10\">\n    <div class=\"col-5-5\">\n      <label for=\"noOfStudies\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "number_of_studies", {"name":"i18n","hash":{},"data":data})))
    + "\n        <span class=\"context-help-icon\" data-property=\"noOfStudies\"></span>\n      </label>\n    </div>\n    <div class=\"col-5-5\">\n      <input type=\"text\" class=\"form-control\"\n        name=\"noOfStudies\" id=\"noOfStudies\" value=\""
    + escapeExpression(((helper = (helper = helpers.noOfStudies || (depth0 != null ? depth0.noOfStudies : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"noOfStudies","hash":{},"data":data}) : helper)))
    + "\" >\n    </div>\n    <div class=\"sup col-1\"></div>\n  </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "noOfParticipantsStudiesDiagnostic", "number_of_participants_and_studies", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}