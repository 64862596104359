ConnectStore = require 'components/enhancers/connect_store'
DBEPPublicationActions = require 'actions/dbep_publication_actions'
DBEPPublicationStore = require 'stores/dbep_publication_store'
Exception = require 'base/lib/exception'
Modal = require 'components/common/modal'
QuestionsSelection = require './questions_selection'
{ StepsContainer, Step } = require 'components/common/steps'
Translation = require 'components/mixins/translation'
Validation = require './validation'

storeConnector =
  DBEPPublicationStore: (Store) ->
    mode: Store.getMode()
    type: Store.getType()
    isOpen: Store.isModalOpened()
    isFetchingQuestions: Store.isFetchingQuestions()
    isValidating: Store.isValidating()
    step: Store.getCurrentStep()
    selectedQuestions: Store.getSelectedQuestions()
    validationError: Store.getValidationError()
    profiles: Store.getProfiles()
    isPublishing: Store.isPublishing()
    validationResult: Store.getValidationResult()
    validationOverride: Store.areErrorsOverridden()
    topAccepted: Store.areTOPAccepted()
    publishResult: Store.getPublishResult()

DBEPPublicationModal = createReactClass
  displayName: 'DBEPPublicationModal'
  mixins: [Translation('dbep:publish_dialog')]

  propTypes:
    mode: PropTypes.oneOf(['preview', 'publish', 'publish-no-moderation']).isRequired
    type: PropTypes.oneOf(['multi', 'single']).isRequired
    isOpen: PropTypes.bool.isRequired
    isFetchingQuestions: PropTypes.bool.isRequired
    isValidating: PropTypes.bool.isRequired
    step: PropTypes.oneOf(['selectQuestions', 'publication']).isRequired
    selectedQuestions: PropTypes.instanceOf(Immutable.List).isRequired
    validationError: PropTypes.oneOfType([
      PropTypes.string, PropTypes.instanceOf(Error), PropTypes.instanceOf(Exception)
    ])
    profiles: PropTypes.instanceOf(Immutable.List).isRequired
    isPublishing: PropTypes.bool.isRequired
    validationResult: PropTypes.instanceOf(Immutable.Map).isRequired
    validationOverride: PropTypes.bool.isRequired
    topAccepted: PropTypes.bool.isRequired
    publishResult: PropTypes.instanceOf(Immutable.Map).isRequired

  _isCloseEnabled: ->
    { isFetchingQuestions, isValidating } = @props
    not isFetchingQuestions and not isValidating

  _getTitle: ->
    switch @props.mode
      when 'preview'
        @i18n 'title_preview'
      else
        @i18n 'title'

  _getPublicationStepLabel: ->
    switch @props.mode
      when 'preview'
        @i18n 'steps.preview'
      else
        @i18n 'steps.publication'

  _getPublicationStepNextButtonLabel: ->
    { mode, validationError, publishResult } = @props
    switch mode
      when 'preview'
        @i18n '/actions.close'
      else
        if validationError? or publishResult.get 'ok'
          @i18n '/actions.close'
        else
          @i18n '/actions.publish'

  _getSelectQuestionsStepNextButtonLabel: ->
    switch @props.mode
      when 'preview'
        @i18n '/actions.preview'
      else
        @i18n '/actions.next'

  _isNextStepEnabled: ->
    {
      mode,
      step,
      selectedQuestions,
      isValidating,
      validationError,
      validationResult,
      validationOverride,
      topAccepted,
      publishResult
    } = @props
    switch step
      when 'selectQuestions'
        not selectedQuestions.isEmpty()
      else
        return false if isValidating or validationError?
        switch mode
          when 'preview'
            true
          when 'publish-no-moderation'
            return true if publishResult.get 'ok'
            validationResult.get('ok') or validationOverride
          when 'publish'
            return true if publishResult.get 'ok'
            topAccepted and (validationResult.get('ok') or validationOverride)

  _isApplying: ->
    { step, mode, isPublishing } = @props
    return false if step is 'selectQuestions' or mode is 'preview'
    isPublishing

  _shouldDisplayCancelButton: ->
    { mode, step, publishResult } = @props
    return false if publishResult.get 'ok'
    not (mode is 'preview' and step isnt 'selectQuestions')

  _shouldDisplayButtons: ->
    not @props.isValidating

  sendQuestions: ->
    { mode, selectedQuestions } = @props
    return if selectedQuestions.isEmpty()
    questionIds = selectedQuestions.toJS()
    switch mode
      when 'preview'
        DBEPPublicationActions.sendPreview questionIds
      else
        DBEPPublicationActions.validate questionIds

  sendPublish: ->
    { mode, profiles, validationError, publishResult } = @props
    switch mode
      when 'preview'
        DBEPPublicationActions.closeModal()
      else
        if validationError? or publishResult.get 'ok'
          DBEPPublicationActions.closeModal()
        else
          DBEPPublicationActions.publish profiles.toJS(), mode

  render: ->
    { isOpen, step, type } = @props

    <Modal
      isOpen={isOpen}
      title={@_getTitle()}
      closeButton={@_isCloseEnabled()}
      onClose={DBEPPublicationActions.closeModal}
      modalSize="medium"
      className="dbep-modal"
    >
      <StepsContainer
        currentStep={step}
        applying={@_isApplying()}
        isNextStepEnabled={@_isNextStepEnabled()}
        displayCancelButton={@_shouldDisplayCancelButton()}
        displayButtons={@_shouldDisplayButtons()}
        displayStepIndicator={type isnt 'single'}
      >
        <Step
          stepKey="selectQuestions"
          stepLabel={@i18n 'steps.selectQuestions'}
          nextLabel={@_getSelectQuestionsStepNextButtonLabel()}
          backLabel={@i18n '/actions.cancel'}
          onNext={@sendQuestions}
          onBack={DBEPPublicationActions.closeModal}
        >
          <QuestionsSelection />
        </Step>
        <Step
          stepKey="publication"
          stepLabel={@_getPublicationStepLabel()}
          nextLabel={@_getPublicationStepNextButtonLabel()}
          backLabel={@i18n '/actions.cancel'}
          onNext={@sendPublish}
          onBack={DBEPPublicationActions.closeModal}
        >
          <Validation />
        </Step>
      </StepsContainer>
    </Modal>

module.exports = ConnectStore DBEPPublicationModal, [DBEPPublicationStore], storeConnector
