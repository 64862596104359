Question = require 'components/scope/questions/question'
QuestionWithComments = require 'components/scope/questions/question_with_comments'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ instanceOf, bool, func, string } = PropTypes

QuestionsList = createReactClass
  displayName: 'QuestionsList'

  propTypes:
    questions: instanceOf(Immutable.List).isRequired
    readOnly: bool.isRequired
    membersMap: instanceOf(Immutable.OrderedMap)
    onCommentToggle: func
    onApplyAdminComment: func
    showingAllComments: bool

  mixins: [ CustomRenderMixin ]

  _getCommentsProps: ->
    membersMap: @props.membersMap
    showingComments: @props.showingAllComments
    onCommentToggle: @props.onCommentToggle
    onApplyAdminComment: @props.onApplyAdminComment
    readOnly: @props.readOnly

  renderQuestion: (qData, idx) ->
    memberComments = qData.get 'memberComments', Immutable.Map()
    adminComments = qData.get 'adminComments', Immutable.Map()
    liClass = classNames qData.get('source', ''),
      'with-comments': not memberComments.isEmpty()
      'expanded': @props.showingAllComments

    <li className={liClass} key={idx}>
      {if memberComments.isEmpty()
        <Question text={qData.get 'question'} />
      else
        <QuestionWithComments {...@_getCommentsProps()}
          data={qData}
          memberComments={memberComments}
          adminComments={adminComments}
        />
      }
    </li>

  render: ->
    <ol className='standard-list'>
      {@props.questions.map @renderQuestion}
    </ol>

module.exports = QuestionsList
