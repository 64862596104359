var clock;

clock = require('lib/services/clock_service');

module.exports = {
  prepareDeleteConflictDocs: function(dbName, docId, conflictedRevs) {
    var timestamp;
    if (conflictedRevs == null) {
      conflictedRevs = [];
    }
    if (_.isEmpty(conflictedRevs)) {
      return [];
    }
    timestamp = clock.getTimestamp();
    return _(conflictedRevs).map(function(rev) {
      return {
        _id: docId,
        _rev: rev,
        _deleted: true,
        '$timestamp': timestamp,
        info: 'Deleted by doc.helper.deleteDocConflicts'
      };
    });
  }
};
