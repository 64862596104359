EditableQuestion = require 'components/scope/questions/question_editable'
EditableQuestionWithComments = require 'components/scope/questions/question_editable_with_comments'
QuestionEdit = require 'components/scope/questions/question_edit'
QuestionsGenerationActions = require 'actions/questions_generation_actions'
dndSpec = require 'components/scope/questions/question_dnd_spec'
DragDropTypes = require 'components/scope/questions/drag_drop_types'
{ getDragWrapper } = require 'lib/react_dnd'
QuestionDrag = getDragWrapper DragDropTypes.QUESTION, dndSpec
{ instanceOf, func, bool, string } = PropTypes

EditableQuestionsList = createReactClass
  displayName: 'EditableQuestionsList'

  propTypes:
    questions: instanceOf(Immutable.List).isRequired
    membersMap: instanceOf(Immutable.OrderedMap)
    addingQuestion: string
    editingQuestion: string
    onCommentToggle: func
    onApplyAdminComment: func
    showingAllComments: bool

  _addingQuestionRef: (el) ->
    @addingQuestion = el

  _getCommentsProps: ->
    membersMap: @props.membersMap
    showingComments: @props.showingAllComments
    onCommentToggle: @props.onCommentToggle
    onApplyAdminComment: @props.onApplyAdminComment
    readOnly: false

  _getEditProps: ->
    onEdit: @onEdit
    onSave: QuestionsGenerationActions.saveQuestion
    onDelete: QuestionsGenerationActions.deleteQuestion

  onEdit: (id) ->
    @saveCurrentlyAdding() if @props.addingQuestion?
    QuestionsGenerationActions.editQuestion id

  saveCurrentlyAdding: ->
    @addingQuestion.save()

  renderQuestion: (qData, idx) ->
    isEditing = @props.editingQuestion is qData.get 'id'
    type = qData.get('type')
    memberComments = qData.get 'memberComments', Immutable.Map()
    adminComments = qData.get 'adminComments', Immutable.Map()
    liClass = classNames
      'with-comments': not memberComments.isEmpty()
      'expanded': isEditing
    props = _.extend { type, isEditing, data: qData }, @_getEditProps()

    <QuestionDrag id={qData.get 'id'} qIndex={idx} key={idx} canDrag={not isEditing}>
      <li className={liClass} key={idx}>
        {if memberComments.isEmpty()
          <EditableQuestion {...props} />
        else
          commentsProps = @_getCommentsProps()

          <EditableQuestionWithComments {...props} {...commentsProps}
            memberComments={memberComments}
            adminComments={adminComments}
          />
        }
      </li>
    </QuestionDrag>

  render: ->
    <ol className='standard-list'>
      {@props.questions.map @renderQuestion}
      {if @props.addingQuestion
        <li className='expanded' key={1}>
          <QuestionEdit
            ref={@_addingQuestionRef}
            type={@props.addingQuestion}
            onSave={QuestionsGenerationActions.saveQuestion}
            onDelete={QuestionsGenerationActions.deleteQuestion}
          />
        </li>
      }
    </ol>

module.exports = EditableQuestionsList
