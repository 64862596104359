var OVERARCHING_QUESTIONS_DB_VIEW, OVERARCHING_QUESTION_METADATA, QUESTION_DIAG_TAGS, QUESTION_MGMT_TAGS, QUESTION_TAGS, getCreatorFields,
  __slice = [].slice;

OVERARCHING_QUESTION_METADATA = {
  order: ['age', 'intent'],
  values: {
    age: {
      id: 'age',
      type: 'multipleChoice',
      values: ['neonate', 'infant', 'child', 'adolescent', 'adult', 'unspecified', 'not_applicable'],
      autoAddValues: []
    },
    intent: {
      id: 'intent',
      type: 'singleChoice',
      values: ['infection_control', 'preventive', 'screening', 'diagnostic', 'treatment', 'models_of_care'],
      autoAddValues: ['all']
    }
  }
};

QUESTION_DIAG_TAGS = ['indexTest', 'comparatorTest', 'population'];

QUESTION_MGMT_TAGS = ['intervention', 'comparison', 'population', 'outcome'];

QUESTION_TAGS = _.uniq(__slice.call(QUESTION_MGMT_TAGS).concat(__slice.call(QUESTION_DIAG_TAGS)));

OVERARCHING_QUESTIONS_DB_VIEW = {
  map: function(doc) {
    if (doc.docType === 'overarchingQuestion') {
      return emit(doc._id, doc);
    }
  }
};

getCreatorFields = function(OverarchingQuestionActions, canChangeType, tags) {
  return [
    {
      key: 'question',
      type: 'text',
      nameI18nKey: 'overarching_questions:labels.overarching_question'
    }, {
      key: 'type',
      type: 'select',
      nameI18nKey: 'overarching_questions:labels.question_type',
      disabled: !canChangeType,
      options: [
        {
          text: $.t('es:question.management'),
          value: 'overarching:management'
        }, {
          text: $.t('es:question.diagnostic'),
          value: 'overarching:diagnostic'
        }
      ],
      overrideProps: {
        onChange: OverarchingQuestionActions.updateCurrentQuestionType
      }
    }, {
      key: 'tags',
      type: 'tags',
      tagsProps: {
        onAddCode: OverarchingQuestionActions.addCode,
        onAddTag: OverarchingQuestionActions.addTag,
        onRemoveCode: OverarchingQuestionActions.removeCode,
        onRemoveTag: OverarchingQuestionActions.removeTag,
        onUpdateTag: OverarchingQuestionActions.updateTag,
        tags: tags
      }
    }, {
      key: 'separator',
      type: 'separator'
    }, {
      key: 'setting',
      type: 'text',
      nameI18nKey: 'overarching_questions:labels.setting'
    }, {
      key: 'author',
      type: 'text',
      nameI18nKey: 'overarching_questions:labels.author'
    }
  ];
};

module.exports = {
  OVERARCHING_QUESTIONS_DB_VIEW: OVERARCHING_QUESTIONS_DB_VIEW,
  OVERARCHING_QUESTION_METADATA: OVERARCHING_QUESTION_METADATA,
  QUESTION_TAGS: QUESTION_TAGS,
  QUESTION_DIAG_TAGS: QUESTION_DIAG_TAGS,
  QUESTION_MGMT_TAGS: QUESTION_MGMT_TAGS,
  getCreatorFields: getCreatorFields
};
