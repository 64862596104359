AddCustomTooltip = require 'components/enhancers/add_custom_tooltip'
StatusLegend = require 'components/panel_voice/status_legend'
Translation = require 'components/mixins/translation'
{ bool } = PropTypes

LegendIcon = (props) ->
  <span className='info-sign'{...props} />

tooltipParams = trigger: 'click', position: 'bottom', alignment: 'center', positionOffset: 5
LegendTooltip = AddCustomTooltip LegendIcon, StatusLegend, tooltipParams

QuestionsTableHeader = createReactClass
  displayName: 'PanelVoiceQuestionsTableHeader'

  propTypes:
    withGroupsOffset: bool.isRequired

  mixins: [ Translation('voting:panel_voice') ]

  getInitialState: ->
    statusLegendVisible: true

  render: ->
    tableHeaderClass = classNames 'panel-voice-questions__header',
      'with-groups-offset': @props.withGroupsOffset

    <div className={tableHeaderClass}>
      <div className='questions'>{@i18n 'questions'}</div>
      <div className='responses'>{@i18n 'responses'}</div>
      <div className='due-date'>{@i18n '../voting.modals.due_date'}</div>
      <div className='status'>
        <LegendTooltip />
        {@i18n 'status'}
      </div>
      <div className='actions'>{@i18n 'actions'}</div>
    </div>

module.exports = QuestionsTableHeader
