var Editor, annotationConverter, defaultDocxSerializer, getImageBuffer, removeHTMLNode, serializeNode,
  __slice = [].slice;

Editor = Epiditor["default"];

defaultDocxSerializer = EpiditorDocx.defaultDocxSerializer;

removeHTMLNode = require('base/lib/utils').removeHTMLNode;

annotationConverter = function(annotation) {
  return new Promise(function(resolve) {
    var element, onLoad, reactContainer;
    reactContainer = document.createElement('div');
    onLoad = function(_arg) {
      var div, dom, text, _ref, _ref1;
      dom = _arg.dom;
      div = document.createElement('div');
      text = $(div).addClass('epiditor--container').prop('innerHTML', dom.outerHTML).text();
      removeHTMLNode(div);
      ReactDOM.unmountComponentAtNode(reactContainer);
      removeHTMLNode(reactContainer);
      return resolve({
        author: (_ref = (_ref1 = annotation.author) != null ? _ref1.name : void 0) != null ? _ref : '',
        date: new Date(annotation.time),
        text: text
      });
    };
    element = React.createElement(Editor, {
      extensions: [],
      initialState: annotation.data.text,
      mode: 'readonly',
      onLoad: onLoad
    });
    return ReactDOM.render(element, reactContainer);
  });
};

getImageBuffer = function(imageData) {
  return function(src) {
    var imageDataObj;
    imageDataObj = Object.assign.apply(Object, [{}].concat(__slice.call(imageData)));
    return Buffer.from(imageDataObj["" + src], 'base64');
  };
};

serializeNode = function(node, options) {
  var annotations, annotationsToPass, commentStartIdx, imageData, optionsToPass, schema;
  annotations = options.annotations, commentStartIdx = options.commentStartIdx, imageData = options.imageData, schema = options.schema;
  if (imageData == null) {
    imageData = {};
  }
  annotationsToPass = _.chain(annotations != null ? annotations : {}).pairs().reject(function(_arg) {
    var annotation, _id;
    _id = _arg[0], annotation = _arg[1];
    return annotation.resolved;
  }).object().value();
  optionsToPass = {
    annotations: annotationsToPass,
    annotationConverter: annotationConverter,
    commentStartIdx: commentStartIdx,
    getImageBuffer: getImageBuffer(imageData),
    schema: schema
  };
  return defaultDocxSerializer.serialize(node, optionsToPass);
};

module.exports = {
  serializeNode: serializeNode
};
