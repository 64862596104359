var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.diagnosticPrintoutSofSettings, '', 'diagnosticPrintoutSofSettings', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  return "short";
  },"5":function(depth0,helpers,partials,data) {
  return "with-comparator";
  },"7":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "      <tr class=\"outcome-diag-sof-row\">\n        <th rowspan=\"3\" class=\"diagnostic-outcome-label bold\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.test_result", {"name":"i18n","hash":{},"data":data})))
    + "\n        </th>\n        <th class=\"effect-head-cell bold\" colspan=\"6\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.no_of_results_per_1000", {"name":"i18n","hash":{},"data":data})))
    + "\n        </th>\n        <th rowspan=\"3\" class=\"no-of-participants-studies bold\">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.number_of_participants", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " <br>\n            ("
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.studies", {"name":"i18n","hash":{},"data":data})))
    + ")\n        </th>\n        <th rowspan=\"3\" class=\"quality bold\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.certainty_of_evidence", {"name":"i18n","hash":{},"data":data})))
    + "\n        </th>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.noComments : depth0), {"name":"unless","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </tr>\n      <tr class=\"outcome-diag-sof-row\">\n        <th class=\"prevalence-1\" colspan=\"2\">\n          <span class=\"bold\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.prevalence", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence1 || (depth0 != null ? depth0.prevalence1 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n          <div>"
    + escapeExpression(((helper = (helper = helpers.prevalence1Characteristics || (depth0 != null ? depth0.prevalence1Characteristics : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1Characteristics","hash":{},"data":data}) : helper)))
    + "</div>\n        </th>\n        <th class=\"prevalence-2\" colspan=\"2\">\n          <span class=\"bold\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.prevalence", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence2 || (depth0 != null ? depth0.prevalence2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n          <div>"
    + escapeExpression(((helper = (helper = helpers.prevalence2Characteristics || (depth0 != null ? depth0.prevalence2Characteristics : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2Characteristics","hash":{},"data":data}) : helper)))
    + "</div>\n        </th>\n        <th class=\"prevalence-3\" colspan=\"2\">\n          <span class=\"bold\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.prevalence", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence3 || (depth0 != null ? depth0.prevalence3 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n          <div>"
    + escapeExpression(((helper = (helper = helpers.prevalence3Characteristics || (depth0 != null ? depth0.prevalence3Characteristics : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3Characteristics","hash":{},"data":data}) : helper)))
    + "</div>\n        </th>\n      </tr>\n      <tr class=\"outcome-diag-sof-row\">\n        <th class=\"diagnostic-effect prevalence-1\">"
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-1\">"
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-2\">"
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-2\">"
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-3\">"
    + escapeExpression(((helper = (helper = helpers.indexTest || (depth0 != null ? depth0.indexTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"indexTest","hash":{},"data":data}) : helper)))
    + "</th>\n        <th class=\"diagnostic-effect prevalence-3\">"
    + escapeExpression(((helper = (helper = helpers.comparatorTest || (depth0 != null ? depth0.comparatorTest : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"comparatorTest","hash":{},"data":data}) : helper)))
    + "</th>\n      </tr>\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <th rowspan=\"3\" class=\"comments bold\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.comments", {"name":"i18n","hash":{},"data":data})))
    + "\n            </th>\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "      <tr class=\"outcome-diag-sof-row\">\n        <th rowspan=\"2\" class=\"diagnostic-outcome-label bold\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.test_result", {"name":"i18n","hash":{},"data":data})))
    + "\n        </th>\n        <th class=\"effect-head-cell bold\" colspan=\"3\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.no_of_results_per_1000", {"name":"i18n","hash":{},"data":data})))
    + "\n        </th>\n        <th rowspan=\"2\" class=\"no-of-participants-studies bold\">\n          ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.number_of_participants", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " <br>\n            ("
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.studies", {"name":"i18n","hash":{},"data":data})))
    + ")\n        </th>\n        <th rowspan=\"2\" class=\"quality bold\">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.certainty_of_evidence", {"name":"i18n","hash":{},"data":data})))
    + "\n        </th>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.noComments : depth0), {"name":"unless","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </tr>\n      <tr class=\"outcome-diag-sof-row\">\n        <th class=\"diagnostic-effect prevalence-1\">\n          <span class=\"content bold\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.prevalence", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence1 || (depth0 != null ? depth0.prevalence1 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n          <div>"
    + escapeExpression(((helper = (helper = helpers.prevalence1Characteristics || (depth0 != null ? depth0.prevalence1Characteristics : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1Characteristics","hash":{},"data":data}) : helper)))
    + "</div>\n        </th>\n        <th class=\"diagnostic-effect prevalence-2\">\n          <span class=\"content bold\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.prevalence", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence2 || (depth0 != null ? depth0.prevalence2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n          <div>"
    + escapeExpression(((helper = (helper = helpers.prevalence2Characteristics || (depth0 != null ? depth0.prevalence2Characteristics : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2Characteristics","hash":{},"data":data}) : helper)))
    + "</div>\n        </th>\n        <th class=\"diagnostic-effect prevalence-3\">\n          <span class=\"content bold\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.prevalence", {"name":"i18n","hash":{},"data":data})))
    + "\n            <span class=\"prev-value\">"
    + escapeExpression(((helper = (helper = helpers.prevalence3 || (depth0 != null ? depth0.prevalence3 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3","hash":{},"data":data}) : helper)))
    + "</span>%\n          </span>\n          <div>"
    + escapeExpression(((helper = (helper = helpers.prevalence3Characteristics || (depth0 != null ? depth0.prevalence3Characteristics : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3Characteristics","hash":{},"data":data}) : helper)))
    + "</div>\n        </th>\n      </tr>\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <th rowspan=\"2\" class=\"comments bold\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.comments", {"name":"i18n","hash":{},"data":data})))
    + "\n            </th>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<table class=\"outcomes-table ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" width=\"100%\">\n  <thead>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(11, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </thead>\n\n  <tbody class=\"outcomes\"></tbody>\n</table>\n<div class=\"conjunctions\">";
  stack1 = ((helper = (helper = helpers.conjunctions || (depth0 != null ? depth0.conjunctions : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"conjunctions","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}