ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
ConnectStore = require 'components/enhancers/connect_store'
EtdsStore = require 'stores/etds_store'
NMAListStore = require 'stores/nma_list_store'
NMAQuestionStore = require 'stores/nma_question_store'
IconButton = require 'components/common/icon_button'
Spinner = require 'components/common/spinner'
mediator = require 'mediator'
{ I18nTable } = VIComponents
{ merge } = require 'lodash'
{ useI18n, useCoffeeMemo } = require 'lib/react_utils'
{ getVisualizationData } = require 'lib/visualization_utils'
{ useEffect } = React

storeConnector =
  EtdsStore: (Store, props) ->
    etds: Store.getRecommendations(props.recIdsToBeFetchedFromStore)

  NMAListStore: (Store) ->
    isFetchingNMAList: Store.isFetching()
    nmaList: Store.getQuestions()

  NMAQuestionStore: (Store) ->
    isFetchingNMAQuestionsData: Store.isFetching()
    outcomes: Store.getOutcomes()

InteractiveTable = ({
  etds
  isFetchingNMAList
  isFetchingNMAQuestionsData
  nmaList
  outcomes
  populationsSection
  recIdsToBeFetchedFromStore
  tableData
}) ->
  [ preparingData, setPreparingData ] = React.useState(false)
  [ renderMode, setRenderMode ] = React.useState('table')
  [ recOutcomesData, setRecOutcomesData ] = React.useState({})

  i18n = useI18n()

  toggleRenderMode = -> setRenderMode(if renderMode is 'table' then 'visualization' else 'table')

  useEffect ->
    setPreparingData(true)
    getVisualizationData(tableData, populationsSection, etds)
    .then (data) -> setRecOutcomesData data
    .finally ->
      setPreparingData(false)

    return undefined
  , [populationsSection, etds, tableData]

  data = useCoffeeMemo [tableData, recOutcomesData], ->
    merge(tableData.toJS(), recOutcomesData)

  if isFetchingNMAList or isFetchingNMAQuestionsData or preparingData
    return <Spinner />

  analysis = nmaList.map (nma) ->
    Immutable.Map({
      _id: nma.get('_id')
      title: nma.get('question')
      interventions: nma.get('interventions')
      outcomes: nma.get('outcomes').map (outcomeId) -> outcomes.get(outcomeId)
    })

  confirmDeleteWindow = ({ title, message, onCancel, onConfirm }) ->
    confirmOptions =
      title: title
      message: message
      confirmText: i18n '/actions.delete'
      declineText: i18n '/actions.cancel'
      yesClass: 'danger'
    mediator.dialogs.confirm confirmOptions, (confirmed) ->
      if confirmed then onConfirm?() else onCancel?()

  <div>
    <I18nTable
      analysisGroups={analysis.toJS()}
      data={data}
      i18n={i18next}
      onSave={ACPVisualGuidelinesActions.updateTableData}
      confirmWindow={confirmDeleteWindow}
    />
  </div>

InteractiveTable.propTypes =
  etds: PropTypes.instanceOf(Immutable.Map).isRequired
  populationsSection: PropTypes.instanceOf(Immutable.Map).isRequired
  tableData: PropTypes.instanceOf(Immutable.Map).isRequired
  recIdsToBeFetchedFromStore: PropTypes.arrayOf(PropTypes.string).isRequired

module.exports = ConnectStore InteractiveTable, [
  EtdsStore
  NMAListStore
  NMAQuestionStore
], storeConnector
