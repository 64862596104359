QuestionGroupWithIndividualRatings =
  require 'components/scope/outcomes/question_group_with_individual_ratings'
IndividualRatingsTable = require 'components/scope/individual_ratings_table'
Modal = require 'components/common/modal'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

IndividualRatingsModal = createReactClass
  displayName: 'IndividualRatingsModal'

  propTypes:
    isOpen: PropTypes.bool.isRequired
    onClose: PropTypes.func.isRequired
    membersMap: PropTypes.object.isRequired

  mixins: [
    Translation('scope:outcomes')
    CustomRenderMixin
  ]

  _questionGroupsMapper: (qGroup, idx) ->
    <QuestionGroupWithIndividualRatings
      data={qGroup}
      gIndex={idx}
      membersMap={@props.membersMap}
      key={idx}
    />

  render: ->
    <Modal
      isOpen={@props.isOpen}
      closeButton
      onClose={@props.onClose}
      title={@i18n 'individual_ratings_title'}
      className='individual-ratings-modal'
    >
      {if @props.questionGroups
        <div className='questions-container'>
          {@props.questionGroups.map(@_questionGroupsMapper).toList()}
        </div>
      else
        <IndividualRatingsTable
          questions={@props.questions}
          membersMap={@props.membersMap}
        />
      }
      <div className='buttons'>
        <button className='btn' onClick={@props.onClose}>{@i18n 'ok'}</button>
      </div>
    </Modal>

module.exports = IndividualRatingsModal
