var DbDoc, MDA_TOPIC_PREFIX, mdaTopicDocShape,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

DbDoc = require('lib/db_docs/db_doc');

mdaTopicDocShape = require('lib/db_docs/doc_shapes/mda_topic_doc_shape');

MDA_TOPIC_PREFIX = require('lib/doc_prefixes').mdaTopic;

module.exports = DbDoc(mdaTopicDocShape, {
  dbSetup: {
    idPrefix: MDA_TOPIC_PREFIX
  },
  methods: {
    assignMember: function(memberType, member) {
      return this.setAt('members', memberType)(member);
    },
    addTables: function(tablesIds) {
      return this.updateAt('insertedTables')(function(insertedTables) {
        return _.chain(insertedTables != null ? insertedTables : []).union(tablesIds).uniq().value();
      });
    },
    removeTables: function(tableIds) {
      return this.updateAt('insertedTables')(function(insertedTables) {
        return _.reject(insertedTables != null ? insertedTables : [], function(tableId) {
          return __indexOf.call(tableIds, tableId) >= 0;
        });
      });
    }
  }
});
