{ useState, useEffect } = React
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
DbHelper = require 'base/lib/db_helper'
FormsApiHelper = require('base/lib/forms_api_helper').coi
Modal = require 'components/common/modal'
QuestionsActions = require 'actions/questions_actions'
QuestionsStore = require 'stores/questions_store'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'
{ groupByKeepOrder } = require 'base/lib/utils'

storeConnector =
  QuestionsStore: (Store) ->
    isLoading: Store.isFetching()
    questions: Store.getQuestions()

MDGAnswers = ({ i18n, answers }) ->
  <div>
  {_.map answers, (answersGroup) ->
    { title } = _.first(answersGroup)
    <div className="mb-10">
      <b>{title}</b>
      {_.map answersGroup, (answer) ->
        { inputType, value, consent, client, description } = answer
        <div>
          {switch inputType
            when 'textarea', 'incomeType'
              <div>{value}</div>
            when 'checkbox'
              if consent then i18n 'yes' else i18n 'no'
            when 'honorariaType', 'businessType'
              <div>
                <b>{client}</b>: {description}
              </div>
            else
              <div>{value}</div>
          }
        </div>
      }
    </div>
  }
  </div>

Conflicts = ({ member, i18n }) ->
  [loading, setLoading] = useState(false)
  [answers, setAnswers] = useState(null)

  useEffect ->
    setLoading true
    FormsApiHelper.getFormDoc member.get('coiFormId')
    .then (response) ->
      # Preparing conflict entries for MDG only, for other form types check coi_input_view.coffee
      conflictEntries = _.map response.conflictEntries, (c) ->
       _.extend {},
        _.pick(c, 'value', 'consent', 'client', 'description')
        _.pick(c.type, 'name', 'inputType', 'multi')
        title: $.t("team:coi.mdg_form.#{_.string.underscored c.type.name}")
      grouppedAnswers = groupByKeepOrder conflictEntries, 'name'
      setAnswers grouppedAnswers
      setLoading false
    () -> setAnswers null
  , [setLoading]

  <div>
    {if loading then <Spinner /> else <MDGAnswers i18n={i18n} answers={answers} />}
  </div>

QuestionsList = ({ i18n, questions, onCheck, memberId }) ->
  <div className="questions-with-conflicts">
    <div className="questions">
      <table className="standard-table">
        <thead>
          <tr>
            <th>{i18n "question"}</th>
            <th className="conflict-column">{i18n "conflict"}</th>
          </tr>
        </thead>
        <tbody>
          {questions.map (question) ->
            <tr key={question.get('_id')}>
              <td>{question.get('question')}</td>
              <td className="conflict-column">
                <input
                  onChange={onCheck(question.get('_id'))}
                  type="checkbox"
                  checked={question.getIn(['coi', memberId], false)}
                />
              </td>
            </tr>
          .toList()
          }
        </tbody>
      </table>
    </div>
  </div>

CoiInput = createReactClass
  displayName: "CoiInput"

  mixins: [Translation('team:coi')]

  propTypes:
    projectId: PropTypes.string.isRequired
    member: PropTypes.instanceOf(Immutable.Map).isRequired
    organizationId: PropTypes.string.isRequired
    onClose: PropTypes.func.isRequired
    coiFormType: PropTypes.string.isRequired

  getFormUrl: ->
    { member, coiFormType } = @props

    formUrl = "#{DbHelper.getFormsAppUrl()}#"
    switch coiFormType
      when 'imcje', 'icmje' then formUrl += 'coi-preview'
      else formUrl += "#{coiFormType}-coi-preview"
    formUrl += "/#{member.get('coiFormId')}/sections"
    formUrl

  onCheck: (questionId) -> (e) =>
    QuestionsActions.updateQuestion {
      questionId,
      questionData:
        coi:
          "#{@props.member.get('_id')}": e.target.checked
    }

  render: ->
    { questions, isLoading, member } = @props

    <Modal
      isOpen
      closeButton={true}
      onClose={@props.onClose}
      title={@i18n 'coi'}
    >
      {isLoading and <Spinner />}
      <div className="flex flex-row">
        <div className="coi-questions-list">
          {not isLoading and not _.isEmpty(questions) and <QuestionsList
            i18n={@i18n}
            questions={questions}
            onCheck={@onCheck}
            memberId={member.get('_id')}
            />}
        </div>
        <div className="coi-answers">
          <Conflicts i18n={@i18n} member={member} />
        </div>
      </div>
      <div class="text-center mt-10 mb-10">
        <a href={@getFormUrl()} target="_blank" class="link">{@i18n "preview_coi_form"}</a>
      </div>
      <ApplyCancelButtons
        applyLabel={@i18n '/actions.save'}
        onApply={@props.onClose}
        onCancel={@props.onClose}
      />
    </Modal>

module.exports = ConnectStore CoiInput, QuestionsStore, storeConnector
