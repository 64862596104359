ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ProjectNameInput = require 'components/projects/project_name_input'
Translation = require 'components/mixins/translation'
W = require 'when'
mediator = require 'mediator'
{ bool, func, string } = PropTypes

StartNewProjectModal = createReactClass
  displayName: 'StartNewProjectModal'

  propTypes:
    creating: bool.isRequired
    onStart: func.isRequired
    onCancel: func.isRequired
    savingError: string

  mixins: [Translation('projects:new_project_dialog')]

  getInitialState: ->
    projectName: ''
    selectedLicenseType: null

  onStart: ->
    @props.onStart
      name: _.string.escapeHTML @state.projectName
      # TODO: uncomment below line when license descriptions are ready
      # license: @state.selectedLicenseType

  isCreateEnabled: ->
    # TODO add `and @state.selectedLicenseType` when license descriptions are ready
    not _.isEmpty @state.projectName

  onChangeName: (e) ->
    @setState projectName: e.target.value

  onLicenseOptionSelect: (value) ->
    @setState selectedLicenseType: value

  render: ->
    <div className='fields-container'>
      {if @props.savingError
        <div className='error-message'>{@props.savingError}</div>
      }
      <div className='field'>
        <ProjectNameInput onChange={@onChangeName} projectName={@state.projectName} />
      </div>
      {### TODO: uncomment below line when license descriptions are ready
        <div className='field'>
          <ProjectLicenceSelect
            onLicenseOptionSelect={@onLicenseOptionSelect}
            selectedLicenseType={@props.selectedLicenseType}
          />
        </div>
      ###}
      <ApplyCancelButtons
        isSubmitEnabled={@isCreateEnabled()}
        applyLabel={@i18n 'create'}
        onApply={@onStart}
        onCancel={@props.onCancel}
        applying={@props.creating}
      />
    </div>

module.exports = StartNewProjectModal
