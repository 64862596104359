Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ExportModal = require 'components/overarching_questions/export_modal'
MDGTableStore = require 'stores/mdg_table_store'
ToolbarQuestionsList = require 'components/evidence_syntheses/toolbar_questions_list'
Translation = require 'components/mixins/translation'

storeConnector =
  MDGTableStore: (Store) ->
    currentQuestion: Store.getCurrentQuestion()

RecommendationsToolbar = createReactClass

  displayName: "RecommendationsToolbar"

  mixins: [Translation(''), CustomRenderMixin]

  propTypes:
    currentQuestion: PropTypes.instanceOf(Immutable.Map).isRequired

  getInitialState: ->
    isExportModalOpen: false

  render: ->
    { currentQuestion } = @props
    { isExportModalOpen } = @state

    <div className="mdg-table-toolbar">
      <div className="mdg-table-toolbar__questions">
        <ToolbarQuestionsList
          currentQuestion={currentQuestion.get('question', '')}
          questionsCollectionType="regular"
        />
      </div>
      <div className="button-export-container mr-10">
        <Button
          className='export'
          title={@i18n 'actions.export'}
          onClick={() => @setState isExportModalOpen: true}
          disabled={isExportModalOpen}
        />
      </div>
      {isExportModalOpen and <ExportModal
        currentQuestion={currentQuestion}
        onClose={() => @setState isExportModalOpen: false}
      />}
    </div>

module.exports = ConnectStore(
  RecommendationsToolbar,
  [ MDGTableStore ],
  storeConnector
)
