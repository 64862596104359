var EtdVotingActions, alt, mediator;

alt = require('alt');

mediator = require('mediator');

EtdVotingActions = (function() {
  function EtdVotingActions() {
    this.generateActions('fetchSuccess', 'fetchError', 'setActiveVotingTab');
  }

  EtdVotingActions.prototype.fetch = function(params) {
    var docId, pouchAdapter, projectId;
    this.dispatch(params);
    projectId = mediator.project.id;
    docId = "" + projectId + ":etd-voting";
    pouchAdapter = mediator.services.storePersistenceAdapter;
    return pouchAdapter.fetch(projectId, docId).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  return EtdVotingActions;

})();

module.exports = alt.createActions(EtdVotingActions);
