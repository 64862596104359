DecisionAidsActions = require 'actions/decision_aids_actions'
IconButton = require 'components/common/icon_button'
OutcomeEditModal = require 'components/decision_aid/outcome_edit_modal'
Translation = require 'components/mixins/translation'
ModalVisibility = require 'components/mixins/modal_visibility'


OutcomeName = createReactClass

  displayName: "OutcomeName"

  propTypes:
    daId: PropTypes.string.isRequired
    outcome: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [
    Translation('es:outcome'),
    ModalVisibility # provides @showModal, @hideModal, @isModalVisible methods
  ]

  saveOutcome: (outcomeData, propagateAbsDenominatorChange = false) ->
    { daId, outcome } = @props
    outcomeId = outcome.get('_id')
    DecisionAidsActions.updateOutcome { daId, outcomeId, outcomeData,
      propagateAbsDenominatorChange }
    @hideModal 'OutcomeEditModal'

  render: ->
    { daId, outcome } = @props
    <div className="outcome-row__outcome-name">
      {outcome.get('name')}
      <IconButton
        iconName='edit'
        onClick={=> @showModal 'OutcomeEditModal'}
        inlined={false}
      />

      {@isModalVisible('OutcomeEditModal') and
        <OutcomeEditModal
          daId={daId}
          initialOutcomeName={outcome.get('name')}
          initialAbsDenominator={outcome.get('absDenominator')}
          onSave={@saveOutcome}
          onClose={=> @hideModal 'OutcomeEditModal'}
        />
      }
    </div>

module.exports = OutcomeName
