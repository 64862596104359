ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
{ Button } = ReactComponents
mediator = require 'mediator'
{ getMemberFullName } = require 'lib/members_helper'
Modal = require 'components/common/modal'
SelectCustom = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'

MoveProjectToOrganizationModal = createReactClass
  displayName: "MoveProjectToOrganizationModal"

  mixins: [Translation()]

  propTypes:
    status: PropTypes.instanceOf(Immutable.Map)
    isOnline: PropTypes.bool.isRequired,
    onMove: PropTypes.func.isRequired
    onRequestClose: PropTypes.func.isRequired

  getInitialState: ->
    selectedOrgId: ''

  handleApply: ->
    @props.onMove @props.status.get('projectId'), @state.selectedOrgId

  getOrganizationOptions: ->
    organizations = _(mediator.userOrganizations).reduce (acc, org) ->
      return acc unless org.isAdmin
      acc.concat {value: org.organizationId, text: org.organizationName}
    , []

  setOrganizationId: (orgId) ->
    @setState selectedOrgId: orgId

  render: ->

    moveToOrganizationResponse = @props.status.get('response')

    <Modal
      closeButton={false}
      isOpen={@props.isOpen}
      className="move-project-to-organization"
      modalSize="standard"
      title={@i18n 'actions.move_to_organization'}
    >
      {not _.isEmpty(moveToOrganizationResponse) &&
        <div className="move-project-to-organization__message">
          {moveToOrganizationResponse is 'ok' &&
            <div>
              {@i18n 'messages.project_moved_to_organization'}
            </div>
          }
          {moveToOrganizationResponse is 'err' &&
            <div>
              {@i18n 'messages.invalid_members_when_moving_to_organization'}
              {@props.status.get('invalidProjectMembers').map (member) ->
                <div
                  className="invalid-members__member"
                  key={member.get('email')}
                >
                  {getMemberFullName(member)}
                </div>
              }
            </div>
          }
          <Button onClick={@props.onRequestClose}>{@i18n 'actions.close'}</Button>
        </div>
      }
      {_.isEmpty(moveToOrganizationResponse) &&
        <div>
          <div dangerouslySetInnerHTML={{__html: @i18n 'messages.move_to_organization'}} />
          <SelectCustom
            orderedList={true}
            signPosition="right"
            className="move-project-to-organization__select"
            options={@getOrganizationOptions()}
            selected={@state.selectedOrgId}
            onChange={@setOrganizationId}
          />
          {not @props.isOnline && <div className="warning">
            {@i18n 'messages.not_available_online'}
          </div>}
          <ApplyCancelButtons
            onApply={@handleApply}
            onCancel={@props.onRequestClose}
            applyLabel={@i18n 'actions.move'}
            isSubmitEnabled={not _.isEmpty(@state.selectedOrgId) and @props.isOnline}
          />
        </div>
      }
    </Modal>

module.exports = MoveProjectToOrganizationModal
