var INITIAL_DIALOG_STATE, INITIAL_STATE, PublicationPlatformActions, filterGoodPracticeStatement, generateStore, mediator;

generateStore = require('stores/utils/generate_store');

filterGoodPracticeStatement = require('lib/questions_helper').filterGoodPracticeStatement;

mediator = require('mediator');

PublicationPlatformActions = require('actions/publication_platform_actions');

INITIAL_DIALOG_STATE = Immutable.fromJS({
  isOpen: false,
  mode: 'guideline',
  preview: false,
  republish: false,
  questionId: null,
  isPublishing: false,
  progress: 0,
  maxProgressCount: 0
});

INITIAL_STATE = Immutable.fromJS({
  common: {
    isFetchingQuestions: false,
    isFetchingStatuses: false,
    questions: Immutable.Map(),
    questionGroups: Immutable.Map(),
    statusesDoc: Immutable.Map()
  },
  dialog: INITIAL_DIALOG_STATE,
  table: {
    searchText: '',
    statusFilter: 'all'
  }
});

module.exports = generateStore({
  name: 'PublicationPlatformStore',
  initialState: INITIAL_STATE,
  boundActions: [PublicationPlatformActions],
  methods: {
    onOpenModal: function(_arg) {
      var mode, preview, questionId, republish;
      mode = _arg.mode, questionId = _arg.questionId, preview = _arg.preview, republish = _arg.republish;
      if (preview == null) {
        preview = false;
      }
      if (questionId == null) {
        questionId = null;
      }
      if (republish == null) {
        republish = false;
      }
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['dialog', 'isOpen'], true);
        s.setIn(['dialog', 'mode'], mode);
        s.setIn(['dialog', 'preview'], preview);
        s.setIn(['dialog', 'republish'], republish);
        return s.setIn(['dialog', 'questionId'], questionId);
      }));
    },
    onCloseModal: function() {
      var timeoutFn;
      this.setState(this.state.setIn(['dialog', 'isOpen'], false));
      timeoutFn = (function(_this) {
        return function() {
          return _this.setState(_this.state.set('dialog', INITIAL_DIALOG_STATE));
        };
      })(this);
      return setTimeout(timeoutFn, 200);
    },
    onFetch: function() {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['common', 'isFetchingQuestions'], true);
        return s.setIn(['common', 'isFetchingStatuses'], true);
      }));
    },
    onFetchQuestionsSuccess: function(_arg) {
      var questionGroups, questions;
      questions = _arg.questions, questionGroups = _arg.questionGroups;
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['common', 'isFetchingQuestions'], false);
        s.setIn(['common', 'questions'], Immutable.fromJS(questions));
        return s.setIn(['common', 'questionGroups'], Immutable.fromJS(questionGroups));
      }));
    },
    onFetchQuestionsError: function() {
      return this.setState(this.state.setIn(['common', 'isFetchingQuestions'], false));
    },
    onFetchStatusesSuccess: function(statusesDoc) {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['common', 'isFetchingStatuses'], false);
        return s.setIn(['common', 'statusesDoc'], Immutable.fromJS(statusesDoc));
      }));
    },
    onFetchStatusesError: function() {
      return this.setState(this.state.setIn(['common', 'isFetchingStatuses'], false));
    },
    onDbChange: function(statusesDoc) {
      return this.setState(this.state.setIn(['common', 'statusesDoc'], Immutable.fromJS(statusesDoc)));
    },
    onUpdatePublishGuidelineProgress: function() {
      return this.setState(this.state.updateIn(['dialog', 'progress'], function(currentProgress) {
        return (currentProgress != null ? currentProgress : 0) + 1;
      }));
    },
    onPublishGuideline: function() {
      return this.setState(this.state.setIn(['dialog', 'isPublishing'], true));
    },
    onPublishQuestion: function() {
      return this.setState(this.state.setIn(['dialog', 'isPublishing'], true));
    },
    onPublishSuccess: function() {
      return this.setState(this.state.set('dialog', INITIAL_DIALOG_STATE));
    },
    onPublishError: function() {
      return this.setState(this.state.set('dialog', INITIAL_DIALOG_STATE));
    },
    onSetPublishGuidelineProgressMaxCount: function(count) {
      return this.setState(this.state.setIn(['dialog', 'maxProgressCount'], count));
    },
    onSetSearchText: function(text) {
      return this.setState(this.state.setIn(['table', 'searchText'], text));
    },
    onChangeStatusFilter: function(status) {
      return this.setState(this.state.setIn(['table', 'statusFilter'], status));
    },
    getQuestions: function() {
      return this.state.getIn(['common', 'questions'], Immutable.Map()).filterNot(filterGoodPracticeStatement);
    },
    getQuestionGroups: function() {
      return this.state.getIn(['common', 'questionGroups']);
    },
    getQuestionStatuses: function() {
      return this.state.getIn(['common', 'statusesDoc', 'lastQuestionsPublicationDate'], Immutable.Map());
    },
    getGuidelinePublicationDate: function() {
      return this.state.getIn(['common', 'statusesDoc', 'lastGuidelinePublicationDate']);
    },
    isFetchingQuestions: function() {
      return this.state.getIn(['common', 'isFetchingQuestions']);
    },
    isFetchingStatuses: function() {
      return this.state.getIn(['common', 'isFetchingStatuses']);
    },
    getSearchText: function() {
      return this.state.getIn(['table', 'searchText']);
    },
    getStatusFilter: function() {
      return this.state.getIn(['table', 'statusFilter']);
    },
    getDialogMode: function() {
      return this.state.getIn(['dialog', 'mode']);
    },
    getProgressBarPercentage: function() {
      var maxProgressCount;
      maxProgressCount = this.state.getIn(['dialog', 'maxProgressCount'], 0);
      if (maxProgressCount === 0) {
        return 0;
      }
      return this.state.getIn(['dialog', 'progress']) / maxProgressCount;
    },
    isModalOpened: function() {
      return this.state.getIn(['dialog', 'isOpen']);
    },
    isPreview: function() {
      return this.state.getIn(['dialog', 'preview']);
    },
    isPublishing: function() {
      return this.state.getIn(['dialog', 'isPublishing']);
    },
    isRepublish: function() {
      return this.state.getIn(['dialog', 'republish']);
    },
    getPublishingQuestionId: function() {
      return this.state.getIn(['dialog', 'questionId']);
    }
  }
});
