var formatters, getAbsoluteEffect, getCorrespondingRisk, getRelativeEffect, getSofControl, viewHelperUtils;

viewHelperUtils = require('lib/view_helper_utils');

formatters = require('lib/formatters');

getSofControl = function(outcomeAttrs, controlRisk) {
  var attrs, formatterName, prefix;
  attrs = _.extend({
    _viewTypeName: 'SOF'
  }, outcomeAttrs);
  if (attrs.type === 'cont') {
    return formatters.outcome.control(attrs);
  } else {
    prefix = viewHelperUtils.controlRiskFormatterSuffix(controlRisk).toLowerCase();
    formatterName = prefix ? "" + prefix + "ControlRisk" : 'control';
    return formatters.outcome[formatterName](attrs);
  }
};

getCorrespondingRisk = function(outcomeAttrs, controlRisk) {
  var formatterName;
  if (!controlRisk) {
    return;
  }
  if (!('_viewTypeName' in outcomeAttrs)) {
    outcomeAttrs = _.extend({
      _viewTypeName: 'SOF'
    }, outcomeAttrs);
  }
  if (outcomeAttrs.type === 'cont') {
    return formatters.outcome.correspondingRisk(outcomeAttrs);
  } else {
    formatterName = "correspondingRisk" + (viewHelperUtils.controlRiskFormatterSuffix(controlRisk));
    return formatters.outcome[formatterName](outcomeAttrs);
  }
};

getAbsoluteEffect = function(outcomeAttrs, controlRisk) {
  var formatterName;
  if (!controlRisk) {
    return;
  }
  formatterName = 'absoluteEffect';
  if (outcomeAttrs.type === 'dich' && typeof controlRisk === 'string') {
    formatterName += viewHelperUtils.controlRiskFormatterSuffix(controlRisk);
  }
  return formatters.outcome[formatterName](outcomeAttrs);
};

getRelativeEffect = function(outcomeAttrs) {
  return formatters.outcome.relativeEffect(outcomeAttrs);
};

module.exports = {
  getSofControl: getSofControl,
  getCorrespondingRisk: getCorrespondingRisk,
  getAbsoluteEffect: getAbsoluteEffect,
  getRelativeEffect: getRelativeEffect
};
