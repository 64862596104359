var HtmlExport, QualityIndicatorsEtd, createPrintoutContainer, defaultMargins, exportToFile, mediator, printoutTemplate, removeHTMLNode, _ref, _ref1;

mediator = require('mediator');

_ref = require('lib/export_to_file'), exportToFile = _ref.exportToFile, defaultMargins = _ref.defaultMargins;

_ref1 = require('base/lib/utils'), removeHTMLNode = _ref1.removeHTMLNode, createPrintoutContainer = _ref1.createPrintoutContainer;

HtmlExport = require('lib/html_export');

QualityIndicatorsEtd = require('components/quality_indicators/quality_indicators_etd');

printoutTemplate = require('views/templates/printouts/recommendations_printout_ietd');

_.namespace(module, function() {
  return {
    "export": function(question, outputFormat) {
      var fileName, printoutHTML, printoutType, spliceTag;
      spliceTag = '<div id="page-container">';
      fileName = question.get('question');
      printoutType = 'recommendations-v2';
      printoutHTML = printoutTemplate({
        orientation: 'portrait',
        margins: defaultMargins
      });
      return new HtmlExport({}, printoutType, {
        etdVersion: 'v2',
        renderOptions: {},
        votingStarted: false,
        type: 'qualityIndicators'
      }).exportRecommendations(document, question.get('_id')).then(function(printoutEl) {
        var spliceIdx;
        $(printoutEl).find('td[style="display: none;"],th[style="display: none;"]').remove();
        spliceIdx = printoutHTML.indexOf(spliceTag) + spliceTag.length;
        return [printoutHTML.slice(0, spliceIdx), printoutEl.innerHTML, printoutHTML.slice(spliceIdx)].join('');
      }).then(function(html) {
        return exportToFile(outputFormat, html, {
          fileName: fileName
        });
      })["catch"](function(reason) {
        if (reason !== 'cancelled') {
          return mediator.dialogs.error(reason);
        }
      });
    }
  };
});
