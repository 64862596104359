var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <tr>\n    <td class=\"outcome-name\">\n      <div class=\"cell-value\">"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\n    </td>\n    <td class=\"median\">\n      <div class=\"cell-value centered\">\n        "
    + escapeExpression(((helpers.medianRatingValue || (depth0 && depth0.medianRatingValue) || helperMissing).call(depth0, (depth0 != null ? depth0.medianRating : depth0), {"name":"medianRatingValue","hash":{},"data":data})))
    + "\n      </div>\n    </td>\n    <td class=\"critical\">\n      <div class=\"cell-value centered\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "critical", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </div>\n    </td>\n    <td class=\"important\">\n      <div class=\"cell-value centered\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "important", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </div>\n    </td>\n    <td class=\"not-important\">\n      <div class=\"cell-value centered\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "notImportant", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </td>\n  </tr>\n";
},"2":function(depth0,helpers,partials,data) {
  return "          <span class=\"checked-marker\">&#x25cf;</span>\n";
  },"4":function(depth0,helpers,partials,data) {
  return "         <span class=\"unchecked-marker\">&#x25cb;</span>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.outcomes : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}