var filterOutcomesByIds, getDataForDxTable, getDataForSofShort, getPrevalences, prepareOutcomes;

getDataForSofShort = function(state, selectedOutcomes) {
  var data;
  return data = state.filter(function(v, k) {
    return ['$tableMode', 'comparison', 'intervention', 'outcomes', 'questionType'].indexOf(k) > -1;
  }).update('outcomes', function(outcomes) {
    return prepareOutcomes(outcomes, selectedOutcomes);
  }).mapKeys(function(k) {
    if (k === 'outcomes') {
      return 'includedOutcomes';
    } else {
      return k;
    }
  });
};

getPrevalences = function(question) {
  return [1, 2, 3].reduce(function(prevalences, prevalenceNum) {
    var prevalenceName, prevalenceValue;
    prevalenceName = "prevalence" + prevalenceNum;
    prevalenceValue = Number.parseInt(question.get(prevalenceName));
    if (_.isNaN(prevalenceValue)) {
      return prevalences;
    }
    prevalences.push({
      num: prevalenceNum,
      value: prevalenceValue
    });
    return prevalences;
  }, []);
};

prepareOutcomes = function(outcomes, selectedOutcomes) {
  var CONTROL_ATTRIBUTES;
  CONTROL_ATTRIBUTES = ['calculatedControl', 'lowControl', 'moderateControl', 'highControl'];
  return filterOutcomesByIds(outcomes, selectedOutcomes).map(function(outcome) {
    var enabledRisks;
    enabledRisks = CONTROL_ATTRIBUTES.filter(function(attrName) {
      return outcome.get(attrName);
    });
    return outcome.set('headControl', enabledRisks[0]).set('tailControls', enabledRisks.slice(1)).set('rowspan', enabledRisks.length);
  });
};

getDataForDxTable = function(question, tableName) {
  return question.withMutations(function(question) {
    if (tableName === 'evidence_short') {
      question.set('dxL1Outcomes', question.get('outcomes'));
    }
    return question.set('prevalences', getPrevalences(question));
  });
};

filterOutcomesByIds = function(outcomes, selectedOutcomes) {
  return outcomes.filter(function(outcome) {
    return selectedOutcomes.contains(outcome.get('_id'));
  });
};

module.exports = {
  getDataForSofShort: getDataForSofShort,
  getPrevalences: getPrevalences,
  prepareOutcomes: prepareOutcomes,
  getDataForDxTable: getDataForDxTable,
  filterOutcomesByIds: filterOutcomesByIds
};
