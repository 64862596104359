var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "  <label for=\"effectMeasure\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "estimate_of_the_effect", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n  <div class=\"inline\">\n    <div class=\"select-input-container ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.effectMeasure : depth0), "other", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "effectMeasure", (depth0 != null ? depth0.effectMeasure : depth0), "MD: measure.md, SMD: measure.smd, mean: measure.mean,\n        median: measure.median, other: measure.other", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      <input type=\"text\" name=\"otherMeasure\" value=\""
    + escapeExpression(((helper = (helper = helpers.otherMeasure || (depth0 != null ? depth0.otherMeasure : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"otherMeasure","hash":{},"data":data}) : helper)))
    + "\"\n      ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.effectMeasure : depth0), "other", {"name":"isnt","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n    </div>\n  </div>\n  <label for=\"effectSize\" class=\"middle\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relative_of", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n  <input type=\"text\" name=\"effectSize\" class=\"number\" id=\"effectSize\"\n    value=\""
    + escapeExpression(((helper = (helper = helpers.effectSize || (depth0 != null ? depth0.effectSize : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"effectSize","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"sup\"></div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "other";
  },"4":function(depth0,helpers,partials,data) {
  return "style=\"display: none\"";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}