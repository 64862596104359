var INITIAL_CONFIRMATION_DIALOG_STATE, INITIAL_PUBLICATION_DIALOG_STATE, INITIAL_STATE, MdaWorkflowPublicationActions, generateStore;

generateStore = require('stores/utils/generate_store');

MdaWorkflowPublicationActions = require('actions/mda_workflow_publication_actions');

INITIAL_CONFIRMATION_DIALOG_STATE = Immutable.fromJS({
  isOpen: false,
  isPushingToMaster: false
});

INITIAL_PUBLICATION_DIALOG_STATE = Immutable.fromJS({
  currentTopicName: '',
  isOpen: false,
  isPublishSuccess: false,
  overallProgress: 0,
  overallMaxProgressCount: 0,
  topicMaxProgressCount: 0,
  topicProgress: 0
});

INITIAL_STATE = Immutable.fromJS({
  confirmationDialog: INITIAL_CONFIRMATION_DIALOG_STATE,
  isFetching: false,
  publicationDialog: INITIAL_PUBLICATION_DIALOG_STATE,
  searchText: '',
  selectedTopics: Immutable.List(),
  statusesDoc: Immutable.Map(),
  statusFilter: 'all'
});

module.exports = generateStore({
  name: 'MdaWorkflowPublicationStore',
  initialState: INITIAL_STATE,
  boundActions: [MdaWorkflowPublicationActions],
  methods: {
    onDbChange: function(doc) {
      return this.setState(this.state.set('statusesDoc', Immutable.fromJS(doc)));
    },
    onChangeStatusFilter: function(status) {
      return this.setState(this.state.set('statusFilter', status));
    },
    onFetch: function() {
      return this.setState(this.state.set('isFetching', true));
    },
    onFetchSuccess: function(doc) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetching', false);
        return state.set('statusesDoc', Immutable.fromJS(doc));
      }));
    },
    onFetchError: function() {
      return this.setState(this.state.set('isFetching', false));
    },
    onPushToMaster: function() {
      return this.setState(this.state.setIn(['confirmationDialog', 'isPushingToMaster'], true));
    },
    onSetSearchText: function(searchText) {
      return this.setState(this.state.set('searchText', searchText));
    },
    onToggleConfirmationDialogOpen: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.updateIn(['confirmationDialog', 'isOpen'], function(isOpen) {
          return !isOpen;
        });
        return state.setIn(['confirmationDialog', 'isPushingToMaster'], false);
      }));
    },
    onToggleTopics: function(topicIds) {
      return this.setState(this.state.update('selectedTopics', function(topics) {
        return _.reduce(topicIds, function(acc, topicId) {
          if (topics.includes(topicId)) {
            return acc.filterNot(function(id) {
              return id === topicId;
            });
          } else {
            return acc.push(topicId);
          }
        }, topics);
      }));
    },
    onClosePublicationModal: function() {
      var timeoutFn;
      this.setState(this.state.withMutations(function(state) {
        state.setIn(['publicationDialog', 'isOpen'], false);
        return state.set('selectedTopics', Immutable.List());
      }));
      timeoutFn = (function(_this) {
        return function() {
          return _this.setState(_this.state.set('publicationDialog', INITIAL_PUBLICATION_DIALOG_STATE));
        };
      })(this);
      return setTimeout(timeoutFn, 200);
    },
    onUpdateOverallPublicationProgress: function() {
      return this.setState(this.state.updateIn(['publicationDialog', 'overallProgress'], function(currentProgress) {
        return (currentProgress != null ? currentProgress : 0) + 1;
      }));
    },
    onUpdateTopicPublicationProgress: function() {
      return this.setState(this.state.updateIn(['publicationDialog', 'topicProgress'], function(currentProgress) {
        return (currentProgress != null ? currentProgress : 0) + 1;
      }));
    },
    onPublish: function(topicsCount) {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['publicationDialog', 'isOpen'], true);
        return s.setIn(['publicationDialog', 'overallMaxProgressCount'], topicsCount);
      }));
    },
    onPublishSuccess: function() {
      return this.setState(this.state.setIn(['publicationDialog', 'isPublishSuccess'], true));
    },
    onPublishError: function() {
      return this.onClosePublicationModal();
    },
    onPublishTopic: function(topicName) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['publicationDialog', 'currentTopicName'], topicName);
        return state.setIn(['publicationDialog', 'topicProgress'], 0);
      }));
    },
    onSetPublicationDialogTopicProgressMaxCount: function(count) {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['publicationDialog', 'topicProgress'], 0);
        return state.setIn(['publicationDialog', 'topicMaxProgressCount'], count);
      }));
    },
    getOverallProgressBarPercentage: function() {
      var maxProgressCount;
      maxProgressCount = this.state.getIn(['publicationDialog', 'overallMaxProgressCount'], 0);
      if (maxProgressCount === 0) {
        return 0;
      }
      return this.state.getIn(['publicationDialog', 'overallProgress']) / maxProgressCount;
    },
    getPendingTopics: function() {
      return this.state.getIn(['statusesDoc', 'questionsPending'], Immutable.List());
    },
    getPublicationTopicName: function() {
      return this.state.getIn(['publicationDialog', 'currentTopicName']);
    },
    getSearchText: function() {
      return this.state.get('searchText');
    },
    getSelectedTopics: function() {
      return this.state.get('selectedTopics');
    },
    getStatusFilter: function() {
      return this.state.get('statusFilter');
    },
    getTopicPublicationDates: function() {
      return this.state.getIn(['statusesDoc', 'lastQuestionsPublicationDate'], Immutable.Map());
    },
    getTopicProgressBarPercentage: function() {
      var maxProgressCount;
      maxProgressCount = this.state.getIn(['publicationDialog', 'topicMaxProgressCount'], 0);
      if (maxProgressCount === 0) {
        return 0;
      }
      return this.state.getIn(['publicationDialog', 'topicProgress']) / maxProgressCount;
    },
    isConfirmationDialogOpen: function() {
      return this.state.getIn(['confirmationDialog', 'isOpen']);
    },
    isFetching: function() {
      return this.state.get('isFetching');
    },
    isPublicationDialogOpen: function() {
      return this.state.getIn(['publicationDialog', 'isOpen']);
    },
    isPublishSuccess: function() {
      return this.state.getIn(['publicationDialog', 'isPublishSuccess']);
    },
    isPushingToMaster: function() {
      return this.state.getIn(['confirmationDialog', 'isPushingToMaster']);
    }
  }
});
