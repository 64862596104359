ConnectStore = require 'components/enhancers/connect_store'
ReferencesStore = require 'stores/references_store'
{ vancouverFormat } = require 'lib/references_utils'

storeConnector =
  ReferencesStore: (Store, props) ->
    references: Store.getReferenceByIds props.referencesIds

ReferencesChapterSection = ({ references }) ->
  <div className="epiditor--container">
    <div className="epiditor--pm-editor-root ProseMirror" contentEditable={false}>
      <ol>
        {references.map (reference) ->
          <li
            key={reference.get '_id'}
            dangerouslySetInnerHTML={__html: vancouverFormat(reference.toJS(), true)}
          />
        }
      </ol>
    </div>
  </div>

ReferencesChapterSection.propTypes =
  referencesIds: PropTypes.arrayOf(PropTypes.string).isRequired
  references: PropTypes.instanceOf(Immutable.List).isRequired

module.exports = ConnectStore ReferencesChapterSection, ReferencesStore, storeConnector
