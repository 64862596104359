var EtdV2ManagementRecommendationsExporter, RecommendationsExporter,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

RecommendationsExporter = require('./recommendations_exporter');

module.exports = EtdV2ManagementRecommendationsExporter = (function(_super) {
  __extends(EtdV2ManagementRecommendationsExporter, _super);

  function EtdV2ManagementRecommendationsExporter(_originalQuestion) {
    this.exportHeader = __bind(this.exportHeader, this);
    this._exportResearchPriorities = __bind(this._exportResearchPriorities, this);
    this._exportMonitoringAndEvaluation = __bind(this._exportMonitoringAndEvaluation, this);
    this._exportImplementationConsiderations = __bind(this._exportImplementationConsiderations, this);
    this._exportSubgroupConsiderations = __bind(this._exportSubgroupConsiderations, this);
    this._exportJustification = __bind(this._exportJustification, this);
    this._exportRecommendationText = __bind(this._exportRecommendationText, this);
    this._exportRecommendationType = __bind(this._exportRecommendationType, this);
    EtdV2ManagementRecommendationsExporter.__super__.constructor.call(this, _originalQuestion);
  }

  EtdV2ManagementRecommendationsExporter.prototype._exportProblemSection = function() {
    var priorityCriterion;
    priorityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('problem', 'is_priority'), this._v2standardOptions, this._getNameForOptionLabel(this._originalQuestion['priorityProblemJudgement']), this._originalQuestion['priorityProblemResearchResearchEvidence'], this._originalQuestion['priorityProblemAdditionalConsiderations']);
    return this._makeSection('ProblemSection', this._getTitleForSectionLabel('problem'), priorityCriterion);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportDesirableEffectsSubsection = function() {
    var desirableEffectsCriterion;
    desirableEffectsCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('desirable_undesirable_effects', 'how_substantial_desirable_effects'), this._v2desirableOptions, this._getNameForOptionLabel(this._originalQuestion['desirableEffectsJudgement']), null, this._originalQuestion['desirableEffectsAdditionalConsiderations']);
    return this._makeSection('DesirableEffectsSubsection', this._getTitleForSectionLabel('desirable_undesirable_effects', 'desirable_effects'), desirableEffectsCriterion);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportUndesirableEffectsSubsection = function() {
    var undesirableEffectsCriterion;
    undesirableEffectsCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('desirable_undesirable_effects', 'how_substantial_undesirable_effects'), this._v2undesirableOptions, this._getNameForOptionLabel(this._originalQuestion['undesirableEffectsJudgement']), null, this._originalQuestion['undesirableEffectsAdditionalConsiderations']);
    return this._makeSection('UndesirableEffectsSubsection', this._getTitleForSectionLabel('desirable_undesirable_effects', 'undesirable_effects'), undesirableEffectsCriterion);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportCertaintyOfEvidenceSubsection = function() {
    var certaintyOfEvidenceCriterion;
    certaintyOfEvidenceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'overall_effects_certainty'), this._v2certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['testAccuracyEvidenceCertaintyJudgement']), null, this._originalQuestion['testAccuracyEvidenceCertaintyAdditionalConsiderations']);
    return this._makeSection('CertaintyOfEvidenceSubsection', this._getTitleForSectionLabel('evidence_certainty'), certaintyOfEvidenceCriterion);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportBenefitsAndHarmsSection = function() {
    var researchEvidence, section;
    section = {
      '@type': 'BenefitsAndHarmsSection',
      'name': this._getTitleForSectionLabel('benefits_harms'),
      'subsection': [this._exportDesirableEffectsSubsection(), this._exportUndesirableEffectsSubsection(), this._exportCertaintyOfEvidenceSubsection()]
    };
    researchEvidence = this._originalQuestion['benefitsHarmsResearchEvidence'];
    if ((researchEvidence != null) && researchEvidence !== '') {
      section['researchEvidence'] = researchEvidence;
    }
    return section;
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportValuesSection = function() {
    var outcomesValuesUncertaintyVariabilityCriterion;
    outcomesValuesUncertaintyVariabilityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('values', 'main_outcomes_value_certainty_treatment'), this._uncertaintyOptions, this._getNameForOptionLabel(this._originalQuestion['outcomesValuesCertaintyJudgement']));
    return this._makeSection('ValuesSection', this._getTitleForSectionLabel('values', 'values'), outcomesValuesUncertaintyVariabilityCriterion, this._originalQuestion['outcomesValuesCertaintyEvidence'], this._originalQuestion['outcomesValuesCertaintyAdditionalConsiderations']);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportBalanceOfEffectsSection = function() {
    var effectsBalanceCriterion;
    effectsBalanceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('effects_balance', 'does_effects_balance_favor_intervention'), this._v2balanceOptions, this._getNameForOptionLabel(this._originalQuestion['effectsBalanceJudgement']));
    return this._makeSection('BalanceOfEffectsSection', this._getTitleForSectionLabel('effects_balance', 'effects_balance'), effectsBalanceCriterion, this._originalQuestion['effectsBalanceEffectsEvidence'], this._originalQuestion['effectsBalanceEffectsAdditionalConsiderations']);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportResourcesRequiredSection = function() {
    var resourcesRequirementsCriterion;
    resourcesRequirementsCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('resources', 'resource_requirements_costs'), this._resourcesRequiredOptions, this._getNameForOptionLabel(this._originalQuestion['resourcesRequirementsJudgement']));
    return this._makeSection('ResourcesRequiredSection', this._getTitleForSectionLabel('resources', 'resource_required'), resourcesRequirementsCriterion, this._originalQuestion['resourcesRequirementsEvidence'], this._originalQuestion['resourcesRequirementsAdditionalConsiderations']);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportCertaintyOfEvidenceOfRequiredCostsSection = function() {
    var resourcesRequiredEvidenceCertaintyCriterion;
    resourcesRequiredEvidenceCertaintyCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('resources', 'whats_certainty_of_resource_requirements'), this._v2certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['resourceRequirementsEvidenceCertaintyJudgement']));
    return this._makeSection('CertaintyOfEvidenceOfRequiredCostsSection', this._getTitleForSectionLabel('resources', 'required_costs_evidence_certainty'), resourcesRequiredEvidenceCertaintyCriterion, this._originalQuestion['resourceRequirementsCertaintyEvidence'], this._originalQuestion['resourceRequirementsEvidenceCertaintyAdditionalConsiderations']);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportCostEffectivenessSection = function() {
    var costEffectivenessCriterion;
    costEffectivenessCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('resources', 'does_cost_favor_intervention_or_comparison'), this._v2costEffectivenessOptions, this._getNameForOptionLabel(this._originalQuestion['costEffectivenessJudgement']));
    return this._makeSection('CostEffectivenessSection', this._getTitleForSectionLabel('resources', 'cost_effectiveness'), costEffectivenessCriterion, this._originalQuestion['costEffectivenessEvidence'], this._originalQuestion['costEffectivenessAdditionalConsiderations']);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportEquitySection = function() {
    var inequitiesCriterion;
    inequitiesCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('equity', 'what_would_be_health_equity_impact'), this._v2inequitiesOptions, this._getNameForOptionLabel(this._originalQuestion['healthEquityJudgement']));
    return this._makeSection('EquitySection', this._getTitleForSectionLabel('equity'), inequitiesCriterion, this._originalQuestion['healthEquityEvidence'], this._originalQuestion['healthEquityAdditionalConsiderations']);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportAcceptabilitySection = function() {
    var acceptabilityCriterion;
    acceptabilityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('acceptability', 'is_intervention_acceptable'), this._v2standardOptions, this._getNameForOptionLabel(this._originalQuestion['isTestAcceptableJudgement']));
    return this._makeSection('AcceptabilitySection', this._getTitleForSectionLabel('acceptability'), acceptabilityCriterion, this._originalQuestion['isTestAcceptableEvidence'], this._originalQuestion['isTestAcceptableAdditionalConsiderations']);
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportFeasibilitySection = function() {
    var feasibilityCriterion;
    feasibilityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('feasibility', 'is_intervention_feasible'), this._v2standardOptions, this._getNameForOptionLabel(this._originalQuestion['isTestFeasibleJudgement']));
    return this._makeSection('FeasibilitySection', this._getTitleForSectionLabel('feasibility'), feasibilityCriterion, this._originalQuestion['isTestFeasibleEvidence'], this._originalQuestion['isTestFeasibleAdditionalConsiderations']);
  };

  EtdV2ManagementRecommendationsExporter.prototype.exportSections = function() {
    return [this._exportProblemSection(), this._exportBenefitsAndHarmsSection(), this._exportValuesSection(), this._exportBalanceOfEffectsSection(), this._exportResourcesRequiredSection(), this._exportCertaintyOfEvidenceOfRequiredCostsSection(), this._exportCostEffectivenessSection(), this._exportEquitySection(), this._exportAcceptabilitySection(), this._exportFeasibilitySection()];
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportConsequencesBalance = function() {
    return null;
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportRecommendationType = function() {
    var recommendationType;
    recommendationType = this._originalQuestion['consequencesBalance'];
    if (recommendationType) {
      return $.t("es:recommendations.table.final_recommendations_judgements." + recommendationType);
    } else {
      return '';
    }
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportRecommendationText = function() {
    var _ref;
    return (_ref = this._originalQuestion['recommendationText']) != null ? _ref : '';
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportJustification = function() {
    var _ref;
    return (_ref = this._originalQuestion['recommendationJustification']) != null ? _ref : '';
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportSubgroupConsiderations = function() {
    var _ref;
    return (_ref = this._originalQuestion['subgroupConsiderationsText']) != null ? _ref : '';
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportImplementationConsiderations = function() {
    var _ref;
    return (_ref = this._originalQuestion['implementationConsiderations']) != null ? _ref : '';
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportMonitoringAndEvaluation = function() {
    var _ref;
    return (_ref = this._originalQuestion['monitoringAndEvaluation']) != null ? _ref : '';
  };

  EtdV2ManagementRecommendationsExporter.prototype._exportResearchPriorities = function() {
    var _ref;
    return (_ref = this._originalQuestion['researchPriorities']) != null ? _ref : '';
  };

  EtdV2ManagementRecommendationsExporter.prototype.exportConclusions = function() {
    return {
      'recommendationType': this._exportRecommendationType(),
      'recommendationText': this._exportRecommendationText(),
      'subgroupConsiderations': this._exportSubgroupConsiderations(),
      'implementationConsiderations': this._exportImplementationConsiderations(),
      'monitoringAndEvaluation': this._exportMonitoringAndEvaluation(),
      'researchPriorities': this._exportResearchPriorities(),
      'justification': this._exportJustification()
    };
  };

  EtdV2ManagementRecommendationsExporter.prototype.exportHeader = function() {
    var header;
    header = {};
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerPatientsField'])) {
      header['patients'] = this._originalQuestion['headerPatientsField'];
    }
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerMainOutcomes'])) {
      header['mainOutcomes'] = this._originalQuestion['headerMainOutcomes'];
    }
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerSettingField'])) {
      header['setting'] = this._originalQuestion['headerSettingField'];
    }
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerPerspectiveField'])) {
      header['perspective'] = this._originalQuestion['headerPerspectiveField'];
    }
    if (this._fieldExistsAndIsNotEmpty(this._originalQuestion['headerBackgroundField'])) {
      header['background'] = this._originalQuestion['headerBackgroundField'];
    }
    return header;
  };

  EtdV2ManagementRecommendationsExporter.prototype.getJsonLdType = function() {
    var negatives, positives, typeFromQuestion;
    positives = ['suggest', 'recommend', 'cover', 'cover_with_evidence', 'cover_with_price', 'implement', 'implement_with_evaluation', 'restricted_coverage'];
    negatives = ['suggest_against', 'recommend_against', 'do_not_cover', 'do_not_implement'];
    typeFromQuestion = this._originalQuestion['consequencesBalance'];
    if (__indexOf.call(positives, typeFromQuestion) >= 0) {
      return 'MedicalGuidelineRecommendation';
    } else if (__indexOf.call(negatives, typeFromQuestion) >= 0) {
      return 'MedicalGuidelineContraindication';
    } else {
      return null;
    }
  };

  return EtdV2ManagementRecommendationsExporter;

})(RecommendationsExporter);
