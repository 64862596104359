var Migration, RemoveOutcomeGenerationFeatureSwitch, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = RemoveOutcomeGenerationFeatureSwitch = (function(_super) {
  __extends(RemoveOutcomeGenerationFeatureSwitch, _super);

  function RemoveOutcomeGenerationFeatureSwitch() {
    RemoveOutcomeGenerationFeatureSwitch.__super__.constructor.call(this, false);
  }

  RemoveOutcomeGenerationFeatureSwitch.prototype.up = function(project, colls) {
    var outcomeGenerationSubmodule, scopeModule;
    RemoveOutcomeGenerationFeatureSwitch.__super__.up.apply(this, arguments);
    if (project.get('modules') === '*') {
      return W.resolve();
    }
    scopeModule = _(project.get('modules')).findWhere({
      id: 'scope'
    });
    if (!scopeModule) {
      return W.resolve();
    }
    outcomeGenerationSubmodule = _(scopeModule.submodules).findWhere({
      id: 'outcomes'
    });
    delete outcomeGenerationSubmodule.featureSwitch;
    return project.save();
  };

  return RemoveOutcomeGenerationFeatureSwitch;

})(Migration);
