var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "<div>\n  <label class=\"footnote-scaleFrom footnote-scaleTo\" for=\"possible-scores-range\">\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "range_of_possible_scores", {"name":"i18n","hash":{},"data":data})))
    + "\n  </label>\n  <input id=\"possible-scores-range\" data-property=\"scaleFrom\" name=\"scaleFrom\" type=\"text\"\n    value=\""
    + escapeExpression(((helper = (helper = helpers.scaleFrom || (depth0 != null ? depth0.scaleFrom : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"scaleFrom","hash":{},"data":data}) : helper)))
    + "\">\n  <label class=\"middle\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "to", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n  <input id=\"possible-scores-range\" data-property=\"scaleTo\" name=\"scaleTo\" type=\"text\"\n    value=\""
    + escapeExpression(((helper = (helper = helpers.scaleTo || (depth0 != null ? depth0.scaleTo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"scaleTo","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"sup\"></div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}