var DocSectionsTemplatesActions, DocSectionsTemplatesStore, alt, errorHandler, immutable, mediator;

alt = require('alt');

mediator = require('mediator');

errorHandler = require('lib/app_utils').errorHandler;

immutable = require('stores/utils/immutable');

DocSectionsTemplatesActions = require('actions/doc_sections_templates_actions');

DocSectionsTemplatesStore = (function() {
  function DocSectionsTemplatesStore() {
    this.state = Immutable.fromJS({
      _meta: {
        fetching: false,
        editing: false,
        changed: false
      },
      template: null,
      originalTemplate: null
    });
    this.bindActions(DocSectionsTemplatesActions);
    this.exportPublicMethods({
      getTemplate: this.getTemplate,
      isFetching: this.isFetching,
      getTemplateName: this.getTemplateName,
      getEditingTemplate: this.getEditingTemplate,
      isTemplateChanged: this.isTemplateChanged
    });
  }

  DocSectionsTemplatesStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  DocSectionsTemplatesStore.prototype.onFetchSuccess = function(templateDoc) {
    var immutableTemplateDoc;
    immutableTemplateDoc = Immutable.fromJS(templateDoc);
    return this.setState(this.state.withMutations(function(state) {
      return state.set('template', immutableTemplateDoc).set('originalTemplate', immutableTemplateDoc).setIn(['_meta', 'fetching'], false);
    }));
  };

  DocSectionsTemplatesStore.prototype.onFetchFailure = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    if (err.status !== 404) {
      return errorHandler(err);
    }
  };

  DocSectionsTemplatesStore.prototype.onSaveSuccess = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'editing'], false);
      return state.set(['originalTemplate'], state.get('template'));
    }));
  };

  DocSectionsTemplatesStore.prototype.onSaveFailure = function(err) {
    return errorHandler(err);
  };

  DocSectionsTemplatesStore.prototype.onPrepareTemplate = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      return state.set('template', data).set('originalTemplate', data).setIn(['_meta', 'changed'], false);
    }));
  };

  DocSectionsTemplatesStore.prototype.onEditTemplate = function(data) {
    return this.setState(this.state.setIn(['_meta', 'editing'], 'doc_sections'));
  };

  DocSectionsTemplatesStore.prototype.onRevertChanges = function() {
    return this.setState(this.state.withMutations(function(state) {
      return state.set('template', state.get('originalTemplate')).setIn(['_meta', 'changed'], false);
    }));
  };

  DocSectionsTemplatesStore.prototype.onCancelEdit = function() {
    return this.setState(this.state.withMutations(function(state) {
      return state.setIn(['_meta', 'editing'], null);
    }));
  };

  DocSectionsTemplatesStore.prototype.onToggleSection = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      state.updateIn(['template', 'templateDef', data.blockName], function(sections) {
        var sectionIdx;
        sectionIdx = sections.findIndex(function(sec) {
          return sec.get('id') === data.sectionId;
        });
        return sections.updateIn([sectionIdx, 'checked'], R.not);
      });
      return state.setIn(['_meta', 'changed'], true);
    }));
  };

  DocSectionsTemplatesStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']) || false;
  };

  DocSectionsTemplatesStore.prototype.getTemplate = function() {
    return this.state.get('template');
  };

  DocSectionsTemplatesStore.prototype.getTemplateName = function() {
    var template, templateName;
    template = this.getTemplate();
    templateName = template != null ? template.getIn(['templateDef', 'name']) : void 0;
    if (!templateName) {
      return null;
    }
    return templateName;
  };

  DocSectionsTemplatesStore.prototype.getEditingTemplate = function() {
    return this.state.getIn(['_meta', 'editing']);
  };

  DocSectionsTemplatesStore.prototype.isTemplateChanged = function() {
    return this.state.getIn(['_meta', 'changed']);
  };

  return DocSectionsTemplatesStore;

})();

module.exports = alt.createStore(immutable(DocSectionsTemplatesStore), 'DocSectionsTemplatesStore');
