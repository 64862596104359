var DbHelper, MASTER_PROJECT_ID, MDA_TABLE_DOC_PREFIX, MDA_TOPIC, MdaTableDoc, MdgPublicationApi, PUBLICATION_PLATFORM_PUBLICATION_STATUS_DOC_ID, PublicationStatusDoc, QuestionsListComponentActions, REFERENCE_PREFIX, W, alt, callMethod, convertRowsToArray, errorHandlerWithMsg, errorHandlerWithMsgCurried, fetchIdsForTopics, generateActions, mediator, sequence, _ref, _ref1, _ref2,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

_ref = require('lib/app_utils'), convertRowsToArray = _ref.convertRowsToArray, errorHandlerWithMsg = _ref.errorHandlerWithMsg, errorHandlerWithMsgCurried = _ref.errorHandlerWithMsgCurried;

callMethod = require('base/lib/utils').callMethod;

DbHelper = require('base/lib/db_helper');

generateActions = require('actions/utils/generate_actions');

_ref1 = require('lib/mda_helper'), fetchIdsForTopics = _ref1.fetchIdsForTopics, MASTER_PROJECT_ID = _ref1.MASTER_PROJECT_ID;

MdaTableDoc = require('lib/db_docs/mda_table_doc');

_ref2 = require('lib/doc_prefixes'), MDA_TABLE_DOC_PREFIX = _ref2.mdaTable, REFERENCE_PREFIX = _ref2.reference;

MDA_TOPIC = require('lib/db_docs/doc_types').MDA_TOPIC;

MdgPublicationApi = require('actions/async/mdg_publication_api');

mediator = require('mediator');

PUBLICATION_PLATFORM_PUBLICATION_STATUS_DOC_ID = require('lib/doc_ids').PUBLICATION_PLATFORM_PUBLICATION_STATUS;

PublicationStatusDoc = require('lib/db_docs/publication_platform_status_doc');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

sequence = require('when/sequence');

W = require('when/when');

module.exports = generateActions({
  autoGenerate: ['changeStatusFilter', 'closePublicationModal', 'fetchError', 'fetchSuccess', 'publishError', 'publishSuccess', 'setPublicationDialogTopicProgressMaxCount', 'setSearchText', 'toggleConfirmationDialogOpen', 'toggleTopics', 'updateOverallPublicationProgress', 'updateTopicPublicationProgress'],
  name: 'MdaWorkflowPublicationActions',
  actions: {
    dbChange: function(change, _pending, _lastSeq) {
      if (change.id === PUBLICATION_PLATFORM_PUBLICATION_STATUS_DOC_ID) {
        return this.dispatch(change.doc);
      } else {
        return QuestionsListComponentActions.dbChange(change, _pending, _lastSeq);
      }
    },
    fetch: function(_arg) {
      var dbId;
      dbId = _arg.dbId;
      this.dispatch();
      return W.all([
        QuestionsListComponentActions.fetch({
          questionsCollectionType: MDA_TOPIC,
          questionType: MDA_TOPIC
        }), PublicationStatusDoc.at(dbId).fetch()["catch"](function(err) {
          if (err.status !== 404) {
            throw err;
          }
          return {};
        })
      ]).then((function(_this) {
        return function(_arg1) {
          var publicationStatusDoc, _questionsResult;
          _questionsResult = _arg1[0], publicationStatusDoc = _arg1[1];
          return _this.actions.fetchSuccess(publicationStatusDoc);
        };
      })(this))["catch"](this.actions.fetchError);
    },
    pushToMaster: function(projectId, projectName, topics) {
      var date, lp, mpCentralReplica, mpLocalReplica, topicIds;
      this.dispatch();
      mpCentralReplica = DbHelper.centralReplica(MASTER_PROJECT_ID);
      mpLocalReplica = DbHelper.localReplica(MASTER_PROJECT_ID);
      lp = DbHelper.localReplica(projectId);
      topicIds = _.pluck(topics, '_id');
      date = Date.now();
      return mpCentralReplica.replicate.to(mpLocalReplica).then(function() {
        return mediator.services.storePersistenceAdapter.fetchWithKeyPrefix(projectId, REFERENCE_PREFIX).then(function(_arg) {
          var referenceIds, references, referencesRows;
          referencesRows = _arg.rows;
          references = convertRowsToArray(referencesRows);
          referenceIds = _.pluck(references, '_id');
          return W.all([fetchIdsForTopics(projectId, topics), fetchIdsForTopics(MASTER_PROJECT_ID, topics), MdaTableDoc.at(projectId).fetchMany()]).then(function(_arg1) {
            var docIdsToDeleteFromMaster, docIdsToFetchLocal, docIdsToFetchMaster, masterDocumentIds, mdaTableRows, projectDocumentIds, projectDocumentIdsWithoutTables, _ref3;
            projectDocumentIdsWithoutTables = _arg1[0], masterDocumentIds = _arg1[1], (_ref3 = _arg1[2], mdaTableRows = _ref3.rows);
            projectDocumentIds = _.uniq(_.union([], projectDocumentIdsWithoutTables, _.pluck(mdaTableRows, 'id')));
            docIdsToDeleteFromMaster = _.chain(masterDocumentIds).difference(projectDocumentIds).reject(function(docId) {
              return docId.indexOf(MDA_TABLE_DOC_PREFIX) === 0;
            }).value();
            docIdsToFetchMaster = _.union([], referenceIds, projectDocumentIds, docIdsToDeleteFromMaster);
            docIdsToFetchLocal = _.union([], referenceIds, projectDocumentIds);
            return W.all([
              mpLocalReplica.allDocs({
                keys: docIdsToFetchMaster
              }), lp.allDocs({
                include_docs: true,
                keys: docIdsToFetchLocal
              })
            ]).then(function(_arg2) {
              var docsToDeleteFromMaster, docsToSave, lpResult, mpResult, revsFromMasterProject;
              mpResult = _arg2[0], lpResult = _arg2[1];
              revsFromMasterProject = _.chain(mpResult.rows).filter(function(row) {
                var _ref4;
                return ((_ref4 = row.value) != null ? _ref4.rev : void 0) != null;
              }).map(function(row) {
                return [row.id, row.value.rev];
              }).object().value();
              docsToDeleteFromMaster = _.chain(docIdsToDeleteFromMaster).filter(function(docId) {
                return docId in revsFromMasterProject;
              }).map(function(docId) {
                return {
                  _id: docId,
                  _rev: revsFromMasterProject[docId],
                  _deleted: true
                };
              }).value();
              docsToSave = _.chain(convertRowsToArray(lpResult.rows)).map(function(doc) {
                var _ref4;
                if (doc._id in revsFromMasterProject) {
                  doc._rev = revsFromMasterProject[doc._id];
                } else {
                  delete doc['_rev'];
                }
                if (_ref4 = doc._id, __indexOf.call(topicIds, _ref4) >= 0) {
                  doc.lastUpdateDate = date;
                  doc.lastUpdateProjectName = projectName;
                }
                return doc;
              }).union(docsToDeleteFromMaster).value();
              return mpLocalReplica.bulkDocs(docsToSave);
            });
          });
        }).then(function() {
          return PublicationStatusDoc.at(projectId).updateAsInstance(callMethod('updateGuidelinePublicationDate', date, topicIds));
        }).then(function() {
          return PublicationStatusDoc.at(MASTER_PROJECT_ID).updateAsInstance(callMethod('setQuestionsAsPending', topicIds));
        }).then(function() {
          return mpLocalReplica.replicate.to(mpCentralReplica);
        });
      })["catch"](errorHandlerWithMsgCurried($.t('dbep:mda_publication_screen.error_pushing_to_master')))["finally"]((function(_this) {
        return function() {
          return _this.actions.toggleConfirmationDialogOpen();
        };
      })(this));
    },
    publish: function(topicIds) {
      this.dispatch(topicIds.length);
      return sequence(_.map(topicIds, (function(_this) {
        return function(mdaTopicId) {
          return function() {
            return _this.actions.publishTopic(mdaTopicId).then(function() {
              return _this.actions.updateOverallPublicationProgress();
            });
          };
        };
      })(this))).then((function(_this) {
        return function() {
          return _this.actions.publishSuccess();
        };
      })(this));
    },
    publishTopic: function(mdaTopicId) {
      var projectId, topicName, updateTimestamp, _ref3, _ref4;
      projectId = mediator.project.id;
      updateTimestamp = new Date().getTime();
      topicName = (_ref3 = (_ref4 = alt.stores.QuestionsStore.getQuestion(mdaTopicId)) != null ? _ref4.get('name') : void 0) != null ? _ref3 : '';
      this.dispatch(topicName);
      return mediator.services.documentSectionsExportService["export"]({
        mdaTopicId: mdaTopicId,
        projectId: projectId
      }).then((function(_this) {
        return function(_arg) {
          var chapters, guidelineDoc, guidelinePayload, sections;
          guidelineDoc = _arg[0], chapters = _arg[1], sections = _arg[2];
          _this.actions.setPublicationDialogTopicProgressMaxCount(1 + chapters.length + sections.length);
          guidelinePayload = {
            allChaptersIds: _.pluck(chapters, 'id'),
            allSectionIds: _.pluck(sections, 'id'),
            guideline: guidelineDoc
          };
          return MdgPublicationApi.publishDoc('guideline', projectId, guidelineDoc.id, guidelinePayload).then(function() {
            _this.actions.updateTopicPublicationProgress();
            return sequence(_.map(chapters, function(chapter) {
              return function() {
                return MdgPublicationApi.publishDoc('chapter', projectId, chapter.id, chapter).then(function() {
                  return _this.actions.updateTopicPublicationProgress();
                });
              };
            }));
          }).then(function() {
            return sequence(_.map(sections, function(section) {
              return function() {
                return MdgPublicationApi.publishDoc('section', projectId, section.id, section).then(function() {
                  return _this.actions.updateTopicPublicationProgress();
                });
              };
            }));
          }).then(function() {
            return PublicationStatusDoc.at(mediator.project.id).updateAsInstance(function(docInstance) {
              return docInstance.updateQuestionPublicationDate(updateTimestamp, mdaTopicId).removeQuestionAsPending(mdaTopicId);
            });
          });
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          _this.actions.publishError(err);
          return errorHandlerWithMsg(err, $.t('dbep:mda_publication_screen.publish_error', {
            topicName: topicName
          }));
        };
      })(this));
    }
  }
});
