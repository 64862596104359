Button = require 'components/common/button'
Translation = require 'components/mixins/translation'

ArchiveButton = createReactClass
  displayName: 'ArchiveButton'

  mixins: [Translation()]

  propTypes:
    onClick: PropTypes.func.isRequired
    title: PropTypes.string

  render: ->
    title = @props.title ? @i18n 'actions.archive'
    classes = classNames 'archive', @props.className
    <Button {...@props} className={classes} title={title} />

module.exports = ArchiveButton
