MarkerCell = require 'components/scope/outcomes/approval_outcome_marker_cell'
ApprovalTableOutcome = require 'components/scope/outcomes/approval_outcome'
ApprovalOucomeDropArea = require 'components/scope/outcomes/approval_outcome_drop_area'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ instanceOf, number, bool, func } = PropTypes

OutcomesApprovalTable = createReactClass
  displayName: 'OutcomesApprovalTable'

  propTypes:
    outcomes: instanceOf(Immutable.List).isRequired
    gIndex: number.isRequired
    membersMap: instanceOf(Immutable.OrderedMap)
    readOnly: bool
    showingComments: bool
    onCommentToggle: func

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  _groupOutcomesByImportance: ->
    @props.outcomes.groupBy (o) -> o.get 'importance'

  renderOutcomesTableHeader: (importanceType, outcomesCount) ->
    <thead>
      <tr>
        <th className='outcome-name' colSpan='2'>
          {@i18n importanceType} ({outcomesCount})
        </th>
        <th className='approved'>
          {@i18n '../questions.approval.approved'}
        </th>
        <th className='comments'>
          {@i18n 'comments'}
        </th>
      </tr>
    </thead>

  renderOutcome: (groupType) -> (outcome, idx) =>
    <ApprovalTableOutcome
      key={idx}
      outcome={outcome}
      membersMap={@props.membersMap}
      groupType={groupType}
      gIndex={@props.gIndex}
      showingComments={@props.showingComments}
      todos={@props.todos?.get outcome.get('key'), Immutable.List()}
      readOnly={@props.readOnly}
      onCommentToggle={@props.onCommentToggle}
    />

  renderOutcomes: (importance, outcomes) ->
    importanceGroup = if importance is 'notImportant' then 'excluded' else 'included'
    if outcomes.isEmpty() and not @props.readOnly
      <ApprovalOucomeDropArea importance={importance} gIndex={@props.gIndex} />
    else
      outcomes.map @renderOutcome importanceGroup

  renderIncludedOutcomes: (outcomesByImportance) ->
    criticalOutcomes = outcomesByImportance.get 'critical', Immutable.List()
    importantOutcomes = outcomesByImportance.get 'important', Immutable.List()
    outcomesCount = criticalOutcomes.size + importantOutcomes.size

    <table className='outcomes-approval'>
      {@renderOutcomesTableHeader 'included', outcomesCount}
      {@renderOutcomes 'critical', criticalOutcomes}
      {@renderOutcomes 'important', importantOutcomes}
    </table>

  renderExcludedOutcomes: (outcomes) ->
    <table className='outcomes-approval'>
      {@renderOutcomesTableHeader 'excluded', outcomes.size}
      {@renderOutcomes 'notImportant', outcomes}
    </table>

  render: ->
    byImportance = @_groupOutcomesByImportance()

    <div className='outcomes-approval-container'>
      {@renderIncludedOutcomes byImportance.filterNot (type) -> type is 'notImportant'}
      {@renderExcludedOutcomes byImportance.get 'notImportant', Immutable.List()}
    </div>

module.exports = OutcomesApprovalTable
