var MDA_TABLE_DOC_TYPE, MdaTableList, MdaTableToolbar, MdaTablesView, QuestionsListComponentActions, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

MdaTableList = require('components/mda/tables/mda_table_list');

MdaTableToolbar = require('components/mda/tables/mda_table_toolbar');

MDA_TABLE_DOC_TYPE = require('lib/db_docs/doc_types').MDA_TABLE;

QuestionsListComponentActions = require('actions/questions_list_component_actions');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = MdaTablesView = (function(_super) {
  __extends(MdaTablesView, _super);

  function MdaTablesView() {
    return MdaTablesView.__super__.constructor.apply(this, arguments);
  }

  MdaTablesView.prototype.container = '#page-container';

  MdaTablesView.prototype.id = 'mda-tables';

  MdaTablesView.prototype.autoRender = true;

  MdaTablesView.prototype.initialize = function() {
    MdaTablesView.__super__.initialize.call(this);
    this.subview('toolbar', new ReactToolbarView({
      component: MdaTableToolbar,
      props: {
        projectId: mediator.project.id
      }
    }));
    return this.enable(ReactComponent);
  };

  MdaTablesView.prototype.afterRender = function() {
    MdaTablesView.__super__.afterRender.call(this);
    return this.renderComponent(MdaTableList, QuestionsListComponentActions, null, {
      props: {
        projectId: mediator.project.id
      },
      fetchAndListen: {
        dbId: mediator.project.id
      },
      fetchParams: {
        questionsCollectionType: MDA_TABLE_DOC_TYPE,
        questionType: MDA_TABLE_DOC_TYPE
      }
    });
  };

  MdaTablesView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('QuestionsStore', 'QuestionsListComponentStore', 'QuestionGroupsStore', 'QuestionsStatusesStore', 'OrganizationsStore', 'OverarchingQuestionsStore');
    return MdaTablesView.__super__.dispose.call(this);
  };

  return MdaTablesView;

})(View);
