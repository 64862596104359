var DbHelper, Exceptions, ReferencesActions, USED_REFERENCES_VIEW, USED_REFERENCES_VIEW_NAME, W, alt, createReferenceDoc, getDuplicates, getReferenceIDHash, markDuplicate, markKept, markMerged, mediator, refDocPrefix, sanitizeReference, setMd5Hash, updatedRefSpecToDocsToSave, _ref;

alt = require('alt');

DbHelper = require('base/lib/db_helper');

Exceptions = require('lib/exceptions');

mediator = require('mediator');

W = require('when/when');

_ref = require('lib/references_utils'), createReferenceDoc = _ref.createReferenceDoc, getDuplicates = _ref.getDuplicates, markDuplicate = _ref.markDuplicate, markKept = _ref.markKept, markMerged = _ref.markMerged, setMd5Hash = _ref.setMd5Hash, getReferenceIDHash = _ref.getReferenceIDHash, sanitizeReference = _ref.sanitizeReference;

refDocPrefix = require('lib/doc_prefixes').reference;

updatedRefSpecToDocsToSave = function(refSpec, refId, references) {
  var docsToSave, marked, possibleDuplicates, refDoc;
  if (refId) {
    _.extend(refSpec, {
      'lastUpdated': Date.now()
    });
    refDoc = sanitizeReference(setMd5Hash(references.get(references.findIndex(function(ref) {
      return refId === ref.get('_id');
    })).merge(Immutable.fromJS(refSpec)).toJS()));
  } else {
    refDoc = sanitizeReference(createReferenceDoc(refSpec));
    refId = refDoc._id;
    if (references.some(function(ref) {
      return ref.get('_id') === refId;
    })) {
      return;
    }
  }
  possibleDuplicates = references.filter(function(ref) {
    return ref.get('md5hash') === refDoc.md5hash && ref.get('_id') !== refId;
  });
  return docsToSave = possibleDuplicates.isEmpty() ? [refDoc] : (_.extend(refDoc, {
    status: 'possibleDuplicate'
  }), marked = markDuplicate(possibleDuplicates).toJS(), marked.concat(refDoc));
};

USED_REFERENCES_VIEW_NAME = 'usedReferencesViewV2';

USED_REFERENCES_VIEW = {
  map: function(doc) {
    var findReferences, refIds, _ref1, _ref2, _ref3, _ref4;
    if (doc.type === 'document_sections_section') {
      refIds = [];
      findReferences = function(content) {
        var c, _i, _len, _results;
        if (!content) {
          return;
        }
        _results = [];
        for (_i = 0, _len = content.length; _i < _len; _i++) {
          c = content[_i];
          if (c.type === 'reference') {
            _results.push(refIds.push.apply(refIds, c.attrs.refIds));
          } else if ((c != null ? c.content : void 0) != null) {
            _results.push(findReferences(c.content));
          } else {
            _results.push(void 0);
          }
        }
        return _results;
      };
      findReferences(doc != null ? (_ref1 = doc.content) != null ? (_ref2 = _ref1.doc) != null ? _ref2.content : void 0 : void 0 : void 0);
      if ((refIds != null ? refIds.length : void 0) !== 0) {
        emit(doc._id, {
          _id: doc._id,
          parent: doc.parent,
          title: doc.title,
          refIds: refIds,
          type: doc.type,
          mdaTopicId: doc.mdaTopicId
        });
      }
    }
    if (doc.type === 'document_sections_chapter' && ((_ref3 = doc.sections) != null ? _ref3.length : void 0) !== 0) {
      emit(doc._id, {
        _id: doc._id,
        title: doc.title,
        sections: doc.sections,
        type: doc.type
      });
    }
    if (doc._id.indexOf('mdg_question') === 0 && ((_ref4 = doc.studies) != null ? _ref4.length : void 0) !== 0) {
      emit(doc._id, {
        _id: doc._id,
        question: doc.question,
        studies: doc.studies,
        type: 'mdg_question'
      });
    }
    if (doc._id.indexOf('mda_topic') === 0) {
      return emit(doc._id, {
        _id: doc._id,
        name: doc.name,
        type: 'mda_topic'
      });
    }
  }
};

ReferencesActions = (function() {
  function ReferencesActions() {
    this.generateActions('addNewReference', 'closeDeleteAttachmentModal', 'closeInsertModal', 'closeReferenceUsageModal', 'closeUploadAttachmentModal', 'deleteAttachmentError', 'deleteAttachmentSuccess', 'fetchError', 'fetchSuccess', 'fetchUsedReferencesError', 'fetchUsedReferencesSuccess', 'openDeleteAttachmentModal', 'openInsertModal', 'openReferenceUsageModal', 'openUploadAttachmentModal', 'pouchSaveError', 'resetSearch', 'search', 'setAttachmentFile', 'sortByColumn', 'toggleReferenceEdit', 'toggleReferenceSelection', 'uploadAttachmentError', 'uploadAttachmentSuccess');
  }

  ReferencesActions.prototype.dbChange = function(change, _pending, _lastSeq) {
    return this.dispatch(change);
  };

  ReferencesActions.prototype.fetch = function(projectIdOrOpts) {
    var projectId;
    projectId = _.isObject(projectIdOrOpts) ? projectIdOrOpts.dbId : projectIdOrOpts;
    if (projectId == null) {
      projectId = mediator.project.id;
    }
    mediator.services.storePersistenceAdapter.fetchWithKeyPrefix(projectId, refDocPrefix).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
    return this.dispatch();
  };

  ReferencesActions.prototype.fetchUsedReferences = function() {
    var projectId;
    projectId = mediator.project.id;
    if (!mediator.services.replication.isOnline()) {
      mediator.dialogs.error($.t('references:used_fetch_while_offline'));
      return;
    }
    this.dispatch();
    return mediator.services.storePersistenceAdapter.fetchWithView(projectId, USED_REFERENCES_VIEW_NAME, USED_REFERENCES_VIEW).then(this.actions.fetchUsedReferencesSuccess)["catch"](this.actions.fetchUsedReferencesError);
  };

  ReferencesActions.prototype.saveReference = function(refSpec, refId, references) {
    var docsToSave, projectId, promise;
    projectId = mediator.project.id;
    docsToSave = updatedRefSpecToDocsToSave(refSpec, refId, references);
    promise = docsToSave ? mediator.services.storePersistenceAdapter.bulkDocs(projectId, docsToSave)["catch"](this.actions.pouchSaveError) : W.resolve();
    this.dispatch();
    return promise;
  };

  ReferencesActions.prototype.saveReferences = function(updatedReferences, references) {
    var docsToSave, projectId;
    projectId = mediator.project.id;
    docsToSave = R.chain(function(refSpec) {
      return updatedRefSpecToDocsToSave(refSpec, refSpec['_id'], references);
    }, updatedReferences);
    return mediator.services.storePersistenceAdapter.bulkDocs(projectId, docsToSave)["catch"](this.actions.pouchSaveError);
  };

  ReferencesActions.prototype.removeReference = function(referenceId) {
    var projectId, reference;
    projectId = mediator.project.id;
    reference = alt.stores.ReferencesStore.getReferenceByIds(referenceId).first().set('_deleted', true);
    mediator.services.storePersistenceAdapter.save(projectId, reference.toJS())["catch"](this.actions.pouchSaveError);
    return this.dispatch();
  };

  ReferencesActions.prototype.removeReferences = function(referenceDocs) {
    var markedDeleted, projectId;
    markedDeleted = R.map(R.assoc('_deleted', true), referenceDocs);
    projectId = mediator.project.id;
    mediator.services.storePersistenceAdapter.bulkDocs(projectId, markedDeleted)["catch"](this.actions.pouchSaveError);
    return this.dispatch();
  };

  ReferencesActions.prototype.importReferences = function(refSpecs) {
    var docsToSave, duplicates, duplicatesHashes, existingReferences, existingReferencesIds, markedDuplicates, projectId, refDocs;
    projectId = mediator.project.id;
    existingReferences = alt.stores.ReferencesStore.getReferences();
    existingReferencesIds = existingReferences.map(function(ref) {
      return ref.get('_id');
    });
    refDocs = _.chain(refSpecs).map(function(refSpec) {
      var id, refDoc;
      refDoc = sanitizeReference(createReferenceDoc(refSpec));
      id = "" + refDocPrefix + (getReferenceIDHash(refDoc));
      if (!existingReferencesIds.contains(id)) {
        return _.extend(refDoc, {
          '_id': id
        });
      }
    }).compact().value();
    duplicates = getDuplicates(existingReferences.concat(Immutable.fromJS(refDocs)));
    docsToSave = duplicates.isEmpty() ? refDocs : (markedDuplicates = markDuplicate(duplicates), duplicatesHashes = markedDuplicates.map(function(r) {
      return r.get('md5hash');
    }), _.chain(refDocs).reject(function(r) {
      return duplicatesHashes.contains(r.md5hash);
    }).union(markedDuplicates.toJS()).value());
    return mediator.services.storePersistenceAdapter.bulkDocs(projectId, docsToSave)["catch"](this.actions.pouchSaveError);
  };

  ReferencesActions.prototype.keepDuplicates = function(duplicates) {
    var keptDocs, projectId;
    projectId = mediator.project.id;
    keptDocs = duplicates.map(markKept);
    return mediator.services.storePersistenceAdapter.bulkDocs(projectId, keptDocs.toJS())["catch"](this.actions.pouchSaveError);
  };

  ReferencesActions.prototype.mergeDuplicates = function(duplicates) {
    var mergedDocs, projectId;
    projectId = mediator.project.id;
    mergedDocs = duplicates.map(markMerged);
    return mediator.services.storePersistenceAdapter.bulkDocs(projectId, mergedDocs.toJS())["catch"](this.actions.pouchSaveError);
  };

  ReferencesActions.prototype.uploadAttachment = function(referenceId, file) {
    var err;
    if (mediator.services.replication.isConnected()) {
      this.dispatch();
      return W.promise(function(resolve, reject) {
        var reader;
        reader = new FileReader();
        reader.onload = function(e) {
          return resolve(e.target.result);
        };
        reader.onerror = function(e) {
          return reject(e);
        };
        return reader.readAsArrayBuffer(file);
      }).then((function(_this) {
        return function(fileContent) {
          var params, url;
          url = ("" + (DbHelper.getBackendUrl()) + "/storage/references/") + ("" + mediator.project.id + "/" + referenceId + "?fileName=" + file.name);
          params = {
            xhrFields: {
              withCredentials: true
            },
            processData: false,
            data: fileContent,
            contentType: file.type,
            type: 'PUT'
          };
          return W($.ajax(url, params))["catch"](_this._attachmentsErrorHandler);
        };
      })(this)).then(this.actions.uploadAttachmentSuccess)["catch"](this.actions.uploadAttachmentError);
    } else {
      err = new Exceptions.no_connection;
      return mediator.dialogs.error(err);
    }
  };

  ReferencesActions.prototype.deleteAttachment = function(referenceId) {
    var err, params, url;
    if (mediator.services.replication.isConnected()) {
      this.dispatch();
      url = "" + (DbHelper.getBackendUrl()) + "/storage/references/" + mediator.project.id + "/" + referenceId;
      params = {
        xhrFields: {
          withCredentials: true
        },
        type: 'DELETE'
      };
      return W($.ajax(url, params))["catch"](this._attachmentsErrorHandler).then(this.actions.deleteAttachmentSuccess)["catch"](this.actions.deleteAttachmentError);
    } else {
      err = new Exceptions.no_connection;
      return mediator.dialogs.error(err);
    }
  };

  ReferencesActions.prototype._attachmentsErrorHandler = function(xhr) {
    switch (xhr.status) {
      case 400:
        throw new Exceptions.references.attachments.upload_fail;
        break;
      case 401:
        throw new Exceptions.references.attachments.unauthorized;
        break;
      case 403:
        throw new Exceptions.references.attachments.forbidden;
        break;
      case 404:
        throw new Exceptions.references.attachments.not_found;
        break;
      case 413:
        throw new Exceptions.references.attachments.too_large;
        break;
      default:
        throw new Exceptions.references.attachments.no_connection;
    }
  };

  return ReferencesActions;

})();

module.exports = alt.createActions(ReferencesActions);
