VotingJudgementCell = require 'components/etd/voting_judgement_cell'
VotingCommentsCell = require 'components/etd/voting_comments_cell'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

{ SectionRow, Card, CardContent, CellWithTitle } = ReactComponents

SectionVotingDetailsRow = createReactClass
  displayName: 'SectionVotingDetailsRow'

  propTypes:
    sectionId: PropTypes.string.isRequired
    getPossibleVotingOptions: PropTypes.func.isRequired
    isProposedVoting: PropTypes.bool.isRequired

  mixins: [CustomRenderMixin]

  render: ->
    return <tr /> unless @props.votingResults

    <SectionRow className="voting-details">
      <CellWithTitle className="voting-details__judgement">
        <Card>
          <CardContent>
            <VotingJudgementCell
              draftJudgement={@props.draftJudgement}
              renderMode={@props.renderMode}
              isProposedVoting={@props.isProposedVoting}
              votingResults={@props.votingResults}
              votingOptions={@props.getPossibleVotingOptions()}
              votesExpected={@props.noOfVotingForms}
            />
          </CardContent>
        </Card>
      </CellWithTitle>
      <CellWithTitle colSpan={2} className="voting-details__comments">
        <Card>
          <CardContent>
            <VotingCommentsCell
              renderMode={@props.renderMode}
              contentField='comment'
              currentEditable={@props.currentEditable}
              sectionId={@props.sectionId}
              votingResults={@props.votingResults}
              votingOptions={@props.getPossibleVotingOptions(true)}
              isProposedVoting={@props.isProposedVoting}
              adminComment={@props.adminComment}
              hiddenVotes={@props.hiddenVotes}
            />
          </CardContent>
        </Card>
      </CellWithTitle>
    </SectionRow>

module.exports = SectionVotingDetailsRow
