module.exports = {
  ReducedForRR: {
    name: 'ReducedForRR',
    label: 'reduced_for_RR',
    score: 1
  },
  IncreasedForRR: {
    name: 'IncreasedForRR',
    label: 'increased_for_RR',
    score: 1
  },
  VeryLarge: {
    name: 'VeryLarge',
    label: 'very_strong_association',
    score: 2
  },
  Large: {
    name: 'Large',
    label: 'strong_association',
    score: 1
  },
  None: {
    name: 'None',
    label: 'not_serious',
    score: 0
  },
  Yes: {
    name: 'Yes',
    label: 'yes',
    score: 1
  },
  No: {
    name: 'No',
    label: 'no',
    score: 0
  },
  Serious: {
    name: 'Serious',
    label: 'serious',
    score: -1
  },
  VerySerious: {
    name: 'VerySerious',
    label: 'very_serious',
    score: -2
  },
  ExtremelySerious: {
    name: 'ExtremelySerious',
    label: 'extremely_serious',
    score: -3
  },
  get: function(name) {
    return this[name];
  }
};
