AddQuestionMixin = require 'components/mixins/scope_add_question_mixin'
AddSendButtons = require 'components/scope/questions/add_send_buttons'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
dndSpec = require 'components/scope/questions/question_dnd_spec'
DragDropTypes = require 'components/scope/questions/drag_drop_types'
EditableQuestion = require 'components/scope/questions/question_editable'
EditableQuestionsList = require 'components/scope/questions/questions_list_editable'
EditableQuestionWithComments = require 'components/scope/questions/question_editable_with_comments'
Question = require 'components/scope/questions/question'
{ getDragWrapper } = require 'lib/react_dnd'
QuestionDrag = getDragWrapper DragDropTypes.QUESTION, dndSpec
QuestionsList = require 'components/scope/questions/questions_list'
QuestionsShowCommentsMixin = require 'components/mixins/questions_show_comments_mixin'
QuestionWithComments = require 'components/scope/questions/question_with_comments'
Translation = require 'components/mixins/translation'

{ Button } = ReactComponents
{ license_url } = require 'lib/constants'

ModerationTab = createReactClass
  displayName: 'ModerationTab'

  propTypes:
    questions: PropTypes.instanceOf(Immutable.List).isRequired
    readOnly: PropTypes.bool.isRequired
    questionsLimit: PropTypes.number.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:questions')
    QuestionsShowCommentsMixin
    AddQuestionMixin
  ]

  getCommentsProps: ->
    membersMap: @props.membersMap
    showingAllComments: @state.showingAllComments
    onCommentToggle: @onItemCommentToggle
    phase: 'questions.brainstorming'

  render: ->
    commentsProps = @getCommentsProps()
    <div className='questions-moderation'>
      <div className='information'>
        {@i18n 'moderation.header'}
      </div>
      {@props.questionsLimit isnt -1 and <div
        className='gdt-limits-content-box'
        dangerouslySetInnerHTML={{
          __html: @i18n 'questions_limits_info',
            limit: @props.questionsLimit,
            link: license_url
        }}
      />}
      <div className='buttons'>
        <Button
          className='show-all-comments'
          onClick={@showComments}
          disabled={@_isShowCommentsDisabled()}
        >
          {@i18n 'show_all_comments'}
        </Button>
      </div>
      <div className='questions'>
        {if @props.readOnly
          <QuestionsList {...commentsProps} questions={@props.questions} readOnly />
        else
          <EditableQuestionsList {...commentsProps}
            ref={(el) => @questionsList = el}
            questions={@props.questions}
            addingQuestion={@props.addingQuestion}
            editingQuestion={@props.editingQuestion}
            onApplyAdminComment={@props.onApplyAdminComment}
            readOnly={@props.readOnly}
          />
        }
      </div>
      {unless @props.readOnly
        <AddSendButtons
          onAddTxQuestion={@addQuestion('management')}
          onAddDxQuestion={@addQuestion('diagnostic')}
          onAddFreeQuestion={@addQuestion('freeform')}
          onCloseStep={@props.onCloseStep}
          closingStep={@props.isClosingStep}
        />
      }
    </div>

module.exports = ModerationTab
