ConnectStore = require 'components/enhancers/connect_store'
LimitsActions = require 'actions/limits_actions'
LimitsStore = require 'stores/limits_store'
mediator = require 'mediator'

storeConnector =
  LimitsStore: (Store) ->
    limits: Store.getLimits(mediator.activeWorkspace, mediator.project?.id)

withLimits = (Component) ->
  WL = createReactClass

    displayName: "WithLimits"

    propTypes:
      limits: PropTypes.instanceOf(Immutable.Map)

    getDefaultProps: ->
      limits: Immutable.Map()

    componentDidMount: ->
      return unless @props.limits.isEmpty()
      _.defer -> LimitsActions.fetchLimits mediator.activeWorkspace, mediator.project?.id

    render: ->
      <Component {...@props} />

  ConnectStore WL, [LimitsStore], storeConnector

module.exports = withLimits
