var ProjectTask, UpdateProjectToLatestDataVersion, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ProjectTask = require('lib/project_tasks/task');

mediator = require('mediator');

module.exports = UpdateProjectToLatestDataVersion = (function(_super) {
  __extends(UpdateProjectToLatestDataVersion, _super);

  function UpdateProjectToLatestDataVersion() {
    UpdateProjectToLatestDataVersion.__super__.constructor.call(this, 'update_project_to_latest_data_version');
  }

  UpdateProjectToLatestDataVersion.prototype.unsafeRun = function(projectId) {
    return mediator.projects.getOrFetch(projectId).then(function(project) {
      var currentDataVersion, dataVersion, versionFromMetadoc;
      currentDataVersion = mediator.services.projectMigration.getCurrentDataVersion();
      versionFromMetadoc = project.get('$data_version');
      dataVersion = versionFromMetadoc === -1 ? currentDataVersion : versionFromMetadoc;
      return project.save('$data_version', dataVersion);
    }).then(function() {
      return {
        message: "Data version updated to latest in project " + projectId
      };
    });
  };

  return UpdateProjectToLatestDataVersion;

})(ProjectTask);
