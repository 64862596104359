{element, func, bool} = PropTypes

ItemWithCheckbox = createReactClass

  displayName: "ItemWithCheckbox"

  propTypes:
    onChange: func.isRequired
    children: element.isRequired
    checked: bool
    readonly: bool

  getDefaultProps: ->
    readonly: false
    checked: false

  onChange: (e) ->
    e.stopPropagation()
    @props.onChange(e)

  render: ->
    className = classNames 'item-with-checkbox', readOnly: @props.readonly , @props.className
    inputProps = if @props.readonly
      readOnly: true, checked: @props.checked
    else
      onChange: @onChange, checked: @props.checked

    <div onClick={@onChange} className={className}>
      <div className="item-with-checkbox__checkbox">
        <input type="checkbox" {...inputProps} />
      </div>
      <div className="item-with-checkbox__item">
        {@props.children}
      </div>
    </div>

module.exports = ItemWithCheckbox
