var ETD_PARTS, alt, etdHelper, exportDataOptions, getAppendicesList, getDenormalizedEtdVotingData, getEtdVotingMetadata, getExportOptions, getQuestionEtdId, getQuestionEtdSnapshots, getQuestionLastVotingResults, getQuestionVotingResults, getQuestionVotingResultsByTimestamp, getQuestionVotingStages, getReminderEmailBody, getRoundsTimestamps, getSentPartList, getTestRoundsTimestamps, mediator, prepareOptionsByParts, rawContentToHTML, _getEtdSectionsForPart, _getRoundMetadata, _questionVotingResultsBySection,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

mediator = require('mediator');

etdHelper = require('lib/etd_helper');

ETD_PARTS = require('lib/voting_helper').ETD_PARTS;

alt = require('alt');

rawContentToHTML = require('lib/draft_utils').rawContentToHTML;

exportDataOptions = {
  assessment: {
    checked: false,
    disabled: true,
    labelKey: 'assessment',
    judgment: {
      checked: false,
      disabled: false
    },
    results: {
      checked: false,
      disabled: true
    }
  },
  recommendation: {
    checked: false,
    disabled: true,
    labelKey: 'typeOfRecommendation',
    judgment: {
      checked: false,
      disabled: false
    },
    results: {
      checked: false,
      disabled: true
    }
  },
  conclusion: {
    checked: false,
    disabled: true,
    labelKey: 'conclusion',
    judgment: {
      checked: false,
      disabled: false
    },
    results: {
      checked: false,
      disabled: true
    }
  }
};

getQuestionEtdId = function(state, questionId) {
  var _ref;
  return (_ref = state.getIn(['panelVoiceMetadata', 'questionEtdsMapping', questionId])) != null ? _ref.last() : void 0;
};

getEtdVotingMetadata = function(state, etdId) {
  return state.getIn(['panelVoiceMetadata', 'etds', etdId]);
};

_getRoundMetadata = function(state, roundTimestamp) {
  return state.getIn(['panelVoiceMetadata', 'rounds', "" + roundTimestamp]);
};

getQuestionVotingStages = function(state, questionId) {
  var metadata;
  metadata = getEtdVotingMetadata(state, getQuestionEtdId(state, questionId));
  if (metadata) {
    return metadata.get('stages').map(function(stage) {
      var lastRoundData, lastRoundTimestamp, roundTimestamps;
      roundTimestamps = stage.get('roundTimestamps');
      lastRoundTimestamp = roundTimestamps.last();
      lastRoundData = _getRoundMetadata(state, lastRoundTimestamp);
      return Immutable.fromJS({
        status: stage.get('status'),
        members: lastRoundData.get('members'),
        parts: lastRoundData.get('parts'),
        lastRoundTimestamp: lastRoundTimestamp,
        roundTimestamps: roundTimestamps,
        dueDateTimestamp: lastRoundData.get('dueDateTimestamp')
      });
    });
  } else {
    return Immutable.List();
  }
};

getQuestionVotingResults = function(state, questionId) {
  var etdId, projectId, resultsPrefix;
  etdId = getQuestionEtdId(state, questionId);
  if (etdId) {
    projectId = mediator.project.id;
    resultsPrefix = "" + projectId + ":results:" + etdId;
    return state.get('votingResults').filter(function(result) {
      return _.str.startsWith(result.get('_id'), resultsPrefix);
    });
  } else {
    return null;
  }
};

getQuestionLastVotingResults = function(state, questionId, includeTestStage) {
  var etdId, lastNonTestStage, lastRoundTimestamp, lastStageStatus, metadata, questionVotingResults, results, _ref;
  if (includeTestStage == null) {
    includeTestStage = false;
  }
  etdId = getQuestionEtdId(state, questionId);
  metadata = getEtdVotingMetadata(state, etdId);
  if (!metadata) {
    return null;
  }
  lastStageStatus = metadata.get('stages').last().get('status');
  lastRoundTimestamp = lastStageStatus !== 'test' || (lastStageStatus === 'test' && includeTestStage) ? (_ref = metadata.get('stages').last()) != null ? _ref.get('roundTimestamps').last() : void 0 : (lastNonTestStage = metadata.get('stages').pop().last(), lastNonTestStage != null ? lastNonTestStage.get('roundTimestamps').last() : void 0);
  if (!lastRoundTimestamp) {
    lastRoundTimestamp = -1;
  }
  questionVotingResults = getQuestionVotingResults(state, questionId);
  if (!questionVotingResults) {
    return null;
  }
  results = questionVotingResults.reduce(function(acc, result) {
    if (result.get('roundsResults').has("" + lastRoundTimestamp)) {
      return acc.push(Immutable.Map({
        etdId: result.get('etdId'),
        memberId: result.get('memberId'),
        results: result.getIn(['roundsResults', "" + lastRoundTimestamp]),
        roundTimestamp: lastRoundTimestamp
      }));
    } else {
      return acc;
    }
  }, new Immutable.List());
  return Immutable.Map({
    questionId: questionId,
    etdId: etdId,
    results: results
  });
};

getQuestionEtdSnapshots = function(state, questionId) {
  var etdId;
  etdId = getQuestionEtdId(state, questionId);
  return state.getIn(['etdsSnapshots', etdId]);
};

getReminderEmailBody = function() {
  var defaultMessage, message, projectName, storedEmailBody;
  projectName = mediator.project.get('name');
  defaultMessage = $.t('voting:voting.messages.default_vote_message_text', {
    projectName: projectName
  });
  storedEmailBody = alt.stores.PanelVoiceStore.getPanelVoicePreferences().get('emailBody');
  return message = storedEmailBody ? rawContentToHTML(storedEmailBody) : defaultMessage;
};

_getEtdSectionsForPart = function(partName, etd) {
  var recommendationSectionData, recommendationSectionName;
  switch (partName) {
    case 'assessment':
      return etd.getIn(['templateData', partName, 'sections']);
    case 'recommendation':
      recommendationSectionName = etdHelper.getRecommendationTypeSectionName(etd);
      recommendationSectionData = etd.getIn(['templateData', 'conclusions', 'sections', recommendationSectionName]);
      return Immutable.Map([[recommendationSectionName, recommendationSectionData]]);
    case 'conclusion':
      recommendationSectionName = etdHelper.getRecommendationTypeSectionName(etd);
      return etd.getIn(['templateData', 'conclusions', 'sections']).filterNot(function(_, sectionName) {
        return sectionName === recommendationSectionName && __indexOf.call(etdHelper.getRecommendationTypeSections(), sectionName) >= 0;
      });
  }
};

_questionVotingResultsBySection = function(questionVotingResults, partName, partSections, timestamp) {
  return questionVotingResults.reduce(function(accResults, result) {
    var memberId, roundResult;
    roundResult = result.getIn(['roundsResults', "" + timestamp]);
    if (!roundResult) {
      return accResults;
    }
    memberId = result.get('memberId');
    return accResults.mergeWith(function(accSection, resultSection) {
      var resultSectionExtended;
      resultSectionExtended = resultSection.withMutations(function(section) {
        section.set('memberId', memberId);
        return section.set('status', roundResult.get('status'));
      });
      return accSection.push(resultSectionExtended);
    }, roundResult.getIn(['parts', partName, 'sections']));
  }, partSections);
};

getQuestionVotingResultsByTimestamp = function(state, questionId) {
  var etdSnapshots, metadata, questionVotingResults;
  etdSnapshots = getQuestionEtdSnapshots(state, questionId);
  questionVotingResults = getQuestionVotingResults(state, questionId);
  metadata = getEtdVotingMetadata(state, getQuestionEtdId(state, questionId));
  if (!(metadata && questionVotingResults && etdSnapshots)) {
    return Immutable.Map();
  }
  return metadata.get('stages').reduce(function(timestampsMap, stage) {
    var lastTimestamp, stageStatus, stageTimestamps;
    stageStatus = stage.get('status');
    stageTimestamps = stage.get('roundTimestamps');
    lastTimestamp = stageTimestamps.last();
    return stageTimestamps.reduce(function(acc, timestamp) {
      var etdSnapshot, isTestRound, membersSentTo, roundData, votedParts;
      etdSnapshot = etdSnapshots.get("" + timestamp);
      if (!etdSnapshot) {
        return acc;
      }
      roundData = _getRoundMetadata(state, timestamp);
      votedParts = roundData.get('parts');
      membersSentTo = roundData.get('members');
      isTestRound = roundData.get('isTest', false);
      return acc.set("" + timestamp, votedParts.reduce(function(partsMap, partData) {
        var partName, partSectionsVoted, votingOnProposedOptions, votingStatus;
        partName = partData.get('name');
        votingOnProposedOptions = partData.get('preselectedJudgement');
        partSectionsVoted = _getEtdSectionsForPart(partName, etdSnapshot).map(function() {
          return Immutable.List();
        });
        votingStatus = isTestRound ? 'test' : timestamp === lastTimestamp ? stageStatus : 'closed';
        return partsMap.set(partName, Immutable.Map({
          membersSentTo: membersSentTo,
          timestamp: timestamp,
          votingOnProposedOptions: votingOnProposedOptions,
          status: votingStatus,
          sections: _questionVotingResultsBySection(questionVotingResults, partName, partSectionsVoted, timestamp)
        }));
      }, Immutable.Map()));
    }, timestampsMap);
  }, Immutable.Map());
};

getDenormalizedEtdVotingData = function(state, questionId) {
  var etdId, etdStages, roundsMetadata, stages;
  etdId = getQuestionEtdId(state, questionId);
  etdStages = state.getIn(['panelVoiceMetadata', 'etds', etdId, 'stages'], Immutable.Map());
  if (etdStages == null) {
    return;
  }
  roundsMetadata = state.getIn(['panelVoiceMetadata', 'rounds']);
  stages = etdStages.map(function(stage) {
    return Immutable.Map({
      status: stage.get('status'),
      rounds: stage.get('roundTimestamps').reduce(function(rounds, roundTimestamp) {
        return rounds.set("" + roundTimestamp, roundsMetadata.get("" + roundTimestamp));
      }, Immutable.OrderedMap())
    });
  });
  return Immutable.Map({
    stages: stages
  });
};

getAppendicesList = function(questionId) {
  var EtdStore, PanelVoiceEtdStore, PanelVoiceStore, activeRound, activeStage, activeTab, allRounds, contentSections, etdId, etdSnapshots, etdState, etdTemplateData, hasAssessment, panelVoiceState, parts, votingStages, _ref;
  _ref = alt.stores, EtdStore = _ref.EtdStore, PanelVoiceEtdStore = _ref.PanelVoiceEtdStore, PanelVoiceStore = _ref.PanelVoiceStore;
  etdState = PanelVoiceEtdStore.getState();
  panelVoiceState = PanelVoiceStore.getState();
  etdId = getQuestionEtdId(panelVoiceState, questionId);
  etdSnapshots = panelVoiceState.getIn(['etdsSnapshots', etdId], new Immutable.Map());
  activeRound = etdState.getIn(['meta', 'activeRound']);
  activeStage = etdState.getIn(['meta', 'activeStage']);
  activeTab = etdState.getIn(['meta', 'activeTab']);
  if (etdSnapshots.isEmpty()) {
    return [];
  }
  votingStages = PanelVoiceStore.getDenormalizedEtdVotingData(questionId).get('stages');
  if (activeTab === 'history') {
    activeRound = activeStage === 'test' ? activeRound != null ? activeRound : _.last(getTestRoundsTimestamps(votingStages)) : activeRound;
    if (activeRound === 'draft') {
      activeRound = _.first(getRoundsTimestamps(votingStages));
    }
    allRounds = votingStages.reduce(function(acc, stage) {
      return acc.merge(stage.get('rounds'));
    }, Immutable.Map());
    parts = allRounds.getIn([activeRound.toString(), 'parts']);
    hasAssessment = parts.find(function(part) {
      return part.get('name') === 'assessment';
    });
    if (!hasAssessment) {
      return [];
    }
  } else {
    activeRound = null;
    activeStage = null;
  }
  etdTemplateData = !activeRound && !activeStage ? EtdStore.getState().getIn(['template', 'templateData']) : etdSnapshots.getIn([activeRound.toString(), 'templateData']);
  contentSections = etdTemplateData.getIn(['assessment', 'researchEvidences']);
  return etdHelper.getAppendicesListFromContentSections(contentSections);
};

getRoundsTimestamps = function(votingStages) {
  return votingStages.reduce(function(acc, stage) {
    var rounds;
    rounds = stage.get('rounds', new Immutable.Map());
    if (rounds.isEmpty()) {
      return acc;
    }
    return rounds.reduce(function(roundsAcc, roundData, timestamp) {
      return roundsAcc.concat(timestamp);
    }, []);
  }, []);
};

getTestRoundsTimestamps = function(votingStages) {
  return votingStages.reduce(function(acc, stage) {
    var testRounds;
    testRounds = stage.get('rounds').filter(function(round) {
      return round.get('isTest');
    });
    if (testRounds.isEmpty()) {
      return acc;
    }
    return testRounds.reduce(function(roundsAcc, roundData, timestamp) {
      return roundsAcc.concat(timestamp);
    }, []);
  }, []);
};

getSentPartList = function(stages) {
  return stages.reduce(function(acc, stage) {
    var partNames;
    partNames = stage.get('parts').reduce(function(partsAcc, part) {
      return partsAcc.push(part.get('name'));
    }, new Immutable.List());
    return acc.concat(partNames);
  }, new Immutable.List());
};

prepareOptionsByParts = function(parts, activeRound) {
  var panelVoiceExportOptions;
  panelVoiceExportOptions = JSON.parse(JSON.stringify(exportDataOptions));
  _(ETD_PARTS).each(function(partName) {
    var stageHasPart;
    stageHasPart = parts.find(function(part) {
      return part.get('name') === partName;
    });
    if (stageHasPart) {
      panelVoiceExportOptions[partName].disabled = false;
      if (activeRound !== 'draft') {
        return panelVoiceExportOptions[partName].results.disabled = false;
      }
    }
  });
  return panelVoiceExportOptions;
};

getExportOptions = function(questionId) {
  var PanelVoiceEtdStore, PanelVoiceStore, activeRound, activeStage, activeTab, panelVoiceExportOptions, parts, votingStages, _ref;
  _ref = alt.stores, PanelVoiceStore = _ref.PanelVoiceStore, PanelVoiceEtdStore = _ref.PanelVoiceEtdStore;
  votingStages = PanelVoiceStore.getQuestionVotingStages(questionId);
  activeTab = PanelVoiceEtdStore.getActiveTab();
  activeRound = activeTab === 'history' ? PanelVoiceEtdStore.getActiveRound() : null;
  activeStage = activeTab === 'history' ? PanelVoiceEtdStore.getActiveStage() : null;
  if (activeStage === 'test') {
    votingStages = PanelVoiceStore.getDenormalizedEtdVotingData(this.model.get('_id')).get('stages');
    if (!activeRound) {
      activeRound = _.last(getTestRoundsTimestamps(votingStages));
    }
    parts = votingStages.first().getIn(['rounds', activeRound.toString(), 'parts']);
    return prepareOptionsByParts(parts, activeRound);
  } else if (activeRound && activeStage && activeTab === 'history') {
    parts = votingStages.getIn([activeStage.toString(), 'parts']);
    return prepareOptionsByParts(parts, activeRound);
  } else {
    panelVoiceExportOptions = JSON.parse(JSON.stringify(exportDataOptions));
    _(ETD_PARTS).each(function(part) {
      return panelVoiceExportOptions[part].disabled = false;
    });
    _(getSentPartList(votingStages).toArray()).each(function(part) {
      return panelVoiceExportOptions[part].results.disabled = false;
    });
    return panelVoiceExportOptions;
  }
};

module.exports = {
  exportDataOptions: exportDataOptions,
  getAppendicesList: getAppendicesList,
  getQuestionEtdId: getQuestionEtdId,
  getEtdVotingMetadata: getEtdVotingMetadata,
  getQuestionVotingStages: getQuestionVotingStages,
  getQuestionVotingResults: getQuestionVotingResults,
  getQuestionLastVotingResults: getQuestionLastVotingResults,
  getQuestionEtdSnapshots: getQuestionEtdSnapshots,
  getQuestionVotingResultsByTimestamp: getQuestionVotingResultsByTimestamp,
  getDenormalizedEtdVotingData: getDenormalizedEtdVotingData,
  getReminderEmailBody: getReminderEmailBody,
  getTestRoundsTimestamps: getTestRoundsTimestamps,
  getExportOptions: getExportOptions,
  prepareOptionsByParts: prepareOptionsByParts,
  getSentPartList: getSentPartList
};
