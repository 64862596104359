{ number, oneOfType, string } = PropTypes

GdtNavBarItem = createReactClass
  displayName: 'GdtNavBarItem'

  render: ->
    <div className='gdt-navigation-bar__item'>
      {@props.children}
    </div>

GdtNavBar = createReactClass
  displayName: 'GdtNavBar'

  propTypes:
    children: (props, propName, componentName) ->
      children = props[propName]
      invalidChildType = _.some children, (child) -> child.type isnt GdtNavBarItem
      if invalidChildType
        new Error "Invalid prop `children` supplied to `GdtNavBar`. All children must be of
          `GdtNavBarItem` type"

  render: ->
    <div className='gdt-navigation-bar'>
      {@props.children}
    </div>

module.exports = {
  GdtNavBar
  GdtNavBarItem
}
