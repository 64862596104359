var CreateAdolopmentEtd, DbHelper, ProjectTask, QUESTIONS_DB_VIEW, W, adolopmentDataService, comparisonQuestionGroupsDocId, generateGUID, mediator, mergeEtdsBasedOnAdolopmentData, projectsHelper, resetProperty,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ProjectTask = require('lib/project_tasks/task');

DbHelper = require('base/lib/db_helper');

projectsHelper = require('actions/async/projects');

comparisonQuestionGroupsDocId = require('lib/doc_ids').COMPARISONS_QUESTION_GROUPS;

QUESTIONS_DB_VIEW = require('lib/questions_helper').QUESTIONS_DB_VIEW;

adolopmentDataService = require('lib/services/adolopment_data_service');

mergeEtdsBasedOnAdolopmentData = require('lib/adolopments_helper').mergeEtdsBasedOnAdolopmentData;

generateGUID = require('base/lib/utils').generateGUID;

mediator = require('mediator');

W = require('when');

resetProperty = function(obj, propToReset, newValue) {
  var property, _results;
  _results = [];
  for (property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (property === propToReset) {
        _results.push(obj[property] = newValue);
      } else if (typeof obj[property] === "object") {
        _results.push(resetProperty(obj[property], propToReset, newValue));
      } else {
        _results.push(void 0);
      }
    } else {
      _results.push(void 0);
    }
  }
  return _results;
};

module.exports = CreateAdolopmentEtd = (function(_super) {
  __extends(CreateAdolopmentEtd, _super);

  function CreateAdolopmentEtd() {
    CreateAdolopmentEtd.__super__.constructor.call(this, 'create_adolopment_etd');
  }

  CreateAdolopmentEtd.prototype.unsafeRun = function(projectId, _arg) {
    var comparisonQuestionGroupsPromise, projectDb, selectedQuestions;
    selectedQuestions = _arg.selectedQuestions;
    projectDb = DbHelper.localReplica(projectId);
    comparisonQuestionGroupsPromise = projectDb.get(comparisonQuestionGroupsDocId)["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
      return {
        groups: [],
        mapping: {},
        questionsOrdering: {}
      };
    });
    return W.all([projectDb.get(projectId), projectDb.query(QUESTIONS_DB_VIEW), comparisonQuestionGroupsPromise, adolopmentDataService.fetch(projectId)]).then(function(_arg1) {
      var adolopmentData, allEtdIds, groups, groupsDoc, iteration, mapping, metadoc, questionRows, questions, questionsOrdering, _ref;
      metadoc = _arg1[0], (_ref = _arg1[1], questionRows = _ref.rows), groupsDoc = _arg1[2], adolopmentData = _arg1[3];
      questions = _.chain(questionRows).pluck('value').reject(function(q) {
        return _.isEmpty(q.recommendationIds);
      }).filter(function(q) {
        return selectedQuestions.contains(q._id);
      }).value();
      allEtdIds = _(questions).chain().reduce(function(acc, q) {
        return acc.concat(q['recommendationIds'], q['recommendationAdolopmentIds']);
      }, []).compact().uniq().value();
      questionsOrdering = _.pick(groupsDoc.questionsOrdering || {}, selectedQuestions.toJS());
      mapping = _.pick(groupsDoc.mapping || {}, selectedQuestions.toJS());
      groups = _(groupsDoc.groups || []).filter(function(group) {
        return _(mapping).chain().values().contains(group._id).value();
      });
      groupsDoc = _.extend(groupsDoc, {
        questionsOrdering: questionsOrdering,
        mapping: mapping,
        groups: groups
      });
      iteration = parseInt(metadoc.adolopmentIteration || 0) + 1;
      metadoc.adolopmentIteration = iteration;
      return mediator.services.storePersistenceAdapter.fetch(projectId, allEtdIds).then(function(_arg2) {
        var adolopedQuestions, etdRows, etds, originalEtds;
        etdRows = _arg2.rows;
        etds = _.chain(etdRows).compact().pluck('doc').value();
        originalEtds = _(etds).filter(function(etd) {
          return etd._id.indexOf(':adoloped') === -1;
        }).map(function(etd) {
          var adolopedSections, originalEtd, prevVersion;
          adolopedSections = (adolopmentData != null ? adolopmentData.etdsData[etd._id] : void 0) || {};
          originalEtd = _.omit(JSON.parse(JSON.stringify(etd)), ['_rev']);
          originalEtd._id = "" + etd._id + ":adoloped_" + iteration;
          if (iteration === 1) {
            return originalEtd;
          }
          prevVersion = _(etds).find(function(e) {
            return e._id === ("" + etd._id + ":adoloped_" + (iteration - 1));
          });
          if (prevVersion) {
            return mergeEtdsBasedOnAdolopmentData(prevVersion, originalEtd, adolopedSections);
          } else {
            return originalEtd;
          }
        });
        etds = _(etds).map(function(etd) {
          var tmpQuestion;
          tmpQuestion = JSON.parse(JSON.stringify(etd.templateData.question));
          resetProperty(etd, 'selectedOption', '');
          resetProperty(etd, 'content', '');
          etd.templateData.question = tmpQuestion;
          return etd;
        });
        adolopedQuestions = _(questions).map(function(question) {
          var adolopedQuestion;
          adolopedQuestion = _.omit(JSON.parse(JSON.stringify(question)), ['_rev']);
          adolopedQuestion._id = "" + question._id + ":adoloped_" + iteration;
          return adolopedQuestion;
        });
        questions = _(questions).map(function(q) {
          var recommendationAdolopmentIds, _ref1;
          recommendationAdolopmentIds = ((_ref1 = q.recommendationAdolopmentIds) != null ? _ref1 : []).concat("" + q.recommendationIds[0] + ":adoloped_" + iteration);
          return _.extend({}, q, {
            recommendationAdolopmentIds: recommendationAdolopmentIds
          });
        });
        if (!_.isEmpty(adolopmentData)) {
          adolopmentData = _(adolopmentData).extend({}, {
            etdsData: {}
          });
        }
        return _.union([metadoc], adolopedQuestions, adolopmentData, etds, groupsDoc, originalEtds, questions);
      });
    }).then(function(docs) {
      return projectDb.bulkDocs(docs);
    }).then(function() {
      return {
        message: "Create adolopment etd removed from project " + projectId
      };
    });
  };

  return CreateAdolopmentEtd;

})(ProjectTask);
