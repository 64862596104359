var INITIAL_STATE, ORDoc, OverReviewActions, OverReviewStore, alt, callMethod, errorHandler, generateGUID, immIso, immLensPath, immutable, lensPath, lensWhere, mapped, mediator, mergeNewData, overReviewDataPath, overReviewDocPrefix, removeWhere, whereId, _ref, _ref1, _ref2;

alt = require('alt');

mediator = require('mediator');

immutable = require('stores/utils/immutable');

errorHandler = require('lib/app_utils').errorHandler;

_ref = require('base/lib/utils'), callMethod = _ref.callMethod, generateGUID = _ref.generateGUID;

OverReviewActions = require('actions/over_review_actions');

ORDoc = require('lib/db_docs/over_review_doc');

_ref1 = require('lib/lens_utils'), lensPath = _ref1.lensPath, whereId = _ref1.whereId, immLensPath = _ref1.immLensPath, immIso = _ref1.immIso, lensWhere = _ref1.lensWhere, mapped = _ref1.mapped;

overReviewDocPrefix = require('lib/doc_prefixes').overReview;

_ref2 = require('lib/db_docs/document_instance_helpers'), removeWhere = _ref2.removeWhere, mergeNewData = _ref2.mergeNewData;

INITIAL_STATE = Immutable.fromJS({
  meta: {
    fetchingOverReviewFor: null,
    editingSourceId: null,
    activeTabs: {}
  },
  overReviewData: {}
});

overReviewDataPath = R.memoizeWith(R.identity, function(questionId) {
  return ['overReviewData', questionId];
});

OverReviewStore = (function() {
  function OverReviewStore() {
    this.state = INITIAL_STATE;
    this.bindActions(OverReviewActions);
    this.exportPublicMethods({
      getOverReviewFor: this.getOverReviewFor,
      isFetchingFor: this.isFetchingFor,
      getEdtingSourceId: this.getEdtingSourceId,
      getStudySourcesCount: this.getStudySourcesCount,
      normalizeSourceData: this.normalizeSourceData,
      getDenormalizedSourceData: this.getDenormalizedSourceData,
      suggestStudies: this.suggestStudies,
      getActiveTab: this.getActiveTab
    });
  }

  OverReviewStore.prototype.onDbChange = function(change) {
    var deleted, doc, id, questionId;
    deleted = change.deleted, id = change.id, doc = change.doc;
    if (!_.str.startsWith(id, overReviewDocPrefix)) {
      return;
    }
    questionId = id.replace(overReviewDocPrefix, '');
    return this.setState(this.state.setIn(['overReviewData', questionId], ORDoc.of(doc)));
  };

  OverReviewStore.prototype.onFetch = function(questionId) {
    return this.setState(this.state.setIn(['meta', 'fetchingOverReviewFor'], questionId));
  };

  OverReviewStore.prototype.onFetchOverReviewSuccess = function(overReviewDoc) {
    return this.setState(this.state.withMutations(function(state) {
      var overReviewDataFor;
      overReviewDataFor = state.getIn(['meta', 'fetchingOverReviewFor']);
      state.setIn(['meta', 'fetchingOverReviewFor'], null);
      return state.setIn(overReviewDataPath(overReviewDataFor), ORDoc.of(overReviewDoc));
    }));
  };

  OverReviewStore.prototype.onFetchOverReviewError = function(err) {
    this.setState(this.state.setIn(['meta', 'fetchingOverReviewFor'], null));
    if (err.status === 404) {
      return;
    }
    return errorHandler(err);
  };

  OverReviewStore.prototype.onCreateReviewedOutcome = function(_arg) {
    var name, questionId;
    questionId = _arg.questionId, name = _arg.name;
    return this.updateQuestionOverReview(questionId, callMethod('createReviewedOutcome', name));
  };

  OverReviewStore.prototype.onDeleteReviewedOutcome = function(_arg) {
    var questionId, reviewedOutcomeId;
    questionId = _arg.questionId, reviewedOutcomeId = _arg.reviewedOutcomeId;
    return this.updateQuestionOverReview(questionId, callMethod('deleteReviewedOutcome', reviewedOutcomeId));
  };

  OverReviewStore.prototype.onUpdateReviewedOutcome = function(_arg) {
    var outcomeData, questionId, reviewedOutcomeId;
    questionId = _arg.questionId, reviewedOutcomeId = _arg.reviewedOutcomeId, outcomeData = _arg.outcomeData;
    return this.updateQuestionOverReview(questionId, callMethod('updateReviewedOutcome', reviewedOutcomeId, outcomeData));
  };

  OverReviewStore.prototype.onCreateSource = function(_arg) {
    var questionId, sourceData, studies;
    questionId = _arg.questionId, sourceData = _arg.sourceData, studies = _arg.studies;
    return this.updateQuestionOverReview(questionId, callMethod('createSource', sourceData, studies));
  };

  OverReviewStore.prototype.onDeleteSoure = function(_arg) {
    var questionId, sourceId;
    questionId = _arg.questionId, sourceId = _arg.sourceId;
    return this.updateQuestionOverReview(questionId, callMethod('deleteSource', sourceId));
  };

  OverReviewStore.prototype.onUpdateSource = function(_arg) {
    var questionId, sourceData, sourceId, studies;
    questionId = _arg.questionId, sourceId = _arg.sourceId, sourceData = _arg.sourceData, studies = _arg.studies;
    return this.updateQuestionOverReview(questionId, callMethod('updateSource', sourceId, sourceData, studies));
  };

  OverReviewStore.prototype.onStartSourceEdit = function(sourceId) {
    return this.setState(this.state.setIn(['meta', 'editingSourceId'], sourceId));
  };

  OverReviewStore.prototype.onCancelSourceEdit = function() {
    return this.setState(this.state.setIn(['meta', 'editingSourceId'], null));
  };

  OverReviewStore.prototype.onSaveImportedSources = function(_arg) {
    var questionId, sources, studies;
    questionId = _arg.questionId, sources = _arg.sources, studies = _arg.studies;
    return this.updateQuestionOverReview(questionId, callMethod('createSource', sources, studies));
  };

  OverReviewStore.prototype.onChangeTab = function(_arg) {
    var questionId, tabName;
    questionId = _arg.questionId, tabName = _arg.tabName;
    return this.setState(this.state.setIn(['meta', 'activeTabs', questionId], tabName));
  };

  OverReviewStore.prototype.onMapSourceOutcome = function(_arg) {
    var questionId, reviewedOutcomeId, sourceId, sourceOutcomeId;
    questionId = _arg.questionId, reviewedOutcomeId = _arg.reviewedOutcomeId, sourceId = _arg.sourceId, sourceOutcomeId = _arg.sourceOutcomeId;
    return this.updateQuestionOverReview(questionId, callMethod('mapSourceOutcome', reviewedOutcomeId, sourceId, sourceOutcomeId));
  };

  OverReviewStore.prototype.onRateSourceReviewQuality = function(_arg) {
    var questionId, rating, sourceId;
    questionId = _arg.questionId, sourceId = _arg.sourceId, rating = _arg.rating;
    return this.updateQuestionOverReview(questionId, callMethod('updateSourceQualityOfReview', sourceId, rating));
  };

  OverReviewStore.prototype.onUpdateStudiesMatrix = function(_arg) {
    var questionId, sourceId, studyId, studyStatus;
    questionId = _arg.questionId, sourceId = _arg.sourceId, studyId = _arg.studyId, studyStatus = _arg.studyStatus;
    return this.updateQuestionOverReview(questionId, callMethod('updateStudiesMatrix', sourceId, studyId, studyStatus));
  };

  OverReviewStore.prototype.onMergeStudies = function(_arg) {
    var masterStudyText, mergeStudyIds, questionId;
    questionId = _arg.questionId, mergeStudyIds = _arg.mergeStudyIds, masterStudyText = _arg.masterStudyText;
    return this.updateQuestionOverReview(questionId, callMethod('mergeStudies', mergeStudyIds, masterStudyText));
  };

  OverReviewStore.prototype.onUpdateSourceComment = function(_arg) {
    var comment, questionId, sourceId;
    questionId = _arg.questionId, sourceId = _arg.sourceId, comment = _arg.comment;
    return this.updateQuestionOverReview(questionId, callMethod('updateSourceComment', sourceId, comment));
  };

  OverReviewStore.prototype.updateQuestionOverReview = function(questionId, updaterFn) {
    return this.setState(this.state.updateIn(overReviewDataPath(questionId), updaterFn));
  };

  OverReviewStore.prototype.getOverReviewFor = function(questionId) {
    return this.state.getIn(overReviewDataPath(questionId));
  };

  OverReviewStore.prototype.isFetchingFor = function(questionId) {
    var isFetchingFor;
    isFetchingFor = this.state.getIn(['meta', 'fetchingOverReviewFor']);
    return (isFetchingFor != null) && isFetchingFor === questionId;
  };

  OverReviewStore.prototype.getEdtingSourceId = function() {
    return this.state.getIn(['meta', 'editingSourceId']);
  };

  OverReviewStore.prototype.getStudySourcesCount = function(questionId, studyId, sourcesToExclude) {
    if (sourcesToExclude == null) {
      sourcesToExclude = [];
    }
    return R.keys(this.state.getIn(overReviewDataPath(questionId)).getAt('sources', lensWhere(R.both(R.compose(R.includes(studyId), R.prop('studyIds')), R.compose(R.not, R.includes(R.__, sourcesToExclude), R.prop('_id')))))).length;
  };

  OverReviewStore.prototype.normalizeSourceData = function(sourceData) {
    var sourceDataNormalized, studies, studyIds;
    studies = sourceData.studies;
    studyIds = R.view(lensPath('studies', mapped, '_id'), sourceData);
    sourceDataNormalized = R.compose(R.assoc('studyIds', studyIds), R.dissoc('studies'))(sourceData);
    return {
      sourceData: sourceDataNormalized,
      studies: studies
    };
  };

  OverReviewStore.prototype.getDenormalizedSourceData = function(questionId, sourceId) {
    var overReviewData, source, studies;
    overReviewData = this.state.getIn(overReviewDataPath(questionId));
    if (sourceId === '_new') {
      return {
        outcomes: [],
        studies: []
      };
    } else {
      source = overReviewData.getAt('sources', sourceId);
      studies = R.chain(function(studyId) {
        return overReviewData.getAt('studies', whereId(studyId));
      }, source.studyIds);
      return R.compose(R.assoc('studies', studies), R.dissoc('studyIds'))(source);
    }
  };

  OverReviewStore.prototype.suggestStudies = function(questionId, studyText) {
    if (_.isEmpty(studyText)) {
      return [];
    }
    return this.state.getIn(overReviewDataPath(questionId)).getAt('studies', lensWhere(R.compose(R.includes(studyText.toLowerCase()), R.toLower, R.prop('text'))));
  };

  OverReviewStore.prototype.getActiveTab = function(questionId) {
    var overReview;
    overReview = this.getOverReviewFor(questionId);
    if (overReview && overReview.getAt('hasStarted')) {
      return this.state.getIn(['meta', 'activeTabs', questionId], 'sources');
    } else {
      return null;
    }
  };

  return OverReviewStore;

})();

module.exports = alt.createStore(immutable(OverReviewStore), 'OverReviewStore');
