var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "visible";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"search-container\">\n  <div class=\"input-container\">\n    <input type=\"text\" value="
    + escapeExpression(lambda((depth0 != null ? depth0.searchText : depth0), depth0))
    + ">\n    <button class=\"clear-search ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.searchText : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\"></button>\n  </div>\n  <button class=\"search submit\"></button>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}