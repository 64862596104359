var MdaTopic, MdaTopicView, MdaTopicsActions, MdaTopicsToolbar, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

MdaTopic = require('components/mda/topics/mda_topic');

MdaTopicsActions = require('actions/mda_topics_actions');

MdaTopicsToolbar = require('components/mda/topics/toolbar');

mediator = require('mediator');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = MdaTopicView = (function(_super) {
  __extends(MdaTopicView, _super);

  function MdaTopicView() {
    return MdaTopicView.__super__.constructor.apply(this, arguments);
  }

  MdaTopicView.prototype.container = '#page-container';

  MdaTopicView.prototype.id = 'mda-topic';

  MdaTopicView.prototype.autoRender = true;

  MdaTopicView.prototype.initialize = function() {
    MdaTopicView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: MdaTopicsToolbar,
      props: {
        currentTopicId: this.options.topicId,
        projectId: mediator.project.id
      }
    }));
    return this.enable(ReactComponent);
  };

  MdaTopicView.prototype.afterRender = function() {
    MdaTopicView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(MdaTopic, MdaTopicsActions, this.options.topicId, {
      props: {
        topicId: this.options.topicId
      },
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  MdaTopicView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('QuestionsStore', 'QuestionGroupsStore', 'QuestionsListComponentStore', 'QuestionsStatusesStore', 'OrganizationsStore', 'OverarchingQuestionsStore', 'MdaTopicsStore', 'DocumentSectionsV2Store', 'DiscardChangesStore', 'MdaWorkflowDialogStore');
    return MdaTopicView.__super__.dispose.apply(this, arguments);
  };

  return MdaTopicView;

})(View);
