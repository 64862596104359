mediator = require 'mediator'
Tooltip = require 'components/common/tooltip'
{ useI18n } = require 'lib/react_utils'

QuestionIcons = ({ includedInDocumentSections, locked, hasTable }) ->
  i18n = useI18n('es:question')
  isMdgTablesFeatureOn = mediator.services.switches.isOn 'mdgTables'

  tableBtnClasses = classNames "button table-mode mr-5 inline-block", 'inactive': not hasTable
  includedBtnClasses = classNames "button attached-to-ds mr-5 inline-block",
    'inactive': not includedInDocumentSections
  lockedBtnClasses = classNames "button locked mr-5 inline-block", 'inactive': not locked

  <div className="question-icons flex flex-row items-center">
    {isMdgTablesFeatureOn and
      <Tooltip>
        <span
          title={if hasTable
            i18n('evidence_table_available')
          else
            i18n('evidence_table_not_available')}
          className={tableBtnClasses}
        />
      </Tooltip>
    }
    <Tooltip>
      <span
        title={if includedInDocumentSections
          i18n('inserted_into_ds')
        else
          i18n('not_inserted_into_ds')}
        className={includedBtnClasses}
      />
    </Tooltip>
    {locked and
      <Tooltip>
        <span
          title={if locked then i18n('locked') else i18n('unlocked')}
          className={lockedBtnClasses}
        />
      </Tooltip>
    }
  </div>

QuestionIcons.propTypes =
  includedInDocumentSections: PropTypes.bool.isRequired
  locked: PropTypes.bool.isRequired
  hasTable: PropTypes.bool.isRequired

module.exports = QuestionIcons
