var AdaptiveLayout, Outcome, OutcomeView, TreatmentOutcomeView, absoluteDifferenceTemplate, mediator, statisticalUtils, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

OutcomeView = require('views/evidence_syntheses/outcome_view');

Outcome = require('models/outcome');

AdaptiveLayout = require('base/lib/traits/adaptive_layout');

mediator = require('mediator');

utils = require('base/lib/utils');

statisticalUtils = require('lib/statistical_utils');

absoluteDifferenceTemplate = require('views/templates/evidence_syntheses/diagnostic/absolute_difference');

module.exports = TreatmentOutcomeView = (function(_super) {
  __extends(TreatmentOutcomeView, _super);

  function TreatmentOutcomeView() {
    this.editSofOutcomeHidden = __bind(this.editSofOutcomeHidden, this);
    this.designStudiesChanged = __bind(this.designStudiesChanged, this);
    this.fixLayout = __bind(this.fixLayout, this);
    this.controlChange = __bind(this.controlChange, this);
    return TreatmentOutcomeView.__super__.constructor.apply(this, arguments);
  }

  TreatmentOutcomeView.prototype.initialize = function() {
    TreatmentOutcomeView.__super__.initialize.apply(this, arguments);
    this.modelBind('destroy', this.outcomeDeleted);
    this.delegate('click', '.edit-sof-outcome > button.edit', this.editSofOutcome);
    this.delegate('click', '.edit-sof-outcome > button.save', this.hideSofOutcomeEdit);
    this.modelBind('change:designStudies', this.designStudiesChanged);
    this.modelBind('change:calculatedControl\nchange:lowControl\nchange:moderateControl\nchange:highControl', this.controlChange);
    return this.modelBind('change:type', function() {
      if (!this.disposed) {
        return _.defer((function(_this) {
          return function() {
            return _this.parentView.trigger('!fixLayout');
          };
        })(this));
      }
    });
  };

  TreatmentOutcomeView.prototype.controlRiskClasses = function() {
    return ['control', 'low-control', 'moderate-control', 'high-control', 'absolute-effect', 'corresponding-risk', 'risk-with-control', 'risk-difference-with-intervention'];
  };

  TreatmentOutcomeView.prototype.toggleControlRisks = function() {
    var key, toggle, value, _ref, _results;
    toggle = (function(_this) {
      return function(selectors, condition) {
        var selector, _i, _len, _results;
        _results = [];
        for (_i = 0, _len = selectors.length; _i < _len; _i++) {
          selector = selectors[_i];
          if (condition) {
            _this.$(selector).show();
            _this.$(selector).nextUntil('div').show();
            _results.push(_this.$(selector).css('display', ''));
          } else {
            _this.$(selector).hide();
            _results.push(_this.$(selector).nextUntil('div').hide());
          }
        }
        return _results;
      };
    })(this);
    _ref = {
      'calculatedControl': 'main-content',
      'lowControl': 'low-value',
      'moderateControl': 'moderate-value',
      'highControl': 'high-value'
    };
    _results = [];
    for (key in _ref) {
      value = _ref[key];
      _results.push(toggle(this.controlRiskClasses().map(function(cc) {
        return "td." + cc + " .cell-content." + value;
      }), this.model.get(key)));
    }
    return _results;
  };

  TreatmentOutcomeView.prototype.addControlHorizontalSeparators = function() {
    var addSeparators, contentSelector, contentSelectors, hrSelectors, _i, _len, _results;
    hrSelectors = _(this.controlRiskClasses()).map(function(cc) {
      return "td." + cc + " hr";
    }).join(', ');
    this.$(hrSelectors).remove();
    contentSelectors = _(this.controlRiskClasses()).map(function(cc) {
      return "td." + cc + " .cell-content:visible";
    });
    addSeparators = function(contentCells) {
      var contentCell, idx, _i, _len, _results;
      _results = [];
      for (idx = _i = 0, _len = contentCells.length; _i < _len; idx = ++_i) {
        contentCell = contentCells[idx];
        if (idx > 0) {
          _results.push($(contentCell).before('<hr>'));
        } else {
          _results.push(void 0);
        }
      }
      return _results;
    };
    _results = [];
    for (_i = 0, _len = contentSelectors.length; _i < _len; _i++) {
      contentSelector = contentSelectors[_i];
      _results.push(addSeparators(this.$(contentSelector)));
    }
    return _results;
  };

  TreatmentOutcomeView.prototype.fixControlCellsHeight = function() {
    var controlChecksSize;
    controlChecksSize = this.model.getControlChecksSize();
    this.$('td.absolute-effect div.cell-content').height('auto');
    return _.defer((function(_this) {
      return function() {
        var absoluteCell, controlCell, controlHeight, corrRiskHeight, idx, _i, _j, _len, _len1, _ref, _ref1, _results;
        if (controlChecksSize > 1) {
          _ref = _this.$('td.absolute-effect div.cell-content');
          for (idx = _i = 0, _len = _ref.length; _i < _len; idx = ++_i) {
            absoluteCell = _ref[idx];
            _this.$('td.control div.cell-content').eq(idx).height($(absoluteCell).outerHeight());
          }
          _ref1 = _this.$('td.control div.cell-content');
          _results = [];
          for (idx = _j = 0, _len1 = _ref1.length; _j < _len1; idx = ++_j) {
            controlCell = _ref1[idx];
            controlHeight = $(controlCell).outerHeight();
            corrRiskHeight = _this.$('td.corresponding-risk div.cell-content').eq(idx).height();
            if (controlHeight > corrRiskHeight) {
              _results.push(_this.$('td.corresponding-risk div.cell-content').eq(idx).height(controlHeight));
            } else {
              _results.push($(controlCell).height(corrRiskHeight));
            }
          }
          return _results;
        }
      };
    })(this));
  };

  TreatmentOutcomeView.prototype.controlChange = function() {
    var _ref;
    if (this.disposed) {
      return;
    }
    if ((_ref = this.parentView) != null) {
      _ref.renderItemViews(this.model);
    }
    return this.fixLayout();
  };

  TreatmentOutcomeView.prototype.fixLayout = function() {
    if (this.disposed) {
      return;
    }
    if (this.model.get('type') !== 'dich') {
      return;
    }
    this.toggleControlRisks();
    this.addControlHorizontalSeparators();
    this.fixControlCellsHeight();
    return this.parentView.trigger('!fixLayout');
  };

  TreatmentOutcomeView.prototype.designStudiesChanged = function() {
    var _ref, _ref1, _ref2;
    if (this.disposed) {
      return;
    }
    if (this.model.get('type') === 'cont' && ((_ref = (_ref1 = this.model.get('designStudies')) != null ? _ref1.value : void 0) === 'case_control_studies' || _ref === 'case_control_and_other_combined')) {
      this.model.set('type', 'dich');
    }
    return (_ref2 = this.parentView) != null ? _ref2.renderItemViews(this.model) : void 0;
  };

  TreatmentOutcomeView.prototype.getTemplateData = function() {
    var data;
    data = TreatmentOutcomeView.__super__.getTemplateData.apply(this, arguments);
    _(data).extend(_(this.options.questionModel.attributes).pick(['intervention', 'comparison']));
    data.isCaseControl = this.model.isCaseControl();
    data.controlChecks = this.model.getControlChecksSize();
    data.short = this.options.short;
    data.outcomeType = this.model.get('type');
    return data;
  };

  TreatmentOutcomeView.prototype.editSofOutcome = function() {
    var extraItemViews, _ref;
    if ((_ref = this.parentView) != null ? _ref.extraItemSubview(this.model, 'editSofOutcomeRow') : void 0) {
      this.parentView.extraItemSubview(this.model, 'editSofOutcomeRow').showOutcomeEditView();
      this.parentView.extraItemSubview(this.model, 'editSofOutcomeRow').on('hidden', this.editSofOutcomeHidden);
      extraItemViews = this.parentView.getExtraSubviewsByItemCid(this.model.cid);
      _.each(extraItemViews, function(item, index) {
        return item.$el.addClass('editing');
      });
      return this.$el.addClass('editing');
    }
  };

  TreatmentOutcomeView.prototype.hideSofOutcomeEdit = function(e) {
    var _ref;
    return (_ref = this.parentView.extraItemSubview(this.model, 'editSofOutcomeRow')) != null ? _ref.saveAndHideEdit(e) : void 0;
  };

  TreatmentOutcomeView.prototype.editSofOutcomeHidden = function() {
    var extraItemViews;
    extraItemViews = this.parentView.getExtraSubviewsByItemCid(this.model.cid);
    _.each(extraItemViews, function(item, index) {
      return item.$el.removeClass('editing');
    });
    return this.$el.removeClass('editing');
  };

  TreatmentOutcomeView.prototype.outcomeDeleted = function() {
    this.parentView.outcomeDeleted();
    return this.parentView.currentOutcomeEditView = void 0;
  };

  TreatmentOutcomeView.prototype.afterRender = function() {
    TreatmentOutcomeView.__super__.afterRender.apply(this, arguments);
    return this.fixLayout();
  };

  return TreatmentOutcomeView;

})(OutcomeView);
