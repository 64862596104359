var Tabs, ViewTrait,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('base/lib/traits/view_trait');

module.exports = Tabs = (function(_super) {
  __extends(Tabs, _super);

  function Tabs() {
    return Tabs.__super__.constructor.apply(this, arguments);
  }

  Tabs.prototype.tabs = [];

  Tabs.prototype.activeTab = null;

  Tabs.prototype.apply = function(view) {
    Tabs.__super__.apply.apply(this, arguments);
    this._addFunction(view, 'getTemplateData');
    this._addFunction(view, '_activateTab');
    this._addFunction(view, 'dispose');
    this._addFunction(view, 'getActiveTab');
    return view.delegate('click', '.tabspane > .tab', view._activateTab);
  };

  Tabs.prototype._activateTab = function(originalFunction, trait, e) {
    var selectedTab;
    selectedTab = e.currentTarget.dataset.tab;
    if (trait.activeTab === selectedTab) {
      return;
    }
    this.$(".tabspane [data-tab='" + trait.activeTab + "']").removeClass('tab--selected');
    this.$("." + trait.activeTab + "-tab-panel").removeClass('active');
    trait.activeTab = selectedTab;
    this.$(".tabspane [data-tab='" + trait.activeTab + "']").addClass('tab--selected');
    this.$("." + trait.activeTab + "-tab-panel").addClass('active');
    return this.trigger('tabChanged', trait.activeTab);
  };

  Tabs.prototype.getTemplateData = function(originalFunction, trait) {
    var data;
    data = originalFunction.apply(this);
    return _(data).extend({
      tabs: trait.tabs,
      activeTab: trait.activeTab
    });
  };

  Tabs.prototype.getActiveTab = function(originalFunction, trait) {
    return trait.activeTab;
  };

  Tabs.prototype.dispose = function(originalFunction, trait) {
    this.off('tabChanged');
    return originalFunction.apply(this);
  };

  return Tabs;

})(ViewTrait);
