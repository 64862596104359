OutcomesTableViewActions = require 'actions/outcomes_table_view_actions'
EditableCell = require 'components/evidence_syntheses/outcomes/cells/editable_cell'
{ CompoundEdit, CompoundEditBlock } =
  require 'components/evidence_syntheses/outcomes/cells/compound_edit'
ValidatedInput = require 'components/common/validated_input'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ getKeyValObject } = require 'base/lib/utils'


# TODO: DRY violation with intervention_cell

outcomeAttrNames = [ 'controlTotal' ]
updateOutcomeAttribute = _.compose(
  OutcomesTableViewActions.updateEditedOutcomeAttributes
  getKeyValObject
)

ControlCell = createReactClass
  displayName: 'ControlCell'

  propTypes: {
    editMetadata: PropTypes.instanceOf(Immutable.Map)
    allValid: PropTypes.func.isRequired # provided by withValidation enhancer
    validate: PropTypes.func.isRequired # provided by withValidation enhancer
  }

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]

  _ref: (el) ->
    @el = el

  handleInputChange: (evt, validationError) ->
    outcomeAttrName = evt.target.getAttribute 'name'
    value = evt.target.value.trim()

    updateOutcomeAttribute outcomeAttrName, value

  handleApply: ->
    OutcomesTableViewActions.saveEditedAttributes()
    @resetEdit()

  resetEdit: ->
    @el.stopEdit()

  render: ->
    { outcomeAttrs, editMetadata, allValid, validate } = @props
    controlTotalVal = editMetadata?.getIn(['editedAttributes', 'controlTotal']) ?
      @props.outcomeAttrs['controlTotal']

    <EditableCell {...@props} ref={@_ref} title={@i18n 'control'}>
      <CompoundEdit applyEnabled={allValid()} onApply={@handleApply} onCancel={@resetEdit}>
        <CompoundEditBlock label={@i18n 'total'}>
          <ValidatedInput
            name='controlTotal'
            onChange={@handleInputChange}
            type='text'
            validation={validate 'controlTotal'}
            value={controlTotalVal}
          />
        </CompoundEditBlock>
      </CompoundEdit>
    </EditableCell>

module.exports = ControlCell
