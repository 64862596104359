QuestionsList = require 'components/administration/voting/voting_questions_list'
ValidatedQuestionsList =
  require 'components/administration/voting/voting_validated_questions_list'
{ NoQuestionsSelectedModal } = require 'components/administration/voting/voting_modals'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
ModalDialogsMixin = require 'components/mixins/modal_dialogs_mixin'

QuestionsListWithButtons = createReactClass
  displayName: 'QuestionsListWithButtons'

  propTypes:
    projectId: PropTypes.string.isRequired
    questions: PropTypes.object.isRequired

  mixins: [
    CustomRenderMixin
    Translation('voting:voting')
    ModalDialogsMixin
  ]

  getInitialState: ->
    noQuestionsModal: false

  _wrapChildOnclick: (childElement) -> =>
    if @_questionsList.getSelectedQuestionsIds().size
      childElement.props.onClick()
    else
      @showModal('noQuestionsModal')()

  getSelectedQuestionsIds: ->
    @_questionsList.getSelectedQuestionsIds()

  resetSelectedQuestions: ->
    @_questionsList.resetSelectedQuestions()

  render: ->
    <div>
    {if @props.withValidation
      <ValidatedQuestionsList
        ref={(element) => @_questionsList = element}
        phase={@props.phase}
        projectId={@props.projectId}
        questions={@props.questions}
        questionGroups={@props.questionGroups}
        etds={@props.etds}
        selectedContentOption={@props.selectedContentOption}
        selectedEtdPartsOption={@props.selectedEtdPartsOption}
        selectedJudgmentsOption={@props.selectedJudgmentsOption}
      />
    else
      <QuestionsList
        ref={(element) => @_questionsList = element}
        votingInfo='all'
        projectId={@props.projectId}
        questions={@props.questions}
        questionGroups={@props.questionGroups}
      />
    }
      <div className="buttons">
        {###
            wrap all provided buttons' onClick method, so that it is not called unless
            where is any question selected.
         ###
        }
        {React.Children.map @props.children, (child) =>
          React.cloneElement child, onClick: @_wrapChildOnclick child
        }
      </div>
      {if @state.noQuestionsModal
        <NoQuestionsSelectedModal closeModal={@closeModal 'noQuestionsModal'} />
      }
    </div>

module.exports = QuestionsListWithButtons
