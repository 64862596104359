ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
DropdownMenu = require 'components/common/dropdown_menu'
etdHelper = require 'lib/etd_helper'
GdtEditorWithApply = Editor.EditorWithApply
IconButton = require 'components/common/icon_button'
Select = require 'components/common/select_custom'
Spinner = require 'components/common/spinner'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'
{ useRef, useState, useEffect, useCallback, useMemo } = React
{ SelectWithCheckboxes } = ReactComponents
{ isImmutable } = require 'lib/immutable_utils'
{ rawContentToHTML } = require 'lib/draft_utils'
{ resetSelection } = require 'lib/epiditor_utils'
insertExtension =
  require 'components/document_sections/v2/editor_extensions/insert/insert'
{
  default: EpiEpiditor
  resetState: resetEditorState
  createControlExtension
  serializeState: serializeEditorState
  changeTrackerPluginKey
  annotationsPluginKey
  acceptChange
  rejectChange
  getSchema
} = Epiditor

mediator= require 'mediator'

isDraft = (content) -> content.has('blocks') and content.has('entityMap')

veryLowText = ->
  if mediator.services.switches.isServerSwitchOn 'acpFeatures'
    'insufficient'
  else
    'very_low'

RecommendationContent = ({
  populationId
  recommendation
  recommendationId
  section
  strengthOfRecommendation
}) ->
  i18n = useI18n('')
  sectionId = section.get('_id')

  [saving, setSaving] = useState(false)

  recommendationData = useMemo () ->
    section.getIn(['additionalData', 'recommendationsData', recommendationId], Immutable.Map())
  , [section, recommendationId]

  recommendationContent = useMemo () ->
    recommendationData.get('content') or recommendation.get('content')
  , [recommendationData, recommendation]

  rationale = useMemo () ->
    recommendationData.get('rationale') # we don't have it in EtD yet
  , [recommendationData]
  certaintyTagText = recommendationData.get('certaintyTagText') # we don't have it in EtD yet

  [tagText, setTagText] = useState(certaintyTagText)

  useEffect ->
    setTagText(certaintyTagText)
  , [ certaintyTagText ]

  certaintyOfEvidence = recommendationData.get('certaintyOfEvidence', Immutable.List()).toJS()
     # in EtD we have certaintyOfEvidence by intervention
  strengthOfRecommendation = recommendationData.get('strengthOfRecommendation') or strengthOfRecommendation

  handleSettingSelect = useCoffeeCallback [populationId, sectionId, recommendationId], (setting) ->
    switch setting
      when 'delete'
        groupName = recommendation.get('content')
        confirmOptions =
          title: i18n 'vi:confirm.remove_recommendation.title'
          message: i18n 'vi:confirm.remove_recommendation.message'
          confirmText: i18n '/actions.delete'
          declineText: i18n '/actions.cancel'
          yesClass: 'danger'
        mediator.dialogs.confirm confirmOptions, (confirmed) ->
          return unless confirmed
          ACPVisualGuidelinesActions.removeRecommendation(
            { populationId, sectionId, recommendationId }
          )

  onChangeRecommendationData = useCoffeeCallback [sectionId, recommendationId], (key) -> (value) ->
    ACPVisualGuidelinesActions.updateRecommendationData(
      { sectionId, recommendationId, key, value }
    )

  saveContent = useCoffeeCallback [sectionId, recommendationId], (key, value) -> ->
    ACPVisualGuidelinesActions.updateRecommendationData(
      {
        sectionId
        recommendationId
        key
        value
      }
    )

  <div className="recommendation-content">
    <div className="flex flex-row">
      <div className="flex-grow text-right">
        <DropdownMenu
          opener={(
            <IconButton iconName="more" className="settings-toggle" />
          )}
          options={[
            { text: i18n('vi:form.settings.delete'), value: 'delete'}
          ]}
          onSelect={handleSettingSelect}
        />
      </div>
    </div>
    <div className="flex flex-row">
      <div className="flex-grow">
        <div className="mb-5">
          <h3>{i18n 'vi:recommendation'}</h3>
          <div className="recommendation-content__text-field">
            <DraftToEpiditorSwap
              content={recommendationContent}
              editorKey="content"
              readOnly={false}
              sectionId={sectionId}
              recommendationId={recommendationId}
            />
          </div>
        </div>
        <div className="mb-5">
          <h3>{i18n 'vi:rationale'}</h3>
          <div className="recommendation-content__text-field">
            <DraftToEpiditorSwap
              content={rationale}
              editorKey="rationale"
              readOnly={false}
              sectionId={sectionId}
              recommendationId={recommendationId}
            />
          </div>
        </div>
      </div>
      <div className="ml-20 additional-options">
        <h3>{i18n 'vi:form.strength_of_recommendation'}</h3>
        <Select
          name="strengthOfRecommendation"
          className="w-full"
          onChange={onChangeRecommendationData('strengthOfRecommendation')}
          selected={strengthOfRecommendation}
          options={[
            { text: i18n('vi:form.strength.strong_for'), value: 'strong_for'}
            { text: i18n('vi:form.strength.conditional_for'), value: 'conditional_for'}
            { text: i18n('vi:form.strength.conditional_against'), value: 'conditional_against'}
            { text: i18n('vi:form.strength.strong_against'), value: 'strong_against'}
          ]}
        />
        <h3>{i18n 'vi:form.certainty_title'}</h3>
        <SelectWithCheckboxes
          name="certaintyOfEvidence"
          className="w-full"
          onChange={onChangeRecommendationData('certaintyOfEvidence')}
          selectedOptions={certaintyOfEvidence}
          withSelectAll={false}
          options={[
            { text: i18n("vi:form.certainty.#{veryLowText()}"), value: 'very_low'}
            { text: i18n('vi:form.certainty.low'), value: 'low'}
            { text: i18n('vi:form.certainty.moderate'), value: 'moderate'}
            { text: i18n('vi:form.certainty.high'), value: 'high'}
          ]}
        />
        <h3>{i18n 'vi:form.certainty_label'}</h3>
        <input
          type="text"
          className="w-full"
          value={tagText or ''}
          onChange={(e) -> setTagText(e.target.value)}
          onBlur={saveContent('certaintyTagText', tagText)}
        />
      </div>
    </div>
  </div>

RecommendationContent.propTypes = {
  populationId: PropTypes.string.isRequired
  recommendation: PropTypes.instanceOf(Immutable.Map)
  recommendationId: PropTypes.string.isRequired
  section: PropTypes.instanceOf(Immutable.Map).isRequired
  strengthOfRecommendation: PropTypes.string
}

RecommendationContent.defaultProps = {
  recommendation: Immutable.Map()
  strengthOfRecommendation: null
}

getJSEditorState = (immEditorState) -> immEditorState.toJS()

getEditorContent = (content) ->
  if isImmutable(content)
    if isDraft(content)
      rawContentToHTML(content)
    else
      getJSEditorState(content)
  else
    content

DraftToEpiditorSwap = ({ content, editorKey, readOnly, sectionId, recommendationId }) ->

  [ hasChanges, setHasChanges ] = useState(false) # don't care for now
  [ saving, setSaving ] = useState(false)
  [ editing, setEditing ] = useState(false)

  i18n = useI18n()

  draftRef = useRef(null)
  editorViewRef = useRef(null)
  editorContainerRef = useRef(null)

  editMode = if editing then 'edit' else 'readonly'

  editorLoaded = useCallback(
    (editorView) -> editorViewRef.current = editorView
    []
  )

  editorExtensions = useMemo(
    ->
      applyCancelControlsExt = createControlExtension 'applyCancelControlsExt', ({ view }) ->
        <div className="apply-cancel-editor-controls">
          <button className="cancel-btn" onClick={() => handleEditorCancel(editorKey, view)}>
            {i18n '/actions:cancel'}
          </button>
          <button className="apply-btn" onClick={() => handleEditorSave(editorKey, view)}>
            {i18n '/actions:save'}
          </button>
        </div>

      [
        applyCancelControlsExt,
        insertExtension(),
      ]
    [handleEditorCancel, handleEditorSave, editorKey]
  )

  handleEditorSave = useCallback(
    (editorKey, view) ->
      resetSelection view
      saveContent editorKey, serializeEditorState view.state
      setEditing false
    [saveContent]
  )

  handleEditorCancel = useCallback(
    (editorKey, view) ->
      resetEditorState(view, getEditorContent(content))
      setEditing false
    [content]
  )

  handleEditorClick = useCallback(
    (_editorView, evt) ->
      return if editing
      setEditing true unless readOnly
    [editing, readOnly]
  )

  saveContent = useCoffeeCallback [sectionId, recommendationId], (key, value) ->
    ACPVisualGuidelinesActions.updateRecommendationData({
      sectionId
      recommendationId
      key
      value
    })

  handleEditorChange = useCallback(
    (changedValue) ->
      if hasChanges
        return
      if not editing
        return
      if not isImmutable(content) or not _.isEqual getJSEditorState(content), serializeEditorState(changedValue)
        return
        # DiscardChangesActions.setHasChanges true
    [editing, content, hasChanges]
  )

  handleEditorChangeDebounced = useCallback(
    _.debounce handleEditorChange, 500
    [handleEditorChange]
  )

  <div>
    <EpiEpiditor
      extensions={editorExtensions}
      initialState={getEditorContent(content)}
      mode={editMode}
      onChange={handleEditorChangeDebounced}
      onClick={handleEditorClick}
      onLoad={editorLoaded}
      pluginProps={[]}
      parentOverflowContainer={editorContainerRef.current}
    />
  </div>

module.exports = RecommendationContent
