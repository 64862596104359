var DataFromService, ViewTrait, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

ViewTrait = require('./view_trait');

W = require('when');

module.exports = DataFromService = (function(_super) {
  __extends(DataFromService, _super);

  function DataFromService() {
    return DataFromService.__super__.constructor.apply(this, arguments);
  }

  DataFromService.prototype.dataContainer = null;

  DataFromService.prototype.serviceMethods = null;

  DataFromService.prototype.apply = function(view) {
    DataFromService.__super__.apply.apply(this, arguments);
    if (!this.dataContainer) {
      throw new Error('dataContainer must be defined');
    }
    if (!this.serviceMethods) {
      throw new Error('serviceMethods must be defined');
    }
    if (!_.isArray(this.serviceMethods)) {
      throw new Error('serviceMethods must be an Array');
    }
    this._addFunction(view, 'afterRender');
    view.on('!reloadData', this._loadData(view), 'DataFromService');
    return view.trigger('!reloadData');
  };

  DataFromService.prototype._loadData = function(view) {
    return (function(_this) {
      return function() {
        var promises;
        promises = _this.serviceMethods.map(function(serviceFn) {
          return W(serviceFn.call(view.options.service, {
            force: view.options.preview
          }));
        });
        _this.promise = W.all(promises);
        _this.promise.then(function() {
          var data;
          data = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
          view.trigger.apply(view, ['loadingCompleted'].concat(__slice.call(data)));
          return _this._toggleLoading(view);
        });
        view.trigger('loadingStarted');
        return _this._toggleLoading(view);
      };
    })(this);
  };

  DataFromService.prototype._toggleLoading = function(view) {
    if (this.promise.inspect().state === 'pending') {
      return view.$(this.dataContainer).empty().addClass('box-loading');
    } else {
      return view.$(this.dataContainer).removeClass('box-loading');
    }
  };

  DataFromService.prototype.afterRender = function() {
    var args, originalFunction, trait;
    originalFunction = arguments[0], trait = arguments[1], args = 3 <= arguments.length ? __slice.call(arguments, 2) : [];
    if (originalFunction) {
      originalFunction.apply(this, args);
    }
    return trait._toggleLoading(this);
  };

  return DataFromService;

})(ViewTrait);
