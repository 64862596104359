var PanelVoiceActions, PanelVoiceEtdSectionEditModal, etdHelper;

PanelVoiceActions = require('actions/panel_voice_actions');

etdHelper = require('lib/etd_helper');

PanelVoiceEtdSectionEditModal = {
  getInitialState: function() {
    return {
      initialHighlightState: this.props.highlightToPanelMembers
    };
  },
  componentDidMount: function() {
    return etdHelper.saveState();
  },
  componentWillUnmount: function() {
    return etdHelper.flushSavedState();
  },
  onContentChange: function() {
    if (!this.props.highlightToPanelMembers) {
      return this.toggleSectionHighlight();
    }
  },
  onCancel: function() {
    etdHelper.restoreSavedState();
    if (this.props.highlightToPanelMembers !== this.state.initialHighlightState) {
      PanelVoiceActions.toggleSectionHighlight(this.props.etdId, this.props.sectionId);
    }
    return this.onClose();
  },
  toggleSectionHighlight: function() {
    return PanelVoiceActions.toggleSectionHighlight(this.props.etdId, this.props.sectionId);
  }
};

module.exports = PanelVoiceEtdSectionEditModal;
