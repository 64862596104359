var ImportActions, QuestionsListComponentActions, alt, mediator;

alt = require('alt');

mediator = require('mediator');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

ImportActions = (function() {
  function ImportActions() {
    this.generateActions('openImportModal', 'goBack', 'setFile', 'resetSource', 'toggleIncludeReferences', 'closeImportModal', 'importProjectSuccess', 'parseQuestionsSuccess', 'changeProjectName', 'openRevmanCsvConflictMethodDialog', 'cancelResolveRevmanCsvConflict', 'selectRevmanCsvConflictMethod', 'resolveRevmanCsvConflict');
  }

  ImportActions.prototype.importProject = function(importData) {
    this.dispatch();
    return mediator.services.projectImportService.importProject(importData).then(this.actions.importProjectSuccess)["catch"](this.actions.importProjectError);
  };

  ImportActions.prototype.parseQuestions = function(importData) {
    this.dispatch();
    return mediator.services.projectImportService.parseFile(importData).then(this.actions.parseQuestionsSuccess)["catch"](this.actions.parseQuestionsError);
  };

  ImportActions.prototype.importProjectError = function(error) {
    this.dispatch();
    return mediator.dialogs.error(error);
  };

  ImportActions.prototype.parseQuestionsError = function(error) {
    this.dispatch();
    return mediator.dialogs.error(error);
  };

  ImportActions.prototype.importQuestionsOrOutcomes = function(importData) {
    var promise;
    this.dispatch();
    promise = (function() {
      switch (importData.importType) {
        case 'question':
          return mediator.services.projectImportService.importQuestions(importData);
        case 'outcome':
          return mediator.services.projectImportService.importOutcomes(importData);
      }
    })();
    return promise.then(this.actions.importQuestionsOrOutcomesSuccess)["catch"](this.actions.importQuestionsOrOutcomesError);
  };

  ImportActions.prototype.importQuestionsOrOutcomesSuccess = function() {
    this.dispatch();
    return QuestionsListComponentActions.fetch();
  };

  ImportActions.prototype.importQuestionsOrOutcomesError = function(error) {
    this.dispatch();
    QuestionsListComponentActions.fetch();
    if (error !== 'cancelled') {
      return mediator.dialogs.error(error);
    }
  };

  ImportActions.prototype.selectItems = function(itemsToSelect, checked) {
    return this.dispatch({
      itemsToSelect: itemsToSelect,
      checked: checked
    });
  };

  return ImportActions;

})();

module.exports = alt.createActions(ImportActions);
