mediator = require 'mediator'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
{ PLACEMENT_BEFORE, PLACEMENT_AFTER } = require 'lib/document_sections_v2_helper'

module.exports =
  dragSpec:
    beginDrag: (props) ->
      chapterId: props.section.get('parent')
      sectionId: props.section.get('_id')
      index: props.index
    endDrag: (props, monitor) ->
      item = monitor.getItem()
      dropResult = monitor.getDropResult()
      if !dropResult
        return
      if dropResult.sectionId is item.sectionId
        DocumentSectionsV2Actions.saveMovedSection(mediator.project.id, item.chapterId)
    isDragging: (props, monitor) ->
      props.section.get('_id') is monitor.getItem().sectionId

  dropSpec:
    hover: (props, monitor, component) ->
      if !component
        return
      node = component.item
      if !node
        return
      draggingItem = monitor.getItem()
      dragIndex = draggingItem.index
      hoverIndex = props.index
      if dragIndex is hoverIndex
        return
      hoverBoundingRect = node.getBoundingClientRect()
      hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      clientOffset = monitor.getClientOffset()
      hoverClientY = clientOffset.y - hoverBoundingRect.top
      if dragIndex < hoverIndex && hoverClientY < hoverMiddleY
        return
      if dragIndex > hoverIndex && hoverClientY > hoverMiddleY
        return
      if draggingItem.chapterId isnt props.section.get('parent')
        return
      hoverSectionId = props.section.get('_id')
      placement = if dragIndex < hoverIndex then PLACEMENT_AFTER else PLACEMENT_BEFORE
      DocumentSectionsV2Actions.moveSection({
        chapterId: draggingItem.chapterId,
        sectionId: draggingItem.sectionId,
        referredSectionId: hoverSectionId,
        placement
      })
      draggingItem.index = hoverIndex
    drop: (props, monitor, component) ->
      sectionId: props.section.get('_id')

  dragCollect: (connect, monitor) ->
    connectDragPreview: connect.dragPreview()
    connectDragSource: connect.dragSource()
    isDragging: monitor.isDragging()

  dropCollect: (connect, monitor) ->
    connectDropTarget: connect.dropTarget()
