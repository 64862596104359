var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  <div class=\"info\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:publish.qrcode_message", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n  <div id=\"qrcode\">\n  </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  <div class=\"preview-frame\">\n    <iframe id=\"preview-content\" src=\""
    + escapeExpression(((helper = (helper = helpers.previewUrl || (depth0 != null ? depth0.previewUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"previewUrl","hash":{},"data":data}) : helper)))
    + "\"></iframe>\n  </div>\n  <div class=\"original-text\">\n    <h2>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:publish.original_text", {"name":"i18n","hash":{},"data":data})))
    + "</h2>\n    <div class=\"col-12 mt-10 fields\">\n      <div class=\"rationale\"></div>\n      <div class=\"benefitsAndHarms\"></div>\n    </div>\n    <div class=\"col-12 mt-10\">\n      <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:publish.original_text_description", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n    </div>\n  </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <button class=\"btn btn-block btn-send apply-changes hidden\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.apply", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    <button class=\"btn btn-block btn-send edit-question hidden\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.edit", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    <button class=\"btn btn-block btn-send send-preview mt-10\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "dissemination:publish.send_preview", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.mobilePreviewUrl : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"preview-sidebar\">\n  <div class=\"col-12 mt-10\">\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.mobilePreviewUrl : depth0), {"name":"unless","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n  <div class=\"col-12 mt-10\">\n    <button class=\"btn btn-block btn-cancel close\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "actions.close", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}