var QuestionsListComponentActions, QuestionsListMixin;

QuestionsListComponentActions = require('actions/questions_list_component_actions');

QuestionsListMixin = {
  updateList: function() {
    var questionGroups, questions, _ref;
    _ref = this.props, questions = _ref.questions, questionGroups = _ref.questionGroups;
    return _.defer(function() {
      return QuestionsListComponentActions.updateList({
        questions: questions,
        questionGroups: questionGroups
      });
    });
  },
  componentDidUpdate: function(prevProps, prevState) {
    var isQuestionBeingAdded, isQuestionBeingDuplicated, prevQuestionGroups, prevQuestions, questionGroups, questions, _ref;
    _ref = this.props, questions = _ref.questions, questionGroups = _ref.questionGroups, isQuestionBeingDuplicated = _ref.isQuestionBeingDuplicated, isQuestionBeingAdded = _ref.isQuestionBeingAdded;
    prevQuestions = prevProps.questions, prevQuestionGroups = prevProps.questionGroups;
    if (isQuestionBeingDuplicated || isQuestionBeingAdded) {
      return;
    }
    return this.updateList();
  },
  componentDidMount: function() {
    return this.updateList();
  }
};

module.exports = QuestionsListMixin;
