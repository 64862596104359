SpinnerButton = require 'components/common/spinner_button'
QuestionGroupRating = require 'components/scope/outcomes/question_group_for_rating'
IndividualRatingsModal = require 'components/scope/individual_ratings_modal'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
OutcomeShowCommentsMixin = require 'components/mixins/outcomes_show_comments_mixin'
IndividualRatingsToggle = require 'components/mixins/individual_ratings_toggle'
mediator = require 'mediator'
ratingStep = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS[3]
moderationStep = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS[2]
brainstormingStep = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS[1]

{ Button } = ReactComponents

ProposalTab = createReactClass
  displayName: 'ProposalTab'

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
    OutcomeShowCommentsMixin(brainstormingStep, moderationStep, ratingStep)
    IndividualRatingsToggle
  ]

  getInitialState: ->
    showIndividualRatings: false

  renderQuestionGroup: (qGroup, idx) ->
    <QuestionGroupRating key={idx}
      data={qGroup}
      gIndex={idx}
      membersMap={@props.membersMap}
      readOnly={@props.readOnly}
      onCommentToggle={@onItemCommentToggle}
      showingComments={@state.showingAllComments}
    />

  render: ->
    <div className='proposal-tab'>
      <div className='information'>
        <div>{@i18n 'proposal_section_text'}</div>
      </div>
      <div className='top-buttons'>
        <Button onClick={@toggleIndividualRatings}>
          {@i18n '../show_individual_ratings'}
        </Button>
        <Button
          onClick={@showComments}
          disabled={@isShowCommentsDisabled()}>
          {@i18n '../questions.show_all_comments'}
        </Button>
      </div>
      <div className='questions-container'>
        {@props.questionGroups.map @renderQuestionGroup}
      </div>
      <div className="buttons">
        <SpinnerButton
          className="btn close-step"
          onClick={@props.onFinishAndSend}
          disabled={@props.readOnly}
          label={@i18n '../questions.proposal.next_step'}
          loading={@props.isSendingToMembers}
        />
      </div>
      <IndividualRatingsModal
        isOpen={@state.showIndividualRatings}
        onClose={@toggleIndividualRatings}
        questionGroups={@props.questionGroups}
        membersMap={@props.membersMap}
      />
    </div>

module.exports = ProposalTab
