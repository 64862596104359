ConclusionsJustification = require 'components/etd/conclusions_justification'
ConclusionsTextSection = require 'components/etd/conclusions_text_section'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
mediator = require 'mediator'
MultipleRecommendations = require 'components/etd/multiple_recommendations'
RecommendationMetadata = require 'components/etd/recommendation_metadata'
MDGRecommendation = require 'components/mdg_tables/recommendation_section'
QIMonitoringAndEvaluation = require 'components/quality_indicators/qi_monitoring_and_evaluation'
Translation = require 'components/mixins/translation'
{ getConsensusBasedField } = require 'lib/etd_helper'
ImportFromQualityIndicatorsButton =
  require 'components/quality_indicators/import_from_quality_indicators_button'

CUSTOM_SECTIONS = [
  'goodPracticeStatement'
  'justification'
  'keyMessage'
  'mdgRecommendation'
  'multipleRecommendations'
  'monitoringAndEvaluationQI'
]

CustomRecommendationSection = ({
  assessmentSections
  editable
  isConsensus
  placeholder
  readOnly
  renderMode
  section
  sectionId
}) ->
  switch sectionId
    when 'justification'
      <ConclusionsJustification
        assessmentSections={assessmentSections}
        editable={editable}
        isConsensus={isConsensus}
        renderMode={renderMode}
        readOnly={readOnly}
        justification={section}
      />
    when 'goodPracticeStatement', 'keyMessage'
      <div>
        <ConclusionsTextSection
          cellId={"#{sectionId}#conclusions"}
          content={getConsensusBasedField(section, 'content', isConsensus) or null}
          editable={editable}
          isConsensus={isConsensus}
          options={section.get('options')}
          placeholder={placeholder}
          readOnly={readOnly}
          renderMode={renderMode}
          selectedOption={section.get('selectedOption')}
        />
        {renderMode isnt 'printout' and <div className="text-right mt-10">
          <RecommendationMetadata editable={editable} />
        </div>}
      </div>
    when 'multipleRecommendations'
      <MultipleRecommendations
        editable={editable}
        readOnly={readOnly}
        renderMode={renderMode}
        section={section}
        sectionId={sectionId}
      />
    when 'mdgRecommendation'
      <MDGRecommendation
        editable={editable}
        readOnly={readOnly}
        renderMode={renderMode}
        section={section}
        sectionId={sectionId}
      />
    when 'monitoringAndEvaluationQI'
      <QIMonitoringAndEvaluation
        editable={editable}
        readOnly={readOnly}
        renderMode={renderMode}
        section={section}
        sectionId={sectionId}
      />
    else
      null

ConclusionsRow = createReactClass

  displayName: 'ConclusionsRow'

  mixins: [
    CustomRenderMixin,
    Translation('')
  ]

  render: ->
    {
      assessmentSections
      contentCellClass
      contentColSpan
      editable
      isConsensus
      placeholder
      readOnly
      renderMode
      section
      sectionId
    } = @props

    isQualityIndicatorsSwitchOn = mediator.services.switches.isOn 'qualityIndicators'

    <tr>
      <td
        className={contentCellClass}
        colSpan={contentColSpan}
      >
        {if sectionId in CUSTOM_SECTIONS
          <CustomRecommendationSection
            assessmentSections={assessmentSections}
            editable={editable}
            isConsensus={isConsensus}
            placeholder={placeholder}
            readOnly={readOnly}
            renderMode={renderMode}
            section={section}
            sectionId={sectionId}
          />
        else if sectionId is 'monitoringAndEvaluation' and isQualityIndicatorsSwitchOn
          <div>
            {renderMode isnt 'printout' and <ImportFromQualityIndicatorsButton />}
            <ConclusionsTextSection
              editable={editable}
              isConsensus={isConsensus}
              content={getConsensusBasedField(section, 'content', isConsensus) or ''}
              cellId={"#{sectionId}#conclusions"}
              readOnly={readOnly}
              noInsertOption
              placeholder={placeholder}
              options={section.get('options')}
              selectedOption={section.get('selectedOption')}
            />
          </div>
        else if section.has 'content'
          <ConclusionsTextSection
            editable={editable and sectionId isnt 'evidence'}
            isConsensus={isConsensus}
            content={getConsensusBasedField(section, 'content', isConsensus) or null}
            cellId={"#{sectionId}#conclusions"}
            noInsertOption
            readOnly={readOnly}
            placeholder={placeholder}
            options={section.get('options')}
            selectedOption={section.get('selectedOption')}
          />
        else
          throw new Error 'Unsupported conclusions section'
        }
      </td>
    </tr>

module.exports = ConclusionsRow
