var CHAPTER_ICONS, DA_ILLUSTRATIONS, DA_THEMES, DbHelper, SUBCHAPTER_ILLUSTRATIONS, getChaperIconPath, getSubchapterIllustrationPath;

DbHelper = require('base/lib/db_helper');

CHAPTER_ICONS = ['human', 'human_with_bubble', 'justice', 'pluses', 'file', 'exclamation', 'question'];

DA_ILLUSTRATIONS = ['bloodCells', 'bodyBones', 'bodyChild', 'bodyMan', 'bodyMuscles', 'bodyVeins', 'bodyWoman', 'brain', 'breast', 'eye', 'heart', 'intestines', 'kidneys', 'liver', 'lungs', 'neuron', 'skull', 'stomach', 'urinary', 'veins'];

DA_THEMES = ['blue', 'gold', 'green', 'pink', 'red', 'grey'];

SUBCHAPTER_ILLUSTRATIONS = ['doctor', 'blister', 'cut', 'pills1', 'doctorWithPatient2', 'medicines', 'patch', 'pills2', 'doctorWithPatient1', 'pills4', 'syringe', 'pills3', 'tube'];

getChaperIconPath = function(iconName) {
  return DbHelper.getStaticAssetsUrl("/decision-aids/chapter-icons/" + (_.str.underscored(iconName)) + ".svg");
};

getSubchapterIllustrationPath = function(illustrationName) {
  return DbHelper.getStaticAssetsUrl("/decision-aids/subchapter-illustrations/" + (_.str.underscored(illustrationName)) + ".svg");
};

module.exports = {
  CHAPTER_ICONS: CHAPTER_ICONS,
  DA_ILLUSTRATIONS: DA_ILLUSTRATIONS,
  DA_THEMES: DA_THEMES,
  SUBCHAPTER_ILLUSTRATIONS: SUBCHAPTER_ILLUSTRATIONS,
  getChaperIconPath: getChaperIconPath,
  getSubchapterIllustrationPath: getSubchapterIllustrationPath
};
