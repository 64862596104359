var Migration, TypoInCertaintyOfEvidenceOfManagementEffects, W, mediator, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

mediator = require('mediator');

utils = require('base/lib/utils');

module.exports = TypoInCertaintyOfEvidenceOfManagementEffects = (function(_super) {
  __extends(TypoInCertaintyOfEvidenceOfManagementEffects, _super);

  function TypoInCertaintyOfEvidenceOfManagementEffects() {
    TypoInCertaintyOfEvidenceOfManagementEffects.__super__.constructor.call(this, false);
  }

  TypoInCertaintyOfEvidenceOfManagementEffects.prototype.up = function(project, colls) {
    TypoInCertaintyOfEvidenceOfManagementEffects.__super__.up.apply(this, arguments);
    return W(this.etdTemplatesStore.fetch(project.id, ['etd-dx-template'])).then((function(_this) {
      return function() {
        var baseTemplate, correctValue, lang, newTemplate, pathToCorrect, recIds, state;
        state = _this.etdTemplatesStore.getState();
        if (state.get('dxTemplate') == null) {
          return W.resolve();
        }
        lang = state.getIn(['dxTemplate', 'templateDef', 'lang']);
        if ((lang != null) && lang !== 'en') {
          return W.resolve();
        }
        pathToCorrect = ['templateData', 'assessment', 'criteria', 'certaintyOfEvidenceOfManagementEffects', 'description'];
        baseTemplate = _this.etdTemplateHelper.prepareBaseTemplate(state.getIn(['dxTemplate', 'templateDef', 'id']), 'en');
        correctValue = baseTemplate.getIn(pathToCorrect);
        newTemplate = state.get('dxTemplate').setIn(pathToCorrect, correctValue);
        _this.etdTemplatesActions.save(project.id, newTemplate);
        recIds = _(colls.questions.models).chain().filter(function(q) {
          return q.isDiagnostic();
        }).pluck('attributes').pluck('recommendationIds').flatten().uniq().value();
        return _this.storePersistenceAdapter.fetch(project.id, recIds).then(function(res) {
          return W.map(_(res.rows).pluck('doc'), function(doc) {
            var etdDoc, newDoc;
            etdDoc = Immutable.fromJS(doc);
            lang = etdDoc.getIn(['templateDef', 'lang']);
            if ((lang != null) && lang !== 'en') {
              return;
            }
            newDoc = etdDoc.setIn(pathToCorrect, correctValue);
            return _this.storePersistenceAdapter.save(project.id, newDoc.toJS());
          });
        });
      };
    })(this));
  };

  return TypoInCertaintyOfEvidenceOfManagementEffects;

})(Migration);
