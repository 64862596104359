ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Button = require 'components/common/button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdActions = require 'actions/etd_actions'
Modal = require 'components/common/modal'
Rating = require 'components/common/rating'
RankingDescription = require 'components/etd/judgements/ranking_description'
Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
MultiInterventionJudgementOption =
  require 'components/etd/judgements/multiintervention_judgement_option'
{ getTagsByKeys } = require 'lib/etd_helper'
{ convertListToOrderedMap } = require 'lib/immutable_utils'

RankingModal = createReactClass

  propTypes:
    tags: PropTypes.instanceOf(Immutable.Map).isRequired
    isOpen: PropTypes.bool.isRequired
    onApply: PropTypes.func.isRequired
    onCloseModal: PropTypes.func.isRequired
    handleRating: PropTypes.func.isRequired
    sectionId: PropTypes.string.isRequired
    questionType: PropTypes.string.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  render: ->

    {
      criterion
      handleRating
      isOpen
      onApply
      onCloseModal
      questionType
      sectionId
      tags
    } = @props

    <Modal
      className="ranking-judgements"
      isOpen={isOpen}
      closeButton={true}
      onClose={onCloseModal}
      title={@i18n 'ranking_judgement.name'}
    >
      <div className="ranking-modal-content">
        <div className="ranking-modal-content__description">
          <div className="mb-10">
            {criterion.get('description')}
          </div>
        </div>
        <table className="interventions-rating">
          <thead>
            <tr>
              {tags.map (tag) ->
                <th className="blue-cell" key={tag.get('id')}>
                  {tag.get('name')}
                </th>
              .toList()}
            </tr>
          </thead>
          <tbody>
            <tr>
              {tags.map (tag) ->
                options = criterion.get('options')
                unless criterion.get('additionalOptions').isEmpty()
                  options = options.push {text: "-------", value: null, disabled: true}
                    .concat(criterion.get('additionalOptions'))

                <td key={tag.get('id')}>
                  <Select
                    options={options.toJS()}
                    onChange={handleRating(sectionId, tag.get('id'))}
                    selected={criterion.getIn ['rating', tag.get('id')], ""}
                  />
                </td>
              .toList()}
            </tr>
          </tbody>
        </table>
      </div>
      <ApplyCancelButtons
        onApply={onApply}
        onCancel={onCloseModal}
      />
    </Modal>

RankingJudgementV2 = createReactClass

  displayName: "RankingJudgementV2"

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  propTypes:
    criterion: PropTypes.instanceOf(Immutable.Map).isRequired
    editable: PropTypes.bool.isRequired
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map).isRequired
    questionType: PropTypes.string.isRequired
    renderMode: PropTypes.string.isRequired
    sectionId: PropTypes.string.isRequired

  getInitialState: ->
    isModalOpen: false

  openModal: ->
    return if @props.readOnly or not @props.editable
    @setState isModalOpen: true

  closeModal: ->
    @setState isModalOpen: false

  onApply: ->
    EtdActions.save()
    @closeModal()

  handleRating: (sectionId, key) -> (newRating) ->
    EtdActions.setRating { sectionId, key, newRating }

  render: ->
    { criterion, renderMode, overarchingQuestionData, sectionId, questionType } = @props

    tags = convertListToOrderedMap(getTagsByKeys(
      overarchingQuestionData.get('includedTags'), ['intervention', 'indexTest']), 'id')

    grouppedTagsByOption = criterion.get('rating', Immutable.Map()).groupBy _.identity

    <div className="flex flex-col h-full">
      <div className="flex flex-col flex-grow">
        {criterion.get('options').map (option) ->
          <div key={option}>
            <MultiInterventionJudgementOption
              option={option}
              tags={tags}
              grouppedTagsByOption={grouppedTagsByOption}
            />
          </div>
        .toList()}
        {unless criterion.get('additionalOptions', Immutable.Map()).isEmpty()
          <div>
            <hr />
            {criterion.get('additionalOptions').map (option) ->
              <div key={option}>
                <MultiInterventionJudgementOption
                  option={option}
                  tags={tags}
                  grouppedTagsByOption={grouppedTagsByOption}
                />
              </div>
            .toList()}
          </div>
        }
      </div>
      {renderMode isnt 'printout' and
        <div className="mt-10">
          <Button
            className="btn btn-block detailed-judgements-btn"
            label={@i18n "ranking_judgement.judgment"}
            onClick={@openModal}
          />
          <RankingModal
            criterion={criterion}
            handleRating={@handleRating}
            isOpen={@state.isModalOpen}
            onApply={@onApply}
            onCloseModal={@closeModal}
            questionType={questionType}
            sectionId={sectionId}
            tags={tags}
          />
        </div>
      }
    </div>

module.exports = RankingJudgementV2
