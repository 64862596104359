var arrayOf, docShape, documentId, initial, object, optional, rootDocShape, shape;

documentId = require('lib/db_docs/field_types/document_id');

shape = require('lib/db_docs/field_types/shape');

docShape = require('lib/db_docs/field_types/doc_shape');

initial = require('lib/db_docs/field_types/type_decorators').initial;

arrayOf = require('lib/db_docs/field_types/array_of');

object = require('lib/db_docs/field_types/built_in_types').object;

optional = shape.typeDecorators.optional;

rootDocShape = docShape({
  chaptersOrder: initial([])(arrayOf(documentId)),
  interactiveTableData: optional(initial({})(object))
});

module.exports = rootDocShape;
