var Dashboard, DashboardActions, DashboardStore, DashboardToolbarView, DashboardView, QUESTION_STATUSES_DOC_TYPE, ReactComponent, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

mediator = require('mediator');

QUESTION_STATUSES_DOC_TYPE = require('lib/db_docs/doc_types').QUESTION_STATUSES_DOC_TYPE;

alt = require('alt');

Dashboard = require('components/dashboard/dashboard');

DashboardStore = require('stores/dashboard_store');

DashboardToolbarView = require('views/dashboard/dashboard_toolbar_view');

DashboardActions = require('actions/dashboard_actions');

module.exports = DashboardView = (function(_super) {
  __extends(DashboardView, _super);

  function DashboardView() {
    return DashboardView.__super__.constructor.apply(this, arguments);
  }

  DashboardView.prototype.container = '#page-container';

  DashboardView.prototype.id = 'dashboard-container';

  DashboardView.prototype.autoRender = true;

  DashboardView.prototype.initialize = function() {
    DashboardView.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    return this.subview('toolbar', new DashboardToolbarView);
  };

  DashboardView.prototype.afterRender = function() {
    var projectId;
    DashboardView.__super__.afterRender.apply(this, arguments);
    projectId = mediator.project.id;
    return this.renderComponent(Dashboard, DashboardActions, null, {
      fetchAndListen: {
        dbId: projectId,
        filter: function(doc) {
          return doc._id === projectId || doc.docType === QUESTION_STATUSES_DOC_TYPE;
        }
      }
    });
  };

  DashboardView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('DashboardStore');
    return DashboardView.__super__.dispose.apply(this, arguments);
  };

  return DashboardView;

})(View);
