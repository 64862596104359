var W, getDb, mediator, utils, _createDesignDoc, _getOverwriteTechComment;

W = require('when');

mediator = require('mediator');

utils = require('base/lib/utils');

getDb = function(cache, dbName) {
  var _ref;
  return (_ref = cache[dbName]) != null ? _ref : cache[dbName] = PouchDB(dbName, window.gdt.POUCHDB_OPTIONS);
};

_createDesignDoc = function(name, mapFn, reduceFn) {
  var ddoc;
  if (reduceFn == null) {
    reduceFn = false;
  }
  ddoc = {
    _id: "_design/" + name,
    views: {},
    filters: {}
  };
  ddoc.views[name] = {
    map: mapFn.toString()
  };
  if (reduceFn) {
    ddoc.views[name].reduce = reduceFn.toString();
  }
  return ddoc;
};

_getOverwriteTechComment = function(oldRev, newRev) {
  return "Update with previous revision " + oldRev + " resulted in conflict; overwriting revision " + newRev + ".";
};

_.namespace(module, function(require) {
  var clock, dbCache;
  clock = require('lib/services/clock_service');
  dbCache = {};
  return {
    _ensureTimestampAndRevAuthor: function(doc) {
      return _.extend({}, doc, {
        $timestamp: clock.getTimestamp(),
        rev_author: mediator.user.get('name')
      });
    },
    save: function(dbName, doc) {
      var db, method;
      db = getDb(dbCache, dbName);
      method = doc._id ? 'put' : 'post';
      doc = this._ensureTimestampAndRevAuthor(doc);
      return W(db[method](doc).then(function(result) {
        doc._id = result.id;
        doc._rev = result.rev;
        return doc;
      }))["catch"]((function(_this) {
        return function(e) {
          if (e.status === 409) {
            return _this.fetch(dbName, doc._id).then(function(res) {
              var newRev, oldRev;
              oldRev = doc._rev;
              newRev = res._rev;
              doc._rev = newRev;
              doc.tech_comment = _getOverwriteTechComment(oldRev, newRev);
              doc.tech_old_rev = _.omit(res, 'tech_old_rev');
              return _this.save(dbName, doc);
            });
          } else {
            throw e;
          }
        };
      })(this));
    },
    updateOrCreate: function(dbName, docId, updater) {
      var db;
      db = getDb(dbCache, dbName);
      return W(db.get(docId))["catch"](function(err) {
        if (err.status !== 404) {
          throw err;
        }
        return {};
      }).then((function(_this) {
        return function(doc) {
          var docRev, newDoc;
          docRev = doc._rev;
          newDoc = updater(doc);
          if (newDoc == null) {
            return doc;
          }
          newDoc._id = docId;
          newDoc._rev = docRev;
          return _this.save(dbName, newDoc);
        };
      })(this));
    },
    bulkGet: function(dbName, options) {
      var db;
      if (options == null) {
        options = {};
      }
      if (!(dbName && options.docs)) {
        throw new Error('Both dbName and docs option are required!');
      }
      db = getDb(dbCache, dbName);
      return W(db.bulkGet(options));
    },
    bulkDocs: function(dbName, docs) {
      var db;
      db = getDb(dbCache, dbName);
      return W(db.bulkDocs(_.map(docs, this._ensureTimestampAndRevAuthor))).then(function(result) {
        var error;
        error = _(result).find(function(r) {
          return r.error;
        });
        if (error) {
          throw error;
        } else {
          return result;
        }
      })["catch"](function(e) {
        throw e;
      });
    },
    fetchAllDocs: function(dbName) {
      var db;
      db = getDb(dbCache, dbName);
      return db.allDocs({
        include_docs: true
      }).then(function(response) {
        return _(response.rows).pluck('doc');
      });
    },
    fetch: function(dbName, ids, options) {
      var db;
      if (options == null) {
        options = {};
      }
      if (!(dbName && ids)) {
        throw new Error('Both dbName and doc ID(s) are required!');
      }
      db = getDb(dbCache, dbName);
      return W(_(ids).isArray() ? (_(options).extend({
        include_docs: true,
        keys: ids
      }), db.allDocs(options)) : db.get(ids, options));
    },
    exists: function(dbName, id, options) {
      var db;
      if (options == null) {
        options = {};
      }
      if (!(dbName && id)) {
        throw new Error('Both dbName and doc ID are required!');
      }
      db = getDb(dbCache, dbName);
      return db.get(id, options).then(function() {
        return true;
      })["catch"](function(err) {
        if (err.status === 404) {
          return false;
        }
        throw err;
      });
    },
    fetchWithView: function(dbName, viewName, view, key) {
      var db, getOptionsWithKey, map, reduce;
      getOptionsWithKey = function(opts) {
        var options;
        if (opts == null) {
          opts = {};
        }
        options = _.extend({}, opts);
        if (key != null) {
          options.key = key;
        }
        return options;
      };
      db = getDb(dbCache, dbName);
      map = view.map, reduce = view.reduce;
      reduce = reduce != null ? reduce : false;
      return db.query(viewName, getOptionsWithKey()).then(function(response) {
        return _(response.rows).pluck('value');
      })["catch"](function(err) {
        var designDoc;
        if (err.status === 404) {
          designDoc = _createDesignDoc(viewName, map, reduce);
          db.put(designDoc).then(function(doc) {
            return db.query(viewName, getOptionsWithKey({
              stale: 'update_after'
            }));
          })["catch"](function(err) {
            throw new Error(err.message);
          });
          return db.query(map, getOptionsWithKey({
            reduce: reduce,
            include_docs: true
          })).then(function(response) {
            return _(response.rows).pluck('value');
          });
        } else {
          throw new Error(err.message);
        }
      });
    },
    fetchWithKeyPrefix: function(dbName, keyPrefix, options) {
      var db;
      if (options == null) {
        options = {};
      }
      db = getDb(dbCache, dbName);
      options = _.extend(options, {
        startkey: keyPrefix,
        endkey: "" + keyPrefix + "\uffff",
        include_docs: true
      });
      return db.allDocs(options);
    },
    setupListening: function(dbName, options, changeListener) {
      var db, defaultOptions;
      db = getDb(dbCache, dbName);
      defaultOptions = {
        since: 'now',
        include_docs: true,
        live: true
      };
      return db.changes(_.defaults(options, defaultOptions)).on('change', changeListener).on('change', function(e) {
        return mediator.publish('localDbModified', dbName, e);
      }).on('error', function(error) {
        return utils.reportRavenError(error);
      });
    },
    putAttachment: function(dbName, docId, attachmentName) {},
    getAttachment: function(dbName, docId, attachmentName) {},
    removeAttachment: function(dbName, docId, attachmentName) {
      var db;
      db = getDb(dbCache, dbName);
      return W(db.get(docId).then(function(doc) {
        return db.removeAttachment(docId, attachmentName, doc._rev);
      }));
    },
    query: function(dbName, fun, opts) {
      var db;
      db = getDb(dbCache, dbName);
      return db.query(fun, opts);
    }
  };
});
