var DeleteOptionFromValuesSection, EtdTemplateHelper, EtdTemplateStore, EtdTemplatesActions, Migration, W, mediator, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

mediator = require('mediator');

EtdTemplatesActions = require('actions/etd_templates_actions');

EtdTemplateHelper = require('stores/utils/etd_template_helper');

EtdTemplateStore = require('stores/etd_templates_store');

utils = require('base/lib/utils');

module.exports = DeleteOptionFromValuesSection = (function(_super) {
  __extends(DeleteOptionFromValuesSection, _super);

  function DeleteOptionFromValuesSection() {
    return DeleteOptionFromValuesSection.__super__.constructor.apply(this, arguments);
  }

  DeleteOptionFromValuesSection.prototype.construct = function() {
    return DeleteOptionFromValuesSection.__super__.construct.call(this, false);
  };

  DeleteOptionFromValuesSection.prototype.up = function(project, colls) {
    var removeAdditionalOptions;
    DeleteOptionFromValuesSection.__super__.up.apply(this, arguments);
    removeAdditionalOptions = function(values) {
      return values.set('additionalOptions', Immutable.List());
    };
    return W(EtdTemplateStore.fetch(project.id, ['etd-tx-template', 'etd-dx-template'])).then((function(_this) {
      return function() {
        var dxValues, dxValuesPath, state, txValues, txValuesPath;
        state = EtdTemplateStore.getState();
        dxValuesPath = ['dxTemplate', 'templateData', 'assessment', 'criteria', 'values'];
        txValuesPath = ['txTemplate', 'templateData', 'assessment', 'criteria', 'values'];
        dxValues = state.getIn(dxValuesPath);
        txValues = state.getIn(txValuesPath);
        if (!dxValues && !txValues) {
          return W.resolve();
        }
        if (dxValues) {
          state = state.setIn(dxValuesPath, removeAdditionalOptions(dxValues));
        }
        if (txValues) {
          state = state.setIn(txValuesPath, removeAdditionalOptions(txValues));
        }
        if (state.get('dxTemplate')) {
          _this._updateTemplatesInQuestions(project.id, state.get('dxTemplate'), 'dx');
        }
        if (state.get('txTemplate')) {
          return _this._updateTemplatesInQuestions(project.id, state.get('txTemplate'), 'tx');
        }
      };
    })(this)).otherwise(function(err) {
      console.log(err);
      throw err;
    });
  };

  DeleteOptionFromValuesSection.prototype._updateTemplatesInQuestions = function(dbName, newTemplate, templateType) {
    var filteredQuestions, recIds, removeAdditionalOptions;
    filteredQuestions = mediator.questions.filter(function(q) {
      if (templateType === 'dx') {
        return q.isDiagnostic();
      } else {
        return !q.isDiagnostic();
      }
    });
    removeAdditionalOptions = function(values) {
      return values = values.get('selectedOption') !== 'no_known' && values.get('selectedOptionConsensus') !== 'no_known' ? values.set('additionalOptions', Immutable.List()) : values;
    };
    recIds = _(filteredQuestions).chain().pluck('attributes').pluck('recommendationIds').flatten().uniq().value();
    return mediator.services.storePersistenceAdapter.fetch(dbName, recIds).then(function(res) {
      var etdDoc, etdDocs, saveToDb, updatedDocs, values, valuesPath;
      etdDocs = _(res.rows).pluck('doc');
      updatedDocs = Immutable.List((function() {
        var _i, _len, _ref, _results;
        _ref = _(etdDocs).compact();
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          etdDoc = _ref[_i];
          etdDoc = Immutable.fromJS(etdDoc);
          valuesPath = ['templateData', 'assessment', 'criteria', 'values'];
          values = etdDoc.getIn(valuesPath, Immutable.Map());
          if (values.isEmpty()) {
            _results.push(etdDoc);
          } else {
            etdDoc = etdDoc.setIn(valuesPath, removeAdditionalOptions(values));
            _results.push(EtdTemplateHelper.updateTemplate(etdDoc, newTemplate));
          }
        }
        return _results;
      })());
      saveToDb = mediator.services.storePersistenceAdapter.save;
      return W.all((updatedDocs.map(function(doc) {
        return saveToDb(dbName, doc.toJS());
      })).toJS())["catch"](function(e) {
        return utils.reportRavenError(e);
      });
    });
  };

  return DeleteOptionFromValuesSection;

})(Migration);
