var ChecklistActions, ChecklistDoc, W, alt, callMethod, mediator, projectChecklistDocId;

alt = require('alt');

W = require('when');

ChecklistDoc = require('lib/db_docs/checklist_doc');

callMethod = require('base/lib/utils').callMethod;

projectChecklistDocId = require('lib/doc_ids').PROJECT_CHECKLIST;

mediator = require('mediator');

ChecklistActions = (function() {
  function ChecklistActions() {
    this.generateActions('fetchSuccess', 'fetchError', 'toggleProcessDiagram');
  }

  ChecklistActions.prototype.dbChange = function(data) {
    if (data.id !== projectChecklistDocId) {
      return;
    }
    return this.dispatch(data);
  };

  ChecklistActions.prototype.fetchTopics = function(type) {
    return function() {
      var fileName;
      fileName = type === 'mdg' ? 'checklist_mdg.json' : 'checklist.json';
      return $.ajax("help/" + fileName).then(W.resolve).fail(function(_response, err) {
        return W.reject(err);
      });
    };
  };

  ChecklistActions.prototype.fetchChecklistData = function() {
    return ChecklistDoc.at(mediator.project.id).fetch()["catch"](function(err) {
      if (err.status === 404) {
        return ChecklistDoc.at(mediator.project.id).create();
      } else {
        throw err;
      }
    });
  };

  ChecklistActions.prototype.fetch = function(_arg) {
    var type;
    type = _arg.type;
    this.dispatch();
    return W.sequence([this.actions.fetchTopics(type), this.actions.fetchChecklistData]).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  ChecklistActions.prototype.updateStepStatus = function(payload) {
    var status, stepId, topicId;
    this.dispatch(payload);
    topicId = payload.topicId, stepId = payload.stepId, status = payload.status;
    return ChecklistDoc.at(mediator.project.id).updateAsInstance(callMethod('updateStepData', topicId, stepId, {
      status: status
    }));
  };

  ChecklistActions.prototype.updateStepComment = function(payload) {
    var comment, stepId, topicId;
    this.dispatch(payload);
    topicId = payload.topicId, stepId = payload.stepId, comment = payload.comment;
    return ChecklistDoc.at(mediator.project.id).updateAsInstance(callMethod('updateStepData', topicId, stepId, {
      comment: comment
    }));
  };

  return ChecklistActions;

})();

module.exports = alt.createActions(ChecklistActions);
