var CHECK_CONNECTION_INTERVAL, CHECK_CONNECTION_MAX_RETRIES, DbHelper, Exceptions, OrderedCollection, Project, Projects, User, W, mediator, poll, sequence,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

OrderedCollection = require('models/base/ordered_collection');

Project = require('models/project');

User = require('models/user');

Exceptions = require('lib/exceptions');

W = require('when');

DbHelper = require('base/lib/db_helper');

sequence = require('when/sequence');

poll = require('when/poll');

mediator = require('mediator');

CHECK_CONNECTION_INTERVAL = 100;

CHECK_CONNECTION_MAX_RETRIES = 3;

module.exports = Projects = (function(_super) {
  __extends(Projects, _super);

  function Projects() {
    return Projects.__super__.constructor.apply(this, arguments);
  }

  Projects.prototype.model = Project;

  Projects.prototype._redirects = {};

  Projects.prototype.setOrderOnAdd = false;

  Projects.prototype.initialize = function() {
    Projects.__super__.initialize.apply(this, arguments);
    this.uninitializedProjects = {};
    return this.subscribeEvent('replication.bgChanges.project', (function(_this) {
      return function(info) {
        if (!(info.project in _this.uninitializedProjects)) {
          return;
        }
        return _this._fetchProject(info.project)().then(function(p) {
          if (p) {
            return _this.add(p);
          }
        });
      };
    })(this));
  };

  Projects.prototype.removeProject = function(id) {
    return this.remove(this.get(id));
  };

  Projects.prototype.fetch = function() {
    var project, projectDBs;
    projectDBs = mediator.user.getProjects();
    return sequence((function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = projectDBs.length; _i < _len; _i++) {
        project = projectDBs[_i];
        _results.push(this._fetchProject(project));
      }
      return _results;
    }).call(this)).then((function(_this) {
      return function(results) {
        return _this.reset(_(results).compact());
      };
    })(this));
  };

  Projects.prototype._fetchProject = function(project) {
    return (function(_this) {
      return function() {
        return new Project({
          _id: project
        }).fetch().then(function(model) {
          delete _this.uninitializedProjects[project];
          _this._addRedirectsFor(model);
          return model;
        }, function(error) {
          if (error.status !== 404) {
            throw error;
          }
          return mediator.services.projectDb.checkIfCentralMetadocExists(project).then(function(exists) {
            _this.uninitializedProjects[project] = exists ? 'waiting' : 'missing';
            return null;
          }, function(err) {
            if (err instanceof Exceptions.no_connection || err instanceof Exceptions.authentication.forbidden) {
              _this.uninitializedProjects[project] = 'waiting';
              return null;
            } else {
              throw err;
            }
          });
        });
      };
    })(this);
  };

  Projects.prototype._fetchIfConnected = function(projectId) {
    var checkingConnection, retries;
    retries = 0;
    checkingConnection = function() {
      retries += 1;
      if (retries > CHECK_CONNECTION_MAX_RETRIES) {
        return W.reject('max_retries');
      }
      if (mediator.services.replication.isConnected()) {
        return W.resolve(true);
      }
      if (!mediator.services.replication.isConnecting()) {
        return W.reject('not_connecting');
      }
      return W.resolve(false);
    };
    return poll(checkingConnection, CHECK_CONNECTION_INTERVAL, _.identity).then(function() {
      return mediator.services.projectDb.fetchCentralProject(projectId);
    })["catch"](function(e) {
      if (e === 'max_retries' || e === 'not_connecting') {
        return W.resolve();
      } else if (e instanceof Exceptions.project_missing_on_server) {
        return W.resolve();
      } else {
        throw e;
      }
    });
  };

  Projects.prototype.getOrFetch = function(id) {
    var projectId, _ref;
    projectId = (_ref = this._redirects[id]) != null ? _ref : id;
    return this._fetchIfConnected(projectId).then((function(_this) {
      return function() {
        var project;
        project = _this.get(projectId);
        if (project != null) {
          return W.resolve(project);
        }
        if (__indexOf.call(mediator.user.getListedProjects(), projectId) >= 0) {
          return _this._fetchProject(projectId)().then(function(project) {
            _this.add(project);
            return project;
          });
        } else {
          return W.reject(new Exceptions.project_missing_on_server);
        }
      };
    })(this));
  };

  Projects.prototype.getFetchingStatus = function() {
    var notFetched, status, _ref;
    notFetched = _(this.uninitializedProjects).countBy();
    status = notFetched.waiting > 0 ? 'fetching' : notFetched.missing > 0 ? 'incomplete' : 'complete';
    return {
      status: status,
      total: mediator.user.getProjects().length,
      fetched: this.length,
      missing: (_ref = notFetched.missing) != null ? _ref : 0
    };
  };

  Projects.prototype._addRedirectsFor = function(model) {
    var redirect, redirects, _i, _len, _results;
    redirects = mediator.services.projectDb.projectsRedirectingTo(model);
    _results = [];
    for (_i = 0, _len = redirects.length; _i < _len; _i++) {
      redirect = redirects[_i];
      _results.push(this._redirects[redirect] = model.id);
    }
    return _results;
  };

  Projects.prototype._createProject = function(model, options, handler) {
    var _ref;
    options = (_ref = _.clone(options)) != null ? _ref : {};
    return this._prepareModel(model, options).save().then((function(_this) {
      return function(model) {
        _this.add(model);
        return handler(model.id).then(function() {
          return _this._assignOrderNumber(model);
        }).then(function() {
          return model.addCurrentUserToTeamMembers();
        }).then(function() {
          return model.createDocSectionsTemplate();
        }).then(function() {
          return model.fetch();
        });
      };
    })(this)).then(function(project) {
      if (typeof options.success === "function") {
        options.success(project);
      }
      return project;
    }, function(error) {
      return typeof options.error === "function" ? options.error(error) : void 0;
    });
  };

  Projects.prototype.create = function(model, options) {
    return this._createProject(model, options, function(projectId) {
      return mediator.user.addProject(projectId);
    });
  };

  Projects.prototype.createOrganizationProject = function(model, organizationId, members, options) {
    return this._createProject(model, options, function(projectId) {
      return mediator.user.addOrganizationProject(projectId, organizationId, members);
    });
  };

  Projects.prototype.getMaxOrderNumber = function() {
    return mediator.user.getProjectsMaxOrderNumber();
  };

  Projects.prototype._checkOrder = function() {
    var maxOrderNumber, orderNumber, ordersList, projectId, projects;
    maxOrderNumber = this.getMaxOrderNumber();
    projects = mediator.user.getProjectsWithOrderNumbers();
    ordersList = (function() {
      var _results;
      _results = [];
      for (projectId in projects) {
        orderNumber = projects[projectId];
        if (!(!_.isNumber(orderNumber))) {
          continue;
        }
        maxOrderNumber += 1;
        mediator.user.setProjectOrderNumber(projectId, maxOrderNumber);
        _results.push(maxOrderNumber);
      }
      return _results;
    })();
    if (ordersList.length) {
      return mediator.user.save().otherwise(mediator.dialogs.fatalError);
    }
  };

  Projects.prototype._assignOrderNumber = function(project) {
    var maxOrderNumber;
    maxOrderNumber = this.getMaxOrderNumber();
    mediator.user.setProjectOrderNumber(project.id, maxOrderNumber + 1);
    return mediator.user.save().otherwise(mediator.dialogs.fatalError);
  };

  Projects.prototype.comparator = function(p1, p2) {
    var o1, o2, revertOrderIfExactlyOneMissing, _ref, _ref1;
    o1 = (_ref = mediator.user.getProjectOrderNumber(p1.id)) != null ? _ref : -1;
    o2 = (_ref1 = mediator.user.getProjectOrderNumber(p2.id)) != null ? _ref1 : -1;
    revertOrderIfExactlyOneMissing = o1 * o2;
    return revertOrderIfExactlyOneMissing * (o1 - o2);
  };

  Projects.prototype._getItemOrderNumber = function(idx) {
    return mediator.user.getProjectOrderNumber(this.at(idx).id);
  };

  Projects.prototype._setItemOrderNumber = function(idx, value) {
    return mediator.user.setProjectOrderNumber(this.at(idx).id, value);
  };

  Projects.prototype._saveReorderedItems = function() {
    return mediator.user.save().otherwise(mediator.dialogs.fatalError);
  };

  return Projects;

})(OrderedCollection);
