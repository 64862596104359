CustomRenderMixin = require 'components/mixins/custom_render_mixin'
JudgementPanelVoiceDetails = require 'components/etd/panel_voice_etd/judgement_panel_voice_details'
PanelVoiceResponsesDetails = require 'components/etd/panel_voice_etd/panel_voice_responses_details'
OverarchingOpenPanelVoiceResults =
  require 'components/etd/panel_voice_etd/overarching_open_panel_voice_results'
Translation = require 'components/mixins/translation'
{
  getPanelVoiceAdminSummaryUpdater
  isSectionProposed
} = require 'lib/panel_voice_helper'
{ CellWithTitle, SectionRow, Card, CardContent } = ReactComponents
{
  VOTING_RESULTS_VIEW_TYPES
  OVERARCHING_TEMPLATES_WITH_OPEN_PV
  OVERARCHING_V2_TEMPLATES
} = require 'lib/etd_helper'

PanelVoiceResultsRow = createReactClass

  displayName: "PanelVoiceResultsRow"

  propTypes:
    activeJudgmentOptionBySection: PropTypes.instanceOf(Immutable.Map).isRequired,
    activeResultsViewTypeBySection: PropTypes.instanceOf(Immutable.Map).isRequired
    etdId: PropTypes.string.isRequired
    etdPart: PropTypes.string.isRequired
    etdTab: PropTypes.string.isRequired
    getAdminVotingSummary: PropTypes.func.isRequired,
    memberNamesMap: PropTypes.instanceOf(Immutable.Map).isRequired,
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map)
    renderMode: PropTypes.string.isRequired
    sectionId: PropTypes.string.isRequired
    votedEtdData: PropTypes.instanceOf(Immutable.Map).isRequired,
    votingResults: PropTypes.instanceOf(Immutable.Map).isRequired,

  mixins: [CustomRenderMixin, Translation('es:recommendations.table')]

  getDefaultProps: ->
    overarchingQuestionData: Immutable.Map()

  isProposed: ->
    {
      votedEtdData
      etdPart
      templateId
      votingResults
      overarchingQuestionData
      sectionId
    } = @props

    isProposedVoting = votingResults.get('votingOnProposedOptions')

    if etdPart is 'conclusions'
      isProposedVoting
    else
      isSectionProposed sectionId, votedEtdData, templateId, votingResults, overarchingQuestionData

  resultsFromOverarchingV2OpenVoting: ->
    { templateId, sectionId, etdPart } = @props

    sectionId isnt 'problem' and etdPart is 'judgements' and templateId in
      OVERARCHING_TEMPLATES_WITH_OPEN_PV.concat(OVERARCHING_V2_TEMPLATES) and not @isProposed()

  render: ->
    {
      activeJudgmentOptionBySection
      activeResultsViewTypeBySection
      criterion
      etdId
      etdPart
      etdTab
      getAdminVotingSummary
      memberNamesMap
      overarchingQuestionData
      renderMode
      responseColSpan
      sectionId
      templateId
      votedEtdData
      votingResults
    } = @props

    sectionVotingResults = votingResults.getIn ['sections', sectionId]
    votingStatus = votingResults.get 'status'
    closedOrTestVoting = votingStatus in ['closed', 'test']
    votingTitle = @i18n if sectionVotingResults?
        "#{votingStatus}_voting_results"
      else
        'voting_results_not_included_section'

    <SectionRow className='panel-voice-results'>
      {if sectionVotingResults?
        votingTimestamp = votingResults.get 'timestamp'
        adminVotingCommentUpdater = getPanelVoiceAdminSummaryUpdater sectionId,
          votingTimestamp, etdId
        votingResultsByOption = sectionVotingResults.groupBy (r) ->
          r.get 'selectedOption', '_all'
        activeOption = activeJudgmentOptionBySection.get sectionId, null
        resultsViewType = activeResultsViewTypeBySection.get sectionId,
          VOTING_RESULTS_VIEW_TYPES[0]

        if @resultsFromOverarchingV2OpenVoting()
          <CellWithTitle cellTitle={votingTitle} colSpan={responseColSpan + 1} key='judgement-details'>
            <OverarchingOpenPanelVoiceResults
              activeOption={activeOption}
              adminVotingCommentUpdater={adminVotingCommentUpdater}
              criterion={criterion}
              etdPart={etdPart}
              etdTab={etdTab}
              getAdminVotingSummary={getAdminVotingSummary}
              isProposedVoting={@isProposed()}
              memberNamesMap={memberNamesMap}
              membersVotedCount={sectionVotingResults.size}
              membersVotingCount={votingResults.get('membersSentTo').size}
              overarchingQuestionData={overarchingQuestionData}
              renderMode={renderMode}
              resultsViewType={resultsViewType}
              sectionId={sectionId}
              sectionVotingResults={sectionVotingResults}
              votedEtdData={votedEtdData}
              votingStatus={votingStatus}
              votingTimestamp={votingTimestamp}
            />
          </CellWithTitle>
        else
          <React.Fragment>
            <CellWithTitle cellTitle={votingTitle} colSpan={1} key='judgement-details'>
              <Card className="voting-details">
                <JudgementPanelVoiceDetails
                  activeOption={activeOption}
                  etdPart={etdPart}
                  isProposedVoting={@isProposed()}
                  membersVotedCount={sectionVotingResults.size}
                  membersVotingCount={votingResults.get('membersSentTo').size}
                  templateId={templateId}
                  renderMode={renderMode}
                  resultsViewType={resultsViewType}
                  sectionId={sectionId}
                  votedEtdData={votedEtdData}
                  votingResultsByOption={votingResultsByOption}
                  votingStatus={votingStatus}
                />
              </Card>
            </CellWithTitle>
            <td colSpan={responseColSpan} key='responses-details' className='responses-details-cell'>
              <Card className="voting-details">
                <PanelVoiceResponsesDetails
                  activeOption={activeOption}
                  adminVotingComment={getAdminVotingSummary votingTimestamp, sectionId}
                  etdPart={etdPart}
                  handleAdminVotingSummaryUpdate={adminVotingCommentUpdater}
                  memberNamesMap={memberNamesMap}
                  readOnly={etdTab is 'history'}
                  renderMode={renderMode}
                  sectionId={sectionId}
                  votedEtdData={votedEtdData}
                  votingResultsByOption={votingResultsByOption}
                />
              </Card>
            </td>
          </React.Fragment>
      else
        <CellWithTitle cellTitle={votingTitle} colSpan={responseColSpan + 1} />
      }
    </SectionRow>


module.exports = PanelVoiceResultsRow
