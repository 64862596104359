Modal = require 'components/common/modal'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
ReferencesInsert = require 'components/etd/references_insert'

ReferencesInsertModal = createReactClass
  displayName: 'ReferencesInsertModal'

  propTypes:
    onClose: PropTypes.func.isRequired
    onInsert: PropTypes.func.isRequired
    initialReferences: PropTypes.instanceOf(Immutable.Map)

  mixins: [
    CustomRenderMixin
    Translation()
  ]

  render: ->
    <Modal
      className='references-insert-modal'
      closeButton
      isOpen
      onClose={@props.onClose}
      onRequestClose={@props.onClose}
      title={@i18n 'references:insert_title'}
    >
      <ReferencesInsert
        onClose={@props.onClose}
        onInsert={@props.onInsert}
        initialReferences={@props.initialReferences}
      />
    </Modal>

module.exports = ReferencesInsertModal
