var AlgorithmsActions, errorHandler, generateStore;

AlgorithmsActions = require('actions/algorithms_actions');

errorHandler = require('lib/app_utils').errorHandler;

generateStore = require('stores/utils/generate_store');

module.exports = generateStore({
  name: 'AlgorithmsStore',
  initialState: Immutable.fromJS({
    _meta: {
      fetching: false
    },
    algorithms: Immutable.OrderedMap(),
    activeEditItemsIds: Immutable.List()
  }),
  boundActions: [AlgorithmsActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.setIn(['_meta', 'fetching'], true));
    },
    onFetchSuccess: function(docs) {
      var algorithms;
      algorithms = _.chain(docs).sortBy('orderNumber').map(function(doc) {
        return [doc._id, doc];
      }).value();
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['_meta', 'fetching'], false);
        return state.set('algorithms', Immutable.OrderedMap(algorithms));
      }));
    },
    onFetchError: function(err) {
      this.setState(this.state.setIn(['_meta', 'fetching'], false));
      return errorHandler(err);
    },
    onDbChange: function(_arg) {
      var deleted, doc, id;
      deleted = _arg.deleted, id = _arg.id, doc = _arg.doc;
      if (deleted) {
        return this.setState(this.state.deleteIn(['algorithms', id]));
      } else {
        return this.setState(this.state.setIn(['algorithms', id], doc));
      }
    },
    onToggleAlgorithmEdit: function(id) {
      return this.setState(this.state.update('activeEditItemsIds', function(list) {
        if (list.includes(id)) {
          return list.filter(function(activeId) {
            return activeId !== id;
          });
        } else {
          return list.push(id);
        }
      }));
    },
    onAddNewAlgorithm: function(id) {
      return this.setState(this.state.update('activeEditItemsIds', function(list) {
        return list.push(id);
      }));
    },
    getAlgorithms: function() {
      return this.state.get('algorithms');
    },
    getActiveEditItemsIds: function() {
      return this.state.get('activeEditItemsIds');
    },
    getAlgorithm: function(algorithmId) {
      return this.state.getIn(['algorithms', algorithmId]);
    },
    isFetching: function() {
      return this.state.getIn(['_meta', 'fetching']);
    }
  }
});
