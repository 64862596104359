SelectCustom = require 'components/common/select_custom'
StringField = require 'components/covid_extraction_form/string_field'
Translation = require 'components/mixins/translation'

AgeField = createReactClass
  displayName: 'AgeField'

  mixins: [Translation('mdg_tables:edit_form')]

  propTypes:
    onChange: PropTypes.func.isRequired
    value: PropTypes.shape(
      type: PropTypes.oneOf(['mean', 'range', 'other']).isRequired
      value: PropTypes.string
      valueFrom: PropTypes.string
      valueTo: PropTypes.string
    ).isRequired

  onChange: (field) -> (newValue) =>
    { onChange, value } = @props
    obj = {}
    obj[field] = newValue
    onChange _.extend {}, value, obj

  _getTypeOptions: ->
    _.map ['mean', 'range', 'other'], (value) =>
      { value, text: @i18n "values.#{value}" }

  render: ->
    { value: { type, value, valueFrom, valueTo }} = @props

    <div className="age-field">
      <div className="age-field__type">
        <SelectCustom
          onChange={@onChange('type')}
          options={@_getTypeOptions()}
          selected={type ? 'mean'}
        />
      </div>
      <div className="age-field__value">
        {if type is 'range'
          <div className="value__range">
            <div>{@i18n 'range.from'}</div>
            <StringField
              className="range__valueFrom"
              onChange={@onChange('valueFrom')}
              value={valueFrom}
            />
            <div>{@i18n 'range.to'}</div>
            <StringField
              className="range__valueTo"
              onChange={@onChange('valueTo')}
              value={valueTo}
            />
          </div>
        else
          <StringField
            className="value__value"
            onChange={@onChange('value')}
            value={value}
          />
        }
      </div>
    </div>

module.exports = AgeField
