var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "9", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">9 - "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.critical", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "8", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">8 - "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.critical", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "7", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">7 - "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.critical", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "6", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">6 - "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.important", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "5", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">5 - "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.important", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "4", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">4 - "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.important", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "3", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">3 - "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.not_important", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "2", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">2 - "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.not_important", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n  <li class=\"edit-option\" ";
  stack1 = ((helpers.currentIfEq || (depth0 && depth0.currentIfEq) || helperMissing).call(depth0, (depth0 != null ? depth0.importance : depth0), "1", {"name":"currentIfEq","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">1 - "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.not_important", {"name":"i18n","hash":{},"data":data})))
    + "</li>\n";
  stack1 = this.invokePartial(partials.clearOption, '  ', 'clearOption', (depth0 != null ? depth0.importance : depth0), undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.selectionList || (depth0 && depth0.selectionList) || helperMissing).call(depth0, "importance", "importance", {"name":"selectionList","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}