var DbDoc, TaskDocShape, tasksView;

TaskDocShape = require('lib/db_docs/doc_shapes/task_doc_shape');

DbDoc = require('lib/db_docs/db_doc');

tasksView = {
  map: function(doc) {
    if (doc.docType === 'event') {
      return emit(doc._id, doc);
    }
  }
};

module.exports = DbDoc(TaskDocShape, {
  dbSetup: {
    dbView: {
      name: 'tasks',
      view: tasksView
    }
  }
});
