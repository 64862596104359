ReferenceInsertControl = require './reference_insert_control'
EDMSInsertControl = require './edms_insert_control'
referenceNode = require './reference_node'
ReferenceNodeView = require './reference_node_view'
mediator = require 'mediator'

{ createInsertControl } = Epiditor

showEdmsInsertControl = -> mediator.services.switches.isOn('edmsFeature')

module.exports = (exportMode = false) ->
  {
    id: 'GdtInsertExtension'
    schema: {
      nodes: { reference: referenceNode }
    }
    toolbarControls: _.compact([
      createInsertControl(ReferenceInsertControl)
      showEdmsInsertControl() && createInsertControl(EDMSInsertControl)
    ])
    nodeViews: (portalsAPI) ->
      reference: (node, view, getPos) ->
        new ReferenceNodeView(node, view, getPos, portalsAPI, exportMode)
  }
