ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Input = require 'components/common/input'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'


ProjectEditModal = createReactClass
  displayName: 'ProjectEditModal'

  mixins: [ Translation() ]

  propTypes:
    projectName: PropTypes.string.isRequired
    customer: PropTypes.string
    onSave: PropTypes.func.isRequired
    onRequestClose: PropTypes.func.isRequired

  getInitialState: ->
    projectEditState:
      name: _.string.unescapeHTML(@props.projectName)
      customer: _.string.unescapeHTML(@props.customer)

  handleProjectEditFieldChange: (evt) ->
    fieldName = evt.target.getAttribute 'name'
    fieldValue = evt.target.value

    @setState projectEditState: R.assoc fieldName, fieldValue, @state.projectEditState

  handleApply: ->
    @props.onSave _.mapObject @state.projectEditState, _.string.escapeHTML

  render: ->
    { projectEditState } = @state

    <Modal
      isOpen={true}
      onRequestClose={@props.onRequestClose}
      title={@i18n 'actions.edit'}
      modalSize='standard'
    >
      <div className='project-edit-fields'>
        <div>
          <label>
            <span>{@i18n 'projects:list.name'}</span>
            <Input
              name='name'
              value={_.string.unescapeHTML(projectEditState.name ? '')}
              onChange={@handleProjectEditFieldChange}
            />
          </label>
        </div>
        <div>
          <label>
            <span>{@i18n 'projects:list.customer'}</span>
            <Input
              name='customer'
              value={_.string.unescapeHTML(projectEditState.customer ? '')}
              onChange={@handleProjectEditFieldChange}
            />
          </label>
        </div>
      </div>
      <ApplyCancelButtons
        onApply={@handleApply}
        onCancel={@props.onRequestClose}
        applyLabel={@i18n 'actions.save'}
      />
    </Modal>

module.exports = ProjectEditModal
