var DbHelper, Exceptions, ProjectChangelogService, W, callMethod, mediator, prepareDeleteConflictDocs, projectHelpers,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

Exceptions = require('lib/exceptions');

DbHelper = require('base/lib/db_helper');

mediator = require('mediator');

W = require('when');

projectHelpers = require('actions/async/projects');

prepareDeleteConflictDocs = require('base/lib/doc_helper').prepareDeleteConflictDocs;

callMethod = require('base/lib/utils').callMethod;

module.exports = ProjectChangelogService = (function() {
  function ProjectChangelogService() {
    this._recordChange = __bind(this._recordChange, this);
    mediator.subscribe('dbWrite', this._recordChange);
    this._timestampCache = {};
  }

  ProjectChangelogService.prototype.destroy = function() {
    return mediator.unsubscribe('dbWrite', this._recordChange);
  };

  ProjectChangelogService.prototype._changeWithinAccuracy = function(project, timestamp) {
    var previousTimestamp, _ref;
    previousTimestamp = (_ref = this._timestampCache[project]) != null ? _ref : 0;
    return timestamp - previousTimestamp > this.timestampAccuracy;
  };

  ProjectChangelogService.prototype._recordChange = function(dbName, eventName) {
    var db, timestamp;
    if (!this.projectDbService.isProjectDb(dbName)) {
      return W.resolve();
    }
    timestamp = this.clock.getTimestamp();
    if (!this._changeWithinAccuracy(dbName, timestamp)) {
      return W.resolve();
    }
    db = DbHelper.localReplica(dbName);
    return db.get('latestChange', {
      conflicts: true
    })["catch"](function(e) {
      if (e.status === 404) {
        return {
          _id: 'latestChange'
        };
      } else {
        throw e;
      }
    }).then((function(_this) {
      return function(doc) {
        var delConflictDocs;
        _this._timestampCache[dbName] = timestamp;
        doc.timestamp = timestamp;
        delConflictDocs = prepareDeleteConflictDocs(dbName, 'latestChange', doc._conflicts);
        return db.bulkDocs(delConflictDocs.concat(doc));
      };
    })(this))["catch"](function(e) {
      if (e.status !== 409) {
        throw e;
      }
    });
  };

  ProjectChangelogService.prototype.getChangelog = function(params) {
    if (this.replicationService.isConnecting()) {
      return W.promise((function(_this) {
        return function(resolve) {
          return mediator.subscribe('replication.finishedConnecting', function() {
            return resolve(_this._getChangelog(params));
          });
        };
      })(this));
    } else {
      return this._getChangelog(params);
    }
  };

  ProjectChangelogService.prototype._getChangelog = function(params) {
    var rawChangelog;
    if (params == null) {
      params = {};
    }
    rawChangelog = this.replicationService.isConnected() ? this._getChangelogFromServer(params) : this._getChangelogLocally(params);
    return W.map(rawChangelog, function(projectDetails) {
      var changeTime;
      changeTime = projectDetails.timestamp ? moment(projectDetails.timestamp).format('MMM Do, YYYY') : '';
      return _(projectDetails).extend({
        changeTime: changeTime
      });
    });
  };

  ProjectChangelogService.prototype._getChangelogFromServer = function(params) {
    var login, organizationIdQueryParam, url;
    if (params == null) {
      params = {};
    }
    login = mediator.user.get('name');
    organizationIdQueryParam = params.organizationId ? "?organizationId=" + params.organizationId : '';
    url = "" + (DbHelper.getBackendUrl()) + "/accounts/" + login + "/workspace" + organizationIdQueryParam;
    return W($.ajax(url, _.extend({}, params, {
      xhrFields: {
        withCredentials: true
      }
    }))).then((function(_this) {
      return function(projects) {
        var localParams, projectIdsThatNeedsMerging;
        projectIdsThatNeedsMerging = _.chain(projects).filter(function(_arg) {
          var mergeInClient;
          mergeInClient = _arg.mergeInClient;
          return Boolean(mergeInClient);
        }).pluck('id').value();
        if (!_.isEmpty(projectIdsThatNeedsMerging)) {
          localParams = _.extend({}, params, {
            ids: projectIdsThatNeedsMerging
          });
          return _this._getChangelogLocally(localParams).then(function(localProjects) {
            return _.map(projects, function(project) {
              if (project.mergeInClient) {
                return _.find(localProjects, function(localProject) {
                  return localProject.id === project.id;
                });
              } else {
                return project;
              }
            });
          });
        } else {
          return projects;
        }
      };
    })(this)).then(function(projects) {
      return _.chain(projects).compact().map(function(project) {
        return _.omit(project, 'mergeInClient');
      }).value();
    })["catch"]((function(_this) {
      return function() {
        return _this._getChangelogLocally(params);
      };
    })(this));
  };

  ProjectChangelogService.prototype._getChangelogLocally = function(params) {
    var filterFn, idsFilterFn, organizationFilterFn, projectModels;
    if (params == null) {
      params = {};
    }
    organizationFilterFn = params.organizationId ? R.compose(R.equals(params.organizationId), callMethod('get', 'organizationId')) : R.compose(R.isNil, callMethod('get', 'organizationId'));
    idsFilterFn = params.ids ? R.compose(R.includes(R.__, params.ids), R.prop('id')) : R.always(true);
    filterFn = R.both(organizationFilterFn, idsFilterFn);
    projectModels = mediator.projects.models.filter(filterFn);
    return W.map(projectModels, function(project) {
      var projectDetails;
      projectDetails = {
        id: project.id,
        name: project.get('name'),
        customer: project.get('customer'),
        timestamp: project.get('$timestamp'),
        rev_author: project.get('rev_author')
      };
      return projectHelpers.getProjectAccessRights(project.id).then(function(accessRights) {
        projectDetails.accessRights = accessRights;
        return project.getDocFor('latestChange', false).then(function(changeDoc) {
          return _(projectDetails).extend({
            timestamp: changeDoc.get('timestamp'),
            rev_author: changeDoc.get('rev_author')
          });
        }, function(e) {
          if (e instanceof Exceptions.document_missing) {
            return projectDetails;
          } else {
            throw e;
          }
        });
      });
    }).then(function(changelog) {
      var limit, limitedOrAll, sorted, _ref;
      sorted = _.sortBy(changelog, function(v) {
        var _ref;
        return -((_ref = v.timestamp) != null ? _ref : 0);
      });
      limit = params.limit;
      limitedOrAll = limit ? _.take(sorted, limit) : sorted;
      if (((_ref = limitedOrAll[0]) != null ? _ref.timestamp : void 0) != null) {
        return _(limitedOrAll).filter(function(v) {
          return v.timestamp != null;
        });
      } else {
        return limitedOrAll;
      }
    });
  };

  return ProjectChangelogService;

})();
