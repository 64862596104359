var GdtDocFieldType, OTHER, TypeValidationError, getOtherError, ifEqualsOtherOrElse, otherOrType, typeUtils;

GdtDocFieldType = require('lib/db_docs/field_types/FieldType');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

typeUtils = require('lib/db_docs/field_types/built_in_types').typeUtils;

OTHER = 'other';

getOtherError = function(value) {
  return new TypeValidationError({
    message: "Expected value to be `other`, but " + value + " was provided.",
    validation: 'OtherOrType'
  });
};

ifEqualsOtherOrElse = function(elseFn) {
  return R.ifElse(R.equals(OTHER), R.always(OTHER), elseFn);
};

otherOrType = function(BaseType) {
  if (!(BaseType instanceof GdtDocFieldType)) {
    throw new Error('BaseType parameter must be an instance of `Type`');
  }
  return new GdtDocFieldType({
    _typeMeta: {
      BaseType: BaseType
    },
    name: "OtherOrType(" + BaseType.name + ")",
    defaults: ifEqualsOtherOrElse(BaseType.defaults),
    check: typeUtils.checksSum(ifEqualsOtherOrElse(getOtherError), BaseType.check),
    parse: ifEqualsOtherOrElse(BaseType.parse),
    typeDefault: BaseType.typeDefault
  });
};

module.exports = otherOrType;
