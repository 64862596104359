tableCellClassName = 'gdt-flex-table-cell'
tableRowClassName = 'gdt-flex-table-row'
tableClassName = 'gdt-flex-table'

makeWrapper = (className) -> (props) ->
  classNameProp = classNames className, props.className

  <div {...props} className={classNameProp} />

FlexTableFixedCell = makeWrapper "#{tableCellClassName} fixed"
FlexTableCell = makeWrapper tableCellClassName
FlexTableRow = makeWrapper tableRowClassName

FlexTable = createReactClass
  displayName: 'FlexTable'

  _ref: (el) ->
    @tableEl = el

  onTableScroll: (evt) ->
    scrolled = evt.target.scrollLeft
    FixedCells = @tableEl.querySelectorAll ".#{tableCellClassName}.fixed"

    for $FixedCell in FixedCells
      $FixedCell.style.transform = "translate3d(#{scrolled}px, 0, 0)"

  componentDidMount: ->
    @tableEl.addEventListener 'scroll', @onTableScroll

  render: ->
    classNameProp = classNames tableClassName, @props.className
    <div className={classNameProp} ref={@_ref}>
      {@props.children}
    </div>

module.exports = { FlexTable, FlexTableRow, FlexTableCell, FlexTableFixedCell }
