# overriding default modal styles in a rediculous way due to this issue:
#   https://github.com/rackt/react-modal/issues/97

{ Modal } = ReactComponents

GdtModal = createReactClass
  displayName: 'GdtModal'

  propTypes:
    className: PropTypes.string,
    closeTimeoutMS: PropTypes.number,
    children: PropTypes.node,
    isOpen: PropTypes.bool.isRequired,
    modalSize: PropTypes.oneOf(['small', 'auto', 'standard', 'medium', 'large', 'full-size']),
    overlayClassName: PropTypes.string,
    position: PropTypes.oneOf(['top', 'centered']),
    scrollableContent: PropTypes.bool,
    title: PropTypes.string,
    closeButton: PropTypes.bool
    onClose: PropTypes.func
    parentSelector: PropTypes.func

  getParentSelector: ->
    return @props.parentSelector() if _.isFunction @props.parentSelector
    document.body

  render: ->

    <Modal {...@props}
      ariaHideApp={false}
      parentSelector={@getParentSelector}
      contentLabel={@props.contentLabel ? ""}
    >
      {if @props.closeButton
        <div className="modal-close" onClick={@props.onClose}></div>
      }
      {@props.children}
    </Modal>

module.exports = GdtModal
