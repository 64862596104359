var QuestionCoiView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/team/question_coi');

module.exports = QuestionCoiView = (function(_super) {
  __extends(QuestionCoiView, _super);

  function QuestionCoiView() {
    return QuestionCoiView.__super__.constructor.apply(this, arguments);
  }

  QuestionCoiView.prototype.template = template;

  QuestionCoiView.prototype.tagName = 'tr';

  QuestionCoiView.prototype.bindings = {
    '.question-short': 'questionShort'
  };

  QuestionCoiView.prototype.getTemplateData = function() {
    var data;
    data = QuestionCoiView.__super__.getTemplateData.apply(this, arguments);
    data.members = _(this.options.members).map((function(_this) {
      return function(id) {
        return {
          id: id
        };
      };
    })(this));
    return data;
  };

  QuestionCoiView.prototype._getMemberBinding = function(memberId) {
    var binding;
    binding = {};
    binding["td[data-member='" + memberId + "']"] = {
      observe: 'coi',
      updateMethod: 'html',
      onGet: (function(memberId) {
        return function(val) {
          if (val[memberId]) {
            return '<img src="images/checkbox.png">';
          }
        };
      })(memberId)
    };
    return binding;
  };

  QuestionCoiView.prototype.afterRender = function() {
    var memberBindings, memberId, _i, _len, _ref;
    QuestionCoiView.__super__.afterRender.apply(this, arguments);
    memberBindings = {};
    _ref = this.options.members;
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      memberId = _ref[_i];
      _(memberBindings).extend(this._getMemberBinding(memberId));
    }
    return this.stickit(this.model, _(this.bindings).extend(memberBindings));
  };

  QuestionCoiView.prototype.dispose = function() {
    this.unstickit();
    return QuestionCoiView.__super__.dispose.apply(this, arguments);
  };

  return QuestionCoiView;

})(View);
