ConnectStore = require 'components/enhancers/connect_store'
EtdActions = require 'actions/etd_actions'
EtdEditor = require 'components/etd/etd_editor'
EtdStore = require 'stores/etd_store'
Select = require 'components/common/select_custom'
{ ExtractionForm } = require 'components/covid_extraction_form/extraction_form'
{ MDG_RECOMMENDATION_FORM_ITEMS } = require 'lib/mdg_form_helper'
{ useI18n } = require 'lib/react_utils'

storeConnector =
  EtdStore: (Store) ->
    data: Store.getRecommendationMetadata MDG_RECOMMENDATION_FORM_ITEMS
    attachments: Store.getAttachments()

RecommendationSection = ({ attachments, data, editable, readOnly, renderMode, section, sectionId }) ->
  i18n = useI18n('es:recommendations.metadataExtractionForm')
  content = section.get('content', '')

  onChange = (field, value) ->
    EtdActions.updateRecommendationMetadata field, value

  onChangeRecommendation = (value) ->
    EtdActions.changeSelectedValue { sectionId, value }

  options = section.get('options')
  selectedOption = section.get('selectedOption', '')
  editable = if (renderMode is 'printout' or readOnly) then false else editable

  <div className="mdg-recommendation-section">
    <div className="mb-10">
      {if renderMode is 'printout'
        currentValue = options.find (opt) -> opt.get('value') is selectedOption
        currentValue?.get('text', '') ? ''
      else
        <Select
          className="mb-10"
          onChange={onChangeRecommendation}
          options={options.toJS()}
          selected={selectedOption}
        />
      }
      <EtdEditor
        attachments={attachments}
        cellId="mdgRecommendation#conclusions#content"
        content={content}
        editable={editable}
        forceTableExpand={readOnly or renderMode is 'printout'}
        noInsertOption
        onSaveAttachment={EtdActions.saveAttachment}
        onSaveContent={EtdActions.updateEditorContent}
        stickControlsTo={'mdg-recommendation-section'}
      />
    </div>
    <ExtractionForm
      data={data}
      form={MDG_RECOMMENDATION_FORM_ITEMS}
      i18n={i18n}
      onChange={onChange}
      renderMode={renderMode}
    />
  </div>

module.exports = ConnectStore RecommendationSection, EtdStore, storeConnector
