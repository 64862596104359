RobLevelIndicator = require 'components/rob/rob_level_indicator'
Translation = require 'components/mixins/translation'
Tooltip = require 'components/common/tooltip'
{ vancouverFormat } = require 'lib/references_utils'

BIAS_DOMAINS = [
  'randomSequenceGeneration'
  'allocationConcealment'
  'participantsBlinding'
  'outcomeBlinding'
  'incompleteData'
  'selectiveReporting'
  'other'
]

StudyLevelRoB = createReactClass
  displayName: 'StudyLevelRoB'

  mixins: [Translation('rob:bias_domains')]

  shortFormat: (ref) ->
    firstAuthor = ref.getIn(['authors', 0])
    authorNames = firstAuthor.get('firstNames').join(' ')
    authorLastName = firstAuthor.get('lastName')
    year = ref.get 'year'

    "#{authorLastName} #{authorNames}, #{year}"

  levelChanged: (prop, value, ref) ->
    ReferencesActions.saveReference(
      R.objOf(prop, value),
      ref.get('_id'),
      @props.references
    )

  renderReference: (ref) ->
    { outcomeId, onLevelChange } = @props

    <div className='reference' key={ref.get '_id'}>
      <div className='reference__name'>
        <Tooltip>
          <div className='ellipsized' title={vancouverFormat ref.toJS()}>
            {@shortFormat(ref)}
          </div>
        </Tooltip>
      </div>
      {BIAS_DOMAINS.map (domain) ->
        <div key={domain}>
          <RobLevelIndicator
            domain={domain}
            outcomeId={outcomeId}
            onChange={onLevelChange}
            reference={ref}
          />
        </div>
      }
    </div>

  render: ->
    <div className='assessment-table'>
      <div className='assessment-table__header'>
        {BIAS_DOMAINS.map (domain) =>
          <div key={domain}>{@i18n _.str.underscored domain}</div>
        }
      </div>
      <div className='assessment-table__references'>
        <div>
          {@props.references.map(@renderReference)}
        </div>
      </div>
    </div>

module.exports = StudyLevelRoB
