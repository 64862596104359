var EtdTemplateDefinitionsChange, Migration, W, etdHelper, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

etdHelper = require('lib/etd_helper');

migrationName = 'duplicated_questions_etd_data_fix';

module.exports = EtdTemplateDefinitionsChange = (function(_super) {
  __extends(EtdTemplateDefinitionsChange, _super);

  function EtdTemplateDefinitionsChange() {
    EtdTemplateDefinitionsChange.__super__.constructor.call(this, false);
  }

  EtdTemplateDefinitionsChange.prototype.up = function(project, colls) {
    var adapter, projectId, recommendationIds;
    EtdTemplateDefinitionsChange.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    recommendationIds = _.flatten(colls.questions.map(function(q) {
      return q.get('recommendationIds');
    }));
    return adapter.fetch(projectId, recommendationIds).then(function(_arg) {
      var rows, updatedEtdDocs;
      rows = _arg.rows;
      updatedEtdDocs = rows.map(function(row) {
        var updatedDoc;
        updatedDoc = etdHelper.ensuredInsertedOutcomesTablesQuestionId(row.doc);
        return _.extend(updatedDoc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedEtdDocs);
    })["catch"](function(err) {
      throw err;
    });
  };

  return EtdTemplateDefinitionsChange;

})(Migration);
