List = require 'components/common/list'
ContentEditable = require 'components/common/content_editable'

MultiComparisonsQuestionsList = ({ selectedQuestions, interventionsComparableDesc }) ->
  <div>
    <List className="multi-comparisons__questions-list" ordered={true}>
      {selectedQuestions.map (question) ->
        <li key={question.get 'questionId'}>
          { question.get 'question' }
        </li>
      }
    </List>
    <div>
      <p>{$.t 'mc:are_interventions_comparable'}</p>
      <ContentEditable
        className="multi-comparisons__textarea small"
        html={interventionsComparableDesc}
        autoFocus={false}
        contentEditable={false}
      />
    </div>
  </div>

MultiComparisonsQuestionsList.propTypes =
  selectedQuestions: PropTypes.instanceOf(Immutable.List)
  interventionsComparableDesc: PropTypes.string

module.exports = MultiComparisonsQuestionsList
