var UserActions, alt, mediator;

alt = require('alt');

mediator = require('mediator');

UserActions = (function() {
  function UserActions() {}

  UserActions.prototype.switchProjectReplication = function(projectId) {
    var projectInfo;
    projectInfo = _(mediator.user.get('projects')).findWhere({
      name: projectId
    });
    if (projectInfo.status === 'replication_on') {
      projectInfo.status = 'replication_off';
    } else {
      projectInfo.status = 'replication_on';
      mediator.publish('!replicateBgProject', projectId);
    }
    return mediator.user.save();
  };

  return UserActions;

})();

module.exports = alt.createActions(UserActions);
