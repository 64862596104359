CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
UserProjectDataActions = require 'actions/user_project_data_actions'
{ Button } = ReactComponents
{ string, instanceOf } = PropTypes

AssessmentCollapseButton = createReactClass

  displayName: "AssessmentCollapseButton"

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  propTypes:
    assessmentSections: instanceOf(Immutable.Map).isRequired,
    collapsedAssessmentSections: instanceOf(Immutable.Map).isRequired,
    etdId: string.isRequired,

  toggleAllSections: (collapse) ->  =>
    { assessmentSections, etdId } = @props
    UserProjectDataActions.toggleAssessmentSections etdId, assessmentSections, collapse

  render: ->
    { collapsedAssessmentSections, assessmentSections, etdId } = @props

    allCollapsed = assessmentSections.every (section, key) ->
      collapsedAssessmentSections.has(key) and collapsedAssessmentSections.get(key) is true

    buttonLabel = if allCollapsed then 'expand_all' else 'collapse_all'

    <Button
      className="assessment-section__toggle-all"
      onClick={@toggleAllSections(not allCollapsed)}
      type="round"
    >
      {@i18n "/translation:actions.#{buttonLabel}"}
    </Button>


module.exports = AssessmentCollapseButton
