var GeneralInformationDoc, callMethod, generalInformationDocId, generateActions, kebabCase, mediator;

callMethod = require('base/lib/utils').callMethod;

mediator = require('mediator');

generalInformationDocId = require('lib/doc_ids').GENERAL_INFORMATION;

GeneralInformationDoc = require('lib/db_docs/general_information_doc');

generateActions = require('actions/utils/generate_actions');

kebabCase = require('lodash').kebabCase;

module.exports = generateActions({
  autoGenerate: ['fetchSuccess', 'fetchError'],
  name: 'GeneralInformationActions',
  actions: {
    dbChange: function(data) {
      if (data.id !== generalInformationDocId) {
        return;
      }
      return this.dispatch(data);
    },
    fetch: function(_arg) {
      var projectId;
      projectId = _arg.dbId;
      this.dispatch();
      if (projectId == null) {
        projectId = mediator.project.id;
      }
      return GeneralInformationDoc.at(projectId).fetch()["catch"](function(err) {
        var title, _ref, _ref1;
        if (err.status !== 404) {
          throw err;
        }
        title = (_ref = (_ref1 = mediator.projects.get(projectId)) != null ? _ref1.get('name') : void 0) != null ? _ref : '';
        return GeneralInformationDoc.at(projectId).create({
          slug: kebabCase(title),
          title: title
        });
      }).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
    },
    updateField: function(field, value) {
      return GeneralInformationDoc.at(mediator.project.id).updateAsInstance(callMethod('updateField', field, value)).then(function() {
        if (field === 'intent') {
          return GeneralInformationDoc.at(mediator.project.id).updateAsInstance(callMethod('updateField', 'subIntent', null));
        }
      });
    }
  }
});
