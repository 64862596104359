CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

ApprovalDetails = createReactClass
  displayName: 'ApprovalDetails'

  propTypes:
    approvals: PropTypes.instanceOf(Immutable.Map).isRequired
    originalImportance: PropTypes.string.isRequired
    groupType: PropTypes.string

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  _getOriginalImportance: ->
    @i18n "outcome_#{_.str.underscored @props.originalImportance}"

  _getApprovalStats: ->
    approvalsCount = @_getApprovalsCount()
    return @i18n 'n_a' if approvalsCount is 0
    approvalsForCount = @_getApprovalAgreeCount()
    ratio = "#{Math.floor(approvalsForCount/approvalsCount*100)}%"

    "#{ratio} (#{approvalsForCount}/#{approvalsCount})"

  _getApprovalsCount: ->
    @props.approvals.size

  _getApprovalAgreeCount: ->
    @props.approvals.filter((v) -> v).size

  _hasGroupChanged: ->
    if @props.groupType is 'included'
      @props.originalImportance is 'notImportant'
    else
      @props.originalImportance in ['important', 'critical']

  render: ->
    priorityContainerClass = classNames 'original-importance-container',
      'group-changed': @_hasGroupChanged()

    <div className='approval-details'>
      <div className={priorityContainerClass}>
        <div className='original-importance'>
          {@i18n 'originally_suggested_importance'}:
          <span> {@_getOriginalImportance()}</span>
        </div>
      </div>
      <div className='approval-stats-container'>
        <div className='approval-stats'>{@i18n '../questions.approval.agreed'}:</div>
        <span>{@_getApprovalStats()}</span>
      </div>
    </div>

module.exports = ApprovalDetails
