var CoiSendNotificationsModalView, ModalView, coiService, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

template = require('views/templates/team/coi_send_notifications_modal');

coiService = require('lib/services/coi_service');

module.exports = CoiSendNotificationsModalView = (function(_super) {
  __extends(CoiSendNotificationsModalView, _super);

  function CoiSendNotificationsModalView() {
    return CoiSendNotificationsModalView.__super__.constructor.apply(this, arguments);
  }

  CoiSendNotificationsModalView.prototype.className = 'coi-send-notifications-modal';

  CoiSendNotificationsModalView.prototype.title = $.t('team:coi.send_coi_forms');

  CoiSendNotificationsModalView.prototype.template = template;

  CoiSendNotificationsModalView.prototype.initialize = function() {
    CoiSendNotificationsModalView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.cancel', this.dispose);
    return this.delegate('click', 'button.send', this._send);
  };

  CoiSendNotificationsModalView.prototype._send = function() {
    var action, sendToAll;
    sendToAll = this.$('input[name="coi-send-to-all"]').is(':checked');
    action = sendToAll ? this.parentView.sendNotificationsToAll : this.parentView.sendNewNotifications;
    return action.call(this.parentView).then((function(_this) {
      return function() {
        return _this.dispose();
      };
    })(this));
  };

  return CoiSendNotificationsModalView;

})(ModalView);
