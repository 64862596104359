var DocSectionsFromTemplateActions, DocSectionsFromTemplateStore, alt, brProjectDataSections, commonProjectDataSections, errorHandler, immutable, mediator, _getAllSubsectionsContent, _getChapters, _getChaptersOrder, _getReferenceIds, _ref;

alt = require('alt');

immutable = require('stores/utils/immutable');

errorHandler = require('lib/app_utils').errorHandler;

DocSectionsFromTemplateActions = require('actions/doc_sections_from_template_actions');

_ref = require('lib/document_sections_utils'), _getChapters = _ref.getChapters, _getChaptersOrder = _ref.getChaptersOrder, _getAllSubsectionsContent = _ref.getAllSubsectionsContent, _getReferenceIds = _ref.getReferenceIds;

mediator = require('mediator');

brProjectDataSections = [
  {
    key: 'title',
    label: 'Title'
  }, {
    key: 'number',
    label: 'No'
  }, {
    key: 'mess',
    label: 'Mess'
  }, {
    key: 'year',
    label: 'Ano'
  }, {
    key: 'info',
    label: 'Info',
    type: 'textarea'
  }, {
    key: 'preparationDistribution',
    label: 'Elaboração, distribuição e informações',
    type: 'textarea'
  }, {
    key: 'elaboration',
    label: 'Elaboração'
  }, {
    key: 'technicalCooperationArea',
    label: 'Instituição ou área tecnicacolaboradora'
  }, {
    key: 'supervision',
    label: 'Supervisao'
  }, {
    key: 'graphicDesign',
    label: 'Design gráfico'
  }, {
    key: 'normalization',
    label: 'Normalização'
  }
];

commonProjectDataSections = [
  {
    key: 'title',
    labelKey: 'title'
  }, {
    key: 'subtitle',
    labelKey: 'subtitle'
  }, {
    key: 'authors',
    labelKey: 'authors'
  }, {
    key: 'affiliations',
    labelKey: 'affiliations'
  }, {
    key: 'correspondingAuthor',
    labelKey: 'corresponding_author'
  }, {
    key: 'reviewGroup',
    labelKey: 'review_group'
  }, {
    key: 'acknowledgements',
    labelKey: 'acknowledgements'
  }
];

DocSectionsFromTemplateStore = (function() {
  function DocSectionsFromTemplateStore() {
    this.state = Immutable.fromJS({
      _meta: {
        fetching: false,
        editingSubchapter: null,
        addingSubchapterTo: null,
        deletingSubchapter: null,
        collapsedChapters: []
      },
      document: null,
      projectDataSections: mediator.services.switches.isOn('docSectionsTemplate') ? brProjectDataSections : commonProjectDataSections
    });
    this.bindActions(DocSectionsFromTemplateActions);
    this.exportPublicMethods({
      getDocument: this.getDocument,
      getTemplateId: this.getTemplateId,
      getChapters: this.getChapters,
      getChaptersOrder: this.getChaptersOrder,
      getAllSubsectionsContent: this.getAllSubsectionsContent,
      getReferenceIds: this.getReferenceIds,
      isFetching: this.isFetching,
      getAttachments: this.getAttachments,
      getEditingSubchapterData: this.getEditingSubchapterData,
      getAddingSubchapterTo: this.getAddingSubchapterTo,
      isDeletingSubchapter: this.isDeletingSubchapter,
      getCollapsedChapters: this.getCollapsedChapters,
      getProjectDataSections: this.getProjectDataSections,
      getProjectData: this.getProjectData
    });
  }

  DocSectionsFromTemplateStore.prototype._getChapterSubsectionsPath = function(chapterId) {
    return ['document', 'templateData', 'chapters', 'sections', chapterId, 'subsections'];
  };

  DocSectionsFromTemplateStore.prototype._getChapterSubsectionPath = function(chapterId, subsectionIdx) {
    return this._getChapterSubsectionsPath(chapterId).concat(subsectionIdx);
  };

  DocSectionsFromTemplateStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  DocSectionsFromTemplateStore.prototype.onFetchSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'fetching'], false);
      return state.set('document', Immutable.fromJS(data));
    }));
  };

  DocSectionsFromTemplateStore.prototype.onFetchFailure = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    if (err.status !== 404) {
      return errorHandler(err);
    }
  };

  DocSectionsFromTemplateStore.prototype.onDbChange = function(doc) {
    return this.setState(this.state.set('document', Immutable.fromJS(doc)));
  };

  DocSectionsFromTemplateStore.prototype.onSetAttachment = function(_arg) {
    var attachment, attachmentName, file;
    attachmentName = _arg.attachmentName, file = _arg.file;
    attachment = {
      content_type: file.type,
      data: file
    };
    return this.setState(this.state.setIn(['document', '_attachments', attachmentName], Immutable.fromJS(attachment)));
  };

  DocSectionsFromTemplateStore.prototype.onUpdateChapterSubsection = function(_arg) {
    var chapterId, content, path, subsectionIdx;
    chapterId = _arg.chapterId, subsectionIdx = _arg.subsectionIdx, content = _arg.content;
    path = this._getChapterSubsectionPath(chapterId, subsectionIdx).concat('content');
    return this.setState(this.state.setIn(path, content));
  };

  DocSectionsFromTemplateStore.prototype.onEditChapterSubsection = function(_arg) {
    var chapterId, subsectionIdx;
    chapterId = _arg.chapterId, subsectionIdx = _arg.subsectionIdx;
    return this.setState(this.state.setIn(['_meta', 'editingSubchapter'], Immutable.Map({
      chapterId: chapterId,
      subsectionIdx: subsectionIdx
    })));
  };

  DocSectionsFromTemplateStore.prototype.onCancelEditChapterSubsection = function() {
    return this.setState(this.state.setIn(['_meta', 'editingSubchapter'], null));
  };

  DocSectionsFromTemplateStore.prototype.onSaveEditingChapterSubsectionTitle = function(_arg) {
    var editingSubchapter, title, titleHidden;
    title = _arg.title, titleHidden = _arg.titleHidden;
    editingSubchapter = this.state.getIn(['_meta', 'editingSubchapter']);
    return this.setState(this.state.withMutations((function(_this) {
      return function(state) {
        var path;
        path = _this._getChapterSubsectionPath(editingSubchapter.get('chapterId'), editingSubchapter.get('subsectionIdx'));
        state.setIn(path.concat('title'), title);
        state.setIn(path.concat('titleHidden'), titleHidden);
        return state.setIn(['_meta', 'editingSubchapter'], null);
      };
    })(this)));
  };

  DocSectionsFromTemplateStore.prototype.onShowAddChapterSubsection = function(_arg) {
    var chapterId;
    chapterId = _arg.chapterId;
    return this.setState(this.state.setIn(['_meta', 'addingSubchapterTo'], chapterId));
  };

  DocSectionsFromTemplateStore.prototype.onHideAddChapterSubsection = function(_arg) {
    var chapterId;
    chapterId = _arg.chapterId;
    return this.setState(this.state.setIn(['_meta', 'addingSubchapterTo'], null));
  };

  DocSectionsFromTemplateStore.prototype.onSaveAddingChapterSubsection = function(_arg) {
    var title, titleHidden;
    title = _arg.title, titleHidden = _arg.titleHidden;
    return this.setState(this.state.withMutations((function(_this) {
      return function(state) {
        var path;
        path = _this._getChapterSubsectionsPath(_this.getAddingSubchapterTo());
        state.updateIn(path, function(subsections) {
          return subsections.push(Immutable.Map({
            title: title,
            titleHidden: titleHidden,
            content: ''
          }));
        });
        return state.setIn(['_meta', 'addingSubchapterTo'], null);
      };
    })(this)));
  };

  DocSectionsFromTemplateStore.prototype.onMarkChapterSubsectionToDelete = function(_arg) {
    var chapterId, subsectionIdx;
    chapterId = _arg.chapterId, subsectionIdx = _arg.subsectionIdx;
    return this.setState(this.state.setIn(['_meta', 'deletingSubchapter'], Immutable.Map({
      chapterId: chapterId,
      subsectionIdx: subsectionIdx
    })));
  };

  DocSectionsFromTemplateStore.prototype.onCancelChapterSubsectionDeletion = function() {
    return this.setState(this.state.setIn(['_meta', 'deletingSubchapter'], null));
  };

  DocSectionsFromTemplateStore.prototype.onConfirmChapterSubsectionDeletion = function() {
    var deletingSubchapter, path;
    deletingSubchapter = this.state.getIn(['_meta', 'deletingSubchapter']);
    path = this._getChapterSubsectionsPath(deletingSubchapter.get('chapterId'));
    return this.setState(this.state.withMutations(function(state) {
      state.updateIn(path, function(subsections) {
        return subsections["delete"](deletingSubchapter.get('subsectionIdx'));
      });
      return state.setIn(['_meta', 'deletingSubchapter'], null);
    }));
  };

  DocSectionsFromTemplateStore.prototype.onDuplicateSubchapter = function(_arg) {
    var chapterId, subToDuplicate, subsectionIdx, subsectionsPath;
    chapterId = _arg.chapterId, subsectionIdx = _arg.subsectionIdx;
    subsectionsPath = this._getChapterSubsectionsPath(chapterId);
    subToDuplicate = this.state.getIn(subsectionsPath.concat(subsectionIdx));
    return this.setState(this.state.updateIn(subsectionsPath, function(subsections) {
      return subsections.push(subToDuplicate);
    }));
  };

  DocSectionsFromTemplateStore.prototype.onToggleChapterExpand = function(_arg) {
    var chapterId, collapsedChapters, isCollapsed, updatedCollapsedChapters;
    chapterId = _arg.chapterId;
    collapsedChapters = this.getCollapsedChapters();
    isCollapsed = collapsedChapters.includes(chapterId);
    updatedCollapsedChapters = isCollapsed ? collapsedChapters.filterNot(R.equals(chapterId)) : collapsedChapters.push(chapterId);
    return this.setState(this.state.setIn(['_meta', 'collapsedChapters'], updatedCollapsedChapters));
  };

  DocSectionsFromTemplateStore.prototype.onCollapseAllChapters = function() {
    return this.setState(this.state.setIn(['_meta', 'collapsedChapters'], this.getChapters().keySeq().toList()));
  };

  DocSectionsFromTemplateStore.prototype.onExpandAllChapters = function() {
    return this.setState(this.state.setIn(['_meta', 'collapsedChapters'], Immutable.List()));
  };

  DocSectionsFromTemplateStore.prototype.onSetProjectData = function(projectData) {
    return this.setState(this.state.setIn(['document', 'projectData'], projectData));
  };

  DocSectionsFromTemplateStore.prototype.getDocument = function() {
    return this.state.get('document');
  };

  DocSectionsFromTemplateStore.prototype.getTemplateId = function() {
    return this.state.getIn(['document', 'templateDef', 'id']);
  };

  DocSectionsFromTemplateStore.prototype.getChapters = function() {
    return _getChapters(this.state.get('document'));
  };

  DocSectionsFromTemplateStore.prototype.getChaptersOrder = function() {
    return _getChaptersOrder(this.state.get('document'));
  };

  DocSectionsFromTemplateStore.prototype.getAllSubsectionsContent = function() {
    return _getAllSubsectionsContent(this.state.get('document'));
  };

  DocSectionsFromTemplateStore.prototype.getReferenceIds = function() {
    return _getReferenceIds(this.state.get('document'));
  };

  DocSectionsFromTemplateStore.prototype.getAttachments = function() {
    return this.state.getIn(['document', '_attachments']);
  };

  DocSectionsFromTemplateStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  DocSectionsFromTemplateStore.prototype.getEditingSubchapterData = function() {
    var chapter, _ref1;
    chapter = (_ref1 = this.getChapters()) != null ? _ref1.get(this.state.getIn(['_meta', 'editingSubchapter', 'chapterId'])) : void 0;
    if (!chapter) {
      return null;
    }
    return chapter.get('subsections').get(this.state.getIn(['_meta', 'editingSubchapter', 'subsectionIdx']));
  };

  DocSectionsFromTemplateStore.prototype.getAddingSubchapterTo = function() {
    return this.state.getIn(['_meta', 'addingSubchapterTo']);
  };

  DocSectionsFromTemplateStore.prototype.isDeletingSubchapter = function() {
    return this.state.getIn(['_meta', 'deletingSubchapter']) !== null;
  };

  DocSectionsFromTemplateStore.prototype.getCollapsedChapters = function() {
    return this.state.getIn(['_meta', 'collapsedChapters']);
  };

  DocSectionsFromTemplateStore.prototype.getProjectDataSections = function() {
    return this.state.get('projectDataSections');
  };

  DocSectionsFromTemplateStore.prototype.getProjectData = function() {
    return this.state.getIn(['document', 'projectData']) || Immutable.Map();
  };

  return DocSectionsFromTemplateStore;

})();

module.exports = alt.createStore(immutable(DocSectionsFromTemplateStore), 'DocSectionsFromTemplateStore');
