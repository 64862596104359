PanelMemberSelection = require 'components/panel_voice/panel_member_selection'
{ instanceOf, oneOfType } = PropTypes
Translation = require 'components/mixins/translation'

PanelMembersResend = createReactClass

  displayName: "PanelMembersResend"

  propTypes:
    members: oneOfType([instanceOf(Immutable.List), instanceOf(Immutable.OrderedMap)]).isRequired
    questions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)]
    )
    votingMembers: instanceOf(Immutable.List).isRequired

  mixins: [Translation("")]

  getInitialState: ->
    showAdditionalMembers: false
    selectedMembers: new Immutable.List()

  toggleAdditionalMembers: ->
    @setState showAdditionalMembers: not @state.showAdditionalMembers

  membersWithoutForms: ->
    @props.members.filterNot (member) =>
      @props.votingMembers.contains member.get("_id")

  membersWithForms: ->
    @props.members.filter (member) =>
      @props.votingMembers.contains member.get("_id")

  updateMembersList: (selectedMembers) ->
    @setState {selectedMembers}

  renderPanelMemberSelection: ->
    return null if @membersWithoutForms().size is 0
    buttonText = if @state.showAdditionalMembers
      'team:member.hide'
    else
      'team:member.add'
    <div>
      <button className="add-new-member pull-right" onClick={@toggleAdditionalMembers}>
        {@i18n buttonText}
      </button>
      <div className="clearfix" />
      {if @state.showAdditionalMembers
        <PanelMemberSelection
          members={@membersWithoutForms()}
          questions={@props.questions}
          selectedMembers={@state.selectedMembers}
          updateMembersList={@updateMembersList}
        />
      }
    </div>

  render: ->
    {members, votingMembers, questions} = @props
    <div className="panel-members-resend">
      {@membersWithForms().map (member) ->
        <div key={member.get("_id")} className="member-row">
          {member.get("lastName")} {member.get("givenNames")}
        </div>
      .toList()}
      {@renderPanelMemberSelection()}
    </div>

module.exports = PanelMembersResend
