var ETD_PARTS, ETD_PARTS_LETTERS_MAP, getBeforeLastVotedTimestamp, userPanelVoicePreferences;

ETD_PARTS = ['assessment', 'recommendation', 'conclusion'];

ETD_PARTS_LETTERS_MAP = {
  assessment: 'A',
  recommendation: 'R',
  conclusion: 'C'
};

userPanelVoicePreferences = ['onboardingProposalCount', 'showVotingResendInfo'];

getBeforeLastVotedTimestamp = function(stage) {
  var stageTimestamps;
  stageTimestamps = stage.get('roundTimestamps');
  if (stageTimestamps.size < 2) {
    return 'draft';
  } else {
    return stageTimestamps.get(stageTimestamps.size - 2);
  }
};

module.exports = {
  ETD_PARTS: ETD_PARTS,
  ETD_PARTS_LETTERS_MAP: ETD_PARTS_LETTERS_MAP,
  getBeforeLastVotedTimestamp: getBeforeLastVotedTimestamp,
  userPanelVoicePreferences: userPanelVoicePreferences
};
