var DbDoc, QUALITY_INDICATORS_PREFIX, QualityIndicator, qualityIndicatorsShape;

DbDoc = require('lib/db_docs/db_doc');

qualityIndicatorsShape = require('lib/db_docs/doc_shapes/quality_indicators_doc_shape');

QualityIndicator = require('lib/db_docs/field_types/quality_indicator_type');

QUALITY_INDICATORS_PREFIX = require('lib/doc_prefixes').qualityIdicatorss;

module.exports = DbDoc(qualityIndicatorsShape, {
  dbSetup: {
    idPrefix: QUALITY_INDICATORS_PREFIX
  },
  methods: {
    addQualityIndicator: function(qiId, defaults) {
      var qualityIndicator;
      if (defaults == null) {
        defaults = {};
      }
      qualityIndicator = QualityIndicator.defaults(_.extend({
        id: qiId
      }, defaults));
      return this.updateAt('qualityIndicators')(function(qualityIndicators) {
        qualityIndicators.push(qualityIndicator);
        return qualityIndicators;
      });
    },
    updateQualityIndicator: function(updatedQualityIndicator) {
      return this.updateAt('qualityIndicators')(function(qualityIndicators) {
        var indicatorIdx;
        indicatorIdx = _.findIndex(qualityIndicators, function(_arg) {
          var id;
          id = _arg.id;
          return id === updatedQualityIndicator.id;
        });
        if (indicatorIdx === -1) {
          return qualityIndicators;
        }
        qualityIndicators[indicatorIdx] = updatedQualityIndicator;
        return qualityIndicators;
      });
    },
    deleteQualityIndicator: function(qiId) {
      return this.updateAt('qualityIndicators')(function(qualityIndicators) {
        return _.reject(qualityIndicators, function(_arg) {
          var id;
          id = _arg.id;
          return id === qiId;
        });
      });
    },
    setRecommendationsIds: function(recIds) {
      return this.setAt('recommendationIds')(recIds);
    },
    changeView: function(newView) {
      return this.setAt('currentView')(newView);
    }
  }
});
