var ISoFTx;

module.exports = ISoFTx = (function() {
  function ISoFTx(container, data, onSavePlainLanguageStatement) {
    this.container = container;
    this.data = data;
    this.onSavePlainLanguageStatement = onSavePlainLanguageStatement;
    angular.module('isofTxApp').value('isofData', this.data);
    angular.module('isofTxApp').value('onSavePlainLanguageStatement', this.onSavePlainLanguageStatement);
    angular.element(this.container).ready((function(_this) {
      return function() {
        return angular.bootstrap(_this.container, ['isofTxApp']);
      };
    })(this));
  }

  return ISoFTx;

})();
