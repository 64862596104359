var DbHelper, ProjectTask, QUESTIONS_DB_VIEW, RemoveCoIProjectTask, W, projectsHelper,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ProjectTask = require('lib/project_tasks/task');

DbHelper = require('base/lib/db_helper');

projectsHelper = require('actions/async/projects');

QUESTIONS_DB_VIEW = require('lib/questions_helper').QUESTIONS_DB_VIEW;

W = require('when');

module.exports = RemoveCoIProjectTask = (function(_super) {
  __extends(RemoveCoIProjectTask, _super);

  function RemoveCoIProjectTask() {
    RemoveCoIProjectTask.__super__.constructor.call(this, 'remove_coi');
  }

  RemoveCoIProjectTask.prototype.unsafeRun = function(projectId) {
    var projectDb;
    projectDb = DbHelper.localReplica(projectId);
    return W.all([projectDb.get(projectId), projectsHelper.fetchProjectMembers(projectId), projectDb.query(QUESTIONS_DB_VIEW)]).then(function(_arg) {
      var members, metadoc, questionRows, questions, _ref, _ref1;
      metadoc = _arg[0], (_ref = _arg[1], members = _ref.members), (_ref1 = _arg[2], questionRows = _ref1.rows);
      questions = _.pluck(questionRows, 'value');
      delete metadoc["coiFormCreated"];
      delete metadoc["coiFormType"];
      _.forEach(members, function(member) {
        delete member["coiFormStatus"];
        return delete member["coiFormId"];
      });
      _.forEach(questions, function(question) {
        return delete question["coi"];
      });
      return projectDb.bulkDocs(_.union([metadoc], members, questions));
    }).then(function() {
      return {
        message: "CoI removed from project " + projectId
      };
    });
  };

  return RemoveCoIProjectTask;

})(ProjectTask);
