var rm5ExportConfig;

rm5ExportConfig = require('lib/rm5_export_config');

module.exports = {
  getCellFootnotes: function(cell, footnotes) {
    var attribute, cellFootnotes, cellPossibleAttributes, footnotesArr, _i, _len;
    cellPossibleAttributes = rm5ExportConfig.attributesMap[cell] || [];
    cellFootnotes = [];
    for (_i = 0, _len = cellPossibleAttributes.length; _i < _len; _i++) {
      attribute = cellPossibleAttributes[_i];
      footnotesArr = footnotes.find(function(_arg) {
        var key, _value;
        key = _arg[0], _value = _arg[1];
        return key === attribute;
      });
      if (footnotesArr) {
        footnotesArr = footnotesArr[1];
      }
      if (footnotesArr) {
        cellFootnotes = cellFootnotes.concat(footnotesArr);
      }
    }
    cellFootnotes.sort(function(a, b) {
      return a - b;
    });
    if (!_(cellFootnotes).isEmpty()) {
      return _(cellFootnotes).unique().join(' ');
    } else {
      return '';
    }
  }
};
