GdtEditorWithApply = Editor.EditorWithApply

SummaryEditor = createReactClass
  displayName: 'SummaryEditor'

  propTypes:
    onSave: PropTypes.func.isRequired,
    attrName: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])

  saveContent: ->
    @props.onSave
      attrName: @props.attrName
      value: @gdtEditor.getEditorContent()

  editorRef: (el) ->
    @gdtEditor = el?.gdtEditor

  onCancel: ->
    @gdtEditor.resetContent @props.value

  render: ->
    <div className="multi-comparisons__editor-container">
      <GdtEditorWithApply
        ref={@editorRef}
        stickControlsTo={'.multi-comparisons__textareas-box'}
        editorContent={@props.value}
        onCancel={@onCancel}
        onApply={@saveContent}
        onBlur={@saveContent}
      />
    </div>

module.exports = SummaryEditor
