module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": "หลากหลาย",
              "value": "varies"
            },
            {
              "text": "ไม่ทราบ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "การช่วยเหลือ/การจัดกระทำ เป็นที่ยอมรับต่อผู้มีส่วนได้ส่วนเสียหรือไม่",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ใช่",
                    "value": "yes"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "อาจจะไม่ใช่",
                    "value": "probably_no"
                  },
                  {
                    "text": "ไม่ใช่",
                    "value": "no"
                  }
                ],
                "question": "มีผู้ที่มีส่วนได้ส่วนเสียหลักที่จะไม่ยอมรับสัดส่วนของประโยชน์ โทษ และค่าใช้จ่ายหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ใช่",
                    "value": "yes"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "อาจจะไม่ใช่",
                    "value": "probably_no"
                  },
                  {
                    "text": "ไม่ใช่",
                    "value": "no"
                  }
                ],
                "question": "มีผู้มีส่วนได้ส่วนเสียหลักที่จะไม่ยอมรับค่ารักษาหรือผลลัพธ์ที่ไม่พึงประสงค์ที่เกิดขึ้นในระยะสั้นของผลลัพธ์ที่พึงประสงค์หรือประโยชน์ที่จะเกิดในอนาคตหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ใช่",
                    "value": "yes"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "อาจจะไม่ใช่",
                    "value": "probably_no"
                  },
                  {
                    "text": "ไม่ใช่",
                    "value": "no"
                  }
                ],
                "question": "มีผู้มีส่วนได้ส่วนเสียหลักที่อาจไม่เห็นด้วยกับคุณค่าที่มีต่อผลลัพธ์ที่พึงประสงค์และที่ไม่พึงประสงค์ (เนื่องจากอาจมีผลต่อตัวเอง หรือการรับรู้ว่าอาจมีผลที่สำคัญต่อผู้อื่น) หรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ใช่",
                    "value": "yes"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "อาจจะไม่ใช่",
                    "value": "probably_no"
                  },
                  {
                    "text": "ไม่",
                    "value": "no"
                  }
                ],
                "question": "การช่วยเหลือ (ทางเลือก) มีผลทางลบต่อความเป็นอิสระของบุคคลหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ใช่",
                    "value": "yes"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "อาจจะไม่",
                    "value": "probably_no"
                  },
                  {
                    "text": "ไม่",
                    "value": "no"
                  }
                ],
                "question": "มีผู้มีส่วนได้ส่วนเสียหลักที่อาจไม่รับรองการช่วยเหลือ (ทางเลือก) เนื่องมาจากประเด็นทางด้านจริยธรรม มากกว่าประเด็นของการมีผลต่อความเป็นอิสระของบุคคล (นั่นคือประเด็นที่เกี่ยวข้องหลักจริยศาสตร์ เช่น ความไม่เป็นอันตราย ความเป็นประโยชน์ หรือความเที่ยงธรรม) หรือไม่",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "ผู้มีส่วนได้ส่วนเสียมีแนวโน้มที่จะไม่ยอมรับถึงสัดส่วนของประโยชน์ โทษ และค่าใช้จ่ายที่เกิดขึ้น หรือ ค่าใช้จ่าย และผลลัพธ์ที่ไม่พึงประสงค์ในระยะสั้นของผลลัพธ์ที่พึงประสงค์ (ประโยชน์)ในอนาคตหรือไม่ ผู้มีส่วนได้ส่วนเสียไม่เห็นด้วยกับคุณค่าที่มีต่อผลลัพธ์ที่พึงประสงค์และที่ไม่พึงประสงค์หรือไม่ หรือ ไม่ยอมรับการรักษาหรือการช่วยเหลือเนื่องจากประเด็นของจริยธรรมหรือไม่",
          "options": [
            {
              "text": "ไม่",
              "value": "no"
            },
            {
              "text": "อาจจะไม่",
              "value": "probably_no"
            },
            {
              "text": "อาจจะใช่",
              "value": "probably_yes"
            },
            {
              "text": "ใช่",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "additionalSection": {
            "description": "Custom criterion description",
            "info": "Provide custom tooltip",
            "options": [
              {
                "text": "Judgement 1",
                "value": "option_1"
              },
              {
                "text": "Judgement 2",
                "value": "option_2"
              },
              {
                "text": "Judgement 3",
                "value": "option_3"
              },
              {
                "text": "Judgement 4",
                "value": "option_4"
              },
              {
                "text": "Judgement 5",
                "value": "option_5"
              }
            ],
            "additionalOptions": [
              {
                "text": "Additional judgement 1",
                "value": "additionalOption_1"
              },
              {
                "text": "Additional judgement 2",
                "value": "additionalOption_2"
              }
            ],
            "selectedOption": "",
            "decision": {
              "selectedOption": "",
              "comments": ""
            },
            "details": {
              "panelDiscussion": "",
              // questionsType is one of ['general', 'resources', 'outcomes']
              "questionsType": "general",
              "questions": []
            }
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": "หลากหลาย",
              "value": "varies"
            },
            {
              "text": "ไม่ทราบ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ความสมดุลย์ระหว่างผลลัพธ์ที่พึงประสงค์กับผลลัพธ์ที่ไม่พึงประสงค์สนับสนุนการช่วยเหลือ หรือ การเปรียบเทียบหรือไ่ม่",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "รายละเอียดของการตัดสินของเกณฑ์นี้ประกอบด้วยการพิจารณาถึงเกณฑ์ในแต่ละสี่ข้อก่อนหน้านี้"
              },
              {
                "additions": [
                  "ความน้อยลงในการให้คุณค่าของบุคคลต่อผลลัพธ์ที่จะเกิดขึ้นในอนาคต เปรียบเทียบกับผลลัพธ์ที่เกิดขึ้นปัจจุบัน (อัตราการลดลง)",
                  "ทัศนคติของบุคคลต่อผลลัพธ์ที่ไม่พึงประสงค์ (ต่อการหลีกเลี่ยงความเสี่ยง)",
                  "ทัศนคติของบุคคลต่อผลลัพธ์ที่พึงประสงค์  (ต่อการเผชิญกับความเสี่ยง)"
                ],
                "description": "นอกจากนี้ กรรมการยังต้องคำนึง (หากข้อมูลเกี่ยวข้อง) ถึงการที่สิ่งที่พิจารณาต่อไปนี้จะส่งอิทธิพลถึงความสมดุลย์ระหว่างผลลัพธ์ที่พึงประสงค์กับผลลัพธ์ที่ไม่พึงประสงค์"
              }
            ],
            "panelDiscussion": ""
          },
          "info": "ความสมดุลย์ระหว่างผลลัพธ์ที่พึงประสงค์กับผลลัพธ์ที่ไม่พึงประสงค์ การพิจารณาถึงการให้คุณค่าของบุคคลต่อผลลัพธ์หลัก ความแตกต่างระหว่างผลลัพธ์ที่พึงประสงค์กับที่ไม่พึงประสงค์ ความเชื่อมั่นในการประมาณค่าที่เกิดขึ้น อัตราการลดลง ความเสี่ยงที่จะเกิดขึ้นและความเสี่ยงที่จะลดลง",
          "options": [
            {
              "text": "สนับสนุนสิ่งเปรียบเทียบ",
              "value": "favors_comparison"
            },
            {
              "text": "อาจจะสนับสนุนสิ่งเปรียบเทียบ",
              "value": "probably_favors_comparison"
            },
            {
              "text": "ไม่สนับสนุนทั้งการช่วยเหลือ และสิ่งเปรียบเทียบ",
              "value": "doesnt_favor_any"
            },
            {
              "text": "อาจจะสนับสนุนการช่วยเหลือ",
              "value": "probably_favors_intervention"
            },
            {
              "text": "สนับสนุนการช่วยเหลือ",
              "value": "favors_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "ไม่มีการศึกษาที่สามารถนำเข้ามาพิจารณา",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ความมั่นใจในภาพรวมทั้งหมดของหลักฐานเชิงประจักษ์ในผลลัพธ์ของการทดสอบ",
          "details": {
            "panelDiscussion": ""
          },
          "info": "ข้อบ่งชี้จากงานวิจัยต่อผลลัพธ์ที่สำคัญทั้งหมดเป็นอย่างไร; นั่นคือความเป็นไปได้ที่ผลลัพธ์จะแตกต่างออกไปจากงานวิจัยที่พบที่อาจมีผลกระทบต่อการตัดสินใจเกี่ยวกับการจัดกระทำการวินิจฉัย",
          "options": [
            {
              "text": "ระดับต่ำมาก",
              "value": "very_low"
            },
            {
              "text": "ระดับต่ำ",
              "value": "low"
            },
            {
              "text": "ระดับปานกลาง",
              "value": "moderate"
            },
            {
              "text": "ระดับสูง",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "ไม่มีการศึกษาคัดเข้า",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ความมั่นใจในภาพรวมทั้งหมดของหลักฐานเชิงประจักษ์ของประสิทธิผลคืออะไร",
          "details": {
            "panelDiscussion": ""
          },
          "info": "มีข้อบ่งชี้ที่ดีจากงานวิจัยที่แสดงถึงประสิทธิผลที่เกิดขึ้นต่อผลลัพธ์ที่สำคัญทุกตัวหรือไม่ ได้แก่ ความเป็นไปได้ที่ประสิทธิผลจากงานวิจัยจะแสดงถึงความแตกต่างที่เพียงพอที่อาจส่งผลถึงการตัดสินใจต่อการจัดกระทำนั้นๆ",
          "options": [
            {
              "text": "ต่ำมาก",
              "value": "very_low"
            },
            {
              "text": "ต่ำ",
              "value": "low"
            },
            {
              "text": "ปานกลาง",
              "value": "moderate"
            },
            {
              "text": "สูง",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "ไม่มีการศึกษาคัดเข้า",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ความมั่นใจในภาพรวมทั้งหมดของหลักฐานเชิงประจักษ์ในผลลัพธ์ของการจัดการที่ชี้แนะโดยผลการทดสอบคืออะไร",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "ต่ำมาก",
              "value": "very_low"
            },
            {
              "text": "ต่ำ",
              "value": "low"
            },
            {
              "text": "ปานกลาง",
              "value": "moderate"
            },
            {
              "text": "สูง",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "ไม่มีการศึกษาคัดเข้า",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ความมั่นใจในหลักฐานเชิงประจักษ์ของทรัพยากรที่จำเป็น (ค่าใช้จ่าย)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "หลากหลาย",
                    "value": "varies"
                  },
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ไม่",
                    "value": "no"
                  },
                  {
                    "text": "อาจจะไม่",
                    "value": "probably_no"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "ใช่",
                    "value": "yes"
                  }
                ],
                "question": "ได้มีการระบุรายการที่สำคัญทั้งหมดของทรัพยากรที่ใช้ที่อาจแตกต่างออกไปจากที่เลือกไว้แล้วหรือไม่",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ความไม่มั่นใจที่สำคัญ",
                    "value": "important"
                  },
                  {
                    "text": "ความไม่มั่นใจที่อาจจะสำคัญ",
                    "value": "probably_important"
                  },
                  {
                    "text": "ความไม่มั่นใจที่อาจไม่สำคัญ",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "ความไม่มั่นใจที่ไม่สำคัญ",
                    "value": "no_important"
                  }
                ],
                "question": "ความเชื่อมั่นของค่าใช้จ่ายในรายการของทรัพยากรที่ใช้ที่แตกต่างออกไปจากที่เลือกไว้แล้ว",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ความคลาดเคลื่อนที่สำคัญ",
                    "value": "important"
                  },
                  {
                    "text": "ความคลาดเคลื่อนที่อาจจะสำคัญ",
                    "value": "probably_important"
                  },
                  {
                    "text": "ความคลาดเคลื่อนที่อาจไม่สำคัญ",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "ความคลาดเคลื่อนที่ไม่สำคัญ",
                    "value": "no_important"
                  }
                ],
                "question": "มีความคลาดเคลื่อนที่สำคัญของค่าใช้จ่ายในรายการของทรัพยากรที่ใช้ที่แตกต่างออกไปจากที่เลือกไว้แล้วหรือไม่",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "ความมั่นใจในหลักฐานเชิงประจักษ์ของทรัพยากรแต่ละอย่างที่นำมาใช้ (ได้แก่ ยา การเข้ารับการรักษาในโรงพยาบาล) และค่าใช้จ่าย เป็นอยางไร",
          "options": [
            {
              "text": "ต่ำมาก",
              "value": "very_low"
            },
            {
              "text": "ต่ำ",
              "value": "low"
            },
            {
              "text": "ปานกลาง",
              "value": "moderate"
            },
            {
              "text": "สูง",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "ไม่มีการศึกษาคัดเข้า",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ความมั่นใจในภาพรวมทั้งหมดในความถูกต้องของการทดสอบของหลักฐานเชิงประจักษ์",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "ต่ำมาก",
              "value": "very_low"
            },
            {
              "text": "ต่ำ",
              "value": "low"
            },
            {
              "text": "ปานกลาง",
              "value": "moderate"
            },
            {
              "text": "สูง",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "ไม่มีการศึกษาที่สามารถคัดเข้ามาพิจารณา",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ความมั่นใจในภาพรวมทั้งหมดของหลักฐานเชิงประจักษ์ ต่อ ประโยชน์โดยตรงหรือประโยชน์ที่สำคัญ ผลลัพธ์ที่ไม่พึงประสงค์ หรือ ภาระของการทดสอบ",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "ต่ำมาก",
              "value": "very_low"
            },
            {
              "text": "ต่ำ",
              "value": "low"
            },
            {
              "text": "ปานกลาง",
              "value": "moderate"
            },
            {
              "text": "สูง",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "ไม่มีการศึกษาคัดเข้า",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ความเชื่อมั่นระหว่างผลการทดสอบและการจัดการการตัดสินใจเป็นอย่างไร",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "ต่ำมาก",
              "value": "very_low"
            },
            {
              "text": "ต่ำ",
              "value": "low"
            },
            {
              "text": "ปานกลาง",
              "value": "moderate"
            },
            {
              "text": "สูง",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": "หลากหลาย",
              "value": "varies"
            },
            {
              "text": "ไม่มีการศึกษาคัดเข้า",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ต้นทุนประสิทธิผลของการช่วยเหลือ สนับสนุนการช่วยเหลือหรือสิ่งเปรียบเทียบ",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "ไม่มีการศึกษาคัดเข้า",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "ไม่",
                    "value": "no"
                  },
                  {
                    "text": "อาจจะไม่",
                    "value": "probably_no"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "ใช่",
                    "value": "yes"
                  }
                ],
                "question": "อัตราต้นทุนประสิทธิผลไวต่อการวิเคราะห์ความไวแบบทางเดียวหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่มีการศึกษาคัดเข้า",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "ไม่",
                    "value": "no"
                  },
                  {
                    "text": "อาจจะไม่",
                    "value": "probably_no"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "ใช่",
                    "value": "yes"
                  }
                ],
                "question": "อัตราต้นทุนประสิทธิผลไวต่อการวิเคราะห์ความไวในหลายๆปัจจัยหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่มีการศึกษาคัดเข้า",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "ไม่",
                    "value": "no"
                  },
                  {
                    "text": "มีข้อจำกัดร้ายแรงมาก",
                    "value": "very_serious"
                  },
                  {
                    "text": "มีข้อจำกัดร้ายแรง",
                    "value": "serious"
                  },
                  {
                    "text": "ไม่มีข้อจำกัด",
                    "value": "no_serious"
                  }
                ],
                "question": "การประเมินทางเศรษฐศาสตร์ในการประมาณค่าต้นทุนประสิทธิผลมีความนาเชื่อถือหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่มีการศึกษาคัดเข้า",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "ไม่",
                    "value": "no"
                  },
                  {
                    "text": "มีข้อจำกัดร้ายแรงมาก",
                    "value": "very_serious"
                  },
                  {
                    "text": "มีข้อจำกัดร้ายแรง",
                    "value": "serious"
                  },
                  {
                    "text": "ไม่มีข้อจำกัดร้ายแรง",
                    "value": "no_serious"
                  }
                ],
                "question": "การประเมินทางเศรษฐศาสตร์ในการประมาณค่าต้นทุนประสิทธิผลอยู่บนพื้นฐานที่นำไปประยุกต์ใช้กับสถานที่ที่สนใจหรือไม่",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "การช่วยเหลือมีประสิทธิผลต้นทุนหรือไ่ม่ หากคำนึงถึงความไม่แน่นอนหรือความคลาดเคลื่อนของค่าใช้จ่าย ความไม่แน่นอนหรือความคลาดเคลื่อนของผลประโยชน์สุทธิ การวิเคราะห์ความไว และความเชื่อมั่น และการประยุกต์ใช้การประเมินทางเศรษฐศาสตร์",
          "options": [
            {
              "text": "สนับสนุนสิ่งเปรียบเทียบ",
              "value": "favors_comparison"
            },
            {
              "text": "อาจจะสนับสนุนสิ่งเปรียบเทียบ",
              "value": "probably_favors_comparison"
            },
            {
              "text": "ไม่สนับสนุนทั้งการช่วยเหลือ และสิ่งเปรียบเทียบ",
              "value": "doesnt_favor_any"
            },
            {
              "text": "อาจจะสนับสนุนการช่วยเหลือ",
              "value": "probably_favors_intervention"
            },
            {
              "text": "สนับสนุนการช่วยเหลือ",
              "value": "favors_intervention"
            }
          ],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": "แปรปรวน",
              "value": "varies"
            },
            {
              "text": "ไม่ทราบ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ผลกระทบที่พึงประสงค์ที่คาดไว้ มีความยั่งยืนอย่างไร",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "แปรปรวน",
                    "value": "varies"
                  },
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "น้อยมาก",
                    "value": "trivial"
                  },
                  {
                    "text": "น้อย",
                    "value": "small"
                  },
                  {
                    "text": "ปานกลาง",
                    "value": "moderate"
                  },
                  {
                    "text": "ขนาดใหญ่",
                    "value": "large"
                  }
                ],
                "question": "ความยั่งยืนของผลกระทบที่คาดไว้ (ความแตกต่าง) ในผลลัพธ์ที่สำคัญแต่ละตัว ในกรณีที่มีผลกระทบที่พึงประสงค์เป็นอย่างไร",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "การคำนึงถึงผลลัพธ์ที่พึงประสงค์ของการช่วยเหลือหรือการจัดกระทำในผลลัพธ์ที่สำคัญมีมากแค่ไหน (มีการให้คุณค่ามากแค่ไหน) และขนาดของผลสัมพัทธ์ (ความเป็นไปได้ของประสบการณ์ในความเป็นประโยชน์ หรือ ที่จะพัฒนาขึ้นจากเดิมของแต่ละปัจเจกบุคคลมีมากแค่ไหน)",
          "options": [
            {
              "text": "น้อยมาก",
              "value": "trivial"
            },
            {
              "text": "น้อย",
              "value": "small"
            },
            {
              "text": "ปานกลาง",
              "value": "moderate"
            },
            {
              "text": "ขนาดใหญ่",
              "value": "large"
            }
          ],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": "หลากหลาย",
              "value": "varies"
            },
            {
              "text": "ไม่ทราบ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ผลกระทบต่อความเท่าเทียมกันทางสุขภาพคืออะไร?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ใช่",
                    "value": "yes"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "อาจจะไม่ใช่",
                    "value": "probably_no"
                  },
                  {
                    "text": "ไม่",
                    "value": "no"
                  }
                ],
                "question": "มีกลุ่มคนหรือพื้นที่ ที่อาจจะเสียผลประโยชน์ที่เกี่ยวข้องกับปัญหาหรือทางเลือกที่พิจารณาหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ใช่",
                    "value": "yes"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "อาจจะไม่ใช่",
                    "value": "probably_no"
                  },
                  {
                    "text": "ไม่",
                    "value": "no"
                  }
                ],
                "question": "มีเหตุผลที่เป็นไปได้ในการอธิบายถึงความแตกต่างของประสิทธิผลเชิงสัมพัทธ์ของทางเลือกสำหรับกลุ่มผู้ด้อยโอกาส หรือ พื้นที่ที่ด้อยโอกาสหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ใช่",
                    "value": "yes"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "อาจจะไม่ใช่",
                    "value": "probably_no"
                  },
                  {
                    "text": "ไม่",
                    "value": "no"
                  }
                ],
                "question": "มีความแตกต่างของลักษณะเงื่อนไขเริ่มต้นทั้งกลุ่มหรือพื้นที่ที่มีผลต่อประสิทธิผลที่แท้จริงของทางเลือก หรือ ความสำคัญของปัญหาสำหรับกลุ่มผู้ด้อยโอกาส หรือพื้นที่ที่ด้อยโอกาสหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ใช่",
                    "value": "yes"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "อาจจะไม่ใช่",
                    "value": "probably_no"
                  },
                  {
                    "text": "ไม่",
                    "value": "no"
                  }
                ],
                "question": "มีการพิจารณาถึงประเด็นการนำไปใช้ของทางเลือกหรือการจัดกระทำเพื่อยืนยันถึงการลดลงของความเหลื่อมล้ำ หรือความไม่เท่าเทียมกัน หรือหากเป็นไปไ้ด้ ไม่เพิ่มความเหลื่อมล้ำ หรือความไม่เท่าเทียมกัน หรือไม่",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "มีสาเหตุหรือความน่าจะเป็นที่มีต่อความแตกต่างในประสิทธิผลของการช่วยเหลือหรือการจัดกระทำสำหรับกลุ่มผู้ด้อยโอกาส หรือ มีลักษณะเงื่อนไขแรกเริ่มที่มีความแตกต่างของกลุ่มผู้ด้อยโอกาสที่มีผลต่อประสิทธิผลสุทธิของการช่วยเหลือหรือการจัดกระทำ หรือต่อปัญหที่สำคัญหรือไม่",
          "options": [
            {
              "text": "ลดลง",
              "value": "reduced"
            },
            {
              "text": "อาจจะลดลง",
              "value": "probably_reduced"
            },
            {
              "text": "อาจจะไม่มีผลกระทบ",
              "value": "probably_no_impact"
            },
            {
              "text": "อาจจะเพิ่มขึ้น",
              "value": "probably_increased"
            },
            {
              "text": "เพิ่มขึ้น",
              "value": "increased"
            }
          ],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": "หลากหลาย",
              "value": "varies"
            },
            {
              "text": "ไม่ทราบ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "การช่วยเหลือมีความเป็นไปได้ในการนำไปใช้หรือไม่",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "หลากหลาย",
                    "value": "varies"
                  },
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ไม่",
                    "value": "no"
                  },
                  {
                    "text": "อาจจะไม่",
                    "value": "probably_no"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "ใช่",
                    "value": "yes"
                  }
                ],
                "question": "การช่วยเหลือ (ทางเลือก) ยั่งยืนหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "หลากหลาย",
                    "value": "varies"
                  },
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ไม่",
                    "value": "no"
                  },
                  {
                    "text": "อาจจะไม่",
                    "value": "probably_no"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "ใช่",
                    "value": "yes"
                  }
                ],
                "question": "มีอุปสรรคที่สำคัญที่ทำให้ความเป็นไปได้ในการนำการช่วยเหลือ (ทางเลือก) ไปใช้มีข้อจำกัด หรือ ต้องมีการพิจารณาถึงสิ่งเหลาน้ีเมื่อมีการนำไปปฏิบัติ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "มีความเป็นไปได้หรือไม่ในการยืดการใช้การช่วยเหลือที่ได้รับการประเมินแล้วต่อไปและระบุถึงอุปสรรคที่เป็นไปได้ต่อการใช้",
          "options": [
            {
              "text": "ไม่",
              "value": "no"
            },
            {
              "text": "อาจจะไม่",
              "value": "probably_no"
            },
            {
              "text": "อาจจะใช่",
              "value": "probably_yes"
            },
            {
              "text": "ใช่",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problem": {
          "additionalOptions": [
            {
              "text": "แปรปรวน",
              "value": "varies"
            },
            {
              "text": "ไม่ทราบ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ปัญหานั้นสำคัญเป็นลำดับแรกหรือไม่",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "แปรปรวน",
                    "value": "varies"
                  },
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ไม่",
                    "value": "no"
                  },
                  {
                    "text": "อาจจะไม่",
                    "value": "probably_no"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "ใช่",
                    "value": "yes"
                  }
                ],
                "question": "ผลกระทบของปัญหานั้นรุนแรงหรือไม่ (ได้แก่ รุนแรง หรือสำคัญต่อ ผลประโยชน์ที่จะเกิดขึ้น หรือต่อเงินออม)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "แปรปรวน",
                    "value": "varies"
                  },
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ไม่",
                    "value": "no"
                  },
                  {
                    "text": "อาจจะไม่",
                    "value": "probably_no"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "ใช่",
                    "value": "yes"
                  }
                ],
                "question": "เป็นปัญหาเร่งด่วนหรือไม่",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "แปรปรวน",
                    "value": "varies"
                  },
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ไม่",
                    "value": "no"
                  },
                  {
                    "text": "อาจจะไม่",
                    "value": "probably_no"
                  },
                  {
                    "text": "อาจจะใช่",
                    "value": "probably_yes"
                  },
                  {
                    "text": "ใช่",
                    "value": "yes"
                  }
                ],
                "question": "มันได้รับการยอมรับว่าเป็นปัญหาที่สำคัญอันดับแรกหรือไม่ (เช่น บนพื้นฐานของการตัดสินทางการเมือง หรืองทางนโยบาย)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "ยิ่งปัญหามีความรุนแรงหรือมีความเร่งด่วนมากเท่าใด ยิ่งต้องหาทางเลือกเพื่อแก้ไขปัญหานั้นเป็นอันดับแรก",
          "options": [
            {
              "text": "ไม่",
              "value": "no"
            },
            {
              "text": "อาจจะไม่",
              "value": "probably_no"
            },
            {
              "text": "อาจจะใช่",
              "value": "probably_yes"
            },
            {
              "text": "ใช่",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": "หลากหลาย",
              "value": "varies"
            },
            {
              "text": "ไม่ทราบ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ตวามต้องการทรัพยากร (ราคา)มีมากขนาดไหน",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "หลากหลาย",
                    "value": "varies"
                  },
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "น้อยมาก",
                    "value": "trivial"
                  },
                  {
                    "text": "น้อย",
                    "value": "small"
                  },
                  {
                    "text": "ปานกลาง",
                    "value": "moderate"
                  },
                  {
                    "text": "ขนาดใหญ่",
                    "value": "large"
                  }
                ],
                "question": "ความแตกต่างในแต่ละรายการของทรัพยากรหากสามารถลดการใช้ทรัพยากรน้อยลง",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "หลากหลาย",
                    "value": "varies"
                  },
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ขนาดใหญ่",
                    "value": "large"
                  },
                  {
                    "text": "ปานกลาง",
                    "value": "moderate"
                  },
                  {
                    "text": "ขนาดเล็ก",
                    "value": "small"
                  },
                  {
                    "text": "น้อยมาก",
                    "value": "trivial"
                  }
                ],
                "question": "ความแตกต่างในแต่ละรายการของทรัพยากรที่ใช้หากต้องใช้ทรัพยากรมากขึ้น",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "ความแตกต่างของค่าใช้จ่ายในการใช้ทรัพยากร ระหว่างการช่วยเหลือ และสิ่งเปรียบเทียบ",
          "options": [
            {
              "text": "มีค่าใช้จ่ายเกิดขึ้นมาก",
              "value": "large_costs"
            },
            {
              "text": "มีค่าใช้จ่ายเกิดขึ้นปานกลาง",
              "value": "moderate_costs"
            },
            {
              "text": "เล็กน้อย และประหยัด",
              "value": "negligible_costs_savings"
            },
            {
              "text": "ประหยัดปานกลาง",
              "value": "moderate_savings"
            },
            {
              "text": "ประหยัดมาก",
              "value": "large_savings"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": "คลาดเคลื่อน",
              "value": "varies"
            },
            {
              "text": "ไม่ทราบ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ความถูกต้องของการทดสอบเป็นอย่างไร",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "ไม่ถูกต้องมากๆ",
              "value": "very_inaccurate"
            },
            {
              "text": "ไม่ถูกต้อง",
              "value": "inaccurate"
            },
            {
              "text": "ถูกต้อง",
              "value": "accurate"
            },
            {
              "text": "ถูกต้องมากๆ",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": "แปรปรวน",
              "value": "varies"
            },
            {
              "text": "ไม่ทราบ",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ผลกระทบที่พึงประสงค์ที่คาดไว้มีความยั่งยืนหรือไม่อย่างไร ",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "แปรปรวน",
                    "value": "varies"
                  },
                  {
                    "text": "ไม่ทราบ",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ขนาดใหญ่",
                    "value": "large"
                  },
                  {
                    "text": "ปานกลาง",
                    "value": "moderate"
                  },
                  {
                    "text": "ขนาดเล็ก",
                    "value": "small"
                  },
                  {
                    "text": "น้อยมาก",
                    "value": "trivial"
                  }
                ],
                "question": "ความยั่งยืนของผลกระทบที่พึงประสงค์ (ความแตกต่าง) ในผลลัพธ์หลักที่สำคัญ ในกรณีที่มีผลกระทบที่ไม่พึงประสงค์ด้วย",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "การคำนึงถึงผลลัพธ์ที่ไม่พึงประสงค์ของการช่วยเหลือหรือการจัดกระทำในผลลัพธ์ที่สำคัญมีมากแค่ไหน (มีการให้คุณค่ามากแค่ไหน) และขนาดของผลสัมพัทธ์ (ความเป็นไปได้ของประสบการณ์ในความเป็นประโยชน์ หรือ การพัฒนาขึ้นจากเดิมที่บุคคลประสบมีมากแค่ไหน)",
          "options": [
            {
              "text": "ขนาดใหญ่",
              "value": "large"
            },
            {
              "text": "ปานกลาง",
              "value": "moderate"
            },
            {
              "text": "ขนาดเล็ก",
              "value": "small"
            },
            {
              "text": "น้อยมาก",
              "value": "trivial"
            }
          ],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "มีความไม่แน่ใจที่สำคัญในคุณค่าของผลลัพธ์หลัก หรือความแปรปรวนของการให้คุณค่าต่อผลลัพธ์หลักหรือไม่",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "ความไม่เชื่อมั่นที่สำคัญ",
                    "value": "important"
                  },
                  {
                    "text": "ความไม่เชื่อมั่นที่อาจจะสำคัญ",
                    "value": "probably_important"
                  },
                  {
                    "text": "ความไม่เชื่อมั่นที่อาจไม่สำคัญ",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "ความไม่เชื่อมั่นที่ไม่สำคัญ",
                    "value": "no_important"
                  }
                ],
                "question": "มีความไม่เชื่อมั่นที่สำคัญเกี่ยวกับการที่บุคคลให้คุณค่าต่อผลลัพธ์หลักหรือไม่",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "คุณค่าต่อผลลัพธ์หลักแต่ละตัวมากขนาดไหน? ความไม่แน่ใจเรื่องคุณค่าของผลลัพธ์ในแต่ละตัว หรือ ความแปรปรวนของความแตกต่างของคุณค่าของผลลัพธ์แต่ละตัวมีมากพอที่จะส่งผลถึงการตัดสินใจที่แตกต่างออกไปหรือไม่",
          "options": [
            {
              "text": "ความไม่เชื่อมั่นหรือความแปรปรวน ที่สำคัญ",
              "value": "important_uncertainty"
            },
            {
              "text": "ความไม่เชื่อมั่น หรือความแปรปรวนที่อาจจะสำคัญ",
              "value": "possible_important"
            },
            {
              "text": "ความไม่เชื่อมั่นหรือความแปรปรวน ที่อาจไม่สำคัญ",
              "value": "probably_no_important"
            },
            {
              "text": "ความไม่เชื่อมั่นหรือความแปรปรวน ที่ไม่สำคัญ",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "การยอมรับ",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "Custom criterion",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "สมดุลของผลลัพธ์",
          "researchEvidenceId": "balanceOfEffects"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "ความมั่นใจในผลลัพธ์",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "ความมั่นใจในหลักฐานเชิงประจักษ์",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "ความมั่นใจในหลักฐานเชิงประจักษ์ของการจัดการผลลัพธ์",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "ความมั่นใจในหลักฐานเชิงประจักษ์ของแหล่งข้อมูลที่จำเป็น",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "ความมั่นใจในความถูกต้องของการทดสอบของหลักฐานเชิงประจักษ์",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "ความมั่นใจในผลลัพธ์ของหลักฐานเชิงประจักษ์",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "ความมั่นใจในหลักฐานเชิงประจักษ์ของผลการทดสอบ/การบริหารจัดการ",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "ต้นทุนประสิทธิผล",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "ผลลัพธ์ที่พึงประสงค์",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "ความเสมอภาค",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "ความเป็นไปได้",
          "researchEvidenceId": "feasibility"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "ปัญหา ",
          "researchEvidenceId": "problem"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "แหล่งข้อมูลที่จำเป็น",
          "researchEvidenceId": "resourcesRequired"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": "ความถูกต้องของการทดสอบ",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "ผลลัพธ์ที่ไม่พึงประสงค์",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "คุณค่า",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "decision": {
          "content": "",
          "description": "การตัดสินใจ",
          "options": [
            {
              "text": "การนำไปใช้แบบเต็มรูปแบบ",
              "value": "full_implementation"
            },
            {
              "text": "การประเมินผลกระทบ",
              "value": "impact_evaluation"
            },
            {
              "text": "การศึกษานำร่อง",
              "value": "pilot_study"
            },
            {
              "text": "เลื่อนไป",
              "value": "postpone"
            },
            {
              "text": "ไม่ได้ใช้",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "ชนิดของการตัดสิน",
          "options": [
            {
              "direction": "negative",
              "text": "ไม่คุ้มครอง",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "คุ้มครองโดยการพัฒนาหลักฐานเชิงประจักษ์",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "คุ้มครองด้วยการต่อรองราคา",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "การคุ้มครองที่จำกัด",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "คุ้มครอง",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "ชนิดของการตัดสินใจ",
          "options": [
            {
              "direction": "negative",
              "text": "ห้ามนำทางเลือกไปใช้",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "เลื่อนการตัดสินใจ",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "ทำการศึกษานำร่องในทางเลือก",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "นำทางเลือกไปใช้ พร้อมกับการประเมินผลกระทบ",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "นำทางเลือกไปใช้",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderations": {
          "content": "",
          "description": "การพิจจารณาการนำไปใช้",
          "details": {
            "content": "",
            "description": "รายละเอียดการนำไปใช้"
          }
        },
        "justification": {
          "content": "",
          "description": "การให้เหตุผล",
          "details": {
            "description": "รายละเอียดการให้เหตุผล"
          },
          "overallDescription": "การให้เหตุผลโดยภาพรวม"
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "การตรวจสอบ และการประมิน",
          "details": {
            "content": "",
            "description": "รายละเอียดการตรวจสอบ และการประมิน"
          }
        },
        "recommendation": {
          "content": "",
          "description": "ข้อเสนอแนะ"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "ชนิดของข้อเสนอแนะ",
          "options": [
            {
              "direction": "negative",
              "text": "ข้อเสนอแนะที่เข้มแข็งที่ต่อต้านต่อทางเลือก",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "ข้อเสนอแนะแบบมีเงื่อนไขที่ต่อต้านต่อทางเลือก",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "ข้อเสนอแนะแบบมีเงื่อนไข ระหว่างทางเลือก หรือสิ่งเปรียบเทียบ",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "ข้อเสนอแนะแบบมีเงื่อนไงต่อทางเลือก",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "ข้อเสนอแนะที่เข้มแข็งต่อทางเลือก",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "ชนิดของข้อเสนอแนะ",
          "options": [
            {
              "direction": "negative",
              "text": "ข้อเสนอแนะที่เข้มแข็งที่ต่อต้านต่อทางเลือก",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "ข้อเสนอแนะแบบมีเงื่อนไงต่อต้านต่อทางเลือก",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "ข้อเสนอแนะแบบมีเงื่อนไข ระหว่างทางเลือก หรือสิ่งเปรียบเทียบ",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "ข้อเสนอแนะแบบมีเงื่อนไงต่อทางเลือก",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "ข้อเสนอแนะที่เข้มแข็งต่อทางเลือก",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "การจัดลำดับความสำคัญงานวิจัย"
        },
        "restrictions": {
          "content": "",
          "description": "ข้อจำกัด"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "การพิจารณากลุ่มย่อย"
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "แพทย์ พยาบาล ผู้ดูแล",
          "sections": {
            "background": {
              "name": "ความเป็นมา"
            },
            "detailedJustification": {
              "name": "รายละเอียดการให้เหตุผล"
            },
            "justification": {
              "name": "การให้เหตุผล"
            },
            "relatedRecommendations": {
              "name": "Related recommendations"
            },
            "subgroupConsiderations": {
              "name": "การพิจารณากลุ่มย่อย"
            },
            "summaryOfFindings": {
              "name": "การสรุปข้อค้นพบ"
            },
            "implementationConsiderations": {
                 "name": "Implementation considerations"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "ผู้ป่วย",
          "sections": {
            "relatedRecommendations": {
              "name": "Related recommendations"
            },
            "summaryOfFindings": {
              "name": "SoF"
            },
            "whatItMeansForYou": {
              "name": "What does this mean for you?",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Talk with your health care professional"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "What you can do"
                }
              }
            },
            "whoIsThisFor": {
              "name": "Who is this for?",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "สิ่งนี้เพื่อใคร"
                }
              }
            },
            "whyThisRecommendation": {
              "name": "Why this recommendation?",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "Additional information"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "Benefits and Harms"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "Why this recommendation"
                }
              }
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "ผู้กำหนดนโยบาย",
          "sections": {
            "assessment": {
              "name": "การประเมิน"
            },
            "background": {
              "description": "รายละเอียดคำถามสำหรับข้อเสนอแนะ",
              "details": {
                "name": "เกี่ยวกับการตัดสินใจครั้งนี้",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "วันที่"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "ผู้ทำการตัดสินใจ"
                  },
                  "perspective": {
                    "content": "",
                    "description": "มุมมอง"
                  },
                  "setting": {
                    "content": "",
                    "description": "พื้นที่"
                  }
                },
                "sectionsOrder": [
                  "setting",
                  "perspective",
                  "decisionMakers",
                  "date"
                ]
              },
              "name": "ความเป็นมา"
            },
            "decision": {
              "content": "",
              "name": "การตัดสินใจ",
              "options": [
                {
                  "text": "การนำไปปฏิบัติเต็มรูปแบบ",
                  "value": "full_implementation"
                },
                {
                  "text": "การประเมินผลกระทบ",
                  "value": "impact_evaluation"
                },
                {
                  "text": "การศึกษานำร่อง",
                  "value": "pilot_study"
                },
                {
                  "text": "เลื่อนไป",
                  "value": "postpone"
                },
                {
                  "text": "ไม่ได้ใช้",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "การนำไปปฏิบัติ"
            },
            "justification": {
              "name": "การให้เหตุผล"
            },
            "monitoringAndEvaluation": {
              "name": "การตรวจสอบ และการประมิน"
            },
            "relatedRecommendations": {
              "name": "Related recommendations"
            },
            "summaryOfFindings": {
              "name": "การสรุปข้อค้นพบ"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "anticipatedOutcomes": {
          "content": "",
          "name": "ผลลัพธ์ที่คาดไว้"
        },
        "background": {
          "content": "",
          "name": "ความเป็นมา"
        },
        "coi": {
          "content": "",
          "name": "Conflict of interests"
        },
        "comparison": {
          "content": "",
          "name": "ส่ิงเปรียบเทียบ"
        },
        "intervention": {
          "content": "",
          "name": "การช่วยเหลือ หรือ การจัดกระทำ"
        },
        "linkedTreatments": {
          "content": "",
          "name": "การเชื่อมต่อข้อมูลการบำบัดที่เกี่ยวข้อง"
        },
        "mainOutcomes": {
          "content": "",
          "name": "ผลลัพธ์หลัก"
        },
        "option": {
          "content": "",
          "name": "ทางเลือก"
        },
        "perspective": {
          "content": "",
          "name": "มุมมอง"
        },
        "population": {
          "content": "",
          "name": "ประชากร"
        },
        "problem": {
          "content": "",
          "name": "ปัญหา "
        },
        "purpose": {
          "content": "",
          "name": "วัตถุประสงค์ของการทดสอบ"
        },
        "role": {
          "content": "",
          "name": "บทบาทของการทดสอบ"
        },
        "setting": {
          "content": "",
          "name": "พื้นที่"
        },
        "subgroups": {
          "content": "",
          "name": "กลุ่มย่อย"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
