var QualityIndicatorsToolbar, QualityIndicatorsToolbarView, QuestionsListComponentActions, ReactComponent, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

QualityIndicatorsToolbar = require('components/quality_indicators/toolbar');

mediator = require('mediator');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

ReactComponent = require('base/lib/traits/react_component');

View = require('base/views/view');

module.exports = QualityIndicatorsToolbarView = (function(_super) {
  __extends(QualityIndicatorsToolbarView, _super);

  function QualityIndicatorsToolbarView() {
    return QualityIndicatorsToolbarView.__super__.constructor.apply(this, arguments);
  }

  QualityIndicatorsToolbarView.prototype.container = '#toolbar-container';

  QualityIndicatorsToolbarView.prototype.autoRender = true;

  QualityIndicatorsToolbarView.prototype._stopInputsPropagation = false;

  QualityIndicatorsToolbarView.prototype.initialize = function() {
    QualityIndicatorsToolbarView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  QualityIndicatorsToolbarView.prototype.afterRender = function() {
    QualityIndicatorsToolbarView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(QualityIndicatorsToolbar, QuestionsListComponentActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id
      },
      props: {
        mode: this.options.mode
      }
    });
  };

  QualityIndicatorsToolbarView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('QualityIndicatorsStore', 'QuestionsStore', 'QuestionGroupsStore', 'QuestionsListComponentStore', 'QuestionsStatusesStore', 'OrganizationsStore', 'OverarchingQuestionsStore');
    return QualityIndicatorsToolbarView.__super__.dispose.apply(this, arguments);
  };

  return QualityIndicatorsToolbarView;

})(View);
