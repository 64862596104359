utils = require 'base/lib/utils'

Header = createReactClass
  displayName: 'FixedHeaderTableHeader'

  render: ->
    style =
      width: if @props.scrollbarVisible then "calc(100% - #{utils.scrollbarWidth()}px)" else '100%'

    <table className={@props.className} style={style}>
      {React.Children.map @props.children, (child) ->
        child
      }
    </table>

Body = createReactClass
  displayName: 'FixedHeaderTableBody'

  render: ->
    style =
      overflow: 'auto'
    if @props.height
      style = _(style).extend height: "#{@props.height}px"

    <div className={@props.className} style={style}>
      <table style={width: '100%'}>
        {React.Children.map @props.children, (child) ->
          child
        }
      </table>
    </div>

FixedHeaderTable = createReactClass
  displayName: 'FixedHeaderTable'

  propTypes:
    container:
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired

  getInitialState: ->
    bodyHeight: 0

  componentDidMount: ->
    window.addEventListener 'resize', @windowResized
    @setState bodyHeight: @getBodyHeight()

  componentWillUnmount: ->
    window.removeEventListener 'resize', @windowResized

  getContainerInnerHeight: ->
    container = if _.isString @props.container
      document.querySelector @props.container
    else
      @props.container

    utils.getElementHeight container

  getHeaderHeight: ->
    utils.getElementHeight ReactDOM.findDOMNode @header

  getBodyHeight: ->
    actualBodyHeight = utils.getElementHeight ReactDOM.findDOMNode @body
    headerHeight = @getHeaderHeight()
    containerInnerHeight = @getContainerInnerHeight()

    if actualBodyHeight + headerHeight > containerInnerHeight
      containerInnerHeight - headerHeight
    else
      null

  windowResized:
    _.debounce (-> @setState bodyHeight: @getBodyHeight()), 100

  render: ->
    <div>
      {React.Children.map @props.children, (child) =>
        if child.type.displayName is 'FixedHeaderTableBody'
          React.cloneElement child,
            height: @state.bodyHeight
            className: classNames @props.className, child.props.className
            ref: (body) => @body = body
        else if child.type.displayName is 'FixedHeaderTableHeader'
          React.cloneElement child,
            className: @props.className
            # if bodyHeight is not defined then there's no scrollbar
            scrollbarVisible: @state.bodyHeight?
            ref: (header) => @header = header
      }
    </div>

module.exports = { FixedHeaderTable, Header, Body }
