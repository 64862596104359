var DbDoc, comparisonsQuestionGroupsDocId, comparisonsQuestionGroupsDocShape;

DbDoc = require('lib/db_docs/db_doc');

comparisonsQuestionGroupsDocId = require('lib/doc_ids').COMPARISONS_QUESTION_GROUPS;

comparisonsQuestionGroupsDocShape = require('lib/db_docs/doc_shapes/comparisons_question_groups_doc_shape');

module.exports = DbDoc(comparisonsQuestionGroupsDocShape, {
  dbSetup: {
    docId: comparisonsQuestionGroupsDocId
  },
  methods: {
    addQuestionToGroup: function(questionId, groupId) {
      return this.setAt('mapping', questionId)(groupId);
    }
  }
});
