ReferenceRow = require 'components/references/reference_row'
{ arrayOf, bool, func, instanceOf, string } = PropTypes

Reference = createReactClass
  displayName: 'Reference'

  propTypes:
    referenceData: instanceOf(Immutable.Map).isRequired
    columns: arrayOf(string).isRequired
    isSelected: bool.isRequired
    referenceUsedIn: instanceOf(Immutable.Map)
    toggleSelection: func.isRequired

  getDefaultProps: ->
    referenceUsedIn: Immutable.Map()

  toggleSelect: ->
    @props.toggleSelection @props.referenceData.get '_id'

  render: ->
    { columns, isSelected, referenceData, referenceUsedIn, style } = @props
    refClass = classNames 'reference', 'selected': isSelected

    <ReferenceRow
      className={refClass}
      columns={columns}
      isRowSelected={isSelected}
      onClick={@toggleSelect}
      referenceData={referenceData}
      referenceUsedIn={referenceUsedIn}
    />

module.exports = Reference
