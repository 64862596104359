CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ string, func, bool, arrayOf, object } = PropTypes

TabsPane = createReactClass
  displayName: 'TabsPane'

  mixins: [CustomRenderMixin]

  propTypes:
    tabs: arrayOf(object).isRequired
    onTabClick: func
    activeTab: string
    tabsText: string

  renderTab: (tabProps, idx) ->
    { tabspaneClass, tabName, tabLabel, tabStyle, disabled } = tabProps
    tabClass = classNames 'tab', _.str.dasherize(tabName), tabspaneClass,
      'tab--disabled': disabled
      'tab--selected': @props.activeTab is tabName

    <div
      key={idx}
      className={tabClass}
      style={tabStyle}
      onClick={(=> @props.onTabClick tabName) unless disabled}
    >
      {tabLabel}
    </div>

  render: ->
    <div className='tabspane'>
      {@props.tabsText and <div className="tabstext">{@props.tabsText}</div>}
      {@props.tabs.map @renderTab}
    </div>

Tab = createReactClass
  displayName: 'TabComponent'

  mixins: [CustomRenderMixin]

  propTypes:
    tabName: string.isRequired

  render: ->
    tabClass = classNames 'tab-content', @props.className
    <div className={tabClass} style={@props.style}>
      {@props.children}
    </div>

TabsContainer = createReactClass
  displayName: 'TabsContainer'

  propTypes:
    activeTab: string.isRequired
    withTabsPane: bool
    changeTab: func
    className: string
    fitToScreenBottom: bool
    tabsText: string

  getDefaultProps: ->
    fitToScreenBottom: false

  getTabsList: ->
    React.Children.map @props.children, (tab) -> tab.props

  _tabRef: (el) ->
    @tab = el

  _paneRef: (el) ->
    @pane = el

  _fitHeight: ->
    return unless @props.fitToScreenBottom
    tabElement = ReactDOM.findDOMNode @tab
    paneElement = ReactDOM.findDOMNode @pane
    paneBottom = paneElement.getBoundingClientRect().bottom
    tabElement.style.maxHeight = "#{document.documentElement.offsetHeight - paneBottom - 10}px"

  componentDidMount: ->
    @_fitHeight()

  componentDidUpdate: ->
    @_fitHeight()

  renderTab: ->
    { activeTab, children, fitToScreenBottom } = @props
    ActiveTab = React.Children.toArray(children).filter (tab) -> tab.props.tabName is activeTab
    if fitToScreenBottom
      ActiveTab.map (tab) =>
        React.cloneElement tab,
          ref: @_tabRef
          style:
            overflowY: 'auto'
    else
      ActiveTab

  render: ->
    { activeTab, changeTab, withTabsPane, tabsText } = @props
    containerClass = classNames 'tabs-container', @props.className
    <div className={containerClass}>
      {if withTabsPane
        <TabsPane
          activeTab={activeTab}
          onTabClick={changeTab}
          ref={@_paneRef}
          tabs={@getTabsList()}
          tabsText={tabsText}
        />
      }
      {@renderTab()}
    </div>

module.exports = { Tab, TabsContainer }
