var INITIAL_STATE, LimitsActions, generateStore;

generateStore = require('stores/utils/generate_store');

LimitsActions = require('actions/limits_actions');

INITIAL_STATE = Immutable.fromJS({
  meta: {
    fetching: false
  },
  workspace: {

    /* workspaceId ->
      limits: {
        teamMembers: number
        teamAdmins: number
        questions: number
      }
      projectId -> limits
     */
  }
});

module.exports = generateStore({
  name: 'LimitsStore',
  initialState: INITIAL_STATE,
  boundActions: [LimitsActions],
  methods: {
    onFetchLimits: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], true));
    },
    onFetchLimitsSuccess: function(_arg) {
      var limits, projectId, workspace;
      workspace = _arg.workspace, projectId = _arg.projectId, limits = _arg.limits;
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['meta', 'fetching'], false);
        if (projectId) {
          return s.setIn(['workspace', workspace, projectId], Immutable.fromJS(limits));
        } else {
          return s.setIn(['workspace', workspace, 'limits'], Immutable.fromJS(limits));
        }
      }));
    },
    isFetchingLimits: function() {
      return this.state.getIn(['meta', 'fetching']);
    },
    getLimits: function(workspaceId, projectId) {
      return this.state.getIn(['workspace', workspaceId, projectId || 'limits'], Immutable.Map());
    }
  }
});
