MembersCommentsBlock = require 'components/scope/members_comments'
TextAreaWithApply = require 'components/common/text_area_with_apply'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

CommentsBlock = createReactClass
  displayName: 'CommentsBlock'

  propTypes:
    membersComments: PropTypes.instanceOf(Immutable.Map).isRequired
    membersMap: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:questions')
  ]

  render: ->
    <div className={@props.className}>
      <MembersCommentsBlock
        membersComments={@props.membersComments}
        membersMap={@props.membersMap}
      />
      <div className='comments-section'>
        <div className='title'>
          {@i18n 'my_comment'}
        </div>
        <div className='admin-comment'>
          <TextAreaWithApply
            content={@props.adminComment}
            onApply={@props.onApplyAdminComment}
            readOnly={@props.readOnlyComments}
          />
        </div>
      </div>
    </div>

module.exports = CommentsBlock
