var Collection, Reference, References, W, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Collection = require('models/base/collection');

Reference = require('./reference');

W = require('when/when');

mediator = require('mediator');

module.exports = References = (function(_super) {
  __extends(References, _super);

  function References() {
    return References.__super__.constructor.apply(this, arguments);
  }

  References.prototype.model = Reference;

  References.prototype.pouch = {
    fetch: 'references',
    views: {
      references: {
        map: function(doc) {
          if (doc.docType === 'reference') {
            return emit(doc.docType, doc);
          }
        }
      }
    },
    filters: {
      references: function(doc) {
        return doc._deleted || doc.docType === 'reference';
      }
    }
  };

  References.prototype.addWithStatus = function(reference) {
    var possibleDuplicates, ref;
    if (!(reference instanceof Reference)) {
      reference = new Reference(reference);
    }
    this.add(reference);
    reference.setMd5Hash();
    possibleDuplicates = this.where({
      md5hash: reference.get('md5hash')
    });
    if (possibleDuplicates.length > 1) {
      return W.all((function() {
        var _i, _len, _results;
        _results = [];
        for (_i = 0, _len = possibleDuplicates.length; _i < _len; _i++) {
          ref = possibleDuplicates[_i];
          ref.set('status', 'possibleDuplicate');
          _results.push(ref.save());
        }
        return _results;
      })());
    } else {
      return reference.save();
    }
  };

  References.prototype.getDuplicates = function() {
    var duplicates, group, groupsByHash, hash;
    groupsByHash = this.groupBy(function(ref) {
      return ref.get('md5hash');
    });
    duplicates = {};
    for (hash in groupsByHash) {
      group = groupsByHash[hash];
      group = _(group).reject(function(g) {
        return g.get('deduplicationStatus') === 'merged';
      });
      if (group.length > 1) {
        duplicates[hash] = group;
      }
    }
    return duplicates;
  };

  References.prototype.duplicatesCount = function() {
    return _(this.getDuplicates()).keys().length;
  };

  References.prototype.mergeDuplicates = function(duplicatesArray) {
    var idx, reference;
    return W.all((function() {
      var _i, _len, _results;
      _results = [];
      for (idx = _i = 0, _len = duplicatesArray.length; _i < _len; idx = ++_i) {
        reference = duplicatesArray[idx];
        if (idx !== 0) {
          reference.set('deduplicationStatus', 'merged');
        }
        if (idx === 0) {
          reference.set('status', 'inScreening');
        }
        _results.push(reference.save());
      }
      return _results;
    })()).then(function() {
      return mediator.dialogs.info($.t('references:messages.merged'));
    });
  };

  References.prototype.keepDuplicates = function(duplicatesArray) {
    var reference;
    return W.all((function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = duplicatesArray.length; _i < _len; _i++) {
        reference = duplicatesArray[_i];
        reference.set('md5hash', reference.id);
        reference.set('deduplicationStatus', 'kept');
        reference.set('status', 'inScreening');
        _results.push(reference.save());
      }
      return _results;
    })()).then(function() {
      return mediator.dialogs.info($.t('references:messages.kept'));
    });
  };

  References.prototype.getDeduplicatedList = function() {
    var model, _i, _len, _ref, _results;
    _ref = this.models;
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      model = _ref[_i];
      if (model.get('deduplicationStatus') !== 'merged') {
        _results.push(model);
      }
    }
    return _results;
  };

  References.prototype.getForScreening = function() {
    var model, _i, _len, _ref, _results;
    _ref = this.models;
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      model = _ref[_i];
      if (model.get('status') === 'inScreening') {
        _results.push(model);
      }
    }
    return _results;
  };

  References.prototype.getIncluded = function() {
    var model, _i, _len, _ref, _results;
    _ref = this.models;
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      model = _ref[_i];
      if (model.get('status') === 'included') {
        _results.push(model);
      }
    }
    return _results;
  };

  References.prototype.getExcluded = function() {
    var model, _i, _len, _ref, _results;
    _ref = this.models;
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      model = _ref[_i];
      if (model.get('status') === 'excluded') {
        _results.push(model);
      }
    }
    return _results;
  };

  return References;

})(Collection);
