var TreatmentOutcomeExtraRowView, TreatmentOutcomeView,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

TreatmentOutcomeView = require('views/evidence_syntheses/treatment_outcome_view');

module.exports = TreatmentOutcomeExtraRowView = (function(_super) {
  __extends(TreatmentOutcomeExtraRowView, _super);

  function TreatmentOutcomeExtraRowView() {
    return TreatmentOutcomeExtraRowView.__super__.constructor.apply(this, arguments);
  }

  TreatmentOutcomeExtraRowView.prototype.getTemplateData = function() {
    var templateData;
    templateData = TreatmentOutcomeExtraRowView.__super__.getTemplateData.apply(this, arguments);
    _(templateData).extend({
      controlLabel: this.options.controlLabel,
      controlType: this.options.controlType,
      riskType: this.options.riskType,
      preFix: this.options.preFix,
      riskDifferenceType: this.options.riskDifferenceType,
      correspondingRiskType: this.options.correspondingRiskType,
      absoluteEffectType: this.options.absoluteEffectType,
      viewTypeName: this.options.viewTypeName
    });
    return templateData;
  };

  TreatmentOutcomeExtraRowView.prototype.outcomeDeleted = function() {};

  return TreatmentOutcomeExtraRowView;

})(TreatmentOutcomeView);
