var docShape, docTypes, documentId, initial, mapOf, mdgSearchStrategyShape, oneOf, optional, shape, string;

docShape = require('lib/db_docs/field_types/doc_shape');

docTypes = require('lib/db_docs/doc_types');

documentId = require('lib/db_docs/field_types/document_id');

initial = require('lib/db_docs/field_types/type_decorators').initial;

mapOf = require('lib/db_docs/field_types/map_of');

oneOf = require('lib/db_docs/field_types/one_of');

shape = require('lib/db_docs/field_types/shape');

optional = shape.typeDecorators.optional;

string = require('lib/db_docs/field_types/built_in_types').string;

mdgSearchStrategyShape = docShape({
  criteria: initial({})(mapOf(mapOf(mapOf(optional(string))))),
  docType: initial(docTypes.MDG_SEARCH_STRATEGY)(string),
  searchStrategyType: oneOf(['treatment', 'diagnostic']),
  generalInformation: shape({
    diagnosis: initial('')(string),
    lastUpdate: initial('')(string),
    notes: initial('')(string),
    numberOfSavedArticles: initial('')(string),
    searchDate: shape({
      from: initial('')(string),
      to: initial('')(string)
    }),
    searchTermUsed: initial('')(string),
    searchTime: initial('')(string),
    treatmentTopic: initial('')(string)
  }),
  questionId: documentId
});

module.exports = mdgSearchStrategyShape;
