{ string, func, arrayOf, shape, object } = PropTypes

Select = createReactClass
  displayName: 'SelectComponent'

  propTypes:
    onChange: func.isRequired
    name: string
    id: string
    selected: string
    options: arrayOf shape { value: string, text: string }
    style: object
    className: string

  onChange: (e) ->
    @props.onChange e.currentTarget.value

  render: ->
    <select
      onChange={@onChange}
      name={@props.name}
      id={@props.id}
      value={@props.selected}
      style={@props.style}
      className={@props.className}
    >
      {_(@props.options).map (opt) ->
        if _(opt).isObject()
          value = opt.value ? opt.text
          <option key={value} disabled={opt.disabled} value={value}>
            {opt.text}
          </option>
        else
          <option key={opt} value={opt}>{opt}</option>
      }
    </select>

module.exports = Select
