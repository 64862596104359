var FieldType, GdtDocFieldType,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

FieldType = Immutable.Record({
  name: 'GdtDocFieldType',
  check: _.identity,
  parse: function(v) {
    return this.check(v);
  },
  typeDefault: null,
  _typeMeta: {}
}, 'GdtDocFieldType');

GdtDocFieldType = (function(_super) {
  __extends(GdtDocFieldType, _super);

  function GdtDocFieldType() {
    return GdtDocFieldType.__super__.constructor.apply(this, arguments);
  }

  GdtDocFieldType.prototype.defaults = function(v) {
    if (v != null) {
      return this.parse(v);
    } else {
      return this.typeDefault;
    }
  };

  GdtDocFieldType.prototype.is = function(metaFlagName) {
    return this['_typeMeta'][metaFlagName];
  };

  return GdtDocFieldType;

})(FieldType);

module.exports = GdtDocFieldType;
