var DisseminationScreen, DisseminationToolbar, DisseminationView, ReactComponent, ReactToolbarView, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DisseminationScreen = require('components/dissemination/dissemination_screen');

DisseminationToolbar = require('components/dissemination/dissemination_toolbar');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = DisseminationView = (function(_super) {
  __extends(DisseminationView, _super);

  function DisseminationView() {
    return DisseminationView.__super__.constructor.apply(this, arguments);
  }

  DisseminationView.prototype.container = '#page-container';

  DisseminationView.prototype.className = 'dissemination-view';

  DisseminationView.prototype.autoRender = true;

  DisseminationView.prototype._stopInputsPropagation = false;

  DisseminationView.prototype.initialize = function() {
    DisseminationView.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    return this.subview('toolbar', new ReactToolbarView({
      component: DisseminationToolbar,
      props: {
        submodule: this.options.submodule
      }
    }));
  };

  DisseminationView.prototype.afterRender = function() {
    DisseminationView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(DisseminationScreen);
  };

  DisseminationView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    return DisseminationView.__super__.dispose.apply(this, arguments);
  };

  return DisseminationView;

})(View);
