List = require 'components/common/list'
Label = require 'components/common/label'
Button = require 'components/common/button'
RadioInput = require 'components/common/radio_input'
Translation = require 'components/mixins/translation'
OutcomesSelect = require 'components/common/outcomes_select'
{ formatFileSize } = require 'base/lib/utils'
{ etdAttachmentSizeLimit, editorSupportedImageTypes } = require 'lib/constants'

EtdInsertOptions = createReactClass
  displayName: 'EtdInsertOptions'

  propTypes:
    insertOptions: PropTypes.arrayOf PropTypes.string
    onOptionSelect: PropTypes.func.isRequired
    onFileSelect: PropTypes.func.isRequired
    isFileUploadDisabled: PropTypes.bool
    outcomes: PropTypes.instanceOf Immutable.List
    selectedOutcomes: PropTypes.instanceOf Immutable.List
    updateSelectedOutcomes: PropTypes.func

  mixins: [
    Translation('es:recommendations.insert_modal')
  ]

  getInitialState: ->
    wrongFile: false
    chosenFileName: null
    fileSizeLimitExceeded: false

  onFileSelect: (e) ->
    file = e.target.files[0]

    return unless file

    wrongFile = file.type not in editorSupportedImageTypes
    limitExceeded = file.size > etdAttachmentSizeLimit

    @setState
      wrongFile: wrongFile
      chosenFileName: file.name
      fileSizeLimitExceeded: limitExceeded

    unless wrongFile or limitExceeded
      @props.onFileSelect file

  showFileUpload: (e) ->
    @refs.fileInput.click()

  getFileButtonCaption: ->
    @state.chosenFileName ? @i18n 'choose_file'

  renderOption: (option, idx) ->
    {
      onOptionSelect
      isFileUploadDisabled
      outcomesListTarget
      outcomes
      selectedOutcomes
      selectedOption
      updateSelectedOutcomes
    } = @props

    <div key={idx} className='label-container'>
      <Label htmlFor={option} key={idx}>
        <RadioInput
          id={option}
          name='insert-object'
          checked={selectedOption is option}
          value={option}
          onChange={onOptionSelect}
        />
        <span>{@i18n option}</span>
        {
          if option is 'image'
            [
              <input id='file' type='file' onChange={@onFileSelect} ref='fileInput' key=11 />
              <Button
                key=22
                className='file btn'
                disabled={isFileUploadDisabled}
                label={@getFileButtonCaption()}
                onClick={@showFileUpload}
              />
            ]
        }
      </Label>
      {if outcomesListTarget is option
        <div className='outcomes-list-select' ref={option}>
          <OutcomesSelect
            outcomes={outcomes}
            selectedOutcomes={selectedOutcomes}
            updateSelectedOutcomes={updateSelectedOutcomes}
          />
        </div>
      }
    </div>

  render: ->
    <div>
      <List ordered={false}>
        {@props.insertOptions.map @renderOption}
      </List>
      {if @state.wrongFile
        <div className='error'>
          {@i18n '/messages.unsupported_image_format'}
        </div>
      }
      {if @state.fileSizeLimitExceeded
        <div className='error'>
          {@i18n '/messages.file_size_limit_exceeded', sizeLimit: formatFileSize etdAttachmentSizeLimit}
        </div>
      }
    </div>

module.exports = EtdInsertOptions
