var CADTH_EMPTY_GUIDELINE, CADTH_GUIDELINE_DOC_PREFIX, CADTH_GUIDELINE_DOC_TYPE, CADTH_GUIDELINE_FORM;

CADTH_GUIDELINE_DOC_PREFIX = 'cadth_guideline_';

CADTH_GUIDELINE_DOC_TYPE = 'cadth_guideline';

CADTH_EMPTY_GUIDELINE = {
  countryOrRegion: '',
  guidelineGroup: '',
  publicationYear: '',
  qualityOfGuideline: '',
  shortTitle: '',
  sourceLink: '',
  sourceText: '',
  title: ''
};

CADTH_GUIDELINE_FORM = [
  {
    fieldKey: 'title',
    type: 'textarea',
    showClear: false
  }, {
    fieldKey: 'shortTitle',
    type: 'textarea',
    showClear: false
  }, {
    fieldKey: 'guidelineGroup',
    type: 'textarea',
    showClear: false
  }, {
    fieldKey: 'publicationYear',
    type: 'string',
    showClear: false
  }, {
    fieldKey: 'countryOrRegion',
    type: 'string',
    showClear: false
  }, {
    fieldKey: 'qualityOfGuideline',
    type: 'string',
    showClear: false
  }, {
    fieldKey: 'sourceText',
    type: 'textarea',
    showClear: false
  }, {
    fieldKey: 'sourceLink',
    type: 'string',
    showClear: false
  }
];

module.exports = {
  CADTH_EMPTY_GUIDELINE: CADTH_EMPTY_GUIDELINE,
  CADTH_GUIDELINE_DOC_PREFIX: CADTH_GUIDELINE_DOC_PREFIX,
  CADTH_GUIDELINE_DOC_TYPE: CADTH_GUIDELINE_DOC_TYPE,
  CADTH_GUIDELINE_FORM: CADTH_GUIDELINE_FORM
};
