ConnectStore = require 'components/enhancers/connect_store'
MDGSearchStrategyActions = require 'actions/mdg_search_strategy_actions'
MDGSearchStrategyGeneralInformationModal =
  require 'components/mdg_tables/search_strategy_general_information_modal'
MDGSearchStrategyStore = require 'stores/mdg_search_strategy_store'
MDGSearchStrategyTab = require 'components/mdg_tables/search_strategy_tab'
{ MDG_SEARCH_STRATEGY_TABS } = require 'lib/mdg_helper'
Spinner = require 'components/common/spinner'
{ Tab, TabsContainer } = require 'components/common/tabs_container'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

storeConnector =
  MDGSearchStrategyStore: (Store) ->
    activeTab: Store.getActiveTab()
    isFetching: Store.isFetching()
    searchStrategy: Store.getCurrentSearchStrategy()

MDGSearchStrategy = ({ activeTab, isFetching, searchStrategy }) ->
  i18n = useI18n('mdg_tables:search_strategy')

  onChangeTab = useCoffeeCallback [MDGSearchStrategyActions], (tabName) ->
    MDGSearchStrategyActions.setActiveTab tabName

  searchStrategyId = searchStrategy.get '_id'

  <div className="mdg-search-strategy-view">
    {if isFetching
      <Spinner />
    else
      <React.Fragment>
        <TabsContainer
          activeTab={activeTab}
          changeTab={onChangeTab}
          tabsText={i18n 'title'}
          withTabsPane
        >
          {_.map MDG_SEARCH_STRATEGY_TABS, (tabId) ->
            <Tab key={tabId} tabLabel={i18n "tabs.#{tabId}"} tabName={tabId}>
              <MDGSearchStrategyTab searchStrategy={searchStrategy} tabId={tabId} />
            </Tab>
          }
        </TabsContainer>
        <MDGSearchStrategyGeneralInformationModal searchStrategyId={searchStrategyId} />
      </React.Fragment>
    }
  </div>

MDGSearchStrategy.propTypes =
  activeTab: PropTypes.string.isRequired
  isFetching: PropTypes.bool.isRequired
  searchStrategy: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore MDGSearchStrategy, MDGSearchStrategyStore, storeConnector
