var AccountActions, W, checkForExternalAccount, exceptions, getErrorMessage, getJSON, mediator;

mediator = require('mediator');

W = require('when');

exceptions = require('lib/exceptions');

AccountActions = require('actions/account_actions');

getErrorMessage = function(actionKey, xhr) {
  var messageKey;
  messageKey = (function() {
    switch (xhr.status) {
      case 401:
        return 'unauthorized';
      case 403:
        return 'forbidden';
      case 404:
        return 'not_found';
      case 409:
        return 'conflict';
      case 500:
        return 'server_error';
      default:
        return 'no_connection';
    }
  })();
  if (actionKey != null) {
    return $.t("errors:archie." + actionKey + "." + messageKey);
  } else {
    return messageKey;
  }
};

getJSON = function(responseText) {
  try {
    return JSON.parse(responseText);
  } catch (_error) {
    return {};
  }
};

checkForExternalAccount = function() {
  return mediator.services.externalAccounts.getConnectionStatus('archie', mediator.user.get('name'))["catch"](function() {
    return {
      connected: false
    };
  });
};

module.exports = {
  COCHRANE_ORGANIZATION_ID: 'o_cochrane_0',
  getRevManWebConnectionData: function() {
    if (mediator.activeWorkspace === this.COCHRANE_ORGANIZATION_ID) {
      return checkForExternalAccount();
    } else {
      return W.resolve({
        connected: false
      });
    }
  },
  handleConnectionError: function(actionKey, xhr) {
    var json, _ref;
    if (xhr.status === 401) {
      json = getJSON(xhr.responseText);
      if ((_ref = json.reason) === 'token_expired' || _ref === 'refreshing_token_failed') {
        return W.reject(new exceptions.archie.token_expired(actionKey));
      } else {
        return W.reject(new Error(getErrorMessage(actionKey, xhr)));
      }
    } else {
      return W.reject(new Error(getErrorMessage(actionKey, xhr)));
    }
  },
  reconnectToArchieIfTokenExpired: function(apiCall, actionKey) {
    return apiCall()["catch"](_.partial(this.handleConnectionError, actionKey))["catch"]((function(_this) {
      return function(error) {
        var deferred;
        if (error instanceof exceptions.archie.token_expired) {
          deferred = W.defer();
          AccountActions.openArchieTokenExpiredModal(deferred);
          return deferred.promise.then(apiCall)["catch"](function(errorOrXhr) {
            if (_.isString(errorOrXhr)) {
              return W.reject(new exceptions.archie.token_expired(actionKey));
            } else {
              return _this.handleConnectionError(actionKey, errorOrXhr);
            }
          });
        } else {
          return W.reject(error);
        }
      };
    })(this));
  }
};
