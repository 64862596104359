Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'

ApprovalOutcomeMarkerCell = createReactClass
  displayName: 'ApprovalOutcomeMarkerCell'

  propTypes:
    importance: PropTypes.string.isRequired

  mixins: [
    Translation('scope:outcomes')
  ]

  _getStyles: ->
    { importance } = @props

    backgroundColor: importanceColors[importance]
    width: '10px'
    border: 'none'
    borderRight: '1px solid #bfbfbf'
    padding: 0

  render: ->
    { importance } = @props
    markerClass = classNames 'approval-marker', _.string.dasherize importance

    <Tooltip>
      <td className={markerClass} title={@i18n _.string.underscored importance} />
    </Tooltip>

module.exports = ApprovalOutcomeMarkerCell
