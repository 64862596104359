var Model, ProxyModel, W,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

W = require('when');

module.exports = ProxyModel = (function(_super) {
  __extends(ProxyModel, _super);

  function ProxyModel() {
    this.sync = __bind(this.sync, this);
    return ProxyModel.__super__.constructor.apply(this, arguments);
  }

  ProxyModel.prototype.initialize = function(attrs, options) {
    ProxyModel.__super__.initialize.apply(this, arguments);
    return this.proxied = options.proxied;
  };

  ProxyModel.prototype.create = function(model) {};

  ProxyModel.prototype.update = function(model) {};

  ProxyModel.prototype["delete"] = function() {};

  ProxyModel.prototype.read = function() {};

  ProxyModel.prototype.sync = function(method, model, options) {
    return W(this[method].call(this, model));
  };

  return ProxyModel;

})(Model);
