var closeApproval, closeBrainstorming, closeRating, fetchApprovalResults, fetchBrainstormingResults, fetchRatingResults, questionsGenerationAsync, sendForRatingOrApproval, sendInitial, _ref;

_ref = require('lib/questions_generation_async_helper'), sendInitial = _ref.sendInitial, sendForRatingOrApproval = _ref.sendForRatingOrApproval, fetchBrainstormingResults = _ref.fetchBrainstormingResults, fetchRatingResults = _ref.fetchRatingResults, fetchApprovalResults = _ref.fetchApprovalResults, closeBrainstorming = _ref.closeBrainstorming, closeRating = _ref.closeRating, closeApproval = _ref.closeApproval;

questionsGenerationAsync = {
  sendToMembers: function(params) {
    switch (params.type) {
      case 'questions.brainstorming':
        return sendInitial(params);
      case 'questions.rating':
      case 'questions.approval':
        return sendForRatingOrApproval(params);
    }
  },
  fetchStepResults: function(_arg) {
    var formsDocId, step;
    step = _arg.step, formsDocId = _arg.formsDocId;
    switch (step) {
      case 'questions.brainstorming':
        return fetchBrainstormingResults(formsDocId);
      case 'questions.rating':
        return fetchRatingResults(formsDocId);
      case 'questions.approval':
        return fetchApprovalResults(formsDocId);
    }
  },
  closeStep: function(_arg) {
    var acceptedIds, formsDocId, questions, step;
    step = _arg.step, formsDocId = _arg.formsDocId, questions = _arg.questions, acceptedIds = _arg.acceptedIds;
    switch (step) {
      case 'questions.brainstorming':
        return closeBrainstorming(formsDocId);
      case 'questions.rating':
        return closeRating(formsDocId);
      case 'questions.approval':
        return closeApproval(formsDocId, questions, acceptedIds);
    }
  }
};

module.exports = questionsGenerationAsync;
