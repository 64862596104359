Translation = require 'components/mixins/translation'
{ Entity } = Draft

styles =
  groupContainer:
    border: '1px solid #808080'
    padding: '5px'
    marginBottom: '30px'
  table:
    width: '100%'
    borderCollapse: 'collapse'
  thOutcome:
    border: '1px solid #808080'
    textAlign: 'left'
  thRating:
    border: '1px solid #808080'
    textAlign: 'right'
    width: '120px'
  tdName:
    border: '1px solid #808080'
  tdRating:
    border: '1px solid #808080'
    textAlign: 'right'

GeneratedOutcomesTable = createReactClass
  displayName: 'GeneratedOutcomesTable'

  mixins: [
    Translation('docsec:sections')
  ]

  _getData: ->
    entityKey = @props.block.getEntityAt(0)
    Entity.get(entityKey).getData()

  _getQuestionGroups: ->
    { qGroups } = @_getData()
    qGroups.map (group) ->
      group = Immutable.fromJS group
      group.set 'outcomes', group.get('outcomes').sort (a, b) ->
        importanceValues =
          critical: 2
          important: 1
          notImportant: 0
        importanceOrder =
          importanceValues[b.get 'importance'] - importanceValues[a.get 'importance']
        if importanceOrder
          importanceOrder
        else
          b.get('medianRating') - a.get 'medianRating'

  renderQuestionsGroup: (group, gidx) ->
    name = group.get 'name'
    questions = group.get 'questions'
    outcomes = group.get 'outcomes'

    <div key={gidx} style={styles.groupContainer}>
      <div>
        <dl>
          <dt>{@i18n '../in_questions'}: <b>{name}</b></dt>
          <dd>
            {questions.map (q, qidx) ->
              <div key={qidx}>
                {q.get 'question'}
              </div>
            .toList()
            }
          </dd>
        </dl>
      </div>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.thOutcome}>{@i18n '../outcomes'}</th>
            <th style={styles.thRating}>{@i18n '../median_rating'}</th>
          </tr>
        </thead>
        <tbody>
          {outcomes.map (o, oidx) ->
            rating = o.get 'medianRating'
            medianRatingVal = if isNaN(rating) or rating is -1 then '-' else rating
            <tr key={oidx}>
              <td style={styles.tdName}>{o.get 'name'}</td>
              <td style={styles.tdRating}>{medianRatingVal}</td>
            </tr>
          .toList()
          }
        </tbody>
      </table>
    </div>

  render: ->
    <div>
      {@_getQuestionGroups().map(@renderQuestionsGroup)}
    </div>

module.exports = GeneratedOutcomesTable
