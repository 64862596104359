var DraftRenderer, EVIDENCE_PROFILES_TYPES, MultiComparisonExporter, RECOMMENDATIONS_TYPES, W, etdHelper, exporterUtils, mediator, utils, _parseRowsIntoDocs,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

DraftRenderer = require('./draft_renderer');

W = require('when/when');

mediator = require('mediator');

etdHelper = require('lib/etd_helper');

utils = require('base/lib/utils');

exporterUtils = require('./exporter_utils');

RECOMMENDATIONS_TYPES = {
  'diagnostic': 'DiagnosticRecommendation',
  'management': 'ManagementRecommendation'
};

EVIDENCE_PROFILES_TYPES = {
  'diagnostic': 'DiagnosticEvidenceProfile',
  'management': 'ManagementEvidenceProfile'
};

_parseRowsIntoDocs = function(_arg) {
  var rows;
  rows = _arg.rows;
  return _.map(rows, function(row) {
    if ((row.error != null) || (row.doc == null)) {
      throw new Error($.t('mc:export.errors.docs_missing'));
    }
    return row.doc;
  });
};

module.exports = MultiComparisonExporter = (function() {
  function MultiComparisonExporter(mcDoc, pouchAdapter) {
    var etdIds, questionIds;
    this.mcDoc = mcDoc;
    this._exportRatingSection = __bind(this._exportRatingSection, this);
    this._exportSummaryOfJudgementsSection = __bind(this._exportSummaryOfJudgementsSection, this);
    this._exportIntervention = __bind(this._exportIntervention, this);
    this.interventions = {};
    questionIds = _.map(this.mcDoc.selectedQuestions, function(sq) {
      return sq.questionId;
    });
    etdIds = _.map(this.mcDoc.selectedQuestions, function(sq) {
      return sq.recommendationIds[0];
    });
    this.questionsTypesPromise = pouchAdapter.fetch(mediator.project.id, questionIds).then(_parseRowsIntoDocs).then(function(questions) {
      return _.reduce(questions, function(acc, question) {
        acc[question._id] = question.type === 'diagnostic' ? 'diagnostic' : 'management';
        return acc;
      }, {});
    });
    this.etdsTemplateDataPromise = pouchAdapter.fetch(mediator.project.id, etdIds).then(_parseRowsIntoDocs).then(function(etds) {
      return _.reduce(etds, function(acc, etd) {
        acc[etd._id] = Immutable.fromJS(etd.templateData);
        return acc;
      }, {});
    });
  }

  MultiComparisonExporter.prototype._exportInterventions = function() {
    return _.map(this.mcDoc.selectedInterventions, this._exportIntervention);
  };

  MultiComparisonExporter.prototype._exportIntervention = function(name, idx) {
    var id, _ref, _ref1;
    id = "_:i" + (idx + 1);
    this.interventions[name] = id;
    return {
      '@id': id,
      '@type': 'MultiComparisonIntervention',
      'name': name,
      'hidden': (_ref = (_ref1 = this.mcDoc.hiddenInterventions) != null ? _ref1[name] : void 0) != null ? _ref : false,
      'order': idx
    };
  };

  MultiComparisonExporter.prototype._exportForRecommendations = function(questionsTypes) {
    return _.map(this.mcDoc.selectedQuestions, _.partial(this._exportForRecommendation, questionsTypes));
  };

  MultiComparisonExporter.prototype._exportForRecommendation = function(questionsTypes, questionData, idx) {
    return {
      '@id': questionData.recommendationIds[0],
      '@type': RECOMMENDATIONS_TYPES[questionsTypes[questionData.questionId]],
      'order': idx,
      'forEvidenceProfile': {
        '@type': EVIDENCE_PROFILES_TYPES[questionsTypes[questionData.questionId]],
        '@id': questionData.questionId,
        'title': questionData.question,
        'intervention': questionData.intervention,
        'comparison': questionData.comparison,
        'healthProblemOrPopulation': questionData.healthProblemOrPopulation
      }
    };
  };

  MultiComparisonExporter.prototype._exportSummaryOfJudgementsSections = function(etdsTemplateData) {
    var currentMCType;
    currentMCType = _.first(this.mcDoc.selectedQuestions).type;
    return _.chain(this.mcDoc.sectionsImportanceForDecision).keys().union(currentMCType === 'diagnostic' ? ['certaintyOfEvidenceOfTestAccuracy'] : ['certaintyOfEvidence']).map(_.partial(this._exportSummaryOfJudgementsSection, etdsTemplateData)).value();
  };

  MultiComparisonExporter.prototype._exportSummaryOfJudgementsSection = function(etdsTemplateData, sectionId, idx) {
    var exportedSection, sectionIdUnderscored, _ref, _ref1, _ref2;
    sectionIdUnderscored = utils.toUnderscore(sectionId);
    exportedSection = {
      '@type': 'MultiComparisonSummaryOfJudgementSection',
      '@id': "_:sojs" + (idx + 1),
      'name': $.t("mc:review_tables.sections." + sectionIdUnderscored),
      'hidden': (_ref = (_ref1 = this.mcDoc.hiddenSections) != null ? (_ref2 = _ref1.soj) != null ? _ref2[sectionId] : void 0 : void 0) != null ? _ref : false,
      'judgement': this._exportJudgementsForSection(etdsTemplateData, sectionId)
    };
    if (sectionId !== 'certaintyOfEvidence' && sectionId !== 'certaintyOfEvidenceOfTestAccuracy') {
      exportedSection['importance'] = this.mcDoc.sectionsImportanceForDecision[sectionId];
    }
    return exportedSection;
  };

  MultiComparisonExporter.prototype._exportJudgementsForSection = function(etdsTemplateData, sectionId) {
    return _.chain(etdsTemplateData).pairs().map(function(_arg) {
      var etdId, etdTemplateData;
      etdId = _arg[0], etdTemplateData = _arg[1];
      return {
        'forRecommendation': {
          '@id': etdId
        },
        'value': etdHelper.getAssessmentSelectedOption(etdTemplateData, sectionId)
      };
    }).value();
  };

  MultiComparisonExporter.prototype._exportRatingSections = function() {
    return _.chain(this.mcDoc.interventionsRating).keys().map(this._exportRatingSection).value();
  };

  MultiComparisonExporter.prototype._exportRatingSection = function(sectionId, idx) {
    var comments, exportedSection, rating, sectionIdUnderscored, _ref, _ref1, _ref2, _ref3;
    sectionIdUnderscored = utils.toUnderscore(sectionId);
    rating = _.chain(this.mcDoc.interventionsRating[sectionId]).keys().map((function(_this) {
      return function(interventionName) {
        return _this._exportRatingForIntervention(sectionId, interventionName);
      };
    })(this)).value();
    exportedSection = {
      '@type': 'MultiComparisonRatingSection',
      '@id': "_:rs" + (idx + 1),
      'name': $.t("mc:review_tables.sections." + sectionIdUnderscored),
      'rating': rating,
      'hidden': (_ref = (_ref1 = this.mcDoc.hiddenSections) != null ? (_ref2 = _ref1.grading) != null ? _ref2[sectionId] : void 0 : void 0) != null ? _ref : false
    };
    comments = (_ref3 = this.mcDoc.sectionGradingComments) != null ? _ref3[sectionId] : void 0;
    if (comments != null) {
      exportedSection['comments'] = comments;
    }
    return exportedSection;
  };

  MultiComparisonExporter.prototype._exportRatingForIntervention = function(sectionId, interventionName) {
    var _ref, _ref1;
    return {
      '@type': 'InterventionRating',
      'forIntervention': {
        '@id': this.interventions[interventionName]
      },
      'rating': (_ref = (_ref1 = this.mcDoc.interventionsRating[sectionId]) != null ? _ref1[interventionName] : void 0) != null ? _ref : 0
    };
  };

  MultiComparisonExporter.prototype._exportConclusions = function() {
    return {
      'recommendation': this._exportRecommendations(),
      'justification': DraftRenderer.renderDraftJsContentToHTML(this.mcDoc.justification),
      'researchPriorities': DraftRenderer.renderDraftJsContentToHTML(this.mcDoc.researchPriorities),
      'monitoringAndEvaluation': DraftRenderer.renderDraftJsContentToHTML(this.mcDoc.monitoringAndEvaluation),
      'subgroupConsiderations': DraftRenderer.renderDraftJsContentToHTML(this.mcDoc.subgroupConsiderations),
      'implementationConsiderations': DraftRenderer.renderDraftJsContentToHTML(this.mcDoc.implementationConsiderations)
    };
  };

  MultiComparisonExporter.prototype._exportRecommendations = function() {
    var _ref;
    return _.map((_ref = this.mcDoc.recommendations) != null ? _ref : [], function(recommendation, idx) {
      return {
        '@type': 'Recommendation',
        '@id': "_:rec" + (idx + 1),
        'strength': recommendation.strength,
        'value': DraftRenderer.renderDraftJsContentToHTML(recommendation.text),
        'order': idx
      };
    });
  };

  MultiComparisonExporter.prototype["export"] = function() {
    return W.all([this.questionsTypesPromise, this.etdsTemplateDataPromise]).then((function(_this) {
      return function(_arg) {
        var basicInfo, etdsTemplateData, interventions, questionsTypes, _ref;
        questionsTypes = _arg[0], etdsTemplateData = _arg[1];
        interventions = _this._exportInterventions();
        basicInfo = {
          '@context': exporterUtils.multiComparisonContext(),
          '@id': _this.mcDoc._id,
          '@type': 'MultiComparisonRecommendation',
          'forRecommendation': _this._exportForRecommendations(questionsTypes),
          '@version': _this.mcDoc._rev.split('-')[0],
          'overarchingQuestion': _this.mcDoc.overarchingQuestion,
          'interventionsComparable': (_ref = _this.mcDoc.interventionsComparableDesc) != null ? _ref : '',
          'intervention': interventions,
          'summaryOfJudgementsSection': _this._exportSummaryOfJudgementsSections(etdsTemplateData),
          'ratingSection': _this._exportRatingSections()
        };
        return _.extend({}, basicInfo, _this._exportConclusions());
      };
    })(this));
  };

  return MultiComparisonExporter;

})();
