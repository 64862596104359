var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "  <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "mail_start", {"name":"i18n","hash":{},"data":data})))
    + "</p>\n  <p>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header", {"name":"i18n","hash":{
    'projectName': ((depth0 != null ? depth0.projectName : depth0))
  },"data":data})))
    + "</p>\n  <p>\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "link", {"name":"i18n","hash":{
    'coiFormType': ((depth0 != null ? depth0.coiFormType : depth0)),
    'projectName': ((depth0 != null ? depth0.projectName : depth0))
  },"data":data})))
    + " <a href=\"__link__\">__link__</a>\n  </p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "emails:coi.nhf", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}