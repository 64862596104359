observerOptions =
  childList: true
  subtree: true
  characterData: true
  attributes: true

DynamicHeightContainer = createReactClass
  displayName: 'DynamicHeightContainer'

  propTypes:
    className: PropTypes.string
    onTransitionEnd: PropTypes.func

  _ref: (el) ->
    @container = el

  _refInner: (el) ->
    @innerContainer = el

  _isContainerAttributesMutation: (mutation) ->
    mutation.target is @container and mutation.type is 'attributes'

  updateContainerHeight: (mutations) ->
    return unless @innerContainer?
    # ignore this component's attributes change (this happens when we update style attribute)
    return if mutations? and mutations.every(@_isContainerAttributesMutation)
    height = @innerContainer.scrollHeight
    newHeightStr = "#{height}px"
    return if @container.style.height is newHeightStr

    @container.style.height = newHeightStr

  componentDidMount: ->
    # without updateContainerHeight() here, it may happen that on first render childrens inside
    # will just appear without animation
    @updateContainerHeight()
    if window.MutationObserver
      @observer = new MutationObserver _.debounce @updateContainerHeight, 50
      @observer.observe @innerContainer, observerOptions

  componentWillUnmount: ->
    if @observer?
      @observer.disconnect()
      @observer = null

  componentDidUpdate: (prevProps, prevState) ->
    return if @observer
    @updateContainerHeight()

  handleTransitionEnd: (evt) ->
    $emitter = evt.target
    $listener = evt.currentTarget
    # nobody said that this element's child somewhere in the tree will not be another
    # DynamicHeightContainer. In that case we won't adjust this component's height properly
    # because `@innerContainer.scrollHeight` in the updateContainerHeight() method will still
    # hold old height value of the child DynamicHeightContainer. Only upon onTransitionEnd actual
    # height can be calculated
    if $emitter isnt $listener and $emitter.classList.contains 'dynamic-height-container'
      @updateContainerHeight()
      @props.onTransitionEnd?()

  render: ->
    containerCls = classNames 'dynamic-height-container', @props.className
    <div className={containerCls} ref={@_ref} onTransitionEnd={@handleTransitionEnd}>
      <div className='dynamic-height-container__inner' ref={@_refInner}>
        {@props.children}
      </div>
    </div>

module.exports = DynamicHeightContainer
