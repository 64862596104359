var EtdActions, EtdAsync, EtdsActions, MembersActions, PanelVoiceActions, PanelVoiceEtdActions, QuestionGroupsActions, alt, etdPreferences, getAdminPanelVoiceSummariesDocId, getEtdPreferencesDocId, getKeyValObject, mediator, panelVoiceAdminComments, _ref;

alt = require('alt');

mediator = require('mediator');

EtdActions = require('actions/etd_actions');

EtdsActions = require('actions/etds_actions');

QuestionGroupsActions = require('actions/question_groups_actions');

MembersActions = require('actions/members_actions');

EtdAsync = require('actions/async/etd');

PanelVoiceActions = require('actions/panel_voice_actions');

getKeyValObject = require('base/lib/utils').getKeyValObject;

_ref = require('lib/doc_prefixes'), panelVoiceAdminComments = _ref.panelVoiceAdminComments, etdPreferences = _ref.etdPreferences;

getAdminPanelVoiceSummariesDocId = function(projectId, etdId) {
  return "" + (panelVoiceAdminComments(projectId)) + etdId;
};

getEtdPreferencesDocId = function(projectId, etdId) {
  return "" + (etdPreferences(projectId)) + etdId;
};

PanelVoiceEtdActions = (function() {
  function PanelVoiceEtdActions() {
    this.generateActions('changeTab', 'fetchAdminSummariesError', 'fetchAdminSummariesSuccess', 'fetchViewSettingsError', 'fetchViewSettingsSuccess', 'setActiveMemberBySection', 'setSectionActiveJudgmentOption', 'setSectionResultsViewType', 'setShowComments', 'setSortBySection', 'toggleEtdSectionEdit', 'updateActiveRound', 'updateActiveStage');
  }

  PanelVoiceEtdActions.prototype.dbChange = function(data) {
    EtdActions.dbChange(data);
    return PanelVoiceActions.dbChange(data);
  };

  PanelVoiceEtdActions.prototype.fetchAdminSummaries = function(etdId, projectId) {
    var docId;
    if (projectId == null) {
      projectId = mediator.project.id;
    }
    this.dispatch();
    docId = getAdminPanelVoiceSummariesDocId(projectId, etdId);
    return mediator.services.storePersistenceAdapter.fetch(projectId, docId).then(this.actions.fetchAdminSummariesSuccess)["catch"](this.actions.fetchAdminSummariesError);
  };

  PanelVoiceEtdActions.prototype.updateAdminVotingSummary = function(data) {
    var content, docId, etdId, projectId, roundTimestamp, sectionId;
    etdId = data.etdId, sectionId = data.sectionId, roundTimestamp = data.roundTimestamp, content = data.content;
    this.dispatch(data);
    projectId = mediator.project.id;
    docId = getAdminPanelVoiceSummariesDocId(projectId, etdId);
    return mediator.services.storePersistenceAdapter.updateOrCreate(projectId, docId, function(doc) {
      return Immutable.fromJS(doc).setIn(['rounds', "" + roundTimestamp, 'sections', sectionId], content).toJS();
    });
  };

  PanelVoiceEtdActions.prototype.fetch = function(params) {
    var dbId, docId, etdId;
    dbId = params.dbId, docId = params.docId;
    etdId = docId[0];
    this.actions.fetchViewSettings(etdId, dbId);
    this.actions.fetchAdminSummaries(etdId, dbId);
    EtdActions.fetch(params);
    EtdsActions.fetch();
    PanelVoiceActions.fetchMetadata();
    PanelVoiceActions.fetchVotingResults();
    PanelVoiceActions.fetchEtdSnapshots(etdId, dbId);
    PanelVoiceActions.fetchEtdHighlightDocs();
    MembersActions.fetch();
    return QuestionGroupsActions.fetch();
  };

  PanelVoiceEtdActions.prototype.fetchViewSettings = function(etdId, projectId) {
    var etdPreferencesDocId;
    if (projectId == null) {
      projectId = mediator.project.id;
    }
    etdPreferencesDocId = getEtdPreferencesDocId(projectId, etdId);
    return mediator.services.storePersistenceAdapter.fetch(projectId, etdPreferencesDocId).then(this.actions.fetchViewSettingsSuccess)["catch"](this.actions.fetchViewSettingsError);
  };

  PanelVoiceEtdActions.prototype.updateViewSetting = function(_arg) {
    var etdId, etdPreferencesDocId, isActive, projectId, settingName;
    settingName = _arg.settingName, isActive = _arg.isActive, etdId = _arg.etdId;
    projectId = mediator.project.id;
    etdPreferencesDocId = getEtdPreferencesDocId(projectId, etdId);
    mediator.services.storePersistenceAdapter.updateOrCreate(projectId, etdPreferencesDocId, function(doc) {
      return _.extend(doc, getKeyValObject(settingName, isActive));
    });
    return this.dispatch({
      settingName: settingName,
      isActive: isActive
    });
  };

  return PanelVoiceEtdActions;

})();

module.exports = alt.createActions(PanelVoiceEtdActions);
