{ bool, string, func, instanceOf } = PropTypes
PanelVoiceStore = require 'stores/panel_voice_store'
ConnectStore = require 'components/enhancers/connect_store'
MemberInputItem = require 'components/team/form/member_input_item'
Translation = require "components/mixins/translation"
SpinnerButton = require 'components/common/spinner_button'
{ getReminderEmailBody } = require 'stores/utils/panel_voice_helpers'
mediator = require 'mediator'

storeConnector =
  PanelVoiceStore: (Store) ->
    sendingReminders: Store.isSendingReminders()
    panelVoicePreferences: Store.getPanelVoicePreferences()

ReminderForm = createReactClass
  displayName: "ReminderForm"

  mixins: [Translation('voting:voting')]

  propTypes:
    votedMemberIds: instanceOf(Immutable.List).isRequired
    sentMemberIds: instanceOf(Immutable.List).isRequired
    panelMembers: instanceOf(Immutable.List).isRequired # all panel members
    panelVoicePreferences: instanceOf(Immutable.Map).isRequired
    etdId: string.isRequired
    onSend: func.isRequired
    sendingReminders: bool.isRequired

  getInitialState: ->
    membersSelectionMap: new Immutable.Map()
    showPanelMembersWhoDidNotReciveForms: false

  membersWhoNotResponded: ->
    { sentMemberIds, votedMemberIds } = @props
    notRespondedMemberIds = sentMemberIds.filterNot (id) -> votedMemberIds.contains id
    @props.panelMembers.filter (member) ->
      notRespondedMemberIds.contains member.get("_id")

  membersWhoDidNotReceiveForms: ->
    { sentMemberIds } = @props
    @props.panelMembers.filterNot (member) ->
      sentMemberIds.contains member.get("_id")

  toggleMember: (e) ->
    memberId = e.target.value
    memberSelected = @state.membersSelectionMap.get(memberId, false)

    @setState membersSelectionMap: @state.membersSelectionMap.set(memberId, not memberSelected)

  _getCheckedMemberIds: ->
    @state.membersSelectionMap.filter((selected) -> selected).keySeq()

  sendReminders: ->
    message = getReminderEmailBody()
    @props.onSend @props.etdId, @_getCheckedMemberIds().toJS(), message

  showFullMembersList: ->
    @setState
      showPanelMembersWhoDidNotReciveForms: not @state.showPanelMembersWhoDidNotReciveForms

  renderShowAllMembersButton: ->
    buttonClasses = classNames "pull-right", "add-new-member"
    buttonLabel = @i18n '/team:member.add'

    <button
      className={buttonClasses}
      onClick={@showFullMembersList}
    >
      {buttonLabel}
    </button>

  renderMembersList: (members) ->
    <div className="members-list">
      <div className="panel-member-selection">
        <div className="members">
          {members.map (member) =>
            <MemberInputItem
              className="member-row-with-checkbox"
              key={member.get("_id")}
              type="checkbox"
              member={member}
              checked={@state.membersSelectionMap.get(member.get('_id'), false)}
              onChange={@toggleMember}
            />
          .toList()}
        </div>
      </div>
    </div>

  _isVotingSentToAllPanelMembers: ->
    @props.sentMemberIds.size is @props.panelMembers.size

  _didAllMembersRespond: ->
    @props.votedMemberIds.size is @props.sentMemberIds.size

  _allMembersVisible: ->
    @_isVotingSentToAllPanelMembers() or @state.showPanelMembersWhoDidNotReciveForms

  _getSendButtonLabelAndFormTitle: ->
    if @_allMembersVisible() and not @_isVotingSentToAllPanelMembers()
      formTitle: @i18n 'modals.add_members'
      buttonLabel: @i18n 'modals.add_members_and_send_form'
    else
      formTitle: @i18n 'modals.not_responed'
      buttonLabel: @i18n 'modals.send_reminder'

  renderMembersWhoDidNotRespond: ->
    <div>
      <div>{@i18n 'modals.send_reminder_to'}</div>
      {@renderMembersList @membersWhoNotResponded()}
    </div>

  render: ->
    { formTitle, buttonLabel } = @_getSendButtonLabelAndFormTitle()
    allMembersResponded = @_didAllMembersRespond()

    <div>
      <h2 className="text-center">{formTitle}</h2>

      {unless allMembersResponded
        @renderMembersWhoDidNotRespond()
      }

      {unless (allMembersResponded or @_allMembersVisible())
        @renderShowAllMembersButton()
      }

      <div className="clearfix" />
      {if @state.showPanelMembersWhoDidNotReciveForms or allMembersResponded
        <div className="send-form-new-members">
          <div>{@i18n 'modals.send_form_to'}</div>
          {@renderMembersList @membersWhoDidNotReceiveForms()}
        </div>
      }
      <SpinnerButton
        className="btn btn-block send-button"
        onClick={@sendReminders}
        loading={@props.sendingReminders}
        disabled={@state.membersSelectionMap.filter((selected) -> selected).isEmpty()}
        label={buttonLabel}
      />
    </div>

module.exports = {
  ReminderForm,
  ReminderFormContainer: ConnectStore ReminderForm, [PanelVoiceStore], storeConnector
}
