{ getChaperIconPath, CHAPTER_ICONS } = require 'lib/da_helper'


ChapterIconImage = ({ iconName }) ->
  <img
    alt={iconName}
    className='chapter-icon'
    src={getChaperIconPath iconName}
  />

ChapterIconImage.propTypes =
  iconName: PropTypes.oneOf(CHAPTER_ICONS).isRequired

module.exports = ChapterIconImage
