OverReviewActions = require 'actions/over_review_actions'
EditableCell = require 'components/evidence_syntheses/outcomes/cells/editable_cell'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
QS = require 'models/outcome/quality_scores'
{ SelectionList, SelectionListOption } =
  require 'components/evidence_syntheses/outcomes/cells/selection_list'
{ getKeyValObject } = require 'base/lib/utils'


optionNames = [ 'None', 'Serious', 'VerySerious' ]

DefaultQualityModifierCell = (modifierName) -> createReactClass
  displayName: 'DefaultQualityModifierCell'

  propTypes:
    outcomeAttrs: PropTypes.object.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]

  _ref: (el) ->
    @el = el

  handleOptionSelect: (optionName) ->
    updatedAttributeObject = getKeyValObject modifierName,
      if optionName then QS.get(optionName) else optionName

    OverReviewActions.updateSourceOutcome
      outcomeId: @props.outcomeAttrs['_id']
      sourceId: @props.sourceId
      questionId: @props.questionId
      outcomeData: updatedAttributeObject

    @el.stopEdit()

  renderOption: (optionName) ->
    { label } = QS.get optionName

    <SelectionListOption
      key={optionName}
      label={@i18n label}
      value={optionName}
    />

  render: ->
    selectedOption = @props.outcomeAttrs[modifierName]?.name

    <EditableCell {...@props} ref={@_ref} title={@i18n _.str.underscored modifierName}>
      <SelectionList
        onClear={@handleOptionSelect}
        onSelect={@handleOptionSelect}
        selectedValue={selectedOption}
      >
        {optionNames.map @renderOption}
      </SelectionList>
    </EditableCell>

module.exports = DefaultQualityModifierCell
