var EBMonFHIRManagementQuestionExporter, baseUtils, certaintyModifiers, effectMeasures, formatter, getCertaintyLabelForScore, getCertaintyNameForValue, getCertaintyOptionForLabel, getEffectMeasure, getOutcomeStudyDesign, outcomeTypes, qualities, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

baseUtils = require('base/lib/utils');

utils = require('lib/services/jsonld_exporter/exporter_utils');

formatter = require('lib/formatters').outcome;

certaintyModifiers = require('models/outcome').QUALITY_MODIFIERS;

qualities = require('models/outcome/qualities');

outcomeTypes = {
  'dich': 'dichotomous',
  'cont': 'continuous',
  'narrative': 'narrative'
};

effectMeasures = {
  'RR': 'risk ratio',
  'HR': 'hazard ratio',
  'OR': 'odds ratio',
  'other': 'other effect measure',
  'rateRatio': 'rate ratio',
  'MD': 'mean difference',
  'SMD': 'standardized mean difference',
  'median': 'median',
  'mean': 'mean'
};

getCertaintyNameForValue = function(qualityValue) {
  var quality;
  if (qualityValue > 4) {
    qualityValue = 4;
  }
  if (qualityValue < 1) {
    qualityValue = 1;
  }
  quality = qualities["" + qualityValue].toLowerCase().replace(/\ /g, "_");
  return $.t("es:outcome.qualities." + quality).toUpperCase();
};

getCertaintyLabelForScore = function(score) {
  switch (score) {
    case 0:
      return 'NoChange';
    case -1:
      return 'DowngradeOneLevel';
    case -2:
      return 'DowngradeTwoLevels';
    case 1:
      return 'UpgradeOneLevel';
    case 2:
      return 'UpgradeTwoLevels';
    default:
      return null;
  }
};

getCertaintyOptionForLabel = function(certaintyLabel, field) {
  if (field == null) {
    field = '';
  }
  if (certaintyLabel === '') {
    return '';
  } else {
    switch (field) {
      case 'publicationBias':
        if (certaintyLabel === 'not_serious') {
          return $.t('es:outcome.undetected');
        } else {
          return $.t('es:outcome.publication_bias_strongly_suspected_short');
        }
        break;
      default:
        return $.t("es:outcome." + certaintyLabel);
    }
  }
};

getEffectMeasure = function(outcome) {
  var effectMeasure;
  effectMeasure = outcome['effectMeasure'];
  if (effectMeasure in effectMeasures) {
    effectMeasure = effectMeasures[effectMeasure];
  }
  return effectMeasure;
};

getOutcomeStudyDesign = function(studyDesign) {
  if (!studyDesign) {
    return null;
  }
  if ((studyDesign != null ? typeof studyDesign.indexOf === "function" ? studyDesign.indexOf('ObsStudies.') : void 0 : void 0) === 0) {
    return 'observational study';
  }
  return 'randomised trial';
};

module.exports = EBMonFHIRManagementQuestionExporter = (function() {
  function EBMonFHIRManagementQuestionExporter(questionJson) {
    this.questionJson = questionJson;
    this._exportEffectEvidenceSynthesisForOutcome = __bind(this._exportEffectEvidenceSynthesisForOutcome, this);
  }

  EBMonFHIRManagementQuestionExporter.prototype["export"] = function() {
    var effectEvidenceSynthesis, effectEvidenceSynthesisCount, picDefinition;
    picDefinition = this._exportPicDefinition();
    effectEvidenceSynthesis = this._exportEffectEvidenceSynthesis(picDefinition);
    effectEvidenceSynthesisCount = effectEvidenceSynthesis.length;
    return {
      composition: {
        identifier: this.questionJson['_id'],
        effectEvidenceSynthesis: effectEvidenceSynthesis,
        effectEvidenceSynthesisCount: effectEvidenceSynthesisCount,
        picDefinition: picDefinition
      }
    };
  };

  EBMonFHIRManagementQuestionExporter.prototype._exportEffectEvidenceSynthesis = function(picDefinition) {
    return _.chain(this.questionJson.outcomes).map(_.partial(this._exportEffectEvidenceSynthesisForOutcome, picDefinition)).compact().value();
  };

  EBMonFHIRManagementQuestionExporter.prototype._exportEffectEvidenceSynthesisForOutcome = function(picDefinition, outcome, idx) {
    var outcomeData, outcomeId, studytype, _ref, _ref1, _ref2, _ref3, _ref4, _ref5, _ref6, _ref7, _ref8;
    if (outcome.type === 'narrative') {
      return null;
    }
    if ((_ref = outcome.metaType) !== 'POOLED' && _ref !== 'SINGLE_STUDY') {
      return null;
    }
    outcomeId = (_ref1 = outcome['_id']) != null ? _ref1 : idx + 1;
    outcomeData = this._exportOutcomeData(outcome, outcomeId);
    studytype = getOutcomeStudyDesign((_ref2 = outcome['designStudies']) != null ? _ref2['name'] : void 0);
    return {
      identifier: "" + this.questionJson['_id'] + "." + outcomeId,
      synthesistype: 'summary data meta-analysis',
      studytype: studytype,
      extractiontype: 'original data',
      date: utils.timestampToISOWithTimeZone(this.questionJson['$timestamp']),
      steward: (_ref3 = (_ref4 = this.questionJson['authors']) != null ? typeof _ref4.trim === "function" ? _ref4.trim() : void 0 : void 0) != null ? _ref3 : '',
      reporter: (_ref5 = (_ref6 = this.questionJson['authors']) != null ? typeof _ref6.trim === "function" ? _ref6.trim() : void 0 : void 0) != null ? _ref5 : '',
      reporterrole: 'synthesis author',
      picDefinition: picDefinition,
      outcome: outcomeData,
      sampleSize: {
        numberStudies: outcome['noOfStudies'],
        numberParticipants: ((_ref7 = outcome['interventionTotal']) != null ? _ref7 : 0) + ((_ref8 = outcome['controlTotal']) != null ? _ref8 : 0)
      },
      resultsByExposure: _.compact(this._exportResultsByExposure(picDefinition, outcomeData, studytype, outcome, outcomeId)),
      effectEstimate: this._exportEffectEstimates(outcome),
      certainty: this._exportCertainty(outcome)
    };
  };

  EBMonFHIRManagementQuestionExporter.prototype._exportOutcomeData = function(outcome, outcomeId) {
    var outcomeData, _ref, _ref1, _ref2;
    outcomeData = {
      identifier: "outcome_" + outcomeId + "_for_question_" + this.questionJson['_id'],
      type: 'outcome',
      outcometype: outcomeTypes[(_ref = outcome['type']) != null ? typeof _ref.toLowerCase === "function" ? (_ref1 = _ref.toLowerCase()) != null ? typeof _ref1.trim === "function" ? _ref1.trim() : void 0 : void 0 : void 0 : void 0],
      shortTitle: (_ref2 = outcome['name']) != null ? typeof _ref2.trim === "function" ? _ref2.trim() : void 0 : void 0,
      characteristic: []
    };
    if (outcome['importance']) {
      outcomeData.extension = [
        {
          url: 'https://dbep.gradepro.org/schema/GradeImportance',
          importance: {
            type: 'GradeImportance',
            value: parseInt(outcome['importance']),
            name: outcome['importanceLabel']
          }
        }
      ];
    }
    return outcomeData;
  };

  EBMonFHIRManagementQuestionExporter.prototype._exportResultsByExposure = function(picDefinition, outcomeData, studytype, outcome, outcomeId) {
    switch (outcome.type) {
      case 'dich':
        return this._exportResultsByExposureDichotomous(picDefinition, outcomeData, studytype, outcome, outcomeId);
      case 'cont':
        return this._exportResultsByExposureContinuous(picDefinition, outcomeData, studytype, outcome, outcomeId);
      default:
        return null;
    }
  };

  EBMonFHIRManagementQuestionExporter.prototype._exportResultsByExposureDichotomous = function(picDef, outcomeData, studytype, outcome, outcomeId) {
    var comparisonRiskEvidenceSynthesis, interventionRiskEvidenceSynthesis;
    interventionRiskEvidenceSynthesis = _.chain(picDef).clone().pick('population', 'exposure').extend({
      outcome: _.clone(outcomeData),
      studytype: studytype,
      identifier: ("exposure_riskEvidenceSynthesis_for_outcome_" + outcomeId + "_") + ("for_question_" + this.questionJson['_id']),
      riskEstimate: {
        value: parseFloat(utils.calculatedInterventionPercentage(outcome)),
        nominator: outcome['interventionCount'],
        denominator: outcome['interventionTotal']
      }
    }).value();
    comparisonRiskEvidenceSynthesis = _.chain({}).extend(_.pick(picDef, 'population')).extend({
      exposure: picDef.exposureAlternative,
      outcome: _.clone(outcomeData),
      studytype: studytype,
      identifier: ("exposure_alternative_riskEvidenceSynthesis_for_outcome_" + outcomeId + "_") + ("for_question_" + this.questionJson['_id']),
      riskEstimate: {
        value: parseFloat(utils.calculatedControlPercentage(outcome)),
        nominator: outcome['controlCount'],
        denominator: outcome['controlTotal']
      }
    }).value();
    return [interventionRiskEvidenceSynthesis, comparisonRiskEvidenceSynthesis];
  };

  EBMonFHIRManagementQuestionExporter.prototype._exportResultsByExposureContinuous = function(picDef, outcomeData, studytype, outcome, outcomeId) {
    var comparisonRiskEvidenceSynthesis, interventionRiskEvidenceSynthesis;
    interventionRiskEvidenceSynthesis = _.chain(picDef).clone().pick('population', 'exposure').extend({
      outcome: _.clone(outcomeData),
      studytype: studytype,
      identifier: ("exposure_riskEvidenceSynthesis_for_outcome_" + outcomeId + "_") + ("for_question_" + this.questionJson['_id']),
      riskEstimate: {
        value: 'NOT REPORTED',
        denominator: outcome['interventionTotal']
      }
    }).value();
    comparisonRiskEvidenceSynthesis = _.chain({}).extend(_.pick(picDef, 'population')).extend({
      exposure: picDef.exposureAlternative,
      outcome: _.clone(outcomeData),
      studytype: studytype,
      identifier: ("exposure_alternative_riskEvidenceSynthesis_for_outcome_" + outcomeId + "_") + ("for_question_" + this.questionJson['_id']),
      riskEstimate: {
        value: 'NOT REPORTED',
        denominator: outcome['controlTotal']
      }
    }).value();
    return [interventionRiskEvidenceSynthesis, comparisonRiskEvidenceSynthesis];
  };

  EBMonFHIRManagementQuestionExporter.prototype._exportEffectEstimates = function(outcome) {
    var coefficient, effectEstimates;
    effectEstimates = [];
    coefficient = outcome.type === 'dich' ? -1 : 1;
    if (outcome.type === 'dich') {
      effectEstimates.push({
        description: formatter.relativeEffect(outcome, true),
        absolutetype: 'relative difference',
        specifictype: getEffectMeasure(outcome),
        value: parseFloat(outcome['effectSize']),
        precisionEstimate: {
          precisionEstimateType: "confidence interval",
          precisionEstimateLevel: 0.95,
          precisionEstimateFrom: parseFloat(outcome['confidenceIntervalFrom']),
          precisionEstimateTo: parseFloat(outcome['confidenceIntervalTo'])
        }
      });
    }
    effectEstimates.push({
      description: formatter.absoluteEffect(outcome, true),
      absolutetype: 'absolute difference',
      specifictype: outcome.type === 'dich' ? 'absolute risk difference' : getEffectMeasure(outcome),
      value: coefficient * parseFloat(outcome['absEffectSize']),
      precisionEstimate: {
        precisionEstimateType: "confidence interval",
        precisionEstimateLevel: 0.95,
        precisionEstimateFrom: coefficient * parseFloat(outcome['absEffectFrom']),
        precisionEstimateTo: coefficient * parseFloat(outcome['absEffectTo'])
      }
    });
    return effectEstimates;
  };

  EBMonFHIRManagementQuestionExporter.prototype._exportCertainty = function(outcome) {
    var certaintyComponents;
    certaintyComponents = _.chain(outcome).pick(certaintyModifiers).pairs().map(function(_arg) {
      var field, label, score, _ref;
      field = _arg[0], (_ref = _arg[1], score = _ref.score, label = _ref.label);
      if (!((score != null) && (label != null))) {
        return null;
      }
      return {
        type: {
          coding: {
            system: 'GRADE',
            code: field,
            display: $.t("es:outcome." + (baseUtils.toUnderscore(field))).toLowerCase()
          }
        },
        rating: {
          coding: {
            system: 'GRADE',
            code: getCertaintyLabelForScore(score),
            display: getCertaintyOptionForLabel(label, field).toLowerCase()
          }
        }
      };
    }).compact().value();
    return {
      rating: {
        coding: {
          system: 'GRADE',
          code: outcome.quality,
          display: getCertaintyNameForValue(outcome.quality)
        }
      },
      certaintySubcomponent: certaintyComponents
    };
  };

  EBMonFHIRManagementQuestionExporter.prototype._exportPicDefinition = function() {
    var _ref, _ref1, _ref2;
    return {
      identifier: "PIC_definition_for_question_" + this.questionJson['_id'],
      population: {
        identifier: "Population_for_question_" + this.questionJson['_id'],
        shortTitle: (_ref = this.questionJson['healthProblemOrPopulation']) != null ? typeof _ref.trim === "function" ? _ref.trim() : void 0 : void 0,
        type: 'population',
        characteristic: []
      },
      exposure: {
        identifier: "Intervention_for_question_" + this.questionJson['_id'],
        shortTitle: (_ref1 = this.questionJson['intervention']) != null ? typeof _ref1.trim === "function" ? _ref1.trim() : void 0 : void 0,
        type: 'exposure',
        characteristic: []
      },
      exposureAlternative: {
        identifier: "Comparison_for_question_" + this.questionJson['_id'],
        shortTitle: (_ref2 = this.questionJson['comparison']) != null ? typeof _ref2.trim === "function" ? _ref2.trim() : void 0 : void 0,
        type: 'exposureAlternative',
        characteristic: []
      }
    };
  };

  return EBMonFHIRManagementQuestionExporter;

})();
