var ComparisonQuestionsList, ImportActions, QuestionsListComponentActions, QuestionsListToolbar, QuestionsViewReact, ReactComponent, ReactToolbarView, View, alt, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

ComparisonQuestionsList = require('components/evidence_syntheses/comparison_questions_list');

ImportActions = require('actions/import_actions');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

QuestionsListToolbar = require('components/evidence_syntheses/questions_list_toolbar');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = QuestionsViewReact = (function(_super) {
  __extends(QuestionsViewReact, _super);

  function QuestionsViewReact() {
    this.updateComponent = __bind(this.updateComponent, this);
    return QuestionsViewReact.__super__.constructor.apply(this, arguments);
  }

  QuestionsViewReact.prototype.container = '#page-container';

  QuestionsViewReact.prototype.autoRender = true;

  QuestionsViewReact.prototype.initialize = function() {
    QuestionsViewReact.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: QuestionsListToolbar
    }));
    this.enable(ReactComponent);
    return mediator.user.on('change:showDropQuestionGroupArea', this.updateComponent);
  };

  QuestionsViewReact.prototype.getComponentProps = function() {
    return {
      onQuestionsImport: this.handleQuestionsImport,
      showDropQuestionGroupArea: mediator.user.get('showDropQuestionGroupArea')
    };
  };

  QuestionsViewReact.prototype.updateComponent = function() {
    return this.updateRenderedComponent(this.getComponentProps());
  };

  QuestionsViewReact.prototype.afterRender = function() {
    QuestionsViewReact.__super__.afterRender.apply(this, arguments);
    this.$el.css('height', '100%');
    return this.renderComponent(ComparisonQuestionsList, QuestionsListComponentActions, null, {
      props: this.getComponentProps(),
      fetchParams: {
        questionsCollectionType: 'regular'
      },
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  QuestionsViewReact.prototype.handleQuestionsImport = function() {
    return ImportActions.openImportModal({
      importType: 'question'
    });
  };

  QuestionsViewReact.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    mediator.user.off('change:showDropQuestionGroupArea', this.updateComponent);
    this.unmountComponent();
    alt.recycle('QuestionsStore', 'QuestionGroupsStore', 'QuestionsListComponentStore', 'QuestionsStatusesStore', 'OrganizationsStore', 'OverarchingQuestionsStore');
    return QuestionsViewReact.__super__.dispose.apply(this, arguments);
  };

  return QuestionsViewReact;

})(View);
