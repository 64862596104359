var ChangeQualityIndicatorsTemplate, EtdTemplateHelper, Migration, W, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

EtdTemplateHelper = require('stores/utils/etd_template_helper');

migrationName = 'change_quality_indicators_template';

module.exports = ChangeQualityIndicatorsTemplate = (function(_super) {
  __extends(ChangeQualityIndicatorsTemplate, _super);

  function ChangeQualityIndicatorsTemplate() {
    ChangeQualityIndicatorsTemplate.__super__.constructor.call(this, false);
  }

  ChangeQualityIndicatorsTemplate.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    ChangeQualityIndicatorsTemplate.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('qualityIndicatorsId'));
    }, []);
    return adapter.fetch(projectId, _.compact(_.uniq(docIds))).then(function(_arg) {
      var QIDocIds, rows;
      rows = _arg.rows;
      QIDocIds = _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').pluck('recommendationIds').concat(['etd-qualityIndicators-template']).value();
      return adapter.fetch(projectId, _.compact(_.uniq(_.flatten(QIDocIds))));
    }).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var newTemplate, updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return W.resolve();
      }
      newTemplate = EtdTemplateHelper.prepareBaseTemplate('qualityIndicators').toJS();
      updatedDocs = _.map(templateDocs, function(doc) {
        return _.extend({}, doc, newTemplate, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return ChangeQualityIndicatorsTemplate;

})(Migration);
