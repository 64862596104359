var DashboardActions, HISTORY_DIALOG_INITIAL_STATE, INITIAL_STATE, appUtils, generateStore, getMembersByLoginMapOfCurrentProject, mergeHistoryEntries;

appUtils = require('lib/app_utils');

DashboardActions = require('actions/dashboard_actions');

generateStore = require('stores/utils/generate_store');

getMembersByLoginMapOfCurrentProject = require('lib/members_helper').getMembersByLoginMapOfCurrentProject;

mergeHistoryEntries = require('lib/project_history_helper').mergeHistoryEntries;

HISTORY_DIALOG_INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false,
    isOpen: false
  },
  filterCategory: 'all',
  filterSelectedItems: [],
  history: [],
  docSecChapters: [],
  docSecSections: []
});

INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false,
    confirmationDialogType: null
  },
  historyDialog: HISTORY_DIALOG_INITIAL_STATE
});

module.exports = generateStore({
  name: 'DashboardStore',
  initialState: INITIAL_STATE,
  boundActions: [DashboardActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], true));
    },
    onFetchSuccess: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], false));
    },
    onFetchError: function() {
      return this.setState(this.state.setIn(['meta', 'fetching'], false));
    },
    onOpenConfirmationDialog: function(type) {
      return this.setState(this.state.setIn(['meta', 'confirmationDialogType'], type));
    },
    onCloseConfirmationDialog: function() {
      return this.setState(this.state.setIn(['meta', 'confirmationDialogType'], null));
    },
    onOpenHistoryDialogAndFetchHistory: function() {
      return this.setState(this.state.setIn(['historyDialog', 'meta', 'isOpen'], true));
    },
    onFetchHistory: function() {
      return this.setState(this.state.setIn(['historyDialog', 'meta', 'isFetching'], true));
    },
    onFetchHistorySuccess: function(_arg) {
      var docSecChapters, docSecSections, history;
      docSecChapters = _arg.docSecChapters, docSecSections = _arg.docSecSections, history = _arg.history;
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['historyDialog', 'meta', 'isFetching'], false);
        state.setIn(['historyDialog', 'history'], Immutable.fromJS(mergeHistoryEntries(history)));
        state.setIn(['historyDialog', 'docSecChapters'], Immutable.fromJS(docSecChapters));
        return state.setIn(['historyDialog', 'docSecSections'], Immutable.fromJS(docSecSections));
      }));
    },
    onFetchHistoryError: function(_arg) {
      var err, errorShouldCloseModal;
      err = _arg.err, errorShouldCloseModal = _arg.errorShouldCloseModal;
      appUtils.errorHandlerWithMsg(err, $.t('projects:dashboard.history_modal.fetch_error'));
      if (errorShouldCloseModal) {
        this.setState(this.state.setIn(['historyDialog', 'meta', 'isOpen'], false));
        return setTimeout(((function(_this) {
          return function() {
            return _this.setState(_this.state.set('historyDialog', HISTORY_DIALOG_INITIAL_STATE));
          };
        })(this)), 300);
      }
    },
    onCloseHistoryDialog: function() {
      this.setState(this.state.setIn(['historyDialog', 'meta', 'isOpen'], false));
      return setTimeout(((function(_this) {
        return function() {
          return _this.setState(_this.state.set('historyDialog', HISTORY_DIALOG_INITIAL_STATE));
        };
      })(this)), 300);
    },
    onSelectFilterCategory: function(_arg) {
      var category, items;
      category = _arg.category, items = _arg.items;
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['historyDialog', 'filterCategory'], category);
        return state.setIn(['historyDialog', 'filterSelectedItems'], Immutable.fromJS(items));
      }));
    },
    onSelectFilterItems: function(items) {
      return this.setState(this.state.setIn(['historyDialog', 'filterSelectedItems'], Immutable.fromJS(items)));
    },
    onClearFilters: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['historyDialog', 'filterCategory'], 'all');
        return state.setIn(['historyDialog', 'filterSelectedItems'], Immutable.List());
      }));
    },
    getConfirmationDialogType: function() {
      return this.state.getIn(['meta', 'confirmationDialogType'], null);
    },
    getHistory: function() {
      var allHistory, filterCategory, filterSelectedItems, members;
      allHistory = this.state.getIn(['historyDialog', 'history'], Immutable.List());
      filterCategory = this.state.getIn(['historyDialog', 'filterCategory'], 'all');
      if (filterCategory === 'all') {
        return allHistory;
      }
      filterSelectedItems = this.state.getIn(['historyDialog', 'filterSelectedItems'], Immutable.List());
      members = getMembersByLoginMapOfCurrentProject();
      return allHistory.filter(function(entry) {
        switch (filterCategory) {
          case 'user':
            return filterSelectedItems.includes(entry.get('revAuthor'));
          case 'action':
            return filterSelectedItems.includes(entry.get('type'));
          case 'role':
            return !(members.getIn([entry.get('revAuthor'), 'accessRights'], Immutable.List()).toSet().intersect(filterSelectedItems).isEmpty());
          default:
            return false;
        }
      });
    },
    getDocSecChapters: function() {
      return this.state.getIn(['historyDialog', 'docSecChapters'], Immutable.List());
    },
    getDocSecSections: function() {
      return this.state.getIn(['historyDialog', 'docSecSections'], Immutable.List());
    },
    getFilterCategory: function() {
      return this.state.getIn(['historyDialog', 'filterCategory'], 'all');
    },
    getFilterSelectedItems: function() {
      return this.state.getIn(['historyDialog', 'filterSelectedItems'], Immutable.List());
    },
    isHistoryDialogOpen: function() {
      return this.state.getIn(['historyDialog', 'meta', 'isOpen']);
    },
    isFetchingHistory: function() {
      return this.state.getIn(['historyDialog', 'meta', 'isFetching']);
    }
  }
});
