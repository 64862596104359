var DocumentSectionsController, DocumentSectionsFromTemplateView, DocumentSectionsView, DocumentSectionsViewLegacy, DocumentSectionsViewV2, ModuleController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

DocumentSectionsView = require('views/document_sections_view');

DocumentSectionsFromTemplateView = require('views/document_sections_from_template_view');

DocumentSectionsViewLegacy = require('views/document_sections_view_legacy');

DocumentSectionsViewV2 = require('views/document_sections_view_v2');

mediator = require('mediator');

module.exports = DocumentSectionsController = (function(_super) {
  __extends(DocumentSectionsController, _super);

  function DocumentSectionsController() {
    return DocumentSectionsController.__super__.constructor.apply(this, arguments);
  }

  DocumentSectionsController.prototype.index = function() {
    return this.view = (function() {
      if (mediator.services.switches.isOn('docSectionsTemplate')) {
        return new DocumentSectionsFromTemplateView;
      } else if (mediator.services.switches.isOn('docSectionsV2')) {
        return new DocumentSectionsViewV2;
      } else {
        switch (mediator.projectDocumentSectionsVersion) {
          case 'DraftJS':
            return new DocumentSectionsView;
          case 'legacy':
            return new DocumentSectionsViewLegacy({
              model: mediator.project
            });
          default:
            return new DocumentSectionsFromTemplateView;
        }
      }
    })();
  };

  return DocumentSectionsController;

})(ModuleController);
