var caseControl, clearOption, compoundTemplate, contentEditablePartial, conventionalScaleLimits, copyrightNotice, diagnosticMeta, diagnosticPrintoutSofSettings, effect, finishedOutcomesGeneration, followUpLength, footnotesAndReferences, inputBlockTemplate, interpretationOfResults, languageSelect, noOfParticipantsStudiesEmbedded, obsStudiesSelection, outcomesGenerationComments, outcomesGenerationList, panelVoiceExportOption, pooledSensitivitySpecificity, printoutStylesHead, prognosticObsStudiesSelection, questionGroupElement, rangeOfScores, recommendationDxEvidenceTable, recommendationRadiosTable, recommendationsAppendices, relativeImportanceMainOutcomesTable, rm5TableFooter, robinsIToggle, scoreUnits, searchBar, selectableAttr, selectionListTemplate, sofShortTable, testAccuracySummary, timeToEventSelector;

obsStudiesSelection = require('views/templates/outcome_edit/partials/obs_studies_selection');

clearOption = require('views/templates/outcome_edit/partials/clear_option');

noOfParticipantsStudiesEmbedded = require('views/templates/outcome_edit/partials/no_of_participants_studies_embedded');

selectionListTemplate = require('views/templates/outcome_edit/selection_template');

compoundTemplate = require('views/templates/outcome_edit/compound_template');

inputBlockTemplate = require('views/templates/outcome_edit/input_blocks/input_block_template');

selectableAttr = require('views/templates/outcome_edit/partials/selectable_attr');

contentEditablePartial = require('views/templates/evidence_syntheses/partials/content_editable');

questionGroupElement = require('views/templates/evidence_syntheses/partials/question_group_element');

languageSelect = require('views/templates/language_select');

copyrightNotice = require('views/templates/copyright_notice');

pooledSensitivitySpecificity = require('views/templates/evidence_syntheses/pooled_sensitivity_specificity');

recommendationRadiosTable = require('views/templates/evidence_syntheses/partials/recommendation_radios_table');

recommendationDxEvidenceTable = require('views/templates/evidence_syntheses/partials/recommendation_dx_evidence_table');

printoutStylesHead = require('views/templates/printouts/styles_head');

diagnosticMeta = require('views/templates/evidence_syntheses/partials/diagnostic_meta');

rm5TableFooter = require('views/templates/export/partials/rm5_table_footer');

followUpLength = require('views/templates/outcome_edit/partials/follow_up_length');

effect = require('views/templates/outcome_edit/partials/effect');

caseControl = require('views/templates/outcome_edit/partials/case_control');

diagnosticPrintoutSofSettings = require('views/templates/printouts/diagnostic/sof_settings');

sofShortTable = require('views/templates/evidence_syntheses/partials/sof_short');

relativeImportanceMainOutcomesTable = require('views/templates/evidence_syntheses/partials/relative_importance_main_outcomes_table');

scoreUnits = require('views/templates/outcome_edit/partials/score_units');

testAccuracySummary = require('views/templates/evidence_syntheses/partials/test_accuracy');

recommendationsAppendices = require('views/templates/evidence_syntheses/partials/recommendations_appendices');

outcomesGenerationComments = require('views/templates/printouts/scope/outcome_generation_comments');

outcomesGenerationList = require('views/templates/printouts/scope/outcomes_generation_list');

finishedOutcomesGeneration = require('views/templates/printouts/scope/finished_outcomes_generation');

footnotesAndReferences = require('views/templates/printouts/partials/footnotes_and_references');

searchBar = require('views/templates/search_bar');

panelVoiceExportOption = require('views/templates/export/partials/panel_voice_export_option');

timeToEventSelector = require('views/templates/outcome_edit/partials/time_to_event_selector');

robinsIToggle = require('views/templates/outcome_edit/partials/robins_i_toggle');

prognosticObsStudiesSelection = require('views/templates/outcome_edit/partials/prognostic_obs_studies_selection');

rangeOfScores = require('views/templates/outcome_edit/partials/range_of_scores');

conventionalScaleLimits = require('views/templates/outcome_edit/partials/conventional_scale_limits');

interpretationOfResults = require('views/templates/outcome_edit/partials/interpretation_of_results');

Handlebars.registerPartial("obsStudiesSelection", obsStudiesSelection);

Handlebars.registerPartial("clearOption", clearOption);

Handlebars.registerPartial("noOfParticipantsStudiesEmbedded", noOfParticipantsStudiesEmbedded);

Handlebars.registerPartial("selectionListTemplate", selectionListTemplate);

Handlebars.registerPartial("compoundTemplate", compoundTemplate);

Handlebars.registerPartial("inputBlockTemplate", inputBlockTemplate);

Handlebars.registerPartial("selectableAttrTemplate", selectableAttr);

Handlebars.registerPartial("contentEditablePartial", contentEditablePartial);

Handlebars.registerPartial('addQuestionsButtons', require('views/templates/evidence_syntheses/partials/add_questions_buttons'));

Handlebars.registerPartial('outcomeButtons', require('views/templates/evidence_syntheses/partials/outcome_buttons'));

Handlebars.registerPartial('questionGroupElement', questionGroupElement);

Handlebars.registerPartial('languageSelect', languageSelect);

Handlebars.registerPartial('copyrightNotice', copyrightNotice);

Handlebars.registerPartial('pooledSensitivitySpecificity', pooledSensitivitySpecificity);

Handlebars.registerPartial('recommendationRadiosTable', recommendationRadiosTable);

Handlebars.registerPartial('recommendationDxEvidenceTable', recommendationDxEvidenceTable);

Handlebars.registerPartial('printoutStylesHead', printoutStylesHead);

Handlebars.registerPartial('diagnosticMeta', diagnosticMeta);

Handlebars.registerPartial('rm5TableFooter', rm5TableFooter);

Handlebars.registerPartial('followUpLength', followUpLength);

Handlebars.registerPartial('effect', effect);

Handlebars.registerPartial('diagnosticPrintoutSofSettings', diagnosticPrintoutSofSettings);

Handlebars.registerPartial('riskDifferenceWithInterventionEditPartial', require('views/templates/outcome_edit/partials/risk_difference_with_intervention_edit_partial'));

Handlebars.registerPartial('sofShortTable', sofShortTable);

Handlebars.registerPartial('relativeImportanceMainOutcomesTable', relativeImportanceMainOutcomesTable);

Handlebars.registerPartial('testAccuracySummary', testAccuracySummary);

Handlebars.registerPartial('caseControl', caseControl);

Handlebars.registerPartial('recommendationsAppendices', recommendationsAppendices);

Handlebars.registerPartial('scoreUnits', scoreUnits);

Handlebars.registerPartial('outcomesGenerationComments', outcomesGenerationComments);

Handlebars.registerPartial('outcomesGenerationList', outcomesGenerationList);

Handlebars.registerPartial('finishedOutcomesGeneration', finishedOutcomesGeneration);

Handlebars.registerPartial('footnotesAndReferences', footnotesAndReferences);

Handlebars.registerPartial('searchBar', searchBar);

Handlebars.registerPartial('panelVoiceExportOption', panelVoiceExportOption);

Handlebars.registerPartial('timeToEventSelector', timeToEventSelector);

Handlebars.registerPartial('robinsIToggle', robinsIToggle);

Handlebars.registerPartial('prognosticObsStudiesSelection', prognosticObsStudiesSelection);

Handlebars.registerPartial('rangeOfScores', rangeOfScores);

Handlebars.registerPartial('conventionalScaleLimits', conventionalScaleLimits);

Handlebars.registerPartial('interpretationOfResults', interpretationOfResults);
