var ProjectSharingService, W, mediator;

W = require('when/when');

mediator = require('mediator');

module.exports = ProjectSharingService = (function() {
  function ProjectSharingService() {}

  ProjectSharingService.prototype.acceptInvitation = function(invitationId, organizationId) {
    var login;
    if (organizationId == null) {
      organizationId = null;
    }
    login = mediator.user.getLogin();
    return mediator.services.projectsInvitations.getInvitation(login, invitationId).then(function(invitation) {
      var sharingType;
      sharingType = invitation.createCopy ? 'share_project_copy' : 'share_project';
      mediator.user.getRequests().create({
        type: sharingType,
        project: invitation.projectData.id,
        from: invitation.from.login,
        "with": mediator.user.getEmail(),
        invitationId: invitationId,
        organizationId: organizationId === 'personal' ? null : organizationId
      });
      return mediator.services.projectsInvitations.invitationAccepted(login, invitationId);
    });
  };

  ProjectSharingService.prototype.rejectInvitation = function(invitationId) {
    var login;
    login = mediator.user.getLogin();
    return mediator.services.projectsInvitations.invitationRejected(login, invitationId).then(function() {
      return mediator.user.getRequests().create({
        type: 'project_invitation_reject',
        invitationId: invitationId
      });
    });
  };

  ProjectSharingService.prototype.abandonInvitation = function(invitationId) {
    var login;
    login = mediator.user.getLogin();
    return mediator.services.projectsInvitations.invitationAbandoned(login, invitationId);
  };

  ProjectSharingService.prototype.sendProjectInvitation = function(projectId, recipient, createCopy) {
    return mediator.user.getRequests().create({
      type: 'project_invitation',
      projectId: projectId,
      recipient: recipient,
      createCopy: createCopy
    });
  };

  ProjectSharingService.prototype.revokeAccess = function(projectId, email) {
    return mediator.user.getRequests().create({
      type: 'revoke_project_access',
      projectId: projectId,
      email: email
    });
  };

  return ProjectSharingService;

})();
