ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConnectStore = require 'components/enhancers/connect_store'
ItemWithCheckbox = require 'components/common/item_with_checkbox'
MembersActions = require 'actions/members_actions'
MembersStore = require 'stores/members_store'
Modal = require 'components/common/modal'
SearchBar = require 'components/common/search_bar'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'

storeConnector =
  MembersStore: (Store) ->
    isFetching: Store.isImportModalFetchingMembers()
    isImporting: Store.isImporting()
    isOpen: Store.isImportModalOpen()
    panelMembers: Store.getImportPanelMembers()
    searchText: Store.getImportSearchText()
    selectedMemberIds: Store.getImportSelectedMemberIds()

ImportTeamMembersModal = createReactClass
  displayName: 'ImportTeamMembersModal'

  mixins: [Translation('team:import_modal')]

  propTypes:
    isFetching: PropTypes.bool.isRequired
    isImporting: PropTypes.bool.isRequired
    isOpen: PropTypes.bool.isRequired
    panelMembers: PropTypes.instanceOf(Immutable.List).isRequired
    searchText: PropTypes.string.isRequired
    selectedMemberIds: PropTypes.instanceOf(Immutable.List).isRequired

  onTogglePanelMember: (memberId) -> ->
    MembersActions.toggleImportMember memberId

  onImport: ->
    { selectedMemberIds } = @props
    MembersActions.import selectedMemberIds.toJS()

  render: ->
    { isFetching, isImporting, isOpen, panelMembers, searchText, selectedMemberIds } = @props
    <Modal
      className="panel-members-import-modal"
      isOpen={isOpen}
      title={@i18n 'title'}
    >
      {if isFetching
        <div>
          <Spinner />
        </div>
      else
        <div>
          <p>{@i18n 'select_members_from_list'}</p>
          <div className="search-box">
            <SearchBar
              lowerCaseSearchText={false}
              onSearch={MembersActions.setImportSearchText}
              onSearchReset={MembersActions.clearImportSearchText}
              searchOnChange
              searchText={searchText}
            />
          </div>
          <div className="select-all">
            <ItemWithCheckbox
              checked={panelMembers.size is selectedMemberIds.size}
              onChange={MembersActions.toggleImportSelectAllMembers}
              readonly={isImporting}
            >
              <div>
                {@i18n '/actions.select_all'}
              </div>
            </ItemWithCheckbox>
          </div>
          <div className="panel-members">
            {if panelMembers.isEmpty()
              <p>
                {if searchText
                  @i18n 'no_matches', { searchText }
                else
                  @i18n 'no_members'
                }
              </p>
            else
              panelMembers.map (member) =>
                memberId = member.get '_id'
                <ItemWithCheckbox
                  checked={selectedMemberIds.includes memberId}
                  key={memberId}
                  onChange={@onTogglePanelMember memberId}
                  readonly={isImporting}
                >
                  <div>
                    {"#{member.get('givenNames') ? ''} #{member.get 'lastName'}".trim()}
                  </div>
                </ItemWithCheckbox>
            }
          </div>
          <ApplyCancelButtons
            applying={isImporting}
            applyLabel={@i18n '/actions.add'}
            onApply={@onImport}
            onCancel={MembersActions.closeImportModal}
            isSubmitEnabled={not selectedMemberIds.isEmpty()}
          />
        </div>
      }
    </Modal>

module.exports = ConnectStore ImportTeamMembersModal, MembersStore, storeConnector
