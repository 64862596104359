var ChangeRecommendationOptionsForMdgTemplate2, MdgQuestionDoc, Migration, W, mediator, migrationName, templatesSections, updateTemplateData,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

MdgQuestionDoc = require('lib/db_docs/mdg_question_doc');

Migration = require('./migration');

W = require('when');

templatesSections = require('lib/etd_templates/templates_sections');

migrationName = 'change_recommendation_options_for_mdg_template_2';

updateTemplateData = function(templateData) {
  return templateData.setIn(['conclusions', 'sections', 'mdgRecommendation', 'options'], Immutable.fromJS([
    {
      text: "Recommended",
      value: "recommend",
      direction: "positive"
    }, {
      text: "Not Recommended",
      value: "recommend_against",
      direction: "negative"
    }, {
      text: "Sometimes Recommended",
      value: "suggest_against",
      direction: "negative"
    }, {
      text: "No Recommendation",
      value: "suggest_either",
      direction: "none"
    }
  ]));
};

module.exports = ChangeRecommendationOptionsForMdgTemplate2 = (function(_super) {
  __extends(ChangeRecommendationOptionsForMdgTemplate2, _super);

  function ChangeRecommendationOptionsForMdgTemplate2() {
    ChangeRecommendationOptionsForMdgTemplate2.__super__.constructor.call(this, false);
  }

  ChangeRecommendationOptionsForMdgTemplate2.prototype.up = function(project, colls) {
    var adapter, projectId;
    ChangeRecommendationOptionsForMdgTemplate2.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return MdgQuestionDoc.at(projectId).fetchMany()["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
      return {
        rows: []
      };
    }).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).reduce(function(docIds, q) {
        var _ref;
        return docIds.concat((_ref = q.doc.recommendationIds) != null ? _ref : []);
      }, []).concat(['etd-mdg-template']).compact().value();
    }).then(function(docIds) {
      return adapter.fetch(projectId, docIds);
    }).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return W.resolve();
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        if (doc.templateDef.id !== 'mdg') {
          return;
        }
        doc.templateData = updateTemplateData(Immutable.fromJS(doc.templateData)).toJS();
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, _.compact(updatedDocs));
    });
  };

  return ChangeRecommendationOptionsForMdgTemplate2;

})(Migration);
