var Model, Request,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Model = require('models/base/model');

module.exports = Request = (function(_super) {
  __extends(Request, _super);

  function Request() {
    return Request.__super__.constructor.apply(this, arguments);
  }

  Request.prototype.defaults = {
    status: 'new'
  };

  Request.prototype.initialize = function() {
    Request.__super__.initialize.apply(this, arguments);
    if (!this.get('type')) {
      throw new Error('No request type specified');
    }
  };

  return Request;

})(Model);
