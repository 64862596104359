{ useCoffeeEffect, useI18n } = require 'lib/react_utils'
Button = require 'components/common/button'

SidebarBreakpointEditor = ({ currentElement, onElementRemove }) ->
  i18n = useI18n('algorithms:sidebar')

  <div className='algorithms-sidebar__editor-row'>
    <label>{i18n('delete')}</label>
    <Button
      className='btn-cancel'
      label={i18n('delete_object')}
      onClick={onElementRemove}
    />
  </div>

SidebarBreakpointEditor.propTypes =
  currentElement: PropTypes.object.isRequired
  onElementRemove: PropTypes.func.isRequired

module.exports = SidebarBreakpointEditor
