{ createPortal } = ReactDOM

Portal = ({ children }) ->
  rightSidebar = document.getElementById('right-sidebar-container')
  createPortal(children, rightSidebar)

Portal.propTypes =
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired

module.exports = Portal
