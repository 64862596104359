module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا مداخله برای ذینفعان اصلی قابل پذیرش است؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "هیج",
                    "value": "no"
                  }
                ],
                "question": "آیا از میان ذینفعان اصلی، کسانی هستند که توزیع مزایا، مضرات و هزینه ها را نپذیرند؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "هیج",
                    "value": "no"
                  }
                ],
                "question": "آیا از میان ذینفعان اصلی، کسانی هستند که هزینه ها یا اثرات نامطلوب را در کوتاه مدت به بهای اثرات مطلوب (مزایا) در آینده نپذیرند؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "خیر",
                    "value": "no"
                  }
                ],
                "question": "آیا ذینفعانی وجود دارند که با فواید مرتبط با اثرات مطلوب یا نامطلوب موافق نباشند (به علت آن که ممکن است شخصا تحت تاثیر آن قرار بگیرند یا در نتیجه برداشتشان از اهمیت اثرات مرتبط برای دیگران)؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "خیر",
                    "value": "no"
                  }
                ],
                "question": "آیا مداخله (گزینه مورد نظر) به شکل نامطلوبی استقلال افراد را تحت تاثیر قرار می دهد؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "خیر",
                    "value": "no"
                  }
                ],
                "question": "آیا ذینفعانی هستند که از نظر اخلاقی به دلایلی به غیر از اثرات مداخله (گزینه مورد نظر) بر روی خودمختاری افراد (یعنی در ارتباط با اصول اخلاقی مثل آسیب نرساندن، فایده داشتن یا عدالت) مخالف آن باشند؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "آیا گمان می رود که ذینفعان اصلی توزیع مزایا، مضرات و هزینه ها؛ یا هزینه ها و اثرات نامطلوب را در کوتاه مدت برای اثرات مطلوب (مزایا) در آینده نپذیرند؟ آیا احتمال دارد که آن ها با ارزش های متصل به اثرات مطلوب و نامطلوب مخالف باشند یا مداخله تشخیصی را به دلایل اخلاقی نپذیرند؟",
          "options": [
            {
              "text": "خیر",
              "value": "no"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "بله ",
              "value": "yes"
            }
          ],
          "ratingDescription": "کدام یک از مداخلات از نظر ذینفعان اصلی بیشتر مورد پذیرش است؟",
          "ratingOptions": [
            "best_acceptability",
            "intermediate_acceptability",
            "worst_acceptability"
          ],
          "selectedOption": ""
        },
        "additionalSection": {
          "additionalOptions": [
            {
              "text": "قضاوت تکمیلی 1",
              "value": "additionalOption_1"
            },
            {
              "text": "قضاوت تکمیلی 2",
              "value": "additionalOption_2"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "توصیف سفارشی معیارها",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "راهنمای خطی سفارشی فراهم کنید",
          "options": [
            {
              "text": "قضاوت 1",
              "value": "option_1"
            },
            {
              "text": "قضاوت 2",
              "value": "option_2"
            },
            {
              "text": "قضاوت 3",
              "value": "option_3"
            },
            {
              "text": "قضاوت 4",
              "value": "option_4"
            },
            {
              "text": "قضاوت 5",
              "value": "option_5"
            }
          ],
          "selectedOption": ""
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا تعادل میان اثرات مطلوب و نامطلوب به نفع مداخله است یا مقایسه؟",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "قضاوت های مبسوط برای این معیارها شامل قضاوت ها در خصوص چهار معیاری است که در ادامه آورده می شود:"
              },
              {
                "additions": [
                  "افراد چه مقدار برای پیامدهایی که در آینده ایجاد می شوند در مقایسه با پیامدهایی که در زمان حال حاضر اتفاق می افتند کمتر ارزش قائل می شوند (نرخ تنزیل آن ها)؟",
                  "نگرش افراد به اثرات نامطلوب (چه مقدار آن ها ریسک گریز هستند).",
                  "نگرش افراد به اثرات مطلوب (چقدر ریسک پذیر هستند)."
                ],
                "description": "همچنین، هیئت ها ممکن است بخواهند (و اگر مستند مرتبط بود) وسعتی که ملاحظات رو به رو ممکن است تعادل بین اثرات مطلوب و نامطلوب را تحت تاثیر قرار بدهند، در نظر بگیرند:"
              }
            ],
            "panelDiscussion": ""
          },
          "info": "تعادل میان اثرات مطلوب و نامطلوب با در نظر گرفتن میزان ارزشی که افراد برای پیامدهای اصلی قائل هستند، میزان قابل توجه بودن اثرات مطلوب و نامطلوب، قطعیت آن برآوردها، میزان تخفیفات، مغایرت ریسک گریزی و ریسک پذیری چقدر است؟",
          "options": [
            {
              "text": "به نفع مقایسه است",
              "value": "favors_comparison"
            },
            {
              "text": "احتمالا به نفع مقایسه است",
              "value": "probably_favors_comparison"
            },
            {
              "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
              "value": "doesnt_favor_any"
            },
            {
              "text": "احتمالا به نفع مداخله است",
              "value": "probably_favors_intervention"
            },
            {
              "text": "به نفع مداخله است",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "توازن بین اثرات مطلوب و نامطلوب به نفع مداخله است یا مقایسه(درمان مقابل با مداخله)؟",
          "ratingOptions": [
            "best_balance",
            "intermediate",
            "worst_balance"
          ],
          "selectedOption": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا تعادل بین اثرات مطلوب و نامطلوب بر روی سلامت به نفع مداخله است یا مقایسه؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا <strong> کارایی کوتاه و بلند مدت <strong> (تحت شرایط کنترل شده و اغلب ایده آل) یا <strong> اثربخشی </strong> (در زمینه زندگی واقعی) مداخله بر روی <strong>سلامت افراد</strong>، از جمله پیامدهای گزارش شده در رابطه با بیمار به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا اثربخشی </strong> کوتاه و بلند مدت </strong> یا <strong>تاثیر</strong> مداخله بر روی <strong> سلامت جمعیت </strong، از جمله بر روی پیامدهای فایده گزارش شده، به نفع مداخله است یا مقایسه؟ (این عوامل باید شامل ملاحظات در خصوص اینکه آیا پیامدها در سطح جمعیت نماینده پیامدهای تجمعی در سطح افراد است یا از میان پویایی های سیستم به وجود آمده یا خیر باشد.",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا میزانی که <strong>بیماران/میزان ارزش های </strong> پیامدهای مختلف سلامتی به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا <strong>احتمال و شدت اثرات نامطلوب </strong> مرتبط با مداخله (از جمله خطر خطر سوء استفاده از مداخله) به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا <strong>اثرات فراگیرتر مثبت یا منفی مرتبط با سلامت </strong> (مثل کاهش برچسب گذاری، تاثیر مثبت بر روی سایر بیماری ها، اثرات سر ریز فراتر از بیماران/فواید) مرتبط با مداخله به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هرچقدر مزیت خالص یک مداخله برای سلامت بیشتر باشد، احتمال یک توصیه عمومی به نفع این مداخله بالاتر است.",
          "options": [
            {
              "text": "به نفع مقایسه است",
              "value": "favors_the_comparison"
            },
            {
              "text": "احتمالا به نفع مقایسه است",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "احتمالا به نفع مداخله است",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "به نفع مداخله است",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "هیچ مطالعاتی شامل نشدند",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "میزان اطمینان حاصل از شواهد مربوط به اثرات آزمون چقدر است؟",
          "details": {
            "panelDiscussion": ""
          },
          "info": "تحقیق مورد نظر چقدر می تواند یک مورد مصرف خوب را برای اثرات احتمالی در میان تمامی پیامدهای حیاتی به ارمغان آورد؛ یعنی، احتمال آن که اثرات مورد نظر به اندازه ای با آن چه تحقیق پیدا کرده متفاوت باشند که این موضوع بتواند تصمیم گیری در خصوص مداخله تشخیصی را تحت تاثیر قرار دهد؟",
          "options": [
            {
              "text": "خیلی کم",
              "value": "very_low"
            },
            {
              "text": "کم",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "زیاد",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "هیچ مطالعاتی شامل نشدند",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "میزان اطمینان کلی حاصل از شواهد مربوط به اثرات آزمون چقدر است؟",
          "details": {
            "panelDiscussion": ""
          },
          "info": "تحقیق مورد نظر چقدر می تواند اثرات احتمالی را در میان تمامی پیامدهای حیاتی به ارمغان آورد؛ یعنی، احتمال آن که اثرات حاصل به اندازه ای با آن چه تحقیق پیدا کرده متفاوت باشند که این موضوع بتواند تصمیم گیری در خصوص مداخله را تحت تاثیر قرار دهد؟",
          "options": [
            {
              "text": "خیلی کم",
              "value": "very_low"
            },
            {
              "text": "کم",
              "value": "low"
            },
            {
              "text": "معمولی",
              "value": "moderate"
            },
            {
              "text": "زیاد",
              "value": "high"
            }
          ],
          "ratingDescription": "میزان اطمینان از تاثیر هر مداخله چقدر است؟",
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "هیچ مطالعاتی شامل نشدند",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "قطعیت تام شواهد اثرات مدیریت که به واسطه نتایج آزمایش مطرح هستند، چقدر است؟",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "خیلی کم",
              "value": "very_low"
            },
            {
              "text": "کم",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "زیاد",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "هیچ مطالعاتی شامل نشدند",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "اطمینان مربوط به شواهد منابع مورد نیاز (هزینه ها) چیست؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "هیج",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا تمام موارد مهم مربوط به استفاده از منابع که ممکن است بین گزینه های مورد توجه شناسایی شده اند؟",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "عدم اطمینان مهم",
                    "value": "important"
                  },
                  {
                    "text": "احتمالا عدم اطمینان مهم",
                    "value": "probably_important"
                  },
                  {
                    "text": "احتمالا بدون عدم اطمینان مهم",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "بدون عدم اطمینان قابل اهمیت",
                    "value": "no_important"
                  }
                ],
                "question": "میزان اطمینان از هزینه مربوط به موارد استفاده از منابع که بین گزینه های مورد نظر متفاوت هستند، چقدر است؟",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "تغییرپذیری مهم",
                    "value": "important"
                  },
                  {
                    "text": "احتمالا تغییرپذیری مهم",
                    "value": "probably_important"
                  },
                  {
                    "text": "احتمالا بدون تغییرپذیری مهم",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "هیچ تغییرپذیری قابل اهمیت",
                    "value": "no_important"
                  }
                ],
                "question": "آیا تفاوت قابل اهمیتی در هزینه موارد مربوط به استفاده از منابع که بین گزینه های مورد نظر متفاوت هستند، وجود دارد؟",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "میزان اطمینان مربوط به شواهد تفاوت در هر یک از منابع مورد استفاده (مثل داروها، میزان موارد بستری) و هزینه منابع چگونه است؟",
          "options": [
            {
              "text": "خیلی کم",
              "value": "very_low"
            },
            {
              "text": "کم",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "زیاد",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "هیچ مطالعاتی شامل نشدند",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "اطمینان کلی حاصل از شواهد مربوط به دقت آزمون چیست؟",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "خیلی کم",
              "value": "very_low"
            },
            {
              "text": "کم",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "زیاد",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "هیچ مطالعاتی شامل نشدند",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "میزان اطمینان کلی شواهد برای هر مزیت حیاتی یا مهم مستقیم، اثرات نامطلوب یا بار آزمون چقدر است؟",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "خیلی کم",
              "value": "very_low"
            },
            {
              "text": "کم",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "زیاد",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "هیچ مطالعاتی شامل نشدند",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "ارتباط بین نتایج حاصل از آزمون و تصمیم های مدیریتی چقدر معین و مشخص است؟",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "خیلی کم",
              "value": "very_low"
            },
            {
              "text": "کم",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "زیاد",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "هیچ مطالعاتی شامل نشدند",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا هزینه-اثربخشی مداخله به نفع مداخله هست یا مقایسه؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "هیچ مطالعاتی شامل نشدند",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "هیج",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا نسبت هزینه-اثربخشی به بررسی های یک طرفه حساسیت حساس است؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "هیچ مطالعاتی شامل نشدند",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "نه",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا نسبت هزینه-اثربخشی به بررسی حساسیت چند متغیری حساس است؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "هیچ مطالعاتی شامل نشدند",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "هیج",
                    "value": "no"
                  },
                  {
                    "text": "محدودیت های بسیار جدی",
                    "value": "very_serious"
                  },
                  {
                    "text": "محدودیت های جدی",
                    "value": "serious"
                  },
                  {
                    "text": "هیچ محدودیت جدی",
                    "value": "no_serious"
                  }
                ],
                "question": "آیا ارزیابی اقتصادی که برآورد هزینه-اثربخشی بر اساس آن صورت گرفته است، قابل اعتماد می باشد؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "هیچ مطالعاتی شامل نشدند",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "هیج",
                    "value": "no"
                  },
                  {
                    "text": "محدودیت های بسیار جدی",
                    "value": "very_serious"
                  },
                  {
                    "text": "محدودیت های جدی",
                    "value": "serious"
                  },
                  {
                    "text": "هیچ محدودیت جدی",
                    "value": "no_serious"
                  }
                ],
                "question": "آیا ارزشیابی اقتصادی که برآورد هزینه-اثربخشی بر اساس آن صورت گرفته است، برای موقعیت (های) مورد نظر مناسب است؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "با توجه به عدم اطمینان نسبت به هزینه ها یا متغیر بودن آن ها، عدم اطمینان در مزیت خالص یا متغیر بودن آن ها، حساسیت بررسی ها و قابل اطمینان بودن و قابل پذیرش بودن ارزیابی های اقتصادی، آیا مداخله هزینه-اثربخش است؟",
          "options": [
            {
              "text": "به نفع مقایسه است",
              "value": "favors_comparison"
            },
            {
              "text": "احتمالا به نفع مقایسه است",
              "value": "probably_favors_comparison"
            },
            {
              "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
              "value": "doesnt_favor_any"
            },
            {
              "text": "احتمالا به نفع مداخله است",
              "value": "probably_favors_intervention"
            },
            {
              "text": "به نفع مداخله است",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "هزینه اثربخشی به نفع کدام یک از مداخلات است؟",
          "ratingOptions": [
            "best_cost_effectiveness",
            "intermediate_cost_effectiveness",
            "worst_cost_effectiveness"
          ],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "اثرات مطلوب پیش بینی شده چقدر قابل توجه هستند؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ناچیز",
                    "value": "trivial"
                  },
                  {
                    "text": "اندک",
                    "value": "small"
                  },
                  {
                    "text": "معمولی",
                    "value": "moderate"
                  },
                  {
                    "text": "زیاد",
                    "value": "large"
                  }
                ],
                "question": "اثر پیش بینی شده (تفاوت) برای هر یک از پیامدهای اصلی با یک اثر مطلوب چگونه است؟",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "اثرات مطلوب مطالعه با در نظر گرفتن اهمیت پیامدها (چگونه آن ها ارزش گذاری می شوند) و اندازه اثر (احتمال تجربه یک فایده یا آن که چقدر احتمال دارد افراد پیشرفت را تجربه کنند) چقدر است؟",
          "options": [
            {
              "text": "ناچیز",
              "value": "trivial"
            },
            {
              "text": "اندک",
              "value": "small"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "زیاد",
              "value": "large"
            }
          ],
          "ratingDescription": "اثرات مطلوبی که برای هر مداخله پیشبینی شده چقدر قابل توجه است؟",
          "ratingOptions": [
            "most_effective",
            "intermediate_effectiveness",
            "least_effective"
          ],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "تاثیر آن بر روی برابری سلامتی چه خواهد بود؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "خیر",
                    "value": "no"
                  }
                ],
                "question": "آیا گروه ها یا زمینه هایی هستند که ممکن است در رابطه با مسئله یا گزینه های مورد نظر محروم باشند؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "هیچ",
                    "value": "no"
                  }
                ],
                "question": "آیا دلایل قابل پذیرشی برای پیش بینی تفاوت ها در اثربخشی نسبی گزینه مورد نظر برای گروه ها یا موقعیت های محروم وجود دارد؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "هیچ",
                    "value": "no"
                  }
                ],
                "question": "آیا شرایط پایه متفاوتی در بین گروه ها یا موقعیت ها وجود دارد که اثربخشی تام گزینه مورد نظر یا اهمیت مسئله را تحت تاثیر قرار دهد؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "خیر",
                    "value": "no"
                  }
                ],
                "question": "آیا باید ملاحظات مهمی در زمان به کار بردن مداخله (گزینه) برای اطمینان از کاهش نا برابری و -در صورت امکان- عدم افزایش آن در نظر گرفته شوند؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "آیا دلایل محتملی برای پیش بینی تفاوت ها در اثربخش بودن نسبی مداخله برای زیرگروه های محروم یا تفاوت در شرایط پایه میان زیر گروه های محروم وجود دارد که اثربخشی تام مداخله یا اهمیت مسئله را تحت تاثیر قرار دهد؟",
          "options": [
            {
              "text": "کاهش یافته",
              "value": "reduced"
            },
            {
              "text": "احتمالا کاهش یافته",
              "value": "probably_reduced"
            },
            {
              "text": "احتمالا بدون اثر",
              "value": "probably_no_impact"
            },
            {
              "text": "احتمالا افزایش یافته",
              "value": "probably_increased"
            },
            {
              "text": "افزایش یافته",
              "value": "increased"
            }
          ],
          "ratingDescription": "در صورت پیشنهاد شدن، کدام یک از مداخلات بیشتر از سایرین نابرابری در سلامت را کاهش خواهد داد؟",
          "ratingOptions": [
            "most_reduction",
            "intermediate_reduction",
            "less_reduction"
          ],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا پیاده سازی مداخله امکان پذیر است؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "هیج",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا مداخله (گزینه) پایدار است؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "هیج",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا موانع مهمی وجود دارند که بتوانند امکان پیاده سازی مداخله (گزینه) را محدود کرده یا نیاز به در نظر گرفتن در زمان اجرا داشته باشند؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "آیا استفاده طولانی مدت از مداخلات تشخیصی و درست کردن موانع احتمالی مرتبط با استفاده از آن ها امکان پذیر است؟",
          "options": [
            {
              "text": "هیج",
              "value": "no"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "بله",
              "value": "yes"
            }
          ],
          "ratingDescription": "پیاده سازی کدام یک از مداخلات بیشتر امکان پذیر است؟",
          "ratingOptions": [
            "most_feasible",
            "intermediate_feasibility",
            "least_feasible"
          ],
          "selectedOption": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا امکان پیاده سازی مداخله وجود دارد؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "نه",
                    "value": "no"
                  }
                ],
                "question": "آیا موانع<strong> قانونی</strong> وجود دارد که امکان پیاده سازی مداخله را محدود کند؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "بله",
                    "value": "yes"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "نه",
                    "value": "no"
                  }
                ],
                "question": "آیا جنبه های</strong> <strong>حکمرانی (مثل ملاحظات استراتژیک، تصمیم گیری های قبلی) وجود دارد که امکان داشته باشد پیاده سازی مداخله را محدود کند؟ (این موضوع می تواند شامل ملاحظاتی در خصوص حضور یا نبود موسسه های رسمی یا اطلاعاتی باشد که بتوانند رهبری، بینش و پاسخ گویی موثری را در پیاده سازی تاثیر مداخله و امکان انجام مداخله باشد.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "پیامدهای سودمند زیاد",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "پیامدهای مفید متوسط",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "پیامدهای مفید و نامطلوب قابل چشم پوشی",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "پیامدهای نامطلوب متوسط",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "پیامدهای نامطلوب زیاد",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "<strong>اثرات</strong> مداخله و <strong>تداخل با توجه به سیستم سلامت چه هستند؟</strong> (این مورد، ملاحظاتی در خصوص تداخل’ مداخله با یا اثر آن بر روی سیستم سلامت موجود و بخش های مختلف آن را در بر می گیرد.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "پیامدهای سودمند زیاد",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "پیامدهای مفید متوسط",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "پیامدهای مفید و نامطلوب قابل چشم پوشی",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "پیامدهای نامطلوب متوسط",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "پیامدهای نامطلوب زیاد",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "<strong>پیامدهای</strong> مداخله <strong>برای اعضای کادر درمان و منابع انسانی دیگر </strong> چه هستند؟ (در بخش سلامت یا سایر بخش ها؟ (این مورد باید ملاحظاتی در خصوص نیاز به، استفاده از، و اثر آن بر روی نیروی کادر درمان و سایر منابع انسانی و همچنین توزیع آن ها را در بر بگیرد)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "پیامدهای سودمند زیاد",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "پیامدهای مفید متوسط",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "پیامدهای مفید و نامطلوب قابل چشم پوشی",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "پیامدهای نامطلوب متوسط",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "پیامدهای نامطلوب زیاد",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "<strong>پیامدهای</strong> مداخله <strong>برای زیربنای سیستم درمان و زیربناهای گسترده تر</strong> چه هستند؟ (این مورد باید ملاحظاتی در خصوص نیاز به، استفاده از، و اثر آن بر روی منابع غیر انسانی و زیربنا و همچنین توزیع آن ها را در بر بگیرد)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هرچقدر میزان عملی بودن یک گزینه از دیدگاه همه یا بیشتر ذینفعان بیشتر باشد، احتمال یک توصیه عمومی به نفع مداخله بیشتر است. هرچقدر کاربردها برای سیستم سلامت مزایای بیشتری داشته باشند، احتمال یک توصیه عمومی به نفع مداخله بیشتر است.",
          "options": [
            {
              "text": "خیر",
              "value": "no"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_not"
            },
            {
              "text": "نامطمئن",
              "value": "uncertain"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "بله",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "feasible": {
          "description": "برای شناسایی و اندازه گیری و حساس به دستکاری کردن نیست",
          "options": [],
          "ratingOptions": [
            "very_feasible",
            "feasible",
            "least_feasible"
          ],
          "selectedOptions": ""
        },
        "financialAndEconomicConsiderations": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا ملاحظات مالی و اقتصادی به نفع مداخله هستند یا مقایسه؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا <strong> هزینه و بودجه </strong> مربوطه به اثرات بر روی اجرا و نگهداری مداخله به نفع مداخله است یا مقایسه؟ (این موضوع باید شامل این مورد باشد که اثرات هزینه و بودجه در کوتاه مدت در برابر شرایط دراز مدت تر تغییر می کند.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا <strong>اثر کلی مداخله بر روی اقتصاد </strong> به نفع مداخله است یا مقایسه؟ (این موضوع باید شامل این موارد باشد که انواع مختلف اثرات اقتصادی چگونه در بخش های مختلف یا سطوح مختلف سازمانی پخش شده اند، چه مداخله منجر به دستیابی یا به پیشرفت ها و اهداف کاهش فقر گسترده تر شود یا دستیابی به آن را محدود کند، و چگونه بر روی نیروی کاری در دسترس اثر می گذارد.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا <strong>نسبت هزینه ها و مزایا</strong> (مثلا بر اساس تخمین های هزینه اثربخشی، هزینه فایده یا هزینه کارایی) به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هرچقدر مزیت های پیامدهای مالی و اقتصادی یک مداخله بیشتر باشد، احتمال توصیه عمومی به نفع این مداخله بالاتر است.",
          "options": [
            {
              "text": "به نفع مقایسه است",
              "value": "favors_the_comparison"
            },
            {
              "text": "احتمالا به نفع مقایسه است",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "احتمالا به نفع مداخله است",
              "value": "probably_favor_the_intervention"
            },
            {
              "text": "به نفع مداخله است",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "اثر مداخله بر مساوات، برابری و عدم تبعیض در سلامت چطور خواهد بود؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "هیچ",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا احتمال دارد که مداخله، ناعدالتی و/یا نابرابری های موجود در وضعیت سلامت یا عوامل تعیین کننده آن را افزایش دهد؟ (این مورد باید با در نظر گرفتن تغییرات احتمالی در نابرابری در طی زمان مثل این که افزایش های اولیه احتمالا در طی زمان با افزایش مقیاس مداخله به تعادل خواهند رسید، همراه باشند)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "هیچ",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا احتمال دارد که مزایا و مضرات مداخله به شیوه عادلانه ای توزیع شوند؟ (این موضوع باید توجه ویژه ای به گروه های آسیب پذیر، به حاشیه رانده شده یا در غیر این صورت افراد محروم را شامل شود.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "خیر",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا افراد گروه های تحت تاثیر از پس هزینه های مداخله بر می آیند و بنابراین مداخله از نظر مالی برایشان امکان پذیر است؟ (این موضوع باید اثر هزینه را بر مخارج سلامت در منزل از جمله ریسک مخارج فاجعه بار سلامت و ریسک های مالی مرتبط با سلامت در بر بگیرد.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "خیر",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا مداخله در میان افراد گروه های متاثر در دسترس است؟ (این باید ملاحظات مرتبط با دسترسی فیزیکی و همچنین اطلاعاتی را در بر بگیرد.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نه",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا مداخله به یک بیماری مشخص شدید (برای مثال تهدید کننده زندگی، پایان عمر، تحت تاثیر قرار دادن افراد با مشکلات مرتبط با سلامتی زمینه ای) یا نادر می پردازد؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "نه",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا مداخله، تنها گزینه در دسترس است؟ (این باید ملاحظات اینکه آیا مداخله با نیاز متناسب است یا خیر و اینکه آیا مورد مرور دوره ای قرار می گیرد یا نه را در بر بگیرد.)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هرچقدر احتمال آن که مداخله بتواند برابری حقوق و/یا برابری را افزایش و همچنین تبعیض را در هر گروه به خصوصی کاهش دهد، احتمال یک توصیه عمومی به نفع این مداخله بیشتر است.",
          "options": [
            {
              "text": "منفی",
              "value": "negative"
            },
            {
              "text": "احتمالا منفی",
              "value": "probably_negative"
            },
            {
              "text": "نه منفی نه مثبت",
              "value": "neither_negative_nor_positive"
            },
            {
              "text": "احتمالا مثبت",
              "value": "probably_positive"
            },
            {
              "text": "مثبت",
              "value": "positive"
            }
          ],
          "selectedOption": ""
        },
        "humanRights": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا مداخله منطبق با اصول و استانداردهای جامع حقوق بشر است؟",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "تمامی توصیه ها باید منطبق با اصول و استانداردهای جامع حقوق بشر باشند.",
          "options": [
            {
              "text": "خیر",
              "value": "no"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "نامطمئن",
              "value": "uncertain"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "بله",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problem": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا مسئله مورد نظر ارجحیت دارد؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "خیر",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا عواقب مسئله مورد نظر جدی (یعنی، شدید یا مهم از نظر داشتن پتانسیل مزایا یا صرفه جویی) هستند؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "خیر",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا مسئله مورد نظر فوریت دارد؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "خیر",
                    "value": "no"
                  },
                  {
                    "text": "احتمالا خیر",
                    "value": "probably_no"
                  },
                  {
                    "text": "احتمالا بله",
                    "value": "probably_yes"
                  },
                  {
                    "text": "بله",
                    "value": "yes"
                  }
                ],
                "question": "آیا این موضوع یک اولویت شناخته شده (برای مثال بر پایه تصمیم های سیاسی یا سیاست) است؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هر چقدر یک مشکل جدی تر یا ضروری تر باشد، احتمال آن که گزینه ای که به مسئله می پردازد به یک اولویت بدل شود، بیشتر است. ",
          "options": [
            {
              "text": "خیر",
              "value": "no"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "بله",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "qualityOfEvidence": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "کیفیت کلی شواهد به چه صورت است؟",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "هرچقدر کیفیت شواهد در بین معیارهای مختلف در قالب WHO-INTEGRATE بالاتر باشد، احتمال توصیه عمومی بالاتر است.",
          "options": [
            {
              "text": "خیلی کم",
              "value": "very_low"
            },
            {
              "text": "کم",
              "value": "low"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "زیاد",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "relevantDirect": {
          "description": "برای مشکل مورد نظر و در زمینه کاربرد",
          "options": [],
          "ratingOptions": [
            "very_relevant",
            "relevant",
            "least_relevant"
          ],
          "selectedOptions": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "(هزینه های) منابع مورد نیاز چقدر است؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "ناچیز",
                    "value": "trivial"
                  },
                  {
                    "text": "اندک",
                    "value": "small"
                  },
                  {
                    "text": "متوسط",
                    "value": "moderate"
                  },
                  {
                    "text": "زیاد",
                    "value": "large"
                  }
                ],
                "question": "تفاوت بین گزینه های مختلف منابع که برای هر یک منابع کمتری مورد نیاز است، چقدر است؟",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "زیاد",
                    "value": "large"
                  },
                  {
                    "text": "متوسط",
                    "value": "moderate"
                  },
                  {
                    "text": "اندک",
                    "value": "small"
                  },
                  {
                    "text": "ناچیز",
                    "value": "trivial"
                  }
                ],
                "question": "تفاوت بین هر کدام از منابع مورد استفاده که برای هر یک منابع بیشتری مورد نیاز هستند، چقدر است؟",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "هزینه تفاوت در استفاده از منابع بین مداخله و مقایسه چقدر است؟",
          "options": [
            {
              "text": "هزینه های زیاد",
              "value": "large_costs"
            },
            {
              "text": "هزینه های متوسط",
              "value": "moderate_costs"
            },
            {
              "text": "هزینه های قابل چشم پوشی و صرفه جویی",
              "value": "negligible_costs_savings"
            },
            {
              "text": "صرفه جویی متوسط",
              "value": "moderate_savings"
            },
            {
              "text": "صرفه جویی زیاد",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "منابع مورد نیاز (هزینه ها) برای هر مداخله چقدر است؟",
          "ratingOptions": [
            "less_costs",
            "intermediate_costs",
            "most_costs"
          ],
          "selectedOption": ""
        },
        "responsive": {
          "description": "حساس به تغییر به این معنی که  باید بتوانند تغییرات احتمالی در سیستم را ثبت کنند",
          "options": [],
          "ratingOptions": [
            "very_responsive",
            "responsive",
            "least_responsive"
          ],
          "selectedOptions": ""
        },
        "scientificallySound": {
          "description": "مبتنی بر شواهد (معیارهای GRADE) و به شدت با کیفیت مراقبت مرتبط است.",
          "options": [],
          "ratingOptions": [
            "very_scientifically_sound",
            "scientifically_sound",
            "least_scientifically_sound"
          ],
          "selectedOptions": ""
        },
        "societalImplications": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا تعادل بین پیامدهای اجتماعی مطلوب و نامطلوب به نفع مداخله است یا مقایسه؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا تاثیر اجتماعی و عواقب اجتماعی مداخله (مثل افزایش یا کاهش انگ اجتماعی، پیامدهای آموزشی، هم بستگی اجتماعی یا نیل به حقوق گوناگون انسانی فراتر از سلامتی) به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا اثر محیطی و پیامدهای پایداری محیطی (مثل حفاظت از منابع طبیعی، تخفیف یا تطابق پذیری با تغییرات آب و هوایی) به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هرچقدر مزیت خالص اجتماعی مرتبط با یک مداخله بیشتر باشد، احتمال یک توصیه عمومی به نفع این مداخله بیشتر است.",
          "options": [
            {
              "text": "به نفع مقایسه است",
              "value": "favors_the_comparison"
            },
            {
              "text": "احتمالا به نفع مقایسه است",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "احتمالا به نفع مداخله است",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "به نفع مداخله است",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "socioCulturalAcceptability": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا مداخله برای ذینفعان کلیدی قابل پذیرش است؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "زیاد",
                    "value": "large"
                  },
                  {
                    "text": "متوسط",
                    "value": "moderate"
                  },
                  {
                    "text": "اندک",
                    "value": "small"
                  },
                  {
                    "text": "ناچیز",
                    "value": "trivial"
                  }
                ],
                "question": "میزان مزاحمت مداخله از نظر نقض آزادی افراد (از جمله حریم شخصی و عزت) چقدر قابل توجه است؟ (مزاحمت طیف گسترده ای از ناچیز بودن– برای مثال از راه ایجاد توانایی انتخاب (برای مثال ساختن مسیرهای دوچرخه سواری تا زیاد – برای مثال با محدودسازی یا از بین بردن قدرت انتخاب (برای مثال ممنون کردن سیگار)).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "زیاد",
                    "value": "large"
                  },
                  {
                    "text": "متوسط",
                    "value": "moderate"
                  },
                  {
                    "text": "اندک",
                    "value": "small"
                  },
                  {
                    "text": "ناچیز",
                    "value": "trivial"
                  }
                ],
                "question": "میزان تاثیر مداخله بر روی آزادی افراد، جمعیت، گروه ها، و/یا سازمان ها (با در نظر گرفتن قدرت آن ها برای گرفتن یک تصمیم شایسته، آگاهانه و داوطلبانه) چه مقدار قابل توجه است؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "پذیرش اجتماعی-فرهنگی مداخله در بین ذینفعان مورد نظر به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "پذیرش اجتماعی-فرهنگی مداخله در بین آن هایی که برای پیاده سازی مداخله در نظر گرفته شده اند به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "به نفع هیچ یک از موارد مداخله یا مقایسه نیست",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "آیا پذیرش اجتماعی-فرهنگی مداخله در بین سایر گروه های ذینفعان مرتبط به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "به نفع مقایسه است",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مقایسه است",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "نه به نفع مداخله است نه مقایسه ",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "احتمالا به نفع مداخله است",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "به نفع مداخله است",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "پذیرش اجتماعی-فرهنگی مداخله در بین عموم مردم به نفع مداخله است یا مقایسه؟",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "هرچقدر که یک مداخله برای همه یا بیشتر ذینفعان مرتبط از نظر اجتماعی-فرهنگی قابل پذیرش تر باشد، احتمال یک توصیه عمومی به نفع این مداخله بیشتر است.",
          "options": [
            {
              "text": "نه",
              "value": "no"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "نامطمئن",
              "value": "uncertain"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "بله",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "دقت آزمون چقدر است؟",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "بسیار نادرست",
              "value": "very_inaccurate"
            },
            {
              "text": "غیر دقیق",
              "value": "inaccurate"
            },
            {
              "text": "دقیق",
              "value": "accurate"
            },
            {
              "text": "بسیار بی دقت",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": "متغیر",
              "value": "varies"
            },
            {
              "text": "نظری نداریم",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "اثرات نامطلوب پیش بینی شده چه مقدار قابل توجه هستند؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "متغیر",
                    "value": "varies"
                  },
                  {
                    "text": "نظری نداریم",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "زیاد",
                    "value": "large"
                  },
                  {
                    "text": "متوسط",
                    "value": "moderate"
                  },
                  {
                    "text": "اندک",
                    "value": "small"
                  },
                  {
                    "text": "ناچیز",
                    "value": "trivial"
                  }
                ],
                "question": "اثر پیش بینی شده (تفاوت) برای هر کدام از پیامدهای اصلی که اثر نامطلوبی دارند، چه مقدار قابل توجه است؟",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "اثرات نامطلوب مداخله با در نظر گرفتن اهمیت پیامدها (چقدر ارزش دارند) و اندازه اثر (احتمال تجربه یک مزیت یا احتمال میزان بهبودی که افراد ممکن است تجربه کنند) چقدر است؟",
          "options": [
            {
              "text": "زیاد",
              "value": "large"
            },
            {
              "text": "متوسط",
              "value": "moderate"
            },
            {
              "text": "اندک",
              "value": "small"
            },
            {
              "text": "ناچیز",
              "value": "trivial"
            }
          ],
          "ratingDescription": "اثرات نامطلوب پیش بینی شده برای هر مداخله چقدر است؟",
          "ratingOptions": [
            "least_harmful",
            "intermediate",
            "more_harmful"
          ],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "آیا عدم اطمینان قابل اهمیتی در خصوص یا تغییرپذیری در میزان ارزشی که افراد برای پیامدهای اصلی قائل هستند وجود دارد؟",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "عدم اطمینان مهم",
                    "value": "important"
                  },
                  {
                    "text": "احتمالا عدم اطمینان مهم",
                    "value": "probably_important"
                  },
                  {
                    "text": "احتمالا بدون عدم اطمینان قابل اهمیت",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "بدون عدم اطمینان مهم",
                    "value": "no_important"
                  }
                ],
                "question": "آیا عدم اطمینان قابل اهمیتی در خصوص این که چقدر افراد برای پیامدهای اصلی ارزش قائل هستند وجود دارد؟",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "افراد چه مقدار برای هر یک از پیامدهای اصلی ارزش قائل هستند؟ آیا عدم اطمینان در خصوص میزان ارزشی که آن ها برای پیامدها یا تغییر پذیری در میزان ارزشی که افراد مختلف برای پیامدها قائل هستند، به مقداری هست که منجر به تصمیم های متفاوتی بشود؟",
          "options": [
            {
              "text": "عدم قطعیت مهم یا تغییرپذیر",
              "value": "important_uncertainty"
            },
            {
              "text": "احتمالا عدم قطعیت مهم ‎یا تغییرپذیر",
              "value": "possible_important"
            },
            {
              "text": "احتمالا بدون عدم اطمینان مهم یا تغییرپذیر",
              "value": "probably_no_important"
            },
            {
              "text": "هیچ عدم اطمینان مهم یا تغییرپذیر",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "مقبولیت",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "معیارهای سفارشی",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "تعادل اثرات",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
          "criterionId": "balanceOfHealthBenefitsAndHarms",
          "name": "تعادل بین مزایا و مضرات مرتبط با سلامت",
          "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "قطعیت اثرات",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "قطعیت شواهد",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "قطعیت شواهد اثرات مدیریت",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "قطعیت شواهد حاصل از منابع مورد نیاز",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "قطعیت شواهد دقت آزمون",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "قطعیت شواهد مربوط به اثرات آزمون",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "قطعیت شواهد مربوط به نتایج/مدیریت آزمون ",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "هزینه اثر بخشی",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "اثرات مطلوب",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "برابری حقوق",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "عملی بودن",
          "researchEvidenceId": "feasibility"
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
          "criterionId": "feasibilityAndHealthSystemConsiderations",
          "name": "امکان اجرا و ملاحظات سیستم سلامت",
          "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
        },
        "feasible": {
          "additionalConsiderationId": "feasible",
          "criterionId": "feasible",
          "name": "امکان پذیر",
          "researchEvidenceId": "feasible"
        },
        "financialAndEconomicConsiderations": {
          "additionalConsiderationId": "financialAndEconomicConsiderations",
          "criterionId": "financialAndEconomicConsiderations",
          "name": "ملاحظات مالی و اقتصادی",
          "researchEvidenceId": "financialAndEconomicConsiderations"
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
          "criterionId": "healthEquityEqualityAndNonDiscrimination",
          "name": "برابری سلامتی، مساوات و نبود تبعیض",
          "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
        },
        "humanRights": {
          "additionalConsiderationId": "humanRights",
          "criterionId": "humanRights",
          "name": "حقوق بشر",
          "researchEvidenceId": "humanRights"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "مسئله",
          "researchEvidenceId": "problem"
        },
        "qualityOfEvidence": {
          "additionalConsiderationId": "qualityOfEvidence",
          "criterionId": "qualityOfEvidence",
          "name": "کیفیت شواهد",
          "researchEvidenceId": "qualityOfEvidence"
        },
        "relevantDirect": {
          "additionalConsiderationId": "relevantDirect",
          "criterionId": "relevantDirect",
          "name": "مرتبط (مستقیم)",
          "researchEvidenceId": "relevantDirect"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "منابع مورد نیاز",
          "researchEvidenceId": "resourcesRequired"
        },
        "responsive": {
          "additionalConsiderationId": "responsive",
          "criterionId": "responsive",
          "name": "پاسخگو",
          "researchEvidenceId": "responsive"
        },
        "scientificallySound": {
          "additionalConsiderationId": "scientificallySound",
          "criterionId": "scientificallySound",
          "name": "از نظر علمی صحیح (قطعیت شواهد)",
          "researchEvidenceId": "scientificallySound"
        },
        "societalImplications": {
          "additionalConsiderationId": "societalImplications",
          "criterionId": "societalImplications",
          "name": "پیامدهای اجتماعی",
          "researchEvidenceId": "societalImplications"
        },
        "socioCulturalAcceptability": {
          "additionalConsiderationId": "socioCulturalAcceptability",
          "criterionId": "socioCulturalAcceptability",
          "name": "مقبولیت اجتماعی-فرهنگی",
          "researchEvidenceId": "socioCulturalAcceptability"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": "دقت آزمون",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "اثرات نامطلوب",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "ارزش ها",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "benefits": {
          "content": "",
          "description": "مزایا"
        },
        "clearAndActionable": {
          "content": "",
          "description": "واضح و قابل اجرا",
          "options": [
            {
              "text": "بله",
              "value": "yes"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "خیر",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "clinicalConsiderations": {
          "content": "",
          "description": "ملاحظات بالینی"
        },
        "collectingAndSummarizing": {
          "content": "",
          "description": "جمع آوری و خلاصه سازی شواهد، استفاده ای نادرست از زمان و انرژی محدود اعضای دستورالعمل (گایدلاین) (هزینه فرصت به مقدار زیاد) است. ",
          "options": [
            {
              "text": "بله",
              "value": "yes"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "خیر",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "considerations": {
          "content": "",
          "description": "ملاحظات"
        },
        "contextAndSystemConsiderations": {
          "content": "",
          "description": "زمینه و ملاحظات سیستم"
        },
        "decision": {
          "content": "",
          "description": "تصمیم",
          "options": [
            {
              "text": "اجرای کامل",
              "value": "full_implementation"
            },
            {
              "text": "ارزیابی اثر",
              "value": "impact_evaluation"
            },
            {
              "text": "مطالعه مقدماتی (پایلوت)",
              "value": "pilot_study"
            },
            {
              "text": "به تعویق انداختن",
              "value": "postpone"
            },
            {
              "text": "اجرا نکنید",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "نوع تصمیم",
          "options": [
            {
              "direction": "negative",
              "text": "پوشش ندهید",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "با توسعه شواهد پوشش بدهید",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "با مذاکره قیمت پوشش دهید",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "پوشش محدود",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "پوشش",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "نوع تصمیم",
          "options": [
            {
              "direction": "negative",
              "text": "گزینه مورد نظر را اجرا نکنید",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "تصمیم را به تعویق بیندازید",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "یک مطالعه مقدماتی (پایلوت) در خصوص گزینه مورد نظر انجام دهید",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "گزینه مورد نظر را با ارزیابی اثر اجرا کنید",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "گزینه مورد نظر را اجرا کنید",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "evidence": {
          "content": "",
          "description": "شواهد"
        },
        "frequencyDoseDuration": {
          "content": "",
          "description": "فواصل/دوز/طول مصرف"
        },
        "goodPracticeStatement": {
          "content": "",
          "description": "گزاره راهکار خوب"
        },
        "harms": {
          "content": "",
          "description": "مضرات"
        },
        "implementationConsiderations": {
          "content": "",
          "description": "ملاحظات اجرا",
          "details": {
            "content": "",
            "description": "جزئیات اجرا"
          }
        },
        "implementationConsiderationsOfAllRelevantOutcomes": {
          "content": "",
          "description": "پس از در نظر گرفتن تمامی پیامدهای مرتبط و عواقب احتمالی پایین دستی، اجرای گزاره راهکار خوب به عواقب خالص مثبتی می انجامد. ",
          "options": [
            {
              "text": "بله",
              "value": "yes"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "خیر",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderationsOrRemarks": {
          "content": "",
          "description": "ملاحظات اجرا",
          "options": [
            {
              "text": "بله",
              "value": "yes"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "خیر",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "indications": {
          "content": "",
          "description": "موارد مصرف"
        },
        "indicationsForDiscontinuation": {
          "content": "",
          "description": "موارد عدم ادامه"
        },
        "justification": {
          "content": "",
          "description": "توجیه",
          "details": {
            "description": "جزئیات توجیه"
          },
          "overallDescription": "توجیه کلی"
        },
        "keyMessage": {
          "content": "",
          "description": "پیام اصلی"
        },
        "limitations": {
          "content": "",
          "description": "محدودیت ها"
        },
        "mdgRecommendation": {
          "content": "",
          "description": "توصیه",
          "options": [
            {
              "direction": "positive",
              "text": "توصیه می شود",
              "value": "recommend"
            },
            {
              "direction": "negative",
              "text": "توصیه نمی شود",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "بعضی مواقع توصیه شده است",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "هیج توصیه ای",
              "value": "suggest_either"
            }
          ],
          "selectedOption": ""
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "نظارت و ارزیابی",
          "details": {
            "content": "",
            "description": "نظارت و ارزیابی مفصل"
          }
        },
        "monitoringAndEvaluationQI": {
          "content": "",
          "description": "نظارت و ارزیابی"
        },
        "multipleChronicConditionsAndPolypharmacy": {
          "content": "",
          "description": "چندین بیماری مزمن و پردارویی (پلی فارمسی)"
        },
        "multipleRecommendations": {
          "description": "توصیه (ها)",
          "options": [
            {
              "direction": "negative",
              "text": "توصیه قوی علیه مداخله",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "توصیه مشروط علیه مداخله",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "توصیه مشروط برای مداخله یا مقایسه",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "توصیه مشروط برای مداخله",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "توصیه قوی برای مداخله",
              "value": "recommend"
            }
          ],
          "recommendations": [
            {
              "content": "",
              "selectedOption": ""
            }
          ]
        },
        "rationale": {
          "content": "",
          "description": "منطق"
        },
        "rationaleConnectingIndirectEvidence": {
          "content": "",
          "description": "منطقی واضح، صریح و موثقی وجود دارد که شواهد غیرمستقیم را ارتباط می دهد",
          "options": [
            {
              "text": "بله",
              "value": "yes"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "خیر",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "recommendation": {
          "content": "",
          "description": "توصیه"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "نوع توصیه",
          "options": [
            {
              "direction": "negative",
              "text": "توصیه قوی علیه مداخله",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "توصیه مشروط علیه مداخله",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "توصیه مشروط برای مداخله یا مقایسه",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "توصیه مشروط برای مداخله",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "توصیه قوی برای مداخله",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "نوع توصیه",
          "options": [
            {
              "direction": "negative",
              "text": "توصیه قوی علیه گزینه مورد نظر",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "توصیه مشروط علیه گزینه مورد نظر",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "توصیه مشروط برای گزینه مورد نظر یا مقایسه",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "توصیه مشروط برای گزینه مورد نظر",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "توصیه قوی برای گزینه مورد نظر",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "اولویت های تحقیق"
        },
        "restrictions": {
          "content": "",
          "description": "محدودیت ها"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "ملاحظات زیرگروه"
        },
        "theMessageIsNecessary": {
          "content": "",
          "description": "پیام مورد نظر با توجه به طبابتی که در واقعیت صورت می پذیرد، ضروری است.",
          "options": [
            {
              "text": "بله",
              "value": "yes"
            },
            {
              "text": "احتمالا بله",
              "value": "probably_yes"
            },
            {
              "text": "احتمالا خیر",
              "value": "probably_no"
            },
            {
              "text": "خیر",
              "value": "no"
            }
          ],
          "selectedOption": ""
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "متخصصان بالینی",
          "sections": {
            "background": {
              "name": "زمینه"
            },
            "detailedJustification": {
              "name": "جزئیات توجیه"
            },
            "implementationConsiderations": {
              "name": "ملاحظات اجرا"
            },
            "justification": {
              "name": "توجیه"
            },
            "relatedRecommendations": {
              "name": "توصیه های مرتبط"
            },
            "subgroupConsiderations": {
              "name": "ملاحظات زیرگروه"
            },
            "summaryOfFindings": {
              "name": "خلاصه یافته ها"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "بیماران",
          "sections": {
            "relatedRecommendations": {
              "name": "توصیه های مرتبط"
            },
            "summaryOfFindings": {
              "name": "خلاصه یافته ها"
            },
            "whatItMeansForYou": {
              "name": "این برای شما چه معنایی دارد؟",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "با متخصص درمانی (پزشک) خود صحبت کنید"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "شما چه کاری می توانید انجام دهید"
                }
              }
            },
            "whoIsThisFor": {
              "name": "این برای چه کسانی است؟",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "این برای چه کسانی است"
                }
              }
            },
            "whyThisRecommendation": {
              "name": "چرا این توصیه؟",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "اطلاعات بیشتر"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "مزایا و مضرات"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "چرا این توصیه"
                }
              }
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "سیاست گذاران",
          "sections": {
            "assessment": {
              "name": "سنجش"
            },
            "background": {
              "description": "جزئیات سوال برای توصیه",
              "details": {
                "name": "درباره این تصمیم",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "تاریخ"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "تصمیم گیرندگان"
                  },
                  "perspective": {
                    "content": "",
                    "description": "دورنما"
                  },
                  "setting": {
                    "content": "",
                    "description": "محیط"
                  }
                },
                "sectionsOrder": [
                  "setting",
                  "perspective",
                  "decisionMakers",
                  "date"
                ]
              },
              "name": "زمینه"
            },
            "decision": {
              "content": "",
              "name": "تصمیم",
              "options": [
                {
                  "text": "اجرای کامل",
                  "value": "full_implementation"
                },
                {
                  "text": "ارزیابی اثر",
                  "value": "impact_evaluation"
                },
                {
                  "text": "مطالعه مقدماتی (پایلوت)",
                  "value": "pilot_study"
                },
                {
                  "text": "به تعویق انداختن",
                  "value": "postpone"
                },
                {
                  "text": "اجرا نکنید",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "اجرا"
            },
            "justification": {
              "name": "توجیه"
            },
            "monitoringAndEvaluation": {
              "name": "نظارت و ارزیابی"
            },
            "relatedRecommendations": {
              "name": "توصیه های مرتبط"
            },
            "summaryOfFindings": {
              "name": "خلاصه یافته ها"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "age": {
          "content": "",
          "name": "سن"
        },
        "anticipatedOutcomes": {
          "content": "",
          "name": "پیامدهای پیش بینی شده"
        },
        "background": {
          "content": "",
          "name": "زمینه"
        },
        "coi": {
          "content": "",
          "name": "تضاد منافع"
        },
        "comparison": {
          "content": "",
          "name": "مقایسه"
        },
        "intent": {
          "content": "",
          "name": "قصد"
        },
        "intervention": {
          "content": "",
          "name": "مداخله"
        },
        "linkedTreatments": {
          "content": "",
          "name": "درمان های پیوند یافته"
        },
        "mainOutcomes": {
          "content": "",
          "name": "پیامدهای اصلی"
        },
        "option": {
          "content": "",
          "name": "گزینه"
        },
        "perspective": {
          "content": "",
          "name": "دورنما"
        },
        "population": {
          "content": "",
          "name": "جمعیت"
        },
        "problem": {
          "content": "",
          "name": "مسئله"
        },
        "purpose": {
          "content": "",
          "name": "هدف آزمون"
        },
        "requirements": {
          "content": "",
          "name": "الزامات"
        },
        "role": {
          "content": "",
          "name": "نقش آزمون"
        },
        "setting": {
          "content": "",
          "name": "محیط"
        },
        "subgroups": {
          "content": "",
          "name": "زیرگروه ها"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
