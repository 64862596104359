ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
DropdownMenu = require 'components/common/dropdown_menu'
IconButton = require 'components/common/icon_button'
Translation = require 'components/mixins/translation'
DocumentSecionsTitle = require 'components/document_sections/v2/document_sections_title'
RecommendationItems = require 'components/dissemination/acp_visual_guidelines/recommendation_items'
mediator = require 'mediator'
{ getSettingsOptions, getSettingsOrder } = require 'lib/document_sections_v2_helper'
{
  POPULATION_GROUP_ITEM_DND_TYPE, dragSpec, dropSpec, dragCollect, dropCollect
} = require 'components/dissemination/acp_visual_guidelines/population_item_dnd_spec'


PopulationItem = createReactClass
  displayName: 'PopulationItem'

  itemRef: (el) ->
    @item = el

  mixins: [Translation('')]

  addManualRecommendation: ->
    ACPVisualGuidelinesActions.addManualRecommendation {
      sectionId: @props.section.get('_id'),
      populationId: @props.populationId
    }

  onInsertRecommendation: ->
    { section, populationId, openAddRecommendationModal } = @props
    openAddRecommendationModal { section, populationId }

  handleSettingSelect: (selectedSetting) ->
    { section, populationId, population, openAddRecommendationModal } = @props

    switch selectedSetting
      when 'insert_subgroup'
        ACPVisualGuidelinesActions.insertSubgroup section.get('_id'), populationId
      when 'insert_recommendation'
        @onInsertRecommendation()
      when 'add_manual_recommendation'
        @addManualRecommendation()
      when 'delete'
        groupName = population.get('title')
        confirmOptions =
          title: @i18n 'vi:confirm.remove_population.title'
          message: @i18n 'vi:confirm.remove_population.message', { population: groupName }
          confirmText: @i18n '/actions.delete'
          declineText: @i18n '/actions.cancel'
          yesClass: 'danger'
        mediator.dialogs.confirm confirmOptions, (confirmed) ->
          return unless confirmed
          ACPVisualGuidelinesActions.deleteSubgroup section.get('_id'), populationId

  handleSaveTitle: (title) ->
    { section, population } = @props

    ACPVisualGuidelinesActions.saveSubgroup section.get('_id'), population.set('title', title)

  render: ->

    {
      connectDragSource
      connectDropTarget
      isDragging
      isOver
      population
      populationId
      populations
      section
    } = @props

    sectionId = section.get('_id')
    className = classNames 'population-group-item', {
      dragging: isDragging,
      over: isOver
    }

    connectDragSource(
      connectDropTarget(
        <div ref={@itemRef} className={className}>
          <div className="chapter-section-header">
            <div className="drag-marker" />
            <DocumentSecionsTitle
              title={population.get('title')}
              onSave={@handleSaveTitle}
            />
            <div className="chapter-section-management">
              <DropdownMenu
                opener={(
                  <IconButton iconName="more" className="settings-toggle" />
                )}
                options={getSettingsOptions getSettingsOrder('acp_recommendations_populations')}
                onSelect={@handleSettingSelect}
              />
            </div>
          </div>
          <div>
            <RecommendationItems
              section={section}
              populationId={populationId}
              recommendations={population.get('recommendations', Immutable.List())}
              insertRecommendation={@onInsertRecommendation}
            />
          </div>
        </div>
      )
    )

DragSourced = ReactDnD.DragSource(
  POPULATION_GROUP_ITEM_DND_TYPE, dragSpec, dragCollect
) PopulationItem
DropTargeted = ReactDnD.DropTarget(
  POPULATION_GROUP_ITEM_DND_TYPE, dropSpec, dropCollect
) DragSourced

module.exports = DropTargeted
