var DiscardChangesActions, alt;

alt = require('alt');

DiscardChangesActions = (function() {
  function DiscardChangesActions() {
    this.generateActions('setCallback', 'setHasChanges', 'clear');
  }

  return DiscardChangesActions;

})();

module.exports = alt.createActions(DiscardChangesActions);
