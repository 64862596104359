module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "netBalance": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "干预是否对主要利益相关者开放？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "是否有主要利益相关者不能接受利润、负面事件、成本的分配？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不；没有；否定",
                    "value": "no"
                  }
                ],
                "question": "在短期未来期望的效果内，是否有主要利益相关者无法接受成本或非期望影响？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "是否有主要利益相关者不会赞同期望或不期望的价值？（因为他们可能会受到个人的影响，或者因为他们认为这些影响对他人的相对重要性 ）",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "干预（选择）是否可能对人们的自主性产生负面影响？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "是否有关键的利益相关者会在道德上反对干预(选项)，而不是因为干预对人们自主性的影响(例如，与非恶意、慈善或正义等伦理原则相关)? ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "主要利益相关者是否有可能不接受利益、损害和成本的分配; 还是用短期内的成本或不良影响换取未来的理想效果(利益)? 他们会不会不同意与理想效果或不良效果相关的价值观，或者因为伦理考虑而不接受诊断干预?  ",
          "options": [
            {
              "text": "不",
              "value": "no"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "是，完全确定",
              "value": "yes"
            }
          ],
          "ratingDescription": "那个干预更向主要利益相关者开放？",
          "ratingOptions": ["best_acceptability", "intermediate_acceptability", "worst_acceptability"],
          "selectedOption": ""
        },
        "acceptabilityEOHR": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "干预是否对主要利益相关者开放？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "未纳入研究",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "非常低",
                    "value": "very_low"
                  },
                  {
                    "text": "低的",
                    "value": "low"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "准确度高的",
                    "value": "high"
                  }
                ],
                "question": "What is the certainty in the acceptability of the intervention among the affected population?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "重要变异；重要变化",
                    "value": "important"
                  },
                  {
                    "text": "可能非重要变化",
                    "value": "probably_important"
                  },
                  {
                    "text": "可能非重要变化",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "非重要变化",
                    "value": "no_important"
                  }
                ],
                "question": "Is there variability in the acceptability of the intervention or exposure among beneficiaries of the recommendation and other stakeholders?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "主要利益相关者是否有可能不接受利益、损害和成本的分配; 还是用短期内的成本或不良影响换取未来的理想效果(利益)? 他们会不会不同意与理想效果或不良效果相关的价值观，或者因为伦理考虑而不接受诊断干预?  ",
          "options": [
            {
              "text": "不",
              "value": "no"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "是，完全确定",
              "value": "yes"
            }
          ],
          "ratingDescription": "那个干预更向主要利益相关者开放？",
          "ratingOptions": ["best_acceptability", "intermediate_acceptability", "worst_acceptability"],
          "selectedOption": ""
        },
        "additionalSection": {
          "additionalOptions": [
            {
              "text": "补充审核标准",
              "value": "additionalOption_1"
            },
            {
              "text": "补充审核标准2",
              "value": "additionalOption_2"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "用户标准描述",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "提供自定义工具提示",
          "options": [
            {
              "text": "标准1",
              "value": "option_1"
            },
            {
              "text": "标准2",
              "value": "option_2"
            },
            {
              "text": "标准3",
              "value": "option_3"
            },
            {
              "text": "标准4",
              "value": "option_4"
            },
            {
              "text": "标准5",
              "value": "option_5"
            }
          ],
          "selectedOption": ""
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "期望和非期望之间的效果平衡是支持干预还是对照措施？",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "对这个标准细化的审查包括基于每四个进程标准的审核"
              },
              {
                "additions": ["与现在发生的结果(他们的折现率)相比，人们对未来结果的重视程度有多低?  ", "人们对非期望结果的态度（他们是多么厌恶风险）", "人们对理想效果的态度(如何寻求风险)。"],
                "description": "此外，各小组可能希望审议(如果有相关文件的话)下列考虑在多大程度上可能影响理想和不理想效果之间的平衡:  "
              }
            ],
            "panelDiscussion": ""
          },
          "info": "基于个人对主要结果的重视程度，理想和不理想效果的重要性，估计的确定性、贴现率、风险规避和风险寻求，理想和不理想效果之间的平衡是什么?  ",
          "options": [
            {
              "text": "支持对照",
              "value": "favors_comparison"
            },
            {
              "text": "可能支持对照",
              "value": "probably_favors_comparison"
            },
            {
              "text": "既不支持干预也不支持对照",
              "value": "doesnt_favor_any"
            },
            {
              "text": "可能支持干预",
              "value": "probably_favors_intervention"
            },
            {
              "text": "支持干预",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "期望和非期望之间的效果平衡是支持干预还是对照措施？",
          "ratingOptions": ["best_balance", "intermediate", "worst_balance"],
          "selectedOption": ""
        },
        "balanceOfEffectsEOHR": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "期望和非期望之间的效果平衡是支持干预还是对照措施？",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "对这个标准细化的审查包括基于每四个进程标准的审核"
              },
              {
                "additions": ["与现在发生的结果(他们的折现率)相比，人们对未来结果的重视程度有多低?  ", "人们对非期望结果的态度（他们是多么厌恶风险）", "人们对理想效果的态度(如何寻求风险)。"],
                "description": "此外，各小组可能希望审议(如果有相关文件的话)下列考虑在多大程度上可能影响理想和不理想效果之间的平衡:  "
              }
            ],
            "panelDiscussion": ""
          },
          "info": "基于个人对主要结果的重视程度，理想和不理想效果的重要性，估计的确定性、贴现率、风险规避和风险寻求，理想和不理想效果之间的平衡是什么?  ",
          "options": [
            {
              "text": "支持对照",
              "value": "favors_comparison"
            },
            {
              "text": "可能支持对照",
              "value": "probably_favors_comparison"
            },
            {
              "text": "不支持干预或者对照",
              "value": "doesnt_favor_any"
            },
            {
              "text": "可能支持干预",
              "value": "probably_favors_intervention"
            },
            {
              "text": "支持干预",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "期望和非期望之间的效果平衡是支持干预还是对照措施？",
          "ratingOptions": ["best_balance", "intermediate", "worst_balance"],
          "selectedOption": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "理想和不理想的健康影响之间的平衡是支持干预还是对照措施？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "不支持干预或者对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "长期和短期效果（同一条件控制对照）或基于包括患者支持结论的相关干预个体健康所产生的效果（真实世界条件下）是支持干预还是对比？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "不支持干预或者对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "短期和长期效果或干预对民众健康的影响，包括受益者记录结果，是支持干预还是比较？(这应该包括对群体水平的结果是代表总体个体水平的结果或是通过系统动态出现的考虑)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "不支持干预或者对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "当患者/受益人健康价值评价的情况是支持干预还是对比？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照措施",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "既不支持干预也不支持对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "与干预相关的<strong>不良反应的概率和严重程度</strong>（包括干预被错误使用的风险）支持干预还是对照措施？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照措施",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照措施",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "既不支持干预也不支持对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "与干预相关的<strong>更广泛的积极或消极健康相关影响</strong>（例如减少污名、对其他疾病的积极影响、超出患者/受益人的溢出效应）是支持干预还是对照措施？",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "干预与有益健康网络结合得越好，就有更好的可能性辅助这一干预的普及推荐",
          "options": [
            {
              "text": "支持对照措施",
              "value": "favors_the_comparison"
            },
            {
              "text": "可能支持对照措施",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "既不支持干预也不支持对照",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "可能支持干预",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "支持干预",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "测试效果的证据的总体确定性是多少?  ",
          "details": {
            "panelDiscussion": ""
          },
          "info": "该研究对所有关键结果的可能影响提供了多好的指示; 也就是说，效果与研究发现的可能会影响到关于诊断干预的决定的可能性有多大?  ",
          "options": [
            {
              "text": "非常低",
              "value": "very_low"
            },
            {
              "text": "低的",
              "value": "low"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "准确度高的",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "效应证据的总体准确性是多少?  ",
          "details": {
            "panelDiscussion": ""
          },
          "info": "该研究对所有关键结果的可能影响提供了多好的指示; 也就是说，效果与研究发现的可能影响干预决策的可能性有多大?  ",
          "options": [
            {
              "text": "非常低",
              "value": "very_low"
            },
            {
              "text": "低的",
              "value": "low"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "准确度高的",
              "value": "high"
            }
          ],
          "ratingDescription": "测试效果的证据的总体确定性是多少?  ",
          "selectedOption": ""
        },
        "certaintyOfEvidenceEOHR": {
          "additionalOptions": [
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "效应证据的总体准确性是多少?  ",
          "details": {
            "panelDiscussion": ""
          },
          "info": "该研究对所有关键结果的可能影响提供了多好的指示; 也就是说，效果与研究发现的可能影响干预决策的可能性有多大?  ",
          "options": [
            {
              "text": "非常低",
              "value": "very_low"
            },
            {
              "text": "低的",
              "value": "low"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "准确度高的",
              "value": "high"
            }
          ],
          "ratingDescription": "测试效果的证据的总体确定性是多少?  ",
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "由测试结果指导的管理效果的证据的总体确定性是什么?  ",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "非常低",
              "value": "very_low"
            },
            {
              "text": "低的",
              "value": "low"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "准确度高的",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "资源需求（花费）的可信度是什么？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "那些与资源利用相关的重要条款，可能与考虑范围内的多种选择有分歧，这些条款是否已经被辨别出来？",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "主要不确定性",
                    "value": "important"
                  },
                  {
                    "text": "可能的不确定性和多变性",
                    "value": "probably_important"
                  },
                  {
                    "text": "可能存在但不重要的不确定性或多变性",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "不重要的不确定因素",
                    "value": "no_important"
                  }
                ],
                "question": "与考虑选择不同的资源利用成本的花费有多么准确？",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "重要变异；重要变化",
                    "value": "important"
                  },
                  {
                    "text": "可能重要变化",
                    "value": "probably_important"
                  },
                  {
                    "text": "可能非重要变化",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "非重要变化",
                    "value": "no_important"
                  }
                ],
                "question": "区别于预期选择的资源利用条款花费是否存在重要变化",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "每种资源使用情况(例如药物、住院治疗)和资源成本的差异有精确?",
          "options": [
            {
              "text": "非常低",
              "value": "very_low"
            },
            {
              "text": "低的",
              "value": "low"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "高的",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResourcesEOHR": {
          "additionalOptions": [
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "资源需求（花费）的可信度是什么？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "Have all relevant resource types been identified?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "重要变异；重要变化",
                    "value": "important"
                  },
                  {
                    "text": "可能非重要变化",
                    "value": "probably_important"
                  },
                  {
                    "text": "可能非重要变化",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "非重要变化",
                    "value": "no_important"
                  }
                ],
                "question": "Is there important variability in the cost of the resource types under consideration?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "每种资源使用情况(例如药物、住院治疗)和资源成本的差异有精确?",
          "options": [
            {
              "text": "非常低",
              "value": "very_low"
            },
            {
              "text": "低的",
              "value": "low"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "准确度高的",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "测试准确性的总体可信度是多少?  ",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "非常低",
              "value": "very_low"
            },
            {
              "text": "低的",
              "value": "low"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "准确度高的",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "对于任何关键或重要的直接益处、不利影响或测试负担的证据的总体确定性是多少?  ",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "非常低",
              "value": "very_low"
            },
            {
              "text": "低的",
              "value": "low"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "准确度高的",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "测试结果和管理决策之间的联系有多确定?  ",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "非常低",
              "value": "very_low"
            },
            {
              "text": "低的",
              "value": "low"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "准确度高的",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "干预措施的成本效益结果支持干预措施还是对照措施？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "未纳入研究",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "成本效益率是否敏感于单变量敏感性分析？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "未纳入研究",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "成本效益率是否敏感于多变量敏感性分析？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "未纳入研究",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "非常严重的局限性",
                    "value": "very_serious"
                  },
                  {
                    "text": "严重的局限",
                    "value": "serious"
                  },
                  {
                    "text": "不严重的局限",
                    "value": "no_serious"
                  }
                ],
                "question": "成本效益评估所依据的经济评估是否可靠 ？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "未纳入研究",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "非常严重的局限性",
                    "value": "very_serious"
                  },
                  {
                    "text": "严重的局限",
                    "value": "serious"
                  },
                  {
                    "text": "不严重的局限",
                    "value": "no_serious"
                  }
                ],
                "question": "成本效益评估所依据的经济评估是否适用于相关设定 ？",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "综合成本的不确定性或可变性、净效益的不确定性或可变性、敏感性分析以及经济评估的可靠性和适用性，干预措施是否具有成本效益？ ",
          "options": [
            {
              "text": "支持对照措施",
              "value": "favors_comparison"
            },
            {
              "text": "可能支持对照措施",
              "value": "probably_favors_comparison"
            },
            {
              "text": "既不支持干预也不支持对照",
              "value": "doesnt_favor_any"
            },
            {
              "text": "可能支持干预",
              "value": "probably_favors_intervention"
            },
            {
              "text": "支持干预",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "成本效益偏向哪种干预措施 ？",
          "ratingOptions": ["best_cost_effectiveness", "intermediate_cost_effectiveness", "worst_cost_effectiveness"],
          "selectedOption": ""
        },
        "costEffectivenessEOHR": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "未纳入研究",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "干预措施的成本效益结果支持干预措施还是对照措施？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "未纳入研究",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_comparison"
                  },
                  {
                    "text": "不支持干预或者对照",
                    "value": "doesnt_favor_any"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_intervention"
                  }
                ],
                "question": "What is the certainty in the cost effectiveness analysis?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "综合成本的不确定性或可变性、净效益的不确定性或可变性、敏感性分析以及经济评估的可靠性和适用性，干预措施是否具有成本效益？ ",
          "options": [
            {
              "text": "支持对照",
              "value": "favors_comparison"
            },
            {
              "text": "可能支持对照",
              "value": "probably_favors_comparison"
            },
            {
              "text": "不支持干预或者对照",
              "value": "doesnt_favor_any"
            },
            {
              "text": "可能支持干预",
              "value": "probably_favors_intervention"
            },
            {
              "text": "支持干预",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "成本效益偏向哪种干预措施 ？",
          "ratingOptions": ["best_cost_effectiveness", "intermediate_cost_effectiveness", "worst_cost_effectiveness"],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "预期的效果有多大？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "极少的",
                    "value": "trivial"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "大的，大幅的",
                    "value": "large"
                  }
                ],
                "question": "主要结果的预期效果(差异)有多大?  ",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "干预的期望影响在多大程度上将“结果的重要性（标定价值）”和“样本量的影响（获得益处的可能性或者可能获得多大程度的改善  ）”纳入考虑范围",
          "options": [
            {
              "text": "极少的",
              "value": "trivial"
            },
            {
              "text": "小的，少量的",
              "value": "small"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "大的，大幅的",
              "value": "large"
            }
          ],
          "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
          "ratingOptions": ["most_effective", "intermediate_effectiveness", "least_effective"],
          "selectedOption": ""
        },
        "desirableEffectsEOHR": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "预期的效果有多大？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "极少的",
                    "value": "trivial"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "大的，大幅的",
                    "value": "large"
                  }
                ],
                "question": "How substantial is the anticipated desirable impact (effect) of the intervention or exposure?",
                "selectedOptions": {}
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "极少的",
                    "value": "trivial"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "大的，大幅的",
                    "value": "large"
                  }
                ],
                "question": "How substantial is the time span for the intervention to reach full effectiveness?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "干预的期望影响在多大程度上将“结果的重要性（标定价值）”和“样本量的影响（获得益处的可能性或者可能获得多大程度的改善  ）”纳入考虑范围",
          "options": [
            {
              "text": "极少的",
              "value": "trivial"
            },
            {
              "text": "小的，少量的",
              "value": "small"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "大的，大幅的",
              "value": "large"
            }
          ],
          "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
          "ratingOptions": ["most_effective", "intermediate_effectiveness", "least_effective"],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "什么有可能对健康权益有影响？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "是否有群体或环境在考虑问题或选择时处于不利地位?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "是否有合理的理由来预测该选择对弱势群体或环境的相对有效性的差异?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "是否有不同的基线条件影响到选择的绝对有效性或问题对弱势群体或环境的重要性?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "在实施干预(备选办法)时，是否应考虑到一些重要因素，以确保在可能的情况下减少不平等现象，而不是增加不平等现象?  ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "是否有合理的理由来预测对弱势子群体干预的相对有效性的差异或不同的弱势子群体的基线条件，从而影响干预的绝对有效性或问题的重要性  ",
          "options": [
            {
              "text": "减少的，减弱的，削减的",
              "value": "reduced"
            },
            {
              "text": "可能削减的",
              "value": "probably_reduced"
            },
            {
              "text": "可能没有意义，可能没有影响",
              "value": "probably_no_impact"
            },
            {
              "text": "可能增长的，可能增加的",
              "value": "probably_increased"
            },
            {
              "text": "提高的，增加的",
              "value": "increased"
            }
          ],
          "ratingDescription": "如果推荐，那个干预会大大削减健康公正？",
          "ratingOptions": ["most_reduction", "intermediate_reduction", "less_reduction"],
          "selectedOption": ""
        },
        "equityEOHR": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "什么有可能对健康权益有影响？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  },
                  {
                    "text": "不确定",
                    "value": "varies"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "Are there groups or settings (e.g., susceptible populations or life stages) that may experience a different impact (effect) of the intervention or exposure due to variability in baseline conditions across the affected population?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  },
                  {
                    "text": "不确定",
                    "value": "varies"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "Are the impacts unreasonably disproportionate to particular groups (e.g., complex or unquantified stressors)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  },
                  {
                    "text": "不确定",
                    "value": "varies"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "Would implementing the intervention or exposure reduce or increase inequities experienced by marginalized members of the affected population?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  },
                  {
                    "text": "不确定",
                    "value": "varies"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "Are issues of equality (e.g., under-represented or under-studied groups) addressed?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  },
                  {
                    "text": "不确定",
                    "value": "varies"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "Are social justice issues addressed? (e.g., Is the spirit of the ‘polluter pays principle’ upheld with regard to distribution of impacts/benefits?)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "是否有合理的理由来预测对弱势子群体干预的相对有效性的差异或不同的弱势子群体的基线条件，从而影响干预的绝对有效性或问题的重要性  ",
          "options": [
            {
              "text": "减少的，减弱的，削减的",
              "value": "reduced"
            },
            {
              "text": "可能削减的",
              "value": "probably_reduced"
            },
            {
              "text": "可能没有意义，可能没有影响",
              "value": "probably_no_impact"
            },
            {
              "text": "可能增长的，可能增加的",
              "value": "probably_increased"
            },
            {
              "text": "提高的，增加的",
              "value": "increased"
            }
          ],
          "ratingDescription": "如果推荐，那个干预会大大削减健康公正？",
          "ratingOptions": ["most_reduction", "intermediate_reduction", "less_reduction"],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "干预是否能够实施？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "干预（选择）是否可持续？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "可能的干预实施局限或实施是的考虑需求是否存在主要的阻碍？",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "持续使用诊断干预并消除使用诊断干预的潜在障碍是否可行?  ",
          "options": [
            {
              "text": "不",
              "value": "no"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "是，完全确定",
              "value": "yes"
            }
          ],
          "ratingDescription": "哪个干预更有利于实施？",
          "ratingOptions": ["most_feasible", "intermediate_feasibility", "least_feasible"],
          "selectedOption": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "干预是否能够实施？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "是否存在法律障碍可能限制实施干预的可行性?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "不",
                    "value": "no"
                  }
                ],
                "question": "是否存在治理方面(例如，战略考虑，过去的决策)可能限制实施干预的可行性? (这应包括考虑是否存在可以在实施干预影响实施可行性方面提供有效领导、监督和问责制的正式或信息机构)  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "极大的有利影响",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "温和的有利影响",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "可忽略的有利和不利影响",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "微弱的负面影响",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "大的负面影响",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "干预相互作用和适合现有卫生系统的意义是什么? (这包括对干预措施与现有卫生系统及其组成部分的相互作用或影响的考虑?)  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "极大的有利影响",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "温和的有利影响",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "可忽略的有利和不利影响",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "微弱的负面影响",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "大的负面影响",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "干预措施对卫生人力和更广泛的人力资源(在卫生部门或其他部门)有什么影响? (这应包括对卫生人力和其他人力资源的需求、使用和影响及其分配的考虑)  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "极大的有利影响",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "温和的有利影响",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "可忽略的有利和不利影响",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "微弱的负面影响",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "大的负面影响",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "干预措施对卫生系统基础设施和更广泛的基础设施有什么影响? (这应包括对非人力资源和基础设施及其分配的需求、使用和影响的考虑)  ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "从所有或大多数利益攸关方的角度来看，一种选择的可行性越大，就越有可能提出赞成干预的一般性建议。 对整个卫生系统的影响越有利，就越有可能提出支持干预的一般性建议。  ",
          "options": [
            {
              "text": "不",
              "value": "no"
            },
            {
              "text": "可能不",
              "value": "probably_not"
            },
            {
              "text": "不确定的；不准确的",
              "value": "uncertain"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "是，完全确定",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "feasibilityEOHR": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "干预是否能够实施？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "Is the intervention (option) sustainable for the relevant duration of time?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "Are there important barriers (e.g., absence of laws/regulations) that are likely to limit the feasibility of implementing the intervention (option)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "Are remedies available to address any important barriers to implementing the intervention?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "Are there important enablers (e.g., presence of laws/regulations) that are likely to improve the feasibility of implementing the intervention (option)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "持续使用诊断干预并消除使用诊断干预的潜在障碍是否可行?  ",
          "options": [
            {
              "text": "不",
              "value": "no"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "是，完全确定",
              "value": "yes"
            }
          ],
          "ratingDescription": "哪个干预更有利于实施？",
          "ratingOptions": ["most_feasible", "intermediate_feasibility", "least_feasible"],
          "selectedOption": ""
        },
        "feasible": {
          "description": "to identify and measure, and not susceptible to manipulation",
          "options": [],
          "ratingOptions": ["very_feasible", "feasible", "least_feasible"],
          "selectedOptions": ""
        },
        "financialAndEconomicConsiderations": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "金融和基金措施对干预和对比哪个有效？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "不支持干预或者对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "实施和维持政策的成本和预算影响是有利于干预还是有利于比较? (这应该包括对短期和长期的成本和预算影响的考量。)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "不支持干预或者对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "干预对经济的总体影响有助于干预还是对比？（需要包括不同类型的经济影响如何分布到不同部门或组织级别，干预是否有助于或限制更广泛的发展和减贫目标的实现，以及它如何影响现有劳动力）",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "不支持干预和对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "消耗率和收益率（基于消费相应、消费收益或成本利用的评估）是支持干预还是比较措施？",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "某项政策对金融和经济的积极影响越大，就会有更多更完善的相似措施来支持它。",
          "options": [
            {
              "text": "支持对照",
              "value": "favors_the_comparison"
            },
            {
              "text": "可能支持对照",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "不支持干预或者对照",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "可能支持干预",
              "value": "probably_favor_the_intervention"
            },
            {
              "text": "支持干预",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "干预对卫生公平、平等和非歧视有什么影响?  ",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "干预措施是否可能加剧健康状况或其决定因素方面的现有不平等和/或不平等现象? (这应包括考虑不平等在一段时间内可能发生的变化，例如，随着干预措施的扩大，最初的增加是否有可能在一段时间内抵消?)  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "干预的利与弊是否可能以公平的方式分配? (这应包括特别关注对脆弱、边缘化或其他社会处境不利的人口群体的影响。)  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "受影响的人群是否负担得起干预措施，从而在经济上可获得干预措施? (这应包括对家庭卫生支出的影响，包括灾难性卫生支出的风险和与卫生有关的财务风险。)  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "受影响的人群是否可获得干预措施? (这应该包括物理和信息访问方面的考虑。)  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "干预措施是否解决了一种特别严重的疾病(例如，危及生命的、生命终末期、影响既往健康状况差的个人)或罕见的疾病?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "干预是唯一可行的选择吗? (这应包括考虑干预是否与需要相称，以及是否需要定期审查。)  ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "干预越有可能增加卫生公平和/或平等，减少对任何特定群体的歧视，就越有可能提出支持这种干预的一般性建议。 ",
          "options": [
            {
              "text": "消极的",
              "value": "negative"
            },
            {
              "text": "可能消极的；可能不良的",
              "value": "probably_negative"
            },
            {
              "text": "不积极也不消极",
              "value": "neither_negative_nor_positive"
            },
            {
              "text": "可能积极的",
              "value": "probably_positive"
            },
            {
              "text": "积极的",
              "value": "positive"
            }
          ],
          "selectedOption": ""
        },
        "humanRights": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "干预是否符合普遍的人权标准和原则?  ",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "所有建议都应符合普遍的人权标准和原则。  ",
          "options": [
            {
              "text": "不",
              "value": "no"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "不确定的；不准确的",
              "value": "uncertain"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "是，完全确定",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "netBalance": {
          "additionalOptions": [],
          "description": "Net Balance",
          "info": "What is the overall net balance of effects?",
          "options": [
            {
              "text": "Large net desirable",
              "value": "large_net_desirable"
            },
            {
              "text": "Moderate net desirable",
              "value": "moderate_net_desirable"
            },
            {
              "text": "Small net desirable",
              "value": "small_net_desirable"
            },
            {
              "text": "Null net balance",
              "value": "null_net_balance"
            },
            {
              "text": "Small net undesirable",
              "value": "small_net_undesirable"
            },
            {
              "text": "Rede moderada não desejável ",
              "value": "moderate_net_undesirable"
            },
            {
              "text": "Large net undesirable",
              "value": "large_net_undesirable"
            }
          ],
          "selectedOptions": {}
        },
        "problem": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "是否为主要问题？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "问题的后果是否严重(即就潜在的利益或节省而言是严重或重要的)?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "问题是否紧急？",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "它是否是公认的主要问题(例如基于政治或政策决定)?  ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "问题越严重或紧迫，解决问题的方案就越有可能成为主要问题  ",
          "options": [
            {
              "text": "不",
              "value": "no"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "是，完全确定",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problemEOHR": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "是否为主要问题？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "Are the consequences of the problem serious (i.e., severe, irreversible, or important)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "Is the problem urgent, emergent, or unprecedented??",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "Is the problem a recognized priority of the political system (local or national)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "Is the problem a recognized occupational or public health concern?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "不",
                    "value": "no"
                  },
                  {
                    "text": "可能不",
                    "value": "probably_no"
                  },
                  {
                    "text": "可能是",
                    "value": "probably_yes"
                  },
                  {
                    "text": "是，完全确定",
                    "value": "yes"
                  }
                ],
                "question": "Is the problem a recognized priority for a local community?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "问题越严重或紧迫，解决问题的方案就越有可能成为主要问题  ",
          "options": [
            {
              "text": "不",
              "value": "no"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "是，完全确定",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "qualityOfEvidence": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "什么是总体证据质量",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "世界卫生组织体系中不同证据标准的质量越好，普遍推荐的可能性越大。",
          "options": [
            {
              "text": "非常低",
              "value": "very_low"
            },
            {
              "text": "低的",
              "value": "low"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "准确度高的",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "relevantDirect": {
          "description": "to the selected problem and in the field of application",
          "options": [],
          "ratingOptions": ["very_relevant", "relevant", "least_relevant"],
          "selectedOptions": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "资源成本是多少？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "极少的",
                    "value": "trivial"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "大的；大幅的",
                    "value": "large"
                  }
                ],
                "question": "对于较少资源需求的项目，其差异有多大  ",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "大的，大幅的",
                    "value": "large"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "极少的",
                    "value": "trivial"
                  }
                ],
                "question": "每个用于更多资源需求的条目，其项目资源有多大不同？",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "干预和比较之间的资源使用差异的成本有多大?  ",
          "options": [
            {
              "text": "大成本",
              "value": "large_costs"
            },
            {
              "text": "中小成本",
              "value": "moderate_costs"
            },
            {
              "text": "可忽略成本和节省（的资源等）",
              "value": "negligible_costs_savings"
            },
            {
              "text": "中等的节省",
              "value": "moderate_savings"
            },
            {
              "text": "大的，大幅的节省",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
          "ratingOptions": ["less_costs", "intermediate_costs", "most_costs"],
          "selectedOption": ""
        },
        "resourcesRequiredEOHR": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "资源成本是多少？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "极少的",
                    "value": "trivial"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "大的，大幅的",
                    "value": "large"
                  }
                ],
                "question": "For each type of resource, would the intervention or exposure under consideration produce additional costs or savings?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "干预和比较之间的资源使用差异的成本有多大?  ",
          "options": [
            {
              "text": "大成本",
              "value": "large_costs"
            },
            {
              "text": "中小成本",
              "value": "moderate_costs"
            },
            {
              "text": "可忽略成本和节省（的资源等）",
              "value": "negligible_costs_savings"
            },
            {
              "text": "中等的节省",
              "value": "moderate_savings"
            },
            {
              "text": "大的，大幅的节省",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
          "ratingOptions": ["less_costs", "intermediate_costs", "most_costs"],
          "selectedOption": ""
        },
        "responsive": {
          "description": "sensitive to change that means they should be able to capture possible changes in the system",
          "options": [],
          "ratingOptions": ["very_responsive", "responsive", "least_responsive"],
          "selectedOptions": ""
        },
        "scientificallySound": {
          "description": "based on evidence (GRADE criteria)",
          "options": [],
          "ratingOptions": ["very_scientifically_sound", "scientifically_sound", "least_scientifically_sound"],
          "selectedOptions": ""
        },
        "societalImplications": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "理想和不理想的社会影响之间的平衡是支持干预还是对照组?  ",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "既不支持干预也不支持对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "干预的社会影响和社会后果(如增加或减少污名化、教育成果、社会凝聚力或实现健康以外的各种人权)是否支持干预或对照?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "既不支持干预也不支持对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "环境影响和对生态可持续性的影响(例如保护自然资源、减缓或适应气候变化)是否有利于干预或比较? ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "与干预相关的净社会效益越大，支持这种干预的一般建议的可能性就越大。  ",
          "options": [
            {
              "text": "支持对照",
              "value": "favors_the_comparison"
            },
            {
              "text": "可能支持对照",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "既不支持干预也不支持对照",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "可能支持干预",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "支持干预",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "socioCulturalAcceptability": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "干预是否对主要利益相关者开放？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "大的，大幅的",
                    "value": "large"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "极少的",
                    "value": "trivial"
                  }
                ],
                "question": "就侵犯个人自由(包括隐私和尊严)而言，干预的侵犯性有多大? (侵入性的范围很广，从微不足道的——例如通过允许选择(例如修建自行车道)到很高的——例如通过限制或消除选择(例如禁止吸烟))。",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "大的，大幅的",
                    "value": "large"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "琐碎的",
                    "value": "trivial"
                  }
                ],
                "question": "干预对个人、群体和/或组织(关于他们做出称职的、知情的、自愿的决定的能力)的自主性的影响有多大?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "既不支持干预也不支持对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "预期受益人干预的社会文化可接受性是偏向干预还是比较?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "不支持干预和对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "那些打算实施干预的人对干预的社会文化可接受性是偏向干预还是比较?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "既不支持干预也不支持对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "其他相关利益相关者群体对干预的社会文化可接受性是支持干预还是支持对照措施?  ",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "支持对照",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "可能支持对照",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "不支持干预和对照",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "可能支持干预",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "支持干预",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "公众对干预的社会文化接受程度是偏向干预还是比较?  ",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "对所有或大多数相关利益攸关方而言，干预措施的社会文化接受程度越高，就越有可能提出支持这种干预措施的一般性建议。  ",
          "options": [
            {
              "text": "不",
              "value": "no"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "不确定的；不准确的",
              "value": "uncertain"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "是，完全确定",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "测试如何准确？",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "非常不准确",
              "value": "very_inaccurate"
            },
            {
              "text": "不准确",
              "value": "inaccurate"
            },
            {
              "text": "准确的；精准的",
              "value": "accurate"
            },
            {
              "text": "非常准确",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "预期的不良影响有多大？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "极少的",
                    "value": "trivial"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "大的，大幅的",
                    "value": "large"
                  }
                ],
                "question": "期望影响（差别）对于每个主要结果的不良影响有多大？",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "考虑到结果的重要性(它们的价值有多大)，干预的不良影响有多大(经历收益的可能性或个人可能经历多少改善)?  ",
          "options": [
            {
              "text": "极少的",
              "value": "trivial"
            },
            {
              "text": "小的，少量的",
              "value": "small"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "大的，大幅的",
              "value": "large"
            }
          ],
          "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
          "ratingOptions": ["least_harmful", "intermediate", "more_harmful"],
          "selectedOption": ""
        },
        "undesirableEffectsEOHR": {
          "additionalOptions": [
            {
              "text": "不确定",
              "value": "varies"
            },
            {
              "text": "不知道",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "预期的不良影响有多大？",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "大的，大幅的",
                    "value": "large"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "极少的",
                    "value": "trivial"
                  }
                ],
                "question": "How substantial is the anticipated undesirable impact (effect) of the intervention or exposure?",
                "selectedOptions": {}
              },
              {
                "additionalOptions": [
                  {
                    "text": "不确定",
                    "value": "varies"
                  },
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "大的，大幅的",
                    "value": "large"
                  },
                  {
                    "text": "轻度的，轻微的",
                    "value": "moderate"
                  },
                  {
                    "text": "小的，少量的",
                    "value": "small"
                  },
                  {
                    "text": "极少的",
                    "value": "trivial"
                  }
                ],
                "question": "How substantial is the time span for the undesirable effects of the intervention?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "考虑到结果的重要性(它们的价值有多大)，干预的不良影响有多大(经历收益的可能性或个人可能经历多少改善)?  ",
          "options": [
            {
              "text": "大的，大幅的",
              "value": "large"
            },
            {
              "text": "轻度的，轻微的",
              "value": "moderate"
            },
            {
              "text": "小的，少量的",
              "value": "small"
            },
            {
              "text": "极少的",
              "value": "trivial"
            }
          ],
          "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
          "ratingOptions": ["least_harmful", "intermediate", "more_harmful"],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "人们对主要结果的重视程度是否存在重要的不确定性或多样性?  ",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "主要不确定性",
                    "value": "important"
                  },
                  {
                    "text": "可能主要不确定因素",
                    "value": "probably_important"
                  },
                  {
                    "text": "可能不重要的不确定因素",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "不重要的不确定性",
                    "value": "no_important"
                  }
                ],
                "question": "人们对主要结果的重视程度是否存在重要的不确定性?  ",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "主要结果的个人价值有多少？对于每个结果的价值的不确定性或者不同个体对结果的价值的可变性是否足以导致不同的决定?  ",
          "options": [
            {
              "text": "重要的不确定性和多变性",
              "value": "important_uncertainty"
            },
            {
              "text": "可能重要的不确定性和多变性",
              "value": "possible_important"
            },
            {
              "text": "可能的不重要的不确定性或多变性",
              "value": "probably_no_important"
            },
            {
              "text": "不重要的不确定性或多变性",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        },
        "valuesEOHR": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "人们对主要结果的重视程度是否存在重要的不确定性或多样性?  ",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "主要不确定性",
                    "value": "important"
                  },
                  {
                    "text": "可能存在但不重要的不确定性或多变性",
                    "value": "probably_important"
                  },
                  {
                    "text": "可能存在但不重要的不确定性或多变性",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "不重要的不确定因素",
                    "value": "no_important"
                  }
                ],
                "question": "人们对主要结果的重视程度是否存在重要的不确定性?  ",
                "selectedOptions": {}
              },
              {
                "additionalOptions": [
                  {
                    "text": "不知道",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "重要变异；重要变化",
                    "value": "important"
                  },
                  {
                    "text": "可能非重要变化",
                    "value": "probably_important"
                  },
                  {
                    "text": "可能非重要变化",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "非重要变化",
                    "value": "no_important"
                  }
                ],
                "question": "Is there meaningful variability in how much people value the main outcomes?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "主要结果的个人价值有多少？对于每个结果的价值的不确定性或者不同个体对结果的价值的可变性是否足以导致不同的决定?  ",
          "options": [
            {
              "text": "重要的不确定性和多变性",
              "value": "important_uncertainty"
            },
            {
              "text": "可能重要的不确定性和多变性",
              "value": "possible_important"
            },
            {
              "text": "可能的不重要的不确定性或多变性",
              "value": "probably_no_important"
            },
            {
              "text": "不重要的不确定性或多变性",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "netBalance": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "可接受性",
          "researchEvidenceId": "acceptability"
        },
        "acceptabilityEOHR": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptabilityEOHR",
          "name": "可接受性",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "用户标准",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "效应范围",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfEffectsEOHR": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffectsEOHR",
          "name": "效应范围",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
          "criterionId": "balanceOfHealthBenefitsAndHarms",
          "name": "健康指数参考区间",
          "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "效应可信度",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "证据可信度",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceEOHR": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidenceEOHR",
          "name": "证据可信度",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "管理影响可信度",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "资源需求可信度",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfRequiredResourcesEOHR": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResourcesEOHR",
          "name": "资源需求可信度",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "测试精度可信性",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "测试效应精度",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "实验结果/设计可信度",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "成本效应",
          "researchEvidenceId": "costEffectiveness"
        },
        "costEffectivenessEOHR": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectivenessEOHR",
          "name": "成本效应",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "期望影响",
          "researchEvidenceId": "desirableEffects"
        },
        "desirableEffectsEOHR": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffectsEOHR",
          "name": "期望影响",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "公平性",
          "researchEvidenceId": "equity"
        },
        "equityEOHR": {
          "additionalConsiderationId": "equity",
          "criterionId": "equityEOHR",
          "name": "公平性",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "可实施性",
          "researchEvidenceId": "feasibility"
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
          "criterionId": "feasibilityAndHealthSystemConsiderations",
          "name": "可行性及卫生系统因素",
          "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
        },
        "feasibilityEOHR": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibilityEOHR",
          "name": "可实施性",
          "researchEvidenceId": "feasibility"
        },
        "feasible": {
          "additionalConsiderationId": "feasible",
          "criterionId": "feasible",
          "name": "FEASIBLE",
          "researchEvidenceId": "feasible"
        },
        "financialAndEconomicConsiderations": {
          "additionalConsiderationId": "financialAndEconomicConsiderations",
          "criterionId": "financialAndEconomicConsiderations",
          "name": "金融经济因素",
          "researchEvidenceId": "financialAndEconomicConsiderations"
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
          "criterionId": "healthEquityEqualityAndNonDiscrimination",
          "name": "卫生公众、平等、无歧视",
          "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
        },
        "humanRights": {
          "additionalConsiderationId": "humanRights",
          "criterionId": "humanRights",
          "name": "人权",
          "researchEvidenceId": "humanRights"
        },
        "netBalance": {
          "additionalConsiderationId": "netBalance",
          "criterionId": "netBalance",
          "name": "Net balance",
          "researchEvidenceId": "netBalance"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "问题；质疑",
          "researchEvidenceId": "problem"
        },
        "problemEOHR": {
          "additionalConsiderationId": "problem",
          "criterionId": "problemEOHR",
          "name": "问题；质疑",
          "researchEvidenceId": "problem"
        },
        "qualityOfEvidence": {
          "additionalConsiderationId": "qualityOfEvidence",
          "criterionId": "qualityOfEvidence",
          "name": "证据质量",
          "researchEvidenceId": "qualityOfEvidence"
        },
        "relevantDirect": {
          "additionalConsiderationId": "relevantDirect",
          "criterionId": "relevantDirect",
          "name": "RELEVANT (DIRECT)",
          "researchEvidenceId": "relevantDirect"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "资源需求",
          "researchEvidenceId": "resourcesRequired"
        },
        "resourcesRequiredEOHR": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequiredEOHR",
          "name": "资源需求",
          "researchEvidenceId": "resourcesRequired"
        },
        "responsive": {
          "additionalConsiderationId": "responsive",
          "criterionId": "responsive",
          "name": "RESPONSIVE",
          "researchEvidenceId": "responsive"
        },
        "scientificallySound": {
          "additionalConsiderationId": "scientificallySound",
          "criterionId": "scientificallySound",
          "name": "SCIENTIFICALLY SOUND (CERTAINTY OF EVIDENCE)",
          "researchEvidenceId": "scientificallySound"
        },
        "societalImplications": {
          "additionalConsiderationId": "societalImplications",
          "criterionId": "societalImplications",
          "name": "社会影响",
          "researchEvidenceId": "societalImplications"
        },
        "socioCulturalAcceptability": {
          "additionalConsiderationId": "socioCulturalAcceptability",
          "criterionId": "socioCulturalAcceptability",
          "name": "Socio文化接受度",
          "researchEvidenceId": "socioCulturalAcceptability"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": "测试精度",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "不利效应；不利影响",
          "researchEvidenceId": "undesirableEffects"
        },
        "undesirableEffectsEOHR": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffectsEOHR",
          "name": "不利效应；不利影响",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "值",
          "researchEvidenceId": "values"
        },
        "valuesEOHR": {
          "additionalConsiderationId": "values",
          "criterionId": "valuesEOHR",
          "name": "值",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "benefits": {
          "content": "",
          "description": "收益"
        },
        "clearAndActionable": {
          "content": "",
          "description": "清晰且可实施的",
          "options": [
            {
              "text": "是，完全确定",
              "value": "yes"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "不",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "clinicalConsiderations": {
          "content": "",
          "description": "Clinical considerations"
        },
        "collectingAndSummarizing": {
          "content": "",
          "description": "收集和总结证据是对指导小组有限的时间和精力的不良利用(机会成本很大)。",
          "options": [
            {
              "text": "是，完全确定",
              "value": "yes"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "不",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "considerations": {
          "content": "",
          "description": "措施"
        },
        "contextAndSystemConsiderations": {
          "content": "",
          "description": "背景和系统考量"
        },
        "decision": {
          "content": "",
          "description": "决定；决议；决策",
          "options": [
            {
              "text": "完全实施",
              "value": "full_implementation"
            },
            {
              "text": "影响评估；影响评价",
              "value": "impact_evaluation"
            },
            {
              "text": "初步研究；预实验",
              "value": "pilot_study"
            },
            {
              "text": "推迟；延后；延期；推延",
              "value": "postpone"
            },
            {
              "text": "不实施",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "决定类型；决策类型",
          "options": [
            {
              "direction": "negative",
              "text": "不覆盖；未覆盖；未涉及",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "覆盖证据发展",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "覆盖价格谈判",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "限制范围",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "覆盖；掩埋",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "决定类型；决策类型",
          "options": [
            {
              "direction": "negative",
              "text": "不实施选择",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "推迟决策",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "实施选择的预实验",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "用有意义的评估实施选择",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "实施选择",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "evidence": {
          "content": "",
          "description": "证据；"
        },
        "frequencyDoseDuration": {
          "content": "",
          "description": "频率/剂量/持续时间  "
        },
        "goodPracticeStatement": {
          "content": "",
          "description": "好实施的陈述"
        },
        "harms": {
          "content": "",
          "description": "坏处"
        },
        "implementationConsiderations": {
          "content": "",
          "description": "实施措施",
          "details": {
            "content": "",
            "description": "细化的措施"
          }
        },
        "implementationConsiderationsOfAllRelevantOutcomes": {
          "content": "",
          "description": "在考虑了所有相关结果和潜在的下游后果之后，实施良好实践声明将产生巨大的净积极后果 ",
          "options": [
            {
              "text": "是，完全确定",
              "value": "yes"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "不",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderationsOrRemarks": {
          "content": "",
          "description": "实施措施",
          "options": [
            {
              "text": "是，完全确定",
              "value": "yes"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "不",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "indications": {
          "content": "",
          "description": "迹象；预计时间；估计"
        },
        "indicationsForDiscontinuation": {
          "content": "",
          "description": "预计终止"
        },
        "justification": {
          "content": "",
          "description": "合理理由",
          "details": {
            "description": "细化的合理理由"
          },
          "overallDescription": "总体理由"
        },
        "keyMessage": {
          "content": "",
          "description": "主要信息"
        },
        "limitations": {
          "content": "",
          "description": "局限"
        },
        "mdgRecommendation": {
          "content": "",
          "description": "建议；推荐",
          "options": [
            {
              "direction": "positive",
              "text": "推荐的；建议的",
              "value": "recommend"
            },
            {
              "direction": "negative",
              "text": "不推荐",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Sometimes Recommended",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "没有推荐",
              "value": "suggest_either"
            }
          ],
          "selectedOption": ""
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "监管和评价",
          "details": {
            "content": "",
            "description": "细化的监管和评估"
          }
        },
        "monitoringAndEvaluationQI": {
          "content": "",
          "description": "监管和评价"
        },
        "multipleChronicConditionsAndPolypharmacy": {
          "content": "",
          "description": "多种慢性疾病和多药治疗"
        },
        "multipleRecommendations": {
          "description": "建议；推荐",
          "options": [
            {
              "direction": "negative",
              "text": "强烈建议反对干预",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "一定条件下反对干预建议  ",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "一定条件下推荐干预或者对照措施",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "在一定条件下对干预措施的推荐",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "对干预措施的强烈推荐",
              "value": "recommend"
            }
          ],
          "recommendations": [
            {
              "content": "",
              "selectedOption": ""
            }
          ]
        },
        "rationale": {
          "content": "",
          "description": "基本原理"
        },
        "rationaleConnectingIndirectEvidence": {
          "content": "",
          "description": "这些间接证据之间有一个清晰而明确的理论依据  ",
          "options": [
            {
              "text": "是，完全确定",
              "value": "yes"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "不",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "recommendation": {
          "content": "",
          "description": "建议；推荐"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "推荐类型；建议类型",
          "options": [
            {
              "direction": "negative",
              "text": "强烈建议反对干预",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "一定条件下反对干预措施的建议",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "一定条件下对干预或对比措施的推荐",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "一定条件下对干预措施的推荐",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "对干预措施的强烈推荐",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeInterventionMultipleChoice": {
          "description": "推荐类型；建议类型",
          "options": [
            {
              "direction": "negative",
              "text": "强烈建议反对干预",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "一定条件下反对干预建议  ",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "一定条件下推荐干预或者对照措施",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "在一定条件下对干预措施的推荐",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "对干预措施的强烈推荐",
              "value": "recommend"
            }
          ],
          "selectedOptions": {}
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "推荐类型；建议类型",
          "options": [
            {
              "direction": "negative",
              "text": "反对选择的强烈建议",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "一定条件下反对该选项的建议",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "一定条件下对该选项或者对照措施的推荐",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "一定条件下对该选项的推荐",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "强烈推荐该选项",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "调查优先"
        },
        "restrictions": {
          "content": "",
          "description": "限制条件"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "亚组思想"
        },
        "theMessageIsNecessary": {
          "content": "",
          "description": "这一信息对于实际的卫生保健实践是必要的  ",
          "options": [
            {
              "text": "是，完全确定",
              "value": "yes"
            },
            {
              "text": "可能是",
              "value": "probably_yes"
            },
            {
              "text": "可能不",
              "value": "probably_no"
            },
            {
              "text": "不",
              "value": "no"
            }
          ],
          "selectedOption": ""
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "临床医生",
          "sections": {
            "background": {
              "name": "背景"
            },
            "detailedJustification": {
              "name": "细化的理由"
            },
            "implementationConsiderations": {
              "name": "干预措施"
            },
            "justification": {
              "name": "公正"
            },
            "relatedRecommendations": {
              "name": "相关建议"
            },
            "subgroupConsiderations": {
              "name": "亚组考虑"
            },
            "summaryOfFindings": {
              "name": "结果总结；结论总结；"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "患者",
          "sections": {
            "relatedRecommendations": {
              "name": "相关建议"
            },
            "summaryOfFindings": {
              "name": "平静"
            },
            "whatItMeansForYou": {
              "name": "这对你来说意味着什么？",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "与你的私人医生沟通"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "你能做什么？"
                }
              },
              "sectionsOrder": ["whatYouCanDo", "speakWithHCProfessional"]
            },
            "whoIsThisFor": {
              "name": "这是为谁准备的",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "这是为谁准备的？"
                }
              },
              "sectionsOrder": ["whoIsThisFor"]
            },
            "whyThisRecommendation": {
              "name": "为什么这样建议？",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "补充信息"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "好处和坏处"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "为什么这样建议"
                }
              },
              "sectionsOrder": ["whyThisRecommendation", "benefitsAndHarms", "additionalInformation"]
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "政策制定者",
          "sections": {
            "assessment": {
              "name": "评估；估计"
            },
            "background": {
              "description": "建议的问题细节",
              "details": {
                "name": "关于这一决定",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "日期"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "决策者"
                  },
                  "perspective": {
                    "content": "",
                    "description": "期望的；预想的"
                  },
                  "setting": {
                    "content": "",
                    "description": "设置；放置"
                  }
                },
                "sectionsOrder": ["setting", "perspective", "decisionMakers", "date"]
              },
              "name": "背景"
            },
            "decision": {
              "content": "",
              "name": "决定；决议；决策",
              "options": [
                {
                  "text": "完全实施",
                  "value": "full_implementation"
                },
                {
                  "text": "影响评估；影响评价",
                  "value": "impact_evaluation"
                },
                {
                  "text": "初步研究；预实验",
                  "value": "pilot_study"
                },
                {
                  "text": "推迟；延后；延期；推延",
                  "value": "postpone"
                },
                {
                  "text": "不实施",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "实施"
            },
            "justification": {
              "name": "公正"
            },
            "monitoringAndEvaluation": {
              "name": "检测和评估"
            },
            "relatedRecommendations": {
              "name": "相关建议"
            },
            "summaryOfFindings": {
              "name": "结果总结；结论总结；"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "age": {
          "content": "",
          "name": "年龄"
        },
        "anticipatedOutcomes": {
          "content": "",
          "name": "预期结果"
        },
        "background": {
          "content": "",
          "name": "背景"
        },
        "coi": {
          "content": "",
          "name": "利益冲突"
        },
        "comparison": {
          "content": "",
          "name": "对照；比较"
        },
        "intent": {
          "content": "",
          "name": "意图；目的"
        },
        "intervention": {
          "content": "",
          "name": "干预"
        },
        "linkedTreatments": {
          "content": "",
          "name": "接续的治疗"
        },
        "mainOutcomes": {
          "content": "",
          "name": "主要结果"
        },
        "option": {
          "content": "",
          "name": "选择"
        },
        "perspective": {
          "content": "",
          "name": "期望的；预想的"
        },
        "population": {
          "content": "",
          "name": "人群"
        },
        "problem": {
          "content": "",
          "name": "问题；质疑"
        },
        "purpose": {
          "content": "",
          "name": "测试目的"
        },
        "requirements": {
          "content": "",
          "name": "Requirements"
        },
        "role": {
          "content": "",
          "name": "测试角色"
        },
        "setting": {
          "content": "",
          "name": "设置；放置"
        },
        "subgroups": {
          "content": "",
          "name": "亚组；"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
