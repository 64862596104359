module.exports = {
  sections: [
    {
      id: 'en',
      sections: 'lib/etd_templates/templates_sections'
    }, {
      id: 'es',
      sections: 'lib/etd_templates/templates_sections_es'
    }, {
      id: 'ja',
      sections: 'lib/etd_templates/templates_sections_ja'
    }, {
      id: 'et',
      sections: 'lib/etd_templates/templates_sections_et'
    }, {
      id: 'pt',
      sections: 'lib/etd_templates/templates_sections_pt'
    }, {
      id: 'th',
      sections: 'lib/etd_templates/templates_sections_th'
    }, {
      id: 'it',
      sections: 'lib/etd_templates/templates_sections_it'
    }, {
      id: 'fr',
      sections: 'lib/etd_templates/templates_sections_fr'
    }, {
      id: 'fa',
      sections: 'lib/etd_templates/templates_sections_fa'
    }, {
      id: 'ar',
      sections: 'lib/etd_templates/templates_sections_ar'
    }, {
      id: 'zh_CHS',
      sections: 'lib/etd_templates/templates_sections_zh_chs'
    }, {
      id: 'cs',
      sections: 'lib/etd_templates/templates_sections_cs'
    }, {
      id: 'de',
      sections: 'lib/etd_templates/templates_sections_de'
    }, {
      id: 'hi',
      sections: 'lib/etd_templates/templates_sections_hi'
    }, {
      id: 'sk',
      sections: 'lib/etd_templates/templates_sections_sk'
    }
  ],
  defaultLanguage: 'en'
};
