var Block, algorithmsDocShape, arrayOf, boolean, docShape, initial, number, object, oneOf, optional, shape, string, _ref;

shape = require('lib/db_docs/field_types/shape');

docShape = require('lib/db_docs/field_types/doc_shape');

initial = require('lib/db_docs/field_types/type_decorators').initial;

arrayOf = require('lib/db_docs/field_types/array_of');

oneOf = require('lib/db_docs/field_types/one_of');

_ref = require('lib/db_docs/field_types/built_in_types'), string = _ref.string, boolean = _ref.boolean, number = _ref.number, object = _ref.object;

optional = shape.typeDecorators.optional;

Block = shape({
  id: string,
  type: string,
  data: optional(object),
  position: optional(object),
  style: optional(object),
  draggableX: optional(boolean),
  label: optional(string),
  labelStyle: optional(object),
  source: optional(string),
  target: optional(string),
  targetPosition: optional(string),
  sourceHandle: optional(string),
  targetHandle: optional(string),
  arrowHeadType: optional(string),
  animated: optional(boolean),
  labelXYOffset: optional(object)
});

algorithmsDocShape = docShape({
  title: initial('')(string),
  width: initial(500)(number),
  height: initial(500)(number),
  blocks: initial([])(arrayOf(Block)),
  orderNumber: number
});

module.exports = algorithmsDocShape;
