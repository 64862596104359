VotingTeamComments = require 'components/etd/voting_team_comments'
VotingAdminComment = require 'components/etd/voting_admin_comment'
EtdActions = require 'actions/etd_actions'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

VotingCommentsCell = createReactClass
  displayName: 'VotingCommentsCell'
  mixins: [CustomRenderMixin, Translation('es:recommendations.table')]

  _getTeamCommentsBlockText: ->
    if @props.contentField is 'content'
      @i18n 'team_members_propositions'
    else
      @i18n 'team_members_votes_comments'

  onApply: (newContent) ->
    cellId = "#{@props.etdPart}_#{@props.sectionId}#votingComment"
    EtdActions.updateEditorContent { cellId, content: newContent}

  render: ->
    <div className='voting-comments-cell'>
      <span>{@_getTeamCommentsBlockText()}</span>
      <VotingTeamComments
        etdPart={@props.etdPart}
        renderMode={@props.renderMode}
        isProposedVoting={@props.isProposedVoting}
        votingResults={@props.votingResults}
        votingOptions={@props.votingOptions}
        contentField={@props.contentField}
        actions={EtdActions}
        sectionId={@props.sectionId}
        hiddenVotes={@props.hiddenVotes}
      />
      <VotingAdminComment
        adminComment={@props.adminComment}
        renderMode={@props.renderMode}
        onApply={@onApply}
      />
    </div>

module.exports = VotingCommentsCell
