var NMAOutcome, NMAOutcomeActions, NMAOutcomeToolbar, NMAQuestionView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

NMAOutcomeActions = require('actions/nma_outcome_actions');

NMAOutcomeToolbar = require('components/nma/nma_outcome_toolbar');

NMAOutcome = require('components/nma/nma_outcome');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = NMAQuestionView = (function(_super) {
  __extends(NMAQuestionView, _super);

  function NMAQuestionView() {
    return NMAQuestionView.__super__.constructor.apply(this, arguments);
  }

  NMAQuestionView.prototype.container = '#page-container';

  NMAQuestionView.prototype.id = 'nma-question';

  NMAQuestionView.prototype.autoRender = true;

  NMAQuestionView.prototype._stopInputsPropagation = false;

  NMAQuestionView.prototype.initialize = function() {
    NMAQuestionView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: NMAOutcomeToolbar
    }));
    return this.enable(ReactComponent);
  };

  NMAQuestionView.prototype.afterRender = function() {
    NMAQuestionView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(NMAOutcome, NMAOutcomeActions, this.options.outcomeId, {
      fetchAndListen: {
        dbId: mediator.project.id
      },
      fetchParams: {
        questionId: this.options.questionId
      }
    });
  };

  NMAQuestionView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('NMAListStore', 'NMAQuestionStore', 'NMAOutcomeStore');
    return NMAQuestionView.__super__.dispose.apply(this, arguments);
  };

  return NMAQuestionView;

})(View);
