ApprovalDetails = require 'components/scope/questions/approval_question_details'
ApprovalDetailsExtended = require 'components/scope/questions/approval_question_details_extended'
CommentButton = require 'components/common/empty_comment_button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
QuestionsGenerationActions = require 'actions/questions_generation_actions'

ApprovalDetailsRow = createReactClass
  displayName: 'ApprovalDetailsRow'

  propTypes:
    question: PropTypes.instanceOf(Immutable.Map).isRequired
    membersMap: PropTypes.instanceOf Immutable.Map
    onCommentToggle: PropTypes.func.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:questions.approval')
  ]

  _didAllMembersApprove: ->
    approvals = @props.question.get 'approvals'
    approvals.every (approval) -> approval is 'yes'

  onApplyAdminComment: (stepName) -> (data) ->
    data.step = stepName
    QuestionsGenerationActions.saveAdminComment data

  onSaveClose: ->
    @props.onCommentToggle()
    unless @props.readOnly
      adminComment = @detailsBlock.getAdminComment()
      QuestionsGenerationActions.saveAdminComment
        step: 'questions.approval'
        idOrKey: @props.question.get('id')
        newText: adminComment

  _renderDetailsBlock: (allApproved) ->
    props = _.pick @props, ['question', 'membersMap', 'readOnly', 'groupType']

    if allApproved
      <ApprovalDetails {...props} onApplyAdminComment={@onApplyAdminComment} />
    else
      <ApprovalDetailsExtended {...props}
        ref={(el) => @detailsBlock = el}
        todos={@props.todos}
        saveTodos={QuestionsGenerationActions.saveTodos}
        setDisagreementResolved={QuestionsGenerationActions.setDisagreementResolved}
        onApplyAdminComment={@onApplyAdminComment}
      />

  _renderButtonsBlock: (allApproved) ->
    return null if allApproved
    <div className='button-container'>
      <button onClick={@onSaveClose} className='btn save-close'>
        {@i18n 'save_close'}
      </button>
    </div>

  render: ->
    allApproved = @_didAllMembersApprove()

    <tr className='question-details-row'>
      <td colSpan='3'>
        {@_renderDetailsBlock allApproved}
        {@_renderButtonsBlock allApproved}
      </td>
    </tr>

module.exports = ApprovalDetailsRow
