SpinnerButton = require 'components/common/spinner_button'
QuestionGroup = require 'components/scope/outcomes/question_group'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
OutcomeShowCommentsMixin = require 'components/mixins/outcomes_show_comments_mixin'
ScopeDataFetchMixin = require 'components/mixins/scope_data_fetch_mixin'
brainstormingStep = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS[1]

{ Button } = ReactComponents

BrainstormingTab = createReactClass
  displayName: 'BrainstormingTab'

  propTypes:
    questionGroups: PropTypes.object.isRequired
    fetchResults: PropTypes.func.isRequired
    readOnly: PropTypes.bool.isRequired
    closeStep: PropTypes.func.isRequired
    isClosingStep: PropTypes.bool.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
    ScopeDataFetchMixin
    OutcomeShowCommentsMixin(brainstormingStep)
  ]

  renderQuestionGroup: (qGroup, idx) ->
    <QuestionGroup
      key={idx}
      data={qGroup}
      gIndex={idx}
      membersMap={@props.membersMap}
      showingComments={@state.showingAllComments}
      onCommentToggle={@onItemCommentToggle}
      readOnly={@props.readOnly}
    />

  render: ->
    total = @props.membersSentTo.size
    completed = @props.membersSubmitted.size
    <div className='brainstorming-tab'>
      <div
        className='information'
        dangerouslySetInnerHTML={{__html: @i18n 'brainstorming_section_text'}}
      />
      <div className='top-buttons'>
        <span className="responded">{@i18n 'responded'} {completed} {@i18n 'of'} {total}</span>
        <Button className='btn-refresh'
          onClick={@props.fetchResults}
          disabled={@_isFetchDisabled()}>
          {@i18n '../questions.brainstorming.refresh'}
        </Button>
        <Button
          onClick={@showComments}
          disabled={@isShowCommentsDisabled()}>
          {@i18n '../questions.show_all_comments'}
        </Button>
      </div>
      <div className="questions-container">
        {@props.questionGroups.map(@renderQuestionGroup).toList()}
      </div>
      <div className="buttons">
        <SpinnerButton
          className="btn close-step"
          onClick={@props.closeStep}
          disabled={@props.readOnly}
          loading={@props.isClosingStep}
          label={@i18n '../questions.brainstorming.next_step'}
        />
      </div>
    </div>

module.exports = BrainstormingTab
