var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/outcome_label", depth0, "{\"colNo\": 1}", {"name":"cell","hash":{},"data":data})))
    + "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 2,\n      \"effect-test\": \"index\", \"class\": \"prevalence-1\", \"dataPropertySuffix\": \"-index1\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 3,\n      \"effect-test\": \"comparison\", \"class\": \"prevalence-1\", \"dataPropertySuffix\": \"-comparison1\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 4,\n      \"effect-test\": \"index\", \"class\": \"prevalence-2\", \"dataPropertySuffix\": \"-index2\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 5,\n      \"effect-test\": \"comparison\", \"class\": \"prevalence-2\", \"dataPropertySuffix\": \"-comparison2\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 6,\n      \"effect-test\": \"index\", \"class\": \"prevalence-3\", \"dataPropertySuffix\": \"-index3\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 7,\n      \"effect-test\": \"comparison\", \"class\": \"prevalence-3\", \"dataPropertySuffix\": \"-comparison3\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/no_of_participants_studies", depth0, "{\"colNo\": 8, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n";
  stack1 = ((helpers.any || (depth0 && depth0.any) || helperMissing).call(depth0, (depth0 != null ? depth0['short'] : depth0), (depth0 != null ? depth0.noComments : depth0), {"name":"any","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 9, \"rowspan\": 2, \"class\":\"wider\"}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 9, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n      "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "comments", depth0, "{\"colNo\": 10, \"class\":\"wider\"}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 2,\n      \"effect-test\": \"index\", \"class\": \"prevalence-1\", \"dataPropertySuffix\": \"-1\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 3,\n      \"effect-test\": \"index\", \"class\": \"prevalence-2\", \"dataPropertySuffix\": \"-2\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 4,\n      \"effect-test\": \"index\", \"class\": \"prevalence-3\", \"dataPropertySuffix\": \"-3\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/no_of_participants_studies", depth0, "{\"colNo\": 5, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 6, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.noComments : depth0), {"name":"unless","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "comments", depth0, "{\"colNo\": 7}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, "Inconclusive", (depth0 != null ? depth0.name : depth0), {"name":"is","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, "Complications", (depth0 != null ? depth0.name : depth0), {"name":"is","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/outcome_label", depth0, "{\"colNo\": 1}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/inconclusive_prevalences_desc", depth0, "{\"colNo\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/no_of_participants_studies", depth0, "{\"colNo\": 3}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, 4, "{\"className\": \"quality\", \"dataProperty\": \"quality\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0['short'] : depth0), {"name":"unless","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.noComments : depth0), {"name":"unless","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "comments", depth0, "{\"colNo\": 5}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/outcome_label", depth0, "{\"colNo\": 1}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/complications_prevalences_desc", depth0, "{\"colNo\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/no_of_participants_studies", depth0, "{\"colNo\": 3}", {"name":"cell","hash":{},"data":data})))
    + "\n";
  stack1 = ((helpers.any || (depth0 && depth0.any) || helperMissing).call(depth0, (depth0 != null ? depth0['short'] : depth0), (depth0 != null ? depth0.noComments : depth0), {"name":"any","hash":{},"fn":this.program(17, data),"inverse":this.program(19, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 4, \"class\":\"wider\"}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"19":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 4}", {"name":"cell","hash":{},"data":data})))
    + "\n      "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "comments", depth0, "{\"colNo\": 5, \"class\":\"wider\"}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "TP, FN, TN, FP", (depth0 != null ? depth0.name : depth0), {"name":"inStringArray","hash":{},"fn":this.program(1, data),"inverse":this.program(11, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}