mediator = require 'mediator'
Router = require 'router'
Translation = require 'components/mixins/translation'
ModalVisibility = require 'components/mixins/modal_visibility'
Button = require 'components/common/button'
DecisionAid = require 'components/decision_aid/decision_aid'
DecisionAidsActions = require 'actions/decision_aids_actions'
ChapterEditModal = require 'components/decision_aid/chapter_edit_modal'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
DecisionAid = require 'components/decision_aid/decision_aid'
ChaptersList = require 'components/decision_aid/chapters_list'
DraggableChaptersList = require 'components/decision_aid/draggable_chapters_list'
{ getChaptersOrder } = require 'lib/decision_aids_helpers'

utils = require 'base/lib/utils'
mediator = require 'mediator'


CHAPTERS_CONTROLS = ['changeOrder', 'addNew', 'expandAll']

DecisionAidDetails = createReactClass
  displayName: 'DecisionAidDetails'

  propTypes:
    decisionAid: PropTypes.instanceOf(Immutable.Map)
    editModalVisible: PropTypes.bool.isRequired
    editingChaptersOrder: PropTypes.instanceOf(Immutable.List)
    expandedChapters: PropTypes.instanceOf(Immutable.Set).isRequired
    isEditingHeader: PropTypes.bool.isRequired

  mixins: [Translation('')]

  hideChapterEditModal: ->
    DecisionAidsActions.toggleEditModal false

  handleNewChapterCreate: (chapterData) ->
    DecisionAidsActions.createNewChapter
      daId: @props.decisionAid.get('_id')
      chapterData: chapterData
    @hideChapterEditModal()

  saveChaptersOrder: ->
    DecisionAidsActions.saveDecisionAidChaptersOrder
      daId: @props.decisionAid.get('_id')
      chaptersOrder: @props.editingChaptersOrder.toJS()
    .then ->
      DecisionAidsActions.stopEditingChaptersOrder()

  handleChapterCollapse: (chapterId) ->
    DecisionAidsActions.setExpandedChapters @props.expandedChapters.delete chapterId

  handleChapterExpand: (chapterId) ->
    DecisionAidsActions.setExpandedChapters @props.expandedChapters.add chapterId

  handleDecisionAidDelete: ->
    mediator.publish '!router:route', Router::getProjectRelativeUrl '/dissemination/decision-aids'

  render: ->
    { isEditingHeader, decisionAid, editingChaptersOrder } = @props
    return null unless decisionAid?
    chapters = decisionAid.get 'chapters'
    inChapterOrderingMode = editingChaptersOrder?

    <div className='decision-aid-details'>
      <DecisionAid
        data={decisionAid}
        isEditing={isEditingHeader}
        onDelete={@handleDecisionAidDelete}
      />
      <h2>{@i18n 'da:details-screen.chapters'}</h2>
      {if inChapterOrderingMode
        <DraggableChaptersList
          chapters={chapters}
          chaptersOrder={editingChaptersOrder}
        />
      else
        <ChaptersList
          chapters={chapters}
          chaptersOrder={getChaptersOrder(decisionAid)}
          comparison={@props.decisionAid.getIn ['question', 'comparison']}
          decisionAidId={@props.decisionAid.get '_id'}
          expandedChapters={@props.expandedChapters}
          intervention={@props.decisionAid.getIn ['question', 'intervention']}
          onChapterCollapse={@handleChapterCollapse}
          onChapterExpand={@handleChapterExpand}
          outcomes={@props.decisionAid.getIn ['question', 'outcomes'], Immutable.List()}
          questionId={@props.decisionAid.getIn ['question', '_id']}
        />
      }
      {if inChapterOrderingMode
        <div className='chapters-ordering-apply-cancel'>
          <ApplyCancelButtons
            onCancel={DecisionAidsActions.stopEditingChaptersOrder}
            onApply={@saveChaptersOrder}
            applyLabel={@i18n '/actions.save'}
          />
        </div>
      }
      <ChapterEditModal
        daId={@props.decisionAid.get('_id')}
        isNew
        isOpen={@props.editModalVisible}
        onApply={@handleNewChapterCreate}
        onClose={@hideChapterEditModal}
      />
    </div>

module.exports = DecisionAidDetails
