var ACPVisualGuidelines, ACPVisualGuidelinesActions, ACPVisualGuidelinesToolbar, ACPVisualGuidelinesView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

ACPVisualGuidelines = require('components/dissemination/acp_visual_guidelines/visual_guidelines');

ACPVisualGuidelinesToolbar = require('components/dissemination/acp_visual_guidelines/visual_guidelines_toolbar');

ACPVisualGuidelinesActions = require('actions/acp_visual_guidelines_actions');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = ACPVisualGuidelinesView = (function(_super) {
  __extends(ACPVisualGuidelinesView, _super);

  function ACPVisualGuidelinesView() {
    return ACPVisualGuidelinesView.__super__.constructor.apply(this, arguments);
  }

  ACPVisualGuidelinesView.prototype.container = '#page-container';

  ACPVisualGuidelinesView.prototype.className = 'acp-visual-guidelines';

  ACPVisualGuidelinesView.prototype.autoRender = true;

  ACPVisualGuidelinesView.prototype._stopInputsPropagation = false;

  ACPVisualGuidelinesView.prototype.initialize = function() {
    var _ref;
    ACPVisualGuidelinesView.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    return this.subview('toolbar', new ReactToolbarView({
      component: ACPVisualGuidelinesToolbar,
      props: {
        itemId: this.options.itemId,
        submoduleScreen: (_ref = this.options.submoduleScreen) != null ? _ref : 'list'
      }
    }));
  };

  ACPVisualGuidelinesView.prototype.afterRender = function() {
    var docId, projectId, _ref;
    ACPVisualGuidelinesView.__super__.afterRender.apply(this, arguments);
    projectId = mediator.project.id;
    docId = this.options.itemId ? this.options.itemId : null;
    return this.renderComponent(ACPVisualGuidelines, ACPVisualGuidelinesActions, docId, {
      props: {
        projectId: projectId,
        itemId: this.options.itemId,
        submoduleScreen: (_ref = this.options.submoduleScreen) != null ? _ref : 'list'
      },
      fetchAndListen: {
        dbId: projectId
      }
    });
  };

  ACPVisualGuidelinesView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle('ACPVisualGuidelinesStore', 'QuestionsStore', 'QuestionGroupsStore', 'QuestionsListComponentStore', 'QuestionsStatusesStore', 'OrganizationsStore', 'OverarchingQuestionsStore', 'DocumentSectionsV2Store', 'NMAListStore', 'NMAQuestionStore', 'EtdsStore');
    return ACPVisualGuidelinesView.__super__.dispose.apply(this, arguments);
  };

  return ACPVisualGuidelinesView;

})(View);
