AdolopmentDataActions = require 'actions/adolopment_data_actions'
InfoIconWithTooltip = require 'components/common/info_icon_with_tooltip'
Switcher = require 'components/common/switcher'
Translation = require 'components/mixins/translation'
UserProjectDataActions = require 'actions/user_project_data_actions'
{ SectionHeaderWithCounterAndExpand } = ReactComponents

AssessmentSectionHeader = createReactClass
  displayName: "AssessmentSectionHeader"

  propTypes:
    colSpan: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    etdId: PropTypes.string.isRequired,
    index: PropTypes.number,
    info: PropTypes.string,
    isBeingAdoloped: PropTypes.bool
    renderMode: PropTypes.string.isRequired
    sectionId: PropTypes.string.isRequired,
    sectionName: PropTypes.string.isRequired,

  mixins: [Translation('es:recommendations')]

  getDefaultProps: ->
    isBeingAdoloped: false

  toggleExpand: ->
    UserProjectDataActions.toggleAssessmentSection @props.etdId, @props.sectionId

  toggleAdolopmentForSection: ->
    AdolopmentDataActions.toggleAdolopmentForSection @props.etdId, 'assessment', @props.sectionId

  sectionTitle: ->
    { sectionName, info } = @props
    <h2>
      {sectionName}
      {if info
        <InfoIconWithTooltip info={info} />
      }
    </h2>

  render: ->

    { expanded, colSpan, index, renderMode, content, isBeingAdoloped, withAdolopment } = @props
    headerClassess = classNames 'assessment-section__header',
      'assessment-setion__header--expanded': expanded
      'assessment-setion__header--collapsed': not expanded

    <SectionHeaderWithCounterAndExpand
      className={headerClassess}
      colSpan={colSpan}
      expanded={expanded}
      index={index}
      onToggle={@toggleExpand}
      renderMode={renderMode}
      subtitle={content}
      title={@sectionTitle()}
      withIndex={renderMode isnt 'printout'}
      withToggle={renderMode isnt 'printout'}
    >
    <div className="assessment-section__header__flex">
      <div className="assessment-section__header__content" onClick={@toggleExpand}>
        {@sectionTitle()}
        {if renderMode is 'printout'
          <p className="subtitle">
            {content}
          </p>
        else
          <div className="subtitle">
            {content}
          </div>
        }
      </div>
      {isBeingAdoloped and renderMode isnt 'printout' and
        <Switcher
          checked={withAdolopment}
          onChange={@toggleAdolopmentForSection}
          buttonText={@i18n 'table-view-options.toggle_adolopment'}
        />
      }
      </div>
    </SectionHeaderWithCounterAndExpand>


module.exports =  AssessmentSectionHeader
