var DnDActions;

DnDActions = require('actions/react_dnd_actions');

module.exports = {
  dropSpec: {
    drop: function(props, monitor) {
      return DnDActions.drop({
        type: monitor.getItemType()
      });
    }
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver() && monitor.canDrop()
    };
  }
};
