RadioInput = require 'components/common/radio_input'
RadioWithLabel = require 'components/common/radio_with_label'
StringField = require 'components/covid_extraction_form/string_field'
Translation = require 'components/mixins/translation'

CoIField = createReactClass
  displayName: 'CoIField'

  mixins: [Translation('mdg_tables:edit_form')]

  propTypes:
    onChange: PropTypes.func.isRequired
    value: PropTypes.shape(
      selected: PropTypes.bool
      value: PropTypes.string
    ).isRequired

  onTextChange: (value) ->
    { onChange } = @props
    onChange { selected: true, value }

  onRadioClicked: (fieldValue) -> =>
    { onChange, value } = @props
    onChange _.extend {}, value, selected: fieldValue

  render: ->
    { value: { selected, value }} = @props

    <div className="coi-field">
      <div className="coi-field__no-conflict">
        <RadioWithLabel
          checked={selected is false}
          label={@i18n 'values.noConflict'}
          onChange={@onRadioClicked(false)}
        />
      </div>
      <div className="coi-field__conflict">
        <RadioInput
          className="conflict__selected"
          checked={selected is true}
          onChange={@onRadioClicked(true)}
        />
        <StringField
          className="conflict__value"
          onChange={@onTextChange}
          value={value}
        />
      </div>
    </div>

module.exports = CoIField
