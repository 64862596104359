var QUALITY_INDICATOR_TYPE, QualityIndicators, QualityIndicatorsActions, QualityIndicatorsToolbarView, QualityIndicatorsView, ReactComponent, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

QualityIndicators = require('components/quality_indicators/quality_indicators');

QualityIndicatorsActions = require('actions/quality_indicators_actions');

QualityIndicatorsToolbarView = require('views/quality_indicators/toolbar_view');

mediator = require('mediator');

QUALITY_INDICATOR_TYPE = require('lib/db_docs/doc_types').QUALITY_INDICATOR_TYPE;

ReactComponent = require('base/lib/traits/react_component');

View = require('base/views/view');

module.exports = QualityIndicatorsView = (function(_super) {
  __extends(QualityIndicatorsView, _super);

  function QualityIndicatorsView() {
    return QualityIndicatorsView.__super__.constructor.apply(this, arguments);
  }

  QualityIndicatorsView.prototype.container = '#page-container';

  QualityIndicatorsView.prototype.autoRender = true;

  QualityIndicatorsView.prototype._stopInputsPropagation = false;

  QualityIndicatorsView.prototype.initialize = function() {
    QualityIndicatorsView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new QualityIndicatorsToolbarView());
    return this.enable(ReactComponent);
  };

  QualityIndicatorsView.prototype.afterRender = function() {
    var questionId;
    QualityIndicatorsView.__super__.afterRender.apply(this, arguments);
    questionId = this.options.questionId;
    return this.renderSimpleComponent(QualityIndicators);
  };

  QualityIndicatorsView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('QualityIndicatorsStore', 'EtdStore');
    return QualityIndicatorsView.__super__.dispose.apply(this, arguments);
  };

  return QualityIndicatorsView;

})(View);
