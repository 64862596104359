_.namespace(module, function(require) {
  var MdaTableDoc, exportEpiditorContent, processMdgCodes, _ref;
  _ref = require('lib/services/document_sections_exporter/utils'), exportEpiditorContent = _ref.exportEpiditorContent, processMdgCodes = _ref.processMdgCodes;
  MdaTableDoc = require('lib/db_docs/mda_table_doc');
  return {
    exportSection: function(section, chapterPath, sectionParentPaths, chaptersPaths, exportParams) {
      var codes, exportDate, guidelineId, linkPrefix, mdaTableId, projectId, sectionId;
      sectionId = section._id, codes = section.codes, mdaTableId = section.mdaTableId;
      exportDate = exportParams.exportDate, guidelineId = exportParams.guidelineId, linkPrefix = exportParams.linkPrefix, projectId = exportParams.projectId;
      return MdaTableDoc.at(projectId).fetch(mdaTableId).then(function(mdaTable) {
        var additionalSectionData, content, title;
        content = mdaTable.content, title = mdaTable.name;
        additionalSectionData = {
          tableGuid: mdaTableId
        };
        if (!_.isEmpty(codes)) {
          additionalSectionData.codes = processMdgCodes(codes);
        }
        return exportEpiditorContent({
          additionalSectionData: additionalSectionData,
          chapterPath: chapterPath,
          chaptersPaths: chaptersPaths,
          content: content,
          exportDate: exportDate,
          guidelineId: guidelineId,
          linkPrefix: linkPrefix,
          sectionId: sectionId,
          sectionParentPaths: sectionParentPaths,
          title: title,
          type: 'mdaTable'
        });
      });
    }
  };
});
