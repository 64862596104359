var DecisionAidSubmodule, Migration, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = DecisionAidSubmodule = (function(_super) {
  __extends(DecisionAidSubmodule, _super);

  function DecisionAidSubmodule() {
    DecisionAidSubmodule.__super__.constructor.call(this, false);
  }

  DecisionAidSubmodule.prototype.up = function(project, colls) {
    var disseminationModule, submodule;
    DecisionAidSubmodule.__super__.up.apply(this, arguments);
    if (project.get('modules') === '*') {
      return W.resolve();
    }
    submodule = {
      id: 'decision_aids',
      disabled: false
    };
    disseminationModule = _(project.get('modules')).findWhere({
      id: 'dissemination'
    });
    if (disseminationModule != null) {
      disseminationModule.submodules.splice(2, 0, submodule);
    }
    return project.save();
  };

  return DecisionAidSubmodule;

})(Migration);
