var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<span class=\"question-mark-sign\"\n  title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "source_of_data_info", {"name":"i18n","hash":{},"data":data})))
    + "\"></span>\n<span class=\"title\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "source_of_data", {"name":"i18n","hash":{},"data":data})))
    + ":</span>\n<label>\n  <input type=\"radio\" name=\"sourceOfDxData\" value=\"fromSingleStudy\">\n  <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "from_single_study", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n</label>\n<label>\n  <input type=\"radio\" name=\"sourceOfDxData\" value=\"pooledAcrossStudies\">\n  <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "pooled_across_studies", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n</label>\n<label>\n  <input type=\"radio\" name=\"sourceOfDxData\" value=\"rangeFromStudies\">\n  <span>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "range_from_studies", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n</label>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome.diagnostic", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}