var ContentState, Modifier, SelectionState, alt, convertToRaw, createContentBlock, getEditorState, _ref,
  __slice = [].slice;

convertToRaw = Draft.convertToRaw, ContentState = Draft.ContentState, SelectionState = Draft.SelectionState, Modifier = Draft.Modifier;

_ref = Editor.EditorUtils, getEditorState = _ref.getEditorState, createContentBlock = _ref.createContentBlock;

alt = require('alt');

module.exports = {
  QUALITY_INDICATOR_TYPES: ["health_status_indicator", "process_indicator", "structure_indicator"],
  prepareContentForMonitoringAndEvaluation: function(content, requirements) {
    var currentContent, editorState, qualityIndicatorsBlock, requirementsBlock, requirementsDataBlock;
    editorState = getEditorState(content);
    currentContent = editorState.getCurrentContent();
    qualityIndicatorsBlock = createContentBlock({
      text: $.t('quality_indicators:table.quality_assurance_and_improvement'),
      inlineStyles: ['BOLD']
    });
    if (!_.isEmpty(requirements)) {
      requirementsBlock = createContentBlock({
        text: "" + ($.t('quality_indicators:table.requirements')) + ":",
        inlineStyles: ['BOLD']
      });
      requirementsDataBlock = createContentBlock({
        text: requirements
      });
      return convertToRaw(ContentState.createFromBlockArray([qualityIndicatorsBlock].concat(__slice.call(currentContent.getBlockMap().set(requirementsBlock.key, requirementsBlock).set(requirementsDataBlock.key, requirementsDataBlock).toArray()))));
    } else {
      return convertToRaw(ContentState.createFromBlockArray([qualityIndicatorsBlock].concat(__slice.call(currentContent.getBlockMap().toArray()))));
    }
  }
};
