var DnDActions;

DnDActions = require('actions/react_dnd_actions');

module.exports = {
  dropSpec: {
    canDrop: function(props, monitor) {
      return monitor.getItem().gIndex === props.gIndex;
    },
    drop: function(props, monitor) {
      var dragKey, gIndex, importance;
      gIndex = props.gIndex, importance = props.importance;
      dragKey = monitor.getItem().key;
      return DnDActions.dropAreaDrop({
        type: monitor.getItemType(),
        importance: importance,
        gIndex: gIndex,
        dragKey: dragKey
      });
    }
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget(),
      isTargetHovered: monitor.isOver() && monitor.canDrop()
    };
  }
};
