ConnectStore = require 'components/enhancers/connect_store'
DataSelection = require 'components/global_export/data_selection'
ExportError = require 'components/global_export/export_error'
ExportProgress = require 'components/global_export/export_progress'
GlobalExportActions = require 'actions/global_export_actions'
GlobalExportStore = require 'stores/global_export_store'
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'

SCREENS =
  select_data: DataSelection
  export_progress: ExportProgress
  export_error: ExportError

storeConnectors =
  GlobalExportStore: (Store) ->
    isExportError: Store.isExportError()
    isFetchError: Store.isFetchError()
    isExporting: Store.isExporting()
    isOpen: Store.isOpen()
    screen: Store.getCurrentScreen()

GlobalExportModal = createReactClass
  displayName: 'GlobalExportModal'
  mixins: [Translation('projects:global_export_dialog')]

  propTypes:
    isExportError: PropTypes.bool.isRequired
    isFetchError: PropTypes.bool.isRequired
    isExporting: PropTypes.bool.isRequired
    isOpen: PropTypes.bool.isRequired
    screen: PropTypes.oneOf(_.keys(SCREENS)).isRequired

  _getTitle: ->
    { isExportError, isFetchError } = @props
    return null if isExportError or isFetchError
    @i18n 'title'

  render: ->
    { isExportError, isFetchError, isExporting, isOpen, screen } = @props
    Component = SCREENS[screen]

    <Modal
      isOpen={isOpen}
      title={@_getTitle()}
      closeButton={not isExporting}
      onClose={GlobalExportActions.closeModal}
      className={classNames 'global-export-modal', 'global-export-modal-error': isExportError}
      modalSize="medium"
    >
      {not isExportError and not isFetchError and <p>{@i18n 'description'}</p>}
      <Component />
    </Modal>

module.exports = ConnectStore GlobalExportModal, [GlobalExportStore], storeConnectors
