Outcome = require 'components/scope/outcomes/outcome'
EditableOutcome = require 'components/scope/outcomes/outcome_editable'
CommentButton = require 'components/common/empty_comment_button'
CommentsBlock = require 'components/scope/comments_block'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
CommentsMixin = require 'components/mixins/outcomes_comments_mixin'
Translation = require 'components/mixins/translation'

OutcomeWithComments = createReactClass
  displayName: 'OutcomeWithComments'
  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
    CommentsMixin
  ]
  propTypes:
    editable: PropTypes.bool.isRequired

  getDefaultProps: ->
    className: 'outcome'

  _getToggleButton: ->
    buttonClass = classNames 'comment-blank-hide': @state.showingComments,
      'comment-blank': not @state.showingComments
    <CommentButton className={buttonClass}
      title={@i18n 'view_members_comments'} onClick={@toggleComments} />

  _getChildComponent: ->
    childClass = @props.className unless @state.showingComments

    if @props.editable
      <EditableOutcome {...@props} className={childClass}>
        {@_getToggleButton()}
      </EditableOutcome>
    else
      <Outcome {...@props} className={childClass}>
        {@_getToggleButton()}
      </Outcome>

  render: ->
    if @state.showingComments
      adminComment = @props.outcomeData.getIn ['adminComments', 'brainstorming']
      membersComments = @props.outcomeData.get('memberComments').flatten()

      <div className={@props.className}>
        <div className='outcome-child'>
          {@_getChildComponent()}
        </div>
        <div className='comments-container'>
          <CommentsBlock
            adminComment={adminComment}
            membersComments={membersComments}
            membersMap={@props.membersMap}
            readOnlyComments={@props.readOnlyComments}
            onApplyAdminComment={@onApplyAdminComment('brainstorming')}
          />
        </div>
      </div>
    else
      @_getChildComponent()

module.exports = OutcomeWithComments
