Translation = require 'components/mixins/translation'
MultiComparisonsActions = require 'actions/multi_comparisons_actions'
Rating = require 'components/common/rating'
InterventionComment = require './intervention_comment'
SectionNameCell = require './section_name_cell'
ReviewTableCell = require './review_table_cell'
ReviewTableSortableHeaderCell = require './review_table_sortable_header_cell'

InterventionsGradingTable = createReactClass
  displayName: 'InterventionsGradingTable'

  mixins: [ Translation('mc:review_tables') ]

  propTypes:
    selectedInterventions: PropTypes.instanceOf(Immutable.List).isRequired
    sectionsImportanceForDecision: PropTypes.instanceOf(Immutable.Map).isRequired
    assessmentSections: PropTypes.arrayOf(PropTypes.string).isRequired
    sectionGradingComments: PropTypes.instanceOf(Immutable.Map).isRequired
    interventionsRating: PropTypes.instanceOf(Immutable.Map).isRequired
    hiddenSections: PropTypes.instanceOf(Immutable.Map).isRequired
    hiddenInterventions: PropTypes.instanceOf(Immutable.Map).isRequired

  handleInterventionRating: (section, intervention) -> (newRating) =>
    MultiComparisonsActions.updateInterventionRating { section, intervention, rating: newRating }

  moveIntervention: (intervention, direction) -> ->
    MultiComparisonsActions.moveSelectedIntervention { intervention, direction }

  toggleIntervention: (intervention) -> ->
    MultiComparisonsActions.toggleInterventionColumn { intervention }

  render: ->
    { selectedInterventions,
      sectionsImportanceForDecision,
      assessmentSections,
      sectionGradingComments,
      interventionsRating,
      hiddenSections,
      hiddenInterventions
    } = @props

    # this is percentage size
    sectionNameColumnWidth = 10
    importanceForDecisionColumnWidth = 10
    commentColumnWidth = 10

    hiddenColumnsCount = hiddenInterventions.reduce (acc, hidden) ->
      if hidden then acc = acc + 1
      acc
    , 0

    sortableHiddenColumnWidth = 3
    sortableVisibleColumnWidth =
      (100 -
        (sectionNameColumnWidth + importanceForDecisionColumnWidth + commentColumnWidth)
      ) / (selectedInterventions.size - hiddenColumnsCount)

    <table className="standard-table multi_comparisons__review-table">
      <colgroup>
        <col style={{width: "#{sectionNameColumnWidth}%"}}/>
        {selectedInterventions.map (i) ->
          hidden = hiddenInterventions.get i
          width = if hidden
            "#{sortableHiddenColumnWidth}%"
          else
            "#{sortableVisibleColumnWidth}%"

          <col key={i} style={{ width }} />
        }
        <col style={{width: "#{importanceForDecisionColumnWidth}%"}}/>
        <col style={{width: "#{commentColumnWidth}%"}}/>
      </colgroup>
      <thead>
        <tr>
          <th className="review-table__header-cell review-table__header-cell--blank" />
          {selectedInterventions.map (intervention, idx) =>
            hidden = hiddenInterventions.get intervention, false
            <ReviewTableSortableHeaderCell
              key={intervention}
              title={intervention}
              hidden={hidden}
              columnIdx={idx}
              allColumnsCount={selectedInterventions.size}
              moveColumnLeft={@moveIntervention intervention, -1}
              moveColumnRight={@moveIntervention intervention, 1}
              toggleColumn={@toggleIntervention intervention}
            />
          }
          <th className="review-table__header-cell review-table__header--importance">
            {@i18n 'importance_for_decision'}
          </th>
          <th className="review-table__header-cell">{@i18n 'comment'}</th>
        </tr>
      </thead>
      <tbody>
        {assessmentSections.map (section) =>
          importance = sectionsImportanceForDecision.get section

          <tr key={section}>
            <SectionNameCell
              section={section}
              tableName="grading"
              hidden={hiddenSections.get(section, false)}
            />
            {selectedInterventions.map (intervention) =>
              hiddenCell =
                hiddenSections.get(section, false) or hiddenInterventions.get(intervention, false)

              <ReviewTableCell
                key={intervention}
                className={classNames 'review-table__rating', section}
                hidden={hiddenCell}
              >
                <Rating
                  onRate={@handleInterventionRating(section, intervention)}
                  rating={interventionsRating.getIn [section, intervention]}
                />
              </ReviewTableCell>
            }
            <ReviewTableCell
              className={classNames 'review-table__cell--importance',
                'review-table__cell--grading-importance'
                "review-table__cell--#{importance}-importance": importance
              }
              hidden={hiddenSections.get(section, false)}
            >
              {importance and @i18n "importance.#{importance}"}
            </ReviewTableCell>
            <ReviewTableCell
              className="review-table__cell-comment"
              hidden={hiddenSections.get(section, false)}
            >
              <InterventionComment
                tooltipProps={{
                  section,
                  sectionComment: @props.sectionGradingComments.get(section)
                }}
                sectionComment={@props.sectionGradingComments.get(section)}
                i18n={@i18n}
              />
            </ReviewTableCell>
          </tr>
        }
      </tbody>
    </table>

module.exports = InterventionsGradingTable
