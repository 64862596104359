var ModalView, NewReferenceView, Reference, W, authorStringToObject, authorsToString, createReferenceDoc, mediator, removeLineBreaks, template, _ref,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

Reference = require('models/references/reference');

mediator = require('mediator');

template = require('views/templates/evidence_syntheses/new_reference');

_ref = require('lib/references_utils'), authorsToString = _ref.authorsToString, authorStringToObject = _ref.authorStringToObject, createReferenceDoc = _ref.createReferenceDoc;

removeLineBreaks = require('base/lib/utils').removeLineBreaks;

W = require('when');

module.exports = NewReferenceView = (function(_super) {
  __extends(NewReferenceView, _super);

  function NewReferenceView() {
    return NewReferenceView.__super__.constructor.apply(this, arguments);
  }

  NewReferenceView.prototype.template = template;

  NewReferenceView.prototype.className = 'new-reference-modal';

  NewReferenceView.prototype.bindings = {
    '[name="authors"]': {
      observe: 'authors',
      onGet: function(val) {
        return authorsToString(val);
      },
      onSet: function(val) {
        return _.map(removeLineBreaks(val).trim().split(/; ?/), authorStringToObject);
      }
    },
    '[name="title"]': {
      observe: 'title',
      onSet: removeLineBreaks
    },
    '[name="year"]': {
      observe: 'year',
      onSet: removeLineBreaks
    },
    '[name="publishedIn"]': {
      observe: 'publishedIn',
      onSet: removeLineBreaks
    },
    '[name="volume"]': {
      observe: 'volume',
      onSet: removeLineBreaks
    },
    '[name="issue"]': {
      observe: 'issue',
      onSet: removeLineBreaks
    },
    '[name="pages"]': {
      observe: 'pages',
      onSet: removeLineBreaks
    },
    '[name="abstract"]': {
      observe: 'abstract',
      onSet: removeLineBreaks
    },
    '[name="tags"]': {
      observe: 'tags',
      onGet: function(val) {
        return val.join(', ');
      },
      onSet: function(val) {
        return removeLineBreaks(val).trim().split(/, ?/);
      }
    },
    '[name="authorKeywords"]': {
      observe: 'authorKeywords',
      onGet: function(val) {
        return val.join(', ');
      },
      onSet: function(val) {
        return removeLineBreaks(val).trim().split(/, ?/);
      }
    },
    '[name="DOI"]': {
      observe: 'DOI',
      onSet: removeLineBreaks
    },
    '[name="PMID"]': {
      observe: 'PMID',
      onSet: removeLineBreaks
    },
    '[name="source"]': {
      observe: 'source',
      onSet: removeLineBreaks
    },
    '[name="researchResults"]': {
      observe: 'researchResults'
    }
  };

  NewReferenceView.prototype.initialize = function() {
    this.options.cls = 'new-reference-modal-container';
    this.options.width = '45vw';
    this.options.position = 'top';
    this.options.offsetY = 20;
    this.options.closeBtn = true;
    this.model = new Reference;
    this.delegate('click', '.btn-cancel', this.dispose);
    this.delegate('click', '.btn-save', this.saveAndClose);
    this._deferred = W.defer();
    this.delegate('keydown', 'textarea', this.preventNewTextareaNewline);
    return NewReferenceView.__super__.initialize.apply(this, arguments);
  };

  NewReferenceView.prototype.preventNewTextareaNewline = function(e) {
    if (e.keyCode === 13) {
      return e.preventDefault();
    }
  };

  NewReferenceView.prototype.promise = function() {
    return this._deferred.promise;
  };

  NewReferenceView.prototype.afterRender = function() {
    NewReferenceView.__super__.afterRender.apply(this, arguments);
    this.stickit();
    return autosize(this.$('textarea'));
  };

  NewReferenceView.prototype.saveAndClose = function() {
    var referenceDoc;
    referenceDoc = createReferenceDoc(this.model.toJSON());
    mediator.colls.references.create(referenceDoc, {
      wait: true
    });
    return this.dispose();
  };

  NewReferenceView.prototype.dispose = function() {
    this.unstickit(this.model);
    this._deferred.resolve();
    return NewReferenceView.__super__.dispose.apply(this, arguments);
  };

  return NewReferenceView;

})(ModalView);
