var DEFAULT_MARGINS, DEFAULT_ORIENTATION, FileSaver, OUTPUT_TYPE_TO_EXTENSION, W, errorHandler, exporter, getFullFileName, mediator, utils;

mediator = require('mediator');

FileSaver = require('lib/file_saver');

errorHandler = require('lib/app_utils').errorHandler;

utils = require('base/lib/utils');

W = require('when');

OUTPUT_TYPE_TO_EXTENSION = {
  html: '.html',
  doc: '.docx',
  pdf: '.pdf',
  rm5: '.rm5',
  jsonld: '.json'
};

DEFAULT_MARGINS = {
  top: 12.7,
  right: 12.7,
  bottom: 12.7,
  left: 12.7
};

DEFAULT_ORIENTATION = 'landscape';

getFullFileName = function(fileName, outputType) {
  if (fileName == null) {
    fileName = 'download';
  }
  return "" + fileName + OUTPUT_TYPE_TO_EXTENSION[outputType];
};

module.exports = exporter = {
  defaultMargins: DEFAULT_MARGINS,
  defaultOrientation: DEFAULT_ORIENTATION,
  getHtmlBlob: function(content) {
    return W.resolve(new Blob([content], {
      type: 'text/html;charset=utf-8'
    }));
  },
  getDocxBlob: function(content, orientation, margins) {
    return W.resolve(window.htmlDocx.asBlob(content, {
      orientation: orientation,
      margins: margins
    }));
  },
  getJsonBlob: function(content) {
    return W.resolve(new Blob([JSON.stringify(content, null, 4)], {
      type: 'application/json;charset=utf-8'
    }));
  },
  exportToFile: function(outputType, data, options) {
    var fileBlobPromise, fileName, margins, orientation, _ref, _ref1;
    if (options == null) {
      options = {};
    }
    fileName = getFullFileName(options.fileName, outputType);
    margins = (_ref = options.margins) != null ? _ref : DEFAULT_MARGINS;
    orientation = (_ref1 = options.orientation) != null ? _ref1 : DEFAULT_ORIENTATION;
    fileBlobPromise = (function() {
      switch (outputType) {
        case 'html':
          return exporter.getHtmlBlob(data);
        case 'doc':
          margins = _.object(_(margins).map(function(v, k) {
            return [k, utils.millimetersToTwips(v)];
          }));
          return exporter.getDocxBlob(data, orientation, margins);
        case 'pdf':
          if (mediator.services.replication.isConnected()) {
            return mediator.services.pdfExport["export"](data, {
              orientation: orientation,
              margins: margins
            }, options.pdfTemplate, options.pdfTemplateData).then(function(_arg) {
              var blob, destroyRequest;
              blob = _arg[0], destroyRequest = _arg[1];
              destroyRequest();
              return blob;
            });
          } else {
            return W.reject($.t('projects:export_dialog.types.pdf_offline'));
          }
          break;
        case 'jsonld':
          return exporter.getJsonBlob(data);
      }
    })();
    return fileBlobPromise.then(function(blob) {
      return FileSaver.saveFile(blob, fileName);
    })["catch"](function(reason) {
      if (reason !== 'cancelled') {
        return errorHandler(reason);
      }
    });
  }
};
