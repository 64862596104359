EtdTemplates = require 'components/administration/etd_templates'
DocSectionsTemplates = require 'components/administration/doc_sections_templates'
EtdTemplatesStore = require 'stores/etd_templates_store'
DocSectionsTemplatesStore = require 'stores/doc_sections_templates_store'
EtdTemplatesActions = require 'actions/etd_templates_actions'
ConnectStore = require 'components/enhancers/connect_store'
mediator = require 'mediator'

storeConnectors =
  EtdTemplatesStore: (Store) ->
    dxTemplate: Store.getTemplate('dx')
    dxTemplateName: Store.getTemplateName('dx')
    etdEditingTemplate: Store.getEditingTemplate()
    etdTemplateName: Store.getTemplateName()
    isFetching: Store.isFetching()
    keyMessageTemplate: Store.getTemplate('keyMessage')
    keyMessageTemplateName: Store.getTemplateName('keyMessage')
    overarchingDxTemplate: Store.getTemplate('overarchingDx')
    overarchingDxTemplateName: Store.getTemplateName('overarchingDx')
    overarchingTxTemplate: Store.getTemplate('overarchingTx')
    overarchingTxTemplateName: Store.getTemplateName('overarchingTx')
    template: Store.getTemplate()
    templateChanged: Store.isTemplateChanged()
    txTemplate: Store.getTemplate('tx')
    txTemplateName: Store.getTemplateName('tx')
  DocSectionsTemplatesStore: (Store) ->
    docSectionsEditingTemplate: Store.getEditingTemplate()
    docSectionsTemplate: Store.getTemplate()
    docSectionsTemplateChanged: Store.isTemplateChanged()
    docSectionsTemplateName: Store.getTemplateName()

TemplatesSettings = createReactClass
  displayName: 'TemplatesSettings'

  propTypes:
    docSectionsEditingTemplate: PropTypes.string
    docSectionsTemplate: PropTypes.instanceOf(Immutable.Map)
    docSectionsTemplateChanged: PropTypes.bool
    docSectionsTemplateName: PropTypes.string
    dxTemplate: PropTypes.instanceOf(Immutable.Map)
    dxTemplateName: PropTypes.string
    etdEditingTemplate: PropTypes.string
    etdTemplateName: PropTypes.string
    isFetching: PropTypes.bool
    keyMessageTemplate: PropTypes.instanceOf(Immutable.Map)
    keyMessageTemplateName: PropTypes.string
    template: PropTypes.instanceOf(Immutable.Map)
    templateChanged: PropTypes.bool
    txTemplate: PropTypes.instanceOf(Immutable.Map)
    txTemplateName: PropTypes.string

  render: ->
    {
      docSectionsEditingTemplate
      docSectionsTemplate
      docSectionsTemplateChanged
      docSectionsTemplateName
      dxTemplate
      dxTemplateName
      etdEditingTemplate
      etdTemplateName
      isFetching
      keyMessageTemplate
      keyMessageTemplateName
      overarchingDxTemplate
      overarchingDxTemplateName
      overarchingTxTemplate
      overarchingTxTemplateName
      template
      templateChanged
      txTemplate
      txTemplateName
    } = @props

    <div>
      <EtdTemplates
        dxTemplate={dxTemplate}
        dxTemplateName={dxTemplateName}
        editingTemplate={etdEditingTemplate}
        isFetching={isFetching}
        keyMessageTemplate={keyMessageTemplate}
        keyMessageTemplateName={keyMessageTemplateName}
        overarchingDxTemplate={overarchingDxTemplate}
        overarchingDxTemplateName={overarchingDxTemplateName}
        overarchingTxTemplate={overarchingTxTemplate}
        overarchingTxTemplateName={overarchingTxTemplateName}
        template={template}
        templateChanged={templateChanged}
        templateName={etdTemplateName}
        txTemplate={txTemplate}
        txTemplateName={txTemplateName}
      />
      {if mediator.projectDocumentSectionsVersion is 'templateBased'
        <DocSectionsTemplates
          editingTemplate={docSectionsEditingTemplate}
          template={docSectionsTemplate}
          templateChanged={docSectionsTemplateChanged}
          templateName={docSectionsTemplateName}
        />
      }
    </div>

module.exports =
  ConnectStore TemplatesSettings, [EtdTemplatesStore, DocSectionsTemplatesStore], storeConnectors
