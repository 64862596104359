Etd = require 'components/etd/etd'

Recommendations = ({ questionId }) ->

  <div id="recommendations">
    <Etd type="mdgRecommendation" questionId={questionId} />
  </div>

Recommendations.propTypes = {
  questionId: PropTypes.string.isRequired
}

module.exports = Recommendations
