var EnsureOutcomeHaveSetIdField, Migration, QUESTIONS_DB_VIEW, W, mediator, migrationName, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

QUESTIONS_DB_VIEW = require('lib/questions_helper').QUESTIONS_DB_VIEW;

utils = require('base/lib/utils');

migrationName = 'ensure_outcomes_have_set_id_field';

module.exports = EnsureOutcomeHaveSetIdField = (function(_super) {
  __extends(EnsureOutcomeHaveSetIdField, _super);

  function EnsureOutcomeHaveSetIdField() {
    EnsureOutcomeHaveSetIdField.__super__.constructor.call(this, false);
  }

  EnsureOutcomeHaveSetIdField.prototype.up = function(project, colls) {
    var adapter, projectId;
    EnsureOutcomeHaveSetIdField.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return adapter.fetchWithView(projectId, 'questions', QUESTIONS_DB_VIEW).then(function(questions) {
      return _.map(questions, function(question) {
        var _ref;
        if ((_ref = question.outcomes) != null ? _ref.length : void 0) {
          question.outcomes = _.map(question.outcomes, function(outcome) {
            if (!outcome._id) {
              outcome._id = utils.generateGUID();
            }
            return outcome;
          });
        }
        return question;
      });
    }).then(function(questions) {
      return adapter.bulkDocs(projectId, questions);
    });
  };

  return EnsureOutcomeHaveSetIdField;

})(Migration);
