var BoxView, HelpBoxView, Scrolling, W, mediator, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

BoxView = require('base/views/box_view');

template = require('views/templates/help_box');

W = require('when/when');

mediator = require('mediator');

Scrolling = require('base/lib/traits/scrolling');

module.exports = HelpBoxView = (function(_super) {
  __extends(HelpBoxView, _super);

  function HelpBoxView() {
    return HelpBoxView.__super__.constructor.apply(this, arguments);
  }

  HelpBoxView.prototype.template = template;

  HelpBoxView.prototype.className = 'help-box';

  HelpBoxView.prototype.initialize = function() {
    HelpBoxView.__super__.initialize.apply(this, arguments);
    return this.delegate('click', '.close', this.hide);
  };

  HelpBoxView.prototype.isPopupView = function() {
    return true;
  };

  HelpBoxView.prototype.afterRender = function() {
    var helpResourcePath;
    this.enable(Scrolling, {
      adjustTo: '.box.help-box-container',
      content: '.help-content'
    });
    helpResourcePath = mediator.helpResources.resourceFor(this.options.property);
    if (!helpResourcePath) {
      mediator.dialogs.warning($.t('help.no_help_for_property', {
        property: this.options.property
      }));
      this.hide();
      return;
    }
    return W($.get(helpResourcePath), (function(_this) {
      return function(response) {
        _this.$('.help-content').html(response);
        return _this.trigger('!fixLayout');
      };
    })(this), function(xhr) {
      return mediator.dialogs.fatalError(xhr.statusText);
    });
  };

  HelpBoxView.prototype.show = function(offset) {
    var windowHeight;
    windowHeight = $(window).height();
    offset.top = Math.min(offset.top, Math.min(windowHeight / 2, windowHeight - this.$el.height()));
    return HelpBoxView.__super__.show.apply(this, arguments);
  };

  return HelpBoxView;

})(BoxView);
