SpinnerButton = require 'components/common/spinner_button'
InfoModal = require 'components/common/info_message_modal'
QuestionGroupApproval = require 'components/scope/outcomes/question_group_for_approval'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
VotingDXQuestionsMixin = require 'components/mixins/voting_dx_questions_mixin'
OutcomeShowCommentsMixin = require 'components/mixins/outcomes_show_comments_mixin'
ScopeDataFetchMixin = require 'components/mixins/scope_data_fetch_mixin'
mediator = require 'mediator'
OutcomesGenerationService = require 'lib/services/outcomes_generation_service'
approvalStep = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS[5]
{ bool, func, object } = PropTypes

{ Button } = ReactComponents

ApprovalsTable = createReactClass
  displayName: 'ApprovalsTable'

  propTypes:
    membersSubmitted: object
    memberAgreements: object
    memberAgreementComments: object

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  _memberRow: (memberId, idx) ->
    agreed = @props.memberAgreements.get memberId
    className = classNames
      agreed: agreed
      disagreed: not agreed
    <tr className={className} key={idx}>
      <td>{@props.membersMap.get memberId}</td>
      <td>{@props.memberAgreementComments.get(memberId)}</td>
    </tr>

  render: ->
    <table className='approval-table-legacy'>
      <thead>
        <tr>
          <th className="name-column">{@i18n '../questions.approval.member'}</th>
          <th className="comment-column">{@i18n '../questions.approval.comment'}</th>
        </tr>
      </thead>
      <tbody>
        {@props.membersSubmitted.map(@_memberRow).toList()}
      </tbody>
    </table>

ApprovalTab = createReactClass
  displayName: 'ApprovalTab'

  propTypes:
    members: object.isRequired
    membersSentTo: object.isRequired
    membersSubmitted: object
    memberAgreements: object
    memberAgreementComments: object
    readOnly: bool.isRequired
    fetchResults: func
    closeStep: func

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
    VotingDXQuestionsMixin
    ScopeDataFetchMixin
    OutcomeShowCommentsMixin(approvalStep)
  ]

  getInitialState: ->
    showDXNotification: false

  _hasMemberVoted: (memberId) ->
    @props.membersSubmitted.includes memberId

  _getMemberName: (memberId) ->
    member = @props.members.get memberId
    memberName = "#{member.get 'givenNames'} #{member.get 'lastName'}"

  componentDidMount: ->
    # during first render sentTo will be empty, since this prop is obtained from API, need to fetch
    if @props.membersSentTo.isEmpty()
      @props.fetchResults()

  closeStep: ->
    questions = @getAllQuestions()
    dxQuestions = @getDXQuestions questions
    # show notification if some (but not all) questions are of DX type
    if dxQuestions.size and dxQuestions.size isnt questions.size
      @setState showDXNotification: true
    # if there is no DX question or all questions are of DX type - move outcomes
    else
      @_moveOutcomes()

  _moveOutcomes: ->
    @setState showDXNotification: false
    @props.moveOutcomes @props.questionGroups

  _getCloseButtonText: ->
    questions = @getAllQuestions()
    dxQuestions = @getDXQuestions questions
    if dxQuestions.size and dxQuestions.size is questions.size
      @i18n 'close_approval_dx'
    else
      @i18n 'close_approval'

  renderQuestionGroup: (qGroup, idx) ->
    <QuestionGroupApproval key={idx}
      data={qGroup}
      gIndex={idx}
      membersMap={@props.membersMap}
      todos={@props.todos}
      readOnly={@props.readOnly}
      onCommentToggle={@onItemCommentToggle}
      showingComments={@state.showingAllComments}
    />

  _inLegacyMode: ->
    @props.memberAgreements? and not @props.memberAgreements.isEmpty()

  render: ->
    submittedCount = @props.membersSubmitted.size
    sentToCount = @props.membersSentTo.size
    inLegacyMode = @_inLegacyMode()

    <div className="approval-tab">
      <div className='information'>
        <div>{@i18n 'approval_section_text'}</div>
      </div>
      <div className='top-buttons'>
        <span>{@i18n 'responded'} {submittedCount} {@i18n 'of'} {sentToCount}</span>
        <Button className='btn-refresh'
          onClick={@props.fetchResults}
          disabled={@_isFetchDisabled()}>
          {@i18n '../questions.approval.refresh'}
        </Button>
        {unless inLegacyMode
          <Button
            onClick={@showComments}
            disabled={@isShowCommentsDisabled()}
          >
            {@i18n '../questions.show_all_comments'}
          </Button>
        }
      </div>
      {if inLegacyMode
        <ApprovalsTable
          membersMap={@props.membersMap}
          membersSubmitted={@props.membersSubmitted}
          memberAgreements={@props.memberAgreements}
          memberAgreementComments={@props.memberAgreementComments}
        />
      else
        <div className='questions-container'>
          {@props.questionGroups.map @renderQuestionGroup}
        </div>
      }
      <div className='buttons'>
        <SpinnerButton
          className='btn close-step'
          onClick={@closeStep}
          disabled={@props.readOnly}
          label={@_getCloseButtonText()}
          loading={@props.isMovingOutcomes}
        />
      </div>
      <InfoModal
        isOpen={@state.showDXNotification}
        onClose={@_moveOutcomes}
        text={@i18n 'diagnostic_outcomes_info'}
      />
    </div>

module.exports = ApprovalTab
