var EtdHeaderUpdateLabels, EtdStore, EtdTemplateHelper, EtdTemplateStore, EtdTemplatesActions, Migration, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

EtdTemplatesActions = require('actions/etd_templates_actions');

EtdTemplateHelper = require('stores/utils/etd_template_helper');

EtdTemplateStore = require('stores/etd_templates_store');

EtdStore = require('stores/etd_store');

module.exports = EtdHeaderUpdateLabels = (function(_super) {
  __extends(EtdHeaderUpdateLabels, _super);

  function EtdHeaderUpdateLabels() {
    return EtdHeaderUpdateLabels.__super__.constructor.apply(this, arguments);
  }

  EtdHeaderUpdateLabels.prototype.construct = function() {
    return EtdHeaderUpdateLabels.__super__.construct.call(this, false);
  };

  EtdHeaderUpdateLabels.prototype.up = function(project, colls) {
    var changeQuestionSectionDataFields, question, questionPath, recIds;
    EtdHeaderUpdateLabels.__super__.up.apply(this, arguments);
    changeQuestionSectionDataFields = function(path, state) {
      var gDataSections;
      gDataSections = state.getIn(path);
      gDataSections = gDataSections.updateIn(['role', 'name'], function() {
        return "Role of the test";
      });
      gDataSections = gDataSections.updateIn(['purpose', 'name'], function() {
        return "Purpose of the test";
      });
      return state.setIn(path, gDataSections);
    };
    W(EtdTemplateStore.fetch(project.id, ['etd-tx-template', 'etd-dx-template'])).then(function() {
      var state, templatePath;
      state = EtdTemplateStore.getState();
      if (!state.get('dxTemplate')) {
        return W.resolve();
      }
      templatePath = ['dxTemplate', 'templateData', 'question', 'sections'];
      state = changeQuestionSectionDataFields(templatePath, state);
      return EtdTemplateStore.save(project.id, state.get('dxTemplate').toJS());
    }).otherwise(function(err) {
      console.log(err);
      throw err;
    });
    return W.all((function() {
      var _i, _len, _ref, _results;
      _ref = colls.questions.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        question = _ref[_i];
        if (!(question.isDiagnostic())) {
          continue;
        }
        if (!question.get('recommendationIds')) {
          continue;
        }
        questionPath = ['template', 'templateData', 'question', 'sections'];
        recIds = question.get('recommendationIds');
        _results.push(W(EtdStore.fetch(project.id, recIds)).then(function() {
          var state;
          state = EtdStore.getState();
          if (state.getIn(['_meta', 'templateUndefined']) === true) {
            return W.resolve();
          }
          state = changeQuestionSectionDataFields(questionPath, state);
          return EtdStore.save(project.id, state.get('template').toJS());
        }).otherwise(function(err) {
          console.log(err);
          throw err;
        }));
      }
      return _results;
    })());
  };

  return EtdHeaderUpdateLabels;

})(Migration);
