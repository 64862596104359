var InsertView, ModalView, W, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

template = require('views/templates/insert');

W = require('when');

module.exports = InsertView = (function(_super) {
  __extends(InsertView, _super);

  function InsertView() {
    return InsertView.__super__.constructor.apply(this, arguments);
  }

  InsertView.prototype.template = template;

  InsertView.prototype.title = $.t('es:recommendations.insert_modal.title');

  InsertView.prototype.initialize = function() {
    this.selectedOption = null;
    this.file = null;
    this._deferred = W.defer();
    this.delegate('click', 'button.cancel', this.dispose);
    this.delegate('click', 'button[type=submit]', this.sendData);
    this.delegate('click', 'button.file', function() {
      return this.$('#file').click();
    });
    this.delegate('change', 'input[name=insert-object]', this.handleSelection);
    this.delegate('change', '#file', this._fileSelected);
    return InsertView.__super__.initialize.apply(this, arguments);
  };

  InsertView.prototype.getTemplateData = function() {
    return {
      isDiagnostic: this.options.isDiagnostic
    };
  };

  InsertView.prototype.promise = function() {
    return this._deferred.promise;
  };

  InsertView.prototype.handleSelection = function(e) {
    this.selectedOption = e.target.value;
    if (this.selectedOption !== 'picture') {
      return this._enableButton('[type=submit]');
    } else {
      this._enableButton('.file');
      return this._disableButton('[type=submit]');
    }
  };

  InsertView.prototype._enableButton = function(selector) {
    return this.$("button" + selector).removeAttr('disabled');
  };

  InsertView.prototype._disableButton = function(selector) {
    return this.$("button" + selector).attr('disabled', true);
  };

  InsertView.prototype._fileSelected = function(e) {
    var fileExt, _ref;
    this.file = e.target.files[0];
    fileExt = this.file.name.split('.').pop();
    if (!((_ref = fileExt.toLowerCase()) === 'jpg' || _ref === 'png' || _ref === 'jpeg')) {
      this.$('.error').removeClass('hidden');
      return this.file = null;
    } else {
      if (!this.$('.error').hasClass('hidden')) {
        this.$('.error').addClass('hidden');
      }
      return this._enableButton('[type=submit]');
    }
  };

  InsertView.prototype.sendData = function() {
    return this._deferred.resolve({
      selectedOption: this.selectedOption,
      file: this.file
    }).then(this.dispose);
  };

  return InsertView;

})(ModalView);
