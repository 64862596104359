var ChangeTooltipOfFeasibilitySection, Migration, W, mediator, migrationName, templatesSections,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

templatesSections = require('lib/etd_templates/templates_sections');

migrationName = 'change_tooltip_of_feasibility_section';

module.exports = ChangeTooltipOfFeasibilitySection = (function(_super) {
  __extends(ChangeTooltipOfFeasibilitySection, _super);

  function ChangeTooltipOfFeasibilitySection() {
    ChangeTooltipOfFeasibilitySection.__super__.constructor.call(this, false);
  }

  ChangeTooltipOfFeasibilitySection.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    ChangeTooltipOfFeasibilitySection.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds')).concat(q.get('recommendationAdolopmentIds', []));
    }, []);
    docIds = docIds.concat(['etd-tx-template', 'etd-dx-template', 'etd-overarchingDx-template', 'etd-overarchingTx-template']);
    return adapter.fetch(projectId, _.compact(_.uniq(docIds))).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').value();
    }).then(function(templateDocs) {
      var tooltip, updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return W.resolve();
      }
      tooltip = templatesSections().assessment.criteria.feasibility.info;
      updatedDocs = _.map(templateDocs, function(doc) {
        return R.assocPath(['templateData', 'assessment', 'criteria', 'feasibility', 'info'], tooltip, doc);
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return ChangeTooltipOfFeasibilitySection;

})(Migration);
