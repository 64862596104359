SelectCustom = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'

acrossStudiesOptions = [
  value: 'low'
  text: $.t 'rob:across_studies_options.low'
,
  value: 'unclear'
  text: $.t 'rob:across_studies_options.unclear'
,
  value: 'high'
  text: $.t 'rob:across_studies_options.high'
]

finalAssessmentOptions = [
  value: 'None'
  text: $.t 'es:outcome.not_serious'
,
  value: 'Serious'
  text: $.t 'es:outcome.serious'
,
  value: 'VerySerious'
  text: $.t 'es:outcome.very_serious'
]

limitationsOptions =
  high: [
    value: 'small'
    text: $.t 'rob:limitations_options.crucial_limitation_for_one'
  ,
    value: 'large'
    text: $.t 'rob:limitations_options.crucial_limitation_for_more'
  ]
  unclear: [
    value: 'small'
    text: $.t 'rob:limitations_options.limitations_unlikely'
  ,
    value: 'large'
    text: $.t 'rob:limitations_options.limitations_likely'
  ]

OutcomeLevelRob = createReactClass
  displayName: 'OutcomeLevelRob'

  mixins: [Translation('rob:recommendations')]

  getRecommendation: ->
    switch @props.acrossStudies
      when 'low'
        @i18n 'no_limitations'
      when 'unclear'
        switch @props.limitations
          when 'small' then @i18n 'no_serious_limitations'
          when 'large' then @i18n 'serious_limitations'
      when 'high'
        switch @props.limitations
          when 'small' then @i18n 'serious_limitations'
          when 'large' then @i18n 'very_serious_limitations'

  render: ->
    recommendation = @getRecommendation()

    <div className='outcome-level-rob'>
      <div className='assessment-block'>
        <label>GRADE assessment of study limitations</label>
        <SelectCustom
          options={acrossStudiesOptions}
          onChange={@props.acrossStudiesChanged}
          selected={@props.acrossStudies}
        />
      </div>
      {@props.acrossStudies in ['unclear', 'high'] and
        <div className='assessment-block'>
          <label>Considerations</label>
          <SelectCustom
            options={limitationsOptions[@props.acrossStudies]}
            selected={@props.limitations}
            onChange={@props.limiationsChanged}
          />
        </div>
      }
      {if recommendation?
        <div className='recommendation'>
          {recommendation}
        </div>
      }
      <div className='assessment-block'>
        <label>Final assessment</label>
        <SelectCustom
          options={finalAssessmentOptions}
          selected={@props.final}
          onChange={@props.finalChanged}
        />
      </div>
    </div>

module.exports = OutcomeLevelRob
