Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

VoteOption = (props) ->
  votesRatio = "#{Math.round(100 * props.votesRatio)}%"
  if props.renderMode is 'printout'
    <div>
      <table>
        <tbody>
          <tr>
            <td className='vote-option-text'>{props.optionLabel}</td>
            <td className='votes-ratio'>{votesRatio}</td>
          </tr>
        </tbody>
      </table>
    </div>
  else
    <div>
      <span className='vote-option-text'>
        {props.optionLabel}
      </span>
      <span className='votes-ratio'>{votesRatio}</span>
      <div className='filler' style={width: votesRatio}>&nbsp;</div>
    </div>

VotingJudgementCell = createReactClass
  displayName: 'VotingJudgementCell'
  mixins: [CustomRenderMixin, Translation('es:recommendations.table')]

  _votesByValue: ->
    # calculate votes by their value
    @props.votingResults.groupBy (voteData) -> voteData.get 'selectedOption'
    .map (grouped) -> grouped.size

  _getVotedData: ->
    "#{@props.votingResults.size} (of #{@props.votesExpected})"

  _getOptionVotesRatio: (optionVotes) ->
    return 0 if @props.votingResults.isEmpty()
    optionVotes / @props.votingResults.size

  _votingOptionMapper: (votesByValue, option, idx) ->
    optionValue = option.get 'value'
    optionLabel = if optionValue in ['agree', 'disagree', 'dont_know']
      @i18n optionValue
    else if @props.etdPart in ['conclusions', 'recommendation']
      option.get 'text'
    else
      @i18n "radio_values.#{optionValue}"
    votesRatio = @_getOptionVotesRatio votesByValue.get optionValue, 0

    <VoteOption key={idx}
      votesRatio={votesRatio}
      optionLabel={optionLabel}
      renderMode={@props.renderMode}
    />

  _getVotingSummary: ->
    votesByValue = @_votesByValue()
    if @props.isProposedVoting or @props.etdPart in ['conclusions', 'recommendation']
      <div className="votes-summary">
        {@props.votingOptions.map @_votingOptionMapper.bind this, votesByValue}
      </div>
    else
      <div className="votes-summary">
        {@props.votingOptions.get('main').map @_votingOptionMapper.bind this, votesByValue}
        {if not @props.votingOptions.get('additional').isEmpty()
          <hr />
        }
        {@props.votingOptions.get('additional').map @_votingOptionMapper.bind this, votesByValue}
      </div>

  _getDraftJudgement: ->
    draftJudgementText = if @props.draftJudgement
      if @props.etdPart in ['conclusions', 'recommendation']
        @i18n "final_recommendations_judgements.#{@props.draftJudgement}"
      else
        @i18n "radio_values.#{@props.draftJudgement}"
    else
      @i18n 'n_a'

    <div>
      <div className="draft-judgement">
        <span>
          {@i18n 'draft_judgement'}: {draftJudgementText}
        </span>
      </div>
      <hr />
    </div>

  render: ->
    return false unless @props.votingOptions?

    <div className='voting-judgment-cell'>
      {if @props.isProposedVoting and @props.draftJudgement?
        @_getDraftJudgement()
      }
      <div className="voted-data">
        <span>
          {@i18n 'voted'}: {@_getVotedData()}
        </span>
      </div>
      <div className="judgement-voting">
        {@_getVotingSummary()}
      </div>
    </div>

module.exports = VotingJudgementCell
