var alt;

alt = require('alt');

module.exports = function(_arg) {
  var actions, autoGenerate, name, _ActionsClass;
  autoGenerate = _arg.autoGenerate, actions = _arg.actions, name = _arg.name;
  if (name == null) {
    name = '_ActionsClass';
  }
  _ActionsClass = new Function("return function " + name + "() { };")();
  _ActionsClass.prototype = actions;
  _ActionsClass.prototype.constructor = function() {
    if (!_.isEmpty(autoGenerate)) {
      return this.generateActions.apply(this, autoGenerate);
    }
  };
  return alt.createActions(_ActionsClass);
};
