# It wraps provided children in a container which will have scrollbar appear when content
# overflows. And unlike regular overflow: auto container this one will have its scrollbar appear
# over the content, that is content will not squeeze when scrollbar is rendered. This made it
# possible to remove .with-scroll-bar logic from questions_table_header component as well as related
# CSS styles and has a great potential of re-use since this is a common problem when scroll bar
# squeezes the content, especially in tables with sticky headers.
# Adjusting the width is the implementation details of this component. Inner container width
# property should be explicitly set, otherwise scrollbar will squeeze the content.

Scrollable = createReactClass
  displayName: 'Scrollable'

  _ref: (el) ->
    @container = el

  _contentRef: (el) ->
    @content = el

  _adjustContentWidth: ->
    return unless @container? and @content?
    { clientWidth } = @container

    @content.style.width = "#{clientWidth}px"

  componentDidMount: ->
    @_adjustContentWidth()
    window.addEventListener 'resize', @_adjustContentWidth

  componentWillUnmount: ->
    window.removeEventListener 'resize', @_adjustContentWidth

  render: ->
    <div className='gdt-scrollable__container' ref={@_ref}>
      <div className='gdt-scrollable__content' ref={@_contentRef}>
        {@props.children}
      </div>
    </div>

module.exports = Scrollable
