ConnectStore = require 'components/enhancers/connect_store'
DisplayIfSwitchIsOn = require 'components/common/feature_switches'
mediator = require 'mediator'
ProjectStore = require 'stores/project_store'
QuestionsStatusesStore = require 'stores/questions_statuses_store'
QuestionsStore = require 'stores/questions_store'
ReferencesStore = require 'stores/references_store'
TasksStore = require 'stores/tasks_store'
{ MDG_WORKSPACE } = require 'lib/mdg_helper'
{ getPublicationDateText, getDateModifiedText } = require 'lib/projects_helper'
{ useI18n, useCoffeeCallback, useCoffeeMemo } = require 'lib/react_utils'

QUESTION_STATISTIC_KEYS = if mediator.activeWorkspace is MDG_WORKSPACE
  [
    'questions',
    'evidence_table',
    'evidence_summaries',
    'search_strategy',
    'recommendation',
  ]
else
  [
    'questions',
    'evidence_table',
    'recommendation',
    'presentation',
  ]

Statistics = ({
  isFetchingQuestions
  isFetchingQuestionStatuses
  isFetchingReferences
  isFetchingTasks
  isFetchingUsedReferences
  project
  questionsIds
  questionsStatuses
  references
  tasks
  usedReferences
}) ->

  i18n = useI18n("projects:dashboard")

  doneTasksCount = useCoffeeMemo [tasks], -> tasks.filter((task) -> task.get('done')).size

  isMdgWorkspace = mediator.activeWorkspace is MDG_WORKSPACE

  questionStatistics = _.chain QUESTION_STATISTIC_KEYS
  .map (statisticKey) ->
    path = switch statisticKey
      when 'questions' then ['completed']
      else
        if isMdgWorkspace
          ['mdgResearchTeam', _.str.camelize(statisticKey), 'status']
        else
          ["#{_.str.camelize(statisticKey)}Status", 'status']
    doneNumber = questionsStatuses.filter((questionStatus, key) ->
      questionStatus.getIn(path) in ['done', true] and questionsIds.includes(key)
    ).size
    [statisticKey, doneNumber]
  .object()
  .value()

  <div>
    <div className="mt-20">
      {QUESTION_STATISTIC_KEYS.map (statisticKey) ->
        titleI18nKey = if isMdgWorkspace or statisticKey isnt 'questions'
          statisticKey
        else
          'questions_done'
        <Stats
          key={statisticKey}
          isFetching={isFetchingQuestionStatuses or isFetchingQuestions}
          title={i18n titleI18nKey}
          doneCount={questionStatistics[statisticKey] or 0}
          totalCount={questionsIds.size}
        />
      }
      <Stats
        isFetching={isFetchingTasks}
        title={i18n 'tasks'}
        doneCount={doneTasksCount}
        totalCount={tasks.size}
      />
      <Stats
        isFetching={isFetchingReferences or isFetchingUsedReferences}
        title={i18n if isMdgWorkspace then 'references' else 'references_doc_sec'}
        doneCount={usedReferences.size}
        totalCount={references.size}
      />
    </div>
    <DisplayIfSwitchIsOn switchName="mdgFeatures" switchType="server">
      <div className="mt-20">
        <div className='project-last-modified'>
          <div>
            <span className="project-last-modified__label bold mr-5">
              {i18n '../last_updated'}:
            </span>
            {getDateModifiedText(project.toJS())}
          </div>
          <div>
            <span className="project-last-modified__label bold mr-5">
              {i18n '../last_published'}:
            </span>
            {getPublicationDateText(project.toJS())}
          </div>
        </div>
      </div>
    </DisplayIfSwitchIsOn>
  </div>

Statistics.propTypes =
  isFetchingQuestions: PropTypes.bool.isRequired
  isFetchingQuestionStatuses: PropTypes.bool.isRequired
  isFetchingTasks: PropTypes.bool.isRequired
  project: PropTypes.instanceOf(Immutable.Map).isRequired
  questionsIds: PropTypes.instanceOf(Immutable.List).isRequired
  questionsStatuses: PropTypes.instanceOf(Immutable.Map).isRequired
  references: PropTypes.instanceOf(Immutable.List).isRequired
  tasks: PropTypes.instanceOf(Immutable.List).isRequired
  usedReferences: PropTypes.instanceOf(Immutable.Map).isRequired


Stats = ({ isFetching, title, doneCount, totalCount }) ->

  i18n = useI18n("projects:dashboard")

  <div className="flex flex-row">
    <div className="stats-label">{title}</div>
    {if isFetching
      <div>{i18n 'loading'}</div>
    else
      <div>{doneCount} / {totalCount} </div>
    }
  </div>

storeConnectors =
  ProjectStore: (Store) ->
    project: Store.getProject()
  ReferencesStore: (Store) ->
    isFetchingUsedReferences: Store.isFetchingUsedReferences()
    isFetchingReferences: Store.isFetching()
    references: Store.getDeduplicatedReferences()
    usedReferences: Store.getUsedReferences()
  QuestionsStore: (Store) ->
    isFetchingQuestions: Store.isFetching()
    questionsIds: Store.getQuestionsIds()
  QuestionsStatusesStore: (Store) ->
    isFetchingQuestionStatuses: Store.isFetching()
    questionsStatuses: Store.getQuestionsStatuses()
  TasksStore: (Store) ->
    isFetchingTasks: Store.isFetching()
    tasks: Store.getTasks()

module.exports = ConnectStore Statistics, [
  ProjectStore,
  QuestionsStore,
  ReferencesStore,
  QuestionsStatusesStore,
  TasksStore
], storeConnectors
