var OutcomesTableViewActions, OverReviewActions, alt, mediator;

alt = require('alt');

mediator = require('mediator');

OverReviewActions = require('actions/over_review_actions');

OutcomesTableViewActions = (function() {
  function OutcomesTableViewActions() {
    this.generateActions('changeOutcomesView', 'updateEditedOutcomeAttributes', 'stopOutcomeEdit');
  }

  OutcomesTableViewActions.prototype.startOutcomeEdit = function(params) {
    var editedAttributes;
    editedAttributes = this.alt.stores.OutcomesTableViewStore.getEditedOutcomeAttribites();
    if (!editedAttributes.isEmpty()) {
      this.actions.saveEditedAttributes();
    }
    return this.dispatch(params);
  };

  OutcomesTableViewActions.prototype.saveEditedAttributes = function() {
    var outcomeData, outcomeId, questionId, sourceId;
    outcomeId = this.alt.stores.OutcomesTableViewStore.getCurrentlyEditedOutcomeId();
    sourceId = this.alt.stores.OutcomesTableViewStore.getCurrentlyEditedOutcomeSourceId();
    questionId = this.alt.stores.OutcomesTableViewStore.getCurrentlyEditedOutcomeQuestionId();
    outcomeData = this.alt.stores.OutcomesTableViewStore.getEditedOutcomeAttribites().toJS();
    return OverReviewActions.updateSourceOutcome({
      questionId: questionId,
      sourceId: sourceId,
      outcomeId: outcomeId,
      outcomeData: outcomeData
    });
  };

  return OutcomesTableViewActions;

})();

module.exports = alt.createActions(OutcomesTableViewActions);
