_.namespace(module, function(require) {
  var QuestionDoc, W, defaultLimits, fetchProjectMembers, mediator, organizationPermissionsDocPrefix, personalLicenseLimitsDocId;
  mediator = require('mediator');
  W = require('when');
  QuestionDoc = require('lib/db_docs/question_doc');
  organizationPermissionsDocPrefix = require('lib/doc_prefixes').organizationPermissions;
  personalLicenseLimitsDocId = require('lib/doc_ids').PERSONAL_LICENSE_LIMITS;
  defaultLimits = require('lib/constants').defaultLimits;
  fetchProjectMembers = require('actions/async/projects').fetchProjectMembers;
  return {
    _getProjectOrganizationId: function(projectId) {
      return mediator.services.storePersistenceAdapter.fetch(projectId, projectId).then(R.prop('organizationId'));
    },
    _getOrgLimits: function(organizationId) {
      var orgPermissionsDocId;
      orgPermissionsDocId = "" + organizationPermissionsDocPrefix + "_" + organizationId;
      return mediator.services.storePersistenceAdapter.fetch(mediator.user.id, orgPermissionsDocId).then(function(permDoc) {
        return permDoc.limits || defaultLimits.org;
      })["catch"](function(err) {
        return defaultLimits.org;
      });
    },
    _getPersonalLimits: function(defaultPersonalLimits) {
      return mediator.services.storePersistenceAdapter.fetch(mediator.user.id, personalLicenseLimitsDocId).then(function(limitsDoc) {
        return limitsDoc.limits || defaultPersonalLimits;
      })["catch"](function(err) {
        return defaultPersonalLimits;
      });
    },
    getWorkspaceLimits: function(workspace) {
      var defaultPersonalLimits;
      if (workspace && workspace !== 'personal') {
        return this._getOrgLimits(workspace);
      } else {
        defaultPersonalLimits = (function() {
          switch (window.gdt.enterpriseId) {
            case 'ec':
              return defaultLimits.personalEC;
            default:
              return defaultLimits.personal;
          }
        })();
        return this._getPersonalLimits(defaultPersonalLimits);
      }
    },
    getProjectLimits: function(projectId) {
      return this._getProjectOrganizationId(projectId).then(this.getWorkspaceLimits);
    },
    getProjectLimitsSummary: function(projectId) {
      return W.all([this.getProjectLimits(projectId), QuestionDoc.at(projectId).fetchMany(), fetchProjectMembers(projectId)]).then(function(_arg) {
        var adminResearcherCount, limits, memberCount, members, questions, _ref, _ref1;
        limits = _arg[0], questions = _arg[1], (_ref = _arg[2], members = _ref.members);
        _ref1 = R.countBy(function(tm) {
          var isAdminResearcher, isInvitedAdminResearcher, _ref1, _ref2;
          if (tm.deleted) {
            return 'deleted';
          }
          isAdminResearcher = (_ref1 = R.head(tm.accessRights)) === 'admin' || _ref1 === 'researcher';
          isInvitedAdminResearcher = tm.pendingInvitation && ((_ref2 = tm.pendingInvitationAccessRights) === 'admin' || _ref2 === 'researcher');
          if (isAdminResearcher || isInvitedAdminResearcher) {
            return 'adminResearcher';
          } else {
            return 'member';
          }
        }, members), memberCount = _ref1.member, adminResearcherCount = _ref1.adminResearcher;
        return R.mapObjIndexed(function(limit, limitKey) {
          switch (limitKey) {
            case 'questions':
              return {
                limit: limit,
                actual: questions.length
              };
            case 'teamMembers':
              return {
                limit: limit,
                actual: memberCount + adminResearcherCount
              };
            case 'teamAdmins':
              return {
                limit: limit,
                actual: adminResearcherCount
              };
          }
        }, limits);
      });
    }
  };
});
