var HOSTNAME_REGEXP, MDG_APP_URL, MDG_COLLECTION_IDS_TO_LINK_PREFIXES, MDG_COLLECTION_ID_ITEMS, MDG_CREATE_QUESTION_DATA, MDG_CRITERIA_ORDER, MDG_DIAGNOSTIC_SCORE_CRITERIA_ORDER, MDG_EMPTY_INTERVENTION, MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_CASE_STUDIES, MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_COHORT_STUDIES, MDG_EPIDEMIOLOGICAL_SCORE_POINTS, MDG_GENERAL_INFORMATION_FORM_ITEMS, MDG_IMAGING_INTERVENTIONS, MDG_INITIAL_QUESTION_STATUSES, MDG_INITIAL_SEX, MDG_QUESTION_TYPES, MDG_SEARCH_STRATEGY_GENERAL_INFORMATION_FIELDS_TO_SHOW, MDG_SEARCH_STRATEGY_SPEC_DIAGNOSTIC, MDG_SEARCH_STRATEGY_SPEC_TREATMENT, MDG_SEARCH_STRATEGY_TABS, MDG_TREATMENT_SCORE_CRITERIA_ORDER, PROTOCOL_REGEXP, SEARCH_STRATEGY_TYPES, calculateScore, formatMdgSearchStrategySearchDate, getInitialCriteria, linkTransformer, linkUntransformer, momentForSearchDate, targetTransformer;

getInitialCriteria = function(criteriaOrder) {
  return _.chain(criteriaOrder).map(function(criterion) {
    return [criterion, 0];
  }).object().value();
};

MDG_QUESTION_TYPES = ['treatment', 'diagnostic', 'imagingXRays', 'imagingCT', 'imagingMRI', 'imagingSPECT', 'imagingDTI', 'epidemiological'];

SEARCH_STRATEGY_TYPES = ['treatment', 'diagnostic'];

MDG_TREATMENT_SCORE_CRITERIA_ORDER = ['randomization', 'treatmentAllocationConcealed', 'baselineComparability', 'patientBlinded', 'providerBlinded', 'assessorBlinded', 'controlledForCointerventions', 'complianceAcceptable', 'dropoutRate', 'timingOfAssessments', 'analyzedByIntentionToTreat'];

MDG_DIAGNOSTIC_SCORE_CRITERIA_ORDER = ['disorderClearlyDefined', 'testComparedWithGoldStandard', 'investigativeTestConductedOnAllPatients', 'goldStandardTestConductedOnAllPatients', 'assessorOfInvestigativeTestBlindedToDisorder', 'assessorOfGoldStandardTestBlindedToDisorder', 'statisticalAnalysisSensitivityAndSpecificity', 'statisticalAnalysisPPVAndNPV', 'normalRangeOfInvestigativeTest', 'timingOfTestingConductedAtSamePhaseOfDisorder', 'applicationOfTestsFollowedAppropriateQualityControlProcedures', 'summaryRiskOfBias'];

MDG_EPIDEMIOLOGICAL_SCORE_POINTS = {
  caseControlStudiesAreNotComparable: 0,
  cohortAreNotComparable: 0,
  communityControls: 1,
  completeFollowUp: 1,
  consecutiveOrObviouslyRepresentativeSeriesOfCases: 1,
  drawnFromDifferentSource: 0,
  drawnFromTheSameCommunityAsTheExposed: 1,
  followUpRate: 0,
  hospitalControls: 0,
  independentBlindAssessment: 1,
  interviewNotBlindedToCaseControlStudies: 0,
  no: 0,
  noDescription: 0,
  noDescriptionOfSource: 0,
  noDescriptionOfTheDerivationOfTheCohort: 0,
  noDescriptionOfTheDerivationOfTheNonExposedCohort: 0,
  noHistoryOfDisease: 1,
  nonRespondentsDescribed: 0,
  noStatement: 0,
  potentialForSelectionBiasesOrNotStated: 0,
  rateDifferentAndNoDesignation: 0,
  recordLinkage: 1,
  sameRateForBothGroups: 1,
  secureRecord: 1,
  selectedGroupOfUsers: 0,
  selfReport: 0,
  somewhatRepresentativeOfTheAverage: 1,
  structuredInterview: 1,
  studyControlsFor: 1,
  studyControlsForAnyAdditionalFactor: 1,
  subjectsLostToFollowUp: 1,
  trulyRepresentativeOfTheAverage: 1,
  writtenSelfReport: 0,
  yes: 1,
  yesRecordLinkageOrBasedOnSelfReports: 0,
  yesWithIndependentValidation: 1
};

MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_CASE_STUDIES = Immutable.OrderedMap({
  selection: Immutable.OrderedMap({
    isTheCaseDefinitionAdequate: Immutable.List(["yesWithIndependentValidation", "yesRecordLinkageOrBasedOnSelfReports", "noDescription"]),
    representativenessOfTheCases: Immutable.List(["consecutiveOrObviouslyRepresentativeSeriesOfCases", "potentialForSelectionBiasesOrNotStated"]),
    selectionOfControls: Immutable.List(["communityControls", "hospitalControls", "noDescription"]),
    definitionOfControls: Immutable.List(["noHistoryOfDisease", "noDescriptionOfSource"])
  }),
  comparability: Immutable.OrderedMap({
    comparabilityOfCasesAndControlsOnTheBasisOfTheDesignOrAnalysis: Immutable.List(["studyControlsFor", "studyControlsForAnyAdditionalFactor", "caseControlStudiesAreNotComparable"])
  }),
  exposure: Immutable.OrderedMap({
    ascertainmentOfExposureStudies: Immutable.List(["secureRecord", "structuredInterview", "interviewNotBlindedToCaseControlStudies", "writtenSelfReport", "noDescription"]),
    sameMethodOfAscertainmentForCasesAndControls: Immutable.List(["yes", "no"]),
    nonResponseRate: Immutable.List(["sameRateForBothGroups", "nonRespondentsDescribed", "rateDifferentAndNoDesignation"])
  })
});

MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_COHORT_STUDIES = Immutable.OrderedMap({
  selection: Immutable.OrderedMap({
    representativenessOfTheExposedCohort: Immutable.List(["trulyRepresentativeOfTheAverage", "somewhatRepresentativeOfTheAverage", "selectedGroupOfUsers", "noDescriptionOfTheDerivationOfTheCohort"]),
    selectionOfTheNonExposedCohort: Immutable.List(["drawnFromTheSameCommunityAsTheExposed", "drawnFromDifferentSource", "noDescriptionOfTheDerivationOfTheNonExposedCohort"]),
    ascertainmentOfExposure: Immutable.List(["secureRecord", "structuredInterview", "writtenSelfReport", "noDescription"]),
    demonstrationThatOutcomeOfInterestWasNotPresentAtStartOfStudy: Immutable.List(["yes", "no"])
  }),
  comparability: Immutable.OrderedMap({
    comparabilityOfCohortsOnTheBasisOfTheDesignOrAnalysis: Immutable.List(["studyControlsFor", "studyControlsForAnyAdditionalFactor", "cohortAreNotComparable"])
  }),
  outcome: Immutable.OrderedMap({
    assessmentOfOutcome: Immutable.List(["independentBlindAssessment", "recordLinkage", "selfReport", "noDescription"]),
    wasFollowUpLongEnough: Immutable.List(["yes", "no"]),
    adequacyOfFollowUpOfCohorts: Immutable.List(["completeFollowUp", "subjectsLostToFollowUp", "followUpRate", "noStatement"])
  })
});

MDG_CRITERIA_ORDER = _.union(MDG_TREATMENT_SCORE_CRITERIA_ORDER, MDG_DIAGNOSTIC_SCORE_CRITERIA_ORDER);

MDG_EMPTY_INTERVENTION = Immutable.fromJS({
  codes: {},
  name: '',
  shortName: ''
});

MDG_CREATE_QUESTION_DATA = Immutable.fromJS({
  intervention: MDG_EMPTY_INTERVENTION,
  population: {
    name: '',
    shortName: '',
    codes: {}
  },
  question: '',
  type: 'treatment'
});

MDG_IMAGING_INTERVENTIONS = Immutable.fromJS({
  imagingXRays: {
    name: 'Roentgenograms (X-Rays)',
    shortName: 'X-Rays',
    codes: {}
  },
  imagingCT: {
    name: 'Computed Tomography (CT)',
    shortName: 'CT',
    codes: {}
  },
  imagingMRI: {
    name: 'Magnetic Resonance Imaging (MRI)',
    shortName: 'MRI',
    codes: {}
  },
  imagingSPECT: {
    name: 'Single Proton Emission Computed Tomography (SPECT)',
    shortName: 'SPECT',
    codes: {}
  },
  imagingDTI: {
    name: 'Diffusion Tensor Imaging (DTI)',
    shortName: 'DTI',
    codes: {}
  }
});

MDG_INITIAL_SEX = [
  {
    selected: false,
    name: $.t('mdg_tables:edit_form.values.male'),
    value: ''
  }, {
    selected: false,
    name: $.t('mdg_tables:edit_form.values.female'),
    value: ''
  }
];

MDG_SEARCH_STRATEGY_SPEC_TREATMENT = {
  pubMed: {
    sections: ['rct', 'systematicReviews', 'populationStudies', 'other'],
    rct: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    },
    systematicReviews: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    },
    populationStudies: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    },
    other: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    }
  },
  scopus: {
    sections: ['diagnosisRCTTreatment', 'review', 'populationStudies', 'other'],
    diagnosisRCTTreatment: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    },
    review: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    },
    populationStudies: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    },
    other: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    }
  },
  cochraneLibrary: {
    sections: ['review', 'other'],
    review: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    },
    other: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    }
  },
  ebsco: {
    sections: ['rct', 'review', 'populationStudies', 'other'],
    rct: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    },
    review: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    },
    populationStudies: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    },
    other: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    }
  },
  googleScholar: {
    sections: ['review', 'other'],
    review: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    },
    other: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    }
  },
  other: {
    sections: ['other'],
    other: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    }
  },
  summary: {
    sections: ['summary'],
    summary: {
      columns: ['summary']
    }
  }
};

MDG_SEARCH_STRATEGY_SPEC_DIAGNOSTIC = {
  pubMed: {
    sections: ['rct', 'systematicReviews', 'populationStudies', 'other'],
    rct: {
      columns: ['whatYouTypedInForDiagnosticTopic', 'pubMedBestMatchSearchDetails', 'pubMedMostRecentSearchDetails', 'numberOfRetrievedArticlesBestMatch', 'numberOfRetrievedArticlesMostRecent']
    },
    systematicReviews: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    },
    populationStudies: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    },
    other: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    }
  },
  scopus: {
    sections: ['diagnosisRCTTreatment', 'review', 'populationStudies', 'other'],
    diagnosisRCTTreatment: {
      columns: ['whatYouTypedInForDiagnosticTopic', 'whatYouTypedInDiagnosis', 'whatYouTypedInForDiagnosticTerms', 'searchDetails', 'numberOfRetrievedArticles']
    },
    populationStudies: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    },
    review: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    },
    other: {
      columns: ['whatYouTypedIn', 'searchDetails', 'numberOfRetrievedArticles']
    }
  },
  cochraneLibrary: {
    sections: ['review', 'other'],
    review: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    },
    other: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    }
  },
  ebsco: {
    sections: ['rct', 'review', 'populationStudies', 'other'],
    rct: {
      columns: ['whatYouTypedInForDiagnosticTopic', 'numberOfRetrievedArticles']
    },
    review: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    },
    other: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    },
    populationStudies: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    }
  },
  googleScholar: {
    sections: ['review', 'other'],
    review: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    },
    other: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    }
  },
  other: {
    sections: ['other'],
    other: {
      columns: ['whatYouTypedIn', 'numberOfRetrievedArticles']
    }
  },
  summary: {
    sections: ['summary'],
    summary: {
      columns: ['summary']
    }
  }
};

MDG_SEARCH_STRATEGY_TABS = ['pubMed', 'scopus', 'cochraneLibrary', 'ebsco', 'googleScholar', 'other', 'summary'];

MDG_SEARCH_STRATEGY_GENERAL_INFORMATION_FIELDS_TO_SHOW = ['treatmentTopic', 'diagnosis', 'searchTermUsed', 'numberOfSavedArticles', 'searchDate', 'notes'];

MDG_INITIAL_QUESTION_STATUSES = {
  completed: false,
  locked: false,
  mdgResearchTeam: {
    done: false,
    evidenceScores: {},
    evidenceSummaries: {},
    searchStrategy: {},
    recommendation: {}
  },
  mdgReedGroup: {
    done: false,
    evidenceSummaries: {},
    searchStrategies: {},
    recommendations: {},
    citations: {}
  }
};

momentForSearchDate = function(date) {
  if (!date) {
    return '';
  }
  return moment(date).format('MM/DD/YYYY');
};

formatMdgSearchStrategySearchDate = function(_arg) {
  var from, to;
  from = _arg.from, to = _arg.to;
  if (from || to) {
    return "" + (momentForSearchDate(from)) + " - " + (momentForSearchDate(to));
  } else {
    return '-';
  }
};

MDG_COLLECTION_ID_ITEMS = _.map([1, 3, 9, 10, 11], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.collectionId." + value)
  };
});

MDG_COLLECTION_IDS_TO_LINK_PREFIXES = {
  1: '/mda/',
  3: '/state-guidelines/',
  9: '/acoem/methodology/',
  10: '/acoem/foundations/',
  11: '/acoem/disorders/'
};

MDG_GENERAL_INFORMATION_FORM_ITEMS = {
  main: [
    {
      fieldKey: 'title',
      showClear: false,
      type: 'text'
    }, {
      fieldKey: 'slug',
      helperTextFromI18n: true,
      showClear: false,
      type: 'text'
    }, {
      fieldKey: 'collectionId',
      options: MDG_COLLECTION_ID_ITEMS,
      type: 'select'
    }
  ]
};

calculateScore = function(data) {
  if (data.excludedFromScoring) {
    return data.value;
  } else if (_.isEmpty(data.epidemiologicalCriteria)) {
    return _.reduce(data.criteria, function(acc, criterion) {
      return acc + (criterion != null ? criterion : 0);
    }, 0);
  } else {
    return _.reduce(_.flatten(_.values(data.epidemiologicalCriteria)), function(acc, criterion) {
      var _ref;
      return acc + ((_ref = MDG_EPIDEMIOLOGICAL_SCORE_POINTS[criterion]) != null ? _ref : 0);
    }, 0);
  }
};

PROTOCOL_REGEXP = /^(((?:[a-z+]+:)?\/\/.+)|(?:[a-z]+:)[^\/].*)$/i;

HOSTNAME_REGEXP = /^(.+\.)?mdguidelines\.com$/i;

MDG_APP_URL = 'https://app.mdguidelines.com';

linkTransformer = function(href) {
  var hrefWithoutLeadingSlash;
  if (!href) {
    return href;
  }
  if (PROTOCOL_REGEXP.test(href)) {
    return href;
  }
  hrefWithoutLeadingSlash = href.replace(/^\//, '');
  return "" + MDG_APP_URL + "/" + hrefWithoutLeadingSlash;
};

linkUntransformer = function(href) {
  if (!href) {
    return href;
  }
  if (href.indexOf(MDG_APP_URL) !== 0) {
    return href;
  }
  return href.replace(MDG_APP_URL, '');
};

targetTransformer = function(href) {
  var url;
  if (!href) {
    return '_blank';
  }
  if (!PROTOCOL_REGEXP.test(href)) {
    return '_self';
  }
  url = new URL(href);
  if (HOSTNAME_REGEXP.test(url.hostname)) {
    return '_self';
  } else {
    return '_blank';
  }
};

module.exports = {
  MDG_EIC_HIGHLIGHT_COLOR: "#f8cdf6",
  MDG_WORKSPACE: 'o_md_guidelines_0',
  COI_PROJECT_ID: 'p_mdg_coi',
  MDG_COLLECTION_IDS_TO_LINK_PREFIXES: MDG_COLLECTION_IDS_TO_LINK_PREFIXES,
  MDG_QUESTION_TYPES: MDG_QUESTION_TYPES,
  MDG_DIAGNOSTIC_TYPES: _.filter(MDG_QUESTION_TYPES, function(type) {
    return type === 'diagnostic' || type.indexOf('imaging') === 0;
  }),
  MDG_TREATMENT_SCORE_CRITERIA_ORDER: MDG_TREATMENT_SCORE_CRITERIA_ORDER,
  MDG_TREATMENT_SCORE_CRITERIA: getInitialCriteria(MDG_TREATMENT_SCORE_CRITERIA_ORDER),
  MDG_DIAGNOSTIC_SCORE_CRITERIA_ORDER: MDG_DIAGNOSTIC_SCORE_CRITERIA_ORDER,
  MDG_DIAGNOSTIC_SCORE_CRITERIA: getInitialCriteria(MDG_DIAGNOSTIC_SCORE_CRITERIA_ORDER),
  MDG_CRITERIA_ORDER: MDG_CRITERIA_ORDER,
  MDG_CREATE_QUESTION_DATA: MDG_CREATE_QUESTION_DATA,
  MDG_EMPTY_INTERVENTION: MDG_EMPTY_INTERVENTION,
  MDG_IMAGING_INTERVENTIONS: MDG_IMAGING_INTERVENTIONS,
  MDG_INITIAL_SEX: MDG_INITIAL_SEX,
  MDG_INITIAL_QUESTION_STATUSES: MDG_INITIAL_QUESTION_STATUSES,
  MDG_SEARCH_STRATEGY_SPEC_TREATMENT: MDG_SEARCH_STRATEGY_SPEC_TREATMENT,
  MDG_SEARCH_STRATEGY_SPEC_DIAGNOSTIC: MDG_SEARCH_STRATEGY_SPEC_DIAGNOSTIC,
  MDG_SEARCH_STRATEGY_TABS: MDG_SEARCH_STRATEGY_TABS,
  MDG_SEARCH_STRATEGY_GENERAL_INFORMATION_FIELDS_TO_SHOW: MDG_SEARCH_STRATEGY_GENERAL_INFORMATION_FIELDS_TO_SHOW,
  MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_CASE_STUDIES: MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_CASE_STUDIES,
  MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_COHORT_STUDIES: MDG_EPIDEMIOLOGICAL_SCORE_CRITERIA_ORDER_COHORT_STUDIES,
  MDG_EPIDEMIOLOGICAL_SCORE_POINTS: MDG_EPIDEMIOLOGICAL_SCORE_POINTS,
  formatMdgSearchStrategySearchDate: formatMdgSearchStrategySearchDate,
  MDG_GENERAL_INFORMATION_FORM_ITEMS: MDG_GENERAL_INFORMATION_FORM_ITEMS,
  SEARCH_STRATEGY_TYPES: SEARCH_STRATEGY_TYPES,
  calculateScore: calculateScore,
  mdgHyperlinkPluginOptions: {
    linkTransformer: linkTransformer,
    linkUntransformer: linkUntransformer,
    targetTransformer: targetTransformer
  }
};
