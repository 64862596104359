var ReactCellEditContainer, ReactCellEditView, ReactComponent, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

ReactCellEditContainer = require('components/evidence_syntheses/outcomes/forms/react_cell_edit_container');

ReactComponent = require('base/lib/traits/react_component');

View = require('base/views/view');

module.exports = ReactCellEditView = (function(_super) {
  __extends(ReactCellEditView, _super);

  function ReactCellEditView() {
    return ReactCellEditView.__super__.constructor.apply(this, arguments);
  }

  ReactCellEditView.prototype.container = '#narrative-desc-react';

  ReactCellEditView.prototype.className = 'narrative-desc-react-view';

  ReactCellEditView.prototype.autoRender = true;

  ReactCellEditView.prototype.initialize = function(options) {
    ReactCellEditView.__super__.initialize.apply(this, arguments);
    this._stopInputsPropagation = false;
    this.options = options;
    this.enable(ReactComponent);
    return this.delegate('click', function(e) {
      return e.stopPropagation();
    });
  };

  ReactCellEditView.prototype.afterRender = function() {
    ReactCellEditView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(ReactCellEditContainer, this.options);
  };

  ReactCellEditView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    return ReactCellEditView.__super__.dispose.apply(this, arguments);
  };

  return ReactCellEditView;

})(View);
