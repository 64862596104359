var defaultDocShape, extendWithDefaultDocShape, setDocShapeMeta, shape;

shape = require('lib/db_docs/field_types/shape');

defaultDocShape = require('lib/db_docs/doc_shapes/default_doc');

extendWithDefaultDocShape = function(providedShape) {
  return shape.utils.extend(defaultDocShape, providedShape);
};

setDocShapeMeta = function(shapeTypeRecord) {
  return shapeTypeRecord.update('_typeMeta', R.assoc('docShape', true));
};

module.exports = R.compose(setDocShapeMeta, extendWithDefaultDocShape, shape);
