{ APPROVAL_TAB_OUTCOME } = require 'components/scope/outcomes/drag_drop_types'
{ dropSpec, dropCollect } = require 'components/scope/outcomes/approval_drop_area_dnd_spec'
Translation = require 'components/mixins/translation'
{ string } = PropTypes
DropTarget = ReactDnD.DropTarget

OutcomeDropArea = createReactClass
  displayName: 'OutcomeDropArea'

  propTypes:
    importance: string.isRequired

  mixins: [
    Translation('scope:outcomes')
  ]

  render: ->
    { importance, connectDropTarget, isTargetHovered } = @props
    dropAreaClass = classNames 'drop-area', 'hovered': isTargetHovered
    groupName = @i18n "outcome_#{_.str.underscored importance}"

    connectDropTarget(
      <tbody>
        <tr>
          <td colSpan='5' className={dropAreaClass}>
            <div>{@i18n 'drag_outcome_here', { groupName }}</div>
          </td>
        </tr>
      </tbody>
    )

module.exports = DropTarget(APPROVAL_TAB_OUTCOME, dropSpec, dropCollect)(OutcomeDropArea)
