HorizontalRadios = require './horizontal_radios'
{ useCoffeeMemo } = require 'lib/react_utils'
mediator = require 'mediator'

DetailedOutcome = ({
  additionalOptions
  editable
  groupName
  onChange
  options
  outcome
  selectedOption
}) ->
  proposedValue = useCoffeeMemo [outcome], ->
    return null unless mediator.services.switches.isOn('thresholdFeature')
    T1 = outcome.get('thresholdT1')
    T2 = outcome.get('thresholdT2')
    T3 = outcome.get('thresholdT3')
    absEffectSize = outcome.get('absEffectSize')
    absDenominator = outcome.get('absDenominator')
    utilityValue = outcome.get('utilityValue')

    return null if _.some([T1, T2, T3, absEffectSize, utilityValue], (v) -> !_.isNumber(v))
    unitlessValue = (absEffectSize / absDenominator) * (1 - utilityValue)

    return 'trivial' if unitlessValue < T1
    return 'small' if unitlessValue < T2
    return 'moderate' if unitlessValue < T3
    return 'large' if unitlessValue > T3

  <tr className="judgement-table-row">
    <td>{outcome.get 'name'}</td>
    <td>
      <HorizontalRadios
        additionalOptions={additionalOptions}
        editable={editable}
        groupName={groupName}
        onChange={onChange outcome.get('_id')}
        options={options}
        selectedOption={selectedOption}
        proposedValue={proposedValue}
      />
    </td>
  </tr>

DetailedOutcome.propTypes = {
  additionalOptions: PropTypes.instanceOf(Immutable.List).isRequired
  editable: PropTypes.bool.isRequired
  groupName: PropTypes.string.isRequired
  onChange: PropTypes.func.isRequired
  options: PropTypes.instanceOf(Immutable.List).isRequired
  selectedOption: PropTypes.string
}

DetailedOutcome.defaultProps = {
  selectedOption: ''
}

module.exports = DetailedOutcome
