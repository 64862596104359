{ useCoffeeCallback } = require 'lib/react_utils'

Checkboxes = ({ i18n, onChange, options, selectedOptions, readOnly }) ->
  onCheckboxesChange = useCoffeeCallback [onChange, selectedOptions], (e) ->
    value = e.target.value
    newSelectedOptions = if value in selectedOptions
      _.without selectedOptions, value
    else selectedOptions.concat value
    onChange newSelectedOptions

  <ul className="options-list no-background">
    {_.map options, (option) ->
      <li key={option}>
        <label>
          <input
            className="mr-10"
            disabled={readOnly}
            type="checkbox"
            value={option}
            onChange={onCheckboxesChange}
            checked={option in selectedOptions}
          />
          <span>{i18n option}</span>
        </label>
      </li>
    }
  </ul>

Checkboxes.propTypes =
  i18n: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
  options: PropTypes.arrayOf(PropTypes.string).isRequired
  readOnly: PropTypes.bool
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired

Checkboxes.defaultProps =
  readOnly: false

module.exports = Checkboxes
