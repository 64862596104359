var Code, MDG_QUESTION_TYPES, MdgStudy, SEARCH_STRATEGY_TYPES, arrayOf, boolean, docShape, docTypes, documentId, fieldWithNameShortNameAndCodes, initial, mapOf, mdgQuestionShape, number, oneOf, optional, shape, string, _ref, _ref1;

arrayOf = require('lib/db_docs/field_types/array_of');

Code = require('lib/db_docs/field_types/code_type');

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

docTypes = require('lib/db_docs/doc_types');

initial = require('lib/db_docs/field_types/type_decorators').initial;

mapOf = require('lib/db_docs/field_types/map_of');

_ref = require('lib/mdg_helper'), MDG_QUESTION_TYPES = _ref.MDG_QUESTION_TYPES, SEARCH_STRATEGY_TYPES = _ref.SEARCH_STRATEGY_TYPES;

MdgStudy = require('lib/db_docs/field_types/mdg_study_type');

oneOf = require('lib/db_docs/field_types/one_of');

shape = require('lib/db_docs/field_types/shape');

_ref1 = require('lib/db_docs/field_types/built_in_types'), string = _ref1.string, number = _ref1.number, boolean = _ref1.boolean;

optional = shape.typeDecorators.optional;

fieldWithNameShortNameAndCodes = shape({
  name: initial('')(string),
  shortName: initial('')(string),
  codes: initial({})(mapOf(Code))
});

mdgQuestionShape = docShape({
  coi: optional(mapOf(boolean)),
  docType: initial(docTypes.MDG_QUESTION)(string),
  intervention: fieldWithNameShortNameAndCodes,
  population: fieldWithNameShortNameAndCodes,
  question: initial('')(string),
  orderNumber: optional(number),
  recommendationIds: initial([])(arrayOf(documentId)),
  searchStrategyId: optional(documentId),
  studies: initial([])(arrayOf(MdgStudy)),
  type: initial('treatment')(oneOf(MDG_QUESTION_TYPES)),
  searchStrategyType: initial('treatment')(oneOf(SEARCH_STRATEGY_TYPES))
});

module.exports = mdgQuestionShape;
