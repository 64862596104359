var MdgScore, arrayOf, boolean, calculateScore, calculated, initial, mapOf, number, oneOf, optional, shape, string, _ref, _ref1;

_ref = require('lib/db_docs/field_types/built_in_types'), number = _ref.number, string = _ref.string, boolean = _ref.boolean;

mapOf = require('lib/db_docs/field_types/map_of');

oneOf = require('lib/db_docs/field_types/one_of');

arrayOf = require('lib/db_docs/field_types/array_of');

shape = require('lib/db_docs/field_types/shape');

initial = require('lib/db_docs/field_types/type_decorators').initial;

_ref1 = shape.typeDecorators, calculated = _ref1.calculated, optional = _ref1.optional;

calculateScore = require('lib/mdg_helper').calculateScore;

MdgScore = shape({
  comments: optional(string),
  criteria: initial({})(mapOf(oneOf([0, 0.5, 1]))),
  epidemiologicalCriteria: optional(mapOf(arrayOf(string))),
  value: calculated(calculateScore)(number),
  excludedFromScoring: optional(boolean),
  studyType: optional(oneOf(['case_studies', 'cohort_studies', '']))
});

module.exports = MdgScore;
