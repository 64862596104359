Select = require 'components/common/select_custom'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
SubchapterIllustrationOption = require 'components/decision_aid/subchapter_illustration_option'
Translation = require 'components/mixins/translation'
Modal = require 'components/common/modal'
{ SUBCHAPTER_ILLUSTRATIONS, getSubchapterIllustrationPath } = require 'lib/da_helper'


SubchaterIllustrationModal = createReactClass
  displayName: 'SubchaterIllustrationModal'

  propTypes:
    data: PropTypes.instanceOf(Immutable.Map)
    isOpen: PropTypes.bool.isRequired
    onApply: PropTypes.func.isRequired
    onClose: PropTypes.func.isRequired
    withCustomPosition: PropTypes.bool

  mixins: [Translation('da:details-screen')]

  getDefaultProps: ->
    data: Immutable.Map()
    withCustomPosition: true

  getInitialState: ->
    illustration: @props.data.get('illustration') ? SUBCHAPTER_ILLUSTRATIONS[0]
    position: @props.data.get('position') ? 'left'

  componentDidUpdate: (prevProps, prevState) ->
    if @props.isOpen and not prevProps.isOpen
      @setState @getInitialState()

  getPositionOptions: ->
    ['left', 'right'].map (position) =>
      value: position
      text: @i18n "on_#{position}_side"

  handleIllustrationSelect: (evt) ->
    illustrationName = evt.target.value
    @setState illustration: illustrationName

  handleIllustrationPositionChange: (position) ->
    @setState position: position

  handleApply: ->
    @props.onApply
      illustration: @state.illustration
      position: @state.position

  renderIllustrationOption: (illustrationName) ->
    <SubchapterIllustrationOption
      key={illustrationName}
      value={illustrationName}
      isSelected={@state.illustration is illustrationName}
      onChange={@handleIllustrationSelect}
    />

  render: ->
    <Modal
      className='decision-aid-subchapter-illustration-modal'
      isOpen={@props.isOpen}
      modalSize='medium'
      onClose={@props.onClose}
      onRequestClose={@props.onClose}
      title={@i18n 'choose_illustration'}
    >
      <div>
        {@i18n 'click_to_select'}
      </div>
      <div className='illustration-options'>
        {SUBCHAPTER_ILLUSTRATIONS.map @renderIllustrationOption}
      </div>
      {if @props.withCustomPosition
        <div className='position-setting'>
          <span>{@i18n 'display_image'}:</span>
          <Select
            options={@getPositionOptions()}
            selected={@state.position}
            onChange={@handleIllustrationPositionChange}
          />
        </div>
      }
      <ApplyCancelButtons
        onCancel={@props.onClose}
        onApply={@handleApply}
      />
    </Modal>

module.exports = SubchaterIllustrationModal
