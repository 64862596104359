var HEALTH_ADVISOR_WORKSPACE_ID, ReferencesActions, ReferencesStore, SEARCH_FIELDS, alt, authorsToString, createReferenceDoc, errorHandler, filterOutDuplicatedReferences, filterReferencesByIds, findReferenceById, getDuplicates, getDuplicatesGroups, immutable, mediator, prepareUsedReferences, sorterFn, _ref;

alt = require('alt');

mediator = require('mediator');

immutable = require('stores/utils/immutable');

ReferencesActions = require('actions/references_actions');

HEALTH_ADVISOR_WORKSPACE_ID = require('lib/mda_helper').HEALTH_ADVISOR_WORKSPACE_ID;

errorHandler = require('lib/app_utils').errorHandler;

_ref = require('lib/references_utils'), authorsToString = _ref.authorsToString, createReferenceDoc = _ref.createReferenceDoc, filterOutDuplicatedReferences = _ref.filterOutDuplicatedReferences, filterReferencesByIds = _ref.filterReferencesByIds, getDuplicatesGroups = _ref.getDuplicatesGroups, getDuplicates = _ref.getDuplicates, SEARCH_FIELDS = _ref.SEARCH_FIELDS;

findReferenceById = function(state, refId) {
  return state.get('references').find(function(ref) {
    return refId === ref.get('_id');
  });
};

prepareUsedReferences = function(usedReferences) {
  var chapters, fromMdaTopics, grouppedByType, refs, topics, updater;
  fromMdaTopics = mediator.activeWorkspace === HEALTH_ADVISOR_WORKSPACE_ID;
  grouppedByType = _.groupBy(usedReferences, function(data) {
    return data.type;
  });
  chapters = _.indexBy(grouppedByType['document_sections_chapter'], '_id');
  topics = _.indexBy(grouppedByType['mda_topic'], '_id');
  updater = function(acc, refId, listKey, newItem) {
    return acc.updateIn([refId, listKey], Immutable.List(), function(items) {
      if (items.includes(newItem)) {
        return items;
      } else {
        return items.push(newItem);
      }
    });
  };
  refs = _.reduce(grouppedByType, function(acc, data, key) {
    switch (key) {
      case 'mdg_question':
        _.each(data, function(question) {
          return _.each(question.studies, function(study) {
            var refId;
            refId = study.referenceId;
            if (refId) {
              return acc = updater(acc, refId, 'questions', Immutable.fromJS(question));
            }
          });
        });
        break;
      case 'document_sections_section':
        _.each(data, function(section) {
          var parent, _ref1;
          if (!section.parent) {
            return;
          }
          parent = chapters[section.parent];
          if (!parent) {
            return;
          }
          section = _.extend(section, {
            parentChapterTitle: parent.title,
            topic: fromMdaTopics && ((_ref1 = topics[section.mdaTopicId]) != null ? _ref1.name : void 0)
          });
          return _.each(section.refIds, function(refId) {
            return acc = updater(acc, refId, 'document_sections_section', Immutable.fromJS(section));
          });
        });
    }
    return acc;
  }, Immutable.Map());
  return refs.map(function(ref) {
    return ref.update('document_sections_section', function(sections) {
      return (sections || Immutable.List()).sortBy(function(section) {
        if (fromMdaTopics) {
          return "" + (section.get('topic')) + " - " + (section.get('parentChapterTitle'));
        } else {
          return "" + (section.get('parentChapterTitle'));
        }
      });
    });
  });
};

sorterFn = function(colName) {
  return function(ref) {
    if (colName === 'authors') {
      return authorsToString(ref.get(colName));
    } else {
      return ref.get(colName);
    }
  };
};

ReferencesStore = (function() {
  function ReferencesStore() {
    this.state = Immutable.fromJS({
      _meta: {
        everFetched: false,
        fetching: false,
        fetchingUsedReferences: false,
        usedReferencesFetchTime: null,
        selectedReference: null,
        editingReference: null,
        usedReferences: {},
        referenceUsageModalReferenceId: null,
        sortedBy: 'authors',
        sortAsc: true,
        search: {
          isActive: false,
          searchText: ''
        },
        insertModal: {
          isOpen: false,
          initialReferences: {},
          insertHandler: null
        }
      },
      references: [],
      attachment: {
        file: null,
        isDeleting: false,
        isUploading: false,
        isUploadModalOpen: false,
        isDeleteModalOpen: false,
        referenceId: null
      }
    });
    this.bindActions(ReferencesActions);
    this.exportPublicMethods({
      isFetching: this.isFetching,
      isFetchingUsedReferences: this.isFetchingUsedReferences,
      hasEverFetched: this.hasEverFetched,
      getReferences: this.getReferences,
      getDeduplicatedReferences: this.getDeduplicatedReferences,
      getReferencesWithSearch: this.getReferencesWithSearch,
      getDuplicatesCount: this.getDuplicatesCount,
      getDuplicatesGroups: this.getDuplicatesGroups,
      getSelectedReferenceId: this.getSelectedReferenceId,
      getEditingReference: this.getEditingReference,
      getSortedBy: this.getSortedBy,
      getSortAsc: this.getSortAsc,
      getReferenceById: this.getReferenceById,
      getReferenceByIds: this.getReferenceByIds,
      getActiveSearch: this.getActiveSearch,
      getAttachmentReferenceId: this.getAttachmentReferenceId,
      getAttachmentReference: this.getAttachmentReference,
      isUploadAttachmentModalOpen: this.isUploadAttachmentModalOpen,
      isUploadingAttachment: this.isUploadingAttachment,
      getAttachmentFile: this.getAttachmentFile,
      isDeleteAttachmentModalOpen: this.isDeleteAttachmentModalOpen,
      isDeletingAttachment: this.isDeletingAttachment,
      isInsertModalOpen: this.isInsertModalOpen,
      getInsertModalInsertHandler: this.getInsertModalInsertHandler,
      getInitialReferencesForInsert: this.getInitialReferencesForInsert,
      getUsedReferences: this.getUsedReferences,
      getUsedReferencesFetchTime: this.getUsedReferencesFetchTime,
      isReferenceUsageModalOpen: this.isReferenceUsageModalOpen,
      getReferenceUsageModalReference: this.getReferenceUsageModalReference,
      getReferenceUsageModalReferenceUsedIn: this.getReferenceUsageModalReferenceUsedIn
    });
  }

  ReferencesStore.prototype.onDbChange = function(change) {
    var deleted, doc, id, refIndex, references;
    deleted = change.deleted, id = change.id, doc = change.doc;
    references = this.state.get('references');
    refIndex = references.findIndex(function(ref) {
      return ref.get('_id') === id;
    });
    if (deleted) {
      return this.setState(this.state.set('references', references["delete"](refIndex)));
    } else {
      return this.setState(this.state.set('references', refIndex === -1 ? references.unshift(Immutable.fromJS(doc)) : references.set(refIndex, Immutable.fromJS(doc))));
    }
  };

  ReferencesStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['_meta', 'fetching'], true));
  };

  ReferencesStore.prototype.onFetchSuccess = function(referencesData) {
    var references;
    references = _.pluck(referencesData.rows, 'doc');
    return this.setState(this.state.withMutations(function(state) {
      var referencesImm, sortAsc, sortedBy;
      sortedBy = state.getIn(['_meta', 'sortedBy']);
      sortAsc = state.getIn(['_meta', 'sortAsc']);
      referencesImm = Immutable.fromJS(references).sortBy(sorterFn(sortedBy));
      if (!sortAsc) {
        referencesImm = referencesImm.reverse();
      }
      state.setIn(['_meta', 'fetching'], false);
      state.setIn(['_meta', 'everFetched'], true);
      return state.set('references', referencesImm);
    }));
  };

  ReferencesStore.prototype.onFetchError = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetching'], false));
    return errorHandler(err);
  };

  ReferencesStore.prototype.onFetchUsedReferences = function() {
    return this.setState(this.state.setIn(['_meta', 'fetchingUsedReferences'], true));
  };

  ReferencesStore.prototype.onFetchUsedReferencesSuccess = function(usedReferences) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'usedReferences'], prepareUsedReferences(usedReferences));
      state.setIn(['_meta', 'fetchingUsedReferences'], false);
      return state.setIn(['_meta', 'usedReferencesFetchTime'], Date.now());
    }));
  };

  ReferencesStore.prototype.onFetchUsedReferencesError = function(err) {
    this.setState(this.state.setIn(['_meta', 'fetchingUsedReferences'], false));
    return errorHandler(err);
  };

  ReferencesStore.prototype.onPouchSaveError = errorHandler;

  ReferencesStore.prototype.onToggleReferenceSelection = function(refId) {
    var currentlySelected, newVal;
    currentlySelected = this.state.getIn(['_meta', 'selectedReference']);
    newVal = currentlySelected === refId ? null : refId;
    return this.setState(this.state.setIn(['_meta', 'selectedReference'], newVal));
  };

  ReferencesStore.prototype.onToggleReferenceEdit = function() {
    var editingRef, newVal, selectedRef;
    selectedRef = this.state.getIn(['_meta', 'selectedReference']);
    editingRef = this.state.getIn(['_meta', 'editingReference']);
    newVal = editingRef != null ? null : findReferenceById(this.state, selectedRef);
    return this.setState(this.state.setIn(['_meta', 'editingReference'], newVal));
  };

  ReferencesStore.prototype.onSaveReference = function() {
    return this.setState(this.state.setIn(['_meta', 'selectedReference'], null));
  };

  ReferencesStore.prototype.onAddNewReference = function() {
    var newRefDoc;
    newRefDoc = Immutable.fromJS(_.omit(createReferenceDoc(), '_id'));
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'editingReference'], newRefDoc);
      return state.setIn(['_meta', 'selectedReference'], null);
    }));
  };

  ReferencesStore.prototype.onRemoveReference = function() {
    return this.setState(this.state.setIn(['_meta', 'selectedReference'], null));
  };

  ReferencesStore.prototype.onRemoveReferences = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['_meta', 'selectedReference'], null);
      return state.set('references', Immutable.List());
    }));
  };

  ReferencesStore.prototype.onSortByColumn = function(colName) {
    return this.setState(this.state.withMutations(function(state) {
      var sortAsc, sorted, sortedBy;
      sortedBy = state.getIn(['_meta', 'sortedBy']);
      if (sortedBy === colName) {
        sortAsc = state.getIn(['_meta', 'sortAsc']);
        state.setIn(['_meta', 'sortAsc'], !sortAsc);
      } else {
        state.setIn(['_meta', 'sortAsc'], true);
        state.setIn(['_meta', 'sortedBy'], colName);
      }
      sorted = state.get('references').sortBy(sorterFn(colName));
      if (!state.getIn(['_meta', 'sortAsc'])) {
        sorted = sorted.reverse();
      }
      return state.set('references', sorted);
    }));
  };

  ReferencesStore.prototype.onSearch = function(searchText) {
    return this.setState(this.state.setIn(['_meta', 'search'], Immutable.Map({
      isActive: true,
      searchText: searchText
    })));
  };

  ReferencesStore.prototype.onResetSearch = function() {
    return this.setState(this.state.setIn(['_meta', 'search'], Immutable.Map({
      isActive: false,
      searchText: ''
    })));
  };

  ReferencesStore.prototype.onOpenUploadAttachmentModal = function(referenceId) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['attachment', 'isUploadModalOpen'], true);
      return s.setIn(['attachment', 'referenceId'], referenceId);
    }));
  };

  ReferencesStore.prototype.onCloseUploadAttachmentModal = function() {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['attachment', 'file'], null);
      s.setIn(['attachment', 'isUploadModalOpen'], false);
      return s.setIn(['attachment', 'referenceId'], null);
    }));
  };

  ReferencesStore.prototype.onSetAttachmentFile = function(file) {
    return this.setState(this.state.setIn(['attachment', 'file'], file));
  };

  ReferencesStore.prototype.onUploadAttachment = function() {
    return this.setState(this.state.setIn(['attachment', 'isUploading'], true));
  };

  ReferencesStore.prototype.onUploadAttachmentSuccess = function() {
    mediator.dialogs.success($.t('references:attachment.uploadSuccessful'));
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['attachment', 'isUploadModalOpen'], false);
      s.setIn(['attachment', 'isUploading'], false);
      s.setIn(['attachment', 'file'], null);
      return s.setIn(['attachment', 'referenceId'], null);
    }));
  };

  ReferencesStore.prototype.onUploadAttachmentError = function(err) {
    errorHandler(err);
    return this.setState(this.state.setIn(['attachment', 'isUploading'], false));
  };

  ReferencesStore.prototype.onOpenDeleteAttachmentModal = function(referenceId) {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['attachment', 'isDeleteModalOpen'], true);
      return s.setIn(['attachment', 'referenceId'], referenceId);
    }));
  };

  ReferencesStore.prototype.onCloseDeleteAttachmentModal = function() {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['attachment', 'isDeleteModalOpen'], false);
      return s.setIn(['attachment', 'referenceId'], null);
    }));
  };

  ReferencesStore.prototype.onDeleteAttachment = function() {
    return this.setState(this.state.setIn(['attachment', 'isDeleting'], true));
  };

  ReferencesStore.prototype.onDeleteAttachmentSuccess = function() {
    mediator.dialogs.success($.t('references:attachment.deleteSuccessful'));
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['attachment', 'isDeleting'], false);
      s.setIn(['attachment', 'isDeleteModalOpen'], false);
      return s.setIn(['attachment', 'referenceId'], null);
    }));
  };

  ReferencesStore.prototype.onDeleteAttachmentError = function(err) {
    errorHandler(err);
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['attachment', 'isDeleting'], false);
      s.setIn(['attachment', 'isDeleteModalOpen'], false);
      return s.setIn(['attachment', 'referenceId'], null);
    }));
  };

  ReferencesStore.prototype.onOpenInsertModal = function(_arg) {
    var handler, references;
    handler = _arg.handler, references = _arg.references;
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'insertModal', 'initialReferences'], Immutable.fromJS(references));
      s.setIn(['_meta', 'insertModal', 'isOpen'], true);
      return s.setIn(['_meta', 'insertModal', 'insertHandler'], handler);
    }));
  };

  ReferencesStore.prototype.onCloseInsertModal = function() {
    return this.setState(this.state.withMutations(function(s) {
      s.setIn(['_meta', 'insertModal', 'initialReferences'], Immutable.List());
      s.setIn(['_meta', 'insertModal', 'isOpen'], false);
      return s.setIn(['_meta', 'insertModal', 'insertHandler'], null);
    }));
  };

  ReferencesStore.prototype.onOpenReferenceUsageModal = function(referenceId) {
    return this.setState(this.state.setIn(['_meta', 'referenceUsageModalReferenceId'], referenceId));
  };

  ReferencesStore.prototype.onCloseReferenceUsageModal = function() {
    return this.setState(this.state.setIn(['_meta', 'referenceUsageModalReferenceId'], null));
  };

  ReferencesStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  ReferencesStore.prototype.isFetchingUsedReferences = function() {
    return this.state.getIn(['_meta', 'fetchingUsedReferences']);
  };

  ReferencesStore.prototype.hasEverFetched = function() {
    return this.state.getIn(['_meta', 'everFetched']);
  };

  ReferencesStore.prototype.getEditingReference = function() {
    return this.state.getIn(['_meta', 'editingReference']);
  };

  ReferencesStore.prototype.getSelectedReferenceId = function() {
    return this.state.getIn(['_meta', 'selectedReference']);
  };

  ReferencesStore.prototype.getReferences = function() {
    return this.state.get('references');
  };

  ReferencesStore.prototype.getDeduplicatedReferences = function() {
    return filterOutDuplicatedReferences(this.state.get('references'));
  };

  ReferencesStore.prototype.getReferencesWithSearch = function() {
    var references, searchText;
    references = this.getDeduplicatedReferences();
    searchText = this.state.getIn(['_meta', 'search', 'searchText']);
    if (_.isEmpty(searchText)) {
      return references;
    } else {
      return references.filter(function(ref) {
        return _.some(SEARCH_FIELDS, function(fieldName) {
          var fieldVal, fieldValString;
          fieldVal = ref.get(fieldName);
          if (fieldVal == null) {
            return false;
          }
          fieldValString = fieldName === 'authors' ? authorsToString(fieldVal) : fieldVal.toString();
          return fieldValString.toLowerCase().indexOf(searchText) >= 0;
        });
      });
    }
  };

  ReferencesStore.prototype.getDuplicatesCount = function() {
    return getDuplicates(this.state.get('references')).size;
  };

  ReferencesStore.prototype.getDuplicatesGroups = function() {
    return getDuplicatesGroups(this.state.get('references'));
  };

  ReferencesStore.prototype.getSortedBy = function() {
    return this.state.getIn(['_meta', 'sortedBy']);
  };

  ReferencesStore.prototype.getSortAsc = function() {
    return this.state.getIn(['_meta', 'sortAsc']);
  };

  ReferencesStore.prototype.getReferenceById = function(refId) {
    return this.getReferenceByIds([refId]).first();
  };

  ReferencesStore.prototype.getReferenceByIds = function(refIds) {
    return filterReferencesByIds(this.state.get('references'), refIds);
  };

  ReferencesStore.prototype.getActiveSearch = function() {
    return this.state.getIn(['_meta', 'search']);
  };

  ReferencesStore.prototype.getAttachmentReferenceId = function() {
    return this.state.getIn(['attachment', 'referenceId']);
  };

  ReferencesStore.prototype.getAttachmentReference = function() {
    return findReferenceById(this.state, this.getAttachmentReferenceId());
  };

  ReferencesStore.prototype.isUploadAttachmentModalOpen = function() {
    return this.state.getIn(['attachment', 'isUploadModalOpen']);
  };

  ReferencesStore.prototype.isUploadingAttachment = function() {
    return this.state.getIn(['attachment', 'isUploading']);
  };

  ReferencesStore.prototype.getAttachmentFile = function() {
    return this.state.getIn(['attachment', 'file']);
  };

  ReferencesStore.prototype.isDeleteAttachmentModalOpen = function() {
    return this.state.getIn(['attachment', 'isDeleteModalOpen']);
  };

  ReferencesStore.prototype.isDeletingAttachment = function() {
    return this.state.getIn(['attachment', 'isDeleting']);
  };

  ReferencesStore.prototype.isInsertModalOpen = function() {
    return this.state.getIn(['_meta', 'insertModal', 'isOpen']);
  };

  ReferencesStore.prototype.getInsertModalInsertHandler = function() {
    return this.state.getIn(['_meta', 'insertModal', 'insertHandler']);
  };

  ReferencesStore.prototype.getInitialReferencesForInsert = function() {
    return this.state.getIn(['_meta', 'insertModal', 'initialReferences']);
  };

  ReferencesStore.prototype.getUsedReferences = function() {
    return this.state.getIn(['_meta', 'usedReferences'], Immutable.Map());
  };

  ReferencesStore.prototype.getUsedReferencesFetchTime = function() {
    return this.state.getIn(['_meta', 'usedReferencesFetchTime']);
  };

  ReferencesStore.prototype.isReferenceUsageModalOpen = function() {
    return Boolean(this.state.getIn(['_meta', 'referenceUsageModalReferenceId']));
  };

  ReferencesStore.prototype.getReferenceUsageModalReference = function() {
    var refId;
    refId = this.state.getIn(['_meta', 'referenceUsageModalReferenceId']);
    if (!refId) {
      return;
    }
    return this.getReferenceById(refId);
  };

  ReferencesStore.prototype.getReferenceUsageModalReferenceUsedIn = function() {
    var refId;
    refId = this.state.getIn(['_meta', 'referenceUsageModalReferenceId']);
    if (!refId) {
      return;
    }
    return this.state.getIn(['_meta', 'usedReferences', refId]);
  };

  return ReferencesStore;

})();

module.exports = alt.createStore(immutable(ReferencesStore), 'ReferencesStore');
