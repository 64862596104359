var INITIAL_STATE, MASTER_PROJECT_ID, MdaWorkflowDialogActions, generateStore;

generateStore = require('stores/utils/generate_store');

MASTER_PROJECT_ID = require('lib/mda_helper').MASTER_PROJECT_ID;

MdaWorkflowDialogActions = require('actions/mda_workflow_dialog_actions');

INITIAL_STATE = Immutable.fromJS({
  isCreating: false,
  isFetchingTopics: false,
  isSplitting: false,
  mode: null,
  name: '',
  organizationId: null,
  projects: [],
  searchString: '',
  selectedProjectId: MASTER_PROJECT_ID,
  selectedTopics: [],
  sortColumn: 'name',
  sortType: 'asc',
  tables: {},
  topics: {}
});

module.exports = generateStore({
  name: 'MdaWorkflowDialogStore',
  initialState: INITIAL_STATE,
  boundActions: [MdaWorkflowDialogActions],
  methods: {
    onCloseDialog: function() {
      return this.setState(INITIAL_STATE);
    },
    onCreateWorkflow: function() {
      return this.setState(this.state.set('isCreating', true));
    },
    onFetch: function(projects) {
      return this.setState(this.state.set('projects', Immutable.fromJS(projects)));
    },
    onFetchError: function() {
      return this.setState(INITIAL_STATE);
    },
    onFetchTopics: function(projectId) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetchingTopics', true);
        state.set('selectedProjectId', projectId);
        return state.set('selectedTopics', Immutable.List());
      }));
    },
    onFetchTopicsSuccess: function(_arg) {
      var tables, topics;
      tables = _arg.tables, topics = _arg.topics;
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetchingTopics', false);
        state.set('tables', Immutable.fromJS(tables));
        return state.set('topics', Immutable.fromJS(topics));
      }));
    },
    onOpenDialog: function(_arg) {
      var mode, organizationId;
      mode = _arg.mode, organizationId = _arg.organizationId;
      return this.setState(this.state.withMutations(function(state) {
        state.set('mode', mode);
        return state.set('organizationId', organizationId);
      }));
    },
    onUpdateName: function(name) {
      return this.setState(this.state.set('name', name));
    },
    onUpdateSearchString: function(searchString) {
      return this.setState(this.state.set('searchString', Immutable.fromJS(searchString)));
    },
    onUpdateSort: function(newSortColumn) {
      var currentSortColumn;
      currentSortColumn = this.state.get('sortColumn');
      if (currentSortColumn === newSortColumn) {
        return this.setState(this.state.update('sortType', function(sortType) {
          if (sortType === 'asc') {
            return 'desc';
          } else {
            return 'asc';
          }
        }));
      } else {
        return this.setState(this.state.withMutations(function(state) {
          state.set('sortColumn', newSortColumn);
          return state.set('sortType', 'asc');
        }));
      }
    },
    onToggleTopic: function(topicId) {
      return this.setState(this.state.update('selectedTopics', function(selectedTopics) {
        if (selectedTopics.includes(topicId)) {
          return selectedTopics.filterNot(function(id) {
            return id === topicId;
          });
        } else {
          return selectedTopics.push(topicId);
        }
      }));
    },
    onSplitWorkflow: function() {
      return this.setState(this.state.set('isSplitting', true));
    },
    isCreating: function() {
      return this.state.get('isCreating');
    },
    isFetchingTopics: function() {
      return this.state.get('isFetchingTopics');
    },
    isOpen: function(mode) {
      return this.state.get('mode') === mode;
    },
    isSplitting: function() {
      return this.state.get('isSplitting');
    },
    getName: function() {
      return this.state.get('name');
    },
    getOrganizationId: function() {
      return this.state.get('organizationId');
    },
    getAllTables: function() {
      return this.state.get('tables');
    },
    getAllTopics: function() {
      return this.state.get('topics');
    },
    getProjects: function() {
      return this.state.get('projects');
    },
    getSearchString: function() {
      return this.state.get('searchString');
    },
    getSelectedProjectId: function() {
      return this.state.get('selectedProjectId');
    },
    getSelectedTopics: function() {
      return this.state.get('selectedTopics');
    },
    getSortColumn: function() {
      return this.state.get('sortColumn');
    },
    getSortType: function() {
      return this.state.get('sortType');
    },
    getTopics: function() {
      var filtered, reverseCoefficient, searchString, sortColumn, topics, _ref;
      searchString = (_ref = this.state.get('searchString')) != null ? _ref.toLowerCase() : void 0;
      sortColumn = this.state.get('sortColumn');
      reverseCoefficient = this.state.get('sortType') === 'desc' ? -1 : 1;
      topics = this.state.get('topics').valueSeq();
      filtered = searchString ? topics.filter(function(topic) {
        var _ref1;
        return ((_ref1 = topic.get('name')) != null ? _ref1.toLowerCase().indexOf(searchString) : void 0) >= 0;
      }) : topics;
      return filtered.sort(function(topicA, topicB) {
        var fieldValueA, fieldValueB;
        fieldValueA = topicA.get(sortColumn);
        fieldValueB = topicB.get(sortColumn);
        if (fieldValueA < fieldValueB) {
          return -reverseCoefficient;
        } else if (fieldValueB < fieldValueA) {
          return reverseCoefficient;
        } else {
          return 0;
        }
      }).toList();
    }
  }
});
