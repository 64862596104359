OverReviewActions = require 'actions/over_review_actions'
OverReviewStore = require 'stores/over_review_store'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EditableTextItem = require 'components/over_review/editable_text_item'
SourceEditModal = require 'components/over_review/source_edit_modal'
OverReviewImport = require 'components/over_review/over_review_import'
{ generateGUID } = require 'base/lib/utils'
{ object, array, string } = PropTypes

SourcesList = createReactClass
  displayName: 'SourcesList'

  propTypes:
    questionId: string.isRequired
    sources: object
    sourcesOrder: array
    studies: array
    editingSourceId: string

  mixins: [
    CustomRenderMixin,
    Translation('over_review:actions')
  ]

  _overReviewImportRef: (el) ->
    @overReivewImport = el

  getStudySourcesCount: (studyId) ->
    OverReviewStore.getStudySourcesCount @props.questionId, studyId, @props.editingSourceId

  handleManualAdd: ->
    OverReviewActions.startSourceEdit '_new'

  handleSourceEdit: (sourceId) -> ->
    OverReviewActions.startSourceEdit sourceId

  handleSourceDelete: (sourceId) -> =>
    OverReviewActions.deleteSource { questionId: @props.questionId, sourceId }

  handleSaveEditedSource: (sourceDataDenormalized) ->
    { questionId, editingSourceId } = @props
    { sourceData, studies } = OverReviewStore.normalizeSourceData sourceDataDenormalized

    actionPromise = if editingSourceId is '_new'
      OverReviewActions.createSource { questionId, sourceData, studies }
    else
      OverReviewActions.updateSource { questionId, sourceData, studies, sourceId: editingSourceId }

    actionPromise
      .then OverReviewActions.cancelSourceEdit

  handleSaveImported: ({ sources, studies }) ->
    OverReviewActions.saveImportedSources {
      questionId: @props.questionId,
      sources: sources.map (s) -> R.assoc '_id', generateGUID(), s
      studies
    }

  suggestStudies: (searchText) ->
    OverReviewStore.suggestStudies @props.questionId, searchText

  handleImport: ->
    @overReivewImport.importFile()

  renderSourceItem: (sourceId) ->
    source = @props.sources[sourceId]
    return null unless source?

    { sourceName, intervention, comparison, population } = source

    <div className='source-item' key={sourceId}>
      <EditableTextItem
        isEditing={false}
        label={sourceName}
        onEdit={@handleSourceEdit sourceId}
        onDelete={@handleSourceDelete sourceId}
        text={"#{intervention} vs #{comparison} in #{population}"}
      />
    </div>

  render: ->
    { sourcesOrder, studies, editingSourceId } = @props

    <div className='over-review-sources-list'>
      <div>
        {sourcesOrder.map @renderSourceItem}
      </div>
      <div className='bottom-buttons'>
        <button className='btn btn-accept' onClick={@handleManualAdd}>
          {@i18n 'add_source_manually'}
        </button>
        <button className='btn btn-import' onClick={@handleImport}>
          {@i18n 'import_from_revman'}
        </button>
      </div>
      <OverReviewImport refCb={@_overReviewImportRef} onSave={@handleSaveImported} />
      {if editingSourceId?
        sourceEditData = OverReviewStore.getDenormalizedSourceData @props.questionId,
          editingSourceId

        <SourceEditModal
          sourceEditData={sourceEditData}
          getStudySourcesCount={@getStudySourcesCount}
          suggestStudies={@suggestStudies}
          onClose={OverReviewActions.cancelSourceEdit}
          onSave={@handleSaveEditedSource}
        />
      }
    </div>

module.exports = SourcesList
