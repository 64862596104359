var TN, any, caseControlStudiesDichOutcome, caseControlValues, caseReportsSeriesOutcome, caseReportsSeriesProviders, combineProviders, combineStrategies, concatNodes, contOutcome, contentInCaseControlDichOutcome, contentInCaseReportsSeriesOutcome, contentInNotPooledDichOutcome, contentPropProvider, continuousOutcome, controlHelpers, customPropsProviders, defaultAndCaseReportsSeriesCombinbed, defaultProviders, dichAbsEffectNode, dichCaseControlNode, dichControlsNode, dichOutcome, disabledInCaseControlDichOutcome, disabledInCaseReportsSeriesOutcome, disabledPropProvider, emptyContentPropProvider, getCheckedControls, narrativeOutcome, notMeasureNotReportedValues, notPooledContOutcome, notPooledDichOutcome, oneOfProviders, outcomeSchema, provider, rangeOfEffectsDichOutcome, rangeOfEffectsOutcome, tableFragment, theadSchema, _ref, _ref1, _ref2,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

TN = require('lib/gdt_tables/gdt_table_node');

_ref = require('components/evidence_syntheses/outcomes/outcome_table_description_helpers'), getCheckedControls = _ref.getCheckedControls, controlHelpers = _ref.controlHelpers;

_ref1 = require('components/evidence_syntheses/outcomes/cells/cell_props_providers'), provider = _ref1.provider, disabledPropProvider = _ref1.disabledPropProvider, emptyContentPropProvider = _ref1.emptyContentPropProvider, combineProviders = _ref1.combineProviders, oneOfProviders = _ref1.oneOfProviders;

_ref2 = require('components/evidence_syntheses/outcomes/cells/strategies'), caseControlStudiesDichOutcome = _ref2.caseControlStudiesDichOutcome, caseReportsSeriesOutcome = _ref2.caseReportsSeriesOutcome, continuousOutcome = _ref2.continuousOutcome, notPooledDichOutcome = _ref2.notPooledDichOutcome, notPooledContOutcome = _ref2.notPooledContOutcome, rangeOfEffectsDichOutcome = _ref2.rangeOfEffectsDichOutcome, rangeOfEffectsOutcome = _ref2.rangeOfEffectsOutcome, combineStrategies = _ref2.combineStrategies, any = _ref2.any;

caseControlValues = ['case_control_studies', 'case_control_and_other_combined'];

notMeasureNotReportedValues = ['not_measured', 'not_reported'];

concatNodes = TN.concatNodes;

tableFragment = TN.bind(TN, null, null);

theadSchema = tableFragment(TN('certaintyAssessment', null, TN('noOfStudies'), TN('studyDesign'), TN('riskOfBias'), TN('inconsistency'), TN('indirectness'), TN('imprecision'), TN('otherConsiderations')), TN('summaryOfFindings', null, TN('noOfPatients', null, TN('intervention'), TN('control')), TN('effect', null, TN('relativeEffect'), TN('absoluteEffect')), TN('certainty')), TN('importance'), TN('tableMode'));

theadSchema.totalColsCount = theadSchema.getLeavesCount();

dichControlsNode = function(controls) {
  return concatNodes(_.map(controls, function(_, controlKey) {
    return TN("" + controlKey + "Risk");
  }));
};

dichCaseControlNode = function(designStudy, metaType, checkedControls) {
  var controlsNode, interventionsNode;
  if (__indexOf.call(notMeasureNotReportedValues, metaType) >= 0) {
    return [TN('caseControl'), TN('intervention')];
  }
  controlsNode = dichControlsNode(checkedControls);
  if (__indexOf.call(caseControlValues, designStudy) >= 0) {
    interventionsNode = concatNodes(_.map(checkedControls, function(_, _key) {
      return TN('intervention');
    }));
    return TN('caseControl', null, interventionsNode, controlsNode);
  } else {
    return [TN('intervention'), controlsNode];
  }
};

dichAbsEffectNode = function(designStudy, metaType, checkedControls) {
  var absEffectNodes;
  if (__indexOf.call(notMeasureNotReportedValues, metaType) >= 0) {
    return TN('absoluteEffect');
  }
  absEffectNodes = concatNodes(_.map(checkedControls, function(_, controlKey) {
    return TN("" + controlKey + "AbsoluteEffect");
  }));
  if (__indexOf.call(caseControlValues, designStudy) >= 0) {
    return TN('absoluteEffect', null, absEffectNodes);
  } else {
    return absEffectNodes;
  }
};

dichOutcome = function(outcomeAttrs) {
  var checkedControls, designStudy, metaType, _ref3, _ref4;
  checkedControls = getCheckedControls(outcomeAttrs);
  designStudy = (_ref3 = outcomeAttrs.designStudies) != null ? _ref3.value : void 0;
  metaType = (_ref4 = outcomeAttrs.metaType) != null ? _ref4.toLowerCase() : void 0;
  return tableFragment(TN('noOfStudies'), TN('designStudies'), TN('riskOfBias'), TN('inconsistency'), TN('indirectness'), TN('imprecision'), TN('otherConsiderations'), dichCaseControlNode(designStudy, metaType, checkedControls), TN('relativeEffect'), dichAbsEffectNode(designStudy, metaType, checkedControls), TN('quality'), TN('importance')).toRowsList();
};

contOutcome = function(outcomeAttrs) {
  return tableFragment(TN('noOfStudies'), TN('designStudies'), TN('riskOfBias'), TN('inconsistency'), TN('indirectness'), TN('imprecision'), TN('otherConsiderations'), TN('intervention'), TN('control'), TN('relativeEffect'), TN('absoluteEffect'), TN('quality'), TN('importance')).toRowsList();
};

narrativeOutcome = function() {
  return tableFragment(TN('noOfStudies'), TN('designStudies'), TN('riskOfBias'), TN('inconsistency'), TN('indirectness'), TN('imprecision'), TN('otherConsiderations'), TN('narrativeDesc', {
    colSpan: 4
  }), TN('quality'), TN('importance')).toRowsList();
};

outcomeSchema = function(outcomeAttrs) {
  var outcomeDataRow, outcomeTitleRow;
  outcomeTitleRow = tableFragment(TN('oneRowOutcomeLabel', {
    colSpan: theadSchema.totalColsCount,
    disabled: true
  })).toRowsList();
  outcomeDataRow = (function() {
    switch (outcomeAttrs.type) {
      case 'dich':
        return dichOutcome(outcomeAttrs);
      case 'cont':
        return contOutcome(outcomeAttrs);
      case 'narrative':
        return narrativeOutcome(outcomeAttrs);
    }
  })();
  return outcomeTitleRow.concat(outcomeDataRow);
};

contentPropProvider = provider.bind(null, 'content');

disabledInCaseControlDichOutcome = disabledPropProvider(caseControlStudiesDichOutcome);

disabledInCaseReportsSeriesOutcome = disabledPropProvider(caseReportsSeriesOutcome);

contentInCaseControlDichOutcome = emptyContentPropProvider(caseControlStudiesDichOutcome);

contentInNotPooledDichOutcome = contentPropProvider(_.constant($.t('es:outcome.not_pooled')), notPooledDichOutcome);

contentInCaseReportsSeriesOutcome = emptyContentPropProvider(caseReportsSeriesOutcome);

defaultProviders = combineProviders(oneOfProviders(disabledInCaseControlDichOutcome, disabledPropProvider(rangeOfEffectsDichOutcome)), oneOfProviders(contentPropProvider(_.constant(''), rangeOfEffectsOutcome), contentInNotPooledDichOutcome));

caseReportsSeriesProviders = combineProviders(disabledInCaseReportsSeriesOutcome, contentInCaseReportsSeriesOutcome);

defaultAndCaseReportsSeriesCombinbed = combineProviders(defaultProviders, caseReportsSeriesProviders);

customPropsProviders = {
  intervention: combineProviders(disabledInCaseControlDichOutcome, contentInCaseControlDichOutcome),
  control: caseReportsSeriesProviders,
  calculatedControlRisk: caseReportsSeriesProviders,
  lowControlRisk: caseReportsSeriesProviders,
  moderateControlRisk: caseReportsSeriesProviders,
  highControlRisk: caseReportsSeriesProviders,
  relativeEffect: combineProviders(oneOfProviders(disabledPropProvider(notPooledDichOutcome), disabledInCaseReportsSeriesOutcome), oneOfProviders(contentInNotPooledDichOutcome, contentInCaseReportsSeriesOutcome)),
  absoluteEffect: combineProviders(defaultProviders, oneOfProviders(disabledPropProvider(notPooledContOutcome), disabledPropProvider(notPooledContOutcome), disabledInCaseReportsSeriesOutcome), oneOfProviders(provider('content', _.constant($.t('es:outcome.not_pooled')), notPooledContOutcome), contentInCaseReportsSeriesOutcome)),
  calculatedControlAbsoluteEffect: defaultAndCaseReportsSeriesCombinbed,
  lowControlAbsoluteEffect: defaultAndCaseReportsSeriesCombinbed,
  moderateControlAbsoluteEffect: defaultAndCaseReportsSeriesCombinbed,
  highControlAbsoluteEffect: defaultAndCaseReportsSeriesCombinbed,
  oneRowOutcomeLabel: disabledPropProvider(any)
};

module.exports = {
  theadSchema: theadSchema.toRowsList(),
  outcomeSchema: outcomeSchema,
  customPropsProviders: customPropsProviders
};
