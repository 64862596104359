RecommendationWithCheckbox = require 'components/common/recommendation_with_checkbox'

RecommendationsSelector = ({ etd, selected, onSelect }) ->

  conclusionSections = etd.getIn ['templateData', 'conclusions', 'sections'], Immutable.Map()
  questionId = etd.getIn ['templateData', 'question', 'docId']

  <div>
    {conclusionSections.getIn(['multipleRecommendations', 'recommendations'], Immutable.List())
      .map (recommendation) ->
        checked = selected.includes "#{questionId}_#{recommendation.get('_id')}"
        <div key={recommendation.get('_id')}>
          <RecommendationWithCheckbox
            checked={checked}
            onSelect={onSelect}
            questionId={questionId}
            recommendation={recommendation}
          />
        </div>
    }
  </div>


RecommendationsSelector.propTypes =
  etd: PropTypes.instanceOf Immutable.Map
  selected: PropTypes.instanceOf Immutable.List
  onSelect: PropTypes.func.isRequired

RecommendationsSelector.defaultProps =
  etd: Immutable.Map()
  selected: Immutable.List()


module.exports = RecommendationsSelector
