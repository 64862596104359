{ func, bool } = PropTypes

Slider = createReactClass
  displayName: 'SliderComponent'

  propTypes:
    onChange: func.isRequired
    checked: bool

  render: ->
    <label className='switcher switcher--footnotes-pane'>
      <input onChange={@props.onChange} type='checkbox' checked={@props.checked}/>
      <div className='slider round' />
    </label>

module.exports = Slider
