var InputDialog, ModalView, W, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

template = require('./templates/input_dialog');

W = require('when/when');

module.exports = InputDialog = (function(_super) {
  __extends(InputDialog, _super);

  function InputDialog() {
    return InputDialog.__super__.constructor.apply(this, arguments);
  }

  InputDialog.prototype.template = template;

  InputDialog.prototype.className = 'input-dialog';

  InputDialog.prototype.initialize = function() {
    InputDialog.__super__.initialize.apply(this, arguments);
    this._deferred = W.defer();
    this.delegate('click', 'button.cancel', (function(_this) {
      return function() {
        _this._deferred.reject('cancelled');
        return _this.dispose();
      };
    })(this));
    return this.delegate('click', 'button.ok', (function(_this) {
      return function() {
        _this._deferred.resolve(_this.$('input').val());
        return _this.dispose();
      };
    })(this));
  };

  InputDialog.prototype.getTemplateData = function() {
    return {
      message: this.options.message,
      maskedInput: this.options.maskedInput
    };
  };

  InputDialog.prototype.promise = function() {
    return this._deferred.promise;
  };

  return InputDialog;

})(ModalView);
