Translation = require 'components/mixins/translation'

CopyrightNotice = createReactClass
  displayName: 'CopyrightNotice'
  mixins: [Translation('')]

  render: ->
    <div className="footer copyright-notice">
      {@i18n 'copyright', year: (new Date).getFullYear()}
      <br />
      <a
        href={@i18n 'terms_of_service_url'}
        target="_blank"
      >
        {@i18n 'terms_of_service'}
      </a>
      <a
        href={@i18n 'privacy_policy_url'}
        target="_blank"
      >
        {@i18n 'privacy_policy'}
      </a>
    </div>

module.exports = CopyrightNotice
