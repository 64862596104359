var DiagnosticQuestionsScreeningSwitcher, Migration, QUESTIONS_DB_VIEW, W, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

QUESTIONS_DB_VIEW = require('lib/questions_helper').QUESTIONS_DB_VIEW;

migrationName = 'diagnostic_questions_screening_switcher';

module.exports = DiagnosticQuestionsScreeningSwitcher = (function(_super) {
  __extends(DiagnosticQuestionsScreeningSwitcher, _super);

  function DiagnosticQuestionsScreeningSwitcher() {
    DiagnosticQuestionsScreeningSwitcher.__super__.constructor.call(this, false);
  }

  DiagnosticQuestionsScreeningSwitcher.prototype.up = function(project) {
    var adapter, projectId;
    DiagnosticQuestionsScreeningSwitcher.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return adapter.fetchWithView(projectId, 'questions', QUESTIONS_DB_VIEW).then(function(questions) {
      return _.map(questions, function(question) {
        question.rev_author = "project_migration/" + migrationName;
        if (question.diagnosticVerb == null) {
          question.diagnosticVerb = 'diagnose';
        }
        return question;
      });
    }).then(function(questions) {
      return adapter.bulkDocs(projectId, questions);
    });
  };

  return DiagnosticQuestionsScreeningSwitcher;

})(Migration);
