{
  addEdge,
  removeElements,
  default: ReactFlowComponent
  ConnectionMode,
  ReactFlowProvider
} = ReactFlow
{ useState } = React
{ debounce } = require 'lodash'
{ useCoffeeCallback, useCoffeeEffect, useI18n } = require 'lib/react_utils'
AlgorithmsActions = require 'actions/algorithms_actions'
AlgorithmsStore = require 'stores/algorithms_store'
ConnectStore = require 'components/enhancers/connect_store'
mediator = require 'mediator'
Spinner = require 'components/common/spinner'
Portal = require './portal'
Sidebar = require './sidebar'

storeConnector =
  AlgorithmsStore: (Store, props) ->
    algorithm: Store.getAlgorithm(props.algorithmId)
    isFetching: Store.isFetching()


CANVAS_INITIAL_STATE = {
  width: 500,
  height: 500
}

DEFAULT_NODE_SIZE = {
  width: 150,
  height: 50
}

getTopRightElement = (elements) ->
  return if _.isEmpty(elements) then null else
    _.chain(elements)
      .filter((element) -> element.type == 'unidirectional' || element.type == 'breakpoint')
      .sortBy((element) -> -(element.position.x + element.data.width))
      .sortBy((element) -> element.position.y + element.data.height)
      .first()
      .value()

Algorithm = ({ algorithm, algorithmId, isFetching }) ->
  i18n = useI18n('algorithms:diagram')
  title = _.get(algorithm, 'title')
  blocks = _.get(algorithm, 'blocks')
  projectId = mediator.project.id

  canvasSize = {
    width: _.get(algorithm, 'width') ? _.get(CANVAS_INITIAL_STATE, 'width')
    height: _.get(algorithm, 'height') ? _.get(CANVAS_INITIAL_STATE, 'height')
  }

  elements = blocks ? []
  [currentElement, setCurrentElement] = useState(null)

  elementsToRender = elements.map((elem) ->
    if elem.id == currentElement?.id
      updatedData = _.defaults({}, { selected: true }, currentElement.data)
      _.defaults({}, { data : updatedData }, currentElement)
    else
      updatedData = _.defaults({}, { selected: false }, elem.data)
      _.defaults({}, { data : updatedData }, elem)
  )

  getDiagramDataUrl = (exportFormat) ->
    $diagram = document.getElementById('diagram')
    return unless $diagram
    switch exportFormat
      when 'svg' then htmlToImage.toSvg($diagram)
      when 'png' then htmlToImage.toPng($diagram)

  handleDiagramExport = (exportFormat) ->
    getDiagramDataUrl(exportFormat).then (dataUrl) ->
      return unless dataUrl
      if dataUrl then saveAs(dataUrl, "diagram.#{exportFormat}")

  updateAlgorithmDimensions =
    useCoffeeCallback [AlgorithmsActions, algorithmId, algorithm, projectId], (canvasSize) ->
      updatedAlgorithm = _.defaults({}, canvasSize, algorithm)
      AlgorithmsActions.updateAlgorithm projectId, algorithmId, updatedAlgorithm, false

  updateAlgorithm =
    useCoffeeCallback [AlgorithmsActions, algorithmId, algorithm, projectId], (elements) ->
      updatedAlgorithm = _.defaults({}, { blocks: elements }, algorithm)
      AlgorithmsActions.updateAlgorithm projectId, algorithmId, updatedAlgorithm, false

  onElementsRemove =
    useCoffeeCallback [elements, removeElements, updateAlgorithm], (elementsToRemove) ->
      updateAlgorithm(removeElements(elementsToRemove, elements))

  onConnect = useCoffeeCallback [addEdge, updateAlgorithm], (params) ->
    updateAlgorithm(
      addEdge(_.defaults({}, { type: 'smoothstep' }, params), elements)
    )

  handleElementAdd = useCoffeeCallback [
    updateAlgorithm,
    setCurrentElement,
    canvasSize
  ], (elements) ->
    topRightElement = getTopRightElement(elements)

    newPosition = if topRightElement
      # 25px on the right of the top-right foremost element
      {
        x: topRightElement.position.x + topRightElement.data.width + 25,
        y: topRightElement.position.y
      }
    else
      # hardcoded position around the centre of the canvas (+/- 1px), used when it's empty
      {
        x: Math.floor(canvasSize.width / 2) - Math.floor(DEFAULT_NODE_SIZE.width / 2),
        y: Math.floor(canvasSize.height / 2) - Math.floor(DEFAULT_NODE_SIZE.height / 2)
      }

    newElement = {
      id: Date.now().toString()
      data: {
        label: '',
        hyperlink: '',
        shape: 'rectangle'
        borderColor: '#000'
        background: '#FFF'
        fontColor: '#000',
        lockPosition: false,
        width: DEFAULT_NODE_SIZE.width,
        height: DEFAULT_NODE_SIZE.height
      }
      position: newPosition
      targetPosition: 'top',
      style: {},
      type: 'unidirectional'
    }
    updatedElements = elements.concat(newElement)
    setCurrentElement(newElement)
    updateAlgorithm(updatedElements)

  handleSectionAdd = useCoffeeCallback [updateAlgorithm, setCurrentElement], (elements) ->
    sections = _.chain(elements).filter((element) -> element.type == 'section').value()
    bottommostSection = if _.isEmpty(sections)
      null
    else
      _.chain(sections)
        .sortBy((element) -> element.position.y)
        .last()
        .value()

    newPosition = {
      x: 0
      y: if bottommostSection
        (bottommostSection.position.y + (bottommostSection.data.height ? 100))
      else
        25
    }

    newSection = {
      id: Date.now().toString()
      type: 'section'
      data: { label: '', background: '#FBFAF8', lockPosition: false }
      position: newPosition
    }
    updatedElements = elements.concat(newSection)
    setCurrentElement(newSection)
    updateAlgorithm(updatedElements)

  handleBreakpointAdd = useCoffeeCallback [updateAlgorithm, setCurrentElement], (elements) ->
    topRightElement = getTopRightElement(elements)

    newPosition = if topRightElement
      { x: topRightElement.position.x + 50, y: topRightElement.position.y }
    else
      # hardcoded position somewhere around the centre-top of the canvas, used when it's empty
      # TODO: T4765 - move it exactly to the center of the canvas
      # (when we start limiting its dimensions)
      { x: 400, y: 25 }

    newBreakpoint = {
      id: Date.now().toString()
      data: {}
      position: newPosition
      type: 'breakpoint'
    }
    updatedElements = elements.concat(newBreakpoint)
    setCurrentElement(newBreakpoint)
    updateAlgorithm(updatedElements)

  onNodeDragStop = useCoffeeCallback [elements, updateAlgorithm], (evt, node) ->
    updatedElements = _.map(elements, (element) ->
      if element.id == node.id then _.defaults({}, node, element) else element
    )
    updateAlgorithm(updatedElements)

  handleElementClick = useCoffeeCallback [elements, setCurrentElement], (evt, node) ->
    setCurrentElement(_.find(elements, (element) -> element.id == node.id))

  onPaneClick = useCoffeeCallback [setCurrentElement], (evt) ->
    setCurrentElement(null)

  handleNodeDataUpdate =
    useCoffeeCallback [elements, currentElement, updateAlgorithm], (newData) ->
      updatedCurrentNodeData = _.defaults({}, newData, currentElement.data)
      updatedCurrentNode = _.defaults({}, { data: updatedCurrentNodeData }, currentElement)
      updatedElements = _.map(elements, (element) ->
        if element.id == updatedCurrentNode.id then updatedCurrentNode else element
      )
      updateAlgorithm(updatedElements)

  handleEdgeDataUpdate =
    useCoffeeCallback [elements, currentElement, updateAlgorithm], (newData) ->
      updatedCurrentNode = _.defaults({}, newData, currentElement)
      updatedElements = _.map(elements, (element) ->
        if element.id == updatedCurrentNode.id then updatedCurrentNode else element
      )
      updateAlgorithm(updatedElements)

  handleElementOnResize =
    useCoffeeCallback [elements, currentElement, updateAlgorithm],
    debounce((elem, dimensions) ->
      updatedCurrentNodeData = _.defaults({}, dimensions, currentElement?.data, elem.data)
      updatedCurrentNode = _.defaults({}, { data: updatedCurrentNodeData }, currentElement)
      updatedElements = _.map(elements, (element) ->
        if element.id == updatedCurrentNode.id then updatedCurrentNode else element
      )
      updateAlgorithm(updatedElements)
    , 500)

  handleElementRemove =
    useCoffeeCallback [onElementsRemove, setCurrentElement, currentElement], () ->
      onElementsRemove([currentElement])
      setCurrentElement(null)

  useCoffeeEffect [elements, currentElement], () ->
    if currentElement
      newElement = _.find(elements, (element) -> element.id == currentElement.id) ? currentElement
      setCurrentElement(newElement)

  if isFetching
    <Spinner/>
  else if algorithm
    <div className='algorithm' style={canvasSize}>
      <ReactFlowProvider>
        <ReactFlowComponent
          id='diagram'
          elements={elementsToRender}
          onElementsRemove={onElementsRemove}
          onConnect={onConnect}
          connectionMode={ConnectionMode.Loose}
          selectNodesOnDrag={false}
          onNodeDragStop={onNodeDragStop}
          onElementClick={handleElementClick}
          onPaneClick={onPaneClick}
          snapToGrid={true}
          snapGrid={[12.5, 1]}
          minZoom={1}
          maxZoom={1}
          paneMoveable={false}
          onNodeResize={handleElementOnResize}
          onSectionResize={handleElementOnResize}
        />
      </ReactFlowProvider>
      <Portal>
        <Sidebar
          currentElement={currentElement}
          elements={elements}
          canvasSize={canvasSize}
          onCanvasSizeUpdate={updateAlgorithmDimensions}
          onElementAdd={handleElementAdd}
          onSectionAdd={handleSectionAdd}
          onBreakpointAdd={handleBreakpointAdd}
          onDiagramExport={() -> handleDiagramExport('svg')}
          onNodeDataUpdate={handleNodeDataUpdate}
          onElementRemove={handleElementRemove}
          onEdgeDataUpdate={handleEdgeDataUpdate}
        />
      </Portal>
    </div>
  else
    <p>{i18n('algorithm_with_id_not_found')}</p>

Algorithm.propTypes =
  algorithmId: PropTypes.string.isRequired
  algorithm: PropTypes.object
  isFetching: PropTypes.bool.isRequired

module.exports = ConnectStore Algorithm, AlgorithmsStore, storeConnector
