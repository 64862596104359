var CharactersLimit, Ellipsis, Focusable, FootnotesView, Shortcuts, Tabs, View, mediator, outcomeCellTypes, template, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

View = require('base/views/view');

template = require('views/templates/evidence_syntheses/footnotes');

mediator = require('mediator');

utils = require('base/lib/utils');

Shortcuts = require('base/lib/traits/shortcuts');

Ellipsis = require('base/lib/traits/ellipsis');

CharactersLimit = require('lib/traits/characters_limit');

Focusable = require('base/lib/traits/focusable');

Tabs = require('lib/traits/tabs');

outcomeCellTypes = require('models/outcome/outcome_cell_types');

module.exports = FootnotesView = (function(_super) {
  __extends(FootnotesView, _super);

  function FootnotesView() {
    return FootnotesView.__super__.constructor.apply(this, arguments);
  }

  FootnotesView.prototype.template = template;

  FootnotesView.prototype.className = 'footnotes';

  FootnotesView.prototype.container = '.footnotes-container';

  FootnotesView.prototype.ellipsisSelector = '.note-text';

  FootnotesView.prototype.shortcuts = {
    'down': 'selectNextFootnote',
    'up': 'selectPreviosFootnote'
  };

  FootnotesView.prototype.initialize = function() {
    var tabs, _ref;
    FootnotesView.__super__.initialize.apply(this, arguments);
    tabs = ['footnotes', 'references'];
    this.enable(Tabs, {
      tabs: tabs,
      activeTab: 'footnotes'
    });
    this.enable(Focusable);
    this.enable(Shortcuts);
    this.enable(Ellipsis, {
      selector: this.ellipsisSelector,
      row: 2
    });
    this.enable(CharactersLimit, {
      limits: [
        {
          selector: 'textarea',
          limit: 1000
        }
      ]
    });
    this.model.store();
    this.modelBind('change:footnotesList', this.render);
    mediator.colls.references.on('add', this.referenceAdded);
    _ref = this.options, this.activeOutcome = _ref.activeOutcome, this.activeProperty = _ref.activeProperty, this.activeCell = _ref.activeCell;
    this.fs = this.options.fs;
    this.filterByActiveCell = false;
    this.on('tabChanged', this.render);
    this.subscribeEvent('cellFootnotesClosed', this.render);
    this.delegate('mouseenter', '.footnotes-block li', this.mouseEnterFootnote);
    this.delegate('mouseleave', '.footnotes-block li', this.mouseLeaveFootnote);
    this.delegate('click', '.footnotes-list button.edit', this.editFootnote);
    this.delegate('click', '.footnotes-list button.save', this.saveFootnote);
    this.delegate('click', '.footnotes-list button.delete', this.deleteFootnote);
    this.delegate('click', 'button.filter-by-active-cell-btn', this.toggleFilterByActiveCell);
    this.delegate('click', '.switcher--cell-filter', this.toggleFilterByActiveCell);
    this.delegate('click', function(e) {
      return e.stopPropagation();
    });
    this.delegate('keydown', 'textarea', this.preventNewTextareaNewline);
    this.subscribeEvent('footnotesReordered', this.render);
    return this.subscribeEvent('referencesReordered', this.render);
  };

  FootnotesView.prototype.preventNewTextareaNewline = function(e) {
    if (e.keyCode === 13) {
      return e.preventDefault();
    }
  };

  FootnotesView.prototype.editFootnote = function(evt, $footnoteElement) {
    var $editContainer, $editInput, footnoteId;
    mediator.setFocus(this);
    this.saveFootnote();
    this.$editingFootnoteElement = $footnoteElement ? $footnoteElement : (footnoteId = $(evt.target).closest('li').data()['footnoteId'], this.$el.find("li[data-footnote-id=" + footnoteId + "]"));
    this.$editingFootnoteElement.children('.display-footnote').toggleClass('hidden');
    $editContainer = this.$editingFootnoteElement.children('.edit-footnote');
    $editContainer.toggleClass('hidden');
    $editInput = $editContainer.find('.footnote-input-field');
    $editInput.focus();
    autosize($editInput);
    this.options.outcomesView.fixLayout();
    return this.$editingFootnoteElement[0].scrollIntoView();
  };

  FootnotesView.prototype.saveFootnote = function() {
    var $footnoteInput, footnoteId, footnoteVal;
    if (!this.$editingFootnoteElement) {
      return;
    }
    $footnoteInput = this.$editingFootnoteElement.find('.footnote-input-field');
    footnoteId = parseInt($footnoteInput.attr('name'));
    footnoteVal = utils.removeLineBreaks($footnoteInput.val());
    this.model.updateFootnote(footnoteId, footnoteVal);
    this.closeEditFootnote();
    this.model.save();
    return this.render();
  };

  FootnotesView.prototype.deleteFootnote = function() {
    var footnoteId;
    footnoteId = this.$editingFootnoteElement.find('.footnote-input-field').attr('name');
    this.model.deleteFootnote(footnoteId);
    this.model.get('outcomes').forEach(function(o) {
      return o.unbindFootnote(footnoteId);
    });
    this.model.save();
    this.closeEditFootnote();
    return this.render();
  };

  FootnotesView.prototype.closeEditFootnote = function() {
    if (this.$editingFootnoteElement != null) {
      this.$editingFootnoteElement.children('.display-footnote').toggleClass('hidden');
      this.$editingFootnoteElement.children('.edit-footnote').toggleClass('hidden');
    }
    return this.$editingFootnoteElement = null;
  };

  FootnotesView.prototype.toggleFilterByActiveCell = function() {
    this.filterByActiveCell = !this.filterByActiveCell;
    this.$('.switcher--cell-filter input[type="checkbox"]').prop('checked', this.filterByActiveCell);
    return this.render();
  };

  FootnotesView.prototype.getActiveCellDefaultContentClass = function() {
    var cellContentClasses, cellOtherFormatters, formatter, _ref;
    cellOtherFormatters = (_ref = outcomeCellTypes[this.activeProperty]) != null ? _ref.otherFormatters : void 0;
    if (cellOtherFormatters == null) {
      return null;
    }
    cellContentClasses = this.activeCell.find('.cell-content').attr('class').split(/\s+/);
    formatter = _.find(cellOtherFormatters, function(_arg) {
      var contentDefaultClass;
      contentDefaultClass = _arg.contentDefaultClass;
      return __indexOf.call(cellContentClasses, contentDefaultClass) >= 0;
    });
    return formatter != null ? formatter.contentDefaultClass : void 0;
  };

  FootnotesView.prototype.setActiveOutcomeAndProperty = function(activeOutcome, activeProperty, activeCell) {
    this.activeOutcome = activeOutcome;
    this.activeCell = activeCell;
    this.activeProperty = activeProperty;
    if (this.filterByActiveCell) {
      return this.render();
    }
  };

  FootnotesView.prototype._conditionallyFilterByActiveCell = function(list, listType) {
    var activeCellDefaultContentClass, filteredList, listIds;
    if (!this.filterByActiveCell) {
      return list;
    }
    activeCellDefaultContentClass = this.getActiveCellDefaultContentClass();
    filteredList = this.fs.getOutcomeAnnotationsForProperty(listType, this.activeOutcome, this.activeProperty, activeCellDefaultContentClass);
    listIds = _(filteredList).chain().values().flatten().uniq().value();
    return _(list).filter(function(f) {
      var _ref;
      return _ref = f.id, __indexOf.call(listIds, _ref) >= 0;
    });
  };

  FootnotesView.prototype.getTemplateData = function() {
    var activeTab, footnotesList, outcomes, referencesList;
    activeTab = this.getActiveTab();
    outcomes = this.model.get('outcomes').toArray();
    switch (activeTab) {
      case 'footnotes':
        footnotesList = this.fs.getOutcomesTableItemsObjects('footnotes', outcomes);
        footnotesList = this._conditionallyFilterByActiveCell(footnotesList, 'footnotes');
        break;
      case 'references':
        referencesList = this.fs.getOutcomesTableItemsObjects('references', outcomes);
        referencesList = this._conditionallyFilterByActiveCell(referencesList, 'references');
    }
    return _(FootnotesView.__super__.getTemplateData.apply(this, arguments)).extend({
      footnotesList: footnotesList,
      referencesList: referencesList,
      filterByActiveCell: this.filterByActiveCell
    });
  };

  FootnotesView.prototype.assignFootnoteItems = function() {
    return this.footnoteItems = this.$('.footnotes-block li');
  };

  FootnotesView.prototype.afterRender = function() {
    FootnotesView.__super__.afterRender.apply(this, arguments);
    this.footnotesBlock = this.$('.footnotes-block');
    this.footnotesBlock.show();
    this.assignFootnoteItems();
    return this.options.outcomesView.fixLayout();
  };

  FootnotesView.prototype.mouseEnterFootnote = function(e) {
    return this.setActiveFootnote(this.footnoteItems.index($(e.target).closest('li')));
  };

  FootnotesView.prototype.mouseLeaveFootnote = function(e) {
    var _ref;
    return this.publishEvent('footnoteDeactivated', (_ref = this.activeFootnote.data('footnoteId')) != null ? _ref : this.activeFootnote.data('referenceId'));
  };

  FootnotesView.prototype.setActiveFootnote = function(footnoteItemIdx) {
    var _ref, _ref1;
    if ((_ref = this.activeFootnote) != null) {
      _ref.removeClass('active');
    }
    if (footnoteItemIdx) {
      this.activeFootnote = this.footnoteItems.eq(footnoteItemIdx);
      this.activeFootnoteIdx = footnoteItemIdx;
    } else {
      this.activeFootnote = this.footnoteItems.eq(0);
      this.activeFootnoteIdx = 0;
    }
    this.activeFootnote.addClass('active');
    return this.publishEvent('footnoteActivated', (_ref1 = this.activeFootnote.data('footnoteId')) != null ? _ref1 : this.activeFootnote.data('referenceId'));
  };

  FootnotesView.prototype.selectNextFootnote = function() {
    if (this.activeFootnoteIdx === this.footnoteItems.size() - 1) {
      return;
    }
    return this.setActiveFootnote(this.activeFootnoteIdx + 1);
  };

  FootnotesView.prototype.selectPreviosFootnote = function() {
    if (this.activeFootnoteIdx === 0) {
      return;
    }
    return this.setActiveFootnote(this.activeFootnoteIdx - 1);
  };

  FootnotesView.prototype.dispose = function() {
    return FootnotesView.__super__.dispose.apply(this, arguments);
  };

  return FootnotesView;

})(View);
