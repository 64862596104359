var createData, namespace, viewHelperUtils;

viewHelperUtils = require('lib/view_helper_utils');

namespace = 'es:outcome.';

createData = function(itemData, dataColNo, params) {
  var data, k, v;
  data = itemData === void 0 ? {} : _(itemData).clone();
  data.colspan = null;
  data.dataColNo = dataColNo;
  if (_(params).isString()) {
    params = JSON.parse(params);
    for (k in params) {
      v = params[k];
      data[k] = v;
    }
  }
  data.className = typeof params.className === 'string' ? params.className : '';
  return data;
};

Handlebars.registerHelper('controlNotEditableCell', function(itemData, dataColNo, params) {
  var data, message, partial;
  partial = 'views/templates/evidence_syntheses/cells/not_editable';
  data = createData(itemData, dataColNo, params);
  message = '-';
  if (data.metaType === 'NOT_POOLED') {
    message = viewHelperUtils.getCapitalizedValueForCochrane(namespace, 'not_pooled');
  }
  data.value = message;
  return viewHelperUtils.regPartial(name, partial, data);
});

Handlebars.registerHelper('absoluteEffectNotEditableCell', function(itemData, dataColNo, params) {
  var data, partial, _ref;
  partial = 'views/templates/evidence_syntheses/cells/not_editable';
  data = createData(itemData, dataColNo, params);
  data.value = data.metaType === 'NOT_POOLED' ? data.viewTypeName === 'OneRow' ? viewHelperUtils.getCapitalizedValueForCochrane(namespace, 'not_pooled') : viewHelperUtils.getCapitalizedValueForCochrane(namespace, 'see_comment') : data.metaType === 'RANGE_OF_EFFECTS' ? data.viewTypeName !== 'OneRow' ? viewHelperUtils.getCapitalizedValueForCochrane(namespace, 'not_estimable') : void 0 : (_ref = data.viewTypeName) !== 'OneRow' && _ref !== 'PrintoutOneRow' ? viewHelperUtils.getCapitalizedValueForCochrane(namespace, 'see_comment') : '-';
  return viewHelperUtils.regPartial(name, partial, data);
});

Handlebars.registerHelper('relativeEffectNotEditableCell', function(itemData, dataColNo, params) {
  var data, partial;
  partial = 'views/templates/evidence_syntheses/cells/not_editable';
  data = createData(itemData, dataColNo, params);
  data.value = data.type === 'dich' && data.metaType === 'NOT_POOLED' ? data.viewTypeName === 'SOF' ? '-' : viewHelperUtils.getCapitalizedValueForCochrane(namespace, 'not_pooled') : '-';
  return viewHelperUtils.regPartial(name, partial, data);
});

Handlebars.registerHelper('riskNotEditableCell', function(itemData, dataColNo, params) {
  var data, message, partial, _ref;
  partial = 'views/templates/evidence_syntheses/cells/not_editable';
  data = createData(itemData, dataColNo, params);
  message = data.metaType === 'NOT_POOLED' ? data.type === 'cont' && data.dataProperty === 'correspondingRisk' && ((_ref = data.viewTypeName) === 'SOF_v3' || _ref === 'SOF_v4') ? '-' : data.viewTypeName === 'SOF' ? viewHelperUtils.getCapitalizedValueForCochrane(namespace, 'see_comment') : viewHelperUtils.getCapitalizedValueForCochrane(namespace, 'not_pooled') : '-';
  if (data.effectMeasure === 'SMD' && data.metaType !== 'NOT_POOLED') {
    message = '-';
  }
  data.value = message;
  return viewHelperUtils.regPartial(name, partial, data);
});
