ConnectStore = require 'components/enhancers/connect_store'
ConfirmationModal = require 'components/common/confirmation_modal'
Spinner = require 'components/common/spinner'
TasksFormModal = require 'components/tasks/tasks_form_modal'
TasksActions = require 'actions/tasks_actions'
TasksStore = require 'stores/tasks_store'
TasksTable = require 'components/tasks/tasks_table'
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'

TasksContainer = ({ isFetching, tasks, taskToDelete, sort }) ->

  i18n = useI18n()

  deleteTask = useCoffeeCallback [taskToDelete], -> TasksActions.deleteTask taskToDelete

  <div className="flex flex-row">
    {if isFetching
      <Spinner />
    else
      <div className="tasks-table-container">
        <TasksTable tasks={tasks} sort={sort} />
      </div>
    }
    <div id="calendar-container" className="tasks-calendar-container"/>
    <TasksFormModal />
    <ConfirmationModal
      isOpen={Boolean taskToDelete}
      question={i18n 'tasks:todo.delete_confirmation'}
      onCancel={TasksActions.closeDeleteConfirmationDialog}
      onConfirm={deleteTask}
    />
  </div>

storeConnector =
  TasksStore: (Store) ->
    isFetching: Store.isFetching()
    tasks: Store.getTasks()
    taskToDelete: Store.getDeleteConfirmationDialogOpen()
    sort: Store.getSort()

module.exports = ConnectStore TasksContainer, [
  TasksStore
], storeConnector
