AdolopedEtdActions = require 'actions/adoloped_etd_actions'
AdolopmentDataActions = require 'actions/adolopment_data_actions'
EtdActions = require 'actions/etd_actions'
etdHelper = require 'lib/etd_helper'
mediator = require 'mediator'
MultiComparisonEtdSourceModal = require './multi_comparison_etd_source_modal'
MultiComparisonsActions = require 'actions/multi_comparisons_actions'
multiComparisonsHelper = require 'lib/multi_comparisons_helper'
MultiComparisonsStore = require 'stores/multi_comparisons_store'
ReviewTableCell = require './review_table_cell'
ReviewTableSortableHeaderCell = require './review_table_sortable_header_cell'
SectionNameCell = require './section_name_cell'
Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'

MultiComparisonsSojTable = createReactClass
  displayName: 'MultiComparisonsSojTable'

  mixins: [ Translation('mc:review_tables') ]

  propTypes:
    adolopmentData: PropTypes.instanceOf(Immutable.Map).isRequired
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired
    assessmentSections: PropTypes.arrayOf(PropTypes.string).isRequired
    etds: PropTypes.instanceOf(Immutable.Map).isRequired
    hiddenSections: PropTypes.instanceOf(Immutable.Map).isRequired
    sectionsImportanceForDecision: PropTypes.instanceOf(Immutable.Map).isRequired
    selectedQuestions: PropTypes.instanceOf(Immutable.List).isRequired

  getInitialState: ->
    showSource: false

  getImportancesList: ->
    [
      value: 'low'
      text: @i18n 'importance.low'
    ,
      value: 'moderate'
      text: @i18n 'importance.moderate'
    ,
      value: 'high'
      text: @i18n 'importance.high'
    ]

  updateSectionImportanceForDecision: (section) -> (importance) ->
    MultiComparisonsActions.updateSectionImportanceForDecision { section, importance }

  renderImportanceDropdown: (section, importancesList, valueSelected) ->
    <Select
      className="review-table__importance-for-decision-dropdown"
      options={importancesList}
      selected={valueSelected}
      onChange={@updateSectionImportanceForDecision section}
    />

  moveQuestion: (questionId, direction) -> ->
    MultiComparisonsActions.moveSelectedQuestion { questionId, direction }

  showSource: ({ questionId, recommendationIds, intervention, comparison }) -> =>
    EtdActions.fetch
      dbId: mediator.project.id
      # EtdActions.fetchSuccess expects that we fetch using allDocs
      docId: recommendationIds.toJS()

    @setState
      showSource: true
      etdSourceQuestionId: questionId
      etdSourceIntervention: intervention
      etdSourceComparison: comparison

  closeSourceModal: ->
    @setState
      showSource: false
      etdSourceQuestionId: null

  render: ->
    importancesList = @getImportancesList()

    {
      adolopmentData
      adolopments
      selectedQuestions,
      sectionsImportanceForDecision,
      assessmentSections,
      hiddenSections,
      etds
    } = @props

    # this is percentage size
    sectionNameColumnWidth = 10
    importanceForDecisionColumnWidth = 10
    sortableColumnWidth =
      (100 -
        (sectionNameColumnWidth + importanceForDecisionColumnWidth)
      ) / selectedQuestions.size


    <div>
      {if @state.showSource
        <MultiComparisonEtdSourceModal
          questionId={@state.etdSourceQuestionId}
          intervention={@state.etdSourceIntervention}
          comparison={@state.etdSourceComparison}
          onClose={@closeSourceModal}
        />
      }
      <table className="standard-table multi_comparisons__review-table">
        <colgroup>
          <col style={{width: "#{sectionNameColumnWidth}%"}}/>
          {selectedQuestions.map (sq) ->
            <col key={sq.get 'questionId'} style={{width: "#{sortableColumnWidth}%"}} />
          }
          <col style={{width: "#{importanceForDecisionColumnWidth}%"}}/>
        </colgroup>
        <thead>
          <tr>
            <th className="review-table__header-cell review-table__header-cell--blank" />
            {selectedQuestions.map (sq, idx) =>
              questionId = sq.get 'questionId'
              questionType = sq.get('type')

              title = if questionType is 'diagnostic'
                t = "#{sq.get('indexTest')}"
                if sq.get('comparatorTestPresent') then t += "/#{sq.get('comparatorTest')}" else t
              else
                "#{sq.get('intervention')}/#{sq.get('comparison')}"

              <ReviewTableSortableHeaderCell
                key={questionId}
                title={title}
                hidden={false}
                columnIdx={idx}
                allColumnsCount={selectedQuestions.size}
                moveColumnLeft={@moveQuestion questionId, -1}
                moveColumnRight={@moveQuestion questionId, 1}
                showSource={@showSource(
                  questionId: sq.get('questionId')
                  recommendationIds: sq.get('recommendationIds', Immutable.List())
                  intervention: sq.get('intervention')
                  comparison: sq.get('comparison')
                )}
              />
            }
            <th className="review-table__header-cell review-table__header--importance">
              {@i18n 'importance_for_decision'}
            </th>
          </tr>
        </thead>
        <tbody>
          {assessmentSections.map (section) =>
            <tr key={section}>
              <SectionNameCell
                section={section}
                tableName="soj"
                hidden={hiddenSections.get(section, false)}
              />
              {selectedQuestions.map (sq) =>
                recommendationId = sq.get('recommendationIds').first()
                templateData = etds.get recommendationId
                i18nKey = multiComparisonsHelper.getAssessmentSelectedOption(
                  sq, section, adolopments, templateData, adolopmentData
                )

                <ReviewTableCell
                  key={sq.get('questionId')}
                  className={section}
                  hidden={hiddenSections.get(section, false)}
                >
                  {if i18nKey
                    @i18n "/es:recommendations.table.radio_values.#{i18nKey}"
                  else
                    ''
                  }
                </ReviewTableCell>
              }
              {###
                Last column merged for balanceOfEffects and certaintyOfEvidence
              ###
              importanceForDecision = sectionsImportanceForDecision.get section
              classes = classNames {
                "review-table__cell--importance",
                "review-table__cell--#{importanceForDecision}-importance": importanceForDecision
              }
              if section is 'balanceOfEffects'
                <ReviewTableCell
                  className={classes}
                  rowSpan="2"
                  hidden={hiddenSections.get(section, false)}
                >
                  {@renderImportanceDropdown(section, importancesList, importanceForDecision)}
                </ReviewTableCell>
              else if section not in ['certaintyOfEvidence', 'certaintyOfEvidenceOfTestAccuracy']
                <ReviewTableCell
                  className={classes}
                  hidden={hiddenSections.get(section, false)}
                >
                  {@renderImportanceDropdown(section, importancesList, importanceForDecision)}
                </ReviewTableCell>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>

module.exports = MultiComparisonsSojTable
