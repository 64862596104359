var AssessRobView, Focusable, ReactComponent, ReferencesActions, RobScreen, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ReferencesActions = require('actions/references_actions');

RobScreen = require('components/rob/rob_screen');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

Focusable = require('base/lib/traits/focusable');

mediator = require('mediator');

module.exports = AssessRobView = (function(_super) {
  __extends(AssessRobView, _super);

  function AssessRobView() {
    return AssessRobView.__super__.constructor.apply(this, arguments);
  }

  AssessRobView.prototype.autoRender = true;

  AssessRobView.prototype.initialize = function() {
    AssessRobView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  AssessRobView.prototype.afterRender = function() {
    var ids;
    AssessRobView.__super__.afterRender.apply(this, arguments);
    ids = this.model.getSetOfReferencesIdsForProperty('noOfStudies').asArray();
    return this.renderComponent(RobScreen, ReferencesActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id,
        opts: {
          filter: '_view',
          view: 'references/references'
        }
      },
      props: _(this.options).extend({
        onClose: this.dispose,
        refIds: ids
      })
    });
  };

  AssessRobView.prototype.dispose = function() {
    this.unmountComponent();
    return AssessRobView.__super__.dispose.apply(this, arguments);
  };

  return AssessRobView;

})(View);
