var DecisionAidDoc, DecisionAidService, SERVER_URL, W, callMethod, currentProject, decisionAidDocPrefix, draftUtils, etdBackgroundPath, lensPath, mediator,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

mediator = require('mediator');

DecisionAidDoc = require('lib/db_docs/decision_aid_doc');

callMethod = require('base/lib/utils').callMethod;

decisionAidDocPrefix = require('lib/doc_prefixes').decisionAid;

SERVER_URL = require('base/lib/db_helper').getBackendUrl();

draftUtils = require('lib/draft_utils');

mediator = require('mediator');

W = require('when');

lensPath = require('lib/lens_utils').lensPath;

etdBackgroundPath = lensPath('templateData', 'question', 'sections', 'background', 'content');

currentProject = function() {
  return mediator.project.id;
};

module.exports = DecisionAidService = (function() {
  function DecisionAidService() {}

  DecisionAidService.prototype.create = function(_arg) {
    var appearance, authorOrOrganization, outcomeIds, projectId, questionId, title;
    title = _arg.title, authorOrOrganization = _arg.authorOrOrganization, projectId = _arg.projectId, questionId = _arg.questionId, outcomeIds = _arg.outcomeIds, appearance = _arg.appearance;
    return mediator.services.storePersistenceAdapter.fetch(projectId, questionId).then(function(question) {
      var recommendationId;
      recommendationId = R.head(question.recommendationIds);
      if (recommendationId != null) {
        return mediator.services.storePersistenceAdapter.fetch(projectId, recommendationId).then(function(etd) {
          return {
            question: question,
            etd: etd
          };
        })["catch"](function(err) {
          mediator.dialogs.error(err);
          return {
            question: question
          };
        });
      } else {
        return W.resolve({
          question: question
        });
      }
    }).then(function(_arg1) {
      var comparison, etd, intervention, outcomes, question;
      question = _arg1.question, etd = _arg1.etd;
      intervention = question.intervention;
      comparison = question.comparison;
      outcomes = question.outcomes.filter(function(outcome) {
        var _ref;
        return _ref = outcome._id, __indexOf.call(outcomeIds, _ref) >= 0;
      });
      return DecisionAidDoc.at(currentProject()).create({
        appearance: appearance,
        authorOrOrganization: authorOrOrganization,
        chapters: {
          what_should_you_know: {
            illustration: 'question',
            title: $.t('da:chapters.what_should_you_know'),
            subchapters: [
              {
                title: $.t('da:subchapters.background'),
                content: draftUtils.stringToDraftJSContent(etd != null ? R.view(etdBackgroundPath, etd) : "")
              }, {
                title: $.t('da:subchapters.problem')
              }
            ]
          },
          interventions_compared: {
            illustration: 'justice',
            title: $.t('da:chapters.interventions_compared'),
            deletable: false,
            subchapters: [
              {
                title: '',
                titleHidden: true,
                type: 'table',
                tableContent: {
                  columns: [
                    {
                      header: comparison,
                      orderNum: 0
                    }, {
                      header: intervention,
                      orderNum: 1
                    }
                  ]
                }
              }, {
                title: '',
                type: 'outcomesTable'
              }
            ]
          },
          recommendations: {
            illustration: 'human',
            title: $.t('da:chapters.recommendations'),
            deletable: false,
            subchapters: [
              {
                title: '',
                titleHidden: true
              }
            ]
          },
          clinical_considerations: {
            illustration: 'human_with_bubble',
            title: $.t('da:chapters.clinical_considerations'),
            subchapters: [
              {
                title: ''
              }
            ]
          },
          values_and_preferences: {
            illustration: 'pluses',
            title: $.t('da:chapters.values_and_preferences'),
            subchapters: [
              {
                title: ''
              }, {
                title: '',
                type: 'table',
                tableContent: {
                  columns: [
                    {
                      header: $.t('da:table_headers.intervention_good_points', {
                        intervention: intervention
                      }),
                      orderNum: 0
                    }, {
                      header: $.t('da:table_headers.intervention_bad_points', {
                        intervention: intervention
                      }),
                      orderNum: 1
                    }
                  ]
                }
              }
            ]
          }
        },
        question: _.extend({}, question, {
          outcomes: outcomes
        }),
        title: title
      });
    });
  };

  DecisionAidService.prototype.save = function(decisionAidData) {
    return DecisionAidDoc.at(currentProject()).save(decisionAidData);
  };

  DecisionAidService.prototype["delete"] = function(decisionAidId) {
    return DecisionAidDoc.at(currentProject(), decisionAidId)["delete"]();
  };

  DecisionAidService.prototype.deleteDecisionAidChapter = function(daId, chapterId) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('deleteChapter', chapterId));
  };

  DecisionAidService.prototype.updateDecisionAidChapter = function(daId, chapterId, newData) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('updateChapter', chapterId, newData));
  };

  DecisionAidService.prototype.createDecisionAidChapter = function(daId, chapterdata) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('createChapter', chapterdata));
  };

  DecisionAidService.prototype.updateSubchapterContent = function(daId, chapterId, subchapterIdx, content) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('updateSubchapterContent', chapterId, subchapterIdx, content));
  };

  DecisionAidService.prototype.saveSubchapterTableContent = function(daId, chapterId, subchapterIdx, tableContent) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('saveSubchapterTableContent', chapterId, subchapterIdx, tableContent));
  };

  DecisionAidService.prototype.publish = function(decisionAidId) {
    return W.promise(function(resolve, reject) {
      return $.ajax({
        url: "" + SERVER_URL + "/decision-aids/" + (currentProject()) + "/" + decisionAidId,
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        error: reject,
        success: resolve
      });
    });
  };

  DecisionAidService.prototype.saveDecisionAidChaptersOrder = function(daId, chaptersOrder) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('saveDecisionAidChaptersOrder', chaptersOrder));
  };

  DecisionAidService.prototype.updateSubchapterIllustration = function(daId, chapterId, subchapterIdx, illustrationData) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('updateSubchapterIllustration', chapterId, subchapterIdx, illustrationData));
  };

  DecisionAidService.prototype.deleteSubchapterIllustration = function(daId, chapterId, subchapterIdx, illustrationData) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('deleteSubchapterIllustration', chapterId, subchapterIdx));
  };

  DecisionAidService.prototype.updateDecisionAidSubchapter = function(daId, chapterId, subchapterIdx, subchapterData) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('updateSubchapter', chapterId, subchapterIdx, subchapterData));
  };

  DecisionAidService.prototype.duplicateSubchapter = function(daId, chapterId, subchapterIdx) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('duplicateSubchapter', chapterId, subchapterIdx));
  };

  DecisionAidService.prototype.deleteSubchapter = function(daId, chapterId, subchapterIdx) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('deleteSubchapter', chapterId, subchapterIdx));
  };

  DecisionAidService.prototype.addNewSubchapter = function(daId, chapterId, subchapterIdx, position, newSubchapterData) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('addNewSubchapter', chapterId, subchapterIdx, position, newSubchapterData));
  };

  DecisionAidService.prototype.updateOutcome = function(daId, outcomeId, payload, propagateAbsDenominatorChange) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('updateOutcome', outcomeId, payload, propagateAbsDenominatorChange));
  };

  DecisionAidService.prototype.updateOutcomeComment = function(daId, outcomeId, comment) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('updateOutcomeComment', outcomeId, comment));
  };

  DecisionAidService.prototype.updateQuestion = function(daId, key, value) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('updateQuestion', key, value));
  };

  DecisionAidService.prototype.markRiskValuePreferred = function(daId, outcomeId, riskKey, cellValue) {
    return DecisionAidDoc.at(currentProject(), daId).updateAsInstance(callMethod('markRiskValuePreferred', outcomeId, riskKey, cellValue));
  };

  return DecisionAidService;

})();
