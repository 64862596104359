Translation = require 'components/mixins/translation'

{ func, string } = PropTypes

ProjectInputName = createReactClass
  displayName: "ProjectInputName"

  mixins: [Translation('projects:new_project_dialog')]

  propTypes:
    onChange: func.isRequired,
    value: string,

  getDefaultProps: ->
    value: null

  render: ->
    <label>
      <span>{@i18n 'name'}</span>
      <div className='input-el'>
        <input autoFocus
          type='text'
          onChange={@props.onChange}
          value={@props.projectName}
          placeholder={@i18n '../new_project'}
        />
      </div>
    </label>

module.exports = ProjectInputName
