var checkedControlsValidation, controlRiskNames, number, optional, optionalNumber, shape, validationSpec;

shape = require('lib/db_docs/field_types/shape');

number = require('lib/db_docs/field_types/built_in_types').number;

optional = shape.typeDecorators.optional;

optionalNumber = optional(number);

controlRiskNames = ['calculatedControl', 'lowControl', 'moderateControl', 'highControl'];

checkedControlsValidation = function(checked, props, changedControlName) {
  var checkedControlsCount, editMetadata, editedAttributes, outcomeAttrs, _ref;
  editMetadata = props.editMetadata, outcomeAttrs = props.outcomeAttrs;
  editedAttributes = (_ref = editMetadata != null ? editMetadata.get('editedAttributes') : void 0) != null ? _ref : Immutable.Map();
  checkedControlsCount = _.reduce(controlRiskNames, function(acc, controlName) {
    if (controlName === changedControlName) {
      return acc;
    }
    if (editedAttributes.get(controlName, outcomeAttrs[controlName])) {
      return acc + 1;
    } else {
      return acc;
    }
  }, checked ? 1 : 0);
  if (checkedControlsCount > 3) {
    return [false, $.t('es:outcome/errors:model.control_risk_set_max_3')];
  } else if (checkedControlsCount === 0) {
    return [false, $.t('es:outcome/errors:model.control_risk_not_set')];
  } else {
    return [true, null];
  }
};

validationSpec = {
  controlRiskCount: checkedControlsValidation,
  controlCount: optionalNumber,
  controlTotal: optionalNumber,
  lowControlRisk: optionalNumber,
  moderateControlRisk: optionalNumber,
  highControlRisk: optionalNumber
};

module.exports = validationSpec;
