var INITIAL_STATE, NMAImportModalActions, generateStore, getMappingStepData;

generateStore = require('stores/utils/generate_store');

getMappingStepData = require('lib/nma_helper').getMappingStepData;

NMAImportModalActions = require('actions/nma_import_modal_actions');

INITIAL_STATE = Immutable.fromJS({
  isOpen: false,
  isParsing: false,
  isImporting: false,
  outcome: Immutable.Map(),
  fileData: {
    file: null,
    fileName: ''
  },
  mappingStepData: {
    data: Immutable.List(),
    mapping: Immutable.Map(),
    matchedInterventions: Immutable.List(),
    unmatchedInterventions: Immutable.List()
  },
  currentStep: 'upload'
});

module.exports = generateStore({
  name: 'NMAImportModalStore',
  initialState: INITIAL_STATE,
  boundActions: [NMAImportModalActions],
  methods: {
    onOpenImportDialog: function(outcome) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('isOpen', true);
        return state.set('outcome', outcome);
      }));
    },
    onCloseImportDialog: function() {
      this.setState(this.state.set('isOpen', false));
      return setTimeout((function(_this) {
        return function() {
          return _this.setState(INITIAL_STATE);
        };
      })(this), 300);
    },
    onSetImportFile: function(file) {
      var fileName;
      fileName = file.name;
      return this.setState(this.state.set('fileData', Immutable.fromJS({
        file: file,
        fileName: fileName
      })));
    },
    onResetImportFile: function() {
      return this.setState(this.state.setIn('fileData', Immutable.fromJS({
        file: null,
        fileName: ''
      })));
    },
    onParseImportFileSuccess: function(_arg) {
      var mappingData, parsedData, question;
      parsedData = _arg.parsedData, question = _arg.question;
      mappingData = getMappingStepData(question, Immutable.fromJS(parsedData));
      return this.setState(this.state.withMutations(function(state) {
        state.set('mappingStepData', mappingData);
        state.set('isParsing', false);
        return state.set('currentStep', 'mapping');
      }));
    },
    onParseImportFileError: function() {
      return this.setState(this.state.set('isParsing', false));
    },
    onGoBackOnImportDialog: function() {
      return this.setState(this.state.set('currentStep', 'upload'));
    },
    onImport: function() {
      return this.setState(this.state.set('isImporting', true));
    },
    onImportFinished: function() {
      this.setState(this.state.set('isOpen', false));
      return setTimeout((function(_this) {
        return function() {
          return _this.setState(INITIAL_STATE);
        };
      })(this), 300);
    },
    onSetImportMappingEntry: function(_arg) {
      var interventionId, value;
      interventionId = _arg.interventionId, value = _arg.value;
      return this.setState(this.state.setIn(['mappingStepData', 'mapping', interventionId], value));
    },
    isImportModalOpen: function() {
      return this.state.get('isOpen');
    },
    isImporting: function() {
      return this.state.get('isImporting');
    },
    isParsing: function() {
      return this.state.get('isParsing');
    },
    getImportFileData: function() {
      return this.state.get('fileData');
    },
    getImportCurrentStep: function() {
      return this.state.get('currentStep');
    },
    getImportMappingStepData: function() {
      return this.state.get('mappingStepData');
    },
    getImportOutcome: function() {
      return this.state.get('outcome');
    }
  }
});
