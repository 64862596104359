ContentEditable = require 'components/common/content_editable'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
Button = require 'components/common/button'
ReferencesInsertModal = require 'components/etd/references_insert_modal'
ReferenceCitationMixin = require 'components/mixins/reference_citation_mixin'
GdtEditor = Editor.Editor

RICH_FORMAT_FIELDS = ['mainOutcomes', 'anticipatedOutcomes', 'background', 'coi']

EtdHeaderEditableCell = createReactClass
  displayName: 'EtdHeaderEditableCell'

  propTypes:
    updateHeaderFieldContent: PropTypes.func

  mixins: [CustomRenderMixin, Translation('es:question'), ReferenceCitationMixin()]

  _inputElRef: (el) ->
    @gdtEditor = el

  focus: ->
    @gdtEditor?.focus()

  rednerInputElement: ->
    { sectionId, content, inAutoMode } = @props

    if sectionId in ['intervention', 'comparison']
      <input ref={@_inputElRef} type="text" value={content} disabled />
    else if sectionId in RICH_FORMAT_FIELDS
      isBackgroundField = sectionId is 'background'
      <GdtEditor
        ref={@_inputElRef}
        readOnly={inAutoMode}
        editorContent={content}
        onBlur={@updateContent}
        customControls={[@getReferenceToggleSpec()] if isBackgroundField}
        customDecorators={@getReferenceCitationDecorator() if isBackgroundField}
      />
    else if inAutoMode
      <span className='textarea-content'>{content}</span>
    else
      <textarea
        ref={@_inputElRef}
        defaultValue={content}
        onBlur={@updateContent}
      />

  updateContent: ->
    content = @gdtEditor.getEditorContent?() or @gdtEditor.value
    if @props.content isnt content
      @props.updateHeaderFieldContent { content, sectionId: @props.sectionId }

  render: ->
    withSwitchButton = @props.sectionId in ['population', 'mainOutcomes', 'setting']
    switchButtonLabel = if @props.inAutoMode then 'switch_to_manual' else 'switch_to_auto'
    editableClassNames = classNames 'edit-container',
      'with-switch': withSwitchButton
      'in-auto-mode': @props.inAutoMode
      'rows-1': @props.sectionId in ['purpose', 'linkedTreatments']
      'rows-2': @props.sectionId in ['setting', 'perspective', 'subgroups']
      'rows-4': @props.sectionId in ['anticipatedOutcomes', 'mainOutcomes', 'background', 'coi']

    if @props.isEditing
      <td rowSpan={@props.rowSpan}>
        <div className={editableClassNames}>
          {@rednerInputElement()}
        </div>
        {if withSwitchButton
          <Button
            className="btn auto-manual-switch "
            label={@i18n switchButtonLabel}
            onClick={@props.showContentModeSwitchModal}
          />
        }
        {if @state.showReferencesInsert
          <ReferencesInsertModal
            onInsert={@onInsertReferences}
            onClose={@toggleReferencesInsert}
          />
        }
      </td>
    else
      <td className="editable-cell" rowSpan={@props.rowSpan}>
        {if @props.sectionId in RICH_FORMAT_FIELDS
          <GdtEditor
            customDecorators={@getReferenceCitationDecorator()}
            editorContent={@props.content}
            readOnly
          />
        else
          <p dangerouslySetInnerHTML={{__html: @props.content}} />
        }
      </td>

module.exports = EtdHeaderEditableCell
