var Controller, GdtRouter, ModuleController, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Controller = require('./controller');

GdtRouter = require('router');

utils = require('base/lib/utils');

mediator = require('mediator');

module.exports = ModuleController = (function(_super) {
  __extends(ModuleController, _super);

  function ModuleController() {
    this.historyURL = __bind(this.historyURL, this);
    return ModuleController.__super__.constructor.apply(this, arguments);
  }

  ModuleController.prototype.defaultSubmodule = null;

  ModuleController.prototype.viewsForSubmodules = {};

  ModuleController.prototype.historyURL = function(params) {
    return GdtRouter.prototype.routeForModule(this.moduleName, params);
  };

  ModuleController.prototype.initialize = function() {
    var controllerName;
    ModuleController.__super__.initialize.apply(this, arguments);
    mediator.currentQuestionId = null;
    controllerName = _.functionName(this.constructor);
    return this.moduleName = utils.underscorize(controllerName.slice(0, -'controller'.length));
  };

  ModuleController.prototype.index = function(params) {
    var submodule;
    if (this.defaultSubmodule != null) {
      submodule = _.isFunction(this.defaultSubmodule) ? this.defaultSubmodule() : this.defaultSubmodule;
      return this.redirectTo(this.moduleName, 'submodule', _.extend(params, {
        submodule: submodule,
        forceStartup: true
      }));
    }
  };

  ModuleController.prototype.submodule = function(params) {
    var viewForSubmodule, _ref;
    mediator.currentQuestionId = (_ref = params.id) != null ? _ref : null;
    viewForSubmodule = this.viewsForSubmodules[params.submodule];
    if (viewForSubmodule) {
      return this.view = new viewForSubmodule(_(this._viewParams(params)).extend({
        baseUrl: this.historyURL(params),
        moduleName: this.moduleName,
        submodule: params.submodule
      }));
    } else {
      return this._submoduleNotFound(params);
    }
  };

  ModuleController.prototype._viewParams = function(params) {
    return _(params).extend({
      model: mediator.project
    });
  };

  ModuleController.prototype._submoduleNotFound = function(params) {
    return typeof Raven !== "undefined" && Raven !== null ? Raven.captureMessage("No submodule found for url params " + params.submodule) : void 0;
  };

  return ModuleController;

})(Controller);
