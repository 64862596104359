{ bool, string, oneOf, func, arrayOf, oneOfType, objectOf, instanceOf } = PropTypes
{ useI18n, useCoffeeMemo } = require 'lib/react_utils'
{ isImmutable } = require 'lib/immutable_utils'
Select = require 'components/common/select_custom'
mediator = require 'mediator'
{ MDG_WORKSPACE } = require 'lib/mdg_helper'

STATUSES_LIST = ['inProgress', 'inReview', 'done']
MDG_STATUSES_LIST = ['new', 'inProgress', 'inReview', 'done']

QuestionStatusForm = (props) ->
  {
    completed
    locked
    currentStatus
    statusesList
    groupKey
    toggleCompleted
    toggleLocked
    updateStatus
    withCompleted
    accessRights
  } = props


  editable = mediator.user.isAdmin() or _.isEmpty(accessRights) or
    _.intersection(accessRights, mediator.user.getAccessRights()).length > 0

  i18n = useI18n 'es:question.statuses'
  partLabel = _.string.underscored(groupKey.replace('Status', ''))
  activeWorkspaceIsMDG = mediator.activeWorkspace is MDG_WORKSPACE

  preparedStatusesList = if isImmutable(statusesList) and statusesList.isEmpty()
    Immutable.fromJS(if activeWorkspaceIsMDG then MDG_STATUSES_LIST else STATUSES_LIST)
  else if _.isArray(statusesList)
    Immutable.fromJS statusesList
  else
    statusesList

  <div className="question-progress-form #{_.string.dasherize(partLabel)}">
    <div className='question-progress-form__title'>
      {if partLabel in ['mda_topics', 'evidence_table', 'presentation', 'recommendation']
        i18n("labels.#{partLabel}")
      else
        i18n("labels.group_label", { groupName: i18n "labels.#{partLabel}" })}
    </div>
    <div className='question-progress-form__inputs'>
      {if activeWorkspaceIsMDG
        <SelectStatuses
          groupKey={groupKey}
          currentStatus={currentStatus}
          statusesList={preparedStatusesList}
          updateStatus={updateStatus}
          editable={editable}
        />
      else
        <RadioInputStatuses
          groupKey={groupKey}
          statusesList={preparedStatusesList}
          currentStatus={currentStatus.get('status', '')}
          updateStatus={updateStatus}
          editable={editable}
        />
      }
    </div>
    {mediator.user.isAdmin() and <CompletedLockedBar
      completed={completed}
      locked={locked}
      toggleCompleted={toggleCompleted}
      toggleLocked={toggleLocked}
      withCompleted={withCompleted}
    />}
  </div>

QuestionStatusForm.propTypes =
  completed: bool,
  locked: bool,
  groupKey: oneOf([
    'mdaTopicsStatus'
    'mdgResearchTeam'
    'mdgReedGroup'
    'evidenceTableStatus'
    'recommendationStatus'
    'presentationStatus'
  ]).isRequired
  currentStatus: instanceOf(Immutable.Map)
  statusesList: instanceOf(Immutable.List)
  toggleCompleted: func,
  toggleLocked: func,
  updateStatus: func.isRequired,
  withCompleted: bool

QuestionStatusForm.defaultProps =
  completed: false
  locked: false
  toggleCompleted: () -> null
  withCompleted: true
  showLabel: true
  statusesList: Immutable.fromJS(STATUSES_LIST)

SelectStatuses = ({ groupKey, statusesList, updateStatus, optionsList, currentStatus, editable }) ->
  i18n = useI18n 'es:question.statuses'
  activeWorkspaceIsMDG = mediator.activeWorkspace is MDG_WORKSPACE
  optionsList = if activeWorkspaceIsMDG then MDG_STATUSES_LIST else STATUSES_LIST

  options = useCoffeeMemo [optionsList], -> _.map optionsList, (option) ->
    {
      text: i18n "status.#{_.string.underscored(option)}"
      value: option
    }

  <div>
    {statusesList.filter((_, key) ->
      key not in ['done', 'completed', 'evidenceTable']
    ).map (status, key) ->
      selectedOption = currentStatus.getIn([key, 'status'], '')
      selectClasses = classNames 'status-selector',
        "status-selector--#{_.string.dasherize(selectedOption)}"

      <div key={key} className="flex flex-row items-center mb-10">
        <div className="selector-label">{i18n("labels.#{_.string.underscored(key)}")}</div>
        <Select
          disabled={not editable}
          popoverClassName={selectClasses}
          onChange={(selectedOption) -> updateStatus([groupKey, key], selectedOption)()}
          selected={selectedOption}
          options={options}
        />
      </div>
    .toList()}
    <div className='question-progress-form__completed'>
      {if groupKey is 'mdgResearchTeam'
        <label className="done-label">
          <input
            type="checkbox"
            disabled={not editable}
            checked={currentStatus.getIn(['evidenceTable', 'status']) is 'done'}
            onChange={updateStatus([groupKey, 'evidenceTable'], 'done')}
          />
          <span>
            {i18n("labels.evidence_table_done")}
          </span>
        </label>

      else
        <label className="done-label">
          <input
            type="checkbox"
            disabled={not editable}
            checked={currentStatus.get('status', '') is 'done'}
            onChange={updateStatus([groupKey], 'done')}
          />
          <span>
            {i18n("labels.group_done", {
              groupName: i18n("labels.#{_.str.underscored(groupKey)}")
            })}
          </span>
        </label>
      }
    </div>
  </div>

RadioInputStatuses = ({ statusesList, groupKey, currentStatus, updateStatus, editable }) ->

  i18n = useI18n 'es:question.statuses'
  <div>
    {
      statusesList.map (status) ->
        <label key={status}>
          <input
            checked={status is currentStatus}
            disabled={not editable}
            onChange={updateStatus(groupKey, status)}
            onClick={if currentStatus is status then updateStatus(groupKey, status) else undefined}
            type="radio"
          />
          <span className="question-progress-form__box
            question-progress-form__box--#{_.string.dasherize(status)}">
            {i18n("status.#{_.string.underscored(status)}")}
          </span>
        </label>
      .toList()
    }
  </div>

RadioInputStatuses.propTypes = {
  statusesList: PropTypes.instanceOf(Immutable.Map).isRequired
  groupKey: PropTypes.string.isRequired
  currentStatus: PropTypes.string.isRequired
  updateStatus: PropTypes.func.isRequired
}

CompletedLockedBar = ({ withCompleted, toggleCompleted, toggleLocked, completed, locked }) ->
  i18n = useI18n 'es:question.statuses'
  if withCompleted
    <div className='question-progress-form__completed'>
      <label>
        <input type="checkbox" checked={completed} onChange={toggleCompleted} />
        <span>
          {i18n("labels.mark_as_completed")}
        </span>
      </label>
      <label>
        <input type="checkbox" checked={locked} onChange={toggleLocked} />
        <span>
          {i18n("labels.lock_question_from_editing")}
        </span>
      </label>
    </div>
  else null

CompletedLockedBar.propTypes =
  completed: PropTypes.bool.isRequired
  locked: PropTypes.bool.isRequired
  toggleCompleted: PropTypes.func.isRequired
  toggleLocked: PropTypes.func.isRequired
  withCompleted: PropTypes.bool.isRequired

module.exports = QuestionStatusForm
