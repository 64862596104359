var Migration, QUESTIONS_DB_VIEW, QuestionsOrderNumberMigration, W, mediator, migrationName,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

QUESTIONS_DB_VIEW = require('lib/questions_helper').QUESTIONS_DB_VIEW;

migrationName = 'questions_order_number_migration_to_groups_doc';

module.exports = QuestionsOrderNumberMigration = (function(_super) {
  __extends(QuestionsOrderNumberMigration, _super);

  function QuestionsOrderNumberMigration() {
    QuestionsOrderNumberMigration.__super__.constructor.call(this, false);
  }

  QuestionsOrderNumberMigration.prototype.up = function(project, colls) {
    var adapter, projectId;
    QuestionsOrderNumberMigration.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    return adapter.fetchWithView(projectId, 'questions', QUESTIONS_DB_VIEW).then(function(questions) {
      return _.reduce(questions, function(acc, q, idx) {
        var _ref;
        acc[q['_id']] = (_ref = q.orderNumber) != null ? _ref : idx + 1;
        return acc;
      }, {});
    }).then(function(orderingToMigrate) {
      return adapter.updateOrCreate(projectId, 'comparisons_question_groups', function(doc) {
        var orderNumber, qId;
        if (doc.questionsOrdering == null) {
          doc.questionsOrdering = {};
        }
        for (qId in orderingToMigrate) {
          orderNumber = orderingToMigrate[qId];
          if (!(qId in doc.questionsOrdering)) {
            doc.questionsOrdering[qId] = orderNumber;
          }
        }
        return _.extend(doc, {
          rev_author: "project_migration/" + migrationName
        });
      });
    });
  };

  return QuestionsOrderNumberMigration;

})(Migration);
