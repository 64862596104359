var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "  <div class="
    + escapeExpression(lambda((data && data.key), depth0))
    + ">\n    <label\n      class=\"section-label ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.disabled : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.checked : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n      for="
    + escapeExpression(lambda((data && data.key), depth0))
    + "\n    >\n      <input\n        id="
    + escapeExpression(lambda((data && data.key), depth0))
    + "\n        type=\"radio\"\n        value="
    + escapeExpression(lambda((data && data.key), depth0))
    + "\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.disabled : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.checked : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n      />\n      <span class=\"title\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, (depth0 != null ? depth0.labelKey : depth0), {"name":"i18n","hash":{},"data":data})))
    + "\n      </span>\n      <span class=\"judgment\">\n        <span class=\"judgment-toggle-with-text\">\n          <label class=\"switcher switcher--judgment-pane\"\n            ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.judgment : depth0)) != null ? stack1.disabled : stack1), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            data-for=\""
    + escapeExpression(lambda((data && data.key), depth0))
    + "-judgment\"\n          >\n            <input type=\"checkbox\" disabled=\"disabled\"\n              ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.judgment : depth0)) != null ? stack1.checked : stack1), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            >\n            <div class=\"slider round\"></div>\n          </label>\n        </span>\n      </span>\n      <span class=\"results ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.disabled : stack1), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n        <span class=\"results-toggle-with-text\">\n          <label class=\"switcher switcher--results-pane ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.disabled : stack1), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n            data-for=\""
    + escapeExpression(lambda((data && data.key), depth0))
    + "-results\"\n            ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.disabled : stack1), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n          >\n            <input type=\"checkbox\" disabled=\"disabled\"\n              ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.checked : stack1), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n            >\n            <div class=\"slider round\"></div>\n          </label>\n        </span>\n      </span>\n    </label>\n  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "disabled";
  },"4":function(depth0,helpers,partials,data) {
  return "selected";
  },"6":function(depth0,helpers,partials,data) {
  return "checked";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "voting:panel_voice.labels", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}