DisabledElementTooltip = require 'components/common/disabled_element_tooltip'
RadioInput = require 'components/common/radio_input'
TextWithHelpLink = require 'components/administration/voting/voting_text_with_links'
VotingStart = require 'components/administration/voting/voting_start_block'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

VotingPhase1Start = createReactClass
  displayName: 'VotingPhase1Start'

  propTypes:
    selectedJudgmentsOption: PropTypes.string.isRequired
    selectedEtdPartsOption: PropTypes.string.isRequired
    updateVotingOption: PropTypes.func.isRequired

  mixins: [
    CustomRenderMixin
    Translation('voting:voting')
  ]

  # GETTERS, HELPERS
  _getJudgmentsOptions: ->
    <div className="voting-options">
      <label>
        <RadioInput name="judgment-option" value="all"
          checked={@props.selectedJudgmentsOption is 'all'}
          onChange={@changeJudgmentOption 'all'}
        />
        <b>{@i18n 'all'}</b> {@i18n 'judgments'} <b>{@i18n 'proposed'} </b>
        ({@i18n 'agree_disagree_voting'})
      </label>
      <label>
        <RadioInput name="judgment-option" value="none"
          checked={@props.selectedJudgmentsOption is 'none'}
          onChange={@changeJudgmentOption 'none'}
        />
        <b>{@i18n 'none'}</b> {@i18n 'judgments'}<b> {@i18n 'proposed'} </b>
        ({@i18n 'full_scale_voting'})
      </label>
      <label>
        <RadioInput name="judgment-option" value="some"
          checked={@props.selectedJudgmentsOption is 'some'}
          onChange={@changeJudgmentOption 'some'}
        />
        <b>{@i18n 'some'}</b> {@i18n 'judgments'}<b> {@i18n 'proposed'} </b>
        ({@i18n 'combined_voting'})
      </label>
    </div>

  _getEtdPartsOptions: ->
    tooltipATC = if @props.selectedJudgmentsOption isnt 'all' then @i18n 'tooltip_atc' else ''

    <div className="voting-options">
      <label>
        <RadioInput name="etd-part-option" value="A"
          checked={@props.selectedEtdPartsOption is 'A'}
          onChange={@changeEtdPartsOption 'A'}
        />
        {@i18n 'only'} <b>{@i18n '/settings:etd_templates.assessment'}</b>
      </label>
      <label>
        <RadioInput name="etd-part-option" value="AT"
          checked={@props.selectedEtdPartsOption is 'AT'}
          onChange={@changeEtdPartsOption 'AT'}
        />
        <b>{@i18n '/settings:etd_templates.assessment'}</b> {@i18n 'and'}
        <b> {@i18n 'type_of_recommendation'}</b> ({@i18n 'empty'})
      </label>
      <label>
        <DisabledElementTooltip>
          <RadioInput name="etd-part-option" value="ATC"
            title={tooltipATC}
            checked={@props.selectedEtdPartsOption is 'ATC'}
            onChange={@changeEtdPartsOption 'ATC'}
            disabled={@props.selectedJudgmentsOption isnt 'all'}
          />
        </DisabledElementTooltip>
        <b>{@i18n '/settings:etd_templates.assessment'}</b> ({@i18n 'proposed'}) {@i18n 'and'}
        <b> {@i18n 'type_of_recommendation'}</b> ({@i18n 'proposed'}) {@i18n 'and'}
        <b> {@i18n '/settings:etd_templates.conclusions'}</b> ({@i18n 'proposed'})
      </label>
    </div>

  _getVotingSteps: ->
    <ol>
      <li>
        <TextWithHelpLink
          text={@i18n 'phase1_step1_text'}
          links={
            link1: ['votingFormAssessment', @i18n 'assessment_part']
            link2: ['votingFormJudgement', @i18n 'voting_forms_judgment_help_text']
          }
          onInfoLinkClick={@props.onInfoLinkClick}
        />
        {@_getJudgmentsOptions()}
      </li>
      <li>
        <TextWithHelpLink
          text={@i18n 'phase1_step2_text'}
          links={
            link1: ['votingFormAllSections', @i18n 'etd_parts']
            link2: ['votingFormEtdParts', @i18n 'voting_forms_etd_parts_help_text']
          }
          onInfoLinkClick={@props.onInfoLinkClick}
        />
        {@_getEtdPartsOptions()}
      </li>
      <li>
        <span>{@i18n 'which_questions'}</span>
        <p>{@i18n 'required_data_note'}</p>
      </li>
    </ol>

  changeJudgmentOption: (value) -> =>
    @props.updateVotingOption { name: 'selectedJudgmentsOption', value }
    # if current judgment option isn't 'all' and user has previously selected 'ATC' ETD parts
    # option - change it to 'A' since 'ATC' can only be selected when judgment option is 'all'
    if value isnt 'all' and @props.selectedEtdPartsOption is 'ATC'
      @props.updateVotingOption name: 'selectedEtdPartsOption' ,value: 'A'

  changeEtdPartsOption: (value) -> =>
    @props.updateVotingOption { name: 'selectedEtdPartsOption', value }

  render: ->
    <div className="phase1-voting-start">
      <div className="upper-block">
        <p>{@i18n 'voting_description'}</p>
        <p dangerouslySetInnerHTML={{__html: @i18n 'phase1_title'}} />
        {@_getVotingSteps()}
      </div>
      {if @props.questions.size
        <VotingStart
          phase='phase1a'
          projectId={@props.projectId}
          questions={@props.questions}
          questionGroups={@props.questionGroups}
          etds={@props.etds}
          selectedJudgmentsOption={@props.selectedJudgmentsOption}
          selectedEtdPartsOption={@props.selectedEtdPartsOption}
          etdPartLabels={@props.getEtdPartLables @props.selectedEtdPartsOption}
          message={@props.message}
          dueDate={@props.dueDate}
          contactEmail={@props.contactEmail}
          canVotingBeStarted={@props.canVotingBeStarted}
          updateMessageDetails={@props.updateMessageDetails}
          sendVoting={@props.sendVoting}
        />
      else
        <div className="no-questions">{@i18n 'no_questions'}</div>
      }
    </div>

module.exports = VotingPhase1Start
