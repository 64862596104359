var KeyMessageCreatorView, KeyMessageRecommendationView, KeyMessagesController, ModuleController,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

KeyMessageCreatorView = require('views/key_messages/key_message_creator_view');

KeyMessageRecommendationView = require('views/key_messages/key_message_recommendation_view');

module.exports = KeyMessagesController = (function(_super) {
  __extends(KeyMessagesController, _super);

  function KeyMessagesController() {
    return KeyMessagesController.__super__.constructor.apply(this, arguments);
  }

  KeyMessagesController.prototype.viewsForSubmodules = {
    'create': KeyMessageCreatorView,
    'edit': KeyMessageCreatorView,
    'recommendations': KeyMessageRecommendationView
  };

  return KeyMessagesController;

})(ModuleController);
