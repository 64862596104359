Modal = require 'components/common/modal'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
Translation = require 'components/mixins/translation'

AcademicConfirmationModal = createReactClass
  displayName: 'AcademicConfirmationModal'
  mixins: [Translation('subscriptions.academic.confirmation')]

  getInitialState: ->
    affiliations: {}
    institutionName: ''
    submitting: false

  affiliationChanged: (e) ->
    @setState
      affiliations: R.assoc e.target.value, e.target.checked, @state.affiliations

  institutionNameChanged: (e) ->
    @setState institutionName: e.target.value

  submit: ->
    @setState(submitting: true)
    @props.subscriptionService.checkoutAcademic(@state.institutionName)
    .finally => @setState(submitting: false)
    .then =>
      @props.onClose()
      @props.onActivated()
    , @props.onError

  canSubmit: ->
    anyAffiliationChecked = R.compose(R.any(R.identity), R.values)(@state.affiliations)
    institutionNameSet = not R.isEmpty @state.institutionName

    anyAffiliationChecked and institutionNameSet

  render: ->
    <Modal
      isOpen={@props.isOpen}
      onClose={@props.onClose}
      closeButton={true}
      title={@i18n('title')}
      className="academic-confirmation"
      style={overlay: {zIndex: 10001}, content: {
        position: 'absolute'
        top: '50%'
        bottom: 'auto'
        left: '50%'
        right: 'auto'
        transform: 'translate(-50%, -50%)'
      }}
      >
      <div className='content-container'>
        <div className='introduction'>
          {@i18n('terms_introduction')}
        </div>
        <h4>{@i18n 'affiliation'}</h4>
        <ul>
          {for point, i in @i18n('terms')
            <li key={i}>
              <label>
                <input
                  type='checkbox'
                  checked={@state.affiliations[i] ? false}
                  onChange={@affiliationChanged}
                  value={i}
                />
                {point}
              </label>
            </li>
          }
        </ul>
        <h4>
          {@i18n('institution_name')}:<br/>
          <input type="text" value={@state.institutionName} onChange={@institutionNameChanged} />
        </h4>
        <div className="hint">{@i18n('ok_hint')}</div>
        <ApplyCancelButtons
          onApply={@submit}
          onCancel={@props.onClose}
          applying={@state.submitting}
          isSubmitEnabled={!@state.submitting && @canSubmit()}
          applyLabel={@i18n('ok_caption')}
          applyClass="btn-alternative"
          cancelLabel={@i18n('cancel_caption')}
        />
      </div>
    </Modal>

module.exports = AcademicConfirmationModal
