ConnectStore = require 'components/enhancers/connect_store'
etdHelper = require 'lib/etd_helper'
EtdsStore = require 'stores/etds_store'
Spinner = require 'components/common/spinner'
mediator= require 'mediator'
RecommendationContent =
  require 'components/dissemination/acp_visual_guidelines/recommendation_content'

storeConnector =
  EtdsStore: (Store, props) ->
    isFetching: Store.isFetching()
    etd: Store.getRecommendation(props.recommendationId.split('_')?[0])

RecommendationItem = ({ populationId, section, isFetching, recommendationId, etd }) ->
  return <Spinner /> if isFetching
  return null if etd.isEmpty()

  hasMultipleRecommendations = etd.hasIn(['templateData', 'conclusions', 'sections', 'multipleRecommendations'])
  conclusionSections = etd.getIn ['templateData', 'conclusions', 'sections'], Immutable.Map()

  recommendation = if hasMultipleRecommendations
    conclusionSections.getIn(['multipleRecommendations', 'recommendations'], Immutable.List())
    .find (rec) ->
      rec.get('_id') is recommendationId.split('_')?[1]
    , null, Immutable.Map()
  else
    conclusionSections.get('recommendation', Immutable.Map())

  typeOfRecommendation = if hasMultipleRecommendations
    recommendation.get('selectedOption')
  else
    conclusionSections.get(
      etdHelper.getCurrentTORSectionId(conclusionSections)
    ).get('selectedOption')

  strengthOfRecommendation = switch typeOfRecommendation
    when 'recommend', 'recommend_against' then 'strong'
    when 'suggest_against', 'suggest_either', 'suggest' then 'conditional'
    else ''

  <RecommendationContent
    populationId={populationId}
    recommendation={recommendation}
    recommendationId={recommendationId}
    section={section}
    strengthOfRecommendation={strengthOfRecommendation}
  />


module.exports = ConnectStore RecommendationItem, [EtdsStore], storeConnector
