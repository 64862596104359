Button = require 'components/common/button'
Translation = require 'components/mixins/translation'

EditButton = createReactClass
  displayName: 'EditButton'

  mixins: [Translation()]

  propTypes:
    onClick: PropTypes.func.isRequired
    title: PropTypes.string

  render: ->
    title = @props.title ? @i18n 'actions.edit'
    classes = classNames "edit", @props.className
    <Button {...@props} className={classes} title={title} />

module.exports = EditButton
