ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ProjectNameInput = require 'components/projects/project_name_input'
TemplateTypeSelect = require 'components/projects/template_type_select'
Translation = require 'components/mixins/translation'
W = require 'when'
mediator = require 'mediator'
{ bool, func, string } = PropTypes

NewOrganizationProjectModal = createReactClass
  displayName: 'NewOrganizationProjectModal'

  propTypes:
    creating: bool.isRequired
    onStart: func.isRequired
    onCancel: func.isRequired
    savingError: string
    organizationId: string.isRequired

  mixins: [Translation('projects:new_project_dialog')]

  getInitialState: ->
    projectName: ''
    selectedLicenseType: null
    selectedTemplateType: null

  onStart: ->
    modelParams =
      name: _.string.escapeHTML @state.projectName
      organization: @state.selectedOrganization
      # TODO: uncomment below line when license descriptions are ready
      # license: @state.selectedLicenseType

    if mediator.services.switches.isOn 'docSectionsTemplate'
      _.extend modelParams, docSectionsTemplateType: @state.selectedTemplateType

    organizationParams =
      organizationId: @props.organizationId
      members: [] # TODO: Add members here that will receive project as admins

    @props.onStart modelParams, organizationParams

  isCreateEnabled: ->
    # TODO add `and @state.selectedLicenseType` when license descriptions are ready

    { selectedTemplateType, projectName } = @state

    if mediator.services.switches.isOn 'docSectionsTemplate' and not selectedTemplateType
      return false

    not _.isEmpty(projectName)

  onChangeName: (e) ->
    @setState projectName: e.target.value

  onTemplateTypeSelect: (value) ->
    @setState selectedTemplateType: value

  onLicenseOptionSelect: (value) ->
    @setState selectedLicenseType: value

  render: ->
    <div className='fields-container'>
      {if @props.savingError
        <div className='error-message'>{@props.savingError}</div>
      }
      <div className='field'>
        <ProjectNameInput onChange={@onChangeName} projectName={@state.projectName} />
      </div>
      {mediator.services.switches.isOn('docSectionsTemplate') &&
        <div className='field'>
          <TemplateTypeSelect
            onTemplateTypeSelect={@onTemplateTypeSelect}
            selected={@state.selectedTemplateType}
          />
        </div>
      }
      {### TODO: uncomment below line when license descriptions are ready
        <div className='field'>
          <ProjectLicenceSelect
            onLicenseOptionSelect={@onLicenseOptionSelect}
            selectedLicenseType={@props.selectedLicenseType}
          />
        </div>
      ###}
      <ApplyCancelButtons
        isSubmitEnabled={@isCreateEnabled()}
        applyLabel={@i18n 'create'}
        onApply={@onStart}
        onCancel={@props.onCancel}
        applying={@props.creating}
      />
    </div>

module.exports = NewOrganizationProjectModal
