QuestionsListEditable = require 'components/scope/outcomes/question_list_editable'
QuestionGroupTitleWithButtons =
  require 'components/scope/outcomes/question_group_title_with_buttons'
EditableOutcomesList = require 'components/scope/outcomes/editable_outcomes_list'
OutcomeDropTarget = require 'components/scope/outcomes/outcome_drop_target'
DragDropTypes = require 'components/scope/outcomes/drag_drop_types'
{ dragSpec, dropSpec, dragCollect, dropCollect } =
  require 'components/scope/outcomes/question_group_dnd_spec'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
DragSource = ReactDnD.DragSource
DropTarget = ReactDnD.DropTarget

DraggableQuestionGroup = createReactClass
  displayName: 'DraggableQuestionGroupComponent'
  propTypes:
    onCopyOutcome: PropTypes.func.isRequired
    undoItemMovement: PropTypes.func.isRequired
    updateQuestionGroupTitle: PropTypes.func.isRequired
    deleteQuestionGroup: PropTypes.func.isRequired
    data: PropTypes.object.isRequired
    gIndex: PropTypes.number.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
  ]

  updateTitle: (titleText) ->
    # do not process empty title or same title
    return unless titleText.trim().length or titleText.trim() is @props.data.get 'name'

    @props.updateQuestionGroupTitle {gIndex: @props.gIndex, titleText}

  render: ->
    { connectDragPreview, connectDragSource, connectDropTarget, isDragging } = @props
    groupClasses = classNames 'question-group', 'dragging': isDragging

    name = @props.data.get 'name'
    questions = @props.data.get 'questions'
    outcomes = @props.data.get 'outcomes'

    connectDragPreview(connectDropTarget(
      <div className={groupClasses}>
        <QuestionGroupTitleWithButtons
          text={name}
          onDelete={=> @props.deleteQuestionGroup @props.gIndex}
          onSave={@updateTitle}
        />
        {connectDragSource(
          <div>
            <QuestionsListEditable
              questions={questions}
              groupName={name}
              gIndex={@props.gIndex}
              hasItemDropped={@props.hasItemDropped}
              undoItemMovement={@props.undoItemMovement}
            />
          </div>
        )}
        <EditableOutcomesList
          draggable={true}
          outcomes={outcomes}
          gIndex={@props.gIndex}
          membersMap={@props.membersMap}
          hasItemDropped={@props.hasItemDropped}
          undoItemMovement={@props.undoItemMovement}
          editingOutcome={@props.editingOutcome}
          onCopyOutcome={@props.onCopyOutcome}
          showingComments={@props.showingComments}
          onCommentToggle={@props.onCommentToggle}
        />
        {if outcomes.isEmpty()
          <OutcomeDropTarget gIndex={@props.gIndex} />
        }
      </div>
    ))

# enable drag sources
DragSourced = DragSource(DragDropTypes.QGROUP, dragSpec, dragCollect) DraggableQuestionGroup
# enable drop targets
DropTargeted = DropTarget(DragDropTypes.QUESTION, dropSpec, dropCollect) DragSourced
# export
module.exports = DropTargeted
