var ALLOWED_EXTENSIONS, GrdImport, RevmanCSVImport, Rm5Import, importUtils,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

GrdImport = require('lib/grd_import');

RevmanCSVImport = require('lib/revman_csv_import');

Rm5Import = require('lib/rm5_import');

ALLOWED_EXTENSIONS = ['rm5', 'grd', 'zip'];

importUtils = {
  getFileName: function(file) {
    return file.name;
  },
  getFileExtension: function(file) {
    var fileName, _ref;
    fileName = importUtils.getFileName(file);
    return (_ref = fileName.split('.').pop()) != null ? _ref.toLowerCase() : void 0;
  },
  isValidFile: function(file) {
    var fileExtension;
    fileExtension = importUtils.getFileExtension(file);
    return __indexOf.call(ALLOWED_EXTENSIONS, fileExtension) >= 0;
  },
  parseFile: function(file) {
    var fileExtension;
    fileExtension = importUtils.getFileExtension(file);
    switch (fileExtension) {
      case 'rm5':
        return Rm5Import.parseFile(file);
      case 'grd':
        return GrdImport.parseFile(file);
      case 'zip':
        return RevmanCSVImport.parseFile(file);
      default:
        throw new Error($.t('projects:import_dialog.invalid_format'));
    }
  }
};

module.exports = importUtils;
