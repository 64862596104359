var ItemWithCommentsMixin;

ItemWithCommentsMixin = {
  getInitialState: function() {
    return {
      showingComments: this.props.showingComments
    };
  },
  componentWillReceiveProps: function(nextProps) {
    if (nextProps.showingComments && !this.state.showingComments) {
      return this.setState({
        showingComments: true
      });
    }
  },
  toggleComments: function() {
    var current, _base;
    current = this.state.showingComments;
    this.setState({
      showingComments: !current
    });
    return typeof (_base = this.props).onCommentToggle === "function" ? _base.onCommentToggle(current) : void 0;
  }
};

module.exports = ItemWithCommentsMixin;
