EllipsizedText = require 'components/common/ellipsized_text'

NumberedItemsList = ({ items }) ->
  <div className="items-list-container--numbered">
  {items.map ({key , text}) ->
    <div className='question items-list-container__item' key={key}>
      <EllipsizedText text={text} height={40} />
    </div>
  }
  </div>

NumberedItemsList.propTypes =
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,

module.exports = NumberedItemsList
