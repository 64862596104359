var AdministrationController, GdtRouter, ModuleController,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

GdtRouter = require('router');

module.exports = AdministrationController = (function(_super) {
  __extends(AdministrationController, _super);

  function AdministrationController() {
    return AdministrationController.__super__.constructor.apply(this, arguments);
  }

  AdministrationController.prototype.index = function(params) {
    return this.redirectTo(GdtRouter.prototype.routeForModule('settings', params));
  };

  return AdministrationController;

})(ModuleController);
