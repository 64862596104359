QuestionItem = require 'components/administration/voting/voting_questions_list_item'
Checkbox = require 'components/common/checkbox_input'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
ToggleableItemsMixin = require 'components/mixins/toggleable_items_mixin'
DueDateMixin = require 'components/mixins/due_date_mixin'
Router = require 'router'

VotingQuestionsList = createReactClass
  displayName: 'VotingQuestionsList'

  propTypes:
    projectId: PropTypes.string.isRequired

  mixins: [
    CustomRenderMixin
    Translation('voting:voting')
    ToggleableItemsMixin('selectedQuestions', 'questions', '*_getValidQuestionsIds')
    DueDateMixin
  ]

  componentWillReceiveProps: (nextProps) ->
    # sanitize the selected questions if disabledQuestions changed. For example, if EtD Part
    # content option changed to 'proposed' then we need to 'uncheck' previously checked questions
    # for those EtDs(questions) which are now disabled
    return unless nextProps.disabledQuestions
    disabledQuestionsChanged = not nextProps.disabledQuestions.equals(@props.disabledQuestions)
    if @state.selectedQuestions.size and disabledQuestionsChanged
      @setState selectedQuestions: @state.selectedQuestions.filterNot (id) ->
        nextProps.disabledQuestions.includes id

  toggleQuestion: (questionId) -> => @toggleItem questionId

  _getValidQuestionsIds: ->
    questions = if @props.disabledQuestions
      @props.questions.filterNot (q) => @props.disabledQuestions.includes q.get 'id'
    else
      @props.questions
    questions.map (q) -> q.get 'id'

  _areAllQuestionsDisabled: ->
    return false unless @props.disabledQuestions?
    return false if @props.disabledQuestions.isEmpty()
    @props.disabledQuestions.size is @props.questions.size

  _isQuestionChecked: (questionId) ->
    @state.selectedQuestions.includes questionId

  _isQuestionDisabled: (questionId) ->
    @props.disabledQuestions?.includes questionId

  _getRecommendationsLink: (questionId) ->
    Router::routeForSubmodule 'evidence-syntheses', 'recommendations', { questionId }

  _questionsMapper: (question) ->
    questionId = question.get 'id'
    <QuestionItem
      key={questionId}
      text={question.get 'text'}
      link={@_getRecommendationsLink questionId}
      isDisabled={@_isQuestionDisabled questionId}
      checked={@_isQuestionChecked questionId}
      toggle={@toggleQuestion questionId unless @props.noToggle}
    />

  _questionsWithInfoMapper: (question) ->
    dueDateTimestamp = question.get 'dueDateTimestamp'
    dueDateClass = @_getDueDateClassNames dueDateTimestamp
    questionId = question.get 'id'

    <QuestionItem
      key={questionId}
      text={question.get 'text'}
      link={@_getRecommendationsLink questionId}
      isDisabled={question.get 'isDisabled'}
      checked={@_isQuestionChecked questionId}
      toggle={@toggleQuestion questionId unless @props.noToggle}
    >
      <div className="votes-stats">{question.get 'votes'}</div>
      {unless @props.votingInfo is 'voteStats'
        <div className={dueDateClass}>{@_timestampToDate dueDateTimestamp}</div>
      }
    </QuestionItem>

  _getMapperFn: ->
    # if provided - provides to map over sorted questions. Useful if you want to updated sorted
    # questions with some props (e.g. isDisabled for toggleble questions)
    @props.groupingMapper

  _filterGroupedQuestions: (questions, groupQuestionsIds) ->
    mapperFn = @_getMapperFn()
    filtered = questions.filter (question) -> groupQuestionsIds.has question.get('id')
    if mapperFn then filtered.map mapperFn else filtered

  _filterNoGroupQuestions: (questions, questionGroups) ->
    mapperFn = @_getMapperFn()
    filtered = questions.filterNot (question) ->
      questionGroupId = questionGroups.getIn ['mapping', question.get 'id']
      return false unless questionGroupId
      questionGroups.get('groups').some (group) -> group.get('_id') is questionGroupId
    if mapperFn then filtered.map mapperFn else filtered

  _sortQuestions: (questions = @props.questions, questionGroups = @props.questionGroups) ->
    if questionGroups and questionGroups.get('mapping')?
      questionsByGroup = questionGroups.get('mapping').groupBy (groupId) -> groupId

      groupedQuestions: questionGroups.get('groups').reduce (result, groupData) =>
        groupId = groupData.get '_id'
        groupQuestionsIds = questionsByGroup.get groupId, Immutable.List()
        groupQuestions = @_filterGroupedQuestions questions, groupQuestionsIds
        if groupQuestions.isEmpty()
          result
        else
          result.push groupData.set 'questions', groupQuestions
      , Immutable.List()
      noGroupQuestions: @_filterNoGroupQuestions questions, questionGroups
    else
      groupedQuestions: null
      noGroupQuestions: if @_getMapperFn() then questions.map @_getMapperFn() else questions

  getSelectedQuestionsIds: ->
    @state.selectedQuestions

  resetSelectedQuestions: ->
    @uncheckAllItems()

# RENDER
  renderGroupedQuestions: (groups) ->
    return null if _.isEmpty groups
    mapperFn = if @props.votingInfo then @_questionsWithInfoMapper else @_questionsMapper

    groups.map (group, idx) =>
      <li className="question-group" key={idx}>
        <div className="caption">
          <div className="ellipsis-wrapper"><span>{group.get 'caption'}</span></div>
        </div>
        <ul className="grouped-questions ui-sortable">
          {group.get('questions').map mapperFn}
        </ul>
      </li>

  renderNoGroupQuestions: (questions) ->
    return null unless questions
    mapperFn = if @props.votingInfo then @_questionsWithInfoMapper else @_questionsMapper

    <li className="questions-wo-group">
      <ul className="grouped-questions ui-sortable">
        {questions.map mapperFn}
      </ul>
    </li>

  render: ->
    {groupedQuestions, noGroupQuestions} = @_sortQuestions()

    <div className="questions-block">
      <div className="header">
        {unless @props.noToggle
          <label>
            <Checkbox checked={@_areAllItemsSelected()}
              disabled={@_areAllQuestionsDisabled()}
              onChange={@toggleAllItems}
            />
            {@i18n 'select_all'}
          </label>
        }
        {if @props.votingInfo is 'all'
          <div className="voting-info">
            <div className="votes-stats">{@i18n 'voted'}</div>
            <div className="due-date">{@i18n 'modals.due_date'}</div>
          </div>
        else if @props.votingInfo is 'voteStats'
          <div className="voting-info-only">
            {@i18n 'voted'}
          </div>
        }
      </div>
      <hr />
      <ul className="standard-list group-list">
        {@renderGroupedQuestions groupedQuestions}
        {@renderNoGroupQuestions noGroupQuestions}
      </ul>
    </div>

module.exports = VotingQuestionsList
