CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
SummaryBlock = require 'components/administration/voting/voting_summary_block'

VotingSummary = createReactClass
  displayName: 'VotingSummaryTab'
  mixins: [
    CustomRenderMixin
    Translation('voting:voting')
  ]

  _getGroupResultsByEtdPart: ->
      # first - group by Etd Parts groups. There are 3 Etd Parts groups:
      # - 'ATC', which covers Assessment and Type of Recommendation and Conclusions sections of EtD
      # - 'AT', which covers Assessment and Type of Recommendation sections of EtD
      # - 'A', only Assessment ('judgements' in voting results doc)
      @props.votingResults.groupBy (votingResult) =>
        if @props.votingPhase is 'phase1b'
          etdParts = votingResult.getIn(['phase1', 'blocks']).keySeq()
          if etdParts.includes 'conclusions'
            'ATC'
          else if etdParts.includes 'recommendation'
            'AT'
          else
            'A'
        else
          etdParts = votingResult.getIn(['phase2', 'blocks']).keySeq()
          if etdParts.includes 'recommendation'
            'TC'
          else
            'C'
      # groups must be ordered in a sequence: A, AT, ATC
      .sortBy (votingResults, etdGroup) -> etdGroup.length

  _getResultsQuestions: (votingResults) ->
    # pick corresponding questions with etd ids
    @props.questions.filter (q) ->
      qEtdIds = q.get 'etds'
      qEtdIds.isSubset votingResults.keySeq()

  render: ->
    groupedResults = @_getGroupResultsByEtdPart()

    <div className="voting-summary-container">
      <div className="section-text">{@i18n 'question_actions_text'}</div>
      <div className="etd-parts-summaries">
        {groupedResults.map (votingResults, etdGroup) =>
          <SummaryBlock
            projectId={@props.projectId}
            etdGroup={etdGroup}
            votingPhase={@props.votingPhase}
            etdPartNames={@props.getEtdPartLables etdGroup}
            questions={@_getResultsQuestions votingResults}
            questionGroups={@props.questionGroups}
            reminderMessage={@props.reminderMessage}
            updateMessageDetails={@props.updateMessageDetails}
            handleSendingReminder={@props.handleSendingReminder}
            handleCloseVoting={@props.handleCloseVoting}
            key={etdGroup}
          />
        .toList()
        }
      </div>
    </div>

module.exports = VotingSummary
