var DiagnosticPooledValuesAsDecimals, Migration, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = DiagnosticPooledValuesAsDecimals = (function(_super) {
  __extends(DiagnosticPooledValuesAsDecimals, _super);

  DiagnosticPooledValuesAsDecimals.prototype.pooledValueAttrs = ['pooledSensitivity', 'pooledSensitivityFrom', 'pooledSensitivityTo', 'pooledSpecificity', 'pooledSpecificityFrom', 'pooledSpecificityTo', 'pooledSensitivityComparison', 'pooledSensitivityComparisonFrom', 'pooledSensitivityComparisonTo', 'pooledSpecificityComparison', 'pooledSpecificityComparisonFrom', 'pooledSpecificityComparisonTo'];

  function DiagnosticPooledValuesAsDecimals() {
    DiagnosticPooledValuesAsDecimals.__super__.constructor.call(this, false);
  }

  DiagnosticPooledValuesAsDecimals.prototype.up = function(project, colls) {
    var asDecimalValue, question;
    DiagnosticPooledValuesAsDecimals.__super__.up.apply(this, arguments);
    asDecimalValue = (function(_this) {
      return function(question) {
        var attr, _i, _len, _ref;
        _ref = _this.pooledValueAttrs;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          attr = _ref[_i];
          question.set(attr, parseFloat((question.get(attr) / 100).toFixed(2)));
        }
        return question.save();
      };
    })(this);
    return W.all((function() {
      var _i, _len, _ref, _results;
      _ref = colls.questions.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        question = _ref[_i];
        if (question.isDiagnostic()) {
          _results.push(asDecimalValue(question));
        }
      }
      return _results;
    })());
  };

  return DiagnosticPooledValuesAsDecimals;

})(Migration);
