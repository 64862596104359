var PooledSensitivitySpecificityView, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/evidence_syntheses/source_of_dx_data');

module.exports = PooledSensitivitySpecificityView = (function(_super) {
  __extends(PooledSensitivitySpecificityView, _super);

  function PooledSensitivitySpecificityView() {
    return PooledSensitivitySpecificityView.__super__.constructor.apply(this, arguments);
  }

  PooledSensitivitySpecificityView.prototype.template = template;

  PooledSensitivitySpecificityView.prototype.container = '.source-of-dx-data-container';

  PooledSensitivitySpecificityView.prototype.autoRender = true;

  PooledSensitivitySpecificityView.prototype.className = 'source-of-dx-data';

  PooledSensitivitySpecificityView.prototype.bindings = function() {
    return {
      'input[name=sourceOfDxData]': {
        observe: 'sourceOfDxData',
        onSet: (function(_this) {
          return function(value) {
            _this.model.save('sourceOfDxData', value);
            return value;
          };
        })(this)
      }
    };
  };

  PooledSensitivitySpecificityView.prototype.afterRender = function() {
    PooledSensitivitySpecificityView.__super__.afterRender.apply(this, arguments);
    return this.stickit(this.model, this.bindings());
  };

  PooledSensitivitySpecificityView.prototype.dispose = function() {
    this.unstickit();
    return PooledSensitivitySpecificityView.__super__.dispose.apply(this, arguments);
  };

  return PooledSensitivitySpecificityView;

})(View);
