var AdaptiveLayout, Ellipsis, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

AdaptiveLayout = require('./adaptive_layout');

mediator = require('mediator');

module.exports = Ellipsis = (function(_super) {
  __extends(Ellipsis, _super);

  function Ellipsis() {
    return Ellipsis.__super__.constructor.apply(this, arguments);
  }

  Ellipsis.prototype.selector = '.caption > *';

  Ellipsis.prototype.row = 2;

  Ellipsis.prototype.fixLayout = function() {
    var args, originalFunction, trait;
    originalFunction = arguments[0], trait = arguments[1], args = 3 <= arguments.length ? __slice.call(arguments, 2) : [];
    Ellipsis.__super__.fixLayout.apply(this, arguments);
    return _.defer((function(_this) {
      return function() {
        var $el, $elem, el, origContent, _i, _len;
        if (_this.disposed) {
          return;
        }
        $elem = _this.$(trait.selector);
        for (_i = 0, _len = $elem.length; _i < _len; _i++) {
          el = $elem[_i];
          $el = $(el);
          origContent = $el.text();
          if (!$el.parent().hasClass('ellipsis-wrapper')) {
            $el.wrap('<div class="ellipsis-wrapper"></div>');
          }
        }
        return $elem.ellipsis({
          row: trait.row
        });
      };
    })(this));
  };

  return Ellipsis;

})(AdaptiveLayout);
