var KEY_MESSAGES_TAGS, QuestionDoc, Router, W, alt, appUtils, generateActions, getCurrentQuestion, mediator, prepareTags;

alt = require('alt');

appUtils = require('lib/app_utils');

generateActions = require('actions/utils/generate_actions');

KEY_MESSAGES_TAGS = require('lib/key_messages_helper').KEY_MESSAGES_TAGS;

mediator = require('mediator');

prepareTags = require('lib/question_tags_helper').prepareTags;

Router = require('router');

QuestionDoc = require('lib/db_docs/question_doc');

W = require('when/when');

getCurrentQuestion = function() {
  return alt.stores.KeyMessagesStore.getCurrentQuestion();
};

module.exports = generateActions({
  autoGenerate: ['addCode', 'addTag', 'destroyCurrentQuestion', 'fetchCodesSuccess', 'fetchOneSuccess', 'fetchOneError', 'removeCode', 'removeTag', 'updateCurrentQuestion', 'updateTag'],
  name: 'KeyMessagesActions',
  actions: {
    create: function() {
      return QuestionDoc.at(mediator.project.id).create({
        type: 'keyMessage',
        question: '',
        includedTags: prepareTags(KEY_MESSAGES_TAGS)
      }).then((function(_this) {
        return function(doc) {
          return _this.actions.goToKeyMessageCreator(doc._id, true);
        };
      })(this))["catch"](appUtils.errorHandler);
    },
    deleteCurrentQuestion: function() {
      return QuestionDoc.at(mediator.project.id, getCurrentQuestion().get('_id'))["delete"]()["catch"](appUtils.errorHandler);
    },
    fetchOne: function(questionId) {
      this.dispatch();
      return QuestionDoc.at(mediator.project.id, questionId).fetch().then((function(_this) {
        return function(res) {
          _this.actions.fetchOneSuccess(res);
          return res;
        };
      })(this))["catch"](this.actions.fetchOneError);
    },
    saveCurrentQuestion: function() {
      return QuestionDoc.at(mediator.project.id).save(getCurrentQuestion().toJS())["catch"](appUtils.errorHandler);
    },
    goToKeyMessageCreator: function(questionId, creating) {
      var route, submodule;
      if (creating == null) {
        creating = false;
      }
      submodule = creating ? 'create' : 'edit';
      route = Router.prototype.getProjectRelativeUrl("/key-messages/" + questionId + "/" + submodule);
      return mediator.publish('!router:route', route);
    },
    goToKeyMessageDetails: function(questionId) {
      var route;
      route = Router.prototype.getProjectRelativeUrl("/key-messages/" + questionId + "/recommendations");
      return mediator.publish('!router:route', route);
    }
  }
});
