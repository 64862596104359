ApprovalQuestion = require 'components/scope/questions/approval_questions_group_question'
ApprovalQuestionWithComments =
  require 'components/scope/questions/approval_questions_group_question_with_comments'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ QUESTION_GENERATION_STEPS } = require 'lib/questions_helper'
DragDropTypes = require 'components/scope/questions/drag_drop_types'
DropTarget = ReactDnD.DropTarget
DnDActions = require 'actions/react_dnd_actions'

targetDropSpec =
  hover: (props, monitor, component) ->
    DnDActions.hover
      type: DragDropTypes.APPROVAL_TAB_QUESTION
      hoverGroupType: props.type
      hoverIndex: 0

targetDropCollect = (connect, monitor) ->
  connectDropTarget: connect.dropTarget()
  isTargetHovered: monitor.isOver() and monitor.canDrop()

ApprovalQuestionsGroup = createReactClass
  displayName: 'ApprovalQuestionsGroup'

  propTypes:
    type: PropTypes.string.isRequired
    questions: PropTypes.instanceOf(Immutable.List).isRequired
    membersMap: PropTypes.instanceOf(Immutable.Map)

  mixins: [
    CustomRenderMixin
    Translation('scope:questions')
  ]

  getGroupTitleText: ->
    <span>
      {@i18n "approval.questions_#{@props.type}"} ({@props.questions.size})
    </span>

  renderFirstRow: ->
    <tbody>
      <tr className='caption'>
        <td className='group-title'>
          {@getGroupTitleText()}
        </td>
        <td className='approved'>
          {@i18n 'approval.approved'}
        </td>
        <td className='comments'>
          {@i18n 'proposal.comments'}
        </td>
      </tr>
    </tbody>

  renderQuestions: ->
    @props.questions.map (question, idx) =>
      hasNoApprovals = question.get('approvals', Immutable.Map()).isEmpty()
      hasNoComments = question.getIn(
        ['memberComments', QUESTION_GENERATION_STEPS[5]]
        Immutable.Map()
      ).isEmpty()
      props = {
        question
        qIndex: idx
        groupType: @props.type
        readOnly: @props.readOnly
        key: idx
      }

      if hasNoApprovals or hasNoComments
        <ApprovalQuestion {...props} />
      else
        <ApprovalQuestionWithComments {...props}
          membersMap={@props.membersMap}
          todos={@props.todos.get question.get('id'), Immutable.List()}
          onCommentToggle={@props.onCommentToggle}
          showingComments={@props.showingComments}
        />
    .toList()

  renderDropTarget: ->
    { connectDropTarget, isTargetHovered } = @props
    tragetClass = classNames 'drop-target', 'hovered': isTargetHovered

    connectDropTarget(
      <tbody className={tragetClass}>
        <tr>
          <td colSpan='3'>
            <div>{@i18n 'drop_here'}</div>
          </td>
        </tr>
      </tbody>
    )

  render: ->
    <div className='approval-questions-group'>
      <table className={@props.type}>
        {@renderFirstRow()}
        {if @props.questions.isEmpty()
          @renderDropTarget()
        else
          @renderQuestions()
        }
      </table>
    </div>

module.exports = DropTarget(
  DragDropTypes.APPROVAL_TAB_QUESTION, targetDropSpec, targetDropCollect
) ApprovalQuestionsGroup
