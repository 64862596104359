var Scrolling, TableScrolling, mediator, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Scrolling = require('./scrolling');

utils = require('base/lib/utils');

mediator = require('mediator');

module.exports = TableScrolling = (function(_super) {
  __extends(TableScrolling, _super);

  function TableScrolling() {
    return TableScrolling.__super__.constructor.apply(this, arguments);
  }

  TableScrolling.prototype._helperElementsAdded = false;

  TableScrolling.prototype.fixLayout = function(originalFunction, trait) {
    var padding;
    if (!this.$('.sticky-header').length) {
      trait._addHelperElements.call(this, trait);
    }
    if (trait._getContentHeight() > trait._getMaxHeight(this)) {
      padding = utils.isWebkit() ? 10 : utils.scrollbarWidth();
      this.$('.sticky-header').css('padding-right', padding);
    } else {
      this.$('.sticky-header').css('padding-right', '0');
    }
    return TableScrolling.__super__.fixLayout.apply(this, arguments);
  };

  TableScrolling.prototype._addHelperElements = function(trait) {
    var header;
    trait.contentEl = this.$(trait.content);
    header = trait.contentEl.find('thead');
    trait.contentEl.before(header);
    return header.wrap('<div class="sticky-header"><table class="standard-table"></table></div>');
  };

  return TableScrolling;

})(Scrolling);
