Rectangle = ({ colorClassName, onClick, selected }) ->
  menuItemClass = classNames 'menu_item_rect', colorClassName, 'selected': selected
  <div className={menuItemClass} onClick={onClick} />

Rectangle.propTypes =
  colorClassName: PropTypes.string.isRequired
  onClick: PropTypes.func.isRequired
  selected: PropTypes.bool.isRequired

module.exports = Rectangle
