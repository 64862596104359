var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "checked";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"directness-radios\">\n  <div class=\"input-block\">\n    <label>\n      <input type=\"radio\" name=\""
    + escapeExpression(((helper = (helper = helpers.radioName || (depth0 != null ? depth0.radioName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"radioName","hash":{},"data":data}) : helper)))
    + "\" value=\"yes\" ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.radioValue : depth0), "yes", {"name":"is","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n      <br>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.yes", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n  </div>\n  <div class=\"input-block\">\n    <label>\n      <input type=\"radio\" name=\""
    + escapeExpression(((helper = (helper = helpers.radioName || (depth0 != null ? depth0.radioName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"radioName","hash":{},"data":data}) : helper)))
    + "\" value=\"probably_yes\" ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.radioValue : depth0), "probably_yes", {"name":"is","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n      <br>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.probably_yes", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n  </div>\n  <div class=\"input-block\">\n    <label>\n      <input type=\"radio\" name=\""
    + escapeExpression(((helper = (helper = helpers.radioName || (depth0 != null ? depth0.radioName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"radioName","hash":{},"data":data}) : helper)))
    + "\" value=\"probably_no\" ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.radioValue : depth0), "probably_no", {"name":"is","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n      <br>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.probably_no", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n  </div>\n  <div class=\"input-block\">\n    <label>\n      <input type=\"radio\" name=\""
    + escapeExpression(((helper = (helper = helpers.radioName || (depth0 != null ? depth0.radioName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"radioName","hash":{},"data":data}) : helper)))
    + "\" value=\"no\" ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.radioValue : depth0), "no", {"name":"is","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n      <br>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.directness_table.no", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}