var Translation, i18nForMixin;

i18nForMixin = require('lib/react_utils').i18nForMixin;

Translation = function(namespace) {
  if (namespace == null) {
    namespace = '';
  }
  return {
    i18n: i18nForMixin(namespace)
  };
};

module.exports = Translation;
