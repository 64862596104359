var Exceptions, GlobalExportActions, QUESTION_GROUPS_DOC_ID, QuestionDoc, W, alt, docSectionsFromTemplateId, mediator;

alt = require('alt');

docSectionsFromTemplateId = require('lib/doc_ids').DOCUMENT_SECTIONS_FROM_TEMPLATE;

Exceptions = require('lib/exceptions');

mediator = require('mediator');

QuestionDoc = require('lib/db_docs/question_doc');

W = require('when/when');

QUESTION_GROUPS_DOC_ID = require('lib/questions_helper').QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE.regular;

GlobalExportActions = (function() {
  function GlobalExportActions() {
    this.generateActions('closeModal', 'updateSelectedQuestions', 'selectDocument', 'selectPresentationAudiences', 'selectQuestionViews', 'exportSuccess', 'exportError', 'fetchSuccess', 'fetchError', 'setExportProgress');
  }

  GlobalExportActions.prototype.openModal = function(projectId) {
    var documentSectionsPromise, groupsPromise, isOnline, projectMetadocPromise, questionsPromise;
    this.dispatch(projectId);
    isOnline = mediator.services.replication.isConnected();
    if (isOnline) {
      projectMetadocPromise = mediator.services.storePersistenceAdapter.fetch(projectId, projectId);
      documentSectionsPromise = mediator.services.storePersistenceAdapter.fetch(projectId, docSectionsFromTemplateId, {
        attachments: true
      })["catch"](function(err) {
        if (err.status !== 404) {
          thow(err);
        }
        return null;
      });
      questionsPromise = QuestionDoc.at(projectId).fetchMany().then(function(questions) {
        return _.reduce(questions, function(acc, question) {
          acc[question._id] = question;
          return acc;
        }, {});
      });
      groupsPromise = mediator.services.storePersistenceAdapter.fetch(projectId, QUESTION_GROUPS_DOC_ID)["catch"](function(err) {
        if (err.status !== 404) {
          throw err;
        }
        return {};
      });
      return W.all([projectMetadocPromise, documentSectionsPromise, questionsPromise, groupsPromise]).then((function(_this) {
        return function(_arg) {
          var documentSections, projectMetadoc, questionGroups, questions;
          projectMetadoc = _arg[0], documentSections = _arg[1], questions = _arg[2], questionGroups = _arg[3];
          return _this.actions.fetchSuccess({
            projectMetadoc: projectMetadoc,
            documentSections: documentSections,
            questions: questions,
            questionGroups: questionGroups
          });
        };
      })(this))["catch"](this.actions.fetchError);
    } else {
      return this.actions.fetchError(new Exceptions.no_connection);
    }
  };

  GlobalExportActions.prototype.selectFormats = function(type, formats) {
    return this.dispatch({
      type: type,
      formats: formats
    });
  };

  GlobalExportActions.prototype["export"] = function(exportOptions) {
    var exportOptionsWithCallback;
    this.dispatch();
    exportOptionsWithCallback = exportOptions.set('callback', this.actions.setExportProgress);
    return mediator.services.globalExportService["export"](exportOptionsWithCallback).then(this.actions.exportSuccess)["catch"](this.actions.exportError);
  };

  return GlobalExportActions;

})();

module.exports = alt.createActions(GlobalExportActions);
