var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "    <div class=\"tab-elem ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depths[1] != null ? depths[1].currentTab : depths[1]), (data && data.index), {"name":"is","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\"\n      data-tab-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n        "
    + escapeExpression(lambda(depth0, depth0))
    + "\n    </div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "current-tab";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "<div class=\"tab-elems\">\n  <div class=\"tab-elems-content\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.tabNames : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n  <div class=\"tab-elems-line\"></div>\n</div>\n<div class=\"tab-container\"></div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}