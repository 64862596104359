QuestionGroupsContainer = require 'components/scope/outcomes/question_groups_scroll_on_drag'
QuestionGroup = require 'components/scope/outcomes/question_group'
DraggableQuestionGroup = require 'components/scope/outcomes/question_group_draggable'
Footer = require 'components/scope/outcomes/footer'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
OutcomeShowCommentsMixin = require 'components/mixins/outcomes_show_comments_mixin'
brainstormingStep = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS[1]
moderationStep = require('lib/questions_helper').OUTCOMES_GENERATION_STEPS[2]

{ Button } = ReactComponents

CompletedListTab = createReactClass
  displayName: 'CompletedListTab'
  propTypes:
    questionGroups: PropTypes.object.isRequired
    onFinishAndSend: PropTypes.func.isRequired
    readOnly: PropTypes.bool.isRequired
    isSendingToMembers: PropTypes.bool.isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:outcomes')
    OutcomeShowCommentsMixin(brainstormingStep, moderationStep)
  ]

  _canGenerateOutcomes: ->
    @props.questionGroups.size

  _questionGroupMapper: (qGroup, idx) ->
    basicProps =
      data: qGroup
      gIndex: idx
      readOnly: @props.readOnly
      onCommentToggle: @onItemCommentToggle
      showingComments: @state.showingAllComments

    if @props.readOnly
      <QuestionGroup {...basicProps}
        key={idx}
        membersMap={@props.membersMap}
      />
    else
      <DraggableQuestionGroup {...basicProps}
        key={idx}
        membersMap={@props.membersMap}
        startQuestionDrag={@props.startQuestionDrag}
        draggingQuestionPosition={@props.draggingQuestionPosition}
        editingOutcome={@props.editingOutcome}
        updateQuestionGroupTitle={@props.updateQuestionGroupTitle}
        deleteQuestionGroup={@props.deleteQuestionGroup}
        undoItemMovement={@props.undoItemMovement}
        onCopyOutcome={@props.onCopyOutcome}
      />

  componentDidUpdate: (prevProps, prevState) ->
    if @props.scrollTo?
      @refs.questionsContainer.getDecoratedComponentInstance().scrollToGroup @props.scrollTo
      @props.afterScroll()

  render: ->
    <div className='completed-list-tab'>
      <div className='information'>
        <div>{@i18n '../questions.moderation.header'}</div>
      </div>
      <div className='top-buttons'>
        <Button
          onClick={@showComments}
          disabled={@isShowCommentsDisabled()}
        >
          {@i18n '../questions.show_all_comments'}
        </Button>
      </div>
      <QuestionGroupsContainer ref='questionsContainer' releaseQuestion={@props.releaseQuestion}>
        {@props.questionGroups.map @_questionGroupMapper}
      </QuestionGroupsContainer>
      <Footer
        disabled={@props.readOnly or not @_canGenerateOutcomes()}
        isSending={@props.isSendingToMembers}
        onQuestionDrop={@props.onNewGroupDrop}
        onAddOutcome={@props.onAddOutcome}
        onFinishAndSend={@props.onFinishAndSend}
      />
    </div>

module.exports = CompletedListTab
