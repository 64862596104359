var ExternalChangeHighlight, ViewTrait,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

ViewTrait = require('base/lib/traits/view_trait');

module.exports = ExternalChangeHighlight = (function(_super) {
  __extends(ExternalChangeHighlight, _super);

  function ExternalChangeHighlight() {
    return ExternalChangeHighlight.__super__.constructor.apply(this, arguments);
  }

  ExternalChangeHighlight.prototype.apply = function(view) {
    ExternalChangeHighlight.__super__.apply.apply(this, arguments);
    this._addFunction(view, 'highlightExternalChange');
    return this._addFunction(view, 'stickit', this.addHighlightBindings);
  };

  ExternalChangeHighlight.prototype.addHighlightBindings = function() {
    var args, binding, bindingSelector, highlightBindings, originalFunction, trait, _ref;
    originalFunction = arguments[0], trait = arguments[1], args = 3 <= arguments.length ? __slice.call(arguments, 2) : [];
    highlightBindings = {};
    _ref = this.bindings;
    for (bindingSelector in _ref) {
      binding = _ref[bindingSelector];
      binding.afterUpdate = 'highlightExternalChange';
    }
    return originalFunction.apply(this, args);
  };

  ExternalChangeHighlight.prototype.highlightExternalChange = function(originalFunction, trait, $el, val, options, changeOptions) {
    if (changeOptions != null ? changeOptions.external : void 0) {
      return $el.fadeOut(500, function() {
        return $(this).fadeIn(500);
      });
    }
  };

  return ExternalChangeHighlight;

})(ViewTrait);
