var ExtractVotingAsSeparateModule, Migration, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = ExtractVotingAsSeparateModule = (function(_super) {
  __extends(ExtractVotingAsSeparateModule, _super);

  function ExtractVotingAsSeparateModule() {
    ExtractVotingAsSeparateModule.__super__.constructor.call(this, false);
  }

  ExtractVotingAsSeparateModule.prototype.up = function(project, colls) {
    var administrationModule, documentSectionModule, indexOfAdministrationModule, modules, votingModuleExists, votingSubmodule;
    ExtractVotingAsSeparateModule.__super__.up.apply(this, arguments);
    administrationModule = _(project.get('modules')).findWhere({
      id: 'administration'
    });
    if (administrationModule) {
      indexOfAdministrationModule = _(project.get('modules')).indexOf(administrationModule);
      administrationModule.id = 'settings';
      votingSubmodule = _(administrationModule.submodules).findWhere({
        id: 'voting'
      });
      if (votingSubmodule) {
        administrationModule.submodules = _(administrationModule.submodules).filter(function(submodule) {
          return submodule.id !== 'voting';
        });
        project.get('modules')[indexOfAdministrationModule] = administrationModule;
      }
      votingModuleExists = _(project.get('modules')).findWhere({
        id: 'voting'
      });
      if (!votingModuleExists) {
        project.get('modules').splice(7, 0, votingSubmodule);
      }
    }
    documentSectionModule = _(project.get('modules')).findWhere({
      id: 'document_sections'
    });
    if (documentSectionModule) {
      modules = _(project.get('modules')).without(documentSectionModule);
      project.set('modules', modules);
      project.get('modules').splice(7, 0, documentSectionModule);
    }
    return project.save();
  };

  return ExtractVotingAsSeparateModule;

})(Migration);
