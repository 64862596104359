DbHelper = require 'base/lib/db_helper'
RadioInput = require 'components/common/radio_input'
Translation = require 'components/mixins/translation'
{ SUBCHAPTER_ILLUSTRATIONS, getSubchapterIllustrationPath } = require 'lib/da_helper'


SubchapterIllustrationOption = createReactClass
  displayName: 'SubchapterIllustrationOption'

  propTypes:
    value: PropTypes.oneOf(SUBCHAPTER_ILLUSTRATIONS).isRequired
    isSelected: PropTypes.bool.isRequired
    onChange: PropTypes.func.isRequired

  mixins: [Translation('da:illustrations')]

  render: ->
    { value, isSelected, onChange } = @props

    <div className='da-subchapter-illustration-option'>
      <RadioInput
        checked={isSelected}
        id={value}
        onChange={onChange}
        name='subchapter-illustration'
        value={value}
      />
      <label htmlFor={value}>
        <img src={getSubchapterIllustrationPath value} alt={@i18n _.str.underscored value} />
      </label>
    </div>

module.exports = SubchapterIllustrationOption
