var CellFootnoteView, CharactersLimit, Ellipsis, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

template = require('views/templates/evidence_syntheses/cell_footnote');

Ellipsis = require('base/lib/traits/ellipsis');

CharactersLimit = require('lib/traits/characters_limit');

module.exports = CellFootnoteView = (function(_super) {
  __extends(CellFootnoteView, _super);

  function CellFootnoteView() {
    return CellFootnoteView.__super__.constructor.apply(this, arguments);
  }

  CellFootnoteView.prototype.autoRender = true;

  CellFootnoteView.prototype.template = template;

  CellFootnoteView.prototype.tagName = 'li';

  CellFootnoteView.prototype.initialize = function() {
    this.data = this.options.footnote;
    this.enable(Ellipsis, {
      selector: '.display-footnote > label',
      row: 3
    });
    this.enable(CharactersLimit, {
      limits: [
        {
          selector: 'textarea',
          limit: 1000
        }
      ]
    });
    this.delegate('keydown', 'textarea', this.preventNewTextareaNewline);
    return CellFootnoteView.__super__.initialize.apply(this, arguments);
  };

  CellFootnoteView.prototype.preventNewTextareaNewline = function(e) {
    if (e.keyCode === 13) {
      return e.preventDefault();
    }
  };

  CellFootnoteView.prototype.getTemplateData = function() {
    return this.data;
  };

  CellFootnoteView.prototype.afterRender = function() {
    var checked, id, special, _ref;
    CellFootnoteView.__super__.afterRender.apply(this, arguments);
    this.fixLayout();
    _ref = this.data, id = _ref.id, special = _ref.special, checked = _ref.checked;
    this.$el.attr('data-footnote-id', id);
    this.$el.attr('data-special', special);
    if (checked) {
      return this.$el.addClass('checked');
    }
  };

  return CellFootnoteView;

})(View);
