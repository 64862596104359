{ mergeEtdsBasedOnAdolopmentData } = require 'lib/adolopments_helper'
etdHelper = require 'lib/etd_helper'
ExportDialog = require 'components/common/export_dialog'
ExportEmbeddableCodeDialog = require 'components/common/export_embeddable_code_dialog'
exportTpl = require 'views/templates/printouts/multi_comparisons_printout'
FileSaver = require 'lib/file_saver'
mediator = require 'mediator'
Modal = require 'components/common/modal'
MultiComparisonsActions = require 'actions/multi_comparisons_actions'
multiComparisonsHelper = require 'lib/multi_comparisons_helper'
Translation = require 'components/mixins/translation'
W = require 'when/when'
{ exportToFile, defaultMargins, defaultOrientation } = require 'lib/export_to_file'
{ rawContentToHTML } = require 'lib/draft_utils'

initialState =
  embeddableCodeModal:
    code: ''
    isOpen: false
    onClose: () ->
    parentSelector: null

MultiComparisonsExportModal = createReactClass
  displayName: 'MultiComparisonsExportModal'

  mixins: [Translation('')]

  propTypes:
    adolopmentData: PropTypes.instanceOf(Immutable.Map).isRequired
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired
    assessmentSections: PropTypes.arrayOf(PropTypes.string).isRequired
    comparisonsData: PropTypes.instanceOf Immutable.Map
    etds: PropTypes.instanceOf(Immutable.Map).isRequired
    summarySections: PropTypes.arrayOf(PropTypes.string).isRequired

  getInitialState: -> initialState

  _getVisibleSections: (tableType) ->
    { comparisonsData, assessmentSections } = @props
    hiddenSections = comparisonsData.getIn ['hiddenSections', tableType]
    assessmentSections
      .filter (sectionName) ->
        # certaintyOfEvidence must not be shown in grading table
        return false if tableType is 'grading' and sectionName in ['certaintyOfEvidence',
          'certaintyOfEvidenceOfTestAccuracy']
        not hiddenSections.get sectionName, false

  _prepareQuestionsList: (selectedQuestions) ->
    selectedQuestions
      .map (q, idx) ->
        questionNum: idx + 1
        questionText: q.get 'question'
        intervention: q.get 'intervention'
        comparison: q.get 'comparison'
      .toArray()

  _prepareJudgmentsData: (selectedQuestions) ->
    { etds, comparisonsData, adolopments, adolopmentData } = @props
    visibleSections = @_getVisibleSections 'soj'
    t = @i18n

    visibleSections.map (sectionName) -> {
      importance: comparisonsData.getIn ['sectionsImportanceForDecision', sectionName]
      sectionName: _.str.underscored(sectionName),
      questionValues: selectedQuestions
        .map (sq) ->
          etdId = sq.get('recommendationIds', Immutable.List()).first()
          etdData = etds.get etdId
          i18nKey = multiComparisonsHelper.getAssessmentSelectedOption(
            sq, sectionName, adolopments, etdData, adolopmentData
          )

          if _.isEmpty i18nKey
            ''
          else
            t "es:recommendations.table.radio_values.#{i18nKey}"
        .toArray()
    }

  _prepareReviewData: (selectedQuestions) ->
    { comparisonsData } = @props
    hiddenInterventions = comparisonsData.get 'hiddenInterventions'
    selectedInterventions = comparisonsData.get 'selectedInterventions'

    visibleSections = @_getVisibleSections 'grading'
    visibleInterventions = selectedInterventions.reduce (acc, intervention) ->
      return acc if hiddenInterventions.get intervention, false
      acc.push intervention
      acc
    , []
    gradingComments = @_prepareGradingComments visibleSections

    interventions: visibleInterventions
    gradingComments: gradingComments.valueSeq().toArray()
    gradedInterventions: visibleSections.map (sectionName, idx) ->
      sectionGrades = comparisonsData.getIn ['interventionsRating', sectionName], Immutable.Map()
      sectionComment = gradingComments.get sectionName, null

      importance: comparisonsData.getIn ['sectionsImportanceForDecision', sectionName]
      sectionName: _.str.underscored(sectionName),
      interventionGrades: visibleInterventions.map (intervention) ->
        grade = sectionGrades.get intervention, 0
        new Array(grade + 1).join '★'
      commentIdx: if sectionComment then sectionComment.commentIdx else null

  _prepareGradingComments: (visibleSections) ->
    gradingComments = @props.comparisonsData.get 'sectionGradingComments', Immutable.Map()

    visibleSections.reduce (acc, sectionName, idx) ->
      sectionComment = gradingComments.get sectionName
      return acc unless sectionComment

      acc.set sectionName,
        commentIdx: acc.size + 1
        commentText: sectionComment
    , Immutable.OrderedMap()

  _prepareSummaryData: ->
    { comparisonsData, summarySections } = @props

    recommendations = comparisonsData.get('recommendations')
      .map (recommendation) ->
        strength: recommendation.get('strength') ? ''
        recommendationText: rawContentToHTML recommendation.get('text')
      .toArray()

    sections = summarySections.map (sectionName) ->
      sectionName: _.str.underscored sectionName
      sectionText: rawContentToHTML comparisonsData.get sectionName

    { recommendations, sections }

  _onEmbeddableCodeModalClosed: ->
     @setState initialState

  _modalContainerRef: (el) ->
    @modalContainer = el

  getTemplateData: ->
    { comparisonsData } = @props
    selectedQuestions = comparisonsData.get 'selectedQuestions'

    overarchingQuestion: comparisonsData.get('overarchingQuestion')
    questions: @_prepareQuestionsList selectedQuestions
    judgements: @_prepareJudgmentsData selectedQuestions
    reviewData: @_prepareReviewData selectedQuestions
    summaryData: @_prepareSummaryData()

  _getExternalPresentationData: ->
    { comparisonsData, etds, adolopments, adolopmentData } = @props
    etdsToExport = comparisonsData.get('selectedQuestions').reduce (acc, sq) ->
      etdId = sq.get('recommendationIds').last()
      adolopmentRecId = sq.get('recommendationAdolopmentIds', Immutable.List()).last()
      etd = if not _.isEmpty(adolopmentRecId)
        original = adolopments.get(adolopmentRecId).toJS()
        adolopedSections = adolopmentData.get(etdId, Immutable.Map()).toJS()
        # merge helper expects template data to be present in etd
        etd = Immutable.Map({templateData: etds.get(etdId)}).toJS()
        Immutable.fromJS(mergeEtdsBasedOnAdolopmentData(original, etd, adolopedSections)).get(
          'templateData', Immutable.Map())
      else
        etds.get(etdId)

      acc.set(etdId, etd)
    , Immutable.Map()

    _.extend {}, @props.comparisonsData.toJS(), { etds: etdsToExport.toJS() }

  onDownload: (exportOptions) ->
    { outputFormat } = exportOptions
    promise = switch outputFormat
      when 'jsonld'
        fileName = @props.comparisonsData.get 'overarchingQuestion'
        data = @props.comparisonsData.toJS()
        mediator.services.jsonldMultiComparisonExport.export data
        .then (exportedJson) ->
          blob = new Blob [JSON.stringify exportedJson],
            type: 'application/json;charset=utf-8'
          FileSaver.saveFile blob, "#{fileName}.json"
      when 'external_link'
        presentationData = @_getExternalPresentationData()
        mcId = @props.comparisonsData.get '_id'
        mediator.services.presentationService
        .exportMcPresentation presentationData, mcId
      when 'external_embeddable_code'
        presentationData = @_getExternalPresentationData()
        mcId = @props.comparisonsData.get '_id'
        mediator.services.presentationService
        .getEmbeddableMcPresentationLink presentationData, mcId
        .then (code) =>
          embeddableCodeModalDeferred = W.defer()
          newState =
            embeddableCodeModal:
              code: code
              isOpen: true
              parentSelector: => @modalContainer
              onClose: =>
                @setState initialState
                embeddableCodeModalDeferred.resolve()
          @setState newState
          embeddableCodeModalDeferred.promise
      else
        fileName = @props.comparisonsData.get 'overarchingQuestion'
        templateData = @getTemplateData()
        exportHtml = exportTpl templateData
        exportToFile outputFormat, exportHtml, { fileName }
    promise.catch mediator.dialogs.error
    .ensure MultiComparisonsActions.hideExportDialog

  render: ->
    outputFormats = ['html', 'doc', 'pdf', 'external_link', 'external_embeddable_code']
    <Modal isOpen
      className="multi-comparisons-export-modal"
      closeButton={false}
      title={@i18n 'mc:export.modal_title'}
      ref={@_modalContainerRef}
    >
      <ExportDialog
        outputFormats={outputFormats}
        advancedOutputFormats={['jsonld']}
        onClose={MultiComparisonsActions.hideExportDialog}
        onDownload={@onDownload}
      />
      <ExportEmbeddableCodeDialog {...@state.embeddableCodeModal} />
    </Modal>

module.exports = MultiComparisonsExportModal
