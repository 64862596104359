{ useState, useImperativeHandle, forwardRef } = React
{ useI18n, useCoffeeCallback } = require 'lib/react_utils'
IconButton = require 'components/common/icon_button'
{ authorStringToObject } = require 'lib/references_utils'

ReferenceAuthorsEdit = forwardRef ({ authors }, ref) ->
  [ authorsString, setAuthorsString ] = useState('')
  initalAuthorsState = if authors.isEmpty()
    Immutable.fromJS([{ lastName: '', firstNames: ''}])
  else
    authors.map (author) ->
      author.set('firstNames', author.get('firstNames', Immutable.List()).toJS().join(' '))

  [authorsList, setAuthorsList] = useState(initalAuthorsState)

  i18n = useI18n()

  addAuthor = useCoffeeCallback [], ->
    setAuthorsList (authors) ->
      authors.push Immutable.fromJS({ lastName: '', firstNames: '' })

  deleteAuthor = useCoffeeCallback [], (index) -> ->
    setAuthorsList (authors) -> authors.delete(index)

  useImperativeHandle ref, ->
    getAuthors: ->
      authorsList = authorsList.map (author) ->
        author.set('firstNames', Immutable.fromJS(
          _.map(author.get('firstNames', '').split(' '), (name) -> name.trim())))
      if _.isEmpty(authorsString)
        authorsList
      else
        authorsList.concat Immutable.fromJS(authorsString.split(/; ?/).map(authorStringToObject))

  , [authorsList, authorsString]

  onChange = (index, key) -> (e) ->
    value = e.target.value
    setAuthorsList (authors) -> authors.setIn [index, key], value

  addToList = useCoffeeCallback [authorsString], ->
    setAuthorsList (authorsList) ->
      authorsList.concat(Immutable.fromJS(
        authorsString.split(/; ?/).map(authorStringToObject).map((author) ->
          # we're using firstName in input field, so we have to convert it to string
          author.firstNames = author.firstNames.join(' ')
          author
        )))
    setAuthorsString('')

  <div className="reference-authors-edit">
    <div className="flex flex-row items-center">
      <input
        type='text'
        value={authorsString}
        placeholder={i18n 'references:placeholders.authors'}
        onChange={(e) -> setAuthorsString(e.target.value)}
      />
      <IconButton iconName="add" onClick={addToList} />
    </div>
    <div className="flex flex-row">
      <label className="mr-5">{i18n 'references:columns.last_name'}</label>
      <label className="mr-5">{i18n 'references:columns.first_names'}</label>
    </div>
    {authorsList.map (author, idx) ->
      <div className="flex flex-row items-center mb-5" key={idx}>
        <input
          className="mr-10"
          value={author.get('lastName', '')}
          onChange={onChange(idx, 'lastName')}
        />
        <input
          className="mr-10"
          value={author.get('firstNames', '')}
          onChange={onChange(idx, 'firstNames')}
        />
        <div className="text-right">
          <IconButton iconName="cross" onClick={deleteAuthor(idx)} />
        </div>
      </div>
    }
    <IconButton iconName="add" label={i18n 'references:add_author'} onClick={addAuthor} />
  </div>

ReferenceAuthorsEdit.propTypes =
  authors: PropTypes.instanceOf(Immutable.List)

ReferenceAuthorsEdit.defaultProps =
  authors: Immutable.List()

module.exports = ReferenceAuthorsEdit
