var PublicationPlatformActions, PublicationPlatformStore, PublicationPlatformTable, PublicationPlatformToolbar, PublicationPlatformView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

PublicationPlatformActions = require('actions/publication_platform_actions');

PublicationPlatformStore = require('stores/publication_platform_store');

PublicationPlatformTable = require('components/dissemination/publication_platform/publication_platform_table');

PublicationPlatformToolbar = require('components/dissemination/publication_platform/publication_platform_toolbar');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = PublicationPlatformView = (function(_super) {
  __extends(PublicationPlatformView, _super);

  function PublicationPlatformView() {
    return PublicationPlatformView.__super__.constructor.apply(this, arguments);
  }

  PublicationPlatformView.prototype.container = '#page-container';

  PublicationPlatformView.prototype.className = 'publication-platform-view';

  PublicationPlatformView.prototype.autoRender = true;

  PublicationPlatformView.prototype._stopInputsPropagation = false;

  PublicationPlatformView.prototype.initialize = function() {
    PublicationPlatformView.__super__.initialize.apply(this, arguments);
    this.enable(ReactComponent);
    return this.subview('toolbar', new ReactToolbarView({
      component: PublicationPlatformToolbar
    }));
  };

  PublicationPlatformView.prototype.afterRender = function() {
    PublicationPlatformView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(PublicationPlatformTable, PublicationPlatformActions, null, {
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  PublicationPlatformView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    alt.recycle(PublicationPlatformStore);
    return PublicationPlatformView.__super__.dispose.apply(this, arguments);
  };

  return PublicationPlatformView;

})(View);
