var CADTH_EXTRACTORS_ORG_ID, KEY_MESSAGES_TAGS, getCreatorFields;

KEY_MESSAGES_TAGS = ['intervention', 'comparison', 'population'];

CADTH_EXTRACTORS_ORG_ID = 'o_cadth_0';

getCreatorFields = function(KeyMessagesActions) {
  return [
    {
      key: 'question',
      type: 'text',
      nameI18nKey: 'key_messages:creator.labels.question'
    }, {
      key: 'tags',
      type: 'tags',
      tagsProps: {
        onAddCode: KeyMessagesActions.addCode,
        onAddTag: KeyMessagesActions.addTag,
        onRemoveCode: KeyMessagesActions.removeCode,
        onRemoveTag: KeyMessagesActions.removeTag,
        onUpdateTag: KeyMessagesActions.updateTag,
        tags: KEY_MESSAGES_TAGS
      }
    }
  ];
};

module.exports = {
  KEY_MESSAGES_TAGS: KEY_MESSAGES_TAGS,
  getCreatorFields: getCreatorFields,
  CADTH_EXTRACTORS_ORG_ID: CADTH_EXTRACTORS_ORG_ID
};
