var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<ul id=\"main-menu\">\n  <li>\n    <button>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "evidence_tables", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    <ul>\n      <li><button data-type=\"grade_ep\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "grade_ep", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n      <li><button data-type=\"sof\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sof", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n      <li><button data-type=\"sof_cochrane\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sof_table_cochrane", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n      <li><button data-type=\"etd\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "etd", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n    </ul>\n  </li>\n  <li>\n  <button>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "guidelines", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    <ul>\n      <li><button data-type=\"full_guideline\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "full_guideline", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n      <li><button data-type=\"adapt_guideline\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "adapt_guideline", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n      <li><button data-type=\"update_guideline\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "update_guideline", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n    </ul>\n  </li>\n  <li>\n    <button>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "disseminate", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    <ul>\n      <li><button data-type=\"publish_dbep\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "publish_dbep", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n      <li><button data-type=\"publish_mobile\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "publish_mobile", {"name":"i18n","hash":{},"data":data})))
    + "</button></li>\n    </ul>\n  </li>\n</ul>\n<div id=\"submenu\">\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "projects:welcome.capabilities", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}