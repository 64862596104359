Modal = require 'components/common/modal'
{ Button } = ReactComponents
ButtonsRow = require 'components/common/buttons_row'
mediator = require 'mediator'
Popover = require 'components/common/popover'
Translation = require 'components/mixins/translation'
{ getRouteToProjectsList } = require 'lib/projects_helper'
dbHelper = require 'base/lib/db_helper'
{ facebook_url, twitter_url} = require 'lib/constants'

Links = createReactClass
  displayName: 'Links'

  mixins: [Translation('')]

  render: ->
    <div className='help-links'>
      {_(['terms_of_service', 'terms_of_publication', 'how_to_cite_gradepro']).map (link) =>
        <a key={link} href={@i18n("#{link}_url")} target='_blank'>{@i18n(link)}</a>
      }
    </div>

UserMenu = createReactClass
  displayName: 'UserMenu'

  mixins: [Translation('')]

  propTypes:
    forceMenuOpen: PropTypes.bool.isRequired

  getDefaultProps: ->
    forceMenuOpen: false

  getInitialState: ->
    userMenuVisible: false
    showSubscriptionModal: false

  goToProjectsList: (organizationId) -> =>
    mediator.publish '!router:route', getRouteToProjectsList organizationId

  showUserMenu: ->
    @setState userMenuVisible: true

  hideUserMenu: ->
    @setState userMenuVisible: false

  toggleUserMenu: ->
    if @state.userMenuVisible
      @hideUserMenu()
    else
      @showUserMenu()

  logout: ->
    mediator.publish 'logout'

  showAccountSettings: ->
    @props.parentView.showAccountSettings()
    @hideUserMenu()

  showSubscriptionModal: ->
    @setState showSubscriptionModal: true

  hideSubscriptionModal: ->
    @setState showSubscriptionModal: false

  renderWorkspaceOption: (organization) ->
    organizationId = organization.organizationId
    cls = classNames 'workspaces__link workspaces__link--organization',
      active: mediator.activeWorkspace is organizationId

    <button
      key={organizationId}
      className={cls}
      onClick={@goToProjectsList(organization.organizationId)}>
      {organization.organizationName}
    </button>

  renderPersonalWorkspaceOption: ->
    return if mediator.services.switches.isServerSwitchOn 'organizationProjectsOnly'

    if mediator.user.get 'hasPersonalSubscription'
      cls = classNames 'workspaces__link', active: mediator.activeWorkspace is 'personal'
      <button className={cls} onClick={@goToProjectsList()}>
        {@i18n 'my_personal_workspace'}
      </button>
    else
      <button className='workspaces__link' onClick={@showSubscriptionModal}>
        <i>{@i18n 'create_personal_workspace'}</i>
      </button>

  isMenuVisible: ->
    @props.forceMenuOpen or @state.userMenuVisible

  render: ->
    isMdgServer = mediator.services.switches.isServerSwitchOn 'mdgFeatures'

    <div className='user-menu'>
      <Popover alignment='end' onRequestClose={@hideUserMenu} visible={@isMenuVisible()}>
        <span className='avatar' onClick={@toggleUserMenu} />
        <div className='user-menu__container'>
          <div className='user-information'>
            <div className='user-information__avatar'/>
            <div className='user-information__data'>
              <h3>{mediator.user.getFullName()}</h3>
              <div className='email'>{mediator.user.get('email')}</div>
            </div>
          </div>
          <div className='links'>
            <div className='social-links'>
              <div className="social-links__container">
                <a
                  className='facebook'
                  target="_blank"
                  href={facebook_url}
                  rel='noopener noreferrer'
                />
                <a
                  className='twitter'
                  target="_blank"
                  href={twitter_url}
                  rel='noopener noreferrer'
                />
              </div>
              <div>{@i18n('follow_us')}</div>
            </div>
            <Links />
          </div>
          {not _.isEmpty(mediator.userOrganizations) &&
            <div className='workspaces'>
              <div className='workspaces__title'>{@i18n('change_workspace')}</div>
              {_(mediator.userOrganizations).map @renderWorkspaceOption}
              {@renderPersonalWorkspaceOption()}
              <div className='workspaces-screen-link workspaces-screen'>
                <a href='#organizations'>{@i18n 'go_to_workspaces_list'}</a>
              </div>
            </div>
          }
          {if @props.isOrganizationAdmin and not isMdgServer
            <div className='admin-panel-link'>
              <a href={dbHelper.getAdminPanelUrl()} target='_blank' rel='noopener noreferrer'>
                <img src='images/svg/op.svg' />
                {@i18n 'go_to_organizations_panel'}
              </a>
            </div>
          }
          <ButtonsRow className='bottom-buttons'>
            <Button
              onClick={@showAccountSettings}
              className='user'
            >
              {@i18n('user.account_settings')}
            </Button>
            <Button onClick={@logout} type='submit'>{@i18n('user.log_out')}</Button>
          </ButtonsRow>
        </div>
      </Popover>
    </div>

module.exports = UserMenu
