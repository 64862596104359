var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<!doctype html>\n<html class=\"no-js\" lang=\"en\">\n<head>\n  <meta charset=\"utf-8\">\n  <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge,chrome=1\">\n  <title>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "printout_title", {"name":"i18n","hash":{},"data":data})))
    + "</title>\n  <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\">\n  <style type=\"text/css\">\n    table {\n      width: 100%;\n      border-collapse: collapse;\n      font-size: 8pt;\n      font-family: Calibri, Verdana , 'Arial Narrow', Arial, sans-serif;\n    }\n\n    td {\n      padding: 4px;\n      vertical-align: top;\n      border: 1px solid black;\n    }\n\n    td.title-cell {\n      width: 200px;\n    }\n\n    td.description-cell {\n      width: 33%;\n    }\n  </style>\n</head>\n<body>\n  ";
  stack1 = ((helper = (helper = helpers.scopeTableHTML || (depth0 != null ? depth0.scopeTableHTML : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"scopeTableHTML","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</body>\n</html>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}