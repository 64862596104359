var DbDoc, checklistDocShape, mergeNewData, projectChecklistDocId;

DbDoc = require('lib/db_docs/db_doc');

checklistDocShape = require('lib/db_docs/doc_shapes/checklist_doc_shape');

projectChecklistDocId = require('lib/doc_ids').PROJECT_CHECKLIST;

mergeNewData = require('lib/db_docs/document_instance_helpers').mergeNewData;

module.exports = DbDoc(checklistDocShape, {
  dbSetup: {
    docId: projectChecklistDocId
  },
  methods: {
    updateStepData: function(topicId, stepId, data) {
      return this.updateAt('topics', topicId, stepId)(mergeNewData(data));
    }
  }
});
