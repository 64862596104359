Textarea = ({ onApply, placeholder, content, readOnly }) ->
  onBlur = (e) -> onApply e.target.value

  <div className="text-area-container">
    <textarea
      disabled={readOnly}
      defaultValue={content}
      placeholder={placeholder}
      onBlur={onBlur}
    />
  </div>

Textarea.propTypes =
  onApply: PropTypes.func.isRequired
  placeholder: PropTypes.string
  content: PropTypes.string
  readOnly: PropTypes.bool

Textarea.defaultProps =
  placeholder: null
  content: null
  readOnly: false

module.exports = Textarea
