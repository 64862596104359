var ComparatorTestAsParameter, Migration, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = ComparatorTestAsParameter = (function(_super) {
  __extends(ComparatorTestAsParameter, _super);

  function ComparatorTestAsParameter() {
    ComparatorTestAsParameter.__super__.constructor.call(this, false);
  }

  ComparatorTestAsParameter.prototype.up = function(project, colls) {
    var question, setComparatorTestPresence;
    ComparatorTestAsParameter.__super__.up.apply(this, arguments);
    setComparatorTestPresence = (function(_this) {
      return function(question) {
        var _ref;
        if ((_ref = question.get('comparatorTest')) != null ? _ref.length : void 0) {
          question.set('comparatorTestPresent', true);
        }
        return question.save();
      };
    })(this);
    return W.all((function() {
      var _i, _len, _ref, _results;
      _ref = colls.questions.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        question = _ref[_i];
        if (question.isDiagnostic()) {
          _results.push(setComparatorTestPresence(question));
        }
      }
      return _results;
    })());
  };

  return ComparatorTestAsParameter;

})(Migration);
