var DBEPPublicationActions, DBEP_PUBLICATION_QUESTION_STATUSES_DOC_ID, Exceptions, QUESTION_GROUPS_DOC_ID, QuestionDoc, W, alt, hasFeatureSwitch, isOnline, mediator;

alt = require('alt');

DBEP_PUBLICATION_QUESTION_STATUSES_DOC_ID = require('lib/doc_ids').DBEP_PUBLICATION_STATUS;

Exceptions = require('lib/exceptions');

mediator = require('mediator');

QuestionDoc = require('lib/db_docs/question_doc');

QUESTION_GROUPS_DOC_ID = require('lib/questions_helper').QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE.regular;

W = require('when/when');

isOnline = function() {
  return mediator.services.replication.isConnected();
};

hasFeatureSwitch = function() {
  return _.any([mediator.services.switches.isServerSwitchOn('dbep-publish-no-moderation'), mediator.services.switches.isOn('dbep-publish')]);
};

DBEPPublicationActions = (function() {
  function DBEPPublicationActions() {
    this.generateActions('closeModal', 'updateSelectedQuestions', 'fetchQuestionsSuccess', 'fetchQuestionsError', 'fetchStatusesSuccess', 'fetchStatusesError', 'validationSuccess', 'validationError', 'sendPreviewSuccess', 'sendPreviewError', 'updateProgress', 'toggleValidationOverride', 'toggleTOPAccepted', 'publishSuccess', 'publishError', 'searchOnTable', 'changeStatusFilterOnTable');
  }

  DBEPPublicationActions.prototype.openModal = function(isPreview) {
    var err;
    if (isOnline()) {
      if (isPreview) {
        return this.dispatch('preview');
      } else {
        return this.dispatch('publish');
      }
    } else {
      err = new Exceptions.no_connection;
      return mediator.dialogs.error(err);
    }
  };

  DBEPPublicationActions.prototype.openModalForOneQuestion = function(isPreview, questionId) {
    var err;
    if (isOnline()) {
      if (isPreview) {
        this.dispatch({
          mode: 'preview',
          questionId: questionId
        });
        return this.actions._sendPreview([questionId]);
      } else {
        this.dispatch({
          mode: 'publish',
          questionId: questionId
        });
        return this.actions._validate([questionId]);
      }
    } else {
      err = new Exceptions.no_connection;
      return mediator.dialogs.error(err);
    }
  };

  DBEPPublicationActions.prototype.fetch = function() {
    this.dispatch();
    this.actions.fetchQuestions();
    return this.actions.fetchStatuses();
  };

  DBEPPublicationActions.prototype.fetchQuestions = function() {
    var groupsPromise, questionsPromise;
    questionsPromise = QuestionDoc.at(mediator.project.id).fetchMany().then(function(questions) {
      return _.reduce(questions, function(acc, question) {
        acc[question._id] = question;
        return acc;
      }, {});
    });
    groupsPromise = mediator.services.storePersistenceAdapter.fetch(mediator.project.id, QUESTION_GROUPS_DOC_ID)["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
      return {};
    });
    return W.all([questionsPromise, groupsPromise]).then((function(_this) {
      return function(_arg) {
        var questionGroups, questions;
        questions = _arg[0], questionGroups = _arg[1];
        return _this.actions.fetchQuestionsSuccess({
          questions: questions,
          questionGroups: questionGroups
        });
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        mediator.dialogs.error(err);
        return _this.actions.fetchQuestionsError();
      };
    })(this));
  };

  DBEPPublicationActions.prototype.fetchStatuses = function() {
    return mediator.services.storePersistenceAdapter.fetch(mediator.project.id, DBEP_PUBLICATION_QUESTION_STATUSES_DOC_ID)["catch"](function(err) {
      if (err.status !== 404) {
        throw err;
      }
      return {};
    }).then(this.actions.fetchStatusesSuccess)["catch"]((function(_this) {
      return function(err) {
        mediator.dialogs.error(err);
        return _this.actions.fetchStatusesError();
      };
    })(this));
  };

  DBEPPublicationActions.prototype.dbChange = function(_arg) {
    var doc;
    doc = _arg.doc;
    if (doc._id === DBEP_PUBLICATION_QUESTION_STATUSES_DOC_ID) {
      return this.actions.fetchStatusesSuccess(doc);
    }
  };

  DBEPPublicationActions.prototype.validate = function(questionIds) {
    this.dispatch();
    return this.actions._validate(questionIds);
  };

  DBEPPublicationActions.prototype._validate = function(questionIds) {
    return mediator.services.dbepPublicationService.validateProject(mediator.project, questionIds, this.actions.updateProgress).then(this.actions.validationSuccess)["catch"](this.actions.validationError);
  };

  DBEPPublicationActions.prototype.sendPreview = function(questionIds) {
    this.dispatch();
    return this.actions._sendPreview(questionIds);
  };

  DBEPPublicationActions.prototype._sendPreview = function(questionIds) {
    return mediator.services.dbepPublicationService.uploadPreview(mediator.project, questionIds, this.actions.updateProgress).then(this.actions.sendPreviewSuccess)["catch"](this.actions.sendPreviewError);
  };

  DBEPPublicationActions.prototype.publish = function(profiles, mode) {
    if (mode === 'preview') {
      return;
    }
    this.dispatch();
    switch (mode) {
      case 'publish':
        return mediator.services.dbepPublicationService.uploadProjectToStaging(mediator.project, profiles).then(this.actions.publishSuccess)["catch"]((function(_this) {
          return function(err) {
            mediator.dialogs.error(err);
            return _this.actions.publishError();
          };
        })(this));
      case 'publish-no-moderation':
        return mediator.services.dbepPublicationService.uploadProject(mediator.project, profiles).then(this.actions.publishSuccess)["catch"]((function(_this) {
          return function(err) {
            mediator.dialogs.error(err);
            return _this.actions.publishError();
          };
        })(this));
    }
  };

  return DBEPPublicationActions;

})();

module.exports = alt.createActions(DBEPPublicationActions);
