var EtdHeaderRoleAndSubgroupsFields, EtdTemplateHelper, EtdTemplateStore, EtdTemplatesActions, Migration, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

EtdTemplatesActions = require('actions/etd_templates_actions');

EtdTemplateHelper = require('stores/utils/etd_template_helper');

EtdTemplateStore = require('stores/etd_templates_store');

module.exports = EtdHeaderRoleAndSubgroupsFields = (function(_super) {
  __extends(EtdHeaderRoleAndSubgroupsFields, _super);

  function EtdHeaderRoleAndSubgroupsFields() {
    EtdHeaderRoleAndSubgroupsFields.__super__.constructor.call(this, false);
  }

  EtdHeaderRoleAndSubgroupsFields.prototype.up = function(project, colls) {
    var addDataFields, addDefinitionFields;
    EtdHeaderRoleAndSubgroupsFields.__super__.up.apply(this, arguments);
    addDefinitionFields = function(state) {
      var alreadyHasFields, questionDef;
      questionDef = state.getIn(['dxTemplate', 'templateDef', 'question']);
      if (!questionDef) {
        return state;
      }
      alreadyHasFields = questionDef.find(function(q) {
        return q.get('id') === 'role' || q.get('id') === 'subgroups';
      });
      if (alreadyHasFields) {
        return state;
      }
      questionDef = questionDef.splice(4, 0, Immutable.Map({
        'id': 'role',
        'checked': true
      }));
      questionDef = questionDef.push(Immutable.Map({
        'id': 'subgroups',
        'checked': true
      }));
      return state.setIn(['dxTemplate', 'templateDef', 'question'], questionDef);
    };
    addDataFields = function(state) {
      var questionDataSections, questionSectionsOrder;
      questionDataSections = state.getIn(['dxTemplate', 'templateData', 'question', 'sections']);
      if (!questionDataSections) {
        return state;
      }
      if (questionDataSections.has('role') && questionDataSections.has('subgroups')) {
        return state;
      }
      questionDataSections = questionDataSections.setIn(['role'], Immutable.Map({
        'name': 'Role of the text',
        'content': ''
      }));
      questionDataSections = questionDataSections.setIn(['subgroups'], Immutable.Map({
        'name': 'Subroups',
        'content': ''
      }));
      state = state.setIn(['dxTemplate', 'templateData', 'question', 'sections'], questionDataSections);
      questionSectionsOrder = state.getIn(['dxTemplate', 'templateData', 'question', 'sectionsOrder']);
      if (questionSectionsOrder.indexOf('role') !== -1 && questionSectionsOrder.indexOf('subgroups') !== -1) {
        return state;
      }
      questionSectionsOrder = questionSectionsOrder.splice(4, 0, 'role');
      questionSectionsOrder = questionSectionsOrder.push('subgroups');
      return state.setIn(['dxTemplate', 'templateData', 'question', 'sectionsOrder', questionSectionsOrder]);
    };
    return W(EtdTemplateStore.fetch(project.id, ['etd-tx-template', 'etd-dx-template'])).then(function() {
      var state;
      state = EtdTemplateStore.getState();
      state = addDefinitionFields(state);
      state = addDataFields(state);
      if (!state.get('dxTemplate')) {
        return W.resolve();
      }
      return EtdTemplateHelper.updateTemplatesInQuestions(project.id, state.get('dxTemplate'), 'dx');
    }).otherwise(function(err) {
      console.log(err);
      throw err;
    });
  };

  return EtdHeaderRoleAndSubgroupsFields;

})(Migration);
