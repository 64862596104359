var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div>\n  <p>\n    You receive this message since you are the "
    + escapeExpression(((helper = (helper = helpers.projectName || (depth0 != null ? depth0.projectName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"projectName","hash":{},"data":data}) : helper)))
    + " panel member or participate in this phase of the project in other capacity.\n  </p>\n\n  <p>\n    Outcomes have been identified and their importance for target users of these guidelines was rated.\n  </p>\n\n  <p>\n    ACTION: please review and <b>confirm that you accept the final list of outcomes</b> to be included in these guidelines. You may also comment on any outcomes if you believe that clarification is necessary.\n  </p>\n\n  <p>\n    You should reserve approximately 15-20 minutes of your time to do this.\n  </p>\n\n  <p>\n    <div>Please follow this link:</div>\n    <a href=\"{#link}\">{#link}</a>\n  </p>\n\n  <p>\n    Thank you very much for your input and time.\n  </p>\n\n  <p>\n    You receive this message since you are the [project] panel member or participate in the project in other capacity.\n  </p>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}