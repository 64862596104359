var DraftQuestionsView, QuestionsGeneration, QuestionsGenerationActions, QuestionsGenerationToolbar, ReactComponent, ReactToolbarView, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

QuestionsGeneration = require('components/scope/questions/questions_generation');

QuestionsGenerationToolbar = require('components/scope/questions/toolbar');

QuestionsGenerationActions = require('actions/questions_generation_actions');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

module.exports = DraftQuestionsView = (function(_super) {
  __extends(DraftQuestionsView, _super);

  function DraftQuestionsView() {
    return DraftQuestionsView.__super__.constructor.apply(this, arguments);
  }

  DraftQuestionsView.prototype.container = '#page-container';

  DraftQuestionsView.prototype.className = 'questions-generation-view';

  DraftQuestionsView.prototype.autoRender = true;

  DraftQuestionsView.prototype.initialize = function() {
    DraftQuestionsView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: QuestionsGenerationToolbar
    }));
    return this.enable(ReactComponent);
  };

  DraftQuestionsView.prototype.afterRender = function() {
    DraftQuestionsView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(QuestionsGeneration, QuestionsGenerationActions);
  };

  DraftQuestionsView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('QuestionsGenerationStore', 'MembersStore', 'QuestionsStore', 'LimitsStore');
    return DraftQuestionsView.__super__.dispose.apply(this, arguments);
  };

  return DraftQuestionsView;

})(View);
