var Rm5ExportTableCell, rm5ExportConfig, theadLabels, theadSchemas, _buildThead;

rm5ExportConfig = require('lib/rm5_export_config');

theadSchemas = rm5ExportConfig.theadSchemas;

theadLabels = rm5ExportConfig.theadLabels;

Rm5ExportTableCell = rm5ExportConfig.Rm5ExportTableCell;

_buildThead = function(theadSchema, comparison, intervention) {
  var cellLabel, cellParams, labelText, placeholder, placeholderValue, row, thead, theadRow, _i, _len, _ref;
  thead = [];
  for (_i = 0, _len = theadSchema.length; _i < _len; _i++) {
    row = theadSchema[_i];
    theadRow = {
      cells: []
    };
    for (cellLabel in row) {
      cellParams = row[cellLabel];
      placeholder = (_ref = cellLabel.match(/\[[a-z]+\]/)) != null ? _ref[0] : void 0;
      labelText = !placeholder ? theadLabels[cellLabel] : (placeholderValue = placeholder === '[comparison]' ? comparison : intervention, cellLabel = cellLabel.replace(placeholder, ''), "" + theadLabels[cellLabel] + " " + placeholderValue);
      theadRow.cells.push(new Rm5ExportTableCell({
        type: 'th',
        textContent: labelText,
        rowspan: cellParams[0],
        colspan: cellParams[1],
        name: cellLabel
      }));
    }
    thead.push(theadRow);
  }
  return thead;
};

module.exports = function(viewType, comparison, intervention) {
  var theadSchema;
  if (comparison && intervention) {
    theadSchema = theadSchemas["" + viewType + "_regular"];
    return _buildThead(theadSchema, comparison, intervention);
  } else {
    theadSchema = theadSchemas["" + viewType + "_narrative"];
    return _buildThead(theadSchema);
  }
};
