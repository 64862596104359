SelectCustom = require 'components/common/select_custom'
TextAreaWithApply = require 'components/common/text_area_with_apply'

SelectWithOther = createReactClass
  displayName: 'SelectWithOther'
  propTypes:
    onChange: PropTypes.func.isRequired
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired
      text: PropTypes.string.isRequired
    })).isRequired
    placeholder: PropTypes.string
    value: PropTypes.string

  getDefaultProps: ->
    placeholder: ''
    value: null

  render: ->
    { options, value, onChange, placeholder } = @props
    anyOptionSelected = _.any options, ({ value: optValue }) -> optValue is value
    otherSelected = value? and (value is 'other' or not anyOptionSelected)

    <div>
      <div className="selectWithOther__select">
        <SelectCustom
          onChange={onChange}
          options={options}
          optionsContainerWidthMode="fixed"
          selected={if otherSelected then 'other' else value}
        />
      </div>
      {otherSelected and <div className="selectWithOther__other">
        <TextAreaWithApply
          content={if value is 'other' then '' else value}
          onApply={onChange}
          placeholder={placeholder}
        />
      </div>}
    </div>

module.exports = SelectWithOther
