ToggleSwitch = (props) ->
  { label, containerClass, onChange, checked } = props

  <span className={classNames 'toggle-switch', containerClass} onClick={onChange}>
    <label className='switcher'>
      <input type='checkbox' disabled='disabled' checked={checked} />
      <div className='slider round'></div>
    </label>
    {if label
      <button className='toggle-switch-button'>
        <span className='switch-btn-text'>{label}</span>
      </button>
    }
  </span>

module.exports = ToggleSwitch
