CustomRenderMixin = require 'components/mixins/custom_render_mixin'

EditableTable = createReactClass

  displayName: "EditableTable"

  propTypes:
    children: PropTypes.arrayOf PropTypes.element

  render: ->
    <table className={@props.className}>
      {@props.children}
    </table>

module.exports = EditableTable
