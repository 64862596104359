var Code, MemberShape, arrayOf, docShape, docTypes, documentId, initial, mapOf, number, optional, shape, string, topicShape, _ref;

Code = require('lib/db_docs/field_types/code_type');

arrayOf = require('lib/db_docs/field_types/array_of');

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

docTypes = require('lib/db_docs/doc_types');

initial = require('lib/db_docs/field_types/type_decorators').initial;

mapOf = require('lib/db_docs/field_types/map_of');

shape = require('lib/db_docs/field_types/shape');

_ref = require('lib/db_docs/field_types/built_in_types'), string = _ref.string, number = _ref.number;

optional = shape.typeDecorators.optional;

MemberShape = shape({
  _id: string,
  lastName: initial('')(string),
  givenNames: initial('')(string),
  accessRights: initial([])(arrayOf(string)),
  email: initial('')(string)
});

topicShape = docShape({
  docType: initial(docTypes.MDA_TOPIC)(string),
  insertedTables: initial([])(arrayOf(documentId)),
  lastUpdateDate: optional(number),
  lastUpdateProjectName: optional(string),
  name: initial('')(string),
  orderNumber: optional(number),
  slug: optional(string),
  topicRootId: optional(documentId),
  template: initial('initial')(string),
  codes: optional(mapOf(Code)),
  members: optional(shape({
    editor: optional(MemberShape),
    mar: optional(MemberShape),
    mab1: optional(MemberShape),
    mab2: optional(MemberShape),
    mab3: optional(MemberShape)
  })),
  bodyParts: optional(arrayOf(string))
});

module.exports = topicShape;
