var ModuleController, NMAController, NMAListView, NMAOutcomeView, NMAQuestionView, NMAVizView,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

NMAListView = require('views/nma/nma_list_view');

NMAOutcomeView = require('views/nma/nma_outcome_view');

NMAQuestionView = require('views/nma/nma_question_view');

NMAVizView = require('views/nma/nma_viz_view');

module.exports = NMAController = (function(_super) {
  __extends(NMAController, _super);

  function NMAController() {
    return NMAController.__super__.constructor.apply(this, arguments);
  }

  NMAController.prototype.index = function() {
    return this.view = new NMAListView;
  };

  NMAController.prototype.question = function(params) {
    var questionId;
    questionId = params.questionId;
    return this.view = new NMAQuestionView({
      questionId: questionId
    });
  };

  NMAController.prototype.outcome = function(params) {
    var outcomeId, questionId;
    outcomeId = params.outcomeId, questionId = params.questionId;
    return this.view = new NMAOutcomeView({
      outcomeId: outcomeId,
      questionId: questionId
    });
  };

  NMAController.prototype.viz = function(params) {
    var questionId;
    questionId = params.questionId;
    return this.view = new NMAVizView({
      questionId: questionId
    });
  };

  return NMAController;

})(ModuleController);
