Checkbox = require 'components/common/checkbox_input'
Popover =  require 'components/common/popover'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'
{ useState } = React

MultiSelectCustom = ({
  className
  onChange
  options
  selectAllDisabled
  selectedOptions
}) ->
  i18n = useI18n()
  [showingOptions, setShowingOptions] = useState false

  handleOptionChange = useCoffeeCallback [onChange, selectedOptions], (evt) ->
    checked = evt.target.checked
    optionValue = evt.target.value
    newVal = if checked
      R.union selectedOptions, [optionValue]
    else
      R.without [optionValue], selectedOptions
    onChange newVal

  allOptionsChecked = useCoffeeMemo [options, selectedOptions], ->
    options.every R.compose R.includes(R.__, selectedOptions), R.prop 'value'

  handleAllChange = useCoffeeCallback [onChange, options], (evt) ->
    allChecked = evt.target.checked
    onChange if allChecked
      R.pluck 'value', options
    else
      []

  isSelectAllDisabled = useCoffeeMemo [options, selectAllDisabled], ->
    selectAllDisabled or options.some R.prop 'disabled'

  toggleShowingOptions = useCoffeeCallback [], ->
    setShowingOptions (current) -> not current

  hideOptions = useCoffeeCallback [], ->
    setShowingOptions false

  <Popover
    onRequestClose={hideOptions}
    visible={showingOptions}
    positionParams={positionOffset: 0}
  >
    <div
      className={classNames 'multi-select-container', className}
      onClick={toggleShowingOptions}
    >
      <div className="select-input">
        <div className="select-value">
          {if allOptionsChecked
            i18n 'all'
          else if selectedOptions.length > 0
            options.filter (option) -> R.includes option.value, selectedOptions
              .map (option) -> option.label
              .join ', '
          else
            i18n 'actions.select'
          }
        </div>
        <div className="select-sign" />
      </div>
    </div>
    <div className="options-select-container__options multi-select-container__options">
      <div className="select-all option">
        <label disabled={isSelectAllDisabled}>
          <Checkbox
            checked={allOptionsChecked}
            onChange={handleAllChange}
            disabled={isSelectAllDisabled}
          />
          <div>{i18n 'actions.select_all'}</div>
        </label>
      </div>
      {options.map ({ value, label, disabled }) ->
        <div className="option" key={value}>
          <label disabled={disabled}>
            <Checkbox
              checked={R.includes value, selectedOptions}
              onChange={handleOptionChange}
              value={value}
              disabled={disabled}
            />
            <div className="items-list-container__item">{label}</div>
          </label>
        </div>
      }
    </div>
  </Popover>

MultiSelectCustom.propTypes =
  className: PropTypes.string
  options: PropTypes.arrayOf(PropTypes.shape(
    value: PropTypes.string.isRequired
    label: PropTypes.string.isRequired
    disabled: PropTypes.bool
  )).isRequired
  onChange: PropTypes.func.isRequired
  selectedOptions: PropTypes.arrayOf(PropTypes.string)
  selectAllDisabled: PropTypes.bool

MultiSelectCustom.defaultProps =
  selectAllDisabled: false
  selectedOptions: []

module.exports = MultiSelectCustom
