Button = require 'components/common/button'
Translation = require 'components/mixins/translation'

SaveButton = createReactClass
  displayName: 'SaveButton'

  mixins: [Translation()]

  propTypes:
    onClick: PropTypes.func.isRequired
    title: PropTypes.string

  render: ->
    title = @props.title ? @i18n 'actions.save'
    classes = classNames "save", @props.className
    <Button className={classes} title={title} onClick={@props.onClick}/>

module.exports = SaveButton
