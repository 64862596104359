var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <tr>\n    <td>"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</td>\n    <td><input type=\"text\" name=\""
    + escapeExpression(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"_id","hash":{},"data":data}) : helper)))
    + "-researchEvidenceRelativeImportance\"></td>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (data && data.index), 0, {"name":"is","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </tr>\n";
},"2":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;
  return "      <td rowspan=\""
    + escapeExpression(lambda((depths[2] != null ? depths[2].includedOutcomesNumber : depths[2]), depth0))
    + "\">\n        "
    + escapeExpression(((helpers.contentEditable || (depth0 && depth0.contentEditable) || helperMissing).call(depth0, "certaintyOfEvidenceGroup", {"name":"contentEditable","hash":{},"data":data})))
    + "\n      </td>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <th colspan=\"4\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/es:outcome.impact", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n";
},"6":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "      <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "without", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.recommendationDenominator || (depth0 != null ? depth0.recommendationDenominator : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"recommendationDenominator","hash":{},"data":data}) : helper)))
    + "</th>\n      <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "with", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.intervention || (depth0 != null ? depth0.intervention : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"intervention","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.recommendationDenominator || (depth0 != null ? depth0.recommendationDenominator : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"recommendationDenominator","hash":{},"data":data}) : helper)))
    + "</th>\n      <th class=\"with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.confidence_interval", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "difference", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helper = (helper = helpers.recommendationDenominator || (depth0 != null ? depth0.recommendationDenominator : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"recommendationDenominator","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "confidence_interval", {"name":"i18n","hash":{},"data":data})))
    + "\n      </th>\n      <th class=\"with-info\">\n        <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.relative_effect_sof", {"name":"i18n","hash":{},"data":data})))
    + "\">\n        </span>\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relative_effect", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "confidence_interval", {"name":"i18n","hash":{},"data":data})))
    + "\n      </th>\n";
},"8":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  <tr>\n    <td rowspan=\""
    + escapeExpression(((helper = (helper = helpers.rowspan || (depth0 != null ? depth0.rowspan : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowspan","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</td>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "narrative", {"name":"is","hash":{},"fn":this.program(9, data, depths),"inverse":this.program(11, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <td rowspan=\""
    + escapeExpression(((helper = (helper = helpers.rowspan || (depth0 != null ? depth0.rowspan : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowspan","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helpers.displayQuality || (depth0 && depth0.displayQuality) || helperMissing).call(depth0, depth0, {"name":"displayQuality","hash":{},"data":data})))
    + "</td>\n  </tr>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.tailControls : depth0), {"name":"each","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <td colspan=\"4\">"
    + escapeExpression(((helper = (helper = helpers.narrativeDesc || (depth0 != null ? depth0.narrativeDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"narrativeDesc","hash":{},"data":data}) : helper)))
    + "</td>\n";
},"11":function(depth0,helpers,partials,data,depths) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <td rowspan=\""
    + escapeExpression(((helper = (helper = helpers.rowspan || (depth0 != null ? depth0.rowspan : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowspan","hash":{},"data":data}) : helper)))
    + "\">\n        "
    + escapeExpression(((helpers.recommendationControl || (depth0 && depth0.recommendationControl) || helperMissing).call(depth0, depth0, (depths[1] != null ? depths[1].effectDenominatorForRecommendation : depths[1]), {"name":"recommendationControl","hash":{},"data":data})))
    + "</td>\n      <td rowspan=\""
    + escapeExpression(((helper = (helper = helpers.rowspan || (depth0 != null ? depth0.rowspan : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowspan","hash":{},"data":data}) : helper)))
    + "\">\n        "
    + escapeExpression(((helpers.recommendationIntervention || (depth0 && depth0.recommendationIntervention) || helperMissing).call(depth0, depth0, (depths[1] != null ? depths[1].effectDenominatorForRecommendation : depths[1]), {"name":"recommendationIntervention","hash":{},"data":data})))
    + "</td>\n      <td rowspan=\"1\">"
    + escapeExpression(((helpers.displayAbsoluteEffect || (depth0 && depth0.displayAbsoluteEffect) || helperMissing).call(depth0, depth0, (depth0 != null ? depth0.headControl : depth0), {"name":"displayAbsoluteEffect","hash":{},"data":data})))
    + "</td>\n      <td rowspan=\""
    + escapeExpression(((helper = (helper = helpers.rowspan || (depth0 != null ? depth0.rowspan : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowspan","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helpers.displayRelativeEffect || (depth0 && depth0.displayRelativeEffect) || helperMissing).call(depth0, depth0, {"name":"displayRelativeEffect","hash":{},"data":data})))
    + "</td>\n";
},"13":function(depth0,helpers,partials,data,depths) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <tr>\n      <td>"
    + escapeExpression(((helpers.displayAbsoluteEffect || (depth0 && depth0.displayAbsoluteEffect) || helperMissing).call(depth0, depths[1], depth0, {"name":"displayAbsoluteEffect","hash":{},"data":data})))
    + "</td>\n    </tr>\n";
},"15":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <tfoot class=\"no-print\">\n      <tr><td colspan=\"6\">\n        <button class=\"choose-outcomes\" title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_outcomes_title", {"name":"i18n","hash":{},"data":data})))
    + "'>\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "choose_outcomes", {"name":"i18n","hash":{},"data":data})))
    + "\n        </button>\n      </td></tr>\n    </tfoot>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<strong>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relative_importance_label", {"name":"i18n","hash":{},"data":data})))
    + "</strong>\n<table class=\"standard-table research-evidence-relative-importance with-utilities\">\n  <tr>\n    <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/es:outcome.outcome", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n    <th class=\"with-info\">\n      <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.relative_importance", {"name":"i18n","hash":{},"data":data})))
    + "\">\n      </span>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relative_importance", {"name":"i18n","hash":{},"data":data})))
    + "\n    </th>\n    <th class=\"with-info\">\n      <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.evidence_certainty", {"name":"i18n","hash":{},"data":data})))
    + "\">\n      </span>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "evidence_certainty", {"name":"i18n","hash":{},"data":data})))
    + "\n    </th>\n  </tr>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.includedOutcomes : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</table>\n\n<strong>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "sof_comparison", {"name":"i18n","hash":{},"data":data})))
    + "</strong>: "
    + escapeExpression(((helper = (helper = helpers.tableTitle || (depth0 != null ? depth0.tableTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tableTitle","hash":{},"data":data}) : helper)))
    + "\n<table class=\"standard-table research-evidence-sof with-utilities\">\n  <tr>\n    <th>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "/es:outcome.outcome", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.$tableMode : depth0), "narrative", {"name":"is","hash":{},"fn":this.program(4, data, depths),"inverse":this.program(6, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <th class=\"with-info\">\n      <span class=\"info-sign\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "../tooltips.evidence_certainty_sof", {"name":"i18n","hash":{},"data":data})))
    + "\">\n      </span>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "evidence_certainty", {"name":"i18n","hash":{},"data":data})))
    + "\n    </th>\n  </tr>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.includedOutcomes : depth0), {"name":"each","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.chooseOutcomes : depth0), {"name":"if","hash":{},"fn":this.program(15, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</table>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}