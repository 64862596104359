var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.disconnected_info", {"name":"i18n","hash":{},"data":data})))
    + "<br><br>"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.disconnected_info_chrome", {"name":"i18n","hash":{},"data":data})))
    + "\">\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.disconnected_info", {"name":"i18n","hash":{},"data":data})))
    + "\">\n";
},"5":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <span class=\"workspace-name\">\n      "
    + escapeExpression(((helper = (helper = helpers.activeWorkspaceName || (depth0 != null ? depth0.activeWorkspaceName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"activeWorkspaceName","hash":{},"data":data}) : helper)))
    + "\n    </span>\n";
},"7":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "      <span class=\"donation\">\n        "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "donation.feel_free_to", {"name":"i18n","hash":{},"data":data})))
    + "\n        <button class=\"donate\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "donation.donate", {"name":"i18n","hash":{},"data":data})))
    + "\">\n          <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"43px\" height=\"21px\">\n            <defs>\n              <linearGradient id=\"grad1\" x1=\"0%\" y1=\"0%\" x2=\"100%\" y2=\"0%\">\n                <stop offset=\"0%\" stop-color=\"rgb(233, 84, 0)\" />\n                <stop offset=\"100%\" stop-color=\"rgb(208, 21, 255)\" />\n              </linearGradient>\n            </defs>\n            <text x=\"50%\" y=\"15\" fill=\"#fff\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "donation.donate", {"name":"i18n","hash":{},"data":data})))
    + "\n            </text>\n          </svg>\n        </button>\n      </span>\n";
},"9":function(depth0,helpers,partials,data) {
  return "        <button class=\"connection\"></button>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div id=\"header-message\">\n  <div id=\"header-update-info\" class=\"info\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.cache_updated", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n  <div id=\"header-disconnected-warning\" class=\"warning\">\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.disconnected_title", {"name":"i18n","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.disconnected_title_pt2", {"name":"i18n","hash":{},"data":data})))
    + "\n    <span class=\"question-mark-sign\"\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isChrome : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </span>\n  </div>\n  <div id=\"header-connection-timeout-warning\" class=\"warning\">\n      ";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.connection_timeout", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  </div>\n  <div id=\"header-email-not-confirmed\" class=\"info\">\n    ";
  stack1 = ((helper = (helper = helpers.emailConfirmationMessage || (depth0 != null ? depth0.emailConfirmationMessage : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"emailConfirmationMessage","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  </div>\n</div>\n<div class=\"wrapper\">\n  <h1 class=\"logo\">\n    <a href='"
    + escapeExpression(((helper = (helper = helpers.projectsListURL || (depth0 != null ? depth0.projectsListURL : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"projectsListURL","hash":{},"data":data}) : helper)))
    + "' title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header.go_home", {"name":"i18n","hash":{},"data":data})))
    + "'>GDT</a>\n  </h1>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.activeWorkspaceName : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <nav class=\"projects\"></nav>\n  <div class=\"update-installed-info\">\n    <span class=\"info\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.update_installed", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n    <a class=\"whats-new-link\" target=\"_blank\" href=\""
    + escapeExpression(((helper = (helper = helpers.whatsNewLink || (depth0 != null ? depth0.whatsNewLink : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"whatsNewLink","hash":{},"data":data}) : helper)))
    + "\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "messages.see_what_new", {"name":"i18n","hash":{},"data":data})))
    + "\n    </a>\n  </div>\n  <menu type=\"toolbar\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showDonateButton : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <button class=\"help\" title=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header.help", {"name":"i18n","hash":{},"data":data})))
    + "\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "help.title", {"name":"i18n","hash":{},"data":data})))
    + "</button>\n    <button title='"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "header.settings", {"name":"i18n","hash":{},"data":data})))
    + "' class=\"settings\"></button>\n";
  stack1 = ((helpers.switchOn || (depth0 && depth0.switchOn) || helperMissing).call(depth0, "connection-indicator", {"name":"switchOn","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </menu>\n  <div id=\"user-account-menu\"></div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}