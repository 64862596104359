{ kebabCase } = require 'lodash'
Translation = require 'components/mixins/translation'
Popover = require 'components/common/popover'
{ SECTION_STATUSES } = require 'lib/document_sections_v2_helper'

possibleStatusesDict = SECTION_STATUSES
  .reduce (obj, status) ->
    obj[status] =
      text: $.t("docsec:section_statuses:#{status.toLowerCase()}")
      className: "status-#{kebabCase(status)}"
    obj
  , {}

ChapterSectionStatus = createReactClass
  displayName: 'ChapterSectionStatus'

  mixins: [
    Translation('docsec:section_statuses')
  ]

  propTypes:
    status: PropTypes.string
    onChange: PropTypes.func.isRequired

  getInitialState: ->
    menuOpen: false

  toggleMenuOpen: ->
    @setState
      menuOpen: !@state.menuOpen

  handleChange: (status) ->
    @props.onChange status
    @setState
      menuOpen: false

  render: ->
    { status } = @props
    { menuOpen } = @state
    statusDetails = possibleStatusesDict[status] ||
      text: @i18n 'no_status'
      className: 'status-no-status'

    <Popover
      visible={menuOpen}
      onRequestClose={@toggleMenuOpen}
      positionParams={alignment: 'center'}
    >
      <div
        className={classNames('status-content', statusDetails.className)}
        onClick={@toggleMenuOpen}
      >
        {statusDetails.text}
      </div>
      <div className="statuses-options">
        {
          _.keys(possibleStatusesDict).map((possibleStatus) => (
            <div
              key={possibleStatus}
              className={classNames(
                    'status-option',
                    'status-content',
                    possibleStatusesDict[possibleStatus].className
                  )}
              onClick={() => @handleChange(possibleStatus)}
            >
              {possibleStatusesDict[possibleStatus].text}
            </div>
          ))
        }
      </div>
    </Popover>

module.exports = ChapterSectionStatus
