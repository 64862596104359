var OutcomeScaleTypes, ViewTrait,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ViewTrait = require('base/lib/traits/view_trait');

module.exports = OutcomeScaleTypes = (function(_super) {
  __extends(OutcomeScaleTypes, _super);

  function OutcomeScaleTypes() {
    return OutcomeScaleTypes.__super__.constructor.apply(this, arguments);
  }

  OutcomeScaleTypes.prototype.apply = function(view) {
    OutcomeScaleTypes.__super__.apply.apply(this, arguments);
    return this._addFunction(view, 'toggleScaleFields');
  };

  OutcomeScaleTypes.prototype.toggleScaleFields = function(_originalFunction, _trait) {
    var scaleType;
    scaleType = this.model.get('scaleType');
    this.$('.range-of-scores').toggle(scaleType === 'ordinal');
    return this.$('.conventional-scale-limits').toggle(scaleType === 'ratioOrInterval');
  };

  return OutcomeScaleTypes;

})(ViewTrait);
