var DirectnessTableView, ModalView, mediator, template, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModalView = require('base/views/modal_view');

template = require('views/templates/outcome_edit/directness_table');

mediator = require('mediator');

utils = require('base/lib/utils');

module.exports = DirectnessTableView = (function(_super) {
  __extends(DirectnessTableView, _super);

  function DirectnessTableView() {
    return DirectnessTableView.__super__.constructor.apply(this, arguments);
  }

  DirectnessTableView.prototype.template = template;

  DirectnessTableView.prototype.id = 'directness-table';

  DirectnessTableView.prototype.title = '';

  DirectnessTableView.prototype.shortcuts = {
    'esc': 'cancel',
    'ctrl+s': 'apply',
    'tab': 'focusNextElement',
    'shift+tab': 'focusPrevElement'
  };

  DirectnessTableView.prototype.initialize = function() {
    this.options.position = 'top';
    this.options.offsetY = 50;
    this.options.width = 1000;
    DirectnessTableView.__super__.initialize.apply(this, arguments);
    this.focusableElementsSelector = 'textarea, input, button';
    this.delegate('click', function(e) {
      return e.stopPropagation();
    });
    this.delegate('click', 'button.cancel', this.cancel);
    this.delegate('click', 'button.apply', this.apply);
    return this.delegate('focus', this.focusableElementsSelector, this.setFocus);
  };

  DirectnessTableView.prototype.afterRender = function() {
    DirectnessTableView.__super__.afterRender.apply(this, arguments);
    this.setFocusableElements();
    if (this.focusableElements.eq(0)) {
      this.focusableElements.eq(0).focus();
      return this.focusedIndex = 0;
    } else {
      return this.focusedIndex = -1;
    }
  };

  DirectnessTableView.prototype.setFocusableElements = function() {
    return this.focusableElements = this.$(this.focusableElementsSelector);
  };

  DirectnessTableView.prototype.setFocus = function(e) {
    var $target, newFocusedIndex;
    $target = $(e.target);
    newFocusedIndex = this.focusableElements.index($target);
    if (newFocusedIndex !== this.focusedIndex) {
      this.focusedIndex = newFocusedIndex;
      return this.focusableElements.eq(newFocusedIndex).focus();
    }
  };

  DirectnessTableView.prototype.cancel = function(e) {
    e.stopPropagation();
    return this.dispose();
  };

  DirectnessTableView.prototype.apply = function(e) {
    e.stopPropagation();
    _(this.$('textarea')).each((function(_this) {
      return function(ta) {
        var $ta;
        $ta = $(ta);
        if ($ta.attr('name')) {
          return _this.model.set($ta.attr('name'), $ta.val());
        }
      };
    })(this));
    _(this.$('.directness-radios')).each((function(_this) {
      return function(dr) {
        var $checkedInput;
        $checkedInput = $(dr).find('input[type=radio]:checked');
        if ($checkedInput.attr('name')) {
          return _this.model.set($checkedInput.attr('name'), $checkedInput.val());
        }
      };
    })(this));
    this.model.set('indirectness', this.$('input[name=indirectness]:checked').val());
    this.model.save();
    this.dispose();
    return false;
  };

  DirectnessTableView.prototype.getTemplateData = function() {
    var data, questionModel;
    data = DirectnessTableView.__super__.getTemplateData.apply(this, arguments);
    questionModel = this.options.questionModel;
    data.intervention = questionModel.get('intervention');
    data.comparison = questionModel.get('comparison');
    data.questionType = questionModel.get('type');
    return data;
  };

  DirectnessTableView.prototype.focusNextElement = function() {
    mediator.log("next el: " + (utils.cycleUp(this.focusedIndex, this.focusableElements.length)));
    this.focusableElements.eq(utils.cycleUp(this.focusedIndex, this.focusableElements.length)).focus();
    return false;
  };

  DirectnessTableView.prototype.focusPrevElement = function() {
    this.focusableElements.eq(utils.cycleDown(this.focusedIndex, this.focusableElements.length)).focus();
    return false;
  };

  return DirectnessTableView;

})(ModalView);
