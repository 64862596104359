var ItemWithCommentsMixin, OutcomeCommentsMixin, OutcomesGenerationActions;

OutcomesGenerationActions = require('actions/outcomes_generation_actions');

ItemWithCommentsMixin = require('components/mixins/item_with_comments_mixin');

OutcomeCommentsMixin = _.extend({}, ItemWithCommentsMixin, {
  onApplyAdminComment: function(stepName) {
    return (function(_this) {
      return function(newText) {
        var gIndex, oIndex, _ref;
        _ref = _this.props, gIndex = _ref.gIndex, oIndex = _ref.index;
        return OutcomesGenerationActions.saveAdminComment({
          gIndex: gIndex,
          oIndex: oIndex,
          newText: newText,
          step: stepName
        });
      };
    })(this);
  }
});

module.exports = OutcomeCommentsMixin;
