var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div>\n  <p>You receive this message since you are the "
    + escapeExpression(((helper = (helper = helpers.projectName || (depth0 != null ? depth0.projectName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"projectName","hash":{},"data":data}) : helper)))
    + " panel member or participate in the project in other capacity.</p>\n\n  <div>You will receive 3 messages (including this one) with request for your input:</div>\n  <ol>\n    <li>\n      to generate/brainstorm the list of outcomes possibly important to be taken into account in the guidelines</li>\n    <li>\n      to rate importance of  the outcomes generated during brainstorming\n    </li>\n    <li>\n      to accept the final list of outcomes to be taken into account in this project.\n    </li>\n  </ol>\n\n  <div>We will be grateful for your opinion which outcomes would be possibly important:</div>\n  <ol>\n    <li>\n      to patients in the context of the interventions specified in each question (e.g. symptoms, quality of life, etc.)\n    </li>\n    <li>\n      to those making a decision/recommendation about the choice of intervention specified in each question (usually the same outcomes that are important to patients but also e.g. development of antibiotic resistance that may be important for decision making and not directly to patients).\n    </li>\n  </ol>\n\n  <p>\n    <div>Please follow this link to the list of currently proposed outcomes:</div>\n    <a href=\"{#link}\">{#link}</a>\n  </p>\n\n  <p>\n    ACTION: please review already proposed outcomes and <b>add new outcomes</b> that you think should be taken into account in the guidelines but have not yet been included. You may also comment on any outcomes if you believe that clarification is necessary.\n  </p>\n\n  <p>\n    You should reserve approximately 15-20 minutes of your time to do this.\n  </p>\n\n  <p>\n    Thank you very much for your input and time.\n  </p>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}