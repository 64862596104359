TabsPane = require 'components/common/tabs_pane'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

TABS = ['draftJudgement', 'votingResults', 'consensus']

VotingTabs = createReactClass
  displayName: 'VotingTabs'

  propTypes:
    activeTab: PropTypes.string.isRequired
    onTabClick: PropTypes.func.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  _getTabs: ->
    TABS.map (tabName, idx) =>
      tabLabel = @i18n _.str.underscored tabName
      { tabName, tabLabel }

  render: ->
    <TabsPane
      activeTab={@props.activeTab}
      onTabClick={@props.onTabClick}
      tabs={@_getTabs()}
    />

module.exports = VotingTabs
