MultiComparisonsActions = require 'actions/multi_comparisons_actions'
OverarchingQuestion = require './overarching_question'

ComparisonsReviewHeader = createReactClass
  displayName: 'ComparisonsReviewHeader'

  propTypes:
    questions: PropTypes.instanceOf(Immutable.List).isRequired,
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired,
    comparisonsEditData: PropTypes.instanceOf(Immutable.Map).isRequired

  getInitialState: ->
    expanded: false
    editing: false

  toggleExpand: ->
    @setState 'expanded': not @state.expanded

  onSave: ->
    MultiComparisonsActions.saveInBackground()
    @setState 'editing': false

  editMultiComparison: ->
    @setState 'editing': true

  render: ->
    <OverarchingQuestion
      editingComparison
      questions={@props.questions}
      questionGroups={@props.questionGroups}
      comparisonsEditData={@props.comparisonsEditData}
      multiComparison={@props.comparisonsEditData}
      editing={@state.editing}
      expanded={@state.expanded}
      toggleExpand={@toggleExpand}
      onSave={@onSave}
      onEdit={@editMultiComparison}
    />

module.exports = ComparisonsReviewHeader
