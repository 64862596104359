var EvidenceSynthesesToolbarView, RecommendationsView, RecommendationsWithToolbarView, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

EvidenceSynthesesToolbarView = require('views/evidence_syntheses_toolbar_view');

RecommendationsView = require('views/evidence_syntheses/recommendations_view');

mediator = require('mediator');

module.exports = RecommendationsWithToolbarView = (function(_super) {
  __extends(RecommendationsWithToolbarView, _super);

  function RecommendationsWithToolbarView() {
    return RecommendationsWithToolbarView.__super__.constructor.apply(this, arguments);
  }

  RecommendationsWithToolbarView.prototype.initialize = function() {
    var groupsPromise;
    RecommendationsWithToolbarView.__super__.initialize.apply(this, arguments);
    this.subview("recommendationsView", new RecommendationsView({
      model: this.model,
      parentView: this,
      autoRender: true,
      container: '#page-container'
    }));
    groupsPromise = mediator.project.getDocFor('comparisons_question_groups');
    return this.subview('toolbar', new EvidenceSynthesesToolbarView({
      moduleName: this.options.moduleName,
      parentView: this,
      baseUrl: this.options.baseUrl,
      model: this.model,
      groupsPromise: groupsPromise,
      submodule: "recommendations-" + (mediator.project.get('recommendationsMode'))
    }));
  };

  return RecommendationsWithToolbarView;

})(View);
