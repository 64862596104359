var Outcome, formatters, mediator, stats, utils;

stats = require('lib/statistical_utils');

utils = require('base/lib/utils');

Outcome = require('models/outcome');

formatters = require('lib/formatters');

mediator = require('mediator');

module.exports = {
  convertTreatment: function(question, comparison) {
    var _countPopulation, _getAbsDifference, _getCERForEffect, _getCertaintyExplanation, _getCertaintyFootnote, _getCiFrom, _getCiTo, _getComparisonValue, _getComparisonValueWithEffect, _getDesignStudies, _getEffectSize, _getFollowUp, _getIntervention, _getInterventionValue, _getInterventionValueWithEffect, _getMeasure, _getOutcomes, _getPopulations, _getScale, _getTotalParticipants, _getUnitsType, _maybeClampEffectSize, _preparePopulation;
    _getIntervention = function() {
      return [
        {
          id: 1,
          active: true,
          short_name: question.get('comparison')
        }, {
          id: 2,
          active: true,
          short_name: question.get('intervention')
        }
      ];
    };
    _getScale = function(outcome) {
      var scale;
      scale = (function() {
        switch (outcome.get('type')) {
          case 'dich':
          case 'time_to_event':
            return 'dichotomous';
          case 'cont':
            return 'continuous';
          case 'narrative':
            return 'narrative';
          default:
            return 'non_standard';
        }
      })();
      if (_getDesignStudies(outcome) === 'case_series') {
        scale = 'non_standard';
      }
      if (['NOT_REPORTED', 'NOT_MEASURED'].indexOf(outcome.get('metaType')) >= 0) {
        scale = 'empty';
      }
      return scale;
    };
    _getAbsDifference = function(outcome) {
      var value, _ref;
      if ((_ref = outcome.get('type')) === 'dich' || _ref === 'time_to_event') {
        return Math.abs(parseInt(outcome.get('interventionCount')) - parseInt(outcome.get('controlCount')));
      } else {
        value = Math.abs(parseInt(outcome.get('changeScoreInControlsValue')) - parseInt(outcome.get('effectSize')));
        return parseFloat(Math.round(value * 100) / 100).toFixed(1);
      }
    };
    _getTotalParticipants = function(outcome) {
      return outcome.get('interventionTotal') + outcome.get('controlTotal');
    };
    _getCiFrom = function(outcome, absEffectFromKey) {
      var absEffect, _ref;
      absEffect = outcome.get(absEffectFromKey);
      if ((_ref = outcome.get('type')) === 'dich' || _ref === 'time_to_event') {
        if (absEffect >= 0) {
          return -absEffect;
        } else {
          return Math.abs(absEffect);
        }
      } else {
        return outcome.get('confidenceIntervalFrom');
      }
    };
    _getCiTo = function(outcome, absEffectToKey) {
      var absEffect, _ref;
      absEffect = outcome.get(absEffectToKey);
      if ((_ref = outcome.get('type')) === 'dich' || _ref === 'time_to_event') {
        if (absEffect >= 0) {
          return -absEffect;
        } else {
          return Math.abs(absEffect);
        }
      } else {
        return outcome.get('confidenceIntervalTo');
      }
    };
    _getEffectSize = function(outcome) {
      var cer, eventType, measure, _ref;
      measure = outcome.get('effectMeasure');
      cer = stats.getControlEventRate(outcome.get('controlCount'), outcome.get('controlTotal'));
      switch (measure) {
        case 'OR':
          return stats.riskRatioFromOddsRatio(cer, outcome.get('effectSize'));
        case 'HR':
          eventType = (_ref = outcome.get('eventType')) != null ? _ref : 'event';
          return stats.riskRatioFromHazardRatio(cer, outcome.get('effectSize'), eventType);
        default:
          return parseFloat(outcome.get('effectSize'));
      }
    };
    _getComparisonValue = function(outcome) {
      var _ref;
      if ((_ref = outcome.get('type')) === 'dich' || _ref === 'time_to_event') {
        return Math.round(outcome.get('controlCount') * parseFloat(outcome.get('absDenominator') / outcome.get('controlTotal')));
      } else {
        return parseFloat(outcome.get('changeScoreInControlsValue'));
      }
    };
    _getCERForEffect = function(outcome, effect) {
      return outcome.get(effect.toLowerCase() + 'ControlRisk') / 100;
    };
    _getComparisonValueWithEffect = function(outcome, effect) {
      var absDenominator;
      absDenominator = outcome.get('absDenominator');
      return Math.round(_getCERForEffect(outcome, effect) * absDenominator);
    };
    _getInterventionValue = function(outcome) {
      var comparisonValue, effectSize, value, _ref;
      comparisonValue = _getComparisonValue(outcome);
      effectSize = _getEffectSize(outcome);
      if ((comparisonValue != null) && (effectSize != null)) {
        if ((_ref = outcome.get('type')) === 'dich' || _ref === 'time_to_event') {
          return Math.round(comparisonValue * effectSize);
        } else {
          value = parseFloat(comparisonValue + effectSize);
          if (value % 1 !== 0) {
            return value.toFixed(2);
          } else {
            return value.toFixed(0);
          }
        }
      } else {
        return Math.round(outcome.get('interventionCount') * outcome.get('absDenominator') / outcome.get('interventionTotal'));
      }
    };
    _getInterventionValueWithEffect = function(outcome, effect) {
      var cer, comparisonValue, effectMeasure, effectSize, eventType;
      effectMeasure = outcome.get('effectMeasure');
      effectSize = parseFloat(outcome.get('effectSize'));
      cer = _getCERForEffect(outcome, effect);
      effectSize = (function() {
        var _ref;
        switch (effectMeasure) {
          case 'OR':
            return stats.riskRatioFromOddsRatio(cer, effectSize);
          case 'HR':
            eventType = (_ref = outcome.get('eventType')) != null ? _ref : 'event';
            return stats.riskRatioFromHazardRatio(cer, effectSize, eventType);
          default:
            return effectSize;
        }
      })();
      comparisonValue = _getComparisonValueWithEffect(outcome, effect);
      return Math.round(comparisonValue * effectSize);
    };
    _preparePopulation = function(outcome, comparisonValue, interventionValue, label, effect) {
      return {
        patient_difference_type: 'patients',
        with_intervention: outcome.get('interventionCount'),
        without_intervention: outcome.get('controlCount'),
        absolute_difference: _getAbsDifference(outcome),
        custom_label: label,
        interventions_data: {
          '1': {
            versus: {
              '1': {
                ci_from: 0,
                ci_to: 0
              },
              '2': {
                impact: outcome.get('narrativeDesc'),
                statistical_ratio: {
                  ci_from: outcome.get('confidenceIntervalFrom'),
                  ci_to: outcome.get('confidenceIntervalTo'),
                  type: outcome.get('effectMeasure'),
                  value: outcome.get('effectSize')
                },
                quality_of_evidence: outcome.get('quality'),
                participants: _getTotalParticipants(outcome),
                ci_from: _getCiFrom(outcome, 'absEffectFrom' + effect),
                ci_to: _getCiTo(outcome, 'absEffectTo' + effect),
                studies: outcome.get('noOfStudies')
              }
            },
            value: comparisonValue
          },
          '2': {
            versus: {
              '1': {
                ci_from: 0,
                ci_to: 0
              },
              '2': {
                ci_from: 0,
                ci_to: 0
              }
            },
            value: interventionValue
          }
        }
      };
    };
    _maybeClampEffectSize = function(outcome, effectSize) {
      if (outcome.get('type') === 'cont') {
        return effectSize;
      } else {
        return Math.min(outcome.get('absDenominator'), effectSize);
      }
    };
    _countPopulation = function(outcome, effect) {
      var comparisonValue, interventionValue;
      comparisonValue = _maybeClampEffectSize(outcome, _getComparisonValueWithEffect(outcome, effect));
      interventionValue = _maybeClampEffectSize(outcome, _getInterventionValueWithEffect(outcome, effect));
      return _preparePopulation(outcome, comparisonValue, interventionValue, outcome.get(effect.toLowerCase() + 'ControlLabel'), effect);
    };
    _getPopulations = function(outcome) {
      var comparisonValue, interventionValue, label, populations;
      populations = [];
      if (outcome.get('calculatedControl')) {
        comparisonValue = _maybeClampEffectSize(outcome, _getComparisonValue(outcome));
        interventionValue = _maybeClampEffectSize(outcome, _getInterventionValue(outcome));
        label = $.t("es:outcome.study_population_label");
        populations.push(_preparePopulation(outcome, comparisonValue, interventionValue, label, ''));
      }
      if (outcome.get('lowControl')) {
        populations.push(_countPopulation(outcome, 'Low'));
      }
      if (outcome.get('moderateControl')) {
        populations.push(_countPopulation(outcome, 'Moderate'));
      }
      if (outcome.get('highControl')) {
        populations.push(_countPopulation(outcome, 'High'));
      }
      return populations;
    };
    _getDesignStudies = function(outcome) {
      var ds;
      ds = outcome.get('designStudies');
      if (ds != null) {
        if (ds.name === 'RandTrials') {
          return 'randomised_trials';
        } else {
          return ds.value;
        }
      }
    };
    _getMeasure = function(outcome) {
      var _ref;
      if ((_ref = outcome.get('type')) === 'dich' || _ref === 'time_to_event') {
        return $.t('es:isof.will_develop_an_outcome');
      } else {
        return outcome.get('name').toLowerCase();
      }
    };
    _getUnitsType = function(outcome) {
      switch (outcome.get('type')) {
        case 'dich':
        case 'time_to_event':
          return outcome.get('unitsType') || 'countable';
        case 'cont':
          if (outcome.get('unitsType') === 'countable') {
            return 'uncountable';
          } else {
            return 'countable';
          }
          break;
        default:
          return 'countable';
      }
    };
    _getCertaintyFootnote = function(outcome) {
      var text;
      text = _.chain(Outcome.QUALITY_MODIFIERS).filter(function(field) {
        var _ref, _ref1;
        return ((_ref = outcome.get('footnotes')) != null ? (_ref1 = _ref[field]) != null ? _ref1.length : void 0 : void 0) > 0;
      }).map(function(field) {
        var fieldName, footnotes, footnotesEls, _ref;
        fieldName = $.t("es:outcome." + (utils.toUnderscore(field)));
        footnotes = _.chain((_ref = outcome.get('footnotes')[field]) != null ? _ref : []).map(function(footnoteId) {
          return question.get('footnotesList').notes[footnoteId];
        }).compact().map(function(footnote) {
          return "<li>" + footnote + "</li>";
        }).value();
        if (footnotes.length > 0) {
          footnotesEls = footnotes.join('');
          return "<p style='font-weight: bold'>" + fieldName + ":</p><ul>" + footnotesEls + "</ul>";
        } else {
          return '';
        }
      }).value().join('').trim();
      if (text.length === 0) {
        return null;
      } else {
        return text;
      }
    };
    _getCertaintyExplanation = function(outcome) {
      var text;
      text = _.chain(Outcome.QUALITY_MODIFIERS).filter(function(field) {
        var fieldValue;
        fieldValue = outcome.get(field);
        return (fieldValue != null) && fieldValue.score !== 0;
      }).map(function(field) {
        var label;
        label = outcome.get(field).label;
        return $.t("isof:certainty_explanation." + (utils.toUnderscore(field)) + "." + label);
      }).reduce((function(acc, txt) {
        return "" + acc + "<p>" + txt + "</p>";
      }), '').value().trim();
      if (text.length === 0) {
        return null;
      } else {
        return text;
      }
    };
    _getFollowUp = function(outcome) {
      return formatters.outcome._followUpOrTiming(outcome.toJSON());
    };
    _getOutcomes = function() {
      return question.get('outcomes').models.map(function(outcome) {
        return {
          id: outcome.get('_id'),
          certainty_footnote: _getCertaintyFootnote(outcome),
          certainty_explanation: _getCertaintyExplanation(outcome),
          desirable: false,
          title: outcome.get('name'),
          follow_up: _getFollowUp(outcome),
          summary: '',
          population_index: 0,
          plain_language_summary: outcome.get('plain_language_summary'),
          scale: _getScale(outcome),
          metaType: outcome.get('metaType'),
          scale_from: outcome.get('scaleFrom'),
          scale_to: outcome.get('scaleTo'),
          total: outcome.get('absDenominator'),
          measure: _getMeasure(outcome),
          participants: _getTotalParticipants(outcome),
          participants_global_value: true,
          measurement_unit_plural: outcome.get('units'),
          units_type: _getUnitsType(outcome),
          designStudies: _getDesignStudies(outcome),
          population: _getPopulations(outcome),
          scaleType: outcome.get('scaleType'),
          conventionalScaleLowerLimit: outcome.get('conventionalScaleLowerLimit'),
          conventionalScaleUpperLimit: outcome.get('conventionalScaleUpperLimit')
        };
      });
    };
    return {
      ok: true,
      acp: mediator.services.switches.isServerSwitchOn('acpFeatures'),
      isDiagnostic: question.isDiagnostic(),
      reference_intervention_id: 1,
      interventions: _getIntervention(),
      comparison: question.get('comparison'),
      type_of_patient_singular: 'patient',
      type_of_patient_plural: 'patients',
      base_document: null,
      outcomes: _getOutcomes()
    };
  }
};
