MarketingScreen = require 'components/common/marketing_screen'
Translation = require 'components/mixins/translation'
{ license_url, example_da_url } = require 'lib/constants'

DecisionAidsMarketingScreen = createReactClass
  displayName: 'DecisionAidsMarketingScreen'

  mixins: [Translation('marketing')]

  render: ->
    <MarketingScreen
      descriptions={@i18n 'decision_aids.description', returnObjects: true}
      licenseInfoTitle={@i18n 'decision_aids.license_info_title'}
      licenseInfoDesc={@i18n 'license_info_desc', link: license_url}
      imgName="da.png"
    >
      <div className="dissemination-da-example">
        <a
          className="btn"
          rel="noreferrer noopener"
          target="_blank"
          href={example_da_url}
        >
          {@i18n 'decision_aids.see_example'}
        </a>
      </div>
    </MarketingScreen>

module.exports = DecisionAidsMarketingScreen
