ApplyCancelButtons = require 'components/common/apply_cancel_buttons'

{ QuestionTags, QUESTION_TAGS_PROPS } = require 'components/common/question_tags'
Select = require 'components/common/select_custom'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'

fieldShape = PropTypes.shape
  key: PropTypes.string.isRequired
  disabled: PropTypes.bool
  nameI18nKey: PropTypes.string
  type: PropTypes.oneOf([
    'text'
    'tags'
    'select'
    'separator'
  ]).isRequired
  options: PropTypes.arrayOf(PropTypes.shape(
    text: PropTypes.string.isRequired
    value: PropTypes.string.isRequired
  ))
  tagsProps: PropTypes.shape(_.omit(QUESTION_TAGS_PROPS, 'question'))
  value: PropTypes.oneOfType([
    PropTypes.string
    PropTypes.arrayOf(PropTypes.string)
    PropTypes.instanceOf(Immutable.Map)
    PropTypes.instanceOf(Immutable.List)
  ])
  overrideProps: PropTypes.object

QuestionCreator = createReactClass
  displayName: 'QuestionCreator'
  propTypes:
    creating: PropTypes.bool.isRequired
    fields: PropTypes.arrayOf(fieldShape).isRequired
    isFetching: PropTypes.bool.isRequired
    onChange: PropTypes.func.isRequired
    onSave: PropTypes.func.isRequired
    onCancel: PropTypes.func.isRequired
    question: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [Translation()]

  onFormChange: (key) -> (e) =>
    { onChange } = @props
    onChange(key)(e.target.value)

  render: ->
    { creating, fields, isFetching, onCancel, onChange, onSave, question } = @props

    return <Spinner /> if isFetching

    <div className="question-creator">
      <div className="question-creator__form">
        {_.map fields, (field) =>
          { disabled, key, nameI18nKey, overrideProps, type } = field
          overrideProps ?= {}

          switch type
            when 'text'
              propsToPass = _.extend {
                disabled
                onChange: @onFormChange key
                type: 'text'
                value: question.get key, ''
              }, overrideProps

              <div className="form__text mb-20" key={key}>
                <label>
                  <span className="label-text">
                    {@i18n nameI18nKey}
                  </span>
                  <input {...propsToPass} />
                </label>
              </div>
            when 'select'
              { options } = field
              propsToPass = _.extend {
                disabled
                onChange: onChange key
                options
                selected: question.get key
              }, overrideProps

              <div className="form__select mb-20" key={key}>
                <label>
                  <span className="label-text">
                    {@i18n nameI18nKey}
                  </span>
                </label>
                <Select {...propsToPass} />
              </div>
            when 'tags'
              { tagsProps } = field

              <div className="form__tags mb-20" key={key}>
                <QuestionTags question={question} {...tagsProps} />
              </div>
            when 'separator'
              <hr key={key} />
            else
              null
        }
      </div>
      <ApplyCancelButtons
        applyLabel={@i18n if creating then 'actions.create' else 'actions.save'}
        onApply={onSave}
        onCancel={onCancel}
      />
    </div>

module.exports = {
  fieldShape
  QuestionCreator
}
