var EvidenceSynthesesController, MdgRecommendationsView, MdgSearchStrategyView, MdgTableView, ModuleController, OverReviewView, PresentationsWithToolbarView, QualityIndicators, QuestionWithOutcomesView, QuestionsViewReact, RecommendationsWithToolbarView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

MdgSearchStrategyView = require('views/mdg_tables/search_strategy_view');

MdgTableView = require('views/mdg_tables/table_view');

MdgRecommendationsView = require('views/mdg_tables/recommendations_view');

mediator = require('mediator');

ModuleController = require('controllers/base/module_controller');

OverReviewView = require('views/evidence_syntheses/over_review_view');

PresentationsWithToolbarView = require('views/evidence_syntheses/presentations_with_toolbar_view');

QualityIndicators = require('views/quality_indicators/index_view');

QuestionsViewReact = require('views/evidence_syntheses/questions_view_react');

QuestionWithOutcomesView = require('views/evidence_syntheses/question_with_outcomes_view');

RecommendationsWithToolbarView = require('views/evidence_syntheses/recommendations_with_toolbar_view');

module.exports = EvidenceSynthesesController = (function(_super) {
  __extends(EvidenceSynthesesController, _super);

  function EvidenceSynthesesController() {
    return EvidenceSynthesesController.__super__.constructor.apply(this, arguments);
  }

  EvidenceSynthesesController.prototype.viewsForSubmodules = {
    'quality-of-evidence': QuestionWithOutcomesView,
    'recommendations': RecommendationsWithToolbarView,
    'recommendations-static': RecommendationsWithToolbarView,
    'presentations': PresentationsWithToolbarView,
    'over-review': OverReviewView,
    'mdg-search-strategy': MdgSearchStrategyView,
    'mdg-evidence-table': MdgTableView,
    'mdg-recommendations': MdgRecommendationsView,
    'quality-indicators': QualityIndicators
  };

  EvidenceSynthesesController.prototype.defaultSubmodule = function() {
    var isQOEEnabled;
    isQOEEnabled = mediator.services.modules.isVisible('evidence_syntheses#quality_of_evidence', mediator.project);
    if (isQOEEnabled) {
      return 'quality-of-evidence';
    } else {
      return 'recommendations';
    }
  };

  EvidenceSynthesesController.prototype.index = function() {
    this.view = new QuestionsViewReact({
      moduleName: this.moduleName
    });
    return mediator.publish('questionChanged', null);
  };

  EvidenceSynthesesController.prototype.question = function(params) {
    var newParams;
    if (mediator.questions.get(params.id)) {
      newParams = _.extend(params, {
        submodule: this.defaultSubmodule()
      });
      return this.redirectTo(this.moduleName, 'submodule', newParams, {
        forceStartup: true
      });
    } else {
      return this.redirectTo(this.moduleName, 'index', {}, {
        forceStartup: true
      });
    }
  };

  EvidenceSynthesesController.prototype._viewParams = function(params) {
    var question;
    question = mediator.questions.get(params.id);
    mediator.publish('questionChanged', params.id);
    return {
      model: question,
      questionId: params.id
    };
  };

  EvidenceSynthesesController.prototype._submoduleNotFound = function(params) {
    EvidenceSynthesesController.__super__._submoduleNotFound.apply(this, arguments);
    return this.question(params);
  };

  return EvidenceSynthesesController;

})(ModuleController);
