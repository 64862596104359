ConnectStore = require 'components/enhancers/connect_store'
OrganizationsStore = require 'stores/organizations_store'
QuestionsStatusesStore = require 'stores/questions_statuses_store'
ProgressSelect = require 'components/evidence_syntheses/progress_select'

storeConnectors =
  OrganizationsStore: (Store) ->
    projectFromOrganization: Store.isCurrentProjectFromOrganization()
  QuestionsStatusesStore: (Store, props) ->
    questionStatuses: Store.getQuestionStatuses props.questionId

ConnectedProgressSelect = createReactClass

  render: ->
    return null unless @props.projectFromOrganization
    <ProgressSelect
      questionId={@props.questionId}
      groupKey={@props.groupKey}
      questionStatuses={@props.questionStatuses}
    />


module.exports = ConnectStore(ConnectedProgressSelect,
  [
    QuestionsStatusesStore,
    OrganizationsStore,
  ]
  storeConnectors
)
