var BuiltInType, GdtDocFieldType, TypeValidationError, array, arrayParser, boolean, booleanParser, builtInType, builtInTypeCheck, checksProduct, checksSum, getBuiltInValueType, getValueType, max, min, minLength, number, numberParser, object, objectParser, string, stringParser,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

GdtDocFieldType = require('lib/db_docs/field_types/FieldType');

TypeValidationError = require('lib/db_docs/field_types/type_validation_error');

BuiltInType = (function(_super) {
  __extends(BuiltInType, _super);

  function BuiltInType() {
    return BuiltInType.__super__.constructor.apply(this, arguments);
  }

  return BuiltInType;

})(GdtDocFieldType);

checksSum = function() {
  var checks;
  checks = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return function(val) {
    var check, checkResult, lastFail, _i, _len;
    lastFail = null;
    for (_i = 0, _len = checks.length; _i < _len; _i++) {
      check = checks[_i];
      checkResult = check(val);
      if (!(checkResult instanceof TypeValidationError)) {
        return val;
      }
      lastFail = checkResult;
    }
    return lastFail;
  };
};

checksProduct = function() {
  var checks;
  checks = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return function(val) {
    var check, checkResult, _i, _len;
    for (_i = 0, _len = checks.length; _i < _len; _i++) {
      check = checks[_i];
      checkResult = check(val);
      if (checkResult instanceof TypeValidationError) {
        return checkResult;
      }
    }
    return val;
  };
};

getBuiltInValueType = function(val) {
  switch (val.constructor) {
    case Array:
      return 'Array';
    case Boolean:
      return 'Boolean';
    case Number:
      return 'Number';
    case Object:
      return 'Object';
    case String:
      return 'String';
  }
};

getValueType = function(val) {
  var _ref;
  if (val === null) {
    return 'null';
  } else if (val === void 0) {
    return 'undefined';
  } else {
    return (_ref = val.constructor) != null ? _ref.name : void 0;
  }
};

min = function(min) {
  return function(value) {
    if (value < min) {
      return new TypeValidationError({
        message: "Expected value to be greater or equal to " + min + ", but " + value + " was provided.",
        validation: 'Minimum value'
      });
    } else {
      return value;
    }
  };
};

max = function(max) {
  return function(value) {
    if (value > max) {
      return new TypeValidationError({
        message: "Expected value to be less or equal to " + max + ", but " + value + " was provided.",
        validation: 'Maximum value'
      });
    } else {
      return value;
    }
  };
};

minLength = function(minLength) {
  return function(value) {
    if (value.length < minLength) {
      return new TypeValidationError({
        message: "Expected value length to be of at least " + minLength + ".",
        validation: 'Minimum value length'
      });
    } else {
      return value;
    }
  };
};

builtInTypeCheck = function(BuiltInType) {
  return function(value) {
    var expectedType, providedType;
    expectedType = BuiltInType.name;
    providedType = getValueType(value);
    if (providedType === expectedType) {
      return value;
    } else {
      return new TypeValidationError({
        message: "Expected value to be of `" + expectedType + "` type, but `" + providedType + "` was provided.",
        validation: 'Type validation'
      });
    }
  };
};

stringParser = function(v) {
  switch (getValueType(v)) {
    case 'null':
    case 'undefined':
      return v;
    case 'Array':
      return v.map(function(v) {
        return v.toString();
      }).join(', ');
    case 'Boolean':
      return v.toString();
    case 'Number':
      if (isNaN(v)) {
        return v;
      } else {
        return v.toString();
      }
      break;
    case 'Object':
      try {
        return JSON.stringify(v);
      } catch (_error) {
        return v;
      }
      break;
    default:
      return v;
  }
};

arrayParser = function(v) {
  var parsed;
  try {
    parsed = JSON.parse(v);
    if (getValueType(parsed) === 'Array') {
      return parsed;
    } else {
      return v;
    }
  } catch (_error) {
    return v;
  }
};

booleanParser = function(v) {
  var parsed;
  try {
    parsed = JSON.parse(v);
    if (getValueType(parsed) === 'Boolean') {
      return parsed;
    } else {
      return Boolean(v);
    }
  } catch (_error) {
    return Boolean(v);
  }
};

numberParser = function(v) {
  var parsedNum;
  parsedNum = Number(v);
  if (isNaN(parsedNum)) {
    return v;
  } else {
    return parsedNum;
  }
};

objectParser = function(v) {
  switch (getValueType(v)) {
    case 'Array':
      return v.reduce(function(acc, v, idx) {
        acc[idx] = v;
        return acc;
      }, {});
    case 'String':
      try {
        return JSON.parse(v);
      } catch (_error) {
        return v;
      }
      break;
    default:
      return v;
  }
};

builtInType = function(Type, typeDefault, parser) {
  return new BuiltInType({
    name: Type.name,
    check: builtInTypeCheck(Type),
    parse: function(v) {
      var checkResult;
      checkResult = this.check(v);
      if (!(checkResult instanceof TypeValidationError)) {
        return v;
      }
      return this.check(parser(v));
    },
    typeDefault: typeDefault
  });
};

array = builtInType(Array, [], arrayParser);

boolean = builtInType(Boolean, false, booleanParser);

number = builtInType(Number, 0, numberParser);

object = builtInType(Object, {}, objectParser);

string = builtInType(String, '', stringParser);

module.exports = {
  array: array,
  boolean: boolean,
  number: number,
  object: object,
  string: string,
  extraChecks: {
    number: {
      min: min,
      max: max
    },
    string: {
      minLength: minLength
    }
  },
  typeUtils: {
    checksSum: checksSum,
    checksProduct: checksProduct,
    getValueType: getValueType
  }
};
