{ useI18n } = require 'lib/react_utils'

RankingDescription = ({ type, tags }) ->
  i18n = useI18n('es:recommendations.table')
  translationKey = if type is 'qualityIndicators'
    'quality_indicators_ranking'
  else
    'ranking_judgement'

  <React.Fragment>
    <div>
      {switch type
        when 'tx' then i18n 'ranking_judgement.description_interventions', {count: tags.size}
        when 'dx' then i18n 'ranking_judgement.description_index_tests', {count: tags.size}
        when 'qualityIndicators'then i18n 'ranking_judgement.description_quality_indicators'
        when 'none' then ''
      }
    </div>
    <div  className="mt-10">
      <div>
        <span className="gdt-rating-element gdt-rating-element--active"/>
        <span className="gdt-rating-element gdt-rating-element--active"/>
        <span className="gdt-rating-element gdt-rating-element--active mr-10"/>
        {i18n "#{translationKey}.best_option_description"}
      </div>
      <div>
        <span className="gdt-rating-element gdt-rating-element--active"/>
        <span className="gdt-rating-element gdt-rating-element--active"/>
        <span className="gdt-rating-element mr-10" />
        {i18n "#{translationKey}.intermediate_option_description"}
      </div>
      <div>
        <span className="gdt-rating-element gdt-rating-element--active"/>
        <span className="gdt-rating-element"/>
        <span className="gdt-rating-element mr-10"/>
        {i18n "#{translationKey}.worst_option_description"}
      </div>
    </div>
  </React.Fragment>

RankingDescription.propTypes =
  type: PropTypes.oneOf(['tx', 'dx', 'qualityIndicators', 'none'])

module.exports = RankingDescription
