var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/outcome_label", depth0, "{\"colNo\": 1}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/no_of_studies_patients", depth0, "{\"colNo\": 2, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "design_studies", depth0, "{\"colNo\": 3, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "risk_of_bias", depth0, "{\"colNo\": 4, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "indirectness", depth0, "{\"colNo\": 5, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "inconsistency", depth0, "{\"colNo\": 6, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "imprecision", depth0, "{\"colNo\": 7, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n  "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "other_considerations", depth0, "{\"colNo\": 8, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.comparatorTestPresent : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 9, \"effect-test\": \"index\",\n      \"class\": \"prevalence-1\", \"dataPropertySuffix\": \"-index1\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 10, \"effect-test\": \"comparison\",\n      \"class\": \"prevalence-1\", \"dataPropertySuffix\": \"-comparison1\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 11, \"effect-test\": \"index\",\n      \"class\": \"prevalence-2\", \"dataPropertySuffix\": \"-index2\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 12, \"effect-test\": \"comparison\",\n      \"class\": \"prevalence-2\", \"dataPropertySuffix\": \"-comparison2\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 13, \"effect-test\": \"index\",\n      \"class\": \"prevalence-3\", \"dataPropertySuffix\": \"-index3\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 14, \"effect-test\": \"comparison\",\n      \"class\": \"prevalence-3\", \"dataPropertySuffix\": \"-comparison3\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 15, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "importance", depth0, "{\"colNo\": 16, \"class\": \"wider\"}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 9, \"effect-test\": \"index\",\n      \"class\": \"prevalence-1\", \"dataPropertySuffix\": \"-1\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 10, \"effect-test\": \"index\",\n      \"class\": \"prevalence-2\", \"dataPropertySuffix\": \"-2\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/effect", depth0, "{\"colNo\": 11, \"effect-test\": \"index\",\n      \"class\": \"prevalence-3\", \"dataPropertySuffix\": \"-3\"}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 12, \"rowspan\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "importance", depth0, "{\"colNo\": 13, \"class\": \"wider\"}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, "Inconclusive", (depth0 != null ? depth0.name : depth0), {"name":"is","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, "Complications", (depth0 != null ? depth0.name : depth0), {"name":"is","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/outcome_label", depth0, "{\"colNo\": 1}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/no_of_studies_patients", depth0, "{\"colNo\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, 3, "{\"className\": \"design_studies\", \"dataProperty\": \"designStudies\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, 4, "{\"className\": \"risk_of_bias\", \"dataProperty\": \"riskOfBias\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, 5, "{\"className\": \"indirectness\", \"dataProperty\": \"indirectness\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, 6, "{\"className\": \"inconsistency\", \"dataProperty\": \"inconsistency\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, 7, "{\"className\": \"imprecision\", \"dataProperty\": \"imprecision\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, 8, "{\"className\": \"publication_bias\",\n      \"dataProperty\": \"publicationBias\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/inconclusive_prevalences_desc", depth0, "{\"colNo\": 9}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.notEditableCell || (depth0 && depth0.notEditableCell) || helperMissing).call(depth0, depth0, 10, "{\"className\": \"quality\", \"dataProperty\": \"quality\"}", {"name":"notEditableCell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "importance", depth0, "{\"colNo\": 11, \"class\": \"wider\"}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"9":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/outcome_label", depth0, "{\"colNo\": 1}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/no_of_studies_patients", depth0, "{\"colNo\": 2}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "design_studies", depth0, "{\"colNo\": 3}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "risk_of_bias", depth0, "{\"colNo\": 4}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "indirectness", depth0, "{\"colNo\": 5}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "inconsistency", depth0, "{\"colNo\": 6}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "imprecision", depth0, "{\"colNo\": 7}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/publication_bias", depth0, "{\"colNo\": 8}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "diagnostic/complications_prevalences_desc", depth0, "{\"colNo\": 9}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "quality", depth0, "{\"colNo\": 10}", {"name":"cell","hash":{},"data":data})))
    + "\n    "
    + escapeExpression(((helpers.cell || (depth0 && depth0.cell) || helperMissing).call(depth0, "importance", depth0, "{\"colNo\": 11, \"class\": \"wider\"}", {"name":"cell","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.inStringArray || (depth0 && depth0.inStringArray) || helperMissing).call(depth0, "TP, FN, TN, FP", (depth0 != null ? depth0.name : depth0), {"name":"inStringArray","hash":{},"fn":this.program(1, data),"inverse":this.program(6, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}