utils = require 'base/lib/utils'
Button = require './button'
{ bool, string, oneOf } = PropTypes

IconButton = createReactClass
  displayName: 'IconButton'

  propTypes:
    iconName: string.isRequired
    label: string
    disabled: bool
    title: string
    labelPosition: oneOf(['above', 'below', 'left', 'right']).isRequired
    inlined: bool
    type: string

  getDefaultProps: ->
    displayLabel: utils.getCurrentLanguage() is 'en'
    labelPosition: 'below'
    inlined: true

  render: ->
    className = classNames @props.className, 'btn-icon-container',
      disabled: @props.disabled
      inlined: @props.inlined

    <div
      className={className}
      onClick={@props.onClick unless @props.disabled}
      data-label-position={@props.labelPosition}
    >
      <Button
        type={@props.type || 'button'}
        className={classNames 'btn', 'btn-icon', @props.iconName, disabled: @props.disabled}
        title={@props.title}
      />
      {<div className='label-text'>{@props.label}</div> if @props.label?}
    </div>

module.exports = IconButton
