ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
DecisionAidsActions = require 'actions/decision_aids_actions'
Translation = require 'components/mixins/translation'
CollapsibleWithControls = require 'components/common/collapsible_with_controls'
DecisionAidTitle = require 'components/decision_aid/decision_aid_title'
MultiSelect = require 'components/common/multi_select'
LabeledInput = require 'components/common/labeled_input'
DecisionAidDoc = require 'lib/db_docs/decision_aid_doc'
AppearanceConfigurator = require 'components/decision_aid/appearance_configurator'
AppearanceConfiguratorOption = require 'components/decision_aid/appearance_configurator_option'
QuestionOutcomesSelect = require 'components/decision_aid/question_outcomes_select'
{ mapped } = require 'lib/lens_utils'


QUESTION_FIELDS = ['question', 'healthProblemOrPopulation', 'intervention', 'comparison']

DecisionAid = createReactClass
  displayName: 'DecisionAid'

  propTypes:
    # contains decision aid doc data
    data: PropTypes.instanceOf(Immutable.Map).isRequired
    # editing state flag
    isEditing: PropTypes.bool.isRequired
    # callback for DELETE action
    onDelete: PropTypes.func

  mixins: [Translation('')]

  getInitialState: ->
    decisionAid: DecisionAidDoc.of @props.data.toJS()
    edited: false

  _updateDecisionAid: (newVal) ->
    @setState
      decisionAid: newVal
      edited: true

  getControls: ->
    if @props.isEditing
      [
        { name: 'save', handler: @handleSave }
        { name: 'delete', handler: @handleDelete }
      ]
    else
      [ name: 'edit', handler: @handleEdit ]

  getOutcomesSelectOptions: ->
    @state.decisionAid.getQuestionField('outcomes')
      .reduce (outcomeOptions, o) ->
        outcomeOptions.concat
          value: o['_id']
          label: o['name']
      , []

  getSelectedOutcomes: ->
    @state.decisionAid.getAt 'question', 'outcomes', mapped, '_id'

  handleDecisionAidFieldChange: (evt) ->
    fieldName = evt.target.getAttribute 'name'
    fieldValue = evt.target.value

    @_updateDecisionAid @state.decisionAid.setAt(fieldName)(fieldValue)

  handleQuestionFieldChange: (evt) ->
    fieldName = evt.target.getAttribute 'name'
    fieldValue = evt.target.value

    @_updateDecisionAid @state.decisionAid.setAt('question', fieldName)(fieldValue)

  handleOutcomesSelect: (selectedOutcomes) ->
    @_updateDecisionAid @state.decisionAid.setAt('question', 'outcomes') selectedOutcomes.toJS()

  handleIllustrationChange: (newVal) ->
    @_updateDecisionAid @state.decisionAid.setAt('appearance', 'illustration')(newVal)

  handleThemeChange: (newVal) ->
    @_updateDecisionAid @state.decisionAid.setAt('appearance', 'theme')(newVal)

  handleCancel: ->
    DecisionAidsActions.stopDecisionAidEdit()
    # reset decision aids content and edit state
    @setState
      decisionAid: DecisionAidDoc.of @props.data.toJS()
      edited: false

  handleEdit: ->
    DecisionAidsActions.startDecisionAidEdit @props.data.get '_id'

  handleSave: ->
    DecisionAidsActions.stopDecisionAidEdit()
    if @state.edited
      DecisionAidsActions.saveDecisionAid @state.decisionAid.json()
      # reset edit state
      @setState edited: false

  handleDelete: ->
    DecisionAidsActions.stopDecisionAidEdit()
    DecisionAidsActions
      .deleteDecisionAid @state.decisionAid.getAt '_id'
      .then => @props.onDelete?()

  handleCollapse: ->
    @handleSave() if @props.isEditing

  componentDidUpdate: (prevProps, prevState) ->
    if @props.data isnt prevProps.data
      @setState
        decisionAid: DecisionAidDoc.of @props.data.toJS()

  render: ->
    { isEditing } = @props
    { decisionAid } = @state

    decisionTitle = decisionAid.getAt 'title'
    decisionTheme = decisionAid.getAt 'appearance', 'theme'
    decisionIllustration = decisionAid.getAt 'appearance', 'illustration'

    <div className='decision-aid'>
      <CollapsibleWithControls controls={@getControls()} onCollapse={@handleCollapse}>
        <DecisionAidTitle
          title={decisionTitle}
          isEditing={isEditing}
          onChange={@handleDecisionAidFieldChange}
          decisionAidId={decisionAid.getAt '_id'}
        />
        <div>
          {QUESTION_FIELDS.map (fieldName) =>
            <div className='edit-block' key={fieldName}>
              <LabeledInput
                disabled={not isEditing}
                label={@i18n "da:details-screen.#{_.str.underscored fieldName}"}
                name={fieldName}
                onChange={@handleQuestionFieldChange}
                type='text'
                value={decisionAid.getQuestionField fieldName}
              />
            </div>
          }
          <div className='edit-block'>
            <LabeledInput
              disabled={not isEditing}
              label={@i18n 'da:create-screen.author_organization'}
              name='authorOrOrganization'
              onChange={@handleDecisionAidFieldChange}
              type='text'
              value={decisionAid.getAt 'authorOrOrganization'}
            />
          </div>
          {if isEditing
            <div>
              <h4>{@i18n 'da:details-screen.outcomes'}</h4>
              <QuestionOutcomesSelect
                questionId={decisionAid.getAt 'question', '_id'}
                onChange={@handleOutcomesSelect}
                selected={decisionAid.getAt('question', 'outcomes', mapped, '_id')}
              />
              <h4>{@i18n 'da:details-screen.illustration'}</h4>
              <AppearanceConfigurator
                onChange={@handleIllustrationChange}
                selected={decisionIllustration}
                type='illustrations'
              />
              <h4>{@i18n 'da:details-screen.theme'}</h4>
              <AppearanceConfigurator
                onChange={@handleThemeChange}
                selected={decisionTheme}
                type='themes'
              />
            </div>
          else
            <div>
              <LabeledInput
                type='text'
                disabled
                label={@i18n 'da:details-screen.outcomes'}
                value={decisionAid.getAt('question', 'outcomes', mapped, 'name').join ', '}
              />
              <div className='appearance-details'>
                <h4>{@i18n 'da:details-screen.illustration'}</h4>
                <AppearanceConfiguratorOption type='illustrations' value={decisionIllustration} />
              </div>
              <div className='appearance-details'>
                <h4>{@i18n 'da:details-screen.theme'}</h4>
                <AppearanceConfiguratorOption type='themes' value={decisionTheme} />
              </div>
            </div>
          }
          {isEditing and
            <ApplyCancelButtons
              onApply={@handleSave}
              onCancel={@handleCancel}
              applyClass='btn-success'
              applyLabel={@i18n '/actions.save'}
            />
          }
        </div>
      </CollapsibleWithControls>
    </div>

module.exports = DecisionAid
