var ArchieAPI, Chaplin, Exceptions, GdtDispatcher, W, alt, documentSectionsDocId, mediator, utils,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

ArchieAPI = require('actions/async/archie_api');

Chaplin = require('chaplin');

documentSectionsDocId = require('lib/doc_ids').DOCUMENT_SECTIONS;

Exceptions = require('lib/exceptions');

mediator = require('mediator');

utils = require('base/lib/utils');

W = require('when');

module.exports = GdtDispatcher = (function(_super) {
  __extends(GdtDispatcher, _super);

  function GdtDispatcher() {
    return GdtDispatcher.__super__.constructor.apply(this, arguments);
  }

  GdtDispatcher.prototype.changeProject = function(params) {
    var changingProject, decodedName, doLoadProject, redirectToProjectList, reviewId, _ref, _ref1;
    if ((_ref = mediator.project) != null) {
      _ref.off();
    }
    changingProject = $.Deferred();
    if (!params.projectId) {
      this._removePouchListenersAndCleanStores();
      mediator.project = null;
      changingProject.resolve();
    } else if (params.projectId !== ((_ref1 = mediator.project) != null ? _ref1.id : void 0)) {
      this._removePouchListenersAndCleanStores();
      decodedName = decodeURI(params.projectId);
      this.loadingInfo = mediator.dialogs.loading({
        ms: null
      });
      redirectToProjectList = (function(_this) {
        return function(errorMessage) {
          var activeWorkspace, workspacePart;
          activeWorkspace = mediator.activeWorkspace;
          workspacePart = activeWorkspace === 'personal' ? '' : "/organizations/" + activeWorkspace;
          return mediator.publish('!router:route', "" + workspacePart + "/projects", function() {
            var _ref2;
            if ((_ref2 = _this.loadingInfo) != null) {
              _ref2.destroy();
            }
            _this.loadingInfo = null;
            return changingProject.reject(new Error(errorMessage));
          });
        };
      })(this);
      doLoadProject = (function(_this) {
        return function() {
          return _this._loadProject(decodedName).then(changingProject.resolve, function(err) {
            if (err instanceof Exceptions.project_missing_on_server) {
              return redirectToProjectList($.t('messages.project_not_found'));
            } else {
              return changingProject.reject(err);
            }
          }).ensure(function() {
            var _ref2;
            if ((_ref2 = _this.loadingInfo) != null) {
              _ref2.destroy();
            }
            return _this.loadingInfo = null;
          });
        };
      })(this);
      if (decodedName.indexOf('p_o_cochrane_0_revman_web') === 0) {
        reviewId = _.last(decodedName.split('_'));
        ArchieAPI.checkForPermissions(reviewId).then(function(_arg) {
          var hasPermissions;
          hasPermissions = _arg.hasPermissions;
          if (hasPermissions) {
            return doLoadProject();
          } else {
            return redirectToProjectList($.t('messages.no_permissions_to_project'));
          }
        })["catch"](function() {
          return doLoadProject();
        });
      } else {
        doLoadProject();
      }
    } else {
      changingProject.resolve();
    }
    return changingProject.promise();
  };

  GdtDispatcher.prototype._removePouchListenersAndCleanStores = function() {
    alt.recycle('ReferencesStore');
    return mediator.services.persistenceAdapter.stopListening(mediator.project);
  };

  GdtDispatcher.prototype._loadProject = function(id) {
    return mediator.user.fetch().then(function() {
      return mediator.projects.getOrFetch(id);
    }).then(function(project) {
      var coll, fetchingCollections, _;
      mediator.project = project;
      mediator.colls = mediator.project.getCollections();
      mediator.questions = mediator.colls.questions;
      mediator.prognosticQuestions = mediator.colls.prognosticQuestions;
      mediator.events = mediator.colls.events;
      fetchingCollections = (function() {
        var _ref, _results;
        _ref = mediator.colls;
        _results = [];
        for (_ in _ref) {
          coll = _ref[_];
          _results.push(coll.fetch());
        }
        return _results;
      })();
      return W.all(fetchingCollections);
    }).then(function() {
      return mediator.services.storePersistenceAdapter.fetch(mediator.project.id, documentSectionsDocId).then(function() {
        return mediator.projectDocumentSectionsVersion = 'DraftJS';
      })["catch"](function() {
        return mediator.projectDocumentSectionsVersion = mediator.project.get('documentSections') ? 'legacy' : 'templateBased';
      });
    }).then(this._performMigrations).then(function() {
      return mediator.services.projectTaskRunner.runTasks(id);
    }).then(function() {
      var _ref;
      mediator.activeWorkspace = (_ref = mediator.project.get('organizationId')) != null ? _ref : 'personal';
      mediator.publish('projectChanged', mediator.project);
      return mediator.project;
    });
  };

  GdtDispatcher.prototype._performMigrations = function() {
    if (!mediator.project) {
      return;
    }
    return mediator.services.projectMigration.upgrade(mediator.project, mediator.colls);
  };

  GdtDispatcher.prototype.startupController = function(controllerName, action, params, options) {
    if (action == null) {
      action = 'index';
    }
    if (params == null) {
      params = {};
    }
    if (options == null) {
      options = {};
    }
    return this.changeProject(params).then((function(_this) {
      return function() {
        var error;
        if (options.routeParams) {
          params = _.extend({}, params, options.routeParams);
        }
        if (mediator.debug) {
          return GdtDispatcher.__super__.startupController.call(_this, controllerName, action, params, options);
        } else {
          try {
            return GdtDispatcher.__super__.startupController.call(_this, controllerName, action, params, options);
          } catch (_error) {
            error = _error;
            utils.reportRavenError(error);
            return mediator.dialogs.error($.t('messages.routing_error'));
          }
        }
      };
    })(this), function(err) {
      return mediator.dialogs.fatalError(err);
    });
  };

  return GdtDispatcher;

})(Chaplin.Dispatcher);
