{ bool, string } = PropTypes
ConnectStore = require 'components/enhancers/connect_store'
ImportActions = require 'actions/import_actions'
ImportStore = require 'stores/import_store'
Translation = require 'components/mixins/translation'

storeConnectors =
  ImportStore: (Store) ->
    canChangeName: Store.canChangeProjectName()
    projectName: Store.getProjectName()

ImportProjectName = createReactClass
  displayName: 'ImportProjectName'
  mixins: [Translation('projects:import_dialog')]

  propTypes:
    canChangeName: bool.isRequired
    projectName: string.isRequired

  onChange: (e) ->
    ImportActions.changeProjectName e.target.value

  render: ->
    return null unless @props.canChangeName

    <div className="import-project-name">
      <div>{@i18n 'project_name'}</div>
      <input
        type="text"
        name="project-name"
        onChange={@onChange}
        value={@props.projectName}
      />
    </div>

module.exports = ConnectStore ImportProjectName, [ImportStore], storeConnectors
