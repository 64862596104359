OutcomesTableViewActions = require 'actions/outcomes_table_view_actions'
EditableCell = require 'components/evidence_syntheses/outcomes/cells/editable_cell'
{ CompoundEdit, CompoundEditSection, CompoundEditBlock } =
  require 'components/evidence_syntheses/outcomes/cells/compound_edit'
ValidatedInput = require 'components/common/validated_input'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Select = require 'components/common/select_custom'
{ SelectionList, SelectionListOption } =
  require 'components/evidence_syntheses/outcomes/cells/selection_list'


updateOutcomeAttribute = R.compose(
  OutcomesTableViewActions.updateEditedOutcomeAttributes
  R.objOf
)

FEWER_MORE_OPTIONS = [
  value: 'fewer'
  text: $.t 'es:outcome.fewer'
,
  value: 'more'
  text: $.t 'es:outcome.more'
]

DENOMINATOR_OPTIONS = [100, 1000, 10000, 100000, 1000000].map (denominatorVal) ->
  value: "#{denominatorVal}"
  text: "#{denominatorVal}"

AbsoluteEffectCell = createReactClass
  displayName: 'AbsoluteEffectCell'

  propTypes:
    editMetadata: PropTypes.instanceOf(Immutable.Map)
    allValid: PropTypes.func.isRequired # provided by withValidation enhancer
    validate: PropTypes.func.isRequired # provided by withValidation enhancer

  mixins: [
    Translation('es:outcome')
    CustomRenderMixin
  ]

  _ref: (el) ->
    @el = el

  _getInputValue: (attrName) ->
    @props.editMetadata?.getIn(['editedAttributes', attrName]) ? @props.outcomeAttrs[attrName]

  handleSelect: (selectedAttrName) -> (newVal) ->
    updateOutcomeAttribute selectedAttrName, newVal

  handleInputChange: (evt) ->
    $input = evt.target
    attrName = $input.getAttribute 'name'
    value = if $input.getAttribute('type') is 'checkbox' then $input.checked else $input.value

    updateOutcomeAttribute attrName, value

  handleApply: ->
    OutcomesTableViewActions.saveEditedAttributes()
    @resetEdit()

  resetEdit: ->
    @el.stopEdit()

  render: ->
    isAutoCalculated = @_getInputValue 'absEffectAutoCalc'
    { allValid, validate } = @props

    <EditableCell {...@props} ref={@_ref} title={@i18n 'absolute_effect'}>
      <CompoundEdit applyEnabled={allValid()} onApply={@handleApply} onCancel={@resetEdit}>
        <CompoundEditSection className='auto-calculation'>
          <label>
            <input
              type='checkbox'
              name='absEffectAutoCalc'
              checked={isAutoCalculated}
              onChange={@handleInputChange}
            />
            <span>{@i18n 'absolute_auto_calculate'}</span>
          </label>
        </CompoundEditSection>
        <CompoundEditSection className='effect-data'>
          <CompoundEditBlock label={@i18n 'absolute'}>
            <ValidatedInput
              disabled={isAutoCalculated}
              name='absEffectSize'
              onChange={@handleInputChange}
              type='text'
              validation={validate 'absEffectSize'}
              value={@_getInputValue 'absEffectSize'}
            />
            <Select
              disabled={isAutoCalculated}
              options={FEWER_MORE_OPTIONS}
              selected={"#{@_getInputValue 'fewerMore'}"}
              onChange={@handleSelect 'fewerMore'}
              withClearOption
            />
            <span>{@i18n 'per'}</span>
            <Select
              disabled={isAutoCalculated}
              options={DENOMINATOR_OPTIONS}
              selected={"#{@_getInputValue 'absDenominator'}"}
              onChange={@handleSelect 'absDenominator'}
              withClearOption
            />
          </CompoundEditBlock>
          <CompoundEditBlock label={"95% CI #{@i18n 'from'}"}>
            <ValidatedInput
              disabled={isAutoCalculated}
              name='absEffectFrom'
              onChange={@handleInputChange}
              type='text'
              validation={validate 'absEffectFrom'}
              value={@_getInputValue 'absEffectFrom'}
            />
            <Select
              disabled={isAutoCalculated}
              options={FEWER_MORE_OPTIONS}
              selected={"#{@_getInputValue 'fewerMoreFrom'}"}
              onChange={@handleSelect 'fewerMoreFrom'}
              withClearOption
            />
            <span>{@i18n 'to'}</span>
            <ValidatedInput
              disabled={isAutoCalculated}
              name='absEffectTo'
              onChange={@handleInputChange}
              type='text'
              validation={validate 'absEffectTo'}
              value={@_getInputValue 'absEffectTo'}
            />
            <Select
              disabled={isAutoCalculated}
              options={FEWER_MORE_OPTIONS}
              selected={"#{@_getInputValue 'fewerMoreTo'}"}
              onChange={@handleSelect 'fewerMoreTo'}
              withClearOption
            />
          </CompoundEditBlock>
        </CompoundEditSection>
      </CompoundEdit>
    </EditableCell>

module.exports = AbsoluteEffectCell
