DecisionAidsActions = require 'actions/decision_aids_actions'
CollapsibleChapter = require 'components/common/collapsible_chapter'
DecisionAidChapterTitle = require 'components/decision_aid/decision_aid_chapter_title'
DecisionAidSubchapter = require 'components/decision_aid/decision_aid_subchapter'
AnimatedCollectionItem = require 'components/enhancers/animated_collection_item'
{ TransitionGroup } = ReactTransitionGroup

AnimatedSubchapter = AnimatedCollectionItem DecisionAidSubchapter,
  animationsDuration: 300
  skipAppearAnimation: true
  scrollEnteredIntoView: true
  enterAnimation:
    name: 'splash'
    duration: 700

DecisionAidChapter = createReactClass
  displayName: 'DecisionAidChapter'

  propTypes:
    chapterId: PropTypes.string.isRequired
    comparison: PropTypes.string.isRequired
    daId: PropTypes.string.isRequired
    data: PropTypes.instanceOf(Immutable.Map).isRequired
    intervention: PropTypes.string.isRequired
    isExpanded: PropTypes.bool.isRequired
    onCollapse: PropTypes.func.isRequired
    onExpand: PropTypes.func.isRequired
    outcomes: PropTypes.instanceOf(Immutable.List).isRequired
    questionId: PropTypes.string.isRequired

  handleCollapse: ->
    @props.onCollapse @props.chapterId

  handleExpand: ->
    @props.onExpand @props.chapterId

  handleChapterDelete: ->
    DecisionAidsActions.deleteDecisionAidChapter
      daId: @props.daId
      chapterId: @props.chapterId

  handleChapterUpdate: (newData) ->
    DecisionAidsActions.updateDecisionAidChapter
      daId: @props.daId
      chapterId: @props.chapterId
      chapterData: newData

  render: ->
    { chapterId, comparison, daId, intervention, outcomes, questionId } = @props
    subchapters = @props.data.get 'subchapters'

    <CollapsibleChapter
      title={
        <DecisionAidChapterTitle
          daId={@props.daId}
          deletable={@props.data.get 'deletable'}
          title={@props.data.get 'title'}
          illustration={@props.data.get 'illustration'}
          onDelete={@handleChapterDelete}
          onUpdate={@handleChapterUpdate}
        />
      }
      isExpanded={@props.isExpanded}
      onCollapse={@handleCollapse}
      onExpand={@handleExpand}
    >
      <TransitionGroup  component='div'>
        {subchapters.map (subchapter, idx) ->
          <AnimatedSubchapter
            chapterId={chapterId}
            comparison={comparison}
            data={subchapter}
            daId={daId}
            deletable={subchapters.size > 1 and subchapter.get('deletable')}
            key={subchapter.get '_id'}
            intervention={intervention}
            outcomes={outcomes}
            questionId={questionId}
            subchapterIdx={idx}
          />
        }
      </TransitionGroup>
    </CollapsibleChapter>

module.exports = DecisionAidChapter
