ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
ConfirmationModal = require 'components/common/confirmation_modal'
Input = require 'components/common/input'
Modal = require 'components/common/modal'
Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'

OutcomeEditModal = createReactClass

  displayName: "OutcomeEditModal"

  mixins: [Translation('es:outcome')]

  propTypes:
    initialOutcomeName: PropTypes.string.isRequired
    initialAbsDenominator: PropTypes.number.isRequired
    onClose: PropTypes.func.isRequired
    onSave: PropTypes.func.isRequired

  getInitialState: ->
    confirmDenominatorChangeModal: false
    name: @props.initialOutcomeName
    absDenominator: @props.initialAbsDenominator.toString()

  updateOutcomeName: (e) ->
    @setState name: e.target.value

  updateAbsDenominator: (val) ->
    @setState absDenominator: val

  getDenominatorOptions: ->
    [100, 1000, 10000, 100000, 1000000].map (val) ->
      value: val.toString()
      text: val.toLocaleString()

  handleApply: ->
    if @props.initialAbsDenominator isnt parseInt(@state.absDenominator)
      @setState confirmDenominatorChangeModal: true
    else
      @saveOutcome(false)()

  saveOutcome: (withPropagation) -> =>
    @setState confirmDenominatorChangeModal: false
    outcomeData =
      name: @state.name
      absDenominator: parseInt(@state.absDenominator)
    @props.onSave outcomeData, withPropagation

  render: ->
    <Modal
      className="outcome-name__edit-modal"
      isOpen={true}
      modalSize="standard"
      onClose={@props.onClose}
      onRequestClose={@props.onClose}
    >
      <div className="outcome-name-row">
        <label>{@i18n 'name'}</label>
        <input
          type="text"
          onChange={@updateOutcomeName}
          value={@state.name}
        />
      </div>
      <div className="denominator-row">
        <label>{@i18n 'denominator'}</label>
        <span>
          <span>{@i18n 'per'}</span>
          <Select
            options={@getDenominatorOptions()}
            selected={@state.absDenominator}
            onChange={@updateAbsDenominator}
          />
        </span>
      </div>
      <ApplyCancelButtons
        onCancel={@props.onClose}
        onApply={@handleApply}
      />
      {@state.confirmDenominatorChangeModal &&
        <ConfirmationModal
          cancelLabel={@i18n '/actions.no'}
          isOpen={true}
          message={@i18n 'messages.abs_denominator_propagate_change',
            from: @props.initialAbsDenominator.toLocaleString(),
            to: @state.absDenominator.toLocaleString()
          }
          modalSize={'standard'}
          onCancel={@saveOutcome(false)}
          onConfirm={@saveOutcome(true)}
          question={null}
        />
      }
    </Modal>


module.exports = OutcomeEditModal
