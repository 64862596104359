var OverReview, OverReviewActions, OverReviewView, ReactComponent, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

mediator = require('mediator');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

OverReview = require('components/over_review/over_review');

OverReviewActions = require('actions/over_review_actions');

module.exports = OverReviewView = (function(_super) {
  __extends(OverReviewView, _super);

  function OverReviewView() {
    return OverReviewView.__super__.constructor.apply(this, arguments);
  }

  OverReviewView.prototype.container = '#page-container';

  OverReviewView.prototype.className = 'over-review-view';

  OverReviewView.prototype.autoRender = true;

  OverReviewView.prototype.initialize = function() {
    OverReviewView.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  OverReviewView.prototype.afterRender = function() {
    var comparison, intervention, population, questionId, questionText;
    OverReviewView.__super__.afterRender.apply(this, arguments);
    this._stopInputsPropagation = false;
    questionId = this.options.model.get('_id');
    questionText = this.options.model.get('question');
    comparison = this.options.model.get('comparison');
    intervention = this.options.model.get('intervention');
    population = this.options.model.get('healthProblemOrPopulation');
    return this.renderComponent(OverReview, OverReviewActions, questionId, {
      props: {
        questionId: questionId,
        questionText: questionText,
        questionPico: {
          intervention: intervention,
          comparison: comparison,
          population: population
        }
      },
      fetchAndListen: {
        dbId: mediator.project.id
      }
    });
  };

  OverReviewView.prototype.dispose = function() {
    this.unmountComponent();
    return OverReviewView.__super__.dispose.apply(this, arguments);
  };

  return OverReviewView;

})(View);
