Modal = require 'components/common/modal'
DuplicatesComparison = require 'components/references/duplicates_comparison'
ReferenceRow = require 'components/references/reference_row'
ButtonsRow = require 'components/references/duplicates_buttons_row'
{ instanceOf, string, arrayOf, func } = PropTypes

DuplicatesGroup = createReactClass
  displayName: 'DuplicatesGroup'

  propTypes:
    duplicates: instanceOf(Immutable.List).isRequired
    columns: arrayOf(string).isRequired
    duplicatesHash: string.isRequired
    keepDuplicates: func.isRequired
    mergeDuplicates: func.isRequired

  getInitialState: ->
    showComparison: false

  showComparison: ->
    @setState showComparison: true

  hideComparison: ->
    @setState showComparison: false

  render: ->
    <div>
      <table className='duplicates-duplicates gdt-table'>
        <tbody>
          {@props.duplicates.map (reference, idx) =>
            <ReferenceRow
              key={idx}
              columns={@props.columns}
              referenceData={reference}
            />
          }
          <ButtonsRow
            columns={@props.columns}
            duplicatesHash={@props.duplicatesHash}
            keepDuplicates={@props.keepDuplicates}
            mergeDuplicates={@props.mergeDuplicates}
            showDuplicationDetails={@showComparison}
          />
        </tbody>
      </table>
      {if @state.showComparison
        <Modal isOpen closeButton
          className="compare-duplicates-modal"
          onClose={@hideComparison}
        >
          <DuplicatesComparison
            duplicates={@props.duplicates}
            mergeDuplicates={@props.mergeDuplicates}
            keepDuplicates={@props.keepDuplicates}
          />
        </Modal>
      }
    </div>

module.exports = DuplicatesGroup
