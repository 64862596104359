Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
QualityIndicatorsActions = require 'actions/quality_indicators_actions'
QualityIndicatorsStore = require 'stores/quality_indicators_store'
QuestionsStore = require 'stores/questions_store'
Spinner = require 'components/common/spinner'
{
  QualityIndicatorRow
  QualityIndicatorEditRow
} = require 'components/quality_indicators/quality_indicator_row'
QualityIndicatorsEtd= require 'components/quality_indicators/quality_indicators_etd'
QualityIndicatorsTable = require 'components/quality_indicators/quality_indicators_table'
{ useI18n } = require 'lib/react_utils'

storeConnector =
  QualityIndicatorsStore: (Store) ->
    currentView: Store.getCurrentView()
    editingRows: Store.getEditingRows()
    isFetching: Store.isFetching()
    qualityIndicatorsDocId: Store.getCurrentDocId()
    qualityIndicators: Store.getQualityIndicators()
  QuestionsStore: (Store) ->
    currentQuestion: Store.getQuestion()

QualityIndicators = ({
  currentView,
  qualityIndicatorsDocId,
  isFetching,
  qualityIndicators,
  editingRows,
  currentQuestion
}) ->
  i18n = useI18n('quality_indicators:table')
  if isFetching
    <Spinner />
  else
    <div className="quality-indicators">
      {if currentView is 'candidates'
        <div>
          <div className="quality-indicators__table">
            <QualityIndicatorsTable
              currentQuestion={currentQuestion}
              editingRows={editingRows}
              qualityIndicators={qualityIndicators}
            />
          </div>
          <Button
            className="btn w-full mt-10"
            label={i18n '/actions.add_quality_indicator'}
            onClick={QualityIndicatorsActions.addRow}
          />
        </div>
      else
        <QualityIndicatorsEtd docId={currentQuestion.get('_id')} />
      }
    </div>

QualityIndicators.propTypes =
  currentView: PropTypes.oneOf(['etd', 'candidates']).isRequired
  currentQuestion: PropTypes.instanceOf(Immutable.Map).isRequired
  isFetching: PropTypes.bool.isRequired
  qualityIndicators: PropTypes.instanceOf(Immutable.List).isRequired
  qualityIndicatorsDocId: PropTypes.string.isRequired

module.exports = ConnectStore QualityIndicators,
  [ QualityIndicatorsStore, QuestionsStore ], storeConnector
