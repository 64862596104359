Translation = require 'components/mixins/translation'
Popover = require 'components/common/popover'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'


SourceComment = createReactClass
  displayName: 'SourceComment'

  propTypes:
    onApply: PropTypes.func.isRequired
    comment: PropTypes.string

  mixins: [
    Translation('over_review:module')
  ]

  getInitialState: ->
    showTextArea: false
    commentText: @props.comment ? ''

  toggleTextArea: ->
    @setState
      showTextArea: not @state.showTextArea
      commentText: if @state.showTextArea then '' else @props.comment

  handleApply: ->
    @props.onApply @state.commentText
    @toggleTextArea()

  handleCommentChange: (evt) ->
    @setState commentText: evt.target.value

  render: ->
    { comment, onApply } = @props

    <Popover
      onRequestClose={@toggleTextArea}
      positionParams={position: 'bottom', alignment: 'center'}
      visible={@state.showTextArea}
    >
      <div className='comment-text' onClick={@toggleTextArea}>
        {comment or @i18n '../actions.add_comment'}
      </div>
      <div className='comment-edit'>
        <textarea onChange={@handleCommentChange} value={@state.commentText} />
        <ApplyCancelButtons
          onApply={@handleApply}
          onCancel={@toggleTextArea}
        />
      </div>
    </Popover>

module.exports = SourceComment
