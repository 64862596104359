var CADTH_EMPTY_GUIDELINE, CadthGuidelinesActions, INITIAL_STATE, generateStore;

CADTH_EMPTY_GUIDELINE = require('lib/cadth_guidelines_helper').CADTH_EMPTY_GUIDELINE;

CadthGuidelinesActions = require('actions/cadth_guidelines_actions');

generateStore = require('stores/utils/generate_store');

INITIAL_STATE = Immutable.fromJS({
  meta: {
    isFetching: false,
    fetchingError: null,
    dialogOpen: false,
    dialogSaving: false
  },
  sortBy: {
    field: 'title',
    type: 'asc'
  },
  guidelines: Immutable.OrderedMap(),
  dialogGuideline: Immutable.Map()
});

module.exports = generateStore({
  name: 'CadthGuidelinesStore',
  initialState: INITIAL_STATE,
  boundActions: [CadthGuidelinesActions],
  methods: {
    onDbChange: function(_arg) {
      var deleted, doc, id;
      deleted = _arg.deleted, doc = _arg.doc, id = _arg.id;
      if (deleted) {
        return this.setState(this.state.deleteIn(['guidelines', id]));
      } else {
        return this.setState(this.state.setIn(['guidelines', id], Immutable.fromJS(doc)));
      }
    },
    onFetch: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.set('guidelines', Immutable.OrderedMap());
        state.setIn(['meta', 'isFetching'], true);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchSuccess: function(_arg) {
      var guidelines, rows;
      rows = _arg.rows;
      guidelines = rows.reduce(function(acc, _arg1) {
        var doc;
        doc = _arg1.doc;
        return acc.set(doc['_id'], Immutable.fromJS(doc));
      }, Immutable.OrderedMap());
      return this.setState(this.state.withMutations(function(state) {
        state.set('guidelines', guidelines);
        state.setIn(['meta', 'isFetching'], false);
        return state.setIn(['meta', 'fetchingError'], null);
      }));
    },
    onFetchError: function(err) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('guidelines', Immutable.OrderedMap());
        state.setIn(['meta', 'isFetching'], false);
        return state.setIn(['meta', 'fetchingError'], err);
      }));
    },
    onOpenDialog: function(maybeGuideline) {
      var guideline;
      guideline = maybeGuideline ? maybeGuideline : CADTH_EMPTY_GUIDELINE;
      return this.setState(this.state.withMutations(function(state) {
        state.set('dialogGuideline', Immutable.fromJS(guideline));
        state.setIn(['meta', 'dialogOpen'], true);
        return state.setIn(['meta', 'dialogSaving'], false);
      }));
    },
    onCloseDialog: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.set('dialogGuideline', Immutable.Map());
        state.setIn(['meta', 'dialogOpen'], false);
        return state.setIn(['meta', 'dialogSaving'], false);
      }));
    },
    onSetDialogField: function(_arg) {
      var field, value;
      field = _arg.field, value = _arg.value;
      return this.setState(this.state.setIn(['dialogGuideline', field], value));
    },
    onCreateOrUpdate: function() {
      return this.setState(this.state.setIn(['meta', 'dialogSaving'], true));
    },
    onCreateOrUpdateSuccess: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.set('dialogGuideline', Immutable.Map());
        state.setIn(['meta', 'dialogOpen'], false);
        return state.setIn(['meta', 'dialogSaving'], false);
      }));
    },
    onCreateOrUpdateError: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.set('dialogGuideline', Immutable.Map());
        state.setIn(['meta', 'dialogOpen'], false);
        return state.setIn(['meta', 'dialogSaving'], false);
      }));
    },
    onSetSortBy: function(field) {
      var oldField;
      oldField = this.state.getIn(['sortBy', 'field']);
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['sortBy', 'field'], field);
        return state.updateIn(['sortBy', 'type'], function(oldType) {
          if (field !== oldField) {
            return 'asc';
          } else if (oldType === 'asc') {
            return 'desc';
          } else {
            return 'asc';
          }
        });
      }));
    },
    isFetching: function() {
      return this.state.getIn(['meta', 'isFetching']);
    },
    getFetchingError: function() {
      return this.state.getIn(['meta', 'fetchingError']);
    },
    getGuidelines: function() {
      var guidelines, sortField, sortType, sortedGuidelines;
      guidelines = this.state.get('guidelines');
      sortField = this.state.getIn(['sortBy', 'field']);
      sortType = this.state.getIn(['sortBy', 'type']);
      sortedGuidelines = guidelines.sortBy(function(guideline) {
        return guideline.get(sortField);
      });
      if (sortType === 'desc') {
        return sortedGuidelines.reverse();
      } else {
        return sortedGuidelines;
      }
    },
    getSortBy: function() {
      return this.state.get('sortBy');
    },
    isDialogOpen: function() {
      return this.state.getIn(['meta', 'dialogOpen']);
    },
    isDialogSaving: function() {
      return this.state.getIn(['meta', 'dialogSaving']);
    },
    getDialogGuideline: function() {
      return this.state.get('dialogGuideline');
    }
  }
});
