DecisionAidChapter = require 'components/decision_aid/decision_aid_chapter'


ChaptersList = createReactClass
  displayName: 'ChaptersList'

  propTypes:
    chaptersOrder: PropTypes.instanceOf(Immutable.List).isRequired
    chapters: PropTypes.instanceOf(Immutable.Map).isRequired
    comparison: PropTypes.string.isRequired
    decisionAidId: PropTypes.string.isRequired
    expandedChapters: PropTypes.instanceOf(Immutable.Set).isRequired
    intervention: PropTypes.string.isRequired
    onChapterCollapse: PropTypes.func
    onChapterExpand: PropTypes.func
    outcomes: PropTypes.instanceOf(Immutable.List).isRequired
    questionId: PropTypes.string.isRequired

  render: ->
    {
      chapters
      chaptersOrder
      comparison
      decisionAidId
      expandedChapters
      intervention
      onChapterCollapse
      onChapterExpand
      outcomes
      questionId
    } = @props

    <div className='gdt-collapsible-chapters chapters-list'>
      {chaptersOrder.map (chapterId) ->
        <DecisionAidChapter
          comparison={comparison}
          chapterId={chapterId}
          daId={decisionAidId}
          data={chapters.get chapterId}
          isExpanded={expandedChapters.has chapterId}
          key={chapterId}
          intervention={intervention}
          onCollapse={onChapterCollapse}
          onExpand={onChapterExpand}
          outcomes={outcomes}
          questionId={questionId}
        />
      }
    </div>

module.exports = ChaptersList
