var Exceptions, ModalView, Reference, ReferencesImportView, Shortcuts, W, endnoteImporter, mediator, risImporter, sequence, template,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

ModalView = require('base/views/modal_view');

template = require('views/templates/import_references');

mediator = require('mediator');

Shortcuts = require('base/lib/traits/shortcuts');

W = require('when');

sequence = require('when/sequence');

Exceptions = require('lib/exceptions');

endnoteImporter = require('lib/importers/endnote');

risImporter = require('lib/importers/ris');

Reference = require('models/references/reference');

module.exports = ReferencesImportView = (function(_super) {
  __extends(ReferencesImportView, _super);

  function ReferencesImportView() {
    this._handleImportError = __bind(this._handleImportError, this);
    this._addReferences = __bind(this._addReferences, this);
    return ReferencesImportView.__super__.constructor.apply(this, arguments);
  }

  ReferencesImportView.prototype.template = template;

  ReferencesImportView.prototype.id = 'import-references-dialog';

  ReferencesImportView.prototype.title = $.t('references:import_title');

  ReferencesImportView.prototype.shortcuts = {
    'enter': 'importFile',
    'esc': 'dispose'
  };

  ReferencesImportView.prototype.initialize = function() {
    ReferencesImportView.__super__.initialize.apply(this, arguments);
    this.delegate('change', '#file', this.fileChanged);
    this.delegate('click', '.import', this.importFile);
    this.delegate('click', 'button.cancel', this.dispose);
    this.delegate('click', 'button.file', function(e) {
      e.stopPropagation();
      return this.$('#file').click();
    });
    this.delegate('mouseenter', 'button.file', function(e) {
      var button, height, width;
      if (!this.currentFile) {
        return;
      }
      button = this.$(e.target);
      width = button.outerWidth();
      height = button.outerHeight();
      return button.text($.t('projects:import_dialog.change_file')).width(width).height(height);
    });
    return this.delegate('mouseleave', 'button.file', this.setFileButtonCaption);
  };

  ReferencesImportView.prototype.fileChanged = function(e) {
    var file, valid;
    file = e.target.files[0];
    valid = this.isValidFile(file);
    this.render();
    if (valid) {
      this.currentFile = file;
      this.setFileButtonCaption();
      return this.$('button.import').prop('disabled', false);
    }
  };

  ReferencesImportView.prototype.setFileButtonCaption = function() {
    if (!this.currentFile) {
      return;
    }
    return this.$('button.file').text(this.currentFile.name).width('auto');
  };

  ReferencesImportView.prototype.getTemplateData = function() {
    return {
      error: this.validationError,
      importError: this.importError
    };
  };

  ReferencesImportView.prototype.importFile = function(e) {
    var error;
    e.stopPropagation();
    try {
      return this._parseFile();
    } catch (_error) {
      error = _error;
      return this._handleImportError(error);
    }
  };

  ReferencesImportView.prototype._parseFile = function() {
    var fileExt, importingFile, parser, parsingFile, reader;
    if (!this.currentFile) {
      return;
    }
    fileExt = this.currentFile.name.split('.').pop();
    this.$('button.import').addClass('loading').forceRedraw();
    parser = (function() {
      switch (fileExt) {
        case 'xml':
          return endnoteImporter;
        case 'ris':
          return risImporter({
            format: 'default'
          });
        case 'nbib':
          return risImporter({
            format: 'medline'
          });
      }
    })();
    parsingFile = W.defer();
    reader = new FileReader();
    reader.onload = function(loadEvent) {
      var error;
      try {
        return parsingFile.resolve(parser(loadEvent.target.result));
      } catch (_error) {
        error = _error;
        console.log(error.stack);
        return parsingFile.reject(error);
      }
    };
    reader.readAsText(this.currentFile, 'UTF-8');
    importingFile = parsingFile.promise.then(this._addReferences);
    return W(importingFile).ensure((function(_this) {
      return function() {
        return _this.$('button.import').removeClass('loading');
      };
    })(this)).otherwise(this._handleImportError);
  };

  ReferencesImportView.prototype._addReferences = function(references) {
    this.options.importReferences(references);
    return this.dispose();
  };

  ReferencesImportView.prototype._handleImportError = function(error) {
    var _ref;
    if (typeof Raven !== "undefined" && Raven !== null) {
      Raven.captureException(error);
    }
    this.importError = (_ref = error.message) != null ? _ref : error;
    return this.render();
  };

  ReferencesImportView.prototype.isValidFile = function(f) {
    var allowedExtensions, errorMessage, fileExt, _ref;
    this.validationError = null;
    fileExt = f.name.split('.').pop();
    _ref = [['nbib', 'ris', 'xml'], 'references:invalid_format'], allowedExtensions = _ref[0], errorMessage = _ref[1];
    if (__indexOf.call(allowedExtensions, fileExt) < 0) {
      this.validationError = $.t(errorMessage);
      return false;
    }
    return true;
  };

  ReferencesImportView.prototype.clearFileInput = function() {
    var fieldSpan;
    fieldSpan = $('#file-input');
    return fieldSpan.html(fieldSpan.html());
  };

  return ReferencesImportView;

})(ModalView);
