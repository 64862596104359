{ Button } = ReactComponents
ConnectStore = require 'components/enhancers/connect_store'
{ MASTER_PROJECT_ID } = require 'lib/mda_helper'
{ MDA_TOPIC } = require 'lib/db_docs/doc_types'
MdaWorkflowPublicationActions = require 'actions/mda_workflow_publication_actions'
MdaWorkflowPublicationStore = require 'stores/mda_workflow_publication_store'
mediator = require 'mediator'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

storeConnector =
  MdaWorkflowPublicationStore: (Store) ->
    selectedTopics: Store.getSelectedTopics()
    topicPublicationDates: Store.getTopicPublicationDates()

MdaTopicsWorkflowToolbar = ({ selectedTopics, topicPublicationDates }) ->
  i18n = useI18n()
  inMaster = mediator.project?.id is MASTER_PROJECT_ID
  titleKey = if inMaster then 'dissemination' else 'send_to_main_project'
  buttonLabel = if inMaster
    i18n '/actions.publish_selected_topics', count: selectedTopics.size
  else
    i18n '/actions.publish_to_main_project'

  openConfirmationDialog = useCoffeeCallback [], ->
    if mediator.services.replication.isConnected()
      MdaWorkflowPublicationActions.toggleConfirmationDialogOpen()
    else
      mediator.dialogs.error $.t 'dbep:mda_publication_screen.error_offline'

  publishToAPI = useCoffeeCallback [selectedTopics, topicPublicationDates], ->
    republish = selectedTopics.some (topicId) -> topicPublicationDates.has topicId
    actionI18nKey = if republish then 'republish' else 'publish'
    message = i18n "/dbep:mda_publication_screen.publish_confirmation.#{actionI18nKey}"
    title = i18n '/dbep:mda_publication_screen.publish_confirmation.title'
    mediator.dialogs.confirm { message, title }, (confirmed) ->
      return unless confirmed
      MdaWorkflowPublicationActions.publish selectedTopics.toJS()

  <div className="toolbar-publication-platform">
    <h2 className="toolbar-publication-platform__title">
      {i18n "dissemination:titles.#{titleKey}"}
    </h2>
    <div>
      <Button
        className="btn btn-success"
        disabled={inMaster and selectedTopics.isEmpty()}
        label={buttonLabel}
        onClick={if inMaster then publishToAPI else openConfirmationDialog}
      />
    </div>
  </div>

MdaTopicsWorkflowToolbar.propTypes =
  selectedTopics: PropTypes.instanceOf(Immutable.List).isRequired
  topicPublicationDates: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore MdaTopicsWorkflowToolbar, MdaWorkflowPublicationStore, storeConnector
