RadioInput = require 'components/common/radio_input'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
MedianRatingValue = require 'components/scope/common/median_rating_value'

APPROVAL_VALUES = ['accepted', 'mentioned', 'rejected']

QuestionRatingRow = createReactClass
  displayName: 'QuestionRatingRow'

  propTypes:
    id: PropTypes.string.isRequired
    question: PropTypes.string.isRequired
    medianRating: PropTypes.number.isRequired
    approvalStatus: PropTypes.string.isRequired
    onChangeApprovalStatus: PropTypes.func

  mixins: [
    CustomRenderMixin
    Translation('scope:questions')
  ]

  changeApprovalStatus: (id, approvalVal) -> =>
    @props.onChangeApprovalStatus { id, approvalVal }

  render: ->
    { id, question, approvalStatus, medianRating, commentToggle, readOnly } = @props
    <tr>
      <td>{question}</td>
      {APPROVAL_VALUES.map (approvalVal, idx) =>
        <td key={idx}>
          <label>
            <RadioInput
              name="approvalStatus-#{id}"
              value={approvalVal}
              checked={approvalStatus is approvalVal}
              disabled={readOnly}
              onChange={@changeApprovalStatus id, approvalVal}
            />
          </label>
        </td>
      }
      <td><MedianRatingValue medianRating={medianRating} /></td>
      <td className="comments-toggle">{commentToggle}</td>
    </tr>

module.exports = QuestionRatingRow
