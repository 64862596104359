var OriginalEvidenceTableView, QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE, View, template,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

template = require('views/templates/evidence_syntheses/original_evidence_table');

View = require('base/views/view');

QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE = require('lib/questions_helper').QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE;

module.exports = OriginalEvidenceTableView = (function(_super) {
  __extends(OriginalEvidenceTableView, _super);

  function OriginalEvidenceTableView() {
    return OriginalEvidenceTableView.__super__.constructor.apply(this, arguments);
  }

  OriginalEvidenceTableView.prototype.container = '.show-original-version';

  OriginalEvidenceTableView.prototype.template = template;

  OriginalEvidenceTableView.prototype.autoRender = true;

  OriginalEvidenceTableView.prototype.initialize = function(options) {
    OriginalEvidenceTableView.__super__.initialize.apply(this, arguments);
    return this.delegate('click', 'button.show-original-version', this.showOriginalTable);
  };

  OriginalEvidenceTableView.prototype.showOriginalTable = function() {
    var HtmlExport, adolopedModel, parentView, _ref;
    _ref = this.options, parentView = _ref.parentView, adolopedModel = _ref.adolopedModel;
    HtmlExport = require('lib/html_export');
    return new HtmlExport(adolopedModel, QUESTION_VIEW_NAME_TO_PRINTOUT_TYPE[parentView.viewType.name], {}).getSource().then(function(exportContent) {
      var el, tableContainer, tableWindowRef, title;
      tableWindowRef = null;
      tableWindowRef = window.open('about:blank', '_blank', 'resizable=yes, scrollable=yes, status=yes, menubar=no');
      if (tableWindowRef) {
        tableWindowRef.opener = null;
        el = document.createElement('div');
        title = document.createElement('h3');
        title.innerHTML = adolopedModel.get('question');
        tableContainer = document.createElement('div');
        tableContainer.innerHTML = exportContent;
        el.appendChild(title);
        el.appendChild(tableContainer);
        return tableWindowRef.document.write(el.innerHTML);
      }
    });
  };

  return OriginalEvidenceTableView;

})(View);
