var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "<div>\n  <label for=\"lower-limit\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "conventional_lower_upper_limits", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n  <input id=\"lower-limit\" data-property=\"conventionalScaleLowerLimit\"\n    name=\"conventionalScaleLowerLimit\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.conventionalScaleLowerLimit || (depth0 != null ? depth0.conventionalScaleLowerLimit : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"conventionalScaleLowerLimit","hash":{},"data":data}) : helper)))
    + "\"\n    placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "lower_limit", {"name":"i18n","hash":{},"data":data})))
    + "\">\n  <input id=\"upper-limit\" data-property=\"conventionalScaleUpperLimit\"\n    name=\"conventionalScaleUpperLimit\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.conventionalScaleUpperLimit || (depth0 != null ? depth0.conventionalScaleUpperLimit : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"conventionalScaleUpperLimit","hash":{},"data":data}) : helper)))
    + "\"\n    placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "upper_limit", {"name":"i18n","hash":{},"data":data})))
    + "\">\n  <div class=\"sup\"></div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}