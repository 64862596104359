{ QUESTION_OVER_REVIEW_STUDIES_STATUSES: STD_STATUSES } = require 'lib/questions_helper'
Translation = require 'components/mixins/translation'

STATUSES = [
  value: STD_STATUSES.INCLUDED
  titleKey: 'statuses.included_title'
,
  value: STD_STATUSES.EXCLUDED
  titleKey: 'statuses.excluded_title'
  descriptionKey: 'statuses.excluded_description'
,
  value: STD_STATUSES.POSSIBLE
  descriptionKey: 'statuses.possible_description'
,
  value: STD_STATUSES.NONE
  titleKey: 'statuses.none_title'
]

StatusMatrixLegend = createReactClass
  displayName: 'StatusMatrixLegend'

  mixins: [
    Translation('over_review:studiesTab')
  ]

  renderStatusLegend: ({ value, titleKey, descriptionKey }) ->
    <div className="legend-item #{value}" key={value}>
      <div className='status-value' />
      <div className='status-value-legend'>
        {if titleKey
          <div className='status-value-title'>
            {@i18n titleKey}
          </div>
        }
        {if descriptionKey
          <div className='status-value-description'>
            {@i18n descriptionKey}
          </div>
        }
      </div>
    </div>

  render: ->
    <div className='statux-matrix-legend'>
      <h4>{@i18n 'legend'}</h4>
      {STATUSES.map @renderStatusLegend}
    </div>

module.exports = StatusMatrixLegend
