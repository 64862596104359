var OutcomeViewType, Set, WalkthroughService, hopscotchTemplate, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

mediator = require('mediator');

Set = require('base/lib/set');

OutcomeViewType = require('models/outcome/view_types');

hopscotchTemplate = require('views/templates/hopscotch');

module.exports = WalkthroughService = (function() {
  WalkthroughService.prototype.$elForInterruption = ['button', 'a', 'td', 'li.edit-option', 'div.ui-droppable', 'ol.ui-sortable', 'div.tab-elem'];

  WalkthroughService.prototype._handledEvents = new Set();

  function WalkthroughService() {
    this._attachInterruptionHandlers = __bind(this._attachInterruptionHandlers, this);
    this._tourInterruption = __bind(this._tourInterruption, this);
    this.startTour = __bind(this.startTour, this);
    window.hopscotch.templates.gdt = hopscotchTemplate;
    window.hopscotch.setRenderer('gdt');
  }

  WalkthroughService.prototype.askToStart = function() {
    var publishWalkthroughEnded, startTour, unsetFirstTime;
    if (mediator.user.get('firstTime') === false) {
      return;
    }
    mediator.dialogs.notify({
      message: $.t('walkthrough.start_message'),
      closeBtn: false,
      hideOnClick: false,
      modal: true,
      position: 'center',
      ms: null,
      buttons: [
        {
          'data-role': 'no',
          text: $.t('actions.no')
        }, {
          'data-role': 'not_now',
          text: $.t('walkthrough.not_now')
        }, {
          'data-role': 'yes',
          text: $.t('actions.yes'),
          'class': 'finish'
        }
      ]
    }).on('click:no', function() {
      this.destroy();
      unsetFirstTime();
      mediator.dialogs.modalSuccess($.t('walkthrough.launch_from_menu'));
      return publishWalkthroughEnded();
    }).on('click:not_now', function() {
      this.destroy();
      mediator.dialogs.modalSuccess($.t('walkthrough.will_show_next_time'));
      return publishWalkthroughEnded();
    }).on('click:yes', function() {
      this.destroy();
      unsetFirstTime();
      startTour();
      return window.hopscotch.listen('end', publishWalkthroughEnded);
    });
    startTour = this.startTour;
    unsetFirstTime = function() {
      return mediator.user.save('firstTime', false);
    };
    return publishWalkthroughEnded = function() {
      return mediator.publish('walkthroughEnded');
    };
  };

  WalkthroughService.prototype.startTour = function() {
    return mediator.publish('!router:route', '/', (function(_this) {
      return function(routed) {
        $('.tab-elems .tab-elem[data-tab-index=0]').trigger('click');
        _this._tour = _this._constructTour();
        window.hopscotch.startTour(_this._tour, 0);
        return _this._attachInterruptionHandlers();
      };
    })(this));
  };

  WalkthroughService.prototype._i18n = function(stepNo) {
    return $.t("walkthrough.step" + stepNo);
  };

  WalkthroughService.prototype._clickedIntoWalkthroughControls = function(target) {
    var $target;
    $target = $(target);
    return $target.parents('.hopscotch-bubble-container').length || $target.parents('.notifier.tour-interruption').length;
  };

  WalkthroughService.prototype._expectedClick = function(target) {
    var currTarget, currentStep, isTargetExpectedToBeClicked;
    currTarget = hopscotch.getCurrTarget();
    currentStep = this._tour.steps[window.hopscotch.getCurrStepNum()];
    isTargetExpectedToBeClicked = currentStep.nextOnTargetClick || currentStep.showNextButton === false;
    return isTargetExpectedToBeClicked && (target === currTarget || ((currTarget != null) && $.contains(currTarget, target)));
  };

  WalkthroughService.prototype._tourInterruption = function(e, extraData) {
    if (extraData === 'walkthrough' || this._handledEvents.contains(e.timeStamp)) {
      return;
    }
    this._handledEvents.add(e.timeStamp);
    if (this._clickedIntoWalkthroughControls(e.target) || this._expectedClick(e.target)) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    return mediator.dialogs.confirm({
      message: $.t('walkthrough.interrupt_tour'),
      zIndex: 100000,
      cls: 'tour-interruption'
    }, function(confirmed) {
      if (!confirmed) {
        return;
      }
      window.hopscotch.endTour();
      return mediator.dialogs.modalSuccess($.t('walkthrough.launch_from_menu'));
    });
  };

  WalkthroughService.prototype._attachInterruptionHandlers = function(el, delay) {
    if (el == null) {
      el = null;
    }
    if (delay == null) {
      delay = 350;
    }
    if (el) {
      this.$elForInterruption.push(el);
    }
    return _.delay((function(_this) {
      return function() {
        return $(_this.$elForInterruption.join(',')).on('click.walkthrough', _this._tourInterruption);
      };
    })(this), delay);
  };

  WalkthroughService.prototype._nextStep = _(window.hopscotch.nextStep).bind(window.hopscotch);

  WalkthroughService.prototype._advanceTour = function(fromStep) {
    return (function(_this) {
      return function() {
        if (window.hopscotch.getCurrStepNum() === fromStep - 1) {
          return _(_this._nextStep).delay(_this._tour.steps[fromStep].delay);
        }
      };
    })(this);
  };

  WalkthroughService.prototype._disableOutomeTypeChanges = function() {
    return $('.outcome-type input[type="radio"]').attr('disabled', true);
  };

  WalkthroughService.prototype._constructTour = function() {
    return {
      id: 'gdt-walkthrough',
      templateToUse: 'gdt',
      showCloseButton: false,
      onNext: (function(_this) {
        return function() {
          return _this._attachInterruptionHandlers();
        };
      })(this),
      onEnd: (function(_this) {
        return function() {
          $(_this.$elForInterruption.join(',')).off('click.walkthrough');
          return mediator.unsubscribe(null, null, 'walkthrough');
        };
      })(this),
      i18n: {
        nextBtn: $.t('walkthrough.next'),
        doneBtn: $.t('walkthrough.done')
      },
      steps: [
        {
          content: this._i18n(1),
          target: '.tab-elems .tab-elem.current-tab',
          placement: 'right',
          onNext: function() {
            return $('.tab-elems .tab-elem[data-tab-index=2]').trigger('click', 'walkthrough');
          }
        }, {
          content: this._i18n(2),
          target: '.tab-elems .tab-elem.current-tab',
          placement: 'right',
          onNext: function() {
            return $('.tab-elems .tab-elem[data-tab-index=1]').trigger('click', 'walkthrough');
          },
          onShow: this._attachInterruptionHandlers
        }, {
          content: this._i18n(3),
          target: '.tab-elems .tab-elem.current-tab',
          placement: 'right',
          onShow: this._attachInterruptionHandlers
        }, {
          content: this._i18n(4),
          target: 'button.continue',
          placement: 'left'
        }, {
          content: this._i18n(5),
          target: '#header menu.right',
          placement: 'bottom',
          width: 320
        }, {
          content: this._i18n(6),
          target: 'h1.logo a',
          placement: 'bottom'
        }, {
          content: this._i18n(7),
          target: '.project-types button[data-type="full_guideline"]',
          placement: 'right',
          nextOnTargetClick: true,
          showNextButton: false,
          yOffset: -10
        }, {
          content: this._i18n(8),
          target: '#new-project-dialog button.create',
          placement: 'right',
          delay: 400,
          zindex: 99999,
          showNextButton: false,
          onShow: (function(_this) {
            return function() {
              return mediator.subscribe('projectChanged', _this._advanceTour(8), 'walkthrough');
            };
          })(this)
        }, {
          content: this._i18n(9),
          target: '#sidebar',
          placement: 'right',
          yOffset: 'center',
          arrowOffset: 'center',
          onShow: (function(_this) {
            return function() {
              return _this._attachInterruptionHandlers('body');
            };
          })(this)
        }, {
          content: this._i18n(10),
          target: '#sidebar .tasks',
          placement: 'right',
          yOffset: -15
        }, {
          content: this._i18n(11),
          target: '#sidebar .team',
          placement: 'right',
          yOffset: -15
        }, {
          content: this._i18n(12),
          target: '#sidebar .scope',
          placement: 'right',
          yOffset: -15
        }, {
          content: this._i18n(13),
          target: '#sidebar .document-sections',
          placement: 'right',
          yOffset: -15
        }, {
          content: this._i18n(14),
          target: '#sidebar .evidence-syntheses',
          placement: 'right',
          yOffset: -15
        }, {
          content: this._i18n(15),
          target: '#sidebar .dissemination',
          placement: 'right',
          yOffset: -15
        }, {
          content: this._i18n(16),
          target: 'button.add-management-question',
          placement: 'bottom',
          nextOnTargetClick: true,
          showNextButton: false,
          xOffset: 'center',
          arrowOffset: 'center',
          width: 500
        }, {
          content: this._i18n(17),
          target: '#intervention',
          placement: 'bottom',
          xOffset: 'center',
          arrowOffset: 'center',
          delay: 400,
          onShow: (function(_this) {
            return function() {
              return _this._attachInterruptionHandlers('body');
            };
          })(this)
        }, {
          content: this._i18n(18),
          target: 'menu.context-buttons .context-help',
          placement: 'bottom',
          xOffset: -200,
          arrowOffset: 200,
          nextOnTargetClick: true,
          showNextButton: true,
          onShow: (function(_this) {
            return function() {
              $('#intervention').focus();
              return _this._attachInterruptionHandlers('div.container');
            };
          })(this)
        }, {
          content: this._i18n(19),
          target: '.question.expanded button.save',
          placement: 'left',
          nextOnTargetClick: true,
          showNextButton: false,
          yOffset: -10,
          onShow: (function(_this) {
            return function() {
              return _this._attachInterruptionHandlers('div.container');
            };
          })(this)
        }, {
          content: this._i18n(20),
          target: '.question button.edit',
          placement: 'left',
          delay: 400,
          yOffset: -10,
          showNextButton: true
        }, {
          content: this._i18n(21),
          target: '.question',
          placement: 'bottom',
          delay: 400,
          nextOnTargetClick: false,
          showNextButton: false,
          onShow: (function(_this) {
            return function() {
              return mediator.subscribe('outcomesViewRendered', _this._advanceTour(21), 'walkthrough');
            };
          })(this)
        }, {
          content: this._i18n(22),
          target: '.add-outcome',
          placement: 'bottom',
          delay: 400,
          xOffset: 'center',
          arrowOffset: 'center',
          showNextButton: false,
          onShow: (function(_this) {
            return function() {
              _this._disableOutomeTypeChanges();
              return mediator.currentFocus.model.get('outcomes').on('add', _this._advanceTour(22));
            };
          })(this)
        }, {
          content: this._i18n(23),
          target: '.edit-label-state .outcome-edit-block .label-input',
          placement: 'bottom',
          delay: 200,
          onShow: (function(_this) {
            return function() {
              _this._attachInterruptionHandlers('body');
              return _this._disableOutomeTypeChanges();
            };
          })(this)
        }, {
          content: this._i18n(24),
          target: '.edit-label-state .outcome-edit-block button.save',
          placement: 'left',
          yOffset: -20,
          xOffset: -5,
          nextOnTargetClick: true,
          showNextButton: false,
          onShow: (function(_this) {
            return function() {
              mediator.subscribe('deactivatedProperty', _this._advanceTour(24), 'walkthrough');
              _this._attachInterruptionHandlers('div.container');
              return _this._disableOutomeTypeChanges();
            };
          })(this)
        }, {
          content: this._i18n(25),
          target: '.outcome-row .no-of-studies',
          placement: 'right',
          delay: 500,
          yOffset: -15,
          nextOnTargetClick: true,
          showNextButton: false
        }, {
          content: this._i18n(26),
          target: '.box button.apply',
          placement: 'right',
          delay: 350,
          yOffset: -15,
          nextOnTargetClick: true,
          showNextButton: false,
          onShow: (function(_this) {
            return function() {
              return mediator.currentFocus.on('closed', _this._advanceTour(26), 'walkthrough');
            };
          })(this)
        }, {
          content: this._i18n(27),
          target: '.outcome-row .risk-of-bias',
          placement: 'right',
          delay: 350,
          yOffset: -15,
          nextOnTargetClick: true,
          showNextButton: false
        }, {
          content: this._i18n(28),
          target: 'menu.context-buttons .footnotes',
          placement: 'bottom',
          delay: 100,
          xOffset: -200,
          arrowOffset: 200,
          nextOnTargetClick: true,
          showNextButton: false
        }, {
          content: this._i18n(29),
          target: '.footnotes-box .new-footnote-button',
          placement: 'bottom',
          delay: 200,
          xOffset: 'center',
          arrowOffset: 'center',
          nextOnTargetClick: true,
          showNextButton: false
        }, {
          content: this._i18n(30),
          target: '.footnotes-box',
          placement: 'right',
          delay: 200,
          yOffset: -20,
          showNextButton: false,
          onShow: (function(_this) {
            return function() {
              return mediator.subscribe('cellFootnotesClosed', _this._advanceTour(30), 'walkthrough');
            };
          })(this)
        }, {
          content: this._i18n(31),
          target: '.box',
          placement: 'right',
          delay: 350,
          yOffset: 'center',
          arrowOffset: 'center',
          showNextButton: false,
          onShow: (function(_this) {
            return function() {
              return mediator.currentFocus.on('closed', _this._advanceTour(31), 'walkthrough');
            };
          })(this)
        }, {
          content: this._i18n(32),
          target: '.outcome-row .risk-of-bias',
          placement: 'right',
          yOffset: -15,
          onShow: (function(_this) {
            return function() {
              return mediator.subscribe('cellFootnotesClosed', _this._attachInterruptionHandlers, 'walkthrough');
            };
          })(this)
        }, {
          content: this._i18n(33),
          target: '#toolbar-container button.outcome-view',
          placement: 'left',
          xOffset: -10,
          yOffset: -10,
          arrowOffset: 0,
          nextOnTargetClick: true,
          showNextButton: true
        }, {
          content: this._i18n(36),
          target: '#sidebar .evidence-syntheses',
          placement: 'right',
          delay: 400,
          yOffset: -15,
          nextOnTargetClick: true,
          showNextButton: true
        }, {
          content: this._i18n(37),
          target: '#header .projects .title a',
          placement: 'bottom',
          delay: 550,
          nextOnTargetClick: true,
          showNextButton: false
        }, {
          content: this._i18n(38),
          target: '#header .projects .projects-menu a.projects-list',
          placement: 'right',
          delay: 400,
          yOffset: -20,
          nextOnTargetClick: true,
          showNextButton: false,
          onShow: (function(_this) {
            return function() {
              return _this._attachInterruptionHandlers('div.container');
            };
          })(this)
        }, {
          content: this._i18n(39),
          target: '#header menu.right button.help',
          placement: 'bottom',
          xOffset: -250,
          arrowOffset: 240
        }
      ]
    };
  };

  return WalkthroughService;

})();
