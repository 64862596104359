var CONSENSUS_SUFFIX, DbHelper, ProjectTask, QUESTIONS_DB_VIEW, RemoveOldVotingConsensusProjectTask, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ProjectTask = require('lib/project_tasks/task');

DbHelper = require('base/lib/db_helper');

QUESTIONS_DB_VIEW = require('lib/questions_helper').QUESTIONS_DB_VIEW;

W = require('when');

CONSENSUS_SUFFIX = 'Consensus';

module.exports = RemoveOldVotingConsensusProjectTask = (function(_super) {
  __extends(RemoveOldVotingConsensusProjectTask, _super);

  function RemoveOldVotingConsensusProjectTask() {
    RemoveOldVotingConsensusProjectTask.__super__.constructor.call(this, 'remove_old_voting_consensus');
  }

  RemoveOldVotingConsensusProjectTask.prototype.unsafeRun = function(projectId) {
    var projectDb;
    projectDb = DbHelper.localReplica(projectId);
    return W(projectDb.query(QUESTIONS_DB_VIEW)).then(function(_arg) {
      var etdIds, questionRows;
      questionRows = _arg.rows;
      etdIds = _.chain(questionRows).pluck('value').pluck('recommendationIds').compact().filter(function(ids) {
        return (ids != null) && ids.length;
      }).flatten().value();
      return projectDb.allDocs({
        include_docs: true,
        keys: etdIds
      }).then(function(_arg1) {
        var etdRows, etds, walk;
        etdRows = _arg1.rows;
        etds = _.chain(etdRows).pluck('doc').compact().value();
        walk = function(obj) {
          _.forEach(_.pairs(obj), function(_arg2) {
            var key, keyWithoutSuffix, value;
            key = _arg2[0], value = _arg2[1];
            if (_.str.endsWith(key, CONSENSUS_SUFFIX)) {
              keyWithoutSuffix = key.substring(0, key.length - CONSENSUS_SUFFIX.length);
              obj[keyWithoutSuffix] = value;
              return delete obj[key];
            } else if (_.isObject(value)) {
              return walk(value);
            }
          });
          return obj;
        };
        return projectDb.bulkDocs(_.map(etds, walk));
      });
    }).then(function() {
      return {
        message: "Consensus removed from project " + projectId
      };
    });
  };

  return RemoveOldVotingConsensusProjectTask;

})(ProjectTask);
