mediator = require 'mediator'
W = require 'when'
SelectCustom = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
templatesDefinitions = require 'lib/doc_sections_templates/templates_definitions'

{ func, string } = PropTypes

TemplateTypeSelect = createReactClass

  displayName: 'TemplateTypeSelect'

  mixins: [Translation('projects:new_project_dialog')]

  propTypes:
    onTemplateTypeSelect: func.isRequired,
    selected: string,

  getDefaultProps: ->
    selected: null

  getTemplateTypeOptions: ->
    templatesDefinitions
    .filter (option) ->
      option.featureSwitch is 'docSectionsTemplate'
    .map (option) ->
      value: option.id
      text: option.name
      label: option.name

  render: ->
    <label>
      <span>{@i18n 'document_template'}</span>
      <div className='input-el'>
        <SelectCustom
          options={@getTemplateTypeOptions()}
          onChange={@props.onTemplateTypeSelect}
          selected={@props.selected}
        />
      </div>
    </label>

module.exports = TemplateTypeSelect
