var GeneralScopeActions, alt, mediator, mocHelpers;

alt = require('alt');

mocHelpers = require('actions/utils/moc_helpers');

mediator = require('mediator');

GeneralScopeActions = (function() {
  function GeneralScopeActions() {
    this.generateActions('pouchFetchSuccess', 'pouchSaveSuccess', 'disableContentEditable', 'enableContentEditable', 'toggleExportDialog');
  }

  GeneralScopeActions.prototype.fetch = function() {
    return this.dispatch({
      projectId: mediator.project.id
    });
  };

  GeneralScopeActions.prototype.pouchFetchError = function(error) {
    throw error;
  };

  GeneralScopeActions.prototype.pouchSaveError = function(error) {
    throw error;
  };

  GeneralScopeActions.prototype.updateContentEditableContent = function(contentEditableId, content) {
    return this.dispatch({
      fieldName: contentEditableId,
      content: content
    });
  };

  return GeneralScopeActions;

})();

module.exports = alt.createActions(GeneralScopeActions);
