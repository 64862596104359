var aggregator, createAggregator, exclude, excludeRx, filter, filteredFramesMsg, formatter, logger, mergePromiseFrames, reasonMsg, rejectionMsg, reporter, simpleFormatter, simpleReporter, stackFilter, stackJumpMsg, throttleReporter;

createAggregator = require('when/monitor/aggregator');

throttleReporter = require('when/monitor/throttledReporter');

simpleReporter = require('when/monitor/simpleReporter');

simpleFormatter = require('when/monitor/simpleFormatter');

stackFilter = require('when/monitor/stackFilter');

logger = require('base/lib/when_logger');

mergePromiseFrames = function() {
  return filteredFramesMsg;
};

exclude = function(line) {
  return excludeRx.test(line);
};

rejectionMsg = '=== Unhandled rejection escaped at ===';

reasonMsg = '=== Caused by reason ===';

stackJumpMsg = '  --- new call stack ---';

filteredFramesMsg = '  ...[filtered frames]...';

excludeRx = /when\.js|(module|node)\.js:\d|when\/monitor\//i;

filter = stackFilter(exclude, mergePromiseFrames);

formatter = simpleFormatter(filter, rejectionMsg, reasonMsg, stackJumpMsg);

reporter = simpleReporter(formatter, logger);

aggregator = createAggregator(throttleReporter(200, reporter));

aggregator.publish(console);
