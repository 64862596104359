{ useCallback, useEffect, useLayoutEffect, useMemo, useReducer } = React

_i18n = (namespace, key, params = {}) ->
  keyWithNamespace = if _.string.startsWith key, '/'
    key[1..]
  else if _.string.startsWith key, '../'
    namespaceTree = namespace.split /\.|:/
    if namespaceTree.length is 1
      key
    else
      levelsToGoUp = key.match(/\.\.\//g).length
      normalizedKey = key.replace /\.\.\//g, ''
      namespaceTree
        # navigate up in namespace tree
        .slice(0, namespaceTree.length - levelsToGoUp)
        # prepare keyWithNamespace array
        .concat([ normalizedKey ])
        # return a resuling keyWithNamespace string
        .join '.'
        # first '.' needs to be replaced with ':' as head of resulting path is the root of
        # translation namespace
        .replace '.', ':'
  else if namespace.length
    "#{namespace}.#{key}"
  else key
  $.t keyWithNamespace, params

# componentParams are translation parameters which will be replaced with React component
i18n = (namespace) -> (key, params = {}, componentParams = null) ->
  componentParamKey = (paramKey) -> "\0#{paramKey}\0"
  for componentParam of componentParams ? {}
    params[componentParam] = componentParamKey(componentParam)
  string = _i18n namespace, key, params
  if componentParams
    fragments = []
    replaceWithComponent = (string, key, component) ->
      componentParamKeyString = componentParamKey(paramKey)
      if _.str.contains string, componentParamKeyString
        split = string.split componentParamKeyString
        fragments.push split[0]
        fragments.push component
        split[1]
      else
        string

    for paramKey, component of componentParams
      string = replaceWithComponent string, paramKey, component

    fragments.push string

    fragments.map (fragment, idx) ->
      if _.isString(fragment)
        fragment
      else
        React.createElement React.Fragment, { key: idx }, fragment
  else
    string

# This reverses order of dependency array and callback due to the fact that
# in Coffeescript it looked bad:
# onSth = useCallback(
#   -> console.log test
# ,
#   [test]
# )
# vs.
# onSth = useCoffeeCallback [test], -> console.log test
useCoffeeCallback = (deps, fn) ->
  if arguments.length < 2
    fn = deps
    deps = undefined
  useCallback fn, deps

useCoffeeEffect = (deps, fn) ->
  if arguments.length < 2
    fn = deps
    deps = undefined
  useEffect fn, deps

useCoffeeLayoutEffect = (deps, fn) ->
  if arguments.length < 2
    fn = deps
    deps = undefined
  useLayoutEffect fn, deps

useCoffeeMemo = (deps, fn) ->
  if arguments.length < 2
    fn = deps
    deps = undefined
  useMemo fn, deps

# TODO: Rewrite using context
useI18n = (namespace = '') -> i18n namespace

useSetState = (initialState) ->
  reducer = (state, toMerge) ->
    if _.isFunction(toMerge)
      toMerge(state)
    else
      _.extend {}, state, toMerge
  useReducer reducer, initialState

module.exports = {
  i18nForMixin: i18n
  useCoffeeCallback
  useCoffeeEffect
  useCoffeeLayoutEffect
  useCoffeeMemo
  useI18n
  useSetState
}
