Button = require 'components/common/button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ COI_PROJECT_ID } = require 'lib/mdg_helper'
mediator = require 'mediator'
MembersActions = require 'actions/members_actions'
Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'
{ getAccessRightsList } = require 'lib/members_helper'
{ func, string, bool} = PropTypes

MembersToolbar = createReactClass
  displayName: 'MembersToolbar'

  mixins: [
    Translation('team:member'), CustomRenderMixin
  ]

  propTypes:
    showAddTeamMemberModal: func.isRequired
    showAddMultipleTeamMemberModal: func.isRequired
    onFilterByAccessRight: func.isRequired
    projectFromOrganization: bool.isRequired,
    filterByAccessRight: string

  getAccessRightsList: ->
    [
      value: 'all'
      text: @i18n 'all'
    ].concat getAccessRightsList()

  onImportMembers: ->
    MembersActions.openImportModal()

  render: ->
    showImportButton = mediator.services.switches.isServerSwitchOn('mdgFeatures') and
      mediator.project.id isnt COI_PROJECT_ID

    <div className="team-member-toolbar">
      <div className="row">
        <div className="col-4">
          <div className="filter-by">
            <span><b>{@i18n '/translation:actions.filter_by'}:</b> </span>
            <Select
              options={@getAccessRightsList()}
              selected={@props.filterByAccessRight}
              onChange={@props.onFilterByAccessRight}
            />
          </div>
        </div>
        <div className="col-2" />
        <div className="col-6 text-right">
          {unless @props.projectFromOrganization
            <div>
              <Button
                className='btn add-team-member'
                label={@i18n 'add_title'}
                onClick={@props.showAddTeamMemberModal('single')}
              />
              <Button
                className='btn add-multiple-team-member'
                label={@i18n 'add_multiple.title'}
                onClick={@props.showAddMultipleTeamMemberModal('multiple')}
              />
            </div>
          else
            <div>
              <Button
                className='btn add-team-member'
                disabled={not @props.isOnline}
                label={@i18n 'add_title'}
                onClick={@props.showAddTeamMemberModal('organization')}
              />
              {showImportButton and <Button
                className='btn import-team-members'
                disabled={not @props.isOnline}
                label={@i18n 'import_members'}
                onClick={@onImportMembers}
              />}
            </div>
          }
        </div>
      </div>
      <div className="clearfix" />
    </div>

module.exports = MembersToolbar
