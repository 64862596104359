var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"questions-view\">\n  <ul class=\"standard-list\"></ul>\n</div>\n<div class=\"show-original-version\"></div>\n<div class=\"outcomes-table-container\">\n  <table class=\"standard-table outcomes-table\">\n    <thead>\n      <tr class=\"outcome-one-row\">\n        <th rowspan=\"1\" colspan=\"7\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "certainty_assessment", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th colspan=\"5\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "summary_of_findings", {"name":"i18n","hash":{},"data":data})))
    + "</th>\n        <th rowspan=\"3\" colspan=\"1\" class=\"importance\">\n          <div class=\"cell-label-with-table-menu\">\n            <div class=\"label\">\n              <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "importance", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n              <span class=\"context-help-icon\" data-property=\"importance\"></span>\n            </div>\n            <div class=\"table-menu\">\n              <button class=\"table-mode\"></button>\n            </div>\n          </div>\n        </th>\n      </tr>\n      <tr class=\"outcome-one-row\">\n        <th rowspan=\"2\" class=\"no-of-studies\">\n          <span class=\"value\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "no_of_studies", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n          <span class=\"context-help-icon\" data-property=\"noOfStudies\"></span>\n        </th>\n        <th rowspan=\"2\" class=\"design-studies\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "study_design", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"designStudies\"></span>\n        </th>\n        <th rowspan=\"2\" class=\"risk-of-bias\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "risk_of_bias", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"riskOfBias\"></span>\n        </th>\n        <th rowspan=\"2\" class=\"inconsistency\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "inconsistency", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"inconsistency\"></span>\n        </th>\n        <th rowspan=\"2\" class=\"indirectness\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "indirectness", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"indirectness\"></span>\n        </th>\n        <th rowspan=\"2\" class=\"imprecision\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "imprecision", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"imprecision\"></span>\n        </th>\n        <th rowspan=\"2\" class=\"other-considerations\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "other_considerations", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </th>\n        <th colspan=\"2\" class=\"regular-header no-of-patients\">\n          <span class=\"value\">";
  stack1 = ((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "no_of_patients", {"name":"i18n","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n        </th>\n        <th colspan=\"2\" class=\"regular-header\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "effect", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </th>\n        <th rowspan=\"2\" colspan=\"4\" class=\"impact narrative-header\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "impact", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </th>\n        <th rowspan=\"2\" class=\"quality\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "certainty", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"quality\"></span>\n        </th>\n      </tr>\n      <tr class=\"outcome-one-row\">\n        <th class=\"intervention regular-header\">\n          <span class=\"value\"></span>\n          <span class=\"context-help-icon\" data-property=\"intervention\"></span>\n        </th>\n        <th class=\"control regular-header\">\n          <span class=\"value\"></span>\n          <span class=\"context-help-icon\" data-property=\"control\"></span>\n        </th>\n        <th class=\"relative-effect regular-header\">\n          <span class=\"value\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relative", {"name":"i18n","hash":{},"data":data})))
    + "<br>(95% CI)\n          </span>\n          <span class=\"context-help-icon\" data-property=\"relativeEffect\"></span>\n        </th>\n        <th class=\"absolute-effect regular-header\">\n          <span class=\"value\">\n            "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "absolute", {"name":"i18n","hash":{},"data":data})))
    + "<br>(95% CI)\n          </span>\n          <span class=\"context-help-icon\" data-property=\"absoluteEffect\"></span>\n        </th>\n      </tr>\n    </thead>\n    <tbody></tbody>\n  </table>\n";
  stack1 = this.invokePartial(partials.outcomeButtons, '  ', 'outcomeButtons', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}