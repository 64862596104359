AdolopmentSectionSeparator = require 'components/etd/adolopment_section_separator'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
TypeOfRecommendation = require 'components/etd/type_of_recommendation'
UserProjectDataActions = require 'actions/user_project_data_actions'


toggleOriginalSection = (etdId, sectionId) -> ->
  UserProjectDataActions.toggleOriginalDataSection etdId, sectionId

toggleAdolopmentSection = (etdId, sectionId) -> ->
  UserProjectDataActions.toggleAdolopmentDataSection etdId, sectionId

AdolopmentSeparator = ({i18n, section, onClick}) ->
  <table className="standard-table recommendation-table-static">
    <tbody>
      <AdolopmentSectionSeparator
        type="conclusions"
        onClick={onClick}
        i18n={i18n}
        section={section}
      />
    </tbody>
  </table>


TypeOfRecommendationAdolopment = createReactClass

  displayName: "TypeOfRecommendationAdolopment"

  mixins: [CustomRenderMixin, Translation('es:recommendations.table')]

  propTypes:
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired
    etdId:PropTypes.string.isRequired,
    etdViewSettings: PropTypes.instanceOf(Immutable.Map).isRequired,
    renderMode: PropTypes.string.isRequired,
    sectionId: PropTypes.string.isRequired,

  render: ->
    { adolopments, etdId, renderMode, sectionId, etdViewSettings } = @props
    originalData = not etdViewSettings.getIn([etdId, 'collapsedOriginalSections', sectionId], false)
    adolopment = adolopments.last()
    <div>
      <AdolopmentSeparator
        i18n={@i18n}
        onClick={toggleOriginalSection(etdId, sectionId)}
        section='original'
      />
      {originalData and
        <TypeOfRecommendation
          className='recommendation-table-static'
          editable={false}
          hightlightToPanelMember={false}
          key={adolopment.get('_id')}
          readOnly={true}
          renderMode={renderMode}
          section={adolopment.getIn(['templateData', 'conclusions', 'sections', sectionId])}
          sectionId={sectionId}
        />
      }
      <AdolopmentSeparator
        i18n={@i18n}
        section="adolopment"
        onClick={toggleAdolopmentSection(etdId, sectionId)}
        />
    </div>

module.exports = TypeOfRecommendationAdolopment
