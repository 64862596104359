Tooltip = require 'components/common/tooltip'
formatters = require 'lib/formatters'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

styles =
  table:
    width: '100%'
    tableLayout: 'fixed'
    margin: '0'
    fontSize: '8pt'
    fontFamily: "Verdana, 'Arial Narrow', Arial, sans-serif"
  cell:
    fontSize: '8pt'
    border: '1px solid #bfbfbf'

RelativeImortance = createReactClass
  displayName: 'RelativeImortance'

  propTypes:
    questionData: PropTypes.object.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:outcome')
  ]

  renderOutcome: (outcome, idx) ->
    { name, importanceLabel } = outcome
    quality = formatters.outcome.quality outcome

    <tr key={idx}>
      <td style={styles.cell}>{name}</td>
      <td style={styles.cell}>{importanceLabel}</td>
      <td style={styles.cell}>
        <div dangerouslySetInnerHTML={__html: quality} />
      </td>
    </tr>

  render: ->
    relativeImortanceText = @i18n '../recommendations.table.benefits_harms.relative_importance'
    relativeImortanceTooltip = @i18n '../recommendations.table.tooltips.relative_importance'
    evidenceCertaintyText = @i18n '../recommendations.table.benefits_harms.evidence_certainty'
    evidenceCertaintyTooltip = @i18n '../recommendations.table.tooltips.evidence_certainty_sof'

    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.cell}>{@i18n 'outcome'}</th>
          <th className='with-info' style={styles.cell}>
            <Tooltip>
              <span className='info-sign' title={relativeImortanceTooltip} />
            </Tooltip>
            {relativeImortanceText}
          </th>
          <th className='with-info' style={styles.cell}>
            <Tooltip>
              <span className='info-sign' title={evidenceCertaintyTooltip} />
            </Tooltip>
            {evidenceCertaintyText}
          </th>
        </tr>
      </thead>
      <tbody>
        {@props.questionData.includedOutcomes.map @renderOutcome}
      </tbody>
    </table>

module.exports = RelativeImortance
