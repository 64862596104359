var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"hopscotch-bubble-container\" style=\"width: "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.width : stack1), depth0))
    + "px; \">\n  <div class=\"hopscotch-bubble-content\">\n      <div class=\"text-center\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.title : stack1), depth0))
    + "</div>\n      <div class=\"hopscotch-content mt-20\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.content : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n  </div>\n  <div class=\"hopscotch-actions\">\n    <div class=\"row\">\n        <div class=\"col-4\">\n          <button class=\"btn btn-block hopscotch-nav-button next hopscotch-cta\">\n            "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.buttons : depth0)) != null ? stack1.ctaLabel : stack1), depth0))
    + "\n          </button>\n        </div>\n        <div class=\"col-8\">\n          <button class=\"btn btn-block btn-success hopscotch-nav-button next hopscotch-next\">\n            "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.i18n : depth0)) != null ? stack1.nextBtn : stack1), depth0))
    + "\n          </button>\n        </div>\n      </div>\n  </div>\n</div>\n<div class=\"hopscotch-bubble-arrow-container hopscotch-arrow\">\n  <div class=\"hopscotch-bubble-arrow-border\"></div>\n  <div class=\"hopscotch-bubble-arrow\"></div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}