var INITIAL_STATE, PanelVoiceActions, PanelVoiceStore, alt, errorHandler, getDenormalizedEtdVotingData, getKeyValObject, getQuestionEtdId, getQuestionEtdSnapshots, getQuestionLastVotingResults, getQuestionVotingResultsByTimestamp, getQuestionVotingStages, immutable, mediator, panelVoiceErrorHandler, timesToShowOnboardingProposal, userPanelVoicePreferences, _ref, _ref1;

alt = require('alt');

immutable = require('stores/utils/immutable');

mediator = require('mediator');

PanelVoiceActions = require('actions/panel_voice_actions');

getKeyValObject = require('base/lib/utils').getKeyValObject;

_ref = require('lib/app_utils'), errorHandler = _ref.errorHandler, panelVoiceErrorHandler = _ref.panelVoiceErrorHandler;

userPanelVoicePreferences = require('lib/voting_helper').userPanelVoicePreferences;

timesToShowOnboardingProposal = require('lib/constants').timesToShowOnboardingProposal;

_ref1 = require('stores/utils/panel_voice_helpers'), getQuestionEtdId = _ref1.getQuestionEtdId, getQuestionEtdSnapshots = _ref1.getQuestionEtdSnapshots, getQuestionVotingStages = _ref1.getQuestionVotingStages, getQuestionLastVotingResults = _ref1.getQuestionLastVotingResults, getQuestionVotingResultsByTimestamp = _ref1.getQuestionVotingResultsByTimestamp, getDenormalizedEtdVotingData = _ref1.getDenormalizedEtdVotingData;

INITIAL_STATE = Immutable.fromJS({
  meta: {
    fetchingPanelVoiceMetadata: false,
    fetchingPanelVoicePreferences: false,
    fetchingVotingResults: false,
    fetchingEtdSnapshots: false,
    fetchingEtdHighlightDocs: true,
    fetchingUserData: false,
    searchText: '',
    statusFilter: 'all',
    onboardNotificationShown: false,
    feedbackStatusDialogOpened: false,
    addManualResultsModalOpened: false
  },
  panelVoiceMetadata: {},
  panelVoicePreferences: {
    contactEmail: "",
    sendVoteMessage: null,
    remindVoteMessage: null,
    keepMessage: false
  },
  userPanelVoicePreferences: {},
  votingMessageAttachments: {},
  votingResults: [],
  etdsSnapshots: {},
  etdsWithHighlights: {}
});

PanelVoiceStore = (function() {
  function PanelVoiceStore() {
    this.state = INITIAL_STATE;
    this.bindActions(PanelVoiceActions);
    this.exportPublicMethods({
      getPanelVoiceMetadata: this.getPanelVoiceMetadata,
      isFetchingMetadata: this.isFetchingMetadata,
      isFetchingVotingResults: this.isFetchingVotingResults,
      isFetchingEtdSnapshots: this.isFetchingEtdSnapshots,
      isFetchingEtdHighlightDocs: this.isFetchingEtdHighlightDocs,
      isFetchingPreferences: this.isFetchingPreferences,
      isFetchingUserData: this.isFetchingUserData,
      getSearchText: this.getSearchText,
      getStatusFilter: this.getStatusFilter,
      getQuestionVotingStatus: this.getQuestionVotingStatus,
      getQuestionVotingStages: this.getQuestionVotingStages,
      getLastVotingResults: this.getLastVotingResults,
      getSendModalOptions: this.getSendModalOptions,
      getPanelVoicePreferences: this.getPanelVoicePreferences,
      getQuestionVotingResultsByTimestamp: this.getQuestionVotingResultsByTimestamp,
      getVotingResults: this.getVotingResults,
      getEtdSnapshots: this.getEtdSnapshots,
      getEtdHighlights: this.getEtdHighlights,
      getEtdsWithHighlights: this.getEtdsWithHighlights,
      isSendingReminders: this.isSendingReminders,
      getOpenedRemindersModal: this.getOpenedRemindersModal,
      isVotingCompletelyClosed: this.isVotingCompletelyClosed,
      getEtdHighlightsByRoundMap: this.getEtdHighlightsByRoundMap,
      shouldShowOnboardingProposal: this.shouldShowOnboardingProposal,
      getDenormalizedEtdVotingData: this.getDenormalizedEtdVotingData,
      isFeedbackStatusDialogOpened: this.isFeedbackStatusDialogOpened,
      isMemberInPanelVoice: this.isMemberInPanelVoice.bind(this),
      isAddManualResultsModalOpened: this.isAddManualResultsModalOpened,
      getVotedMemberIds: this.getVotedMemberIds,
      getSentMemberIds: this.getSentMemberIds,
      getVotingMessageAttachments: this.getVotingMessageAttachments,
      getVotingMessageAttachmentsSize: this.getVotingMessageAttachmentsSize,
      showVotingResendInfoDialog: this.showVotingResendInfoDialog
    });
  }

  PanelVoiceStore.prototype.onFetchMetadata = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingPanelVoiceMetadata'], true));
  };

  PanelVoiceStore.prototype.onFetchPreferences = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingPanelVoicePreferences'], true));
  };

  PanelVoiceStore.prototype.onFetchMetadataSuccess = function(metadata) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetchingPanelVoiceMetadata'], false);
      return state.set('panelVoiceMetadata', Immutable.fromJS(metadata));
    }));
  };

  PanelVoiceStore.prototype.onFetchMetadataError = function(err) {
    if (err.status !== 404) {
      errorHandler(err);
    }
    return this.setState(this.state.setIn(['meta', 'fetchingPanelVoiceMetadata'], false));
  };

  PanelVoiceStore.prototype.onFetchVotingResults = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingVotingResults'], true));
  };

  PanelVoiceStore.prototype.onFetchPreferencesSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetchingPanelVoicePreferences'], false);
      return state.set('panelVoicePreferences', Immutable.fromJS(data));
    }));
  };

  PanelVoiceStore.prototype.onFetchPreferencesError = function(err) {
    if (err.status !== 404) {
      errorHandler(err);
    }
    return this.setState(this.state.setIn(['meta', 'fetchingPanelVoicePreferences'], false));
  };

  PanelVoiceStore.prototype.onFetchVotingResultsSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetchingVotingResults'], false);
      return state.set('votingResults', Immutable.fromJS(_.pluck(data.rows, 'doc')));
    }));
  };

  PanelVoiceStore.prototype.onFetchVotingResultsError = function(err) {
    if (err.status !== 404) {
      errorHandler(err);
    }
    return this.setState(this.state.setIn(['meta', 'fetchingVotingResults'], false));
  };

  PanelVoiceStore.prototype.onFetchEtdSnapshots = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingEtdSnapshots'], true));
  };

  PanelVoiceStore.prototype.onFetchEtdSnapshotsSuccess = function(_arg) {
    var etdId, snapshotsData;
    etdId = _arg.etdId, snapshotsData = _arg.snapshotsData;
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetchingEtdSnapshots'], false);
      return state.setIn(['etdsSnapshots', etdId], Immutable.fromJS(snapshotsData));
    }));
  };

  PanelVoiceStore.prototype.onFetchEtdSnapshotsError = function(err) {
    this.setState(this.state.setIn(['meta', 'fetchingEtdSnapshots'], false));
    return errorHandler(err);
  };

  PanelVoiceStore.prototype.onFetchUserData = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingUserData'], true));
  };

  PanelVoiceStore.prototype.onFetchUserDataSuccess = function(userData) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetchingUserData'], false);
      return state.set('userPanelVoicePreferences', Immutable.fromJS(_.pick(userData, userPanelVoicePreferences)));
    }));
  };

  PanelVoiceStore.prototype.onFetchUserDataError = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingUserData'], false));
  };

  PanelVoiceStore.prototype.onUpdateUserPanelVoicePreferences = function(newPrefs) {
    return this.setState(this.state.mergeIn(['userPanelVoicePreferences'], Immutable.fromJS(newPrefs)));
  };

  PanelVoiceStore.prototype.onFetchEtdHighlightDocs = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingEtdHighlightDocs'], true));
  };

  PanelVoiceStore.prototype.onFetchEtdHighlightDocsSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      var etdHighlightsMap;
      state.setIn(['meta', 'fetchingEtdHighlightDocs'], false);
      etdHighlightsMap = _.chain(data.rows).pluck('doc').reduce(function(acc, doc) {
        var etdId, highlightedSections;
        etdId = doc.etdId, highlightedSections = doc.highlightedSections;
        return _.extend(acc, getKeyValObject(etdId, highlightedSections));
      }, {}).value();
      return state.set('etdsWithHighlights', Immutable.fromJS(etdHighlightsMap));
    }));
  };

  PanelVoiceStore.prototype.onFetchEtdHighlightDocsError = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingEtdHighlightDocs'], false));
  };

  PanelVoiceStore.prototype.onChangeStatusFilter = function(newVal) {
    return this.setState(this.state.setIn(['meta', 'statusFilter'], newVal));
  };

  PanelVoiceStore.prototype.onSearch = function(searchText) {
    return this.setState(this.state.setIn(['meta', 'searchText'], searchText));
  };

  PanelVoiceStore.prototype.onResetSearch = function() {
    return this.setState(this.state.setIn(['meta', 'searchText'], ''));
  };

  PanelVoiceStore.prototype.onResetMetadata = function(metadata) {
    return this.setState(this.state.set('panelVoiceMetadata', Immutable.fromJS(metadata)));
  };

  PanelVoiceStore.prototype.onUpdateResultsWithResponse = function(updatedResponse) {
    var updatedResponseId;
    updatedResponseId = updatedResponse._id;
    return this.setState(this.state.update('votingResults', function(votingResults) {
      var existingResponseIdx, immutableResponse;
      existingResponseIdx = votingResults.findIndex(function(r) {
        return r.get('_id') === updatedResponseId;
      });
      immutableResponse = Immutable.fromJS(updatedResponse);
      if (existingResponseIdx === -1) {
        return votingResults.push(immutableResponse);
      } else {
        return votingResults.update(existingResponseIdx, function() {
          return immutableResponse;
        });
      }
    }));
  };

  PanelVoiceStore.prototype.onAddVotingMessageAttachment = function(data) {
    return this.setState(this.state.setIn(['votingMessageAttachments', data.name], Immutable.fromJS(data)));
  };

  PanelVoiceStore.prototype.onRemoveVotingMessageAttachment = function(name) {
    return this.setState(this.state.deleteIn(['votingMessageAttachments', name]));
  };

  PanelVoiceStore.prototype.getVotingResults = function() {
    return this.state.get('votingResults', new Immutable.List());
  };

  PanelVoiceStore.prototype.onUpdateEtdHighlights = function(etdHighlightsDoc) {
    var etdId, highlightedSections;
    etdId = etdHighlightsDoc.etdId, highlightedSections = etdHighlightsDoc.highlightedSections;
    return this.setState(this.state.update('etdsWithHighlights', function(etdsWithHighlights) {
      return etdsWithHighlights.set(etdId, Immutable.fromJS(highlightedSections));
    }));
  };

  PanelVoiceStore.prototype.getQuestionVotingStages = function(questionId) {
    return getQuestionVotingStages(this.state, questionId);
  };

  PanelVoiceStore.prototype.getLastVotingResults = function(questionId) {
    return getQuestionLastVotingResults(this.state, questionId);
  };

  PanelVoiceStore.prototype.getVotedMemberIds = function(questionId) {
    var _ref2;
    return (_ref2 = this.getLastVotingResults(questionId)) != null ? _ref2.get('results').map(function(r) {
      return r.get('memberId');
    }) : void 0;
  };

  PanelVoiceStore.prototype.getSentMemberIds = function(questionId) {
    var _ref2, _ref3;
    return (_ref2 = (_ref3 = this.getQuestionVotingStages(questionId).filter(function(stage) {
      return stage.get('status') !== 'test';
    }).last()) != null ? _ref3.get('members') : void 0) != null ? _ref2 : Immutable.List();
  };

  PanelVoiceStore.prototype.getQuestionVotingResultsByTimestamp = function(questionId) {
    return getQuestionVotingResultsByTimestamp(this.state, questionId);
  };

  PanelVoiceStore.prototype.getQuestionVotingStatus = function(questionId) {
    var etdId, votingStages;
    etdId = getQuestionEtdId(this.state, questionId);
    votingStages = this.state.getIn(['panelVoiceMetadata', 'etds', etdId, 'stages']);
    if (votingStages != null) {
      return votingStages.last().get('status');
    } else {
      return 'unsent';
    }
  };

  PanelVoiceStore.prototype.getPanelVoiceMetadata = function() {
    return this.state.get('panelVoiceMetadata');
  };

  PanelVoiceStore.prototype.getPanelVoicePreferences = function() {
    return this.state.get('panelVoicePreferences');
  };

  PanelVoiceStore.prototype.onUpdatePanelVoicePreferences = function(data) {
    return this.setState(this.state.set('panelVoicePreferences', Immutable.fromJS(data)));
  };

  PanelVoiceStore.prototype.onUpdatePanelVoicePreferencesError = function(err) {
    return errorHandler(err);
  };

  PanelVoiceStore.prototype.onSendVoting = function() {
    return this.setState(this.state.setIn(['meta', 'sendModalOptions', 'sending'], true));
  };

  PanelVoiceStore.prototype.onSendVotingSuccess = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['votingMessageAttachments'], Immutable.Map());
      return state.deleteIn(['meta', 'sendModalOptions']);
    }));
  };

  PanelVoiceStore.prototype.onSendVotingError = function(err) {
    this.setState(this.state.setIn(['votingMessageAttachments'], Immutable.Map()));
    return panelVoiceErrorHandler(err);
  };

  PanelVoiceStore.prototype.onCloseVotingError = function(err) {
    return panelVoiceErrorHandler(err);
  };

  PanelVoiceStore.prototype.onShowFeedbackStatusDialog = function() {
    return this.setState(this.state.setIn(['meta', 'feedbackStatusDialogOpened'], true));
  };

  PanelVoiceStore.prototype.onHideFeedbackStatusDialog = function() {
    return this.setState(this.state.setIn(['meta', 'feedbackStatusDialogOpened'], false));
  };

  PanelVoiceStore.prototype.onShowAddManualResultsModal = function() {
    return this.setState(this.state.setIn(['meta', 'addManualResultsModalOpened'], true));
  };

  PanelVoiceStore.prototype.onHideAddManualResultsModal = function() {
    return this.setState(this.state.setIn(['meta', 'addManualResultsModalOpened'], false));
  };

  PanelVoiceStore.prototype.onShowRemindersModal = function(etdId) {
    return this.setState(this.state.setIn(['meta', 'openedRemindersModal'], etdId));
  };

  PanelVoiceStore.prototype.onHideRemindersModal = function() {
    return this.setState(this.state.deleteIn(['meta', 'openedRemindersModal']));
  };

  PanelVoiceStore.prototype.onSendReminders = function() {
    return this.setState(this.state.setIn(['meta', 'sendingReminders'], true));
  };

  PanelVoiceStore.prototype.onSendRemindersSuccess = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'sendingReminders'], false);
      state.deleteIn(['meta', 'openedRemindersModal']);
      return state.setIn(['meta', 'feedbackStatusDialogOpened'], false);
    }));
  };

  PanelVoiceStore.prototype.onSendRemindersError = function(err) {
    this.setState(this.state.setIn(['meta', 'sendingReminders'], false));
    return panelVoiceErrorHandler(err);
  };

  PanelVoiceStore.prototype.onStartPanelVoiceOnboarding = function() {
    return this.setState(this.state.setIn(['meta', 'onboardNotificationShown'], true));
  };

  PanelVoiceStore.prototype.onHideOnboardingNotification = function() {
    return this.setState(this.state.setIn(['meta', 'onboardNotificationShown'], true));
  };

  PanelVoiceStore.prototype.isFetchingMetadata = function() {
    return this.state.getIn(['meta', 'fetchingPanelVoiceMetadata']);
  };

  PanelVoiceStore.prototype.isFetchingPreferences = function() {
    return this.state.getIn(['meta', 'fetchingPanelVoicePreferences']);
  };

  PanelVoiceStore.prototype.isFetchingUserData = function() {
    return this.state.getIn(['meta', 'fetchingUserData']);
  };

  PanelVoiceStore.prototype.getSearchText = function() {
    return this.state.getIn(['meta', 'searchText']);
  };

  PanelVoiceStore.prototype.getStatusFilter = function() {
    return this.state.getIn(['meta', 'statusFilter']);
  };

  PanelVoiceStore.prototype.isFetchingVotingResults = function() {
    return this.state.getIn(['meta', 'fetchingVotingResults']);
  };

  PanelVoiceStore.prototype.isFetchingEtdSnapshots = function() {
    return this.state.getIn(['meta', 'fetchingEtdSnapshots']);
  };

  PanelVoiceStore.prototype.isFetchingEtdHighlightDocs = function() {
    return this.state.getIn(['meta', 'fetchingEtdHighlightDocs']);
  };

  PanelVoiceStore.prototype.getSendModalOptions = function() {
    return this.state.getIn(['meta', 'sendModalOptions'], Immutable.Map());
  };

  PanelVoiceStore.prototype.onOpenSendVotingModal = function(_arg) {
    var overarching, questionId, type;
    questionId = _arg.questionId, type = _arg.type, overarching = _arg.overarching;
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'sendModalOptions', 'questionId'], questionId);
      state.setIn(['meta', 'sendModalOptions', 'type'], type);
      state.setIn(['meta', 'sendModalOptions', 'opened'], true);
      state.setIn(['meta', 'sendModalOptions', 'sending'], false);
      return state.setIn(['meta', 'sendModalOptions', 'overarching'], overarching);
    }));
  };

  PanelVoiceStore.prototype.onCloseSendVotingModal = function() {
    return this.setState(this.state.withMutations(function(state) {
      state.deleteIn(['meta', 'sendModalOptions']);
      return state.setIn(['votingMessageAttachments'], Immutable.Map());
    }));
  };

  PanelVoiceStore.prototype.getEtdSnapshots = function(questionId) {
    return getQuestionEtdSnapshots(this.state, questionId);
  };

  PanelVoiceStore.prototype.getEtdsWithHighlights = function() {
    return this.state.get('etdsWithHighlights');
  };

  PanelVoiceStore.prototype.getEtdHighlights = function(questionId) {
    var etdId;
    etdId = getQuestionEtdId(this.state, questionId);
    return this.state.getIn(['etdsWithHighlights', etdId], Immutable.Map());
  };

  PanelVoiceStore.prototype.getVotingMessageAttachments = function() {
    return this.state.get('votingMessageAttachments');
  };

  PanelVoiceStore.prototype.getVotingMessageAttachmentsSize = function() {
    var attachments;
    attachments = this.state.get('votingMessageAttachments');
    return attachments.reduce(function(acc, att) {
      return acc + att.get('size');
    }, 0);
  };

  PanelVoiceStore.prototype.isMemberInPanelVoice = function(memberId) {
    var rounds;
    rounds = this.state.getIn(['panelVoiceMetadata', 'rounds'], Immutable.List());
    return rounds.some(function(round) {
      return round.get('members').contains(memberId);
    });
  };

  PanelVoiceStore.prototype.isSendingReminders = function() {
    return this.state.getIn(['meta', 'sendingReminders'], false);
  };

  PanelVoiceStore.prototype.getOpenedRemindersModal = function() {
    return this.state.getIn(['meta', 'openedRemindersModal']);
  };

  PanelVoiceStore.prototype.isFeedbackStatusDialogOpened = function() {
    return this.state.getIn(['meta', 'feedbackStatusDialogOpened']);
  };

  PanelVoiceStore.prototype.isAddManualResultsModalOpened = function() {
    return this.state.getIn(['meta', 'addManualResultsModalOpened']);
  };

  PanelVoiceStore.prototype.isVotingCompletelyClosed = function(questionId) {
    var isStageWithConclusionPart, lastStage, votingStages;
    votingStages = getQuestionVotingStages(this.state, questionId);
    lastStage = votingStages.last();
    if (!lastStage) {
      return false;
    }
    isStageWithConclusionPart = lastStage.get('parts').filter(function(part) {
      return part.get('name') === 'conclusion';
    }).size > 0;
    return isStageWithConclusionPart && lastStage.get('status') === 'closed';
  };

  PanelVoiceStore.prototype.getEtdHighlightsByRoundMap = function(etdId) {
    var rounds;
    rounds = this.state.getIn(['panelVoiceMetadata', 'rounds']);
    return rounds.reduce(function(acc, roundData, roundTimestamp) {
      var roundHighlightsForEtd;
      roundHighlightsForEtd = roundData.getIn(['highlightsMap', etdId], Immutable.Map({}));
      return acc.set(roundTimestamp, roundHighlightsForEtd);
    }, Immutable.Map({}));
  };

  PanelVoiceStore.prototype.getDenormalizedEtdVotingData = function(questionId) {
    return getDenormalizedEtdVotingData(this.state, questionId);
  };

  PanelVoiceStore.prototype.showVotingResendInfoDialog = function() {
    return this.state.getIn(['userPanelVoicePreferences', 'showVotingResendInfo'], true);
  };

  PanelVoiceStore.prototype.shouldShowOnboardingProposal = function() {
    var proposalShownCount;
    if (this.state.getIn(['meta', 'onboardNotificationShown'])) {
      return false;
    }
    if (this.isFetchingUserData()) {
      return false;
    }
    proposalShownCount = this.state.getIn(['userPanelVoicePreferences', 'onboardingProposalCount'], 0);
    return proposalShownCount < timesToShowOnboardingProposal;
  };

  return PanelVoiceStore;

})();

module.exports = alt.createStore(immutable(PanelVoiceStore), 'PanelVoiceStore');
