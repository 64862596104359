CadthGuidelineModal = require './cadth_guideline_modal'
CadthGuidelinesActions = require 'actions/cadth_guidelines_actions'
CadthGuidelinesStore = require 'stores/cadth_guidelines_store'
ConnectStore = require 'components/enhancers/connect_store'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
Spinner = require 'components/common/spinner'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

SourceColumn = ({ guideline }) ->
  if guideline.get('sourceLink') and guideline.get('sourceText')
    <a
      className="sourceLink"
      href={guideline.get 'sourceLink'}
      rel='noreferrer noopener'
      target='_blank'
    >
      {guideline.get 'sourceText'}
    </a>
  else
    guideline.get('sourceText') or ''

SourceColumn.propTypes =
  guideline: PropTypes.instanceOf(Immutable.Map).isRequired

GUIDELINES_COMPOUND_COLUMNS =
  source:
    field: 'sourceText'
    displayComponent: SourceColumn

GUIDELINES_COLUMNS = [
  'title'
  'publicationYear'
  'guidelineGroup'
  'countryOrRegion'
  'qualityOfGuideline'
  'source'
]

storeConnector =
  CadthGuidelinesStore: (Store) ->
    isFetching: Store.isFetching()
    fetchingError: Store.getFetchingError()
    guidelines: Store.getGuidelines()
    sortBy: Store.getSortBy()

CadthGuidelines = ({ isFetching, fetchingError, guidelines, sortBy }) ->
  i18n = useI18n('key_messages:guidelines')

  onSort = (column) -> ->
    field = if column of GUIDELINES_COMPOUND_COLUMNS
      GUIDELINES_COMPOUND_COLUMNS[column].field
    else
      column
    CadthGuidelinesActions.setSortBy field

  onEdit = (guideline) -> ->
    CadthGuidelinesActions.openDialog guideline.toJS()

  onDelete = useCoffeeCallback [i18n], (guideline) -> ->
    title = guideline.get 'title'
    mediator.dialogs.confirm i18n('delete_confirmation_text', { title }), (confirmed) ->
      return unless confirmed
      CadthGuidelinesActions.delete guideline.get '_id'

  getColumnClass = useCoffeeCallback [sortBy], (column) ->
    field = if column of GUIDELINES_COMPOUND_COLUMNS
      GUIDELINES_COMPOUND_COLUMNS[column].field
    else
      column
    return column unless sortBy.get('field') is field
    "#{column} sorted #{sortBy.get 'type'}"

  if isFetching
    <Spinner />
  else if fetchingError
    <div className="cadth-guidelines">
      <p className="cadth-guidelines__fetch-error">
        {i18n 'fetch_error'}
      </p>
    </div>
  else
    <div className="cadth-guidelines">
      <table className="cadth-guidelines__table">
        <thead>
          <tr>
            {_.map GUIDELINES_COLUMNS, (column) ->
              <th className={getColumnClass(column)} onClick={onSort(column)} key={column}>
                {i18n "columns.#{column}"}
              </th>
            }
            <th className="actions">
              {i18n 'columns.actions'}
            </th>
          </tr>
        </thead>
        <tbody>
          {if guidelines.isEmpty()
            <tr className="table__no-guidelines-row">
              <td colSpan={GUIDELINES_COLUMNS.length + 1}>
                {i18n 'no_guidelines'}
              </td>
            </tr>
          else
            guidelines.valueSeq().map (guideline) ->
              <tr key={guideline.get '_id'}>
                {_.map GUIDELINES_COLUMNS, (column) ->
                  <td className={column} key={column}>
                    {if column of GUIDELINES_COMPOUND_COLUMNS
                      { displayComponent: Component } = GUIDELINES_COMPOUND_COLUMNS[column]
                      <Component guideline={guideline} />
                    else
                      guideline.get(column) or ''
                    }
                  </td>
                }
                <td className="actions">
                  <div>
                    <IconButton iconName="edit" onClick={onEdit guideline} />
                    <IconButton iconName="delete" onClick={onDelete guideline} />
                  </div>
                </td>
              </tr>
          }
        </tbody>
      </table>
      <CadthGuidelineModal />
    </div>

CadthGuidelines.propTypes =
  isFetching: PropTypes.bool.isRequired
  fetchingError: PropTypes.object
  guidelines: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
  sortBy: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore CadthGuidelines, CadthGuidelinesStore, storeConnector
