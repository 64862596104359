MultiSelect = require 'components/common/multi_select'
QuestionsStore = require 'stores/questions_store'
ConnectStore = require 'components/enhancers/connect_store'


storeConnector =
  QuestionsStore: (Store, props) ->
    question: Store.getQuestion(props.questionId)

QuestionOutcomesSelect = createReactClass
  displayName: 'QuestionOutcomesSelect'

  propTypes:
    questionId: PropTypes.string.isRequired
    question: PropTypes.instanceOf(Immutable.Map).isRequired
    onChange: PropTypes.func.isRequired
    selected: PropTypes.arrayOf(PropTypes.string).isRequired

  getOutcomesSelectOptions: ->
    @props.question.get('outcomes', Immutable.List())
    .reduce (outcomeOptions, o) ->
      outcomeOptions.concat
        value: o.get '_id'
        label: o.get 'name'
    , []

  handleOutcomeSelect: (selectedOutcomes) ->
    @props.onChange @props.question.get('outcomes').filter (outcomes) ->
      outcomes.get('_id') in selectedOutcomes

  render: ->
    return null unless @props.question?

    <MultiSelect
      onChange={@handleOutcomeSelect}
      options={@getOutcomesSelectOptions()}
      selectedOptions={@props.selected}
    />

module.exports = ConnectStore QuestionOutcomesSelect, QuestionsStore, storeConnector
