var JsonLdExportMultiComparisonService, MultiComparisonExporter;

MultiComparisonExporter = require('./mc_exporter');

module.exports = JsonLdExportMultiComparisonService = (function() {
  function JsonLdExportMultiComparisonService() {}

  JsonLdExportMultiComparisonService.prototype["export"] = function(mcDoc) {
    var exporter;
    exporter = new MultiComparisonExporter(mcDoc, this.pouchAdapter);
    return exporter["export"]();
  };

  return JsonLdExportMultiComparisonService;

})();
