var Migration, TeamMembersToProjectMembers, W, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

module.exports = TeamMembersToProjectMembers = (function(_super) {
  __extends(TeamMembersToProjectMembers, _super);

  function TeamMembersToProjectMembers() {
    TeamMembersToProjectMembers.__super__.constructor.call(this, false);
  }

  TeamMembersToProjectMembers.prototype.up = function(project, colls) {
    var adapter, db;
    TeamMembersToProjectMembers.__super__.up.apply(this, arguments);
    db = PouchDB(project.id, window.gdt.POUCHDB_OPTIONS);
    adapter = mediator.services.storePersistenceAdapter;
    return db.allDocs({
      include_docs: true
    }).then(function(docs) {
      var projectMembers, teamMembers;
      teamMembers = _(docs.rows).chain().pluck('doc').filter(function(doc) {
        return doc.docType === 'teamMember';
      }).value();
      projectMembers = _(teamMembers).map(function(member) {
        member.docType = 'projectMember';
        member.panelMember = true;
        if (member.accessRights == null) {
          member.accessRights = ['no_access'];
        }
        member.rev_author = 'team_to_project_members_migration';
        return member;
      });
      return adapter.bulkDocs(project.id, projectMembers);
    }).then(function() {
      return mediator.colls.teamMembers.fetch();
    });
  };

  return TeamMembersToProjectMembers;

})(Migration);
