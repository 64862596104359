var CollectionView, Trait, View, ViewTrait,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Trait = require('base/lib/traits/trait');

View = require('base/views/view');

CollectionView = require('base/views/collection_view');

module.exports = ViewTrait = (function(_super) {
  __extends(ViewTrait, _super);

  function ViewTrait() {
    return ViewTrait.__super__.constructor.apply(this, arguments);
  }

  ViewTrait.prototype.apply = function(view) {
    if (!((view instanceof View) || (view instanceof CollectionView))) {
      throw new Error('ViewTrait can only be applied to View or CollectionView');
    }
    return ViewTrait.__super__.apply.apply(this, arguments);
  };

  return ViewTrait;

})(Trait);
