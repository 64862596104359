ConnectStore = require 'components/enhancers/connect_store'
IconButton = require 'components/common/icon_button'
Modal = require 'components/common/modal'
NMAImportModalActions = require 'actions/nma_import_modal_actions'
NMAImportModalStore = require 'stores/nma_import_modal_store'
NMAMappingStep = require 'components/nma/nma_mapping_step'
NMAQuestionStore = require 'stores/nma_question_store'
{ StepsContainer, Step } = require 'components/common/steps'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'
{ useRef } = React

storeConnector =
  NMAImportModalStore: (Store) ->
    currentStep: Store.getImportCurrentStep()
    fileData: Store.getImportFileData()
    isImporting: Store.isImporting()
    isOpen: Store.isImportModalOpen()
    isParsing: Store.isParsing()
    mappingStepData: Store.getImportMappingStepData()
    outcome: Store.getImportOutcome()
  NMAQuestionStore: (Store) ->
    question: Store.getCurrentQuestion()

ImportUploadStep = ({ fileData }) ->
  i18n = useI18n 'nma:import_modal'
  fileInputRef = useRef null
  fileInputFormRef = useRef null

  onFileClicked = useCoffeeCallback [fileInputRef.current], ->
    fileInputRef.current?.click()

  onResetSource = useCoffeeCallback [fileInputFormRef.current], ->
    fileInputFormRef.current?.reset()
    NMAImportModalActions.resetImportFile()

  onFileChanged = useCoffeeCallback [], (event) ->
    file = event.target.files[0]
    return unless file
    NMAImportModalActions.setImportFile file

  isFileChosen = Boolean fileData.get 'file'
  buttonTitle = fileData.get('fileName') or ''
  buttonText = if isFileChosen then buttonTitle else i18n 'choose_csv_file'

  <React.Fragment>
    <div className="nma-import-outcome-modal__step-description">
      {i18n 'step_descriptions.upload'}
    </div>
    <div className="nma-import-outcome-modal__upload-step">
      <form ref={fileInputFormRef}>
        <input
          type="file"
          ref={fileInputRef}
          className="import-source-input-file"
          accept=".csv"
          onChange={onFileChanged}
        />
      </form>
      <Tooltip>
        <button
          className="btn btn-apply import-source-button"
          onClick={onFileClicked}
          title={buttonTitle}
        >
          {buttonText}
        </button>
      </Tooltip>
      {isFileChosen and <IconButton
        title={i18n '/actions.remove'}
        iconName="import-source-cross decline-icon"
        onClick={onResetSource}
      />}
    </div>
  </React.Fragment>

ImportUploadStep.propTypes =
  fileData: PropTypes.instanceOf(Immutable.Map).isRequired

NMAImportModal = ({
  currentStep
  fileData
  isImporting
  isOpen
  isParsing
  mappingStepData
  outcome
  question
}) ->
  i18n = useI18n 'nma:import_modal'

  isNextStepEnabled = useCoffeeMemo [currentStep, fileData, mappingStepData], ->
    switch currentStep
      when 'upload'
        Boolean fileData.get 'file'
      when 'mapping'
        allInterventions = mappingStepData.get('matchedInterventions', Immutable.List()).concat(
          mappingStepData.get('unmatchedInterventions', Immutable.List())
        )
        mappingStepData.get('mapping', Immutable.Map()).size is allInterventions.size

  onNext = useCoffeeCallback [fileData, outcome, question], ->
    NMAImportModalActions.parseImportFile fileData.toJS(), outcome, question

  onImport = useCoffeeCallback [fileData, mappingStepData, outcome, question], ->
    NMAImportModalActions.import mappingStepData, question, outcome, fileData.get 'fileName'

  <Modal
    className="nma-import-outcome-modal"
    closeButton={not isImporting and not isParsing}
    isOpen={isOpen}
    modalSize="large"
    onClose={NMAImportModalActions.closeImportDialog}
    title={i18n 'title'}
  >
    <StepsContainer
      applying={isImporting or isParsing}
      currentStep={currentStep}
      isCancelEnabled={not isImporting and not isParsing}
      isNextStepEnabled={isNextStepEnabled}
    >
      <Step
        backLabel={i18n '/actions.cancel'}
        nextLabel={i18n '/actions.next'}
        stepKey="upload"
        stepLabel={i18n 'steps.upload'}
        onBack={NMAImportModalActions.closeImportDialog}
        onNext={onNext}
      >
        <ImportUploadStep fileData={fileData} />
      </Step>
      <Step
        backLabel={i18n '/actions.back'}
        nextLabel={i18n '/actions.apply'}
        stepKey="mapping"
        stepLabel={i18n 'steps.mapping'}
        onBack={NMAImportModalActions.goBackOnImportDialog}
        onNext={onImport}
      >
        <NMAMappingStep />
      </Step>
    </StepsContainer>
  </Modal>

NMAImportModal.propTypes =
  currentStep: PropTypes.oneOf(['upload', 'mapping']).isRequired
  fileData: PropTypes.instanceOf(Immutable.Map).isRequired
  isImporting: PropTypes.bool.isRequired
  isOpen: PropTypes.bool.isRequired
  isParsing: PropTypes.bool.isRequired
  mappingStepData: PropTypes.instanceOf(Immutable.Map).isRequired
  outcome: PropTypes.instanceOf(Immutable.Map).isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = ConnectStore NMAImportModal,
  [NMAImportModalStore, NMAQuestionStore],
  storeConnector
