var AbstractQuestion, FootnotesBinding, Outcomes, PouchMemento, PrognosticQuestion, ReferencesBinding, mediator, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

AbstractQuestion = require('models/base/abstract_question');

Outcomes = require('models/outcomes');

FootnotesBinding = require('lib/traits/footnotes_binding');

ReferencesBinding = require('lib/traits/references_binding');

PouchMemento = require('lib/traits/pouch_memento');

utils = require('base/lib/utils');

mediator = require('mediator');

module.exports = PrognosticQuestion = (function(_super) {
  __extends(PrognosticQuestion, _super);

  function PrognosticQuestion() {
    this.getManagementPopulations = __bind(this.getManagementPopulations, this);
    return PrognosticQuestion.__super__.constructor.apply(this, arguments);
  }

  PrognosticQuestion.TITLE_ATTRIBUTES = ['healthCondition', 'population', 'overTime', 'populationOther'];

  PrognosticQuestion.prototype.defaults = {
    $tableMode: 'regular',
    type: 'prognostic',
    healthCondition: utils.bracketize($.t('prognosis:question.defaults.health_condition')),
    population: '',
    populationOther: '',
    overTime: utils.bracketize($.t('prognosis:question.defaults.time'))
  };

  PrognosticQuestion.prototype.initialize = function() {
    this.setAdditionalDefaults();
    PrognosticQuestion.__super__.initialize.apply(this, arguments);
    this.enable(FootnotesBinding);
    this.enable(ReferencesBinding);
    this.enable(PouchMemento);
    this.on((PrognosticQuestion.TITLE_ATTRIBUTES.map(function(prop) {
      return "change:" + prop;
    })).join(' '), function() {
      return this.set(this.calculateTitles());
    });
    this.set(this.calculateTitles());
    return this._dirty = false;
  };

  PrognosticQuestion.prototype.setAdditionalDefaults = function() {
    if (!this.get('outcomes')) {
      return this.set('outcomes', new Outcomes());
    }
  };

  PrognosticQuestion.prototype.calculateTitles = function(attributes) {
    var params, suffix;
    if (attributes == null) {
      attributes = this.attributes;
    }
    params = _(attributes).chain().pick(PrognosticQuestion.TITLE_ATTRIBUTES).clone().value();
    params.population = this.get('population') === 'other' ? this.get('populationOther') : this.get('population');
    suffix = _.isEmpty(this.get('population')) && _.isEmpty(this.get('overTime')) ? '_no_pop_no_time' : _.isEmpty(this.get('population')) ? '_no_pop' : _.isEmpty(this.get('overTime')) ? '_no_time' : '';
    return {
      question: $.t("prognosis:question.templates.general" + suffix, params),
      tableTitle: $.t("prognosis:question.templates.table_title" + suffix, params),
      questionShort: $.t("prognosis:question.templates.table_title" + suffix, params)
    };
  };

  PrognosticQuestion.prototype.getManagementPopulations = function() {
    var options;
    options = mediator.questions.chain().map(function(q) {
      if (!q.isDiagnostic() && q.get('questionFormat') === 'IN_POP') {
        return {
          label: q.get('healthProblemOrPopulation'),
          value: q.get('healthProblemOrPopulation')
        };
      }
    }).compact().uniq().value();
    options.unshift({
      label: '',
      value: ''
    });
    options.push({
      label: $.t('prognosis:question.other'),
      value: 'other'
    });
    return options;
  };

  PrognosticQuestion.prototype.duplicate = function() {
    var clonnedAttrs;
    clonnedAttrs = this.parse(_(this.toJSON()).omit('_id', '_rev', 'orderNumber'));
    return new PrognosticQuestion(clonnedAttrs, {
      wait: true
    });
  };

  return PrognosticQuestion;

})(AbstractQuestion);
