Popover = require 'components/common/popover'
QuestionsStatusesActions = require 'actions/questions_statuses_actions'
QuestionStatusForm = require 'components/evidence_syntheses/question/question_status_form'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'
{ INITIAL_QUESTION_STATUSES } = require 'lib/questions_helper'
{ MDG_WORKSPACE, MDG_INITIAL_QUESTION_STATUSES } = require 'lib/mdg_helper'

{ instanceOf, string } = PropTypes

STATUSES_LIST = ['inProgress', 'inReview', 'done']

STATUSES = [
  {
    groupKey: 'evidenceTableStatus',
    label: 'E'
  },
  {
    groupKey: 'recommendationStatus',
    label: 'R'
  },
  {
    groupKey: 'presentationStatus',
    label: 'P'
  }
]
MDG_STATUSES = [
  {
    groupKey: 'mdgResearchTeam',
    label: 'RT'
    accessRights: ['researcher', 'mdg_editor_in_chief']
  },
  {
    groupKey: 'mdgReedGroup',
    label: 'A'
    accessRights: ['admin']
  }
]

getInitialStatuses = ->
  if mediator.activeWorkspace is MDG_WORKSPACE
    Immutable.fromJS(
      completed: false,
      locked: false,
      mdgResearchTeam: {
        done: false
        evidenceTable: {}
        evidenceScores: {}
        evidenceSummaries: {}
        searchStrategy: {}
        recommendation: {}
        },
      mdgReedGroup: {
        done: false
        evidenceSummaries: {}
        searchStrategies: {}
        recommendations: {}
        citations: {}
      }
    )
  else
    Immutable.fromJS(
      completed: false,
      locked: false,
      evidenceTableStatus: {},
      recommendationStatus: {},
      presentationStatus: {}
    )


statusEqual = (value) -> (status) -> status.get('status', '') is value

StatusRec = createReactClass

  displayName: 'StatusRec'

  propTypes:
    label: PropTypes.string.isRequired
    onClick: PropTypes.func.isRequired
    className: PropTypes.string

  getDefaultProps: ->
    className: ''

  render: ->
    { onClick, className, label } = @props
    <div className={className} onClick={onClick}>{label}</div>

QuestionStatus = createReactClass

  displayName: 'QuestionStatus'
  mixins: [Translation('es:question.statuses')]

  propTypes:
    questionId: string.isRequired
    questionStatus: instanceOf(Immutable.Map)

  getInitialState: ->
    evidenceTableStatusFormVisible: false
    recommendationStatusFormVisible: false
    presentationStatusFormVisible: false
    mdgResearchTeamFormVisible: false
    mdgReedGroupFormVisible: false

  getDefaultProps: ->
    questionStatus: getInitialStatuses()

  toggleLocked: (e) ->
    QuestionsStatusesActions.toggleQuestionLocked @props.questionId, e.target.checked
    @toggleForm(key)()

  toggleCompleted: (e) ->
    QuestionsStatusesActions.toggleQuestionCompleted @props.questionId, e.target.checked
    @toggleForm(key)()

  updateStatus: (pathOrKey, value) -> =>
    QuestionsStatusesActions.updateQuestionStatus @props.questionId, pathOrKey, value
    @toggleForm(pathOrKey)() unless _.isArray(pathOrKey)

  toggleForm: (groupKey) -> =>
    @setState "#{groupKey}FormVisible": not @state["#{groupKey}FormVisible"]

  getStatusStr: (currentStatus) ->
    statusesMaps = currentStatus.filter (status) -> Immutable.Map.isMap(status)
    globalStatus = currentStatus.get('status', null)
    if globalStatus and _.isString(globalStatus)
      globalStatus
    else if statusesMaps.isEmpty() then 'new'
    else if statusesMaps.every(statusEqual('done'))
      'done'
    else if statusesMaps.every(statusEqual('inReview'))
      'inReview'
    else if statusesMaps.every(statusEqual('inProgress'))
      'inProgress'
    else if statusesMaps.some(statusEqual('new'))
      'new'
    else if statusesMaps.some(statusEqual('inProgress'))
      'inProgress'
    else if statusesMaps.some(statusEqual('inReview'))
      'inReview'
    else if statusesMaps.some(statusEqual('done'))
      'done'
    else
      'new'

  render: ->
    { questionStatus } = @props
    isMdgTablesFeatureOn = mediator.activeWorkspace is MDG_WORKSPACE
    statuses = if isMdgTablesFeatureOn then MDG_STATUSES else STATUSES

    <div className='question-progress'>
      <div className="question-progress__rects">
        {statuses.map(({ groupKey, label, accessRights }) =>

          currentStatus = questionStatus.getIn([groupKey], Immutable.Map())
          classes = classNames "question-progress__rects__rect",
            "question-progress__rects__rect--#{_.str.dasherize(@getStatusStr(currentStatus))}"
            "question-progress__rects__rect--completed": questionStatus.get('completed')

          <Popover
            key={groupKey}
            onRequestClose={@toggleForm(groupKey)}
            visible={@state["#{groupKey}FormVisible"]}
          >
            <StatusRec
              className={classes}
              label={label}
              onClick={@toggleForm(groupKey)}
            />
            <QuestionStatusForm
              completed={questionStatus.get('completed') or false}
              currentStatus={currentStatus}
              i18n={@i18n}
              locked={questionStatus.get('locked') or false}
              statusesList={getInitialStatuses().get(groupKey)}
              groupKey={groupKey}
              toggleCompleted={@toggleCompleted}
              toggleLocked={@toggleLocked}
              updateStatus={@updateStatus}
              accessRights={accessRights}
            />
          </Popover>
        )}
      </div>
    </div>

module.exports = QuestionStatus
