var Migration, MultiComparisonsModule, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = MultiComparisonsModule = (function(_super) {
  __extends(MultiComparisonsModule, _super);

  function MultiComparisonsModule() {
    MultiComparisonsModule.__super__.constructor.call(this, false);
  }

  MultiComparisonsModule.prototype.up = function(project, colls) {
    var module, moduleIndex;
    MultiComparisonsModule.__super__.up.apply(this, arguments);
    if (_(project.get('modules')).findWhere({
      id: 'multi_comparisons'
    }) || project.get('modules') === '*') {
      return W.resolve();
    }
    moduleIndex = project.get('modules').findIndex(function(m) {
      return m.id === 'evidence_syntheses';
    }) + 1;
    module = {
      id: 'multi_comparisons',
      featureSwitch: 'multiComparisons',
      optional: true
    };
    project.get('modules').splice(moduleIndex, 0, module);
    return project.save();
  };

  return MultiComparisonsModule;

})(Migration);
