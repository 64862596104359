VotingTabs = require 'components/etd/voting_tabs'
EtdSectionTitle = require 'components/etd/etd_section_title'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
mediator = require 'mediator'

EtdPart = createReactClass
  diplayName: 'EtdPartComponent'

  propTypes:
    titleKey: PropTypes.string
    renderMode: PropTypes.string.isRequired

  getDefaultProps: ->
    titleKey: null

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  _tabsVisible: ->
    @props.activeTab and @props.renderMode isnt 'printout' and
      mediator.services.switches.isOn 'voting'

  render: ->
    <div className={@props.className}>
      {@props.titleKey and <EtdSectionTitle
          renderMode={@props.renderMode}
          title={@i18n @props.titleKey if @props.titleKey}
          className={classNames 'with-tabs': @_tabsVisible()}
        >
          {if @_tabsVisible()
            <VotingTabs
              activeTab={@props.activeTab}
              onTabClick={@props.onTabClick}
            />
          }
        </EtdSectionTitle>
      }
      {@props.children}
    </div>

module.exports = EtdPart
