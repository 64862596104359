CollapsibleWithControls = require 'components/common/collapsible_with_controls'
EllipsizedText = require 'components/common/ellipsized_text'
mediator = require 'mediator'
OverarchingQuestionActions = require 'actions/overarching_questions_actions'
Router = require 'router'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'

ComparisonOverarchingQuestion = createReactClass
  displayName: 'ComparisonOverarchingQuestion'

  propTypes:
    question: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.Map),
      PropTypes.instanceOf(Immutable.OrderedMap),
    ]).isRequired

  mixins: [Translation('')]

  onEdit: ->
    OverarchingQuestionActions.goToOverarchingCreator(@props.question.get('_id'))

  onDelete: ->
    dialog =
      message: @i18n 'overarching_questions:question_delete',
        question: @props.question.get 'question'
      declineText: @i18n 'actions.cancel'
      confirmText: @i18n 'actions.delete'
    mediator.dialogs.confirm dialog, (confirmed) =>
      return unless confirmed
      OverarchingQuestionActions.delete mediator.project.id, @props.question.get '_id'

  onDuplicate: ->
    OverarchingQuestionActions.duplicate @props.question.get '_id'

  getQuestionControls: ->
    [
      name: 'edit'
      handler: @onEdit
    ,
      name: 'duplicate'
      handler: @onDuplicate
    ,
      name: 'delete'
      handler: @onDelete
    ]

  goToDetails: ->
    questionId = @props.question.get('_id')
    route = Router::getProjectRelativeUrl("/overarching-questions/#{questionId}/details")
    mediator.publish '!router:route', route

  renderMetaField: (question) -> (field) =>
    i18nKey = "es:question.#{if field is 'author' then 'authors' else _.str.underscored field}"
    <div className={"details__item #{field}"} key={field}>
      <div className="item__label">
        <label htmlFor={field}>
          {field is 'author' and <Tooltip
            tooltipType='info'
            tipJoint='left bottom'
            targetJoint='left bottom'
          >
            <span
              className='info-sign'
              title={@i18n 'es:question.authors_info'}
            />
          </Tooltip>}
          {@i18n i18nKey}
          {':'}
        </label>
      </div>
      <div className="item__value">
        {if field isnt 'last-update'
          question.get field, ''
        else
          moment(question.get '$timestamp')?.format @i18n 'time_format'
        }
      </div>
    </div>

  render: ->
    { question } = @props
    <div className="comparison-overarching-question">
      <CollapsibleWithControls controls={@getQuestionControls()}>
        <div className="question-text" onClick={@goToDetails}>
          <EllipsizedText height={40} text={question.get 'question'} />
        </div>
        <div className="comparison-overarching-question__details">
          {['setting', 'author', 'last-update'].map(@renderMetaField(question))}
        </div>
      </CollapsibleWithControls>
    </div>

module.exports = ComparisonOverarchingQuestion
