PanelVoiceOverarchingQuestion =
  require 'components/overarching_questions/questions_list/panel_voice_overarching_question'
PanelVoiceQuestion = require 'components/panel_voice/question'
Scrollable = require 'components/common/scrollable'
QuestionsList = require 'components/evidence_syntheses/questions_list/questions_list'
{ instanceOf, oneOfType } = PropTypes

QuestionsTableBody = createReactClass
  displayName: 'PanelVoiceQuestionsTableBody'

  propTypes:
    overarchingQuestions: instanceOf(Immutable.OrderedMap).isRequired
    questions: instanceOf(Immutable.OrderedMap).isRequired
    questionGroups: instanceOf(Immutable.Map).isRequired

  render: ->
    { overarchingQuestions, questions, questionGroups } = @props
    containerClass = classNames 'panel-voice-questions__body',
      'with-groups-offset': not questionGroups.get('groups').isEmpty()

    <div className={containerClass}>
      <Scrollable>
        <QuestionsList
          displayOverarchingQuestions
          overarchingQuestions={overarchingQuestions}
          overarchingQuestionComponent={PanelVoiceOverarchingQuestion}
          questions={questions}
          questionGroups={questionGroups}
          withEmptyQuestionGroups={false}
        >
          <PanelVoiceQuestion />
        </QuestionsList>
      </Scrollable>
    </div>

module.exports = QuestionsTableBody
