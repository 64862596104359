var AbstractQuestion, FootnotesList, FootnotesService, Model, Outcomes, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Model = require('models/base/model');

Outcomes = require('models/outcomes');

FootnotesList = require('lib/traits/footnotes_list');

FootnotesService = require('lib/services/footnotes_service');

mediator = require('mediator');

module.exports = AbstractQuestion = (function(_super) {
  __extends(AbstractQuestion, _super);

  function AbstractQuestion() {
    return AbstractQuestion.__super__.constructor.apply(this, arguments);
  }

  AbstractQuestion.prototype.initialize = function() {
    AbstractQuestion.__super__.initialize.apply(this, arguments);
    if (this.constructor === AbstractQuestion) {
      throw new Error("Can't instantiate abstract class!");
    }
    return this.enable(FootnotesList);
  };

  AbstractQuestion.prototype.parse = function(response) {
    response = AbstractQuestion.__super__.parse.apply(this, arguments);
    if (response.questionFormat) {
      _(response).extend(this.calculateTitles(response));
    }
    return response;
  };

  AbstractQuestion.prototype.nestedCollections = {
    'outcomes': Outcomes
  };

  AbstractQuestion.prototype.areAllOutcomesNarrative = function() {
    var narrOutcomes, o;
    narrOutcomes = (function() {
      var _i, _len, _ref, _results;
      _ref = this.get('outcomes').models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        o = _ref[_i];
        if (o.get('type') === 'narrative') {
          _results.push(o);
        }
      }
      return _results;
    }).call(this);
    return narrOutcomes.length === this.get('outcomes').models.length;
  };

  AbstractQuestion.prototype.isDiagnostic = function() {
    return this.get('type') === 'diagnostic';
  };

  AbstractQuestion.prototype.isPrognostic = function() {
    return this.get('type') === 'prognostic';
  };

  AbstractQuestion.prototype.isManagement = function() {
    var type, _ref;
    type = (_ref = this.get('type')) != null ? _ref : 'management';
    return type === 'management' || type === '';
  };

  AbstractQuestion.prototype._getQuestionItemsIds = function(itemsName, questionFootnotesAttrArray) {
    var attr, ids, _i, _len;
    if (questionFootnotesAttrArray) {
      ids = [];
      for (_i = 0, _len = questionFootnotesAttrArray.length; _i < _len; _i++) {
        attr = questionFootnotesAttrArray[_i];
        ids.push(this.get(itemsName)[attr]);
      }
      return _(ids).chain().flatten().uniq().value();
    } else {
      return _(this.get(itemsName)).chain().values().flatten().uniq().value();
    }
  };

  AbstractQuestion.prototype._getFilteredOutcomeItems = function(itemsName, outcomeIdsArray) {
    var outcome, outcomesFootnotes;
    return outcomesFootnotes = _((function() {
      var _i, _len, _ref, _ref1, _results;
      _ref = this.get('outcomes').models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        outcome = _ref[_i];
        if (_ref1 = outcome.id, __indexOf.call(outcomeIdsArray, _ref1) >= 0) {
          _results.push(outcome["get" + (_.string.capitalize(itemsName)) + "Ids"]());
        }
      }
      return _results;
    }).call(this)).chain().flatten().uniq().value();
  };

  AbstractQuestion.prototype.getFootnotesObjectsArray = function(outcomeIdsArray, questionFootnotesAttrArray) {
    var footnoteIds, footnotesList;
    if (questionFootnotesAttrArray == null) {
      questionFootnotesAttrArray = null;
    }
    if (!_.isEmpty(outcomeIdsArray)) {
      footnoteIds = _.union(this._getQuestionItemsIds('footnotes', questionFootnotesAttrArray), this._getFilteredOutcomeItems('footnotes', outcomeIdsArray));
      footnotesList = this.get('footnotesList').getNotesList(footnoteIds);
      return _.map(footnotesList, function(f, idx) {
        f.idx = idx;
        return f;
      });
    } else {
      return this.get('footnotesList').getNotesList();
    }
  };

  AbstractQuestion.prototype.getReferencesObjectsArray = function(outcomeIdsArray, questionFootnotesAttrArray) {
    var outcomesReferences, questionReferences, referencesOrder, _ref;
    if (questionFootnotesAttrArray == null) {
      questionFootnotesAttrArray = null;
    }
    referencesOrder = (_ref = this.get('referencesOrder')) != null ? _ref : [];
    questionReferences = this._getQuestionItemsIds('references', questionFootnotesAttrArray);
    referencesOrder = _.isEmpty(outcomeIdsArray) ? referencesOrder : (outcomesReferences = this._getFilteredOutcomeItems('references', outcomeIdsArray), _(referencesOrder).filter(function(referenceId) {
      return __indexOf.call(outcomesReferences.concat(questionReferences), referenceId) >= 0;
    }));
    return mediator.colls.references.filter(function(r) {
      var _ref1;
      return _ref1 = r.get('_id'), __indexOf.call(referencesOrder, _ref1) >= 0;
    }).sort(function(a, b) {
      return referencesOrder.indexOf(a.get('_id')) - referencesOrder.indexOf(b.get('_id'));
    }).map(function(r, idx) {
      return {
        id: r.get('_id'),
        text: r.vancouverFormat(),
        counter: idx + 1,
        idx: idx
      };
    });
  };

  AbstractQuestion.prototype.dispose = function() {
    this.footnotesService = null;
    return AbstractQuestion.__super__.dispose.apply(this, arguments);
  };

  return AbstractQuestion;

})(Model);
