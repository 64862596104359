OptionsList = createReactClass
  displayName: 'OptionsList'

  render: ->
    <ul className="options-list">
      {@props.options.map (option, key) =>
        <li key={key} onClick={option.actionCbk}>
          {option.text}
        </li>
      }
    </ul>
module.exports = OptionsList
