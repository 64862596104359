var DocumentSections, DocumentSectionsActions, DocumentSectionsToolbar, DocumentSectionsView, ReactComponent, ReactToolbarView, View, alt, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DocumentSectionsToolbar = require('components/document_sections/document_sections_toolbar');

DocumentSections = require('components/document_sections/document_sections');

DocumentSectionsActions = require('actions/document_sections_actions');

ReactToolbarView = require('views/react_toolbar_view');

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

mediator = require('mediator');

alt = require('alt');

module.exports = DocumentSectionsView = (function(_super) {
  __extends(DocumentSectionsView, _super);

  function DocumentSectionsView() {
    this.saveEditorContent = __bind(this.saveEditorContent, this);
    return DocumentSectionsView.__super__.constructor.apply(this, arguments);
  }

  DocumentSectionsView.prototype.container = '#page-container';

  DocumentSectionsView.prototype.id = 'document-sections-container';

  DocumentSectionsView.prototype.autoRender = true;

  DocumentSectionsView.prototype.initialize = function() {
    DocumentSectionsView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: DocumentSectionsToolbar,
      props: {
        onSave: this.saveEditorContent
      }
    }));
    return this.enable(ReactComponent);
  };

  DocumentSectionsView.prototype.saveEditorContent = function() {
    return this.documentSections.saveContent();
  };

  DocumentSectionsView.prototype.afterRender = function() {
    var projectId;
    DocumentSectionsView.__super__.afterRender.apply(this, arguments);
    projectId = mediator.project.id;
    return this.renderComponent(DocumentSections, DocumentSectionsActions, null, {
      props: {
        projectId: projectId,
        ref: (function(_this) {
          return function(el) {
            return _this.documentSections = el;
          };
        })(this)
      },
      fetchAndListen: {
        dbId: projectId
      }
    });
  };

  DocumentSectionsView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('DocumentSectionsStore');
    return DocumentSectionsView.__super__.dispose.apply(this, arguments);
  };

  return DocumentSectionsView;

})(View);
