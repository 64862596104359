LimitCounter = require 'components/common/limit_counter'


MembersLimitsInfo = ({ i18n, limits, membersCount, adminsCount }) ->

  membersLimitKey = i18n 'member_count', count: membersCount
  adminsLimitKey = i18n 'admin_count', count: adminsCount

  <div className="members-limits">
    <div className="members-limits__container">
      <div dangerouslySetInnerHTML={
        __html: i18n('current_members_placeholder', key: membersLimitKey )}
      />
      <LimitCounter limit={limits.get('teamMembers')} count={membersCount}/>
    </div>
    <div className="members-limits__container">
      <div dangerouslySetInnerHTML={
        __html: i18n('current_members_placeholder', key: adminsLimitKey )}
      />
      <LimitCounter limit={limits.get('teamAdmins')} count={adminsCount} />
    </div>
  </div>

MembersLimitsInfo.propTypes =
  i18n: PropTypes.func.isRequired
  limits: PropTypes.instanceOf(Immutable.Map).isRequired
  membersCount: PropTypes.number.isRequired,
  adminsCount: PropTypes.number.isRequired

module.exports = MembersLimitsInfo
