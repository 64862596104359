var DxQuestionToPooledSourceOfData, Migration, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = DxQuestionToPooledSourceOfData = (function(_super) {
  __extends(DxQuestionToPooledSourceOfData, _super);

  function DxQuestionToPooledSourceOfData() {
    DxQuestionToPooledSourceOfData.__super__.constructor.call(this, false);
  }

  DxQuestionToPooledSourceOfData.prototype.up = function(project, colls) {
    var question;
    DxQuestionToPooledSourceOfData.__super__.up.apply(this, arguments);
    return W.all((function() {
      var _i, _len, _ref, _results;
      _ref = colls.questions.models;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        question = _ref[_i];
        if (question.isDiagnostic()) {
          if (!question.get('sourceOfDxData')) {
            _results.push(question.save('sourceOfDxData', 'pooledAcrossStudies'));
          } else {
            _results.push(void 0);
          }
        }
      }
      return _results;
    })())["catch"](function(err) {
      throw err;
    });
  };

  return DxQuestionToPooledSourceOfData;

})(Migration);
