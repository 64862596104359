var EtdFieldsContentToDraftJSContent, Migration, W, convertContent, convertSectionContent, customHTML2Content, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

customHTML2Content = Editor.EditorUtils.customHTML2Content;

mediator = require('mediator');

W = require('when');

convertContent = _.compose(Draft.convertToRaw, customHTML2Content);

convertSectionContent = function(section, fieldNames) {
  var content, fieldName, _i, _len, _results;
  if (fieldNames == null) {
    fieldNames = ['content', 'contentConsensus'];
  }
  _results = [];
  for (_i = 0, _len = fieldNames.length; _i < _len; _i++) {
    fieldName = fieldNames[_i];
    content = section[fieldName];
    if (_.isString(content)) {
      _results.push(section[fieldName] = _.isEmpty(content) ? null : convertContent(content));
    } else {
      _results.push(void 0);
    }
  }
  return _results;
};

module.exports = EtdFieldsContentToDraftJSContent = (function(_super) {
  __extends(EtdFieldsContentToDraftJSContent, _super);

  function EtdFieldsContentToDraftJSContent() {
    EtdFieldsContentToDraftJSContent.__super__.constructor.call(this, false);
  }

  EtdFieldsContentToDraftJSContent.prototype.up = function(project, colls) {
    var recommendationIds;
    EtdFieldsContentToDraftJSContent.__super__.up.apply(this, arguments);
    recommendationIds = colls.questions.reduce(function(recommendationIds, q) {
      return recommendationIds.concat(q.get('recommendationIds'));
    }, []);
    return mediator.services.storePersistenceAdapter.fetch(project.id, recommendationIds).then(function(_arg) {
      var rows, updatedEtdDocs;
      rows = _arg.rows;
      updatedEtdDocs = rows.map(function(row) {
        var doc, partName, section, sectionName, _i, _len, _ref, _ref1, _ref2, _ref3;
        doc = row.doc;
        _ref = ['researchEvidences', 'additionalConsiderations'];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          partName = _ref[_i];
          _ref1 = doc.templateData.assessment[partName];
          for (sectionName in _ref1) {
            section = _ref1[sectionName];
            convertSectionContent(section);
          }
        }
        _ref2 = doc.templateData.conclusions.sections;
        for (sectionName in _ref2) {
          section = _ref2[sectionName];
          convertSectionContent(section);
        }
        _ref3 = doc.templateData.assessment.criteria;
        for (sectionName in _ref3) {
          section = _ref3[sectionName];
          convertSectionContent(section, ['votingComment']);
        }
        return doc;
      });
      return mediator.services.storePersistenceAdapter.bulkDocs(project.id, updatedEtdDocs);
    })["catch"](function(err) {
      throw err;
    });
  };

  return EtdFieldsContentToDraftJSContent;

})(Migration);
