var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"questions-view\">\n  <ul class=\"standard-list\"></ul>\n</div>\n<div class=\"show-original-version\"></div>\n<div class=\"outcomes-table-container\">\n  <table class=\"standard-table outcomes-table "
    + escapeExpression(((helper = (helper = helpers.$tableMode || (depth0 != null ? depth0.$tableMode : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"$tableMode","hash":{},"data":data}) : helper)))
    + "\">\n    <thead>\n      <tr class=\"outcome-sof-row v4\">\n        <th rowspan=\"2\" class=\"outcome-cell\">\n          <div class=\"align-left\">\n            <span class=\"value\">\n              "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "outcome_and_follow_up", {"name":"i18n","hash":{},"data":data})))
    + "\n            </span>\n          </div>\n        </th>\n        <th rowspan=\"2\" class=\"patient-studies regular-header\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "patients_studies", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </th>\n        <th rowspan=\"2\" class=\"patient-studies narrative-header\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "patients_studies", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </th>\n        <th rowspan=\"2\" class=\"relative-effect regular-header\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "relative_effect", {"name":"i18n","hash":{},"data":data})))
    + "<br>(95% CI)</span>\n          <span class=\"context-help-icon\" data-property=\"relativeEffect\"></span>\n        </th>\n        <th colspan=\"3\" class=\"regular-header\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "absolute_effects", {"name":"i18n","hash":{},"data":data})))
    + " (95% CI)</span>\n        </th>\n        <th colspan=\"4\" rowspan=\"2\" class=\"impact narrative-header\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "impact", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </th>\n        <th rowspan=\"2\" class=\"quality\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "certainty", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n          <span class=\"context-help-icon\" data-property=\"quality\"></span>\n        </th>\n        <th rowspan=\"2\" class=\"what_happens regular-header\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "what_happens", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </th>\n        <th rowspan=\"2\" class=\"table-mode\">\n          <button class=\"table-mode\"></button>\n        </th>\n      </tr>\n      <tr class=\"outcome-sof-row-v4\">\n        <th class=\"control regular-header\">\n          <span class=\"value\"></span>\n        </th>\n        <th class=\"intervention regular-header\">\n          <span class=\"value\"></span>\n          <span class=\"context-help-icon\" data-property=\"absoluteEffect\"></span>\n        </th>\n        <th class=\"difference regular-header\">\n          <span class=\"value\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "difference", {"name":"i18n","hash":{},"data":data})))
    + "</span>\n        </th>\n      </tr>\n    </thead>\n\n    <tbody></tbody>\n\n  </table>\n\n";
  stack1 = this.invokePartial(partials.outcomeButtons, '  ', 'outcomeButtons', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}