var EtdV1DiagnosticRecommendationsExporter, RecommendationsExporter,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

RecommendationsExporter = require('./recommendations_exporter');

module.exports = EtdV1DiagnosticRecommendationsExporter = (function(_super) {
  __extends(EtdV1DiagnosticRecommendationsExporter, _super);

  function EtdV1DiagnosticRecommendationsExporter(_originalQuestion) {
    this._exportResearchPriorities = __bind(this._exportResearchPriorities, this);
    this._exportMonitoringAndEvaluation = __bind(this._exportMonitoringAndEvaluation, this);
    this._exportImplementationConsiderations = __bind(this._exportImplementationConsiderations, this);
    this._exportJustification = __bind(this._exportJustification, this);
    this._exportRecommendationText = __bind(this._exportRecommendationText, this);
    this._exportPanelDecisions = __bind(this._exportPanelDecisions, this);
    this._exportRecommendationType = __bind(this._exportRecommendationType, this);
    this._exportConsequencesBalance = __bind(this._exportConsequencesBalance, this);
    this._exportFeasibilitySection = __bind(this._exportFeasibilitySection, this);
    this._exportAcceptabilitySection = __bind(this._exportAcceptabilitySection, this);
    this._exportEquitySection = __bind(this._exportEquitySection, this);
    this._exportResourcesSection = __bind(this._exportResourcesSection, this);
    this._exportBenefitsAndHarmsSection = __bind(this._exportBenefitsAndHarmsSection, this);
    this._exportCertaintyOfEvidenceSection = __bind(this._exportCertaintyOfEvidenceSection, this);
    this._exportValuesAndPreferencesSection = __bind(this._exportValuesAndPreferencesSection, this);
    this._exportDiagnosticTestAccuracySection = __bind(this._exportDiagnosticTestAccuracySection, this);
    this._exportProblemSection = __bind(this._exportProblemSection, this);
    EtdV1DiagnosticRecommendationsExporter.__super__.constructor.call(this, _originalQuestion);
  }

  EtdV1DiagnosticRecommendationsExporter.prototype._exportProblemSection = function() {
    var priorityCriterion, severityCriterion;
    priorityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('problem', 'is_priority'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['problemPriorityJudgement']));
    severityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('problem', 'is_severe'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['problemSevereJudgement']));
    return this._makeSection('ProblemSection', this._getTitleForSectionLabel('problem'), [priorityCriterion, severityCriterion], this._originalQuestion['problemResearchEvidence'], this._originalQuestion['problemAdditionalConsiderations']);
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportDiagnosticTestAccuracySection = function() {
    var accuracyCriterion;
    accuracyCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('diagnostic_test_accuracy', 'what_test_accuracy'), this._accuracyOptions, this._getNameForOptionLabel(this._originalQuestion['diagnosticTestAccuracyJudgement']));
    return this._makeSection('DiagnosticTestAccuracySection', this._getTitleForSectionLabel('diagnostic_test_accuracy'), accuracyCriterion, this._originalQuestion['diagnosticTestAccuracyEvidence'], this._originalQuestion['diagnosticTestAccuracyAdditionalConsiderations']);
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportValuesAndPreferencesSection = function() {
    var similarityCriterion;
    similarityCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('values_and_preferences', 'is_similarity'), this._similarityOptions, this._getNameForOptionLabel(this._originalQuestion['mainOutcomesValuesSimilarityJudgement']));
    return this._makeSection('ValuesAndPreferencesSection', this._getTitleForSectionLabel('values_and_preferences'), similarityCriterion, this._originalQuestion['valuesAndPreferencesEvidence'], this._originalQuestion['valuesAndPreferencesAdditionalConsiderations']);
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportCertaintyOfEvidenceSection = function() {
    var adverseEffectsEvidenceCertaintyCriterion, decisionsLinkCertaintyCriterion, managementConsequencesCertaintyCriterion, overallCertaintyCriterion, testAccuracyCertaintyCriterion;
    testAccuracyCertaintyCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'accuracy_certainty'), this._certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['accuracyEvidenceCertaintyJudgement']), this._originalQuestion['accuracyEvidenceCertaintyEvidence']);
    adverseEffectsEvidenceCertaintyCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'adverse_effects_certainty'), this._certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['adverseEffectsCertaintyJudgement']), this._originalQuestion['adverseEffectsCertaintyEvidence'], this._originalQuestion['adverseEffectsCertaintyAdditionalConsiderations']);
    managementConsequencesCertaintyCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'management_consequences_effects_certainty'), this._certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['managementConsequencesEffectsCertaintyJudgement']), this._originalQuestion['managementConsequencesEffectsCertaintyEvidence'], this._originalQuestion['managementConsequencesEffectsCertaintyAdditionalConsiderations']);
    decisionsLinkCertaintyCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'decisions_link_certainty'), this._certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['decisionsLinkCertaintyJudgement']), this._originalQuestion['decisionsLinkCertaintyEvidence'], this._originalQuestion['decisionsLinkCertaintyAdditionalConsiderations']);
    overallCertaintyCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('evidence_certainty', 'overall_effects_certainty'), this._certaintyOptions, this._getNameForOptionLabel(this._originalQuestion['overallEffectsCertaintyJudgement']), this._originalQuestion['overallEffectsCertaintyEvidence'], this._originalQuestion['overallEffectsCertaintyAdditionalConsiderations']);
    return this._makeSection('CertaintyOfEvidenceSection', this._getTitleForSectionLabel('evidence_certainty'), [testAccuracyCertaintyCriterion, adverseEffectsEvidenceCertaintyCriterion, managementConsequencesCertaintyCriterion, decisionsLinkCertaintyCriterion, overallCertaintyCriterion]);
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportBenefitsAndHarmsSection = function() {
    var effectsBalanceFavourCriterion, outcomeImportanceCriterion, substantialDesirableEffectsCriterion, substantialUndesirableEffectsCriterion;
    outcomeImportanceCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('benefits_harms', 'how_important_outcomes'), null, null, null, this._originalQuestion['outcomeImportanceAdditionalConsiderations']);
    substantialDesirableEffectsCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('benefits_harms', 'how_substantial_desirable_effects'), null, null, null, this._originalQuestion['substantialDesirableEffectsAdditionalConsiderations']);
    substantialUndesirableEffectsCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('benefits_harms', 'how_substantial_undesirable_effects'), null, null, null, this._originalQuestion['substantialUndesirableEffectsAdditionalConsiderations']);
    effectsBalanceFavourCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('benefits_harms', 'effects_balance_favour'), this._balanceOptions, this._getNameForOptionLabel(this._originalQuestion['effectsBalanceFavourJudgement']), null, this._originalQuestion['effectsBalanceFavourAdditionalConsiderations']);
    return this._makeSection('BenefitsAndHarmsSection', this._getTitleForSectionLabel('benefits_harms', 'title_dx'), [outcomeImportanceCriterion, substantialDesirableEffectsCriterion, substantialUndesirableEffectsCriterion, effectsBalanceFavourCriterion]);
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportResourcesSection = function() {
    var areResourcesSmallCriterion, isIncrementalCostSmallCriterion;
    areResourcesSmallCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('resources', 'are_resources_small'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['areResourcesSmallJudgement']), this._originalQuestion['areResourcesSmallEvidence'], this._originalQuestion['areResourcesSmallAdditionalConsiderations']);
    isIncrementalCostSmallCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('resources', 'is_incremental_cost_small'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['isIncrementalCostSmallJudgement']), this._originalQuestion['isIncrementalCostSmallEvidence'], this._originalQuestion['isIncrementalCostSmallAdditionalConsiderations']);
    return this._makeSection('ResourcesSection', this._getTitleForSectionLabel('resources'), [areResourcesSmallCriterion, isIncrementalCostSmallCriterion]);
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportEquitySection = function() {
    var inequitiesCriterion;
    inequitiesCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('equity', 'what_happens_to_health_inequities'), this._inequitiesOptions, this._getNameForOptionLabel(this._originalQuestion['healthInequitiesJudgement']));
    return this._makeSection('EquitySection', this._getTitleForSectionLabel('equity'), inequitiesCriterion, this._originalQuestion['healthInequitiesEvidence'], this._originalQuestion['healthInequitiesAdditionalConsiderations']);
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportAcceptabilitySection = function() {
    var isOptionAcceptableCriterion;
    isOptionAcceptableCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('acceptability', 'is_option_acceptable'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['isOptionAcceptableJudgement']));
    return this._makeSection('AcceptabilitySection', this._getTitleForSectionLabel('acceptability'), isOptionAcceptableCriterion, this._originalQuestion['isOptionAcceptableEvidence'], this._originalQuestion['isOptionAcceptableAdditionalConsiderations']);
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportFeasibilitySection = function() {
    var isOptionFeasibleCriterion;
    isOptionFeasibleCriterion = this._makeCriterion('CustomJudgementCriterion', this._getQuestionForSectionAndQuestionLabels('feasibility', 'is_option_feasible'), this._standardOptions, this._getNameForOptionLabel(this._originalQuestion['isOptionFeasibleJudgement']));
    return this._makeSection('FeasibilitySection', this._getTitleForSectionLabel('feasibility'), isOptionFeasibleCriterion, this._originalQuestion['isOptionFeasibleEvidence'], this._originalQuestion['isOptionFeasibleAdditionalConsiderations']);
  };

  EtdV1DiagnosticRecommendationsExporter.prototype.exportSections = function() {
    return [this._exportProblemSection(), this._exportDiagnosticTestAccuracySection(), this._exportValuesAndPreferencesSection(), this._exportCertaintyOfEvidenceSection(), this._exportBenefitsAndHarmsSection(), this._exportResourcesSection(), this._exportEquitySection(), this._exportAcceptabilitySection(), this._exportFeasibilitySection()];
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportConsequencesBalance = function() {
    var consequencesBalance, prefix;
    consequencesBalance = this._originalQuestion['consequencesBalance'];
    if (consequencesBalance) {
      prefix = 'es:recommendations.table.consequences_balance_dx.';
      return $.t("" + prefix + consequencesBalance);
    } else {
      return '';
    }
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportRecommendationType = function() {
    var prefix, recommendationType;
    recommendationType = this._originalQuestion['recommendationType'];
    if (recommendationType) {
      prefix = 'es:recommendations.table.recommendation_dx.';
      return $.t("" + prefix + recommendationType);
    } else {
      return $.t('es:recommendations.table.recommendation_dx.no_recommendation');
    }
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportPanelDecisions = function() {
    var _ref;
    return (_ref = this._originalQuestion['panelDecisionsText']) != null ? _ref : '';
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportRecommendationText = function() {
    var _ref;
    return (_ref = this._originalQuestion['recommendationText']) != null ? _ref : '';
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportJustification = function() {
    var _ref;
    return (_ref = this._originalQuestion['recommendationJustification']) != null ? _ref : '';
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportImplementationConsiderations = function() {
    var _ref;
    return (_ref = this._originalQuestion['implementationConsiderations']) != null ? _ref : '';
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportMonitoringAndEvaluation = function() {
    var _ref;
    return (_ref = this._originalQuestion['monitoringAndEvaluation']) != null ? _ref : '';
  };

  EtdV1DiagnosticRecommendationsExporter.prototype._exportResearchPriorities = function() {
    var _ref;
    return (_ref = this._originalQuestion['researchPriorities']) != null ? _ref : '';
  };

  EtdV1DiagnosticRecommendationsExporter.prototype.exportConclusions = function() {
    return {
      'consequencesBalance': this._exportConsequencesBalance(),
      'recommendationType': this._exportRecommendationType(),
      'recommendationText': this._exportRecommendationText(),
      'justification': this._exportJustification(),
      'panelDecisions': this._exportPanelDecisions(),
      'implementationConsiderations': this._exportImplementationConsiderations(),
      'monitoringAndEvaluation': this._exportMonitoringAndEvaluation(),
      'researchPriorities': this._exportResearchPriorities()
    };
  };

  EtdV1DiagnosticRecommendationsExporter.prototype.exportHeader = function() {
    return null;
  };

  EtdV1DiagnosticRecommendationsExporter.prototype.getJsonLdType = function() {
    switch (this._originalQuestion['recommendationType']) {
      case 'strongly_positive':
      case 'positive':
        return 'MedicalGuidelineRecommendation';
      case 'strongly_negative':
      case 'negative':
        return 'MedicalGuidelineContraindication';
      default:
        return null;
    }
  };

  return EtdV1DiagnosticRecommendationsExporter;

})(RecommendationsExporter);
