assessmentAdolopments = require 'components/etd/assessment_adolopments'
AssessmentSectionRow = require 'components/etd/assessment_section_row'
AssessmentSectionHeader = require 'components/etd/assessment_section_header'
AssessmentSectionVotingDetailsRow = require 'components/etd/assessment_section_voting_details_row'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdSectionWithVotingMixin = require 'components/mixins/etd_section_with_voting_data_mixin'
mediator = require 'mediator'
VotingTitleRow = require 'components/etd/voting_section_title_row'


AssessmentSectionRowWithVoting = createReactClass
  displayName: 'AssessmentSectionRowWithVoting'

  propTypes: {
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired,
  }

  mixins: [
    CustomRenderMixin
    EtdSectionWithVotingMixin 'judgements'
  ]

  render: ->
    votingProps = @_getVotingProps()
    spacerColSpan = if @props.renderMode in ['printout', 'fullscreen'] then 3 else 4
    { adolopments, sectionId, expanded } = @props

    rows = []

    unless adolopments.isEmpty()
      rows.push assessmentAdolopments(_.extend({}, @props, i18n: @i18n))

    rows.push <AssessmentSectionRow
      isAdoloped={not adolopments.isEmpty()}
      key={sectionId}
      {...@props}
    />
    rows.push <VotingTitleRow key={"#{sectionId}-voting-title-row"} {...@props} {...votingProps} />
    rows.push <AssessmentSectionVotingDetailsRow
      key={"#{sectionId}-voting-details-row"}
      {...@props}
      {...votingProps}
    />

    <tbody className={@props.className}>
      <AssessmentSectionHeader
        etdId={@props.etdId}
        index={@props.index}
        colSpan={spacerColSpan}
        sectionName={@props.sectionName}
        info={@props.criterion.get('info')}
        content={@props.criterion.get('description')}
        renderMode={@props.renderMode}
        sectionId={@props.sectionId}
        expanded={@props.expanded}
      />
      {rows if (expanded or @props.renderMode is 'printout')}
    </tbody>

module.exports = AssessmentSectionRowWithVoting
