Input = require 'components/common/input'
Tooltip = require 'components/common/tooltip'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
QuestionsActions = require 'actions/questions_actions'
Translation = require 'components/mixins/translation'
{ getKeyValObject } = require 'base/lib/utils'
{ bool, instanceOf, arrayOf, string, func } = PropTypes
QuestionCodes = require 'components/common/question_codes'
{ QUESTION_DIAG_TAGS, QUESTION_MGMT_TAGS } = require 'lib/overarching_question_helper'
Switcher = require 'components/common/switcher'
mediator = require 'mediator'

QUESTION_META_FIELDS = ['settings', 'authors']

{ switches } = mediator.services

QuestionMetaFields = createReactClass
  displayName: 'QuestionMetaFields'

  propTypes:
    fields: arrayOf(string).isRequired
    isEditing: bool.isRequired
    onRequestSave: func.isRequired
    onRequestCancel: func.isRequired
    questionData: instanceOf(Immutable.Map).isRequired
    comparatorTestPresent: bool

  mixins: [
    CustomRenderMixin
    Translation('es:question')
  ]

  getInitialState: ->
    {}

  getDefaultProps: ->
    fields: QUESTION_META_FIELDS

  handleFieldChange: (evt) ->
    fieldName = evt.target.getAttribute 'name'
    fieldValue = if fieldName is 'manualTableTitle' then evt.target.checked else evt.target.value
    @setState getKeyValObject fieldName, fieldValue

  getFieldValue: (attrName) ->
    @state[attrName] ? @props.questionData.get attrName,
      if attrName is 'manualTableTitle' then false else ''

  getEditedValues: ->
    editedValues = {}

    for fieldName, editedValue of @state
      initialValue = @props.questionData.get fieldName, ''
      if editedValue? and editedValue isnt initialValue
        editedValues[fieldName] = editedValue

    editedValues

  componentDidUpdate: (prevProps, prevState) ->
    if prevProps.isEditing and not @props.isEditing
      @setState @props.fields.reduce (newState, fieldName) =>
        _.extend newState, getKeyValObject fieldName, null
      , { tableTitle: null }

  addCode: (codeGroup) -> (payload) ->
    QuestionsActions.addCode { codeGroup, payload }

  removeCode: (codeGroup) -> (payload) ->
    QuestionsActions.removeCode { codeGroup, payload }

  renderFieldInput: (fieldName) ->
    fieldText = @getFieldValue fieldName
    isFieldDisabled = fieldName is 'tableTitle' and not @getFieldValue 'manualTableTitle'
    fieldCls = classNames 'field__input', fieldName

    <div className={fieldCls}>
      <Input
        disabled={isFieldDisabled}
        id={fieldName}
        key={fieldName}
        name={fieldName}
        onChange={@handleFieldChange}
        onRequestSave={@props.onRequestSave}
        onRequestCancel={@props.onRequestCancel}
        type='text'
        value={fieldText}
      />
      {if fieldName is 'tableTitle'
        isManual = @getFieldValue 'manualTableTitle'

        <label>
          <input
            checked={isManual}
            onChange={@handleFieldChange}
            name='manualTableTitle'
            type='checkbox'
          />
          <span className='btn btn-switch auto-manual-switch'>
            {@i18n if isManual then 'switch_to_auto' else 'switch_to_manual'}
          </span>
        </label>
      }
    </div>

  renderField: (fieldName) ->
    labelKey = if fieldName is 'settings' then 'setting' else _.str.underscored fieldName

    <div className='field' key={fieldName}>
      <div className='field__label'>
        <label htmlFor={fieldName}>
          {if fieldName is 'authors'
            <Tooltip tooltipType='info' tipJoint='left bottom' targetJoint='left bottom'>
              <span
                className='info-sign'
                title={@i18n 'authors_info'}
              />
            </Tooltip>
          }
          {@i18n labelKey}
        </label>
      </div>
      <div className='field__text'>
        {if @props.isEditing
          @renderFieldInput(fieldName)
        else
          <span>{@props.questionData.get fieldName}</span>
        }
      </div>
    </div>

  onCadthSwitchChange: ->
    QuestionsActions.toggleCadthPublication @props.questionData.get '_id'

  getCodeFields: ->
    { questionData, comparatorTestPresent } = @props
    if questionData.get('type') is 'diagnostic'
      _.without(QUESTION_DIAG_TAGS, if comparatorTestPresent then '' else 'comparatorTest')
    else
      _.without(QUESTION_MGMT_TAGS, 'outcome')

  render: ->
    questionCodesVisible = switches.isServerSwitchOn('covidFeatures') or
      switches.isServerSwitchOn('tbFeatures') or
      switches.isServerSwitchOn('ecFeatures')
    cadthSwitchVisible = switches.isServerSwitchOn 'covidFeatures'

    <div className='question-meta-fields'>
      {@props.fields.map @renderField}
      {questionCodesVisible and <div>
        {_.map(@getCodeFields(), (codeGroup) =>
          fieldLabelClassNames = classNames 'field__label',
            'asterixed': switches.isServerSwitchOn('tbFeatures') and @props.isEditing and
            codeGroup in ['comparison', 'intervention']

          <div className='field' key={codeGroup}>
            <div className={fieldLabelClassNames}>
              {@i18n "#{codeGroup}_code"}
            </div>
            <div className='field__text'>
              <QuestionCodes
                isEditing={@props.isEditing}
                codes={@props.questionData.getIn(['codes', codeGroup], Immutable.Map())}
                parentId={@props.questionData.get('_id')}
                codeGroup={codeGroup}
                onAdd={@addCode(codeGroup)}
                onRemove={@removeCode(codeGroup)}
              />
            </div>
          </div>
        )}
      </div>}
      {cadthSwitchVisible and <Switcher
        buttonText={@i18n 'cadth_publication'}
        checked={@props.questionData.get('cadthPublication') or false}
        onChange={@onCadthSwitchChange}
      />}
    </div>

module.exports = QuestionMetaFields
