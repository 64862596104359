var EtdVotingView, ModuleController, VotingController, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ModuleController = require('controllers/base/module_controller');

EtdVotingView = require('views/etd_voting_view');

mediator = require('mediator');

module.exports = VotingController = (function(_super) {
  __extends(VotingController, _super);

  function VotingController() {
    return VotingController.__super__.constructor.apply(this, arguments);
  }

  VotingController.prototype.index = function(params) {
    var _ref;
    return this.view = new EtdVotingView({
      model: mediator.project,
      onboardingAutostart: (_ref = params.onboardingTourAutostart) != null ? _ref : false
    });
  };

  return VotingController;

})(ModuleController);
