{ string, func, instanceOf } = PropTypes
Modal = require 'components/common/modal'
{ ReminderFormContainer: ReminderForm } = require '../forms/reminder_form'

ReminderModal = createReactClass
  displayName: "ReminderModal"

  propTypes:
    panelMembers: instanceOf(Immutable.List).isRequired
    votedMemberIds: instanceOf(Immutable.List).isRequired
    sentMemberIds: instanceOf(Immutable.List).isRequired
    etdId: string.isRequired
    onSend: func.isRequired
    onClose: func.isRequired

  render: ->
    {
      panelMembers
      votedMemberIds
      sentMemberIds
      etdId
      onSend
      onClose
    } = @props

    <Modal
      className="reminder-modal"
      isOpen
      closeButton
      onClose={onClose}
    >
      <ReminderForm
        panelMembers={panelMembers}
        votedMemberIds={votedMemberIds}
        sentMemberIds={sentMemberIds}
        etdId={etdId}
        onSend={onSend}
      />
    </Modal>

module.exports = ReminderModal
