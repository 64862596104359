Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
MDGPublicationActions = require 'actions/mdg_publication_actions'
MDGPublicationStore = require 'stores/mdg_publication_store'
Modal = require 'components/common/modal'
ProgressBar = require 'components/common/progress_bar'
{ useI18n } = require 'lib/react_utils'

storeConnector =
  MDGPublicationStore: (Store) ->
    isOpen: Store.isPublishDialogOpen()
    isPublishSuccess: Store.isPublishSuccess()
    progress: Store.getPublishProgress()

MDGPublicationModal = ({ isOpen, isPublishSuccess, progress }) ->
  i18n = useI18n('dbep:mdg_publication_screen.modal')

  <Modal
    className="mdg-publication-modal"
    isOpen={isOpen}
    modalSize="medium"
    title={i18n 'title'}
  >
    <div className="mdg-publication-modal__info">
      {if isPublishSuccess then i18n('success') else i18n('info')}
    </div>
    <ProgressBar className="mdg-publication-modal__progress" percentage={progress} />
    {isPublishSuccess and <div className="mdg-publication-modal__button">
      <Button
        className="btn btn-apply"
        label={i18n '/actions.ok'}
        onClick={MDGPublicationActions.closePublishDialog}
      />
    </div>}
  </Modal>

MDGPublicationModal.propTypes =
  isOpen: PropTypes.bool.isRequired
  isPublishSuccess: PropTypes.bool.isRequired
  progress: PropTypes.number.isRequired

module.exports = ConnectStore MDGPublicationModal, MDGPublicationStore, storeConnector
