var CHAPTER_ICONS, Chapter, DAOutcome, DA_ILLUSTRATIONS, DA_THEMES, DecisionAidDocShape, FAVOURS_OPTIONS, Outcome, Question, SUBCHAPTER_ILLUSTRATIONS, Subchapter, TableContent, arrayOf, boolean, convertToDraftJS, docShape, extendedParse, initial, mapOf, number, object, oneOf, optional, shape, string, _ref, _ref1, _ref2;

docShape = require('lib/db_docs/field_types/doc_shape');

Outcome = require('lib/db_docs/doc_shapes/outcome_doc_shape');

oneOf = require('lib/db_docs/field_types/one_of');

arrayOf = require('lib/db_docs/field_types/array_of');

mapOf = require('lib/db_docs/field_types/map_of');

_ref = require('lib/db_docs/field_types/type_decorators'), initial = _ref.initial, extendedParse = _ref.extendedParse;

shape = require('lib/db_docs/field_types/shape');

optional = shape.typeDecorators.optional;

_ref1 = require('lib/db_docs/field_types/built_in_types'), string = _ref1.string, boolean = _ref1.boolean, object = _ref1.object, number = _ref1.number;

_ref2 = require('lib/da_helper'), DA_ILLUSTRATIONS = _ref2.DA_ILLUSTRATIONS, DA_THEMES = _ref2.DA_THEMES, CHAPTER_ICONS = _ref2.CHAPTER_ICONS, SUBCHAPTER_ILLUSTRATIONS = _ref2.SUBCHAPTER_ILLUSTRATIONS;

convertToDraftJS = require('lib/draft_utils').convertToDraftJS;

FAVOURS_OPTIONS = ['intervention', 'comparison'];

DAOutcome = shape.utils.extend(Outcome, shape({
  comments: optional(extendedParse(convertToDraftJS)(object)),
  narrativeDesc: optional(extendedParse(convertToDraftJS)(object)),
  calculatedControlFavours: optional(oneOf(FAVOURS_OPTIONS)),
  lowControlFavours: optional(oneOf(FAVOURS_OPTIONS)),
  moderateControlFavours: optional(oneOf(FAVOURS_OPTIONS)),
  highControlFavours: optional(oneOf(FAVOURS_OPTIONS))
}));

Question = docShape({
  question: string,
  intervention: string,
  comparison: string,
  indexTest: string,
  comparatorTest: string,
  comparatorTestPresent: boolean,
  targetCondition: string,
  healthProblemOrPopulation: string,
  author: string,
  outcomes: arrayOf(DAOutcome)
});

TableContent = shape({
  columns: arrayOf(shape({
    header: string,
    content: optional(object),
    orderNum: number,
    illustration: optional(shape({
      illustration: optional(oneOf(SUBCHAPTER_ILLUSTRATIONS)),
      customSrc: optional(string)
    }))
  }))
});

Subchapter = docShape({
  title: string,
  titleHidden: boolean,
  illustration: optional(shape({
    position: oneOf(['left', 'right']),
    illustration: optional(oneOf(SUBCHAPTER_ILLUSTRATIONS)),
    customSrc: optional(string)
  })),
  deletable: initial(true)(boolean),
  type: oneOf(['editor', 'table', 'outcomesTable']),
  content: optional(extendedParse(convertToDraftJS)(object)),
  tableContent: optional(TableContent)
});

Chapter = shape({
  title: string,
  illustration: optional(oneOf(CHAPTER_ICONS)),
  subchapters: arrayOf(Subchapter),
  deletable: initial(true)(boolean)
});

DecisionAidDocShape = docShape({
  title: string,
  authorOrOrganization: string,
  question: Question,
  chapters: mapOf(Chapter),
  chaptersOrder: arrayOf(string),
  appearance: shape({
    theme: oneOf(DA_THEMES),
    illustration: oneOf(DA_ILLUSTRATIONS)
  })
});

module.exports = DecisionAidDocShape;
