IconButton = require 'components/common/icon_button'
Popover = require 'components/common/popover'


InfoTooltip = createReactClass

  displayName: "InfoTooltip"

  getInitialState: ->
    showTooltip: false

  toggleShowTooltip: ->
    @setState showTooltip: not @state.showTooltip

  render: ->
    <Popover
      visible={@state.showTooltip}
      onRequestClose={@toggleShowTooltip}
    >
      <IconButton
        iconName="info-icon"
        onClick={@toggleShowTooltip}
      />
      <div className='information-tooltip'>
        {@props.children}
      </div>
    </Popover>

module.exports = InfoTooltip
