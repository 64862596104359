var MDA_TABLE_TYPE, MDA_TOPIC_TYPE, MDG_CREATE_QUESTION_DATA, MDG_CREATE_QUESTION_DIALOG_INITIAL_STATE, MDG_EMPTY_INTERVENTION, MDG_IMAGING_INTERVENTIONS, MDG_QUESTION_TYPE, QUESTION_TYPES_BY_QUESTION_DOC_TYPE, Question, QuestionsActions, QuestionsStore, alt, errorHandler, errorHandlerWithMsg, filterOutAdoloped, filterOutcomesByIds, getDataForDxTable, getDataForSofShort, getPrevalences, getQuestionIsofData, getQuestionTypeDocType, immutable, mediator, pluck, prepareCodeWithSanitizedValue, prepareOutcomes, _ref, _ref1, _ref2, _ref3, _ref4;

alt = require('alt');

immutable = require('stores/utils/immutable');

_ref = require('lib/mdg_helper'), MDG_CREATE_QUESTION_DATA = _ref.MDG_CREATE_QUESTION_DATA, MDG_EMPTY_INTERVENTION = _ref.MDG_EMPTY_INTERVENTION, MDG_IMAGING_INTERVENTIONS = _ref.MDG_IMAGING_INTERVENTIONS;

_ref1 = require('lib/db_docs/doc_types'), MDA_TABLE_TYPE = _ref1.MDA_TABLE, MDA_TOPIC_TYPE = _ref1.MDA_TOPIC, MDG_QUESTION_TYPE = _ref1.MDG_QUESTION;

pluck = require('lib/immutable_utils').pluck;

mediator = require('mediator');

QuestionsActions = require('actions/questions_actions');

Question = require('models/question');

_ref2 = require('lib/app_utils'), errorHandler = _ref2.errorHandler, errorHandlerWithMsg = _ref2.errorHandlerWithMsg;

_ref3 = require('lib/questions_helper'), filterOutAdoloped = _ref3.filterOutAdoloped, getQuestionIsofData = _ref3.getQuestionIsofData, prepareCodeWithSanitizedValue = _ref3.prepareCodeWithSanitizedValue;

_ref4 = require('stores/utils/questions_store_helper'), getDataForSofShort = _ref4.getDataForSofShort, getPrevalences = _ref4.getPrevalences, prepareOutcomes = _ref4.prepareOutcomes, getDataForDxTable = _ref4.getDataForDxTable, filterOutcomesByIds = _ref4.filterOutcomesByIds;

QUESTION_TYPES_BY_QUESTION_DOC_TYPE = require('lib/questions_helper').QUESTION_TYPES_BY_QUESTION_DOC_TYPE;

getQuestionTypeDocType = function(type) {
  switch (type) {
    case 'regular':
      return 'question';
    case MDA_TABLE_TYPE:
      return MDA_TABLE_TYPE;
    case MDA_TOPIC_TYPE:
      return MDA_TOPIC_TYPE;
    default:
      return "" + type + "Question";
  }
};

MDG_CREATE_QUESTION_DIALOG_INITIAL_STATE = Immutable.fromJS({
  isCreating: false,
  isOpen: false,
  questionData: MDG_CREATE_QUESTION_DATA
});

QuestionsStore = (function() {
  function QuestionsStore() {
    this.state = Immutable.Map({
      meta: Immutable.Map({
        fetching: false,
        lastAddedQuestionid: null,
        deletedQuestions: Immutable.Map(),
        deletedRecommendationDocs: Immutable.Map(),
        deletedOverReviewDocs: Immutable.Map(),
        editingQuestion: Immutable.Map(),
        overReviewModuleStateByQuestion: Immutable.Map(),
        isQuestionBeingDuplicated: false,
        isQuestionBeingAdded: false
      }),
      questions: Immutable.OrderedMap(),
      questionsCollection: Immutable.Map(),
      mdgCreateQuestionDialog: MDG_CREATE_QUESTION_DIALOG_INITIAL_STATE
    });
    this.exportPublicMethods({
      getQuestion: this.getQuestion,
      getQuestions: this.getQuestions,
      getQuestionsCount: this.getQuestionsCount,
      getQuestionsWithEtd: this.getQuestionsWithEtd,
      getQuestionModel: this.getQuestionModel,
      getIsofQuestionModel: this.getIsofQuestionModel,
      getDataForTable: this.getDataForTable,
      getAllOutcomes: this.getAllOutcomes,
      getCriticalOutcomes: this.getCriticalOutcomes,
      isFetching: this.isFetching,
      getLastAddedQuestionId: this.getLastAddedQuestionId,
      getDeletedQuestionDoc: this.getDeletedQuestionDoc,
      getDeletedRecommendationDocs: this.getDeletedRecommendationDocs,
      getDeletedOverReviewDoc: this.getDeletedOverReviewDoc,
      getEditingQuestionId: this.getEditingQuestionId,
      getQuestionEditState: this.getQuestionEditState,
      getQuestionType: this.getQuestionType,
      getOverReviewModuleStateByQuestion: this.getOverReviewModuleStateByQuestion,
      getQuestionOverReviewModuleState: this.getQuestionOverReviewModuleState,
      getSortedQuestionsByKey: this.getSortedQuestionsByKey,
      isQuestionBeingDuplicated: this.isQuestionBeingDuplicated,
      isQuestionBeingAdded: this.isQuestionBeingAdded,
      isMdgCreateQuestionDialogOpen: this.isMdgCreateQuestionDialogOpen,
      isMdgCreateQuestionDialogCreating: this.isMdgCreateQuestionDialogCreating,
      getMdgCreateQuestionDialogQuestionData: this.getMdgCreateQuestionDialogQuestionData,
      getQuestionsIds: this.getQuestionsIds
    });
    this.bindActions(QuestionsActions);
  }

  QuestionsStore.prototype.onDbChange = function(change) {
    var deleted, doc, docType, id, isPresent, questions, shouldUpdateList;
    deleted = change.deleted, id = change.id, doc = change.doc;
    questions = this.state.get('questions');
    isPresent = questions.has(id);
    docType = doc.docType;
    shouldUpdateList = docType === MDA_TABLE_TYPE || docType === MDA_TOPIC_TYPE ? questions.isEmpty() || questions.first().get('docType') === docType : true;
    if (!shouldUpdateList) {
      return;
    }
    if (deleted) {
      return this.setState(this.state.withMutations(function(state) {
        state.updateIn(['meta', 'deletedQuestions', id], function(deletedQuestion) {
          return (deletedQuestion || Immutable.Map()).merge(Immutable.fromJS(doc));
        });
        if (isPresent) {
          return state.set('questions', questions["delete"](id));
        }
      }));
    } else {
      return this.setState(this.state.withMutations(function(state) {
        if (isPresent) {
          return state.set('questions', questions.update(id, function(q) {
            return q.merge(Immutable.fromJS(doc));
          }));
        } else {
          state.setIn(['meta', 'lastAddedQuestionid'], id);
          return state.set('questions', questions.set(id, Immutable.fromJS(doc)));
        }
      }));
    }
  };

  QuestionsStore.prototype.onFetch = function() {
    return this.setState(this.state.setIn(['meta', 'fetching'], true));
  };

  QuestionsStore.prototype.onFetchSuccess = function(qDocs) {
    return this.setState(this.state.withMutations(function(state) {
      var questionsOrderedMap;
      state.setIn(['meta', 'fetching'], false);
      questionsOrderedMap = qDocs.reduce(function(accMap, qDoc) {
        return accMap.set(qDoc['_id'], Immutable.fromJS(qDoc));
      }, Immutable.OrderedMap());
      return state.set('questions', questionsOrderedMap);
    }));
  };

  QuestionsStore.prototype.onFetchError = function(err) {
    this.setState(this.state.withMutations(function(state) {
      return state.setIn(['meta', 'fetching'], false);
    }));
    if (err.status !== 404) {
      return errorHandler(err);
    }
  };

  QuestionsStore.prototype.onDeleteQuestion = function(questionId) {
    var currentlyEditingQuestion;
    currentlyEditingQuestion = this.getEditingQuestionId();
    return this.setState(this.state.withMutations(function(state) {
      if (currentlyEditingQuestion === questionId) {
        state.setIn(['meta', 'editingQuestion', 'isBeingDeleted'], true);
      }
      return state.setIn(['meta', 'deletedQuestions', questionId], Immutable.Map({
        '_id': questionId,
        '_deleted': false
      }));
    }));
  };

  QuestionsStore.prototype.onDeleteQuestionSuccess = function(_arg) {
    var currentlyEditingQuestion, deletedOverReview, deletedQuestion, deletedRecommendationDocs, questionId;
    deletedQuestion = _arg.deletedQuestion, deletedRecommendationDocs = _arg.deletedRecommendationDocs, deletedOverReview = _arg.deletedOverReview;
    questionId = deletedQuestion['_id'];
    currentlyEditingQuestion = this.getEditingQuestionId();
    return this.setState(this.state.withMutations(function(state) {
      if (currentlyEditingQuestion === questionId) {
        state.setIn(['meta', 'editingQuestion'], Immutable.Map());
      }
      state.setIn(['meta', 'deletedRecommendationDocs', questionId], deletedRecommendationDocs);
      return state.setIn(['meta', 'deletedOverReviewDocs', questionId], deletedOverReview);
    }));
  };

  QuestionsStore.prototype.onDeleteQuestionError = function(_arg) {
    var currentlyEditingQuestion, questionId;
    questionId = _arg.questionId;
    currentlyEditingQuestion = this.getEditingQuestionId();
    return this.setState(this.state.withMutations(function(state) {
      if (currentlyEditingQuestion === questionId) {
        state.setIn(['meta', 'editingQuestion', 'isBeingDeleted'], false);
      }
      return state.updateIn(['meta', 'deletedQuestions'], function(deletedQuestions) {
        return deletedQuestions["delete"](questionId);
      });
    }));
  };

  QuestionsStore.prototype.onUpdateQuestion = function(_arg) {
    var questionId;
    questionId = _arg.questionId;
    if (this.getEditingQuestionId() === questionId) {
      return this.setState(this.state.setIn(['meta', 'editingQuestion', 'isBeingSaved'], true));
    }
  };

  QuestionsStore.prototype.onUpdateQuestionSuccess = function(qDoc) {
    if (this.getEditingQuestionId() === qDoc['_id']) {
      return this.setState(this.state.setIn(['meta', 'editingQuestion'], Immutable.Map()));
    }
  };

  QuestionsStore.prototype.onUpdateQuestionError = function(_arg) {
    var questionId;
    questionId = _arg.questionId;
    if (this.getEditingQuestionId() === questionId) {
      return this.setState(this.state.setIn(['meta', 'editingQuestion', 'isBeingSaved'], false));
    }
  };

  QuestionsStore.prototype.onDuplicateQuestion = function(questionId) {
    return this.setState(this.state.setIn(['meta', 'isQuestionBeingDuplicated'], true));
  };

  QuestionsStore.prototype.onDuplicateQuestionSuccess = function(duplicatedDoc) {
    var duplicatedDocId;
    duplicatedDocId = duplicatedDoc['_id'];
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'editingQuestion'], Immutable.Map({
        questionId: duplicatedDocId
      }));
      return state.setIn(['meta', 'isQuestionBeingDuplicated'], false);
    }));
  };

  QuestionsStore.prototype.onDuplicateQuestionError = function(_arg) {
    var questionId;
    questionId = _arg.questionId;
    return this.setState(this.state.setIn(['meta', 'isQuestionBeingDuplicated'], false));
  };

  QuestionsStore.prototype.onPouchSaveError = errorHandler;

  QuestionsStore.prototype.onCreateQuestion = function() {
    return this.setState(this.state.setIn(['meta', 'isQuestionBeingAdded'], true));
  };

  QuestionsStore.prototype.onCreateQuestionSuccess = function(newQuestion) {
    var newQuestionId;
    newQuestionId = newQuestion['_id'];
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'editingQuestion'], Immutable.Map({
        questionId: newQuestionId
      }));
      return state.setIn(['meta', 'isQuestionBeingAdded'], false);
    }));
  };

  QuestionsStore.prototype.onCreateQuestionError = function() {
    return this.setState(this.state.setIn(['meta', 'isQuestionBeingAdded'], false));
  };

  QuestionsStore.prototype.onStartQuestionEdit = function(questionId) {
    var currentlyEditingQuestion;
    currentlyEditingQuestion = this.getEditingQuestionId();
    if (currentlyEditingQuestion !== questionId) {
      return this.setState(this.state.setIn(['meta', 'editingQuestion'], Immutable.Map({
        questionId: questionId
      })));
    }
  };

  QuestionsStore.prototype.onStopQuestionEdit = function(questionId) {
    var currentlyEditingQuestion;
    currentlyEditingQuestion = this.getEditingQuestionId();
    if (currentlyEditingQuestion === questionId) {
      return this.setState(this.state.setIn(['meta', 'editingQuestion'], Immutable.Map()));
    }
  };

  QuestionsStore.prototype.onGetQuestionOverReviewModuleStateSuccess = function(_arg) {
    var isEnabled, questionId;
    questionId = _arg.questionId, isEnabled = _arg.isEnabled;
    return this.setState(this.state.setIn(['meta', 'overReviewModuleStateByQuestion', questionId], isEnabled));
  };

  QuestionsStore.prototype.onGetQuestionOverReviewModuleStateError = errorHandler;

  QuestionsStore.prototype.onToggleOverReview = function(_arg) {
    var isEnabled, questionId;
    questionId = _arg.questionId, isEnabled = _arg.isEnabled;
    return this.setState(this.state.setIn(['meta', 'overReviewModuleStateByQuestion', questionId], isEnabled));
  };

  QuestionsStore.prototype.onOpenMdgCreateQuestionDialog = function() {
    return this.setState(this.state.setIn(['mdgCreateQuestionDialog', 'isOpen'], true));
  };

  QuestionsStore.prototype.onOpenMdgEditQuestionDialog = function(question) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['mdgCreateQuestionDialog', 'isOpen'], true);
      return state.setIn(['mdgCreateQuestionDialog', 'questionData'], question);
    }));
  };

  QuestionsStore.prototype.onCloseMdgCreateQuestionDialog = function() {
    return this.setState(this.state.set('mdgCreateQuestionDialog', MDG_CREATE_QUESTION_DIALOG_INITIAL_STATE));
  };

  QuestionsStore.prototype.onChangeMdgCreateQuestionDialogQuestionType = function(newType) {
    var interventionPath, isNewTypeImaging, isOldTypeImaging, oldType, typePath;
    typePath = ['mdgCreateQuestionDialog', 'questionData', 'type'];
    interventionPath = ['mdgCreateQuestionDialog', 'questionData', 'intervention'];
    oldType = this.state.getIn(typePath);
    isOldTypeImaging = oldType.indexOf('imaging') === 0;
    isNewTypeImaging = newType.indexOf('imaging') === 0;
    return this.setState(this.state.withMutations(function(state) {
      var newIntervention, _ref5;
      state.setIn(typePath, newType);
      if (isNewTypeImaging) {
        newIntervention = (_ref5 = MDG_IMAGING_INTERVENTIONS.get(newType)) != null ? _ref5 : MDG_EMPTY_INTERVENTION;
        return state.setIn(interventionPath, newIntervention);
      } else if (isOldTypeImaging) {
        return state.setIn(interventionPath, MDG_EMPTY_INTERVENTION);
      }
    }));
  };

  QuestionsStore.prototype.onSetMdgCreateQuestionDialogQuestionDataField = function(_arg) {
    var field, useImmutable, value;
    field = _arg.field, value = _arg.value, useImmutable = _arg.useImmutable;
    return this.setState(this.state.setIn(['mdgCreateQuestionDialog', 'questionData', field], useImmutable ? Immutable.fromJS(value) : value));
  };

  QuestionsStore.prototype.onAddMdgCreateQuestionDialogCode = function(_arg) {
    var codeId, codes, parentId, path;
    parentId = _arg.parentId, codes = _arg.codes;
    codeId = codes.first().get('@id');
    path = ['mdgCreateQuestionDialog', 'questionData', parentId, 'codes', codeId];
    return this.setState(this.state.setIn(path, prepareCodeWithSanitizedValue(codes.first())));
  };

  QuestionsStore.prototype.onRemoveMdgCreateQuestionDialogCode = function(_arg) {
    var code, codeId, parentId, path;
    parentId = _arg.parentId, code = _arg.code;
    codeId = code.get('id');
    path = ['mdgCreateQuestionDialog', 'questionData', parentId, 'codes', codeId];
    return this.setState(this.state.deleteIn(path));
  };

  QuestionsStore.prototype.onCreateMdgQuestion = function() {
    return this.setState(this.state.setIn(['mdgCreateQuestionDialog', 'isCreating'], true));
  };

  QuestionsStore.prototype.onCreateMdgQuestionSuccess = function() {
    this.setState(this.state.set('mdgCreateQuestionDialog', MDG_CREATE_QUESTION_DIALOG_INITIAL_STATE));
    return mediator.dialogs.info($.t('mdg_tables:questionCreated'));
  };

  QuestionsStore.prototype.onUpdateMdgQuestionSuccess = function() {
    this.setState(this.state.set('mdgCreateQuestionDialog', MDG_CREATE_QUESTION_DIALOG_INITIAL_STATE));
    return mediator.dialogs.info($.t('mdg_tables:questionSaved'));
  };

  QuestionsStore.prototype.onUpdateMdgQuestionError = function(err) {
    this.setState(this.state.set('mdgCreateQuestionDialog', MDG_CREATE_QUESTION_DIALOG_INITIAL_STATE));
    return errorHandlerWithMsg(err, $.t('mdg_tables:questionUpdateFailed'));
  };

  QuestionsStore.prototype.onCreateMdgQuestionError = function(err) {
    this.setState(this.state.set('mdgCreateQuestionDialog', MDG_CREATE_QUESTION_DIALOG_INITIAL_STATE));
    return errorHandlerWithMsg(err, $.t('mdg_tables:questionCreationFailed'));
  };

  QuestionsStore.prototype.getQuestion = function(qId) {
    if (qId == null) {
      qId = mediator.currentQuestionId;
    }
    if (qId == null) {
      return null;
    }
    return this.state.getIn(['questions', qId], Immutable.Map());
  };

  QuestionsStore.prototype.getQuestions = function(questionsCollectionType) {
    if (questionsCollectionType == null) {
      questionsCollectionType = 'regular';
    }
    return this.state.get('questions').filter(filterOutAdoloped).filter(function(q) {
      var questionTypeDocType;
      if (questionsCollectionType != null) {
        return questionTypeDocType = mediator.services.switches.isOn('mdgTables') ? MDG_QUESTION_TYPE : getQuestionTypeDocType(questionsCollectionType);
      } else {
        return q.get('docType') === 'question';
      }
    });
  };

  QuestionsStore.prototype.getQuestionsIds = function() {
    return pluck(this.getQuestions(), '_id').toList();
  };

  QuestionsStore.prototype.getSortedQuestionsByKey = function(questionsCollectionType, key) {
    if (questionsCollectionType == null) {
      questionsCollectionType = 'regular';
    }
    if (key == null) {
      key = 'name';
    }
    return this.getQuestions(questionsCollectionType).sort(function(a, b) {
      return a.get(key, '').localeCompare(b.get(key));
    });
  };

  QuestionsStore.prototype.getQuestionsByIds = function(questionIds) {
    return this.state.get('questions').filter(function(question) {
      return questionIds.includes(question.get('_id'));
    });
  };

  QuestionsStore.prototype.getQuestionsCount = function(questionsCollectionType) {
    if (questionsCollectionType == null) {
      questionsCollectionType = 'regular';
    }
    return this.getQuestions(questionsCollectionType).size;
  };

  QuestionsStore.prototype.getQuestionsWithEtd = function() {
    return this.state.get('questions').filter(filterOutAdoloped).filter(function(q) {
      return q.get('recommendationIds', Immutable.List()).size;
    });
  };

  QuestionsStore.prototype.getQuestionModel = function(qId) {
    if (qId == null) {
      qId = mediator.currentQuestionId;
    }
    if (qId == null) {
      return null;
    }
    return new Question(this.getQuestion(qId).toJS());
  };

  QuestionsStore.prototype.getIsofQuestionModel = function() {
    return getQuestionIsofData(this.getQuestionModel(), mediator.project.get('name'));
  };

  QuestionsStore.prototype.getDataForTable = function(tableName, outcomes, qId) {
    var question;
    if (qId == null) {
      qId = mediator.currentQuestionId;
    }
    question = this.getQuestion(qId);
    if (question == null) {
      return null;
    }
    switch (tableName) {
      case 'sof_short':
      case 'relative_importance':
        return getDataForSofShort(question, outcomes);
      case 'l1_sof':
      case 'l2_sof':
      case 'evidence_short':
        return getDataForDxTable(question, tableName);
      default:
        return question;
    }
  };

  QuestionsStore.prototype.getAllOutcomes = function(qId) {
    var question;
    if (qId == null) {
      qId = mediator.currentQuestionId;
    }
    question = this.getQuestion(qId);
    if (question == null) {
      return null;
    }
    return question.get('outcomes');
  };

  QuestionsStore.prototype.getCriticalOutcomes = function(qId) {
    var _ref5;
    if (qId == null) {
      qId = mediator.currentQuestionId;
    }
    return (_ref5 = this.getAllOutcomes(qId)) != null ? _ref5.filter(function(outcome) {
      var importance;
      importance = outcome.get('importance');
      return importance >= 7 && importance <= 9;
    }) : void 0;
  };

  QuestionsStore.prototype.isFetching = function() {
    return this.state.getIn(['meta', 'fetching']);
  };

  QuestionsStore.prototype.getLastAddedQuestionId = function() {
    return this.state.getIn(['meta', 'lastAddedQuestionid']);
  };

  QuestionsStore.prototype.getDeletedQuestionDoc = function(questionId) {
    return this.state.getIn(['meta', 'deletedQuestions', questionId]);
  };

  QuestionsStore.prototype.getDeletedRecommendationDocs = function(questionId) {
    return this.state.getIn(['meta', 'deletedRecommendationDocs', questionId]);
  };

  QuestionsStore.prototype.getDeletedOverReviewDoc = function(questionId) {
    return this.state.getIn(['meta', 'deletedOverReviewDocs', questionId]);
  };

  QuestionsStore.prototype.getQuestionEditState = function(qId) {
    var editingQuestion;
    editingQuestion = this.state.getIn(['meta', 'editingQuestion']);
    if (qId === editingQuestion.get('questionId')) {
      return editingQuestion;
    } else {
      return null;
    }
  };

  QuestionsStore.prototype.getEditingQuestionId = function() {
    return this.state.getIn(['meta', 'editingQuestion', 'questionId']);
  };

  QuestionsStore.prototype.getQuestionType = function(questionId) {
    var questionDocType, _ref5;
    questionDocType = this.state.getIn(['questions', questionId, 'docType']);
    return (_ref5 = QUESTION_TYPES_BY_QUESTION_DOC_TYPE[questionDocType]) != null ? _ref5 : 'regular';
  };

  QuestionsStore.prototype.getOverReviewModuleStateByQuestion = function() {
    return this.state.getIn(['meta', 'overReviewModuleStateByQuestion']);
  };

  QuestionsStore.prototype.getQuestionOverReviewModuleState = function(qId) {
    return this.state.getIn(['meta', 'overReviewModuleStateByQuestion', qId]);
  };

  QuestionsStore.prototype.isQuestionBeingDuplicated = function() {
    return this.state.getIn(['meta', 'isQuestionBeingDuplicated'], false);
  };

  QuestionsStore.prototype.isQuestionBeingAdded = function() {
    return this.state.getIn(['meta', 'isQuestionBeingAdded'], false);
  };

  QuestionsStore.prototype.isMdgCreateQuestionDialogOpen = function() {
    return this.state.getIn(['mdgCreateQuestionDialog', 'isOpen']);
  };

  QuestionsStore.prototype.isMdgCreateQuestionDialogCreating = function() {
    return this.state.getIn(['mdgCreateQuestionDialog', 'isCreating']);
  };

  QuestionsStore.prototype.getMdgCreateQuestionDialogQuestionData = function() {
    return this.state.getIn(['mdgCreateQuestionDialog', 'questionData']);
  };

  return QuestionsStore;

})();

module.exports = alt.createStore(immutable(QuestionsStore), 'QuestionsStore');
