IconButton = require 'components/common/icon_button'
TextAreaWithApply = require 'components/common/text_area_with_apply'

MultipleInputs = createReactClass
  displayName: 'MultipleInputs'

  propTypes:
    value: PropTypes.arrayOf(PropTypes.string).isRequired
    onApply: PropTypes.func.isRequired
    addValueLabel: PropTypes.string.isRequired
    placeholder: PropTypes.string
    initialValue: PropTypes.arrayOf(PropTypes.string)

  getDefaultProps: ->
    placeholder: ''
    initialValue: []

  onAddValue: ->
    { onApply, value } = @props
    currentValue = value ? []
    onApply currentValue.concat ''

  onTextAreaApply: (idx) -> (newValue) =>
    { onApply, value } = @props
    value[idx] = newValue
    onApply value

  onDeleteValue: (idx) -> =>
    { onApply, value } = @props
    value.splice idx, 1
    onApply value

  render: ->
    { addValueLabel, placeholder, value, initialValue } = @props

    values = if _.isEmpty(value)
      unless _.isEmpty(initialValue)
        initialValue
    else
      value

    values

    <div className="multiple-inputs">
      {_.map values, (content, idx) =>
        <div className="multiple-inputs__input" key={idx}>
          <div className="input__textarea">
            <TextAreaWithApply
              content={content}
              onApply={@onTextAreaApply(idx)}
              placeholder={placeholder}
            />
          </div>
          <div>
            <IconButton
              className="ml-10"
              iconName="cross"
              onClick={@onDeleteValue(idx)}
            />
          </div>
        </div>
      }
      <div className="multiple-inputs__add">
        <span className="add-link" onClick={@onAddValue}>
          + {addValueLabel}
        </span>
      </div>
    </div>

module.exports = MultipleInputs
