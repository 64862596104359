var TASKS, TASKS_MAP;

TASKS = [require('lib/project_tasks/remove_coi'), require('lib/project_tasks/remove_consensus'), require('lib/project_tasks/create_adolopment_etd'), require('lib/project_tasks/update_question_after_revman_web_import'), require('lib/project_tasks/update_metadoc_after_revman_web_import'), require('lib/project_tasks/update_project_to_latest_data_version')];

TASKS_MAP = _.reduce(TASKS, function(acc, TaskClass) {
  var task;
  task = new TaskClass;
  acc[task.taskId] = task;
  return acc;
}, {});

module.exports = TASKS_MAP;
