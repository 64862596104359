var CADTH_GUIDELINE_FORM, FORM_MAPPER, KEY_MESSAGE_RECOMMENDATION_FORM_ITEMS, exportConclusions, exportGuidelines, exportInterventions, exportJustification, exportMetadata, exportPopulations, exportQuestionAndRecommendation, get, getGrouppedTagsFromQuestion, gradeProCodeToWHOCode, renderEditorValue, _ref;

CADTH_GUIDELINE_FORM = require('lib/cadth_guidelines_helper').CADTH_GUIDELINE_FORM;

_ref = require('lib/services/covid_exporter/utils'), exportConclusions = _ref.exportConclusions, exportJustification = _ref.exportJustification, gradeProCodeToWHOCode = _ref.gradeProCodeToWHOCode, renderEditorValue = _ref.renderEditorValue;

get = require('lodash').get;

getGrouppedTagsFromQuestion = require('lib/question_tags_helper').getGrouppedTagsFromQuestion;

KEY_MESSAGE_RECOMMENDATION_FORM_ITEMS = require('lib/key_message_form_helper').KEY_MESSAGE_RECOMMENDATION_FORM_ITEMS;

FORM_MAPPER = {
  'evidenceTablesUrl': 'evidenceTableUrl',
  'guidelines': 'guideline'
};

exportInterventions = function(tags) {
  var comparisonTags, interventionTags, tagsForInterventions;
  interventionTags = tags.get('intervention', Immutable.List()).valueSeq();
  comparisonTags = tags.get('comparison', Immutable.List()).valueSeq();
  tagsForInterventions = interventionTags.concat(comparisonTags);
  return _.chain(tagsForInterventions.toJS()).filter(function(tag) {
    return tag.included === true;
  }).map(function(tag, idx) {
    var _ref1;
    if (!tag.name) {
      return void 0;
    }
    return {
      '@id': "_:int" + (idx + 1),
      '@type': 'CadthIntervention',
      'name': tag.name,
      'code': _.chain((_ref1 = tag.codes) != null ? _ref1 : {}).values().map(gradeProCodeToWHOCode).value()
    };
  }).compact().value();
};

exportPopulations = function(tags) {
  return _.chain(tags.get('population', Immutable.List()).valueSeq().toJS()).filter(function(tag) {
    return tag.included === true;
  }).map(function(tag, idx) {
    var _ref1;
    if (!tag.name) {
      return void 0;
    }
    return {
      '@id': "_:pop" + (idx + 1),
      '@type': 'CadthPopulation',
      'name': tag.name,
      'code': _.chain((_ref1 = tag.codes) != null ? _ref1 : {}).values().map(gradeProCodeToWHOCode).value()
    };
  }).compact().value();
};

exportGuidelines = function(value, guidelines) {
  return _.chain(value).map(function(_arg) {
    var fieldsFromGuideline, guideline, guidelineId, quality;
    guidelineId = _arg.guidelineId, quality = _arg.quality;
    guideline = guidelines[guidelineId];
    if (!guideline) {
      return void 0;
    }
    fieldsFromGuideline = _.pick(guideline, _.pluck(CADTH_GUIDELINE_FORM, 'fieldKey'));
    return _.extend({
      quality: quality
    }, fieldsFromGuideline);
  }).compact().value();
};

exportMetadata = function(recommendation, guidelines) {
  return _.chain(KEY_MESSAGE_RECOMMENDATION_FORM_ITEMS.main).map(function(_arg) {
    var fieldKey, hasBooleans, key, parsed, value;
    fieldKey = _arg.fieldKey, hasBooleans = _arg.hasBooleans;
    value = get(recommendation, "templateData.conclusions.sections.recommendation." + fieldKey);
    if (hasBooleans) {
      if (value == null) {
        return void 0;
      }
    } else {
      if (!Boolean(value) || _.isEmpty(value)) {
        return void 0;
      }
    }
    value = (function() {
      switch (fieldKey) {
        case 'recommendationIntent':
          return ['all'].concat(value);
        case 'evidenceTablesUrl':
          return _.compact(value);
        case 'publicationYear':
          parsed = parseInt(value);
          if (parsed === null || _.isNaN(parsed)) {
            return void 0;
          } else {
            return parsed;
          }
          break;
        case 'guidelines':
          return exportGuidelines(value, guidelines);
        default:
          return value;
      }
    })();
    key = fieldKey in FORM_MAPPER ? FORM_MAPPER[fieldKey] : fieldKey;
    return [key, value];
  }).compact().object().value();
};

exportQuestionAndRecommendation = function(question, recommendations, guidelines) {
  var comparisonInQuestion, comparisonTags, conclusions, hasComparison, interventions, interventionsInQuestion, populations, rec, recommendation, recommendationId, recommendationText, tags;
  recommendationId = _.first(question['recommendationIds']);
  recommendation = get(recommendations, recommendationId);
  if (!recommendation) {
    return void 0;
  }
  recommendationText = renderEditorValue(get(recommendation, 'templateData.conclusions.sections.keyMessage.content'));
  if (!recommendationText) {
    return void 0;
  }
  tags = getGrouppedTagsFromQuestion(Immutable.fromJS(question));
  interventions = exportInterventions(tags);
  populations = exportPopulations(tags);
  conclusions = exportConclusions(recommendation, ['keyMessage']);
  conclusions['sections']['justification'] = exportJustification(recommendation);
  comparisonTags = tags.get('comparison', Immutable.List()).valueSeq().toJS();
  hasComparison = !(_.chain(comparisonTags).pluck('name').compact().isEmpty().value());
  interventionsInQuestion = hasComparison ? _.initial(interventions) : interventions;
  comparisonInQuestion = hasComparison ? [
    {
      '@id': _.last(interventions)['@id']
    }
  ] : void 0;
  rec = {
    '@id': question['_id'],
    '@type': 'CadthKeyMessage',
    'intervention': interventions,
    'interventionDescription': _.pluck(interventions, 'name').join(', '),
    'population': populations,
    'populationDescription': _.pluck(populations, 'name').join(', '),
    'question': [
      {
        '@id': '_:q1',
        '@type': 'CadthQuestion',
        'comparison': comparisonInQuestion,
        'intervention': _.map(interventionsInQuestion, function(i) {
          return {
            '@id': i['@id']
          };
        }),
        'population': _.map(populations, function(p) {
          return {
            '@id': p['@id']
          };
        })
      }
    ],
    'recommendation': {
      'value': recommendationText,
      'conclusions': conclusions
    }
  };
  return _.extend({}, rec, exportMetadata(recommendation, guidelines));
};

module.exports = exportQuestionAndRecommendation;
