var IsofEmbeddableLinkDialog, IsofExportView, QuestionExportView, getQuestionIsofData, mediator, template, toImageFile,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

QuestionExportView = require('views/question_export_view');

IsofEmbeddableLinkDialog = require('views/isof_embeddable_link_dialog');

template = require('views/templates/isof_export');

toImageFile = require('lib/html_to_image').toImageFile;

getQuestionIsofData = require('lib/questions_helper').getQuestionIsofData;

mediator = require('mediator');

IsofExportView = (function(_super) {
  __extends(IsofExportView, _super);

  function IsofExportView() {
    return IsofExportView.__super__.constructor.apply(this, arguments);
  }

  IsofExportView.prototype.id = 'isof-export-dialog';

  IsofExportView.prototype.template = template;

  IsofExportView.prototype.shortcuts = {
    'esc': 'dispose'
  };

  IsofExportView.prototype.initialize = function() {
    IsofExportView.__super__.initialize.apply(this, arguments);
    return this.delegate('change', 'input[name="exportType"]', this.toggleDownloadButton);
  };

  IsofExportView.prototype.getTemplateData = function() {
    var viewTypeName;
    viewTypeName = this._getViewType().name;
    return _(IsofExportView.__super__.getTemplateData.apply(this, arguments)).extend({
      title: $.t('projects:export_dialog.export_table_title', {
        viewName: $.t("es:outcome_view_type." + this.typeToi18nKeyMap[viewTypeName])
      })
    });
  };

  IsofExportView.prototype.exportIsofToImage = function() {
    return toImageFile(document.querySelector('.isof-view')).then(this.dispose);
  };

  IsofExportView.prototype._getCheckedExportFormat = function() {
    return this.$('input[name="exportType"]:checked').val();
  };

  IsofExportView.prototype.toggleDownloadButton = function() {
    return this.$('button.download').prop('disabled', this._getCheckedExportFormat() == null);
  };

  IsofExportView.prototype.download = function() {
    switch (this._getCheckedExportFormat()) {
      case 'image':
        return this.exportIsofToImage();
      case 'externalLink':
        return mediator.services.presentationService.exportIsofPresentation(getQuestionIsofData(this.model, mediator.project.get('name')), this.model.get('_id'), this.model.get('question')).then(this.dispose);
      case 'externalEmbeddable':
        return mediator.services.presentationService.getEmbeddableIsofLink(getQuestionIsofData(this.model, mediator.project.get('name')), this.model.get('_id'), this.model.get('question')).then((function(_this) {
          return function(embeddableLink) {
            var embeddableLinkView;
            embeddableLinkView = new IsofEmbeddableLinkDialog({
              link: embeddableLink,
              closeBtn: true
            });
            _this.subview('isofEmbeddableLinkDialog', embeddableLinkView);
            return embeddableLinkView.promise().then(_this.dispose);
          };
        })(this));
    }
  };

  return IsofExportView;

})(QuestionExportView);

module.exports = IsofExportView;
