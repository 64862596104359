module.exports = function () { return {
  "question": {
      "docId": "",
      "type": "",
      "sections": {
          "intent": {
              "name": "Kavatsus",
              "content": ""
          },
          "age": {
              "name": "Vanus",
              "content": ""
          },
          "population": {
              "name": "Sihtrühm",
              "content": ""
          },
          "intervention": {
              "name": "Sekkumine",
              "content": ""
          },
          "comparison": {
              "name": "Võrdlus",
              "content": ""
          },
          "mainOutcomes": {
              "name": "Peamised tulemusnäitajad",
              "content": ""
          },
          "setting": {
              "name": "Kontekst",
              "content": ""
          },
          "perspective": {
              "name": "Vaatenurk",
              "content": ""
          },
          "background": {
              "name": "Taust",
              "content": ""
          },
          "problem": {
              "name": "Probleem",
              "content": ""
          },
          "option": {
              "name": "Võimalus",
              "content": ""
          },
          "purpose": {
              "name": "Testi eesmärk",
              "content": ""
          },
          "role": {
              "name": "Testi ülesanne",
              "content": ""
          },
          "linkedTreatments": {
              "name": "Seotud raviviisid",
              "content": ""
          },
          "anticipatedOutcomes": {
              "name": "Eeldatavad tulemusnäitajad",
              "content": ""
          },
          "requirements": {
              "name": "Requirements",
              "content": ""
          },
          "subgroups": {
              "name": "Alarühmad",
              "content": ""
          },
          "coi": {
              "name": "Huvide konflikt",
              "content": ""
          }
      }
  },
  "assessment": {
      "sections": {
          "problem": {
              "name": "Probleem",
              "criterionId": "problem",
              "researchEvidenceId": "problem",
              "additionalConsiderationId": "problem"
          },
          "desirableEffects": {
              "name": "Soovitud mõju",
              "criterionId": "desirableEffects",
              "researchEvidenceId": "desirableEffects",
              "additionalConsiderationId": "desirableEffects"
          },
          "undesirableEffects": {
              "name": "Soovimatu mõju",
              "criterionId": "undesirableEffects",
              "researchEvidenceId": "undesirableEffects",
              "additionalConsiderationId": "undesirableEffects"
          },
          "certaintyOfEvidence": {
              "name": "Tõendatuse kindlus",
              "criterionId": "certaintyOfEvidence",
              "researchEvidenceId": "certaintyOfEvidence",
              "additionalConsiderationId": "certaintyOfEvidence"
          },
          "values": {
              "name": "Väärtushinnangud",
              "criterionId": "values",
              "researchEvidenceId": "values",
              "additionalConsiderationId": "values"
          },
          "balanceOfEffects": {
              "name": "Mõjude tasakaal",
              "criterionId": "balanceOfEffects",
              "researchEvidenceId": "balanceOfEffects",
              "additionalConsiderationId": "balanceOfEffects"
          },
          "resourcesRequired": {
              "name": "Vajaminevad ressursid",
              "criterionId": "resourcesRequired",
              "researchEvidenceId": "resourcesRequired",
              "additionalConsiderationId": "resourcesRequired"
          },
          "certaintyOfEvidenceOfRequiredResources": {
              "name": "Vajaminevate ressursside tõendatuse kindlus",
              "criterionId": "certaintyOfEvidenceOfRequiredResources",
              "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources",
              "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources"
          },
          "costEffectiveness": {
              "name": "Kulutõhusus",
              "criterionId": "costEffectiveness",
              "researchEvidenceId": "costEffectiveness",
              "additionalConsiderationId": "costEffectiveness"
          },
          "equity": {
              "name": "Võrdsed võimalused",
              "criterionId": "equity",
              "researchEvidenceId": "equity",
              "additionalConsiderationId": "equity"
          },
          "acceptability": {
              "name": "Vastuvõetavus",
              "criterionId": "acceptability",
              "researchEvidenceId": "acceptability",
              "additionalConsiderationId": "acceptability"
          },
          "feasibility": {
              "name": "Teostatavus",
              "criterionId": "feasibility",
              "researchEvidenceId": "feasibility",
              "additionalConsiderationId": "feasibility"
          },
          "testAccuracy": {
              "name": "Testi täpsus",
              "criterionId": "testAccuracy",
              "researchEvidenceId": "testAccuracy",
              "additionalConsiderationId": "testAccuracy"
          },
          "certaintyOfEvidenceOfTestAccuracy": {
              "name": "Testi täpsuse tõendatus ",
              "criterionId": "certaintyOfEvidenceOfTestAccuracy",
              "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy",
              "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy"
          },
          "certaintyOfEvidenceOfTestEffects": {
              "name": "Testi mõju tõendatuse kindlus ",
              "criterionId": "certaintyOfEvidenceOfTestEffects",
              "researchEvidenceId": "certaintyOfEvidenceOfTestEffects",
              "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects"
          },
          "certaintyOfEvidenceOfManagementEffects": {
              "name": "Ravi mõju tõendatuse kindlus",
              "criterionId": "certaintyOfEvidenceOfManagementEffects",
              "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects",
              "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects"
          },
          "certaintyOfEvidenceOfTestResult": {
              "name": "Kui kindel võib olla testi tulemuste / ravi mõju tõendatuses",
              "criterionId": "certaintyOfEvidenceOfTestResult",
              "researchEvidenceId": "certaintyOfEvidenceOfTestResult",
              "additionalConsiderationId": "certaintyOfEvidenceOfTestResult"
          },
          "certaintyOfEffects": {
              "name": "Toimete kindlus ",
              "criterionId": "certaintyOfEffects",
              "researchEvidenceId": "certaintyOfEffects",
              "additionalConsiderationId": "certaintyOfEffects"
          },
          "balanceOfHealthBenefitsAndHarms": {
              "name": "Balance of health benefits and harms",
              "criterionId": "balanceOfHealthBenefitsAndHarms",
              "researchEvidenceId": "balanceOfHealthBenefitsAndHarms",
              "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms"
          },
          "humanRights": {
              "name": "Human rights",
              "criterionId": "humanRights",
              "researchEvidenceId": "humanRights",
              "additionalConsiderationId": "humanRights"
          },
          "socioCulturalAcceptability": {
              "name": "Socio-cultural acceptability",
              "criterionId": "socioCulturalAcceptability",
              "researchEvidenceId": "socioCulturalAcceptability",
              "additionalConsiderationId": "socioCulturalAcceptability"
          },
          "healthEquityEqualityAndNonDiscrimination": {
              "name": "Health equity, equality, and non-discrimination",
              "criterionId": "healthEquityEqualityAndNonDiscrimination",
              "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination",
              "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination"
          },
          "societalImplications": {
              "name": "Societal implications",
              "criterionId": "societalImplications",
              "researchEvidenceId": "societalImplications",
              "additionalConsiderationId": "societalImplications"
          },
          "financialAndEconomicConsiderations": {
              "name": "Financial and economic considerations",
              "criterionId": "financialAndEconomicConsiderations",
              "researchEvidenceId": "financialAndEconomicConsiderations",
              "additionalConsiderationId": "financialAndEconomicConsiderations"
          },
          "feasibilityAndHealthSystemConsiderations": {
              "name": "Feasibility and health system considerations",
              "criterionId": "feasibilityAndHealthSystemConsiderations",
              "researchEvidenceId": "feasibilityAndHealthSystemConsiderations",
              "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations"
          },
          "qualityOfEvidence": {
              "name": "Quality of Evidence",
              "criterionId": "qualityOfEvidence",
              "researchEvidenceId": "qualityOfEvidence",
              "additionalConsiderationId": "qualityOfEvidence"
          },
          "additionalSection": {
              "name": "Custom criterion",
              "criterionId": "additionalSection",
              "researchEvidenceId": "additionalSection",
              "additionalConsiderationId": "additionalSection"
          },
          "scientificallySound": {
              "name": "SCIENTIFICALLY SOUND (CERTAINTY OF EVIDENCE)",
              "criterionId": "scientificallySound",
              "researchEvidenceId": "scientificallySound",
              "additionalConsiderationId": "scientificallySound"
          },
          "relevantDirect": {
              "name": "RELEVANT (DIRECT)",
              "criterionId": "relevantDirect",
              "researchEvidenceId": "relevantDirect",
              "additionalConsiderationId": "relevantDirect"
          },
          "responsive": {
              "name": "RESPONSIVE",
              "criterionId": "responsive",
              "researchEvidenceId": "responsive",
              "additionalConsiderationId": "responsive"
          },
          "feasible": {
              "name": "FEASIBLE",
              "criterionId": "feasible",
              "researchEvidenceId": "feasible",
              "additionalConsiderationId": "feasible"
          },
          "problemEOHR": {
              "name": "Problem",
              "criterionId": "problemEOHR",
              "researchEvidenceId": "problem",
              "additionalConsiderationId": "problem"
          },
          "desirableEffectsEOHR": {
              "name": "Desirable Effects",
              "criterionId": "desirableEffectsEOHR",
              "researchEvidenceId": "desirableEffects",
              "additionalConsiderationId": "desirableEffects"
          },
          "undesirableEffectsEOHR": {
              "name": "Undesirable Effects",
              "criterionId": "undesirableEffectsEOHR",
              "researchEvidenceId": "undesirableEffects",
              "additionalConsiderationId": "undesirableEffects"
          },
          "certaintyOfEvidenceEOHR": {
              "name": "Certainty of Evidence",
              "criterionId": "certaintyOfEvidenceEOHR",
              "researchEvidenceId": "certaintyOfEvidence",
              "additionalConsiderationId": "certaintyOfEvidence"
          },
          "valuesEOHR": {
              "name": "Values",
              "criterionId": "valuesEOHR",
              "researchEvidenceId": "values",
              "additionalConsiderationId": "values"
          },
          "balanceOfEffectsEOHR": {
              "name": "Balance of Effects",
              "criterionId": "balanceOfEffectsEOHR",
              "researchEvidenceId": "balanceOfEffects",
              "additionalConsiderationId": "balanceOfEffects"
          },
          "resourcesRequiredEOHR": {
              "name": "Resources Required",
              "criterionId": "resourcesRequiredEOHR",
              "researchEvidenceId": "resourcesRequired",
              "additionalConsiderationId": "resourcesRequired"
          },
          "certaintyOfEvidenceOfRequiredResourcesEOHR": {
              "name": "Certainty of Evidence of Required Resources",
              "criterionId": "certaintyOfEvidenceOfRequiredResourcesEOHR",
              "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources",
              "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources"
          },
          "costEffectivenessEOHR": {
              "name": "Cost Effectiveness",
              "criterionId": "costEffectivenessEOHR",
              "researchEvidenceId": "costEffectiveness",
              "additionalConsiderationId": "costEffectiveness"
          },
          "equityEOHR": {
              "name": "Equity",
              "criterionId": "equityEOHR",
              "researchEvidenceId": "equity",
              "additionalConsiderationId": "equity"
          },
          "acceptabilityEOHR": {
              "name": "Acceptability",
              "criterionId": "acceptabilityEOHR",
              "researchEvidenceId": "acceptability",
              "additionalConsiderationId": "acceptability"
          },
          "feasibilityEOHR": {
              "name": "Feasibility",
              "criterionId": "feasibilityEOHR",
              "researchEvidenceId": "feasibility",
              "additionalConsiderationId": "feasibility"
          },
          "netBalance": {
              "name": "Net balance",
              "criterionId": "netBalance",
              "researchEvidenceId": "netBalance",
              "additionalConsiderationId": "netBalance"
          }
      },
      "criteria": {
          "problem": {
              "description": "Kas probleem on prioriteetne?",
              "info": "Mida tõsisem või pakilisem on probleem, seda tähtsam on kõnealusele käsitluse (sh ravi) küsimusele lahendus leida.",
              "options": [
                  {
                      "text": "Ei",
                      "value": "no"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "jah",
                      "value": "yes"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Kas probleemi tagajärjed on tõsised (st tuntavad või olulised võimalike hüvede või säästu osas)?",
                          "options": [
                              {
                                  "text": "Ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas probleem on pakiline?",
                          "options": [
                              {
                                  "text": "Ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas see on tunnustatud prioriteet (nt põhineb poliitilisel või põhimõttelisel otsusel)?",
                          "options": [
                              {
                                  "text": "Ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              }
          },
          "netBalance": {
              "description": "Net Balance",
              "info": "What is the overall net balance of effects?",
              "options": [
                  {
                      "text": "Large net desirable",
                      "value": "large_net_desirable"
                  },
                  {
                      "text": "Moderate net desirable",
                      "value": "moderate_net_desirable"
                  },
                  {
                      "text": "Small net desirable",
                      "value": "small_net_desirable"
                  },
                  {
                      "text": "Null net balance",
                      "value": "null_net_balance"
                  },
                  {
                      "text": "Small net undesirable",
                      "value": "small_net_undesirable"
                  },
                  {
                      "text": "Moderate net undesirable",
                      "value": "moderate_net_undesirable"
                  },
                  {
                      "text": "Large net undesirable",
                      "value": "large_net_undesirable"
                  }
              ]
          },
          "desirableEffects": {
              "description": "Kui suur on eeldatav soovitud mõju?",
              "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
              "info": "Kui suur on kokkuvõttes sekkumise soovitud mõju, võttes arvesse nii tulemusnäitajate olulisust (seda, kuivõrd oluliseks neid peetakse) kui sekkumise mõju suurust (kui suure tõenäosusega saab inimene sekkumisest kasu või kui suur see kasu on)?",
              "options": [
                  {
                      "text": "Tühine",
                      "value": "trivial"
                  },
                  {
                      "text": "Väike",
                      "value": "small"
                  },
                  {
                      "text": "Mõõdukas",
                      "value": "moderate"
                  },
                  {
                      "text": "Suur",
                      "value": "large"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "outcomes",
                  "questions": [
                      {
                          "question": "Kui tuntav on eeldatav toime (erinevus) iga peamise tulemusnäitaja osas, mille puhul soovitud toime esineb?",
                          "options": [
                              {
                                  "text": "Tühine",
                                  "value": "trivial"
                              },
                              {
                                  "text": "Väike",
                                  "value": "small"
                              },
                              {
                                  "text": "Mõõdukas",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Suur",
                                  "value": "large"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ]
                      }
                  ]
              },
              "ratingOptions": [
                  "most_effective",
                  "intermediate_effectiveness",
                  "least_effective"
              ]
          },
          "undesirableEffects": {
              "description": "Kui suur on eeldatav soovimatu mõju?",
              "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
              "info": "Kui suur on kokkuvõttes sekkumise soovimatu mõju, võttes arvesse nii tulemusnäitajate olulisust (seda, kuivõrd oluliseks neid peetakse) kui sekkumise mõju suurust (kui suure tõenäosusega saab inimene sekkumisest kasu või kui suur see kasu on)?",
              "options": [
                  {
                      "text": "Suur",
                      "value": "trivial"
                  },
                  {
                      "text": "Mõõdukas",
                      "value": "small"
                  },
                  {
                      "text": "Väike",
                      "value": "moderate"
                  },
                  {
                      "text": "Tühine",
                      "value": "large"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "outcomes",
                  "questions": [
                      {
                          "question": "Kui tuntav on eeldatav toime (erinevus) iga peamise tulemusnäitaja osas, mille puhul soovimatu toime esineb?",
                          "options": [
                              {
                                  "text": "Suur",
                                  "value": "trivial"
                              },
                              {
                                  "text": "Mõõdukas",
                                  "value": "small"
                              },
                              {
                                  "text": "Väike",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Tühine",
                                  "value": "large"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ]
                      }
                  ]
              },
              "ratingOptions": [
                  "least_harmful",
                  "intermediate",
                  "more_harmful"
              ]
          },
          "certaintyOfEvidence": {
              "description": "Kui kindel võib kokkuvõttes olla sekkumise mõju tõendatuses? ",
              "ratingDescription": "What is the overall certainty of the evidence of effects for each intervention?",
              "info": "Millise kindluse annab tõendusmaterjal sekkumise mõju kohta kõigi kriitiliste tulemusnäitajate osas ehk kuivõrd tõenäoline on, et sekkumise tegelik mõju võib (mõne tulemusnäitaja osas) teadusuuringutes nähtust erineda sedavõrd, et see mõjutab otsuse langetamist?",
              "options": [
                  {
                      "text": "Väga madal",
                      "value": "very_low"
                  },
                  {
                      "text": "madal",
                      "value": "low"
                  },
                  {
                      "text": "Mõõdukas",
                      "value": "moderate"
                  },
                  {
                      "text": "väga",
                      "value": "high"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "kaasatud uuringud puuduvad",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": ""
              }
          },
          "values": {
              "description": "Kas see, kuivõrd inimesed (inimeste erinevad alarühmad) peamisi tulemusi väärtustavad, varieerub või kui ebakindlad me nende hinnangutes oleme?",
              "info": "Kuivõrd inimesed iga peamist tulemusnäitajat väärtustavad? Kas ebakindlus selles osas, kuivõrd iga erinevat tulemusnäitajat väärtustatakse või selles, kuivõrd varieerub erinevate inimeste hinnang erinevate tulemusnäitajate olulisusele, on piisavalt suur, et viia (mitme) erineva otsuseni?",
              "options": [
                  {
                      "text": "oluline ebakindlus või varieeruvus",
                      "value": "important_uncertainty"
                  },
                  {
                      "text": "võimalik oluline ebakindlus või varieeruvus",
                      "value": "possible_important"
                  },
                  {
                      "text": "oluline ebakindlus või varieeruvus tõenäoliselt puudub",
                      "value": "probably_no_important"
                  },
                  {
                      "text": "oluline ebakindlus või varieeruvus puudub",
                      "value": "no_important"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "outcomes",
                  "questions": [
                      {
                          "question": "Kui ebakindel on see, kuivõrd inimesed peamisi tulemusnäitajaid väärtustavad?",
                          "options": [
                              {
                                  "text": "Oluline ebakindlus",
                                  "value": "important"
                              },
                              {
                                  "text": "võimalik oluline ebakindlus",
                                  "value": "probably_important"
                              },
                              {
                                  "text": "Oluline ebakindlus arvatavasti puudub",
                                  "value": "probably_no_important"
                              },
                              {
                                  "text": "Oluline ebakindlus puudub",
                                  "value": "no_important"
                              }
                          ]
                      }
                  ]
              }
          },
          "balanceOfEffects": {
              "description": "Kas sekkumise soovitud ja soovimatu mõju vahekord viitab sekkumise või võrdlus(tegevuse) ülekaalule?",
              "ratingDescription": "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?",
              "info": "Milline on soovitud ja soovimatu mõju tasakaal, võttes arvesse nii seda, kuivõrd inimesed erinevaid peamisi tulemusnäitajaid väärtustavad, kui suur on sekkumise arvatav/hinnanguline soovitud ja soovimatu mõju (kõigi tulemusnäitajate lõikes), kui suur on kindlus sekkumise mõju hinnangu(te)s, diskonteerimismääras, inimeste riskivalmiduses.",
              "options": [
                  {
                      "text": "soosib võrdlust",
                      "value": "favors_comparison"
                  },
                  {
                      "text": "pigem soosib võrdlust",
                      "value": "probably_favors_comparison"
                  },
                  {
                      "text": "ei soosi sekkumist ega võrdlust",
                      "value": "doesnt_favor_any"
                  },
                  {
                      "text": "pigem soosib sekkumist",
                      "value": "probably_favors_intervention"
                  },
                  {
                      "text": "soosib sekkumist",
                      "value": "favors_intervention"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "additionalInfos": [
                      {
                          "description": "Selle kriteeriumi üksikasjalikud hindamised hõlmavad kõigi nelja eelneva kriteeriumi hindamisi:",
                          "criteriaAnswers": [
                              {
                                  "criterionId": "values"
                              },
                              {
                                  "criterionId": "certaintyOfEvidence"
                              },
                              {
                                  "criterionId": "desirableEffects"
                              },
                              {
                                  "criterionId": "undesirableEffects"
                              }
                          ]
                      },
                      {
                          "description": "Lisaks võivad töörühmad tahta arvesse võtta (ja vajadusel dokumenteerida) ulatust, mille võrra võivad järgmised kaalutlused mõjutada soovitud ja soovimatu mõju tasakaalu:",
                          "additions": [
                              "Kuivõrd väärtustavad inimesed tuleviku tulemusnäitajaid vähem, võrreldes praeguste tulemusnäitajatega (nende allahindlusmääraga)?",
                              "Inimeste suhtumine soovimatutesse toimetesse (kui riskivastased nad on).",
                              "Inimeste suhtumine soovitud toimetesse (kui riskialtid nad on)."
                          ]
                      }
                  ]
              },
              "ratingOptions": [
                  "best_balance",
                  "intermediate",
                  "worst_balance"
              ]
          },
          "resourcesRequired": {
              "description": "How large are the resource requirements (costs)?\"",
              "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
              "info": "Kui suur on sekkumiseks vajaminevate ressursside maksumus võrreldes võrdlus(tegevus)e ressursside maksumusega?",
              "options": [
                  {
                      "text": "suur kulu",
                      "value": "large_costs"
                  },
                  {
                      "text": "Mõõdukas kulu",
                      "value": "moderate_costs"
                  },
                  {
                      "text": "mittearvestatav kulu ja sääst",
                      "value": "negligible_costs_savings"
                  },
                  {
                      "text": "Mõõdukas sääst",
                      "value": "moderate_savings"
                  },
                  {
                      "text": "suur sääst",
                      "value": "large_savings"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "resources",
                  "questions": [
                      {
                          "question": "Kui suur on erinevus iga kasutatud ressursiühiku vahel, mille jaoks vajatakse vähem ressursse?",
                          "options": [
                              {
                                  "text": "Tühine",
                                  "value": "trivial"
                              },
                              {
                                  "text": "Väike",
                                  "value": "small"
                              },
                              {
                                  "text": "Mõõdukas",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Suur",
                                  "value": "large"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ]
                      },
                      {
                          "question": "Kui suur on erinevus iga kasutatud ressursiühiku vahel, mille jaoks vajatakse lisaressursse?",
                          "options": [
                              {
                                  "text": "Suur",
                                  "value": "large"
                              },
                              {
                                  "text": "Mõõdukas",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Väike",
                                  "value": "small"
                              },
                              {
                                  "text": "Tühine",
                                  "value": "trivial"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ]
                      }
                  ]
              },
              "ratingOptions": [
                  "less_costs",
                  "intermediate_costs",
                  "most_costs"
              ]
          },
          "certaintyOfEvidenceOfRequiredResources": {
              "description": "Milline on ressursivajaduse (kulude) tõendatusse aste?",
              "info": "Kui hea on tõendusmaterjal erinevate ressursside vajaduse ja nende maksumuse kohta?",
              "options": [
                  {
                      "text": "Väga madal",
                      "value": "very_low"
                  },
                  {
                      "text": "madal",
                      "value": "low"
                  },
                  {
                      "text": "Mõõdukas",
                      "value": "moderate"
                  },
                  {
                      "text": "väga",
                      "value": "high"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "kaasatud uuringud puuduvad",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "resources",
                  "questions": [
                      {
                          "question": "Kas kõik olulised ressursid, mis sekkumisel ja võrdlusel võivad erineda, on välja selgitatud?",
                          "options": [
                              {
                                  "text": "ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ]
                      },
                      {
                          "question": "Kui kindel võib olla nende ressursside maksumuses, mis sekkumisel ja võrdlusel võivad erineda?",
                          "options": [
                              {
                                  "text": "Oluline ebakindlus",
                                  "value": "important"
                              },
                              {
                                  "text": "võimalik oluline ebakindlus",
                                  "value": "probably_important"
                              },
                              {
                                  "text": "Oluline ebakindlus arvatavasti puudub",
                                  "value": "probably_no_important"
                              },
                              {
                                  "text": "Oluline ebakindlus puudub",
                                  "value": "no_important"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ]
                      },
                      {
                          "question": "Kas nende ressursside maksumuses, mis sekkumisel ja võrdlusel erineda võivad, on suur varieeruvus?",
                          "options": [
                              {
                                  "text": "Oluline varieeruvus",
                                  "value": "important"
                              },
                              {
                                  "text": "Võimalik oluline varieeruvus",
                                  "value": "probably_important"
                              },
                              {
                                  "text": "Oluline varieeruvus eeldatavalt puudub",
                                  "value": "probably_no_important"
                              },
                              {
                                  "text": "Oluline varieeruvus puudub",
                                  "value": "no_important"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ]
                      }
                  ]
              }
          },
          "costEffectiveness": {
              "description": "Kas sekkumise kulutõhusus soosib sekkumist või võrdlust?",
              "ratingDescription": "Which intervention does the cost effectiveness favor?",
              "info": "Kas sekkumine on kulutõhus, arvestades kulude määramatust või muutlikkust, netokasu määramatust või muutlikkust, tundlikkusanalüüse ning majandusliku hinnangu usaldusväärsust ja asjakohasust?",
              "options": [
                  {
                      "text": "soosib võrdlust",
                      "value": "favors_comparison"
                  },
                  {
                      "text": "pigem soosib võrdlust",
                      "value": "probably_favors_comparison"
                  },
                  {
                      "text": "ei soosi sekkumist ega võrdlust",
                      "value": "doesnt_favor_any"
                  },
                  {
                      "text": "pigem soosib sekkumist",
                      "value": "probably_favors_intervention"
                  },
                  {
                      "text": "soosib sekkumist",
                      "value": "favors_intervention"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "kaasatud uuringud puuduvad",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Kas kulutõhususe määr varieerub ühe muutujaga tundlikkusanalüüsides oluliselt?",
                          "options": [
                              {
                                  "text": "Ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "kaasatud uuringud puuduvad",
                                  "value": "no_included_studies"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas kulutõhususe määr varieerub mitme muutujaga tundlikkusanalüüsis oluliselt?",
                          "options": [
                              {
                                  "text": "Ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "kaasatud uuringud puuduvad",
                                  "value": "no_included_studies"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas kulutõhususe hinnangu aluseks olev majanduslik hindamine on usaldusväärne?",
                          "options": [
                              {
                                  "text": "Ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Väga tõsised piirangud",
                                  "value": "very_serious"
                              },
                              {
                                  "text": "Tõsised piirangud",
                                  "value": "serious"
                              },
                              {
                                  "text": "Tõsised piirangud puuduvad",
                                  "value": "no_serious"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "kaasatud uuringud puuduvad",
                                  "value": "no_included_studies"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas kulutõhususe hinnangu aluseks olev majanduslik hindamine on asjakohane ka käsitletavas olukorras?",
                          "options": [
                              {
                                  "text": "Ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Väga tõsised piirangud",
                                  "value": "very_serious"
                              },
                              {
                                  "text": "Tõsised piirangud",
                                  "value": "serious"
                              },
                              {
                                  "text": "Tõsised piirangud puuduvad",
                                  "value": "no_serious"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "kaasatud uuringud puuduvad",
                                  "value": "no_included_studies"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              },
              "ratingOptions": [
                  "best_cost_effectiveness",
                  "intermediate_cost_effectiveness",
                  "worst_cost_effectiveness"
              ]
          },
          "equity": {
              "description": "Kuivõrd sekkumine mõjutab tervisevõimaluste võrdsust?",
              "ratingDescription": "If recommended, which intervention would reduce health inequities the most?",
              "info": "Kas on põhjust arvata, et ebasoodsamas olukorras inimestel on sekkumise suhteline toime teistsugune või mõjutab nende inimeste erinev lähteolukord sekkumise tulemuslikkust või probleemi olulisust?",
              "options": [
                  {
                      "text": "vähendab võrdsust",
                      "value": "reduced"
                  },
                  {
                      "text": "tõenäoliselt vähendab võrdsust",
                      "value": "probably_reduced"
                  },
                  {
                      "text": "tõenäoliselt ei mõjuta võrdsust",
                      "value": "probably_no_impact"
                  },
                  {
                      "text": "tõenäoliselt suurendab võrdsust",
                      "value": "probably_increased"
                  },
                  {
                      "text": "suurendab võrdsust",
                      "value": "increased"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Kas selle probleemiga seoses on mingeid rahvastikurühmi, kes võivad selle sekkumise korral sattuda ebasoodsamasse või ebavõrdsemasse olukorda?",
                          "options": [
                              {
                                  "text": "Jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas on alust arvata, et selle sekkumise suhteline tõhusus võib vähemsoodsas ollukorras olevas rahvatikurühmas olla teistsugune?",
                          "options": [
                              {
                                  "text": "jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas erinevad algtingimused erinevates rahvastikurühmades võivad tingida selle, et sekkumise absoluutne tõhusus (sekkumise mõju) või probleemi olulisus on neis rahvastikurühmades erinev?",
                          "options": [
                              {
                                  "text": "jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas on mingeid olulisi asjaolusid, mida tuleks seda sekkumist ellu viies arvesse võtta, et võimalusel tagada ebavõrdsuse vähenemine või vähemalt ebavõrdsust mitte suurendada?",
                          "options": [
                              {
                                  "text": "jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              },
              "ratingOptions": [
                  "most_reduction",
                  "intermediate_reduction",
                  "less_reduction"
              ]
          },
          "acceptability": {
              "description": "Kas sekkumine on huvitatud osapooltele vastuvõetav?",
              "ratingDescription": "Kas sekkumine on huvitatud osapooltele vastuvõetav?",
              "info": "Kas on tõenäoline, et kõik huvitatud osapooled ei nõustu sekkumise kasude, kahjude ning kulude vahekorraga või ei ole selleks, et pikemas perspektiivis kasu saada, nõus lühemas perspektiivis kahjusid ja kulusid kandma? Kas on tõenäoline, et nad ei nõustu soovitud või soovimatu mõju (erinevate toimete) olulisusele antud hinnangutega või et nad ei nõustu diagnostilise sekkumisega eetilistel kaalutlustel?",
              "options": [
                  {
                      "text": "Ei",
                      "value": "no"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "jah",
                      "value": "yes"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Kas on huvitatud osapooli, kes ei oleks nõus hüvede, kahjude ja kulude jaotusega?",
                          "options": [
                              {
                                  "text": "jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas on huvitatud osapooli, kes ei lepiks lühemas perspektiivis kulude või soovimatute toimetega, ehkki tulevikus tooksid need soovitud toimeid (hüvesid)?",
                          "options": [
                              {
                                  "text": "jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas on huvitatud osapooli, kes ei nõustuks soovitud või soovimatutele toimetele omistatud väärtustega (põhjustel, mis puudutaksid neid isiklikult, või nende arusaama tõttu toimete suhtelisest olulisusest teistele)?",
                          "options": [
                              {
                                  "text": "jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas sekkumine (võimalus) mõjutaks negatiivselt inimeste autonoomiat?",
                          "options": [
                              {
                                  "text": "jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas on huvitatud osapooli, kes ei nõustuks sekkumisega (võimalusega) moraalsetel põhjustel, mis ei ole selle toime inimeste autonoomiale (teistel eetilistel kaalutlustel, nt mittepahasoovlikkus, heasoovlikkus või õiglus)?",
                          "options": [
                              {
                                  "text": "jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              },
              "ratingOptions": [
                  "best_acceptability",
                  "intermediate_acceptability",
                  "worst_acceptability"
              ]
          },
          "feasibility": {
              "description": "Kas sekkumine on teostatav?",
              "ratingDescription": "Which intervention is more feasible to implement?",
              "info": "Kas sekkumine on elluviidav/teostatav ja kas on võimalik kõrvaldada seda takistada võivad tegurid?",
              "options": [
                  {
                      "text": "Ei",
                      "value": "no"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "jah",
                      "value": "yes"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Kas sekkumine (võimalus) on teostatav?",
                          "options": [
                              {
                                  "text": "Ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Kas on olulisi takistusi, mis tõenäoliselt piiravad sekkumise (võimaluse) teostatavust või mida tuleb teostamisel arvesse võtta?",
                          "options": [
                              {
                                  "text": "Ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              },
              "ratingOptions": [
                  "most_feasible",
                  "intermediate_feasibility",
                  "least_feasible"
              ]
          },
          "testAccuracy": {
              "description": "Kui täpne on test?",
              "options": [
                  {
                      "text": "Väga ebatäpne",
                      "value": "very_inaccurate"
                  },
                  {
                      "text": "Ebatäpne",
                      "value": "inaccurate"
                  },
                  {
                      "text": "Täpne",
                      "value": "accurate"
                  },
                  {
                      "text": "Väga täpne",
                      "value": "very_accurate"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": ""
              }
          },
          "certaintyOfEvidenceOfTestAccuracy": {
              "description": "Kui kindel võib olla testi täpsuse tõendatuses?",
              "options": [
                  {
                      "text": "Väga madal",
                      "value": "very_low"
                  },
                  {
                      "text": "madal",
                      "value": "low"
                  },
                  {
                      "text": "Mõõdukas",
                      "value": "moderate"
                  },
                  {
                      "text": "väga",
                      "value": "high"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "kaasatud uuringud puuduvad",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": ""
              }
          },
          "certaintyOfEvidenceOfTestEffects": {
              "description": "Kui kindel võib olla kriitiliste või oluliste otseste hüvede, kõrvaltoimete või testikoormuse tõendatuses?",
              "options": [
                  {
                      "text": "Väga madal",
                      "value": "very_low"
                  },
                  {
                      "text": "madal",
                      "value": "low"
                  },
                  {
                      "text": "Mõõdukas",
                      "value": "moderate"
                  },
                  {
                      "text": "väga",
                      "value": "high"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "kaasatud uuringud puuduvad",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": ""
              }
          },
          "certaintyOfEvidenceOfManagementEffects": {
              "description": "Kui kindel võib olla testitulemustest lähtuva ravi mõju tõendatuses?",
              "options": [
                  {
                      "text": "Väga madal",
                      "value": "very_low"
                  },
                  {
                      "text": "madal",
                      "value": "low"
                  },
                  {
                      "text": "Mõõdukas",
                      "value": "moderate"
                  },
                  {
                      "text": "väga",
                      "value": "high"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "kaasatud uuringud puuduvad",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": ""
              }
          },
          "certaintyOfEvidenceOfTestResult": {
              "description": "Kui kindel on seos testi tulemuste ja raviotsuste vahel?",
              "options": [
                  {
                      "text": "Väga madal",
                      "value": "very_low"
                  },
                  {
                      "text": "madal",
                      "value": "low"
                  },
                  {
                      "text": "Mõõdukas",
                      "value": "moderate"
                  },
                  {
                      "text": "väga",
                      "value": "high"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "kaasatud uuringud puuduvad",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": ""
              }
          },
          "certaintyOfEffects": {
              "description": "Kui kindel võib kokkuvõttes olla testi mõju tõendatuses?",
              "info": "Kui hea näidustuse annab uurimistöö kõigi kriitiliste tulemuste tõenäoliste toimete kohta, st tõenäosuse kohta, et toimed erinevad piisavalt uuringutes tuvastatutest, nii et see võib mõjutada otsust diagnostilise sekkumise kohta?",
              "options": [
                  {
                      "text": "Väga madal",
                      "value": "very_low"
                  },
                  {
                      "text": "madal",
                      "value": "low"
                  },
                  {
                      "text": "Mõõdukas",
                      "value": "moderate"
                  },
                  {
                      "text": "väga",
                      "value": "high"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "kaasatud uuringud puuduvad",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": ""
              }
          },
          "balanceOfHealthBenefitsAndHarms": {
              "description": "Kas sekkumise soovitud ja soovimatu mõju vahekord viitab sekkumise või võrdlus(tegevuse) ülekaalule?",
              "info": "The greater the net health benefit associated with an intervention, the greater the likelihood of a general recommendation in favour of this intervention.",
              "options": [
                  {
                      "text": "soosib võrdlust",
                      "value": "favors_the_comparison"
                  },
                  {
                      "text": "pigem soosib võrdlust",
                      "value": "probably_favors_the_comparison"
                  },
                  {
                      "text": "ei soosi sekkumist ega võrdlust",
                      "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                      "text": "pigem soosib sekkumist",
                      "value": "probably_favors_the_intervention"
                  },
                  {
                      "text": "soosib sekkumist",
                      "value": "favors_the_intervention"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Does the short- and longer-term <strong>efficacy</strong> (under controlled, often ideal circumstances) or <strong>effectiveness</strong> (in a real-life setting) of the intervention on the <strong>health of individuals</strong>, including patient-reported outcomes, favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the short- and longer-term <strong>effectiveness</strong> or <strong>impact</strong> of the intervention on the <strong>health of the population</strong>, including on beneficiary-reported outcomes, favor the intervention or the comparison? (This should include considerations regarding whether population-level outcomes represent aggregated individual-level outcomes or emerge through system dynamics.)",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the extent to which <strong>patients/beneficiaries’ value</strong> different health outcomes favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the <strong>probability and severity of adverse effects</strong> associated with the intervention (including the risk of the intervention being misused) favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Do the <strong>broader positive or negative health-related impacts</strong> (e.g. reduction of stigma, positive impact on other diseases, spillover effects beyond patients/beneficiaries) associated with the intervention favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              }
          },
          "humanRights": {
              "description": "Is the intervention in accordance with universal human rights standards and principles?",
              "info": "All recommendations should be in accordance with universal human rights standards and principles.",
              "options": [
                  {
                      "text": "ei",
                      "value": "no"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "Uncertain",
                      "value": "uncertain"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "jah",
                      "value": "yes"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general"
              }
          },
          "socioCulturalAcceptability": {
              "description": "Kas sekkumine on huvitatud osapooltele vastuvõetav?",
              "info": "The greater the socio-cultural acceptability of an intervention to all or most relevant stakeholders, the greater the likelihood of a general recommendation in favour of this intervention.",
              "options": [
                  {
                      "text": "ei",
                      "value": "no"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "Uncertain",
                      "value": "uncertain"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "jah",
                      "value": "yes"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "How substantial is the intrusiveness of the intervention in terms of infringing on individual liberties (including privacy and dignity)? (Intrusiveness ranges from trivial – for example through enabling choice (e.g. building cycle paths) to high – for example by restricting or eliminating choice (e.g. banning cigarettes)).",
                          "options": [
                              {
                                  "text": "Suur",
                                  "value": "large"
                              },
                              {
                                  "text": "Mõõdukas",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Väike",
                                  "value": "small"
                              },
                              {
                                  "text": "Tühine",
                                  "value": "trivial"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "How substantial is the impact of the intervention on the autonomy of individuals, population groups, and/or organizations (with regards to their ability to make a competent, informed, and voluntary decision)?",
                          "options": [
                              {
                                  "text": "Suur",
                                  "value": "large"
                              },
                              {
                                  "text": "Mõõdukas",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Väike",
                                  "value": "small"
                              },
                              {
                                  "text": "Tühine",
                                  "value": "trivial"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the socio-cultural acceptability of the intervention among intended beneficiaries favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the socio-cultural acceptability of the intervention among those intended to implement the intervention favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the socio-cultural acceptability of the intervention among other relevant stakeholder groups favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the socio-cultural acceptability of the intervention among the general public favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              }
          },
          "healthEquityEqualityAndNonDiscrimination": {
              "description": "What would be the impact of the intervention on health equity, equality, and non-discrimination?",
              "info": "The greater the likelihood that the intervention increases health equity and/or equality and that it reduces discrimination against any particular group, the greater the likelihood of a general recommendation in favour of this intervention.",
              "options": [
                  {
                      "text": "Negative",
                      "value": "negative"
                  },
                  {
                      "text": "Probably negative",
                      "value": "probably_negative"
                  },
                  {
                      "text": "Neither negative nor positive",
                      "value": "neither_negative_nor_positive"
                  },
                  {
                      "text": "Probably positive",
                      "value": "probably_positive"
                  },
                  {
                      "text": "Positive",
                      "value": "positive"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Is the intervention likely to increase existing inequalities and/or inequities in the health condition or its determinants? (This should include considerations of likely changes in inequalities over time, e.g. whether initial increases are likely to balance out over time, as the intervention is scaled up?)",
                          "options": [
                              {
                                  "text": "ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Are the intervention’s benefits and harms likely to be distributed in an equitable manner? (This should include a special focus on implications for vulnerable, marginalized or otherwise socially disadvantaged population groups.)",
                          "options": [
                              {
                                  "text": "ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Is the intervention affordable among affected population groups, and therefore financially accessible? (This should include the impact on household health expenditures, including the risk of catastrophic health expenditures and health-related financial risks.)",
                          "options": [
                              {
                                  "text": "ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Is the intervention accessible among affected population groups? (This should include considerations regarding physical as well as informational access.)",
                          "options": [
                              {
                                  "text": "ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the intervention address a particularly severe (e.g. life-threatening, end-of-life, affecting individuals with a low pre-existing health status) or rare condition?",
                          "options": [
                              {
                                  "text": "ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the intervention represent the only available option? (This should include considerations whether the intervention is proportionate to the need, and whether it will be subject to periodic review.)",
                          "options": [
                              {
                                  "text": "ei",
                                  "value": "no"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "jah",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              }
          },
          "societalImplications": {
              "description": "Kas sekkumise soovitud ja soovimatu mõju vahekord viitab sekkumise või võrdlus(tegevuse) ülekaalule?",
              "info": "The greater the net societal benefit associated with an intervention, the greater the likelihood of a general recommendation in favour of this intervention.",
              "options": [
                  {
                      "text": "soosib võrdlust",
                      "value": "favors_the_comparison"
                  },
                  {
                      "text": "pigem soosib võrdlust",
                      "value": "probably_favors_the_comparison"
                  },
                  {
                      "text": "ei soosi sekkumist ega võrdlust",
                      "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                      "text": "pigem soosib sekkumist",
                      "value": "probably_favors_the_intervention"
                  },
                  {
                      "text": "soosib sekkumist",
                      "value": "favors_the_intervention"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Do the social impact and social consequences of the intervention (such as increase or reduction of stigma, educational outcomes, social cohesion, or the attainment of various human rights beyond health) favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Do the environmental impact and the implications for ecological sustainability (e.g. protection of natural resources, mitigation or adaption to climate change) favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              }
          },
          "financialAndEconomicConsiderations": {
              "description": "Do financial and economic considerations favor the intervention or the comparison?",
              "info": "The more advantageous the financial and economic implications of an intervention, the greater the likelihood of a general recommendation in favour of this intervention.",
              "options": [
                  {
                      "text": "soosib võrdlust",
                      "value": "favors_the_comparison"
                  },
                  {
                      "text": "pigem soosib võrdlust",
                      "value": "probably_favors_the_comparison"
                  },
                  {
                      "text": "ei soosi sekkumist ega võrdlust",
                      "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                      "text": "pigem soosib sekkumist",
                      "value": "probably_favor_the_intervention"
                  },
                  {
                      "text": "soosib sekkumist",
                      "value": "favors_the_intervention"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Do the <strong>cost and budget</strong> impacts of implementing and maintaining the intervention favor the intervention or the comparison? (This should include considerations of how cost and budget impacts vary in the short- versus longer-term.)",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the <strong>overall impact of the intervention on the economy</strong> favor the intervention or the comparison? (This should include considerations of how the different types of economic impact are distributed across different sectors or organizational levels whether the intervention contributes to or limits the achievement of broader development and poverty reduction goals, and how it impacts the available workforce.)",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Does the <strong>ratio of costs and benefits</strong> (e.g. based on estimates of cost-effectiveness, cost-benefit or cost-utility) favor the intervention or the comparison?",
                          "options": [
                              {
                                  "text": "soosib võrdlust",
                                  "value": "favors_the_comparison"
                              },
                              {
                                  "text": "pigem soosib võrdlust",
                                  "value": "probably_favors_the_comparison"
                              },
                              {
                                  "text": "ei soosi sekkumist ega võrdlust",
                                  "value": "does_not_favor_either_the_intervention_or_the_comparison"
                              },
                              {
                                  "text": "pigem soosib sekkumist",
                                  "value": "probably_favors_the_intervention"
                              },
                              {
                                  "text": "soosib sekkumist",
                                  "value": "favors_the_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              }
          },
          "feasibilityAndHealthSystemConsiderations": {
              "description": "Kas sekkumine on teostatav?",
              "info": "The greater the feasibility of an option from the perspective of all or most stakeholders, the greater the likelihood of a general recommendation in favour of the intervention. The more advantageous the implications for the health system as a whole, the greater the likelihood of a general recommendation in favour of the intervention.",
              "options": [
                  {
                      "text": "ei",
                      "value": "no"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_not"
                  },
                  {
                      "text": "Uncertain",
                      "value": "uncertain"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "jah",
                      "value": "yes"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Are there <strong>legal barriers</strong> which may limit the feasibility of implementing the intervention?",
                          "options": [
                              {
                                  "text": "jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Are there <strong>governance aspects</strong> (e.g. strategic considerations, past decisions) which may limit the feasibility of implementing the intervention? (This should include considerations regarding the presence or absence of formal or information institutions which can provide effective leadership, oversight, and accountability in implementing the intervention influence feasibility of implementation)",
                          "options": [
                              {
                                  "text": "jah",
                                  "value": "yes"
                              },
                              {
                                  "text": "Pigem jah",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Pigem ei",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "ei",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "What are the <strong>implications</strong> of the intervention <strong>interaction and fit with the existing health system?</strong> (This includes considerations regarding the intervention’s interaction with or impact on the existing health system and its components?)",
                          "options": [
                              {
                                  "text": "Large beneficial implications",
                                  "value": "large_beneficial_implications"
                              },
                              {
                                  "text": "Moderate beneficial implications",
                                  "value": "moderate_beneficial_implications"
                              },
                              {
                                  "text": "Negligible beneficial and adverse implications",
                                  "value": "negligible_beneficial_and_adverse_implications"
                              },
                              {
                                  "text": "Moderate adverse implications",
                                  "value": "moderate_adverse_implications"
                              },
                              {
                                  "text": "Large adverse implications",
                                  "value": "large_adverse_implications"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "What are the <strong>implications</strong> of the intervention <strong>for the health workforce and broader human resources</strong> (in the health sector or other sectors? (This should include considerations regarding the need for, usage of, and impact on health workforce and other human resources as well as their distribution)",
                          "options": [
                              {
                                  "text": "Large beneficial implications",
                                  "value": "large_beneficial_implications"
                              },
                              {
                                  "text": "Moderate beneficial implications",
                                  "value": "moderate_beneficial_implications"
                              },
                              {
                                  "text": "Negligible beneficial and adverse implications",
                                  "value": "negligible_beneficial_and_adverse_implications"
                              },
                              {
                                  "text": "Moderate adverse implications",
                                  "value": "moderate_adverse_implications"
                              },
                              {
                                  "text": "Large adverse implications",
                                  "value": "large_adverse_implications"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "What are the <strong>implications</strong> of the intervention <strong>for health system infrastructure and broader infrastructure</strong>? (This should include considerations regarding the need for, usage of, and impact on non-human resources and infrastructure as well as their distribution)",
                          "options": [
                              {
                                  "text": "Large beneficial implications",
                                  "value": "large_beneficial_implications"
                              },
                              {
                                  "text": "Moderate beneficial implications",
                                  "value": "moderate_beneficial_implications"
                              },
                              {
                                  "text": "Negligible beneficial and adverse implications",
                                  "value": "negligible_beneficial_and_adverse_implications"
                              },
                              {
                                  "text": "Moderate adverse implications",
                                  "value": "moderate_adverse_implications"
                              },
                              {
                                  "text": "Large adverse implications",
                                  "value": "large_adverse_implications"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varieerub",
                                  "value": "varies"
                              },
                              {
                                  "text": "Ei oska öelda",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              }
          },
          "qualityOfEvidence": {
              "description": "What is the overall quality of evidence?",
              "info": "The greater the quality of the evidence across different criteria in the WHO-INTEGRATE framework, the greater the likelihood of a general recommendation.",
              "options": [
                  {
                      "text": "Väga madal",
                      "value": "very_low"
                  },
                  {
                      "text": "madal",
                      "value": "low"
                  },
                  {
                      "text": "Mõõdukas",
                      "value": "moderate"
                  },
                  {
                      "text": "väga",
                      "value": "high"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varieerub",
                      "value": "varies"
                  },
                  {
                      "text": "Ei oska öelda",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general"
              }
          },
          "additionalSection": {
              "description": "Custom criterion description",
              "info": "Provide custom tooltip",
              "options": [
                  {
                      "text": "Judgement 1",
                      "value": "option_1"
                  },
                  {
                      "text": "Judgement 2",
                      "value": "option_2"
                  },
                  {
                      "text": "Judgement 3",
                      "value": "option_3"
                  },
                  {
                      "text": "Judgement 4",
                      "value": "option_4"
                  },
                  {
                      "text": "Judgement 5",
                      "value": "option_5"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Additional judgement 1",
                      "value": "additionalOption_1"
                  },
                  {
                      "text": "Additional judgement 2",
                      "value": "additionalOption_2"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general"
              }
          },
          "scientificallySound": {
              "description": "based on evidence (GRADE criteria)",
              "selectedOptions": "",
              "ratingOptions": [
                  "very_scientifically_sound",
                  "scientifically_sound",
                  "least_scientifically_sound"
              ]
          },
          "relevantDirect": {
              "description": "to the selected problem and in the field of application",
              "selectedOptions": "",
              "ratingOptions": [
                  "very_relevant",
                  "relevant",
                  "least_relevant"
              ]
          },
          "responsive": {
              "description": "sensitive to change that means they should be able to capture possible changes in the system",
              "selectedOptions": "",
              "ratingOptions": [
                  "very_responsive",
                  "responsive",
                  "least_responsive"
              ]
          },
          "feasible": {
              "description": "to identify and measure, and not susceptible to manipulation",
              "selectedOptions": "",
              "ratingOptions": [
                  "very_feasible",
                  "feasible",
                  "least_feasible"
              ]
          },
          "problemEOHR": {
              "description": "Is the problem a priority?",
              "info": "The more serious or urgent a problem is, the more likely it is that an option that addresses the problem will be a priority",
              "options": [
                  {
                      "text": "No",
                      "value": "no"
                  },
                  {
                      "text": "Probably no",
                      "value": "probably_no"
                  },
                  {
                      "text": "Probably yes",
                      "value": "probably_yes"
                  },
                  {
                      "text": "Yes",
                      "value": "yes"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varies",
                      "value": "varies"
                  },
                  {
                      "text": "Don't know",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Are the consequences of the problem serious (i.e., severe, irreversible, or important)?",
                          "options": [
                              {
                                  "text": "No",
                                  "value": "no"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Is the problem urgent, emergent, or unprecedented??",
                          "options": [
                              {
                                  "text": "No",
                                  "value": "no"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Is the problem a recognized priority of the political system (local or national)?",
                          "options": [
                              {
                                  "text": "No",
                                  "value": "no"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Is the problem a recognized occupational or public health concern?",
                          "options": [
                              {
                                  "text": "No",
                                  "value": "no"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Is the problem a recognized priority for a local community?",
                          "options": [
                              {
                                  "text": "No",
                                  "value": "no"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              }
          },
          "desirableEffectsEOHR": {
              "description": "How substantial are the desirable anticipated effects?",
              "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
              "info": "How large are the desirable effects of the intervention taking into account the importance of the outcomes (how much they are valued), and the size of the effect (the likelihood of experiencing a benefit or how much of an improvement individuals would be likely to experience)?",
              "options": [
                  {
                      "text": "Trivial",
                      "value": "trivial"
                  },
                  {
                      "text": "Small",
                      "value": "small"
                  },
                  {
                      "text": "Moderate",
                      "value": "moderate"
                  },
                  {
                      "text": "Large",
                      "value": "large"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varies",
                      "value": "varies"
                  },
                  {
                      "text": "Don't know",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "outcomes",
                  "questions": [
                      {
                          "question": "How substantial is the anticipated desirable impact (effect) of the intervention or exposure?",
                          "options": [
                              {
                                  "text": "Trivial",
                                  "value": "trivial"
                              },
                              {
                                  "text": "Small",
                                  "value": "small"
                              },
                              {
                                  "text": "Moderate",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Large",
                                  "value": "large"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ]
                      },
                      {
                          "question": "How substantial is the time span for the intervention to reach full effectiveness?",
                          "options": [
                              {
                                  "text": "Trivial",
                                  "value": "trivial"
                              },
                              {
                                  "text": "Small",
                                  "value": "small"
                              },
                              {
                                  "text": "Moderate",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Large",
                                  "value": "large"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ]
                      }
                  ]
              },
              "ratingOptions": [
                  "most_effective",
                  "intermediate_effectiveness",
                  "least_effective"
              ]
          },
          "undesirableEffectsEOHR": {
              "description": "How substantial are the undesirable anticipated effects?",
              "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
              "info": "How large are the undesirable effects of the intervention taking into account the importance of the outcomes (how much they are valued), and the size of the effect (the likelihood of experiencing a benefit or how much of an improvement individuals would be likely to experience)?",
              "options": [
                  {
                      "text": "Large",
                      "value": "large"
                  },
                  {
                      "text": "Moderate",
                      "value": "moderate"
                  },
                  {
                      "text": "Small",
                      "value": "small"
                  },
                  {
                      "text": "Trivial",
                      "value": "trivial"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varies",
                      "value": "varies"
                  },
                  {
                      "text": "Don't know",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "outcomes",
                  "questions": [
                      {
                          "question": "How substantial is the anticipated undesirable impact (effect) of the intervention or exposure?",
                          "options": [
                              {
                                  "text": "Large",
                                  "value": "large"
                              },
                              {
                                  "text": "Moderate",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Small",
                                  "value": "small"
                              },
                              {
                                  "text": "Trivial",
                                  "value": "trivial"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ]
                      },
                      {
                          "question": "How substantial is the time span for the undesirable effects of the intervention?",
                          "options": [
                              {
                                  "text": "Large",
                                  "value": "large"
                              },
                              {
                                  "text": "Moderate",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Small",
                                  "value": "small"
                              },
                              {
                                  "text": "Trivial",
                                  "value": "trivial"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ]
                      }
                  ]
              },
              "ratingOptions": [
                  "least_harmful",
                  "intermediate",
                  "more_harmful"
              ]
          },
          "certaintyOfEvidenceEOHR": {
              "description": "What is the overall certainty of the evidence of effects?",
              "ratingDescription": "What is the overall certainty of the evidence of effects for each intervention?",
              "info": "How good an indication does the research provide of the likely effects across all of the critical outcomes; i.e. the likelihood that the effects will be different enough from what the research found that it might affect a decision about the intervention?",
              "options": [
                  {
                      "text": "Very low",
                      "value": "very_low"
                  },
                  {
                      "text": "Low",
                      "value": "low"
                  },
                  {
                      "text": "Moderate",
                      "value": "moderate"
                  },
                  {
                      "text": "High",
                      "value": "high"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "No included studies",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": ""
              }
          },
          "valuesEOHR": {
              "description": "Is there important uncertainty about or variability in how much people value the main outcomes?",
              "info": "How much do individuals value each of the main outcomes? Is uncertainty about how much they value each of the outcomes or variability in how much different individuals value the outcomes large enough that it could lead to different decisions?",
              "options": [
                  {
                      "text": "Important uncertainty or variability",
                      "value": "important_uncertainty"
                  },
                  {
                      "text": "Possibly important uncertainty or variability",
                      "value": "possible_important"
                  },
                  {
                      "text": "Probably no important uncertainty or variability",
                      "value": "probably_no_important"
                  },
                  {
                      "text": "No important uncertainty or variability",
                      "value": "no_important"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "outcomes",
                  "questions": [
                      {
                          "question": "Is there important uncertainty about how much people value the main outcomes?",
                          "options": [
                              {
                                  "text": "Important uncertainty",
                                  "value": "important"
                              },
                              {
                                  "text": "Probably important uncertainty",
                                  "value": "probably_important"
                              },
                              {
                                  "text": "Probably no important uncertainty",
                                  "value": "probably_no_important"
                              },
                              {
                                  "text": "No important uncertainty",
                                  "value": "no_important"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ]
                      },
                      {
                          "question": "Is there meaningful variability in how much people value the main outcomes?",
                          "options": [
                              {
                                  "text": "Important variability",
                                  "value": "important"
                              },
                              {
                                  "text": "Probably important variability",
                                  "value": "probably_important"
                              },
                              {
                                  "text": "Probably no important variability",
                                  "value": "probably_no_important"
                              },
                              {
                                  "text": "No important variability",
                                  "value": "no_important"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ]
                      }
                  ]
              }
          },
          "balanceOfEffectsEOHR": {
              "description": "Does the balance between desirable and undesirable effects favor the intervention or the comparison?",
              "ratingDescription": "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?",
              "info": "What is the balance between the desirable and undesirable effects, taking into account how much individuals value the main outcomes, how substantial the desirable and undesirable effects are, the certainty of those estimates, discount rates, risk aversion and risk seeking?",
              "options": [
                  {
                      "text": "Favors the comparison",
                      "value": "favors_comparison"
                  },
                  {
                      "text": "Probably favors the comparison",
                      "value": "probably_favors_comparison"
                  },
                  {
                      "text": "Does not favor either the intervention or the comparison",
                      "value": "doesnt_favor_any"
                  },
                  {
                      "text": "Probably favors the intervention",
                      "value": "probably_favors_intervention"
                  },
                  {
                      "text": "Favors the intervention",
                      "value": "favors_intervention"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varies",
                      "value": "varies"
                  },
                  {
                      "text": "Don't know",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "additionalInfos": [
                      {
                          "description": "Detailed judgements for this criterion include judgements regarding each of the four preceding criteria:",
                          "criteriaAnswers": [
                              {
                                  "criterionId": "values"
                              },
                              {
                                  "criterionId": "certaintyOfEvidence"
                              },
                              {
                                  "criterionId": "desirableEffects"
                              },
                              {
                                  "criterionId": "undesirableEffects"
                              }
                          ]
                      },
                      {
                          "description": "In addition, panels might want to consider (and, if relevant document) the extent to which the following considerations might influence the balance between the desirable and undesirable effects:",
                          "additions": [
                              "How much less people value outcomes that are in the future compared to outcomes that occur now (their discount rates)?",
                              "People’s attitudes towards undesirable effects (how risk averse they are).",
                              "People’s attitudes towards desirable effects (how risk seeking they are)."
                          ]
                      }
                  ]
              },
              "ratingOptions": [
                  "best_balance",
                  "intermediate",
                  "worst_balance"
              ]
          },
          "resourcesRequiredEOHR": {
              "description": "Kui suur on ressursivajadus (kulud)?",
              "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
              "info": "How large is the cost of the difference in resource use between the intervention and comparison?",
              "options": [
                  {
                      "text": "Large costs",
                      "value": "large_costs"
                  },
                  {
                      "text": "Moderate costs",
                      "value": "moderate_costs"
                  },
                  {
                      "text": "Negligible costs and savings",
                      "value": "negligible_costs_savings"
                  },
                  {
                      "text": "Moderate savings",
                      "value": "moderate_savings"
                  },
                  {
                      "text": "Large savings",
                      "value": "large_savings"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varies",
                      "value": "varies"
                  },
                  {
                      "text": "Don't know",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "resources",
                  "questions": [
                      {
                          "question": "For each type of resource, would the intervention or exposure under consideration produce additional costs or savings?",
                          "options": [
                              {
                                  "text": "Trivial",
                                  "value": "trivial"
                              },
                              {
                                  "text": "Small",
                                  "value": "small"
                              },
                              {
                                  "text": "Moderate",
                                  "value": "moderate"
                              },
                              {
                                  "text": "Large",
                                  "value": "large"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ]
                      }
                  ]
              },
              "ratingOptions": [
                  "less_costs",
                  "intermediate_costs",
                  "most_costs"
              ]
          },
          "certaintyOfEvidenceOfRequiredResourcesEOHR": {
              "description": "What is the certainty of the evidence of resource requirements?",
              "info": "How certain is the evidence of a difference for each type of resource use (e.g. drugs, hospitalisations) and the cost of resources?",
              "options": [
                  {
                      "text": "Very low",
                      "value": "very_low"
                  },
                  {
                      "text": "Low",
                      "value": "low"
                  },
                  {
                      "text": "Moderate",
                      "value": "moderate"
                  },
                  {
                      "text": "High",
                      "value": "high"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "No included studies",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "resources",
                  "questions": [
                      {
                          "question": "Have all relevant resource types been identified?",
                          "options": [
                              {
                                  "text": "No",
                                  "value": "no"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ]
                      },
                      {
                          "question": "Is there important variability in the cost of the resource types under consideration?",
                          "options": [
                              {
                                  "text": "Important variability",
                                  "value": "important"
                              },
                              {
                                  "text": "Probably important variability",
                                  "value": "probably_important"
                              },
                              {
                                  "text": "Probably no important variability",
                                  "value": "probably_no_important"
                              },
                              {
                                  "text": "No important variability",
                                  "value": "no_important"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ]
                      }
                  ]
              }
          },
          "costEffectivenessEOHR": {
              "description": "Does the cost-effectiveness of the intervention favor the intervention or the comparison?",
              "ratingDescription": "Which intervention does the cost effectiveness favor?",
              "info": "Is the intervention cost-effective, taking into account uncertainty about or variability in the costs, uncertainty about or variability in the net benefit, sensitivity analyses, and the reliability and applicability of the economic evaluation?",
              "options": [
                  {
                      "text": "Favors the comparison",
                      "value": "favors_comparison"
                  },
                  {
                      "text": "Probably favors the comparison",
                      "value": "probably_favors_comparison"
                  },
                  {
                      "text": "Does not favor either the intervention or the comparison",
                      "value": "doesnt_favor_any"
                  },
                  {
                      "text": "Probably favors the intervention",
                      "value": "probably_favors_intervention"
                  },
                  {
                      "text": "Favors the intervention",
                      "value": "favors_intervention"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varies",
                      "value": "varies"
                  },
                  {
                      "text": "No included studies",
                      "value": "no_included_studies"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "What is the certainty in the cost effectiveness analysis?",
                          "options": [
                              {
                                  "text": "Favors the comparison",
                                  "value": "favors_comparison"
                              },
                              {
                                  "text": "Probably favors the comparison",
                                  "value": "probably_favors_comparison"
                              },
                              {
                                  "text": "Does not favor either the intervention or the comparison",
                                  "value": "doesnt_favor_any"
                              },
                              {
                                  "text": "Probably favors the intervention",
                                  "value": "probably_favors_intervention"
                              },
                              {
                                  "text": "Favors the intervention",
                                  "value": "favors_intervention"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "No included studies",
                                  "value": "no_included_studies"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              },
              "ratingOptions": [
                  "best_cost_effectiveness",
                  "intermediate_cost_effectiveness",
                  "worst_cost_effectiveness"
              ]
          },
          "equityEOHR": {
              "description": "What would be the impact on equity?",
              "ratingDescription": "If recommended, which intervention would reduce health inequities the most?",
              "info": "Are there plausible reasons for anticipating differences in the relative effectiveness of the intervention for disadvantaged subgroups or different baseline conditions across disadvantaged subgroups that affect the absolute effectiveness of the intervention or the importance of the problem?",
              "options": [
                  {
                      "text": "Reduced",
                      "value": "reduced"
                  },
                  {
                      "text": "Probably reduced",
                      "value": "probably_reduced"
                  },
                  {
                      "text": "Probably no impact",
                      "value": "probably_no_impact"
                  },
                  {
                      "text": "Probably increased",
                      "value": "probably_increased"
                  },
                  {
                      "text": "Increased",
                      "value": "increased"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varies",
                      "value": "varies"
                  },
                  {
                      "text": "Don't know",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Are there groups or settings (e.g., susceptible populations or life stages) that may experience a different impact (effect) of the intervention or exposure due to variability in baseline conditions across the affected population?",
                          "options": [
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "No",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              },
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Are the impacts unreasonably disproportionate to particular groups (e.g., complex or unquantified stressors)?",
                          "options": [
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "No",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              },
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Would implementing the intervention or exposure reduce or increase inequities experienced by marginalized members of the affected population?",
                          "options": [
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "No",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              },
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Are issues of equality (e.g., under-represented or under-studied groups) addressed?",
                          "options": [
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "No",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              },
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Are social justice issues addressed? (e.g., Is the spirit of the ‘polluter pays principle’ upheld with regard to distribution of impacts/benefits?)",
                          "options": [
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "No",
                                  "value": "no"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              },
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              },
              "ratingOptions": [
                  "most_reduction",
                  "intermediate_reduction",
                  "less_reduction"
              ]
          },
          "acceptabilityEOHR": {
              "description": "Is the intervention acceptable to key stakeholders?",
              "ratingDescription": "Which intervention is more acceptable to key stakeholders?",
              "info": "Are key stakeholders likely not to accept the distribution of the benefits, harms and costs; or the costs or undesirable effects in the short term for desirable effects (benefits) in the future? Are they likely to disagree with the values attached to the desirable or undesirable effects, or not to accept the diagnostic intervention because of ethical concerns?",
              "options": [
                  {
                      "text": "No",
                      "value": "no"
                  },
                  {
                      "text": "Probably no",
                      "value": "probably_no"
                  },
                  {
                      "text": "Probably yes",
                      "value": "probably_yes"
                  },
                  {
                      "text": "Yes",
                      "value": "yes"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varies",
                      "value": "varies"
                  },
                  {
                      "text": "Don't know",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "What is the certainty in the acceptability of the intervention among the affected population?",
                          "options": [
                              {
                                  "text": "Very low",
                                  "value": "very_low"
                              },
                              {
                                  "text": "Low",
                                  "value": "low"
                              },
                              {
                                  "text": "Moderate",
                                  "value": "moderate"
                              },
                              {
                                  "text": "High",
                                  "value": "high"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "No included studies",
                                  "value": "no_included_studies"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Is there variability in the acceptability of the intervention or exposure among beneficiaries of the recommendation and other stakeholders?",
                          "options": [
                              {
                                  "text": "Important variability",
                                  "value": "important"
                              },
                              {
                                  "text": "Probably important variability",
                                  "value": "probably_important"
                              },
                              {
                                  "text": "Probably no important variability",
                                  "value": "probably_no_important"
                              },
                              {
                                  "text": "No important variability",
                                  "value": "no_important"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              },
              "ratingOptions": [
                  "best_acceptability",
                  "intermediate_acceptability",
                  "worst_acceptability"
              ]
          },
          "feasibilityEOHR": {
              "description": "Is the intervention feasible to implement?",
              "ratingDescription": "Which intervention is more feasible to implement?",
              "info": "Is it feasible to sustain use of the diagnostic intervention and to address potential barriers to using it?",
              "options": [
                  {
                      "text": "No",
                      "value": "no"
                  },
                  {
                      "text": "Probably no",
                      "value": "probably_no"
                  },
                  {
                      "text": "Probably yes",
                      "value": "probably_yes"
                  },
                  {
                      "text": "Yes",
                      "value": "yes"
                  }
              ],
              "additionalOptions": [
                  {
                      "text": "Varies",
                      "value": "varies"
                  },
                  {
                      "text": "Don't know",
                      "value": "dont_know"
                  }
              ],
              "selectedOption": "",
              "decision": {
                  "selectedOption": "",
                  "comments": ""
              },
              "details": {
                  "panelDiscussion": "",
                  "questionsType": "general",
                  "questions": [
                      {
                          "question": "Is the intervention (option) sustainable for the relevant duration of time?",
                          "options": [
                              {
                                  "text": "No",
                                  "value": "no"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Are there important barriers (e.g., absence of laws/regulations) that are likely to limit the feasibility of implementing the intervention (option)?",
                          "options": [
                              {
                                  "text": "No",
                                  "value": "no"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Are remedies available to address any important barriers to implementing the intervention?",
                          "options": [
                              {
                                  "text": "No",
                                  "value": "no"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      },
                      {
                          "question": "Are there important enablers (e.g., presence of laws/regulations) that are likely to improve the feasibility of implementing the intervention (option)?",
                          "options": [
                              {
                                  "text": "No",
                                  "value": "no"
                              },
                              {
                                  "text": "Probably no",
                                  "value": "probably_no"
                              },
                              {
                                  "text": "Probably yes",
                                  "value": "probably_yes"
                              },
                              {
                                  "text": "Yes",
                                  "value": "yes"
                              }
                          ],
                          "additionalOptions": [
                              {
                                  "text": "Varies",
                                  "value": "varies"
                              },
                              {
                                  "text": "Don't know",
                                  "value": "dont_know"
                              }
                          ],
                          "selectedOption": ""
                      }
                  ]
              },
              "ratingOptions": [
                  "most_feasible",
                  "intermediate_feasibility",
                  "least_feasible"
              ]
          }
      },
      "researchEvidences": {
          "problem": {
              "content": ""
          },
          "desirableEffects": {
              "content": ""
          },
          "undesirableEffects": {
              "content": ""
          },
          "certaintyOfEvidence": {
              "content": ""
          },
          "values": {
              "content": ""
          },
          "balanceOfEffects": {
              "content": ""
          },
          "resourcesRequired": {
              "content": ""
          },
          "certaintyOfEvidenceOfRequiredResources": {
              "content": ""
          },
          "costEffectiveness": {
              "content": ""
          },
          "equity": {
              "content": ""
          },
          "acceptability": {
              "content": ""
          },
          "feasibility": {
              "content": ""
          },
          "testAccuracy": {
              "content": ""
          },
          "certaintyOfEvidenceOfTestAccuracy": {
              "content": ""
          },
          "certaintyOfEvidenceOfTestEffects": {
              "content": ""
          },
          "certaintyOfEvidenceOfManagementEffects": {
              "content": ""
          },
          "certaintyOfEvidenceOfTestResult": {
              "content": ""
          },
          "certaintyOfEffects": {
              "content": ""
          },
          "balanceOfHealthBenefitsAndHarms": {
              "content": ""
          },
          "humanRights": {
              "content": ""
          },
          "socioCulturalAcceptability": {
              "content": ""
          },
          "healthEquityEqualityAndNonDiscrimination": {
              "content": ""
          },
          "societalImplications": {
              "content": ""
          },
          "financialAndEconomicConsiderations": {
              "content": ""
          },
          "feasibilityAndHealthSystemConsiderations": {
              "content": ""
          },
          "qualityOfEvidence": {
              "content": ""
          },
          "additionalSection": {
              "content": ""
          },
          "scientificallySound": {
              "content": ""
          },
          "relevantDirect": {
              "content": ""
          },
          "responsive": {
              "content": ""
          },
          "feasible": {
              "content": ""
          },
          "netBalance": {
              "content": ""
          }
      },
      "additionalConsiderations": {
          "problem": {
              "content": ""
          },
          "desirableEffects": {
              "content": ""
          },
          "undesirableEffects": {
              "content": ""
          },
          "certaintyOfEvidence": {
              "content": ""
          },
          "values": {
              "content": ""
          },
          "balanceOfEffects": {
              "content": ""
          },
          "resourcesRequired": {
              "content": ""
          },
          "certaintyOfEvidenceOfRequiredResources": {
              "content": ""
          },
          "costEffectiveness": {
              "content": ""
          },
          "equity": {
              "content": ""
          },
          "acceptability": {
              "content": ""
          },
          "feasibility": {
              "content": ""
          },
          "testAccuracy": {
              "content": ""
          },
          "certaintyOfEvidenceOfTestAccuracy": {
              "content": ""
          },
          "certaintyOfEvidenceOfTestEffects": {
              "content": ""
          },
          "certaintyOfEvidenceOfManagementEffects": {
              "content": ""
          },
          "certaintyOfEvidenceOfTestResult": {
              "content": ""
          },
          "certaintyOfEffects": {
              "content": ""
          },
          "balanceOfHealthBenefitsAndHarms": {
              "content": ""
          },
          "humanRights": {
              "content": ""
          },
          "socioCulturalAcceptability": {
              "content": ""
          },
          "healthEquityEqualityAndNonDiscrimination": {
              "content": ""
          },
          "societalImplications": {
              "content": ""
          },
          "financialAndEconomicConsiderations": {
              "content": ""
          },
          "feasibilityAndHealthSystemConsiderations": {
              "content": ""
          },
          "qualityOfEvidence": {
              "content": ""
          },
          "additionalSection": {
              "content": ""
          },
          "scientificallySound": {
              "content": ""
          },
          "relevantDirect": {
              "content": ""
          },
          "responsive": {
              "content": ""
          },
          "feasible": {
              "content": ""
          },
          "netBalance": {
              "content": ""
          }
      }
  },
  "conclusions": {
      "sections": {
          "recommendationTypeInterventionMultipleChoice": {
              "description": "Type of recommendation",
              "options": [
                  {
                      "text": "Strong recommendation against the intervention",
                      "value": "recommend_against",
                      "direction": "negative"
                  },
                  {
                      "text": "Nõrk soovitus sekkumise vastu",
                      "value": "suggest_against",
                      "direction": "negative"
                  },
                  {
                      "text": "Conditional recommendation for either the intervention or the comparison",
                      "value": "suggest_either",
                      "direction": "none"
                  },
                  {
                      "text": "Conditional recommendation for the intervention",
                      "value": "suggest",
                      "direction": "positive"
                  },
                  {
                      "text": "Strong recommendation for the intervention",
                      "value": "recommend",
                      "direction": "positive"
                  }
              ]
          },
          "recommendationTypeIntervention": {
              "description": "Soovituse liik",
              "options": [
                  {
                      "text": "Tugev soovitus mitte teha",
                      "value": "recommend_against",
                      "direction": "negative"
                  },
                  {
                      "text": "Nõrk soovitus sekkumise vastu",
                      "value": "suggest_against",
                      "direction": "negative"
                  },
                  {
                      "text": "Nõrk soovitus kas sekkumise või alternatiivi poolt",
                      "value": "suggest_either",
                      "direction": "none"
                  },
                  {
                      "text": "Nõrk soovitus sekkumise poolt",
                      "value": "suggest",
                      "direction": "positive"
                  },
                  {
                      "text": "Tugev soovitus teha",
                      "value": "recommend",
                      "direction": "positive"
                  }
              ],
              "selectedOption": "",
              "content": ""
          },
          "recommendationTypeOption": {
              "description": "Soovituse liik",
              "options": [
                  {
                      "text": "Tugev soovitus mitte teha",
                      "value": "recommend_against",
                      "direction": "negative"
                  },
                  {
                      "text": "Tingimuslik vastuseis sekkumisele",
                      "value": "suggest_against",
                      "direction": "negative"
                  },
                  {
                      "text": "Valige sekkumine või alternatiiv või pole kohaldatav",
                      "value": "suggest_either",
                      "direction": "none"
                  },
                  {
                      "text": "Nõrk soovitus sekkumise poolt",
                      "value": "suggest",
                      "direction": "positive"
                  },
                  {
                      "text": "Tugev soovitus teha",
                      "value": "recommend",
                      "direction": "positive"
                  }
              ],
              "selectedOption": "",
              "content": ""
          },
          "decisionTypeCoverage": {
              "description": "Otsuse liik",
              "options": [
                  {
                      "text": "Ära rahasta",
                      "value": "do_not_cover",
                      "direction": "negative"
                  },
                  {
                      "text": "Rahasta tõendusmaterjali täienemisel",
                      "value": "cover_with_evidence",
                      "direction": "positive"
                  },
                  {
                      "text": "Rahasta pärast hinnaläbirääkimisi",
                      "value": "cover_with_price",
                      "direction": "positive"
                  },
                  {
                      "text": "Rahasta piiratud sihtgrupil",
                      "value": "restricted_coverage",
                      "direction": "positive"
                  },
                  {
                      "text": "Rahastada",
                      "value": "cover",
                      "direction": "positive"
                  }
              ],
              "selectedOption": "",
              "content": ""
          },
          "decisionTypeImplementation": {
              "description": "Otsuse liik",
              "options": [
                  {
                      "text": "Ära võta sekkumist kasutusse",
                      "value": "do_not_implement",
                      "direction": "negative"
                  },
                  {
                      "text": "Lükka otsustamine edasi",
                      "value": "postpone",
                      "direction": "none"
                  },
                  {
                      "text": "Vii läbi sekkumise pilootuuring",
                      "value": "conduct_pilot_study",
                      "direction": "none"
                  },
                  {
                      "text": "Võta sekkumine kasutusse pärast (selle) mõju hindamist",
                      "value": "implement_with_evaluation",
                      "direction": "positive"
                  },
                  {
                      "text": "Võta sekkumine kasutusse",
                      "value": "implement",
                      "direction": "positive"
                  }
              ],
              "selectedOption": "",
              "content": ""
          },
          "recommendation": {
              "description": "Soovitus",
              "content": ""
          },
          "multipleRecommendations": {
              "description": "Soovitus",
              "recommendations": [
                  {
                      "_id": "__id__",
                      "selectedOption": "",
                      "content": ""
                  }
              ],
              "options": [
                  {
                      "text": "Tugev soovitus mitte teha",
                      "value": "recommend_against",
                      "direction": "negative"
                  },
                  {
                      "text": "Nõrk soovitus sekkumise vastu",
                      "value": "suggest_against",
                      "direction": "negative"
                  },
                  {
                      "text": "Nõrk soovitus kas sekkumise või alternatiivi poolt",
                      "value": "suggest_either",
                      "direction": "none"
                  },
                  {
                      "text": "Nõrk soovitus sekkumise poolt",
                      "value": "suggest",
                      "direction": "positive"
                  },
                  {
                      "text": "Tugev soovitus sekkumise poolt",
                      "value": "recommend",
                      "direction": "positive"
                  }
              ]
          },
          "decision": {
              "description": "Otsus",
              "content": "",
              "options": [
                  {
                      "text": "Täielik rakendamine",
                      "value": "full_implementation"
                  },
                  {
                      "text": "Mõju hindamine",
                      "value": "impact_evaluation"
                  },
                  {
                      "text": "Pilootuuring",
                      "value": "pilot_study"
                  },
                  {
                      "text": "Edasi lükata",
                      "value": "postpone"
                  },
                  {
                      "text": "Mitte rakendada",
                      "value": "do_not_implement"
                  }
              ]
          },
          "justification": {
              "description": "Põhjendus",
              "overallDescription": "Üldine põhjendus",
              "content": "",
              "details": {
                  "description": "Üksikasjalik põhjendus"
              }
          },
          "subgroupConsiderations": {
              "description": "Kaalutlused alamrühmade osas",
              "content": ""
          },
          "restrictions": {
              "description": "Piirangud",
              "content": ""
          },
          "implementationConsiderations": {
              "description": "Rakenduskaalutlused",
              "content": "",
              "details": {
                  "description": "Üksikasjalik rakendus",
                  "content": ""
              }
          },
          "multipleChronicConditionsAndPolypharmacy": {
              "description": "Multiple Chronic conditions and Polypharmacy",
              "content": ""
          },
          "monitoringAndEvaluation": {
              "description": "Jälgimine ja hindamine",
              "content": "",
              "details": {
                  "description": "Üksikasjalik jälgimine ja hindamine",
                  "content": ""
              }
          },
          "researchPriorities": {
              "description": "Edasiste/täpsustavate uuringute vajadus",
              "content": ""
          },
          "contextAndSystemConsiderations": {
              "description": "Context and system considerations",
              "content": ""
          },
          "goodPracticeStatement": {
              "description": "Good practice statement",
              "content": ""
          },
          "clearAndActionable": {
              "description": "Clear and actionable",
              "content": "",
              "options": [
                  {
                      "text": "jah",
                      "value": "yes"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "ei",
                      "value": "no"
                  }
              ],
              "selectedOption": ""
          },
          "theMessageIsNecessary": {
              "description": "The message is necessary in regard to actual health care practice",
              "content": "",
              "options": [
                  {
                      "text": "jah",
                      "value": "yes"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "ei",
                      "value": "no"
                  }
              ],
              "selectedOption": ""
          },
          "implementationConsiderationsOfAllRelevantOutcomes": {
              "description": "After consideration of all relevant outcomes and potential downstream consequences, implementing the good practice statement results in a large net positive consequences",
              "content": "",
              "options": [
                  {
                      "text": "jah",
                      "value": "yes"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "ei",
                      "value": "no"
                  }
              ],
              "selectedOption": ""
          },
          "collectingAndSummarizing": {
              "description": "Collecting and summarizing the evidence is a poor use of the guideline panel's limited time and energy (opportunity cost in large)",
              "content": "",
              "options": [
                  {
                      "text": "jah",
                      "value": "yes"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "ei",
                      "value": "no"
                  }
              ],
              "selectedOption": ""
          },
          "rationaleConnectingIndirectEvidence": {
              "description": "There is a well-documented clear and explicit rationale connecting the indirect evidence",
              "content": "",
              "options": [
                  {
                      "text": "jah",
                      "value": "yes"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "ei",
                      "value": "no"
                  }
              ],
              "selectedOption": ""
          },
          "implementationConsiderationsOrRemarks": {
              "description": "Rakenduskaalutlused",
              "content": "",
              "options": [
                  {
                      "text": "jah",
                      "value": "yes"
                  },
                  {
                      "text": "Pigem jah",
                      "value": "probably_yes"
                  },
                  {
                      "text": "Pigem ei",
                      "value": "probably_no"
                  },
                  {
                      "text": "ei",
                      "value": "no"
                  }
              ],
              "selectedOption": ""
          },
          "keyMessage": {
              "description": "Key Message",
              "content": ""
          },
          "limitations": {
              "description": "Limitations",
              "content": ""
          },
          "considerations": {
              "description": "Considerations",
              "content": ""
          },
          "mdgRecommendation": {
              "description": "Soovitus",
              "selectedOption": "",
              "options": [
                  {
                      "text": "Recommended",
                      "value": "recommend",
                      "direction": "positive"
                  },
                  {
                      "text": "Not Recommended",
                      "value": "recommend_against",
                      "direction": "negative"
                  },
                  {
                      "text": "Nõrk soovitus sekkumise vastu",
                      "value": "suggest_against",
                      "direction": "negative"
                  },
                  {
                      "text": "Soovitus",
                      "value": "suggest_either",
                      "direction": "none"
                  }
              ],
              "content": ""
          },
          "indications": {
              "description": "Indications",
              "content": ""
          },
          "benefits": {
              "description": "Benefits",
              "content": ""
          },
          "harms": {
              "description": "Harms",
              "content": ""
          },
          "frequencyDoseDuration": {
              "description": "Frequency/Dose/Duration",
              "content": ""
          },
          "indicationsForDiscontinuation": {
              "description": "Indications for discontinuation",
              "content": ""
          },
          "rationale": {
              "description": "Rationale",
              "content": ""
          },
          "evidence": {
              "description": "Evidence",
              "content": ""
          },
          "monitoringAndEvaluationQI": {
              "description": "Jälgimine ja hindamine",
              "content": ""
          },
          "clinicalConsiderations": {
              "description": "Clinical considerations",
              "content": ""
          }
      }
  },
  "presentations": {
      "sections": {
          "clinicians": {
              "name": "Tervishoiutöötajad",
              "sections": {
                  "background": {
                      "name": "Taust"
                  },
                  "detailedJustification": {
                      "name": "Üksikasjalik põhjendus"
                  },
                  "justification": {
                      "name": "Põhjendus"
                  },
                  "subgroupConsiderations": {
                      "name": "Kaalutlused alamrühmade osas"
                  },
                  "summaryOfFindings": {
                      "name": "Tõendusmaterjali kokkuvõte"
                  },
                  "relatedRecommendations": {
                      "name": "Seotud soovitused"
                  },
                  "implementationConsiderations": {
                      "name": "Rakenduskaalutlused"
                  }
              }
          },
          "patients": {
              "name": "Patsiendid",
              "sections": {
                  "whyThisRecommendation": {
                      "name": "Miks see soovitus?",
                      "parts": {
                          "whyThisRecommendation": {
                              "name": "Miks see soovitus",
                              "content": ""
                          },
                          "benefitsAndHarms": {
                              "name": "Hüved ja kahjud",
                              "content": ""
                          },
                          "additionalInformation": {
                              "name": "Lisateave",
                              "content": ""
                          }
                      },
                      "sectionsOrder": [
                          "whyThisRecommendation",
                          "benefitsAndHarms",
                          "additionalInformation"
                      ]
                  },
                  "whatItMeansForYou": {
                      "name": "Mida see Sulle tähendab?",
                      "parts": {
                          "whatYouCanDo": {
                              "name": "Mida saad teha",
                              "content": ""
                          },
                          "speakWithHCProfessional": {
                              "name": "Räägi oma tervishoiutöötajaga",
                              "content": "",
                              "additionalFields": [
                                  {
                                      "content": ""
                                  },
                                  {
                                      "content": ""
                                  }
                              ]
                          }
                      },
                      "sectionsOrder": [
                          "whatYouCanDo",
                          "speakWithHCProfessional"
                      ]
                  },
                  "whoIsThisFor": {
                      "name": "Kellele see on mõeldud?",
                      "parts": {
                          "whoIsThisFor": {
                              "name": "Kellele see on mõeldud",
                              "content": "",
                              "additionalFields": [
                                  {
                                      "content": ""
                                  }
                              ]
                          }
                      },
                      "sectionsOrder": [
                          "whoIsThisFor"
                      ]
                  },
                  "summaryOfFindings": {
                      "name": "SoF"
                  },
                  "relatedRecommendations": {
                      "name": "Seotud soovitused"
                  }
              }
          },
          "policymakers": {
              "name": "Tervishoiupoliitika kujundajad",
              "sections": {
                  "assessment": {
                      "name": "Hindamine"
                  },
                  "background": {
                      "name": "Taust",
                      "description": "Küsimuse üksikasjad soovituse jaoks",
                      "details": {
                          "name": "Teave otsuse kohta",
                          "sections": {
                              "setting": {
                                  "description": "Kontekst",
                                  "content": ""
                              },
                              "perspective": {
                                  "description": "Vaatenurk",
                                  "content": ""
                              },
                              "decisionMakers": {
                                  "description": "Otsustajad",
                                  "content": ""
                              },
                              "date": {
                                  "description": "Kuupäev",
                                  "content": ""
                              }
                          },
                          "sectionsOrder": [
                              "setting",
                              "perspective",
                              "decisionMakers",
                              "date"
                          ]
                      }
                  },
                  "decision": {
                      "name": "Otsus",
                      "content": "",
                      "selectedOption": "",
                      "options": [
                          {
                              "text": "Täielik rakendamine",
                              "value": "full_implementation"
                          },
                          {
                              "text": "Mõju hindamine",
                              "value": "impact_evaluation"
                          },
                          {
                              "text": "Pilootuuring",
                              "value": "pilot_study"
                          },
                          {
                              "text": "Edasi lükata",
                              "value": "postpone"
                          },
                          {
                              "text": "Mitte rakendada",
                              "value": "do_not_implement"
                          }
                      ]
                  },
                  "implementation": {
                      "name": "Rakendus"
                  },
                  "justification": {
                      "name": "Põhjendus"
                  },
                  "monitoringAndEvaluation": {
                      "name": "Jälgimine ja hindamine"
                  },
                  "summaryOfFindings": {
                      "name": "Tõendusmaterjali kokkuvõte"
                  },
                  "relatedRecommendations": {
                      "name": "Seotud soovitused"
                  }
              }
          }
      }
  }
}
; };
