{ MessageEditedModal, VotingModal } = require 'components/administration/voting/voting_modals'
ContentEditable = require 'components/common/content_editable'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
DueDateMixin = require 'components/mixins/due_date_mixin'
Validation = require 'base/validation'
GdtEditor = Editor.Editor
VotingMessageForm = require 'components/common/voting_message_form'
{ string, instanceOf, func, oneOfType } = PropTypes

SendVoteModal = createReactClass
  displayName: 'SendVoteModal'

  propTypes:
    type: string.isRequired
    message: oneOfType([instanceOf(Immutable.Map), string]).isRequired
    questions: instanceOf(Immutable.List).isRequired
    onClose: func.isRequired
    handleSending: func.isRequired

  mixins: [
    Translation('voting:voting.modals'),
    DueDateMixin
  ]

  keepOriginalMessage: true

  getInitialState: ->
    if @props.type is 'start'
      dueDateTimestamp: @props.dueDate
      contactEmail: @props.contactEmail
    else
      {}

# GETTERS, HELPERS
  _getMessageText: ->
    return Immutable.Map() if _.isEmpty ReactDOM.findDOMNode(@votingMessageForm.editor).textContent
    @votingMessageForm.editor.getEditorContent()

  _getDataForSending: ->
    if @props.type is 'start'
      emailBody: @_getMessageText()
      contactEmail: ReactDOM.findDOMNode(@votingMessageForm.refs.contactEmail).value
      dueDateTimestamp: @state.dueDateTimestamp
    else
      emailBody: @_getMessageText()

  _wasMessageEdited: ->
    message = @_getMessageText()
    not message.equals(@props.message) and not message.isEmpty()

  _isDataValid: (fieldName, text) ->
    switch fieldName
      when 'contactEmail' then Validation.isValidEmail text
      when 'emailBody'
        not _.isEmpty ReactDOM.findDOMNode(@votingMessageForm.editor).textContent

  _dataValidator: (data) -> (result, fieldName) =>
    switch fieldName
      when 'contactEmail', 'emailBody'
        if @_isDataValid fieldName, data[fieldName]
          result
        else
          {success: false, failedFields: result.failedFields.concat [fieldName]}
      else
        result

  validateData: (data) ->
    validationFields = ['emailBody']
    validationFields.push 'contactEmail' if @props.type is 'start'
    validationFields.reduce @_dataValidator(data), {success: true, failedFields: []}

  _getSendVoteMessage: ->
    if @state.message? then @state.message else @props.message

  _questionsMapper: (q, idx) ->
    if @props.type is 'reminder'
      dueDateTimestamp = q.get 'dueDateTimestamp'
      dueDateClass = @_getDueDateClassNames dueDateTimestamp

      <span key={idx}>
        <span className="question-text">{q.get 'text'}</span>
        <span className=dueDateClass>{@_timestampToDate dueDateTimestamp}</span>
      </span>
    else
      <span key={idx}>{q.get 'text'}</span>

# EVENT HANDLERS

  setDueDate: (e, date, { disabled }) ->
    return if disabled
    @setState dueDateTimestamp: date.getTime()

  closeModal: ->
    data = @_getDataForSending()
    # Don't pass 'emailBody' if it was edited and user decided not to save edited text
    if @keepOriginalMessage
      data = _.omit data, 'emailBody'

    @props.onClose data

  onSend: ->
    data = @_getDataForSending()
    validation = @validateData data
    if validation.success
      @props.handleSending data
      @props.onClose()
    else
      @setState validationFault: validation.failedFields

  onCancel: ->
    if @_wasMessageEdited()
      @setState messageEditedModal: true
    else
      @closeModal()

  onKeepUpdated: ->
    @keepOriginalMessage = false
    @closeModal()

# RENDER

  render: ->
    title = if @props.type is 'start'
      @i18n('message_to_members')
    else
      @i18n('reminder_to_members')

    <VotingModal onClose={@onCancel}>
      <div className="voting-message">
        <h1>{title}</h1>
        <VotingMessageForm
          ref={(e) => @votingMessageForm = e}
          type={@props.type}
          message={@props.message}
          contactEmail={@state.contactEmail}
          dueDateTimestamp={@state.dueDateTimestamp}
          validationFault={@state.validationFault}
          setDueDate={@setDueDate}
        />

        <div className="details-block">
          {if @props.type is 'start'
            <div>
              <span className="section-caption">{@i18n 'voting_etd_parts'}</span>
              <span>: </span>
              <span>{@props.etdPartLabels.join ', '}</span>
            </div>
          }
          {if @props.type is 'reminder'
            <div className="reminder-details">
              <span className="section-caption">
                {@i18n 'reminding_questions'}
              </span>
              <span className="section-caption due-date">
                {@i18n 'due_date'}
              </span>
              <div className="questions-list">
                {@props.questions.map @_questionsMapper}
              </div>
            </div>
          else
            <div>
              <span className="section-caption">
                {@i18n 'voting_questions'}
              </span>
              <div className="questions-list">
                {@props.questions.map @_questionsMapper}
              </div>
            </div>
          }
          <div className="coi-questions-info">
            {@i18n 'coi_information'}
          </div>
        </div>
        <div className="buttons">
          <button className="btn btn-cancel" onClick={@onCancel}>
            {@i18n '/settings:etd_templates.cancel'}
          </button>
          <button className="btn btn-success" onClick={@onSend}>
            {if @props.type is 'start' then @i18n 'send_to_all' else @i18n 'remind_not_voted'}
          </button>
        </div>
        {if @state.messageEditedModal
          <MessageEditedModal onNo={@closeModal} onYes={@onKeepUpdated} />
        }
      </div>
    </VotingModal>

module.exports = SendVoteModal
