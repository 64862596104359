Tooltip = require 'components/common/tooltip'

holderStyles =
  position: 'absolute'
  minWidth: '100%'
  top: 0
  bottom: 0
  left: 0

containerStyles =
  position: 'relative'
  display: 'inline-block'

DisabledElementTooltip = createReactClass
  displayName: 'DisabledElementTooltip'

  render: ->
    Child = React.Children.only @props.children
    title = Child.props.title
    isDisabled = Child.props.disabled

    if isDisabled
      <div style={containerStyles}>
        {React.cloneElement Child}
        <Tooltip>
          <div style={holderStyles} title={title} />
        </Tooltip>
      </div>
    else
      <Tooltip>
        {Child}
      </Tooltip>

module.exports = DisabledElementTooltip
