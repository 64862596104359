ConclusionsSection = require 'components/etd/conclusions_section'
ConslusionsSectionVotingDetailsRow =
  require 'components/etd/conclusions_section_voting_details_row'
VotingTitleRow = require 'components/etd/voting_section_title_row'
EtdSectionWithVotingMixin = require 'components/mixins/etd_section_with_voting_data_mixin'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
mediator = require 'mediator'


ConclusionsSectionWithVoting = createReactClass
  displayName: 'ConclusionsSectionWithVoting'

  propTypes: {
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired,
  }

  mixins: [
    CustomRenderMixin
    EtdSectionWithVotingMixin 'conclusions'
  ]

  render: ->
    votingProps = @_getVotingProps()

    <table className="standard-table recommendation-table-static">
      <ConclusionsSection
        adolopments={@props.adolopments}
        renderMode={@props.renderMode}
        isConsensus={@props.isConsensus}
        hasVotingStarted={true}
        editable={@props.editable}
        sectionId={@props.sectionId}
        section={@props.section}
        assessmentSections={@props.assessmentSections}
        etdViewSettings={@props.etdViewSettings}
        contentColSpan={2}
      />
      <tbody>
        <VotingTitleRow
          renderMode={@props.renderMode}
          sectionId={@props.sectionId}
          sectionName={@props.section.get 'description'}
          {...votingProps}
        />
        <ConslusionsSectionVotingDetailsRow
          sectionId={@props.sectionId}
          renderMode={@props.renderMode}
          isProposedVoting={@props.isProposedVoting}
          noOfVotingForms={@props.noOfVotingForms}
          {...votingProps}
        />
      </tbody>
    </table>

module.exports = ConclusionsSectionWithVoting
