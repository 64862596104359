var GdtTableNode, gdtNodeProto, nodeReducer, validateNode, _getNodeMaxRowSpan, _getTableRowTotalColSpan,
  __slice = [].slice;

_getTableRowTotalColSpan = function(rowCells) {
  return _.reduce(rowCells, (function(acc, cell) {
    return acc + cell.attrs.colSpan;
  }), 0);
};

_getNodeMaxRowSpan = function(totalRowsCount, pointer, rows) {
  var extraColSpan, maxRowSpan, nextRow, nextRowTotalColSpan, pointedRow, pointedRowTotalColSpan, rowSpan;
  maxRowSpan = totalRowsCount - pointer;
  if (maxRowSpan === 1) {
    return maxRowSpan;
  }
  pointedRow = rows[pointer];
  pointedRowTotalColSpan = _getTableRowTotalColSpan(pointedRow);
  rowSpan = 1;
  while ((pointer + rowSpan) < totalRowsCount) {
    nextRow = rows[pointer + rowSpan];
    if (nextRow != null) {
      nextRowTotalColSpan = _getTableRowTotalColSpan(nextRow);
      if (nextRowTotalColSpan <= pointedRowTotalColSpan && _.size(nextRow) < _.size(pointedRow)) {
        extraColSpan = _.reduce(pointedRow, (function(acc, c) {
          if (c.rowSpan > 1) {
            return acc + c.colSpan;
          } else {
            return acc;
          }
        }), 0);
        nextRowTotalColSpan += extraColSpan;
      }
      if (pointedRowTotalColSpan > 0 && pointedRowTotalColSpan < nextRowTotalColSpan) {
        return rowSpan;
      }
    }
    rowSpan++;
  }
  return rowSpan;
};

nodeReducer = function(_arg, node) {
  var attrs, childNodes, colSpan, id, pointer, rowSpan, rows, totalRowsCount;
  pointer = _arg.pointer, totalRowsCount = _arg.totalRowsCount, rows = _arg.rows;
  id = node.id, attrs = node.attrs, childNodes = node.childNodes;
  rowSpan = attrs.rowSpan != null ? attrs.rowSpan : node.isLeaf() ? _getNodeMaxRowSpan(totalRowsCount, pointer, rows) : id != null ? 1 : 0;
  if (id != null) {
    if (rows[pointer] == null) {
      rows[pointer] = [];
    }
    colSpan = attrs.colSpan != null ? attrs.colSpan : node.getColsCount() || 1;
    rows[pointer].push({
      id: node.id,
      attrs: R.mergeRight(attrs, {
        rowSpan: rowSpan,
        colSpan: colSpan
      })
    });
  }
  if (!node.isLeaf()) {
    _.reduce(node.childNodes, nodeReducer, {
      pointer: pointer + rowSpan,
      totalRowsCount: totalRowsCount,
      rows: rows
    });
  }
  return {
    pointer: pointer,
    totalRowsCount: totalRowsCount,
    rows: rows
  };
};

gdtNodeProto = {
  isLeaf: function() {
    return _.isEmpty(this.childNodes);
  },
  getRowsCount: function() {
    var nodeRowSpan, _ref;
    nodeRowSpan = this.id != null ? (_ref = this.attrs.rowSpan) != null ? _ref : 1 : 0;
    if (this.isLeaf()) {
      return nodeRowSpan;
    }
    return nodeRowSpan + Math.max.apply(null, _.map(this.childNodes, GdtTableNode.getNodeRowsCount));
  },
  getLeaves: function() {
    if (this.isLeaf()) {
      return [];
    }
    return _.reduce(this.childNodes, (function(acc, node) {
      return acc.concat(node.isLeaf() ? node : node.getLeaves());
    }), []);
  },
  getLeavesCount: function() {
    return _.size(this.getLeaves());
  },
  getColsCount: function() {
    var counter;
    counter = function(acc, node) {
      var _ref;
      return acc + ((_ref = node.attrs.colSpan) != null ? _ref : 1);
    };
    return R.reduce(counter, 0, this.getLeaves());
  },
  toRowsList: function() {
    var reduced, totalRowsCount;
    totalRowsCount = this.getRowsCount();
    reduced = _.reduce(this.childNodes, nodeReducer, {
      pointer: 0,
      totalRowsCount: totalRowsCount,
      rows: []
    });
    return reduced.rows;
  }
};

GdtTableNode = function() {
  var attrs, childNodes, id;
  id = arguments[0], attrs = arguments[1], childNodes = 3 <= arguments.length ? __slice.call(arguments, 2) : [];
  return _.extend(Object.create(gdtNodeProto), {
    id: id,
    attrs: attrs != null ? attrs : {},
    childNodes: _.flatten(childNodes)
  });
};

validateNode = function(node) {
  if (!gdtNodeProto.isPrototypeOf(node)) {
    throw new Error('Argument must be an instance of GdtTableNode');
  }
};

GdtTableNode.isLeafNode = function(node) {
  validateNode(node);
  return node.isLeaf();
};

GdtTableNode.getNodeRowsCount = function(node) {
  validateNode(node);
  return node.getRowsCount();
};

GdtTableNode.nodeToRowsList = function(node) {
  validateNode(node);
  return node.toRowsList();
};

GdtTableNode.cloneNode = function(node) {
  var attrs, childNodes, clonedAttrs, id;
  id = node.id, attrs = node.attrs, childNodes = node.childNodes;
  clonedAttrs = R.clone(attrs);
  return GdtTableNode(id, clonedAttrs, _.map(childNodes, GdtTableNode.cloneNode));
};

GdtTableNode.concatNodes = function(nodesList) {
  return _.reduceRight(_.initial(nodesList), (function(accNode, node) {
    var cloned;
    validateNode(node);
    cloned = GdtTableNode.cloneNode(node);
    if (cloned.isLeaf()) {
      cloned.childNodes.push(accNode);
    } else {
      cloned.childNodes[0].childNodes.unshift(accNode);
    }
    return cloned;
  }), GdtTableNode.cloneNode(_.last(nodesList)));
};

module.exports = GdtTableNode;
