IconButton = require './icon_button'

PasswordInput = createReactClass
  displayName: 'PasswordInput'

  getInitialState: ->
    mode: 'password'

  toggleMode: -> @setState (state) ->
    mode: if state.mode is 'password' then 'text' else 'password'

  render: ->
    <div className="password-input">
      <input {...@props} type={@state.mode} />
      <IconButton
        onClick={@toggleMode}
        iconName={if @state.mode is 'password' then 'blind-eye' else 'eye'}
      />
    </div>

module.exports = PasswordInput
