mediator = require 'mediator'
Modal = require 'components/common/modal'
{ useState } = React
RadioInput = require 'components/common/radio_input'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
{  mdgFormat } = require 'lib/references_utils'
{ useI18n, useSetState, useCoffeeMemo, useCoffeeCallback } = require 'lib/react_utils'

EXPORT_FORMATS = ['doc', 'html' ,'pdf']

INITIAL_STATE =
  exportFormat: ''
  studiesForExport: []

ExportDialog = ({ isOpen, onClose, question, references }) ->
  i18n = useI18n('projects:export_dialog')
  [{ exportFormat, studiesForExport }, setState] = useSetState INITIAL_STATE

  onChangeFormat = (e) ->
    setState exportFormat: e.target.value

  onChangeStudy = (e) ->
    studyId = e.target.value
    newValue =  if _.contains studiesForExport, studyId
      _.without studiesForExport, studyId
    else
      studiesForExport.concat studyId
    setState studiesForExport: newValue

  onCloseDialog = useCoffeeCallback [setState, onClose], ->
    setState INITIAL_STATE
    onClose()

  studiesIds = useCoffeeMemo [question], ->
    question.get('studies', Immutable.List()).map (study) -> study.get('id')
    .toJS()

  allSelected = useCoffeeMemo [studiesForExport, studiesIds], ->
    _.every studiesIds, (studyId) -> studyId in studiesForExport

  toggleAll = useCoffeeCallback [studiesForExport, studiesIds], ->
    if allSelected then setState studiesForExport: [] else setState studiesForExport: studiesIds

  findReference = useCoffeeCallback [references], (referenceId) ->
    references.find (reference) -> referenceId is reference.get('_id')

  onApply = ->
    mediator.services.mdgService.export i18n, question, studiesForExport, references, exportFormat

  <Modal
    isOpen={isOpen}
    modalSize="standard"
    closeButton={true}
    onClose={onCloseDialog}
  >
    <div className="export-dialog">
      <div className="studies-to-export mb-10">
        <div className="mb-10"><b>{i18n 'choose_studies_for_export'}</b></div>
        <ul className="studies-to-export__studies">
          <li className="select-all">
            <label className="flex flex-row items-center">
              <input
                type="checkbox"
                value='all'
                className="mr-10"
                  checked={allSelected}
                  onChange={toggleAll}
                />
              {i18n 'select_all'}
            </label>
            <hr/>
          </li>
          {question.get('studies', Immutable.List()).map (study) ->
            studyId = study.get('id')
            reference = findReference study.get 'referenceId'
            <li key={studyId}>
              <label className="flex flex-row items-center">
                <input
                  className="mr-10"
                  type="checkbox"
                  value={studyId}
                  onChange={onChangeStudy}
                  checked={studyId in studiesForExport}
                />
                {mdgFormat((reference or Immutable.List()).toJS()) or i18n 'no_reference_assigned'}
              </label>
            </li>
          }
        </ul>
      </div>
      <div className="mb-10"><b>{i18n 'choose_export_format'}</b></div>
      <div className="formats-list mb-10">
        {_.map EXPORT_FORMATS, (format) ->
          <label key={format} className="flex flex-row items-center">
            <RadioInput name="export-format" value={format} onChange={onChangeFormat} />
            <span>{i18n "types.#{format}"}</span>
          </label>
        }
      </div>
      <ApplyCancelButtons
        onApply={onApply}
        onCancel={onCloseDialog}
        applying={false}
        isSubmitEnabled={not _.isEmpty(exportFormat) and not _.isEmpty(studiesForExport)}
      />
    </div>
  </Modal>

ExportDialog.propTypes =
  isOpen: PropTypes.bool.isRequired
  onClose: PropTypes.func.isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  references: PropTypes.instanceOf(Immutable.List).isRequired

module.exports = ExportDialog
