Button = require 'components/common/button'
{ oneOfType, string, element, bool, func } = PropTypes

Collapsible = createReactClass
  displayName: 'Collapsible'

  propTypes:
    caption: oneOfType [ string, element ]
    defaultExpanded: bool
    onToggle: func

  getInitialState: ->
    expanded: @props.defaultExpanded or false

  onToggle: ->
    @setState expanded: not @state.expanded, => @props.onToggle?()

  _renderCaption: ->
    if React.isValidElement @props.caption
      @props.caption
    else
      <div className={@props.captionClass} onClick={@onToggle}>
        {@props.caption}
      </div>

  componentDidUpdate: (prevProps, prevState) ->
    if @props.defaultExpanded isnt prevProps.defaultExpanded
      @setState expanded: @props.defaultExpanded

  render: ->
    containerClass = classNames 'collapsible-container', @props.containerClass,
      if @state.expanded then 'expanded' else 'collapsed'
    toggleClass = classNames 'collapsible-toggle',
      if @state.expanded then 'expanded' else 'collapsed'

    <div className={containerClass} ref={(el) => @container = el}>
      <div ref={(el) => @caption = el} className='collapsible-container-caption'>
        {@_renderCaption()}
        <Button className={toggleClass} onClick={@onToggle} />
      </div>
      <div ref={(el) => @child = el} className='collapsible-container-content'>
        {if @state.expanded
          React.cloneElement React.Children.only @props.children
        }
      </div>
    </div>

module.exports = Collapsible
