{ ExtractionFormField, fieldShape } = require './extraction_form_field'

getParentValue = (data, fieldKey) ->
  switch fieldKey
    when 'subIntent' then data['intent']
    when 'score' then data['referenceId']
    else
      null

extractionFormShape = PropTypes.objectOf(
  PropTypes.arrayOf(
    PropTypes.shape(fieldShape)
  )
)

ExtractionForm = createReactClass
  displayName: 'ExtractionForm'

  propTypes:
    asterixedFields: PropTypes.arrayOf(PropTypes.string)
    className: PropTypes.string
    data: PropTypes.object
    form: extractionFormShape.isRequired
    i18n: PropTypes.func.isRequired
    inModal: PropTypes.bool
    renderMode: PropTypes.oneOf(['regular', 'printout'])
    onChange: PropTypes.func.isRequired

  getDefaultProps: ->
    asterixedFields: []
    className: null
    inModal: false
    renderMode: 'regular'

  render: ->
    {
      asterixedFields
      className
      data
      form
      i18n
      inModal
      onChange
      renderMode
    } = @props

    <div className={classNames 'extraction-form', className, 'in-modal': inModal}>
      {_.map form, (itemGroup, key) ->
        <div key={key} className="extraction-form__group">
        {key isnt 'main' and <h2 className="ml-10">{i18n key}</h2>}
        {_.map itemGroup, (item) ->
          { fieldKey } = item
          value = data[fieldKey]
          <ExtractionFormField
            i18n={i18n}
            key={fieldKey}
            onChange={onChange}
            renderMode={renderMode}
            parentValue={getParentValue(data, fieldKey)}
            value={value}
            withAsterix={fieldKey in asterixedFields}
            {...item}
          />
        }
        </div>
      }
    </div>

module.exports = { ExtractionForm, extractionFormShape }
