GradeProLogo = require 'components/common/grade_pro_logo'
Translation = require 'components/mixins/translation'
Validation = require 'base/validation'
mediator = require 'mediator'

PasswordResetForm = createReactClass
  displayName: 'PasswordResetForm'

  mixins: [Translation('registration')]

  propTypes:
    email: PropTypes.string
    onEmailChange: PropTypes.func
    logoSrc: PropTypes.string
    withTitle: PropTypes.bool
    withLabeledFields: PropTypes.bool
    withPlaceholders: PropTypes.bool
    service: PropTypes.shape
      resetPasswordForEmail: PropTypes.func.isRequired

  getDefaultProps: ->
    withLabeledFields: true
    withPlaceholders: false

  getInitialState: ->
    submitting: false
    success: false

  submit: (e) ->
    e.preventDefault()
    unless Validation.isValidEmail(@props.email)
      @setState(error: message: @i18n("/errors:authentication.invalid_email"))
      return
    @setState(submitting: true, error: null)
    @props.service.resetPasswordForEmail(@props.email).then =>
      @setState(success: true)
    , (error) =>
      @setState({error, submitting: false})

  getErrorMessage: -> __html: @state.error?.message

  render: ->
    { withTitle, withLabeledFields } = @props

    <form onSubmit={@submit}>
      <GradeProLogo logoSrc={@props.logoSrc} />
      {if withTitle
        <h1 className="title">{@i18n('password_recover_title')}</h1>
      }
      {if @state.success
        <div className="info">
          <p>{@i18n('password_reset_success')}</p>
          <p><a class="return" href="">{@i18n('return_to_login')}</a></p>
        </div>
      else
        <div>
          <div className="info">{@i18n('enter_email')}</div>
          {@state.error && (
            <div className="error-shared" dangerouslySetInnerHTML={@getErrorMessage()} />
          )}
          <label>
            {if withLabeledFields
              @i18n('email')
            }
            <input
              autoFocus={true}
              value={@props.email}
              onChange={@props.onEmailChange}
              disabled={@props.emailDisabled}
              placeholder={if @props.withPlaceholders then @i18n('email') else ''}
            />
          </label>

          <button
            type="submit"
            className={classNames 'btn btn-alternative btn-block', loading: @state.submitting}
            disabled={@state.submitting}>
            {@i18n('/actions.send_instructions')}
          </button>
        </div>
      }
    </form>

module.exports = PasswordResetForm
