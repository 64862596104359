var MembersActions, ReactComponent, ReactToolbarView, TeamMembersComponent, TeamMembersToolbar, TeamMembersView, View, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

MembersActions = require('actions/members_actions');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

TeamMembersToolbar = require('components/team/team_members_toolbar');

TeamMembersComponent = require('components/team/team_members');

mediator = require('mediator');

module.exports = TeamMembersView = (function(_super) {
  __extends(TeamMembersView, _super);

  function TeamMembersView() {
    return TeamMembersView.__super__.constructor.apply(this, arguments);
  }

  TeamMembersView.prototype.container = '#page-container';

  TeamMembersView.prototype.className = 'team-members-view';

  TeamMembersView.prototype.autoRender = true;

  TeamMembersView.prototype.initialize = function(options) {
    TeamMembersView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: TeamMembersToolbar
    }));
    this._stopInputsPropagation = false;
    return this.enable(ReactComponent);
  };

  TeamMembersView.prototype.afterRender = function() {
    TeamMembersView.__super__.afterRender.apply(this, arguments);
    return this.renderSimpleComponent(TeamMembersComponent, {
      projectId: mediator.project.id,
      organizationId: mediator.project.get('organizationId')
    });
  };

  TeamMembersView.prototype.dispose = function() {
    if (this.disposed) {
      return;
    }
    this.unmountComponent();
    return TeamMembersView.__super__.dispose.apply(this, arguments);
  };

  return TeamMembersView;

})(View);
