var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "<div class=\"row mt-10\">\n  "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "es:outcome.diagnostic.group_characteristics", {"name":"i18n","hash":{},"data":data})))
    + "\n</div>\n<div class=\"edit-input-block row mt-10\" data-property=\"prevalence1\">\n  <div class=\"percent\">\n    <input class=\"number short\" type=\"text\" name=\"prevalence1\" value=\""
    + escapeExpression(((helper = (helper = helpers.prevalence1 || (depth0 != null ? depth0.prevalence1 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1","hash":{},"data":data}) : helper)))
    + "\">\n  </div>\n  <textarea class=\"short cell-edit-textarea\" name=\"prevalence1Characteristics\"\n    id=\"prevalence1Characteristics\">"
    + escapeExpression(((helper = (helper = helpers.prevalence1Characteristics || (depth0 != null ? depth0.prevalence1Characteristics : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence1Characteristics","hash":{},"data":data}) : helper)))
    + "</textarea>\n  <div class=\"sup\"></div>\n  <div class=\"clearfix\"></div>\n</div>\n<div class=\"edit-input-block row mt-10\" data-property=\"prevalence2\">\n  <div class=\"percent\">\n    <input class=\"number short\" type=\"text\" name=\"prevalence2\" value=\""
    + escapeExpression(((helper = (helper = helpers.prevalence2 || (depth0 != null ? depth0.prevalence2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2","hash":{},"data":data}) : helper)))
    + "\">\n  </div>\n  <textarea class=\"short cell-edit-textarea\" name=\"prevalence2Characteristics\"\n    id=\"prevalence2Characteristics\">"
    + escapeExpression(((helper = (helper = helpers.prevalence2Characteristics || (depth0 != null ? depth0.prevalence2Characteristics : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence2Characteristics","hash":{},"data":data}) : helper)))
    + "</textarea>\n  <div class=\"sup\"></div>\n</div>\n<div class=\"edit-input-block row mt-10\" data-property=\"prevalence3\">\n  <div class=\"percent\">\n    <input class=\"number short\" type=\"text\" name=\"prevalence3\" value=\""
    + escapeExpression(((helper = (helper = helpers.prevalence3 || (depth0 != null ? depth0.prevalence3 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3","hash":{},"data":data}) : helper)))
    + "\">\n  </div>\n  <textarea class=\"short cell-edit-textarea\" name=\"prevalence3Characteristics\"\n    id=\"prevalence3Characteristics\">"
    + escapeExpression(((helper = (helper = helpers.prevalence3Characteristics || (depth0 != null ? depth0.prevalence3Characteristics : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prevalence3Characteristics","hash":{},"data":data}) : helper)))
    + "</textarea>\n  <div class=\"sup\"></div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "prevalences", "diagnostic.prevalences", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}