var DesignStudy, Exceptions, OUTCOME_FIELD_MAPPING, OUTCOME_FOOTNOTES_MAPPING, Outcome, Outcomes, Project, QUESTION_FIELD_MAPPING, Question, Questions, W, fromList, mediator, qualityModifier, utils, _safeNumber;

Project = require('models/project');

Outcome = require('models/outcome');

Question = require('models/question');

Questions = require('models/questions');

Outcome = require('models/outcome');

Outcomes = require('models/outcomes');

DesignStudy = require('models/outcome/design_studies');

Exceptions = require('lib/exceptions');

W = require('when');

utils = require('base/lib/utils');

mediator = require('mediator');

fromList = function(list) {
  return function(v) {
    var idx;
    idx = Number(v);
    if (idx) {
      return list[idx - 1];
    }
  };
};

_safeNumber = function(n) {
  n = Number(n);
  if (isNaN(n)) {
    return 0;
  } else {
    return n;
  }
};

qualityModifier = fromList(['None', 'Serious', 'VerySerious']);

QUESTION_FIELD_MAPPING = {
  guid: 'grd_id',
  profile_id: 'grd_profileId',
  text_1: 'intervention',
  text_2: 'comparison',
  text_3: 'healthProblemOrPopulation',
  settings: 'grd_settings',
  bibliography: 'bibliography',
  authors: 'authors',
  mod_date: function(v) {
    return this.set('importedDate', moment(_.isFinite(v) ? Number(v) : v).toDate());
  },
  minor_modddate: 'date',
  question: [
    'questionFormat', function(v) {
      var ending, lastWord, truncated;
      ending = this.get('healthProblemOrPopulation');
      truncated = v.slice(0, -(ending.length + 2));
      lastWord = truncated.slice(truncated.lastIndexOf(' ') + 1);
      if (lastWord === 'in') {
        return 'IN_POP';
      } else {
        return 'FOR_HP';
      }
    }
  ],
  sofTitle: 'sofTitle',
  titleEditable: [
    'manualSofTitle', function(v) {
      return Boolean(Number(v));
    }
  ],
  rDraftRecommendation: 'recommendationText',
  rPreferenceStatement: 'valuesPreferencesExplanation',
  rRemarks: 'remarksJustifications',
  rDraftChanged: [
    'grd_draftChanged', function(v) {
      return Boolean(Number(v));
    }
  ],
  rPreferenceChanged: [
    'grd_preferenceChanged', function(v) {
      return Boolean(Number(v));
    }
  ],
  rAbsenceExplanation: 'qualityExplanation',
  rUncertainBalanceExplanation: 'benefitsHarmsExplanation',
  rUncertainBenefitsExplanation: 'resourcesExplanation',
  rUncertainDifferanceExplanation: 'valuesPreferencesExplanation',
  rMarginalExplanation: 'grd_marginalExplanation',
  recommendationStrength: 'grd_recommendationStrength',
  recomendationQuality: ['qualityJudgement', fromList(['high', 'moderate', 'low', 'very_low'])],
  recommendationAbsence: 'grd_recommendationAbsence',
  recommendationUncertainBalance: 'grd_recommendationUncertainBalance',
  recommendationUncertainBenefits: 'grd_recommendationUncertainBenefits',
  recommendationUncertainDifferance: 'grd_recommendationUncertainDifferance',
  recommendationMarginal: 'grd_recommendationMarginal',
  rVoting1: 'grd_rVoting1',
  rVoting2: 'grd_rVoting2',
  rVoting3: 'grd_rVoting3',
  rVoting4: 'grd_rVoting4',
  rVoting5: 'grd_rVoting5',
  timeFrame: 'grd_timeFrame'
};

OUTCOME_FIELD_MAPPING = {
  guid: '_id',
  name: 'name',
  type: ['type', fromList(['dich', 'cont'])],
  importance: [
    'importance', function(v) {
      return _safeNumber(v) || null;
    }
  ],
  studies_no: 'noOfStudies',
  methodology_idx: [
    'designStudies', function(v) {
      var idx;
      idx = _safeNumber(v);
      if (!idx) {
        return;
      }
      if (idx === 2) {
        return 'ObsStudies';
      } else {
        return 'RandTrials';
      }
    }
  ],
  lin: ['riskOfBias', qualityModifier],
  inc: ['inconsistency', qualityModifier],
  uad: ['indirectness', qualityModifier],
  impr: ['imprecision', qualityModifier],
  rb: ['publicationBias', qualityModifier],
  lmof: ['largeEffect', fromList(['None', 'Large', 'VeryLarge'])],
  apcwre: ['plausibleConfounding', fromList(['None', 'ReducedForRR', 'IncreasedForRR'])],
  drg: ['doseResponseGradient', fromList(['None', 'Large'])],
  mod_date: 'grd_modificationDate',
  followup: 'followUpLength',
  period_no: ['followUpTimeUnit', fromList(['days', 'weeks', 'months', 'years', 'patientYears', 'others'])],
  intervention: [
    (function() {
      if (this.get('type') === 'dich') {
        return 'interventionCount';
      } else {
        return 'interventionTotal';
      }
    }), _safeNumber
  ],
  intTotal: [
    (function() {
      if (this.get('type') === 'dich') {
        return 'interventionTotal';
      }
    }), _safeNumber
  ],
  control: [
    (function() {
      if (this.get('type') === 'dich') {
        return 'controlCount';
      } else {
        return 'controlTotal';
      }
    }), _safeNumber
  ],
  ctrlTotal: [
    (function() {
      if (this.get('type') === 'dich') {
        return 'controlTotal';
      }
    }), _safeNumber
  ],
  relative: [
    'effectMeasure', function(v) {
      var map;
      map = this.get('type') === 'dich' ? {
        '0': 'RR',
        '1': 'OR',
        '2': 'HR',
        '5': 'other'
      } : {
        '0': 'MD',
        '1': 'SMD',
        '2': 'mean',
        '3': 'median',
        '5': 'other'
      };
      return map[v];
    }
  ],
  relativeOf: ['effectSize', _safeNumber],
  clFrom: ['confidenceIntervalFrom', _safeNumber],
  clTo: ['confidenceIntervalTo', _safeNumber],
  abs: ['absEffectSize', _safeNumber],
  absPeriod: [
    'absDenominator', function(v) {
      return [1000, 1000000, 100][_safeNumber(v)];
    }
  ],
  absCmb: ['absEffectAutoCalc', Boolean],
  mean_idx: ['followUpType', fromList(['mean', 'median', 'range'])],
  other: 'othersFollowUpTimeUnit',
  comment: 'comments',
  absClFrom: ['absEffectFrom', _safeNumber],
  absClTo: ['absEffectTo', _safeNumber],
  measure: 'measuredWith',
  assessment: 'measuredWith',
  relOther: 'otherMeasure',
  lowRiskValue: ['lowControlRisk', _safeNumber],
  mediumRiskValue: ['moderateControlRisk', _safeNumber],
  highRiskValue: ['highControlRisk', _safeNumber],
  riskChecks: function(v) {
    this.set('calculatedControl', Boolean(v & 1));
    this.set('lowControl', Boolean(v & 2));
    this.set('moderateControl', Boolean(v & 4));
    return this.set('highControl', Boolean(v & 8));
  },
  baseCombo: 'grd_baseCombo',
  baseValue: 'grd_baseValue',
  baseMeasure: 'grd_baseMeasure',
  autoAbs: ['absEffectAutoCalc', _safeNumber],
  metaType: [
    'metaType', function(v) {
      return ['POOLED', 'NOT_POOLED', 'NOT_MEASURED', 'NOT_REPORTED', 'SINGLE_STUDY', 'RANGE_OF_EFFECTS'][_safeNumber(v)];
    }
  ],
  possibleScoreFrom: 'grd_possibleScoreFrom',
  possibleScoreTo: 'grd_possibleScoreTo',
  wasRD: 'grd_wasRD',
  oldR: 'grd_oldClFrom',
  oldClFrom: 'grd_oldClFrom',
  oldClTo: 'grd_oldClTo',
  wasImported: 'grd_wasImported',
  observationalMethodology: [
    'designStudy', fromList(DesignStudy.ObsStudies.values.map(function(v) {
      return "ObsStudies." + v;
    }))
  ],
  shortName: 'shortName',
  headerCER1: 'lowControlLabel',
  headerCER2: 'moderateControlLabel',
  headerCER3: 'highControlLabel'
};

OUTCOME_FOOTNOTES_MAPPING = {
  importance: 'importance',
  studies_no: 'noOfStudies',
  methodology_idx: 'designStudies',
  lid: 'riskOfBias',
  inc: 'inconsistency',
  uad: 'indirectness',
  imp: 'imprecision',
  rb: 'otherConsiderations',
  lmof: 'otherConsiderations',
  apcwre: 'otherConsiderations',
  drg: 'otherConsiderations',
  followup: 'followUpLength',
  intervention: 'intervention',
  ctrl: 'control',
  relative: 'relativeEffect',
  abs: 'absoluteEffect',
  measure: 'measuredWith',
  assessment: 'measuredWith',
  ctrlrisk: 'control',
  meta: 'metaType',
  submethodology: 'designStudies'
};

module.exports = {
  parseFile: function(file) {
    return W.promise((function(_this) {
      return function(resolve, reject) {
        var reader;
        reader = new FileReader();
        reader.onload = function(loadEvent) {
          var err;
          try {
            return resolve(_this._parse(loadEvent.target.result));
          } catch (_error) {
            err = _error;
            return reject(err);
          }
        };
        return reader.readAsArrayBuffer(file);
      };
    })(this));
  },
  _parse: function(data) {
    var db, error, project, projects, _i, _len, _results;
    db = SQL.open(new Uint8Array(data));
    try {
      projects = this._getProjectInfo(db);
      _results = [];
      for (_i = 0, _len = projects.length; _i < _len; _i++) {
        project = projects[_i];
        _results.push({
          project: project,
          questions: this._getQuestions(db, project)
        });
      }
      return _results;
    } catch (_error) {
      error = _error;
      if (typeof error.match === "function" ? error.match(/SQLite exception: 26/) : void 0) {
        throw new Exceptions["import"].unrecognized_grd;
      } else if (typeof error.match === "function" ? error.match(/SQLite exception: 11/) : void 0) {
        throw new Exceptions["import"].malformed_grd_file;
      } else {
        throw error;
      }
    } finally {
      db.close();
    }
  },
  _getProjectInfo: function(db) {
    var profileGroup, _i, _len, _ref, _results;
    _ref = db.exec('SELECT group_id, group_name FROM profilegroups ORDER BY orderNo');
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      profileGroup = _ref[_i];
      _results.push(new Project({
        'name': profileGroup[1].value,
        'GRADEproId': profileGroup[0].value
      }));
    }
    return _results;
  },
  _getQuestions: function(db, project) {
    var column, field, footnote, footnotesList, gdtFootnoteId, noteId, outcome, outcomeRow, outcomes, question, questionRow, use, _i, _j, _k, _l, _len, _len1, _len2, _len3, _len4, _len5, _m, _n, _ref, _ref1, _ref2, _ref3, _results;
    _ref = db.exec("SELECT * FROM profiles WHERE group_idx = " + (project.get('GRADEproId')) + " ORDER BY orderNo");
    _results = [];
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      questionRow = _ref[_i];
      question = new Question();
      for (_j = 0, _len1 = questionRow.length; _j < _len1; _j++) {
        column = questionRow[_j];
        this._setValue(question, QUESTION_FIELD_MAPPING[column.column], column.value);
      }
      outcomes = new Outcomes();
      _ref1 = db.exec("SELECT * FROM outcomes WHERE profile_idx = " + (question.get('grd_profileId')) + " ORDER BY orderNo");
      for (_k = 0, _len2 = _ref1.length; _k < _len2; _k++) {
        outcomeRow = _ref1[_k];
        outcome = new Outcome();
        for (_l = 0, _len3 = outcomeRow.length; _l < _len3; _l++) {
          column = outcomeRow[_l];
          this._setValue(outcome, OUTCOME_FIELD_MAPPING[column.column], column.value);
        }
        outcome.recalculateOutcomeData();
        outcomes.add(outcome);
      }
      footnotesList = question.get('footnotesList');
      _ref2 = db.exec("SELECT note_id, note FROM footnotes WHERE profile_idx = " + (question.get('grd_profileId')) + " ORDER BY order_no");
      for (_m = 0, _len4 = _ref2.length; _m < _len4; _m++) {
        footnote = _ref2[_m];
        noteId = footnote[0].value;
        gdtFootnoteId = footnotesList.addNote(footnote[1].value);
        _ref3 = db.exec("SELECT footer_column, guid FROM outcome_footer JOIN outcomes ON outcome_id = outcome_idx WHERE note_idx = " + noteId);
        for (_n = 0, _len5 = _ref3.length; _n < _len5; _n++) {
          use = _ref3[_n];
          field = this._getPropertyForOutcomeFootnote(use[0].value);
          if (field) {
            outcomes.get(use[1].value).bindFootnote(gdtFootnoteId, field);
          }
        }
      }
      question.set('outcomes', outcomes);
      _results.push(question);
    }
    return _results;
  },
  _setValue: function(model, mapping, value) {
    var key;
    if (!mapping) {
      mediator.log("Empty mapping for value '" + value + "'");
      return;
    }
    if (_(mapping).isFunction()) {
      return mapping.call(model, value);
    } else if (_(mapping).isArray()) {
      key = _(mapping[0]).isFunction() ? mapping[0].call(model) : mapping[0];
      if (key) {
        return model.set(key, mapping[1].call(model, value), {
          silent: true
        });
      }
    } else {
      return model.set(mapping, value, {
        silent: true
      });
    }
  },
  _getPropertyForOutcomeFootnote: function(column) {
    return OUTCOME_FOOTNOTES_MAPPING[column];
  }
};
