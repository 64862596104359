ApprovalDetails = require 'components/scope/questions/approval_details'
Todos = require 'components/common/todos'
CheckboxInput = require 'components/common/checkbox_input'
PreviousComments = require 'components/scope/questions/grouped_comments_blocks'
MembersCommentsBlock = require 'components/scope/members_comments'
ApprovalStep = require('lib/questions_helper').QUESTION_GENERATION_STEPS[5]
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'

ApprovalComments = createReactClass
  displayName: 'ApprovalComments'

  propTypes:
    approvalsByValue: PropTypes.instanceOf(Immutable.Map).isRequired
    approvalComments: PropTypes.instanceOf(Immutable.Map).isRequired
    membersMap: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [
    CustomRenderMixin
    Translation('scope:questions')
  ]

  _getCommentsByApproval: ->
    getCommentsByApproval = (value) =>
      @props.approvalsByValue.get(value, Immutable.Map())
        .filter (v, memberId) => @props.approvalComments.has memberId
        .mapEntries ([memberId, v]) => [memberId, @props.approvalComments.get memberId]
    # return a tupple of [disagreeComments, agreeComments]
    [ getCommentsByApproval('no'), getCommentsByApproval 'yes' ]

  render: ->
    [ disagreeComments, agreeComments ] = @_getCommentsByApproval()

    <div>
      <MembersCommentsBlock
        blockTitleText={"#{@i18n 'approval.disagreement_comments_title'}:"}
        membersComments={disagreeComments}
        membersMap={@props.membersMap}
      />
      {unless agreeComments.isEmpty()
        <MembersCommentsBlock
          blockTitleText={"#{@i18n 'approval.agreement_comments_title'}:"}
          membersComments={agreeComments}
          membersMap={@props.membersMap}
        />
      }
    </div>

QuestionApprovalDetailsExtended = createReactClass
  displayName: 'QuestionApprovalDetailsExtended'

  propTypes:
    question: PropTypes.instanceOf(Immutable.Map).isRequired
    membersMap: PropTypes.instanceOf(Immutable.Map).isRequired
    setDisagreementResolved: PropTypes.func.isRequired
    saveTodos: PropTypes.func
    onApplyAdminComment: PropTypes.func
    readOnly: PropTypes.bool

  mixins: [
    CustomRenderMixin
    Translation('scope:questions')
  ]

  _getOriginalPriority: ->
    @props.question.get 'approvalStatusOriginal', @props.question.get 'approvalStatus'

  _isDisagreementResolved: ->
    @props.question.get 'priorityDisagreementResolved', false

  _getPreviousStepsComments: ->
    @props.question.get('memberComments', Immutable.Map())
      .filterNot (comments, stepName) -> stepName is ApprovalStep

  getAdminComment: ->
    @approvalAdminComment.value

  saveTodos: (todos) ->
    questionId = @props.question.get 'id'
    @props.saveTodos { questionId, todos }

  toggleDisagreementResolution: ->
    currentState = @_isDisagreementResolved()
    questionId = @props.question.get 'id'
    @props.setDisagreementResolved { questionId, value: not currentState }

  render: ->
    approvalComments = @props.question.getIn ['memberComments', ApprovalStep]
    approvalsByValue = @props.question.get('approvals').groupBy (v) -> v
    approvalAdminComment = @props.question.getIn ['adminComments', ApprovalStep]

    <div className='question-details'>
      <div className='step-name'>
        {@i18n '../outcomes.approval_phase'}
      </div>
      <div className='extended-details-container'>
        <div className='extended-details-block'>
          <ApprovalDetails
            approvals={@props.question.get 'approvals'}
            originalPriority={@_getOriginalPriority()}
            groupType={@props.groupType}
          />
          <CheckboxInput
            id='disagreement'
            defaultChecked={@_isDisagreementResolved()}
            onChange={@toggleDisagreementResolution}
            disabled={@props.readOnly}
          />
          <label htmlFor='disagreement'>
            <b> {@i18n 'approval.disagreement_resolved'}</b>
          </label>
        </div>
        <div className='extended-details-block with-todos'>
          <div className='my-comment'>
            <div className='title'><b>{@i18n '../outcomes.my_comment'}:</b></div>
            {if @props.readOnly
              <div className='admin-comment'>
                {approvalAdminComment}
              </div>
            else
              <textarea
                ref={(el) => @approvalAdminComment = el}
                defaultValue={approvalAdminComment}
              />
            }
          </div>
          <div>
            <div className='title'><b>{@i18n 'approval.to_do'}:</b></div>
            <Todos
              todos={@props.todos}
              saveTodos={@saveTodos}
              readOnly={@props.readOnly}
            />
          </div>
        </div>
      </div>
      <ApprovalComments
        approvalsByValue={approvalsByValue}
        approvalComments={approvalComments}
        membersMap={@props.membersMap}
      />
      <PreviousComments
        itemIdOrKey={@props.question.get 'id'}
        membersComments={@_getPreviousStepsComments()}
        adminComments={@props.question.get 'adminComments'}
        membersMap={@props.membersMap}
        onApplyAdminComment={@props.onApplyAdminComment}
        readOnly={@props.readOnly}
      />
    </div>

module.exports = QuestionApprovalDetailsExtended
