var Trait;

module.exports = Trait = (function() {
  function Trait(options) {
    this.options = options != null ? options : {};
    _(this).extend(this.options);
  }

  Trait.prototype.apply = function(clazz) {
    return this._setTraitsAttribute(clazz, this);
  };

  Trait.prototype._setTraitsAttribute = function(clazz, trait) {
    if (_.functionName(trait.constructor) === 'Trait') {
      return;
    }
    if (clazz.traits) {
      clazz.traits.push(_.functionName(trait.constructor));
    } else {
      clazz.traits = [_.functionName(trait.constructor)];
    }
    return this._setTraitsAttribute(clazz, trait.constructor.__super__);
  };

  Trait.prototype._addFunction = function(clazz, name, f) {
    var originalFunction;
    if (f == null) {
      f = this[name];
    }
    originalFunction = clazz[name];
    if (originalFunction && !_(originalFunction).isFunction()) {
      throw new Error("Property " + name + " is not a function in " + clazz);
    }
    f = _(originalFunction).wrap(f);
    return clazz[name] = _(f).bind(clazz, this);
  };

  Trait.prototype.detachAttrs = function() {
    var key, _i, _len, _ref;
    _ref = _(this.options).keys();
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      key = _ref[_i];
      this[key] = null;
    }
    return this.options = null;
  };

  return Trait;

})();
