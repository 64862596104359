var QuestionExporter, qualities;

qualities = require('models/outcome/qualities');

module.exports = QuestionExporter = (function() {
  function QuestionExporter(_originalQuestion, _questionType, _referencesExporter) {
    this._originalQuestion = _originalQuestion;
    this._questionType = _questionType;
    this._referencesExporter = _referencesExporter;
    this._outcomesTypes = {
      'TP': 'TruePositive',
      'TN': 'TrueNegative',
      'FP': 'FalsePositive',
      'FN': 'FalseNegative',
      'Inconclusive': 'InconclusiveTestResult',
      'Complications': 'Complication'
    };
    this._outcomesNameConversion = {
      'TP': $.t('es:outcome.diagnostic.TP'),
      'TN': $.t('es:outcome.diagnostic.TN'),
      'FP': $.t('es:outcome.diagnostic.FP'),
      'FN': $.t('es:outcome.diagnostic.FN')
    };
  }

  QuestionExporter.prototype._convertOutcomeName = function(originalName) {
    if (originalName in this._outcomesNameConversion) {
      return this._outcomesNameConversion[originalName];
    } else {
      return originalName;
    }
  };

  QuestionExporter.prototype._getOutcomeId = function(outcome) {
    switch (outcome['name']) {
      case 'TP':
        return 'outcomes/TruePositive';
      case 'FP':
        return 'outcomes/FalsePositive';
      case 'TN':
        return 'outcomes/TrueNegative';
      case 'FN':
        return 'outcomes/FalseNegative';
      case 'Inconclusive':
        return 'outcomes/InconclusiveTestResult';
      case 'Complications':
        return 'outcomes/Complication';
      default:
        return "outcomes/" + outcome['_id'];
    }
  };

  QuestionExporter.prototype._processOutcome = function(outcome) {
    var exportedOutcome, name, originalName, types;
    originalName = outcome['name'];
    if ((originalName != null ? originalName.trim() : void 0) === 'Cost' && this._questionType === 'diagnostic') {
      return void 0;
    }
    name = this._convertOutcomeName(originalName);
    types = ['Outcome'];
    if (outcome['type'] === 'time_to_event') {
      types.push('TimeToEvent');
    }
    if (originalName in this._outcomesTypes) {
      types.push(this._outcomesTypes[originalName]);
    }
    exportedOutcome = {
      '@id': this._getOutcomeId(outcome),
      '@type': types,
      'name': this.getValueAndFootnotes(outcome, 'name', 'value', name)
    };
    if (outcome['type'] === 'time_to_event') {
      if (outcome['eventType'] !== 'event') {
        exportedOutcome['event'] = {
          '@type': 'NonEvent',
          'name': this.getValueAndFootnotes(outcome, 'nonEvent', 'value')
        };
      } else {
        exportedOutcome['event'] = {
          '@type': 'Event'
        };
      }
    }
    return exportedOutcome;
  };

  QuestionExporter.prototype.exportOutcomes = function() {
    var exportedOutcomes, originalOutcomes, outcome, _i, _len;
    originalOutcomes = this._originalQuestion['outcomes'];
    exportedOutcomes = (function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = originalOutcomes.length; _i < _len; _i++) {
        outcome = originalOutcomes[_i];
        _results.push(this._processOutcome(outcome));
      }
      return _results;
    }).call(this);
    this._outcomes = {};
    for (_i = 0, _len = originalOutcomes.length; _i < _len; _i++) {
      outcome = originalOutcomes[_i];
      this._outcomes[outcome['_id']] = this._getOutcomeId(outcome);
    }
    return _.compact(exportedOutcomes);
  };

  QuestionExporter.prototype._processExplanation = function(explanation, i) {
    var exportedExplanation;
    exportedExplanation = {
      '@type': 'Explanation',
      '@id': "_:e" + i,
      'text': explanation
    };
    return exportedExplanation;
  };

  QuestionExporter.prototype.exportExplanations = function() {
    var explanation, exportedExplanations, footnotes, i, _ref;
    exportedExplanations = [this._processExplanation('no_explanation_provided', 0)];
    footnotes = (_ref = this._originalQuestion['footnotesList']) != null ? _ref['notes'] : void 0;
    for (i in footnotes) {
      explanation = footnotes[i];
      exportedExplanations.push(this._processExplanation(explanation, i));
    }
    this._explanations = {
      'no_explanation_provided': '_:e0'
    };
    for (i in footnotes) {
      this._explanations[i] = "_:e" + i;
    }
    return exportedExplanations;
  };

  QuestionExporter.prototype.findExplanations = function(explanations, key, score) {
    var foundExplanations, id;
    if ((explanations != null ? explanations[key] : void 0) == null) {
      if (score >= 0) {
        return [];
      } else {
        return [
          {
            '@id': '_:e0'
          }
        ];
      }
    } else {
      foundExplanations = (function() {
        var _i, _len, _ref, _results;
        _ref = explanations[key];
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          id = _ref[_i];
          _results.push({
            '@id': this._explanations[id.toString()]
          });
        }
        return _results;
      }).call(this);
      return _.chain(foundExplanations).reject(function(obj) {
        return obj === {} || !obj || !obj['@id'];
      }).sortBy(function(explanation) {
        return parseInt(explanation['@id'].replace('_:e', ''));
      }).value();
    }
  };

  QuestionExporter.prototype.findReferences = function(references, key) {
    var id;
    if ((references != null ? references[key] : void 0) != null) {
      return _.chain((function() {
        var _i, _len, _ref, _results;
        _ref = references[key];
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          id = _ref[_i];
          _results.push({
            '@id': this._referencesExporter.getReferenceJSONLDId(id)
          });
        }
        return _results;
      }).call(this)).filter(function(reference) {
        return reference['@id'] != null;
      }).sortBy(function(reference) {
        return parseInt(reference['@id'].replace('_:r', ''));
      }).value();
    } else {
      return [];
    }
  };

  QuestionExporter.prototype.findFootnotes = function(explanations, references, key, score) {
    var foundExplanations, foundReferences;
    foundExplanations = this.findExplanations(explanations, key, score);
    foundReferences = this.findReferences(references, key);
    return _.union(foundExplanations, foundReferences);
  };

  QuestionExporter.prototype._qualityNameForValue = function(qualityValue) {
    var quality;
    if (qualityValue > 4) {
      qualityValue = 4;
    }
    if (qualityValue < 1) {
      qualityValue = 1;
    }
    quality = qualities["" + qualityValue].toLowerCase().replace(/\ /g, "_");
    return $.t("es:outcome.qualities." + quality).toUpperCase();
  };

  QuestionExporter.prototype._getQualityOptionForLabel = function(qualityLabel, field) {
    if (field == null) {
      field = '';
    }
    if (qualityLabel === '') {
      return '';
    } else {
      switch (field) {
        case 'publicationBias':
          if (qualityLabel === 'not_serious') {
            return $.t('es:outcome.undetected');
          } else {
            return $.t('es:outcome.publication_bias_strongly_suspected_short');
          }
          break;
        default:
          return $.t("es:outcome." + qualityLabel);
      }
    }
  };

  QuestionExporter.prototype._qualityLabelForScore = function(score) {
    switch (score) {
      case 0:
        return 'NoChange';
      case -1:
        return 'DowngradeOneLevel';
      case -2:
        return 'DowngradeTwoLevels';
      case 1:
        return 'UpgradeOneLevel';
      case 2:
        return 'UpgradeTwoLevels';
      default:
        return null;
    }
  };

  QuestionExporter.prototype.getValueAndFootnotes = function(obj, fieldName, valueFieldName, value) {
    var footnotes, toReturn, _ref;
    if (valueFieldName == null) {
      valueFieldName = 'value';
    }
    if (value == null) {
      value = null;
    }
    footnotes = this.findFootnotes(obj['footnotes'], obj['references'], fieldName, 0);
    toReturn = {};
    toReturn[valueFieldName] = value != null ? value : (_ref = obj[fieldName]) != null ? _ref : '';
    if (footnotes.length > 0) {
      toReturn['footnote'] = footnotes;
    }
    return toReturn;
  };

  return QuestionExporter;

})();
