var DbHelper, DraftContentRenderer, EditorRenderMaps, HtmlExport, MobilePublicationService, QuestionGroupsActions, QuestionGroupsStore, QuestionsActions, QuestionsListComponentStore, QuestionsStore, W, getSectionContent, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

QuestionsActions = require('actions/questions_actions');

QuestionGroupsActions = require('actions/question_groups_actions');

QuestionGroupsStore = require('stores/question_groups_store');

QuestionsListComponentStore = require('stores/questions_list_component_store');

QuestionsStore = require('stores/questions_store');

DbHelper = require('base/lib/db_helper');

HtmlExport = require('lib/html_export');

W = require('when');

mediator = require('mediator');

DraftContentRenderer = require('lib/draft_utils').DraftContentRenderer;

EditorRenderMaps = require('lib/editor_render_maps');

getSectionContent = function(sectionData) {
  if (sectionData == null) {
    return '';
  }
  return sectionData.contentConsensus || sectionData.content;
};

module.exports = MobilePublicationService = (function() {
  function MobilePublicationService() {
    this.disposeHtmlCleaning = __bind(this.disposeHtmlCleaning, this);
  }

  MobilePublicationService.prototype._mapQualityJudgement = function(qualityJudgement) {
    var qualities;
    qualities = {
      'very_low': 1,
      'low': 2,
      'moderate': 3,
      'high': 4
    };
    return qualities[qualityJudgement];
  };

  MobilePublicationService.prototype._getSoF = function(question) {
    var htmlExport;
    htmlExport = new HtmlExport(question, 'sof');
    return htmlExport.getSource();
  };

  MobilePublicationService.prototype._getAttr = function(q, v, defaultKey) {
    var defaultValue, value, _ref, _ref1;
    defaultValue = defaultKey ? q.get(defaultKey) : void 0;
    value = (_ref = (_ref1 = q.get(v)) != null ? _ref1 : defaultValue) != null ? _ref : '';
    return this._cleanHtml(value);
  };

  MobilePublicationService.prototype._setupHtmlCleaning = function() {
    this._$tempEl = $('<div>');
    $(document.body).append(this._$tempEl);
    this._$tempEl.css({
      opacity: 0
    });
    return this._editor = new MediumEditor(this._$tempEl);
  };

  MobilePublicationService.prototype.disposeHtmlCleaning = function() {
    this._editor.destroy();
    return this._$tempEl.remove();
  };

  MobilePublicationService.prototype._cleanHtml = function(v) {
    this._$tempEl.focus();
    this._$tempEl.html('');
    this._editor.cleanPaste(v);
    return this._$tempEl.html();
  };

  MobilePublicationService.prototype._processQuestion = function(q, original) {
    var balance;
    if (original == null) {
      original = false;
    }
    balance = q.get('consequencesBalance');
    return this._getSoF(q).then((function(_this) {
      return function(sof) {
        return {
          id: q.id,
          sofTableAvailable: true,
          sofSource: sof,
          text: _this._getAttr(q, 'question'),
          tableTitle: _this._getAttr(q, 'tableTitle'),
          benefitsAndHarms: original ? _this._getAttr(q, 'benefitsHarmsAdditionalConsiderations') : _this._getAttr(q, 'benefitsAndHarmsForMobile', 'benefitsHarmsAdditionalConsiderations'),
          valuesAndPreferences: _this._getAttr(q, 'valuesPreferencesExplanation'),
          remarks: original ? _this._getAttr(q, 'recommendationJustification') : _this._getAttr(q, 'remarksForMobile', 'recommendationJustification'),
          resources: _this._getAttr(q, 'incrementalCostToNetBenefitsAdditionalConsiderations'),
          evidenceQuality: _this._mapQualityJudgement(_this._getAttr(q, 'overallCertaintyJudgement')),
          recommendations: [
            {
              text: _this._getAttr(q, 'recommendationText'),
              strength: (balance != null ? balance.indexOf('probably') : void 0) === 0 ? 1 : (balance != null ? balance.indexOf('clearly') : void 0) === 0 ? 2 : void 0
            }
          ]
        };
      };
    })(this));
  };

  MobilePublicationService.prototype._getRecommendationsData = function(project, questions) {
    var questionIdToRecommendationIdMap, recommendationIds;
    if (project.get('recommendationsMode') === 'static') {
      questionIdToRecommendationIdMap = _.chain(questions).map(function(q) {
        var _ref;
        return [q.id, (_ref = q.get('recommendationIds')) != null ? _ref[0] : void 0];
      }).filter(function(_arg) {
        var qId, recommendationId;
        qId = _arg[0], recommendationId = _arg[1];
        return recommendationId != null;
      }).object().value();
      recommendationIds = _.values(questionIdToRecommendationIdMap);
      return mediator.services.storePersistenceAdapter.fetch(mediator.project.id, recommendationIds).then(function(_arg) {
        var recommendationIdToRecommendationMap, rows;
        rows = _arg.rows;
        recommendationIdToRecommendationMap = _.chain(rows).map(function(r) {
          return [r.id, r.doc];
        }).object().value();
        return {
          questionIdToRecommendationIdMap: questionIdToRecommendationIdMap,
          recommendationIdToRecommendationMap: recommendationIdToRecommendationMap
        };
      });
    } else {
      return W.resolve(null);
    }
  };

  MobilePublicationService.prototype["export"] = function(project) {
    var projectId, questionProcessor;
    projectId = project.id;
    this.currentProject = project;
    this._setupHtmlCleaning();
    questionProcessor = project.get('recommendationsMode') === 'static' ? this._processQuestionForStatic.bind(this) : this._processQuestion.bind(this);
    return W.all([
      QuestionsActions.fetch({
        projectId: projectId
      }), QuestionGroupsActions.fetch('regular', projectId)
    ]).then((function(_this) {
      return function() {
        var questionModels, questions, questionsByGroup;
        questions = QuestionsStore.getQuestions('regular');
        questionModels = questions.map(function(q) {
          return QuestionsStore.getQuestionModel(q.get('_id'));
        }).toArray();
        questionsByGroup = QuestionsListComponentStore.groupQuestionsByGroup(QuestionsStore.getQuestions('regular'), QuestionGroupsStore.getQuestionGroups('regular'));
        return _this._getRecommendationsData(project, questionModels).then(function(recommendationsData) {
          return W.map(questionModels, function(q) {
            return questionProcessor(q, false, recommendationsData);
          }).then(function(questionsArr) {
            return questionsArr.reduce(function(acc, q) {
              acc[q.id] = q;
              return acc;
            }, {});
          }).then(function(processedQuestionsMap) {
            return questionsByGroup.reduce(function(groupsArr, group, groupId) {
              groupsArr.push({
                id: groupsArr.length,
                title: groupId === 'questionsWithoutGroup' ? $.t('dissemination:publish.questions_wo_group') : group.get('caption'),
                questions: group.get('questions').reduce(function(groupQuestions, groupQuestion) {
                  groupQuestions.push(processedQuestionsMap[groupQuestion.get('_id')]);
                  return groupQuestions;
                }, [])
              });
              return groupsArr;
            }, []);
          }).then(function(questionGroups) {
            return {
              questionGroups: questionGroups,
              title: project.get('name'),
              background: project.get('documentSections')
            };
          });
        });
      };
    })(this));
  };

  MobilePublicationService.prototype._prepareStaticRecommendation = function(recommendation) {
    var additionalConsiderations, benefitsAndHarms, benefitsAndHarmsSections, conclusionsSections, contentRenderer, justificationContent, recommendationContent, recommendationText, recommendationType, recommendationTypeOption, remarks, renderOptions, resources, resourcesContent, _ref;
    additionalConsiderations = recommendation.templateData.assessment.additionalConsiderations;
    conclusionsSections = recommendation.templateData.conclusions.sections;
    recommendationTypeOption = _.first(recommendation.templateData.conclusions.sectionsOrder);
    renderOptions = {
      customEntityRenderers: EditorRenderMaps,
      attachments: Immutable.fromJS(recommendation._attachments)
    };
    contentRenderer = new DraftContentRenderer(renderOptions);
    recommendationType = conclusionsSections[recommendationTypeOption].selectedOption;
    benefitsAndHarmsSections = ['desirableEffects', 'undesirableEffects', 'certaintyOfEvidenceOfTestAccuracy', 'certaintyOfEvidenceOfTestEffects', 'certaintyOfEvidenceOfManagementEffects', 'certaintyOfEvidenceOfTestResult', 'certaintyOfEvidenceOfRequiredResources', 'values'];
    benefitsAndHarms = benefitsAndHarmsSections.reduce(function(resultString, sectionId) {
      var convertedContent, sectionData;
      sectionData = additionalConsiderations[sectionId];
      convertedContent = contentRenderer.rawContentToHTML(getSectionContent(sectionData));
      return resultString + convertedContent;
    }, '');
    justificationContent = getSectionContent(conclusionsSections.justification);
    resourcesContent = getSectionContent(additionalConsiderations.resourcesRequired);
    recommendationContent = getSectionContent(conclusionsSections.recommendation);
    remarks = contentRenderer.rawContentToHTML(justificationContent);
    resources = contentRenderer.rawContentToHTML(resourcesContent);
    recommendationText = contentRenderer.rawContentToHTML(recommendationContent);
    contentRenderer.dispose();
    return {
      benefitsAndHarms: benefitsAndHarms,
      remarks: remarks,
      resources: resources,
      evidenceQuality: this._mapQualityJudgement((_ref = recommendation.templateData.assessment.criteria.certaintyOfEvidence) != null ? _ref.selectedOption : void 0),
      recommendations: [
        {
          text: recommendationText,
          strength: (recommendationType != null ? recommendationType.indexOf('suggest') : void 0) === 0 ? 1 : (recommendationType != null ? recommendationType.indexOf('recommend') : void 0) === 0 ? 2 : void 0
        }
      ]
    };
  };

  MobilePublicationService.prototype._getRelatedRecommendations = function(recommendation, recommendationsData) {
    var relatedQuestionIds, _ref;
    relatedQuestionIds = (_ref = recommendation.templateData.relatedQuestionsIds) != null ? _ref : [];
    return _.map(relatedQuestionIds, function(qId) {
      var conclusionsSections, contentRenderer, rId, recommendationContent, recommendationText, relatedRecommendation, renderOptions;
      rId = recommendationsData.questionIdToRecommendationIdMap[qId];
      relatedRecommendation = recommendationsData.recommendationIdToRecommendationMap[rId];
      conclusionsSections = relatedRecommendation.templateData.conclusions.sections;
      renderOptions = {
        customEntityRenderers: EditorRenderMaps,
        attachments: Immutable.fromJS(recommendation._attachments)
      };
      contentRenderer = new DraftContentRenderer(renderOptions);
      recommendationContent = getSectionContent(conclusionsSections.recommendation);
      recommendationText = contentRenderer.rawContentToHTML(recommendationContent);
      return {
        recommendationId: rId,
        questionId: qId,
        recommendationText: recommendationText
      };
    });
  };

  MobilePublicationService.prototype._prepareStaticModel = function(q, recommendation, original, recommendationsData) {
    var recommendationFields, relatedRecommendations;
    recommendationFields = recommendation ? this._prepareStaticRecommendation(recommendation) : {};
    relatedRecommendations = recommendation ? this._getRelatedRecommendations(recommendation, recommendationsData) : [];
    if (!original) {
      if (!_.isEmpty(this._getAttr(q, 'benefitsAndHarmsForMobile'))) {
        recommendationFields.benefitsAndHarms = this._getAttr(q, 'benefitsAndHarmsForMobile');
      }
      if (!_.isEmpty(this._getAttr(q, 'remarksForMobile'))) {
        recommendationFields.remarks = this._getAttr(q, 'remarksForMobile');
      }
    }
    return this._getSoF(q).then((function(_this) {
      return function(sof) {
        return _(recommendationFields).extend({
          id: q.id,
          sofTableAvailable: true,
          sofSource: sof,
          text: _this._getAttr(q, 'question'),
          tableTitle: _this._getAttr(q, 'tableTitle'),
          relatedRecommendations: relatedRecommendations
        });
      };
    })(this));
  };

  MobilePublicationService.prototype._processQuestionForStatic = function(q, original, recommendationsData) {
    var fetchingRecommendation, projectDbName, recommendationId, recommendationIds;
    if (original == null) {
      original = false;
    }
    if (recommendationsData == null) {
      recommendationsData = null;
    }
    recommendationIds = q.get('recommendationIds');
    projectDbName = mediator.project.id;
    fetchingRecommendation = recommendationIds.length ? (recommendationId = recommendationIds[0], recommendationsData ? W.resolve(recommendationsData.recommendationIdToRecommendationMap[recommendationId]) : mediator.services.storePersistenceAdapter.fetch(projectDbName, recommendationId)) : W.resolve(null);
    return fetchingRecommendation.then((function(_this) {
      return function(recommendation) {
        return _this._prepareStaticModel(q, recommendation, original, recommendationsData);
      };
    })(this));
  };

  MobilePublicationService.prototype.originalEtdFields = function(question) {
    if (this.currentProject.get('recommendationsMode') === 'static') {
      return this._processQuestionForStatic(question, true);
    } else {
      return this._processQuestion(question, true);
    }
  };

  MobilePublicationService.prototype.publishPreview = function(project) {
    var updateOrCreate, url;
    url = DbHelper.getMobilePreviewDocUrl(project.id);
    updateOrCreate = function(id, newContent) {
      return W($.get(url)).then(function(doc) {
        return $.parseJSON(doc);
      })["catch"](function(xhr) {
        if (xhr.status === 404) {
          return {
            _id: id
          };
        } else {
          throw new Exceptions.server_error;
        }
      }).then(function(doc) {
        var content;
        content = _(newContent).extend(_(doc).pick('_id', '_rev'));
        return W($.ajax(url, {
          type: 'PUT',
          data: JSON.stringify(content)
        }));
      });
    };
    return this["export"](project).then(function(previewDoc) {
      return updateOrCreate(project.id, previewDoc).then(function() {
        return DbHelper.getMobilePreviewUrl(project.id);
      });
    });
  };

  return MobilePublicationService;

})();
