var AccessRightsCell, DefaultCell, LabelCell, NameCell, PanelMemberCell, PanelMemberHeaderCell, SortableLabelCell, _ref;

PanelMemberCell = require('components/team/form/panel_member_cell');

_ref = require('components/team/member_cells'), DefaultCell = _ref.DefaultCell, LabelCell = _ref.LabelCell, NameCell = _ref.NameCell, AccessRightsCell = _ref.AccessRightsCell, SortableLabelCell = _ref.SortableLabelCell, PanelMemberHeaderCell = _ref.PanelMemberHeaderCell;

module.exports = {
  header: [
    {
      name: "name",
      tooltip: 'name',
      component: SortableLabelCell
    }, {
      name: 'panel-member',
      tooltip: 'panel-member',
      component: PanelMemberHeaderCell
    }, {
      name: 'access-rights',
      tooltip: "access-rights",
      component: SortableLabelCell
    }
  ],
  listItemCollapsed: [
    {
      name: "name",
      tooltip: 'name',
      component: NameCell
    }, {
      name: 'panel-member',
      tooltip: 'panel-member',
      component: PanelMemberCell
    }, {
      name: 'access-rights',
      tooltip: "access-rights",
      component: AccessRightsCell
    }
  ]
};
