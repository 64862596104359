var CadthGuidelinesController, CadthGuidelinesView, ModuleController,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

CadthGuidelinesView = require('views/settings/cadth_guidelines_view');

ModuleController = require('controllers/base/module_controller');

module.exports = CadthGuidelinesController = (function(_super) {
  __extends(CadthGuidelinesController, _super);

  function CadthGuidelinesController() {
    return CadthGuidelinesController.__super__.constructor.apply(this, arguments);
  }

  CadthGuidelinesController.prototype.index = function() {
    return this.view = new CadthGuidelinesView;
  };

  return CadthGuidelinesController;

})(ModuleController);
