var DnDActions, DragDropTypes, QuestionsListComponentActions;

DnDActions = require('actions/react_dnd_actions');

QuestionsListComponentActions = require('actions/questions_list_component_actions');

DragDropTypes = require('components/evidence_syntheses/questions_list/drag_drop_types');

module.exports = {
  dropSpec: {
    drop: function(props, monitor) {
      if (monitor.getItemType() !== DragDropTypes.QUESTION) {
        return;
      }
      QuestionsListComponentActions.moveQuestionToGroup({
        qId: monitor.getItem().qId
      });
      return {
        newGroup: true
      };
    }
  },
  dropCollect: function(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget(),
      isTargetHovered: monitor.isOver() && monitor.canDrop()
    };
  }
};
