Select = require 'components/common/select_custom'
Translation = require 'components/mixins/translation'

LanguageSelector = createReactClass
  displayName: 'LanguageSelector'

  setLanguage: (lng) ->
    i18next.changeLanguage(lng)
    window.gdt.storage.set('gdtLanguage', lng).then -> window.location.reload()

  getCurrentLanguage: -> i18next.language

  render: ->
    <div className="languages-selector">
      <Select
        options={window.gdt.languages}
        selected={@getCurrentLanguage()}
        onChange={@setLanguage}
      />
    </div>

module.exports = LanguageSelector
