var NMA_QUESTION_PREFIX, NmaOutcomeDoc, NmaQuestionDoc, W, appUtils, generateActions, mediator, utils;

appUtils = require('lib/app_utils');

generateActions = require('actions/utils/generate_actions');

mediator = require('mediator');

NMA_QUESTION_PREFIX = require('lib/doc_prefixes').nmaQuestion;

NmaOutcomeDoc = require('lib/db_docs/nma_outcome_doc');

NmaQuestionDoc = require('lib/db_docs/nma_question_doc');

utils = require('base/lib/utils');

W = require('when/when');

module.exports = generateActions({
  autoGenerate: ['closeQuestionModal', 'fetchError', 'fetchSuccess', 'openQuestionModal'],
  name: 'NMAListActions',
  actions: {
    dbChange: function(change, _pending, _lastSeq) {
      if (change.id.indexOf(NMA_QUESTION_PREFIX) !== 0) {
        return;
      }
      return this.dispatch(change);
    },
    fetch: function(_arg) {
      var dbId;
      dbId = _arg.dbId;
      this.dispatch();
      return NmaQuestionDoc.at(dbId).fetchMany().then((function(_this) {
        return function(_arg1) {
          var docs, rows;
          rows = _arg1.rows;
          docs = _.compact(_.pluck(rows, 'doc'));
          _this.actions.fetchSuccess(docs);
          return docs;
        };
      })(this))["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('nma:questions_list.fetch_error'));
          return _this.actions.fetchError(err);
        };
      })(this));
    },
    fetchAllQuestionsAndOutcomes: function(_arg) {
      var dbId;
      dbId = _arg.dbId;
      this.dispatch();
      return NmaQuestionDoc.at(dbId).fetchMany().then((function(_this) {
        return function(_arg1) {
          var docs, outcomesIds, rows;
          rows = _arg1.rows;
          docs = _.compact(_.pluck(rows, 'doc'));
          outcomesIds = _.flatten(_.pluck(docs, 'outcomes'));
          _this.actions.fetchSuccess(docs);
          return outcomesIds;
        };
      })(this)).then(function(outcomesIds) {
        return NmaOutcomeDoc.at(dbId, outcomesIds).fetch().then(function(_arg1) {
          var NMAQuestionActions, docs, rows;
          rows = _arg1.rows;
          docs = _.compact(_.pluck(rows, 'doc'));
          NMAQuestionActions = require('actions/nma_question_actions');
          NMAQuestionActions.fetchOutcomesSuccess(docs);
          return docs;
        });
      })["catch"]((function(_this) {
        return function(err) {
          appUtils.errorHandlerWithMsg(err, $.t('nma:questions_list.fetch_error'));
          return _this.actions.fetchError(err);
        };
      })(this));
    },
    createQuestion: function(questionData) {
      this.dispatch();
      return NmaQuestionDoc.at(mediator.project.id).create(_.extend({}, questionData, {
        _id: "" + NMA_QUESTION_PREFIX + (utils.generateGUID())
      })).then(function() {
        return mediator.dialogs.info($.t('nma:questions_list.create_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:questions_list.create_error')))["finally"]((function(_this) {
        return function() {
          return _this.actions.closeQuestionModal();
        };
      })(this));
    },
    updateQuestion: function(questionData) {
      this.dispatch();
      return NmaQuestionDoc.at(mediator.project.id, questionData._id).update(questionData).then(function() {
        return mediator.dialogs.info($.t('nma:questions_list.update_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:questions_list.update_error')))["finally"]((function(_this) {
        return function() {
          return _this.actions.closeQuestionModal();
        };
      })(this));
    },
    updateQuestionInModal: function(field, value) {
      return this.dispatch({
        field: field,
        value: value
      });
    },
    deleteQuestion: function(question) {
      var outcomes, questionId;
      questionId = question._id, outcomes = question.outcomes;
      return W.all(_.union([NmaQuestionDoc.at(mediator.project.id, questionId)["delete"]()], _.map(outcomes, function(outcomeId) {
        return NmaOutcomeDoc.at(mediator.project.id, outcomeId)["delete"]();
      }))).then(function() {
        return mediator.dialogs.info($.t('nma:questions_list.delete_success'));
      })["catch"](appUtils.errorHandlerWithMsgCurried($.t('nma:questions_list.delete_error')));
    }
  }
});
