Router = require 'router'
mediator =  require 'mediator'
EllipsizedText = require 'components/common/ellipsized_text'
QuestionVotingStatus = require 'components/panel_voice/question_voting_status'
QuestionVotingControls = require 'components/panel_voice/question_voting_controls'
Responses = require './responses'
CloseVotingConfirmation = require 'components/panel_voice/modals/close_voting_confirmation'
ResendVotingInformation = require 'components/panel_voice/modals/resend_voting_information'
ConnectStore = require 'components/enhancers/connect_store'
PanelVoiceStore = require 'stores/panel_voice_store'
PanelVoiceActions = require 'actions/panel_voice_actions'
Translation = require 'components/mixins/translation'
{ isOverarching } = require 'lib/questions_helper'
{ instanceOf, bool } = PropTypes

controlSpec = (name, handler, disabled, helpText) ->
  { name, handler, disabled, helpText }

storeConnector =
  PanelVoiceStore: (Store, props) ->
    qId = props.data.get('_id')
    votingStages: Store.getQuestionVotingStages qId
    lastVotingResults: Store.getLastVotingResults qId
    etdHighlights: Store.getEtdHighlights qId
    votingCompletelyClosed: Store.isVotingCompletelyClosed qId
    showVotingResendInfoDialog: Store.showVotingResendInfoDialog()

Question = createReactClass
  displayName: 'PanelVoiceQuestion'

  propTypes:
    overarching: bool
    data: instanceOf(Immutable.Map).isRequired
    votingStages: instanceOf(Immutable.List).isRequired
    etdHighlights: instanceOf(Immutable.Map).isRequired
    lastVotingResults: instanceOf(Immutable.Map)
    showVotingResendInfoDialog: bool.isRequired

  mixins: [
    Translation('voting:panel_voice')
  ]

  getDefaultProps: ->
    overarching: false

  getInitialState: ->
    isShowingCloseConfirmation: false
    isShowingResendInformation: false

  navigateToEtd: ->
    { data } = @props
    questionId = data.get '_id'
    questionType = data.get 'type'
    etdLink = if isOverarching(questionType)
      Router::routeForSubmodule 'overarching-questions', 'details', { questionId }
    else
      Router::routeForSubmodule 'evidence-syntheses', 'recommendations', { questionId }

    mediator.publish '!router:route', etdLink

  getDueDate: ->
    if @props.votingStages.isEmpty()
      '-'
    else
      lastRoundTimestamp = @props.votingStages.last().get 'dueDateTimestamp'
      if lastRoundTimestamp
        moment(lastRoundTimestamp).format 'DD MMM YYYY'
      else
        '-'

  onSend: ->
    { data } = @props
    PanelVoiceActions.openSendVotingModal data.get('_id'), 'start', isOverarching(data.get('type'))

  toggleCloseConfirmation: ->
    @setState isShowingCloseConfirmation: not @state.isShowingCloseConfirmation

  toggleResendInformation: ->
    @setState isShowingResendInformation: not @state.isShowingResendInformation

  onCloseVoting: ->
    PanelVoiceActions.closeVoting @props.data.get("recommendationIds", Immutable.List()).first()
    @toggleCloseConfirmation()

  onSendTest: ->
    { data } = @props
    PanelVoiceActions.openSendVotingModal data.get('_id'), 'test', isOverarching(data.get('type'))

  _resendVoting: ->
    { data } = @props
    PanelVoiceActions.openSendVotingModal data.get('_id'), 'resend', isOverarching(data.get('type'))

  onResend: ->
    if @props.showVotingResendInfoDialog
      @toggleResendInformation()
    else
      @_resendVoting()

  onContinueResend: (neverShowAgain) ->
    @toggleResendInformation()
    if neverShowAgain
      PanelVoiceActions.updateUserPanelVoicePreferences showVotingResendInfo: false
    @_resendVoting()

  getQuestionControls: ->
    { votingStages, votingCompletelyClosed } = @props
    questionVotingStatus = if votingStages.isEmpty()
      'unsent'
    else
      votingStages.last().get('status')
    allPartsSent = votingStages.reduce(((acc, stage) -> acc + stage.get('parts').size), 0) is 3
    disabled = @props.data.get('recommendationIds', Immutable.List()).isEmpty()
    switch questionVotingStatus
      when 'unsent'
        sendTestControl = controlSpec 'sendTest', @onSendTest, disabled, @i18n 'test_round_tooltip'
        [ sendTestControl ].concat if votingStages.isEmpty()
          controlSpec 'send', @onSend, disabled
        else
          controlSpec 'sendPart', @onSend, disabled
      when 'ongoing'
        resendControl = controlSpec 'resend', @onResend
        if allPartsSent
          [ controlSpec 'close', @toggleCloseConfirmation ].concat resendControl
        else
          [ controlSpec 'closePart', @toggleCloseConfirmation ].concat resendControl
      when 'closed'
        [
          controlSpec 'sendTest', @onSendTest, votingCompletelyClosed, @i18n 'test_round_tooltip'
        ,
          controlSpec 'sendPart', @onSend, votingCompletelyClosed
        ]
      when 'test'
        [
          controlSpec 'sendTest', @onSendTest, votingCompletelyClosed, @i18n 'test_round_tooltip'
        ,
          controlSpec 'send', @onSend, votingCompletelyClosed
        ]

  render: ->
    question = @props.data.get 'question'
    inactive = @props.data.get('recommendationIds', Immutable.List()).isEmpty()
    completelyClosed = @props.votingCompletelyClosed
    rowClass = classNames 'panel-voice-question',
      'inactive': inactive
      'completely-closed': completelyClosed
      'overarching': @props.overarching

    <div className={rowClass}>
      <div className='panel-voice-question__cell question'>
        <div className='cell-content'>
          <EllipsizedText
            text={question}
            height={50}
            style={cursor: 'pointer'}
            onClick={@navigateToEtd}
          />
        </div>
      </div>
      <div className='panel-voice-question__cell responses'>
        <div className='cell-content'>
          <Responses
            questionId={@props.data.get '_id'}
            votingStages={@props.votingStages}
            lastVotingResults={@props.lastVotingResults}
          />
        </div>
      </div>
      <div className='panel-voice-question__cell due-date'>
        <div className='cell-content'>
          <span className='question-due-date'>{@getDueDate()}</span>
        </div>
      </div>
      <div className='panel-voice-question__cell status'>
        <div className='cell-content'>
          <QuestionVotingStatus
            votingStages={@props.votingStages}
            inactive={inactive}
          />
        </div>
      </div>
      <div className='panel-voice-question__cell actions'>
        <div className='cell-content'>
          <QuestionVotingControls controls={@getQuestionControls()} />
          {if @state.isShowingCloseConfirmation
            <CloseVotingConfirmation
              etdHighlights={@props.etdHighlights}
              onCancel={@toggleCloseConfirmation}
              onApply={@onCloseVoting}
            />
          }
          {if @state.isShowingResendInformation
            <ResendVotingInformation
              onClose={@toggleResendInformation}
              onContinue={@onContinueResend}
            />
          }
        </div>
      </div>
    </div>

module.exports = ConnectStore Question, PanelVoiceStore, storeConnector
