ConfirmationModal = require 'components/common/confirmation_modal'
IconButton = require 'components/common/icon_button'
QualityIndicatorsActions = require 'actions/quality_indicators_actions'
Select = require 'components/common/select_custom'
Textarea = require 'components/common/textarea'
EtdEditor = require 'components/etd/etd_editor'

{ useI18n } = require 'lib/react_utils'
{ useState, useMemo, useCallback } = React
{ QUALITY_INDICATOR_TYPES } = require 'lib/quality_indicators_helper'

QualityIndicatorEditRow = ({ qualityIndicator, currentQuestion }) ->

  i18n = useI18n('quality_indicators:table')

  [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  [qualityIndicatorEdit, setQualityIndicator] = useState qualityIndicator

  openConfirmationModal = useCallback ->
    setDeleteConfirmationOpen true
  , []

  onCancelDelete = useCallback ->
    setDeleteConfirmationOpen false
  , []

  onConfirmDelete = useCallback ->
    QualityIndicatorsActions.deleteRow(qualityIndicator)
    setDeleteConfirmationOpen false
  , [qualityIndicator]

  updateValue = (key) -> (value) ->
    setQualityIndicator(qualityIndicatorEdit.set(key, value))

  outcomeSelectOptions = useMemo ->
    currentQuestion.get('outcomes', Immutable.List()).map (outcome) ->
      {
        text: outcome.get('name'),
        value: outcome.get('_id')
      }
    .toJS()
  , [currentQuestion]

  qualityTypes = useMemo ->
    _.map QUALITY_INDICATOR_TYPES, (type) ->
      {
        text: i18n "../types.#{type}"
        value: type
      }
  , [QUALITY_INDICATOR_TYPES, i18n]

  qiName = qualityIndicatorEdit.get('name')


  <React.Fragment>
    <tr className="quality-indicator-edit-row">
      <td colSpan="3">
        <div className="flex items-center">
          <div className="flex-grow mr-20">
            <input
              type="text"
              value={qiName}
              onChange={(e) -> updateValue('name')(e.target.value)}
            />
          </div>
          <IconButton
            className="mr-20"
            iconName="delete"
            onClick={openConfirmationModal}
          />
          <IconButton
            iconName="save"
            onClick={-> QualityIndicatorsActions.saveRow(qualityIndicatorEdit)}
          />
          <ConfirmationModal
            isOpen={isDeleteConfirmationOpen}
            onCancel={onCancelDelete}
            onConfirm={onConfirmDelete}
            confirmLabel={i18n '/actions.delete'}
            message={i18n '../messages.delete_message'}
            question={i18n '../messages.delete_confirmation',
             { name: if _.isEmpty(qiName) then i18n '../this_quality_indicator' else qiName }}
          />
        </div>
      </td>
    </tr>
    <tr className="quality-indicator-edit-row">
      <td>
        <div className="pb-10">
          <div className="mb-10">
            <b className="block">{i18n 'outcome'}</b>
            <Select
              options={outcomeSelectOptions}
              selected={qualityIndicatorEdit.get('outcome')}
              onChange={updateValue('outcome')}
            />
          </div>
          <div className="mb-10">
            <b className="block">{i18n 'type'}</b>
            <Select
              options={qualityTypes}
              selected={qualityIndicatorEdit.get('type')}
              onChange={updateValue('type')}
            />
          </div>
          <div>
            <b className="block">{i18n 'explanation_of_terms'}</b>
            <input
              type="text"
              value={qualityIndicatorEdit.get('explanationOfTerms')}
              onChange={(e) -> updateValue('explanationOfTerms')(e.target.value)}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="pb-10">
          <b className="block">{i18n 'how_is_the_performance_measured'}</b>
          <EtdEditor
            cellId="calculationFormula"
            content={qualityIndicator.get('calculationFormula')}
            editable
            forceTableExpand
            isConsensus={false}
            noInsertOption
            placeholder={""}
            renderMode="regular"
            withApplyCancelButtons={false}
            onSaveContent={({cellId, content}) -> updateValue(cellId) content}
          />
        </div>
      </td>
      <td>
        <div className="pb-10">
          <b className="block">{i18n 'performance_indicator'}</b>
          <EtdEditor
            cellId="performanceIndicator"
            content={qualityIndicator.get('performanceIndicator')}
            editable
            forceTableExpand
            isConsensus={false}
            noInsertOption
            placeholder={""}
            renderMode="regular"
            withApplyCancelButtons={false}
            onSaveContent={({cellId, content}) -> updateValue(cellId) content}
          />
        </div>
      </td>
    </tr>
  </React.Fragment>



QualityIndicatorEditRow.propTypes =
  qualityIndicator: PropTypes.instanceOf(Immutable.Map).isRequired
  currentQuestion: PropTypes.instanceOf(Immutable.Map).isRequired


module.exports = QualityIndicatorEditRow
