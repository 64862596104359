var EditableItemView, Ellipsis, Exceptions, Shortcuts, View, W, mediator,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('./view');

Shortcuts = require('base/lib/traits/shortcuts');

Ellipsis = require('base/lib/traits/ellipsis');

W = require('when');

Exceptions = require('lib/exceptions');

mediator = require('mediator');

module.exports = EditableItemView = (function(_super) {
  __extends(EditableItemView, _super);

  function EditableItemView() {
    this.onFocusLost = __bind(this.onFocusLost, this);
    this._close = __bind(this._close, this);
    this._delete = __bind(this._delete, this);
    this["delete"] = __bind(this["delete"], this);
    this.showEditBox = __bind(this.showEditBox, this);
    this.focusInput = __bind(this.focusInput, this);
    return EditableItemView.__super__.constructor.apply(this, arguments);
  }

  EditableItemView.prototype.tagName = 'li';

  EditableItemView.prototype.enableEllipsis = true;

  EditableItemView.prototype.ellipsisSelector = '.caption > *';

  EditableItemView.prototype.confirmDelete = true;

  EditableItemView.prototype.shortcuts = {
    'enter': 'saveChanges',
    'esc': 'loseFocus'
  };

  EditableItemView.prototype.animationDuration = 500;

  EditableItemView.prototype.initialize = function() {
    EditableItemView.__super__.initialize.apply(this, arguments);
    this.delegate('click', 'button.edit', this.showEditBox);
    this.delegate('click', 'button.delete', this["delete"]);
    this.delegate('click', 'button.save', this.saveChanges);
    this.delegate('click', function(e) {
      if (this.editing) {
        e.stopPropagation();
      }
      return mediator.setFocus(this);
    });
    this._stopInputsPropagation = false;
    this.enable(Shortcuts);
    if (this.enableEllipsis) {
      this.enable(Ellipsis, {
        selector: this.ellipsisSelector
      });
    }
    this.editing = this.model.isNew();
    return this.bindValidationTooltips();
  };

  EditableItemView.prototype.getTemplateData = function() {
    return _(EditableItemView.__super__.getTemplateData.apply(this, arguments)).extend({
      editing: this.editing
    });
  };

  EditableItemView.prototype.afterRender = function() {
    EditableItemView.__super__.afterRender.apply(this, arguments);
    this.$el.toggleClass('expanded', this.editing);
    this.stickit();
    if (this.editing) {
      return _.defer((function(_this) {
        return function() {
          _this.focusInput();
          mediator.setFocus(_this);
          _this._scroll();
          return mediator.publish('editBoxOpened');
        };
      })(this));
    }
  };

  EditableItemView.prototype.focusInput = function() {
    return this.$('input').first().focus();
  };

  EditableItemView.prototype.showEditBox = function(e) {
    if (this.editing) {
      return;
    }
    return mediator.setFocus(this, (function(_this) {
      return function() {
        _this.editing = true;
        _this.render();
        return _this._animate();
      };
    })(this));
  };

  EditableItemView.prototype["delete"] = function(confirmText, errorHandler) {
    if (confirmText == null) {
      confirmText = $.t('messages.confirm');
    }
    if (errorHandler == null) {
      errorHandler = function(e) {
        return console.error(e);
      };
    }
    if (this.confirmDelete) {
      return mediator.dialogs.confirm(confirmText, (function(_this) {
        return function(confirmed) {
          if (confirmed) {
            return _this._delete(errorHandler);
          }
        };
      })(this));
    } else {
      return this._delete(errorHandler);
    }
  };

  EditableItemView.prototype._delete = function(errorHandler) {
    this.$el.height(0);
    mediator.publish('editBoxClosed');
    this.deleting = true;
    return _.delay(((function(_this) {
      return function() {
        if (_this.model.isNew()) {
          return _this.model.collection.remove(_this.model);
        }
        return _this.model.destroy({
          wait: true
        }).otherwise(function(e) {
          if (_.isFunction(errorHandler)) {
            return errorHandler(e);
          } else {
            throw e;
          }
        });
      };
    })(this)), this.animationDuration);
  };

  EditableItemView.prototype.saveChanges = function() {
    if (!this.editing) {
      return W.resolve(true);
    }
    return this.model.save().then((function(_this) {
      return function() {
        _this._close();
        mediator.setFocus();
        return true;
      };
    })(this), function(err) {
      if (!(err instanceof Exceptions.validation)) {
        throw err;
      }
      return false;
    });
  };

  EditableItemView.prototype._animate = function() {
    if (this.animationDuration && this.parentView) {
      return _((function(_this) {
        return function() {
          var _ref;
          return (_ref = _this.parentView) != null ? _ref.trigger('!fixLayout') : void 0;
        };
      })(this)).runEvery(25)["for"](this.animationDuration);
    }
  };

  EditableItemView.prototype._scroll = function() {
    var _ref;
    return (_ref = this.parentView) != null ? _ref.trigger('!scroll', this.$el, 100) : void 0;
  };

  EditableItemView.prototype.loseFocus = function() {
    return mediator.setFocus();
  };

  EditableItemView.prototype._close = function() {
    this.editing = false;
    if (this.disposed) {
      return;
    }
    this.render();
    if (this.fixLayout) {
      this.fixLayout();
    }
    this._animate();
    return mediator.publish('editBoxClosed');
  };

  EditableItemView.prototype._saveUnappliedChanges = function(deferred) {
    if (this.isAbandoningChanges() && !this.deleting) {
      return this.saveChanges().then((function(_this) {
        return function(saved) {
          if (saved) {
            if (_this.willBeDisposed) {
              mediator.dialogs.success($.t('messages.changes_saved'));
            }
            return deferred.resolve(true);
          } else if (_this.willBeDisposed) {
            if (_this.model.isNew()) {
              _this.model.destroy({
                wait: true
              });
            } else {
              _this.model.fetch();
            }
            return deferred.resolve(true);
          } else {
            return deferred.reject();
          }
        };
      })(this)).otherwise(function() {
        return deferred.reject();
      });
    } else {
      return deferred.resolve(true);
    }
  };

  EditableItemView.prototype.beforeFocusLost = function(deferred, focusTo) {
    if (!this.editing || this.changingFocusToSubview(focusTo)) {
      return deferred.resolve(false);
    }
    return W(this._saveUnappliedChanges(deferred)).otherwise((function(_this) {
      return function() {
        if (!_this.disposed) {
          return _this.focusInput();
        }
      };
    })(this));
  };

  EditableItemView.prototype.isAbandoningChanges = function() {
    var _ref, _ref1;
    return ((_ref = this.model) != null ? _ref.isNew() : void 0) || ((_ref1 = this.model) != null ? _ref1.isDirty() : void 0);
  };

  EditableItemView.prototype.onFocusLost = function() {
    return this._close();
  };

  EditableItemView.prototype.dispose = function() {
    return this._saveUnappliedChanges(W.defer()).ensure((function(_this) {
      return function() {
        _this.unstickit();
        return EditableItemView.__super__.dispose.apply(_this, arguments);
      };
    })(this));
  };

  return EditableItemView;

})(View);
