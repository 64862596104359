var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div class=\"edit-input-block form-group row mt-10\n  ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.followUpType : depth0), "range", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n  data-property=\"followUpLength\" id=\"follow-up-length-row\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.value : stack1), "case_control_studies", {"name":"is","hash":{},"fn":this.program(4, data),"inverse":this.program(6, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.isPopup : depth0), {"name":"unless","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.designStudies : depth0)) != null ? stack1.value : stack1), "case_control_studies", {"name":"is","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"inline short-select\">\n    "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "followUpType", (depth0 != null ? depth0.followUpType : depth0), "mean: mean, median: median, range: range", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n  </div>\n  <div class=\"inline\">\n    <input id=\"follow-up-length\" class=\"short\" name=\"followUpLength\" type=\"text\"\n      data-property=\"followUpLength\" value=\""
    + escapeExpression(((helper = (helper = helpers.followUpLength || (depth0 != null ? depth0.followUpLength : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"followUpLength","hash":{},"data":data}) : helper)))
    + "\">\n  </div>\n  <div class=\"inline\">\n    <div class=\"select-input-container ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.followUpTimeUnit : depth0), "others", {"name":"is","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "followUpTimeUnit", (depth0 != null ? depth0.followUpTimeUnit : depth0), "days: days, weeks: weeks, months: months, years: years,\n          patientYears: patient_years, others: others", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n      <input type=\"text\" name=\"othersFollowUpTimeUnit\" class=\"follow-up-time-unit-input\"\n        placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "others", {"name":"i18n","hash":{},"data":data})))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.othersFollowUpTimeUnit || (depth0 != null ? depth0.othersFollowUpTimeUnit : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"othersFollowUpTimeUnit","hash":{},"data":data}) : helper)))
    + "\"\n        data-property=\"othersFollowUpTimeUnit\"\n        ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.followUpTimeUnit : depth0), "others", {"name":"isnt","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n    </div>\n  </div>\n  <div id=\"range-to\" class=\"inline\" ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.followUpType : depth0), "range", {"name":"isnt","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n    <div class=\"inline\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "to", {"name":"i18n","hash":{},"data":data})))
    + "</div>\n    <div class=\"inline\">\n      <input id=\"follow-up-length-to\" class=\"short\" data-property=\"followUpLengthTo\"\n        name=\"followUpLengthTo\" type=\"text\" value=\""
    + escapeExpression(((helper = (helper = helpers.followUpLengthTo || (depth0 != null ? depth0.followUpLengthTo : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"followUpLengthTo","hash":{},"data":data}) : helper)))
    + "\">\n    </div>\n    <div class=\"inline\">\n      <div class=\"select-input-container ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.followUpToTimeUnit : depth0), "others", {"name":"is","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n        "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "followUpToTimeUnit", (depth0 != null ? depth0.followUpToTimeUnit : depth0), "days: days, weeks: weeks, months: months, years: years,\n            patientYears: patient_years, others: others", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n        <input type=\"text\" name=\"othersFollowUpToTimeUnit\" class=\"follow-up-to-time-unit-input\"\n          placeholder=\""
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "others", {"name":"i18n","hash":{},"data":data})))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.othersFollowUpToTimeUnit || (depth0 != null ? depth0.othersFollowUpToTimeUnit : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"othersFollowUpToTimeUnit","hash":{},"data":data}) : helper)))
    + "\"\n          data-property=\"othersFollowUpToTimeUnit\"\n          ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.followUpToTimeUnit : depth0), "others", {"name":"isnt","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n      </div>\n    </div>\n  </div>\n  <div class=\"inline sup\"></div>\n  <div class=\"clearfix\"></div>\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "ranged";
  },"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <label>\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "timing_of_exposure", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n";
},"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <label class=\"footnote-followUpLength\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "length_follow_up", {"name":"i18n","hash":{},"data":data})))
    + "\n    </label>\n";
},"8":function(depth0,helpers,partials,data) {
  return "    <div class=\"clearfix\"></div>\n";
  },"10":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <div class=\"inline short-select\">\n      "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "timingOfExposure", (depth0 != null ? depth0.timingOfExposure : depth0), "duration: duration, time_since: time_since", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n    </div>\n";
},"12":function(depth0,helpers,partials,data) {
  return "other";
  },"14":function(depth0,helpers,partials,data) {
  return "style=\"display: none\"";
  },"16":function(depth0,helpers,partials,data) {
  return "style=\"display:none\"";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "/es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}