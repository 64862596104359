var QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE, QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID, QuestionGroupsActions, alt, createGroupUpdater, mediator, questionToGroupUpdater, questionsOrderingUpdate, removeQuestionFromGroupUpdater, updateGroupsDoc, _ref, _ref1;

alt = require('alt');

mediator = require('mediator');

_ref = require('lib/questions_helper'), QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE = _ref.QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE, QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID = _ref.QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID;

_ref1 = require('lib/question_groups_helper'), updateGroupsDoc = _ref1.updateGroupsDoc, createGroupUpdater = _ref1.createGroupUpdater, questionToGroupUpdater = _ref1.questionToGroupUpdater, removeQuestionFromGroupUpdater = _ref1.removeQuestionFromGroupUpdater, questionsOrderingUpdate = _ref1.questionsOrderingUpdate;

QuestionGroupsActions = (function() {
  function QuestionGroupsActions() {
    this.generateActions('fetchSuccess', 'fetchError', 'deleteGroupSuccess', 'deleteGroupError', 'moveQuestionToGroupSuccess', 'moveQuestionToGroupError');
  }

  QuestionGroupsActions.prototype.dbChange = function(change, _pending, _lastSeq) {
    if (!(change.id in QUESTION_TYPES_BY_QUESTION_GROUP_DOC_ID)) {
      return;
    }
    return this.dispatch(change);
  };

  QuestionGroupsActions.prototype.fetch = function(questionType, projectId) {
    if (questionType == null) {
      questionType = 'regular';
    }
    this.dispatch(questionType);
    if (projectId == null) {
      projectId = mediator.project.id;
    }
    return mediator.services.storePersistenceAdapter.fetch(projectId, QUESTION_GROUPS_DOC_IDS_BY_QUESTION_TYPE[questionType]).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  QuestionGroupsActions.prototype.moveQuestionToGroup = function(params) {
    var groupId, qId, questionType;
    groupId = params.groupId, qId = params.qId, questionType = params.questionType;
    return updateGroupsDoc(questionType, questionToGroupUpdater(qId, groupId)).then((function(_this) {
      return function() {
        return _this.actions.moveQuestionToGroupSuccess(params);
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        _this.actions.moveQuestionToGroupError(params);
        return _this.actions.fetchError(err);
      };
    })(this));
  };

  QuestionGroupsActions.prototype.removeQuestionFromGroup = function(_arg) {
    var qId, questionType;
    qId = _arg.qId, questionType = _arg.questionType;
    return updateGroupsDoc(questionType, removeQuestionFromGroupUpdater(qId))["catch"](this.actions.fetchError);
  };

  QuestionGroupsActions.prototype.updateQuestionPosition = function(params) {
    var groupId, orderNumber, qId, questionType;
    orderNumber = params.orderNumber, groupId = params.groupId, questionType = params.questionType, qId = params.qId;
    return updateGroupsDoc(questionType, function(groupsDoc) {
      if (groupId && groupId !== groupsDoc.mapping[qId]) {
        groupsDoc = groupId === 'questionsWithoutGroup' ? removeQuestionFromGroupUpdater(qId)(groupsDoc) : questionToGroupUpdater(qId, groupId)(groupsDoc);
      }
      if (orderNumber !== groupsDoc.questionsOrdering[qId]) {
        groupsDoc = questionsOrderingUpdate(qId, orderNumber)(groupsDoc);
      }
      return groupsDoc;
    });
  };

  QuestionGroupsActions.prototype.updateGroupCaption = function(_arg) {
    var caption, groupId, questionType;
    groupId = _arg.groupId, caption = _arg.caption, questionType = _arg.questionType;
    return updateGroupsDoc(questionType, function(groupsDoc) {
      groupsDoc.groups = groupsDoc.groups.map(function(group) {
        if (group['_id'] === groupId) {
          return _.extend(group, {
            caption: caption
          });
        } else {
          return group;
        }
      });
      return groupsDoc;
    })["catch"](this.actions.fetchError);
  };

  QuestionGroupsActions.prototype.deleteGroup = function(params) {
    var groupId, questionType;
    groupId = params.groupId, questionType = params.questionType;
    return updateGroupsDoc(questionType, function(groupsDoc) {
      var newMapping;
      groupsDoc.groups = _.reject(groupsDoc.groups, function(group) {
        return group['_id'] === groupId;
      });
      newMapping = _.chain(groupsDoc.mapping).pairs().reject(function(_arg) {
        var gId, _qId;
        _qId = _arg[0], gId = _arg[1];
        return groupId === gId;
      }).object().value();
      return _.extend(groupsDoc, {
        mapping: newMapping
      });
    }).then((function(_this) {
      return function(withDeletedGroup) {
        _this.actions.deleteGroupSuccess(withDeletedGroup);
        return withDeletedGroup;
      };
    })(this))["catch"]((function(_this) {
      return function(err) {
        _this.actions.deleteGroupError({
          groupId: groupId,
          err: err
        });
        return _this.actions.fetchError(err);
      };
    })(this));
  };

  QuestionGroupsActions.prototype.createGroup = function(params) {
    var questionType;
    questionType = params.questionType;
    return updateGroupsDoc(questionType, createGroupUpdater);
  };

  return QuestionGroupsActions;

})();

module.exports = alt.createActions(QuestionGroupsActions);
