var W, errorHandlerWithMsg, etdHelper, exportEpiditorContent, flatPluckCompact, get, getContentPromise, isDraft, isImmutable, keyBy, mediator, rawContentToHTML, useCoffeeMemo, visuzalizationUtils, _ref;

keyBy = require('lodash').keyBy;

rawContentToHTML = require('lib/draft_utils').rawContentToHTML;

_ref = require('lib/app_utils'), errorHandlerWithMsg = _ref.errorHandlerWithMsg, flatPluckCompact = _ref.flatPluckCompact;

useCoffeeMemo = require('lib/react_utils').useCoffeeMemo;

get = require('lodash').get;

etdHelper = require('lib/etd_helper');

isImmutable = require('lib/immutable_utils').isImmutable;

exportEpiditorContent = require('lib/services/document_sections_exporter/utils').exportEpiditorContent;

W = require('when');

mediator = require('mediator');

isDraft = function(content) {
  return isImmutable(content) && content.has('blocks') && content.has('entityMap');
};

getContentPromise = function(id, content) {
  if (isDraft(content)) {
    return W.resolve(rawContentToHTML(content));
  } else {
    return exportEpiditorContent({
      chapterPath: '',
      chaptersPaths: {},
      content: Immutable.Map.isMap(content) ? content.toJS() : content || '',
      exportDate: '',
      guidelineId: '',
      linkPrefix: '',
      sectionId: id,
      sectionParentPaths: {},
      title: '',
      type: 'text'
    });
  }
};

visuzalizationUtils = {
  VISUALIZATION_TEMPLATES: [
    {
      id: 'acp_visualization_template',
      chapters: [
        {
          title: 'general_information',
          editable: false,
          deletable: false,
          chapterType: 'acp_visual_guideline_general_information_form',
          sections: [
            {
              title: "",
              sectionType: 'acp_visual_guideline_general_information_form',
              content: '',
              data: {}
            }
          ]
        }, {
          title: 'recommendations',
          editable: false,
          deletable: false,
          chapterType: 'acp_visual_guideline_recommendations',
          sections: [
            {
              title: "populations",
              sectionType: 'acp_recommendations_populations',
              content: '',
              data: {}
            }
          ]
        }, {
          title: 'clinical_considerations',
          sections: [
            {
              title: "clinical_considerations",
              sectionType: 'visual_guideline_text_section'
            }, {
              title: "citations_for_full_guideline",
              sectionType: 'visual_guideline_text_section'
            }, {
              title: "citations_for_evidence_review",
              sectionType: 'visual_guideline_text_section'
            }
          ]
        }
      ]
    }
  ],
  getVisualizationData: function(tableData, populationsSection, etds) {
    var analysisGroups, getRationaleContent, getRecContent, getStrengthOfRecommendation, outcomesIds, recommendationsData, recommendationsOrder, recsPromiseArr, _ref1;
    recommendationsData = populationsSection.getIn(['additionalData', 'recommendationsData'], Immutable.Map());
    recommendationsOrder = populationsSection.getIn(['additionalData', 'populations'], Immutable.List()).map(function(pop) {
      return pop.get('recommendations', Immutable.List());
    }).valueSeq().flatten().toJS();
    getRecContent = function(recId) {
      var path, recommendation;
      if (recId.indexOf(etdHelper.MANUAL_REC_PREFIX) === 0) {
        return recommendationsData.getIn([recId, 'content'], '');
      } else if (!_.isEmpty(recId.split('_')[1])) {
        if (recommendationsData.hasIn([recId, 'content'])) {
          return recommendationsData.getIn([recId, 'content'], '');
        } else {
          path = [recId.split('_')[0], 'templateData', 'conclusions', 'sections', 'multipleRecommendations', 'recommendations'];
          recommendation = etds.getIn(path).find(function(rec) {
            return rec.get('_id') === recId.split('_')[1];
          }, null, Immutable.Map());
          return recommendation.get('content', '');
        }
      } else {
        if (recommendationsData.has(recId)) {
          return recommendationsData.getIn([recId, 'content'], '');
        } else {
          path = [recId, 'templateData', 'conclusions', 'sections', 'recommendation', 'content'];
          return etds.getIn(path);
        }
      }
    };
    getStrengthOfRecommendation = function(recId) {
      var conclusionSections, path, recommendation;
      if (recId.indexOf(etdHelper.MANUAL_REC_PREFIX) === 0) {
        return recommendationsData.getIn([recId, 'strengthOfRecommendation'], '');
      } else if (!_.isEmpty(recId.split('_')[1])) {
        if (!_.isEmpty(recommendationsData.getIn([recId, 'strengthOfRecommendation']))) {
          return recommendationsData.getIn([recId, 'strengthOfRecommendation'], '');
        } else {
          path = [recId.split('_')[0], 'templateData', 'conclusions', 'sections', 'multipleRecommendations', 'recommendations'];
          recommendation = etds.getIn(path).find(function(rec) {
            return rec.get('_id') === recId.split('_')[1];
          }, null, Immutable.Map());
          return recommendation.get('selectedOption', '');
        }
      } else {
        if (recommendationsData.has(recId)) {
          return recommendationsData.getIn([recId, 'strengthOfRecommendation'], '');
        } else {
          conclusionSections = etds.getIn([recId, 'templateData', 'conclusions', 'sections'], Immutable.List());
          return conclusionSections.get(etdHelper.getCurrentTORSectionId(conclusionSections)).get('selectedOption');
        }
      }
    };
    getRationaleContent = function(recId) {
      if (recId.indexOf(etdHelper.MANUAL_REC_PREFIX) === 0) {
        return recommendationsData.getIn([recId, 'rationale'], '');
      } else if (!_.isEmpty(recId.split('_')[1])) {
        return recommendationsData.getIn([recId, 'rationale'], '');
      } else {
        return recommendationsData.getIn([recId, 'rationale'], '');
      }
    };
    recsPromiseArr = recommendationsOrder.map(function(recId) {
      var contentPromise, rationalePromise;
      contentPromise = getContentPromise("content_" + recId, getRecContent(recId));
      rationalePromise = getContentPromise("rationale_" + recId, getRationaleContent(recId));
      return W.all([contentPromise, rationalePromise]).then(function(_arg) {
        var content, getHtmlContent, rationale;
        content = _arg[0], rationale = _arg[1];
        getHtmlContent = function(c) {
          if (_.isObject(c)) {
            return get(c, 'sections[0].htmlContent') || '';
          } else {
            return c;
          }
        };
        return {
          id: recId,
          title: '',
          content: getHtmlContent(content),
          rationale: getHtmlContent(rationale),
          strengthOfRecommendation: getStrengthOfRecommendation(recId)
        };
      });
    });
    _ref1 = tableData.get('populations', Immutable.Map()).reduce(function(acc, population) {
      population.get('interventionsData', Immutable.Map()).map(function(intervention) {
        return intervention.get('recommendationData', Immutable.Map()).map(function(recData) {
          return recData.get('analysis', Immutable.List()).map(function(analysis) {
            acc.outcomesIds = acc.outcomesIds.concat(analysis.get('outcomesOrder').toJS());
            return acc.analysisGroups = acc.analysisGroups.concat(analysis.get('_id'));
          });
        });
      });
      return acc;
    }, {
      outcomesIds: [],
      analysisGroups: []
    }), analysisGroups = _ref1.analysisGroups, outcomesIds = _ref1.outcomesIds;
    return W.all(recsPromiseArr).then(function(recs) {
      return keyBy(recs, 'id');
    }).then(function(recommendations) {
      return mediator.services.storePersistenceAdapter.fetch(mediator.project.id, _.uniq(outcomesIds)).then(function(outcomes) {
        return {
          recommendations: recommendations,
          outcomes: keyBy(flatPluckCompact(outcomes.rows, 'doc'), '_id'),
          analysisGroups: _.uniq(analysisGroups)
        };
      });
    })["catch"](function(err) {
      return errorHandlerWithMsg(err, $.t('vi:errors.prepare_data_error'));
    });
  }
};

module.exports = visuzalizationUtils;
