mediator = require 'mediator'
InsertedGdtTable = require 'components/evidence_syntheses/inserted_gdt_table'
InsertModal = require 'components/etd/insert_modal'
ReferencesInsertModal = require 'components/etd/references_insert_modal'
ReferenceCitationMixin = require 'components/mixins/reference_citation_mixin'
Translation = require 'components/mixins/translation'
{ bool, func, instanceOf, string, object, oneOfType } = PropTypes

editorUtils = Editor.EditorUtils
GdtEditorWithApply = Editor.EditorWithApply
GdtEditor = Editor.Editor

EtdEditor = createReactClass
  displayName: 'EtdEditor'

  propTypes:
    additionalOptions: object
    attachments: instanceOf(Immutable.Map)
    cellId: string.isRequired
    content: oneOfType [instanceOf(Immutable.Map), string]
    forceTableExpand: bool
    isConsensus: bool
    noAutoSave: bool
    noInsertOption: bool
    onChange: func
    onSaveContent: func
    onSaveAttachment: func
    placeholder: string
    renderMode: string
    stickControlsTo: string
    withApplyCancelButtons: bool

  getDefaultProps: ->
    additionalOptions: null
    forceTableExpand: false
    isConsensus: false
    noAutoSave: false
    noInsertOption: false
    placeholder: null
    stickControlsTo: '#recommendations'
    withApplyCancelButtons: true
    onSaveAttachment: null
    onSaveContent: null

  mixins: [
    Translation('')
    ReferenceCitationMixin()
  ]

  getInitialState: ->
    showInsertModal: false
    showCancelApply: false

  editorRef: (el) ->
    # when withApplyCancelButtons prop is true, EditorWIthApply is rendered which is a wrapper
    # around Editor, that is why need to get el.gdtEditor prop. Otherwise not wrapped Editor is
    # rendered, so it is safe to reference `el`
    @gdtEditor = if @props.withApplyCancelButtons then el?.gdtEditor else el

  _getCustomControls: ->
    return [@getReferenceToggleSpec()] if @props.noInsertOption

    insertToggle =
      label: 'insert'
      onToggle: =>
        @gdtEditor.keepEditorActive()
        @setState showInsertModal: true

    [ insertToggle, @getReferenceToggleSpec() ]

  _getCustomEntityRenderers: ->
    'GDT_TABLE': (Editor) =>
      component: InsertedGdtTable
      editable: false
      props:
        forceTableExpand: @props.forceTableExpand
        renderMode: @props.renderMode
        focusEditor: Editor.focus
        updateData: @updateEntityData
        isEditing: Editor.state.isEditing

  updateEntityData: (entityKey, newData, silent = false) ->
    editorUtils.updatedEntityData entityKey, newData
    if silent
      @saveContent()
    else
      mediator.dialogs.success 'Updated successfully'
      @gdtEditor.focus()

  closeInsertModal: ->
    @setState showInsertModal: false
    @gdtEditor.focus()

  onInsertModalSubmit: (insertData) ->
    { contentType, content } = insertData
    @closeInsertModal()

    if contentType is  'GDT_TABLE'
      gdtTableBlockSpec = type: 'GDT_TABLE', mutability: 'IMMUTABLE', data: content
      @gdtEditor.insertCustomBlock gdtTableBlockSpec
      content?.type is 'quality_indicators' and  @insertJustificationParagraph()
    else
      @gdtEditor.insertContent insertData

  insertJustificationParagraph: ->
    justificationData =
      contentType: 'HTML'
      content: "<br/><b>#{@i18n 'quality_indicators:table.justification'}:</b><br/>"
    _.defer =>
      @gdtEditor.insertContent justificationData

  onCancel: ->
    @gdtEditor.resetContent @props.content

  onApply: ->
    @saveContent()

  onBlur: (e) ->
    return if @props.noAutoSave
    # when onBlur is triggered by one of buttons from inserted table
    # then do not save content because it is being saved by updateEntityData method
    @saveContent() unless e.relatedTarget?.classList.contains 'inserted-table_button'

  saveContent: ->
    content = @gdtEditor.getEditorContent()
    options = isConsensus: @props.isConsensus
    @props.onSaveContent? { cellId: @props.cellId, content, options }

  saveAttachment: (file) ->
    attachmentName = "#{@props.cellId}/#{file.name.replace(/\s/g, '_')}"
    @props.onSaveAttachment? { attachmentName, file }
    attachmentName

  componentDidUpdate: (prevProps, prevState) ->
    # if content has changed - force editor re-render
    prevProps.content ?= ''
    hasContentChanged = if _.isString prevProps.content
      prevProps.content isnt @props.content
    else if prevProps.content and @props.content
      not prevProps.content?.equals @props.content
    else false

    @gdtEditor.resetContent @props.content if hasContentChanged

  render: ->
    EditorComponent = if @props.withApplyCancelButtons then GdtEditorWithApply else GdtEditor

    <div>
      <EditorComponent
        attachments={@props.attachments}
        customControls={@_getCustomControls()}
        customEntityRenderers={@_getCustomEntityRenderers()}
        customDecorators={@getReferenceCitationDecorator()}
        customHandlers={handlePastedText: @handleReferencePaste}
        editorContent={@props.content}
        onChange={@props.onChange}
        onCancel={@onCancel}
        onApply={@onApply}
        onBlur={@onBlur}
        placeholder={@props.placeholder}
        readOnly={not @props.editable}
        ref={@editorRef}
        stickControlsTo={@props.stickControlsTo}
        saveAttachment={@saveAttachment}
        showReferenceAttachments={@props.renderMode isnt 'printout'}
      />
      {if @state.showInsertModal
        <InsertModal
          additionalOptions={@props.additionalOptions}
          onClose={@closeInsertModal}
          onInsert={@onInsertModalSubmit}
        />
      }
      {if @state.showReferencesInsert
        <ReferencesInsertModal
          onInsert={@onInsertReferences}
          onClose={@toggleReferencesInsert}
        />
      }
    </div>

module.exports = EtdEditor
