var PROPOSED_VOTING_OPTIONS, TYPE_OF_RECOMMENDATION_SECTIONS, mediator;

mediator = require('mediator');

TYPE_OF_RECOMMENDATION_SECTIONS = require('lib/etd_helper').getRecommendationTypeSections();

PROPOSED_VOTING_OPTIONS = Immutable.fromJS([
  {
    value: 'agree'
  }, {
    value: 'disagree'
  }
]);

module.exports = function(etdPart) {
  return {
    _prepareVotingResults: function() {
      if (!this.props.votingResults) {
        return null;
      }
      return this.props.votingResults.map(function(voteData) {
        var givenNames, lastName, userData, _ref;
        userData = mediator.colls.teamMembers.get(voteData.get('memberId'));
        if (userData != null) {
          _ref = userData.attributes, lastName = _ref.lastName, givenNames = _ref.givenNames;
        }
        if (lastName || givenNames) {
          return voteData.merge({
            lastName: lastName,
            givenNames: givenNames
          });
        } else {
          return voteData.set('userWasDeleted', true);
        }
      });
    },
    _getPossibleVotingOptions: function(combined) {
      if (combined == null) {
        combined = false;
      }
      if (this.props.isProposedVoting) {
        return PROPOSED_VOTING_OPTIONS;
      }
      switch (etdPart) {
        case 'judgements':
          if (combined) {
            return this.props.criterion.get('options').concat(this.props.criterion.get('additionalOptions'));
          } else {
            return Immutable.Map({
              main: this.props.criterion.get('options'),
              additional: this.props.criterion.get('additionalOptions')
            });
          }
          break;
        case 'recommendation':
          return this.props.section.get('options');
        case 'conclusions':
          return null;
      }
    },
    _getDraftJudgement: function() {
      switch (etdPart) {
        case 'judgements':
          return this.props.criterion.get('selectedOption');
        case 'recommendation':
          return this.props.section.get('selectedOption');
        case 'conclusions':
          return null;
      }
    },
    _getAdminComment: function() {
      switch (etdPart) {
        case 'judgements':
          return this.props.criterion.get('votingComment', null);
        case 'recommendation':
          return this.props.section.get('votingComment', null);
        case 'conclusions':
          return this.props.section.get('votingComment', null);
      }
    },
    _getHiddenVotes: function() {
      switch (etdPart) {
        case 'judgements':
          return this.props.criterion.get('hiddenVotes', Immutable.List());
        case 'recommendation':
          return this.props.section.get('hiddenVotes', Immutable.List());
        case 'conclusions':
          return this.props.section.get('hiddenVotes', Immutable.List());
      }
    },
    _getVotingProps: function() {
      return {
        etdPart: etdPart,
        votingResults: this._prepareVotingResults(),
        isProposedVoting: this.props.isProposedVoting,
        getPossibleVotingOptions: this._getPossibleVotingOptions,
        sectionDescription: etdPart === 'judgements' ? this.props.criterion.get('description') : void 0,
        draftJudgement: this._getDraftJudgement(),
        adminComment: this._getAdminComment(),
        hiddenVotes: this._getHiddenVotes()
      };
    }
  };
};
