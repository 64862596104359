var EtdActions, Exceptions, QUALITY_INDICATOR_PREFIX, QUALITY_INDICATOR_TYPE, UserProjectDataActions, W, alt, appUtils, callMethod, docTypes, fetchEtdDoc, generateActions, generateGUID, get, goToLastRow, mediator, prepareContentForMonitoringAndEvaluation, qualityIndicatorDoc, questionDoc, set, _ref, _ref1;

alt = require('alt');

appUtils = require('lib/app_utils');

docTypes = require('lib/db_docs/doc_types');

EtdActions = require('actions/etd_actions');

Exceptions = require('lib/exceptions');

generateActions = require('actions/utils/generate_actions');

mediator = require('mediator');

qualityIndicatorDoc = require('lib/db_docs/quality_indicator_doc');

questionDoc = require('lib/db_docs/question_doc');

UserProjectDataActions = require('actions/user_project_data_actions');

W = require('when/when');

_ref = require('lodash'), set = _ref.set, get = _ref.get;

fetchEtdDoc = require('actions/async/etd').fetchEtdDoc;

_ref1 = require('base/lib/utils'), callMethod = _ref1.callMethod, generateGUID = _ref1.generateGUID;

QUALITY_INDICATOR_TYPE = require('lib/db_docs/doc_types').QUALITY_INDICATOR_TYPE;

QUALITY_INDICATOR_PREFIX = require('lib/doc_prefixes').qualityIndicators;

prepareContentForMonitoringAndEvaluation = require('lib/quality_indicators_helper').prepareContentForMonitoringAndEvaluation;

goToLastRow = function() {
  var elements, lastEl;
  elements = document.querySelectorAll('.quality-indicators table tbody tr');
  lastEl = _.last(elements);
  if (!lastEl) {
    return;
  }
  return lastEl.scrollIntoView({
    behavior: 'smooth',
    block: 'end'
  });
};

module.exports = generateActions({
  autoGenerate: ['editRow', 'openExportDialog', 'hideExportDialog', 'fetchError', 'fetchSuccess'],
  name: 'QualityIndicatorsActions',
  actions: {
    fetch: function(_arg) {
      var dbId, docId;
      dbId = _arg.dbId, docId = _arg.docId;
      this.dispatch();
      return questionDoc.at(dbId, docId).fetch().then((function(_this) {
        return function(question) {
          var QIId, QIPromise, qualityIndicatorsId;
          qualityIndicatorsId = question.qualityIndicatorsId;
          QIPromise = _.isEmpty(qualityIndicatorsId) ? (QIId = "" + QUALITY_INDICATOR_PREFIX + (generateGUID()), qualityIndicatorDoc.at(dbId).create({
            _id: QIId
          }).then(function(qiDoc) {
            W.all([questionDoc.at(dbId, docId).updateAsInstance(callMethod('setQualityIndicatorId', QIId)), EtdActions.createRecommendationsTable(dbId, QIId, 'qualityIndicators')]);
            return qiDoc;
          })) : qualityIndicatorDoc.at(dbId, qualityIndicatorsId).fetch();
          return QIPromise.then(function(qiDoc) {
            _this.actions.fetchSuccess(qiDoc);
            return qiDoc;
          }).then(function(qiDoc) {
            return EtdActions.fetch({
              dbId: dbId,
              docId: qiDoc.recommendationIds
            });
          });
        };
      })(this))["catch"](appUtils.errorHandler);
    },
    dbChange: function(change, _pending, _lastSeq) {
      var doc;
      doc = change.doc;
      if (doc.docType !== QUALITY_INDICATOR_TYPE) {
        return;
      }
      return this.dispatch(change);
    },
    addRow: function() {
      var docId, qiId;
      qiId = generateGUID();
      docId = alt.stores.QualityIndicatorsStore.getCurrentDocId();
      this.dispatch(qiId);
      return qualityIndicatorDoc.at(mediator.project.id, docId).updateAsInstance(callMethod('addQualityIndicator', qiId)).then(function() {
        return setTimeout(goToLastRow, 100);
      })["catch"](appUtils.errorHandler);
    },
    createWithOutcome: function(_arg) {
      var docId, name, outcomeId, qiId;
      outcomeId = _arg._id, name = _arg.name;
      qiId = generateGUID();
      docId = alt.stores.QualityIndicatorsStore.getCurrentDocId();
      return qualityIndicatorDoc.at(mediator.project.id, docId).updateAsInstance(callMethod('addQualityIndicator', qiId, {
        outcome: outcomeId,
        name: name
      }))["catch"](appUtils.errorHandler);
    },
    saveRow: function(qualityIndicator) {
      var docId;
      this.dispatch(qualityIndicator.get('id'));
      docId = alt.stores.QualityIndicatorsStore.getCurrentDocId();
      return qualityIndicatorDoc.at(mediator.project.id, docId).updateAsInstance(callMethod('updateQualityIndicator', qualityIndicator.toJS()))["catch"](appUtils.errorHandler);
    },
    deleteRow: function(qualityIndicator) {
      var docId;
      this.dispatch(qualityIndicator.get('id'));
      docId = alt.stores.QualityIndicatorsStore.getCurrentDocId();
      return qualityIndicatorDoc.at(mediator.project.id, docId).updateAsInstance(callMethod('deleteQualityIndicator', qualityIndicator.get('id')))["catch"](appUtils.errorHandler);
    },
    changeView: function(currentView) {
      var docId, newView;
      newView = currentView === 'etd' ? 'candidates' : 'etd';
      if (newView === 'etd') {
        docId = alt.stores.QualityIndicatorsStore.getCurrentDocId();
        qualityIndicatorDoc.at(mediator.project.id, docId).updateAsInstance(callMethod('changeView', newView))["catch"](appUtils.errorHandler);
      }
      return this.dispatch(newView);
    },
    setIncludeForMonitoring: function(qiId, value) {
      var docId, qualityIndicator;
      qualityIndicator = alt.stores.QualityIndicatorsStore.getQualityIndicator(qiId);
      docId = alt.stores.QualityIndicatorsStore.getCurrentDocId();
      return qualityIndicatorDoc.at(mediator.project.id, docId).updateAsInstance(callMethod('updateQualityIndicator', qualityIndicator.set('includeForMonitoring', value).toJS()))["catch"](appUtils.errorHandler);
    },
    updateValue: function(qiId, key, value) {
      var docId, qualityIndicator;
      qualityIndicator = alt.stores.QualityIndicatorsStore.getQualityIndicator(qiId);
      docId = alt.stores.QualityIndicatorsStore.getCurrentDocId();
      return qualityIndicatorDoc.at(mediator.project.id, docId).updateAsInstance(callMethod('updateQualityIndicator', qualityIndicator.set(key, value).toJS()))["catch"](appUtils.errorHandler);
    },
    sendToMonitoringAndEvaluation: function(content) {
      var contentToBeSaved, err, etdId, requirements;
      etdId = alt.stores.QuestionsStore.getQuestion().get('recommendationIds', Immutable.List()).first();
      requirements = alt.stores.EtdStore.getQuestion().getIn(['requirements', 'content'], '');
      contentToBeSaved = prepareContentForMonitoringAndEvaluation(content, requirements);
      if (etdId) {
        return fetchEtdDoc(mediator.project.id, etdId).then(function(doc) {
          var updatedDoc;
          updatedDoc = set(doc, 'templateData.conclusions.sections.monitoringAndEvaluation.content', contentToBeSaved);
          return mediator.services.storePersistenceAdapter.save(mediator.project.id, updatedDoc);
        })["catch"](appUtils.errorHandler);
      } else {
        err = new Exceptions.quality_indicators.etd_document_missing;
        return mediator.dialogs.error(err);
      }
    }
  }
});
