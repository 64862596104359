var W;

W = require('when');

module.exports = {
  saveFile: function(blob, fileName) {
    window.saveAs(blob, fileName);
    return W.resolve();
  }
};
