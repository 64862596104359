var Const, Identity, callMethod, immIso, immLensPath, immProp, isImmutable, isInstanceOf, isomorphism, keyToImmLens, keyToLens, lensIso, lensPath, lensWhere, mapped, whereAttr, whereId, _deriveFunctor, _ref,
  __slice = [].slice;

isImmutable = require('lib/immutable_utils').isImmutable;

_ref = require('base/lib/utils'), callMethod = _ref.callMethod, isInstanceOf = _ref.isInstanceOf;

immProp = function(key) {
  return R.lens(function(obj) {
    return obj != null ? obj.get(key) : void 0;
  }, function(newVal, obj) {
    return obj != null ? obj.set(key, newVal) : void 0;
  });
};

Const = function(x) {
  return {
    value: x,
    map: function() {
      return this;
    }
  };
};

Identity = function(x) {
  return {
    value: x,
    map: function(f) {
      return Identity(f(x));
    }
  };
};

_deriveFunctor = function(toFunctorFn, target) {
  var inFunctor;
  inFunctor = toFunctorFn(target != null ? R.ifElse(isImmutable, callMethod('first'), R.head)(target) : target);
  if (R.map(R.identity, inFunctor) === inFunctor) {
    return Const;
  } else {
    return Identity;
  }
};

mapped = function(toFunctorFn) {
  return function(target) {
    var Functor, getter, setter;
    Functor = _deriveFunctor(toFunctorFn, target);
    if (R.isEmpty(target) || R.isNil(target)) {
      return Functor(target);
    }
    getter = R.map(R.compose(R.prop('value'), toFunctorFn));
    setter = R.identity;
    return Functor(R.map(setter, getter(target)));
  };
};

isomorphism = function(to, from) {
  var _isomorphism;
  _isomorphism = function(target) {
    return to(target);
  };
  _isomorphism.from = from;
  return _isomorphism;
};

lensIso = function(to, from) {
  return isomorphism(R.curry(function(toFunctorFn, target) {
    return R.map(from, toFunctorFn(to(target)));
  }), R.curry(function(toFunctorFn, target) {
    return R.map(to, toFunctorFn(from(target)));
  }));
};

lensIso.from = R.curry(function(_isomorphism, target) {
  return _isomorphism.from(target);
});

lensWhere = function(pred) {
  return function(toFunctorFn) {
    return function(target) {
      var Functor, getter, setter;
      Functor = _deriveFunctor(toFunctorFn, target);
      if (R.isEmpty(target) || R.isNil(target)) {
        return Functor(target);
      }
      getter = R.compose(R.map(R.compose(R.prop('value'), toFunctorFn)), R.filter(pred));
      setter = R.map(R.cond([[pred, R.compose(R.prop('value'), toFunctorFn)], [R.T, R.identity]]));
      return R.map(R.always(setter(target)), Functor(getter(target)));
    };
  };
};

immIso = lensIso(R.cond([[R.both(_.isObject, R.hasIn('toJS')), callMethod('toJS')], [R.T, R.identity]]), Immutable.fromJS);

keyToLens = R.cond([[_.isString, R.lensProp], [_.isNumber, R.lensIndex], [R.T, R.identity]]);

keyToImmLens = R.cond([[R.either(_.isString, _.isNumber), immProp], [R.T, R.identity]]);

whereAttr = function(attrName) {
  return function(lookupVal) {
    return lensWhere(function(item) {
      var itemAttrVal;
      itemAttrVal = isImmutable(item) ? item.get(attrName) : R.prop(attrName, item);
      return itemAttrVal === lookupVal;
    });
  };
};

whereId = whereAttr('_id');

lensPath = function() {
  var keyOrLenses;
  keyOrLenses = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return keyOrLenses.map(keyToLens).reduce(R.map);
};

immLensPath = function() {
  var keyOrLenses;
  keyOrLenses = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
  return keyOrLenses.map(keyToImmLens).reduce(R.map);
};

module.exports = {
  immProp: immProp,
  mapped: mapped,
  lensIso: lensIso,
  immIso: immIso,
  lensWhere: lensWhere,
  lensPath: lensPath,
  immLensPath: immLensPath,
  whereAttr: whereAttr,
  whereId: whereId
};
