var MultiComparisonsCreate, MultiComparisonsListView, MultiComparisonsToolbar, ReactComponent, ReactToolbarView, View,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

ReactToolbarView = require('views/react_toolbar_view');

MultiComparisonsCreate = require('components/multi_comparisons/multi_comparisons_create');

MultiComparisonsToolbar = require('components/multi_comparisons/multi_comparisons_toolbar');

module.exports = MultiComparisonsListView = (function(_super) {
  __extends(MultiComparisonsListView, _super);

  function MultiComparisonsListView() {
    return MultiComparisonsListView.__super__.constructor.apply(this, arguments);
  }

  MultiComparisonsListView.prototype.container = '#page-container';

  MultiComparisonsListView.prototype.className = 'multi-comparisons-view';

  MultiComparisonsListView.prototype.autoRender = true;

  MultiComparisonsListView.prototype.initialize = function() {
    MultiComparisonsListView.__super__.initialize.apply(this, arguments);
    this.subview('toolbar', new ReactToolbarView({
      component: MultiComparisonsToolbar
    }));
    return this.enable(ReactComponent);
  };

  MultiComparisonsListView.prototype.afterRender = function() {
    MultiComparisonsListView.__super__.afterRender.apply(this, arguments);
    this._stopInputsPropagation = false;
    return this.renderSimpleComponent(MultiComparisonsCreate);
  };

  MultiComparisonsListView.prototype.dispose = function() {
    this.unmountComponent();
    return MultiComparisonsListView.__super__.dispose.apply(this, arguments);
  };

  return MultiComparisonsListView;

})(View);
