var Migration, ReferencesModule, W,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Migration = require('./migration');

W = require('when');

module.exports = ReferencesModule = (function(_super) {
  __extends(ReferencesModule, _super);

  function ReferencesModule() {
    ReferencesModule.__super__.constructor.call(this, false);
  }

  ReferencesModule.prototype.up = function(project, colls) {
    var module, moduleIndex;
    ReferencesModule.__super__.up.apply(this, arguments);
    if (_(project.get('modules')).findWhere({
      id: 'references'
    }) || project.get('modules') === '*') {
      return W.resolve();
    }
    moduleIndex = project.get('modules').findIndex(function(m) {
      return m.id === 'scope';
    }) + 1;
    module = {
      id: 'references',
      featureSwitch: 'references',
      submodules: [
        {
          id: 'duplicates'
        }
      ],
      disabled: false
    };
    project.get('modules').splice(moduleIndex, 0, module);
    return project.save();
  };

  return ReferencesModule;

})(Migration);
