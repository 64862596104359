AddQuestionMixin = require 'components/mixins/scope_add_question_mixin'
AddSendButtons = require 'components/scope/questions/add_send_buttons'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EditableQuestionsList = require 'components/scope/questions/questions_list_editable'
QuestionsGenerationActions = require 'actions/questions_generation_actions'
QuestionsList = require 'components/scope/questions/questions_list'
Translation = require 'components/mixins/translation'
{ license_url } = require 'lib/constants'

InitialTab = createReactClass
  displayName: 'InitialTab'

  propTypes:
    questions: PropTypes.instanceOf(Immutable.List).isRequired
    questionsLimit: PropTypes.number.isRequired
    readOnly: PropTypes.bool.isRequired

  mixins: [
    AddQuestionMixin
    CustomRenderMixin
    Translation('scope:questions')
  ]

  render: ->
    if @props.readOnly
      <div className='questions-initial-draft'>
        <div className="questions">
          <QuestionsList questions={@props.questions} readOnly/>
        </div>
      </div>
    else
      <div className='questions-initial-draft'>
        {@props.questionsLimit isnt -1 and <div
          className='gdt-limits-content-box'
          dangerouslySetInnerHTML={{
            __html: @i18n 'questions_limits_info',
              limit: @props.questionsLimit,
              link: license_url
          }}
        />}
        <div className="questions">
          <EditableQuestionsList
            ref={(el) => @questionsList = el}
            questions={@props.questions}
            editingQuestion={@props.editingQuestion}
            addingQuestion={@props.addingQuestion}
          />
        </div>
        <AddSendButtons
          onAddTxQuestion={@addQuestion('management')}
          onAddDxQuestion={@addQuestion('diagnostic')}
          onAddFreeQuestion={@addQuestion('freeform')}
          onCloseStep={@props.onCloseStep}
          closingStep={@props.isClosingStep}
        />
      </div>

module.exports = InitialTab
