Tooltip = require 'components/common/tooltip'

AddMemberButton = ({ btnLabel, disabled, i18n, onClick }) ->
  <div className="add-new-member-row">
    <Tooltip tooltipType="questionMark">
      <span title={i18n('/team:limits.members_limit_reached')}>
        <button
          className="add-new-member"
          disabled={disabled}
          onClick={onClick}
        >
          {btnLabel}
        </button>
      </span>
    </Tooltip>
  </div>

module.exports = AddMemberButton
