Question = require 'components/scope/outcomes/question'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ dragSpec, dropSpec, dragCollect, dropCollect } =
  require 'components/scope/outcomes/question_dnd_spec'
DragDropTypes = require 'components/scope/outcomes/drag_drop_types'
DragSource = ReactDnD.DragSource
DropTarget = ReactDnD.DropTarget

DraggableQuestion = createReactClass
  displayName: 'DraggableQuestionComponent'
  propTypes:
    id: PropTypes.string.isRequired
    question: PropTypes.string.isRequired

  mixins: [CustomRenderMixin]

  render: ->
    { connectDragSource, connectDropTarget, isDraggingGroupQuestion, isDragging } = @props
    questionClasses = classNames 'question', 'draggable',
      'dragging': isDragging or isDraggingGroupQuestion

    connectDragSource(
      connectDropTarget(
        <div>
          <Question className={questionClasses} question={@props.question} />
        </div>
      )
    )

DragSourced = DragSource(DragDropTypes.QUESTION, dragSpec, dragCollect) DraggableQuestion
DropTargeted = DropTarget([DragDropTypes.QUESTION, DragDropTypes.QGROUP],
  dropSpec, dropCollect) DragSourced
module.exports = DropTargeted
