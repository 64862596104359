var resetSelection, scrollPosIntoView, useEffect, usePrevious, _ref;

_ref = require('lib/epiditor_utils'), resetSelection = _ref.resetSelection, scrollPosIntoView = _ref.scrollPosIntoView;

useEffect = React.useEffect;

usePrevious = require('components/hooks/use_previous');

module.exports = function(sectionId, scrollToSectionContent, editorViewRef) {
  var scrollToSectionContentPrev;
  scrollToSectionContentPrev = usePrevious(scrollToSectionContent);
  useEffect(function() {
    var contentPos, view;
    if (!editorViewRef) {
      return;
    }
    view = editorViewRef.current;
    if (scrollToSectionContent && sectionId === scrollToSectionContent.sectionId && view) {
      contentPos = scrollToSectionContent.contentPos;
      scrollPosIntoView(contentPos, view, true);
    }
  }, [scrollToSectionContent, scrollToSectionContentPrev, sectionId]);
  return useEffect(function() {
    var shouldResetSelection, view;
    view = editorViewRef.current;
    shouldResetSelection = view && (((scrollToSectionContentPrev != null) && (scrollToSectionContent == null)) || (scrollToSectionContent != null ? scrollToSectionContent.sectionId : void 0) !== sectionId);
    if (shouldResetSelection) {
      return resetSelection(view);
    }
  }, [scrollToSectionContentPrev, scrollToSectionContent, sectionId]);
};
