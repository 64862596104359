Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'
{ license_url } = require 'lib/constants'

LimitExceededWarningModal = createReactClass

  displayName: "LimitExceededWarningModal"

  mixins: [Translation('')]

  propTypes:
    onCancel: PropTypes.func.isRequired
    title: PropTypes.string.isRequired

  render: ->
    <Modal
      isOpen
      className="limits-exceeded-warning"
    >
      <div className="bold">{@props.title}</div>
      <div className="website-link"
        dangerouslySetInnerHTML={__html:
          @i18n '/go_to_licence_page_for_more_information', link: license_url}
      />
      <div className="buttons">
        <button className="btn btn-block btn-cancel" onClick={@props.onCancel}>
          {@i18n '/actions.ok'}
        </button>
        <a
          className="btn btn-block btn-apply"
          href={license_url}
          target="_blank"
          onClick={@props.onCancel}
        >
          {@i18n '/actions.go_to_website'}
        </a>
      </div>
    </Modal>

module.exports = LimitExceededWarningModal
