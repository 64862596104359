var getOrderedSections, mergeEtdsBasedOnAdolopmentData;

getOrderedSections = require('lib/etd_helper').getOrderedSections;

mergeEtdsBasedOnAdolopmentData = require('lib/adolopments_helper').mergeEtdsBasedOnAdolopmentData;

module.exports = {
  preparePresentationsData: function(assessment, attachments, conclusions, adolopments, adolopmentData, questionData) {
    var adolopmentRecId, fakeEtdTemplate, recId, template;
    recId = questionData.get('recommendationIds', Immutable.List()).last();
    adolopmentRecId = questionData.get('recommendationAdolopmentIds', Immutable.List()).last();
    if (_.isEmpty(adolopmentRecId)) {
      return {
        assessment: assessment,
        conclusions: getOrderedSections(conclusions),
        attachments: attachments
      };
    }
    fakeEtdTemplate = Immutable.Map().withMutations(function(s) {
      s.set('_attachments', attachments || Immutable.Map());
      s.setIn(['templateData', 'assessment'], assessment);
      return s.setIn(['templateData', 'conclusions'], conclusions);
    });
    template = Immutable.fromJS(mergeEtdsBasedOnAdolopmentData(adolopments.get(adolopmentRecId), fakeEtdTemplate, adolopmentData.get(recId, Immutable.Map())));
    return {
      attachments: template.get('_attachments', Immutable.Map()),
      assessment: template.getIn(['templateData', 'assessment']),
      conclusions: getOrderedSections(template.getIn(['templateData', 'conclusions']))
    };
  }
};
