var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "          <button class=\"hopscotch-nav-button prev hopscotch-prev\">\n          </button>\n";
  },"3":function(depth0,helpers,partials,data) {
  return "          <button class=\"hopscotch-nav-button next hopscotch-next\">\n          </button>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div class=\"hopscotch-bubble-container panel-voice-onboarding\" style=\"width: "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.width : stack1), depth0))
    + "px; \">\n  <div class=\"hopscotch-bubble-content\">\n    <div class=\"close-button-container\">\n      <button class=\"hopscotch-bubble-close hopscotch-close\"></button>\n    </div>\n    <div class=\"hopscotch-title\">\n      "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "walkthrough.step", {"name":"i18n","hash":{},"data":data})))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.i18n : depth0)) != null ? stack1.stepNum : stack1), depth0))
    + "/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.tour : depth0)) != null ? stack1.numSteps : stack1), depth0))
    + "\n    </div>\n    <div class=\"hopscotch-content\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.step : depth0)) != null ? stack1.content : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n  </div>\n  <div class=\"hopscotch-actions\">\n    <div class=\"row\">\n      <div class=\"col-1\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.buttons : depth0)) != null ? stack1.showPrev : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </div>\n      <div class=\"col-10\">\n      </div>\n      <div class=\"col-1\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.buttons : depth0)) != null ? stack1.showNext : stack1), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </div>\n  </div>\n</div>\n<div class=\"hopscotch-bubble-arrow-container hopscotch-arrow panel-voice-onboarding\">\n  <div class=\"hopscotch-bubble-arrow-border\"></div>\n  <div class=\"hopscotch-bubble-arrow\"></div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}