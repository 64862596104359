Translation = require 'components/mixins/translation'
Popover = require 'components/common/popover'
Label = require 'components/common/label'
Input = require 'components/common/input'
Button = require 'components/common/button'
OverReviewActions = require 'actions/over_review_actions'
{ string, object, arrayOf } = PropTypes

OutcomesMappingSelect = createReactClass
  displayName: 'OutcomesMappingSelect'

  propTypes:
    questionId: string.isRequired
    reviewedOutcomeId: string.isRequired
    sourceOutcomesByReviewedOutcomes: object
    mappedSourceOutcome: object
    sourceId: string.isRequired
    sourceOutcomes: arrayOf(object).isRequired

  mixins: [
    Translation('over_review:module')
  ]

  getInitialState: ->
    showOutcomesSelect: false

  toggleOutcomesSelect: ->
    @setState showOutcomesSelect: not @state.showOutcomesSelect

  mapSourceOutcomeAndClose: (selectedOutcomeId) ->
    OverReviewActions.mapSourceOutcome
      questionId: @props.questionId
      reviewedOutcomeId: @props.reviewedOutcomeId
      sourceOutcomeId: selectedOutcomeId
      sourceId: @props.sourceId

    @toggleOutcomesSelect()

  handleClearMapping: ->
    return @toggleOutcomesSelect() unless @props.mappedSourceOutcome?
    @mapSourceOutcomeAndClose null

  handleOutcomeMapping: (evt) ->
    @mapSourceOutcomeAndClose evt.target.value

  render: ->
    { showOutcomesSelect } = @state
    outcomeNameClassName = classNames 'mapped-outcome-name',
      active: showOutcomesSelect

    <Popover
      onRequestClose={@toggleOutcomesSelect}
      positionParams={position: 'bottom', alignment: 'center'}
      visible={showOutcomesSelect}
      draggable
    >
      <div className={outcomeNameClassName} onClick={@toggleOutcomesSelect}>
        {@props.mappedSourceOutcome?.name ? ''}
      </div>
      <div className='outcomes-mapping-select-box'>
        {@props.sourceOutcomes.map ({ name, _id }) =>
          mappedTo = @props.sourceOutcomesByReviewedOutcomes[_id]
          mappedToOtherReviewedOutcome = mappedTo and mappedTo isnt @props.reviewedOutcomeId

          <div key={_id}>
            <Label title={mappedToOtherReviewedOutcome and @i18n '../outcome_already_mapped'}>
              <span>
                <Input
                  checked={@props.mappedSourceOutcome?['_id'] is _id}
                  disabled={mappedToOtherReviewedOutcome}
                  keyboardSupport={false}
                  name='source-outcome'
                  onChange={@handleOutcomeMapping}
                  type='radio'
                  value={_id}
                />
              </span>
              <span>{name}</span>
            </Label>
          </div>
        }
        <Button
          className='btn'
          onClick={@handleClearMapping}
          label={@i18n '/actions.clear'}
        />
      </div>
    </Popover>

module.exports = OutcomesMappingSelect
