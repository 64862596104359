NumberedItemsList = require 'components/common/numbered_questions_list'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
ProjectsActions = require 'actions/projects_actions'
SpinnerSmall = require 'components/common/spinner_small'
Translation = require 'components/mixins/translation'

{ instanceOf, string } = PropTypes

ProjectQuestions = createReactClass
  displayName: 'ProjectQuestions'

  propTypes:
    questions: instanceOf(Immutable.Map)
    projectId: string.isRequired

  mixins: [CustomRenderMixin, Translation()]

  componentWillMount: ->
    ProjectsActions.fetchProjectQuestions @props.projectId

  renderQuestions: ->
    return <SpinnerSmall  /> unless @props.questions?
    return @i18n 'projects:list.no_questions' if @props.questions.isEmpty()
    items = @props.questions
    .map (q) ->
          {key: q.get('_id'), text: q.get('question')}
    .toJS()

    <NumberedItemsList items={items} />

  render: ->
    <div className='project-questions-container'>
      {@renderQuestions()}
    </div>

module.exports = ProjectQuestions
